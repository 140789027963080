import * as actionTypes from '../actions/actionTypes';

const switchmodalityState = {
	switchingEncounterModality: false,
	switchedEncounterModality: null,
	switchingEncounterModalityError: null,
};

export const defaultState = {
	data: [],
	error: null,
	loading: false,
	loaded: false,
	...switchmodalityState,
};

export default (state = defaultState, action = {}) => {
	switch (action.type) {
		case actionTypes.LOAD_APPOINTMENTS_START:
			return {
				...state,
				loading: true,
				loaded: false,
				error: null,
			};

		case actionTypes.LOAD_APPOINTMENTS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: null,
				data: action.payload,
			};

		case actionTypes.LOAD_APPOINTMENTS_FAILURE:
			return {
				...state,
				loading: false,
				loaded: true,
				error: action.payload,
			};

		case actionTypes.SWITCH_MODALITY_START:
			return {
				...state,
				switchingEncounterModality: true,
				switchedEncounterModality: null,
				switchingEncounterModalityError: null,
			};

		case actionTypes.SWITCH_MODALITY_SUCCESS:
			return {
				...state,
				switchingEncounterModality: false,
				switchedEncounterModality: action.payload,
				switchingEncounterModalityError: null,
			};

		case actionTypes.SWITCH_MODALITY_FAILURE:
			return {
				...state,
				switchingEncounterModality: false,
				switchedEncounterModality: null,
				switchingEncounterModalityError: action.payload,
			};

		default:
			return state;
	}
};
