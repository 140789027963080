import docIcon from '../components/Patients/V2/Documents/NewDocumentList/doc.svg';
import excelIcon from '../components/Patients/V2/Documents/NewDocumentList/excel.svg';
import imgIcon from '../components/Patients/V2/Documents/NewDocumentList/img.svg';
import otherIcon from '../components/Patients/V2/Documents/NewDocumentList/other.svg';
import pdfIcon from '../components/Patients/V2/Documents/NewDocumentList/pdf.svg';
import _ from 'lodash';

export const FAX = 'fax';
export const EMAIL = 'email';
export const modality = {
	[FAX]: 'eFAX',
	[EMAIL]: 'Email',
};

export const LIST = 'LIST';
export const AMEND = 'AMEND';
export const FINALIZE = 'FINALIZE';

export const RELEASE_SOURCE = {
	[LIST]: LIST,
	[AMEND]: AMEND,
	[FINALIZE]: FINALIZE,
};

export const renderFileIcon = (extension) => {
	const iconMapper = {
		'(gif|jpe?g|tiff?|png|webp|bmp|img)$': imgIcon,
		'(pdf)$': pdfIcon,
		'(doc)$': docIcon,
		'(excel|xls|xlsx)$': excelIcon,
	};
	let icon = otherIcon;

	for (const pattern in iconMapper) {
		const regex = new RegExp(pattern, 'i');
		if (regex.test(extension)) {
			icon = iconMapper[pattern];
			break;
		}
	}
	return icon;
};

export const documentDescriptionValidation = (description) => {
	if (typeof description === 'string' && description) {
		return description.match(/^[\w.,!?@"'’|/()&$ :;\-+%\s]+$/);
	} else return true;
};

export const encodeHTML = (str) =>
	str &&
	str.replace(/[\u00A0-\u9999<>&]/g, function (i) {
		return '&#' + i.charCodeAt(0) + ';';
	});

export const documentDescriptionLengthValidator = (description) => {
	if (typeof description === 'string' && description) {
		return description.length <= 40;
	}
	return false;
};

export const documentDescripitonRequiredValidation = (description) => {
	return (
		document &&
		!_.isNil(document) &&
		!_.isEmpty(description) &&
		description.trim().length > 0
	);
};

export const documentTypeIsVisit = (documentType) => {
	return documentType === 'visit_documentation';
};

export const documentTypeIsAdminMisc = (documentType) => {
	return documentType === 'administrative';
};

export const documentTypeIsRiskAssessment = (documentType) => {
	return documentType === 'risk_assessment';
};
