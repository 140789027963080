import * as actionTypes from './actionTypes';

export const accessrestricted = () => {
	return {
		type: actionTypes.ACCESS_RESTRICTED_CHANGE,
		payload: true,
	};
};

export const noaccessrestricted = () => {
	return {
		type: actionTypes.ACCESS_RESTRICTED_CHANGE,
		payload: false,
	};
};
