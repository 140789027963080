import React from 'react';

import get from 'lodash/get';

import ReactSVG from 'react-svg';
import xIcon from './../../../../../../assets/exit-alert.svg';
import alertIcon from './../../../../../../assets/alert.svg';

import { Loading } from 'gm-element-react';

class FlagArchiveModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			message: '',
			maxLength: 280,
			loading: false,
		};
		//bindings
		this.handleTextAreaChange = this.handleTextAreaChange.bind(this);
		this.getRemainingCount = this.getRemainingCount.bind(this);
		this.canSave = this.canSave.bind(this);
		this.handleArchive = this.handleArchive.bind(this);
	}
	//functions

	handleTextAreaChange(e) {
		const inputtedMessage = get(e, 'target.value', '');
		this.setState({ message: inputtedMessage });
	}

	getRemainingCount() {
		const currentMessage = get(this, 'state.message', '');
		const currentLength = get(currentMessage, 'length', 0);
		const difference = 280 - currentLength;
		return `${difference} ${
			difference === 1 ? 'character' : 'characters'
		} remaining`;
	}

	canSave() {
		const message = get(this, 'state.message', null);
		return typeof message === 'string' && get(message.trim(), 'length', 0);
	}

	handleArchive() {
		if (this.canSave())
			this.props.updateFlags(get(this, 'state.message', ''));
	}

	render() {
		const updatingTestOrderFlags = get(
			this,
			'props.updatingTestOrderFlags',
			false
		);
		const archiveButtonClass = this.canSave()
			? 'flagArchiveModalButton'
			: 'flagArchiveModalButton flagArchiveModalButtonDisabled';

		if (get(this, 'state.loading', false) || updatingTestOrderFlags) {
			return (
				<div className='fullModalBackground'>
					<div className='flagArchiveModalContainer'>
						<Loading loading={true} className='flagArchiveLoader' />
					</div>
				</div>
			);
		} else {
			return (
				<div className='fullModalBackground'>
					<div className='flagArchiveModalContainer'>
						<div className='auditTrailTitleRow'>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<ReactSVG
									src={alertIcon}
									className='modalAlertIcon'
								/>
								<span style={{ paddingBottom: '2px' }}>
									Archive Flag
								</span>
							</div>
							<div
								className='auditTrailClose'
								style={{
									top: '-2px',
									transform: 'scale(1.25)',
									borderTopRightRadius: '4px',
									height: '42px',
								}}
							>
								<ReactSVG
									src={xIcon}
									style={{
										position: 'relative',
										bottom: '-8px',
										right: '-9px',
									}}
									onClick={this.props.closeArchiveModal}
								/>
							</div>
						</div>

						<div className='flagArchiveModalBody'>
							<div className='flagArchiveModalLabel'>
								Archiving Flag
							</div>
							<div className='flagArchiveModalDescription'>
								{get(
									this,
									'props.renderArchiveModalFlagDescription',
									''
								) || '--'}
							</div>
							<div className='flagArchiveModalInputContainer'>
								<div className='flagArchiveModalRequired'>
									*
								</div>
								<div className='flagArchiveModalInputLabel'>
									Reason for archiving
								</div>
								<textarea
									className='flagArchiveModalInputTextArea'
									placeholder='Your message'
									value={get(this, 'state.message', '')}
									onChange={this.handleTextAreaChange}
									maxLength={get(
										this,
										'state.maxLength',
										280
									)}
								/>
								<div className='flagArchiveModalInputRemainingCount'>
									{this.getRemainingCount()}
								</div>
							</div>
						</div>

						<div className='flagArchiveModalButtonRow'>
							<div
								className={archiveButtonClass}
								onClick={this.handleArchive}
							>
								Archive
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default FlagArchiveModal;
