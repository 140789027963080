//React & Redux
import React, { useEffect, useState } from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import TagSelection from './TagSelection.js';

//Utils
import {
	determineTopDropShadow,
	determineBottomDropShadow,
} from '../../../utils.js';

const ExceptionsStage = (props) => {
	const {
		alternateFinalizeButtonText,
		buttonText,
		func,
		items,
		keyForSearch,
		loading: loadingFromProps,
		preselectedItems,
		price,
		regressStage,
	} = props;

	const [allValid, setAllValid] = useState(true);
	const [bodyElement, setBodyElement] = useState(null);
	const [bottomDropShadow, setBottomDropShadow] = useState(false);
	const [currentStage, setCurrentStage] = useState('select');
	const [icdCodes, setIcdCodes] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [selectedICDCodes, setSelectedICDCodes] = useState([]);
	const [topDropShadow, setTopDropShadow] = useState(false);

	useEffect(() => {
		const preselectedItemsArr = _.cloneDeep(preselectedItems);

		if (Array.isArray(preselectedItemsArr)) {
			preselectedItemsArr.forEach((el) => {
				if (el.partner_name) delete el['partner_name'];
			});

			setSelectedICDCodes(preselectedItemsArr);
		}

		setIcdCodes(items);
	}, []);

	useEffect(() => {
		filterResults();
	}, [searchQuery]);

	useEffect(() => {
		determineDropShadow(['top', 'bottom']);
	}, [bodyElement]);

	useEffect(() => {
		validateInputs;
	}, [selectedICDCodes]);

	const handleSetBodyElement = (el) => {
		el && setBodyElement(el);
	};

	const determineDropShadow = _.debounce((sides) => {
		if (Array.isArray(sides) && bodyElement) {
			sides.forEach((side) => {
				let shadow;
				switch (side) {
					case 'top':
						shadow = determineTopDropShadow(bodyElement);
						setTopDropShadow(shadow);
						break;
					case 'bottom':
						shadow = determineBottomDropShadow(bodyElement);
						setBottomDropShadow(shadow);
						break;
					default:
						console.log('Unrecognized modal side.');
						break;
				}
			});
		}
	}, 100);

	const handleSearchQueryChange = (str) => {
		setSearchQuery(str);
	};

	const filterResults = () => {
		if (!Array.isArray(items)) return;
		const filteredItems = items.filter((el) => {
			return (el[keyForSearch] || '')
				.toLowerCase()
				.includes(searchQuery.toLowerCase());
		});
		setIcdCodes(filteredItems);
	};

	const toggleCodeSelection = (status, id, icdCode, input) => {
		if (status) {
			toggleCodeSelectionOn(id, icdCode, input);
		} else {
			toggleCodeSelectionOff(id, icdCode, input);
		}
	};

	const toggleCodeSelectionOn = (id, icdCode, input) => {
		const filteredCodes = selectedICDCodes.filter(
			(el) => (el.partner_id || '') === id
		);
		if (filteredCodes.length === 0) {
			selectedICDCodes.push(constructSelectedObject(icdCode, input));
			sortAndSetSelectedCodes(selectedICDCodes);
		}
	};

	const constructSelectedObject = (icdCode, input) => {
		const obj = {
			consultation_price: input,
			partner_id: icdCode.id || null,
		};
		if (Array.isArray(preselectedItems)) {
			const preselectedMatch = _.find(
				preselectedItems,
				(el) => (el.partner_id || null) === (icdCode.id || '')
			);
			if (preselectedMatch) obj['id'] = preselectedMatch.id || null;
		}
		return obj;
	};

	const toggleCodeSelectionOff = (id) => {
		_.remove(selectedICDCodes, (el) => (el.partner_id || '') === id);
		sortAndSetSelectedCodes(selectedICDCodes);
	};

	const sortAndSetSelectedCodes = (selectedCodes) => {
		if (Array.isArray(selectedCodes)) {
			selectedCodes = _.sortBy(selectedCodes, 'display_name');
		}
		setSelectedICDCodes(selectedCodes);
	};

	const handleInputChange = (input, icdCode) => {
		if (Array.isArray(selectedICDCodes)) {
			setSelectedICDCodes(
				selectedICDCodes.map((el) => {
					if ((el.partner_id || null) === (icdCode.id || '')) {
						el.consultation_price = input;
					}
					return el;
				})
			);
		}
	};

	const validateInputs = () => {
		let status = true;
		if (Array.isArray(selectedICDCodes)) {
			selectedICDCodes.forEach((el) => {
				const consultationPrice = el.consultation_price || null;
				if (typeof consultationPrice !== 'string') {
					if (typeof consultationPrice !== 'number') status = false;
				} else {
					if (!consultationPrice.match(/^\d+(\.\d+)?$/i))
						status = false;
				}
			});
		}
		setAllValid(status);
	};

	const renderCurrentStage = () => {
		switch (currentStage) {
			case 'select':
				return (
					<TagSelection
						{...{
							allValid,
							alternateFinalizeButtonText,
							buttonText,
							collection: items,
							func,
							handleInputChange,
							handleSearchQueryChange,
							icdCodes,
							price,
							regressStage,
							searchQuery,
							selectedICDCodes,
							setBodyElement: handleSetBodyElement,
							scrollHandler: determineDropShadow,
							shouldHaveBottomDropShadow: bottomDropShadow,
							shouldHaveTopDropShadow: topDropShadow,
							toggleCodeSelection,
						}}
					/>
				);
			default:
				console.log('Unrecognized stage.');
		}
	};

	return (
		<Loading
			className='exceptionLoading'
			loading={loadingFromProps || loading}
		>
			{renderCurrentStage()}
		</Loading>
	);
};

export default ExceptionsStage;
