//React & Redux
import React from 'react';

//UI Libraries
import { Dialog, Button } from 'gm-element-react';

//Components
import Icon from '../Common/Icon';

//Styles
import './ProviderUnlinkDialog.css';

const ProviderUnlinkDialog = (props) => {
	const { cancel, confirm, content, visible } = props;

	return (
		<Dialog
			customClass='provider-unlink-dialog'
			title={
				<span>
					<Icon icon='warning-circle' />
					<span className='unlink-title'>{'Unlink Provider'}</span>
				</span>
			}
			size='tiny'
			modal={true}
			closeOnPressEscape={true}
			closeOnClickModal={true}
			showClose={false}
			visible={visible}
			onCancel={cancel}
		>
			<Dialog.Body>{content}</Dialog.Body>
			<Dialog.Footer className='dialog-footer'>
				<Button className='cancel-button' onClick={cancel}>
					{'Cancel'}
				</Button>
				<Button className='confirm-button' onClick={confirm}>
					{'Unlink Provider'}
				</Button>
			</Dialog.Footer>
		</Dialog>
	);
};

export default ProviderUnlinkDialog;
