import axios from 'axios';
import get from 'lodash/get';
import qs from 'query-string';

class PatientService {
	patients = (data) => {
		let url =
			'/api/patients?offset=' +
			get(data, 'offset', 0) +
			'&q=' +
			get(data, 'q', '') +
			'&order_by=' +
			get(data, 'order_by', 'created_at') +
			'&order_type=' +
			get(data, 'order_type', 'desc') +
			'&limit=' +
			get(data, 'limit', '');
		if (get(data, 'patient_athena_package', ''))
			url += `&patient_athena_package=${get(
				data,
				'patient_athena_package',
				'false'
			)}`;
		if (get(data, 'component', ''))
			url += `&component=${get(data, 'component', '')}`;
		if (get(data, 'primary', null))
			url += `&primary=${get(data, 'primary', null)}`;
		return axios.get(url);
	};

	patientsWithoutEncounters = (data) => {
		let url =
			'/api/patients/without-encounters?offset=' +
			get(data, 'offset', 0) +
			'&q=' +
			get(data, 'q', '') +
			'&order_by=' +
			get(data, 'order_by', 'created_at') +
			'&order_type=' +
			get(data, 'order_type', 'desc') +
			'&limit=' +
			get(data, 'limit', '');
		if (get(data, 'patient_athena_package', ''))
			url += `&patient_athena_package=${get(
				data,
				'patient_athena_package',
				'false'
			)}`;
		if (get(data, 'component', ''))
			url += `&component=${get(data, 'component', '')}`;
		if (get(data, 'primary', null))
			url += `&primary=${get(data, 'primary', null)}`;
		return axios.get(url);
	};

	deletepatient = (data) => {
		return axios.delete(`/api/patients/${data.data.user_uuid}`, data);
	};

	patientdetail = (pid) => {
		return axios.get(`/api/patients/${pid}`);
	};

	addpatient = (data) => {
		return axios.post('/api/patients', data);
	};

	getReferralPrograms = () => {
		return axios.get('/api/get_referral_programs', {
			params: {
				order_by: 'display_name',
				order_type: 'asc',
				limit: -1,
			},
		});
	};
	getAppointmentReferralPrograms = () => {
		return axios.get('/api/get_appointment_referral_programs', {
			params: {
				order_by: 'display_name',
				order_type: 'asc',
				limit: 200,
			},
		});
	};

	getContactMethods = () => {
		return axios.get('/api/contact-methods', {
			params: {
				order_by: 'display_name',
				order_type: 'asc',
				limit: -1,
			},
		});
	};

	getContactOutcomes = () => {
		return axios.get('/api/contact-outcomes');
	};

	getReferralReasons = () => {
		return axios.get('/api/referral-reasons', {
			params: {
				order_by: 'display_name',
				order_type: 'asc',
				limit: -1,
			},
		});
	};

	getReferralProviders = () => {
		return axios.get('/api/referral-providers', {
			params: {
				order_by: 'name',
				order_type: 'asc',
				limit: -1,
			},
		});
	};

	getConsenttotreat = () => {
		return axios.get('/api/enums/patients/consent_to_treat');
	};

	getEligibilityFields = (referral) => {
		return axios.get(
			'/api/patients/eligibility-fields?referral=' + referral
		);
	};

	updateOutreach = async (data) => {
		return await axios.post(
			`/api/patients/outreach/${data.patient_id}`,
			data.payload
		);
	};

	updateAddress = async (data) => {
		const res = await axios.post(
			`/api/patients/address/${data.patient_id}`,
			data.payload
		);
		return res;
	};

	setPassword = async (patient_uuid) => {
		return await axios.get(`/api/patients/${patient_uuid}/password-reset`);
	};

	updateRelationship = async (data) => {
		const res = await axios.post(
			`/api/patients/${data.patient_id}/relationship/${data.method}`,
			data.payload
		);
		return res;
	};

	getNoOutreachReasons = () => {
		return axios.get(`/api/enums/patients/outreach_reason`);
	};

	getInsuranceCompanies = async (data, full_list) => {
		let url = `/api/patients/${data.patient_uuid}/insurance-companies?order_by=name&order_type=asc&full_list=${full_list}`;
		return axios.get(url);
	};

	getInsuranceAuths = async (data) => {
		const res = await axios.get(
			`/api/insurance/${data.policyholder_insurance_id}/authorizations`
		);
		return res;
	};

	saveInsurance = async (data, patient_uuid) => {
		const res = await axios.post(
			`/api/patients/${patient_uuid}/insurance`,
			data
		);
		return res;
	};

	getInsuranceInfo = async (patient_uuid) => {
		const res = await axios.get(`/api/patients/${patient_uuid}/insurance`);
		return res;
	};

	saveInsuranceAuth = async (data) => {
		let res;
		if (data.insurance_authorization_id) {
			res = axios.put(
				`/api/insurance/${data.policyholder_insurance_id}/authorizations/${data.insurance_authorization_id}`,
				data
			);
		} else {
			res = axios.post(
				`/api/insurance/${data.policyholder_insurance_id}/authorizations`,
				data
			);
		}
		return res;
	};

	getConsultations = (user_id, partner_id) => {
		const query = qs.stringify({ user_id, partner_id });
		return axios.get(
			`/api/encounters/visits/consultations_types${
				query ? `?${query}` : ''
			}`
		);
	};

	updatePaymentMethod = async (data, patient_uuid) => {
		const res = await axios.post(
			`/api/patients/${patient_uuid}/payment-method`,
			data
		);
		return res;
	};

	addInitialContactMethod = (data) => {
		return axios.post('/api/contact-methods', data);
	};

	addReferralReason = (data) => {
		return axios.post('/api/referral-reasons', data);
	};

	addReferringProvider = (data) => {
		return axios.post('/api/referral-providers', data);
	};

	createAthenaPatient = (uuid) => {
		return axios.post(`/api/patients/${uuid}/athena`);
	};

	duplicatePatientCheck = (data) => {
		return axios.get(`/api/patients/matching`, {
			params: {
				order_type: 'asc',
				limit: 3,
				offset: get(data, 'offset', 0),
				dob: get(data, 'dob', null),
				email: get(data, 'email', null),
				last_name: get(data, 'last_name', null),
				first_name: get(data, 'first_name', null),
				is_unborn: get(data, 'is_unborn', null),
			},
		});
	};

	linkProviders = (patient_uuid, data) => {
		return axios.post(`/api/patients/care-team/${patient_uuid}`, data);
	};

	updateLinkedProvider = (patient_uuid, provider_uuid, data) => {
		return axios.patch(
			`/api/patients/care-team/${patient_uuid}/provider/${provider_uuid}`,
			{ data }
		);
	};

	getLinkedProviders = (patient_uuid) => {
		return axios.get(`/api/patients/care-team/${patient_uuid}`);
	};

	unlinkProvider = (patient_uuid, provider_uuid, data) => {
		return axios.delete(
			`/api/patients/care-team/${patient_uuid}/provider/${provider_uuid}`,
			{ data }
		);
	};

	getPatientTags = () => {
		return axios.post('/api/enums/tags', {});
	};

	getGender = () => {
		return axios.get('/api/enums/patients/gender');
	};

	getPronouns = () => {
		return axios.get('/api/enums/patients/pronouns');
	};

	getSexAssignedAtBirth = () => {
		return axios.get('/api/enums/patients/sex_assigned_at_birth');
	};

	getRaceEnum = () => {
		return axios.get('/api/enums/patients/race');
	};

	getEthnicityEnum = () => {
		return axios.get('/api/enums/patients/ethnicity');
	};

	updateConsentStatus = async (patientUUID, data) => {
		const res = await axios.post(
			`/api/patients/${patientUUID}/consent/status`,
			data
		);
		return res;
	};

	updatePatient = (patientUUID, data) => {
		return axios.patch(`/api/patients/${patientUUID}`, data);
	};

	sendAuthToRecontactOutreach = async (patient_uuid) => {
		return await axios.post(
			`/api/patients/${patient_uuid}/outreach/auth-to-recontact`,
			{}
		);
	};
}

export default new PatientService();
