//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import { getMomentDateTimeOrString, getDateTimeString } from './utilsfunctions';

//Other Libraries
import classnames from 'classnames';
import Datetime from 'react-datetime';

export const EncounterDateTimeField = ({
	name,
	value,
	onChange,
	placeholder,
	trim,
	extraclassName,
	disabled,
	error,
	disabledDate,
	onBlur,
	onRight,
	istouched = true,
}) => {
	const iserror = istouched && error && !_.isNil(error) && !_.isEmpty(error);
	const currentClassName = classnames(
		iserror ? 'encounter-input-error-field' : 'encounter-input-field',
		extraclassName,
		{ 'calendar-right-encounter': onRight }
	);

	return (
		<Fragment>
			<div className='relative-section'>
				<div className={currentClassName}>
					<Datetime
						dateFormat={'MM/DD/YYYY'}
						timeFormat={'hh:mm a'}
						value={getMomentDateTimeOrString(value)}
						isDisabled={disabled}
						onChange={(value) => onChange(getDateTimeString(value))}
						isValidDate={disabledDate}
						disabledDate={disabledDate}
						onBlur={(value) => onBlur(getDateTimeString(value))}
						timeConstraints={{
							minutes: { min: 0, max: 60, step: 10 },
						}}
						strictParsing={true}
						inputProps={{
							disabled: disabled,
							placeholder: placeholder || 'MM/DD/YYYY',
							autoComplete: 'no',
						}}
						closeOnTab={true}
						closeOnSelect={true} // if we enabled this then sometime blur might not be called from plugin.
						className={iserror ? 'inputFieldDateTimeError' : ''}
					/>
				</div>
				<i className='el-input__icon el-icon-date'></i>
				<div
					className='inputFieldError'
					style={{ top: 'unset', left: 'unset' }}
				>
					{iserror ? error : ''}
				</div>
			</div>
		</Fragment>
	);
};
