//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';
import find from 'lodash/find';

//Utils
import {
	UTCDateToFormattedDate,
	turnUTCIntoLocalDateTime,
	determineWidthOverflow,
	determineHeightOverflow,
} from '../../../utils.js';
import { getUserImage, providers_images } from '../../../Utils/encounterUtils';

//Libraries
import moment from 'moment';

//Styling
import './notes.css';

//Components
import Tooltip from './../Tooltip.js';

class CommentBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.getNoteTimeStamp = this.getNoteTimeStamp.bind(this);
	}
	//functions

	componentDidMount() {
		this.forceUpdate();
	}

	componentDidUpdate(prevProps, prevState) {
		const currentNote = get(this, 'props.note', null) || {};
		const currentNoteComment = get(currentNote, 'text', null) || '--';
		const prevNote = get(prevProps, 'note', null) || {};
		const prevNoteComment = get(prevNote, 'text', null) || '--';
		if (currentNoteComment != prevNoteComment) this.forceUpdate();
	}

	getNoteTimeStamp(note = {}) {
		const enteredAt = get(note, 'enteredAt', null);
		if (!enteredAt) return '--';

		const noteDate = turnUTCIntoLocalDateTime(enteredAt, 'MMM D, YYYY');
		const noteTime = turnUTCIntoLocalDateTime(enteredAt, 'hh:mm A');

		return `${noteDate} | ${noteTime}`;
	}

	render() {
		const variant = get(this, 'props.variant', null) || 'dense';
		const note = get(this, 'props.note', null) || {};
		const noteComment = get(note, 'text', null) || '--';
		const noteAuthor = get(note, 'source.name', null) || '--';
		const authorDateTimeClass =
			variant in { dense: 1, cfDNA: 1 }
				? 'commentBoxAuthorDateTimeDense'
				: 'commentBoxAuthorDateTimeFull';
		const commentBoxAuthorClass =
			variant in { dense: 1, cfDNA: 1 }
				? 'commentBoxAuthor'
				: 'commentBoxAuthor commentBoxAuthorFull';
		const commentBoxDateTimeClass =
			variant in { dense: 1, cfDNA: 1 }
				? 'commentBoxDateTime'
				: 'commentBoxDateTime commentBoxDateTimeFull';
		const commentBoxCommentClass =
			variant in { dense: 1, cfDNA: 1 }
				? 'commentBoxComment'
				: 'commentBoxComment commentBoxCommentFull';
		const userImageContainerStyling = { transform: 'scale(0.5)' };
		const userImageStyling = { borderRadius: '5px' };
		return (
			<div className='commentBoxContainer'>
				<div className={authorDateTimeClass}>
					<div className={commentBoxAuthorClass}>
						{getUserImage(
							note,
							noteAuthor,
							userImageContainerStyling,
							userImageStyling
						)}
						<div className='commentBoxAuthorName'>{noteAuthor}</div>
					</div>
					<div className={commentBoxDateTimeClass}>
						{this.getNoteTimeStamp(note)}
					</div>
				</div>
				<div className={commentBoxCommentClass}>
					{variant in { dense: 1, cfDNA: 1 } ? (
						<Tooltip
							content={noteComment}
							disabled={
								variant === 'dense'
									? determineWidthOverflow(
											this.tooltipCommentDiv
									  )
									: determineHeightOverflow(
											this.tooltipCommentDiv
									  )
							}
						>
							<div
								style={
									variant === 'dense'
										? {
												textOverflow: 'ellipsis',
												maxWidth: '100%',
												overflowX: 'hidden',
												whiteSpace: 'nowrap',
										  }
										: {
												marginLeft: '28px',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												webkitBoxOrient: 'vertical',
												webkitLineClamp: '3',
												display: '-webkit-box',
										  }
								}
								ref={(el) => (this.tooltipCommentDiv = el)}
							>
								{noteComment}
							</div>
						</Tooltip>
					) : (
						noteComment
					)}
				</div>
			</div>
		);
	}
}

export default CommentBox;
