import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik, FormikProvider } from 'formik';
import { get } from 'lodash';

import { ModalWindow } from '../Common/V3/ModalWindow';
import Dropdown from '../Common/FormFields/Dropdown';
import Button from '../Common/Button';
import { FileDropZone } from '@gm/common/ui';

import { getDefaultSchedulingOptions } from '../../actions/referralprograms/referralprograms';
import { LoadPatientConsultations } from '../../actions/scheduling';
import {
	LoadDocumentTypesForPatient,
	uploadFile,
} from '../../actions/documents';
import OutreachService from '../../service/Outreach';

import './InvitePatientToSchedule.scss';

/**
 * Component for inviting a patient to schedule an appointment.
 *
 * @param {Object} props
 * @param {Function} props.closeModal - Function to close the modal.
 * @param {Function} props.onSuccess - Callback fired after outreach round is created successfully.
 * @param {string} props.partnerUUID - The partner's UUID.
 * @param {Array} props.encounterList - List of encounters.
 * @param {Object} props.associatedProgram - The program associated with the patient.
 * @param {string} props.patientId - The ID for the patient.
 * @param {string} props.patientUUID - The UUID of the patient.
 */
const InvitePatientToSchedule = ({
	closeModal,
	onSuccess,
	partnerUUID,
	encounterList,
	associatedProgram,
	patientId,
	patientUUID,
}) => {
	const {
		schedulingConsultations,
		defaultSchedulingOptions,
		documentTypesForPatient,
	} = useSelector((state) => ({
		schedulingConsultations: state.Scheduling?.schedulingConsultations,
		defaultSchedulingOptions:
			state.referralProgramsList?.defaultSchedulingOptions?.payload,
		documentTypesForPatient: state.documents?.documentTypesForPatient,
	}));

	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState(null);
	const [sizeError, setSizeError] = useState(false);

	const formulateDocTypes = (document_types, noUnassignedFlag) => {
		if (Array.isArray(document_types)) {
			let formulatedDocTypes = [];
			const documentTypes = noUnassignedFlag
				? document_types.filter(
						(el) => get(el, 'key', '') !== 'unassigned'
				  )
				: document_types;
			formulatedDocTypes = documentTypes.map((el) => {
				const subTypes = get(el, 'subtypes', null);
				const docType = {
					value: get(el, 'key', null),
					label: get(el, 'display_name', null),
				};
				if (Array.isArray(subTypes) && get(subTypes, 'length', 0) > 0) {
					docType.children = subTypes.map((subType) => {
						return {
							value: get(subType, 'key', null),
							label: get(subType, 'display_name', ''),
						};
					});
				}
				return docType;
			});
			return formulatedDocTypes;
		} else return document_types;
	};

	const documentTypes = formulateDocTypes(documentTypesForPatient);

	const dispatch = useDispatch();

	useEffect(() => {
		if (sizeError) {
			const timer = setTimeout(() => setSizeError(false), 750);
			return () => clearTimeout(timer);
		}
	}, [sizeError]);

	useEffect(() => {
		if (partnerUUID) {
			dispatch(
				getDefaultSchedulingOptions({ partner_uuid: partnerUUID })
			);
		}
	}, [partnerUUID, dispatch]);

	useEffect(() => {
		if (partnerUUID && associatedProgram) {
			dispatch(LoadPatientConsultations(patientId, associatedProgram));
			dispatch(LoadDocumentTypesForPatient());
		}
	}, [partnerUUID, associatedProgram, patientId, dispatch]);

	useEffect(() => {
		if (file) {
			setFieldValue('file', file);
		} else {
			setFieldValue('file', null);
		}
	}, [file]);

	const consultationsOptions = () => {
		const consultationList = schedulingConsultations?.filter(
					(item) => item.type !== 'follow_up'
			  );
		return consultationList?.map((el) => ({
			key: el.id,
			label: el.name,
			value: el.type,
		}));
	};

	const specialtyOptions = () =>
		defaultSchedulingOptions?.specialties?.map((el) => ({
			key: el.id,
			label: el.display_name,
			value: el.name.toLowerCase(),
		}));

	const formik = useFormik({
		initialValues: {
			consultationType: '',
			specialty: '',
			file: '',
			documentType: '',
		},
		validate: (values) => {
			const errors = {};

			if (!values.consultationType) {
				errors.consultationType = 'Consultation Type is required';
			}
			if (!values.specialty) {
				errors.specialty = 'Specialty is required';
			}

			if (
				(values.file && !values.documentType) ||
				(!values.file && values.documentType)
			) {
				errors.file =
					'Both file and document type are required together';
				errors.documentType =
					'Both document type and file are required together';
			}

			return errors;
		},
		onSubmit: async (values) => {
			const data = {
				consultation_type: values.consultationType,
				specialty: values.specialty,
			};

			await OutreachService.createOutreachRound(patientUUID, data);

			if (values.documentType && values.file) {
				const formData = new FormData();
				formData.append('document_type', values.documentType);
				formData.append('document_file', values.file);
				const json = {
					payload: formData,
					patientuuid: patientUUID,
				};
				dispatch(uploadFile(json));
			}

			closeModal();
			onSuccess();
		},
	});

	const { setFieldValue, handleSubmit, touched, errors, values } = formik;

	return (
		<FormikProvider value={formik}>
			<ModalWindow
				withIcon={false}
				onCancel={closeModal}
				title='Trigger New Outreach to Patient'
				width='359px'
				top='10%'
				closeOnClickOutsideModal={false}
			>
				<div className='invite-patient-to-schedule'>
					<form onSubmit={handleSubmit} className='forms'>
						<div className='subsection'>
							<div className='label'>
								Consultation Information
							</div>
							<div className='divider'></div>
						</div>
						<Dropdown
							required
							className='dropdown-input-outreach'
							name='consultationType'
							label='Consultation Type'
							options={consultationsOptions()}
							value={values.consultationType}
							error={
								touched.consultationType &&
								errors.consultationType
							}
							onChange={(value) =>
								setFieldValue('consultationType', value)
							}
						/>
						<Dropdown
							required
							className='dropdown-input-outreach'
							name='specialty'
							label='Specialty'
							options={specialtyOptions()}
							value={values.specialty}
							error={touched.specialty && errors.specialty}
							onChange={(value) =>
								setFieldValue('specialty', value)
							}
						/>
					</form>
					<div className='alert-box-modal'>
						<div className='text'>
							Consultation Type & Specialty cannot be edited after
							outreach is triggered. Please ensure the values are
							correct.
						</div>
					</div>
					<div className='subsection'>
						<div className='label'>Documents</div>
						<div className='divider'></div>
					</div>
					<span className='doc-text'>
						You have the option to upload the patient’s test results
					</span>
					<Dropdown
						className='dropdown-input-outreach'
						name='documentType'
						label='Document Type'
						options={documentTypes}
						required={fileName}
						value={values.documentType}
						error={touched.documentType && errors.documentType}
						onChange={(value) =>
							setFieldValue('documentType', value)
						}
					/>
					<FileDropZone
						setFile={setFile}
						fileName={fileName}
						setFileName={setFileName}
						setSizeError={setSizeError}
						sizeError={sizeError}
					/>
					<div className='btn-container-modal'>
						<Button
							onClick={closeModal}
							className='btn-schedule-appointment inverted button-outreach'
							inverted
						>
							Cancel
						</Button>

						<Button
							onClick={handleSubmit}
							className='btn-schedule-appointment button-outreach'
						>
							Save
						</Button>
					</div>
				</div>
			</ModalWindow>
		</FormikProvider>
	);
};

export default InvitePatientToSchedule;
