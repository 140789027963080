import classnames from 'classnames';
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { StripePayment } from './StripePayment';

import './CreditCardForm.scss';
import PropTypes from 'prop-types';

export const CreditCardForm = forwardRef(function SelfPayForm(
	{ className },
	ref
) {
	const { patientDetails } = useSelector((store) => ({
		patientDetails: store.patientdetail?.patientdetail?.[0],
	}));
	const patientUuid = patientDetails?.uuid;

	return (
		<div className={classnames('credit-card-form', className)}>
			<StripePayment
				patientUuid={patientUuid}
				ref={ref}
				defaultValues={{
					name: `${patientDetails?.first_name} ${patientDetails?.last_name}`,
					address: {
						postalCode: patientDetails?.address?.[0]?.zip,
					},
				}}
			/>
		</div>
	);
});

CreditCardForm.propTypes = {
	className: PropTypes.string,
};
