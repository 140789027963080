//React & Redux
import React from 'react';
import { connect } from 'react-redux';

//Images
import ReactSVG from 'react-svg';
import backToTopIcon from './back-to-top.svg';
import resultsPerPageIcon from './results-per-page.svg';

//Lodash
import { get, max, toNumber, isEmpty, isArray } from 'lodash';

//Actions & Services
import { updateResultsPerPage } from './../../../actions/uiConfig';

//UI Libraries
import { Dropdown, Loading } from 'gm-element-react';

//Components
import Pagination from '../Pagination';

//Styles
import './styles.css';

class Table extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			trs: {},
		};

		//bindings
		this.scrollToTop = this.scrollToTop.bind(this);
		this.handleResultsPerPageChange =
			this.handleResultsPerPageChange.bind(this);
		this.registerTr = this.registerTr.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleResetFilters = this.handleResetFilters.bind(this);
		this.handleSort = this.handleSort.bind(this);
	}

	registerTr(uuid) {
		const { trs } = this.state;
		trs[uuid] = get(this, ['props', 'isAllExpanded'], false);
		this.setState({ trs });
	}

	handleToggle(uuid) {
		const trs = get(this, ['state', 'trs'], {});
		let isAllExpanded = get(this, ['props', 'isAllExpanded'], false);

		if (uuid) {
			trs[uuid] = !trs[uuid];
			const values = new Set(Object.values(trs));
			isAllExpanded = values.size === 1 && Array.from(values)[0];
		} else {
			isAllExpanded = !isAllExpanded;
			for (const uuid in trs) {
				trs[uuid] = isAllExpanded;
			}
		}

		this.setState({ trs });
		this.props.setIsAllExpanded(isAllExpanded);
	}

	scrollToTop() {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}

	handleResultsPerPageChange(limit) {
		this.scrollToTop();
		this.setState({ trs: {} });
		if (limit !== 'All') this.props.updateResultsPerPage(+limit);
		this.props.handleResultsPerPageChange(limit);
	}

	handlePageChange(e, offset) {
		this.scrollToTop();
		this.setState({ trs: {} });
		this.props.handlePageChange(e, offset);
	}

	handleResetFilters() {
		this.setState({ trs: {} });
		this.props.handleResetFilters();
	}

	handleSort(order_by, order_type) {
		this.setState({ trs: {} });
		this.props.handleSort(order_by, order_type);
	}

	render() {
		const children = isArray(this.props.children)
			? this.props.children
			: [this.props.children];
		const limit = get(this, ['props', 'limit'], this.props.defaultLimit);
		const paginationLimit = max([
			get(
				this,
				['props', 'pagination', 'limit'],
				this.props.defaultLimit
			),
			toNumber(limit),
		]);
		const loading = get(this, ['props', 'loading'], false);
		const hasData = get(this, ['props', 'hasData'], true);
		const isExpandable = get(this, ['props', 'isExpandable'], false);
		const shouldRenderResultsPerPage =
			get(this, ['props', 'resultsPerPage', 'length'], 0) > 0 &&
			get(this, ['props', 'handleResultsPerPageChange'], null);
		const shouldRenderBackToTop = get(this, ['props', 'backToTop'], false);
		const shouldRenderPagination = !isEmpty(
			get(this, ['props', 'pagination'], {})
		);
		const containerStyle =
			get(this, ['props', 'containerStyle'], null) || {};

		return (
			<Loading
				loading={loading}
				className='custom-table'
				style={{ ...containerStyle }}
			>
				<table
					style={get(this, ['props', 'style'], {})}
					className={get(this, ['props', 'className'], '')}
				>
					{children.map((child, i) =>
						React.cloneElement(child, {
							key: `table-row-idx-${i}`,
							hasData,
							loading,
							handleResetFilters: this.handleResetFilters,
							handleSort: this.handleSort,
							sortBy: this.props.sortBy,
							noResultsFound: this.props.noResultsFound,
							isExpandable,
							trs: this.state.trs,
							isAllExpanded: get(
								this,
								['props', 'isAllExpanded'],
								false
							),
							registerTr: this.registerTr,
							handleToggle: this.handleToggle,
							noResultsFoundAltMessage:
								this.props.noResultsFoundAltMessage,
							emptyOnNoResults: this.props.emptyOnNoResults,
						})
					)}
					{hasData &&
						(shouldRenderResultsPerPage ||
							shouldRenderBackToTop ||
							shouldRenderPagination) && (
							<tfoot>
								<tr>
									<td colSpan='100%'>
										<div className='tfoot-wrapper'>
											{/* results per page */}
											{shouldRenderResultsPerPage && (
												<div className='results-per-page'>
													<span>
														Results per page:
													</span>
													<Dropdown
														trigger='click'
														menu={
															<Dropdown.Menu>
																{get(
																	this,
																	[
																		'props',
																		'resultsPerPage',
																	],
																	[]
																).map(
																	(
																		num,
																		i
																	) => (
																		<Dropdown.Item
																			key={
																				i
																			}
																			command={num.toString()}
																		>
																			{
																				num
																			}
																		</Dropdown.Item>
																	)
																)}
															</Dropdown.Menu>
														}
														onCommand={
															this
																.handleResultsPerPageChange
														}
													>
														<span>
															<span>{limit}</span>
															<ReactSVG
																src={
																	resultsPerPageIcon
																}
															/>
														</span>
													</Dropdown>
												</div>
											)}

											{/* back to top */}
											{shouldRenderBackToTop && (
												<div
													className='back-to-top'
													onClick={this.scrollToTop}
												>
													<span>Back to top</span>
													<ReactSVG
														src={backToTopIcon}
													/>
												</div>
											)}

											{/* pagination */}
											{shouldRenderPagination && (
												<div className='pagination'>
													<Pagination
														className='appointment-pagination'
														pageSize={
															paginationLimit
														}
														total={get(
															this,
															[
																'props',
																'pagination',
																'total',
															],
															0
														)}
														offset={get(
															this,
															[
																'props',
																'pagination',
																'offset',
															],
															0
														)}
														layout='prev, pager, next'
														onCurrentChange={
															this
																.handlePageChange
														}
													/>
												</div>
											)}
										</div>
									</td>
								</tr>
							</tfoot>
						)}
				</table>
			</Loading>
		);
	}
}

const mapStateToProps = (state) => ({
	defaultLimit: state.uiConfig?.table.resultsPerPage,
});

const mapDispatchToProps = (dispatch) => ({
	updateResultsPerPage: (limit) => dispatch(updateResultsPerPage(limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
