//React & Redux
import React from 'react';

//Other Libraries
import moment from 'moment';

export const dateTimeFormats = [
	'YYYY-MM-DD HH:mm:ss',
	'MM/DD/YYYY hh:mm a',
	'MM/DD/YYYY hh:mma',
];

export const getMomentDateTimeOrString = (
	datetimestr,
	formats = 'YYYY-MM-DD HH:mm:ss'
) => {
	return moment(datetimestr, formats, true).isValid()
		? moment(datetimestr, formats, true)
		: datetimestr;
};

export const getDateTimeString = (datetime) => {
	return moment.isMoment(datetime)
		? moment(datetime).format('YYYY-MM-DD HH:mm:ss')
		: datetime;
};
export const isValidDateTimeString = (
	datetimestr,
	formats = 'YYYY-MM-DD HH:mm:ss'
) => {
	return moment(datetimestr, formats, true).isValid();
};

export const DisplayDateTimeField = (value) => {
	return moment(value).isValid()
		? moment.tz(value, 'UTC').local().format('MM/DD/YYYY hh:mm a')
		: value;
};
export const checkDateIsFuture = (value, formats = 'YYYY-MM-DD HH:mm:ss') => {
	if (moment(value, formats, true).isValid()) {
		const momentcurrent = moment(value, formats);
		return momentcurrent.isAfter(moment());
	}
	return true;
};

export const LastCallDateDiffrence = (value) => {
	const created_at = moment(value, 'YYYY-MM-DD hh:mm:ss').toDate();
	const newDateObj = new Date(
		created_at.getTime() - created_at.getTimezoneOffset() * 60 * 1000
	);
	const actualDate = moment(newDateObj).format('MMM DD, YYYY hh:mm A');

	const current_date = moment(new Date(), 'YYYY-MM-DD hh:mm:ss');
	const currentDate = moment(current_date).format('MMM DD, YYYY hh:mm A');

	const difference =
		new Date(currentDate).getTime() - new Date(actualDate).getTime();
	const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
	const hoursDiff = Math.floor(difference / 1000 / 60 / 60);

	if (hoursDiff < 24) {
		return <span style={{ color: '#e74c3c' }}> {'< 24hrs ago'} </span>;
	} else if (hoursDiff > 24 && hoursDiff < 48) {
		return <span>{'1d ago'} </span>;
	} else {
		const showHours = Math.floor(hoursDiff / 24);
		return <span>{`${showHours}d ago`} </span>;
	}
};
