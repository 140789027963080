//React & Redux
import React from 'react';

const AddEditExternalProviderButtonRow = (props) => {
	const { canSave = false, editingProvider = false, providerCTA } = props;

	const handleSave = () => {
		if (canSave) {
			if (editingProvider) {
				providerCTA('edit');
			} else {
				providerCTA('save');
			}
		}
	};

	const buttonClass = canSave
		? 'modalButton'
		: 'modalButton modalButtonDisabled';
	const buttonText = editingProvider ? 'Save' : 'Add Provider';

	return (
		<div className='externalProviderModalButtonRow'>
			<div
				className={buttonClass}
				style={{ marginLeft: 'auto' }}
				onClick={handleSave}
			>
				{buttonText}
			</div>
		</div>
	);
};

export default AddEditExternalProviderButtonRow;
