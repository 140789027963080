import moment from 'moment';
import React, { useContext } from 'react';

import { useCurrentUser } from '../../../../hooks/useCurrentUser';

import Searchbar from '../../../Filter/Searchbar';
import ResetFilters from '../../../Filter/ResetFilters';

import { ReferralPageContext } from '../../hooks';

import { Assignee } from './Assignee';
import { ReferralProgram } from './ReferralProgram';
import { ConsultType } from './ConsultType';
import { Specialty } from './Specialty';
import { OUTREACH_STATUS, OUTREACH_SUBSTATUS, Status } from './Status';
import { Tags } from './Tags';
import { ReferralDate } from './ReferralDate';

import './Filters.scss';

const FILTERS = {
	ASSIGNEE: 'assigned_ccs',
	CONSULTATIONS_TYPE: 'consultations',
	TEXT: 'q',
	REFERRAL_DATE_FROM: 'referral_date_from',
	REFERRAL_DATE_TO: 'referral_date_to',
	REFERRAL_PROGRAM: 'referral_programs',
	SPECIALTY: 'specialties',
	STATUS: 'status',
	SUBSTATUS: 'substatus',
	TAG: 'tags',
};

export function getDefaultFilters() {
	return {
		[FILTERS.CONSULTATIONS_TYPE]: [],
		[FILTERS.TEXT]: null,
		[FILTERS.REFERRAL_DATE_FROM]: moment()
			.subtract(30, 'days')
			.startOf('day')
			.format('YYYY-MM-DD'),
		[FILTERS.REFERRAL_DATE_TO]: moment().endOf('day').format('YYYY-MM-DD'),
		[FILTERS.REFERRAL_PROGRAM]: [],
		[FILTERS.SPECIALTY]: [],
		[FILTERS.STATUS]: [
			OUTREACH_STATUS.IN_PROGRESS,
			OUTREACH_STATUS.FOLLOWUP_REQUIRED,
			OUTREACH_STATUS.OUTREACH_COMPLETED,
		],
		[FILTERS.SUBSTATUS]: [
			OUTREACH_SUBSTATUS.DO_NOT_OUTREACH,
			OUTREACH_SUBSTATUS.NOT_SCHEDULED,
		],
		[FILTERS.TAG]: [],
		[FILTERS.ASSIGNEE]: [],
	};
}

/**
 * @param {Object} props
 * @param {Object} props.filters
 * @param {(filter: string, value: any) => void} props.setFilter
 */
export function Filters() {
	const { isExternal } = useCurrentUser();
	const { referralDashboardData } = useContext(ReferralPageContext);
	const { filters, setFilter } = referralDashboardData;

	return (
		<div className='filter'>
			<div className='row'>
				<ResetFilters
					handleResetFilters={() =>
						Object.entries(getDefaultFilters()).forEach(([k, v]) =>
							setFilter(k, v)
						)
					}
				/>
				<Searchbar
					setStateFunc={(e) =>
						setFilter(FILTERS.TEXT, e.target.value)
					}
					currentValue={filters[FILTERS.TEXT]}
					useInputEvtObj={true}
					placeholder='Search'
				/>
				<ReferralDate
					onChange={(v) => {
						setFilter(FILTERS.REFERRAL_DATE_FROM, v.from);
						setFilter(FILTERS.REFERRAL_DATE_TO, v.to);
					}}
					value={{
						from: filters[FILTERS.REFERRAL_DATE_FROM],
						to: filters[FILTERS.REFERRAL_DATE_TO],
					}}
				/>
				<ReferralProgram
					onChange={(v) => setFilter(FILTERS.REFERRAL_PROGRAM, v)}
					value={filters[FILTERS.REFERRAL_PROGRAM]}
				/>
				<Status
					onChange={(values) => {
						const v = values.map((vv) => vv.split(';'));
						const status = Array.from(new Set(v.map((i) => i[0])));
						const substatus = v.map((i) => i[1]).filter(Boolean);
						setFilter(FILTERS.STATUS, status);
						setFilter(FILTERS.SUBSTATUS, substatus);
					}}
					value={{
						status: filters[FILTERS.STATUS],
						substatus: filters[FILTERS.SUBSTATUS],
					}}
				/>
				<ConsultType
					onChange={(v) => setFilter(FILTERS.CONSULTATIONS_TYPE, v)}
					value={filters[FILTERS.CONSULTATIONS_TYPE]}
				/>
			</div>
			<div className='row'>
				<Tags
					onChange={(v) => setFilter(FILTERS.TAG, v)}
					value={filters[FILTERS.TAG]}
				/>
				{!isExternal && (
					<Assignee
						onChange={(v) => setFilter(FILTERS.ASSIGNEE, v)}
						value={filters[FILTERS.ASSIGNEE]}
					/>
				)}
				<Specialty
					onChange={(v) => setFilter(FILTERS.SPECIALTY, v)}
					value={filters[FILTERS.SPECIALTY]}
				/>
			</div>
		</div>
	);
}
