//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import ICDSearchResultRow from './ICDSearchResultRow.js';
import ICDSearchResultsRowLoading from './ICDSearchResultsRowLoading.js';

class ICDSearchResults extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.determineIsSelected = this.determineIsSelected.bind(this);
		this.determineAltCheckboxSelection =
			this.determineAltCheckboxSelection.bind(this);
		this.determineCodeFreeText = this.determineCodeFreeText.bind(this);
		this.scrollHandler = this.scrollHandler.bind(this);
	}
	//functions

	componentDidUpdate(prevProps, prevState) {
		if (
			get(prevProps, 'initialQueryLoading', false) &&
			!get(this, 'props.initialQueryLoading', false)
		) {
			if (get(this, 'resultsContainer', null)) {
				setTimeout(
					() =>
						this.props.determineDropShadow(
							['top', 'bottom'],
							get(this, 'resultsContainer', null)
						),
					10
				);
			}
		}
	}

	determineIsSelected(uuid) {
		return (get(this, 'props.selectedICDCodes', []) || []).filter(
			(el) => get(el, 'uuid', '') === uuid
		).length;
	}

	determineAltCheckboxSelection(uuid) {
		const altCheckbox = get(this, 'props.altCheckbox', null);
		const selectedCodes = get(this, 'props.selectedICDCodes', []) || [];
		if (
			altCheckbox &&
			Array.isArray(altCheckbox) &&
			Array.isArray(selectedCodes)
		) {
			const checkboxSelection = [];
			const selectedObj = _.find(
				selectedCodes,
				(code) => get(code, 'uuid', '') === uuid
			);
			altCheckbox.forEach((el) => {
				const internalValue = get(el, 'internal', null);
				if (internalValue && get(selectedObj, internalValue, null))
					checkboxSelection.push(internalValue);
			});
			return checkboxSelection;
		} else return null;
	}

	determineCodeFreeText(uuid) {
		const selectedCodes = get(this, 'props.selectedICDCodes', []) || [];
		const freeTextKey = get(this, 'props.freeTextKey', null) || 'freeText';
		if (Array.isArray(selectedCodes) && uuid) {
			const selectedObj = _.find(
				selectedCodes,
				(code) => get(code, 'uuid', '') === uuid
			);
			return get(selectedObj, freeTextKey, null) || '';
		} else return null;
	}

	scrollHandler(e) {
		const scrollHeight = get(e, 'target.scrollHeight', -1);
		const scrollTop = get(e, 'target.scrollTop', -1);
		const clientHeight = get(e, 'target.clientHeight', -1);

		if (scrollTop === 0) {
			this.props.lazyLoadTop();
		}

		if (scrollHeight === scrollTop + clientHeight) {
			this.props.lazyLoadBottom();
		}

		this.props.determineDropShadow(
			['top', 'bottom'],
			get(e, 'target', null)
		);
	}

	render() {
		const icdCodes = get(this, 'props.icdCodes', []) || [];
		const icdCodesLength = get(icdCodes, 'length', 0) || 0;
		const filter = get(this, 'props.filter', null);
		const containerStyling =
			get(this, 'props.containerStyling', null) || {};
		if (filter) containerStyling.maxHeight = 'calc(100% - 105px)';
		if (get(this, 'props.initialQueryLoading', false)) {
			return (
				<div
					className='chargeICDSearchPlaceholderContainer'
					style={{ position: 'relative' }}
				>
					<Loading
						loading={true}
						style={{ position: 'unset' }}
						className='chargeICDSearchPlaceholderContainerLoading'
					/>
				</div>
			);
		} else {
			return (
				<div
					className='chargeICDSearchResultsContainer'
					ref={(el) => (this.resultsContainer = el)}
					onScroll={this.scrollHandler}
					style={{ ...containerStyling }}
				>
					{get(this, 'props.topLoading', false) && (
						<ICDSearchResultsRowLoading />
					)}
					{icdCodes.map((el, i) => {
						return (
							<ICDSearchResultRow
								icdCode={el}
								key={el.uuid}
								selectedICDCodes={this.props.selectedICDCodes}
								toggleCodeSelection={
									this.props.toggleCodeSelection
								}
								localSearchKey={get(
									this,
									'props.localSearchKey',
									null
								)}
								isSelected={this.determineIsSelected(
									get(el, 'uuid', '')
								)}
								noDescription={get(
									this,
									'props.noDescription',
									false
								)}
								topIntersectionObserver={i === 0}
								bottomIntersectionObserver={
									i === icdCodesLength
								}
								filter={filter}
								reverseVariant={get(
									this,
									'props.reverseVariant',
									false
								)}
								altCheckbox={get(
									this,
									'props.altCheckbox',
									null
								)}
								altCheckboxSelection={this.determineAltCheckboxSelection(
									get(el, 'uuid', '')
								)}
								toggleCodeSelectionAltCheckbox={
									this.props.toggleCodeSelectionAltCheckbox
								}
								freeText={this.determineCodeFreeText(
									get(el, 'uuid', '')
								)}
								handleCodeFreeTextChange={
									this.props.handleCodeFreeTextChange
								}
							/>
						);
					})}
					{get(this, 'props.bottomLoading', false) && (
						<ICDSearchResultsRowLoading />
					)}
				</div>
			);
		}
	}
}

export default ICDSearchResults;
