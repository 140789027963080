// React
import React from 'react';

// Lodash
import { isEmpty, isNull, find, get, filter } from 'lodash';

// Other Libraries
import classnames from 'classnames';
import moment from 'moment';
import { Loading } from 'gm-element-react';

// Images
import ReactSVG from 'react-svg';
import docIcon from './doc.svg';
import excelIcon from './excel.svg';
import imgIcon from './img.svg';
import otherIcon from './other.svg';
import pdfIcon from './pdf.svg';
import userIcon from './user.svg';
import clockIcon from './clock.svg';
import moreinfoicon from './../../../../../assets/purple-more-info.svg';
import maliciousIcon from './malicious.png';

// Components
import CommonCheckbox from './../../../../Common/Checkbox.js';
import ThreeDotsMenu from '../../../../Common/controls/ThreeDotsMenu';
import RBAC from '../../../../RBAC';
import Tooltip from '../../../../Common/Tooltip';

// Utils
import {
	CARE_PATIENTS_PATIENT_DOCUMENT_AUDIT_TRAIL,
	CARE_PATIENTS_PATIENT_DOCUMENT_LOG_RELEASE,
	CARE_PATIENTS_PATIENT_DOCUMENT_RELEASE,
	CARE_PATIENTS_PATIENT_DOCUMENT_SHARE,
	CARE_PATIENTS_PATIENT_DOCUMENT_ARCHIVE,
	CARE_PATIENTS_PATIENT_DOCUMENT_EDIT_DOC,
	CARE_PATIENTS_PATIENT_COMBINE_DOC,
} from '../../../../../Utils/permissionUtils';
import {
	isPartnerInitiatedPreTest,
	isPartnerInitiatedUnsolicitedResult,
} from '../../Encounters/Common/utilsfunctions';

//Utils
import { UTCToCurrentDateView } from '../../../../../utils';

// Styles
import './style.css';

const NewDocumentList = (props) => {
	const {
		archiveFile,
		canBeArchived,
		canReleasedPP,
		canReleasedProvider,
		data,
		encounters,
		handleDocClick,
		isArchived,
		orderBy = null,
		printDocument,
		showAuditTrail,
		showEditDocumentDetail,
		showLogReleaseDocument,
		showReleaseDocument,
		showShareDocument,
		withoutSubtypeVisitDoc,
		downloadDocument,
		selectedCombineDocuments,
		setSelectedCombineDocuments,
	} = props;

	const objectDocs = ['encounter'];
	const arrayDocs = {
		other: 'Other Documents',
		active: orderBy === 'dateAdded' ? 'By Last Added' : 'By Last Modified',
		archived: 'Archived Files',
		visit_documentation: 'Visit Documentation',
		genetic_test_results: 'Genetic Test Results',
		questionnaires: 'Questionnaires',
		pedigree: 'Pedigree',
		administrative: 'Administrative Misc',
		external_records: 'External Records',
		patient_communication: 'Patient Communication',
		insurance_card: 'Insurance card',
		insurance_document: 'Insurance doc',
	};

	const renderHeader = (title) => (
		<div className='header'>
			<label>{title}</label>
		</div>
	);

	const renderFileIcon = (extension, isMalicious, doc) => {
		if (isMalicious) {
			return (
				<div className='document-icon' alt='malicious icon'>
					<img src={maliciousIcon} className='malicious-icon' />
				</div>
			);
		}

		const iconMapper = {
			'(gif|jpe?g|tiff?|png|webp|bmp|img)$': imgIcon,
			'(pdf)$': pdfIcon,
			'(doc)$': docIcon,
			'(excel|xls|xlsx)$': excelIcon,
		};
		let icon = otherIcon;

		for (const pattern in iconMapper) {
			const regex = new RegExp(pattern, 'i');
			if (regex.test(extension)) {
				icon = iconMapper[pattern];
				break;
			}
		}

		return (
			<ReactSVG
				className='document-icon'
				style={
					extension !== 'pdf' || isArchived(doc)
						? { marginLeft: '28px' }
						: {}
				}
				src={icon}
				alt={`${extension} icon`}
			/>
		);
	};

	const renderThreeDots = (doc) => {
		if (doc.is_malicious !== false) return null;

		const gDoc = doc.is_editable;

		return (
			<ThreeDotsMenu
				directRender={true}
				options={
					isArchived(doc) ? (
						<ul className='three-dots-menu'>
							<RBAC
								action={
									CARE_PATIENTS_PATIENT_DOCUMENT_AUDIT_TRAIL
								}
								yes={
									<li
										data-testId='archived-audit-trail-btn'
										onClick={(e) => showAuditTrail(e, doc)}
									>
										Audit Trail
									</li>
								}
							/>
						</ul>
					) : (
						<ul className='three-dots-menu'>
							<RBAC
								action={CARE_PATIENTS_PATIENT_DOCUMENT_EDIT_DOC}
								yes={
									<li
										data-testId='edit-document-details-btn'
										onClick={(e) =>
											showEditDocumentDetail(e, doc)
										}
									>
										Edit Document Details
									</li>
								}
							/>
							{!gDoc && (
								<li
									data-testId='print-btn'
									onClick={(e) => printDocument(e, doc)}
								>
									Print
								</li>
							)}
							{!gDoc && (
								<li
									data-testId='print-btn'
									onClick={(e) => downloadDocument(e, doc)}
								>
									Download
								</li>
							)}
							{
								<RBAC
									action={
										CARE_PATIENTS_PATIENT_DOCUMENT_LOG_RELEASE
									}
									yes={
										!gDoc && (
											<li
												data-testId='log-manual-release-btn'
												onClick={(e) =>
													showLogReleaseDocument(
														e,
														doc
													)
												}
											>
												Log Manual Release
											</li>
										)
									}
								/>
							}
							{
								<RBAC
									action={
										CARE_PATIENTS_PATIENT_DOCUMENT_RELEASE
									}
									yes={
										!gDoc &&
										(canReleasedPP(doc) ||
											canReleasedProvider(doc)) && (
											<>
												{withoutSubtypeVisitDoc(doc) ? (
													<li className='popovr-segment'>
														<span className='without-subtype-disable'>
															Release Document
														</span>
														<span className='required-subtype-tooltip'>
															<Tooltip
																effect='light'
																content={
																	<div
																		style={{
																			whiteSpace:
																				'pre-wrap',
																		}}
																	>
																		Visit
																		Document
																		sub type
																		needs to
																		be
																		selected
																		for the
																		associated
																		Google
																		Doc in
																		order to
																		release
																		this
																		document
																	</div>
																}
																placement='top'
															>
																<img
																	src={
																		moreinfoicon
																	}
																/>
															</Tooltip>
														</span>
													</li>
												) : (
													<li
														data-testId='release-document-btn'
														onClick={(e) =>
															showReleaseDocument(
																e,
																doc
															)
														}
													>
														Release Document
													</li>
												)}
											</>
										)
									}
								/>
							}
							{
								<RBAC
									action={
										CARE_PATIENTS_PATIENT_DOCUMENT_SHARE
									}
									yes={
										<li
											data-testId='share-document-btn'
											onClick={(e) =>
												showShareDocument(e, doc)
											}
										>
											Share Internally
										</li>
									}
								/>
							}
							{
								<RBAC
									action={
										CARE_PATIENTS_PATIENT_DOCUMENT_AUDIT_TRAIL
									}
									yes={
										!gDoc && (
											<li
												data-testId='audit-trail-btn'
												onClick={(e) =>
													showAuditTrail(e, doc)
												}
											>
												Audit Trail
											</li>
										)
									}
								/>
							}
							{canBeArchived(doc) && (
								<RBAC
									action={
										CARE_PATIENTS_PATIENT_DOCUMENT_ARCHIVE
									}
									yes={
										<li
											onClick={(e) => archiveFile(e, doc)}
										>
											Archive
										</li>
									}
								/>
							)}
							{
								<RBAC
									action={
										CARE_PATIENTS_PATIENT_DOCUMENT_AUDIT_TRAIL
									}
									yes={
										gDoc && (
											<li
												data-testId='gdoc-audit-trail-btn'
												onClick={(e) =>
													showAuditTrail(e, doc)
												}
											>
												Audit Trail
											</li>
										)
									}
								/>
							}
						</ul>
					)
				}
			/>
		);
	};

	const isDocModified = (doc) => {
		if (orderBy === 'dateAdded') {
			return false;
		} else if (
			orderBy === 'lastModified' ||
			doc.created_at !== doc.updated_at
		) {
			return true;
		}
		return false;
	};

	const getDocTime = (doc) => {
		const isModified = isDocModified(doc);
		let time = isModified ? 'Modified ' : 'Added ';
		const key = isModified ? 'updated_at' : 'created_at';
		const docTimeFromNow = moment
			.duration(moment().diff(moment(doc[key])))
			.asMinutes();

		if (docTimeFromNow < 60) {
			time += `${parseInt(docTimeFromNow)} mins ago`;
		} else if (docTimeFromNow >= 60 && docTimeFromNow < 1440) {
			time += `${parseInt(docTimeFromNow / 60)} hrs ago`;
		} else if (docTimeFromNow >= 1440 && docTimeFromNow < 10080) {
			time += `${parseInt(docTimeFromNow / 1440)} days ago`;
		} else {
			time += moment(doc[key]).format('MMM D, YYYY');
		}

		return time;
	};

	const renderDocTimeTooltip = (doc) => (
		<>
			<div>
				Added On:
				<br />
				{moment(doc.created_at).format('MMM D, YYYY hh:mm A')}
			</div>
			{isDocModified(doc) && (
				<>
					<div className='separator' />
					<div>
						Last Modified:
						<br />
						{moment(doc.updated_at).format('MMM D, YYYY hh:mm A')}
					</div>
				</>
			)}
		</>
	);

	const getEncounterSpecificPatientDate = (dos, timezone, type) => {
		const tz = type == 'research-data' ? 'America/New_York' : timezone;
		return UTCToCurrentDateView(moment, moment(dos), tz);
	};

	const onSelectPDFDocCheckboxClick = (doc) => {
		let isDocumentSelected = [...selectedCombineDocuments];
		const docUUID = doc?.uuid;
		const isDocumentPresent = find(
			isDocumentSelected,
			(o) => o.uuid === docUUID
		);
		if (!isDocumentPresent) {
			isDocumentSelected.push({
				uuid: doc.uuid,
				file_name: doc.file_name,
			});
		} else {
			isDocumentSelected = filter(isDocumentSelected, function (d) {
				return d.uuid !== doc?.uuid;
			});
		}
		setSelectedCombineDocuments(isDocumentSelected);
	};

	const getDocumentCheckState = (uuid, all_selected) => {
		const checked_record = find(all_selected, function (o) {
			return o.uuid === uuid;
		});
		return checked_record ? true : false;
	};

	const renderDocumentRow = (doc) => (
		<div className='document-row-container'>
			<RBAC
				action={CARE_PATIENTS_PATIENT_COMBINE_DOC}
				yes={
					doc?.file_extension_id === 'pdf' && !isArchived(doc) && (
						<CommonCheckbox
							id={doc?.id}
							checked={getDocumentCheckState(
								doc?.uuid,
								selectedCombineDocuments
							)}
							onChangeFunc={() =>
								onSelectPDFDocCheckboxClick(doc)
							}
							disabled={isNull(doc.is_malicious)}
						/>
					)
				}
			/>
			<div
				onClick={() => handleDocClick(doc)}
				className='document-row-noncheckbox-section'
			>
				{renderFileIcon(
					doc.file_extension_id || null,
					doc.is_malicious || null,
					doc
				)}
				<div className='display-content'>
					<div
						className={classnames({
							italic: doc.visit_document_status === 'draft',
						})}
					>
						<div
							className={classnames('file-name', {
								'is-archived': !!doc.deleted_at,
							})}
						>
							{doc.file_name || '--'}
						</div>
						<div className='patient-info'>
							<Tooltip
								placement='top'
								content={
									<div>
										Added By:
										<br />
										<span>
											{(doc.created_by?.first_name || '') +
												(doc.created_by?.last_name
													? ' ' + doc.created_by.last_name
													: '')}
										</span>
									</div>
								}
							>
								<ReactSVG src={userIcon} alt={'user icon'} />
								<span>
									{(doc.created_by?.first_name || '') +
										(doc.created_by?.last_name
											? ' ' + doc.created_by.last_name
											: '')}
								</span>
							</Tooltip>
							<Tooltip
								placement='top'
								content={renderDocTimeTooltip(doc)}
							>
								<ReactSVG src={clockIcon} alt={'clock icon'} />
								{getDocTime(doc)}
							</Tooltip>
						</div>
						<div className='description'>{doc.description}</div>
					</div>
					<div
						className={classnames({
							'doc-status': doc.visit_document_status,
							yellow: doc.visit_document_status === 'draft',
							green: doc.visit_document_status === 'finalized',
							orange: doc.visit_document_status === 'amended',
						})}
					>
						{doc.visit_document_status}
					</div>
				</div>
				<Loading
					className='document-row-loader'
					loading={isNull(doc.is_malicious)}
				>
					<div className='three-dots-wrapper' />
				</Loading>
				{renderThreeDots(doc)}
			</div>
		</div>
	);

	const renderList = (docs) => (
		<div className='document-list'>
			{(docs || []).map((doc) => (
				<div
					data-testId='doc-list-item'
					key={doc.uuid}
					className={classnames({
						'is-malicious': doc.is_malicious,
						scanning: isNull(doc.is_malicious),
					})}
				>
					{doc.is_malicious ? (
						<Tooltip
							placement='top'
							content='This file contains a virus and is blocked'
						>
							{renderDocumentRow(doc)}
						</Tooltip>
					) : isNull(doc.is_malicious) ? (
						<Tooltip placement='top' content='Scanning for viruses'>
							{renderDocumentRow(doc)}
						</Tooltip>
					) : (
						renderDocumentRow(doc)
					)}
				</div>
			))}
		</div>
	);

	const renderDocsFromObject = () => {
		for (const key of objectDocs) {
			const docsObject = data?.[key] || {};
			if (isEmpty(docsObject)) return null;
			return docsObject.map((obj) => (
				<div key={key}>
					{renderHeader(
						`${
							isPartnerInitiatedPreTest(
								find(encounters, ['uuid', obj.uuid])
							)
								? 'TRO'
								: isPartnerInitiatedUnsolicitedResult(
										find(encounters, ['uuid', obj.uuid])
								  )
								? 'TRO - Results Only'
								: !obj.consultation_type && obj.type === 'scp'
								? 'SCP'
								: !obj.consultation_type &&
								  obj.type === 'lab_test_authorization'
								? 'Lab'
								: obj.consultation_type || 'N/A'
						} ${
							obj.date_of_service
								? `- ${getEncounterSpecificPatientDate(
										obj.date_of_service,
										get(obj, ['timezone'], ''),
										get(obj, 'type', '')
								  )}`
								: ''
						}`
					)}
					{renderList(obj.documents)}
				</div>
			));
		}
	};

	const renderDocsFromArray = () =>
		Object.entries(arrayDocs).map(([key, title]) => {
			const docs = data?.[key] || [];
			if (!isEmpty(docs)) {
				return (
					<div key={key}>
						{renderHeader(title)}
						{renderList(docs)}
					</div>
				);
			}

			return null;
		});

	return (
		<div className='groups-list'>
			{renderDocsFromObject()}
			{renderDocsFromArray()}
		</div>
	);
};

export default NewDocumentList;
