//React & Redux
import React, { Component, Fragment } from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from '../../../../assets/exit-alert.svg';
import breadcrumbArrow from '../../../../assets/menu-breadcrumb.svg';
import { getMomentDateOrStringNonStrick } from '../Encounters/Common/utilsfunctions';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Button, Loading, Tabs } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';

//Components
import PreAuthTestRequestModal from './PreAuthTestRequestModal';
import ICDSelectionComponent from '../../ChargeInformation/ICDSelectionComponent';

//Styles
import './create-test-request-modal.css';

const CREATEFORMSTEP = 'createform';
const EDITFORMSTEP = 'editform';
const ICDSTEP = 'icd';
const datefields = {
	dispatch_date: 1,
};
const RequiredFields = [
	'lab_uuid',
	'genetic_test_uuid',
	'request_decision',
	'alternate_lab_uuid',
	'alternate_test_uuid',
];
const cascaderfields = {
	dispatch_status: 1,
};

class PreAuthTestOrderModalContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentStep:
				_.get(this, ['props', 'testRequestMode'], '') === 'create'
					? CREATEFORMSTEP
					: EDITFORMSTEP,
			formdata: {},
			userSelectedICDCodes: [],
			selectedICDCodes: [],
			loading: false,
			tabvalue: 'requestInfoTab',
		};
		this.onFormChange = this.onFormChange.bind(this);
		this.savePreAuthData = this.savePreAuthData.bind(this);
		this.setSelectedICDCodes = this.setSelectedICDCodes.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.isSubmitDisabled = this.isSubmitDisabled.bind(this);
		this.handleDateFieldValidate = this.handleDateFieldValidate.bind(this);
		this.closePopup = this.closePopup.bind(this);
		this.editPreAuthData = this.editPreAuthData.bind(this);
	}
	componentDidMount() {
		let formdata = {};

		if (
			_.get(this, ['props', 'testRequestMode'], '') === 'edit' &&
			!_.isEmpty(_.get(this, ['props', 'testorder'], {}))
		) {
			formdata = {
				lab_uuid: _.get(this, ['props', 'testorder', 'lab_uuid'], ''),
				genetic_test_uuid: _.get(
					this,
					['props', 'testorder', 'genetic_test_uuid'],
					''
				),
				test_name: _.get(this, ['props', 'testorder', 'test_name'], ''),
				request_decision: _.get(
					this,
					['props', 'testorder', 'request_decision'],
					''
				),
				dispatch_status: _.get(
					this,
					['props', 'testorder', 'dispatch_status'],
					''
				),
				dispatch_date: _.get(
					this,
					['props', 'testorder', 'dispatch_date'],
					null
				),
				alternate_lab_uuid: _.get(
					this,
					['props', 'testorder', 'alternate_lab_uuid'],
					null
				),
				alternate_test_uuid: _.get(
					this,
					['props', 'testorder', 'alternate_test_uuid'],
					null
				),
			};

			const updatedformdata = _.mapValues(
				formdata,
				function (value, key) {
					if (key in datefields)
						return getMomentDateOrStringNonStrick(value);
					return value;
				}
			);
			this.setSelectedICDCodes(
				_.get(this, ['props', 'testorder', 'medical_codes'], [])
			);
			this.setState({
				formdata: updatedformdata,
				errors: {},
			});

			this.props.LoadTestOrderPopupTestEnum(
				_.get(this, ['props', 'testorder', 'lab_uuid'], '')
			);
			this.props.LoadAlternateTestEnum(
				_.get(this, ['props', 'testorder', 'alternate_lab_uuid'], '')
			);
		} else {
			formdata = {
				lab_uuid: undefined,
				genetic_test_uuid: undefined,
				request_decision: undefined,
				alternate_lab_uuid: undefined,
				alternate_test_uuid: undefined,
			};

			const _encounterICDcodes = _.get(
				this,
				['props', 'encounterICDcodes'],
				[]
			);
			const selectedICDCodes = _encounterICDcodes.map((obj) => {
				return obj;
			});

			this.setState({
				formdata,
				errors: {},
				selectedICDCodes: selectedICDCodes,
			});
		}
	}

	handleChangeTab = (tab) => {
		const currentStep = tab === 'requestInfoTab' ? EDITFORMSTEP : ICDSTEP;
		this.setState({
			currentStep: currentStep,
			tabvalue: tab,
		});
	};

	onFormChange = (key, value) => {
		const formdata = _.get(this, ['state', 'formdata'], {});
		const errors = this.validateForm(formdata);
		formdata[key] = value;

		if (key == 'lab_uuid') {
			this.props.LoadTestOrderPopupTestEnum(value);
			formdata[key] = value;
			formdata['genetic_test_uuid'] = '';
		}

		if (key == 'alternate_lab_uuid') {
			this.props.LoadAlternateTestEnum(value);
			formdata[key] = value;
			formdata['alternate_test_uuid'] = '';
		}

		if (key in cascaderfields) {
			formdata[key] = _.last(value);
		}

		this.setState({ formdata, errors });
	};

	handleDateFieldValidate = (key, value) => {
		const formdata = _.get(this, ['state', 'formdata'], {});
		formdata[key] = value;
		const errors = this.validateForm(formdata);
		this.setState({ errors, formdata });
	};

	setSelectedICDCodes(selectedICDCodes) {
		if (selectedICDCodes && Array.isArray(selectedICDCodes)) {
			this.setState({ selectedICDCodes: selectedICDCodes.slice() });
		}
	}

	savePreAuthData = () => {
		this.setState({
			userSelectedICDCodes: this.state.selectedICDCodes,
		});

		let formdata = _.get(this, ['state', 'formdata'], {});
		const medical_codes = this.state.selectedICDCodes.map((obj) => ({
			code_name: obj.code_name,
			uuid: obj.uuid,
		}));

		if (formdata['request_decision'] === 'patient_pending_pa') {
			formdata = {
				...formdata,
				dispatch_status: 'waiting_to_submit',
				dispatch_status_reason: 'awaiting_information_patient',
			};
		} else {
			formdata = {
				...formdata,
				dispatch_status: 'waiting_to_submit',
				dispatch_status_reason: 'awaiting_cc_submission',
			};
		}

		const data = {
			...formdata,
			dispatch_date: null,
			medical_codes: medical_codes,
		};
		data['genetic_test_uuid'] = _.get(
			this,
			['state', 'formdata', 'genetic_test_uuid'],
			''
		);

		if (
			_.get(this, ['state', 'formdata', 'request_decision'], '') !=
			'requested_alternate_pa_atr'
		) {
			delete data['alternate_test_uuid'];
			delete data['alternate_lab_uuid'];
		}

		this.setState({ loading: true });
		this.props
			.createPreauthorizeTestOrder(data)
			.then((res) => {
				this.setState({ loading: false });
				this.props.hidePreAuthTestModal();
				this.props.closeEditTestOrder();
			})
			.catch((error) => {
				this.setState({ loading: false });
			});
	};

	editPreAuthData = () => {
		const formdata = this.state.formdata;
		const errors = this.validateForm(formdata);
		if (_.isEmpty(errors)) {
			const { testorder } = this.props;

			const medical_codes = this.state.selectedICDCodes.map((obj) => ({
				code_name: obj.code_name,
				uuid: obj.uuid,
			}));

			let newdata = { ...formdata };

			for (const key in newdata) {
				if (newdata[key] == '') {
					newdata[key] = null;
				}
			}

			newdata = {
				...newdata,
				medical_codes,
			};

			const preauthdispatchstatusenums = _.get(
				this,
				['props', 'preauthdispatchstatusenums'],
				[]
			);

			const temporderstatus = _.find(
				preauthdispatchstatusenums,
				function (o) {
					return o.key == _.get(formdata, 'dispatch_status');
				}
			);

			if (temporderstatus) {
				newdata['dispatch_status'] = _.get(temporderstatus, 'key', '');
				newdata['dispatch_status_reason'] = null;
			} else {
				const tempstatusreson = _.find(
					preauthdispatchstatusenums,
					function (o) {
						return _.find(o.children, function (x) {
							return x.key == _.get(formdata, 'dispatch_status');
						});
					}
				);
				newdata['dispatch_status'] = _.get(tempstatusreson, 'key', '');
				newdata['dispatch_status_reason'] = _.get(
					formdata,
					'dispatch_status'
				);
			}

			const propsdecisionstatus = _.get(
				this,
				['props', 'testorder', 'request_decision'],
				null
			);
			const stateorderstatus = _.get(
				this,
				['props', 'formdata', 'request_decision'],
				{}
			);

			if (
				propsdecisionstatus == 'patient_pending_pa' &&
				stateorderstatus != 'patient_pending_pa'
			) {
				newdata = {
					...newdata,
					dispatch_status: 'waiting_to_submit',
					dispatch_status_reason: 'awaiting_cc_submission',
				};
			}

			delete newdata['alternate_lab_uuid'];

			this.setState({ loading: true });

			return this.props
				.updatePreAuthTestOrder(
					testorder.preauth_test_order_uuid,
					newdata
				)
				.then((data) => {
					this.setState({ loading: false });
					this.props.hideModal();
				})
				.catch((error) => {
					this.setState({ loading: false });
				});
		} else {
			this.setState({ errors });
		}
	};

	validateForm = (formdata) => {
		const errors = {};

		if (_.isEmpty(formdata['lab_uuid'])) {
			errors['lab_uuid'] = 'Lab is required';
		}

		if (_.isEmpty(formdata['genetic_test_uuid'])) {
			errors['genetic_test_uuid'] = 'Test Name is required';
		}

		if (_.isEmpty(formdata['request_decision'])) {
			errors['request_decision'] =
				'Pre-Authorization Decision is required';
		}

		if (
			!_.isEmpty(formdata['request_decision']) &&
			formdata['request_decision'] === 'requested_alternate_pa_atr' &&
			_.isEmpty(formdata['alternate_lab_uuid'])
		) {
			errors['request_decision'] = 'Alternate Lab is required';
		}

		if (
			!_.isEmpty(formdata['request_decision']) &&
			formdata['request_decision'] === 'requested_alternate_pa_atr' &&
			_.isEmpty(formdata['alternate_test_uuid'])
		) {
			errors['request_decision'] = 'Alternate Test Name is required';
		}

		if (
			!_.isEmpty(formdata['dispatch_status']) &&
			formdata['dispatch_status'] == 'pa_form_sent' &&
			_.get(formdata, ['dispatch_date', 'length'], 0) == 0
		) {
			errors['dispatch_date'] =
				'Pre-Authorization Dispatch Date is required';
		}

		return errors;
	};

	isNextEnabled = () => {
		const formdata = _.get(this, ['state', 'formdata'], {});
		const errors = this.validateForm(formdata);
		return !_.isEmpty(errors);
	};

	isSubmitDisabled = () => {
		//const errors = _.get(this, ['state', 'errors'], {});

		const formdata = _.get(this, ['state', 'formdata'], {});
		const errors = this.validateForm(formdata);
		return !_.isEmpty(errors);
	};

	closePopup = () => {
		this.props.hidePreAuthTestModal();
		this.props.closeEditTestOrder();
	};

	preAuthTestRequestModal = () => {
		const testRequestMode = _.get(this, ['props', 'testRequestMode'], '');

		return (
			<PreAuthTestRequestModal
				testRequestMode={testRequestMode}
				labenums={_.get(this, ['props', 'labenums'], [])}
				nonlegacytestOrderPopupTestEnums={_.get(
					this,
					['props', 'nonlegacytestOrderPopupTestEnums'],
					[]
				)}
				nonlegacyalternatetestnameenums={_.get(
					this,
					['props', 'nonlegacyalternatetestnameenums'],
					[]
				)}
				selectedTestRequestFields={_.get(
					this,
					['state', 'selectedTestRequestFields'],
					{}
				)}
				userSelectedICDCodes={_.get(
					this,
					['state', 'selectedICDCodes'],
					null
				)}
				formdata={_.get(this, ['state', 'formdata'], {})}
				onFormChange={this.onFormChange}
				handleDateFieldValidate={this.handleDateFieldValidate}
				visitsschema={_.get(this, ['props', 'visitsschema'], {})}
				testorder={_.get(this, ['props', 'testorder'], {})}
				testnameenums={_.get(this, ['props', 'testnameenums'], [])}
				encounter={_.get(this, ['props', 'encounter'], {})}
				patientdetail={_.get(this, ['props', 'patientdetail'], {})}
				preauthdispatchstatusenums={_.get(
					this,
					['props', 'preauthdispatchstatusenums'],
					[]
				)}
				preauthdecisionenums={_.get(
					this,
					['props', 'preauthdecisionenums'],
					[]
				)}
			/>
		);
	};

	ICDSelectionComponent = () => {
		return (
			<ICDSelectionComponent
				icdCodesError={this.props.icdCodesError}
				icdCodesLoading={this.props.icdCodesLoading}
				icdCodesPlacement={this.props.icdCodesPlacement}
				icdCodes={this.props.icdCodes}
				selectedICDCodes={this.state.selectedICDCodes}
				getICDCodes={this.props.getICDCodes}
				enqueueSnackbar={this.props.enqueueSnackbar}
				setSelectedICDCodes={this.setSelectedICDCodes}
				encounterICDcodes={_.get(
					this,
					['props', 'encounterICDcodes'],
					[]
				)}
			/>
		);
	};

	render() {
		const testRequestMode = _.get(this, ['props', 'testRequestMode'], '');

		return (
			<div className={classnames('createTestOrderModalContainer ')}>
				<Loading loading={_.get(this, ['state', 'loading'], false)}>
					<div className='edit-test-order-modal-header-wrapper'>
						<div className='createTestOrderModalHeader'>
							{testRequestMode !== 'create'
								? 'Edit Pre-Authorization Request'
								: 'Request a Pre-Authorization'}
							{testRequestMode === 'create' && (
								<div className='createTestOrderModalBreadcrumbs'>
									<span
										className={
											this.state.currentStep ==
												CREATEFORMSTEP ||
											this.state.currentStep ==
												EDITFORMSTEP
												? 'createTestOrderModalBreadcrumbsCurrent'
												: ''
										}
									>
										{testRequestMode === 'create'
											? 'Enter Request Information'
											: 'Edit Request Information'}
									</span>
									<ReactSVG
										src={breadcrumbArrow}
										className='createTestOrderModalBreadcrumbArrow'
									/>
									<span
										className={
											this.state.currentStep == ICDSTEP
												? 'createTestOrderModalBreadcrumbsCurrent'
												: ''
										}
									>
										{_.get(
											this,
											['props', 'testRequestMode'],
											''
										) === 'create'
											? 'Select Test Request ICD-10 Code(s)'
											: this.state.currentStep === ICDSTEP
											? 'Edit Pre-Authorization ICD-10 Code(s)'
											: 'Select Pre-Authorization ICD-10 Code(s)'}
									</span>
								</div>
							)}
						</div>
						<div className='createTestOrderModalClose1 edit-test-order-modal-close'>
							<ReactSVG src={xIcon} onClick={this.closePopup} />
						</div>
					</div>
					<div className='edit-test-rqst-dialog-body'>
						{testRequestMode === 'create' ? (
							this.state.currentStep == CREATEFORMSTEP ||
							this.state.currentStep == EDITFORMSTEP ? (
								this.preAuthTestRequestModal()
							) : (
								this.ICDSelectionComponent()
							)
						) : (
							<Tabs
								activeName={this.state.tabvalue}
								className='edit-test-order-tabs-wrapper'
								onTabClick={(tab) =>
									this.handleChangeTab(tab.props.name)
								}
							>
								<Tabs.Pane
									label='Request Info'
									name='requestInfoTab'
								>
									{this.preAuthTestRequestModal()}
								</Tabs.Pane>
								<Tabs.Pane
									label='ICD-10 Codes'
									name='ICD10CodesTab'
								>
									{this.ICDSelectionComponent()}
								</Tabs.Pane>
							</Tabs>
						)}
					</div>
					<div className='createTestOrderModalButtonRow'>
						{testRequestMode === 'create' &&
							this.state.currentStep == CREATEFORMSTEP && (
								<Button
									className='createTestOrderModalActionButton'
									disabled={this.isNextEnabled()}
									onClick={() => {
										this.setState({ currentStep: ICDSTEP });
									}}
								>
									Next
								</Button>
							)}
						{testRequestMode === 'create' &&
							this.state.currentStep != CREATEFORMSTEP && (
								<Fragment>
									<Button
										className='createTestOrderModalBackButton'
										onClick={() => {
											this.setState({
												currentStep: CREATEFORMSTEP,
											});
										}}
									>
										{' '}
										BACK
									</Button>
									<Button
										className='createTestOrderModalActionButton'
										onClick={this.savePreAuthData}
									>
										Save
									</Button>
								</Fragment>
							)}
						{testRequestMode !== 'create' && (
							<Button
								className='createTestOrderModalActionButton'
								disabled={this.isSubmitDisabled()}
								onClick={this.editPreAuthData}
							>
								Save & Close
							</Button>
						)}
					</div>
				</Loading>
			</div>
		);
	}
}

export default PreAuthTestOrderModalContainer;
