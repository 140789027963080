import { FormikErrors, FormikTouched, useFormikContext } from 'formik';

type ISetSectionFieldValue<T> = (field: keyof T, value: unknown) => void;

/**
 * Hook which provides necessary props for the section to handle interaction
 * with the form
 */
export function useSection<
	TSchema extends Record<string, unknown>,
	TKey extends keyof TSchema,
>(
	name: TKey
): {
	allValues: TSchema;
	values: TSchema[TKey];
	errors: FormikErrors<TSchema[TKey]>;
	touched: FormikTouched<TSchema[TKey]>;
	onChange: (key: keyof TSchema[TKey]) => (value: unknown) => void;
	setFieldValue: ISetSectionFieldValue<TSchema[TKey]>;
} {
	const { values, errors, touched, setFieldValue } =
		useFormikContext<TSchema>();

	const setSectionFieldValue: ISetSectionFieldValue<TSchema[TKey]> = (
		field,
		value
	) => {
		void setFieldValue(`${String(name)}.${String(field)}`, value);
	};

	return {
		allValues: values,
		values: values[name],
		errors: errors[name] ?? {},
		touched: touched[name] ?? {},
		onChange: (f) => (v) => {
			setSectionFieldValue(f, v);
		},
		setFieldValue: setSectionFieldValue,
	};
}
