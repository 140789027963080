//React & Redux
import React, { Component } from 'react';
// Lodash
import { get } from 'lodash';

// UI Libraries
import { Loading, Tabs } from 'gm-element-react';

// Other Libraries
import moment from 'moment';

// Components
import CreateTestRequestEncntrInfo from './CreateTestRequestEncntrInfo';
import TestRequestInfoTab from './TestRequestInfoTab';

// TODO
const statusEnum = {
	approved: 1,
	awaiting_cc_submission: 1,
	sent_to_lab: 1,
	sample_not_submitted: 1,
	patient_not_responsive: 1,
	order_cancelled_by_patient: 1,
	order_cancelled_by_provider: 1,
	order_cancelled_by_lab: 1,
	results_ready: 1,
	results_entered: 1,
	result_positive_no_genes: 1,
	result_positive_with_genes: 1,
	result_negative: 1,
	awaiting_cc_submission: 1,
};

export default class EncounterAuditTrail extends Component {
	state = {
		lastUpdated: null,
		loading: true,
	};

	componentDidMount() {
		setTimeout(() => {
			this.setState({ loading: false });
		}, 2000);
	}

	render() {
		const logsLoaded =
			!this.props.auditTrailEncounterLoading &&
			!this.props.auditTrailEncounterError &&
			this.props.auditTrailEncounter.length;
		return (
			<div className='encounter-audit-trail-wrapper'>
				<Loading loading={this.state.loading} className='loader'>
					<div className='last-updated'>
						Last Updated On{' '}
						{moment().local().format('MMM DD, YYYY hh:mma')}
					</div>
					<div className='logs'>
						<div>
							<div className='title'>Processing Sample</div>
							<div>By Ambry Genetics</div>
							<div>On Apr 30th, 2020 1:05pm</div>
						</div>
					</div>
				</Loading>
				{get(this, ['props', 'testorder', 'localorderstatus'], null) in
				statusEnum ? (
					<Tabs
						key={get(this, ['props', 'tabvalue'], '')}
						activeName='testRequestInfoTab'
						className='test-order-encntr-testrequest-tabs-wrapper'
					>
						<Tabs.Pane label='Encounter' name='encntrInfoTab'>
							<CreateTestRequestEncntrInfo
								encounter={get(
									this,
									['props', 'encounter'],
									{}
								)}
								encounterschema={get(
									this,
									['props', 'encounterschema'],
									{}
								)}
								patientdetail={get(
									this,
									['props', 'patientdetail'],
									{}
								)}
							/>
						</Tabs.Pane>
						<Tabs.Pane
							label='Test Request'
							name='testRequestInfoTab'
						>
							<TestRequestInfoTab
								testorder={get(
									this,
									['props', 'testorder'],
									{}
								)}
								ordering_physicianenums={get(
									this,
									['props', 'ordering_physicianenums'],
									[]
								)}
								selectedICDCodes={this.props.selectedICDCodes}
								sampletypeenums={get(
									this,
									['props', 'sampletypeenums'],
									[]
								)}
								patientbillingmethodenums={get(
									this,
									['props', 'patientbillingmethodenums'],
									[]
								)}
								formdata={get(this, ['props', 'formdata'], {})}
							/>
						</Tabs.Pane>
					</Tabs>
				) : (
					<CreateTestRequestEncntrInfo
						encounter={get(this, ['props', 'encounter'], {})}
						encounterschema={get(
							this,
							['props', 'encounterschema'],
							{}
						)}
						patientdetail={get(
							this,
							['props', 'patientdetail'],
							{}
						)}
					/>
				)}
			</div>
		);
	}
}
