import axios from 'axios';

class UserAccounts {
	getPartnersList = () => {
		return axios.get('/api/enums/partners');
	};

	createUser = (data) => {
		return axios.post(`/api/users`, data);
	};

	updateUser = (data, uuid) => {
		return axios.patch(`/api/users/${uuid}`, data);
	};

	deleteUser = (uuid, data) => {
		return axios.delete(`/api/users/${uuid}`, data);
	};

	getAuditTrail = (userUUID) => {
		return axios.get(`/api/users/${userUUID}/history`);
	};
}

export default new UserAccounts();
