//React & Redux
import React, { useState } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	getEncounterSpecificTime,
	createEnumsOptions,
	isValidDateStringOREmpty,
	getMomentDateOrStringNonStrick,
	DatePickerValue,
} from '../Common/utilsfunctions';

//UI Libraries
import { Button, Loading } from 'gm-element-react';

//Components
import { EncounterInputField } from '../Common/EncounterInputField';
import { EncounterDateField } from '../Common/EncounterDateField';
import { EncounterMultiSelectField } from '../Common/EncounterMultiSelectField';

const datefields = {
	cap_sent_date_pcp: 1,
};

const EditVisitDetailSegment = (props) => {
	const { encounter, saveLabEncounter, cancel, labsschema } = props;

	const tempFormData = {
		cap_sent_date_pcp: _.get(encounter, ['cap_sent_date_pcp'], ''),
		cap_release_method_pcp: _.get(
			encounter,
			['cap_release_method_pcp'],
			[]
		),
		state_of_visit: _.get(encounter, ['state_of_visit'], ''),
	};
	/* some time we are getting date and time in datetime fields so we are converting it */
	const updatedformdata = _.mapValues(tempFormData, function (value, key) {
		if (key in datefields) return getMomentDateOrStringNonStrick(value);
		return value;
	});

	const [formData, setFormData] = useState(updatedformdata);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	const validateForm = (formdata) => {
		let errors = {};
		const dateerors = _.omitBy(
			_.mapValues(datefields, function (value, key) {
				if (!isValidDateStringOREmpty(_.get(formdata, key, ''))) {
					return 'Invalid date';
				}
				return undefined;
			}),
			_.isUndefined
		);
		errors = { ...dateerors };

		if (
			_.isEmpty(_.get(formdata, 'cap_sent_date_pcp', '')) &&
			!_.isEmpty(_.get(formdata, 'cap_release_method_pcp', []))
		) {
			errors['cap_sent_date_pcp'] = 'CAP sent date (PCP) is required';
		}

		if (
			_.isEmpty(_.get(formdata, 'cap_release_method_pcp', '')) &&
			!_.isEmpty(_.get(formdata, 'cap_sent_date_pcp', []))
		) {
			errors['cap_release_method_pcp'] =
				'CAP release method (PCP) is required';
		}
		return errors;
	};

	const saveForm = () => {
		const errors = validateForm(formData);
		if (_.isEmpty(errors)) {
			const updatedformdata = _.mapValues(
				formData,
				function (value, key) {
					if (key in datefields && _.isNil(value)) {
						return '';
					}
					if (value == 'empty') {
						return '';
					}
					return value;
				}
			);

			setLoading(true);
			saveLabEncounter(encounter, updatedformdata)
				.then((result) => {
					setTimeout(() => {
						setLoading(false);
						cancel();
					}, 1000);
				})
				.catch((error) => {
					setLoading(false);
				});
		} else {
			setErrors(errors);
		}
	};

	return (
		<Loading loading={loading}>
			<div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Date & Time Of Service
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Visit State
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={getEncounterSpecificTime(
										_.get(
											encounter,
											['date_of_service'],
											''
										),
										_.get(
											props,
											['encounter', 'timezone'],
											''
										)
									)}
									name='date_of_service'
									disabled={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={_.get(
										encounter,
										['state_of_visit'],
										''
									)}
									name='state_of_visit'
									disabled={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Result CAP Completed Date
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-field-header'>
								Result CAP Sent To Patient On
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-field-header'>
								Result CAP Sent To PCP On
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={DatePickerValue(
										encounter,
										['followup_cap_completed_date'],
										''
									)}
									name='followup_cap_completed_date'
									disabled={true}
								/>
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-multi-cols-section'>
								<div className='encounter-input-section multi-first-col'>
									<EncounterDateField
										value={_.get(
											encounter,
											['cap_sent_to_patient'],
											''
										)}
										name='cap_sent_to_patient'
										disabled={true}
									/>
								</div>
								<div className='via_seprator'> via </div>
								<div className='encounter-input-section multi-two-col'>
									<EncounterMultiSelectField
										value={_.get(
											encounter,
											['cap_release_method_patient'],
											''
										)}
										name='cap_release_method_patient'
										options={createEnumsOptions(
											labsschema,
											['cap_release_method_patient'],
											'key',
											'display_name',
											'key'
										)}
										extraclassName='multi-selectfield-disable'
										disabled={true}
									/>
								</div>
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-multi-cols-section'>
								<div className='encounter-input-section multi-first-col'>
									<EncounterDateField
										value={_.get(
											encounter,
											['cap_sent_date_pcp'],
											''
										)}
										name='cap_sent_date_pcp'
										disabled={true}
									/>
								</div>
								<div className='via_seprator'> via </div>
								<div className='encounter-input-section multi-two-col'>
									<EncounterMultiSelectField
										value={_.get(
											encounter,
											['cap_release_method_pcp'],
											''
										)}
										name='cap_release_method_pcp'
										options={createEnumsOptions(
											labsschema,
											['cap_release_method_pcp'],
											'key',
											'display_name',
											'key'
										)}
										extraclassName='multi-selectfield-disable'
										disabled={true}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-footer-container'>
					<div className='cancel-btn-link'>
						<Button onClick={() => cancel()}>Cancel</Button>
					</div>
					<div className='save-btn-link'>
						<Button style={{ float: 'right' }} onClick={saveForm}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</Loading>
	);
};

export default EditVisitDetailSegment;
