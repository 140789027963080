//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import SearchbarExpanded from './SearchbarExpanded.js';
import SearchbarRetracted from './SearchbarRetracted.js';

//Styles
import './styles.css';

class Searchbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasSetStateFunc: false,
			expanded: true,
		};
		//bindings
		this.checkHasSetStateFunc = this.checkHasSetStateFunc.bind(this);
		this.expandSearchbar = this.expandSearchbar.bind(this);
		this.retractSearchbar = this.retractSearchbar.bind(this);
	}
	//functions

	componentDidMount() {
		this.checkHasSetStateFunc();
	}

	checkHasSetStateFunc() {
		if (this.props.setStateFunc) {
			this.setState({ hasSetStateFunc: true });
		}
	}

	expandSearchbar() {
		this.setState({ expanded: true });
	}

	retractSearchbar() {
		this.setState({ expanded: false });
	}

	render() {
		if (get(this, 'state.expanded', false)) {
			return (
				<SearchbarExpanded
					setStateFunc={this.props.setStateFunc}
					hasSetStateFunc={get(this, 'state.hasSetStateFunc', false)}
					currentValue={get(this, 'props.currentValue', '') || ''}
					retractSearchbar={this.retractSearchbar}
					useInputEvtObj={get(this, 'props.useInputEvtObj', false)}
					placeholder={get(this, 'props.placeholder', 'Search')}
				/>
			);
		} else {
			return (
				<SearchbarRetracted expandSearchbar={this.expandSearchbar} />
			);
		}
	}
}

export default Searchbar;
