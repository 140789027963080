import React from 'react';

//UI Libraries
import CommonCheckbox from '../../Common/Checkbox';

const MultiSelectList = (props) => {
	const {
		filteredData = [],
		internalValues = [],
		isMultiple,
		toggleCheck,
	} = props;

	const handleClick = (e, key) => {
		e.preventDefault();
		toggleCheck(key);
	};

	return (
		<div style={isMultiple ? { maxHeight: '326px' } : {}}>
			{filteredData.map((el) => {
				const isChecked = internalValues.includes(el.key);
				if (el.groupingLabel) {
					return (
						<div
							key={el.groupingLabel}
							className='multi-select-checkbox-grouping'
						>
							{el.groupingLabel}
						</div>
					);
				} else {
					return (
						<div
							className='chargeICDSearchResultRow multi-select-checkbox-modal-row'
							key={el.key}
							onClick={(e) => handleClick(e, el.key)}
						>
							<CommonCheckbox
								className={`chargeICDSearchResultRowCheckbox ${
									isChecked
										? 'chargeICDSearchResultRowCheckboxSelected'
										: ''
								}`}
								checked={isChecked}
							/>
							<div
								className={`chargeICDSearchResultRowTitle ${
									isChecked
										? 'chargeICDSearchResultRowSelectedText'
										: ''
								}`}
							>
								{el.display_name || '--'}
							</div>
						</div>
					);
				}
			})}
		</div>
	);
};

export default MultiSelectList;
