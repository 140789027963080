import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import * as patients from './Patients';
import * as dashboard from './Dashboard';
import * as encounter from './Encounter';
import * as notes from './Notes';
import * as session from './Session';
import * as search from './Search';
import * as exportreports from './Providers';
import * as billingencounters from './Billing';
import * as searchresults from './EnhancedSearch';
import * as accessrestricted from './accessrestricted';
import * as tests from './Tests';
import appData from './appData';
import documents from './Documents';
import Scheduling from './Scheduling';
import genes from './patient/genes';
import referralProgramsList from './referralprograms/ReferralPrograms';
import appointments from './appointments';
import insurancecompany from './insuranceCompany';
import { reducer as formReducer } from 'redux-form';
import me from './me';
import * as users from './Users';
import UserRoles from './userroles';
import outreach from './patient/outreach';
import metrics from './Metrics';
import uiConfig from './UIConfig';
import * as graphql from './GraphQL';
import * as cfDNA from './cfDNA';
import httpHandlers from './HTTPHandlers';
import * as userAccounts from './userAccounts';
import enums from './Enums';
import * as discreteVariant from './DiscreteVariant';
import * as structuralVariant from './StructuralVariant';
import riseAnalytics from './RiseAnalytics';
import * as cfDNAPrenatalScreening from './cfDNAPrenatalScreening';
import * as nucleotideRepeatVariant from './NucleotideRepeatVariant';

const Reducers = combineReducers({
	routing: routerReducer,
	patients: patients.patients,
	globalPatients: patients.globalPatients,
	patientdetail: patients.patientdetail,
	editpatient: patients.editpatient,
	deletepatient: patients.deletepatient,
	dashboard: dashboard.dashboard,
	dashboardBulkUpdateStatus: dashboard.dashboardBulkUpdateStatus,
	addpatient: patients.addpatient,
	linkpatientproviders: patients.linkproviders,
	unlinkpatientprovider: patients.unlinkprovider,
	linkedpatientproviders: patients.linkedproviders,
	linkencounterproviders: encounter.linkproviders,
	unlinkencounterprovider: encounter.unlinkprovider,
	updatelinkedpatientprovider: patients.updatelinkedprovider,
	patientTags: patients.patientTags,
	exportreports: exportreports.exportReport,
	getAllReport: exportreports.getAllReports,
	provideraffiliations: exportreports.provideraffiliations,
	partnerOrganization: exportreports.partnerOrganization,
	providers: exportreports.providers,
	updateProvider: exportreports.updateProvider,
	createProvider: exportreports.createProvider,
	providerEnums: exportreports.providerEnums,
	dashboardencounters: dashboard.dashboardencounters,
	dashboardpatients: dashboard.dashboardpatients,
	ccList: dashboard.ccList,
	gcaList: dashboard.gcaList,
	assignCcGca: dashboard.assignCcGca,
	upcomingappointments: dashboard.upcomingappointments,
	dashboardReferralPrograms: dashboard.dashboardReferralPrograms,
	getResults: searchresults.getResults,
	getSearchExportReport: searchresults.getSearchExportReport,
	searchAffiliations: searchresults.searchAffiliations,
	searchOrderingPhysicians: searchresults.searchOrderingPhysicians,
	searchVisitProviders: searchresults.searchVisitProviders,
	searchSpecialty: searchresults.searchSpecialty,
	searchTestNames: searchresults.searchTestNames,
	searchTestResults: searchresults.searchTestResults,
	searchVisitStatus: searchresults.searchVisitStatus,
	searchActionability: searchresults.searchActionability,
	searchLabs: searchresults.searchLabs,
	getEncounterTypes: searchresults.getEncounterTypes,
	searchOrderStatus: searchresults.searchOrderStatus,
	searchRequestDecision: searchresults.searchRequestDecision,
	searchReferringProviderName: searchresults.searchReferringProviderName,
	searchVisitState: searchresults.searchVisitState,
	searchMultiPatientStatus: searchresults.searchMultiPatientStatus,
	searchDispatchStatus: searchresults.searchDispatchStatus,
	searchOutreachStatus: searchresults.searchOutreachStatus,
	searchTestType: searchresults.searchTestType,
	billingencounters: billingencounters.billingEncounters,
	referralchannels: billingencounters.referralchannels,
	billingaffiliations: billingencounters.billingaffiliations,
	getBillingEncounterDetail: billingencounters.getBillingEncounterDetail,
	getBillingPatientDetail: billingencounters.getBillingPatientDetail,
	getBillingEncounterObject: billingencounters.getBillingEncounterObject,
	getEncounterEnums: billingencounters.getEncounterEnums,
	getExportBillingEncounter: billingencounters.getExportBillingEncounter,
	saveEncounter: billingencounters.saveEncounter,
	billingEncounterBulkUpdate: billingencounters.billingEncounterBulkUpdate,
	patientFeeEnum: billingencounters.patientFeeEnum,
	getCounts: billingencounters.getCounts,
	encounterlist: encounter.encounterlist,
	notes: notes.notes,
	submitnotes: notes.submitnotes,
	deletenotes: notes.deletenotes,
	encounterdetail: encounter.encounterdetail,
	fetchnotes: notes.fetchnotes,
	session: session.session,
	searchkey: search.searchkey,
	addencounter: encounter.addencounter,
	orderrequest: encounter.orderrequest,
	editencounter: encounter.editencounter,
	interval: session.interval,
	groupsessionschema: encounter.encountergroupsessionschema,
	ccintakeschema: encounter.encounterccintakeschema,
	visitsschema: encounter.encountervisitsschema,
	labsschema: encounter.encounterlabsschema,
	researchdataschema: encounter.encounterresearchdataschema,
	payorEnum: encounter.payorEnum,
	orderingphysicianenum: encounter.orderingphysicianenum,
	visitproviderenum: encounter.visitproviderenum,
	visitconsultationtypes: encounter.visitconsultationtypes,
	partnerinvoicestatus: encounter.partnerinvoicestatus,
	getBillingEncounterData: encounter.getBillingEncounterData,
	getSCPClinicalInfoData: encounter.getSCPClinicalInfoData,
	referralPrograms: patients.getReferralPrograms,
	getAppointmentReferralPrograms: patients.getAppointmentReferralPrograms,
	contactMethods: patients.getContactMethods,
	contactOutcomes: patients.getContactOutcomes,
	referralReasons: patients.getReferralReasons,
	referralProviders: patients.getReferralProviders,
	eligibilityFields: patients.getEligibilityFields,
	getConsenttotreat: patients.getConsenttotreat,
	createAthenaPatient: patients.createAthenaPatient,
	duplicatePatientCheck: patients.duplicatePatientCheck,
	noOutreach: patients.noOutreach,
	tests: tests.tests,
	getTestDetail: tests.getTestDetail,
	geneticTestEnums: tests.testEnums,
	testUpdateCreate: tests.testUpdateCreate,
	geneticTests: tests.geneticTests,
	testAuthEnums: tests.testAuthEnums,
	accessrestricted: accessrestricted.accessrestricted,
	documents: documents,
	testEnums: encounter.TestEnums,
	alternateTestEnums: encounter.AlternateTestEnums,
	editTestOrderTestEnums: encounter.EditTestOrderTestEnums,
	setpassword: patients.setpassword,
	insuranceCompanies: patients.getInsuranceCompanies,
	fullListOfInsuranceCompanies: patients.getFullListOfInsuranceCompanies,
	insuranceAuths: patients.getInsuranceAuths,
	saveinsurance: patients.saveinsurance,
	getInsuranceInfo: patients.getInsuranceInfo,
	saveinsuranceauth: patients.saveinsuranceauth,
	addreferringprovider: patients.addreferringprovider,
	addreferralreason: patients.addreferralreason,
	addinitialcontactmethod: patients.addinitialcontactmethod,
	genes: genes,
	referralProgramsList: referralProgramsList,
	appointments: appointments,
	Scheduling: Scheduling,
	insurancecompany: insurancecompany,
	form: formReducer,
	icdCodes: encounter.icdCodes,
	me: me,
	Users: users.users,
	User: users.user,
	UserRoles: UserRoles,
	outreach: outreach,
	encounterReclaim: encounter.Reclaim,
	dashboardpatientsV2: dashboard.dashboardpatientsV2,
	metrics: metrics,
	preauthorizationsEnums: encounter.getPreauthorizationsEnums,
	encounterPreauthTestOrders: encounter.getPreauthorizationTestOrders,
	encounterTestOrderRequest: encounter.TestOrderRequest,
	gentestenums: encounter.getGeneTestEnums,
	geneTestOrderStatusEnum: encounter.geneTestOrderStatusEnum,
	testAuthorization: encounter.testAuthorization,
	testBulkUpdate: encounter.testBulkUpdate,
	testOrder: encounter.testOrder,
	getTestOrderNotes: encounter.getTestOrderNotes,
	auditTrailEncounter: encounter.auditTrailEncounter,
	contentViewedByPatient: encounter.contentViewedByPatient,
	encounterCommunications: encounter.encounterCommunications,
	uiConfig,
	dashboardRoROutreach: dashboard.dashboardroroutreach,
	exportEncounterRecord: dashboard.exportEncounterRecord,
	updateSurveyStatus: dashboard.updateSurveyStatus,
	appData: appData,
	gender: patients.gender,
	pronouns: patients.pronouns,
	sexAssignedAtBirth: patients.sexAssignedAtBirth,
	drugExposures: graphql.drugExposures,
	diagnosticReports: cfDNA.diagnosticReports,
	addDiagnosticFinding: cfDNA.addDiagnosticFinding,
	addDiagnosticFindingClinicalNote: cfDNA.addDiagnosticFindingClinicalNote,
	httpHandlers,
	raceEnum: patients.raceEnum,
	ethnicityEnum: patients.ethnicityEnum,
	searchPatientTags: searchresults.searchPatientTags,
	visitIndications: encounter.visitIndications,
	partners: userAccounts.partners,
	createUser: userAccounts.createUser,
	updateUser: userAccounts.updateUser,
	deleteUser: userAccounts.deleteUser,
	userAuditTrail: userAccounts.userAuditTrail,
	enums: enums,
	referenceAssembliesEnum: discreteVariant.referenceAssembliesEnum,
	geneticResultGenesSymbolEnum: discreteVariant.geneticResultGenesSymbolEnum,
	addDiscreteVariantFinding: discreteVariant.addDiscreteVariantFinding,
	getDiagnosticFindingValidation:
		discreteVariant.getDiagnosticFindingValidation,
	updateTestOrderRedFlag: encounter.updateTestOrderRedFlag,
	encounterDocuments: encounter.getEncounterDocuments,
	riseAnalyticsData: riseAnalytics,
	addStructuralVariantFinding: structuralVariant.addStructuralVariantFinding,
	getStructuralFindingValidation:
		structuralVariant.getStructuralFindingValidation,
	addCFDNAPrenatalScreeningFinding:
		cfDNAPrenatalScreening.addCFDNAPrenatalScreeningFinding,
	addNucleotideRepeatVariantFinding:
		nucleotideRepeatVariant.addNucleotideRepeatVariantFinding,
	updateConsentStatus: patients.updateConsentStatus,
});

const rootReducer = (state, action) => {
	if (action.type === 'RESET_STORE') {
		return Reducers(undefined, action);
	}
	return Reducers(state, action);
};
export default rootReducer;
