// React & Redux
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// Images
import ReactSVG from 'react-svg';
import patSearchIcon from '../../../assets/pat-search-icon.svg';
import crossIcon from '../../../assets/pat-search-close.svg';

// Lodash
import { get, isEmpty, debounce } from 'lodash';

// Other Libraries
import moment from 'moment';
import classnames from 'classnames';

// Utils
import { ADVANCED_SEARCH } from '../../../Utils/permissionUtils';

// Actions & Services
import {
	globalPatientSearch,
	globalPatientSearchClear,
} from '../../../actions/patients';
import { getsearchkey, resetsearchkey } from '../../../actions/Search';

// UI Libraries
import { Loading, Input, Button, Form } from 'gm-element-react';

// Component
import RBAC from '../../RBAC';
import AdvancedSearchIcon from '../../Icons/AdvancedSearch';
import TextWithTooltip from '../../Common/TextWithTooltip';
import Tags from '../../Common/Tags';
import Tooltip from '../Tooltip';

// Styles
import './style.css';

class SearchPatient extends Component {
	constructor(props) {
		super(props);

		this.state = {
			query: '',
			hasFocus: false,
		};

		this.bodyClickHandler = this.bodyClickHandler.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.searchInPatients = this.searchInPatients.bind(this);
		this.redirectToAdvancedSearch =
			this.redirectToAdvancedSearch.bind(this);
		this.clearSearch = this.clearSearch.bind(this);
		this.searchPatients = debounce(() => {
			if (!isEmpty(this.state.query))
				this.props.searchPatients(this.state.query);
		}, 500);
	}

	componentDidMount() {
		document
			.querySelector('body')
			.addEventListener('click', this.bodyClickHandler, {
				capture: true,
			});
	}

	componentWillUnmount() {
		document
			.querySelector('body')
			.removeEventListener('click', this.bodyClickHandler);
	}

	bodyClickHandler(e) {
		const currentNode = e.target;
		const searchPatientsContainer = document.querySelector(
			'.search-patient-container'
		);
		if (!searchPatientsContainer?.contains(currentNode))
			this.setState({ hasFocus: false });
	}

	handleChange(query) {
		if (!query) this.props.resetGlobalSearchKey();
		this.setState(
			{
				query,
				hasFocus: true,
			},
			this.searchPatients
		);
	}

	searchInPatients() {
		this.setState({ hasFocus: false });
		this.props.setGlobalSearchKey(this.state.query);
		this.props.history.history.push('/app/patients');
	}

	handleFocus() {
		this.setState({ hasFocus: true });
	}

	redirectToAdvancedSearch() {
		this.setState({ hasFocus: false });
		this.props.history.history.push('/app/advancedsearch');
	}

	clearSearch() {
		this.setState({ query: '', hasFocus: false });
		this.props.clearGlobalSearchList();
		this.props.resetGlobalSearchKey();
	}

	redirectToPatientDetail = ({ first_name, last_name, uuid }) => {
		this.setState({ hasFocus: false });
		this.props.history.history.push({
			pathname: `/app/patientdetail/${uuid}/0/0/`,
			state: {
				breadcrumbs: [
					{
						location: 'Patient Detail',
						patientFullName: `${first_name || '--'} ${
							last_name || '--'
						}`,
					},
				],
			},
		});
	};

	renderHighlights = (patient) => {
		let uuid;
		let externalPatientID;
		let phone;

		for (const key of Object.keys(get(patient, ['highlight'], {}))) {
			if (!uuid && key.startsWith('uuid')) {
				uuid = (
					<div className='highlight'>
						{get(patient, ['uuid'], '--')}
					</div>
				);
			} else if (
				!externalPatientID &&
				key.startsWith('external_patient_id')
			) {
				externalPatientID = (
					<div className='highlight'>
						{get(patient, ['external_patient_id'], '--')}
					</div>
				);
			} else if (!phone && key.startsWith('phone')) {
				phone = (
					<div className='highlight'>
						{get(patient, ['phone'], '--')}
					</div>
				);
			}
		}

		return (
			<Fragment>
				{uuid}
				{externalPatientID}
				{phone}
			</Fragment>
		);
	};

	render() {
		const { query, hasFocus } = this.state;
		const { patients, loading } = this.props;

		return (
			<Form
				className={classnames('search-patient-container', {
					'has-focus': hasFocus,
					'has-value': !isEmpty(query),
				})}
				onSubmit={(e) => e.preventDefault()}
			>
				<Input
					className='search-field'
					placeholder={'Search Patients'}
					value={query}
					onChange={this.handleChange}
					onKeyPress={(event) =>
						event.key === 'Enter' && this.searchInPatients()
					}
					onFocus={this.handleFocus}
					append={
						<Fragment>
							{!isEmpty(query) ? (
								<Button onClick={this.clearSearch}>
									<ReactSVG src={crossIcon} />
								</Button>
							) : (
								<Button>
									<ReactSVG src={patSearchIcon} />
								</Button>
							)}
							<RBAC
								action={ADVANCED_SEARCH}
								yes={
									<Tooltip
										effect='dark'
										placement='bottom-center'
										content={
											<div className='adv-search-text'>
												Advanced Search
											</div>
										}
									>
										<Button
											onClick={
												this.redirectToAdvancedSearch
											}
										>
											<AdvancedSearchIcon />
										</Button>
									</Tooltip>
								}
							/>
						</Fragment>
					}
				/>

				{hasFocus && !isEmpty(query) && (
					<div className='patients-list-container'>
						<div className='list scrollbox'>
							{loading ? (
								<Loading />
							) : (
								<Fragment>
									{patients.map((patient) => {
										return (
											<div
												className='patient-detail'
												key={patient.uuid}
												onClick={() =>
													this.redirectToPatientDetail(
														patient
													)
												}
											>
												<div className='name-tags'>
													<TextWithTooltip
														maxWidth={
															369 -
															get(
																patient,
																[
																	'user_tags',
																	'length',
																],
																0
															) *
																20 +
															'px'
														}
													>
														{patient.first_name ||
														patient.last_name
															? patient.first_name +
																' ' +
																patient.last_name
															: '--'}
													</TextWithTooltip>
													<span>
														<Tags
															tags={
																patient.user_tags
															}
															isDisabledTooltip={
																true
															}
														/>
													</span>
												</div>
												<div className='email-dob'>
													<TextWithTooltip maxWidth='290px'>
														{patient.email || '--'}
													</TextWithTooltip>
													<span>
														{patient.dob
															? moment(
																	patient.dob
																).format(
																	'MMM DD, YYYY'
																)
															: 'Unknown'}
													</span>
												</div>
												{this.renderHighlights(patient)}
											</div>
										);
									})}
								</Fragment>
							)}
						</div>
						<div
							className='search-in-patient'
							onClick={this.searchInPatients}
						>
							Search in Patients
						</div>
					</div>
				)}
			</Form>
		);
	}
}

const mapStateToProps = (state, props) => ({
	patients: state.globalPatients.patients,
	loading: state.globalPatients.loading,
	error: state.globalPatients.error,
});

const mapDispatchToProps = (dispatch) => ({
	setGlobalSearchKey: (data) => dispatch(getsearchkey(data)),
	resetGlobalSearchKey: (data) => dispatch(resetsearchkey(data)),
	searchPatients: (data) => dispatch(globalPatientSearch(data)),
	clearGlobalSearchList: () => dispatch(globalPatientSearchClear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPatient);
