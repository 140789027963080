//React & Redux
import React from 'react';

//UI Libraries
import { Loading } from 'gm-element-react';

class ICDSearchResultsRowLoading extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		return (
			<Loading loading={true} style={{ width: 'calc(100% - 10px)' }}>
				<div
					className='chargeICDSearchResultRow'
					style={{ height: '48px' }}
				/>
			</Loading>
		);
	}
}

export default ICDSearchResultsRowLoading;
