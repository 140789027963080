import * as actionTypes from '../actions/actionTypes';

const defaultSnackbar = {
	message: undefined,
	variant: undefined,
};

const defaultState = {
	table: {
		resultsPerPage: 25,
	},

	snackbar: { ...defaultSnackbar },
};

export default function (state = defaultState, action = {}) {
	switch (action.type) {
		case actionTypes.UPDATE_RESULTS_PER_PAGE:
			return {
				...state,
				table: {
					resultsPerPage: action.payload,
				},
			};
		case actionTypes.SHOW_SNACKBAR:
			return {
				...state,
				snackbar: {
					message: action.payload.message,
					variant: action.payload.variant,
				},
			};
		case actionTypes.RESET_SNACKBAR:
			return {
				...state,
				snackbar: { ...defaultSnackbar },
			};
		default:
			return state;
	}
}
