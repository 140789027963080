//React & Redux
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//Lodash
import _ from 'lodash';

//Actions & Services
import {
	getReferralDocuments,
	getWorkflowDocuments,
	createTemplate,
	updateTemplate,
	resetReferralDocuments,
	resetWorkflowDocuments,
	getDocumentDetail,
} from '../../actions/referralprograms/ReferralDocument';
import {
	clearDefaultProvidersState,
	createReferralProgramDefaultProvider,
	getReferralProgramDetail,
	resetReferralProgramDetail,
	updateReferralProgramDefaultProvider,
	updateReferralProgramDefaultDocumentNotificationRecipients,
} from '../../actions/referralprograms/referralprograms';
import {
	clearLoadDocumentTypesForWorflow,
	LoadDocumentTypesForWorkflow,
} from './../../actions/documents';
import {
	createProvider,
	getProviderCredentials,
	getProviderSpecialties,
	getProviders,
	updateProvider,
} from '../../actions/providers';

// Other libraries
import queryString from 'query-string';

//Components
import DefaultProviders from './DefaultProviders/DefaultProviders';
import WorkFlowDocumentForm from './WorkFlowDocumentForm';
import DocumentNotifications from '././DocumentNotifications/DocumentNotifications';

//Styles
import './css/refprogram-detail.css';

const Tabs = {
	WORKFLOW_DOCUMENT: 2,
	DEFAULT_PROVIDERS: 3,
	DOCUMENT_NOTIFICATIONS: 4,
};

const categoryType = {
	WORKFLOW_DOCUMENT: 'workflow',
};

class ReferralProgramsDetails extends Component {
	constructor(props) {
		super(props);
		const params = queryString.parse(
			_.get(this, ['props', 'location', 'search'], '')
		);
		this.state = {
			showTab: Tabs.WORKFLOW_DOCUMENT,
			affiliation_name: (params && params.name) || '',
		};
	}

	componentWillUnmount() {
		this.props.clearLoadDocumentTypes();
	}

	goToReferralProgramsList = () => {
		const location_state = {};
		const bc = _.get(this.props, 'location.state.breadcrumbs[0]', {});

		location_state['breadcrumbs'] = [
			{
				offset: bc.offset,
				order_by: bc.order_by,
				order_type: bc.order_type,
				query: bc.query,
			},
		];

		this.props.history.push('/app/referralprograms', location_state);
	};

	ChangeTab = (value) => {
		this.setState({ showTab: value });
	};

	sendNotification = (message, type) => {
		this.props.enqueueSnackbar(message, {
			variant: type,
			className: 'export_notification',
			anchorOrigin: { horizontal: 'center', vertical: 'top' },
		});
	};

	render() {
		const viewid = _.get(this, 'props.match.params.view', 0);
		const { showTab } = this.state;
		const {
			getLoadDocumentTypes,
			documentTypes,
			getWorkDocuments,
			getCreateTemplate,
			getUpdateTemplate,
			updateTemplate,
			workflowDocuments,
			clearLoadDocumentTypes,
			externalProviderUsers,
		} = this.props;

		const filteredExternalProviderUser = externalProviderUsers?.filter(
			(providerUser) =>
				providerUser.is_default_doc_release_recipient === true
		);
		const documentNotificationRecipients =
			filteredExternalProviderUser?.map((provider) => provider.user);
		const renderTabComponent = () => {
			switch (this.state.showTab) {
				case Tabs.WORKFLOW_DOCUMENT:
					return (
						<WorkFlowDocumentForm
							key={Tabs.WORKFLOW_DOCUMENT}
							workflowDocuments={workflowDocuments}
							getLoadDocumentTypes={() => getLoadDocumentTypes()}
							resetWorkflowDocuments={
								this.props.resetWorkflowDocuments
							}
							getWorkDocuments={getWorkDocuments}
							documentTypes={documentTypes}
							categoryType={categoryType}
							partner_uuid={
								this.props.match?.params?.referralprogramid || 0
							}
							getUpdateTemplate={getUpdateTemplate}
							getCreateTemplate={getCreateTemplate}
							updateTemplate={updateTemplate}
							sendNotification={this.sendNotification}
							history={this.props.history}
							location={this.props.location}
							ChangeTab={this.ChangeTab}
							Tabs={Tabs}
							clearLoadDocumentTypes={clearLoadDocumentTypes}
							goToReferralProgramsList={
								this.goToReferralProgramsList
							}
						/>
					);
				case Tabs.DEFAULT_PROVIDERS:
					return (
						<DefaultProviders
							{...{
								key: Tabs.DEFAULT_PROVIDERS,
								clearDefaultProvidersState:
									this.props.clearDefaultProvidersState,
								createReferralProgramDefaultProvider:
									this.props
										.createReferralProgramDefaultProvider,
								createProvider: this.props.createProvider,
								createdProvider: this.props.createdProvider,
								createdProviderError:
									this.props.createdProviderError,
								creatingProvider: this.props.creatingProvider,
								enqueueSnackbar: this.props.enqueueSnackbar,
								getProviderCredentials:
									this.props.getProviderCredentials,
								getProviderSpecialties:
									this.props.getProviderSpecialties,
								getProviders: this.props.getProviders,
								getReferralProgramDetail:
									this.props.getReferralProgramDetail,
								gettingProviderCredentials:
									this.props.gettingProviderCredentials,
								gettingProviderSpecialties:
									this.props.gettingProviderSpecialties,
								gettingProviders: this.props.gettingProviders,
								partnerUuid:
									this.props.match?.params
										?.referralprogramid || 0,
								providerCredentials:
									this.props.providerCredentials,
								providerSpecialties:
									this.props.providerSpecialties,
								providers: this.props.providers,
								referralProgramsList:
									this.props.referralProgramsList,
								updateReferralProgramDefaultProvider:
									this.props
										.updateReferralProgramDefaultProvider,
								updateProvider: this.props.updateProvider,
								updatedProvider: this.props.updatedProvider,
								updatedProviderError:
									this.props.updatedProviderError,
								updatingProvider: this.props.updatingProvider,
							}}
						/>
					);
				case Tabs.DOCUMENT_NOTIFICATIONS:
					return (
						<DocumentNotifications
							key={Tabs.DOCUMENT_NOTIFICATION}
							documentNotificationRecipients={
								documentNotificationRecipients
							}
							clearDefaultProviderState={
								this.props.clearDefaultProviderState
							}
							affiliationName={this.state.affiliation_name}
							externalProviderUsers={externalProviderUsers}
							enqueueSnackbar={this.props.enqueueSnackbar}
							getReferralProgramDetail={
								this.props.getReferralProgramDetail
							}
							loadingReferralProgramDetail={
								this.props.loadingReferralProgramDetail
							}
							partnerUuid={
								this.props.match?.params?.referralprogramid || 0
							}
							updateReferralProgramDefaultDocumentNotificationRecipients={
								this.props
									.updateReferralProgramDefaultDocumentNotificationRecipients
							}
						/>
					);
			}
		};

		return (
			<Fragment>
				{viewid == 0 ? (
					<div className='ref-programs-details-container'>
						<header className='referral-program-header'>
							<nav
								className='ref-programslist-backlink'
								onClick={() => this.goToReferralProgramsList()}
							>
								<i className='material-icons'>chevron_left</i>
								<div className='ref-programslist-backtext'>
									Referral programs list
								</div>
							</nav>
							<h4 style={{ textTransform: 'capitalize' }}>
								{this.state.affiliation_name}
							</h4>
						</header>
						<div className='ref-programs-details-body'>
							<nav className='left-nav-container'>
								<ul>
									<li
										className={`${
											showTab == Tabs.WORKFLOW_DOCUMENT &&
											'active'
										}`}
										onClick={() =>
											this.ChangeTab(
												Tabs.WORKFLOW_DOCUMENT
											)
										}
									>
										Workflow documents
									</li>
									<li
										className={`${
											showTab == Tabs.DEFAULT_PROVIDERS &&
											'active'
										}`}
										onClick={() =>
											this.ChangeTab(
												Tabs.DEFAULT_PROVIDERS
											)
										}
									>
										Default providers
									</li>
									<li
										className={`${
											showTab ==
												Tabs.DOCUMENT_NOTIFICATIONS &&
											'active'
										}`}
										onClick={() =>
											this.ChangeTab(
												Tabs.DOCUMENT_NOTIFICATIONS
											)
										}
									>
										Document notifications
									</li>
								</ul>
							</nav>
							<main className='ref-program-detail-section'>
								{renderTabComponent()}
							</main>
						</div>
					</div>
				) : null}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	createdProvider: state.createProvider?.createdProvider || null,
	createdProviderError: state.createProvider?.createdProviderError || null,
	creatingProvider: state.createProvider?.creatingProvider || false,
	documentTypes: state.documents?.documentTypesForWorkflow,
	externalProviderUsers:
		state.referralProgramsList.referralProgramDetail
			.externalProviderUsers || [],
	loaded_documentdetail:
		state.referralProgramsList?.loaded_documentdetail?.data?.data,
	gettingProviderCredentials:
		state.providerEnums?.gettingProviderCredentials || false,
	gettingProviderSpecialties:
		state.providerEnums?.gettingProviderSpecialties || false,
	gettingProviders: state.providers?.loading || false,
	loading_documentdetail: state.referralProgramsList?.loading_documentdetail,
	loadingReferralProgramDetail:
		state.referralProgramsList?.loadingReferralProgramDetail || false,
	providerCredentials: state.providerEnums?.providerCredentials || [],
	providerSpecialties: state.providerEnums?.providerSpecialties || [],
	providers: state.providers?.data || [],
	referralDocuments: {
		loading: state.referralProgramsList.loading_referraldocument,
		loaded: state.referralProgramsList.loaded_referraldocument,
		total:
			state.referralProgramsList.loaded_referraldocument &&
			state.referralProgramsList.loaded_referraldocument.total,
	},
	referralProgramsList: state.referralProgramsList,
	updateTemplate: {
		updating: state.referralProgramsList.updatingTemplate,
		updated: state.referralProgramsList.updatedTemplate,
	},
	updatedProvider: state.updateProvider?.updatedProvider || null,
	updatedProviderError: state.updateProvider?.updatedProviderError || null,
	updatingProvider: state.updateProvider?.updatingProvider || false,
	workflowDocuments: {
		loading: state.referralProgramsList.loading_workflowdocument,
		loaded: state.referralProgramsList.loaded_workflowdocument,
		total:
			state.referralProgramsList.loaded_workflowdocument &&
			state.referralProgramsList.loaded_workflowdocument.total,
	},
});

const mapDispatchToProps = (dispatch) => ({
	clearLoadDocumentTypes: () => dispatch(clearLoadDocumentTypesForWorflow()),
	getCreateTemplate: (data) => dispatch(createTemplate(data)),
	getLoadDocumentTypes: () => dispatch(LoadDocumentTypesForWorkflow()),
	getRefDocuments: (data) => dispatch(getReferralDocuments(data)),
	getUpdateTemplate: (uuid, data) => dispatch(updateTemplate(uuid, data)),
	getWorkDocuments: (data) => dispatch(getWorkflowDocuments(data)),
	...bindActionCreators(
		{
			clearDefaultProvidersState,
			createProvider,
			createReferralProgramDefaultProvider,
			getDocumentDetail,
			getProviderCredentials,
			getProviderSpecialties,
			getProviders,
			getReferralProgramDetail,
			resetReferralDocuments,
			resetReferralProgramDetail,
			resetWorkflowDocuments,
			updateProvider,
			updateReferralProgramDefaultProvider,
			updateReferralProgramDefaultDocumentNotificationRecipients,
		},
		dispatch
	),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReferralProgramsDetails);
