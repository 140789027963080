//React & Redux
import React, { useEffect, useRef, useState } from 'react';

//Images
import helpIcon from './images/help.svg';
import editInsuranceButton from './images/edit.svg';

//Lodash
import { isEmpty, isNil, get, forEach, startsWith } from 'lodash';

//Utils
import { dependentVariants } from '../../../utils.js';
import { states_list } from '../../../utils.js';
import { validPastDateNoMessage } from '../../../Utils/validate.js';
import { CARE_PATIENTS_PATIENT_PAYMENTS_INSURANCE_ACTIONS } from '../../../Utils/permissionUtils';

//UI Libraries
import { Form, Input, Loading, Radio, Select, Button } from 'gm-element-react';
import InputMask from 'react-input-mask';

//Other Libraries
import classnames from 'classnames';
import moment from 'moment';
import { isValidNumber } from 'libphonenumber-js';

//Components
import Tooltip from '../../Common/Tooltip';
import RBAC from '../../RBAC';

//Hooks
import { usePrevious } from '../../../hooks/usePrevious';

//Images
import ReactSVG from 'react-svg';
import add from '../../../assets/addcircle_secondry.svg';

const OTHER_COMPANY = 2;

const rules = {
	primary_insurance_company_id: [
		{
			required: true,
			message: 'Please select health insurance',
			trigger: 'change',
		},
	],
	primary_insurance_no: [
		{
			required: true,
			message: 'Please enter insurance ID',
			trigger: 'blur',
		},
	],
	primary_group_number: [
		{
			required: true,
			message: 'Please enter group number',
			trigger: 'blur',
		},
	],
	primary_is_policyholder: [
		{
			required: true,
			type: 'boolean',
			message: 'Please select primary policyholder',
			trigger: 'change',
		},
	],
	primary_company_name: [
		{
			required: true,
			message: 'Please enter health insurance company',
			trigger: 'change',
		},
	],
	primary_policyholder_relationship: [
		{
			required: true,
			message: 'Please select relationship',
			trigger: 'change',
		},
	],
	primary_policyholder_first_name: [
		{ required: true, message: 'Please enter first name', trigger: 'blur' },
	],
	primary_policyholder_last_name: [
		{ required: true, message: 'Please enter last name', trigger: 'blur' },
	],
	primary_policyholder_dob: [
		{
			validator: (rule, value, callback) => {
				if (
					value &&
					value !== '' &&
					!validPastDateNoMessage(value, 'MM/DD/YYYY')
				) {
					callback(new Error('Please select valid date of birth'));
				} else {
					callback();
				}
			},
			trigger: 'change',
		},
	],
	primary_policyholder_biological_sex: [
		{
			required: true,
			message: 'Please select biological sex',
			trigger: 'change',
		},
	],
	primary_phone: [
		{
			required: true,
			message: 'Please enter phone number',
			trigger: 'blur',
		},
		{
			validator: (rule, value, callback) => {
				if (value && value !== '' && !isValidNumber(value)) {
					callback(new Error('Invalid phone number entered'));
				} else {
					callback();
				}
			},
		},
	],
	primary_insurance_address: [
		{ required: true, message: 'Please enter address', trigger: 'blur' },
	],
	primary_insurance_city: [
		{ required: true, message: 'Please enter city', trigger: 'blur' },
	],
	primary_insurance_state: [
		{ required: true, message: 'Please select state', trigger: 'change' },
	],
	primary_insurance_zipcode: [
		{ required: true, message: 'Please enter zipcode', trigger: 'blur' },
	],
	secondary_insurance_company_id: [
		{
			required: true,
			message: 'Please select health insurance',
			trigger: 'change',
		},
	],
	secondary_company_name: [
		{
			required: true,
			message: 'Please enter health insurance company',
			trigger: 'change',
		},
	],
	secondary_insurance_no: [
		{
			required: true,
			message: 'Please enter insurance ID',
			trigger: 'blur',
		},
	],
	secondary_policyholder_relationship: [
		{
			required: true,
			message: 'Please select relationship',
			trigger: 'change',
		},
	],
	secondary_policyholder_first_name: [
		{ required: true, message: 'Please enter first name', trigger: 'blur' },
	],
	secondary_policyholder_last_name: [
		{ required: true, message: 'Please enter last name', trigger: 'blur' },
	],
	secondary_policyholder_dob: [
		{
			validator: (rule, value, callback) => {
				if (
					value &&
					value !== '' &&
					!validPastDateNoMessage(value, 'MM/DD/YYYY')
				) {
					callback(new Error('Please select valid date of birth'));
				} else {
					callback();
				}
			},
			trigger: 'change',
		},
	],
	secondary_policyholder_biological_sex: [
		{
			required: true,
			message: 'Please select biological sex',
			trigger: 'change',
		},
	],
	secondary_phone: [
		{
			validator: (rule, value, callback) => {
				if (value && value !== '' && !isValidNumber(value)) {
					callback(new Error('Invalid phone number entered'));
				} else {
					callback();
				}
			},
		},
	],
	lab_insurance_company_id: [
		{
			required: true,
			message: 'Please select health insurance',
			trigger: 'change',
		},
	],
	lab_is_policyholder: [
		{
			required: true,
			type: 'boolean',
			message: 'Please select lab policyholder',
			trigger: 'change',
		},
	],
	lab_company_name: [
		{
			required: true,
			message: 'Please enter health insurance company',
			trigger: 'change',
		},
	],
	lab_insurance_no: [
		{
			required: true,
			message: 'Please enter insurance ID',
			trigger: 'blur',
		},
	],
	lab_policyholder_relationship: [
		{
			required: true,
			message: 'Please select relationship',
			trigger: 'change',
		},
	],
	lab_policyholder_first_name: [
		{ required: true, message: 'Please enter first name', trigger: 'blur' },
	],
	lab_policyholder_last_name: [
		{ required: true, message: 'Please enter last name', trigger: 'blur' },
	],
	lab_policyholder_dob: [
		{
			validator: (rule, value, callback) => {
				if (
					value &&
					value !== '' &&
					!validPastDateNoMessage(value, 'MM/DD/YYYY')
				) {
					callback(new Error('Please select valid date of birth'));
				} else {
					callback();
				}
			},
			trigger: 'change',
		},
	],
	lab_policyholder_biological_sex: [
		{
			required: true,
			message: 'Please select biological sex',
			trigger: 'change',
		},
	],
	lab_phone: [
		{
			validator: (rule, value, callback) => {
				if (value && value !== '' && !isValidNumber(value)) {
					callback(new Error('Invalid phone number entered'));
				} else {
					callback();
				}
			},
		},
	],
	lab_group_number: [
		{
			required: true,
			message: 'Please enter group number',
			trigger: 'blur',
		},
	],
};

const InsuranceForm = (props) => {
	const {
		actionType,
		biologicalSexVariants = [],
		enqueueSnackbar,
		insuranceCompanies = [],
		insuranceFormActions: { saveInsurance },
		match: { params },
		patient,
		planTypesEnums,
		saveInsuranceResponse,
		setAction,
		isAddPrimaryInsuranceFromForm,
		setIsAddPrimaryInsuranceFromForm,
		isAddSecondaryInsuranceFromForm,
		setIsAddSecondaryInsuranceFromForm,
		isAddLabInsuranceFromForm,
		setIsAddLabInsuranceFromForm,
		getPatientInsuranceInfo,
		patientInsuranceInfo,
		isActionTypeEdit,
		fullListOfInsuranceCompanies = [],
		getPatientDetail,
	} = props;

	const formatePayloadForInsurance = (payload) => {
		if (isInsuranceEmpty(payload)) {
			return payload;
		}
		return {
			insurance_id: get(payload, 'insurance_id', ''),
			insurance_plan_type: get(payload, 'insurance_company.name', ''),
			insurance_type: get(payload, 'insurance_type', ''),
			is_policyholder: get(payload, 'is_policyholder', ''),
			patient_uuid: get(payload, 'patient_uuid', ''),
			policyholder_biological_sex: get(
				payload,
				'policyholder_biological_sex',
				''
			),
			policyholder_dob: get(payload, 'policyholder_dob', ''),
			policyholder_first_name: get(
				payload,
				'policyholder_first_name',
				''
			),
			policyholder_last_name: get(payload, 'policyholder_last_name', ''),
			policyholder_name: get(payload, 'policyholder_name', ''),
			policyholder_relationship: get(
				payload,
				'policyholder_relationship',
				''
			),
			athena_insurance_id: get(
				payload,
				'insurance.athena_insurance_id',
				''
			),
			athena_insurance_package_id: get(
				payload,
				'insurance.athena_insurance_package_id',
				''
			),
			athena_insurance_package_name: get(
				payload,
				'insurance.athena_insurance_package_name',
				''
			),
			company_name: get(payload, 'insurance.company_name', ''),
			employer: get(payload, 'insurance.employer', ''),
			group_no: get(payload, 'insurance.group_no', ''),
			insurance_address: get(payload, 'insurance.insurance_address', ''),
			insurance_city: get(payload, 'insurance.insurance_city', ''),
			insurance_company_id: get(
				payload,
				'insurance.insurance_company_id',
				''
			),
			insurance_no: get(payload, 'insurance.insurance_no', ''),
			insurance_plan_name: get(payload, 'insurance_plan_type.name', ''),
			insurance_state: get(payload, 'insurance.insurance_state', ''),
			insurance_zipcode: get(payload, 'insurance.insurance_zipcode', ''),
			phone: get(payload, 'insurance.phone', ''),
			insurance_company_type: get(payload, 'insurance_company.name', ''),
		};
	};

	const isInsuranceEmpty = (InsuranceDetails) => {
		return _.every(InsuranceDetails, (value, key) => {
			let result = false;
			if (key == 'is_policyholder' && value == true) result = true;
			else if (typeof value == 'string') {
				result = _.isEmpty(value);
			} else {
				result = _.isNil(value);
			}
			return result;
		});
	};

	const primaryInsurance =
		!isEmpty(patientInsuranceInfo) &&
		!isNil(patientInsuranceInfo) &&
		formatePayloadForInsurance(
			(patientInsuranceInfo || []).filter(
				(el) =>
					el.insurance_type === 'primary' &&
					el.lab_insurance === false &&
					el.visit_insurance === true
			)?.[0] || {}
		);

	const secondaryInsurance =
		!isEmpty(patientInsuranceInfo) &&
		!isNil(patientInsuranceInfo) &&
		formatePayloadForInsurance(
			(patientInsuranceInfo || []).filter(
				(el) =>
					el.insurance_type === 'secondary' &&
					el.lab_insurance === false &&
					el.visit_insurance === true
			)?.[0] || {}
		);

	const labInsurance =
		!isEmpty(patientInsuranceInfo) &&
		!isNil(patientInsuranceInfo) &&
		formatePayloadForInsurance(
			(patientInsuranceInfo || []).filter(
				(el) =>
					el.insurance_type === 'primary' &&
					el.lab_insurance === true &&
					el.visit_insurance === false
			)?.[0] || {}
		);

	const insObjPrimary =
		insuranceCompanies &&
		insuranceCompanies.find(
			(a) => a && a.id && a.id == primaryInsurance.insurance_company_type
		);
	const insObjSecondary =
		insuranceCompanies &&
		insuranceCompanies.find(
			(a) =>
				a && a.id && a.id == secondaryInsurance.insurance_company_type
		);

	/* For lab insurance, get full list of insurance companies, irrespective of admin switches */
	const insObjLab =
		fullListOfInsuranceCompanies &&
		fullListOfInsuranceCompanies.find(
			(a) => a && a.id && a.id == labInsurance.insurance_company_type
		);

	const getInsuranceCompanyID = (name) => {
		const nameStr = name && name.toString();
		const obj = insuranceCompanies.find((a) => a && a.name == nameStr);
		return (obj && obj.id && obj.id.toString()) || '';
	};

	const getLabInsuranceCompanyID = (name) => {
		const nameStr = name && name.toString();
		const obj = fullListOfInsuranceCompanies.find(
			(a) => a && a.name == nameStr
		);
		return (obj && obj.id && obj.id.toString()) || '';
	};

	const setInsuranceAddress = (insObj = {}, patientInsurance) => {
		const insuranceAddress = {};
		const isNotEmptyNorNil = (value) =>
			!isNil(value) && !isEmpty(value) ? true : false;
		if (isNotEmptyNorNil(patientInsurance.insurance_address)) {
			insuranceAddress.insurance_address =
				patientInsurance.insurance_address || '';
		} else {
			insuranceAddress.insurance_address =
				insObj.athena_insurance_package_address || '';
		}

		if (isNotEmptyNorNil(patientInsurance.insurance_city)) {
			insuranceAddress.insurance_city =
				patientInsurance.insurance_city || '';
		} else {
			insuranceAddress.insurance_city =
				insObj.athena_insurance_package_city || '';
		}

		if (isNotEmptyNorNil(patientInsurance.insurance_state)) {
			insuranceAddress.insurance_state =
				patientInsurance.insurance_state || '';
		} else {
			insuranceAddress.insurance_state =
				insObj.athena_insurance_package_state || '';
		}

		if (isNotEmptyNorNil(patientInsurance.insurance_zipcode)) {
			insuranceAddress.insurance_zipcode =
				patientInsurance.insurance_zipcode || '';
		} else {
			insuranceAddress.insurance_zipcode =
				insObj.athena_insurance_package_zip || '';
		}

		if (isNotEmptyNorNil(patientInsurance.phone)) {
			insuranceAddress.phone = patientInsurance.phone || '';
		} else {
			insuranceAddress.phone =
				insObj.athena_insurance_package_phone || '';
		}

		return insuranceAddress;
	};

	const setPrimaryPolicyholderBiologicalSex = () => {
		const isNotEmptyNorNil = (value) =>
			!isNil(value) && !isEmpty(value) ? true : false;
		if (isNotEmptyNorNil(primaryInsurance.policyholder_biological_sex)) {
			return primaryInsurance.policyholder_biological_sex;
		} else if (
			biologicalSexVariants.find(
				(a) => a.value == primaryInsurance.gender
			)
		) {
			return primaryInsurance.gender;
		}
		return '';
	};

	const setSecondaryPolicyholderBiologicalSex = () => {
		const isNotEmptyNorNil = (value) =>
			!isNil(value) && !isEmpty(value) ? true : false;
		if (isNotEmptyNorNil(secondaryInsurance.policyholder_biological_sex)) {
			return secondaryInsurance.policyholder_biological_sex;
		} else if (
			biologicalSexVariants.find(
				(a) => a.value == secondaryInsurance.gender
			)
		) {
			return secondaryInsurance.gender;
		}
		return '';
	};

	const setLabPolicyholderBiologicalSex = () => {
		const isNotEmptyNorNil = (value) =>
			!isNil(value) && !isEmpty(value) ? true : false;
		if (isNotEmptyNorNil(labInsurance.policyholder_biological_sex)) {
			return labInsurance.policyholder_biological_sex;
		} else if (
			biologicalSexVariants.find((a) => a.value == labInsurance.gender)
		) {
			return labInsurance.gender;
		}
		return '';
	};

	const primaryInsuranceAddress = setInsuranceAddress(
		insObjPrimary,
		primaryInsurance
	);
	const secondaryInsuranceAddress = setInsuranceAddress(
		insObjSecondary,
		secondaryInsurance
	);
	const labInsuranceAddress = setInsuranceAddress(insObjLab, labInsurance);

	const [savingInsurance, setSavingInsurance] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [serverErrors, setServerErrors] = useState([]);
	const [form, setForm] = useState({
		primary_insurance_company_id: getInsuranceCompanyID(
			primaryInsurance.insurance_company_type
		),
		primary_company_name: primaryInsurance.company_name || '',
		primary_insurance_no: primaryInsurance.insurance_no || '',
		primary_group_number: primaryInsurance.group_no || '',
		primary_plan_name: primaryInsurance.insurance_plan_name || '',
		primary_phone: primaryInsuranceAddress.phone || '',
		primary_is_policyholder: !isNil(primaryInsurance.is_policyholder)
			? primaryInsurance.is_policyholder
			: true,
		primary_policyholder_relationship:
			primaryInsurance.policyholder_relationship || '',
		primary_policyholder_first_name:
			primaryInsurance.policyholder_first_name || '',
		primary_policyholder_last_name:
			primaryInsurance.policyholder_last_name || '',
		primary_policyholder_dob:
			primaryInsurance.policyholder_dob &&
			moment(primaryInsurance.policyholder_dob).isValid()
				? moment(primaryInsurance.policyholder_dob).format('MM/DD/YYYY')
				: '',
		primary_patient_id: primaryInsurance.id || '',
		primary_employer: primaryInsurance.employer || '',
		primary_insurance_address:
			primaryInsuranceAddress.insurance_address || '',
		primary_insurance_city: primaryInsuranceAddress.insurance_city || '',
		primary_insurance_state: primaryInsuranceAddress.insurance_state || '',
		primary_insurance_zipcode:
			primaryInsuranceAddress.insurance_zipcode || '',
		primary_policyholder_biological_sex:
			setPrimaryPolicyholderBiologicalSex(),
		primary_athena_insurance_package_id:
			primaryInsurance.athena_insurance_package_id || '',
		primary_athena_insurance_package_name:
			primaryInsurance.athena_insurance_package_name || '',
		primary_athena_insurance_id: primaryInsurance.athena_insurance_id || '',
		secondary_insurance_company_id: getInsuranceCompanyID(
			secondaryInsurance.insurance_company_type
		),
		secondary_company_name: secondaryInsurance.company_name || '',
		secondary_insurance_no: secondaryInsurance.insurance_no || '',
		secondary_group_number: secondaryInsurance.group_no || '',
		secondary_plan_name: secondaryInsurance.insurance_plan_name || '',
		secondary_phone: secondaryInsuranceAddress.phone || '',
		secondary_is_policyholder: !isNil(secondaryInsurance.is_policyholder)
			? secondaryInsurance.is_policyholder
			: true,
		secondary_policyholder_relationship:
			secondaryInsurance.policyholder_relationship || '',
		secondary_policyholder_first_name:
			secondaryInsurance.policyholder_first_name || '',
		secondary_policyholder_last_name:
			secondaryInsurance.policyholder_last_name || '',
		secondary_policyholder_dob:
			secondaryInsurance.policyholder_dob &&
			moment(secondaryInsurance.policyholder_dob).isValid()
				? moment(secondaryInsurance.policyholder_dob).format(
						'MM/DD/YYYY'
				  )
				: '',
		secondary_patient_id: secondaryInsurance.id || '',
		secondary_employer: secondaryInsurance.employer || '',
		secondary_insurance_address:
			secondaryInsuranceAddress.insurance_address || '',
		secondary_insurance_city:
			secondaryInsuranceAddress.insurance_city || '',
		secondary_insurance_state:
			secondaryInsuranceAddress.insurance_state || '',
		secondary_insurance_zipcode:
			secondaryInsuranceAddress.insurance_zipcode || '',
		secondary_policyholder_biological_sex:
			setSecondaryPolicyholderBiologicalSex(),
		secondary_athena_insurance_package_id:
			secondaryInsurance.athena_insurance_package_id || '',
		secondary_athena_insurance_package_name:
			secondaryInsurance.athena_insurance_package_name || '',
		secondary_athena_insurance_id:
			secondaryInsurance.athena_insurance_id || '',
		lab_insurance_company_id: getLabInsuranceCompanyID(
			labInsurance.insurance_company_type
		),
		lab_company_name: labInsurance.company_name || '',
		lab_insurance_no: labInsurance.insurance_no || '',
		lab_group_number: labInsurance.group_no || '',
		lab_plan_name: labInsurance.insurance_plan_name || '',
		lab_phone: labInsuranceAddress.phone || '',
		lab_is_policyholder: !isNil(labInsurance.is_policyholder)
			? labInsurance.is_policyholder
			: true,
		lab_policyholder_relationship:
			labInsurance.policyholder_relationship || '',
		lab_policyholder_first_name: labInsurance.policyholder_first_name || '',
		lab_policyholder_last_name: labInsurance.policyholder_last_name || '',
		lab_policyholder_dob:
			labInsurance.policyholder_dob &&
			moment(labInsurance.policyholder_dob).isValid()
				? moment(labInsurance.policyholder_dob).format('MM/DD/YYYY')
				: '',
		lab_patient_id: labInsurance.id || '',
		lab_employer: labInsurance.employer || '',
		lab_insurance_address: labInsuranceAddress.insurance_address || '',
		lab_insurance_city: labInsuranceAddress.insurance_city || '',
		lab_insurance_state: labInsuranceAddress.insurance_state || '',
		lab_insurance_zipcode: labInsuranceAddress.insurance_zipcode || '',
		lab_policyholder_biological_sex: setLabPolicyholderBiologicalSex(),
	});

	const prevInsuranceCompanyId = usePrevious(
		form.primary_insurance_company_id
	);

	const formRef = useRef(null);

	const [isAddPrimaryInsurance, setIsAddPrimaryInsurance] = useState(false);

	const [isAddSecondaryInsurance, setIsAddSecondaryInsurance] =
		useState(false);

	const [isAddLabInsurance, setIsAddLabInsurance] = useState(false);

	useEffect(() => {
		if (savingInsurance) {
			if (saveInsuranceResponse?.success || false) {
				fnGetPatientInsuranceInfo();
				getPatientDetail(false);
				setServerErrors([]);
				setSubmitting(false);
				setAction(actionType.view);
				sendNotification('Insurance saved successfully', 'success');
				setServerErrors([]);
				setSubmitting(true);
				setIsAddPrimaryInsuranceFromForm(false);
				setIsAddSecondaryInsuranceFromForm(false);
				setIsAddLabInsuranceFromForm(false);
			} else {
				sendNotification('Invalid request.', 'error');
			}
			setSavingInsurance(false);
		}
	}, [saveInsuranceResponse]);

	useEffect(() => {
		if (
			prevInsuranceCompanyId !== undefined &&
			form.primary_insurance_company_id != OTHER_COMPANY
		) {
			formRef.current.validateField('primary_phone');
			formRef.current.validateField('primary_insurance_address');
			formRef.current.validateField('primary_insurance_city');
			formRef.current.validateField('primary_insurance_state');
			formRef.current.validateField('primary_insurance_zipcode');
		}
	}, [form.primary_insurance_company_id]);

	const handleSubmit = (e) => {
		setSubmitting(true);
		e.preventDefault();
		formRef.current.validate((valid) => {
			if (valid) {
				setSavingInsurance(true);
				const primaryInsuranceDetails = {};
				const secondaryInsuranceDetails = {};
				const labInsuranceDetails = {};
				forEach(form, function (value, key) {
					if (startsWith(key, 'primary_')) {
						primaryInsuranceDetails[key.replace('primary_', '')] =
							value;
					} else if (startsWith(key, 'secondary_')) {
						secondaryInsuranceDetails[
							key.replace('secondary_', '')
						] = value;
					} else if (startsWith(key, 'lab_')) {
						labInsuranceDetails[key.replace('lab_', '')] = value;
					}
				});
				const insurance = [];
				const { primaryid = null } =
					(!isEmpty(patientInsuranceInfo) &&
						!isNil(patientInsuranceInfo) &&
						(patientInsuranceInfo || []).filter(
							(el) =>
								el.insurance_type === 'primary' &&
								el.lab_insurance === false &&
								el.visit_insurance === true
						)?.[0]) ||
					{};
				if (primaryid || !isInsuranceEmpty(primaryInsuranceDetails)) {
					insurance.push({
						...primaryInsuranceDetails,
						insurance_type: 'primary',
						patient_id: patient.id || '',
						patient_uuid: patient.uuid || '',
						visit_insurance: true,
						lab_insurance: false,
					});
				}
				const { id = null } =
					(!isEmpty(patientInsuranceInfo) &&
						!isNil(patientInsuranceInfo) &&
						(patientInsuranceInfo || []).filter(
							(el) =>
								el.insurance_type === 'secondary' &&
								el.lab_insurance === false &&
								el.visit_insurance === true
						)?.[0]) ||
					{};
				if (id || !isInsuranceEmpty(secondaryInsuranceDetails)) {
					insurance.push({
						...secondaryInsuranceDetails,
						insurance_type: 'secondary',
						patient_id: patient.id || '',
						patient_uuid: patient.uuid || '',
						visit_insurance: true,
						lab_insurance: false,
					});
				}
				const { labId = null } =
					(!isEmpty(patientInsuranceInfo) &&
						!isNil(patientInsuranceInfo) &&
						(patientInsuranceInfo || []).filter(
							(el) =>
								el.insurance_type === 'primary' &&
								el.lab_insurance === true &&
								el.visit_insurance === false
						)?.[0]) ||
					{};

				if (labId || !isInsuranceEmpty(labInsuranceDetails)) {
					insurance.push({
						...labInsuranceDetails,
						insurance_type: 'primary',
						patient_id: patient.id || '',
						patient_uuid: patient.uuid || '',
						visit_insurance: false,
						lab_insurance: true,
					});
				}
				saveInsurance(insurance, patient.uuid || '');
			}
		});
	};

	const sendNotification = (message, type) => {
		enqueueSnackbar(message, {
			variant: type,
			className: 'export_notification',
			anchorOrigin: { horizontal: 'center', vertical: 'top' },
		});
	};

	const handleReset = (e) => {
		e.preventDefault();
		formRef.current.resetFields();
		setAction(actionType.view);
		setIsAddPrimaryInsuranceFromForm(false);
		setIsAddSecondaryInsuranceFromForm(false);
		setIsAddLabInsuranceFromForm(false);
	};

	const onChangePrimaryInsuranceFields = (value) => {
		const newForm = { ...form };
		const insObj =
			insuranceCompanies &&
			insuranceCompanies.find((a) => a && a.id && a.id == value);
		const policyholder_dob = primaryInsurance.policyholder_dob || '';
		if (
			value ==
			getInsuranceCompanyID(primaryInsurance.insurance_company_type || '')
		) {
			const insuranceAddress = setInsuranceAddress(
				insObj,
				primaryInsurance
			);
			newForm.primary_insurance_company_id = value;
			newForm.primary_company_name = primaryInsurance.company_name || '';
			newForm.primary_phone = insuranceAddress.phone || '';
			newForm.primary_insurance_address =
				insuranceAddress.insurance_address || '';
			newForm.primary_insurance_city =
				insuranceAddress.insurance_city || '';
			newForm.primary_insurance_state =
				insuranceAddress.insurance_state || '';
			newForm.primary_insurance_zipcode =
				insuranceAddress.insurance_zipcode || '';
			newForm.primary_athena_insurance_package_id =
				primaryInsurance.athena_insurance_package_id || '';
			newForm.primary_athena_insurance_package_name =
				primaryInsurance.athena_insurance_package_name || '';
			newForm.primary_group_number = primaryInsurance.group_no || '';
			newForm.primary_plan_name = primaryInsurance.plan_name || '';
			newForm.primary_insurance_no = primaryInsurance.insurance_no || '';
			newForm.primary_employer = primaryInsurance.employer || '';
			newForm.primary_is_policyholder =
				primaryInsurance.is_policyholder || true;
			newForm.primary_policyholder_relationship =
				primaryInsurance.policyholder_relationship || '';
			newForm.primary_policyholder_first_name =
				primaryInsurance.policyholder_first_name || '';
			newForm.primary_policyholder_last_name =
				primaryInsurance.policyholder_last_name || '';
			newForm.primary_policyholder_dob =
				policyholder_dob && moment(policyholder_dob).isValid()
					? moment(policyholder_dob).format('MM/DD/YYYY')
					: '';
			newForm.primary_policyholder_biological_sex =
				primaryInsurance.policyholder_biological_sex || '';
		} else {
			newForm.primary_insurance_company_id = value;
			newForm.primary_company_name =
				value != OTHER_COMPANY ? form.primary_company_name : '';
			newForm.primary_phone = !isNil(
				insObj.athena_insurance_package_phone || ''
			)
				? insObj.athena_insurance_package_phone
				: '';
			newForm.primary_insurance_address =
				insObj.athena_insurance_package_address || '';
			newForm.primary_insurance_city =
				insObj.athena_insurance_package_city || '';
			newForm.primary_insurance_state =
				insObj.athena_insurance_package_state || '';
			newForm.primary_insurance_zipcode =
				insObj.athena_insurance_package_zip || '';
			newForm.primary_athena_insurance_package_id =
				insObj.athena_insurance_package_id || '';
			newForm.primary_athena_insurance_package_name =
				insObj.athena_insurance_package_name || '';
			newForm.primary_group_number = '';
			newForm.primary_plan_name = '';
			newForm.primary_insurance_no = '';
			newForm.primary_employer = '';
		}

		setForm(newForm);
	};

	const onChangeSecondaryInsuranceFields = (value) => {
		const newForm = { ...form };
		const insObj =
			insuranceCompanies &&
			insuranceCompanies.find((a) => a && a.id && a.id == value);
		const policyholder_dob = secondaryInsurance.policyholder_dob || '';
		if (
			value ==
			getInsuranceCompanyID(
				secondaryInsurance.insurance_company_type || ''
			)
		) {
			const insuranceAddress = setInsuranceAddress(
				insObj,
				secondaryInsurance
			);
			newForm.secondary_insurance_company_id = value;
			newForm.secondary_company_name =
				secondaryInsurance.company_name || '';
			newForm.secondary_phone = insuranceAddress.phone || '';
			newForm.secondary_insurance_address =
				insuranceAddress.insurance_address || '';
			newForm.secondary_insurance_city =
				insuranceAddress.insurance_city || '';
			newForm.secondary_insurance_state =
				insuranceAddress.insurance_state || '';
			newForm.secondary_insurance_zipcode =
				insuranceAddress.insurance_zipcode || '';
			newForm.secondary_athena_insurance_package_id =
				secondaryInsurance.athena_insurance_package_id || '';
			newForm.secondary_athena_insurance_package_name =
				secondaryInsurance.athena_insurance_package_name || '';
			newForm.secondary_group_number = secondaryInsurance.group_no || '';
			newForm.secondary_plan_name =
				secondaryInsurance.insurance_plan_name || '';
			newForm.secondary_insurance_no =
				secondaryInsurance.insurance_no || '';
			newForm.secondary_employer = secondaryInsurance.employer || '';
			newForm.secondary_is_policyholder =
				secondaryInsurance.is_policyholder || true;
			newForm.secondary_policyholder_relationship =
				secondaryInsurance.policyholder_relationship || '';
			newForm.secondary_policyholder_first_name =
				secondaryInsurance.policyholder_first_name || '';
			newForm.secondary_policyholder_last_name =
				secondaryInsurance.policyholder_last_name || '';
			newForm.secondary_policyholder_dob =
				policyholder_dob && moment(policyholder_dob).isValid()
					? moment(policyholder_dob).format('MM/DD/YYYY')
					: '';
			newForm.secondary_policyholder_biological_sex =
				secondaryInsurance.policyholder_biological_sex || '';
		} else {
			newForm.secondary_insurance_company_id = value;
			newForm.secondary_company_name =
				value != OTHER_COMPANY ? form.company_name : '';
			newForm.secondary_phone = !isNil(
				insObj.athena_insurance_package_phone || ''
			)
				? insObj.athena_insurance_package_phone
				: '';
			newForm.secondary_insurance_address =
				insObj.athena_insurance_package_address || '';
			newForm.secondary_insurance_city =
				insObj.athena_insurance_package_city || '';
			newForm.secondary_insurance_state =
				insObj.athena_insurance_package_state || '';
			newForm.secondary_insurance_zipcode =
				insObj.athena_insurance_package_zip || '';
			newForm.secondary_athena_insurance_package_id =
				insObj.athena_insurance_package_id || '';
			newForm.secondary_athena_insurance_package_name =
				insObj.athena_insurance_package_name || '';
			newForm.secondary_group_number = '';
			newForm.secondary_plan_name = '';
			newForm.secondary_insurance_no = '';
			newForm.secondary_employer = '';
		}

		setForm(newForm);
	};

	const onChangeLabInsuranceFields = (value) => {
		const newForm = { ...form };
		const insObj =
			fullListOfInsuranceCompanies &&
			fullListOfInsuranceCompanies.find(
				(a) => a && a.id && a.id == value
			);
		const policyholder_dob = labInsurance.policyholder_dob || '';
		if (
			value ==
			getLabInsuranceCompanyID(labInsurance.insurance_company_type || '')
		) {
			const insuranceAddress = setInsuranceAddress(insObj, labInsurance);
			newForm.lab_insurance_company_id = value;
			newForm.lab_company_name = labInsurance.company_name || '';
			newForm.lab_phone = insuranceAddress.phone || '';
			newForm.lab_insurance_address =
				insuranceAddress.insurance_address || '';
			newForm.lab_insurance_city = insuranceAddress.insurance_city || '';
			newForm.lab_insurance_state =
				insuranceAddress.insurance_state || '';
			newForm.lab_insurance_zipcode =
				insuranceAddress.insurance_zipcode || '';
			newForm.lab_group_number = labInsurance.group_no || '';
			newForm.lab_plan_name = labInsurance.insurance_plan_name || '';
			newForm.lab_insurance_no = labInsurance.insurance_no || '';
			newForm.lab_employer = labInsurance.employer || '';
			newForm.lab_is_policyholder = labInsurance.is_policyholder || true;
			newForm.lab_policyholder_relationship =
				labInsurance.policyholder_relationship || '';
			newForm.lab_policyholder_first_name =
				labInsurance.policyholder_first_name || '';
			newForm.lab_policyholder_last_name =
				labInsurance.policyholder_last_name || '';
			newForm.lab_policyholder_dob =
				policyholder_dob && moment(policyholder_dob).isValid()
					? moment(policyholder_dob).format('MM/DD/YYYY')
					: '';
			newForm.lab_policyholder_biological_sex =
				labInsurance.policyholder_biological_sex || '';
		} else {
			newForm.lab_insurance_company_id = value;
			newForm.lab_company_name =
				value != OTHER_COMPANY ? form.company_name : '';
			newForm.lab_phone = insObj?.athena_insurance_package_phone || '';
			newForm.lab_insurance_address =
				insObj?.athena_insurance_package_address || '';
			newForm.lab_insurance_city =
				insObj?.athena_insurance_package_city || '';
			newForm.lab_insurance_state =
				insObj?.athena_insurance_package_state || '';
			newForm.lab_insurance_zipcode =
				insObj?.athena_insurance_package_zip || '';
			newForm.lab_group_number = '';
			newForm.lab_plan_name = '';
			newForm.lab_insurance_no = '';
			newForm.lab_employer = '';
		}

		setForm(newForm);
	};

	const onChange = (key, value) => {
		const updateForm = (k, v) => {
			setForm((form) => ({
				...form,
				[k]: v,
			}));
		};

		switch (key) {
			case 'primary_policyholder_dob':
				updateForm(
					key,
					value instanceof moment ? moment(value) : value
				);
				break;
			case 'primary_insurance_company_id':
				onChangePrimaryInsuranceFields(value);
				break;
			case 'primary_is_policyholder':
				if (value === true) {
					let primary_policyholder_biological_sex = '';
					if (
						biologicalSexVariants.find(
							(a) => a.value == primaryInsurance.gender
						)
					) {
						primary_policyholder_biological_sex =
							primaryInsurance.gender;
					}
					setForm((form) => ({
						...form,
						[key]: value,
						primary_policyholder_relationship: '',
						primary_policyholder_first_name: '',
						primary_policyholder_last_name: '',
						primary_policyholder_dob: '',
						primary_policyholder_biological_sex,
					}));
				} else {
					setForm((form) => ({
						...form,
						[key]: value,
						primary_policyholder_biological_sex: '',
					}));
				}
				break;
			case 'secondary_policyholder_dob':
				updateForm(
					key,
					value instanceof moment ? moment(value) : value
				);
				break;
			case 'secondary_insurance_company_id':
				onChangeSecondaryInsuranceFields(value);
				break;
			case 'secondary_is_policyholder':
				if (value === true) {
					let secondary_policyholder_biological_sex = '';
					if (
						biologicalSexVariants.find(
							(a) => a.value == secondaryInsurance.gender
						)
					) {
						secondary_policyholder_biological_sex =
							secondaryInsurance.gender;
					}
					setForm((form) => ({
						...form,
						[key]: value,
						secondary_policyholder_relationship: '',
						secondary_policyholder_first_name: '',
						secondary_policyholder_last_name: '',
						secondary_policyholder_dob: '',
						secondary_policyholder_biological_sex,
					}));
				} else {
					setForm((form) => ({
						...form,
						[key]: value,
						secondary_policyholder_biological_sex: '',
					}));
				}
				break;
			case 'lab_policyholder_dob':
				updateForm(
					key,
					value instanceof moment ? moment(value) : value
				);
				break;
			case 'lab_insurance_company_id':
				onChangeLabInsuranceFields(value);
				break;
			case 'lab_is_policyholder':
				if (value === true) {
					let lab_policyholder_biological_sex = '';
					if (
						biologicalSexVariants.find(
							(a) => a.value == labInsurance.gender
						)
					) {
						lab_policyholder_biological_sex = labInsurance.gender;
					}
					setForm((form) => ({
						...form,
						[key]: value,
						lab_policyholder_relationship: '',
						lab_policyholder_first_name: '',
						lab_policyholder_last_name: '',
						lab_policyholder_dob: '',
						lab_policyholder_biological_sex,
					}));
				} else {
					setForm((form) => ({
						...form,
						[key]: value,
						lab_policyholder_biological_sex: '',
					}));
				}
				break;

			default:
				updateForm(key, value);
				break;
		}

		setSubmitting(false);
	};

	const handleChange = (e) => onChange(e.target.name, e.target.value);
	const onSubmit = (e) => e.preventDefault();

	const fnGetPatientInsuranceInfo = () => {
		getPatientInsuranceInfo(params.patientid);
	};

	const getServerError = (pref, field) => {
		const errObj = serverErrors[pref] || {};
		return !isEmpty(form[pref + '_' + field] || '') && errObj[field] ? (
			<label className='label-server-error'>
				{errObj[field]['validValue']}
			</label>
		) : (
			''
		);
	};

	const getPlanTypes = (insurance_plan_name) => {
		const planName = insurance_plan_name || '';

		let customPlanTypeEnums = planTypesEnums;

		if (!isEmpty(planTypesEnums)) {
			customPlanTypeEnums = [
				{ name: '', display_name: '' },
				...planTypesEnums,
			];
			if (!isNil(planName)) {
				const existingPlanName = customPlanTypeEnums.find(
					(type) => type.name == planName
				);
				if (!existingPlanName) {
					customPlanTypeEnums = [
						...customPlanTypeEnums,
						{ name: planName, display_name: planName },
					];
				}
			}
			return [...customPlanTypeEnums];
		}
		return [];
	};

	const primaryHasOther = form.primary_insurance_company_id == OTHER_COMPANY;
	const isPrimaryPolicyHolder = !isNil(form.primary_is_policyholder)
		? form.primary_is_policyholder
		: true;

	const secondaryHasOther =
		form.secondary_insurance_company_id == OTHER_COMPANY;
	const isSecondaryPolicyHolder = !isNil(form.secondary_is_policyholder)
		? form.secondary_is_policyholder
		: true;

	const labHasOther = form.lab_insurance_company_id == OTHER_COMPANY;
	const isLabPolicyHolder = !isNil(form.lab_is_policyholder)
		? form.lab_is_policyholder
		: true;

	const isSaveDisabled = () => {
		if (
			(isActionTypeEdit && !isInsuranceEmpty(primaryInsurance)) ||
			(isActionTypeEdit && !isInsuranceEmpty(labInsurance)) ||
			isAddPrimaryInsurance ||
			isAddSecondaryInsurance ||
			isAddLabInsurance ||
			isAddPrimaryInsuranceFromForm ||
			isAddSecondaryInsuranceFromForm ||
			isAddLabInsuranceFromForm
		) {
			return false;
		}
		return true;
	};

	const showPrimaryInsuranceFormFields =
		isAddPrimaryInsurance ||
		isAddPrimaryInsuranceFromForm ||
		(isActionTypeEdit && !isInsuranceEmpty(primaryInsurance));

	const showSecondaryInsuranceFormFields =
		isAddSecondaryInsurance ||
		isAddSecondaryInsuranceFromForm ||
		(isActionTypeEdit && !isInsuranceEmpty(secondaryInsurance));

	const showDivBorderBottomForPrimaryInsurance =
		!isInsuranceEmpty(primaryInsurance) ||
		isAddPrimaryInsurance ||
		isAddPrimaryInsuranceFromForm;

	const isShowPrimaryInsuranceAddIcon =
		(isActionTypeEdit &&
			isInsuranceEmpty(primaryInsurance) &&
			!isAddPrimaryInsurance) ||
		(!isActionTypeEdit &&
			!isAddPrimaryInsuranceFromForm &&
			!isAddPrimaryInsurance);

	const isShowPrimaryInsuranceEditIcon =
		(isAddLabInsuranceFromForm || isAddSecondaryInsuranceFromForm) &&
		!isInsuranceEmpty(primaryInsurance) &&
		!isAddPrimaryInsurance;

	const isShowSecondaryInsuranceTitleWithAdd =
		!isInsuranceEmpty(primaryInsurance) ||
		isAddPrimaryInsurance ||
		isAddPrimaryInsuranceFromForm;

	const isShowSecondaryInsuranceAddIcon =
		(isActionTypeEdit &&
			isInsuranceEmpty(secondaryInsurance) &&
			!isAddSecondaryInsurance) ||
		(!isActionTypeEdit &&
			!isAddSecondaryInsuranceFromForm &&
			!isAddSecondaryInsurance) ||
		(!isInsuranceEmpty(primaryInsurance) &&
			isAddLabInsuranceFromForm &&
			!isAddSecondaryInsurance);

	const isShowSecondaryInsuranceEditIcon =
		(isAddLabInsuranceFromForm || isAddPrimaryInsuranceFromForm) &&
		!isInsuranceEmpty(secondaryInsurance) &&
		!isAddSecondaryInsurance;

	return (
		<div className='payment-leftblock'>
			<div className='insurance-info'>
				<div className='col-12'>
					<div className='insurance-header'>
						Patient's Insurance Card
					</div>
				</div>

				<Loading
					loading={savingInsurance}
					className={classnames(
						savingInsurance
							? 'insurance-loader loader-insurance-form'
							: ''
					)}
				>
					<div className='d-flex-primary'>
						<div className='insurance-type-heading-text'>
							Primary Insurance
						</div>
						{isShowPrimaryInsuranceAddIcon &&
							!isShowPrimaryInsuranceEditIcon && (
								<div
									type='button'
									className='btn-add-primary-insurance'
									onClick={() => {
										setIsAddPrimaryInsurance(true);
									}}
								>
									<ReactSVG
										className='btn-plus-icon'
										src={add}
									/>
								</div>
							)}
						{isShowPrimaryInsuranceEditIcon && (
							<RBAC
								action={
									CARE_PATIENTS_PATIENT_PAYMENTS_INSURANCE_ACTIONS
								}
								yes={
									<div
										data-testId='edit-btn'
										onClick={() => {
											setIsAddPrimaryInsurance(true);
										}}
										className='insurance-edit-button'
									>
										<img
											src={editInsuranceButton}
											alt=''
											style={{
												position: 'relative',
												bottom: '5px',
											}}
										/>
									</div>
								}
							/>
						)}
					</div>

					<Form
						ref={formRef}
						className={classnames(
							'insurance-form',
							submitting ? 'is-submitting' : ''
						)}
						model={form}
						rules={rules}
						onSubmit={onSubmit}
					>
						{showPrimaryInsuranceFormFields && (
							<>
								{!primaryHasOther ? (
									<div className='col-12'>
										<div
											className={classnames(
												'pad-right-20px full_width'
											)}
										>
											<Form.Item
												label='Health Insurance'
												prop='primary_insurance_company_id'
											>
												<Select
													value={
														form.primary_insurance_company_id
													}
													placeholder='Select'
													onChange={(e) =>
														onChange(
															'primary_insurance_company_id',
															e
														)
													}
												>
													{insuranceCompanies.map(
														(el) => (
															<Select.Option
																key={
																	'primary_insurance_company_' +
																	el.name
																}
																label={el.name}
																value={el.id.toString()}
															/>
														)
													)}
												</Select>
											</Form.Item>
										</div>
									</div>
								) : (
									<div className='col-12'>
										<Form.Item
											label='Health Insurance'
											prop='primary_company_name'
											className={classnames(
												submitting &&
													isEmpty(
														form.primary_company_name
													)
													? 'no-error-required'
													: ''
											)}
										>
											<Input
												value={
													form.primary_company_name
												}
												onChange={(e) =>
													onChange(
														'primary_company_name',
														e
													)
												}
												placeholder='Health insurance company'
												className={classnames(
													'field_company_name',
													primaryHasOther &&
														isEmpty(
															form.primary_company_name
														)
														? 'is-errors is-company-errors'
														: '',
													'prependedSelect'
												)}
												trim={true}
												prepend={
													<Form.Item
														className='pull-left company_name_field'
														label=''
														prop='primary_insurance_company_id'
													>
														<Select
															className='other-selection'
															value={
																form.primary_insurance_company_id
															}
															placeholder='Select'
															onChange={(e) =>
																onChange(
																	'primary_insurance_company_id',
																	e
																)
															}
														>
															{insuranceCompanies &&
																insuranceCompanies.map(
																	(el) => (
																		<Select.Option
																			key={
																				'primary_insurance_company_' +
																				el.name
																			}
																			label={
																				el.name
																			}
																			value={el.id.toString()}
																		/>
																	)
																)}
														</Select>
													</Form.Item>
												}
											/>
										</Form.Item>
									</div>
								)}
								<div className='col-12 float-left'>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Insurance ID #'
											prop='primary_insurance_no'
										>
											<Input
												value={
													form.primary_insurance_no
												}
												placeholder='1234567890'
												trim={true}
												onChange={(e) =>
													onChange(
														'primary_insurance_no',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'primary',
											'insurance_no'
										)}
									</div>
									<div className='col-4 pad-right-20px policyholder-label'>
										<div className='insurance-phn'>
											<label className='el-form-item__label phone-star'>
												Insurance phone number
											</label>
											<Tooltip
												placement='top'
												className='phone-tooltip-mainb2'
												content={
													<div className='phone-tooltip'>
														Please Enter the
														Insurance <br />
														Phone Number Indicated
														on the <br />
														Back of Your Insurance
														Card
													</div>
												}
											>
												<img
													className='phone-tooltip-icon'
													src={helpIcon}
													alt=''
												/>
											</Tooltip>
										</div>

										<Form.Item prop='primary_phone'>
											<InputMask
												className='inputFieldText inputFieldTextTaller'
												name='primary_phone'
												mask='+1 (999) 999-9999'
												value={form.primary_phone || ''}
												placeholder='(123) 456 - 7890'
												onChange={handleChange}
												maskChar={null}
											/>
										</Form.Item>
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Plan type (optional)'
											prop='primary_plan_name'
										>
											<Select
												value={form.primary_plan_name}
												placeholder='Select'
												onChange={(e) =>
													onChange(
														'primary_plan_name',
														e
													)
												}
												className='inputFieldDropdown inputFieldDropdownTaller'
											>
												{getPlanTypes(
													form.primary_plan_name
												).map((el) => (
													<Select.Option
														key={
															'plan_name_' +
															el.name
														}
														label={
															el.display_name ||
															''
														}
														value={el.name || ''}
													/>
												))}
											</Select>
										</Form.Item>
										{getServerError('primary', 'plan_name')}
									</div>
								</div>
								<div className='col-12 float-left'>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Group #'
											prop='primary_group_number'
										>
											<Input
												value={
													form.primary_group_number
												}
												placeholder='1234567890'
												trim={true}
												onChange={(e) =>
													onChange(
														'primary_group_number',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'primary',
											'group_number'
										)}
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Employer'
											prop='primary_employer'
										>
											<Input
												value={form.primary_employer}
												placeholder='Company ABC'
												trim={true}
												onChange={(e) =>
													onChange(
														'primary_employer',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError('primary', 'employer')}
									</div>
								</div>
								<div className='col-12'>
									<div className='pad-right-20px full_width'>
										<Form.Item
											label='Insurance address'
											prop='primary_insurance_address'
										>
											<Input
												value={
													form.primary_insurance_address
												}
												placeholder='123 Main street'
												trim={true}
												onChange={(e) =>
													onChange(
														'primary_insurance_address',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'primary',
											'insurance_address'
										)}
									</div>
								</div>
								<div className='col-12 float-left'>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Insurance city'
											prop='primary_insurance_city'
										>
											<Input
												value={
													form.primary_insurance_city
												}
												placeholder='San Francisco'
												trim={true}
												onChange={(e) =>
													onChange(
														'primary_insurance_city',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'primary',
											'insurance_city'
										)}
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Insurance state'
											prop='primary_insurance_state'
										>
											<Select
												value={
													form.primary_insurance_state
												}
												placeholder='Select'
												onChange={(e) =>
													onChange(
														'primary_insurance_state',
														e
													)
												}
												className='inputFieldDropdown inputFieldDropdownTaller'
											>
												{states_list &&
													states_list.map((el) => {
														return (
															<Select.Option
																key={
																	'insurance_state_' +
																	el.key
																}
																label={el.value}
																value={el.key}
															/>
														);
													})}
											</Select>
										</Form.Item>
										{getServerError(
											'primary',
											'insurance_state'
										)}
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Insurance zipcode'
											prop='primary_insurance_zipcode'
										>
											<Input
												value={
													form.primary_insurance_zipcode
												}
												placeholder='12345'
												trim={true}
												onChange={(e) =>
													onChange(
														'primary_insurance_zipcode',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'primary',
											'insurance_zipcode'
										)}
									</div>
								</div>
								<div className='col-12 float-left'>
									<div className='col-4 pad-right-20px policyholder-label'>
										<Form.Item
											label='Is patient the primary policyholder?'
											prop='primary_is_policyholder'
										>
											<Radio.Group
												value={
													form.primary_is_policyholder
												}
												onChange={(e) =>
													onChange(
														'primary_is_policyholder',
														e
													)
												}
											>
												<Radio value={true}>Yes</Radio>
												<Radio value={false}>No</Radio>
											</Radio.Group>
										</Form.Item>
									</div>
									{isPrimaryPolicyHolder && (
										<div className='col-4 pad-right-20px policyholder-label'>
											<Form.Item
												label={
													"Policyholder's biological sex"
												}
												prop='primary_policyholder_biological_sex'
											>
												<Select
													value={
														form.primary_policyholder_biological_sex
													}
													placeholder='Select'
													onChange={(e) =>
														onChange(
															'primary_policyholder_biological_sex',
															e
														)
													}
													className='inputFieldDropdown inputFieldDropdownTaller'
												>
													{biologicalSexVariants.map(
														(el) => (
															<Select.Option
																key={
																	'policyholder_biological_sex_' +
																	el.value
																}
																label={
																	el.label ||
																	''
																}
																value={
																	el.value ||
																	''
																}
															/>
														)
													)}
												</Select>
											</Form.Item>
											{getServerError(
												'primary',
												'policyholder_biological_sex'
											)}
										</div>
									)}
									{!isPrimaryPolicyHolder && (
										<>
											<div className='col-4 pad-right-20px policyholder-label'>
												<Form.Item
													label={
														"Policyholder's first name"
													}
													prop='primary_policyholder_first_name'
												>
													<Input
														placeholder='John'
														trim={true}
														value={
															form.primary_policyholder_first_name
														}
														onChange={(e) =>
															onChange(
																'primary_policyholder_first_name',
																e
															)
														}
														className='erFieldText inputFieldTextTaller'
													/>
												</Form.Item>
												{getServerError(
													'primary',
													'policyholder_first_name'
												)}
											</div>
											<div className='col-4 pad-right-20px policyholder-label'>
												<Form.Item
													label={
														"Policyholder's last name"
													}
													prop='primary_policyholder_last_name'
												>
													<Input
														placeholder='Smith'
														trim={true}
														value={
															form.primary_policyholder_last_name
														}
														onChange={(e) =>
															onChange(
																'primary_policyholder_last_name',
																e
															)
														}
														className='erFieldText inputFieldTextTaller'
													/>
												</Form.Item>
												{getServerError(
													'primary',
													'policyholder_last_name'
												)}
											</div>
										</>
									)}
								</div>
								{!isPrimaryPolicyHolder && (
									<div className='col-12 no-policyholder-partition float-left'>
										<div className='col-4 pad-right-20px policyholder-label'>
											<Form.Item
												label={
													"Policyholder's biological sex"
												}
												prop='primary_policyholder_biological_sex'
											>
												<Select
													value={
														form.primary_policyholder_biological_sex
													}
													placeholder='Select'
													onChange={(e) =>
														onChange(
															'primary_policyholder_biological_sex',
															e
														)
													}
													className='inputFieldDropdown inputFieldDropdownTaller'
												>
													{biologicalSexVariants.map(
														(el) => (
															<Select.Option
																key={
																	'policyholder_biological_sex_' +
																	el.value
																}
																label={
																	el.label ||
																	''
																}
																value={
																	el.value ||
																	''
																}
															/>
														)
													)}
												</Select>
											</Form.Item>
											{getServerError(
												'primary',
												'policyholder_biological_sex'
											)}
										</div>
										<div className='col-4 pad-right-20px policyholder-label'>
											<Form.Item
												label='Relationship to policyholder'
												prop='primary_policyholder_relationship'
											>
												<Select
													value={
														form.primary_policyholder_relationship
													}
													placeholder='Select'
													onChange={(e) =>
														onChange(
															'primary_policyholder_relationship',
															e
														)
													}
													className='inputFieldDropdown inputFieldDropdownTaller'
												>
													{dependentVariants.map(
														(el) => (
															<Select.Option
																key={
																	'relationship_' +
																	el.value
																}
																label={
																	el.label ||
																	''
																}
																value={
																	el.value ||
																	''
																}
															/>
														)
													)}
												</Select>
											</Form.Item>
											{getServerError(
												'primary',
												'policyholder_relationship'
											)}
										</div>
										<div className='col-4 pad-right-20px policyholder-label'>
											<Form.Item
												label={"Policyholder's DOB"}
												prop='primary_policyholder_dob'
											>
												<InputMask
													value={
														form.primary_policyholder_dob
													}
													placeholder='MM/DD/YYYY'
													name='primary_policyholder_dob'
													trim={true}
													onChange={handleChange}
													mask='99/99/9999'
													maskChar={null}
													className='inputFieldText inputFieldTextTaller'
												/>
											</Form.Item>
											{getServerError(
												'primary',
												'policyholder_dob'
											)}
										</div>
									</div>
								)}
								<div className='col-12 clearfix'>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Athena insurance package ID'
											prop='primary_athena_insurance_package_id'
										>
											<Input
												value={
													form.primary_athena_insurance_package_id
												}
												trim={true}
												onChange={(e) =>
													onChange(
														'primary_athena_insurance_package_id',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'primary',
											'athena_insurance_package_id'
										)}
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Athena insurance name'
											prop='primary_athena_insurance_package_name'
										>
											<Input
												value={
													form.primary_athena_insurance_package_name
												}
												trim={true}
												onChange={(e) =>
													onChange(
														'primary_athena_insurance_package_name',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'primary',
											'athena_insurance_package_name'
										)}
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Athena patient insurance record ID'
											prop='primary_athena_insurance_id'
										>
											<Input
												value={
													form.primary_athena_insurance_id
												}
												trim={true}
												disabled={true}
												onChange={(e) =>
													onChange(
														'primary_athena_insurance_id',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'primary',
											'athena_insurance_id'
										)}
									</div>
								</div>
							</>
						)}
						{showDivBorderBottomForPrimaryInsurance && (
							<span className='divBorderBottom'></span>
						)}
						{isShowSecondaryInsuranceTitleWithAdd && (
							<div
								className={classnames(
									isAddSecondaryInsuranceFromForm ||
										isAddSecondaryInsurance ||
										!isInsuranceEmpty(secondaryInsurance)
										? isAddSecondaryInsuranceFromForm ||
										  !isInsuranceEmpty(secondaryInsurance)
											? 'd-flex-secondary'
											: 'd-flex-secondary secondaryinsurancelabel'
										: 'd-flex-secondary'
								)}
							>
								<div className='insurance-type-heading-text'>
									Secondary Insurance
								</div>
								{isShowSecondaryInsuranceAddIcon &&
									!isShowSecondaryInsuranceEditIcon && (
										<div
											type='button'
											className='btn-add-secondary-insurance'
											onClick={() => {
												setIsAddSecondaryInsurance(
													true
												);
											}}
										>
											<ReactSVG
												className='btn-plus-icon'
												src={add}
											/>
										</div>
									)}
								{isShowSecondaryInsuranceEditIcon && (
									<RBAC
										action={
											CARE_PATIENTS_PATIENT_PAYMENTS_INSURANCE_ACTIONS
										}
										yes={
											<div
												data-testId='edit-btn'
												onClick={() => {
													setIsAddSecondaryInsurance(
														true
													);
												}}
												className='insurance-edit-button'
											>
												<img
													src={editInsuranceButton}
													alt=''
													style={{
														position: 'relative',
														bottom: '5px',
													}}
												/>
											</div>
										}
									/>
								)}
							</div>
						)}
						{/* )} */}
						{showSecondaryInsuranceFormFields && (
							<>
								{!secondaryHasOther ? (
									<div className='col-12'>
										<div
											className={classnames(
												'pad-right-20px full_width'
											)}
										>
											<Form.Item
												label='Health Insurance'
												prop='secondary_insurance_company_id'
											>
												<Select
													value={
														form.secondary_insurance_company_id
													}
													placeholder='Select'
													onChange={(e) =>
														onChange(
															'secondary_insurance_company_id',
															e
														)
													}
												>
													{insuranceCompanies.map(
														(el) => (
															<Select.Option
																key={
																	'secondary_insurance_company_' +
																	el.name
																}
																label={el.name}
																value={el.id.toString()}
															/>
														)
													)}
												</Select>
											</Form.Item>
										</div>
									</div>
								) : (
									<div className='col-12'>
										<Form.Item
											label='Health Insurance'
											prop='secondary_company_name'
											className={classnames(
												submitting &&
													isEmpty(
														form.secondary_company_name
													)
													? 'no-error-required'
													: ''
											)}
										>
											<Input
												value={
													form.secondary_company_name
												}
												onChange={(e) =>
													onChange(
														'secondary_company_name',
														e
													)
												}
												placeholder='Health insurance company'
												className={classnames(
													'field_company_name',
													secondaryHasOther &&
														isEmpty(
															form.secondary_company_name
														)
														? 'is-errors is-company-errors'
														: '',
													'prependedSelect'
												)}
												trim={true}
												prepend={
													<Form.Item
														className='pull-left company_name_field'
														label=''
														prop='secondary_insurance_company_id'
													>
														<Select
															className='other-selection'
															value={
																form.secondary_insurance_company_id
															}
															placeholder='Select'
															onChange={(e) =>
																onChange(
																	'secondary_insurance_company_id',
																	e
																)
															}
														>
															{insuranceCompanies &&
																insuranceCompanies.map(
																	(el) => (
																		<Select.Option
																			key={
																				'insurance_company_' +
																				el.name
																			}
																			label={
																				el.name
																			}
																			value={el.id.toString()}
																		/>
																	)
																)}
														</Select>
													</Form.Item>
												}
											/>
										</Form.Item>
									</div>
								)}
								<div className='col-12 float-left'>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Insurance ID #'
											prop='secondary_insurance_no'
										>
											<Input
												value={
													form.secondary_insurance_no
												}
												placeholder='1234567890'
												trim={true}
												onChange={(e) =>
													onChange(
														'secondary_insurance_no',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'secondary',
											'insurance_no'
										)}
									</div>
									<div className='col-4 pad-right-20px policyholder-label'>
										<div className='insurance-phn'>
											<label className='el-form-item__label'>
												Insurance phone number
											</label>
											<Tooltip
												placement='top'
												className='phone-tooltip-mainb2'
												content={
													<div className='phone-tooltip'>
														Please Enter the
														Insurance <br />
														Phone Number Indicated
														on the <br />
														Back of Your Insurance
														Card
													</div>
												}
											>
												<img
													className='phone-tooltip-icon'
													src={helpIcon}
													alt=''
												/>
											</Tooltip>
										</div>

										<Form.Item prop='secondary_phone'>
											<InputMask
												className='inputFieldText inputFieldTextTaller'
												name='secondary_phone'
												mask='+1 (999) 999-9999'
												value={
													form.secondary_phone || ''
												}
												placeholder='(123) 456 - 7890'
												onChange={handleChange}
												maskChar={null}
											/>
										</Form.Item>
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Plan type (optional)'
											prop='secondary_plan_name'
										>
											<Select
												value={form.secondary_plan_name}
												placeholder='Select'
												onChange={(e) =>
													onChange(
														'secondary_plan_name',
														e
													)
												}
												className='inputFieldDropdown inputFieldDropdownTaller'
											>
												{getPlanTypes(
													form.secondary_plan_name
												).map((el) => (
													<Select.Option
														key={
															'plan_name_' +
															el.name
														}
														label={
															el.display_name ||
															''
														}
														value={el.name || ''}
													/>
												))}
											</Select>
										</Form.Item>
										{getServerError(
											'secondary',
											'plan_name'
										)}
									</div>
								</div>
								<div className='col-12 clearfix'>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Group #'
											prop='secondary_group_number'
										>
											<Input
												value={
													form.secondary_group_number
												}
												placeholder='1234567890'
												trim={true}
												onChange={(e) =>
													onChange(
														'secondary_group_number',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'secondary',
											'group_number'
										)}
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Employer'
											prop='secondary_employer'
										>
											<Input
												value={form.secondary_employer}
												placeholder='Company ABC'
												trim={true}
												onChange={(e) =>
													onChange(
														'secondary_employer',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'secondary',
											'employer'
										)}
									</div>
								</div>
								<div className='col-12'>
									<div className='col-12'>
										<div className='pad-right-20px full_width'>
											<Form.Item
												label='Insurance address'
												prop='secondary_insurance_address'
											>
												<Input
													value={
														form.secondary_insurance_address
													}
													placeholder='123 Main street'
													trim={true}
													onChange={(e) =>
														onChange(
															'secondary_insurance_address',
															e
														)
													}
													className='erFieldText inputFieldTextTaller'
												/>
											</Form.Item>
											{getServerError(
												'secondary',
												'insurance_address'
											)}
										</div>
									</div>
								</div>
								<div className='col-12 float-left'>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Insurance city'
											prop='secondary_insurance_city'
										>
											<Input
												value={
													form.secondary_insurance_city
												}
												placeholder='San Francisco'
												trim={true}
												onChange={(e) =>
													onChange(
														'secondary_insurance_city',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'secondary',
											'insurance_city'
										)}
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Insurance state'
											prop='secondary_insurance_state'
										>
											<Select
												value={
													form.secondary_insurance_state
												}
												placeholder='Select'
												onChange={(e) =>
													onChange(
														'secondary_insurance_state',
														e
													)
												}
												className='inputFieldDropdown inputFieldDropdownTaller'
											>
												{states_list &&
													states_list.map((el) => {
														return (
															<Select.Option
																key={
																	'insurance_state_' +
																	el.key
																}
																label={el.value}
																value={el.key}
															/>
														);
													})}
											</Select>
										</Form.Item>
										{getServerError(
											'secondary',
											'insurance_state'
										)}
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Insurance zipcode'
											prop='secondary_insurance_zipcode'
										>
											<Input
												value={
													form.secondary_insurance_zipcode
												}
												placeholder='12345'
												trim={true}
												onChange={(e) =>
													onChange(
														'secondary_insurance_zipcode',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'secondary',
											'insurance_zipcode'
										)}
									</div>
								</div>
								<div className='col-12 float-left'>
									<div className='col-4 pad-right-20px policyholder-label'>
										<Form.Item
											label='Is patient the primary policyholder?'
											prop='secondary_is_policyholder'
										>
											<Radio.Group
												value={
													form.secondary_is_policyholder
												}
												onChange={(e) =>
													onChange(
														'secondary_is_policyholder',
														e
													)
												}
											>
												<Radio value={true}>Yes</Radio>
												<Radio value={false}>No</Radio>
											</Radio.Group>
										</Form.Item>
									</div>
									{isSecondaryPolicyHolder && (
										<div className='col-4 pad-right-20px policyholder-label'>
											<Form.Item
												label={
													"Policyholder's biological sex"
												}
												prop='secondary_policyholder_biological_sex'
											>
												<Select
													value={
														form.secondary_policyholder_biological_sex
													}
													placeholder='Select'
													onChange={(e) =>
														onChange(
															'secondary_policyholder_biological_sex',
															e
														)
													}
													className='inputFieldDropdown inputFieldDropdownTaller'
												>
													{biologicalSexVariants.map(
														(el) => (
															<Select.Option
																key={
																	'policyholder_biological_sex_' +
																	el.value
																}
																label={
																	el.label ||
																	''
																}
																value={
																	el.value ||
																	''
																}
															/>
														)
													)}
												</Select>
											</Form.Item>
											{getServerError(
												'secondary',
												'policyholder_biological_sex'
											)}
										</div>
									)}
									{!isSecondaryPolicyHolder && (
										<>
											<div className='col-4 pad-right-20px policyholder-label'>
												<Form.Item
													label={
														"Policyholder's first name"
													}
													prop='secondary_policyholder_first_name'
												>
													<Input
														placeholder='John'
														trim={true}
														value={
															form.secondary_policyholder_first_name
														}
														onChange={(e) =>
															onChange(
																'secondary_policyholder_first_name',
																e
															)
														}
														className='erFieldText inputFieldTextTaller'
													/>
												</Form.Item>
												{getServerError(
													'secondary',
													'policyholder_first_name'
												)}
											</div>
											<div className='col-4 pad-right-20px policyholder-label'>
												<Form.Item
													label={
														"Policyholder's last name"
													}
													prop='secondary_policyholder_last_name'
												>
													<Input
														placeholder='Smith'
														trim={true}
														value={
															form.secondary_policyholder_last_name
														}
														onChange={(e) =>
															onChange(
																'secondary_policyholder_last_name',
																e
															)
														}
														className='erFieldText inputFieldTextTaller'
													/>
												</Form.Item>
												{getServerError(
													'secondary',
													'policyholder_last_name'
												)}
											</div>
										</>
									)}
								</div>
								{!isSecondaryPolicyHolder && (
									<div className='col-12 no-policyholder-partition float-left'>
										<div className='col-4 pad-right-20px policyholder-label'>
											<Form.Item
												label={
													"Policyholder's biological sex"
												}
												prop='secondary_policyholder_biological_sex'
											>
												<Select
													value={
														form.secondary_policyholder_biological_sex
													}
													placeholder='Select'
													onChange={(e) =>
														onChange(
															'secondary_policyholder_biological_sex',
															e
														)
													}
													className='inputFieldDropdown inputFieldDropdownTaller'
												>
													{biologicalSexVariants.map(
														(el) => (
															<Select.Option
																key={
																	'policyholder_biological_sex_' +
																	el.value
																}
																label={
																	el.label ||
																	''
																}
																value={
																	el.value ||
																	''
																}
															/>
														)
													)}
												</Select>
											</Form.Item>
											{getServerError(
												'secondary',
												'policyholder_biological_sex'
											)}
										</div>
										<div className='col-4 pad-right-20px policyholder-label'>
											<Form.Item
												label='Relationship to policyholder'
												prop='secondary_policyholder_relationship'
											>
												<Select
													value={
														form.secondary_policyholder_relationship
													}
													placeholder='Select'
													onChange={(e) =>
														onChange(
															'secondary_policyholder_relationship',
															e
														)
													}
													className='inputFieldDropdown inputFieldDropdownTaller'
												>
													{dependentVariants.map(
														(el) => (
															<Select.Option
																key={
																	'relationship_' +
																	el.value
																}
																label={
																	el.label ||
																	''
																}
																value={
																	el.value ||
																	''
																}
															/>
														)
													)}
												</Select>
											</Form.Item>
											{getServerError(
												'secondary',
												'policyholder_relationship'
											)}
										</div>
										<div className='col-4 pad-right-20px policyholder-label'>
											<Form.Item
												label={"Policyholder's DOB"}
												prop='secondary_policyholder_dob'
											>
												<InputMask
													value={
														form.secondary_policyholder_dob
													}
													placeholder='MM/DD/YYYY'
													name='secondary_policyholder_dob'
													trim={true}
													onChange={handleChange}
													mask='99/99/9999'
													maskChar={null}
													className='inputFieldText inputFieldTextTaller'
												/>
											</Form.Item>
											{getServerError(
												'secondary',
												'policyholder_dob'
											)}
										</div>
									</div>
								)}
								<div className='col-12 clearfix'>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Athena insurance package ID'
											prop='secondary_athena_insurance_package_id'
										>
											<Input
												value={
													form.secondary_athena_insurance_package_id
												}
												trim={true}
												onChange={(e) =>
													onChange(
														'secondary_athena_insurance_package_id',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'secondary',
											'athena_insurance_package_id'
										)}
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Athena insurance name'
											prop='secondary_athena_insurance_package_name'
										>
											<Input
												value={
													form.secondary_athena_insurance_package_name
												}
												trim={true}
												onChange={(e) =>
													onChange(
														'secondary_athena_insurance_package_name',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'secondary',
											'athena_insurance_package_name'
										)}
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Athena patient insurance record ID'
											prop='secondary_athena_insurance_id'
										>
											<Input
												value={
													form.secondary_athena_insurance_id
												}
												trim={true}
												disabled={true}
												onChange={(e) =>
													onChange(
														'secondary_athena_insurance_id',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'secondary',
											'athena_insurance_id'
										)}
									</div>
								</div>
							</>
						)}

						{((!isAddSecondaryInsuranceFromForm &&
							isAddLabInsuranceFromForm) ||
							(isAddSecondaryInsuranceFromForm &&
								!isAddLabInsuranceFromForm) ||
							(!isAddSecondaryInsuranceFromForm &&
								!isAddLabInsuranceFromForm)) && (
							<span
								className={classnames(
									!isInsuranceEmpty(labInsurance) &&
										'divBorderBottom'
								)}
							></span>
						)}
						<div
							className={classnames(
								isAddLabInsuranceFromForm ||
									isAddLabInsurance ||
									!isInsuranceEmpty(labInsurance)
									? isAddLabInsuranceFromForm ||
									  !isInsuranceEmpty(labInsurance)
										? 'd-flex-secondary lab-insurance-label-bb'
										: 'd-flex-secondary lab-insurance-label'
									: 'd-flex-secondary'
							)}
						>
							<div className='insurance-type-heading-text'>
								Lab Insurance
							</div>
							{!isAddLabInsurance &&
								!isAddLabInsuranceFromForm &&
								isInsuranceEmpty(labInsurance) && (
									<div
										type='button'
										className='btn-add-lab-insurance'
										onClick={() => {
											setIsAddLabInsurance(true);
										}}
									>
										<ReactSVG
											className='btn-plus-icon'
											src={add}
										/>
									</div>
								)}
						</div>
						{(isAddLabInsurance ||
							isAddLabInsuranceFromForm ||
							!isInsuranceEmpty(labInsurance)) && (
							<>
								{!labHasOther ? (
									<div className='col-12'>
										<div
											className={classnames(
												'pad-right-20px full_width'
											)}
										>
											<Form.Item
												label='Health Insurance'
												prop='lab_insurance_company_id'
											>
												<Select
													value={
														form.lab_insurance_company_id
													}
													placeholder='Select'
													onChange={(e) =>
														onChange(
															'lab_insurance_company_id',
															e
														)
													}
												>
													{fullListOfInsuranceCompanies.map(
														(el) => (
															<Select.Option
																key={
																	'lab_insurance_company_' +
																	el.name
																}
																label={el.name}
																value={el.id.toString()}
															/>
														)
													)}
												</Select>
											</Form.Item>
										</div>
									</div>
								) : (
									<div className='col-12'>
										<Form.Item
											label='Health Insurance'
											prop='lab_company_name'
											className={classnames(
												submitting &&
													isEmpty(
														form.lab_company_name
													)
													? 'no-error-required'
													: ''
											)}
										>
											<Input
												value={form.lab_company_name}
												onChange={(e) =>
													onChange(
														'lab_company_name',
														e
													)
												}
												placeholder='Health insurance company'
												className={classnames(
													'field_company_name',
													labHasOther &&
														isEmpty(
															form.lab_company_name
														)
														? 'is-errors is-company-errors'
														: '',
													'prependedSelect'
												)}
												trim={true}
												prepend={
													<Form.Item
														className='pull-left company_name_field'
														label=''
														prop='lab_insurance_company_id'
													>
														<Select
															className='other-selection'
															value={
																form.lab_insurance_company_id
															}
															placeholder='Select'
															onChange={(e) =>
																onChange(
																	'lab_insurance_company_id',
																	e
																)
															}
														>
															{fullListOfInsuranceCompanies &&
																fullListOfInsuranceCompanies.map(
																	(el) => (
																		<Select.Option
																			key={
																				'insurance_company_' +
																				el.name
																			}
																			label={
																				el.name
																			}
																			value={el.id.toString()}
																		/>
																	)
																)}
														</Select>
													</Form.Item>
												}
											/>
										</Form.Item>
									</div>
								)}
								<div className='col-12 float-left'>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Insurance ID #'
											prop='lab_insurance_no'
										>
											<Input
												value={form.lab_insurance_no}
												placeholder='1234567890'
												trim={true}
												onChange={(e) =>
													onChange(
														'lab_insurance_no',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError('lab', 'insurance_no')}
									</div>
									<div className='col-4 pad-right-20px policyholder-label'>
										<div className='insurance-phn'>
											<label className='el-form-item__label'>
												Insurance phone number
											</label>
											<Tooltip
												placement='top'
												className='phone-tooltip-mainb2'
												content={
													<div className='phone-tooltip'>
														Please Enter the
														Insurance <br />
														Phone Number
														Indicated on the{' '}
														<br />
														Back of Your
														Insurance Card
													</div>
												}
											>
												<img
													className='phone-tooltip-icon'
													src={helpIcon}
													alt=''
												/>
											</Tooltip>
										</div>

										<Form.Item prop='lab_phone'>
											<InputMask
												className='inputFieldText inputFieldTextTaller'
												name='lab_phone'
												mask='+1 (999) 999-9999'
												value={form.lab_phone || ''}
												placeholder='(123) 456 - 7890'
												onChange={handleChange}
												maskChar={null}
											/>
										</Form.Item>
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Plan type (optional)'
											prop='lab_plan_name'
										>
											<Select
												value={form.lab_plan_name}
												placeholder='Select'
												onChange={(e) =>
													onChange('lab_plan_name', e)
												}
												className='inputFieldDropdown inputFieldDropdownTaller'
											>
												{getPlanTypes(
													form.lab_plan_name
												).map((el) => (
													<Select.Option
														key={
															'plan_name_' +
															el.name
														}
														label={
															el.display_name ||
															''
														}
														value={el.name || ''}
													/>
												))}
											</Select>
										</Form.Item>
										{getServerError('lab', 'plan_name')}
									</div>
								</div>
								<div className='col-12 float-left'>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Group #'
											prop='lab_group_number'
										>
											<Input
												value={form.lab_group_number}
												placeholder='1234567890'
												trim={true}
												onChange={(e) =>
													onChange(
														'lab_group_number',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError('lab', 'group_number')}
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Employer'
											prop='lab_employer'
										>
											<Input
												value={form.lab_employer}
												placeholder='Company ABC'
												trim={true}
												onChange={(e) =>
													onChange('lab_employer', e)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError('lab', 'employer')}
									</div>
								</div>
								<div className='col-12'>
									<div className='col-12'>
										<div className='pad-right-20px full_width'>
											<Form.Item
												label='Insurance address'
												prop='lab_insurance_address'
											>
												<Input
													value={
														form.lab_insurance_address
													}
													placeholder='123 Main street'
													trim={true}
													onChange={(e) =>
														onChange(
															'lab_insurance_address',
															e
														)
													}
													className='erFieldText inputFieldTextTaller'
												/>
											</Form.Item>
											{getServerError(
												'lab',
												'insurance_address'
											)}
										</div>
									</div>
								</div>
								<div className='col-12 float-left'>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Insurance city'
											prop='lab_insurance_city'
										>
											<Input
												value={form.lab_insurance_city}
												placeholder='San Francisco'
												trim={true}
												onChange={(e) =>
													onChange(
														'lab_insurance_city',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'lab',
											'insurance_city'
										)}
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Insurance state'
											prop='lab_insurance_state'
										>
											<Select
												value={form.lab_insurance_state}
												placeholder='Select'
												onChange={(e) =>
													onChange(
														'lab_insurance_state',
														e
													)
												}
												className='inputFieldDropdown inputFieldDropdownTaller'
											>
												{states_list &&
													states_list.map((el) => {
														return (
															<Select.Option
																key={
																	'insurance_state_' +
																	el.key
																}
																label={el.value}
																value={el.key}
															/>
														);
													})}
											</Select>
										</Form.Item>
										{getServerError(
											'lab',
											'insurance_state'
										)}
									</div>
									<div className='col-4 pad-right-20px'>
										<Form.Item
											label='Insurance zipcode'
											prop='lab_insurance_zipcode'
										>
											<Input
												value={
													form.lab_insurance_zipcode
												}
												placeholder='12345'
												trim={true}
												onChange={(e) =>
													onChange(
														'lab_insurance_zipcode',
														e
													)
												}
												className='erFieldText inputFieldTextTaller'
											/>
										</Form.Item>
										{getServerError(
											'lab',
											'insurance_zipcode'
										)}
									</div>
								</div>
								<div className='col-12 float-left'>
									<div className='col-4 pad-right-20px policyholder-label'>
										<Form.Item
											label='Is patient the primary policyholder?'
											prop='lab_is_policyholder'
										>
											<Radio.Group
												value={form.lab_is_policyholder}
												onChange={(e) =>
													onChange(
														'lab_is_policyholder',
														e
													)
												}
											>
												<Radio value={true}>Yes</Radio>
												<Radio value={false}>No</Radio>
											</Radio.Group>
										</Form.Item>
									</div>
									{isLabPolicyHolder && (
										<div className='col-4 pad-right-20px policyholder-label'>
											<Form.Item
												label={
													"Policyholder's biological sex"
												}
												prop='lab_policyholder_biological_sex'
											>
												<Select
													value={
														form.lab_policyholder_biological_sex
													}
													placeholder='Select'
													onChange={(e) =>
														onChange(
															'lab_policyholder_biological_sex',
															e
														)
													}
													className='inputFieldDropdown inputFieldDropdownTaller'
												>
													{biologicalSexVariants.map(
														(el) => (
															<Select.Option
																key={
																	'policyholder_biological_sex_' +
																	el.value
																}
																label={
																	el.label ||
																	''
																}
																value={
																	el.value ||
																	''
																}
															/>
														)
													)}
												</Select>
											</Form.Item>
											{getServerError(
												'lab',
												'policyholder_biological_sex'
											)}
										</div>
									)}
									{!isLabPolicyHolder && (
										<>
											<div className='col-4 pad-right-20px policyholder-label'>
												<Form.Item
													label={
														"Policyholder's first name"
													}
													prop='lab_policyholder_first_name'
												>
													<Input
														placeholder='John'
														trim={true}
														value={
															form.lab_policyholder_first_name
														}
														onChange={(e) =>
															onChange(
																'lab_policyholder_first_name',
																e
															)
														}
														className='erFieldText inputFieldTextTaller'
													/>
												</Form.Item>
												{getServerError(
													'lab',
													'policyholder_first_name'
												)}
											</div>
											<div className='col-4 pad-right-20px policyholder-label'>
												<Form.Item
													label={
														"Policyholder's last name"
													}
													prop='lab_policyholder_last_name'
												>
													<Input
														placeholder='Smith'
														trim={true}
														value={
															form.lab_policyholder_last_name
														}
														onChange={(e) =>
															onChange(
																'lab_policyholder_last_name',
																e
															)
														}
														className='erFieldText inputFieldTextTaller'
													/>
												</Form.Item>
												{getServerError(
													'lab',
													'policyholder_last_name'
												)}
											</div>
										</>
									)}
								</div>
								{!isLabPolicyHolder && (
									<div className='col-12 no-policyholder-partition float-left'>
										<div className='col-4 pad-right-20px policyholder-label'>
											<Form.Item
												label={
													"Policyholder's biological sex"
												}
												prop='lab_policyholder_biological_sex'
											>
												<Select
													value={
														form.lab_policyholder_biological_sex
													}
													placeholder='Select'
													onChange={(e) =>
														onChange(
															'lab_policyholder_biological_sex',
															e
														)
													}
													className='inputFieldDropdown inputFieldDropdownTaller'
												>
													{biologicalSexVariants.map(
														(el) => (
															<Select.Option
																key={
																	'policyholder_biological_sex_' +
																	el.value
																}
																label={
																	el.label ||
																	''
																}
																value={
																	el.value ||
																	''
																}
															/>
														)
													)}
												</Select>
											</Form.Item>
											{getServerError(
												'lab',
												'policyholder_biological_sex'
											)}
										</div>
										<div className='col-4 pad-right-20px policyholder-label'>
											<Form.Item
												label='Relationship to policyholder'
												prop='lab_policyholder_relationship'
											>
												<Select
													value={
														form.lab_policyholder_relationship
													}
													placeholder='Select'
													onChange={(e) =>
														onChange(
															'lab_policyholder_relationship',
															e
														)
													}
													className='inputFieldDropdown inputFieldDropdownTaller'
												>
													{dependentVariants.map(
														(el) => (
															<Select.Option
																key={
																	'relationship_' +
																	el.value
																}
																label={
																	el.label ||
																	''
																}
																value={
																	el.value ||
																	''
																}
															/>
														)
													)}
												</Select>
											</Form.Item>
											{getServerError(
												'lab',
												'policyholder_relationship'
											)}
										</div>
										<div className='col-4 pad-right-20px policyholder-label'>
											<Form.Item
												label={"Policyholder's DOB"}
												prop='lab_policyholder_dob'
											>
												<InputMask
													value={
														form.lab_policyholder_dob
													}
													placeholder='MM/DD/YYYY'
													name='lab_policyholder_dob'
													trim={true}
													onChange={handleChange}
													mask='99/99/9999'
													maskChar={null}
													className='inputFieldText inputFieldTextTaller'
												/>
											</Form.Item>
											{getServerError(
												'lab',
												'policyholder_dob'
											)}
										</div>
									</div>
								)}
							</>
						)}

						<div className='action-buttons'>
							<div
								data-testId='cancel-btn'
								className='cancel-button'
								onClick={handleReset}
							>
								<span className='text'>Cancel</span>
							</div>
							<Button
								data-testId='save-btn'
								className='save-button'
								disabled={isSaveDisabled()}
								onClick={handleSubmit}
							>
								Save
							</Button>
						</div>
					</Form>
				</Loading>
			</div>
		</div>
	);
};

export default InsuranceForm;
