//React & Redux
import React, { useState, useEffect } from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from '../../../../assets/exit-alert.svg';

//Utils
import {
	getMomentDateOrString,
	isValidDateString,
	getMomentDateOrStringNonStrick,
	TestRequestStatus,
	isValidDateStringOREmpty,
	currentForm,
	TESTREQUEST,
	determineDropdownQuestionnaire,
	buildQuestionnairePayload,
	questionnaireNoneValue,
	determineShippingAddressRequired,
	TESTORDER,
} from '../Encounters/Common/utilsfunctions';
import validPostalCode from 'postcode-validator';

//UI Libraries
import { Button, Loading, Tabs } from 'gm-element-react';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious';

//Other Libraries
import moment from 'moment';
import _ from 'lodash';
import EmailValidator from 'email-validator';
import { isValidPhoneNumber } from 'libphonenumber-js';

//Components
import ICDSelectionComponent from '../../ChargeInformation/ICDSelectionComponent';
import EditTestRequestModal from './EditTestRequestModal';
import NotesTestOrder from './NotesTestOrder';
import EncounterAuditTrail from './EncounterAuditTrail';
import TextWithTooltip from '../../../Common/TextWithTooltip';
import AOEQuestions from './AOEQuestions';

//Styles
import './create-test-request-modal.css';

const cascaderfields = {
	order_request_status: 1,
	order_status: 1,
};
const datefields = {
	date_test_ordered: 1,
	expected_lab_results_date: 1,
	date_received_report: 1,
	blood_draw_order_date: 1,
	date_test_authorized: 1,
	date_results_released_to_patient: 1,
};

const orderStatusSentToLabAndAfterThat = {
	sent_to_lab: 1,
	results_ready: 1,
	results_entered: 1,
	result_positive_no_genes: 1,
	result_positive_with_genes: 1,
	result_negative: 1,
	approved: 1,
	awaiting_cc_submission: 1,
	sample_not_submitted: 1,
	patient_not_responsive: 1,
	order_cancelled_by_patient: 1,
	order_cancelled_by_provider: 1,
	order_cancelled_by_lab: 1,
	cancelled: 1,
};

const DateTypes = ['date_any', 'date_past', 'date_future'];

const EditTestOrderModalContainer = (props) => {
	const {
		auditTrailEncounter = [],
		auditTrailEncounterLoading = false,
		callTestOrderNotes,
		encounter = {},
		encounterICDcodes = [],
		encounterschema = {},
		enqueueSnackbar,
		getICDCodes,
		hideModal,
		holdResultRelease = null,
		icdCodes,
		icdCodesError,
		icdCodesLoading,
		icdCodesPlacement,
		labenums = [],
		labOrderStatus = '',
		laborderstatusenums = [],
		LoadTestOrderPopupTestEnum,
		mloOrderStatusEnums = [],
		nonlegacytestOrderPopupTestEnums = [],
		nonlegacytestOrderPopupTestEnumsLoading = false,
		ordering_physicianenums = [],
		orderrequeststatuscascadeenums = [],
		orderstatuscascadeenums = [],
		outreachStatusEnums = [],
		outreachTimeToStart = null,
		patientbillingmethodenums = [],
		patientdetail = {},
		sampletypeenums = [],
		testnameenums = [],
		testOrderList,
		testOrderNotes = [],
		testOrderNumber = 0,
		testorder = {},
		testOutreachStatus = '',
		updateTestOrder,
		user = {},
		genetic_test2 = {},
		genetic_test3 = {},
		groupTestorders = [],
	} = props;

	const [errors, setErrors] = useState({});
	const [errorsForMultipleOrder, setErrorsForMultipleOrder] = useState({});
	const [formdata, setFormdata] = useState({});
	const [testOrderProps, setTestOrderProps] = useState({});
	const [selectedICDCodes, setSelectedICDCodes] = useState([]);
	const [loading, setLoading] = useState(false);
	const [draftNotes, setDraftNotes] = useState([]);
	const [tabvalue, setTabValue] = useState('');
	const [userSelectedResultAction, setUserSelectedResultAction] =
		useState(null);
	const [shippingAddressDataErrors, setShippingAddressDataErrors] = useState(
		{}
	);
	const [shippingAddressFormData, setShippingAddressFormData] = useState({});
	const [aoeQuestions, setAOEQuestions] = useState([]);
	const [aoeAnswers, setAOEAnswers] = useState({});
	const [
		addAnotherTestNameFormDataErrors,
		setAddAnotherTestNameFormDataErrors,
	] = useState({});
	const [addAnotherTestNameFormData, setAddAnotherTestNameFormData] =
		useState({
			test_name2: null,
			addTest2: '',
			test_name3: null,
			addTest3: '',
		});

	const tempFormData = {};
	_.map(groupTestorders, (group) => {
		tempFormData[group.uuid] = _.clone({
			...group,
			order_status: group.localorderstatus,
		});
	});
	const [stateGroupTestorders, setStateGroupTestorders] =
		useState(tempFormData);
	const [reflexAOEQuestions, setReflexAOEQuestions] = useState([]);
	const [reflexAOEAnswers, setReflexAOEAnswers] = useState({});
	const [
		showInternalTestOrderErrorPopup,
		setShowInternalTestOrderErrorPopup,
	] = useState(false);
	const [aoeAnswersErrors, setAoeAnswersErrors] = useState({});

	const prevNonlegacytestOrderPopupTestEnums = usePrevious(
		nonlegacytestOrderPopupTestEnums
	);

	useEffect(() => {
		setLoading(nonlegacytestOrderPopupTestEnumsLoading?.loading || false);
	}, [nonlegacytestOrderPopupTestEnumsLoading]);

	useEffect(() => {
		const hold_result_release = _.get(
			encounter,
			['hold_result_release'],
			null
		);
		const formdata = {
			lab_uuid: _.get(testorder, ['lab_uuid'], ''),
			genetic_test_uuid: _.get(testorder, ['genetic_test_uuid'], ''),
			ordering_physician: _.get(testorder, ['ordering_physician'], ''),
			order_status:
				_.get(testorder, ['localorderstatus'], '') === 'approved'
					? 'awaiting_cc_submission'
					: _.get(testorder, ['localorderstatus'], ''),
			sample_type: _.get(testorder, ['sample_type'], ''),
			blood_draw_request:
				_.isEmpty(_.get(testorder, ['blood_draw_request'], '')) &&
				['approved', 'sent_to_lab'].includes(
					_.get(testorder, ['localorderstatus'])
				)
					? 'na'
					: _.get(testorder, ['blood_draw_request'], ''),
			blood_draw_order_date: _.get(
				testorder,
				['blood_draw_order_date'],
				''
			),
			date_test_ordered: _.get(testorder, ['date_test_ordered'], ''),
			date_received_report: _.get(
				testorder,
				['date_received_report'],
				''
			),
			expected_lab_results_date: getExpectedLabResultsDate(),
			bd_saliva_kit_sent: _.get(testorder, ['bd_saliva_kit_sent'], 'n/a'),
			date_results_released_to_patient: _.get(
				testorder,
				['date_results_released_to_patient'],
				''
			),
			patient_billing_method: _.get(
				testorder,
				['patient_billing_method'],
				''
			),
			date_test_authorized: _.get(
				testorder,
				['date_test_authorized'],
				''
			),
			hold_result_release: _.isNil(hold_result_release)
				? false
				: hold_result_release,
			positive_results_to_report: _.get(
				testorder,
				['positive_results_to_report'],
				null
			),
			is_actionable: _.get(testorder, ['is_actionable'], false),
			lab_order_status: _.get(testorder, ['lab_order_status'], ''),
			order_questionnaire: _.get(testorder, 'order_questionnaire', null),
			skip_lab_integration: _.get(
				testorder,
				'skip_lab_integration',
				true
			),
		};
		const isShippingAddressExist = !_.isEmpty(
			_.filter(_.get(patientdetail, ['address'], []), (o) => {
				return _.get(o, ['type'], []).includes('delivery');
			})
		);
		const _shippingAddress = _.filter(
			_.get(patientdetail, ['address'], []),
			(o) => {
				return _.get(o, ['type'], []).includes('delivery');
			}
		);
		const shippingAddressFormData = {
			patient_address_line1: isShippingAddressExist
				? _.get(_shippingAddress, ['0', 'address_line1'], '')
				: '',
			patient_address_line2: isShippingAddressExist
				? _.get(_shippingAddress, ['0', 'address_line2'], '')
				: '',
			patient_shipping_address_city: isShippingAddressExist
				? _.get(_shippingAddress, ['0', 'city'], '')
				: '',
			patient_shipping_address_state: isShippingAddressExist
				? _.get(_shippingAddress, ['0', 'state'], '')
				: '',
			patient_shipping_address_zipcode: isShippingAddressExist
				? _.get(_shippingAddress, ['0', 'zip'], '')
				: '',
		};
		const updatedformdata = _.mapValues(formdata, function (value, key) {
			if (key in datefields) return getMomentDateOrStringNonStrick(value);
			if (key === 'order_questionnaire' && Array.isArray(value))
				return value?.[0] || {};

			return value;
		});
		setSelectedICDCodesFunc(_.get(testorder, ['medical_codes'], []));
		setFormdata(updatedformdata);
		setTestOrderProps(testorder);
		setErrors({});
		setShippingAddressFormData(shippingAddressFormData);
		setShippingAddressDataErrors({});

		LoadTestOrderPopupTestEnum(_.get(testorder, ['lab_uuid'], ''));
		callTestOrderNotes(_.get(testorder, ['test_order_uuid'], ''));
	}, []);

	useEffect(() => {
		if (
			!_.isNil(nonlegacytestOrderPopupTestEnums) &&
			!_.isNil(prevNonlegacytestOrderPopupTestEnums) &&
			JSON.stringify(nonlegacytestOrderPopupTestEnums) !==
				JSON.stringify(prevNonlegacytestOrderPopupTestEnums)
		) {			
			const testInfo = nonlegacytestOrderPopupTestEnums.filter(
				(test) => test.key === formdata.genetic_test_uuid
			)?.[0];

			if (
				!_.isEmpty(testInfo?.order_questionnaire) &&
				!_.isEmpty(formdata.order_questionnaire)
			) {
				const answers = {};

				for (const {
					name,
					default_option,
				} of testInfo.order_questionnaire) {
					answers[name] = default_option;
				}

				for (const [key, value] of Object.entries(
					formdata.order_questionnaire
				)) {
					const question = _.find(testInfo?.order_questionnaire, {
						question_text: key,
					});
					const answer =
						!_.isEmpty(question?.options) &&
						_.isArray(question?.options)
							? _.find(
									question?.options,
									(option) =>
										option.toLowerCase() ===
										value[0].toLowerCase()
							  )
							: value[0];

					answers[question?.name] = answer;
				}
				setAOEAnswers(answers);
			}
			setAOEQuestions(testInfo?.order_questionnaire);
			setAddAnotherTestNameFormData((oldState) => ({
				...oldState,
				addTest2: !_.isEmpty(genetic_test2?.testNameUuid)
					? 'yes'
					: 'no',
				test_name2: !_.isEmpty(genetic_test2?.testNameUuid)
					? genetic_test2?.testNameUuid
					: null,
				addTest3: !_.isEmpty(genetic_test3?.testNameUuid)
					? 'yes'
					: 'no',
				test_name3: !_.isEmpty(genetic_test3?.testNameUuid)
					? genetic_test3?.testNameUuid
					: null,
			}));

			if (!_.isEmpty(testInfo?.reflex_questionnaire)) {
				const reflexAnswers = {};

				for (const {
					name,
					default_option,
				} of testInfo.reflex_questionnaire) {
					reflexAnswers[name] = default_option;
				}

				if (!_.isNil(formdata.order_questionnaire)) {
					for (const [key, value] of Object.entries(
						formdata.order_questionnaire
					)) {
						const reflexQuestion = _.find(testInfo?.reflex_questionnaire, {
							question_text: key,
						});					
						if (!_.isNil(reflexQuestion)) {
							reflexAnswers[reflexQuestion?.name] = value[0];
						}
					}
				}	

				setReflexAOEAnswers(reflexAnswers);
			}
			setReflexAOEQuestions(testInfo?.reflex_questionnaire);
		}
	}, [nonlegacytestOrderPopupTestEnums]);

	const getExpectedLabResultsDate = () => {
		const days = 21;
		const expectedLabResultsDate = _.get(
			testorder,
			['expected_lab_results_date'],
			''
		);
		const dateTestOrdered = _.get(testorder, ['date_test_ordered'], '');

		if (_.isEmpty(expectedLabResultsDate)) {
			if (!_.isEmpty(dateTestOrdered) && !_.isNil(dateTestOrdered)) {
				return moment(dateTestOrdered).clone().add(days, 'days');
			} else {
				return null;
			}
		} else {
			return expectedLabResultsDate;
		}
	};

	const onFormChange = (key, value) => {
		const _formdata = formdata;
		const prevQuestionnaireFormData =
			_.cloneDeep(_formdata['order_questionnaire']) || [];
		let _errors = {};
		_formdata[key] = value;

		if (key === 'order_status') {
			_formdata['positive_results_to_report'] = null;
			_formdata['is_actionable'] = false;
		}

		if (key == 'lab_uuid') {
			LoadTestOrderPopupTestEnum(value);
			_formdata[key] = value;
			_formdata['genetic_test_uuid'] = '';
			_formdata['order_questionnaire'] = [];
			_formdata['skip_lab_integration'] = true;
			setAddAnotherTestNameFormData((oldState) => ({
				...oldState,
				test_name2: null,
				addTest2: '',
				test_name3: null,
				addTest3: '',
			}));
			setAOEAnswers({});
			setReflexAOEAnswers({});
		}

		if (key == 'genetic_test_uuid') {
			const testData = _.find(nonlegacytestOrderPopupTestEnums || [], {
				key: value,
			});
			_formdata[key] = value;
			_formdata['order_questionnaire'] = [];
			if (!_.isEmpty(value) && !_.isNil(value)) {
				_formdata['skip_lab_integration'] =
					!testData?.lab_integration_enabled;
			} else {
				setAOEAnswers({});
				setReflexAOEAnswers({});
			}
			setAddAnotherTestNameFormData((oldState) => ({
				...oldState,
				test_name2: null,
				addTest2: 'no',
				test_name3: null,
				addTest3: 'no',
			}));

			setAOEQuestions(testData?.order_questionnaire || []);
			if (
				_.isArray(testData?.order_questionnaire) &&
				!_.isEmpty(testData?.order_questionnaire)
			) {
				for (const {
					default_option,
					name,
				} of testData?.order_questionnaire || []) {
					if (default_option)
						setAOEAnswers({
							...aoeAnswers,
							[name]: default_option,
						});
				}
			}

			setReflexAOEQuestions(testData?.reflex_questionnaire || []);
			if (
				_.isArray(testData?.reflex_questionnaire) &&
				!_.isEmpty(testData?.reflex_questionnaire)
			) {
				for (const {
					default_option,
					name,
				} of testData?.reflex_questionnaire || []) {
					if (default_option)
						setReflexAOEAnswers({
							...reflexAOEAnswers,
							[name]: default_option,
						});
				}
			}

			if (
				testorder.genetic_test_uuid !== formdata.genetic_test_uuid &&
				_.includes(_.toLower(testorder?.test_name), 'other') &&
				!_.isEmpty(_.get(testorder, ['date_test_ordered'], '')) &&
				formdata?.order_status in
					{
						sent_to_lab: 1,
						results_ready: 1,
						result_positive_no_genes: 1,
						result_positive_with_genes: 1,
						result_negative: 1,
						sample_not_submitted: 1,
						patient_not_responsive: 1,
						order_cancelled_by_patient: 1,
						order_cancelled_by_provider: 1,
						order_cancelled_by_lab: 1,
					}
			) {
				_formdata['skip_lab_integration'] = true;
				setAOEAnswers({});
				setReflexAOEAnswers({});
			}
		}

		if (key == 'skip_lab_integration' && value === false) {
			const testData = _.find(nonlegacytestOrderPopupTestEnums || [], {
				key: _formdata['genetic_test_uuid'],
			});

			if (
				_.isArray(testData?.order_questionnaire) &&
				!_.isEmpty(testData?.order_questionnaire)
			) {
				for (const {
					default_option,
					name,
				} of testData?.order_questionnaire || []) {
					if (default_option)
						setAOEAnswers({
							...aoeAnswers,
							[name]: default_option,
						});
				}
			}

			if (
				_.isArray(testData?.reflex_questionnaire) &&
				!_.isEmpty(testData?.reflex_questionnaire)
			) {
				for (const {
					default_option,
					name,
				} of testData?.reflex_questionnaire || []) {
					if (default_option)
						setReflexAOEAnswers({
							...reflexAOEAnswers,
							[name]: default_option,
						});
				}
			}
		}

		if (key == 'order_questionnaire') {
			if (
				Array.isArray(value) &&
				value.includes(questionnaireNoneValue) &&
				!prevQuestionnaireFormData.includes(questionnaireNoneValue)
			) {
				_formdata['order_questionnaire'] = [questionnaireNoneValue];
			}

			if (
				Array.isArray(value) &&
				value.length === 2 &&
				prevQuestionnaireFormData.length === 1 &&
				prevQuestionnaireFormData.includes(questionnaireNoneValue)
			) {
				_.remove(
					_formdata['order_questionnaire'],
					(el) => el === questionnaireNoneValue
				);
			}
		}

		if (key in cascaderfields) {
			_formdata[key] = _.last(value);
		}

		if (key === 'date_test_ordered') {
			if (_.isNil(value) || _.isEmpty(value)) {
				_formdata[key] = '';
				_formdata['expected_lab_results_date'] = '';
			} else if (isValidDateString(value)) {
				const momentobj = getMomentDateOrString(value);
				_formdata['expected_lab_results_date'] = moment.isMoment(
					momentobj
				)
					? momentobj.add(35, 'days').format('YYYY-MM-DD')
					: '';
			}
		}

		if (!(key in datefields)) {
			_errors = validateForm(_formdata);
		}

		setErrors(_errors);
		setFormdata(_formdata);
	};

	const onShippingAddressFormChange = (key, value) => {
		const _shippingAddressFormData = shippingAddressFormData;
		let _shippingAddressDataErrors = {};

		_shippingAddressFormData[key] = value;
		_shippingAddressDataErrors = validateShippingAddressForm(
			_shippingAddressFormData
		);
		setShippingAddressDataErrors(_shippingAddressDataErrors);
		setShippingAddressFormData(_shippingAddressFormData);
	};

	const onResultActionChange = (value) => {
		setUserSelectedResultAction(value);
	};

	const handleDateFieldValidate = (key, value) => {
		const _formdata = {};
		_formdata[key] = value;
		if (key === 'date_test_ordered') {
			if (_.isNil(value) || _.isEmpty(value)) {
				_formdata[key] = '';
				_formdata['expected_lab_results_date'] = '';
			} else if (isValidDateString(value)) {
				const momentobj = getMomentDateOrString(value);
				_formdata['expected_lab_results_date'] = moment.isMoment(
					momentobj
				)
					? momentobj.add(35, 'days').format('YYYY-MM-DD')
					: '';
			}
		}
		setErrors(validateForm(_formdata));
		setFormdata((prevState) => ({
			...prevState,
			..._formdata,
		}));
	};

	const setSelectedICDCodesFunc = (selectedICDCodes) => {
		if (selectedICDCodes && Array.isArray(selectedICDCodes)) {
			setSelectedICDCodes(selectedICDCodes.slice());
		}
	};

	const validateAddAnotherTestNameForm = (addAnotherTestNameFormData) => {
		const addAnotherTestNameFormDataErrors = {};

		if (
			addAnotherTestNameFormData['addTest2'] == 'yes' &&
			(_.isNil(addAnotherTestNameFormData['test_name2']) ||
				_.isEmpty(addAnotherTestNameFormData['test_name2']))
		) {
			addAnotherTestNameFormDataErrors['test_name2'] =
				'Test Name 2 is required';
		}

		if (
			addAnotherTestNameFormData['addTest3'] == 'yes' &&
			(_.isNil(addAnotherTestNameFormData['test_name3']) ||
				_.isEmpty(addAnotherTestNameFormData['test_name3']))
		) {
			addAnotherTestNameFormDataErrors['test_name3'] =
				'Test Name 3 is required';
		}

		return addAnotherTestNameFormDataErrors;
	};

	const onAddAnotherTestNameFormChange = (key, value) => {
		const _addAnotherTestNameFormData = addAnotherTestNameFormData;
		let _addAnotherTestNameFormDataErrors = {};

		_addAnotherTestNameFormData[key] = value;
		_addAnotherTestNameFormDataErrors = validateAddAnotherTestNameForm(
			_addAnotherTestNameFormData
		);
		setAddAnotherTestNameFormDataErrors(_addAnotherTestNameFormDataErrors);
		setAddAnotherTestNameFormData((oldState) => ({
			...oldState,
			key: value,
		}));
	};

	const onFormChangeForMultipleTestOrder = (newState) => {
		setStateGroupTestorders((oldState) => {
			return {
				...oldState,
				...newState,
			};
		});
		const _errors = {};
		_.map(stateGroupTestorders, (groupOrder, uuid) => {
			_errors[uuid] = validateForm(groupOrder);
		});
		setErrorsForMultipleOrder((oldState) => {
			return {
				...oldState,
				..._errors,
			};
		});
	};

	const singleOrder = (uuid) => {
		return _.find(groupTestorders, (group) => {
			return group.uuid == uuid;
		});
	};

	const isMultipleOrderAfterSentToLab = () => {
		return (
			testorder?.order_status in orderStatusSentToLabAndAfterThat &&
			groupTestorders.length > 1
		);
	};

	const handleEditMultipleTestOrder = () => {
		const _updateTestOrderData = [];
		_.map(stateGroupTestorders, (val, key) => {
			const propGroupOrder = singleOrder(key);

			const medical_codes = selectedICDCodes.map((obj) => ({
				code_name: obj.code_name,
				uuid: obj.uuid,
			}));

			const newdata = {
				...val,
				medical_codes,
			};

			let edited = _.reduce(
				newdata,
				function (result, value, key) {
					return _.isEqual(value, propGroupOrder[key])
						? result
						: result.concat(key);
				},
				[]
			);

			edited = _.remove(edited, function (element) {
				return (
					element != 'lab_uuid' && element != 'hold_result_release'
				);
			});

			const data = {};
			edited.map((e) => {
				if (_.has(newdata, e)) {
					data[e] = _.get(newdata, e, '');
				} else {
					data[e] = propGroupOrder[e];
				}

				if (e in datefields) {
					if (isValidDateString(_.get(newdata, e))) {
						const _currentTime = moment().format('HH:mm:ss');
						const _value = `${_.get(newdata, e)} ${_currentTime}`;
						data[e] = moment(_value)
							.utc()
							.format('YYYY-MM-DD HH:mm:ss');
					}
				}

				if (e == 'blood_draw_order_date' && _.get(newdata, e) == '') {
					data[e] = null;
				}
				if (
					e == 'date_results_released_to_patient' &&
					_.get(newdata, e) == ''
				) {
					data[e] = null;
				}
			});

			const temporderstatus = _.find(mloOrderStatusEnums, function (o) {
				return o.key == _.get(newdata, 'order_status');
			});

			if (temporderstatus) {
				data['order_status'] = _.get(temporderstatus, 'key', '');
				if (temporderstatus['key'] != 'results_ready') {
					data['status_reason'] = '';
				}
			} else {
				const tempstatusreson = _.find(
					mloOrderStatusEnums,
					function (o) {
						return _.find(o.children, function (x) {
							return x.key == _.get(newdata, 'order_status');
						});
					}
				);
				data['order_status'] = _.get(tempstatusreson, 'key', '');
				data['status_reason'] = _.get(newdata, 'order_status');
			}

			data['is_actionable'] = newdata['is_actionable'];

			_updateTestOrderData.push({ key, data });
		});
		return _updateTestOrderData;
	};

	const editTestOrderData = () => {
		const errors = validateForm(formdata);
		let isGroupedTestOrder = false;
		const labName = _.get(testorder, ['lab'], '');
		const testName = _.get(testorder, ['test_name'], '');

		if (!_.isEmpty(aoeQuestions) && isAOEAnswerInvalid()) {
			return;
		}

		if (
			currentForm(testorder) == TESTORDER &&
			_.get(testorder, ['genetic_test_uuid']) !==
				_.get(formdata, ['genetic_test_uuid']) &&
			!_.includes(_.toLower(labName), 'other') &&
			!_.includes(_.toLower(testName), 'other')
		) {
			setShowInternalTestOrderErrorPopup(true);
			return;
		}
		if (_.isEmpty(errors)) {
			setLoading(true);

			const medical_codes = selectedICDCodes.map((obj) => ({
				code_name: obj.code_name,
				uuid: obj.uuid,
			}));

			let newdata = {
				...formdata,
				medical_codes,
			};

			if (newdata['order_questionnaire']) {
				const questionnaire = determineDropdownQuestionnaire(
					formdata['genetic_test_uuid'],
					nonlegacytestOrderPopupTestEnums
				);
				const questionnairePayload = buildQuestionnairePayload(
					formdata['order_questionnaire'],
					questionnaire
				);
				if (questionnairePayload) {
					newdata['order_questionnaire'] = questionnairePayload;
				} else {
					newdata['order_questionnaire'] = null;
				}
			}

			if (!newdata?.skip_lab_integration && !_.isEmpty(aoeAnswers)) {
				const order_questionnaire = {};

				for (const [key, value] of Object.entries(aoeAnswers)) {
					const question = _.find(aoeQuestions, { name: key });
					if (question && value) {
						order_questionnaire[question?.question_text] = [
							DateTypes.includes(question.type)
								? moment(value).format('YYYY-MM-DD')
								: value,
						];
					}
				}

				if (!_.isEmpty(order_questionnaire))
					newdata = {
						...newdata,
						order_questionnaire,
					};
			}

			if (!newdata?.skip_lab_integration && !_.isEmpty(reflexAOEAnswers) && !_.isNil(reflexAOEAnswers?.is_reflex)) {
				const reflex_questionnaire = {};
	
				for (const [key, value] of Object.entries(reflexAOEAnswers)) {
					if (value) {
						const reflex_question = _.find(reflexAOEQuestions, { name: key });
						reflex_questionnaire[reflex_question?.question_text] = [
							DateTypes.includes(reflex_question.type)
								? moment(value).format('YYYY-MM-DD')
								: value,
						];
					}
				}

				if(reflexAOEAnswers?.is_reflex === 'No' && reflex_questionnaire['Under which conditions?'] != null) {
					delete reflex_questionnaire['Under which conditions?']
				}

				if (!_.isEmpty(reflex_questionnaire))
					newdata = {
						...newdata,
						reflex_questionnaire,
					};
			}

			let edited = _.reduce(
				newdata,
				function (result, value, key) {
					return _.isEqual(value, testOrderProps[key])
						? result
						: result.concat(key);
				},
				[]
			);

			edited = _.remove(edited, function (element) {
				return (
					element != 'lab_uuid' && element != 'hold_result_release'
				);
			});

			const data = {};

			edited.map((e) => {
				if (_.has(newdata, e)) {
					data[e] = _.get(newdata, e, '');
				} else {
					data[e] = testorder[e];
				}

				if (e in datefields) {
					if (isValidDateString(_.get(newdata, e))) {
						const _currentTime = moment().format('HH:mm:ss');
						const _value = `${_.get(newdata, e)} ${_currentTime}`;
						data[e] = moment(_value)
							.utc()
							.format('YYYY-MM-DD HH:mm:ss');
					}
				}

				if (e == 'blood_draw_order_date' && _.get(newdata, e) == '') {
					data[e] = null;
				}
				if (
					e == 'date_results_released_to_patient' &&
					_.get(newdata, e) == ''
				) {
					data[e] = null;
				}
			});

			const temporderstatus = _.find(mloOrderStatusEnums, function (o) {
				return o.key == _.get(formdata, 'order_status');
			});

			if (temporderstatus) {
				data['order_status'] = _.get(temporderstatus, 'key', '');
				if (temporderstatus['key'] != 'results_ready') {
					data['status_reason'] = '';
				}
			} else {
				const tempstatusreson = _.find(
					mloOrderStatusEnums,
					function (o) {
						return _.find(o.children, function (x) {
							return x.key == _.get(formdata, 'order_status');
						});
					}
				);
				data['order_status'] = _.get(tempstatusreson, 'key', '');
				data['status_reason'] = _.get(formdata, 'order_status');
			}

			/* pass result actionable field if it is change */
			if (!_.isNil(userSelectedResultAction)) {
				data['is_actionable'] = formdata['is_actionable'];
			}

			const _draftNotes = _.reverse(draftNotes);
			const requiresShippingAddressFields =
				determineShippingAddressRequired(
					formdata['genetic_test_uuid'],
					nonlegacytestOrderPopupTestEnums,
					_.get(formdata, ['order_status'], {})
				);
			let _shippingAddressFormData = shippingAddressFormData;

			for (const _key in data) {
				if (_key === 'lab_order_status' && data[_key].trim() === '') {
					delete data[_key];
				}
			}

			if (requiresShippingAddressFields) {
				const data = {};
				const addressArr = _.cloneDeep(
					_.get(patientdetail, ['address'], [])
				);
				const addressObjectsWithoutDelivery = [];

				addressArr.forEach((obj) => {
					if (
						obj.type.includes('delivery') &&
						obj.type.length !== 1
					) {
						let idx;
						const newAddressWithoutDelivery = { ...obj };
						_.remove(newAddressWithoutDelivery.type, (t, i) => {
							if (t === 'delivery') {
								idx = i;
								return true;
							}
						});
						_.remove(
							newAddressWithoutDelivery.user_address_type_id,
							(typeId, i) => {
								if (i === idx) {
									return true;
								}
							}
						);
						addressObjectsWithoutDelivery.push(
							newAddressWithoutDelivery
						);
					} else if (!obj.type.includes('delivery')) {
						addressObjectsWithoutDelivery.push(obj);
					}
				});

				const {
					patient_address_line1,
					patient_address_line2,
					patient_shipping_address_city,
					patient_shipping_address_state,
					patient_shipping_address_zipcode,
				} = shippingAddressFormData;

				const newAddress = {};

				newAddress.full_name =
					`${_.get(patientdetail, 'first_name', '')} ${_.get(
						patientdetail,
						'last_name',
						''
					)}` || '';
				newAddress.address_line1 = patient_address_line1 || '';
				newAddress.address_line2 = patient_address_line2 || '';
				newAddress.city = patient_shipping_address_city || '';
				newAddress.state = patient_shipping_address_state || '';
				newAddress.zip = patient_shipping_address_zipcode || '';
				newAddress.user_id =
					patientdetail.address?.[0]?.user_id || null;
				newAddress.type = ['delivery'];

				data.patient_id = _.get(patientdetail, 'uuid', null);
				data.payload = [...addressObjectsWithoutDelivery, newAddress];
				_shippingAddressFormData = data;
			} else {
				_shippingAddressFormData = '';
			}

			let _updateTestOrderData = [];
			if (isMultipleOrderAfterSentToLab()) {
				_updateTestOrderData = handleEditMultipleTestOrder();
			} else if (
				(((!_.isNil(addAnotherTestNameFormData['test_name2']) &&
					!_.isEmpty(addAnotherTestNameFormData['test_name2'])) ||
					(!_.isEmpty(addAnotherTestNameFormData['test_name3']) &&
						!_.isEmpty(
							addAnotherTestNameFormData['test_name3']
						))) &&
					!formdata?.skip_lab_integration) ||
				(!_.isNil(genetic_test2.orderUuid) &&
					!_.isEmpty(genetic_test2.orderUuid)) ||
				(!_.isEmpty(genetic_test3.orderUuid) &&
					!_.isEmpty(genetic_test3.orderUuid))
			) {
				const _newTestNameUuid = [];
				const _updatedTestNameUuid = {};
				const _testOrdersUuid = [];
				_testOrdersUuid.push(_.get(testorder, ['test_order_uuid'], ''));
				if (
					!_.isEmpty(genetic_test2?.orderUuid) &&
					!_.isNil(testorder.lab_uuid) &&
					!_.isNil(formdata.lab_uuid) &&
					testorder.lab_uuid === formdata.lab_uuid &&
					!formdata.skip_lab_integration &&
					addAnotherTestNameFormData['addTest2'] != 'no'
				) {
					_testOrdersUuid.push(genetic_test2?.orderUuid);
				}
				if (
					!_.isEmpty(genetic_test3?.orderUuid) &&
					!_.isNil(testorder.lab_uuid) &&
					!_.isNil(formdata.lab_uuid) &&
					testorder.lab_uuid === formdata.lab_uuid &&
					!formdata.skip_lab_integration &&
					addAnotherTestNameFormData['addTest3'] != 'no'
				) {
					_testOrdersUuid.push(genetic_test3?.orderUuid);
				}

				if (
					_.isEmpty(genetic_test2?.orderUuid) &&
					_.isEmpty(genetic_test2?.testNameUuid) &&
					!_.isEmpty(addAnotherTestNameFormData['test_name2']) &&
					!_.isNil(addAnotherTestNameFormData['test_name2'])
				) {
					_newTestNameUuid.push(
						addAnotherTestNameFormData?.test_name2
					);
				}
				if (
					_.isEmpty(genetic_test3?.orderUuid) &&
					_.isEmpty(genetic_test3?.testNameUuid) &&
					!_.isEmpty(addAnotherTestNameFormData['test_name3']) &&
					!_.isNil(addAnotherTestNameFormData['test_name3'])
				) {
					_newTestNameUuid.push(
						addAnotherTestNameFormData?.test_name3
					);
				}

				if (
					(!_.isNil(testorder.lab_uuid) &&
						!_.isNil(formdata.lab_uuid) &&
						testorder.lab_uuid !== formdata.lab_uuid) ||
					formdata.skip_lab_integration
				) {
					_updatedTestNameUuid[testorder.test_order_uuid] =
						formdata.genetic_test_uuid;
					delete data.genetic_test_uuid;
				}
				if (
					!_.isNil(testorder.lab_uuid) &&
					!_.isNil(formdata.lab_uuid) &&
					!_.isNil(testorder.genetic_test_uuid) &&
					!_.isNil(formdata.genetic_test_uuid) &&
					testorder.genetic_test_uuid !== formdata.genetic_test_uuid
				) {
					_updatedTestNameUuid[testorder.test_order_uuid] =
						formdata.genetic_test_uuid;
					delete data.genetic_test_uuid;
				}
				if (
					!_.isEmpty(genetic_test2?.orderUuid) &&
					!_.isEmpty(genetic_test2?.testNameUuid) &&
					(testorder.lab_uuid !== formdata.lab_uuid ||
						formdata.skip_lab_integration)
				) {
					_updatedTestNameUuid[genetic_test2?.orderUuid] =
						formdata.skip_lab_integration
							? null
							: addAnotherTestNameFormData?.test_name2;
				}
				if (
					!_.isEmpty(genetic_test2?.orderUuid) &&
					!_.isEmpty(genetic_test2?.testNameUuid) &&
					(genetic_test2?.testNameUuid !==
						addAnotherTestNameFormData['test_name2'] ||
						addAnotherTestNameFormData['addTest2'] == 'no')
				) {
					_updatedTestNameUuid[genetic_test2?.orderUuid] =
						addAnotherTestNameFormData['addTest2'] == 'no'
							? null
							: addAnotherTestNameFormData?.test_name2;
				}
				if (
					!_.isEmpty(genetic_test3?.orderUuid) &&
					!_.isEmpty(genetic_test3?.testNameUuid) &&
					(testorder.lab_uuid !== formdata.lab_uuid ||
						formdata.skip_lab_integration)
				) {
					_updatedTestNameUuid[genetic_test3?.orderUuid] =
						formdata.skip_lab_integration
							? null
							: addAnotherTestNameFormData?.test_name3;
				}
				if (
					!_.isEmpty(genetic_test3?.orderUuid) &&
					!_.isEmpty(genetic_test3?.testNameUuid) &&
					(genetic_test3?.testNameUuid !==
						addAnotherTestNameFormData['test_name3'] ||
						addAnotherTestNameFormData['addTest3'] == 'no')
				) {
					_updatedTestNameUuid[genetic_test3?.orderUuid] =
						addAnotherTestNameFormData['addTest3'] == 'no'
							? null
							: addAnotherTestNameFormData?.test_name3;
				}

				data['uuid'] = _testOrdersUuid;
				if (
					_newTestNameUuid.length > 0 ||
					!_.isEmpty(_updatedTestNameUuid)
				) {
					data['tests'] = {};
				}
				if (_newTestNameUuid.length > 0) {
					data['tests'].new = _newTestNameUuid;
				}
				if (!_.isEmpty(_updatedTestNameUuid)) {
					data['tests'].update = _updatedTestNameUuid;
				}
				isGroupedTestOrder = true;
			}

			return updateTestOrder(
				testOrderProps.test_order_uuid,
				data,				
				_draftNotes,
				_shippingAddressFormData,
				isGroupedTestOrder,
				_updateTestOrderData
			)
				.then(() => {
					setLoading(false);
					hideModal();
				})
				.catch(() => {
					setLoading(false);
				});
		} else {
			setErrors(errors);
		}
	};

	const validateForm = (formdata) => {
		let errors = {};
		const dateerors = _.omitBy(
			_.mapValues(datefields, function (value, key) {
				if (
					!isValidDateStringOREmpty(_.get(formdata, key, '')) &&
					!_.isNil(_.get(formdata, key, ''))
				) {
					return 'Invalid date';
				}
				return undefined;
			}),
			_.isUndefined
		);

		errors = { ...dateerors };
		if (
			!_.isEmpty(formdata['lab_uuid']) &&
			_.isEmpty(formdata['genetic_test_uuid'])
		) {
			errors['genetic_test_uuid'] = 'Test Name is required';
		}

		if (_.isEmpty(formdata['lab_uuid'])) {
			errors['lab_uuid'] = 'Lab is required';
		}
        
		if (
			formdata['order_status'] == 'sent_to_lab' &&
			_.get(formdata, ['date_test_ordered', 'length'], 0) == 0
		) {
			errors['date_test_ordered'] = 'Test Dispatch Date is required';
		}

		if (
			formdata['order_status'] in
				{
					results_ready: 1,
					result_positive_no_genes: 1,
					result_positive_with_genes: 1,
					result_negative: 1,
				} &&
			_.get(formdata, ['date_received_report', 'length'], 0) == 0
		) {
			errors['date_received_report'] = 'Report Receipt Date is required';
		}

		if (
			formdata['order_status'] == 'results_ready' &&
			_.isNil(_.get(formdata, ['positive_results_to_report'], null))
		) {
			errors['positive_results_to_report'] =
				'Please select type of findings to report';
		}

		if (_.isEmpty(formdata['sample_type'])) {
			errors['sample_type'] = 'Sample Type is required';
		}
		if (
			(formdata['order_status'] === 'approved' ||
				formdata['order_status'] === 'rejected' ||
				formdata['order_status'] == 'rejected_other' ||
				formdata['order_status'] == 'no_test_available') &&
			_.get(formdata, ['date_test_authorized', 'length'], 0) == 0
		) {
			errors['date_test_authorized'] =
				'Test Authorization Date is required';
		}
		if (
			(formdata['order_status'] === 'approved' ||
				formdata['order_status'] === 'rejected' ||
				formdata['order_status'] == 'rejected_other' ||
				formdata['order_status'] == 'no_test_available') &&
			_.get(formdata, ['ordering_physician', 'length'], 0) == 0
		) {
			errors['ordering_physician'] = 'Ordering Physician is required';
		}

		if (
			_.isEmpty(formdata['patient_billing_method']) ||
			_.isNil(formdata['patient_billing_method'])
		) {
			errors['patient_billing_method'] =
				'Test Billing Method is required';
		}

		if (
			isMultipleOrderAfterSentToLab() &&
			formdata['order_status'] == 'results_ready' &&
			_.isNil(formdata['is_actionable'])
		) {
			errors['is_actionable'] = 'Please select results actionable';
		} else if (
			!isMultipleOrderAfterSentToLab() &&
			formdata['order_status'] == 'results_ready' &&
			_.isNil(userSelectedResultAction)
		) {
			errors['is_actionable'] = 'Please select results actionable';
		}

		const requiresQuestionnaireFields = determineDropdownQuestionnaire(
			formdata['genetic_test_uuid'],
			nonlegacytestOrderPopupTestEnums
		);
		if (
			currentForm(testorder) == TESTREQUEST &&
			requiresQuestionnaireFields &&
			_.get(formdata, 'order_questionnaire.length', 0) === 0
		) {
			errors['order_questionnaire'] = 'Questionnaire data is required';
		}

		return errors;
	};

	const validateShippingAddressForm = (shippingAddressFormData) => {
		const shippingAddressDataErrors = {};
		const requiresShippingAddressFields = determineShippingAddressRequired(
			formdata['genetic_test_uuid'],
			nonlegacytestOrderPopupTestEnums,
			_.get(formdata, ['order_status'], {})
		);

		if (
			currentForm(testorder) == TESTREQUEST &&
			requiresShippingAddressFields
		) {
			if (
				_.isEmpty(shippingAddressFormData['patient_address_line1']) ||
				_.isNil(shippingAddressFormData['patient_address_line1'])
			) {
				shippingAddressDataErrors['patient_address_line1'] =
					'Address is required';
			}

			if (
				!_.isEmpty(shippingAddressFormData['patient_address_line1`']) &&
				!shippingAddressFormData['patient_address_line1'].match(
					/((?:[A-Za-z0-9-\—\ '/,.()!@$%#]+$)*[a-zA-Z0-9])/
				)
			) {
				shippingAddressDataErrors['patient_address_line1'] =
					'Address is invalid';
			}

			if (
				_.isEmpty(
					shippingAddressFormData['patient_shipping_address_city']
				) ||
				_.isNil(
					shippingAddressFormData['patient_shipping_address_city']
				)
			) {
				shippingAddressDataErrors['patient_shipping_address_city'] =
					'City is required';
			}

			if (
				!_.isEmpty(
					shippingAddressFormData['patient_shipping_address_city']
				) &&
				!/^[a-zA-Z,\d\s-'.`]+$/.test(
					shippingAddressFormData['patient_shipping_address_city']
				)
			) {
				shippingAddressDataErrors['patient_shipping_address_city'] =
					'City is invalid';
			}

			if (
				_.isEmpty(
					shippingAddressFormData['patient_shipping_address_state']
				) ||
				_.isNil(
					shippingAddressFormData['patient_shipping_address_state']
				)
			) {
				shippingAddressDataErrors['patient_shipping_address_state'] =
					'State is required';
			}

			if (
				_.isEmpty(
					shippingAddressFormData['patient_shipping_address_zipcode']
				) ||
				_.isNil(
					shippingAddressFormData['patient_shipping_address_zipcode']
				)
			) {
				shippingAddressDataErrors['patient_shipping_address_zipcode'] =
					'Zip is required';
			}

			if (
				!_.isEmpty(
					shippingAddressFormData['patient_shipping_address_zipcode']
				) &&
				!validPostalCode.validate(
					shippingAddressFormData['patient_shipping_address_zipcode'],
					'US'
				)
			) {
				shippingAddressDataErrors['patient_shipping_address_zipcode'] =
					'Zip is invalid';
			}
		}

		return shippingAddressDataErrors;
	};

	const saveButtonValidation = () => {
		let _testorder = testorder;
		const hold_result_release = _.get(
			encounter,
			['hold_result_release'],
			null
		);
		_testorder = {
			..._testorder,
			hold_result_release: _.isNil(hold_result_release)
				? false
				: hold_result_release,
			order_status:
				_.get(_testorder, 'localorderstatus', '') === 'approved'
					? 'awaiting_cc_submission'
					: _.get(_testorder, 'localorderstatus', ''),
			expected_lab_results_date: getExpectedLabResultsDate(),
		};
		const _changeFields = _.omitBy(formdata, (value, key) => {
			if (key in datefields) {
				const datevalue = value == '' ? null : value;
				const propskeyvalue = _.isEmpty(_.get(_testorder, key, ''))
					? null
					: _.get(_testorder, key, null);
				if (_.isNil(datevalue) || _.isNil(propskeyvalue)) {
					if (datevalue == propskeyvalue) {
						return true;
					} else {
						return false;
					}
				}
				return moment(propskeyvalue).isSame(moment(datevalue));
			}
			return _testorder[key] == value;
		});
		const _newICDCodes = _.xorBy(
			selectedICDCodes,
			_.get(testorder, 'medical_codes', []),
			'uuid'
		);

		if (!_.isEmpty(_changeFields)) {
			for (const _key in _changeFields) {
				if (
					_key === 'lab_order_status' &&
					_changeFields[_key].trim() === ''
				) {
					delete _changeFields[_key];
				}
			}
		}

		if (
			_.isEmpty(_changeFields) &&
			draftNotes.length == 0 &&
			_newICDCodes.length == 0
		) {
			return true;
		}
		return false;
	};

	const isSubmitDisabled = () => {
		const errors = validateForm(formdata);
		const shippingAddressDataErrors = validateShippingAddressForm(
			shippingAddressFormData
		);
		const requiresShippingAddressFields = determineShippingAddressRequired(
			formdata['genetic_test_uuid'],
			nonlegacytestOrderPopupTestEnums,
			_.get(formdata, ['order_status'], {})
		);

		if (requiresShippingAddressFields) {
			return (
				saveButtonValidation() ||
				!_.isEmpty(shippingAddressDataErrors) ||
				loading
			);
		}

		if (
			!isMultipleOrderAfterSentToLab() &&
			(addAnotherTestNameFormData['addTest2'] === 'yes' ||
				addAnotherTestNameFormData['addTest3'] === 'yes')
		) {
			const addAnotherTestNameFormDataErrors =
				validateAddAnotherTestNameForm(addAnotherTestNameFormData);
			return (
				!_.isEmpty(addAnotherTestNameFormDataErrors) ||
				!_.isEmpty(errors)
			);
		}

		let isMultipleTestOrderAfterSentLabError = false;
		Object.keys(errorsForMultipleOrder).forEach((key) => {
			if (!_.isEmpty(errorsForMultipleOrder[key])) {
				isMultipleTestOrderAfterSentLabError = true;
			}
		});
		return (
			saveButtonValidation() ||
			!_.isEmpty(errors) ||
			loading ||
			isMultipleTestOrderAfterSentLabError
		);
	};

	const getTestNumberText = () => {
		const testNumber = testOrderNumber;
		if (testNumber > 0) {
			return '#' + testNumber;
		} else {
			return '';
		}
	};

	const handleDraftNotes = (value) => {
		setDraftNotes(value);
	};

	const getNotes = () => {
		let result = draftNotes.map((obj) => {
			return { ...obj, note_type: 'draft' };
		});

		if (!_.isNil(testOrderNotes)) {
			const _sortPropTestOrderNotes = _.orderBy(
				testOrderNotes,
				(o) => {
					return new moment(_.get(o, 'note_drafted_time', null));
				},
				['desc']
			);
			result = [...result, ..._sortPropTestOrderNotes];
		}
		return result;
	};

	const tabChange = (tabvalue) => {
		setTabValue(tabvalue);
	};

	const notesTabLabel =
		!_.isNil(testOrderNotes) && !_.isEmpty(testOrderNotes)
			? `Notes (${_.get(testOrderNotes, ['length'], 0)})`
			: 'Notes';

	const isLabIntegrationEnabled = () => {
		return _.find(nonlegacytestOrderPopupTestEnums, {
			key: formdata?.genetic_test_uuid,
		})?.lab_integration_enabled;
	};

	const isRequiredReflexAOEQuestion = (required, dependsOn, answers) => {
		if (required === 'yes') {
			return true;
		} else if (
			required === 'conditional' &&
			!_.isEmpty(dependsOn) &&
			_.get(answers, [dependsOn.name], null) === dependsOn.value
		) {
			return true;
		} else if (required === 'no') {
			return false;
		}
		return false;
	};

	const isAOEUnanswered = () => {
		if (
			testorder?.is_questionnaire_editable ||
			/* if test name has change and new test name is for lab integration */
			testorder.genetic_test_uuid !== formdata.genetic_test_uuid
		) {
			if (!formdata?.skip_lab_integration && !_.isEmpty(aoeQuestions)) {
				for (const {
					name,
					is_required,
					type,
					depends_on,
				} of aoeQuestions) {
					if (DateTypes.includes(type)) {
						if (
							isRequiredReflexAOEQuestion(
								is_required,
								depends_on,
								aoeAnswers
							) &&
							(!_.has(aoeAnswers, name) ||
								!moment(_.get(aoeAnswers, name)).isValid())
						) {
							return true;
						}
					} else {
						if (
							isRequiredReflexAOEQuestion(
								is_required,
								depends_on,
								aoeAnswers
							) &&
							(!_.has(aoeAnswers, name) ||
								_.isEmpty(_.get(aoeAnswers, name)) ||
								_.isNil(_.get(aoeAnswers, name)))
						) {
							return true;
						}
					}
				}
			}
		}
		return false;
	};

	const isReflexAOEUnanswered = () => {
		if (testorder?.is_questionnaire_editable) {
			if (!formdata?.skip_lab_integration && !_.isEmpty(reflexAOEQuestions)) {
				for (const {
					name,
					is_required,
					type,
					depends_on,
				} of reflexAOEQuestions) {
					if (DateTypes.includes(type)) {
						if (
							isRequiredReflexAOEQuestion(
								is_required,
								depends_on,
								reflexAOEAnswers
							) &&
							(!_.has(reflexAOEAnswers, name) ||
								!moment(_.get(reflexAOEAnswers, name)).isValid())
						) {
							return true;
						}
					} else {
						if (
							isRequiredReflexAOEQuestion(
								is_required,
								depends_on,
								reflexAOEAnswers
							) &&
							(!_.has(reflexAOEAnswers, name) ||
								_.isEmpty(_.get(reflexAOEAnswers, name)) ||
								_.isNil(_.get(reflexAOEAnswers, name)))
						) {
							return true;
						}
					}
				}
			}
		}

		return false;
	};

	const isAOEAnswerInvalid = () => {
		let hasAOEAnswerError = false;
		if (!_.isEmpty(aoeQuestions)) {
			for (const { name, type } of aoeQuestions) {
				if (
					type === 'email' &&
					name in aoeAnswers &&
					!_.isEmpty(aoeAnswers[name]) &&
					!EmailValidator.validate(aoeAnswers[name])
				) {
					setAoeAnswersErrors((prevState) => {
						return {
							...prevState,
							[name]: 'Email is invalid',
						};
					});
					hasAOEAnswerError = true;
				} else if (
					type === 'phone' &&
					name in aoeAnswers &&
					!_.isEmpty(aoeAnswers[name]) &&
					!isValidPhoneNumber(aoeAnswers[name])
				) {
					setAoeAnswersErrors((prevState) => {
						return {
							...prevState,
							[name]: 'Phone number is invalid',
						};
					});
					hasAOEAnswerError = true;
				} else if (
					DateTypes.includes(type) &&
					name in aoeAnswers &&
					!_.isEmpty(aoeAnswers[name]) &&
					!isValidDateStringOREmpty(aoeAnswers[name])
				) {
					setAoeAnswersErrors((prevState) => {
						return {
							...prevState,
							[name]: 'Date is invalid',
						};
					});
					hasAOEAnswerError = true;
				} else {
					setAoeAnswersErrors((prevState) => {
						return {
							...prevState,
							[name]: '',
						};
					});
				}
			}
		}
		return hasAOEAnswerError;
	};

	const testName2Enums = () => {
		const testName = formdata?.genetic_test_uuid;
		const testName3Key = addAnotherTestNameFormData?.test_name3 || null;
		const testName1Detail = _.find(nonlegacytestOrderPopupTestEnums || [], {
			key: testName,
		});
		const _testNameEnums = [];
		if (testName1Detail && testName1Detail.group) {
			const testName1GroupUUID = _.get(
				testName1Detail,
				['group', 'uuid'],
				''
			);
			_.forEach(nonlegacytestOrderPopupTestEnums, function (value) {
				if (
					_.get(value, ['group', 'uuid'], '') ===
						testName1GroupUUID &&
					value.key !== testName1Detail.key &&
					value.key !== testName3Key
				) {
					_testNameEnums.push({
						key: value?.key,
						value: value?.key,
						label: value?.display_name,
					});
				}
			});
		}
		return _testNameEnums;
	};

	const testName3Enums = () => {
		const testName = formdata?.genetic_test_uuid;
		const testName2Key = addAnotherTestNameFormData?.test_name2 || '';
		const testName1Detail = _.find(nonlegacytestOrderPopupTestEnums || [], {
			key: testName,
		});
		const _testNameEnums = [];
		if (testName1Detail && testName1Detail.group) {
			const testName1GroupUUID = _.get(
				testName1Detail,
				['group', 'uuid'],
				''
			);
			_.forEach(nonlegacytestOrderPopupTestEnums, function (value) {
				if (
					_.get(value, ['group', 'uuid'], '') ===
						testName1GroupUUID &&
					value.key !== testName1Detail.key &&
					value.key !== testName2Key
				) {
					_testNameEnums.push({
						key: value?.key,
						value: value?.key,
						label: value?.display_name,
					});
				}
			});
		}
		return _testNameEnums;
	};

	const hasPatientNoInsurance = () => {
		const lab = _.get(formdata, ['lab_uuid'], '');
		const labvalue = labenums.find((a) => a.key === lab);
		if (
			(formdata?.patient_billing_method || '').toLowerCase() ===
				'insurance' &&
			_.isEmpty(patientdetail?.insurances || []) &&
			(labvalue?.display_name || '').toLowerCase() === 'invitae'
		) {
			return true;
		}
		return false;
	};

	return (
		<div className='createTestOrderModalContainer'>
			<Loading loading={loading}>
				<div className='edit-test-order-modal-top-row'>
					<div className='edit-test-order-modal-header-wrapper'>
						<div className='createTestOrderModalHeader'>
							<TextWithTooltip maxWidth={'584px'}>
								{'Test Order ' + getTestNumberText()}
							</TextWithTooltip>
						</div>
						<div className='edit-test-order-modal-close'>
							<ReactSVG
								src={xIcon}
								onClick={() => {
									hideModal();
								}}
							/>
						</div>
					</div>
				</div>
				<div className='edit-test-rqst-dialog-body'>
					<Tabs
						activeName={
							tabvalue ||
							_.get(
								props,
								['defaultTabName'],
								'testInfoTab'
							)
						}
						className='edit-test-order-tabs-wrapper'
						onTabClick={(tab) => tabChange(tab.props.name)}
					>
						<Tabs.Pane
							label={
								_.get(testorder, ['localorderstatus'], null) in
								TestRequestStatus
									? 'Test Info'
									: 'Order Info'
							}
							name='testInfoTab'
						>
							<div className='crte-test-rqst-dialog edit-test-rqst-dialog-v2'>
								<EditTestRequestModal
									encounter={encounter}
									encounterschema={encounterschema}
									formdata={formdata}
									handleDateFieldValidate={
										handleDateFieldValidate
									}
									holdResultRelease={holdResultRelease}
									labenums={labenums}
									labOrderStatus={labOrderStatus}
									laborderstatusenums={laborderstatusenums}
									mloOrderStatusEnums={mloOrderStatusEnums}
									nonlegacytestOrderPopupTestEnums={
										nonlegacytestOrderPopupTestEnums
									}
									onFormChange={onFormChange}
									onResultActionChange={onResultActionChange}
									ordering_physicianenums={
										ordering_physicianenums
									}
									orderrequeststatuscascadeenums={
										orderrequeststatuscascadeenums
									}
									orderstatuscascadeenums={
										orderstatuscascadeenums
									}
									outreachStatusEnums={outreachStatusEnums}
									outreachTimeToStart={outreachTimeToStart}
									patientbillingmethodenums={
										patientbillingmethodenums
									}
									patientdetail={patientdetail}
									sampletypeenums={sampletypeenums}
									selectedICDCodes={selectedICDCodes}
									tabvalue={tabvalue}
									testnameenums={testnameenums}
									testOrderList={testOrderList}
									testOrderNumber={testOrderNumber}
									testorder={testorder}
									groupTestorders={groupTestorders}
									testOutreachStatus={testOutreachStatus}
									userSelectedICDCodes={selectedICDCodes}
									shippingAddressFormData={
										shippingAddressFormData
									}
									onShippingAddressFormChange={
										onShippingAddressFormChange
									}
									onAddAnotherTestNameFormChange={
										onAddAnotherTestNameFormChange
									}
									genetic_test2={genetic_test2}
									genetic_test3={genetic_test3}
									testName2Enums={testName2Enums()}
									testName3Enums={testName3Enums()}
									onFormChangeForMultipleTestOrder={
										onFormChangeForMultipleTestOrder
									}
									stateGroupTestorders={stateGroupTestorders}
									reflexAOEQuestions={reflexAOEQuestions}
									reflexAOEAnswers={reflexAOEAnswers}
									setReflexAnswers={setReflexAOEAnswers}
									hideModal={hideModal}
									hasPatientNoInsurance={
										hasPatientNoInsurance
									}
								/>
							</div>
						</Tabs.Pane>
						{!formdata.skip_lab_integration &&
							isLabIntegrationEnabled() &&
							!_.isEmpty(nonlegacytestOrderPopupTestEnums) && (
								<Tabs.Pane
									label='Ask on Order Entry'
									name='askOnOrderEntry'
								>
									<div className='crte-test-rqst-dialog edit-test-rqst-dialog-v2'>
										<AOEQuestions
											isEditable={
												testorder?.is_questionnaire_editable ||
												/* if test name has change and new test name is for lab integration */
												(!formdata.skip_lab_integration &&
													!_.isNil(
														testorder.genetic_test_uuid
													) &&
													!_.isNil(
														formdata.genetic_test_uuid
													) &&
													testorder.genetic_test_uuid !==
														formdata.genetic_test_uuid)
											}
											questions={aoeQuestions}
											answers={aoeAnswers}
											setAnswer={setAOEAnswers}
											encounter={encounter}
											encounterSchema={encounterschema}
											patientdetail={patientdetail}
											testorder={testorder}
											orderingPhysicianenums={
												ordering_physicianenums
											}
											selectedICDCodes={selectedICDCodes}
											sampletypeenums={sampletypeenums}
											patientbillingmethodenums={
												patientbillingmethodenums
											}
											formdata={formdata}
											tabvalue={tabvalue}
											aoeAnswersErrors={aoeAnswersErrors}
										/>
									</div>
								</Tabs.Pane>
							)}
						<Tabs.Pane label='ICD-10 Codes' name='ICD10CodesTab'>
							<div className='crte-test-rqst-dialog edit-test-rqst-dialog-v2'>
								<ICDSelectionComponent
									icdCodesError={icdCodesError}
									icdCodesLoading={icdCodesLoading}
									icdCodesPlacement={icdCodesPlacement}
									icdCodes={icdCodes}
									selectedICDCodes={selectedICDCodes}
									getICDCodes={getICDCodes}
									enqueueSnackbar={enqueueSnackbar}
									setSelectedICDCodes={
										setSelectedICDCodesFunc
									}
									encounterICDcodes={encounterICDcodes}
								/>
							</div>
						</Tabs.Pane>
						<Tabs.Pane label={notesTabLabel} name='notesTab'>
							<NotesTestOrder
								{...{
									draftNotes,
									encounter,
									encounterschema,
									formdata: formdata,
									handleDraftNotes,
									noteList: getNotes(),
									ordering_physicianenums,
									patientbillingmethodenums,
									patientdetail,
									sampletypeenums,
									selectedICDCodes,
									tabvalue,
									testorder,
									user,
								}}
							/>
						</Tabs.Pane>
						<Tabs.Pane label='Audit Trail' name='AuditTrail'>
							<EncounterAuditTrail
								encounter={encounter}
								encounterschema={encounterschema}
								patientdetail={patientdetail}
								testorder={testorder}
								ordering_physicianenums={
									ordering_physicianenums
								}
								selectedICDCodes={selectedICDCodes}
								sampletypeenums={sampletypeenums}
								patientbillingmethodenums={
									patientbillingmethodenums
								}
								user={user}
								handleDraftNotes={handleDraftNotes}
								noteList={getNotes()}
								draftNotes={draftNotes}
								tabvalue={tabvalue}
								formdata={formdata}
								auditTrailEncounterLoading={
									auditTrailEncounterLoading
								}
								auditTrailEncounter={auditTrailEncounter}
								auditTrailEncounterError={
									auditTrailEncounterLoading
								}
							/>
						</Tabs.Pane>
					</Tabs>
				</div>
				{isAOEUnanswered() && tabvalue !== 'askOnOrderEntry' ? (
					<div className='createTestOrderModalButtonRow'>
						<span
							className={
								!saveButtonValidation() ? 'save-alert-msg' : ''
							}
						>
							Please verify your changes before saving.
						</span>
						<Button
							className='createTestOrderModalActionButton'
							onClick={() => tabChange('askOnOrderEntry')}
							disabled={
								isReflexAOEUnanswered() ||
								hasPatientNoInsurance()
							}
						>
							Next
						</Button>
					</div>
				) : (
					<div className='createTestOrderModalButtonRow'>
						<span
							className={
								!saveButtonValidation() ? 'save-alert-msg' : ''
							}
						>
							Please verify your changes before saving.
						</span>
						<Button
							className='createTestOrderModalActionButton'
							disabled={
								isSubmitDisabled() ||
								isAOEUnanswered() ||
								isReflexAOEUnanswered() ||
								hasPatientNoInsurance()
							}
							onClick={editTestOrderData}
						>
							Save & Close
						</Button>
					</div>
				)}

				{showInternalTestOrderErrorPopup && (
					<InternalTestOrderErrorPopup
						setShowInternalTestOrderErrorPopup={
							setShowInternalTestOrderErrorPopup
						}
						hideModalTestOrderPopup={hideModal}
					/>
				)}
			</Loading>
		</div>
	);
};

export default EditTestOrderModalContainer;

const InternalTestOrderErrorPopup = (props) => {
	const { setShowInternalTestOrderErrorPopup, hideModalTestOrderPopup } = props;
	return (
		<div className='popupModal'>
			<div className='internal-test-order-error-modal-container'>
				<div className='internal-test-order-error-modal-top-row'>
					<div className='internal-test-order-error-modal-header'>
						Internal Test Order Error
					</div>
					<div className='internal-test-order-error-modal-close'>
						<ReactSVG
							src={xIcon}
							style={{
								position: 'relative',
								bottom: '2px',
								right: '1.25px',
							}}
							onClick={() => {
								setShowInternalTestOrderErrorPopup(false);
							}}
						/>
					</div>
				</div>
				<div className='internal-test-order-error-modal-body'>
					An internal error has occurred. Please click "Refresh"
					and re-enter the information in the fields on this page
					to update the status.
				</div>
				<div className='internal-test-order-error-modal-buttonrow'>
					<Button
						className='internal-test-order-error-cancel-button'
						onClick={() => {
							setShowInternalTestOrderErrorPopup(false);
						}}
					>
						Cancel
					</Button>
					<Button
						className='internal-test-order-error-refresh-button'
						onClick={() => {
							hideModalTestOrderPopup();
						}}
					>
						Refresh
					</Button>
				</div>
			</div>
		</div>
	);
};
