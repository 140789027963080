//React & Redux
import React from 'react';
import classnames from 'classnames';

//Lodash
import _ from 'lodash';

//Utils
import {
	getLinkedVisitEncounter,
	scp_encounter_sub_types_automated_pre_test,
} from '../../../Utils/encounterUtils';

//Components
import LinkPopover from '../../Common/Popovers/LinkPopover';

//Styles
import './LinkedEncounter.css';

const LinkedEncounter = (props) => {
	const { encounter, history, patient } = props;

	const { encounter_subtype: encounterSubType = '' } = encounter;

	const {
		first_name: firstName = '--',
		last_name: lastName = '--',
		uuid: patientUuid = 0,
	} = patient;

	const linkedEncounterObj = getLinkedVisitEncounter(encounter);
	const encounterUuid = _.get(linkedEncounterObj, 'uuid', 0);
	const linkedEncounterUrl = `/app/patientdetail/${patientUuid}/0/2/${encounterUuid}/`;

	let linkedEncounterText = (
		<span className='not-scheduled'>{'Not Scheduled'}</span>
	);
	let hasHref = false;

	if (
		_.get(
			scp_encounter_sub_types_automated_pre_test,
			encounterSubType,
			false
		)
	) {
		linkedEncounterText = '--';
	} else if (!_.isEmpty(linkedEncounterObj)) {
		linkedEncounterText = (
			<span>{_.get(linkedEncounterObj, 'consultation_type', '')}</span>
		);
		hasHref = true;
	}

	const redirectToLinkedEncounter = () => {
		history.push({
			pathname: linkedEncounterUrl,
			state: {
				breadcrumbs: [
					{
						location: 'Patient Detail',
						patientFullName: `${firstName} ${lastName}`,
					},
				],
			},
		});
	};

	return (
		<div className='linked-encounter-wrapper'>
			<span className='linked-encounter-label'>Linked Encounter</span>
			{hasHref ? (
				<LinkPopover
					linkTo={linkedEncounterUrl}
					options={{ link: true }}
				>
					<span
						onClick={
							hasHref ? () => redirectToLinkedEncounter() : null
						}
						className={classnames(
							'linked-encounter-content',
							hasHref ? 'linked-encounter-link' : ''
						)}
					>
						{linkedEncounterText}
					</span>
				</LinkPopover>
			) : (
				<span className='linked-encounter-content'>
					{linkedEncounterText}
				</span>
			)}
		</div>
	);
};

export default LinkedEncounter;
