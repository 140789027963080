//React & Redux
import React, { Component } from 'react';

//Images
import editpencil from '../../../../assets/edit.svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import { CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER } from '../../../../Utils/permissionUtils';

//Other Libraries
import moment from 'moment';
import { Button } from 'gm-element-react';

//Components
import PreAuthTestOrderModalContainer from './PreAuthTestOrderModalContainer';
import RBAC from '../../../RBAC';
import TextWithTooltip from '../../../Common/TextWithTooltip';

//Styles
import './style.css';

const finddisplayvalue = (array, key) => {
	const element = _.find(array, ['key', key]);
	return element && element.display_name ? element.display_name : '';
};

const findchilderndisplayvalue = (array, parent, child) => {
	if (child) {
		const element = _.find(array, ['key', parent]);
		return element && element.children
			? finddisplayvalue(element.children || [], child)
			: '';
	} else {
		return finddisplayvalue(array, parent);
	}
};

class PreAuthTestOrderRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPreAuthTestModal: false,
			defaultTabIndex: 0,
		};
		this.hidePreAuthTestModal = this.hidePreAuthTestModal.bind(this);
	}

	hidePreAuthTestModal = () => {
		this.setState({
			showPreAuthTestModal: false,
			defaultTabName: 'requestInfo',
			defaultTabIndex: 0,
		});
	};

	progressbarRequestDecisionData = () => {
		const request_decision = _.get(
			this,
			['props', 'testorder', 'request_decision'],
			''
		);
		const preauthorizationdecisionenums = _.get(
			this,
			['props', 'preauthorizationdecisionenums'],
			''
		);
		if (request_decision == 'requested_pa_cm') {
			return {
				displayName: finddisplayvalue(
					preauthorizationdecisionenums,
					request_decision
				),
				displayDate: this.findfirststatuschagefromhistory(
					'dispatch_reason',
					'awaiting_cc_submission'
				),
				className: 'done',
			};
		} else if (request_decision == 'requested_pa_cnm') {
			return {
				displayName: finddisplayvalue(
					preauthorizationdecisionenums,
					request_decision
				),
				displayDate: this.findfirststatuschagefromhistory(
					'dispatch_reason',
					'awaiting_cc_submission'
				),
				className: 'done',
			};
		} else if (request_decision == 'requested_alternate_pa_atr') {
			return {
				displayName: finddisplayvalue(
					preauthorizationdecisionenums,
					request_decision
				),
				displayDate: this.findfirststatuschagefromhistory(
					'dispatch_reason',
					'awaiting_cc_submission'
				),
				className: 'done',
			};
		} else if (request_decision == 'not_requested_pa_cnm') {
			return {
				displayName: finddisplayvalue(
					preauthorizationdecisionenums,
					request_decision
				),
				displayDate: this.findfirststatuschagefromhistory(
					'dispatch_reason',
					'awaiting_cc_submission'
				),
				className: 'stop',
			};
		} else if (request_decision == 'patient_declined_pa_cm') {
			return {
				displayName: finddisplayvalue(
					preauthorizationdecisionenums,
					request_decision
				),
				displayDate: this.findfirststatuschagefromhistory(
					'dispatch_reason',
					'awaiting_cc_submission'
				),
				className: 'stop',
			};
		} else if (request_decision == 'patient_declined_pa_cnm') {
			return {
				displayName: finddisplayvalue(
					preauthorizationdecisionenums,
					request_decision
				),
				displayDate: this.findfirststatuschagefromhistory(
					'dispatch_reason',
					'awaiting_cc_submission'
				),
				className: 'stop',
			};
		} else if (request_decision == 'patient_pending_pa') {
			return {
				displayName: finddisplayvalue(
					preauthorizationdecisionenums,
					request_decision
				),
				displayDate: '',
				className: 'inprogress',
			};
		} else {
			return {
				displayName: '',
				displayDate: '',
				className: '',
			};
		}
	};

	progressbarDispatchStatusData = () => {
		const testorder = _.get(this, ['props', 'testorder'], {});
		const dispatch_status_reason = _.get(
			this,
			['props', 'testorder', 'props_dispatch_status_reason'],
			''
		);
		const dispatch_status = _.get(
			this,
			['props', 'testorder', 'props_dispatch_status'],
			''
		);
		const preauthdispatchstatusenums = _.get(
			this,
			['props', 'preauthdispatchstatusenums'],
			''
		);
		if (dispatch_status_reason == 'awaiting_information_patient') {
			return {
				displayName: findchilderndisplayvalue(
					preauthdispatchstatusenums,
					dispatch_status,
					dispatch_status_reason
				),
				displayDate: '',
				className: 'inprogress',
			};
		} else if (dispatch_status_reason == 'awaiting_cc_submission') {
			return {
				displayName: findchilderndisplayvalue(
					preauthdispatchstatusenums,
					dispatch_status,
					dispatch_status_reason
				),
				displayDate: '',
				className: 'inprogress',
			};
		} else if (dispatch_status == 'pa_form_sent') {
			return {
				displayName: finddisplayvalue(
					preauthdispatchstatusenums,
					dispatch_status
				),
				displayDate:
					testorder && testorder.dispatch_date
						? moment(testorder.dispatch_date).format('MMM DD')
						: '',
				className: 'done',
			};
		} else if (dispatch_status == 'declined_dispatch_na') {
			return {
				displayName: finddisplayvalue(
					preauthdispatchstatusenums,
					dispatch_status
				),
				displayDate: '',
				className: 'stop',
			};
		} else {
			return {
				displayName: '',
				displayDate: '',
				className: 'pending',
			};
		}
	};

	findfirststatuschagefromhistory = (key, value) => {
		const props = this.props;
		const history = _.get(props, ['testorder', 'history'], '[]');
		const sortedarray = _.sortBy(history, function (dateObj) {
			return new Date(dateObj.created_at);
		});
		if (_.isArray(sortedarray)) {
			const obj = _.find(sortedarray, [key, value]);
			return obj && obj.created_at
				? moment.tz(obj.created_at, 'UTC').local().format('MMM DD')
				: '';
		} else {
			return '';
		}
	};

	render() {
		const props = this.props;
		const DecisionData = this.progressbarRequestDecisionData();
		const DispatchData = this.progressbarDispatchStatusData();
		return (
			<div className='testorder-row'>
				<div className='testorder-testname-bar-contain'>
					<div className='testorder-testname-bar'>
						<RBAC
							action={CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER}
							yes={
								<Button
									className='val-test-name'
									onClick={() =>
										this.setState({
											showPreAuthTestModal: true,
										})
									}
									type='text'
								>
									<TextWithTooltip>
										{_.get(
											props,
											['testorder', 'test_name'],
											'--'
										)}
									</TextWithTooltip>
								</Button>
							}
							no={
								<Button
									className='val-test-name disable-btn-extrnl-user'
									type='text'
								>
									<TextWithTooltip>
										{_.get(
											props,
											['testorder', 'test_name'],
											'--'
										)}
									</TextWithTooltip>
								</Button>
							}
						/>

						<RBAC
							action={CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER}
							yes={
								<Button
									className='link-icd-10'
									onClick={() =>
										this.setState({
											showPreAuthTestModal: true,
											defaultTabIndex: 1,
										})
									}
									type='text'
								>
									ICD-10 Codes (
									{_.get(
										this,
										[
											'props',
											'testorder',
											'medical_codes',
											'length',
										],
										0
									)}
									)
								</Button>
							}
							no={
								<Button
									className='link-icd-10 disable-btn-extrnl-user'
									type='text'
								>
									ICD-10 Codes (
									{_.get(
										this,
										[
											'props',
											'testorder',
											'medical_codes',
											'length',
										],
										0
									)}
									)
								</Button>
							}
						/>
					</div>
					<div className='testorder-tracking-bar'>
						<div className='testorder-left-side'>
							<div className='lab-name'>
								{' '}
								{_.get(props, ['testorder', 'lab'], '--')}{' '}
							</div>
						</div>
					</div>
					<div className='testorder-tracking-bar'>
						<div className='preauth-progressbar-wrapper'>
							<ul className='pre-auth-progressbar'>
								<li className={DecisionData.className}>
									<span />
									<div className='step-name'>
										{DecisionData.displayName}
									</div>
									<div className='step-date'>
										{DecisionData.displayDate}
									</div>
								</li>
								<li className={DispatchData.className}>
									<span />
									<div className='step-name'>
										{DispatchData.displayName}
									</div>
									<div className='step-date'>
										{DispatchData.displayDate}
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				{get(this, 'state.showPreAuthTestModal', false) && (
					<div className='popupModal'>
						<PreAuthTestOrderModalContainer
							testRequestMode='edit'
							hidePreAuthTestModal={this.hidePreAuthTestModal}
							getICDCodes={this.props.getICDCodes}
							icdCodes={_.get(this, ['props', 'icdCodes'], [])}
							icdCodesLoading={_.get(
								this,
								['props', 'icdCodesLoading'],
								false
							)}
							icdCodesError={_.get(
								this,
								['props', 'icdCodesError'],
								null
							)}
							icdCodesPlacement={_.get(
								this,
								['props', 'icdCodesPlacement'],
								null
							)}
							enqueueSnackbar={this.props.enqueueSnackbar}
							selectedICDCodes={_.get(
								this,
								'props.encounter.medical_codes',
								null
							)}
							testorder={_.get(this, ['props', 'testorder'], {})}
							labenums={_.get(
								this,
								['props', 'encounterschema', 'lab'],
								[]
							)}
							LoadTestOrderPopupTestEnum={_.get(
								this,
								['props', 'LoadTestOrderPopupTestEnum'],
								[]
							)}
							nonlegacytestnameenums={_.get(
								this,
								['props', 'nonlegacytestnameenums'],
								[]
							)}
							nonlegacytestOrderPopupTestEnums={_.get(
								this,
								['props', 'nonlegacytestOrderPopupTestEnums'],
								[]
							)}
							encounter={_.get(this, ['props', 'encounter'], {})}
							patientdetail={_.get(
								this,
								['props', 'patientdetail'],
								{}
							)}
							closeEditTestOrder={this.props.cancel}
							dispatchstatusenums={_.get(
								this,
								['props', 'dispatchstatusenums'],
								[]
							)}
							preauthorizationdecisionenums={_.get(
								this,
								['props', 'preauthorizationdecisionenums'],
								[]
							)}
							preauthdecisionenums={_.get(
								this,
								['props', 'preauthdecisionenums'],
								[]
							)}
							preauthdispatchstatusenums={_.get(
								this,
								['props', 'preauthdispatchstatusenums'],
								[]
							)}
							updatePreAuthTestOrder={
								this.props.updatePreAuthTestOrder
							}
							LoadAlternateTestEnum={_.get(
								this,
								['props', 'LoadAlternateTestEnum'],
								[]
							)}
							nonlegacyalternatetestnameenums={_.get(
								this,
								['props', 'nonlegacyalternatetestnameenums'],
								[]
							)}
							encounterICDcodes={_.get(
								this.props,
								'encounterICDcodes',
								[]
							)}
							defaultTabIndex={this.state.defaultTabIndex}
						/>
					</div>
				)}
			</div>
		);
	}
}
export default PreAuthTestOrderRow;
