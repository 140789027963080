import React, { Component } from 'react';
import { connect } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import _ from 'lodash';

class SnackBar extends Component {
	componentDidUpdate(prevProps) {
		const {
			snackbar: { message, variant },
		} = this.props;
		if (
			prevProps.snackbar != this.props.snackbar &&
			!_.isUndefined(message)
		) {
			enqueueSnackbar(message, {
				variant: variant || 'info',
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'bottom',
				},
			});
		}
	}

	render() {
		return <div />;
	}
}
const mapStateToProps = (state, props) => ({
	snackbar: state.uiConfig.snackbar,
});

export default connect(mapStateToProps, null)(SnackBar);
