//React & Redux
import React, { Component } from 'react';
import PropTypes from 'prop-types';

//Styles
import './AppointmentSlot.css';

class AppointmentSlot extends Component {
	static propTypes = {
		event: PropTypes.object.isRequired,
	};

	render() {
		return (
			<div className='app-slot'>
				<div className='left-line' />
				<div className='title'>{this.props.event.title}</div>
			</div>
		);
	}
}

export default AppointmentSlot;
