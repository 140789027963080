//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//UI Libraries
import { DatePicker } from 'gm-element-react';
import { PaperTRFOnlyEnums, TestClassificationEnums } from './../../utils.js';

//Components
import RadioGroup from '../Common/controls/RadioGroup/RadioGroup';
import { SelectComponent as Select } from '../Common/SelectComponent';

//Other Libraries
import moment from 'moment';

class AddTestInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		const test = this.props.newTest;
		const editTitle = ' testEditTitle';
		const inputFieldText = 'inputFieldText fullWidth';
		const inputFieldDropdown = 'inputFieldDropdown';
		const labError = this.props.errors.has('lab')
			? ' inputFieldDropdownError'
			: '';
		const displayNameError = this.props.errors.has('display_name')
			? ' inputFieldTextErrorState'
			: '';
		const versionError = this.props.errors.has('version')
			? ' inputFieldTextErrorState'
			: '';
		const classificationError = this.props.errors.has('classification')
			? ' inputFieldDropdownError'
			: '';
		const currentlyOfferOption = get(
			this,
			'props.otherEnums.currently_offered',
			[]
		).map((el) => {
			return { key: el.key, display_name: el.display_name };
		});
		const paperFormOnlyOption = PaperTRFOnlyEnums.map((el) => {
			return { key: el.key, display_name: el.display_name };
		});

		return (
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'wrap',
				}}
			>
				<div
					className='testInformation testInformationNew'
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className='testEditRequiredField'>*</div>
					{this.props.errors.has('lab') && (
						<div className='fieldErrMsgTestCreate'>
							Please give a value.
						</div>
					)}
					<div className={'test_field_title' + editTitle}> Lab </div>
					<div className={'test_field_detail'}>
						<Select
							value={test.lab_uuid}
							className={`${inputFieldDropdown} ${labError}`}
							placeholder={'Select'}
							onChange={(e) => {
								this.props.editNewTest('lab_uuid', e);
							}}
							typeahead={true}
							clearable={true}
							optionClass='select-lab'
							options={_.map(
								get(this, 'props.labs', []),
								(lab) => {
									return {
										...lab,
										value: lab.key,
										label: lab.display_name,
									};
								}
							)}
							customOption={(option) => {
								return (
									<Fragment>
										<span className='lab-name'>
											{option.display_name}
										</span>
										{option.paper_form_only == true && (
											<span className='lab-paper-trf'>
												Paper TRF
											</span>
										)}
									</Fragment>
								);
							}}
						/>
					</div>
				</div>

				<div
					className='testInformation testInformationNew'
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className='testEditRequiredField'>*</div>
					{this.props.errors.has('display_name') && (
						<div className='fieldErrMsgTestCreate'>
							Please give a value.
						</div>
					)}
					<div className={'test_field_title' + editTitle}>
						{' '}
						Display name{' '}
					</div>
					<div className={'test_field_detail'}>
						<input
							value={get(test, 'display_name', '')}
							className={`${inputFieldText} ${displayNameError}`}
							onChange={(e) => {
								this.props.editNewTest(
									'display_name',
									e.target.value
								);
							}}
							placeholder='Perkin Elmer_GenePrism'
						/>
					</div>
				</div>

				<div
					className='testInformation testInformationNew testInformationNewEnd'
					style={{ width: '33.3%' }}
				>
					<div className='testEditRequiredField'>*</div>
					{this.props.errors.has('version') && (
						<div className='fieldErrMsgTestCreate'>
							Please give a value.
						</div>
					)}
					<div className={'test_field_title' + editTitle}>
						{' '}
						Version{' '}
					</div>
					<div className={'test_field_detail'}>
						<input
							value={get(test, 'version', '')}
							className={`${inputFieldText} ${versionError}`}
							onChange={(e) => {
								this.props.editNewTest(
									'version',
									e.target.value
								);
							}}
							placeholder='1.1, 2.0, etc.'
						/>
					</div>
				</div>

				<div
					className='testInformation testInformationNew'
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className='testEditRequiredField'>*</div>
					{this.props.errors.has('currently_offered') && (
						<div className='fieldErrMsgTestCreate fieldErrMsgRadio'>
							Please give a value.
						</div>
					)}
					<div className={'test_field_title' + editTitle}>
						{' '}
						Currently Offered?{' '}
					</div>
					<RadioGroup
						options={currentlyOfferOption}
						value={test.currently_offered}
						onChange={(e) => {
							this.props.editNewTest('currently_offered', e);
						}}
					/>
				</div>

				<div className='testInformation' style={{ width: '30.3%' }}>
					<div className='testEditRequiredField'>*</div>
					{this.props.errors.has('classification') && (
						<div
							className='fieldErrMsgTestCreate fieldErrMsgRadio'
							style={{ bottom: '64px' }}
						>
							Please give a value.
						</div>
					)}
					<div className={'test_field_title' + editTitle}>
						{' '}
						Classification{' '}
					</div>
					<div className={'test_field_detail'}>
						<Select
							value={test.classification}
							className={`${inputFieldDropdown} ${classificationError}`}
							placeholder='Select'
							onChange={(e) => {
								this.props.editNewTest('classification', e);
							}}
							typeahead={true}
							clearable={true}
							options={TestClassificationEnums}
						/>
					</div>
				</div>

				<div
					className='testInformation'
					style={{
						width: 'calc(33.3% - 24px)',
						pointerEvents: 'none',
						opacity: 0.4,
					}}
				>
					<div className='testEditRequiredField'>*</div>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Paper TRF only?{' '}
					</div>
					<RadioGroup
						options={paperFormOnlyOption}
						value={test.paper_form_only}
					/>
				</div>

				<div
					className='testInformation'
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Initial available date{' '}
					</div>
					<DatePicker
						value={
							test.init_available_date
								? moment(
										test.init_available_date,
										'YYYY-MM-DD'
								  ).toDate()
								: null
						}
						placeholder='Select'
						onChange={(e) => {
							this.props.editNewTest('init_available_date', e);
						}}
						className='testEditInput testCreateInput testEditInputWide'
						autoComplete='off'
						isReadOnly={false}
						placeholder='Select'
						format='MM/dd/yyyy'
					/>
				</div>

				<div
					className='testInformation testInformationNewEnd'
					style={{ width: '66.6%' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Test code (as used by lab){' '}
					</div>
					<div className={'test_field_detail'}>
						<input
							value={test.lab_test_code || ''}
							className={`${inputFieldText}`}
							onChange={(e) => {
								this.props.editNewTest(
									'lab_test_code',
									e.target.value
								);
							}}
							placeholder='12asg4d'
						/>
					</div>
				</div>

				<div
					className='testInformation testInformationNew testInformationNewEnd'
					style={{ width: '100%' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Lab info{' '}
					</div>
					<div
						className={
							'test_field_detail testInformationUneditable'
						}
						style={{ height: '90px' }}
					>
						<div
							className={'testInformationUneditableText'}
							style={{ width: '100%', height: '90px' }}
						>
							{/* Will be enabled later */}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AddTestInfo;
