//React & Redux
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _, { filter } from 'lodash';
import {
	getaffiliation,
	getPartnerOrganization,
	resetaffiliation,
} from '../../actions/providers';
import {
	loadRiseDashboardData,
	riseDashboardExportReport,
} from '../../actions/riseAnalytics';
import { setFilter } from '../../actions/appData';
import './style.css';
import RiseDashboardFilter from './RiseDashboardFilter';
import DashboardSummary from './DashboardSummary';
import DashboardList from './DashboardList';
import moment from 'moment';
//UI Libraries
import { Loading } from 'gm-element-react';

//Hooks
import { usePrevious } from '../../hooks/usePrevious';

const CardList = [
	{ name: 'complete', display_name: 'COMPLETE' },
	{ name: 'incomplete', display_name: 'INCOMPLETE' },
	{ name: 'criteria_met', display_name: 'MEETS CRITERIA' },
	{ name: 'criteria_not_met', display_name: 'CRITERIA NOT MET' },
	{ name: 'consider_counseling', display_name: 'CONSIDER COUNSELING' },
	{ name: 'appointments_scheduled', display_name: 'APPOINTMENT SCHEDULED' },
];
const LIMIT = 25;

const defaultPageData = { limit: LIMIT, offset: 0 };
const defaultPageDataForCard = {
	limit: LIMIT,
	offset: 0,
	order_by: 'patient_id',
	order_by_direction: 'desc',
};

const RiseManagement = (props) => {
	const {
		getPartnerOrganization,
		getAffiliations,
		partnerOrganization,
		affiliations,
		cardFilter,
		setCardFilter,
		getRiseDashboardData,
		riseAnalyticsData,
		riseAnalyticsDataLoading,
		history,
		gettingProviderAffiliations,
		location,
		exportRiseAnalyticsDataData,
		exportRiseAnalyticsDataLoading,
		exportRiseAnalyticsDataError,
		enqueueSnackbar,
		riseDashboardReport,
	} = props;
	const tempFilter = {
		partner: _.get(cardFilter, ['partner'], []),
		program: _.get(cardFilter, ['program'], []),
		daterange: {
			from: !_.isNil(_.get(cardFilter, ['daterange', 'from'], null))
				? moment(cardFilter.daterange.from).toDate()
				: moment().subtract(90, 'days').toDate(),
			to: !_.isNil(_.get(cardFilter, ['daterange', 'to'], null))
				? moment(cardFilter.daterange.to).toDate()
				: moment().toDate(),
		},
	};
	const tempSummaryPageData = {
		offset: _.get(cardFilter, ['summaryPageData', 'offset'], 0),
		limit: _.get(cardFilter, ['summaryPageData', 'limit'], LIMIT),
	};
	const tempCard = _.get(
		location,
		['state', 'breadcrumbs', '0', 'selectedCard'],
		null
	);
	const tempCardPageData = _.get(
		location,
		['state', 'breadcrumbs', '0', 'CardPageData'],
		{}
	);

	const [filterState, setFilterState] = useState(tempFilter);
	const [selectedCard, setSelectedCard] = useState(tempCard || null);
	const [summaryPageData, setSummaryPageData] = useState(tempSummaryPageData);
	const [CardPageData, setCardPageData] = useState({
		...defaultPageDataForCard,
		...tempCardPageData,
	});
	const [loading, setLoading] = useState(true);

	const previousGettingProviderAffiliations = usePrevious(
		gettingProviderAffiliations
	);
	const previousExportRiseAnalyticsDataLoading = usePrevious(
		exportRiseAnalyticsDataLoading
	);

	useEffect(() => {
		getPartnerOrganization();
		getAffiliations(filterState.partner);
	}, []);

	useEffect(() => {
		if (
			previousGettingProviderAffiliations &&
			!gettingProviderAffiliations &&
			loading
		) {
			callGetDashboradData({
				...filterState,
				selectedCard,
				summaryPageData,
				CardPageData,
			});
			setLoading(false);
		}
	}, [affiliations]);

	useEffect(() => {
		if (
			previousExportRiseAnalyticsDataLoading &&
			!exportRiseAnalyticsDataLoading &&
			loading
		) {
			if (exportRiseAnalyticsDataError != null) {
				enqueueSnackbar(
					'Something went wrong. Please try again later.',
					{
						variant: 'error',
						className: 'export_notification',
						anchorOrigin: { horizontal: 'center', vertical: 'top' },
					}
				);
			} else {
				enqueueSnackbar('Report is sent to your email id.', {
					variant: 'success',
					className: 'export_notification',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
				});
			}
			setLoading(false);
		}
	}, [exportRiseAnalyticsDataLoading]);

	const callGetDashboradData = (data) => {
		setCardFilter({ ...cardFilter, ...data });
		const query = {
			date_from: moment(_.get(data.daterange, ['from'])).format(
				'YYYY-MM-DD'
			),
			date_to: moment(_.get(data.daterange, ['to'])).format('YYYY-MM-DD'),
			offset:
				data.selectedCard == null
					? _.get(data, ['summaryPageData', 'offset'], 0)
					: _.get(data, ['CardPageData', 'offset'], 0),
			limit:
				data.selectedCard == null
					? _.get(data, ['summaryPageData', 'limit'], LIMIT)
					: _.get(data, ['CardPageData', 'limit'], LIMIT),
		};
		if (
			!_.isNil(_.get(data, ['CardPageData', 'order_by'], null)) &&
			data.selectedCard != null
		) {
			query['order_by'] = _.get(data, ['CardPageData', 'order_by'], null);
		}
		if (
			!_.isNil(
				_.get(data, ['CardPageData', 'order_by_direction'], null)
			) &&
			data.selectedCard != null
		) {
			query['order_by_direction'] = _.get(
				data,
				['CardPageData', 'order_by_direction'],
				null
			);
		}

		if (!_.isEmpty(data.program)) {
			query['referral_programs'] = data.program;
		} else {
			query['referral_programs'] = _.map(affiliations, (el) => {
				return el.name;
			});
		}
		const dimension =
			data.selectedCard == null ? 'complete' : data.selectedCard;
		getRiseDashboardData({ query: query }, dimension);
	};

	const handleFilterchange = (label, value) => {
		const tempfilterstate = { ...filterState };
		tempfilterstate[label] = value;
		if (label == 'partner') {
			tempfilterstate['program'] = [];
			setFilterState({ ...tempfilterstate });
			setSummaryPageData(defaultPageData);
			setCardPageData(defaultPageDataForCard);
			getAffiliations(value);
			setLoading(true);
		} else {
			setFilterState({ ...tempfilterstate });
			setSummaryPageData(defaultPageData);
			setCardPageData(defaultPageDataForCard);
			callGetDashboradData({
				...tempfilterstate,
				selectedCard,
				summaryPageData: defaultPageData,
				CardPageData: defaultPageData,
			});
		}
	};

	const handleSummaryTblClick = (type, partner, program) => {
		const tempfilterstate = { ...filterState };
		const findPartner = _.find(partnerOrganization, (el) => {
			return el.display_name == partner;
		});
		tempfilterstate['partner'] = [_.get(findPartner, ['key'], 0)];
		tempfilterstate['program'] = [program];
		setSelectedCard(type);
		setFilterState({ ...tempfilterstate });
		getAffiliations([_.get(findPartner, ['key'], 0)]);
		setLoading(true);
		setSummaryPageData(defaultPageData);
		setCardPageData(defaultPageDataForCard);
	};

	const handleResetFilters = () => {
		const filter = {
			partner: [],
			program: [],
			daterange: {
				from: moment().subtract(90, 'days').toDate(),
				to: moment().toDate(),
			},
		};
		setFilterState(filter);
		setSummaryPageData(defaultPageData);
		setCardPageData(defaultPageDataForCard);
		getAffiliations([]);
		setLoading(true);
	};
	const handleCardClick = (value) => {
		setSelectedCard(value);
		setCardPageData(defaultPageDataForCard);
		const query = {
			...filterState,
			selectedCard: value,
			summaryPageData,
			CardPageData: defaultPageDataForCard,
		};
		callGetDashboradData(query);
	};
	const handlePagination = (type, value) => {
		if (type == 'summary') {
			const _summaryPageData = { ...summaryPageData, offset: value };
			setSummaryPageData(_summaryPageData);
			callGetDashboradData({
				...filterState,
				selectedCard,
				summaryPageData: _summaryPageData,
				CardPageData,
			});
		} else {
			const _CardPageData = { ...CardPageData, offset: value };
			setCardPageData(_CardPageData);
			callGetDashboradData({
				...filterState,
				selectedCard,
				summaryPageData,
				CardPageData: _CardPageData,
			});
		}
	};
	const handleResultsPerPageChange = (type, value) => {
		if (type == 'summary') {
			const _summaryPageData = {
				...summaryPageData,
				offset: 0,
				limit: value,
			};
			setSummaryPageData(_summaryPageData);
			callGetDashboradData({
				...filterState,
				selectedCard,
				summaryPageData: _summaryPageData,
				CardPageData,
			});
		} else {
			const _CardPageData = { ...CardPageData, offset: 0, limit: value };
			setCardPageData(_CardPageData);
			callGetDashboradData({
				...filterState,
				selectedCard,
				summaryPageData,
				CardPageData: _CardPageData,
			});
		}
	};

	const selectPatient = (selectedPatient) => {
		const pathname =
			'/app/patientdetail/' +
			(selectedPatient.patient_uuid || 0) +
			'/0/0/';
		history.push({
			pathname: pathname,
			state: {
				breadcrumbs: [
					{
						location: 'RISE',
						url: '/app/rise',
						selectedCard,
						CardPageData,
					},
					{
						location: 'Patient Detail',
						patientFullName: `${
							selectedPatient.first_name || '--'
						} ${selectedPatient.last_name || '--'}`,
					},
				],
			},
		});
	};
	const handleSort = (order_by, order_type) => {
		const CardPageData = {
			...CardPageData,
			order_by,
			order_by_direction: order_type,
		};
		setCardPageData(CardPageData);
		callGetDashboradData({
			...filterState,
			selectedCard,
			summaryPageData,
			CardPageData,
		});
	};
	const handleExportButton = () => {
		const query = {
			date_from: moment(_.get(filterState.daterange, ['from'])).format(
				'YYYY-MM-DD'
			),
			date_to: moment(_.get(filterState.daterange, ['to'])).format(
				'YYYY-MM-DD'
			),
		};
		if (
			!_.isNil(_.get(CardPageData, ['order_by'], null)) &&
			selectedCard != null
		) {
			query['order_by'] = _.get(CardPageData, ['order_by'], null);
		}
		if (
			!_.isNil(_.get(CardPageData, ['order_by_direction'], null)) &&
			selectedCard != null
		) {
			query['order_by_direction'] = _.get(
				CardPageData,
				['order_by_direction'],
				null
			);
		}
		if (!_.isEmpty(filterState.program)) {
			query['referral_programs'] = filterState.program;
		} else {
			query['referral_programs'] = _.map(affiliations, (el) => {
				return el.name;
			});
		}
		const dimension = selectedCard == null ? 'summary' : selectedCard;
		setLoading(true);
		riseDashboardReport({ query: query }, dimension);
	};

	const cardData = _.get(riseAnalyticsData, ['totals'], {});
	return (
		<Fragment>
			{
				<div className='patients-list-wrapper rise-wrapper'>
					<RiseDashboardFilter
						filterState={filterState}
						handleFilterchange={handleFilterchange}
						handleResetFilters={handleResetFilters}
						partnerOrganization={partnerOrganization}
						handleCardClick={handleCardClick}
						selectedCard={selectedCard}
						cardList={CardList}
						affiliations={affiliations}
						handleExportButton={handleExportButton}
						loading={riseAnalyticsDataLoading || loading}
					/>
					{riseAnalyticsDataLoading || loading ? (
						<Loading loading={true} className='list-loading' />
					) : (
						<Fragment>
							{selectedCard == null && (
								<DashboardSummary
									handleCardClick={handleCardClick}
									selectedCard={selectedCard}
									cardList={CardList}
									cardData={cardData}
									riseAnalyticsData={riseAnalyticsData}
									handlePagination={handlePagination}
									handleResultsPerPageChange={
										handleResultsPerPageChange
									}
									handleSummaryTblClick={
										handleSummaryTblClick
									}
								/>
							)}
							{!_.isNil(selectedCard) && (
								<DashboardList
									selectedCard={selectedCard}
									riseAnalyticsData={riseAnalyticsData}
									handlePagination={handlePagination}
									handleResultsPerPageChange={
										handleResultsPerPageChange
									}
									selectPatient={selectPatient}
									handleSort={handleSort}
									CardPageData={CardPageData}
								/>
							)}
						</Fragment>
					)}
				</div>
			}
		</Fragment>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		affiliations: _.get(
			state,
			['provideraffiliations', 'provideraffiliations', 'data'],
			[]
		),
		gettingProviderAffiliations: _.get(
			state,
			['provideraffiliations', 'gettingProviderAffiliations'],
			false
		),

		partnerOrganization: _.get(
			state,
			[
				'partnerOrganization',
				'partnerOrganization',
				'partner_organizations',
			],
			[]
		),
		cardFilter: _.get(state, 'appData.riseDashboard', null) || {},
		riseAnalyticsData: _.get(state, ['riseAnalyticsData', 'data'], {}),
		riseAnalyticsDataLoading: _.get(
			state,
			['riseAnalyticsData', 'loading'],
			false
		),
		exportRiseAnalyticsData: _.get(
			state,
			['riseAnalyticsData', 'exportData'],
			null
		),
		exportRiseAnalyticsDataLoading: _.get(
			state,
			['riseAnalyticsData', 'exportLoading'],
			false
		),
		exportRiseAnalyticsDataError: _.get(
			state,
			['riseAnalyticsData', 'exportError'],
			null
		),
	};
};

const mapDispatchToProps = (dispatch) => ({
	getAffiliations: (data) => dispatch(getaffiliation(data)),
	getPartnerOrganization: () => dispatch(getPartnerOrganization()),
	resetaffiliation: () => dispatch(resetaffiliation()),
	setCardFilter: (data) => dispatch(setFilter('riseDashboard', data)),
	getRiseDashboardData: (data, dimension) =>
		dispatch(loadRiseDashboardData(data, dimension)),
	riseDashboardReport: (data, dimension) =>
		dispatch(riseDashboardExportReport(data, dimension)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RiseManagement);
