//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../assets/close.svg';

const AddEditExternalProviderTitleRow = (props) => {
	const { editingProvider = false, hideProviderModal } = props;

	const determineTitle = () => {
		if (editingProvider) {
			return 'Edit External Provider';
		} else {
			return 'Add External Provider';
		}
	};

	return (
		<div className='externalProviderModalTopRow'>
			<div>{determineTitle()}</div>
			<ReactSVG
				src={xIcon}
				className='singleSelectModalXIcon externalProviderTopRowXIcon'
				onClick={hideProviderModal}
			/>
		</div>
	);
};

export default AddEditExternalProviderTitleRow;
