//React & Redux
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//Lodash
import _ from 'lodash';

//Actions & Services
import { getPatientTests } from '../../../../actions/tests';
import {
	getOutreachs,
	addOutreach,
	updateOutreach,
	updateOutreachRound,
	dispatchResetOutreachs,
} from '../../../../actions/patient/outreach';
import { followUpDeclinedOutreach } from '../../../../actions/encounter';
import { getGeneTestEnums } from '../../../../actions/encounter';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import OutreachGroupList from '../../../Common/Outreach/OutreachGroupList';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious';

//Styles
import './style.css';

export const OutreachLogTab = (props) => {
	const {
		actions: {
			dispatchResetOutreachs,
			getGeneTestEnums,
			getOutreachs,
			getPatientTests,
		},
		enqueueSnackbar,
		gcRoROutreachStopReasonsEnums,
		loadingOutreachList = false,
		loadingOutreachSetting = false,
		noOutreachReasons,
		outreachEnums,
		outreachList = [],
		outreachSetting,
		outreachStatusEnums,
		patientDeclinedReasonsEnums,
		patientUuid = 0,
		tests: { gettingTests, tests },
	} = props;

	const [loading, setLoading] = useState(false);
	const [showUnableStopOutreachModal, setShowUnableStopOutreachModal] =
		useState(false);

	const prevLoadingOutreachList = usePrevious(loadingOutreachList);

	useEffect(() => {
		getOutreachs(patientUuid);
		getGeneTestEnums();
		getPatientTests({ patient_uuid: patientUuid });
		setLoading(true);

		return () => dispatchResetOutreachs();
	}, []);

	useEffect(() => {
		prevLoadingOutreachList && !loadingOutreachList && setLoading(false);
	}, [loadingOutreachList]);

	const addHandleSubmit = (data) => {

		const handleSuccess = () => {

			getOutreachs(patientUuid);
			setLoading(true);
			enqueueSnackbar('Outreach saved successfully.', {
				variant: 'info',
				anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
			});
		}

		const handleError = () => {
			enqueueSnackbar('Error in outreach.', {
				variant: 'error',
				anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
			});
		}

		return addOutreach(data, patientUuid)
			.then(() => {

				const {
					outreach_round_id,
					newReferralOutreachRoundStatus,
					newReferralOutreachRoundSubstatus
				} = data;

				if (outreach_round_id && (newReferralOutreachRoundStatus || newReferralOutreachRoundSubstatus)) {
					updateOutreachRound(patientUuid, outreach_round_id, {
						status: newReferralOutreachRoundStatus,
						substatus: newReferralOutreachRoundSubstatus
					}).then(() => {
						handleSuccess();
					}).catch((err) => {
						handleError();
					})
				} else {
					handleSuccess();
				}

			})
			.catch((err) => {
				handleError();
				throw err;
			});
	};

	const compareFields = (currentData, initalData) => {
		const formdata = {};

		if (
			!_.isEqual(
				_.get(currentData, 'date_time'),
				_.get(initalData, 'contactDateTime')
			)
		) {
			formdata['date_time'] = currentData['date_time'];
		}
		if (
			!_.isEqual(
				_.get(currentData, 'contact_method'),
				_.get(initalData, ['contactMethod', 'name'])
			)
		) {
			formdata['contact_method'] = currentData['contact_method'];
		}
		if (
			!_.isEqual(
				_.get(currentData, 'type'),
				_.get(initalData, ['type', 'name'])
			)
		) {
			formdata['type'] = currentData['type'];
		}
		if (
			!_.isEqual(
				_.get(currentData, 'outcome'),
				_.get(initalData, ['outcome', 'name'])
			)
		) {
			formdata['outcome'] = currentData['outcome'];
		}
		if (_.get(currentData, 'outcome') == 'other') {
			formdata['notes'] = currentData['notes'];
		}
		if (_.get(currentData, 'type') == 'ror_outreach') {
			formdata['encounter_uuid'] = currentData['encounter_uuid'];
			formdata['outreach_sequence_id'] =
				currentData['outreach_sequence_id'];
		}
		if (_.get(currentData, 'type') == 'delayed_lab_outreach') {
			formdata['encounter_uuid'] = currentData['encounter_uuid'];
			formdata['gene_test_order_uuid'] =
				currentData['gene_test_order_uuid'];
		}

		return formdata;
	};

	const editHandleSubmit = (formdata, communication_uuid, initalProps) => {
		const updatedData = compareFields(formdata, initalProps);
		if (!_.isEmpty(updatedData)) {
			return updateOutreach(updatedData, patientUuid, communication_uuid)
				.then(() => {
					getOutreachs(patientUuid);
					setLoading(true);
					enqueueSnackbar('Outreach update successfully.', {
						variant: 'info',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
				})
				.catch((err) => {
					enqueueSnackbar('Error in outreach.', {
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
					throw err;
				});
		} else {
			return Promise.resolve().then(() => { });
		}
	};

	const handleFUDeclinedSubmit = (encounter_uuid, data) => {
		return followUpDeclinedOutreach(encounter_uuid, data)
			.then((res) => {
				if (
					_.get(res, ['data', 'data', 'ror_advanced'], true) == false
				) {
					setShowUnableStopOutreachModal(true);
				} else {
					getOutreachs(patientUuid);
					setLoading(true);
					enqueueSnackbar(
						'Outreach mark as followup declined successfully.',
						{
							variant: 'info',
							anchorOrigin: {
								horizontal: 'right',
								vertical: 'bottom',
							},
						}
					);
				}
				return res;
			})
			.catch((err) => {
				enqueueSnackbar(
					'Error while outreach mark as followup declined.',
					{
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
				throw err;
			});
	};

	const filterTestOrderRequest = () => {
		if (_.isArray(tests)) {
			return tests.map((obj) => ({
				encounterUUID: obj.encounterUUID,
				outreachSeqNo: obj.outreachSeqNo,
				testName: obj.testName,
			}));
		}
		return [];
	};

	const closeUnableStopOutreachModal = () => {
		setShowUnableStopOutreachModal(false);
	};

	const showLoading = () =>
		loading ||
		_.isNil(outreachEnums || undefined) ||
		loadingOutreachList ||
		loadingOutreachSetting ||
		gettingTests;

	return (
		<Loading loading={showLoading()}>
			<div className='outrchlog-tab'>
				<OutreachGroupList
					{...{
						addHandleSubmit,
						closeUnableStopOutreachModal,
						editHandleSubmit,
						gcRoROutreachStopReasonsEnums,
						geneTestOrderRequest: filterTestOrderRequest(),
						getLatestOutreachStatus: () =>
							getOutreachs(patientUuid, false),
						getList: () => getOutreachs(patientUuid, false),
						handleFUDeclinedSubmit,
						noOutreachReasons,
						outreachEnums,
						outreachList,
						outreachSetting,
						outreachStatusEnums,
						outreachType: 'outreachTab',
						patientDeclinedReasonsEnums,
						showUnableStopOutreachModal,
						tests,
					}}
				/>
			</div>
		</Loading>
	);
};

const mapStateToProps = (state, ownProps) => ({
	gcRoROutreachStopReasonsEnums: _.get(
		state,
		['gentestenums', 'gentestenums', 'ror_outreach_stop_reasons'],
		[]
	),
	loadingOutreachList: state.outreach.loading_outreachList,
	loadingOutreachSetting: state.outreach.loading_outrechSetting,
	outreachEnums: state.outreach.outreachEnums,
	outreachList: state.outreach.outreachList,
	outreachSetting: state.outreach.outreachSetting,
	outreachStatusEnums: _.get(
		state,
		['gentestenums', 'gentestenums', 'outreach_status'],
		[]
	),
	patientDeclinedReasonsEnums: _.get(
		state,
		['gentestenums', 'gentestenums', 'declined_reasons'],
		[]
	),
	patientUuid: ownProps.match.params.patientid,
	tests: state.tests,
});

const mapDispatchToProps = (dispatch) => ({
	actions: {
		...bindActionCreators(
			{
				dispatchResetOutreachs,
				getGeneTestEnums,
				getPatientTests,
			},
			dispatch
		),
		getOutreachs: (patientUuid, flag = true) =>
			dispatch(getOutreachs(patientUuid, flag)),
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(OutreachLogTab);
