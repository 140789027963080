//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	EncounterDateFieldsDisplay,
	GetSelectvalue,
	getProviderNameForLab,
	getProviderIcon,
} from '../Common/utilsfunctions';

const TestOrderSegment = (props) => {
	return (
		<div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Order Request Status
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>Lab</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>Test Name</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Ordering Physician
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Test Authorization Date
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'order_request_status'],
								_.get(
									props,
									['labsschema', 'order_request_status'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'lab'],
								_.get(props, ['labsschema', 'lab'], [])
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'type_of_test'],
								_.get(props, ['labsschema', 'type_of_test'], [])
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{getProviderIcon(props, [
								'encounter',
								'ordering_physician',
							]) != null && (
								<img
									src={getProviderIcon(props, [
										'encounter',
										'ordering_physician',
									])}
									alt=''
								/>
							)}
							{getProviderNameForLab(
								props,
								['encounter', 'ordering_physician'],
								_.get(props, ['ordering_physicianenums'], [])
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'encounter',
								'date_test_authorized',
							])}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Order Status
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Test Dispatch Date
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Expected Lab Result Date
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Authorized Via Lab Portal
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Report Receipt Date
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'order_status'],
								_.get(
									props,
									['labsschema', 'order_status_lab'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'encounter',
								'date_test_ordered',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'encounter',
								'expected_lab_results_date',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'encounter',
								'date_authorized_via_lab_portal',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'encounter',
								'date_received_report',
							])}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Results Released To Portal?
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Result Release Date
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'encounter',
								'date_results_released_to_portal',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'encounter',
								'date_results_released_to_patient',
							])}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TestOrderSegment;
