import * as actionTypes from '../actions/actionTypes';

const defaultDiagnosticReports = {
	diagnosticReports: {},
	diagnosticReportsLoading: false,
	diagnosticReportsError: null,
};

const defaultAddDiagnosticFinding = {
	addDiagnosticFinding: {},
	addDiagnosticFindingLoading: false,
	addDiagnosticFindingError: null,
};

const defaultAddDiagnosticFindingClinicalNote = {
	addDiagnosticFindingClinicalNote: {},
	addDiagnosticFindingClinicalNoteLoading: false,
	addDiagnosticFindingClinicalNoteError: null,
};

export const diagnosticReports = (
	state = defaultDiagnosticReports,
	action = {}
) => {
	switch (action.type) {
		case actionTypes.GET_DIAGNOSTIC_REPORTS_LOADING:
			return {
				...state,
				diagnosticReportsLoading: true,
				diagnosticReportsError: null,
			};

		case actionTypes.GET_DIAGNOSTIC_REPORTS_SUCCESS:
			return {
				...state,
				diagnosticReports: action.payload,
				diagnosticReportsLoading: false,
				diagnosticReportsError: null,
			};

		case actionTypes.GET_DIAGNOSTIC_REPORTS_ERROR:
			return {
				...state,
				diagnosticReportsLoading: false,
				diagnosticReportsError: action.payload,
			};

		case actionTypes.RESET_GET_DIAGNOSTIC_REPORTS:
			return {
				...state,
				diagnosticReportsLoading: false,
				diagnosticReports: {},
			};

		default:
			return state;
	}
};

export const addDiagnosticFinding = (
	state = defaultAddDiagnosticFinding,
	action = {}
) => {
	switch (action.type) {
		case actionTypes.ADD_DIAGNOSTIC_FINDING_LOADING:
			return {
				...state,
				addDiagnosticFinding: {},
				addDiagnosticFindingLoading: true,
				addDiagnosticFindingError: null,
			};

		case actionTypes.ADD_DIAGNOSTIC_FINDING_SUCCESS:
			return {
				...state,
				addDiagnosticFinding: action.payload,
				addDiagnosticFindingLoading: false,
				addDiagnosticFindingError: null,
			};

		case actionTypes.ADD_DIAGNOSTIC_FINDING_ERROR:
			return {
				...state,
				addDiagnosticFinding: {},
				addDiagnosticFindingLoading: false,
				addDiagnosticFindingError: action.payload,
			};

		default:
			return state;
	}
};

export const addDiagnosticFindingClinicalNote = (
	state = defaultAddDiagnosticFindingClinicalNote,
	action = {}
) => {
	switch (action.type) {
		case actionTypes.ADD_DIAGNOSTIC_FINDING_CLINICAL_NOTE_LOADING:
			return {
				...state,
				addDiagnosticFindingClinicalNote: {},
				addDiagnosticFindingClinicalNoteLoading: true,
				addDiagnosticFindingClinicalNoteError: null,
			};

		case actionTypes.ADD_DIAGNOSTIC_FINDING_CLINICAL_NOTE_SUCCESS:
			return {
				...state,
				addDiagnosticFindingClinicalNote: action.payload,
				addDiagnosticFindingClinicalNoteLoading: false,
				addDiagnosticFindingClinicalNoteError: null,
			};

		case actionTypes.ADD_DIAGNOSTIC_FINDING_CLINICAL_NOTE_ERROR:
			return {
				...state,
				addDiagnosticFindingClinicalNote: {},
				addDiagnosticFindingClinicalNoteLoading: false,
				addDiagnosticFindingClinicalNoteError: action.payload,
			};

		default:
			return state;
	}
};
