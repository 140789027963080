//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//Utils
import { imageDefault } from '../../Utils/encounterUtils';
import { providers_images_for_appointment } from '../../Utils/appointmentUtils';

//Other Libraries
import moment from 'moment';

//Components
import Icon from '../Common/Icon';

class SummaryAppointmentPreview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	getAppointmentTime() {
		if (
			!_.isNil(
				_.get(this, ['props', 'newAppointment', 'timeslot'], null)
			) &&
			_.get(this, ['props', 'newAppointment', 'timeslot', 'start']) &&
			_.get(this, ['props', 'newAppointment', 'timeslot', 'end'])
		) {
			const timezone = _.get(this, [
				'props',
				'newAppointment',
				'timezone',
			]);
			const start = moment(this.props.newAppointment.timeslot.start);
			const end = moment(this.props.newAppointment.timeslot.end);
			const timezonedate = moment.tz(timezone);
			return (
				start.format('dddd, MMM D, h:mm A') +
				' - ' +
				end.format('h:mm A') +
				timezonedate.format(' z')
			);
		}
		return '';
	}

	getspecialty() {
		const selected = !_.isNil(
			_.get(this, ['props', 'newAppointment', 'vsee_specialty'], '')
		)
			? _.get(this, ['props', 'newAppointment', 'vsee_specialty'], '')
					.replace('_Willing', '')
					.replace('_willing', '')
			: '';
		const speciality = _.get(this, ['props', 'specialties'], []).find(
			(c) => c.key === selected
		);
		return _.get(speciality, 'display_name', '');
	}

	ucFirstAllWords(str, separator = ' ') {
		const pieces = str ? str.split(separator) : [];
		for (let i = 0; i < pieces.length; i++) {
			const j = pieces[i].charAt(0).toUpperCase();
			pieces[i] = j + pieces[i].substr(1).toLowerCase();
		}
		return pieces.join(separator);
	}

	getProviderName() {
		const provider_id = _.get(this, [
			'props',
			'newAppointment',
			'provider_id',
		]);
		const providers = _.get(this, ['props', 'Scheduling', 'providers'], []);
		let providerName = '';
		const separator = ' ';
		if (provider_id) {
			const provider = _.find(providers, function (o) {
				return o.id == provider_id;
			});
			providerName = this.ucFirstAllWords(
				_.get(provider, 'full_name', '')
			);
			if (providerName && providerName.length > 0) {
				const ProviderNameobj = providerName.split(separator);
				const first_name = _.get(ProviderNameobj, 0, '');
				const middle_name =
					providerName.length > 2
						? ' ' + _.get(ProviderNameobj, 1, '')
						: '';
				const last_name =
					providerName.length > 2
						? ' ' + _.get(ProviderNameobj, 2, '')
						: ' ' + _.get(ProviderNameobj, 1, '');
				const result =
					first_name.charAt(0) + '.' + middle_name + last_name;
				return result;
			} else {
				return '--';
			}
		} else {
			return 'All Providers';
		}
	}

	getProviderIcon() {
		const provider_id = _.get(this, [
			'props',
			'newAppointment',
			'provider_id',
		]);
		const providers = _.get(this, ['props', 'Scheduling', 'providers'], []);
		let providerImage = (
			<img style={{ height: '100%' }} src={imageDefault} alt='' />
		);

		if (provider_id) {
			const provider = _.find(providers, function (o) {
				return o.id == provider_id;
			});
			const full_name = _.get(provider, 'full_name', '');

			let providerImage = null;
			const mappedImage = providers_images_for_appointment[full_name];
			if (full_name && mappedImage) {
				providerImage = (
					<img style={{ height: '100%' }} src={mappedImage} alt='' />
				);
			} else if (full_name != '')
				providerImage = (
					<img style={{ height: '100%' }} src={imageDefault} alt='' />
				);
			else providerImage = null;
			return (
				providerImage && (
					<span className='patient-imgbox'>{providerImage}</span>
				)
			);
		} else {
			providerImage = (
				<img style={{ height: '100%' }} src={imageDefault} alt='' />
			);
		}
		return providerImage;
	}

	getAppointmentModalityIcon() {
		const modality = _.get(this, ['props', 'newAppointment', 'modality']);
		if (modality.toLowerCase() == 'video')
			return <Icon icon='video' className='videoic' />;
		return <Icon icon='phone' className='videoic' />;
	}

	render() {
		if (_.get(this, 'props.newAppointment', null)) {
			return (
				<React.Fragment>
					<div className='sideContainerBorder' />
					<div className='scheduleAppointmentSideContainerSection'>
						<div
							className='scheduleAppointmentSideContainerName'
							style={{ marginBottom: '3px' }}
						>
							{_.get(
								this.props.selectedconsultation(),
								'name',
								''
							)}
						</div>
						<div className='scheduleAppointmentSideContainerPatientText'>
							{this.getAppointmentTime()} -{' '}
							<span style={{ fontFamily: 'RionaSans-Bold' }}>
								{' '}
								{_.get(
									this,
									['props', 'newAppointment', 'state'],
									''
								)}
							</span>
						</div>
						<div className='scheduleAppointmentSideContainerPatientText'>
							{this.getspecialty()}
						</div>
						<div
							className='scheduleAppointmentSideContainerPatientText scheduleAppointmentSideContainerRow'
							style={{ marginBottom: '3px' }}
						>
							<div className='scheduleAppointmentSideContainerHalf'>
								<span
									style={{
										height: '20px',
										width: '20px',
										borderRadius: '4px',
										marginRight: '4px',
										overflow: 'hidden',
									}}
								>
									{this.getProviderIcon()}
								</span>
								<div className='provider-name'>
									{this.getProviderName()}
								</div>
							</div>
							<div
								className='scheduleAppointmentSideContainerHalf'
								style={{ alignItems: 'center' }}
							>
								<span style={{ height: '18px', width: '18px' }}>
									{this.getAppointmentModalityIcon()}
								</span>
								<div style={{ textTransform: 'capitalize' }}>
									{_.get(
										this,
										['props', 'newAppointment', 'modality'],
										''
									)}
								</div>
							</div>
						</div>
					</div>
					<div className='sideContainerBorder' />
				</React.Fragment>
			);
		}
	}
}

export default SummaryAppointmentPreview;
