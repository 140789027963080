//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//Components
import CreateTestRequestLabInfo from './CreateTestRequestLabInfo';
import CreateTestRequestEncntrInfo from './CreateTestRequestEncntrInfo';

//Styles
import './create-test-request-modal.css';

const CreateTestRequestModal = (props) => {
	const {
		ordering_physicianenums = [],
		labenums = [],
		nonlegacytestOrderPopupTestEnums = [],
		orderrequeststatuscascadeenums = [],
		sampletypeenums = [],
		patientbillingmethodenums = [],
		mloOrderStatusEnums = [],
		formdata = {},
		testOrderNumber = 1,
		encounter = {},
		patientdetail = {},
		onFormChange,
		shippingAddressFormData = {},
		onShippingAddressFormChange,
		onAddAnotherTestNameFormChange,
		addAnotherTestNameFormData,
		reflexAOEQuestions,
		reflexAOEAnswers,
		setReflexAnswers,
		hideModal,
		hasPatientNoInsurance,
	} = props;

	return (
		<div className='crte-test-rqst-dialog-body'>
			<div className='crte-test-rqst-fields-container'>
				<CreateTestRequestLabInfo
					ordering_physicianenums={ordering_physicianenums}
					labenums={labenums}
					nonlegacytestOrderPopupTestEnums={
						nonlegacytestOrderPopupTestEnums
					}
					formdata={formdata}
					onFormChange={onFormChange}
					orderrequeststatuscascadeenums={
						orderrequeststatuscascadeenums
					}
					sampletypeenums={sampletypeenums}
					patientbillingmethodenums={patientbillingmethodenums}
					mloOrderStatusEnums={mloOrderStatusEnums}
					testOrderNumber={testOrderNumber}
					patientdetail={patientdetail}
					shippingAddressFormData={shippingAddressFormData}
					onShippingAddressFormChange={onShippingAddressFormChange}
					addAnotherTestNameFormData={addAnotherTestNameFormData}
					onAddAnotherTestNameFormChange={
						onAddAnotherTestNameFormChange
					}
					reflexAOEQuestions={reflexAOEQuestions}
					reflexAOEAnswers={reflexAOEAnswers}
					setReflexAnswers={setReflexAnswers}
					hideModal={hideModal}
					hasPatientNoInsurance={hasPatientNoInsurance}
				/>
				<CreateTestRequestEncntrInfo
					encounter={encounter}
					patientdetail={patientdetail}
				/>
			</div>
		</div>
	);
};

export default CreateTestRequestModal;
