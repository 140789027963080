//React & Redux
import React, { useState } from 'react';

//UI Libraries
import { Dialog, Button } from 'gm-element-react';

//Images
import warningicon from '../../../assets/warning-alert.svg';

//Styles
import './UnableStopOutreachDialog.css';

const UnableToStopOutreachDialog = (props) => {
	const { closeUnableStopOutreachModal, getLatestOutreachStatus } = props;

	const [isDialogVisible, setIsDialogVisible] = useState(true);

	const handleSubmit = () => {
		setIsDialogVisible(false);
		getLatestOutreachStatus();
		closeUnableStopOutreachModal();
	};

	return (
		<Dialog
			customClass='unable-stop-outreach-dialog'
			title={
				<span>
					<img src={warningicon} alt='' className='warning-icon' />
					Unable To Stop Outreach
				</span>
			}
			size='tiny'
			modal={true}
			closeOnPressEscape={false}
			closeOnClickModal={false}
			showClose={false}
			visible={isDialogVisible}
		>
			<Dialog.Body>
				<span className='unable-stop-outreach-message'>
					It looks like the RoR Outreach has already been completed
					for this round and so we are unable to Stop Outreach.
				</span>
			</Dialog.Body>
			<Dialog.Footer>
				<Button className='btn-continue' onClick={handleSubmit}>
					Continue
				</Button>
			</Dialog.Footer>
		</Dialog>
	);
};

export default UnableToStopOutreachDialog;
