import React, { useEffect, useReducer } from 'react';
import z from 'zod';

import Button from '../../../../Common/Button';
import { Section } from '@gm/common/ui';
import ProviderLinkDialog from '../../../../ExternalProviders/ProviderLinkDialog';
import ProviderUnlinkDialog from '../../../../ExternalProviders/ProviderUnlinkDialog';
import ProviderLinked from '../../../../ExternalProviders/ProviderLinked';

import { useSection } from './useSection';

import './ExternalCareTeam.scss';

export const initialExternalCareTeam = { externalProviders: [] };

export const schemaExternalCareTeam = z
	.object({
		externalProviders: z.array(
			z.object({
				providerUUID: z.string().uuid(),
				providerRole: z.array(z.string()),
				receiveAllComms: z.boolean(),
			})
		),
	})
	.required();

function reducer(state, action) {
	switch (action.type) {
		case 'CLOSE_PROVIDER_EDIT_DIALOG':
			return {
				...state,
				showProviderDialog: false,
				editingProvider: false,
				selectedProvider: null,
			};
		case 'CLOSE_PROVIDER_EDIT_LINK_DIALOG':
			return {
				...state,
				showProviderDialog: false,
				editingLinkInfo: false,
				selectedProvider: null,
			};
		case 'CLOSE_PROVIDER_DIALOG':
			return {
				...state,
				showProviderDialog: false,
				editingLinkInfo: false,
				editingProvider: false,
				selectedProvider: null,
			};
		case 'CLOSE_PROVIDER_UNLINK_DIALOG':
			return {
				...state,
				selectedProvider: null,
				showProviderUnlinkDialog: false,
			};
		case 'SHOW_PROVIDER_EDIT_DIALOG':
			return {
				...state,
				showProviderDialog: true,
				editingProvider: true,
				selectedProvider: action.provider,
			};
		case 'SHOW_PROVIDER_EDIT_LINK_DIALOG':
			return {
				...state,
				showProviderDialog: true,
				editingLinkInfo: true,
				selectedProvider: action.provider,
			};
		case 'SHOW_PROVIDER_DIALOG':
			return {
				...state,
				showProviderDialog: true,
			};
		case 'SHOW_PROVIDER_UNLINK_DIALOG':
			return {
				...state,
				selectedProvider: action.provider,
				showProviderUnlinkDialog: true,
			};
		default:
			return state;
	}
}
export const ExternalCareTeam = function ({
	enqueueSnackbar,
	isEdit,
	linkedProviders,
	defaultProviders,
	sectionDataContainerName,
	activeRole
}) {
	const { setFieldValue, values } = useSection(sectionDataContainerName);
	const [
		{
			editingLinkInfo,
			editingProvider,
			showProviderDialog,
			showProviderUnlinkDialog,
			selectedProvider,
		},
		dispatch,
	] = useReducer(reducer, {
		editingLinkInfo: false,
		editingProvider: false,
		showProviderDialog: false,
		showProviderUnlinkDialog: false,
		selectedProvider: null,
	});

	useEffect(() => {
		if ((linkedProviders || defaultProviders) && isEdit) {
			const _linkedProviders = linkedProviders.map(lp => ({
				...lp,
				isDefaultProvider: defaultProviders && defaultProviders.some(dp => dp.uuid === lp.providerUUID) || false,
			}));

			setFieldValue('externalProviders', _linkedProviders);
		}
	}, [linkedProviders, defaultProviders]);

	return (
		<Section className='external-care-team' title='External Care Team'>
			<p>
				Please add the patient's personal provider information to ensure
				documentation delivery.
			</p>
			<div className='linked-providers'>
				{values.externalProviders.map((provider) => (
					<ProviderLinked
						key={provider.providerUUID}
						provider={provider}
						editLinkInfo={() => {
							dispatch({
								type: 'SHOW_PROVIDER_EDIT_LINK_DIALOG',
								provider,
							});
						}}
						editProviderInfo={() => {
							dispatch({
								type: 'SHOW_PROVIDER_EDIT_DIALOG',
								provider,
							});
						}}
						unlinkProviderInfo={() => {
							dispatch({
								type: 'SHOW_PROVIDER_UNLINK_DIALOG',
								provider,
							});
						}}
						activeRole={activeRole}
					/>
				))}
			</div>

			<Button
				inverted
				onClick={() => dispatch({ type: 'SHOW_PROVIDER_DIALOG' })}
			>
				Add External Provider
			</Button>
			{showProviderDialog && (
				<ProviderLinkDialog
					visible={showProviderDialog}
					cancel={() => dispatch({ type: 'CLOSE_PROVIDER_DIALOG' })}
					addExternalProvider={(provider) => {
						const newProviders = [...values.externalProviders];
						if (editingProvider || editingLinkInfo) {
							const idx = newProviders.findIndex(
								(item) =>
									item.providerUUID === provider.providerUUID
							);
							if (idx >= 0) {
								newProviders[idx] = {
									...newProviders[idx],
									...provider,
								};
							}
						} else {
							newProviders.push(provider);
						}
						setFieldValue('externalProviders', newProviders);
						dispatch({ type: 'CLOSE_PROVIDER_DIALOG' });
					}}
					editingLinkInfo={editingLinkInfo}
					editingProvider={editingProvider}
					selectedProvider={selectedProvider}
					enqueueSnackbar={enqueueSnackbar}
				/>
			)}
			<ProviderUnlinkDialog
				content={
					'Are you sure you want to unlink the external provider from this patient record?'
				}
				visible={showProviderUnlinkDialog}
				cancel={() =>
					dispatch({ type: 'CLOSE_PROVIDER_UNLINK_DIALOG' })
				}
				confirm={() => {
					setFieldValue(
						'externalProviders',
						values.externalProviders.filter(
							(item) =>
								item.providerUUID !==
								selectedProvider?.providerUUID
						)
					);
					dispatch({ type: 'CLOSE_PROVIDER_UNLINK_DIALOG' });
				}}
			/>
		</Section>
	);
};
