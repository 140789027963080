//React & Redux
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

//Images
import ReactSVG from 'react-svg';
import plusIcon from './../../assets/add.svg';

//Lodash
import { get, isEmpty } from 'lodash';
import moment from 'moment';

//Utils

//Actions & Services
import {
	getTests,
	getTestTypes,
	getTags,
	getOtherEnums,
	createTest,
} from './../../actions/tests.js';
import { getencounterlabseschema } from '../../actions/encounter';
import { setFilter } from '../../actions/appData';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import AddTest from './AddTest.js';
import MultiSelectCheckbox from './../Filter/MultiSelectCheckbox';
import ResetFilters from './../Filter/ResetFilters';
import SingleSelect from './../Filter/SingleSelect';
import MoreFilters from './../Filter/MoreFilters';
import { Table, Thead, Tbody, Th, Tr, Td } from '../Common/Table';

//Styles
import './styles.css';

const offeredEnum = [
	{
		display_name: 'Currently Offered',
		key: 'yes',
	},
	{
		display_name: 'Not Currently Offered',
		key: 'no',
	},
];

class TestManagement extends React.Component {
	constructor(props) {
		super(props);

		const testManagementFilter =
			get(this, 'props.testManagementFilter', null) || {};
		this.defaultFilter = {
			tagsDropDownVal: [],
			testTypesDropDownVal: [],
			labsDropDownVal: [],
			offeredDropDownVal: [],
			activePage: 0,
			order: 'asc',
			orderBy: 'currently_offered',
		};
		this.state = {
			renderAddTest: false,
			...this.defaultFilter,
			...testManagementFilter,
		};
		//bindings
		this.formulateTagsEnum = this.formulateTagsEnum.bind(this);
		this.formulateTypesEnum = this.formulateTypesEnum.bind(this);
		this.showTestDetail = this.showTestDetail.bind(this);
		this.renderAddTest = this.renderAddTest.bind(this);
		this.closeAddTest = this.closeAddTest.bind(this);
		this.getFilter = this.getFilter.bind(this);
		this.handleResetFilters = this.handleResetFilters.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleSort = this.handleSort.bind(this);
		this.handleResultsPerPageChange =
			this.handleResultsPerPageChange.bind(this);
	}
	//functions

	componentDidMount() {
		this.props.getTags();
		this.props.getTestTypes();
		this.props.getLabsEnum();
		this.props.getOtherEnums();
		this.props.getTests({
			order_type: this.state.order,
			order_by: this.state.orderBy,
			tag: this.state.tagsDropDownVal,
			test_type: this.state.testTypesDropDownVal,
			lab: this.state.labsDropDownVal,
			currently_offered: this.state.offeredDropDownVal,
			offset: this.state.activePage,
			limit: this.props.limit,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.creatingTest && !this.props.creatingTest) {
			this.props.getTests();
			this.closeAddTest();
			this.handleResetFilters();
			this.props.enqueueSnackbar('Test created successfully', {
				variant: 'info',
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'bottom',
				},
			});
		}

		if (
			get(prevProps, 'gettingTestTags', false) &&
			!get(this, 'props.gettingTestTags', false)
		) {
			this.formulateTagsEnum();
		}

		if (
			get(prevProps, 'gettingTestTypes', false) &&
			!get(this, 'props.gettingTestTypes', false)
		) {
			this.formulateTypesEnum();
		}

		if (
			get(prevProps, 'tests.gettingTests', false) &&
			!get(this, 'props.tests.gettingTests', false)
		) {
			if (!get(this, 'props.tests.testsError', false))
				this.props.setTestManagementFilter(this.getFilter());
		}
	}

	getFilter() {
		return {
			tagsDropDownVal: get(this, 'state.tagsDropDownVal', null),
			testTypesDropDownVal: get(this, 'state.testTypesDropDownVal', null),
			labsDropDownVal: get(this, 'state.labsDropDownVal', null),
			offeredDropDownVal: get(this, 'state.offeredDropDownVal', null),
			activePage: get(this, 'state.activePage', null),
			order: get(this, 'state.order', null),
			orderBy: get(this, 'state.orderBy', null),
		};
	}

	showTestDetail(test, edit) {
		this.props.history.push({
			pathname: `/app/test/${test.uuid}`,
			search: edit ? '?edit=true' : '',
			state: {
				breadcrumbs: [
					{
						location: 'Test management',
						tags: this.state.tagsDropDownVal,
						testTypes: this.state.testTypesDropDownVal,
						labs: this.state.labsDropDownVal,
						offered: this.state.offeredDropDownVal,
						order: this.state.order,
						orderBy: this.state.orderBy,
						activePage: this.state.activePage,
					},
					{ location: 'Test Detail' },
				],
			},
		});
	}

	handlePageChange(evt, pageNumber) {
		this.setState({ activePage: pageNumber }, () => {
			this.props.getTests({
				tag: this.state.tagsDropDownVal,
				test_type: this.state.testTypesDropDownVal,
				lab: this.state.labsDropDownVal,
				currently_offered: this.state.offeredDropDownVal,
				offset: pageNumber,
				order_type: this.state.order,
				order_by: this.state.orderBy,
				limit: this.props.limit,
			});
		});
	}

	renderAddTest(e) {
		this.setState({ renderAddTest: true });
	}

	closeAddTest(e) {
		this.setState({ renderAddTest: false });
	}

	formulateTagsEnum() {
		const tagsVal = get(this, 'props.testTags', []) || [];
		if (Array.isArray(tagsVal)) {
			const formulatedTagsEnum = tagsVal.map((el) => {
				return {
					display_name: get(el, 'tag', ''),
					key: get(el, 'uuid', null),
				};
			});
			this.setState({ tagsEnum: formulatedTagsEnum });
		}
	}

	formulateTypesEnum() {
		const testTypesVal = get(this, 'props.testTypes', []) || [];
		if (Array.isArray(testTypesVal)) {
			const formulatedTypesEnum = testTypesVal.map((el) => {
				return {
					display_name: get(el, 'display_name', ''),
					key: get(el, 'id', null),
				};
			});
			this.setState({ typesEnum: formulatedTypesEnum });
		}
	}

	handleResetFilters() {
		this.setState(this.defaultFilter, () => {
			this.props.getTests({
				tag: this.state.tagsDropDownVal,
				test_type: this.state.testTypesDropDownVal,
				lab: this.state.labsDropDownVal,
				currently_offered: this.state.offeredDropDownVal,
				offset: this.state.activePage,
				order_type: this.state.order,
				order_by: this.state.orderBy,
				limit: this.props.limit,
			});
		});
	}

	handleTagsDropDown = (e) => {
		this.setState(
			{
				tagsDropDownVal: e,
				activePage: 0,
				orderBy: 'currently_offered',
				order: 'asc',
			},
			() => {
				this.props.getTests({
					tag: this.state.tagsDropDownVal,
					test_type: this.state.testTypesDropDownVal,
					lab: this.state.labsDropDownVal,
					currently_offered: this.state.offeredDropDownVal,
					offset: this.state.activePage,
					order_type: 'desc',
					order_by: 'currently_offered',
					limit: this.props.limit,
				});
			}
		);
	};

	handleTestTypesDropDown = (e) => {
		this.setState(
			{
				testTypesDropDownVal: e,
				activePage: 0,
				orderBy: 'currently_offered',
				order: 'asc',
			},
			() => {
				this.props.getTests({
					tag: this.state.tagsDropDownVal,
					test_type: this.state.testTypesDropDownVal,
					lab: this.state.labsDropDownVal,
					currently_offered: this.state.offeredDropDownVal,
					offset: this.state.activePage,
					order_type: 'desc',
					order_by: 'currently_offered',
					limit: this.props.limit,
				});
			}
		);
	};

	handleLabsDropDown = (e) => {
		this.setState(
			{
				labsDropDownVal: e,
				activePage: 0,
				orderBy: 'currently_offered',
				order: 'desc',
			},
			() => {
				this.props.getTests({
					tag: this.state.tagsDropDownVal,
					test_type: this.state.testTypesDropDownVal,
					lab: this.state.labsDropDownVal,
					currently_offered: this.state.offeredDropDownVal,
					offset: this.state.activePage,
					order_type: 'desc',
					order_by: 'currently_offered',
					limit: this.props.limit,
				});
			}
		);
	};

	handleOfferedDropDown = (e) => {
		this.setState(
			{
				offeredDropDownVal: e,
				activePage: 0,
				orderBy: 'currently_offered',
				order: 'desc',
			},
			() => {
				this.props.getTests({
					tag: this.state.tagsDropDownVal,
					test_type: this.state.testTypesDropDownVal,
					lab: this.state.labsDropDownVal,
					currently_offered: this.state.offeredDropDownVal,
					offset: this.state.activePage,
					order_type: 'desc',
					order_by: 'currently_offered',
					limit: this.props.limit,
				});
			}
		);
	};

	handleSort(order_by, order_type) {
		this.setState(
			{ order: order_type, orderBy: order_by, activePage: 0 },
			() => {
				this.props.getTests({
					tag: this.state.tagsDropDownVal,
					test_type: this.state.testTypesDropDownVal,
					lab: this.state.labsDropDownVal,
					currently_offered: this.state.offeredDropDownVal,
					offset: this.state.activePage,
					order_type: this.state.order,
					order_by: this.state.orderBy,
					limit: this.props.limit,
				});
			}
		);
	}

	handleResultsPerPageChange(limit) {
		this.setState({ activePage: 0 }, () => {
			this.props.getTests({
				tag: this.state.tagsDropDownVal,
				test_type: this.state.testTypesDropDownVal,
				lab: this.state.labsDropDownVal,
				currently_offered: this.state.offeredDropDownVal,
				offset: this.state.activePage,
				order_type: this.state.order,
				order_by: this.state.orderBy,
				limit: limit,
			});
		});
	}

	render() {
		const gettingTests = this.props.tests.gettingTests;

		const tagsVal = get(this, 'props.testTags', []) || [];
		const tagsEnum = get(this, 'state.tagsEnum', []) || [];

		const testTypesVal = get(this, 'props.testTypes', []) || [];
		const typesEnum = get(this, 'state.typesEnum', []) || [];

		const labsVal = get(this, 'props.labs.data.lab', []) || [];
		const offeredVal =
			get(this, 'props.otherEnums.currently_offered', []) || [];
		const data = get(this, ['props', 'tests', 'tests', 'data'], []);
		const pagination = {
			end: get(this, ['props', 'tests', 'tests', 'end'], 0),
			has_next: get(this, ['props', 'tests', 'tests', 'has_next'], false),
			has_prev: get(this, ['props', 'tests', 'tests', 'has_prev'], false),
			limit: get(this, ['props', 'tests', 'tests', 'limit'], 0),
			offset: get(this, ['props', 'tests', 'tests', 'offset'], 0),
			start: get(this, ['props', 'tests', 'tests', 'start'], 0),
			total: get(this, ['props', 'tests', 'tests', 'total'], 0),
		};
		const sortBy = {
			key: get(this, ['state', 'orderBy'], ''),
			value: get(this, ['state', 'order'], ''),
		};

		return (
			<div className='testManagementContainer'>
				{this.state.renderAddTest && (
					<AddTest
						closeAddTest={this.closeAddTest}
						getLabsEnum={this.props.getLabsEnum}
						getTestTypes={this.props.getTestTypes}
						getTags={this.props.getTags}
						getOtherEnums={this.props.getOtherEnums}
						gettingLabs={this.props.gettingLabs}
						gettingTestTypes={this.props.gettingTestTypes}
						gettingTestTags={this.props.gettingTestTags}
						gettingOtherEnums={this.props.gettingOtherEnums}
						labs={get(this, 'props.labs.data.lab', [])}
						testTypes={this.props.testTypes}
						testTags={this.props.testTags}
						otherEnums={this.props.otherEnums}
						creatingTest={this.props.creatingTest}
						createTest={this.props.createTest}
					/>
				)}

				<div className='testManagementTopRow'>
					<div className='addTestButton' onClick={this.renderAddTest}>
						<ReactSVG
							src={plusIcon}
							style={{
								position: 'relative',
								top: '1px',
								marginRight: '8px',
							}}
						/>
						Add New Test Option
					</div>

					<ResetFilters
						handleResetFilters={this.handleResetFilters}
					/>

					<MultiSelectCheckbox
						data={tagsEnum}
						selected={get(this, 'state.tagsDropDownVal', []) || []}
						title={'Tags'}
						submit={(values) => this.handleTagsDropDown(values)}
						majorPillTitle={'Tags'}
						defaultValues={[]}
						searchBar={false}
						previewTab={false}
					/>

					<MultiSelectCheckbox
						data={typesEnum}
						selected={
							get(this, 'state.testTypesDropDownVal', []) || []
						}
						title={'Test Types'}
						submit={(values) =>
							this.handleTestTypesDropDown(values)
						}
						majorPillTitle={'Test Types'}
						defaultValues={[]}
						searchBar={false}
						previewTab={false}
					/>

					<MultiSelectCheckbox
						data={labsVal}
						selected={get(this, 'state.labsDropDownVal', []) || []}
						title={'Labs'}
						submit={(values) => this.handleLabsDropDown(values)}
						majorPillTitle={'Labs'}
						defaultValues={[]}
						searchBar={true}
						previewTab={true}
					/>

					<MultiSelectCheckbox
						data={offeredEnum}
						selected={
							get(this, 'state.offeredDropDownVal', []) || []
						}
						title={'Offering'}
						submit={(values) => this.handleOfferedDropDown(values)}
						majorPillTitle={'Offering'}
						defaultValues={[]}
						searchBar={false}
						previewTab={false}
					/>
				</div>

				{gettingTests === true || gettingTests === undefined ? (
					<Loading loading={true} className='loaderTestManagement' />
				) : (
					<Table
						loading={false}
						pagination={pagination}
						handlePageChange={this.handlePageChange}
						hasData={!isEmpty(data)}
						handleSort={this.handleSort}
						resultsPerPage={[25, 50, 100]}
						handleResultsPerPageChange={
							this.handleResultsPerPageChange
						}
						handleResetFilters={this.handleResetFilters}
						sortBy={sortBy}
						noResultsFound={
							<Fragment>
								<div>No Results Found</div>
								<p>
									Try adjusting your search or filter options
									to find
									<br />
									what you’re looking for.
								</p>
								<span
									onClick={(e) => this.handleResetFilters()}
								>
									Reset Filters
								</span>
							</Fragment>
						}
						className='test-management-table'
					>
						<Thead>
							<Tr>
								<Th
									sortable={{
										display_name: {
											display_name: 'Test name',
											sortBy: {
												asc: 'A to Z',
												desc: 'Z to A',
											},
										},
									}}
								>
									Test Name
								</Th>
								<Th>Lab</Th>
								<Th>Test Type</Th>
								<Th>Tags</Th>
								<Th
									sortable={{
										currently_offered: {
											display_name: 'Current',
											sortBy: {
												asc: 'A to Z',
												desc: 'Z to A',
											},
										},
									}}
								>
									Current
								</Th>
								<Th
									sortable={{
										updated_at: {
											display_name: 'Last Updated',
											sortBy: {
												asc: 'A to Z',
												desc: 'Z to A',
											},
										},
									}}
								>
									Last updated
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data.map((item, i) => {
								const { uuid, lab_uuid, updated_at } = item;
								return (
									<Tr
										key={uuid || lab_uuid || updated_at}
										onClick={() =>
											this.showTestDetail(item, true)
										}
									>
										<Td
											minWidth='270px'
											maxWidth='300px'
											className='cellText'
										>
											{get(item, 'display_name', '') +
												(get(item, 'version', '')
													? ' - v' +
													  get(item, 'version', '--')
													: '')}
										</Td>
										<Td
											minWidth='240px'
											maxWidth='290px'
											className='cellText'
										>
											{get(item, 'labs[0]', '')
												? get(
														item,
														'labs[0].display_name',
														'--'
												  )
												: '--'}
										</Td>
										<Td
											minWidth='230px'
											maxWidth='240px'
											className='cellText'
										>
											{get(item, 'test_type[0]', '')
												? get(
														item,
														'test_type[0].display_name',
														'--'
												  )
												: '--'}
										</Td>
										<Td
											minWidth='214px'
											maxWidth='225px'
											className='cellText test-tag'
										>
											<div className='testTagContainer'>
												{get(item, 'tags', []).map(
													(tag) => {
														return (
															<div
																className='testTag'
																key={tag.uuid}
															>
																{get(
																	tag,
																	'tag',
																	'--'
																) || '--'}
															</div>
														);
													}
												)}
											</div>
										</Td>
										<Td
											minWidth='130px'
											maxWidth='180px'
											className='cellText'
										>
											{get(item, 'currently_offered', '')
												? get(
														item,
														'currently_offered[0]',
														''
												  ).toUpperCase() +
												  get(
														item,
														'currently_offered',
														''
												  ).slice(1)
												: '--'}
										</Td>
										<Td
											minWidth='120px'
											maxWidth='129px'
											className='cellText'
										>
											{get(item, 'updated_at', '')
												? moment(
														get(
															item,
															'updated_at',
															''
														)
												  ).format('MMM DD, YYYY')
												: '--'}
										</Td>
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		tests: state.tests,
		labs: state.labsschema.schema,
		gettingLabs: state.labsschema.gettingLabsEnum,
		gettingTestTypes: state.geneticTestEnums.gettingTestTypes,
		gettingTestTags: state.geneticTestEnums.gettingTestTags,
		testTypes: state.geneticTestEnums.testTypes,
		testTags: state.geneticTestEnums.testTags,
		otherEnums: state.geneticTestEnums.otherEnums,
		gettingOtherEnums: state.geneticTestEnums.gettingOtherEnums,
		creatingTest: state.testUpdateCreate.creatingTest,
		testManagementFilter: get(state, 'appData.testManagement', null) || {},
		limit: state.uiConfig.table.resultsPerPage,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getTests: (data) => dispatch(getTests(data)),
	getLabsEnum: () => dispatch(getencounterlabseschema()),
	getTestTypes: () => dispatch(getTestTypes()),
	getTags: () => dispatch(getTags()),
	getOtherEnums: () => dispatch(getOtherEnums()),
	createTest: (data) => dispatch(createTest(data)),
	setTestManagementFilter: (data) =>
		dispatch(setFilter('testManagement', data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestManagement);
