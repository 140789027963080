import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';

import { isFeatureEnabled } from '../../Utils/featureFlags';

export const AUTH0_CONFIG = {
	audience: process.env.REACT_APP_AUTH0_AUDIENCE,
	organization: process.env.REACT_APP_AUTH0_INTERNAL_ORG_ID,
	domain: process.env.REACT_APP_AUTH0_DOMAIN,
	clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
};

export const AUTH0_LOGIN_ENABLED = isFeatureEnabled('AUTH0_LOGIN', {
	allowCookie: true,
});
export const AUTH0_PASSWORD_LOGIN_ENABLED = isFeatureEnabled(
	'AUTH0_PASSWORD_LOGIN',
	{ allowCookie: true }
);

export const Auth0Wrapper = ({ children }) =>
	AUTH0_LOGIN_ENABLED ? (
		<Auth0Provider
			domain={AUTH0_CONFIG.domain}
			clientId={AUTH0_CONFIG.clientId}
			authorizationParams={{
				audience: AUTH0_CONFIG.audience,
			}}
			useRefreshTokens={true}
			cacheLocation='localstorage'
		>
			{children}
		</Auth0Provider>
	) : (
		children
	);
