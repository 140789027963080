import * as actionTypes from '../actionTypes';
import ReferralProgramsService from '../../service/ReferralPrograms';

//dispatchers
export const startReferralProgramsDispatch = () => ({
	type: actionTypes.LOADING_REFERRAL_PROGRAMS,
});

export const successReferralProgramsDispatch = (data) => ({
	type: actionTypes.LOADED_REFERRAL_PROGRAMS,
	payload: data,
});

export const faliureReferralProgramsDispatch = (error) => ({
	type: actionTypes.ERROR_REFERRAL_PROGRAMS,
	payload: error,
});

export const startReferralProgramDetailDispatch = () => ({
	type: actionTypes.LOADING_REFERRAL_PROGRAM_DETAIL,
});

export const successReferralProgramDetailDispatch = (data) => ({
	type: actionTypes.LOADED_REFERRAL_PROGRAM_DETAIL,
	payload: data,
});

export const failureReferralProgramDetailDispatch = (error) => ({
	type: actionTypes.ERROR_REFERRAL_PROGRAM_DETAIL,
	payload: error,
});

export const startDefaultSchedulingOptionsDispatch = () => ({
	type: actionTypes.LOADING_DEFAULT_SCHEDULING_OPTIONS,
});

export const successDefaultSchedulingOptionsDispatch = (data) => ({
	type: actionTypes.LOADED_DEFAULT_SCHEDULING_OPTIONS,
	payload: data,
});

export const failureDefaultSchedulingOptionsDispatch = (error) => ({
	type: actionTypes.ERROR_DEFAULT_SCHEDULING_OPTIONS,
	payload: error,
});

export const resetDefaultSchedulingOptionsDispatch = () => ({
	type: actionTypes.RESET_DEFAULT_SCHEDULING_OPTIONS,
});

export const startCreateReferralProgramDefaultProviderDispatch = () => ({
	type: actionTypes.LOADING_CREATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER,
});

export const successCreateReferralProgramDefaultProviderDispatch = (data) => ({
	type: actionTypes.LOADED_CREATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER,
	payload: data,
});

export const failureCreateReferralProgramDefaultProviderDispatch = (error) => ({
	type: actionTypes.ERROR_CREATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER,
	payload: error,
});

export const startUpdateReferralProgramDefaultProviderDispatch = () => ({
	type: actionTypes.LOADING_UPDATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER,
});

export const successUpdateReferralProgramDefaultProviderDispatch = (data) => ({
	type: actionTypes.LOADED_UPDATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER,
	payload: data,
});

export const failureUpdateReferralProgramDefaultProviderDispatch = (error) => ({
	type: actionTypes.ERROR_UPDATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER,
	payload: error,
});

export const startUpdateReferralProgramDefaultDocumentNotificationRecipientsDispatch =
	() => ({
		type: actionTypes.LOADING_UPDATE_REFERRAL_PROGRAM_DEFAULT_DOCUMENT_RECIPIENT,
	});

export const successUpdateReferralProgramDefaultDocumentNotificationRecipientsDispatch =
	(data) => ({
		type: actionTypes.LOADED_UPDATE_REFERRAL_PROGRAM_DEFAULT_DOCUMENT_RECIPIENT,
		payload: data,
	});

export const failureUpdateReferralProgramDefaultDocumentNotificationRecipientsDispatch =
	(error) => ({
		type: actionTypes.ERROR_UPDATE_REFERRAL_PROGRAM_DEFAULT_DOCUMENT_RECIPIENT,
		payload: error,
	});

export const clearDefaultProvidersState = () => ({
	type: actionTypes.CLEAR_DEFAULT_PROVIDERS_STATE,
});

export function getReferralPrograms(data) {
	return (dispatch) => {
		dispatch(startReferralProgramsDispatch());
		ReferralProgramsService.getReferralPrograms(data)
			.then((res) => {
				dispatch(successReferralProgramsDispatch(res));
			})
			.catch((error) => {
				dispatch(faliureReferralProgramsDispatch(error));
			});
	};
}

export function getReferralProgramDetail(data) {
	return (dispatch) => {
		dispatch(startReferralProgramDetailDispatch());
		ReferralProgramsService.getReferralProgramDetail(data)
			.then((res) => {
				dispatch(successReferralProgramDetailDispatch(res));
			})
			.catch((error) => {
				dispatch(failureReferralProgramDetailDispatch(error));
			});
	};
}

export function getDefaultSchedulingOptions(data) {
	return (dispatch) => {
		dispatch(startDefaultSchedulingOptionsDispatch());
		ReferralProgramsService.getDefaultSchedulingOptions(data)
			.then((res) => {
				dispatch(successDefaultSchedulingOptionsDispatch(res));
			})
			.catch((error) => {
				dispatch(failureDefaultSchedulingOptionsDispatch(error));
			});
	};
}

export const createReferralProgramDefaultProvider = (data) => (dispatch) => {
	dispatch(startCreateReferralProgramDefaultProviderDispatch());
	ReferralProgramsService.createReferralProgramDefaultProvider(data)
		.then((res) => {
			dispatch(successCreateReferralProgramDefaultProviderDispatch(res));
		})
		.catch((error) => {
			dispatch(
				failureCreateReferralProgramDefaultProviderDispatch(error)
			);
		});
};

export const updateReferralProgramDefaultProvider = (data) => (dispatch) => {
	dispatch(startUpdateReferralProgramDefaultProviderDispatch());
	ReferralProgramsService.updateReferralProgramDefaultProvider(data)
		.then((res) => {
			dispatch(successUpdateReferralProgramDefaultProviderDispatch(res));
		})
		.catch((error) => {
			dispatch(
				failureUpdateReferralProgramDefaultProviderDispatch(error)
			);
		});
};

export const updateReferralProgramDefaultDocumentNotificationRecipients =
	(data) => async (dispatch) => {
		dispatch(
			startUpdateReferralProgramDefaultDocumentNotificationRecipientsDispatch()
		);
		try {
			const res = await ReferralProgramsService.updateReferralProgramDefaultNotificationRecipient(
				data
			);
			dispatch(
				successUpdateReferralProgramDefaultDocumentNotificationRecipientsDispatch(
					res
				)
			);
		} catch (error) {
			dispatch(
				failureUpdateReferralProgramDefaultDocumentNotificationRecipientsDispatch(
					error
				)
			);
		}
	};

export function resetReferralProgramDetail() {
	return (dispatch) => {
		dispatch({ type: actionTypes.RESET_REFERRAL_PROGRAM_DETAIL });
	};
}
