//React & Redux
import React, { Component, Fragment } from 'react';

//UI Libraries
import { Loading } from 'gm-element-react';

//Lodash
import _ from 'lodash';

//Components
import PatientDetail from './PatientDetail';

class PatientDetailContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}

	componentDidUpdate(prevProps) {
		const { patientid } = _.get(this, 'props.match.params', {});

		if (
			patientid != _.get(prevProps, ['match', 'params', 'patientid'], '')
		) {
			this.setState({
				loading: true,
			});
			setTimeout(() => {
				this.setState({
					loading: false,
				});
			}, 500);
		}
	}

	render() {
		return (
			<Fragment>
				{_.get(this, ['state', 'loading'], false) == true ? (
					<Loading
						loading={true}
						className='patient_detail_loader'
						style={{ background: 'transparent' }}
					/>
				) : (
					<PatientDetail {...this.props} />
				)}
			</Fragment>
		);
	}
}

export default PatientDetailContainer;
