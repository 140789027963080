//React
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../../assets/close.svg';

//Lodash
import get from 'lodash/get';

//Styles
import './FormModal.css';

class TopRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		const customStyling = get(this, 'props.customStyling', null) || {};
		return (
			<div className='modalTopRow'>
				<div>{get(this, 'props.title', null) || '--'}</div>
				<ReactSVG
					src={xIcon}
					className='singleSelectModalXIcon modalTopRowXIcon'
					style={customStyling}
					onClick={this.props.close ? this.props.close : () => {}}
				/>
			</div>
		);
	}
}

export default TopRow;
