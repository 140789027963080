//React
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import InvalidCategoryError from './InvalidCategoryError';

const ErrorComponent = (props) => {
	return (
		<div className='errorcomp'>
			<InvalidCategoryError searchValue={get(props, 'searchValue', '')} />
		</div>
	);
};
export default ErrorComponent;
