//React & Redux
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

//Lodash
import _ from 'lodash';

//Utils
import { CARE_APPOINTMENTS_SCHEDULING } from '../../Utils/permissionUtils';

//Components
import Searchbar from './../Filter/Searchbar';
import MultiSelectCheckbox from './../Filter/MultiSelectCheckbox';
import SingleSelect from './../Filter/SingleSelect';
import MoreFilters from './../Filter/MoreFilters';
import ResetFilters from './../Filter/ResetFilters';
import RBAC from '../RBAC';
import Icon from '../Common/Icon';

const AppointmentFilter = (props) => {
	const {
		changefilter,
		consultationTypes = [],
		referralProviders = [],
		appointmentStatus = [],
		vsee_specialty = [],
		gmi_visit_providers = [],
		modalities = [],
		gmi_visit_providers_loading,
		filter = {},
		defaultFilter = {},
		showScheduleAppointment,
		handleResetFilters,
		changeFilterBulk,
	} = props;

	const changeFilterHandler = (name, value) => {
		changefilter(name, value);
	};

	const formulateMoreFiltersData = () => {
		const data = [
			{
				type: 'singleSelect',
				needSelectAll: true,
				data: _.map(consultationTypes, (option) => {
					return {
						key: option.name,
						display_name: option.name,
					};
				}),
				selected: _.get(filter, ['consultation_type'], ''),
				title: 'Visit Types',
				submitKey: 'consultation_type',
				defaultValues: _.get(defaultFilter, ['consultation_type'], ''),
			},
			{
				type: 'singleSelect',
				needSelectAll: true,
				data: modalities,
				selected: _.get(filter, ['tm_platform'], ''),
				title: 'Modality',
				submitKey: 'tm_platform',
				defaultValues: _.get(defaultFilter, ['tm_platform'], ''),
			},
			{
				type: 'singleSelect',
				needSelectAll: true,
				data: vsee_specialty,
				selected: _.get(filter, ['vsee_specialty'], ''),
				title: 'Specialty',
				submitKey: 'vsee_specialty',
				defaultValues: _.get(defaultFilter, ['vsee_specialty'], ''),
			},
			{
				type: 'checkbox',
				checked: _.get(filter, ['patient_athena_package'], false),
				defaultValue: _.get(
					defaultFilter,
					['patient_athena_package'],
					false
				),
				title: 'Insurance',
				text: 'Needs Insurance Selection',
				submitKey: 'patient_athena_package',
			},
		];
		return data;
	};

	const constructReferralProgrammsEnum = () => {
		return [
			{
				external: 'All Referral Programs',
				internal: -1,
			},
			..._.map(referralProviders, (option) => {
				return {
					internal: option.display_name,
					external: option.display_name,
				};
			}),
		];
	};

	const constructVisitProviderEnum = () => {
		return gmi_visit_providers.map((visit_provider) => ({
			display_name: visit_provider['full_name'],
			key: visit_provider['uuid'],
		}));
	};

	return (
		<Fragment>
			<div className='filter_containter'>
				<div className='filter_header'>
					<RBAC
						action={CARE_APPOINTMENTS_SCHEDULING}
						yes={
							<div
								data-testId='btn-schedule-appointment'
								className='btn-schedule-appointment'
								onClick={() => showScheduleAppointment(filter)}
							>
								<Icon
									className='appointmentAddIcon'
									icon='calendar-user-plus'
								/>
								<span>Schedule appointment</span>
							</div>
						}
						no={<div></div>}
					/>
					<div className='filter-rightcontainer'>
						<Searchbar
							setStateFunc={(e) =>
								changeFilterHandler('q', e.target.value)
							}
							currentValue={_.get(filter, 'q', '')}
							useInputEvtObj={true}
							placeholder='Search'
						/>

						<ResetFilters handleResetFilters={handleResetFilters} />

						<MultiSelectCheckbox
							data={appointmentStatus}
							selected={_.get(filter, ['visit_status'], [])}
							title={'Visit Status'}
							submit={(values) =>
								changeFilterHandler('visit_status', [...values])
							}
							majorPillTitle={'Visit Status'}
							defaultValues={_.get(
								defaultFilter,
								['visit_status'],
								[]
							)}
							searchBar={false}
							previewTab={false}
							emptyInputFullOutputOverride={true}
						/>

						<MultiSelectCheckbox
							data={constructVisitProviderEnum()}
							selected={_.get(
								filter,
								['visit_provider_uuid'],
								[]
							)}
							title={'Visit Providers'}
							submit={(values) =>
								changeFilterHandler('visit_provider_uuid', [
									...values,
								])
							}
							majorPillTitle={'Visit Providers'}
							defaultValues={_.get(
								defaultFilter,
								['visit_provider_uuid'],
								[]
							)}
							searchBar={true}
							previewTab={true}
							loading={gmi_visit_providers_loading}
						/>

						<SingleSelect
							data={constructReferralProgrammsEnum()}
							currentValue={
								_.get(filter, ['referral_program'], '') || -1
							}
							title={'Referral Programs'}
							setStateFunc={(value) =>
								changeFilterHandler(
									'referral_program',
									value !== -1 ? value : ''
								)
							}
							defaultValue={-1}
							convertStrsToNums={true}
						/>

						<MoreFilters
							data={formulateMoreFiltersData()}
							submit={(filters) =>
								changeFilterBulk({ ...filters })
							}
						/>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

AppointmentFilter.propTypes = {
	gmi_visit_providers: PropTypes.array.isRequired,
	referralProviders: PropTypes.array.isRequired,
	consultationTypes: PropTypes.array.isRequired,
	modalities: PropTypes.array.isRequired,
	history: PropTypes.object.isRequired,
	changefilter: PropTypes.func.isRequired,
};

export default AppointmentFilter;
