//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	EncounterDateFieldsDisplay,
	GetSelectvalue,
} from '../Common/utilsfunctions';

const RORSegment = (props) => {
	return (
		<div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							RoR Visit Status
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'ror_visit_status'],
								_.get(
									props,
									['labsschema', 'ror_visit_status'],
									[]
								)
							)}
						</div>
					</div>
				</div>
			</div>

			<div className='segment-row segment-row-title'>
				<div className='segment-col'>
					RoR Follow-up Schedule Contact Date
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>Initial</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>Recontact</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>Final</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'encounter',
								'ror_date_contacted',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'encounter',
								'ror_date_recontacted',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'encounter',
								'ror_date_final_contacted',
							])}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RORSegment;
