//React & Redux
import React from 'react';

//Components
import TagSearch from './TagSearch.js';
import TagSelected from './TagSelected.js';

const TagSelection = (props) => {
	const {
		allValid,
		alternateFinalizeButtonText = null,
		buttonText = 'Save',
		collection,
		func,
		handleInputChange,
		handleSearchQueryChange,
		icdCodes = [],
		price,
		regressStage,
		scrollHandler,
		searchQuery,
		selectedICDCodes = [],
		setBodyElement,
		shouldHaveBottomDropShadow,
		shouldHaveTopDropShadow,
		toggleCodeSelection,
	} = props;

	const advanceStage = () => {
		allValid && func(selectedICDCodes);
	};

	const getButtonText = () => alternateFinalizeButtonText || buttonText;

	const containerClass = shouldHaveBottomDropShadow
		? 'chargeModalICDButtonRow scrollableModalDropShadowBottom'
		: 'chargeModalICDButtonRow';

	const buttonClass = allValid
		? 'chargeModalActionButton'
		: 'chargeModalActionButton chargeModalActionButtonDisabled';

	return (
		<div className='chargeICDContainer'>
			<div className='chargeICDSearchAndSelectContainer'>
				<TagSearch
					{...{
						handleInputChange,
						handleSearchQueryChange,
						icdCodes,
						price,
						scrollHandler,
						searchQuery,
						selectedICDCodes,
						setBodyElement,
						shouldHaveTopDropShadow,
						toggleCodeSelection,
					}}
				/>
				<TagSelected
					{...{
						collection,
						selectedICDCodes,
						toggleCodeSelection,
					}}
				/>
			</div>
			<div className={containerClass}>
				<div className={'chargeModalBackButton'} onClick={regressStage}>
					Back
				</div>
				<div className={buttonClass} onClick={advanceStage}>
					{getButtonText()}
				</div>
			</div>
		</div>
	);
};

export default TagSelection;
