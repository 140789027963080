//React & Redux
import React, { Fragment } from 'react';

//Images
import phoneicon from '../../../../assets/quick-view-phone.svg';
import videoicon from '../../../../assets/video-modality.svg';

//Lodash
import _ from 'lodash';

//Utils
import { UTCToCurrentTimeView } from '../../../../utils';
import {
	imageDefault,
	providers_images,
} from '../../../../Utils/encounterUtils';

//UI Libraries
import { Layout } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Styles
import './create-test-request-modal.css';

//Components
import Tooltip from '../../../Common/Tooltip';

const CreateTestRequestEncntrInfo = (props) => {
	const encounter = _.get(props, 'encounter', {});
	const patient = _.get(props, 'patientdetail', {});

	const ucFirstAllWords = (str, separator = ' ') => {
		const pieces = (str && str.split(separator)) || [];

		for (let i = 0; i < pieces.length; i++) {
			const j = pieces[i].charAt(0).toUpperCase();
			if (pieces[i].substr(1)) {
				pieces[i] = j + pieces[i].substr(1).toLowerCase();
			}
		}

		return pieces.join(separator);
	};

	const getTimestamp = (encounter) => {
		const date1 = moment.tz(
			moment(_.get(encounter, 'date_of_service', '')).format(
				'YYYY-MM-DD HH:mm:ss'
			),
			'UTC'
		);
		const date_time_zone = moment
			.tz(date1, _.get(encounter, ['timezone'], ''))
			.format('dddd, MMM D');

		return `${date_time_zone}, ${UTCToCurrentTimeView(
			moment,
			moment(_.get(encounter, 'date_of_service', '')),
			_.get(encounter, ['timezone'], '')
		)}`;
	};

	const getShortText = (text, length) => {
		if (text && !_.isEmpty(text) && text.length > length) {
			return (
				<Tooltip
					name='name_tooltip'
					className='name_tooltip'
					disableFocusListener
					disableTouchListener
					title={text}
					placement='top'
					content={text}
				>
					<span>{text.substring(0, length) + '...'}</span>
				</Tooltip>
			);
		} else {
			return text;
		}
	};

	const renderDOB = (patient) => {
		let date_of_birth = '';

		if (_.get(patient, 'dob') && _.get(patient, 'dob') != null) {
			date_of_birth = (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div>
						{moment(_.get(patient, 'dob')).format('MMM D, YYYY')}
					</div>
					<div>
						{moment().diff(patient.dob, 'years') + ' yr '} (
						{moment(_.get(patient, 'dob')).format('MM/DD/YYYY')})
					</div>
				</div>
			);
		} else {
			date_of_birth = <div>DOB Unknown</div>;
		}

		return date_of_birth;
	};

	const getProviderImage = (encounter) => {
		let providerImage = null;
		const providerName = _.get(encounter, 'visit_provider', '');
		const mappedImage = providers_images[providerName];

		if (providerName && mappedImage) {
			providerImage = <img src={mappedImage} alt='' />;
		} else if (providerName != '') {
			providerImage = <img src={imageDefault} alt='' />;
		} else {
			providerImage = null;
		}

		return (
			providerImage && (
				<span className='patient-imgbox'>{providerImage}</span>
			)
		);
	};

	const getProviderName = (obj) => {
		let result = '';
		let providerName = '';
		let separator = ' ';
		const enums = _.get(
			this,
			['props', 'encounterschema', 'visit_provider'],
			[]
		);
		const enum_value = _.find(enums, function (o) {
			return o.key == _.get(obj, 'visit_provider', '--');
		});

		if (enum_value) {
			providerName = _.get(enum_value, 'display_name', '');
		} else {
			separator = '_';
			providerName = ucFirstAllWords(
				_.get(obj, 'visit_provider', ''),
				separator
			);
		}

		if (providerName.length > 0) {
			const ProviderNameobj = providerName.split(separator);
			const first_name = _.get(ProviderNameobj, 0, '');
			const middle_name =
				providerName.length > 2
					? ' ' + _.get(ProviderNameobj, 1, '')
					: '';
			const last_name =
				providerName.length > 2
					? ' ' + _.get(ProviderNameobj, 2, '')
					: ' ' + _.get(ProviderNameobj, 1, '');

			result = first_name.charAt(0) + '.' + middle_name + last_name;

			return (
				<span
					className='provider-name'
					style={{
						color: '#262837',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{getShortText(result, 12)}
				</span>
			);
		} else {
			return <span className='pat-dash'>--</span>;
		}
	};

	const getModalityIcon = (encounter) => {
		let iconModality = '';
		const tmPlatForm = !_.isEmpty(_.get(encounter, 'tm_platform', ''))
			? _.get(encounter, 'tm_platform', '')
			: '';
		if (tmPlatForm.toLowerCase() === 'phone') {
			iconModality = (
				<img src={phoneicon} className='aptmnt-smry-modality-icon' />
			);
		} else if (tmPlatForm.toLowerCase() === 'video') {
			iconModality = (
				<img src={videoicon} className='aptmnt-smry-modality-icon' />
			);
		}
		return iconModality;
	};

	return (
		<Fragment>
			<section className='crte-test-rqst-encntr-container'>
				<span className='enctr-sumry-title'>ENCOUNTER SUMMARY</span>
				<Layout.Row>
					<Layout.Col span='24' className='patient-name'>
						{patient.last_name} {patient.first_name}
					</Layout.Col>
				</Layout.Row>
				<Layout.Row>
					<Layout.Col span='24'>{patient.email}</Layout.Col>
				</Layout.Row>
				<Layout.Row className='patient-phone'>
					<Layout.Col span='24'>
						{patient.phone
							? !_.isNil(patient.phone)
								? patient.phone
								: 'Phone number not available.'
							: 'Phone number not available.'}
					</Layout.Col>
				</Layout.Row>
				<Layout.Row>
					<Layout.Col span='12'>{renderDOB(patient)}</Layout.Col>
					<Layout.Col span='12' className='patient-refral-prgrm'>
						{patient.referral_program}
					</Layout.Col>
				</Layout.Row>
				<Layout.Row>
					<Layout.Col span='24' className='consultation-type'>
						{_.get(encounter, ['consultation_type'], '')}
					</Layout.Col>
				</Layout.Row>
				<Layout.Row>
					<Layout.Col span='24'>
						{getTimestamp(encounter)}
						{!_.isNil(_.get(encounter, ['state_of_visit'])) &&
						!_.isEmpty(_.get(encounter, ['state_of_visit'])) ? (
							<span className='enctr-sumry-state'>
								{' '}
								- {_.get(encounter, ['state_of_visit'], '--')}
							</span>
						) : (
							''
						)}
					</Layout.Col>
				</Layout.Row>
				<Layout.Row>
					<Layout.Col span='24'>
						{!_.isNil(_.get(encounter, ['vsee_specialty'], ''))
							? ucFirstAllWords(
									_.get(encounter, ['vsee_specialty'], '')
										.replace('_Willing', '')
										.replace('_willing', '')
										.replace(/_/g, ' '),
									' '
							  )
							: ''}
					</Layout.Col>
				</Layout.Row>
				<Layout.Row>
					<Layout.Col span='12' className='enctr-sumry-prvdr-name'>
						{getProviderImage(encounter)}
						{getProviderName(encounter)}
					</Layout.Col>
					<Layout.Col span='12' className='enctr-sumry-tm-platform'>
						{getModalityIcon(encounter)}
						{_.get(encounter, ['tm_platform'], '')}
					</Layout.Col>
				</Layout.Row>
			</section>
		</Fragment>
	);
};

export default CreateTestRequestEncntrInfo;
