import classnames from 'classnames';
import { Button as ElButton } from 'gm-element-react';
import React from 'react';

import './Button.scss';

interface IProps {
	className?: string;
	disabled?: boolean;
	inverted?: boolean;
	link?: boolean;
	children: React.ReactNode;
	onClick?: () => void;
}

export const Button: React.FC<IProps> = (props) => {
	const { className, disabled, inverted, link, onClick, ...otherProps } =
		props;
	return (
		<ElButton
			{...otherProps}
			disabled={disabled}
			className={classnames(
				'gm-btn',
				{ disabled, inverted, link },
				className
			)}
			onClick={onClick}
		/>
	);
};
