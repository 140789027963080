import React from 'react';

import _ from 'lodash';
import get from 'lodash/get';

import moment from 'moment';

import ReactSVG from 'react-svg';
import infoIcon from './../../../../../../assets/more-info.svg';
import xIcon from './../../../../../../assets/exit-alert.svg';

import { getEncounterSpecificTime } from './../../Common/utilsfunctions.js';

import FlagArchiveModal from './FlagArchiveModal.js';

//Components
import Tooltip from '../../../../../Common/Tooltip';

class FlaggedForReview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			newFlags: [],
			archivalReason: '',
			inFlagAdditionMode: false,
			renderArchiveModal: false,
			renderArchiveModalFlagUuid: null,
			allowedStatuses: new Set([
				'requested',
				'approved',
				'awaiting_external_approval',
				'waiting_to_submit',
				'sent_to_lab',
				'patient_not_responsive',
			]),
		};
		//bindings
		this.statusAllowsAction = this.statusAllowsAction.bind(this);
		this.renderFlag = this.renderFlag.bind(this);
		this.renderNewFlag = this.renderNewFlag.bind(this);
		this.renderArchiveModal = this.renderArchiveModal.bind(this);
		this.closeArchiveModal = this.closeArchiveModal.bind(this);
		this.constructTooltipText = this.constructTooltipText.bind(this);
		this.updateNewFlagText = this.updateNewFlagText.bind(this);
		this.removeSingleNewFlag = this.removeSingleNewFlag.bind(this);
		this.toggleFlagAdditionModeOn =
			this.toggleFlagAdditionModeOn.bind(this);
		this.toggleFlagAdditionModeOff =
			this.toggleFlagAdditionModeOff.bind(this);
		this.canSave = this.canSave.bind(this);
		this.saveNewFlags = this.saveNewFlags.bind(this);
		this.updateFlags = this.updateFlags.bind(this);
	}
	//functions

	statusAllowsAction() {
		const testOrderStatus = get(this, 'props.testOrderStatus', false);
		return get(this, 'state.allowedStatuses', new Set()).has(
			testOrderStatus
		);
	}

	renderFlag(flag) {
		const isArchived = get(flag, 'archived', false);
		const textClass = isArchived
			? 'flaggedForReviewFlagText flaggedForReviewFlagArchived'
			: 'flaggedForReviewFlagText';
		const inFlagAdditionMode = get(this, 'state.inFlagAdditionMode', false);
		return (
			<div
				className='flaggedForReviewFlag'
				style={{
					cursor:
						inFlagAdditionMode && !isArchived ? 'no-drop' : 'unset',
				}}
			>
				<div className='flaggedForReviewFlagMain'>
					<span className='flaggedForReviewFlagBulletPoint'>·</span>
					<div className={textClass}>
						{get(flag, 'description', '--') || '--'}
					</div>
					{isArchived && (
						<Tooltip
							className='flaggedForReviewIconContainer'
							effect='dark'
							content={this.constructTooltipText(flag)}
							placement='top'
							transition='fade-in-linear'
							openDelay={100}
						>
							<ReactSVG
								src={infoIcon}
								className='flaggedForReviewIcon'
							/>
						</Tooltip>
					)}
				</div>
				{!isArchived &&
					this.statusAllowsAction() &&
					!inFlagAdditionMode && (
						<div
							className='flaggedForReviewFlagArchive'
							onClick={(e) => this.renderArchiveModal(flag)}
						>
							Archive
						</div>
					)}
			</div>
		);
	}

	renderNewFlag(flagText, idx) {
		return (
			<div className='flaggedForReviewNewFlag'>
				<span className='flaggedForReviewFlagBulletPoint'>·</span>
				<input
					placeholder='Add a flag'
					className='flaggedForReviewNewFlagInput'
					value={flagText}
					onChange={(e) => this.updateNewFlagText(e, idx)}
				/>
				<div className='flaggedForReviewNewFlagX'>
					<ReactSVG
						src={xIcon}
						style={{
							position: 'relative',
							bottom: '2px',
							right: '2px',
						}}
						onClick={(e) => this.removeSingleNewFlag(idx)}
					/>
				</div>
			</div>
		);
	}

	renderArchiveModal(flag) {
		this.setState({
			renderArchiveModal: true,
			renderArchiveModalFlagUuid: get(flag, 'uuid', null),
			renderArchiveModalFlagDescription: get(flag, 'description', null),
		});
	}

	closeArchiveModal() {
		this.setState({
			renderArchiveModal: false,
			renderArchiveModalFlagUuid: null,
			renderArchiveModalFlagDescription: null,
		});
	}

	constructTooltipText(flag) {
		if (flag) {
			return (
				<div className='flaggedForReviewTooltip'>
					<div className='flaggedForReviewTooltipHeader'>
						Archived:
					</div>
					<div className='flaggedForReviewTooltipBody'>
						{getEncounterSpecificTime(
							get(flag, 'archived_at', null) || ''
						)}
						<br />
						by {get(flag, 'archived_by', '') || '--'}
					</div>
					<br />
					<div className='flaggedForReviewTooltipHeader'>
						Reason for Archiving:
					</div>
					<div className='flaggedForReviewTooltipBody'>
						{get(flag, 'archived_reason', '--')}
					</div>
				</div>
			);
		} else return '--';
	}

	updateNewFlagText(e, idx) {
		const currentNewFlags = get(this, 'state.newFlags', []) || [];
		const newText = get(e, 'target.value', '');
		currentNewFlags[idx] = newText;
		this.setState({ newFlags: currentNewFlags });
	}

	removeSingleNewFlag(idx) {
		const currentNewFlags = get(this, 'state.newFlags', []) || [];
		if ((idx || idx === 0) && Array.isArray(currentNewFlags)) {
			_.remove(currentNewFlags, (el, i) => i === idx);
			this.setState({ newFlags: currentNewFlags }, () => {
				if (get(this, 'state.newFlags.length', 0) === 0)
					this.toggleFlagAdditionModeOff();
			});
		}
	}

	toggleFlagAdditionModeOn() {
		if (!get(this, 'state.inFlagAdditionMode', false)) {
			this.setState({ inFlagAdditionMode: true, newFlags: [''] });
		} else {
			this.setState({
				newFlags: (get(this, 'state.newFlags', []) || []).concat(''),
			});
		}
	}

	toggleFlagAdditionModeOff() {
		this.setState({ inFlagAdditionMode: false, newFlags: [] });
	}

	canSave() {
		const currentNewFlags = get(this, 'state.newFlags', []) || [];
		if (Array.isArray(currentNewFlags)) {
			return (
				currentNewFlags.every(
					(el) =>
						typeof el === 'string' && get(el.trim(), 'length', 0)
				) && get(currentNewFlags, 'length', 0)
			);
		} else return false;
	}

	saveNewFlags() {
		const currentNewFlags = get(this, 'state.newFlags', []) || [];
		const testOrderUuid = get(this, 'props.testOrderUuid', null);
		if (this.canSave() && testOrderUuid) {
			this.props.createTestRequestFlags(
				testOrderUuid,
				currentNewFlags.map((el) => {
					return { description: el };
				}),
				this.toggleFlagAdditionModeOff
			);
		}
	}

	updateFlags(description) {
		const testOrderUuid = get(this, 'props.testOrderUuid', null);
		const flagUuid = get(this, 'state.renderArchiveModalFlagUuid', null);
		if (testOrderUuid && flagUuid) {
			const payload = [
				{
					uuid: flagUuid,
					archived: true,
					archived_reason: description,
				},
			];
			this.props.updateTestRequestFlags(
				testOrderUuid,
				payload,
				this.closeArchiveModal
			);
		}
	}

	render() {
		const flags = get(this, 'props.flags', []) || [];
		const inFlagAdditionMode = get(this, 'state.inFlagAdditionMode', false);
		const saveButtonClass = this.canSave()
			? 'confirmationModalButtonRowConfirm'
			: 'confirmationModalButtonRowConfirm confirmationModalButtonRowConfirmDisabled';
		const creatingTestOrderFlags = get(
			this,
			'props.creatingTestOrderFlags',
			false
		);
		const updatingTestOrderFlags = get(
			this,
			'props.updatingTestOrderFlags',
			false
		);
		const renderArchiveModal = get(this, 'state.renderArchiveModal', false);
		const renderArchiveModalFlagDescription = get(
			this,
			'state.renderArchiveModalFlagDescription',
			null
		);
		return (
			<div className='flaggedForReviewContainer'>
				<div className='clinical-flag-title'>
					FLAGGED FOR RESULTS REVIEW
				</div>
				{get(flags, 'length', 0) === 0 ? (
					<div className='flaggedForReviewNoItems'>
						No items flagged for results review
					</div>
				) : (
					flags.map((el) => this.renderFlag(el))
				)}
				{inFlagAdditionMode &&
					(get(this, 'state.newFlags', []) || []).map((el, i) =>
						this.renderNewFlag(el, i)
					)}
				{this.statusAllowsAction() && (
					<div
						className='flaggedForReviewAddItems'
						onClick={this.toggleFlagAdditionModeOn}
					>
						{get(flags, 'length', 0) === 0 && !inFlagAdditionMode
							? '+ Add a Manual Flag'
							: '+ Add Another'}
					</div>
				)}
				{inFlagAdditionMode && (
					<div className='flaggedForReviewButtonRow'>
						<div
							className={saveButtonClass}
							style={{ width: '120px' }}
							onClick={this.saveNewFlags}
						>
							Save
						</div>
						<div
							className='flaggedForReviewAddItems'
							style={{
								fontSize: '13px',
								marginLeft: '32px',
								paddingBottom: '4px',
							}}
							onClick={this.toggleFlagAdditionModeOff}
						>
							Cancel
						</div>
					</div>
				)}
				{renderArchiveModal && (
					<FlagArchiveModal
						updatingTestOrderFlags={updatingTestOrderFlags}
						renderArchiveModalFlagDescription={
							renderArchiveModalFlagDescription
						}
						closeArchiveModal={this.closeArchiveModal}
						updateFlags={this.updateFlags}
					/>
				)}
			</div>
		);
	}
}

export default FlaggedForReview;
