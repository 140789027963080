//React & Redux
import React, { useEffect, useState, useRef } from 'react';

//Images
import ReactSVG from 'react-svg';
import userIcon from '../../assets/contacts.svg';
import alert from '../../assets/alert_dob.svg';
import dropdownArrow from '../../assets/dropdown.svg';

//Utils
import { shouldBeMasked, getLanguageTagText } from './../../utils.js';

//Lodash
import { get, isEmpty, isNil, find, join } from 'lodash';

//Other Libraries
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';

//Components
import PatientTag from './../Common/PatientTag.js';
import TooltipDOB from './../Common/Tooltip';

const PatientInfoBox = (props) => {
	const {
		noOutreach,
		patient = {},
		pronouns = [],
		classes,
		hasPriorityTag,
		hasPriorityTagLanguage,
		hasVIPTag,
		hasLanguageLineSupportTag,
		hasLanguageLineSupportTagLanguage,
		hasSpeechImpairedTag,
		hashearingImpairedTag,
		hasVisionImpairedTag,
		hasFinancialAssistanceTag,
		hasFinancialAssistancePerVisitTag,
		hasDeceasedTag,
		hasDischargedTag,
		hasMarketingOptoutTag,
		hasResearchOptoutTag,
		hasNoFurtherEscalationTag,
		hasPatientStoryTag,
		hasDuplicatePatientTag,
		duplicatePatients,
		enqueueSnackbar,
		determineWidthOverflow,
		copyClipboard,
	} = props;

	const { guardian = {}, has_guardian: hasGuardian = false } = patient;

	const [expanded, setExpanded] = useState(true);

	useEffect(() => {
		setInitialExpansion();
	}, []);

	const guardianText = useRef(null);
	const emailText = useRef(null);
	const primaryPhoneText = useRef(null);
	const secondaryPhoneText = useRef(null);
	const dobText = useRef(null);

	const setInitialExpansion = () => {
		if (noOutreach) setExpanded(false);
	};

	const renderGuardianName = () => {
		return `${get(guardian, 'first_name', '--') || '--'} ${
			!isNil(get(guardian, 'preferred_name', '')) &&
			!isEmpty(get(guardian, 'preferred_name', ''))
				? '(' + get(guardian, 'preferred_name', '') + ')'
				: ''
		} ${get(guardian, 'last_name', '--') || '--'} ${
			!isNil(get(guardian, 'relationship', ''))
				? '(' + get(guardian, 'relationship', '--') + ')'
				: ''
		}`;
	};

	const renderGuardianPronouns = () => {
		const _pronouns = [];
		if (!isNil(get(guardian, 'pronouns', ''))) {
			get(guardian, 'pronouns', []).map((val) => {
				const pronouns_val = find(pronouns, function (o) {
					return o.key == val;
				});
				if (pronouns_val) {
					_pronouns.push(pronouns_val.display_name);
				}
			});
			return join(_pronouns, ', ');
		}
		return _pronouns;
	};

	const isValidDob = (dob) => {
		return dob !== '01/01/1901';
	};

	const toggleExpansion = (e) => {
		setExpanded(!expanded);
	};

	const getPrimaryPhoneValue = (patient) => {
		if (get(patient, 'has_guardian', false)) {
			return get(patient, ['guardian', 'phones', 'primary', 'value']);
		}
		return get(patient, ['phones', 'primary', 'value']);
	};

	const getPrimaryPhoneType = (patient) => {
		const isNotEmpty = (value) => !isNil(value) && !isEmpty(value);
		if (get(patient, 'has_guardian', false)) {
			if (
				isNotEmpty(
					get(patient, ['guardian', 'phones', 'primary', 'type'])
				)
			)
				return (
					' (Primary-' +
					get(patient, ['guardian', 'phones', 'primary', 'type']) +
					')'
				);
		} else {
			if (isNotEmpty(get(patient, ['phones', 'primary', 'type'])))
				return (
					' (Primary-' +
					get(patient, ['phones', 'primary', 'type']) +
					')'
				);
		}
		return '';
	};

	const getSecondaryPhoneValue = (patient) => {
		if (get(patient, 'has_guardian', false)) {
			return get(patient, ['guardian', 'phones', 'secondary', 'value']);
		}
		return get(patient, ['phones', 'secondary', 'value']);
	};

	const getSecondaryPhoneType = (patient) => {
		const isNotEmpty = (value) => !isNil(value) && !isEmpty(value);
		if (get(patient, 'has_guardian', false)) {
			if (
				isNotEmpty(
					get(patient, ['guardian', 'phones', 'secondary', 'type'])
				)
			)
				return (
					' (Secondary-' +
					get(patient, ['guardian', 'phones', 'secondary', 'type']) +
					')'
				);
		} else {
			if (isNotEmpty(get(patient, ['phones', 'secondary', 'type'])))
				return (
					' (Secondary-' +
					get(patient, ['phones', 'secondary', 'type']) +
					')'
				);
		}
		return '';
	};

	const getPrimaryPhoneText = (patient) => {
		let result = '';
		if (isPrimaryPhone(patient)) {
			result += getPrimaryPhoneValue(patient);
			result += getPrimaryPhoneType(patient);
		} else {
			result = '--';
		}
		return result;
	};

	const getSecondaryPhoneText = (patient) => {
		let result = '';
		if (isSecondaryPhone(patient)) {
			result += getSecondaryPhoneValue(patient);
			result += getSecondaryPhoneType(patient);
		} else {
			result = '--';
		}
		return result;
	};

	const isPrimaryPhone = (patient) => {
		if (get(patient, 'has_guardian', false)) {
			return !isNil(get(patient, ['guardian', 'phones', 'primary']));
		}
		return !isNil(get(patient, ['phones', 'primary']));
	};

	const isSecondaryPhone = (patient) => {
		if (get(patient, 'has_guardian', false)) {
			return !isNil(get(patient, ['guardian', 'phones', 'secondary']));
		}
		return !isNil(get(patient, ['phones', 'secondary']));
	};

	const detailsVisible = expanded;
	const dob = moment(get(guardian, ['dob'], '')).format('MM/DD/YYYY');

	return (
		<div className='patientInfoBoxContainer'>
			<div className='patientInfoBoxIconContainer'>
				<ReactSVG src={userIcon} className='patientInfoBoxIcon' />
			</div>
			<div className='patientInfoBoxTextContainer'>
				<div className='patientInfoBoxTagContainer'>
					<div className='patientInfoBoxTagRow'>
						{noOutreach && <PatientTag tagType='noOutreach' />}
						{hasDischargedTag && (
							<PatientTag tagType='discharged' />
						)}
						{hasDeceasedTag && <PatientTag tagType='deceased' />}
						{hasPriorityTag && (
							<PatientTag
								tagType='priority'
								customText={hasPriorityTagLanguage}
							/>
						)}
						{shouldBeMasked() && hasVIPTag && (
							<PatientTag tagType='vip_patient' />
						)}
						{hasGuardian && <PatientTag tagType='guardian' />}
						{hasFinancialAssistanceTag && (
							<PatientTag tagType='financial_assistance' />
						)}
						{hasFinancialAssistancePerVisitTag && (
							<PatientTag tagType='financial_assistance_per_visit' />
						)}
						{hasLanguageLineSupportTag && (
							<PatientTag
								tagType='language_line_support'
								customText={hasLanguageLineSupportTagLanguage}
							/>
						)}
						{hasSpeechImpairedTag && (
							<PatientTag tagType='speech_impaired' />
						)}
						{hashearingImpairedTag && (
							<PatientTag tagType='hearing_impaired' />
						)}
						{hasVisionImpairedTag && (
							<PatientTag tagType='vision_impaired' />
						)}
						{hasMarketingOptoutTag && (
							<PatientTag tagType='marketing_opt-out' />
						)}
						{hasResearchOptoutTag && (
							<PatientTag tagType='research_opt-out' />
						)}
						{hasNoFurtherEscalationTag && (
							<PatientTag tagType='no_further_escalation' />
						)}
						{hasPatientStoryTag && (
							<PatientTag tagType='patient_story' />
						)}
						{hasDuplicatePatientTag && (
							<PatientTag
								tagType='duplicate'
								duplicatePatients={duplicatePatients}
								enqueueSnackbar={enqueueSnackbar}
							/>
						)}
					</div>
					{noOutreach ? (
						<ReactSVG
							src={dropdownArrow}
							className='patientInfoBoxDropdown'
							onClick={toggleExpansion}
							style={{
								transform: detailsVisible
									? 'rotate(180deg)'
									: 'rotate(0deg)',
							}}
						/>
					) : (
						<div />
					)}
				</div>
				{detailsVisible && (
					<div className='patientInfoBoxData'>
						<div className='patientInfoBoxTextDemographicInfoContainer'>
							{get(patient, 'has_guardian', false) && (
								<TooltipDOB
									content={renderGuardianName()}
									placement='bottom-start'
									disabled={determineWidthOverflow(
										guardianText.current
									)}
								>
									<div
										className='patientInfoBoxTextDemographicInfoRow'
										ref={(el) =>
											(guardianText.current = el)
										}
										style={{ textTransform: 'capitalize' }}
									>
										{renderGuardianName()}
									</div>
								</TooltipDOB>
							)}
							<span className='patientInfoBoxTextDemographicInfoRow guardian-pronouns'>
								{renderGuardianPronouns()}
							</span>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<TooltipDOB
									content={
										get(patient, 'email', '--') || '--'
									}
									placement='bottom-start'
									disabled={determineWidthOverflow(
										emailText.current
									)}
								>
									<div
										className='patientInfoBoxTextDemographicInfoRow'
										ref={(el) => (emailText.current = el)}
									>
										{get(patient, 'email', '--') || '--'}
									</div>
								</TooltipDOB>
								<CopyToClipboard
									text={get(patient, 'email', '--') || '--'}
									onCopy={() => copyClipboard('Email')}
								>
									<i className='material-icons copyIcon'>
										file_copy
									</i>
								</CopyToClipboard>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<TooltipDOB
									content={getPrimaryPhoneText(patient)}
									placement='bottom-start'
									disabled={determineWidthOverflow(
										primaryPhoneText.current
									)}
								>
									<div
										className='patientInfoBoxTextDemographicInfoRow'
										ref={(el) =>
											(primaryPhoneText.current = el)
										}
									>
										{getPrimaryPhoneText(patient)}
									</div>
								</TooltipDOB>
								{isPrimaryPhone(patient) ? (
									<CopyToClipboard
										text={getPrimaryPhoneValue(patient)}
										onCopy={() => copyClipboard('Phone')}
									>
										<i className='material-icons copyIcon'>
											file_copy
										</i>
									</CopyToClipboard>
								) : (
									''
								)}
							</div>
							{isSecondaryPhone(patient) && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									<TooltipDOB
										content={getSecondaryPhoneText(patient)}
										placement='bottom-start'
										disabled={determineWidthOverflow(
											secondaryPhoneText.current
										)}
									>
										<div
											className='patientInfoBoxTextDemographicInfoRow'
											ref={(el) =>
												(secondaryPhoneText.current =
													el)
											}
										>
											{getSecondaryPhoneText(patient)}
										</div>
									</TooltipDOB>
									{isSecondaryPhone(patient) ? (
										<CopyToClipboard
											text={getSecondaryPhoneValue(
												patient
											)}
											onCopy={() =>
												copyClipboard('Phone')
											}
										>
											<i className='material-icons copyIcon'>
												file_copy
											</i>
										</CopyToClipboard>
									) : (
										''
									)}
								</div>
							)}
							{get(patient, 'is_unborn', false) &&
								!isEmpty(get(guardian, ['dob'], null)) && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<TooltipDOB
											content={dob}
											placement='bottom-start'
											disabled={determineWidthOverflow(
												dobText.current
											)}
										>
											<div
												className={
													!isValidDob(dob)
														? 'patientInfoBoxTextDemographicInfoRow alert_dob'
														: 'patientInfoBoxTextDemographicInfoRow'
												}
												ref={(el) =>
													(dobText.current = el)
												}
											>
												{dob}
											</div>
										</TooltipDOB>
										{dob ? (
											!isValidDob(dob) ? (
												<TooltipDOB
													className='alert_dob_tooltip'
													effect='light'
													content={
														'DOB Unknown. Please update.'
													}
													placement='top-middle'
													visible={true}
												>
													<ReactSVG
														className='alert_dob_svg'
														src={alert}
														style={{
															position:
																'relative',
															height: '16px',
														}}
													/>
												</TooltipDOB>
											) : (
												<CopyToClipboard
													text={dob}
													onCopy={() =>
														copyClipboard('DOB')
													}
												>
													<i className='material-icons copyIcon'>
														file_copy
													</i>
												</CopyToClipboard>
											)
										) : null}
									</div>
								)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default PatientInfoBox;
