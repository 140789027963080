//React & Redux
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

//Lodash
import get from 'lodash/get';
import _ from 'lodash';

//Utils
import { getRegUrlFromRefProgram } from '../../utils';

//Hooks
import { useDebounce } from '../../hooks/useDebounce';
import { usePrevious } from '../../hooks/usePrevious';

//Actions & Servies
import { getReferralPrograms } from '../../actions/referralprograms/referralprograms';
import { setFilter } from '../../actions/appData';

//Other Libraries
import moment from 'moment';

//Components
import LinkPopover from '../Common/Popovers/LinkPopover';
import Table from '../Common/Table/Table';
import Thead from '../Common/Table/Thead';
import Tbody from '../Common/Table/Tbody';
import Th from '../Common/Table/Th';
import Tr from '../Common/Table/Tr';
import Td from '../Common/Table/Td';
import Searchbar from '../Filter/Searchbar';

//Styles
import './css/refprogramlist.css';
import { Loading } from 'gm-element-react';

const DEFAULT_LIMIT = 25;

const ReferralPrograms = (props) => {
	const {
		history,
		location,
		enqueueSnackbar,
		referralPrograms,
		getRefPrograms,
		limit: propsLimit,
		setRefProgramsFilter,
		refProgramsFilter,
	} = props;

	const location_state = get(location, 'state.breadcrumbs[0]', {});

	const [state, setState] = useState({
		activePage: location_state.offset || 0,
		order: location_state.order_type || 'asc',
		orderBy: location_state.order_by || 'name',
		query: location_state.query || '',
		limit: propsLimit,
		...refProgramsFilter,
	});

	const { activePage, order, orderBy, query, limit } = state;

	const { loading, loaded, error } = referralPrograms;
	const data = (loaded && loaded.data) || [];

	const debouncedQuery = useDebounce(state.query, 500);
	useEffect(() => {
		referralProgramsApiFunc(order, orderBy, activePage, limit, query);
	}, [debouncedQuery]);

	const prevLoaded = usePrevious(loaded);
	const prevLoading = usePrevious(loading);

	useEffect(() => {
		setState((prevState) => {
			return { ...prevState, activePage: 0 };
		});
	}, [prevLoaded && !loaded]);

	useEffect(() => {
		if (prevLoading && !loading && !error) {
			setRefProgramsFilter(getRefProgramsFilter());
		}
	}, [loading]);

	const referralProgramsApiFunc = (order, orderBy, offset, limit, query) => {
		getRefPrograms({
			order_type: order,
			order_by: orderBy,
			offset: offset,
			limit: limit,
			query: query,
		});
	};

	const determineSelfRegisterURL = (refProgram) => {
		return getRegUrlFromRefProgram(refProgram);
	};

	const onSearchChange = (e) => {
		setState((prevState) => {
			return { ...prevState, query: e.target.value, activePage: 0 };
		});
	};

	const handlePageChange = (e, pageNumber) => {
		setState((prevState) => {
			return { ...prevState, activePage: pageNumber };
		});
		referralProgramsApiFunc(order, orderBy, pageNumber, limit, query);
	};

	const handleSort = (order_by, order_type) => {
		setState((prevState) => {
			return {
				...prevState,
				order: order_type,
				orderBy: order_by,
				activePage: 0,
			};
		});
		referralProgramsApiFunc(order_type, order_by, 0, limit, query);
	};

	const openRefProgramDetailPage = (uuid, name, e) => {
		if (
			!get(
				e,
				'target',
				document.createElement('span')
			).classList.contains('regUrlCopyContainer') &&
			!get(
				e,
				'target',
				document.createElement('span')
			).classList.contains('copyIcon')
		) {
			history.push({
				pathname: '/app/referralprogramdetail/' + uuid + '/0',
				search: '?name=' + name,
				state: {
					breadcrumbs: [
						{
							location: 'Referral program',
							offset: activePage,
							order_by: orderBy,
							order_type: order,
							query: query,
							limit: limit,
						},
						{ location: 'Referral program detail' },
					],
				},
			});
		}
	};

	const handleResultsPerPageChange = (limit) => {
		setState((prevState) => {
			return { ...prevState, activePage: 0, limit };
		});
		referralProgramsApiFunc(order, orderBy, 0, limit, query);
	};

	const handleResetFilters = () => {
		setState((prevState) => {
			return {
				...prevState,
				activePage: 0,
				order: 'asc',
				orderBy: 'name',
				query: '',
				limit,
			};
		});
		referralProgramsApiFunc('asc', 'name', 0, limit, '');
	};

	const getRefProgramsFilter = () => {
		return {
			activePage,
			order,
			orderBy,
			query,
		};
	};

	let pagination = {};
	if (!_.isEmpty(loaded)) {
		const {
			end = 0,
			has_next = false,
			has_prev = false,
			limit = 0,
			offset = 0,
			start = 0,
			total = 0,
		} = loaded;
		pagination = {
			end,
			has_next,
			has_prev,
			limit,
			offset,
			start,
			total,
		};
	}

	const sortBy = {
		key: orderBy,
		value: order,
	};

	return (
		<div className='referral-programs-container'>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					minWidth: 'inherit',
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
					<Searchbar
						setStateFunc={(e) => onSearchChange(e)}
						currentValue={query}
						useInputEvtObj={true}
						placeholder='Search'
					/>
				</div>
				{loading ? (
					<Loading loading={true} className='list-loading' />
				) : (
					<Table
						style={{ marginTop: '16px', minWidth: '1204px' }}
						loading={false}
						backToTop
						resultsPerPage={[25, 50, 100]}
						handleResultsPerPageChange={handleResultsPerPageChange}
						pagination={pagination}
						handlePageChange={handlePageChange}
						hasData={!_.isEmpty(data)}
						handleResetFilters={handleResetFilters}
						handleSort={handleSort}
						sortBy={sortBy}
					>
						<Thead>
							<Tr>
								<Th
									sortable={{
										name: {
											display_name: 'Name',
											sortBy: {
												asc: 'A to Z',
												desc: 'Z to A',
											},
										},
									}}
								>
									Name
								</Th>
								<Th
									sortable={{
										displayName: {
											display_name: 'Display name',
											sortBy: {
												asc: 'A to Z',
												desc: 'Z to A',
											},
										},
									}}
								>
									Display name
								</Th>
								<Th
									sortable={{
										eligibility: {
											display_name: 'Eligibility',
											sortBy: {
												asc: 'A to Z',
												desc: 'Z to A',
											},
										},
									}}
								>
									Eligibility
								</Th>
								<Th
									sortable={{
										selfScheduling: {
											display_name: 'Self-Registration',
											sortBy: {
												asc: 'A to Z',
												desc: 'Z to A',
											},
										},
									}}
								>
									Self-Registration
								</Th>
								<Th
									sortable={{
										createdDate: {
											display_name: 'Created',
											sortBy: {
												asc: 'A to Z',
												desc: 'Z to A',
											},
										},
									}}
								>
									Created
								</Th>
								<Th
									sortable={{
										modifiedDate: {
											display_name: 'Modified',
											sortBy: {
												asc: 'A to Z',
												desc: 'Z to A',
											},
										},
									}}
								>
									Modified
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data.map((el) => {
								return (
									<Tr
										className='test_list_row'
										key={el.id}
										style={{ cursor: 'pointer' }}
										onClick={(e) =>
											openRefProgramDetailPage(
												el.referralUUID,
												el.name,
												e
											)
										}
									>
										<Td
											minWidth='200px'
											maxWidth='200px'
											className={`cellText ${
												orderBy == 'name'
													? 'font-bold'
													: ''
											}`}
										>
											{el.name || '--'}{' '}
										</Td>
										<Td
											minWidth='200px'
											maxWidth='200px'
											className={`cellText ${
												orderBy == 'displayName'
													? 'font-bold'
													: ''
											}`}
										>
											{el.displayName || '--'}{' '}
										</Td>
										<Td
											minWidth='100px'
											maxWidth='100px'
											className={`cellText ${
												orderBy == 'eligibility'
													? 'font-bold'
													: ''
											}`}
										>
											{el.eligibility ? 'Yes' : 'No'}
										</Td>
										<Td
											minWidth='208px'
											maxWidth='208px'
											className={`cellText ${
												orderBy == 'selfScheduling'
													? 'font-bold'
													: ''
											}`}
										>
											{get(
												el,
												'selfScheduling',
												false
											) ? (
												<div className='col-self-scheduling-yes'>
													Yes
													<LinkPopover
														enqueueSnackbar={
															enqueueSnackbar
														}
														linkTo={determineSelfRegisterURL(
															el
														)}
														options={{
															text: true,
															link: true,
															copy: true,
														}}
													>
														<span className='regUrlCopyContainer'>
															Self Reg URL
														</span>
													</LinkPopover>
												</div>
											) : (
												<div>No</div>
											)}
										</Td>
										<Td
											minWidth='160px'
											maxWidth='160px'
											className={`cellText ${
												orderBy == 'createdDate'
													? 'font-bold'
													: ''
											}`}
										>
											{(el.createdDate &&
												moment(el.createdDate).format(
													'MMM DD, YYYY  HH:mm A'
												)) ||
												'--'}{' '}
										</Td>
										<Td
											minWidth='160px'
											maxWidth='160px'
											className={`cellText ${
												orderBy == 'modifiedDate'
													? 'font-bold'
													: ''
											}`}
										>
											{(el.modifiedDate &&
												moment(el.modifiedDate).format(
													'MMM DD, YYYY  HH:mm A'
												)) ||
												'--'}{' '}
										</Td>
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const referralPrograms = {
		loading: state.referralProgramsList.loading_referralprogram,
		loaded: state.referralProgramsList.loaded_referralprograms,
		error: state.referralProgramsList.loading_referralprogram,
		total:
			state.referralProgramsList.loaded_referralprograms &&
			state.referralProgramsList.loaded_referralprograms.total,
	};
	return {
		referralPrograms: referralPrograms,
		limit:
			get(state, 'uiConfig.table.resultsPerPage', null) || DEFAULT_LIMIT,
		refProgramsFilter: get(state, 'appData.refPrograms', null) || {},
	};
};

const mapDispatchToProps = (dispatch) => ({
	getRefPrograms: (data) => dispatch(getReferralPrograms(data)),
	setRefProgramsFilter: (data) => dispatch(setFilter('refPrograms', data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferralPrograms);
