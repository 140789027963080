//React & Redux
import React, { useState } from 'react';

//Utils
import {
	createEnumsOptions,
	isValidDateStringOREmpty,
	getMomentDateOrStringNonStrick,
} from '../Common/utilsfunctions';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Button, Loading } from 'gm-element-react';
import { EncounterSelectField } from '../Common/EncounterSelectField';
import { EncounterDateField } from '../Common/EncounterDateField';

const datefields = {
	ror_date_contacted: 1,
	ror_date_recontacted: 1,
	ror_date_final_contacted: 1,
};

const EditRORSegment = (props) => {
	const { labsschema, encounter, saveLabEncounter, cancel } = props;

	const tempFormData = {
		ror_visit_status: _.get(encounter, ['ror_visit_status'], ''),
		ror_date_contacted: _.get(encounter, ['ror_date_contacted'], ''),
		ror_date_recontacted: _.get(encounter, ['ror_date_recontacted'], ''),
		ror_date_final_contacted: _.get(
			encounter,
			['ror_date_final_contacted'],
			''
		),
	};
	/* some time we are getting date and time in datetime fields so we are converting it */
	const updatedformdata = _.mapValues(tempFormData, function (value, key) {
		if (key in datefields) return getMomentDateOrStringNonStrick(value);
		return value;
	});

	const [formData, setFormData] = useState(updatedformdata);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const validateForm = (formdata) => {
		let errors = {};
		const datetimeerors = _.omitBy(
			_.mapValues(datefields, function (value, key) {
				if (!isValidDateStringOREmpty(_.get(formdata, key, ''))) {
					return 'Invalid date';
				}
				return undefined;
			}),
			_.isUndefined
		);

		errors = { ...datetimeerors };
		return errors;
	};

	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		let stateErrors = {};
		stateData[key] = value;
		if (!(key in datefields)) {
			stateErrors = validateForm(stateData);
		}
		setErrors(stateErrors);
		setFormData(stateData);
	};

	const handleDateFieldValidate = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		const errors = validateForm(stateData);
		setErrors(errors);
		setFormData(stateData);
	};

	const saveForm = () => {
		const errors = validateForm(formData);
		if (_.isEmpty(errors)) {
			const updatedformdata = _.mapValues(
				formData,
				function (value, key) {
					if (value == 'empty') return '';
					return value;
				}
			);

			setLoading(true);
			saveLabEncounter(encounter, updatedformdata)
				.then((result) => {
					setTimeout(() => {
						setLoading(false);
						cancel();
					}, 1000);
				})
				.catch((error) => {
					setLoading(false);
				});
		} else {
			setErrors(errors);
		}
	};

	return (
		<Loading loading={loading}>
			<div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								RoR Visit Status
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['ror_visit_status'],
										''
									)}
									name='ror_visit_status'
									options={createEnumsOptions(
										labsschema,
										['ror_visit_status'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('ror_visit_status', value)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className='segment-row segment-row-title'>
					<div className='segment-col'>
						RoR Follow-up Schedule Contact Date
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Initial
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Recontact
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>Final</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(
										formData,
										['ror_date_contacted'],
										''
									)}
									name='ror_date_contacted'
									error={_.get(
										errors,
										['ror_date_contacted'],
										''
									)}
									onChange={(value) =>
										onFormChange(
											'ror_date_contacted',
											value
										)
									}
									onBlur={(value) =>
										handleDateFieldValidate(
											'ror_date_contacted',
											value
										)
									}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(
										formData,
										['ror_date_recontacted'],
										''
									)}
									name='ror_date_recontacted'
									error={_.get(
										errors,
										['ror_date_recontacted'],
										''
									)}
									onChange={(value) =>
										onFormChange(
											'ror_date_recontacted',
											value
										)
									}
									onBlur={(value) =>
										handleDateFieldValidate(
											'ror_date_recontacted',
											value
										)
									}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(
										formData,
										['ror_date_final_contacted'],
										''
									)}
									name='ror_date_final_contacted'
									error={_.get(
										errors,
										['ror_date_final_contacted'],
										''
									)}
									onChange={(value) =>
										onFormChange(
											'ror_date_final_contacted',
											value
										)
									}
									onBlur={(value) =>
										handleDateFieldValidate(
											'ror_date_final_contacted',
											value
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className='segment-footer-container'>
					<div className='cancel-btn-link'>
						<Button onClick={cancel}>Cancel</Button>
					</div>
					<div className='save-btn-link'>
						<Button style={{ float: 'right' }} onClick={saveForm}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</Loading>
	);
};

export default EditRORSegment;
