import get from 'lodash/get';

import * as actionTypes from './actionTypes';
import CfDNAService from '../service/cfDNA';
import { hydrateDiagnosticReports } from '../transformers/cfDNATransformers';

const getDiagnosticReportsLoading = () => {
	return {
		type: actionTypes.GET_DIAGNOSTIC_REPORTS_LOADING,
	};
};

const getDiagnosticReportsSuccess = (payload) => {
	return {
		type: actionTypes.GET_DIAGNOSTIC_REPORTS_SUCCESS,
		payload,
	};
};

const getDiagnosticReportsError = (payload) => {
	return {
		type: actionTypes.GET_DIAGNOSTIC_REPORTS_ERROR,
		payload,
	};
};

export function getDiagnosticReports(testOrderID) {
	return (dispatch) => {
		dispatch(getDiagnosticReportsLoading());
		return CfDNAService.getDiagnosticReports(testOrderID)
			.then((res) => {
				dispatch(
					getDiagnosticReportsSuccess(
						hydrateDiagnosticReports(
							get(res, 'data.diagnosticReports', null)
						)
					)
				);
			})
			.catch((err) => {
				dispatch(getDiagnosticReportsError(err));
			});
	};
}

export const resetGetDiagnosticReports = () => {
	return {
		type: actionTypes.RESET_GET_DIAGNOSTIC_REPORTS,
	};
};

const addDiagnosticFindingLoading = () => {
	return {
		type: actionTypes.ADD_DIAGNOSTIC_FINDING_LOADING,
	};
};

const addDiagnosticFindingSuccess = (payload) => {
	return {
		type: actionTypes.ADD_DIAGNOSTIC_FINDING_SUCCESS,
		payload,
	};
};

const addDiagnosticFindingError = (payload) => {
	return {
		type: actionTypes.ADD_DIAGNOSTIC_FINDING_ERROR,
		payload,
	};
};

export function addDiagnosticFinding(data, diagnosticReportId) {
	return (dispatch) => {
		dispatch(addDiagnosticFindingLoading());
		return CfDNAService.addDiagnosticFinding(data, diagnosticReportId)
			.then((res) => {
				dispatch(
					addDiagnosticFindingSuccess(
						get(res, [
							'data',
							'addDiagnosticFinding',
							'finding',
							'id',
						])
					)
				);
			})
			.catch((err) => {
				dispatch(addDiagnosticFindingError(err));
			});
	};
}

export function createDiagnosticReportAndAddFindings(
	data,
	patientId,
	testOrderId
) {
	return (dispatch) => {
		dispatch(addDiagnosticFindingLoading());
		return CfDNAService.createDiagnosticReport(patientId, testOrderId)
			.then((res) => {
				const diagnosticReportId = get(
					res,
					['data', 'addDiagnosticReport', 'diagnosticReport', 'id'],
					null
				);
				dispatch(addDiagnosticFinding(data, diagnosticReportId));
			})
			.catch((err) => {
				dispatch(addDiagnosticFindingError(err));
			});
	};
}

export function updateDiagnosticFinding(data, diagnosticReportId) {
	return (dispatch) => {
		dispatch(addDiagnosticFindingLoading());
		return CfDNAService.updateDiagnosticFinding(data, diagnosticReportId)
			.then((res) => {
				dispatch(
					addDiagnosticFindingSuccess(
						get(res, [
							'data',
							'updateDiagnosticFinding',
							'finding',
							'id',
						])
					)
				);
			})
			.catch((err) => {
				dispatch(addDiagnosticFindingError(err));
			});
	};
}

export function deleteDiagnosticFinding(diagnosticFindingId) {
	return (dispatch) => {
		dispatch(addDiagnosticFindingLoading());
		return CfDNAService.deleteDiagnosticFinding(diagnosticFindingId)
			.then((res) => {
				dispatch(
					addDiagnosticFindingSuccess(
						get(res, [
							'data',
							'deleteDiagnosticFinding',
							'finding',
							'id',
						])
					)
				);
			})
			.catch((err) => {
				dispatch(addDiagnosticFindingError(err));
			});
	};
}

const addDiagnosticFindingClinicalNoteLoading = () => {
	return {
		type: actionTypes.ADD_DIAGNOSTIC_FINDING_CLINICAL_NOTE_LOADING,
	};
};

const addDiagnosticFindingClinicalNoteSuccess = (payload) => {
	return {
		type: actionTypes.ADD_DIAGNOSTIC_FINDING_CLINICAL_NOTE_SUCCESS,
		payload,
	};
};

const addDiagnosticFindingClinicalNoteError = (payload) => {
	return {
		type: actionTypes.ADD_DIAGNOSTIC_FINDING_CLINICAL_NOTE_ERROR,
		payload,
	};
};

export function addDiagnosticFindingClinicalNote(findingId, text) {
	return (dispatch) => {
		dispatch(addDiagnosticFindingClinicalNoteLoading());
		return CfDNAService.addDiagnosticFindingClinicalNote(findingId, text)
			.then((res) => {
				dispatch(
					addDiagnosticFindingClinicalNoteSuccess(
						get(res, [
							'data',
							'addDiagnosticFinding',
							'finding',
							'id',
						])
					)
				);
			})
			.catch((err) => {
				dispatch(addDiagnosticFindingClinicalNoteError(err));
			});
	};
}
