//React
import React, { useState, useEffect } from 'react';
import AuditTrailHistory from './AuditTrailHistory';

//UI Libraries
import { Select } from 'gm-element-react';

const AuditTrailData = ({ smsConsentLog, patientName, recontactConsentLog }) => {
	const [showHistory, setHistory] = useState(true);
	const [smsConsent, setsmsConsent] = useState('smsConsent');
	const [recontactConsent, setrecontactConsent] = useState('recontactConsent');
	const [historyObject, sethistoryObject] = useState('historyObject');
	const [selectedLog, setselectedLog] = useState('selectedLog');

	useEffect(() => {
		setselectedLog('smsConsent');
	}, []);


	const handleHistory = (value) => {
		setHistory(true);
		sethistoryObject(value == 'smsConsent'? smsConsentLog : recontactConsentLog);
		setselectedLog(value);
	};

	return (
		<div className='auditTrailDataContainer'>
			<div
				className='auditTrailSelectContainer'
				style={{ marginBottom: '30px' }}
			>
				<div className='auditTrailSelectRequired'>*</div>
				<div className='auditTrailSelectTitle'>Info Category</div>
				<Select
					onChange={(e) =>
						handleHistory(e)
					}
					value={'smsConsent'}
					className={''}
					placeholder={'Select'}
				>
					<Select.Option
						label='Text Message Consent'
						value='smsConsent'
					>
						<div>Text Message Consent</div>
					</Select.Option>
					<Select.Option
						label='Consent to Recontact'
						value='recontactConsent'
					>
						<div>Consent to Recontact</div>
					</Select.Option>
				</Select>
			</div>
			{showHistory && (
				<AuditTrailHistory
					smsConsentLog={smsConsentLog}
					recontactConsentLog = {recontactConsentLog}
					patientName={patientName}
					selectedLog = {selectedLog}
				/>
			)}
		</div>
	);
};

export default AuditTrailData;
