import React, { Component, Fragment } from 'react';

//Lodash
import { get, size, isEmpty, has } from 'lodash';

//UI Libraries
import { Radio } from 'gm-element-react';

//Images
import ReactSVG from 'react-svg';
import sortArrow from './back-to-top.svg';
import sortPopover from './results-per-page.svg';

//Other Libraries
import classnames from 'classnames';

export default class Th extends Component {
	constructor(props) {
		super(props);

		this.isSortable = !isEmpty(get(this, ['props', 'sortable'], {}));
		this.state = {
			popoverVisible: false,
		};

		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleSort = this.handleSort.bind(this);
	}

	componentDidMount() {
		if (this.isSortable)
			document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		if (this.isSortable)
			document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleClickOutside(event) {
		if (
			this.wrapperRef &&
			!this.wrapperRef.contains(event.target) &&
			this.state.popoverVisible
		) {
			this.setState({ popoverVisible: false });
		}
	}

	handleSort(event, newKey, newValue) {
		if (!get(this.props, 'sortBy')) {
			return;
		}

		event.stopPropagation();

		const {
			sortable,
			sortBy: { key, value },
		} = this.props;

		if (!isEmpty(sortable)) {
			if (size(sortable) > 1) {
				if (newKey && newValue) {
					this.setState({ popoverVisible: false });
					this.props.handleSort(newKey, newValue);
				} else
					this.setState({
						popoverVisible: !this.state.popoverVisible,
					});
			} else {
				newKey = Object.keys(sortable)[0];
				this.props.handleSort(
					newKey,
					key === newKey && value === 'asc' ? 'desc' : 'asc'
				);
			}
		}
	}

	renderSorting = () => {
		const { children } = this.props;

		if (this.isSortable) {
			const { popoverVisible } = this.state;
			const {
				sortable,
				sortBy: { key, value },
				handleSort,
				hasData,
			} = this.props;
			const isSorted = has(sortable, key);

			if (hasData && !isEmpty(sortable) && handleSort) {
				if (size(sortable) > 1) {
					return (
						<div
							className={classnames('sortable', {
								'is-sorted': isSorted,
							})}
						>
							<span>{children}</span>
							<div>
								<ReactSVG
									src={sortPopover}
									className={classnames('multiple', value, {
										invisible: !isSorted,
									})}
								/>
								{popoverVisible && (
									<div className='sortables'>
										{Object.keys(sortable).map(
											(optionKey) => (
												<div key={optionKey}>
													<span>
														{
															sortable[optionKey]
																.display_name
														}
													</span>
													<div>
														{Object.keys(
															sortable[optionKey]
																.sortBy
														).map((sortKey) => (
															<div
																onClick={(e) =>
																	this.handleSort(
																		e,
																		optionKey,
																		sortKey
																	)
																}
																key={sortKey}
																className='option'
															>
																<Radio
																	value={
																		sortKey
																	}
																	checked={
																		key ===
																			optionKey &&
																		value ===
																			sortKey
																	}
																>
																	{
																		sortable[
																			optionKey
																		]
																			.sortBy[
																			sortKey
																		]
																	}
																</Radio>
															</div>
														))}
													</div>
												</div>
											)
										)}
									</div>
								)}
							</div>
						</div>
					);
				}

				return (
					<div
						className={classnames('sortable', {
							'is-sorted': isSorted,
						})}
					>
						<span>{children}</span>
						<ReactSVG
							src={sortArrow}
							className={classnames('single', value, {
								invisible: !isSorted,
							})}
						/>
					</div>
				);
			}
		}

		return <span>{children}</span>;
	};

	render() {
		return (
			<th
				ref={this.setWrapperRef}
				className={classnames(
					get(this.props, ['className'], ''),
					!this.isSortable || !get(this, ['props', 'hasData'], true)
						? 'no-hover'
						: get(this, ['state', 'popoverVisible'], false)
						? 'focused'
						: ''
				)}
				onClick={this.handleSort}
			>
				{this.renderSorting()}
			</th>
		);
	}
}
