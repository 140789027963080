//React & Redux
import React, { useState } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	GetSelectvalue,
	createEnumsOptions,
	DatePickerValue,
	CascaderOptions,
	findCascaderHierarchy,
	isValidDateStringOREmpty,
	getMomentDateOrStringNonStrick,
} from '../Common/utilsfunctions';

//Other Libraries
import moment from 'moment';

//Components
import { Button, Loading } from 'gm-element-react';
import { EncounterSelectField } from '../Common/EncounterSelectField';
import { EncounterDateField } from '../Common/EncounterDateField';
import { EncounterCascaderField } from '../Common/EncounterCascaderField';

const datefields = {
	date_authorized_via_lab_portal: 1,
	expected_lab_results_date: 1,
	date_received_report: 1,
	date_results_released_to_portal: 1,
	date_results_released_to_patient: 1,
	cap_sent_to_patient: 1,
	blood_draw_order_date: 1,
};
const cascaderfields = {
	order_request_status: 1,
	order_status: 1,
};
const EditTestOrderSegment = (props) => {
	const {
		saveLabEncounter,
		encounter,
		cancel,
		orderrequeststatuscascadeenums,
		labsschema,
		ordering_physicianenums,
	} = props;
	const tempFormData = {
		order_status: _.get(encounter, ['order_status'], ''),
		order_request_status: _.get(encounter, ['order_request_status'], ''),
		expected_lab_results_date: _.get(
			encounter,
			['expected_lab_results_date'],
			''
		),
		date_authorized_via_lab_portal: _.get(
			encounter,
			['date_authorized_via_lab_portal'],
			''
		),
		date_received_report: _.get(encounter, ['date_received_report'], ''),
		date_results_released_to_portal: _.get(
			encounter,
			['date_results_released_to_portal'],
			''
		),
		date_results_released_to_patient: _.get(
			encounter,
			['date_results_released_to_patient'],
			''
		),
	};
	/* some time we are getting date and time in datetime fields so we are converting it */
	const updatedformdata = _.mapValues(tempFormData, function (value, key) {
		if (key in datefields) return getMomentDateOrStringNonStrick(value);
		return value;
	});

	const [formData, setFormData] = useState(updatedformdata);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	const isExpectedLabResultsDateEditable = () => {
		return _.get(encounter, ['date_test_ordered', 'length'], 0) > 0;
	};

	const validateForm = (formdata) => {
		let errors = {};
		const dateerors = _.omitBy(
			_.mapValues(datefields, function (value, key) {
				if (!isValidDateStringOREmpty(_.get(formdata, key, ''))) {
					return 'Invalid date';
				}
				return undefined;
			}),
			_.isUndefined
		);
		errors = { ...dateerors };
		if (
			formdata['order_status'] == 'results_ready' &&
			_.get(formdata, ['date_received_report', 'length'], 0) == 0
		) {
			errors['date_received_report'] = 'Report Receipt Date is required';
		}
		return errors;
	};
	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		let stateErrors = {};
		stateData[key] = value;
		if (key in cascaderfields) {
			stateData[key] = _.last(value);
		}
		if (!(key in datefields)) {
			stateErrors = validateForm(stateData);
		}
		setErrors(stateErrors);
		setFormData(stateData);
	};

	const handleDateFieldValidate = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		const errors = validateForm(stateData);
		setErrors(errors);
		setFormData(stateData);
	};

	const saveForm = () => {
		const errors = validateForm(formData);
		if (_.isEmpty(errors)) {
			const updatedformdata = _.mapValues(
				formData,
				function (value, key) {
					if (key in datefields && _.isNil(value)) return '';
					if (key in cascaderfields) {
						if (_.isArray(value)) {
							return _.last(value);
						}
					}
					if (value == 'empty') return '';
					return value;
				}
			);
			setLoading(true);
			saveLabEncounter(encounter, updatedformdata)
				.then((result) => {
					setTimeout(() => {
						setLoading(false);
						cancel();
					}, 1000);
				})
				.catch((error) => {
					setLoading(false);
				});
		} else {
			setErrors(errors);
		}
	};

	return (
		<Loading loading={loading}>
			<div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Order Request Status
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>Lab</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Test Name
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Ordering Physician
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Test Authorization Date
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterCascaderField
									name={'order_request_status'}
									disabled={true}
									value={findCascaderHierarchy(
										orderrequeststatuscascadeenums,
										'key',
										_.get(
											formData,
											['order_request_status'],
											''
										)
									)}
									options={CascaderOptions(
										orderrequeststatuscascadeenums,
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'order_request_status',
											value
										)
									}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={GetSelectvalue(
										encounter,
										['lab'],
										_.get(labsschema, ['lab'], [])
									)}
									name='lab'
									disabled={true}
									options={[
										{
											label: GetSelectvalue(
												encounter,
												['lab'],
												_.get(labsschema, ['lab'], [])
											),
											key: GetSelectvalue(
												encounter,
												['lab'],
												_.get(labsschema, ['lab'], [])
											),
											value: GetSelectvalue(
												encounter,
												['lab'],
												_.get(labsschema, ['lab'], [])
											),
										},
									]}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={GetSelectvalue(
										encounter,
										['type_of_test'],
										_.get(labsschema, ['type_of_test'], [])
									)}
									name='type_of_test'
									disabled={true}
									options={[
										{
											label: GetSelectvalue(
												encounter,
												['type_of_test'],
												_.get(
													labsschema,
													['type_of_test'],
													[]
												)
											),
											key: GetSelectvalue(
												encounter,
												['type_of_test'],
												_.get(
													labsschema,
													['type_of_test'],
													[]
												)
											),
											value: GetSelectvalue(
												encounter,
												['type_of_test'],
												_.get(
													labsschema,
													['type_of_test'],
													[]
												)
											),
										},
									]}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={GetSelectvalue(
										encounter,
										['ordering_physician'],
										ordering_physicianenums
									)}
									name='ordering_physician'
									disabled={true}
									options={[
										{
											label: GetSelectvalue(
												encounter,
												['ordering_physician'],
												ordering_physicianenums
											),
											key: GetSelectvalue(
												encounter,
												['ordering_physician'],
												ordering_physicianenums
											),
											value: GetSelectvalue(
												encounter,
												['ordering_physician'],
												ordering_physicianenums
											),
										},
									]}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={DatePickerValue(encounter, [
										'date_test_authorized',
									])}
									name='date_test_authorized'
									disabled={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Order Status
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Test Dispatch Date
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Expected Lab Result Date
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Authorized Via Lab Portal
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Report Receipt Date
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										encounter,
										['order_status'],
										''
									)}
									name='order_status'
									disabled={true}
									options={createEnumsOptions(
										labsschema,
										['order_status_lab'],
										'key',
										'display_name',
										'key'
									)}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={DatePickerValue(encounter, [
										'date_test_ordered',
									])}
									name='date_test_ordered'
									disabled={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(
										formData,
										['expected_lab_results_date'],
										''
									)}
									name='expected_lab_results_date'
									disabled={
										!isExpectedLabResultsDateEditable()
									}
									onChange={(value) =>
										onFormChange(
											'expected_lab_results_date',
											value
										)
									}
									disabledDate={(current) => {
										const yesterday = _.get(
											encounter,
											'date_test_ordered'
										);
										const momentcurrent = moment(current);
										if (!_.isNil(yesterday))
											return momentcurrent.isAfter(
												moment(yesterday)
													.clone()
													.add(34, 'days')
											);
										else return true;
									}}
									error={_.get(
										errors,
										['expected_lab_results_date'],
										''
									)}
									onBlur={(value) =>
										handleDateFieldValidate(
											'expected_lab_results_date',
											value
										)
									}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(
										formData,
										['date_authorized_via_lab_portal'],
										''
									)}
									name='date_authorized_via_lab_portal'
									onChange={(value) =>
										onFormChange(
											'date_authorized_via_lab_portal',
											value
										)
									}
									error={_.get(
										errors,
										['date_authorized_via_lab_portal'],
										''
									)}
									onBlur={(value) =>
										handleDateFieldValidate(
											'date_authorized_via_lab_portal',
											value
										)
									}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={DatePickerValue(
										encounter,
										['date_received_report'],
										''
									)}
									name='date_received_report'
									disabled={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Results Released To Portal?
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Result Release Date
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(
										formData,
										['date_results_released_to_portal'],
										''
									)}
									name='date_results_released_to_portal'
									onChange={(value) =>
										onFormChange(
											'date_results_released_to_portal',
											value
										)
									}
									error={_.get(
										errors,
										['date_results_released_to_portal'],
										''
									)}
									onBlur={(value) =>
										handleDateFieldValidate(
											'date_results_released_to_portal',
											value
										)
									}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(
										formData,
										['date_results_released_to_patient'],
										''
									)}
									name='date_results_released_to_patient'
									onChange={(value) =>
										onFormChange(
											'date_results_released_to_patient',
											value
										)
									}
									error={_.get(
										errors,
										['date_results_released_to_patient'],
										''
									)}
									onBlur={(value) =>
										handleDateFieldValidate(
											'date_results_released_to_patient',
											value
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-footer-container'>
					<div className='cancel-btn-link'>
						<Button onClick={cancel}>Cancel</Button>
					</div>
					<div className='save-btn-link'>
						<Button style={{ float: 'right' }} onClick={saveForm}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</Loading>
	);
};

export default EditTestOrderSegment;
