//React & Redux
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

//Action & Services
import { getencountervisitsschema } from './../../../actions/encounter';
import { getReferralPrograms } from '../../../actions/patients';
import {
	LoadAppointmentSlots,
	RescheduleInitialData,
	RescheduleBookAppointment,
	ResetRescheduleAppointmentData,
} from './../../../actions/scheduling';

//Lodash
import _ from 'lodash';
import moment from 'moment';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import RescheduleStage from './RescheduleStage';
import RescheduleAppointmentSidebar from './rescheduleAppointmentSidebar';
import RescheduleConfirmDialog from './RescheduleConfirmDialog';
import RescheduleReasonDialog from './RescheduleReasonDialog';
import AppointmentErrorDialog from '../AppointmentErrorDialog';

const defaultSelectedState = {
	state: undefined,
	modality: undefined,
	provider_id: undefined,
	start_time: undefined,
	timezone: undefined,
	vsee_specialty: undefined,
	end_time: undefined,
};

class RescheduleAppointment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedSlot: { ...defaultSelectedState },
			reschedule_reason: undefined,
			show_rescheduleconfirmdialog: false,
			show_reschedulereasondialog: false,
			showErrorDialog: false,
			error: '',
			errorType: '',
			loading: false,
		};

		//bindings
		this.setSelectedSlotParams = this.setSelectedSlotParams.bind(this);
		this.callRescheduleAppointment =
			this.callRescheduleAppointment.bind(this);
		this.clearSelectedSlotParams = this.clearSelectedSlotParams.bind(this);
		this.setModality = this.setModality.bind(this);
		this.handleNavigateToAppointmentList =
			this.handleNavigateToAppointmentList.bind(this);
		this.closeRescheduleConfirmDialog =
			this.closeRescheduleConfirmDialog.bind(this);
		this.setRescheduleReason = this.setRescheduleReason.bind(this);
		this.showRescheduleConfirmDialog =
			this.showRescheduleConfirmDialog.bind(this);
		this.handleConfirmDialogClick =
			this.handleConfirmDialogClick.bind(this);
		this.errorCloseHandler = this.errorCloseHandler.bind(this);
		this.RescheduleReasonDialogCancel =
			this.RescheduleReasonDialogCancel.bind(this);
		this.hasAppointmentDetail = this.hasAppointmentDetail.bind(this);
	}
	//functions
	componentDidMount() {
		const EncounterUUID = _.get(
			this,
			['props', 'match', 'params', 'encounter_uuid'],
			0
		);
		this.props.rescheduleInitialData(EncounterUUID);
		this.props.getRefPrograms();
	}

	componentDidUpdate(preProps) {
		if (
			_.get(this.props, 'partnerDetail') !=
			_.get(preProps, 'partnerDetail')
		) {
			if (
				_.get(
					this.props,
					['partnerDetail', 'isSelectedSpecialties'],
					false
				)
			) {
				this.props.getVisitSchema({
					partner_uuid: _.get(this.props, [
						'partnerDetail',
						'partnerUUID',
					]),
				});
			} else {
				this.props.getVisitSchema();
			}
		}
		const { slotsLoading, slotsError } = this.props.Scheduling;

		if (
			slotsLoading != _.get(preProps, ['Scheduling', 'slotsLoading']) &&
			!slotsLoading
		) {
			if (
				!_.isNil(slotsError) &&
				_.get(slotsError, ['code']) == 'pioneer_configuration_error'
			) {
				this.setState({
					error: _.get(slotsError, 'message'),
					showErrorDialog: true,
					errorType: 'pioneer_configuration_error',
				});
			}
		}
	}

	componentWillUnmount() {
		this.props.resetRescheduleAppointmentData();
	}

	setSelectedSlotParams(data) {
		const selectedSlot = { ...this.state.selectedSlot };
		selectedSlot['state'] = data.state;
		selectedSlot['modality'] = data.modality;
		selectedSlot['provider_id'] = data.provider_id;
		selectedSlot['start_time'] = data.start_time;
		selectedSlot['end_time'] = data.end_time;
		selectedSlot['timezone'] = data.timezone;
		selectedSlot['vsee_specialty'] = data.vsee_specialty;
		this.setState({ selectedSlot });
	}
	clearSelectedSlotParams() {
		this.setState({ selectedSlot: { ...defaultSelectedState } });
	}
	setRescheduleReason(value) {
		this.setState({ reschedule_reason: value });
	}
	setModality(value) {
		const selectedSlot = { ...this.state.selectedSlot };
		selectedSlot['modality'] = value;
		this.setState({ selectedSlot });
	}

	callRescheduleAppointment() {
		const encounterUUID = _.get(this, ['props', 'encounterdetail', 'uuid']);
		const {
			selectedSlot: {
				modality,
				provider_id,
				start_time,
				state,
				timezone,
				vsee_specialty,
			},
			reschedule_reason,
		} = this.state;
		const data = {
			by_phone: modality,
			provider_id: provider_id,
			reschedule_reason: reschedule_reason,
			start_time: start_time,
			state: state,
			timezone: timezone,
			vsee_specialty: vsee_specialty,
		};
		this.handleRescheduleReasonDialog(false);
		this.setState({ loading: true });
		this.props
			.rescheduleBookAppointment(encounterUUID, data)
			.then(() => {
				this.setState({ loading: false });
				this.handleNavigateToAppointmentList();
			})
			.catch((error) => {
				const defaultErrorMessage = 'Invalid request';
				let customError = _.get(
					error,
					['response', 'data', 'message'],
					defaultErrorMessage
				);
				try {
					// If error message is a JSON string, attempt to extract
					// inner message.
					const parsedError = JSON.parse(customError);
					customError = parsedError?.message || defaultErrorMessage;
				} finally {
					this.setState({
						loading: false,
						error: customError,
						showErrorDialog: true,
					});
				}
			});
	}
	//confirm dialog confirm button
	handleConfirmDialogClick() {
		this.closeRescheduleConfirmDialog();
		this.handleRescheduleReasonDialog(true);
	}
	//confirm dialog show
	showRescheduleConfirmDialog() {
		this.setState({ show_rescheduleconfirmdialog: true });
	}
	//confirm dialog hide
	closeRescheduleConfirmDialog() {
		this.setState({ show_rescheduleconfirmdialog: false });
	}
	//error dialog hide/show
	errorCloseHandler() {
		this.setState({
			showErrorDialog: false,
			error: '',
			errorType: '',
		});
	}

	//reason dialog hide/show
	handleRescheduleReasonDialog(value) {
		this.setState({ show_reschedulereasondialog: value });
	}
	//reason dialog cancel
	RescheduleReasonDialogCancel() {
		this.setRescheduleReason(undefined);
		this.handleRescheduleReasonDialog(false);
	}
	handleNavigateToAppointmentList() {
		this.props.history.push('/app/appointments');
	}

	hasAppointmentDetail() {
		return (
			!_.isEmpty(_.get(this, ['props', 'encounterdetail'], {})) &&
			!_.isEmpty(_.get(this, ['props', 'patientDetail'], {})) &&
			_.get(this, ['props', 'specialties', 'length'], 0) > 0
		);
	}

	isWarningSummary() {
		if (
			!_.isNil(
				_.get(this, ['state', 'selectedSlot', 'timezone'], null)
			) &&
			_.get(this, ['state', 'selectedSlot', 'start_time']) &&
			_.get(this, ['state', 'selectedSlot', 'end_time'])
		) {
			const timezone = _.get(this, ['state', 'selectedSlot', 'timezone']);
			const start = moment(this.state.selectedSlot.start_time);
			const currenttimeOfTimezone = moment(new Date()).tz(timezone);
			const starttimeOfTimezone = moment.tz(
				moment(start).format('YYYY-MM-DDTHH:mm:ss'),
				timezone
			);
			const duration = moment.duration(
				starttimeOfTimezone.diff(currenttimeOfTimezone)
			);
			const hours = duration.asHours();
			return hours <= 8;
		}
		return false;
	}

	render() {
		return (
			<Fragment>
				<div className='scheduleAppointmentContainer'>
					<Loading
						loading={
							!this.hasAppointmentDetail() || this.state.loading
						}
						className='reschedule-aptmnt-loader'
					>
						<div className='reschedule-appointment-wrapper'>
							<div className='scheduleAppointmentMainContainer'>
								<div className='scheduleAppointmentNavRow'>
									<div className='scheduleAppointmentNavHeaderSelected'>
										Reschedule
									</div>
								</div>
								<div className='scheduleAppointmentContentContainer'>
									{!_.isEmpty(
										_.get(
											this,
											['props', 'encounterdetail'],
											{}
										)
									) &&
									!_.isEmpty(
										_.get(
											this,
											['props', 'patientDetail'],
											{}
										)
									) &&
									_.get(
										this,
										['props', 'specialties', 'length'],
										0
									) > 0 ? (
										<RescheduleStage
											patientDetail={
												this.props.patientDetail
											}
											Scheduling={this.props.Scheduling}
											modalities={this.props.modalities}
											LoadAppointmentSlot={
												this.props.LoadAppointmentSlot
											}
											encounterdetail={
												this.props.encounterdetail
											}
											billingencounterdetail={
												this.props
													.billingencounterdetail
											}
											setSelectedSlotParams={
												this.setSelectedSlotParams
											}
											clearSelectedSlotParams={
												this.clearSelectedSlotParams
											}
											selectedSlot={
												this.state.selectedSlot
											}
											showRescheduleConfirmDialog={
												this.showRescheduleConfirmDialog
											}
											setModality={this.setModality}
											handleNavigateToAppointmentList={
												this
													.handleNavigateToAppointmentList
											}
											specialties={this.props.specialties}
											partnerDetail={
												this.props.partnerDetail
											}
										/>
									) : null}
								</div>
							</div>
							<React.Fragment>
								<RescheduleAppointmentSidebar
									patientDetail={this.props.patientDetail}
									encounterdetail={this.props.encounterdetail}
									specialties={this.props.specialties}
									billingencounterdetail={
										this.props.billingencounterdetail
									}
									isWarningSummary={this.isWarningSummary()}
									referralPrograms={
										this.props.referralPrograms
									}
								/>
							</React.Fragment>
							<Fragment>
								<RescheduleConfirmDialog
									closeRescheduleConfirmDialog={
										this.closeRescheduleConfirmDialog
									}
									show_rescheduleconfirmdialog={
										this.state.show_rescheduleconfirmdialog
									}
									handleNavigateToAppointmentList={
										this.handleNavigateToAppointmentList
									}
									details={{
										consultation_type: _.get(
											this,
											[
												'props',
												'encounterdetail',
												'consultation_type',
											],
											''
										),
										start_time:
											this.state.selectedSlot.start_time,
										end_time:
											this.state.selectedSlot.end_time,
										state: this.state.selectedSlot.state,
										vsee_specialty:
											this.state.selectedSlot
												.vsee_specialty,
										modality:
											this.state.selectedSlot.modality,
										provider_id:
											this.state.selectedSlot.provider_id,
										timezone:
											this.state.selectedSlot.timezone,
									}}
									specialties={this.props.specialties}
									providers={_.get(
										this,
										['props', 'Scheduling', 'providers'],
										[]
									)}
									handleConfirmDialogClick={
										this.handleConfirmDialogClick
									}
								/>
								<RescheduleReasonDialog
									RescheduleReasonDialogCancel={
										this.RescheduleReasonDialogCancel
									}
									show_reschedulereasondialog={
										this.state.show_reschedulereasondialog
									}
									setRescheduleReason={
										this.setRescheduleReason
									}
									reschedule_reason={
										this.state.reschedule_reason
									}
									callRescheduleAppointment={
										this.callRescheduleAppointment
									}
								/>

								<AppointmentErrorDialog
									showErrorDialog={this.state.showErrorDialog}
									errorCloseHandler={this.errorCloseHandler}
									error={this.state.error}
									errorType={this.state.errorType}
									errorTitle={
										'Appointment Rescheduling Failed'
									}
									initialErrorMessage={
										'We were unable to schedule this appointment because'
									}
								/>
							</Fragment>
						</div>
					</Loading>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		Scheduling: state.Scheduling,
		patientDetail: _.get(
			state,
			['patientdetail', 'patientdetail', '0'],
			{}
		),
		modalities: _.get(
			state,
			['visitsschema', 'schema', 'data', 'tm_platform'],
			[]
		),
		specialties: _.get(
			state,
			['visitsschema', 'schema', 'data', 'vsee_specialty'],
			[]
		),
		encounterdetail: _.get(state, ['encounterdetail', 'encounter'], {}),
		billingencounterdetail: _.get(
			state,
			['getBillingEncounterData', 'billingobject'],
			{}
		),
		partnerDetail: _.get(
			state,
			['referralProgramsList', 'loadedReferralProgramDetail'],
			{}
		),
		referralPrograms: _.get(
			state,
			['referralPrograms', 'referralPrograms'],
			[]
		),
	};
};
const mapDispatchToProps = (dispatch) => ({
	LoadAppointmentSlot: (data) => dispatch(LoadAppointmentSlots(data)),
	getRefPrograms: () => dispatch(getReferralPrograms()),
	getVisitSchema: (params) => dispatch(getencountervisitsschema(params)), // list modality, vsee,
	rescheduleInitialData: (uuid) => dispatch(RescheduleInitialData(uuid)),
	rescheduleBookAppointment: (encounterUUID, data) =>
		RescheduleBookAppointment(encounterUUID, data),
	resetRescheduleAppointmentData: () =>
		dispatch(ResetRescheduleAppointmentData()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RescheduleAppointment);
