//React & Redux
import React, { Component } from 'react';

//Lodash
import _ from 'lodash';

//Components
import ICDSearch from './ICDSearch.js';
import ICDSelected from './ICDSelected.js';

const maxLimitBrowser = 500;

class ICDSelectionComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchQuery: '',
			icdCodes: [],
			initialQueryLoading: false,
			topLoading: false,
			bottomLoading: false,
		};
		this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this);
		this.toggleCodeSelection = this.toggleCodeSelection.bind(this);
		this.toggleCodeSelectionOn = this.toggleCodeSelectionOn.bind(this);
		this.toggleCodeSelectionOff = this.toggleCodeSelectionOff.bind(this);
		this.sortAndSetSelectedCodes = this.sortAndSetSelectedCodes.bind(this);
		this.lazyLoadTop = this.lazyLoadTop.bind(this);
		this.lazyLoadBottom = this.lazyLoadBottom.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			_.get(prevState, 'searchQuery', '') !==
			_.get(this, 'state.searchQuery', '')
		) {
			if (
				!_.get(this, 'state.topLoading', false) &&
				!_.get(this, 'state.bottomLoading', false)
			) {
				if (!_.get(this, 'state.queryEntered', true))
					this.setState({ queryEntered: true });
				this.setState({ queryLoading: true });
				this.getICDCodesDebounced();
			}
		}

		if (
			_.get(prevState, 'searchQuery.length', 0) === 0 &&
			_.get(this, 'state.searchQuery.length', 0) > 0
		) {
			this.setState({ initialQueryLoading: true });
		}

		if (
			_.get(this, 'props.icdCodes.query', '') === this.state.searchQuery
		) {
			if (_.get(this, 'state.initialQueryLoading', true)) {
				this.setState({ initialQueryLoading: false });
			}

			if (
				_.get(this, 'props.icdCodesError', false) &&
				!(
					_.get(this, 'state.topLoading', false) ||
					_.get(this, 'state.bottomLoading', false) ||
					_.get(this, 'state.queryLoading', false)
				)
			) {
				this.props.enqueueSnackbar('Error in getting ICD-10 codes', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				this.setState({
					bottomLoading: false,
					topLoading: false,
					queryLoading: false,
				});
			}

			if (!_.get(this, 'state.initialModalLoadComplete', false)) {
				this.setState({ initialModalLoadComplete: true });
			}
		}

		if (!_.get(this, 'props.icdCodesLoading', false)) {
			if (
				_.get(this, 'props.icdCodes.query', '') ===
				this.state.searchQuery
			) {
				if (
					_.get(this, 'state.topLoading', false) ||
					_.get(this, 'state.bottomLoading', false) ||
					this.state.queryLoading
				) {
					if (
						_.get(this, 'props.icdCodesPlacement', null) === 'top'
					) {
						this.setICDCodesTop(
							_.get(this, 'props.icdCodes', { data: [] })
						);
					} else if (
						_.get(this, 'props.icdCodesPlacement', null) ===
						'bottom'
					) {
						this.setICDCodesBottom(
							_.get(this, 'props.icdCodes', { data: [] })
						);
					} else {
						if (
							!(
								_.get(this, 'state.topLoading', false) ||
								_.get(this, 'state.bottomLoading', false)
							) &&
							this.state.queryLoading
						) {
							this.setICDCodes(
								_.get(this, 'props.icdCodes', { data: [] })
							);
						}
					}
				}
			}
		}
	}

	handleSearchQueryChange(str) {
		this.setState({ searchQuery: str });
	}

	toggleCodeSelection(status, uuid, icdCode) {
		if (status) {
			this.toggleCodeSelectionOn(uuid, icdCode);
		} else {
			this.toggleCodeSelectionOff(uuid, icdCode);
		}
	}

	toggleCodeSelectionOn(uuid, icdCode) {
		const currentCodes = this.props.selectedICDCodes || [];
		const filteredCodes = currentCodes.filter(
			(el) => _.get(el, 'uuid', '') === uuid
		);
		if (filteredCodes.length === 0) {
			currentCodes.push(icdCode);
			this.sortAndSetSelectedCodes(currentCodes);
		}
	}

	toggleCodeSelectionOff(uuid, icdCode) {
		const currentCodes = this.props.selectedICDCodes || [];
		_.remove(currentCodes, (el) => _.get(el, 'uuid', '') === uuid);
		this.sortAndSetSelectedCodes(currentCodes);
	}

	sortAndSetSelectedCodes(selectedCodes) {
		if (Array.isArray(selectedCodes)) {
			selectedCodes.sort((a, b) => {
				if (_.get(a, 'code_name', '') > _.get(b, 'code_name', '')) {
					return 1;
				} else {
					return -1;
				}
			});
		}
		this.props.setSelectedICDCodes(selectedCodes);
	}

	lazyLoadTop() {
		if (!this.alreadyLoading() && _.get(this, 'state.has_prev', false)) {
			this.getICDCodes('top');
			this.setState({ topLoading: true });
		}
	}

	lazyLoadBottom() {
		if (!this.alreadyLoading() && _.get(this, 'state.has_next', true)) {
			this.getICDCodes('bottom');
			this.setState({ bottomLoading: true });
		}
	}

	alreadyLoading() {
		return this.state.topLoading || this.state.bottomLoading;
	}

	setICDCodes(icdCodes) {
		this.setState({
			icdCodes: _.get(icdCodes, 'data', []) || [],
			currentOffset: _.get(icdCodes, 'offset', 0) || 0,
			start: _.get(icdCodes, 'start', 0) || 0,
			end: _.get(icdCodes, 'end', 99) || 99,
			total: _.get(icdCodes, 'total', 100) || 100,
			has_next: _.get(icdCodes, 'has_next', false) || false,
			has_prev: _.get(icdCodes, 'has_prev', false) || false,
			topLoading: false,
			bottomLoading: false,
			queryLoading: false,
		});
	}

	setICDCodesTop(icdCodes) {
		this.setState(
			{
				has_prev: _.get(
					icdCodes,
					'has_prev',
					_.get(this, 'state.has_prev', false)
				),
				topLoading: false,
				queryLoading: false,
			},
			async () => {
				await this.prependResults(icdCodes);
			}
		);
	}

	prependResults(icdCodes) {
		return new Promise((resolve, reject) => {
			const codes = _.get(icdCodes, 'data', []) || [];
			const codesTotal = _.get(icdCodes, 'total', 0) || 0;
			let newCodes = codes.concat(
				_.get(this, 'state.icdCodes', []) || []
			);
			const size = newCodes.length;
			const icdStart = _.get(icdCodes, 'start', 0);
			const currentStart = _.get(this, 'state.start', 0);
			const sizeDiff = currentStart - icdStart;
			const currentEnd = _.get(this, 'state.end', 0);
			if (sizeDiff > 0) this.setState({ end: currentEnd - sizeDiff });
			if (size > maxLimitBrowser)
				newCodes = newCodes.slice(0, maxLimitBrowser);
			if (currentEnd + 1 < codesTotal) this.setState({ has_next: true });
			this.setState(
				{
					icdCodes: newCodes,
					start: Math.min(
						_.get(icdCodes, 'start', 0) || 0,
						_.get(this, 'state.start', 0)
					),
				},
				resolve
			);
		});
	}

	setICDCodesBottom(icdCodes) {
		this.setState(
			{
				end: Math.max(
					_.get(icdCodes, 'end', 0) || 0,
					_.get(this, 'state.end', 0)
				),
				has_next: _.get(
					icdCodes,
					'has_next',
					_.get(this, 'state.has_next', true)
				),
				bottomLoading: false,
				queryLoading: false,
			},
			async () => {
				await this.appendResults(icdCodes);
			}
		);
	}

	appendResults(icdCodes) {
		return new Promise((resolve, reject) => {
			const codes = _.get(icdCodes, 'data', []) || [];
			let newCodes = (_.get(this, 'state.icdCodes', []) || []).concat(
				codes
			);
			const size = newCodes.length;
			const icdEnd = _.get(icdCodes, 'end', 100);
			const newStart = icdEnd - maxLimitBrowser + 1;
			if (newStart > 0)
				this.setState({ start: newStart, has_prev: true });
			if (size > maxLimitBrowser)
				newCodes = newCodes.slice(maxLimitBrowser * -1);
			this.setState({ icdCodes: newCodes }, resolve);
		});
	}

	getICDCodes(topOrBottom) {
		const searchQuery = _.get(this, 'state.searchQuery', '') || '';
		const currentOffset = this.getNewOffset(topOrBottom);
		const currentLimit = this.getNewLimit(topOrBottom);
		if (topOrBottom === 'bottom') this.setState({ bottomLoading: true });
		if (topOrBottom === 'top') this.setState({ topLoading: true });
		this.props.getICDCodes(
			searchQuery,
			currentOffset,
			currentLimit,
			topOrBottom
		);
	}

	getICDCodesDebounced() {
		const searchQuery = _.get(this, 'state.searchQuery', '') || '';
		const currentOffset = 0;
		const currentLimit = _.get(this, 'state.currentLimit', 100);
		this.props.getICDCodes(searchQuery, currentOffset, currentLimit);
	}

	getNewOffset(topOrBottom) {
		let offset;
		if (!topOrBottom) offset = _.get(this, 'state.currentOffset', 0);
		else if (topOrBottom === 'top')
			offset = Math.max(
				_.get(this, 'state.start', 0) -
					_.get(this, 'state.currentLimit', 100),
				0
			);
		else if (topOrBottom === 'bottom')
			offset = _.get(this, 'state.end', 100) + 1;
		else return 0;
		return offset;
	}

	getNewLimit(topOrBottom) {
		if (topOrBottom !== 'top')
			return _.get(this, 'state.currentLimit', 100);
		else {
			if (_.get(this, 'state.start', 0) < 100)
				return _.get(this, 'state.start', 0) + 1;
			else return 100;
		}
	}
	render() {
		return (
			<div
				className='chargeICDSearchAndSelectContainer'
				style={{ height: '100%' }}
			>
				<ICDSearch
					searchQuery={this.state.searchQuery}
					selectedICDCodes={this.props.selectedICDCodes}
					handleSearchQueryChange={this.handleSearchQueryChange}
					icdCodes={this.state.icdCodes}
					icdCodesError={this.props.icdCodesError}
					icdCodesLoading={this.props.icdCodesLoading}
					toggleCodeSelection={this.toggleCodeSelection}
					initialQueryLoading={this.state.initialQueryLoading}
					topLoading={this.state.topLoading}
					bottomLoading={this.state.bottomLoading}
					lazyLoadTop={this.lazyLoadTop}
					lazyLoadBottom={this.lazyLoadBottom}
					encounterICDcodes={this.props.encounterICDcodes || []}
				/>
				<ICDSelected
					selectedICDCodes={this.props.selectedICDCodes}
					toggleCodeSelection={this.toggleCodeSelection}
				/>
			</div>
		);
	}
}
export default ICDSelectionComponent;
