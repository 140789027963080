//React & Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';

//Lodash
import get from 'lodash/get';

//Utils
import { EncounterTabs } from '../../utils';

//Actions & Services
import {
	getencountergroupsessionschema,
	getencounterccintakeschema,
	getencountervisitsschema,
	getencounterlabseschema,
	getspecificencounterenum,
	getencounterresearchdataschema,
} from '../../actions/encounter';
import {
	getReferralProgramDetail,
} from '../../actions/referralprograms/referralprograms';

//Components
import EncounterDetail from './V2/Encounters/EncounterDetail';
import EncounterListContainer from './EncounterListContainer';

class EncounterTab extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reloadProviders: false,
		};
		this.setReloadProviders = this.setReloadProviders.bind(this);
	}

	componentDidMount() {
		const partnerUUID = get(this, 'props.patientdetail[0].partner_uuid', 0)
		this.props.resetPaginationCounter();
		this.props.getReferralProgramDetails({
			partner_uuid: partnerUUID,
		});
	}

	handleCreateEncounter(e) {
		const tabtype = get(this, 'props.match.params.tabtype', 0);
		this.props.history.push(
			'/app/patientdetail/' +
				get(this, 'props.patientdetail[0].uuid', 0) +
				'/' +
				tabtype +
				'/1'
		);
	}

	setReloadProviders(flag) {
		this.setState({ reloadProviders: flag });
	}

	getcomponent() {
		const value = this.props.encounterstep;
		switch (value) {
			case EncounterTabs.DETAIL:
				return (
					<EncounterDetail
						enqueueSnackbar={this.props.enqueueSnackbar}
						changeEncounterTab={this.props.changeEncounterTab}
						match={this.props.match}
						openAppointmentAlert={this.props.openAppointmentAlert}
						encounterCompleteLoading={
							this.props.encounterCompleteLoading
						}
						encounterCancelLoading={
							this.props.encounterCancelLoading
						}
						completeEncounter={this.props.completeEncounter}
						closeAlert={this.props.closeAlert}
						getPatientDetail={this.props.getPatientDetail}
						encounterCompleteError={
							this.props.encounterCompleteError
						}
						icdCodes={this.props.icdCodes}
						icdCodesLoading={this.props.icdCodesLoading}
						icdCodesError={this.props.icdCodesError}
						icdCodesPlacement={this.props.icdCodesPlacement}
						getICDCodes={this.props.getICDCodes}
						getEncounterList={this.props.getEncounterList}
						history={this.props.history}
						location={this.props.location}
						linkedProviders={get(
							this.props,
							['linkedProviders'],
							[]
						)}
						providerSpecialties={
							get(this, 'props.providerSpecialties', null) || []
						}
						setReloadProviders={this.setReloadProviders}
						reloadProviders={get(
							this,
							'state.reloadProviders',
							false
						)}
					/>
				);
			default:
				return <EncounterListContainer {...this.props} />;
		}
	}

	render() {
		return <div>{this.getcomponent()}</div>;
	}
}

const mapDispatchToProps = (dispatch, props) => ({
	callencountergroupsessionschema: () =>
		dispatch(getencountergroupsessionschema()),
	callccintakeschema: () => dispatch(getencounterccintakeschema()),
	callvisitsschema: () => dispatch(getencountervisitsschema()),
	calllabsschema: () => dispatch(getencounterlabseschema()),
	callspecificencounterenum: (data) =>
		dispatch(getspecificencounterenum(data)),
	callresearchdataschema: () => dispatch(getencounterresearchdataschema()),
	getReferralProgramDetails: (data) => dispatch(getReferralProgramDetail(data))
});

export default connect(undefined, mapDispatchToProps)(EncounterTab);
