//React & Redux
import React from 'react';

//Lodash
import { get } from 'lodash';

//Utils
import {
	GetSelectvalue,
	encounterFieldDisplay,
	EncounterDateFieldsDisplay,
} from '../Common/utilsfunctions';

const PGXSegment = (props) => {
	return (
		<div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							PharmD Identification
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Drug Interaction
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							# of Interaction(s)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Drug/Gene Interaction
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Drug Dos Adj Recommended
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'pharmd'],
								get(props, ['visitsschema', 'pharmd'], [])
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'drug_interaction'],
								get(
									props,
									['visitsschema', 'drug_interaction'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'no_of_interactions'],
								get(
									props,
									['visitsschema', 'no_of_interactions'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'drug_contraindications'],
								get(
									props,
									['visitsschema', 'drug_contraindications'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								[
									'encounter',
									'drug_dosage_adjustment_recommended',
								],
								get(
									props,
									[
										'visitsschema',
										'drug_dosage_adjustment_recommended',
									],
									[]
								)
							)}
						</div>
					</div>
				</div>
			</div>

			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							PGx Pre-Session Indirect Time (minutes)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							PGx Post-Session Indirect Time (minutes)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							PGx Post-Session Indirect Time Date
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							PGx Post CAP Indirect Time (minutes)
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'encounter',
								'pgx_pre_session_indirect_time',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'encounter',
								'pgx_post_session_indirect_time',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'encounter',
								'pgx_post_session_indirect_time_date',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'encounter',
								'pgx_post_cap_indirect_time',
							])}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PGXSegment;
