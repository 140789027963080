//React & Redux
import React from 'react';
import { Switch } from 'react-router-dom';
import history from './history';

//Lodash
import _ from 'lodash';

//Components
import Patients from './components/Patients/Patients';
import AuthenticatedRoute from './components/AuthenticatedRoute/AuthenticatedRoute';
import PatientDetailContainer from './components/Patients/PatientDetailContainer';
import Dashboard from './components/Dashboard/Dashboard';
import ExternalDashboard from './components/Dashboard/ExternalUser/Dashboard';
import UserApp from './components/index';
import ReportSelector from './components/Providers/ReportSelector';
import DashboardEncounterContainer from './components/Dashboard/DashboardEncounterContainer';
import DashboardRoRPatients from './components/Dashboard/DashboardRoRPatients';
import ExternalDashboardContainer from './components/Dashboard/ExternalUser/DashboardEncounterContainer';
import Billing from './components/Billing/BillingDashboard';
import EnhancedSearch from './components/EnhancedSearch/EnhancedSearch';
import Signincallback from './components/Callback/SigninCallback';
import TestManagement from './components/TestManagement/TestManagement';
import TestDetailView from './components/TestManagement/TestDetailView';
import ReferralPrograms from './components/ReferralPrograms/ReferralPrograms';
import ReferralProgramsDetails from './components/ReferralPrograms/ReferralProgramsDetails';
import AppointmentPage from './components/Appointment/AppointmentPage';
import ScheduleAppointment from './components/Appointment/ScheduleAppointment';
import ScheduleAppointmentV2 from './components/Appointment/V2/ScheduleAppointment';
import RescheduleAppointment from './components/Appointment/rescheduleAppointment/RescheduleAppointment';
import RescheduleAppointmentV2 from './components/Appointment/V2/RescheduleAppointment';
import TestAuthorization from './components/TestAuthorization/TestAuthorization.js';
import InsuranceContainer from './components/Insurance/InsuranceContainer';
import Templates from './components/Templates/Templates';
import ExternalProviders from './components/ExternalProviders/ExternalProviders';
import CreatePatient from './components/Patients/V2/CreatePatient/CreatePatientV2';
import CreatePatientV3 from './components/Patients/V3/CreatePatient';
import ReleaseNotes from './components/ReleaseNotes/ReleaseNotes';
import RedirectRoute from './components/RedirectRoute/RedirectRoute';
import UserAccounts from './components/UserAccounts/UserAccounts';
import RiseManagement from './components/RiseManagement/RiseManagement';
import ReferralDashboard from './components/ReferralDashboard';

//Utils
import {
	isExternalRole,
	isPhysicianRole,
	isSuperUserRole,
	hasPermission,
	HOME_DASHBOARD,
	OPERATIONS_REPORTS,
	HOME_DASHBOARD_CARDS_PATIENT_OUTREACH_PENDING,
	ADVANCED_SEARCH,
	OPERATIONS_RISE,
	FINANCE_BILLING,
	CARE_PATIENTS,
	CARE_APPOINTMENTS,
	CARE_APPOINTMENTS_SCHEDULING,
	CARE_APPOINTMENTS_APPOINTMENT_RESCHEDULING,
	CARE_PATIENTS_PATIENT_DETAIL,
	CARE_TEST_AUTHORIZATION,
	OPERATIONS_TESTS,
	OPERATIONS_TESTS_TEST_DETAIL,
	OPERATIONS_REFERRAL_PROGRAMS,
	OPERATIONS_REFERRAL_PROGRAMS_DETAIL,
	FINANCE_INSURANCE,
	OPERATIONS_TEMPLATE_MANAGEMENT,
	OPERATIONS_EXTERNAL_PROVIDERS,
	CARE_PATIENTS_CREATE,
	CARE_TEST_ACTIONS,
	RELEASE_NOTES,
	OPERATIONS_USER_ACCOUNT_MANAGEMENT,
} from './Utils/permissionUtils';

// eslint-disable-next-line react/display-name
export default ({ childProps }) => {
	const activerole = _.get(childProps, ['me', 'activerole', 'name'], '');
	const isExternal = isExternalRole(activerole);
	const isPhysician = isPhysicianRole(activerole);
	const isSuperUser = isSuperUserRole(activerole);
	const allowedPemissions = _.get(childProps, ['me', 'permissions'], {});

	function getDefaultComponent() {
		if (isExternal) return ExternalDashboard;
		else if (isSuperUser || isPhysician) return RedirectRoute;
		return Dashboard;
	}

	function getDefaultRoute() {
		if (isPhysician) return '/app/testauthorization';
		if (isSuperUser) return '/app/useraccounts';
		return '/app/dashboard';
	}

	function getDefaultProps() {
		if (isPhysician)
			return {
				...childProps,
				actionname: 'appredirect',
				toPath: '/app/testauthorization',
			};
		else if (isSuperUser)
			return {
				...childProps,
				actionname: 'appredirect',
				toPath: '/app/useraccounts',
			};
		return childProps;
	}

	const allRoutes = [
		{
			path: '/',
			component: getDefaultComponent(),
			props: getDefaultProps(),
			exact: true,
			permission: hasPermission(allowedPemissions, HOME_DASHBOARD),
		},
		{
			path: '/app',
			component: getDefaultComponent(),
			props: getDefaultProps(),
			exact: true,
			permission: hasPermission(allowedPemissions, HOME_DASHBOARD),
		},
		{
			path: '/app/dashboard',
			component: isExternal ? ExternalDashboard : Dashboard,
			props: childProps,
			exact: true,
			permission: hasPermission(allowedPemissions, HOME_DASHBOARD),
		},
		{
			path: '/app/dashboard/encounters',
			exact: true,
			component: isExternal
				? ExternalDashboardContainer
				: DashboardEncounterContainer,
			props: childProps,
			permission: hasPermission(allowedPemissions, OPERATIONS_REPORTS),
		},
		{
			path: '/app/dashboard/patients/ror',
			exact: true,
			component: DashboardRoRPatients,
			props: childProps,
			permission: hasPermission(
				allowedPemissions,
				HOME_DASHBOARD_CARDS_PATIENT_OUTREACH_PENDING
			),
		},

		{
			path: '/app/advancedsearch',
			exact: true,
			component: EnhancedSearch,
			props: childProps,
			permission: hasPermission(allowedPemissions, ADVANCED_SEARCH),
		},
		{
			path: '/app/providers',
			exact: true,
			component: ReportSelector,
			props: childProps,
			permission: hasPermission(allowedPemissions, OPERATIONS_REPORTS),
		},
		{
			path: '/app/rise',
			exact: true,
			component: RiseManagement,
			props: childProps,
			permission: hasPermission(allowedPemissions, OPERATIONS_RISE),
		},
		{
			path: '/app/billing',
			exact: true,
			component: Billing,
			props: childProps,
			permission: hasPermission(allowedPemissions, FINANCE_BILLING),
		},
		{
			path: '/app/patients',
			exact: true,
			component: Patients,
			props: childProps,
			permission: hasPermission(allowedPemissions, CARE_PATIENTS),
		},
		{
			path: '/app/appointments',
			exact: true,
			component: AppointmentPage,
			props: childProps,
			permission: hasPermission(allowedPemissions, CARE_APPOINTMENTS),
		},
		{
			path: '/app/appointments/schedule',
			exact: true,
			component: ScheduleAppointmentV2,
			props: childProps,
			permission: hasPermission(
				allowedPemissions,
				CARE_APPOINTMENTS_SCHEDULING
			),
		},
		{
			path: '/app/appointments/schedule/legacy',
			exact: true,
			component: ScheduleAppointment,
			props: childProps,
			permission: hasPermission(
				allowedPemissions,
				CARE_APPOINTMENTS_SCHEDULING
			),
		},
		{
			path: '/app/appointments/reschedule/:encounter_uuid',
			exact: true,
			component: RescheduleAppointmentV2,
			props: childProps,
			permission: hasPermission(
				allowedPemissions,
				CARE_APPOINTMENTS_APPOINTMENT_RESCHEDULING
			),
		},
		{
			path: '/app/appointments/reschedule/:encounter_uuid/legacy',
			exact: true,
			component: RescheduleAppointment,
			props: childProps,
			permission: hasPermission(
				allowedPemissions,
				CARE_APPOINTMENTS_APPOINTMENT_RESCHEDULING
			),
		},
		{
			path: '/app/patientdetail/:patientid/:tabtype/:enctype/:encounterid?/:activePage?/:q?',
			exact: true,
			component: PatientDetailContainer,
			props: childProps,
			permission: hasPermission(
				allowedPemissions,
				CARE_PATIENTS_PATIENT_DETAIL
			),
		},
		{
			path: '/app/testauthorization',
			exact: true,
			component: TestAuthorization,
			props: childProps,
			permission: hasPermission(
				allowedPemissions,
				CARE_TEST_AUTHORIZATION
			),
		},
		{
			path: '/app/testmanagement',
			exact: true,
			component: TestManagement,
			props: childProps,
			permission: hasPermission(allowedPemissions, OPERATIONS_TESTS),
		},
		{
			path: '/app/test/:id',
			exact: true,
			component: TestDetailView,
			props: childProps,
			permission: hasPermission(
				allowedPemissions,
				OPERATIONS_TESTS_TEST_DETAIL
			),
		},
		{
			path: '/app/referralprograms',
			exact: true,
			component: ReferralPrograms,
			props: childProps,
			permission: hasPermission(
				allowedPemissions,
				OPERATIONS_REFERRAL_PROGRAMS
			),
		},
		{
			path: '/app/referralprogramdetail/:referralprogramid/:view/:detailid?',
			exact: true,
			component: ReferralProgramsDetails,
			props: childProps,
			permission: hasPermission(
				allowedPemissions,
				OPERATIONS_REFERRAL_PROGRAMS_DETAIL
			),
		},
		{
			path: '/app/insurance',
			exact: true,
			component: InsuranceContainer,
			props: childProps,
			permission: hasPermission(allowedPemissions, FINANCE_INSURANCE),
		},
		{
			path: '/app/templates',
			exact: true,
			component: Templates,
			props: childProps,
			permission: hasPermission(
				allowedPemissions,
				OPERATIONS_TEMPLATE_MANAGEMENT
			),
		},
		{
			path: '/app/externalproviders',
			exact: true,
			component: ExternalProviders,
			props: childProps,
			permission: hasPermission(
				allowedPemissions,
				OPERATIONS_EXTERNAL_PROVIDERS
			),
		},
		{
			path: '/app/patientmanagement',
			exact: true,
			component: CreatePatientV3,
			props: childProps,
			permission: hasPermission(allowedPemissions, CARE_PATIENTS_CREATE),
		},
		{
			path: '/app/patientmanagement/legacy',
			exact: true,
			component: CreatePatient,
			props: childProps,
			permission: hasPermission(allowedPemissions, CARE_PATIENTS_CREATE),
		},
		{
			path: '/app/patientmanagement/edit',
			exact: true,
			component: CreatePatientV3,
			props: childProps,
			permission: hasPermission(allowedPemissions, CARE_PATIENTS_CREATE),
		},
		{
			path: '/app/patientmanagement/edit/legacy',
			exact: true,
			component: CreatePatient,
			props: childProps,
			permission: hasPermission(allowedPemissions, CARE_PATIENTS_CREATE),
		},
		{
			path: '/app/action/:actionname/:param1?/:param2?',
			exact: true,
			component: RedirectRoute,
			props: childProps,
			permission: hasPermission(allowedPemissions, CARE_TEST_ACTIONS),
		},
		{
			path: '/app/releasenotes',
			exact: true,
			component: ReleaseNotes,
			props: childProps,
			permission: hasPermission(allowedPemissions, RELEASE_NOTES),
		},
		{
			path: '/app/useraccounts',
			exact: true,
			component: UserAccounts,
			props: childProps,
			permission: hasPermission(
				allowedPemissions,
				OPERATIONS_USER_ACCOUNT_MANAGEMENT
			),
		},
		{
			path: '/app/referraldashboard',
			exact: true,
			component: ReferralDashboard,
			props: childProps,
			permission: hasPermission(allowedPemissions, CARE_PATIENTS),
		},
	];

	return (
		<UserApp props={childProps} history={history}>
			<Switch>
				<AuthenticatedRoute
					path='/signincallback'
					exact
					component={Signincallback}
					props={childProps}
				/>
				{allRoutes.map((route, index) => {
					return (
						route.permission && (
							<AuthenticatedRoute
								key={`route-${index}`}
								path={route.path}
								exact={route.exact}
								component={route.component}
								props={route.props}
							/>
						)
					);
				})}
				<AuthenticatedRoute
					path='*'
					key={`route-generic`}
					component={RedirectRoute}
					props={{
						...childProps,
						actionname: 'appredirect',
						toPath: getDefaultRoute(),
					}}
				/>
			</Switch>
		</UserApp>
	);
};
