import * as actionTypes from './actionTypes';
import AppointmentsService from '../service/Appointments';
import PatientService from '../service/Patients';
import { InsuranceErrorTransformers } from '../transformers/ErrorTransformers';
import { PaymentErrorTransformers } from '../transformers/ErrorTransformers';
import { getPatientPhoneValue } from '../transformers/patients/patientTransformer';
import { appointmenterrors } from '../transformers/errorconstant';
import _ from 'lodash';
import moment from 'moment';

export const startLoadAppointments = () => {
	return { type: actionTypes.LOAD_APPOINTMENTS_START };
};

export function successLoadAppointments(appointments) {
	return {
		type: actionTypes.LOAD_APPOINTMENTS_SUCCESS,
		payload: appointments,
	};
}

export function failureLoadAppointments(error) {
	return { type: actionTypes.LOAD_APPOINTMENTS_FAILURE, payload: error };
}

export function LoadAppointments(data) {
	return (dispatch) => {
		dispatch(startLoadAppointments());
		return AppointmentsService.get(data)
			.then((res) => {
				return dispatch(
					successLoadAppointments(minimizeAppointment(res.data))
				);
			})
			.catch((err) => {
				dispatch(failureLoadAppointments(err));
			});
	};
}

export function minimizeAppointment(data) {
	if (_.get(data, ['data', 'length'], 0) > 0) {
		const updateddata = _.get(data, 'data', []).map((appointment) => {
			const updatedAppointment = {
				id: appointment && appointment.id,
				visit_status: appointment && appointment.visit_status,
				visit_provider: appointment && appointment.visit_provider,
				tm_platform: appointment && appointment.tm_platform,
				date_of_service: appointment && appointment.date_of_service,
				end_time: appointment && appointment.end_time,
				timezone: appointment && appointment.timezone,
				uuid: appointment && appointment.uuid,
				user_uuid: appointment && appointment.user_uuid,
				type: appointment && appointment.type,
				consultation_type: appointment && appointment.consultation_type,
				state_of_visit: appointment && appointment.state_of_visit,
				payor: appointment && appointment.payor,
				patient: {
					first_name:
						appointment &&
						appointment.patient &&
						appointment.patient.first_name,
					last_name:
						appointment &&
						appointment.patient &&
						appointment.patient.last_name,
					email:
						appointment &&
						appointment.patient &&
						appointment.patient.email,
					phone:
						appointment &&
						appointment.patient &&
						getPatientPhoneValue(appointment.patient),
					uuid:
						appointment &&
						appointment.patient &&
						appointment.patient.uuid,
					partner: {
						name:
							appointment &&
							appointment.patient &&
							appointment.patient.partner &&
							appointment.patient.partner.name,
						id:
							appointment &&
							appointment.patient &&
							appointment.patient.partner &&
							appointment.patient.partner.id,
						uuid:
							appointment &&
							appointment.patient &&
							appointment.patient.partner &&
							appointment.patient.partner.uuid,
						display_name:
							appointment &&
							appointment.patient &&
							appointment.patient.partner &&
							appointment.patient.partner.display_name,
						scheduling_platform:
							appointment?.patient?.partner?.scheduling_platform,
						is_phone_only:
							appointment?.patient?.partner?.is_phone_only,
					},
				},
				pioneer_appointment_id: appointment?.pioneer_appointment_id,
			};
			return updatedAppointment;
		});
		data.data = updateddata;
	}
	return data;
}

export const startSwitchEncounterModality = () => {
	return { type: actionTypes.SWITCH_MODALITY_START };
};

export function successSwitchEncounterModality(appointments) {
	return { type: actionTypes.SWITCH_MODALITY_SUCCESS, payload: appointments };
}

export function failureSwitchEncounterModality(error) {
	return { type: actionTypes.SWITCH_MODALITY_FAILURE, payload: error };
}

export function ChangeModality(encounter_uuid, type) {
	return (dispatch) => {
		dispatch(startSwitchEncounterModality());
		return AppointmentsService.switchModality(encounter_uuid, type)
			.then((res) => {
				return dispatch(successSwitchEncounterModality(res.data));
			})
			.catch((err) => {
				dispatch(failureSwitchEncounterModality(err));
			});
	};
}

export async function bookAppointment(data) {
	const modality = _.get(data, 'modality', 'video');
	const timezone = data.timezone;
	const start = moment
		.tz(moment(data.start_time).format('YYYY-MM-DDTHH:mm:ss'), timezone)
		.format();
	const by_phone =
		(_.isNil(modality) ? 'video' : modality).toLowerCase() == 'video'
			? undefined
			: true;
	const country = _.get(data, 'country', 'US');
	const reqdata = _.omitBy(
		{
			patient_id: data.patient_id,
			consultation_id: data.consultation_id,
			provider_id: data.provider_id,
			start_time: start,
			country: country,
			state: data.state,
			vsee_specialty: data.vsee_specialty,
			timezone: data.timezone,
			by_phone: by_phone,
			coupon_code: data.coupon_code,
			use_insurance: data.use_insurance,
		},
		(d) => _.isNil(d)
	);
	return await AppointmentsService.bookAppointment(reqdata);
}

export async function nonZeroBookAppointment(data) {
	const errors = {
		PaymentFieldsErrors: {},
		InsuranceFieldsErrors: {},
		ScheduleActionableErrors: [],
		ScheduleNonActionableErrors: [],
		PaymentActionableErrors: [],
		PaymentNonActionableErrors: [],
		NonCategoriesErrors: [],
	};

	if (data && data.InsuranceSection && !_.isEmpty(data.InsuranceSection)) {
		try {
			await PatientService.saveInsurance(
				{ insurance: data.InsuranceSection.insurances },
				data.InsuranceSection.patient_uuid
			);
		} catch (insuranceerror) {
			const insurancehydrateerros =
				InsuranceErrorTransformers(insuranceerror);
			if (!_.isEmpty(_.get(insurancehydrateerros, 'errorMessages', {})))
				errors.InsuranceFieldsErrors = {
					...insurancehydrateerros.errorMessages,
				};
			throw errors;
		}
	}
	if (data && data.CreditCardSection && !_.isEmpty(data.CreditCardSection)) {
		try {
			const creditcard = {
				...data.CreditCardSection.data,
				number: data.CreditCardSection.data.number.replace(/\s/g, ''),
				...data.CreditCardSection.data.expirydate,
			};
			await PatientService.updatePaymentMethod(
				creditcard,
				data.CreditCardSection.patient_uuid
			);
		} catch (creditcardError) {
			const creditcardhydrateerros =
				PaymentErrorTransformers(creditcardError);
			const errormessage = _.get(
				creditcardhydrateerros,
				['messages', '0'],
				''
			);
			if (!_.isEmpty(errormessage)) {
				_.each(appointmenterrors, (value, errorkey) => {
					if (value.includes(errormessage)) {
						errors[errorkey].push(errormessage);
					} else if (
						!_.get(errors, 'NonCategoriesErrors', []).includes(
							errormessage
						)
					) {
						errors['NonCategoriesErrors'].push(errormessage);
					}
				});
			}
			if (!_.isEmpty(_.get(creditcardhydrateerros, 'errorMessages', {})))
				errors.PaymentFieldsErrors = {
					...creditcardhydrateerros.errorMessages,
				};
			throw errors;
		}
	}
	if (data && data.bookAppointment && !_.isEmpty(data.bookAppointment)) {
		try {
			const bookappointmentsuccess = await bookAppointment(
				data.bookAppointment
			);
			return Promise.resolve(
				_.get(bookappointmentsuccess, ['data', 'data', '0'], {})
			);
		} catch (bookappointmenterror) {
			const bookingError = PaymentErrorTransformers(bookappointmenterror);
			const errormessage = _.get(bookingError, ['messages', '0'], '');
			if (!_.isEmpty(errormessage)) {
				_.each(appointmenterrors, (value, errorkey) => {
					if (value.includes(errormessage)) {
						errors[errorkey].push(errormessage);
					} else if (
						!_.get(errors, 'NonCategoriesErrors', []).includes(
							errormessage
						)
					) {
						errors['NonCategoriesErrors'].push(errormessage);
					}
				});
			}
			if (!_.isEmpty(_.get(bookingError, 'errorMessages', {})))
				errors.PaymentFieldsErrors = { ...bookingError.errorMessages };
			throw errors;
		}
	}
}

export async function zeroFeeBookAppointment(data) {
	const errors = {
		PaymentFieldsErrors: {},
		InsuranceFieldsErrors: {},
		ScheduleActionableErrors: [],
		ScheduleNonActionableErrors: [],
		PaymentActionableErrors: [],
		PaymentNonActionableErrors: [],
		NonCategoriesErrors: [],
	};
	try {
		const bookappointmentsuccess = await bookAppointment(data);
		return Promise.resolve(
			_.get(bookappointmentsuccess, ['data', 'data', '0'], {})
		);
	} catch (bookappointmenterror) {
		const bookingError = PaymentErrorTransformers(bookappointmenterror);
		const errormessage = _.get(bookingError, ['messages', '0'], '');
		if (!_.isEmpty(errormessage)) {
			_.each(appointmenterrors, (value, errorkey) => {
				if (value.includes(errormessage)) {
					errors[errorkey].push(errormessage);
				} else if (
					!_.get(errors, 'NonCategoriesErrors', []).includes(
						errormessage
					)
				) {
					errors['NonCategoriesErrors'].push(errormessage);
				}
			});
		}
		if (!_.isEmpty(_.get(bookingError, 'errorMessages', {})))
			errors.PaymentFieldsErrors = { ...bookingError.errorMessages };
		throw errors;
	}
}
