import axios from 'axios';
import get from 'lodash/get';

class BillingService {
	billingEncounters = (data) => {
		const url = '/api/billings';
		return axios.post(url, data);
	};

	referralchannels = () => {
		const url = '/api/referral-channels';
		return axios.get(url);
	};

	billingaffiliations = (data) => {
		let url = '/api/billing-affiliations';
		if (get(data, 'referral_channel_id')) {
			url =
				url +
				'?referral_channel_id=' +
				get(data, 'referral_channel_id');
		}
		return axios.get(url);
	};

	getCounts = (data) => {
		const url = '/api/billings/dashboard';
		return axios.post(url, data);
	};

	getBillingEncounterDetail = (uuid) => {
		return axios.get(`/api/encounters/${uuid}`);
	};

	getBillingPatientDetail = (uuid) => {
		return axios.get(`/api/patients/${uuid}`);
	};

	getBillingEncounterObject = (uuid) => {
		return axios.get(`/api/encounters/${uuid}/billing`);
	};

	getEncounterEnums = () => {
		return axios.get('/api/billing-encounter-enums');
	};

	saveEncounter = (data_enc) => {
		const data = { data: data_enc };
		if (data.data.type == 'visit')
			return axios.put('/api/encounters/visits', data);
		else if (data.data.type == 'cc-intake')
			return axios.put('/api/encounters/cc_intakes', data);
		else if (data.data.type == 'group-session')
			return axios.put('/api/encounters/groupsessions', data);
		else if (data.data.type == 'research-data')
			return axios.put('/api/encounters/research_datas', data);
		else return axios.put('/api/encounters/labs', data);
	};

	saveBillingEncounter = (data) => {
		return axios.put(
			`/api/encounters/${data.encounter_uuid}/billing`,
			data
		);
	};

	updateBillingEncounter = (data) => {
		const encounter_uuid = data.encounter_uuid;
		if (data.encounter_uuid) {
			delete data['encounter_uuid'];
		}
		return axios.put(`/api/encounters/${encounter_uuid}/billing`, data);
	};

	exportBillingReport = (data) => {
		return axios.post('/api/report/billing', data);
	};

	billingEncounterBulkUpdate = (data) => {
		return axios.post('/api/encounters/billing/bulk-update', data);
	};

	getPatientFeeEnum = () => {
		return axios.get(
			'/api/enums/encounters/billing/patient_fee_refund_reason'
		);
	};

	makeTokenizedPaymentMethodIntent = (patientUuid) => {
		return axios.post(
			`/api/patients/${patientUuid}/payment-method/stripe-setup-intent`,
			{}
		);
	};
}

export default new BillingService();
