//React & Redux
import React, { Fragment } from 'react';

//Images
import ReactSVG from 'react-svg';
import dropdownIcon from './../../assets/dropdown.svg';

//Lodash
import get from 'lodash/get';
import _ from 'lodash';

//Utils
import { statusVals } from './../../Utils/templateUtils.js';

//UI Libraries
import { Dropdown } from 'gm-element-react';

//Components
import MultiSelectCheckbox from '../Filter/MultiSelectCheckbox';
import Searchbar from '../Filter/Searchbar';
import ResetFilters from '../Filter/ResetFilters';

const TemplatesFilterActionRow = (props) => {
	const {
		canEditReferralPrograms = false,
		canEditTemplateTypes = false,
		handleResetFilters,
		q = '',
		referralPrograms = [],
		selectedReferralPrograms = [],
		templateStatus = [],
		templateType = [],
		templateTypes = [],
		updateFilters,
	} = props;

	const renderDropdownOrDisabledButton = () => {
		if (canEditReferralPrograms || canEditTemplateTypes) {
			return renderDropdownButton();
		} else {
			return renderDisabledButton();
		}
	};

	const renderDropdownButton = () => {
		return (
			<Dropdown
				menu={<Dropdown.Menu>{renderOptions()}</Dropdown.Menu>}
				onCommand={handleCommand}
				trigger='click'
				className='filterDropdownElement'
			>
				<div className='filterDropdownButton'>
					Bulk Actions
					<ReactSVG
						src={dropdownIcon}
						className='filterDropdownButtonIcon'
					/>
				</div>
			</Dropdown>
		);
	};

	const renderOptions = () => {
		const options = [];
		if (canEditReferralPrograms) {
			options.push(
				<Dropdown.Item
					command='openReferralProgramEditModal'
					key='editRefProgs'
				>
					Edit Referral Programs
				</Dropdown.Item>
			);
		}

		if (canEditTemplateTypes) {
			options.push(
				<Dropdown.Item
					command='openChangeTemplateTypeModal'
					key='editTemplateTypes'
				>
					Change Template Type
				</Dropdown.Item>
			);
		}
		return options;
	};

	const renderDisabledButton = () => {
		return (
			<div className='filterDropdownButton filterDropdownButtonDisabled'>
				Bulk Actions
				<ReactSVG
					src={dropdownIcon}
					className='filterDropdownButtonIcon'
				/>
			</div>
		);
	};

	const handleCommand = (command) => {
		if (typeof get(props, `${command}`, null) === 'function')
			props[command]();
	};

	const getTemplateStatus = () => {
		return statusVals.map((el) => {
			return { display_name: el.external, key: el.internal };
		});
	};

	const getReferralPrograms = () => {
		return referralPrograms.map((el) => {
			return { display_name: el.display_name, key: el.uuid };
		});
	};

	return (
		<Fragment>
			<div style={{ display: 'flex' }}>
				{renderDropdownOrDisabledButton()}
			</div>

			<div style={{ display: 'flex' }}>
				<Searchbar
					setStateFunc={(e) =>
						updateFilters('q', _.get(e, ['target', 'value'], ''))
					}
					currentValue={q || ''}
					useInputEvtObj={true}
					placeholder='Search'
				/>
				<ResetFilters handleResetFilters={handleResetFilters} />
				<MultiSelectCheckbox
					data={getTemplateStatus()}
					title={'Template Status'}
					majorPillTitle={'Template Status'}
					selected={templateStatus}
					defaultValues={['active']}
					searchBar={false}
					previewTab={false}
					emptyInputFullOutputOverride={true}
					submit={(e) => updateFilters('templateStatus', e)}
				/>
				<MultiSelectCheckbox
					data={templateTypes}
					title={'Template Types'}
					majorPillTitle={'Template Types'}
					selected={templateType}
					searchBar={false}
					previewTab={false}
					submit={(e) => updateFilters('templateType', e)}
				/>
				<MultiSelectCheckbox
					data={getReferralPrograms()}
					title={'Referral Programs'}
					majorPillTitle={'Referral Programs'}
					selected={selectedReferralPrograms}
					searchBar={true}
					previewTab={true}
					submit={(e) => updateFilters('referralPrograms', e)}
				/>
			</div>
		</Fragment>
	);
};

export default TemplatesFilterActionRow;
