//React & Redux
import React, { useEffect, useState } from 'react';
//Images
import ReactSVG from 'react-svg';
import xModalIcon from './../../../../assets/close.svg';

//Utils
import {
	documentDescriptionValidation,
	documentDescripitonRequiredValidation,
	documentDescriptionLengthValidator,
	documentTypeIsVisit,
	documentTypeIsAdminMisc,
} from '../../../../Utils/documentUtils.js';

//UI Libraries
import { Loading, Select, Cascader } from 'gm-element-react';

//Components
import RadioGroup from '../../../Common/controls/RadioGroup/RadioGroup';
import { FileDropZone } from '@gm/common/ui';

const UploadNewDocument = (props) => {
	const {
		documentTypes,
		documentTypesLoading,
		encounterStringDisplay,
		encounters = [],
		fileUploading,
		getDocumentTypesForPatient,
		hideDocumentUpload,
		patientUuid,
		uploadDocument,
		showEncounter = true,
	} = props;

	const [description, setDescription] = useState('');
	const [documentSide, setDocumentSide] = useState(null);
	const [documentSubType, setDocumentSubType] = useState(null);
	const [documentType, setDocumentType] = useState(null);
	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState(null);
	const [relatedEncounter, setRelatedEncounter] = useState(null);
	const [showSideInputs, setShowSideInputs] = useState(false);
	const [sizeError, setSizeError] = useState(false);

	useEffect(() => {
		document
			.querySelector('body')
			.addEventListener('dragover', preventDragOver);
		document
			.querySelector('body')
			.addEventListener('drop', preventDragOver);
		getDocumentTypesForPatient();

		return () => {
			document
				.querySelector('body')
				.removeEventListener('dragover', preventDragOver);
			document
				.querySelector('body')
				.removeEventListener('drop', preventDragOver);
		};
	}, []);

	useEffect(() => {
		if (documentType === 'insurance_card') {
			setShowSideInputs(true);
		} else {
			setDocumentSide(null);
			setShowSideInputs(false);
		}
	}, [documentType]);

	useEffect(() => {
		sizeError && setTimeout(() => setSizeError(false), 750);
	}, [sizeError]);

	const preventDragOver = (e) => {
		e.preventDefault();
	};

	const setValue = (e, type) => {
		switch (type) {
			case 'encounter':
				setRelatedEncounter(e);
				break;
			case 'document':
				setDocumentType(e?.[0] || null);
				setDocumentSubType(e?.[1] || null);
				break;
			case 'description':
				setDescription(e);
				break;
			case 'document_side':
				setDocumentSide(e);
				break;
		}
	};

	const onChangeDocumentSide = (value) => {
		setDocumentSide(value);
	};

	const handleUploadDocument = () => {
		if (documentType && fileName) {
			if (documentType === 'insurance_card' && !documentSide) return;
			if (canSave() && !errorCheck()) {
				if (patientUuid)
					uploadDocument(
						relatedEncounter,
						documentType,
						description,
						file,
						patientUuid,
						documentSide,
						documentSubType
					);
				else {
					uploadDocument(
						documentType,
						description,
						file,
						documentSide
					);
				}
			}
		}
	};

	const errorCheck = () => {
		let errors = false;
		const currentSet = new Set();

		if (!documentType) {
			currentSet.add('documentType');
			errors = true;
		}

		if (!fileName || !file) {
			currentSet.add('file');
			errors = true;
		}

		if (fileTypeHasError(file)) {
			currentSet.add('fileType');
			errors = true;
		}

		if (description && !isValidDescription(description)) {
			errors = true;
		}

		return errors;
	};

	const fileTypeHasError = (file) => {
		const fileName = file?.name || '';
		if (fileName.indexOf('.') === -1) return true;
		const splitFileName = fileName.split('.');
		const extension = splitFileName[splitFileName.length - 1];
		if (
			!new Set(['pdf', 'jpeg', 'jpg', 'gif', 'png']).has(
				extension.toLowerCase()
			)
		)
			return true;

		return false;
	};

	const getDocType = () =>
		documentTypes.filter(
			(type) => !['unassigned', 'risk_assessment'].includes(type.value)
		);

	const processEncounters = () => {
		const nonCancelledEncounters = encounters.filter((el) => {
			if (el.type !== 'research-data') {
				return (
					!(el.visit_status || '').includes('cancelled') &&
					el.visit_status !== 'no_show'
				);
			} else {
				return false;
			}
		});
		return nonCancelledEncounters;
	};

	const canSave = () => {
		const hasBasicFields =
			documentType && fileName && file && isValidDescription();
		if (documentTypeIsVisit(documentType)) {
			return hasBasicFields && relatedEncounter;
		} else {
			return hasBasicFields;
		}
	};

	const isValidDescription = () => {
		if (documentTypeIsAdminMisc(documentType)) {
			if (!documentDescripitonRequiredValidation(description)) {
				return false;
			} else if (!documentDescriptionLengthValidator(description)) {
				return false;
			}
		}
		if (description && !documentDescriptionValidation(description)) {
			return false;
		}
		return true;
	};

	const invalidLength = (
		<div
			className='inputFieldError'
			style={{ position: 'absolute', top: '85px' }}
		>
			Description should not be more than 40 characters.
		</div>
	);
	const invalidDescription = (
		<div
			className='inputFieldError'
			style={{ position: 'absolute', top: '85px' }}
		>
			Invalid description.
		</div>
	);

	const determineDescriptionErrorMessage = () => {
		// WHEN EXTRACTING PAY ATTENTION TO STRINGINNER TEXT CONTENT
		if (documentTypeIsAdminMisc(documentType)) {
			if (!documentDescripitonRequiredValidation(description)) {
				return null;
			} else if (!documentDescriptionLengthValidator(description)) {
				return invalidLength;
			}
		}
		if (description && !documentDescriptionValidation(description)) {
			return invalidDescription;
		}
		return null;
	};

	const docTypes = getDocType();
	const documentTypeValue = [documentType, documentSubType].filter(
		(el) => el
	);
	let selectValues = showSideInputs
		? [
				{
					header: 'Document type',
					type: 'document',
					required: true,
					options: docTypes,
					placeholder: 'Select document type',
					value: documentTypeValue,
				},
				{
					header: 'Front or back?',
					type: 'document_side',
					required: true,
					options: [
						{ display_name: 'Front', key: 'front' },
						{ display_name: 'Back', key: 'back' },
					],
					placeholder: 'Select document type',
					value: documentSide,
				},
				{
					header: 'Related encounter',
					type: 'encounter',
					required: documentTypeIsVisit(documentType) ? true : false,
					options: processEncounters(),
					placeholder: 'Select encounter',
					value: relatedEncounter,
				},
				{
					header: 'Description',
					type: 'description',
					value: description,
					placeholder: 'Clinical note from Dr. Smith',
				},
		  ]
		: [
				{
					header: 'Document type',
					type: 'document',
					required: true,
					options: docTypes,
					placeholder: 'Select document type',
					value: documentTypeValue,
				},
				{
					header: 'Related encounter',
					type: 'encounter',
					required: documentTypeIsVisit(documentType) ? true : false,
					options: processEncounters(),
					placeholder: 'Select encounter',
					value: relatedEncounter,
				},
				{
					header: documentTypeIsAdminMisc(documentType)
						? 'Description (visible to patients upon document release)'
						: 'Description',
					type: 'description',
					value: description,
					required: documentTypeIsAdminMisc(documentType)
						? true
						: false,
					placeholder: 'Clinical note from Dr. Smith',
				},
		  ];

	if (!showEncounter) {
		selectValues = selectValues.filter((item) => item.type !== 'encounter');
	}

	let uploadBtnClass = canSave() ? '' : ' createDocumentCreateButtonInactive';
	if (documentType === 'insurance_card' && !documentSide) {
		uploadBtnClass = ' createDocumentCreateButtonInactive';
	}
	const cascaderClass = showSideInputs
		? 'createDocumentInputSelect createDocumentV2TypeSelect halfWidth'
		: 'createDocumentInputSelect createDocumentV2TypeSelect';

	return (
		<div className='fullModalBackground'>
			<div className='uploadDocumentContainer'>
				{fileUploading || documentTypesLoading ? (
					<Loading loading={true} style={{ position: 'unset' }} />
				) : (
					<>
						<div className='uploadDocumentHeader'>
							<div>Upload Document</div>
							<ReactSVG
								data-testId='close-btn'
								src={xModalIcon}
								className='singleSelectModalXIcon uploadDocumentHeaderXIcon'
								onClick={hideDocumentUpload}
							/>
						</div>

						<div className='uploadDocumentBody'>
							{selectValues.map((obj) => (
								<div
									data-testId='create-document-input-container'
									className='createDocumentInputContainer'
									key={obj.header}
									style={
										showSideInputs &&
										obj.type === 'document_side'
											? {
													position: 'absolute',
													top: '85px',
													left: 'calc(50% + 40px)',
											  }
											: {}
									}
								>
									{obj.required && (
										<div style={{ position: 'relative' }}>
											<div
												className='createDocumentRequired'
												style={{}}
											>
												*
											</div>
										</div>
									)}
									<div className='createDocumentInputHeader'>
										{obj.header}
									</div>
									{obj.type === 'description' ? (
										<>
											<textarea
												placeholder={obj.placeholder}
												value={description}
												className='inputFieldTextArea'
												style={{ height: '66px' }}
												onChange={(e) =>
													setValue(
														e.target.value,
														obj.type
													)
												}
											/>
											{determineDescriptionErrorMessage()}
										</>
									) : obj.type === 'document_side' ? (
										<RadioGroup
											options={obj.options || []}
											value={documentSide || []}
											onChange={onChangeDocumentSide}
										/>
									) : obj.type === 'encounter' ? (
										<Select
											className={
												'createDocumentInputSelect'
											}
											style={
												obj.type === 'document' &&
												showSideInputs
													? { width: '50%' }
													: {}
											}
											placeholder={
												obj.placeholder || 'Select'
											}
											value={obj.value}
											onChange={(e) =>
												setValue(e, obj.type)
											}
										>
											{obj.options.map((el) => {
												if (obj.type === 'encounter') {
													return (
														<Select.Option
															className=''
															label={encounterStringDisplay(
																el
															)}
															value={`${el.uuid}`}
															key={`${el.uuid}`}
														>
															{encounterStringDisplay(
																el
															)}
														</Select.Option>
													);
												} else {
													return (
														<Select.Option
															className=''
															label={
																el.display_name ||
																'Select'
															}
															value={el.key}
															key={el.key}
														>
															{el.display_name}
														</Select.Option>
													);
												}
											})}
										</Select>
									) : (
										<Cascader
											className={cascaderClass}
											placeholder={'Select document type'}
											value={obj.value}
											onChange={(e) =>
												setValue(e, obj.type)
											}
											options={obj.options || []}
											expandTrigger={'hover'}
										/>
									)}
								</div>
							))}
							<FileDropZone
								setFile={setFile}
								fileName={fileName}
								setFileName={setFileName}
								setSizeError={setSizeError}
								sizeError={sizeError}
							/>
						</div>

						<div className='uploadDocumentButtonRow'>
							<div
								data-testId='upload-file'
								className={
									'singleSelectSave uploadDocumentButtonSave' +
									uploadBtnClass
								}
								onClick={handleUploadDocument}
							>
								Upload
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default UploadNewDocument;
