//React & Redux
import React, { Component } from 'react';

// Lodash
import { get } from 'lodash';

//UI Libraries
import { Button, Popover } from 'gm-element-react';
import classnames from 'classnames';

//Components
import Icon from '../../Common/Icon';

//Styles
import './ThreeDotsMenu.css';

export default class ThreeDotsMenu extends Component {
	state = {
		optionsVisible: false,
	};

	componentDidMount() {
		document
			.querySelector('body')
			.addEventListener('click', this.bodyClickHandler.bind(this), {
				capture: true,
			});
	}

	componentWillUnmount() {
		document
			.querySelector('body')
			.removeEventListener('click', this.bodyClickHandler.bind(this));
	}

	bodyClickHandler(e) {
		const currentNode = e.target;
		if (this.element && !this.element.contains(currentNode))
			this.setState({ optionsVisible: false });
	}

	toggleVisibility(event) {
		event.stopPropagation();
		this.setState({
			optionsVisible: !this.state.optionsVisible,
		});
	}

	render() {
		const directRender = get(this, ['props', 'directRender'], false);
		const options = get(this, ['props', 'options'], []);
		const blackVariant = get(this, 'props.blackVariant', null);
		const blackVariantClass = blackVariant
			? ' three-dots-black-variant'
			: '';
		const menuOpen = this.state.optionsVisible ? ' three-dots-open' : '';
		const containerClassName =
			'three-dots-wrapper' + blackVariantClass + menuOpen;
		const customStyling = get(this, 'props.customStyling', null) || {};
		if (this.props.disabled) {
			return (
				<div className='three-dots-wrapper disabled'>
					<Icon className='three-dots-icon' icon='three-dots' />
				</div>
			);
		}

		return (
			<div
				data-testId='three-dots-menu'
				className={containerClassName}
				onClick={(event) => this.toggleVisibility(event)}
				ref={(el) => (this.element = el)}
				style={customStyling}
			>
				<Popover
					visibleArrow={false}
					placement='bottom'
					trigger='manual'
					content={
						directRender ? (
							options
						) : options.length ? (
							<ul className='three-dots-menu'>
								{options.map(
									(e, i) =>
										e && (
											<li
												className={classnames({
													disabled: get(
														e,
														['disabled'],
														false
													),
												})}
												onClick={
													get(e, ['disabled'], false)
														? () => {}
														: e.click
												}
												key={i}
											>
												{e.label}
											</li>
										)
								)}
							</ul>
						) : null
					}
					visible={this.state.optionsVisible}
				>
					<Button>
						<Icon className='three-dots-icon' icon='three-dots' />
					</Button>
				</Popover>
			</div>
		);
	}
}
