//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import { CARE_TEST_ACTIONS } from '../../Utils/permissionUtils';
import {
	RemoveUnderscoreAndCapitalizeInString,
	states_list,
} from '../../utils.js';

//UI Libraries
import { Checkbox } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Images
import ReactSVG from 'react-svg';
import redFlagIcon from '../../assets/red-flag.svg';
import greenFlagIcon from '../../assets/green-flag.svg';
import pdfIcon from '../../assets/pdf.svg';

import { EncounterTabs } from '../../utils';

//Components
import RBAC from '../RBAC';
import { Tr, Td } from '../Common/Table';
import LinkPopover from '../Common/Popovers/LinkPopover';
import TextWithTooltip from '../Common/TextWithTooltip';
import Tooltip from '../Common/Tooltip';

class TestAuthTableRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			documentsLoading: false,
			documentsList: [],
		};
		//bindings
		this.determineIsChecked = this.determineIsChecked.bind(this);
		this.determineHasFlag = this.determineHasFlag.bind(this);
		this.displayFlagAndReason = this.displayFlagAndReason.bind(this);
		this.parseDateForDate = this.parseDateForDate.bind(this);
		this.parseDateForTime = this.parseDateForTime.bind(this);
		this.redirectToEncounterDetail =
			this.redirectToEncounterDetail.bind(this);
		this.renderOrderStatus = this.renderOrderStatus.bind(this);
	}
	//functions

	determineIsChecked() {
		const selectedTests = get(this, 'props.selectedTests', []) || [];
		const test = get(this, 'props.test', {}) || {};
		return selectedTests.includes(get(test, 'order.uuid', null));
	}

	determineHasFlag() {
		const test = get(this, 'props.test', {}) || {};
		return (
			get(test, 'order.order_status', null) === 'requested' &&
			get(test, ['can_change_order'], false)
		);
	}

	determineOrderStatusColor() {
		const test = get(this, 'props.test', {}) || {};
		const orderStatus = get(test, 'order.order_status', null);
		if (orderStatus in { rejected: 1 }) return '#ad1505';
		else if (orderStatus in { authorized: 1 }) return '#00a044';
		return '#7b8291';
	}

	displayFlagAndReason() {
		if (
			_.size(get(this, ['props', 'test', 'order', 'red_flags'], [])) > 0
		) {
			let greenFlagReason = '';
			let showRedFlag = false;
			const test = get(this, 'props.test', {}) || {};
			const redFlags = get(
				this,
				['props', 'test', 'order', 'red_flags'],
				[]
			).map((flag) => {
				if (flag.enabled) {
					showRedFlag = true;
				}
				return flag.message;
			});

			if (!showRedFlag) {
				let redFlagsAuditTrail = get(
					this,
					['props', 'test', 'order', 'red_flags_audit_trail'],
					[]
				);
				redFlagsAuditTrail = _.orderBy(
					redFlagsAuditTrail,
					(flag) => moment(get(flag, 'created_at', null)),
					['desc']
				);
				greenFlagReason = _.find(redFlagsAuditTrail, (flag) => {
					return _.get(flag, 'action', '') === 'red_flag_cleared';
				});
			}

			return (
				<Fragment>
					{showRedFlag ? (
						<Fragment>
							<img
								src={redFlagIcon}
								alt=''
								className='red-flag-icon'
								onClick={() =>
									this.props.showUpdateFlagStatusModal(test)
								}
							/>
							<TextWithTooltip
								className='testAuthBodyRowMinorText'
								maxWidth='200px'
							>
								{_.join(redFlags, '; ') || '--'}
							</TextWithTooltip>
						</Fragment>
					) : (
						<Fragment>
							<img
								src={greenFlagIcon}
								alt=''
								className='green-flag-icon'
								onClick={() =>
									this.props.showRedFlagAuditTrailModal(test)
								}
							/>
							<TextWithTooltip
								className='testAuthBodyRowMinorText green-flag-tooltip'
								maxWidth='200px'
							>
								{_.get(greenFlagReason, 'reason', '')}
							</TextWithTooltip>
						</Fragment>
					)}
				</Fragment>
			);
		}
		return '--';
	}

	parseDateForDate(timestamp) {
		if (!timestamp || !moment(timestamp).isValid() || timestamp === '--')
			return '--';
		const dateObj = moment(timestamp, 'YYYY-MM-DD hh:mm:ss').toDate();
		const newDateObj = new Date(
			dateObj.getTime() - dateObj.getTimezoneOffset() * 60 * 1000
		);
		return moment(newDateObj).format('MMM DD, YYYY');
	}

	parseDateForTime(timestamp) {
		if (!timestamp || !moment(timestamp).isValid() || timestamp === '--')
			return '--';
		const dateObj = moment(timestamp, 'YYYY-MM-DD hh:mm:ss').toDate();
		const newDateObj = new Date(
			dateObj.getTime() - dateObj.getTimezoneOffset() * 60 * 1000
		);
		return moment(newDateObj).format('hh:mm A z');
	}

	redirectToEncounterDetail(e) {
		if (
			// this.buttonCellApprove &&
			// this.buttonCellReject &&
			e &&
			e.target &&
			e.target !== this.buttonCellApprove &&
			e.target !== this.buttonCellReject &&
			e.target !== this.checkbox &&
			!e.target.classList.contains('el-checkbox__inner') &&
			get(e, 'target.type') !== 'checkbox'
		) {
			const test = get(this, 'props.test', {}) || {};
			const patientUuid = get(test, 'patient.uuid', null);
			const selectedPatient = get(test, 'patient', null);
			const encounterUuid = get(test, 'uuid', null);
			if (selectedPatient && patientUuid && encounterUuid) {
				this.props.redirectToEncounter(
					selectedPatient,
					patientUuid,
					encounterUuid
				);
			}
		}
	}

	renderOrderStatus() {
		const test = get(this, 'props.test', {}) || {};
		const orderStatusEnum = get(this, 'props.orderStatusEnum', []) || [];
		const orderStatus = get(test, 'order.order_status', null);
		if (orderStatus && Array.isArray(orderStatusEnum)) {
			const displayOrderStatus = _.find(
				orderStatusEnum,
				(el) => get(el, 'key', '') === orderStatus
			);
			if (orderStatus == 'requested') {
				if (!get(test, ['can_change_order'], false))
					return 'Not licensed to authorize';
				return (
					<RBAC
						action={CARE_TEST_ACTIONS}
						yes={
							<div className='testAuthCTACellButtons'>
								<div
									className='testAuthCTAButtons testAuthCTAApprove'
									onClick={(e) =>
										this.props.approveTest(
											get(test, 'order.uuid', null),
											test
										)
									}
									ref={(el) => (this.buttonCellApprove = el)}
								>
									Approve
								</div>
								<div
									className='testAuthCTAButtons testAuthCTAReject'
									onClick={(e) =>
										this.props.rejectTest(
											get(test, 'order.uuid', null),
											test
										)
									}
									ref={(el) => (this.buttonCellReject = el)}
								>
									Reject
								</div>
							</div>
						}
					/>
				);
			}
			if (displayOrderStatus)
				return get(displayOrderStatus, 'display_name', '') || '';
			else
				return RemoveUnderscoreAndCapitalizeInString(orderStatus || '');
		} else {
			return '--';
		}
	}

	renderSexAssignedAtBirth() {
		let result = 'Unknown';
		const sexAssignedAtBirthEnums = get(
			this,
			['props', 'sexAssignedAtBirthEnums'],
			[]
		);
		const sexAssignedAtBirth = get(
			this,
			['props', 'test', 'patient', 'sex_assigned_at_birth'],
			null
		);
		if (!_.isNil(sexAssignedAtBirth)) {
			const sexAssignedAtBirthVal = _.find(
				sexAssignedAtBirthEnums,
				(o) => o.key == sexAssignedAtBirth
			);
			result = get(
				sexAssignedAtBirthVal,
				['display_name'],
				sexAssignedAtBirth
			);
		}
		return result;
	}

	redirectToEncounterDetailURL = () => {
		const test = get(this, 'props.test', {}) || {};
		const patientUuid = get(test, 'patient.uuid', null);
		const encounterUuid = get(test, 'uuid', null);
		return (
			'/app/patientdetail/' +
			patientUuid +
			'/0/' +
			EncounterTabs.DETAIL +
			'/' +
			encounterUuid
		);
	};

	shippingStateFullName = (shippingState) => {
		return states_list.map((state) => {
			if (state.key == shippingState) {
				return state.value;
			}
			return '';
		});
	};

	render() {
		const test = get(this, 'props.test', {}) || {};
		const sortedField = this.props.sortedField;
		const testDate = get(test, 'order.date_test_requested', '--') || '--';
		const dob = get(test, 'patient.dob', '--') || '--';
		const fullName =
			get(test, 'patient.first_name', '--') ||
			'--' + ' ' + get(test, 'patient.last_name', '--') ||
			'--';
		const hasFlag = _.size(get(test, ['order', 'red_flags'], [])) > 0;

		return (
			<Tr highlight={this.determineIsChecked()}>
				<Td
					className='testAuthCheckboxCell'
					minWidth='56px'
					maxWidth='56px'
				>
					{get(test, ['can_change_order'], false) ? (
						<Checkbox
							checked={this.determineIsChecked()}
							onChange={(e) =>
								this.props.handleItemToggle(
									e,
									get(test, 'order.uuid', null)
								)
							}
						/>
					) : (
						' '
					)}
				</Td>
				<Td className='testAuthDateCell' minWidth='126px'>
					<div className={'testAuthBodyRowMajorText'}>
						{this.parseDateForDate(testDate)}
					</div>
					<div className='testAuthBodyRowMinorText'>
						{this.parseDateForTime(testDate)}
					</div>
				</Td>
				<Td
					className='testAuthNameCell'
					minWidth='220px'
					maxWidth='220px'
				>
					<div className='patient-name-state-wrapper'>
						<LinkPopover
							overflow={true}
							onClick={this.redirectToEncounterDetail}
							className='testAuthBodyRowMajorText'
							maxWidth='220px'
							linkTo={this.redirectToEncounterDetailURL()}
							options={{
								text: true,
								link: true,
								linkOptions: {
									text: fullName,
									style: {
										textTransform: 'capitalize',
										fontFamily: 'RionaSans-Medium',
									},
								},
							}}
						>
							{get(test, 'patient.first_name', '--') || '--'}{' '}
							{get(test, 'patient.last_name', '--') || '--'}
						</LinkPopover>
						<span className='patient-name-state-separator'>
							{' '}
							·{' '}
						</span>
						<Tooltip
							effect='dark'
							content={this.shippingStateFullName(
								get(test, 'patient.address.state', '')
							)}
							placement='top'
							className='tt-patient-shipping-address'
						>
							<span className='tooltip-info'>
								{get(test, 'patient.address.state', '')}
							</span>
						</Tooltip>
					</div>
					<div
						className='testAuthBodyRowMinorText'
						style={{ textTransform: 'capitalize' }}
					>
						{moment(dob).isValid()
							? moment.utc(dob).format('MMM DD, YYYY') +
							  ' (' +
							  moment().diff(dob, 'years') +
							  ' yrs)'
							: '--'}{' '}
						· {this.renderSexAssignedAtBirth()}
					</div>
				</Td>
				<Td className='testAuthPDFCell'>
					<ReactSVG
						className='pdfButton'
						src={pdfIcon}
						onClick={() => this.props.getEncounterDocuments(test)}
					/>
				</Td>
				<Td className='testAuthTestCell' minWidth='310px'>
					<div className='testAuthBodyRowMajorText'>
						{get(test, 'test_name', '--') || '--'}
					</div>
					<div className='testAuthBodyRowMinorText'>
						{get(test, 'lab.display_name', '--') || '--'}
					</div>
				</Td>
				<Td className='testAuthFlagCell' minWidth='234px'>
					{hasFlag ? (
						<div className='testAuthFlagCellFull'>
							{this.displayFlagAndReason()}
						</div>
					) : (
						<div className='testAuthFlagCellEmpty'>--</div>
					)}
				</Td>
				<Td
					className='testAuthStatusCell'
					minWidth='178px'
					maxWidth='unset'
				>
					<div
						className='testAuthBodyRowMajorText'
						style={{
							fontFamily: !this.determineHasFlag()
								? 'RionaSans-MediumItalic'
								: '',
							color: this.determineOrderStatusColor(),
							fontWeight: '500',
							fontStyle: !this.determineHasFlag()
								? 'italic'
								: 'normal',
							minWidth: '130px',
						}}
					>
						{this.renderOrderStatus()}
					</div>
				</Td>
			</Tr>
		);
	}
}

export default TestAuthTableRow;
