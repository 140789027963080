//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';
import find from 'lodash/find';
import _ from 'lodash';

//Utils
import { report_types } from '../../utils';

//Other Libraries
import moment from 'moment';

const ExportConfirmation = (props) => {
	const { affiliation_list, report_type, showAffiliationSelector, dates } =
		props;

	const getAffiiationName = () => {
		const list = [...affiliation_list];
		const affilationArray = _.map(
			get(props, ['affiliation_id'], []),
			(val) => {
				const tempobj = find(list, function (o) {
					return o.id == val;
				});
				return get(tempobj, 'display_name', '-');
			}
		);
		return affilationArray;
	};

	const getSubtypes = () => {
		const list = get(props, ['selected_report_subtypes'], []).map((e) => {
			return report_types[e];
		});
		return list.join(', ');
	};

	return (
		<div className='report_type_selection'>
			<div className='report_confirmation_grid'>
				<div style={{ display: 'flex' }}>
					<div className='report_label_headers'>Type of report</div>
					<div className='report_label_values'>
						<div className='report_type_main'>
							{report_types[report_type]}
						</div>
						{report_type in
						{ scheduling_reports: 1, appointment_reports: 1 } ? (
							<div className='report_type_subtype'>
								{' '}
								{getSubtypes()}{' '}
							</div>
						) : null}
					</div>
				</div>
				<div style={{ display: 'flex', marginTop: '44px' }}>
					{showAffiliationSelector && (
						<div className='report_label_headers'>
							{' '}
							Referral Program
						</div>
					)}
					{showAffiliationSelector && (
						<div
							className='report_type_main'
							style={{ marginLeft: '22px' }}
						>
							{_.map(getAffiiationName(), (value) => {
								return <div>{value}</div>;
							})}
						</div>
					)}
				</div>
				<div
					style={{
						display: 'flex',
						marginTop: '44px',
						marginBottom: '60px',
					}}
				>
					<div className='report_label_headers'> Date range </div>
					<div
						className='report_type_main'
						style={{ marginLeft: '22px' }}
					>
						{' '}
						{moment(get(dates, 'start_date')).format(
							'MMM D, YYYY'
						) +
							' to ' +
							moment(get(dates, 'end_date')).format(
								'MMM D, YYYY'
							)}{' '}
					</div>
				</div>
			</div>
		</div>
	);
};
export default ExportConfirmation;
