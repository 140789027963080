//React & Redux
import React, { Fragment } from 'react';

//Images
import group from '../../../assets/group.png';

//Lodash
import _ from 'lodash';

//Utils
import {
	imageDefault,
	providers_images,
	scp_encounter_sub_types,
} from '../../../Utils/encounterUtils';
import {
	isPartnerInitiatedPreTest,
	isPartnerInitiatedUnsolicitedResult,
} from '../../Patients/V2/Encounters/Common/utilsfunctions';

//UI Libraries
import { Layout } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import EncounterTestOrders from './EncounterTestOrders';
import EncounterTestOrdersPreAuth from './EncounterTestOrdersPreAuth';
import LinkPopover from '../../Common/Popovers/LinkPopover';
import LinkedEncounter from './LinkedEncounter';
import Tooltip from '../../Common/Tooltip';

const encounterTypes = {
	lab_test_authorization: 'Lab',
	visit: 'Visit',
	Visit: 'Visit',
	'group-session': 'Group session',
	'cc-intake': 'CC Intake',
	'Lab Test Authorization': 'Lab',
	'research-data': 'Research Data',
	'Group-Session': 'Group Session',
	'Research-Data': 'Research Data',
	scp: 'SCP',
};

const EncounterInfoSegment = (props) => {
	const {
		bloodRequestEnums = [],
		encounterDetail,
		encounterDetail: {
			blood_draw_order_date: bloodDrawOrderDate = '',
			blood_draw_request: bloodDrawRequest = '--',
			consultation_type: consultationType = '',
			date_of_service,
			date_test_ordered: dateTestOrdered = '',
			encounter_subtype: encounterSubType = '',
			lab,
			type = '',
			type_of_test: typeOfTest = '--',
			uuid: encounterUuid = 0,
			user_uuid: userUuid = 0,
			visit_provider: encounterVisitProvider = '',
		},
		enqueueSnackbar,
		history,
		labEnums = [],
		multiple_labs: multipleLabs = false,
		patientDetail,
		patientDetail: {
			first_name: firstName,
			last_name: lastName,
			referral_channel_name: referralChannelName = '-',
		},
		testOrders,
		testOrdersPreAuth,
		typeOfTestEnums,
		visitProvider = [],
	} = props;

	const viewEncountBillingHandlerURL = `/app/patientdetail/${userUuid}/0/2/${encounterUuid}/`;

	const getNameFromEnums = (enums, key) => {
		const enumObj = enums.find((o) => o.key == key);
		return _.get(enumObj, 'display_name', key);
	};

	const getShortText = (text, l) => {
		if (text && text.length > l) {
			return (
				<Tooltip
					name='name_tooltip'
					className='name_tooltip'
					content={text}
					placement='top-center'
				>
					<span>{text.substr(0, l)}...</span>
				</Tooltip>
			);
		} else {
			return !_.isEmpty(text) ? (
				text
			) : (
				<span className='pat-dash'>--</span>
			);
		}
	};

	const getProviderImage = (value) => {
		let providerImage = null;
		const providerName = value;
		const mappedImage = providers_images[providerName];

		if (providerName && mappedImage) {
			providerImage = <img src={mappedImage} alt='' />;
		} else if (providerName != '')
			providerImage = <img src={imageDefault} alt='' />;
		else {
			providerImage = null;
		}
		return (
			providerImage && (
				<span className='patient-imgbox'>{providerImage}</span>
			)
		);
	};

	const ucFirstAllWords = (str, separator = ' ') => {
		const pieces = str ? str.split(separator) : [];
		for (let i = 0; i < pieces.length; i++) {
			const j = pieces[i].charAt(0).toUpperCase();
			pieces[i] = j + pieces[i].substr(1).toLowerCase();
		}
		return pieces.join(separator);
	};

	const getProviderName = (value) => {
		let result = '';
		let providerName = '';
		let separator = ' ';
		const enumValue = visitProvider.find((o) => o.key == value);
		if (enumValue) {
			providerName = _.get(enumValue, 'display_name', '');
		} else {
			separator = '_';
			providerName = ucFirstAllWords(value, separator);
		}
		if (providerName && providerName.length > 0) {
			const providerNameObj = providerName.split(separator);
			const first_name = _.get(providerNameObj, 0, '');
			const middle_name =
				providerName.length > 2
					? ' ' + _.get(providerNameObj, 1, '')
					: '';
			const last_name =
				providerName.length > 2
					? ' ' + _.get(providerNameObj, 2, '')
					: ' ' + _.get(providerNameObj, 1, '');
			result = first_name.charAt(0) + '.' + middle_name + last_name;
			return <span>{getShortText(result, 12)} </span>;
		} else {
			return <span className='pat-dash'>--</span>;
		}
	};

	const displayDate = (value) =>
		_.get(value, 'length', 0) > 0 && moment(value).isValid()
			? moment(value).format('MMM D, YYYY')
			: '--';

	const viewEncountBillingHandler = () => {
		history.push({
			pathname: viewEncountBillingHandlerURL,
			state: {
				breadcrumbs: [
					{
						location: 'Patient Detail',
						patientFullName: `${firstName || '--'} ${
							lastName || '--'
						}`,
					},
				],
			},
		});
	};

	const isMLO = () =>
		type == 'lab_test_authorization' || type == 'research-data'
			? false
			: true;

	const dateOfService =
		_.get(date_of_service, 'length', 0) > 0
			? moment(date_of_service || '').format('MMM D, YYYY')
			: '--';
	const isSCPEncounter = type == 'scp';
	const isGroupSessionOrCCIntake =
		type in { visit: 1, 'group-session': 1, 'cc-intake': 1 };
	const consulationType = isSCPEncounter
		? isPartnerInitiatedPreTest(encounterDetail)
			? ''
			: isPartnerInitiatedUnsolicitedResult(encounterDetail)
			? ''
			: _.get(scp_encounter_sub_types, encounterSubType, '')
		: consultationType;

	return (
		<>
			<div className='body'>
				<Layout.Row gutter='20'>
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>Date of Service</span>
						<span className='val-enctr-sgmnt-field'>
							{dateOfService}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>Encounter Type</span>
						<span className='val-enctr-sgmnt-field'>
							{isPartnerInitiatedPreTest(encounterDetail) ? (
								<span className='val-enctr-sgmnt-field billing-encount-link'>
									<LinkPopover
										enqueueSnackbar={enqueueSnackbar}
										linkTo={viewEncountBillingHandlerURL}
										options={{ link: true }}
									>
										<span
											onClick={viewEncountBillingHandler}
										>
											TRO
										</span>
									</LinkPopover>
								</span>
							) : isPartnerInitiatedUnsolicitedResult(
									encounterDetail
							  ) ? (
								'TRO - Results Only'
							) : (
								encounterTypes[type]
							)}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>
							Referral Channel
						</span>
						<span className='val-enctr-sgmnt-field'>
							{referralChannelName}
						</span>
					</Layout.Col>
				</Layout.Row>

				<Layout.Row gutter='20'>
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>
							Consultation Type
						</span>
						{!_.isEmpty(consulationType) ? (
							<span className='val-enctr-sgmnt-field billing-encount-link'>
								<LinkPopover
									enqueueSnackbar={enqueueSnackbar}
									linkTo={viewEncountBillingHandlerURL}
									options={{ link: true }}
								>
									<span onClick={viewEncountBillingHandler}>
										{consulationType}
									</span>
								</LinkPopover>
							</span>
						) : (
							<span className='val-enctr-sgmnt-field'>
								<span className='pat-dash'>--</span>
							</span>
						)}
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>Visit Provider</span>
						<span className='val-enctr-sgmnt-field'>
							{getProviderImage(encounterVisitProvider)}
							{getProviderName(encounterVisitProvider)}
						</span>
					</Layout.Col>
					{isSCPEncounter && (
						<Layout.Col span='8'>
							<LinkedEncounter
								{...{
									encounter: encounterDetail,
									history,
									patient: patientDetail,
								}}
							/>
						</Layout.Col>
					)}
				</Layout.Row>

				{!isMLO() && (
					<Layout.Row gutter='20'>
						<Layout.Col span='8'>
							<span className='lbl-enctr-sgmnt'>Lab</span>
							<span className='val-enctr-sgmnt-field'>
								{_.get(lab, 'length', 0) > 0
									? getNameFromEnums(labEnums, lab)
									: '--'}{' '}
								<img
									className='icon-lab'
									src={group}
									alt=''
									style={{
										display:
											multipleLabs &&
											_.get(lab, 'length', 0) > 0
												? ''
												: 'none',
									}}
								/>{' '}
							</span>
						</Layout.Col>
						<Layout.Col span='8'>
							<span className='lbl-enctr-sgmnt'>
								Test Dispatch Date
							</span>
							<span className='val-enctr-sgmnt-field'>
								{displayDate(dateTestOrdered)}
							</span>
						</Layout.Col>
						<Layout.Col span='8'>
							<span className='lbl-enctr-sgmnt'>Test Type</span>
							<span className='val-enctr-sgmnt-field'>
								{_.get(typeOfTest, 'length', 0) > 0
									? getNameFromEnums(
											typeOfTestEnums,
											typeOfTest
									  )
									: '--'}
							</span>
						</Layout.Col>
					</Layout.Row>
				)}
				{isGroupSessionOrCCIntake && !isMLO() && (
					<Layout.Row gutter='20'>
						<Fragment>
							<Layout.Col span='8'>
								<span className='lbl-enctr-sgmnt'>
									Blood Draw Request
								</span>
								<span className='val-enctr-sgmnt-field'>
									{getNameFromEnums(
										bloodRequestEnums,
										bloodDrawRequest
									)}
								</span>
							</Layout.Col>
							<Layout.Col span='8'>
								<span className='lbl-enctr-sgmnt'>
									Blood Draw Order Date
								</span>
								<span className='val-enctr-sgmnt-field'>
									{displayDate(bloodDrawOrderDate)}
								</span>
							</Layout.Col>
						</Fragment>
					</Layout.Row>
				)}
				{isMLO() && !_.isEmpty(testOrders) && (
					<EncounterTestOrders
						{...{
							bloodRequestEnums,
							getProviderImage,
							getProviderName,
							labEnums,
							testOrders,
						}}
					/>
				)}
				{isMLO() && !_.isEmpty(testOrdersPreAuth) && (
					<EncounterTestOrdersPreAuth
						testOrders={testOrdersPreAuth}
					/>
				)}
			</div>
		</>
	);
};

export default EncounterInfoSegment;
