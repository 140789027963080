//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//Components
import EncounterDetail from './EncounterDetail';
import PatientDetail from './PatientDetail';

const BillingDetail = (props) => {
	const {
		billingSegmentProps,
		closeDetailView,
		encounterDetail,
		encounterInfoSegmentProps,
		enqueueSnackbar,
		history,
		patientDetail,
		sexAssignedAtBirthEnum,
		referralPrograms,
	} = props;

	return (
		<>
			<PatientDetail
				{...{
					closeDetailView,
					enqueueSnackbar,
					patientDetail,
					sexAssignedAtBirthEnum,
				}}
			/>
			<EncounterDetail
				{...{
					billingSegmentProps,
					encounterDetail,
					encounterInfoSegmentProps,
					history,
					patientDetail,
				}}
			/>
		</>
	);
};

export default BillingDetail;
