//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import Checkbox from './../Checkbox.js';

//Styles
import './CheckboxRow.css';

class CheckboxRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasOnChangeFunc: false,
		};
		//bindings
		this.determineHasOnChangeFunc =
			this.determineHasOnChangeFunc.bind(this);
		this.checkboxRowClickHandler = this.checkboxRowClickHandler.bind(this);
	}
	//functions

	componentDidMount() {
		this.determineHasOnChangeFunc();
	}

	determineHasOnChangeFunc() {
		const onChangeFunc = get(this, 'props.onChangeFunc', null);
		if (typeof onChangeFunc === 'function')
			this.setState({ hasOnChangeFunc: true });
	}

	checkboxRowClickHandler(e) {
		if (
			e.target.classList.contains('el-checkbox__inner') ||
			e.target.classList.contains('el-checkbox__original')
		)
			return;
		const hasOnChangeFunc = get(this, 'state.hasOnChangeFunc', false);
		const checked = get(this, 'props.checked', false);
		if (hasOnChangeFunc) {
			this.props.onChangeFunc(!checked);
		}
	}

	render() {
		const checked = get(this, 'props.checked', false);
		const disabled = get(this, 'props.disabled', false);
		const className = get(this, 'props.className', false);
		let containerClass = checked
			? 'checkboxRowContainer checkboxRowContainerChecked'
			: 'checkboxRowContainer';
		if (disabled) containerClass += ' checkboxRowContainerDisabled';
		if (className) containerClass += ` ${className}`;
		const hasOnChangeFunc = get(this, 'state.hasOnChangeFunc', false);
		const label = get(this, 'props.label', null) || '--';
		const rightEl = get(this, 'props.rightEl', null);
		const bottomEl = get(this, 'props.bottomEl', null);
		const containerStyleOverride =
			get(this, 'props.containerStyleOverride', null) || {};
		const expandedMainRowOverride =
			get(this, 'props.expandedMainRowOverride', null) || {};
		return (
			<div
				className={containerClass}
				style={{
					height: checked && bottomEl ? 'unset' : '48px',
					...containerStyleOverride,
				}}
			>
				<div
					className='checkboxRowMainRow'
					onClick={!disabled && this.checkboxRowClickHandler}
					style={{
						cursor: disabled ? 'no-drop' : 'pointer',
						...expandedMainRowOverride,
					}}
				>
					<div className='checkboxRowMainRowLeft'>
						<Checkbox
							checked={checked}
							marginLeftOverride={0}
							marginRightOverride={13}
							topOverride={-3}
							hasOnChangeFunc={hasOnChangeFunc}
							onChangeFunc={
								hasOnChangeFunc && !disabled
									? this.props.onChangeFunc
									: null
							}
							disabled={disabled}
						/>
						<div className='checkboxRowMainRowLeftLabel'>
							{label}
						</div>
					</div>
					<div className='checkboxRowMainRowRight'>{rightEl}</div>
				</div>
				<div className='checkboxRowSecondRow'>
					{checked && bottomEl}
				</div>
			</div>
		);
	}
}

export default CheckboxRow;
