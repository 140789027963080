//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import { biologicalSexVariants } from '../../Utils/appointmentUtils';
import { trimAllTextFields } from './../../utils.js';

//UI Libraries
import { Layout } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import CreditCardSection from './CreditCardSection';
import InsuranceSection from './InsuranceSection';
import SecondaryInsuranceSection from './SecondaryInsuranceSection';
import PreFilledCreditCard from './PreFilledCreditCard';
import AppointmentSection from './AppointmentSection';

class PaymentStage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			paytype: 'self-pay',
			isSecondaryInsurance: false,
		};
		//bindings
		this.getFormRefs = this.getFormRefs.bind(this);
		this.isCreditCardShow = this.isCreditCardShow.bind(this);
		this.updatePayType = this.updatePayType.bind(this);
		this.changePaymentMethod = this.changePaymentMethod.bind(this);
	}

	//functions

	componentDidMount() {
		const patient = _.get(
			this,
			['props', 'newAppointment', 'patientInfo'],
			{}
		);
		const insurance_enabled = this.props.PartnerDetail?.insurance_enabled;
		const paytype = insurance_enabled ? 'insurance' : 'self-pay';
		this.setState({ paytype }, () => {
			this.updatePayType(paytype);
		});
		this.props.setNonzerobookbtnDisbled(); // by default make btn disbled
		this.props.setPayor(paytype);
		const isCardChange = _.get(patient, 'has_payment_method', false);
		this.props.updateAppointmentparams({ isCardChange });
		setTimeout(() => {
			this.props.PaymentStageChange();
		}, 200);
	}

	getFormRefs = () => {
		// It will pass the refrence of section to schedule apppintment
		return {
			InsuranceSection: this.refs.InsuranceSection,
			CreditCardSection: this.refs.CreditCardSection,
			SecondaryInsuranceSection: this.refs.SecondaryInsuranceSection,
		};
	};

	isSelfPayShow() {
		const selectedconsulation = this.props.selectedconsultation();
		const is_predefined = _.get(
			selectedconsulation,
			'is_predefined',
			false
		);
		const insurance_only = _.get(
			this,
			['props', 'PartnerDetail', 'insurance_only'],
			false
		);
		return insurance_only === false || is_predefined == true;
	}

	isInsuranceShow() {
		const insurance_enabled = _.get(
			this,
			['props', 'PartnerDetail', 'insurance_enabled'],
			false
		);
		const insurance_only = _.get(
			this,
			['props', 'PartnerDetail', 'insurance_only'],
			false
		);
		const selectedconsulation = this.props.selectedconsultation();
		const is_predefined = _.get(
			selectedconsulation,
			'is_predefined',
			false
		);
		return (
			(insurance_enabled === true || insurance_only === true) &&
			is_predefined == false
		);
	}

	isCreditCardShow() {
		const { useInsurance, insurancePrice } = _.get(
			this,
			['props', 'newAppointment'],
			{}
		);
		const price = this.props.calculatePrice();
		const couponcodeAvaiable = !_.isNil(this.props.couponCodeSuccess);
		const zeropricewithcoupon =
			price != undefined &&
			price == 0 &&
			couponcodeAvaiable &&
			useInsurance == false;
		return !(
			(useInsurance == true && insurancePrice == 0) ||
			zeropricewithcoupon
		);
	}

	onChange = (value) => {
		this.setState({ paytype: value }, () => {
			this.updatePayType(value);
			this.props.PaymentStageChange();
			this.props.setPayor(value);
		});
	};

	updatePayType(paytype) {
		const useInsurance = paytype == 'insurance' ? true : false;
		this.props.updateAppointmentparams({ useInsurance });
		if (useInsurance) {
			this.props.resetCouponCodeDetails();

			const patient = _.get(
				this,
				['props', 'newAppointment', 'patientInfo'],
				{}
			);
			const insurances = _.get(patient, 'insurances', []);
			let secondaryInsurance = {};
			if (_.get(insurances, 'length', 0) > 0) {
				secondaryInsurance = _.find(insurances, (ins) => {
					return ins.insurance_type == 'secondary';
				});
				if (secondaryInsurance)
					this.setState({ isSecondaryInsurance: true });
			}
		}
	}

	updateSecondaryInsurance = (flag) => {
		this.setState({ isSecondaryInsurance: flag });
	};

	changePaymentMethod(value) {
		this.props.updateAppointmentparams({ isCardChange: value });
		this.props.PaymentStageChange();
	}

	getPaymentDetail() {
		const patient = _.get(
			this,
			['props', 'newAppointment', 'patientInfo'],
			{}
		);
		const user_payment_detail = {
			address_line1: _.get(
				patient,
				['address', 'billing', 'address_line1'],
				''
			),
			address_line2: _.get(
				patient,
				['address', 'billing', 'address_line2'],
				''
			),
			city: _.get(patient, ['address', 'billing', 'city'], ''),
			state: _.get(patient, ['address', 'billing', 'state'], ''),
			zip: _.get(patient, ['address', 'billing', 'zip'], ''),
			name: _.get(patient, ['name'], ''),
			number: '',
		};
		return user_payment_detail;
	}

	getCardPlaceHolder() {
		const patient = _.get(
			this,
			['props', 'newAppointment', 'patientInfo'],
			{}
		);
		return _.isEmpty(_.get(patient, ['last4'], ''))
			? '**** **** **** ****'
			: '**** **** **** ' + _.get(patient, ['last4'], '');
	}

	getInsuranceDetail() {
		const patient = _.get(
			this,
			['props', 'newAppointment', 'patientInfo'],
			{}
		);
		const insurances = _.get(patient, 'insurances', []);
		let PrimaryInsurance = {};
		let secondaryInsurance = {};
		if (_.get(insurances, 'length', 0) > 0) {
			PrimaryInsurance = _.find(insurances, (ins) => {
				return ins.insurance_type == 'primary';
			});
			secondaryInsurance = _.find(insurances, (ins) => {
				return ins.insurance_type == 'secondary';
			});
		}
		let policyHolderBiologicalSex = _.get(
			PrimaryInsurance,
			'policyholder_biological_sex',
			null
		);
		const validBiologicalSexObj = biologicalSexVariants.find(
			(variant) => variant && variant.value == policyHolderBiologicalSex
		);
		if (_.isEmpty(validBiologicalSexObj)) {
			policyHolderBiologicalSex = '';
		}

		const user_insurance_detail = {
			insurance_company_id: _.get(
				PrimaryInsurance,
				['insurance', 'insurance_company_id'],
				0
			),
			company_name: _.get(
				PrimaryInsurance,
				['insurance', 'company_name'],
				''
			),
			insurance_no: _.get(
				PrimaryInsurance,
				['insurance', 'insurance_no'],
				''
			),
			group_number: _.get(
				PrimaryInsurance,
				['insurance', 'group_no'],
				''
			),
			plan_name: _.get(
				PrimaryInsurance,
				['insurance_plan_type', 'name'],
				''
			),
			phone: _.get(PrimaryInsurance, ['insurance', 'phone'], ''),
			is_policyholder: _.get(PrimaryInsurance, 'is_policyholder', true),
			policyholder_relationship: _.get(
				PrimaryInsurance,
				'policyholder_relationship',
				''
			),
			policyholder_first_name: _.get(
				PrimaryInsurance,
				'policyholder_first_name',
				''
			),
			policyholder_last_name: _.get(
				PrimaryInsurance,
				'policyholder_last_name',
				''
			),
			policyholder_dob: _.get(PrimaryInsurance, 'policyholder_dob')
				? moment(
						_.get(PrimaryInsurance, 'policyholder_dob', ''),
						'YYYY-MM-DD'
				  ).format('MM/DD/YYYY')
				: '',
			patient_id: _.get(PrimaryInsurance, 'id', ''),
			patient_uuid: _.get(patient, 'uuid', ''),
			employer: _.get(PrimaryInsurance, ['insurance', 'employer'], ''),
			insurance_address: _.get(
				PrimaryInsurance,
				['insurance', 'insurance_address'],
				''
			),
			insurance_city: _.get(
				PrimaryInsurance,
				['insurance', 'insurance_city'],
				''
			),
			insurance_state: _.get(
				PrimaryInsurance,
				['insurance', 'insurance_state'],
				''
			),
			insurance_zipcode: _.get(
				PrimaryInsurance,
				['insurance', 'insurance_zipcode'],
				''
			),
			policyholder_biological_sex: policyHolderBiologicalSex,
			is_secondary_insurance:
				!_.isNil(secondaryInsurance) && !_.isEmpty(secondaryInsurance),
		};
		return trimAllTextFields(user_insurance_detail);
	}

	getSecondaryInsuranceDetail() {
		const patient = _.get(
			this,
			['props', 'newAppointment', 'patientInfo'],
			{}
		);
		const insurances = _.get(patient, 'insurances', []);
		let secondaryInsurance = {};
		if (_.get(insurances, 'length', 0) > 0) {
			secondaryInsurance = _.find(insurances, (ins) => {
				return ins.insurance_type == 'secondary';
			});
		}
		let policyHolderBiologicalSex = _.get(
			secondaryInsurance,
			'policyholder_biological_sex',
			null
		);
		const validBiologicalSexObj = biologicalSexVariants.find(
			(variant) => variant && variant.value == policyHolderBiologicalSex
		);
		if (_.isEmpty(validBiologicalSexObj)) {
			policyHolderBiologicalSex = '';
		}

		const user_insurance_detail = {
			insurance_company_id: _.get(
				secondaryInsurance,
				['insurance', 'insurance_company_id'],
				0
			),
			company_name: _.get(
				secondaryInsurance,
				['insurance', 'company_name'],
				''
			),
			insurance_no: _.get(
				secondaryInsurance,
				['insurance', 'insurance_no'],
				''
			),
			group_number: _.get(
				secondaryInsurance,
				['insurance', 'group_no'],
				''
			),
			plan_name: _.get(
				secondaryInsurance,
				['insurance_plan_type', 'name'],
				''
			),
			phone: _.get(secondaryInsurance, ['insurance', 'phone'], ''),
			is_policyholder: _.get(secondaryInsurance, 'is_policyholder', true),
			policyholder_relationship: _.get(
				secondaryInsurance,
				'policyholder_relationship',
				''
			),
			policyholder_first_name: _.get(
				secondaryInsurance,
				'policyholder_first_name',
				''
			),
			policyholder_last_name: _.get(
				secondaryInsurance,
				'policyholder_last_name',
				''
			),
			policyholder_dob: _.get(secondaryInsurance, 'policyholder_dob')
				? moment(
						_.get(secondaryInsurance, 'policyholder_dob', ''),
						'YYYY-MM-DD'
				  ).format('MM/DD/YYYY')
				: '',
			patient_id: _.get(secondaryInsurance, 'id', ''),
			patient_uuid: _.get(patient, 'uuid', ''),
			employer: _.get(secondaryInsurance, ['insurance', 'employer'], ''),
			insurance_address: _.get(
				secondaryInsurance,
				['insurance', 'insurance_address'],
				''
			),
			insurance_city: _.get(
				secondaryInsurance,
				['insurance', 'insurance_city'],
				''
			),
			insurance_state: _.get(
				secondaryInsurance,
				['insurance', 'insurance_state'],
				''
			),
			insurance_zipcode: _.get(
				secondaryInsurance,
				['insurance', 'insurance_zipcode'],
				''
			),
			policyholder_biological_sex: policyHolderBiologicalSex,
		};
		return trimAllTextFields(user_insurance_detail);
	}

	getDefaultInsuranceDetail() {
		const patient = _.get(
			this,
			['props', 'newAppointment', 'patientInfo'],
			{}
		);
		const user_insurance_detail = {
			company_name: '',
			insurance_no: '',
			group_number: '',
			plan_name: '',
			phone: '',
			is_policyholder: true,
			policyholder_relationship: '',
			policyholder_first_name: '',
			policyholder_last_name: '',
			policyholder_dob: '',
			patient_id: _.get(patient, 'id', ''),
			patient_uuid: _.get(patient, 'uuid', ''),
			employer: '',
			insurance_address: '',
			insurance_city: '',
			insurance_state: '',
			insurance_zipcode: '',
			policyholder_biological_sex: '',
		};
		return user_insurance_detail;
	}

	getHomeAddressDetail() {
		const patient = _.get(
			this,
			['props', 'newAppointment', 'patientInfo'],
			{}
		);
		const homeaddress = {
			address_line1: _.get(
				patient,
				['address', 'home', 'address_line1'],
				''
			),
			address_line2: _.get(
				patient,
				['address', 'home', 'address_line2'],
				''
			),
			city: _.get(patient, ['address', 'home', 'city'], ''),
			state: _.get(patient, ['address', 'home', 'state'], ''),
			zip: _.get(patient, ['address', 'home', 'zip'], ''),
		};
		return homeaddress;
	}

	getPrifilledCardInfo() {
		const patient = _.get(
			this,
			['props', 'newAppointment', 'patientInfo'],
			{}
		);
		const card = {
			brand: _.get(patient, ['brand'], 'visa'),
			last4: _.get(patient, ['last4'], ''),
			exp_year: _.get(patient, ['exp_year'], ''),
			exp_month: _.get(patient, ['exp_month'], ''),
			name: _.get(patient, ['name'], ''),
		};
		return card;
	}

	render() {
		return (
			<div className='appointmentshedule-maincontainer'>
				<div className='appointmentschedule-paymentstage'>
					<AppointmentSection
						heading='Payment Method'
						description=''
						isRequired={true}
						horizontalLine={true}
						marginAuto={true}
					>
						<section className='patient-info-container'>
							<Layout.Row
								gutter='16'
								style={{ marginBottom: '27px' }}
							>
								<Layout.Col span='24'>
									{this.props.renderSwitch({
										label: 'How would the Patient like to pay?',
										component: this.props.renderSwitch,
										input: {
											name: 'paymentMethod',
											onChange: this.onChange,
											value:
												_.get(
													this,
													'state.paytype',
													null
												) || 'insurance',
										},
										isrequired: true,
										options: [
											{
												disabled: !this.isSelfPayShow(),
												label: 'Non-insurance',
												value: 'self-pay',
											},
											{
												disabled:
													!this.isInsuranceShow(),
												label: 'Insurance',
												value: 'insurance',
											},
										],
									})}
								</Layout.Col>
							</Layout.Row>
						</section>
					</AppointmentSection>

					{this.state.paytype == 'insurance' && (
						<InsuranceSection
							ref='InsuranceSection'
							insuranceCompanies={this.props.insuranceCompanies}
							initialValues={{ ...this.getInsuranceDetail() }}
							StoredInsurance={{ ...this.getInsuranceDetail() }}
							defaultInsuranceDetail={{
								...this.getDefaultInsuranceDetail(),
							}}
							onChange={this.props.PaymentStageChange}
							updateAppointmentparams={
								this.props.updateAppointmentparams
							}
							patient={_.get(
								this,
								['props', 'newAppointment', 'patientInfo'],
								{}
							)}
							isSelfPayShow={this.isSelfPayShow()}
							selectSelfPay={() => this.onChange('self-pay')}
							renderSwitch={this.props.renderSwitch}
							planTypesEnums={this.props.planTypesEnums}
							updateSecondaryInsurance={
								this.updateSecondaryInsurance
							}
						/>
					)}
					{this.state.paytype == 'insurance' &&
						this.state.isSecondaryInsurance && (
							<SecondaryInsuranceSection
								ref='SecondaryInsuranceSection'
								insuranceCompanies={
									this.props.insuranceCompanies
								}
								initialValues={{
									...this.getSecondaryInsuranceDetail(),
								}}
								StoredInsurance={{
									...this.getSecondaryInsuranceDetail(),
								}}
								defaultInsuranceDetail={{
									...this.getDefaultInsuranceDetail(),
								}}
								patient={_.get(
									this,
									['props', 'newAppointment', 'patientInfo'],
									{}
								)}
								isSelfPayShow={this.isSelfPayShow()}
								selectSelfPay={() => this.onChange('self-pay')}
								renderSwitch={this.props.renderSwitch}
								planTypesEnums={this.props.planTypesEnums}
								onChange={this.props.PaymentStageChange}
							/>
						)}

					{this.isCreditCardShow() == true && (
						<div className='appointmentschedule-creditcardsection'>
							{!this.props.newAppointment.isCardChange ? (
								<Fragment>
									{((this.state.paytype === 'self-pay' &&
										!this.props.isZeroPriceConsultation()) ||
										this.state.paytype === 'insurance') && (
										<CreditCardSection
											ref='CreditCardSection'
											initialValues={{
												...this.getPaymentDetail(),
											}}
											homeaddress={{
												...this.getHomeAddressDetail(),
											}}
											onChange={
												this.props.PaymentStageChange
											}
											cardPlaceHolder={this.getCardPlaceHolder()}
											renderAddressSection={
												this.props.renderAddressSection
											}
											selectedAddressIdx={_.get(
												this,
												'props.selectedAddressIdx',
												null
											)}
											addresses={
												_.get(
													this,
													'props.addresses',
													null
												) || []
											}
											addressErrors={
												_.get(
													this,
													'props.addressErrors',
													null
												) || []
											}
										/>
									)}
								</Fragment>
							) : (
								((this.state.paytype === 'self-pay' &&
									!this.props.isZeroPriceConsultation()) ||
									this.state.paytype === 'insurance') && (
									<PreFilledCreditCard
										changePaymentMethod={
											this.changePaymentMethod
										}
										cardInfo={{
											...this.getPrifilledCardInfo(),
										}}
									/>
								)
							)}
						</div>
					)}

					<div className='insurance-footer-button'>
						<div>
							<button
								type='button'
								className='health-backbutton'
								onClick={() => this.props.goToStep(1)}
							>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PaymentStage;
