import React from 'react';
import { states_list as statesList } from '../../../utils';

import { Table, Tbody, Tr, Thead, Th, Td } from '../../Common/Table';
import ThreeDotsMenu from '../../Common/controls/ThreeDotsMenu';

const DefaultProvidersTable = (props) => {
	const {
		defaultProviders = [],
		getSpecialty,
		handleRemoveDefaultProvider,
		showProviderModalEdit,
		tableLoading,
	} = props;

	const getFullStateName = (key) => {
		const stateObj = statesList.find((s) => s.key === key);
		return stateObj?.value;
	};

	const tableRows = defaultProviders.map((provider) => {
		return (
			<Tr key={provider.uuid}>
				<Td minWidth='198px' maxWidth='224px'>
					{provider.first_name + ' ' + provider.last_name}
				</Td>
				<Td>{provider.title}</Td>
				<Td>{provider.organization_name}</Td>
				<Td>{getFullStateName(provider?.address?.office?.state)}</Td>
				<Td>{getSpecialty(provider?.specialties?.[0])}</Td>
				<Td minWidth='unset' maxWidth='unset'>
					<ThreeDotsMenu
						options={[
							{
								label: 'Edit Provider Info',
								click: () => showProviderModalEdit(provider),
							},
							{
								label: 'Remove Default Provider',
								click: () =>
									handleRemoveDefaultProvider(provider.uuid),
							},
						]}
					/>
				</Td>
			</Tr>
		);
	});

	return (
		<Table style={{ marginTop: '16px' }} loading={tableLoading}>
			<Thead>
				<Tr>
					<Th>Provider</Th>
					<Th>Credentials</Th>
					<Th>Organization</Th>
					<Th>State</Th>
					<Th>Specialty</Th>
					<Th />
				</Tr>
			</Thead>
			<Tbody>{tableRows}</Tbody>
		</Table>
	);
};

export default DefaultProvidersTable;
