//React & Redux
import React, { Fragment, useEffect, useState, useRef } from 'react';

//Images
import ReactSVG from 'react-svg';
import alert from '../../assets/alert_dob.svg';
import editIcon from '../../assets/edit-patient.svg';
import imageWarningRed from '../../assets/warning-red.svg';
import noOutreachIcon from './../../assets/do-not-outreach.svg';
import resetPasswordIcon from '../../assets/reset-pw.svg';
import setDeceasedIcon from '../../assets/deceased.svg';
import setPasswordIcon from '../../assets/set-pw.svg';
import trashIcon from './../../assets/delete.svg';
import disableNoOutreachIcon from './../../assets/phone-check.svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import {
	RemoveUnderscoreAndCapitalizeFirstLetter,
	UTCToCurrentView,
	currentAddressKey,
	PatientConsentStatus,
} from './../../utils';
import { isFeatureEnabled } from '../../Utils/featureFlags';
import {
	CARE_PATIENTS_PATIENT_DELETE,
	CARE_PATIENTS_PATIENT_SETTINGS,
} from '../../Utils/permissionUtils';

//UI Libraries
import { Dialog, Loading, Tabs, Button } from 'gm-element-react';

//Other Libraries
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';

//Actions
import { sendAuthToRecontactOutreach } from '../../actions/patients';

//Components
import RBAC from '../RBAC';
import PatientDeleteDialog from './PatientDeleteDialog';
import PatientDemographicGeneralTab from './PatientDemographicGeneralTab.js';
import PatientDemographicContactTab from './PatientDemographicContactTab.js';
import PatientDemographicTimelineTab from './PatientDemographicTimelineTab.js';
import PatientInfoBox from './PatientInfoBox.js';
import ExternalCareTeam from '../ExternalProviders/ExternalCareTeam';
import TooltipDOB from '../Common/Tooltip';
import ThreeDotsMenu from '../Common/controls/ThreeDotsMenu';
import AuditTrail from './AuditTrail/AuditTrail';
import SendAuthToRecontactDialog from './SendAuthToRecontactDialog';

const PatientInfo = (props) => {
	const {
		activateDoNoResetPatient,
		classes,
		consenttotreat,
		duplicatePatients,
		eligibilityFields,
		encounter_list,
		encounterloading,
		enqueueSnackbar,
		gender,
		getPatientDetail,
		hasDeceasedTag,
		hasDischargedTag,
		hasDuplicatePatientTag,
		hasFinancialAssistanceTag,
		hasFinancialAssistancePerVisitTag,
		hashearingImpairedTag,
		hasLanguageLineSupportTag,
		hasLanguageLineSupportTagLanguage,
		hasMarketingOptoutTag,
		hasNoFurtherEscalationTag,
		hasPatientStoryTag,
		hasPriorityTag,
		hasPriorityTagLanguage,
		hasResearchOptoutTag,
		hasSpeechImpairedTag,
		hasVIPTag,
		hasVisionImpairedTag,
		history,
		linkedProviders,
		openDateTimeDisabledOutreach,
		outreachSetting,
		patient,
		pronouns,
		providerSpecialties,
		referralProviders,
		referralReasons,
		setPassword,
		settingPassword,
		settingPasswordSuccess,
		sexAssignedAtBirth,
		updateOutreach,
		updatingOutreach,
		updatingOutreachError,
	} = props;

	const [settingOpen, setSettingOpen] = useState(false);
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [deceasedDialog, setDeceasedDialog] = useState(false);
	const [isDeceasedFlag, setIsDeceasedFlag] = useState(false);
	const [loading, setLoading] = useState(false);
	const [settingPriority, setSettingPriority] = useState(false);
	const [prevPriority, setPrevPriority] = useState(null);
	const [noOutreach, setNoOutreach] = useState(null);
	const [showAuditTrail, setAuditTrail] = useState(false);
	const [showSendAuthRecontactDialog, setShowSendAuthRecontactDialog] =
		useState(false);
	const [
		sendAuthToRecontactDialogLoading,
		setSendAuthToRecontactDialogLoading,
	] = useState(false);

	useEffect(() => {
		determineNoOutreach();
	}, []);

	useEffect(() => {
		if (settingPassword === false && settingPasswordSuccess) {
			setSettingOpen(true);
		}
	}, [settingPassword]);

	useEffect(() => {
		if (updatingOutreach == false && isDeceasedFlag === true) {
			setIsDeceasedFlag(false);
			setLoading(false);
			if (updatingOutreachError) {
				enqueueSnackbar('Error in patient mark deceased!', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				enqueueSnackbar('Patient has mark deceased successfully.', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			}
			getPatientDetail(false);
		}

		if (updatingOutreach == false && settingPriority == true) {
			if (updatingOutreachError) {
				enqueueSnackbar('Error in setting up priority!', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				enqueueSnackbar(
					prevPriority
						? 'Patient successfully de-prioritized'
						: 'Patient successfully prioritized',
					{
						variant: 'info',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
			}
			getPatientDetail(false);
			setSettingPriority(false);
			setLoading(false);
			setSettingOpen(false);
		}
	}, [updatingOutreach]);

	const infoContainer = useRef(null);
	const topNameDiv = useRef(null);

	const changeDeceased = () => {
		const data = {};
		const payload = {};
		data.patient_id = get(patient, 'uuid', '');
		payload.is_deceased = true;
		data.payload = payload;
		updateOutreach(data);
		setDeceasedDialog(false);
		setIsDeceasedFlag(true);
		setLoading(true);
	};

	const determineNoOutreach = () => {
		if (_.get(outreachSetting, 'enabled') == false) {
			setNoOutreach(true);
		} else {
			setNoOutreach(false);
		}
	};

	const copyClipboard = (name) => {
		enqueueSnackbar('The ' + name + ' has been copied.', {
			variant: 'success',
			className: 'export_notification',
			anchorOrigin: { horizontal: 'center', vertical: 'top' },
		});
	};

	const getAddress = (address) => {
		const value =
			get(address, 'address_line1', '-') +
			(get(address, 'address_line2') &&
			get(address, 'address_line2') != null
				? ', ' + get(address, 'address_line2')
				: '') +
			(get(address, 'city') && get(address, 'city') != null
				? ', ' + get(address, 'city')
				: '') +
			(get(address, 'state') && get(address, 'state') != null
				? ', ' + get(address, 'state')
				: '') +
			(get(address, 'zip') && get(address, 'zip') != null
				? ', ' + get(address, 'zip')
				: '');
		return value;
	};

	const isValidDob = (dob) => {
		return dob !== '1901-01-01';
	};

	const getPatientValue = (key, patient) => {
		switch (key) {
			case 'external_patient_id': {
				let external_id = '';
				if (
					get(patient, 'external_patient_id') &&
					get(patient, 'external_patient_id') != null
				) {
					external_id = (
						<div>
							{get(patient, 'external_patient_id')}
							&nbsp;
							<CopyToClipboard
								text={get(patient, 'external_patient_id')}
								onCopy={() =>
									copyClipboard('External Patient ID')
								}
							>
								<i
									className='material-icons'
									style={{
										fontSize: '0.8vw',
										marginLeft: '0.4vw',
										cursor: 'pointer',
									}}
								>
									file_copy
								</i>
							</CopyToClipboard>
						</div>
					);
				} else {
					external_id = '-';
				}
				return external_id;
			}
			case 'address_line':
				return (
					get(patient, 'address_line', '-') +
					(get(patient, 'city') && get(patient, 'city') != null
						? ', ' + get(patient, 'city')
						: '')
				);
			case 'consent': {
				const consent = consenttotreat || [];
				const consent_val = _.find(consent, function (o) {
					return o.key == get(patient, 'consent_to_treat', '-');
				});
				return consent_val
					? get(consent_val, 'display_name', 'N/A')
					: 'N/A';
			}
			case 'created_at':
				return UTCToCurrentView(
					moment,
					moment(get(patient, 'created_at', '')),
					''
				);
			case 'modified_at':
				return UTCToCurrentView(
					moment,
					moment(get(patient, 'modified_at', '')),
					''
				);
			case 'sex_assigned_at_birth': {
				const sexAssignedAtBirthVal = _.find(
					sexAssignedAtBirth || [],
					function (o) {
						return (
							o.key == get(patient, 'sex_assigned_at_birth', '')
						);
					}
				);
				return !_.isNil(get(patient, 'sex_assigned_at_birth', null))
					? sexAssignedAtBirthVal
						? get(sexAssignedAtBirthVal, 'display_name', '')
						: get(patient, 'sex_assigned_at_birth', '')
					: 'Unknown';
			}
			case 'gender': {
				const genderEnum = gender || [];
				const patientGender = _.find(genderEnum, function (o) {
					return o.key == get(patient, 'gender', '-');
				});
				const genderDisplayName = !_.isNil(get(patient, 'gender', '-'))
					? patientGender
						? get(patientGender, 'display_name', '')
						: get(patient, 'gender', '-')
					: '--';
				return RemoveUnderscoreAndCapitalizeFirstLetter(
					genderDisplayName
				);
			}
			case 'dob': {
				let date_of_birth = '';
				if (get(patient, 'dob') && get(patient, 'dob') != null) {
					date_of_birth = (
						<>
							<span
								className={
									!isValidDob(get(patient, 'dob'))
										? 'alert_dob'
										: ''
								}
							>
								{moment(get(patient, 'dob')).format(
									'MMM D, YYYY'
								)}
							</span>
							{!isValidDob(get(patient, 'dob')) && (
								<TooltipDOB
									className='alert_dob_tooltip'
									effect='light'
									content={'DOB Unknown. Please update.'}
									placement='top-middle'
									visible={true}
								>
									<ReactSVG
										className='alert_dob_svg'
										src={alert}
										style={{
											position: 'relative',
											height: '16px',
										}}
									/>
								</TooltipDOB>
							)}
							{' (' +
								moment().diff(patient.dob, 'years') +
								' yrs)'}
							<CopyToClipboard
								text={moment(get(patient, 'dob')).format(
									'MM/DD/YYYY'
								)}
								onCopy={() => copyClipboard('date of birth')}
							>
								<i className='material-icons copyIcon'>
									file_copy
								</i>
							</CopyToClipboard>
						</>
					);
				} else {
					date_of_birth = 'DOB Unknown';
				}
				return date_of_birth;
			}
			case 'phone':
				return (
					get(patient, 'phone', 'Phone # Unknown') ||
					'Phone # Unknown'
				);
			case 'referral_program':
				return get(patient, 'referral_program') &&
					get(patient, 'referral_program') != null
					? get(patient, 'referral_program', '-')
					: 'N/A';
			case 'home_address':
				return get(patient, 'address.home') &&
					get(patient, 'address.home') != null
					? getAddress(get(patient, 'address.home', ''))
					: '-';
			case 'billing_address':
				return get(patient, 'address.billing') &&
					get(patient, 'address.billing') != null
					? getAddress(get(patient, 'address.billing'))
					: '-';
			case 'warm_referral_src':
				return get(patient, 'warm_referral_src') &&
					get(patient, 'warm_referral_src') != null &&
					get(patient, 'warm_referral_src.length', 0) > 0
					? get(patient, 'warm_referral_src', '-')
					: '-';
			case 'pronouns': {
				const _pronouns = [];
				get(patient, 'pronouns', []).map((val) => {
					const pronouns_val = _.find(pronouns || [], function (o) {
						return o.key == val;
					});
					if (pronouns_val) {
						_pronouns.push(pronouns_val.display_name);
					}
				});

				return _.join(_pronouns, ', ') || '--';
			}
			case 'race': {
				const race = get(patient, 'race', null);
				if (race) {
					let secondaryRaces = '';
					const patientSecondaryRaces =
						get(patient, 'secondary_races', null) || [];
					if (
						Array.isArray(patientSecondaryRaces) &&
						get(patientSecondaryRaces, 'length', 0) > 0
					) {
						secondaryRaces = patientSecondaryRaces.join(', ');
					}
					return secondaryRaces || race;
				} else return '--';
			}
			case 'ethnicity': {
				const ethnicity = get(patient, 'ethnicity', null);
				return ethnicity || '--';
			}
			default:
				break;
		}
	};

	const determineWidthOverflow = (el) => {
		if (_.isElement(el) && el) {
			const offsetWidth = get(el, 'offsetWidth', 0);
			const scrollWidth = get(el, 'scrollWidth', 0);
			if (scrollWidth > offsetWidth) return false;
			else return true;
		} else {
			return true;
		}
	};

	const changeTab = (e) => {
		const value = get(e, 'props.name');
		setActiveTab(value);
	};

	const renderTab = () => {
		switch (activeTab) {
			case 0:
				return (
					<PatientDemographicGeneralTab
						patient={patient || {}}
						consent={get(patient, 'consent_to_treat', '')}
						referralReasons={referralReasons}
						referralProviders={referralProviders}
						consentToTreat={consenttotreat || []}
						determineWidthOverflow={determineWidthOverflow}
						eligibilityFields={eligibilityFields || []}
						{...props}
					/>
				);
			case 1:
				return (
					<PatientDemographicContactTab
						patient={patient || {}}
						referralReasons={referralReasons}
						referralProviders={referralProviders}
						addresses={
							get(patient, `${currentAddressKey}`, null) || []
						}
						{...props}
					/>
				);
			case 2:
				return (
					<PatientDemographicTimelineTab
						patient={patient || {}}
						referralReasons={referralReasons}
						referralProviders={referralProviders}
					/>
				);
		}
	};

	const handleDialogOpen = () => {
		setDeleteDialog(true);
	};

	const handleDialogClose = () => {
		setDeleteDialog(false);
	};

	const renderPatientEditV2 = async () => {
		await activateDoNoResetPatient();
		const currentBreadcrumbs =
			get(history, 'location.state.breadcrumbs', []) || [];
		const editPatientPath = isFeatureEnabled('EDIT_PT_NEW_ENABLED', {
			allowCookie: true,
		})
			? '/app/patientmanagement/edit'
			: '/app/patientmanagement/edit/legacy';

		if (Array.isArray(currentBreadcrumbs)) {
			history.push({
				pathname: editPatientPath,
				state: {
					breadcrumbs: currentBreadcrumbs.concat({
						location: 'Edit Patient',
						url: editPatientPath,
						state: {
							edit: true,
							patientUuid: get(patient, 'uuid', null),
							encounter_list: encounter_list,
						},
					}),
				},
			});
		}
	};

	const setPasswordFunc = () => {
		if (get(patient, 'uuid')) {
			setPassword(get(patient, 'uuid', null));
		} else {
			enqueueSnackbar('Patient not found', {
				variant: 'error',
				className: 'export_notification',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
			});
		}
	};

	const handleAuditTrail = () => {
		setAuditTrail(!showAuditTrail);
	};

	const isShowSendAuthInviteItem = () => {
		const latestConsentStatus =
			patient?.latestPatientToRecontactStatusObject?.patient_consent
				?.status || '';
		let consentToText = patient?.phones?.primary?.consent_to_text || false;
		consentToText =
			patient?.guardian?.phones?.primary?.consent_to_text ||
			consentToText;
		return (
			!noOutreach &&
			latestConsentStatus != PatientConsentStatus.REVOKED &&
			latestConsentStatus != PatientConsentStatus.CONSENTED &&
			(consentToText || !patient?.is_noemail)
		);
	};

	const handleSendAuthRecontactDialog = () => {
		setShowSendAuthRecontactDialog(!showSendAuthRecontactDialog);
	};

	const hideSendAuthToRecontactDialog = () => {
		setShowSendAuthRecontactDialog(false);
	};

	const authToRecontactOutreachFunc = async () => {
		try {
			setSendAuthToRecontactDialogLoading(true);
			await sendAuthToRecontactOutreach(patient?.uuid || null);
			props.enqueueSnackbar(
				'Sucessfully sending authorization to recontact outreach',
				{
					variant: 'info',
					className: 'export_notification',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				}
			);
		} catch (error) {
			props.enqueueSnackbar(
				'Error in sending authorization to recontact outreach',
				{
					variant: 'error',
					className: 'export_notification',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				}
			);
		} finally {
			setSendAuthToRecontactDialogLoading(false);
			hideSendAuthToRecontactDialog();
		}
	};

	const getThreeDotMenuChoices = () => {
		const options = [
			<div
				className='black-variant-item'
				key='renderPatientEditOptions'
				onClick={renderPatientEditV2}
			>
				<ReactSVG
					src={editIcon}
					className='patientActionIcon'
					style={{
						position: 'relative',
						top: '2px',
						transform: 'scale(0.90)',
						marginRight: '6px',
					}}
				/>
				<span>{' Edit'}</span>
			</div>,
		];
		if (!isFeatureEnabled('AUTH0_PATIENT_PORTAL_LOGIN', { allowCookie: true })) { 
			if (!get(patient, 'has_password')) {
				options.push(
					<div className='black-variant-item' onClick={setPasswordFunc}>
						<ReactSVG
							src={setPasswordIcon}
							className='patientActionIcon'
							style={{
								position: 'relative',
								top: '2px',
								marginRight: '6px',
							}}
						/>
						<span>{' Set password'}</span>
					</div>
				);
			} else {
				options.push(
					<div className='black-variant-item' onClick={setPasswordFunc}>
						<ReactSVG
							src={resetPasswordIcon}
							className='patientActionIcon'
							style={{
								position: 'relative',
								top: '2px',
								marginRight: '6px',
							}}
						/>
						<span>{' Reset password'}</span>
					</div>
				);
			}
		}
		

		options.push(
			<div
				className='black-variant-item'
				onClick={handleAuditTrail}
				style={{ paddingLeft: '8px' }}
			>
				<span>{' Audit Trail'}</span>
			</div>
		);
		if (isShowSendAuthInviteItem()) {
			options.push(
				<div
					className='black-variant-item'
					onClick={handleSendAuthRecontactDialog}
					style={{ paddingLeft: '8px' }}
				>
					<span>Send Auth Invite</span>
				</div>
			);
		}
		options.push(<div className='menu-divider'></div>);

		options.push(
			<div
				className={'black-variant-item do-not-outreach'}
				onClick={openDateTimeDisabledOutreach}
			>
				{get(outreachSetting, 'enabled', false) ? (
					<Fragment>
						<ReactSVG
							src={noOutreachIcon}
							className='patientActionIcon patientActionNoOutreachIcon'
							style={{
								position: 'relative',
								top: '2px',
								marginRight: '6px',
							}}
						/>
						<span>Do Not Outreach</span>
					</Fragment>
				) : (
					<Fragment>
						<ReactSVG
							src={disableNoOutreachIcon}
							className='patientActionIcon patientActionNoOutreachIcon'
							style={{
								position: 'relative',
								top: '8px',
								left: '5px',
							}}
						/>
						<span style={{ maxWidth: '120px', paddingLeft: '4px' }}>
							Disable “Do Not Outreach”
						</span>
					</Fragment>
				)}
			</div>
		);

		options.push(<div className='menu-divider'></div>);

		options.push(
			<RBAC
				action={CARE_PATIENTS_PATIENT_DELETE}
				yes={<Fragment>{renderDeleteMenuOption()}</Fragment>}
			/>
		);

		return options;
	};

	const renderDeleteMenuOption = () => {
		const hasUpcomingAppointments = get(encounter_list, 'data', []).some(
			(e) =>
				get(e, 'visit_status', '') === 'booked' &&
				get(e, 'type', '') !== 'lab'
		);

		const deleteButton = (
			<div
				className={'black-variant-item'}
				onClick={
					!hasUpcomingAppointments && !encounterloading
						? handleDialogOpen
						: handleDialogClose
				}
			>
				<ReactSVG
					src={trashIcon}
					className='patientActionIcon'
					style={{
						position: 'relative',
						top: '2px',
						marginRight: '4px',
					}}
				/>
				<span style={{ color: '#e74c3c' }}>{' Delete'}</span>
			</div>
		);

		let deleteComponent = null;
		if (!hasUpcomingAppointments) {
			deleteComponent = deleteButton;
		}

		return deleteComponent;
	};

	let deletePatientComponents = null;
	const noOutreachSet = noOutreach || !get(outreachSetting, 'enabled', true);

	deletePatientComponents = (
		<RBAC
			action={CARE_PATIENTS_PATIENT_SETTINGS}
			yes={
				<div>
					<ThreeDotsMenu
						options={getThreeDotMenuChoices()}
						blackVariant={true}
						customStyling={{ bottom: '7px', left: '5px' }}
						directRender={true}
					/>
					<PatientDeleteDialog
						{...props}
						patient={patient}
						delete_dialog={deleteDialog}
						onCancel={handleDialogClose}
					/>
				</div>
			}
			no={<div className='threeDotIconContainer-wrapper'></div>}
		/>
	);

	return (
		<div className='patient_info' ref={infoContainer}>
			{loading ? (
				<Loading
					loading={loading}
					className='patientEditLoader'
				></Loading>
			) : (
				<Fragment>
					{
						<React.Fragment>
							<div className='name_wrapper main-part patientTopPanel'>
								<div>
									<div className='name_dob'>
										<div className='nameRow hp-patinent-info'>
											<div className='name'>
												<TooltipDOB
													content={`${get(
														patient,
														'first_name',
														''
													)} ${
														!_.isNil(
															get(
																patient,
																'preferred_name',
																null
															)
														) &&
														!_.isEmpty(
															get(
																patient,
																'preferred_name',
																''
															)
														)
															? `(${get(
																	patient,
																	'preferred_name',
																	''
																)})`
															: ''
													} ${get(
														patient,
														'last_name',
														''
													)}`}
													placement='top-start'
													disabled={determineWidthOverflow(
														topNameDiv.current
													)}
													className='tooltipWidth'
												>
													<div>
														<CopyToClipboard
															text={`${get(
																patient,
																'first_name',
																''
															)} ${get(
																patient,
																'last_name',
																''
															)}`}
															onCopy={() =>
																copyClipboard(
																	'name'
																)
															}
														>
															<div
																style={{
																	fontFamily:
																		'RionaSans-Bold',
																	textOverflow:
																		'ellipsis',
																	maxWidth:
																		'250px',
																	overflowX:
																		'hidden',
																	whiteSpace:
																		'nowrap',
																}}
																ref={(el) =>
																	(topNameDiv.current =
																		el)
																}
															>
																{`${get(
																	patient,
																	'first_name',
																	''
																)}
                                                            ${
																!_.isNil(
																	get(
																		patient,
																		'preferred_name',
																		''
																	)
																) &&
																!_.isEmpty(
																	get(
																		patient,
																		'preferred_name',
																		''
																	)
																)
																	? '(' +
																		get(
																			patient,
																			'preferred_name',
																			''
																		) +
																		')'
																	: ''
															}
                                                            ${get(
																patient,
																'last_name',
																''
															)}`}
															</div>
														</CopyToClipboard>
													</div>
												</TooltipDOB>
											</div>
											{deletePatientComponents}
										</div>
										<div
											className='patientInfoTop patientInfoTopMargin'
											style={{
												display: 'flex',
												marginTop: '19px',
											}}
										>
											<div>
												<TooltipDOB
													content='Patient UUID'
													placement='bottom'
												>
													<span>
														{get(
															patient,
															'uuid',
															''
														)}
													</span>
												</TooltipDOB>
											</div>
											<div>
												<CopyToClipboard
													text={get(
														patient,
														'uuid',
														''
													)}
													onCopy={() =>
														copyClipboard('UUID')
													}
												>
													<i className='material-icons copyIcon'>
														file_copy
													</i>
												</CopyToClipboard>
											</div>
										</div>
									</div>
								</div>
								<div
									className='pull-right patient-action-buttons'
									style={{ marginRight: '30px' }}
								></div>
							</div>
							<div className='demographicGroupContainer patient-info'>
								<div className='demographicItem'>
									<div className='demographicItemHeader'>
										Pronouns
									</div>
									<div className='demographicItemData'>
										{getPatientValue(
											'pronouns',
											patient || {}
										)}
									</div>
								</div>
								<div className='demographicItem'>
									<div className='demographicItemHeader'>
										Sex Assigned At Birth
									</div>
									<div className='demographicItemData'>
										{getPatientValue(
											'sex_assigned_at_birth',
											patient || {}
										)}
									</div>
								</div>
								<div className='demographicItem'>
									<div className='demographicItemHeader'>
										Gender
									</div>
									<div className='demographicItemData'>
										{getPatientValue(
											'gender',
											patient || {}
										)}
									</div>
								</div>
								<div className='demographicItem'>
									<div className='demographicItemHeader'>
										Race
									</div>
									<div className='demographicItemData'>
										{getPatientValue('race', patient || {})}
									</div>
								</div>
								<div className='demographicItem'>
									<div className='demographicItemHeader'>
										Ethnicity
									</div>
									<div className='demographicItemData'>
										{getPatientValue(
											'ethnicity',
											patient || {}
										)}
									</div>
								</div>
								<div className='demographicItem'>
									<div className='demographicItemHeader'>
										DOB
									</div>
									<div className='demographicItemData'>
										{getPatientValue('dob', patient || {})}
									</div>
								</div>
								<div className='demographicItem'>
									<div className='demographicItemHeader'>
										Referral Program
									</div>
									<div className='demographicItemData'>
										{getPatientValue(
											'referral_program',
											patient || {}
										)}
									</div>
								</div>
							</div>
							<PatientInfoBox
								patient={patient || {}}
								noOutreach={noOutreachSet}
								determineWidthOverflow={determineWidthOverflow}
								copyClipboard={copyClipboard}
								classes={classes}
								hasPriorityTag={hasPriorityTag}
								hasPriorityTagLanguage={hasPriorityTagLanguage}
								hasVIPTag={hasVIPTag}
								hasLanguageLineSupportTag={
									hasLanguageLineSupportTag
								}
								hasLanguageLineSupportTagLanguage={
									hasLanguageLineSupportTagLanguage
								}
								hasSpeechImpairedTag={hasSpeechImpairedTag}
								hashearingImpairedTag={hashearingImpairedTag}
								hasVisionImpairedTag={hasVisionImpairedTag}
								hasFinancialAssistanceTag={
									hasFinancialAssistanceTag
								}
								hasFinancialAssistancePerVisitTag={
									hasFinancialAssistancePerVisitTag
								}
								hasDeceasedTag={hasDeceasedTag}
								hasDischargedTag={hasDischargedTag}
								pronouns={pronouns || []}
								hasMarketingOptoutTag={hasMarketingOptoutTag}
								hasResearchOptoutTag={hasResearchOptoutTag}
								hasNoFurtherEscalationTag={
									hasNoFurtherEscalationTag
								}
								hasPatientStoryTag={hasPatientStoryTag}
								hasDuplicatePatientTag={hasDuplicatePatientTag}
								enqueueSnackbar={enqueueSnackbar}
								duplicatePatients={duplicatePatients || []}
							/>
							<div className='patientBottomPanel'>
								<Tabs
									value={activeTab || 0}
									className='patient_sidebar_tabs'
									fullWidth={false}
									onTabClick={(tab) => changeTab(tab)}
								>
									<Tabs.Pane
										className={
											'noMinWidth ' +
											(activeTab == 0
												? 'activeDemographic'
												: 'non-activeDemographic')
										}
										label={
											<span
												className={
													'sidebar_tab_labels '
												}
											>
												General
											</span>
										}
										name={0}
									/>
									<Tabs.Pane
										className={
											'noMinWidth ' +
											(activeTab == 1
												? 'activeDemographic'
												: 'non-activeDemographic')
										}
										label={
											<span
												className={'sidebar_tab_labels'}
											>
												Contact
											</span>
										}
										name={1}
									/>
									<Tabs.Pane
										className={
											'noMinWidth ' +
											(activeTab == 2
												? 'activeDemographic'
												: 'non-activeDemographic')
										}
										label={
											<span
												className={'sidebar_tab_labels'}
											>
												Timeline
											</span>
										}
										name={2}
									/>
								</Tabs>
								{renderTab()}
								{!_.isEmpty(linkedProviders || []) && (
									<div className='patient-external-care-team'>
										<div className='ext-care-team'>{`External Care Team`}</div>
										{(linkedProviders || []).map(
											(el) =>
												el && (
													<ExternalCareTeam
														key={el}
														{...el}
														providerSpecialties={
															providerSpecialties ||
															[]
														}
													/>
												)
										)}
									</div>
								)}
							</div>
						</React.Fragment>
					}
					<Dialog
						customClass='deceased-dialog'
						title={
							<span>
								<img
									src={imageWarningRed}
									alt=''
									className='confirm-img-warning'
								/>
								Confirmation
							</span>
						}
						size='tiny'
						modal='true'
						closeOnPressEscape='true'
						closeOnClickModal='true'
						showClose='false'
						visible={deceasedDialog}
						onCancel={() => setDeceasedDialog(false)}
					>
						<Dialog.Body>
							<span>
								Are you sure you want to mark this patient as
								deceased?
							</span>
						</Dialog.Body>
						<Dialog.Footer className='dialog-footer'>
							<Button
								className='btn-deceased-cancel'
								onClick={() => setDeceasedDialog(false)}
							>
								Cancel
							</Button>
							<Button
								className='btn-deceased'
								onClick={() => changeDeceased()}
							>
								<span className='btn-deceased-icon'>
									{' '}
									<ReactSVG src={setDeceasedIcon} />
								</span>
								<span className='btn-deceased-text'>
									{'Yes, Mark deceased'}
								</span>
							</Button>
						</Dialog.Footer>
					</Dialog>
					{showAuditTrail && (
						<AuditTrail
							handleAuditTrail={handleAuditTrail}
							smsConsentLog={get(patient, 'sms_consent_log', '')}
							recontactConsentLog={get(
								patient,
								'consentHistory',
								[]
							)}
							patientName={
								get(patient, 'has_guardian', false)
									? `${get(
											patient,
											'guardian.first_name',
											''
										)} ${get(
											patient,
											'guardian.last_name',
											''
										)}`
									: `${get(patient, 'first_name', '')} ${get(
											patient,
											'last_name',
											''
										)}`
							}
						/>
					)}
					{showSendAuthRecontactDialog && (
						<SendAuthToRecontactDialog
							sendAuthToRecontactDialogLoading={
								sendAuthToRecontactDialogLoading
							}
							hideSendAuthToRecontactDialog={
								hideSendAuthToRecontactDialog
							}
							authToRecontactOutreachFunc={
								authToRecontactOutreachFunc
							}
						/>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default PatientInfo;
