//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../../assets/exit-alert.svg';

const MultiSelectPreviewTab = (props) => {
	const { data = [], internalValues = [], toggleCheck } = props;

	return (
		<div className='multi-select-checkbox-selected'>
			<div className='multi-select-checkbox-selected-header'>
				Selected
			</div>
			{data.map((el, i) => {
				return internalValues.includes(el.key || '--') ? (
					<div
						className='multi-select-checkbox-selected-item'
						key={i}
					>
						<div className='multi-select-checkbox-selected-item-name'>
							{el.display_name || '--'}
						</div>
						<ReactSVG
							src={xIcon}
							className='multi-select-checkbox-x-icon'
							onClick={() => toggleCheck(el.key || '--')}
						/>
					</div>
				) : null;
			})}
		</div>
	);
};

export default MultiSelectPreviewTab;
