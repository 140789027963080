//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import AuditTrailHistoryRecord from './AuditTrailHistoryRecord.js';

class AuditTrailHistory extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
		//bindings
	}
	//functions

	render() {
		const history = get(this, 'props.auditTrailEncounter', []) || [];
		const lastIndex = get(history, 'length', 0) - 1;
		const lastRecord = get(history, `[${lastIndex}]`, null);
		const historyLength = get(history, 'length', 0);
		const currentField = get(this, 'props.currentField', null);
		if (
			get(this, 'props.auditTrailEncounterLoading', null) ||
			get(this, 'props.encounterAuditEnumLoading', null) ||
			get(this, 'state.loading', false)
		) {
			return (
				<div className='auditTrailHistoryContainer'>
					<Loading loading={true} className='auditTrailLoading' />
				</div>
			);
		} else {
			return (
				<div className='auditTrailHistoryContainer'>
					{history.map((el, i) => {
						const lastItem =
							i == lastIndex && currentField == 'visit_status'
								? true
								: false;
						const encounter =
							get(this, 'props.encounter', {}) || {};
						return (
							<AuditTrailHistoryRecord
								record={el}
								key={get(el, 'created_at', i)}
								currentField={currentField}
								lastItem={lastItem}
								relevantEncounterEnum={
									get(
										this,
										'props.relevantEncounterEnum',
										{}
									) || {}
								}
								encounter={
									lastItem
										? {
												...encounter,
												created_at: get(
													el,
													'created_at'
												),
												visit_status: get(el, [
													'changes',
													'0',
													'new_value',
												]),
										  }
										: null
								}
							/>
						);
					})}
					{currentField && currentField != 'visit_status' && (
						<AuditTrailHistoryRecord
							record={lastRecord}
							key={get(
								this,
								'props.encounter.created_at',
								'last'
							)}
							currentField={currentField}
							lastItem={true}
							relevantEncounterEnum={
								get(this, 'props.relevantEncounterEnum', {}) ||
								{}
							}
							encounterCreationDate={get(
								this,
								'props.encounterCreationDate',
								null
							)}
							encounter={get(this, 'props.encounter', {}) || {}}
							nonZeroResults={!!historyLength}
						/>
					)}
				</div>
			);
		}
	}
}

export default AuditTrailHistory;
