//React & Redux
import React, { Component } from 'react';
import PropTypes from 'prop-types';

//UI Libraries
import { Pagination as ERPagination } from 'gm-element-react';

//Other Libraries
import { get, floor } from 'lodash';
import classnames from 'classnames';

//Styles
import './pagination.css';

const Pagination = (props) => {
	const { pageSize, offset, total, layout } = props;

	//functions
	const onCurrentChange = (currentPage) => {
		const currentOffset = currentPage * pageSize - pageSize;
		props.onCurrentChange(currentPage, currentOffset);
	};

	const currentPage = floor(offset / pageSize) + 1;
	const hideButton = total == 0 ? 'hide-button' : '';
	return (
		<ERPagination
			total={total}
			pageSize={pageSize}
			currentPage={currentPage}
			layout={layout}
			className={classnames(
				'gmi-el-pagination',
				get(props, 'className', ''),
				hideButton
			)}
			onCurrentChange={(currentPage) => onCurrentChange(currentPage)}
		/>
	);
};

Pagination.propTypes = {
	total: PropTypes.number,
	pageSize: PropTypes.number,
	offset: PropTypes.number,
	layout: PropTypes.string,
	offset: PropTypes.isRequired,
	pageSize: PropTypes.isRequired,
	total: PropTypes.isRequired,
	onCurrentChange: PropTypes.func,
};

export default Pagination;

/*  Implementation notes 
    
    pageSize = item count of each page
    toatal = total item count
    offset = number of items 
    onCurrentChange = triggers when current-page changes

    <Pagination
        pageSize={paginationLimit}
        total={get(this, ["props", "pagination", "total"], 0)} 
        offset={offset}             
        layout="prev, pager, next"
        onCurrentChange={(currentPage, offset) => this.handlePageChangeP(currentPage, offset)}
    />
*/
