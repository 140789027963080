//React & Redux
import React, { useEffect, useRef } from 'react';

//Lodash
import get from 'lodash/get';

//UI Libraries
import { Select, DatePicker, Loading } from 'gm-element-react';

const BulkUpdateModal = (props) => {
	const {
		billingBulkUpdate,
		billingEncounterBulkUpdating,
		closeModal,
		handleBulkDataChange,
		invoiceDate,
		invoiceStatus,
		partnerInvoice,
		partnerInvoiceEnum,
		resetBulkUpdateData,
	} = props;

	const billingBulkUpdateContainer = useRef();

	useEffect(() => {
		document
			.querySelector('body')
			.addEventListener('click', documentEventListener, {
				capture: true,
			});

		return () =>
			document
				.querySelector('body')
				.removeEventListener('click', documentEventListener);
	}, []);

	const documentEventListener = (e) => {
		const elToCheck = get(
			billingBulkUpdateContainer,
			'current',
			document.createElement('div')
		);
		const targetNode = e.target;
		const datePicker = document.querySelector('.el-date-picker');

		if (elToCheck !== null && !elToCheck.contains(targetNode)) {
			if (datePicker) {
				!datePicker.contains(targetNode) && closeModal();
			} else {
				closeModal();
			}
		}
	};

	const handleInvoiceAmountChange = (e) => {
		const newAmount = e?.target?.value || null;
		if (newAmount.match(/^\d+\.?(\d+)?$/) || newAmount === '')
			handleBulkDataChange('bulkUpdatePartnerInvoice', newAmount);
	};

	const canAdvance = () => {
		if (invoiceStatus || invoiceDate || partnerInvoice) {
			if (partnerInvoice) {
				return partnerInvoice.match(/^\d+(\.\d{1,2})?$/);
			} else return true;
		} else {
			return false;
		}
	};

	const handleSave = () => canAdvance() && billingBulkUpdate();

	const buttonClass = canAdvance()
		? 'billingBulkUpdateModalResetSave'
		: 'billingBulkUpdateModalResetSave billingBulkUpdateModalResetSaveDisabled';

	return (
		<div
			className='billingBulkUpdateModalContainer'
			ref={billingBulkUpdateContainer}
		>
			<Loading loading={billingEncounterBulkUpdating}>
				<div className='billingBulkUpdateModalTopRow'>
					<div className='billingBulkUpdateModalTopRowText'>
						Partner Invoice
					</div>
				</div>
				<div className='billingBulkUpdateModalBodyContainer'>
					<div className='billingBulkUpdateModalInputSectionContainer'>
						<div className='billingBulkUpdateModalInputHeader'>
							Invoice Status
						</div>
						<Select
							value={invoiceStatus}
							onChange={(e) =>
								handleBulkDataChange(
									'bulkUpdateInvoiceStatus',
									e
								)
							}
						>
							{partnerInvoiceEnum.map((e, i) => (
								<Select.Option
									key={get(e, 'key', i)}
									label={get(e, 'display_name', null)}
									value={get(e, 'key', null)}
								>
									{get(e, 'display_name', null)}
								</Select.Option>
							))}
						</Select>
					</div>

					<div className='billingBulkUpdateModalInputSectionContainer'>
						<div className='billingBulkUpdateModalInputHeader'>
							Invoice Date
						</div>
						<DatePicker
							placeholder='MM/DD/YYYY'
							format={'MM/dd/yyyy'}
							value={invoiceDate}
							onChange={(e) =>
								handleBulkDataChange('bulkUpdateInvoiceDate', e)
							}
							isReadOnly={true}
						/>
					</div>

					<div className='billingBulkUpdateModalInputSectionContainer'>
						<div className='billingBulkUpdateModalInputHeader'>
							Partner Invoice
						</div>
						<input
							placeholder='$12.34'
							value={partnerInvoice || ''}
							onChange={handleInvoiceAmountChange}
							className='billingBulkUpdateModalInputText'
						/>
					</div>
				</div>

				<div className='billingBulkUpdateModalBottomRow'>
					<div
						className='billingBulkUpdateModalReset'
						onClick={resetBulkUpdateData}
					>
						Reset
					</div>
					<div className={buttonClass} onClick={handleSave}>
						Save
					</div>
				</div>
			</Loading>
		</div>
	);
};

export default BulkUpdateModal;
