//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Tabs } from 'gm-element-react';

//Components
import EditTestRequestLabInfo from './EditTestRequestLabInfo';
import EditTestRequestLabInfoAfterSentToLab from './EditTestRequestLabInfoAfterSentToLab';
import CreateTestRequestEncntrInfo from './CreateTestRequestEncntrInfo';
import TestRequestInfoTab from './TestRequestInfoTab';

//Styles
import './create-test-request-modal.css';

const EditTestRequestModal = (props) => {
	const {
		encounter = {},
		encounterschema = {},
		formdata = {},
		handleDateFieldValidate,
		holdResultRelease = null,
		labenums = [],
		labOrderStatus = '',
		laborderstatusenums = [],
		mloOrderStatusEnums = [],
		nonlegacytestOrderPopupTestEnums = [],
		onFormChange,
		onResultActionChange,
		ordering_physicianenums = [],
		orderrequeststatuscascadeenums = [],
		orderstatuscascadeenums = {},
		outreachStatusEnums = [],
		outreachTimeToStart = null,
		patientbillingmethodenums = [],
		patientdetail = {},
		sampletypeenums = [],
		selectedICDCodes,
		tabvalue = '',
		testnameenums = [],
		testOrderList,
		testOrderNumber = 1,
		testorder = {},
		testOutreachStatus = '',		
		userSelectedICDCodes = null,
		shippingAddressFormData = {},
		onShippingAddressFormChange,
		onAddAnotherTestNameFormChange,
		genetic_test2,
		genetic_test3,
		testName2Enums,
		testName3Enums,
		groupTestorders = [],
		onFormChangeForMultipleTestOrder,
		stateGroupTestorders,
		reflexAOEQuestions,
		reflexAOEAnswers,
		setReflexAnswers,
		hasPatientNoInsurance,
		hideModal,
	} = props;

	const statusEnum = {
		approved: 1,
		awaiting_cc_submission: 1,
		sent_to_lab: 1,
		sample_not_submitted: 1,
		patient_not_responsive: 1,
		order_cancelled_by_patient: 1,
		order_cancelled_by_provider: 1,
		order_cancelled_by_lab: 1,
		results_ready: 1,
		result_positive_no_genes: 1,
		result_positive_with_genes: 1,
		result_negative: 1,
	};

	const orderStatusSentToLabAndAfterThat = {
		sent_to_lab: 1,
		results_ready: 1,
		results_entered: 1,
		result_positive_no_genes: 1,
		result_positive_with_genes: 1,
		result_negative: 1,
		approved: 1,
		awaiting_cc_submission: 1,
		sample_not_submitted: 1,
		patient_not_responsive: 1,
		order_cancelled_by_patient: 1,
		order_cancelled_by_provider: 1,
		order_cancelled_by_lab: 1,
		cancelled: 1,
	};

	return (
		<div className='crte-test-rqst-dialog-body'>
			<div className='crte-test-rqst-fields-container'>
			{testorder?.order_status in orderStatusSentToLabAndAfterThat && groupTestorders.length > 1 ? (
					<EditTestRequestLabInfoAfterSentToLab
						{...{
							groupTestorders,
							mloOrderStatusEnums,
							laborderstatusenums,
							sampletypeenums,
							patientbillingmethodenums,
							handleDateFieldValidate,							
							holdResultRelease,
							onResultActionChange,
							testOrderList,
							encounterschema,
							encounter,
							onFormChangeForMultipleTestOrder,
							stateGroupTestorders,
							hasPatientNoInsurance,
							hideModal,
							reflexAOEQuestions,
							reflexAOEAnswers,
						}}
					/>
				) : (
					<EditTestRequestLabInfo
						{...{
							encounterschema,
							testnameenums,
							testorder,
							labenums,
							laborderstatusenums,
							nonlegacytestOrderPopupTestEnums,
							orderrequeststatuscascadeenums,
							ordering_physicianenums,
							userSelectedICDCodes,
							formdata,
							onFormChange,
							handleDateFieldValidate,
							orderstatuscascadeenums,
							sampletypeenums,
							patientbillingmethodenums,
							mloOrderStatusEnums,
							testOrderNumber,
							labOrderStatus,
							testOrderList,
							holdResultRelease,
							onResultActionChange,
							patientdetail,
							shippingAddressFormData,
							onShippingAddressFormChange,
							onAddAnotherTestNameFormChange,
							genetic_test2,
							genetic_test3,
							testName2Enums,
							testName3Enums,
							reflexAOEQuestions,
							reflexAOEAnswers,
							setReflexAnswers,
							hasPatientNoInsurance,
							hideModal,
						}}
					/>
				)}
				{_.get(testorder, ['localorderstatus'], null) in statusEnum ? (
					<Tabs
						key={tabvalue}
						activeName='testRequestInfoTab'
						className='test-order-encntr-testrequest-tabs-wrapper'
					>
						<Tabs.Pane label='Encounter' name='encntrInfoTab'>
							<CreateTestRequestEncntrInfo
								encounter={encounter}
								encounterschema={encounterschema}
								patientdetail={patientdetail}
							/>
						</Tabs.Pane>
						<Tabs.Pane
							label='Test Request'
							name='testRequestInfoTab'
						>
							<TestRequestInfoTab
								testorder={testorder}
								ordering_physicianenums={
									ordering_physicianenums
								}
								selectedICDCodes={selectedICDCodes}
								sampletypeenums={sampletypeenums}
								patientbillingmethodenums={
									patientbillingmethodenums
								}
								formdata={formdata}
								testOutreachStatus={testOutreachStatus}
								outreachStatusEnums={outreachStatusEnums}
								holdResultRelease={holdResultRelease}
								outreachTimeToStart={outreachTimeToStart}
								groupTestorders={groupTestorders}
							/>
						</Tabs.Pane>
					</Tabs>
				) : (
					<CreateTestRequestEncntrInfo
						encounter={encounter}
						encounterschema={encounterschema}
						patientdetail={patientdetail}
					/>
				)}
			</div>
		</div>
	);
};

export default EditTestRequestModal;
