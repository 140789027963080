//React & Redux
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

//Lodash
import _ from 'lodash';

//Actions & Services
import {
	getGeneTestResultsEnums,
	getGeneticResultGenes,
	clearGeneticsResultsGenes,
	getGeneticResultGenes_Edit,
	clearGeneticsResultsGenes_Edit,
} from './../../../../actions/patient/genes';
import { CARE_PATIENTS_ENTER_TEST_RESULT } from '../../../../Utils/permissionUtils';

//Components
import AddGeneDetails from './AddGeneDetails';
import ListGene from './ListGene';
import RBAC from '../../../RBAC';

//Styles
import './css/gene-result.css';

export const GeneResult = (props) => {
	const {
		add_componentShow,
		addDiagnosticFindingClinicalNoteError,
		addDiagnosticFindingClinicalNoteLoading,
		addGeneprops,
		addNewDiagnosticFindingClinicalNote,
		callAddgeneResultFunc,
		callDeleteLastGeneResultFunc,
		callupdateGeneTestFunc,
		change_btnShowFunc,
		clearingGeneticsResultsGenes,
		clearingGeneticsResultsGenes_Edit,
		diagnosticReports,
		enqueueSnackbar,
		geneEnum_Edit,
		genesTestResultEnumsLoading,
		genesEnum,
		getGeneticResultGene,
		getGeneticResultGene_Edit,
		gettingGeneResultsEnum,
		gettingTestDetail,
		interpretation,
		non_gene,
		showAddEditDiscreteVariantModalFunc,
		showAddEditStructuralVariantModalFunc,
		showAddEditNucleotideRepeatVariantModalFunc,
		showcfDNACancerModal,
		showViewDiscreteVariantFullDetailFunc,
		showViewStructuralVariantFullDetailFunc,
		testDetail,
		testOrderUUID,
		testResult,
		updategeneprops,
		zygosity,
		showViewCFDNAPrenatalScreeningFullDetailFunc,
		showViewNucleotideRepeatVariantFullDetailFunc,
	} = props;

	const isCancelBtn = testResult.length > 0 || false;

	useEffect(() => {
		gettingGeneResultsEnum();
	}, []);

	return (
		<>
			{!add_componentShow() ||
			testResult.length ||
			_.get(diagnosticReports, ['observationFinding', 'length'], false) ||
			_.get(
				diagnosticReports,
				['discreteVariantFinding', 'length'],
				false
			) ||
			_.get(
				diagnosticReports,
				['riskAssessmentFinding', 'length'],
				false
			) ||
			_.get(
				diagnosticReports,
				['cfDNAPrenatalObservationFinding', 'length'],
				false
			) ||
			_.get(
				diagnosticReports,
				['structuralVariantFinding', 'length'],
				false
			) ||
			_.get(
				diagnosticReports,
				['nucleotideRepeatVariantFinding', 'length'],
				false
			) ? (
				<div className='gene-result'>
					<div className='gene-result-container'>
						<header className='head-gene-result'>
							{' '}
							{_.get(
								diagnosticReports,
								['observationFinding'],
								[]
							).length > 0 ||
							_.get(
								diagnosticReports,
								['discreteVariantFinding'],
								[]
							).length > 0 ||
							_.get(
								diagnosticReports,
								['riskAssessmentFinding'],
								[]
							).length > 0 ||
							_.get(
								diagnosticReports,
								['structuralVariantFinding'],
								[]
							).length > 0 ||
							_.get(
								diagnosticReports,
								['cfDNAPrenatalObservationFinding'],
								[]
							).length > 0 ||
							_.get(
								diagnosticReports,
								['nucleotideRepeatVariantFinding'],
								[]
							).length > 0
								? 'Findings'
								: 'Result details'}
						</header>
						{!add_componentShow() && (
							<RBAC
								action={CARE_PATIENTS_ENTER_TEST_RESULT}
								yes={
									<AddGeneDetails
										{...{
											addGeneprops,
											callAddgeneResultFunc,
											change_btnShowFunc,
											clearGeneticsResultsGenes:
												clearingGeneticsResultsGenes,
											data: testDetail.testDetail,
											enqueueSnackbar,
											genesEnum,
											genesTestResultEnumsLoading,
											getGeneticResultGene,
											gettingTestDetail,
											isCancelBtn,
											interpretation,
											nonGeneEnum: non_gene,
											testOrderUUID,
											zygosity,
										}}
									/>
								}
							/>
						)}
						<ListGene
							{...{
								addDiagnosticFindingClinicalNoteError,
								addDiagnosticFindingClinicalNoteLoading,
								addNewDiagnosticFindingClinicalNote,
								callDeleteLastGeneResultFunc,
								callupdateGeneTestFunc,
								clearingGeneticsResultsGenes_Edit,
								clearGeneticsResultsGenes:
									clearingGeneticsResultsGenes,
								data: testDetail.testDetail,
								diagnosticReports,
								enqueueSnackbar,
								geneEnum_Edit,
								geneList: testResult,
								genesEnum,
								getGeneticResultGene_Edit,
								interpretation,
								nonGeneEnum: non_gene,
								showAddEditDiscreteVariantModalFunc,
								showAddEditStructuralVariantModalFunc,
								showAddEditNucleotideRepeatVariantModalFunc,
								showcfDNACancerModal,
								showViewDiscreteVariantFullDetailFunc,
								showViewStructuralVariantFullDetailFunc,
								updategeneprops,
								zygosity,
								showViewCFDNAPrenatalScreeningFullDetailFunc,
								showViewNucleotideRepeatVariantFullDetailFunc,
							}}
						/>
					</div>
				</div>
			) : null}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		addedGeneResult,
		addingGeneResult,
		addingGeneResultError,
		geneEnum_Edit,
		genesEnum,
		genesTestResultEnums,
		genesTestResultEnumsLoading,
		updatedGeneResult,
		updatingGeneResult,
		updatingGeneResultError,
	} = state?.genes || {};

	return {
		addGeneprops: {
			added: addedGeneResult,
			adding: addingGeneResult,
			error: addingGeneResultError,
		},
		geneEnum_Edit,
		genesEnum,
		genesTestResultEnumsLoading,

		interpretation:
			(genesTestResultEnums && genesTestResultEnums['interpretation']) ||
			[],
		non_gene:
			(genesTestResultEnums && genesTestResultEnums['non_gene']) || [],
		testDetail: _.get(state, 'getTestDetail', {}),
		updategeneprops: {
			updated: updatedGeneResult,
			updating: updatingGeneResult,
			error: updatingGeneResultError,
		},
		zygosity:
			(genesTestResultEnums && genesTestResultEnums['zygosity']) || [],
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const {
		add_componentShow,
		addDiagnosticFindingClinicalNoteError,
		addDiagnosticFindingClinicalNoteLoading,
		addNewDiagnosticFindingClinicalNote,
		change_btnShowFunc,
		callAddgeneResultFunc,
		callDeleteLastGeneResultFunc,
		callupdateGeneTestFunc,
		diagnosticReports,
		enqueueSnackbar,
		gettingTestDetail,
		showAddEditDiscreteVariantModalFunc,
		showAddEditStructuralVariantModalFunc,
		showAddEditNucleotideRepeatVariantModalFunc,
		showcfDNACancerModal,
		showViewDiscreteVariantFullDetailFunc,
		showViewStructuralVariantFullDetailFunc,
		testOrderUUID,
		testResult,
		testUUID,
		showViewCFDNAPrenatalScreeningFullDetailFunc,
		showViewNucleotideRepeatVariantFullDetailFunc,
	} = ownProps;
	const {
		interpretation,
		zygosity,
		non_gene,
		addGeneprops,
		updategeneprops,
		genesEnum,
		testDetail,
		geneEnum_Edit,
	} = stateProps;
	const gettingGeneResultsEnum = () => dispatch(getGeneTestResultsEnums());
	const getGeneticResultGene = (q) =>
		dispatch(getGeneticResultGenes(testUUID, q));
	const clearingGeneticsResultsGenes = () =>
		dispatch(clearGeneticsResultsGenes());
	const getGeneticResultGene_Edit = (q, gene_ids) =>
		dispatch(
			getGeneticResultGenes_Edit({
				uuid: testUUID,
				q: q,
				geneIDList: gene_ids,
			})
		);
	const clearingGeneticsResultsGenes_Edit = () =>
		dispatch(clearGeneticsResultsGenes_Edit());
	return {
		interpretation,
		zygosity,
		non_gene,
		addGeneprops,
		updategeneprops,
		gettingGeneResultsEnum,
		testResult,
		testOrderUUID,
		enqueueSnackbar,
		add_componentShow,
		change_btnShowFunc,
		callAddgeneResultFunc,
		callupdateGeneTestFunc,
		getGeneticResultGene,
		genesEnum,
		testDetail,
		clearingGeneticsResultsGenes,
		geneEnum_Edit,
		getGeneticResultGene_Edit,
		clearingGeneticsResultsGenes_Edit,
		callDeleteLastGeneResultFunc,
		showcfDNACancerModal,
		diagnosticReports,
		addNewDiagnosticFindingClinicalNote,
		addDiagnosticFindingClinicalNoteLoading,
		addDiagnosticFindingClinicalNoteError,
		gettingTestDetail,
		showAddEditDiscreteVariantModalFunc,
		showAddEditStructuralVariantModalFunc,
		showAddEditNucleotideRepeatVariantModalFunc,
		showViewDiscreteVariantFullDetailFunc,
		showViewStructuralVariantFullDetailFunc,
		showViewCFDNAPrenatalScreeningFullDetailFunc,
		showViewNucleotideRepeatVariantFullDetailFunc,
	};
};

export default connect(mapStateToProps, null, mergeProps)(GeneResult);
