//React & Redux
import React from 'react';
import _ from 'lodash';
import { Table, Thead, Tbody, Th, Tr, Td } from '../Common/Table';

const DashboardSummaryDetail = (props) => {
	const {
		riseAnalyticsData,
		handlePagination,
		handleResultsPerPageChange,
		handleSummaryTblClick,
	} = props;
	const summaryData = _.get(riseAnalyticsData, ['summary', 'data'], []);
	const total = _.get(riseAnalyticsData, ['summary', 'total'], []);
	const limit = _.get(riseAnalyticsData, ['limit'], []);
	const offset = _.get(riseAnalyticsData, ['offset'], []);

	const pagination = {
		limit,
		offset,
		total: total,
	};
	const isClickable = (value) => {
		return !_.isNil(value) && value != 0;
	};
	return (
		<div>
			<Table
				loading={false}
				backToTop
				pagination={pagination}
				handlePageChange={(value) => handlePagination('summary', value)}
				hasData={!_.isEmpty(summaryData) && !_.isNil(summaryData)}
				resultsPerPage={[25, 50, 100]}
				handleResultsPerPageChange={(value) =>
					handleResultsPerPageChange('summary', value)
				}
				noResultsFound={<div>No Results Found</div>}
			>
				<Thead>
					<Tr>
						<Th>Referral Partner</Th>
						<Th>Referral Program</Th>
						<Th>Complete</Th>
						<Th>Incomplete</Th>
						<Th>Meets Criteria</Th>
						<Th>Criteria No Met</Th>
						<Th>Consider Counseling</Th>
						<Th>Scheduled</Th>
					</Tr>
				</Thead>
				<Tbody>
					{(summaryData || []).map((item, i) => {
						const {
							referral_partner,
							referral_program,
							appointments_scheduled,
							complete,
							criteria_not_met,
							incomplete,
							criteria_met,
							consider_counseling,
						} = item;
						return (
							<Tr key={i}>
								<Td>{referral_partner}</Td>
								<Td>{referral_program}</Td>
								<Td>
									<span
										className={
											isClickable(complete)
												? 'smry_clickable'
												: ''
										}
										onClick={() =>
											isClickable(complete) &&
											handleSummaryTblClick(
												'complete',
												referral_partner,
												referral_program
											)
										}
									>
										{_.toString(complete)}
									</span>
								</Td>
								<Td>
									<span
										className={
											isClickable(incomplete)
												? 'smry_clickable'
												: ''
										}
										onClick={() =>
											isClickable(incomplete) &&
											handleSummaryTblClick(
												'incomplete',
												referral_partner,
												referral_program
											)
										}
									>
										{_.toString(incomplete)}
									</span>
								</Td>
								<Td>
									<span
										className={
											isClickable(criteria_met)
												? 'smry_clickable'
												: ''
										}
										onClick={() =>
											isClickable(criteria_met) &&
											handleSummaryTblClick(
												'criteria_met',
												referral_partner,
												referral_program
											)
										}
									>
										{_.toString(criteria_met)}
									</span>
								</Td>
								<Td>
									<span
										className={
											isClickable(criteria_not_met)
												? 'smry_clickable'
												: ''
										}
										onClick={() =>
											isClickable(criteria_not_met) &&
											handleSummaryTblClick(
												'criteria_not_met',
												referral_partner,
												referral_program
											)
										}
									>
										{_.toString(criteria_not_met)}
									</span>
								</Td>
								<Td>
									<span
										className={
											isClickable(consider_counseling)
												? 'smry_clickable'
												: ''
										}
										onClick={() =>
											isClickable(consider_counseling) &&
											handleSummaryTblClick(
												'consider_counseling',
												referral_partner,
												referral_program
											)
										}
									>
										{_.toString(consider_counseling)}
									</span>
								</Td>
								<Td>
									<span
										className={
											isClickable(appointments_scheduled)
												? 'smry_clickable'
												: ''
										}
										onClick={() =>
											isClickable(
												appointments_scheduled
											) &&
											handleSummaryTblClick(
												'appointments_scheduled',
												referral_partner,
												referral_program
											)
										}
									>
										{_.toString(appointments_scheduled)}
									</span>
								</Td>
							</Tr>
						);
					})}
				</Tbody>
			</Table>
		</div>
	);
};

export default DashboardSummaryDetail;
