export const GUARDIAN_RELATIONSHIP_ENUM = {
	CHILD: 'Child',
	PARENT: 'Parent',
	SPOUSE: 'Spouse/Domestic Partner',
	SIBLING: 'Sibling',
	LEGAL_GUARDIAN: 'Legal Guardian',
	OTHER: 'Other',
};

function makeVariant(value: string) {
	return { key: value, label: value, value };
}

interface GuardianRelationship {
	key: string;
	label: string;
	value: string;
}

export const guardianRelationships: GuardianRelationship[] = [
	makeVariant(GUARDIAN_RELATIONSHIP_ENUM.CHILD),
	makeVariant(GUARDIAN_RELATIONSHIP_ENUM.PARENT),
	makeVariant(GUARDIAN_RELATIONSHIP_ENUM.SPOUSE),
	makeVariant(GUARDIAN_RELATIONSHIP_ENUM.SIBLING),
	makeVariant(GUARDIAN_RELATIONSHIP_ENUM.LEGAL_GUARDIAN),
	makeVariant(GUARDIAN_RELATIONSHIP_ENUM.OTHER),
];

interface Variant {
	label: string;
	value: string;
}

export const dependentVariants: Variant[] = [
	{ label: 'Spouse or Partner', value: 'spouse' },
	{ label: 'Child', value: 'child' },
	{ label: 'Parent', value: 'parent' },
	{ label: 'Other', value: 'other' },
];

export const biologicalSexVariants: Variant[] = [
	{ label: 'Male', value: 'male' },
	{ label: 'Female', value: 'female' },
];

export const sexAssignedAtBirthOptions: Variant[] = [
	{ label: 'Male', value: 'male' },
	{ label: 'Female', value: 'female' },
	{ label: 'Intersex', value: 'intersex' },
];
