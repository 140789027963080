//React & Redux
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import PropTypes from 'prop-types';

//Images
import ReactSVG from 'react-svg';
import logoMin from './icons_logo_minimized.png';
import logoMax from './icons_logo_full.png';
import billing from '../../assets/billing.svg';
import reports from '../../assets/reports.svg';
import rise from '../../assets/rise.svg';
import patients from '../../assets/patients.svg';
import home from '../../assets/home.svg';
import search from '../../assets/search.svg';
import testManagement from '../../assets/test-management.svg';
import referral from '../../assets/referral.svg';
import referralPrograms from '../../assets/referral-programs.svg';
import appointmentsicon from '../../assets/appointments.svg';
import testAuthorization from '../../assets/test-authorization.svg';
import externalProviders from '../../assets/external-providers.svg';
import insurance from '../../assets/insurance.svg';
import templates from '../../assets/templates.svg';
import releaseNotes from '../../assets/release-notes.svg';
import userAccounts from '../../assets/user-accounts.svg';

//Lodash
import _ from 'lodash';

//Utils
import { isFeatureEnabled } from '../../Utils/featureFlags';
import {
	HOME as HOME_PERMISSION,
	PIONEER,
	CARE,
	FINANCE,
	OPERATIONS,
	HOME_DASHBOARD,
	CARE_PATIENTS,
	CARE_APPOINTMENTS,
	CARE_TEST_AUTHORIZATION,
	FINANCE_BILLING,
	FINANCE_INSURANCE,
	OPERATIONS_REPORTS,
	OPERATIONS_RISE,
	OPERATIONS_TESTS,
	OPERATIONS_TEMPLATE_MANAGEMENT,
	OPERATIONS_REFERRAL_PROGRAMS,
	OPERATIONS_EXTERNAL_PROVIDERS,
	OPERATIONS_USER_ACCOUNT_MANAGEMENT,
	ROLE_PHYSICIAN,
	ROLE_PRODENG,
	RELEASE_NOTES,
} from '../../Utils/permissionUtils';

//Components
import MenuItemCollection from './MenuItemCollection.js';
import RBAC from '../RBAC';

//Styles
import './style.css';
import { shouldBeMasked } from '../../utils.js';

const MapRoutes = new Map();

MapRoutes.set('/app/dashboard', { parentmenupath: '/app/dashboard' });
MapRoutes.set('/app/dashboard/encounters', {
	parentmenupath: '/app/dashboard',
});
MapRoutes.set('/app/dashboard/outreach/patients', {
	parentmenupath: '/app/dashboard',
});

MapRoutes.set('/app/patients', { parentmenupath: '/app/patients' });
MapRoutes.set('/app/patientmanagement', { parentmenupath: '/app/patients' });
MapRoutes.set('/app/advancedsearch', { parentmenupath: '/app/patients' });
MapRoutes.set('/app/patientdetail', { parentmenupath: '/app/patients' });
MapRoutes.set('/app/order_request', { parentmenupath: '/app/patients' });

MapRoutes.set('/app/referraldashboard', {
	parentmenupath: '/app/referraldashboard',
});

MapRoutes.set('/app/appointments', { parentmenupath: '/app/appointments' });
MapRoutes.set('/app/appointments/schedule', {
	parentmenupath: '/app/appointments',
});
MapRoutes.set('/app/appointments/reschedule', {
	parentmenupath: '/app/appointments',
});

MapRoutes.set('/app/testauthorization', {
	parentmenupath: '/app/testauthorization',
});

MapRoutes.set('/app/billing', { parentmenupath: '/app/billing' });
MapRoutes.set('/app/insurance', { parentmenupath: '/app/insurance' });
MapRoutes.set('/app/providers', { parentmenupath: '/app/providers' });
MapRoutes.set('/app/rise', { parentmenupath: '/app/rise' });

MapRoutes.set('/app/testmanagement', { parentmenupath: '/app/testmanagement' });
MapRoutes.set('/app/test', { parentmenupath: '/app/testmanagement' });

MapRoutes.set('/app/referralprograms', {
	parentmenupath: '/app/referralprograms',
});
MapRoutes.set('/app/referralprogramdetail', {
	parentmenupath: '/app/referralprograms',
});
MapRoutes.set('/app/templates', { parentmenupath: '/app/templates' });
MapRoutes.set('/app/externalproviders', {
	parentmenupath: '/app/externalproviders',
});
MapRoutes.set('/app/useraccounts', { parentmenupath: '/app/useraccounts' });

MapRoutes.set('/app/releasenotes', { parentmenupath: '/app/releasenotes' });

class NavComponent extends Component {
	constructor(props) {
		super(props);
		const CARE_ITEMS = [
			{
				text: 'Patients',
				path: '/app/patients',
				rbacaction: CARE_PATIENTS,
				idx: 1,
			},
			{
				text: 'Appointments',
				path: '/app/appointments',
				rbacaction: CARE_APPOINTMENTS,
				idx: 7,
			},
			{
				text: 'Test Authorization',
				path: '/app/testauthorization',
				rbacaction: CARE_TEST_AUTHORIZATION,
				idx: 10,
			},
		];

		if (isFeatureEnabled('REFERRAL_DASHBOARD', { allowCookie: true })) {
			CARE_ITEMS.splice(1, 0, {
				text: 'Referrals',
				path: '/app/referraldashboard',
				rbacaction: CARE_PATIENTS,
				idx: 89,
			});
		}

		this.state = {
			menuItemCollections: [
				{
					header: 'Home',
					group: HOME_PERMISSION,
					items: [
						{
							text: 'Home',
							path: '/app/dashboard',
							rbacaction: HOME_DASHBOARD,
							idx: 0,
						},
					],
				},
				{
					header: 'Pioneer',
					group: PIONEER,
					items: [
						{
							text: 'Pioneer',
							path: process.env.REACT_APP_PIONEER_BASE_URL,
							rbacaction: PIONEER,
							idx: 14,
						},
					],
				},
				{
					header: 'Care',
					group: CARE,
					items: CARE_ITEMS,
				},
				{
					header: 'Finance',
					group: FINANCE,
					items: [
						{
							text: 'Billing',
							path: '/app/billing',
							rbacaction: FINANCE_BILLING,
							idx: 2,
						},
						{
							text: 'Insurance',
							path: '/app/insurance',
							rbacaction: FINANCE_INSURANCE,
							idx: 8,
						},
					],
				},
				{
					header: 'Operations',
					group: OPERATIONS,
					items: [
						{
							text: 'RISE',
							path: '/app/rise',
							rbacaction: OPERATIONS_RISE,
							idx: 13,
						},
						{
							text: 'Reports',
							path: '/app/providers',
							rbacaction: OPERATIONS_REPORTS,
							idx: 3,
						},
						{
							text: 'Test Management',
							path: '/app/testmanagement',
							rbacaction: OPERATIONS_TESTS,
							idx: 4,
						},
						{
							text: 'Referral Programs',
							path: '/app/referralprograms',
							rbacaction: OPERATIONS_REFERRAL_PROGRAMS,
							idx: 5,
						},
						{
							text: 'Templates',
							path: '/app/templates',
							rbacaction: OPERATIONS_TEMPLATE_MANAGEMENT,
							idx: 9,
						},
						{
							text: 'External Providers',
							path: '/app/externalproviders',
							rbacaction: OPERATIONS_EXTERNAL_PROVIDERS,
							idx: 11,
						},
						{
							text: 'User Accounts',
							path: '/app/useraccounts',
							rbacaction: OPERATIONS_USER_ACCOUNT_MANAGEMENT,
							idx: 12,
						},
					],
				},
				{
					header: 'Updates',
					group: RELEASE_NOTES,
					customStyling: { marginTop: 'auto' },
					items: [
						{
							text: 'Release Notes',
							path: '/app/releasenotes',
							rbacaction: RELEASE_NOTES,
							idx: 100,
						},
					],
				},
			],
			pathsSansHome: new Set(),
			pathToIdxMap: {},
			expanded: false,
			expanding: false,
			mouseInTray: false,
		};
		this.renderIcon = this.renderIcon.bind(this);
		this.navRedirect = this.navRedirect.bind(this);
		this.createPathsSansHome = this.createPathsSansHome.bind(this);
		this.createPathToIdxMap = this.createPathToIdxMap.bind(this);
		this.activateMenu = this.activateMenu.bind(this);
	}

	static propTypes = {
		auth: PropTypes.object,
	};

	async componentDidMount() {
		window.addEventListener('mousemove', this.handleMouseMove);
		await this.createPathsSansHome();
		await this.createPathToIdxMap();
		const currentPath = _.get(this, ['props', 'location', 'pathname'], '');
		this.activateMenu(currentPath);
	}

	componentWillUnmount() {
		window.removeEventListener('mousemove', this.handleMouseMove);
	}

	componentDidUpdate(prevProps, prevState) {
		const currentPath = _.get(this, ['props', 'location', 'pathname'], '');
		if (_.get(prevProps, ['location', 'pathname'], '') != currentPath) {
			this.activateMenu(currentPath);
		}
	}

	createPathsSansHome() {
		const store = new Set();
		this.state.menuItemCollections.forEach((collection) => {
			collection.items.forEach((item) => {
				if (!item.path.match(/dashboard/gi)) store.add(item.path);
			});
		});
		return new Promise((resolve, reject) => {
			this.setState({ pathsSansHome: store }, resolve);
		});
	}

	createPathToIdxMap() {
		const store = {};
		this.state.menuItemCollections.forEach((collection) => {
			collection.items.forEach((item) => {
				store[item.path] = item.idx;
			});
		});
		return new Promise((resolve, reject) => {
			this.setState({ pathToIdxMap: store }, resolve);
		});
	}

	renderIcon(name, activityFlag) {
		const svgClass = activityFlag
			? 'menuItemIcon menuItemIconActive'
			: 'menuItemIcon';
		const lastViewedReleaseNoteVersion = _.get(
			this,
			['props', 'me', 'user', 'last_viewed_release_note_version'],
			''
		);
		switch (name) {
			case 'Billing':
				return <ReactSVG className={svgClass} src={billing} />;
			case 'Reports':
				return <ReactSVG className={svgClass} src={reports} />;
			case 'RISE':
				return <ReactSVG className={svgClass} src={rise} />;
			case 'Patients':
				return <ReactSVG className={svgClass} src={patients} />;
			case 'Referrals':
				return <ReactSVG className={svgClass} src={referral} />;
			case 'Home':
				return <ReactSVG className={svgClass} src={home} />;
			case 'Pioneer':
				return <ReactSVG className={svgClass} src={home} />;
			case 'Adv. Search':
				return <ReactSVG className={svgClass} src={search} />;
			case 'Test Management':
				return <ReactSVG className={svgClass} src={testManagement} />;
			case 'Referral Programs':
				return <ReactSVG className={svgClass} src={referralPrograms} />;
			case 'Appointments':
				return <ReactSVG className={svgClass} src={appointmentsicon} />;
			case 'Insurance':
				return <ReactSVG className={svgClass} src={insurance} />;
			case 'Templates':
				return <ReactSVG className={svgClass} src={templates} />;
			case 'Test Authorization':
				return (
					<ReactSVG className={svgClass} src={testAuthorization} />
				);
			case 'External Providers':
				return (
					<ReactSVG className={svgClass} src={externalProviders} />
				);
			case 'User Accounts':
				return <ReactSVG className={svgClass} src={userAccounts} />;
			case 'Release Notes':
				return (
					<Fragment>
						<ReactSVG className={svgClass} src={releaseNotes} />
						{process.env.REACT_APP_GMI_TAG &&
							lastViewedReleaseNoteVersion !==
								process.env.REACT_APP_GMI_TAG && (
								<span className='badge'></span>
							)}
					</Fragment>
				);
			default:
				return new Error('That icon is not imported.');
		}
	}

	handleMouseMove = (e) => {
		// Check if the mouse is within the tray's bounds
		const trayRect = this.sideMenu.getBoundingClientRect();

		const isMouseInTray =
			e.clientX >= trayRect.left &&
			e.clientX <= trayRect.right &&
			e.clientY >= trayRect.top &&
			e.clientY <= trayRect.bottom;

		if (!isMouseInTray && this.state.expanded) {
			this.retractMenu();
		}

		this.setState({ mouseInTray: isMouseInTray });
	};

	expandMenu(e) {
		this.sideMenu.classList.remove('leftNavContainerRetracted');
		this.sideMenu.classList.add('leftNavContainerExpanded');
		this.setState({ expanding: true });
		setTimeout(() => {
			if (this.state.expanding) {
				this.logo.src = logoMax;
				this.logo.classList.add('logoMax');
				this.logo.classList.remove('logoMin');
				this.props.toggleDimmerOn();
				this.setState({
					expanded: true,
					expanding: false,
				});
			}
		}, 165);
	}

	retractMenu(e) {
		this.sideMenu.classList.remove('leftNavContainerExpanded');
		this.sideMenu.classList.add('leftNavContainerRetracted');
		this.logo.src = logoMin;
		this.logo.classList.add('logoMin');
		this.logo.classList.remove('logoMax');
		this.setState({
			expanded: false,
			expanding: false,
		});
		this.props.toggleDimmerOff();
	}

	changeLogo(e) {
		if (this.logo.src === logoMin) {
			this.logo.classList.add('logoMax');
			this.logo.classList.remove('logoMin');
		} else {
			this.logo.classList.add('logoMin');
			this.logo.classList.remove('logoMax');
		}
		this.logo.src === logoMin
			? (this.logo.src = logoMax)
			: (this.logo.src = logoMin);
	}

	navRedirect(path, text) {
		if (text && text === 'Pioneer') window.open(path, '_blank');
		else this.props.history.push(path);
	}

	logoClickHandler = () => {
		const activerole = _.get(
			this,
			['props', 'me', 'activerole', 'name'],
			''
		);
		if (activerole !== '') {
			if (activerole == ROLE_PHYSICIAN) {
				this.navRedirect('/app/testauthorization');
			} else this.navRedirect('/app/dashboard');
		}
	};

	activateMenu(path) {
		let idx = 0; //default it should be dashboard
		const keys = [...MapRoutes.keys()];
		if (MapRoutes.has(path)) {
			const route = MapRoutes.get(path);
			idx = this.state.pathToIdxMap[route.parentmenupath];
		} else {
			const findpath = _.find(keys, (value) => {
				return path.includes(value);
			});
			if (findpath) {
				const route = MapRoutes.get(findpath);
				idx = this.state.pathToIdxMap[route.parentmenupath];
			}
		}
		this.setState({ activeItemIdx: idx });
	}

	render() {
		const { className } = this.props;
		const role = _.get(this, ['props', 'me', 'activerole', 'name'], null);
		return (
			<div className={classnames('NavBar fullheight ', className)}>
				<div
					className='leftNavContainerRetracted'
					id='leftNavContainer1'
					onMouseEnter={(e) => this.expandMenu(e)}
					onMouseLeave={(e) => this.retractMenu(e)}
					ref={(el) => (this.sideMenu = el)}
				>
					<img
						className='logoMin'
						src={logoMin}
						ref={(el) => (this.logo = el)}
						onClick={(evt) => this.logoClickHandler()}
					/>
					{this.state.menuItemCollections.map((collection, i) => {
						return (
							<RBAC
								action={collection.group}
								key={collection.header}
								yes={
									<MenuItemCollection
										collection={collection}
										colIdx={i}
										key={collection.header}
										expanded={this.state.expanded}
										activeItemIdx={this.state.activeItemIdx}
										renderIcon={this.renderIcon}
										navRedirect={this.navRedirect}
										role={role}
									/>
								}
							/>
						);
					})}
					{role === ROLE_PRODENG && process.env.REACT_APP_GMI_TAG && (
						<div
							className={classnames('gmi-version', {
								expanded: this.state.expanded,
							})}
						>
							{process.env.REACT_APP_GMI_TAG}
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	me: state.me,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(NavComponent)
);
