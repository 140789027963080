//React & Redux
import React from 'react';

//UI Libraries
import { Layout, Input } from 'gm-element-react';

const EditBillingNotesSegment = (props) => {
	const {
		handleChange,
		stateData: { billingNotes = '' },
	} = props;

	return (
		<>
			<Layout.Row gutter='20'>
				<Layout.Col span='24'>
					<div className='test-number-container'>
						<div className='test-number'>Billing Notes</div>
						<div className='divider'></div>
					</div>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20'>
				<Layout.Col span='24'>
					<span className='lbl-enctr-sgmnt'>Billing Notes</span>
					<span className='val-enctr-sgmnt-field'>
						<Input
							type='textarea'
							rows={5}
							onChange={(value) =>
								handleChange('billing_notes', value)
							}
							value={billingNotes}
							className='lab-comments text-area'
						></Input>
					</span>
				</Layout.Col>
			</Layout.Row>
		</>
	);
};

export default EditBillingNotesSegment;
