//React & Redux
import React, { Fragment, useState, useEffect } from 'react';

//Images
import phoneIcon from '../../../assets/phone-purple-16x16.svg';
import videoIcon from '../../../assets/video-purple-16x16.svg';

//Lodash
import _ from 'lodash';

//Utils
import { UTCToCurrentTimeView } from '../../../utils.js';

//Components
import { Table, Tbody, Tr, Td } from '../../Common/Table';

//Other Libraries
import moment from 'moment';

//Styles
import './upcoming-appointment.css';

const DEFAULT_LIMIT = 20;

const UpcomingAppointments = (props) => {
	const {
		upcoming_appointments = {},
		history,
		getDashboardAppointments,
		loadingUpcomingappointments = false,
	} = props;

	const [activePage, setActivePage] = useState(0);
	const [limit, setLimit] = useState(DEFAULT_LIMIT);

	const GroupAppointmentByDate = (appointments) => {
		const appoints =
			appointments && appointments.data && _.isArray(appointments.data)
				? appointments.data
				: [];

		const grouped = _.groupBy(appoints, (app) => {
			const dos = moment(app.date_of_service);
			const dosmoment = moment.tz(
				dos.format('YYYY-MM-DD HH:mm:ss'),
				'UTC'
			);
			return moment.tz(dosmoment, app.timezone).format('YYYY-MM-DD');
		});

		const sortedgrouped = _.sortBy(_.keys(grouped), [
			function (key) {
				return moment(key);
			},
		]);

		const grouparray = _.map(sortedgrouped, (key) => {
			return { key: key, data: grouped[key] };
		});

		return grouparray;
	};

	const getGroupDate = (appointment) => {
		const stringDate = _.get(appointment, 'key', '');
		const date = moment(stringDate, 'YYYY-MM-DD');
		const timestamp = date.unix();
		const day = date.format('DD');
		const monthday = date.format('MMM, ddd');

		return { day, monthday, timestamp };
	};

	const isTodayDate = (appointment) => {
		const stringDate = _.get(appointment, 'key', '');
		const today = moment().format('YYYY-MM-DD');

		return today == stringDate;
	};

	const getPatientFullName = (uuid) => {
		const patientsList = _.get(
			upcoming_appointments,
			'patientslist.data',
			[]
		);
		const patient = _.find(patientsList, function (o) {
			return o.uuid == uuid;
		});
		const fullName =
			_.get(patient, 'first_name', '') +
			' ' +
			_.get(patient, 'last_name', '');

		return fullName;
	};

	const getAppointmentModalityIcon = (appointment) => {
		const modality = _.get(appointment, ['tm_platform'], 'phone');

		if (modality.toLowerCase() == 'video') {
			return <img src={videoIcon} alt='modality' />;
		} else {
			return <img src={phoneIcon} alt='modality' />;
		}
	};

	const navigateToPatientDetail = (uuid) => {
		history.push('/app/patientdetail/' + uuid + '/0/0');
	};

	const renderappointmentGroup = (group) => {
		const groupstringdate = getGroupDate(group);
		const dayclass = isTodayDate(group)
			? 'apntmnt-today-day-date'
			: 'apntmnt-day-num';
		const datestringclass = isTodayDate(group)
			? 'apntmnt-today-day'
			: 'apntmnt-day';

		return group.data.map((appointment, idx) => {
			const StartTime = UTCToCurrentTimeView(
				moment,
				moment(_.get(appointment, 'date_of_service')),
				_.get(appointment, 'timezone')
			);
			return (
				<Tr key={idx} parent='tbody' doNotClone={true}>
					{idx == 0 && (
						<Td
							className='cell-text td-apntmnt-date'
							rowSpan={group?.data?.length || '1'}
						>
							<div className='apntmnt-date'>
								<div className={dayclass}>
									{_.get(groupstringdate, 'day', '')}
								</div>
								<div className={datestringclass}>
									{_.get(groupstringdate, 'monthday', '')}
								</div>
							</div>
						</Td>
					)}
					<Td className='cell-text apntmnt-time'>{StartTime}</Td>
					<Td className='cell-text'>
						<span
							className='patient-fullname'
							onClick={(evt) =>
								navigateToPatientDetail(
									_.get(appointment, 'user_uuid', '')
								)
							}
						>
							{getPatientFullName(
								_.get(appointment, 'user_uuid', '')
							)}
						</span>
						<span className='apntmnt-type'>
							{_.get(appointment, ['consultation_type'], '--')}
						</span>
					</Td>
					<Td className='cell-text'>
						{getAppointmentModalityIcon(appointment)}
					</Td>
				</Tr>
			);
		});
	};

	const appointmentDateRange = () => {
		const momentDate = moment();
		const todayDate = momentDate.format('MMM DD');
		const tommorowDate = momentDate.add(1, 'day').format('MMM DD');
		return todayDate + ' - ' + tommorowDate;
	};

	const handlePageChange = (evt, pageNumber) => {
		getDashboardAppointments({ offset: pageNumber });
		setActivePage(pageNumber);
	};

	const GroupedAppointments = GroupAppointmentByDate(upcoming_appointments);

	const { data = [], total = 0 } = upcoming_appointments;
	const pagination = {
		limit: limit,
		offset: activePage,
		total: total,
	};

	return (
		<section className='extrnl-dashbrd-upcoming-apntmnt-wrapper'>
			<span className='apntmnt-range'>{appointmentDateRange()}</span>
			{total > 0 ? (
				<Fragment>
					<Table
						loading={loadingUpcomingappointments}
						pagination={pagination}
						limit={limit}
						handlePageChange={handlePageChange}
						hasData={data.length > 0}
						emptyOnNoResults={true}
						className='dashboardAppointmentsTable'
						containerStyle={{
							minWidth: 'unset',
							maxWidth: 'unset',
							width: '100%',
							minHeight: '150px',
						}}
					>
						<Tbody doNotClone={true}>
							{GroupedAppointments.map((group) =>
								renderappointmentGroup(group)
							)}
						</Tbody>
					</Table>
				</Fragment>
			) : (
				<div className='no-appointment'>NO APPOINTMENTS</div>
			)}
		</section>
	);
};

export default UpcomingAppointments;
