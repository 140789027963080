export const advanced_search_categories_master = [
	{
		key: 'full_name',
		name: 'Name',
		id: 1,
		icon: 'name',
		condition: ['contains'],
		placeholder: 'John Snow',
	},
	{
		key: 'first_name',
		name: 'First Name',
		id: 2,
		icon: 'name',
		condition: ['contains'],
		placeholder: 'John',
	},
	{
		key: 'last_name',
		name: 'Last Name',
		id: 3,
		icon: 'name',
		condition: ['contains'],
		placeholder: 'Snow',
	},
	{
		key: 'dob',
		name: 'Date of Birth',
		id: 4,
		icon: 'dob',
		condition: ['is'],
		placeholder: 'MM/DD/YYYY',
	},
	{
		key: 'phone',
		name: 'Phone Number',
		id: 5,
		icon: 'phone',
		condition: ['contains'],
		placeholder: '(123) 456 - 7890',
	},
	{
		key: 'referral_program',
		name: 'Referral Program',
		id: 6,
		icon: 'referral-program',
		condition: ['contains', 'is'],
		placeholder: 'Helix, PerkinElmer-Helix, Invitae',
	},
	{
		key: 'email',
		name: 'Email',
		id: 7,
		icon: 'email',
		condition: ['contains', 'is'],
		placeholder: 'email@email.com',
	},
	{
		key: 'date_of_service',
		name: 'Date of Service',
		id: 8,
		icon: 'dos',
		condition: ['is', 'after', 'before'],
		placeholder: '',
	},
	{
		key: 'coupon_code',
		name: 'Coupon',
		id: 9,
		icon: 'coupon',
		condition: ['contains'],
		placeholder: 'FREE, BRCA90, GMFRIENDS45',
	},
	{
		key: 'payor',
		name: 'Insurance',
		id: 10,
		icon: 'insurance',
		condition: ['contains'],
		placeholder: 'Cigna, Kaiser Permanente, Blue Cross Blue Shield',
	},
	{
		key: 'uuid',
		name: 'Patient ID',
		id: 11,
		icon: 'name',
		condition: ['contains'],
		placeholder: 'abc123xa-bfc7-4712-a880-0e08342c5a86',
	},
	{
		key: 'billing_notes',
		name: 'Billing Notes',
		id: 12,
		icon: 'billing_notes',
		condition: ['contains'],
		placeholder: 'Invoice patient, mode of payment, insurance declined',
	},
	{
		key: 'cc_comments',
		name: 'Patient Comment',
		id: 13,
		icon: 'billing_notes',
		condition: ['contains'],
		placeholder: 'Preferred name, point of contact, parent/child',
	},
	{
		key: 'visit_provider',
		name: 'Genetic Counselor',
		id: 14,
		icon: 'gc',
		condition: ['is'],
		placeholder: 'Erynn Gordon',
	},
	{
		key: 'lab',
		name: 'Lab',
		id: 15,
		icon: 'lab',
		condition: ['is'],
		placeholder: 'Invitae',
	},
	{
		key: 'order_status',
		name: 'Order Status',
		id: 16,
		icon: 'order_status',
		condition: ['is'],
		placeholder: 'Not requested, sent to lab, results ready',
	},
	{
		key: 'type_of_test',
		name: 'Test Type',
		id: 17,
		icon: 'test_type',
		condition: ['is'],
		placeholder: 'GenePrism, Proact-Cancer, FH, MODY',
	},
	{
		key: 'encounter_type',
		name: 'Encounter Type',
		id: 18,
		icon: 'encounter',
		condition: ['is'],
		placeholder: 'Visit, lab, group session',
	},
	{
		key: 'external_patient_id',
		name: 'External Patient ID',
		id: 19,
		icon: 'reports',
		condition: ['contains'],
		placeholder: '1234ABC',
	},
	{
		key: 'ordering_physician',
		name: 'Ordering Physician',
		id: 20,
		icon: 'gc',
		condition: ['is'],
		placeholder: 'Erynn Gordon',
	},
	{
		key: 'vsee_specialty',
		name: 'Requested Specialty',
		id: 21,
		icon: 'gc',
		condition: ['is'],
		placeholder: 'Cancer',
	},
	{
		key: 'provider_indicated_specialty',
		name: 'Provider Indicated Specialty',
		id: 22,
		icon: 'gc',
		condition: ['is'],
		placeholder: 'Cancer',
	},
	{
		key: 'visit_status',
		name: 'Visit Status',
		id: 23,
		icon: 'gc',
		condition: ['is'],
		placeholder: 'Booked',
	},
	{
		key: 'test_name',
		name: 'Test Name',
		id: 24,
		icon: 'lab',
		condition: ['is'],
		placeholder: 'GenePrism, Proact-Cancer, FH, MODY',
	},
	{
		key: 'test_result',
		name: 'Result',
		id: 25,
		icon: 'lab',
		condition: ['is'],
		placeholder: 'Findings, No Findings, Empty',
	},
	{
		key: 'dispatch_status',
		name: 'Preauth Dispatch Status',
		id: 26,
		icon: 'order_status',
		condition: ['is'],
		placeholder: 'Awaiting CC Submission, PA Form Sent',
	},
	{
		key: 'request_decision',
		name: 'Preauth Decision',
		id: 27,
		icon: 'order_status',
		condition: ['is'],
		placeholder: 'Patient Declined - PA-CNM , Recommended - PA-CNM',
	},
	{
		key: 'referring_provider_name',
		name: 'Referring Provider',
		id: 28,
		icon: 'gc',
		condition: ['is'],
		placeholder: 'John Snow',
	},
	{
		key: 'state_of_visit',
		name: 'Visit State',
		id: 29,
		icon: 'gc',
		condition: ['is'],
		placeholder: 'CA, TX',
	},
	{
		key: 'multipatient_visit',
		name: 'Multi-patient Visit',
		id: 30,
		icon: 'multi-patient',
		condition: ['is'],
		placeholder: 'True, False, N/A',
	},
	{
		key: 'outreach_status',
		name: 'Outreach Status',
		id: 31,
		icon: 'order_status',
		condition: ['is'],
		placeholder: 'Not Started, Outreach Complete',
	},
	{
		key: 'tag_display_name',
		name: 'Patient Tag',
		id: 32,
		icon: 'pt',
		condition: ['is', 'is not'],
		placeholder: 'Patient Tag',
	},
	{
		key: 'is_actionable',
		name: 'Actionability',
		id: 33,
		condition: ['is'],
		placeholder: 'Actionable',
	},
	{
		key: 'gene_symbol',
		name: 'Gene',
		id: 34,
		icon: 'test_type',
		condition: ['is'],
		placeholder: 'Gene',
	},
];

export const hiddenAdvancedSearchCategoriesOnProd = new Set(['Gene']);

export const advancedSearchEncounterCategories = [
	{
		id: 'lab_test_authorization',
		name: 'Lab',
	},
	{
		id: 'visit',
		name: 'Visit',
	},
	{
		id: 'group-session',
		name: 'Group Session',
	},
	{
		id: 'cc-intake',
		name: 'CC Intake',
	},
];

export const allowed_search_categories = [
	'name',
	'first name',
	'last name',
	'phone',
	'email',
	'patient id',
	'referral program',
	'insurance',
	'coupon',
	'date of birth',
	'date of service',
	'billing notes',
	'patient comment',
	'genetic counselor',
	'ordering physician',
	'lab',
	'order status',
	'test name',
	'result',
	'test type',
	'encounter type',
	'external patient id',
	'requested specialty',
	'provider indicated specialty',
	'visit status',
	'preauth dispatch status',
	'preauth decision',
	'referring provider',
	'visit state',
	'multi-patient visit',
	'is_actionable',
];

export const allowed_search_categories_display = [
	'Name',
	'First Name',
	'Last Name',
	'Phone',
	'Email',
	'Patient ID',
	'Referral Program',
	'Insurance',
	'Coupon',
	'Date of Birth',
	'Date of Service',
	'Billing Notes',
	'Patient Comment',
	'Genetic Counselor',
	'Ordering Physician',
	'Lab',
	'Order Status',
	'Test Name',
	'Result',
	'Test Type',
	'Encounter Type',
	'External Patient ID',
	'Visit Status',
	'Requested Specialty',
	'Provider Indicated Specialty',
	'Preauth Dispatch Status',
	'Preauth Decision',
	'Referring Provider',
	'Visit State',
	'Multi-patient Visit',
	'Outreach Status',
	'Patient Tags',
	'Actionability',
	'Gene',
];

export const conditions_categories = {
	date_of_service: [
		{
			key: 'condition',
			name: 'is',
			id: 'condition_is',
			placeholder: 'MM/DD/YYYY',
		},
		{
			key: 'condition',
			name: 'after',
			id: 'condition_after',
			placeholder: 'MM/DD/YYYY',
		},
		{
			key: 'condition',
			name: 'before',
			id: 'condition_before',
			placeholder: 'MM/DD/YYYY',
		},
		{
			key: 'condition',
			name: 'between',
			id: 'condition_between',
			placeholder: 'MM/DD/YYYY',
		},
	],
	email: [
		{
			key: 'condition',
			name: 'is',
			id: 'condition_is',
			placeholder: 'email@email.com',
		},
		{
			key: 'condition',
			name: 'contains',
			id: 'condition_contains',
			placeholder: 'email@email.com',
		},
	],
	referral_program: [
		{
			key: 'condition',
			name: 'is',
			id: 'condition_is',
			placeholder: 'Helix, PerkinElmer-Helix, Invitae',
		},
		{
			key: 'condition',
			name: 'contains',
			id: 'condition_contains',
			placeholder: 'Helix, PerkinElmer-Helix, Invitae',
		},
	],
	tag_display_name: [
		{
			key: 'condition',
			name: 'is',
			id: 'condition_is',
			placeholder: 'Patient Tag',
		},
		{
			key: 'condition',
			name: 'is not',
			id: 'condition_is_not',
			placeholder: 'Patient Tag',
		},
	],
};
export const EnhancedSearchHeader = [
	{
		key: 'first_name',
		display: 'First',
		type: 'fixed',
		issortable: true,
	},
	{
		key: 'last_name',
		display: 'Last',
		type: 'fixed',
		issortable: true,
	},
	{
		key: 'dob',
		display: 'DOB',
		type: 'fixed',
	},
	{
		key: 'phone',
		display: 'Phone',
		type: 'fixed',
	},

	{
		key: 'referral_program',
		display: 'Referral Program',
		type: 'fixed',
		issortable: true,
	},
	{
		key: 'email',
		display: 'Email',
		type: 'fixed',
	},
	{
		key: 'external_patient_id',
		display: 'External Patient ID',
		type: 'dynamic',
	},
	{
		key: 'date_of_service',
		display: 'Date of Service*',
		type: 'dynamic',
	},
	{
		key: 'order_status',
		display: 'Order Status*',
		type: 'dynamic',
	},
	{
		key: 'dispatch_status',
		display: 'Preauth Dispatch Status*',
		type: 'dynamic',
	},
	{
		key: 'request_decision',
		display: 'Preauth Decision*',
		type: 'dynamic',
	},
	{
		key: 'lab',
		display: 'Lab*',
		type: 'dynamic',
	},
	{
		key: 'test_name',
		display: 'Test Name*',
		type: 'optional',
	},
	{
		key: 'test_result',
		display: 'Result*',
		type: 'optional',
	},
	{
		key: 'visit_provider',
		display: 'Genetic Counselor*',
		type: 'optional',
	},
	{
		key: 'ordering_physician',
		display: 'Ordering Physician*',
		type: 'optional',
	},
	{
		key: 'vsee_specialty',
		display: 'Requested Specialty*',
		type: 'optional',
	},
	{
		key: 'provider_indicated_specialty',
		display: 'Provider Indicated Specialty*',
		type: 'optional',
	},
	{
		key: 'visit_status',
		display: 'Visit Status*',
		type: 'optional',
	},
	{
		key: 'visit_state',
		display: 'Visit State*',
		type: 'optional',
	},
	{
		key: 'multipatient_visit',
		display: 'Multi-patient Visit?*',
		type: 'optional',
	},
	{
		key: 'referring_provider_name',
		display: 'Referring Provider*',
		type: 'optional',
	},
	{
		key: 'coupon_code',
		display: 'Coupon*',
		type: 'optional',
	},
	{
		key: 'payor',
		display: 'Insurance',
		type: 'optional',
	},
	{
		key: 'outreach_status',
		display: 'Outreach Status*',
		type: 'dynamic',
	},
	{
		key: 'uuid',
		display: 'Patient ID',
		type: 'optional',
	},
	{
		key: 'tag_display_name',
		display: 'Patient Tag*',
		type: 'dynamic',
	},
];

export const encounter_keys_dict = {
	date_of_service: 'Date of Service*',
	order_status: 'Order Status*',
	lab: 'Lab*',
	type_of_test: 'Test Type*',
	visit_provider: 'Genetic Counselor*',
	ordering_physician: 'Ordering Physician*',
	coupon_code: 'Coupon*',
	payor: 'Insurance*',
	external_patient_id: 'External Patient ID',
	vsee_specialty: 'Requested Specialty*',
	provider_indicated_specialty: 'Provider Indicated Specialty*',
	visit_status: 'Visit Status*',
	test_name: 'Test Name*',
	test_result: 'Result',
	dispatch_status: 'Preauth Dispatch Status*',
	request_decision: 'Preauth Decision*',
	referring_provider_name: 'Referring Provider*',
	state_of_visit: 'Visit State*',
	multipatient_visit: 'Multi-patient Visit?*',
	outreach_status: 'Outreach Status*',
	tag_display_name: 'Patient Tag*',
	gene_symbol: 'Gene*',
};
