//React & Redux
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
//Images
import add from '../../assets/add.svg';
import ReactSVG from 'react-svg';
import dropdownIcon from '../../assets/dropdown.svg';
import multipleVisitIcon from '../../assets/multi-visit.svg';

//Lodash
import get from 'lodash/get';
import _ from 'lodash';
import find from 'lodash/find';

//Utils
import {
	UTCToCurrentDateView,
	UTCToCurrentTimeView,
	RemoveUnderscoreAndCapitalizeInString,
	AddEncounterTypes,
} from '../../utils';
import { isFeatureEnabled } from '../../Utils/featureFlags';
import {
	imageDefault,
	providers_images,
	encounter_types_images,
	encounter_types,
	visit_status_colors,
	scp_encounter_sub_types_images,
	isMultipleVisit,
	isPrimaryVisit,
	isSecondaryVisit,
	isManualVisit,
} from '../../Utils/encounterUtils';
import {
	getSCPEncounterSubType,
	isPartnerInitiatedPreTest,
	getOrderStatusDisplayNameForAll,
	isPartnerInitiatedUnsolicitedResult,
} from './V2/Encounters/Common/utilsfunctions';
import {
	CARE_PATIENTS_PATIENT_ADD_ENCOUNTER,
	CARE_APPOINTMENTS_SCHEDULING,
} from '../../Utils/permissionUtils';

//Actions & Services
import {
	getencountergroupsessionschema,
	getencounterccintakeschema,
	getencountervisitsschema,
	getencounterlabseschema,
	getGeneTestEnums,
	resetencounters,
} from '../../actions/encounter';
import { patientdetail } from '../../actions/patients';
//UI Libraries
import Tooltip from './../Common/Tooltip';
import Pagination from './../Common/Pagination';
import { Button, Dropdown } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';
import moment from 'moment';
import 'moment-timezone';

//Components
import RBAC from '../RBAC';
import OrderStatusPill from '../Common/OrderStatusPill';
import Icon from '../Common/Icon';
import InvitePatientToSchedule from '../Patients/InvitePatientToSchedule.jsx';

//Styles
import './encounterlist.css';
import 'react-datetime/css/react-datetime.css';

const DEFAULT_LIMIT = 20;

const dash = <span className='pat-dash'>--</span>;

const EncounterList = (props) => {
	const {
		callresetencounters,
		getEncounterList,
		callencountergroupsessionschema,
		callccintakeschema,
		callvisitsschema,
		calllabsschema,
		callGeneTestEnums,
		changeEncounterTab,
		updateAddEncountedType,
		showAddEncounter,
		handlePageChange,
		activePage,
		encounter_list,
		groupsessionschema,
		ccintakeschema,
		labsschema,
		visitsschema,
		MLOOrderStatus,
		visitProvider,
		showMultiPatientVisitEncounterModal,
	} = props;

	const [open, setOpen] = useState(false);
	const [inviteModal, setInviteModal] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		window.scrollTo(0, 0);
		callresetencounters();
		getEncounterList();
		callencountergroupsessionschema();
		callccintakeschema();
		callvisitsschema();
		calllabsschema();
		callGeneTestEnums();
	}, []);

	const RedirectToEncounterDetail = (uuid, enctype, type) => {
		changeEncounterTab(uuid, enctype);
	};

	const getRequestStatus = (value, key, enc_type) => {
		if (value == 'na') return 'N/A';
		else if (value == '' || value == null) return value;
		else return getStatusLabel(value, key, enc_type);
	};

	const getConsultationSubType = (obj) => {
		if (isSCPEncoutnerWithSubType(obj)) {
			return getSCPEncounterSubType(obj);
		} else {
			return obj.type && obj.consultation_type && obj.type == 'visit'
				? ' - ' + obj.consultation_type
				: '';
		}
	};

	const getShortText = (text, length) => {
		if (text && !_.isEmpty(text) && text.length > length) {
			return (
				<Tooltip
					className='short-text-with-tooltip'
					effect='dark'
					content={text}
					placement='top'
				>
					<span>{text.substring(0, length) + '...'}</span>
				</Tooltip>
			);
		} else {
			return text;
		}
	};

	const getVisitStatusColor = (status) => {
		return get(visit_status_colors, status, 'green');
	};

	const getStatusLabel = (value, key, enc_type) => {
		let status = null;
		switch (enc_type) {
			case 'group-session':
				const groupSession = get(groupsessionschema, ['data', key], []);
				const groupSessionRow = groupSession.find(
					(a) => a.key === value,
				);
				status = get(groupSessionRow, 'display_name', null);
				break;
			case 'cc-intake':
				const ccIntake = get(ccintakeschema, ['data', key], []);
				const ccIntakeRow = ccIntake.find((a) => a.key === value);
				status = get(ccIntakeRow, 'display_name', null);
				break;
			case 'lab_test_authorization':
				const lab = get(labsschema, ['data', key], []);
				const labRow = lab.find((a) => a.key === value);
				status = get(labRow, 'display_name', null);
				break;
			case 'visit':
			case 'scp':
				const visit = get(visitsschema, ['data', key], []);
				const visitRow = visit.find((a) => a.key === value);
				status = get(visitRow, 'display_name', null);
				break;
		}
		return status;
	};

	const getOrderStatusLabel = (value, status_reason, enc_type) => {
		let status = null;

		if (!_.isEmpty(status_reason)) {
			status = getOrderStatusDisplayNameForAll(
				value,
				status_reason,
				MLOOrderStatus,
				false,
			);
		} else {
			switch (enc_type) {
				case 'group-session':
					const groupSession = get(
						groupsessionschema,
						'data.order_status',
						[],
					);
					const groupSessionRow = groupSession.find(
						(a) => a.key === value,
					);
					status = get(groupSessionRow, 'display_name', null);
					break;
				case 'cc-intake':
					const ccIntake = get(
						ccintakeschema,
						'data.order_status',
						[],
					);
					const ccIntakeRow = ccIntake.find((a) => a.key === value);
					status = get(ccIntakeRow, 'display_name', null);
					break;
				case 'lab_test_authorization':
					const lab = get(labsschema, 'data.order_status_lab', []);
					const labRow = lab.find((a) => a.key === value);
					status = get(labRow, 'display_name', null);
					break;
				case 'visit':
				case 'scp':
					const visit = get(visitsschema, 'data.order_status', []);
					const visitRow = visit.find((a) => a.key === value);
					status = get(visitRow, 'display_name', null);
					break;
				default:
					status = null;
					break;
			}
		}
		if (value && !status)
			return RemoveUnderscoreAndCapitalizeInString(value);
		return status || 'N/A';
	};

	const getEncounterSpecificPatientDate = (dos, timezone, type) => {
		const tz = type == 'research-data' ? 'America/New_York' : timezone;
		return UTCToCurrentDateView(moment, moment(dos), tz);
	};

	const getEncounterSpecificPatientTime = (dos, timezone, type, format) => {
		const tz = type == 'research-data' ? 'America/New_York' : timezone;
		return UTCToCurrentTimeView(moment, moment(dos), tz, format);
	};

	const getProviderImage = (obj) => {
		let providerImage = null;
		const type = get(obj, 'type', '');
		switch (type) {
			case 'lab_test_authorization':
			case 'scp':
				const labProviderName = get(obj, 'ordering_physician', '');
				const mappedImageLab = providers_images[labProviderName];
				if (labProviderName && mappedImageLab) {
					providerImage = <img src={mappedImageLab} alt='' />;
				} else if (labProviderName != '')
					providerImage = <img src={imageDefault} alt='' />;
				else providerImage = <img src={imageDefault} alt='' />;
				break;
			case 'cc-intake':
			case 'group-session':
			case 'visit':
				const providerName = get(obj, 'visit_provider', '');
				const mappedImage = providers_images[providerName];
				if (providerName && mappedImage) {
					providerImage = <img src={mappedImage} alt='' />;
				} else if (providerName != '') {
					providerImage = <img src={imageDefault} alt='' />;
				} else {
					providerImage = null;
				}
				break;
			case 'clinical-review':
				providerImage = null;
				break;
		}
		return (
			providerImage && (
				<span className='patient-imgbox'>{providerImage}</span>
			)
		);
	};

	const getProviderName = (obj) => {
		let result = '';
		let providerName = '';
		const type = get(obj, 'type', '');
		let separator = ' ';
		let enum_value = '';
		switch (type) {
			case 'lab_test_authorization':
			case 'scp':
				const enumslab = get(
					visitsschema,
					['data', 'ordering_physician'],
					[],
				); //labschema doesn't have ordreing_physician so visitschema needs to be use.
				enum_value = find(enumslab, function (o) {
					return o.key == get(obj, 'ordering_physician', '--');
				});
				if (enum_value) {
					providerName = get(enum_value, 'display_name', '');
				} else {
					separator = '_';
					providerName = ucFirstAllWords(
						get(obj, 'ordering_physician', ''),
						separator,
					);
				}
				//providerName=get(obj,'ordering_physician','--');
				break;
			case 'cc-intake':
			case 'group-session':
			case 'visit':
				const enums = visitProvider;
				enum_value = find(enums, function (o) {
					return o.key == get(obj, 'visit_provider', '--');
				});
				if (enum_value) {
					providerName = get(enum_value, 'display_name', '');
				} else {
					separator = '_';
					providerName = ucFirstAllWords(
						get(obj, 'visit_provider', ''),
						separator,
					);
				}
				break;
			case 'clinical-review':
				providerName = '';
			default:
				providerName = '';
		}
		if (providerName && providerName.length > 0) {
			const ProviderNameobj = providerName.split(separator);
			const first_name = get(ProviderNameobj, 0, '');
			const middle_name =
				providerName.length > 2
					? ' ' + get(ProviderNameobj, 1, '')
					: '';
			const last_name =
				providerName.length > 2
					? ' ' + get(ProviderNameobj, 2, '')
					: ' ' + get(ProviderNameobj, 1, '');
			result = first_name.charAt(0) + '.' + middle_name + last_name;
			return (
				<span
					className='prv-name'
					style={{
						color: '#262837',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{getShortText(result, 12)}{' '}
				</span>
			);
		} else {
			return dash;
		}
	};

	const ucFirstAllWords = (str, separator = ' ') => {
		const pieces = (str && str.split(separator)) || [];
		for (let i = 0; i < pieces.length; i++) {
			const j = pieces[i].charAt(0).toUpperCase();
			pieces[i] = j + pieces[i].substr(1).toLowerCase();
		}
		return pieces.join(separator);
	};

	const isSCPEncoutnerWithSubType = (encounter) => {
		return (
			_.get(encounter, 'type', '') == 'scp' &&
			_.get(encounter, 'encounter_subtype', false)
		);
	};

	const handleNewEncounter = (encounterType) => {
		if (encounterType === AddEncounterTypes.MULTI_PATIENT_VISIT)
			showMultiPatientVisitEncounterModal();
		else {
			updateAddEncountedType(encounterType);
			showAddEncounter();
		}
	};

	const getMultipleVisitType = (el) => {
		if (isMultipleVisit(el)) {
			if (isPrimaryVisit(el)) {
				return ' (Primary) ';
			} else if (isSecondaryVisit(el)) {
				return ' (Secondary) ';
			}
		} else if (isManualVisit(el)) {
			return ' (Manual) ';
		} else {
			return '';
		}
	};

	const triggerNewOutreachToPatient = () => {
		setInviteModal(true);
	};

	const closeInviteModal = () => {
		setInviteModal(false);
	};

	const showScheduleAppointment = () => {
		const patient = props.patientdetail[0];
		const patient_id = !_.isNaN(_.toNumber(patient?.id))
			? _.toNumber(patient?.id)
			: 0;
		props.history.push({
			pathname: '/app/appointments/schedule',
			state: {
				breadcrumbs: [
					{
						location: 'Appointments',
						url: '/app/appointments',
					},
					{
						location: 'Schedule Appointment',
						url: '/app/appointments/schedule',
						patientData: {
							firstName: patient?.first_name || '',
							lastName: patient?.last_name || '',
							id: patient_id,
							uuid: patient?.uuid || 0,
						},
					},
				],
			},
		});
	};
	const onSuccess = () => {
		const enctype = 0;
		const params = new URLSearchParams(props.location?.search);
		const order_by = params.get('order_by');
		const order_type = params.get('order_type');
		const offset = params.get('offset');

		const newPath =
			'/app/patientdetail/' +
			props.match?.params?.patientid +
			'/8' +
			'/' +
			enctype;
		const newSearch =
			'?offset=' +
			(offset || 0) +
			'&order_by=' +
			(order_by || '') +
			'&order_type=' +
			(order_type || 'DESC');

		props.history.push({
			pathname: newPath,
			search: newSearch,
			state: props.location?.state,
		});
		dispatch(patientdetail(props.patientdetail[0]?.uuid));
	};

	return (
		<div>
			<div
				style={{
					marginTop: '16px',
					marginBottom: '28px',
					height: '36px',
					paddingLeft: '20px',
				}}
			>
				<div id='new-encounter-dropdown-container'>
					<RBAC
						action={CARE_PATIENTS_PATIENT_ADD_ENCOUNTER}
						yes={
							<React.Fragment>
								<Dropdown
									onVisibleChange={() => setOpen(!open)}
									className={`${open ? 'open' : ''}`}
									trigger='click'
									onCommand={handleNewEncounter}
									menu={
										<Dropdown.Menu>
											<Dropdown.Item
												command={
													AddEncounterTypes.GROUP_SESSION
												}
											>
												Group Session
											</Dropdown.Item>
											<Dropdown.Item
												command={
													AddEncounterTypes.CC_INTAKE
												}
											>
												CC Intake
											</Dropdown.Item>
											<Dropdown.Item
												command={
													AddEncounterTypes.MANUAL_VISIT
												}
											>
												Visit(Manual)
											</Dropdown.Item>
											<Dropdown.Item
												command={
													AddEncounterTypes.MULTI_PATIENT_VISIT
												}
											>
												Multi-patient Visit
											</Dropdown.Item>
										</Dropdown.Menu>
									}
								>
									<Button className='new-encounter-dropdown'>
										<ReactSVG
											className={'addIcon'}
											src={add}
										/>
										<div className='new-encounter-text'>
											New encounter
										</div>
										<ReactSVG
											className='dropdownIcon'
											src={dropdownIcon}
										/>
									</Button>
								</Dropdown>
							</React.Fragment>
						}
					/>
					<RBAC
						action={CARE_APPOINTMENTS_SCHEDULING}
						yes={
							<div
								data-testId='btn-schedule-appointment'
								className='btn-schedule-appointment'
								onClick={() => showScheduleAppointment()}
							>
								<Icon
									className='appointmentAddIcon'
									icon='calendar-user-plus'
								/>
								<span>Schedule appointment</span>
							</div>
						}
						no={<div></div>}
					/>
					{props.patientdetail[0]
						?.allow_next_referral_outreach_round &&
						isFeatureEnabled('INVITE_PT_TO_SCHEDULE', {
							allowCookie: true,
						}) && (
							<RBAC
								action={CARE_APPOINTMENTS_SCHEDULING}
								yes={
									<div
										data-testId='btn-invite-pt-to-schedule'
										className='btn-invite-pt-to-schedule'
										onClick={() =>
											triggerNewOutreachToPatient()
										}
									>
										<span>Invite Patient to Schedule</span>
									</div>
								}
								no={<div></div>}
							/>
						)}
					{inviteModal && (
						<InvitePatientToSchedule
							closeModal={closeInviteModal}
							onSuccess={onSuccess}
							encounterList={encounter_list?.data}
							partnerUUID={props.patientdetail[0]?.partner_uuid}
							associatedProgram={
								props.patientdetail[0]?.partner_id
							}
							patientId={props.patientdetail[0]?.id}
							patientUUID={props.patientdetail[0]?.uuid}
						/>
					)}
				</div>
				<Pagination
					className='patient_records_pagination encounter_list pull-right'
					pageSize={DEFAULT_LIMIT}
					offset={activePage || 0}
					total={get(encounter_list, 'total', 0)}
					layout='prev, pager, next'
					onCurrentChange={(e, offset) => handlePageChange(e, offset)}
				/>
			</div>
			<div
				className={classnames('PatientsRecords', 'encounter-listview')}
			>
				<div className='heading encounter_list'>
					{[
						'Date of service',
						'Type',
						'Provider',
						'Order status',
						'Created at',
					].map((e, i) => {
						return (
							<div
								className={
									'heading_column' +
									{
										0: ' dos',
										1: ' type',
										2: ' provider',
										3: ' request_status',
										4: ' order_status',
										5: ' created_at',
									}[i]
								}
								key={i}
							>
								{' '}
								{e}{' '}
							</div>
						);
					})}
				</div>
				{get(encounter_list, 'data', []).map((e, i) => {
					const isUpcoming =
						get(e, 'visit_status', '') == 'booked' &&
						get(e, 'type', '') != 'lab'
							? true
							: false;
					return (
						e && (
							<div
								className={classnames(
									'div_row encounter_list' +
										(isUpcoming ? ' upcoming' : ''),
								)}
								key={'fragment_' + i}
								onClick={(evt) =>
									RedirectToEncounterDetail(e.uuid, 2, e.type)
								}
							>
								<div
									className='encounter_body_area'
									key={i}
									id={'patient_record_' + i}
								>
									<div className='encounter_list_dos'>
										<div className='prov-date  encounter_list'>
											{get(
												e,
												['date_of_service', 'length'],
												0,
											) > 0 ? (
												<span className='tbl-date encounter_list'>
													{' '}
													{getEncounterSpecificPatientDate(
														get(
															e,
															'date_of_service',
														),
														get(
															e,
															['timezone'],
															'',
														),
														get(e, 'type', ''),
													)}{' '}
												</span>
											) : (
												dash
											)}
										</div>
										{get(
											e,
											['date_of_service', 'length'],
											0,
										) > 0 ? (
											<span className='tbl-time encounter_list'>
												{' '}
												{getEncounterSpecificPatientTime(
													get(e, 'date_of_service'),
													get(e, ['timezone'], ''),
													get(e, 'type', ''),
												)}{' '}
											</span>
										) : (
											dash
										)}
									</div>
									<div
										style={{
											display: 'flex',
											minWidth: '130px',
											marginRight: '20px',
											width: '15%',
										}}
									>
										<div className='provider-typeimg encounter_list'>
											{isSCPEncoutnerWithSubType(e) ? (
												scp_encounter_sub_types_images[
													_.get(
														e,
														'encounter_subtype',
														'',
													)
												] ? (
													<img
														src={
															scp_encounter_sub_types_images[
																_.get(
																	e,
																	'encounter_subtype',
																	'',
																)
															]
														}
														alt=''
													/>
												) : null
											) : isMultipleVisit(e) ? (
												<img
													src={multipleVisitIcon}
													alt=''
												/>
											) : encounter_types_images[
													e.type
											  ] ? (
												<img
													src={
														encounter_types_images[
															e.type
														]
													}
													alt=''
												/>
											) : null}
										</div>
										<div
											className={
												'prov-typemain encounter_list' +
												(e.type == 'visit' || isUpcoming
													? ''
													: ' non-visit')
											}
										>
											<div
												className='prov-type encounter_list'
												style={{
													textOverflow: 'ellipsis',
													whiteSpace: 'nowrap',
													overflow: 'hidden',
												}}
											>
												{isPartnerInitiatedPreTest(e)
													? 'TRO'
													: isPartnerInitiatedUnsolicitedResult(
																e,
														  )
														? getShortText(
																'TRO - Results Only',
																17,
															)
														: getShortText(
																encounter_types[
																	e.type
																] +
																	getMultipleVisitType(
																		e,
																	) +
																	getConsultationSubType(
																		e,
																	),
																22,
															)}
											</div>
											<div
												className={classnames(
													'visit-status encounter_list',
													'status-' +
														getVisitStatusColor(
															isUpcoming
																? 'booked'
																: get(
																		e,
																		'visit_status',
																		'',
																	),
														),
												)}
											>
												{isUpcoming
													? 'Booked'
													: getShortText(
															getRequestStatus(
																get(
																	e,
																	'visit_status',
																	'',
																),
																'visit_status',
																e.type,
															),
															22,
														)}
											</div>
										</div>
									</div>
									<div
										style={{
											paddingTop: '15px',
											marginRight: '20px',
											minWidth: '120px',
											width: '13%',
										}}
									>
										<div
											className='pad-0'
											style={{ display: 'flex' }}
										>
											{getProviderImage(e)}
											{getProviderName(e)}
										</div>
									</div>
									<div
										className={
											'order_status_encounter_list'
										}
									>
										<div className='pad-0'>
											<div
												className='prv-apprv'
												style={{
													minWidth: '140px',
													width: '12%',
												}}
											>
												{get(
													e,
													'order_status.length',
													0,
												) > 0 ||
												get(
													e,
													'test_recommended',
													'',
												) === 'PA' ? (
													<div>
														{get(
															e,
															'order_status',
															'',
														) === 'multiple' ? (
															<OrderStatusPill
																displayName='Multiple'
																value={get(
																	e,
																	'order_status',
																	'',
																)}
															/>
														) : get(
																e,
																'order_status',
																'',
														  ) == 'na' ? (
															'N/A'
														) : get(
																e,
																'test_recommended',
																'',
														  ) === 'PA' ? (
															<OrderStatusPill
																displayName='Pre-Auth'
																value={get(
																	e,
																	'test_recommended',
																	'',
																)}
															/>
														) : (
															null || (
																<OrderStatusPill
																	displayName={getOrderStatusLabel(
																		get(
																			e,
																			'order_status',
																			'',
																		),
																		get(
																			e,
																			'status_reason',
																			'',
																		),
																		get(
																			e,
																			'type',
																			'',
																		),
																	)}
																	value={
																		!_.isEmpty(
																			get(
																				e,
																				'status_reason',
																				'',
																			),
																		)
																			? get(
																					e,
																					'status_reason',
																					'',
																				)
																			: get(
																					e,
																					'order_status',
																					'',
																				)
																	}
																/>
															)
														)}
													</div>
												) : (
													dash
												)}
											</div>
										</div>
									</div>
									<div className='created_at_encounter_list'>
										<div className='prov-date  encounter_list'>
											{get(e, 'created_at.length', 0) >
											0 ? (
												<span className='tbl-date encounter_list'>
													{' '}
													{getEncounterSpecificPatientDate(
														get(
															e,
															'created_at',
															'',
														),
														'',
														'',
													)}{' '}
												</span>
											) : (
												dash
											)}
										</div>
										<div className='prov-time  encounter_list'>
											{get(e, 'created_at.length', 0) >
											0 ? (
												<span className='tbl-time encounter_list'>
													{' '}
													{getEncounterSpecificPatientTime(
														get(
															e,
															'created_at',
															'',
														),
														'',
														'',
														'h:mm:ss A z',
													)}{' '}
												</span>
											) : (
												dash
											)}
										</div>
									</div>
								</div>
							</div>
						)
					);
				})}
			</div>
		</div>
	);
};

const mapStateToProps = (state, props) => ({
	groupsessionschema: state.groupsessionschema.schema,
	ccintakeschema: state.ccintakeschema.schema,
	visitsschema: state.visitsschema.schema,
	labsschema: state.labsschema.schema,
	MLOOrderStatus: _.get(
		state,
		['gentestenums', 'gentestenums', 'order_status'],
		[],
	),
});

const mapDispatchToProps = (dispatch) => ({
	callencountergroupsessionschema: () =>
		dispatch(getencountergroupsessionschema()),
	callccintakeschema: () => dispatch(getencounterccintakeschema()),
	callvisitsschema: () => dispatch(getencountervisitsschema()),
	calllabsschema: () => dispatch(getencounterlabseschema()),
	callresetencounters: () => dispatch(resetencounters()),
	// getencouter:(pid) => dispatch(getencounters(pid)),
	callGeneTestEnums: () => dispatch(getGeneTestEnums()),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EncounterList),
);
