//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../../assets/exit-alert.svg';

const Tag = (props) => {
	const { collection, hasX, icdCode: code = {}, toggleCodeSelection } = props;

	const { consultation_price, partner_id = null } = code;

	const handleRemoveTag = () => {
		toggleCodeSelection(false, partner_id, code);
	};

	const renderText = () => {
		if (Array.isArray(collection)) {
			const { display_name } = collection.find(
				(el) => (el.id || '') === partner_id
			);
			let price = consultation_price || '--';
			if (!price && price !== 0) price = '--';

			return `${display_name || '--'} ($${price})`;
		} else {
			return '-- ($--)';
		}
	};

	return (
		<div className='chargeICDSelectedTag' style={{ whiteSpace: 'pre' }}>
			<div>{renderText()}</div>
			{hasX && (
				<ReactSVG
					src={xIcon}
					className='chargeICDSelectedTagX'
					onClick={handleRemoveTag}
				/>
			)}
		</div>
	);
};

export default Tag;
