import { isNil, omitBy, pickBy } from 'lodash';

const omitNullEmptyAlleleRpeats1Value = (data) => {
	return pickBy(
		{
			comparator: data.comparatorAllele1,
			value: data.numericAllele1,
		},
		(value) => {
			return !(value === '' || value === null || value === undefined);
		}
	);
};

const omitNullEmptyAlleleRpeats2Value = (data) => {
	return pickBy(
		{
			comparator: data.comparatorAllele2,
			value: data.numericAllele2,
		},
		(value) => {
			return !(value === '' || value === null || value === undefined);
		}
	);
};

export const deHydrateNucleotideRepeatVariant = (data) => {
	const _deHydrateNucleotideRepeatVariant = omitBy(
		{
			geneId: data.geneId,
			referenceSequence: data.referenceSequence,
			referenceAssemblyCode: data.referenceAssembly,
			alleleRpeats1: omitNullEmptyAlleleRpeats1Value(data),
			alleleRpeats2: omitNullEmptyAlleleRpeats2Value(data),
			characterizationAllele1: data.preSetAllele1,
			characterizationAllele2: data.preSetAllele2,
			originalReportedClassificationAllele1: data.labClassificationAllele1,
			originalReportedClassificationAllele2: data.labClassificationAllele2,
			dnaChangeType: data.dnaChange,
			genomicSourceClass: data.genomicSourceClass,
			assessment: 'PRESENT',
		},
		isNil
	);
	return _deHydrateNucleotideRepeatVariant;
};
