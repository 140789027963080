import * as actionTypes from './actionTypes';
import DocumentsService from '../service/Documents';
import { hydrateAuditTrail } from '../transformers/documentTransformers';
import _ from 'lodash';
import mime from 'mime';
import axios from 'axios';

export const startLoadDocuments = (payload) => {
	return {
		type: actionTypes.LOAD_PATIENT_DOCUMENTS_START,
		payload,
	};
};

export function successLoadDocuments(docs) {
	return {
		type: actionTypes.LOAD_PATIENT_DOCUMENTS_SUCCESS,
		payload: _.get(docs, 'data', []),
	};
}

export function failureLoadDocuments(error) {
	return { type: actionTypes.LOAD_PATIENT_DOCUMENTS_FAILURE, payload: error };
}

export function UnloadDocument() {
	return { type: actionTypes.UNLOAD_PATIENT_DOCUMENTS };
}

const creatingV2Document = () => {
	return { type: actionTypes.V2_CREATING_DOCUMENTS };
};

const SuccessV2DocumentCreated = (doc) => {
	return {
		type: actionTypes.V2_CREATED_DOCUMENTS,
		payload: _.get(doc, 'data', []),
	};
};

const failureV2DocumentCreating = (error) => {
	return { type: actionTypes.V2_CREATING_DOCUMENTS_FAILURE, payload: error };
};

//For templates
const startLoadDocumentTypesForTemplate = () => {
	return { type: actionTypes.LOAD_DOCUMENT_TYPES_TEMPLATE_START };
};
const successLoadDocumentTypesForTemplate = (docTypes) => {
	const doctypes = docTypes ? docTypes : [];
	return {
		type: actionTypes.LOAD_DOCUMENT_TYPES_TEMPLATE_SUCCESS,
		payload: doctypes,
	};
};
const failureLoadDocumentTypesForTemplate = (error) => {
	return {
		type: actionTypes.LOAD_DOCUMENT_TYPES_TEMPLATE_FAILURE,
		payload: error,
	};
};

//For Patients
const startLoadDocumentTypesForPatient = () => {
	return { type: actionTypes.LOAD_DOCUMENT_TYPES_PATIENT_START };
};
const successLoadDocumentTypesForPatient = (docTypes) => {
	const doctypes = docTypes ? docTypes : [];
	return {
		type: actionTypes.LOAD_DOCUMENT_TYPES_PATIENT_SUCCESS,
		payload: doctypes,
	};
};
const failureLoadDocumentTypesForPatient = (error) => {
	return {
		type: actionTypes.LOAD_DOCUMENT_TYPES_PATIENT_FAILURE,
		payload: error,
	};
};

//For workflow
const startLoadDocumentTypesForWorkflow = () => {
	return { type: actionTypes.LOAD_DOCUMENT_TYPES_WORKFLOW_START };
};
const successLoadDocumentTypesForWorkflow = (docTypes) => {
	const doctypes = docTypes ? docTypes : [];
	return {
		type: actionTypes.LOAD_DOCUMENT_TYPES_WORKFLOW_SUCCESS,
		payload: doctypes,
	};
};
const failureLoadDocumentTypesForWorkflow = (error) => {
	return {
		type: actionTypes.LOAD_DOCUMENT_TYPES_WORKFLOW_FAILURE,
		payload: error,
	};
};

const startLoadDetailDocument = () => {
	return { type: actionTypes.LOAD_DETAIL_DOCUMENT_START };
};

const successLoadDetailDocument = (doc) => {
	return { type: actionTypes.LOAD_DETAIL_DOCUMENT_SUCCESS, payload: doc };
};

const failureLoadDetailDocument = (error) => {
	return { type: actionTypes.LOAD_DETAIL_DOCUMENT_FAILURE, payload: error };
};

export function UnloadDetailDocument() {
	return { type: actionTypes.UNLOAD_DETAIL_DOCUMENT };
}

export function LoadV2Documents(
	patient_uuid,
	type,
	active,
	order_by,
	order_type,
	show_loader = true
) {
	return (dispatch) => {
		dispatch(startLoadDocuments(show_loader));
		return DocumentsService.listV2(
			patient_uuid,
			type,
			active,
			order_by,
			order_type
		)
			.then((res) => {
				return dispatch(successLoadDocuments(res.data));
			})
			.catch((err) => {
				if (!axios.isCancel(err)) dispatch(failureLoadDocuments(err));
			});
	};
}

export function CreateV2Document(
	encounteruuid,
	documenttype,
	patientuuid,
	template,
	description,
	documentSubType
) {
	return (dispatch) => {
		dispatch(creatingV2Document());
		return DocumentsService.createDocument(
			encounteruuid,
			documenttype,
			patientuuid,
			template,
			description,
			documentSubType
		)
			.then((res) => {
				return dispatch(SuccessV2DocumentCreated(res.data));
			})
			.catch((err) => {
				dispatch(failureV2DocumentCreating(err));
			});
	};
}

//Template
export function LoadDocumentTypesForTemplate() {
	return (dispatch) => {
		dispatch(startLoadDocumentTypesForTemplate());
		return DocumentsService.getTypes('template')
			.then((res) => {
				return dispatch(
					successLoadDocumentTypesForTemplate(
						_.get(res, ['data', 'data', 'document_types'], [])
					)
				);
			})
			.catch((err) => {
				dispatch(failureLoadDocumentTypesForTemplate(err));
			});
	};
}
export const clearLoadDocumentTypesForTemplate = () => {
	return { type: actionTypes.LOAD_DOCUMENT_TYPES_TEMPLATE_CLEAR };
};

//Patient
export function LoadDocumentTypesForPatient() {
	return (dispatch) => {
		dispatch(startLoadDocumentTypesForPatient());
		return DocumentsService.getTypes('patient')
			.then((res) => {
				return dispatch(
					successLoadDocumentTypesForPatient(
						_.get(res, ['data', 'data', 'document_types'], [])
					)
				);
			})
			.catch((err) => {
				dispatch(failureLoadDocumentTypesForPatient(err));
			});
	};
}
export const clearLoadDocumentTypesForPatient = () => {
	return { type: actionTypes.LOAD_DOCUMENT_TYPES_PATIENT_CLEAR };
};

//Workflow
export function LoadDocumentTypesForWorkflow() {
	return (dispatch) => {
		dispatch(startLoadDocumentTypesForWorkflow());
		return DocumentsService.getTypes('workflow')
			.then((res) => {
				return dispatch(
					successLoadDocumentTypesForWorkflow(
						_.get(res, ['data', 'data', 'document_types'], [])
					)
				);
			})
			.catch((err) => {
				dispatch(failureLoadDocumentTypesForWorkflow(err));
			});
	};
}
export const clearLoadDocumentTypesForWorflow = () => {
	return { type: actionTypes.LOAD_DOCUMENT_TYPES_WORKFLOW_CLEAR };
};

//document detail
export function LoadDocumentDetail(patient_uuid, document_uuid) {
	return (dispatch) => {
		dispatch(startLoadDetailDocument());
		return DocumentsService.get(patient_uuid, document_uuid)
			.then((res) => {
				return dispatch(
					successLoadDetailDocument(_.get(res, ['data', 'data'], {}))
				);
			})
			.catch((err) => {
				dispatch(failureLoadDetailDocument(err));
			});
	};
}

//inline document detail
export function loadInlineDocumentDetail(documentObj, action, patientUuid) {
	return (dispatch) => {
		dispatch(startInlineDocumentDetail());
		return DocumentsService.inlineDocumentDetail(
			documentObj,
			action,
			patientUuid
		)
			.then((res) => {
				const contentType = mime.getType(
					_.get(documentObj, 'file_extension_id', '')
				);
				let blob = res.data;
				blob = blob.slice(0, blob.size, contentType || blob.type);
				return dispatch(successInlineDocumentDetail(blob));
			})
			.catch((err) => {
				dispatch(failureInlineDocumentDetail({ message: err.message }));
			});
	};
}

const startInlineDocumentDetail = () => {
	return {
		type: actionTypes.INLINE_DOCUMENT_DETAIL_START,
		payload: true,
	};
};

const successInlineDocumentDetail = (data) => {
	return {
		type: actionTypes.INLINE_DOCUMENT_DETAIL_SUCCESS,
		payload: data,
	};
};

const failureInlineDocumentDetail = (err) => {
	return {
		type: actionTypes.INLINE_DOCUMENT_DETAIL_FAILURE,
		payload: err,
	};
};

//document action (print, download)
export function documentAction(documentObj, action, patientUuid) {
	return (dispatch) => {
		dispatch(startDocumentAction(action));
		DocumentsService.documentAction(documentObj, action, patientUuid)
			.then(() => {
				return dispatch(successDocumentAction());
			})
			.catch((err) => {
				dispatch(failureDocumentAction({ message: err.message }));
			});
	};
}

const startDocumentAction = (action) => {
	return {
		type: actionTypes.DOCUMENT_ACTION_START,
		payload: action,
	};
};

const successDocumentAction = () => {
	return {
		type: actionTypes.DOCUMENT_ACTION_SUCCESS,
		payload: true,
	};
};

const failureDocumentAction = (err) => {
	return {
		type: actionTypes.DOCUMENT_ACTION_FAILURE,
		payload: err,
	};
};

const gettingTemplates = () => {
	return {
		type: actionTypes.V2_LOAD_TEMPLATES_START,
		payload: true,
	};
};

const gotTemplates = (payload) => {
	return {
		type: actionTypes.V2_LOAD_TEMPLATES_SUCCESS,
		payload,
	};
};

const failureGetTemplates = (err) => {
	return {
		type: actionTypes.V2_LOAD_TEMPLATES_FAILURE,
		payload: err,
	};
};

//getting templates (flexible route)
export function getTemplatesFlexible(
	partner_uuid,
	docType,
	q,
	documentState,
	category,
	order_by,
	order_type,
	offset,
	limit
) {
	return (dispatch) => {
		dispatch(gettingTemplates());
		return DocumentsService.getTemplatesFlexible(
			partner_uuid,
			docType,
			q,
			documentState,
			category,
			order_by,
			order_type,
			offset,
			limit
		)
			.then((res) => {
				dispatch(gotTemplates(_.get(res, 'data', {})));
			})
			.catch((err) => {
				dispatch(failureGetTemplates(err));
			});
	};
}

//get workflow affiliation docs
export function getWorkflowDocs(patient_uuid) {
	return (dispatch) => {
		dispatch(gettingWorkflowDocs());
		return DocumentsService.getWorkflowDocs(patient_uuid)
			.then((res) => {
				dispatch(gotWorkflowDocs(_.get(res, 'data.data', [])));
			})
			.catch((err) => {
				dispatch(failureGetWorkflowDocs(err));
			});
	};
}

const gettingWorkflowDocs = () => {
	return {
		type: actionTypes.V2_LOAD_WORKFLOW_DOCS_START,
		payload: true,
	};
};

const gotWorkflowDocs = (data) => {
	return {
		type: actionTypes.V2_LOAD_WORKFLOW_DOCS_SUCCESS,
		payload: data,
	};
};

const failureGetWorkflowDocs = (err) => {
	return {
		type: actionTypes.V2_LOAD_WORKFLOW_DOCS_FAILURE,
		payload: err,
	};
};

//upload file
export function uploadFile(data) {
	return (dispatch) => {
		dispatch(uploadingFile());
		return DocumentsService.uploadFile(data)
			.then((res) => {
				dispatch(uploadedFile(_.get(res, 'data.data', {})));
			})
			.catch((err) => {
				dispatch(
					failureUploadFile(_.get(err, 'response.data.data', {}))
				);
			});
	};
}

const uploadingFile = () => {
	return {
		type: actionTypes.V2_UPLOAD_FILE_START,
		payload: true,
	};
};

const uploadedFile = (data) => {
	return {
		type: actionTypes.V2_UPLOAD_FILE_SUCCESS,
		payload: data,
	};
};

const failureUploadFile = (err) => {
	return {
		type: actionTypes.V2_UPLOAD_FILE_FAILURE,
		payload: err,
	};
};

//Archive Document
export function archiveV2Document(patient_uuid, document_uuid) {
	return (dispatch) => {
		dispatch(startArchivingDetailDocument());
		return DocumentsService.archiveDocument(patient_uuid, document_uuid)
			.then((res) => {
				dispatch(
					successArchivingDetailDocument(_.get(res, 'data.data', {}))
				);
			})
			.catch((err) => {
				dispatch(failureArchivingDetailDocument(err));
			});
	};
}

const startArchivingDetailDocument = () => {
	return { type: actionTypes.V2_ARCHIVE_DOCUMENT_START };
};

const successArchivingDetailDocument = (doc) => {
	return { type: actionTypes.V2_ARCHIVE_DOCUMENT_SUCCESS, payload: doc };
};

const failureArchivingDetailDocument = (error) => {
	return { type: actionTypes.V2_ARCHIVE_DOCUMENT_FAILURE, payload: error };
};

//finalizing Document
const startFinalizingDocument = () => {
	return { type: actionTypes.V2_FINALIZING_DOCUMENT_START };
};

const successFinalizingDocument = (doc) => {
	return { type: actionTypes.V2_FINALIZED_DOCUMENT_SUCCESS, payload: doc };
};

const failureFinalizingDocument = (error) => {
	return { type: actionTypes.V2_FINALIZING_DOCUMENT_FAILURE, payload: error };
};

export function finalizeV2Document(patient_uuid, document_uuid, data) {
	return (dispatch) => {
		dispatch(startFinalizingDocument());
		return DocumentsService.finalizeDocument(
			patient_uuid,
			document_uuid,
			data
		)
			.then((res) => {
				dispatch(
					successFinalizingDocument(_.get(res, 'data.data', {}))
				);
			})
			.catch((err) => {
				dispatch(failureFinalizingDocument(err));
			});
	};
}

const startUpdateDocType = () => {
	return { type: actionTypes.UPDATE_DOCUMENT_TYPE_START };
};

const successUpdateDocType = (doc) => {
	return { type: actionTypes.UPDATE_DOCUMENT_TYPE_SUCCESS, payload: doc };
};

const failureUpdateDocType = (error) => {
	return { type: actionTypes.UPDATE_DOCUMENT_TYPE_FAILURE, payload: error };
};

//change unassigned document type to correct doc type in document list documents tab
export function updateDocTypeAction(patient_uuid, document_uuid, data) {
	return (dispatch) => {
		dispatch(startUpdateDocType());
		return DocumentsService.updateDocumentType(
			patient_uuid,
			document_uuid,
			data
		)
			.then((res) => {
				return dispatch(
					successUpdateDocType(_.get(res, ['data', 'data'], {}))
				);
			})
			.catch((err) => {
				dispatch(failureUpdateDocType(err));
			});
	};
}

//Create new template or clone from existing template
export function createTemplate(
	document_type,
	file_name,
	partner_uuids,
	clone_from_template_uuid
) {
	return (dispatch) => {
		dispatch(creatingTemplate());
		return DocumentsService.createTemplate(
			document_type,
			file_name,
			partner_uuids,
			clone_from_template_uuid
		)
			.then((res) => {
				return dispatch(createdTemplate(res));
			})
			.catch((err) => {
				dispatch(createdTemplateFailure(err));
			});
	};
}

const creatingTemplate = () => {
	return {
		type: actionTypes.CREATING_TEMPLATE,
		payload: true,
	};
};

const createdTemplate = (data) => {
	return {
		type: actionTypes.CREATED_TEMPLATE,
		payload: data,
	};
};

const createdTemplateFailure = (err) => {
	return {
		type: actionTypes.CREATED_TEMPLATE_ERROR,
		payload: err,
	};
};

//Edit an existing template
export function editTemplate(
	document_uuid,
	document_type,
	file_name,
	partner_uuids,
	template_state
) {
	return (dispatch) => {
		dispatch(editingTemplate());
		return DocumentsService.editTemplate(
			document_uuid,
			document_type,
			file_name,
			partner_uuids,
			template_state
		)
			.then((res) => {
				return dispatch(editedTemplate(res));
			})
			.catch((err) => {
				dispatch(editedTemplateError(err));
			});
	};
}

const editingTemplate = () => {
	return {
		type: actionTypes.EDITING_TEMPLATE,
		payload: true,
	};
};

const editedTemplate = (data) => {
	return {
		type: actionTypes.EDITED_TEMPLATE,
		payload: data,
	};
};

const editedTemplateError = (err) => {
	return {
		type: actionTypes.EDITED_TEMPLATE_ERROR,
		payload: err,
	};
};

//Archive template (soft delete)
export function archiveTemplate(document_uuid) {
	return (dispatch) => {
		dispatch(archivingTemplate());
		return DocumentsService.archiveTemplate(document_uuid)
			.then((res) => {
				return dispatch(archivedTemplate(res));
			})
			.catch((err) => {
				dispatch(archivedTemplateError(err));
			});
	};
}

const archivingTemplate = () => {
	return {
		type: actionTypes.ARCHIVING_TEMPLATE,
		payload: true,
	};
};

const archivedTemplate = (data) => {
	return {
		type: actionTypes.ARCHIVED_TEMPLATE,
		payload: data,
	};
};

const archivedTemplateError = (err) => {
	return {
		type: actionTypes.ARCHIVED_TEMPLATE_ERROR,
		payload: err,
	};
};

//Update associated partners/referral programs of a given template (batch operation)
export function batchUpdateTemplatePartners(document_uuids, partner_uuids) {
	return (dispatch) => {
		dispatch(batchUpdatingTemplatePartners());
		return DocumentsService.batchUpdateTemplatePartners(
			document_uuids,
			partner_uuids
		)
			.then((res) => {
				return dispatch(batchUpdatedTemplatePartners(res));
			})
			.catch((err) => {
				dispatch(batchUpdatedTemplatePartnersError(err));
			});
	};
}

const batchUpdatingTemplatePartners = () => {
	return {
		type: actionTypes.BATCH_UPDATING_TEMPLATE_PARTNERS,
		payload: true,
	};
};

const batchUpdatedTemplatePartners = (data) => {
	return {
		type: actionTypes.BATCH_UPDATED_TEMPLATE_PARTNERS,
		payload: data,
	};
};

const batchUpdatedTemplatePartnersError = (err) => {
	return {
		type: actionTypes.BATCH_UPDATED_TEMPLATE_PARTNERS_ERROR,
		payload: err,
	};
};

//Batch update template type
export function batchUpdateTemplateType(
	document_uuids,
	partner_uuids = null,
	document_type
) {
	return (dispatch) => {
		dispatch(batchUpdatingTemplateType());
		return DocumentsService.batchUpdateTemplatePartners(
			document_uuids,
			partner_uuids,
			document_type
		)
			.then((res) => {
				return dispatch(batchUpdatedTemplateType(res));
			})
			.catch((err) => {
				dispatch(batchUpdatedTemplateTypeError(err));
			});
	};
}

const batchUpdatingTemplateType = () => {
	return {
		type: actionTypes.BATCH_UPDATING_TEMPLATE_TYPE,
		payload: true,
	};
};

const batchUpdatedTemplateType = (data) => {
	return {
		type: actionTypes.BATCH_UPDATED_TEMPLATE_TYPE,
		payload: data,
	};
};

const batchUpdatedTemplateTypeError = (err) => {
	return {
		type: actionTypes.BATCH_UPDATED_TEMPLATE_TYPE_ERROR,
		payload: err,
	};
};

//  share document
const startSharingDocument = () => {
	return { type: actionTypes.SHARE_DOCUMENT_START };
};

const successSharingDocument = (doc) => {
	return { type: actionTypes.SHARE_DOCUMENT_SUCCESS, payload: doc };
};

const failuresharingDocument = (error) => {
	return { type: actionTypes.SHARE_DOCUMENT_FAILURE, payload: error };
};

export function shareDocument(patient_uuid, document_uuid, data) {
	return (dispatch) => {
		dispatch(startSharingDocument());
		return DocumentsService.shareDocument(patient_uuid, document_uuid, data)
			.then((res) => {
				dispatch(successSharingDocument(_.get(res, 'data.data', {})));
			})
			.catch((err) => {
				dispatch(failuresharingDocument(err));
			});
	};
}

//Update Document's Related Encounter
export function updateDocumentRelatedEncounter(
	patientUuid,
	documentUuid,
	documentType,
	newEncounterUuid
) {
	return (dispatch) => {
		dispatch(updatingDocumentRelatedEncounter());
		return DocumentsService.updateDocumentRelatedEncounter(
			patientUuid,
			documentUuid,
			documentType,
			newEncounterUuid
		)
			.then((res) => {
				return dispatch(updateDocumentRelatedEncounterSuccess(res));
			})
			.catch((err) => {
				dispatch(updateDocumentRelatedEncounterError(err));
			});
	};
}

const updatingDocumentRelatedEncounter = () => {
	return {
		type: actionTypes.UPDATING_DOCUMENT_RELATED_ENCOUNTER,
		payload: true,
	};
};

const updateDocumentRelatedEncounterSuccess = (data) => {
	return {
		type: actionTypes.UPDATE_DOCUMENT_ENCOUNTER_SUCCESS,
		payload: data,
	};
};

const updateDocumentRelatedEncounterError = (err) => {
	return {
		type: actionTypes.UPDATE_DOCUMENT_ENCOUNTER_FAILURE,
		payload: err,
	};
};

const startLoadDocumentMediums = () => {
	return { type: actionTypes.LOAD_DOCUMENT_MEDIUMS_START };
};

const successDocumentMediums = (docTypes) => {
	const doctypes = docTypes ? docTypes : [];
	return {
		type: actionTypes.LOAD_DOCUMENT_MEDIUMS_SUCCESS,
		payload: doctypes,
	};
};

const failureDocumentMediums = (error) => {
	return { type: actionTypes.LOAD_DOCUMENT_MEDIUMS_FAILURE, payload: error };
};

export function LoadDocumentMediums(param, recipient_type = null) {
	return (dispatch) => {
		dispatch(startLoadDocumentMediums());
		return DocumentsService.getShareMediums(param, recipient_type)
			.then((res) => {
				return dispatch(
					successDocumentMediums(
						_.get(res, ['data', 'data', 'mediums'], [])
					)
				);
			})
			.catch((err) => {
				dispatch(failureDocumentMediums(err));
			});
	};
}

const startLoadDocumentRecipients = () => {
	return { type: actionTypes.LOAD_DOCUMENT_RECIPIENTS_START };
};

const successDocumentRecipients = (docTypes) => {
	const doctypes = docTypes ? docTypes : [];
	return {
		type: actionTypes.LOAD_DOCUMENT_RECIPIENTS_SUCCESS,
		payload: doctypes,
	};
};

const failureDocumentRecipients = (error) => {
	return {
		type: actionTypes.LOAD_DOCUMENT_RECIPIENTS_FAILURE,
		payload: error,
	};
};

export function LoadDocumentRecipients(param) {
	return (dispatch) => {
		dispatch(startLoadDocumentRecipients());
		return DocumentsService.getShareRecipients(param)
			.then((res) => {
				return dispatch(
					successDocumentRecipients(
						_.get(res, ['data', 'data', 'recipients'], [])
					)
				);
			})
			.catch((err) => {
				dispatch(failureDocumentRecipients(err));
			});
	};
}

//Log Release document
const startLogReleaseDocument = () => {
	return { type: actionTypes.LOG_RELEASE_DOCUMENT_START };
};

const successLogReleaseDocument = (doc) => {
	return { type: actionTypes.LOG_RELEASE_DOCUMENT_SUCCESS, payload: doc };
};

const failureLogReleaseDocument = (error) => {
	return { type: actionTypes.LOG_RELEASE_DOCUMENT_FAILURE, payload: error };
};

export function logReleaseDocument(patient_uuid, document_uuid, data) {
	return (dispatch) => {
		dispatch(startLogReleaseDocument());
		return DocumentsService.shareDocument(patient_uuid, document_uuid, data)
			.then((res) => {
				dispatch(
					successLogReleaseDocument(_.get(res, 'data.data', {}))
				);
			})
			.catch((err) => {
				dispatch(failureLogReleaseDocument(err));
			});
	};
}

// get audit trail
const getDocumentAuditTrailStart = () => {
	return {
		type: actionTypes.GET_DOCUMENT_AUDIT_TRAIL_START,
	};
};

const getDocumentAuditTrailSuccess = (payload) => {
	return {
		type: actionTypes.GET_DOCUMENT_AUDIT_TRAIL_SUCCESS,
		payload,
	};
};

const getDocumentAuditTrailFailure = (payload) => {
	return {
		type: actionTypes.GET_DOCUMENT_AUDIT_TRAIL_FAILURE,
		payload,
	};
};

export function getDocumentAuditTrail(patient_uuid, document_uuid) {
	return (dispatch) => {
		dispatch(getDocumentAuditTrailStart());
		return DocumentsService.getAuditTrail(patient_uuid, document_uuid)
			.then((res) => {
				dispatch(
					getDocumentAuditTrailSuccess(
						hydrateAuditTrail(_.get(res, 'data.data', {}))
					)
				);
			})
			.catch((err) => {
				dispatch(getDocumentAuditTrailFailure(err));
			});
	};
}

export function retractDocument(patient_uuid, document_uuid) {
	return (dispatch) => {
		dispatch(getDocumentAuditTrailStart());
		return DocumentsService.retractDocument(patient_uuid, document_uuid)
			.then(() => {
				dispatch(getDocumentAuditTrail(patient_uuid, document_uuid));
			})
			.catch(() => {
				// let it go, if it fails, audit trail will display "failed"
			});
	};
}

//Release document
const startReleaseDocument = () => {
	return { type: actionTypes.RELEASE_DOCUMENT_START };
};

const successReleaseDocument = (doc) => {
	return { type: actionTypes.RELEASE_DOCUMENT_SUCCESS, payload: doc };
};

const failureReleaseDocument = (error) => {
	return { type: actionTypes.RELEASE_DOCUMENT_FAILURE, payload: error };
};

export function releaseDocument(patient_uuid, document_uuid, data) {
	return (dispatch) => {
		dispatch(startReleaseDocument());
		return DocumentsService.shareDocument(patient_uuid, document_uuid, data)
			.then((res) => {
				dispatch(successReleaseDocument(_.get(res, 'data.data', {})));
			})
			.catch((err) => {
				dispatch(failureReleaseDocument(err));
			});
	};
}

//merge documents or pdfs
export function combineDocuments(patient_uuid, data) {
	return (dispatch) => {
		dispatch(combineDocumentStart());
		return DocumentsService.combineDocument(patient_uuid, data)
			.then((res) => {
				dispatch(combineDocumentSuccess(_.get(res, 'data.data', {})));
			})
			.catch((err) => {
				dispatch(
					combineDocumentFailure(_.get(err, 'response.data.data', {}))
				);
			});
	};
}

const combineDocumentStart = () => {
	return {
		type: actionTypes.COMBINE_DOCUMENT_START,
		payload: true,
	};
};

const combineDocumentSuccess = (data) => {
	return {
		type: actionTypes.COMBINE_DOCUMENT_SUCCESS,
		payload: data,
	};
};

const combineDocumentFailure = (err) => {
	return {
		type: actionTypes.COMBINE_DOCUMENT_FAILURE,
		payload: err,
	};
};
