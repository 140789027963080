//React & Redux
import React from 'react';

//UI Libraries
import { Table, Tbody, Tr, Thead, Th, Td } from '../../Common/Table';
import trashIcon from '../../../assets/delete.svg';
import ReactSVG from 'react-svg';

//Styles
import '../css/document-notifications-table.css';

const DocumentNotificationsTable = (props) => {
	const {
		documentNotificationRecipients = [],
		handleRemoveDefaultDocumentNotificationRecipient,
		tableLoading,
	} = props;

	const tableRows = documentNotificationRecipients.map((recipient) => {
		return (
			<Tr key={recipient.uuid}>
				<Td minWidth='198px' maxWidth='224px'>
					{recipient.first_name + ' ' + recipient.last_name}
				</Td>
				<Td minWidth='198px' maxWidth='524px'>
					{recipient.email}
				</Td>
				<Td minWidth='unset' maxWidth='unset'>
					<ReactSVG
						src={trashIcon}
						className='patientActionIcon trash-icon-style'
						onClick={() =>
							handleRemoveDefaultDocumentNotificationRecipient(
								recipient.uuid
							)
						}
					/>
				</Td>
			</Tr>
		);
	});

	return (
		<div>
			{tableRows.length === 0 ? (
				<p className='no-recipients-text'>No recipients added</p>
			) : (
				<Table style={{ marginTop: '16px' }} loading={tableLoading}>
					<Thead>
						<Tr>
							<Th>Name</Th>
							<Th>Email</Th>
							<Th>Actions</Th>
						</Tr>
					</Thead>
					<Tbody>{tableRows}</Tbody>
				</Table>
			)}
		</div>
	);
};

export default DocumentNotificationsTable;
