import * as actionTypes from './actionTypes';
import _ from 'lodash';
import SchedulingService from '../service/Scheduling';
import PatientService from '../service/Patients';
import EncounterService from '../service/Encounter';
import ReferralProgramsService from '../service/ReferralPrograms';
import ProvidersService from '../service/Providers';
import {
	patientdetail,
	resetPatientInfo,
	getInsuranceCompanies,
	updatingAddress,
	updatedAddress,
	updateAddressError,
} from './patients';
import {
	getencounters,
	resetencounters,
	encounterDetail,
	clearencounterdetail,
	billingobject,
} from './encounter';
import {
	getReferralProgramDetail,
	resetReferralProgramDetail,
} from '../actions/referralprograms/referralprograms';
import moment from 'moment';

const startLoadAppointmentSlots = () => {
	return { type: actionTypes.LOAD_APPOINTMENT_SLOTS_START };
};

function successLoadAppointmentSlots(slots, data) {
	return {
		type: actionTypes.LOAD_APPOINTMENT_SLOTS_SUCCESS,
		payload: slots,
		data,
	};
}

function failureLoadAppointmentSlots(error) {
	return { type: actionTypes.LOAD_APPOINTMENT_SLOTS_FAILURE, payload: error };
}

export function LoadAppointmentSlots(data) {
	return (dispatch) => {
		const {
			country = 'US',
			state,
			timezone,
			vsee_specialty,
			consultation_id,
			provider_id,
			calendar_date,
		} = data;

		dispatch(
			SETAPPOINTMENTPARAMS({
				country,
				state,
				timezone,
				vsee_specialty,
				consultation_id,
				provider_id,
				calendar_date,
			})
		);
		dispatch(startLoadAppointmentSlots());
		return SchedulingService.getSlots(data)
			.then((res) => {
				return dispatch(successLoadAppointmentSlots(res, data));
			})
			.catch((err) => {
				dispatch(
					failureLoadAppointmentSlots(
						_.get(err, ['response', 'data', 'data'], err)
					)
				);
			});
	};
}

export function UpdatePatientDetail(data) {
	return (dispatch) => {
		const promiseall = [];
		if (data && data.outreach && !_.isEmpty(data.outreach)) {
			const promise1 = PatientService.updateOutreach(data.outreach)
				.then((data) => {
					return { type: 'outreach', data: data };
				})
				.catch((error) => {
					throw { type: 'outreach', error: error };
				});
			promiseall.push(promise1);
		}
		if (data && data.address && !_.isEmpty(data.address)) {
			dispatch(updatingAddress());
			const promise2 = PatientService.updateAddress(data.address)
				.then((data) => {
					dispatch(updatedAddress(_.get(data, 'data', null) || {}));
					return { type: 'address', data: data };
				})
				.catch((error) => {
					dispatch(
						updateAddressError(
							_.get(error, 'response.data.fields', null) || {}
						)
					);
					throw { type: 'address', error: error };
				});
			promiseall.push(promise2);
		}
		return Promise.allSettled(promiseall);
	};
}
const StartLoadPatientConsultations = () => {
	return {
		type: actionTypes.LOAD_PATIENT_CONSULTATIONS_START,
	};
};

const LoadPatientConsultationsSuccess = (data) => {
	return {
		type: actionTypes.LOAD_PATIENT_CONSULTATIONS_SUCCESS,
		payload: data,
	};
};

const LoadPatientConsultationsFailure = (err) => {
	return {
		type: actionTypes.LOAD_PATIENT_CONSULTATIONS_FAILURE,
		payload: err,
	};
};

export const LoadPatientConsultations = (user_id, partner_id) => {
	return (dispatch) => {
		dispatch(StartLoadPatientConsultations());
		return PatientService.getConsultations(user_id, partner_id)
			.then((res) => {
				dispatch(
					LoadPatientConsultationsSuccess(_.get(res, 'data.data', []))
				);
			})
			.catch((err) => {
				dispatch(LoadPatientConsultationsFailure(err));
			});
	};
};

const StartLoadAppointmentProvider = () => {
	return {
		type: actionTypes.LOAD_APPOINTMENT_PROVIDER_START,
	};
};

const LoadAppointmentProviderSuccess = (data) => {
	return {
		type: actionTypes.LOAD_APPOINTMENT_PROVIDER_SUCCESS,
		payload: data,
	};
};

const LoadAppointmentProviderFailure = (err) => {
	return {
		type: actionTypes.LOAD_APPOINTMENT_PROVIDER_FAILURE,
		payload: err,
	};
};

export const LoadAppointmentProvider = (user_id, partner_id) => {
	return (dispatch) => {
		dispatch(StartLoadAppointmentProvider());
		return ProvidersService.listProviders({
			user_id,
			order_by: 'first_name',
			partner_id,
		})
			.then((res) => {
				dispatch(
					LoadAppointmentProviderSuccess(_.get(res, 'data.data', []))
				);
			})
			.catch((err) => {
				dispatch(LoadAppointmentProviderFailure(err));
			});
	};
};

export const SETAPPOINTMENTPARAMS = (data) => {
	return {
		type: actionTypes.SET_APPOINTMENT_PARAMS,
		payload: data,
	};
};

export const TOGGLEAPPOINTMENTPATIENT = (data) => {
	return {
		type: actionTypes.TOGGLE_APPOINTMENT_PATIENT,
		payload: data,
	};
};

export const SetAppointmentPatient = (data) => {
	return (dispatch) => {
		dispatch(ClearAppointmentPartnerDetail());
		dispatch(ClearAppointmentProvider());
		dispatch(TOGGLEAPPOINTMENTPATIENT(data));
		dispatch(resetencounters());
		dispatch(patientdetail(_.get(data, 'uuid', '')));
		dispatch(getencounters({ id: _.get(data, 'uuid', ''), offset: 0 }));
		dispatch(LoadPatientConsultations(_.get(data, 'id', '')));
		dispatch(
			getInsuranceCompanies({ patient_uuid: _.get(data, 'uuid', '') })
		);
	};
};

const StartLoadAppointmentPartnerDetail = () => {
	return {
		type: actionTypes.LOAD_APPOINTMENT_PARTNER_DETAIL_START,
	};
};

const LoadAppointmentPartnerDetailSuccess = (data) => {
	return {
		type: actionTypes.LOAD_APPOINTMENT_PARTNER_DETAIL_SUCCESS,
		payload: data,
	};
};

const LoadAppointmentPartnerDetailFailure = (err) => {
	return {
		type: actionTypes.LOAD_APPOINTMENT_PARTNER_DETAIL_FAILURE,
		payload: err,
	};
};

export const LoadAppointmentPartnerDetailbyId = (partner_id) => {
	return (dispatch) => {
		dispatch(StartLoadAppointmentPartnerDetail());
		return ReferralProgramsService.getReferralProgramDetailById(partner_id)
			.then((res) => {
				dispatch(
					LoadAppointmentPartnerDetailSuccess(
						_.get(res, ['data', 'data', '0'], null)
					)
				);
			})
			.catch((err) => {
				dispatch(LoadAppointmentPartnerDetailFailure(err));
			});
	};
};

const resetCouponCodeDetails = () => {
	return {
		type: actionTypes.RESET_APPLY_COUPONCODE,
	};
};

export const ResetCouponCodeDetails = () => {
	return (dispatch) => {
		dispatch(resetCouponCodeDetails());
	};
};

const startApplyingCouponCode = () => {
	return {
		type: actionTypes.LOADING_APPLY_COUPONCODE,
	};
};

const successApplyingCouponCode = (data) => {
	return {
		type: actionTypes.LOADED_APPLY_COUPONCODE,
		payload: data,
	};
};

const faliureApplyingCouponCode = (err) => {
	return {
		type: actionTypes.ERROR_APPLY_COUPONCODE,
		payload: err,
	};
};

export const ApplyCouponCode = (patient_uuid, data) => {
	return (dispatch) => {
		dispatch(startApplyingCouponCode());
		return SchedulingService.applyCouponCode(patient_uuid, data)
			.then((res) => {
				const ressend = _.get(res, ['data', 'data', '0'], []);
				ressend['code'] = _.get(data, ['code'], '');
				dispatch(successApplyingCouponCode(ressend));
			})
			.catch((err) => {
				dispatch(
					faliureApplyingCouponCode(
						_.get(err, ['response', 'data'], [])
					)
				);
			});
	};
};
export const ClearSelectedSlot = () => {
	return (dispatch) => {
		dispatch(SETAPPOINTMENTPARAMS({ timeslot: {} }));
	};
};

export const SetModality = (modality) => {
	return (dispatch) => {
		dispatch(SETAPPOINTMENTPARAMS({ modality }));
	};
};

const RESETNEWAPPOINTMENT = () => {
	return {
		type: actionTypes.RESET_NEW_APPOINTMENT,
	};
};

const ClearAppointmentPartnerDetail = () => {
	return {
		type: actionTypes.CLEAR_APPOINTMENT_PARTNER_DETAIL_START,
	};
};

const ClearAppointmentProvider = () => {
	return {
		type: actionTypes.CLEAR_APPOINTMENT_PROVIDER,
	};
};

export const ResetNewAppointment = () => {
	return (dispatch) => {
		dispatch(RESETNEWAPPOINTMENT());
		dispatch(resetPatientInfo());
		dispatch(resetencounters());
		dispatch(ClearAppointmentPartnerDetail());
		dispatch(ClearAppointmentProvider());
		dispatch(ResetCouponCodeDetails());
		dispatch(resetReferralProgramDetail());
	};
};

export const RescheduleInitialData = (encounterUUID) => {
	return (dispatch) => {
		dispatch(clearencounterdetail());
		EncounterService.encounterdetail(encounterUUID).then((res) => {
			EncounterService.getBillingEncounterObject(encounterUUID).then(
				(resp) => {
					const encounterresp = _.get(res, ['data', 'data', '0'], {});
					dispatch(encounterDetail(encounterresp));
					dispatch(
						billingobject(_.get(resp, ['data', 'data', '0'], {}))
					);
					dispatch(
						patientdetail(_.get(encounterresp, 'user_uuid', 0))
					);
					dispatch(
						LoadAppointmentProvider(
							_.get(encounterresp, 'user_id', 0)
						)
					);
					dispatch(
						getReferralProgramDetail({
							partner_uuid: _.get(encounterresp, 'partner_uuid'),
						})
					);
				}
			);
		});
	};
};

export const RescheduleBookAppointment = (encounterUUID, data) => {
	const by_phone =
		_.get(data, ['by_phone'], 'video') == 'video' ? false : true;
	const start = moment
		.tz(
			moment(data.start_time).format('YYYY-MM-DDTHH:mm:ss'),
			data.timezone
		)
		.format();
	const country = _.get(data, 'country', 'US');
	const reqData = _.omitBy(
		{
			by_phone: by_phone,
			country: country,
			provider_id: data.provider_id,
			reschedule_reason: data.reschedule_reason,
			start_time: start,
			state: data.state,
			timezone: data.timezone,
			vsee_specialty: data.vsee_specialty,
		},
		(f) => _.isNil(f)
	);
	return SchedulingService.rescheduleAppointment(encounterUUID, reqData);
};

export const ResetRescheduleAppointmentData = () => {
	return (dispatch) => {
		dispatch(clearencounterdetail());
		dispatch(resetPatientInfo());
		dispatch(ClearAppointmentProvider());
	};
};
export const FilterChangeEvent = () => {
	return (dispatch) => {
		dispatch(resetCouponCodeDetails());
		dispatch(
			SETAPPOINTMENTPARAMS({
				useInsurance: undefined,
				insurancePrice: null,
				insurance_company_id: 0,
				isCardChange: false,
			})
		);
	};
};
