import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getVisitConsulationTypesEnums } from '../../../../actions/encounter';

import MultiSelectCheckbox from '../../../Filter/MultiSelectCheckbox';

export function ConsultType({ onChange, value }) {
	const consultationTypes = useSelector(
		(store) =>
			store.visitconsultationtypes.VisitConsultationTypes?.data ?? []
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getVisitConsulationTypesEnums(100, 1, false));
	}, []);

	return (
		<MultiSelectCheckbox
			data={consultationTypes.map((item) => ({
				display_name: item.name,
				key: item.name,
			}))}
			selected={value}
			title='Consult Type'
			submit={onChange}
			majorPillTitle='Consult Type'
			// defaultValues={_.get(defaultFilter, ['visit_status'], [])}
			searchBar={false}
			previewTab={false}
			emptyInputFullOutputOverride={false}
		/>
	);
}
