import React, { useReducer, useEffect, useRef } from 'react';
import { Dialog } from 'gm-element-react';
import Icon from '../../Common/Icon';
import './push-to-pioneer-failed-dialog.css';
import { connect } from 'react-redux';
import { Loading } from 'gm-element-react';
import { migrateToPioneerRCO } from '../../../actions/encounter';

const initialState = {
	buttonVisible: true,
	loading: false,
	error: '',
	success: false,
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_BUTTON_VISIBLE':
			return { ...state, buttonVisible: action.payload };
		case 'SET_LOADING':
			return { ...state, loading: action.payload };
		case 'ERROR':
			return {
				...state,
				error: action.payload,
				buttonVisible: false,
			};
		case 'SUCCESS':
			return {
				...state,
				success: true,
			};
		case 'CLOSE_ERROR_DIALOG':
			return { ...state };
		default:
			return state;
	}
};

function PushToPioneerFailedDialog(props) {
	const {
		errorCloseHandler,
		showErrorDialog,
		encounter,
		pioneerRcoErrorMessage,
		migrateToPioneerRCO,
		onSuccessfulRetry,
	} = props;

	const [state, dispatch] = useReducer(reducer, initialState);
	const encounterUuid = encounter.uuid;
	const isMounted = useRef(true);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleSuccess = () => {
		if (isMounted.current) {
			dispatch({ type: 'SUCCESS' });
		}
	};

	const handleError = (error) => {
		if (isMounted.current) {
			dispatch({ type: 'ERROR', payload: error });
		}
	};

	const handlePushToPioneer = () => {
		if (isMounted.current) {
			dispatch({ type: 'SET_LOADING', payload: true });
		}

		migrateToPioneerRCO({ uuid: encounterUuid })
			.then(({ data }) => {
				if (data?.data?.success === true) {
					handleSuccess();
					errorCloseHandler();
					onSuccessfulRetry();
				} else {
					handleError('Internal Server Error');
				}
			})
			.catch(handleError)
			.then(() => {
				if (isMounted.current) {
					dispatch({ type: 'SET_LOADING', payload: false });
				}
			});
	};

	return (
		<>
			<Dialog
				className='push-to-pioneer-error-dialog'
				title={
					<Icon
						className={`closeIcon ${
							state.buttonVisible ? '' : 'small'
						}`}
						icon='close'
						onClick={errorCloseHandler}
					></Icon>
				}
				size='tiny'
				visible={showErrorDialog}
				onCancel={errorCloseHandler}
				lockScroll={false}
				showClose={false}
				top='33%'
			>
				<Dialog.Body>
					<section className='appointment-scheduling-error-msg-title'>
						{
							<div>
								This encounter failed to send to Pioneer:{' '}
								{pioneerRcoErrorMessage}
							</div>
						}
					</section>
					{state.buttonVisible ? (
						<div>
							<section className='appointment-scheduling-error-msg-option-container'>
								<div className='header'>Option 1:</div>
								<div className='description'>
									Resolve the above error on your own, then
									click the button below to resend.
								</div>

								<Loading loading={state.loading}>
									{state.buttonVisible && !state.success && (
										<div
											className='button'
											style={{ cursor: 'pointer' }}
											onClick={handlePushToPioneer}
										>
											Send to Pioneer
										</div>
									)}
								</Loading>
							</section>

							<div className='solid'></div>

							<section className='appointment-scheduling-error-msg-option-container'>
								<div className='header'>Option 2:</div>
								<div className='description'>
									If you cannot resolve the error, submit a PS
									ticket to{' '}
									<a
										href='mailto:ps@genomemedical.com'
										style={{ color: '#01749D' }}
									>
										{' '}
										ps@genomemedical.com
									</a>
									.
								</div>
							</section>
						</div>
					) : (
						<section
							className='appointment-scheduling-error-msg-option-container'
							style={{ height: '30px' }}
						>
							<div className='description'>
								Submit a PS ticket to{' '}
								<a
									href='mailto:ps@genomemedical.com'
									style={{ color: '#01749D' }}
								>
									{' '}
									ps@genomemedical.com
								</a>
								.
							</div>
						</section>
					)}
				</Dialog.Body>
			</Dialog>
		</>
	);
}

const mapDispatchToProps = {
	migrateToPioneerRCO,
};
export default connect(null, mapDispatchToProps)(PushToPioneerFailedDialog);
