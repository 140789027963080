import React from 'react';
import z from 'zod';

import { dependentVariants } from '../../../../../utils';
import { biologicalSexVariants } from '../../../../../Utils/appointmentUtils';
import { Section } from '@gm/common/ui';

import { InsuranceDetails } from '../components/InsuranceDetails';
import { OtherDemographicIdentifiers } from '../components/OtherDemographicIdentifiers';
import { PatientConsentSection } from '../components/PatientConsentSection';

import './AdditionalPatientInfo.scss';
import { useSection } from './useSection';

/**
 * Zod validation schema for the section
 */
export const schemaAdditionalPatientInfo = z
	.object({
		group: z.string(),
		healthInsurance: z.number().nullable(),
		insuranceId: z.string(),
		isPrimaryPolicyHolder: z.boolean(),
		policyholderFirstName: z.string().nullable(),
		policyholderLastName: z.string().nullable(),
		policyholderDOB: z.string().nullable(),
		policyholderBiologicalSex: z
			.null()
			.or(z.enum(biologicalSexVariants.map((item) => item.value))),
		relationshipToPolicyholder: z
			.null()
			.or(z.enum(dependentVariants.map((item) => item.value))),
		race: z.null().or(z.string()),
		secondaryRace: z.array(z.string()),
		ethnicity: z.null().or(z.string()),
		preferredName: z.string().nullable(),
		gender: z.null().or(z.string()),
		pronouns: z.array(z.string()),
		consentToTreat: z.null().or(z.string()),
		checkIfConsentToText: z.boolean(),
	})
	.superRefine((value, ctx) => {
		if (value.healthInsurance !== null) {
			if (value.group.length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Group # is required',
					path: ['group'],
				});
			}
			if (value.insuranceId.length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Insurance ID # is required',
					path: ['insuranceId'],
				});
			}
			if (value.policyholderBiologicalSex === null) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: "Policyholder's biological sex is required",
					path: ['policyholderBiologicalSex'],
				});
			}

			if (value.isPrimaryPolicyHolder === false) {
				if (value.relationshipToPolicyholder === null) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Relationship to policyholder is required',
						path: ['relationshipToPolicyholder'],
					});
				}

				if (value.policyholderFirstName === null) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Policyholder's First Name is Required",
						path: ['policyholderFirstName'],
					});
				}

				if (value.policyholderLastName === null) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Policyholder's Last Name is Required",
						path: ['policyholderLastName'],
					});
				}

				if (value.policyholderDOB === null) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Policyholder's Date of Birth is Required",
						path: ['policyholderDOB'],
					});
				}
			}
		}
	});

/**
 * Initial values for the section
 */
export const initialAdditionalPatientInfo = {
	group: '',
	healthInsurance: null,
	insuranceId: '',
	isPrimaryPolicyHolder: true,
	policyholderBiologicalSex: null,
	policyholderFirstName: null,
	policyholderLastName: null,
	policyholderDOB: null,
	relationshipToPolicyholder: null,

	race: null,
	secondaryRace: [],
	ethnicity: null,
	preferredName: '',
	gender: null,
	pronouns: [],

	consentToTreat: null,
	checkIfConsentToText: false,
};

export const AdditionalPatientInfo = function ({
	isEdit,
	patientDetail,
	sectionDataContainerName,
	activeRole
}) {
	const { allValues } = useSection(sectionDataContainerName);
	const preFillForm =
		isEdit &&
		patientDetail !== null &&
		patientDetail !== undefined &&
		Object.keys(patientDetail).length > 0;
	return (
		<Section
			className='additional-patient-info'
			title='Additional Patient Info'
		>
			<h3>Insurance Details</h3>
			<InsuranceDetails
				className='column-layout-3'
				preFillForm={preFillForm}
				referralProgramId={allValues.serviceAndProgram.referralProgram}
				sectionDataContainerName={sectionDataContainerName}
			/>
			<hr />
			<OtherDemographicIdentifiers
				isEdit={isEdit}
				patientDetail={patientDetail}
				sectionDataContainerName={sectionDataContainerName}
			/>
			{isEdit &&
			<>
				<hr />
				<PatientConsentSection
					isEdit={isEdit}
					patientDetail={patientDetail}
					sectionDataContainerName={sectionDataContainerName}
					activeRole={activeRole}
				/>
			</>}
		</Section>
	);
};
