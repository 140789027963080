//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//UI Libraries
import InputMask from 'react-input-mask';

//Components
import { SelectComponent as Select } from '../Common/SelectComponent';

const AddEditExternalProviderInputField = (props) => {
	const {
		errors = {},
		fieldChange,
		fieldData = {},
		setPhoneFieldFocus,
	} = props;

	const {
		type = null,
		header = null,
		value = null,
		placeholder = null,
		label = null,
		options = [],
		maskType = null,
		quartersWidth = 2,
		required = false,
		recommended = false,
		marginLeft = null,
		typeahead = false,
		clearable = false,
		keyOverride = false,
	} = fieldData;

	const stateHeaders = new Set(['state', 'orgState']);

	const renderInputField = () => {
		const widthClass = determineWidthClass() || '';
		const fieldError = get(errors, header, null);
		const errorClass = fieldError ? 'inputFieldTextErrorState' : '';
		switch (type) {
			case 'text':
				return (
					<input
						className={`inputField inputFieldText ${widthClass} ${errorClass}`}
						onChange={(e) =>
							fieldChange(header, get(e, 'target.value', ''))
						}
						value={value}
						placeholder={placeholder || label}
					/>
				);
			case 'textMask':
				if (maskType === 'phone') {
					return (
						<InputMask
							placeholder={placeholder || label}
							value={value}
							onChange={(e) =>
								fieldChange(header, get(e, 'target.value', ''))
							}
							className={`inputField inputFieldText ${widthClass} ${errorClass}`}
							autoComplete='off'
							mask={'+1 (999) 999-9999'}
							maskChar={null}
							onFocus={(e) => setPhoneFieldFocus(true)}
							onBlur={(e) => setPhoneFieldFocus(false)}
						/>
					);
				}
			case 'singleSelect':
				return (
					<Select
						className={`inputField inputFieldDropdown ${widthClass}`}
						onChange={(e) => fieldChange(header, e)}
						value={value}
						placeholder={placeholder || 'Select'}
						options={options}
						typeahead={typeahead}
						clearable={clearable}
						keyOverride={keyOverride}
					/>
				);
			case 'singleSelectPill':
				return (
					<div className='externalProviderModalSelectPillContainer'>
						{Array.isArray(options) &&
							options.map((el) => {
								const isSelected = get(el, 'key', '') === value;
								const pillClassName = isSelected
									? 'singleSelectPill singleSelectPillSelected'
									: 'singleSelectPill singleSelectPillUnselected';
								const key = get(el, 'key', null);
								return (
									<div
										className={pillClassName}
										onClick={(e) =>
											fieldChange(
												header,
												isSelected ? null : key
											)
										}
										key={key}
									>
										{get(el, 'display_name', null) || ''}
									</div>
								);
							})}
					</div>
				);
			case 'textArea':
				return (
					<textarea
						className={`inputField inputFieldTextArea ${widthClass}`}
						onChange={(e) =>
							fieldChange(header, get(e, 'target.value', ''))
						}
						value={value}
						placeholder={placeholder || label}
					/>
				);
			default:
				console.log('Unrecognized field type.');
				break;
		}
	};

	const determineWidthClass = () => {
		const isDropdown = type === 'singleSelect';
		switch (quartersWidth) {
			case 1:
				return isDropdown
					? 'externalProviderOneQuarterWidthDropdown'
					: 'externalProviderOneQuarterWidthText';
			case 2:
				return isDropdown
					? 'externalProviderTwoQuarterWidthDropdown'
					: 'externalProviderTwoQuarterWidthText';
			case 3:
				return isDropdown
					? 'externalProviderThreeQuarterWidthDropdown'
					: 'externalProviderThreeQuarterWidthText';
			case 4:
				return isDropdown
					? 'externalProviderFourQuarterWidthDropdown'
					: 'externalProviderFourQuarterWidthText';
			default:
				console.log('Unrecognized width');
		}
	};

	const determineErrorStyle = () => {
		if (header === 'notes') {
			return { top: '103px' };
		} else return {};
	};

	const isSelectPill = type === 'singleSelectPill';
	const headerClass = isSelectPill
		? 'inputFieldHeader externalProviderModalSelectPillHeader'
		: 'inputFieldHeader';
	const fieldError = get(errors, header, null);
	return (
		<div
			className='inputFieldContainer'
			style={{ marginLeft: marginLeft ? '16px' : '0px' }}
		>
			{required && <div className='inputFieldRequired'>*</div>}
			{recommended && <div className='inputFieldRecommended'>*</div>}
			<div className={headerClass}>
				{get(fieldData, 'label', '') || '--'}
			</div>
			{renderInputField()}
			{fieldError && (
				<div className='inputFieldError' style={determineErrorStyle()}>
					{fieldError}
				</div>
			)}
		</div>
	);
};

export default AddEditExternalProviderInputField;
