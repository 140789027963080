import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSection } from '../../../../Patients/V3/CreatePatient/sections/useSection';
import FormField from '../../../../Common/FormFields/FormField';

import { ApplyCouponCode } from '../../../../../actions/scheduling';
//Images
import ReactSVG from 'react-svg';
import removeIcon from './../../../../../assets/remove.svg';

//UI Libraries
import { Button } from '@gm/common/ui';
import { Loading } from 'gm-element-react';

import { get, isNil } from 'lodash';

import './CouponCodeSection.scss';

export function CouponCodeSection({
	sectionDataContainerName,
	totalPrice,
	selectedConsultation,
	patientDetails,
	updateDisplayPrice,
	isCouponCodeShow,
}) {
	const { setFieldValue, values } = useSection(sectionDataContainerName);
	const [couponCode, setCouponCode] = useState(null);
	const {
		couponCodeSuccess,
		couponCodeWarning,
		couponCodeError,
		loadingCouponCodeDetail,
	} = useSelector((state) => ({
		loadingCouponCodeDetail: state.Scheduling?.loadingCouponCodeDetail,
		couponCodeSuccess: state.Scheduling?.couponCodeSuccess,
		couponCodeWarning: state.Scheduling?.couponCodeWarning,
		couponCodeError: state.Scheduling?.couponCodeError,
	}));

	const { showAddCouponCodeBtn, couponCodeApplied } = values;

	const dispatch = useDispatch();

	const handleCouponCodeChange = (e) => {
		setCouponCode(e.target.value);
	};

	const handleApplyCouponCode = (e) => {
		e.preventDefault();
		let product_id = get(selectedConsultation, ['id'], null);
		let product_type = 'consultation';
		if (
			get(selectedConsultation, 'is_predefined') &&
			get(selectedConsultation, ['purchased_service_package', '0', 'id'])
		) {
			product_id = get(
				selectedConsultation,
				['purchased_service_package', '0', 'id'],
				null
			);
			product_type = 'service_package';
		}
		const data = {
			code: couponCode,
			products: [
				{
					product_id: product_id,
					product_type: product_type,
				},
			],
		};
		dispatch(ApplyCouponCode(patientDetails.uuid, data));
	};

	const calculatePrice = () => {
		let discountValueRes = get(
			couponCodeSuccess,
			['discount_info', 'discount_value'],
			0
		);
		if (totalPrice !== undefined) {
			const consulatationPrice =
				totalPrice - discountValueRes >= 0
					? totalPrice - discountValueRes
					: 0;
			return consulatationPrice;
		}
		return totalPrice;
	};

	const isLoading = loadingCouponCodeDetail ?? false;
	let errCouponCodeMessage = !isNil(couponCodeWarning)
		? get(couponCodeWarning, ['message'], null)
		: !isNil(couponCodeError)
			? get(couponCodeError, ['message'], null)
			: null;
	errCouponCodeMessage = !isNil(errCouponCodeMessage)
		? 'Coupon code is invalid'
		: '';

	useEffect(() => {
		if (!isNil(couponCodeSuccess)) {
			updateDisplayPrice(calculatePrice());
			setFieldValue('couponCodeApplied', true);
			setFieldValue('showAddCouponCodeBtn', false);
			setFieldValue('couponCode', couponCode);
			setCouponCode(null);
		}
	}, [couponCodeSuccess]);

	const canBeApplyCoupon = () => {
		return couponCode && couponCode.length > 0;
	};

	const handleRemoveCouponCode = (e) => {
		e.preventDefault();
		updateDisplayPrice(totalPrice);
		setCouponCode(null);
		setFieldValue('couponCodeApplied', false);
		setFieldValue('showAddCouponCodeBtn', false);
		setFieldValue('couponCode', null);
	};

	const renderCouponCodeSection = () => {
		if (isCouponCodeShow() === true) {
			if (couponCodeApplied === true) {
				return (
					<div className='coupon-code-section'>
						<div className='coupon-code-row'>
							<FormField label='Coupon Code'>
								{values.couponCode || '--'}
							</FormField>
							<button
								type='primary'
								className='btn-remove-coupon-code gm-btn'
								onClick={(e) => handleRemoveCouponCode(e)}
							>
								<ReactSVG
									src={removeIcon}
									style={{
										position: 'relative',
										top: '1px',
										marginRight: '8px',
									}}
								/>
								Remove
							</button>
						</div>
					</div>
				);
			}
			if (showAddCouponCodeBtn === true) {
				return (
					<div className='coupon-code-section'>
						<div className='coupon-code-row'>
							<div className='gm-form-field gm-input'>
								<label>Coupon Code</label>
								<div className='el-input'>
									<input
										value={couponCode}
										placeholder='FRE3_GM1'
										className='txt-coupon-code'
										onChange={(e) =>
											handleCouponCodeChange(e)
										}
										onKeyPress={(e) =>
											e.key === 'Enter' &&
											canBeApplyCoupon() === true &&
											handleApplyCouponCode(e)
										}
									/>
								</div>
							</div>
							<Button
								link
								className='btn-apply-coupon-code gm-btn'
								disabled={canBeApplyCoupon() !== true}
								onClick={(e) => handleApplyCouponCode(e)}
							>
								Apply
							</Button>
						</div>
						<span className='error-message'>
							{errCouponCodeMessage}
						</span>
					</div>
				);
			}
		}
	};

	return (
		<Loading loading={isLoading} className='summary-couponcode-loader'>
			{renderCouponCodeSection()}
		</Loading>
	);
}
