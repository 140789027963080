import * as actionTypes from '../../actions/actionTypes';

export const defaultState = {
	loading_referralprogram: false,
	loaded_referralprograms: null,

	loading_referraldocument: false,
	loaded_referraldocument: null,

	creatingTemplate: false,
	createdTemplate: null,

	updatingTemplate: false,
	updatedTemplate: null,
	updatedTemplateError: null,

	viewingTemplate: false,
	viewedTemplate: null,

	loading_documentdetail: false,
	loaded_documentdetail: null,
	loaded_documentDetailError: null,

	loadingReferralProgramDetail: false,
	loadedReferralProgramDetail: {
		loaded: {},
		loading: false,
	},
	referralProgramDetail: {},

	linkingDefaultProvider: false,
	linkedDefaultProvider: false,
	linkedDefaultProviderError: null,

	updatingDefaultProviders: false,
	updatedDefaultProviders: false,
	updatedDefaultProvidersError: null,

	creatingBillingInfo: false,
	createdBillingInfo: null,

	updatingBillingInfo: false,
	updatedBillingInfo: null,

	archivingTemplate: false,
	archivedTemplate: null,
	archivingTemplateError: null,

	defaultSchedulingOptions: {
		loading: false,
		payload: {},
		error: null,
	},
};

export default (state = defaultState, action = {}) => {
	switch (action.type) {
		case actionTypes.LOADING_REFERRAL_PROGRAMS:
			return {
				...state,
				loading_referralprogram: true,
			};

		case actionTypes.LOADED_REFERRAL_PROGRAMS:
			return {
				...state,
				loading_referralprogram: false,
				loaded_referralprograms: action.payload,
			};

		case actionTypes.ERROR_REFERRAL_PROGRAMS:
			return {
				...state,
				loading_referralprogram: false,
				loaded_referralprograms: null,
			};

		case actionTypes.LOADING_WORKFLOW_DOCUMENTS:
			return {
				...state,
				loading_workflowdocument: true,
			};

		case actionTypes.LOADED_WORKFLOW_DOCUMENTS:
			return {
				...state,
				loading_workflowdocument: false,
				loaded_workflowdocument: action.payload,
			};

		case actionTypes.ERROR_WORKFLOW_DOCUMENTS:
			return {
				...state,
				loading_workflowdocument: false,
				loaded_workflowdocument: null,
			};

		case actionTypes.LOADING_REFERRAL_DOCUMENTS:
			return {
				...state,
				loading_referraldocument: true,
			};

		case actionTypes.LOADED_REFERRAL_DOCUMENTS:
			return {
				...state,
				loading_referraldocument: false,
				loaded_referraldocument: action.payload,
			};

		case actionTypes.ERROR_REFERRAL_DOCUMENTS:
			return {
				...state,
				loading_referraldocument: false,
				loaded_referraldocument: null,
			};

		case actionTypes.LOADING_CREATE_TEMPLATE:
			return {
				...state,
				creatingTemplate: true,
				createdTemplateError: null,
			};

		case actionTypes.LOADED_CREATE_TEMPLATE:
			return {
				...state,
				creatingTemplate: false,
				createdTemplate: action.payload,
				createdTemplateError: null,
			};

		case actionTypes.ERROR_CREATE_TEMPLATE:
			return {
				...state,
				creatingTemplate: false,
				createdTemplate: null,
				createdTemplateError: action.payload,
			};

		case actionTypes.LOADING_UPDATE_TEMPLATE:
			return {
				...state,
				updatingTemplate: true,
				updatedTemplate: null,
				updatedTemplateError: null,
			};

		case actionTypes.LOADED_UPDATE_TEMPLATE:
			return {
				...state,
				updatingTemplate: false,
				updatedTemplate: action.payload,
				updatedTemplateError: null,
			};

		case actionTypes.ERROR_UPDATE_TEMPLATE:
			return {
				...state,
				updatingTemplate: false,
				updatedTemplate: null,
				updatedTemplateError: action.payload,
			};

		case actionTypes.LOADING_VIEW_TEMPLATE:
			return {
				...state,
				viewingTemplate: true,
			};

		case actionTypes.LOADED_VIEW_TEMPLATE:
			return {
				...state,
				viewingTemplate: false,
				viewedTemplate: action.payload,
			};

		case actionTypes.ERROR_VIEW_TEMPLATE:
			return {
				...state,
				viewingTemplate: false,
				viewedTemplate: null,
			};

		case actionTypes.RESET_REFERRAL_DOCUMENTS:
			return {
				...state,
				loading_referraldocument: false,
				loaded_referraldocument: null,
			};

		case actionTypes.LOADING_REFERRAL_DOCUMENT_DETAIL:
			return {
				...state,
				loading_documentdetail: true,
				loaded_documentdetail: null,
				loaded_documentDetailError: null,
			};

		case actionTypes.LOADED_REFERRAL_DOCUMENT_DETAIL:
			return {
				...state,
				loading_documentdetail: false,
				loaded_documentdetail: action.payload,
				loaded_documentDetailError: null,
			};

		case actionTypes.ERROR_REFERRAL_DOCUMENT_DETAIL:
			return {
				...state,
				loading_documentdetail: false,
				loaded_documentdetail: null,
				loaded_documentDetailError: action.payload,
			};

		case actionTypes.RESET_WORKFLOW_DOCUMENTS:
			return {
				...state,
				loading_workflowdocument: false,
				loaded_workflowdocument: null,
			};

		case actionTypes.LOADING_REFERRAL_PROGRAM_DETAIL:
			return {
				...state,
				loadingReferralProgramDetail: true,
			};

		case actionTypes.LOADED_REFERRAL_PROGRAM_DETAIL:
			return {
				...state,
				loadingReferralProgramDetail: false,
				loadedReferralProgramDetail: action.payload,
				referralProgramDetail: action.payload,
			};

		case actionTypes.ERROR_REFERRAL_PROGRAM_DETAIL:
			return {
				...state,
				loadingReferralProgramDetail: false,
				loadedReferralProgramDetail: null,
				referralProgramDetail: {},
			};

		case actionTypes.RESET_REFERRAL_PROGRAM_DETAIL:
			return {
				...state,
				loadingReferralProgramDetail: false,
				loadedReferralProgramDetail: null,
				referralProgramDetail: {},
			};

		case actionTypes.LOADING_DEFAULT_SCHEDULING_OPTIONS:
			return {
				...state,
				defaultSchedulingOptions: {
					loading: true,
					payload: {},
					error: null,
				},
			};

		case actionTypes.LOADED_DEFAULT_SCHEDULING_OPTIONS:
			return {
				...state,
				defaultSchedulingOptions: {
					loading: false,
					payload: action.payload,
					error: null,
				},
			};

		case actionTypes.ERROR_DEFAULT_SCHEDULING_OPTIONS:
			return {
				...state,
				defaultSchedulingOptions: {
					loading: false,
					payload: {},
					error: action.payload,
				},
			};

		case actionTypes.RESET_DEFAULT_SCHEDULING_OPTIONS:
			return {
				...state,
				defaultSchedulingOptions: {
					loading: false,
					payload: {},
					error: null,
				},
			};

		case actionTypes.LOADING_CREATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER:
			return {
				...state,
				linkingDefaultProvider: true,
			};

		case actionTypes.LOADED_CREATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER:
			return {
				...state,
				linkingDefaultProvider: false,
				linkedDefaultProvider: action.payload.data,
				linkedDefaultProviderError: null,
			};

		case actionTypes.ERROR_CREATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER:
			return {
				...state,
				linkingDefaultProvider: false,
				linkedDefaultProvider: true,
				linkedDefaultProviderError: action.payload,
			};

		case actionTypes.LOADING_UPDATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER:
			return {
				...state,
				updatingDefaultProviders: true,
			};

		case actionTypes.LOADED_UPDATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER:
			return {
				...state,
				updatingDefaultProviders: false,
				updatedDefaultProviders: true,
				updatedDefaultProvidersError: null,
			};

		case actionTypes.ERROR_UPDATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER:
			return {
				...state,
				updatingDefaultProviders: false,
				updatedDefaultProviders: true,
				updatedDefaultProvidersError: action.payload,
			};

		case actionTypes.CLEAR_DEFAULT_PROVIDERS_STATE:
			return {
				...state,
				linkingDefaultProvider: false,
				linkedDefaultProvider: false,
				linkedDefaultProviderError: null,
				updatingDefaultProviders: false,
				updatedDefaultProviders: false,
				updatedDefaultProvidersError: null,
			};

		case actionTypes.LOADING_CREATE_BILLING_INFO:
			return {
				...state,
				creatingBillingInfo: true,
			};

		case actionTypes.LOADED_CREATE_BILLING_INFO:
			return {
				...state,
				creatingBillingInfo: false,
				createdBillingInfo: action.payload,
			};

		case actionTypes.ERROR_CREATE_BILLING_INFO:
			return {
				...state,
				creatingBillingInfo: false,
				createdBillingInfo: null,
			};

		case actionTypes.LOADING_UPDATE_BILLING_INFO:
			return {
				...state,
				updatingBillingInfo: true,
			};

		case actionTypes.LOADED_UPDATE_BILLING_INFO:
			return {
				...state,
				updatingBillingInfo: false,
				updatedBillingInfo: action.payload,
			};

		case actionTypes.ERROR_UPDATE_BILLING_INFO:
			return {
				...state,
				updatingBillingInfo: false,
				updatedBillingInfo: null,
			};

		case actionTypes.ARCHIVE_TEMPLATE_START:
			return {
				...state,
				archivingTemplate: true,
				archivedTemplate: null,
				archivingTemplateError: null,
			};

		case actionTypes.ARCHIVE_TEMPLATE_SUCCESS:
			return {
				...state,
				archivingTemplate: false,
				archivedTemplate: action.payload,
				archivingTemplateError: null,
			};

		case actionTypes.ARCHIVE_TEMPLATE_FAILURE:
			return {
				...state,
				archivingTemplate: false,
				archivedTemplate: null,
				archivingTemplateError: action.payload,
			};
		default:
			return state;
	}
};
