//React & Redux
import React, { useState, useEffect } from 'react';

//Images

//Other Libraries
import { get, isEmpty, isNil } from 'lodash';
import classnames from 'classnames';

import { Button, Layout } from 'gm-element-react';

//Images
import ReactSVG from 'react-svg';
import trashIcon from './../../../../assets/delete.svg';

//Styles
import './css/cfdna-cancerassociated-modal.css';

//Utils
import {
	primaryPredictedOrignEnum,
	secondaryPredictedOrignEnum,
} from '../../../../utils';
import { CARE_PATIENTS_ENTER_TEST_RESULT } from '../../../../Utils/permissionUtils';

//Components
import { SelectComponent as Select } from '../../../Common/SelectComponent';
import RBAC from '../../../RBAC';

const CFDNACancerAssociatedModal = (props) => {
	const {
		modalMode = 'view',
		diagnosticReportFindings = {},
		hidecfDNACancerModal,
		deleteFinding,
		saveFindings,
	} = props;

	const [formdata, setFormdata] = useState({
		cancerSignalDetected: null,
		primaryPredictedOrign: '',
		secondaryPredictedOrign: '',
	});
	const [errors, setErrors] = useState({
		errors: {},
	});

	useEffect(() => {
		if (
			!isEmpty(diagnosticReportFindings) &&
			!isNil(diagnosticReportFindings) &&
			isEditMode()
		) {
			setFormdata({
				cancerSignalDetected:
					diagnosticReportFindings.cancerSignal.toLowerCase() ===
					'detected',
				primaryPredictedOrign: diagnosticReportFindings.primary.key,
				secondaryPredictedOrign: diagnosticReportFindings.secondary.key,
			});
		}
	}, []);

	//functions
	const onFormChange = (key, value) => {
		let errors = {};

		if (key == 'cancerSignalDetected' && value == false) {
			formdata['primaryPredictedOrign'] = '';
			formdata['secondaryPredictedOrign'] = '';
		}
		formdata[key] = value;

		errors = validateForm(formdata);
		setFormdata(formdata);
		setErrors(errors);
	};

	const validateForm = (formdata) => {
		const errors = {};

		if (isNil(formdata['cancerSignalDetected'])) {
			errors['cancerSignalDetected'] = 'Cancer Signal is required';
		}

		if (
			!isEmpty(formdata['secondaryPredictedOrign']) &&
			isEmpty(formdata['primaryPredictedOrign'])
		) {
			errors['primaryPredictedOrign'] =
				'Primary Predicted Orign is required';
		}
		return errors;
	};

	const isCancerSignalDetected = () => {
		const cancerSignalDetected = formdata?.cancerSignalDetected || null;
		return !isNil(cancerSignalDetected) && cancerSignalDetected;
	};

	const isPrimaryPredictedOrignRequired = () => {
		return !isEmpty(formdata['secondaryPredictedOrign']);
	};

	const isDisableSavedButton = () => {
		const errors = validateForm(formdata);
		return !isEmpty(errors);
	};

	const saveTestResultData = () => {
		hidecfDNACancerModal();
		saveFindings(
			{
				...formdata,
				uuid: get(diagnosticReportFindings, ['uuid'], null),
			},
			isEditMode()
		);
	};

	const _deleteFinding = () => {
		const diagnosticFindingId = get(
			diagnosticReportFindings,
			['uuid'],
			null
		);

		if (diagnosticFindingId) {
			hidecfDNACancerModal();
			deleteFinding(diagnosticFindingId);
		}
	};

	const isEditMode = () => {
		return modalMode == 'edit';
	};

	const handleCancelButton = () => {
		setFormdata({
			cancerSignalDetected: null,
			primaryPredictedOrign: '',
			secondaryPredictedOrign: '',
		});
		hidecfDNACancerModal();
	};

	return (
		<div className='fullModalBackground'>
			<div className={'cfdna-cancer-associated-container'}>
				<div className='modal-top-row'>
					<div className='modal-header'>
						<span>
							{isEditMode() ? 'Edit' : 'Add'} Finding — cfDNA
							Cancer
						</span>
					</div>
				</div>
				<div className='modal-body'>
					<Layout.Row>
						<Layout.Col
							span='24'
							className='required button-cancer-signal-wrapper'
						>
							<span className='label-cancer-signal-detected'>
								Cancer Signal
							</span>
							<div
								onClick={() =>
									onFormChange('cancerSignalDetected', true)
								}
								className={classnames(
									'button-cancer-signal button-cancer-signal-detected',
									isCancerSignalDetected()
										? 'button-cancer-signal-selected'
										: 'button-cancer-signal-unselected'
								)}
							>
								Detected
							</div>
							<div
								onClick={() =>
									onFormChange('cancerSignalDetected', false)
								}
								className={classnames(
									'button-cancer-signal',
									!isNil(formdata.cancerSignalDetected) &&
										!formdata.cancerSignalDetected
										? 'button-cancer-signal-selected'
										: 'button-cancer-signal-unselected'
								)}
							>
								Not Detected
							</div>
						</Layout.Col>
					</Layout.Row>
					<Layout.Row gutter='20'>
						<Layout.Col span='24'>
							<span
								className={
									isPrimaryPredictedOrignRequired()
										? 'required'
										: ''
								}
							>
								Top Predicted Origin
							</span>
							<Select
								value={get(
									formdata,
									['primaryPredictedOrign'],
									''
								)}
								onChange={(value) =>
									onFormChange('primaryPredictedOrign', value)
								}
								placeholder='Select'
								disabled={!isCancerSignalDetected()}
								className='inputFieldDropdown fullWidth'
								typeahead={true}
								clearable={true}
								options={primaryPredictedOrignEnum.map((el) => {
									const option = {};
									option.key = el['key'];
									option.label = el['display_name'];
									option.value = el['key'];
									return option;
								})}
							/>
						</Layout.Col>
					</Layout.Row>
					<Layout.Row gutter='20'>
						<Layout.Col span='24'>
							<span>Secondary Predicted Origin</span>
							<Select
								value={get(
									formdata,
									['secondaryPredictedOrign'],
									''
								)}
								onChange={(value) =>
									onFormChange(
										'secondaryPredictedOrign',
										value
									)
								}
								placeholder='Select'
								disabled={!isCancerSignalDetected()}
								className='inputFieldDropdown fullWidth'
								typeahead={true}
								clearable={true}
								options={secondaryPredictedOrignEnum.map(
									(el) => {
										const option = {};
										option.key = el['key'];
										option.label = el['display_name'];
										option.value = el['key'];
										return option;
									}
								)}
							/>
						</Layout.Col>
					</Layout.Row>
					{isEditMode() &&
						!isEmpty(diagnosticReportFindings?.assertedBy) &&
						diagnosticReportFindings?.assertedBy === 'User' && (
							<Layout.Row gutter='16'>
								<Layout.Col span='24'>
									<RBAC
										action={CARE_PATIENTS_ENTER_TEST_RESULT}
										yes={
											<div
												className='cfDNA-delete-finding'
												onClick={() => _deleteFinding()}
											>
												<ReactSVG
													src={trashIcon}
													className='gene-result-delete-icon'
												/>{' '}
												<span>Delete Finding</span>
											</div>
										}
									/>
								</Layout.Col>
							</Layout.Row>
						)}
				</div>
				<div className='modal-footer'>
					<Button
						className='cancel-button'
						onClick={handleCancelButton}
					>
						{' '}
						Cancel
					</Button>
					<Button
						className='save-button gmi-fill-purple-button'
						disabled={isDisableSavedButton()}
						onClick={() => {
							saveTestResultData();
						}}
					>
						Save
					</Button>
				</div>
			</div>
		</div>
	);
};

export default CFDNACancerAssociatedModal;
