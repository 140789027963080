//React & Redux
import React, { useEffect, useState, Fragment } from 'react';

//Images
import editpencil from '../../../../../assets/edit.svg';
import imageExpandCollapse from '../../../../../assets/chevron-large.svg';

//Lodash
import _ from 'lodash';

//Utils
import {
	CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER,
	CARE_PATIENTS_ENCOUNTER_ADDINFOSEC_EDIT,
	CARE_PATIENTS_PATIENT_ENCOUNTER_ADDITIONAL_INFO,
	CARE_PATIENTS_PATIENT_ENCOUNTER_PROVIDER_OPERATIONS,
	CARE_PATIENTS_PATIENT_ENCOUNTER_BILLING,
} from '../../../../../Utils/permissionUtils';
import { SCHEDULING_PLATFORM } from '../../../../../Utils/appointmentUtils';

//UI Libraries
import { Loading } from 'gm-element-react';

//Actions & Services
import {
	preauthorizationsTestOrder,
	createTestOrderRequest,
	updatePreAuthTestOrder,
	createTestOrderNote,
} from '../../../../../actions/encounter';
import {
	addOutreach,
	updateOutreach,
} from '../../../../../actions/patient/outreach';
import { followUpDeclinedOutreach } from '../../../../../actions/encounter';

//Other Libraries
import classnames from 'classnames';

//Components
import VisitDetailSegment from './VisitDetailSegment';
import EditVisitDetailSegment from './EditVisitDetailSegment';
import TestOrderSegmentV2 from './TestOrderSegmentV2';
import EditTestOrderSegmentV2 from './EditTestOrderSegmentV2';
import PGXSegment from './PGXSegment';
import EditPGXSegment from './EditPGXSegment';
import RORSegment from './RORSegment';
import BillingSegment from './BillingSegment';
import EditBillingSegment from './EditBillingSegment';
import AdditionalSegment from './AdditionalSegment';
import EditAdditionalSegment from './EditAdditionalSegment';
import RBAC from '../../../../RBAC';
import RelatedEncounterInfoSegment from '../../../../Billing/BillingDetail/RelatedEncounterInfoSegment';
import ProviderLinked from '../../../../ExternalProviders/ProviderLinked';
import ProviderAddAnother from '../../../../ExternalProviders/ProviderAddAnother';
import { usePrevious } from '../../../../../hooks/usePrevious';

const VisitEncounterDetail = (props) => {
	const {
		externalProviderProps,
		callvisitsschema,
		LoadTestEnums,
		encounter,
		patientdetail,
		user,
		fetchOrderingPhysician,
		fetchVisitProvider,
		callPreauthorizationsEnums,
		callGeneTestEnums,
		callTestOrderRequest,
		callPreauthorizationTestOrders,
		getPatientFeeRefundEnum,
		encounterTestOrderRequest,
		encounter_type,
		encounter_uuid,
		calleditencouter,
		UpdateTestOrder,
		getencouter,
		getOutreachsLog,
		enqueueSnackbar,
		shouldEditable,
		billingencounter,
		calleditbillingencouter,
		patient_uuid,
		LoadTestOrderPopupTestEnum,
		nonlegacytestOrderPopupTestEnums,
		nonlegacytestOrderPopupTestEnumsLoading,
		LoadAlternateTestEnum,
		nonlegacyalternatetestnameenums,
		getICDCodes,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		callTestOrderNotes,
		isBillingCoordinator,
		getEncounterAuditTrail,
		orderrequeststatuscascadeenums,
		getLatestOutreachStatus,
		visit_provider,
		testnameenums,
		orderstatuscascadeenums,
		filteredorderstatuscascadeenums,
		ordering_physician,
		preauthorizationsEnums,
		encounterPreauthTestOrders,
		sampleTypeEnums,
		labOrderStatusEnums,
		patientBillingMethodEnums,
		MLOOrderStatusEnums,
		outreachStatusEnums,
		goToTestTab,
		gotoMLOTestDetail,
		preauthdecisionenums,
		preauthdispatchstatusenums,
		testOrderNotes,
		auditTrailEncounterLoading,
		auditTrailEncounter,
		auditTrailEncounterError,
		encounterCommunications,
		outreachEnums,
		gcRoROutreachStopReasonsEnums,
		patientFeeEnum,
		patientDeclinedReasonsEnums,
		visitsschema,
		nonlegacytestnameenums,
		encounterReclaim,
		reclaimEncounter,
		gotoRelatedEncounter,
		visitIndications,
		getAllVisitIndications,
		referralPrograms,
		updatePatientAddress,
		getPatientDetail,
		calltestBulkUpdate,
		activeRole,
		manual_visit_providers,
		partnerLevelSpecialities
	} = props;

	const defaultSegment = {
		RelatedEncounterSegment: true,
		VisitDetailSegment: true,
		TestOrderSegment: !isBillingCoordinator,
		PxInfoSegment: false,
		RORSegment: false,
		BillingSegment: isBillingCoordinator,
		AdditionalSegment: false,
		ExtProvidersSegment: _.get(
			externalProviderProps,
			'expandExtProviders',
			false
		),
	};
	const [segment, setSegment] = useState(defaultSegment);
	const [EditedSegment, setEditedSegment] = useState('');
	const [showChargeModal, setShowChargeModal] = useState(false);
	const [showPreAuthTestModal, setShowPreAuthTestModal] = useState(false);
	const [showHoldReleaseResultModal, setShowHoldReleaseResultModal] =
		useState(false);
	const [showUnableStopOutreachModal, setShowUnableStopOutreachModal] =
		useState(false);
	const [billingViewLoading, setBillingViewLoading] = useState(false);
	const [showListModal, setShowListModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const previousEncounterReclaim = usePrevious(encounterReclaim);

	useEffect(() => {
		callvisitsschema();
		LoadTestEnums(_.get(encounter, ['lab'], ''));
		fetchOrderingPhysician();
		fetchVisitProvider();
		callPreauthorizationsEnums();
		callGeneTestEnums();
		callTestOrderRequest();
		callPreauthorizationTestOrders();
		getOutreachsLog();
		getPatientFeeRefundEnum();
		getAllVisitIndications();

		const hash = window.location.hash;

		if (hash) {
			document.getElementById(hash.replace('#', '')).scrollIntoView({ behavior: 'smooth' });
		}
		
	}, []);
	useEffect(() => {
		if (
			_.get(previousEncounterReclaim, 'loading', false) == true &&
			_.get(encounterReclaim, 'loading', false) == false
		) {
			getencouter(false);
			setTimeout(() => {
				setBillingViewLoading(false);
			}, 1000);
		}
	}, [_.get(encounterReclaim, 'loading', false)]);

	const makeEdited = (segmentname) => (e) => {
		const tempSegment = { ...segment };
		if (shouldEditable) {
			const EditedSegment = segmentname;
			setEditedSegment(EditedSegment);
			tempSegment[segmentname] = true;
			setSegment(tempSegment);
		}
		e.stopPropagation();
	};
	const makeNonEdited = (segmentname) => {
		const tempSegment = { ...segment };
		setEditedSegment('');
		tempSegment[segmentname] = true;
		setSegment(tempSegment);
	};
	const isAnyEditActive = () => {
		return EditedSegment != '';
	};

	const isInEditMode = (segmentname) => {
		return segmentname == EditedSegment;
	};
	const isExpanded = (segmentname) => {
		return _.get(segment, [segmentname], false) == true;
	};
	const toggleSegment = (segmentname) => {
		const tempSegment = { ...segment };
		const isInEditMod = isInEditMode(segmentname);
		if (tempSegment && !isInEditMod) {
			tempSegment[segmentname] = !tempSegment[segmentname];
			setSegment(tempSegment);
		}
	};

	const relatedEncounterProps = () => {
		return {
			encounter: {
				parent_of: _.get(encounter, ['parent_of'], []),
				part_of: _.get(encounter, ['part_of'], []),
				sibling_of: _.get(encounter, ['sibling_of'], []),
			},
			providerenums: visit_provider,
			gotoRelatedEncounter: gotoRelatedEncounter,
		};
	};
	const schedulingPlatform = encounter.pioneer_appointment_id
		? SCHEDULING_PLATFORM.PIONEER
		: SCHEDULING_PLATFORM.VSEE;

	const visitDetailsProps = () => {
		return {
			enqueueSnackbar: enqueueSnackbar,
			encounter: {
				scheduling_poc: _.get(encounter, ['scheduling_poc']),
				pioneer_appointment_id: _.get(encounter, [
					'pioneer_appointment_id',
				]),
				schedulingPlatform,
				state_of_visit: _.get(encounter, ['state_of_visit']),
				timezone: _.get(encounter, ['timezone']),
				relationship_to_patient: _.get(encounter, [
					'relationship_to_patient',
				]),
				date_of_service: _.get(encounter, ['date_of_service']),
				created_at: _.get(encounter, ['created_at']),

				tm_platform: _.get(encounter, ['tm_platform']),
				consultation_type: _.get(encounter, ['consultation_type']),
				vsee_specialty: _.get(encounter, ['vsee_specialty']),
				provider_indicated_specialty: _.get(encounter, [
					'provider_indicated_specialty',
				]),
				visit_provider: _.get(encounter, ['visit_provider']),
				visit_provider_uuid: _.get(encounter, ['visit_provider_uuid']),

				visit_status: _.get(encounter, ['visit_status']),

				records_requested: _.get(encounter, ['records_requested']),
				records_received: _.get(encounter, ['records_received']),
				referring_provider_name: _.get(encounter, [
					'referring_provider_name',
				]),
				referring_provider_fax_email: _.get(encounter, [
					'referring_provider_fax_email',
				]),
				requested_test_via_provider: _.get(encounter, [
					'requested_test_via_provider',
				]),

				initial_cap_completed_date: _.get(encounter, [
					'initial_cap_completed_date',
				]),
				initial_visit_summary_sent: _.get(encounter, [
					'initial_visit_summary_sent',
				]),
				visit_summary_release_method_patient: _.get(encounter, [
					'visit_summary_release_method_patient',
				]),

				followup_cap_completed_date: _.get(encounter, [
					'followup_cap_completed_date',
				]),
				cap_sent_to_patient: _.get(encounter, ['cap_sent_to_patient']),
				cap_release_method_patient: _.get(encounter, [
					'cap_release_method_patient',
				]),
				visit_summary_release_date_pcp: _.get(encounter, [
					'visit_summary_release_date_pcp',
				]),
				visit_summary_release_method_pcp: _.get(encounter, [
					'visit_summary_release_method_pcp',
				]),
				cap_sent_date_pcp: _.get(encounter, ['cap_sent_date_pcp']),
				cap_release_method_pcp: _.get(encounter, [
					'cap_release_method_pcp',
				]),

				medical_codes: _.get(encounter, 'medical_codes', []),
				visit_indications:
					_.get(encounter, 'visit_indications', null) || [],
				uuid: _.get(encounter, 'uuid', []),

				is_escalated: _.get(encounter, 'is_escalated'),
				supervising_physician: _.get(
					encounter,
					'supervising_physician'
				),
				additional_conference_line: _.get(
					encounter,
					'additional_conference_line'
				),
			},
			visitsschema: visitsschema,
			visit_providerenums: visit_provider,
			visitIndicationsEnum: visitIndications,
			showListModalFlag: showListModal,
			showListModal: visibleListModal,
			hideListModal: hideListModal,
			billingencounter: {
				cpt_s0265_95: !!_.get(billingencounter, ['cpt_s0265_95'], null),
				direct_cc_minutes: _.get(
					billingencounter,
					['direct_cc_minutes'],
					null
				),
				indirect_cc_minutes: _.get(
					billingencounter,
					['indirect_cc_minutes'],
					null
				),
				post_session_indirect_cc_minutes: _.get(
					billingencounter,
					['post_session_indirect_cc_minutes'],
					null
				),
				athena_insurance_package_id: _.get(
					billingencounter,
					['athena_insurance_package_id'],
					null
				),
				post_negative_minutes: _.get(
					billingencounter,
					'post_negative_minutes'
				),
				units: _.get(billingencounter, 'units', null),
				units_s0265_95: _.get(billingencounter, 'units_s0265_95', null),
			},
			manual_visit_providers:manual_visit_providers,
			partnerLevelSpecialities:partnerLevelSpecialities
		};
	};

	const testOrdersProps = () => {
		return {
			encounter: {
				order_request_status: _.get(encounter, [
					'order_request_status',
				]),
				lab: _.get(encounter, ['lab']),
				multiple_labs: _.get(encounter, ['multiple_labs'], false),
				test_name: _.get(encounter, ['test_name']),
				ordering_physician: _.get(encounter, ['ordering_physician']),
				date_test_authorized: _.get(encounter, [
					'date_test_authorized',
				]),

				bd_saliva_kit_sent: _.get(encounter, ['bd_saliva_kit_sent']),
				order_status: _.get(encounter, ['order_status']),
				date_test_ordered: _.get(encounter, ['date_test_ordered']),
				expected_lab_results_date: _.get(encounter, [
					'expected_lab_results_date',
				]),
				date_received_report: _.get(encounter, [
					'date_received_report',
				]),

				date_results_released_to_patient: _.get(encounter, [
					'date_results_released_to_patient',
				]),
				blood_draw_request: _.get(encounter, ['blood_draw_request']),
				blood_draw_order_date: _.get(encounter, [
					'blood_draw_order_date',
				]),

				medical_codes: _.get(encounter, 'medical_codes', []),
			},
			visitsschema: visitsschema,
			testnameenums: _.get(testnameenums, ['data'], []),
			nonlegacytestnameenums: nonlegacytestnameenums,
			orderstatuscascadeenums: orderstatuscascadeenums,
			filteredorderstatuscascadeenums: filteredorderstatuscascadeenums,
			orderrequeststatuscascadeenums: orderrequeststatuscascadeenums,
			ordering_physicianenums: ordering_physician,
			preauthorizationsenums: _.get(
				preauthorizationsEnums,
				['preauthorizationsenums'],
				{}
			),
			encounterPreauthTestOrders: encounterPreauthTestOrders,
			sampletypeenums: sampleTypeEnums,
			laborderstatusenums: labOrderStatusEnums,
			patientbillingmethodenums: patientBillingMethodEnums,
			mloOrderStatusEnums: MLOOrderStatusEnums,
			outreachStatusEnums: outreachStatusEnums,
			saveTestOrderRequest: saveTestOrderRequest,
			encounterTestOrderRequest: encounterTestOrderRequest,
			goToTestTab: goToTestTab,
			gotoMLOTestDetail: gotoMLOTestDetail,
			encounterschema: visitsschema,
			preauthdecisionenums: preauthdecisionenums,
			preauthdispatchstatusenums: preauthdispatchstatusenums,
			testOrderNotes: testOrderNotes,
			createPreauthorizeTestOrder: createPreauthorizeTestOrder,
			auditTrailEncounterLoading: auditTrailEncounterLoading,
			auditTrailEncounter: auditTrailEncounter,
			auditTrailEncounterError: auditTrailEncounterError,
			getEncounterAuditTrail: getEncounterAuditTrail,
		};
	};
	const pgxProps = () => {
		return {
			encounter: {
				drug_contraindications: _.get(encounter, [
					'drug_contraindications',
				]),
				drug_dosage_adjustment_recommended: _.get(encounter, [
					'drug_dosage_adjustment_recommended',
				]),
				drug_interaction: _.get(encounter, ['drug_interaction']),
				no_of_interactions: _.get(encounter, ['no_of_interactions']),
				pgx_post_cap_indirect_time: _.get(encounter, [
					'pgx_post_cap_indirect_time',
				]),
				pgx_post_session_indirect_time: _.get(encounter, [
					'pgx_post_session_indirect_time',
				]),
				pgx_post_session_indirect_time_date: _.get(encounter, [
					'pgx_post_session_indirect_time_date',
				]),
				pgx_pre_session_indirect_time: _.get(encounter, [
					'pgx_pre_session_indirect_time',
				]),
				pharmd: _.get(encounter, ['pharmd']),
			},
			visitsschema: visitsschema,
		};
	};
	const rorProps = () => {
		return {
			encounter: {
				ror_visit_status: _.get(encounter, ['ror_visit_status']),
				ror_date_contacted: _.get(encounter, ['ror_date_contacted']),
				ror_date_recontacted: _.get(encounter, [
					'ror_date_recontacted',
				]),
				ror_date_final_contacted: _.get(encounter, [
					'ror_date_final_contacted',
				]),
				outreach_status: _.get(encounter, ['outreach_status']),
			},
			visitsschema: visitsschema,
			outreachStatusEnums: outreachStatusEnums,
			communications: encounterCommunications,
			outreachEnums: outreachEnums,
			patientDeclinedReasonsEnums: patientDeclinedReasonsEnums,
			gcRoROutreachStopReasonsEnums: gcRoROutreachStopReasonsEnums,
			addOutreachHandleSubmit: addOutreachHandleSubmit,
			editOutreachHandleSubmit: editOutreachHandleSubmit,
			handleFUDeclinedOutreachSubmit: handleFUDeclinedOutreachSubmit,
			getOutreachLog: () => getOutreachsLog(false),
			geneTestOrderRequest: filterTestOrderRequest(),
			showUnableStopOutreachModal: showUnableStopOutreachModal,
			closeUnableStopOutreachModal: closeUnableStopOutreachModal,
			getLatestOutreachStatus: getLatestOutreachStatus,
			callTestOrderRequest: callTestOrderRequest,
		};
	};
	const billingProps = () => {
		return {
			encounter: {
				uuid: _.get(encounter, ['uuid']),
				date_of_service: _.get(encounter, ['date_of_service']),
				medical_codes: _.get(encounter, ['medical_codes']),
				initial_cap_completed_date: _.get(encounter, [
					'initial_cap_completed_date',
				]),
				order_request_status: _.get(encounter, [
					'order_request_status',
				]),
				supervising_physician: _.get(encounter, [
					'supervising_physician',
				]),
			},
			billingencounter: {
				patient_billing_date: _.get(billingencounter, [
					'patient_billing_date',
				]),
				patient_billing_amount: _.get(billingencounter, [
					'patient_billing_amount',
				]),
				athena_claim_id: _.get(billingencounter, ['athena_claim_id']),
				athena_claim_err_msg: _.get(billingencounter, [
					'athena_claim_err_msg',
				]),
				direct_cc_minutes: _.get(billingencounter, [
					'direct_cc_minutes',
				]),
				indirect_cc_minutes: _.get(billingencounter, [
					'indirect_cc_minutes',
				]),
				post_session_indirect_cc_minutes: _.get(billingencounter, [
					'post_session_indirect_cc_minutes',
				]),
				units: _.get(billingencounter, ['units']),
				units_s0265_95: _.get(billingencounter, ['units_s0265_95']),
				procedure_code: _.get(billingencounter, ['procedure_code']),
				insurance_expected_revenue: _.get(billingencounter, [
					'insurance_expected_revenue',
				]),
				athena_insurance_package_id: _.get(billingencounter, [
					'athena_insurance_package_id',
				]),
				athena_payment_batch_id: _.get(billingencounter, [
					'athena_payment_batch_id',
				]),
				athena_insurance_id: _.get(billingencounter, [
					'athena_insurance_id',
				]),
				athena_authorization_id: _.get(billingencounter, [
					'athena_authorization_id',
				]),
				athena_rendering_provider_id: _.get(billingencounter, [
					'athena_rendering_provider_id',
				]),
				athena_referring_provider_id: _.get(billingencounter, [
					'athena_referring_provider_id',
				]),
				athena_supervising_provider_id: _.get(billingencounter, [
					'athena_supervising_provider_id',
				]),
				payor: _.get(billingencounter, ['payor']),
				coupon_code: _.get(billingencounter, ['coupon_code']),
				patient_payment: _.get(billingencounter, ['patient_payment']),
				patient_pay: _.get(billingencounter, ['patient_pay']),
				billing_status: _.get(billingencounter, ['billing_status']),
				insurance_claim_status: _.get(billingencounter, [
					'insurance_claim_status',
				]),
				insurance_claim_date: _.get(billingencounter, [
					'insurance_claim_date',
				]),
				billing_notes: _.get(billingencounter, ['billing_notes']),
				patient_fee_refund: _.get(billingencounter, [
					'patient_fee_refund',
				]),
				patient_fee_refund_decision: _.get(billingencounter, [
					'patient_fee_refund_decision',
				]),
				patient_fee_refund_reason: _.get(billingencounter, [
					'patient_fee_refund_reason',
				]),
				patient_fee_refund_reason_other: _.get(billingencounter, [
					'patient_fee_refund_reason_other',
				]),
				patient_fee_refund_processed_date: _.get(billingencounter, [
					'patient_fee_refund_processed_date',
				]),
				cpt_96040_95: _.get(billingencounter, ['cpt_96040_95'], true),
				cpt_s0265_95: _.get(billingencounter, ['cpt_s0265_95'], false),
				post_negative_minutes: _.get(billingencounter, [
					'post_negative_minutes',
				]),
				payment_method: _.get(billingencounter, ['payment_method']),
			},
			visitsschema: visitsschema,
			reclaimEncounter: handleReclaimEncounter,
			encounterReclaim: encounterReclaim,
			patientdetail: patientdetail,
			patientFeeEnum: patientFeeEnum,
			referralPrograms: referralPrograms,
		};
	};
	const additionalInfoProps = () => {
		return {
			encounter: {
				notes: _.get(encounter, ['notes']),
			},
			visitsschema: visitsschema,
		};
	};

	const saveVisitBillingEncounter = (initalProps, formdata) => {
		const edited = _.reduce(
			formdata,
			function (result, value, key) {
				return _.isEqual(value, initalProps[key])
					? result
					: result.concat(key);
			},
			[]
		);

		if (edited && _.get(edited, 'length', 0) > 0) {
			const json = {
				data: {
					uuid: _.get(billingencounter, ['uuid']),
					encounter_uuid: _.get(encounter, ['uuid']),
				},
			};

			edited.map((e) => {
				const makeObjNull = {
					patient_fee_refund_reason: 1,
					patient_fee_refund_reason_other: 1,
				};

				if (_.has(formdata, e)) {
					json['data'][e] = _.get(formdata, e, '');
				} else {
					json['data'][e] = _.get(initalProps, [e]);
				}

				if (json['data'][e] == 'empty') {
					json['data'][e] = '';
				}

				if (e in makeObjNull && json['data'][e] == '') {
					json['data'][e] = null;
				}
			});
			if (json.data.hasOwnProperty('units')) delete json.data.units;
			if (json.data.hasOwnProperty('units_s0265_95'))
				delete json.data.units_s0265_95;
			return calleditbillingencouter(json)
				.then((data) => {
					getencouter(false);
					if (
						formdata['post_negative_minutes'] ==
						_.get(billingencounter, 'post_negative_minutes')
					) {
						enqueueSnackbar(
							'Billing Encounter successfully updated',
							{
								variant: 'info',
								anchorOrigin: {
									horizontal: 'right',
									vertical: 'bottom',
								},
							}
						);
					}
					return data;
				})
				.catch((error) => {
					enqueueSnackbar('Error in edit encounter', {
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
					throw error;
				});
		} else {
			return Promise.resolve({});
		}
	};

	const saveVisitEncounter = async (initalProps, formdata) => {
		const promiseall = [];
		const edited = _.reduce(
			formdata,
			function (result, value, key) {
				return _.isEqual(value, initalProps[key])
					? result
					: result.concat(key);
			},
			[]
		);

		if (edited && _.get(edited, 'length', 0) > 0) {
			const json = {
				data: {
					type: _.get(encounter, ['type']),
					uuid: _.get(encounter, ['uuid']),
				},
			};

			edited.map((e) => {
				if (_.has(formdata, e)) {
					json['data'][e] = _.get(formdata, e, '');
				} else {
					json['data'][e] = _.get(initalProps, [e]);
				}
				if (json['data'][e] == 'empty') {
					json['data'][e] = '';
				}
			});
			const promise2 = calleditencouter(json);
			promiseall.push(promise2);

			try {
				const result_1 = await Promise.all(promiseall);
				getencouter(false);
				enqueueSnackbar('Encounter successfully updated', {
					variant: 'info',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				return result_1;
			} catch (error) {
				enqueueSnackbar('Error in edit encounter', {
					variant: 'error',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				throw error;
			}
		} else return Promise.resolve({});
	};
	const handleReclaimEncounter = () => {
		setBillingViewLoading(true);
		reclaimEncounter();
	};
	const createPreauthorizeTestOrder = (data) => {
		const promiseall = [];
		const recommendValue =
			encounter.test_recommended === 'No'
				? 'PA'
				: encounter.test_recommended || 'PA';
		const encounterreq = {
			data: {
				type: encounter_type,
				uuid: encounter_uuid,
				test_recommended: recommendValue,
			},
		};
		const promise1 = calleditencouter(encounterreq);
		const promise2 = preauthorizationsTestOrder(encounter_uuid, data);

		promiseall.push(promise1);
		promiseall.push(promise2);

		return Promise.all(promiseall)
			.then((res) => {
				getencouter(false);
				callPreauthorizationTestOrders();
				enqueueSnackbar(
					'Pre-authoriztion Test Order updated successfully',
					{
						variant: 'info',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
				return res;
			})
			.catch((error) => {
				enqueueSnackbar('Error in Preauthorizations Test Order', {
					variant: 'error',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				throw error;
			});
	};

	const handleUpdateTestOrder = async (
		test_order_uuid,
		data,
		notedata,
		shippingAddressFormData,
		isGroupedTestOrder = false,
		updateGroupedTestOrderAfterSentToLab
	) => {
		try {
			if (isGroupedTestOrder) {
				const isMDAuth = false;
				const _data = data;
				if (notedata.length > 0) {
					_data['notes'] = notedata;
				}
				await calltestBulkUpdate(_data, isMDAuth);
				if (!_.isEmpty(shippingAddressFormData)) {
					await updatePatientAddress(shippingAddressFormData).then(
						() => {
							return getPatientDetail(patient_uuid, true);
						}
					);
				}
				getencouter(false);
				callTestOrderRequest();
				getOutreachsLog();
				enqueueSnackbar('Test Order updated successfully', {
					variant: 'info',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
			} else if (updateGroupedTestOrderAfterSentToLab.length > 0) {
				const _testOrderPromiseAll = [];
				_.map(updateGroupedTestOrderAfterSentToLab, (testorder) => {
					_testOrderPromiseAll.push(
						UpdateTestOrder(testorder.key, testorder.data)
					);
				});
				await Promise.all(_testOrderPromiseAll);
				if (!_.isEmpty(notedata)) {
					const _testOrderNotePromiseAll = [];
					_.map(updateGroupedTestOrderAfterSentToLab, (testorder) => {
						_testOrderNotePromiseAll.push(
							createTestOrderNote(testorder.key, notedata)
						);
					});
					await Promise.all(_testOrderNotePromiseAll);
				}
				getencouter(false);
				callTestOrderRequest();
				getOutreachsLog();
				enqueueSnackbar('Test Order updated successfully', {
					variant: 'info',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
			} else {
				await UpdateTestOrder(test_order_uuid, data);

				if (!_.isEmpty(notedata)) {
					await createTestOrderNote(test_order_uuid, notedata);
				}

				if (!_.isEmpty(shippingAddressFormData)) {
					await updatePatientAddress(shippingAddressFormData).then(
						() => {
							return getPatientDetail(patient_uuid, true);
						}
					);
				}

				getencouter(false);
				callTestOrderRequest();
				getOutreachsLog();
				enqueueSnackbar('Test Order updated successfully', {
					variant: 'info',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
			}
		} catch (error) {
			enqueueSnackbar('Error while updating Test Order', {
				variant: 'error',
				anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
			});
			throw error;
		}
	};

	const handleUpdatePreAuthTestOrder = (preauth_test_order_uuid, data) => {
		return updatePreAuthTestOrder(preauth_test_order_uuid, data)
			.then((res) => {
				getencouter(false);
				callPreauthorizationTestOrders();
				enqueueSnackbar(
					'Pre-authorization Test Order updated successfully',
					{
						variant: 'info',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
				return res;
			})
			.catch((error) => {
				enqueueSnackbar('Error in Test Order', {
					variant: 'error',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				throw error;
			});
	};

	const toggleChargeModal = () => {
		setShowChargeModal(!showChargeModal);
	};

	const togglePreAuthTestModal = () => {
		setShowPreAuthTestModal(!showPreAuthTestModal);
	};

	const getTestOrderNumber = () => {
		return encounterTestOrderRequest
			? encounterTestOrderRequest.length + 1
			: 1;
	};
	const saveTestOrderRequest = (
		testorderrequestdata,
		notedata,
		shippingAddressFormData,
		groupedTestOrder
	) => {
		const promiseall = [];
		const promise1 = createTestOrderRequest(
			encounter_uuid,
			testorderrequestdata,
			groupedTestOrder
		);
		const recommendValue =
			encounter.test_recommended === 'No'
				? 'Yes'
				: encounter.test_recommended || 'Yes';
		promiseall.push(promise1);

		if (_.get(encounterTestOrderRequest, 'length') == 0) {
			let encounterreq = {};
			encounterreq = {
				data: {
					type: encounter_type,
					uuid: encounter_uuid,
					test_recommended: recommendValue,
				},
			};
			const promise2 = calleditencouter(encounterreq);
			promiseall.push(promise2);
		}
		if (!_.isEmpty(shippingAddressFormData)) {
			const promise3 = updatePatientAddress(shippingAddressFormData);
			promiseall.push(promise3);
		}

		return Promise.all(promiseall)
			.then((result) => {
				if (!_.isEmpty(notedata)) {
					const test_order_uuid = _.get(
						result,
						['0', 'data', 'uuid'],
						''
					);

					const testOrders = _.get(
						result,
						['0', 'data', 'orders'],
						[]
					);
					if (testOrders.length > 0) {
						const _orderUUID = [];
						const isMDAuth = false;
						const orderStatus = _.get(
							testOrders,
							['0', 'order_status'],
							''
						);
						const statusReason = _.get(
							testOrders,
							['0', 'status_reason'],
							''
						);
						const orderQuestionnaire = _.get(
							testOrders,
							['0', 'order_questionnaire'],
							null
						);
						_.forEach(testOrders, function (value) {
							_orderUUID.push(value?.uuid);
						});

						const payload = {
							uuid: _orderUUID,
							order_status: orderStatus,
							status_reason: statusReason,
							notes: notedata,
							order_questionnaire: orderQuestionnaire,
						};

						return calltestBulkUpdate(payload, isMDAuth).then(
							() => {
								getencouter(false);
								callTestOrderRequest();
								if (!_.isEmpty(shippingAddressFormData)) {
									getPatientDetail(patient_uuid, true);
								}
							}
						);
					} else {
						return createTestOrderNote(
							test_order_uuid,
							notedata
						).then((result) => {
							getencouter(false);
							callTestOrderRequest();
							if (!_.isEmpty(shippingAddressFormData)) {
								getPatientDetail(patient_uuid, true);
							}
							enqueueSnackbar('Encounter successfully updated', {
								variant: 'info',
								anchorOrigin: {
									horizontal: 'right',
									vertical: 'bottom',
								},
							});
							return result;
						});
					}
				} else {
					getencouter(false);
					callTestOrderRequest();
					if (!_.isEmpty(shippingAddressFormData)) {
						getPatientDetail(patient_uuid, true);
					}
					enqueueSnackbar('Encounter successfully updated', {
						variant: 'info',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
					return result;
				}
			})
			.catch((error) => {
				enqueueSnackbar('Error in edit encounter', {
					variant: 'error',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				throw error;
			});
	};

	const editencouterOnHoldResultRelease = (encounterrequestdata) => {
		const encounterreq = {
			data: {
				type: encounter_type,
				uuid: encounter_uuid,
				hold_result_release: _.get(
					encounterrequestdata,
					['hold_result_release'],
					false
				),
			},
		};
		return calleditencouter(encounterreq).then(() => {
			getencouter(false);
			getOutreachsLog();
			callTestOrderRequest();
		});
	};

	const toggleHoldReleaseResultModal = () => {
		setShowHoldReleaseResultModal(!showHoldReleaseResultModal);
	};

	const handleFUDeclinedOutreachSubmit = (encounter_uuid, data) => {
		return followUpDeclinedOutreach(encounter_uuid, data)
			.then((res) => {
				if (
					_.get(res, ['data', 'data', 'ror_advanced'], true) == false
				) {
					setShowUnableStopOutreachModal(true);
				} else {
					getOutreachsLog();
					callTestOrderRequest();
					enqueueSnackbar(
						'Outreach mark as followup declined successfully.',
						{
							variant: 'info',
							anchorOrigin: {
								horizontal: 'right',
								vertical: 'bottom',
							},
						}
					);
				}
				return res;
			})
			.catch((err) => {
				enqueueSnackbar(
					'Error while outreach mark as followup declined.',
					{
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
				throw err;
			});
	};

	const compareFields = (currentData, initalData) => {
		const formdata = {};

		if (
			!_.isEqual(
				_.get(currentData, 'date_time'),
				_.get(initalData, 'contactDateTime')
			)
		)
			formdata['date_time'] = currentData['date_time'];
		if (
			!_.isEqual(
				_.get(currentData, 'contact_method'),
				_.get(initalData, ['contactMethod', 'name'])
			)
		)
			formdata['contact_method'] = currentData['contact_method'];
		if (
			!_.isEqual(
				_.get(currentData, 'type'),
				_.get(initalData, ['type', 'name'])
			)
		)
			formdata['type'] = currentData['type'];
		if (
			!_.isEqual(
				_.get(currentData, 'outcome'),
				_.get(initalData, ['outcome', 'name'])
			)
		)
			formdata['outcome'] = currentData['outcome'];
		if (_.get(currentData, 'outcome') == 'other')
			formdata['notes'] = currentData['notes'];
		if (_.get(currentData, 'type') == 'ror_outreach') {
			formdata['encounter_uuid'] = currentData['encounter_uuid'];
			formdata['outreach_sequence_id'] =
				currentData['outreach_sequence_id'];
		}
		return formdata;
	};

	const addOutreachHandleSubmit = (data) => {
		return addOutreach(data, patient_uuid)
			.then(() => {
				getOutreachsLog();
				enqueueSnackbar('Outreach saved successfully.', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			})
			.catch((err) => {
				enqueueSnackbar('Error in outreach.', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				throw err;
			});
	};

	const editOutreachHandleSubmit = (
		formdata,
		communication_uuid,
		initalProps
	) => {
		const updatedData = compareFields(formdata, initalProps);

		if (!_.isEmpty(updatedData)) {
			return updateOutreach(updatedData, patient_uuid, communication_uuid)
				.then(() => {
					getOutreachsLog();
					setLoading(true);
					enqueueSnackbar('Outreach update successfully.', {
						variant: 'info',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
				})
				.catch((err) => {
					enqueueSnackbar('Error in outreach.', {
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
					throw err;
				});
		} else {
			return Promise.resolve().then(() => {});
		}
	};

	const filterTestOrderRequest = () => {
		if (_.isArray(encounterTestOrderRequest)) {
			const _filterTestOrderRequest = [];
			_.map(encounterTestOrderRequest, (obj) => {
				if (obj?.orders) {
					(obj?.orders ?? '').map((order) => {
						_filterTestOrderRequest.push({
							testName: order?.genetic_test_display_name,
							outreachSeqNo: order?.outreach_seq_no,
							encounterUUID: encounter_uuid,
						});
					});
				} else {
					_filterTestOrderRequest.push({
						testName: obj?.genetic_test_display_name,
						encounterUUID: encounter_uuid,
						outreachSeqNo: obj?.outreach_seq_no,
					});
				}
			});
			return _filterTestOrderRequest;
		}
	};

	const isPatientFeeDisabled = () => {
		const { type } = encounter;

		return !(isBillingCoordinator && type === 'visit');
	};

	const closeUnableStopOutreachModal = () => {
		setShowUnableStopOutreachModal(false);
	};

	const visibleListModal = () => {
		setShowListModal(true);
	};

	const hideListModal = () => {
		setShowListModal(false);
	};

	const showRelatedEncounter =
		_.get(encounter, ['type'], '') === 'visit' &&
		(_.get(encounter, ['part_of', 'type'], '') === 'visit' ||
			_.get(encounter, ['parent_of', 'length'], 0) > 0);
	return (
		<Fragment>
			{
				<Fragment>
					<div className='encounter-sections'>
						{showRelatedEncounter && (
							<div className='encounter-section'>
								<div
									className={classnames(
										'encounter-accordian-header',
										{
											'encounter-accordian-header-expanded':
												isExpanded(
													'RelatedEncounterSegment'
												),
										}
									)}
									style={{
										cursor: isInEditMode(
											'RelatedEncounterSegment'
										)
											? 'auto'
											: 'pointer',
									}}
									onClick={() =>
										toggleSegment('RelatedEncounterSegment')
									}
								>
									<span className='accordian-title'>
										RELATED ENCOUNTER(S)
									</span>
									{!isInEditMode(
										'RelatedEncounterSegment'
									) && (
										<Fragment>
											<span className='accordian-icon'>
												<img
													src={imageExpandCollapse}
													style={{
														transform: isExpanded(
															'RelatedEncounterSegment'
														)
															? 'rotate(-90deg)'
															: 'rotate(90deg)',
														float: 'right',
													}}
													alt='expand-icon'
												/>
											</span>
										</Fragment>
									)}
								</div>
								<div
									className='encounter-accordian-body'
									style={{
										display: isExpanded(
											'RelatedEncounterSegment'
										)
											? 'block'
											: 'none',
									}}
								>
									{
										<RelatedEncounterInfoSegment
											{...relatedEncounterProps()}
										/>
									}
								</div>
							</div>
						)}

						<div className='encounter-section'>
							<div
								className={classnames(
									'encounter-accordian-header',
									{
										'encounter-accordian-header-expanded':
											isExpanded('VisitDetailSegment'),
									}
								)}
								style={{
									cursor: isInEditMode('VisitDetailSegment')
										? 'auto'
										: 'pointer',
								}}
								onClick={() =>
									toggleSegment('VisitDetailSegment')
								}
							>
								<span className='accordian-title'>
									VISIT DETAILS
								</span>
								{!isInEditMode('VisitDetailSegment') && (
									<Fragment>
										{isExpanded('VisitDetailSegment') &&
											!isAnyEditActive() && (
												<RBAC
													action={
														CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
													}
													yes={
														<span>
															<img
																src={editpencil}
																alt='edit-icon'
																className='edit-icon'
																onClick={makeEdited(
																	'VisitDetailSegment'
																)}
															/>
														</span>
													}
												/>
											)}
										<span className='accordian-icon'>
											<img
												src={imageExpandCollapse}
												style={{
													transform: isExpanded(
														'VisitDetailSegment'
													)
														? 'rotate(-90deg)'
														: 'rotate(90deg)',
													float: 'right',
												}}
												alt='expand-icon'
											/>
										</span>
									</Fragment>
								)}
							</div>
							<div
								className='encounter-accordian-body'
								style={{
									display: isExpanded('VisitDetailSegment')
										? 'block'
										: 'none',
								}}
							>
								{isInEditMode('VisitDetailSegment') ? (
									<RBAC
										action={
											CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
										}
										yes={
											<EditVisitDetailSegment
												{...visitDetailsProps()}
												saveVisitBillingEncounter={
													saveVisitBillingEncounter
												}
												saveVisitEncounter={
													saveVisitEncounter
												}
												cancel={() =>
													makeNonEdited(
														'VisitDetailSegment'
													)
												}
												icdCodes={icdCodes}
												icdCodesLoading={
													icdCodesLoading
												}
												icdCodesError={icdCodesError}
												icdCodesPlacement={
													icdCodesPlacement
												}
												getICDCodes={getICDCodes}
											/>
										}
									/>
								) : (
									<VisitDetailSegment
										{...visitDetailsProps()}
									/>
								)}
							</div>
						</div>

						<div className='encounter-section'>
							<div
								className={classnames(
									'encounter-accordian-header',
									{
										'encounter-accordian-header-expanded':
											isExpanded('TestOrderSegment'),
									}
								)}
								style={{
									cursor: isInEditMode('TestOrderSegment')
										? 'auto'
										: 'pointer',
								}}
								onClick={() =>
									toggleSegment('TestOrderSegment')
								}
							>
								<span className='accordian-title' id="test-order">
									TEST ORDER(S)
								</span>
								{!isInEditMode('TestOrderSegment') && (
									<Fragment>
										{isExpanded('TestOrderSegment') &&
											!isAnyEditActive() &&
											(_.get(
												encounter,
												['test_recommended'],
												''
											) == 'No' ||
												_.isEmpty(
													_.get(
														encounter,
														['test_recommended'],
														''
													)
												)) && (
												<RBAC
													action={
														CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
													}
													yes={
														<span>
															<img
																src={editpencil}
																alt='edit-icon'
																onClick={makeEdited(
																	'TestOrderSegment'
																)}
															/>
														</span>
													}
												/>
											)}
										<span className='accordian-icon'>
											<img
												src={imageExpandCollapse}
												style={{
													transform: isExpanded(
														'TestOrderSegment'
													)
														? 'rotate(-90deg)'
														: 'rotate(90deg)',
													float: 'right',
												}}
												alt='expand-icon'
											/>
										</span>
									</Fragment>
								)}
							</div>
							<div
								className='encounter-accordian-body'
								style={{
									display: isExpanded('TestOrderSegment')
										? 'block'
										: 'none',
								}}
							>
								{isInEditMode('TestOrderSegment') ? (
									<RBAC
										action={
											CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
										}
										yes={
											<EditTestOrderSegmentV2
												{...testOrdersProps()}
												saveVisitEncounter={
													saveVisitEncounter
												}
												LoadTestOrderPopupTestEnum={
													LoadTestOrderPopupTestEnum
												}
												nonlegacytestOrderPopupTestEnums={
													nonlegacytestOrderPopupTestEnums
												}
												nonlegacytestOrderPopupTestEnumsLoading={
													nonlegacytestOrderPopupTestEnumsLoading
												}
												LoadAlternateTestEnum={
													LoadAlternateTestEnum
												}
												nonlegacyalternatetestnameenums={
													nonlegacyalternatetestnameenums
												}
												cancel={() =>
													makeNonEdited(
														'TestOrderSegment'
													)
												}
												getICDCodes={getICDCodes}
												icdCodes={icdCodes}
												icdCodesLoading={
													icdCodesLoading
												}
												icdCodesError={icdCodesError}
												icdCodesPlacement={
													icdCodesPlacement
												}
												encounter={encounter}
												patientdetail={patientdetail}
												testOrderNumber={getTestOrderNumber()}
												user={user}
											/>
										}
									/>
								) : (
									<TestOrderSegmentV2
										{...testOrdersProps()}
										getICDCodes={getICDCodes}
										icdCodes={icdCodes}
										icdCodesLoading={icdCodesLoading}
										icdCodesError={icdCodesError}
										icdCodesPlacement={icdCodesPlacement}
										enqueueSnackbar={enqueueSnackbar}
										selectedICDCodes={_.get(
											encounter,
											'medical_codes',
											null
										)}
										labenums={_.get(
											visitsschema,
											['lab'],
											[]
										)}
										LoadTestOrderPopupTestEnum={
											LoadTestOrderPopupTestEnum
										}
										nonlegacytestOrderPopupTestEnums={
											nonlegacytestOrderPopupTestEnums
										}
										nonlegacytestOrderPopupTestEnumsLoading={
											nonlegacytestOrderPopupTestEnumsLoading
										}
										nonlegacyalternatetestnameenums={
											nonlegacyalternatetestnameenums
										}
										orderrequeststatuscascadeenums={
											orderrequeststatuscascadeenums
										}
										encounter={encounter}
										patientdetail={patientdetail}
										showChargeModal={showChargeModal}
										toggleChargeModal={toggleChargeModal}
										showPreAuthTestModal={
											showPreAuthTestModal
										}
										togglePreAuthTestModal={
											togglePreAuthTestModal
										}
										cancel={() =>
											makeNonEdited('TestOrderSegment')
										}
										updateTestOrder={handleUpdateTestOrder}
										testOrderNumber={getTestOrderNumber()}
										updatePreAuthTestOrder={
											handleUpdatePreAuthTestOrder
										}
										LoadAlternateTestEnum={
											LoadAlternateTestEnum
										}
										showHoldReleaseResultModal={
											showHoldReleaseResultModal
										}
										toggleHoldReleaseResultModal={
											toggleHoldReleaseResultModal
										}
										editencouterOnHoldResultRelease={
											editencouterOnHoldResultRelease
										}
										user={user}
										callTestOrderNotes={callTestOrderNotes}
										callTestOrderRequest={
											callTestOrderRequest
										}
										getOutreachsLog={getOutreachsLog}
									/>
								)}
							</div>
						</div>

						<div className='encounter-section'>
							<div
								className={classnames(
									'encounter-accordian-header',
									{
										'encounter-accordian-header-expanded':
											isExpanded('ExtProvidersSegment'),
									}
								)}
								style={{
									cursor: isInEditMode('ExtProvidersSegment')
										? 'auto'
										: 'pointer',
								}}
								onClick={() =>
									toggleSegment('ExtProvidersSegment')
								}
							>
								<span className='accordian-title'>
									EXTERNAL PROVIDERS
								</span>
								{!isInEditMode('ExtProvidersSegment') && (
									<Fragment>
										<span className='accordian-icon'>
											<img
												src={imageExpandCollapse}
												style={{
													transform: isExpanded(
														'ExtProvidersSegment'
													)
														? 'rotate(-90deg)'
														: 'rotate(90deg)',
													float: 'right',
												}}
												alt='expand-icon'
											/>
										</span>
									</Fragment>
								)}
							</div>
							<div
								className='encounter-accordian-body'
								style={{
									display: isExpanded('ExtProvidersSegment')
										? 'block'
										: 'none',
								}}
							>
								<Loading
									className='ext-providers-wrapper'
									loading={_.get(
										externalProviderProps,
										'loading',
										false
									)}
								>
									<div className='ext-providers'>
										{_.get(
											externalProviderProps,
											['externalProviders'],
											[]
										).map(
											(el) =>
												el && (
													<ProviderLinked
														linkedType={'encounter'}
														provider={el}
														loading={_.get(
															externalProviderProps,
															'loading',
															false
														)}
														editLinkInfo={_.get(
															externalProviderProps,
															'editLinkInfo'
														)}
														editProviderInfo={_.get(
															externalProviderProps,
															'editProviderInfo'
														)}
														unlinkProviderInfo={_.get(
															externalProviderProps,
															'unlinkProviderInfo'
														)}
														linkProviderToEncounter={_.get(
															externalProviderProps,
															'linkProviderToEncounter'
														)}
														providerSpecialties={
															_.get(
																externalProviderProps,
																'providerSpecialties',
																null
															) || []
														}
														activeRole={activeRole}
													/>
												)
										)}
									</div>
									{!_.isEmpty(
										_.get(
											externalProviderProps,
											'externalProviders',
											[]
										)
									) ? (
										<RBAC
											action={
												CARE_PATIENTS_PATIENT_ENCOUNTER_PROVIDER_OPERATIONS
											}
											yes={
												<ProviderAddAnother
													click={_.get(
														externalProviderProps,
														'handleProviderLinkDialog'
													)}
													title='Link Another Provider'
												/>
											}
										/>
									) : (
										<RBAC
											action={
												CARE_PATIENTS_PATIENT_ENCOUNTER_PROVIDER_OPERATIONS
											}
											yes={
												<ProviderAddAnother
													title='Link External Provider'
													click={_.get(
														externalProviderProps,
														'handleProviderLinkDialog'
													)}
												/>
											}
										/>
									)}
								</Loading>
							</div>
						</div>

						<div className='encounter-section'>
							<div
								className={classnames(
									'encounter-accordian-header',
									{
										'encounter-accordian-header-expanded':
											isExpanded('PxInfoSegment'),
									}
								)}
								style={{
									cursor: isInEditMode('PxInfoSegment')
										? 'auto'
										: 'pointer',
								}}
								onClick={() => toggleSegment('PxInfoSegment')}
							>
								<span className='accordian-title'>
									PGX INFORMATION
								</span>
								{!isInEditMode('PxInfoSegment') && (
									<Fragment>
										{isExpanded('PxInfoSegment') &&
											!isAnyEditActive() && (
												<RBAC
													action={
														CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
													}
													yes={
														<span>
															<img
																src={editpencil}
																alt='edit-icon'
																onClick={makeEdited(
																	'PxInfoSegment'
																)}
															/>
														</span>
													}
												/>
											)}
										<span className='accordian-icon'>
											<img
												src={imageExpandCollapse}
												style={{
													transform: isExpanded(
														'PxInfoSegment'
													)
														? 'rotate(-90deg)'
														: 'rotate(90deg)',
													float: 'right',
												}}
												alt='expand-icon'
											/>
										</span>
									</Fragment>
								)}
							</div>
							<div
								className='encounter-accordian-body'
								style={{
									display: isExpanded('PxInfoSegment')
										? 'block'
										: 'none',
								}}
							>
								{isInEditMode('PxInfoSegment') ? (
									<RBAC
										action={
											CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
										}
										yes={
											<EditPGXSegment
												{...pgxProps()}
												saveVisitEncounter={
													saveVisitEncounter
												}
												cancel={() =>
													makeNonEdited(
														'PxInfoSegment'
													)
												}
											/>
										}
									/>
								) : (
									<PGXSegment {...pgxProps()} />
								)}
							</div>
						</div>

						<div className='encounter-section'>
							<div
								className={classnames(
									'encounter-accordian-header',
									{
										'encounter-accordian-header-expanded':
											isExpanded('RORSegment'),
									}
								)}
								style={{ cursor: 'pointer' }}
								onClick={() => toggleSegment('RORSegment')}
							>
								<span className='accordian-title'>
									RETURN OF RESULTS (RoR) FOLLOW-UP
								</span>
								<span className='accordian-icon'>
									<img
										src={imageExpandCollapse}
										style={{
											transform: isExpanded('RORSegment')
												? 'rotate(-90deg)'
												: 'rotate(90deg)',
											float: 'right',
										}}
										alt='expand-icon'
									/>
								</span>
							</div>
							<div
								className='encounter-accordian-body'
								style={{
									display: isExpanded('RORSegment')
										? 'block'
										: 'none',
								}}
							>
								<RORSegment {...rorProps()} />
							</div>
						</div>

						<RBAC
							action={CARE_PATIENTS_PATIENT_ENCOUNTER_BILLING}
							yes={
								<div className='encounter-section'>
									<div
										className={classnames(
											'encounter-accordian-header',
											{
												'encounter-accordian-header-expanded':
													isExpanded(
														'BillingSegment'
													),
											}
										)}
										style={{
											cursor: isInEditMode(
												'BillingSegment'
											)
												? 'auto'
												: 'pointer',
										}}
										onClick={() =>
											toggleSegment('BillingSegment')
										}
									>
										<span className='accordian-title'>
											BILLING
										</span>
										{!isInEditMode('BillingSegment') && (
											<Fragment>
												{isExpanded('BillingSegment') &&
													!isAnyEditActive() && (
														<RBAC
															action={
																CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
															}
															yes={
																<span>
																	<img
																		src={
																			editpencil
																		}
																		alt='edit-icon'
																		onClick={makeEdited(
																			'BillingSegment'
																		)}
																	/>
																</span>
															}
														/>
													)}
												<span className='accordian-icon'>
													<img
														src={
															imageExpandCollapse
														}
														style={{
															transform:
																isExpanded(
																	'BillingSegment'
																)
																	? 'rotate(-90deg)'
																	: 'rotate(90deg)',
															float: 'right',
														}}
														alt='expand-icon'
													/>
												</span>
											</Fragment>
										)}
									</div>
									<div
										className='encounter-accordian-body'
										style={{
											display: isExpanded(
												'BillingSegment'
											)
												? 'block'
												: 'none',
										}}
									>
										{isInEditMode('BillingSegment') ? (
											<RBAC
												action={
													CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
												}
												yes={
													<EditBillingSegment
														{...billingProps()}
														saveVisitBillingEncounter={
															saveVisitBillingEncounter
														}
														saveVisitEncounter={
															saveVisitEncounter
														}
														cancel={() =>
															makeNonEdited(
																'BillingSegment'
															)
														}
														isBillingCoordinator={
															isBillingCoordinator
														}
														isPatientFeeDisabled={isPatientFeeDisabled()}
													/>
												}
											/>
										) : (
											<BillingSegment
												{...billingProps()}
												loading={billingViewLoading}
											/>
										)}
									</div>
								</div>
							}
						/>

						<RBAC
							action={
								CARE_PATIENTS_PATIENT_ENCOUNTER_ADDITIONAL_INFO
							}
							yes={
								<div className='encounter-section'>
									<div
										className={classnames(
											'encounter-accordian-header',
											{
												'encounter-accordian-header-expanded':
													isExpanded(
														'AdditionalSegment'
													),
											}
										)}
										style={{
											cursor: isInEditMode(
												'AdditionalSegment'
											)
												? 'auto'
												: 'pointer',
										}}
										onClick={() =>
											toggleSegment('AdditionalSegment')
										}
									>
										<span className='accordian-title'>
											ADDITIONAL INFO
										</span>
										{!isInEditMode('AdditionalSegment') && (
											<Fragment>
												<span className='accordian-icon'>
													<img
														src={
															imageExpandCollapse
														}
														style={{
															transform:
																isExpanded(
																	'AdditionalSegment'
																)
																	? 'rotate(-90deg)'
																	: 'rotate(90deg)',
															float: 'right',
														}}
														alt='expand-icon'
													/>
												</span>
											</Fragment>
										)}
									</div>
									<div
										className='encounter-accordian-body'
										style={{
											display: isExpanded(
												'AdditionalSegment'
											)
												? 'block'
												: 'none',
										}}
									>
										{isInEditMode('AdditionalSegment') ? (
											<RBAC
												action={
													CARE_PATIENTS_ENCOUNTER_ADDINFOSEC_EDIT
												}
												yes={
													<EditAdditionalSegment
														{...additionalInfoProps()}
														saveVisitEncounter={
															saveVisitEncounter
														}
														cancel={() =>
															makeNonEdited(
																'AdditionalSegment'
															)
														}
													/>
												}
											/>
										) : (
											<AdditionalSegment
												{...additionalInfoProps()}
											/>
										)}
									</div>
								</div>
							}
						/>
					</div>
				</Fragment>
			}
		</Fragment>
	);
};
export default VisitEncounterDetail;
