//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//Components
import Tooltip from '../Common/Tooltip';

//Other Libraries
import moment from 'moment';

//Utils
import { determineWidthOverflow } from '../../utils.js';

class SummaryPatientInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.renderDOB = this.renderDOB.bind(this);
	}
	//functions

	renderDOB() {
		const patient = _.get(this, 'props.patient', {});
		let date_of_birth = '';
		if (_.get(patient, 'dob') && _.get(patient, 'dob') != null) {
			date_of_birth = (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div>
						{moment(_.get(patient, 'dob')).format('MMM D, YYYY')}
					</div>
					<div>
						{moment().diff(patient.dob, 'years') + ' yrs '}(
						{moment(_.get(patient, 'dob')).format('MM/DD/YYYY')})
					</div>
				</div>
			);
		} else {
			date_of_birth = <div>DOB Unknown</div>;
		}
		return date_of_birth;
	}

	findRefProgram(name) {
		if (!name) {
			return undefined;
		}

		const programs = _.get(this.props, 'referralPrograms.data', []);
		return programs.find((it) => it.display_name === name);
	}

	render() {
		const patient = _.get(this, 'props.patient', null);
		const emailClass = this.props.determineEmailStatus(
			_.get(this, 'props.patient.email', '') || ''
		)
			? ' emailAlertText'
			: '';
		const referralProgram = this.findRefProgram(
			_.get(patient, 'referral_program')
		);

		if (patient)
			return (
				<div className='scheduleAppointmentSideContainerSection'>
					<div className='scheduleAppointmentSideContainerName'>
						{patient.last_name}, {patient.first_name}
					</div>
					<div
						className={
							'scheduleAppointmentSideContainerPatientText' +
							emailClass
						}
					>
						{patient.email}
					</div>
					<div className='scheduleAppointmentSideContainerPatientText'>
						{patient.phone
							? !_.isNil(patient.phone)
								? patient.phone
								: 'Phone number not available.'
							: 'Phone number not available.'}
					</div>
					<div
						className='scheduleAppointmentSideContainerPatientText scheduleAppointmentSideContainerRow'
						style={{
							display: 'flex',
							flexDirection: 'row',
							width: '100%',
							justifyContent: 'space-between',
							whiteSpace: 'pre-wrap',
						}}
					>
						<div className='scheduleAppointmentSideContainerHalf'>
							{this.renderDOB()}
						</div>
						<div
							className='scheduleAppointmentSideContainerHalf'
							style={{ alignItems: 'stretch' }}
						>
							<Tooltip
								content={patient.referral_program}
								placement='top-start'
								disabled={determineWidthOverflow(
									this.referralProgramDiv
								)}
								className='tooltipWidth'
							>
								<div
									style={{
										fontFamily: 'RionaSans-Bold',
										textOverflow: 'ellipsis',
										maxWidth: '100%',
										overflowX: 'hidden',
										whiteSpace: 'nowrap',
									}}
									ref={(el) => (this.referralProgramDiv = el)}
								>
									{patient.referral_program}
								</div>
							</Tooltip>
						</div>
					</div>
					<div
						className='scheduleAppointmentSideContainerPatientText'
						style={{
							marginTop: '1rem',
						}}
					>
						<i className='el-icon-date'></i>&nbsp;
						{_.get(
							referralProgram,
							'scheduling_platform'
						) === 'Pioneer'
							? 'Pioneer Scheduling Platform'
							: 'VSee Scheduling Platform'}
					</div>
				</div>
			);
	}
}

export default SummaryPatientInfo;
