//React & Redux
import React, { useState, useEffect, Fragment } from 'react';

//Lodash
import get from 'lodash/get';

//UI Libraries
import { Loading } from 'gm-element-react';

const ContentReviewedByPatient = (props) => {
	const {
		encounterUuid,
		getAllContentViewedByPatient,
		contentViewedByPatient,
		contentViewedByPatientLoading,
	} = props;

	useEffect(() => {
		if (encounterUuid) {
			getAllContentViewedByPatient(encounterUuid);
		}
	}, []);
	const loading = contentViewedByPatientLoading;

	return (
		<div className='encounterContentReviewedContainer'>
			{loading ? (
				<Loading loading={true} style={{ left: 'calc(50% - 50px)' }} />
			) : (
				<React.Fragment>
					{Array.isArray(contentViewedByPatient) &&
						contentViewedByPatient.map((el, i) => {
							return (
								<div className='encounterContentReviewedItem'>
									{i + 1}){' '}
									{get(el, 'page_title', '--') || '--'}
								</div>
							);
						})}
				</React.Fragment>
			)}
		</div>
	);
};

export default ContentReviewedByPatient;
