//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import { GetSelectvalue } from '../Common/utilsfunctions';

//Other Libraries
import classnames from 'classnames';

const sortSCPClinicalInfo = (scpClinicalInfo) => {
	return _.orderBy(scpClinicalInfo, ['ranking'], ['asc']);
};

const getStyle = (styles) => {
	return {
		fontWeight: styles && styles.text,
		color: styles && styles.color,
	};
};

const PatientClinicalInfo = (props) => {
	const showAboutSection = _.get(props, 'showAboutSection', true);
	const scpClinicalInfo = sortSCPClinicalInfo(
		_.get(props, 'scpClinicalInfo', [])
	);

	const l = (scpClinicalInfo && scpClinicalInfo.length) || 0;
	const twoChunks = _.chunk(scpClinicalInfo, Math.ceil(l / 2));

	return (
		<div className='pci-encounter-accordian-body'>
			{!_.isEmpty(twoChunks) && (
				<div className='pci-segment-row'>
					<div className='pci-segment-label-row'>
						<div className='pci-encounter-field-header'>
							PATIENT REPORTS
						</div>
					</div>
					<div
						className={classnames(
							'pci-segment-list',
							!showAboutSection ? 'no-border' : ''
						)}
					>
						{twoChunks &&
							twoChunks.map((data) => (
								<ul className='pci-list-segment-col'>
									{data &&
										data.map((d) => (
											<li
												style={
													!_.isEmpty(d.style)
														? getStyle(d.style)
														: {}
												}
											>
												{d.text}
											</li>
										))}
								</ul>
							))}
					</div>
				</div>
			)}

			{showAboutSection && (
				<div
					style={_.isEmpty(twoChunks) ? { paddingTop: 0 } : {}}
					className='pci-segment-row requested-test-segment'
				>
					<div className='segment-label-row'>
						<div className='pci-segment-label-row'>
							<div className='pci-encounter-field-header'>
								ABOUT THE REQUESTED TEST
							</div>
						</div>
					</div>
					<div className='pci-segment-content-row'>
						<div className='pci-left-segment-content'>
							<div className='pci-segment-content-header'>
								Test Name
							</div>
							<div className='pci-segment-content-bottom'>
								{GetSelectvalue(
									props,
									['encounter', 'test_name'],
									_.get(props, ['testnameenums'], [])
								)}
							</div>
						</div>
						<div className='pci-right-segment-content'>
							<div className='pci-segment-content-header'>
								Lab
							</div>
							<div className='pci-segment-content-bottom'>
								{GetSelectvalue(
									props,
									['encounter', 'lab'],
									_.get(props, ['visitsschema', 'lab'], [])
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default PatientClinicalInfo;
