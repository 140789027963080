//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';
import _ from 'lodash';

//Components
import Tooltip from '../Common/Tooltip';

class SummaryPayment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		const payment = get(this, 'props.payment', {});
		const buttonClass = this.props.nonzerobookbtnenabled
			? 'scheduleAppointmentSideContainerCheckoutBtn modalButtonPurpleDisabled'
			: 'scheduleAppointmentSideContainerCheckoutBtn';
		return (
			<React.Fragment>
				<div className='scheduleAppointmentSideContainerSection summary-payment-container'>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							marginBottom: '20px',
						}}
					>
						<div style={{ color: '#262838', fontSize: '14px' }}>
							{this.props.useInsurance
								? 'Visit Deposit: '
								: 'Visit Fee: '}
						</div>
						<div className='scheduleAppointmentSideContainerPayment'>{`$${payment}`}</div>
					</div>
					{this.props.useInsurance && (
						<div className='insuranceVisitDepositSubSection'>
							After the patient’s session, Genome Medical will
							submit a claim to the patient’s insurance on their
							behalf. Once the claim is processed, taking into
							account any applicable co-insurance and deductible,
							Genome Medical may follow up directly with the
							patient to collect any outstanding payments.
						</div>
					)}
					{this.props.isZeroPriceConsultation() == true && (
						<div
							className='scheduleAppointmentSideContainerCheckoutBtn'
							onClick={this.props.ZeroFeeBookAppointment}
						>
							Book Visit
						</div>
					)}

					{this.props.isZeroPriceConsultation() == false &&
					_.get(this, ['props', 'activeItem'], -1) == 2 ? (
						<Tooltip
							content='Please fill in all the required fields'
							placement='top'
							disabled={!this.props.nonzerobookbtnenabled}
						>
							<div
								className={buttonClass}
								onClick={
									this.props.nonzerobookbtnenabled
										? () => {}
										: this.props.NonZeroFeeBookAppointment
								}
								disabled={this.props.nonzerobookbtnenabled}
							>
								Book Visit
							</div>
						</Tooltip>
					) : null}
				</div>
			</React.Fragment>
		);
	}
}

export default SummaryPayment;
