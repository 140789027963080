//React & Redux
import React from 'react';

//UI Libraries
import { Dialog, Button } from 'gm-element-react';

//Styles
import './selfPayModal.css';

class SelfPayModal extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { companyName, close, click } = this.props;
		return (
			<div className='self-pay-dialog-container'>
				<Dialog
					className='self-pay-dialog'
					title={<span className='title'>Non-insurance</span>}
					size='large'
					visible={true}
					lockScroll={false}
					showClose={false}
					onCancel={close}
				>
					<Dialog.Body>
						<div className='description'>
							You have filled in,{' '}
							<span className='bold pre'>{companyName}</span> for{' '}
							<span className='bold'>
								Health Insurance Company Name
							</span>{' '}
							field. We will direct you to the non-insurance
							payment page to input your payment information.
						</div>
					</Dialog.Body>
					<Dialog.Footer className='dialog-footer'>
						<Button type='primary' onClick={click}>
							Continue
						</Button>
					</Dialog.Footer>
				</Dialog>
			</div>
		);
	}
}

export default SelfPayModal;
