//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import arrowIcon from './../../assets/chevron-appointment-right.svg';

//Lodash
import get from 'lodash/get';

class SingleSelectPill extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.handleClick = this.handleClick.bind(this);
	}
	//functions

	componentDidMount() {
		if (get(this, 'pillElement', null)) {
			this.props.setPillElement(this.pillElement);
		}
	}

	handleClick() {
		const modalOpen = get(this, 'props.modalOpen', false);
		modalOpen ? this.props.closeModal() : this.props.openModal();
	}

	render() {
		const modalOpen = get(this, 'props.modalOpen', false);
		const containerClassName = modalOpen
			? 'selectPillContainer selectPillContainerActive'
			: 'selectPillContainer';
		return (
			<div
				data-testId='single-select-pill'
				className={containerClassName}
				onClick={this.handleClick}
				ref={(el) => (this.pillElement = el)}
			>
				<span className='selectPillText'>
					{get(this, 'props.label', '') || '--'}
				</span>
				<ReactSVG
					src={arrowIcon}
					className='selectPillArrow'
					style={{
						transform: `rotate(${modalOpen ? '270deg' : '90deg'})`,
						left: modalOpen ? '2px' : '6px',
						bottom: modalOpen ? '-2px' : '2px',
					}}
				/>
			</div>
		);
	}
}

export default SingleSelectPill;
