import axios from 'axios';
import get from 'lodash/get';
import { hydrateTestOrder } from '../transformers/patients/testOrderTransformer';
import geneService from './Genes';
import _ from 'lodash';
import { arrayToString } from './../utils.js';

class Tests {
	getTests = (data) => {
		if (data) {
			for (const key in data) data[key] = arrayToString(data[key]);
		}
		return axios.get('/api/genetic-tests', {
			params: {
				offset: get(data, 'offset', 0),
				tag: get(data, 'tag', ''),
				test_type: get(data, 'test_type', ''),
				lab: get(data, 'lab', ''),
				currently_offered: get(data, 'currently_offered', ''),
				order_by: get(data, 'order_by', ''),
				order_type: get(data, 'order_type', ''),
				limit: get(data, 'limit', ''),
			},
		});
	};

	getPatientTests = (data) => {
		return axios.get(
			`/api/patients/${data.patient_uuid}/genetic-tests/gene-test-orders`,
			{
				params: {
					offset: get(data, 'offset', 0),
					tag: get(data, 'tag', ''),
					test_type: get(data, 'test_type', ''),
					lab: get(data, 'lab', ''),
					currently_offered: get(data, 'currently_offered', ''),
					order_by: get(data, 'order_by', ''),
					order_type: get(data, 'order_type', ''),
					limit: get(data, 'limit', '25'),
					mlo: get(data, 'mlo', '0'),
				},
			}
		);
	};

	getTestDetail = (uuid) => {
		return axios.get(`/api/genetic-tests/${uuid}`);
	};

	getOrderTestDetail(uuid) {
		return axios
			.get(`/api/genetic-tests/gene-test-orders/${uuid}`)
			.then((data) => {
				const testResult = _.get(data, ['data', 'test_results'], []);
				if (_.isArray(testResult)) {
					const temptestResult = _.filter(testResult, function (o) {
						return o.genetic_test_genes_enums_id != null;
					});
					const geneIDs = temptestResult.map((a) =>
						parseInt(a.genetic_test_genes_enums_id)
					);
					const geneEnumsReq = {
						geneIDList: geneIDs,
						uuid: data.data.genetic_test_uuid,
					};
					return geneService
						.getGeneticResultGenes_MultipleID(geneEnumsReq)
						.then((generes) => {
							const genelist = _.get(
								generes,
								['data', 'data'],
								null
							);
							if (_.isArray(genelist)) {
								return (
									data &&
									hydrateTestOrder(data.data, genelist)
								);
							}
							return data && hydrateTestOrder(data.data);
						})
						.catch(() => data && hydrateTestOrder(data.data));
				} else {
					return data && hydrateTestOrder(data.data);
				}
			});
	}

	getTestTypes = () => {
		return axios.get('/api/genetic-tests/types');
	};

	getTags = () => {
		return axios.get('/api/genetic-tests/tags');
	};

	getOtherEnums = () => {
		return axios.get('/api/enums/genetic-test/');
	};

	getTestAuthEnums = (data) => {
		return axios.post(
			'/api/enums/genetic-test/genetic-test-orders/search',
			data
		);
	};

	updateTest = async (data) => {
		const response = await axios.put(
			'/api/genetic-tests/' + data.uuid,
			data.payload
		);
		return response;
	};

	createTest = async (data) => {
		const response = await axios.post(
			'/api/genetic-tests/create',
			data.payload
		);
		return response;
	};

	resetTest = async (uuid, data) => {
		const response = await axios.put(
			`/api/genetic-tests/gene-test-orders/${uuid}`,
			data
		);
		return response;
	};

	getGeneticTests = () => {
		return axios.get('/api/me/enums/genetic-tests');
	};
}

export default new Tests();
