const storage = localStorage || sessionStorage;

class StorageService {
	setRedirectURL = (value) => {
		storage.setItem('REDIRECTURL', value);
	};
	RedirectURL = () => {
		return storage.getItem('REDIRECTURL');
	};

	CLEARRedirectURL = () => {
		storage.removeItem('REDIRECTURL');
	};
}
export function getAccessToken() {
	return storage.getItem('gmi-token');
}

export function setAccessToken(value) {
	storage.setItem('gmi-token', value);
}

export function clearAccessToken() {
	storage.removeItem('gmi-token');
}

export default new StorageService();
