//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import {
	encounterFieldDisplay,
	getEncounterSpecificTime,
	EncounterDateFieldsDisplay,
	GetMultiSelectvalue,
	GetSelectvalue,
	getProviderName,
	getProviderIcon,
	encounterFreeTextFieldDisplay,
	getVisitIndicationTagDisplay,
	addVisitIndicationDisplayNames,
} from '../Common/utilsfunctions';

//Components
import ICDTag from './../../../ChargeInformation/ICDTag.js';
import ListModal from './../../../../Common/ListModal.js';

import './VisitDetailSegment.scss';

const VisitDetailSegment = (props) => {
	const pioneerAppointmentId = _.get(
		props,
		'encounter.pioneer_appointment_id'
	);

	let medicalCodes = get(props, 'encounter.medical_codes', []);
	medicalCodes = medicalCodes.slice().sort((a, b) => {
		if (get(a, 'code_name', '') < get(b, 'code_name', '')) return -1;
		else return 1;
	});
	const medicalCodesDisplay =
		medicalCodes.length > 0
			? medicalCodes.map((el) => {
					return (
						<ICDTag
							icdCode={el}
							toggleCodeSelection={() => {}}
							hasX={false}
							key={el.uuid}
						/>
					);
				})
			: '--';

	const visitIndicationsEnum = get(props, 'visitIndicationsEnum', []);
	let visitIndications = addVisitIndicationDisplayNames(
		get(props, 'encounter.visit_indications', []),
		visitIndicationsEnum
	);
	visitIndications = visitIndications.slice().sort((a, b) => {
		return get(a, 'visit_indication_name', '') <
			get(b, 'visit_indication_name', '')
			? -1
			: 1;
	});
	const visitIndicationsLength = visitIndications.length;
	const firstThree = visitIndications.slice(0, 3);

	const visitIndicationsDisplay =
		visitIndicationsLength > 0
			? firstThree.map((el, i) => {
					const last = i === firstThree.length - 1;
					const viewAll =
						visitIndicationsLength > 3 && last ? (
							<div
								className='purpleText purpleTextSmall'
								onClick={props.showListModal}
								style={{ marginLeft: 'auto' }}
							>
								View all ({visitIndicationsLength})
							</div>
						) : (
							''
						);
					return (
						<React.Fragment>
							<ICDTag
								icdCode={el}
								altDisplayKey='display_name'
								toggleCodeSelection={() => {}}
								tagDisplayFunc={getVisitIndicationTagDisplay}
								longTagVariant={true}
								hasX={false}
								key={el.uuid}
							/>
							{viewAll}
						</React.Fragment>
					);
				})
			: '--';

	const visitIndicationTagsHTML = visitIndications.map((tag) => {
		return (
			<ICDTag
				icdCode={tag}
				altDisplayKey='display_name'
				toggleCodeSelection={() => {}}
				tagDisplayFunc={getVisitIndicationTagDisplay}
				longTagVariant={true}
				hasX={false}
			/>
		);
	});
	return (
		<div className='visit-detail-segment'>
			{props.showListModalFlag && (
				<div className='popupModal'>
					<ListModal
						htmlContent={visitIndicationTagsHTML}
						title={`All Indications (${visitIndicationsLength})`}
						close={props.hideListModal}
					/>
				</div>
			)}
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Scheduling POC
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Visit State
						</div>
					</div>

					<div className='segment-col'>
						<div className='encounter-field-header'>
							Visit Timezone
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Relationship To Patient
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Date & Time Of Service
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFreeTextFieldDisplay(props, [
								'encounter',
								'scheduling_poc',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{_.get(
								props,
								['encounter', 'state_of_visit'],
								'--'
							)}
						</div>
					</div>

					<div className='segment-col'>
						<div className='encounter-field-value'>
							{_.get(props, ['encounter', 'timezone'], '--')}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'relationship_to_patient'],
								_.get(
									props,
									['visitsschema', 'relationship_to_patient'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{getEncounterSpecificTime(
								_.get(
									props,
									['encounter', 'date_of_service'],
									''
								),
								_.get(props, ['encounter', 'timezone'], '')
							)}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							TM Platform
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Consultation Type
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Requested Specialty
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Visit Provider
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Visit Status
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'encounter',
								'tm_platform',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFreeTextFieldDisplay(props, [
								'encounter',
								'consultation_type',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'encounter',
								'vsee_specialty',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{getProviderIcon(props, [
								'encounter',
								'visit_provider',
							]) != null && (
								<img
									src={getProviderIcon(props, [
										'encounter',
										'visit_provider',
									])}
									alt=''
								/>
							)}
							{getProviderName(
								props,
								['encounter', 'visit_provider'],
								_.get(props, ['visit_providerenums'], [])
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'visit_status'],
								_.get(
									props,
									['visitsschema', 'visit_status'],
									[]
								)
							)}
							{/*encounterFieldDisplay(props,['encounter','visit_status'])*/}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Records Requested
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Records Received
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Referring Provider (RP)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							RP Contact Info
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							RP Recommended Test
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'records_requested'],
								_.get(
									props,
									['visitsschema', 'records_requested'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'records_received'],
								_.get(
									props,
									['visitsschema', 'records_received'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'encounter',
								'referring_provider_name',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'encounter',
								'referring_provider_fax_email',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'encounter',
								'requested_test_via_provider',
							])}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Provider Indicated Specialty
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							ICD-10 Code(s) (Visit)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Indications
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Escalated to supervising physician?
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Supervising physician
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{_.get(props, [
								'encounter',
								'provider_indicated_specialty',
							]) == null
								? encounterFieldDisplay(props, [
										'encounter',
										'vsee_specialty',
									])
								: encounterFieldDisplay(props, [
										'encounter',
										'provider_indicated_specialty',
									])}
						</div>
					</div>
					<div
						className='segment-col'
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
						}}
					>
						{medicalCodesDisplay}
					</div>
					<div
						className='segment-col'
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
						}}
					>
						{visitIndicationsDisplay}
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{_.get(props, ['encounter', 'is_escalated'], false)
								? 'Yes'
								: 'No'}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'supervising_physician'],
								_.get(
									props,
									['visitsschema', 'supervising_physician'],
									[]
								)
							)}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Direct Clinical Care Time (minutes)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Pre-Session Indirect Time (minutes)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Post-Session Indirect Time (minutes)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Post-Test Result CAP Time Spent (minutes)
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'billingencounter',
								'direct_cc_minutes',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'billingencounter',
								'indirect_cc_minutes',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'billingencounter',
								'post_session_indirect_cc_minutes',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'billingencounter',
								'post_negative_minutes',
							])}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Visit CAP Completed Date
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Visit CAP sent to patient on
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Visit CAP Sent To PCP On
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'encounter',
								'initial_cap_completed_date',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{!_.isNil(
								_.get(props, [
									'encounter',
									'initial_visit_summary_sent',
								])
							) &&
							!_.isNil(
								_.get(props, [
									'encounter',
									'visit_summary_release_method_patient',
								])
							) &&
							!_.isEmpty(
								_.get(props, [
									'encounter',
									'initial_visit_summary_sent',
								])
							) &&
							!_.isEmpty(
								_.get(props, [
									'encounter',
									'visit_summary_release_method_patient',
								])
							)
								? EncounterDateFieldsDisplay(props, [
										'encounter',
										'initial_visit_summary_sent',
									]) +
									' via ' +
									GetMultiSelectvalue(
										props,
										[
											'encounter',
											'visit_summary_release_method_patient',
										],
										_.get(
											props,
											[
												'visitsschema',
												'visit_summary_release_method_patient',
											],
											[]
										)
									)
								: '--'}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{!_.isNil(
								_.get(props, [
									'encounter',
									'visit_summary_release_date_pcp',
								])
							) &&
							!_.isNil(
								_.get(props, [
									'encounter',
									'visit_summary_release_method_pcp',
								])
							) &&
							!_.isEmpty(
								_.get(props, [
									'encounter',
									'visit_summary_release_date_pcp',
								])
							) &&
							!_.isEmpty(
								_.get(props, [
									'encounter',
									'visit_summary_release_method_pcp',
								])
							)
								? EncounterDateFieldsDisplay(props, [
										'encounter',
										'visit_summary_release_date_pcp',
									]) +
									' via ' +
									GetMultiSelectvalue(
										props,
										[
											'encounter',
											'visit_summary_release_method_pcp',
										],
										_.get(
											props,
											[
												'visitsschema',
												'visit_summary_release_method_pcp',
											],
											[]
										)
									)
								: '--'}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Result CAP Completed Date
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Result CAP Sent To Patient On
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Result CAP Sent To PCP On
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'encounter',
								'followup_cap_completed_date',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{!_.isNil(
								_.get(props, [
									'encounter',
									'cap_sent_to_patient',
								])
							) &&
							!_.isNil(
								_.get(props, [
									'encounter',
									'cap_release_method_patient',
								])
							) &&
							!_.isEmpty(
								_.get(props, [
									'encounter',
									'cap_sent_to_patient',
								])
							) &&
							!_.isEmpty(
								_.get(props, [
									'encounter',
									'cap_release_method_patient',
								])
							)
								? EncounterDateFieldsDisplay(props, [
										'encounter',
										'cap_sent_to_patient',
									]) +
									' via ' +
									GetMultiSelectvalue(
										props,
										[
											'encounter',
											'cap_release_method_patient',
										],
										_.get(
											props,
											[
												'visitsschema',
												'cap_release_method_patient',
											],
											[]
										)
									)
								: '--'}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{!_.isNil(
								_.get(props, ['encounter', 'cap_sent_date_pcp'])
							) &&
							!_.isNil(
								_.get(props, [
									'encounter',
									'cap_release_method_pcp',
								])
							) &&
							!_.isEmpty(
								_.get(props, ['encounter', 'cap_sent_date_pcp'])
							) &&
							!_.isEmpty(
								_.get(props, [
									'encounter',
									'cap_release_method_pcp',
								])
							)
								? EncounterDateFieldsDisplay(props, [
										'encounter',
										'cap_sent_date_pcp',
									]) +
									' via ' +
									GetMultiSelectvalue(
										props,
										['encounter', 'cap_release_method_pcp'],
										_.get(
											props,
											[
												'visitsschema',
												'cap_release_method_pcp',
											],
											[]
										)
									)
								: '--'}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Pioneer Appointment ID
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Additional Conference Line
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{pioneerAppointmentId ? (
								<a
									href={`${process.env.REACT_APP_PIONEER_BASE_URL}/dl?to=${encodeURIComponent(
										`/appointments/${pioneerAppointmentId}`
									)}`}
									target='_blank'
								>
									{pioneerAppointmentId}
								</a>
							) : (
								'N/A'
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{props.encounter?.additional_conference_line ??
								'--'}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VisitDetailSegment;
