import _ from 'lodash';
import get from 'lodash/get';
import moment from 'moment';

export const deHydrateEncounter = (data) => {
	return {
		uuid: data && data.encounterUUID,
		type: data && data.encounterName,
		order_status: data && data.orderStatus,
		date_test_ordered: data && data.dispatchDate,
		date_test_authorized: data && data.authDate,
		date_results_released_to_patient: data && data.resultReleaseDate,
		date_received_report: data && data.reportReceiptDate,
		clinician_comments: data && data.clinicalCommentsGeneral,
		ordering_physician: data && data.orderingPhysicianName,
		positive_results_to_report: data && data.positiveResultsToReport,
		test_results: data && data.testResult,
	};
};

export const icdCodeSort = (codesResponse = {}) => {
	const codes = get(codesResponse, 'data', []) || [];
	if (!Array.isArray(codes)) return codes;

	const newCodes = codes.slice().sort((a, b) => {
		if (get(a, 'code_name', '') > get(b, 'code_name', '')) {
			return 1;
		} else return -1;
	});

	codesResponse.data = newCodes;
	return codesResponse;
};

export const deHydratePreauthorizationsTestOrder = (data) => {
	return _.omitBy(
		{
			alternate_test_uuid: data && data.alternate_test_uuid,
			genetic_test_uuid: data && data.genetic_test_uuid,
			dispatch_date: data && data.preAuthDispatchDate,
			dispatch_status: data && data.dispatch_status,
			dispatch_status_reason: data && data.dispatch_status_reason,
			medical_codes: data && data.medical_codes,
			request_decision: data && data.request_decision,
		},
		_.isUndefined
	);
};
export const hydrateProviders = (data) => {
	return _.isArray(data) && data.map((d) => hydrateProvider(d));
};

export const hydrateProvider = (data) => {
	const isArchived = _.get(data, 'is_archived', false);
	return {
		savedRecord: true,
		linkedToEncounter: true,
		providerUUID: data.uuid,
		title: data.title,
		providerFullName: data.name,
		providerRole: data.role || [],
		receiveAllComms: data.receive_all_communications || false,
		city: _.get(data, ['address', 'office', 'city'], ''),
		state: _.get(data, ['address', 'office', 'state'], ''),
		addressLine1: _.get(data, ['address', 'office', 'address_line1'], ''),
		specialities: _.get(data, ['specialties'], []),
		isArchived: isArchived,
	};
};

export const formatVisitIndications = (data) => {
	if (Array.isArray(data)) {
		return data.map((el) => {
			const displayName = _.get(el, 'display_name', '');
			const categoryName = _.get(el, 'category_name', '');
			if (
				displayName &&
				typeof displayName === 'string' &&
				displayName.match(/other/gi)
			)
				el.textEnabled = true;
			else if (
				displayName &&
				typeof displayName === 'string' &&
				displayName.match(/Congenital Anomaly\(ies\)/g)
			)
				el.textEnabled = true;
			else if (
				displayName === 'Reproductive' &&
				categoryName === 'Reproductive'
			)
				el.disabled = true;
			return el;
		});
	} else return data;
};

export const hydrateTestOrders = (data) => {
	return _.isArray(data) && data.map((d) => hydrateTestOrder(d));
};

const UTCToLocalDate = (timestamp) => {
	if (!moment(timestamp).isValid()) return timestamp;
	if (
		moment(timestamp).hours() === 0 &&
		moment(timestamp).minutes() === 0 &&
		moment(timestamp).seconds() === 0
	)
		return timestamp;

	return moment.utc(timestamp).local().format('YYYY-MM-DD');
};

export const hydrateTestOrder = (data) => {
	if (data?.type === 'group') {
		const orders = data.orders.map((order) => {
			return {
				...order,
				blood_draw_order_date: UTCToLocalDate(
					_.get(order, 'blood_draw_order_date')
				),
				date_received_report: UTCToLocalDate(
					_.get(order, 'date_received_report')
				),
				date_test_authorized: UTCToLocalDate(
					_.get(order, 'date_test_authorized')
				),
				date_test_ordered: UTCToLocalDate(_.get(order, 'date_test_ordered')),
				expected_lab_results_date: UTCToLocalDate(
					_.get(order, 'expected_lab_results_date')
				),
				date_results_released_to_patient: UTCToLocalDate(
					_.get(order, 'date_results_released_to_patient')
				),
			};
		});

		return {
			created_at: data.created_at,
			type: data.type,
			orders: _.orderBy(
				orders,
				(o) => {
					return o?.genetic_test_display_name;
				},
				['asc']
			),
		};
	} else {
		return {
			...data,
			blood_draw_order_date: UTCToLocalDate(
				_.get(data, 'blood_draw_order_date')
			),
			date_received_report: UTCToLocalDate(
				_.get(data, 'date_received_report')
			),
			date_test_authorized: UTCToLocalDate(
				_.get(data, 'date_test_authorized')
			),
			date_test_ordered: UTCToLocalDate(_.get(data, 'date_test_ordered')),
			expected_lab_results_date: UTCToLocalDate(
				_.get(data, 'expected_lab_results_date')
			),
			date_results_released_to_patient: UTCToLocalDate(
				_.get(data, 'date_results_released_to_patient')
			),
		};
	}
};
