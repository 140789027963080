import debounce from 'lodash/debounce';
import { sanitize } from 'dompurify';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dropdown from '../../../../Common/FormFields/DropdownOld';
import { getpatients } from '../../../../../actions/patients';

import './SelectPatient.scss';

/**
 * Dropdown to search patients across the system
 * @param {object} props
 * @param {(patientId: number, patientUuid: string) => void} props.onChange
 * @param {string} props.value patient UUID
 * @returns {JSX.Element}
 */
export function SelectPatient(props) {
	const dispatch = useDispatch();
	const { searchResult, searchResultLoading } = useSelector((store) => ({
		searchResult: store.patients.patients?.data?.data ?? [],
		searchResultLoading: store.patients.loading,
	}));
	const [searchQuery, setSearchQuery] = useState('');

	const filterFunction = debounce((query) => {
		if (query && query !== searchQuery) {
			setSearchQuery(query);
			dispatch(getpatients({ q: query, component: 'scheduling' }));
		}
	}, 500);

	return (
		<Dropdown
			className='select-patient'
			clearable
			typeahead
			loading={searchResultLoading}
			placeholder='Search for a patient by name, email, phone number or patient ID'
			onChange={(id, item) => {
				let uuid = '';
				if (id) {
					uuid = searchResult.find((i) => i.id === id)?.uuid;
				}
				// Notes:
				// 1. `item` is undefined when clearing the dropdown
				// 2. store the label as search query to prevent refetching when
				// clicking into the input field
				setSearchQuery(item?.props?.label ?? '');
				props.onChange(id, uuid);
			}}
			customOption={ResultItem}
			filterFunction={filterFunction}
			options={searchResult.map((item) => ({
				label: `${item.first_name} ${item.last_name}`,
				item,
				value: item.id,
			}))}
			value={props.value}
			error={props.touched && props.error}
		/>
	);
}

function ResultItem({ item }) {
	const { first_name, last_name, email } = item.highlight;
	const firstNameElement = first_name?.[0] ? (
		<span
			dangerouslySetInnerHTML={{ __html: sanitize(first_name[0]) }}
		></span>
	) : (
		item.first_name
	);
	const lastNameElement = last_name?.[0] ? (
		<span
			dangerouslySetInnerHTML={{ __html: sanitize(last_name[0]) }}
		></span>
	) : (
		item.last_name
	);
	const emailElement = email?.[0] ? (
		<span dangerouslySetInnerHTML={{ __html: sanitize(email[0]) }}></span>
	) : (
		item.email
	);
	return (
		<div className='patient-item' key={item.uuid} data-testid={item.uuid}>
			<div>
				{firstNameElement} {lastNameElement}
			</div>
			<div>{emailElement}</div>
		</div>
	);
}
