//React & Redux
import React, { Component, Fragment } from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Dialog } from 'gm-element-react';

//Components
import Icon from '../Common/Icon';

//Styles
import './css/appointment-scheduling-error-dialog.css';
class AppointmentErrorDialog extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const errorMessage =
			[
				_.get(this, ['props', 'error'], ''),
				_.get(this, ['props', 'errorType'], ''),
			]
				.filter(Boolean)
				.join(': ') || _.get(this, ['props', 'messages'], '');

		const errorTitle = _.get(this, ['props', 'errorTitle'], '');

		const initialErrorMessage = _.get(
			this,
			['props', 'initialErrorMessage'],
			''
		);
		return (
			<Fragment>
				<Dialog
					className={'appointment-scheduling-error-dialog'}
					title={
						<span className='title'>
							<Icon
								className='warningIcon'
								icon='warning-circle'
							/>
							<span className='error-title'>{errorTitle}</span>
							<Icon
								className='closeIcon'
								icon='close'
								onClick={() => this.props.errorCloseHandler()}
							></Icon>
						</span>
					}
					size='tiny'
					visible={_.get(this, ['props', 'showErrorDialog'])}
					onCancel={() => this.props.errorCloseHandler()}
					lockScroll={false}
					showClose={false}
					top='33%'
				>
					<Dialog.Body>
						<section className='appointment-scheduling-error-msg'>
							{
								<>
									<>
										<div>
											{initialErrorMessage} [
											{errorMessage}].
										</div>
										<div>
											Please contact your support team.
										</div>
									</>
								</>
							}
						</section>
					</Dialog.Body>
				</Dialog>
			</Fragment>
		);
	}
}

export default AppointmentErrorDialog;
