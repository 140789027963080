//React & Redux
import React, { Fragment } from 'react';

//UI Libraries
import { Layout } from 'gm-element-react';

//Loadsh
import _ from 'lodash';
//Other Libraries
import moment from 'moment';

const EncounterTestOrdersPreAuth = (props) => {
	const { testOrders } = props;

	const displayDate = (value) =>
		_.get(value, 'length', 0) > 0 && moment(value).isValid()
			? moment(value).format('MM/DD/YYYY')
			: '--';

	const getTestOrders = () =>
		testOrders.map((e) => (
			<Fragment key={e.test_display_name}>
				<Layout.Row gutter='20' style={{ marginBottom: '16px' }}>
					<Layout.Col span='24' style={{ paddingRight: 0 }}>
						<div className='test-number-container'>
							<div className='test-number'>
								Pre-Authorization Request
							</div>
							<div className='divider'></div>
						</div>
					</Layout.Col>
				</Layout.Row>
				<Layout.Row gutter='20' style={{ marginBottom: '24px' }}>
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>Lab</span>
						<span className='val-enctr-sgmnt-field'>
							{e.lab_display_name || '--'}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>Dispatch Date</span>
						<span className='val-enctr-sgmnt-field'>
							{_.get(e.dispatch_date, 'length', 0) > 0
								? displayDate(e.dispatch_date)
								: '--'}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>Test Type</span>
						<span className='val-enctr-sgmnt-field'>
							{e.test_display_name || '--'}
						</span>
					</Layout.Col>
				</Layout.Row>
			</Fragment>
		));

	return <>{testOrders && getTestOrders()}</>;
};

export default EncounterTestOrdersPreAuth;
