//React & Redux
import React from 'react';

//Lodash
import { remove, isEqual, filter } from 'lodash';

//Components
import SingleSelectTitleRow from './SingleSelectTitleRow';
import MultiSelectCheckboxBody from './MultiSelectCheckboxBody';
import SingleSelectButtons from './SingleSelectButtons';

//UI Libraries
import { Loading } from 'gm-element-react';

//Styles
import './MultiSelectCheckboxModal.css';

class MultiSelectCheckboxModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			internalValues: [...(this.props.selected || [])],
			searchQuery: '',
			changed: false,
			loading: false,
		};
		//bindings
		this.bodyClickHandler = this.bodyClickHandler.bind(this);
		this.resetInternalValues = this.resetInternalValues.bind(this);
		this.saveValue = this.saveValue.bind(this);
		this.canSaveValue = this.canSaveValue.bind(this);
		this.isEverythingSelected = this.isEverythingSelected.bind(this);
		this.toggleCheck = this.toggleCheck.bind(this);
		this.selectAll = this.selectAll.bind(this);
		this.selectNone = this.selectNone.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.searchReset = this.searchReset.bind(this);
		this.filteredData = this.filteredData.bind(this);
	}
	//functions

	componentDidMount() {
		if (this.modalElement) {
			document
				.querySelector('body')
				.addEventListener('click', this.bodyClickHandler, {
					capture: true,
				});
		}

		if (
			(this.props.selected?.length || 0) === 0 &&
			!(this.props.emptyInputFullOutputOverride || false)
		) {
			this.selectAll(false, true);
		}
	}

	componentWillUnmount() {
		if (this.modalElement) {
			document
				.querySelector('body')
				.removeEventListener('click', this.bodyClickHandler);
		}
	}

	bodyClickHandler(e) {
		const currentNode = e.target;
		const pillElement = this.props.pillElement || null;
		const modalElement = this.modalElement || null;
		if (modalElement && pillElement) {
			if (
				!modalElement.contains(currentNode) &&
				!pillElement.contains(currentNode)
			) {
				this.props.closeModal();
			}
		}
	}

	resetInternalValues() {
		const defaultValues = this.props.defaultValues || [];
		const internalValues = this.state.internalValues || [];
		if (
			this.isEverythingSelected() &&
			defaultValues.length === 0 &&
			!(this.props.emptyInputFullOutputOverride || false)
		)
			return;

		if (
			Array.isArray(defaultValues) &&
			Array.isArray(internalValues) &&
			(!isEqual(
				internalValues.slice().sort(),
				defaultValues.slice().sort()
			) ||
				((internalValues.length || 0) === 0 &&
					(defaultValues.length || 0) === 0))
		) {
			if (
				(defaultValues.length || 0) === 0 &&
				!(this.props.emptyInputFullOutputOverride || false)
			) {
				this.selectAll(false);
			} else {
				this.setState({
					internalValues: [...(this.props.defaultValues || [])],
				});
			}
			this.setState({ changed: true });
		}
	}

	// this saves and updates filtering
	saveValue() {
		if (this.canSaveValue()) {
			const data = this.props.data || [];
			const dataItemLength = Array.isArray(data)
				? data.filter((el) => !el.groupingLabel).length
				: 0;
			if (
				(this.state.internalValues?.length || 0) === dataItemLength &&
				!(this.props.emptyInputFullOutputOverride || false)
			) {
				this.props.saveSelected([]);
			} else {
				this.props.saveSelected(this.state.internalValues || null);
			}
			this.props.closeModal();
		}
	}

	canSaveValue() {
		return (
			(this.props.hasSubmitFunc || false) &&
			!isEqual(
				(this.state.internalValues || []).sort(),
				(this.props.selected || []).sort()
			) &&
			(this.state.changed || false) &&
			(this.props.allowsEmptyOutput || false
				? true
				: this.state.internalValues?.length || 0)
		);
	}

	isEverythingSelected() {
		const data = this.props.data || [];
		const dataItemLength = Array.isArray(data)
			? data.filter((el) => !el.groupingLabel).length
			: 0;
		const selectedItemLength = this.state.internalValues?.length || 0;
		return dataItemLength === selectedItemLength;
	}

	toggleCheck(value) {
		let internalValues = [...(this.state.internalValues || [])];

		if (internalValues.includes(value)) {
			internalValues = remove(internalValues, (val) => val !== value);
		} else {
			internalValues.push(value);
		}

		this.setState({ internalValues, changed: true });
	}

	selectAll(toggle = true, changedToggleOverride) {
		const data = this.filteredData().filter((el) => !el.groupingLabel);
		const keys = data.map((el) => el.key);

		if (toggle) {
			const { internalValues } = this.state;

			this.setState({
				internalValues:
					internalValues.length === data.length ? [] : [...keys],
			});
		} else this.setState({ internalValues: [...keys] });

		if (!changedToggleOverride) this.setState({ changed: true });
	}

	selectNone() {
		this.setState({ internalValues: [], changed: true });
	}

	filteredData() {
		const data = this.props.data || [];

		if (this.props.searchBar || false) {
			const searchQuery = this.state.searchQuery || '';
			return filter(
				data,
				(el) =>
					(el.display_name || '')
						.toLowerCase()
						.includes(searchQuery.toLowerCase()) ||
					el.groupingLabel ||
					null
			);
		}

		return data;
	}

	handleSearch(e) {
		this.setState({ searchQuery: e.target?.value || null });
	}

	searchReset() {
		this.setState({ searchQuery: '' });
	}

	render() {
		const isLoading =
			this.props.loading || false || this.state.loading || false;

		return (
			<div
				className='multi-select-checkbox-modal-container'
				ref={(el) => (this.modalElement = el)}
				style={{
					width: this.props.previewTab || false ? '520px' : '368px',
					height: isLoading ? '520px' : 'unset',
				}}
			>
				{isLoading ? (
					<Loading
						loading={true}
						className='filterModalSpinner'
						style={{ top: '50%' }}
					/>
				) : (
					<>
						<SingleSelectTitleRow
							title={this.props.title || ''}
							closeModal={this.props.closeModal}
						/>
						<MultiSelectCheckboxBody
							searchBar={this.props.searchBar || false}
							previewTab={this.props.previewTab || false}
							data={this.props.data || []}
							filteredData={this.filteredData()}
							internalValues={this.state.internalValues || null}
							searchQuery={this.state.searchQuery || null}
							hideSelectAll={this.props.hideSelectAll || false}
							toggleCheck={this.toggleCheck}
							selectAll={this.selectAll}
							selectNone={this.selectNone}
							handleSearch={this.handleSearch}
							searchReset={this.searchReset}
							handleChangeList={this.props.handleChangeList}
							radioFilterOptions={this.props.radioFilterOptions}
						/>
						<SingleSelectButtons
							resetInternalValue={this.resetInternalValues}
							saveValue={this.saveValue}
							canSaveValue={this.canSaveValue()}
						/>
					</>
				)}
			</div>
		);
	}
}

export default MultiSelectCheckboxModal;
