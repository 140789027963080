import axios from 'axios';
import { dehydrateGeneTestResult } from './../transformers/patients/testOrderTransformer';

class Genes {
	getGeneTestResultsEnums = () => {
		return axios.get('/api/enums/genetic-test/gene-test-results');
	};

	getGeneticResultGenes = (uuid, q, gene_uuid) => {
		return axios.get('/api/enums/genetic-result/genes/' + uuid, {
			params: { q: q, gene_uuid: gene_uuid },
		});
	};

	getGeneticResultGenes_MultipleID = (data) => {
		return axios.post(
			'/api/enums/genetic-result/genes',
			{
				gene_id_list: (data && data.geneIDList) || [],
			},
			{
				params: {
					q: data && data.q,
					testuuid: data.uuid,
				},
			}
		);
	};

	createGeneResult = async (data) => {
		const body = { data: dehydrateGeneTestResult(data.payload) };
		return await axios.post(
			`/api/genetic-tests/gene-test-orders/${data.testOrderUUID}/gene-test-results`,
			body
		);
	};
	updateGeneResult = async (data) => {
		const body = { data: dehydrateGeneTestResult(data.payload) };
		return await axios.put(
			`/api/genetic-tests/gene-test-orders/${data.testOrderUUID}/gene-test-results/${data.geneUUID}`,
			body
		);
	};
}

export default new Genes();
