import React, { forwardRef, useImperativeHandle } from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';

import { useCreatePaymentMethod } from './useCreatePaymentMethod';
import PropTypes from 'prop-types';

export const CardPayment = forwardRef(function CardPayment(props, ref) {
	const createPaymentMethod = useCreatePaymentMethod();

	useImperativeHandle(
		ref,
		() => {
			return {
				createPaymentMethod: createPaymentMethod,
			};
		},
		[createPaymentMethod]
	);

	return (
		<PaymentElement
			options={{
				defaultValues: {
					billingDetails: {
						name: props.defaultValues?.name,
						address: {
							country:
								props.defaultValues?.address?.country || 'US',
							postal_code:
								props.defaultValues?.address?.postalCode,
						},
					},
				},
				fields: {
					billingDetails: {
						name: 'auto',
					},
				},
			}}
		/>
	);
});

CardPayment.propTypes = {
	defaultValues: PropTypes.shape({
		name: PropTypes.string,
		address: PropTypes.shape({
			country: PropTypes.string,
			postalCode: PropTypes.string,
		}),
	}),
};
