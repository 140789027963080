//React & Redux
import React, { useRef } from 'react';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Components
import { DateRangePicker } from 'gm-element-react';

//Styles
import './styles.css';

const DateRangeFilter = (props) => {
	const {
		startDate,
		endDate,
		startDatePlaceholder,
		endDatePlaceholder,
		format,
		shortcuts,
		onChange,
		externalRef,
	} = props;
	const innerDateRangerRef = useRef();
	const dateRangerRef = externalRef ?? innerDateRangerRef;

	const formulateShortcuts = () => {
		if (shortcuts && Array.isArray(shortcuts)) {
			return shortcuts.map((el) => {
				return {
					text: get(el, 'text', 'Date'),
					onClick: () => {
						handleChange(get(el, 'newValues', null));
						dateRangerRef.current.togglePickerVisible();
					},
				};
			});
		} else return null;
	};
	const handleChange = (dates) => {
		const ranges = { from: dates?.[0], to: dates?.[1] };
		onChange(ranges);
	};

	return (
		<div className='dateRangeContainer'>
			<DateRangePicker
				value={[startDate, endDate]}
				placeholder={startDatePlaceholder + ' - ' + endDatePlaceholder}
				format={format || 'MM/dd/yyyy'}
				onChange={(e) => handleChange(e)}
				isReadOnly={true}
				align={'left'}
				shortcuts={formulateShortcuts()}
				ref={dateRangerRef}
			/>
		</div>
	);
};

export default DateRangeFilter;
