//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import alertIcon from './../../assets/alert.svg';

//Lodash
import get from 'lodash/get';

//UI Libraries
import { Select, Loading } from 'gm-element-react';

const TemplateModalFinalize = (props) => {
	const {
		changeTemplateName,
		changeTemplateType,
		close,
		finalizeDraft,
		finalizeDraftLoading = false,
		template = {},
		templateName,
		templateType,
		templateTypes = [],
		title,
	} = props;

	const canFinalize = () => {
		return templateType && templateName;
	};

	const handleFinalize = () => {
		if (canFinalize()) finalizeDraft();
	};

	const buttonClass = canFinalize()
		? 'confirmationModalButtonRowConfirm'
		: 'confirmationModalButtonRowConfirm filterDropdownButtonDisabled';
	return (
		<div className='confirmationModalContainer'>
			<Loading loading={finalizeDraftLoading}>
				<div className='confirmationModalHeaderRow'>
					<ReactSVG
						src={alertIcon}
						className='confirmationModalHeaderRowIcon'
					/>
					<div style={{ position: 'relative', bottom: '3px' }}>
						{title || 'Confirmation'}
					</div>
				</div>
				<div className='confirmationModalBodyContainer confirmationModalBodyDropdownContainer'>
					<div
						className='confirmationModalDropdownContainer'
						style={{ marginBottom: '20px' }}
					>
						<div className='confirmationModalRequiredField'>*</div>
						<div className={'confirmationModalDropdownFieldTitle'}>
							{' '}
							Template Type{' '}
						</div>
						<Select
							value={
								templateType ||
								get(template, 'document_type.key', null)
							}
							placeholder={'Select'}
							onChange={(e) => {
								changeTemplateType(e);
							}}
							className='confirmationModalDropdownEl'
						>
							{templateTypes.map((el, i) => {
								return (
									<Select.Option
										label={
											get(el, 'display_name', '--') ||
											'--'
										}
										value={get(el, 'key', null)}
										key={get(el, 'key', i)}
									>
										<div className='confirmationModalDropdownElementText'>
											{get(el, 'display_name', '--')}
										</div>
									</Select.Option>
								);
							})}
						</Select>
					</div>

					<div
						className='confirmationModalDropdownContainer'
						style={{ marginBottom: '19px' }}
					>
						<div className='confirmationModalRequiredField'>*</div>
						<div className={'confirmationModalDropdownFieldTitle'}>
							{' '}
							Template Name{' '}
						</div>
						<div>
							<input
								value={
									templateName ||
									get(template, 'file_name', null) ||
									''
								}
								placeholder={'Template name'}
								onChange={(e) => changeTemplateName(e)}
								className='confirmationModalInput'
							/>
						</div>
					</div>
				</div>
				<div className='confirmationModalButtonRow'>
					<div
						className='confirmationModalButtonRowCancel'
						onClick={(e) => close()}
					>
						Cancel
					</div>
					<div
						className={buttonClass}
						onClick={(e) => handleFinalize()}
					>
						{title || 'Accept'}
					</div>
				</div>
			</Loading>
		</div>
	);
};

export default TemplateModalFinalize;
