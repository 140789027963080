import z from 'zod';
import _ from 'lodash';

import { enqueueSnackbar } from '../../../../utils';
import {
	initialPatientInfo,
	initialPaymentInfo,
	schemaPatientInfo,
	schemaPaymentInfo,
	initialServiceInfoAndScheduling,
	schemaServiceInfoAndScheduling,
	schemaSummary,
	initialSummary,
} from './sections';

export const schema = z
	.object({
		patientInfo: schemaPatientInfo,
		paymentInfo: schemaPaymentInfo,
		serviceInfoAndScheduling: schemaServiceInfoAndScheduling,
		summary: schemaSummary,
	})
	.required();

export const initialValues = {
	patientInfo: initialPatientInfo,
	paymentInfo: initialPaymentInfo,
	serviceInfoAndScheduling: initialServiceInfoAndScheduling,
	summary: initialSummary,
};

export function getInitialValues({ patientId, patientUUID }) {
	if (patientUUID) {
		return {
			...initialValues,
			patientInfo: {
				...initialValues.patientInfo,
				patientId,
				patientUUID,
			},
		};
	}
	return initialValues;
}

/**
 * Validates `input` against Zod `schema`.
 * @param {Object} schema Zod validation schema
 * @param {Object} input Form input
 * @returns {Record<string, string>} error object
 */
export function validateForm(schema, input) {
	const result = schema.safeParse(input);
	if (result.success) {
		return {};
	}
	enqueueSnackbar('Check the form, there are validation errors');

	const errors = result.error.errors.reduce((acc, item) => {
		return _.set(acc, item.path, item.message);
	}, {});

	return errors;
}
