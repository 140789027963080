//React & Redux
import React from 'react';

//Images
import Logo from './logo.svg';

//Styles
import './style.css';
import './noaccess.css';

const NoAccess = (props) => (
	<div className='no-access'>
		<nav className='nav'>
			<div className='container'>
				<div className='mob-logo'>
					<img src={Logo} alt='' className='logomain' />
				</div>
			</div>
		</nav>
		<div className='noaccesstext'>
			Unfortunately, it appears you do not have the appropriate
			permissions to access this page.
			<br />
			<br />
			If you believe this is an error or access is required, please
			contact{' '}
			<a href='mailto:support@genomemedical.com'>
				{' '}
				support@genomemedical.com.{' '}
			</a>
			<br /> <br />
			<span
				className='noaccess_goback'
				onClick={props.gotoHome}
				data-testid='goback'
			>
				back
			</span>
		</div>
	</div>
);

export default NoAccess;
