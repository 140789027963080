//React & Redux
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

//Images
import export_img from '../../assets/export.svg';
import ReactSVG from 'react-svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import {
	advanced_search_categories_master,
	hiddenAdvancedSearchCategoriesOnProd,
	conditions_categories,
} from '../../Utils/searchUtils';

//Actions & Services
import {
	getSearchedResults,
	resetSearchData,
	getSearchAffiliations,
	getSearchOrderingPhysicians,
	getSearchVisitProviders,
	getSearchVisitStatus,
	getActionability,
	getSearchSpecialty,
	getEnhancedSearchLabs,
	getEnhancedSearchOrderStatus,
	getEnhancedSearchEncounterTypes,
	getSearchExportReport,
	getEnhancedSearchTestNames,
	getEnhancedSearchTestResults,
	getSearchDispatchStatus,
	getSearchRequestDecision,
	getEnhancedSearchTestType,
	getSearchReferringProviderName,
	getSearchVisitState,
	getMultiPatientStatus,
	getSearchOutreachStatus,
	getEnhancedSearchPatientTag,
} from '../../actions/advancedsearch';

//UI Libraries
import { Button } from 'gm-element-react';
import ReactTags from 'react-tag-input-custom-search';
import PillBar from './Pill/PillBar.js';

//Other Libraries
import moment from 'moment';
import uuidv4 from 'uuid/v4';

//Components
import ErrorComponent from './InvalidCategoryError/ErrorComponent';
import TableComponent from './EnhancedSearchTable/TableComponent';

//Styles
import './style.css';

const advanced_search_categories_orig = advanced_search_categories_master;
let advanced_search_categories = advanced_search_categories_orig;

const Keys = {
	TAB: 9,
	SPACE: 32,
	COMMA: 188,
	ENTER: 13,
};

const single_categories = {
	ordering_physician: 1,
	visit_provider: 1,
	lab: 1,
	order_status: 1,
	type_of_test: 1,
	encounter_type: 1,
	vsee_specialty: 1,
	provider_indicated_specialty: 1,
	visit_status: 1,
	test_name: 1,
	test_result: 1,
	dispatch_status: 1,
	request_decision: 1,
	referring_provider_name: 1,
	state_of_visit: 1,
	multipatient_visit: 1,
	outreach_status: 1,
	is_actionable: 1,
};
const restrict_input_categories = {
	date_of_service: 1,
	referral_program: 1,
	ordering_physician: 1,
	visit_provider: 1,
	lab: 1,
	order_status: 1,
	type_of_test: 1,
	encounter_type: 1,
	vsee_specialty: 1,
	provider_indicated_specialty: 1,
	visit_status: 1,
	test_name: 1,
	test_result: 1,
	dispatch_status: 1,
	request_decision: 1,
	referring_provider_name: 1,
	state_of_visit: 1,
	multipatient_visit: 1,
	outreach_status: 1,
	is_actionable: 1,
};

const DEFAULT_LIMIT = 25;

const EnhancedSearch = (props) => {
	const [state, setState] = useState({
		tags: [],
		inputType: '',
		inputQuery: '',
		suggestions: advanced_search_categories,
		delimiters: [Keys.TAB, Keys.COMMA, Keys.ENTER],
		error_category: false,
		type_of_test_pill_created_value: false,
		test_name_pill_created_value: false,
		lab_pill_created_value: false,
		multipatient_visit_pill_created_value: false,
		encounter_type_pill_created_value: false,
		is_lab_pill_created: false,
		is_test_name_pill_created: false,
		is_type_of_test_pill_created: false,
		is_multipatient_visit_pill_created: false,
		is_encounter_type_pill_created: false,
		column: 'first_name',
		sort_column: 'asc',
		searchedResults: [],
		searchDisabled: false,
		activePage: 0,
		selected_pill: [],
	});

	const {
		tags,
		inputType,
		inputQuery,
		suggestions,
		delimiters,
		error_category,
		type_of_test_pill_created_value,
		test_name_pill_created_value,
		lab_pill_created_value,
		multipatient_visit_pill_created_value,
		encounter_type_pill_created_value,
		column,
		sort_column,
		searchedResults,
		searchDisabled,
		activePage,
		selected_pill,
	} = state;

	const {
		testNames,
		testType,
		lab,
		multiPatientStatus,
		encounterTypes,
		resetSearchData,
		getPatientTags,
		getSearchedResults,
		exportReport,
		enqueueSnackbar,
		history,
		affiliation,
		orderingPhysician,
		visitProviders,
		orderStatus,
		specialtyEnums,
		visitStatus,
		actionability,
		testResults,
		dispatchStatus,
		requestDecision,
		referringProviderName,
		stateOfVisit,
		outreachStatus,
		searchPatientTags,
		searchOutreachStatus,
		searchencounterTypes,
		searchTestResults,
		searchTestNames,
		searchTestType,
		searchMultiPatientStatus,
		searchVisitState,
		searchReferringProviderName,
		searchRequestDecision,
		searchDispatchStatus,
		searchOrderStatus,
		searchLabs,
		searchActionability,
		searchVisitStatus,
		searchSpecialtyEnums,
		searchVisitProviders,
		searchOrderingPhysician,
		searchAffiliations,
	} = props;

	useEffect(() => {
		affiliation();
		orderingPhysician();
		visitProviders();
		lab();
		orderStatus();
		testType();
		encounterTypes();
		specialtyEnums();
		visitStatus();
		actionability();
		testNames();
		testResults();
		dispatchStatus();
		requestDecision();
		referringProviderName();
		stateOfVisit();
		multiPatientStatus();
		outreachStatus();
		getPatientTags();

		const selectedPillLocation = get(
			history,
			'location.state.selected_pill',
			[]
		);
		const activePageLocation = get(
			history,
			'location.state.breadcrumbs[0].activePage',
			0
		);
		const columnLocation = get(
			history,
			'location.state.breadcrumbs[0].column',
			'first_name'
		);
		const sortColumnLocation = get(
			history,
			'location.state.breadcrumbs[0].sort_column',
			'asc'
		);

		advanced_search_categories = advanced_search_categories.map((e) => {
			const thisKey = _.get(e, 'key');
			const selected = selectedPillLocation.find(
				(a) => a && a.category && a.category.key == thisKey
			);
			if (
				!_.isEmpty(selected) &&
				_.get(selected, ['category', 'key'], '') == 'tag_display_name'
			) {
				e['disabled'] = false;
			} else {
				e['disabled'] = !_.isEmpty(selected) ? true : false;
			}
			return e;
		});
		setState((prevState) => {
			return {
				...prevState,
				suggestions: advanced_search_categories,
			};
		});

		if (selectedPillLocation && !_.isEmpty(selectedPillLocation)) {
			setState((prevState) => {
				return {
					...prevState,
					selected_pill: selectedPillLocation,
					activePage: activePageLocation, //EHR-4204
					column: columnLocation,
					sort_column: sortColumnLocation,
				};
			});
			getSearchResults(
				activePageLocation,
				selectedPillLocation,
				columnLocation,
				sortColumnLocation
			);
		}
	}, []);

	const handleDelete = (i) => {
		if (get(inputQuery, 'length', 0) == 0 && get(tags, 'length', 0) == 0)
			return;

		const tagsList = tags.slice(0);
		tagsList.splice(i, 1);

		if (tagsList.length == 0) {
			advanced_search_categories = advanced_search_categories.map((e) => {
				e['disabled'] = false;
				return e;
			});
			setState((prevState) => {
				return {
					...prevState,
					suggestions: advanced_search_categories,
					tags: [],
					inputType: '',
					inputQuery: '',
				};
			});
		} else {
			setState((prevState) => {
				return {
					...prevState,
					suggestions:
						conditions_categories[get(_.last(tagsList), 'key')],
					tags: tagsList,
					inputType: '',
					inputQuery: '',
				};
			});
		}
	};

	const handleAddition = (tag) => {
		if (get(tag, 'name.length', 0) == 0) {
			return;
		}

		const tag_found = _.find(suggestions || [], function (o, idx) {
			return o.name.toLowerCase() == tag.name.toLowerCase().trim();
		});

		if (get(tags, 'length', 0) == 0) {
			if (tag_found) {
				const selected_category_idx = _.findIndex(
					advanced_search_categories,
					function (o) {
						return o.id == tag_found.id;
					}
				);
				if (get(tag, 'key', '') == 'tag_display_name') {
					advanced_search_categories[selected_category_idx][
						'disabled'
					] = false;
				} else {
					advanced_search_categories[selected_category_idx][
						'disabled'
					] = true;
				}
			}
		}

		if (error_category && !tag_found) {
			return;
		}
		if (!tag.id) {
			if (
				get(tags, ['0', 'key'], '') in
					{ referral_program: 1, date_of_service: 1 } &&
				get(tags, 'length', 0) < 2
			)
				return;
			if (get(tags, 'length', 0) > 0) {
				createPills(tag);
			} else return;
		} else {
			const tagsList = [].concat(tags, tag);
			let inputType = '';
			if (
				tagsList.length == 3 &&
				get(tagsList, '[0].key', '') in
					{ referral_program: 1, tag_display_name: 1 }
			) {
				createPills(tag);
				return;
			} else if (get(tags, ['0', 'key'], '') in single_categories) {
				createPills(tag);
				return;
			}

			if (tagsList.length == 2) {
				if (get(tagsList, '[1].name', '') == 'between') {
					inputType = 'multi_dates';
				} else
					inputType =
						get(tagsList, '[0].key', '') == 'date_of_service'
							? 'date'
							: '';
			} else if (get(tag, 'key', '') == 'dob') {
				inputType = 'date';
			} else if (get(tag, 'key', '') == 'phone') {
				inputType = 'phone';
			}

			const suggestions = getUpdatedSuggestions(tag);

			setState((prevState) => {
				return {
					...prevState,
					tags: tagsList,
					error_category: false,
					suggestions: suggestions,
					inputType: inputType,
				};
			});
		}
		setState((prevState) => {
			return {
				...prevState,
				delimiters: [Keys.TAB, Keys.COMMA, Keys.ENTER],
			};
		});
	};

	const getConditionalEnumsForDependentCategories = (
		key,
		condition1,
		condition2,
		context,
		context1,
		context2,
		contextcall1,
		contextcall2,
		func1,
		func2,
		func3,
		operation
	) => {
		if (!condition1 && !condition2) {
			operation === 'add' ? func1({ context: [context] }) : func1();
			if (func2 !== null)
				operation === 'add' ? func2({ context: [context] }) : func2();
		} else if (!condition1) {
			func1(contextcall2);
			operation === 'delete' && func3({ context: [context2] });
		} else if (!condition2) {
			if (func2 !== null) {
				func2(contextcall1);
			}
			operation === 'delete' &&
				func3 !== null &&
				func3({ context: [context1] });
		}
	};

	const getUpdatedSuggestionsForDependentCategories = (
		key,
		value,
		operation
	) => {
		const context = { name: key, value: value };
		const type_of_test_context = {
			name: 'type_of_test',
			value: type_of_test_pill_created_value,
		};
		const test_name_context = {
			name: 'test_name',
			value: test_name_pill_created_value,
		};
		const lab_context = { name: 'lab', value: lab_pill_created_value };
		const multipatient_visit_context = {
			name: 'multipatient_visit',
			value: multipatient_visit_pill_created_value,
		};
		const encounter_type_context = {
			name: 'encounter_type',
			value: encounter_type_pill_created_value,
		};
		const type_of_test_context_call = {
			context: [
				...(operation === 'add' ? [context] : []),
				type_of_test_context,
			],
		};
		const test_name_context_call = {
			context: [
				...(operation === 'add' ? [context] : []),
				test_name_context,
			],
		};
		const lab_context_call = {
			context: [...(operation === 'add' ? [context] : []), lab_context],
		};
		const multipatient_visit_context_call = {
			context: [
				...(operation === 'add' ? [context] : []),
				multipatient_visit_context,
			],
		};
		const encounter_type_context_call = {
			context: [
				...(operation === 'add' ? [context] : []),
				encounter_type_context,
			],
		};
		let is_lab_pill_created;
		let is_test_name_pill_created;
		let is_type_of_test_pill_created;
		let is_multipatient_visit_pill_created;
		let is_encounter_type_pill_created;

		if (key === 'lab') {
			getConditionalEnumsForDependentCategories(
				'lab',
				is_test_name_pill_created,
				is_type_of_test_pill_created,
				context,
				test_name_context,
				type_of_test_context,
				test_name_context_call,
				type_of_test_context_call,
				testNames,
				testType,
				lab,
				operation
			);
		} else if (key === 'test_name') {
			getConditionalEnumsForDependentCategories(
				'test_name',
				is_lab_pill_created,
				is_type_of_test_pill_created,
				context,
				lab_context,
				type_of_test_context,
				lab_context_call,
				type_of_test_context_call,
				lab,
				testType,
				testNames,
				operation
			);
		} else if (key === 'type_of_test') {
			getConditionalEnumsForDependentCategories(
				'type_of_test',
				is_test_name_pill_created,
				is_lab_pill_created,
				context,
				test_name_context,
				lab_context,
				test_name_context_call,
				lab_context_call,
				testNames,
				lab,
				testType,
				operation
			);
		} else if (key === 'encounter_type') {
			getConditionalEnumsForDependentCategories(
				'encounter_type',
				is_multipatient_visit_pill_created,
				false,
				context,
				multipatient_visit_context,
				null,
				multipatient_visit_context_call,
				null,
				multiPatientStatus,
				encounterTypes,
				encounterTypes,
				operation
			);
		} else if (key === 'multipatient_visit') {
			getConditionalEnumsForDependentCategories(
				'multipatient_visit',
				is_encounter_type_pill_created,
				false,
				context,
				encounter_type_context,
				null,
				encounter_type_context_call,
				null,
				encounterTypes,
				multiPatientStatus,
				multiPatientStatus,
				operation
			);
		}
	};

	const createPills = (tag) => {
		const selected_pill_list = selected_pill;
		const selected_category = get(tags, '0', '');
		const selected_category_name = get(selected_category, 'name');
		const condition_query =
			get(tags, 'length', 0) == 1
				? get(selected_category, 'condition[0]')
				: get(_.last(tags), 'name');
		let input_query = [get(tag, 'name')];

		if (
			get(selected_category, 'key', '') in { dob: 1, date_of_service: 1 }
		) {
			if (condition_query == 'between') {
				const dates_range = get(tag, 'name')
					.replace(/\s/g, '')
					.split('and');
				dates_range[0] = moment(dates_range[0], 'MM/DD/YYYY').format(
					'YYYY-MM-DD'
				);
				dates_range[1] = moment(dates_range[1], 'MM/DD/YYYY').format(
					'YYYY-MM-DD'
				);
				input_query = dates_range;
			} else
				input_query = [
					moment(get(tag, 'name'), 'MM/DD/YYYY').format('YYYY-MM-DD'),
				];
		}

		selected_pill_list.push({
			category: selected_category,
			condition: condition_query,
			inputs: input_query,
			id: uuidv4(),
		});

		if (
			get(selected_category, 'key', '') in
			{
				lab: 1,
				test_name: 1,
				type_of_test: 1,
				multipatient_visit: 1,
				encounter_type: 1,
			}
		) {
			setState((prevState) => {
				return {
					...prevState,
					['is_' +
					get(selected_category, 'key', '') +
					'_pill_created']: true,
					[get(selected_category, 'key', '') + '_pill_created_value']:
						get(tag, 'name'),
				};
			});
			getUpdatedSuggestionsForDependentCategories(
				get(selected_category, 'key', ''),
				get(tag, 'name'),
				'add'
			);
		}

		setState((prevState) => {
			return {
				...prevState,
				selected_pill: selected_pill_list,
				tags: [],
				error_category: false,
				inputType: '',
				searchDisabled: false,
				suggestions: advanced_search_categories,
				inputQuery: '',
			};
		});

		getSearchResults(0, selected_pill_list, column, sort_column);
	};

	const getUpdatedSuggestions = (tag) => {
		if (
			get(tags, ['0', 'key'], '') == 'referral_program' &&
			get(tags, 'length', 0) == 1
		) {
			return get(tag, 'name', '') in { contains: 1 }
				? []
				: searchAffiliations || [];
		}
		if (get(tag, 'key', '') == 'ordering_physician') {
			return searchOrderingPhysician || [];
		}
		if (get(tag, 'key', '') == 'visit_provider') {
			return searchVisitProviders || [];
		}
		if (
			get(tag, 'key', '') in
			{ vsee_specialty: 1, provider_indicated_specialty: 1 }
		) {
			return searchSpecialtyEnums || [];
		}
		if (get(tag, 'key', '') == 'visit_status') {
			return searchVisitStatus || [];
		}
		if (get(tag, 'key', '') == 'is_actionable') {
			return searchActionability || [];
		}
		if (get(tag, 'key', '') == 'lab') {
			return searchLabs || [];
		}
		if (get(tag, 'key', '') == 'order_status') {
			return searchOrderStatus || [];
		}
		if (get(tag, 'key', '') == 'dispatch_status') {
			return searchDispatchStatus || [];
		}
		if (get(tag, 'key', '') == 'request_decision') {
			return searchRequestDecision || [];
		}
		if (get(tag, 'key', '') == 'referring_provider_name') {
			return searchReferringProviderName || [];
		}
		if (get(tag, 'key', '') == 'state_of_visit') {
			return searchVisitState || [];
		}
		if (get(tag, 'key', '') == 'multipatient_visit') {
			return searchMultiPatientStatus || [];
		}
		if (get(tag, 'key', '') == 'type_of_test') {
			return searchTestType || [];
		}
		if (get(tag, 'key', '') == 'test_name') {
			return searchTestNames || [];
		}
		if (get(tag, 'key', '') == 'test_result') {
			return searchTestResults || [];
		}
		if (get(tag, 'key', '') == 'encounter_type') {
			return searchencounterTypes || [];
		}
		if (get(tag, 'key', '') == 'outreach_status') {
			return searchOutreachStatus || [];
		}
		if (get(tags, ['0', 'key'], '') == 'tag_display_name') {
			if (get(selected_pill, 'length', 0) > 0) {
				const selected_pill_list = selected_pill;
				const selected = selected_pill_list.find(
					(a) => get(a, 'category.key', '') == 'tag_display_name'
				);
				let _patientTags = searchPatientTags;
				if (!_.isEmpty(selected)) {
					selected_pill_list.map((e) => {
						const isSelectedPillTagName =
							get(e, 'category.key', '') == 'tag_display_name';
						const selectedPatientTag = get(e, ['inputs', '0'], '');

						if (isSelectedPillTagName) {
							_patientTags = _.filter(_patientTags, (o) => {
								return o.id != selectedPatientTag;
							});
						}
					});
				}
				return _patientTags;
			} else return searchPatientTags;
		}
		return get(tag, 'condition.length', 0) > 1
			? conditions_categories[get(tag, 'key')]
			: []; //advanced_search_categories
	};

	const handleInputChange = (input) => {
		input = input.trim();
		const allowed_categories = suggestions || [];

		if (get(tags, 'length', 0) == 0 && input.length < 1) {
			setState((prevState) => {
				return { ...prevState, inputQuery: '' };
			});
		}

		if (
			(get(tags, 'length', 0) > 0 &&
				!(
					get(tags, ['0', 'key'], '') in
					{ date_of_service: 1, referral_program: 1 }
				)) ||
			input.length < 1
		) {
			setState((prevState) => {
				return { ...prevState, error_category: false };
			});
			return;
		}
		if (get(tags, 'length', 0) == 2) return;

		if (
			_.find(allowed_categories, function (o) {
				return o.name.toLowerCase() == input.trim().toLowerCase();
			})
		) {
			setState((prevState) => {
				return {
					...prevState,
					error_category: false,
					delimiters: [Keys.TAB, Keys.SPACE, Keys.COMMA, Keys.ENTER],
					inputQuery: input,
				};
			});
		} else if (
			allowed_categories.some(function (v) {
				return v.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
			})
		) {
			setState((prevState) => {
				return {
					...prevState,
					error_category: false,
					delimiters: [Keys.TAB, Keys.COMMA, Keys.ENTER],
					inputQuery: input,
				};
			});
		} else {
			setState((prevState) => {
				return {
					...prevState,
					error_category: true,
					inputQuery: input,
					delimiters: [],
				};
			});
		}
	};

	const clearAllPills = () => {
		setState((prevState) => {
			return {
				...prevState,
				selected_pill: [],
				searchDisabled: true,
				is_lab_pill_created: false,
				is_test_name_pill_created: false,
				is_type_of_test_pill_created: false,
				is_multipatient_visit_pill_created: false,
				is_encounter_type_pill_created: false,
				lab_pill_created_value: '',
				test_name_pill_created_value: '',
				test_type_pill_created_value: '',
				multipatient_visit_created_value: '',
				encounter_type_created_value: '',
			};
		});
		advanced_search_categories = advanced_search_categories.map((e) => {
			e['disabled'] = false;
			return e;
		});
		resetSearchData();
		testNames();
		lab();
		testType();
		multiPatientStatus();
		encounterTypes();
		getPatientTags();
	};

	const getPlaceholderText = () => {
		const tagsList = tags;
		if (get(tagsList, 'length', []) == 0) {
			return 'Name, Referral Program, Phone Number';
		} else {
			const category_placeholder = get(
				_.last(tagsList),
				'placeholder',
				''
			);
			return category_placeholder;
		}
	};

	const clearPill = (id) => {
		const selected_pills = selected_pill;
		const pill_not_to_be_deleted = _.filter(selected_pills, function (o) {
			return o.id != id;
		});
		const pill_to_be_deleted = _.filter(selected_pills, function (o) {
			return o.id == id;
		});

		const tag_found = _.find(advanced_search_categories, function (o, idx) {
			return (
				o.key.toLowerCase() ==
				get(pill_to_be_deleted, [0, 'category', 'key'], '')
					.toLowerCase()
					.trim()
			);
		});

		if (
			get(pill_to_be_deleted, [0, 'category', 'key'], '') in
			{
				lab: 1,
				test_name: 1,
				type_of_test: 1,
				multipatient_visit: 1,
				encounter_type: 1,
			}
		) {
			setState((prevState) => {
				return {
					...prevState,
					['is_' +
					get(pill_to_be_deleted, [0, 'category', 'key'], '') +
					'_pill_created']: false,
					[get(pill_to_be_deleted, [0, 'category', 'key'], '') +
					'_pill_created_value']: get(
						pill_to_be_deleted,
						[0, 'inputs', 0],
						''
					),
				};
			});
			getUpdatedSuggestionsForDependentCategories(
				get(pill_to_be_deleted, [0, 'category', 'key'], ''),
				get(pill_to_be_deleted, [0, 'inputs', 0], ''),
				'delete'
			);
		}

		if (tag_found) {
			const selected_category_idx = _.findIndex(
				advanced_search_categories,
				function (o) {
					return o.id == tag_found.id;
				}
			);
			advanced_search_categories[selected_category_idx]['disabled'] =
				false;
		}

		setState((prevState) => {
			return {
				...prevState,
				selected_pill: pill_not_to_be_deleted
					? pill_not_to_be_deleted
					: [],
				searchDisabled: pill_not_to_be_deleted ? false : true,
			};
		});

		if (!pill_not_to_be_deleted || pill_not_to_be_deleted.length == 0) {
			setState((prevState) => {
				return {
					...prevState,
					searchDisabled: true,
				};
			});
			resetSearchData();
		} else {
			getSearchResults(0, pill_not_to_be_deleted, column, sort_column);
		}
	};

	const getTagComponent = (tag) => {
		const conditions = get(tag, 'tag.condition', []);
		if (conditions.length == 1) {
			return (
				<button className='react-tags__selected-tag'>
					<span className='react-tags__selected-tag-name'>
						{' '}
						{get(tag, 'tag.name', '') +
							' ' +
							get(conditions, [0], '')}{' '}
					</span>
				</button>
			);
		} else {
			return (
				<button className='react-tags__selected-tag'>
					<span className='react-tags__selected-tag-name'>
						{' '}
						{get(tag, 'tag.name', '')}{' '}
					</span>
				</button>
			);
		}
	};

	const santizePhone = (phone) => {
		return (phone || '').replace(/^\+1/, '').replace(/[^0-9]+/gi, '');
	};

	const getSearchResults = (offset, pills, order_by, order_type) => {
		const queryData = pills.map((e) => {
			const category = get(e, 'category.key', '');
			const inputs = get(e, 'inputs', []);
			const condition = get(e, 'condition', 'contains');
			return {
				category,
				condition:
					category == 'tag_display_name'
						? condition.split(' ').join('_')
						: condition,
				inputs:
					category == 'phone'
						? [santizePhone(get(inputs, ['0'], ''))]
						: inputs,
			};
		});
		setState((prevState) => {
			return {
				...prevState,
				searchDisabled: true,
			};
		});
		getSearchedResults({
			queryData: queryData,
			offset: offset,
			order_by: order_by,
			order_type: order_type,
			limit: DEFAULT_LIMIT,
		});
		setState((prevState) => {
			return { ...prevState, activePage: 0 };
		});
	};

	const handlePageChange = (e, offset) => {
		setState((prevState) => {
			return {
				...prevState,
				activePage: offset,
			};
		});
		getSearchResults(offset, selected_pill, column, sort_column);
	};

	const handleTableSort = (column, direction) => {
		setState((prevState) => {
			return {
				...prevState,
				column: column,
				sort_column: direction,
			};
		});
		getSearchResults(0, selected_pill, column, direction);
	};

	const getComponentSuggestions = () => {
		return (get(tags, 'length', 0) == 0 ||
			_.get(tags, ['0', 'key'], '') in
				{ referral_program: 1, tag_display_name: 1 } ||
			_.get(tags, ['0', 'key'], '') in single_categories ||
			_.get(_.last(tags), 'condition.length', 0) > 1) &&
			!error_category
			? suggestions
			: [];
	};

	const getAllowNew = () => {
		if (
			get(tags, ['0', 'key'], '') == 'referral_program' &&
			get(tags, ['1', 'name'], '') != 'contains'
		)
			return false;
		else if (get(tags, ['0', 'key'], '') in single_categories) return false;
		else if (get(tags, 'length', 0) == 0) return false;
		else return true;
	};

	const exportSearchResults = () => {
		const queryData = selected_pill.map((e) => {
			const category = get(e, 'category.key', '');
			const condition = get(e, 'condition', 'contains');
			return {
				category,
				condition:
					category == 'tag_display_name'
						? condition.split(' ').join('_')
						: condition,
				inputs:
					category == 'phone'
						? [santizePhone(get(e, ['inputs', '0'], ''))]
						: get(e, 'inputs', []),
			};
		});
		exportReport({
			queryData: queryData,
			order_type: sort_column,
			order_by: column,
		});
		enqueueSnackbar('The report will be sent to your email id.', {
			variant: 'success',
			className: 'export_notification',
			anchorOrigin: { horizontal: 'center', vertical: 'top' },
		});
	};
	return (
		<div className='advanced-search-container'>
			<div className='advanced-search-container-wrapper'>
				<div style={{ width: '100%' }}>
					<div style={{ minHeight: '3vw' }}>
						<PillBar
							searchpills={selected_pill}
							clearAllPills={clearAllPills}
							onDeletePill={clearPill}
							onDeleteClick={clearPill}
						/>
					</div>
					<div style={{ display: 'flex' }}>
						{
							<div
								className='search_tags_wrapper'
								style={
									error_category ||
									(get(inputQuery, 'length', 0) == 0 &&
										get(tags, 'length', 0) == 0)
										? {
												height: error_category
													? '22vw'
													: '19vw',
												borderRadius: '30px',
											}
										: {
												height: '3vw',
												borderRadius: '50px',
											}
								}
							>
								<div
									style={{
										paddingLeft: '1vw',
										width: '3.5vw',
									}}
								>
									{' '}
									<i className='material-icons search_enhaced'>
										{' '}
										search{' '}
									</i>{' '}
								</div>
								<div style={{ width: '93%' }}>
									<ReactTags
										handleDelete={handleDelete}
										handleAddition={(tag) =>
											!error_category &&
											handleAddition(tag)
										}
										tags={tags}
										tagComponent={(tag) =>
											getTagComponent(tag)
										}
										suggestions={getComponentSuggestions()}
										handleInputChange={handleInputChange}
										placeholder={getPlaceholderText()}
										allowNew={getAllowNew()}
										allowUnique={true}
										autocomplete={true}
										maxSuggestionsLength={get(
											suggestions,
											'length',
											10
										)}
										restrictInput={
											get(_.last(tags), 'key', '') in
												restrict_input_categories ||
											(get(tags, ['0', 'key'], '') in
												{ referral_program: 1 } &&
												get(tags, 'length', 0) == 2 &&
												get(tags, ['1', 'name'], '') !=
													'contains')
										}
										type={inputType}
										minQueryLength={
											get(tags, 'length', 0) == 0 ? 1 : 0
										}
										delimiters={delimiters}
									/>
									{(error_category ||
										(get(inputQuery, 'length', 0) == 0 &&
											get(tags, 'length', 0) == 0)) && (
										<ErrorComponent
											searchValue={inputQuery}
										/>
									)}
								</div>
							</div>
						}
						<div style={{ marginLeft: '2vw' }}>
							<Button
								disabled={
									get(selected_pill, 'length', 0) == 0 ||
									get(
										props,
										'searchedResults.data.length',
										0
									) == 0
								}
								className={
									'advanced_search_button' +
									(get(selected_pill, 'length', 0) == 0 ||
									get(searchedResults, 'data.length', 0) == 0
										? ''
										: ' active')
								}
								type='primary'
								onClick={(evt) => exportSearchResults()}
							>
								{/*<img className = 'patient_invoice_status' src= {export_img} alt="" style = {{height:'1vw', marginRight:'1vw', marginTop:'-0.3vw'}}/>*/}
								<div style={{ marginRight: '0.5vw' }}>
									<ReactSVG
										className={'menuItemIconWhite pop'}
										src={export_img}
									/>
								</div>
								<div>Export</div>
							</Button>
						</div>
					</div>
				</div>
				{get(selected_pill, 'length', 0) > 0 && searchDisabled && (
					<TableComponent
						selected_pill={selected_pill}
						data={get(props, 'searchedResults', [])}
						pageChange={handlePageChange}
						activePage={activePage || 0}
						sortList={handleTableSort}
						sort_column={sort_column}
						column={column}
						history={history}
					/>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		searchedResults: get(state, 'getResults.search_results.data', []),
		searchAffiliations: get(state, 'searchAffiliations.search_results', []),
		searchOrderingPhysician: get(
			state,
			'searchOrderingPhysicians.searchorderingPhysicians',
			[]
		),
		searchVisitProviders: get(
			state,
			'searchVisitProviders.searchvisitproviders',
			[]
		),
		searchSpecialtyEnums: get(state, 'searchSpecialty.searchspecialty', []),
		searchTestNames: get(state, 'searchTestNames.searchtestnames', []),
		searchTestResults: get(
			state,
			'searchTestResults.searchtestresults',
			[]
		),
		searchVisitStatus: get(
			state,
			'searchVisitStatus.searchvisitstatus',
			[]
		),
		searchActionability: get(
			state,
			'searchActionability.searchactionability',
			[]
		),
		searchLabs: get(state, 'searchLabs.advancedSearchLabs', []),
		searchOrderStatus: get(
			state,
			'searchOrderStatus.searchOrderStatus',
			[]
		),
		searchDispatchStatus: get(
			state,
			'searchDispatchStatus.searchdispatchstatus',
			[]
		),
		searchOutreachStatus: get(
			state,
			'searchOutreachStatus.searchoutreachstatus',
			[]
		),
		searchRequestDecision: get(
			state,
			'searchRequestDecision.searchrequestdecision',
			[]
		),
		searchReferringProviderName: get(
			state,
			'searchReferringProviderName.searchreferringprovidername',
			[]
		),
		searchVisitState: get(state, 'searchVisitState.searchvisitstate', []),
		searchMultiPatientStatus: get(
			state,
			'searchMultiPatientStatus.searchmultipatientstatus',
			[]
		),
		searchTestType: get(state, 'searchTestType.searchTestType', []),
		searchencounterTypes: get(
			state,
			'getEncounterTypes.searchEncounterTypes',
			[]
		),
		searchPatientTags: get(
			state,
			'searchPatientTags.advancedSearchPatientTags',
			[]
		),
	};
};

const mapDispatchToProps = (dispatch) => ({
	getSearchedResults: (data) => dispatch(getSearchedResults(data)),
	resetSearchData: () => dispatch(resetSearchData()),
	affiliation: (data) => dispatch(getSearchAffiliations(data)),
	orderingPhysician: (data) => dispatch(getSearchOrderingPhysicians(data)),
	visitProviders: (data) => dispatch(getSearchVisitProviders(data)),
	visitStatus: (data) => dispatch(getSearchVisitStatus(data)),
	actionability: (data) => dispatch(getActionability(data)),
	specialtyEnums: (data) => dispatch(getSearchSpecialty(data)),
	lab: (data) => dispatch(getEnhancedSearchLabs(data)),
	orderStatus: (data) => dispatch(getEnhancedSearchOrderStatus(data)),
	testType: (data) => dispatch(getEnhancedSearchTestType(data)),
	testNames: (data) => dispatch(getEnhancedSearchTestNames(data)),
	testResults: (data) => dispatch(getEnhancedSearchTestResults(data)),
	encounterTypes: (data) => dispatch(getEnhancedSearchEncounterTypes(data)),
	exportReport: (data) => dispatch(getSearchExportReport(data)),
	dispatchStatus: (data) => dispatch(getSearchDispatchStatus(data)),
	requestDecision: (data) => dispatch(getSearchRequestDecision(data)),
	referringProviderName: (data) =>
		dispatch(getSearchReferringProviderName(data)),
	stateOfVisit: (data) => dispatch(getSearchVisitState(data)),
	multiPatientStatus: (data) => dispatch(getMultiPatientStatus(data)),
	outreachStatus: () => dispatch(getSearchOutreachStatus()),
	getPatientTags: (data) => dispatch(getEnhancedSearchPatientTag(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedSearch);
