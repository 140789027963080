import * as actionTypes from './actionTypes';

export const resetsearchkey = () => {
	return (dispatch) =>
		dispatch({
			type: actionTypes.RESET_GLOBAL_SEARCH,
			payload: '',
		});
};
export const getsearchkey = (data) => {
	return {
		type: actionTypes.GLOBAL_SEARCH,
		payload: data,
	};
};
