import React from 'react';
import { NoteInputField } from './NoteInputField';

export const InternalNote = ({ values, onChange }) => (
	<div className='notes-div'>
		<h3>Internal Notes</h3>
		<p>
			Please enter any relevant internal notes associated with the
			patient.
		</p>
		<NoteInputField
			name='internalNotes'
			onChange={onChange('enteredInternalNote')}
			value={values?.enteredInternalNote}
		/>
	</div>
);
