//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Utils
import {
	isValidDateTimeString,
	dateTimeFormats,
} from './../V2/Outreachs/utilfunctions.js';

//UI Libraries
import { Select } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import { EncounterDateTimeField } from './../V2/Encounters/Common/EncounterDateTimeField.js';

class OutreachInputs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			removeFromOutcomeOptions: new Set([
				'patient_declined',
				'patient_scheduled',
				'email_sent',
			]),
		};
		//bindings
		this.renderInput = this.renderInput.bind(this);
		this.filterOutcomeOptions = this.filterOutcomeOptions.bind(this);
		this.canAdvance = this.canAdvance.bind(this);
		this.advance = this.advance.bind(this);
		this.getDropdownValue = this.getDropdownValue.bind(this);
		this.getDateTimeValue = this.getDateTimeValue.bind(this);
	}
	//functions

	filterOutcomeOptions() {
		const options = get(this, 'props.options', []) || [];
		if (Array.isArray(options)) {
			return options.filter((el) => {
				return !get(
					this,
					'state.removeFromOutcomeOptions',
					new Set()
				).has((get(el, 'name', '') || '').toLowerCase());
			});
		} else return [];
	}

	renderInput(el) {
		if (!el) return;
		switch (get(el, 'type', '')) {
			case 'select':
				return (
					<Select
						placeholder={get(el, 'placeholder', '') || 'Select'}
						onChange={(e) => {
							this.props.setValue(get(el, 'stateVar', ''), e);
						}}
						className='doNotOutreachAlertInputField updatedDoNotOutreachAlertInputField'
						value={this.getDropdownValue()}
					>
						{this.filterOutcomeOptions(
							get(this, 'props.options', []) || []
						).map((el) => {
							return (
								<Select.Option
									label={get(el, 'display_name', null)}
									value={
										get(el, 'name', null) ||
										get(el, 'key', null)
									}
									key={
										get(el, 'name', null) ||
										get(el, 'key', null)
									}
								>
									{get(el, 'display_name', null)}
								</Select.Option>
							);
						})}
					</Select>
				);
			case 'dateTime':
				return (
					<EncounterDateTimeField
						value={this.getDateTimeValue()}
						name='date_of_service'
						disabled={false}
						placeholder={
							get(el, 'placeholder', '') || 'Select Date & Time'
						}
						istouched={
							get(this, 'state.doNotOutreachDate', false)
								? true
								: false
						}
						onChange={(e) => {
							this.props.setValue(get(el, 'stateVar', ''), e);
						}}
						onBlur={(e) => {
							this.props.setValue(get(el, 'stateVar', ''), e);
						}}
						extraclassName={
							'doNotOutreachAlertInputField updatedDoNotOutreachAlertInputField'
						}
						disabledDate={(current) => {
							const momentcurrent = moment(current);
							return momentcurrent.isBefore(moment());
						}}
					/>
				);
			default:
				console.log('Unrecognized input.');
		}
	}

	getDropdownValue() {
		if (get(this, 'props.currentStage', '') === 'addOutreach')
			return get(this, 'props.selectedOptionAddOutreach', '');
		else if (get(this, 'props.currentStage', '') === 'doNotOutreach')
			return get(this, 'props.selectedOptionNoOutreach', '');
		else return '';
	}

	getDateTimeValue() {
		if (get(this, 'props.currentStage', '') === 'addOutreach') {
			return get(this, 'props.selectedTimeAddOutreach', null);
		} else if (get(this, 'props.currentStage', '') === 'doNotOutreach') {
			return get(this, 'props.selectedTimeNoOutreach', null);
		} else return null;
	}

	canAdvance() {
		if (get(this, 'props.currentStage', '') === 'addOutreach') {
			if (get(this, 'props.selectedOptionAddOutreach', '') === 'other') {
				return (
					get(this, 'props.selectedOptionAddOutreach', '') &&
					get(this, 'props.selectedTimeAddOutreach', '') &&
					isValidDateTimeString(
						get(this, 'props.selectedTimeAddOutreach', ''),
						dateTimeFormats
					) &&
					get(this, 'props.patientCreateAddOutreachNotes', '')
				);
			} else {
				return (
					get(this, 'props.selectedOptionAddOutreach', '') &&
					get(this, 'props.selectedTimeAddOutreach', '') &&
					isValidDateTimeString(
						get(this, 'props.selectedTimeAddOutreach', ''),
						dateTimeFormats
					)
				);
			}
		} else if (get(this, 'props.currentStage', '') === 'doNotOutreach') {
			return (
				get(this, 'props.selectedOptionNoOutreach', '') &&
				get(this, 'props.selectedTimeNoOutreach', '') &&
				isValidDateTimeString(
					get(this, 'props.selectedTimeNoOutreach', ''),
					dateTimeFormats
				)
			);
		} else if (get(this, 'props.currentStage', '') === 'schedule') {
			return true;
		} else {
			return false;
		}
	}

	advance() {
		if (this.canAdvance()) this.props.func();
	}

	render() {
		return (
			<div className='patientCreateOutreachModalSelectContainer'>
				<div
					style={{
						fontSize: '13px',
						fontFamily: 'RionaSans-Bold',
						color: '#262837',
						marginBottom: '8px',
					}}
				>
					{get(this, 'props.header', 'Please select')}
				</div>
				{(get(this, 'props.fields', []) || []).map((el, i) => {
					return (
						<div
							className='patientCreateOutreachInputContainer'
							key={get(el, 'header', i)}
							style={{ marginBottom: '4px' }}
						>
							{get(el, 'required', true) && (
								<div className='requiredInputField'>*</div>
							)}
							<div
								className='doNotOutreachAlertInputFieldHeader'
								style={{ color: '#262837' }}
							>
								{get(el, 'header', null)}
							</div>
							<div className='doNotOutreachAlertInputFieldRow updatedDoNotOutreachAlertInputFieldRow'>
								{this.renderInput(el)}
							</div>
						</div>
					);
				})}
				{get(this, 'props.currentStage', '') === 'addOutreach' &&
					get(this, 'props.selectedOptionAddOutreach', '') ===
						'other' && (
						<div
							className='patientCreateOutreachInputContainer'
							style={{ marginBottom: '18px' }}
						>
							<div className='requiredInputField'>*</div>
							<div
								className='doNotOutreachAlertInputFieldHeader'
								style={{ color: '#262837' }}
							>
								Notes
							</div>
							<div className='doNotOutreachAlertInputFieldRow updatedDoNotOutreachAlertInputFieldRow'>
								<textarea
									value={get(
										this,
										'props.patientCreateAddOutreachNotes',
										''
									)}
									onChange={(e) => {
										this.props.setValue(
											'patientCreateAddOutreachNotes',
											get(e, 'target.value', '')
										);
									}}
									className='patientCreateOutreachTextarea'
								/>
							</div>
						</div>
					)}
				<div className='patientCreateOutreachButtonRow'>
					<div
						className={get(this, 'props.buttons.back.class', '')}
						onClick={this.props.goBack}
					>
						{get(this, 'props.buttons.back.text', 'Back')}
					</div>
					<div
						className={
							this.canAdvance()
								? get(this, 'props.buttons.confirm.class', '')
								: get(
										this,
										'props.buttons.confirm.disabled',
										''
								  )
						}
						onClick={this.advance}
					>
						{get(this, 'props.buttons.confirm.text', 'Confirm')}
					</div>
				</div>
			</div>
		);
	}
}

export default OutreachInputs;
