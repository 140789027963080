//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Utils
import { patient_theme } from './../../utils.js';

//UI Libraries
import { Loading, Checkbox } from 'gm-element-react';

//Components
import TestAuthTableBody from './TestAuthTableBody.js';
import { Table, Thead, Tbody, Tr, Th } from '../Common/Table';

class TestAuthTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			currentTotal: 0,
		};
		//bindings
	}
	//functions

	render() {
		const { tests } = this.props;
		const testAuthSearchLoading = get(
			this,
			'props.testAuthSearchLoading',
			false
		);
		const gettingGeneticTestsDropdown = get(
			this,
			'props.gettingGeneticTestsDropdown',
			false
		);
		const encounterEditLoading = get(
			this,
			'props.encounterEditLoading',
			false
		);
		const testBulkUpdating = get(this, 'props.testBulkUpdating', false);
		const testOrderUpdating = get(this, 'props.testOrderUpdating', false);
		const geneTestOrderStatusEnumLoading = get(
			this,
			'props.geneTestOrderStatusEnumLoading',
			false
		);
		const sexAssignedAtBirthLoading = get(
			this,
			['props', 'sexAssignedAtBirthLoading'],
			false
		);
		const selectableData = get(this, 'props.tests.data', []).filter(
			(data) => data.can_change_order
		);
		const allSelected =
			selectableData.length &&
			selectableData.length ===
				get(this, 'props.selectedTests.length', null);
		const sortBy = {
			key: get(this, ['props', 'order_by'], ''),
			value: get(this, ['props', 'order_type'], ''),
		};
		const pagination = {
			total: get(tests, 'total', 0) || get(this, 'state.currentTotal', 0),
			limit: this.props.limit,
			end: get(tests, 'end', 0),
			has_next: get(tests, 'has_next', true),
			has_prev: get(tests, 'has_prev', false),
			start: get(tests, 'start', 0),
			total: get(tests, 'total', 0),
			offset: get(tests, 'offset', 0),
		};
		const isLoading =
			testAuthSearchLoading ||
			gettingGeneticTestsDropdown ||
			encounterEditLoading ||
			testBulkUpdating ||
			testOrderUpdating ||
			geneTestOrderStatusEnumLoading ||
			sexAssignedAtBirthLoading ||
			get(this, 'state.loading');

		if (isLoading) {
			return <Loading loading={true} className='testAuthTableSpinner' />;
		} else {
			return (
				<div>
					<Table
						style={{ marginTop: '16px', minWidth: '1204px' }}
						loading={isLoading}
						backToTop
						resultsPerPage={[25, 50]}
						handleResultsPerPageChange={
							this.props.handleResultsPerPageChange
						}
						pagination={pagination}
						handlePageChange={this.props.handlePageChange}
						hasData={get(this, 'props.tests.data.length', 0) > 0}
						handleSort={this.props.handleSort}
						sortBy={sortBy}
						limit={this.props.limit}
						handleResetFilters={this.props.handleResetFilters}
						noResultsFoundAltMessage={'No Test Requests Found'}
					>
						<Thead>
							<Tr>
								<Th>
									<Checkbox
										indeterminate={
											this.props.determineItemsSelected() &&
											!allSelected
										}
										checked={allSelected}
										onChange={(e) =>
											this.props.handleAllToggle(e)
										}
									/>
								</Th>
								<Th
									sortable={{
										date_test_requested: {
											display_name: 'Requested Date',
											sortBy: {
												asc: 'A to Z',
												desc: 'Z to A',
											},
										},
									}}
								>
									Request Date
								</Th>
								<Th
									sortable={{
										patient_name: {
											display_name: 'Patient',
											sortBy: {
												asc: 'A to Z',
												desc: 'Z to A',
											},
										},
									}}
								>
									Patient
								</Th>
								<Th />
								<Th
									sortable={{
										test_name: {
											display_name: 'Test Requested',
											sortBy: {
												asc: 'A to Z',
												desc: 'Z to A',
											},
										},
									}}
								>
									Test Requested
								</Th>
								<Th
									sortable={{
										red_flag: {
											display_name: 'Clinical Flag',
											sortBy: {
												asc: 'A to Z',
												desc: 'Z to A',
											},
										},
									}}
								>
									Clinical Flag
								</Th>
								<Th />
							</Tr>
						</Thead>
						<Tbody>
							<TestAuthTableBody
								tests={get(this, 'props.tests', {}) || {}}
								handleItemToggle={this.props.handleItemToggle}
								approveTest={this.props.approveTest}
								rejectTest={this.props.rejectTest}
								selectedTests={
									get(this, 'props.selectedTests', []) || []
								}
								sortedField={this.props.sortedField}
								redirectToEncounter={
									this.props.redirectToEncounter
								}
								sexAssignedAtBirthEnums={
									this.props.sexAssignedAtBirthEnums
								}
								orderStatusEnum={this.props.orderStatusEnum}
								showUpdateFlagStatusModal={
									this.props.showUpdateFlagStatusModal
								}
								showRedFlagAuditTrailModal={
									this.props.showRedFlagAuditTrailModal
								}
								getEncounterDocuments={
									this.props.getEncounterDocuments
								}
							/>
						</Tbody>
					</Table>
				</div>
			);
		}
	}
}

export default TestAuthTable;
