//React & Redux
import React from 'react';

//Images
import actionRequired from '../../../assets/action-required.png';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Layout } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Utils
import { getFromEnumOrFormat } from '../../../utils.js';

const ViewPatientInvoiceSegment = (props) => {
	const {
		billingStatusEnums = [],
		encounterBillingDetail: {
			billing_status: billingStatus = '',
			coupon_code: couponCode = '',
			patient_billing_amount: patientBillingAmount = null,
			patient_billing_date: patientBillingDate = '',
			patient_fee_refund: patientFeeRefund = null,
			patient_fee_refund_decision: patientFeeRefundDecision = null,
			patient_fee_refund_processed_date:
				patientFeeRefundProcessedDate = '',
			patient_fee_refund_reason: patientFeeRefundReason = null,
			patient_fee_refund_reason_other: patientFeeRefundReasonOther = null,
			patient_pay: patientPay = null,
			patient_payment: patientPayment = '',
		},
		encounterDetail: { billing_status: detailBillingStatus = '' },
		patientFeeEnum,
	} = props;

	const displayCouponCode = () =>
		_.get(couponCode, 'length', 0) > 0 ? couponCode : '--';

	const displayPaymentStatus = () => {
		let value = '--';
		if (!_.isNil(patientPayment) && !_.isEmpty(patientPayment)) {
			if (patientPayment == 'na') {
				value = 'N/A';
			} else {
				value = patientPayment;
			}
		}
		value = value.replace(/_/g, ' ');
		return value;
	};

	const displayDollarAmountNilCheck = (prop) =>
		!_.isNil(prop) ? '$' + prop : '--';

	const displayBillingStatement = () => {
		const invoiceObject = billingStatusEnums.find(
			(a) => a.key == billingStatus
		);
		return invoiceObject
			? invoiceObject.display_name || '--'
			: billingStatus || '--';
	};

	const displayBillingDate = () =>
		_.get(patientBillingDate, 'length', 0) > 0
			? moment(patientBillingDate).format('MMM D, YYYY')
			: '--';

	const displayPatientFeeRefundDecision = () =>
		!_.isNil(patientFeeRefundDecision)
			? _.startCase(patientFeeRefundDecision.replace('_', ' '))
			: '--';

	const displayPatientFeeRefundDate = () =>
		_.get(patientFeeRefundProcessedDate, 'length', 0) > 0
			? moment(patientFeeRefundProcessedDate).format('MMM D, YYYY')
			: '--';

	const displayPatientFeeRefundReason = () =>
		getFromEnumOrFormat(patientFeeEnum, patientFeeRefundReason, 'key');

	const displayPatientFeeRefundReasonOther = (shouldDisplayFlag) =>
		shouldDisplayFlag
			? patientFeeRefundReason === 'other'
			: patientFeeRefundReasonOther;

	return (
		<>
			<Layout.Row gutter='20'>
				<Layout.Col span='24'>
					<div className='test-number-container'>
						<div className='test-number'>Patient Invoice</div>
						<div className='divider'></div>
					</div>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20'>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Coupon Code</span>
					<span className='val-enctr-sgmnt-field'>
						{displayCouponCode()}
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Payment Status</span>
					<span className='val-enctr-sgmnt-field'>
						{displayPaymentStatus()}
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Payment Fee</span>
					<span className='val-enctr-sgmnt-field'>
						{displayDollarAmountNilCheck(patientPay)}
					</span>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20'>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>
						Billing Status (patient)
						{detailBillingStatus == 'not_invoiced' && (
							<img
								className='action-required'
								src={actionRequired}
								alt=''
							/>
						)}
					</span>
					<span className='val-enctr-sgmnt-field'>
						{displayBillingStatement()}
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Billing Date</span>
					<span className='val-enctr-sgmnt-field'>
						{displayBillingDate()}
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Billing Amount</span>
					<span className='val-enctr-sgmnt-field'>
						{displayDollarAmountNilCheck(patientBillingAmount)}
					</span>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20' className='patient-fee-section'>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Patient Fee Refund</span>
					<span className='val-enctr-sgmnt-field'>
						{displayDollarAmountNilCheck(patientFeeRefund)}
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>
						Patient Fee Refund Decision
					</span>
					<span className='val-enctr-sgmnt-field'>
						{displayPatientFeeRefundDecision()}
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>
						Patient Fee Refund Processed Date
					</span>
					<span className='val-enctr-sgmnt-field'>
						{displayPatientFeeRefundDate()}
					</span>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20' className='patient-fee-section'>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>
						Patient Fee Refund Reason
					</span>
					<span className='val-enctr-sgmnt-field'>
						{displayPatientFeeRefundReason()}
					</span>
				</Layout.Col>
				{displayPatientFeeRefundReasonOther(true) && (
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>
							Other Refund Reason
						</span>
						<span className='val-enctr-sgmnt-field'>
							{displayPatientFeeRefundReasonOther()}
						</span>
					</Layout.Col>
				)}
			</Layout.Row>
		</>
	);
};

export default ViewPatientInvoiceSegment;
