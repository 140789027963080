//React & Redux
import React, { Component } from 'react';
import PropTypes from 'prop-types';

//Lodash
import get from 'lodash/get';

//Components
import Pill from './Pill';
import ClearPill from './ClearPill';

//Styles
import './pill.css';

const PillBar = (props) => {
	const { searchpills, clearAllPills, onDeletePill } = props;

	const onclearClick = () => {
		clearAllPills();
	};

	const deletePill = (id) => {
		onDeletePill(id);
	};

	return (
		<div className='pillbar'>
			{searchpills.map((value, i) => {
				return (
					<Pill
						key={i}
						id={i}
						onDeletePill={(evt) => deletePill(get(value, 'id'))}
						{...value}
					/>
				);
			})}
			{get(searchpills, 'length', 0) > 0 && (
				<ClearPill onClick={(evt) => onclearClick()} />
			)}
		</div>
	);
};

PillBar.propTypes = {
	searchpills: PropTypes.array.isRequired,
};

export default PillBar;
