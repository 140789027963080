//React & Redux
import React, { useState } from 'react';

//Other Libraries
import _ from 'lodash';

//UI Libraries
import { Dialog, Button, Loading } from 'gm-element-react';

//Images
import warningicon from '../../assets/alert.svg';

//Styles
import './RoR-fu-declineddialog.css';

//Utils
import { createEnumsOptions } from '../Patients/V2/Encounters/Common/utilsfunctions';

//Components
import { SelectComponent as Select } from './SelectComponent';

const RoRFUDeclinedDialog = (props) => {
	const {
		encounterUUID,
		gcRoROutreachStopReasonsEnums,
		onCancel,
		onSubmit,
		patientDeclinedReasonsEnums,
		stopOutreachType = '',
	} = props;

	const [loading, setLoading] = useState(false);
	const [RoRReasonFUDecline, setRoRReasonFuDecline] = useState('');

	const handleChange = (value) => setRoRReasonFuDecline(value);

	const isBtnFUDeclinedDisabled = _.isEmpty(RoRReasonFUDecline);
	const outreachTypeIsPatientDeclined = stopOutreachType == 'patientDeclined';

	const handleSubmit = () => {
		let data;

		if (outreachTypeIsPatientDeclined) {
			data = {
				data: {
					outreach_status: 'followup_declined',
					followup_declined_reason: RoRReasonFUDecline,
				},
			};
		} else {
			data = {
				data: {
					outreach_status: 'outreach_stopped',
					outreach_stopped_reason: RoRReasonFUDecline,
				},
			};
		}

		setLoading(true);
		onSubmit(encounterUUID, data)
			.then(() => {
				setLoading(false);
				onCancel();
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const dialogSpanText =
		(outreachTypeIsPatientDeclined ? "Patient's " : "GC's ") +
		'Reason For Declining';
	const dialogTitle = (
		<span>
			<img src={warningicon} alt='' className='warningicon' />
			{dialogSpanText}
		</span>
	);

	return (
		<Dialog
			customClass='ror-fu-declined-dialog'
			title={dialogTitle}
			size='tiny'
			modal='true'
			closeOnPressEscape='true'
			closeOnClickModal='true'
			showClose={false}
			visible={true}
		>
			<Dialog.Body>
				<Loading loading={loading}>
					<span className='field-lable required'>
						{dialogSpanText}
					</span>
					<Select
						name='ror_reason_fu_decline'
						value={RoRReasonFUDecline}
						placeholder='Select'
						className='lst-ror-reason-decline-fu createDocumentInputSelect createDocumentV2TypeSelect'
						onChange={handleChange}
						typeahead={true}
						clearable={true}
						options={createEnumsOptions(
							props,
							stopOutreachType == 'patientDeclined'
								? 'patientDeclinedReasonsEnums'
								: 'gcRoROutreachStopReasonsEnums',
							'key',
							'display_name',
							'key'
						)}
					/>
				</Loading>
			</Dialog.Body>
			<Dialog.Footer>
				<Button className='btn-cancel' onClick={onCancel}>
					Cancel
				</Button>
				<Button
					className='btn-fu-declined'
					disabled={isBtnFUDeclinedDisabled}
					onClick={handleSubmit}
				>
					{outreachTypeIsPatientDeclined
						? 'Mark As F/U Declined'
						: 'Stop Outreach'}
				</Button>
			</Dialog.Footer>
		</Dialog>
	);
};

export default RoRFUDeclinedDialog;
