import { createContext, useEffect, useState } from 'react';

import ReferralDashboardService from '../../service/ReferralDashboard';

import { getDefaultFilters } from './components/filters/Filters';
import { ORDER_BY, ORDER_TYPE } from './components/Table';

export const ReferralPageContext = createContext({});

function useStatePersisted(storageKey, defaultValue) {
	const [data, setData] = useState(() => {
		const storageItem = window.localStorage.getItem(storageKey);
		if (storageItem) {
			try {
				return JSON.parse(storageItem);
			} catch {
				// Failed to decode item, fall back to defaultValue
			}
		}
		return defaultValue;
	});
	useEffect(() => {
		window.localStorage.setItem(storageKey, JSON.stringify(data));
	}, [data, storageKey]);

	return [data, setData];
}

function useFilters() {
	const [filters, setFilters] = useStatePersisted(
		'referralDashboardFilters',
		getDefaultFilters()
	);
	return [
		filters,
		(filter, value) =>
			setFilters((previousFilters) => ({
				...previousFilters,
				[filter]: value,
			})),
	];
}

function useOrder() {
	const [order, setOrder] = useStatePersisted('referralDashboardOrder', {
		order_by: ORDER_BY.CREATED_AT,
		order_type: ORDER_TYPE.DESC,
	});

	function invert(v) {
		return v === ORDER_TYPE.DESC ? ORDER_TYPE.ASC : ORDER_TYPE.DESC;
	}

	return [
		order,
		(by, defaultOrder) => {
			setOrder((o) => {
				let orderType = defaultOrder ?? ORDER_TYPE.DESC;
				if (by === o.order_by && o.order_type === orderType) {
					orderType = invert(orderType);
				}
				return { order_by: by, order_type: orderType };
			});
		},
	];
}

function useLimit() {	
	const [limit, setLimits] = useStatePersisted('referralDashboardLimit', 25);
	return [
		limit,
		(value) => 
			setLimits(value),
	];
}

export function useReferralDashboardData() {
	const [offset, setOffset] = useStatePersisted('referralDashboardOffset', 0);

	const [filters, setFilter] = useFilters();
	const [order, toggleOrder] = useOrder();
	const [limit, setLimit] = useLimit();

	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

	async function doFetch() {
		try {
			setLoading(true);
			setError(null);
			const result = await ReferralDashboardService.getReferralData({
				// `limit` and `order` properties next to each other causes WAF to block
				// the request
				limit: limit,
				...order,
				...filters,
				offset,
			});
			setData(result);
		} catch (e) {
			setError(e);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		// reset offset if filters change
		setOffset(0);
	}, [filters]);

	useEffect(() => {
		void doFetch();
	}, [offset, filters, order, limit]);

	return {
		data: data?.data,
		paging: data?.paging,
		loading,
		error,
		filters,
		setFilter,
		setOffset,
		order,
		toggleOrder,
		limit,
		setLimit,
		refetch: doFetch,
	};
}

export function useSelectedItems() {
	const [selectAll, setSelectAll] = useState(false);
	const [selectedIdx, setSelectedIdx] = useState({});

	return {
		selectAll: (items) => (checked) => {
			setSelectedIdx(
				items.reduce((acc, item) => {
					acc[item] = checked;
					return acc;
				}, {})
			);
			setSelectAll(checked);
		},
		isSelectAllChecked: selectAll,
		isChecked: (id) => selectedIdx[id] ?? false,
		toggle: (id) => (checked) => {
			setSelectedIdx((idx) => ({ ...idx, [id]: checked }));
		},
		reset: () => {
			setSelectedIdx({});
			setSelectAll(false);
		},
		selectedItems: Object.keys(selectedIdx).filter(
			(item) => selectedIdx[item]
		),
	};
}
