//React & Redux
import React, { Fragment, useState, useEffect, useRef } from 'react';

//Other Libraries
import {
	isEmpty,
	debounce,
	isNil,
	get,
	isNaN,
	toNumber,
	omitBy,
	groupBy,
	size,
	flatten,
	uniq,
} from 'lodash';
import {
	Dialog,
	Button,
	Layout,
	Select,
	Input,
	Loading,
	Popover,
} from 'gm-element-react';

//Images
import ReactSVG from 'react-svg';
import trashIcon from '../../../../assets/delete.svg';
import imageExpandCollapse from '../../../../assets/icons/black_chevron_arrow.svg';
import warningicon from '../../../../assets/warning.svg';
import infoIcon from '../../../../assets/icons/info.svg';
import errorIcon from '../../../Appointment/images/error.svg';
import moreinfoicon from '../../../../assets/purple-more-info.svg';
//Styles
import './css/add-edit-discrete-variant-modal.css';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious';

//Other Libraries
import classnames from 'classnames';

//Components
import Icon from '../../../Common/Icon';
import RadioGroup from '../../../Common/controls/RadioGroup/RadioGroup';

//Utils
import {
	nomenclatureRadioEnum,
	allelicStateEnum,
	labClassificationEnum,
	dnaChangeTypeEnum,
	proteinChangeTypeEnum,
	genomicSourceClassEnum,
	originEnum,
	mosaicismEnum,
} from '../../../../utils';

const fieldHightlightValidationMapping = {
	transcriptReferenceSequence: 'referenceSequence',
	dnaChange: 'dnaChangeDescription',
	allelicState: 'allelicState',
	referenceAssemblyCode: 'referenceAssembly',
	cytogenomicNomenclature: 'sequence',
	proteinChange: 'proteinChangeDescription',
	originalReportedClassification: 'labClassification',
	dnaChangeType: 'dnaChangeType',
	proteinChangeType: 'proteinChangeType',
	allelicFrequency: 'sampleAllelicFrequency',
	genomicSourceClass: 'genomicSourceClass',
	mosaicism: 'mosaicism',
	origin: 'origin',
};

const AddEditDiscreteVariantModal = (props) => {
	const {
		hideAddEditDiscreteVariantModalFunc,
		modalMode = 'add',
		getReferenceAssembliesEnum,
		referenceAssembliesEnum,
		saveDiscreteVariantFinding,
		getGeneticResultGenesSymbol,
		discreteVariantFindings = {},
		geneticResultGenesSymbolEnum = [],
		resetGetGeneticResultGenesSymbol,
		deleteDiscreteVariantFinding,
		labUUID = null,
		getDiagnosticFindingValidation,
		diagnosticFindingValidationText,
		diagnosticFindingValidationLoading,
		diagnosticFindingValidationError,
		resetGetDiagnosticFindingValidation,
	} = props;

	const [formdata, setFormdata] = useState({
		geneId: 0,
		allelicState: null,
		referenceAssembly: 'grch37',
		dnaChangeDescription: null,
		referenceSequence: null,
		proteinChangeDescription: null,
		sequence: null,
		labClassification: null,
		dnaChangeType: null,
		proteinChangeType: null,
		sampleAllelicFrequency: null,
		genomicSourceClass: null,
		mosaicism: null,
		origin: null,
	});
	const [nomenclature, setNomenclature] = useState('HGVS');
	const [genesSearchLoading, setGenesSearchLoading] = useState(false);
	const [optionsGenesSymbol, setOptionsGenesSymbol] = useState([]);
	const [errors, setErrors] = useState({
		errors: {},
	});
	const [isDisableSaveButton, setIsDisableSaveButton] = useState(true);
	const [geneSymbolLabel, setGeneSymbolLabel] = useState('');
	const [loading, setLoading] = useState(diagnosticFindingValidationLoading);
	const [showErrorMessageSection, setShowErrorMessageSection] =
		useState(false);
	const [fieldHighlightForError, setFieldHighlightForError] = useState([]);

	const prevDiagnosticFindingValidationText = usePrevious(
		diagnosticFindingValidationText
	);
	const prevDiagnosticFindingValidationLoading = usePrevious(
		diagnosticFindingValidationLoading
	);

	const validationMessageContainerRef = useRef();

	useEffect(() => {
		getReferenceAssembliesEnum();

		if (
			isEditMode() &&
			!isNil(discreteVariantFindings?.genesSymbol?.symbol) &&
			!isEmpty(discreteVariantFindings?.genesSymbol?.symbol)
		) {
			const data = {
				query: discreteVariantFindings?.genesSymbol?.symbol,
				referenceAssembly:
					discreteVariantFindings?.referenceAssembly?.code,
				labId: labUUID,
			};
			getGeneticResultGenesSymbol(data);
			setGeneSymbolLabel(discreteVariantFindings?.genesSymbol?.symbol);
		}

		if (
			!isEmpty(discreteVariantFindings) &&
			!isNil(discreteVariantFindings) &&
			isEditMode()
		) {
			setFormdata({
				geneId: discreteVariantFindings?.genesSymbol?.id || 0,
				allelicState: discreteVariantFindings?.allelicState || null,
				referenceAssembly:
					discreteVariantFindings?.referenceAssembly?.code ||
					'grch37',
				dnaChangeDescription:
					discreteVariantFindings?.dnaChangeDescription || null,
				referenceSequence:
					discreteVariantFindings?.referenceSequence || null,
				proteinChangeDescription:
					discreteVariantFindings?.proteinChangeDescription || null,
				sequence: discreteVariantFindings?.sequence || null,
				labClassification:
					discreteVariantFindings?.labClassification || null,
				dnaChangeType: discreteVariantFindings?.dnaChangeType || null,
				proteinChangeType:
					discreteVariantFindings?.proteinChangeType || null,
				sampleAllelicFrequency:
					discreteVariantFindings?.sampleAllelicFrequency || null,
				genomicSourceClass:
					discreteVariantFindings?.genomicSourceClass || null,
				origin: discreteVariantFindings?.origin || null,
				mosaicism: discreteVariantFindings?.mosaicism || null,
			});
			setNomenclature(
				discreteVariantFindings?.hasHgvsNomenclature || false
					? 'HGVS'
					: 'ISCN'
			);
		}

		return () => {
			resetGetGeneticResultGenesSymbol();
			resetGetDiagnosticFindingValidation();
		};
	}, []);

	useEffect(() => {
		if (formdata['geneId'] != 0) {
			const data = {
				query: geneSymbolLabel,
				referenceAssembly: formdata?.referenceAssembly,
				labId: labUUID,
			};
			getGeneticResultGenesSymbol(data);
		}
	}, [formdata?.referenceAssembly]);

	useEffect(() => {
		if (formdata.geneId != 0) {
			geneticResultGenesSymbolEnum.forEach((gene) => {
				setFormdata((state) => ({
					...state,
					referenceSequence:
						gene.suggestedTranscriptReferenceSequence || null,
				}));
			});
		}
	}, [geneticResultGenesSymbolEnum]);

	useEffect(() => {
		if (
			diagnosticFindingValidationLoading !=
			prevDiagnosticFindingValidationLoading
		) {
			setLoading(diagnosticFindingValidationLoading);
		}
	}, [diagnosticFindingValidationLoading]);

	useEffect(() => {
		if (
			diagnosticFindingValidationText !=
				prevDiagnosticFindingValidationText &&
			get(diagnosticFindingValidationText, ['isValid'], false) == true
		) {
			setIsDisableSaveButton(false);
		}
		if (
			diagnosticFindingValidationText !=
				prevDiagnosticFindingValidationText &&
			!isEmpty(get(diagnosticFindingValidationText, ['issues'], []))
		) {
			const issues = get(diagnosticFindingValidationText, ['issues'], []);
			let paths = issues.map((item) => {
				if (size(get(item, ['paths', '0'], [])) > 0) {
					return get(item, ['paths', '0'], []);
				}
			});
			paths = uniq(flatten(paths));
			const fieldNameArray = paths.map((p) => {
				return fieldHightlightValidationMapping[p];
			});

			setShowErrorMessageSection(true);
			setFieldHighlightForError(fieldNameArray);

			setTimeout(() => {
				validationMessageContainerRef.current.scrollIntoView(true);
			}, 80);
		} else {
			setFieldHighlightForError([]);
		}
	}, [diagnosticFindingValidationText]);

	const isEditMode = () => {
		return modalMode == 'edit';
	};

	const getQueryGeneticResultGene = debounce((query) => {
		const data = {
			query: query,
			referenceAssembly: formdata?.referenceAssembly || 'grch37',
			labId: labUUID,
		};
		getGeneticResultGenesSymbol(data);
		setGeneSymbolLabel(query);
	}, 500);

	const closeAddEditDiscreteVariantModal = () => {
		hideAddEditDiscreteVariantModalFunc();
	};

	const validateForm = (formdata) => {
		const errors = {};

		if (formdata['geneId'] == 0) {
			errors['geneId'] = 'Please select gene symbol';
		}

		if (isEmpty(formdata['allelicState'])) {
			errors['allelicState'] = 'Allelic State is required';
		}

		if (
			nomenclature === 'HGVS' &&
			(isNil(formdata['dnaChangeDescription']) ||
				isEmpty(formdata['dnaChangeDescription']))
		) {
			errors['dnaChangeDescription_empty'] = 'DNA Change is required';
		}

		if (
			nomenclature === 'HGVS' &&
			(isNil(formdata['referenceSequence']) ||
				isEmpty(formdata['referenceSequence']))
		) {
			errors['referenceSequence'] = 'Reference Sequence is required';
		}

		if (
			nomenclature === 'ISCN' &&
			(isNil(formdata['sequence']) || isEmpty(formdata['sequence']))
		) {
			errors['sequence'] = 'Sequence is required';
		}

		if (isEmpty(formdata['labClassification'])) {
			errors['labClassification'] = 'Lab Classification is required';
		}

		if (
			!isNil(formdata['sampleAllelicFrequency']) &&
			!isEmpty(formdata['sampleAllelicFrequency']) &&
			(toNumber(formdata['sampleAllelicFrequency']) <= 0 ||
				toNumber(formdata['sampleAllelicFrequency']) >= 1)
		) {
			errors['sampleAllelicFrequency'] =
				'Please enter a decimal number between 0 and 1';
		}

		if (
			!isNil(formdata['dnaChangeDescription']) &&
			!isEmpty(formdata['dnaChangeDescription']) &&
			!formdata['dnaChangeDescription'].match(
				/^[cmn]\.\S[^\t\n\r\f\v]{0,}$/
			)
		) {
			errors['dnaChangeDescription'] = 'Incorrect entry format';
		}

		if (
			!isNil(formdata['proteinChangeDescription']) &&
			!isEmpty(formdata['proteinChangeDescription']) &&
			!formdata['proteinChangeDescription'].match(
				/^p\.\S[^\t\n\r\f\v]{0,}$/
			)
		) {
			errors['proteinChangeDescription'] = 'Incorrect entry format';
		}
		return errors;
	};

	const selectNomenclatureFunc = (nomenclature) => {
		setFormdata(formdata);
		setNomenclature(nomenclature);
	};

	const onFormChange = (key, value) => {
		let errors = {};
		formdata[key] = value;
		errors = validateForm(formdata);
		setFormdata(formdata);
		setErrors(errors);
		setIsDisableSaveButton(true);
		if (key == 'geneId') {
			if (!isEmpty(geneticResultGenesSymbolEnum)) {
				geneticResultGenesSymbolEnum.forEach((gene) => {
					if (gene.id && gene.id === value) {
						setFormdata({
							...formdata,
							referenceSequence:
								gene.suggestedTranscriptReferenceSequence ||
								null,
						});
					}
				});
			}
		}
	};

	const onSampleAllelicFrequencyChange = (key, val) => {
		!isNaN(toNumber(val)) && onFormChange(key, val);
	};

	const onGenesSymbolSearch = (query) => {
		if (query !== '') {
			setGenesSearchLoading(false);
			if (query.length > 1) getQueryGeneticResultGene(query);
		} else {
			formdata['geneId'] = 0;
			setOptionsGenesSymbol([]);
			setFormdata(formdata);
		}
	};

	const isDisableSavedButtonFunc = () => {
		const errors = validateForm(formdata);
		if (
			!isNil(diagnosticFindingValidationText) &&
			!isEmpty(diagnosticFindingValidationText) &&
			get(diagnosticFindingValidationText, ['isValid'], false) == true &&
			isEmpty(errors) &&
			!isDisableSaveButton &&
			!loading
		) {
			return false;
		} else {
			return true;
		}
	};

	const isDisableCheckEntriesButton = () => {
		const errors = validateForm(formdata);
		const _discreteVariantFindings = discreteVariantFindings;
		const _changeFields = omitBy(formdata, (value, key) => {
			if (isNil(value)) {
				return true;
			}
			if (key == 'geneId') {
				return (
					get(_discreteVariantFindings, ['genesSymbol', 'id'], 0) ==
					value
				);
			}
			if (key == 'referenceAssembly') {
				return (
					get(
						_discreteVariantFindings,
						['referenceAssembly', 'code'],
						'grch37'
					) == value
				);
			}
			return _discreteVariantFindings[key] == value;
		});

		if (isEmpty(_changeFields) || !isEmpty(errors) || loading) {
			return true;
		}
		return false;
	};

	const saveTestResultData = () => {
		const _formdata = formdata;

		closeAddEditDiscreteVariantModal();

		if (nomenclature === 'ISCN') {
			_formdata['dnaChangeDescription'] = null;
			_formdata['referenceSequence'] = null;
			_formdata['proteinChangeDescription'] = null;
		}

		if (nomenclature === 'HGVS') {
			_formdata['sequence'] = null;
		}

		if (get(_formdata, 'genomicSourceClass') === null) {
			_formdata['genomicSourceClass'] = 'UNKNOWN';
		}

		if (get(_formdata, 'origin') === null) {
			_formdata['origin'] = 'UNKNOWN';
		}

		saveDiscreteVariantFinding(
			{
				..._formdata,
				uuid: discreteVariantFindings?.uuid || null,
			},
			isEditMode()
		);
	};

	const _deleteDiscreteVariantFinding = () => {
		const diagnosticFindingId = discreteVariantFindings?.uuid || null;

		if (diagnosticFindingId) {
			closeAddEditDiscreteVariantModal();
			deleteDiscreteVariantFinding(diagnosticFindingId);
		}
	};

	const checkEntries = () => {
		const _formdata = formdata;
		setLoading(true);
		if (nomenclature === 'ISCN') {
			_formdata['dnaChangeDescription'] = null;
			_formdata['referenceSequence'] = null;
			_formdata['proteinChangeDescription'] = null;
		}

		if (nomenclature === 'HGVS') {
			_formdata['sequence'] = null;
		}

		getDiagnosticFindingValidation({
			..._formdata,
			genomicDNAChange: discreteVariantFindings?.genomicDNAChange || null,
			genomicReferenceSequence:
				discreteVariantFindings?.genomicReferenceSequence || null,
			proteinReferenceSequence:
				discreteVariantFindings?.proteinReferenceSequence || null,
		});
	};

	const displayFindingValidationMessage = () => {
		if (!isEmpty(get(diagnosticFindingValidationText, ['issues'], []))) {
			const issues = get(diagnosticFindingValidationText, ['issues'], []);
			const groupByIssues = groupBy(issues, 'severity');
			const errorMessageArray = groupByIssues['ERROR'];
			const warningMessageArray = groupByIssues['WARNING'];
			const infoMessageArray = groupByIssues['INFO'];

			return (
				<div
					className='validation-message-container'
					ref={validationMessageContainerRef}
				>
					{_.isArray(errorMessageArray) &&
						size(errorMessageArray) > 0 && (
							<Fragment>
								<span className='message-type-title'>
									<img
										src={errorIcon}
										alt=''
										className='warning-icon'
									/>
									<span>
										{size(errorMessageArray) > 1
											? 'Variants invalid'
											: 'Variant invalid'}
									</span>
								</span>
								{errorMessageArray.map((data) => {
									const isDetailMessage =
										!isEmpty(
											get(data, ['detailMessage'], '')
										) &&
										!isNil(
											get(data, ['detailMessage'], '')
										);
									return (
										<div>
											<span className='error-message'>
												{data.message}
											</span>
											{isDetailMessage && (
												<Popover
													trigger='hover'
													popperClass={classnames(
														'pp-variant-validation'
													)}
													content={data.detailMessage}
													key='popover-variant-validation'
													placement='top'
												>
													<span className='tooltip-info'>
														<img
															src={moreinfoicon}
															className='more-info-icon'
														/>
													</span>
												</Popover>
											)}
										</div>
									);
								})}
							</Fragment>
						)}
					{_.isArray(warningMessageArray) &&
						size(warningMessageArray) > 0 && (
							<Fragment>
								<span className='message-type-title'>
									<img
										src={warningicon}
										alt=''
										className='warning-icon'
									/>
									<span>
										{size(warningMessageArray) > 1
											? 'Warning Messages'
											: 'Warning Message'}
									</span>
								</span>
								{warningMessageArray.map((data) => {
									const isDetailMessage =
										!isEmpty(
											get(data, ['detailMessage'], '')
										) &&
										!isNil(
											get(data, ['detailMessage'], '')
										);
									return (
										<div>
											<span>{data.message}</span>
											{isDetailMessage && (
												<Popover
													trigger='hover'
													popperClass={classnames(
														'pp-variant-validation'
													)}
													content={data.detailMessage}
													key='popover-variant-validation'
													placement='top'
												>
													<span className='tooltip-info'>
														<img
															src={moreinfoicon}
															className='more-info-icon'
														/>
													</span>
												</Popover>
											)}
										</div>
									);
								})}
							</Fragment>
						)}
					{_.isArray(infoMessageArray) && size(infoMessageArray) > 0 && (
						<Fragment>
							<span className='message-type-title'>
								<img
									src={infoIcon}
									alt=''
									className='info-icon'
								/>
								<span>
									{size(infoMessageArray) > 1
										? 'Info Messages'
										: 'Info Message'}
								</span>
							</span>
							{infoMessageArray.map((data) => {
								const isDetailMessage =
									!isEmpty(
										get(data, ['detailMessage'], '')
									) &&
									!isNil(get(data, ['detailMessage'], ''));
								return (
									<div>
										<span className='info-message'>
											{data.message}
										</span>
										{isDetailMessage && (
											<Popover
												trigger='hover'
												popperClass={classnames(
													'pp-variant-validation'
												)}
												content={data.detailMessage}
												key='popover-variant-validation'
												placement='top'
											>
												<span className='tooltip-info'>
													<img
														src={moreinfoicon}
														className='more-info-icon'
													/>
												</span>
											</Popover>
										)}
									</div>
								);
							})}
						</Fragment>
					)}
				</div>
			);
		} else {
			return '';
		}
	};

	return (
		<Dialog
			customClass='add-edit-discrete-variant-dialog'
			title={
				<span className='header'>
					<span>
						{isEditMode() ? 'Edit' : 'Add New'} Discrete Variant
						Finding
					</span>
					<span
						className='close-btn-icon'
						onClick={closeAddEditDiscreteVariantModal}
					>
						<Icon icon='close' className='close-icon' />
					</span>
				</span>
			}
			size='tiny'
			modal={true}
			closeOnPressEscape={true}
			closeOnClickModal={false}
			showClose={false}
			visible={true}
			onCancel={closeAddEditDiscreteVariantModal}
		>
			<Dialog.Body>
				<Loading loading={loading}>
					<section className='basic-information-module'>
						<h2 className='module-header'>Basic Information</h2>
						<Layout.Row gutter='12'>
							<Layout.Col span='8' className='required'>
								<span>Gene Symbol</span>
								<Select
									name='geneId'
									value={formdata.geneId}
									onChange={(value) =>
										onFormChange('geneId', value)
									}
									filterable={true}
									remote={true}
									clearable={true}
									placeholder='Gene Symbol'
									remoteMethod={(val) =>
										onGenesSymbolSearch(val)
									}
									loading={genesSearchLoading}
								>
									{geneticResultGenesSymbolEnum.map((el) => {
										return (
											<Select.Option
												key={el.id}
												label={el.symbol}
												value={el.id}
											/>
										);
									})}
								</Select>
							</Layout.Col>
							<Layout.Col span='8' className='required'>
								<span>Allelic State</span>
								<Select
									name='allelicState'
									value={formdata?.allelicState}
									onChange={(value) =>
										onFormChange('allelicState', value)
									}
									placeholder='Select'
									className={
										fieldHighlightForError.includes(
											'allelicState'
										)
											? 'validation-field-hightlight'
											: ''
									}
								>
									{allelicStateEnum.map((item, idx) => {
										return (
											<Select.Option
												key={idx}
												label={item.display_name}
												value={item.key}
											/>
										);
									})}
								</Select>
							</Layout.Col>
							<Layout.Col span='8' className='required'>
								<span>Reference Assembly</span>
								<Select
									name='referenceAssembly'
									value={
										formdata?.referenceAssembly || 'grch37'
									}
									onChange={(value) =>
										onFormChange('referenceAssembly', value)
									}
									placeholder='Select'
									className={
										fieldHighlightForError.includes(
											'referenceAssembly'
										)
											? 'validation-field-hightlight'
											: ''
									}
								>
									{referenceAssembliesEnum &&
										referenceAssembliesEnum.map(
											(item, idx) => {
												return (
													<Select.Option
														key={idx}
														label={item.name}
														value={item.code}
													/>
												);
											}
										)}
								</Select>
							</Layout.Col>
						</Layout.Row>
					</section>

					<section className='identification-module'>
						<h2 className='module-header'>Identification</h2>
						<Layout.Row>
							<Layout.Col span='4'>
								<span className='nomenclature-label'>
									Nomenclature
								</span>
							</Layout.Col>
							<Layout.Col span='12'>
								<RadioGroup
									options={nomenclatureRadioEnum}
									value={nomenclature || ''}
									onChange={selectNomenclatureFunc}
								/>
							</Layout.Col>
						</Layout.Row>

						{(nomenclature || '') == 'HGVS' && (
							<Fragment>
								<Layout.Row gutter='12'>
									<Layout.Col span='16'>
										<Layout.Row
											gutter='12'
											className='mt-zero'
										>
											<Layout.Col>
												<span className='dnaChange-label'>
													DNA Change
												</span>
											</Layout.Col>
										</Layout.Row>
										<Layout.Row
											gutter='12'
											className='mt-zero'
										>
											<Layout.Col span='12'>
												<span className='required'>
													Description (c., n., or m.)
												</span>
												<Input
													name='dnaChangeDescription'
													id='dnaChangeDescription'
													value={
														formdata?.dnaChangeDescription
													}
													onChange={(value) =>
														onFormChange(
															'dnaChangeDescription',
															value
														)
													}
													placeholder='Enter change description'
													className={
														fieldHighlightForError.includes(
															'dnaChangeDescription'
														)
															? 'validation-field-hightlight'
															: ''
													}
												/>
												<div className='input-error-text'>
													{get(
														errors,
														[
															'dnaChangeDescription',
														],
														''
													)}
												</div>
											</Layout.Col>
											<Layout.Col
												span='12'
												className='required'
											>
												<span>Reference Sequence</span>
												<Input
													name='referenceSequence'
													id='referenceSequence'
													value={
														formdata?.referenceSequence
													}
													onChange={(value) =>
														onFormChange(
															'referenceSequence',
															value
														)
													}
													placeholder='Enter reference sequence'
													className={
														fieldHighlightForError.includes(
															'referenceSequence'
														)
															? 'validation-field-hightlight'
															: ''
													}
												/>
											</Layout.Col>
										</Layout.Row>
									</Layout.Col>
									<Layout.Col
										span='8'
										style={{
											paddingLeft: '25px',
											paddingRight: '6px',
											borderLeft: '1px solid #d8dde4',
											marginLeft: '-20px',
										}}
									>
										<Layout.Row
											gutter='12'
											className='mt-zero'
										>
											<Layout.Col>
												<span className='proteinChange-label'>
													Protein Change
												</span>
											</Layout.Col>
										</Layout.Row>
										<Layout.Row
											gutter='12'
											className='mt-zero'
										>
											<Layout.Col>
												<span>Description (p.)</span>
												<Input
													name='proteinChangeDescription'
													id='proteinChangeDescription'
													value={
														formdata?.proteinChangeDescription
													}
													onChange={(value) =>
														onFormChange(
															'proteinChangeDescription',
															value
														)
													}
													placeholder='Enter change description'
													className={
														fieldHighlightForError.includes(
															'proteinChangeDescription'
														)
															? 'validation-field-hightlight'
															: ''
													}
												/>
												<div className='input-error-text'>
													{get(
														errors,
														[
															'proteinChangeDescription',
														],
														''
													)}
												</div>
											</Layout.Col>
										</Layout.Row>
									</Layout.Col>
								</Layout.Row>
							</Fragment>
						)}

						{(nomenclature || '') == 'ISCN' && (
							<Layout.Row gutter='12'>
								<Layout.Col span='16' className='required'>
									<span>Sequence</span>
								</Layout.Col>
								<Layout.Col span='16'>
									<Input
										name='sequence'
										id='sequence'
										value={formdata?.sequence}
										onChange={(value) =>
											onFormChange('sequence', value)
										}
										placeholder='Enter sequence'
										className={
											'iscn-sequence ' +
											(fieldHighlightForError.includes(
												'sequence'
											)
												? 'validation-field-hightlight'
												: '')
										}
									/>
								</Layout.Col>
							</Layout.Row>
						)}
					</section>

					<section className='interpretation-module'>
						<h2 className='module-header'>Interpretation</h2>
						<Layout.Row>
							<Layout.Col span='8' className='required'>
								<span>Lab Classification</span>
								<Select
									name='labClassification'
									value={formdata?.labClassification}
									onChange={(value) =>
										onFormChange('labClassification', value)
									}
									placeholder='Select'
									className={
										fieldHighlightForError.includes(
											'labClassification'
										)
											? 'validation-field-hightlight'
											: ''
									}
								>
									{labClassificationEnum.map((item, idx) => {
										return (
											<Select.Option
												key={idx}
												label={item.display_name}
												value={item.key}
											/>
										);
									})}
								</Select>
							</Layout.Col>
						</Layout.Row>
					</section>

					<section className='additional-information-module'>
						<h2 className='module-header'>
							Additional Information
						</h2>
						<Layout.Row gutter='12'>
							<Layout.Col span='8'>
								<span>DNA Change Type</span>
								<Select
									name='dnaChangeType'
									value={formdata?.dnaChangeType}
									onChange={(value) =>
										onFormChange('dnaChangeType', value)
									}
									placeholder='Select change type'
									className={
										fieldHighlightForError.includes(
											'dnaChangeType'
										)
											? 'validation-field-hightlight'
											: ''
									}
								>
									{dnaChangeTypeEnum.map((item, idx) => {
										return (
											<Select.Option
												key={idx}
												label={item.display_name}
												value={item.key}
											/>
										);
									})}
								</Select>
							</Layout.Col>
							<Layout.Col span='8'>
								<span>Protein Change Type</span>
								<Select
									name='proteinChangeType'
									value={formdata?.proteinChangeType}
									onChange={(value) =>
										onFormChange('proteinChangeType', value)
									}
									placeholder='Select change type'
									className={
										fieldHighlightForError.includes(
											'proteinChangeType'
										)
											? 'validation-field-hightlight'
											: ''
									}
								>
									{proteinChangeTypeEnum.map((item, idx) => {
										return (
											<Select.Option
												key={idx}
												label={item.display_name}
												value={item.key}
											/>
										);
									})}
								</Select>
							</Layout.Col>
							<Layout.Col span='8'>
								<span>Sample Allelic Frequency</span>
								<Input
									name='sampleAllelicFrequency'
									id='sampleAllelicFrequency'
									value={formdata?.sampleAllelicFrequency}
									onChange={(value) =>
										onSampleAllelicFrequencyChange(
											'sampleAllelicFrequency',
											value
										)
									}
									placeholder='Enter decimal'
									className={
										fieldHighlightForError.includes(
											'sampleAllelicFrequency'
										)
											? 'validation-field-hightlight'
											: ''
									}
								/>
								<div className='input-error-text'>
									{get(
										errors,
										['sampleAllelicFrequency'],
										''
									)}
								</div>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row gutter='12'>
							<Layout.Col span='8'>
								<span>Mosaicism</span>
								<Select
									name='mosaicism'
									value={formdata?.mosaicism}
									onChange={(value) =>
										onFormChange('mosaicism', value)
									}
									placeholder='Select mosaicism'
								>
									{mosaicismEnum.map((item, idx) => {
										return (
											<Select.Option
												key={idx}
												label={item.display_name}
												value={item.key}
											/>
										);
									})}
								</Select>
							</Layout.Col>
							<Layout.Col span='8'>
								<span>Genomic Source Class</span>
								<Select
									name='genomicSourceClass'
									value={formdata?.genomicSourceClass}
									onChange={(value) =>
										onFormChange(
											'genomicSourceClass',
											value
										)
									}
									placeholder='Select genomic source'
									className={
										fieldHighlightForError.includes(
											'genomicSourceClass'
										)
											? 'validation-field-hightlight'
											: ''
									}
								>
									{genomicSourceClassEnum.map((item, idx) => {
										return (
											<Select.Option
												key={idx}
												label={item.display_name}
												value={item.key}
											/>
										);
									})}
								</Select>
							</Layout.Col>
							<Layout.Col span='8'>
								<span>Origin</span>
								<Select
									name='origin'
									value={formdata?.origin}
									onChange={(value) =>
										onFormChange('origin', value)
									}
									placeholder='Select origin'
									className={
										fieldHighlightForError.includes(
											'origin'
										)
											? 'validation-field-hightlight'
											: ''
									}
								>
									{originEnum.map((item, idx) => {
										return (
											<Select.Option
												key={idx}
												label={item.display_name}
												value={item.key}
											/>
										);
									})}
								</Select>
							</Layout.Col>
						</Layout.Row>
					</section>

					{showErrorMessageSection === true &&
						displayFindingValidationMessage()}
					{!isEmpty(diagnosticFindingValidationError) && (
						<div className='validation-message-container'>
							{!isEmpty(
								get(
									diagnosticFindingValidationError,
									['message'],
									null
								)
							)
								? diagnosticFindingValidationError.message
								: ''}
						</div>
					)}
				</Loading>
			</Dialog.Body>
			<Dialog.Footer className='dialog-footer'>
				{!isEditMode() && (
					<Fragment>
						<Button
							disabled={isDisableCheckEntriesButton()}
							className='check-entries'
							onClick={() => {
								checkEntries();
							}}
							type='text'
						>
							Check Entries
						</Button>
						<Button
							className='next'
							disabled={isDisableSavedButtonFunc()}
							onClick={() => {
								saveTestResultData();
							}}
						>
							Add Finding
						</Button>
					</Fragment>
				)}
				{isEditMode() && (
					<Fragment>
						{!isEmpty(discreteVariantFindings?.assertedBy) &&
							discreteVariantFindings?.assertedBy === 'User' && (
								<Button
									disabled={loading}
									className='delete-variant'
									onClick={() =>
										_deleteDiscreteVariantFinding()
									}
								>
									<ReactSVG
										src={trashIcon}
										className='delete-variant-icon'
									/>
									<span>Delete Finding</span>
								</Button>
							)}
						<Button
							disabled={isDisableCheckEntriesButton()}
							className='check-entries'
							onClick={() => {
								checkEntries();
							}}
							type='text'
						>
							Check Entries
						</Button>
						<Button
							className='save'
							disabled={isDisableSavedButtonFunc()}
							onClick={() => {
								saveTestResultData();
							}}
						>
							Save
						</Button>
					</Fragment>
				)}
			</Dialog.Footer>
		</Dialog>
	);
};

export default AddEditDiscreteVariantModal;
