//React
import React from 'react';

//UI Libraries
import { Button, Dialog } from 'gm-element-react';

//Components
import Icon from '../../../../Common/Icon';

//Styles
import './ApiErrorModal.css';

export const ApiErrorModal = ({ isVisible, onClose, message }) => {
	return (
		<Dialog
			size='tiny'
			customClass='api-error-modal'
			visible={isVisible}
			onCancel={onClose}
			lockScroll={false}
			showClose={false}
			closeOnPressEscape={false}
			closeOnClickModal={false}
			title={
				<span className='header'>
					<Icon className='warning-icon' icon='warning-circle' />
					<span className='error-title'>
						Encounter Creation Failed
					</span>
					<Icon
						className='close-icon'
						icon='close'
						onClick={onClose}
					/>
				</span>
			}
		>
			<Dialog.Body>
				<p>This encounter creation failed: {message}</p>
				<p>
					Submit a PS ticket to{' '}
					<a href='mailto:ps@genomemedical.com'>
						ps@genomemedical.com
					</a>
					.
				</p>
			</Dialog.Body>
		</Dialog>
	);
};
