import * as actionTypes from '../actions/actionTypes';
import _ from 'lodash';
const UsersListDefaultState = {
	data: [],
	error: null,
	loading: false,
	loaded: false,
	query: undefined,
	pagination: {},
};

export const defaultState = {
	...UsersListDefaultState,
};

export const users = (state = defaultState, action = {}) => {
	switch (action.type) {
		case actionTypes.LOAD_USERS_START:
			return {
				...state,
				loading: true,
				loaded: false,
				error: null,
				data: [],
				query: undefined,
				pagination: {},
			};

		case actionTypes.LOAD_USERS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: null,
				data: _.get(action, ['payload', 'data'], []),
				query: _.get(action, ['payload', 'query'], []),
				pagination: _.get(action, ['payload', 'pagination'], {}),
			};

		case actionTypes.LOAD_USERS_FAILURE:
			return {
				...state,
				loading: false,
				loaded: true,
				error: action.payload,
			};

		case actionTypes.UNLOAD_USERS:
			return {
				...state,
				...UsersListDefaultState,
			};
		default:
			return state;
	}
};

export const user = (state = defaultState, action = {}) => {
	switch (action.type) {
		case actionTypes.LOAD_USER_START:
			return {
				...state,
				loading: true,
				loaded: false,
				error: null,
				data: [],
			};
		case actionTypes.LOAD_USER_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: null,
				data: action.payload,
			};
		case actionTypes.LOAD_USER_FAILURE:
			return {
				...state,
				loading: false,
				loaded: true,
				error: action.payload,
			};
		case actionTypes.UNLOAD_USER:
			return {
				...state,
				...UsersListDefaultState,
			};
		default:
			return state;
	}
};
