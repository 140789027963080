import * as actionTypes from '../actions/actionTypes';
export const session = (state = { isAuthenticate: false }, action) => {
	if (action.type === actionTypes.AUTHENTICATE) {
		const newState = Object.assign({}, state);
		newState.isAuthenticate = action.payload;
		return newState;
	} else {
		return state;
	}
};
export const interval = (state = { interval: 0 }, action) => {
	if (action.type === actionTypes.INTERVAL) {
		const newState = Object.assign({}, state);
		newState.interval = action.payload;
		return newState;
	} else {
		return state;
	}
};
