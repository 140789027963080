//React & Redux
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

//Lodash
import _ from 'lodash';

//Utils
import {
	selectedExtProviderRoles,
	getFromEnumOrFormat,
} from '../../utils';
import { CARE_PATIENTS_PATIENT_ENCOUNTER_PROVIDER_OPERATIONS, ROLE_PRODENG } from './../../Utils/permissionUtils.js';

//Components
import Icon from '../Common/Icon';
import ThreeDotsMenu from '../Common/controls/ThreeDotsMenu';
import RBAC from './../RBAC.js';

//Styles
import './ProviderLinked.css';

const ProviderLinked = (props) => {
	const {
		activeRole = ROLE_PRODENG,
		editLinkInfo,
		editProviderInfo,
		linkedType,
		linkProviderToEncounter,
		loading,
		provider = {},
		providerSpecialties = [],
		unlinkProviderInfo,
	} = props;

	const {
		addressLine1 = '',
		city = '',
		isArchived,
		isDefaultProvider = false,
		linkedToEncounter,
		linkedToPatient,
		providerFullName = '',
		providerRole = [],
		providerUUID,
		receiveAllComms = false,
		specialities = [],
		state = '',		
	} = provider;

	const getOptions = () => {
		const options = [];
		if (linkedType == 'encounter') {
			if (!isArchived && linkedToEncounter && linkedToPatient) {
				options.push({
					click: () => editLinkInfo(providerUUID),
					label: 'Edit Link Info',
				});
			}
			if (!isArchived && !linkedToEncounter && linkedToPatient) {
				options.push({
					click: () => linkProviderToEncounter(providerUUID),
					label: 'Link To This Encounter',
				});
			}
			if (!isArchived && (linkedToPatient || linkedToEncounter)) {
				options.push({
					click: () => editProviderInfo(providerUUID),
					label: 'Edit Provider Info',
				});
			}
			if (linkedToEncounter) {
				options.push({
					click: () => unlinkProviderInfo(providerUUID),
					label: 'Unlink From Encounter',
				});
			}
		} else {
			if (!isArchived) {
				options.push({
					click: () => editLinkInfo(providerUUID),
					label: 'Edit Link Info',
				});
				options.push({
					click: () => editProviderInfo(providerUUID),
					label: 'Edit Provider Info',
				});
			}
			options.push({
				click: () => unlinkProviderInfo(providerUUID),
				label: 'Unlink Provider',
			});
		}
		return options;
	};

	const options = getOptions();
	const specialty = specialities?.[0] || '';
	return (
		<div className='provider-linked'>
			<div className='provider'>
				<div className='icon'>
					<Icon className='stethoscope-icon' icon='stethoscope' />
				</div>
				<div className='info'>
					<div className='provider-name'>{providerFullName}</div>
					{!_.isEmpty(specialty) && (
						<span className='location'>
							{getFromEnumOrFormat(
								providerSpecialties,
								specialty,
								'name',
								'display_name'
							)}
						</span>
					)}
					{!_.isEmpty(addressLine1) && (
						<span className='location'>{addressLine1}</span>
					)}
					<span className='location'>{`${city}${
						!_.isEmpty(state) ? ', ' + state : ''
					}`}</span>
					{linkedType != 'encounter' && receiveAllComms && (
						<div className='link'>Linked to All Encounters</div>
					)}
					{(linkedToPatient || linkedToEncounter) && (
						<div className='link'>
							{linkedType == 'encounter' && (
								<Fragment>
									<span
										className={
											!linkedToEncounter ? 'red' : ''
										}
									>
										{linkedToEncounter
											? 'Linked to encounter'
											: 'Not linked to encounter'}
										,&nbsp;
									</span>
								</Fragment>
							)}
							{linkedType == 'encounter' && !isArchived && (
								<span className={!linkedToPatient ? 'red' : ''}>
									{linkedToPatient
										? 'Linked to patient'
										: 'Not linked to patient'}
								</span>
							)}
							{isArchived && (
								<span className='red'>
									{'Provider Archived'}
								</span>
							)}
						</div>
					)}
				</div>
			</div>
			<div className='actions'>
				<div className='designation'>
					{_.join(selectedExtProviderRoles(providerRole), ', ')}
				</div>
				<RBAC
					action={CARE_PATIENTS_PATIENT_ENCOUNTER_PROVIDER_OPERATIONS}
					yes={
						!_.isEmpty(options) &&
						!loading && <ThreeDotsMenu options={options} {...((activeRole !== ROLE_PRODENG && isDefaultProvider)  && {disabled: true})} />
					}
					no={
						linkedType === 'patientCreateEdit' &&
						!_.isEmpty(options) &&
						!loading && <ThreeDotsMenu options={options} />
					}
				/>
			</div>
		</div>
	);
};

ProviderLinked.propTypes = {
	linkedType: PropTypes.string,
	editLinkInfo: PropTypes.func,
	editProviderInfo: PropTypes.func,
	unlinkProviderInfo: PropTypes.func,
	linkProviderToEncounter: PropTypes.func,
};

export default ProviderLinked;
