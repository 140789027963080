//React & Redux
import React from 'react';

//Images
import warningImage from './images/warning.svg';
import ReactSVG from 'react-svg';

//Lodash
import _ from 'lodash';

class SummaryWarning extends React.Component {
	constructor(props) {
		super(props);
	}
	//functions

	render() {
		const warning = _.get(this, ['props', 'iswarning'], false);
		if (warning) {
			return (
				<React.Fragment>
					<div
						style={{
							width: '100%',
							height: '1px',
							backgroundColor: '#d8dde4',
						}}
					/>
					<div className='scheduleAppointmentSideContainerSection'>
						<div className='warning-wrapper'>
							<div>
								<ReactSVG
									className='warning-icon'
									src={warningImage}
								/>
							</div>
							<div className='warning-message'>
								Appointments that will occur within the next 8
								hours must be scheduled directly by phone with a
								Genome Medical Care Coordinator (877-688-0992).
								Please call us so we can help!
								<br />
								If you'd prefer to proceed with our online
								scheduling process, please select a different
								appointment.
							</div>
						</div>
					</div>
				</React.Fragment>
			);
		} else {
			return null;
		}
	}
}

export default SummaryWarning;
