import React, { forwardRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import z from 'zod';
import validPostalCode from 'postcode-validator';

import { dependentVariants, addressTypeConst } from '../../../../../utils';
import { biologicalSexVariants } from '../../../../../Utils/appointmentUtils';

import { Section } from '@gm/common/ui';
import { useSection } from '../../../../Patients/V3/CreatePatient/sections/useSection';
import RadioGroupField from '../../../../Common/FormFields/RadioGroupField';

import { AddressForm } from '../components/AddressForm';
import { SelfPayment } from './SelfPayment';
import { InsurancePayment } from './InsurancePayment';

import './PaymentInfo.scss';

/**
 * Keeps children mounted in the DOM while making it possible to hide it
 */
function Tab({ children, visible }) {
	return (
		<div style={{ display: visible ? 'block' : 'none' }}>{children}</div>
	);
}

export const PAYMENT_METHOD = {
	NON_INSURANCE: 'NON_INSURANCE',
	INSURANCE: 'INSURANCE',
};

export const schemaPaymentInfo = z
	.object({
		paymentMethod: z.string(),
		useSavedCard: z.boolean(),
		group: z.string().nullable(),
		healthInsurance: z.number().nullable(),
		insuranceId: z.string().nullable(),
		isPrimaryPolicyHolder: z.boolean(),
		policyholderBiologicalSex: z
			.null()
			.or(z.enum(biologicalSexVariants.map((item) => item.value))),
		relationshipToPolicyholder: z
			.null()
			.or(z.enum(dependentVariants.map((item) => item.value))),
		policyholderFirstName: z.string().nullable(),
		policyholderLastName: z.string().nullable(),
		// EMR BE accepts string instead of date
		policyholderDOB: z.string().nullable(),
		homeAddress: z.object({
			addressLine1: z.string(),
			addressLine2: z.string().nullable(),
			city: z.string(),
			state: z.string().nullable(),
			zip: z.string(),
			country: z.string(),
		}),
		shippingAddress: z.object({
			addressLine1: z.string(),
			addressLine2: z.string().nullable(),
			city: z.string(),
			state: z.string().nullable(),
			zip: z.string(),
		}),
		requireShippingAddress: z.boolean(),
		consultationPrice: z.number(),
		insurancePrice: z.number().nullable(),
	})
	.superRefine((value, ctx) => {
		// Insurance details are required for insurance payment flow
		if (value.paymentMethod === PAYMENT_METHOD.INSURANCE) {
			if (value.healthInsurance === null) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Insurance Provider is required',
					path: ['healthInsurance'],
				});
			}
			if (!value.group || value.group.length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Group # is required',
					path: ['group'],
				});
			}
			if (!value.insuranceId || value.insuranceId.length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Insurance ID # is required',
					path: ['insuranceId'],
				});
			}
			if (value.policyholderBiologicalSex === null) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: "Policyholder's biological sex is required",
					path: ['policyholderBiologicalSex'],
				});
			}

			if (value.isPrimaryPolicyHolder === false) {
				if (value.relationshipToPolicyholder === null) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Relationship to policyholder is required',
						path: ['relationshipToPolicyholder'],
					});
				}

				if (value.policyholderFirstName === null) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Policyholder's First Name is Required",
						path: ['policyholderFirstName'],
					});
				}

				if (value.policyholderLastName === null) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Policyholder's Last Name is Required",
						path: ['policyholderLastName'],
					});
				}

				if (value.policyholderDOB === null) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Policyholder's Date of Birth is Required",
						path: ['policyholderDOB'],
					});
				}
			}
		}

		// Collect 'Billing address' if patient has to pay (non-insurance flow
		// or insurance does not cover total cost)
		if (value.consultationPrice !== 0) {
			if (!value.homeAddress.addressLine1) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Address line 1 is required',
					path: ['homeAddress', 'addressLine1'],
				});
			}
			if (!value.homeAddress.country) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Country is required',
					path: ['homeAddress', 'country'],
				});
			}

			if (!value.homeAddress.city) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'City is required',
					path: ['homeAddress', 'city'],
				});
			}
			if (!value.homeAddress.state) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message:
						value.homeAddress.country === 'CA'
							? 'Province is required'
							: 'State is required',
					path: ['homeAddress', 'state'],
				});
			}
			if (!value.homeAddress.zip) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message:
						value.homeAddress.country === 'CA'
							? 'Postal code is required'
							: 'Zip is required',
					path: ['homeAddress', 'zip'],
				});
			} else if (
				!validPostalCode.validate(
					value.homeAddress.zip,
					value.homeAddress.country
				)
			) {
				ctx.addIssue({
					path: ['homeAddress', 'zip'],
					code: z.ZodIssueCode.custom,
					message:
						value.homeAddress.country === 'CA'
							? 'Postal code is invalid'
							: 'Zip is invalid',
				});
			}
		}

		// Validate shipping address
		if (value.requireShippingAddress) {
			if (!value.shippingAddress.addressLine1) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Address line 1 is required',
					path: ['shippingAddress', 'addressLine1'],
				});
			}
			if (!value.shippingAddress.city) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'City is required',
					path: ['shippingAddress', 'city'],
				});
			}
			if (!value.shippingAddress.state) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'State is required',
					path: ['shippingAddress', 'state'],
				});
			}
			if (!value.shippingAddress.zip) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Zip code is required',
					path: ['shippingAddress', 'zip'],
				});
			}
		}
	});

export const initialPaymentInfo = {
	paymentMethod: PAYMENT_METHOD.INSURANCE,
	useSavedCard: false,
	group: '',
	healthInsurance: null,
	insuranceId: '',
	isPrimaryPolicyHolder: true,
	policyholderBiologicalSex: null,
	relationshipToPolicyholder: null,
	policyholderDOB: null,
	policyholderFirstName: null,
	policyholderLastName: null,
	homeAddress: {
		addressLine1: '',
		addressLine2: '',
		city: '',
		state: null,
		zip: '',
		country: 'US',
	},
	shippingAddress: {
		addressLine1: '',
		addressLine2: '',
		city: '',
		state: null,
		zip: '',
	},
	requireShippingAddress: false,
	consultationPrice: null,
	insurancePrice: null,
};

const cardInfoSchema = z
	.object({
		brand: z.string().min(1),
		last4: z.number(),
		exp_year: z.string().min(1),
		exp_month: z.string().min(1),
	})
	.required();

export const PaymentInfo = forwardRef(function PaymentInfo(
	{ sectionDataContainerName },
	ref
) {
	const { allValues, onChange, setFieldValue, values } = useSection(
		sectionDataContainerName
	);

	const { associatedProgram } = allValues.patientInfo;
	const { consultationType } = allValues.serviceInfoAndScheduling;

	const {
		insuranceCompany,
		schedulingConsultations,
		patientDetails,
		referralPrograms,
		referralProgramsDetail,
		loadedGetInsuranceCompanies,
	} = useSelector((store) => ({
		loadedGetInsuranceCompanies:
			store.insurancecompany?.loadedGetInsuranceCompanies,
		insuranceCompany:
			store.insurancecompany?.loadedGetInsuranceCompanies?.data ?? [],
		patientDetails: store.patientdetail?.patientdetail?.[0],
		referralPrograms: store.referralPrograms.referralPrograms.data ?? [],
		referralProgramsDetail:
			store.referralProgramsList?.loadedReferralProgramDetail ?? [],
		schedulingConsultations: store.Scheduling?.schedulingConsultations,
	}));

	const selectedReferralProgram = associatedProgram
		? referralPrograms.find(
				(item) => item.id === allValues.patientInfo.associatedProgram
		  )
		: null;

	const { requireShippingAddress } = referralProgramsDetail;

	useEffect(() => {
		const selectedConsultation = schedulingConsultations?.find(
			(item) => item.id === consultationType
		);
		if (selectedConsultation)
			setFieldValue('consultationPrice', parseFloat(selectedConsultation?.price));
	}, [consultationType, schedulingConsultations]);

	useEffect(() => {
		if (requireShippingAddress) {
			setFieldValue('requireShippingAddress', true);
		} else {
			setFieldValue('requireShippingAddress', false);
		}
	}, [requireShippingAddress]);

	useEffect(() => {
		if (patientDetails) {
			const primaryInsurance = patientDetails.insurances?.find(
				(item) => item.insurance_type === 'primary'
			);
			if (primaryInsurance) {
				setFieldValue(
					'healthInsurance',
					primaryInsurance.insurance_company.id
				);
				setFieldValue(
					'insuranceId',
					primaryInsurance.insurance.insurance_no
				);
				setFieldValue('group', primaryInsurance.insurance.group_no);
				setFieldValue(
					'isPrimaryPolicyHolder',
					primaryInsurance.is_policyholder
				);
				setFieldValue(
					'policyholderBiologicalSex',
					primaryInsurance.policyholder_biological_sex
				);
				setFieldValue(
					'relationshipToPolicyholder',
					primaryInsurance.policyholder_relationship || null
				);
				setFieldValue(
					'policyholderFirstName',
					primaryInsurance.policyholder_first_name || null
				);
				setFieldValue(
					'policyholderLastName',
					primaryInsurance.policyholder_last_name || null
				);
				setFieldValue(
					'policyholderDOB',
					primaryInsurance.policyholder_dob || null
				);
			}
		}
	}, [patientDetails]);

	useEffect(() => {
		if (selectedReferralProgram) {
			if (!selectedReferralProgram.insurance_enabled) {
				setFieldValue('paymentMethod', PAYMENT_METHOD.NON_INSURANCE);
			} else if (selectedReferralProgram.insurance_only) {
				setFieldValue('paymentMethod', PAYMENT_METHOD.INSURANCE);
			}
		}
	}, [selectedReferralProgram]);

	const cardInfo = {
		brand: patientDetails?.brand,
		last4: patientDetails?.last4,
		exp_year: patientDetails?.exp_year,
		exp_month: patientDetails?.exp_month,
	};
	const hasSavedCard = cardInfoSchema.safeParse(cardInfo).success;

	useEffect(() => {
		if (patientDetails && hasSavedCard) {
			setFieldValue('useSavedCard', true);
		}
	}, [patientDetails]);

	useEffect(() => {
		if (values.healthInsurance === null) {
			setFieldValue('insurancePrice', null);
		} else if (associatedInsurance) {
			const { consultationPrice, insurance_prepay_exceptions = [] } =
				associatedInsurance;
			const insuranceException = insurance_prepay_exceptions.find(
				(exception) =>
					exception.partner_id === selectedReferralProgram?.id
			);
			setFieldValue(
				'insurancePrice',
				parseInt(insuranceException?.consultation_price) ||
					consultationPrice
			);
		}
	}, [values.healthInsurance, loadedGetInsuranceCompanies]);

	const hideCheckBox =
		values.paymentMethod !== PAYMENT_METHOD.INSURANCE &&
		values.consultationPrice === 0;

	const associatedInsurance = insuranceCompany.find(
		(item) => item.companyID === values.healthInsurance
	);

	return (
		<>
			<Section title='Payment Info'>
				<RadioGroupField
					label='Payment method'
					required
					options={[
						{
							display_name: 'Non-insurance',
							key: PAYMENT_METHOD.NON_INSURANCE,
							disabled: selectedReferralProgram?.insurance_only,
						},
						{
							display_name: 'Insurance',
							key: PAYMENT_METHOD.INSURANCE,
							disabled:
								!selectedReferralProgram?.insurance_enabled,
						},
					]}
					onChange={onChange('paymentMethod')}
					value={values.paymentMethod}
				/>
				<Tab
					visible={
						values.paymentMethod === PAYMENT_METHOD.NON_INSURANCE
					}
				>
					<SelfPayment
						hasSavedCard={hasSavedCard}
						cardInfo={cardInfo}
						ref={ref}
						sectionDataContainerName={sectionDataContainerName}
					/>
				</Tab>
				<Tab
					visible={values.paymentMethod === PAYMENT_METHOD.INSURANCE}
				>
					<InsurancePayment
						hasSavedCard={hasSavedCard}
						cardInfo={cardInfo}
						ref={ref}
						sectionDataContainerName={sectionDataContainerName}
					/>
				</Tab>
			</Section>
			{requireShippingAddress && (
				<Section title='Shipping Address'>
					<AddressForm
						hideCheckBox={hideCheckBox}
						sectionDataContainerName={sectionDataContainerName}
						addressType={addressTypeConst.shipping}
					/>
				</Section>
			)}
		</>
	);
});
