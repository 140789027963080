//React & Redux
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

//Images
import ReactSVG from 'react-svg';
import add from '../../../../assets/add.svg';
import uploadicon from '../../../../assets/upload.svg';

//Lodash
import _, { filter, get, isEmpty, isNil, isNull, map } from 'lodash';

//Utils
import {
	CARE_PATIENTS_PATIENT_WORKFLOW_DOCS,
	CARE_PATIENTS_PATIENT_CREATE_DOC,
	CARE_PATIENTS_PATIENT_UPLOAD_DOC,
	CARE_PATIENTS_PATIENT_COMBINE_DOC,
	ROLE_CARECOORDINATOR,
	ROLE_COUNSELOR,
	ROLE_PHARMAD,
	ROLE_PHYSICIAN,
	ROLE_GCA,
} from '../../../../Utils/permissionUtils';
import { LIST } from '../../../../Utils/documentUtils';

//UI Libraries
import { Dialog, Button, Loading } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';

//Components
import CreateNewDocumentV2 from './CreateNewDocumentV2.js';
import UploadNewDocument from './UploadNewDocument.js';
import WorkflowDocuments from './WorkflowDocuments.js';
import ShareDocument from './ShareDocument.js';
import EditDocumentDetail from './EditDocumentDetail.js';
import RBAC from '../../../RBAC';
import LogReleaseDocument from './LogReleaseDocument.js';
import AuditTrail from './AuditTrail';
import ReleaseDocumentModal from './ReleaseDocument/ReleaseDocumentModal.js';
import NewDocumentList from './NewDocumentList/NewDocumentList';
import SingleSelect from '../../../Filter/SingleSelect';
import MultiSelectCheckbox from '../../../Filter/MultiSelectCheckbox';
import CombineDocuments from './CombineDocuments';
import Tooltip from '../../../Common/Tooltip';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious';

const validDocRoles = [
	ROLE_CARECOORDINATOR,
	ROLE_COUNSELOR,
	ROLE_GCA,
	ROLE_PHARMAD,
	ROLE_PHYSICIAN,
];

const { ERROR, INFO } = {
	ERROR: {
		variant: 'error',
		anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
	},
	INFO: {
		variant: 'info',
		anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
	},
};

const DocumentList = (props) => {
	const {
		affiliationWorkflowDocs,
		affiliationWorkflowDocsLoading,
		archivedDocument,
		archivingDocument,
		callLabsSchema,
		clearEncounterState,
		clearUsers,
		createDocument,
		createdDocument,
		creatingDocument,
		creatingDocumentError,
		doreleasedocument: doReleaseDocument,
		doShareDocument,
		documentActionLoading,
		documentListOrderBy = 'encounter',
		documentListOrderType = 'desc',
		documentMediums,
		documentMediumsLoading = false,
		documentRecipients,
		documentRecipientsLoading = false,
		documentTypesForPatient = [],
		documentTypesForPatientLoading,
		documentTypesForTemplate = [],
		documentTypesForTemplateLoading,
		documents,
		documentsFilter = {},
		doLogReleaseDocument,
		downloadDocument,
		encounter,
		encounterStringDisplay,
		encounters = [],
		encountersLoading,
		enqueueSnackbar,
		fileUploadError,
		fileUploaded,
		fileUploading,
		getAllProviderSpecialties,
		getAllProviders,
		getDocument,
		getDocumentMediums,
		getDocumentRecipients,
		getDocumentTemplatesFlexible,
		getDocumentTypesForPatient,
		getDocumentTypesForTemplate,
		getEncounter,
		getPatientLinkedProviders,
		getRefPrograms,
		getSpecialty,
		gettingEncounterDetailLoading,
		gettingLinkedProviders,
		labs,
		loadUsers,
		loaded,
		loading,
		loggingReleaseDocument,
		loggingReleaseDocumentError,
		navigateToDocumentDetail,
		navigateToViewDocumentDetail,
		partnerUuid,
		patientDetail,
		PatientlinkedProviders: patientLinkedProviders,
		printDocument,
		providers,
		referralPrograms,
		releasedDocumentError,
		releasingDocument,
		resetAllProviderState,
		setDocumentsFilter,
		setModal,
		sharingDocument,
		sharingDocumentError,
		templates,
		updateDocumentType,
		updateProvider,
		updatedDocumentType,
		updatingDocumentType,
		updatingDocumentTypeError,
		uploadDocument,
		userQuery,
		userRoles,
		users,
		usersLoading,
		uuid,
		combineDocument,
		combiningDocument,
		combinedDocument,
		combineDocumentError,
	} = props;

	const [localState, setLocalState] = useState({
		archiveSlider: documentsFilter.archiveSlider || false,
		auditTrailDocument: {},
		dialogVisible: false,
		editDocument: {},
		logReleaseDocument: {},
		order: documentListOrderType,
		orderBy: documentListOrderBy,
		releaseDocument: {},
		renderAuditTrail: false,
		renderDocumentCreateMasked: false,
		renderDocumentShare: false,
		renderDocumentUpload: false,
		renderEditDocument: false,
		renderLogReleaseDocument: false,
		renderReleaseDocument: false,
		selectdocument: null,
		sharingDocument: {},
		target: null,
		workflowSlider: documentsFilter.workflowSlider || true,
		renderDocumentCombineDialog: false,
	});

	const [selectedCombineDocuments, setSelectedCombineDocuments] = useState(
		[]
	);

	const prevArchivingDocument = usePrevious(archivingDocument);
	const prevCreatingDocument = usePrevious(creatingDocument);
	const prevFileUploading = usePrevious(fileUploading);
	const prevLoaded = usePrevious(loaded);
	const prevLoggingReleaseDocument = usePrevious(loggingReleaseDocument);
	const prevReleasingDocument = usePrevious(releasingDocument);
	const prevSharingDocument = usePrevious(sharingDocument);
	const prevUpdatingDocumentType = usePrevious(updatingDocumentType);
	const prevCombiningDocument = usePrevious(combiningDocument);

	const dummyDiv = useRef(null);

	useEffect(() => {
		getDocumentTypesForPatient();
		callLabsSchema();
		getRefPrograms();
	}, []);

	useEffect(() => {
		if (!creatingDocument && prevCreatingDocument && createdDocument) {
			hideDocumentCreateMasked();
			navigateToDocumentDetail(createdDocument.uuid);
		} else if (
			!creatingDocument &&
			prevCreatingDocument &&
			!createdDocument &&
			creatingDocumentError
		) {
			enqueueSnackbar('Error in document creation', ERROR);
		}
	}, [creatingDocument]);

	useEffect(() => {
		if (!sharingDocument && prevSharingDocument) {
			if (isNil(sharingDocumentError)) {
				hideShareDocument();
				enqueueSnackbar('Document successfully shared', INFO);
			} else {
				enqueueSnackbar('Error in document share', ERROR);
			}
		}
	}, [sharingDocument]);

	useEffect(() => {
		if (!loggingReleaseDocument && prevLoggingReleaseDocument) {
			if (isNil(loggingReleaseDocumentError)) {
				hideLogReleaseDocument();
				enqueueSnackbar('Release successfully logged', INFO);
			} else {
				enqueueSnackbar('Error in document logged release', ERROR);
			}
		}
	}, [loggingReleaseDocument]);

	useEffect(() => {
		if (!releasingDocument && prevReleasingDocument) {
			if (isNil(releasedDocumentError)) {
				hideReleaseDocument();
				getDocument(
					!localState.archiveSlider,
					documentListOrderBy,
					documentListOrderType
				);
				enqueueSnackbar('Document released', INFO);
			} else {
				enqueueSnackbar('Error in document release', ERROR);
			}
		}
	}, [releasingDocument]);

	useEffect(() => {
		if (!fileUploading && prevFileUploading) {
			if (fileUploaded && !fileUploadError) {
				hideDocumentUpload();
				updateLocalState({
					order: documentListOrderType,
					orderBy: documentListOrderBy,
				});
				getDocument(
					!localState.archiveSlider,
					documentListOrderBy || 'dateAdded',
					documentListOrderType || 'asc'
				);
			} else if (fileUploadError) {
				let errorMessage = fileUploadError.message || null;
				if (typeof errorMessage != 'string')
					errorMessage = 'Error in file upload';
				enqueueSnackbar(errorMessage, ERROR);
			}
		}
	}, [fileUploading]);

	useEffect(() => {
		if (!archivingDocument && prevArchivingDocument && archivedDocument) {
			updateLocalState({
				order: documentListOrderType || 'asc',
				orderBy: documentListOrderBy || 'dateAdded',
			});
			getDocument(
				!localState.archiveSlider,
				documentListOrderBy || 'dateAdded',
				documentListOrderType || 'asc'
			);
			/* Uncheck checkbox for documents once selected document is archived  */
			setSelectedCombineDocuments([]);
		}
	}, [archivingDocument]);

	useEffect(() => {
		if (
			!updatingDocumentType &&
			prevUpdatingDocumentType &&
			updatedDocumentType
		) {
			if (updatingDocumentTypeError) {
				enqueueSnackbar('Error in updating document', ERROR);
			} else {
				updateLocalState({
					order: documentListOrderType || 'asc',
					orderBy: documentListOrderBy || 'dateAdded',
				});
				hideEditDocumentDetail();
				getDocument(
					!localState.archiveSlider,
					documentListOrderBy || 'dateAdded',
					documentListOrderType || 'asc'
				);
				enqueueSnackbar('Document successfully updated', INFO);
			}
		}
	}, [updatingDocumentType]);

	useEffect(() => {
		if (loaded && prevLoaded !== loaded && isDocumentUnderScanning()) {
			setTimeout(() => {
				getDocument(
					!localState.archiveSlider,
					documentListOrderBy,
					documentListOrderType,
					false
				);
			}, 500);
		}
	}, [loaded]);

	useEffect(() => {
		if (!combiningDocument && prevCombiningDocument) {
			if (combinedDocument && !combineDocumentError) {
				hideDocumentCombineDialog();
				updateLocalState({
					order: documentListOrderType,
					orderBy: documentListOrderBy,
				});
				getDocument(
					!localState.archiveSlider,
					documentListOrderBy || 'dateAdded',
					documentListOrderType || 'asc'
				);
			} else if (combineDocumentError) {
				enqueueSnackbar('Error in combination document', ERROR);
			}
			setSelectedCombineDocuments([]);
		}
	}, [combiningDocument]);

	const updateLocalState = (obj) => {
		setLocalState((state) => ({
			...state,
			...obj,
		}));
	};

	const isDocumentUnderScanning = () => {
		const newObj = { ...documents };

		for (const encounterDocs of get(newObj, ['encounter'], [])) {
			for (const doc of encounterDocs.documents) {
				if (isNull(doc.is_malicious)) return true;
			}
		}

		delete newObj.encounter;

		for (const docType in newObj) {
			for (const doc of newObj[docType]) {
				if (isNull(doc.is_malicious)) return true;
			}
		}

		return false;
	};

	const loadUsersWithValidRoles = (query) => {
		const filterroles = filter(
			userRoles,
			(o) => o && o.name && validDocRoles.includes(o.name)
		);
		const filterrolesuuid = map(filterroles, 'uuid');
		const data = {
			limit: 500,
			offset: 0,
			role: filterrolesuuid,
			q: query,
		};
		if (!isEmpty(filterrolesuuid)) loadUsers(data);
	};

	const hideDocumentUpload = () => {
		updateLocalState({ renderDocumentUpload: false });
	};

	const showDocumentUpload = () => {
		updateLocalState({ renderDocumentUpload: true });
	};

	const hideDocumentCombineDialog = () => {
		updateLocalState({ renderDocumentCombineDialog: false });
	};

	const showDocumentCombineDialog = () => {
		updateLocalState({ renderDocumentCombineDialog: true });
	};

	const showShareDocument = (e, doc) => {
		dummyDiv.current.click();
		e.stopPropagation();
		e.preventDefault();
		updateLocalState({
			renderDocumentShare: true,
			sharingDocument: doc,
		});
	};

	const showLogReleaseDocument = (e, doc) => {
		dummyDiv.current.click();
		e.stopPropagation();
		e.preventDefault();
		updateLocalState({
			renderEditDocument: true,
			editDocument: doc,
			logReleaseDocument: doc,
			target: 'logManualRelease',
		});
	};

	const showReleaseDocument = (e, doc) => {
		dummyDiv.current.click();
		e.stopPropagation();
		e.preventDefault();
		updateLocalState({
			renderEditDocument: true,
			editDocument: doc,
			releaseDocument: doc,
			target: 'releaseDoc',
		});
	};

	const hideShareDocument = () => {
		updateLocalState({
			renderDocumentShare: false,
			sharingDocument: {},
		});
	};

	const hideLogReleaseDocument = () => {
		updateLocalState({
			renderLogReleaseDocument: false,
			logReleaseDocument: {},
			target: null,
		});
	};

	const hideReleaseDocument = () => {
		updateLocalState({
			renderReleaseDocument: false,
			releaseDocument: {},
			target: null,
		});
	};

	const showEditDocumentDetail = (e, doc) => {
		dummyDiv.current.click();
		e.stopPropagation();
		e.preventDefault();
		updateLocalState({
			renderEditDocument: true,
			editDocument: doc,
			target: null,
		});
	};

	const hideEditDocumentDetail = (closeModal = false) => {
		setLocalState((state) => ({
			...state,
			renderEditDocument: false,
			editDocument: {},
			renderLogReleaseDocument:
				!closeModal && state.target === 'logManualRelease',
			renderReleaseDocument: !closeModal && state.target === 'releaseDoc',
			logReleaseDocument: closeModal ? {} : state.logReleaseDocument,
			releaseDocument: closeModal ? {} : state.releaseDocument,
			target: closeModal ? null : state.target,
		}));
	};

	const updateDocTypeHandler = (document_uuid, data) => {
		updateDocumentType(document_uuid, data);
	};

	const archiveFile = (e, doc) => {
		dummyDiv.current.click();
		e.stopPropagation();
		setModal('archival', doc.uuid);
	};

	const rowClickHandler = (doc) => {
		if (doc.is_malicious !== true) {
			const gDrive = doc.is_editable;
			if (!gDrive) {
				navigateToViewDocumentDetail(get(doc, 'uuid', 0));
			} else {
				const fileExtension =
					doc && !isNil(doc.file_extension_id)
						? get(doc, 'file_extension_id', '')
						: '';
				if (fileExtension.toLowerCase() in { doc: 1, docx: 1 })
					navigateToDocumentDetail(get(doc, 'uuid', 0));
			}
		}
	};

	const showDialog = () => (
		<Dialog
			title=''
			size='tiny'
			visible={localState.dialogVisible}
			onCancel={() =>
				updateLocalState({ dialogVisible: false, selectdocument: null })
			}
			lockScroll={false}
			showClose={false}
		>
			<Dialog.Body>
				<span>Please ensure downloaded PHI are securely managed</span>
			</Dialog.Body>
			<Dialog.Footer className='dialog-footer'>
				<Button type='primary'>Confirm</Button>
			</Dialog.Footer>
		</Dialog>
	);

	const formulateDocTypes = (document_types, noUnassignedFlag) => {
		if (Array.isArray(document_types)) {
			let documentTypes;
			let formulatedDocTypes = [];
			documentTypes = noUnassignedFlag
				? document_types.filter(
						(el) => get(el, 'key', '') !== 'unassigned'
				  )
				: document_types;
			formulatedDocTypes = documentTypes.map((el) => {
				const subTypes = get(el, 'subtypes', null);
				const docType = {
					value: get(el, 'key', null),
					label: get(el, 'display_name', null),
				};
				if (Array.isArray(subTypes) && get(subTypes, 'length', 0) > 0) {
					docType.children = subTypes.map((subType) => {
						return {
							value: get(subType, 'key', null),
							label: get(subType, 'display_name', ''),
						};
					});
				}
				return docType;
			});
			return formulatedDocTypes;
		} else return document_types;
	};

	const hideDocumentCreateMasked = () => {
		updateLocalState({ renderDocumentCreateMasked: false });
	};

	const showDocumentCreateMasked = () => {
		updateLocalState({ renderDocumentCreateMasked: true });
	};

	const processEncounters = (encounters = []) => {
		if (!Array.isArray(encounters)) return encounters;
		const nonCancelledEncounters = encounters.filter((el) => {
			if (get(el, 'type', '') !== 'research-data') {
				return (
					!(get(el, 'visit_status', '') || '').includes(
						'cancelled'
					) && get(el, 'visit_status', '') !== 'no_show'
				);
			} else return false;
		});
		return nonCancelledEncounters;
	};

	const canBeArchived = (obj) => get(obj, 'can_be_archived', false);
	const isArchived = (obj) =>
		obj && (obj.deleted_at === null ? false : true) && !canBeArchived(obj);

	const showAuditTrail = (e, auditTrailDocument) => {
		dummyDiv.current.click();
		e.stopPropagation();
		e.preventDefault();
		updateLocalState({ renderAuditTrail: true, auditTrailDocument });
	};

	const hideAuditTrail = () => {
		updateLocalState({ renderAuditTrail: false, auditTrailDocument: {} });
	};

	const canReleasedPP = (obj) => get(obj, 'can_be_released_pp', false);
	const canReleasedProvider = (obj) =>
		get(obj, 'can_be_released_provider', false);

	const withoutSubtypeVisitDoc = (doc) => {
		const documentType = get(doc, ['document_type', 'name'], null);
		const documentSubType = get(doc, 'document_subtype', null);
		return (
			documentType === 'visit_documentation' &&
			(isNil(get(documentSubType, 'name', null)) ||
				isEmpty(get(documentSubType, 'name', '')))
		);
	};

	const handleSortBy = (orderBy) => {
		updateLocalState({ orderBy });
		getDocument(!localState.archiveSlider, orderBy, localState.order);
		setDocumentsFilter({
			...documentsFilter,
			documentListOrderBy: orderBy,
			documentListOrderType: localState.order,
			workflowSlider: localState.workflowSlider,
			archiveSlider: localState.archiveSlider,
		});
	};

	const getSelectedOptions = (withWorkflow = true) => {
		const options = [];

		if (
			withWorkflow &&
			(localState.workflowSlider || documentsFilter.workflowSlider)
		)
			options.push('workflowSlider');
		if (localState.archiveSlider || documentsFilter.archiveSlider)
			options.push('archiveSlider');

		return options;
	};

	const setOptions = (values) => {
		let newArchiveSlider;
		let newWorkflowSlider;
		const prevArchiveSlider = localState.archiveSlider;
		if (values.length) {
			(newArchiveSlider = values.indexOf('archiveSlider') !== -1),
				(newWorkflowSlider = values.indexOf('workflowSlider') !== -1);
		} else {
			(newArchiveSlider = false), (newWorkflowSlider = false);
		}

		updateLocalState({
			archiveSlider: newArchiveSlider,
			workflowSlider: newWorkflowSlider,
		});
		if (prevArchiveSlider !== newArchiveSlider) {
			getDocument(
				!newArchiveSlider,
				localState.orderBy,
				localState.order
			);
		}

		setDocumentsFilter({
			...documentsFilter,
			documentListOrderBy: localState.orderBy,
			documentListOrderType: localState.order,
			workflowSlider: newWorkflowSlider,
			archiveSlider: newArchiveSlider,
		});
	};

	return (
		<div className='document-tab-section new-version'>
			{localState.renderDocumentCreateMasked && (
				<CreateNewDocumentV2
					{...{
						createDocument,
						createdDocument,
						creatingDocument,
						creatingDocumentError,
						documentTypes: formulateDocTypes(
							documentTypesForTemplate
						),
						documentTypesLoading: documentTypesForTemplateLoading,
						encounterStringDisplay,
						encounters: processEncounters(encounters),
						encountersLoading,
						getDocumentTemplatesFlexible,
						getDocumentTypesForTemplate,
						hideDocumentCreateMasked,
						partnerUuid,
						patientDetail,
						patientUuid: uuid,
						templates,
					}}
				/>
			)}
			{localState.renderDocumentUpload && (
				<UploadNewDocument
					{...{
						documentTypes: formulateDocTypes(
							documentTypesForPatient
						),
						documentTypesLoading: documentTypesForPatientLoading,
						encounterStringDisplay,
						encounters,
						fileUploading,
						getDocumentTypesForPatient,
						hideDocumentUpload,
						patientUuid: uuid,
						uploadDocument,
					}}
				/>
			)}
			{localState.renderDocumentShare &&
				!isEmpty(localState.sharingDocument) && (
					<ShareDocument
						{...{
							clearUsers,
							doShareDocument,
							document: localState.sharingDocument,
							hideShareDocument,
							loadUsersWithValidRoles,
							sharingDocument,
							userQuery,
							users,
							usersLoading,
						}}
					/>
				)}
			{localState.renderEditDocument && (
				<EditDocumentDetail
					{...{
						document: localState.editDocument,
						documentTypesForPatient,
						documentTypesForPatientLoading,
						documentTypesForTemplate,
						documentTypesForTemplateLoading,
						encounterStringDisplay,
						encounters: processEncounters(encounters),
						encountersLoading,
						formulateDocTypes,
						getDocumentTypesForPatient,
						getDocumentTypesForTemplate,
						hideEditDocumentDetail,
						updateDocTypeHandler,
						updatingDocumentType,
						verifyDoc: !!localState.target,
					}}
				/>
			)}
			{localState.renderAuditTrail &&
				!isEmpty(localState.auditTrailDocument) && (
					<AuditTrail
						{...{
							document: localState.auditTrailDocument,
							hideAuditTrail,
							patientUUID: uuid || null,
							refreshDocumentList: () =>
								getDocument(
									!localState.archiveSlider,
									documentListOrderBy,
									documentListOrderType
								),
						}}
					/>
				)}
			{localState.renderDocumentCombineDialog && (
				<CombineDocuments
					{...{
						documentTypes: formulateDocTypes(
							documentTypesForPatient
						),
						documentTypesLoading: documentTypesForPatientLoading,
						encounterStringDisplay,
						encounters,
						getDocumentTypesForPatient,
						hideDocumentCombineDialog,
						selectedCombineDocuments,
						combineDocument,
						combiningDocument,
					}}
				/>
			)}
			<div className='document-action-row'>
				<div className='document-action-buttons-container'>
					<RBAC
						action={CARE_PATIENTS_PATIENT_CREATE_DOC}
						yes={
							<button
								type='button'
								className={classnames('btn-bg', 'createdocbtn')}
								disabled={patientDetail?.uuid?.length === 0}
								onClick={showDocumentCreateMasked}
							>
								<span className='button-space'>
									<ReactSVG
										className={
											'menuItemIconWhite new_encounter'
										}
										src={add}
									/>
								</span>
								<span className='text_space'>Create Doc</span>
							</button>
						}
					/>
					<RBAC
						action={CARE_PATIENTS_PATIENT_UPLOAD_DOC}
						yes={
							<button
								type='button'
								className={classnames('btn-bg', 'uploaddocbtn')}
								disabled={patientDetail?.uuid?.length === 0}
								onClick={showDocumentUpload}
							>
								<span className='button-space'>
									<ReactSVG
										className={
											'menuItemIconWhite new_encounter'
										}
										src={uploadicon}
									/>
								</span>
								<span className='text_space'>Upload File</span>
							</button>
						}
					/>
					<RBAC
						action={CARE_PATIENTS_PATIENT_COMBINE_DOC}
						yes={
							<Tooltip
								placement='bottom'
								content='The maximum number of documents to combine is 10'
								disabled={selectedCombineDocuments.length <= 10}
								className='tooltip-max-document-combine'
							>
								<section className='combine-doc-button-section'>
									<Button
										className='combine-doc-button'
										disabled={
											selectedCombineDocuments.length < 2 ||
											selectedCombineDocuments.length > 10
										}
										onClick={showDocumentCombineDialog}
									>
										Combine Selected Docs
									</Button>
								</section>
							</Tooltip>
						}
					/>
				</div>
				<div className='new-version-filters'>
					<SingleSelect
						data={[
							{
								internal: 'encounter',
								external: 'By Encounter',
							},
							{
								internal: 'type',
								external: 'By Document Type',
							},
							{
								internal: 'lastModified',
								external: 'By Last Modified',
							},
							{
								internal: 'dateAdded',
								external: 'By Last Added',
							},
						]}
						currentValue={localState.orderBy || 'encounter'}
						title={'Sort Documents'}
						setStateFunc={handleSortBy}
						defaultValue={documentListOrderBy || 'encounter'}
					/>
					<RBAC
						action={CARE_PATIENTS_PATIENT_WORKFLOW_DOCS}
						yes={
							<MultiSelectCheckbox
								data={[
									{
										key: 'workflowSlider',
										display_name: 'Show Workflow Docs',
									},
									{
										key: 'archiveSlider',
										display_name: 'Show Archived Files',
									},
								]}
								selected={getSelectedOptions()}
								title='Options'
								submit={(values) => setOptions(values)}
								majorPillTitle='Options'
								defaultValues={['workflowSlider']}
								zeroToAllPillTitle={true}
								hideSelectAll={true}
								alwaysNumber={true}
								allowsEmptyOutput={true}
								emptyInputFullOutputOverride={true}
							/>
						}
						no={
							<MultiSelectCheckbox
								data={[
									{
										key: 'archiveSlider',
										display_name: 'Show Archived Files',
									},
								]}
								selected={getSelectedOptions(false)}
								title='Options'
								submit={(values) => setOptions(values)}
								majorPillTitle='Options'
								defaultValues={[]}
								zeroToAllPillTitle={true}
								hideSelectAll={true}
								alwaysNumber={true}
								allowsEmptyOutput={true}
								emptyInputFullOutputOverride={true}
							/>
						}
					/>
				</div>
			</div>
			{localState.workflowSlider && (
				<RBAC
					action={CARE_PATIENTS_PATIENT_WORKFLOW_DOCS}
					yes={
						<WorkflowDocuments
							{...{
								affiliationWorkflowDocs,
								enqueueSnackbar,
							}}
						/>
					}
				/>
			)}
			<div className='document-list-section'>
				{loading ||
				archivingDocument ||
				documentActionLoading ||
				affiliationWorkflowDocsLoading ||
				updatingDocumentType ? (
					<Loading
						loading={true}
						style={{ top: 'calc(40vh - 160px)' }}
					/>
				) : (
					<NewDocumentList
						{...{
							archiveFile,
							canBeArchived,
							canReleasedPP,
							canReleasedProvider,
							data: documents || null,
							encounters,
							handleDocClick: (doc) => rowClickHandler(doc),
							isArchived,
							orderBy: localState.orderBy || 'encounter',
							printDocument,
							showAuditTrail,
							showEditDocumentDetail,
							showLogReleaseDocument,
							showReleaseDocument,
							showShareDocument,
							withoutSubtypeVisitDoc,
							downloadDocument,
							selectedCombineDocuments,
							setSelectedCombineDocuments,
						}}
					/>
				)}
				{showDialog()}
				{localState.renderLogReleaseDocument &&
					!isEmpty(localState.logReleaseDocument) && (
						<LogReleaseDocument
							{...{
								document: localState.logReleaseDocument,
								documentMediums,
								documentMediumsLoading,
								documentRecipients,
								documentRecipientsLoading,
								doLogReleaseDocument,
								downloadDocument,
								getAllProviderSpecialties,
								getAllProviders,
								getDocumentMediums,
								getDocumentRecipients,
								getPatientLinkedProviders,
								getSpecialty,
								hideLogReleaseDocument,
								labs,
								loggingReleaseDocument,
								patientDetail,
								providers,
								referralPrograms,
								resetAllProviderState,
							}}
						/>
					)}

				{localState.renderReleaseDocument &&
					!isEmpty(localState.releaseDocument) && (
						<ReleaseDocumentModal
							{...{
								clearEncounterState,
								doReleaseDocument,
								document: localState.releaseDocument,
								encounter,
								getEncounter,
								getPatientLinkedProviders,
								gettingEncounterDetailLoading,
								gettingLinkedProviders,
								hideReleaseDocument,
								patientDetail,
								patientLinkedProviders,
								source: LIST,
								releasingDocument,
								updateProvider,
							}}
						/>
					)}
			</div>
			<div key='dummydiv' ref={dummyDiv} />
		</div>
	);
};

DocumentList.propTypes = {
	history: PropTypes.object,
	patientDetail: PropTypes.object,
	uuid: PropTypes.string,
	createDocument: PropTypes.func.isRequired,
	documentTypes: PropTypes.array,
	documents: PropTypes.array,
	encounters: PropTypes.array,
	navigateToDocumentDetail: PropTypes.func.isRequired,
	navigateToViewDocumentDetail: PropTypes.func.isRequired,
	encountersLoading: PropTypes.bool,
	users: PropTypes.array,
	clearUsers: PropTypes.func.isRequired,
	getDocumentRecipients: PropTypes.func.isRequired,
	getDocumentMediums: PropTypes.func.isRequired,
	doLogReleaseDocument: PropTypes.func.isRequired,
};

export default DocumentList;
