//React & Redux
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

//Lodash
import { isString, parseInt } from 'lodash';

//UI Libraries
import { Popover } from 'gm-element-react';

//Other Libraries
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classnames from 'classnames';

//Components
import Icon from '../Icon';

//Styles
import './LinkPopover.css';

//Utils
import { getLink } from '../../../Utils/urlUtils';

const LinkPopover = (props) => {
	const {
		children,
		className: classNames = '',
		maxHeight = '40px',
		maxWidth = '144px',
		multiLine = false,
		onClick,
		overflow = false,
		style = {},
	} = props;

	const [isOverflowing, setIsOverflowing] = useState(false);
	const containingDivRef = useRef();

	const isContentString = isString(children);

	useEffect(() => {
		if (isContentString) {
			const { style } = containingDivRef.current;
			const { width, height } =
				containingDivRef.current.getBoundingClientRect();
			const maxWidth = parseInt(style.maxWidth);
			const maxHeight = parseInt(style.maxHeight);

			if (height > maxHeight || (!multiLine && width >= maxWidth)) {
				setIsOverflowing(true);
			}
		}
	}, []);

	const handleTextCopied = ({ enqueueSnackbar }) => {
		enqueueSnackbar('URL copied', {
			variant: 'success',
			className: 'export_notification',
			anchorOrigin: { horizontal: 'center', vertical: 'top' },
		});
	};

	const generateContent = (props, isOverflowing, iconAlignment) => {
		const { linkTo, options = {}, overflow } = props;

		const { copy, link, linkOptions = {}, text } = options;

		const { style: linkOptionsStyle, text: linkOptionsText } = linkOptions;

		const redirectURL = getLink(linkTo);
		let copyIcon;
		let linkIcon;
		let redirectURLText;
		let separator;
		if (text) {
			redirectURLText = (
				<div className='redirectURL' style={linkOptionsStyle || {}}>
					{linkOptionsText || redirectURL}
				</div>
			);
			if (overflow && !isOverflowing) redirectURLText = '';
		}
		if (copy) {
			copyIcon = (
				<CopyToClipboard
					text={redirectURL}
					onCopy={() => handleTextCopied(props)}
				>
					<Icon className='LinkIcon CopyLinkIcon' icon='copy' />
				</CopyToClipboard>
			);
		}
		if (copy && link) {
			separator = <div className='separator'></div>;
		}
		if (link) {
			linkIcon = (
				<Icon
					className='LinkIcon ExternalLinkIcon'
					icon='external-link'
					onClick={() => window.open(redirectURL, '_blank')}
				/>
			);
		}

		switch (iconAlignment) {
			case 'left':
				return (
					<div
						className='LinkPopoverContent'
						style={{ justifyContent: 'start' }}
					>
						{linkIcon}
						{redirectURLText}
					</div>
				);
			default:
				return (
					<div className='LinkPopoverContent'>
						{redirectURLText}
						{copyIcon}
						{separator}
						{linkIcon}
					</div>
				);
		}
	};

	const renderContent = (props, isOverflowing) => {
		const { options = {} } = props;
		const { iconAlignment = 'right', multiChildElements = [] } = options;

		if (multiChildElements.length) {
			return multiChildElements.map((el) =>
				generateContent(el, isOverflowing, iconAlignment)
			);
		} else {
			return generateContent(props, isOverflowing, iconAlignment);
		}
	};

	return (
		<div
			ref={containingDivRef}
			style={
				overflow
					? {
							...style,
							maxWidth,
							maxHeight,
					  }
					: {}
			}
			className={classnames(
				'LinkPopover',
				overflow
					? {
							'single-line': !multiLine,
							'multi-line': multiLine,
					  }
					: {},
				`${classNames}`
			)}
			onClick={(e) => e.stopPropagation()}
		>
			<Popover
				width='auto'
				placement='top'
				trigger='hover'
				content={renderContent(props, isOverflowing)}
			>
				<div
					onClick={onClick && onClick}
					className='LinkPopoverContentMain'
				>
					{children}
				</div>
			</Popover>
		</div>
	);
};

LinkPopover.propTypes = {
	linkTo: PropTypes.isRequired,
	options: PropTypes.isRequired,
	enqueueSnackbar: PropTypes.isRequired,
};

export default LinkPopover;
