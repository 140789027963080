//React & Redux
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

//Images
import imageEdit from '../../../assets/edit.svg';
import imageExpandCollapse from '../../../assets/chevron-large.svg';

//Lodash
import _ from 'lodash';

//Components
import RelatedEncounterInfoSegment from './RelatedEncounterInfoSegment';
import EncounterInfoSegment from './EncounterInfoSegment';
import ViewBillingSegment from './ViewBillingSegment';
import EditBillingSegment from './EditBillingSegment';

//Styles
import '../css/encounter-detail.css';
import '../BillingDetail/RelatedEncounterInfoSegmentBilling.css';

const EncounterDetail = (props) => {
	const {
		billingSegmentProps,
		encounterDetail: {
			parent_of: parentOf = '',
			part_of: partOf = '',
			sibling_of: siblingOf = '',
			visit_provider: visitProvider = '',
		},
		encounterInfoSegmentProps,
		history,
		patientDetail,
	} = props;

	const [localState, setLocalState] = useState({
		encounterInfo: {
			edit: false,
			expand: false,
		},
		patientBilling: {
			edit: false,
			expand: true,
		},
		relatedEncounterInfo: {
			edit: false,
			expand: false,
		},
	});

	useEffect(() => {
		setLocalState((state) => ({
			...state,
			encounterInfo: {
				...state.encounterInfo,
				edit: false,
				expand: false,
			},
			patientBilling: {
				...state.patientBilling,
				edit: false,
				expand: true,
			},
			relatedEncounterInfo: {
				...state.relatedEncounterInfo,
				edit: false,
				expand: false,
			},
		}));
	}, [patientDetail]);

	const { encounterInfo, patientBilling, relatedEncounterInfo } = localState;

	const toggleStateProp = (segment, prop) => {
		setLocalState((state) => ({
			...state,
			[segment]: {
				...state[segment],
				[prop]: !localState[segment][prop],
			},
		}));
	};

	const handleToggleExpand = (segment) => {
		if (localState[segment].edit) return;
		toggleStateProp(segment, 'expand');
	};

	const handleToggleEditingSegment = (segment) => {
		toggleStateProp(segment, 'edit');
	};

	const enableEditSegment = (e, segment) => {
		e.stopPropagation();
		handleToggleEditingSegment(segment);
	};

	const showEditIcon = (segment) =>
		localState[segment].expand && !localState[segment].edit;

	const gotoRelatedEncounter = (
		patient_uuid,
		encounter_uuid,
		patientFullName
	) =>
		history.push({
			pathname: `/app/patientdetail/${patient_uuid}/0/2/${encounter_uuid}/`,
			state: {
				breadcrumbs: [
					{
						location: 'Patient Detail',
						patientFullName: patientFullName,
					},
				],
			},
		});

	const relatedEncounterProps = () => ({
		encounter: {
			parent_of: parentOf,
			part_of: partOf,
			provider_name: visitProvider,
			sibling_of: siblingOf,
		},
		gotoRelatedEncounter,
		isRelatedEncounterClickable: false,
		showLinkIcon: false,
	});

	const showRelatedEncounter =
		!_.isEmpty(parentOf) ||
		!_.isEmpty(siblingOf) ||
		!_.isEmpty(_.get(partOf, 'uuid', ''));
	const relatedEncounterInfoClass = classnames(
		'header',
		relatedEncounterInfo.expand ? 'active' : ''
	);
	const encounterInfoClass = classnames(
		'header',
		encounterInfo.expand ? 'active' : ''
	);
	const patientBillingClass = classnames(
		'header',
		patientBilling.expand ? 'active' : ''
	);

	return (
		<section className='billing-encount-detail-wrapper'>
			{/* Related Encounter Information Section */}
			{showRelatedEncounter && (
				<div className='billing-segment-wrapper'>
					<div
						className={relatedEncounterInfoClass}
						onClick={() =>
							handleToggleExpand('relatedEncounterInfo')
						}
					>
						<span>Related encounter(s)</span>
						<span style={{ marginLeft: 'auto' }}>
							<img
								src={imageExpandCollapse}
								style={{
									transform: relatedEncounterInfo.expand
										? 'rotate(-90deg)'
										: 'rotate(90deg)',
								}}
								alt=''
							/>
						</span>
					</div>
					<div
						style={{
							display: relatedEncounterInfo.expand
								? 'block'
								: 'none',
						}}
					>
						<RelatedEncounterInfoSegment
							{...relatedEncounterProps(props)}
						/>
					</div>
				</div>
			)}

			{/* Encounter Information Section */}
			<div className='billing-segment-wrapper'>
				<div
					className={encounterInfoClass}
					onClick={() => handleToggleExpand('encounterInfo')}
				>
					<span>Encounter info</span>
					<span style={{ marginLeft: 'auto' }}>
						<img
							src={imageExpandCollapse}
							style={{
								transform: encounterInfo.expand
									? 'rotate(-90deg)'
									: 'rotate(90deg)',
							}}
							alt=''
						/>
					</span>
				</div>
				<div
					style={{ display: encounterInfo.expand ? 'block' : 'none' }}
				>
					<EncounterInfoSegment {...encounterInfoSegmentProps} />
				</div>
			</div>

			{/* Patient Billing Section */}
			<div className='billing-segment-wrapper'>
				<div
					className={patientBillingClass}
					style={{ cursor: patientBilling.edit ? 'auto' : 'pointer' }}
					onClick={() => handleToggleExpand('patientBilling')}
				>
					<span>
						Billing{' '}
						{showEditIcon('patientBilling') && (
							<img
								style={{ marginLeft: '80px' }}
								alt='edit'
								src={imageEdit}
								className='img-edit'
								onClick={(e) =>
									enableEditSegment(e, 'patientBilling')
								}
							/>
						)}
					</span>
					{!patientBilling.edit && (
						<span style={{ marginLeft: 'auto' }}>
							<img
								src={imageExpandCollapse}
								style={{
									transform: patientBilling.expand
										? 'rotate(-90deg)'
										: 'rotate(90deg)',
								}}
								alt=''
							/>
						</span>
					)}
				</div>
				<div
					style={{
						display: patientBilling.expand ? 'block' : 'none',
					}}
				>
					{patientBilling.edit == false ? (
						<ViewBillingSegment {...billingSegmentProps} />
					) : (
						<EditBillingSegment
							{...billingSegmentProps}
							{...{
								handleToggleEdit: () =>
									handleToggleEditingSegment(
										'patientBilling'
									),
							}}
						/>
					)}
				</div>
			</div>
		</section>
	);
};

export default EncounterDetail;
