import {
	ApolloClient,
	InMemoryCache,
	HttpLink,
	ApolloLink,
	from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { get } from 'lodash';
import UniversalCookie from 'universal-cookie';
import { v4 } from 'uuid';

import { getAccessToken } from './Storage';
import { successStatus, failedStatus } from '../Utils/httpHandlers';
import { store } from '../store/configureStore';

const cookies = new UniversalCookie();
const httpLink = new HttpLink({ uri: '/api/graphql' });

// this middleware will handle http status code errors
const interceptor = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message }) =>
			console.log(`[GraphQL error]: Message: ${message}`)
		);
	}
	if (networkError) {
		failedStatus(
			{
				...networkError,
				response: {
					...networkError.response,
					status: networkError.statusCode,
					data: networkError.bodyText,
				},
			},
			'graphql'
		);
	}
});

// this middleware will handle adding custom headers and success response
const authMiddleware = new ApolloLink((operation, forward) => {
	const email = get(store.getState(), ['me', 'user', 'email']);
	operation.setContext(({ headers = {} }) => {
		const context = {
			headers: {
				...headers,
				'gmi-version': process.env.REACT_APP_GMI_TAG || '',
				'retry-counts': headers['retry-counts'] || 0,
				'gmi-request': v4(),
				'gmi-email': email,
				'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
			},
		};
		const token = getAccessToken();
		if (token) {
			context.headers['gmi-token'] = token;
		}
		return context;
	});

	return forward(operation).map((response) => {
		const context = operation.getContext();
		const updated = context.response.headers.get('updated');
		successStatus({ headers: { updated } }, 'graphql');
		return response;
	});
});

// initializing apollo client
export const client = new ApolloClient({
	cache: new InMemoryCache(),
	credentials: 'same-origin',
	link: from([authMiddleware, interceptor, httpLink]),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'no-cache',
		},
		query: {
			fetchPolicy: 'no-cache',
		},
	},
});
