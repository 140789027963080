import * as actionTypes from './actionTypes';

export function showRefreshModal() {
	return {
		type: actionTypes.REFRESH_MODAL,
	};
}

export function retryExceeded(payload = true) {
	return {
		type: actionTypes.RETRY_EXCEEDED,
		payload,
	};
}

export function showSwitchRoleModal(UUID) {
	return {
		type: actionTypes.SWITCH_ROLE_MODAL,
		payload: UUID,
	};
}

export function requestAuthorizationStatus(ok) {
	return {
		type: actionTypes.REQUEST_AUTHORIZATION_STATUS,
		payload: ok,
	};
}
