import classnames from 'classnames';
import React from 'react';

import './Page.scss';

export function Page({ children, className }) {
	return (
		<div className={classnames('page-container', className)}>
			{children}
		</div>
	);
}
