import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import CheckboxField from '../FormFields/CheckboxField';
import InputField from '../FormFields/InputField';

//Images
import ReactSVG from 'react-svg';
import plusIcon from '../../../assets/add.svg';
import trashIcon from '../../../assets/delete.svg';

import './DuplicatePatient.scss';

export const DuplicatePatient = ({
	value,
	onChange,
	error,
	existingDuplicatePatientsUuid,
}) => {
	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		setIsChecked(value.length > 0);
	}, [value]);

	const handleCheckboxChange = (checked) => {
		setIsChecked(checked);
		if (checked && value.length === 0) {
			onChange(['']);
		} else if (!checked) {
			onChange([]);
		}
	};

	const handleAddUUID = () => {
		onChange([...value, '']);
	};

	const handleUUIDChange = (index, newValue) => {
		const updatedList = [...value];
		updatedList[index] = newValue;
		onChange(updatedList);
	};

	const handleRemoveUUID = (index) => {
		const updatedList = value.filter((_, i) => i !== index);
		onChange(updatedList);

		if (updatedList.length === 0) {
			setIsChecked(false);
		}
	};

	const isUuidAlreadyExist = (uuid) => {
		return existingDuplicatePatientsUuid.find(
			(duplicatePatients) => duplicatePatients.uuid === uuid
		);
	};

	return (
		<div
			className={classnames('tag duplicate-patient-tag', {
				selected: isChecked,
			})}
		>
			<CheckboxField
				label='Duplicate Patient Record'
				name='duplicate'
				value={isChecked}
				onChange={handleCheckboxChange}
			/>
			{isChecked && (
				<div className='uuid-container'>
					{value.map((uuid, index) => (
						<div key={index} className='uuid-wrapper'>
							<div key={index} className='uuid-input-container'>
								<InputField
									name={`patientUUIDs.${index}`}
									label='UUID'
									placeholder='Enter UUID'
									value={uuid}
									onChange={(val) =>
										handleUUIDChange(index, val)
									}
									className='uuid-input'
									disabled={isUuidAlreadyExist(uuid)}
								/>
								<ReactSVG
									className='remove-uuid-icon-wrapper'
									onClick={() => handleRemoveUUID(index)}
									src={trashIcon}
								/>
							</div>
							{error && error[index] && (
								<div className='error'>{error[index]}</div>
							)}
						</div>
					))}
					<div
						className='add-another-uuid-button'
						onClick={handleAddUUID}
					>
						<ReactSVG
							className='addAnotherUuidSvgWrapper'
							src={plusIcon}
						/>
						<label>Add Another UUID</label>
					</div>
				</div>
			)}
		</div>
	);
};
