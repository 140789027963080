//React & Redux
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//Components
import EnhancedSearchTable from './EnhancedSearchTable';

//Styles
import './style.css';

const TableComponent = (props) => {
	const {
		sort_column,
		column,
		pageChange,
		sortList,
		data,
		history,
		activePage,
		selected_pill,
	} = props;

	return (
		<div className='patients_list_wrapper enhanced_search'>
			<EnhancedSearchTable
				sort_column={sort_column}
				column={column}
				handlePageChange={pageChange}
				handleSortChange={sortList}
				searchresult={data || []}
				history={history}
				activePage={activePage}
				selected_pill={selected_pill || []}
			/>
		</div>
	);
};

TableComponent.propTypes = {
	auth: PropTypes.object,
};

export default connect(null, null)(TableComponent);
