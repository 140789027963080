//React & Redux
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//Lodash
import _ from 'lodash';

//Utils
import {
	isPartnerInitiatedPreTest,
	isPartnerInitiatedUnsolicitedResult,
} from '../Encounters/Common/utilsfunctions';
import { MLOORderStatusHierarchyEnums } from '../Encounters/Common/utilsfunctions';

//Actions & Services
import {
	getOrderTestDetail,
	resetTestDetail,
} from './../../../../actions/tests';
import {
	addGeneTestResults,
	updateGeneTestResults,
} from './../../../../actions/patient/genes';
import { createTestOrderNote } from './../../../../actions/encounter';
import {
	getDiagnosticReports,
	addDiagnosticFinding,
	createDiagnosticReportAndAddFindings,
	updateDiagnosticFinding,
	deleteDiagnosticFinding,
	addDiagnosticFindingClinicalNote,
	resetGetDiagnosticReports,
} from './../../../../actions/cfDNA';
import {
	getReferenceAssembliesEnum,
	getGeneticResultGenesSymbol,
	addDiscreteVariantFinding,
	createDiagnosticReportAndAddDiscreteVariantFindings,
	updateDiscreteVariantFinding,
	resetGetGeneticResultGenesSymbol,
	getDiagnosticFindingValidation,
	resetGetDiagnosticFindingValidation,
} from './../../../../actions/discretevariant';
import {
	addStructuralVariantFinding,
	createDiagnosticReportAndAddStructuralVariantFindings,
	updateStructuralVariantFinding,
	getStructuralFindingValidation,
	resetGetStructuralFindingValidation,
} from './../../../../actions/structuralvariant';
import {
	createDiagnosticReportAndAddNucleotideRepeatVariantFinding,
	addNucleotideRepeatVariantFinding,
	updateNucleotideRepeatVariantFinding,
} from './../../../../actions/nucleotideRepeatVariant';
import {
	addCFDNAPrenatalScreeningFinding,
	createDiagnosticReportAndAddCFDNAPrenatalScreeningFinding,
} from './../../../../actions/cfDNAPrenatalScreening';

//Components
import EditTestOrderModalContainer from '../TestOrders/EditTestOrderModalContainer';
import View from './View';

export const TestDetail = (props) => {
	const {
		actions: {
			addGeneTestResults,
			addDiagnosticFinding,
			addDiscreteVariantFinding,
			addDiagnosticFindingClinicalNote,
			createDiagnosticReportAndAddDiscreteVariantFindings,
			createDiagnosticReportAndAddFindings,
			deleteDiagnosticFinding,
			getDiagnosticReports,
			getGeneticResultGenesSymbol,
			getReferenceAssembliesEnum,
			getOrderTestDetail,
			resetGetDiagnosticReports,
			resetGetGeneticResultGenesSymbol,
			resetTestDetail,
			updateDiagnosticFinding,
			updateDiscreteVariantFinding,
			updateGeneTestResults,
			getDiagnosticFindingValidation,
			resetGetDiagnosticFindingValidation,
			addStructuralVariantFinding,
			createDiagnosticReportAndAddStructuralVariantFindings,
			updateStructuralVariantFinding,
			getStructuralFindingValidation,
			resetGetStructuralFindingValidation,
			addCFDNAPrenatalScreeningFinding,
			createDiagnosticReportAndAddCFDNAPrenatalScreeningFinding,
			addNucleotideRepeatVariantFinding,
			createDiagnosticReportAndAddNucleotideRepeatVariantFinding,
			updateNucleotideRepeatVariantFinding,
		},
		parentProps: {
			callccintakeschema,
			callencountergroupsessionschema,
			calllabsschema,
			callresearchdataschema,
			callspecificencounterenum,
			callTestOrderNotes,
			callvisitsschema,
			ccintakeschema,
			enqueueSnackbar,
			getICDCodes = [],
			getOrderStatusColor,
			getProviderImage,
			getProviderName,
			getRequestStatus,
			goToTestTab,
			groupsessionschema,
			history,
			icdCodes = [],
			icdCodesError = null,
			icdCodesLoading = false,
			icdCodesPlacement = null,
			labsschema,
			LoadTestEnums,
			LoadTestOrderPopupTestEnum = [],
			match: {
				params: {
					encounterid: test_uuid = 0,
					patientid: patientId = 0,
				},
			},
			MLOOrderStatus = [],
			nonlegacytestOrderPopupTestEnums = [],
			order_status_main_level,
			ordering_physician,
			outreachStatusEnums = [],
			patientdetail,
			patientBillingMethodEnums = [],
			positiveResultsToReport,
			researchdataschema,
			sampletypeenums = [],
			sendNotification,
			testnameenums = [],
			testOrderNotes = [],
			updateTestOrderModal,
			user = {},
			visitsschema,
		},
		reduxStateProps: {
			activeRole,
			addDiagnosticFindingClinicalNoteError,
			addDiagnosticFindingClinicalNoteLoading,
			addDiagnosticFindingError,
			addDiagnosticFindingLoading,
			addDiscreteVariantFindingError,
			addDiscreteVariantFindingLoading,
			addStructuralVariantFindingLoading,
			addStructuralVariantFindingError,
			addGeneprops,
			diagnosticReports,
			diagnosticReportsError,
			diagnosticReportsLoading,
			editencounter,
			geneticResultGenesSymbolEnum,
			gettingTestDetail,
			referenceAssembliesEnum,
			testDetail,
			updategeneprops,
			diagnosticFindingValidationText,
			diagnosticFindingValidationError,
			diagnosticFindingValidationLoading,
			structuralFindingValidationLoading,
			structuralFindingValidationText,
			structuralFindingValidationError,
			addCFDNAPrenatalScreeningFindingLoading,
			addCFDNAPrenatalScreeningFindingError,
			addNucleotideRepeatVariantFindingLoading,
			addNucleotideRepeatVariantFindingError,
		},
	} = props;

	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		return () => resetGetDiagnosticReports();
	}, []);

	useEffect(() => {
		if (showModal) {
			callspecificencounterenum({
				type: testDetail.encounterName,
				field_name: 'ordering_physician',
			});
			LoadTestEnums(testDetail.labUUID);
		}
	}, [showModal]);

	const hideModal = () => {
		setShowModal(false);
	};

	const showTestOrderModal = () => {
		setShowModal(true);
	};

	const updateTestOrderFunc = (data) => {
		return updateTestOrderModal(test_uuid, data);
	};

	const updateTestOrderModalFunc = (
		test_order_uuid,
		data,
		encounterrequestdata = null,
		notedata
	) => {
		const promiseall = [];

		const promise1 = updateTestOrderModal(test_order_uuid, data);
		promiseall.push(promise1);
		if (!_.isEmpty(notedata)) {
			const promise2 = createTestOrderNote(test_order_uuid, notedata);
			promiseall.push(promise2);
		}

		return Promise.all(promiseall)
			.then((res) => {
				getOrderTestDetail(test_uuid);
				enqueueSnackbar('Test Order Updated Successfully', {
					variant: 'info',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				return res;
			})
			.catch((error) => {
				enqueueSnackbar('Error in Test Order', {
					variant: 'error',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				throw error;
			});
	};

	const getEncounterName = (data) => {
		const hasPartnerInitiatedPreTest = isPartnerInitiatedPreTest(
			_.get(data, 'encounter', {})
		);
		const hasPartnerInitiatedUnsolicitedResult =
			isPartnerInitiatedUnsolicitedResult(_.get(data, 'encounter', {}));

		let ConsultType =
			data?.encounterName &&
			data?.consulationType &&
			data?.encounterName == 'visit'
				? ' - ' + _.get(data, 'consulationType', '')
				: '';
		let encounterName = _.get(data, 'encounterName', '');
		if (hasPartnerInitiatedPreTest) {
			ConsultType = 'TRO';
			encounterName = '';
		}
		if (hasPartnerInitiatedUnsolicitedResult) {
			ConsultType = 'TRO - Results Only';
			encounterName = '';
		}
		return (
			encounterName +
			ConsultType +
			'. ' +
			(data?.serviceDate ? data?.serviceDate.format('MMM DD, YYYY') : '')
		);
	};

	const redirectToEncounterDetail = (data) => {
		const tabtype = 0;
		history.push({
			pathname:
				'/app/patientdetail/' +
				patientId +
				'/' +
				tabtype +
				'/' +
				'2' +
				'/' +
				data?.encounterUUID +
				'/',
			state: _.get(props, 'location.state', {}),
		});
	};

	const redirectToEncounterDetailURL = (data) => {
		const tabtype = 0;
		return (
			'/app/patientdetail/' +
			patientId +
			'/' +
			tabtype +
			'/' +
			'2' +
			'/' +
			data?.encounterUUID +
			'/'
		);
	};

	const getEncounterSchema = (data) => {
		switch (data?.encounterName) {
			case 'lab_test_authorization':
				return _.get(props, ['parentProps', 'labsschema', 'data'], {});
			case 'scp':
			case 'visit':
				return _.get(
					props,
					['parentProps', 'visitsschema', 'data'],
					{}
				);
			case 'group-session':
				return _.get(
					props,
					['parentProps', 'groupsessionschema', 'data'],
					{}
				);
			case 'cc-intake':
				return _.get(
					props,
					['parentProps', 'ccintakeschema', 'data'],
					{}
				);
			case 'research-data':
				return _.get(
					props,
					['parentProps', 'researchdataschema', 'data'],
					{}
				);
			default:
				return {};
		}
	};

	const getOrderingPhysicianEnums = () => {
		return ordering_physician?.visit || [];
	};

	const getLocalStatus = (el) => {
		let localorderstatus = _.get(el, 'testOrderStatus', null);
		if (
			!_.isNil(_.get(el, ['statusReason'], null)) &&
			!_.isEmpty(_.get(el, ['statusReason'], null))
		) {
			localorderstatus = _.get(el, ['statusReason'], null);
		}
		return localorderstatus;
	};

	return (
		<div className='tests-container'>
			<View
				{...{
					activeRole,
					AddGeneTestResultFunc: addGeneTestResults,
					addDiagnosticFindingClinicalNoteError,
					addDiagnosticFindingClinicalNoteLoading,
					addDiagnosticFindingError,
					addDiagnosticFindingLoading,
					addDiagnosticFinding,
					addDiscreteVariantFinding,
					addDiscreteVariantFindingError,
					addDiscreteVariantFindingLoading,
					addDiagnosticFindingClinicalNote,
					addGeneprops,
					callccintakeschema,
					callencountergroupsessionschema,
					calllabsschema,
					callresearchdataschema,
					callvisitsschema,
					ccintakeschema,
					createDiagnosticReportAndAddDiscreteVariantFindings,
					createDiagnosticReportAndAddFindings,
					data: testDetail,
					deleteDiagnosticFinding,
					diagnosticReports,
					diagnosticReportsError,
					diagnosticReportsLoading,
					editencounter,
					enqueueSnackbar,
					geneticResultGenesSymbolEnum,
					getDiagnosticReports,
					getEncounterName,
					getGeneticResultGenesSymbol,
					getOrderStatusColor,
					getProviderImage,
					getProviderName,
					getReferenceAssembliesEnum,
					getRequestStatus,
					getTestOrder: getOrderTestDetail,
					gettingTestDetail,
					goToTestTab,
					groupsessionschema,
					labsschema,
					order_status_main_level,
					patientId,
					ordering_physicianenums:
						getOrderingPhysicianEnums(testDetail),
					positiveResultsToReport,
					redirectToEncounterDetail,
					redirectToEncounterDetailURL,
					referenceAssembliesEnum,
					researchdataschema,
					resetGetGeneticResultGenesSymbol,
					resetTestDetailFunc: resetTestDetail,
					sendNotification,
					showTestOrderModal,
					test_uuid,
					updateDiagnosticFinding,
					updateDiscreteVariantFinding,
					updateGeneTestResultFunc: updateGeneTestResults,
					updategeneprops,
					updateTestOrder: updateTestOrderFunc,
					visitsschema,
					getDiagnosticFindingValidation,
					resetGetDiagnosticFindingValidation,
					diagnosticFindingValidationText,
					diagnosticFindingValidationLoading,
					diagnosticFindingValidationError,
					addStructuralVariantFinding,
					addStructuralVariantFindingLoading,
					addStructuralVariantFindingError,
					createDiagnosticReportAndAddStructuralVariantFindings,
					updateStructuralVariantFinding,
					getStructuralFindingValidation,
					structuralFindingValidationLoading,
					structuralFindingValidationText,
					structuralFindingValidationError,
					resetGetStructuralFindingValidation,
					addCFDNAPrenatalScreeningFinding,
					createDiagnosticReportAndAddCFDNAPrenatalScreeningFinding,
					addCFDNAPrenatalScreeningFindingLoading,
					addCFDNAPrenatalScreeningFindingError,
					addNucleotideRepeatVariantFinding,
					createDiagnosticReportAndAddNucleotideRepeatVariantFinding,
					updateNucleotideRepeatVariantFinding,
					addNucleotideRepeatVariantFindingLoading,
					addNucleotideRepeatVariantFindingError,
				}}
			/>

			{showModal && (
				<div className='popupModal'>
					<EditTestOrderModalContainer
						hideModal={hideModal}
						testorder={{
							lab: _.get(testDetail, 'labName', ''),
							test_name: _.get(testDetail, 'testName', ''),
							lab_uuid: _.get(testDetail, 'labUUID', ''),
							genetic_test_uuid: _.get(
								testDetail,
								'geneticTestUUID',
								''
							),
							order_status: _.get(
								testDetail,
								'testOrderStatus',
								''
							),
							ordering_physician: _.get(
								testDetail,
								'testOrderOrderingPhysician',
								''
							),
							medical_codes: _.get(
								testDetail,
								'medicalCodes',
								''
							),
							blood_draw_request: _.get(
								testDetail,
								'bloodDrawStatus',
								''
							),
							sample_type: _.get(testDetail, 'sampleType', ''),
							test_order_uuid: _.get(
								testDetail,
								'testOrderUUID',
								''
							),
							status_reason: _.get(
								testDetail,
								'statusReason',
								''
							),
							localorderstatus: getLocalStatus(testDetail),
							bd_saliva_kit_sent: _.get(
								testDetail,
								'bdSalivaKitSent',
								''
							),
							blood_draw_order_date: _.get(
								testDetail,
								'bloodDrawOrderDate',
								''
							),
							date_received_report: _.get(
								testDetail,
								'testOrderResultReceivedDate',
								''
							),
							date_results_released_to_patient: _.get(
								testDetail,
								'editTestOrderResultReleaseDate',
								''
							),
							date_test_ordered: _.get(
								testDetail,
								'testOrderDispatchDate',
								''
							),
							expected_lab_results_date: _.get(
								testDetail,
								'expectedLabResultDate',
								''
							),
							history: _.get(testDetail, 'history', []),
							date_test_authorized: _.get(
								testDetail,
								'testOrderAuthDate',
								''
							),
							patient_billing_method: _.get(
								testDetail,
								'patientBillingMethod',
								''
							),
							lab_paper_form_only: _.get(
								testDetail,
								'labPaperFormOnly',
								false
							),
							is_actionable: _.get(
								testDetail,
								['isActionable'],
								false
							),
							lab_order_status: _.get(
								testDetail,
								'labOrderStatusKeyName',
								''
							),
							is_questionnaire_editable: _.get(
								testDetail,
								['is_questionnaire_editable'],
								false
							),
							skip_lab_integration: _.get(
								testDetail,
								['skip_lab_integration'],
								false
							),
							order_questionnaire: _.get(
								testDetail,
								['order_questionnaire'],
								{}
							),
						}}
						patientdetail={_.get(patientdetail, '0', {})}
						encounter={_.get(testDetail, 'encounter', {})}
						LoadTestOrderPopupTestEnum={LoadTestOrderPopupTestEnum}
						encounterschema={getEncounterSchema(testDetail)}
						labenums={_.get(
							getEncounterSchema(testDetail),
							['lab'],
							[]
						)}
						enqueueSnackbar={enqueueSnackbar}
						updateTestOrder={updateTestOrderModalFunc}
						getICDCodes={getICDCodes}
						icdCodes={icdCodes}
						icdCodesLoading={icdCodesLoading}
						icdCodesError={icdCodesError}
						icdCodesPlacement={icdCodesPlacement}
						selectedICDCodes={_.get(testDetail, 'medicalCodes', [])}
						testOrderNumber={0}
						ordering_physicianenums={getOrderingPhysicianEnums(
							testDetail
						)}
						mloOrderStatusEnums={MLOORderStatusHierarchyEnums(
							MLOOrderStatus
						)}
						sampletypeenums={sampletypeenums}
						nonlegacytestOrderPopupTestEnums={
							nonlegacytestOrderPopupTestEnums
						}
						patientbillingmethodenums={patientBillingMethodEnums}
						testnameenums={testnameenums}
						user={user}
						callTestOrderNotes={callTestOrderNotes}
						testOrderNotes={testOrderNotes}
						testOutreachStatus={_.get(
							testDetail,
							['testOutreachStatus'],
							''
						)}
						outreachTimeToStart={_.get(
							testDetail,
							['outreachTimeToStart'],
							null
						)}
						outreachStatusEnums={outreachStatusEnums}
					/>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	reduxStateProps: {
		activeRole: state.me?.activerole?.name || null,
		addDiagnosticFindingClinicalNoteError:
			state.addDiagnosticFindingClinicalNote
				?.addDiagnosticFindingClinicalNoteError || false,
		addDiagnosticFindingClinicalNoteLoading:
			state.addDiagnosticFindingClinicalNote
				?.addDiagnosticFindingClinicalNoteLoading || false,
		addDiagnosticFindingError:
			state.addDiagnosticFinding?.addDiagnosticFindingError || null,
		addDiagnosticFindingLoading:
			state.addDiagnosticFinding?.addDiagnosticFindingLoading || false,
		addDiscreteVariantFindingError:
			state.addDiscreteVariantFinding?.addDiscreteVariantFindingError ||
			null,
		addDiscreteVariantFindingLoading:
			state.addDiscreteVariantFinding?.addDiscreteVariantFindingLoading ||
			false,
		addGeneprops: {
			adding: state.genes?.addingGeneResult,
			added: state.genes?.addedGeneResult,
			error: state.genes?.addingGeneResultError,
		},
		diagnosticReports: state.diagnosticReports?.diagnosticReports || [],
		diagnosticReportsError:
			state.diagnosticReports?.diagnosticReportsError || null,
		diagnosticReportsLoading:
			state.diagnosticReports?.diagnosticReportsLoading || false,
		editencounter: state.editencounter?.encounter,
		geneticResultGenesSymbolEnum:
			state.geneticResultGenesSymbolEnum?.geneticResultGenesSymbolEnum ||
			[],
		gettingTestDetail: state.getTestDetail?.gettingTestDetail,
		referenceAssembliesEnum:
			state.referenceAssembliesEnum?.referenceAssembliesEnum || [],
		testDetail: state.getTestDetail?.testDetail || {},
		updategeneprops: {
			updating: state.genes?.updatingGeneResult,
			updated: state.genes?.updatedGeneResult,
			error: state.genes?.updatingGeneResultError,
		},
		diagnosticFindingValidationText:
			state.getDiagnosticFindingValidation
				?.diagnosticFindingValidationText || {},
		diagnosticFindingValidationLoading:
			state.getDiagnosticFindingValidation
				?.diagnosticFindingValidationLoading || false,
		diagnosticFindingValidationError:
			state.getDiagnosticFindingValidation
				?.diagnosticFindingValidationError || null,
		addStructuralVariantFindingLoading:
			state.addStructuralVariantFinding
				?.addStructuralVariantFindingLoading || false,
		addStructuralVariantFindingError:
			state.addStructuralVariantFinding
				?.addStructuralVariantFindingError || null,
		structuralFindingValidationText:
			state.getStructuralFindingValidation
				?.structuralFindingValidationText || {},
		structuralFindingValidationLoading:
			state.getStructuralFindingValidation
				?.structuralFindingValidationLoading || false,
		structuralFindingValidationError:
			state.getStructuralFindingValidation
				?.structuralFindingValidationError || null,
		addCFDNAPrenatalScreeningFindingLoading:
			state.addCFDNAPrenatalScreeningFinding
				?.addCFDNAPrenatalScreeningFindingLoading || false,
		addCFDNAPrenatalScreeningFindingError:
			state.addCFDNAPrenatalScreeningFinding
				?.addCFDNAPrenatalScreeningFindingError || null,
		addNucleotideRepeatVariantFindingLoading:
			state.addNucleotideRepeatVariantFinding
				?.addNucleotideRepeatVariantFindingLoading || false,
		addNucleotideRepeatVariantFindingError:
			state.addNucleotideRepeatVariantFinding
				?.addNucleotideRepeatVariantFindingError || null,
	},
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(
		{
			addGeneTestResults,
			addDiagnosticFinding,
			addDiscreteVariantFinding,
			addDiagnosticFindingClinicalNote,
			createDiagnosticReportAndAddDiscreteVariantFindings,
			createDiagnosticReportAndAddFindings,
			deleteDiagnosticFinding,
			getDiagnosticReports,
			getGeneticResultGenesSymbol,
			getReferenceAssembliesEnum,
			getOrderTestDetail,
			resetGetDiagnosticReports,
			resetGetGeneticResultGenesSymbol,
			resetTestDetail,
			updateDiagnosticFinding,
			updateDiscreteVariantFinding,
			updateGeneTestResults,
			getDiagnosticFindingValidation,
			resetGetDiagnosticFindingValidation,
			addStructuralVariantFinding,
			createDiagnosticReportAndAddStructuralVariantFindings,
			updateStructuralVariantFinding,
			getStructuralFindingValidation,
			resetGetStructuralFindingValidation,
			addCFDNAPrenatalScreeningFinding,
			createDiagnosticReportAndAddCFDNAPrenatalScreeningFinding,
			addNucleotideRepeatVariantFinding,
			createDiagnosticReportAndAddNucleotideRepeatVariantFinding,
			updateNucleotideRepeatVariantFinding,
		},
		dispatch
	),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestDetail);
