//React & Redux
import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';

//Images
import editImage from './images/edit.svg';
import addicon from './images/add.svg';
import warningicon from '../../../../assets/warning.svg';
import imageWarningRed from '../../../../assets/warning-red.svg';
import moreinfoicon from '../../../../assets/purple-more-info.svg';

//Utils
import {
	CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER,
	CARE_PATIENTS_ENTER_TEST_RESULT,
} from '../../../../Utils/permissionUtils';
import { getProviderIcon } from '../Encounters/Common/utilsfunctions';

//UI Libraries
import {
	Radio,
	Loading,
	Dialog,
	Button,
	Input,
	Popover,
} from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';
import moment from 'moment';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious';

//Components
import RBAC from '../../../RBAC';
import GeneResult from './GeneResult';
import OrderStatusPill from '../../../Common/OrderStatusPill';
import LinkPopover from '../../../Common/Popovers/LinkPopover';
import CFDNACancerAssociatedModal from './CFDNACancerAssociatedModal';
import AddNewFindingSelectionDialog from './AddNewFindingSelectionDialog';
import AddEditDiscreteVariantModal from './AddEditDiscreteVariantModal';
import AddEditStructuralVariantModal from './AddEditStructuralVariantModal';
import AddEditNucleotideRepeatVariantModal from './AddEditNucleotideRepeatVariantModal';
import AddEditCFDNAPrenatalScreeningModal from './AddEditCFDNAPrenatalScreeningModal';
import ViewDiscreteVariantFullDetail from './ViewDiscreteVariantFullDetail';
import ViewStructuralVariantFullDetail from './ViewStructuralVariantFullDetail';
import ViewNucleotideRepeatVariantFullDetail from './ViewNucleotideRepeatVariantFullDetail';
import ViewCFDNAPrenatalScreeningFullDetail from './ViewCFDNAPrenatalScreeningFullDetail';
import Icon from '../../../Common/Icon';

//Styles
import './css/view.css';

const AddButtonValue = {
	addanother: 'addanother',
	complete: 'complete',
};

const dash = <span className='pat-dash'>--</span>;

const View = (props) => {
	const {
		AddGeneTestResultFunc,
		addDiagnosticFinding,
		addDiagnosticFindingClinicalNoteError = false,
		addDiagnosticFindingClinicalNoteLoading = false,
		addDiagnosticFindingError = false,
		addDiagnosticFindingLoading = false,
		addDiscreteVariantFinding,
		addDiscreteVariantFindingError = null,
		addDiscreteVariantFindingLoading = false,
		addGeneprops,
		addDiagnosticFindingClinicalNote: addNewDiagnosticFindingClinicalNote,
		callccintakeschema,
		callencountergroupsessionschema,
		calllabsschema,
		callresearchdataschema,
		callvisitsschema,
		data,
		deleteDiagnosticFinding,
		diagnosticReports,
		diagnosticReportsLoading,
		enqueueSnackbar,
		geneticResultGenesSymbolEnum = [],
		getDiagnosticReports,
		getEncounterName,
		getGeneticResultGenesSymbol,
		getProviderImage,
		getProviderName,
		getReferenceAssembliesEnum,
		getRequestStatus,
		getTestOrder,
		gettingTestDetail,
		goToTestTab,
		ordering_physicianenums = [],
		positiveResultsToReport,
		redirectToEncounterDetail,
		redirectToEncounterDetailURL,
		referenceAssembliesEnum = [],
		resetGetGeneticResultGenesSymbol,
		resetTestDetailFunc,
		sendNotification,
		showTestOrderModal,
		test_uuid = '',
		updategeneprops,
		updateTestOrder,
		getDiagnosticFindingValidation,
		diagnosticFindingValidationText = {},
		diagnosticFindingValidationLoading = false,
		diagnosticFindingValidationError = null,
		resetGetDiagnosticFindingValidation,
		addStructuralVariantFinding,
		addStructuralVariantFindingLoading = false,
		addStructuralVariantFindingError = null,
		getStructuralFindingValidation,
		structuralFindingValidationLoading,
		structuralFindingValidationText,
		structuralFindingValidationError,
		resetGetStructuralFindingValidation,
		addCFDNAPrenatalScreeningFinding,
		createDiagnosticReportAndAddCFDNAPrenatalScreeningFinding,
		addCFDNAPrenatalScreeningFindingLoading = false,
		addCFDNAPrenatalScreeningFindingError = null,
		addNucleotideRepeatVariantFinding,
		createDiagnosticReportAndAddNucleotideRepeatVariantFinding,
		addNucleotideRepeatVariantFindingLoading = false,
		addNucleotideRepeatVariantFindingError = null,
		updateNucleotideRepeatVariantFinding,
	} = props;

	const [form, setForm] = useState({
		positiveResultsToReport: positiveResultsToReport || null,
	});
	const [addButtonShow, setAddButtonShow] = useState(true);
	const [loading, setLoading] = useState(false);
	const [addBtnValue, setAddBtnValue] = useState(null);
	const [byPassLoader, setByPassLoader] = useState(false);
	const [dialogVisible, setDialogVisible] = useState(false);
	const [editClinicalComment, setEditClinicalComment] = useState(false);
	const [clinicalCommentsGeneral, setClinicalCommentsGeneral] = useState(
		data?.testclinicalCommentsGeneral || ''
	);
	const [updatingClinicalComments, setUpdatingClinicalComments] =
		useState(false);
	const [isActionable, setIsActionable] = useState(
		data?.isActionable || null
	);
	const [cfDNAModalMode, setcfDNAModalMode] = useState('view');
	const [
		showUnabletoChangeFindingDialog,
		setShowUnabletoChangeFindingDialog,
	] = useState(false);
	const [
		showAddNewFindingSelectionDialog,
		setShowAddNewFindingSelectionDialog,
	] = useState(false);

	const [diagnosticReportFindings, setDiagnosticReportFindings] = useState(
		{}
	);
	const [discreteVariantFindings, setDiscreteVariantFindings] = useState({});
	const [structuralVariantFindings, setStructuralVariantFindings] = useState(
		{}
	);
	const [
		nucleotideRepeatVariantFindings,
		setNucleotideRepeatVariantFindings,
	] = useState({});
	const [cfDNAPrenatalScreeningFindings, setcfDNAPrenatalScreeningFindings] =
		useState({});
	const [
		showViewDiscreteVariantFullDetail,
		setShowViewDiscreteVariantFullDetail,
	] = useState(false);
	const [
		showViewStructuralVariantFullDetail,
		setShowViewStructuralVariantFullDetail,
	] = useState(false);
	const [
		showViewNucleotideRepeatVariantFullDetail,
		setShowViewNucleotideRepeatVariantFullDetail,
	] = useState(false);
	const [
		showViewCFDNAPrenatalScreeningFullDetail,
		setShowViewCFDNAPrenatalScreeningFullDetail,
	] = useState(false);

	const [discreteVariantModalMode, setDiscreteVariantModalMode] =
		useState('add');
	const [structuralVariantModalMode, setStructuralVariantModalMode] =
		useState('add');
	const [
		nucleotideRepeatVariantModalMode,
		setNucleotideRepeatVariantModalMode,
	] = useState('add');
	const [
		cfDNAPrenatalScreeningModalMode,
		setcfDNAPrenatalScreeningModalMode,
	] = useState('add');

	const [
		showAddEditDiscreteVariantModal,
		setShowAddEditDiscreteVariantModal,
	] = useState(false);
	const [
		showAddEditStructuralVariantModal,
		setShowAddEditStructuralVariantModal,
	] = useState(false);
	const [showcfDNACancerAssociatedModal, setShowcfDNACancerAssociatedModal] =
		useState(false);
	const [
		showAddEditcfDNAPrenatalScreeningModal,
		setShowAddEditcfDNAPrenatalScreeningModal,
	] = useState(false);
	const [
		showAddEditNucleotideRepeatVariantModal,
		setShowAddEditNucleotideRepeatVariantModal,
	] = useState(false);

	const prevAddGeneprops = usePrevious(addGeneprops);
	const prevUpdateGeneprops = usePrevious(updategeneprops);
	const prevGettingTestDetail = usePrevious(gettingTestDetail);
	const prevAddDiagnosticFindingLoading = usePrevious(
		addDiagnosticFindingLoading
	);
	const prevAddDiagnosticFindingClinicalNoteLoading = usePrevious(
		addDiagnosticFindingClinicalNoteLoading
	);
	const prevData = usePrevious(data);
	const prevAddDiscreteVariantFindingLoading = usePrevious(
		addDiscreteVariantFindingLoading
	);
	const prevAddStructuralVariantFindingLoading = usePrevious(
		addStructuralVariantFindingLoading
	);
	const prevAddCFDNAPrenatalScreeningFindingLoading = usePrevious(
		addCFDNAPrenatalScreeningFindingLoading
	);
	const prevAddNucleotideRepeatVariantFindingLoading = usePrevious(
		addNucleotideRepeatVariantFindingLoading
	);

	const totalFinding =
		_.get(data, ['testResult'], 0).length +
		_.get(diagnosticReports, ['observationFinding'], 0).length +
		_.get(diagnosticReports, ['discreteVariantFinding'], 0).length +
		_.get(diagnosticReports, ['riskAssessmentFinding'], 0).length +
		_.get(diagnosticReports, ['structuralVariantFinding'], 0).length +
		_.get(diagnosticReports, ['cfDNAPrenatalObservationFinding'], 0).length;

	useEffect(() => {
		resetTestDetailFunc();
		getTestOrder(test_uuid);
	}, []);

	//Add Gene Result
	useEffect(() => {
		if (
			!_.isNil(prevAddGeneprops) &&
			prevAddGeneprops.adding != addGeneprops.adding &&
			addGeneprops.adding == true
		) {
			setLoading(true);
			setByPassLoader(true);
		} else if (
			!_.isNil(prevAddGeneprops) &&
			prevAddGeneprops.added != addGeneprops.added &&
			addGeneprops.added
		) {
			setByPassLoader(true);
			getTestOrder(test_uuid);

			if (addBtnValue == AddButtonValue.complete) {
				setAddButtonShow(true);
			}

			if (addBtnValue == AddButtonValue.addanother) {
				setAddButtonShow(false);
			}
		} else if (
			!_.isNil(prevAddGeneprops) &&
			prevAddGeneprops.error != addGeneprops.error &&
			prevAddGeneprops.adding == true &&
			addGeneprops.adding == false
		) {
			setByPassLoader(true);
			getTestOrder(test_uuid);
		}
	}, [addGeneprops]);

	//Update Gene Result
	useEffect(() => {
		if (
			!_.isNil(prevUpdateGeneprops) &&
			prevUpdateGeneprops.updating != updategeneprops.updating &&
			updategeneprops.updating == true
		) {
			setLoading(true);
			setByPassLoader(true);
		} else if (
			!_.isNil(prevUpdateGeneprops) &&
			prevUpdateGeneprops.updated != updategeneprops.updated &&
			updategeneprops.updated
		) {
			setByPassLoader(true);
			getTestOrder(test_uuid);
		} else if (
			!_.isNil(prevUpdateGeneprops) &&
			prevUpdateGeneprops.error != updategeneprops.error &&
			updategeneprops.updating == false &&
			prevUpdateGeneprops.updating == true
		) {
			setByPassLoader(true);
			getTestOrder(test_uuid);
		}
	}, [updategeneprops]);

	useEffect(() => {
		if (
			prevGettingTestDetail != gettingTestDetail &&
			gettingTestDetail == false
		) {
			setLoading(false);
			setByPassLoader(true);
		}
	}, [gettingTestDetail]);

	//set actionable state
	useEffect(() => {
		if (!_.isNil(prevData) && prevData != data) {
			setForm(
				Object.assign({}, form, {
					['testOrderUUID']: _.get(data, 'testOrderUUID', ''),
					['positiveResultsToReport']:
						_.get(data, 'testpositiveResultsToReport', null) ===
						null
							? _.get(prevData, 'positiveResultsToReport', null)
							: _.get(data, 'testpositiveResultsToReport', null),
				})
			);
			setIsActionable(data?.isActionable);
		}
	}, [data]);

	useEffect(() => {
		if (
			(!_.isNil(prevData) && prevData?.encounterName) ||
			'' != data?.encounterName ||
			''
		) {
			getEncounterEnums(data?.encounterName || '');
		}
	}, [data?.encounterName]);

	//get cfDNA, risk assessment
	useEffect(() => {
		if (
			(_.get(data, ['testOrderUUID'], null) &&
				_.get(data, ['testOrderUUID'], null) !==
					_.get(prevData, ['testOrderUUID'], null)) ||
			(!addDiagnosticFindingLoading &&
				!addDiagnosticFindingError &&
				addDiagnosticFindingLoading !==
					prevAddDiagnosticFindingLoading) ||
			(!addDiagnosticFindingClinicalNoteLoading &&
				!addDiagnosticFindingClinicalNoteError &&
				addDiagnosticFindingClinicalNoteLoading !==
					prevAddDiagnosticFindingClinicalNoteLoading) ||
			(!addDiscreteVariantFindingLoading &&
				!addDiscreteVariantFindingError &&
				addDiscreteVariantFindingLoading !==
					prevAddDiscreteVariantFindingLoading) ||
			(!addStructuralVariantFindingLoading &&
				!addStructuralVariantFindingError &&
				addStructuralVariantFindingLoading !==
					prevAddStructuralVariantFindingLoading) ||
			(!addCFDNAPrenatalScreeningFindingLoading &&
				!addCFDNAPrenatalScreeningFindingError &&
				addCFDNAPrenatalScreeningFindingLoading !==
					prevAddCFDNAPrenatalScreeningFindingLoading) ||
			(!addNucleotideRepeatVariantFindingLoading &&
				!addNucleotideRepeatVariantFindingError &&
				addNucleotideRepeatVariantFindingLoading !==
					prevAddNucleotideRepeatVariantFindingLoading)
		) {
			const testOrderUUID = _.get(data, ['testOrderUUID'], null);
			if (testOrderUUID) {
				getDiagnosticReports(test_uuid);
				getTestOrder(test_uuid);
			}
		}
	}, [
		data?.testOrderUUID,
		addDiagnosticFindingLoading,
		addDiagnosticFindingClinicalNoteLoading,
		addDiscreteVariantFindingLoading,
		addStructuralVariantFindingLoading,
		addCFDNAPrenatalScreeningFindingLoading,
		addNucleotideRepeatVariantFindingLoading,
	]);

	useEffect(() => {
		if (byPassLoader && prevData != data) {
			setByPassLoader(false);
		}
	}, [data]);

	const onChange = (key, value) => {
		setForm(Object.assign({}, form, { [key]: value }));
	};

	const onResultActionChange = (value) => {
		setIsActionable(value);
	};

	const showSaveBtn = () => {
		const positiveResultsToReport = form.positiveResultsToReport;
		const propsPositiveResultsToReport = _.get(
			props,
			['data', 'testpositiveResultsToReport'],
			null
		);
		const propsIsActionable = _.get(props, ['data', 'isActionable'], null);

		return (
			positiveResultsToReport != propsPositiveResultsToReport ||
			isActionable != propsIsActionable
		);
	};

	const revertChanges = () => {
		const propsPositiveResultsToReport = _.get(
			props,
			['data', 'testpositiveResultsToReport'],
			null
		);
		const propsIsActionable = _.get(props, ['data', 'isActionable'], null);
		setIsActionable(propsIsActionable);
		setForm(
			Object.assign({}, form, {
				positiveResultsToReport: propsPositiveResultsToReport,
			})
		);
	};

	const saveChangeFindingOrActionable = () => {
		const statePositiveResultsToReport = form.positiveResultsToReport;
		const propsPositiveResultsToReport = _.get(
			props,
			['data', 'testpositiveResultsToReport'],
			null
		);

		if (
			totalFinding > 0 &&
			statePositiveResultsToReport != propsPositiveResultsToReport
		) {
			setShowUnabletoChangeFindingDialog(true);
		} else if (
			totalFinding == 0 &&
			statePositiveResultsToReport != propsPositiveResultsToReport
		) {
			const _formdata = compareObj(
				{
					order_status: 'results_entered',
					status_reason:
						statePositiveResultsToReport == false
							? 'result_negative'
							: 'result_positive_no_genes',
					test_results: [],
					positive_results_to_report:
						statePositiveResultsToReport == false ? false : true,
					is_actionable: isActionable,
				},
				{
					order_status: _.get(data, ['testOrderStatus'], ''),
					status_reason: _.get(data, ['statusReason'], ''),
					test_results: _.get(data, ['testResult'], ''),
					positive_results_to_report: _.get(
						data,
						['testpositiveResultsToReport'],
						''
					),
					is_actionable: _.get(data, ['isActionable']),
				}
			);

			setByPassLoader(false);
			if (!_.isEmpty(_formdata)) {
				updateTestOrder(Object.assign({}, { ..._formdata })).then(
					() => {
						sendNotification(
							'Data updated successfully.',
							'success'
						);
						getTestOrder(test_uuid);
					}
				);
			}
		} else {
			setByPassLoader(false);
			props
				.updateTestOrder({ is_actionable: isActionable })
				.then(() => {
					props.sendNotification(
						'Data updated successfully.',
						'success'
					);
					props.getTestOrder(test_uuid);
				})
				.catch(() => {
					props.sendNotification(
						'Error while updating the test result.',
						'error'
					);
				});
		}
	};

	const compareObj = (state, props) => {
		const result = {};
		_.forIn(state, (val, key) => {
			if (
				!_.isEqual(val, _.get(props, key)) ||
				(key == 'order_status' &&
					_.get(state, 'status_reason') !=
						_.get(props, 'status_reason'))
			) {
				result[key] = val;
			}
		});
		return result;
	};

	const onChangeResult = () => {
		const statePositiveResultsToReport = form.positiveResultsToReport;
		const _formdata = compareObj(
			{
				order_status: 'results_entered',
				status_reason:
					statePositiveResultsToReport == false
						? 'result_negative'
						: 'result_positive_no_genes',
				test_results: [],
				positive_results_to_report:
					statePositiveResultsToReport == false ? false : true,
				is_actionable: isActionable,
			},
			{
				order_status: _.get(data, ['testOrderStatus'], ''),
				status_reason: _.get(data, ['statusReason'], ''),
				test_results: _.get(data, ['testResult'], ''),
				positive_results_to_report: _.get(
					data,
					['testpositiveResultsToReport'],
					''
				),
				is_actionable: _.get(data, ['isActionable']),
			}
		);

		setByPassLoader(false);
		setDialogVisible(false);
		if (!_.isEmpty(_formdata)) {
			updateTestOrder(Object.assign({}, { ..._formdata })).then(() => {
				sendNotification('Data updated successfully.', 'success');
				getTestOrder(test_uuid);
			});
		}
	};

	const onChangeResultCancel = () => {
		setDialogVisible(false);
	};

	const showLoading = () => {
		if (byPassLoader) {
			return false;
		} else {
			return (
				loading ||
				gettingTestDetail ||
				updatingClinicalComments ||
				diagnosticReportsLoading ||
				addDiagnosticFindingLoading ||
				addDiscreteVariantFindingLoading
			);
		}
	};

	const add_btnShowFunc = () => {
		return (
			_.get(props, ['data', 'testpositiveResultsToReport'], false) &&
			addButtonShow
		);
	};

	const callAddgeneResultFunc = (_data, value) => {
		setAddBtnValue(value);
		AddGeneTestResultFunc(_data);

		const _formdata = compareObj(
			{
				order_status: 'results_entered',
				status_reason: 'result_positive_with_genes',
			},
			{
				order_status: _.get(data, ['testOrderStatus'], ''),
				status_reason: _.get(data, ['statusReason'], ''),
			}
		);

		if (!_.isEmpty(_formdata)) {
			updateTestOrder(_formdata);
		}
	};

	const callDeleteLastGeneResultFunc = () => {
		const _formdata = compareObj(
			{
				order_status: 'results_entered',
				status_reason: 'result_positive_no_genes',
				test_results: [],
			},
			{
				order_status: _.get(data, ['testOrderStatus'], ''),
				status_reason: _.get(data, ['statusReason'], ''),
				test_results: _.get(data, ['testResult'], ''),
			}
		);

		updateTestOrder(_formdata);
	};

	const change_btnShowFunc = () => {
		setAddButtonShow(!addButtonShow);
	};

	const editTestOrder = () => {
		showTestOrderModal();
	};

	const handleEditClinicalComment = () => {
		setEditClinicalComment(!editClinicalComment);
		setClinicalCommentsGeneral(data?.testclinicalCommentsGeneral || '');
	};

	const handleClinicalComment = (value) => {
		setClinicalCommentsGeneral(value);
	};

	const handleSaveClinicalComment = () => {
		setUpdatingClinicalComments(true);
		setByPassLoader(false);
		updateTestOrder({
			clinician_comments: clinicalCommentsGeneral,
		}).then(() => {
			setUpdatingClinicalComments(false);
			setEditClinicalComment(false);
			getTestOrder(test_uuid);
			sendNotification(
				'Clinical comments updated successfully.',
				'success'
			);
		});
	};

	const updateGeneTestResultFunc = (geneUUID, obj) => {
		const data = {
			payload: obj,
			geneUUID: geneUUID,
			testOrderUUID: props.data.testOrderUUID,
		};
		props.updateGeneTestResultFunc(data);
	};

	const getEncounterEnums = (encounterName) => {
		switch (encounterName) {
			case 'lab_test_authorization':
				calllabsschema();
				break;
			case 'visit':
				callvisitsschema();
				break;
			case 'group-session':
				callencountergroupsessionschema();
				break;
			case 'cc-intake':
				callccintakeschema();
				break;
			case 'research-data':
				callresearchdataschema();
				break;
			default:
				calllabsschema();
				callvisitsschema();
				callencountergroupsessionschema();
				callccintakeschema();
				callresearchdataschema();
				break;
		}
	};

	const isReportReceiptDate = (data) => {
		return (
			data &&
			!_.isNil(data.testOrderReportReceiptDate) &&
			!_.isEmpty(data.testOrderReportReceiptDate)
		);
	};

	const showcfDNACancerModal = (
		cfDNAModalMode,
		diagnosticReportFindings = {}
	) => {
		setShowcfDNACancerAssociatedModal(true);
		setcfDNAModalMode(cfDNAModalMode);
		setDiagnosticReportFindings(diagnosticReportFindings);
	};

	const hidecfDNACancerModal = () => {
		setShowcfDNACancerAssociatedModal(false);
		setcfDNAModalMode('view');
	};

	const showAddNewFindingSelectionDialogFunc = () => {
		setShowAddNewFindingSelectionDialog(true);
	};

	const hideAddNewFindingSelectDialogFunc = () => {
		setShowAddNewFindingSelectionDialog(false);
	};

	const navigateToSelectedFindingFunc = (selectedFinding) => {
		if (selectedFinding == 'discrete_variant') {
			setShowAddNewFindingSelectionDialog(false);
			setShowAddEditDiscreteVariantModal(true);
		} else if (selectedFinding == 'structural_variant') {
			setShowAddNewFindingSelectionDialog(false);
			setShowAddEditStructuralVariantModal(true);
		} else if (selectedFinding == 'cfDNA') {
			setShowAddNewFindingSelectionDialog(false);
			setShowcfDNACancerAssociatedModal(true);
		} else if (selectedFinding == 'risk_assessment') {
			console.log(selectedFinding);
		} else if (selectedFinding == 'cfDNA_prenatal_screening') {
			setShowAddNewFindingSelectionDialog(false);
			setShowAddEditcfDNAPrenatalScreeningModal(true);
		} else if (selectedFinding == 'nucleotide_repeat_variant') {
			setShowAddNewFindingSelectionDialog(false);
			setShowAddEditNucleotideRepeatVariantModal(true);
		} else if (selectedFinding == 'other') {
			setShowAddNewFindingSelectionDialog(false);
			change_btnShowFunc();
		}
	};

	const showAddEditDiscreteVariantModalFunc = (
		discreteVariantModalMode,
		discreteVariantFindings = {}
	) => {
		setShowAddEditDiscreteVariantModal(true);
		setDiscreteVariantModalMode(discreteVariantModalMode);
		setDiscreteVariantFindings(discreteVariantFindings);
	};

	const hideAddEditDiscreteVariantModalFunc = () => {
		setShowAddEditDiscreteVariantModal(false);
		setDiscreteVariantModalMode('add');
	};

	const showAddEditStructuralVariantModalFunc = (
		structuralVariantModalMode,
		structuralVariantFindings = {}
	) => {
		setShowAddEditStructuralVariantModal(true);
		setStructuralVariantModalMode(structuralVariantModalMode);
		setStructuralVariantFindings(structuralVariantFindings);
	};

	const hideAddEditStructuralVariantModalFunc = () => {
		setShowAddEditStructuralVariantModal(false);
		setStructuralVariantModalMode('add');
	};

	const showAddEditNucleotideRepeatVariantModalFunc = (
		nucleotideRepeatVariantModalMode,
		nucleotideRepeatVariantFindings = {}
	) => {
		setShowAddEditNucleotideRepeatVariantModal(true);
		setNucleotideRepeatVariantModalMode(nucleotideRepeatVariantModalMode);
		setNucleotideRepeatVariantFindings(nucleotideRepeatVariantFindings);
	};

	const hideAddEditNucleotideRepeatVariantModalFunc = () => {
		setShowAddEditNucleotideRepeatVariantModal(false);
		setNucleotideRepeatVariantModalMode('add');
	};

	const hideAddEditCFDNAPrenatalScreeningModalFunc = () => {
		setShowAddEditcfDNAPrenatalScreeningModal(false);
		setcfDNAPrenatalScreeningModalMode('add');
	};

	const showViewDiscreteVariantFullDetailFunc = (
		discreteVariantFindings = {}
	) => {
		setDiscreteVariantFindings(discreteVariantFindings);
		setShowViewDiscreteVariantFullDetail(true);
	};

	const hideViewDiscreteVariantFullDetailFunc = () => {
		setShowViewDiscreteVariantFullDetail(false);
	};

	const showViewStructuralVariantFullDetailFunc = (
		structuralVariantFindings = {}
	) => {
		setStructuralVariantFindings(structuralVariantFindings);
		setShowViewStructuralVariantFullDetail(true);
	};

	const hideViewStructuralVariantFullDetailFunc = () => {
		setShowViewStructuralVariantFullDetail(false);
	};

	const showViewNucleotideRepeatVariantFullDetailFunc = (
		nucleotideRepeatVariantFindings = {}
	) => {
		setNucleotideRepeatVariantFindings(nucleotideRepeatVariantFindings);
		setShowViewNucleotideRepeatVariantFullDetail(true);
	};

	const hideViewNucleotideRepeatVariantFullDetailFunc = () => {
		setShowViewNucleotideRepeatVariantFullDetail(false);
	};

	const showViewCFDNAPrenatalScreeningFullDetailFunc = (
		cfDNAPrenatalScreeningFindings = {}
	) => {
		setcfDNAPrenatalScreeningFindings(cfDNAPrenatalScreeningFindings);
		setShowViewCFDNAPrenatalScreeningFullDetail(true);
	};

	const hideViewCFDNAPrenatalScreeningFullDetailFunc = () => {
		setShowViewCFDNAPrenatalScreeningFullDetail(false);
	};

	const getIndicateResultsSection = (_data, _dash, isDisabledForExternal) => {
		return (
			<div className='col-12 padding-top-32px'>
				<div
					className={classnames(
						'width-40',
						isReportReceiptDate(_data) && !isDisabledForExternal
							? ''
							: 'disable-indicate-result-section'
					)}
				>
					<div className='view-question-label'>
						<span className='error-star'>*</span>Are there any
						findings to report?
						<Popover
							width='250'
							trigger='hover'
							popperClass={classnames('pp-finding-result')}
							content="If the genetic testing report contains no findings or is considered 'normal' or 'negative' overall, please select 'No findings to report'. If there are individual findings to report, even those considered 'negative,' please select 'At least one finding to report.'"
							key='popover-finding-result'
						>
							<span className='tooltip-info'>
								<img src={moreinfoicon} />
							</span>
						</Popover>
					</div>
					<div className='view-question-text'>
						<Radio.Group
							value={form.positiveResultsToReport}
							onChange={(e) =>
								onChange('positiveResultsToReport', e)
							}
						>
							<Radio
								value={true}
								className={
									form.positiveResultsToReport
										? 'is-radio-checked'
										: ''
								}
							>
								At least one finding to report
							</Radio>
							<Radio
								value={false}
								className={
									form.positiveResultsToReport == false
										? 'is-radio-checked'
										: ''
								}
							>
								No findings
							</Radio>
						</Radio.Group>
					</div>
					<div className='result-actionable-label'>
						<span className='error-star'>*</span>Are the results
						Actionable?
						<Popover
							width='290'
							trigger='hover'
							popperClass={classnames('pp-result-actionable')}
							content={
								<Fragment>
									If there are findings, only change to no if
									the result is uncertain, benign, or variant
									of uncertain significance. If there are no
									findings to report, only change to yes if GC
									has requested outreach. If you have any
									questions at all, please reach out to the
									counselor of the week (COW) in the COW slack
									channel.&nbsp;
									<a
										href='https://docs.google.com/document/d/1Hcutji1fkM_JcFyIBzsV9Ble7utY-9w6nslJ6OOT4ic/edit'
										target='_blank'
										className='more-details'
										rel='noreferrer'
									>
										{' '}
										rel="noreferrer" More details
									</a>
								</Fragment>
							}
							key='popover-actioable'
						>
							<span className='tooltip-info'>
								<img src={moreinfoicon} />
							</span>
						</Popover>
					</div>
					<div className='result-actionable-yesno-button-wrapper'>
						<div
							data-testId='actionable-true'
							onClick={() => onResultActionChange(true)}
							className={classnames(
								'yes-button result-actionable-yesno-button',
								!_.isNil(isActionable) && isActionable
									? 'yesno-button-selected'
									: 'yesno-button-unselected'
							)}
						>
							Yes
						</div>
						<div
							data-testId='actionable-false'
							onClick={() => onResultActionChange(false)}
							className={classnames(
								'result-actionable-yesno-button',
								!_.isNil(isActionable) && !isActionable
									? 'yesno-button-selected'
									: 'yesno-button-unselected'
							)}
						>
							No
						</div>
					</div>
					{showSaveBtn() && (
						<div className='result-select-action-wrapper'>
							<Button
								data-testId='save-btn'
								onClick={() => saveChangeFindingOrActionable()}
								className='btn-result-select-save'
							>
								Save
							</Button>
							<Button
								data-testId='cancel-btn'
								type='text'
								onClick={() => revertChanges()}
								className='btn-result-select-cancel'
							>
								Cancel
							</Button>
						</div>
					)}
				</div>
				<div className='width-60 padding-left-20px'>
					<div className='view-label'>
						Clinician comments about test result/report (Optional)
						{isReportReceiptDate(_data) &&
							!isDisabledForExternal &&
							!editClinicalComment && (
								<RBAC
									action={
										CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
									}
									yes={
										<img
											data-testId='edit-clinical-comment'
											className='test-edit-image'
											src={editImage}
											alt=''
											onClick={() =>
												handleEditClinicalComment()
											}
										/>
									}
								/>
							)}
					</div>
					<div
						className={classnames(
							'word-break clinical-comments-textarea',
							!editClinicalComment ? 'view-text' : ''
						)}
					>
						{editClinicalComment && (
							<Fragment>
								<Input
									type='textarea'
									placeholder='Content'
									rows={5}
									value={clinicalCommentsGeneral}
									onChange={handleClinicalComment}
								/>
								<div className='c-action-buttons'>
									<div
										className='c-cancel-button'
										onClick={() =>
											handleEditClinicalComment()
										}
									>
										<span className='c-text'>Cancel</span>
									</div>
									<div
										className='c-save-button'
										onClick={handleSaveClinicalComment}
									>
										<span className='c-text'>Save</span>
									</div>
								</div>
							</Fragment>
						)}
						{!editClinicalComment &&
							((_data && _data.testclinicalCommentsGeneral) ||
								_dash)}
					</div>
				</div>
			</div>
		);
	};

	const saveFindings = (_data, isEditMode) => {
		const patientId = _.get(props, ['patientId'], null);
		const testOrderUUID = _.get(data, ['testOrderUUID'], null);
		const _diagnosticReportId = _.get(diagnosticReports, ['uuid'], null);

		setByPassLoader(false);

		if (!isEditMode) {
			if (_diagnosticReportId)
				addDiagnosticFinding(_data, _diagnosticReportId);
			else
				props.createDiagnosticReportAndAddFindings(
					_data,
					patientId,
					testOrderUUID
				);
		} else props.updateDiagnosticFinding(_data, _diagnosticReportId);

		const _formdata = compareObj(
			{
				order_status: 'results_entered',
				status_reason: 'result_positive_with_genes',
			},
			{
				order_status: _.get(data, ['testOrderStatus'], ''),
				status_reason: _.get(data, ['statusReason'], ''),
			}
		);

		if (!_.isEmpty(_formdata)) {
			updateTestOrder(_formdata);
		}
	};

	const saveDiscreteVariantFinding = (_data, isEditMode = 'add') => {
		const patientId = _.get(props, ['patientId'], null);
		const testOrderUUID = _.get(data, ['testOrderUUID'], null);
		const _diagnosticReportId = _.get(diagnosticReports, ['uuid'], null);

		setByPassLoader(false);

		if (!isEditMode) {
			if (_diagnosticReportId)
				addDiscreteVariantFinding(_data, _diagnosticReportId);
			else
				props.createDiagnosticReportAndAddDiscreteVariantFindings(
					_data,
					patientId,
					testOrderUUID
				);
		} else props.updateDiscreteVariantFinding(_data);

		const _formdata = compareObj(
			{
				order_status: 'results_entered',
				status_reason: 'result_positive_with_genes',
			},
			{
				order_status: _.get(data, ['testOrderStatus'], ''),
				status_reason: _.get(data, ['statusReason'], ''),
			}
		);

		if (!_.isEmpty(_formdata)) {
			updateTestOrder(_formdata);
		}
	};

	const saveStructuralVariantFinding = (_data, isEditMode = 'add') => {
		const patientId = _.get(props, ['patientId'], null);
		const testOrderUUID = _.get(data, ['testOrderUUID'], null);
		const _diagnosticReportId = _.get(diagnosticReports, ['uuid'], null);

		setByPassLoader(false);

		if (!isEditMode) {
			if (_diagnosticReportId) {
				addStructuralVariantFinding(_data, _diagnosticReportId);
			} else {
				props.createDiagnosticReportAndAddStructuralVariantFindings(
					_data,
					patientId,
					testOrderUUID
				);
			}
		} else {
			props.updateStructuralVariantFinding(_data);
		}

		const _formdata = compareObj(
			{
				order_status: 'results_entered',
				status_reason: 'result_positive_with_genes',
			},
			{
				order_status: _.get(data, ['testOrderStatus'], ''),
				status_reason: _.get(data, ['statusReason'], ''),
			}
		);

		if (!_.isEmpty(_formdata)) {
			updateTestOrder(_formdata);
		}
	};

	const saveNucleotideRepeatVariantFinding = (_data, isEditMode = 'add') => {
		const patientId = props?.patientId;
		const testOrderUUID = data?.testOrderUUID;
		const _diagnosticReportId = diagnosticReports?.uuid;

		setByPassLoader(false);

		if (!isEditMode) {
			if (_diagnosticReportId)
				addNucleotideRepeatVariantFinding(_data, _diagnosticReportId);
			else
				createDiagnosticReportAndAddNucleotideRepeatVariantFinding(
					_data,
					patientId,
					testOrderUUID
				);
		} else {
			updateNucleotideRepeatVariantFinding(_data);
		}

		const _formdata = compareObj(
			{
				order_status: 'results_entered',
				status_reason: 'result_positive_with_genes',
			},
			{
				order_status: _.get(data, ['testOrderStatus'], ''),
				status_reason: _.get(data, ['statusReason'], ''),
			}
		);

		if (!_.isEmpty(_formdata)) {
			updateTestOrder(_formdata);
		}
	};

	const saveCFDNAPrenatalScreeningFinding = (_data, isEditMode = 'add') => {
		const patientId = _.get(props, ['patientId'], null);
		const testOrderUUID = _.get(data, ['testOrderUUID'], null);
		const _diagnosticReportId = _.get(diagnosticReports, ['uuid'], null);

		setByPassLoader(false);

		if (!isEditMode) {
			if (_diagnosticReportId) {
				addCFDNAPrenatalScreeningFinding(_data, _diagnosticReportId);
			} else {
				createDiagnosticReportAndAddCFDNAPrenatalScreeningFinding(
					_data,
					patientId,
					testOrderUUID
				);
			}
		}

		const _formdata = compareObj(
			{
				order_status: 'results_entered',
				status_reason: 'result_positive_with_genes',
			},
			{
				order_status: _.get(data, ['testOrderStatus'], ''),
				status_reason: _.get(data, ['statusReason'], ''),
			}
		);

		if (!_.isEmpty(_formdata)) {
			updateTestOrder(_formdata);
		}
	};

	const deleteFinding = (diagnosticFindingId) => {
		const isResultWithOldUI = _.size(_.get(data, ['testResult'], 0)) > 0;

		deleteDiagnosticFinding(diagnosticFindingId);
		setByPassLoader(false);

		const allFindings = _.concat(
			_.get(diagnosticReports, ['observationFinding'], []),
			_.get(diagnosticReports, ['discreteVariantFinding'], []),
			_.get(diagnosticReports, ['riskAssessmentFinding'], []),
			_.get(diagnosticReports, ['structuralVariantFinding'], []),
			_.get(diagnosticReports, ['cfDNAPrenatalObservationFinding'], []),
			_.get(diagnosticReports, ['nucleotideRepeatVariantFinding'], [])
		);

		if (_.get(allFindings, ['length'], 0) === 1 && !isResultWithOldUI) {
			callDeleteLastGeneResultFunc();
		}
	};

	const closeUnableChangeFindingDialog = () => {
		setShowUnabletoChangeFindingDialog(false);
		revertChanges();
	};

	return (
		<Loading className='test-view-loader' loading={showLoading()}>
			{
				<div className='test-view-container'>
					<div className='col-12'>
						<div
							className='test-back-link'
							onClick={() => goToTestTab()}
						>
							{'< Test list'}
						</div>
						<div className='test-header'>
							<div className='test-header-data'>
								{_.get(data, 'testName', dash)}
								<RBAC
									action={
										CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
									}
									yes={
										<img
											data-testId='test-edit'
											className='test-edit-image'
											src={editImage}
											alt=''
											onClick={() => editTestOrder(data)}
										/>
									}
								/>
							</div>
						</div>
						<div className='text-sub-header'>
							{_.get(data, 'labName', dash)}
						</div>
					</div>
					<div className='col-12 padding-top-32px'>
						<div className='width-40'>
							<div className='view-label'>Encounter</div>
							<div className='view-text-link'>
								<LinkPopover
									enqueueSnackbar={enqueueSnackbar}
									linkTo={redirectToEncounterDetailURL(data)}
									options={{ link: true }}
								>
									<span
										data-testId='view-encounter'
										className='view-text-link-inner'
										onClick={() =>
											redirectToEncounterDetail(data)
										}
									>
										{getEncounterName(data)}
									</span>
								</LinkPopover>
							</div>
						</div>
						<div className='width-20 padding-left-20px'>
							<div className='view-label'>Visit provider</div>
							<div className='view-text'>
								<div className='provider'>
									{_.get(data, 'providerName') && (
										<img
											className='provider-image'
											src={getProviderImage(data)}
											alt=''
										/>
									)}
									<div className='provider-name'>
										{getProviderName(data?.providerName)}
									</div>
								</div>
							</div>
						</div>
						<div className='width-30 padding-left-20px'>
							<div className='view-label'>Order status</div>
							<div className='view-text'>
								{data?.testOrderStatus && (
									<OrderStatusPill
										value={
											!_.isEmpty(data?.statusReason)
												? data?.statusReason
												: data?.testOrderStatus
										}
										displayName={getRequestStatus(
											data?.testOrderStatus,
											data?.statusReason,
											false
										)}
									/>
								)}
							</div>
						</div>
					</div>
					<div className='col-12 padding-top-24px'>
						<div className='width-20'>
							<div className='view-label'>Ordering physician</div>
							<div className='view-text'>
								<div className='provider'>
									<img
										className='provider-image'
										src={getProviderIcon(data, [
											'testOrderOrderingPhysician',
										])}
										alt=''
									/>
									<div className='provider-name'>
										{ordering_physicianenums.find(
											(item) =>
												item.key ===
												data?.testOrderOrderingPhysician
										)?.display_name ?? '--'}
									</div>
								</div>
							</div>
						</div>
						<div className='width-20 padding-left-20px'>
							<div className='view-label'>Test auth. date</div>
							<div className='view-text'>
								{!_.isEmpty(data?.testOrderAuthDate)
									? moment(data?.testOrderAuthDate).format(
											'MMM DD, YYYY'
										)
									: dash}
							</div>
						</div>
						<div className='width-20 padding-left-20px'>
							<div className='view-label'>Test dispatch date</div>
							<div className='view-text'>
								{!_.isEmpty(data?.testOrderDispatchDate)
									? moment(
											data?.testOrderDispatchDate
										).format('MMM DD, YYYY')
									: dash}
							</div>
						</div>
						<div className='width-20 padding-left-20px'>
							<div className='view-label'>
								Report receipt date
							</div>
							<div className='view-text'>
								{!_.isEmpty(data?.testOrderReportReceiptDate)
									? moment(
											data?.testOrderReportReceiptDate
										).format('MMM DD, YYYY')
									: dash}
							</div>
						</div>
						<div className='width-20 padding-left-20px'>
							<div className='view-label'>
								Results release date
							</div>
							<div className='view-text'>
								{!_.isEmpty(data?.testOrderResultReleaseDate)
									? moment(
											data?.testOrderResultReleaseDate
										).format('MMM DD, YYYY')
									: dash}
							</div>
						</div>
					</div>
					<div className='col-12  padding-top-32px'>
						<hr />
					</div>
					{
						<RBAC
							action={CARE_PATIENTS_ENTER_TEST_RESULT}
							yes={getIndicateResultsSection(data, dash, false)}
							no={getIndicateResultsSection(data, dash, true)}
						/>
					}
					{_.get(data, ['testResult', 'length'], 0) == 0 ? (
						<hr className='test-detail-separator' />
					) : (
						''
					)}
				</div>
			}
			{_.get(data, ['testpositiveResultsToReport'], null) &&
			!_.isEmpty(data) ? (
				<div
					className={classnames(
						isReportReceiptDate(data)
							? ''
							: 'disable-indicate-result-section'
					)}
				>
					<GeneResult
						{...{
							add_componentShow: add_btnShowFunc,
							addDiagnosticFindingClinicalNoteError,
							addDiagnosticFindingClinicalNoteLoading,
							addNewDiagnosticFindingClinicalNote,
							change_btnShowFunc,
							callAddgeneResultFunc,
							callDeleteLastGeneResultFunc,
							callupdateGeneTestFunc: updateGeneTestResultFunc,
							diagnosticReports,
							enqueueSnackbar,
							gettingTestDetail,
							showAddEditDiscreteVariantModalFunc,
							showAddEditStructuralVariantModalFunc,
							showAddEditNucleotideRepeatVariantModalFunc,
							showcfDNACancerModal,
							showViewDiscreteVariantFullDetailFunc,
							showViewStructuralVariantFullDetailFunc,
							testOrderUUID: data.testOrderUUID,
							testResult: data.testResult || [],
							testUUID: data.testUUID,
							showViewCFDNAPrenatalScreeningFullDetailFunc,
							showViewNucleotideRepeatVariantFullDetailFunc,
						}}
					/>
				</div>
			) : null}

			{add_btnShowFunc() && (
				<div className='add-gene-button-wrapper'>
					{
						<RBAC
							action={CARE_PATIENTS_ENTER_TEST_RESULT}
							yes={
								<div
									className={'add-new-finding-button'}
									onClick={() =>
										showAddNewFindingSelectionDialogFunc()
									}
								>
									<img
										className='add-new-finding-icon'
										src={addicon}
										alt=''
									/>
									<span className='add-new-finding-text'>
										Add New Finding
									</span>
								</div>
							}
						/>
					}
				</div>
			)}

			<Dialog
				customClass='dlg-gene-negative-result'
				title={
					<span>
						<img src={warningicon} alt='' className='' />
						{form.positiveResultsToReport === false
							? 'Mark as No Findings'
							: 'Indicate Findings'}
					</span>
				}
				size='tiny'
				modal='true'
				closeOnPressEscape='true'
				closeOnClickModal='true'
				showClose={false}
				visible={dialogVisible}
				onCancel={() => onChangeResultCancel()}
			>
				<Dialog.Body>
					{form.positiveResultsToReport === false ? (
						<Fragment>
							<p>
								Are you sure you want to mark this result as{' '}
								<span className='bold-fontfamily'>
									No Findings?
								</span>
							</p>
							<p>Any findings entered will be deleted.</p>
						</Fragment>
					) : (
						<Fragment>
							<p>
								Are you sure you want to indicate that there is{' '}
								<span className='bold-fontfamily'>
									at least one finding to report?
								</span>
							</p>
						</Fragment>
					)}
				</Dialog.Body>
				<Dialog.Footer className='dialog-footer'>
					<Button onClick={() => onChangeResultCancel()}>
						Cancel
					</Button>
					<Button onClick={() => onChangeResult()}>
						{form.positiveResultsToReport === false
							? 'Mark as No Findings'
							: 'Confirm'}
					</Button>
				</Dialog.Footer>
			</Dialog>

			<Dialog
				customClass='unable-change-finding-dialog'
				title={
					<span className='title-wrapper'>
						<img
							src={imageWarningRed}
							alt=''
							className='warning-icon'
						/>
						<span>Unable to Change Status</span>
						<span
							className='close-btn-icon'
							onClick={closeUnableChangeFindingDialog}
						>
							<Icon icon='close' className='close-icon' />
						</span>
					</span>
				}
				size='tiny'
				modal={true}
				closeOnPressEscape={false}
				closeOnClickModal={false}
				showClose={false}
				visible={showUnabletoChangeFindingDialog}
			>
				<Dialog.Body>
					<span className='unable-change-finding-message'>
						<p>
							You have already entered ({totalFinding}) findings.
						</p>
						<p>
							Before changing the status to "
							<span className='bold-fontfamily'>
								No findings,
							</span>
							" you must first delete each individual finding.
							Click "
							<span className='bold-fontfamily'>
								Edit Details
							</span>
							" on the finding card to begin the deletion process.
						</p>
					</span>
				</Dialog.Body>
			</Dialog>

			{showcfDNACancerAssociatedModal && (
				<CFDNACancerAssociatedModal
					hidecfDNACancerModal={hidecfDNACancerModal}
					modalMode={cfDNAModalMode}
					diagnosticReportFindings={diagnosticReportFindings}
					saveFindings={saveFindings}
					deleteFinding={deleteFinding}
				/>
			)}
			{showAddNewFindingSelectionDialog && (
				<AddNewFindingSelectionDialog
					navigateToSelectedFindingFunc={
						navigateToSelectedFindingFunc
					}
					hideAddNewFindingSelectDialogFunc={
						hideAddNewFindingSelectDialogFunc
					}
					isCFDNAPrenatalScreeningDisabled={
						_.get(
							diagnosticReports,
							['cfDNAPrenatalObservationFinding'],
							0
						).length > 0
					}
				/>
			)}
			{showAddEditDiscreteVariantModal && (
				<AddEditDiscreteVariantModal
					hideAddEditDiscreteVariantModalFunc={
						hideAddEditDiscreteVariantModalFunc
					}
					modalMode={discreteVariantModalMode}
					discreteVariantFindings={discreteVariantFindings}
					getReferenceAssembliesEnum={getReferenceAssembliesEnum}
					referenceAssembliesEnum={referenceAssembliesEnum}
					saveDiscreteVariantFinding={saveDiscreteVariantFinding}
					getGeneticResultGenesSymbol={getGeneticResultGenesSymbol}
					geneticResultGenesSymbolEnum={geneticResultGenesSymbolEnum}
					resetGetGeneticResultGenesSymbol={
						resetGetGeneticResultGenesSymbol
					}
					deleteDiscreteVariantFinding={deleteFinding}
					labUUID={_.get(data, ['labUUID'], '')}
					getDiagnosticFindingValidation={
						getDiagnosticFindingValidation
					}
					diagnosticFindingValidationText={
						diagnosticFindingValidationText
					}
					diagnosticFindingValidationLoading={
						diagnosticFindingValidationLoading
					}
					diagnosticFindingValidationError={
						diagnosticFindingValidationError
					}
					resetGetDiagnosticFindingValidation={
						resetGetDiagnosticFindingValidation
					}
				/>
			)}
			{showAddEditStructuralVariantModal && (
				<AddEditStructuralVariantModal
					hideAddEditStructuralVariantModalFunc={
						hideAddEditStructuralVariantModalFunc
					}
					modalMode={structuralVariantModalMode}
					structuralVariantFindings={structuralVariantFindings}
					getReferenceAssembliesEnum={getReferenceAssembliesEnum}
					referenceAssembliesEnum={referenceAssembliesEnum}
					saveStructuralVariantFinding={saveStructuralVariantFinding}
					getGeneticResultGenesSymbol={getGeneticResultGenesSymbol}
					geneticResultGenesSymbolEnum={geneticResultGenesSymbolEnum}
					resetGetGeneticResultGenesSymbol={
						resetGetGeneticResultGenesSymbol
					}
					deleteStructuralVariantFinding={deleteFinding}
					labUUID={_.get(data, ['labUUID'], '')}
					getStructuralFindingValidation={
						getStructuralFindingValidation
					}
					structuralFindingValidationText={
						structuralFindingValidationText
					}
					structuralFindingValidationLoading={
						structuralFindingValidationLoading
					}
					structuralFindingValidationError={
						structuralFindingValidationError
					}
					resetGetStructuralFindingValidation={
						resetGetStructuralFindingValidation
					}
				/>
			)}
			{showAddEditNucleotideRepeatVariantModal && (
				<AddEditNucleotideRepeatVariantModal
					hideAddEditNucleotideRepeatVariantModalFunc={
						hideAddEditNucleotideRepeatVariantModalFunc
					}
					modalMode={nucleotideRepeatVariantModalMode}
					nucleotideRepeatVariantFindings={
						nucleotideRepeatVariantFindings
					}
					geneticResultGenesSymbolEnum={geneticResultGenesSymbolEnum}
					referenceAssembliesEnum={referenceAssembliesEnum}
					getReferenceAssembliesEnum={getReferenceAssembliesEnum}
					getGeneticResultGenesSymbol={getGeneticResultGenesSymbol}
					saveNucleotideRepeatVariantFinding={
						saveNucleotideRepeatVariantFinding
					}
					resetGetGeneticResultGenesSymbol={
						resetGetGeneticResultGenesSymbol
					}
					labUUID={data?.labUUID || ''}
					deleteNucleotideRepeatVariantFinding={deleteFinding}
				/>
			)}
			{showAddEditcfDNAPrenatalScreeningModal && (
				<AddEditCFDNAPrenatalScreeningModal
					hideAddEditCFDNAPrenatalScreeningModalFunc={
						hideAddEditCFDNAPrenatalScreeningModalFunc
					}
					modalMode={cfDNAPrenatalScreeningModalMode}
					cfDNAPrenatalScreeningFindings={
						cfDNAPrenatalScreeningFindings
					}
					saveCFDNAPrenatalScreeningFinding={
						saveCFDNAPrenatalScreeningFinding
					}
					deletecfDNAPrenatalScreeningFinding={deleteFinding}
				/>
			)}
			{showViewDiscreteVariantFullDetail && (
				<ViewDiscreteVariantFullDetail
					hideViewDiscreteVariantFullDetailFunc={
						hideViewDiscreteVariantFullDetailFunc
					}
					showAddEditDiscreteVariantModalFunc={
						showAddEditDiscreteVariantModalFunc
					}
					discreteVariantFindings={discreteVariantFindings}
				/>
			)}
			{showViewStructuralVariantFullDetail && (
				<ViewStructuralVariantFullDetail
					hideViewStructuralVariantFullDetailFunc={
						hideViewStructuralVariantFullDetailFunc
					}
					showAddEditStructuralVariantModalFunc={
						showAddEditStructuralVariantModalFunc
					}
					structuralVariantFindings={structuralVariantFindings}
				/>
			)}
			{showViewNucleotideRepeatVariantFullDetail && (
				<ViewNucleotideRepeatVariantFullDetail
					hideViewNucleotideRepeatVariantFullDetailFunc={
						hideViewNucleotideRepeatVariantFullDetailFunc
					}
					showAddEditNucleotideRepeatVariantModalFunc={
						showAddEditNucleotideRepeatVariantModalFunc
					}
					nucleotideRepeatVariantFindings={
						nucleotideRepeatVariantFindings
					}
				/>
			)}
			{showViewCFDNAPrenatalScreeningFullDetail && (
				<ViewCFDNAPrenatalScreeningFullDetail
					hideViewCFDNAPrenatalScreeningFullDetailFunc={
						hideViewCFDNAPrenatalScreeningFullDetailFunc
					}
					cfDNAPrenatalScreeningFindings={
						cfDNAPrenatalScreeningFindings
					}
				/>
			)}
		</Loading>
	);
};

export default View;
