import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../../assets/exit-alert.svg';
import searchIcon from './../../../assets/ic_search.svg';

//UI Libraries
import CommonCheckbox from './../../Common/Checkbox.js';

const MultiSelectSearchHeader = (props) => {
	const {
		data = [],
		handleSearch,
		hideSelectAll = false,
		internalValues = [],
		searchBar = false,
		searchQuery = '',
		searchReset,
		selectAll,
		selectNone,
	} = props;

	const isChecked = data.length === internalValues.length;
	return searchBar ? (
		<div className='multi-select-search-container'>
			<div className='multi-select-search-row-container'>
				<div className='multi-select-search-bar-container'>
					<input
						className='multi-select-search-input'
						placeholder='Search'
						onChange={(e) => handleSearch(e)}
						value={searchQuery}
					/>
					{searchQuery?.length ? (
						<ReactSVG
							src={xIcon}
							style={{
								position: 'relative',
								bottom: '6px',
								left: '2px',
							}}
							onClick={searchReset}
							className='multi-select-search-clear'
						/>
					) : (
						<ReactSVG
							src={searchIcon}
							style={{
								position: 'relative',
								bottom: '1px',
								right: '5px',
							}}
						/>
					)}
				</div>
				{internalValues.length ? (
					<div
						className='multi-select-search-toggle'
						onClick={() => selectNone(false)}
						style={{ position: 'relative', right: '7px' }}
					>
						Select None
					</div>
				) : (
					<div
						className='multi-select-search-toggle'
						onClick={() => selectAll(false)}
					>
						Select All
					</div>
				)}
			</div>
		</div>
	) : (
		!hideSelectAll && (
			<div
				className='chargeICDSearchResultRow multi-select-checkbox-modal-row multi-select-checkbox-modal-row-select-all'
				onClick={(e) => {
					e.preventDefault();
					selectAll(true);
				}}
				style={{
					borderBottom: '1px solid #edeff3',
					textTransform: 'capitalize',
				}}
			>
				<CommonCheckbox
					className={`chargeICDSearchResultRowCheckbox ${
						isChecked
							? 'chargeICDSearchResultRowCheckboxSelected'
							: ''
					}`}
					checked={isChecked}
				/>
				<div
					className={`chargeICDSearchResultRowTitle ${
						isChecked ? 'chargeICDSearchResultRowSelectedText' : ''
					}`}
				>
					Select all
				</div>
			</div>
		)
	);
};

export default MultiSelectSearchHeader;
