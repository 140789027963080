//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../assets/exit-alert.svg';

//Lodash
import _ from 'lodash';

const TemplateModalReferralPrograms = (props) => {
	const {
		close,
		preformedText = '',
		subtitle,
		title = 'Associated List',
		template = {},
		templateName,
		referralPrograms = [],
		allStatus,
	} = props;

	const renderReferralPrograms = () => {
		if (_.get(template, 'all_partners', null) || allStatus) {
			return <div className='referralProgramsModalBodyRow'>{'All'}</div>;
		} else {
			return referralPrograms.map((el) => {
				return (
					<div className='referralProgramsModalBodyRow' key={el}>
						{el || '--'}
					</div>
				);
			});
		}
	};

	const renderPreformedText = () =>
		preformedText.length ? (
			preformedText.map((el) => {
				return (
					<div className='referralProgramsModalBodyRow' key={el}>
						{el || '--'}
					</div>
				);
			})
		) : (
			<div className='referralProgramsModalBodyRow'>"--"</div>
		);

	return (
		<div className='fullModalBackground'>
			<div className='referralProgramsModalContainer'>
				<div className='referralProgramsModalTopRowContainer'>
					<div className='referralProgramsModalTopRowTitleSubtitleContainer'>
						<div className='referralProgramsModalTopRowTitle'>
							{title}
						</div>
						<div className='referralProgramsModalTopRowSubtitle'>
							{(subtitle || templateName || '--').trim() || '--'}
						</div>
					</div>
					<div className='chargeModalClose'>
						<ReactSVG
							src={xIcon}
							style={{
								position: 'relative',
								bottom: '2px',
								right: '1.25px',
							}}
							onClick={close}
						/>
					</div>
				</div>
				<div className='referralProgramsModalBodyContainer'>
					{preformedText
						? renderPreformedText()
						: renderReferralPrograms()}
				</div>
			</div>
		</div>
	);
};

export default TemplateModalReferralPrograms;
