// React & Redux
import React from 'react';

// Other Libraries
import { isEmpty, isArray, get, isNil } from 'lodash';
import moment from 'moment';
import InputMask from 'react-input-mask';

// UI Libraries
import { Layout, Select, Input, Tabs } from 'gm-element-react';
import classnames from 'classnames';

// Components
import CreateTestRequestEncntrInfo from './CreateTestRequestEncntrInfo';
import TestRequestInfoTab from './TestRequestInfoTab';
import { EncounterDateField } from './DateComponent';

const DateTypes = ['date_any', 'date_past', 'date_future'];

const AOEQuestions = (props) => {
	const {
		isEditable,
		questions,
		answers,
		setAnswer,
		encounter,
		encounterSchema,
		patientdetail,
		testorder,
		orderingPhysicianenums,
		selectedICDCodes,
		sampletypeenums,
		patientbillingmethodenums,
		formdata,
		tabvalue,
		aoeAnswersErrors = {},
	} = props;

	const handleChange = (key, value, type, max, min) => {
		let newAnswers = { ...answers };

		if (type === 'int' && (value === '' || /^[0-9]+$/.test(value))) {
			if (
				(min &&
					parseInt(value) >= min &&
					max &&
					parseInt(value) <= max) ||
				(isNil(min) && isNil(max)) ||
				(!isNil(min) && !isNil(max) && value === '') //For removing all value through backspace
			) {
				newAnswers = {
					...answers,
					[key]: value,
				};
			}
		} else if (type !== 'int') {
			newAnswers = {
				...answers,
				[key]: value,
			};
		}

		for (const { name, depends_on } of questions) {
			if (
				!isEmpty(depends_on) &&
				depends_on?.name === key &&
				depends_on?.value !== value
			)
				newAnswers[name] = '';
		}

		setAnswer({ ...newAnswers });
	};

	const isRequiredAOEQuestion = (required, dependsOn) => {
		if (required === 'yes') {
			return true;
		} else if (
			required === 'conditional' &&
			!isEmpty(dependsOn) &&
			get(answers, [dependsOn.name], null) === dependsOn.value
		) {
			return true;
		} else if (required === 'no') {
			return false;
		}
		return false;
	};

	const renderQuestionField = ({
		default_option = null,
		display_text,
		name,
		type,
		is_required,
		options,
		depends_on,
		min,
		max,
	}) => {
		const isDisabled =
			!isEditable ||
			(!isEmpty(depends_on) &&
				get(answers, [depends_on.name], null) !== depends_on.value);

		if (type === 'enum' && !isEmpty(options)) {
			return (
				<>
					<span
						className={classnames({
							required:
								isEditable &&
								isRequiredAOEQuestion(is_required, depends_on),
						})}
					>
						{display_text}
					</span>
					<Select
						name={name}
						value={get(answers, [name], default_option)}
						disabled={isDisabled}
						onChange={(value) => handleChange(name, value)}
					>
						{options.map((option) => (
							<Select.Option
								key={option}
								label={option}
								value={option}
								className='aoe-dropdown-list'
							/>
						))}
					</Select>
				</>
			);
		} else if (DateTypes.includes(type)) {
			return (
				<>
					<span
						className={classnames({
							required:
								isEditable &&
								isRequiredAOEQuestion(is_required, depends_on),
						})}
					>
						{display_text}
					</span>

					<EncounterDateField
						placeholder='MM/DD/YYYY'
						value={get(answers, [name], default_option)}
						disabled={isDisabled}
						onChange={(value) => handleChange(name, value)}
						onBlur={(value) => handleChange(name, value)}
						disabledDate={(current) => {
							const today = moment();
							if (type === 'date_future') {
								return current.isAfter(today, 'day');
							} else if (type === 'date_past') {
								return current.isBefore(today, 'day');
							} else {
								return (
									current.isSameOrBefore(today, 'day') ||
									current.isSameOrAfter(today, 'day')
								);
							}
						}}
						makeCalendarOnly={(event) => {
							if (event.keyCode == 8 || event.keyCode == 46) {
								return true;
							} else {
								event.preventDefault();
							}
						}}
						className='testrequestdatefield'
					/>
					<span style={{ color: 'red' }}>
						{aoeAnswersErrors[name]}
					</span>
				</>
			);
		} else if (type === 'int') {
			return (
				<>
					<span
						className={classnames({
							required:
								isEditable &&
								isRequiredAOEQuestion(is_required, depends_on),
						})}
					>
						{display_text}
					</span>
					<Input
						name={name}
						value={get(answers, [name], default_option)}
						placeholder={display_text}
						trim={true}
						disabled={isDisabled}
						onChange={(value) =>
							handleChange(name, value, type, max, min)
						}
						min={min}
						max={max}
					/>
				</>
			);
		} else if (type === 'phone') {
			return (
				<>
					<span
						className={classnames({
							required:
								isEditable &&
								isRequiredAOEQuestion(is_required, depends_on),
						})}
					>
						{display_text}
					</span>
					<InputMask
						placeholder={display_text}
						value={get(answers, [name], default_option)}
						disabled={isDisabled}
						onChange={(value) =>
							handleChange(name, value?.target?.value || '', type)
						}
						autoComplete='off'
						mask={'+1 (999) 999-9999'}
						maskChar={null}
						className='aoe-questions-type-phone'
					/>
					<span style={{ color: 'red' }}>
						{aoeAnswersErrors[name]}
					</span>
				</>
			);
		} else if (['text', 'email'].includes(type)) {
			return (
				<>
					<span
						className={classnames({
							required:
								isEditable &&
								isRequiredAOEQuestion(is_required, depends_on),
						})}
					>
						{display_text}
					</span>
					<Input
						name={name}
						value={get(answers, [name], default_option)}
						placeholder={display_text}
						trim={true}
						disabled={isDisabled}
						onChange={(value) => handleChange(name, value, type)}
						maxLength={255}
					/>
					<span style={{ color: 'red' }}>
						{aoeAnswersErrors[name]}
					</span>
				</>
			);
		}

		return null;
	};

	return (
		<div className='crte-test-rqst-dialog-body'>
			<div className='crte-test-rqst-fields-container'>
				<section className='create-test-rqst-labinfo-container create-test-rqst-labinfo-container-v2 aoe-questions'>
					{isEmpty(questions) || !isArray(questions) ? (
						<div className='placeholder-text'>
							No questions to answer
						</div>
					) : (
						questions.map((question) => {
							return (
								<Layout.Row key={question.name}>
									<Layout.Col span='24'>
										{renderQuestionField(question)}
									</Layout.Col>
								</Layout.Row>
							);
						})
					)}
				</section>
				{isEditable ? (
					<CreateTestRequestEncntrInfo
						encounter={encounter}
						patientdetail={patientdetail}
					/>
				) : (
					<Tabs
						key={tabvalue}
						activeName='testRequestInfoTab'
						className='test-order-encntr-testrequest-tabs-wrapper'
					>
						<Tabs.Pane label='Encounter' name='encntrInfoTab'>
							<CreateTestRequestEncntrInfo
								encounter={encounter}
								encounterschema={encounterSchema}
								patientdetail={patientdetail}
							/>
						</Tabs.Pane>
						<Tabs.Pane
							label='Test Request'
							name='testRequestInfoTab'
						>
							<TestRequestInfoTab
								testorder={testorder}
								ordering_physicianenums={orderingPhysicianenums}
								selectedICDCodes={selectedICDCodes}
								sampletypeenums={sampletypeenums}
								patientbillingmethodenums={
									patientbillingmethodenums
								}
								formdata={formdata}
							/>
						</Tabs.Pane>
					</Tabs>
				)}
			</div>
		</div>
	);
};

export default AOEQuestions;
