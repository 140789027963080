import { useFormikContext, setNestedObjectValues } from 'formik';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from 'gm-element-react';
import { patientdetail } from '../../../../../actions/patients';
import Button from '../../../../Common/Button';
import DuplicatePatientTooltip from '../../../V2/CreatePatient/DuplicatePatientTooltip';
import { useDuplicatePatientCheck } from './useDuplicatePatientCheck';

import './Footer.scss';

/**
 * Footer component with buttons to send the form
 */
export const Footer = ({
	existingPatientUUID,
	enqueueSnackbar,
	history,
	isEdit,
}) => {
	const { submitForm, values, isSubmitting, validateForm, setTouched } =
		useFormikContext();

	const dispatch = useDispatch();

	const {
		checkForDuplicatePatient,
		duplicateCheckState,
		isDuplicatePatient,
	} = useDuplicatePatientCheck();

	const [buttonClicked, setButtonClicked] = useState('');
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const [
		alreadyCheckedDuplicatePatients,
		setAlreadyCheckedDuplicatePatients,
	] = useState(false);
	const [offset, setOffset] = useState(0);

	const validateFormData = async () => {
		const formErrors = await validateForm();
		const hasErrors = Object.keys(formErrors).length > 0;
		if (hasErrors) {
			setTouched(setNestedObjectValues(formErrors, true));
			return false;
		}
		return true;
	};

	const duplicatePatientCheck = async () => {
		const isValidForm = await validateFormData();
		if (isValidForm) {
			if (!isEdit) {
				await checkForDuplicatePatient(
					values.patientDemographicsAndContact,
					offset,
				);
			}
			setAlreadyCheckedDuplicatePatients(true);
		}
	};

	useEffect(() => {
		const { duplicatePatientCheckLoading, duplicatePatientCheckError } =
			duplicateCheckState;
		if (!duplicatePatientCheckLoading && alreadyCheckedDuplicatePatients) {
			if (duplicatePatientCheckError) {
				enqueueSnackbar('Error in checking for duplicate patients', {
					variant: 'error',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				setIsTooltipOpen(false);
			} else {
				setIsTooltipOpen(isDuplicatePatient);
				if (!isDuplicatePatient) {
					handleCreateAnyway();
				}
			}
		}
	}, [duplicateCheckState, enqueueSnackbar, isDuplicatePatient]);

	const changeOffsetAndCheck = useCallback(
		(newOffset) => {
			setOffset(newOffset);
			setIsTooltipOpen(true);
			checkForDuplicatePatient(
				values.patientDemographicsAndContact,
				newOffset,
			);
		},
		[checkForDuplicatePatient, values.patientDemographicsAndContact],
	);

	const handleButton = async (action) => {
		if (!(await validateFormData())) return;
		await duplicatePatientCheck();
		setButtonClicked(action);
		if (isEdit) {
			handleCreateAnyway();
		}
	};

	const handleCreateAnyway = async () => {
		if (!(await validateFormData())) return;
		const patientUUID = await submitForm();
		// The old scheduling page expects to be primed with an object containing
		// BOTH uuid and id. Since the create patient API just returns uuid, we need
		// to fetch the newly created patient to get its id to pass to the scheduling route..
		if (!isEdit) {
			const patientDetails = await dispatch(patientdetail(patientUUID));
			redirectToNextPage(patientDetails, values, buttonClicked);
		} else {
			navigateToPatientDetail(patientUUID, values);
		}
	};

	const redirectToNextPage = (patientDetails) => {
		if (buttonClicked === 'schedule') {
			history.push({
				pathname: '/app/appointments/schedule',
				state: {
					breadcrumbs: [
						{
							location: 'Appointments',
							url: '/app/appointments',
						},
						{
							location: 'Schedule Appointment',
							url: '/app/appointments/schedule',
							patientData: {
								firstName:
									values.patientDemographicsAndContact
										.patientFirstName || '',
								lastName:
									values.patientDemographicsAndContact
										.patientLastName || '',
								uuid: patientDetails?.uuid || 0,
								id: patientDetails?.id || 0,
							},
						},
					],
				},
			});
		} else if (buttonClicked === 'create') {
			navigateToPatientDetail(patientDetails.uuid);
		}
	};

	const navigateToPatientDetail = (patientUUID) => {
		const { patientFirstName = '', patientLastName = '' } =
			values.patientDemographicsAndContact;

		history.push({
			pathname: `/app/patientdetail/${patientUUID}/0/0/`,
			state: {
				breadcrumbs: [
					{
						location: 'Patient Detail',
						patientFullName: `${patientFirstName} ${patientLastName}`,
					},
				],
			},
		});
	};

	const navigateToLegacyEditPage = () => {
		const currentBreadcrumbs = history?.location?.state?.breadcrumbs || [];

		const newBreadcrumb = {
			location: 'Edit Patient Legacy',
			url: '/app/patientmanagement/edit/legacy',
			state: {
				edit: true,
				patientUuid: existingPatientUUID,
				encounter_list: [],
			},
		};

		const updatedBreadcrumbs = currentBreadcrumbs.map((crumb) => {
			if (crumb?.location === 'Edit Patient') {
				newBreadcrumb.state.encounter_list = crumb.state.encounter_list;
				return newBreadcrumb;
			}
			return crumb;
		});

		if (
			!currentBreadcrumbs.some(
				(crumb) => crumb?.location === 'Edit Patient',
			)
		) {
			updatedBreadcrumbs.push(newBreadcrumb);
		}

		history.push({
			pathname: '/app/patientmanagement/edit/legacy',
			state: { breadcrumbs: updatedBreadcrumbs },
		});
	};

	const defaultSchedulingOptionsLoading = useSelector(
		(state) =>
			state.referralProgramsList?.defaultSchedulingOptions?.loading,
	);
	const defaultSchedulingOptionsPayload = useSelector(
		(state) =>
			state.referralProgramsList?.defaultSchedulingOptions?.payload,
	);
	const referralPrograms = useSelector(
		(state) => state?.referralPrograms?.referralPrograms,
	);

	const isDefaultSchedulingOptionsLoading =
		defaultSchedulingOptionsLoading ||
		!defaultSchedulingOptionsPayload ||
		!Object.keys(defaultSchedulingOptionsPayload).length > 0 ||
		(isEdit && referralPrograms.length === 0);

	const loading = duplicateCheckState.loading || isSubmitting;

	return (
		<footer>
			{isEdit ? (
				loading || isDefaultSchedulingOptionsLoading ? (
					<>
						<Loading
							loading={true}
							style={{
								position: 'fixed',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
							}}
						/>
					</>
				) : (
					<div className='edit-patient-container'>
						<a
							onClick={() => navigateToLegacyEditPage()}
							className='legacy-link'
						>
							Go to legacy Edit Patient experience
						</a>
						<Button onClick={() => handleButton('edit')}>
							Save
						</Button>
					</div>
				)
			) : (
				<>
					{isTooltipOpen && (
						<DuplicatePatientTooltip
							offset={offset}
							changeOffset={(e, newOffset) =>
								changeOffsetAndCheck(newOffset)
							}
							duplicatePatientData={
								duplicateCheckState.duplicatePatientCheck
							}
							closeDuplicatePatientTooltip={() =>
								setIsTooltipOpen(false)
							}
							duplicatePatientCheckLoading={
								duplicateCheckState.duplicatePatientCheckLoading
							}
							formPatientFirstName={
								values.patientDemographicsAndContact
									.patientFirstName
							}
							formPatientLastName={
								values.patientDemographicsAndContact
									.patientLastName
							}
							formPatientDob={
								values.patientDemographicsAndContact.dateOfBirth
							}
							formPatientEmail={
								values.patientDemographicsAndContact
									.patientEmail
							}
							formPatientNoEmail={
								values.patientDemographicsAndContact.noEmail
							}
						/>
					)}
					{loading ? (
						<Loading
							loading={true}
							style={{
								position: 'fixed',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
							}}
						/>
					) : (
						<>
							{alreadyCheckedDuplicatePatients ? (
								<Button onClick={handleCreateAnyway}>
									Create anyway
								</Button>
							) : (
								<>
									<Button
										onClick={() => handleButton('schedule')}
										inverted
									>
										Create and Schedule
									</Button>
									<Button
										onClick={() => handleButton('create')}
									>
										Create Patient
									</Button>
								</>
							)}
						</>
					)}
				</>
			)}
		</footer>
	);
};
