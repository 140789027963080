//React & Redux
import React from 'react';

//Utils
import { determineEmailStatus } from '../../../Utils/appointmentUtils.js';

//Lodash
import _ from 'lodash';

//Components
import SummaryPatientInfo from './../SummaryPatientInfo.js';
import CurrentAppointmentView from './CurrentAppointmentView';
import SummaryWarning from '../SummaryWarning';
import Icon from '../../Common/Icon';

//Styles
import './../css/patient-summary.css';

class RescheduleAppointmentSidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		return (
			<div className='scheduleAppointmentSideContainer'>
				<div className='scheduleAppointmentSideContainerTitle'>
					<span
						style={{
							height: '28px',
							width: '28px',
							marginLeft: '-4px',
							verticalAlign: 'text-bottom',
						}}
					>
						<Icon icon='calendar' />
					</span>
					<span>Current appointment</span>
				</div>

				{!_.isEmpty(_.get(this, ['props', 'patientDetail'], {})) ? (
					<SummaryPatientInfo
						patient={this.props.patientDetail}
						determineEmailStatus={determineEmailStatus}
						referralPrograms={this.props.referralPrograms}
					/>
				) : null}

				{!_.isEmpty(_.get(this, ['props', 'encounterdetail'], {})) ? (
					<CurrentAppointmentView
						encounterdetail={this.props.encounterdetail}
						billingencounterdetail={
							this.props.billingencounterdetail
						}
						specialties={this.props.specialties}
						payment={_.get(
							this,
							['props', 'billingencounterdetail', 'patient_pay'],
							null
						)}
					/>
				) : null}
				{this.props.isWarningSummary && (
					<SummaryWarning iswarning={this.props.isWarningSummary} />
				)}
			</div>
		);
	}
}

export default RescheduleAppointmentSidebar;
