import * as actionTypes from '../actions/actionTypes';

const defaultState = {
	visitCompletionStatusUnknownCard: {},
	orderRequestStatusUnknownCard: {},
	ordersPendingLabSubmissionCard: {},
	returnOfResultsVisitPendingCard: {},
	CAPNotSentVisitCard: {},
	resultsCAPNotSentCard: {},
	insuranceClaimNotFiledCard: {},
	patientOutreachPendingCard: {},
	RoROutreachPendingCard: {},
	appointments: {},
	billing: {},
	testManagement: {},
	templates: {},
	externalProviders: {},
	patients: {},
	testAuthorization: {},
};

export const appData = (state = defaultState, action) => {
	if (action.type === actionTypes.SET_APPDATA_FILTER) {
		const newState = Object.assign({}, state);
		newState[action.component] = action.payload;
		return newState;
	} else {
		return state;
	}
};

export default appData;
