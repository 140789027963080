import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import './AppointmentSummary.scss';
import {
	imageDefault,
	encounter_types_images,
} from '../../../../../Utils/encounterUtils';
import { providers_images_for_appointment } from '../../../../../Utils/appointmentUtils';
import EncounterService from '../../../../../service/Encounter';

/**
 * @param {object} props
 * @param {Moment} props.startTime
 * @param {string} props.consultationType
 * @param {string} props.encounterType
 * @param {string} props.specialty
 * @param {string} [props.provider]
 */
function AppointmentSummaryInner(props) {
	const date = moment(props.startTime);

	return (
		<div className='appointment-summary'>
			<div>{date.format('MMMM D, YYYY')}</div>
			<div>{date.format('h:mm A zz')}</div>
			<div className='with-image'>
				<img src={encounter_types_images[props.encounterType]} />
				{props.consultationType}
			</div>
			<div>{props.specialty}</div>
			{props.provider && (
				<div className='with-image'>
					<img
						src={
							providers_images_for_appointment[props.provider] ??
							imageDefault
						}
					/>
					{props.provider}
				</div>
			)}
		</div>
	);
}

/**
 * @param {object} props
 * @param {string} props.encounterUUID
 * @param {boolean} props.showProvider
 *
 */
export function AppointmentSummary(props) {
	const { defaultSchedulingOptions, providers } = useSelector((store) => ({
		defaultSchedulingOptions:
			store.referralProgramsList?.defaultSchedulingOptions?.payload,
		providers: store.Scheduling?.providers,
	}));

	const [encounter, setEncounter] = useState();

	useEffect(() => {
		async function doFetch() {
			if (props.encounterUUID) {
				const resp = await EncounterService.encounterdetail(
					props.encounterUUID
				);
				setEncounter(resp.data.data[0]);
			}
		}
		void doFetch();
	}, [props.encounterUUID]);

	if (!encounter) {
		return null;
	}

	return (
		<AppointmentSummaryInner
			startTime={moment
				.utc(encounter.date_of_service)
				.tz(encounter.timezone)}
			encounterType={encounter.type}
			consultationType={encounter.consultation_type}
			specialty={
				defaultSchedulingOptions?.specialties?.find(
					(it) => it.name.toLowerCase() === encounter.vsee_specialty
				)?.display_name ?? encounter.vsee_specialty
			}
			provider={
				props.showProvider
					? providers?.find(
							(it) => it.uuid === encounter.visit_provider_uuid
					  )?.full_name
					: null
			}
		/>
	);
}
