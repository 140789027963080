//React & Redux
import React, { Component } from 'react';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import {
	getProviderName,
	getProviderIcon,
} from '../Encounters/Common/utilsfunctions';
import {
	CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER,
} from '../../../../Utils/permissionUtils';

//UI Libraries
import { Button } from 'gm-element-react';

//Components
import RBAC from '../../../RBAC';
import EditTestOrderModalContainer from './EditTestOrderModalContainer';
import TextWithTooltip from '../../../Common/TextWithTooltip';
import TestOrderProgressBar from './TestOrderProgressBar';

//Styles
import './style.css';

class TestOrderRow extends Component {
	constructor(props) {
		super(props);
		this.outreachTimeTimer = null;
		this.state = {
			showModal: false,
			defaultTabName: 'testInfoTab',
		};
		this.hideModal = this.hideModal.bind(this);
	}

	hideModal = () => {
		this.setState({ showModal: false, defaultTabName: 'testInfoTab' });
	};	

	displayProgressbar = () => {
		const props = this.props;
		const progressbarPathObj = _.get(props, ['progressbarPathObj'], '');
		const mloOrderStatusEnums = _.get(props, ['mloOrderStatusEnums'], []);
		const outreachTimeToStart = _.get(props, ['outreachTimeToStart'], null);

		if (_.get(progressbarPathObj, ['type'], '') === 'group') {			
			return _.get(progressbarPathObj, ['orders'], '').map((order) => {				
				return (
					<>
						<TestOrderProgressBar
							key={_.get(order, ['id'], '')}
							order={order}
							mloOrderStatusEnums={mloOrderStatusEnums}
							outreachTimeToStart={outreachTimeToStart}
							testorder={props.testorder}
							encounter={props.encounter}
							outreachStatusEnums={props.outreachStatusEnums}
							callTestOrderRequest={props.callTestOrderRequest}
							showModalFunc={this.showModalFunc}
							gotoMLOTestDetail={props.gotoMLOTestDetail}
						/>			
						<div className='test-order-divider'></div>
					</>
				)					
			});
		} else {
			return (
				<TestOrderProgressBar															
					key={_.get(progressbarPathObj, ['id'], '')}
					order={progressbarPathObj}
					mloOrderStatusEnums={mloOrderStatusEnums}
					outreachTimeToStart={outreachTimeToStart}
					testorder={props.testorder}
					encounter={props.encounter}
					outreachStatusEnums={props.outreachStatusEnums}
					callTestOrderRequest={props.callTestOrderRequest}
					showModalFunc={this.showModalFunc}
					gotoMLOTestDetail={props.gotoMLOTestDetail}
				/>
			);			
		}
	};

	getGroupTestorders = (groupTestorders) => {
		const _groupTestorders = [];
		_.map(groupTestorders, (test_order) => {
			let localorderstatus = _.get(test_order, 'order_status', null);
			if (
				!_.isNil(_.get(test_order, ['status_reason'], null)) &&
				!_.isEmpty(_.get(test_order, ['status_reason'], null))
			) {
				localorderstatus = _.get(test_order, ['status_reason'], null);
			}
			_groupTestorders.push({ ...test_order, localorderstatus });
		});
		return _groupTestorders;
	};

	showModalFunc = (showEditTestOrderPopup = false) => {
		this.setState({ showModal: showEditTestOrderPopup })
	}

	render() {
		const props = this.props;
		const progressbarPathObj = _.get(props, ['progressbarPathObj'], '');
		const geneticTestUuid2 = _.get(progressbarPathObj, ['type'], '') === 'group' ? _.get(progressbarPathObj, ['orders', '1', 'genetic_test_uuid'], '') : '';
		const geneticTestUuid3 = _.get(progressbarPathObj, ['type'], '') === 'group' ? _.get(progressbarPathObj, ['orders', '2', 'genetic_test_uuid'], '') : '';
		const testOrder2Uuid = _.get(progressbarPathObj, ['type'], '') === 'group' ? _.get(progressbarPathObj, ['orders', '1', 'uuid'], '') : '';
		const testOrder3Uuid = _.get(progressbarPathObj, ['type'], '') === 'group' ? _.get(progressbarPathObj, ['orders', '2', 'uuid'], '') : '';
		const genetic_test2 =  { 'orderUuid': testOrder2Uuid, 'testNameUuid': geneticTestUuid2 }
		const genetic_test3 =  { 'orderUuid': testOrder3Uuid, 'testNameUuid': geneticTestUuid3 }

		return (
			<div className='testorder-row'>
				<div className='testorder-testname-bar-contain'>
					<div className='testorder-testname-bar'>
						<RBAC
							action={CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER}
							yes={
								<Button
									className='testorder-label'
									onClick={() =>
										this.setState({ showModal: true })
									}
									type='text'
								>
									<TextWithTooltip>
										Test Order #
										{_.get(this.props, 'index', 1)}
									</TextWithTooltip>
								</Button>
							}
							no={
								<Button
									className='testorder-label disable-btn-extrnl-user'
									type='text'
								>
									<TextWithTooltip>
										Test Order #
										{_.get(this.props, 'index', 1)}
									</TextWithTooltip>
								</Button>
							}
						/>

						<RBAC
							action={CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER}
							yes={
								<Button
									className='link-icd-10 button-text'
									onClick={() =>
										this.setState({
											showModal: true,
											defaultTabName: 'ICD10CodesTab',
										})
									}
									type='text'
								>
									ICD-10 Codes (
									{_.get(
										this,
										[
											'props',
											'testorder',
											'medical_codes',
											'length',
										],
										0
									)}
									)
								</Button>
							}
							no={
								<Button
									className='link-icd-10 button-text disable-btn-extrnl-user'
									type='text'
								>
									ICD-10 Codes (
									{_.get(
										this,
										[
											'props',
											'testorder',
											'medical_codes',
											'length',
										],
										0
									)}
									)
								</Button>
							}
						/>

						<span className='divider-dot'>.</span>

						<RBAC
							action={CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER}
							yes={
								<Button
									className='button-text'
									onClick={() =>
										this.setState({
											showModal: true,
											defaultTabName: 'notesTab',
										})
									}
									type='text'
								>
									Notes (
									{_.get(
										this,
										['props', 'orderNoteCount'],
										0
									)}
									)
								</Button>
							}
							no={
								<Button
									className='button-text disable-btn-extrnl-user'
									type='text'
								>
									Notes (
									{_.get(
										this,
										['props', 'orderNoteCount'],
										0
									)}
									)
								</Button>
							}
						/>

						{/* <span className="divider-dot">.</span> */}

						{/* <RBAC
            action={CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER}
            yes={<Button
              className="button-text"
              onClick={() => this.setState({ showModal: true, defaultTabName: 'AuditTrail' })}
              type="text">
                Audit Trail
              </Button>
            }
            no={<Button
              className="button-text disable-btn-extrnl-user"
              type="text">
                Audit Trail
              </Button>
            }
          /> */}
					</div>
					<div className='testorder-tracking-bar'>
						<div className='testorder-left-side'>
							<div className='lab-name'>
								{' '}
								{_.get(props, ['testorder', 'lab'], '--')}{' '}
							</div>
						</div>

						<div className='testorder-right-side'>
							<div className='ordering-sec'>
								<span className='lbl-ordering-physician'>
									Ordering physician
								</span>
								<span className='val-ordering-physician'>
									{getProviderIcon(props, [
										'testorder',
										'ordering_physician',
									]) != null && (
										<img
											src={getProviderIcon(props, [
												'testorder',
												'ordering_physician',
											])}
											alt=''
										/>
									)}
									{getProviderName(
										props,
										['testorder', 'ordering_physician'],
										_.get(
											props,
											['ordering_physicianenums'],
											[]
										)
									)}
								</span>
							</div>
						</div>
					</div>
					<div className='testorder-tracking-bar'>
						<div className='progressbar-wrapper'>
							{this.displayProgressbar()}
						</div>
					</div>
				</div>
				{get(this, 'state.showModal', false) && (
					<div className='popupModal'>
						<EditTestOrderModalContainer
							hideModal={this.hideModal}
							getICDCodes={this.props.getICDCodes}
							icdCodes={_.get(this, ['props', 'icdCodes'], [])}
							icdCodesLoading={_.get(
								this,
								['props', 'icdCodesLoading'],
								false
							)}
							icdCodesError={_.get(
								this,
								['props', 'icdCodesError'],
								null
							)}
							icdCodesPlacement={_.get(
								this,
								['props', 'icdCodesPlacement'],
								null
							)}
							enqueueSnackbar={this.props.enqueueSnackbar}
							selectedICDCodes={_.get(
								this,
								'props.testorder.medical_codes',
								null
							)}
							labenums={_.get(
								props,
								['encounterschema', 'lab'],
								[]
							)}
							LoadTestOrderPopupTestEnum={_.get(
								props,
								['LoadTestOrderPopupTestEnum'],
								[]
							)}
							nonlegacytestOrderPopupTestEnums={_.get(
								props,
								['nonlegacytestOrderPopupTestEnums'],
								[]
							)}
							nonlegacytestOrderPopupTestEnumsLoading={_.get(
								props,
								['nonlegacytestOrderPopupTestEnumsLoading'],
								false
							)}
							encounterschema={_.get(
								this,
								['props', 'encounterschema'],
								{}
							)}
							testorder={_.get(this, ['props', 'testorder'], {})}
							groupTestorders={this.getGroupTestorders(this?.props?.progressbarPathObj?.orders || [])}
							ordering_physicianenums={_.get(
								this,
								['props', 'ordering_physicianenums'],
								[]
							)}
							testnameenums={_.get(
								this,
								['props', 'testnameenums'],
								[]
							)}
							orderrequeststatuscascadeenums={_.get(
								this,
								['props', 'orderrequeststatuscascadeenums'],
								[]
							)}
							encounter={_.get(this, ['props', 'encounter'], {})}
							patientdetail={_.get(
								this,
								['props', 'patientdetail'],
								{}
							)}
							orderstatuscascadeenums={_.get(
								this,
								['props', 'orderstatuscascadeenums'],
								[]
							)}
							sampletypeenums={_.get(
								props,
								['sampletypeenums'],
								[]
							)}
							laborderstatusenums={_.get(
								props,
								['laborderstatusenums'],
								[]
							)}
							patientbillingmethodenums={_.get(
								props,
								['patientbillingmethodenums'],
								[]
							)}
							mloOrderStatusEnums={_.get(
								props,
								['mloOrderStatusEnums'],
								[]
							)}
							updateTestOrder={this.props.updateTestOrder}
							testOrderNumber={_.get(this.props, 'index', 1)}							
							encounterICDcodes={_.get(
								this.props,
								'encounter.medical_codes',
								[]
							)}
							user={_.get(props, ['user'], {})}
							callTestOrderNotes={this.props.callTestOrderNotes}
							testOrderNotes={_.get(
								this,
								['props', 'testOrderNotes'],
								[]
							)}
							defaultTabName={this.state.defaultTabName}
							testOutreachStatus={_.get(
								this,
								['props', 'testOutreachStatus'],
								''
							)}
							outreachStatusEnums={_.get(
								this,
								['props', 'outreachStatusEnums'],
								[]
							)}
							holdResultRelease={_.get(
								this,
								['props', 'encounter', 'hold_result_release'],
								null
							)}
							outreachTimeToStart={_.get(
								this,
								['props', 'outreachTimeToStart'],
								null
							)}
							testOrderList={_.get(
								this,
								['props', 'testOrderList'],
								[]
							)}
							outreachSeqNo={_.get(
								this,
								['props', 'outreachSeqNo'],
								0
							)}
							auditTrailEncounterLoading={_.get(
								this,
								['props', 'auditTrailEncounterLoading'],
								false
							)}
							auditTrailEncounter={_.get(
								this,
								['props', 'auditTrailEncounter'],
								[]
							)}
							auditTrailEncounterError={_.get(
								this,
								['props', 'auditTrailEncounterLoading'],
								null
							)}
							getEncounterAuditTrail={
								this.props.getEncounterAuditTrail
							}
							genetic_test2={genetic_test2}
							genetic_test3={genetic_test3}
						/>
					</div>
				)}
			</div>
		);
	}
}
export default TestOrderRow;
