//React & Redux
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

//Lodash
import _ from 'lodash';

//Utils
import { required, validateCardNumber } from '../../Utils/validate';

//UI Libraries
import { Input, Select, Layout } from 'gm-element-react';

// Components
import ErrorBoundary from '../Common/ErrorBoundary';
import AppointmentSection from './AppointmentSection';

//Other Libraries
import { card } from 'creditcards';

//Styles
import './css/select-patient.css';
import './css/payment-stage.css';

const ValidateCardName = required('Card name');
const ValidateCreditCard = required('Credit card');
const ValidateExpiryDate = required('Expiration date');
const ValidateCSV = required('CVC');

const cardNameController = (str) => {
	if (!str) return str;
	return /^[a-zA-Z]+\s?[a-zA-Z]*$/.test(str)
		? str
		: str.match(/^[a-zA-Z]+\s?[a-zA-Z]+/);
};

const formatCardNumber = (value) => card.format(card.parse(value));

const cvcController = (cvc, previousValue) => {
	if (!cvc) return cvc;
	return /^\d{0,4}$/.test(cvc) ? cvc : previousValue;
};

const ExpiryDateList = () => {
	const expriydate = [];
	const startYear = new Date().getFullYear();
	const currentmonth = new Date().getMonth();
	const years = _.range(startYear + 1, startYear + 10).map((y) => y);
	const months = _.range(1, 13).map((i) => ({
		display: i < 10 ? `0${i}` : `${i}`,
		value: i,
	}));
	const currentyearmonth = _.range(currentmonth + 1, 13).map((i) => ({
		display: i < 10 ? `0${i}` : `${i}`,
		value: i,
	}));
	currentyearmonth.map((month) => {
		expriydate.push({
			display: month.display + '/' + startYear.toString().substr(2, 4),
			data: { exp_month: month.value, exp_year: startYear },
		});
	});
	years.map((year) => {
		months.map((month) => {
			expriydate.push({
				display: month.display + '/' + year.toString().substr(2, 4),
				data: { exp_month: month.value, exp_year: year },
			});
		});
	});
	return expriydate;
};

const textField = ' erFieldText erFieldTextWider';

const CardHolderName = ({ input, meta, placeholder, trim, disabled }) => {
	return (
		<div className='insurancebox' style={{ width: '100%' }}>
			<div className='appointmentRequiredField'>*</div>
			<label className='selfpay-label'>Name On Card</label>

			<div>
				<Input
					{...input}
					placeholder={placeholder}
					trim={trim}
					disabled={disabled}
					className={textField}
				/>
				{meta && meta.touched && meta.error && (
					<span className='error'>{meta.error}</span>
				)}
			</div>
		</div>
	);
};

const CreditCard = ({ input, meta, placeholder, trim, disabled }) => {
	return (
		<div className='insurancebox'>
			<div className='appointmentRequiredField'>*</div>
			<label className='selfpay-label'>Credit Card Number</label>

			<div>
				<Input
					{...input}
					placeholder={placeholder}
					trim={trim}
					disabled={disabled}
					className={'card-name' + textField}
				/>
				{meta && meta.touched && meta.error && (
					<span className='error'>{meta.error}</span>
				)}
			</div>
		</div>
	);
};

const CSV = ({ input, meta, placeholder, trim, disabled, type }) => {
	return (
		<div className='insurancebox' style={{ width: '100%' }}>
			<div className='appointmentRequiredField'>*</div>
			<label className='selfpay-label'>CVC</label>

			<div>
				<Input
					{...input}
					type={type}
					placeholder={placeholder}
					trim={trim}
					disabled={disabled}
					className={textField}
				/>
				{meta && meta.touched && meta.error && (
					<span className='error'>{meta.error}</span>
				)}
			</div>
		</div>
	);
};

const ExpiryDate = ({ name, input, meta, placeholder, onVisibleChange }) => (
	<div className='insurancebox' style={{ width: '100% ' }}>
		<div className='appointmentRequiredField'>*</div>
		<label className='selfpay-label'>Expiry Date</label>
		<div>
			<ErrorBoundary>
				<Select
					{...input}
					name={name}
					placeholder={placeholder}
					className='insurance-state-select dropdownLeftEdgeAligned inputFieldDropdown'
					filterable={true}
					name={'abc'}
					autocomplete={'off'}
					onVisibleChange={onVisibleChange}
				>
					{ExpiryDateList().map((el, i) => {
						return (
							<Select.Option
								key={i}
								label={el.display}
								value={el.data}
							>
								{el.display}
							</Select.Option>
						);
					})}
				</Select>
			</ErrorBoundary>
			{meta && meta.touched && meta.error && (
				<span className='error'>{meta.error}</span>
			)}
		</div>
	</div>
);

class CreditCardSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initialState: {},
			ishomesame: false,
		};
		//bindings
		this.touchfield = this.touchfield.bind(this);
	}

	componentDidMount() {
		const { initialValues } = this.props;
		if (initialValues) {
			this.setState({
				initialState: initialValues,
			});
		}
		if (this.isbillingAddressAvailable() !== true) {
			this.handleAsHomeAddress(true);
		} else {
			this.handleAsHomeAddress(false);
		}
	}

	touchfield(value, fieldname) {
		if (!value) {
			if (_.isFunction(_.get(this, ['props', 'touch']))) {
				this.props.touch(fieldname);
			}
		}
	}

	handleChange = (value, key) => {
		this.setState({
			initialState: Object.assign({}, this.state.initialState, {
				[key]: value,
			}),
		});
	};

	handleAsHomeAddress(value) {
		if (value) {
			this.props.initialize({
				...this.state.initialState,
				...this.props.homeaddress,
			});
		}
		this.setState({ ishomesame: value });
	}

	isbillingAddressAvailable() {
		const isNotEmpty = (value) =>
			!_.isNil(value) && !_.isUndefined(value) && value !== '';
		const { address_line1, address_line2, city, state, zip } = {
			...this.props.initialValues,
		};
		return (
			isNotEmpty(address_line1) ||
			isNotEmpty(address_line2) ||
			isNotEmpty(city) ||
			isNotEmpty(state) ||
			isNotEmpty(zip)
		);
	}

	render() {
		return (
			<form>
				<div className='appointmentschedule-insurancedetail'>
					<AppointmentSection
						heading='Credit Card Information'
						description=''
						isRequired={true}
						horizontalLine={true}
						marginAuto={true}
					>
						<section className='patient-info-container'>
							<Layout.Row
								gutter='16'
								style={{ marginBottom: '19px' }}
							>
								<Layout.Col span='24'>
									<Field
										name='name'
										component={CardHolderName}
										type='text'
										placeholder='Name On Card'
										isrequired={true}
										normalize={cardNameController}
										validate={[ValidateCardName]}
										onChange={(e, value) =>
											this.handleChange(value, 'name')
										}
									/>
								</Layout.Col>
							</Layout.Row>
							<Layout.Row
								gutter='16'
								style={{ marginBottom: '19px' }}
							>
								<Layout.Col span='12'>
									<Field
										name='number'
										component={CreditCard}
										type='text'
										placeholder={
											_.get(
												this,
												['props', 'cardPlaceHolder'],
												''
											) || 'Credit Card Number'
										}
										isrequired={true}
										normalize={formatCardNumber}
										sensitive={true}
										validate={[
											ValidateCreditCard,
											validateCardNumber,
										]}
										onChange={(e, value) =>
											this.handleChange(value, 'number')
										}
									/>
								</Layout.Col>
								<Layout.Col span='6'>
									<Field
										name='expirydate'
										component={ExpiryDate}
										placeholder='MM/YY'
										validate={[ValidateExpiryDate]}
										onChange={(e, value) =>
											this.handleChange(
												value,
												'expirydate'
											)
										}
										onVisibleChange={(value) =>
											this.touchfield(value, 'expirydate')
										}
									/>
								</Layout.Col>
								<Layout.Col span='6'>
									<Field
										name='cvc'
										component={CSV}
										type='password'
										placeholder='CVC'
										isrequired={true}
										normalize={cvcController}
										sensitive={true}
										validate={[ValidateCSV]}
										onChange={(e, value) =>
											this.handleChange(value, 'cvc')
										}
									/>
								</Layout.Col>
							</Layout.Row>
						</section>
					</AppointmentSection>
					<AppointmentSection
						heading='Billing Address'
						description=''
						isRequired={true}
						horizontalLine={false}
					>
						<section className='home-address-container'>
							{this.props.renderAddressSection()}
						</section>
					</AppointmentSection>
				</div>
			</form>
		);
	}
}
CreditCardSection.propTypes = {
	initialValues: PropTypes.object,
};

// Decorate the form component
// eslint-disable-next-line no-class-assign
CreditCardSection = reduxForm({
	form: 'scheduleappointmentcreditcardform', // a unique name for this form
})(CreditCardSection);
export default CreditCardSection;
