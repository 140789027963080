import * as actionTypes from '../actions/actionTypes';

const defaultPatientTotals = {
	PatientTotals: {},
	PatientTotalsError: null,
	PatientTotalsLoading: false,
	PatientTotalsLoaded: false,
};

const defaultEncounterTotals = {
	EncounterTotals: {},
	EncounterTotalsError: null,
	EncounterTotalsLoading: false,
	EncounterTotalsLoaded: false,
};

export const defaultState = {
	...defaultPatientTotals,
	...defaultEncounterTotals,
};

export default (state = defaultState, action = {}) => {
	switch (action.type) {
		case actionTypes.LOAD_METRICS_TOTAL_PATIENT_COUNT_START:
			return {
				...state,
				...defaultPatientTotals,
				PatientTotalsLoading: true,
			};

		case actionTypes.LOAD_METRICS_TOTAL_PATIENT_COUNT_SUCCESS:
			return {
				...state,
				PatientTotals: action.payload,
				PatientTotalsError: null,
				PatientTotalsLoading: false,
				PatientTotalsLoaded: true,
			};

		case actionTypes.LOAD_METRICS_TOTAL_PATIENT_COUNT_FAILURE:
			return {
				...state,
				PatientTotalsLoading: false,
				PatientTotalsLoaded: true,
				PatientTotalsError: action.payload,
			};

		case actionTypes.UNLOAD_METRICS_TOTAL_PATIENT_COUNT:
			return {
				...state,
				...defaultPatientTotals,
			};

		case actionTypes.LOAD_METRICS_TOTAL_ENCOUNTER_COUNT_START:
			return {
				...state,
				...defaultEncounterTotals,
				EncounterTotalsLoading: true,
			};
		case actionTypes.LOAD_METRICS_TOTAL_ENCOUNTER_COUNT_SUCCESS:
			return {
				...state,
				EncounterTotals: action.payload,
				EncounterTotalsError: null,
				EncounterTotalsLoading: false,
				EncounterTotalsLoaded: true,
			};
		case actionTypes.LOAD_METRICS_TOTAL_ENCOUNTER_COUNT_FAILURE:
			return {
				...state,
				EncounterTotalsLoading: false,
				EncounterTotalsLoaded: true,
				EncounterTotalsError: action.payload,
			};
		case actionTypes.UNLOAD_METRICS_TOTAL_ENCOUNTER_COUNT:
			return {
				...state,
				...defaultEncounterTotals,
			};

		default:
			return state;
	}
};
