import _ from 'lodash';
import moment from 'moment';

export const hydrateTestOrders = (data) => {
	return {
		pagination: {
			end: data.end,
			has_next: data.has_next,
			has_prev: data.has_prev,
			limit: data.limit,
			offset: data.offset,
			start: data.start,
			total: data.total,
		},
		data: data && data.data.map((d) => hydrateTestOrder(d)),
	};
};

const UTCToLocalDate = (timestamp) => {
	if (!moment(timestamp).isValid()) return timestamp;
	if (
		moment(timestamp).hours() === 0 &&
		moment(timestamp).minutes() === 0 &&
		moment(timestamp).seconds() === 0
	)
		return timestamp;

	return moment.utc(timestamp).local().format('YYYY-MM-DD');
};

export const hydrateTestOrder = (data, geneEnumList) => {
	const testResult =
		data &&
		data.test_results &&
		_.isArray(data.test_results) &&
		_.orderBy(
			data.test_results,
			function (o) {
				return new moment(o.created_at);
			},
			['desc']
		);

	return {
		testOrderUUID: data.uuid,
		testName: data.genetic_test_display_name,
		labName: data.lab,
		providerName:
			data && data.encounter ? data.encounter.visit_provider : '',
		orderStatus:
			data && data.encounter && data.encounter.order_status
				? data.encounter.order_status
				: '',
		testResult: testResult.map((a) =>
			hydrateGeneTestResult(a, geneEnumList)
		),
		encounterName:
			data && data.encounter && data.encounter.type
				? data.encounter.type
				: '',
		orderingPhysicianName:
			data && data.encounter && data.encounter.ordering_physician
				? data.encounter.ordering_physician
				: '',
		dispatchDate:
			data &&
			data.encounter &&
			data.encounter.date_test_ordered &&
			moment(data.encounter.date_test_ordered).isValid()
				? moment(data.encounter.date_test_ordered)
				: '',
		testOrderDispatchDate:
			data &&
			data.date_test_ordered &&
			UTCToLocalDate(data.date_test_ordered),
		authDate:
			data &&
			data.encounter &&
			data.encounter.date_test_authorized &&
			moment(data.encounter.date_test_authorized).isValid()
				? moment(data.encounter.date_test_authorized)
				: '',
		testOrderAuthDate:
			data &&
			data.date_test_authorized &&
			UTCToLocalDate(data.date_test_authorized),
		reportReceiptDate:
			data &&
			data.encounter &&
			data.encounter.date_received_report &&
			moment(data.encounter.date_received_report).isValid()
				? moment(data.encounter.date_received_report)
				: '',
		testOrderReportReceiptDate:
			data &&
			data.date_received_report &&
			UTCToLocalDate(data.date_received_report),
		resultReleaseDate:
			data &&
			data.encounter &&
			data.encounter.date_results_released_to_patient &&
			moment(data.encounter.date_results_released_to_patient).isValid()
				? moment(data.encounter.date_results_released_to_patient)
				: '',
		testOrderResultReleaseDate:
			data &&
			data.date_results_released_to_patient &&
			UTCToLocalDate(data.date_results_released_to_patient),
		clinicalCommentsGeneral:
			data && data.encounter && data.encounter.clinician_comments
				? data.encounter.clinician_comments
				: '',
		encounterUUID:
			data && data.encounter && data.encounter.uuid
				? data.encounter.uuid
				: '',
		consulationType:
			data && data.encounter && data.encounter.consultation_type
				? data.encounter.consultation_type
				: '',
		serviceDate:
			data &&
			data.encounter &&
			data.encounter.date_of_service &&
			moment(data.encounter.date_of_service).isValid()
				? moment(data.encounter.date_of_service)
				: '',
		positiveResultsToReport:
			data &&
			data.encounter &&
			data.encounter.positive_results_to_report != null
				? data.encounter.positive_results_to_report
				: null,
		testUUID: data && data.genetic_test_uuid,
		labUUID: data && data.lab_uuid,
		geneticTestUUID: data && data.genetic_test_uuid,
		medicalCodes: data && data.medical_codes,
		bloodDrawStatus: data && data.blood_draw_request,
		sampleType: data && data.sample_type,
		statusReason: data && data.status_reason,
		bdSalivaKitSent: data && data.bd_saliva_kit_sent,
		bloodDrawOrderDate:
			data &&
			data.blood_draw_order_date &&
			UTCToLocalDate(data.blood_draw_order_date),
		expectedLabResultDate:
			data &&
			data.expected_lab_results_date &&
			UTCToLocalDate(data.expected_lab_results_date),
		history: data && data.history,
		encounter: data && data.encounter,
		testOrderStatus: data && data.order_status,
		testOrderOrderingPhysician: data && data.ordering_physician,
		testOrderResultReceivedDate:
			data &&
			data.date_received_report &&
			UTCToLocalDate(data.date_received_report),
		editTestOrderResultReleaseDate:
			data &&
			data.date_results_released_to_patient &&
			UTCToLocalDate(data.date_results_released_to_patient),
		patientBillingMethod: data && data.patient_billing_method,
		testpositiveResultsToReport:
			data && data.positive_results_to_report != null
				? data.positive_results_to_report
				: null,
		testclinicalCommentsGeneral:
			data && data.clinician_comments ? data.clinician_comments : '',
		labPaperFormOnly: data && data.lab_paper_form_only,
		outreachSeqNo: data && data.outreach_seq_no,
		testOutreachStatus: data && data.test_outreach_status,
		outreachTimeToStart: data && data.time_to_start,
		isActionable: data && data.is_actionable,
		labOrderStatusKeyName: data && data.lab_order_status_key_name,
		order_questionnaire: data?.order_questionnaire || {},
	};
};

export const deHydrateTestOrder = (data) => {
	return {
		test_results: data && data.testResults,
		positive_results_to_report: data && data.positiveResultsToReport,
		order_status: data && data.orderStatus,
	};
};

export const hydrateGeneTestResult = (data, testresults) => {
	const displayObject =
		testresults &&
		testresults.find((a) => a.id == data.genetic_test_genes_enums_id);
	return {
		cDnaPos: data && data.c_dna_pos,
		clinicianComments: data && data.clinician_comments,
		geneticTestGenesEnumsId: data && data.genetic_test_genes_enums_id,
		genoType: data && data.genotype,
		haploType: data && data.haplotype,
		clinvarID: data && data.clinvar_id,
		interpretation: data && data.interpretation,
		labComments: data && data.lab_comments,
		pPosition: data && data.p_position,
		riskScore: data && data.risk_score,
		riskScoreNormalRange: data && data.risk_score_normal_range,
		rsID: data && data.rs_id,
		zygosity: data && data.zygosity,
		geneUUID: data && data.uuid,
		allele1Repeats: data && data.allele_1_repeats,
		allele2Repeats: data && data.allele_2_repeats,
		nonGeneTestType: data && data.non_gene_test_type,
		hgvs: data && data.hgvs,
		copyNumber: data && data.copy_number,
		activityScore: data && data.activity_score,
		displayName: _.get(displayObject, 'gene', '--'),
	};
};

export const dehydrateGeneTestResult = (data) => {
	return {
		c_dna_pos: data && data.cDnaPos,
		clinician_comments: data && data.clinicianComments,
		genetic_test_genes_enums_id:
			data &&
			data.geneticTestGenesEnumsId &&
			parseInt(data.geneticTestGenesEnumsId),
		genotype: data && data.genoType,
		haplotype: data && data.haploType,
		clinvar_id: data && data.clinvarID,
		interpretation: data && data.interpretation,
		lab_comments: data && data.labComments,
		p_position: data && data.pPosition,
		risk_score: data && data.riskScore,
		risk_score_normal_range: data && data.riskScoreNormalRange,
		rs_id: data && data.rsID,
		zygosity: data && data.zygosity,
		uuid: data && data.geneUUID,
		is_deleted: data && data.isDeleted,
		allele_1_repeats: data && data.allele1Repeats,
		allele_2_repeats: data && data.allele2Repeats,
		non_gene_test_type: data && data.nonGeneTestType,
		hgvs: data && data.hgvs,
		copy_number: data && data.copyNumber,
		activity_score: data && data.activityScore,
	};
};
