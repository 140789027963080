import { join, isEmpty } from 'lodash';
import moment from 'moment';
import { UTCToCurrent } from './../utils';

const getName = (recipient) => {
	if (recipient.display_name) return recipient.display_name;
	else if (recipient.first_name)
		return (
			recipient.first_name +
			(recipient.last_name ? ` ${recipient.last_name}` : '')
		);
	else if (recipient.description) return recipient.description;
	else return null;
};

const getTitle = (log) => {
	if (log.type.name === 'retracted') return 'Retracted from Patient Portal';
	else if (log.type.name === 'shared_internally')
		return `Shared Internally with`;
	else if (log.type.name === 'shared_to_default_doc_recipient')
		return 'Notification Sent to Program Administrator';

	const title = [log.type.display_name];

	if (!isEmpty(log.medium)) {
		if (
			log.type.name === 'released' ||
			log.type.name === 'logged_release'
		) {
			switch (log.medium.name) {
				case 'patient_portal':
				case 'external_ehr':
					title.push('to');
					break;
				case 'email':
				case 'fax':
				case 'postal_mail':
				default:
					title.push('via');
					break;
			}
		}

		title.push(log.medium.display_name);
	}

	return join(title, ' ');
};

export const hydrateAuditTrail = (data) => {
	const hydratedLogs = data.map((log) => {
		return {
			original: log,
			email: log.email,
			fax: log.fax,
			can_be_retracted: log.can_be_retracted_pp,
			status: log.release_status,
			title: getTitle(log),
			to: getName(log.recipient),
			by: getName(log.user),
			date: UTCToCurrent(moment, moment.tz(log.created_at, 'UTC')).format(
				'MMM Do, YYYY hh:mma'
			),
			merge_to_with_title: log.type.name === 'shared_internally',
		};
	});

	return hydratedLogs;
};
