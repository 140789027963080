import * as actionTypes from '../actions/actionTypes';

export const accessrestricted = (
	state = { accessrestricted: false },
	action
) => {
	if (action.type === actionTypes.ACCESS_RESTRICTED_CHANGE) {
		const newState = Object.assign({}, state);
		newState.accessrestricted = action.payload;
		return newState;
	} else {
		return state;
	}
};
