//React & Redux
import React, { useEffect, useState } from 'react';

//Images
import ReactSVG from 'react-svg';
import xModalIcon from './../../../../assets/close.svg';
import downArrow from './../../../../assets/arrow-down-16X16.svg';
import upArrow from './../../../../assets/arrow-up-16X16.svg';

//Utils
import { documentDescriptionValidation } from '../../../../Utils/documentUtils.js';
//Lodash
import { isEmpty, isNil, get } from 'lodash';

//Other Libraries
import classnames from 'classnames';

//UI Libraries
import { Loading, Select, Cascader } from 'gm-element-react';

//Components

const CombineDocuments = (props) => {
	const {
		documentTypes,
		documentTypesLoading,
		encounterStringDisplay,
		encounters = [],
		getDocumentTypesForPatient,
		hideDocumentCombineDialog,
		selectedCombineDocuments,
		combiningDocument,
		combineDocument,
	} = props;

	const [description, setDescription] = useState('');
	const [documentType, setDocumentType] = useState(null);
	const [documentSubType, setDocumentSubType] = useState(null);
	const [relatedEncounter, setRelatedEncounter] = useState(null);
	const [orderDocumentList, setOrderDocumentList] = useState(
		selectedCombineDocuments
	);
	const [documentsUuids, setDocumentsUuids] = useState([]);

	useEffect(() => {
		getDocumentTypesForPatient();
		const orderDocsUuid = (selectedCombineDocuments || []).map((doc) => {
			return doc?.uuid;
		});
		setDocumentsUuids(orderDocsUuid);
	}, []);

	const handleCombineDocument = () => {
		if (documentType && isValidDescription()) {
			if (canSave()) {
				const data = {};
				if (!isEmpty(description) && !isNil(description)) {
					data['description'] = description;
				}
				if (!isEmpty(documentType) && !isNil(documentType)) {
					data['document_type'] = documentType;
				}
				if (!isEmpty(documentSubType) && !isNil(documentSubType)) {
					data['document_subtype'] = documentSubType;
				}
				if (!isEmpty(relatedEncounter) && !isNil(relatedEncounter)) {
					data['encounter_uuid'] = relatedEncounter;
				}
				if (!isEmpty(documentsUuids) && !isNil(documentsUuids)) {
					data['uuids'] = documentsUuids;
				}
				combineDocument(data);
			}
		}
	};

	const isValidDescription = () => {
		if (
			!isEmpty(description) &&
			description.trim().length !== 0 &&
			documentDescriptionValidation(description)
		) {
			return true;
		}
		return false;
	};

	const docTypes = documentTypes.filter(
		(type) => !['unassigned', 'risk_assessment'].includes(type.value)
	);
	const documentTypeValue = [documentType, documentSubType].filter(
		(el) => el
	);
	const selectValues = [
		{
			header: 'Document type',
			type: 'document',
			required: true,
			options: docTypes,
			placeholder: 'Select document type',
			value: documentTypeValue,
			element: 'cascader',
		},
		{
			header: 'Related encounter',
			type: 'encounter',
			required: false,
			options: encounters,
			placeholder: 'Select encounter',
			value: relatedEncounter,
			element: 'select',
		},
		{
			header: 'Description',
			type: 'description',
			required: true,
			options: null,
			placeholder: 'Enter a description',
			value: description,
			element: 'textarea',
		},
	];

	const setValue = (e, type) => {
		switch (type) {
			case 'encounter':
				setRelatedEncounter(e);
				break;
			case 'document':
				if (Array.isArray(e)) {
					const [documentType = null, documentSubType = null] = e;
					setDocumentType(documentType);
					setDocumentSubType(documentSubType);
				} else {
					setDocumentType(e);
					setDocumentSubType(null);
				}
				break;
			case 'description':
				setDescription(e?.target?.value);
				break;
		}
	};

	const canSave = () => {
		return documentType && isValidDescription();
	};

	const buttonClass = canSave()
		? 'combine-document-save-button'
		: 'combine-document-save-button combine-document-inactive-save-button';

	const onUpDocumentArrowClick = (idx) => {
		const _orderDocumentList = [...orderDocumentList];
		const temp = _orderDocumentList[idx - 1];
		_orderDocumentList[idx - 1] = _orderDocumentList[idx];
		_orderDocumentList[idx] = temp;
		setOrderDocumentList(_orderDocumentList);

		const orderDocsUuid = (_orderDocumentList || []).map((doc) => {
			return doc?.uuid;
		});
		setDocumentsUuids(orderDocsUuid);
	};

	const onDownDocumentArrowClick = (idx) => {
		const _orderDocumentList = [...orderDocumentList];
		const temp = _orderDocumentList[idx + 1];
		_orderDocumentList[idx + 1] = _orderDocumentList[idx];
		_orderDocumentList[idx] = temp;
		setOrderDocumentList(_orderDocumentList);

		const orderDocsUuid = (_orderDocumentList || []).map((doc) => {
			return doc?.uuid;
		});
		setDocumentsUuids(orderDocsUuid);
	};

	return (
		<div className='fullModalBackground'>
			<div className='combine-document-dialog'>
				{combiningDocument || documentTypesLoading ? (
					<Loading loading={true} style={{ position: 'unset' }} />
				) : (
					<>
						<div className='combine-document-dialog-header'>
							Combine Selected Documents
							<ReactSVG
								src={xModalIcon}
								className='singleSelectModalXIcon combine-document-dialog-header-XIcon'
								onClick={hideDocumentCombineDialog}
							/>
						</div>
						<div className='combine-document-dialog-body'>
							<h2>Document Information</h2>
							{selectValues.map((obj) => (
								<div
									className='createDocumentInputContainer'
									data-testId='create-document-fields'
									key={obj.header}
									style={{ top: 'unset' }}
								>
									{obj.required && (
										<div style={{ position: 'relative' }}>
											{' '}
											<div className='createDocumentRequired'>*</div>
										</div>
									)}
									<div className='createDocumentInputHeader'>
										{obj.header}
									</div>
									{obj.element === 'select' ? (
										<>
											<Select
												className={
													'createDocumentInputSelect'
												}
												placeholder={
													obj.placeholder || 'Select'
												}
												value={obj.value}
												onChange={(e) =>
													setValue(e, obj.type)
												}
											>
												{obj.options.map((el) => {
													if (
														obj.type === 'encounter'
													) {
														return (
															<Select.Option
																label={encounterStringDisplay(
																	el
																)}
																value={`${el.uuid}`}
																key={`${el.uuid}`}
															>
																{encounterStringDisplay(el)}
															</Select.Option>
														);
													} else {
														return (
															<Select.Option
																label={
																	el.display_name ||
																	'Select'
																}
																value={el.key}
																key={el.key}
															>
																{el.display_name}
															</Select.Option>
														);
													}
												})}
											</Select>
										</>
									) : get(obj, 'element', null) === 'cascader' ? (
										<Cascader
											className={
												'createDocumentInputSelect createDocumentV2TypeSelect'
											}
											placeholder={
												obj.placeholder || 'Select'
											}
											value={obj.value}
											onChange={(e) =>
												setValue(e, obj.type)
											}
											options={obj.options || []}
											expandTrigger={'hover'}
										/>
									) : (
										<>
											<textarea
												data-testId='create-document-textarea'
												value={obj.value}
												placeholder={obj.placeholder}
												className='combine-document-description'
												onChange={(e) =>
													setValue(e, obj.type)
												}
											/>
										</>
									)}
								</div>
							))}

							<h2 className='document-order-header'>
								Document Order
							</h2>
							<span className='document-reorder-header'>
								Use arrows to re-order documents
							</span>
							<ol className='document-order-list'>
								{(orderDocumentList || []).map((doc, idx) => {
									return (
										<li key={doc.id}>
											<span>{doc.file_name}</span>
											<button
												type='button'
												className={classnames(
													'up-arrow-button',
													idx === 0
														? 'disable-arrow-button'
														: ''
												)}
												onClick={() =>
													onUpDocumentArrowClick(idx)
												}
											>
												<img src={upArrow} />
											</button>
											<button
												type='button'
												className={classnames(
													'down-arrow-button',
													idx ===
														orderDocumentList.length - 1
														? 'disable-arrow-button'
														: ''
												)}
												onClick={() =>
													onDownDocumentArrowClick(
														idx
													)
												}
											>
												<img src={downArrow} />
											</button>
										</li>
									);
								})}
							</ol>
						</div>
						<div className='combine-document-dialog-footer'>
							<div
								className={buttonClass}
								onClick={handleCombineDocument}
							>
								Combine
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default CombineDocuments;
