//React & Redux
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

//Hooks
import { usePrevious } from '../../hooks/usePrevious';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Actions & Services
import { getReferralPrograms } from '../../actions/patients';
import {
	getTemplatesFlexible,
	createTemplate,
	editTemplate,
	archiveTemplate,
	batchUpdateTemplatePartners,
	batchUpdateTemplateType,
	LoadDocumentTypesForTemplate,
	clearLoadDocumentTypesForTemplate,
} from './../../actions/documents.js';
import {
	updateTemplate,
	updateSharedDocument,
	getDocumentDetail,
	getSharedDocumentDetail,
} from '../../actions/referralprograms/ReferralDocument';
import { setFilter } from '../../actions/appData';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import TemplatesFilterActionRow from './TemplatesFilterActionRow.js';
import TemplateTable from './TemplateTable.js';
import TemplateModals from './TemplateModals.js';
import TemplateDetail from './TemplateDetail.js';

//Styles
import './templates.css';

const defaultFilter = {
	templateStatus: ['active'],
	templateType: [],
	referralPrograms: [],
	order_by: 'created_at',
	order_type: 'desc',
	offset: 0,
	limit: 'All',
	q: '',
};

const headerFields = [
	{
		header: 'Template Name',
		data: 'file_name',
		minWidth: '336px',
		width: '28%',
	},
	{
		header: 'Template Type',
		data: 'document_type.display_name',
		minWidth: '160px',
		width: '13%',
	},
	{
		header: 'Created',
		data: 'created_at',
		minWidth: '168px',
		width: '13%',
	},
	{
		header: 'Modified',
		data: 'updated_at',
		minWidth: '168px',
		width: '13%',
	},
	{
		header: 'Referral Programs',
		data: 'referral_programs',
		minWidth: '240px',
		width: '20%',
	},
];

const sortableFields = new Set([
	'file_name',
	'document_type.display_name',
	'created_at',
	'updated_at',
]);

const Templates = (props) => {
	const {
		archivedTemplateError,
		archiveTemplateDocument,
		archivingTemplate,
		batchUpdatedTemplatePartnersError,
		batchUpdatedTemplateTypeError,
		batchUpdateTemplateType,
		batchUpdateTemplatePartners,
		batchUpdatingTemplatePartners,
		batchUpdatingTemplateTypes,
		clearLoadDocumentTypes,
		createdTemplateError,
		createTemplate,
		creatingTemplate,
		editedTemplateError,
		editingTemplate,
		editTemplate,
		enqueueSnackbar,
		getDocumentTemplatesFlexible,
		getDocumentTypes,
		getRefPrograms,
		getSharedDocumentDetail,
		gettingReferralPrograms,
		loaded_documentdetail,
		loaded_documentDetailError,
		loading_documentdetail,
		pagination = {},
		referralPrograms = [],
		setTemplatesFilter,
		templates = [],
		templatesError,
		templatesFilter = {},
		templatesLoading,
		templateTypes = [],
		templateTypesLoading,
		updatedTemplateError,
		updateSharedDocument,
		updatingTemplate,
	} = props;

	const [loading, setLoading] = useState(false);
	const [headerChecked, setHeaderChecked] = useState(false);
	const [selectedTemplates, setSelectedTemplates] = useState([]);
	const [selectedTemplatesObj, setSelectedTemplatesObj] = useState({});
	const [showEditTemplateModal, setShowEditTemplateModal] = useState(false);
	const [showDuplicateTemplateModal, setShowDuplicateTemplateModal] =
		useState(false);
	const [showFinalizeDraftModal, setShowFinalizeDraftModal] = useState(false);
	const [finalizeDraftModalTemplate, setFinalizeDraftModalTemplate] =
		useState(null);
	const [showArchiveDraftModal, setShowArchiveDraftModal] = useState(false);
	const [showEditReferralProgramsModal, setShowEditReferralProgramsModal] =
		useState(false);
	const [showReferralProgramsModal, setShowReferralProgramsModal] =
		useState(false);
	const [showChangeTemplateTypeModal, setShowChangeTemplateTypeModal] =
		useState(false);
	const [referralProgramModalNameList, setReferralProgramModalNameList] =
		useState([]);
	const [
		referralProgramModalTemplateName,
		setReferralProgramModalTemplateName,
	] = useState(null);
	const [referralProgramModalTemplate, setReferralProgramModalTemplate] =
		useState(null);
	const [finalizeDraftSelectedType, setFinalizeDraftSelectedType] =
		useState(null);
	const [finalizeDraftSelectedName, setFinalizeDraftSelectedName] =
		useState(null);
	const [changeTypeSelectedType, setChangeTypeSelectedType] = useState(null);
	const [canEditTemplate, setCanEditTemplate] = useState(false);
	const [canDuplicateTemplate, setCanDuplicateTemplate] = useState(false);
	const [canArchiveTemplate, setCanArchiveTemplate] = useState(false);
	const [canFinalizeTemplate, setCanFinalizeTemplate] = useState(false);
	const [canEditReferralPrograms, setCanEditReferralPrograms] =
		useState(false);
	const [canEditTemplateTypes, setCanEditTemplateTypes] = useState(false);
	const [initialLoading, setInitialLoading] = useState(true);
	const [shouldShowTemplateDetail, setShowTemplateDetail] = useState(false);
	const [singleSelectedTemplateUuid, setSingleSelectedTemplateUuid] =
		useState(null);
	const [templateEditFlag, setTemplateEditFlag] = useState(false);
	const [templateStatus, setTemplateStatus] = useState(
		get(templatesFilter, 'templateStatus') || defaultFilter.templateStatus
	);
	const [templateType, setTemplateType] = useState(
		get(templatesFilter, 'templateType') || defaultFilter.templateType
	);
	const [selectedReferralPrograms, setSelectedReferralPrograms] = useState(
		get(templatesFilter, 'referralPrograms') ||
			defaultFilter.referralPrograms
	);
	const [order_by, setOrder_by] = useState(
		get(templatesFilter, 'order_by') || defaultFilter.order_by
	);
	const [order_type, setOrder_type] = useState(
		get(templatesFilter, 'order_type') || defaultFilter.order_type
	);
	const [offset, setOffset] = useState(
		get(templatesFilter, 'offset') || defaultFilter.offset
	);
	const [limit, setLimit] = useState(
		get(templatesFilter, 'limit') || defaultFilter.limit
	);
	const [q, setQ] = useState(get(templatesFilter, 'q') || defaultFilter.q);
	const [getFilteredTemplatesFlag, setGetFilteredTemplatesFlag] =
		useState(false);
	const [clearSelectedTemplates, setClearSelectedTemplates] = useState(true);

	const previousTemplatesLoading = usePrevious(templatesLoading);
	const previousCreatingTemplate = usePrevious(creatingTemplate);
	const previousArchivingTemplate = usePrevious(archivingTemplate);
	const previousEditingTemplate = usePrevious(editingTemplate);
	const previousBatchUpdatingTemplatePartners = usePrevious(
		batchUpdatingTemplatePartners
	);
	const previousBatchUpdatingTemplateTypes = usePrevious(
		batchUpdatingTemplateTypes
	);

	useEffect(() => {
		props.getDocumentTypes();
		props.getRefPrograms();
		getFilteredTemplates();
		return props.clearLoadDocumentTypes;
	}, []);

	useEffect(() => {
		if (!isLoading()) {
			if (initialLoading) setInitialLoading(false);
		}

		if (get(selectedTemplates, 'length', 0) === 0 && headerChecked) {
			setHeaderChecked(false);
		}
	});

	useEffect(() => {
		if (previousTemplatesLoading && !templatesLoading) {
			if (templatesError) {
				enqueueSnackbar('Error in getting templates', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				setTemplatesFilter(getFilter());
			}
		}
	}, [templatesLoading]);

	useEffect(() => {
		if (previousCreatingTemplate && !creatingTemplate) {
			if (createdTemplateError) {
				enqueueSnackbar('Error in duplicating template', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				enqueueSnackbar('Template duplicated', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			}
			closeAllModalsAndDocumentDetail();
			updateFilters('templateStatus', ['draft']);
		}
	}, [creatingTemplate]);

	useEffect(() => {
		if (previousArchivingTemplate && !archivingTemplate) {
			if (archivedTemplateError) {
				enqueueSnackbar('Error in archiving template', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				enqueueSnackbar('Template archived', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			}
			closeAllModalsAndDocumentDetail();
			updateFilters('templateStatus', ['archived']);
		}
	}, [archivingTemplate]);

	useEffect(() => {
		if (previousEditingTemplate && !editingTemplate) {
			if (editedTemplateError) {
				enqueueSnackbar('Error in finalizing template', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				enqueueSnackbar('Template finalized', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			}
			closeAllModalsAndDocumentDetail();
			updateFilters('templateStatus', ['active']);
		}
	}, [editingTemplate]);

	useEffect(() => {
		if (
			previousBatchUpdatingTemplatePartners &&
			!batchUpdatingTemplatePartners
		) {
			if (batchUpdatedTemplatePartnersError) {
				enqueueSnackbar('Error in updating referral programs', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				enqueueSnackbar('Referral programs updated', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			}

			closeAllModals(!templateEditFlag);
			getFilteredTemplates();
		}
	}, [batchUpdatingTemplatePartners]);

	useEffect(() => {
		if (previousBatchUpdatingTemplateTypes && !batchUpdatingTemplateTypes) {
			if (batchUpdatedTemplateTypeError) {
				enqueueSnackbar('Error in updating template type', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				enqueueSnackbar('Template type updated', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			}
			if (changeTypeSelectedType)
				updateFilters('templateType', [changeTypeSelectedType]);
			closeAllModals(true);
		}
	}, [batchUpdatingTemplateTypes]);

	useEffect(() => {
		getFilteredTemplates(true, clearSelectedTemplates);
	}, [
		selectedReferralPrograms,
		templateType,
		q,
		templateStatus,
		order_by,
		order_type,
		offset,
		limit,
	]);

	useEffect(() => {
		actionsUpdate();
	}, [selectedTemplates, selectedTemplatesObj]);

	useEffect(() => {
		if (getFilteredTemplatesFlag) {
			getFilteredTemplates();
			setGetFilteredTemplatesFlag(false);
		}
	}, [getFilteredTemplatesFlag]);

	const getTemplates = _.debounce(() => {
		getAllTemplates();
	}, 500);

	const getFilter = () => {
		return {
			referralPrograms: selectedReferralPrograms,
			templateType,
			q,
			templateStatus,
			order_by,
			order_type,
			offset,
			limit,
		};
	};

	const handleResetFilters = () => {
		setSelectedReferralPrograms(defaultFilter.referralPrograms);
		setTemplateType(defaultFilter.templateType);
		setQ(defaultFilter.q);
		setTemplateStatus(defaultFilter.templateStatus);
		setOrder_by(defaultFilter.order_by);
		setOrder_type(defaultFilter.order_type);
		setOffset(defaultFilter.offset);
		setLimit(defaultFilter.limit);
	};

	const getAllTemplates = () => {
		const uuid = selectedReferralPrograms;
		let docType = templateType;
		if (docType && docType.length <= 0 && !_.isNil(templateTypes)) {
			docType = templateTypes.map((e) => e.key);
		}
		const status = templateStatus;
		let orderByTemplates = order_by;
		if (order_by.includes('.') && typeof order_by === 'string')
			orderByTemplates = order_by.split('.')[0];
		let limitTemplates = limit;
		limitTemplates = limit === 'All' ? -1 : limit;
		getDocumentTemplatesFlexible(
			uuid,
			docType,
			q,
			status,
			orderByTemplates,
			order_type,
			+offset,
			+limitTemplates
		);
	};

	const isLoading = () => {
		return (
			loading ||
			gettingReferralPrograms ||
			templatesLoading ||
			templateTypesLoading
		);
	};

	const handleTemplateSelectionChange = (status, template) => {
		if (status) {
			addSelectedTemplate(template);
		} else {
			removeSelectedTemplate(template);
		}
	};

	const addSelectedTemplate = (template) => {
		const uuid = get(template, 'uuid', null);
		if (uuid) {
			const newSelectedTemplates = _.cloneDeep(selectedTemplates);
			const newSelectedTemplatesObj = _.cloneDeep(selectedTemplatesObj);
			newSelectedTemplates.push(uuid);
			newSelectedTemplatesObj[uuid] = template;
			setSelectedTemplates(newSelectedTemplates);
			setSelectedTemplatesObj(newSelectedTemplatesObj);
			setHeaderChecked(true);
		}
	};

	const removeSelectedTemplate = (template) => {
		const uuid = get(template, 'uuid', null);
		if (uuid) {
			const newSelectedTemplates = _.cloneDeep(selectedTemplates);
			const newSelectedTemplatesObj = _.cloneDeep(selectedTemplatesObj);
			_.remove(newSelectedTemplates, (el) => el === uuid);
			delete newSelectedTemplatesObj[uuid];
			setSelectedTemplates(newSelectedTemplates);
			setSelectedTemplatesObj(newSelectedTemplatesObj);
		}
	};

	const isAllChecked = () => {
		const { total } = pagination;

		for (const { uuid } of templates) {
			if (!selectedTemplatesObj[uuid]) return false;
		}

		return total > 0;
	};

	const isSomeChecked = () => {
		const { end } = pagination;

		const filterData = _.filter(templates, (obj) => {
			return selectedTemplatesObj[obj.uuid];
		});

		return filterData.length > 0 && filterData.length !== end + 1;
	};

	const handleAllTemplatesToggle = (isChecked) => {
		if (isChecked) isChecked = !isSomeChecked();
		const dataMap = {};

		for (const template of templates) {
			delete selectedTemplatesObj[template.uuid];
			dataMap[template.uuid] = { ...template };
		}

		if (isChecked) {
			setSelectedTemplates([
				...Object.keys(selectedTemplatesObj),
				...Object.keys(dataMap),
			]);
			setSelectedTemplatesObj({ ...selectedTemplatesObj, ...dataMap });
		} else {
			setSelectedTemplates([...Object.keys(selectedTemplatesObj)]);
			setSelectedTemplatesObj({ ...selectedTemplatesObj });
		}
	};

	const removeAllTemplates = () => {
		setSelectedTemplates([]);
		setSelectedTemplatesObj({});
		setHeaderChecked(false);
	};

	const getTemplateOwnerUuids = (template) => {
		if (get(template, 'all_partners', '') === true) return 'All';
		else {
			return getArrOfOwnerUuids(template);
		}
	};

	const getArrOfOwnerUuids = (template) => {
		const ownerList = get(template, 'document_owners', []) || [];
		const referralPrograms = ownerList.filter(
			(el) => get(el, 'owner_type', null) === 'affiliation'
		);
		const referralProgramUuids = [];
		referralPrograms.forEach((el) => {
			if (get(el, 'uuid', ''))
				referralProgramUuids.push(get(el, 'uuid', null));
		});
		return referralProgramUuids;
	};

	const getTemplateFromUuid = (uuid) => {
		const matchingTemplate = _.find(
			templates,
			(el) => get(el, 'uuid', '') === uuid
		);
		return matchingTemplate || null;
	};

	const getSelectedTemplateFromUuid = (uuid) => {
		return selectedTemplatesObj[uuid] || null;
	};

	const getRefProgramFromUuid = (uuid) => {
		const refProgram = _.find(
			referralPrograms,
			(el) => get(el, 'uuid', '') === uuid
		);
		return refProgram || null;
	};

	const getRefProgNameFromUuid = (uuid) => {
		const refProgram = _.find(
			referralPrograms,
			(el) => get(el, 'uuid', '') === uuid
		);
		return get(refProgram, 'display_name', '--');
	};

	const getTypeFromUuid = (uuid) => {
		if (Array.isArray(uuid)) uuid = get(uuid, '[0]', null);
		const template = getTemplateFromUuid(uuid);
		if (template) {
			return get(template, 'document_type.display_name', null);
		} else return null;
	};

	const openReferralProgramModal = (nameList, fileName, template) => {
		setShowReferralProgramsModal(true);
		setReferralProgramModalNameList(nameList);
		setReferralProgramModalTemplateName(fileName);
		setReferralProgramModalTemplate(template);
	};

	const closeReferralProgramModal = () => {
		if (templateEditFlag) {
			setShowReferralProgramsModal(false);
		} else {
			setShowReferralProgramsModal(false);
			setReferralProgramModalNameList(null);
			setReferralProgramModalTemplateName(null);
			setReferralProgramModalTemplate(null);
		}
		setTemplateEditFlag(false);
	};

	const noSelectedArchivedTemplates = () => {
		if (Array.isArray(selectedTemplates)) {
			for (let i = 0; i < selectedTemplates.length; i++) {
				const template = getSelectedTemplateFromUuid(
					selectedTemplates[i]
				);
				if (templateIsArchived(template)) return false;
			}
		}
		return true;
	};

	const templateIsArchived = (template) => {
		return get(template, 'document_state.name', '') === 'archived';
	};

	const templateIsDraft = (template) => {
		return get(template, 'document_state.name', '') === 'draft';
	};

	const openEditTemplateModal = (uuid) => {
		setShowEditTemplateModal(true);
		if (uuid) setSingleSelectedTemplateUuid([uuid]);
	};

	const determineCanEditTemplate = () => {
		setCanEditTemplate(
			get(selectedTemplates, 'length', 0) === 1 &&
				noSelectedArchivedTemplates()
		);
	};

	const openDuplicateModal = (uuid) => {
		setShowDuplicateTemplateModal(true);
		if (uuid) setSingleSelectedTemplateUuid([uuid]);
	};

	const determineCanDuplicateTemplate = () => {
		setCanDuplicateTemplate(
			get(selectedTemplates, 'length', 0) === 1 &&
				noSelectedArchivedTemplates()
		);
	};

	const openArchiveModal = (uuid, flag) => {
		setShowArchiveDraftModal(true);
		if (uuid) setSingleSelectedTemplateUuid([uuid]);
		if (flag) setTemplateEditFlag(true);
	};

	const determineCanArchiveTemplate = () => {
		const selectedTemplateUuid = get(selectedTemplates, '[0]', null);
		if (selectedTemplateUuid) {
			const template = getSelectedTemplateFromUuid(selectedTemplateUuid);
			if (template) {
				setCanArchiveTemplate(
					get(selectedTemplates, 'length', 0) === 1 &&
						get(template, 'document_state.name', '') !== 'archived'
				);
			}
		} else {
			setCanArchiveTemplate(false);
		}
	};

	const openFinalizeDraftModal = (uuid, flag) => {
		setShowFinalizeDraftModal(true);
		if (uuid) setSingleSelectedTemplateUuid([uuid]);
		if (flag) setTemplateEditFlag(true);
	};

	const determineCanFinalizeTemplate = () => {
		const selectedTemplateUuid = get(selectedTemplates, '[0]', null);
		if (selectedTemplateUuid) {
			const template = getSelectedTemplateFromUuid(selectedTemplateUuid);
			if (template) {
				setCanFinalizeTemplate(
					get(selectedTemplates, 'length', 0) === 1 &&
						get(template, 'document_state.name', '') === 'draft'
				);
			}
		} else {
			setCanFinalizeTemplate(false);
		}
	};

	const changeTemplateType = (type) => {
		setFinalizeDraftSelectedType(type);
	};

	const changeTemplateName = (e) => {
		const name = get(e, 'target.value', null);
		setFinalizeDraftSelectedName(name);
	};

	const closeFinalizeDraftModal = () => {
		if (templateEditFlag) {
			setShowFinalizeDraftModal(false);
		} else {
			setShowFinalizeDraftModal(false);
			setFinalizeDraftModalTemplate(null);
			setFinalizeDraftSelectedName(null);
			setFinalizeDraftSelectedType(null);
			setSingleSelectedTemplateUuid(null);
		}
		setTemplateEditFlag(false);
	};

	const openReferralProgramEditModal = (uuid, flag) => {
		if (uuid) setSingleSelectedTemplateUuid([uuid]);
		setShowEditReferralProgramsModal(true);
		if (flag) setTemplateEditFlag(true);
	};

	const determineCanEditReferralPrograms = () => {
		if (noSelectedArchivedTemplates()) {
			if (get(selectedTemplates, 'length', 0) === 0) {
				setCanEditReferralPrograms(false);
				return;
			}
			setCanEditReferralPrograms(
				checkAllOwnersEquality() && checkDocumentOwnersEquality()
			);
		}
	};

	const checkAllOwnersEquality = () => {
		for (let i = 0; i < selectedTemplates.length - 1; i++) {
			if (
				get(
					getSelectedTemplateFromUuid(selectedTemplates[i]),
					'all_partners',
					null
				) !==
				get(
					getSelectedTemplateFromUuid(selectedTemplates[i + 1]),
					'all_partners',
					null
				)
			)
				return false;
		}
		return true;
	};

	const checkDocumentOwnersEquality = () => {
		for (let i = 0; i < selectedTemplates.length - 1; i++) {
			if (!ownersAreEqual(selectedTemplates[i], selectedTemplates[i + 1]))
				return false;
		}
		return true;
	};

	const ownersAreEqual = (uuid1, uuid2) => {
		const template1 = getSelectedTemplateFromUuid(uuid1);
		const template2 = getSelectedTemplateFromUuid(uuid2);
		if (!template1 || !template2) return false;

		const template1Uuids = getArrOfOwnerUuids(template1);
		const template2Uuids = getArrOfOwnerUuids(template2);
		if (!Array.isArray(template1Uuids) || !Array.isArray(template2Uuids))
			return false;

		return _.isEqual(template1Uuids.sort(), template2Uuids.sort());
	};

	const determineCanEditTemplateTypes = () => {
		if (noSelectedArchivedTemplates()) {
			if (get(selectedTemplates, 'length', 0) === 0) {
				setCanEditTemplateTypes(false);
				return;
			}
			setCanEditTemplateTypes(checkAllTemplateTypesEquality());
		}
	};

	const checkAllTemplateTypesEquality = () => {
		for (let i = 0; i < selectedTemplates.length - 1; i++) {
			if (
				get(
					getSelectedTemplateFromUuid(selectedTemplates[i]),
					'document_type.name',
					null
				) !==
				get(
					getSelectedTemplateFromUuid(selectedTemplates[i + 1]),
					'document_type.name',
					''
				)
			)
				return false;
		}
		return true;
	};

	const getUuidFromPartners = (partners) => {
		if (!Array.isArray(partners)) return [];
		const uuids = [];
		partners.forEach((el) => {
			if (get(el, 'uuid', '')) uuids.push(get(el, 'uuid', ''));
		});
		return uuids;
	};

	const closeReferralProgramEditModal = () => {
		if (templateEditFlag) {
			setShowEditReferralProgramsModal(false);
		} else {
			setShowEditReferralProgramsModal(false);
			setSingleSelectedTemplateUuid(null);
		}
		setTemplateEditFlag(false);
	};

	const openChangeTemplateTypeModal = (uuid) => {
		if (uuid) setSingleSelectedTemplateUuid([uuid]);
		setShowChangeTemplateTypeModal(true);
	};

	const changeCurrentTemplateType = (type) => {
		setChangeTypeSelectedType(type);
	};

	const closeAllModals = (override) => {
		if (templateEditFlag && !override) {
			setShowEditTemplateModal(false);
			setShowDuplicateTemplateModal(false);
			setShowFinalizeDraftModal(false);
			setShowArchiveDraftModal(false);
			setShowEditReferralProgramsModal(false);
			setShowReferralProgramsModal(false);
			setShowChangeTemplateTypeModal(false);
			setChangeTypeSelectedType(null);
		} else {
			setShowEditTemplateModal(false);
			setShowDuplicateTemplateModal(false);
			setShowFinalizeDraftModal(false);
			setShowArchiveDraftModal(false);
			setShowEditReferralProgramsModal(false);
			setShowReferralProgramsModal(false);
			setShowChangeTemplateTypeModal(false);
			setChangeTypeSelectedType(null);
			setFinalizeDraftModalTemplate(null);
			setFinalizeDraftSelectedType(null);
			setFinalizeDraftSelectedName(null);
			setSingleSelectedTemplateUuid(null);
		}
		setTemplateEditFlag(false);
	};

	const closeAllModalsAndDocumentDetail = (flag) => {
		closeAllModals(true);
		setShowTemplateDetail(false);
		if (flag) setGetFilteredTemplatesFlag(flag);
	};

	const actionsUpdate = () => {
		determineCanEditTemplate();
		determineCanDuplicateTemplate();
		determineCanArchiveTemplate();
		determineCanFinalizeTemplate();
		determineCanEditReferralPrograms();
		determineCanEditTemplateTypes();
	};

	const updateFilters = (header, value) => {
		setOffset(0);
		switch (header) {
			case 'referralPrograms':
				setSelectedReferralPrograms(value);
				break;
			case 'templateType':
				setTemplateType(value);
				break;
			case 'q':
				setQ(value);
				break;
			case 'templateStatus':
				setTemplateStatus(value);
				break;
			case 'order_by':
				setOrder_by(value);
				break;
			case 'order_type':
				setOrder_type(value);
				break;
			case 'offset':
				setOffset(value);
				break;
			case 'limit':
				setLimit(value);
				break;
		}
	};

	const handleSort = (order_by, order_type) => {
		setOrder_by(order_by);
		setOrder_type(order_type);
		setOffset(0);
	};

	const getFilteredTemplates = (debounce = false, clearSelected = true) => {
		if (debounce) getTemplates();
		else getAllTemplates();

		if (clearSelected) {
			removeAllTemplates();
		} else {
			setClearSelectedTemplates(true);
		}
	};

	const handleResultsPerPageChange = (limit) => {
		setOffset(0);
		setLimit(limit);
	};

	const handlePageChange = (e, offset) => {
		setOffset(offset);
		setClearSelectedTemplates(false);
	};

	const shouldAddAllPartners = (partnersArr) => {
		if (get(partnersArr, 'length', 0) === 0) return false;
		else return !partnersArr.includes('all_partners');
	};

	const duplicateTemplate = () => {
		const selectedTemplateUuid =
			get(singleSelectedTemplateUuid, '[0]', null) ||
			get(selectedTemplates, '[0]', null);
		if (selectedTemplateUuid) {
			const template = getTemplateFromUuid(selectedTemplateUuid);
			const documentType = get(template, 'document_type.name', '');
			const fileName = get(template, 'file_name', '');
			const partnerUuids = getArrOfOwnerUuids(template);
			createTemplate(
				documentType,
				fileName,
				partnerUuids,
				selectedTemplateUuid
			);
		}
	};

	const archiveTemplateDoc = () => {
		const selectedTemplateUuid =
			get(singleSelectedTemplateUuid, '[0]', null) ||
			get(selectedTemplates, '[0]', null);
		if (selectedTemplateUuid) {
			archiveTemplateDocument(selectedTemplateUuid);
		}
	};

	const finalizeTemplate = () => {
		const selectedTemplateUuid =
			get(singleSelectedTemplateUuid, '[0]', null) ||
			get(selectedTemplates, '[0]', null);
		if (selectedTemplateUuid) {
			const template = getTemplateFromUuid(selectedTemplateUuid);
			const documentType = finalizeDraftSelectedType || '';
			const fileName = finalizeDraftSelectedName || '';
			const partnerUuids = getArrOfOwnerUuids(template);
			editTemplate(
				selectedTemplateUuid,
				documentType,
				fileName,
				partnerUuids,
				'activate'
			);
		}
	};

	const handleBatchUpdateTemplatePartners = (selectedPartners) => {
		const partner_uuids = getUuidFromPartners(selectedPartners);
		const selectedTemplateUuids =
			singleSelectedTemplateUuid || selectedTemplates || [];
		batchUpdateTemplatesPartners(selectedTemplateUuids, partner_uuids);
	};

	const changeSelectedTemplateType = () => {
		const selectedTemplateUuids =
			singleSelectedTemplateUuid || selectedTemplates;
		const selectedType = changeTypeSelectedType;
		if (get(selectedTemplateUuids, 'length', 0) > 0 && selectedType) {
			batchUpdateTemplateType(selectedTemplateUuids, null, selectedType);
		}
	};

	const batchUpdateTemplatesPartners = (document_uuids, partner_uuids) => {
		if (
			get(partner_uuids, 'length', 0) ===
			get(referralPrograms, 'length', null)
		)
			partner_uuids = [];
		batchUpdateTemplatePartners(document_uuids, partner_uuids);
	};

	const showTemplateDetail = () => {
		setShowEditTemplateModal(false);
		setShowTemplateDetail(true);
	};

	const closeTemplateDetail = () => {
		setShowTemplateDetail(false);
		setSingleSelectedTemplateUuid(null);
	};

	if (initialLoading) {
		return (
			<div className='templates-wrapper'>
				<Loading loading={true} className='templatesLoader' />
			</div>
		);
	} else {
		return (
			<div className='templates-wrapper'>
				<TemplateModals
					selectedTemplates={selectedTemplates || []}
					singleSelectedTemplateUuid={singleSelectedTemplateUuid}
					getTemplateFromUuid={getTemplateFromUuid}
					getArrOfOwnerUuids={getArrOfOwnerUuids}
					getRefProgramFromUuid={getRefProgramFromUuid}
					getTypeFromUuid={getTypeFromUuid}
					showEditTemplateModal={showEditTemplateModal}
					showDuplicateTemplateModal={showDuplicateTemplateModal}
					showFinalizeDraftModal={showFinalizeDraftModal}
					closeFinalizeDraftModal={closeFinalizeDraftModal}
					finalizeDraftModalTemplate={
						finalizeDraftModalTemplate || {}
					}
					finalizeDraftSelectedType={finalizeDraftSelectedType}
					finalizeDraftSelectedName={finalizeDraftSelectedName}
					templateTypes={templateTypes || []}
					changeTemplateType={changeTemplateType}
					changeTemplateName={changeTemplateName}
					changeCurrentTemplateType={changeCurrentTemplateType}
					changeTypeSelectedType={changeTypeSelectedType}
					changeSelectedTemplateType={changeSelectedTemplateType}
					showArchiveDraftModal={showArchiveDraftModal}
					showEditReferralProgramsModal={
						showEditReferralProgramsModal
					}
					showReferralProgramsModal={showReferralProgramsModal}
					showChangeTemplateTypeModal={showChangeTemplateTypeModal}
					referralProgramModalNameList={
						referralProgramModalNameList || []
					}
					referralProgramModalTemplateName={
						referralProgramModalTemplateName || '--'
					}
					referralProgramModalTemplate={
						referralProgramModalTemplate || {}
					}
					closeReferralProgramModal={closeReferralProgramModal}
					templatesLoading={templatesLoading}
					duplicateTemplate={duplicateTemplate}
					creatingTemplate={creatingTemplate}
					finalizeTemplate={finalizeTemplate}
					editingTemplate={editingTemplate}
					archiveTemplate={archiveTemplateDoc}
					archivingTemplate={archivingTemplate}
					handleBatchUpdateTemplatePartners={
						handleBatchUpdateTemplatePartners
					}
					batchUpdatingTemplatePartners={
						batchUpdatingTemplatePartners
					}
					batchUpdatingTemplateTypes={batchUpdatingTemplateTypes}
					showTemplateDetail={showTemplateDetail}
					referralPrograms={referralPrograms}
					closeReferralProgramEditModal={
						closeReferralProgramEditModal
					}
					closeAllModals={closeAllModals}
				/>
				{shouldShowTemplateDetail ? (
					<TemplateDetail
						closeTemplateDetail={closeAllModalsAndDocumentDetail}
						enqueueSnackbar={enqueueSnackbar}
						getSharedDocumentDetail={getSharedDocumentDetail}
						getTemplateFromUuid={getTemplateFromUuid}
						getTemplateOwnerUuids={getTemplateOwnerUuids}
						loadedDocumentDetail={loaded_documentdetail}
						loadedDocumentDetailError={loaded_documentDetailError}
						loadingDocumentDetail={loading_documentdetail}
						openArchiveModal={openArchiveModal}
						openFinalizeDraftModal={openFinalizeDraftModal}
						openReferralProgramEditModal={
							openReferralProgramEditModal
						}
						referralPrograms={referralPrograms || []}
						selectedTemplates={
							singleSelectedTemplateUuid ||
							selectedTemplates ||
							[]
						}
						updateSharedDocument={updateSharedDocument}
						updatingTemplate={updatingTemplate}
						updatedTemplateError={updatedTemplateError}
					/>
				) : (
					<Fragment>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								minWidth: 'inherit',
							}}
						>
							<TemplatesFilterActionRow
								templateStatus={templateStatus || ['active']}
								templateType={templateType || []}
								referralPrograms={referralPrograms}
								selectedReferralPrograms={
									selectedReferralPrograms
								}
								templateTypes={templateTypes || []}
								openEditTemplateModal={openEditTemplateModal}
								openDuplicateModal={openDuplicateModal}
								openArchiveModal={openArchiveModal}
								openFinalizeDraftModal={openFinalizeDraftModal}
								openReferralProgramEditModal={
									openReferralProgramEditModal
								}
								openChangeTemplateTypeModal={
									openChangeTemplateTypeModal
								}
								canEditTemplate={canEditTemplate}
								canDuplicateTemplate={canDuplicateTemplate}
								canArchiveTemplate={canArchiveTemplate}
								canFinalizeTemplate={canFinalizeTemplate}
								canEditReferralPrograms={
									canEditReferralPrograms
								}
								canEditTemplateTypes={canEditTemplateTypes}
								updateFilters={updateFilters}
								handleResetFilters={handleResetFilters}
								q={q}
							/>
						</div>
						<div>
							<TemplateTable
								templates={templates || []}
								pagination={pagination || {}}
								headerChecked={headerChecked}
								handleAllTemplatesToggle={
									handleAllTemplatesToggle
								}
								headerFields={headerFields}
								selectedTemplates={selectedTemplates || []}
								selectedTemplatesObj={
									selectedTemplatesObj || {}
								}
								handleTemplateSelectionChange={
									handleTemplateSelectionChange
								}
								addSelectedTemplate={addSelectedTemplate}
								removeSelectedTemplate={removeSelectedTemplate}
								getTemplateOwnerUuids={getTemplateOwnerUuids}
								getRefProgNameFromUuid={getRefProgNameFromUuid}
								openReferralProgramModal={
									openReferralProgramModal
								}
								isLoading={isLoading}
								sortableFields={sortableFields}
								order_by={order_by || 'created_at'}
								order_type={order_type || 'desc'}
								limit={limit}
								handleSort={handleSort}
								templateIsArchived={templateIsArchived}
								templateIsDraft={templateIsDraft}
								openEditTemplateModal={openEditTemplateModal}
								openDuplicateModal={openDuplicateModal}
								openArchiveModal={openArchiveModal}
								openFinalizeDraftModal={openFinalizeDraftModal}
								openReferralProgramEditModal={
									openReferralProgramEditModal
								}
								openChangeTemplateTypeModal={
									openChangeTemplateTypeModal
								}
								handleResultsPerPageChange={
									handleResultsPerPageChange
								}
								handlePageChange={handlePageChange}
								isAllChecked={isAllChecked}
								isSomeChecked={isSomeChecked}
								handleResetFilters={handleResetFilters}
							/>
						</div>
					</Fragment>
				)}
			</div>
		);
	}
};

const mapStateToProps = (state, ownProps) => {
	return {
		referralPrograms: get(
			state,
			'referralPrograms.referralPrograms.data',
			[]
		),
		gettingReferralPrograms: state.referralPrograms.gettingReferralPrograms,
		referralProgramsError: state.referralPrograms.referralProgramsError,
		templatesLoading: state.documents.templatesLoading,
		templatesLoaded: state.documents.templatesLoaded,
		templatesError: state.documents.templatesError,
		templates: get(state, ['documents', 'templates'], []),
		pagination: get(state, ['documents', 'templatesPagination'], {}),
		creatingTemplate: state.documents.creatingTemplate,
		createdTemplate: state.documents.createdTemplate,
		createdTemplateError: state.documents.createdTemplateError,
		editingTemplate: state.documents.editingTemplate,
		editedTemplate: state.documents.editedTemplate,
		editedTemplateError: state.documents.editedTemplateError,
		archivingTemplate: state.documents.archivingTemplate,
		archivedTemplate: state.documents.archivedTemplate,
		archivedTemplateError: state.documents.archivedTemplateError,
		batchUpdatingTemplatePartners:
			state.documents.batchUpdatingTemplatePartners,
		batchUpdatedTemplatePartners:
			state.documents.batchUpdatedTemplatePartners,
		batchUpdatedTemplatePartnersError:
			state.documents.batchUpdatedTemplatePartnersError,
		batchUpdatingTemplateTypes: state.documents.batchUpdatingTemplateTypes,
		batchUpdatedTemplateType: state.documents.batchUpdatedTemplateType,
		batchUpdatedTemplateTypeError:
			state.documents.batchUpdatedTemplateTypeError,
		templateTypes: state.documents.documentTypesForTemplate,
		templateTypesError: state.documents.documentTypesForTemplateError,
		templateTypesLoading:
			state.documents.documentTypesForForTemplateLoading,
		loaded_documentdetail: state.referralProgramsList.loaded_documentdetail,
		loading_documentdetail:
			state.referralProgramsList.loading_documentdetail,
		updated_template: state.referralProgramsList.updatedTemplate,
		updatingTemplate: state.referralProgramsList.updatingTemplate,
		updatedTemplateError: state.referralProgramsList.updatedTemplateError,
		loaded_documentDetailError:
			state.referralProgramsList.loaded_documentDetailError,
		templatesFilter: get(state, 'appData.templates', null) || {},
	};
};

const mapDispatchToProps = (dispatch) => ({
	getRefPrograms: () => dispatch(getReferralPrograms()),
	getDocumentTemplatesFlexible: (
		uuid,
		docType,
		q,
		status,
		order_by,
		order_type,
		offset,
		limit
	) =>
		dispatch(
			getTemplatesFlexible(
				uuid,
				docType,
				q,
				status,
				'templates',
				order_by,
				order_type,
				offset,
				limit
			)
		),
	getDocumentTypes: () => dispatch(LoadDocumentTypesForTemplate()),
	createTemplate: (
		document_type,
		file_name,
		partner_uuids,
		clone_from_template_uuid
	) =>
		dispatch(
			createTemplate(
				document_type,
				file_name,
				partner_uuids,
				clone_from_template_uuid
			)
		),
	editTemplate: (
		document_uuid,
		document_type,
		file_name,
		partner_uuids,
		template_state
	) =>
		dispatch(
			editTemplate(
				document_uuid,
				document_type,
				file_name,
				partner_uuids,
				template_state
			)
		),
	archiveTemplateDocument: (document_uuid) =>
		dispatch(archiveTemplate(document_uuid)),
	batchUpdateTemplatePartners: (document_uuids, partner_uuids) =>
		dispatch(batchUpdateTemplatePartners(document_uuids, partner_uuids)),
	batchUpdateTemplateType: (document_uuids, partner_uuids, document_type) =>
		dispatch(batchUpdateTemplateType(document_uuids, null, document_type)),
	updateTemplate: (uuid, data) => dispatch(updateTemplate(uuid, data)),
	updateSharedDocument: (uuid, data) =>
		dispatch(updateSharedDocument(uuid, data)),
	getDocumentDetail: (data) => dispatch(getDocumentDetail(data)),
	getSharedDocumentDetail: (data) => dispatch(getSharedDocumentDetail(data)),
	clearLoadDocumentTypes: () => dispatch(clearLoadDocumentTypesForTemplate()),
	setTemplatesFilter: (data) => dispatch(setFilter('templates', data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
