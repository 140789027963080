//React & Redux
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

//Lodash
import _ from 'lodash';

//Utils
import {
	states_list,
	GetStateWiseTimezone,
	isDisabledTimezone,
} from '../../../utils.js';

//Components
import Icon from '../../Common/Icon';
import Tooltip from '../../Common/Tooltip';

//UI Libraries
import { Select, Button } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';

class RecheduleFilters extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.filteredModalities = this.filteredModalities.bind(this);
		this.sortedConsultations = this.sortedConsultations.bind(this);
	}

	filteredModalities() {
		const modalities = _.get(this, ['props', 'modalities'], []);
		const filtered = _.filter(modalities, (mod) => {
			return mod && mod.key && !(mod.key == 'webinar');
		});
		return filtered;
	}

	sortedConsultations() {
		const { consultation_type } = this.props.selectedFilters;
		return [{ label: consultation_type, value: consultation_type }];
	}

	filteredVseeSpeciality() {
		const specialties = _.get(this, ['props', 'specialties'], []);
		const filtered = _.filter(specialties, (spec) => {
			const kay = _.get(spec, 'key', '');
			return (
				kay != '' &&
				kay.indexOf('_willing') == -1 &&
				kay != 'no_default'
			);
		});
		return filtered;
	}

	VisitProviders() {
		const Providers = _.get(this, ['props', 'providers'], []);
		const sortedProvider = [
			{ full_name: 'All Providers', id: undefined },
			...Providers,
		];
		return sortedProvider;
	}

	render() {
		const { selectedFilters } = this.props;
		const state = _.get(selectedFilters, 'state', '');
		const continueDisabled = !this.props.configureButtonDisabled()
			? ''
			: ' scheduleFilterButtonContinueDisabled';
		return (
			<Fragment>
				{[
					{
						title: 'Specialty',
						required: true,
						type: 'dropdown',
						options: this.filteredVseeSpeciality(),
						internal: 'key',
						external: 'display_name',
						stateVal: 'vsee_specialty',
						label: 'display_name',
					},
					{
						title: 'Appointment type',
						required: true,
						type: 'dropdown',
						options: this.sortedConsultations(),
						internal: 'value',
						external: 'label',
						stateVal: 'consultation_type',
						label: 'lebel',
						disabled: true,
					},
					{
						title: 'Visit state',
						required: true,
						type: 'dropdown',
						options: states_list,
						internal: 'key',
						external: 'value',
						stateVal: 'state',
						label: 'key',
						tooltipContent:
							'Patient’s delivery address state is auto-populated here. But please ensure that the state where the visit will take place is entered',
					},
					{
						title: 'Timezone',
						required: true,
						type: 'dropdown',
						options: GetStateWiseTimezone(state),
						internal: 'area',
						external: 'name',
						stateVal: 'timezone',
						label: 'name',
						disabled: isDisabledTimezone(state),
					},
					this.props.showModality
						? {
								title: 'Modality',
								required: true,
								type: 'dropdown',
								options: this.filteredModalities(),
								internal: 'key',
								external: 'display_name',
								stateVal: 'modality',
								label: 'display_name',
						  }
						: null,
					{
						title: 'Provider',
						required: true,
						type: 'dropdown',
						options: this.VisitProviders(),
						internal: 'id',
						external: 'full_name',
						stateVal: 'provider_id',
						label: 'full_name',
					},
				]
					.filter((it) => it !== null)
					.map((el) => {
						return (
							<div
								className='scheduleFilterDropdownContainer'
								key={el.title}
							>
								{el.required && (
									<span className='scheduleRequired'>*</span>
								)}
								<div
									className={classnames(
										'scheduleFilterDropdownTitle',
										{ tooltipcontent: el.tooltipContent }
									)}
								>
									{el.title}
									{el.tooltipContent && (
										<Tooltip
											className='scheduleFilterTooltip'
											effect='dark'
											content={el.tooltipContent}
											placement='top'
										>
											<Icon icon='info' />
										</Tooltip>
									)}
								</div>
								<Select
									value={
										this.props.selectedFilters[el.stateVal]
									}
									placeholder='Select'
									onChange={(e) =>
										this.props.updateFilter(el.stateVal, e)
									}
									className='scheduleFilterDropdownEl dropdownLeftEdgeAligned'
									disabled={el.disabled}
								>
									{_.get(el, 'options', []).map((option) => {
										return (
											<Select.Option
												key={option[el.internal]}
												label={option[el.label]}
												value={option[el.internal]}
											>
												{option[el.external]}
											</Select.Option>
										);
									})}
								</Select>
							</div>
						);
					})}
				<div className='scheduleFilterButtonRow'>
					<div
						className='scheduleFilterButtonBack'
						onClick={() => {
							this.props.handleNavigateToAppointmentList();
						}}
					>
						Cancel
					</div>
					<Button
						nativeType='button'
						className={
							'scheduleFilterButtonContinue' + continueDisabled
						}
						onClick={() => {
							this.props.showRescheduleConfirmDialog();
						}}
						disabled={this.props.configureButtonDisabled()}
					>
						Confirm
					</Button>
				</div>
			</Fragment>
		);
	}
}

RecheduleFilters.propTypes = {
	modalities: PropTypes.array.isRequired,
	providers: PropTypes.array.isRequired,
	schedulingConsultations: PropTypes.array.isRequired,
	showModality: PropTypes.bool,
};

export default RecheduleFilters;
