//React & Redux
import React, { useEffect, useState } from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Layout, Button, Loading } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import EditPatientInvoiceSegment from './EditPatientInvoiceSegment';
import EditInsuranceBillingSegment from './EditInsuranceBillingSegment';
import EditPartnerInvoiceSegment from './EditPartnerInvoiceSegment';
import EditBillingNotesSegment from './EditBillingNotesSegment';

const EditBillingSegment = (props) => {
	const {
		billingStatusEnums,
		encounterBillingDetail,
		encounterBillingDetail: {
			billing_notes = null,
			billing_status,
			coupon_code,
			insurance_expected_revenue = null,
			partner_invoice_date,
			partner_invoice_rate = null,
			partner_invoice_status,
			patient_billing_date,
			patient_billing_amount,
			patient_fee_refund,
			patient_fee_refund_decision,
			patient_fee_refund_processed_date,
			patient_fee_refund_reason,
			patient_fee_refund_reason_other,
			patient_pay,
			payor,
		},
		encounterDetail,
		handleToggleEdit,
		isBillingCoordinator,
		partnerInvoiceEnums,
		patientFeeEnum,
		saveEncounterValues,
		referralPrograms,
		patientDetail,
	} = props;

	const initialPaymentMethod =
		payor === 'self_pay'
			? 'self_pay'
			: payor == 'undetermined'
			? ''
			: 'insurance';
	const [loading, setLoading] = useState(false);
	const [updatedFormValues, setUpdatedFormValues] = useState({});
	const [encounterBillingDetailState, setEncounterBillingDetailState] =
		useState({
			billing_notes,
			billing_status,
			coupon_code,
			insurance_expected_revenue,
			partner_invoice_date: moment(partner_invoice_date).isValid()
				? moment(partner_invoice_date).toDate()
				: null,
			partner_invoice_rate,
			partner_invoice_status,
			patient_billing_date: moment(patient_billing_date).isValid()
				? moment(patient_billing_date).toDate()
				: null,
			patient_billing_amount,
			patient_fee_refund,
			patient_fee_refund_decision,
			patient_fee_refund_processed_date: moment(
				patient_fee_refund_processed_date
			).isValid()
				? moment(patient_fee_refund_processed_date).toDate()
				: null,
			patient_fee_refund_reason,
			patient_fee_refund_reason_other,
			patient_pay,
			payment_method: initialPaymentMethod,
		});

	useEffect(() => {
		setEncounterBillingDetailState({
			billing_notes,
			billing_status,
			coupon_code,
			insurance_expected_revenue,
			partner_invoice_date: moment(partner_invoice_date).isValid()
				? moment(partner_invoice_date).toDate()
				: null,
			partner_invoice_rate,
			partner_invoice_status,
			patient_billing_date: moment(patient_billing_date).isValid()
				? moment(patient_billing_date).toDate()
				: null,
			patient_billing_amount,
			patient_fee_refund,
			patient_fee_refund_decision,
			patient_fee_refund_processed_date: moment(
				patient_fee_refund_processed_date
			).isValid()
				? moment(patient_fee_refund_processed_date).toDate()
				: null,
			patient_fee_refund_reason,
			patient_fee_refund_reason_other,
			patient_pay,
			payment_method: initialPaymentMethod,
		});
	}, [encounterBillingDetail, encounterDetail]);

	const [errors, setErrors] = useState({});

	const isFieldUpdated = (key) =>
		updatedFormValues && updatedFormValues[key] !== undefined;

	const handleSubmit = () => {
		const enc_info = _.cloneDeep(encounterDetail);
		const enc_billing_info = {};

		//Patient invoice segment
		const patientFeeRefundDecision =
			updatedFormValues.patient_fee_refund_decision || undefined;
		const patientFeeRefundReason =
			updatedFormValues.patient_fee_refund_reason || null;
		if (
			updatedFormValues &&
			(!_.isEmpty(patientFeeRefundDecision) ||
				_.isNil(patientFeeRefundDecision))
		)
			enc_billing_info['patient_fee_refund_decision'] =
				patientFeeRefundDecision;
		if (isFieldUpdated('billing_status'))
			enc_billing_info['billing_status'] =
				updatedFormValues.billing_status || 'na';
		if (isFieldUpdated('patient_billing_date'))
			enc_billing_info['patient_billing_date'] = !_.isNil(
				updatedFormValues.patient_billing_date
			)
				? moment(updatedFormValues.patient_billing_date || null).format(
						'YYYY-MM-DD'
				  )
				: null;
		if (isFieldUpdated('patient_billing_amount'))
			enc_billing_info['patient_billing_amount'] =
				parseFloat(updatedFormValues.patient_billing_amount || null) !=
				NaN
					? parseFloat(
							updatedFormValues.patient_billing_amount || null
					  )
					: 0.0;
		if (isFieldUpdated('patient_pay'))
			enc_billing_info['patient_pay'] =
				parseFloat(updatedFormValues.patient_pay || null) != NaN
					? parseFloat(updatedFormValues.patient_pay || null)
					: 0.0;
		if (isFieldUpdated('patient_fee_refund'))
			enc_billing_info['patient_fee_refund'] =
				parseFloat(updatedFormValues.patient_fee_refund || null) != NaN
					? parseFloat(updatedFormValues.patient_fee_refund || null)
					: null;
		if (isFieldUpdated('patient_fee_refund_processed_date'))
			enc_billing_info['patient_fee_refund_processed_date'] = !_.isNil(
				updatedFormValues.patient_fee_refund_processed_date
			)
				? moment(
						updatedFormValues.patient_fee_refund_processed_date ||
							null
				  ).format('YYYY-MM-DD')
				: null;
		if (isFieldUpdated('patient_fee_refund_reason'))
			enc_billing_info['patient_fee_refund_reason'] =
				patientFeeRefundReason;
		if (patientFeeRefundReason !== 'other')
			enc_billing_info['patient_fee_refund_reason_other'] = null;
		if (
			isFieldUpdated('patient_fee_refund_reason_other') &&
			patientFeeRefundReason === 'other'
		)
			enc_billing_info['patient_fee_refund_reason_other'] =
				updatedFormValues.patient_fee_refund_reason_other || null;
		if (isFieldUpdated('coupon_code'))
			enc_billing_info['coupon_code'] = updatedFormValues.coupon_code;

		//Insurance billing
		if (isFieldUpdated('insurance_expected_revenue'))
			enc_billing_info['insurance_expected_revenue'] =
				parseFloat(
					updatedFormValues.insurance_expected_revenue || null
				) != NaN
					? parseFloat(
							updatedFormValues.insurance_expected_revenue || null
					  )
					: 0.0;
		if (
			!(
				(initialPaymentMethod == '' ||
					initialPaymentMethod == 'undetermined') &&
				!isFieldUpdated('payment_method')
			)
		)
			enc_billing_info['payment_method'] =
				updatedFormValues.payment_method || initialPaymentMethod;

		//Partner invoice
		if (isFieldUpdated('partner_invoice_status'))
			enc_billing_info['partner_invoice_status'] =
				updatedFormValues.partner_invoice_status || 'na';
		if (isFieldUpdated('partner_invoice_date'))
			enc_billing_info['partner_invoice_date'] = !_.isNil(
				updatedFormValues.partner_invoice_date
			)
				? moment(updatedFormValues.partner_invoice_date || null).format(
						'YYYY-MM-DD'
				  )
				: '';
		if (isFieldUpdated('partner_invoice_rate'))
			enc_billing_info['partner_invoice_rate'] =
				parseFloat(updatedFormValues.partner_invoice_rate || null) !=
				NaN
					? parseFloat(updatedFormValues.partner_invoice_rate || null)
					: 0.0;

		//Billing notes
		if (isFieldUpdated('billing_notes'))
			enc_billing_info['billing_notes'] =
				updatedFormValues.billing_notes || '';

		setLoading(true);
		saveEncounterValues(enc_info, enc_billing_info)
			.then(() => {
				setTimeout(() => {
					handleToggleEdit();
					setLoading(false);
					setErrors({});
				}, 2000);
			})
			.catch((error) => {
				if (
					error.response?.data?.code === 422 &&
					error.response?.data?.data.message ===
						'Coupon code could not be found.'
				) {
					setErrors({
						...errors,
						coupon_code:
							'Coupon with specified code could not be found',
					});
				}
				setLoading(false);
			});
	};

	const handleUpdatedValues = (key, val) => {
		setUpdatedFormValues((state) => ({
			...state,
			[key]: val,
		}));
	};

	const handleChange = (key, val) => {
		setEncounterBillingDetailState((state) => ({
			...state,
			[key]: val,
		}));
		handleUpdatedValues(key, val);
	};

	return (
		<>
			<Loading loading={loading} className='loading-referral-billing'>
				<div className='body'>
					<EditPatientInvoiceSegment
						{...{
							billingStatusEnums,
							encounterBillingDetail,
							encounterDetail,
							errors,
							handleChange,
							isBillingCoordinator,
							patientFeeEnum,
							stateData: {
								billingStatus:
									encounterBillingDetailState.billing_status,
								couponCode:
									encounterBillingDetailState.coupon_code,
								patientFeeRefundReason:
									encounterBillingDetailState.patient_fee_refund_reason,
								patientBillingAmount:
									encounterBillingDetailState.patient_billing_amount,
								patientBillingDate:
									encounterBillingDetailState.patient_billing_date,
								patientFeeRefundDecision:
									encounterBillingDetailState.patient_fee_refund_decision,
								patientFeeRefundProcessedDate:
									encounterBillingDetailState.patient_fee_refund_processed_date,
								patientFeeRefundReasonOther:
									encounterBillingDetailState.patient_fee_refund_reason_other,
								patientPay:
									encounterBillingDetailState.patient_pay,
							},
						}}
					/>
					<EditInsuranceBillingSegment
						{...{
							encounterBillingDetail,
							encounterDetail,
							handleChange,
							referralPrograms,
							patientDetail,
							stateData: {
								insuranceExpectedRevenue:
									encounterBillingDetailState.insurance_expected_revenue,
								paymentMethod:
									encounterBillingDetailState.payment_method,
							},
						}}
					/>
					<EditPartnerInvoiceSegment
						{...{
							handleChange,
							partnerInvoiceEnums,
							stateData: {
								partnerInvoiceDate:
									encounterBillingDetailState.partner_invoice_date,
								partnerInvoiceRate:
									encounterBillingDetailState.partner_invoice_rate,
								partnerInvoiceStatus:
									encounterBillingDetailState.partner_invoice_status,
							},
						}}
					/>
					<EditBillingNotesSegment
						{...{
							handleChange,
							stateData: {
								billingNotes:
									encounterBillingDetailState.billing_notes,
							},
						}}
					/>
					<Layout.Row className='action-buttons'>
						<Layout.Col span='21'>
							<Button
								className='cancel-button'
								onClick={handleToggleEdit}
							>
								Cancel
							</Button>
						</Layout.Col>
						<Layout.Col span='3'>
							<Button
								className='save-button'
								disabled={_.isEmpty(updatedFormValues)}
								onClick={handleSubmit}
							>
								Save
							</Button>
						</Layout.Col>
					</Layout.Row>
				</div>
			</Loading>
		</>
	);
};

export default EditBillingSegment;
