import * as actionTypes from '../../actions/actionTypes';

export const defaultState = {
	outreachEnums: undefined,
	loading_outreachList: false,
	outreachList: undefined,
	outreachSetting: undefined,
	loading_outrechSetting: false,
};

export default (state = defaultState, action = {}) => {
	switch (action.type) {
		case actionTypes.LOADING_OUTREACH_ENUMS:
			return {
				...state,
				outreachEnumsLoading: true,
				outreachEnumsError: null,
			};

		case actionTypes.OUTREACH_ENUMS:
			return {
				...state,
				outreachEnums: action.payload,
				outreachEnumsLoading: false,
				outreachEnumsError: null,
			};

		case actionTypes.OUTREACH_ENUMS_ERROR:
			return {
				...state,
				outreachEnums: null,
				outreachEnumsLoading: false,
				outreachEnumsError: action.payload,
			};

		case actionTypes.GETTING_OUTREACH_DECLINED_ENUM:
			return {
				...state,
				outreachDeclinedEnumLoading: true,
				outreachDeclinedEnum: null,
				outreachDeclinedEnumError: null,
			};

		case actionTypes.GOT_OUTREACH_DECLINED_ENUM:
			return {
				...state,
				outreachDeclinedEnumLoading: false,
				outreachDeclinedEnum: action.payload,
				outreachDeclinedEnumError: null,
			};

		case actionTypes.GOT_OUTREACH_DECLINED_ENUM_ERROR:
			return {
				...state,
				outreachDeclinedEnumLoading: false,
				outreachDeclinedEnum: null,
				outreachDeclinedEnumError: action.payload,
			};

		case actionTypes.LOAD_OUTREACHS_START:
			return {
				...state,
				loading_outreachList: true,
			};

		case actionTypes.LOAD_OUTREACHS_SUCCESS:
			return {
				...state,
				loading_outreachList: false,
				outreachList: action.payload,
			};

		case actionTypes.LOAD_USERS_FAILURE:
			return {
				...state,
				loading_outreachList: false,
			};

		case actionTypes.RESET_OUTREACHS:
			return {
				...state,
				loading_outreachList: false,
				outreachList: undefined,
			};

		case actionTypes.ADDING_OUTREACH:
			return {
				...state,
				addOutreachLoading: true,
				addedOutreach: null,
				addedOutreachError: null,
			};

		case actionTypes.ADDED_OUTREACH_SUCCESS:
			return {
				...state,
				addOutreachLoading: false,
				addedOutreach: action.payload,
				addedOutreachError: null,
			};

		case actionTypes.ADDED_OUTREACH_FAILURE:
			return {
				...state,
				addOutreachLoading: false,
				addedOutreach: null,
				addedOutreachError: action.payload,
			};

		case actionTypes.SETTING_DO_NOT_OUTREACH:
			return {
				...state,
				settingDoNotOutreach: true,
				setDoNotOutreach: null,
				setDoNotOutreachError: null,
			};

		case actionTypes.SET_DO_NOT_OUTREACH:
			return {
				...state,
				settingDoNotOutreach: false,
				setDoNotOutreach: action.payload,
				setDoNotOutreachError: null,
			};

		case actionTypes.SET_DO_NOT_OUTREACH_ERROR:
			return {
				...state,
				settingDoNotOutreach: false,
				setDoNotOutreach: null,
				setDoNotOutreachError: action.payload,
			};

		case actionTypes.LOAD_OUTREACH_SETTING_START:
			return {
				...state,
				loading_outrechSetting: true,
				outreachSetting: null,
				outreachSettingError: null,
			};

		case actionTypes.LOAD_OUTREACH_SETTING_SUCCESS:
			return {
				...state,
				loading_outrechSetting: false,
				outreachSetting: action.payload,
				outreachSettingError: null,
			};

		case actionTypes.LOAD_OUTREACH_SETTING_FAILURE:
			return {
				...state,
				loading_outrechSetting: false,
				outreachSetting: null,
				outreachSettingError: action.payload,
			};

		case actionTypes.RESET_OUTREACH_SETTING:
			return {
				...state,
				loading_outrechSetting: false,
				outreachSetting: undefined,
			};

		case actionTypes.GETTING_PATIENT_OUTREACH_ROUNDS:
			return {
				...state,
				patientOutreachRoundsLoading: true,
				patientOutreachRounds: null,
				patientOutreachRoundsError: null,
			};

		case actionTypes.GOT_PATIENT_OUTREACH_ROUNDS_SUCCESS:
			return {
				...state,
				patientOutreachRoundsLoading: false,
				patientOutreachRounds: action.payload,
				patientOutreachRoundsError: null,
			};

		case actionTypes.GOT_PATIENT_OUTREACH_ROUNDS_FAILURE:
			return {
				...state,
				patientOutreachRoundsLoading: false,
				patientOutreachRounds: null,
				patientOutreachRoundsError: action.payload,
			};

		default:
			return state;
	}
};
