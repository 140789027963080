//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import warningImg from '../../../../assets/milestone-warning.svg';
import checkImg from '../../../../assets/green-check.svg';

//Lodash
import { isEmpty } from 'lodash';

//Utils
import { renderFileIcon } from '../../../../Utils/documentUtils';

//Other Libraries
import classnames from 'classnames';

//Components
import './DocumentNotice.css';

const DocumentNotice = (props) => {
	const {
		document: {
			document_subtype,
			document_type,
			file_extension_id,
			file_name,
		},
		handleDownload,
		icon,
		message,
		theme = 'default',
		visible,
	} = props;

	const documentTypeDisplayName = document_type?.display_name || '';
	const documentSubtypeDisplayName = document_subtype?.display_name || '';

	return (
		<div className={classnames('documentNoticeContainer', theme)}>
			<div className='documentFileBody'>
				<div className='documentFile'>
					<span className='fileIcon'>
						{<ReactSVG src={renderFileIcon(file_extension_id)} />}
					</span>
					<span className='fileName'>
						{(file_name || '').replace(/\.[^/.]+$/, '')}
					</span>
				</div>
				{(documentSubtypeDisplayName || documentTypeDisplayName) && (
					<div className='fileType'>
						{!isEmpty(documentSubtypeDisplayName)
							? documentSubtypeDisplayName
							: documentTypeDisplayName}
					</div>
				)}
				{handleDownload && (
					<div
						className='fileDownload'
						data-testId='file-download'
						onClick={handleDownload}
					>
						Download Document
					</div>
				)}
			</div>
			{visible && !isEmpty(message) && (
				<div className='documentNoticeBody'>
					<div className='documentNoticeIcon'>
						<ReactSVG
							src={icon == 'check' ? checkImg : warningImg}
						/>
					</div>
					<div className='documentNoticeText'>{message}</div>
				</div>
			)}
		</div>
	);
};

export default DocumentNotice;
