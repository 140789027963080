//React & Redux
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../../../assets/exit-alert.svg';

//Lodash
import { isEmpty, isNil } from 'lodash';

//Actions and Services
import {
	getDocumentAuditTrail,
	retractDocument,
} from '../../../../actions/documents';

//UI Libraries
import { Loading } from 'gm-element-react';

// Utils
import { CARE_PATIENTS_PATIENT_DOCUMENT_AUDIT_TRAIL_RETRACT } from '../../../../Utils/permissionUtils';

//Components
import RetractDocument from './RetractDocument';
import RBAC from '../../../RBAC';

const documentSubtype = {
	'visit summary': 1,
	'results summary': 1,
	other: 1,
};

export const AuditTrail = (props) => {
	const {
		actions: { getDocumentAuditTrail, retractDocument },
		document: { document_type, uuid: documentUuid },
		hideAuditTrail,
		loaded,
		loading = false,
		logs = [],
		patientUUID,
		refreshDocumentList,
	} = props;

	useEffect(() => {
		getDocumentAuditTrail(patientUUID, documentUuid);
	}, []);

	const getLogs = () => {
		const sortedLogs = logs.sort((a, b) => {
			const logA = new Date(a.original.created_at);
			const logB = new Date(b.original.created_at);
			return logB - logA;
		});

		return !loading && loaded && sortedLogs.length > 0 ? sortedLogs : [];
	};

	const handleRetract = () => {
		retractDocument(patientUUID, documentUuid).then(() =>
			refreshDocumentList()
		);
	};

	const renderBy = (log) => {
		if (log.by === 'system') {
			if (log.original.type.name === 'shared_to_default_doc_recipient') {
				return 'System';
			} else if (document_type?.name || '' === 'visit_documentation') {
				return 'AUTOGEN/GCN';
			}
		}
		return log.by;
	}

	return (
		<div className='fullModalBackground'>
			<div className='auditTrailContainer'>
				<Loading className='loading' loading={loading}>
					<div className='topRow'>
						<div className='header'>Audit Trail</div>
						<div className='close'>
							<ReactSVG
								src={xIcon}
								style={{
									position: 'relative',
									bottom: '2px',
									right: '1.25px',
								}}
								onClick={hideAuditTrail}
							/>
						</div>
					</div>
					<div className='content'>
						{getLogs().map((log) => {
							return (
								<div className='log' key={log.created_at}>
									<div className='title'>
										{(log.original?.medium?.name || '') ===
										'api'
											? 'Released to Partner via API'
											: log.title}{' '}
										{!isEmpty(log.to) &&
											log.merge_to_with_title && (
												<span>{log.to}</span>
											)}
									</div>
									{!isEmpty(log.to) &&
										!log.merge_to_with_title && (
											<div>To {log.to}</div>
										)}
									{!isEmpty(log.email) && (
										<div>{log.email}</div>
									)}
									{!isEmpty(log.fax) && <div>{log.fax}</div>}
									<div>By {renderBy(log)}</div>
									<div>On {log.date}</div>
									{!isNil(log?.original?.old_encounter) &&
										!isNil(
											log?.original?.new_encounter
										) && (
											<>
												<div className='modified-encounter'>
													<span>Old Encounter: </span>
													{
														log?.original
															?.old_encounter
													}{' '}
												</div>
												<div className='modified-encounter'>
													<span>New Encounter: </span>
													{
														log?.original
															?.new_encounter
													}
												</div>
											</>
										)}
									{!isNil(log?.original?.new_description) && (
										<>
											<div className='modified-description'>
												<span>Old Description: </span>
												{
													log?.original
														?.old_description
												}{' '}
											</div>
											<div className='modified-description'>
												<span>New Description: </span>
												{log?.original?.new_description}
											</div>
										</>
									)}
									{((!isNil(
										log?.original?.document_type?.old_type
									) &&
										!isNil(
											log?.original?.document_type
												?.new_type
										)) ||
										(!isNil(
											log?.original?.document_type
												?.old_subtype
										) &&
											!isNil(
												log?.original?.document_type
													?.new_subtype
											))) && (
										<>
											<div className='modified-document-type'>
												<span>
													Old Document Type:&nbsp;
												</span>
												{(
													log?.original?.document_type
														?.old_subtype || ''
												).toLowerCase() in
												documentSubtype
													? 'Visit documentation'
													: log?.original
															?.document_type
															?.old_type}{' '}
												{!isNil(
													log?.original?.document_type
														?.old_subtype
												) && (
													<>
														<span>&gt;&gt;</span>{' '}
														<span>
															{
																log?.original
																	?.document_type
																	?.old_subtype
															}
														</span>
													</>
												)}
											</div>
											<div className='modified-document-type'>
												<span>
													New Document Type:&nbsp;
												</span>
												{(
													log?.original?.document_type
													?.new_subtype || ''
												).toLowerCase() in
												documentSubtype
													? 'Visit documentation'
													: log?.original
															?.document_type
															?.new_type}{' '}
												{!isNil(
													log?.original?.document_type
														?.new_subtype
												) && (
													<>
														<span>&gt;&gt;</span>{' '}
														<span>
															{
																log?.original
																	?.document_type
																	?.new_subtype
															}
														</span>
													</>
												)}
											</div>
										</>
									)}
									{log.status === 'success' &&
										log.can_be_retracted && (
											<RBAC
												action={
													CARE_PATIENTS_PATIENT_DOCUMENT_AUDIT_TRAIL_RETRACT
												}
												yes={
													<RetractDocument
														handleRetract={
															handleRetract
														}
													/>
												}
											/>
										)}
									{log.status === 'failure' && (
										<div className='status'>Failed</div>
									)}
								</div>
							);
						})}
					</div>
				</Loading>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	loaded: state.documents.auditTrailLoaded,
	loading: state.documents.auditTrailLoading,
	logs: state.documents.auditTrail,
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(
		{
			getDocumentAuditTrail,
			retractDocument,
		},
		dispatch
	),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditTrail);
