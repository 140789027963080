//React & Redux
import React, { useState, useEffect } from 'react';

//UI Libraries
import { Tabs } from 'gm-element-react';

//Other Libraries
import moment from 'moment';
import archiveicon from '../../../assets/archive.svg';
import alertIcon from '../../../assets/alert.svg';
import ReactSVG from 'react-svg';

const NotesList = (props) => {
	const {
		notes = [],
		deleteNoteForPatient,
		deleteNoteSuccess,
		deleteLoadingNotes,
		deleteNoteError,
		enqueueSnackbar,
		currentNoteTypeView,
		setCurrentNoteTypeView,
		notesEnum,
		noteCounts,
		currentLoggedInUser,
	} = props;

	const [isReadMore, setIsReadMore] = useState({});
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [noteToDelete, setNoteToDelete] = useState(null);
	const [modalState, setModalState] = useState({});
	
	const handleShowDeleteModal = (note) => {
		setNoteToDelete(note);
		setShowDeleteModal(true);
		setModalState({
			modalHeader: (
				<span className="modal-header-content">
					<ReactSVG 
						src={alertIcon} 
						style={{ 
							display: 'inline-block',
							marginRight: '8px',
							verticalAlign: 'middle'
						}} 
					/>
					<span style={{ verticalAlign: 'middle' }}>Delete Note?</span>
				</span>
			),
			modalDescription: 'You are about to permanently delete this note. The action is not reversible.',
			modalHeight: 190,
		});
	};

	const handleCancelModal = () => {
		setShowDeleteModal(false);
		setModalState({});
		setNoteToDelete(null);
	};

	const handleHideModal = () => {
		if (noteToDelete) {
			enqueueSnackbar('Note deleted successfully', {
				variant: 'success',
				anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
			})
		}
		setShowDeleteModal(false);
		setModalState({});
		setNoteToDelete(null);
	};

	const handleDeleteConfirm = async () => {
		try {
			await deleteNoteForPatient(noteToDelete.uuid, noteToDelete.patient_uuid);
		} catch (error) {
			enqueueSnackbar('Failed to delete note', {
				variant: 'error',
				anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
			});
		}
	};

	const renderModal = () => {
		if (showDeleteModal) {
			const { modalHeader, modalDescription, modalHeight } = modalState;
			return (
				<div className="popupModal">
					<div
						className="documentsTabModalContainer"
						style={{ height: `${modalHeight}px` || '175px' }}
					>
						<div className="documentsTabModalHeaderRow">
							<span style={{ position: 'relative', bottom: '2px' }}>
								{modalHeader}
							</span>
						</div>
						<div className="documentsTabModalDescription">
							{modalDescription}
						</div>
						<div className="documentsTabModalButtonRow">
							<div
								className="documentsTabModalCancelButton"
								onClick={handleCancelModal}
							>
								Cancel
							</div>
							<div
								className="archivePopupButton"
								onClick={handleDeleteConfirm}
							>
								<ReactSVG
									src={archiveicon}
									style={{
										position: 'relative',
										bottom: '6px',
										right: '4px',
									}}
								/>
								Confirm
							</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};

	const renderTabHeaders = () => {
		const tabs = notesEnum.map((noteObj, i) => {
			const tabName = noteObj.display_name || '--';
			const noteInternalValue = noteObj.key;
			const relevantCount = noteCounts[noteInternalValue];
			const noteCount =
				typeof relevantCount === 'number' ? relevantCount : '--';
			return (
				<Tabs.Pane
					className={
						'tabhead' +
						(currentNoteTypeView == noteInternalValue
							? ' active'
							: ' non-active')
					}
					label={
						<span className={'tab_labels '}>
							{`${tabName}s (${noteCount})`}{' '}
						</span>
					}
					name={noteInternalValue}
				/>
			);
		});

		return tabs;
	};

	const readMore = (uuid) => {
		setIsReadMore((state) => ({
			...state,
			[uuid]: true,
		}));
	};

	const canDeleteNote = (note) => {
		// Check if the current user is the author of the note
		return currentLoggedInUser === note.author;
	};

	const getNotes = notes.map((note = {}) => {
		const {
			author = '',
			date_created: dateCreated = '',
			note: noteContent = '',
			uuid = '',
		} = note;

		return (
			<section key={uuid} className='note-list-card'>
				<div className='note-meta-data'>
					<span className='note-email'>{author}</span>
					<div className='note-date'>
						{moment(dateCreated).format('MMM DD, YYYY')}
					</div>
					<span className='note-time'>
						{moment(dateCreated).format('LT')}
					</span>
				</div>
				{!isReadMore[uuid] && noteContent.length > 248 ? (
					<div className='note-detail'>
						{noteContent.substring(0, 248)}
						<span
							data-testId='read-more'
							onClick={() => readMore(uuid)}
							className='read-more'
						>
							Read more...
						</span>
					</div>
				) : (
					<div className='note-detail'>{noteContent}</div>
				)}
				{canDeleteNote(note) && (
					<button
						onClick={() => handleShowDeleteModal(note)}
						aria-label="Delete note"
						className="delete-button"
						>
						<svg 
							width="16" 
							height="16" 
							viewBox="0 0 24 24" 
							style={{ display: 'inline-block' }}
						>
							<path 
							d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
							fill="none" 
							stroke="currentColor" 
							strokeWidth="2" 
							strokeLinecap="round" 
							strokeLinejoin="round"
							/>
						</svg>
						</button>
					)}
			</section>
		);
	});

	useEffect(() => {
		if (deleteNoteSuccess && !deleteLoadingNotes) {
			handleHideModal();
		} else if (deleteNoteError) {
			enqueueSnackbar(
				deleteNoteError.response?.data?.message ||
					'Error deleting note',
				{
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				}
			);
		}
	}, [deleteNoteError, deleteLoadingNotes, deleteNoteSuccess]);

	return (
		<>
			<div className='note-list-container'>
				<header className='note-list-title'>
					<Tabs
						value={currentNoteTypeView}
						textColor='primary'
						className='patient_notes_tabs'
						fullWidth={false}
						id='encounter_tabs'
						variant='scrollable'
						onTabClick={(tab) => {
							const value = tab.props?.name || 0;
							setCurrentNoteTypeView(value);
						}}
					>
						{renderTabHeaders()}
					</Tabs>
				</header>
				{notes.length ? (
					getNotes
				) : (
					<div className='no_data_found'>No notes found</div>
				)}
			</div>
			{renderModal()}
		</>
	);
};

export default NotesList;
