//React & Redux
import React, { useRef } from 'react';

//Lodash
import get from 'lodash/get';

//UI Libraries
import { Checkbox } from 'gm-element-react';

//Components
import CommonCheckbox from './../../Common/Checkbox.js';

const TagSearchResultsRow = (props) => {
	const row = useRef(null);

	const { icdCode = {}, isSelected = false, toggleCodeSelection } = props;

	const checkboxClass = isSelected
		? 'chargeICDSearchResultRowCheckbox chargeICDSearchResultRowCheckboxSelected'
		: 'chargeICDSearchResultRowCheckbox';
	const textClass = isSelected ? ' chargeICDSearchResultRowSelectedText' : '';
	return (
		<div
			className='chargeICDSearchResultRow'
			ref={row}
			onClick={(e) => {
				e.preventDefault();
				toggleCodeSelection(
					!isSelected,
					get(icdCode, 'uuid', null),
					icdCode
				);
			}}
		>
			<CommonCheckbox checked={isSelected} />
			<div className={'chargeICDSearchResultRowTitle' + textClass}>
				{get(icdCode, 'display_name', '--')}
			</div>
		</div>
	);
};

export default TagSearchResultsRow;
