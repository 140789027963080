import moment from 'moment-timezone';

export function formToRescheduleApiPayload(input) {
	return {
		by_phone: false,
		provider_id: input.serviceInfoAndScheduling.provider,
		reschedule_reason: input.rescheduleReason,
		start_time: moment(input.serviceInfoAndScheduling.timeslot).tz(
			input.serviceInfoAndScheduling.timezone
		),
		state: input.serviceInfoAndScheduling.state,
		timezone: input.serviceInfoAndScheduling.timezone,
		vsee_specialty: input.serviceInfoAndScheduling.specialty,
	};
}

/**
 * Convert form values to encounter edit payload
 * @param {FormValues} input
 * @returns {object} Encounter edit payload details
 */
export function formToEditEncounterApiPayload(input) {
	return {
		additional_conference_line:
			input.serviceInfoAndScheduling.additionalConferenceLine || null,
	};
}
