//React & Redux
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

//Lodash
import _, { get, find, has, isEmpty, uniq, flatten, values } from 'lodash';

//Utils
import {
	RemoveUnderscoreAndCapitalizeFirstLetter,
	statesTimezones,
	returnCcGcaCount,
	RemoveUnderscoreAndCapitalizeInString,
	UTCToCurrentView,
	getHomeAddress,
	getFromEnumOrFormat,
} from '../../utils';
import {
	isPartnerInitiatedPreTest,
	isPartnerInitiatedUnsolicitedResult,
} from '../Patients/V2/Encounters/Common/utilsfunctions';

//Actions and Services
import {
	getDashboardReferralProgram_V2,
	getDashboardRoROutreachPending,
	dashboardRoROutreachPendingReport,
	dispatch_DashboardReferralProgramReset,
	dispatch_DashboardRoROutreachPendingReset,
	getdashboardattempts,
	getActiveGCAs,
	getGCAAssignments,
	assignCCOrGCA,
	getspecificencounterenum,
} from '../../actions/dashboard';
import {
	getOutreachEnums,
	addOutreachUponCreate,
} from '../../actions/patient/outreach';
import { getContactMethods, getContactOutcomes } from '../../actions/patients';
import { getProviderSpecialties } from '../../actions/providers';
import {
	followUpDeclinedOutreach,
	getTestOrderRequest,
	resetTestOrder,
	getGeneTestEnums,
} from '../../actions/encounter';
import { setFilter } from '../../actions/appData';

//UI Libraries
import { Loading, Checkbox } from 'gm-element-react';
import Clock from '../Common/LiveClock';
import classNames from 'classnames';

//Other Libraries
import moment from 'moment';

//Components
import ProviderInfo from '../Common/ProviderInfo';
import CreateRoROutreachModal from './OutreachPopUp/CreateRoROutreachModal';
import MultiSelectCheckbox from '../Filter/MultiSelectCheckbox.js';
import ResetFilters from './../Filter/ResetFilters';
import CommonCheckbox from '../Common/Checkbox.js';
import { Table, Thead, Tbody, Tr, Th, Td } from '../Common/Table';
import TextWithTooltip from '../Common/TextWithTooltip';
import AssignModal from './AssignModal';
import CCGCAAssignBadges from './CCGCAAssignBadges';

//Styles
import './ror-outreach-card.css';
class DashboardRoROutReach extends Component {
	constructor(props) {
		super(props);
		const location_state = this.getLocationState();
		this.state = {
			orderBy: get(location_state, 'orderBy', 'call_attempts')
				? get(location_state, 'orderBy', 'call_attempts')
				: 'call_attempts',
			order: get(location_state, 'order', 'desc')
				? get(location_state, 'order', 'asc')
				: 'asc',
			activePage: has(location_state, 'activePage')
				? get(location_state, 'activePage', 0)
				: 0,
			initialLoad: false,
			referral_program_id: get(location_state, 'referral_program_id', []),
			attempts: get(location_state, 'attempts', []) || [],
			showCreateOutreachModal: get(
				location_state,
				'showCreateOutreachModal',
				false
			),
			selectedPatient: get(location_state, 'selectedPatient', undefined),
			idx: get(location_state, 'idx', '0'),
			referral_program_display:
				get(location_state, 'referral_program_display', null) || [],
			assigned_gca_uuid: location_state?.assigned_gca_uuid || [],
			gcaAssignments: [],
			referralProgramsEnum: [],
			callAttemptsEnum: [],
			userUuids: {},
			limit: get(this, 'props.limit', 25),
			filterList: 'gca',
			showAssignModal: false,
			selectedAssignee: [],
		};
		this.getFilter = this.getFilter.bind(this);
		this.closeDialogOutsideClick = this.closeDialogOutsideClick.bind(this);
		this.createOutreachRedirectToScheduling =
			this.createOutreachRedirectToScheduling.bind(this);
		this.getRecordCheckState = this.getRecordCheckState.bind(this);
		this.onSelectRowClick = this.onSelectRowClick.bind(this);
		this.onSelectAllClick = this.onSelectAllClick.bind(this);
		this.getSelectedListEmptyStatus =
			this.getSelectedListEmptyStatus.bind(this);
		this.resetFilters = this.resetFilters.bind(this);
		this.filterGCAAssignment = this.filterGCAAssignment.bind(this);
		this.reassignGCA = this.reassignGCA.bind(this);
		this.getUnassignedCount = this.getUnassignedCount.bind(this);
		this.formulateGCAAssignmentEnum =
			this.formulateGCAAssignmentEnum.bind(this);
		this.formulateAssignmentEnum = this.formulateAssignmentEnum.bind(this);
		this.formulateReferralProgramsEnum =
			this.formulateReferralProgramsEnum.bind(this);
		this.formulateCallAttemptsEnum =
			this.formulateCallAttemptsEnum.bind(this);
		this.getActiveAssignments = this.getActiveAssignments.bind(this);
		this.getInactiveAssignments = this.getInactiveAssignments.bind(this);
		this.handleFollowUpDeclinedOutreach =
			this.handleFollowUpDeclinedOutreach.bind(this);
		this.handleResultsPerPageChange =
			this.handleResultsPerPageChange.bind(this);
		this.handleChangeList = this.handleChangeList.bind(this);
		this.handleSort = this.handleSort.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
	}

	static propTypes = {
		auth: PropTypes.object,
	};

	componentDidMount() {
		const location_state = this.getLocationState();
		this.props.getDashboardPatientsReferralPrograms();
		this.props.getDashboardOutreachAttempts();
		if (
			!(
				get(this, 'props.currentRole', null) === 'GCA' &&
				get(this, 'props.currentUserUuid', null)
			) ||
			get(location_state, 'assigned_gca_uuid', null)
		) {
			this.props.getDashboardPatients({
				offset: get(this, 'state.activePage', 0),
				filter: get(this, 'props.history.location.search', ''),
				order_by: get(this, 'state.orderBy'),
				order_type: get(this, 'state.order'),
				referral_program: get(
					this,
					'state.referral_program_display',
					null
				),
				call_attempts: get(this, 'state.attempts', null),
				assigned_gca_uuid: get(this, 'state.assigned_gca_uuid', null),
				limit: get(this, 'props.limit', 25),
			});
		}
		this.props.callVisitProviderEnum({
			type: 'visit',
			field_name: 'visit_provider',
		});

		document
			.getElementById('root')
			.addEventListener('click', this.closeDialogOutsideClick, {
				capture: true,
			});
		this.props.getContactMethods();
		this.props.getContactOutcomes();
		this.props.callGeneTestEnums();
		this.props.getOutreachEnums();
		this.props.getActiveGCAList();
		this.props.getAllProviderSpecialties();

		const locationState = this.getLocationState();
		let locationData = false;

		if (has(locationState, 'assigned_gca_uuid')) {
			this.filterGCAAssignment(locationState.assigned_gca_uuid || null);
			locationData = true;
		}

		const { currentRole, currentUserUuid } = this.props;

		if (
			!locationData &&
			(currentRole || null) === 'GCA' &&
			(currentUserUuid || null) &&
			!get(locationState, 'assigned_gca_uuid', null)
		) {
			this.filterGCAAssignment(
				currentUserUuid ? [currentUserUuid] : null
			);
		}
	}

	componentDidUpdate(prevProps) {
		if (
			get(prevProps, 'addOutreachLoading', false) &&
			!get(this, 'props.addOutreachLoading', false)
		) {
			if (!get(this, 'props.addedOutreachError', null)) {
				this.props.getDashboardPatients({
					offset: get(this, 'state.activePage', 0),
					filter: get(this, 'props.history.location.search', ''),
					order_by: get(this, 'state.orderBy'),
					order_type: get(this, 'state.order'),
					referral_program: get(
						this,
						'state.referral_program_display',
						null
					),
					call_attempts: get(this, 'state.attempts', null),
					assigned_gca_uuid: get(
						this,
						'state.assigned_gca_uuid',
						null
					),
					limit: get(this, 'props.limit', 25),
				});
			}
		}

		if (
			get(prevProps, 'dashboardPatientsLoading', false) &&
			!get(this, 'props.dashboardPatientsLoading', false)
		) {
			this.getAssignedGCAList();
			this.props.setCardFilter(this.getFilter());
		}

		if (
			get(prevProps, 'assigningCCOrGCA', false) &&
			!get(this, 'props.assigningCCOrGCA', false)
		) {
			if (get(this, 'props.assignedCCOrGCAError', false)) {
				this.props.enqueueSnackbar('Error in assigning CC/GCA', {
					variant: 'error',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
			} else {
				this.setState({ isItemSelected: {}, userUuids: {} });
				this.filterGCAAssignment(
					get(this, 'state.assigned_gca_uuid', null)
				);
			}
		}

		if (
			get(prevProps, 'gcaAssignmentsLoading', false) &&
			!get(this, 'props.gcaAssignmentsLoading', false)
		) {
			if (!get(this, 'props.gcaAssignmentsError', false)) {
				this.formulateAssignmentEnum('gca');
			}
		}

		if (
			get(prevProps, 'referralProgramsLoading', false) &&
			!get(this, 'props.referralProgramsLoading', false)
		) {
			if (!get(this, 'props.referralProgramsError', false)) {
				this.formulateReferralProgramsEnum();
				this.formulateCallAttemptsEnum();
			}
		}
	}

	componentWillUnmount() {
		this.props.resetDashboardOutreachPending();
		this.props.resetDashboardReferralProgram();
		document
			.getElementById('root')
			.removeEventListener('click', this.closeDialogOutsideClick);
	}

	closeDialogOutsideClick(e) {
		if (
			document.getElementById('tooltip_div') != null &&
			!document.getElementById('tooltip_div').contains(e.target)
		) {
			if (
				this.state.tooltipVisible &&
				this.state.tooltipVisible == true
			) {
				this.setState({ tooltipVisible: false });
			}
		}
	}

	getLocationState = () => {
		return get(this, 'props.cardFilter', null) || {};
	};

	getFilter() {
		return {
			activePage: get(this, 'state.activePage', null),
			attempts: get(this, 'state.attempts', null),
			referral_program_display: get(
				this,
				'state.referral_program_display',
				null
			),
			referral_program_id: get(this, 'state.referral_program_id', null),
			assigned_gca_uuid: get(this, 'state.assigned_gca_uuid', null),
			order: get(this, 'state.order', null),
			orderBy: get(this, 'state.orderBy', null),
			showCreateOutreachModal: get(
				this,
				'state.showCreateOutreachModal',
				null
			),
			selectedPatient: get(this, 'state.selectedPatient', null),
			idx: get(this, 'state.idx', null),
		};
	}

	getAssignedGCAList() {
		const payload = {
			filter: 'ror_outreach_pending',
			referral_program: get(this, 'state.referral_program_display', null),
			call_attempts: get(this, 'state.attempts', null),
		};

		this.props.getGCAAssignmentList(payload);
	}

	formulateAssignmentEnum(type) {
		let assignmentEnum = [];
		let stateProp;

		switch (type) {
			case 'gca':
				stateProp = 'gcaAssignments';
				break;
			default:
				break;
		}

		assignmentEnum.push({
			display_name: `Unassigned (${this.getUnassignedCount(type)})`,
			key: 'null',
		});

		assignmentEnum.push({
			groupingLabel: 'ACTIVE',
		});
		const activeAssignments = this.getActiveAssignments(type).map((el) => {
			return {
				display_name: `${
					get(el, 'name', '--') || '--'
				} (${returnCcGcaCount(el)})`,
				key: get(el, 'uuid', null),
			};
		});
		assignmentEnum = assignmentEnum.concat(activeAssignments);

		assignmentEnum.push({
			groupingLabel: 'INACTIVE',
		});
		const inactiveAssignments = this.getInactiveAssignments(type).map(
			(el) => {
				return {
					display_name: `${
						get(el, 'name', '--') || '--'
					} (${returnCcGcaCount(el)})`,
					key: get(el, 'uuid', null),
				};
			}
		);
		assignmentEnum = assignmentEnum.concat(inactiveAssignments);
		this.setState({ [stateProp]: assignmentEnum });
	}

	formulateReferralProgramsEnum() {
		let formulatedRefProgEnum = [];
		const referralProgramsEnum =
			get(this, 'props.referralPrograms.data', []) || [];

		if (Array.isArray(referralProgramsEnum)) {
			formulatedRefProgEnum = referralProgramsEnum.map((el) => {
				return {
					display_name: get(el, 'display_name', null),
					key: get(el, 'display_name', null),
				};
			});
		}
		this.setState({ referralProgramsEnum: formulatedRefProgEnum });
	}

	formulateCallAttemptsEnum() {
		let formulatedCallAttemptsEnum = [];
		const callAttemptsEnum =
			get(this, 'props.dashboardoutreachattempts', []) || [];

		if (Array.isArray(callAttemptsEnum)) {
			formulatedCallAttemptsEnum = callAttemptsEnum
				.filter((callObj) => get(callObj, 'value', null) !== 'All')
				.map((el) => {
					return {
						display_name: get(el, 'label', null),
						key: get(el, 'value', null),
					};
				});
		}
		this.setState({ callAttemptsEnum: formulatedCallAttemptsEnum });
	}

	getRecordCheckState(id, all_selected) {
		const checked_record = _.find(all_selected, function (o) {
			return o == id;
		});

		return checked_record ? true : false;
	}

	onSelectRowClick(evt, i, user_uuid) {
		const curr_page = get(this, 'state.activePage', 0);
		const current_selected_items = get(this, 'state.isItemSelected', {});
		let isItemSelected = get(
			this,
			['state', 'isItemSelected', curr_page],
			[]
		);
		if (isItemSelected.indexOf(i) == -1) {
			isItemSelected.push(i);
		} else {
			isItemSelected = _.remove(isItemSelected, function (n) {
				return n != i;
			});
		}
		current_selected_items[curr_page] = isItemSelected;

		this.setState({
			isItemSelected: current_selected_items,
		});

		const currentUserUuids = get(this, 'state.userUuids', {});
		const isUuidSelected = get(this, ['state', 'userUuids', curr_page], []);
		if (evt) {
			isUuidSelected.push(user_uuid);
		} else {
			let removal = false;
			_.remove(isUuidSelected, function (n) {
				if (!removal && n === user_uuid) {
					removal = true;
					return true;
				}
			});
		}
		currentUserUuids[curr_page] = isUuidSelected;
		this.setState({
			userUuids: currentUserUuids,
		});
	}

	onSelectAllClick() {
		const curr_page = get(this, 'state.activePage', 0);
		if (
			get(this, ['state', 'isItemSelected', curr_page, 'length'], 0) == 0
		) {
			const allRows = get(this, 'props.dashboard_patients.data', []).map(
				(e) => {
					return e.encounter_uuid;
				}
			);

			const current_selected_items = get(
				this,
				'state.isItemSelected',
				{}
			);
			current_selected_items[get(this, 'state.activePage', 0)] = allRows;

			this.setState({
				isItemSelected: current_selected_items,
			});
		} else {
			const current_selected_items = get(
				this,
				'state.isItemSelected',
				{}
			);
			current_selected_items[get(this, 'state.activePage', 0)] = [];
			this.setState({
				isItemSelected: current_selected_items,
			});
		}

		const dashboardList = _.get(this, 'props.dashboard_patients.data', []);

		if (get(this, ['state', 'userUuids', curr_page, 'length'], 0) == 0) {
			const allRows = dashboardList.map((e) => {
				return e.patient_uuid;
			});

			const currentUserUuids = get(this, 'state.userUuids', {});
			currentUserUuids[get(this, 'state.activePage', 0)] = allRows;

			this.setState({
				userUuids: currentUserUuids,
			});
		} else {
			const currentUserUuids = get(this, 'state.userUuids', {});
			currentUserUuids[get(this, 'state.activePage', 0)] = [];
			this.setState({
				userUuids: currentUserUuids,
			});
		}
	}

	getPatientName(uuid) {
		const patientslist = get(
			this,
			'props.dashboard_encounters.patientslist.data',
			[]
		);
		const patient = find(patientslist, function (o) {
			return o.uuid == uuid;
		});
		const name =
			get(patient, 'first_name', '') +
			' ' +
			get(patient, 'last_name', '');
		return name;
	}

	getEmail(uuid) {
		const patientslist = get(
			this,
			'props.dashboard_encounters.patientslist.data',
			[]
		);
		const patient = find(patientslist, function (o) {
			return o.uuid == uuid;
		});
		const email = get(patient, 'email', '-');
		return email;
	}

	getReferralProgram(uuid) {
		const patientslist = get(
			this,
			'props.dashboard_encounters.patientslist.data',
			[]
		);
		const patient = find(patientslist, function (o) {
			return o.uuid == uuid;
		});
		const referral_program = RemoveUnderscoreAndCapitalizeFirstLetter(
			get(patient, 'referral_program', '-')
		);
		return referral_program;
	}

	navigateToDashboard() {
		this.props.history.push('/app/dashboard');
	}

	handlePageChange(evt, pageNumber) {
		const filter = get(this, 'props.history.location.search', '');
		this.props.getDashboardPatients({
			offset: pageNumber,
			filter: filter,
			referral_program: get(this, 'state.referral_program_display', null),
			call_attempts: get(this, 'state.attempts', null),
			order_by: get(this, 'state.orderBy'),
			order_type: get(this, 'state.order'),
			assigned_gca_uuid: get(this, 'state.assigned_gca_uuid', null),
			limit: get(this, 'props.limit', 25),
		});
		this.setState({ activePage: pageNumber });
		window.scroll(0, 0);
	}

	getSelectedPatient = (uuid) => {
		return get(this, 'props.dashboard_patients.data', []).find(
			(e) => e.patient_uuid == uuid
		);
	};

	selectPatientURL = (idx) => {
		const selectedPatient = this.getSelectedPatient(idx);
		return (
			'/app/patientdetail/' +
			get(selectedPatient, 'patient_uuid', 0) +
			'/0/' +
			get(this.props, 'patientstate.activePage', 0) +
			'/' +
			get(this.props, 'patientstate.query', '')
		);
	};

	selectPatient(idx, evt) {
		evt.stopPropagation();
		const selectedPatient = this.getSelectedPatient(idx);
		this.props.setCardFilter(this.getFilter());
		const fullName = `${get(selectedPatient, 'first_name', '--') || '--'} ${
			get(selectedPatient, 'last_name', '--') || '--'
		}`;
		this.props.history.location.state = {
			breadcrumbs: [
				{
					location: 'ror_outreach_pending',
					offset: get(this, 'state.activePage', 0),
					order_by: get(this, 'state.orderBy'),
					order_type: get(this, 'state.order'),
					key: 'dashboard_card',
					referral_program_id: get(
						this,
						'state.referral_program_id',
						null
					),
					referral_program_display: get(
						this,
						'state.referral_program_display',
						null
					),
					attempts: get(this, 'state.attempts', null),
					assigned_gca_uuid: get(
						this,
						'state.assigned_gca_uuid',
						null
					),
					showCreateOutreachModal: this.state.showCreateOutreachModal,
					selectedPatient: selectedPatient,
					idx: idx,
				},
				{
					location: 'Patient Detail',
					patientFullName: fullName,
				},
			],
			search: '?filter=ror_outreach_pending',
		};

		this.props.history.push({
			pathname:
				'/app/patientdetail/' +
				get(selectedPatient, 'patient_uuid', 0) +
				'/0/' +
				get(this.props, 'patientstate.activePage', 0) +
				'/' +
				get(this.props, 'patientstate.query', '') +
				'?offset=' +
				get(this, 'state.activePage', 0),
			state: {
				breadcrumbs: [
					{
						location: 'ror_outreach_pending',
						offset: get(this, 'state.activePage', 0),
						order_by: get(this, 'state.orderBy'),
						order_type: get(this, 'state.order'),
						referral_program_id: get(
							this,
							'state.referral_program_id',
							null
						),
						referral_program_display: get(
							this,
							'state.referral_program_display',
							null
						),
						attempts: get(this, 'state.attempts', null),
						key: 'dashboard_card',
						assigned_gca_uuid: get(
							this,
							'state.assigned_gca_uuid',
							null
						),
						showCreateOutreachModal:
							this.state.showCreateOutreachModal,
						selectedPatient: selectedPatient,
						idx: idx,
					},
					{
						location: 'Patient Detail',
						patientFullName: fullName,
					},
				],
				search: '?filter=ror_outreach_pending',
			},
		});
	}

	getOutreachDate(patient_obj) {
		const { initial_contact_date, recontact_date, final_contact_date } =
			patient_obj;
		if (get(final_contact_date, 'length', 0) > 0) {
			return moment(final_contact_date).format('MMM DD, YYYY');
		} else if (get(recontact_date, 'length', 0) > 0) {
			return moment(recontact_date).format('MMM DD, YYYY');
		} else if (get(initial_contact_date, 'length', 0) > 0) {
			return moment(initial_contact_date).format('MMM DD, YYYY');
		} else return '-';
	}

	getSelectedListEmptyStatus() {
		const currentListState = get(this, 'state.userUuids', {});
		const selected_values = uniq(flatten(values(currentListState))) || [];

		if (selected_values.length > 0)
			return { flag: true, count: selected_values.length };

		return { flag: false, count: 0 };
	}

	resetFilters() {
		const { currentRole, currentUserUuid } = this.props;
		this.setState(
			{
				orderBy: 'call_attempts',
				order: 'asc',
				activePage: 0,
				initialLoad: false,
				referral_program_id: [],
				attempts: [],
				showCreateOutreachModal: false,
				selectedPatient: undefined,
				referral_program_display: [],
				assigned_gca_uuid:
					currentRole === 'GCA' && currentUserUuid
						? [currentUserUuid]
						: [],
				userUuids: {},
			},
			() => {
				this.props.getDashboardPatients({
					offset: this.state.activePage || 0,
					filter: this.props.history?.location?.search || '',
					order_by: this.state.orderBy,
					order_type: this.state.order,
					referral_program:
						this.state.referral_program_display || null,
					call_attempts: this.state.attempts || null,
					assigned_gca_uuid: this.state.assigned_gca_uuid || null,
					limit: this.props.limit || 25,
				});
			}
		);
	}

	createSortHandler(e) {
		let curr_order = get(this, 'state.order', '');
		if (curr_order == '' || curr_order == 'desc') {
			curr_order = 'asc';
		} else curr_order = 'desc';

		this.setState({
			orderBy: e,
			order: curr_order,
			activePage: 0,
			isItemSelected: {},
			userUuids: {},
		});

		this.props.getDashboardPatients({
			offset: 0,
			order_by: e,
			order_type: curr_order,
			referral_program: get(this, 'state.referral_program_display', null),
			attempts: get(this, 'state.attempts', null),
			filter: get(this, 'props.history.location.search', ''),
			assigned_gca_uuid: get(this, 'state.assigned_gca_uuid', null),
			limit: get(this, 'props.limit', 25),
		});
	}

	getUserTimeZone(state) {
		return statesTimezones[state];
	}

	handleReferralFilterChange(evt) {
		if (
			get(evt, 'length', 0) ===
			get(this, 'state.referralProgramsEnum.length', null)
		) {
			evt = [];
		}

		this.setState({
			referral_program_id: get(evt, 'length', 0) === 0 ? [] : evt,
			activePage: 0,
			isItemSelected: {},
			referral_program_display: evt,
		});

		if (evt !== 'All') {
			this.props.getDashboardPatients({
				offset: 0,
				order_by: get(this, 'state.orderBy'),
				order_type: get(this, 'state.order'),
				filter: get(this, 'props.history.location.search', ''),
				referral_program: evt,
				call_attempts: get(this, 'state.attempts', null),
				assigned_gca_uuid: get(this, 'state.assigned_gca_uuid', null),
				limit: get(this, 'props.limit', 25),
			});
		} else {
			this.props.getDashboardPatients({
				offset: 0,
				order_by: get(this, 'state.orderBy'),
				order_type: get(this, 'state.order'),
				filter: get(this, 'props.history.location.search', ''),
				call_attempts: get(this, 'state.attempts', null),
				assigned_gca_uuid: get(this, 'state.assigned_gca_uuid', null),
				limit: get(this, 'props.limit', 25),
			});
		}
	}

	handleAttemptFilterChange(evt) {
		this.setState({
			attempts: evt,
			activePage: 0,
			isItemSelected: {},
		});

		if (evt !== 'All') {
			this.props.getDashboardPatients({
				offset: 0,
				order_by: get(this, 'state.orderBy'),
				order_type: get(this, 'state.order'),
				filter: get(this, 'props.history.location.search', ''),
				referral_program: get(
					this,
					'state.referral_program_display',
					null
				),
				call_attempts: evt,
				assigned_gca_uuid: get(this, 'state.assigned_gca_uuid', null),
				limit: get(this, 'props.limit', 25),
			});
		} else {
			this.props.getDashboardPatients({
				offset: 0,
				order_by: get(this, 'state.orderBy'),
				order_type: get(this, 'state.order'),
				filter: get(this, 'props.history.location.search', ''),
				referral_program: get(
					this,
					'state.referral_program_display',
					null
				),
				assigned_gca_uuid: get(this, 'state.assigned_gca_uuid', null),
				limit: get(this, 'props.limit', 25),
			});
		}
	}

	filterAssignments(isDeleted) {
		const assignments = this.props['gcaAssignments'] || [];

		return assignments.filter((el) => {
			return (
				get(el, ['is_deleted'], null) === isDeleted &&
				get(el, ['uuid'], null)
			);
		});
	}

	formulateGCAAssignmentEnum() {
		const gcaAssignments = _.get(this, ['props', 'gcaAssignments'], []);
		let gcaEnum = [];

		if (_.isEmpty(gcaAssignments)) return gcaEnum;

		const unassignedObj = _.find(
			gcaAssignments,
			(o) => o.name === 'Unassigned'
		);

		gcaEnum.push({
			display_name: `Unassigned (${unassignedObj.count})`,
			key: 'null',
		});
		gcaEnum.push({ groupingLabel: 'ACTIVE' });

		const activeAssignments = this.filterAssignments(false).map((el) => {
			return {
				display_name: `${_.get(el, ['name'], '--')} (${_.get(
					el,
					['count'],
					0
				)})`,
				key: _.get(el, ['uuid'], null),
			};
		});
		gcaEnum = gcaEnum.concat(activeAssignments);
		gcaEnum.push({ groupingLabel: 'INACTIVE' });

		const inactiveAssignments = this.filterAssignments(true).map((el) => {
			return {
				display_name: `${_.get(el, ['name'], '--')} (${_.get(
					el,
					['count'],
					0
				)})`,
				key: _.get(el, ['uuid'], null),
			};
		});
		gcaEnum = gcaEnum.concat(inactiveAssignments);

		return gcaEnum;
	}

	filterGCAAssignment(e) {
		const gcaAssignmentsLength = this.props.gcaAssignments?.length || null;

		let gcaValues;
		console.log('e', e);
		if (e === null) {
			gcaValues = [];
		} else {
			gcaValues = e ? [...e] : null;
		}

		if ((e?.length || 0) === gcaAssignmentsLength) {
			gcaValues = [];
		}

		this.setState(
			{
				assigned_gca_uuid: gcaValues,
				activePage: this.state.activePage || 0,
				isItemSelected: {},
				userUuids: {},
			},
			() => {
				this.props.getDashboardPatients({
					offset: this.state.activePage || 0,
					order_by: this.state.orderBy,
					order_type: this.state.order,
					filter: this.props.history?.location?.search || '',
					referral_program:
						this.state.referral_program_display || null,
					call_attempts: this.state.attempts || null,
					assigned_gca_uuid: this.state.assigned_gca_uuid || null,
					limit: this.props.limit || 25,
				});
			}
		);
	}

	filterGCAAsssignment(values) {
		const gcaAssignmentsLength = _.get(
			this,
			['props', 'gcaAssignments', 'length'],
			null
		);
		let gcaValues = [...values.gca];

		if (values.length === gcaAssignmentsLength) gcaValues = [];

		this.setState(
			{
				assigned_gca_uuid: gcaValues,
				activePage: this.state.activePage || 0,
				isItemSelected: {},
				userUuids: {},
			},
			() => {
				this.props.getDashboardPatients({
					offset: this.state.activePage || 0,
					order_by: this.state.orderBy,
					order_type: this.state.order,
					filter: this.props.history?.location?.search || '',
					referral_program:
						this.state.referral_program_display || null,
					call_attempts: this.state.attempts || null,
					assigned_gca_uuid: this.state.assigned_gca_uuid || null,
					limit: this.props.limit || 25,
				});
			}
		);
	}

	reassignGCA() {
		const currentListState = get(this, 'state.userUuids', {});
		const selected_values = uniq(flatten(values(currentListState))) || [];
		const [assigneeType, assignedUuid] = this.state.selectedAssignee;
		const settingUnassigned = assignedUuid === 'unassigned';

		if (
			(assignedUuid || assignedUuid === null) &&
			Array.isArray(selected_values)
		) {
			this.props.changeCCOrGCAAssignment({
				[`assigned_${assigneeType}_uuid`]: settingUnassigned
					? null
					: assignedUuid,
				patients: selected_values,
			});
		}

		this.setState({
			filterList: 'gca',
			showAssignModal: false,
			selectedAssignee: [],
		});
	}

	exportRoR() {
		if (
			get(this, 'props.dashboard_patients.data.length', 0) == 0 ||
			get(this, 'state.updatingRecords')
		) {
			return;
		}

		const gcaUuid = get(this, 'state.assigned_gca_uuid', null);
		const attempts = get(this, 'state.attempts', []);
		this.setState({
			generatingPopReport: true,
		});

		const payload = {
			referral_program: get(this, 'state.referral_program_id', []),
			call_attempts: attempts,
			assigned_gca_uuid: gcaUuid,
		};

		dashboardRoROutreachPendingReport(payload)
			.then(() => {
				this.props.enqueueSnackbar('Report is sent to your email id.', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				this.setState({
					generatingPopReport: false,
				});
			})
			.catch(() => {
				this.setState({
					generatingPopReport: false,
				});
			});
	}

	createOutreachRedirectToScheduling(patient) {
		const patient_id = !_.isNaN(_.toNumber(get(patient, 'id')))
			? _.toNumber(get(patient, 'id'))
			: 0;
		get(this, 'props.history', []).push({
			pathname: '/app/appointments/schedule',
			state: {
				breadcrumbs: [
					{
						location: 'Appointments',
						url: '/app/appointments',
					},
					{
						location: 'Schedule Appointment',
						url: '/app/appointments/schedule',
						patientData: {
							firstName: get(patient, 'first_name', ''),
							lastName: get(patient, 'last_name', ''),
							id: patient_id,
							uuid: get(patient, 'patient_uuid', 0),
							encounter_uuid: get(
								patient,
								'encounter_uuid',
								null
							),
						},
					},
				],
			},
		});
	}

	showCreateOutreachPopup = (evt, patientdetail) => {
		const targetNode = get(evt, 'target', document.createElement('div'));
		if (
			!targetNode.classList.contains('el-checkbox__inner') &&
			!targetNode.classList.contains('el-checkbox__original')
		) {
			this.setState({
				showCreateOutreachModal: true,
				selectedPatient: patientdetail,
				idx: patientdetail.patient_uuid,
			});
		}
	};

	hideCreateOutreachPopup = () => {
		this.setState(
			{ showCreateOutreachModal: false, selectedPatient: undefined },
			() => {
				this.props.setCardFilter(this.getFilter());
			}
		);
	};

	getAssignments(type) {
		let assignments = [];
		switch (type) {
			case 'gca':
				assignments = this.props.gcaAssignments || [];
				break;
			default:
				break;
		}
		return assignments;
	}

	getUnassignedCount(type) {
		const assignments = this.getAssignments(type);
		if (Array.isArray(assignments)) {
			const unassigned = _.find(
				assignments,
				(el) => get(el, 'name', null) === 'Unassigned'
			);
			if (unassigned) return returnCcGcaCount(unassigned);
			else return '--';
		} else return '--';
	}

	getActiveAssignments(type) {
		const assignments = this.getAssignments(type);
		if (Array.isArray(assignments)) {
			return assignments.filter((el) => {
				return (
					get(el, 'is_deleted', null) === false &&
					get(el, 'uuid', null)
				);
			});
		} else return [];
	}

	getInactiveAssignments(type) {
		const assignments = this.getAssignments(type);
		if (Array.isArray(assignments)) {
			return assignments.filter((el) => {
				return (
					get(el, 'is_deleted', null) === true &&
					get(el, 'uuid', null)
				);
			});
		} else return [];
	}

	getProviderName(obj) {
		let providervalue = '';
		const enums = get(
			this,
			['props', 'visitProvider', 'data', 'visit_provider'],
			[]
		);
		const enum_value = find(enums, function (o) {
			return o.key == _.get(obj, 'visit_provider', '');
		});

		if (enum_value) {
			providervalue = get(enum_value, 'display_name', '--');
		} else {
			providervalue = RemoveUnderscoreAndCapitalizeInString(
				get(obj, 'visit_provider', '--')
			);
		}
		return providervalue;
	}

	getProviderInfo(visit_provider) {
		let providerenum = [];
		let providerName = '';

		providerName = visit_provider;
		providerenum = _.get(
			this,
			['props', 'visitProvider', 'data', 'visit_provider'],
			[]
		);
		return { providerenum, providerName };
	}

	displayOverdueCall(obj) {
		let overdueText = '';
		const projectedLatestOutreachDatetime = _.get(
			obj,
			'projected_latest_outreach_datetime',
			''
		);
		const momentProjectedDatetime = moment.utc(
			projectedLatestOutreachDatetime
		);
		const overdueTime = moment
			.utc()
			.diff(moment(momentProjectedDatetime), 'hours');

		if (overdueTime >= 0 && overdueTime < 24) {
			overdueText = (
				<span className='overdue-by overdue-by-today'>Call Today</span>
			);
		} else if (overdueTime >= 24 && overdueTime < 48) {
			overdueText = (
				<span className='overdue-by overdue-by-1day'>
					Overdue by 1d
				</span>
			);
		} else if (overdueTime >= 48 && overdueTime < 72) {
			overdueText = (
				<span className='overdue-by overdue-by-2days'>
					Overdue by 2d
				</span>
			);
		} else if (overdueTime >= 72 && overdueTime < 96) {
			overdueText = (
				<span className='overdue-by overdue-by-3days'>
					Overdue by 3d
				</span>
			);
		} else if (overdueTime >= 96 && overdueTime < 120) {
			overdueText = (
				<span className='overdue-by overdue-by-4days'>
					Overdue by 4d
				</span>
			);
		} else if (overdueTime >= 120 && overdueTime < 144) {
			overdueText = (
				<span className='overdue-by overdue-by-5days'>
					Overdue by 5d
				</span>
			);
		} else if (overdueTime >= 144 && overdueTime < 168) {
			overdueText = (
				<span className='overdue-by overdue-by-6days'>
					Overdue by 6d
				</span>
			);
		}

		return overdueText;
	}

	handleFollowUpDeclinedOutreach = (encounter_uuid, data) => {
		const { enqueueSnackbar } = this.props;

		return followUpDeclinedOutreach(encounter_uuid, data)
			.then((res) => {
				this.props.getDashboardPatients({
					offset: get(this, 'state.activePage', 0),
					filter: get(this, 'props.history.location.search', ''),
					order_by: get(this, 'state.orderBy'),
					order_type: get(this, 'state.order'),
					referral_program: get(
						this,
						'state.referral_program_display',
						null
					),
					call_attempts: get(this, 'state.attempts', null),
					assigned_gca_uuid: get(
						this,
						'state.assigned_gca_uuid',
						null
					),
					limit: get(this, 'props.limit', 25),
				});

				enqueueSnackbar(
					'Outreach mark as followup declined successfully.',
					{
						variant: 'info',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
				this.hideCreateOutreachPopup();
				return res;
			})
			.catch((err) => {
				enqueueSnackbar(
					'Error while outreach mark as followup declined.',
					{
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
				this.hideCreateOutreachPopup();
				throw err;
			});
	};

	getEncounterSpecificTime(dos, timezone) {
		return UTCToCurrentView(
			moment,
			moment(dos),
			timezone,
			null,
			'MMM D, YYYY hh:mmA z'
		);
	}

	handleChangeList(filterList) {
		this.setState({ filterList });
	}

	handleResultsPerPageChange(limit) {
		this.setState({ limit, activePage: 0 }, () =>
			this.props.getDashboardPatients({
				offset: get(this, 'state.activePage', 0),
				filter: get(this, 'props.history.location.search', ''),
				order_by: get(this, 'state.orderBy'),
				order_type: get(this, 'state.order'),
				referral_program: get(
					this,
					'state.referral_program_display',
					null
				),
				call_attempts: get(this, 'state.attempts', null),
				assigned_gca_uuid: get(this, 'state.assigned_gca_uuid', null),
				limit,
			})
		);
	}

	handleSort(order_by, order_type) {
		this.setState(
			{
				orderBy: order_by,
				order: order_type,
				activePage: 0,
			},
			() => {
				this.props.getDashboardPatients({
					offset: get(this, 'state.activePage', 0),
					order_by,
					order_type,
					referral_program: get(
						this,
						'state.referral_program_display',
						null
					),
					call_attempts: get(this, 'state.attempts', null),
					filter: get(this, 'props.history.location.search', ''),
					assigned_gca_uuid: get(
						this,
						'state.assigned_gca_uuid',
						null
					),
					limit: get(this, 'props.limit', 25),
				});
			}
		);
	}

	render() {
		const numSelected = get(this, ['state', 'isItemSelected'], {});
		const all_selected = _.flatten(_.values(numSelected));
		const all_displayed_current_page = _.map(
			get(this, 'props.dashboard_patients.data', []),
			'encounter_uuid'
		);
		const rowCount = Math.min(
			get(this, 'props.limit', 25),
			get(this, 'props.dashboard_patients.data.length', 0)
		);
		const current_displayed_selected_list = _.intersection(
			all_displayed_current_page,
			all_selected
		);

		const pagination = {
			limit: Math.min(
				get(this, 'props.limit', 25),
				get(this, 'props.dashboard_patients.data.length', 0)
			),
			offset: get(this, 'state.activePage', 0),
			total: get(this, 'props.dashboard_patients.total', 0),
		};
		const sortBy = {
			key: _.get(this, ['state', 'orderBy'], ''),
			value: _.get(this, ['state', 'order'], ''),
		};

		const { currentUserUuid } = this.props;
		const totalCount = get(this, 'props.dashboard_patients.total', 0);
		const providerSpecialties =
			_.get(this, 'props.providerSpecialties', null) || [];

		const assignButtonDisabled = !this.getSelectedListEmptyStatus().flag;
		const unassigned = { key: 'unassigned', display_name: 'Unassigned' };

		return (
			<div className='db-ror-outreach-list'>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						minWidth: 'inherit',
					}}
				>
					<div style={{ display: 'flex' }}>
						<div
							className={classNames('assign-cc-gca-button', {
								disabled: assignButtonDisabled,
							})}
							onClick={() =>
								this.setState({ showAssignModal: true })
							}
						>
							Assign Patient
						</div>
						<div
							className={
								get(
									this,
									'props.dashboard_patients.data.length',
									0
								) > 0 && !get(this, 'state.updatingRecords')
									? 'export_enabled'
									: 'export_disabled'
							}
							variant='contained'
							color='primary'
							onClick={totalCount ? () => this.exportRoR() : null}
						>
							{'Export' + ' (' + totalCount + ')'}
						</div>
					</div>
					<div style={{ display: 'flex' }}>
						<ResetFilters handleResetFilters={this.resetFilters} />

						<MultiSelectCheckbox
							data={this.formulateGCAAssignmentEnum()}
							selected={
								_.get(
									this,
									['state', 'assigned_gca_uuid'],
									[]
								) || []
							}
							title={'Assignments'}
							submit={(values) =>
								this.filterGCAAssignment(values)
							}
							majorPillTitle={'Assignments'}
							defaultValues={[]}
							searchBar={true}
							previewTab={true}
							alwaysNumber={false}
							zeroToAllPillTitle={true}
						/>

						<MultiSelectCheckbox
							data={
								get(this, 'state.referralProgramsEnum', []) ||
								[]
							}
							selected={
								_.get(
									this,
									['state', 'referral_program_display'],
									[]
								) || []
							}
							title={'Referral Programs'}
							submit={(values) =>
								this.handleReferralFilterChange(values)
							}
							majorPillTitle={'Referral Programs'}
							defaultValues={[]}
							searchBar={true}
							previewTab={true}
							alwaysNumber={false}
							zeroToAllPillTitle={true}
						/>

						<MultiSelectCheckbox
							data={get(this, 'state.callAttemptsEnum', []) || []}
							selected={
								_.get(this, ['state', 'attempts'], []) || []
							}
							title={'Call Attempts'}
							submit={(values) =>
								this.handleAttemptFilterChange(values)
							}
							majorPillTitle={'Call Attempts'}
							defaultValues={[]}
							searchBar={false}
							previewTab={false}
							alwaysNumber={false}
							zeroToAllPillTitle={false}
						/>
					</div>
				</div>
				{this.state.showAssignModal && (
					<AssignModal
						{...{
							closeModal: () => {
								this.setState({
									filterList: 'gca',
									showAssignModal: false,
									selectedAssignee: [],
								});
							},
							gcaList: [...this.props.gcaList, unassigned],
							selectListGCA: () =>
								this.setState({ filterList: 'gca' }),
							selectedAssignee: this.state.selectedAssignee,
							selectAssignee: (e) =>
								this.setState({
									selectedAssignee: [
										this.state.filterList,
										e,
									],
								}),
							updateGCAAssignment: this.reassignGCA,
						}}
					/>
				)}
				<div>
					{_.get(
						this,
						['props', 'dashboardPatientsLoading'],
						false
					) || get(this, 'state.updatingRecords', false) ? (
						<Loading
							loading={true}
							style={{
								background: 'transparent',
								minWidth: 'inherit',
							}}
							className='db-ror-list-loading'
						/>
					) : (
						<Table
							loading={get(this, 'state.updatingRecords', false)}
							backToTop
							resultsPerPage={[25, 50, 100]}
							handleResultsPerPageChange={
								this.handleResultsPerPageChange
							}
							pagination={pagination}
							handlePageChange={this.handlePageChange}
							hasData={
								get(
									this,
									'props.dashboard_patients.data.length',
									0
								) > 0
							}
							handleResetFilters={this.resetFilters}
							handleSort={this.handleSort}
							sortBy={sortBy}
							noResultsFound={
								<>
									<div>No Results Found</div>
									<p>
										Try adjusting your search or filter
										options to find
										<br />
										what you’re looking for.
									</p>
									<span
										onClick={() =>
											this.filterGCAAssignment(null)
										}
									>
										View All Assignments
									</span>
									<span className='separator' />
									<span onClick={() => this.resetFilters()}>
										Reset Filters
									</span>
								</>
							}
						>
							<Thead>
								<Tr>
									<Th>
										<Checkbox
											checked={
												current_displayed_selected_list.length ==
													rowCount &&
												current_displayed_selected_list.length >
													0
											}
											indeterminate={
												current_displayed_selected_list.length >
													0 &&
												current_displayed_selected_list.length <
													rowCount
											}
											onChange={(evt) =>
												this.onSelectAllClick(evt)
											}
											inputProps={{
												'aria-label':
													'Select all patients',
											}}
											className={
												'patient_selection_checkbox' +
												(current_displayed_selected_list.length ===
												rowCount
													? ' checked'
													: '')
											}
										/>
									</Th>
									<Th
										sortable={{
											first_name: {
												display_name: 'first name',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
											patient_email: {
												display_name: 'email',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										Patient
									</Th>
									<Th
										sortable={{
											referral_program: {
												display_name:
													'referral program',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										Referral Program
									</Th>
									<Th
										sortable={{
											visit_provider: {
												display_name: 'provider',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										Provider
									</Th>
									<Th
										sortable={{
											consultation_type: {
												display_name:
													'consultation type',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
											date_of_service: {
												display_name:
													'date & time of service',
												sortBy: {
													asc: 'Oldest First',
													desc: 'Newest First',
												},
											},
										}}
									>
										Encounter
									</Th>
									<Th
										sortable={{
											provider_indicated_specialty: {
												display_name:
													'provider indicated specialty',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										Provider Indicated Specialty
									</Th>
									<Th
										sortable={{
											patient_phone: {
												display_name: 'Phone Number',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										Phone Number
									</Th>
									<Th
										sortable={{
											call_attempts: {
												display_name: 'Call Attempts',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										Call Attempts
									</Th>
									<Th>Assignee(s)</Th>
								</Tr>
							</Thead>
							<Tbody>
								{get(
									this,
									'props.dashboard_patients.data',
									[]
								).map((e, i) => {
									const {
										encounter_uuid,
										patient_uuid,
										first_name,
										last_name,
										patient_email,
										referral_program,
										date_of_service,
										timezone,
										high_priority,
										phone,
										consultation_type,
										encounter_type,
										visit_provider,
										guardian,
										assigned_gca_uuid,
										provider_indicated_specialty,
									} = e;
									const has_guardian =
										!isEmpty(guardian) &&
										!isEmpty(
											get(guardian, ['first_name'], '')
										);
									const fullName =
										first_name || last_name
											? first_name + ' ' + last_name
											: '--';
									const homeAddress = getHomeAddress(
										get(e, 'address', null)
									);
									return (
										<Tr
											key={encounter_uuid}
											highlight={this.getRecordCheckState(
												encounter_uuid,
												all_selected
											)}
											onClick={(evt) => {
												this.showCreateOutreachPopup(
													evt,
													e
												);
											}}
										>
											<Td minWidth='53px' maxWidth='56px'>
												<CommonCheckbox
													checked={this.getRecordCheckState(
														encounter_uuid,
														all_selected
													)}
													id={i}
													onChangeFunc={
														this.onSelectRowClick
													}
													textColor={'purple'}
													additionalOnChangeArgs={[
														encounter_uuid,
														patient_uuid,
													]}
												></CommonCheckbox>
											</Td>
											<Td maxWidth='296px'>
												<p style={{ display: 'flex' }}>
													<TextWithTooltip maxWidth='200px'>
														{fullName}
													</TextWithTooltip>
													{(high_priority === true ||
														high_priority ===
															'true') && (
														<span className='high-priority' />
													)}
													{has_guardian && (
														<span className='has-guardian' />
													)}
												</p>
												<TextWithTooltip
													className='sub'
													maxWidth='200px'
												>
													{patient_email || '--'}
												</TextWithTooltip>
											</Td>
											<Td maxWidth='190px'>
												{get(
													referral_program,
													'length',
													0
												) > 0
													? referral_program
													: '--'}
											</Td>
											<Td maxWidth='224px'>
												<ProviderInfo
													fullName={true}
													{...this.getProviderInfo(
														visit_provider
													)}
												/>
											</Td>
											<Td maxWidth='170px'>
												<p>
													<TextWithTooltip className='capitalize'>
														{isPartnerInitiatedPreTest(
															{
																...e,
																type: encounter_type,
															}
														)
															? 'TRO'
															: isPartnerInitiatedUnsolicitedResult(
																	{
																		...e,
																		type: encounter_type,
																	}
															  )
															? 'TRO - Results Only'
															: consultation_type ||
															  '--'}
													</TextWithTooltip>
												</p>
												<p>
													<TextWithTooltip className='sub'>
														{this.getEncounterSpecificTime(
															date_of_service,
															timezone,
															encounter_type
														) || '--'}
													</TextWithTooltip>
												</p>
											</Td>
											<Td maxWidth='224px'>
												{getFromEnumOrFormat(
													providerSpecialties,
													provider_indicated_specialty,
													'name',
													'display_name'
												)}
											</Td>
											<Td maxWidth='172px'>
												<p>
													<TextWithTooltip maxWidth='136px'>
														{get(
															phone,
															'length',
															0
														) > 0
															? phone
															: '--'}
													</TextWithTooltip>
												</p>
												<p>
													{get(
														homeAddress,
														'state.length',
														0
													) > 0 ? (
														<>
															<p className='sub'>
																<Clock
																	format={
																		'hh:mm A'
																	}
																	timezone={this.getUserTimeZone(
																		get(
																			homeAddress,
																			'state',
																			'CA'
																		)
																	)}
																/>{' '}
																Local Time
															</p>
														</>
													) : (
														'--'
													)}
												</p>
											</Td>
											<Td maxWidth='128px'>
												{get(e, 'call_attempts', 0)}
												<p className='overdue-by-2days'>
													{this.displayOverdueCall(e)}
												</p>
											</Td>
											<Td
												minWidth='unset'
												maxWidth='none'
											>
												<CCGCAAssignBadges
													{...{
														assigned_gca_uuid,
														currentUserUuid,
														gcaList:
															this.props
																.gcaList || [],
													}}
												/>
											</Td>
										</Tr>
									);
								})}
							</Tbody>
						</Table>
					)}
				</div>

				<Loading
					loading={get(this, 'state.generatingPopReport', false)}
					className='ror_report_loader'
					style={{ background: 'transparent', position: 'relative' }}
				></Loading>

				{rowCount > 0 &&
					!_.isEmpty(
						this.getSelectedPatient(
							_.get(this, ['state', 'idx'], '0')
						)
					) &&
					_.get(
						this,
						['state', 'showCreateOutreachModal'],
						false
					) && (
						<CreateRoROutreachModal
							patient={_.get(
								this,
								['state', 'selectedPatient'],
								{}
							)}
							enqueueSnackbar={this.props.enqueueSnackbar}
							CreateRedirectToScheduling={() =>
								this.createOutreachRedirectToScheduling(
									_.get(
										this,
										['state', 'selectedPatient'],
										{}
									)
								)
							}
							contactMethods={this.props.contactMethods}
							contactOutcomes={this.props.contactOutcomes}
							patientDeclinedReasonsEnums={
								this.props.patientDeclinedReasonsEnums
							}
							close={this.hideCreateOutreachPopup}
							outreachEnums={
								get(this, 'props.outreachEnums', []) || []
							}
							handleFollowUpDeclinedOutreach={
								this.handleFollowUpDeclinedOutreach
							}
							addOutreachUponCreate={
								this.props.addOutreachUponCreate
							}
							addOutreachLoading={get(
								this,
								'props.addOutreachLoading',
								null
							)}
							addedOutreachError={get(
								this,
								'props.addedOutreachError',
								null
							)}
							selectPatient={(evt) =>
								this.selectPatient(
									_.get(this, ['state', 'idx'], '0'),
									evt
								)
							}
							getProviderName={this.getProviderName}
							callResetEncounters={this.props.callResetEncounters}
							callTestOrderRequest={
								this.props.callTestOrderRequest
							}
							encounterTestOrderRequestLoading={
								this.props.encounterTestOrderRequestLoading
							}
							encounterTestOrderRequest={
								this.props.encounterTestOrderRequest
							}
							selectPatientURL={() =>
								this.selectPatientURL(
									_.get(this, ['state', 'idx'], '0')
								)
							}
						/>
					)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		dashboard_patients: state.dashboardRoROutreach.dashboardroroutreach,
		dashboardPatientsLoading:
			state.dashboardRoROutreach.dashboardroroutreachLoading,
		referralPrograms: get(
			state,
			'dashboardRoROutreach.referralprogram.data.data',
			{}
		),
		referralProgramsLoading: get(
			state,
			'dashboardRoROutreach.referralProgramsLoading',
			null
		),
		referralProgramsError: get(
			state,
			'dashboardRoROutreach.referralProgramsError',
			null
		),
		dashboardoutreachattempts: get(
			state,
			'dashboardRoROutreach.referralprogram.attempts',
			[]
		),
		update_status: get(
			state,
			'dashboardBulkUpdateStatus.update_status.data'
		),
		contactMethods: state.contactMethods.contactMethods.data,
		contactOutcomes: state.contactOutcomes.contactOutcomes,
		outreachEnums: state.outreach.outreachEnums,
		outreachEnumsError: state.outreach.outreachEnumsError,
		addOutreachLoading: state.outreach.addOutreachLoading,
		gcaList: state.gcaList.gcaList,
		gcaAssignmentsLoading: state.gcaList.gcaAssignmentsLoading,
		gcaAssignments: state.gcaList.gcaAssignments,
		gcaAssignmentsError: state.gcaList.gcaAssignmentsError,
		assigningCCOrGCA: state.assignCcGca.assigningCCOrGCA,
		assignedCCOrGCAError: state.assignCcGca.assignedCCOrGCAError,
		currentRole: get(state, 'me.activerole.name', null),
		currentUserUuid: get(state, 'me.user.uuid', null),
		visitProvider: state.dashboardencounters.visitproviderenum,
		encounterTestOrderRequest: _.orderBy(
			_.get(state, ['encounterTestOrderRequest', 'data'], []),
			(o) => {
				return new moment(_.get(o, 'created_at', null));
			},
			['asc']
		),
		encounterTestOrderRequestLoading: _.get(
			state,
			'encounterTestOrderRequest.loading',
			null
		),
		limit: state.uiConfig.table.resultsPerPage,
		patientDeclinedReasonsEnums: get(
			state,
			['gentestenums', 'gentestenums', 'declined_reasons'],
			[]
		),
		cardFilter: _.get(state, 'appData.RoROutreachPendingCard', null) || {},
		gettingProviderSpecialties: _.get(
			state,
			'providerEnums.gettingProviderSpecialties',
			false
		),
		providerSpecialties:
			_.get(state, 'providerEnums.providerSpecialties', null) || [],
		providerSpecialtiesError: _.get(
			state,
			'providerEnums.providerSpecialtiesError',
			null
		),
	};
};

const mapDispatchToProps = (dispatch) => ({
	getDashboardPatientsReferralPrograms: () =>
		dispatch(getDashboardReferralProgram_V2('ror_outreach_pending')),
	getDashboardPatients: (data) =>
		dispatch(getDashboardRoROutreachPending(data)),
	getDashboardOutreachAttempts: () => dispatch(getdashboardattempts()),
	getContactMethods: () => dispatch(getContactMethods()),
	getContactOutcomes: () => dispatch(getContactOutcomes()),
	getOutreachEnums: () => dispatch(getOutreachEnums()),
	addOutreachUponCreate: (data, uuid, loading) =>
		dispatch(addOutreachUponCreate(data, uuid, loading)),
	resetDashboardOutreachPending: () =>
		dispatch(dispatch_DashboardRoROutreachPendingReset()),
	resetDashboardReferralProgram: () =>
		dispatch(dispatch_DashboardReferralProgramReset()),
	getActiveGCAList: () => dispatch(getActiveGCAs()),
	getGCAAssignmentList: (data) => dispatch(getGCAAssignments(data)),
	changeCCOrGCAAssignment: (data) => dispatch(assignCCOrGCA(data)),
	callVisitProviderEnum: (data) => dispatch(getspecificencounterenum(data)),
	callTestOrderRequest: (encounter_uuid, flag = true) =>
		dispatch(getTestOrderRequest(encounter_uuid, flag)),
	callResetEncounters: () => dispatch(resetTestOrder()),
	callGeneTestEnums: () => dispatch(getGeneTestEnums()),
	setCardFilter: (data) =>
		dispatch(setFilter('RoROutreachPendingCard', data)),
	getAllProviderSpecialties: () => dispatch(getProviderSpecialties()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DashboardRoROutReach);
