//React & Redux
import React, { useEffect, useRef, useState } from 'react';

//Other Libraries
import _ from 'lodash';
import moment from 'moment';

//Styles
import './OutreachGroupRecord.css';

//Utils
import {
	outreach_pill_bg_color,
	outreachTypes
} from '../../../utils';
import {
	CARE_PATIENTS_PATIENT_OUTREACH,
	CARE_PATIENTS_PATIENT_EDIT_RoR_OUTREACH,
} from '../../../Utils/permissionUtils';
import { encounter_types } from '../../../Utils/encounterUtils';
import {
	isPartnerInitiatedPreTest,
	isPartnerInitiatedUnsolicitedResult,
} from '../../Patients/V2/Encounters/Common/utilsfunctions';

//UI Libraries
import { Button, Dropdown } from 'gm-element-react';

//Components
import RBAC from '../../RBAC';
import OutreachList from './OutreachList';
import Pill from '../Pill';
import EmptyOutreachRecord from './EmptyOutreachRecord';

const OutreachGroupRecord = (props) => {
	const {
		getList,
		outreachGroupRecord = {},
		outreachType = '',
		showAddOutreachDialog,
		showEditOutreachDialog,
		showFUDeclinedDialog,
	} = props;

	const {
		communicationType = '',
		consultationType,
		DOS,
		data = [],
		displayName,
		referralOutreachSubTitle,
		isAddOutreach,
		isDisplayPill,
		isStopOutreach,
		emptyReferralOutreachMsg,
		encounterSubType,
		encounterType,
		holdResultRelease = null,
		isDisableStopOutreach,
		outreachStatusDisplayName = '',
		outreachStatus = '',
		outreachTimeToStart = null,
		testNames = '',
		UUID = null,
	} = outreachGroupRecord;

	const [liveOutreachTimeToStart, setLiveOutreachTimeToStart] = useState('');
	const [apiCount, setApiCount] = useState(0);

	const outreachTimeTimer = useRef(null);

	const consultation =
		encounterType == 'visit' ? `- ${consultationType}` : '';

	useEffect(() => {
		outreachTimeInterval();
		return () => clearInterval(outreachTimeTimer.current);
	}, []);

	useEffect(() => {
		if (apiCount && apiCount < 4) {
			if (
				moment(outreachTimeToStart).isValid() &&
				outreachStatus == 'waiting_to_start'
			) {
				getOutreachTime();
			} else {
				setApiCount(-1);
			}
		}
	}, [outreachGroupRecord]);

	const getOutreachTime = () => {
		let outreachToStartMin = 0;
		let outreachToStartSec = 0;

		if (
			moment(outreachTimeToStart).isValid() &&
			outreachStatus == 'waiting_to_start'
		) {
			const _outreachTimeToStart = moment.utc(outreachTimeToStart);
			const currentUtc = moment.utc();

			outreachToStartSec = _outreachTimeToStart.diff(
				currentUtc,
				'seconds'
			);
			outreachToStartMin = Math.ceil(outreachToStartSec / 60);

			if (currentUtc.isSameOrAfter(_outreachTimeToStart)) {
				clearInterval(outreachTimeTimer.current);
				setTimeout(() => {
					setApiCount((state) => state + 1);
					getList();
				}, 20 * 1000);
				setLiveOutreachTimeToStart(1);
			} else {
				setLiveOutreachTimeToStart(outreachToStartMin);
			}
		} else {
			clearInterval(outreachTimeTimer.current);
		}
	};

	const outreachTimeInterval = () => {
		getOutreachTime();
		outreachTimeTimer.current = setInterval(() => {
			getOutreachTime();
		}, 15 * 1000);
	};

	const getPillText = () => {
		switch (outreachStatus) {
			case 'not_started':
				if (holdResultRelease) {
					return 'Outreach On Hold';
				}
				break;
			case 'waiting_to_start':
				return `Outreach Starts in ${liveOutreachTimeToStart}m`;
			case 'in_progress':
				return `Outreach ${outreachStatusDisplayName}`;
			default:
				return `${outreachStatusDisplayName}`;
		}
	};

	const renderAddOutreach = (obj) => (
		<Button
			data-testId='add-outreach'
			className={'btn-add-outreach button-text'}
			onClick={() => showAddOutreachDialog(obj)}
			disabled={obj.isDisableAddOutreach ? true : false}
			type='text'
		>
			Add Outreach
		</Button>
	);

	return (
		<>
			<div className='outreach-group-hdr-wrapper'>
				<span className='outreach-group-hdr'>{displayName}</span>
				{isDisplayPill && (
					<span className='outreach-pill'>
						<Pill
							pillText={getPillText()}
							pillExtendStyle={
								outreach_pill_bg_color[outreachStatus]
							}
						/>
					</span>
				)}
				<>
					<span className='divider-horizotal-line' />
					<RBAC
						action={CARE_PATIENTS_PATIENT_EDIT_RoR_OUTREACH}
						yes={
							isStopOutreach && (
								<>
									<Dropdown
										trigger='click'
										onCommand={(command) =>
											showFUDeclinedDialog(command, UUID)
										}
										className='btn-stop-outreach'
										menu={
											<Dropdown.Menu>
												<Dropdown.Item
													command={'patientDeclined'}
												>
													Patient Declined
												</Dropdown.Item>
												<Dropdown.Item
													command={'gcDeclined'}
												>
													GC Declined
												</Dropdown.Item>
											</Dropdown.Menu>
										}
									>
										<Button
											type='text'
											disabled={!!isDisableStopOutreach}
										>
											Stop Outreach{' '}
											<i className='el-icon-caret-bottom'></i>
										</Button>
									</Dropdown>
									<span className='divider-dot'>.</span>
								</>
							)
						}
					/>
					{communicationType == 'ror_outreach' ? (
						<RBAC
							action={CARE_PATIENTS_PATIENT_EDIT_RoR_OUTREACH}
							yes={
								isAddOutreach &&
								renderAddOutreach(outreachGroupRecord)
							}
						/>
					) : (
						<RBAC
							action={CARE_PATIENTS_PATIENT_OUTREACH}
							yes={
								isAddOutreach &&
								renderAddOutreach(outreachGroupRecord)
							}
						/>
					)}
				</>
			</div>

			{communicationType == 'ror_outreach' && !_.isEmpty(testNames) && (
				<div className='outreach-group-encntrtest-wrapper'>
					{outreachType == 'outreachTab' && (
						<div className='outreach-group-encntr-detail'>
							<span className='lable'>Encounter</span>
							<span className='encntr-info'>
								{isPartnerInitiatedPreTest({
									...outreachGroupRecord,
									type: encounterType,
									encounter_subtype: encounterSubType,
								})
									? 'TRO'
									: isPartnerInitiatedUnsolicitedResult({
										...outreachGroupRecord,
										type: encounterType,
										encounter_subtype: encounterSubType,
									})
										? 'TRO - Results Only'
										: encounter_types[encounterType]}
								{consultation ? ` ${consultation}` : ``},{' '}
								{moment(DOS).format('MMM DD, YYYY')}
							</span>
						</div>
					)}
					<div className='outreach-group-test-detail'>
						<span className='lable'>Tests Included</span>
						<span className='test-name'>{testNames}</span>
					</div>
				</div>
			)}

			{
				communicationType === outreachTypes.referralOutreach && referralOutreachSubTitle && (
					<div className='outreach-group-subtitle'>
						<span className='label'>{referralOutreachSubTitle}</span>
					</div>
				)
			}

			{_.isNil(emptyReferralOutreachMsg) ? (
				<OutreachList
					outreachList={data}
					showEditOutreachDialog={showEditOutreachDialog}
				/>
			) : (
				<EmptyOutreachRecord message={emptyReferralOutreachMsg} />
			)}
		</>
	);
};

export default OutreachGroupRecord;
