import React from 'react';
import { Tooltip } from 'gm-element-react';

import { CircleIcon } from './CircleIcon';

import './PatientTagIndicator.scss';

//Utils
import { PATIENT_TAG_DEFINITION } from '../../../utils';

/**
 * @param {object} props
 * @param {boolean} props.withText
 * @param {string} props.tagType
 * @param {string} props.subtag
 * @param {string} props.isDisabledTooltip
 */
export const PatientTagIndicator = function (props) {
	const definition = PATIENT_TAG_DEFINITION[props.tagType];
	const isDisabledTooltip = props?.isDisabledTooltip;

	if (!definition) {
		return null;
	}

	const tagText = props.subtag
		? `${definition.text}: ${props.subtag}`
		: definition.text;

	if (props.withText) {
		return (
			<span
				className='patient-tag-indicator with-text'
				style={{ backgroundColor: definition.bgColor }}
			>
				{tagText}
			</span>
		);
	}

	return (
		<span className='patient-tag-indicator'>
			<Tooltip content={tagText} disabled={isDisabledTooltip} >
				<CircleIcon color={definition.bgColor} />
			</Tooltip>
		</span>
	);
};
