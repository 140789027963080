//React & Redux
import React, { useState, useEffect, useRef } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	createEnumsOptions,
	createStateEnumsOptions,
	isValidDateTimeString,
	isValidDateStringOREmpty,
} from '../Common/utilsfunctions';

//UI Libraries
import { Button, Loading, Dialog } from 'gm-element-react';

//Other Libraries
import moment from 'moment';
import 'moment-timezone';
import classnames from 'classnames';

//Components
import { EncounterDateTimeField } from '../Common/EncounterDateTimeField';
import { EncounterSelectField } from '../Common/EncounterSelectField';
import { EncounterDateField } from '../Common/EncounterDateField';
import { EncounterMultiSelectField } from '../Common/EncounterMultiSelectField';
import { EncounterTextAreaField } from '../Common/EncounterTextAreaField';
import { isEmpty } from '../../../../../Utils/validate';
import {
	GetZoneDatetime,
	EncounterTabs,
	states_list,
} from '../../../../../utils';

const datefields = {
	initial_cap_completed_date: 1,
	followup_cap_completed_date: 1,
};
const datetimefields = {
	date_of_service: 1,
};

const AddGroupSession = (props) => {
	const {
		callgroupsessionschema,
		fetchOrderingPhysician,
		fetchVisitProvider,
		groupsessionschema,
		cancel,
		patientdetail,
		patient_uuid,
		calladdencounter,
		changeEncounterTab,
	} = props;

	const sectionRefs = useRef({});
	const defaultFormData = {
		type: 'group-session',
		date_of_service: '',
		timezone: '',
		tm_platform: '',
		visit_provider: '',
		visit_status: '',
		initial_cap_completed_date: '',
		followup_cap_completed_date: '',
		consultation_type: 'Group Session',
		icd_10: [''],
		state_of_visit: '',
		order_status: '',
		lab: '',
		multiple_labs: false,
		test_name: '',
		order_request_status: '',
		ordering_physician: '',
		date_test_authorized: '',
		bd_saliva_kit_sent: '',
		date_test_ordered: '',
		expected_lab_results_date: '',
		date_received_report: '',
		date_results_released_to_patient: '',
		blood_draw_request: 'na',
		blood_draw_order_date: '',
		pharmd: null,
		drug_interaction: null,
		no_of_interactions: null,
		drug_contraindications: null,
		drug_dosage_adjustment_recommended: null,
		ror_visit_status: '',
		ror_date_contacted: '',
		ror_date_recontacted: '',
		ror_date_final_contacted: '',
		billing_status: 'na',
		billing_notes: '',
		notes: '',
	};

	const [formData, setFormData] = useState(defaultFormData);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [touched, setTouched] = useState(false);
	const [confirmsubmit, setConfirmSubmit] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);

	useEffect(() => {
		callgroupsessionschema();
		fetchOrderingPhysician();
		fetchVisitProvider();
	}, []);

	const validateForm = (formdata) => {
		let errors = {};
		const dateerors = _.omitBy(
			_.mapValues(datefields, function (value, key) {
				if (!isValidDateStringOREmpty(_.get(formdata, key, ''))) {
					return 'Invalid Date';
				}
				return undefined;
			}),
			_.isUndefined
		);

		const datetimeerors = _.omitBy(
			_.mapValues(datetimefields, function (value, key) {
				if (!isValidDateTimeString(_.get(formdata, key, ''))) {
					return 'Invalid Date and Time';
				}
				return undefined;
			}),
			_.isUndefined
		);
		errors = { ...dateerors, ...datetimeerors };

		if (isEmpty(_.get(formdata, ['timezone']))) {
			errors['timezone'] = 'Timezone is required';
		}

		if (!isValidDateTimeString(_.get(formdata, ['date_of_service']))) {
			errors['date_of_service'] = 'Invalid Date Time';
		}

		if (isEmpty(_.get(formdata, ['date_of_service']))) {
			errors['date_of_service'] = 'Date & Time of Service is required';
		}

		if (isEmpty(_.get(formdata, ['visit_provider']))) {
			errors['visit_provider'] = 'Visit Provider is required';
		}
		if (
			formdata['drug_interaction'] == 'yes' &&
			_.get(formdata, ['no_of_interactions', 'length'], 0) == 0
		) {
			errors['no_of_interactions'] = '# of Interaction is required';
		}
		return errors;
	};

	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		let errors = {};
		let updatedtouched = {};
		stateData[key] = value;
		if (!(key in datefields) && !(key in datetimefields)) {
			errors = validateForm(stateData);
			updatedtouched = { ...touched, [key]: true };
		}
		setErrors(errors);
		setTouched(updatedtouched);
		setFormData(stateData);
	};
	const handleDateFieldValidate = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		const errors = validateForm(stateData);
		const updatedtouched = { ...touched, [key]: true };
		setErrors(errors);
		setTouched(updatedtouched);
		setFormData(stateData);
	};

	const touchAll = () => {
		return _.mapValues(formData, (o) => {
			return true;
		});
	};

	const saveForm = () => {
		const errors = validateForm(formData);

		if (_.isEmpty(errors)) {
			setConfirmSubmit(true);
		} else {
			const touched = touchAll();
			setErrors(errors);
			setTouched(touched);
			setTimeout(() => {
				navigateToErrorFields(errors);
			}, 100);
		}
	};

	const addEncounter = () => {
		const errors = validateForm(formData);
		setIsDisabled(true);
		if (_.isEmpty(errors)) {
			const updatedformdata = _.mapValues(
				formData,
				function (value, key) {
					if (key == 'date_of_service') {
						if (
							moment(value, 'YYYY-MM-DD HH:mm:ss', true).isValid()
						) {
							const momentdos = moment(
								value,
								'YYYY-MM-DD HH:mm:ss',
								true
							);
							const timezone = formData['timezone'];
							return GetZoneDatetime(moment, momentdos, timezone);
						} else {
							/* ideally this should never execute bt safer side if happends the dos should not changed.*/
						}
					}
					if (key == 'notes') {
						return value ? value.trimEnd().trimRight() : '';
					}

					if (value == 'empty') return '';

					return value;
				}
			);
			/* remove unwanted fields */
			delete updatedformdata['timezone'];
			/* add hardcoded fileds */
			updatedformdata['user_id'] = _.get(patientdetail, ['id'], '');
			updatedformdata['version'] = 1;
			updatedformdata['user_uuid'] = patient_uuid;
			updatedformdata['patient_billing_amount'] = '';
			updatedformdata['patient_billing_date'] = '';
			setConfirmSubmit(false);
			setLoading(true);
			calladdencounter({ data: updatedformdata })
				.then((result) => {
					setTimeout(() => {
						setLoading(false);
						if (!_.isEmpty(_.get(result, ['data', 'uuid']), '')) {
							changeEncounterTab(
								_.get(result, ['data', 'uuid'], ''),
								EncounterTabs.DETAIL
							);
						} else {
							cancel();
							setIsDisabled(false);
						}
					}, 1000);
				})
				.catch((error) => {
					const touched = touchAll();
					setIsDisabled(false);
					setLoading(false);
					setTouched(touched);
				});
		}
	};

	const navigateToErrorFields = (errors) => {
		const visitkeys = ['timezone', 'date_of_service', 'visit_provider'];
		const Pgxkeys = ['no_of_interactions'];
		const keys = _.keys(errors);

		if (!_.isEmpty(_.intersection(keys, visitkeys))) {
			const scrollposition = _.get(
				sectionRefs,
				['current', 'visitdetail', 'offsetTop'],
				40
			);
			window.scrollTo({
				top: scrollposition - 0,
				left: 0,
				behavior: 'smooth',
			});
		} else if (!_.isEmpty(_.intersection(keys, Pgxkeys))) {
			const scrollposition = _.get(
				sectionRefs,
				['current', 'pgx', 'offsetTop'],
				40
			);
			window.scrollTo({
				top: scrollposition - 30,
				left: 0,
				behavior: 'smooth',
			});
		}
	};

	const renderVisitDetail = () => {
		return (
			<div
				className='encounter-section'
				ref={(section) => {
					sectionRefs.current['visitdetail'] = section;
				}}
			>
				<div
					className={classnames(
						'encounter-accordian-header',
						'encounter-accordian-header-expanded'
					)}
					style={{ cursor: 'pointer' }}
				>
					<span className='accordian-title'>VISIT DETAILS</span>
				</div>
				<div className='encounter-accordian-body editable'>
					<div>
						<div className='segment-row'>
							<div className='segment-label-row'>
								<div className='segment-col'>
									<div className='encounter-field-header required'>
										Date & Time Of Service
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header required'>
										Visit Timezone
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										TM Platform
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header required'>
										Visit Provider
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Visit Status{' '}
									</div>
								</div>
							</div>
							<div className='segment-value-row'>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterDateTimeField
											value={_.get(
												formData,
												['date_of_service'],
												''
											)}
											name='date_of_service'
											disabled={false}
											placeholder={moment
												.tz(moment(), moment.tz.guess())
												.format(
													'MMM D, YYYY hh:mm a z'
												)}
											istouched={_.get(
												touched,
												['date_of_service'],
												false
											)}
											error={_.get(
												errors,
												['date_of_service'],
												''
											)}
											onChange={(value) =>
												onFormChange(
													'date_of_service',
													value
												)
											}
											onBlur={(value) =>
												handleDateFieldValidate(
													'date_of_service',
													value
												)
											}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['timezone'],
												''
											)}
											name='timezone'
											placeholder={'Select'}
											istouched={_.get(
												touched,
												['timezone'],
												false
											)}
											error={_.get(
												errors,
												['timezone'],
												''
											)}
											options={createEnumsOptions(
												props,
												['timezoneenums'],
												'key',
												'display_name',
												'key'
											)}
											onChange={(value) =>
												onFormChange('timezone', value)
											}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['tm_platform'],
												''
											)}
											name='tm_platform'
											options={createEnumsOptions(
												groupsessionschema,
												['tm_platform'],
												'key',
												'display_name',
												'key'
											)}
											onChange={(value) =>
												onFormChange(
													'tm_platform',
													value
												)
											}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['visit_provider'],
												''
											)}
											name='visit_provider'
											istouched={_.get(
												touched,
												['visit_provider'],
												false
											)}
											options={createEnumsOptions(
												props,
												['visit_providerenums'],
												'key',
												'display_name',
												'key'
											)}
											onChange={(value) =>
												onFormChange(
													'visit_provider',
													value
												)
											}
											error={_.get(
												errors,
												['visit_provider'],
												''
											)}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['visit_status'],
												''
											)}
											name='visit_status'
											options={createEnumsOptions(
												groupsessionschema,
												['visit_status'],
												'key',
												'display_name',
												'key'
											)}
											onChange={(value) =>
												onFormChange(
													'visit_status',
													value
												)
											}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='segment-row'>
							<div className='segment-label-row'>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Visit State
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Consultation Type
									</div>
								</div>
							</div>
							<div className='segment-value-row'>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['state_of_visit'],
												''
											)}
											name='state_of_visit'
											options={createStateEnumsOptions(
												states_list,
												'key',
												'value',
												'key'
											)}
											onChange={(value) =>
												onFormChange(
													'state_of_visit',
													value
												)
											}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['consultation_type'],
												''
											)}
											name='consultation_type'
											disabled={true}
											options={[
												{
													key: _.get(
														formData,
														['consultation_type'],
														''
													),
													label: _.get(
														formData,
														['consultation_type'],
														''
													),
													value: _.get(
														formData,
														['consultation_type'],
														''
													),
												},
											]}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='segment-row'>
							<div className='segment-label-row'>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Visit CAP Completed Date
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-field-header'>
										Visit CAP Sent To Patient On
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-field-header'>
										Visit CAP Sent To PCP On
									</div>
								</div>
							</div>
							<div className='segment-value-row'>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterDateField
											value={_.get(
												formData,
												['initial_cap_completed_date'],
												''
											)}
											name='initial_cap_completed_date'
											onChange={(value) =>
												onFormChange(
													'initial_cap_completed_date',
													value
												)
											}
											error={_.get(
												errors,
												['initial_cap_completed_date'],
												''
											)}
											onBlur={(value) =>
												handleDateFieldValidate(
													'initial_cap_completed_date',
													value
												)
											}
										/>
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-multi-cols-section'>
										<div className='encounter-input-section multi-first-col'>
											<EncounterDateField
												value={''}
												name='initial_visit_summary_sent'
												disabled={true}
											/>
										</div>
										<div className='via_seprator'>
											{' '}
											via{' '}
										</div>
										<div className='encounter-input-section multi-two-col'>
											<EncounterMultiSelectField
												value={''}
												name='visit_summary_release_method_patient'
												options={createEnumsOptions(
													groupsessionschema,
													[
														'visit_summary_release_method_patient',
													],
													'key',
													'display_name',
													'key'
												)}
												placeholder={'Email'}
												disabled={true}
											/>
										</div>
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-multi-cols-section'>
										<div className='encounter-input-section multi-first-col'>
											<EncounterDateField
												value={''}
												name='visit_summary_release_date_pcp'
												disabled={true}
											/>
										</div>
										<div className='via_seprator'>
											{' '}
											via{' '}
										</div>
										<div className='encounter-input-section multi-two-col'>
											<EncounterMultiSelectField
												value={''}
												name='visit_summary_release_method_pcp'
												options={createEnumsOptions(
													groupsessionschema,
													[
														'visit_summary_release_method_pcp',
													],
													'key',
													'display_name',
													'key'
												)}
												placeholder={'Email'}
												disabled={true}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='segment-row'>
							<div className='segment-label-row'>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Result CAP Completed Date
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-field-header'>
										Result CAP Sent To Patient On
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-field-header'>
										Result CAP Sent To PCP On
									</div>
								</div>
							</div>
							<div className='segment-value-row'>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterDateField
											value={_.get(
												formData,
												['followup_cap_completed_date'],
												''
											)}
											name='followup_cap_completed_date'
											error={_.get(
												errors,
												['followup_cap_completed_date'],
												''
											)}
											onChange={(value) =>
												onFormChange(
													'followup_cap_completed_date',
													value
												)
											}
											onBlur={(value) =>
												handleDateFieldValidate(
													'followup_cap_completed_date',
													value
												)
											}
										/>
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-multi-cols-section'>
										<div className='encounter-input-section multi-first-col'>
											<EncounterDateField
												value={''}
												name='cap_sent_to_patient'
												disabled={true}
											/>
										</div>
										<div className='via_seprator'>
											{' '}
											via{' '}
										</div>
										<div className='encounter-input-section multi-two-col'>
											<EncounterMultiSelectField
												value={''}
												name='cap_release_method_patient'
												options={createEnumsOptions(
													groupsessionschema,
													[
														'cap_release_method_patient',
													],
													'key',
													'display_name',
													'key'
												)}
												placeholder={'Email'}
												disabled={true}
											/>
										</div>
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-multi-cols-section'>
										<div className='encounter-input-section multi-first-col'>
											<EncounterDateField
												value={''}
												name='cap_sent_date_pcp'
												disabled={true}
											/>
										</div>
										<div className='via_seprator'>
											{' '}
											via{' '}
										</div>
										<div className='encounter-input-section multi-two-col'>
											<EncounterMultiSelectField
												value={''}
												name='cap_release_method_pcp'
												options={createEnumsOptions(
													groupsessionschema,
													['cap_release_method_pcp'],
													'key',
													'display_name',
													'key'
												)}
												placeholder={'Email'}
												disabled={true}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderTestOrderDetail = () => {
		return (
			<div
				className='encounter-section'
				ref={(section) => {
					sectionRefs.current['testorder'] = section;
				}}
			>
				<div
					className={classnames(
						'encounter-accordian-header',
						'encounter-accordian-header-expanded'
					)}
					style={{ cursor: 'pointer' }}
				>
					<span className='accordian-title'>TEST ORDER(S)</span>
				</div>
				<div className='encounter-accordian-body editable'>
					<div className='post-creation-notice'>
						These fields will be made available after the encounter
						is created.
					</div>
				</div>
			</div>
		);
	};

	const renderPGxDetail = () => {
		return (
			<div
				className='encounter-section'
				ref={(section) => {
					sectionRefs.current['pgx'] = section;
				}}
			>
				<div
					className={classnames(
						'encounter-accordian-header',
						'encounter-accordian-header-expanded'
					)}
					style={{ cursor: 'pointer' }}
				>
					<span className='accordian-title'>PGX INFORMATION</span>
				</div>
				<div className='encounter-accordian-body editable'>
					<div>
						<div className='segment-row'>
							<div className='segment-label-row'>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										PharmD Identification
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Drug Interaction
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										# of Interaction(s)
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Drug/Gene Interaction
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Drug Dos Adj Recommended
									</div>
								</div>
							</div>
							<div className='segment-value-row'>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['pharmd'],
												''
											)}
											name='pharmd'
											options={createEnumsOptions(
												groupsessionschema,
												['pharmd'],
												'key',
												'display_name',
												'key'
											)}
											onChange={(value) =>
												onFormChange('pharmd', value)
											}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['drug_interaction'],
												''
											)}
											name='drug_interaction'
											options={createEnumsOptions(
												groupsessionschema,
												['drug_interaction'],
												'key',
												'display_name',
												'key'
											)}
											onChange={(value) =>
												onFormChange(
													'drug_interaction',
													value
												)
											}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['no_of_interactions'],
												''
											)}
											name='no_of_interactions'
											options={createEnumsOptions(
												groupsessionschema,
												['no_of_interactions'],
												'key',
												'display_name',
												'key'
											)}
											onChange={(value) =>
												onFormChange(
													'no_of_interactions',
													value
												)
											}
											error={_.get(
												errors,
												['no_of_interactions'],
												''
											)}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['drug_contraindications'],
												''
											)}
											name='drug_contraindications'
											options={createEnumsOptions(
												groupsessionschema,
												['drug_contraindications'],
												'key',
												'display_name',
												'key'
											)}
											onChange={(value) =>
												onFormChange(
													'drug_contraindications',
													value
												)
											}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												[
													'drug_dosage_adjustment_recommended',
												],
												''
											)}
											name='drug_dosage_adjustment_recommended'
											options={createEnumsOptions(
												groupsessionschema,
												[
													'drug_dosage_adjustment_recommended',
												],
												'key',
												'display_name',
												'key'
											)}
											onChange={(value) =>
												onFormChange(
													'drug_dosage_adjustment_recommended',
													value
												)
											}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderRoRDetail = () => {
		return (
			<div
				className='encounter-section'
				ref={(section) => {
					sectionRefs.current['ror'] = section;
				}}
			>
				<div
					className={classnames(
						'encounter-accordian-header',
						'encounter-accordian-header-expanded'
					)}
					style={{ cursor: 'pointer' }}
				>
					<span className='accordian-title'>
						RETURN OF RESULTS (RoR) FOLLOW-UP
					</span>
				</div>
				<div className='encounter-accordian-body editable'>
					<div className='post-creation-notice'>
						These fields will be made available after the encounter
						is created.
					</div>
				</div>
			</div>
		);
	};

	const renderBillingDetail = () => {
		return (
			<div
				className='encounter-section'
				ref={(section) => {
					sectionRefs.current['billing'] = section;
				}}
			>
				<div
					className={classnames(
						'encounter-accordian-header',
						'encounter-accordian-header-expanded'
					)}
					style={{ cursor: 'pointer' }}
				>
					<span className='accordian-title'>BILLING</span>
				</div>
				<div className='encounter-accordian-body editable'>
					<div>
						<div className='segment-row'>
							<div className='segment-label-row'>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Patient Payment Status
									</div>
								</div>
							</div>
							<div className='segment-value-row'>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['billing_status'],
												''
											)}
											name='billing_status'
											options={createEnumsOptions(
												groupsessionschema,
												['billing_status'],
												'key',
												'display_name',
												'key'
											)}
											onChange={(value) =>
												onFormChange(
													'billing_status',
													value
												)
											}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='add-encntr-lbl-billing-notes'>
							BILLING NOTES
						</div>
						<div className='segment-row encounter-field-billing-value'>
							<EncounterTextAreaField
								value={_.get(formData, ['billing_notes'], '')}
								name='billing_notes'
								disabled={false}
								onChange={(value) =>
									onFormChange('billing_notes', value)
								}
								placeholder='Content'
							/>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderAdditionalDetail = () => {
		return (
			<div
				className='encounter-section'
				ref={(section) => {
					sectionRefs.current['additional'] = section;
				}}
			>
				<div
					className={classnames(
						'encounter-accordian-header',
						'encounter-accordian-header-expanded'
					)}
					style={{ cursor: 'pointer' }}
				>
					<span className='accordian-title'>ADDITIONAL INFO</span>
				</div>
				<div className='encounter-accordian-body editable'>
					<div>
						<div className='add-encntr-lbl-cc-notes'>CC NOTES</div>
						<div className='segment-row'>
							<EncounterTextAreaField
								value={_.get(formData, ['notes'], '')}
								name='notes'
								disabled={false}
								onChange={(value) =>
									onFormChange('notes', value)
								}
								placeholder='Content'
							/>
						</div>
					</div>
				</div>
			</div>
		);
	};
	return (
		<Loading loading={loading}>
			<div className='encounter-sections'>
				{renderVisitDetail()}
				{renderTestOrderDetail()}
				{renderPGxDetail()}
				{renderRoRDetail()}
				{renderBillingDetail()}
				{renderAdditionalDetail()}
			</div>
			<div className='addencounter-footer-container'>
				<div className='cancel-btn-link'>
					<Button onClick={() => cancel()}>Cancel</Button>
				</div>
				<div className='save-btn-link'>
					<Button
						style={{ float: 'right' }}
						onClick={() => saveForm()}
					>
						Save
					</Button>
				</div>
			</div>
			<Dialog
				size='tiny'
				visible={confirmsubmit}
				onCancel={() => setConfirmSubmit(false)}
				lockScroll={false}
				showClose={false}
				closeOnPressEscape={false}
				closeOnClickModal={false}
			>
				<Dialog.Body>
					<span>Are you sure you want to add encounter?</span>
				</Dialog.Body>
				<Dialog.Footer className='dialog-footer'>
					<Button onClick={() => setConfirmSubmit(false)}>
						Cancel
					</Button>
					<Button
						disabled={isDisabled}
						type='primary'
						onClick={() => addEncounter()}
					>
						Submit
					</Button>
				</Dialog.Footer>
			</Dialog>
		</Loading>
	);
};

export default AddGroupSession;
