//Lodash
import _ from 'lodash';

export const hydrateProviders = (data) => {
	return _.isArray(data) && data.map((d) => hydrateProvider(d));
};

export const hydrateProvider = (data) => {
	return {
		providerUUID: data.uuid,
		title: data.title,
		providerFullName: `${data.first_name} ${data.last_name}`,
		city: _.get(data, ['address', 'office', 'city'], ''),
		state: _.get(data, ['address', 'office', 'state'], ''),
		addressLine1: _.get(data, ['address', 'office', 'address_line1'], ''),
		specialities: _.get(data, ['specialties'], []),
		isArchived: _.get(data, 'is_archived', false),
		rawData: data,
	};
};

export const hydrateProviderCreateUpdateErrors = (errors) => {
	const newErrors = { ...errors };

	const addressOfficeErrors = _.get(newErrors, 'address.office', null);
	if (addressOfficeErrors && typeof addressOfficeErrors === 'object') {
		for (const field in addressOfficeErrors) {
			newErrors[`address_office_${field}`] = addressOfficeErrors[field];
		}
	}

	const addressOrgErrors = _.get(newErrors, 'address.organization', null);
	if (addressOrgErrors && typeof addressOrgErrors === 'object') {
		for (const field in addressOrgErrors) {
			newErrors[`address_organization_${field}`] =
				addressOrgErrors[field];
		}
	}

	if (newErrors.address) delete newErrors.address;

	const phoneErrors = _.get(newErrors, 'phones', null);
	if (phoneErrors && typeof phoneErrors === 'object') {
		for (const field in phoneErrors) {
			newErrors[`phones_${field}`] = phoneErrors[field];
		}
	}

	return newErrors;
};
