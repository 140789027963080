//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import alert from '../../../../../assets/alert.svg';

//Lodash
import _ from 'lodash';

//Utils
import {
	encounterFieldDisplay,
	valueWithDollar,
	EncounterDateFieldsDisplay,
	encounterFreeTextFieldDisplay,
	GetSelectvalue,
	payorName,
} from '../Common/utilsfunctions';
import { RemoveUnderscoreAndCapitalizeInString } from '../../../../../utils';

//UI Libraries
import { Checkbox, Button, Loading } from 'gm-element-react';

//Components
import CommonCheckbox from './../../../../Common/Checkbox.js';
import Tooltip from './../../../../Common/Tooltip';

const hasData = (data) => !_.isNil(data) && !_.isEmpty(data);
const hasNumericData = (data) => !_.isNil(data) && _.isNumber(data);
const reclaimDisbled = (props) => {
	const isReclaimDisbaled =
		props &&
		props.encounter &&
		props.billingencounter &&
		props.patientdetail &&
		hasData(props.billingencounter.procedure_code) &&
		hasNumericData(props.billingencounter.units) &&
		hasNumericData(props.billingencounter.athena_insurance_id) &&
		hasNumericData(props.billingencounter.athena_referring_provider_id) &&
		hasData(props.encounter.date_of_service) &&
		hasNumericData(props.billingencounter.athena_rendering_provider_id) &&
		hasData(props.encounter.medical_codes) &&
		hasData(props.patientdetail.athena_patient_id);
	return !isReclaimDisbaled;
};
const submitClaimDisabled = (props) => {
	const isSubmitClaimDisbaled =
		props &&
		props.encounter &&
		props.billingencounter &&
		props.patientdetail &&
		hasNumericData(props.billingencounter.direct_cc_minutes) &&
		hasNumericData(
			props.billingencounter.post_session_indirect_cc_minutes
		) &&
		hasNumericData(props.billingencounter.indirect_cc_minutes) &&
		hasData(props.encounter.medical_codes) &&
		props.encounter.initial_cap_completed_date != null &&
		props.encounter.initial_cap_completed_date != '' &&
		checkSupervisingPhysician(props);
	return !isSubmitClaimDisbaled;
};

const checkSupervisingPhysician = (props) => {
	if (props.billingencounter.cpt_s0265_95) {
		return (
			props.encounter.supervising_physician != null &&
			props.encounter.supervising_physician != ''
		);
	} else {
		return true;
	}
};

const getPayorValue = (props) => {
	const paymentMethod = _.get(
		props,
		['billingencounter', 'payment_method'],
		null
	);
	if (paymentMethod === 'self_pay') {
		return 'Non-insurance';
	} else if (paymentMethod === 'insurance') {
		return payorName(_.get(props, 'patientdetail', null));
	} else {
		if (_.get(props, 'billingencounter.payor') === 'self_pay') {
			return 'Non-insurance';
		} else {
			return (
				RemoveUnderscoreAndCapitalizeInString(
					_.get(props, 'billingencounter.payor')
				) || '--'
			);
		}
	}
};

const BillingSegment = (props) => {
	const isreclaimDisbled = reclaimDisbled(props);
	const isSubmitClaimDisbled = submitClaimDisabled(props);
	const patientFeeRefundReasonOther = _.get(
		props,
		['billingencounter', 'patient_fee_refund_reason_other'],
		null
	);
	const payorValue = getPayorValue(props);
	return (
		<Loading loading={_.get(props, 'loading', false)}>
			<div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>Payor</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Coupon Code
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{payorValue}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{encounterFieldDisplay(props, [
									'billingencounter',
									'coupon_code',
								])}
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Payment Status
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Fee
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Billing Status (Patient)
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Billing Date (Patient)
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Billing Amount (Patient)
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{encounterFieldDisplay(props, [
									'billingencounter',
									'patient_payment',
								])}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{valueWithDollar(props, [
									'billingencounter',
									'patient_pay',
								])}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{GetSelectvalue(
									props,
									['billingencounter', 'billing_status'],
									_.get(
										props,
										['visitsschema', 'billing_status'],
										[]
									)
								)}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{EncounterDateFieldsDisplay(props, [
									'billingencounter',
									'patient_billing_date',
								])}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{valueWithDollar(props, [
									'billingencounter',
									'patient_billing_amount',
								])}
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Fee Refund
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Fee Refund Decision
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Fee Refund Processed Date
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Fee Refund Reason
							</div>
						</div>
						{patientFeeRefundReasonOther && (
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Other Refund Reason
								</div>
							</div>
						)}
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{valueWithDollar(props, [
									'billingencounter',
									'patient_fee_refund',
								])}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{_.get(
									props,
									'billingencounter.patient_fee_refund_decision'
								) &&
								_.get(
									props,
									'billingencounter.patient_fee_refund_decision'
								) !== null
									? _.startCase(
											_.get(
												props,
												'billingencounter.patient_fee_refund_decision'
											).replace('_', ' ')
									  )
									: '--'}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{EncounterDateFieldsDisplay(props, [
									'billingencounter',
									'patient_fee_refund_processed_date',
								])}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{encounterFieldDisplay(props, [
									'billingencounter',
									'patient_fee_refund_reason',
								])}
							</div>
						</div>
						{patientFeeRefundReasonOther && (
							<div className='segment-col'>
								<div className='encounter-field-value'>
									{encounterFieldDisplay(props, [
										'billingencounter',
										'patient_fee_refund_reason_other',
									])}
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='segment-row segment-row-title'>
					<div className='segment-col'>INSURANCE CLAIM</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Insurance Claim Status
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Athena Claim ID
							</div>
						</div>
						<div className='segment-3cols'>
							<div className='encounter-field-header'>
								Athena Claim Error Message(s)
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{GetSelectvalue(
									props,
									[
										'billingencounter',
										'insurance_claim_status',
									],
									_.get(
										props,
										[
											'visitsschema',
											'insurance_claim_status',
										],
										[]
									)
								)}
							</div>
						</div>
						<div className='segment-col'>
							{_.get(
								props,
								'billingencounter.athena_claim_id'
							) !== null ? (
								<div className='encounter-field-value'>
									{encounterFieldDisplay(props, [
										'billingencounter',
										'athena_claim_id',
									])}
								</div>
							) : _.get(
									props,
									'patientdetail.athena_patient_id',
									null
							  ) !== null &&
							  _.get(
									props,
									'billingencounter.athena_insurance_package_id'
							  ) !== null ? (
								<div className='resubmit-claim'>
									{_.get(
										props,
										'billingencounter.athena_claim_err_msg',
										null
									) !== null ? (
										<Button
											disabled={isreclaimDisbled}
											onClick={() => {
												props.reclaimEncounter();
											}}
										>
											Resubmit
										</Button>
									) : (
										<Tooltip
											className='claim-tooltip'
											placement='top'
											content={
												<div
													style={{ fontSize: '12px' }}
												>
													<div>
														Submit after completing
														these fields:
													</div>

													<div
														style={{
															marginTop: '4px',
														}}
													>
														1. ICD-10 code (visit)
													</div>

													<div
														style={{
															marginTop: '4px',
														}}
													>
														2. Direct Clinical Care
														Time (minutes)
													</div>

													<div
														style={{
															marginTop: '4px',
														}}
													>
														3. Pre-Session Indirect
														Time (minutes)
													</div>

													<div
														style={{
															marginTop: '4px',
														}}
													>
														{' '}
														4. Post-Session Indirect
														Time (minutes){' '}
													</div>

													<div
														style={{
															marginTop: '4px',
														}}
													>
														{' '}
														5. Visit CAP Complete
														Date
													</div>
													{_.get(
														props,
														[
															'billingencounter',
															'cpt_s0265_95',
														],
														false
													) && (
														<div
															style={{
																marginTop:
																	'4px',
															}}
														>
															{' '}
															6. Supervising
															Physician
														</div>
													)}
												</div>
											}
											disabled={!isSubmitClaimDisbled}
										>
											<Button
												className={
													isSubmitClaimDisbled
														? 'reclaim-disabled'
														: ''
												}
												/*disabled={isSubmitClaimDisbled}*/ onClick={() => {
													!isSubmitClaimDisbled &&
														props.reclaimEncounter();
												}}
											>
												Submit
											</Button>
										</Tooltip>
									)}
								</div>
							) : (
								<div> -- </div>
							)}
						</div>
						<div className='segment-3cols'>
							<div className='encounter-field-value'>
								{_.get(
									props,
									'billingencounter.athena_claim_err_msg'
								) ? (
									<div className='alerticondiv'>
										<ReactSVG src={alert} />
										{_.get(
											props,
											[
												'billingencounter',
												'athena_claim_err_msg',
											],
											'--'
										)}
									</div>
								) : (
									'--'
								)}
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Insurance Claim Date
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								CPT 96040,95 (Unit)
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								CPT S0265,95 (Unit)
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{EncounterDateFieldsDisplay(props, [
									'billingencounter',
									'insurance_claim_date',
								])}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-multi-cols-section'>
								<div>
									<CommonCheckbox
										disabled={true}
										checked={_.get(
											props,
											[
												'billingencounter',
												'cpt_96040_95',
											],
											true
										)}
										marginLeftOverride={0}
										marginRightOverride={0}
									/>
								</div>
								<div className='encounter-input-section'>
									<div className='encounter-field-value'>
										{encounterFieldDisplay(props, [
											'billingencounter',
											'units',
										])}
									</div>
								</div>
							</div>
						</div>

						<div className='segment-col'>
							<div className='encounter-multi-cols-section'>
								<div>
									<CommonCheckbox
										disabled={true}
										checked={_.get(
											props,
											[
												'billingencounter',
												'cpt_s0265_95',
											],
											false
										)}
										marginRightOverride={0}
										marginLeftOverride={0}
									/>
								</div>
								<div className='encounter-input-section'>
									<div className='encounter-field-value'>
										{encounterFieldDisplay(props, [
											'billingencounter',
											'units_s0265_95',
										])}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Encounter UUID
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Insurance Expected Revenue
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Athena Insurance Package ID
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Athena Insurance Record ID
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Athena Authorization ID
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{encounterFieldDisplay(props, [
									'encounter',
									'uuid',
								]).toLowerCase()}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{valueWithDollar(props, [
									'billingencounter',
									'insurance_expected_revenue',
								])}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{encounterFieldDisplay(props, [
									'billingencounter',
									'athena_insurance_package_id',
								])}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{encounterFieldDisplay(props, [
									'billingencounter',
									'athena_insurance_id',
								])}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{encounterFieldDisplay(props, [
									'billingencounter',
									'athena_authorization_id',
								])}
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Athena Supervising Provider ID
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Athena Rendering Provider ID
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Athena Referring Provider ID
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Athena Payment Batch ID
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{encounterFieldDisplay(props, [
									'billingencounter',
									'athena_supervising_provider_id',
								])}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{encounterFieldDisplay(props, [
									'billingencounter',
									'athena_rendering_provider_id',
								])}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{encounterFieldDisplay(props, [
									'billingencounter',
									'athena_referring_provider_id',
								])}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{encounterFieldDisplay(props, [
									'billingencounter',
									'athena_payment_batch_id',
								])}
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row segment-row-title'>
					<div className='segment-col'>BILLING NOTES</div>
				</div>
				<div className='segment-row'>
					<div
						className='segment-col encounter-field-billing-value'
						style={{
							width: '100%',
							whiteSpace: 'pre-wrap',
							wordBreak: 'break-word',
						}}
					>
						{encounterFreeTextFieldDisplay(props, [
							'billingencounter',
							'billing_notes',
						])}
					</div>
				</div>
			</div>
		</Loading>
	);
};

export default BillingSegment;
