import { object } from 'zod';

import {
	initialAdditionalPatientInfo,
	initialExternalCareTeam,
	initialPatientDemographicsAndContact,
	initialServiceAndProgram,
	initialNotesAndDocuments,
	schemaAdditionalPatientInfo,
	schemaExternalCareTeam,
	schemaPatientDemographicsAndContact,
	schemaServiceAndProgram,
} from './sections';

import { formValidation } from '@gm/common/ui';

export const schema = object({
	additionalPatientInfo: schemaAdditionalPatientInfo,
	externalCareTeam: schemaExternalCareTeam,
	patientDemographicsAndContact: schemaPatientDemographicsAndContact,
	serviceAndProgram: schemaServiceAndProgram,
}).required();

/**
 * Initial values for the form
 */
export const initialValues = {
	additionalPatientInfo: initialAdditionalPatientInfo,
	externalCareTeam: initialExternalCareTeam,
	notesAndDocuments: initialNotesAndDocuments,
	patientDemographicsAndContact: initialPatientDemographicsAndContact,
	serviceAndProgram: initialServiceAndProgram,
};

export function validateForm(input) {
	return formValidation(schema, input);
}
