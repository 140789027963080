import z from 'zod';

import { schemaServiceInfoAndScheduling } from '../ScheduleAppointment/sections/ServiceInfoAndScheduling';
export { initialValues, validateForm } from '../ScheduleAppointment/validation';

export const schema = z
	.object({
		serviceInfoAndScheduling: schemaServiceInfoAndScheduling,
	})
	.required();
