import * as actionTypes from '../actions/actionTypes';

export const defaultState = {
	user: {},
	portals: [],
	permissions: {},
	activerole: undefined,
	updateMeLoading: false,
	updateMeLoaded: false,
	updateMeData: null,
	UpdateMeError: null
};

export default (state = defaultState, action = {}) => {
	switch (action.type) {
		case actionTypes.ME_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case actionTypes.UPDATE_ME_START:
			return {
				...state,
				updateMeLoading: true,
				updateMeLoaded: false,
				updateMeData: null,
				UpdateMeError: null,
			}
		case actionTypes.UPDATE_ME_SUCCESS:
			return {
				...state,
				updateMeLoading: false,
				updateMeLoaded: true,
				updateMeData: action.payload,
				UpdateMeError: null,
			}
		case actionTypes.UPDATE_ME_FAILURE:
			return {
				...state,
				updateMeLoading: false,
				updateMeLoaded: true,
				updateMeData: null,
				UpdateMeError: action.payload,
			}
		default:
			return state;
	}
};
