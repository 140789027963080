//React & Redux
import React, { Component, Fragment } from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Layout, Loading, Button } from 'gm-element-react';

//Styles
import './css/coupon-code.css';

class SummaryCouponCode extends Component {
	constructor(props) {
		super(props);
		this.state = {
			couponCode: '',
			loading: false,
		};
		//bindings
		this.handleApplyCouponCode = this.handleApplyCouponCode.bind(this);
		this.handleRemoveCouponCode = this.handleRemoveCouponCode.bind(this);
	}

	//functions

	handleApplyCouponCode(e) {
		e.preventDefault();
		const selectedconsulation = this.props.selectedconsultation();
		let product_id = _.get(
			this,
			['props', 'newAppointment', 'consultation_id'],
			null
		);
		let product_type = 'consultation';

		if (
			_.get(selectedconsulation, 'is_predefined') &&
			_.get(selectedconsulation, ['purchased_service_package', '0', 'id'])
		) {
			product_id = _.get(
				selectedconsulation,
				['purchased_service_package', '0', 'id'],
				null
			);
			product_type = 'service_package';
		}

		const data = {
			code: this.state.couponCode,
			products: [
				{
					product_id: product_id,
					product_type: product_type,
				},
			],
		};

		let patient_uuid = _.get(this, ['props', 'newAppointment', 'patientInfo', 'uuid'])

		this.props.getApplyCouponCodeDetails(patient_uuid, data);
	}

	handleRemoveCouponCode(e) {
		e.preventDefault();
		this.setState({ couponCode: '' });
		this.props.resetCouponCodeDetails();
	}

	handleCouponCodeChange(e) {
		this.setState({ couponCode: e.target.value });
	}

	canBeApplyCoupon() {
		const { couponCode } = this.state;

		return couponCode && couponCode.length > 0;
	}

	getDiscountValue = () => {
		let discountValue = _.get(
			this,
			['props', 'couponCodeSuccess', 'discount_info', 'discount_value'],
			0
		);
		const consulationPrice = _.get(this, ['props', 'consulationPrice']);
		if (consulationPrice !== undefined) {
			discountValue =
				discountValue >= consulationPrice
					? consulationPrice
					: discountValue;
		}
		return discountValue;
	};
	render() {
		const isEnabledApplyCoupon = this.canBeApplyCoupon();
		const isLoading = _.get(
			this,
			['props', 'loadingCouponCodeDetail'],
			false
		);
		const couponCodeSuccess = _.get(
			this,
			['props', 'couponCodeSuccess'],
			null
		);
		const couponCodeWarning = _.get(
			this,
			['props', 'couponCodeWarning'],
			null
		);
		const couponCodeError = _.get(this, ['props', 'couponCodeError'], null);
		let errCouponCodeMessage = !_.isNil(couponCodeWarning)
			? _.get(couponCodeWarning, ['message'], null)
			: !_.isNil(couponCodeError)
			? _.get(couponCodeError, ['message'], null)
			: null;
		errCouponCodeMessage = !_.isNil(errCouponCodeMessage)
			? 'Coupon code is invalid'
			: '';

		return (
			<Fragment>
				<div className='scheduleAppointmentSideContainerSection summary-couponcode-container'>
					<Loading
						loading={isLoading}
						className='summary-couponcode-loader'
					>
						{_.isNil(couponCodeSuccess) && (
							<Fragment>
								<Layout.Row>
									<Layout.Col
										span='24'
										className='lbl-coupon-code'
									>
										Coupon code
									</Layout.Col>
								</Layout.Row>
								<Layout.Row gutter='20'>
									<Layout.Col
										span='16'
										style={{ paddingRight: '16px' }}
									>
										<input
											value={this.state.couponCode}
											placeholder='FRE3_GM1'
											className='txt-coupon-code'
											onChange={(e) =>
												this.handleCouponCodeChange(e)
											}
											onKeyPress={(e) =>
												e.key == 'Enter' &&
												isEnabledApplyCoupon &&
												this.handleApplyCouponCode(e)
											}
										/>
									</Layout.Col>
									<Layout.Col
										span='8'
										style={{ paddingLeft: '0' }}
									>
										<Button
											type='primary'
											className='btn-coupon-code'
											disabled={!isEnabledApplyCoupon}
											onClick={(e) =>
												this.handleApplyCouponCode(e)
											}
										>
											Apply
										</Button>
									</Layout.Col>
									<Layout.Col
										span='24'
										className='error-message'
									>
										{errCouponCodeMessage}
									</Layout.Col>
								</Layout.Row>
							</Fragment>
						)}
						{!_.isNil(couponCodeSuccess) && (
							<Fragment>
								<Layout.Row>
									<Layout.Col
										span='24'
										className='lbl-coupon-code'
									>
										Coupon:
									</Layout.Col>
								</Layout.Row>
								<Layout.Row gutter='20'>
									<Layout.Col
										span='14'
										className='val-coupon-code'
									>
										{_.get(couponCodeSuccess, 'code', '')}{' '}
										(-${this.getDiscountValue()})
									</Layout.Col>
									{_.get(
										this,
										['props', 'activeItem'],
										-1
									) === 2 ? (
										<Layout.Col
											span='10'
											className='btn-remove-coupon-code'
										>
											<Button
												type='text'
												onClick={(e) =>
													this.handleRemoveCouponCode(
														e
													)
												}
											>
												[Remove]
											</Button>
										</Layout.Col>
									) : null}
								</Layout.Row>
							</Fragment>
						)}
					</Loading>
				</div>
			</Fragment>
		);
	}
}

export default SummaryCouponCode;
