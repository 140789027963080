//React & Redux
import React, { Component } from 'react';

//Other Libraries
import moment from 'moment-timezone';

let tickTimer;
class LiveClock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			display: '',
		};
	}

	componentDidMount() {
		const display = moment()
			.tz(this.props.timezone)
			.format(this.props.format);
		this.setState({ display });
		tickTimer = setInterval(() => {
			const display = moment()
				.tz(this.props.timezone)
				.format(this.props.format);
			if (display !== this.state.display) this.setState({ display });
		}, this.props.interval);
	}

	componentWillUnmount() {
		if (tickTimer) {
			clearInterval(tickTimer);
		}
	}

	render() {
		return <time>{this.state.display}</time>;
	}
}

LiveClock.defaultProps = {
	interval: 1000,
	format: 'MMMM Do YYYY, h:mm:ss a z',
	timezone: moment.tz.guess(),
};

export default LiveClock;
