//React & Redux
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import { guardianRelationships } from '../../Utils/appointmentUtils';
import { EmailValidator } from '../../Utils/validate';

//UI Libraries
import { Layout, Input, Button } from 'gm-element-react';
import InputMask from 'react-input-mask';
import { createEnumsOptions } from '../Patients/V2/Encounters/Common/utilsfunctions';

//Other Libraries
import { isValidNumber } from 'libphonenumber-js';
import moment from 'moment';

//Components
import Icon from '../Common/Icon';
import AppointmentSection from './AppointmentSection';
import Tooltip from '../Common/Tooltip';
import { SelectComponent as Select } from '../Common/SelectComponent';

//Styles
import './css/select-patient.css';

const validate = (values) => {
	const errors = {};
	const isEmpty = (value) => _([undefined, null, '']).includes(value);

	if (isEmpty(values.primaryPhone)) {
		errors.primaryPhone = 'Phone is required';
	} else if (!isValidNumber(values.primaryPhone))
		errors.primaryPhone = 'Phone number is invalid';

	if (
		!isEmpty(values.secondaryPhone) &&
		!isValidNumber(values.secondaryPhone)
	) {
		errors.secondaryPhone = 'Secondary Phone number is invalid';
	}

	if (values.hasGuardian) {
		if (isEmpty(values.guardianFirstName)) {
			errors.guardianFirstName = 'First name is required';
		}
		if (isEmpty(values.guardianLastName)) {
			errors.guardianLastName = 'Last name is required';
		}
		if (isEmpty(values.guardianRelationship)) {
			errors.guardianRelationship = 'Relationship is required';
		}
		if (isEmpty(values.guardianPrimaryPhone)) {
			errors.guardianPrimaryPhone = 'Phone is required';
		} else if (!isValidNumber(values.guardianPrimaryPhone)) {
			errors.guardianPrimaryPhone = 'Phone number is invalid';
		}
		if (
			!isEmpty(values.guardianSecondaryPhone) &&
			!isValidNumber(values.guardianSecondaryPhone)
		) {
			errors.guardianSecondaryPhone = 'Secondary Phone number is invalid';
		}
		if (isEmpty(values.guardianDOB)) {
			errors.guardianDOB = 'DOB is required';
		} else if (!moment(values.guardianDOB, 'MM/DD/YYYY', true).isValid()) {
			errors.guardianDOB = 'DOB is invalid';
		}
	}

	if (isEmpty(values.email)) {
		errors.email = 'Email is required';
	} else if (!EmailValidator.validate(values.email))
		errors.email = 'Email is invalid';

	if (isEmpty(values.consentToTreat)) {
		errors.consentToTreat = 'Consent to treat is required';
	}

	return errors;
};

const renderField = ({
	label,
	input,
	meta,
	placeholder,
	trim,
	className = '',
	disabled,
	isrequired,
}) => {
	return (
		<div className='input-row'>
			<label className={`${isrequired ? 'required' : ''}`}>{label}</label>
			<Input
				{...input}
				placeholder={placeholder}
				trim={trim}
				className={className + ' erFieldText'}
				disabled={disabled}
			/>
			{meta && meta.touched && meta.error && (
				<span className='error'>{meta.error}</span>
			)}
		</div>
	);
};

const renderConsentToTreat = ({
	label,
	input,
	meta,
	options,
	className = '',
	placeholder,
	isrequired,
	typeahead = false,
}) => (
	<div className='input-row'>
		<label className={`${isrequired ? 'required' : ''}`}>{label}</label>
		<Select
			{...input}
			placeholder={placeholder}
			className={className}
			value={input.value}
			disabled={
				_.get(input, 'value', '') == 'consented_via_partner'
					? true
					: false
			}
			typeahead={typeahead}
			clearable={
				_.get(input, 'value', '') != 'consented_via_partner'
					? true
					: false
			}
			options={options}
		/>
		{meta && meta.touched && meta.error && (
			<span className='error'>{meta.error}</span>
		)}
	</div>
);

const renderSelect = ({
	label,
	input,
	meta,
	placeholder,
	isrequired,
	onVisibleChange,
	options,
	typeahead = false,
	clearable = false,
}) => (
	<div className='input-row'>
		<label className={`${isrequired ? 'required' : ''}`}>{label}</label>
		<Select
			{...input}
			placeholder={placeholder}
			value={input.value}
			onVisibleChange={onVisibleChange}
			className='inputFieldDropdown'
			typeahead={typeahead}
			clearable={clearable}
			options={options}
		/>
		{meta && meta.touched && meta.error && (
			<span className='error'>{meta.error}</span>
		)}
	</div>
);

const renderInputMask = (field) => (
	<div className='input-row'>
		<label className={`${field.isrequired ? 'required' : ''}`}>
			{field.label}
		</label>
		<InputMask {...field.input} {...field} />
		{field.meta.touched && field.meta.error && (
			<span className='error'>{field.meta.error}</span>
		)}
	</div>
);

const phoneTypes = [
	{ key: 'Home', value: 'Home', label: 'Home' },
	{ key: 'Office', value: 'Office', label: 'Office' },
	{ key: 'Mobile', value: 'Mobile', label: 'Mobile' },
];

class AppointmentPatientInfoForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasGuardian: _.get(props, ['initialValues', 'hasGuardian'], false),
			showAddressCompletionWarning: false,
		};
		//bindings
		this.touchfield = this.touchfield.bind(this);
		this.determineEmailStatus = this.determineEmailStatus.bind(this);
	}

	//functions

	onSubmit = async (values) => {
		await this.props.setFirstStageSubmitAttempt();
		const addressCompletionWarning = get(
			this,
			'props.addressCompletionWarning',
			false
		);
		if (!addressCompletionWarning) {
			return this.props.onSubmit(values);
		}
	};

	touchfield(value, fieldname) {
		if (!value) {
			if (_.isFunction(_.get(this, ['props', 'touch']))) {
				this.props.touch(fieldname);
			}
		}
	}

	determineEmailStatus() {
		if (
			this.props.determineEmailStatus(
				_.get(this, 'props.initialValues.email', '') || ''
			)
		) {
			return (
				<div style={{ position: 'relative' }}>
					Email
					<Tooltip
						className='emailAlertIcon'
						effect='dark'
						content={
							<div style={{ whiteSpace: 'nowrap' }}>
								Email address unknown.
								<br />
								Please update it.
							</div>
						}
						placement='top-start'
						visible={true}
					>
						<Icon icon='warning-circle' className='warningIcon' />
					</Tooltip>
				</div>
			);
		} else if (this.state.hasGuardian) {
			return "Guardian's Email";
		} else {
			return "Patient's Email";
		}
	}

	handleHasGuardian = () => {
		this.setState({ hasGuardian: !this.state.hasGuardian });
	};

	render() {
		const { handleSubmit, valid, initialValues } = this.props;
		const { hasGuardian } = this.state;
		const addresses = get(this, 'props.addresses', null) || [];
		const hasAddresses = get(addresses, 'length', 0);
		return (
			<form
				onSubmit={handleSubmit(this.onSubmit)}
				className='select-patient-form'
			>
				<AppointmentSection
					heading='Verify Contact Information'
					description='Please verify the Patient’s contact information'
					isRequired={true}
					horizontalLine={true}
				>
					<section className='patient-info-container'>
						<Layout.Row
							gutter='16'
							style={{ marginBottom: '27px' }}
						>
							<Layout.Col span='24'>
								<Field
									name='hasGuardian'
									label='Does the Patient have a Guardian or Power of Attorney?'
									component={this.props.renderSwitch}
									onChange={this.handleHasGuardian}
									value={this.state.hasGuardian}
									isrequired={false}
									options={[
										{ label: 'Yes', value: true },
										{
											disabled: _.get(
												initialValues,
												'hasGuardian',
												false
											),
											label: 'No',
											value: false,
										},
									]}
								/>
							</Layout.Col>
						</Layout.Row>
						<Layout.Row
							gutter='16'
							style={{ marginBottom: '19px' }}
						>
							<Layout.Col span='24'>
								<Field
									name='email'
									type='text'
									label={this.determineEmailStatus()}
									placeholder='Email Address'
									trim={true}
									className='erFieldTextWider'
									component={renderField}
									disabled={false}
									isrequired={true}
								/>
							</Layout.Col>
						</Layout.Row>
						{hasGuardian && (
							<Fragment>
								<Layout.Row
									gutter='16'
									style={{ marginBottom: '19px' }}
								>
									<Layout.Col span='12'>
										<Field
											name='guardianFirstName'
											type='text'
											label='Guardian’s First Name'
											placeholder='Guardian’s First Name'
											trim={true}
											component={renderField}
											disabled={false}
											isrequired={true}
										/>
									</Layout.Col>
									<Layout.Col span='12'>
										<Field
											name='guardianLastName'
											type='text'
											label='Guardian’s Last Name'
											placeholder='Guardian’s Last Name'
											trim={true}
											component={renderField}
											disabled={false}
											isrequired={true}
										/>
									</Layout.Col>
								</Layout.Row>
								<Layout.Row
									gutter='16'
									style={{ marginBottom: '19px' }}
								>
									<Layout.Col span='12'>
										<Field
											name='guardianRelationship'
											type='select'
											label='Relationship to Patient'
											placeholder='Select'
											component={renderSelect}
											options={guardianRelationships}
											isrequired={true}
											typeahead={true}
											clearable={true}
										/>
									</Layout.Col>
									<Layout.Col span='12'>
										<Field
											name='guardianDOB'
											type='text'
											label='Guardian’s Date of Birth'
											placeholder='MM/DD/YYY'
											mask='99/99/9999'
											maskChar={null}
											autoComplete='off'
											component={renderInputMask}
											className='inputFieldText'
											isrequired={true}
										/>
									</Layout.Col>
								</Layout.Row>
							</Fragment>
						)}
						{hasGuardian ? (
							<Fragment>
								<Layout.Row
									gutter='16'
									style={{ marginBottom: '19px' }}
								>
									<Layout.Col span='12'>
										<Field
											name='guardianPrimaryPhone'
											type='text'
											label='Guardian’s Primary Phone'
											mask='+1 (999) 999-9999'
											placeholder='Primary Phone Number'
											maskChar={null}
											autoComplete='off'
											className='inputFieldText'
											component={renderInputMask}
											isrequired={true}
										/>
									</Layout.Col>
									<Layout.Col span='12'>
										<Field
											name='guardianPrimaryPhoneType'
											type='select'
											label='Primary Phone Type'
											placeholder='Select'
											component={renderSelect}
											options={phoneTypes}
											isrequired={false}
											typeahead={true}
											clearable={true}
										/>
									</Layout.Col>
								</Layout.Row>
								<Layout.Row
									gutter='16'
									style={{ marginBottom: '19px' }}
								>
									<Layout.Col span='12'>
										<Field
											name='guardianSecondaryPhone'
											type='text'
											label='Guardian’s Secondary Phone'
											mask='+1 (999) 999-9999'
											placeholder='Secondary Phone Number'
											maskChar={null}
											autoComplete='off'
											className='inputFieldText'
											component={renderInputMask}
											isrequired={false}
										/>
									</Layout.Col>
									<Layout.Col span='12'>
										<Field
											name='guardianSecondaryPhoneType'
											type='select'
											label='Secondary Phone Type'
											placeholder='Select'
											component={renderSelect}
											options={phoneTypes}
											isrequired={false}
											typeahead={true}
											clearable={true}
										/>
									</Layout.Col>
								</Layout.Row>
							</Fragment>
						) : (
							<Fragment>
								<Layout.Row
									gutter='16'
									style={{ marginBottom: '19px' }}
								>
									<Layout.Col span='12'>
										<Field
											name='primaryPhone'
											type='text'
											label='Patient’s Primary Phone'
											mask='+1 (999) 999-9999'
											placeholder='Primary Phone Number'
											maskChar={null}
											autoComplete='off'
											className='inputFieldText'
											component={renderInputMask}
											isrequired={true}
										/>
									</Layout.Col>
									<Layout.Col span='12'>
										<Field
											name='primaryPhoneType'
											type='select'
											label='Primary Phone Type'
											placeholder='Select'
											component={renderSelect}
											options={phoneTypes}
											isrequired={false}
											typeahead={true}
											clearable={true}
										/>
									</Layout.Col>
								</Layout.Row>
								<Layout.Row
									gutter='16'
									style={{ marginBottom: '19px' }}
								>
									<Layout.Col span='12'>
										<Field
											name='secondaryPhone'
											type='text'
											label='Patient’s Secondary Phone'
											mask='+1 (999) 999-9999'
											placeholder='Secondary Phone Number'
											maskChar={null}
											autoComplete='off'
											className='inputFieldText'
											component={renderInputMask}
											isrequired={false}
										/>
									</Layout.Col>
									<Layout.Col span='12'>
										<Field
											name='secondaryPhoneType'
											type='select'
											label='Secondary Phone Type'
											placeholder='Select'
											component={renderSelect}
											options={phoneTypes}
											isrequired={false}
											typeahead={true}
											clearable={true}
										/>
									</Layout.Col>
								</Layout.Row>
							</Fragment>
						)}
						<Layout.Row
							gutter='16'
							style={{ marginBottom: '19px' }}
						>
							<Layout.Col span='12'>
								<Field
									name='consentToTreat'
									type='select'
									label='Consent to treat'
									placeholder='Consented via phone'
									component={renderConsentToTreat}
									options={createEnumsOptions(
										this,
										['props', 'consenttotreat'],
										'key',
										'display_name',
										'key'
									)}
									isrequired={true}
									typeahead={true}
									clearable={true}
								/>
							</Layout.Col>
						</Layout.Row>
					</section>
				</AppointmentSection>
				<AppointmentSection
					heading='Home & Shipping Address'
					description="Please provide Patient's home address and a shipping address where the Patient would like to receive mail, lab kits, etc."
					isRequired={true}
					horizontalLine={true}
				>
					<section className='home-address-container'>
						{this.props.renderAddressSection()}
					</section>
				</AppointmentSection>
				<Layout.Row className='action-buttons-v2'>
					<Layout.Col span='24' className='sched-submit-button-v2'>
						<a
							className='cancel-button-v2'
							onClick={() =>
								this.props.handleNavigateToAppointmentList()
							}
						>
							Cancel
						</a>
						<Tooltip
							className='continue-button-tooltip-no'
							effect='dark'
							content={
								<div className='tooltip-text'>
									Please fill in all the required fields
								</div>
							}
							placement='top-start'
							disabled={valid && hasAddresses}
						>
							<Button
								nativeType='submit'
								disabled={!(valid && hasAddresses)}
								className='continue-button-v2'
							>
								Continue
							</Button>
						</Tooltip>
					</Layout.Col>
				</Layout.Row>
			</form>
		);
	}
}

AppointmentPatientInfoForm.propTypes = {
	initialValues: PropTypes.object,
};

// Decorate the form component
// eslint-disable-next-line no-class-assign
AppointmentPatientInfoForm = reduxForm({
	form: 'scheduleappointmentselectedPatientInfo', // a unique name for this form
	validate,
})(AppointmentPatientInfoForm);

export default AppointmentPatientInfoForm;
