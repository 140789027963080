import { isNil, omitBy, pickBy } from 'lodash';

const omitNullEmptyHgvsValue = (data) => {
	return pickBy(
		{
			dnaChange: data.dnaChangeDescription,
			transcriptReferenceSequence: data.referenceSequence,
			proteinChange: data.proteinChangeDescription,
		},
		(value) => {
			return !(value === '' || value === null || value === undefined);
		}
	);
};

export const deHydrateDiscreteVariant = (data) => {
	const _deHydrateDiscreteVariant = omitBy(
		{
			geneId: data.geneId,
			allelicState: data.allelicState,
			referenceAssemblyCode: data.referenceAssembly,
			origin: data.origin,
			dnaChangeType: data.dnaChangeType,
			hgvsNomenclature: omitNullEmptyHgvsValue(data),
			genomicSourceClass: data.genomicSourceClass,
			proteinChangeType: data.proteinChangeType,
			allelicFrequency: data.sampleAllelicFrequency,
			originalReportedClassification: data.labClassification,
			cytogenomicNomenclature: data.sequence,
			mosaicism: data.mosaicism,
			assessment: 'PRESENT',
		},
		isNil
	);
	return _deHydrateDiscreteVariant;
};
