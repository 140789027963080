import { get, isEmpty, isNil, size } from 'lodash';

const cfDNAPrenatalFindingEnum = {
	'gm:00000065': 1,
	'gm:00000066': 1,
	'gm:00000128': 1,
	'gm:00000130': 1,
	'gm:00000131': 1,
	'gm:00000132': 1,
	'gm:00000129': 1,
	'gm:00000133': 1,
	'gm:00000134': 1,
	'gm:00000135': 1,
	'gm:00000136': 1,
	'gm:00000137': 1,
	'gm:00000138': 1,
	'gm:00000139': 1,
	'gm:00000140': 1,
	'gm:00000141': 1,
	'gm:00000142': 1,
	'gm:00000143': 1,
	'gm:00000144': 1,
};

export const hydrateDiagnosticReports = (data) => {
	const report = get(data, ['0'], []);
	const findings = get(report, ['findings'], []);
	const hydratedReport = {
		uuid: get(report, ['id'], null),
		observationFinding: [],
		riskAssessmentFinding: [],
		discreteVariantFinding: [],
		structuralVariantFinding: [],
		cfDNAPrenatalObservationFinding: [],
	};
	const _observationFinding = [];
	const _discreteVariantFinding = [];
	const _nucleotideRepeatVariantFinding = [];
	const _riskAssessmentFinding = [];
	const _structuralVariantFinding = [];
	const _cfDNAPrenatalObservationFinding = [];

	if (!isEmpty(findings)) {
		findings.map((finding) => {
			if (finding.__typename === 'ObservationFinding') {
				if (
					get(finding, ['kind', 'id'], '').toLowerCase() ===
					'gm:00000002'
				) {
					_observationFinding.push(finding);
				}
				if (
					get(finding, ['kind', 'id'], '').toLowerCase() in
					cfDNAPrenatalFindingEnum
				) {
					_cfDNAPrenatalObservationFinding.push(finding);
				}
			} else if (finding.__typename === 'RiskAssessmentFinding') {
				_riskAssessmentFinding.push(finding);
			} else if (finding.__typename === 'DiscreteVariantFinding') {
				_discreteVariantFinding.push(finding);
			} else if (
				finding.__typename === 'NucleotideRepeatVariantFinding'
			) {
				_nucleotideRepeatVariantFinding.push(finding);
			} else if (finding.__typename === 'StructuralVariantFinding') {
				_structuralVariantFinding.push(finding);
			}
		});

		const hydratedObservationFindings = _observationFinding.map(
			(finding) => {
				const primary = finding.components.filter(
					(component) =>
						component.kind.id.toLowerCase() === 'gm:00000004'
				);
				const secondary = finding.components.filter(
					(component) =>
						component.kind.id.toLowerCase() === 'gm:00000005'
				);
				return {
					uuid: finding.id,
					cancerSignal: finding.value.label,
					primary: {
						key: get(primary, ['0', 'value', 'id'], null),
						value: get(primary, ['0', 'value', 'label'], null),
					},
					secondary: {
						key: get(secondary, ['0', 'value', 'id'], null),
						value: get(secondary, ['0', 'value', 'label'], null),
					},
					notes: get(finding, ['notes'], []),
					assertedBy: finding?.assertedBy?.__typename || '',
				};
			}
		);

		const hydratedCFDNAPrenatalObservationFindings =
			_cfDNAPrenatalObservationFinding.map((finding) => {
				return {
					uuid: finding.id,
					riskName: finding.kind,
					riskType: finding.value,
					riskPriorResidual: finding.components,
					isHighRiskType:
						get(finding, ['value', 'id'], '').toLowerCase() ===
						'gm:00000106',
					notes: get(finding, ['notes'], []),
					assertedBy: finding?.assertedBy?.__typename || '',
				};
			});

		const hydratedRiskAssessmentFinding = _riskAssessmentFinding.map(
			(finding) => {
				return {
					uuid: finding?.id || null,
					type: finding?.type?.label || '',
					version: finding?.version || '',
					predictions: finding?.predictions || [],
					notes: finding?.notes || '',
				};
			}
		);

		const hydratedDiscreteVariantFindings = _discreteVariantFinding.map(
			(finding) => {
				return {
					uuid: finding?.id || null,
					genesSymbol: finding?.gene || {},
					allelicState: finding?.allelicState || null,
					hasHgvsNomenclature: !isNil(
						finding?.hgvsNomenclature || null
					),
					dnaChangeDescription:
						finding?.hgvsNomenclature?.dnaChange || null,
					proteinChangeDescription:
						finding?.hgvsNomenclature?.proteinChange || null,
					referenceSequence:
						finding?.hgvsNomenclature
							?.transcriptReferenceSequence || null,
					sequence: finding?.cytogenomicNomenclature || null,
					labClassification:
						finding?.mostRecentInterpretation?.classification ||
						null,
					interpretations: finding?.interpretations || [],
					dnaChangeType: finding?.dnaChangeType || null,
					proteinChangeType: finding?.proteinChangeType || null,
					sampleAllelicFrequency: finding?.allelicFrequency || null,
					referenceAssembly: finding.referenceAssembly || null,
					mostRecentInterpretation:
						finding?.mostRecentInterpretation || {},
					originalReportedInterpretation:
						finding?.originalReportedInterpretation || {},
					genomicSourceClass: finding?.genomicSourceClass || '',
					mosaicism: finding?.mosaicism || '',
					origin: finding?.origin || '',
					notes: finding?.notes || [],
					dbsnpId: finding?.dbsnpId || '',
					assessment: finding?.assessment || 'PRESENT',
					assertedBy: finding?.assertedBy?.__typename || '',
				};
			}
		);

		const hydratedNucleotideRepeatVariantFinding =
			_nucleotideRepeatVariantFinding.map((finding) => {
				return {
					uuid: finding?.id || null,
					genesSymbol: finding?.gene || {},
					referenceSequence: finding?.referenceSequence || null,
					hgvsDNAChange: finding?.hgvsDNAChange || null,
					referenceAssembly: finding.referenceAssembly || null,
					mostRecentInterpretationAllele1:
						finding?.allele1?.mostRecentInterpretation || {},
					mostRecentInterpretationAllele2:
						finding?.allele2?.mostRecentInterpretation || {},
					originalReportedInterpretationAllele1:
						finding?.allele1?.originalReportedInterpretation || {},
					originalReportedInterpretationAllele2:
						finding?.allele2?.originalReportedInterpretation || {},
					allele1: finding?.allele1 || {},
					allele2: finding?.allele2 || {},
					genomicSourceClass:
						finding?.nucleotideGenomicSourceClass || '',
					notes: finding?.notes || [],
					assessment: finding?.assessment || 'PRESENT',
					assertedBy: finding?.assertedBy?.__typename || '',
				};
			});

		const hydratedStructuralVariantFinding = _structuralVariantFinding.map(
			(finding) => {
				return {
					uuid: finding?.id || null,
					geneticExtents: finding?.geneticExtents || [],
					chromosomalExtents: finding?.chromosomalExtents || [],
					sequence: finding?.cytogenomicNomenclature || null,
					characterization:
						finding?.cytogenomicCharacterization || null,
					copyNumber: finding?.copyNumber ?? null,
					sizeValue: finding?.size?.value ?? null,
					unit: finding?.size?.unit || null,
					variantType: finding?.variantType || null,
					labClassification:
						finding?.mostRecentInterpretation?.classification ||
						null,
					referenceAssembly:
						finding?.structuralReferenceAssembly || null,
					mostRecentInterpretation:
						finding?.mostRecentInterpretation || {},
					genomicSourceClass: finding?.genomicSourceClass || '',
					origin: finding?.origin || '',
					mosaicism: finding?.mosaicism || '',
					notes: finding?.notes || [],
					assessment: finding?.assessment || 'PRESENT',
					hasGeneticExtents: size(finding?.geneticExtents || []) > 0,
					hasChromosomalExtents:
						size(finding?.chromosomalExtents || []) > 0,
					hasISCN: !isNil(finding?.cytogenomicNomenclature),
					assertedBy: finding?.assertedBy?.__typename || '',
				};
			}
		);

		return {
			...hydratedReport,
			observationFinding: hydratedObservationFindings,
			riskAssessmentFinding: hydratedRiskAssessmentFinding,
			discreteVariantFinding: hydratedDiscreteVariantFindings,
			structuralVariantFinding: hydratedStructuralVariantFinding,
			cfDNAPrenatalObservationFinding:
				hydratedCFDNAPrenatalObservationFindings,
			nucleotideRepeatVariantFinding:
				hydratedNucleotideRepeatVariantFinding,
		};
	} else return hydratedReport;
};
