import classnames from 'classnames';
import { Input as ElInput } from 'gm-element-react';
import React from 'react';

import { FormField, IFormFieldProps } from './FormField';

import './InputField.scss';

interface IProps extends IFormFieldProps {
	disabled?: boolean;
	value?: string;
	type?: string;
	onChange: (value: string) => void;
	placeholder?: string;
	maxLength?: number;
}

export const InputField: React.FC<IProps> = (props) => {
	const {
		className,
		name,
		label,
		required,
		error,
		tooltip,
		value,
		type,
		onChange,
		disabled,
		placeholder,
		maxLength,
	} = props;
	return (
		<FormField
			className={classnames('gm-input', className)}
			name={name}
			label={label}
			required={required}
			error={error}
			tooltip={tooltip}
		>
			<ElInput
				maxLength={maxLength}
				name={name}
				onChange={onChange}
				value={value}
				type={type}
				disabled={disabled}
				placeholder={placeholder}
			/>
		</FormField>
	);
};
