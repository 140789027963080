import React from 'react';
import LinkPopover from '../../../Common/Popovers/LinkPopover';

const WorkflowDocuments = (props) => {
	const { affiliationWorkflowDocs = [], enqueueSnackbar } = props;

	const openAffiliationDoc = (link) => link && window.open(link, '_blank');

	return (
		<div className='documentAffiliationDocsContainer'>
			<div className='documentAffiliationDocsHeader'>
				Workflow documents
			</div>
			<div className='documentAffiliationDocsRow'>
				{affiliationWorkflowDocs.map((el, i) => {
					const totalCount = affiliationWorkflowDocs.length;
					let width;
					let marginRight;
					let marginLeft;

					switch (totalCount) {
						case 1:
						case 2:
							width = 'calc(50% - 6px)';
							marginRight = '6px';
							marginLeft = '6px';
							break;
						case 3:
							width = 'calc(33% - 4px)';
							marginRight = '8px';
							marginLeft = '4px';
							break;
						case 4:
						default:
							width = 'calc(25% - 9px)';
							marginRight = '6px';
							marginLeft = '6px';
							break;
					}

					return (
						<div
							data-testId='workflow-doc-container'
							className='documentAffiliationDocContainer'
							key={el.file_name}
							style={{
								width,
								marginRight:
									i === totalCount - 1 ? '0px' : marginRight,
								marginLeft: i === 0 ? '0px' : marginLeft,
								minWidth: 'unset',
							}}
							onClick={() =>
								openAffiliationDoc(el.external_storage_id)
							}
						>
							<LinkPopover
								enqueueSnackbar={enqueueSnackbar}
								linkTo={el.external_storage_id}
								options={{ link: true, text: true, copy: true }}
							>
								<div className='documentAffiliationDocHeaderRow'>
									<div
										className='documentAffiliationDocHeaderRowTitles'
										style={{
											maxWidth:
												totalCount === 4
													? 'calc(100% - 10px)'
													: 'calc(100% - 68px)',
										}}
									>
										<div className='documentAffiliationDocHeaderRowTitlesTitle'>
											{el.file_name}
										</div>
										<div className='documentAffiliationDocHeaderRowTitlesSubheader'>
											{el.document_type.display_name}
										</div>
									</div>

									<div className='documentAffiliationDocHeaderRowButtons'></div>
								</div>
								<div
									className='documentAffiliationDocDescriptionRow'
									style={{ display: 'none' }}
								>
									{el.description}
								</div>
							</LinkPopover>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default WorkflowDocuments;
