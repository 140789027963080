import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import z from 'zod';
import { useFormikContext } from 'formik';
import moment from 'moment-timezone';

import { Section } from '@gm/common/ui';
import { useSection } from '../../../../Patients/V3/CreatePatient/sections/useSection';
import Button from '../../../../Common/Button';
import FormField from '../../../../Common/FormFields/FormField';
import Icon from '../../../../Common/Icon';
import AppointmentAlert from '../../../AppointmentAlert';
import { PAYMENT_METHOD } from './PaymentInfo';

import { LoadPatientConsultations } from '../../../../../actions/scheduling';
import { getDefaultSchedulingOptions } from '../../../../../actions/referralprograms/referralprograms';

import './Summary.scss';
import { CouponCodeSection } from './CouponCodeSection';

export const schemaSummary = z.object({
	couponCode: z.string().nullable(),
	couponCodeApplied: z.boolean(),
	showAddCouponCodeBtn: z.boolean(),
	displayPrice: z.number().nullable(),
});

export const initialSummary = {
	couponCode: null,
	couponCodeApplied: false,
	showAddCouponCodeBtn: false,
	displayPrice: null,
};

export function Summary({
	sectionDataContainerName,
	showPaymentSection = true,
	showLinkToPatientAccount,
	submitForm,
}) {
	const { setFieldValue: summarySetFieldValue, values: summaryValues } =
		useSection(sectionDataContainerName);
	const { setFieldValue, values } = useFormikContext();
	const [showAppointmentAlert, setShowAppointmentAlert] = useState(false);
	const {
		providers,
		referralPrograms,
		defaultSchedulingOptions,
		insuranceCompany,
		patientDetails,
		schedulingConsultations,
	} = useSelector((state) => ({
		providers: state.Scheduling?.providers,
		referralPrograms: state.referralPrograms.referralPrograms.data ?? [],
		defaultSchedulingOptions:
			state.referralProgramsList?.defaultSchedulingOptions?.payload,
		insuranceCompany:
			state.insurancecompany?.loadedGetInsuranceCompanies?.data ?? [],
		patientDetails: state.patientdetail?.patientdetail?.[0],
		schedulingConsultations: state.Scheduling?.schedulingConsultations,
	}));

	const dispatch = useDispatch();

	useEffect(() => {
		if (values.patientInfo.associatedProgram) {
			const partner_uuid = referralPrograms.find(
				(it) => it.id === values.patientInfo.associatedProgram
			)?.partner_uuid;
			if (partner_uuid) {
				dispatch(getDefaultSchedulingOptions({ partner_uuid }));
				dispatch(
					LoadPatientConsultations(
						patientDetails.id,
						values.patientInfo.associatedProgram
					)
				);
			}
		}
	}, [values.patientInfo.associatedProgram]);

	const { showAddCouponCodeBtn, couponCodeApplied } = summaryValues;

	const { patientInfo, serviceInfoAndScheduling, paymentInfo } = values;
	const { dateOfBirth, associatedProgram } = patientInfo;
	const { timezone, timeslot, provider, specialty, consultationType } =
		serviceInfoAndScheduling;
	const { paymentMethod, healthInsurance } = paymentInfo;

	const providerName = providers.find((c) => c.id === provider)?.full_name;
	const specialtyName = defaultSchedulingOptions?.specialties?.find(
		(item) => item.name.toLowerCase() === specialty
	)?.display_name;

	// The affiliation pricing cannot be found from the defaultSchedulingOptions
	const selectedConsultation = schedulingConsultations?.find(
		(item) => item.id === consultationType
	);

	const consultationName = selectedConsultation?.name;
	const consultationPrice = parseFloat(selectedConsultation?.price);

	const formattedDateOfBirth = dateOfBirth
		? moment(dateOfBirth).format('YYYY-MM-DD')
		: '';
	const age = dateOfBirth ? moment().diff(moment(dateOfBirth), 'years') : '';

	const selectedTime =
		timeslot && timezone
			? moment(timeslot).tz(timezone).format('hh:mm A zz')
			: '--';
	const selectedDate =
		timeslot && timezone
			? moment(timeslot).tz(timezone).format('MM/DD/YYYY')
			: '--';

	const partner = referralPrograms.find(
		(item) => item.id === associatedProgram
	);
	const partnerName = partner?.display_name;
	const partnerId = partner?.id;

	let displayPrice = null;

	if (paymentMethod === PAYMENT_METHOD.INSURANCE) {
		const associatedInsurance = insuranceCompany.find(
			(item) => item.companyID === healthInsurance
		);
		if (associatedInsurance) {
			const { consultationPrice, insurance_prepay_exceptions = [] } =
				associatedInsurance;
			const insuranceException = insurance_prepay_exceptions.find(
				(exception) => exception.partner_id === partnerId
			);
			//If there is an exception based on referral program, use that price, otherwise default insurance based consultation price
			displayPrice =
				parseInt(insuranceException?.consultation_price) ||
				consultationPrice;
		}
	} else {
		// Self pay affiliation price set from the admin portal
		displayPrice = consultationPrice;
	}

	useEffect(() => {
		if (paymentMethod === PAYMENT_METHOD.INSURANCE) {
			summarySetFieldValue('couponCodeApplied', false);
			summarySetFieldValue('showAddCouponCodeBtn', false);
			summarySetFieldValue('couponCode', null);
		}
	}, [paymentMethod]);

	useEffect(() => {
		summarySetFieldValue('displayPrice', displayPrice);
	}, [displayPrice]);

	const isCouponCodeShow = () => {
		if (
			paymentMethod === PAYMENT_METHOD.NON_INSURANCE &&
			displayPrice > 0
		) {
			return true;
		}
	};

	const updateDisplayPrice = (newDisplayPrice) => {
		summarySetFieldValue('displayPrice', newDisplayPrice);
		setFieldValue('paymentInfo.consultationPrice', newDisplayPrice);
	};

	const addCouponCode = () => {
		summarySetFieldValue('showAddCouponCodeBtn', true);
	};

	return (
		<Section title='Summary'>
			<div className='info-container'>
				<p className='section-title'>Patient Details</p>
				<div className='column-layout-2'>
					<FormField label='Name'>
						{patientInfo.patientFirstName &&
						patientInfo.patientLastName
							? `${patientInfo.patientFirstName} ${patientInfo.patientLastName}`
							: '--'}
					</FormField>
					<FormField label='Date of Birth'>
						{formattedDateOfBirth
							? `${formattedDateOfBirth} (${age} yrs old)`
							: '--'}
					</FormField>
					<FormField label='Time Zone'>{timezone || '--'}</FormField>
					<FormField label='Program'>{partnerName || '--'}</FormField>
				</div>
				{showLinkToPatientAccount && (
					<div className='patient-account-link'>
						<Icon className='link-icon' icon='external-link' />
						<Link
							target='_blank'
							to={`/app/patientdetail/${values.patientInfo.patientUUID}/0/0`}
						>
							View Patient Account
						</Link>
					</div>
				)}
			</div>
			<hr className='separator' />
			<div className='info-container'>
				<p className='section-title'>Appointment Details</p>
				<div className='column-layout-2'>
					<FormField label='Date'>{selectedDate || '--'}</FormField>
					<FormField label='Time'>{selectedTime || '--'}</FormField>
					<FormField label='Type'>
						{consultationName || '--'}
					</FormField>
					<FormField label='Provider'>
						{providerName || 'No Preference'}
					</FormField>
					<FormField label='Specialty'>
						{specialtyName || '--'}
					</FormField>
				</div>
			</div>
			{showPaymentSection && (
				<>
					<hr className='separator' />
					<div className='payment-section'>
						<p className='section-title'>Payment</p>
						<CouponCodeSection
							sectionDataContainerName={sectionDataContainerName}
							totalPrice={displayPrice}
							selectedConsultation={selectedConsultation}
							patientDetails={patientDetails}
							updateDisplayPrice={updateDisplayPrice}
							isCouponCodeShow={isCouponCodeShow}
						/>
						<div className='payment-row'>
							<div className='payment-details'>
								<div className='payment-text'>
									Visit{' '}
									{paymentMethod === PAYMENT_METHOD.INSURANCE
										? 'Deposit'
										: 'Fee'}
								</div>
								<div className='payment-value'>
									{summaryValues.displayPrice !== '' &&
									summaryValues.displayPrice !== undefined &&
									summaryValues.displayPrice !== null
										? `$${summaryValues.displayPrice}`
										: '--'}
								</div>
							</div>
							{isCouponCodeShow() === true && (
								<div className='add-coupon-code'>
									{showAddCouponCodeBtn === false &&
										couponCodeApplied === false && (
											<button
												type='primary'
												className='btn-add-coupon-code'
												onClick={(e) => addCouponCode()}
											>
												<span>Add Coupon Code</span>
											</button>
										)}
								</div>
							)}
						</div>
					</div>
				</>
			)}
			<Button
				className='submit-button'
				onClick={() => {
					// show appointment alert if selected time slot starts
					// within 8 hours
					if (
						values.serviceInfoAndScheduling.timeslot &&
						moment(
							values.serviceInfoAndScheduling.timeslot
						).isSameOrBefore(moment().add(8, 'hours'))
					) {
						setShowAppointmentAlert(true);
					} else {
						void submitForm();
					}
				}}
			>
				Book visit
			</Button>
			{showAppointmentAlert && (
				<AppointmentAlert
					cancel={() => {
						setFieldValue(
							'serviceInfoAndScheduling.timeslot',
							null
						);
						setShowAppointmentAlert(false);
					}}
					save={() => {
						setShowAppointmentAlert(false);
						void submitForm();
					}}
				/>
			)}
		</Section>
	);
}
