//React & Redux
import React, { useEffect, useState } from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../../../assets/exit-alert.svg';
import breadcrumbArrow from './../../../../assets/menu-breadcrumb.svg';

//Utils
import {
	determineTopDropShadow,
	determineBottomDropShadow,
} from '../../../../utils.js';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import CreateNewDocumentV2Type from './CreateNewDocumentV2Type.js';
import CreateNewDocumentV2Template from './CreateNewDocumentV2Template.js';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious';

const CreateNewDocumentV2 = (props) => {
	const {
		createDocument,
		createdDocument = false,
		creatingDocument = false,
		creatingDocumentError = false,
		documentTypes,
		documentTypesLoading,
		encounterStringDisplay,
		encounters = [],
		encountersLoading,
		getDocumentTemplatesFlexible,
		getDocumentTypesForTemplate,
		hideDocumentCreateMasked,
		partnerUuid = '',
		patientDetail,
		patientUuid,
		templates = [],
	} = props;

	const [bodyElement, setBodyElement] = useState(null);
	const [shouldHaveBottomDropShadow, setBottomDropShadow] = useState(false);
	const [currentStage, setCurrentStage] = useState('type');
	const [description, setDescription] = useState('');
	const [documentSubType, setDocumentSubType] = useState(null);
	const [documentType, setDocumentType] = useState(null);
	const [loading, setLoading] = useState(false);
	const [relatedEncounter, setRelatedEncounter] = useState(null);
	const [searchAll, setSearchAll] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [shouldHaveTopDropShadow, setTopDropShadow] = useState(false);

	const prevStage = usePrevious(currentStage);

	const TEMPLATE = 'template';
	const TYPE = 'type';

	useEffect(() => {
		getDocumentTypesForTemplate();
	}, []);

	useEffect(() => {
		prevStage !== TYPE &&
			currentStage === TYPE &&
			setSelectedTemplate(null);

		currentStage === TEMPLATE &&
			getTemplatesCurrentPartner(documentType || '');
	}, [currentStage]);

	useEffect(() => {
		setSelectedTemplate(null);
	}, [searchAll, searchValue]);

	useEffect(() => {
		if (!creatingDocument && !createdDocument && creatingDocumentError) {
			setCurrentStage(TYPE);
			getTemplatesCurrentPartner(documentType || '', searchValue);
		}
	}, [createdDocument, creatingDocument, creatingDocumentError]);

	useEffect(() => {
		determineDropShadow(['top', 'bottom']);
	}, [bodyElement]);

	useEffect(() => {
		if (searchAll) {
			getTemplatesAllPartners(documentType || '', searchValue);
		} else {
			getTemplatesCurrentPartner(documentType || '', searchValue);
		}
	}, [searchAll, searchValue]);

	const getTemplatesAllPartners = (docType, q = '') => {
		getDocumentTemplatesFlexible('', docType, q);
	};

	const getTemplatesCurrentPartner = (docType, q = '') => {
		getDocumentTemplatesFlexible([partnerUuid, 'all_partners'], docType, q);
	};

	const renderCurrentStage = () => {
		switch (currentStage) {
			case TYPE:
				return (
					<CreateNewDocumentV2Type
						{...{
							description,
							documentSubType,
							documentType,
							documentTypes,
							encounterStringDisplay,
							encounters,
							relatedEncounter,
							setCurrentStage,
							setValue,
						}}
					/>
				);
			case TEMPLATE:
				return (
					<CreateNewDocumentV2Template
						{...{
							createDocument: createNewDocument,
							determineDropShadow,
							patientDetail,
							resetToggleAndSearch,
							searchAll,
							selectedTemplate,
							setBodyElement,
							setCurrentStage,
							setSearchAll,
							setSearchValue,
							setSelectedTemplate,
							shouldHaveBottomDropShadow,
							shouldHaveTopDropShadow,
							templates,
						}}
					/>
				);
			default:
				console.log('Unrecognized stage.');
		}
	};

	const determineDropShadow = (sides) => {
		if (Array.isArray(sides) && bodyElement) {
			sides.forEach((side) => {
				switch (side) {
					case 'top':
						setTopDropShadow(determineTopDropShadow(bodyElement));
						break;
					case 'bottom':
						setBottomDropShadow(
							determineBottomDropShadow(bodyElement)
						);
						break;
					default:
						console.log('Unrecognized modal side.');
						break;
				}
			});
		}
	};

	const setValue = (e, type) => {
		switch (type) {
			case 'encounter':
				setRelatedEncounter(e);
				break;
			case 'document':
				if (Array.isArray(e)) {
					const [documentType = null, documentSubType = null] = e;
					setDocumentType(documentType);
					setDocumentSubType(documentSubType);
					getTemplatesCurrentPartner(documentType);
				} else {
					setDocumentType(e);
					setDocumentSubType(null);
					getTemplatesCurrentPartner(e);
				}
				break;
			case 'template':
				setSelectedTemplate(e);
				break;
			case 'description':
				setDescription(e?.target?.value);
				break;
		}
	};

	const resetToggleAndSearch = () => {
		setSearchAll(false);
		setSearchValue('');
	};

	const createNewDocument = () => {
		createDocument(
			relatedEncounter || null,
			documentType,
			patientUuid,
			selectedTemplate || null,
			description || null,
			documentSubType || null
		);
	};

	return loading ||
		encountersLoading ||
		creatingDocument ||
		documentTypesLoading ? (
		<div className='fullModalBackground'>
			<div className='createDocumentContainerV2'>
				<Loading
					loading={true}
					style={{ top: 'calc(50% - 25px)', position: 'initial' }}
				/>
			</div>
		</div>
	) : (
		<div className='fullModalBackground'>
			<div className='createDocumentContainerV2'>
				<div className='createDocumentV2TopRow'>
					<div className='createDocumentV2HeaderBreadcrumbs'>
						<div className='createDocumentV2Header'>
							Create Document
						</div>
						<div className='createDocumentV2Breadcrumbs'>
							<span
								className={
									currentStage === TYPE
										? 'createDocumentV2BreadcrumbsCurrent'
										: ''
								}
							>
								Select Document Type
							</span>
							<ReactSVG
								src={breadcrumbArrow}
								className='createDocumentV2BreadcrumbArrow'
							/>
							<span
								className={
									currentStage === TEMPLATE
										? 'createDocumentV2BreadcrumbsCurrent'
										: ''
								}
							>
								Select Template
							</span>
						</div>
					</div>
					<div className='createDocumentV2Close'>
						<ReactSVG
							data-testId='close-modal'
							src={xIcon}
							style={{
								position: 'relative',
								bottom: '2px',
								right: '1.25px',
							}}
							onClick={hideDocumentCreateMasked}
						/>
					</div>
				</div>
				{renderCurrentStage()}
			</div>
		</div>
	);
};

export default CreateNewDocumentV2;
