//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../../assets/exit-alert.svg';
import breadcrumbArrow from './../../../assets/menu-breadcrumb.svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import ICDSelection from './ICDSelection.js';
import BillingInfo from './BillingInfo.js';

//Styles
import './ChargeInformation.css';

const maxLimitBrowser = 500;

class ChargeModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentStage: 'icd',
			selectedICDCodes: [],
			icdCodes: [],
			procedureCode: '96040,95',
			directCareTime: '',
			indirectCareTime: '',
			searchQuery: '',
			currentOffset: 0,
			start: 0,
			end: 0,
			has_previous: false,
			has_next: true,
			currentLimit: 100,
			initialModalLoadComplete: false,
			queryEntered: false,
			initialQueryLoading: false,
			queryLoading: false,
			topLoading: false,
			bottomLoading: false,
			selectedFilter: null,
		};
		//bindings
		this.getICDCodes = this.getICDCodes.bind(this);
		this.getICDCodesDebounced = _.debounce(
			this.getICDCodesDebounced.bind(this),
			100
		);
		this.setICDCodes = this.setICDCodes.bind(this);
		this.setICDCodesTop = this.setICDCodesTop.bind(this);
		this.setBillingEncounterData = this.setBillingEncounterData.bind(this);
		this.setInitialEnum = this.setInitialEnum.bind(this);
		this.prependResults = this.prependResults.bind(this);
		this.setICDCodesBottom = this.setICDCodesBottom.bind(this);
		this.getFilteredCodes = this.getFilteredCodes.bind(this);
		this.appendResults = this.appendResults.bind(this);
		this.getNewOffset = this.getNewOffset.bind(this);
		this.getNewLimit = this.getNewLimit.bind(this);
		this.alreadyLoading = this.alreadyLoading.bind(this);
		this.setSelectedICDCodes = this.setSelectedICDCodes.bind(this);
		this.setSelectedICDCodesFromEncounterObj =
			this.setSelectedICDCodesFromEncounterObj.bind(this);
		this.renderCurrentStage = this.renderCurrentStage.bind(this);
		this.setCurrentStage = this.setCurrentStage.bind(this);
		this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this);
		this.setInputValue = this.setInputValue.bind(this);
		this.toggleCodeSelection = this.toggleCodeSelection.bind(this);
		this.toggleCodeSelectionOn = this.toggleCodeSelectionOn.bind(this);
		this.toggleCodeSelectionOff = this.toggleCodeSelectionOff.bind(this);
		this.toggleCodeSelectionAltCheckbox =
			this.toggleCodeSelectionAltCheckbox.bind(this);
		this.toggleCodeSelectionAltCheckboxOn =
			this.toggleCodeSelectionAltCheckboxOn.bind(this);
		this.toggleCodeSelectionAltCheckboxOff =
			this.toggleCodeSelectionAltCheckboxOff.bind(this);
		this.handleCodeFreeTextChange =
			this.handleCodeFreeTextChange.bind(this);
		this.sortAndSetSelectedCodes = this.sortAndSetSelectedCodes.bind(this);
		this.changeFilterValue = this.changeFilterValue.bind(this);
		this.lazyLoadTop = this.lazyLoadTop.bind(this);
		this.lazyLoadBottom = this.lazyLoadBottom.bind(this);
		this.encounterCall = this.encounterCall.bind(this);
		this.editEncounterCall = this.editEncounterCall.bind(this);
		this.editBillingEncounterCall =
			this.editBillingEncounterCall.bind(this);
	}
	//functions

	componentDidMount() {
		const filter = get(this, 'props.filter', null);
		const filterKey = get(this, 'props.filterKey', null);

		if (!get(this, 'props.notUsingIcd', false)) {
			this.getICDCodes();
			this.setSelectedICDCodes();

			if (
				get(this, 'props.shouldGetEncounter', false) &&
				get(this, 'props.encounterUuid', null) &&
				get(this, 'props.getEncounter', false)
			) {
				this.props.getEncounter(get(this, 'props.encounterUuid', null));
			}

			if (
				this.props.willUseBilling &&
				get(this, 'props.encounterUuid', null)
			) {
				this.props.getAssociatedBillingEncounter(
					get(this, 'props.encounterUuid', null)
				);
			}
		}

		if (get(this, 'props.onlyLocalSearch', false)) {
			this.setInitialEnum();
			this.setSelectedICDCodes();
		}

		if (
			filter &&
			Array.isArray(filter) &&
			get(filter, 'length', 0) > 0 &&
			filterKey
		) {
			this.setState({ selectedFilter: [] });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!get(this, 'props.notUsingIcd', false)) {
			const currentEncounterEditLoading = get(
				this,
				'props.encounterEditLoading',
				false
			);
			const currentBillingEncounterEditLoading = get(
				this,
				'props.billingEncounterEditLoading',
				false
			);
			const currentEncounterEditLoadingsArr = [
				currentEncounterEditLoading,
				currentBillingEncounterEditLoading,
			];

			const prevEncounterEditLoading = get(
				prevProps,
				'encounterEditLoading',
				false
			);
			const prevBillingEncounterEditLoading = get(
				prevProps,
				'billingEncounterEditLoading',
				false
			);
			const prevEncounterEditLoadingsArr = [
				prevEncounterEditLoading,
				prevBillingEncounterEditLoading,
			];

			const currentEncounterEditError = get(
				this,
				'props.encounterEditError',
				false
			);
			const currentBillingEncounterEditError = get(
				this,
				'props.billingEncounterEditError',
				false
			);
			const currentEncounterEditErrorsArr = [
				currentEncounterEditError,
				currentBillingEncounterEditError,
			];

			if (
				prevEncounterEditLoadingsArr.some((el) => el) &&
				!currentEncounterEditLoadingsArr.some((el) => el)
			) {
				if (currentEncounterEditErrorsArr.some((el) => el)) {
					if (currentEncounterEditError) {
						this.props.enqueueSnackbar(
							'Error in editing associated visit encounter',
							{
								variant: 'error',
								anchorOrigin: {
									horizontal: 'right',
									vertical: 'bottom',
								},
							}
						);
					}

					if (currentBillingEncounterEditError) {
						this.props.enqueueSnackbar(
							'Error in editing associated billing encounter',
							{
								variant: 'error',
								anchorOrigin: {
									horizontal: 'right',
									vertical: 'bottom',
								},
							}
						);
					}
				} else {
					this.props.closeChargeModalAndDispatch();
				}
			}

			if (
				!get(this, 'props.associatedBillingEncounterLoading', false) &&
				get(prevProps, 'associatedBillingEncounterLoading', false)
			) {
				if (get(this, 'props.associatedBillingEncounterError', false)) {
					this.props.enqueueSnackbar(
						'Error in getting associated billing encounter',
						{
							variant: 'error',
							anchorOrigin: {
								horizontal: 'right',
								vertical: 'bottom',
							},
						}
					);
				} else {
					this.setBillingEncounterData();
				}
			}

			if (
				get(prevState, 'searchQuery', '') !==
				get(this, 'state.searchQuery', '')
			) {
				if (
					!get(this, 'state.topLoading', false) &&
					!get(this, 'state.bottomLoading', false)
				) {
					if (!get(this, 'state.queryEntered', true))
						this.setState({ queryEntered: true });
					this.setState({ queryLoading: true });
					this.getICDCodesDebounced();
				}
			}

			if (
				get(prevState, 'searchQuery.length', 0) === 0 &&
				get(this, 'state.searchQuery.length', 0) > 0
			) {
				this.setState({ initialQueryLoading: true });
			}

			if (
				get(this, 'props.icdCodes.query', '') === this.state.searchQuery
			) {
				if (get(this, 'state.initialQueryLoading', true)) {
					this.setState({ initialQueryLoading: false });
				}

				if (
					get(this, 'props.icdCodesError', false) &&
					!(
						get(this, 'state.topLoading', false) ||
						get(this, 'state.bottomLoading', false) ||
						get(this, 'state.queryLoading', false)
					)
				) {
					this.props.enqueueSnackbar(
						'Error in getting ICD-10 codes',
						{
							variant: 'error',
							anchorOrigin: {
								horizontal: 'right',
								vertical: 'bottom',
							},
						}
					);
					this.setState({
						bottomLoading: false,
						topLoading: false,
						queryLoading: false,
					});
				}

				if (!get(this, 'state.initialModalLoadComplete', false)) {
					this.setState({ initialModalLoadComplete: true });
				}
			}

			if (!get(this, 'props.icdCodesLoading', false)) {
				if (
					get(this, 'props.icdCodes.query', '') ===
					this.state.searchQuery
				) {
					if (
						get(this, 'state.topLoading', false) ||
						get(this, 'state.bottomLoading', false) ||
						this.state.queryLoading
					) {
						if (
							get(this, 'props.icdCodesPlacement', null) === 'top'
						) {
							this.setICDCodesTop(
								get(this, 'props.icdCodes', { data: [] })
							);
						} else if (
							get(this, 'props.icdCodesPlacement', null) ===
							'bottom'
						) {
							this.setICDCodesBottom(
								get(this, 'props.icdCodes', { data: [] })
							);
						} else {
							if (
								!(
									get(this, 'state.topLoading', false) ||
									get(this, 'state.bottomLoading', false)
								) &&
								this.state.queryLoading
							) {
								this.setICDCodes(
									get(this, 'props.icdCodes', { data: [] })
								);
							}
						}
					}
				}
			}

			if (
				get(prevProps, 'gettingEncounterDetailLoading', false) &&
				!get(this, 'props.gettingEncounterDetailLoading', false)
			) {
				if (get(this, 'props.gettingEncounterDetailError', false)) {
					this.props.enqueueSnackbar(
						'Error in getting associated encounter',
						{
							variant: 'error',
							anchorOrigin: {
								horizontal: 'right',
								vertical: 'bottom',
							},
						}
					);
				} else {
					this.setSelectedICDCodesFromEncounterObj();
				}
			}
		}
	}

	componentWillUnmount() {
		const unmountFunctions = get(this, 'props.unmountFunctions', []) || [];
		if (Array.isArray(unmountFunctions)) {
			unmountFunctions.forEach((func) => {
				if (typeof func === 'function') func();
			});
		}
	}

	setBillingEncounterData() {
		const billingEncounterObj =
			get(this, 'props.associatedBillingEncounter', {}) || {};
		let directCCMinutes = get(
			billingEncounterObj,
			'direct_cc_minutes',
			null
		);
		let indirectCCMinutes = get(
			billingEncounterObj,
			'indirect_cc_minutes',
			null
		);

		if (typeof directCCMinutes === 'number')
			directCCMinutes = JSON.stringify(directCCMinutes);
		if (typeof indirectCCMinutes === 'number')
			indirectCCMinutes = JSON.stringify(indirectCCMinutes);

		this.setState({
			directCareTime: directCCMinutes,
			indirectCareTime: indirectCCMinutes,
		});
	}

	setICDCodes(icdCodes) {
		this.setState({
			icdCodes: get(icdCodes, 'data', []) || [],
			currentOffset: get(icdCodes, 'offset', 0) || 0,
			start: get(icdCodes, 'start', 0) || 0,
			end: get(icdCodes, 'end', 99) || 99,
			total: get(icdCodes, 'total', 100) || 100,
			has_next: get(icdCodes, 'has_next', false) || false,
			has_prev: get(icdCodes, 'has_prev', false) || false,
			topLoading: false,
			bottomLoading: false,
			queryLoading: false,
		});
	}

	setICDCodesTop(icdCodes) {
		this.setState(
			{
				has_prev: get(
					icdCodes,
					'has_prev',
					get(this, 'state.has_prev', false)
				),
				topLoading: false,
				queryLoading: false,
			},
			async () => {
				await this.prependResults(icdCodes);
			}
		);
	}

	setInitialEnum() {
		const initialData = get(this, 'props.icdCodes', null) || [];
		if (Array.isArray(initialData))
			this.setState({ icdCodes: initialData });
	}

	prependResults(icdCodes) {
		return new Promise((resolve, reject) => {
			const codes = get(icdCodes, 'data', []) || [];
			const codesTotal = get(icdCodes, 'total', 0) || 0;
			let newCodes = codes.concat(get(this, 'state.icdCodes', []) || []);
			const size = newCodes.length;
			const icdStart = get(icdCodes, 'start', 0);
			const currentStart = get(this, 'state.start', 0);
			const sizeDiff = currentStart - icdStart;
			const currentEnd = get(this, 'state.end', 0);
			if (sizeDiff > 0) this.setState({ end: currentEnd - sizeDiff });
			if (size > maxLimitBrowser)
				newCodes = newCodes.slice(0, maxLimitBrowser);
			if (currentEnd + 1 < codesTotal) this.setState({ has_next: true });
			this.setState(
				{
					icdCodes: newCodes,
					start: Math.min(
						get(icdCodes, 'start', 0) || 0,
						get(this, 'state.start', 0)
					),
				},
				resolve
			);
		});
	}

	setICDCodesBottom(icdCodes) {
		this.setState(
			{
				end: Math.max(
					get(icdCodes, 'end', 0) || 0,
					get(this, 'state.end', 0)
				),
				has_next: get(
					icdCodes,
					'has_next',
					get(this, 'state.has_next', true)
				),
				bottomLoading: false,
				queryLoading: false,
			},
			async () => {
				await this.appendResults(icdCodes);
			}
		);
	}

	appendResults(icdCodes) {
		return new Promise((resolve, reject) => {
			const codes = get(icdCodes, 'data', []) || [];
			let newCodes = (get(this, 'state.icdCodes', []) || []).concat(
				codes
			);
			const size = newCodes.length;
			const icdEnd = get(icdCodes, 'end', 100);
			const newStart = icdEnd - maxLimitBrowser + 1;
			if (newStart > 0)
				this.setState({ start: newStart, has_prev: true });
			if (size > maxLimitBrowser)
				newCodes = newCodes.slice(maxLimitBrowser * -1);
			this.setState({ icdCodes: newCodes }, resolve);
		});
	}

	setSelectedICDCodes() {
		const selectedICDCodes = _.cloneDeep(
			get(this, 'props.selectedICDCodes', null)
		);
		if (selectedICDCodes && Array.isArray(selectedICDCodes)) {
			this.setState({ selectedICDCodes: selectedICDCodes.slice() });
		}
	}

	setSelectedICDCodesFromEncounterObj() {
		const selectedICDCodes = get(
			this,
			'props.icd10EncounterObj.medical_codes',
			null
		);
		if (selectedICDCodes && Array.isArray(selectedICDCodes)) {
			this.setState({ selectedICDCodes: selectedICDCodes.slice() });
		}
	}

	getICDCodes(topOrBottom) {
		const searchQuery = get(this, 'state.searchQuery', '') || '';
		const currentOffset = this.getNewOffset(topOrBottom);
		const currentLimit = this.getNewLimit(topOrBottom);
		if (topOrBottom === 'bottom') this.setState({ bottomLoading: true });
		if (topOrBottom === 'top') this.setState({ topLoading: true });
		this.props.getICDCodes(
			searchQuery,
			currentOffset,
			currentLimit,
			topOrBottom
		);
	}

	getICDCodesDebounced() {
		const searchQuery = get(this, 'state.searchQuery', '') || '';
		const currentOffset = 0;
		const currentLimit = get(this, 'state.currentLimit', 100);
		this.props.getICDCodes(searchQuery, currentOffset, currentLimit);
	}

	getNewOffset(topOrBottom) {
		let offset;
		if (!topOrBottom) offset = get(this, 'state.currentOffset', 0);
		else if (topOrBottom === 'top')
			offset = Math.max(
				get(this, 'state.start', 0) -
					get(this, 'state.currentLimit', 100),
				0
			);
		else if (topOrBottom === 'bottom')
			offset = get(this, 'state.end', 100) + 1;
		else return 0;
		return offset;
	}

	getNewLimit(topOrBottom) {
		if (topOrBottom !== 'top') return get(this, 'state.currentLimit', 100);
		else {
			if (get(this, 'state.start', 0) < 100)
				return get(this, 'state.start', 0) + 1;
			else return 100;
		}
	}

	getFilteredCodes(codes = []) {
		const localSearchKey =
			get(this, 'props.localSearchKey', null) || 'display_name';
		const searchQuery = get(this, 'state.searchQuery', '');
		const selectedFilter = get(this, 'state.selectedFilter', null);
		const filterKey = get(this, 'props.filterKey', null);
		const localSortKey = get(this, 'props.localSortKey', null);

		if (Array.isArray(codes)) {
			const filteredResults = codes.filter((el) => {
				const name = get(el, localSearchKey, '');
				if (
					typeof name === 'string' &&
					typeof searchQuery === 'string'
				) {
					const status = name.match(new RegExp(_.escapeRegExp(searchQuery), 'gi'));
					if (
						selectedFilter &&
						Array.isArray(selectedFilter) &&
						get(selectedFilter, 'length', 0) &&
						filterKey
					) {
						if (Array.isArray(el[filterKey])) {
							return (
								status &&
								get(
									_.intersection(
										selectedFilter,
										el[filterKey]
									),
									'length',
									0
								)
							);
						} else {
							return (
								status && selectedFilter.includes(el[filterKey])
							);
						}
					} else return status;
				} else return false;
			});
			if (localSortKey)
				return filteredResults.sort((a, b) => {
					if (get(a, localSortKey, '') > get(b, localSortKey, '')) {
						return 1;
					} else {
						return -1;
					}
				});
			else return filteredResults;
		} else return codes;
	}

	renderCurrentStage() {
		const onlyLocalSearch = get(this, 'props.onlyLocalSearch', false);
		switch (this.state.currentStage) {
			case 'icd':
				return (
					<ICDSelection
						setCurrentStage={this.setCurrentStage}
						selectedICDCodes={this.state.selectedICDCodes}
						searchQuery={this.state.searchQuery}
						handleSearchQueryChange={this.handleSearchQueryChange}
						icdCodes={
							onlyLocalSearch
								? this.getFilteredCodes(this.state.icdCodes)
								: this.state.icdCodes
						}
						icdCodesError={this.props.icdCodesError}
						icdCodesLoading={this.props.icdCodesLoading}
						toggleCodeSelection={this.toggleCodeSelection}
						initialQueryLoading={this.state.initialQueryLoading}
						topLoading={this.state.topLoading}
						bottomLoading={this.state.bottomLoading}
						lazyLoadTop={this.lazyLoadTop}
						lazyLoadBottom={this.lazyLoadBottom}
						singleStage={this.props.singleStage}
						title={this.props.title}
						emptyAllowed={get(this, 'props.emptyAllowed', false)}
						buttonText={get(this, 'props.buttonText', null)}
						closeChargeModalAndDispatch={
							this.props.closeChargeModalAndDispatch
						}
						noPlaceHolder={get(this, 'props.noPlaceHolder', false)}
						localSearchKey={get(this, 'props.localSearchKey', null)}
						noDescription={get(this, 'props.noDescription', false)}
						reverseVariant={get(
							this,
							'props.reverseVariant',
							false
						)}
						filter={get(this, 'props.filter', null)}
						selectedFilter={
							get(this, 'state.selectedFilter', null) || []
						}
						changeFilterValue={this.changeFilterValue}
						filterHeader={get(this, 'props.filterHeader', null)}
						altCheckbox={get(this, 'props.altCheckbox', null)}
						toggleCodeSelectionAltCheckbox={
							this.toggleCodeSelectionAltCheckbox
						}
						handleCodeFreeTextChange={this.handleCodeFreeTextChange}
						tagDisplayFunc={this.props.tagDisplayFunc}
						longTagVariant={get(
							this,
							'props.longTagVariant',
							false
						)}
						freeTextKey={
							get(this, 'props.freeTextKey', null) || 'freeText'
						}
					/>
				);
			case 'billing':
				return (
					<BillingInfo
						setCurrentStage={this.setCurrentStage}
						selectedICDCodes={this.state.selectedICDCodes}
						toggleCodeSelection={this.toggleCodeSelection}
						procedureCode={this.state.procedureCode}
						directCareTime={this.state.directCareTime}
						indirectCareTime={this.state.indirectCareTime}
						setInputValue={this.setInputValue}
						buttonText={this.props.title}
						encounterCall={this.encounterCall}
					/>
				);
			default:
				console.log('Unrecognized stage.');
		}
	}

	setInputValue(fieldName, value) {
		const newState = {};
		newState[fieldName] = value;
		this.setState(newState);
	}

	setCurrentStage(value) {
		this.setState({ currentStage: value });
	}

	handleSearchQueryChange(str) {
		this.setState({ searchQuery: str });
	}

	toggleCodeSelection(status, uuid, icdCode) {
		if (status) {
			this.toggleCodeSelectionOn(uuid, icdCode);
		} else {
			this.toggleCodeSelectionOff(uuid, icdCode);
		}
	}

	toggleCodeSelectionOn(uuid, icdCode) {
		const currentCodes = this.state.selectedICDCodes || [];
		const filteredCodes = currentCodes.filter(
			(el) => get(el, 'uuid', '') === uuid
		);
		if (filteredCodes.length === 0) {
			currentCodes.push(icdCode);
			this.sortAndSetSelectedCodes(currentCodes);
		}
	}

	toggleCodeSelectionOff(uuid, icdCode) {
		const currentCodes = this.state.selectedICDCodes || [];
		_.remove(currentCodes, (el) => get(el, 'uuid', '') === uuid);
		this.sortAndSetSelectedCodes(currentCodes);
	}

	toggleCodeSelectionAltCheckbox(status, internalValue, uuid, icdCode) {
		if (status) {
			this.toggleCodeSelectionAltCheckboxOn(internalValue, uuid, icdCode);
		} else {
			this.toggleCodeSelectionAltCheckboxOff(
				internalValue,
				uuid,
				icdCode
			);
		}
	}

	toggleCodeSelectionAltCheckboxOn(internalValue, uuid, icdCode) {
		const currentCodes = this.state.selectedICDCodes || [];
		if (Array.isArray(currentCodes)) {
			const existingCode = _.find(
				currentCodes,
				(el) => get(el, 'uuid', '') === uuid
			);
			if (existingCode) existingCode[internalValue] = true;
			else {
				currentCodes.push({
					...icdCode,
					[internalValue]: true,
				});
			}
			this.sortAndSetSelectedCodes(currentCodes);
		}
	}

	toggleCodeSelectionAltCheckboxOff(internalValue, uuid, icdCode) {
		const currentCodes = this.state.selectedICDCodes || [];
		const altCheckboxData = get(this, 'props.altCheckbox', null);
		if (
			Array.isArray(currentCodes) &&
			altCheckboxData &&
			Array.isArray(altCheckboxData)
		) {
			const existingCode = _.find(
				currentCodes,
				(el) => get(el, 'uuid', '') === uuid
			);
			if (existingCode) {
				existingCode[internalValue] = false;
				const emptyStatus = altCheckboxData.every(
					(enumValue) => !existingCode[get(enumValue, 'internal', '')]
				);
				if (emptyStatus)
					_.remove(
						currentCodes,
						(el) => get(el, 'uuid', '') === uuid
					);
			}
			this.sortAndSetSelectedCodes(currentCodes);
		}
	}

	handleCodeFreeTextChange(e, uuid, icdCode) {
		const str = get(e, 'target.value', null) || '';
		const currentCodes = this.state.selectedICDCodes || [];
		const freeTextKey = get(this, 'props.freeTextKey', null) || 'freeText';
		if (Array.isArray(currentCodes) && typeof str === 'string') {
			const existingCode = _.find(
				currentCodes,
				(el) => get(el, 'uuid', '') === uuid
			);
			if (existingCode) {
				existingCode[freeTextKey] = str;
			}
			this.sortAndSetSelectedCodes(currentCodes);
		}
	}

	sortAndSetSelectedCodes(selectedCodes) {
		const localSearchKey = get(this, 'props.localSearchKey', null);
		const localSortKey = get(this, 'props.localSortKey', null);
		const comparisonKey = localSortKey || localSearchKey || 'code_name';
		if (Array.isArray(selectedCodes)) {
			selectedCodes.sort((a, b) => {
				if (get(a, comparisonKey, '') > get(b, comparisonKey, '')) {
					return 1;
				} else {
					return -1;
				}
			});
		}
		this.setState({ selectedICDCodes: selectedCodes });
	}

	changeFilterValue(flag, value) {
		const currentFilters = get(this, 'state.selectedFilter', null) || [];
		let newFilters;
		if (Array.isArray(currentFilters) && value) {
			if (flag) newFilters = currentFilters.concat(value);
			else newFilters = currentFilters.filter((el) => el !== value);

			this.setState({ selectedFilter: newFilters });
		}
	}

	lazyLoadTop() {
		const onlyLocalSearch = get(this, 'props.onlyLocalSearch', false);
		if (
			!this.alreadyLoading() &&
			get(this, 'state.has_prev', false) &&
			!onlyLocalSearch
		) {
			this.getICDCodes('top');
			this.setState({ topLoading: true });
		}
	}

	lazyLoadBottom() {
		const onlyLocalSearch = get(this, 'props.onlyLocalSearch', false);
		if (
			!this.alreadyLoading() &&
			get(this, 'state.has_next', true) &&
			!onlyLocalSearch
		) {
			this.getICDCodes('bottom');
			this.setState({ bottomLoading: true });
		}
	}

	alreadyLoading() {
		return this.state.topLoading || this.state.bottomLoading;
	}

	encounterCall() {
		if (this.props.encounterUuid && this.props.associatedBillingEncounter) {
			this.editEncounterCall();
			this.editBillingEncounterCall();
		}
	}

	editEncounterCall() {
		const payload = {};
		const medicalCodes = [];
		(get(this, 'state.selectedICDCodes', []) || []).forEach((el) => {
			if (get(el, 'uuid', '') && get(el, 'code_name', '')) {
				medicalCodes.push({
					code_name: get(el, 'code_name', null),
					uuid: get(el, 'uuid', null),
				});
			}
		});
		payload['medical_codes'] = medicalCodes;
		payload.type = 'visit';
		payload.uuid = get(this, 'props.encounterUuid', null);
		this.props.editEncounter({
			data: payload,
		});
	}

	editBillingEncounterCall() {
		const billingEncounterObj =
			get(this, 'props.associatedBillingEncounter', {}) || {};

		const procedureCode =
			get(this, 'state.procedureCode', '96040,95') || '96040,95';
		const directCCMinutes =
			Number(get(this, 'state.directCareTime', '0')) || 0;
		const indirectCCMinutes =
			Number(get(this, 'state.indirectCareTime', '0')) || 0;

		const payload = {
			...billingEncounterObj,
			procedure_code: procedureCode,
			direct_cc_minutes: directCCMinutes,
			indirect_cc_minutes: indirectCCMinutes,
		};
		this.props.editBillingEncounter(payload);
	}

	render() {
		if (
			!get(this, 'props.noLoading', false) &&
			(!get(this, 'state.initialModalLoadComplete', true) ||
				get(this, 'props.encounterEditLoading', false) ||
				get(this, 'props.billingEncounterEditLoading', false) ||
				get(this, 'props.associatedBillingEncounterLoading', false) ||
				get(this, 'props.gettingEncounterDetailLoading', false))
		) {
			return (
				<div className='chargeModalContainer'>
					<Loading loading={true} style={{ position: 'unset' }} />
				</div>
			);
		} else {
			return (
				<div className='chargeModalContainer'>
					<div className='chargeModalTopRow'>
						<div className='chargeModalHeaderBreadcrumbs'>
							<div
								className='chargeModalHeader'
								style={{
									position: this.props.singleStage
										? 'relative'
										: 'unset',
									top: '5px',
									left: '5px',
								}}
							>
								{this.props.title || 'Charge Information'}
							</div>
							{!this.props.singleStage && (
								<div className='chargeModalBreadcrumbs'>
									<span
										className={
											this.state.currentStage === 'icd'
												? 'chargeModalBreadcrumbsCurrent'
												: ''
										}
									>
										Select Visit ICD-10 Code(s)
									</span>
									<ReactSVG
										src={breadcrumbArrow}
										className='chargeModalBreadcrumbArrow'
									/>
									<span
										className={
											this.state.currentStage ===
											'billing'
												? 'chargeModalBreadcrumbsCurrent'
												: ''
										}
									>
										Enter Billing Info
									</span>
								</div>
							)}
						</div>
						<div className='chargeModalClose'>
							<ReactSVG
								src={xIcon}
								style={{
									position: 'relative',
									bottom: '2px',
									right: '1.25px',
								}}
								onClick={(e) => {
									this.props.hideChargeModal();
								}}
							/>
						</div>
					</div>
					{this.renderCurrentStage()}
				</div>
			);
		}
	}
}

export default ChargeModal;
