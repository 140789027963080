//React & Redux
import React, { useEffect, useState } from 'react';

//Lodash
import { isEmpty, isNil, isUndefined, omitBy, trim } from 'lodash';

//Utils
import { encounter_types } from '../../../../Utils/encounterUtils';
import {
	isPartnerInitiatedPreTest,
	isPartnerInitiatedUnsolicitedResult,
	createEnumsOptions,
} from '../Encounters/Common/utilsfunctions';
import {
	getDateTimeString,
	getMomentDateTimeOrString,
	isValidDateTimeString,
	checkDateIsFuture,
} from './utilfunctions';
import {
	referralOutreachStatuses,
	referralOutreachSubstatuses,
	outreachOutcomes,
} from '../../../../utils';

//UI Libraries
import { Dialog, Button, Input, Loading } from 'gm-element-react';
import Datetime from 'react-datetime';
import classnames from 'classnames';
import moment from 'moment';

//Components
import { SelectComponent as Select } from '../../../Common/SelectComponent';

const formats = [
	'YYYY-MM-DD HH:mm:ss',
	'MM/DD/YYYY hh:mm:ss a',
	'MM/DD/YYYY hh:mma',
];
const requiredFields = [
	'contact_method',
	'type',
	'outcome',
	'related_encounter',
];

const dateTimeFields = { date_time: 1 };
const fieldsErrorMapping = {
	date_time: 'Contact Date & Time is required',
	contact_method: 'Contact Method is required',
	type: 'Outreach Type is required',
	outcome: 'Outcome is required',
	notes: 'Notes are required',
	related_encounter: 'Related Encounter is required',
};

const AddOutreach = (props) => {
	const {
		addHandleSubmit,
		closeAddOutreachDialog,
		editHandleSubmit,
		outcomeDetail = {},
		tests,
	} = props;

	const {
		consultation = '',
		contact_method,
		date_time,
		DOS = null,
		encounter_uuid,
		encounterSubType = '',
		encounterType = '',
		notes,
		outcome,
		outreachSequenceNo = 0,
		type = '',
		UUID = null,
		testId = null,
		roundId,
		referralOutreachRoundStatus,
		referralOutreachRoundSubstatus
	} = outcomeDetail;

	const [formData, setFormData] = useState({
		contact_method: 'phone',
		date_time: moment(),
		notes: null,
		outcome: null,
	});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [touched, setTouched] = useState({});

	useEffect(() => {
		setFormData({
			contact_method,
			date_time: moment
				.utc(date_time)
				.local()
				.format('MM/DD/YYYY hh:mm:ss A'),
			outcome,
			notes,
			related_encounter: encounter_uuid,
			gene_test_order_uuid: testId,
		});
	}, []);

	const onFormChange = (key, value) => {
		const newFormData = { ...formData };
		const newTouched = { ...touched };
		let errors = {};

		newFormData[key] = value;

		if (key == 'contact_method' && value == 'email') {
			newFormData.outcome = 'email_sent';
			newTouched.outcome = true;
		}
		if (key == 'outcome' && value == 'other') {
			newTouched.notes = false;
		}
		if (!(key in dateTimeFields)) {
			newTouched[key] = true;
			errors = validateForm(newFormData);
		}
		setFormData(newFormData);
		setErrors(errors);
		setTouched(newTouched);
	};

	const omitFields = (obj) => {
		const modifiedObj = { ...obj };

		switch (type) {
			case 'referral_outreach':
				delete modifiedObj.related_encounter;
				break;

			default:
				break;
		}

		return modifiedObj;
	};

	const createValidationObj = (obj) => {
		const validationObj = {};

		Object.keys(omitFields(obj)).forEach((key) => {
			const val = obj[key];
			if (key in dateTimeFields) {
				if (isNil(val) || isEmpty(val)) {
					validationObj[key] = 'Contact Date & Time is required';
				} else if (
					!isValidDateTimeString(formData[key], formats) ||
					checkDateIsFuture(formData[key], formats)
				) {
					validationObj[key] = 'Invalid date and time';
				}
			} else if (
				key == 'notes' &&
				formData.outcome == 'other' &&
				(isNil(val) || isEmpty(trim(val)))
			) {
				validationObj[key] = fieldsErrorMapping[key];
			} else if (
				requiredFields.includes(key) &&
				(isNil(val) || isEmpty(trim(val)))
			) {
				validationObj[key] = fieldsErrorMapping[key];
			} else {
				validationObj[key] = undefined;
			}
		});

		return validationObj;
	};

	const validateForm = (formData) =>
		omitBy(createValidationObj(formData), isUndefined);

	const outcomeValueIsOther = formData.outcome == 'other';

	const isFieldError = (key) => touched[key] && !isNil(errors[key]);

	const btnIsDisabled = !isEmpty(validateForm(formData));

	const handleDateTimeFieldValidate = (key, value) => {
		let newFormData;

		setFormData((state) => {
			newFormData = {
				...state,
				[key]: value,
			};

			return newFormData;
		});
		setErrors(validateForm(newFormData));
	};

	const isEditMode = !isNil(UUID);

	const isReferralType = type == 'referral_outreach';
	const isReferralDelayedType = type == 'delayed_lab_outreach';

	const outreachTypeLabel = isReferralType
		? 'Referral Outreach'
		: isReferralDelayedType
			? 'Delayed Lab Outreach'
			: `ROR Outreach Round ${outreachSequenceNo}`;

	const onSubmit = () => {
		const formDataToSubmit = { ...formData };
		let tempDateTime = formData.date_time;
		const errors = validateForm(formData);

		if (isEmpty(errors)) {
			if (isValidDateTimeString(formDataToSubmit.date_time, formats)) {
				const momentdatetime = moment(
					formDataToSubmit.date_time,
					formats,
					true
				);
				tempDateTime = momentdatetime
					.utc()
					.format('YYYY-MM-DD HH:mm:ss');
			}

			const notes =
				formDataToSubmit.outcome != 'other'
					? null
					: formDataToSubmit.notes.trim();

			formDataToSubmit.type = type;

			if (!isReferralType && !isReferralDelayedType) {
				formDataToSubmit.encounter_uuid = encounter_uuid;
				formDataToSubmit.outreach_sequence_id = outreachSequenceNo;
			}
			if (isReferralDelayedType) {
				formDataToSubmit.encounter_uuid = encounter_uuid;
			}

			const updatedFormData = {
				...formDataToSubmit,
				class: 'outreach',
				date_time: tempDateTime,
				notes,
			};

			if (roundId) {
				updatedFormData['outreach_round_id'] = roundId;
			}

			setLoading(true);

			if (isEditMode) {
				editHandleSubmit(
					updatedFormData,
					outcomeDetail.UUID,
					outcomeDetail
				)
					.then(() => {
						setLoading(false);
						closeAddOutreachDialog();
					})
					.catch(() => setLoading(false));
			} else {

				if (
					roundId &&
					referralOutreachRoundStatus === referralOutreachStatuses.followupRequired &&
					[
						outreachOutcomes.leftVoicemail,
						outreachOutcomes.unableToLeaveVoicemail,
						outreachOutcomes.patientCallback,
						outreachOutcomes.patientDeclined,
						outreachOutcomes.other
					].includes(formDataToSubmit.outcome)
				) {
					updatedFormData['newReferralOutreachRoundStatus'] = referralOutreachStatuses.outreachCompleted;
					updatedFormData['newReferralOutreachRoundSubstatus'] = referralOutreachSubstatuses.notScheduled;
				}

				addHandleSubmit(updatedFormData)
					.then(() => {
						setLoading(false);
						closeAddOutreachDialog();
					})
					.catch(() => setLoading(false));
			}
		}
	};

	const consultationType =
		encounterType == 'visit' && !isEmpty(consultation)
			? `${consultation}, `
			: '';
	const getRelatedEncounterLabel = () => {
		const initiated = isPartnerInitiatedPreTest({
			type: encounterType,
			encounter_subtype: encounterSubType,
		});
		const partnerInitiatedUnsolicitedResult =
			isPartnerInitiatedUnsolicitedResult({
				type: encounterType,
				encounter_subtype: encounterSubType,
			});
		const substring = `${consultationType}${moment(DOS).format(
			'YYYY-MM-DD'
		)}`;
		return `${initiated
				? 'TRO'
				: partnerInitiatedUnsolicitedResult
					? 'TRO - Results Only'
					: encounter_types[encounterType]
			}, ${substring}`;
	};

	const displayError = (key) =>
		isFieldError(key) ? (
			<div className='input-error'>{errors[key]}</div>
		) : null;

	return (
		<Dialog
			className='outrch-dialog'
			visible={true}
			onCancel={closeAddOutreachDialog}
			closeOnClickModal={false}
			title={`${isEditMode ? 'Edit Outreach' : 'Add Outreach'}`}
		>
			<Dialog.Body>
				<Loading loading={loading} style={{ position: 'unset' }}>
					<div className='outrch-dialog-body'>
						<div className='segment'>
							<div className='segememt-header'>
								Contact Date & Time
							</div>
							<div className='segmeent-body'>
								<div
									className={classnames(
										'input-section',
										isFieldError('date_time')
											? 'error-field'
											: ''
									)}
								>
									<Datetime
										dateFormat={'MM/DD/YYYY'}
										timeFormat={'hh:mm a'}
										timeConstraints={{
											minutes: {
												min: 0,
												max: 60,
												step: 10,
											},
										}}
										strictParsing={true}
										inputProps={{
											placeholder: 'Date',
											autoComplete: 'no',
										}}
										closeOnTab={true}
										value={getMomentDateTimeOrString(
											formData.date_time,
											'MM/DD/YYYY hh:mm:ss a'
										)}
										onChange={(value) =>
											onFormChange(
												'date_time',
												getDateTimeString(value)
											)
										}
										onBlur={(value) =>
											handleDateTimeFieldValidate(
												'date_time',
												getDateTimeString(value)
											)
										}
										isValidDate={(current) =>
											moment(current).isBefore(moment())
										}
									/>
								</div>
								{displayError('date_time')}
							</div>
						</div>
						<div className='segment'>
							<div className='segememt-header'>
								Contact Method
							</div>
							<div className='segmeent-body'>
								<div
									className={classnames(
										'input-section',
										isFieldError('contact_method')
											? 'error-field'
											: ''
									)}
								>
									<Select
										name={'contact_methods'}
										placeholder='Select'
										value={formData.contact_method}
										onChange={(value) =>
											onFormChange(
												'contact_method',
												value
											)
										}
										className='inputFieldDropdown'
										typeahead={true}
										clearable={true}
										options={createEnumsOptions(
											props,
											[
												'outreachEnums',
												'contact_methods',
											],
											'name',
											'display_name',
											'name'
										)}
									/>
								</div>
								{displayError('contact_method')}
							</div>
						</div>
						{(!isReferralType || isReferralDelayedType) && (
							<div className='segment'>
								<div className='segememt-header'>
									Related Encounter
								</div>
								<div className='segmeent-body'>
									<div
										className={classnames('input-section')}
									>
										<Select
											placeholder='Select'
											value={encounter_uuid}
											disabled={isEditMode}
											className='inputFieldDropdown'
											typeahead={true}
											clearable={
												!isEditMode ? true : false
											}
											options={
												encounter_uuid
													? [
														{
															key: encounter_uuid,
															value: encounter_uuid,
															label: getRelatedEncounterLabel(),
														},
													]
													: []
											}
											onChange={(value) =>
												onFormChange(
													'related_encounter',
													value
												)
											}
										/>
									</div>
									{displayError('related_encounter')}
								</div>
							</div>
						)}

						{isReferralDelayedType && (
							<div className='segment'>
								<div className='segememt-header'>
									Related Test Order
								</div>
								<div className='segmeent-body'>
									<div
										className={classnames('input-section')}
									>
										<Select
											placeholder='Select'
											value={testId}
											className='inputFieldDropdown'
											typeahead={true}
											clearable={
												!isEditMode ? true : false
											}
											options={tests?.data?.map((el) => {
												return {
													key: el.testOrderUUID,
													value: el.testOrderUUID,
													label: el.testName,
												};
											})}
											onChange={(value) =>
												onFormChange(
													'gene_test_order_uuid',
													value
												)
											}
										/>
									</div>
									{displayError('gene_test_order_uuid')}
								</div>
							</div>
						)}

						<div className='segment'>
							<div className='segememt-header'>Outreach Type</div>
							<div className='segmeent-body'>
								<div className={classnames('input-section')}>
									<Select
										name='outreach type'
										placeholder='Select'
										value={type}
										disabled={isEditMode}
										className='inputFieldDropdown'
										typeahead={true}
										clearable={!isEditMode ? true : false}
										options={[
											{
												key: 'referral_outreach',
												value: type,
												label: outreachTypeLabel,
											},
										]}
										onChange={(value) =>
											onFormChange('type', value)
										}
									/>
								</div>
								{displayError('type')}
							</div>
						</div>
						<div className='segment'>
							<div className='segememt-header'>Outcome</div>
							<div className='segmeent-body'>
								<div
									className={classnames(
										'input-section',
										isFieldError('outcome')
											? 'error-field'
											: ''
									)}
								>
									<Select
										name='outCome'
										placeholder='Select'
										value={formData.outcome}
										onChange={(value) =>
											onFormChange('outcome', value)
										}
										className='inputFieldDropdown'
										typeahead={true}
										clearable={true}
										options={createEnumsOptions(
											props,
											['outreachEnums', 'outcomes'],
											'name',
											'display_name',
											'name'
										)}
									/>
								</div>
								{displayError('outcome')}
							</div>
						</div>
						{outcomeValueIsOther && (
							<div className='segment'>
								<div className='segememt-header'>Notes</div>
								<div className='segmeent-body'>
									<div
										className={classnames(
											'input-section',
											isFieldError('notes')
												? 'error-field'
												: ''
										)}
									>
										<Input
											type='textarea'
											placeholder='Enter Notes'
											name='notes'
											rows={3}
											value={formData.notes}
											onChange={(value) =>
												onFormChange('notes', value)
											}
										/>
									</div>
									{displayError('notes')}
								</div>
							</div>
						)}
					</div>
				</Loading>
			</Dialog.Body>
			<Dialog.Footer>
				<Button
					type='primary'
					className='outreach-btn'
					disabled={btnIsDisabled}
					onClick={onSubmit}
				>
					{isEditMode ? 'Save' : 'Add Outreach'}
				</Button>
			</Dialog.Footer>
		</Dialog>
	);
};

export default AddOutreach;
