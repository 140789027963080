//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//Components
import TestOrderRow from './TestOrders/TestOrderRow';

const TestOrderSegment = (props) => {
	const row = { ...props.encounterTestOrderRequest[0] };
	let localorderstatus = _.get(row, 'order_status', null);
	if (
		!_.isNil(_.get(row, ['status_reason'], null)) &&
		!_.isEmpty(_.get(row, ['status_reason'], null))
	) {
		localorderstatus = _.get(row, ['status_reason'], null);
	}
	return (
		<div className='tro-testorder-rows '>
			{_.get(props, ['encounterTestOrderRequest', 'length'], 0) > 0 ? (
				<TestOrderRow
					testorder={{
						lab: _.get(row, 'lab', ''),
						test_name: _.get(row, 'genetic_test_display_name', ''),
						lab_uuid: _.get(row, 'lab_uuid', ''),
						genetic_test_uuid: _.get(row, 'genetic_test_uuid', ''),
						order_status: _.get(row, 'order_status', null),
						ordering_physician: _.get(
							row,
							'ordering_physician',
							''
						),
						blood_draw_request: _.get(
							row,
							'blood_draw_request',
							null
						),
						patient_billing_method: _.get(
							row,
							'patient_billing_method',
							null
						),
						sample_type: _.get(row, 'sample_type', null),
						medical_codes: _.get(row, 'medical_codes', []),
						flags: _.get(row, 'flags', []),
						test_order_uuid: _.get(row, 'uuid', ''),
						status_reason: _.get(row, 'status_reason', null),
						localorderstatus: localorderstatus,
						bd_saliva_kit_sent: _.get(
							row,
							'bd_saliva_kit_sent',
							null
						),
						blood_draw_order_date: _.get(
							row,
							'blood_draw_order_date',
							null
						),
						date_received_report: _.get(
							row,
							'date_received_report',
							null
						),
						date_results_released_to_patient: _.get(
							row,
							'date_results_released_to_patient',
							null
						),
						date_test_ordered: _.get(
							row,
							'date_test_ordered',
							null
						),
						expected_lab_results_date: _.get(
							row,
							'expected_lab_results_date',
							null
						),
						history: _.get(row, 'history', null),
						date_test_authorized: _.get(
							row,
							'date_test_authorized',
							null
						),
						lab_paper_form_only: _.get(
							row,
							'lab_paper_form_only',
							false
						),
						lab_order_status: _.get(
							row,
							'lab_order_status_key_name',
							''
						),
						is_actionable: _.get(row, 'is_actionable', false),
						order_questionnaire: _.get(
							row,
							'order_questionnaire',
							null
						),
						patient_test_details: _.get(
							row,
							'patient_test_details',
							[]
						),
						red_flags: _.get(row, 'red_flags', []),
						red_flags_audit_trail: _.get(
							row,
							'red_flags_audit_trail',
							[]
						),
						can_change_lab_order_status: _.get(
							row,
							'can_change_lab_order_status',
							false
						),
						is_questionnaire_editable: _.get(
							row,
							['is_questionnaire_editable'],
							false
						),
						skip_lab_integration: _.get(
							row,
							['skip_lab_integration'],
							false
						),
					}}
					ordering_physicianenums={_.get(
						props,
						['ordering_physicianenums'],
						[]
					)}
					testnameenums={_.get(props, ['testnameenums'], [])}
					orderrequeststatuscascadeenums={_.get(
						props,
						['orderrequeststatuscascadeenums'],
						[]
					)}
					orderstatuscascadeenums={_.get(
						props,
						['orderstatuscascadeenums'],
						[]
					)}
					encounterschema={props.visitsschema}
					getICDCodes={props.getICDCodes}
					icdCodes={props.icdCodes}
					icdCodesLoading={props.icdCodesLoading}
					icdCodesError={props.icdCodesError}
					icdCodesPlacement={props.icdCodesPlacement}
					patientdetail={_.get(props, 'patientdetail', {})}
					encounter={_.get(props, ['encounter'], {})}
					navigateAndExpand={props.navigateAndExpand}
					goToDocumentTab={props.goToDocumentTab}
					saveTROEncounter={props.saveTROEncounter}
					updateTestOrder={_.get(props, ['updateTestOrder'], null)}
					outreachStatusEnums={_.get(
						props,
						['outreachStatusEnums'],
						[]
					)}
					mloOrderStatusEnums={_.get(
						props,
						['mloOrderStatusEnums'],
						[]
					)}
					laborderstatusenums={_.get(
						props,
						['laborderstatusenums'],
						[]
					)}
					progressbarPathObj={row}
					LoadTestOrderPopupTestEnum={_.get(
						props,
						['LoadTestOrderPopupTestEnum'],
						null
					)}
					nonlegacytestOrderPopupTestEnums={_.get(
						props,
						['nonlegacytestOrderPopupTestEnums'],
						null
					)}
					sampletypeenums={_.get(props, ['sampletypeenums'], [])}
					patientbillingmethodenums={_.get(
						props,
						['patientbillingmethodenums'],
						null
					)}
					gotoMLOTestDetail={_.get(
						props,
						['gotoMLOTestDetail'],
						null
					)}
					user={_.get(props, ['user'], {})}
					callTestOrderNotes={_.get(
						props,
						['callTestOrderNotes'],
						null
					)}
					testOrderNotes={_.get(props, ['testOrderNotes'], null)}
					activeRole={_.get(props, ['activeRole'], '')}
					createTestRequestFlags={props.createTestRequestFlags}
					updateTestRequestFlags={props.updateTestRequestFlags}
					creatingTestOrderFlags={_.get(
						props,
						'creatingTestOrderFlags',
						null
					)}
					updatingTestOrderFlags={_.get(
						props,
						'updatingTestOrderFlags',
						null
					)}
					encounterTestOrderRequestLoading={_.get(
						props,
						'encounterTestOrderRequestLoading',
						null
					)}
					isPreAppt={_.get(props, 'isPreAppt', false)}
					orderNoteCount={_.get(row, 'order_note_count', 0)}
					outreachSeqNo={_.get(row, 'outreach_seq_no', 0)}
					testOutreachStatus={_.get(row, 'test_outreach_status', '')}
					outreachTimeToStart={_.get(row, 'time_to_start', null)}
					callTestOrderRequest={props.callTestOrderRequest}
					getOutreachsLog={props.getOutreachsLog}
					isPartnerInitiatedPreTest={_.get(
						props,
						['isPartnerInitiatedPreTest'],
						false
					)}
					testOrderList={_.get(
						props,
						['encounterTestOrderRequest'],
						[]
					)}
					testOrderUpdating={_.get(props, 'testOrderUpdating', false)}
					getEncounterAuditTrail={props.getEncounterAuditTrail}
					updateTestOrderRedFlag={props.updateTestOrderRedFlag}
					testOrderRedFlagUpdating={props.testOrderRedFlagUpdating}
					testOrderRedFlagUpdated={props.testOrderRedFlagUpdated}
					testOrderRedFlagUpdatedError={
						props.testOrderRedFlagUpdatedError
					}
				/>
			) : (
				<div />
			)}
		</div>
	);
};
export default TestOrderSegment;
