//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import DuplicatePatientTooltip from './DuplicatePatientTooltip.js';

class ButtonRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.handleButtonClick = this.handleButtonClick.bind(this);
		this.handleTooltipMouseEnter = this.handleTooltipMouseEnter.bind(this);
		this.renderDuplicatePatientTooltip =
			this.renderDuplicatePatientTooltip.bind(this);
	}
	//functions

	handleButtonClick() {
		const requiredFieldsFilled = get(this, 'props.canAdvance', false);
		if (requiredFieldsFilled) this.props.handleCreatePatient();
	}

	handleTooltipMouseEnter() {
		const duplicatePatientTooltip = get(
			this,
			'props.duplicatePatientTooltip',
			false
		);
		const duplicatePatientTooltipOpen = get(
			this,
			'props.duplicatePatientTooltipOpen',
			false
		);
		if (duplicatePatientTooltip && !duplicatePatientTooltipOpen) {
			this.props.openDuplicatePatientTooltip();
		}
	}

	renderDuplicatePatientTooltip() {
		const duplicatePatientTooltip = get(
			this,
			'props.duplicatePatientTooltip',
			false
		);
		const duplicatePatientTooltipOpen = get(
			this,
			'props.duplicatePatientTooltipOpen',
			false
		);
		const duplicatePatientData = get(
			this,
			'props.duplicatePatientData',
			false
		);
		if (duplicatePatientTooltip && duplicatePatientTooltipOpen) {
			return (
				<DuplicatePatientTooltip
					duplicatePatientData={duplicatePatientData}
					closeDuplicatePatientTooltip={
						this.props.closeDuplicatePatientTooltip
					}
					offset={get(this, 'props.offset', 0)}
					changeOffset={this.props.changeOffset}
					duplicatePatientCheckLoading={get(
						this,
						'props.duplicatePatientCheckLoading',
						false
					)}
					formPatientFirstName={get(
						this,
						'props.formPatientFirstName',
						null
					)}
					formPatientLastName={get(
						this,
						'props.formPatientLastName',
						null
					)}
					formPatientDob={get(this, 'props.formPatientDob', null)}
					formPatientEmail={get(this, 'props.formPatientEmail', null)}
					formPatientNoEmail={get(
						this,
						'props.formPatientNoEmail',
						null
					)}
					formPatientUnbornSelection={get(
						this,
						'props.formPatientUnbornSelection',
						null
					)}
					formPatientGuardianLastName={get(
						this,
						'props.formPatientGuardianLastName',
						null
					)}
				/>
			);
		}
	}

	render() {
		const requiredFieldsFilled = get(this, 'props.canAdvance', false);
		const buttonClass = requiredFieldsFilled
			? 'patientCreateV2Button'
			: 'patientCreateV2Button patientCreateV2ButtonDisabled';
		const tooltipButtonClass = 'duplicatePatientCreateV2Button';
		const duplicatePatientTooltip = get(
			this,
			'props.duplicatePatientTooltip',
			false
		);
		const duplicatePatientCheck = get(
			this,
			'props.duplicatePatientCheck',
			{}
		);
		const containerClass = get(this, 'props.shouldHaveDropShadow', false)
			? 'patientCreateV2ButtonRow scrollableModalDropShadowBottom'
			: 'patientCreateV2ButtonRow';
		return (
			<div className={containerClass}>
				{duplicatePatientTooltip ? (
					<React.Fragment>
						<div
							className={tooltipButtonClass}
							onClick={this.handleButtonClick}
							onMouseEnter={this.handleTooltipMouseEnter}
						>
							{'Create Anyway'}
						</div>
						{this.renderDuplicatePatientTooltip()}
					</React.Fragment>
				) : (
					<div
						className={buttonClass}
						onClick={this.handleButtonClick}
					>
						{get(this, 'props.alternativeButtonText', null) ||
							'Create Patient'}
					</div>
				)}
			</div>
		);
	}
}

export default ButtonRow;
