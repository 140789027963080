import React, { useCallback, useEffect, useRef, useState } from 'react';

import ReactSVG from 'react-svg';
import threeDotMenu from './../../../assets/threedot.svg';
import athenaIcon from './../../../assets/athena-icon.svg';

//Lodash
import { get, isNil } from 'lodash';
const CTAMenu = (props) => {
	const { createAthenaPatientAndInsurance, patientDetail = [] } = props;

	let primaryInsurance = {};
	if (patientDetail.length > 0) {
		primaryInsurance = get(
			patientDetail[0].insurances.filter(
				(el) =>
					el.insurance_type === 'primary' &&
					el.lab_insurance === false
			),
			'[0]',
			{}
		);
	}

	const athena_insurance_package_id = get(
		primaryInsurance,
		['insurance', 'athena_insurance_package_id'],
		null
	);
	const { uuid = null } = patientDetail[0] || {};

	const [menuOpen, setMenuOpen] = useState(false);

	const menuContainer = useRef(null);

	useEffect(() => {
		return () => {
			document
				.querySelector('body')
				.removeEventListener('click', menuEventListenerFunc);
		};
	}, []);

	const menuEventListenerFunc = useCallback((e) => {
		if (
			menuContainer.current &&
			!menuContainer.current.contains(e.target)
		) {
			setMenuOpen(false);
			document
				.querySelector('body')
				.removeEventListener('click', menuEventListenerFunc);
		}
	}, []);

	const handleClickThreeDots = () => {
		if (!menuOpen) {
			setMenuOpen(true);
			document
				.querySelector('body')
				.addEventListener('click', menuEventListenerFunc, {
					capture: true,
				});
		} else {
			setMenuOpen(false);
			document
				.querySelector('body')
				.removeEventListener('click', menuEventListenerFunc);
		}
	};

	const handleAthenaCreate = () => {
		if (athena_insurance_package_id && uuid) {
			createAthenaPatientAndInsurance(uuid);
			if (menuOpen) {
				setMenuOpen(false);
			}
		}
	};

	const threeDotMenuClass = menuOpen
		? 'threeDotMenu threeDotMenuOpen'
		: 'threeDotMenu';

	return (
		<div className='insuranceCTAContainer'>
			<ReactSVG
				data-testId='three-dots'
				src={threeDotMenu}
				className={threeDotMenuClass}
				onClick={handleClickThreeDots}
			/>
			{menuOpen && (
				<div className='insuranceCTAMenuContainer' ref={menuContainer}>
					<div
						data-testId='athena-create'
						className={
							!isNil(athena_insurance_package_id)
								? 'insuranceMenuOption'
								: 'insuranceMenuOption insuranceMenuOptionDisabled'
						}
						onClick={handleAthenaCreate}
					>
						<ReactSVG
							src={athenaIcon}
							className='insuranceMenuOptionIcon'
						/>
						<span className='insuranceMenuOptionText'>
							Add patient on Athena
						</span>
					</div>
				</div>
			)}
		</div>
	);
};

export default CTAMenu;
