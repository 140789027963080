//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import warningImg from '../../../../assets/milestone-warning.svg';

//Lodash
import _ from 'lodash';

//Styles
import './PreAuthMilestoneWarning.css';

const isVisible = (props) => {
	const warningFields = _.get(props, ['formdata', 'warningFields'], []);
	if (warningFields.includes('request_decision')) {
		const stateValue = _.get(props, ['formdata', 'request_decision'], '');
		const propValue = _.get(props, ['testorder', 'request_decision'], '');
		const warningStates = [
			'requested_pa_cnm',
			'not_requested_pa_cnm',
			'patient_declined_pa_cm',
			'requested_pa_cm',
			'requested_alternate_pa_atr',
			'patient_declined_pa_cnm',
		];
		return stateValue != propValue && warningStates.includes(stateValue);
	}
	if (warningFields.includes('dispatch_status')) {
		const stateValue = _.get(props, ['formdata', 'dispatch_status'], '');
		const propValue = _.get(props, ['testorder', 'dispatch_status'], '');
		const warningStates = ['pa_form_sent', 'declined_dispatch_na'];
		return stateValue != propValue && warningStates.includes(stateValue);
	}
	return false;
};

const selectedValue = (props) => {
	const currentKey = _.get(props, 'currentKey', '');
	if (currentKey == 'request_decision') {
		return getDisplayValue(
			_.get(props, ['formdata', 'request_decision'], ''),
			_.get(props, ['preauthdecisionenums'], [])
		);
	}
	if (currentKey == 'dispatch_status') {
		return getDisplayValue(
			_.get(props, ['formdata', 'dispatch_status'], ''),
			_.get(props, ['preauthdispatchstatusenums'], [])
		);
	}
	return '';
};

const getDisplayValue = (stateValue, preAuthEnums) => {
	const obj = _.find(preAuthEnums, (one) => {
		if (one) {
			if (one.key == stateValue) {
				return one;
			} else if (_.get(one, 'children')) {
				return _.find(
					one.children,
					(two) => two && two.key == stateValue
				);
			}
		} else {
			return {};
		}
	});
	if (_.get(obj, 'children')) {
		const enums = _.find(obj.children, (o) => o.key && o.key == stateValue);
		return _.get(enums, 'display_name', '');
	} else {
		return _.get(obj, 'display_name', '');
	}
};

const PreAuthMilestoneWarning = (props) => {
	return (
		isVisible(props) && (
			<div className='pa-milestone-warning-container'>
				<div className='pa-milestone-warning-body'>
					<div className='pa-milestone-warning-icon'>
						<ReactSVG src={warningImg} />
					</div>
					<div className='pa-milestone-warning-text'>
						{`Changing status to “${selectedValue(
							props
						)}” is an irreversible action once saved.`}
					</div>
				</div>
			</div>
		)
	);
};

export default PreAuthMilestoneWarning;
