// React
import React from 'react';

// Components
import { InputField } from '../../../../Common/InputField';

export const EncounterInputField = ({
	disabled,
	error,
	extraclassName,
	istouched = true,
	maxLength,
	name,
	onChange,
	placeholder,
	trim,
	value,
}) => (
	<InputField
		disabled={disabled}
		error={error}
		extraErrorStyleOverride={{ top: 'unset', left: 'unset' }}
		extraclassName={extraclassName}
		istouched={istouched}
		maxLength={maxLength}
		name={name}
		onChange={onChange}
		placeholder={placeholder}
		trim={trim}
		value={value}
	/>
);
