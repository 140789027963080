//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import RadioGroup from '../Common/controls/RadioGroup/RadioGroup';
import { lab_order_integration_options } from '../../utils';
import { SelectComponent as Select } from '../Common/SelectComponent';

// Utils
import { createEnumsOptions } from '../Patients/V2/Encounters/Common/utilsfunctions';

class AddTestAdditional extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		const test = this.props.newTest;
		const editTitle = ' testEditTitle';
		const inputFieldText = 'inputFieldText fullWidth';
		const inputFieldDropdown = 'inputFieldDropdown';
		const inputFieldMultiselect = 'formMultiselect';
		const inputFieldTextArea = 'inputFieldTextArea';
		const testTypeError = get(this, 'props.errors', new Set()).has(
			'test_type'
		)
			? ' inputFieldDropdownError'
			: '';
		const cliaError = get(this, 'props.errors', new Set()).has('clia')
			? ' inputFieldDropdownError'
			: '';

		return (
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'wrap',
				}}
			>
				<div
					className='testInformation testInformationNew'
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className='testEditRequiredField'>*</div>
					{get(this, 'props.errors', new Set()).has('test_type') && (
						<div className='fieldErrMsgTestCreate'>
							Please give a value.
						</div>
					)}
					<div className={'test_field_title' + editTitle}>
						{' '}
						Test type{' '}
					</div>
					<div className={'test_field_detail'}>
						<Select
							value={test.genetic_test_types_enums_uuid}
							className={`${inputFieldDropdown} ${testTypeError}`}
							placeholder='Select'
							onChange={(e) => {
								this.props.editNewTest('test_type', e);
							}}
							typeahead={true}
							clearable={true}
							options={createEnumsOptions(
								this,
								['props', 'testTypes'],
								'uuid',
								'display_name',
								'uuid'
							)}
						/>
					</div>
				</div>

				<div
					className='testInformation testInformationNew'
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className='testEditRequiredField'>*</div>
					{get(this, 'props.errors', new Set()).has('clia') && (
						<div className='fieldErrMsgTestCreate'>
							Please give a value.
						</div>
					)}
					<div className={'test_field_title' + editTitle}>
						{' '}
						Confirmation recommended?{' '}
					</div>
					<div className={'test_field_detail'}>
						<Select
							value={test.clia_confirm_needed}
							className={`${inputFieldDropdown} ${cliaError}`}
							placeholder='Select'
							onChange={(e) => {
								this.props.editNewTest(
									'clia_confirm_needed',
									e
								);
							}}
							typeahead={true}
							clearable={true}
							options={createEnumsOptions(
								this,
								['props', 'otherEnums', 'clia_confirm_needed'],
								'key',
								'display_name',
								'key'
							)}
						/>
					</div>
				</div>

				<div
					className='testInformation testInformationNewEnd'
					style={{ width: 'calc(33.3%)' }}
				>
					<div className='testEditRequiredField'>*</div>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Prenatal{' '}
					</div>
					<RadioGroup
						value={test.prenatal}
						options={get(this, 'props.otherEnums.prenatal', [])}
						onChange={(e) => {
							this.props.editNewTest('prenatal', e);
						}}
					/>
					{get(this, 'props.errors', new Set()).has('prenatal') && (
						<div
							className='fieldErrMsgTestCreate fieldErrMsgRadio'
							style={{ bottom: '-16px' }}
						>
							Please give a value.
						</div>
					)}
				</div>

				<div
					className='testInformation testInformationNew'
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className='testEditRequiredField'>*</div>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Opp. for reinterpretation?{' '}
					</div>
					<RadioGroup
						value={test.opportunity_for_reinterpretation}
						options={get(
							this,
							'props.otherEnums.opportunity_for_reinterpretation',
							[]
						)}
						onChange={(e) => {
							this.props.editNewTest(
								'opportunity_for_reinterpretation',
								e
							);
						}}
					/>
				</div>

				<div
					className='testInformation testInformationNewEnd'
					style={{ flexGrow: '1', width: 'calc(66.6%)' }}
				>
					{/* <div className="testEditRequiredField">*</div> */}
					{/* {get(this, "props.errors", new Set()).has("tags") && <div className="fieldErrMsgTestCreate" style={{bottom: '-14px'}}>Please give a value.</div>} */}
					<div className={'test_field_title' + editTitle}>
						{' '}
						Tags (select all that apply)
					</div>
					<div
						className='test_field_detail'
						style={{
							height: '100%',
							display: 'flex',
							flexWrap: 'wrap',
						}}
					>
						<div
							className='testTagContainer detailView'
							style={{ paddingLeft: '0px', marginTop: '0px' }}
						>
							{get(this, 'props.testTags', []).map((tag) => {
								return (
									<div
										className={
											get(
												this,
												'props.selectedTags',
												new Set()
											).has(tag.uuid)
												? 'testTagEditSelected'
												: 'testTagEdit'
										}
										key={tag.uuid}
										onClick={(e) =>
											this.props.handleTagSelect(
												e,
												tag.uuid
											)
										}
									>
										{tag.tag || '--'}
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<div
					className='testInformation'
					style={{ marginBottom: '6px', width: 'calc(33.3% - 20px)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						NGS vs SNP assay?{' '}
					</div>
					<RadioGroup
						value={test.ngs_vs_snp_assay}
						options={get(
							this,
							'props.otherEnums.ngs_vs_snp_assay',
							[]
						)}
						onChange={(e) => {
							this.props.editNewTest('ngs_vs_snp_assay', e);
						}}
					/>
				</div>

				<div
					className='testInformation testInformationNewEnd'
					style={{ width: 'calc(66.6%)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Global limitations{' '}
					</div>
					<Select
						value={test.global_limitations}
						className='inputFieldMultiselect'
						placeholder='Select'
						onChange={(e) => {
							this.props.editNewTest('global_limitations', e);
						}}
						multiple={true}
						options={[].map((el) => {
							return {
								key: el.key,
								label: el.display_name,
								value: el.key,
							};
						})}
					/>
				</div>

				<div
					className='testInformation'
					style={{
						width: '100%',
						marginRight: '0px',
						marginTop: '3px',
					}}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Gene-specific limitations{' '}
					</div>
					<textarea
						value={test.gene_specific_limitations || ''}
						className={inputFieldTextArea}
						style={{ height: '58px', marginBottom: '0px' }}
						onChange={(e) => {
							this.props.editNewTest(
								'gene_specific_limitations',
								e.target.value
							);
						}}
						placeholder='Lorem ipsum'
					/>
				</div>

				<div
					className='testInformation testInformationNewEnd'
					style={{ width: '100%', minHeight: '79px' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Comments{' '}
					</div>
					<textarea
						value={test.comments || ''}
						className={inputFieldTextArea}
						onChange={(e) => {
							this.props.editNewTest('comments', e.target.value);
						}}
						style={{
							width: '100%',
							minHeight: '90px',
							marginBottom: '0px',
						}}
						placeholder='Write something here'
					/>
				</div>
				<div
					className='testInformation testInformationNewEnd hide-lab-integration-field'
					style={{ width: '100%', minHeight: '79px' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Send Order to Lab via Integration?
					</div>
					<RadioGroup
						options={lab_order_integration_options}
						onChange={(e) => {
							this.props.editNewTest('lab_order_integration', e);
						}}
						value={test.lab_order_integration}
					/>
				</div>
			</div>
		);
	}
}

export default AddTestAdditional;
