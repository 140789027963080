import * as actionTypes from './actionTypes';
import _ from 'lodash';
import SessionService from '../service/Session';
import UserRoleService from '../service/UserRoles';
import {
	isExternalRole,
	isBillingCoordinator,
} from './../Utils/permissionUtils.js';

export const mesuccess = (payload) => {
	return {
		type: actionTypes.ME_SUCCESS,
		payload: payload,
	};
};

export const me = () => {
	return (dispatch) => {
		const Promises = [];
		Promises.push(SessionService.me());
		Promises.push(SessionService.activerole());
		Promises.push(UserRoleService.get());
		return Promise.all(Promises)
			.then((responses) => {
				const medata = {
					..._.get(responses, ['0', 'data', 'data'], {}),
				};
				let activeroleuuid = '';
				if (_.get(responses, ['1', 'data', 'activerole'])) {
					activeroleuuid = _.get(responses, [
						'1',
						'data',
						'activerole',
					]);
				} else {
					const userroles = _.get(
						responses,
						['0', 'data', 'data', 'roles'],
						[]
					);
					const defaultrole = _.find(userroles, (role) => {
						return role && role.default === true;
					});
					activeroleuuid = _.get(defaultrole, 'uuid', '');
				}
				const roles = _.get(responses, ['2', 'data', 'data'], []);
				const activerole = _.find(roles, (role) => {
					return (
						role &&
						role.uuid &&
						role.name &&
						role.uuid === activeroleuuid
					);
				});
				if (activerole) {
					activerole.isExternalUser = isExternalRole(
						_.get(activerole, 'name', null)
					);
					activerole.isBillingCoordinator = isBillingCoordinator(
						_.get(activerole, 'name', null)
					);
				}
				medata.activerole = activerole;
				return medata;
			})
			.then((medata) => {
				if (medata && medata.activerole) {
					SessionService.permission(medata.activerole.uuid).then(
						(permissions) => {
							const permissionmedata = {
								...medata,
								permissions: {
									..._.get(permissions, ['data', 'data'], {}),
								},
							};
							dispatch(mesuccess(permissionmedata));
						}
					);
				} else {
					console.log('active role is not present in master roles');
				}
			});
	};
};

export const switchRole = (currentRoleUUID = null, selectedRoleUUID = null) => {
	return new Promise((resolve) => {
		return SessionService.me().then((meRes) => {
			const userRoles = _.get(meRes, ['data', 'data', 'roles'], []);

			const findRole = (role) =>
				_.find(userRoles, (a) => {
					return a.uuid == role;
				});
			const SelectedRoleIsAvailable =
				selectedRoleUUID && findRole(selectedRoleUUID);
			const currentRoleIsAvailable =
				currentRoleUUID && findRole(currentRoleUUID);
			const defaultrole = _.find(userRoles, (role) => {
				return role && role.default === true;
			});

			if (SelectedRoleIsAvailable)
				return resolve({ status: 'SELECTED_ROLE' });
			else if (currentRoleIsAvailable)
				return resolve({ status: 'CURRENT' });
			else if (defaultrole)
				return resolve({
					status: 'DEFAULT',
					roleuuid: defaultrole.uuid,
				});
			else return resolve({ status: 'INVALID' });
		});
	});
};

export const changeCurrentRole = (roleuuid) => {
	return () => {
		SessionService.changerole(roleuuid)
			.then(() => {
				window.location.href = window.location.origin;
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

function updateMeStart() {
	return { type: actionTypes.UPDATE_ME_START };
}

function updateMeSuccess(payload) {
	return { type: actionTypes.UPDATE_ME_SUCCESS, payload };
}

function updateMeFailure(payload) {
	return { type: actionTypes.UPDATE_ME_FAILURE, payload };
}

export function updateMe(data) {
	return (dispatch) => {
		dispatch(updateMeStart());
		return SessionService.updateMe(data)
			.then((res) => {
				return dispatch(
					updateMeSuccess(res));
			})
			.catch((err) => {
				dispatch(
					updateMeFailure(
						_.get(
							err,
							['response', 'data'],
							'Something went wrong.'
						)
					)
				);
			});
	};
}
