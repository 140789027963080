import * as actionTypes from '../actions/actionTypes';
import _ from 'lodash';
const defaultCurrentEvent = {
	duration: '',
	start: '',
	end: '',
};

const defaultCouponCodeState = {
	loadingCouponCodeDetail: false,
	couponCodeSuccess: null,
	couponCodeError: null,
	couponCodeWarning: null,
};

const defaultNewAppointment = {
	id: null,
	uuid: null,
	error: null,
	type: null,
	timeslot: {},
	patientId: null,
	provider_id: undefined,
	state: '',
	vsee_specialty: null,
	consultation_id: undefined,
	couponCode: null,
	byPhone: null,
	modality: '',
	reason: '',
	paymentmode: '',
	useInsurance: undefined,
	insurancePrice: null,
	insurance_company_id: 0,
	isCardChange: false,
	hideCouponPanel: false,
	generatedappointment: null,
	calendar_date: null,
	patientInfo: {},
};

export const defaultState = {
	slots: [],
	reservations: [],
	currentEvent: { ...defaultCurrentEvent },
	newAppointment: { ...defaultNewAppointment },
	schedulingConsultations: [],
	loading: false,
	slotsLoading: false,
	slotsError: null,
	providers: [],
	partnerDetail: null,
	...defaultCouponCodeState,
};

export default (state = defaultState, action = {}) => {
	switch (action.type) {
		case actionTypes.LOAD_APPOINTMENT_SLOTS_START:
			return {
				...state,
				slotsLoading: true,
				slots: [],
				reservations: [],
				slotsError: null,
			};
		case actionTypes.LOAD_APPOINTMENT_SLOTS_SUCCESS:
			return {
				...state,
				slotsLoading: isfiltermatch(
					{ ...state.newAppointment },
					_.get(action, 'data', {})
				),
				slots: _.get(action.payload, 'available_slots', []),
				reservations: _.get(action.payload, 'reservations', []),
			};
		case actionTypes.LOAD_APPOINTMENT_SLOTS_FAILURE:
			return {
				...state,
				slotsLoading: false,
				slots: [],
				reservations: [],
				slotsError: action.payload,
			};
		case actionTypes.LOAD_PATIENT_CONSULTATIONS_SUCCESS:
			return {
				...state,
				schedulingConsultations: action.payload,
			};
		case actionTypes.LOAD_APPOINTMENT_PROVIDER_SUCCESS:
			return {
				...state,
				providers: action.payload,
			};
		case actionTypes.SET_APPOINTMENT_PARAMS: {
			const newAppointment = {
				...state.newAppointment,
				...action.payload,
			};
			return {
				...state,
				newAppointment,
			};
		}
		case actionTypes.TOGGLE_APPOINTMENT_PATIENT: {
			const newAppointment = {
				...defaultNewAppointment,
				...action.payload,
			};
			return {
				...state,
				newAppointment,
				slots: [],
				reservations: [],
				schedulingConsultations: [],
			};
		}
		case actionTypes.LOAD_APPOINTMENT_PARTNER_DETAIL_SUCCESS: {
			return {
				...state,
				partnerDetail: action.payload,
			};
		}
		case actionTypes.LOADING_APPLY_COUPONCODE: {
			return {
				...state,
				couponCodeSuccess: null,
				couponCodeWarning: null,
				couponCodeError: null,
				loadingCouponCodeDetail: true,
			};
		}
		case actionTypes.LOADED_APPLY_COUPONCODE: {
			let couponWarn = null;
			let couponSuccess = null;

			if (!_.isEmpty(_.get(action.payload, 'error', null))) {
				couponWarn = _.get(action.payload, 'error', null);
			} else {
				couponSuccess = action.payload;
			}

			return {
				...state,
				loadingCouponCodeDetail: false,
				couponCodeSuccess: couponSuccess,
				couponCodeWarning: couponWarn,
				couponCodeError: null,
			};
		}
		case actionTypes.ERROR_APPLY_COUPONCODE: {
			return {
				...state,
				loadingCouponCodeDetail: false,
				couponCodeSuccess: null,
				couponCodeWarning: null,
				couponCodeError: action.payload,
			};
		}
		case actionTypes.CLEAR_APPOINTMENT_PARTNER_DETAIL_START: {
			return {
				...state,
				partnerDetail: null,
			};
		}
		case actionTypes.RESET_NEW_APPOINTMENT: {
			return {
				...defaultState,
			};
		}
		case actionTypes.RESET_APPLY_COUPONCODE: {
			return {
				...state,
				...defaultCouponCodeState,
			};
		}
		case actionTypes.CLEAR_APPOINTMENT_PROVIDER: {
			return {
				...state,
				providers: [],
			};
		}
		default:
			return state;
	}
};

const isfiltermatch = (selectedFilters, newAppointmentFilters) => {
	const isstatesame =
		_.get(selectedFilters, 'state') ==
		_.get(newAppointmentFilters, 'state');
	const istimezonesame =
		_.get(selectedFilters, 'timezone') ==
		_.get(newAppointmentFilters, 'timezone');
	const isprovidesame =
		_.get(selectedFilters, 'provider_id') ==
		_.get(newAppointmentFilters, 'provider_id');
	const isconsulationsame =
		_.get(selectedFilters, 'consultation_id') ==
		_.get(newAppointmentFilters, 'consultation_id');
	const isvsee_specialitysame =
		_.get(selectedFilters, 'vsee_specialty') ==
		_.get(newAppointmentFilters, 'vsee_specialty');
	const isfiltersame =
		isstatesame &&
		istimezonesame &&
		isprovidesame &&
		isconsulationsame &&
		isvsee_specialitysame &&
		isvsee_specialitysame;
	return !isfiltersame;
};
