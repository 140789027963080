import React from 'react';

import MultiSelectCheckbox from '../../../Filter/MultiSelectCheckbox';

export const OUTREACH_STATUS = {
	FOLLOWUP_REQUIRED: 'followup_required',
	IN_PROGRESS: 'in_progress',
	OUTREACH_COMPLETED: 'outreach_completed',
};

export const OUTREACH_STATUS_OPTIONS = {
	[OUTREACH_STATUS.FOLLOWUP_REQUIRED]: 'Followup Required',
	[OUTREACH_STATUS.IN_PROGRESS]: 'Outreach In Progress',
	[OUTREACH_STATUS.OUTREACH_COMPLETED]: 'Outreach Completed',
};

export const OUTREACH_SUBSTATUS = {
	DO_NOT_OUTREACH: 'do_not_outreach',
	NOT_SCHEDULED: 'not_scheduled',
	SCHEDULED: 'scheduled',
};

export const OUTREACH_SUBSTATUS_OPTIONS = {
	[OUTREACH_SUBSTATUS.DO_NOT_OUTREACH]: 'Do Not Outreach',
	[OUTREACH_SUBSTATUS.NOT_SCHEDULED]: 'Not Scheduled',
	[OUTREACH_SUBSTATUS.SCHEDULED]: 'Scheduled',
};

const COMBINED_OPTIONS = [
	{
		display_name: 'Followup Required',
		key: OUTREACH_STATUS.FOLLOWUP_REQUIRED,
	},
	{ display_name: 'Outreach In Progress', key: OUTREACH_STATUS.IN_PROGRESS },
	{
		display_name: 'Outreach Completed: Do Not Outreach',
		key: [
			OUTREACH_STATUS.OUTREACH_COMPLETED,
			OUTREACH_SUBSTATUS.DO_NOT_OUTREACH,
		].join(';'),
	},
	{
		display_name: 'Outreach Completed: Not Scheduled',
		key: [
			OUTREACH_STATUS.OUTREACH_COMPLETED,
			OUTREACH_SUBSTATUS.NOT_SCHEDULED,
		].join(';'),
	},
	{
		display_name: 'Outreach Completed: Scheduled',
		key: [
			OUTREACH_STATUS.OUTREACH_COMPLETED,
			OUTREACH_SUBSTATUS.SCHEDULED,
		].join(';'),
	},
];

/**
 * @param {object} filter
 * @param {string[]} filter.status
 * @param {string[]} filter.substatus
 */
export function filterToValue({ status, substatus }) {
	if (
		(substatus.length &&
			!status.includes(OUTREACH_STATUS.OUTREACH_COMPLETED)) ||
		(status.includes(OUTREACH_STATUS.OUTREACH_COMPLETED) &&
			!substatus.length)
	) {
		throw new Error('Invalid status filter');
	}

	const statusValues = status.filter(
		(s) => s !== OUTREACH_STATUS.OUTREACH_COMPLETED
	);

	return substatus.length
		? [
				...statusValues,
				...substatus.map(
					(s) => `${OUTREACH_STATUS.OUTREACH_COMPLETED};${s}`
				),
		  ]
		: statusValues;
}

/**
 * @param {(v: string) => void} onChange
 * @param {{ status: string[]; substatus: string[]; }} value
 */
export function Status({ onChange, value }) {
	return (
		<MultiSelectCheckbox
			data={COMBINED_OPTIONS}
			selected={filterToValue(value)}
			title='Status'
			submit={onChange}
			majorPillTitle='Status'
			searchBar={false}
			previewTab={false}
			emptyInputFullOutputOverride={false}
		/>
	);
}
