import React, { forwardRef } from 'react';

import { addressTypeConst } from '../../../../../utils';

import { useSection } from '../../../../Patients/V3/CreatePatient/sections/useSection';
import PreFilledCreditCard from '../../../PreFilledCreditCard';

import { AddressForm } from '../components/AddressForm';
import { CreditCardForm } from '../../../../Patients/V3/CreatePatient/components/CreditCardForm';

export const SelfPayment = forwardRef(function SelfPayment(
	{ sectionDataContainerName, hasSavedCard, cardInfo },
	ref
) {
	const { allValues, setFieldValue, values } = useSection(
		sectionDataContainerName
	);

	const showHomeAddress = values.consultationPrice !== 0;

	return (
		<>
			{values.consultationPrice !== null &&
				values.consultationPrice !== 0 &&
				(allValues.patientInfo.patientId &&
				hasSavedCard &&
				values.useSavedCard ? (
					<PreFilledCreditCard
						cardInfo={cardInfo}
						changePaymentMethod={() =>
							setFieldValue('useSavedCard', false)
						}
					/>
				) : (
					<CreditCardForm
						ref={ref}
						required
						sectionDataContainerName={sectionDataContainerName}
						referralProgramId={
							allValues.patientInfo.associatedProgram
						}
					/>
				))}
			{/* Note: Although this field is labeled as 'Billing address', it is represented as the 'Home address' on the backend. */}
			{showHomeAddress && (
				<>
					<h3>Billing Address</h3>
					<AddressForm
						sectionDataContainerName={sectionDataContainerName}
						addressType={addressTypeConst.home}
					/>
				</>
			)}
		</>
	);
});
