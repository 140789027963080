//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//UI Libraries
import { Checkbox } from 'gm-element-react';

class CommonCheckbox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasOnChangeFunc: false,
		};
		//bindings
		this.determineHasOnChangeFunc =
			this.determineHasOnChangeFunc.bind(this);
		this.determineClassName = this.determineClassName.bind(this);
		this.determineStyling = this.determineStyling.bind(this);
	}
	//functions

	componentDidMount() {
		this.determineHasOnChangeFunc();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			get(prevProps, 'disabled', false) !==
				get(this, 'props.disabled', false) ||
			get(prevProps, 'hasOnChangeFunc', false) !==
				get(this, 'props.hasOnChangeFunc', false)
		) {
			this.determineHasOnChangeFunc();
		}
	}

	determineHasOnChangeFunc() {
		const onChangeFunc = get(this, 'props.onChangeFunc', null);
		if (typeof onChangeFunc === 'function')
			this.setState({ hasOnChangeFunc: true });
	}

	determineClassName() {
		let className = '';
		const uncheckedClassOverride = get(
			this,
			'props.uncheckedClassOverride',
			false
		);
		const checkedClassOverride = get(
			this,
			'props.checkedClassOverride',
			false
		);

		if (typeof uncheckedClassOverride === 'string')
			className += uncheckedClassOverride;
		else className += 'chargeICDSearchResultRowCheckbox';

		if (typeof checkedClassOverride === 'string')
			className += ` ${checkedClassOverride}`;
		else className += ' chargeICDSearchResultRowCheckboxSelected';

		return className;
	}

	determineStyling() {
		const style = {};
		const marginLeftOverride = get(this, 'props.marginLeftOverride', null);
		if (typeof marginLeftOverride === 'number') {
			style.marginLeft = `${marginLeftOverride}px`;
		}
		const marginRightOverride = get(
			this,
			'props.marginRightOverride',
			null
		);
		if (typeof marginRightOverride === 'number') {
			style.marginRight = `${marginRightOverride}px`;
		}
		const marginBottomOverride = get(
			this,
			'props.marginBottomOverride',
			null
		);
		if (typeof marginBottomOverride === 'number') {
			style.marginBottom = `${marginBottomOverride}px`;
		}
		const topOverride = get(this, 'props.topOverride', null);
		if (typeof topOverride === 'number') {
			style.top = `${topOverride}px`;
		}
		return style;
	}

	render() {
		let additionalArgs = get(this, 'props.additionalOnChangeArgs', null);
		if (!Array.isArray(additionalArgs)) additionalArgs = [];
		return (
			<Checkbox
				checked={get(this, 'props.checked', false)}
				indeterminate={get(this, 'props.indeterminate', false)}
				onChange={
					get(this, 'state.hasOnChangeFunc', false)
						? (e) => {
								if (
									get(this, 'props.noOnChangeEvtArg', false)
								) {
									return this.props.onChangeFunc(
										...additionalArgs
									);
								} else {
									return this.props.onChangeFunc(
										e,
										...additionalArgs
									);
								}
						  }
						: null
				}
				inputProps={get(this, 'props.inputProps', null)}
				className={this.determineClassName()}
				textColor={get(this, 'props.textColor', null)}
				id={get(this, 'props.id', null)}
				style={this.determineStyling()}
				disabled={get(this, 'props.disabled', false)}
			/>
		);
	}
}

export default CommonCheckbox;
