//React & Redux
import React, { Component, Fragment } from 'react';

//Images
import redx from '../../../../../../assets/red-x.svg';
import whitecheck from '../../../../../../assets/white-check.svg';

//Lodash
import _ from 'lodash';

//Utils
import { scp_encounter_sub_types_automated_pre_test } from '../../../../../../Utils/encounterUtils';
import {
	getTestOrderScruber,
	getProviderIcon,
	getProviderName,
	GetSelectvalue,
	isPartnerInitiatedUnsolicitedResult,
} from '../../Common/utilsfunctions';
import {
	CARE_TEST_ACTIONS,
	CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER,
	CARE_PATIENTS_ENTER_TEST_RESULT,
} from '../../../../../../Utils/permissionUtils';
import { outreach_pill_bg_color } from '../../../../../../utils.js';

//UI Libraries
import { Loading, Button } from 'gm-element-react';

//Other Libraries
import moment from 'moment';
import classnames from 'classnames';

//Components
import RBAC from '../../../../../RBAC';
import EditTestOrderModalContainer from '../../../TestOrders/EditTestOrderModalContainer';
import Pill from '../../../../../Common/Pill';
import TextWithTooltip from '../../../../../Common/TextWithTooltip';

import FlaggedForReview from './FlaggedForReview.js';
import RejectTestRequestModal from '../../RejectTestRequestModal/RejectTestRequestModal';
import ERTooltip from '../../../../../Common/Tooltip';

//Styles
import './style.css';

class TestOrderRow extends Component {
	constructor(props) {
		super(props);
		this.outreachTimeTimer = null;
		this.state = {
			loading: false,
			showModal: false,
			showRejectTestModal: false,
			defaultTabName: 'testInfoTab',
			outreachTimeToStart: '',
		};
		//bindings
		this.hideRejectTestModal = this.hideRejectTestModal.bind(this);
		this.updateTestOrder = this.updateTestOrder.bind(this);
	}

	componentDidMount() {
		this.outreachTimeInterval();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.progressbarPathObj != this.props.progressbarPathObj) {
			this.getOutreachTime();
		}
	}

	componentWillUnmount() {
		clearInterval(this.outreachTimeTimer);
	}

	approveOrRejectSingleTest = (uuid, action, extraPayload, test) => {
		let payload = {};
		if (uuid && action) {
			payload.order_status = action;
			payload.date_test_authorized = moment.utc().format('YYYY-MM-DD');
			if (action === 'rejected') payload.status_reason = 'rejected_other';
			if (extraPayload) payload = { ...payload, ...extraPayload };
			this.props.updateTestOrder(uuid, payload).then((result) => {
				this.hideRejectTestModal();
			});
		}
	};

	allowICCodes = () => {
		const orderStatus = _.get(
			this.props,
			['testorder', 'order_status'],
			''
		);
		const allow = {
			cancelled: 1,
			order_cancelled_by_provider: 1,
			order_cancelled_by_patient: 1,
			order_cancelled_by_lab: 1,
		};
		return !(orderStatus in allow);
	};

	isEditable = () => {
		const orderStatus = _.get(
			this.props,
			['testorder', 'order_status'],
			''
		);
		const allow = {
			requested: 1,
		};
		return !(orderStatus in allow);
	};

	hideModal = () => {
		this.setState({ showModal: false, defaultTabName: 'testInfoTab' });
	};

	hideRejectTestModal = () => {
		this.setState({ showRejectTestModal: false });
	};

	approveTest = () => {
		this.props.updateTestOrder(
			_.get(this, ['props', 'testorder', 'test_order_uuid'], ''),
			{
				order_status: 'approved',
				date_test_authorized: moment
					.utc()
					.format('YYYY-MM-DD HH:mm:ss'),
			}
		);
	};

	rejectTest = () => {
		this.setState({ showRejectTestModal: true });
	};

	updateTestOrder = (testOrderUUID, data) => {
		return this.props.updateTestOrder(testOrderUUID, data);
	};

	getProgressbarArray = () => {
		if (
			!isPartnerInitiatedUnsolicitedResult(
				_.get(this, ['props', 'encounter'], {})
			)
		) {
			return getTestOrderScruber(
				_.get(this.props, ['progressbarPathObj'], {}),
				_.get(this.props, ['mloOrderStatusEnums'], [])
			);
		} else {
			return getTestOrderScruber(
				_.get(this.props, ['progressbarPathObj'], {}),
				[
					{
						display_name: 'Results Ready',
						key: 'results_ready',
					},
					{
						display_name: 'Findings Available',
						key: 'results_entered',
						children: [
							{
								key: 'result_positive_with_genes',
								display_name: 'Findings entered',
							},
							{
								key: 'result_positive_no_genes',
								display_name: 'Findings, Awaiting Entry',
							},
							{
								key: 'result_negative',
								display_name: 'No Findings',
							},
						],
					},
				],
				true
			);
		}
	};

	allowGotoTestLab = () => {
		const orderStatus = _.get(
			this.props,
			['testorder', 'order_status'],
			''
		);
		const allow = {
			results_entered: 1,
		};
		return orderStatus in allow;
	};

	allowEnterResult = () => {
		const orderStatus = _.get(
			this.props,
			['testorder', 'order_status'],
			''
		);
		const allow = {
			results_ready: 1,
		};
		return orderStatus in allow;
	};

	goToTestDetail = () => {
		this.props.gotoMLOTestDetail(
			_.get(this, ['props', 'testorder', 'test_order_uuid'], 0)
		);
	};

	getShortText = (text, length) => {
		if (text && !_.isEmpty(text) && text.length > length) {
			return (
				<TextWithTooltip multiLine={true} maxWidth='190px'>
					{text}
				</TextWithTooltip>
			);
		} else {
			return text;
		}
	};

	getOutreachTime = () => {
		let outreachToStartMin = 0;
		let outreachToStartSec = 0;
		const testOutreachStatus = _.get(
			this,
			['props', 'testOutreachStatus'],
			''
		);

		if (
			moment(
				_.get(this, ['props', 'outreachTimeToStart'], null)
			).isValid() &&
			testOutreachStatus == 'waiting_to_start'
		) {
			const outreachTimeToStart = moment.utc(
				_.get(this, ['props', 'outreachTimeToStart'], null)
			);
			const currentutc = moment.utc();

			outreachToStartSec = outreachTimeToStart.diff(
				currentutc,
				'seconds'
			);
			outreachToStartMin = outreachToStartMin = Math.ceil(
				outreachToStartSec / 60
			);

			if (currentutc.isSameOrAfter(outreachTimeToStart)) {
				clearInterval(this.outreachTimeTimer);
				setTimeout(() => {
					this.props.callTestOrderRequest(false);
				}, 20 * 1000);
				this.setState({ outreachTimeToStart: 1 });
			} else {
				this.setState({ outreachTimeToStart: outreachToStartMin });
			}
		} else {
			clearInterval(this.outreachTimeTimer);
		}
	};

	outreachTimeInterval = () => {
		this.getOutreachTime();
		this.outreachTimeTimer = setInterval(() => {
			this.getOutreachTime();
		}, 15 * 1000);
	};

	getPillText = () => {
		const testOutreachStatus = _.get(
			this,
			['props', 'testOutreachStatus'],
			''
		);
		const outreachDisplayName = GetSelectvalue(
			this,
			['props', 'testOutreachStatus'],
			_.get(this, ['props', 'outreachStatusEnums'], [])
		);
		let pillText = '';

		switch (testOutreachStatus) {
			case 'waiting_to_start':
				pillText = `Outreach Starts in ${this.state.outreachTimeToStart}m`;
				break;
			case 'in_progress':
				pillText = `Outreach ${outreachDisplayName}`;
				break;
			default:
				pillText = `${outreachDisplayName}`;
		}

		return pillText;
	};

	isShowPill = () => {
		const statusEnum = {
			results_entered: 1,
			result_positive_no_genes: 1,
			result_positive_with_genes: 1,
			result_negative: 1,
		};

		const propsorderstatus = _.get(
			this,
			['props', 'testorder', 'localorderstatus'],
			''
		);

		if (propsorderstatus in statusEnum) {
			return !(
				_.get(this, ['props', 'testOutreachStatus'], '') ==
				'not_started'
			);
		}
		return false;
	};

	render() {
		const props = this.props;
		const { encounter, testorder } = props;
		const isAutomatedPreTest = _.get(
			scp_encounter_sub_types_automated_pre_test,
			_.get(encounter, 'encounter_subtype', ''),
			false
		);
		const isPreAppt = _.get(this, 'props.isPreAppt', false);
		const isPartnerInitiatedPreTest = _.get(
			this,
			'props.isPartnerInitiatedPreTest',
			false
		);
		const progressbarArray = this.getProgressbarArray();
		const creatingTestOrderFlags = _.get(
			this,
			'props.creatingTestOrderFlags',
			null
		);
		const encounterTestOrderRequestLoading = _.get(
			this,
			'props.encounterTestOrderRequestLoading',
			null
		);
		const testOutreachStatus = _.get(
			this,
			['props', 'testOutreachStatus'],
			''
		);
		const canChangeOrder = _.get(
			props,
			['testOrderList', '0', 'can_change_order'],
			true
		);
		const isEditable = this.isEditable();

		return (
			<Loading
				loading={
					_.get(this, ['state', 'loading'], false) ||
					creatingTestOrderFlags ||
					encounterTestOrderRequestLoading
				}
			>
				<div className='scp-testorder-row'>
					<div className='scp-testorder-row-container'>
						{
							<Fragment>
								<div className='testorder-testname-bar'>
									<RBAC
										action={
											CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
										}
										yes={
											<Button
												className={classnames(
													'val-test-name',
													!isEditable
														? 'no-click'
														: ''
												)}
												onClick={() =>
													(isAutomatedPreTest ||
														isPartnerInitiatedPreTest ||
														isPartnerInitiatedUnsolicitedResult(
															encounter
														)) &&
													isEditable &&
													this.setState({
														showModal: true,
													})
												}
												type='text'
											>
												<TextWithTooltip>
													{_.get(
														props,
														[
															'testorder',
															'test_name',
														],
														'--'
													)}
												</TextWithTooltip>
											</Button>
										}
										no={
											<Button
												className='val-test-name disable-btn-extrnl-user'
												type='text'
											>
												<TextWithTooltip>
													{_.get(
														props,
														[
															'testorder',
															'test_name',
														],
														'--'
													)}
												</TextWithTooltip>
											</Button>
										}
									/>

									<RBAC
										action={
											CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
										}
										yes={
											<Button
												className={classnames(
													'link-icd-10 button-text',
													!isEditable
														? 'no-click'
														: ''
												)}
												onClick={() =>
													isEditable &&
													this.allowICCodes() &&
													this.setState({
														showModal: true,
														defaultTabName:
															'ICD10CodesTab',
													})
												}
												type='text'
											>
												ICD-10 Codes (
												{_.get(
													this,
													[
														'props',
														'testorder',
														'medical_codes',
														'length',
													],
													0
												)}
												)
											</Button>
										}
										no={
											<Button
												className='link-icd-10 button-text disable-btn-extrnl-user'
												type='text'
											>
												ICD-10 Codes (
												{_.get(
													this,
													[
														'props',
														'testorder',
														'medical_codes',
														'length',
													],
													0
												)}
												)
											</Button>
										}
									/>

									<span className='divider-dot'>.</span>

									<RBAC
										action={
											CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
										}
										yes={
											<Button
												className={classnames(
													'button-text',
													!isEditable
														? 'no-click'
														: ''
												)}
												onClick={() =>
													isEditable &&
													this.setState({
														showModal: true,
														defaultTabName:
															'notesTab',
													})
												}
												type='text'
											>
												Notes (
												{_.get(
													this,
													['props', 'orderNoteCount'],
													0
												)}
												)
											</Button>
										}
										no={
											<Button
												className='button-text disable-btn-extrnl-user'
												type='text'
											>
												Notes (
												{_.get(
													this,
													['props', 'orderNoteCount'],
													0
												)}
												)
											</Button>
										}
									/>

									{this.allowGotoTestLab() && (
										<Fragment>
											<span className='divider-dot'>
												.
											</span>
											<RBAC
												action={
													CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
												}
												yes={
													<Button
														className='button-text'
														onClick={() =>
															this.goToTestDetail()
														}
														type='text'
													>
														Edit Results
													</Button>
												}
												no={
													<Button
														className='button-text'
														onClick={() =>
															this.goToTestDetail()
														}
														type='text'
													>
														View Results
													</Button>
												}
											/>
										</Fragment>
									)}

									<RBAC
										action={CARE_PATIENTS_ENTER_TEST_RESULT}
										yes={
											this.allowEnterResult() && (
												<Button
													className='button-text'
													onClick={() =>
														this.goToTestDetail()
													}
													type='text'
												>
													Enter Results
												</Button>
											)
										}
									/>

									{/* <RBAC
                    action={CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER}
                    yes={<Button
                      className="button-text"
                      onClick={() => this.setState({ showModal: true, defaultTabName: 'AuditTrail' })}
                      type="text">
                        Audit Trail
                      </Button>
                    }
                    no={<Button
                      className="button-text disable-btn-extrnl-user"
                      type="text">
                        Audit Trail
                      </Button>
                    }
                  /> */}
								</div>
								<div className='testorder-tracking-bar'>
									<div className='testorder-left-side'>
										<div className='lab-name'>
											{' '}
											{_.get(
												props,
												['testorder', 'lab'],
												'--'
											)}
										</div>
									</div>
									{this.isShowPill() && (
										<span className='outreach-pill'>
											<Pill
												pillText={this.getPillText()}
												pillExtendStyle={
													outreach_pill_bg_color[
														testOutreachStatus
													]
												}
											/>
										</span>
									)}
								</div>
								<div className='testorder-tracking-bar'>
									<div
										className={classnames(
											'progressbar-wrapper',
											isPartnerInitiatedUnsolicitedResult(
												encounter
											)
												? 'unsolicited-result-progressbar'
												: ''
										)}
									>
										{isAutomatedPreTest ||
										isPartnerInitiatedPreTest ||
										isPartnerInitiatedUnsolicitedResult(
											encounter
										) ? (
											<ul className='progressbar'>
												{progressbarArray.map(
													(row, index) => {
														return (
															<li
																className={
																	row.className
																}
															>
																{row.displayName ===
																	'Rejected' &&
																!_.isEmpty(
																	_.get(
																		row,
																		[
																			'orderStatusNote',
																		],
																		''
																	)
																) ? (
																	<ERTooltip
																		effect='dark'
																		content={_.get(
																			row,
																			[
																				'orderStatusNote',
																			],
																			''
																		)}
																		placement='top'
																		className='tt-rejected-test-reason'
																	>
																		<span className='circle' />
																	</ERTooltip>
																) : (
																	<span className='circle' />
																)}
																<div
																	className={classnames(
																		'step-name',
																		_.includes(
																			row.displayName,
																			'Actionable'
																		)
																			? 'result-actionable'
																			: ''
																	)}
																>
																	{this.getShortText(
																		row.displayName,
																		!_.includes(
																			row.displayName,
																			'Actionable'
																		)
																			? 26
																			: 38
																	)}
																</div>
																<div className='step-date'>
																	{
																		row.displayDate
																	}
																</div>
															</li>
														);
													}
												)}
											</ul>
										) : null}
									</div>
									<div className='ordering-sec'>
										<div className='lbl-ordering-physician'>
											Ordering physician
										</div>
										<div className='val-ordering-physician'>
											{getProviderIcon(props, [
												'testorder',
												'ordering_physician',
											]) != null && (
												<img
													src={getProviderIcon(
														props,
														[
															'testorder',
															'ordering_physician',
														]
													)}
													alt=''
												/>
											)}
											{getProviderName(
												props,
												[
													'testorder',
													'ordering_physician',
												],
												_.get(
													props,
													['ordering_physicianenums'],
													[]
												)
											)}
										</div>
									</div>
								</div>
							</Fragment>
						}
					</div>

					<div
						className='scp-testorder-row-middle'
						style={
							isAutomatedPreTest || isPartnerInitiatedPreTest
								? { borderBottom: 'none' }
								: {}
						}
					>
						<div className='inner-scp-testorder-middle'>
							<div className='scp-testorder-half'>
								{!isPartnerInitiatedPreTest &&
									!isPartnerInitiatedUnsolicitedResult(
										encounter
									) && (
									<Fragment>
										<div className='clinical-flag-title'>
											CLINICAL FLAG
										</div>
										<div className='medical-history-content'>
											No items flagged from patient’s
											personal/family medical history
										</div>
										<div className='scp-content-list'>
											<ul>
												<li>
													{' '}
													Patient declined pre-test
													1:1 GC appointment{' '}
												</li>
												<li>
													Patient attests to reviewing
													and understanding the
													presented educational
													materials{' '}
												</li>
												<li>
													{' '}
													Patient consents to testing{' '}
												</li>
											</ul>
										</div>
									</Fragment>
								)}
								<div className={classnames('questionnaire-link', isPartnerInitiatedUnsolicitedResult(encounter) ? 'unsolicited-result' : '')}>
									{isPartnerInitiatedUnsolicitedResult(
										encounter
									) && (
										<div className='related-documents-title'>
											RELATED DOCUMENTS
										</div>
									)}
									<ul>
										<li>
											<a
												href='javascript:void(0)'
												onClick={() =>
													props.goToDocumentTab()
												}
											>
												Questionnaire
											</a>
										</li>
										{(isAutomatedPreTest ||
											isPartnerInitiatedPreTest ||
											isPartnerInitiatedUnsolicitedResult(encounter)) && (
											<li>
												<a
													href='javascript:void(0)'
													onClick={() =>
														props.navigateAndExpand(
															'additional_info',
															'AdditionalSegment'
														)
													}
												>
													{' '}
													CC Notes{' '}
												</a>
											</li>
										)}
									</ul>
								</div>
							</div>

							{!isPreAppt &&
								!isPartnerInitiatedPreTest &&
								!isPartnerInitiatedUnsolicitedResult(
									encounter
								) && (
								<div className='scp-testorder-half'>
									<FlaggedForReview
										createTestRequestFlags={
											this.props.createTestRequestFlags
										}
										updateTestRequestFlags={
											this.props.updateTestRequestFlags
										}
										testOrderUuid={
											_.get(
												this,
												'props.testorder.test_order_uuid',
												null
											) || null
										}
										flags={
											_.get(
												this,
												'props.testorder.flags',
												[]
											) || []
										}
										creatingTestOrderFlags={_.get(
											this,
											'props.creatingTestOrderFlags',
											null
										)}
										updatingTestOrderFlags={_.get(
											this,
											'props.updatingTestOrderFlags',
											null
										)}
										testOrderStatus={_.get(
											this,
											'props.testorder.order_status',
											null
										)}
									/>
								</div>
							)}
						</div>
					</div>
					{(isAutomatedPreTest || isPartnerInitiatedPreTest) &&
						canChangeOrder &&
						!isEditable && (
							<div className='scp-testorder-row-footer'>
								<div className='btn-segment'>
									<RBAC
										action={CARE_TEST_ACTIONS}
										yes={
											<button
												className='reject-btn'
												onClick={(e) =>
													this.rejectTest()
												}
											>
												<span className='reject-btn-icon'>
													{' '}
													<img
														src={redx}
														alt=''
													/>{' '}
												</span>{' '}
												Reject
											</button>
										}
									/>
									<RBAC
										action={CARE_TEST_ACTIONS}
										yes={
											<button
												className='approve-btn'
												onClick={(e) =>
													this.approveTest()
												}
											>
												<span className='approve-btn-icon'>
													{' '}
													<img
														src={whitecheck}
														alt=''
													/>{' '}
												</span>{' '}
												Approve
											</button>
										}
									/>
								</div>
							</div>
						)}
					{_.get(this, 'state.showRejectTestModal', false) && (
						<div className='popupModal'>
							<RejectTestRequestModal
								hideRejectTestModal={this.hideRejectTestModal}
								updateTestOrder={this.updateTestOrder}
								patientDetail={{
									dob: _.get(
										this,
										['props', 'patientdetail', 'dob'],
										null
									),
									first_name: _.get(
										this,
										[
											'props',
											'patientdetail',
											'first_name',
										],
										''
									),
									last_name: _.get(
										this,
										['props', 'patientdetail', 'last_name'],
										''
									),
									email: _.get(
										this,
										['props', 'patientdetail', 'email'],
										''
									),
									gender: _.get(
										this,
										['props', 'patientdetail', 'gender'],
										''
									),
									referral_program: _.get(
										this,
										[
											'props',
											'patientdetail',
											'referral_program',
										],
										''
									),
								}}
								testOrder={{
									testname: _.get(
										props,
										['testorder', 'test_name'],
										''
									),
									lab: _.get(props, ['testorder', 'lab'], ''),
									test_order_uuid: _.get(
										props,
										['testorder', 'test_order_uuid'],
										''
									),
								}}
								approveOrRejectSingleTest={
									this.approveOrRejectSingleTest
								}
								testOrderUpdating={_.get(
									this.props,
									'testOrderUpdating',
									false
								)}
							/>
						</div>
					)}
				</div>
				{_.get(this, 'state.showModal', false) && (
					<div className='popupModal'>
						<EditTestOrderModalContainer
							hideModal={this.hideModal}
							getICDCodes={props.getICDCodes}
							icdCodes={_.get(props, ['icdCodes'], [])}
							icdCodesLoading={_.get(
								props,
								['icdCodesLoading'],
								false
							)}
							icdCodesError={_.get(
								props,
								['icdCodesError'],
								null
							)}
							icdCodesPlacement={_.get(
								props,
								['icdCodesPlacement'],
								null
							)}
							enqueueSnackbar={props.enqueueSnackbar}
							selectedICDCodes={_.get(
								this,
								'props.testorder.medical_codes',
								null
							)}
							labenums={_.get(
								props,
								['encounterschema', 'lab'],
								[]
							)}
							LoadTestOrderPopupTestEnum={_.get(
								props,
								['LoadTestOrderPopupTestEnum'],
								[]
							)}
							nonlegacytestOrderPopupTestEnums={_.get(
								props,
								['nonlegacytestOrderPopupTestEnums'],
								[]
							)}
							encounterschema={_.get(
								props,
								['encounterschema'],
								{}
							)}
							testorder={_.get(props, ['testorder'], {})}
							ordering_physicianenums={_.get(
								props,
								['ordering_physicianenums'],
								[]
							)}
							testnameenums={_.get(props, ['testnameenums'], [])}
							orderrequeststatuscascadeenums={_.get(
								props,
								['orderrequeststatuscascadeenums'],
								[]
							)}
							encounter={_.get(props, ['encounter'], {})}
							patientdetail={_.get(props, ['patientdetail'], {})}
							orderstatuscascadeenums={_.get(
								props,
								['orderstatuscascadeenums'],
								[]
							)}
							sampletypeenums={_.get(
								props,
								['sampletypeenums'],
								[]
							)}
							laborderstatusenums={_.get(
								props,
								['laborderstatusenums'],
								[]
							)}
							patientbillingmethodenums={_.get(
								props,
								['patientbillingmethodenums'],
								[]
							)}
							mloOrderStatusEnums={_.get(
								props,
								['mloOrderStatusEnums'],
								[]
							)}
							updateTestOrder={props.updateTestOrder}
							testOrderNumber={_.get(props, 'index', 1)}
							encounterICDcodes={_.get(
								props,
								'encounter.medical_codes',
								[]
							)}
							user={_.get(props, ['user'], {})}
							callTestOrderNotes={_.get(
								props,
								['callTestOrderNotes'],
								null
							)}
							testOrderNotes={_.get(
								props,
								['testOrderNotes'],
								[]
							)}
							defaultTabName={this.state.defaultTabName}
							testOutreachStatus={_.get(
								this,
								['props', 'testOutreachStatus'],
								''
							)}
							outreachStatusEnums={_.get(
								this,
								['props', 'outreachStatusEnums'],
								[]
							)}
							outreachTimeToStart={_.get(
								this,
								['props', 'outreachTimeToStart'],
								null
							)}
							testOrderList={_.get(
								this,
								['props', 'testOrderList'],
								[]
							)}
							outreachSeqNo={_.get(
								this,
								['props', 'outreachSeqNo'],
								0
							)}
							getEncounterAuditTrail={
								this.props.getEncounterAuditTrail
							}
						/>
					</div>
				)}
			</Loading>
		);
	}
}

export default TestOrderRow;
