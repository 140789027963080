import React, { Fragment } from 'react';
import AuditTrailHistoryRecord from './AuditTrailHistoryRecord';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { getEncounterSpecificTime } from '../V2/Encounters/Common/utilsfunctions';

const AuditTrailHistory = ({ smsConsentLog, patientName, selectedLog, recontactConsentLog }) => {
	const updatedFromOptions = {
		gmi: 'gmi',
		patient_portal: 'patient_portal',
		twillio: 'twillio',
		referral_api: 'referral_api',
	};

	const getMessage = (item) => {
		const {
			log: { status },
		} = item;
		return `${
			status == 'opt-in'
				? 'Opted in to Text Message'
				: `Opted out of Text Message`
		}`;
	};

	const getByName = (user, log) => {
		const {
			first_name, last_name
		} = user;

		const {
			updated_from
		} = log;


		switch (updated_from) {
			case updatedFromOptions.patient_portal:
				return patientName;
			case updatedFromOptions.gmi:
				return `${first_name} ${last_name}`;
			case updatedFromOptions.referral_api:
				return 'System';
			case updatedFromOptions.twillio:
				return patientName;
			default:
				return `${first_name} ${last_name}`;
		}
	};

	const getOnDate = (createdDate) => {
		const {
			created_at
		} = createdDate;
		return getEncounterSpecificTime(created_at);
	};

	return (
		selectedLog == 'smsConsent' ?
			<Fragment>
				{orderBy(smsConsentLog, ['log.created_at'], ['desc']).map(
					(item, index) => (
						<AuditTrailHistoryRecord
							key={`AuditTrailHistoryRecord-${index}`}
							message={getMessage(item)}
							byName={getByName(item.user, item.log)}
							onDate={getOnDate(item.log)}
							lastItem={smsConsentLog.length === index + 1}
						/>
					)
				)}
			</Fragment> :
			selectedLog == 'recontactConsent' ?
			<Fragment>
				{orderBy(recontactConsentLog, ['created_at'], ['desc']).map(
					(item, index) => (
						<AuditTrailHistoryRecord
							key={`AuditTrailHistoryRecord-${index}`}
							message={_.capitalize(`${item.status} (${item.consent_version})`)}
							byName={getByName(item.user, {})}
							onDate={getOnDate(item)}
							lastItem={recontactConsentLog.length === index + 1}
						/>
					)
				)}
			</Fragment> : ''

	);
};

export default AuditTrailHistory;
