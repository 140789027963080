import * as actionTypes from '../actions/actionTypes';
import _ from 'lodash';

export const getResults = (state = { search_results: [] }, action) => {
	if (action.type === actionTypes.ENHANCED_SEARCH_RESULTS) {
		const newState = Object.assign({}, state);
		newState.search_results = action.payload;

		return newState;
	} else {
		return state;
	}
};

export const getSearchExportReport = (
	state = { search_export_status: [] },
	action
) => {
	if (action.type === actionTypes.ENHANCED_SEARCH_RESULTS_EXPORT) {
		const newState = Object.assign({}, state);
		newState.search_export_status = action.payload;

		return newState;
	} else {
		return state;
	}
};

export const searchAffiliations = (
	state = { searchaffiliation: [] },
	action
) => {
	if (action.type === actionTypes.LOADING_ENHANCED_SEARCH_AFFILIATIONS) {
		const newState = Object.assign({}, state);
		newState.enhancedSearchaffiliationLoading = true;
		newState.enhancedSearchaffiliationError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_AFFILIATIONS) {
		const newState = Object.assign({}, state);
		newState.enhancedSearchaffiliationLoading = false;
		newState.enhancedSearchaffiliationError = null;
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.search_results = data;

		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_AFFILIATIONS_ERROR) {
		const newState = Object.assign({}, state);
		newState.enhancedSearchaffiliationLoading = false;
		newState.enhancedSearchaffiliationError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchOrderingPhysicians = (
	state = { searchorderingPhysicians: [] },
	action
) => {
	if (
		action.type === actionTypes.LOADING_ENHANCED_SEARCH_ORDERING_PHYSICIANS
	) {
		const newState = Object.assign({}, state);
		newState.enhancedSearchOrderingPhysiciansLoading = true;
		newState.enhancedSearchOrderingPhysiciansError = null;
		return newState;
	} else if (
		action.type === actionTypes.ENHANCED_SEARCH_ORDERING_PHYSICIANS
	) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchorderingPhysicians = data;

		return newState;
	} else if (
		action.type === actionTypes.ENHANCED_SEARCH_ORDERING_PHYSICIANS_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.enhancedSearchOrderingPhysiciansLoading = false;
		newState.searchorderingPhysicians = [];
		newState.enhancedSearchOrderingPhysiciansError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchVisitProviders = (
	state = { searchvisitproviders: [] },
	action
) => {
	if (action.type === actionTypes.LOADING_ENHANCED_SEARCH_VISIT_PROVIDERS) {
		const newState = Object.assign({}, state);
		newState.searchvisitprovidersLoading = true;
		newState.searchvisitprovidersError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_VISIT_PROVIDERS) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchvisitprovidersLoading = false;
		newState.searchvisitproviders = data;
		newState.searchvisitprovidersError = null;

		return newState;
	} else if (
		action.type === actionTypes.ENHANCED_SEARCH_VISIT_PROVIDERS_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.searchvisitprovidersLoading = false;
		newState.searchvisitproviders = [];
		newState.searchvisitprovidersError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchSpecialty = (state = { searchspecialty: [] }, action) => {
	if (action.type === actionTypes.LOADING_ENHANCED_SEARCH_SPECIALTY) {
		const newState = Object.assign({}, state);
		newState.searchspecialtyLoading = true;
		newState.searchspecialtyError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_SPECIALTY) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchspecialty = data;
		newState.searchspecialtyLoading = false;
		newState.searchspecialtyError = null;

		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_SPECIALTY_ERROR) {
		const newState = Object.assign({}, state);
		newState.searchspecialtyLoading = false;
		newState.searchspecialty = [];
		newState.searchspecialtyError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchTestNames = (state = { searchtestnames: [] }, action) => {
	if (action.type === actionTypes.LOADING_ENHANCED_SEARCH_TEST_NAMES) {
		const newState = Object.assign({}, state);
		newState.searchtestnamesLoading = true;
		newState.searchtestnamesError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_TEST_NAMES) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchtestnames = data;
		newState.searchtestnamesLoading = false;
		newState.searchtestnamesError = null;

		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_TEST_NAMES_ERROR) {
		const newState = Object.assign({}, state);
		newState.searchtestnamesLoading = false;
		newState.searchtestnames = [];
		newState.searchtestnamesError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchTestResults = (
	state = { searchtestresults: [] },
	action
) => {
	if (action.type === actionTypes.LOADING_ENHANCED_SEARCH_TEST_RESULTS) {
		const newState = Object.assign({}, state);
		newState.searchtestresultsLoading = true;
		newState.searchtestresultsError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_TEST_RESULTS) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchtestresults = data;
		newState.searchtestresultsLoading = false;
		newState.searchtestresultsError = null;

		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_TEST_RESULTS_ERROR) {
		const newState = Object.assign({}, state);
		newState.searchtestresultsLoading = false;
		newState.searchtestresults = [];
		newState.searchtestresultsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchVisitStatus = (
	state = { searchvisitstatus: [] },
	action
) => {
	if (action.type === actionTypes.LOADING_ENHANCED_SEARCH_ORDER_STATUS) {
		const newState = Object.assign({}, state);
		newState.searchvisitstatusLoading = true;
		newState.searchvisitstatusError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_VISIT_STATUS) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchvisitstatus = data;
		newState.searchvisitstatusLoading = false;
		newState.searchvisitstatusError = null;

		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_ORDER_STATUS_ERROR) {
		const newState = Object.assign({}, state);
		newState.searchvisitstatusLoading = false;
		newState.searchvisitstatus = [];
		newState.searchvisitstatusError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchActionability = (
	state = { searchactionability: [] },
	action
) => {
	if (action.type === actionTypes.LOADING_ENHANCED_SEARCH_ACTIONABILITY) {
		const newState = Object.assign({}, state);
		newState.searchactionabilityLoading = true;
		newState.searchactionabilityError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_ACTIONABILITY) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchactionability = data;
		newState.searchactionabilityLoading = false;
		newState.searchactionabilityError = null;

		return newState;
	} else if (
		action.type === actionTypes.ENHANCED_SEARCH_ACTIONABILITY_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.searchactionabilityLoading = false;
		newState.searchactionability = [];
		newState.searchactionabilityError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchOrderStatus = (
	state = { searchOrderStatus: [] },
	action
) => {
	if (action.type === actionTypes.LOADING_ENHANCED_SEARCH_ORDER_STATUS) {
		const newState = Object.assign({}, state);
		newState.searchOrderStatusLoading = true;
		newState.searchOrderStatusError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_ORDER_STATUS) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchOrderStatusLoading = false;
		newState.searchOrderStatus = data;
		newState.searchOrderStatusError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_ORDER_STATUS_ERROR) {
		const newState = Object.assign({}, state);
		newState.searchOrderStatusLoading = false;
		newState.searchOrderStatus = [];
		newState.searchOrderStatusError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchDispatchStatus = (
	state = { searchdispatchstatus: [] },
	action
) => {
	if (action.type === actionTypes.LOADING_ENHANCED_SEARCH_DISPATCH_STATUS) {
		const newState = Object.assign({}, state);
		newState.searchdispatchstatusLoading = true;
		newState.searchdispatchstatusError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_DISPATCH_STATUS) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchdispatchstatus = data;
		newState.searchdispatchstatusLoading = false;
		newState.searchdispatchstatusError = null;

		return newState;
	} else if (
		action.type === actionTypes.ENHANCED_SEARCH_DISPATCH_STATUS_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.searchdispatchstatusLoading = false;
		newState.searchdispatchstatus = [];
		newState.searchdispatchstatusError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchOutreachStatus = (
	state = { searchoutreachstatus: [] },
	action
) => {
	if (action.type === actionTypes.LOADING_ENHANCED_SEARCH_OUTREACH_STATUS) {
		const newState = Object.assign({}, state);
		newState.searchoutreachstatusLoading = true;
		newState.searchoutreachstatusError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_OUTREACH_STATUS) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchoutreachstatus = data;
		newState.searchoutreachstatusLoading = false;
		newState.searchoutreachstatusError = null;

		return newState;
	} else if (
		action.type === actionTypes.ENHANCED_SEARCH_OUTREACH_STATUS_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.searchoutreachstatusLoading = false;
		newState.searchoutreachstatus = [];
		newState.searchoutreachstatusError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchRequestDecision = (
	state = { searchrequestdecision: [] },
	action
) => {
	if (action.type === actionTypes.LOADING_ENHANCED_SEARCH_REQUEST_DECISION) {
		const newState = Object.assign({}, state);
		newState.searchrequestdecisionLoading = true;
		newState.searchrequestdecisionError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_REQUEST_DECISION) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchrequestdecision = data;
		newState.searchrequestdecisionLoading = false;
		newState.searchrequestdecisionError = null;

		return newState;
	} else if (
		action.type === actionTypes.ENHANCED_SEARCH_REQUEST_DECISION_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.searchrequestdecisionLoading = false;
		newState.searchrequestdecision = [];
		newState.searchrequestdecisionError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchReferringProviderName = (
	state = { searchreferringprovidername: [] },
	action
) => {
	if (
		action.type ===
		actionTypes.LOADING_ENHANCED_SEARCH_REFERRING_PROVIDER_NAME
	) {
		const newState = Object.assign({}, state);
		newState.searchreferringprovidernameLoading = true;
		newState.searchreferringprovidernameError = null;
		return newState;
	} else if (
		action.type === actionTypes.ENHANCED_SEARCH_REFERRING_PROVIDER_NAME
	) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchreferringprovidername = data;
		newState.searchreferringprovidernameLoading = false;
		newState.searchreferringprovidernameError = null;

		return newState;
	} else if (
		action.type ===
		actionTypes.ENHANCED_SEARCH_REFERRING_PROVIDER_NAME_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.searchreferringprovidernameLoading = false;
		newState.searchreferringprovidername = [];
		newState.searchreferringprovidernameError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchVisitState = (state = { searchvisitstate: [] }, action) => {
	if (action.type === actionTypes.LOADING_ENHANCED_SEARCH_VISIT_STATE) {
		const newState = Object.assign({}, state);
		newState.searchvisitstateLoading = true;
		newState.searchvisitstateError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_VISIT_STATE) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchvisitstate = data;
		newState.searchvisitstateLoading = false;
		newState.searchvisitstateError = null;

		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_VISIT_STATE_ERROR) {
		const newState = Object.assign({}, state);
		newState.searchvisitstateLoading = false;
		newState.searchvisitstate = [];
		newState.searchvisitstateError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchMultiPatientStatus = (
	state = { searchmultipatientstatus: [] },
	action
) => {
	if (
		action.type === actionTypes.LOADING_ENHANCED_SEARCH_MULTIPATIENT_STATUS
	) {
		const newState = Object.assign({}, state);
		newState.searchmultipatientstatusLoading = true;
		newState.searchmultipatientstatusError = null;
		return newState;
	} else if (
		action.type === actionTypes.ENHANCED_SEARCH_MULTIPATIENT_STATUS
	) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchmultipatientstatus = data;
		newState.searchmultipatientstatusLoading = false;
		newState.searchmultipatientstatusError = null;

		return newState;
	} else if (
		action.type === actionTypes.ENHANCED_SEARCH_MULTIPATIENT_STATUS_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.searchmultipatientstatusLoading = false;
		newState.searchmultipatientstatus = [];
		newState.searchmultipatientstatusError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchTestType = (state = { searchTestType: [] }, action) => {
	if (action.type === actionTypes.LOADING_ENHANCED_SEARCH_TEST_TYPES) {
		const newState = Object.assign({}, state);
		newState.searchTestTypeLoading = true;
		newState.searchTestTypeError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_TEST_TYPES) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchTestTypeError = null;
		newState.searchTestType = data;
		newState.searchTestTypeLoading = false;

		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_TEST_TYPES_ERROR) {
		const newState = Object.assign({}, state);
		newState.searchTestTypeLoading = false;
		newState.searchTestType = [];
		newState.searchTestTypeError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getEncounterTypes = (
	state = { searchEncounterTypes: [] },
	action
) => {
	if (action.type === actionTypes.LOADING_ENHANCED_ENCOUNTER_TYPES) {
		const newState = Object.assign({}, state);
		newState.searchEncounterTypesLoading = true;
		newState.searchEncounterTypesError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_ENCOUNTER_TYPES) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.searchEncounterTypesLoading = false;
		newState.searchEncounterTypes = data;
		newState.searchEncounterTypesError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_ENCOUNTER_TYPES_ERROR) {
		const newState = Object.assign({}, state);
		newState.searchEncounterTypesLoading = false;
		newState.searchEncounterTypes = [];
		newState.searchEncounterTypesError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchLabs = (state = { advancedSearchLabs: [] }, action) => {
	if (action.type === actionTypes.LOADING_ENHANCED_SEARCH_LABS) {
		const newState = Object.assign({}, state);
		newState.advancedSearchLabsLoading = true;
		newState.advancedSearchLabsError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_LABS) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.advancedSearchLabsLoading = false;
		newState.advancedSearchLabs = data;
		newState.advancedSearchLabsError = null;

		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_LABS_ERROR) {
		const newState = Object.assign({}, state);
		newState.advancedSearchLabsLoading = false;
		newState.advancedSearchLabs = [];
		newState.advancedSearchLabsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const searchPatientTags = (
	state = { advancedSearchPatientTags: [] },
	action
) => {
	if (action.type === actionTypes.LOADING_ENHANCED_SEARCH_PATIENT_TAGS) {
		const newState = Object.assign({}, state);
		newState.advancedSearchPatientTagsLoading = true;
		newState.advancedSearchPatientTagsError = null;
		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_PATIENT_TAGS) {
		const newState = Object.assign({}, state);
		const data = _.get(action, 'payload.data.data', []).map((e) => {
			return { name: e, id: e };
		});
		newState.advancedSearchPatientTagsLoading = false;
		newState.advancedSearchPatientTags = data;
		newState.advancedSearchPatientTagsError = null;

		return newState;
	} else if (action.type === actionTypes.ENHANCED_SEARCH_PATIENT_TAGS_ERROR) {
		const newState = Object.assign({}, state);
		newState.advancedSearchPatientTagsLoading = false;
		newState.advancedSearchPatientTags = [];
		newState.advancedSearchPatientTagsError = action.payload;
		return newState;
	} else {
		return state;
	}
};
