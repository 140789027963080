import axios from 'axios';
import _ from 'lodash';
import get from 'lodash/get';

class DashboardService {
	dashboard = (card_list = []) => {
		const pop_card = '?pop_card=v2';

		const cardlistquery =
			_.isArray(card_list) && _.get(card_list, 'length', 0) > 0
				? '&card_list=' + card_list.join(',')
				: '';
		return axios.get(`/api/dashboard${pop_card}${cardlistquery}`);
	};

	dashboardencounters = (data) => {
		const url = '/api/dashboard/encounters';

		const referralPrograms = get(data, 'referral_program_id', null);
		if (referralPrograms) {
			if (Array.isArray(referralPrograms)) {
				if (get(referralPrograms, 'length', 0) > 0)
					data.partner_id = referralPrograms.join(',');
			} else data.partner_id = referralPrograms;
		}

		const initialCapCompletedDate = get(
			data,
			'initial_cap_completed_date',
			null
		);
		if (initialCapCompletedDate) {
			if (Array.isArray(initialCapCompletedDate)) {
				if (get(initialCapCompletedDate, 'length', 0) > 0)
					data.initial_cap_completed_date =
						initialCapCompletedDate.join(',');
				else delete data.initial_cap_completed_date;
			}
		}

		const providerIndicatedSpecialty = get(
			data,
			'provider_indicated_specialty',
			null
		);
		if (providerIndicatedSpecialty) {
			if (Array.isArray(providerIndicatedSpecialty)) {
				if (get(providerIndicatedSpecialty, 'length', 0) > 0)
					data.provider_indicated_specialty =
						providerIndicatedSpecialty;
				else delete data.provider_indicated_specialty;
			}
		}
		if (get(data, 'followup_cap_completed_date'))
			data.gc_cap_filter = get(data, 'followup_cap_completed_date');

		const assignedCCUuids = get(data, 'assigned_cc_uuid', null);
		if (get(data, 'assigned_cc_uuid')) {
			if (assignedCCUuids === 'unassigned') {
				data.assigned_cc_uuid = null;
			} else if (Array.isArray(assignedCCUuids)) {
				if (get(assignedCCUuids, 'length', 0) > 0)
					data.assigned_cc_uuid = assignedCCUuids
						.map((el) => (el === null ? 'null' : el))
						.join(',');
				else delete data.assigned_cc_uuid;
			}
		}

		const assignedGCAUuids = get(data, 'assigned_gca_uuid', null);
		if (get(data, 'assigned_gca_uuid')) {
			if (assignedGCAUuids === 'unassigned') {
				data.assigned_gca_uuid = null;
			} else if (Array.isArray(assignedGCAUuids)) {
				if (get(assignedGCAUuids, 'length', 0) > 0)
					data.assigned_gca_uuid = assignedGCAUuids
						.map((el) => (el === null ? 'null' : el))
						.join(',');
				else delete data.assigned_gca_uuid;
			}
		}

		const visiting_providers = get(data, 'visiting_providers', null);
		if (get(visiting_providers, 'length', 0) > 0)
			data.visiting_providers = visiting_providers;

		if (data.filter) {
			if (typeof data.filter === 'string' && data.filter.split('=')[1])
				data.flag = data.filter.split('=')[1];
			else data.flag = data.filter;
			delete data.filter;
		}
		if (data.referral_program_id) delete data.referral_program_id;
		data.limit = data.limit || 20;
		if (data.pioneer_status && data.pioneer_status.length > 0) {
			const apiCompatiblePioneerStatus = data.pioneer_status
				.map((status) => `'${status}'`)
				.join(',');
			data.pioneer_status = apiCompatiblePioneerStatus;
		}
		for (const key in data)
			if (
				data[key] === '' ||
				data[key] === null ||
				(Array.isArray(data[key]) && data[key].length === 0)
			)
				delete data[key];
		return axios.post(url, data);
	};

	upcomingappointments = (query) => {
		let params = '?offset=' + _.get(query, 'offset', 0);
		if (_.get(query, ['provider_only']))
			params =
				params + `&provider_only=${_.get(query, ['provider_only'])}`;
		const url = '/api/upcomingappointments' + params;
		return axios.get(url);
	};

	dashboardReferralPrograms = (filter) => {
		return axios.get('/api/dashboardreferralprograms?filter=' + filter);
	};

	exportencounters = (data) => {
		for (const key in data) {
			if (
				key == 'provider_indicated_specialty' &&
				Array.isArray(data[key])
			) {
				continue;
			} else if (
				key == 'visiting_providers' &&
				Array.isArray(data[key])
			) {
				continue;
			} else if (key == 'pioneer_status') {
				if (data[key] && data[key].length > 0) {
					data[key] = data[key]
						.map((status) => `'${status}'`)
						.join(',');
				} else {
					delete data[key];
				}
			} else if (Array.isArray(data[key])) {
				data[key] = data[key]
					.map((el) => (el === null ? 'null' : el))
					.join(',');
			}
		}
		return axios.post('/api/report/dashboard_export_report', data);
	};

	dashboardRoROutreachPendingReport = (data) => {
		const url = '/api/report/ror_outreach_pending';
		return axios.post(url, data);
	};

	getReferralProgramsV2 = (flag) => {
		const url = '/api/dashboard/referral-programs?flag=' + flag;
		return axios.get(url);
	};

	getDashboardRoROutreachPending = (data) => {
		const url = '/api/dashboard/ror-outreach-pending';

		for (const key in data) if (data[key] === '') delete data[key];
		if (data.filter) delete data.filter;
		return axios.post(url, data);
	};

	getActiveCCs = () => {
		return axios.get('/api/enums/carecoordinators');
	};

	getActiveGCAs = () => {
		return axios.get('/api/enums/gca');
	};

	getCCAssignments = (data) => {
		let url = '/api/dashboard/cc-assignments';

		url += '?';

		if (get(data, 'filter')) url += 'filter=' + get(data, 'filter', null);

		if (get(data, 'initial_cap_completed_date'))
			url +=
				'&initial_cap_completed_date=' +
				get(data, 'initial_cap_completed_date');

		if (get(data, 'provider_indicated_specialty'))
			url +=
				'&provider_indicated_specialty=' +
				get(data, 'provider_indicated_specialty');

		if (get(data, 'followup_cap_completed_date'))
			url +=
				'&followup_cap_completed_date=' +
				get(data, 'followup_cap_completed_date');

		if (get(data, 'partner_id'))
			url += '&partner_id=' + get(data, 'partner_id');

		if (get(data, 'referral_program'))
			url += '&referral_program=' + get(data, 'referral_program');

		if (get(data, 'call_attempts'))
			url += '&call_attempts=' + get(data, 'call_attempts');

		return axios.get(url);
	};

	getGCAAssignments = (data) => {
		let url = '/api/dashboard/gca-assignments';

		url += '?';

		if (get(data, 'filter')) url += 'filter=' + get(data, 'filter', null);

		if (get(data, 'initial_cap_completed_date'))
			url +=
				'&initial_cap_completed_date=' +
				get(data, 'initial_cap_completed_date');

		if (get(data, 'provider_indicated_specialty'))
			url +=
				'&provider_indicated_specialty=' +
				get(data, 'provider_indicated_specialty');

		if (get(data, 'followup_cap_completed_date'))
			url +=
				'&followup_cap_completed_date=' +
				get(data, 'followup_cap_completed_date');

		if (get(data, 'partner_id'))
			url += '&partner_id=' + get(data, 'partner_id');

		if (get(data, 'referral_program'))
			url += '&referral_program=' + get(data, 'referral_program');

		if (get(data, 'call_attempts'))
			url += '&call_attempts=' + get(data, 'call_attempts');

		return axios.get(url);
	};

	assignCCOrGCA = (data) => {
		return axios.post('/api/patients/bulk-update', data);
	};
}
export default new DashboardService();
