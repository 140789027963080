import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { setAccessToken } from '../../service/Storage';

import { AUTH0_CONFIG } from './Auth0Wrapper';
import './Auth0Login.scss';

const Auth0Login = function Auth0Login(props) {
	const {
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		loginWithRedirect,
		logout,
	} = useAuth0();
	const [error, setError] = useState(null);

	useEffect(() => {
		const query = new URLSearchParams(window.location.search);
		const errorParam = query.get('error');
		const errorDescription = query.get('error_description');
		if (
			errorParam === 'invalid_request' &&
			errorDescription &&
			errorDescription
				.toLowerCase()
				.includes('client requires organization membership')
		) {
			void handleOrganizationMembershipError();
			return;
		}
		async function handleOrganizationMembershipError() {
			try {
				await logout();
			} catch (_) {}
		}

		if (isLoading) {
			return;
		}

		if (isAuthenticated) {
			async function getAccessToken() {
				try {
					const at = await getAccessTokenSilently({
						authorizationParams: {
							organization: props.organizationId,
							audience: AUTH0_CONFIG.audience,
						},
					});
					await axios.get('/oauth/clear');
					setAccessToken(`Bearer ${at}`);
					window.location = '/ui/signincallback?auth0=true';
				} catch (e) {
					console.error(e);
					if (e.message === 'Unknown or invalid refresh token.') {
						await logout();
						window.location = '/login';
					} else {
						setError(e);
					}
				}
			}
			void getAccessToken();
		} else {
			let email = undefined;
			try {
				const query = new URLSearchParams(window.location.search);
				email = query.get('email') || undefined;
			} catch (_) {}

			void loginWithRedirect({
				authorizationParams: {
					login_hint: email,
					organization: props.organizationId,
					redirect_uri: props.redirectUri || window.location.href,
				},
			});
		}
	}, [isAuthenticated, isLoading]);

	return (
		error && (
			<div className='internal-login'>
				<div className='form-wrapper'>
					<i className='genome-logo'></i>
					<span className='login-title'>Provider Sign-in</span>

					<div className='error-container'>
						<div className='error-message'>
							<svg
								width='30'
								height='20'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34783 20.9464 6.80429 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM13 9.2134L12.5242 14H11.4792L11 9.2134V7H13V9.2134ZM13 18H11V16H13V18Z'
									fill='#400404'
								/>
							</svg>
							<div>
								<p className='title'>
									Something went wrong on our end!
								</p>
								<p>
									Please click the button below to log out,
									then try logging in again.
								</p>
							</div>
						</div>
					</div>

					<button
						className='btn-submit'
						onClick={async () => {
							try {
								await logout();
							} finally {
								window.location.href = '/';
							}
						}}
					>
						Log Out
					</button>
				</div>
			</div>
		)
	);
};

Auth0Login.propTypes = {
	organizationId: PropTypes.string,
	redirectUri: PropTypes.string,
};

export default Auth0Login;
