import classNames from 'classnames';
import { Loading, Dropdown } from 'gm-element-react';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { CARE_APPOINTMENTS_SCHEDULING } from '../../../Utils/permissionUtils';

import DownArrow from '../../Icons/DownArrow';
import ReactSVG from 'react-svg';
import downArrow from '../../../assets/icons/down-arrow.svg';
import RBAC from '../../RBAC';

import Pagination from '../../Common/Pagination';
import CheckboxField from '../../Common/FormFields/CheckboxField';
import CircleBadge from '../../Common/CircleBadge';

import { ReferralPageContext } from '../hooks';

import { AddOutreach } from './AddOutreach';
import Tags from '../../Common/Tags';

import {
	OUTREACH_STATUS,
	OUTREACH_STATUS_OPTIONS,
	OUTREACH_SUBSTATUS_OPTIONS,
} from './filters/Status';

import './Table.scss';

export const ORDER_TYPE = {
	ASC: 'asc',
	DESC: 'desc',
};

export const ORDER_BY = {
	CREATED_AT: 'created_at',
	NAME: 'patient_name',
	LAST_OUTREACH: 'last_outreach',
};

export const RESULTSPERPAGE = [25, 50, 100];

function SortIcon({ order, name }) {
	if (name !== order.order_by) {
		return null;
	}

	return order.order_type === ORDER_TYPE.ASC ? (
		<DownArrow direction='UP' />
	) : (
		<DownArrow />
	);
}

export function Table() {
	const { isExternal } = useCurrentUser();
	const { selectedItems, referralDashboardData } =
		useContext(ReferralPageContext);
	const { isChecked, isSelectAllChecked, reset, selectAll, toggle } =
		selectedItems;
	const { data, paging, loading, setOffset, order, toggleOrder, limit, setLimit } =
		referralDashboardData;

	useEffect(() => {
		reset();
	}, [data]);

	const [selectedOutreachDetails, setSelectedOutreachDetails] =
		useState(null);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};
	
	const handleResultsPerPageChange = (limit) => {
		scrollToTop();
		setLimit(limit);		
	};

	return (
		<div className='main'>
			{loading && <Loading fullscreen />}
			<table className='main-table'>
				<thead>
					<tr>
						{!isExternal && (
							<th>
								<CheckboxField
									onChange={selectAll(
										data?.map(
											(item) => item.patient.uuid
										) ?? []
									)}
									value={isSelectAllChecked}
								/>
							</th>
						)}
						<th onClick={() => toggleOrder(ORDER_BY.CREATED_AT)}>
							<div className='sortable'>
								Referral Date
								<SortIcon
									name={ORDER_BY.CREATED_AT}
									order={order}
								/>
							</div>
						</th>
						<th
							onClick={() =>
								toggleOrder(ORDER_BY.NAME, ORDER_TYPE.ASC)
							}
						>
							<div className='sortable'>
								Patient Name
								<SortIcon name={ORDER_BY.NAME} order={order} />
							</div>
						</th>
						<th>Program</th>
						<th>Status</th>
						<th onClick={() => toggleOrder(ORDER_BY.LAST_OUTREACH)}>
							<div className='sortable'>
								Last Outreach
								<SortIcon
									name={ORDER_BY.LAST_OUTREACH}
									order={order}
								/>
							</div>
						</th>
						<th>Consultation Type</th>
						<th>Specialty</th>
						{!isExternal && <th>Assignee</th>}
					</tr>
				</thead>
				<tbody>
					{data?.map((item) => {
						const { hasDuplicatePatients, hasGuardian } =
							item.patient;
						const combinedTags = [...item.patient.tags];
						hasDuplicatePatients &&
							combinedTags.push({
								display_name: 'Dup. Record',
								name: 'duplicate',
							});
						hasGuardian &&
							combinedTags.push({
								display_name: 'Guardian',
								name: 'guardian',
							});
						return (
							<tr key={item.id}>
								{!isExternal && (
									<td>
										<CheckboxField
											onChange={toggle(item.patient.uuid)}
											value={isChecked(item.patient.uuid)}
										/>
									</td>
								)}
								<td>
									{moment
										.utc(item.createdAt)
										.format('MM/DD/YYYY')}
									<br />
									<span className='sub'>
										{moment
											.utc(item.createdAt)
											.tz(moment.tz.guess())
											.format('h:mm A zz')}
									</span>
								</td>
								<td
									onClick={() => {
										window.open(
											`/app/patientdetail/${item.patient.uuid}/0/0`,
											'_blank'
										);
									}}
									className='actionable'
								>
									<span className='highlight'>
										{item.patient.firstName}{' '}
										{item.patient.lastName}
									</span>
									<Tags
										tags={combinedTags}
										isDisabledTooltip={false}
									/>
									<br />
									<span className='sub'>
										{item.patient.phones?.primary?.value}
									</span>
								</td>
								<td>{item.patient.referralProgram}</td>
								<td>
									{OUTREACH_STATUS_OPTIONS[item.status]}
									{item.status ===
										OUTREACH_STATUS.OUTREACH_COMPLETED &&
										` - ${
											OUTREACH_SUBSTATUS_OPTIONS[
												item.substatus
											]
										}`}
								</td>
								<td
									onClick={() =>
										!isExternal &&
										setSelectedOutreachDetails({
											patient: item.patient.uuid,
											outreachRound: item.id,
											status: item.status,
										})
									}
									className='actionable'
								>
									<span
										className={classNames({
											highlight: !isExternal,
										})}
									>
										{item.lastOutreach?.communication
											?.subtype ??
											item.lastOutreach?.communication
												?.type ??
											'--'}
									</span>
									<br />
									{item.lastOutreach && (
										<span className='sub'>
											{moment
												.utc(
													item.lastOutreach
														.communication.dateTime
												)
												.tz(moment.tz.guess())
												.format(
													'MMM D, YYYY, h:mm A zz'
												)}
										</span>
									)}
								</td>
								<td>{item.consultation ?? '--'}</td>
								<td>{item.specialty ?? '--'}</td>
								{!isExternal && (
									<td>
										<CircleBadge
											displayName={
												item.patient.assignedCc || '--'
											}
											isActive={false}
										/>
									</td>
								)}
								<RBAC
									action={CARE_APPOINTMENTS_SCHEDULING}
									yes={
										<td>
											<Link
												to={{
													pathname:
														'/app/appointments/schedule',
													state: {
														breadcrumbs: [
															{
																location:
																	'Referrals',
																url: '/app/referraldashboard',
															},
															{
																location:
																	'Schedule Appointment',
																url: '/app/appointments/schedule',
																patientData: {
																	firstName:
																		item
																			.patient
																			.firstName,
																	lastName:
																		item
																			.patient
																			.lastName,
																	uuid: item
																		.patient
																		.uuid,
																},
															},
														],
													},
												}}
											>
												Schedule
											</Link>
										</td>
									}
								/>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className='tfoot-wrapper'>
				<div className='results-per-page'>
					<span className='label-results-per-page'>Results per page:</span>
					<Dropdown
						trigger='click'
						menu={
							<Dropdown.Menu>
								{RESULTSPERPAGE.map((num, i) => (
									<Dropdown.Item
										key={i}
										command={num.toString()}
									>
										{num}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						}
						onCommand={handleResultsPerPageChange}
					>
						<span className='limit-number-wrapper'>
							<span>{limit}</span>
							<ReactSVG src={downArrow}/>
						</span>
					</Dropdown>
				</div>
				<div className='pagination'>
					<Pagination
						pageSize={paging?.limit}
						total={paging?.total}
						offset={paging?.offset}
						layout='prev, pager, next'
						onCurrentChange={(_, offset) => {
							setOffset(offset);
						}}
					/>
				</div>
			</div>
			<AddOutreach
				patientUuid={selectedOutreachDetails?.patient}
				outreachRoundId={selectedOutreachDetails?.outreachRound}
				outreachStatus={selectedOutreachDetails?.status}
				close={() => setSelectedOutreachDetails(null)}
			/>
		</div>
	);
}
