//React & Redux
import React from 'react';

//Other Libraries
import moment from 'moment';

const PatientDemographicTimelineTab = (props) => {
	const { patient } = props;

	const processTimestamp = (time) => {
		if (!time) return '--';

		const dateObj = moment(time, 'YYYY-MM-DD hh:mm:ss').toDate();
		const newDateObj = new Date(
			dateObj.getTime() - dateObj.getTimezoneOffset() * 60 * 1000
		);
		return moment(newDateObj).format('MMM DD, YYYY hh:mm A');
	};

	return (
		<div className={'demographicItem fullWidth topMargin'}>
			<div className='demographicItemHeader'>Modified</div>
			<div
				className='demographicItemData'
				style={{ marginBottom: '16px' }}
			>
				{processTimestamp(patient.modified_at)}
			</div>
			<div className='demographicItemHeader'>Created</div>
			<div className='demographicItemData'>
				{processTimestamp(patient.created_at)}
			</div>
		</div>
	);
};

export default PatientDemographicTimelineTab;
