//React & Redux
import React from 'react';

//Images

//Other Libraries
import classnames from 'classnames';

import { Dialog, Button, Layout, Popover } from 'gm-element-react';
import { orderBy } from 'lodash';
import moment from 'moment';

//Images
import ReactSVG from 'react-svg';
import editIcon from '../../../../assets/edit.svg';
import moreinfoicon from './../../../../assets/purple-more-info.svg';

//Styles
import './css/view-nucleotide-variant-full-detail.css';

//Utils
import { CARE_PATIENTS_ENTER_TEST_RESULT } from '../../../../Utils/permissionUtils';
import {
	genomicSourceClassEnum,
	labClassificationNucleotideVariantEnum,
} from '../../../../utils';

//Components
import RBAC from '../../../RBAC';
import Icon from '../../../Common/Icon';
import { Fragment } from 'react';

const ViewNucleotideRepeatVariantFullDetail = (props) => {
	const {
		showAddEditNucleotideRepeatVariantModalFunc,
		hideViewNucleotideRepeatVariantFullDetailFunc,
		nucleotideRepeatVariantFindings,
	} = props;

	const closeViewNucleotideRepeatVariantFullDetail = () => {
		hideViewNucleotideRepeatVariantFullDetailFunc();
	};

	const showEditNucleotideRepeatVariantModalFunc = () => {
		closeViewNucleotideRepeatVariantFullDetail();
		showAddEditNucleotideRepeatVariantModalFunc(
			'edit',
			nucleotideRepeatVariantFindings
		);
	};

	const getSortedInterpretations = (interpretations) => {
		if (Array.isArray(interpretations)) {
			return orderBy(
				interpretations || [],
				function (p) {
					return new moment(p.interpretedOn);
				},
				['desc']
			);
		}
		return [];
	};

	const getInterpretationAlleleText = (interpretationsAllele) => {
		let interpretationText = '--';
		let classification = '--';
		let interpretedOn = null;
		let name = '';
		const sortedInterpretations = getSortedInterpretations(interpretationsAllele);

		sortedInterpretations.map((interpretations, idx) => {
			classification = labClassificationNucleotideVariantEnum.find(
				(o) => o.key === interpretations.classification
			);
			classification = classification?.display_name;
			interpretedOn = moment(
				interpretations.interpretedOn || null
			).isValid()
				? moment(interpretations.interpretedOn).format('MM.DD.YYYY')
				: '';
			name = interpretations.source?.name;

			interpretationText =
				classification + ' | ' + interpretedOn + ' | ' + name;
			return interpretationText;
		});
		return interpretationText;
	};

	const getGenomicSourceClass = () => {
		const genomicSourceClass = genomicSourceClassEnum.find(
			(o) => o.key === nucleotideRepeatVariantFindings?.genomicSourceClass
		);
		return genomicSourceClass?.display_name || '--';
	};

	return (
		<Dialog
			customClass='view-nucleotide-variant-full-detail-dialog'
			title={
				<span className='header'>
					<span>
						Nucleotide Repeat Variant Finding -{' '}
						{nucleotideRepeatVariantFindings?.genesSymbol?.symbol ||
							'--'}
					</span>
					<Popover
						trigger='hover'
						popperClass={classnames('pp-nucleotide-variant')}
						content={
							<Fragment>
								<span style={{ display: 'block' }}>
									Name:{' '}
									{nucleotideRepeatVariantFindings
										?.genesSymbol?.name || '--'}
								</span>
								<span style={{ display: 'block' }}>
									Aliases:{' '}
									{(
										nucleotideRepeatVariantFindings
											?.genesSymbol?.aliasSymbols || []
									).join(', ')}
								</span>
								<span style={{ display: 'block' }}>
									Cytogenetic location:{' '}
									{nucleotideRepeatVariantFindings
										?.genesSymbol?.cytogeneticLocation ||
										'--'}
								</span>
							</Fragment>
						}
						key='popover-discrete-variant'
						placement='top'
					>
						<span className='tooltip-info'>
							<img
								src={moreinfoicon}
								className='more-info-icon'
							/>
						</span>
					</Popover>
					<span
						className='close-btn-icon'
						onClick={closeViewNucleotideRepeatVariantFullDetail}
					>
						<Icon icon='close' className='close-icon' />
					</span>
				</span>
			}
			size='tiny'
			modal={true}
			closeOnPressEscape={true}
			closeOnClickModal={false}
			showClose={false}
			visible={true}
			onCancel={closeViewNucleotideRepeatVariantFullDetail}
		>
			<Dialog.Body>
				<Layout.Row gutter='26'>
					<Layout.Col span='8'>
						<span className='field-label'>Repeat-First Allele</span>
						<span className='field-value'>
							{nucleotideRepeatVariantFindings?.allele1?.repeats
								?.value || '--'}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='field-label'>
							Interpretation-First Allele
						</span>
						<span className='field-value'>
							{getInterpretationAlleleText(
								nucleotideRepeatVariantFindings?.allele1?.interpretations || []
							)}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='field-label'>
							Repeat-Second Allele
						</span>
						<span className='field-value'>
							{nucleotideRepeatVariantFindings?.allele2?.repeats
								?.value || '--'}
						</span>
					</Layout.Col>
				</Layout.Row>

				<Layout.Row gutter='26'>
					<Layout.Col span='8'>
						<span className='field-label'>
							Interpretation-Second Allele
						</span>
						<span className='field-value'>
							{getInterpretationAlleleText(
								nucleotideRepeatVariantFindings?.allele2?.interpretations || []
							)}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='field-label'>Reference Sequence</span>
						<span className='field-value'>
							{nucleotideRepeatVariantFindings?.referenceSequence ||
								'--'}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='field-label'>Reference Assembly</span>
						<span className='field-value'>
							{nucleotideRepeatVariantFindings?.referenceAssembly
								?.name || '--'}
						</span>
					</Layout.Col>
				</Layout.Row>

				<Layout.Row gutter='26' className='divide-section'>
					<Layout.Col span='8'>
						<span className='field-label'>
							Genomic Source Class
						</span>
						<span className='field-value'>
							{getGenomicSourceClass()}
						</span>
					</Layout.Col>
				</Layout.Row>
			</Dialog.Body>

			<Dialog.Footer className='dialog-footer'>
				<RBAC
					action={CARE_PATIENTS_ENTER_TEST_RESULT}
					yes={
						<Button
							className='edit-detail-button'
							onClick={showEditNucleotideRepeatVariantModalFunc}
						>
							<ReactSVG
								src={editIcon}
								className='edit-details-icon'
							/>
							<span>Edit Original</span>
						</Button>
					}
				/>
			</Dialog.Footer>
		</Dialog>
	);
};

export default ViewNucleotideRepeatVariantFullDetail;
