//React & Redux
import React, { Component } from 'react';

//Images
import ReactSVG from 'react-svg';
import resetIcon from '../../assets/ic_reset_filters.svg';

//Components
import Tooltip from '../Common/Tooltip';

class ResetFilters extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.handleClick = this.handleClick.bind(this);
	}
	//functions

	handleClick() {
		this.props.handleResetFilters();
		setTimeout(() => {
			document.querySelector('body').click();
		}, 0);
	}

	render() {
		return (
			<Tooltip content='Reset filters and sorting' placement='bottom'>
				<div
					data-testId='reset-filter'
					className='reset-filter-container'
					onClick={this.handleClick}
				>
					<ReactSVG src={resetIcon} className='reset-filter-icon' />
				</div>
			</Tooltip>
		);
	}
}

export default ResetFilters;
