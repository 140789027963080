//React & Redux
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

//Images
import ReactSVG from 'react-svg';
import breadcrumbMenu from './../../assets/menu-breadcrumb.svg';

class ScheduleWizardSteps extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<Fragment>
				<div
					onClick={() => {}}
					className={
						this.props.activeItem === 0
							? 'scheduleAppointmentNavHeaderSelected'
							: 'scheduleAppointmentNavHeader'
					}
					style={{ width: '134px' }}
				>
					Select Patient
				</div>
				<ReactSVG
					src={breadcrumbMenu}
					className='scheduleAppointmentNavArrow'
				/>
				<div
					onClick={() => {}}
					className={
						this.props.activeItem === 1
							? 'scheduleAppointmentNavHeaderSelected'
							: 'scheduleAppointmentNavHeader'
					}
					style={{ width: '89px' }}
				>
					Schedule
				</div>
				{this.props.isZeroPriceConsultation() === false && (
					<Fragment>
						<ReactSVG
							src={breadcrumbMenu}
							className='scheduleAppointmentNavArrow'
						/>
						<div
							onClick={() => {}}
							className={
								this.props.activeItem === 2
									? 'scheduleAppointmentNavHeaderSelected'
									: 'scheduleAppointmentNavHeader'
							}
							style={{ width: '88px' }}
						>
							Payment
						</div>
					</Fragment>
				)}
			</Fragment>
		);
	}
}

ScheduleWizardSteps.propTypes = {
	wizardSteps: PropTypes.array.isRequired,
	activeItem: PropTypes.object,
	goToStep: PropTypes.func.isRequired,
};

export default ScheduleWizardSteps;
