import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { prepareDate, getEndOfWeek } from '../Utils/CalendarUtils';

class SchedulingService {
	getSlots = (data) => {
		const {
			start_time,
			end_time,
			timezone,
			country = 'US',
			state,
			provider_id,
			consultation_id,
			patient_id,
			vsee_specialty,
			appointment_id,
		} = data;
		const params = {
			start_time: prepareDate(start_time),
			end_time: end_time
				? prepareDate(end_time)
				: prepareDate(getEndOfWeek(start_time)),
			timezone,
			consultation_id: consultation_id,
			provider_id: provider_id,
			patient_id: patient_id,
			country,
			state,
			vsee_specialty: vsee_specialty,
			appointment_id: appointment_id,
		};

		return axios
			.get('/api/encounters/visits/availability', { params })
			.then((r) =>
				this.getTimezoneNormalizedSlots(
					_.get(r, ['data', 'data'], []),
					timezone,
					provider_id,
					country,
					state,
					consultation_id,
					vsee_specialty
				)
			);
	};
	getTimezoneNormalizedSlots(
		availabilityResponse,
		timezone,
		provider_id,
		country,
		state,
		consultation_id,
		vsee_specialty
	) {
		const r = _.cloneDeep(availabilityResponse);
		['available_slots', 'reservations'].forEach((slotsField) => {
			if (r.available_slots) {
				r[slotsField] = r[slotsField].map((slot) => {
					//Assuming service duration is same for all the slot
					let serviceDuration = slot.duration;
					if (!serviceDuration)
						serviceDuration = moment(slot.end).diff(
							moment(slot.start),
							'minutes'
						);
					return {
						...slot,
						provider_id: slot.provider_id || provider_id,
						country,
						state,
						consultation_id,
						timezone,
						start: moment(slot.start).tz(timezone).format(),
						end: moment(slot.end).tz(timezone).format(),
						vsee_specialty: slot.vsee_specialty || vsee_specialty,
						duration: serviceDuration,
					};
				});
			}
		});
		return r;
	}

	getAvailabilityDates = ({
		consultation_id,
		month,
		partner_id,
		patient_id,
		provider_id,
		country,
		state,
		timezone,
		vsee_specialty,
	}) => {
		return axios.get('/api/encounters/visits/availability/dates', {
			params: {
				month: moment(month).format('YYYY-MM'),
				timezone,
				consultation_id,
				partner_id,
				patient_id,
				provider_id,
				country,
				state,
				vsee_specialty,
			},
		});
	};

	getAvailabilityTimes = ({
		consultation_id,
		date,
		partner_id,
		patient_id,
		provider_id,
		country,
		state,
		timezone,
		vsee_specialty,
	}) => {
		return axios.get('/api/encounters/visits/availability/times', {
			params: {
				date: moment(date).format('YYYY-MM-DD'),
				timezone,
				consultation_id,
				partner_id,
				patient_id,
				provider_id,
				state,
				country,
				vsee_specialty,
			},
		});
	};

	applyCouponCode = (patient_uuid, data) => {
		const body = data;

		return axios.post(
			`/api/coupon-codes/info?patient_uuid=${patient_uuid}`,
			body
		);
	};

	rescheduleAppointment = (encounter_uuid, data) => {
		return axios.post(`/api/encounters/visits/${encounter_uuid}`, data);
	};
}

export default new SchedulingService();
