//React & Redux
import React, { useEffect, useRef, useState } from 'react';

//Lodash
import { isEmpty, isNil, isNull, omitBy, toNumber } from 'lodash';

//UI Libraries
import {
	Dialog,
	Form,
	Loading,
	Input,
	DatePicker,
	Select,
} from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';
import moment from 'moment';

//Styles
import './insurance-auth-form.css';

const unitEnums = [
	{ key: 'Visit', value: 'Visit' },
	{ key: 'Unit', value: 'Unit' },
];

const rules = {
	athena_insurance_package_name: [
		{
			required: false,
			message: 'Please enter package name',
			trigger: 'blur',
		},
	],
	insurance_authorization_number: [
		{
			required: true,
			message: 'Please enter authorization number',
			trigger: 'blur',
		},
	],
	athena_referring_provider_id: [
		{
			required: true,
			message: 'Please enter athena referring provider ID',
			trigger: 'blur',
		},
	],
};

const intMaxLength = 2147483647;

const InsuranceAuthForm = (props) => {
	const {
		actionType,
		authData = {},
		enqueueSnackbar,
		insuranceAuthFormActions: { getInsuranceAuths, saveInsuranceAuth },
		patient,
		saveInsuranceAuthResponse,
		setAction,
		title,
		patientInsuranceInfo,
	} = props;

	const [savingData, setSavingData] = useState(false);
	const [serverErrors, setServerErrors] = useState([]);
	const [form, setForm] = useState({
		athena_insurance_package_name: !isEmpty(authData)
			? authData.athena_insurance_package_name || null
			: patient.athena_insurance_package_name || null,
		athena_insurance_package_id: !isEmpty(authData)
			? authData.athena_insurance_package_id || null
			: patient.athena_insurance_package_id || null,
		insurance_authorization_id: authData.id || null,
		icd_10_code: authData.icd_10_code || null,
		referring_provider_name: authData.referring_provider_name || null,
		visit_or_unit: authData.visit_or_unit || null,
		icd_9_code: authData.icd_9_code || null,
		referring_provider_npi: authData.referring_provider_npi || null,
		number_of_visits_approved: authData.number_of_visits_approved || null,
		insurance_authorization_number:
			authData.insurance_authorization_number || null,
		procedure_code: authData.procedure_code || null,
		athena_referring_provider_id: !isNil(
			authData.athena_referring_provider_id || null
		)
			? (authData.athena_referring_provider_id || null).toString()
			: null,
		number_of_visits_used: authData.number_of_visits_used || null,
		authorization_start_date: !isEmpty(
			authData.authorization_start_date || null
		)
			? moment(authData.authorization_start_date).toDate()
			: null,
		authorization_end_date: !isEmpty(
			authData.authorization_end_date || null
		)
			? moment(authData.authorization_end_date).toDate()
			: null,
		number_of_visits_left: authData.number_of_visits_left || null,
		athena_authorization_id: authData.athena_authorization_id || null,
	});

	const formRef = useRef(null);

	useEffect(() => {
		const primaryInsurance =
			(!isEmpty(patientInsuranceInfo) &&
				!isNil(patientInsuranceInfo) &&
				(patientInsuranceInfo || []).filter(
					(el) =>
						el.insurance_type === 'primary' &&
						el.visit_insurance === true &&
						el.lab_insurance === false
				)?.[0]) ||
			{};

		if (!isEmpty(primaryInsurance) && !isNil(primaryInsurance)) {
			setForm((form) => ({
				...form,
				['policyholder_insurance_id']:
					primaryInsurance.insurance_id || '',
			}));
		}
	}, []);

	useEffect(() => {
		if (savingData) {
			if (saveInsuranceAuthResponse.success) {
				if (form?.policyholder_insurance_id) {
					getInsuranceAuths({
						policyholder_insurance_id:
							form.policyholder_insurance_id,
					});
				}
				setServerErrors([]);
				setAction(actionType.view);
				sendNotification(
					'Insurance authorization saved successfully',
					'success'
				);
			} else {
				setServerErrors(
					saveInsuranceAuthResponse.payload?.fields || []
				);
				sendNotification(
					saveInsuranceAuthResponse.payload?.message ||
						'Invalid request.',
					'error'
				);
			}
			setSavingData(false);
		}
	}, [saveInsuranceAuthResponse.payload]);

	const handleSubmit = (e) => {
		e.preventDefault();
		formRef.current.validate((valid) => {
			if (valid) {
				setSavingData(true);
				const newForm = omitBy({ ...form }, (value) => isNil(value));
				newForm.authorization_start_date =
					newForm.authorization_start_date &&
					moment(newForm.authorization_start_date).isValid()
						? moment(newForm.authorization_start_date).format(
								'YYYY-MM-DD'
						  )
						: null;
				newForm.authorization_end_date =
					newForm.authorization_end_date &&
					moment(newForm.authorization_end_date).isValid()
						? moment(newForm.authorization_end_date).format(
								'YYYY-MM-DD'
						  )
						: null;
				newForm.athena_referring_provider_id =
					newForm.athena_referring_provider_id &&
					toNumber(newForm.athena_referring_provider_id);
				newForm.referring_provider_npi =
					newForm.referring_provider_npi &&
					toNumber(newForm.referring_provider_npi);
				saveInsuranceAuth(newForm);
			} else {
			}
		});
	};

	const sendNotification = (message, type) => {
		enqueueSnackbar(message, {
			variant: type,
			className: 'export_notification',
			anchorOrigin: { horizontal: 'center', vertical: 'top' },
		});
	};

	const handleReset = (e) => {
		e.preventDefault();
		formRef.current.resetFields();
		setAction(actionType.view);
	};

	const onChange = (key, value) => {
		const updateForm = (k, v) => {
			setForm((form) => ({
				...form,
				[k]: v,
			}));
		};

		switch (key) {
			case 'authorization_start_date':
			case 'authorization_end_date':
				updateForm(
					key,
					value instanceof moment
						? moment(value)
						: isNull(value)
						? ''
						: value
				);
				break;
			case 'number_of_visits_approved':
			case 'number_of_visits_left':
			case 'number_of_visits_used':
				updateForm(key, value > intMaxLength ? intMaxLength : value);
				break;
			default:
				updateForm(key, value);
				break;
		}
	};

	const onNumberChange = (key, value) => {
		if (value != '' && !Number(value)) return;
		onChange(key, value);
	};

	const getServerError = (field) => {
		const errObj = serverErrors[field] || {};
		const unknownKey = Object.keys(errObj)[0] || '';
		if (
			field === 'authorization_start_date' ||
			field === 'authorization_end_date' ||
			field === 'referring_provider_npi'
		) {
			return errObj[unknownKey] || '' ? (
				<label className='label-server-error'>
					{errObj[unknownKey]}
				</label>
			) : (
				''
			);
		} else {
			return !isEmpty(form[field] || '') && (errObj[unknownKey] || '') ? (
				<label className='label-server-error'>
					{errObj[unknownKey]}
				</label>
			) : (
				''
			);
		}
	};

	const textClass = 'erFieldText erFieldTextWider';

	return (
		<Dialog
			className='insurance-auth-info'
			onCancel={() => {}}
			title={<div className='insurance-auth-header'>{title}</div>}
			size='large'
			visible={true}
			showClose={false}
			lockScroll={true}
		>
			<Dialog.Body>
				<Loading
					loading={savingData}
					className='loader-auth-insurance-form'
				>
					<Form
						ref={formRef}
						className='insurance-auth-form'
						model={form}
						rules={rules}
						onSubmit={(e) => e.preventDefault()}
					>
						<div className='insurance-auth-part1'>
							<Form.Item
								label='Athena insurance package ID'
								prop='athena_insurance_package_id'
							>
								<Input
									value={form.athena_insurance_package_id}
									placeholder='1234567890'
									trim={true}
									disabled={true}
									onChange={(val) =>
										onChange(
											'athena_insurance_package_id',
											val
										)
									}
									className={textClass + ' authpkg-id'}
								/>
								{getServerError('athena_insurance_package_id')}
							</Form.Item>
							<Form.Item label='ICD-10 code' prop='icd_10_code'>
								<Input
									value={form.icd_10_code}
									placeholder='1234567890'
									trim={true}
									onChange={(val) =>
										onChange('icd_10_code', val)
									}
									className={textClass}
								/>
								{getServerError('icd_10_code')}
							</Form.Item>
							<Form.Item
								label='Referring provider'
								prop='referring_provider_name'
							>
								<Input
									value={form.referring_provider_name}
									placeholder='John Smith'
									trim={true}
									onChange={(val) =>
										onChange('referring_provider_name', val)
									}
									className={textClass}
								/>
								{getServerError('referring_provider_name')}
							</Form.Item>
							<Form.Item
								label='Visit or unit?'
								prop='visit_or_unit'
							>
								<Select
									value={form.visit_or_unit}
									placeholder='Visit or unit'
									onChange={(val) =>
										onChange('visit_or_unit', val)
									}
									className='inputFieldDropdown'
								>
									{unitEnums &&
										unitEnums.map((el) => (
											<Select.Option
												key={'visit_or_unit_' + el.key}
												label={el.value}
												value={el.key}
											/>
										))}
								</Select>
								{getServerError('visit_or_unit')}
							</Form.Item>
						</div>
						<div className='insurance-auth-part2'>
							<Form.Item
								label='Athena insurance package name'
								prop='athena_insurance_package_name'
							>
								<Input
									value={form.athena_insurance_package_name}
									placeholder='1234567890'
									trim={true}
									disabled={true}
									onChange={(val) =>
										onChange(
											'athena_insurance_package_name',
											val
										)
									}
									className={textClass + ' authpkg-id'}
								/>
								{getServerError(
									'athena_insurance_package_name'
								)}
							</Form.Item>
							<Form.Item label='ICD-9 code' prop='icd_9_code'>
								<Input
									value={form.icd_9_code}
									placeholder='1234567890'
									trim={true}
									onChange={(val) =>
										onChange('icd_9_code', val)
									}
									className={textClass}
								/>
								{getServerError('icd_9_code')}
							</Form.Item>
							<Form.Item
								label='Referring provider NPI'
								prop='referring_provider_npi'
							>
								<Input
									value={form.referring_provider_npi}
									placeholder='1234567890'
									trim={true}
									onChange={(val) =>
										onNumberChange(
											'referring_provider_npi',
											val
										)
									}
									className={textClass}
								/>
								{getServerError('referring_provider_npi')}
							</Form.Item>
							<Form.Item
								label='Number of visits approved'
								prop='number_of_visits_approved'
							>
								<Input
									value={form.number_of_visits_approved}
									placeholder='10'
									trim={true}
									onChange={(val) =>
										onNumberChange(
											'number_of_visits_approved',
											val
										)
									}
									className={textClass}
								/>
								{getServerError('number_of_visits_approved')}
							</Form.Item>
						</div>
						<div className='insurance-auth-part3'>
							<Form.Item
								label='Insurance authorization number'
								prop='insurance_authorization_number'
							>
								<Input
									value={form.insurance_authorization_number}
									placeholder='1234567890'
									trim={true}
									onChange={(val) =>
										onChange(
											'insurance_authorization_number',
											val
										)
									}
									className={textClass + ' authpkg-id'}
								/>
								{getServerError(
									'insurance_authorization_number'
								)}
							</Form.Item>

							<Form.Item
								label='Procedure code'
								prop='procedure_code'
							>
								<Input
									value={form.procedure_code}
									placeholder='1234567890'
									trim={true}
									onChange={(val) =>
										onChange('procedure_code', val)
									}
									className={textClass}
								/>
								{getServerError('procedure_code')}
							</Form.Item>

							<Form.Item
								label='Athena referring provider ID'
								prop='athena_referring_provider_id'
							>
								<Input
									value={form.athena_referring_provider_id}
									placeholder='1234567890'
									trim={true}
									onChange={(val) =>
										onNumberChange(
											'athena_referring_provider_id',
											val
										)
									}
									className={textClass}
								/>
								{getServerError('athena_referring_provider_id')}
							</Form.Item>

							<Form.Item
								label='Number of visits used'
								prop='number_of_visits_used'
							>
								<Input
									value={form.number_of_visits_used}
									placeholder='1'
									trim={true}
									onChange={(val) =>
										onNumberChange(
											'number_of_visits_used',
											val
										)
									}
									className={textClass}
								/>
								{getServerError('number_of_visits_used')}
							</Form.Item>
						</div>
						<div className='datepicker-container'>
							<div className='datepicker-section'>
								<div className='auth-datepicker'>
									<Form.Item
										label='Authorization date'
										prop='authorization_start_date'
									>
										<DatePicker
											value={
												form.authorization_start_date
											}
											placeholder='Jan 1, 2019'
											trim={true}
											onChange={(val) =>
												onChange(
													'authorization_start_date',
													val
												)
											}
										/>
										{getServerError(
											'authorization_start_date'
										)}
									</Form.Item>
								</div>
								<div className='auth-date-dash'>-</div>
								<div className='auth-datepicker'>
									<label className='el-form-item__label'></label>
									<Form.Item prop='authorization_end_date'>
										<DatePicker
											value={form.authorization_end_date}
											placeholder='Mar 31, 2019'
											trim={true}
											onChange={(val) =>
												onChange(
													'authorization_end_date',
													val
												)
											}
										/>
										{getServerError(
											'authorization_end_date'
										)}
									</Form.Item>
								</div>
							</div>
							<div className='visit-section'>
								<Form.Item
									label='Number of visits left'
									prop='number_of_visits_left'
								>
									<Input
										value={form.number_of_visits_left}
										placeholder='9'
										trim={true}
										onChange={(val) =>
											onNumberChange(
												'number_of_visits_left',
												val
											)
										}
										className={textClass}
									/>
									{getServerError('number_of_visits_left')}
								</Form.Item>
							</div>
						</div>
						<div className='authid-athena'>
							<Form.Item
								label='Athena authorization ID'
								prop='athena_authorization_id'
							>
								<Input
									value={form.athena_authorization_id}
									placeholder='1234567890'
									trim={true}
									disabled={true}
									onChange={(val) =>
										onChange('athena_authorization_id', val)
									}
									className={textClass}
								/>
								{getServerError('athena_authorization_id')}
							</Form.Item>
						</div>
						<div className='ins-auth-action-buttons'>
							<div
								data-testId='cancel-btn'
								className='cancel-button'
								onClick={handleReset}
							>
								<span className='text'>Cancel</span>
							</div>
							<div
								data-testId='submit-btn'
								className={classnames(
									'save-button',
									!isEmpty(authData) ? 'small-save' : ''
								)}
								onClick={handleSubmit}
							>
								<span className='text'>
									{!isEmpty(authData) ? 'Save' : 'Create'}
								</span>
							</div>
						</div>
					</Form>
				</Loading>
			</Dialog.Body>
		</Dialog>
	);
};

export default InsuranceAuthForm;
