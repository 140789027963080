import * as actionTypes from '../actions/actionTypes';

const defaultTestsState = {
	gettingTests: false,
	tests: [],
	testsError: null,
};

const defaultGeneticTests = {
	gettingGeneticTests: false,
	geneticTests: null,
	geneticTestsError: null,
};

const defaultGetTestDetail = {
	gettingTestDetail: false,
	testDetail: {},
	testDetailError: null,
};

const defaultTestAuthEnums = {
	testAuthEnumsLoading: false,
	testAuthEnums: {},
	testAuthEnumsError: null,
};

export const tests = (state = defaultTestsState, action) => {
	if (action.type === actionTypes.GETTING_TESTS) {
		const newState = {
			...state,
		};
		newState.gettingTests = true;
		newState.tests = null;
		newState.testsError = null;
		return newState;
	} else if (action.type === actionTypes.TESTS) {
		const newState = {
			...state,
		};
		newState.gettingTests = false;
		newState.tests = action.payload;
		newState.testsError = null;
		return newState;
	} else if (action.type === actionTypes.GET_TESTS_ERROR) {
		const newState = {
			...state,
		};
		newState.gettingTests = false;
		newState.tests = null;
		newState.testsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getTestDetail = (state = defaultGetTestDetail, action) => {
	if (action.type === actionTypes.TEST_DETAIL) {
		const newState = Object.assign({}, state);
		newState.gettingTestDetail = false;
		newState.testDetail = action.payload;
		return newState;
	} else if (action.type === actionTypes.GETTING_TEST_DETAIL) {
		const newState = Object.assign({}, state);
		newState.gettingTestDetail = true;
		newState.testDetailError = null;
		return newState;
	} else if (action.type === actionTypes.TEST_DETAIL_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingTestDetail = false;
		newState.testDetailError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const testUpdateCreate = (state = { updatingTest: false }, action) => {
	if (action.type === actionTypes.UPDATING_TEST) {
		const newState = Object.assign({}, state);
		newState.updatingTest = true;
		return newState;
	} else if (action.type === actionTypes.UPDATED_TEST) {
		const newState = Object.assign({}, state);
		newState.updatingTest = false;
		return newState;
	} else if (action.type === actionTypes.CREATING_TEST) {
		const newState = Object.assign({}, state);
		newState.creatingTest = true;
		newState.createdTest = null;
		newState.creatingTestError = null;
		return newState;
	} else if (action.type === actionTypes.CREATED_TEST) {
		const newState = Object.assign({}, state);
		newState.creatingTest = false;
		newState.createdTest = action.payload;
		newState.creatingTestError = null;
		return newState;
	} else if (action.type === actionTypes.CREATED_TEST_ERROR) {
		const newState = Object.assign({}, state);
		newState.creatingTest = false;
		newState.createdTest = null;
		newState.creatingTestError = action.payload;
		return newState;
	} else if (action.type === actionTypes.TEST_RESETTING) {
		const newState = Object.assign({}, state);
		newState.restingTest = true;
		newState.testResetError = null;
		return newState;
	} else if (action.type === actionTypes.TEST_REST_DONE) {
		const newState = Object.assign({}, state);
		newState.resetDone = false;
		return newState;
	} else if (action.type === actionTypes.TEST_REST_ERROR) {
		const newState = Object.assign({}, state);
		newState.resetDone = false;
		newState.testResetError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const testEnums = (state = {}, action) => {
	if (action.type === actionTypes.GETTING_TEST_TYPES) {
		const newState = Object.assign({}, state);
		newState.gettingTestTypes = true;
		newState.testTypes = null;
		newState.testTypesError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_TEST_TYPES) {
		const newState = Object.assign({}, state);
		newState.testTypes = action.payload;
		newState.gettingTestTypes = false;
		newState.testTypesError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_TEST_TYPES_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingTestTypes = false;
		newState.testTypes = null;
		newState.testTypesError = action.payload;
		return newState;
	} else if (action.type === actionTypes.GETTING_TEST_TAGS) {
		const newState = Object.assign({}, state);
		newState.gettingTestTags = true;
		newState.testTags = null;
		newState.testTagsError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_TEST_TAGS) {
		const newState = Object.assign({}, state);
		newState.testTags = action.payload;
		newState.gettingTestTags = false;
		newState.testTagsError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_TEST_TAGS_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingTestTags = false;
		newState.testTags = null;
		newState.testTagsError = action.payload;
		return newState;
	} else if (action.type === actionTypes.GETTING_OTHER_ENUMS) {
		const newState = Object.assign({}, state);
		newState.gettingOtherEnums = true;
		newState.otherEnums = null;
		newState.otherEnumsError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_OTHER_ENUMS) {
		const newState = Object.assign({}, state);
		newState.otherEnums = action.payload;
		newState.gettingOtherEnums = false;
		newState.otherEnumsError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_OTHER_ENUMS_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingOtherEnums = false;
		newState.otherEnums = null;
		newState.otherEnumsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const geneticTests = (state = defaultGeneticTests, action) => {
	if (action.type === actionTypes.GETTING_GENETIC_TESTS) {
		const newState = Object.assign({}, state);
		newState.gettingGeneticTests = action.payload;
		newState.geneticTests = null;
		newState.geneticTestsError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_GENETIC_TESTS) {
		const newState = Object.assign({}, state);
		newState.gettingGeneticTests = false;
		newState.geneticTests = action.payload;
		newState.geneticTestsError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_GENETIC_TESTS_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingGeneticTests = false;
		newState.geneticTests = null;
		newState.geneticTestsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const testAuthEnums = (state = defaultTestAuthEnums, action) => {
	if (action.type === actionTypes.GET_TEST_AUTH_ENUMS_STARTED) {
		const newState = Object.assign({}, state);
		newState.testAuthEnumsLoading = true;
		newState.testAuthEnumsError = null;
		return newState;
	} else if (action.type === actionTypes.GET_TEST_AUTH_ENUMS_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.testAuthEnums = action.payload;
		newState.testAuthEnumsLoading = false;
		newState.testAuthEnumsError = null;
		return newState;
	} else if (action.type === actionTypes.GET_TEST_AUTH_ENUMS_FAILED) {
		const newState = Object.assign({}, state);
		newState.testAuthEnums = [];
		newState.testAuthEnumsLoading = false;
		newState.testAuthEnumsError = action.payload;
		return newState;
	} else {
		return state;
	}
};
