import * as actionTypes from '../actionTypes';
import GenesService from '../../service/Genes';
import get from 'lodash/get';

//dispatchers
export const gettingGeneResultsEnums = (data) => {
	return {
		type: actionTypes.GETTING_GENE_RESULTS_ENUM,
		payload: data,
	};
};

const loadingGeneResultsEnums = () => {
	return {
		type: actionTypes.LOADING_GENE_RESULTS_ENUM,
	};
};

const geneResultsEnumsError = (err) => {
	return {
		type: actionTypes.GENE_RESULTS_ENUM_ERROR,
		payload: err,
	};
};

export const gettingGeneticsResultsGenes = (data) => {
	return {
		type: actionTypes.GETTING_GENETIC_RESULT_GENES,
		payload: data,
	};
};

const loadingGeneticsResultsGenes = () => {
	return {
		type: actionTypes.LOADING_GENETIC_RESULT_GENES,
	};
};

const geneticsResultsGenesError = (err) => {
	return {
		type: actionTypes.GENETIC_RESULT_GENES_ERROR,
		payload: err,
	};
};

export const clearGeneticsResultsGenes = () => {
	return {
		type: actionTypes.GETTING_GENETIC_RESULT_GENES,
		payload: [],
	};
};
export const clearGeneticsResultsGenes_Edit = () => {
	return {
		type: actionTypes.GETTING_GENETIC_RESULT_GENES_EDIT,
		payload: [],
	};
};

export const gettingGeneticsResultsGenes_Edit = (data) => {
	return {
		type: actionTypes.GETTING_GENETIC_RESULT_GENES_EDIT,
		payload: data,
	};
};

const loadingGeneticsResultsGenesEdit = () => {
	return {
		type: actionTypes.LOADING_GENETIC_RESULT_GENES_EDIT,
	};
};

const geneticsResultsGenesEditError = (err) => {
	return {
		type: actionTypes.GENETIC_RESULT_GENES_EDIT_ERROR,
		payload: err,
	};
};

export const startAddGeneResultDispatch = () => {
	return {
		type: actionTypes.ADDING_GENE_RESULTS,
	};
};
export const successAddGeneResultDispatch = (data) => {
	return {
		type: actionTypes.ADDED_GENE_RESULTS,
		payload: data,
	};
};
export const faliureAddGeneResultDispatch = (error) => {
	return {
		type: actionTypes.ADD_GENE_RESULTS_ERROR,
		payload: error,
	};
};

export const startupdateGeneResultDispatch = (data) => {
	return {
		type: actionTypes.UPDATING_GENE_RESULTS,
		payload: data,
	};
};

export const successupdateGeneResultDispatch = (data) => {
	return {
		type: actionTypes.UPDATED_GENE_RESULTS,
		payload: data,
	};
};

export const faliureupdateGeneResultDispatch = (error) => {
	return {
		type: actionTypes.UPDATE_GENE_RESULTS_ERROR,
		payload: error,
	};
};

//gene test result enums
export function getGeneTestResultsEnums() {
	return (dispatch) => {
		dispatch(loadingGeneResultsEnums());
		GenesService.getGeneTestResultsEnums()
			.then((res) => {
				dispatch(gettingGeneResultsEnums(get(res, 'data', {})));
			})
			.catch((err) => {
				dispatch(geneResultsEnumsError(err));
			});
	};
}

export function resetGeneTestResultsEnums() {
	return (dispatch) => {
		dispatch(gettingGeneResultsEnums({}));
	};
}

//genetic test genes
export function getGeneticResultGenes(uuid, q) {
	return (dispatch) => {
		dispatch(loadingGeneticsResultsGenes());
		GenesService.getGeneticResultGenes(uuid, q)
			.then((res) => {
				dispatch(
					gettingGeneticsResultsGenes(get(res, 'data.data', {}))
				);
			})
			.catch((err) => {
				dispatch(geneticsResultsGenesError(err));
			});
	};
}

export function getGeneticResultGenes_Edit(data) {
	return (dispatch) => {
		dispatch(loadingGeneticsResultsGenesEdit());
		GenesService.getGeneticResultGenes_MultipleID(data)
			.then((res) => {
				dispatch(
					gettingGeneticsResultsGenes_Edit(get(res, 'data.data', {}))
				);
			})
			.catch((err) => {
				dispatch(geneticsResultsGenesEditError(err));
			});
	};
}
export function addGeneTestResults(data) {
	return (dispatch) => {
		dispatch(startAddGeneResultDispatch());
		GenesService.createGeneResult(data)
			.then((res) => {
				dispatch(successAddGeneResultDispatch(get(res, 'data', {})));
			})
			.catch((error) => {
				dispatch(faliureAddGeneResultDispatch(error));
			});
	};
}
export function updateGeneTestResults(data) {
	return (dispatch) => {
		dispatch(startupdateGeneResultDispatch());
		GenesService.updateGeneResult(data)
			.then((res) => {
				dispatch(successupdateGeneResultDispatch(get(res, 'data', {})));
			})
			.catch((error) => {
				dispatch(faliureupdateGeneResultDispatch(error));
			});
	};
}
