import React from 'react';

import { Section } from '@gm/common/ui';

import { ProviderNote } from '../components/ProviderNote';
import { InternalNote } from '../components/InternalNote';
import { DocumentUpload } from '../components/DocumentUpload';

import { useSection } from './useSection';
import './NotesAndDocuments.scss';

export const initialNotesAndDocuments = {
	enteredProviderNote: '',
	enteredInternalNote: '',
	documentData: [],
};

export const NotesAndDocuments = ({
	sectionDataContainerName,
	isExternalUser,
}) => {
	const { values, onChange, setFieldValue } = useSection(
		sectionDataContainerName
	);

	return (
		<Section className='notes-and-documents' title='Notes & Documents'>
			{isExternalUser ? (
				<div className='two-col-div'>
					<ProviderNote values={values} onChange={onChange} />
					<DocumentUpload setFieldValue={setFieldValue} />
				</div>
			) : (
				<React.Fragment>
					<div className='two-col-div'>
						<InternalNote values={values} onChange={onChange} />
						<ProviderNote values={values} onChange={onChange} />
					</div>
					<hr />
					<div>
						<DocumentUpload setFieldValue={setFieldValue} />
					</div>
				</React.Fragment>
			)}
		</Section>
	);
};
