//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//Utils
import { GetSelectvalue } from '../Common/utilsfunctions';

const PGXSegment = (props) => {
	return (
		<div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							PharmD Identification
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Drug Interaction
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							# of Interaction(s)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Drug/Gene Interaction
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Drug Dos Adj Recommended
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'pharmd'],
								_.get(
									props,
									['groupsessionschema', 'pharmd'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'drug_interaction'],
								_.get(
									props,
									['groupsessionschema', 'drug_interaction'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'no_of_interactions'],
								_.get(
									props,
									[
										'groupsessionschema',
										'no_of_interactions',
									],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'drug_contraindications'],
								_.get(
									props,
									[
										'groupsessionschema',
										'drug_contraindications',
									],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								[
									'encounter',
									'drug_dosage_adjustment_recommended',
								],
								_.get(
									props,
									[
										'groupsessionschema',
										'drug_dosage_adjustment_recommended',
									],
									[]
								)
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PGXSegment;
