//React & Redux
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

//Lodash
import _ from 'lodash';

//Utils
import { states_list } from './../../utils.js';
import {
	dependentVariants,
	biologicalSexVariants,
} from '../../Utils/appointmentUtils';
import {
	required,
	phoneOrEmpty,
	validZipCode,
	MatchAddress,
	MatchCity,
	validNonEmptyDate,
	isSelfPayText,
	invalidInsuranceCompany,
} from '../../Utils/validate';

//UI Libraries
import InputMask from 'react-input-mask';
import { Input, Select, Layout } from 'gm-element-react';

//Components
import Icon from '../Common/Icon';
import SelfPayModal from './selfPayModal';
import AppointmentSection from './AppointmentSection';
import Tooltip from '../Common/Tooltip';

//Styles
import './css/select-patient.css';
import './css/payment-stage.css';

const ValidateHealthInsuranceCompany = required('Company name');
const ValidateHealthInsuranceCompanyID = required('Insurance');
const ValidateHealthInsuranceNumber = required('Insurance ID');
const ValidateHealthInsurancePhone = required('Phone number');
const ValidateInsuranceAddress = MatchAddress('Insurance address');
const ValidateRequiredInsuranceAddress = required('Insurance address');
const ValidateInsuranceCity = MatchCity('Insurance city');
const ValidateRequiredInsuranceCity = required('Insurance city');
const ValidateInsuranceState = required('State');
const ValidateInsuranceZipCode = validZipCode('Zip code');
const ValidateRequiredInsuranceZipCode = required('Zip code');
const ValidatePolicyHolderFirstName = required('First name');
const ValidatePolicyHolderLastName = required('Last name');
const ValidatePolicyHolderBiologicalSex = required('Biological sex');
const ValidatePolicyHolderRelationship = required('Relationship');
const ValidatePolicyHolderDOB = validNonEmptyDate(
	'Policyholder DOB is invalid',
	'MM/DD/YYYY',
);

const textField = ' erFieldText erFieldTextWider';

const healthInsuranceCompany = ({
	input,
	meta,
	placeholder,
	options,
	className = '',
	serverError,
}) => (
	<>
		<Select
			{...input}
			placeholder={placeholder}
			className={
				className
					? className + ' dropdownLeftEdgeAligned'
					: 'dropdownLeftEdgeAligned'
			}
		>
			{options.map((el, i) => {
				return (
					<Select.Option key={i} label={el.name} value={el.id}>
						{el.name}
					</Select.Option>
				);
			})}
		</Select>
		{meta && meta.touched && (meta.error || serverError) && (
			<span className='error'>{meta.error || serverError}</span>
		)}
	</>
);

const renderInsuranceId = ({ input, meta, placeholder, trim, disabled }) => (
	<div className='position-rel'>
		<div className='appointmentRequiredField'>*</div>
		<label className='selfpay-label'>Insurance ID #</label>
		<div>
			<Input
				{...input}
				placeholder={placeholder}
				trim={trim}
				className={'schedulingPaymentInput' + textField}
				disabled={disabled}
			/>
		</div>
		{meta && meta.touched && meta.error && (
			<span className='error'>{meta.error}</span>
		)}
	</div>
);

const renderCompanyOther = ({ input, meta, placeholder, trim, disabled }) => (
	<>
		<Input
			{...input}
			placeholder={placeholder}
			trim={trim}
			className='health-other-textbox select-textbox-border schedulingPaymentInput'
			disabled={disabled}
		/>
		{meta && meta.touched && meta.error && (
			<span
				className='error'
				style={{
					position: 'absolute',
					top: '52px',
					left: '91px',
				}}
			>
				{meta.error}
			</span>
		)}
	</>
);

const renderInsurancePhoneNumber = (field) => (
	<div className='insurancebox'>
		<div className='appointmentRequiredField'>*</div>
		<label
			className='selfpay-label insurance-num'
			style={{ display: 'flex', marginBottom: '2px' }}
		>
			Insurance Phone Number
			<Tooltip
				effect='dark'
				content='Please enter the insurance phone number indicated on the back of your insurance card'
				placement='top'
			>
				<span className='tooltip-info'>
					<Icon icon='info' />
				</span>
			</Tooltip>
		</label>
		<div className='health-text-container'>
			<div>
				<InputMask {...field.input} {...field} />
			</div>
			{field.meta.touched && field.meta.error && (
				<span className='error'>{field.meta.error}</span>
			)}
		</div>
	</div>
);

const renderPlantype = ({ input, meta, placeholder, options }) => {
	return (
		<div className='insurancebox' style={{ width: '100%' }}>
			<label className='selfpay-label insurancce-num'>Plan Type</label>
			<div>
				<Select
					{...input}
					placeholder={placeholder}
					className='insurance-state-select insurancePlanType inputFieldDropdown'
				>
					{options.map((el, i) => {
						return (
							<Select.Option
								key={i}
								label={el.display_name}
								value={el.name}
							>
								{el.display_name}
							</Select.Option>
						);
					})}
				</Select>
				{meta && meta.touched && meta.error && (
					<span className='error'>{meta.error}</span>
				)}
			</div>
		</div>
	);
};

const renderGroup = ({ input, meta, placeholder, trim, disabled }) => {
	return (
		<div className='insurancebox'>
			<label className='selfpay-label insurancce-num'>Group #</label>
			<div className='health-text-container'>
				<div>
					<Input
						{...input}
						placeholder={placeholder}
						trim={trim}
						disabled={disabled}
						className={'schedulingPaymentInput' + textField}
					/>
					{meta && meta.touched && meta.error && (
						<span className='error'>{meta.error}</span>
					)}
				</div>
			</div>
		</div>
	);
};

const renderEmployer = ({ input, meta, placeholder, trim, disabled }) => {
	return (
		<div className='insurancebox'>
			<label className='selfpay-label insurancce-num'>Employer</label>
			<div className='health-text-container'>
				<div>
					<Input
						{...input}
						placeholder={placeholder}
						trim={trim}
						disabled={disabled}
						className={'schedulingPaymentInput' + textField}
					/>
					{meta && meta.touched && meta.error && (
						<span className='error'>{meta.error}</span>
					)}
				</div>
			</div>
		</div>
	);
};

const renderInsuranceAddress = ({
	input,
	meta,
	placeholder,
	trim,
	disabled,
}) => {
	return (
		<div className='insuranceaddress'>
			<div className='appointmentRequiredField'>*</div>
			<label className='selfpay-label insurancce-num'>
				Insurance Address
			</label>
			<div className=''>
				<div>
					<Input
						{...input}
						placeholder={placeholder}
						trim={trim}
						disabled={disabled}
						className={
							'insuranceadd_width schedulingPaymentInput' +
							textField
						}
					/>
					{meta && meta.touched && meta.error && (
						<span className='error'>{meta.error}</span>
					)}
				</div>
			</div>
		</div>
	);
};

const renderInsuranceCity = ({ input, meta, placeholder, trim, disabled }) => {
	return (
		<div className='insurancebox'>
			<div className='appointmentRequiredField'>*</div>
			<label className='selfpay-label insurancce-num'>
				Insurance City
			</label>
			<div className='health-text-container'>
				<div>
					<Input
						{...input}
						placeholder={placeholder}
						trim={trim}
						disabled={disabled}
						className={'schedulingPaymentInput' + textField}
					/>
					{meta && meta.touched && meta.error && (
						<span className='error'>{meta.error}</span>
					)}
				</div>
			</div>
		</div>
	);
};

const renderInsuranceState = ({
	input,
	meta,
	placeholder,
	onVisibleChange,
}) => (
	<div className='insurancebox insuranceShortField'>
		<div className='appointmentRequiredField'>*</div>
		<label className='selfpay-label  insurancce-num'>State</label>
		<div>
			<Select
				{...input}
				placeholder={placeholder}
				className='insurance-state-select inputFieldDropdown'
				onVisibleChange={onVisibleChange}
			>
				{states_list.map((el, i) => {
					return (
						<Select.Option key={i} label={el.key} value={el.key}>
							{el.value}
						</Select.Option>
					);
				})}
			</Select>
			{meta && meta.touched && meta.error && (
				<span className='error'>{meta.error}</span>
			)}
		</div>
	</div>
);

const renderInsurancezip = ({ input, meta, placeholder, trim, disabled }) => {
	return (
		<div className='insurancebox insuranceShortField'>
			<div className='appointmentRequiredField'>*</div>
			<label className='selfpay-label insurancce-num'>Zipcode</label>
			<div className='health-text-container'>
				<div>
					<Input
						{...input}
						placeholder={placeholder}
						trim={trim}
						disabled={disabled}
						className={
							'insurance-numtextbox schedulingPaymentInput' +
							textField
						}
					/>
					{meta && meta.touched && meta.error && (
						<span className='error'>{meta.error}</span>
					)}
				</div>
			</div>
		</div>
	);
};

const PolicyHolderFirstName = ({
	input,
	meta,
	placeholder,
	trim,
	disabled,
}) => {
	return (
		<div className='insurancebox'>
			<div className='appointmentRequiredField'>*</div>
			<label className='selfpay-label'>Policyholder's First Name</label>
			<div>
				<Input
					{...input}
					placeholder={placeholder}
					trim={trim}
					disabled={disabled}
					className={'schedulingPaymentInput' + textField}
				/>
				{meta && meta.touched && meta.error && (
					<span className='error'>{meta.error}</span>
				)}
			</div>
		</div>
	);
};

const PolicyHolderLastName = ({ input, meta, placeholder, trim, disabled }) => {
	return (
		<div className='insurancebox'>
			<div className='appointmentRequiredField'>*</div>
			<label className='selfpay-label'>Policyholder's Last Name</label>
			<div>
				<Input
					{...input}
					placeholder={placeholder}
					trim={trim}
					disabled={disabled}
					className={'schedulingPaymentInput' + textField}
				/>
				{meta && meta.touched && meta.error && (
					<span className='error'>{meta.error}</span>
				)}
			</div>
		</div>
	);
};

const PolicyHolderBiologicalSex = ({
	input,
	meta,
	placeholder,
	onVisibleChange,
}) => (
	<div className='insurancebox'>
		<div className='appointmentRequiredField'>*</div>
		<label className='selfpay-label  insurancce-num'>
			Policyholder's Biological Sex
		</label>
		<div>
			<Select
				{...input}
				placeholder={placeholder}
				value={input.value}
				className='insurance-state-select policyBiologicalSex inputFieldDropdown'
				onVisibleChange={onVisibleChange}
			>
				{biologicalSexVariants.map((el, i) => {
					return (
						<Select.Option
							key={i}
							label={el.label}
							value={el.value}
						>
							{el.label}
						</Select.Option>
					);
				})}
			</Select>
			{meta && meta.touched && meta.error && (
				<span className='error'>{meta.error}</span>
			)}
		</div>
	</div>
);

const PolicyHolderRelationship = ({
	input,
	meta,
	placeholder,
	onVisibleChange,
}) => (
	<div className='insurancebox' style={{ width: '100%' }}>
		<div className='appointmentRequiredField'>*</div>
		<label className='selfpay-label insurancce-num'>
			Patient's Relationship to Policyholder
		</label>
		<div>
			<Select
				{...input}
				placeholder={placeholder}
				className='insurance-state-select dropdownLeftEdgeAligned health-insurance-select-full inputFieldDropdown'
				onVisibleChange={onVisibleChange}
			>
				{dependentVariants.map((el, i) => {
					return (
						<Select.Option
							key={i}
							label={el.label}
							value={el.value}
						>
							{el.label}
						</Select.Option>
					);
				})}
			</Select>
			{meta && meta.touched && meta.error && (
				<span className='error'>{meta.error}</span>
			)}
		</div>
	</div>
);

const PolicyHolderDOBText = (field) => {
	return (
		<div className='insurancebox'>
			<div className='appointmentRequiredField'>*</div>
			<label className='selfpay-label'>Policyholder's DOB</label>
			<div className='health-text-container'>
				<InputMask {...field.input} {...field} />
				{field.meta.touched && field.meta.error && (
					<span
						className='error'
						style={{ position: 'absolute', bottom: '-15px' }}
					>
						{field.meta.error}
					</span>
				)}
			</div>
		</div>
	);
};

class InsuranceSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dob: null,
			initialState: {
				is_policyholder: true,
				insurance_company_id: 0,
				policyholder_biological_sex: _.get(
					this.props,
					'initialValues.policyholder_biological_sex',
					'',
				),
			},
		};
		//bindings
		this.touchfield = this.touchfield.bind(this);
		this.isOtherCompanyIsSelfPay = this.isOtherCompanyIsSelfPay.bind(this);
	}

	componentDidMount() {
		const insurance_company_id = _.get(
			this,
			['props', 'initialValues', 'insurance_company_id'],
			0,
		);
		const { initialValues } = this.props;
		if (initialValues) {
			const values = { ...initialValues };
			this.setState({
				initialState: values,
			});
		}

		if (insurance_company_id) {
			const companies = _.get(this, ['props', 'insuranceCompanies'], []);
			const selectedCompany = _.find(companies, (comp) => {
				return (
					insurance_company_id &&
					comp.id &&
					comp.id === insurance_company_id
				);
			});

			if (selectedCompany) {
				this.setState(
					{ insurance_company_id: insurance_company_id },
					() => {
						this.props.updateAppointmentparams({
							insurancePrice: selectedCompany.consultation_price,
							insurance_company_id,
						});
					},
				);
			}
		}
	}

	onChangeDOB = (value) => {
		this.setState({ dob: value });
	};

	isOtherCompanySelected = () => {
		const insurance_company_id = _.get(
			this,
			['state', 'initialState', 'insurance_company_id'],
			0,
		);
		const companies = _.get(this, ['props', 'insuranceCompanies'], []);

		const selectedCompany = _.find(companies, (comp) => {
			return (
				insurance_company_id &&
				comp.id &&
				comp.id === insurance_company_id
			);
		});
		if (selectedCompany) {
			return (
				selectedCompany &&
				selectedCompany.name_override_required &&
				selectedCompany.name_override_required == true
			);
		}
		return false;
	};

	touchfield(value, fieldname) {
		if (!value) {
			if (_.isFunction(_.get(this, ['props', 'touch']))) {
				this.props.touch(fieldname);
			}
		}
	}

	handleChange = (value, key, isPolicyHolder) => {
		const { initialValues } = this.props;
		if (key == 'insurance_company_id') {
			const companies = _.get(this, ['props', 'insuranceCompanies'], []);
			const selectedCompany = _.find(companies, (comp) => {
				return value && comp.id && comp.id === value;
			});
			if (selectedCompany) {
				const insurance_company_id = value;
				if (
					insurance_company_id ==
					this.props.StoredInsurance.insurance_company_id
				) {
					this.setState(
						{
							initialState: Object.assign(
								{},
								this.props.StoredInsurance,
								{ [key]: value },
							),
						},
						() => {
							this.props.initialize({
								...this.state.initialState,
							});
							this.props.updateAppointmentparams({
								insurancePrice:
									selectedCompany.consultation_price,
								insurance_company_id,
							});
						},
					);
				} else {
					const athena_insurance_package_phone = _.get(
						selectedCompany,
						'athena_insurance_package_phone',
						'',
					);
					this.setState(
						{
							initialState: Object.assign(
								{},
								this.props.defaultInsuranceDetail,
								{
									[key]: value,
									phone: athena_insurance_package_phone,
								},
							),
						},
						() => {
							this.props.initialize({
								...this.state.initialState,
							});
							this.props.updateAppointmentparams({
								insurancePrice:
									selectedCompany.consultation_price,
								insurance_company_id,
							});
						},
					);
				}
			}
		} else if (key == 'is_policyholder') {
			this.setState(
				{
					initialState: Object.assign({}, this.state.initialState, {
						[key]: isPolicyHolder,
					}),
				},
				() => {
					const patientsBiologicalSex = _.get(
						this.props,
						'patient.gender',
						'',
					);
					if (
						isPolicyHolder &&
						biologicalSexVariants.find(
							(a) =>
								a &&
								a.value &&
								a.value == patientsBiologicalSex,
						)
					) {
						this.setState({
							initialState: Object.assign(
								{},
								this.state.initialState,
								{
									policyholder_biological_sex:
										patientsBiologicalSex,
								},
							),
						});
						this.props.initialize({
							...this.state.initialState,
							policyholder_biological_sex: patientsBiologicalSex,
						});
					} else {
						this.setState({
							initialState: Object.assign(
								{},
								this.state.initialState,
								{
									policyholder_biological_sex:
										initialValues &&
										initialValues.policyholder_biological_sex,
								},
							),
						});
						this.props.initialize({
							...this.state.initialState,
							policyholder_biological_sex:
								initialValues &&
								initialValues.policyholder_biological_sex,
						});
					}
				},
			);
		} else {
			this.setState({
				initialState: Object.assign({}, this.state.initialState, {
					[key]: value,
				}),
			});
		}
	};

	ValidateInsuranceCompany = (value) => {
		const { isSelfPayShow } = this.props;
		return invalidInsuranceCompany(isSelfPayShow, value);
	};

	isOtherCompanyIsSelfPay = () => {
		const { isSelfPayShow } = this.props;
		return (
			isSelfPayShow &&
			this.isOtherCompanySelected() &&
			isSelfPayText(
				_.get(this.state, ['initialState', 'company_name'], ''),
			)
		);
	};

	onSelfPayModalContinue = () => {
		this.props.selectSelfPay();
	};

	GetPlanTypes = () => {
		const { planTypesEnums, initialValues } = this.props;
		const planName = _.get(initialValues, 'plan_name', '');
		let customPlanTypeEnums = planTypesEnums;
		if (!_.isEmpty(planTypesEnums)) {
			customPlanTypeEnums = [
				{ name: '', display_name: '' },
				...planTypesEnums,
			];
			if (!_.isNil(planName)) {
				const existngPlanName = _.find(customPlanTypeEnums, (type) => {
					return type.name == planName;
				});
				if (!existngPlanName) {
					customPlanTypeEnums = [
						...customPlanTypeEnums,
						{ name: planName, display_name: planName },
					];
				}
			}

			return [...customPlanTypeEnums];
		}
		return [];
	};

	render() {
		const { StoredInsurance } = this.props;
		const GetPlanTypes = this.GetPlanTypes();
		const showSecondaryInsurance = false;
		return (
			<>
				<form>
					<div className='appointmentschedule-insurancesection'>
						<AppointmentSection
							heading='Insurance Information'
							description=''
							isRequired={true}
							horizontalLine={true}
							marginAuto={true}
						>
							<section className='patient-info-container'>
								<Layout.Row
									gutter='16'
									style={{ marginBottom: '19px' }}
								>
									<Layout.Col span='24'>
										<div style={{ position: 'relative' }}>
											<div className='appointmentRequiredField'>
												*
											</div>
											<label className='selfpay-label'>
												Health Insurance Company Name
											</label>
											<div className='health-container'>
												<div
													className='health-text-container'
													style={{ display: 'flex' }}
												>
													<Field
														name='insurance_company_id'
														component={
															healthInsuranceCompany
														}
														type='select'
														placeholder='Select'
														isrequired={true}
														className={`health-insurance-select ${
															this.isOtherCompanySelected() ==
															false
																? 'health-insurance-select-full'
																: 'health-insurance-select-part'
														}`}
														options={
															this.props
																.insuranceCompanies
														}
														onChange={(e, value) =>
															this.handleChange(
																value,
																'insurance_company_id',
															)
														}
														validate={[
															ValidateHealthInsuranceCompanyID,
														]}
													/>

													{this.isOtherCompanySelected() ===
													true ? (
														<Field
															name='company_name'
															placeholder='Health Insurance Company name'
															component={
																renderCompanyOther
															}
															validate={[
																ValidateHealthInsuranceCompany,
																this
																	.ValidateInsuranceCompany,
															]}
															onChange={(
																e,
																value,
															) =>
																this.handleChange(
																	value,
																	'company_name',
																)
															}
														/>
													) : null}
												</div>
											</div>
										</div>
									</Layout.Col>
								</Layout.Row>
								<Layout.Row
									gutter='16'
									style={{ marginBottom: '19px' }}
								>
									<Layout.Col span='12'>
										<Field
											name='insurance_no'
											component={renderInsuranceId}
											type='text'
											placeholder='1234567890'
											isrequired={true}
											validate={[
												ValidateHealthInsuranceNumber,
											]}
											onChange={(e, value) =>
												this.handleChange(
													value,
													'insurance_no',
												)
											}
										/>
									</Layout.Col>
									<Layout.Col span='12'>
										<Field
											name='phone'
											type='text'
											label='Phone number'
											mask='+1 999 999-9999'
											placeholder='Insurance Phone Number'
											maskChar={null}
											autoComplete='off'
											className='inputFieldText insurance-numtextbox'
											component={
												renderInsurancePhoneNumber
											}
											isrequired={true}
											validate={[
												ValidateHealthInsurancePhone,
												phoneOrEmpty,
											]}
											onChange={(e, value) =>
												this.handleChange(
													value,
													'phone',
												)
											}
										/>
									</Layout.Col>
								</Layout.Row>

								{this.isOtherCompanySelected() === true ? (
									<>
										<Layout.Row
											gutter='16'
											style={{ marginBottom: '19px' }}
										>
											<Layout.Col span='24'>
												<Field
													name='plan_name'
													component={renderPlantype}
													type='text'
													placeholder='HMO Platinum'
													trim={true}
													options={GetPlanTypes}
												/>
											</Layout.Col>
										</Layout.Row>
										<Layout.Row
											gutter='16'
											style={{ marginBottom: '19px' }}
										>
											<Layout.Col span='12'>
												<Field
													name='group_number'
													component={renderGroup}
													type='text'
													placeholder='Group #'
													trim={true}
													onChange={(e, value) =>
														this.handleChange(
															value,
															'group_number',
														)
													}
												/>
											</Layout.Col>
											<Layout.Col span='12'>
												<Field
													name='employer'
													component={renderEmployer}
													type='text'
													placeholder='Employer'
													trim={true}
													onChange={(e, value) =>
														this.handleChange(
															value,
															'employer',
														)
													}
												/>
											</Layout.Col>
										</Layout.Row>
										<Layout.Row
											gutter='16'
											style={{ marginBottom: '19px' }}
										>
											<Layout.Col span='24'>
												<Field
													name='insurance_address'
													component={
														renderInsuranceAddress
													}
													type='text'
													placeholder='Insurance Address'
													trim={true}
													validate={[
														ValidateRequiredInsuranceAddress,
														ValidateInsuranceAddress,
													]}
													onChange={(e, value) =>
														this.handleChange(
															value,
															'insurance_address',
														)
													}
												/>
											</Layout.Col>
										</Layout.Row>
										<Layout.Row
											gutter='16'
											style={{ marginBottom: '19px' }}
										>
											<Layout.Col span='12'>
												<Field
													name='insurance_city'
													component={
														renderInsuranceCity
													}
													type='text'
													placeholder='Insurance City'
													trim={true}
													validate={[
														ValidateRequiredInsuranceCity,
														ValidateInsuranceCity,
													]}
													onChange={(e, value) =>
														this.handleChange(
															value,
															'insurance_city',
														)
													}
												/>
											</Layout.Col>
											<Layout.Col span='6'>
												<Field
													name='insurance_state'
													component={
														renderInsuranceState
													}
													type='select'
													onChange={(e, value) =>
														this.handleChange(
															value,
															'insurance_state',
														)
													}
													validate={[
														ValidateInsuranceState,
													]}
													onVisibleChange={(value) =>
														this.touchfield(
															value,
															'insurance_state',
														)
													}
												/>
											</Layout.Col>
											<Layout.Col span='6'>
												<Field
													name='insurance_zipcode'
													component={
														renderInsurancezip
													}
													type='text'
													placeholder='Zipcode'
													trim={true}
													validate={[
														ValidateRequiredInsuranceZipCode,
														ValidateInsuranceZipCode,
													]}
													onChange={(e, value) =>
														this.handleChange(
															value,
															'insurance_zipcode',
														)
													}
												/>
											</Layout.Col>
										</Layout.Row>
									</>
								) : null}
								<Layout.Row
									gutter='16'
									style={{ marginBottom: '19px' }}
								>
									<Layout.Col span='24'>
										<Field
											name='is_policyholder'
											label='Is the Patient the primary policyholder?'
											component={this.props.renderSwitch}
											onChange={(value, isPolicyHolder) =>
												this.handleChange(
													null,
													'is_policyholder',
													isPolicyHolder,
												)
											}
											isrequired={true}
											options={[
												{ label: 'Yes', value: true },
												{ label: 'No', value: false },
											]}
										/>
									</Layout.Col>
								</Layout.Row>
								{this.state.initialState.is_policyholder ==
									false && (
									<Layout.Row
										gutter='16'
										style={{ marginBottom: '19px' }}
									>
										<Layout.Col span='12'>
											<Field
												name='policyholder_first_name'
												component={
													PolicyHolderFirstName
												}
												type='text'
												placeholder='John'
												trim={true}
												validate={[
													ValidatePolicyHolderFirstName,
												]}
												onChange={(e, value) =>
													this.handleChange(
														value,
														'policyholder_first_name',
													)
												}
											/>
										</Layout.Col>
										<Layout.Col span='12'>
											<Field
												name='policyholder_last_name'
												component={PolicyHolderLastName}
												type='text'
												placeholder='Smith'
												trim={true}
												validate={[
													ValidatePolicyHolderLastName,
												]}
												onChange={(e, value) =>
													this.handleChange(
														value,
														'policyholder_last_name',
													)
												}
											/>
										</Layout.Col>
									</Layout.Row>
								)}
								<Layout.Row
									gutter='16'
									style={{ marginBottom: '19px' }}
								>
									<Layout.Col span='12'>
										<Field
											name='policyholder_biological_sex'
											component={
												PolicyHolderBiologicalSex
											}
											type='select'
											validate={[
												ValidatePolicyHolderBiologicalSex,
											]}
											onChange={(e, value) =>
												this.handleChange(
													value,
													'policyholder_biological_sex',
												)
											}
											onVisibleChange={(value) =>
												this.touchfield(
													value,
													'policyholder_biological_sex',
												)
											}
										/>
									</Layout.Col>
									<Layout.Col span='12'>
										{this.state.initialState
											.is_policyholder == false && (
											<Field
												name='policyholder_dob'
												type='text'
												label='DOB'
												placeholder='MM/DD/YYYY'
												component={PolicyHolderDOBText}
												validate={[
													ValidatePolicyHolderDOB,
												]}
												onChange={(e, value) =>
													this.handleChange(
														value,
														'policyholder_dob',
													)
												}
												trim={true}
												mask='99/99/9999'
												maskChar={null}
												autoComplete='off'
												className='insurance-numtextbox inputFieldText'
											/>
										)}
									</Layout.Col>
								</Layout.Row>
								{this.state.initialState.is_policyholder ==
									false && (
									<Layout.Row
										gutter='16'
										style={{ marginBottom: '19px' }}
									>
										<Layout.Col span='24'>
											<Field
												name='policyholder_relationship'
												component={
													PolicyHolderRelationship
												}
												type='select'
												validate={[
													ValidatePolicyHolderRelationship,
												]}
												onChange={(e, value) =>
													this.handleChange(
														value,
														'policyholder_relationship',
													)
												}
												onVisibleChange={(value) =>
													this.touchfield(
														value,
														'policyholder_relationship',
													)
												}
											/>
										</Layout.Col>
									</Layout.Row>
								)}
								{showSecondaryInsurance && (
									<Layout.Row
										gutter='16'
										style={{ marginBottom: '19px' }}
									>
										<Layout.Col span='24'>
											<Field
												name='is_secondary_insurance'
												label='Does the patient have secondary insurance?'
												component={
													this.props.renderSwitch
												}
												onChange={(e, value) =>
													this.props.updateSecondaryInsurance(
														value,
													)
												}
												isrequired={true}
												options={[
													{
														label: 'Yes',
														value: true,
														disabled:
															StoredInsurance.is_secondary_insurance,
													},
													{
														label: 'No',
														value: false,
														disabled:
															StoredInsurance.is_secondary_insurance,
													},
												]}
											/>
										</Layout.Col>
									</Layout.Row>
								)}
							</section>
						</AppointmentSection>
					</div>
				</form>
				{this.isOtherCompanyIsSelfPay() && (
					<SelfPayModal
						companyName={_.get(
							this.state,
							['initialState', 'company_name'],
							'',
						)}
						click={this.onSelfPayModalContinue}
						close={() => {
							return;
						}}
					/>
				)}
			</>
		);
	}
}

InsuranceSection.propTypes = {
	initialValues: PropTypes.object,
};

// Decorate the form component
// eslint-disable-next-line no-class-assign
InsuranceSection = reduxForm({
	form: 'scheduleappointmentinsuranceform', // a unique name for this form
})(InsuranceSection);

export default InsuranceSection;
