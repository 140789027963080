//React & Redux
import React, { Fragment, useState, useEffect, useRef } from 'react';

//Images
import imageExpandCollapse from '../../../../assets/chevron-navy-blue.svg';
import moreinfoicon from '../../../../assets/more-info.svg';
import warningicon from '../../../../assets/warning-alert.svg';

//Lodash
import _ from 'lodash';

//UI Libraries
import {
	Loading,
	Layout,
	Form,
	Input,
	Select,
	Radio,
	Button,
	Dialog,
} from 'gm-element-react';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious';

// Component
import ErrorBoundary from '../../../Common/ErrorBoundary';
import Tooltip from '../../../Common/Tooltip';

const EditGeneDetails = (props) => {
	const {
		callDeleteLastGeneResultFunc,
		clearingGeneticsResultsGenes_Edit,
		data,
		enqueueSnackbar,
		geneEnum_Edit,
		geneList,
		getGeneticResultGene_Edit,
		interpretation = [],
		isCancel,
		item,
		nonGeneEnum = [],
		updateGeneTestResultFunc,
		updategeneprops,
		zygosity = [],
		discreteVariantFinding,
		observationFinding,
		riskAssessmentFinding,
		structuralVariantFinding,
		cfDNAPrenatalObservationFinding,
		nucleotideRepeatVariantFinding,
	} = props;

	const formRef = useRef();

	const [toggleAdditionInfo, setToggleAdditionInfo] = useState(true);
	const [searchloading, setSearchloading] = useState(false);
	const [form, setForm] = useState({
		geneticTestGenesEnumsId: item.geneticTestGenesEnumsId,
		rsID: item.rsID,
		pPosition: item.pPosition,
		genoType: item.genoType,
		cDnaPos: item.cDnaPos,
		riskScore: item.riskScore,
		riskScoreNormalRange: item.riskScoreNormalRange,
		labComments: item.labComments,
		interpretation: item.interpretation,
		clinvarID: item.clinvarID,
		zygosity: item.zygosity,
		hgvs: item.hgvs,
		haploType: item.haploType,
		clinicianComments: item.clinicianComments,
		geneUUID: item.geneUUID,
		copyNumber: item.copyNumber,
		activityScore: item.activityScore,
		allele1Repeats: item.allele1Repeats,
		allele2Repeats: item.allele2Repeats,
		nonGeneTestType: item.nonGeneTestType,
	});
	const [optionsGenes, setOptionsGenes] = useState([]);
	const [loading, setLoading] = useState(false);
	const [geneResultDeleteDialog, setGeneResultDeleteDialog] = useState(false);
	const [geneResultCancelDialog, setGeneResultCancelDialog] = useState(false);
	const [rules, setRules] = useState({});
	const [errors, setErrors] = useState({
		mutationInfo: '',
		gene: '',
		nonGene: '',
	});

	const prevUpdategeneprops = usePrevious(updategeneprops);
	const prevData = usePrevious(data);

	useEffect(() => {
		if (!_.isNil(_.get(item, ['geneticTestGenesEnumsId'], '0'))) {
			getGeneticResultGene_Edit('', [item.geneticTestGenesEnumsId]);
		}

		return () => clearingGeneticsResultsGenes_Edit();
	}, []);

	useEffect(() => {
		if (
			!_.isNil(prevUpdategeneprops) &&
			prevUpdategeneprops.updating != updategeneprops.updating &&
			updategeneprops.updating == true
		) {
			setLoading(true);
		} else if (
			!_.isNil(prevUpdategeneprops) &&
			prevUpdategeneprops.updated != updategeneprops.updated &&
			updategeneprops.updated
		) {
			if (
				!_.isNull(
					_.get(
						updategeneprops,
						['updated', 'data', 'genetic_test_genes_enums_id'],
						'0'
					)
				)
			) {
				enqueueSnackbar('Gene has been updated successfully.', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			}
			if (
				_.isNull(
					_.get(
						updategeneprops,
						['updated', 'data', 'genetic_test_genes_enums_id'],
						'0'
					)
				)
			) {
				enqueueSnackbar('Non-Gene has been updated successfully.', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			}
		} else if (
			!_.isNil(prevUpdategeneprops) &&
			prevUpdategeneprops.error != updategeneprops.error &&
			prevUpdategeneprops.updating == true &&
			updategeneprops.updating == false
		) {
			enqueueSnackbar('Error in updating gene', {
				variant: 'error',
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'bottom',
				},
			});
		}
	}, [updategeneprops]);

	useEffect(() => {
		if (!_.isNil(prevData) && prevData != data) {
			setLoading(false);
			isCancel();
		}
	}, [data]);

	const getQueryGeneticResultGene = _.debounce((query) => {
		getGeneticResultGene_Edit(query);
	}, 500);

	const validatefields = () => {
		const _errors = { ...errors };

		if (!_.isNil(_.get(item, ['geneticTestGenesEnumsId'], '0'))) {
			if (!form['geneticTestGenesEnumsId'] != 0) {
				_errors['gene'] = 'Please select gene';
			} else {
				delete _errors['gene'];
				delete _errors['nonGene'];
			}
		}

		if (_.isNil(_.get(item, ['geneticTestGenesEnumsId'], '0'))) {
			if (form['nonGeneTestType'] === '') {
				_errors['nonGene'] = 'Please select Non-Gene';
			} else {
				delete _errors['nonGene'];
				delete _errors['gene'];
			}
		}

		if (
			!(
				form['rsID'] !== '' ||
				form['pPosition'] !== '' ||
				form['cDnaPos'] !== '' ||
				form['genoType'] !== '' ||
				form['riskScore'] !== ''
			)
		) {
			_errors['mutationInfo'] =
				'Please enter one of the 5 fields c.DNA pos, p.Position, rsID, Genotype, risk score';
		} else {
			delete _errors['mutationInfo'];
		}

		setErrors({
			..._errors,
		});
		if (_.isEmpty(_errors)) {
			return true;
		}
		return false;
	};

	const handleChange = (key, value) => {
		setForm((prevForm) => ({
			...prevForm,
			[key]: value,
		}));
	};

	const onSearch = (query) => {
		if (query !== '') {
			setSearchloading(false);
			getQueryGeneticResultGene(query);
		} else {
			form['geneticTestGenesEnumsId'] = 0;
			setOptionsGenes([]);
			setForm(form);
			getQueryGeneticResultGene(query);
		}
	};

	const handleComplete = (e) => {
		e.preventDefault();
		if (validatefields()) {
			updateGeneTestResultFunc(form);
		}
	};

	const showDeleteGeneDialog = () => {
		setGeneResultDeleteDialog(true);
	};

	const showCancelGeneDialog = () => {
		setGeneResultCancelDialog(true);
	};

	const handleDeleteGeneResult = () => {
		const isLastResult = _.get(geneList, ['length'], 0) == 1;
		const isResultWithNewUI =
			(observationFinding || []).length > 0 ||
			(discreteVariantFinding || []).length > 0 ||
			(riskAssessmentFinding || []).length > 0 ||
			(structuralVariantFinding || []).length > 0 ||
			(cfDNAPrenatalObservationFinding || []).length > 0 ||
			(nucleotideRepeatVariantFinding || []).length > 0;

		updateGeneTestResultFunc({ isDeleted: true });
		if (isLastResult && !isResultWithNewUI) {
			callDeleteLastGeneResultFunc();
		}
		setGeneResultDeleteDialog(false);
	};

	const handleResultType = () => {
		return _.isNil(_.get(item, ['geneticTestGenesEnumsId'], '0'))
			? 'nonGeneResult'
			: 'geneResult';
	};

	const genes =
		geneEnum_Edit && _.isArray(geneEnum_Edit) && geneEnum_Edit.length > 0
			? geneEnum_Edit
			: [];
	return (
		<Fragment>
			<Loading loading={loading}>
				<Form
					ref={formRef}
					className='addgene-form'
					model={form}
					rules={rules}
				>
					<Layout.Row gutter='38'>
						<Layout.Col span='7'>
							<Layout.Row className='disable-section'>
								<Layout.Col span='24' className='gene-header '>
									RESULT INFO
								</Layout.Col>
							</Layout.Row>

							<Layout.Row className='disable-section'>
								<Layout.Col
									span='24'
									className='result-type-question'
								>
									What type of result is this?
								</Layout.Col>
							</Layout.Row>
							<Layout.Row className='disable-section'>
								<Layout.Col span='24'>
									<Radio.Group
										value={handleResultType()}
										className='rdoResultType'
									>
										<Radio value={'geneResult'}>
											A positive or VUS result
											(Gene/Mutation)
										</Radio>
										<Radio value={'nonGeneResult'}>
											Other type of result (“Non-gene”)
										</Radio>
									</Radio.Group>
								</Layout.Col>
							</Layout.Row>

							<Layout.Row>
								<Layout.Col span='24'>
									{!_.isNil(
										_.get(
											item,
											['geneticTestGenesEnumsId'],
											'0'
										)
									) && (
										<Form.Item
											label='Gene'
											prop='gene_id'
											className={`required ${
												errors.gene && 'errorMSGMargin'
											}`}
										>
											<ErrorBoundary>
												<Select
													value={
														_.isNaN(
															parseInt(
																_.get(
																	form,
																	[
																		'geneticTestGenesEnumsId',
																	],
																	'0'
																)
															)
														)
															? 0
															: parseInt(
																	_.get(
																		form,
																		[
																			'geneticTestGenesEnumsId',
																		],
																		'0'
																	)
															  )
													}
													onChange={(value) =>
														handleChange(
															'geneticTestGenesEnumsId',
															value
														)
													}
													filterable={true}
													remote={true}
													clearable={true}
													placeholder='BRCA1'
													remoteMethod={(val) =>
														onSearch(val)
													}
													loading={searchloading}
												>
													{genes.map((el) => {
														return (
															<Select.Option
																key={el.id}
																label={el.gene}
																value={el.id}
															/>
														);
													})}
												</Select>
											</ErrorBoundary>
										</Form.Item>
									)}
									{_.isNil(
										_.get(
											item,
											['geneticTestGenesEnumsId'],
											'0'
										)
									) && (
										<Form.Item
											label='Non-Gene'
											prop='nonGeneTestType'
											className={`required ${
												errors.nonGene &&
												'errorMSGMargin'
											}`}
										>
											<Select
												value={form.nonGeneTestType}
												onChange={(value) =>
													handleChange(
														'nonGeneTestType',
														value
													)
												}
												placeholder='Select'
											>
												<Select.Option
													label='Select'
													value=''
												/>
												{nonGeneEnum.map(
													(item, idx) => {
														return (
															<Select.Option
																key={idx}
																label={
																	item.display_name
																}
																value={item.key}
															/>
														);
													}
												)}
											</Select>
										</Form.Item>
									)}
								</Layout.Col>
								<Layout.Col span='24'>
									<span
										className={
											!errors.gene
												? 'hide'
												: 'errorMessage'
										}
									>
										{errors.gene}
									</span>
								</Layout.Col>
							</Layout.Row>
						</Layout.Col>

						<Layout.Col span='17'>
							<Layout.Row>
								<Layout.Col
									span='12'
									className='mutation-header'
								>
									mutation info
								</Layout.Col>
								<Layout.Col
									span='12'
									className='add-gene-col-header one-more-fields-required'
								>
									<span className='double-asterisk-hint'>
										One or more of these fields required
									</span>
								</Layout.Col>
							</Layout.Row>

							<Layout.Row gutter='20'>
								<Layout.Col span='6'>
									<Form.Item
										label='c.DNA pos'
										prop='cDnaPos'
										className={`double-asterisk ${
											errors.mutationInfo &&
											'errorMSGMargin'
										}`}
									>
										<Input
											value={form.cDnaPos}
											name='cDnaPos'
											onChange={(value) =>
												handleChange('cDnaPos', value)
											}
											placeholder='c.5851_5854del'
											trim={true}
										/>
									</Form.Item>
								</Layout.Col>
								<Layout.Col span='6'>
									<Form.Item
										label='p.position'
										prop='pPosition'
										className={`double-asterisk ${
											errors.mutationInfo &&
											'errorMSGMargin'
										}`}
									>
										<Input
											value={form.pPosition}
											onChange={(value) =>
												handleChange('pPosition', value)
											}
											placeholder='p.Ser1951fs'
											trim={true}
										/>
									</Form.Item>
								</Layout.Col>
								<Layout.Col span='6'>
									<Form.Item
										label='rsID'
										prop='rsID'
										className={`double-asterisk ${
											errors.mutationInfo &&
											'errorMSGMargin'
										}`}
									>
										<Input
											value={form.rsID}
											placeholder='rs80359543'
											onChange={(value) =>
												handleChange('rsID', value)
											}
											trim={true}
										/>
									</Form.Item>
								</Layout.Col>
								<Layout.Col span='6'>
									<Form.Item
										label='Genotype'
										prop='genoType'
										className={`double-asterisk ${
											errors.mutationInfo &&
											'errorMSGMargin'
										}`}
									>
										<Input
											value={form.genoType}
											placeholder='geno type'
											onChange={(value) =>
												handleChange('genoType', value)
											}
											trim={true}
										/>
									</Form.Item>
								</Layout.Col>
								<Layout.Col
									span='24'
									className={
										!errors.mutationInfo
											? 'hide'
											: 'errorMessage'
									}
								>
									{errors.mutationInfo}
								</Layout.Col>
							</Layout.Row>

							<Layout.Row>
								<Layout.Col
									span='24'
									className='risk-score-header'
								>
									risk score
								</Layout.Col>
							</Layout.Row>

							<Layout.Row gutter='20'>
								<Layout.Col span='12'>
									<Layout.Row gutter='20'>
										<Layout.Col span='12'>
											<Form.Item
												label="Patient's risk score"
												prop='riskScore'
												className='double-asterisk'
											>
												<Input
													value={form.riskScore}
													placeholder='1.2, 2.3, 4.2, etc'
													onChange={(value) =>
														handleChange(
															'riskScore',
															value
														)
													}
													trim={true}
												/>
											</Form.Item>
										</Layout.Col>
										<Layout.Col span='12'>
											<Form.Item
												label='Normal range'
												prop='riskScoreNormalRange'
											>
												<Input
													value={
														form.riskScoreNormalRange
													}
													placeholder='0-1.5'
													onChange={(value) =>
														handleChange(
															'riskScoreNormalRange',
															value
														)
													}
													trim={true}
												/>
											</Form.Item>
										</Layout.Col>
									</Layout.Row>

									<Layout.Row>
										<Layout.Col
											span='24'
											className='interprete-header'
										>
											Interpretation
										</Layout.Col>
									</Layout.Row>

									<Layout.Row gutter='20'>
										<Layout.Col span='12'>
											<Form.Item
												label='Interpretation'
												prop='interpretation'
												className='interpretation'
											>
												<Select
													value={form.interpretation}
													onChange={(value) =>
														handleChange(
															'interpretation',
															value
														)
													}
													placeholder='Select'
												>
													<Select.Option
														label='Select'
														value=''
													/>
													{interpretation.map(
														(item, idx) => {
															return (
																<Select.Option
																	key={idx}
																	label={
																		item.display_name
																	}
																	value={
																		item.key
																	}
																/>
															);
														}
													)}
												</Select>
											</Form.Item>
										</Layout.Col>
										<Layout.Col span='12'>
											<Form.Item
												label='Zygosity'
												prop='zygosity'
												className='zygosity'
											>
												<Radio.Group
													value={form.zygosity}
													onChange={(value) =>
														handleChange(
															'zygosity',
															value
														)
													}
												>
													{zygosity.map(
														(item, idx) => {
															return (
																<Radio
																	key={idx}
																	value={
																		item.key
																	}
																>
																	{
																		item.display_name
																	}
																</Radio>
															);
														}
													)}
												</Radio.Group>
											</Form.Item>
										</Layout.Col>
									</Layout.Row>
								</Layout.Col>
								<Layout.Col span='12'>
									<Form.Item
										label='Comments from Lab about the variant (optional)'
										prop='labComments'
									>
										<Input
											value={form.labComments}
											type='textarea'
											rows={6}
											placeholder='Write something here'
											onChange={(value) =>
												handleChange(
													'labComments',
													value
												)
											}
											className='lab-comments'
										></Input>
									</Form.Item>
								</Layout.Col>
							</Layout.Row>

							<Layout.Row>
								<Layout.Col
									span='24'
									className='additional-info-header'
									style={{
										marginBottom: `${
											toggleAdditionInfo === true
												? '16px'
												: '0'
										}`,
									}}
								>
									additional info
									<a
										className='toggle_icon rotate'
										href='javascript:;'
										onClick={() =>
											setToggleAdditionInfo(
												!toggleAdditionInfo
											)
										}
									>
										<img
											src={imageExpandCollapse}
											alt=''
											style={{
												transform: `${
													toggleAdditionInfo === true
														? 'rotate(90deg)'
														: 'rotate(-90deg)'
												}`,
											}}
										/>
									</a>
								</Layout.Col>
								{!toggleAdditionInfo && (
									<div className='addition-sub-title'>
										ClinVar ID, Haplotype, Clinician
										comments - results specific
									</div>
								)}
							</Layout.Row>

							{toggleAdditionInfo === true && (
								<Fragment>
									<Layout.Row gutter='20'>
										<Layout.Col span='6'>
											<label className='lbl-clinvar-alle'>
												ClinVar ID - Allele ID
											</label>
											<Tooltip
												effect='dark'
												content={
													<a
														href='https://www.ncbi.nlm.nih.gov/clinvar/docs/identifiers/#allele'
														target='_blank'
														rel='noreferrer'
													>
														Click to view more info
													</a>
												}
												placement='top'
												className='tooltip-clinvar'
											>
												<img
													src={moreinfoicon}
													alt='more info'
													className='more-info'
												/>
											</Tooltip>
											<Form.Item prop='clinvarID'>
												<Input
													value={form.clinvarID}
													placeholder='46557'
													onChange={(value) =>
														handleChange(
															'clinvarID',
															value
														)
													}
													trim={true}
												/>
											</Form.Item>
										</Layout.Col>
										<Layout.Col
											span='6'
											style={{ marginTop: '-8px' }}
										>
											<Form.Item
												label='Allele 1 Repeats'
												prop='allele1Repeats'
											>
												<Input
													value={form.allele1Repeats}
													placeholder='465547'
													onChange={(value) =>
														handleChange(
															'allele1Repeats',
															value
														)
													}
													trim={true}
												/>
											</Form.Item>
										</Layout.Col>
										<Layout.Col
											span='6'
											style={{ marginTop: '-8px' }}
										>
											<Form.Item
												label='Allele 2 Repeats'
												prop='allele2Repeats'
											>
												<Input
													value={form.allele2Repeats}
													placeholder='465547'
													onChange={(value) =>
														handleChange(
															'allele2Repeats',
															value
														)
													}
													trim={true}
												/>
											</Form.Item>
										</Layout.Col>
										<Layout.Col
											span='6'
											style={{ marginTop: '-8px' }}
										>
											<Form.Item label='HGVS' prop='hgvs'>
												<Input
													value={form.hgvs}
													placeholder='465547'
													onChange={(value) =>
														handleChange(
															'hgvs',
															value
														)
													}
													trim={true}
												/>
											</Form.Item>
										</Layout.Col>
									</Layout.Row>
									<Layout.Row gutter='20'>
										<Layout.Col
											span='6'
											style={{ marginTop: '-8px' }}
										>
											<Form.Item
												label='Haplotype'
												prop='haploType'
											>
												<Input
													value={form.haploType}
													placeholder='CYP2A6*1B1'
													onChange={(value) =>
														handleChange(
															'haploType',
															value
														)
													}
													trim={true}
												/>
											</Form.Item>
										</Layout.Col>
										<Layout.Col
											span='6'
											style={{ marginTop: '-8px' }}
										>
											<Form.Item
												label='Copy Number'
												prop='copyNumber'
											>
												<Input
													value={form.copyNumber}
													placeholder='465547'
													onChange={(value) =>
														handleChange(
															'copyNumber',
															value
														)
													}
													trim={true}
												/>
											</Form.Item>
										</Layout.Col>
										<Layout.Col
											span='6'
											style={{ marginTop: '-8px' }}
										>
											<Form.Item
												label='Activity Score'
												prop='activityScore'
											>
												<Input
													value={form.activityScore}
													placeholder='465547'
													onChange={(value) =>
														handleChange(
															'activityScore',
															value
														)
													}
													trim={true}
												/>
											</Form.Item>
										</Layout.Col>
										<Layout.Col span='24'>
											<Form.Item
												label='Clinician comments - results specific'
												prop='clinicianComments'
											>
												<Input
													value={
														form.clinicianComments
													}
													type='textarea'
													rows={3}
													placeholder='Write something here'
													onChange={(value) =>
														handleChange(
															'clinicianComments',
															value
														)
													}
												></Input>
											</Form.Item>
										</Layout.Col>
									</Layout.Row>
								</Fragment>
							)}
						</Layout.Col>
					</Layout.Row>
					<Layout.Row type='flex' style={{ marginTop: '20px' }}>
						<Layout.Col offset='7' span='12'>
							<Button
								data-testId='edit-gene-save'
								className='save-button'
								disabled={loading}
								onClick={(e) => handleComplete(e)}
							>
								Save
							</Button>
							<Button
								data-testId='edit-gene-cancel'
								className='cancel-button'
								type='text'
								onClick={() => showCancelGeneDialog()}
							>
								Cancel
							</Button>
						</Layout.Col>
						<Layout.Col offset='2' span='5'>
							<Button
								className='delete-button'
								disabled={loading}
								onClick={() => showDeleteGeneDialog()}
							>
								Delete
							</Button>
						</Layout.Col>
					</Layout.Row>
				</Form>
			</Loading>
			<Dialog
				customClass='gene-result-delete-dlg'
				title='Confirmation'
				size='tiny'
				modal='true'
				closeOnPressEscape='true'
				closeOnClickModal='true'
				showClose='true'
				visible={geneResultDeleteDialog}
				onCancel={() => setGeneResultDeleteDialog(false)}
			>
				<Dialog.Body>
					<span>
						Are you sure you would like to delete this result?
					</span>
				</Dialog.Body>
				<Dialog.Footer className='dialog-footer'>
					<Button onClick={() => setGeneResultDeleteDialog(false)}>
						Cancel
					</Button>
					<Button
						onClick={() => handleDeleteGeneResult()}
						className='delete-button'
					>
						Yes, Delete
					</Button>
				</Dialog.Footer>
			</Dialog>
			<Dialog
				customClass='gene-result-cancel-dlg'
				title={
					<span>
						<img src={warningicon} alt='' className='' />
						Alert
					</span>
				}
				size='tiny'
				modal='true'
				closeOnPressEscape='true'
				closeOnClickModal='true'
				showClose='true'
				visible={geneResultCancelDialog}
				onCancel={() => setGeneResultCancelDialog(false)}
			>
				<Dialog.Body>
					<p>Are you sure you want to exit?</p>
					<p>Any changes you have made will be lost</p>
				</Dialog.Body>
				<Dialog.Footer className='dialog-footer'>
					<Button onClick={() => setGeneResultCancelDialog(false)}>
						Cancel
					</Button>
					<Button onClick={() => isCancel()}>Quit</Button>
				</Dialog.Footer>
			</Dialog>
		</Fragment>
	);
};
export default EditGeneDetails;
