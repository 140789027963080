//React & Redux
import React, { useEffect, useState } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	createEnumsOptions,
	isValidDateStringOREmpty,
} from '../Common/utilsfunctions';

//UI Libraries
import { Button, Loading } from 'gm-element-react';

//Components
import { EncounterSelectField } from '../Common/EncounterSelectField';
import { EncounterNumberInputField } from '../Common/EncounterNumberInputField';
import { EncounterDateField } from '../Common/EncounterDateField';

const datefields = {
	pgx_post_session_indirect_time_date: 1,
};

const EditPGXSegment = (props) => {
	const { cancel, encounter = {}, saveSCPEncounter, visitsschema } = props;

	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setFormData({
			drug_contraindications: _.get(
				encounter,
				['drug_contraindications'],
				''
			),
			drug_dosage_adjustment_recommended: _.get(
				encounter,
				['drug_dosage_adjustment_recommended'],
				''
			),
			drug_interaction: _.get(encounter, ['drug_interaction'], ''),
			no_of_interactions: _.get(encounter, ['no_of_interactions'], ''),
			pgx_post_cap_indirect_time: _.get(
				encounter,
				['pgx_post_cap_indirect_time'],
				''
			),
			pgx_post_session_indirect_time: _.get(
				encounter,
				['pgx_post_session_indirect_time'],
				''
			),
			pgx_post_session_indirect_time_date: _.get(
				encounter,
				['pgx_post_session_indirect_time_date'],
				''
			),
			pgx_pre_session_indirect_time: _.get(
				encounter,
				['pgx_pre_session_indirect_time'],
				''
			),
			pharmd: _.get(encounter, ['pharmd'], ''),
		});
	}, []);

	const validateForm = (formdata) => {
		const errors = {};
		if (
			formdata['drug_interaction'] == 'yes' &&
			_.get(formdata, ['no_of_interactions', 'length'], 0) == 0
		) {
			errors['no_of_interactions'] = '# of Interaction is required';
		}
		if (
			!_.isNil(formdata['pgx_post_session_indirect_time']) &&
			!_.isInteger(formdata['pgx_post_session_indirect_time'])
		) {
			errors['pgx_post_session_indirect_time'] =
				'Please enter a non-decimal number';
		}
		if (
			!_.isNil(formdata['pgx_post_cap_indirect_time']) &&
			!_.isInteger(formdata['pgx_post_cap_indirect_time'])
		) {
			errors['pgx_post_cap_indirect_time'] =
				'Please enter a non-decimal number';
		}
		if (
			!_.isNil(formdata['pgx_post_session_indirect_time_date']) &&
			!isValidDateStringOREmpty(
				formdata['pgx_post_session_indirect_time_date']
			)
		) {
			errors['pgx_post_session_indirect_time_date'] = 'Invalid date';
		}
		if (
			!_.isNil(formdata['pgx_pre_session_indirect_time']) &&
			!_.isInteger(formdata['pgx_pre_session_indirect_time'])
		) {
			errors['pgx_pre_session_indirect_time'] =
				'Please enter a non-decimal number';
		}
		return errors;
	};

	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		let stateErrors = {};
		stateData[key] = value;
		if (!(key in datefields)) {
			const _stateData = { ...stateData };
			stateErrors = validateForm(_stateData);
		}
		setErrors(stateErrors);
		setFormData(stateData);
	};

	const handleDateFieldValidate = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		const errors = validateForm(stateData);
		setErrors(errors);
		setFormData(stateData);
	};

	const saveForm = () => {
		const errors = validateForm(formData);
		if (_.isEmpty(errors)) {
			const updatedformdata = _.mapValues(
				formData,
				function (value, key) {
					if (value == 'empty') return '';
					if (
						(key in
							{
								pgx_post_session_indirect_time: 1,
								pgx_post_cap_indirect_time: 1,
								pgx_pre_session_indirect_time: 1,
							} &&
							value === '') ||
						value == undefined
					) {
						return null;
					}
					return value;
				}
			);
			setLoading(true);
			saveSCPEncounter(encounter, updatedformdata)
				.then(() => {
					setTimeout(() => {
						setLoading(false);
						cancel();
					}, 1000);
				})
				.catch(() => {
					setLoading(false);
				});
		} else {
			setErrors(errors);
			setLoading(false);
		}
	};

	return (
		<Loading loading={loading}>
			<div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								PharmD Identification
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Drug Interaction
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								# of Interaction(s)
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Drug/Gene Interaction
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Drug Dos Adj Recommended
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(formData, ['pharmd'], '')}
									name='pharmd'
									options={createEnumsOptions(
										visitsschema,
										['pharmd'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('pharmd', value)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['drug_interaction'],
										''
									)}
									name='drug_interaction'
									options={createEnumsOptions(
										visitsschema,
										['drug_interaction'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('drug_interaction', value)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['no_of_interactions'],
										''
									)}
									name='no_of_interactions'
									options={createEnumsOptions(
										visitsschema,
										['no_of_interactions'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'no_of_interactions',
											value
										)
									}
									error={_.get(
										errors,
										['no_of_interactions'],
										''
									)}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['drug_contraindications'],
										''
									)}
									name='drug_contraindications'
									options={createEnumsOptions(
										visitsschema,
										['drug_contraindications'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'drug_contraindications',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['drug_dosage_adjustment_recommended'],
										''
									)}
									name='drug_dosage_adjustment_recommended'
									options={createEnumsOptions(
										visitsschema,
										['drug_dosage_adjustment_recommended'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'drug_dosage_adjustment_recommended',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								PGx Pre-Session Indirect Time (minutes)
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								PGx Post-Session Indirect Time (minutes)
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								PGx Post-Session Indirect Time Date
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								PGx Post CAP Indirect Time (minutes)
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								<EncounterNumberInputField
									defaultValue={_.get(
										formData,
										['pgx_pre_session_indirect_time'],
										null
									)}
									value={_.get(
										formData,
										['pgx_pre_session_indirect_time'],
										null
									)}
									name='pgx_pre_session_indirect_time'
									onChange={(value) =>
										onFormChange(
											'pgx_pre_session_indirect_time',
											value
										)
									}
									min='1'
									error={_.get(
										errors,
										['pgx_pre_session_indirect_time'],
										''
									)}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								<EncounterNumberInputField
									defaultValue={_.get(
										formData,
										['pgx_post_session_indirect_time'],
										null
									)}
									value={_.get(
										formData,
										['pgx_post_session_indirect_time'],
										null
									)}
									name='pgx_post_session_indirect_time'
									onChange={(value) =>
										onFormChange(
											'pgx_post_session_indirect_time',
											value
										)
									}
									min='1'
									error={_.get(
										errors,
										['pgx_post_session_indirect_time'],
										''
									)}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section '>
								<div className='encounter-field-value'>
									<EncounterDateField
										value={_.get(formData, [
											'pgx_post_session_indirect_time_date',
										])}
										name='pgx_post_session_indirect_time_date'
										onBlur={(value) =>
											handleDateFieldValidate(
												'pgx_post_session_indirect_time_date',
												value
											)
										}
										onChange={(value) =>
											onFormChange(
												'pgx_post_session_indirect_time_date',
												value
											)
										}
										min='1'
										placeholder='Date'
										error={_.get(
											errors,
											[
												'pgx_post_session_indirect_time_date',
											],
											''
										)}
									/>
								</div>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								<EncounterNumberInputField
									defaultValue={_.get(
										formData,
										['pgx_post_cap_indirect_time'],
										null
									)}
									value={_.get(
										formData,
										['pgx_post_cap_indirect_time'],
										null
									)}
									name='pgx_post_cap_indirect_time'
									onChange={(value) =>
										onFormChange(
											'pgx_post_cap_indirect_time',
											value
										)
									}
									min='1'
									error={_.get(
										errors,
										['pgx_post_cap_indirect_time'],
										''
									)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-footer-container'>
					<div className='cancel-btn-link'>
						<Button onClick={cancel}>Cancel</Button>
					</div>
					<div className='save-btn-link'>
						<Button style={{ float: 'right' }} onClick={saveForm}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</Loading>
	);
};

export default EditPGXSegment;
