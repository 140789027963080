import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPatientTags } from '../../../actions/patients';
import CheckboxField from '../FormFields/CheckboxField';
import Dropdown from '../FormFields/Dropdown';

import './Tags.scss';
import _ from 'lodash';

const tagOrder = [
	'priority_patient',
	'language_line_support',
	'hearing_impaired',
	'speech_impaired',
	'vision_impaired',
	'deceased',
	'marketing_opt-out',
	'research_opt-out',
	'no_further_escalation',
	'discharged',
	'patient_story',
	'financial_assistance',
	'financial_assistance_per_visit',
];

/**
 * Sort tags according to `tagOrder`
 * @param tags {Array<{ name: string }>}
 * @returns {Array<{ name: string }>}
 */
function sortTags(tags) {
	return tags.sort(
		(a, b) => tagOrder.indexOf(a.name) - tagOrder.indexOf(b.name)
	);
}

/**
 * Tags section for create patient page
 * @param {Object} props
 * @param {Record<string, boolean | Record<string, string>>} props.values
 * @param {(field: string, value: any) => void} props.setFieldValue
 * @param {Record<number, Record<string, string>>} props.errors
 * @param {Record<string, boolean | Record<string, string[]>> } props.values
 * @param {((isOpen: boolean) => React.JSX.Element) | undefined} props.children
 * @returns {React.JSX.Element}
 */
export function Tags({ errors, setFieldValue, touched, values, children }) {
	const { patientTagsEnum } = useSelector((store) => ({
		patientTagsEnum: store.patientTags?.patientTags ?? [],
	}));

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getPatientTags());
	}, []);

	const [showAllTags, setShowAllTags] = useState(false);

	const sortedEnums = sortTags([...patientTagsEnum]);

	return (
		<div className='tags-component'>
			<div className='tags column-layout-3'>
				{(showAllTags ? sortedEnums : sortedEnums.slice(0, 3)).map(
					(item) => (
						<div
							className={classnames('tag', {
								selected: !!values[item.id],
							})}
							key={item.id}
						>
							<CheckboxField
								label={item.display_name}
								name={item.name}
								value={!!values[item.id]}
								onChange={(checked) => {
									let tagValue = checked;
									// Need to provide an object for complex tag
									// values to make validation work
									if (item.tag_properties && checked) {
										tagValue = Object.keys(
											item.tag_properties
										).reduce((acc, p) => {
											acc[p] = [];
											return acc;
										}, {});
									}
									setFieldValue('tags', {
										...values,
										[item.id]: tagValue,
									});
								}}
							/>
							{values[item.id] &&
								item.tag_properties &&
								Object.entries(item.tag_properties).map(
									([prop, options]) => {
										// Check if tag is in use and populate in dropdown
										const assignedTagProperty = _.get(
											values[item.id],
											prop
										);

										const dropdownOptions = Array.isArray(
											options.values
										)
											? options.values
													.filter(
														(o) =>
															o.value ==
																assignedTagProperty ||
															o.in_use
													)
													.map((o) => ({
														value: o.value,
														label: o.value,
													})) // New format
											: options.map((o) => ({
													value: o,
													label: o,
												})); // Old format

										return (
											<Dropdown
												key={prop}
												clearable
												required
												label={`${prop[0].toUpperCase()}${prop.slice(1)}`}
												options={dropdownOptions}
												error={
													touched?.[item.id]?.[
														prop
													] &&
													errors?.[item.id]?.[prop]
												}
												onChange={(value) => {
													setFieldValue('tags', {
														...values,
														[item.id]: {
															[prop]:
																typeof value ===
																'string'
																	? [value]
																	: value,
														},
													});
												}}
												value={
													!options.allow_multiselect &&
													values[item.id][prop]
														? values[item.id][
																prop
															][0]
														: values[item.id][prop]
												}
												multiple={
													options.allow_multiselect
												}
											/>
										);
									}
								)}
						</div>
					)
				)}
				{children?.(showAllTags)}
			</div>
			<span
				className='purpleText show-hide-button'
				onClick={() => setShowAllTags(!showAllTags)}
			>
				{showAllTags ? (
					<>
						Hide Additional Flags
						<i className='material-icons switchArrow'>
							expand_less
						</i>
					</>
				) : (
					<>
						Show Additional Flags
						<i className='material-icons switchArrow'>
							expand_more
						</i>
					</>
				)}
			</span>
		</div>
	);
}
