//React & Redux
import React, { useEffect, useState } from 'react';

//Hooks
import { usePrevious } from '../../hooks/usePrevious';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Styling
import './externalProviderModal.css';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import AddEditExternalProviderTitleRow from './AddEditExternalProviderTitleRow.js';
import AddEditExternalProviderBody from './AddEditExternalProviderBody.js';
import AddEditExternalProviderButtonRow from './AddEditExternalProviderButtonRow.js';

//Utils
import {
	states_list,
	requiredFieldsFilled,
	trimPayload,
	contactMethods,
	fullFieldsValidation,
} from './../../utils.js';
import {
	createEnumsOptions,
	createStateEnumsOptions,
} from '../Patients/V2/Encounters/Common/utilsfunctions';

const AddEditExternalProviderModal = (props) => {
	const {
		createdProviderError,
		createProviderFunc,
		creatingProvider,
		editingProvider = false,
		editProviderData = {},
		hideProviderModal,
		providerCredentials = [],
		providerSpecialties = [],
		setExternalSubmit,
		updatedProviderError,
		updateProviderFunc,
		updatingProvider,
	} = props;

	const [errors, setErrors] = useState({});
	const [showAdditionalFields, setShowAdditionalFields] = useState(false);
	const [showAdditionalFieldsText, setShowAdditionalFieldsText] =
		useState(false);
	const [canSave, setCanSave] = useState(false);
	const [loading, setLoading] = useState(false);
	const [phoneFieldFocus, setPhoneFieldFocus] = useState(false);
	const [formData, setFormData] = useState({});
	const [changeData, setChangeData] = useState(new Set());
	const [ctaMade, setCtaMade] = useState(false);

	const prevUpdatedProviderError = usePrevious(updatedProviderError);
	const prevCreatedProviderError = usePrevious(createdProviderError);

	const requiredFields = [
		'firstName',
		'lastName',
		'credentials',
		'city',
		'state',
	];

	useEffect(() => {
		if (editingProvider) {
			setFormData({
				firstName: editProviderData?.first_name || '',
				lastName: editProviderData?.last_name || '',
				credentials: editProviderData?.title || null,
				specialty: editProviderData?.specialties?.[0] || null,
				officePhone: editProviderData?.phones?.office || '',
				officeFax: editProviderData?.phones?.fax_office || '',
				email: editProviderData?.email || '',
				contactMethod:
					editProviderData?.preferred_contact_method || null,
				address1:
					editProviderData?.address?.office?.address_line1 || '',
				address2:
					editProviderData?.address?.office?.address_line2 || '',
				city: editProviderData?.address?.office?.city || '',
				state: editProviderData?.address?.office?.state || null,
				zip: editProviderData?.address?.office?.zip || '',
				mobilePhone: editProviderData?.phones?.mobile || '',
				providerNPI: editProviderData?.npi || '',
				orgName: editProviderData?.organization_name || '',
				orgPhone: editProviderData?.phones?.organization || '',
				orgFax: editProviderData?.phones?.fax_organization || '',
				orgAddress1:
					editProviderData?.address?.organization?.address_line1 ||
					'',
				orgAddress2:
					editProviderData?.address?.organization?.address_line2 ||
					'',
				orgCity: editProviderData?.address?.organization?.city || '',
				orgState:
					editProviderData?.address?.organization?.state || null,
				orgZip: editProviderData?.address?.organization?.zip || '',
				notes: editProviderData?.notes || '',
			});
		}
	}, []);

	useEffect(() => {
		canSaveFunc();
	}, [formData]);

	useEffect(() => {
		if (
			(!prevUpdatedProviderError && updatedProviderError) ||
			(!prevCreatedProviderError && createdProviderError)
		) {
			if (ctaMade) mapErrors();
		}
	}, [
		updatedProviderError,
		createdProviderError,
		prevUpdatedProviderError,
		prevCreatedProviderError,
	]);

	useEffect(() => {
		if (setExternalSubmit) {
			submitExternally(canSave);
		}
	}, [canSave, formData]);

	useEffect(() => {
		if (!phoneFieldFocus && setExternalSubmit) {
			const requiredFields = gatherRequiredFields() || [];
			const canSave = requiredFieldsFilled(requiredFields);
			if (canSave) canSaveFunc();
		}
	}, [phoneFieldFocus]);

	const constructFields = () => {
		return [
			{
				label: 'First Name',
				header: 'firstName',
				value: formData.firstName || null,
				type: 'text',
				required: true,
				quartersWidth: 2,
			},
			{
				label: 'Last Name',
				header: 'lastName',
				value: formData.lastName || null,
				type: 'text',
				required: true,
				quartersWidth: 2,
				marginLeft: true,
			},
			{
				label: 'Credentials',
				header: 'credentials',
				value: formData.credentials || null,
				type: 'singleSelect',
				options: createEnumsOptions(
					props,
					['providerCredentials'],
					'name',
					'display_name',
					'name'
				),
				placeholder: 'Select',
				required: true,
				quartersWidth: 1,
				typeahead: true,
				clearable: true,
			},
			{
				label: 'Specialty',
				header: 'specialty',
				value: formData.specialty || null,
				type: 'singleSelect',
				options: createEnumsOptions(
					props,
					['providerSpecialties'],
					'name',
					'display_name',
					'name'
				),
				recommended: true,
				quartersWidth: 3,
				marginLeft: true,
				typeahead: true,
				clearable: true,
			},
			{
				label: 'Office Phone Number',
				header: 'officePhone',
				value: formData.officePhone || null,
				type: 'textMask',
				maskType: 'phone',
				placeholder: '+1 (123) 456-7890',
				recommended: true,
				quartersWidth: 2,
			},
			{
				label: 'Office Fax Number',
				header: 'officeFax',
				value: formData.officeFax || null,
				type: 'textMask',
				maskType: 'phone',
				placeholder: '+1 (123) 456-7890',
				recommended: true,
				quartersWidth: 2,
				marginLeft: true,
			},
			{
				label: 'Email Address',
				header: 'email',
				value: formData.email || null,
				type: 'text',
				recommended: true,
				quartersWidth: 4,
			},
			{
				label: 'Preferred method of contact',
				header: 'contactMethod',
				value: formData.contactMethod || null,
				type: 'singleSelectPill',
				options: contactMethods,
				recommended: true,
				quartersWidth: 4,
			},
			{
				label: 'Address 1',
				header: 'address1',
				value: formData.address1 || null,
				type: 'text',
				quartersWidth: 4,
			},
			{
				label: 'Address 2',
				header: 'address2',
				value: formData.address2 || null,
				type: 'text',
				quartersWidth: 4,
			},
			{
				label: 'City',
				header: 'city',
				value: formData.city || null,
				type: 'text',
				required: true,
				quartersWidth: 2,
			},
			{
				label: 'State',
				header: 'state',
				value: formData.state || null,
				type: 'singleSelect',
				options: createStateEnumsOptions(
					states_list || [],
					'key',
					'value',
					'key'
				),
				placeholder: 'CA',
				required: true,
				quartersWidth: 1,
				marginLeft: true,
				typeahead: true,
				clearable: true,
				keyOverride: true,
			},
			{
				label: 'Zipcode',
				header: 'zip',
				value: formData.zip || null,
				type: 'text',
				placeholder: '12345',
				quartersWidth: 1,
				marginLeft: true,
			},
		];
	};

	const constructAdditionalFields = () => {
		return [
			{
				label: 'Mobile Phone Number',
				header: 'mobilePhone',
				value: formData.mobilePhone || '',
				type: 'textMask',
				maskType: 'phone',
				placeholder: '+1 (123) 456-7890',
				quartersWidth: 2,
			},
			{
				label: 'Provider NPI',
				header: 'providerNPI',
				value: formData.providerNPI || '',
				type: 'text',
				placeholder: 'NPI Number',
				quartersWidth: 2,
				marginLeft: true,
			},
			{
				label: 'Organization Name',
				header: 'orgName',
				value: formData.orgName || '',
				type: 'text',
				quartersWidth: 4,
			},
			{
				label: 'Org. Phone Number',
				header: 'orgPhone',
				value: formData.orgPhone || '',
				type: 'textMask',
				maskType: 'phone',
				placeholder: '+1 (123) 456-7890',
				quartersWidth: 2,
			},
			{
				label: 'Org. Fax Number',
				header: 'orgFax',
				value: formData.orgFax || '',
				type: 'textMask',
				maskType: 'phone',
				placeholder: '+1 (123) 456-7890',
				quartersWidth: 2,
				marginLeft: true,
			},
			{
				label: 'Org. Address 1',
				header: 'orgAddress1',
				value: formData.orgAddress1 || '',
				type: 'text',
				placeholder: 'Address 1',
				quartersWidth: 4,
			},
			{
				label: 'Org. Address 2',
				header: 'orgAddress2',
				value: formData.orgAddress2 || '',
				type: 'text',
				placeholder: 'Address 2',
				quartersWidth: 4,
			},
			{
				label: 'Org. City',
				header: 'orgCity',
				value: formData.orgCity || '',
				type: 'text',
				placeholder: 'City',
				quartersWidth: 2,
			},
			{
				label: 'Org. State',
				header: 'orgState',
				value: formData.orgState || null,
				type: 'singleSelect',
				options: createStateEnumsOptions(
					states_list || [],
					'key',
					'value',
					'key'
				),
				placeholder: 'CA',
				quartersWidth: 1,
				marginLeft: true,
				typeahead: true,
				clearable: true,
				keyOverride: true,
			},
			{
				label: 'Org. Zipcode',
				header: 'orgZip',
				value: formData.orgZip || null,
				type: 'text',
				placeholder: '12345',
				quartersWidth: 1,
				marginLeft: true,
			},
			{
				label: 'Notes',
				header: 'notes',
				value: formData.notes || '',
				type: 'textArea',
				quartersWidth: 4,
			},
		];
	};

	const mapErrors = () => {
		const errorFields = {};
		const apiErrors = {
			...updatedProviderError,
			...createdProviderError,
		};
		for (const field in apiErrors) {
			const message = get(Object.values(apiErrors[field]), '[0]', '');
			if (message && typeof message === 'string') {
				let fieldName;
				switch (field) {
					case 'email':
						fieldName = 'email';
						break;
					case 'first_name':
						fieldName = 'firstName';
						break;
					case 'last_name':
						fieldName = 'lastName';
						break;
					case 'address_office_address_line1':
						fieldName = 'address1';
						break;
					case 'address_office_address_line2':
						fieldName = 'address2';
						break;
					case 'address_office_city':
						fieldName = 'city';
						break;
					case 'address_office_state':
						fieldName = 'state';
						break;
					case 'address_office_zip':
						fieldName = 'zip';
						break;
					case 'address_organization_address_line1':
						fieldName = 'orgAddress1';
						break;
					case 'address_organization_address_line2':
						fieldName = 'orgAddress2';
						break;
					case 'address_organization_city':
						fieldName = 'orgCity';
						break;
					case 'address_organization_state':
						fieldName = 'orgState';
						break;
					case 'address_organization_zip':
						fieldName = 'orgZip';
						break;
					case 'phones_fax_office':
						fieldName = 'officeFax';
						break;
					case 'phones_fax_organization':
						fieldName = 'orgFax';
						break;
					case 'phones_mobile':
						fieldName = 'mobilePhone';
						break;
					case 'phones_office':
						fieldName = 'officePhone';
						break;
					case 'phones_organization':
						fieldName = 'orgPhone';
						break;
					case 'npi':
						fieldName = 'providerNPI';
						break;
					case 'title':
						fieldName = 'credentials';
						break;
					default:
						fieldName = field;
						break;
				}
				errorFields[fieldName] = message;
			}
		}
		setErrors(errorFields);
	};

	const toggleFieldSwitch = (e) => {
		if (!showAdditionalFields) toggleFieldSwitchOn();
		else toggleFieldSwitchOff();
	};

	const toggleFieldSwitchOn = () => {
		setShowAdditionalFields(true);
		setShowAdditionalFieldsText(true);
	};

	const toggleFieldSwitchOff = () => {
		setShowAdditionalFields(false);
		setShowAdditionalFieldsText(false);
	};

	const fieldChange = (header, newValue) => {
		if (header) {
			setFormData((formData) => ({ ...formData, [header]: newValue }));
			setChangeData((changeData) => {
				if (!changeData.has(header)) changeData.add(header);
				return changeData;
			});
		}
	};

	const canSaveFunc = () => {
		const requiredFields = gatherRequiredFields() || [];
		const canSave = requiredFieldsFilled(requiredFields);
		setCanSave(canSave);
	};

	const submitExternally = (canSave) => {
		const formErrors = errorCheck();
		if (!hasErrors(formErrors)) {
			setCtaMade(true);
			setExternalSubmit(canSave, constructPayload());
		} else {
			setExternalSubmit(false, {});
		}
	};

	const errorCheck = () => {
		const phoneNumbers = gatherPhoneNumbers() || [];
		const emails = gatherEmails() || [];
		const names = gatherNames() || [];
		const addressLines = gatherAddressLines() || [];
		const otherNumberFields = gatherOtherNumberFields() || [];
		const allFields = [
			...phoneNumbers,
			...emails,
			...names,
			...addressLines,
			...otherNumberFields,
		];
		const errorFields = fullFieldsValidation(allFields);
		setErrors(errorFields);
		return errorFields;
	};

	const hasErrors = (inputError) => {
		return get(Object.keys(inputError || errors), 'length', 0);
	};

	const clearErrors = () => {
		setErrors({});
	};

	const gatherRequiredFields = () => {
		return requiredFields.map((el) => {
			return get(formData, `${el}`, null);
		});
	};

	const gatherPhoneNumbers = () => {
		return [
			{
				type: 'phoneNumber',
				value: formData?.officePhone || null,
				header: 'officePhone',
				subType: null,
			},
			{
				type: 'phoneNumber',
				value: formData?.officeFax || null,
				header: 'officeFax',
				subType: 'fax',
			},
			{
				type: 'phoneNumber',
				value: formData?.mobilePhone || null,
				header: 'mobilePhone',
				subType: null,
			},
			{
				type: 'phoneNumber',
				value: formData?.orgPhone || null,
				header: 'orgPhone',
				subType: null,
			},
			{
				type: 'phoneNumber',
				value: formData?.orgFax || null,
				header: 'orgFax',
				subType: 'fax',
			},
		].filter((el) => get(el, 'value', null));
	};

	const gatherEmails = () => {
		return [
			{
				type: 'email',
				value: formData?.email || null,
				header: 'email',
				subType: null,
			},
		].filter((el) => get(el, 'value', null));
	};

	const gatherNames = () => {
		return [
			{
				type: 'name',
				value: formData?.firstName || null,
				header: 'firstName',
				subType: 'firstName',
			},
			{
				type: 'name',
				value: formData?.lastName || null,
				header: 'lastName',
				subType: 'lastName',
			},
			{
				type: 'name',
				value: formData?.orgName || null,
				header: 'orgName',
				subType: null,
			},
		].filter((el) => get(el, 'value', null));
	};

	const gatherAddressLines = () => {
		return [
			{
				type: 'address',
				value: formData?.address1 || null,
				header: 'address1',
				subType: 'addressLine',
			},
			{
				type: 'address',
				value: formData?.address2 || null,
				header: 'address2',
				subType: 'addressLine',
			},
			{
				type: 'address',
				value: formData?.city || null,
				header: 'city',
				subType: 'city',
			},
			{
				type: 'address',
				value: formData?.zip || null,
				header: 'zip',
				subType: 'zip',
			},
			{
				type: 'address',
				value: formData?.orgAddress1 || null,
				header: 'orgAddress1',
				subType: 'addressLine',
			},
			{
				type: 'address',
				value: formData?.orgAddress2 || null,
				header: 'orgAddress2',
				subType: 'addressLine',
			},
			{
				type: 'address',
				value: formData?.orgCity || null,
				header: 'orgCity',
				subType: 'city',
			},
			{
				type: 'address',
				value: formData?.orgZip || null,
				header: 'orgZip',
				subType: 'zip',
			},
		].filter((el) => get(el, 'value', null));
	};

	const gatherOtherNumberFields = () => {
		return [
			{
				type: 'number',
				value: formData?.providerNPI || null,
				header: 'providerNPI',
				length: 10,
				subType: 'NPI',
			},
		].filter((el) => get(el, 'value', null));
	};

	const providerCTA = (action) => {
		const formErrors = errorCheck();
		if (!hasErrors(formErrors)) {
			if (action === 'edit') editProvider();
			else if (action === 'save') saveProvider();

			setCtaMade(true);
		}
	};

	const editProvider = () => {
		const payload = constructPayload();
		const providerUuid = editProviderData?.uuid;
		if (payload && providerUuid) {
			updateProviderFunc(providerUuid, payload);
		}
		clearErrors();
	};

	const saveProvider = () => {
		const payload = constructPayload();
		if (payload) {
			createProviderFunc(payload);
		}
		clearErrors();
	};

	const constructPayload = () => {
		let payload = {
			...payloadRequiredFields(),
			...payloadPhoneNumbers(),
			...payloadAddressFields(),
			...payloadOtherFields(),
			is_gmi_provider: false,
			is_referring_provider: true,
		};
		payload = trimPayload(payload);
		return payload;
	};

	const payloadRequiredFields = () => {
		const requiredFieldsPayload = {
			first_name: formData?.firstName || '',
			last_name: formData?.lastName || '',
			title: formData?.credentials || '',
		};
		return requiredFieldsPayload;
	};

	const payloadPhoneNumbers = () => {
		const phonePayload = {};

		const officePhone = formData?.officePhone || null;
		const officeFax = formData?.officeFax || null;
		const mobilePhone = formData?.mobilePhone || null;
		const orgPhone = formData?.orgPhone || null;
		const orgFax = formData?.orgFax || null;

		if (officePhone || (changeData.has('officePhone') && editingProvider))
			phonePayload.office = officePhone;

		if (officeFax || (changeData.has('officeFax') && editingProvider))
			phonePayload.fax_office = officeFax;

		if (mobilePhone || (changeData.has('mobilePhone') && editingProvider))
			phonePayload.mobile = mobilePhone;

		if (orgPhone || (changeData.has('orgPhone') && editingProvider))
			phonePayload.organization = orgPhone;

		if (orgFax || (changeData.has('orgFax') && editingProvider))
			phonePayload.fax_organization = orgFax;

		if (get(Object.keys(phonePayload), 'length', 0) > 0) {
			return {
				phones: phonePayload,
			};
		} else {
			return {};
		}
	};

	const payloadAddressFields = () => {
		const addressPayload = {};

		const officeAddress1 = formData?.address1 || null;
		const officeAddress2 = formData?.address2 || null;
		const officeCity = formData?.city || null;
		const officeState = formData?.state || null;
		const officeZip = formData?.zip || null;

		if (officeCity && officeState) {
			const officeAddressValue = {};
			officeAddressValue.city = officeCity;
			officeAddressValue.state = officeState;
			if (
				officeAddress1 ||
				(changeData.has('address1') && editingProvider)
			)
				officeAddressValue.address_line1 = officeAddress1;
			if (
				officeAddress2 ||
				(changeData.has('address2') && editingProvider)
			)
				officeAddressValue.address_line2 = officeAddress2;
			if (officeZip || (changeData.has('zip') && editingProvider))
				officeAddressValue.zip = officeZip;
			addressPayload.office = officeAddressValue;
		}

		const orgAddress1 = formData?.orgAddress1 || null;
		const orgAddress2 = formData?.orgAddress2 || null;
		const orgCity = formData?.orgCity || null;
		const orgState = formData?.orgState || null;
		const orgZip = formData?.orgZip || null;
		const allOrgAddress = [
			orgAddress1,
			orgAddress2,
			orgCity,
			orgState,
			orgZip,
		];

		if (allOrgAddress.some((el) => el)) {
			const orgAddressValue = {};
			if (
				orgAddress1 ||
				(changeData.has('orgAddress1') && editingProvider)
			)
				orgAddressValue.address_line1 = orgAddress1;
			if (
				orgAddress2 ||
				(changeData.has('orgAddress2') && editingProvider)
			)
				orgAddressValue.address_line2 = orgAddress2;
			if (orgCity || (changeData.has('orgCity') && editingProvider))
				orgAddressValue.city = orgCity;
			if (orgState || (changeData.has('orgState') && editingProvider))
				orgAddressValue.state = orgState;
			if (orgZip || (changeData.has('orgZip') && editingProvider))
				orgAddressValue.zip = orgZip;
			addressPayload.organization = orgAddressValue;
		}

		if (get(Object.keys(addressPayload), 'length', 0) > 0) {
			return {
				address: addressPayload,
			};
		} else {
			return {};
		}
	};

	const payloadOtherFields = () => {
		const otherFieldsPayload = {};

		const specialty = formData?.specialty || null;
		const email = formData?.email || null;
		const contactMethod = formData?.contactMethod || null;
		const providerNPI = formData?.providerNPI || null;
		const orgName = formData?.orgName || null;
		const notes = formData?.notes || null;

		if (specialty || (changeData.has('specialty') && editingProvider))
			otherFieldsPayload.specialties = [specialty];
		if (email || (changeData.has('email') && editingProvider))
			otherFieldsPayload.email = email;
		if (
			contactMethod ||
			(changeData.has('contactMethod') && editingProvider)
		)
			otherFieldsPayload.preferred_contact_method = contactMethod;
		if (
			(providerNPI ||
				(changeData.has('providerNPI') && editingProvider)) &&
			requiredFieldsFilled([providerNPI])
		)
			otherFieldsPayload.npi = providerNPI;
		if (orgName || (changeData.has('orgName') && editingProvider))
			otherFieldsPayload.organization_name = orgName;
		if (
			(notes || (changeData.has('notes') && editingProvider)) &&
			requiredFieldsFilled([notes])
		)
			otherFieldsPayload.notes = notes;

		return otherFieldsPayload;
	};

	const loadingModal = loading || updatingProvider || creatingProvider;
	if (loadingModal) {
		return (
			<div className='fullModalBackgroundFlex'>
				<div
					className='externalProviderModalContainer'
					style={{ height: 'min(760px, 100vh)' }}
				>
					<Loading
						loading={true}
						className='externalProviderModalLoading'
					></Loading>
				</div>
			</div>
		);
	} else {
		return (
			<div className='fullModalBackgroundFlex'>
				<div className='externalProviderModalContainer'>
					<AddEditExternalProviderTitleRow
						hideProviderModal={hideProviderModal}
						editingProvider={editingProvider}
					/>
					<AddEditExternalProviderBody
						fieldsJSON={constructFields()}
						errors={errors}
						additionalFieldsJSON={constructAdditionalFields()}
						showAdditionalFields={showAdditionalFields}
						showAdditionalFieldsText={showAdditionalFieldsText}
						toggleFieldSwitch={toggleFieldSwitch}
						fieldChange={fieldChange}
						editingProvider={editingProvider}
						setPhoneFieldFocus={setPhoneFieldFocus}
					/>
					<AddEditExternalProviderButtonRow
						{...{
							editingProvider,
							canSave,
							providerCTA,
							editProvider,
							saveProvider,
						}}
					/>
				</div>
			</div>
		);
	}
};

export default AddEditExternalProviderModal;
