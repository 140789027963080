import React from 'react';

import get from 'lodash/get';

import moment from 'moment';

import CreateOutreachModalTopRow from './CreateOutreachModalTopRow.js';
import PatientDetailBox from './PatientDetailBox.js';
import SelectOutcome from './SelectOutcome.js';
import OutreachInputs from './OutreachInputs.js';

import './PatientCreateOutreach.css';

import { Loading } from 'gm-element-react';

/**
 * @typedef {Object} Props
 * @property {boolean} props.addOutreachLoading
 * @property {string | null} props.addedOutreachError
 * @property {() => void} props.close
 * @property {{}[]} props.contactMethods
 * @property {{}[]} props.contactOutcomes
 * @property {(message: string, opts: any) => void} props.enqueueSnackbar
 * @property {{}[]} props.noOutreachReasons
 * @property {{}[]} props.outreachEnums
 * @property {{}} props.patient
 * @property {() => void} props.patientCreateAddOutreach
 * @property {string} props.patientCreateAddOutreachDateTime
 * @property {} props.patientCreateAddOutreachNotes
 * @property {() => void} props.patientCreateAddOutreachOutcome
 * @property {() => void} props.patientCreateDoNotOutreachDateTime
 * @property {() => void} props.patientCreateDoNotOutreachReason
 * @property {() => void} props.patientCreateRedirectToScheduling
 * @property {() => void} props.patientCreateSetDoNotOutreach
 * @property {() => void} props.setValue
 * @property {() => void} props.settingDoNotOutreach
 *
 */

/**
 * @extends React.Component<Props>
 */
class PatientCreateOutreachModal extends React.Component {
	/**
	 * @param {Props} props
	 */
	constructor(props) {
		super(props);
		this.state = {
			currentStage: 'selectOutcome',
			addOutreachHeader: 'Add Outreach',
			doNotOutreachHeader: 'Mark As "Do Not Outreach"',
			addOutreachFields: [
				{
					header: 'Contact Date & Time',
					required: true,
					type: 'dateTime',
					placeholder: 'Select time',
					stateVar: 'patientCreateAddOutreachDateTime',
				},
				{
					header: 'Outcome',
					required: true,
					type: 'select',
					placeholder: 'Select outcome',
					stateVar: 'patientCreateAddOutreachOutcome',
				},
			],
			doNotOutreachFields: [
				{
					header: 'Declined Date & Time',
					required: true,
					type: 'dateTime',
					placeholder: 'Select time',
					stateVar: 'patientCreateDoNotOutreachDateTime',
				},
				{
					header: 'Reason For Declining Outreach',
					required: true,
					type: 'select',
					placeholder: 'Select reason',
					stateVar: 'patientCreateDoNotOutreachReason',
				},
			],
			addOutreachButtons: {
				back: {
					text: 'Back',
					class: 'patientCreateOutreachAddBackButton',
				},
				confirm: {
					text: 'Add Outreach',
					class: 'patientCreateOutreachAddConfirmButton',
					disabled:
						'patientCreateOutreachAddConfirmButton patientCreateOutreachAddConfirmButtonDisabled',
				},
			},
			scheduleButtons: {
				back: {
					text: 'Back',
					class: 'patientCreateOutreachAddBackButton',
				},
				confirm: {
					text: 'Go To Scheduling',
					class: 'patientCreateOutreachAddConfirmButton',
					disabled: 'patientCreateOutreachAddConfirmButton',
				},
			},
			doNotOutreachButtons: {
				back: {
					text: 'Back',
					class: 'doNotOutreachAlertButton updatedDoNotOutreachAlertButtonCancel',
				},
				confirm: {
					text: 'Do Not Outreach',
					class: 'doNotOutreachAlertButton updatedDoNotOutreachAlertButtonSave',
					disabled:
						'doNotOutreachAlertButton updatedDoNotOutreachAlertButtonSave updatedDoNotOutreachAlertButtonSaveDisabled',
				},
			},
			loading: false,
		};
		//bindings
		this.renderCurrentStage = this.renderCurrentStage.bind(this);
		this.determineDOB = this.determineDOB.bind(this);
		this.determineDOBDifference = this.determineDOBDifference.bind(this);
		this.setStage = this.setStage.bind(this);
		this.redirectToScheduling = this.redirectToScheduling.bind(this);
	}
	//functions

	componentDidUpdate(prevProps, prevState) {
		if (
			get(prevProps, 'addOutreachLoading', false) &&
			!get(this, 'props.addOutreachLoading', false)
		) {
			if (get(this, 'props.addedOutreachError', null)) {
				this.props.enqueueSnackbar('Error in adding outreach', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				this.props.enqueueSnackbar('Outreach added', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				this.props.close();
			}
		}
	}

	renderCurrentStage() {
		switch (get(this, 'state.currentStage', 'initial')) {
			case 'selectOutcome':
				return (
					<SelectOutcome
						setStage={this.setStage}
						redirectToScheduling={
							this.props.patientCreateRedirectToScheduling
						}
					/>
				);
			case 'schedule':
				return (
					<OutreachInputs
						header={
							<div>
								Are you sure you want to proceed to Scheduling
								for this patient?
								<br />
								<br />A positive Outreach will be logged
								automatically.
							</div>
						}
						buttons={get(this, 'state.scheduleButtons', {}) || {}}
						func={this.props.patientCreateRedirectToScheduling}
						goBack={() => this.setStage('selectOutcome')}
						currentStage={get(
							this,
							'state.currentStage',
							'selectOutcome'
						)}
					/>
				);
			case 'addOutreach':
				return (
					<OutreachInputs
						header={get(
							this,
							'state.addOutreachHeader',
							'Please select'
						)}
						options={
							get(this, 'props.outreachEnums.outcomes', []) || []
						}
						fields={get(this, 'state.addOutreachFields', []) || []}
						buttons={
							get(this, 'state.addOutreachButtons', {}) || {}
						}
						func={this.props.patientCreateAddOutreach}
						goBack={() => this.setStage('selectOutcome')}
						selectedTimeAddOutreach={get(
							this,
							'props.patientCreateAddOutreachDateTime',
							null
						)}
						selectedOptionAddOutreach={get(
							this,
							'props.patientCreateAddOutreachOutcome',
							null
						)}
						patientCreateAddOutreachNotes={get(
							this,
							'props.patientCreateAddOutreachNotes',
							''
						)}
						setValue={this.props.setValue}
						currentStage={get(
							this,
							'state.currentStage',
							'selectOutcome'
						)}
					/>
				);
			case 'doNotOutreach':
				return (
					<OutreachInputs
						header={get(
							this,
							'state.doNotOutreachHeader',
							'Please select'
						)}
						options={get(this, 'props.noOutreachReasons', []) || []}
						fields={
							get(this, 'state.doNotOutreachFields', []) || []
						}
						buttons={
							get(this, 'state.doNotOutreachButtons', {}) || {}
						}
						func={this.props.patientCreateSetDoNotOutreach}
						goBack={() => this.setStage('selectOutcome')}
						selectedTimeNoOutreach={get(
							this,
							'props.patientCreateDoNotOutreachDateTime',
							null
						)}
						selectedOptionNoOutreach={get(
							this,
							'props.patientCreateDoNotOutreachReason',
							null
						)}
						setValue={this.props.setValue}
						currentStage={get(
							this,
							'state.currentStage',
							'selectOutcome'
						)}
					/>
				);
			default:
				console.log('Unrecognized stage.');
		}
	}

	setStage(stage) {
		this.setState({ currentStage: stage });
	}

	redirectToScheduling() {
		console.log('Redirecting to scheduling...');
	}

	determineDOB() {
		const patient = get(this, 'props.patient', {}) || {};
		const dob = get(patient, 'dob', null);
		if (moment(dob).isValid()) {
			return moment(dob).format('MMM DD, YYYY');
		} else return dob || 'DOB Unknown';
	}

	determineDOBDifference() {
		const patient = get(this, 'props.patient', {}) || {};
		const dob = get(patient, 'dob', null);
		if (moment(dob).isValid()) {
			return moment().diff(dob, 'years') + ' yrs ';
		} else return 'Age Unknown';
	}

	render() {
		const patient = get(this, 'props.patient', {}) || {};
		let primaryInsurance = {};
		if (patient?.insurances?.length > 0) {
			primaryInsurance = get(
				patient.insurances.filter(
					(el) => el.insurance_type === 'primary'
				),
				'[0]',
				{}
			);
		}
		return (
			<div className='appointmentAlertBackground'>
				<div className='patientCreateOutreachModalContainer'>
					<Loading
						loading={
							get(this, 'props.addOutreachLoading', false) ||
							get(this, 'state.loading', false) ||
							get(this, 'props.settingDoNotOutreach', false)
						}
						className='patientCreateOutreachModalContainerLoader'
					>
						<CreateOutreachModalTopRow
							header={'Document Manual Outreach'}
							close={this.props.close}
						/>
						<div className='patientCreateOutreachModalBody'>
							<PatientDetailBox
								determineDOB={this.determineDOB}
								determineDOBDifference={
									this.determineDOBDifference
								}
								patient={{
									...patient,
									insurance_company_type: get(
										primaryInsurance,
										['insurance_company', 'name'],
										null
									),
								}}
								selectedpage='patient'
							/>
							{this.renderCurrentStage()}
						</div>
					</Loading>
				</div>
			</div>
		);
	}
}

export default PatientCreateOutreachModal;
