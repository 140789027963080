//React & Redux
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//Utils
import { dashboard_keys, ignore_keys } from '../../Utils/dashboardUtils';

//Actions & Services
import { getdashboard } from '../../actions/dashboard';

//Components
import PatientStageCards from './PatientStageCards';
import UpcomingAppointments from './UpcomingAppointments';

//Styles
import './style.css';

const Dashboard = (props) => {
	const {
		dashboardData,
		getDashboard,
		history,
		v2,
	} = props;

	useEffect(() => {
		getDashboard();
		window.scrollTo(0, 0);
	}, []);

	const total =
		(dashboardData.cdt_caps_ready_to_send || 0) +
		(dashboardData.non_cdt_caps_ready_to_send_visit_encounters || 0) +
		(dashboardData.non_cdt_caps_ready_to_send_cc_intake_encounters || 0) +
		(dashboardData.non_cdt_caps_ready_to_send_group_session_encounters ||
			0) +
		(dashboardData.non_cdt_caps_ready_to_send_scp_encounters || 0) +
		(dashboardData.non_cdt_caps_ready_to_send_tro_encounters || 0) +
		(dashboardData.non_cdt_caps_result_only_encounters || 0);

	return (
		<div className='dashboard_cards_div_wrapper' style={{ width: '100%' }}>
			<div className='patients_dashboard' style={{ marginTop: '68px' }}>
				<div
					className='dashboard_breadcrumb'
					style={{ marginBottom: '3vw', width: '96.5%' }}
				>
					<div style={{ float: 'left', width: '50%' }}>
						Pending Tasks
					</div>					
				</div>
				<div className='patient_cards'>
					<div className='dashboard_grid_container'>
						{(dashboard_keys || []).map((dashKey, i) => {
							return (
								!(dashKey in ignore_keys) &&
								!!dashboardData && (
									<PatientStageCards
										key={i}
										obj={dashKey}
										{...{
											dashboardData,
											history,
											total,
											v2,
										}}
									/>
								)
							);
						})}
					</div>
				</div>
			</div>
			<UpcomingAppointments history={props.history} />
		</div>
	);
};

Dashboard.propTypes = {
	auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
	dashboardData: state.dashboard.dashboard_data,
});

const mapDispatchToProps = (dispatch) => ({
	getDashboard: () => dispatch(getdashboard()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
