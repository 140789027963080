//React & Redux
import React, { useEffect, useState } from 'react';

//Lodash
import { find, remove } from 'lodash';

//UI Libraries
import { Dialog, Loading } from 'gm-element-react';
import ReactSVG from 'react-svg';
import plusIcon from '../../../assets/add.svg';

//Other Libraries
import classnames from 'classnames';

//Components
import CTAWarningModal from '../../Common/CTAWarningModal';
import DefaultProvidersTable from './DefaultProvidersTable';
import Icon from '../../Common/Icon';
import ProviderForm from '../../ExternalProviders/ProviderForm';

//Styles
import '../css/workflow-document-form.css';

//Hooks
import { usePrevious } from '../../../hooks/usePrevious';

const VARIANTS = {
	ERROR: 'error',
	INFO: 'info',
	SUCCESS: 'success',
};

const DefaultProviders = (props) => {
	const {
		clearDefaultProvidersState,
		createReferralProgramDefaultProvider,
		createProvider,
		createdProviderError,
		creatingProvider,
		enqueueSnackbar,
		getProviderCredentials,
		getProviderSpecialties,
		getProviders,
		getReferralProgramDetail,
		gettingProviderCredentials,
		gettingProviderSpecialties,
		gettingProviders,
		partnerUuid,
		providerCredentials,
		providerSpecialties,
		providers,
		referralProgramsList: {
			loadingReferralProgramDetail,
			referralProgramDetail: { defaultProviders = [] },
			linkedDefaultProvider,
			linkedDefaultProviderError,
			linkingDefaultProvider,
			updatedDefaultProvidersError,
			updatingDefaultProviders,
		},
		updateProvider,
		updateReferralProgramDefaultProvider,
		updatedProviderError,
		updatingProvider,
	} = props;

	const [editingProvider, setEditingProvider] = useState(false);
	const [editProviderData, setEditProviderData] = useState(null);
	const [searchingProvider, setSearchingProvider] = useState(false);
	const [removeDefaultProviderUuid, setRemoveDefaultProviderUuid] =
		useState(null);
	const [showAddEditProviderModal, setShowAddEditProviderModal] =
		useState(false);
	const [showRemoveDefaultProviderModal, setShowRemoveDefaultProviderModal] =
		useState(false);

	const prevUpdatingProvider = usePrevious(updatingProvider);
	const prevCreatingProvider = usePrevious(creatingProvider);
	const prevLinkingDefaultProvider = usePrevious(linkingDefaultProvider);
	const prevUpdatingDefaultProviders = usePrevious(updatingDefaultProviders);

	useEffect(() => {
		getProviderCredentials();
		getProviderSpecialties();
		getReferralProgramDetail({ partner_uuid: partnerUuid });

		return () => clearDefaultProvidersState();
	}, []);

	useEffect(() => {
		if (prevUpdatingProvider && !updatingProvider) {
			if (updatedProviderError) {
				sendNotification('Error in updating providers', VARIANTS.ERROR);
			} else {
				closeModalsAndRefetchReferralProgramDetail();
				sendNotification(
					'Successfully updated providers',
					VARIANTS.INFO
				);
			}
		}
	}, [updatingProvider]);

	useEffect(() => {
		if (prevLinkingDefaultProvider && !linkingDefaultProvider) {
			if (linkedDefaultProviderError) {
				sendNotification(
					'Error in linking default provider',
					VARIANTS.ERROR
				);
			} else {
				closeModalsAndRefetchReferralProgramDetail();
				sendNotification(linkedDefaultProvider.data, VARIANTS.SUCCESS);
			}
		}
	}, [linkingDefaultProvider]);

	useEffect(() => {
		if (prevUpdatingDefaultProviders && !updatingDefaultProviders) {
			if (updatedDefaultProvidersError) {
				sendNotification(
					'Error in updating default providers',
					VARIANTS.ERROR
				);
			} else {
				closeModalsAndRefetchReferralProgramDetail();
				sendNotification(
					'Successfully updated providers',
					VARIANTS.INFO
				);
			}
		}
	}, [updatingDefaultProviders]);

	useEffect(() => {
		if (prevCreatingProvider && !creatingProvider) {
			if (createdProviderError) {
				sendNotification('Error in creating provider', VARIANTS.ERROR);
			} else {
				closeModalsAndRefetchReferralProgramDetail();
				sendNotification(
					'Successfully created provider',
					VARIANTS.SUCCESS
				);
			}
		}
	}, [creatingProvider]);

	const closeModalsAndRefetchReferralProgramDetail = () => {
		hideAllModals();
		getReferralProgramDetail({ partner_uuid: partnerUuid });
	};

	const sendNotification = (message, variant) => {
		enqueueSnackbar(message, {
			variant,
			anchorOrigin: {
				horizontal: 'right',
				vertical: 'bottom',
			},
		});
	};

	const hideAllModals = () => {
		hideProviderModal();
		setShowRemoveDefaultProviderModal(false);
	};

	const showProviderModalCreate = () => {
		setShowAddEditProviderModal(true);
		setEditingProvider(false);
		setEditProviderData(null);
	};

	const showProviderModalEdit = (providerData) => {
		setShowAddEditProviderModal(true);
		setEditingProvider(true);
		setEditProviderData(providerData || {});
	};

	const hideProviderModal = () => {
		setShowAddEditProviderModal(false);
		setEditingProvider(false);
		setEditProviderData(null);
	};

	const getSpecialty = (specialty) => {
		if (specialty && Array.isArray(providerSpecialties)) {
			const specialtyObj = find(providerSpecialties, ['name', specialty]);
			return specialtyObj?.display_name || '--';
		} else return '--';
	};

	const handleSearchingProvider = (value) => {
		setSearchingProvider(value);
	};

	const linkExistingProvider = async (providerUuid) => {
		createReferralProgramDefaultProvider({
			partner_uuid: partnerUuid,
			provider_uuids: providerUuid,
		});
	};

	const unlinkProvider = (providerUuid) => {
		const providerUuids = defaultProviders.map((provider) => provider.uuid);
		remove(providerUuids, (uuid) => uuid === providerUuid);
		updateReferralProgramDefaultProvider({
			partner_uuid: partnerUuid,
			provider_uuids: providerUuids,
		});
	};

	const handleRemoveDefaultProvider = (providerUuid) => {
		setShowRemoveDefaultProviderModal(true);
		setRemoveDefaultProviderUuid(providerUuid);
	};

	const handleConfirmRemoveDefaultProvider = () => {
		unlinkProvider(removeDefaultProviderUuid);
	};

	const handleHideRemoveDefaultProviderModal = () => {
		setShowRemoveDefaultProviderModal(false);
		setRemoveDefaultProviderUuid(null);
	};

	const isLoadingData = () =>
		gettingProviders ||
		creatingProvider ||
		linkingDefaultProvider ||
		updatingProvider;

	const isLoading = () => !searchingProvider && isLoadingData();

	const tableLoading =
		loadingReferralProgramDetail ||
		gettingProviderCredentials ||
		updatingDefaultProviders ||
		gettingProviderSpecialties;

	return (
		<>
			<div
				className='addTestButton'
				onClick={showProviderModalCreate}
				style={{ width: '140px' }}
			>
				<ReactSVG
					src={plusIcon}
					style={{
						position: 'relative',
						top: '1px',
						marginRight: '8px',
					}}
				/>
				Add Provider
			</div>
			<div className='default-providers-table'>
				<DefaultProvidersTable
					{...{
						defaultProviders,
						getSpecialty,
						handleRemoveDefaultProvider,
						showProviderModalEdit,
						tableLoading,
					}}
				/>
			</div>
			{showRemoveDefaultProviderModal && (
				<CTAWarningModal
					colorScheme={'red'}
					title={'Remove default provider'}
					text={
						'Are you sure you want to remove this provider as a default provider?'
					}
					mainButtonText={'Remove provider'}
					loading={updatingDefaultProviders}
					mainFunc={handleConfirmRemoveDefaultProvider}
					closeFunc={handleHideRemoveDefaultProviderModal}
				/>
			)}
			{showAddEditProviderModal && (
				<Dialog
					customClass='provider-link-dialog'
					title={
						<span className='header'>
							<span className='dialog-title no-breadcrumbs'>
								{editingProvider
									? 'Edit Link Info'
									: editingProvider
									? 'Edit External Provider'
									: 'Link External Provider'}
							</span>
							<span
								className={classnames(
									'close-btn-wrapper',
									isLoadingData() ? 'isReadOnly' : ''
								)}
							>
								<span
									className='close-btn-icon'
									onClick={hideAllModals}
								>
									<Icon icon='close' className='close-icon' />
								</span>
							</span>
						</span>
					}
					size='tiny'
					modal={true}
					closeOnPressEscape={true}
					closeOnClickModal={false}
					showClose={false}
					visible={showAddEditProviderModal}
					onCancel={hideAllModals}
				>
					<Dialog.Body>
						<div className='provider-wrapper'>
							<Loading
								className='provider-loading'
								loading={isLoading()}
							>
								<ProviderForm
									onSubmit={() => {}}
									{...{
										createProvider,
										createdProviderError,
										creatingProvider,
										editProviderData,
										editingProvider,
										getProviders: (q) =>
											getProviders({
												is_gmi_provider: false,
												limit: 100,
												q,
											}),
										getSpecialty,
										gettingProviders,
										handleSearchingProvider,
										linkExistingProvider,
										linkingDefaultProviders: true,
										providerCredentials,
										providerSpecialties,
										providers,
										searchingProvider,
										updateProvider,
										updatedProviderError,
										updatingProvider,
									}}
								/>
							</Loading>
						</div>
					</Dialog.Body>
				</Dialog>
			)}
		</>
	);
};

export default DefaultProviders;
