//React & Redux
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

//Hooks
import { usePrevious } from '../../hooks/usePrevious';

//Lodash
import { get, join, isEmpty, find } from 'lodash';

//Utils
import {
	states_list,
	basicUTCDateTime,
	buildCsvStrFromArr,
	titleFirstNameLastName,
} from './../../utils';

//Actions & Services
import {
	searchProviders,
	updateProvider,
	createProvider,
	getProviderSpecialties,
	getProviderCredentials,
	resetProviderState,
} from '../../actions/providers';
import { getReferralPrograms } from '../../actions/patients';
import { setFilter } from '../../actions/appData';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import AddEditExternalProviderModal from './AddEditExternalProviderModal.js';
import { Table, Thead, Tbody, Tr, Th, Td } from '../Common/Table';
import ThreeDotsMenu from './../Common/controls//ThreeDotsMenu';
import CTAWarningModal from '../Common/CTAWarningModal.js';
import { default as ReferralProgramsModal } from './../Templates/TemplateModalReferralPrograms.js';
import { default as EditReferralProgramsModal } from './../Templates/EditReferralPrograms/TemplateModalEditReferralPrograms.js';
import Searchbar from './../Filter/Searchbar';
import ResetFilters from './../Filter/ResetFilters';
import MultiSelectCheckbox from './../Filter/MultiSelectCheckbox';

//Images
import ReactSVG from 'react-svg';
import plusIcon from './../../assets/add.svg';

//Styles
import './styles.css';

const defaultFilters = {
	order_by: 'last_name',
	order_type: 'asc',
	q: '',
	title: [],
	specialties: [],
	state: [],
	offset: 0,
};

const ExternalProviders = (props) => {
	const {
		createdProviderError,
		createProvider,
		creatingProvider,
		enqueueSnackbar,
		externalProvidersFilter = {},
		getProviderCredentials,
		getProvidersList,
		getProviderSpecialties,
		getRefPrograms,
		gettingProviderCredentials,
		gettingProviderSpecialties,
		gettingReferralPrograms,
		limit = 25,
		providerCredentials = [],
		providers = {},
		providerSpecialties = [],
		referralPrograms = [],
		resetProviderState,
		setExternalProvidersFilter,
		updatedProviderError,
		updateProvider,
		updatingProvider,
	} = props;

	const {
		loading: providersLoading = false,
		error: providersError = null,
		pagination = {},
		data = [],
	} = providers;

	const [showAddEditProviderModal, setShowAddEditProviderModal] =
		useState(false);
	const [editingProvider, setEditingProvider] = useState(false);
	const [editProviderData, setEditProviderData] = useState(null);
	const [showArchiveProviderModal, setShowArchiveProviderModal] =
		useState(false);
	const [archiveProviderUuid, setArchiveProviderUuid] = useState(null);
	const [stateLoading, setStateLoading] = useState(false);
	const [showReferralProgramsViewModal, setShowReferralProgramsViewModal] =
		useState(false);
	const [showReferralProgramsEditModal, setShowReferralProgramsEditModal] =
		useState(false);
	const [referralProgramsViewSubtitle, setReferralProgramsViewSubtitle] =
		useState(null);
	const [referralProgramsViewList, setReferralProgramsViewList] =
		useState(null);
	const [referralProgramsViewAllStatus, setReferralProgramsViewAllStatus] =
		useState(null);
	const [referralProgramsProviderUuid, setReferralProgramsProviderUuid] =
		useState(null);
	const [
		referralProgramsProviderPreselected,
		setReferralProgramsProviderPreselected,
	] = useState(null);
	const [filters, setFilters] = useState({
		...defaultFilters,
		...externalProvidersFilter,
	});

	const prevUpdatingProvider = usePrevious(updatingProvider);
	const prevCreatingProvider = usePrevious(creatingProvider);
	const prevProvidersLoading = usePrevious(providersLoading);

	useEffect(() => {
		getProviderEnums();
		getRefPrograms();
		initialGetProvidersList();
		return resetProviderState();
	}, []);

	useEffect(() => {
		if (prevUpdatingProvider && !updatingProvider) {
			if (updatedProviderError) {
				enqueueSnackbar('Error in updating provider', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				hideAllModals();
				initialGetProvidersList();
			}
		}
	}, [updatingProvider]);

	useEffect(() => {
		if (prevCreatingProvider && !creatingProvider) {
			if (createdProviderError) {
				enqueueSnackbar('Error in creating provider', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				hideAllModals();
				initialGetProvidersList();
			}
		}
	}, [creatingProvider]);

	useEffect(() => {
		if (prevProvidersLoading && !providersLoading) {
			if (!providersError) setExternalProvidersFilter(filters);
		}
	}, [providersLoading]);

	useEffect(() => {
		getProvidersList({
			is_gmi_provider: false,
			offset: 0,
			limit,
			...filters,
		});
	}, [filters]);

	const getProviderEnums = () => {
		getProviderSpecialties();
		getProviderCredentials();
	};

	const initialGetProvidersList = () => {
		getProvidersList({
			is_gmi_provider: false,
			offset: filters?.offset || 25,
			limit: limit,
			...filters,
		});
	};

	const hideAllModals = () => {
		hideProviderModal();
		hideArchiveProviderModal();
		hideReferralProgramsEditModal();
	};

	const showProviderModal = () => {
		setShowAddEditProviderModal(true);
		setEditingProvider(false);
		setEditProviderData(null);
	};

	const showProviderModalEdit = (data) => {
		setShowAddEditProviderModal(true);
		setEditingProvider(true);
		setEditProviderData(data || {});
	};

	const hideProviderModal = () => {
		setShowAddEditProviderModal(false);
		setEditingProvider(false);
		setEditProviderData(null);
	};

	const showArchiveProviderModalFunc = (data) => {
		const providerUuid = data?.uuid;
		if (providerUuid) {
			setShowArchiveProviderModal(true);
			setArchiveProviderUuid(providerUuid);
		}
	};

	const hideArchiveProviderModal = () => {
		setShowArchiveProviderModal(false);
		setArchiveProviderUuid(false);
	};

	const showReferralProgramsViewModalFunc = (
		referralProgramsViewSubtitle,
		referralProgramsViewList,
		referralProgramsViewAllStatus
	) => {
		setShowReferralProgramsViewModal(true);
		setReferralProgramsViewSubtitle(referralProgramsViewSubtitle);
		setReferralProgramsViewList(referralProgramsViewList);
		setReferralProgramsViewAllStatus(referralProgramsViewAllStatus);
	};

	const hideReferralProgramsViewModal = () => {
		setShowReferralProgramsViewModal(false);
		setReferralProgramsViewSubtitle(null);
		setReferralProgramsViewList(null);
		setReferralProgramsViewAllStatus(null);
	};

	const handlePageChange = (e, offset) => {
		setFilters((filters) => ({ ...filters, offset }));
	};

	const handleResultsPerPageChange = (limit) => {
		setFilters((filters) => ({ ...filters, offset: 0 }));
	};

	const handleResetFilters = () => {
		setFilters({ ...defaultFilters });
	};

	const handleSort = (order_by, order_type) => {
		setFilters((filters) => ({
			...filters,
			order_by,
			order_type,
			offset: 0,
		}));
	};

	const getRefProgramNames = (provider) => {
		const providerReferralPrograms = provider?.referral_programs || [];
		if (
			Array.isArray(referralPrograms) &&
			Array.isArray(providerReferralPrograms)
		) {
			return providerReferralPrograms.map(
				(el) => get(el, 'display_name', null) || '--'
			);
		} else return [];
	};

	const getFullStateName = (key) => {
		const stateObj = find(states_list, ['key', key]);
		return get(stateObj, ['value'], null);
	};

	const getSpecialty = (specialty) => {
		if (specialty && Array.isArray(providerSpecialties)) {
			const specialtyObj = find(providerSpecialties, ['name', specialty]);
			return get(specialtyObj, 'display_name', null) || '--';
		} else return '--';
	};

	const renderReferralProgramCell = (provider) => {
		const refProgramNames = getRefProgramNames(provider);
		const allStatus = get(provider, 'all_providers', null);
		const providerSubtitle = titleFirstNameLastName(provider);
		return (
			<div
				className='templateTableReferralProgramsCell'
				style={{ maxWidth: '216px' }}
			>
				<span
					className='templateTableReferralProgramsText'
					onClick={(e) =>
						showReferralProgramsViewModalFunc(
							providerSubtitle,
							refProgramNames,
							allStatus
						)
					}
					style={{ maxWidth: '100%' }}
				>
					{allStatus
						? 'All'
						: buildCsvStrFromArr(refProgramNames.sort()) || '--'}
				</span>
			</div>
		);
	};

	const archiveProvider = () => {
		const deletedAt = basicUTCDateTime();
		if (archiveProviderUuid && deletedAt) {
			const payload = {
				deleted_at: deletedAt,
			};
			updateProvider(archiveProviderUuid, payload);
		}
	};

	const createProviderFunc = (payload) => {
		if (payload) {
			createProvider(payload);
		}
	};

	const updateProviderFunc = (archiveProviderUuid, payload) => {
		if (archiveProviderUuid && payload) {
			updateProvider(archiveProviderUuid, payload);
		}
	};

	const updateProviderReferralPrograms = (programs) => {
		if (referralProgramsProviderUuid && Array.isArray(programs)) {
			const referralProgramUuids = programs.map((el) =>
				get(el, 'uuid', null)
			);
			const payload = {
				referral_program_uuids: referralProgramUuids,
				is_gmi_provider: false,
				is_referring_provider: true,
			};
			updateProvider(referralProgramsProviderUuid, payload);
		}
	};

	const changeFilterHandler = (key, value) => {
		setFilters((filters) => ({
			...filters,
			[key]: value,
			offset: 0,
		}));
	};

	const constructEnums = (enums) => {
		return enums.map((obj) => {
			return {
				key: obj.name || obj.key,
				display_name: obj.display_name || obj.value,
			};
		});
	};

	const showReferralProgramsEditModalFunc = (provider) => {
		const providerUuid = get(provider, 'uuid', null);
		if (providerUuid) {
			setShowReferralProgramsEditModal(true);
			setReferralProgramsProviderUuid(providerUuid);
			setReferralProgramsProviderPreselected(
				provider?.referral_programs || []
			);
		}
	};

	const hideReferralProgramsEditModal = () => {
		setShowReferralProgramsEditModal(false);
		setReferralProgramsProviderUuid(null);
		setReferralProgramsProviderPreselected(null);
	};

	const tableLoading =
		providersLoading ||
		gettingProviderSpecialties ||
		gettingProviderCredentials ||
		gettingReferralPrograms ||
		stateLoading;

	const sortBy = {
		key: filters.order_by || null,
		value: filters.order_type || null,
	};

	return (
		<div className='external_providers_wrapper'>
			<div style={{ display: 'flex', minWidth: 'inherit' }}>
				<div
					className='addTestButton'
					onClick={showProviderModal}
					style={{ width: '140px' }}
				>
					<ReactSVG
						src={plusIcon}
						style={{
							position: 'relative',
							top: '1px',
							marginRight: '8px',
						}}
					/>
					Add Provider
				</div>
				<div className='filter-rightcontainer'>
					<Searchbar
						setStateFunc={(e) =>
							changeFilterHandler('q', e.target.value)
						}
						currentValue={filters.q || ''}
						useInputEvtObj={true}
						placeholder='Search'
					/>

					<ResetFilters handleResetFilters={handleResetFilters} />

					<MultiSelectCheckbox
						data={constructEnums(providerCredentials)}
						selected={filters.title || []}
						title={'Credentials'}
						submit={(values) =>
							changeFilterHandler('title', [...values])
						}
						majorPillTitle={'Credentials'}
						defaultValues={defaultFilters.title}
						searchBar={false}
						previewTab={false}
						loading={gettingProviderCredentials}
					/>

					<MultiSelectCheckbox
						data={constructEnums(providerSpecialties)}
						selected={filters.specialties}
						title={'Specialty'}
						submit={(values) =>
							changeFilterHandler('specialties', [...values])
						}
						majorPillTitle={'Specialty'}
						defaultValues={defaultFilters.specialties}
						searchBar={true}
						previewTab={true}
						loading={gettingProviderSpecialties}
					/>

					<MultiSelectCheckbox
						data={constructEnums(states_list)}
						selected={filters.state}
						title={'State'}
						submit={(values) =>
							changeFilterHandler('state', [...values])
						}
						majorPillTitle={'State'}
						defaultValues={defaultFilters.state}
						searchBar={true}
						previewTab={true}
					/>
				</div>
			</div>
			{showAddEditProviderModal && (
				<AddEditExternalProviderModal
					{...{
						hideProviderModal,
						editingProvider,
						editProviderData,
						createProviderFunc,
						updateProviderFunc,
						providerSpecialties,
						providerCredentials,
						updatingProvider,
						creatingProvider,
						updatedProviderError,
						createdProviderError,
					}}
				/>
			)}
			{showArchiveProviderModal && (
				<CTAWarningModal
					colorScheme={'red'}
					title={'Archive Provider'}
					text={
						'Are you sure you want to archive this external referring provider?'
					}
					mainButtonText={'Archive Provider'}
					loading={updatingProvider}
					mainFunc={archiveProvider}
					closeFunc={hideArchiveProviderModal}
				/>
			)}
			{showReferralProgramsViewModal && (
				<div className='fullModalBackground'>
					<ReferralProgramsModal
						title={'Linked Referral Programs'}
						subtitle={referralProgramsViewSubtitle}
						referralPrograms={referralProgramsViewList}
						allStatus={referralProgramsViewAllStatus}
						close={hideReferralProgramsViewModal}
					/>
				</div>
			)}
			{showReferralProgramsEditModal && (
				<div className='fullModalBackground'>
					<EditReferralProgramsModal
						close={hideReferralProgramsEditModal}
						func={updateProviderReferralPrograms}
						buttonText={'Save'}
						items={referralPrograms}
						keyForSearch={'display_name'}
						loading={updatingProvider}
						title={'Edit Referral Programs'}
						preselectedItems={referralProgramsProviderPreselected}
						canSaveEmpty={true}
					/>
				</div>
			)}
			{tableLoading ? (
				<Loading loading={true} style={{ top: '80%' }} />
			) : (
				<Table
					style={{ marginTop: '16px', minWidth: '1204px' }}
					loading={tableLoading}
					backToTop
					resultsPerPage={[25, 50, 100]}
					handleResultsPerPageChange={handleResultsPerPageChange}
					pagination={pagination}
					handlePageChange={handlePageChange}
					hasData={data.length > 0}
					handleResetFilters={handleResetFilters}
					handleSort={handleSort}
					sortBy={sortBy}
				>
					<Thead>
						<Tr>
							<Th
								sortable={{
									first_name: {
										display_name: 'first name',
										sortBy: {
											asc: 'A to Z',
											desc: 'Z to A',
										},
									},
									last_name: {
										display_name: 'last name',
										sortBy: {
											asc: 'A to Z',
											desc: 'Z to A',
										},
									},
								}}
							>
								Provider
							</Th>
							<Th>Credentials</Th>
							<Th>Organization</Th>
							<Th>State</Th>
							<Th>Specialty</Th>
							<Th>Referral Programs</Th>
							<Th />
						</Tr>
					</Thead>
					<Tbody>
						{data.map((obj) => (
							<Tr key={get(obj, ['uuid'])}>
								<Td minWidth='198px' maxWidth='224px'>
									{get(obj, ['first_name'], null) +
										' ' +
										get(obj, ['last_name'], null)}
								</Td>
								<Td>{get(obj, ['title'], null)}</Td>
								<Td>{get(obj, ['organization_name'], null)}</Td>
								<Td>
									{getFullStateName(
										get(
											obj,
											['address', 'office', 'state'],
											null
										)
									)}
								</Td>
								<Td>
									{getSpecialty(
										get(obj, 'specialties[0]', null)
									)}
								</Td>
								<Td minWidth='198px' maxWidth='224px'>
									{renderReferralProgramCell(obj)}
								</Td>
								<Td minWidth='unset' maxWidth='unset'>
									<ThreeDotsMenu
										options={[
											{
												label: 'Edit Provider Info',
												click: (e) =>
													showProviderModalEdit(obj),
											},
											{
												label: 'Edit Linked Referral Programs',
												click: (e) =>
													showReferralProgramsEditModalFunc(
														obj
													),
											},
											{
												label: 'Archive Provider',
												click: (e) =>
													showArchiveProviderModalFunc(
														obj
													),
											},
										]}
									/>
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			)}
		</div>
	);
};

const mapStateToProps = (state, props) => ({
	providers: state.providers,
	limit: state.uiConfig.table.resultsPerPage,
	updatingProvider: get(state, 'updateProvider.updatingProvider', false),
	updatedProvider: get(state, 'updateProvider.updatedProvider', null),
	updatedProviderError: get(
		state,
		'updateProvider.updatedProviderError',
		null
	),
	creatingProvider: get(state, 'createProvider.creatingProvider', false),
	createdProvider: get(state, 'createProvider.createdProvider', null),
	createdProviderError: get(
		state,
		'createProvider.createdProviderError',
		null
	),
	gettingProviderSpecialties: get(
		state,
		'providerEnums.gettingProviderSpecialties',
		false
	),
	providerSpecialties:
		get(state, 'providerEnums.providerSpecialties', null) || [],
	providerSpecialtiesError: get(
		state,
		'providerEnums.providerSpecialtiesError',
		null
	),
	gettingProviderCredentials: get(
		state,
		'providerEnums.gettingProviderCredentials',
		false
	),
	providerCredentials:
		get(state, 'providerEnums.providerCredentials', null) || [],
	providerCredentialsError: get(
		state,
		'providerEnums.providerCredentialsError',
		null
	),
	referralPrograms: get(state, 'referralPrograms.referralPrograms.data', []),
	gettingReferralPrograms: get(
		state,
		'referralPrograms.gettingReferralPrograms',
		false
	),
	referralProgramsError: get(
		state,
		'referralPrograms.referralProgramsError',
		null
	),
	externalProvidersFilter:
		get(state, 'appData.externalProviders', null) || {},
});

const mapDispatchToProps = (dispatch) => ({
	getProvidersList: (params) => dispatch(searchProviders({ ...params })),
	updateProvider: (providerUuid, payload) =>
		dispatch(updateProvider(providerUuid, payload)),
	createProvider: (payload) => dispatch(createProvider(payload)),
	getProviderSpecialties: () => dispatch(getProviderSpecialties()),
	getProviderCredentials: () => dispatch(getProviderCredentials()),
	getRefPrograms: () => dispatch(getReferralPrograms()),
	resetProviderState: () => dispatch(resetProviderState()),
	setExternalProvidersFilter: (data) =>
		dispatch(setFilter('externalProviders', data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalProviders);
