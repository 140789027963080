//React & Redux
import React, { Fragment, useEffect, useState, useRef } from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../../assets/exit-alert.svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import TagSelection from './TagSelection.js';

//Utils
import {
	determineTopDropShadow,
	determineBottomDropShadow,
} from './../../../utils.js';

const TemplateModalEditReferralPrograms = (props) => {
	const {
		allInitial,
		buttonText,
		canSaveEmpty = false,
		close,
		closeChargeModalAndDispatch,
		func,
		items = [],
		keyForSearch = 'display_name',
		loading = false,
		preselectedItems = [],
		title,
	} = props;

	const [selectedTags, setSelectedTags] = useState([]);
	const [selectedICDCodes, setSelectedICDCodes] = useState([]);
	const [icdCodes, setIcdCodes] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [currentStage, setCurrentStage] = useState('select');
	const [stateLoading, setLoading] = useState(false);
	const [bodyElement, setBodyElement] = useState(null);
	const [topDropShadow, setTopDropShadow] = useState(false);
	const [bottomDropShadow, setBottomDropShadow] = useState(false);

	useEffect(() => {
		let preselectedItemsArr = [];
		if (Array.isArray(preselectedItems)) {
			preselectedItemsArr = _.sortBy(preselectedItems, 'display_name');
		}
		setIcdCodes(items || []);
		setSelectedICDCodes(preselectedItemsArr);

		if (allInitial) selectAll();
	}, []);

	useEffect(() => {
		filterResults();
	}, [searchQuery]);

	useEffect(() => {
		determineDropShadow(['top', 'bottom']);
	}, [bodyElement]);

	const setBodyEl = (el) => {
		if (el) setBodyElement(el);
	};

	const determineDropShadow = (sides) => {
		if (Array.isArray(sides) && bodyElement) {
			sides.forEach((side) => {
				let shadow;
				switch (side) {
					case 'top':
						shadow = determineTopDropShadow(bodyElement);
						setTopDropShadow(shadow);
						break;
					case 'bottom':
						shadow = determineBottomDropShadow(bodyElement);
						setBottomDropShadow(shadow);
						break;
					default:
						console.log('Unrecognized modal side.');
						break;
				}
			});
		}
	};

	const renderCurrentStage = () => {
		switch (currentStage) {
			case 'select':
				return (
					<TagSelection
						buttonText={buttonText}
						selectedICDCodes={selectedICDCodes}
						searchQuery={searchQuery}
						handleSearchQueryChange={handleSearchQueryChange}
						icdCodes={icdCodes}
						toggleCodeSelection={toggleCodeSelection}
						closeChargeModalAndDispatch={
							closeChargeModalAndDispatch
						}
						selectAll={selectAll}
						selectNone={selectNone}
						func={func}
						setBodyElement={setBodyEl}
						scrollHandler={_.debounce(determineDropShadow, 100)}
						shouldHaveTopDropShadow={topDropShadow}
						shouldHaveBottomDropShadow={bottomDropShadow}
						canSaveEmpty={canSaveEmpty}
					/>
				);
			default:
				console.log('Unrecognized stage.');
		}
	};

	const selectAll = () => {
		setSelectedICDCodes((items || []).slice());
	};

	const selectNone = () => {
		setSelectedICDCodes([]);
	};

	const handleSearchQueryChange = (str) => {
		setSearchQuery(str);
	};

	const filterResults = () => {
		if (!Array.isArray(items)) return;
		const filteredItems = items.filter((el) => {
			return get(el, keyForSearch, '')
				.toLowerCase()
				.includes(searchQuery.toLowerCase());
		});
		setIcdCodes(filteredItems);
	};

	const toggleCodeSelection = (status, uuid, icdCode) => {
		if (status) {
			toggleCodeSelectionOn(uuid, icdCode);
		} else {
			toggleCodeSelectionOff(uuid, icdCode);
		}
	};

	const toggleCodeSelectionOn = (uuid, icdCode) => {
		const currentCodes = selectedICDCodes || [];
		const filteredCodes = currentCodes.filter(
			(el) => get(el, 'uuid', '') === uuid
		);
		if (filteredCodes.length === 0) {
			currentCodes.push(icdCode);
			sortAndSetSelectedCodes(currentCodes);
		}
	};

	const toggleCodeSelectionOff = (uuid, icdCode) => {
		const currentCodes = selectedICDCodes || [];
		_.remove(currentCodes, (el) => get(el, 'uuid', '') === uuid);
		sortAndSetSelectedCodes(currentCodes);
	};

	const sortAndSetSelectedCodes = (selectedCodes) => {
		if (Array.isArray(selectedCodes)) {
			selectedCodes = _.sortBy(selectedCodes, 'display_name');
		}
		setSelectedICDCodes(selectedCodes);
	};

	return (
		<div className='chargeModalContainer'>
			<Loading loading={loading || stateLoading}>
				<div className='chargeModalTopRow'>
					<div className='chargeModalHeaderBreadcrumbs'>
						<div
							className='chargeModalHeader'
							style={{
								position: 'relative',
								top: '5px',
								left: '5px',
							}}
						>
							{title || 'Edit Referral Programs'}
						</div>
					</div>
					<div className='chargeModalClose'>
						<ReactSVG
							src={xIcon}
							style={{
								position: 'relative',
								bottom: '2px',
								right: '1.25px',
							}}
							onClick={(e) => {
								close();
							}}
						/>
					</div>
				</div>
				{renderCurrentStage()}
			</Loading>
		</div>
	);
};

export default TemplateModalEditReferralPrograms;
