//React & Redux
import React, { useEffect, useState } from 'react';

//Images
import export_img from '../../assets/export.svg';

//Other Libraries
import moment from 'moment';
import _, { get } from 'lodash';

//Utils
import {
	getBeginningOfMonthDateObj,
	getBeginningOfYearDateObj,
	getBeginningOfTodayDateObj,
} from '../../Utils/CalendarUtils.js';
import './style.css';

//Components
import MultiSelectCheckbox from '../Filter/MultiSelectCheckbox.js';
import ResetFilters from '../Filter/ResetFilters';
import SingleSelect from '../Filter/SingleSelect';
import DateRangeFilter from '../Filter/DateRangeFilter';

const RiseDashboardFilter = (props) => {
	const {
		filterState,
		partnerOrganization,
		handleCardClick,
		selectedCard,
		handleFilterchange,
		cardList,
		affiliations,
		handleResetFilters,
		handleExportButton,
		loading,
	} = props;
	const exportButtonClass = loading
		? 'btn-export-rise disabled'
		: 'btn-export-rise';
	return (
		<section
			className='billing-db-fltr-wrapper rise-dashboard-fltr-wraper'
			style={{ justifyContent: 'space-between' }}
		>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div
					className={exportButtonClass}
					onClick={() => !loading && handleExportButton()}
					style={{ marginRight: '0px' }}
				>
					<span className='btn-export-rise-txt'>Export Table</span>
				</div>
				{!_.isNil(selectedCard) && (
					<div
						className='create-lab-order'
						onClick={() => handleCardClick(null)}
					>
						Back
					</div>
				)}
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}
			>
				<ResetFilters handleResetFilters={handleResetFilters} />
				<MultiSelectCheckbox
					data={partnerOrganization}
					selected={filterState.partner}
					title={'Referral Partner'}
					submit={(values) => handleFilterchange('partner', values)}
					majorPillTitle={'Referral Partner'}
					defaultValues={[]}
					searchBar={true}
					previewTab={true}
					alwaysNumber={false}
					zeroToAllPillTitle={true}
					sortingKey={'display_name'}
				/>
				<MultiSelectCheckbox
					data={affiliations.map((el) => {
						return { ...el, key: el.name };
					})}
					selected={filterState.program}
					title={'Referral Program'}
					submit={(values) => handleFilterchange('program', values)}
					majorPillTitle={'Referral Program'}
					defaultValues={[]}
					searchBar={true}
					previewTab={true}
					alwaysNumber={false}
					zeroToAllPillTitle={true}
					sortingKey={'display_name'}
				/>
				{!_.isNil(selectedCard) && (
					<SingleSelect
						data={_.map(cardList, (card) => {
							return {
								internal: card.name,
								external: card.display_name,
							};
						})}
						currentValue={selectedCard}
						title={'Program Stage'}
						majorPillTitle={'Program Stage'}
						setStateFunc={(value) => handleCardClick(value)}
						defaultValue={'complete'}
						convertStrsToNums={false}
					/>
				)}
				<DateRangeFilter
					startDate={get(
						filterState,
						['daterange', 'from'],
						moment().subtract(90, 'days').toDate()
					)}
					endDate={get(
						filterState,
						['daterange', 'to'],
						moment().toDate()
					)}
					startDatePlaceholder={get(
						filterState,
						['daterange', 'from'],
						moment().subtract(90, 'days').format('MM/DD/YYYY')
					)}
					endDatePlaceholder={get(
						filterState,
						['daterange', 'to'],
						moment().format('MM/DD/YYYY')
					)}
					format='MM/dd/yyyy'
					shortcuts={[
						{
							text: 'MTD',
							newValues: [
								getBeginningOfMonthDateObj(),
								getBeginningOfTodayDateObj(),
							],
						},
						{
							text: 'YTD',
							newValues: [
								getBeginningOfYearDateObj(),
								getBeginningOfTodayDateObj(),
							],
						},
					]}
					onChange={(values) =>
						handleFilterchange('daterange', values)
					}
				/>
			</div>
		</section>
	);
};

export default RiseDashboardFilter;
