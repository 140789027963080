import _ from 'lodash';
import moment from 'moment-timezone';

export const AppointmentType = {
	EMPTY: 'EMPTY',
	APPOINTMENT: 'APPOINTMENT',
	NOT_AVAILABLE_SLOT: 'NA',
	SELECTED: 'SELECTED',
};

export const MIN_HOUR = 7;
export const MAX_HOUR = 21;

const forbiddenTimeSpan = 60000;

export function clearOffset(dateStr) {
	return dateStr.substr(0, 19);
}

export function getOffset(dateStr) {
	return dateStr.substr(19);
}

export function getDateFromStr(dateStr) {
	return moment(clearOffset(dateStr)).toDate();
}

export function getStartOfWeek(date) {
	return moment(date).startOf('week').toDate();
}

export function getEndOfWeek(date) {
	return moment(date).endOf('week').toDate();
}

export function prepareDate(date) {
	return moment(date).format('YYYY-MM-DDTHH:mm:ss');
}

export function getFormattedRange(start, end) {
	if (start.month() === end.month() && start.year() === end.year()) {
		return moment(start).format('MMMM YYYY');
	} else if (start.year() !== end.year()) {
		return (
			moment(start).format('MMM YYYY') +
			' — ' +
			moment(end).format(' MMM YYYY')
		);
	}
	return (
		moment(start).format('MMM') + ' — ' + moment(end).format(' MMM YYYY')
	);
}

/**
 * returns new date with updated time
 */
function updateDate(date, hours = 0, minutes = 0, seconds = 0, ms = 0) {
	return new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
		hours,
		minutes,
		seconds,
		ms
	);
}

/**
 * this function splits events if "start" and "end" are on the different days
 */
function splitEvents(o) {
	if (o.start.getDay() !== o.end.getDay()) {
		const newSlot = _.clone(o);
		o.end = updateDate(o.start, 23, 59, 59, 999);
		newSlot.start = updateDate(newSlot.end);
		return [o, newSlot];
	}
	return o;
}

/**
 * corrects start and end dates to fit MIN_HOUR and MAX_HOUR settings
 */
function normalizeTime(slot) {
	if (
		slot.start.getHours() >= MAX_HOUR ||
		slot.end.getHours() < MIN_HOUR ||
		(slot.end.getHours() === MIN_HOUR && slot.end.getMinutes() === 0)
	)
		return null;
	if (slot.start.getHours() < MIN_HOUR)
		slot.start = updateDate(slot.start, MIN_HOUR);
	if (
		slot.end.getHours() > MAX_HOUR ||
		(slot.end.getHours() === MAX_HOUR && slot.end.getMinutes() > 0)
	) {
		slot.end = updateDate(slot.end, MAX_HOUR);
	}
	return slot;
}

/**
 * filter out events that are finished BEFORE the current time plus forbidden interval
 * @param {*} currentTime
 * @param {*} o
 */
function filterOutOfDateEvents(currentTime, o) {
	return +o.realUtcEnd >= currentTime + forbiddenTimeSpan;
}

function fixStartTimeOfClosestEvent(currentTime, o) {
	if (
		o.realUtcStart > currentTime &&
		o.realUtcStart < currentTime + forbiddenTimeSpan
	) {
		const delta = o.realUtcStart - +o.start;
		const newStart = new Date(currentTime - delta + forbiddenTimeSpan);
		o.start = updateDate(
			newStart,
			newStart.getHours() + (newStart.getMinutes() >= 30 ? 1 : 0),
			newStart.getMinutes() < 30 ? 30 : 0
		);
		// normalize o.realUtcStart. We will hardly need realUtcStart later, but do it just in case
		o.realUtcStart = +o.start + delta;
	}
	return o;
}

function flatten(array) {
	return [].concat.apply([], array);
}

function removeReservations(slots, reservations) {
	//TODO: this function should be rewritten to support reservations with any duration
	const slotsByDay = _.groupBy(slots, (s) => +updateDate(s.start));
	reservations.forEach((r) => {
		const rStart = +r.start;
		const dayStart = +updateDate(r.start);
		const dayEnd = +updateDate(r.end);
		_({
			[dayStart]: slotsByDay[dayStart],
			[dayEnd]: slotsByDay[dayEnd],
		}).forIn((sts, day) => {
			if (sts) {
				slotsByDay[day] = flatten(
					sts.map((s) => {
						if (+s.start === rStart) {
							s.start.setMinutes(s.start.getMinutes() + 30);
						} else if (rStart + 30 * 60000 === +s.end) {
							s.end.setMinutes(s.start.getMinutes() - 30);
						} else if (rStart > s.start && rStart < s.end) {
							return [
								Object.assign(_.clone(s), {
									end: new Date(rStart),
								}),
								Object.assign(_.clone(s), {
									start: new Date(rStart + 30 * 60000),
								}),
							];
						}
						return s;
					})
				);
			}
		});
	});
	return flatten(Object.values(slotsByDay));
}

function generateNotAvailableEvents(existingEvents, startDate) {
	const hashFunc = (date) => date.toISOString().slice(0, 16);
	const filledSlots = _.reduce(
		existingEvents,
		(result, e) => {
			const slotsCount = (+e.end - e.start) / (30 * 60000);
			_.range(slotsCount).forEach((i) => {
				result[
					hashFunc(new Date(+e.start + i * 30 * 60000))
				] = true;
			});
			return result;
		},
		{}
	);
	const minuteLength = 60 * 1000;
	const hourLength = 60 * minuteLength;
	const dayLength = 24 * hourLength;
	const allSlotsDateStarts = _.flattenDeep(
		_.range(7).map((day) =>
			_.range(MIN_HOUR, MAX_HOUR).map((hour) =>
				[0, 30].map(
					(minutes) =>
						new Date(
							+startDate +
								day * dayLength +
								hour * hourLength +
								minutes * minuteLength
						)
				)
			)
		)
	);
	return allSlotsDateStarts
		.filter((date) => !filledSlots[hashFunc(date)])
		.map((date) => ({
			duration:30,
			type: AppointmentType.NOT_AVAILABLE_SLOT,
			start: date,
			end: new Date(+date + 30 * minuteLength),
		})); 
}

export function CreateEmptySlotEvents({
	eventsList,
	reservations,
	onSlotClick,
	startDate,
	timeslot,
}) {
	const currentTime = +new Date();
	// in this flow THERE ARE mutations. they're deliberate
	const filteredEvents = _(eventsList)
		.map((o) => {
			let selected = false;
			try {
				selected =
					timeslot &&
					timeslot.start &&
					timeslot.end &&
					moment(timeslot.start).isSame(getDateFromStr(o.start)) &&
					moment(timeslot.end).isSame(getDateFromStr(o.end));
			} catch (error) {
				console.log('error in slot selected matching logic', error);
			}
			return {
				realUtcStart: moment(o.start).valueOf(), // number of milliseconds
				realUtcEnd: moment(o.end).valueOf(), // number of milliseconds
				start: getDateFromStr(o.start), // JS Date
				end: getDateFromStr(o.end), // JS Date
				sid: o.provider_id,
				consultation_id: o.consultation_id,
				state: o.state,
				timezone: o.timezone,
				duration:o.duration,
				onSlotClick,
				type: selected
					? AppointmentType.SELECTED
					: AppointmentType.EMPTY,
				vsee_specialty: o.vsee_specialty,
			};
		})
		.map((o) => splitEvents(o)) // split event if start and end dates have different days
		.flatten()
		.filter((o) => filterOutOfDateEvents(currentTime, o))
		.map((o) => fixStartTimeOfClosestEvent(currentTime, o))
		.map((o) => normalizeTime(o)) // fix time to fit MIN_HOUR and MAX_HOUR
		.filter((o) =>o && o.end - o.start >= o.duration * 60000)
		.map((o) => {
			delete o.realUtcEnd;
			delete o.realUtcStart;
			return o;
		})
		.value(); // remove time that is less than duration
	//.map(o => log(o));

	// events captured bye other clients. they can be "reserved" or "booked"
	const reservedEvents = reservations.map((o) => ({
		start: getDateFromStr(o.start),
		end: getDateFromStr(o.end),
		providerId: o.providerId,
	}));
	const pureEvents = removeReservations(filteredEvents, reservedEvents);

	const naEvents = generateNotAvailableEvents(
		pureEvents,
		startDate
	);

	return pureEvents.concat(naEvents);
}

export function getBeginningOfTodayDateObj() {
	const date = new Date();
	date.setHours(0, 0, 0, 0);
	return date;
}

export function getBeginningOfMonthDateObj() {
	const date = new Date();
	date.setDate(1);
	date.setHours(0, 0, 0, 0);
	return date;
}

export function getBeginningOfYearDateObj() {
	const date = new Date();
	date.setDate(1);
	date.setHours(0, 0, 0, 0);
	date.setMonth(0);
	return date;
}
