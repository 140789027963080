//React & Redux
import React, { useState } from 'react';
import { connect } from 'react-redux';

//Lodash
import get from 'lodash/get';

//Actions & Services
import { exportProviderReport } from '../../actions/providers';

//UI Libraries
import { Button, Steps, Loading } from 'gm-element-react';
import ReportTypeSelection from './ReportTypeSelection.js';
import CustomizedSelection from './CustomizedSelection.js';
import ExportConfirmation from './ExportConfirmation.js';

//Other Libraries
import moment from 'moment';

//Styles
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './style.css';

const ReportSelector = (props) => {
	const { enqueueSnackbar, exportReport } = props;
	const [activeStep, setActiveStep] = useState(0);
	const [selected_report_type, setselectedReportType] =
		useState('provider_report');
	const [selected_affiliation, setSelectedAffiliation] = useState(undefined);
	const [notes, setNotes] = useState(false);
	const [cs_version, setCSVersion] = useState(false);
	const [selected_partner, setSelectedPartner] = useState(undefined);
	const [selected_all_affiliation, setSelectedAllAffiliation] =
		useState(false);
	const [selected_all_partner, setSelectedAllPartner] = useState(false);
	const [report_subtypes, setReportSubtypes] = useState([]);
	const [generatingReport, setGenratingReport] = useState(false);
	const [start_date, setStartDate] = useState(new Date());
	const [end_date, setEndDate] = useState(new Date());
	const [affiliations_list, setAffiliationsList] = useState([]);

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
		setReportSubtypes([]);
	};

	const handleExport = () => {
		setGenratingReport(true);
		const formatted_startDate =
			selected_report_type == 'provider_report'
				? moment(start_date).format('YYYY-MM-DD ZZ').toString()
				: moment(start_date).format('YYYY-MM-DD').toString();
		const fomratted_endDate =
			selected_report_type == 'provider_report'
				? moment(end_date).format('YYYY-MM-DD ZZ').toString()
				: moment(end_date).format('YYYY-MM-DD').toString();

		exportReport({
			referral_programs:
				selected_report_type == 'completed_encounters_report' &&
				selected_all_affiliation
					? []
					: selected_affiliation || -1,
			referral_partners:
				selected_report_type == 'completed_encounters_report' &&
				selected_all_partner
					? []
					: selected_partner || -1,
			start_date: formatted_startDate,
			end_date: fomratted_endDate,
			report_type: selected_report_type,
			report_subtypes: report_subtypes,
			notes: notes,
			cs_version: cs_version,
		});

		setTimeout(() => {
			enqueueSnackbar('Report is sent to your email id.', {
				variant: 'info',
				anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
			});
			setGenratingReport(false);
			setActiveStep(0);
			setSelectedAffiliation(undefined);
			setStartDate(new Date());
			setEndDate(new Date());
			setselectedReportType('provider_report');
			setReportSubtypes([]);
			setNotes(false);
			setCSVersion(false);
			setSelectedPartner(undefined);
		}, 2000);
	};

	const selectedReportType = (value) => {
		setselectedReportType(value);
		setStartDate(new Date());
		setEndDate(new Date());
		setSelectedAffiliation(undefined);
		setSelectedPartner(undefined);
	};

	const selectedAffiliation = (value) => {
		setSelectedAffiliation(value);
	};

	const selectedRefPartner = (value) => {
		setSelectedPartner(value);
	};
	const handleAffiliationsList = (list) => {
		setAffiliationsList(list);
	};
	const setNoteInclusion = (option) => {
		setNotes(option);
	};

	const handleCSVersion = (option) => {
		setCSVersion(option);
	};

	const setDateRanges = (dates) => {
		setStartDate(get(dates, 'start_date'));
		setEndDate(get(dates, 'end_date'));
	};

	const getStepContents = () => {
		if (activeStep == 0) {
			return (
				<ReportTypeSelection
					selectedReportType={selectedReportType}
					report_type={selected_report_type}
				/>
			);
		} else if (activeStep == 1) {
			return (
				<CustomizedSelection
					showAffiliationSelector={
						selected_report_type in
						{
							provider_report: 1,
							new_patient_seen_report: 1,
							completed_encounters_report: 1,
						}
					}
					showProviderReportSelector={
						selected_report_type == 'provider_report'
					}
					selected_affiliation={selectedAffiliation}
					selectedRefPartner={selectedRefPartner}
					affiliations_list={handleAffiliationsList}
					setDateRanges={setDateRanges}
					affiliation_id={selected_affiliation}
					partner_ids={selected_partner}
					start_date={start_date}
					end_date={end_date}
					report_type={selected_report_type}
					setNoteInclusion={setNoteInclusion}
					setCSVersion={handleCSVersion}
					notes={notes}
					cs_version={cs_version}
					handleSelectAllRefPartners={handleSelectAllRefPartners}
					handleSelectAllRefPrograms={handleSelectAllRefPrograms}
				/>
			);
		} else if (activeStep == 2) {
			return (
				<ExportConfirmation
					dates={{ start_date: start_date, end_date: end_date }}
					affiliation_id={selected_affiliation}
					affiliation_list={affiliations_list}
					report_type={selected_report_type}
					showAffiliationSelector={
						selected_report_type in
						{
							provider_report: 1,
							new_patient_seen_report: 1,
							completed_encounters_report: 1,
						}
					}
					generatingReport={generatingReport}
					selected_report_subtypes={report_subtypes}
				/>
			);
		}
	};

	const getNextDisabledStatus = () => {
		if (activeStep == 1) {
			if (
				selected_affiliation &&
				get(selected_affiliation, 'length', 0) != 0 &&
				selected_partner &&
				get(selected_partner, 'length', 0) != 0
			) {
				return false;
			} else {
				return true;
			}
		}

		return false;
	};

	const handleSelectAllRefPartners = (value) => {
		setSelectedAllPartner(value);
	};

	const handleSelectAllRefPrograms = (value) => {
		setSelectedAllAffiliation(value);
	};

	return (
		<div style={{ width: '95%' }}>
			<div
				className='patients_dashboard self_service_report'
				style={{ marginTop: '68px' }}
			>
				<Loading
					loading={generatingReport}
					className='report_loader'
					style={{ display: 'flex', height: '99%' }}
				>
					<div className='left_panel_self_service'>
						<Steps
							direction='vertical'
							space={100}
							active={activeStep}
							className='self_service_stepper_wrapper'
						>
							<Steps.Step
								title='Select Report' /*icon="document"*/
								className={
									activeStep == 0
										? 'self_service_stepper_label_active'
										: 'self_service_stepper'
								}
							></Steps.Step>
							<Steps.Step
								title='Set customization' /*icon="date"*/
								className={
									activeStep == 1
										? 'self_service_stepper_label_active'
										: 'self_service_stepper'
								}
							></Steps.Step>
							<Steps.Step
								title='Confirm export' /*icon="upload"*/
								className={
									activeStep == 2
										? 'self_service_stepper_label_active'
										: 'self_service_stepper'
								}
							></Steps.Step>
						</Steps>
					</div>
					<div className='report_type_selection-wrapper'>
						{getStepContents()}
						<div className='button-wrapper'>
							{activeStep != 0 && (
								<Button
									type='text'
									className='report_selection_button_back'
									disabled={activeStep == 0}
									onClick={handleBack}
								>
									{activeStep == 2 ? 'Edit' : 'Back'}
								</Button>
							)}
							<Button
								className={
									'report_selection_buttons' +
									(getNextDisabledStatus() ? ' disabled' : '')
								}
								disabled={getNextDisabledStatus()}
								onClick={
									activeStep == 2 ? handleExport : handleNext
								}
							>
								{activeStep == 2 ? 'Export' : 'Continue'}
							</Button>
						</div>
					</div>
				</Loading>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	exportReport: (data) => dispatch(exportProviderReport(data)),
});

export default connect(null, mapDispatchToProps)(ReportSelector);
