import { Dialog } from 'gm-element-react';
import React from 'react';

import Icon from '../Icon';

import './ModalWindow.scss';

export function ModalWindow({
	children,
	title,
	onCancel,
	withIcon = true,
	width,
	top = '30%',
	closeOnClickOutsideModal = true
}) {
	const Title = (
		<>
			{withIcon && <Icon className='icon' icon='warning-circle' />}
			<span className='title'>{title}</span>
		</>
	);
	const dialogStyle = width ? { width } : {};

	return (
		<Dialog
			className='gm-modal-window'
			visible
			title={Title}
			size='tiny'
			onCancel={onCancel}
			top={top}
			style={dialogStyle}
			closeOnClickModal={closeOnClickOutsideModal} 
		>
			<hr />
			<Dialog.Body>{children}</Dialog.Body>
		</Dialog>
	);
}
