//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import { getEncounterSpecificTime } from '../Common/utilsfunctions';
import {
	determineWidthOverflow,
	RemoveUnderscoreAndCapitalizeFirstLetter,
} from '../../../../../utils.js';

//Other Libraries
import moment from 'moment';

//Components
import ICDTag from './../../../ChargeInformation/ICDTag.js';
import Tooltip from '../../../../Common/Tooltip';

class AuditTrailHistoryRecord extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			emptyValues: new Set([null, undefined, '']),
		};
		//bindings
		this.getValue = this.getValue.bind(this);
		this.handleValueGet = this.handleValueGet.bind(this);
		this.getLastItemValueNonZeroResults =
			this.getLastItemValueNonZeroResults.bind(this);
		this.renderICDCodes = this.renderICDCodes.bind(this);
		this.renderICDTags = this.renderICDTags.bind(this);
		this.getLastItemValueZeroResults =
			this.getLastItemValueZeroResults.bind(this);
		this.isValidDate = this.isValidDate.bind(this);
		this.formattedDate = this.formattedDate.bind(this);
		this.getValueFromEnum = this.getValueFromEnum.bind(this);
		this.getRelevantChange = this.getRelevantChange.bind(this);
		this.getTimestamp = this.getTimestamp.bind(this);
		this.getUsername = this.getUsername.bind(this);
	}
	//functions

	componentDidMount() {
		this.forceUpdate();
	}

	handleValueGet() {
		const lastItem = get(this, 'props.lastItem', null);
		const nonZeroResults = get(this, 'props.nonZeroResults', null);
		if (lastItem) {
			if (nonZeroResults) return this.getLastItemValueNonZeroResults();
			else return this.getLastItemValueZeroResults();
		} else return this.getValue();
	}

	getValue() {
		const relevantChange = this.getRelevantChange();
		const currentField = get(this, 'props.currentField', null);
		if (relevantChange) {
			if (currentField === 'medical_codes')
				return this.renderICDCodes(relevantChange, false, false);
			const value = get(relevantChange, 'new_value', null);
			if (this.isValidDate(value)) return this.formattedDate(value);
			else return this.getValueFromEnum(value);
		}
	}

	renderICDCodes(relevantChange, lastItem = false, nonZeroResults = false) {
		const encounter = get(this, 'props.encounter', {}) || {};
		if (lastItem && nonZeroResults) {
			return this.renderICDTags(
				get(relevantChange, 'old_value', []) || []
			);
		} else if (lastItem && !nonZeroResults) {
			return this.renderICDTags(
				get(encounter, 'medical_codes', []) || []
			);
		} else {
			return this.renderICDTags(
				get(relevantChange, 'new_value', []) || []
			);
		}
	}

	renderICDTags(tags) {
		if (typeof tags === 'string' && tags[0] === '[') {
			try {
				tags = JSON.parse(tags.replace(/\'/gi, '"'));
			} catch (e) {
				return;
			}
		}
		if (Array.isArray(tags) && get(tags, 'length', 0)) {
			return (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						flexWrap: 'wrap',
					}}
				>
					{tags.map((el) => {
						return (
							<ICDTag
								key={`tag-${el.uuid}`}
								hasX={false}
								icdCode={el}
							/>
						);
					})}
				</div>
			);
		} else return '--';
	}

	getLastItemValueNonZeroResults() {
		const relevantChange = this.getRelevantChange();
		const currentField = get(this, 'props.currentField', null);
		if (relevantChange) {
			if (currentField === 'medical_codes')
				return this.renderICDCodes(relevantChange, true, true);
			const value = get(relevantChange, 'old_value', null);
			if (this.isValidDate(value)) return this.formattedDate(value);
			else return this.getValueFromEnum(value);
		}
	}

	getLastItemValueZeroResults() {
		const relevantChange = this.getRelevantChange();
		const encounter = get(this, 'props.encounter', {}) || {};
		const currentField = get(this, 'props.currentField', null);
		const value = get(encounter, currentField, null);
		if (value) {
			if (currentField === 'medical_codes')
				return this.renderICDCodes(relevantChange, true, false);
			if (this.isValidDate(value)) return this.formattedDate(value);
			else return this.getValueFromEnum(value);
		} else return value;
	}

	getValueFromEnum(value) {
		const relevantEncounterEnum =
			get(this, 'props.relevantEncounterEnum', {}) || {};
		const currentField = get(this, 'props.currentField', null);
		const enumFieldArr = get(relevantEncounterEnum, currentField, []) || [];
		if (typeof value === 'string')
			value = RemoveUnderscoreAndCapitalizeFirstLetter(value);
		if (Array.isArray(enumFieldArr)) {
			const valueObj = _.find(enumFieldArr, (el) => {
				return get(el, 'key', '') === value;
			});
			return valueObj ? get(valueObj, 'display_name', value) : value;
		} else return value;
	}

	getRelevantChange() {
		const record = get(this, 'props.record', {}) || {};
		const currentField = get(this, 'props.currentField', null);
		const changeObj = get(record, 'changes', []) || [];
		const relevantChangeObj = _.find(changeObj, (el) => {
			return get(el, 'attribute', '') === currentField;
		});
		return relevantChangeObj;
	}

	isValidDate(value) {
		const currentField = get(this, 'props.currentField', null);
		const exclude = [
			'scheduling_poc',
			'patient_fee_refund',
			'indirect_cc_minutes',
			'post_negative_minutes',
			'post_session_indirect_cc_minutes',
			'direct_cc_minutes',
			'billing_notes',
		];
		if (
			value &&
			typeof value === 'string' &&
			!exclude.includes(currentField)
		) {
			return moment(value, 'YYYY-MM-DD').isValid();
		} else return false;
	}

	formattedDate(value) {
		if (value) {
			return moment(value).format('MMM DD, YYYY');
		} else return '--';
	}

	getTimestamp() {
		const record = get(this, 'props.record', {}) || {};
		const timeUTC = get(record, 'created_at', null);
		const lastItem = get(this, 'props.lastItem', null);
		const encounterCreationDate = get(
			this,
			'props.encounter.created_at',
			null
		);
		if (lastItem) {
			if (encounterCreationDate)
				return getEncounterSpecificTime(encounterCreationDate);
			else return '--';
		} else {
			try {
				return getEncounterSpecificTime(timeUTC);
			} catch (e) {
				return '--';
			}
		}
	}

	getUsername() {
		const record = get(this, 'props.record', {}) || {};
		return get(record, 'username', '') || '--';
	}

	render() {
		const lastItem = get(this, 'props.lastItem', null);
		const currentField = get(this, 'props.currentField', null);
		let value = this.handleValueGet();
		const isEmptyValue = get(this, 'state.emptyValues', new Set()).has(
			value
		);
		if (
			typeof value !== 'string' &&
			!isEmptyValue &&
			currentField !== 'medical_codes'
		) {
			value = RemoveUnderscoreAndCapitalizeFirstLetter(value.toString());
		}
		return (
			<div
				className='auditTrailHistoryRecordContainer'
				style={{
					borderLeft: lastItem ? 'none' : '2px solid #edeff3',
					marginBottom: lastItem ? '0px' : '11px',
					marginLeft: lastItem ? '6px' : '4px',
					// height: lastItem ? "42px" : "70px"
				}}
			>
				<div className='auditTrailHistoryRecordPoint' />
				<div className='auditTrailHistoryRecordData'>
					<div className='auditTrailHistoryRecordDataMajor'>
						{lastItem ? (
							<span
								className='auditTrailHistoryRecordMajorLabel'
								style={{ minWidth: '140px' }}
							>
								At Encounter Creation:
							</span>
						) : (
							<span
								className='auditTrailHistoryRecordMajorLabel'
								style={{ minWidth: '77px' }}
							>
								Updated to:
							</span>
						)}
						<span className='auditTrailHistoryRecordDataMajorChanged'>
							{' '}
							{isEmptyValue ? '--' : value}
						</span>
					</div>
					{!lastItem && (
						<Tooltip
							className='duplicatePatientTooltipOverflowTooltip'
							effect='dark'
							content={this.getUsername()}
							placement='top'
							disabled={determineWidthOverflow(
								this.minorUsername
							)}
						>
							<div
								className='auditTrailHistoryRecordDataMinor'
								ref={(el) => (this.minorUsername = el)}
							>
								By {this.getUsername() || '--'}
							</div>
						</Tooltip>
					)}
					<div className='auditTrailHistoryRecordDataMinor'>
						On {this.getTimestamp() || '--'}
					</div>
				</div>
			</div>
		);
	}
}

export default AuditTrailHistoryRecord;
