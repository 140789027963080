//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Checkbox } from 'gm-element-react';

//Components
import PatientTag from './../PatientTag.js';

//Styles
import './CheckBoxButton.css';

const CheckBoxButton = (props) => {
	const { value, onChange, options } = props;
	return (
		_.isArray(options) &&
		!_.isEmpty(options) && (
			<div className='checkbox-button-wrapper'>
				<Checkbox.Group value={value} onChange={onChange}>
					{(options || []).map((el) => {
						const tag = _.get(el, 'tag', null);
						return (
							el && (
								<Checkbox
									className={
										!_.isEmpty(value) &&
										value.includes(el.value)
											? 'el-checked'
											: ''
									}
									value={el.value}
									key={el.value}
								>
									{el.label}
									{tag && (
										<PatientTag
											tagType={el.value}
											noMarginBottom={true}
										/>
									)}
								</Checkbox>
							)
						);
					})}
				</Checkbox.Group>
			</div>
		)
	);
};

export default CheckBoxButton;
