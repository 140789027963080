//React & Redux
import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

//Images
import ReactSVG from 'react-svg';
import moreinfoicon from '../../../../assets/purple-more-info.svg';
import warningImg from '../../../../assets/milestone-warning.svg';
import add from '../Tests/images/add.svg';

//Lodash
import _ from 'lodash';
import moment from 'moment';
import Datetime from 'react-datetime';

//Utils
import {
	createEnumsOptions,
	findCascaderHierarchy,
	CascaderOptions,
	getSampleTypeGrouping,
	determineDropdownQuestionnaire,
	determineShippingAddressRequired,
	createStateEnumsOptions,
} from '../Encounters/Common/utilsfunctions';
import { states_list } from './../../../../utils.js';

//UI Libraries
import {
	Layout,
	Select,
	DatePicker,
	Cascader,
	Checkbox,
	Input,
	Radio,
	Button,
} from 'gm-element-react';
import classnames from 'classnames';

//Components
import TestOrderMilestoneWarning from './TestOrderMilestoneWarning';
import ErrorBoundary from '../../../Common/ErrorBoundary';

//Styles
import './create-test-request-modal.css';

const filterOrderStatus = (cascadeenums) => {
	const validstatus = ['requested', 'patient_undecided', 'patient_declined'];
	const nextstatus = _.filter(cascadeenums, (el) => {
		return (
			el &&
			el.key &&
			_.isArray(validstatus) &&
			validstatus.includes(el.key)
		);
	});
	return nextstatus;
};

const DateTypes = ['date_any', 'date_past', 'date_future'];

const CreateTestRequestLabInfo = (props) => {
	const {
		onFormChange,
		formdata = {},
		testorder = {},
		mloOrderStatusEnums = [],
		nonlegacytestOrderPopupTestEnums = [],
		labenums = {},
		shippingAddressFormData = {},
		onShippingAddressFormChange,
		onAddAnotherTestNameFormChange,
		addAnotherTestNameFormData,
		reflexAOEQuestions,
		reflexAOEAnswers = [],
		setReflexAnswers,
		hideModal,
		hasPatientNoInsurance,
	} = props;

	const [optionsLabEnum, setOptionsLabEnum] = useState(labenums);
	const [addAnotherTest2, setAddAnotherTest2] = useState({
		addTest2: addAnotherTestNameFormData?.addTest2 || 'no',
		test_name2: addAnotherTestNameFormData?.test_name2 || undefined,
		enums: [],
	});
	const [addAnotherTest3, setAddAnotherTest3] = useState({
		addTest3: addAnotherTestNameFormData?.addTest3 || 'no',
		test_name3: addAnotherTestNameFormData?.test_name3 || undefined,
		enums: [],
	});
	const [optionsTestNameEnum, setOptionsTestNameEnum] = useState(
		_.isArray(
			createEnumsOptions(
				props,
				['nonlegacytestOrderPopupTestEnums'],
				'key',
				'display_name',
				'key'
			)
		)
			? createEnumsOptions(
					props,
					['nonlegacytestOrderPopupTestEnums'],
					'key',
					'display_name',
					'key'
			  )
			: []
	);
	const [optionsOrderingPhysicianEnum, setOptionsOrderingPhysicianEnum] =
		useState(
			_.isArray(
				createEnumsOptions(
					props,
					['ordering_physicianenums'],
					'key',
					'display_name',
					'key'
				)
			)
				? createEnumsOptions(
						props,
						['ordering_physicianenums'],
						'key',
						'display_name',
						'key'
				  )
				: []
		);
	const [optionsBillingMethodEnum, setOptionsBillingMethodEnum] = useState(
		_.isArray(
			createEnumsOptions(
				props,
				['patientbillingmethodenums'],
				'key',
				'display_name',
				'key'
			)
		)
			? createEnumsOptions(
					props,
					['patientbillingmethodenums'],
					'key',
					'display_name',
					'key'
			  )
			: []
	);
	const [optionsStatesEnum, setOptionsStatesEnum] = useState(
		createStateEnumsOptions(states_list, 'key', 'value', 'key')
	);
	const [optionsSampleTypesEnum, setOptionsSampleTypesEnum] = useState(
		_.isArray(
			createEnumsOptions(
				props,
				['sampletypeenums'],
				'key',
				'display_name',
				'key'
			)
		)
			? createEnumsOptions(
					props,
					['sampletypeenums'],
					'key',
					'display_name',
					'key'
			  )
			: []
	);

	useEffect(() => {
		const _testNameEnum = createEnumsOptions(
			props,
			['nonlegacytestOrderPopupTestEnums'],
			'key',
			'display_name',
			'key'
		);
		setOptionsTestNameEnum(_testNameEnum);
	}, [nonlegacytestOrderPopupTestEnums]);

	useEffect(() => {
		if (addAnotherTestNameFormData?.addTest2 === 'yes') {
			const _addAnotherTest2 = addAnotherTest2;
			const testName = _.get(formdata, ['test_name'], '');
			const testName1Detail = _.find(
				nonlegacytestOrderPopupTestEnums || [],
				{ key: testName }
			);
			const testName3Key = _.get(addAnotherTest3, ['test_name3'], '');
			if (testName1Detail && testName1Detail.group) {
				const tempArr = [];
				const testName1GroupUUID = _.get(
					testName1Detail,
					['group', 'uuid'],
					''
				);
				_.forEach(nonlegacytestOrderPopupTestEnums, function (value) {
					if (
						_.get(value, ['group', 'uuid'], '') ===
							testName1GroupUUID &&
						value.key !== testName1Detail.key &&
						value.key !== testName3Key
					) {
						tempArr.push({
							key: value?.key,
							value: value?.key,
							label: value?.display_name,
						});
					}
				});
				_addAnotherTest2['enums'] = tempArr;
			}
			setAddAnotherTest2((oldState) => ({
				...oldState,
				..._addAnotherTest2,
			}));
		}
		if (addAnotherTestNameFormData?.addTest3 === 'yes') {
			const _addAnotherTest3 = addAnotherTest3;
			const testName = _.get(formdata, ['test_name'], '');
			const testName2Key = _.get(addAnotherTest2, ['test_name2'], '');
			const testName1Detail = _.find(
				nonlegacytestOrderPopupTestEnums || [],
				{ key: testName }
			);
			if (testName1Detail && testName1Detail.group) {
				const tempArr = [];
				const testName1GroupUUID = _.get(
					testName1Detail,
					['group', 'uuid'],
					''
				);
				_.forEach(nonlegacytestOrderPopupTestEnums, function (value) {
					if (
						_.get(value, ['group', 'uuid'], '') ===
							testName1GroupUUID &&
						value.key !== testName1Detail.key &&
						value.key !== testName2Key
					) {
						tempArr.push({
							key: value?.key,
							value: value?.key,
							label: value?.display_name,
						});
					}
				});
				_addAnotherTest3['enums'] = tempArr;
			}
			setAddAnotherTest3((oldState) => ({
				...oldState,
				..._addAnotherTest3,
			}));
		}
	}, [addAnotherTestNameFormData]);

	const handleChange = (key, value) => {
		onFormChange(key, value);
		if (key === 'lab' || key === 'test_name') {
			setAddAnotherTest2((oldState) => ({
				...oldState,
				addTest2: 'no',
				test_name2: undefined,
				enums: [],
			}));
			setAddAnotherTest3((oldState) => ({
				...oldState,
				addTest3: 'no',
				test_name3: undefined,
				enums: [],
			}));
		}
	};

	const onShippingAddressFormChangeFunc = (key, value) => {
		onShippingAddressFormChange(key, value);
	};

	const isSelectedLabEmpty = () => {
		const lab = _.get(formdata, ['lab'], '');
		const labschema = labenums;
		const labvalue = labschema.find((a) => a.key == lab);
		return labvalue && _.get(labvalue, 'display_name', '') == '';
	};

	const isTestNameEditable = () => {
		const lab = _.get(formdata, ['lab'], '');
		if (lab == '') {
			return false;
		} else {
			return !isSelectedLabEmpty();
		}
	};

	const renderTestOrderMilestoneWarning = () => {
		return (
			<TestOrderMilestoneWarning
				testorder={testorder}
				propsOrderStatus={_.get(testorder, ['localorderstatus'], null)}
				stateOrderStatus={_.get(formdata, ['order_status'], null)}
				mloOrderStatusEnums={mloOrderStatusEnums}
			/>
		);
	};

	const filterMloOrderStatusEnums = () => {
		const mloorderstatus = mloOrderStatusEnums.map((e) => {
			if (_.get(e, ['children', '0'], null) != null) {
				let statusReason = e.children;
				if (e.key == 'patient_declined') {
					statusReason = _.filter(e.children, function (o) {
						return o.key !== 'declined_via_partner';
					});
				} else statusReason = e.children;

				return {
					key: e.key,
					display_name: e.display_name,
					children: statusReason,
				};
			} else {
				return e;
			}
		});
		return mloorderstatus;
	};

	const handleAddAnotherTest2 = (key, value) => {
		const _addAnotherTest2 = addAnotherTest2;
		_addAnotherTest2[key] = value;
		onAddAnotherTestNameFormChange(key, value);
		if ((key = 'addTest2' && value == 'yes')) {
			const testName = _.get(formdata, ['test_name'], '');
			const testName3Key = _.get(addAnotherTest3, ['test_name3'], '');
			const findTestDetail = _.find(
				nonlegacytestOrderPopupTestEnums || [],
				{ key: testName }
			);
			if (findTestDetail && findTestDetail.group) {
				const tempArr = [];
				const testName1GroupUUID = _.get(
					findTestDetail,
					['group', 'uuid'],
					''
				);
				_.forEach(
					nonlegacytestOrderPopupTestEnums,
					function (value, key) {
						if (
							_.get(value, ['group', 'uuid'], '') ===
								testName1GroupUUID &&
							value.key !== findTestDetail.key &&
							value.key !== testName3Key
						) {
							tempArr.push({
								key: value?.key,
								value: value?.key,
								label: value?.display_name,
							});
						}
					}
				);
				_addAnotherTest2['enums'] = tempArr;
			}
		}
		setAddAnotherTest2((oldState) => ({
			...oldState,
			..._addAnotherTest2,
		}));
		if (addAnotherTest3.addTest3 !== 'yes') {
			setAddAnotherTest3((oldState) => ({
				...oldState,
				addTest3: 'no',
				test_name3: undefined,
				enums: [],
			}));
		}
	};

	const handleAddAnotherTest3 = (key, value) => {
		const _addAnotherTest3 = addAnotherTest3;
		_addAnotherTest3[key] = value;
		onAddAnotherTestNameFormChange(key, value);
		if ((key = 'addTest3' && value == 'yes')) {
			const testName = _.get(formdata, ['test_name'], '');
			const testName2Key = _.get(addAnotherTest2, ['test_name2'], '');
			const testName1Detail = _.find(
				nonlegacytestOrderPopupTestEnums || [],
				{ key: testName }
			);
			if (testName1Detail && testName1Detail.group) {
				const tempArr = [];
				const testName1GroupUUID = _.get(
					testName1Detail,
					['group', 'uuid'],
					''
				);
				_.forEach(
					nonlegacytestOrderPopupTestEnums,
					function (value, key) {
						if (
							_.get(value, ['group', 'uuid'], '') ===
								testName1GroupUUID &&
							value.key !== testName1Detail.key &&
							value.key !== testName2Key
						) {
							tempArr.push({
								key: value?.key,
								value: value?.key,
								label: value?.display_name,
							});
						}
					}
				);
				_addAnotherTest3['enums'] = tempArr;
			}
		} else if (addAnotherTest2.addTest2 === 'yes') {
			const _addAnotherTest2 = {};
			const testName = _.get(formdata, ['test_name'], '');
			const testName3Key = value;
			const findTestDetail = _.find(
				nonlegacytestOrderPopupTestEnums || [],
				{ key: testName }
			);
			if (findTestDetail && findTestDetail.group) {
				const tempArr = [];
				const testName1GroupUUID = _.get(
					findTestDetail,
					['group', 'uuid'],
					''
				);
				_.forEach(nonlegacytestOrderPopupTestEnums, function (value) {
					if (
						_.get(value, ['group', 'uuid'], '') ===
							testName1GroupUUID &&
						value.key !== findTestDetail.key &&
						value.key !== testName3Key
					) {
						tempArr.push({
							key: value?.key,
							value: value?.key,
							label: value?.display_name,
						});
					}
				});
				_addAnotherTest2['enums'] = tempArr;
			}

			setAddAnotherTest2((oldState) => ({
				...oldState,
				enums: _addAnotherTest2.enums,
			}));
		}

		setAddAnotherTest3((oldState) => ({
			...oldState,
			..._addAnotherTest3,
		}));
	};

	const handleReflexQuestionFieldChange = (key, value) => {
		const newReflexAnswers = {
			...reflexAOEAnswers,
			[key]: value,
		};

		for (const { name, depends_on, default_option } of reflexAOEQuestions) {
			if (
				!_.isEmpty(depends_on) &&
				depends_on?.name === key &&
				depends_on?.value !== value
			)
				newReflexAnswers[name] = '';
			else if (!_.isNil(default_option)) {
				newReflexAnswers[name] = default_option;
			}
		}

		setReflexAnswers({ ...newReflexAnswers });
	};

	const isRequiredReflexQuestion = (required, dependsOn) => {
		if (required === 'yes') {
			return true;
		} else if (
			required === 'conditional' &&
			!_.isEmpty(dependsOn) &&
			_.get(reflexAOEAnswers, [dependsOn.name], null) === dependsOn.value
		) {
			return true;
		} else if (required === 'no') {
			return false;
		}
		return false;
	};

	const renderReflexQuestionField = ({
		default_option = null,
		display_text,
		name,
		type,
		is_required,
		options,
		depends_on,
	}) => {
		const isDisabled =
			!_.isEmpty(depends_on) &&
			_.get(reflexAOEAnswers, [depends_on.name], null) !==
				depends_on.value;

		if (type === 'enum' && !_.isEmpty(options)) {
			return (
				<>
					<span
						className={classnames({
							required: isRequiredReflexQuestion(
								is_required,
								depends_on
							),
						})}
					>
						{display_text}
					</span>
					<Select
						name={name}
						value={_.get(reflexAOEAnswers, [name], default_option)}
						disabled={isDisabled}
						onChange={(value) => handleReflexQuestionFieldChange(name, value)}
					>
						{options.map((option) => (
							<Select.Option
								key={option}
								label={option}
								value={option}
							/>
						))}
					</Select>
				</>
			);
		} else if (DateTypes.includes(type)) {
			return (
				<>
					<span
						className={classnames({
							required: isRequiredReflexQuestion(
								is_required,
								depends_on
							),
						})}
					>
						{display_text}
					</span>
					<Datetime
						value={
							moment(
								_.get(reflexAOEAnswers, [name], default_option)
							).isValid()
								? moment(
										_.get(reflexAOEAnswers, [name], default_option)
								  ).toDate()
								: ''
						}
						dateFormat={'MM/DD/YYYY'}
						timeFormat={false}
						isDisabled={isDisabled}
						onChange={(value) => handleReflexQuestionFieldChange(name, value)}
						isValidDate={(current) => {
							const today = moment();
							if (type === 'date_future') {
								return current.isSameOrAfter(today, 'day');
							} else if (type === 'date_past') {
								return current.isSameOrBefore(today, 'day');
							}
						}}
						className='testrequestdatefield'
						inputProps={{
							placeholder: 'MM/DD/YYYY',
						}}
						strictParsing={true}
						closeOnTab={true}
						closeOnSelect={true}
					/>
				</>
			);
		} else if (type === 'text') {
			return (
				<>
					<span
						className={classnames({
							required: isRequiredReflexQuestion(
								is_required,
								depends_on
							),
						})}
					>
						{display_text}
					</span>
					<Input
						name={name}
						value={_.get(reflexAOEAnswers, [name], default_option)}
						placeholder={display_text}
						trim={true}
						disabled={isDisabled}
						onChange={(value) => handleReflexQuestionFieldChange(name, value)}
						maxLength={255}
					/>
				</>
			);
		}

		return null;
	};

	const sampleTypes = getSampleTypeGrouping(optionsSampleTypesEnum);
	const testName = _.get(formdata, 'test_name');
	const stateorderstatus = _.get(formdata, ['order_status'], {});
	const dropdownQuestionnaire = determineDropdownQuestionnaire(
		testName,
		nonlegacytestOrderPopupTestEnums
	);
	const showShippingAddress = determineShippingAddressRequired(
		testName,
		nonlegacytestOrderPopupTestEnums,
		stateorderstatus
	);
	const labIntegrationEnabled = _.find(
		nonlegacytestOrderPopupTestEnums || [],
		{ key: formdata?.test_name }
	)?.lab_integration_enabled;
	const testName1group =
		_.find(nonlegacytestOrderPopupTestEnums || [], {
			key: formdata?.test_name,
		})?.group || {};
	const testNameGroup = _.filter(
		nonlegacytestOrderPopupTestEnums || [],
		function (to) {
			return (
				!_.isEmpty(to.group.uuid) &&
				!_.isNil(to.group.uuid) &&
				testName1group?.uuid === to.group.uuid
			);
		}
	);

	const changeTab = (value) => {
		const enctype = 0;
		const params = new URLSearchParams(
			_.get(props, ['location', 'search'], '')
		);
		const order_by = params.get('order_by');
		const order_type = params.get('order_type');
		const offset = params.get('offset');
		props.history.push({
			pathname:
				'/app/patientdetail/' +
				_.get(props, 'match.params.patientid', 0) +
				'/' +
				value +
				'/' +
				enctype,
			search:
				'?offset=' +
				(offset || 0) +
				'&order_by=' +
				(order_by || '') +
				'&order_type=' +
				(order_type || 'DESC'),
			state: _.get(props, 'location.state', {}),
		});
	};

	return (
		<Fragment>
			<section className='create-test-rqst-labinfo-container create-test-rqst-labinfo-container-v2'>
				<h2 className='module-header'>Test Info</h2>

				<Layout.Row>
					<Layout.Col span='24' className='required'>
						Lab
					</Layout.Col>
					<Layout.Col span='24'>
						<ErrorBoundary>
							<Select
								name='lab'
								value={_.get(formdata, ['lab'], '')}
								placeholder='Select'
								onChange={(value) => handleChange('lab', value)}
								clearable={true}
								filterable={true}
								className='smaller-dropdown'
								filterMethod={(value) => {
									if (_.isEmpty(value) || _.isNil(value)) {
										setOptionsLabEnum(labenums);
									} else {
										const _options = labenums.filter(
											(o) =>
												o.display_name &&
												o.display_name
													.toLowerCase()
													.startsWith(
														value.toLowerCase()
													)
										);
										setOptionsLabEnum(_options);
									}
								}}
							>
								{optionsLabEnum.map((el) => {
									return (
										<Select.Option
											label={el.display_name || ' '}
											value={el.key}
											className='select-lab'
											key={el.key}
										>
											<span className='lab-name'>
												{el.display_name}
											</span>
											{el.paper_form_only == true && (
												<span className='lab-paper-trf'>
													Paper TRF
												</span>
											)}
										</Select.Option>
									);
								})}
							</Select>
						</ErrorBoundary>
					</Layout.Col>
				</Layout.Row>

				<Layout.Row className='test-name'>
					<Layout.Col span='24' className='required'>
						{labIntegrationEnabled && testNameGroup.length > 1
							? 'Test Name (1)'
							: 'Test Name'}
					</Layout.Col>
					<Layout.Col span='24'>
						<ErrorBoundary>
							<Select
								name='test_name'
								value={_.get(formdata, ['test_name'], '')}
								placeholder='Select'
								disabled={!isTestNameEditable()}
								onChange={(value) =>
									handleChange('test_name', value)
								}
								clearable={isTestNameEditable()}
								filterable={isTestNameEditable()}
								className='smaller-dropdown'
								filterMethod={(value) => {
									if (isTestNameEditable()) {
										const _testNameEnum =
											createEnumsOptions(
												props,
												[
													'nonlegacytestOrderPopupTestEnums',
												],
												'key',
												'display_name',
												'key'
											);
										if (
											_.isEmpty(value) ||
											_.isNil(value)
										) {
											setOptionsTestNameEnum(
												_testNameEnum
											);
										} else {
											const _options =
												_testNameEnum.filter(
													(o) =>
														o.label &&
														o.label
															.toLowerCase()
															.startsWith(
																value.toLowerCase()
															)
												);
											setOptionsTestNameEnum(_options);
										}
									}
								}}
							>
								{isTestNameEditable() &&
									optionsTestNameEnum.map((el) => {
										return (
											<Select.Option
												className='mlo-test-name-list'
												key={el.value}
												label={el.label}
												value={el.value}
											/>
										);
									})}
							</Select>
						</ErrorBoundary>
					</Layout.Col>
				</Layout.Row>

				{_.get(formdata, ['test_name'], '') && labIntegrationEnabled && (
					<Layout.Row className='row-checkbox-order-test-manually'>
						<Layout.Col span='24'>
							<Checkbox
								checked={!formdata?.lab_integration_enabled}
								onChange={(value) => {
									handleChange(
										'lab_integration_enabled',
										!value
									);
								}}
								className='chk-order-test-manually'
							>
								Order test(s) manually
							</Checkbox>
						</Layout.Col>
					</Layout.Row>
				)}

				{_.get(formdata, ['test_name'], '') && labIntegrationEnabled && (
					<Layout.Row className='integration-supported'>
						<Layout.Col span='24'>
							<div className='milestone-warning-container'>
								<div className='milestone-warning-body'>
									<div className='milestone-warning-icon'>
										<ReactSVG src={moreinfoicon} />
									</div>
									<div className='milestone-warning-text'>
										Check "Order test(s) manually" for
										sponsor or re-requisition testing.
									</div>
								</div>
							</div>
						</Layout.Col>
					</Layout.Row>
				)}

				{_.get(formdata, ['test_name'], '') && !labIntegrationEnabled && (
					<Layout.Row className='integration-not-supported'>
						<Layout.Col span='24'>
							<div className='milestone-error-container'>
								<div className='milestone-error-body'>
									<div className='milestone-error-icon'>
										<ReactSVG src={warningImg} />
									</div>
									<div className='milestone-error-text'>
										Please place this order manually. This
										test cannot be ordered through EMR
										integration.
									</div>
								</div>
							</div>
						</Layout.Col>
					</Layout.Row>
				)}

				{labIntegrationEnabled &&
					!_.isEmpty(testName1group) &&
					testNameGroup.length > 1 &&
					formdata?.lab_integration_enabled && (
						<Fragment>
							<Layout.Row className='add-another-test'>
								<Layout.Col span='24' className='required'>
									Add another simultaneous test?
								</Layout.Col>
								<Layout.Col span='24'>
									<Radio.Group
										className='gmi-radio-group add-another-test-radio'
										value={addAnotherTest2.addTest2}
										onChange={(value) =>
											handleAddAnotherTest2(
												'addTest2',
												value
											)
										}
									>
										<Radio value={'no'}>No</Radio>
										<Radio value={'yes'}>Yes</Radio>
									</Radio.Group>
								</Layout.Col>
							</Layout.Row>
							{addAnotherTest2.addTest2 === 'yes' && (
								<Fragment>
									<Layout.Row>
										<Layout.Col
											span='24'
											className='required'
										>
											Test Name (2)
										</Layout.Col>
										<Layout.Col span='24'>
											<ErrorBoundary>
												<Select
													name='anotherTestName'
													value={
														addAnotherTest2.test_name2
													}
													placeholder='Select'
													onChange={(value) =>
														handleAddAnotherTest2(
															'test_name2',
															value
														)
													}
													className='smaller-dropdown'
													clearable={true}
													filterable={true}
													filterMethod={(value) => {
														const _testNameEnum =
															[];
														const testName = _.get(
															formdata,
															['test_name'],
															''
														);
														const findTestDetail =
															_.find(
																nonlegacytestOrderPopupTestEnums ||
																	[],
																{
																	key: testName,
																}
															);
														if (
															findTestDetail &&
															findTestDetail.group
														) {
															const testName1GroupUUID =
																_.get(
																	findTestDetail,
																	[
																		'group',
																		'uuid',
																	],
																	''
																);
															_.forEach(
																nonlegacytestOrderPopupTestEnums,
																function (
																	value,
																	key
																) {
																	if (
																		_.get(
																			value,
																			[
																				'group',
																				'uuid',
																			],
																			''
																		) ===
																			testName1GroupUUID &&
																		value.key !==
																			findTestDetail.key
																	) {
																		_testNameEnum.push(
																			{
																				key: value?.key,
																				value: value?.key,
																				label: value?.display_name,
																			}
																		);
																	}
																}
															);
														}
														if (
															_.isEmpty(value) ||
															_.isNil(value)
														) {
															setAddAnotherTest2(
																(oldState) => ({
																	...oldState,
																	enums: _testNameEnum,
																})
															);
														} else {
															const _options =
																_testNameEnum.filter(
																	(o) =>
																		o.label &&
																		o.label
																			.toLowerCase()
																			.startsWith(
																				value.toLowerCase()
																			)
																);
															setAddAnotherTest2(
																(oldState) => ({
																	...oldState,
																	enums: _options,
																})
															);
														}
													}}
												>
													{addAnotherTest2.enums.map(
														(el) => {
															return (
																<Select.Option
																	className='mlo-test-name-list'
																	key={
																		el.value
																	}
																	label={
																		el.label
																	}
																	value={
																		el.value
																	}
																/>
															);
														}
													)}
												</Select>
											</ErrorBoundary>
										</Layout.Col>
									</Layout.Row>
								</Fragment>
							)}

							{labIntegrationEnabled &&
								!_.isEmpty(testName1group) &&
								testNameGroup.length > 2 &&
								formdata?.lab_integration_enabled &&
								(addAnotherTest2?.addTest2 === 'yes' ||
									addAnotherTest3.addTest3 === 'yes') && (
									<Layout.Row className='add-another-test'>
										<Layout.Col
											span='24'
											className='required'
										>
											Add another simultaneous test?
										</Layout.Col>
										<Layout.Col span='24'>
											<Radio.Group
												className='gmi-radio-group add-another-test-radio'
												value={addAnotherTest3.addTest3}
												onChange={(value) =>
													handleAddAnotherTest3(
														'addTest3',
														value
													)
												}
											>
												<Radio value={'no'}>No</Radio>
												<Radio value={'yes'}>Yes</Radio>
											</Radio.Group>
										</Layout.Col>
									</Layout.Row>
								)}
							{addAnotherTest3.addTest3 === 'yes' && (
								<Layout.Row>
									<Layout.Col span='24' className='required'>
										Test Name (3)
									</Layout.Col>
									<Layout.Col span='24'>
										<ErrorBoundary>
											<Select
												name='test_name3'
												value={
													addAnotherTest3.test_name3
												}
												placeholder='Select'
												onChange={(value) =>
													handleAddAnotherTest3(
														'test_name3',
														value
													)
												}
												className='smaller-dropdown'
												clearable={true}
												filterable={true}
												filterMethod={(value) => {
													const _testNameEnum = [];
													const testName = _.get(
														formdata,
														['test_name'],
														''
													);
													const testName2Key = _.get(
														addAnotherTest2,
														['test_name2'],
														''
													);
													const testName1Detail =
														_.find(
															nonlegacytestOrderPopupTestEnums ||
																[],
															{ key: testName }
														);
													if (
														testName1Detail &&
														testName1Detail.group
													) {
														const testName1GroupUUID =
															_.get(
																testName1Detail,
																[
																	'group',
																	'uuid',
																],
																''
															);
														_.forEach(
															nonlegacytestOrderPopupTestEnums,
															function (
																value,
																key
															) {
																if (
																	_.get(
																		value,
																		[
																			'group',
																			'uuid',
																		],
																		''
																	) ===
																		testName1GroupUUID &&
																	value.key !==
																		testName1Detail.key &&
																	value.key !==
																		testName2Key
																) {
																	_testNameEnum.push(
																		{
																			key: value?.key,
																			value: value?.key,
																			label: value?.display_name,
																		}
																	);
																}
															}
														);
													}
													if (
														_.isEmpty(value) ||
														_.isNil(value)
													) {
														setAddAnotherTest3(
															(oldState) => ({
																...oldState,
																enums: _testNameEnum,
															})
														);
													} else {
														const _options =
															_testNameEnum.filter(
																(o) =>
																	o.label &&
																	o.label
																		.toLowerCase()
																		.startsWith(
																			value.toLowerCase()
																		)
															);
														setAddAnotherTest3(
															(oldState) => ({
																...oldState,
																enums: _options,
															})
														);
													}
												}}
											>
												{addAnotherTest3.enums.map(
													(el) => {
														return (
															<Select.Option
																className='mlo-test-name-list'
																key={el.value}
																label={el.label}
																value={el.value}
															/>
														);
													}
												)}
											</Select>
										</ErrorBoundary>
									</Layout.Col>
								</Layout.Row>
							)}
						</Fragment>
					)}

				{formdata?.lab_integration_enabled && !_.isEmpty(reflexAOEQuestions) && _.isArray(reflexAOEQuestions) &&
					reflexAOEQuestions.map((reflexQuestion) => {
						return (
							<Layout.Row key={reflexQuestion.name}>
								<Layout.Col span='24'>
									{renderReflexQuestionField(reflexQuestion)}
								</Layout.Col>
							</Layout.Row>
						);
					})
				}

				<Layout.Row className='order-request-status'>
					<Layout.Col span='24' className='required'>
						Order Request Status
					</Layout.Col>
					<Layout.Col span='24'>
						<Cascader
							name={'order_status'}
							value={findCascaderHierarchy(
								mloOrderStatusEnums,
								'key',
								_.get(formdata, ['order_status'], '')
							)}
							onChange={(value) =>
								handleChange('order_status', value)
							}
							showAllLevels={false}
							expandTrigger='hover'
							options={CascaderOptions(
								filterOrderStatus(filterMloOrderStatusEnums()),
								'key',
								'display_name',
								'key'
							)}
							className='smaller-cascader'
						/>
						{renderTestOrderMilestoneWarning()}
					</Layout.Col>
				</Layout.Row>

				{dropdownQuestionnaire && (
					<Layout.Row>
						<Layout.Col span='24' className='required'>
							Patient Details Requested by Lab (check all that
							apply)
						</Layout.Col>
						<Layout.Col span='24'>
							<Select
								name='order_questionnaire'
								value={_.get(
									formdata,
									['order_questionnaire'],
									''
								)}
								placeholder='Select'
								onChange={(value) =>
									handleChange('order_questionnaire', value)
								}
								multiple={true}
								className='test-request-multiselect'
								filterable={true}
								clearable={true}
							>
								{dropdownQuestionnaire.map((el) => {
									return (
										<Select.Option
											className='mlo-test-name-list'
											key={el.option}
											label={el.display_text}
											value={el.option}
										/>
									);
								})}
							</Select>
						</Layout.Col>
					</Layout.Row>
				)}

				<Layout.Row gutter='16'>
					<Layout.Col span='12'>
						<span>Ordering Physician</span>
						<Select
							value={_.get(formdata, ['ordering_physician'], '')}
							name='ordering_physician'
							placeholder='Select'
							onChange={(value) =>
								handleChange('ordering_physician', value)
							}
							clearable={true}
							filterable={true}
							filterMethod={(value) => {
								const _orderingPhysicianEnum =
									createEnumsOptions(
										props,
										['ordering_physicianenums'],
										'key',
										'display_name',
										'key'
									);
								if (_.isEmpty(value) || _.isNil(value)) {
									setOptionsOrderingPhysicianEnum(
										_orderingPhysicianEnum
									);
								} else {
									const _options =
										_orderingPhysicianEnum.filter(
											(o) =>
												o.label &&
												o.label
													.toLowerCase()
													.startsWith(
														value.toLowerCase()
													)
										);
									setOptionsOrderingPhysicianEnum(_options);
								}
							}}
						>
							{optionsOrderingPhysicianEnum.map((el) => {
								return (
									<Select.Option
										key={el.value}
										label={el.label}
										value={el.value}
									/>
								);
							})}
						</Select>
					</Layout.Col>
					<Layout.Col span='12'>
						<span>Test Authorization Date</span>
						<DatePicker
							value={_.get(formdata, ['testAuthDate'], null)}
							placeholder={'MM/DD/YYYY'}
							autoComplete='off'
							isReadOnly={true}
							format='MM/dd/yyyy'
							isDisabled={true}
							className='smaller-datepicker'
						/>
					</Layout.Col>
				</Layout.Row>

				<Layout.Row gutter='16'>
					<Layout.Col span='24'>
						<span>Notes</span>
						<Input
							type='textarea'
							name='note'
							id='note'
							autosize={{ minRows: 2, maxRows: 4 }}
							value={_.get(formdata, ['note'], '')}
							placeholder='Add a note'
							onChange={(value) => handleChange('note', value)}
						/>
					</Layout.Col>
				</Layout.Row>

				<h2 className='module-header'>Sample Info</h2>

				<Layout.Row gutter='16'>
					<Layout.Col span='12'>
						<span className='required'>
							Sample Type (Preferred)
						</span>
						<Select
							name='sample_type'
							value={_.get(formdata, ['sample_type'], '')}
							placeholder='Select'
							onChange={(value) =>
								handleChange('sample_type', value)
							}
							clearable={true}
							filterable={true}
							filterMethod={(value) => {
								const _optionSampleTypeEnum =
									createEnumsOptions(
										props,
										['sampletypeenums'],
										'key',
										'display_name',
										'key'
									);
								if (_.isEmpty(value) || _.isNil(value)) {
									setOptionsSampleTypesEnum(
										_optionSampleTypeEnum
									);
								} else {
									const _options =
										_optionSampleTypeEnum.filter(
											(o) =>
												o.label &&
												o.label
													.toLowerCase()
													.startsWith(
														value.toLowerCase()
													)
										);
									setOptionsSampleTypesEnum(_options);
								}
							}}
						>
							{_.get(sampleTypes, 'non-others', []).map((el) => {
								return (
									<Select.Option
										key={el.value}
										label={el.label}
										value={el.value}
									/>
								);
							})}
							{_.get(sampleTypes, 'others', false) && (
								<Select.OptionGroup
									key={'other'}
									label='OTHER'
									className='sample-type-other'
								>
									{_.get(sampleTypes, 'others', []).map(
										(el) => {
											return (
												<Select.Option
													key={el.value}
													label={el.label}
													value={el.value}
												/>
											);
										}
									)}
								</Select.OptionGroup>
							)}
						</Select>
					</Layout.Col>
				</Layout.Row>

				<h2 className='module-header'>Billing Info</h2>

				<Layout.Row gutter='16'>
					<Layout.Col span='12'>
						<span className='required'>Test Billing Method</span>
						<Select
							name='patient_billing_method'
							value={_.get(
								formdata,
								['patient_billing_method'],
								''
							)}
							placeholder='Select'
							onChange={(value) =>
								handleChange('patient_billing_method', value)
							}
							clearable={true}
							filterable={true}
							filterMethod={(value) => {
								const _billingMethodEnum = createEnumsOptions(
									props,
									['patientbillingmethodenums'],
									'key',
									'display_name',
									'key'
								);
								if (_.isEmpty(value) || _.isNil(value)) {
									setOptionsBillingMethodEnum(
										_billingMethodEnum
									);
								} else {
									const _options = _billingMethodEnum.filter(
										(o) =>
											o.label &&
											o.label
												.toLowerCase()
												.startsWith(value.toLowerCase())
									);
									setOptionsBillingMethodEnum(_options);
								}
							}}
						>
							{optionsBillingMethodEnum.map((el) => {
								return (
									<Select.Option
										key={el.value}
										label={el.label}
										value={el.value}
									/>
								);
							})}
						</Select>
					</Layout.Col>
				</Layout.Row>

				{hasPatientNoInsurance() && (
					<>
						<Layout.Row gutter='16'>
							<Layout.Col span='24'>
								<div className='noinsunrace-info-container'>
									<div className='noinsunrace-info-body'>
										<div className='milestone-warning-icon'>
											<ReactSVG src={warningImg} />
										</div>
										<div className='noinsunrace-info-text'>
											This patient does not have any
											insurance info on file. Click
											the button below to add insurance.
										</div>
									</div>
								</div>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row gutter='16'>
							<Layout.Col span='24'>
								<Button
									className='add-insuranceinfo-button'
									onClick={() => {
										hideModal();
										changeTab(5);
									}}
								>
									<ReactSVG className='add-icon' src={add} />
									<div>Add Insurance Info</div>
								</Button>
							</Layout.Col>
						</Layout.Row>
					</>
				)}

				{showShippingAddress && (
					<Fragment>
						<h2 className='module-header'>Shipping Address</h2>

						<Layout.Row>
							<Layout.Col span='24' className='required'>
								<span>Address Line 1</span>
								<Input
									name='patient_address_line1'
									value={_.get(
										shippingAddressFormData,
										['patient_address_line1'],
										''
									)}
									placeholder='Address Line 1'
									onChange={(value) =>
										onShippingAddressFormChangeFunc(
											'patient_address_line1',
											value
										)
									}
									trim={true}
								/>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row gutter='16'>
							<Layout.Col span='8'>
								<span>Address Line 2</span>
								<Input
									name='patient_address_line2'
									value={_.get(
										shippingAddressFormData,
										['patient_address_line2'],
										''
									)}
									placeholder='Address Line 2'
									onChange={(value) =>
										onShippingAddressFormChangeFunc(
											'patient_address_line2',
											value
										)
									}
									trim={true}
								/>
							</Layout.Col>
							<Layout.Col span='16' className='required'>
								<span>City</span>
								<Input
									name='patient_shipping_address_city'
									value={_.get(
										shippingAddressFormData,
										['patient_shipping_address_city'],
										''
									)}
									placeholder='Address'
									onChange={(value) =>
										onShippingAddressFormChangeFunc(
											'patient_shipping_address_city',
											value
										)
									}
									trim={true}
								/>
							</Layout.Col>
						</Layout.Row>
						<Layout.Row gutter='16'>
							<Layout.Col span='6' className='required'>
								<span>State</span>
								<Select
									name='patient_shipping_address_state'
									value={_.get(
										shippingAddressFormData,
										['patient_shipping_address_state'],
										''
									)}
									onChange={(value) =>
										onShippingAddressFormChangeFunc(
											'patient_shipping_address_state',
											value
										)
									}
									placeholder='--'
									clearable={true}
									filterable={true}
									filterMethod={(val) => {
										const _states = createStateEnumsOptions(
											states_list,
											'key',
											'value',
											'key'
										);
										if (_.isEmpty(val) || _.isNil(val)) {
											setOptionsStatesEnum(_states);
										} else {
											const _options = _states.filter(
												(o) =>
													o.label
														.toString()
														.toLowerCase()
														.startsWith(
															val.toLowerCase()
														) ||
													o.key
														.toLowerCase()
														.startsWith(
															val.toLowerCase()
														)
											);
											setOptionsStatesEnum(_options);
										}
									}}
								>
									{optionsStatesEnum.map((el) => (
										<Select.Option
											key={el.key}
											label={el.value}
											value={el.value}
										>
											{el.label}
										</Select.Option>
									))}
								</Select>
							</Layout.Col>
							<Layout.Col span='10' className='required'>
								<span>Zip Code</span>
								<Input
									name='patient_shipping_address_zipcode`'
									value={_.get(
										shippingAddressFormData,
										['patient_shipping_address_zipcode'],
										''
									)}
									placeholder='Zip Code'
									onChange={(value) =>
										onShippingAddressFormChangeFunc(
											'patient_shipping_address_zipcode',
											value
										)
									}
									trim={true}
								/>
							</Layout.Col>
						</Layout.Row>
					</Fragment>
				)}
			</section>
		</Fragment>
	);
};

export default withRouter(CreateTestRequestLabInfo);
