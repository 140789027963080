//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import searchIcon from './../../../assets/ic_search.svg';
import xIcon from './../../../assets/exit-alert.svg';

//Components
import TagSearchResults from './TagSearchResults.js';

const TagSearch = (props) => {
	const {
		bottomLoading,
		handleSearchQueryChange,
		icdCodes,
		icdCodesLoading,
		initialQueryLoading,
		lazyLoadBottom,
		lazyLoadTop,
		scrollHandler,
		searchQuery = '',
		selectAll,
		selectedICDCodes = [],
		selectNone,
		setBodyElement,
		shouldHaveTopDropShadow = false,
		toggleCodeSelection,
		topLoading,
	} = props;

	const handleSearchChange = (e) => {
		handleSearchQueryChange(e?.target?.value || '');
	};

	const searchReset = () => {
		handleSearchQueryChange('');
	};

	const renderComponent = () => {
		return (
			<TagSearchResults
				{...{
					icdCodes,
					selectedICDCodes,
					toggleCodeSelection,
					searchQuery,
					icdCodesLoading,
					initialQueryLoading,
					topLoading,
					bottomLoading,
					lazyLoadTop,
					lazyLoadBottom,
					setBodyElement,
					scrollHandler,
				}}
			/>
		);
	};

	const query = searchQuery || '';
	const containerClass = shouldHaveTopDropShadow
		? 'chargeICDSearchRowContainer scrollableModalDropShadowTopInside'
		: 'chargeICDSearchRowContainer';
	return (
		<div className='chargeICDSearchContainer'>
			<div className={containerClass}>
				<div
					className='chargeICDSearchBarContainer refProgMultiselectSearchbarContainer'
					style={{ width: '72%' }}
				>
					<input
						className='chargeICDSearchInput'
						placeholder='Search'
						onChange={(e) => handleSearchChange(e)}
						value={query}
					/>
					{query.length > 0 ? (
						<ReactSVG
							className='chargeICDSearchXIcon'
							src={xIcon}
							style={{
								position: 'relative',
								bottom: '6px',
								left: '2px',
							}}
							onClick={searchReset}
						/>
					) : (
						<ReactSVG
							className='chargeICDSearchIcon'
							src={searchIcon}
							style={{
								position: 'relative',
								bottom: '1px',
								right: '5px',
							}}
						/>
					)}
				</div>
				<div className='chargeICDSearchToggle' onClick={selectAll}>
					Select All
				</div>
				<div
					className='chargeICDSearchToggle'
					onClick={selectNone}
					style={{ margin: '0' }}
				>
					Select None
				</div>
			</div>
			{renderComponent()}
		</div>
	);
};

export default TagSearch;
