//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import searchIcon from './../../assets/ic_search.svg';

class SearchbarRetracted extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.handleClick = this.handleClick.bind(this);
	}
	//functions

	handleClick(e) {
		this.props.expandSearchbar();
	}

	render() {
		return (
			<div
				className='retractedSearchbarContainer'
				onClick={this.handleClick}
			>
				<ReactSVG
					src={searchIcon}
					className='retractedSearchBarSearchIcon'
				/>
			</div>
		);
	}
}

export default SearchbarRetracted;
