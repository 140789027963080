//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';
import _ from 'lodash';

//Components
import CreatePatientV2BodySection from './CreatePatientV2BodySection.js';

// Utils
import {
	createStateEnumsOptions,
	createEnumsOptions,
} from '../Encounters/Common/utilsfunctions';
import { SCHEDULING_PLATFORM } from './../../../../Utils/appointmentUtils.js';

class CreatePatientV2Body extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.constructEligibilityFieldsJSON =
			this.constructEligibilityFieldsJSON.bind(this);
		this.getShippingAddressFields =
			this.getShippingAddressFields.bind(this);
		this.secondaryRaceFields = this.secondaryRaceFields.bind(this);
	}
	//functions

	componentDidMount() {
		if (get(this, 'bodyElement', null))
			this.props.setBodyElement(get(this, 'bodyElement', null));
	}

	getConsentToTreatEnums = () => {
		const enums = createEnumsOptions(
			this,
			['props', 'consentToTreatEnum'],
			'internal',
			'external',
			'internal'
		);
		if (
			get(this, 'props.editMode', false) &&
			get(this, 'props.consentToTreatPatient', null) != 'not_consented'
		) {
			return _.filter(enums, (e) => e && e.value != 'not_consented');
		} else {
			return enums;
		}
	};

	constructEligibilityFieldsJSON() {
		const errorFields = get(this, 'props.errorFields', {});
		const fields = get(this, 'props.eligibilityFields', []);
		if (Array.isArray(fields)) {
			return fields.map((el, i) => {
				if (get(el, 'type', null) === 'text') {
					return {
						required: get(el, 'required', true),
						fieldHeader:
							get(el, 'label', 'Eligibility Field') ||
							'Eligibility Field',
						type: 'text',
						placeholder:
							get(el, 'label', 'Eligibility Field') ||
							'Eligibility Field',
						value: get(
							this,
							`props.fullState.${get(el, 'name', '')}`,
							null
						),
						header: get(el, 'name', ''),
						immutableCondition:
							get(el, 'name', null) === 'employeeId' &&
							get(this, 'props.editMode', false),
						immutableValue: get(
							this,
							`props.fullState.${get(el, 'name', '')}`,
							null
						),
						className:
							i === 1
								? 'patientCreateV2SectionInputFieldEdge'
								: '',
						error: get(errorFields, get(el, 'name', ''), null),
					};
				} else if (get(el, 'type', null) === 'select') {
					return {
						required:
							get(el, 'name', '') === 'relationship'
								? true
								: get(el, 'required', true),
						fieldHeader:
							get(el, 'label', 'Eligibility Field') ||
							'Eligibility Field',
						type: 'dropdown',
						placeholder: 'Select',
						value: get(
							this,
							`props.fullState.${get(el, 'name', '')}`,
							null
						),
						options: this.props.getEligibilityFieldOptions(el),
						header: get(el, 'name', ''),
						className:
							i === 1
								? 'patientCreateV2SectionInputFieldEdge'
								: '',
						error: get(errorFields, get(el, 'name', ''), null),
						typeahead: false,
					};
				} else return [];
			});
		} else return [];
	}

	phoneConsentTooltipText() {
		return (
			<div>
				Consent to Text indicates whether the patient has <br />
				agreed to receive automated text notifications <br />
				from Genome Medical on their mobile phone.
				<br />
				The notifications may be about appointments, <br />
				test results, and more.
			</div>
		);
	}

	patientPhoneFields = () => {
		const errorFields = get(this, 'props.errorFields', {}) || {};
		const primaryPhoneFields = [
			{
				required: true,
				fieldHeader: 'Patient’s Primary Phone',
				type: 'textInputMask',
				inputMaskType: 'phone',
				placeholder: 'Primary Phone Number',
				value: get(this, 'props.primaryPhone', null),
				header: 'primaryPhone',
				error: get(errorFields, 'primaryPhone', null),
			},
			{
				required: false,
				fieldHeader: 'Primary Phone Type',
				type: 'dropdown',
				placeholder: 'Select',
				options: createEnumsOptions(
					this,
					['props', 'phoneTypes'],
					'internal',
					'external',
					'internal'
				),
				value: get(this, 'props.primaryPhoneType', null),
				header: 'primaryPhoneType',
				className: 'patientCreateV2SectionInputFieldEdge',
				error: get(errorFields, 'primaryPhoneType', null),
				typeahead: true,
			},
		];

		const primaryPhoneConsentToTextField = [
			{
				required: false,
				fieldHeader: '',
				type: 'simpleCheckboxRow',
				placeholder: null,
				disabled: get(this, 'props.editMode', false),
				label: 'Send patient text messages',
				value: get(this, 'props.primaryPhoneConsentToText', null),
				header: 'primaryPhoneConsentToText',
				className: 'consent-to-text',
				description:
					'By providing the patient’s phone number, you confirm that the patient agrees to receive texts from Genome Medical about its genetic services.',
			},
		];

		const secondaryPhoneFields = [
			{
				required: false,
				fieldHeader: 'Patient’s Secondary Phone',
				type: 'textInputMask',
				inputMaskType: 'phone',
				placeholder: 'Secondary Phone Number',
				value: get(this, 'props.secondaryPhone', null),
				header: 'secondaryPhone',
				error: get(errorFields, 'secondaryPhone', null),
			},
			{
				required: false,
				fieldHeader: 'Secondary Phone Type',
				type: 'dropdown',
				placeholder: 'Select',
				options: createEnumsOptions(
					this,
					['props', 'phoneTypes'],
					'internal',
					'external',
					'internal'
				),
				value: get(this, 'props.secondaryPhoneType', null),
				header: 'secondaryPhoneType',
				className: 'patientCreateV2SectionInputFieldEdge',
				error: get(errorFields, 'secondaryPhoneType', null),
				typeahead: true,
			},
		];

		return primaryPhoneFields
			.concat(primaryPhoneConsentToTextField)
			.concat(secondaryPhoneFields);
	};

	guardianPhoneNamePronounFields = () => {
		const errorFields = get(this, 'props.errorFields', {}) || {};

		const primaryPhoneFields = [
			{
				required: true,
				fieldHeader: 'Guardian’s Primary Phone',
				type: 'textInputMask',
				inputMaskType: 'phone',
				placeholder: 'Primary Phone Number',
				value: get(this, 'props.guardianPrimaryPhone', null),
				header: 'guardianPrimaryPhone',
				error: get(errorFields, 'guardianPrimaryPhone', null),
			},
			{
				required: false,
				fieldHeader: 'Primary Phone Type',
				type: 'dropdown',
				placeholder: 'Select',
				options: createEnumsOptions(
					this,
					['props', 'phoneTypes'],
					'internal',
					'external',
					'internal'
				),
				value: get(this, 'props.guardianPrimaryPhoneType', null),
				header: 'guardianPrimaryPhoneType',
				className: 'patientCreateV2SectionInputFieldEdge',
				error: get(errorFields, 'guardianPrimaryPhoneType', null),
				typeahead: true,
			},
		];

		const primaryPhoneConsentToTextField = [
			{
				required: false,
				fieldHeader: '',
				type: 'simpleCheckboxRow',
				placeholder: null,
				disabled: get(this, 'props.editMode', false),
				label: 'Send patient text messages',
				value: get(
					this,
					'props.guardianPrimaryPhoneConsentToText',
					null
				),
				header: 'guardianPrimaryPhoneConsentToText',
				className: 'consent-to-text',
				description:
					'By providing the patient’s phone number, you confirm that the patient agrees to receive texts from Genome Medical about its genetic services.',
			},
		];

		const secondaryPhoneFields = [
			{
				required: false,
				fieldHeader: 'Guardian’s Secondary Phone',
				type: 'textInputMask',
				inputMaskType: 'phone',
				placeholder: 'Secondary Phone Number',
				value: get(this, 'props.guardianSecondaryPhone', null),
				header: 'guardianSecondaryPhone',
				error: get(errorFields, 'guardianSecondaryPhone', null),
			},
			{
				required: false,
				fieldHeader: 'Secondary Phone Type',
				type: 'dropdown',
				placeholder: 'Select',
				options: createEnumsOptions(
					this,
					['props', 'phoneTypes'],
					'internal',
					'external',
					'internal'
				),
				value: get(this, 'props.guardianSecondaryPhoneType', null),
				header: 'guardianSecondaryPhoneType',
				className: 'patientCreateV2SectionInputFieldEdge',
				error: get(errorFields, 'guardianSecondaryPhoneType', null),
				typeahead: true,
			},
		];

		const otherFields = [
			{
				required: false,
				fieldHeader: 'Guardian’s Preferred Name',
				type: 'text',
				placeholder: 'Guardian’s Preferred Name',
				value: get(this, 'props.guardianPreferredName', null),
				header: 'guardianPreferredName',
			},
			{
				required: false,
				fieldHeader: 'Guardian’s Pronouns',
				fieldSubHeader: '(select all that apply)',
				type: 'simpleCheckboxes',
				placeholder: 'Select',
				options: get(this, 'props.pronounsEnum', []) || [],
				value: get(this, 'props.guardianPronouns', null) || [],
				header: 'guardianPronouns',
				className: '',
			},
		];

		return primaryPhoneFields
			.concat(primaryPhoneConsentToTextField)
			.concat(secondaryPhoneFields)
			.concat(otherFields);
	};

	getShippingAddressFields() {
		const nonEmptyShippingAddress = get(
			this,
			'props.nonEmptyShippingAddress',
			false
		);
		const sameShippingAddress = get(
			this,
			'props.sameShippingAddress',
			false
		);
		const errorFields = get(this, 'props.errorFields', {}) || {};
		if (sameShippingAddress) return [];
		else
			return [
				{
					required: nonEmptyShippingAddress,
					fieldHeader: 'Address Line 1',
					type: 'text',
					placeholder: 'Address 1',
					value: get(this, 'props.createAddress1Shipping', null),
					header: 'createAddress1Shipping',
					className:
						'patientCreateV2SectionInputFieldEdge patientCreateV2SectionInputFieldEdgeFullWidthText',
					error: get(errorFields, 'createAddress1Shipping', null),
				},
				{
					required: false,
					fieldHeader: 'Address Line 2',
					type: 'text',
					placeholder: 'Address 2',
					value: get(this, 'props.createAddress2Shipping', null),
					header: 'createAddress2Shipping',
					className:
						'patientCreateV2SectionInputFieldEdge patientCreateV2SectionInputFieldEdgeFullWidthText',
					error: get(errorFields, 'createAddress2Shipping', null),
				},
				{
					required: nonEmptyShippingAddress,
					fieldHeader: 'City',
					type: 'text',
					placeholder: 'City',
					value: get(this, 'props.createCityShipping', null),
					header: 'createCityShipping',
					className: '',
					error: get(errorFields, 'createCityShipping', null),
				},
				{
					required: nonEmptyShippingAddress,
					fieldHeader: 'State',
					type: 'dropdown',
					placeholder: 'Select',
					value: get(this, 'props.createStateShipping', null),
					options: createStateEnumsOptions(
						get(this, 'props.statesEnum', []) || [],
						'key',
						'value',
						'key'
					),
					header: 'createStateShipping',
					className: 'oneQuarterAbsoluteWidth',
					error: get(errorFields, 'createStateShipping', null),
					typeahead: true,
				},
				{
					required: nonEmptyShippingAddress,
					fieldHeader: 'Zipcode',
					type: 'text',
					placeholder: '12345',
					value: get(this, 'props.createZipShipping', null),
					header: 'createZipShipping',
					className:
						'patientCreateV2SectionInputFieldEdge oneQuarterAbsoluteWidth',
					error: get(errorFields, 'createZipShipping', null),
				},
			];
	}

	secondaryRaceFields() {
		const raceEnum = get(this, 'props.raceEnum', null) || [];
		const currentRaceValue = get(this, 'props.primaryRace', null);
		const currentSecondaryRacesValue =
			get(this, 'props.secondaryRaces', null) || [];
		const required = false;
		const header = 'secondaryRaces';
		const type = 'simpleCheckboxes';
		const fieldHeader = 'Please Specify Your Race';
		const fieldSubHeader = '(select all that apply)';

		if (currentRaceValue && Array.isArray(raceEnum)) {
			const raceEnumObj = _.find(
				raceEnum,
				(race) => get(race, 'internal', '') === currentRaceValue
			);
			const options = get(raceEnumObj, 'subOptions', null);
			if (
				raceEnumObj &&
				Array.isArray(options) &&
				get(options, 'length', 0) > 0
			) {
				return [
					{
						required,
						fieldHeader,
						fieldSubHeader,
						type,
						options,
						value: currentSecondaryRacesValue,
						header,
						className: '',
						containerStylingOverride: { marginBottom: '12px' },
					},
				];
			} else return [];
		} else return [];
	}

	isPrimaryInsuranceFieldsRequired() {
		return _.every(this.props.primaryinsurance, (value, key) => {
			let result = false;
			if (key == 'is_policyholder' && value == true) result = true;
			else if (typeof value == 'string') {
				result = _.isEmpty(value);
			} else {
				result = _.isNil(value);
			}
			return result;
		});
	}

	isSecondaryInsuranceFieldsRequired() {
		return _.every(this.props.secondaryinsurance, (value, key) => {
			let result = false;
			if (key == 'is_policyholder' && value == true) result = true;
			else if (typeof value == 'string') {
				result = _.isEmpty(value);
			} else {
				result = _.isNil(value);
			}
			return result;
		});
	}

	disabledSpecialtyAndConsultationType(
		encounterList,
		disableDefaultSchedulingOptionsFields
	) {
		const { schedulingPlatform } = this.props;
		let disabledSpecialtyAndConsultationType =
			disableDefaultSchedulingOptionsFields;
		if (
			encounterList.length > 0 &&
			schedulingPlatform === SCHEDULING_PLATFORM.PIONEER
		) {
			disabledSpecialtyAndConsultationType = encounterList.some(
				(encounter) => encounter.visit_status === 'booked'
			);
		}

		return disabledSpecialtyAndConsultationType;
	}

	render() {
		const { defaultSchedulingOptions, showMedicareQuestion } = this.props;
		const nonEmptyAddress = get(this, 'props.nonEmptyAddress', false);
		const nonEmptyShippingAddress = get(
			this,
			'props.nonEmptyShippingAddress',
			false
		);
		const errorFields = get(this, 'props.errorFields', {}) || {};
		const editMode = get(this, 'props.editMode', false);
		const userRoleIsCCGCOrProdeng = get(this, 'props.userRoleIsCCGCOrProdeng', false);
		const autorizationToRecontactIsConsented = get(this, ['props','autorizationToRecontactIsConsented'], false);
		const authorizationToRecontactConsentVersion=get(this, ['props','authorizationToRecontactConsentVersion']);
		const isSCPMode = get(this, 'props.isSCPMode', false);
		const hasDefaultSchedulingOptions =
			!_.isEmpty(defaultSchedulingOptions) &&
			!_.isNil(defaultSchedulingOptions);
		const encounterList = this.props.encounters;
		const disableDefaultSchedulingOptionsFields =
			!this.props.selectedReferralProgram || !hasDefaultSchedulingOptions;
		const disabledSpecialtyAndConsult =
			this.disabledSpecialtyAndConsultationType(
				encounterList,
				disableDefaultSchedulingOptionsFields
			);
		const disabledReferralProgram =
			disabledSpecialtyAndConsult &&
			encounterList.length > 0 &&
			this.props.schedulingPlatform === SCHEDULING_PLATFORM.PIONEER;
		const defaultSchedulingOptionsFields = [
			{
				required: !disabledSpecialtyAndConsult,
				fieldHeader: 'Specialty',
				type: 'dropdown',
				placeholder: 'Select',
				options: (
					this.props.defaultSchedulingOptions?.specialties || []
				).map((el) => {
					return {
						key: el.id,
						label: el.display_name,
						value: el.id,
					};
				}),
				value: this.props.defaultSpecialty || null,
				header: 'defaultSpecialty',
				className: '',
				typeahead: !disabledSpecialtyAndConsult,
				disabled: disabledSpecialtyAndConsult,
			},
			{
				required: !disabledSpecialtyAndConsult,
				fieldHeader: 'Consultation Type',
				type: 'dropdown',
				placeholder: 'Select',
				options: (
					this.props.defaultSchedulingOptions?.consultations || []
				).map((el) => {
					return {
						key: el.id,
						label: el.name,
						value: el.id,
					};
				}),
				value: this.props.defaultConsultationType || null,
				header: 'defaultConsultationType',
				className: 'patientCreateV2SectionInputFieldEdge',
				typeahead: !disabledSpecialtyAndConsult,
				disabled: disabledSpecialtyAndConsult,
			},
		];
		const showSecondaryInsurance = false;

		return (
			<div
				className='patientCreateV2BodyContainer'
				onScroll={() => this.props.scrollHandler(['top', 'bottom'])}
				ref={(el) => (this.bodyElement = el)}
			>
				<CreatePatientV2BodySection
					changeValue={this.props.changeValue}
					title={'Referral Program'}
					description={
						'Please select the appropriate affiliation or referral source for this patient.'
					}
					sectionRequired={true}
					hasCheckbox={false}
					yesNoChoice={false}
					inputFields={[
						{
							required: true,
							fieldHeader: 'Referral Program',
							type: 'dropdown',
							placeholder: 'Select',
							options: createEnumsOptions(
								this,
								['props', 'referralProgramsEnum'],
								'internal',
								'external',
								'internal'
							),
							value: get(
								this,
								'props.selectedReferralProgram',
								null
							),
							header: 'selectedReferralProgram',
							className: '',
							typeahead: !disabledReferralProgram,
							loading: get(
								this,
								'props.eligibilityFieldsLoading',
								false
							),
							disabled: disabledReferralProgram,
						},
						{
							required: true,
							fieldHeader: 'Original Referral Date',
							type: 'textInputMask',
							inputMaskType: 'date',
							placeholder: 'MM/DD/YYYY',
							value: get(
								this,
								'props.selectedReferralDate',
								null
							),
							header: 'selectedReferralDate',
							className: 'patientCreateV2SectionInputFieldEdge',
							error: get(
								errorFields,
								'selectedReferralDate',
								null
							),
						},
					]
						.concat(defaultSchedulingOptionsFields) // this is replaced with actual array (above) with CS-505
						.concat(this.constructEligibilityFieldsJSON())
						.concat([
							{
								required: false,
								fieldHeader: 'External Patient ID',
								type: 'text',
								placeholder: '1234567890',
								value: get(
									this,
									'props.externalProviderID',
									null
								),
								header: 'externalProviderID',
								className: '',
							},
						])}
				/>
				<CreatePatientV2BodySection
					changeValue={this.props.changeValue}
					title={'Patient Information'}
					description={
						'Please fill in the following demographic information.'
					}
					sectionRequired={true}
					hasCheckbox={false}
					yesNoChoice={{
						text: 'Is the patient unborn?',
						selection: get(this, 'props.unbornSelection', false),
						header: 'unbornSelection',
					}}
					yesNoChoice2={{
						text: 'Is the patient covered by Medicare?',
						selection:
							showMedicareQuestion &&
							get(this, 'props.medicareSelection', null),
						header: 'medicareSelection',
					}}
					showMedicareQuestion={showMedicareQuestion}
					isSCPMode={isSCPMode}
					inputFields={
						get(this, 'props.unbornSelection', false) && !editMode
							? []
							: [
									{
										required: true,
										fieldHeader: 'First Name',
										type: 'text',
										placeholder: "Patient's First Name",
										value: get(
											this,
											'props.firstName',
											null
										),
										header: 'firstName',
										className: '',
										error: get(
											errorFields,
											'firstName',
											null
										),
									},
									{
										required: true,
										fieldHeader: 'Last Name',
										type: 'text',
										placeholder: "Patient's Last Name",
										value: get(
											this,
											'props.lastName',
											null
										),
										header: 'lastName',
										className:
											'patientCreateV2SectionInputFieldEdge',
										error: get(
											errorFields,
											'lastName',
											null
										),
									},
									{
										required: !(
											get(
												this,
												'props.unbornSelection',
												false
											) && editMode
										),
										fieldHeader: 'Date of Birth',
										type: 'textInputMask',
										inputMaskType: 'date',
										placeholder: 'MM/DD/YYYY',
										value: get(this, 'props.dob', null),
										header: 'dob',
										className: '',
										error: get(errorFields, 'dob', null),
									},
									{
										required: true,
										fieldHeader: 'Sex Assigned at Birth',
										type: 'dropdown',
										placeholder: 'Select',
										options: createEnumsOptions(
											this,
											['props', 'sexAssignedAtBirthEnum'],
											'internal',
											'external',
											'internal'
										),
										value: get(
											this,
											'props.sexAssignedAtBirth',
											null
										),
										header: 'sexAssignedAtBirth',
										className:
											'patientCreateV2SectionInputFieldEdge',
										typeahead: true,
									},
									{
										required: false,
										fieldHeader: 'Preferred Name',
										type: 'text',
										placeholder: 'Preferred Name',
										value: get(
											this,
											'props.preferredName',
											null
										),
										header: 'preferredName',
										className: '',
									},
							  ].concat(
									[
										{
											required: false,
											fieldHeader: 'Gender',
											type: 'dropdown',
											placeholder: 'Select',
											options: createEnumsOptions(
												this,
												['props', 'genderEnum'],
												'internal',
												'external',
												'internal'
											),
											value: get(
												this,
												'props.biologicalSex',
												null
											),
											header: 'biologicalSex',
											className:
												'patientCreateV2SectionInputFieldEdge',
											typeahead: true,
										},
										{
											required: false,
											fieldHeader: 'Pronouns',
											fieldSubHeader:
												'(select all that apply)',
											type: 'simpleCheckboxes',
											placeholder: 'Select',
											options:
												get(
													this,
													'props.pronounsEnum',
													[]
												) || [],
											value:
												get(
													this,
													'props.pronouns',
													null
												) || [],
											header: 'pronouns',
											className: '',
											containerStylingOverride: {
												marginBottom: '12px',
											},
										},
										{
											required: !editMode,
											fieldHeader: 'Race',
											type: 'dropdown',
											placeholder: 'Select',
											options: createEnumsOptions(
												this,
												['props', 'raceEnum'],
												'internal',
												'external',
												'internal'
											),
											value: get(
												this,
												'props.primaryRace',
												null
											),
											header: 'primaryRace',
											className: '',
											error: get(
												errorFields,
												'primaryRace',
												null
											),
											typeahead: true,
										},
										{
											required: !editMode,
											fieldHeader: 'Ethnicity',
											type: 'dropdown',
											placeholder: 'Select',
											options: createEnumsOptions(
												this,
												['props', 'ethnicityEnum'],
												'internal',
												'external',
												'internal'
											),
											value: get(
												this,
												'props.ethnicity',
												null
											),
											header: 'ethnicity',
											className:
												'patientCreateV2SectionInputFieldEdge',
											error: get(
												errorFields,
												'ethnicity',
												null
											),
											typeahead: true,
										},
									].concat(this.secondaryRaceFields())
							  )
					}
				/>
				<CreatePatientV2BodySection
					changeValue={this.props.changeValue}
					title={'Contact Information'}
					description={
						'Please provide the contact information for this patient or guardian/POA.'
					}
					sectionRequired={true}
					hasCheckbox={!editMode}
					checkboxText={'No Email'}
					checkboxSelection={get(this, 'props.noEmail', false)}
					checkboxHeader={'noEmail'}
					yesNoChoice={
						get(this, 'props.unbornSelection', false) && !editMode
							? null
							: {
									text: 'Does the patient have a Guardian or Power of Attorney?',
									selection: get(
										this,
										'props.guardianSelection',
										false
									),
									header: 'guardianSelection',
							  }
					}
					isSCPMode={isSCPMode}
					canNotRemoveGuardian={get(
						this,
						'props.canNotRemoveGuardian',
						false
					)}
					inputFields={
						get(this, 'props.guardianSelection', false) ||
						get(this, 'props.unbornSelection', false)
							? [
									{
										required: true,
										fieldHeader: "Guardian's Email",
										type: 'text',
										placeholder: 'email@email.com',
										value: get(this, 'props.email', null),
										header: 'email',
										className:
											'patientCreateV2SectionInputFieldEdgeFullWidthText',
										immutableCondition: get(
											this,
											'props.noEmail',
											false
										),
										immutableValue: ``,
										immutableClass:
											'patientCreateV2SectionTextInputFieldDisabledFullWidth',
										hasFutureBookedAppointments: get(
											this,
											'props.hasFutureBookedAppointments',
											false
										),
										tooltipClass:
											'tooltipPositionGuardianEmail',
										error: get(errorFields, 'email', null),
									},
							  ]
									.concat([
										{
											required: true,
											fieldHeader:
												"Guardian's First Name",
											type: 'text',
											placeholder: 'First Name',
											value: get(
												this,
												'props.guardianFirstName',
												null
											),
											header: 'guardianFirstName',
											className: '',
											error: get(
												errorFields,
												'guardianFirstName',
												null
											),
										},
										{
											required: true,
											fieldHeader: "Guardian's Last Name",
											type: 'text',
											placeholder: 'Last Name',
											value: get(
												this,
												'props.guardianLastName',
												null
											),
											header: 'guardianLastName',
											className:
												'patientCreateV2SectionInputFieldEdge',
											error: get(
												errorFields,
												'guardianLastName',
												null
											),
										},
										{
											required: true,
											fieldHeader:
												'Relationship to Patient',
											type: 'dropdown',
											placeholder: 'Select',
											options: createEnumsOptions(
												this,
												[
													'props',
													'guardianRelationships',
												],
												'internal',
												'external',
												'internal'
											),
											value: get(
												this,
												'props.guardianRelationship',
												null
											),
											header: 'guardianRelationship',
											className: '',
											error: get(
												errorFields,
												'guardianRelationship',
												null
											),
											typeahead: true,
										},
									])
									.concat(
										get(
											this,
											'props.unbornSelection',
											false
										)
											? [
													{
														required: get(
															this,
															'props.unbornSelection',
															false
														)
															? true
															: false,
														fieldHeader:
															'Guardian’s Date of Birth',
														type: 'textInputMask',
														inputMaskType: 'date',
														placeholder:
															'MM/DD/YYYY',
														value: get(
															this,
															'props.guardianDob',
															null
														),
														header: 'guardianDob',
														className:
															'patientCreateV2SectionInputFieldEdge',
														error: get(
															errorFields,
															'guardianDob',
															null
														),
													},
											  ]
											: []
									)
									.concat(
										this.guardianPhoneNamePronounFields()
									)
							: [
									{
										required: true,
										fieldHeader: "Patient's Email",
										type: 'text',
										placeholder: 'email@email.com',
										value: get(this, 'props.email', null),
										header: 'email',
										className:
											'patientCreateV2SectionInputFieldEdgeFullWidthText',
										immutableCondition: get(
											this,
											'props.noEmail',
											false
										),
										immutableValue: ``,
										immutableClass:
											'patientCreateV2SectionTextInputFieldDisabledFullWidth',
										hasFutureBookedAppointments: get(
											this,
											'props.hasFutureBookedAppointments',
											false
										),
										tooltipClass:
											'tooltipPositionPatientEmail',
										error: get(errorFields, 'email', null),
									},
							  ].concat(this.patientPhoneFields())
					}
				/>
				{editMode ? (
					<CreatePatientV2BodySection
						changeValue={this.props.changeValue}
						title={'Patient Addresses'}
						description={''}
						sectionRequired={false}
						hasCheckbox={false}
						yesNoChoice={null}
						inputFields={[]}
						addressSection={true}
						openAddressModal={this.props.openAddressModal}
						hasAllAddressTypes={get(
							this,
							'props.hasAllAddressTypes',
							false
						)}
						addressData={get(this, 'props.addressData', null) || []}
						addressErrors={
							get(this, 'props.addressErrors', null) || []
						}
					/>
				) : (
					<CreatePatientV2BodySection
						changeValue={this.props.changeValue}
						title={'Home Address'}
						description={''}
						sectionRequired={false}
						sectionRecommended={true}
						hasCheckbox={false}
						yesNoChoice={null}
						inputFields={[
							{
								required: nonEmptyAddress,
								fieldHeader: 'Address Line 1',
								type: 'text',
								placeholder: 'Address 1',
								value: get(this, 'props.createAddress1', null),
								header: 'createAddress1',
								className:
									'patientCreateV2SectionInputFieldEdge patientCreateV2SectionInputFieldEdgeFullWidthText',
								error: get(errorFields, 'createAddress1', null),
							},
							{
								required: false,
								fieldHeader: 'Address Line 2',
								type: 'text',
								placeholder: 'Address 2',
								value: get(this, 'props.createAddress2', null),
								header: 'createAddress2',
								className:
									'patientCreateV2SectionInputFieldEdge patientCreateV2SectionInputFieldEdgeFullWidthText',
								error: get(errorFields, 'createAddress2', null),
							},
							{
								required: nonEmptyAddress,
								fieldHeader: 'City',
								type: 'text',
								placeholder: 'City',
								value: get(this, 'props.createCity', null),
								header: 'createCity',
								className: '',
								error: get(errorFields, 'createCity', null),
							},
							{
								required: nonEmptyAddress,
								fieldHeader: 'State',
								type: 'dropdown',
								placeholder: 'Select',
								value: get(this, 'props.createState', null),
								options: createStateEnumsOptions(
									get(this, 'props.statesEnum', []) || [],
									'key',
									'value',
									'key'
								),
								header: 'createState',
								className: 'oneQuarterAbsoluteWidth',
								error: get(errorFields, 'createState', null),
								typeahead: true,
							},
							{
								required: nonEmptyAddress,
								fieldHeader: 'Zipcode',
								type: 'text',
								placeholder: '12345',
								value: get(this, 'props.createZip', null),
								header: 'createZip',
								className:
									'patientCreateV2SectionInputFieldEdge oneQuarterAbsoluteWidth',
								error: get(errorFields, 'createZip', null),
							},
						]}
					/>
				)}
				{!editMode && (
					<CreatePatientV2BodySection
						changeValue={this.props.changeValue}
						title={'Shipping Address'}
						description={
							'Please provide an address where the patient would like to receive mail, lab kits, etc.'
						}
						sectionRequired={false}
						sectionRecommended={true}
						hasCheckbox={false}
						yesNoChoice={null}
						inputFields={[
							{
								required: false,
								fieldHeader: '',
								type: 'simpleCheckboxRow',
								placeholder: null,
								label: 'Same As Home Address',
								value: get(
									this,
									'props.sameShippingAddress',
									false
								),
								header: 'sameShippingAddress',
								className: null,
								disabled:
									!nonEmptyAddress &&
									!nonEmptyShippingAddress,
							},
						].concat(this.getShippingAddressFields())}
						addressErrors={
							get(this, 'props.addressErrors', null) || []
						}
					/>
				)}
				{!editMode && (
					<CreatePatientV2BodySection
						changeValue={this.props.changeValue}
						title={"Patient's Primary Insurance Card"}
						description={''}
						sectionRequired={false}
						sectionRecommended={true}
						hasCheckbox={false}
						inputFields={[
							{
								required:
									!this.isPrimaryInsuranceFieldsRequired(),
								fieldHeader: 'Health Insurance',
								type: 'dropdown',
								placeholder: 'Select',
								value: get(
									this,
									'props.primaryinsurance.insurance_company_id',
									null
								),
								options:
									get(this, 'props.insuranceCompanies', []) ||
									[],
								header: 'primaryinsurance.insurance_company_id',
								className:
									this.props.OTHER_COMPANY !==
									this.props.primaryinsurance
										.insurance_company_id
										? 'patientCreateV2SectionInputFieldEdge patientCreateV2SectionInputFieldEdgeFullWidthText'
										: '',
								error: get(
									errorFields,
									'primaryinsurance.insurance_company_id',
									null
								),
								typeahead: true,
							},
						]
							.concat(
								this.props.OTHER_COMPANY ===
									this.props.primaryinsurance
										.insurance_company_id
									? [
											{
												required:
													!this.isPrimaryInsuranceFieldsRequired(),
												fieldHeader:
													'Health Insurance Company',
												type: 'text',
												placeholder:
													'Health insurance company',
												value: get(
													this,
													'props.primaryinsurance.company_name',
													null
												),
												header: 'primaryinsurance.company_name',
												className:
													'patientCreateV2SectionInputFieldEdge',
												error: get(
													errorFields,
													'primaryinsurance.company_name',
													null
												),
											},
									  ]
									: []
							)
							.concat([
								{
									required:
										!this.isPrimaryInsuranceFieldsRequired(),
									fieldHeader: 'Insurance ID #',
									type: 'text',
									placeholder: '1234567890',
									value: get(
										this,
										'props.primaryinsurance.insurance_no',
										null
									),
									header: 'primaryinsurance.insurance_no',
									className: '',
									error: get(
										errorFields,
										'primaryinsurance.insurance_no',
										null
									),
								},
								{
									required:
										!this.isPrimaryInsuranceFieldsRequired(),
									fieldHeader: 'Insurance phone number',
									fieldHeaderTooltip:
										'Please Enter the Insurance \nPhone Number Indicated on the \nBack of Your Insurance Card',
									type: 'textInputMask',
									inputMaskType: 'phone',
									placeholder: '(123) 456 - 789',
									value: get(
										this,
										'props.primaryinsurance.phone',
										null
									),
									header: 'primaryinsurance.phone',
									className:
										'patientCreateV2SectionInputFieldEdge',
									error: get(
										errorFields,
										'primaryinsurance.phone',
										null
									),
								},
								{
									required: false,
									fieldHeader: 'Plan type (optional)',
									type: 'dropdown',
									placeholder: 'Select',
									value: get(
										this,
										'props.primaryinsurance.plan_name',
										null
									),
									options: createEnumsOptions(
										this,
										['props', 'planTypesEnums'],
										'internal',
										'external',
										'internal'
									),
									header: 'primaryinsurance.plan_name',
									className: '',
									error: null,
									typeahead: true,
								},
								{
									required:
										!this.isPrimaryInsuranceFieldsRequired(),
									fieldHeader: 'Group #',
									type: 'text',
									placeholder: '1234567890',
									value: get(
										this,
										'props.primaryinsurance.group_no',
										null
									),
									header: 'primaryinsurance.group_no',
									className:
										'patientCreateV2SectionInputFieldEdge',
									error: get(
										errorFields,
										'primaryinsurance.group_no',
										null
									),
								},
								{
									required: false,
									fieldHeader: 'Employer',
									type: 'text',
									placeholder: 'Company ABC',
									value: get(
										this,
										'props.primaryinsurance.employer',
										null
									),
									header: 'primaryinsurance.employer',
									className: '',
									error: null,
								},
								{
									required:
										!this.isPrimaryInsuranceFieldsRequired(),
									fieldHeader: 'Insurance address',
									type: 'text',
									placeholder: '123 Main  street',
									value: get(
										this,
										'props.primaryinsurance.insurance_address',
										null
									),
									header: 'primaryinsurance.insurance_address',
									className:
										'patientCreateV2SectionInputFieldEdge patientCreateV2SectionInputFieldEdgeFullWidthText',
									error: get(
										errorFields,
										'primaryinsurance.insurance_address',
										null
									),
								},
								{
									required:
										!this.isPrimaryInsuranceFieldsRequired(),
									fieldHeader: 'Insurance city',
									type: 'text',
									placeholder: 'San Francisco',
									value: get(
										this,
										'props.primaryinsurance.insurance_city',
										null
									),
									header: 'primaryinsurance.insurance_city',
									className: '',
									error: get(
										errorFields,
										'primaryinsurance.insurance_city',
										null
									),
								},
								{
									required:
										!this.isPrimaryInsuranceFieldsRequired(),
									fieldHeader: 'Insurance state',
									type: 'dropdown',
									placeholder: 'Select',
									value: get(
										this,
										'props.primaryinsurance.insurance_state',
										null
									),
									options: (
										get(this, 'props.statesEnum', []) || []
									).map((el) => {
										return {
											key: el.key,
											value: el.key,
											label: el.value,
										};
									}),
									header: 'primaryinsurance.insurance_state',
									className:
										'patientCreateV2SectionInputFieldEdge',
									error: get(
										errorFields,
										'primaryinsurance.insurance_state',
										null
									),
									typeahead: true,
								},
								{
									required:
										!this.isPrimaryInsuranceFieldsRequired(),
									fieldHeader: 'Insurance zipcode',
									type: 'text',
									placeholder: '12345',
									value: get(
										this,
										'props.primaryinsurance.insurance_zipcode',
										null
									),
									header: 'primaryinsurance.insurance_zipcode',
									className: '',
									error: get(
										errorFields,
										'primaryinsurance.insurance_zipcode',
										null
									),
								},
								{
									required:
										!this.isPrimaryInsuranceFieldsRequired(),
									fieldHeader:
										'Is patient the primary policyholder?',
									type: 'radioButtonPill',
									placeholder: '',
									options: [
										{ label: 'Yes', value: true },
										{ label: 'No', value: false },
									],
									value: get(
										this,
										'props.primaryinsurance.is_policyholder',
										true
									),
									header: 'primaryinsurance.is_policyholder',
									className: 'is_policyholder',
									error: get(
										errorFields,
										'primaryinsurance.is_policyholder',
										null
									),
								},
								{
									required:
										!this.isPrimaryInsuranceFieldsRequired(),
									fieldHeader:
										"Policyholder's biological sex",
									type: 'dropdown',
									placeholder: 'Select',
									value: get(
										this,
										'props.primaryinsurance.policyholder_biological_sex',
										null
									),
									options: createEnumsOptions(
										this,
										['props', 'biologicalSexVariants'],
										'internal',
										'external',
										'internal'
									),
									header: 'primaryinsurance.policyholder_biological_sex',
									className: '',
									error: get(
										errorFields,
										'primaryinsurance.policyholder_biological_sex',
										null
									),
									typeahead: true,
								},
							])
							.concat(
								!get(
									this,
									'props.primaryinsurance.is_policyholder',
									true
								)
									? [
											{
												required:
													!this.isPrimaryInsuranceFieldsRequired() &&
													!get(
														this,
														'props.primaryinsurance.is_policyholder',
														true
													),
												fieldHeader:
													"Policyholder's first name",
												type: 'text',
												placeholder: 'John',
												value: get(
													this,
													'props.primaryinsurance.policyholder_first_name',
													null
												),
												header: 'primaryinsurance.policyholder_first_name',
												className:
													'patientCreateV2SectionInputFieldEdge',
												error: get(
													errorFields,
													'primaryinsurance.policyholder_first_name',
													null
												),
											},
											{
												required:
													!this.isPrimaryInsuranceFieldsRequired() &&
													!get(
														this,
														'props.primaryinsurance.is_policyholder',
														true
													),
												fieldHeader:
													"Policyholder's last name",
												type: 'text',
												placeholder: 'Smith',
												value: get(
													this,
													'props.primaryinsurance.policyholder_last_name',
													null
												),
												header: 'primaryinsurance.policyholder_last_name',
												className: '',
												error: get(
													errorFields,
													'primaryinsurance.policyholder_last_name',
													null
												),
											},
											{
												required:
													!this.isPrimaryInsuranceFieldsRequired() &&
													!get(
														this,
														'props.primaryinsurance.is_policyholder',
														true
													),
												fieldHeader:
													'Relationship to policyholder',
												type: 'dropdown',
												placeholder: 'Select',
												value: get(
													this,
													'props.primaryinsurance.policyholder_relationship',
													null
												),
												options:
													get(
														this,
														'props.dependentVariants',
														[]
													) || [],
												header: 'primaryinsurance.policyholder_relationship',
												className:
													'patientCreateV2SectionInputFieldEdge',
												error: get(
													errorFields,
													'primaryinsurance.insurance_company_id',
													null
												),
												typeahead: true,
											},
											{
												required: false,
												fieldHeader:
													"Policyholder's DOB",
												type: 'textInputMask',
												inputMaskType: 'date',
												placeholder: 'MM/DD/YYYY',
												value: get(
													this,
													'props.primaryinsurance.policyholder_dob',
													null
												),
												header: 'primaryinsurance.policyholder_dob',
												className: '',
												error: null,
											},
									  ]
									: []
							)
							.concat([
								{
									required: false,
									fieldHeader: 'Athena insurance package ID',
									type: 'text',
									placeholder: '',
									value: get(
										this,
										'props.primaryinsurance.athena_insurance_package_id',
										null
									),
									header: 'primaryinsurance.athena_insurance_package_id',
									className:
										'patientCreateV2SectionInputFieldEdge',
									error: null,
								},
								{
									required: false,
									fieldHeader: 'Athena insurance name',
									type: 'text',
									placeholder: '',
									value: get(
										this,
										'props.primaryinsurance.athena_insurance_package_name',
										null
									),
									header: 'primaryinsurance.athena_insurance_package_name',
									className: '',
									error: null,
								},
							])}
					/>
				)}
				{!editMode &&
					get(this, 'props.doesPatientHasSeondaryInsurance', false) &&
					showSecondaryInsurance && (
						<CreatePatientV2BodySection
							changeValue={this.props.changeValue}
							title={"Patient's Secondary Insurance Card"}
							description={''}
							sectionRequired={false}
							sectionRecommended={false}
							hasCheckbox={false}
							inputFields={[
								{
									fieldHeader: 'Health Insurance',
									type: 'dropdown',
									required:
										!this.isSecondaryInsuranceFieldsRequired(),
									placeholder: 'Select',
									value: get(
										this,
										'props.secondaryinsurance.insurance_company_id',
										null
									),
									options:
										get(
											this,
											'props.insuranceCompanies',
											[]
										) || [],
									header: 'secondaryinsurance.insurance_company_id',
									className:
										this.props.OTHER_COMPANY !==
										this.props.secondaryinsurance
											.insurance_company_id
											? 'patientCreateV2SectionInputFieldEdge patientCreateV2SectionInputFieldEdgeFullWidthText'
											: '',
									error: get(
										errorFields,
										'secondaryinsurance.insurance_company_id',
										null
									),
									typeahead: true,
								},
							]
								.concat(
									this.props.OTHER_COMPANY ===
										this.props.secondaryinsurance
											.insurance_company_id
										? [
												{
													fieldHeader:
														'Health Insurance Company',
													type: 'text',
													required:
														!this.isSecondaryInsuranceFieldsRequired(),
													placeholder:
														'Health insurance company',
													value: get(
														this,
														'props.secondaryinsurance.company_name',
														null
													),
													header: 'secondaryinsurance.company_name',
													className:
														'patientCreateV2SectionInputFieldEdge',
													error: get(
														errorFields,
														'secondaryinsurance.company_name',
														null
													),
												},
										  ]
										: []
								)
								.concat([
									{
										fieldHeader: 'Insurance ID #',
										type: 'text',
										required:
											!this.isSecondaryInsuranceFieldsRequired(),
										placeholder: '1234567890',
										value: get(
											this,
											'props.secondaryinsurance.insurance_no',
											null
										),
										header: 'secondaryinsurance.insurance_no',
										className: '',
										error: get(
											errorFields,
											'secondaryinsurance.insurance_no',
											null
										),
									},
									{
										required: false,
										fieldHeader: 'Insurance phone number',
										fieldHeaderTooltip:
											'Please Enter the Insurance \nPhone Number Indicated on the \nBack of Your Insurance Card',
										type: 'textInputMask',
										inputMaskType: 'phone',
										placeholder: '(123) 456 - 789',
										value: get(
											this,
											'props.secondaryinsurance.phone',
											null
										),
										header: 'secondaryinsurance.phone',
										className:
											'patientCreateV2SectionInputFieldEdge',
										error: get(
											errorFields,
											'secondaryinsurance.phone',
											null
										),
									},
									{
										required: false,
										fieldHeader: 'Plan type (optional)',
										type: 'dropdown',
										placeholder: 'Select',
										value: get(
											this,
											'props.secondaryinsurance.plan_name',
											null
										),
										options: createEnumsOptions(
											this,
											['props', 'planTypesEnums'],
											'internal',
											'external',
											'internal'
										),
										header: 'secondaryinsurance.plan_name',
										className: '',
										error: null,
										typeahead: true,
									},
									{
										required: false,
										fieldHeader: 'Group #',
										type: 'text',
										placeholder: '1234567890',
										value: get(
											this,
											'props.secondaryinsurance.group_no',
											null
										),
										header: 'secondaryinsurance.group_no',
										className:
											'patientCreateV2SectionInputFieldEdge',
										error: get(
											errorFields,
											'secondaryinsurance.group_no',
											null
										),
									},
									{
										required: false,
										fieldHeader: 'Employer',
										type: 'text',
										placeholder: 'Company ABC',
										value: get(
											this,
											'props.secondaryinsurance.employer',
											null
										),
										header: 'secondaryinsurance.employer',
										className: '',
										error: null,
									},
									{
										required: false,
										fieldHeader: 'Insurance address',
										type: 'text',
										placeholder: '123 Main  street',
										value: get(
											this,
											'props.secondaryinsurance.insurance_address',
											null
										),
										header: 'secondaryinsurance.insurance_address',
										className:
											'patientCreateV2SectionInputFieldEdge patientCreateV2SectionInputFieldEdgeFullWidthText',
										error: get(
											errorFields,
											'secondaryinsurance.insurance_address',
											null
										),
									},
									{
										required: false,
										fieldHeader: 'Insurance city',
										type: 'text',
										placeholder: 'San Francisco',
										value: get(
											this,
											'props.secondaryinsurance.insurance_city',
											null
										),
										header: 'secondaryinsurance.insurance_city',
										className: '',
										error: get(
											errorFields,
											'secondaryinsurance.insurance_city',
											null
										),
									},
									{
										required: false,
										fieldHeader: 'Insurance state',
										type: 'dropdown',
										placeholder: 'Select',
										value: get(
											this,
											'props.secondaryinsurance.insurance_state',
											null
										),
										options: (
											get(this, 'props.statesEnum', []) ||
											[]
										).map((el) => {
											return {
												key: el.key,
												value: el.key,
												label: el.value,
											};
										}),
										header: 'secondaryinsurance.insurance_state',
										className:
											'patientCreateV2SectionInputFieldEdge',
										error: get(
											errorFields,
											'secondaryinsurance.insurance_state',
											null
										),
										typeahead: true,
									},
									{
										required: false,
										fieldHeader: 'Insurance zipcode',
										type: 'text',
										placeholder: '12345',
										value: get(
											this,
											'props.secondaryinsurance.insurance_zipcode',
											null
										),
										header: 'secondaryinsurance.insurance_zipcode',
										className: '',
										error: get(
											errorFields,
											'secondaryinsurance.insurance_zipcode',
											null
										),
									},
									{
										fieldHeader:
											'Is patient the primary policyholder?',
										type: 'radioButtonPill',
										placeholder: '',
										required:
											!this.isSecondaryInsuranceFieldsRequired(),
										options: [
											{ label: 'Yes', value: true },
											{ label: 'No', value: false },
										],
										value: get(
											this,
											'props.secondaryinsurance.is_policyholder',
											true
										),
										header: 'secondaryinsurance.is_policyholder',
										className: 'is_policyholder',
										error: get(
											errorFields,
											'secondaryinsurance.is_policyholder',
											null
										),
									},
									{
										fieldHeader:
											"Policyholder's biological sex",
										type: 'dropdown',
										required:
											!this.isSecondaryInsuranceFieldsRequired(),
										placeholder: 'Select',
										value: get(
											this,
											'props.secondaryinsurance.policyholder_biological_sex',
											null
										),
										options: createEnumsOptions(
											this,
											['props', 'biologicalSexVariants'],
											'internal',
											'external',
											'internal'
										),
										header: 'secondaryinsurance.policyholder_biological_sex',
										className: '',
										error: get(
											errorFields,
											'secondaryinsurance.policyholder_biological_sex',
											null
										),
										typeahead: true,
									},
								])
								.concat(
									!get(
										this,
										'props.secondaryinsurance.is_policyholder',
										true
									)
										? [
												{
													fieldHeader:
														"Policyholder's first name",
													type: 'text',
													required:
														!this.isSecondaryInsuranceFieldsRequired(),
													placeholder: 'John',
													value: get(
														this,
														'props.secondaryinsurance.policyholder_first_name',
														null
													),
													header: 'secondaryinsurance.policyholder_first_name',
													className:
														'patientCreateV2SectionInputFieldEdge',
													error: get(
														errorFields,
														'secondaryinsurance.policyholder_first_name',
														null
													),
												},
												{
													fieldHeader:
														"Policyholder's last name",
													type: 'text',
													required:
														!this.isSecondaryInsuranceFieldsRequired(),
													placeholder: 'Smith',
													value: get(
														this,
														'props.secondaryinsurance.policyholder_last_name',
														null
													),
													header: 'secondaryinsurance.policyholder_last_name',
													className: '',
													error: get(
														errorFields,
														'secondaryinsurance.policyholder_last_name',
														null
													),
												},
												{
													fieldHeader:
														'Relationship to policyholder',
													type: 'dropdown',
													required:
														!this.isSecondaryInsuranceFieldsRequired(),
													placeholder: 'Select',
													value: get(
														this,
														'props.secondaryinsurance.policyholder_relationship',
														null
													),
													options:
														get(
															this,
															'props.dependentVariants',
															[]
														) || [],
													header: 'secondaryinsurance.policyholder_relationship',
													className:
														'patientCreateV2SectionInputFieldEdge',
													error: get(
														errorFields,
														'secondaryinsurance.insurance_company_id',
														null
													),
													typeahead: true,
												},
												{
													required: false,
													fieldHeader:
														"Policyholder's DOB",
													type: 'textInputMask',
													inputMaskType: 'date',
													placeholder: 'MM/DD/YYYY',
													value: get(
														this,
														'props.secondaryinsurance.policyholder_dob',
														null
													),
													header: 'secondaryinsurance.policyholder_dob',
													className: '',
													error: null,
												},
										  ]
										: []
								)
								.concat([
									{
										required: false,
										fieldHeader:
											'Athena insurance package ID',
										type: 'text',
										placeholder: '',
										value: get(
											this,
											'props.secondaryinsurance.athena_insurance_package_id',
											null
										),
										header: 'secondaryinsurance.athena_insurance_package_id',
										className:
											'patientCreateV2SectionInputFieldEdge',
										error: null,
									},
									{
										required: false,
										fieldHeader: 'Athena insurance name',
										type: 'text',
										placeholder: '',
										value: get(
											this,
											'props.secondaryinsurance.athena_insurance_package_name',
											null
										),
										header: 'secondaryinsurance.athena_insurance_package_name',
										className: '',
										error: null,
									},
								])}
						/>
					)}
				<CreatePatientV2BodySection
					changeValue={this.props.changeValue}
					title={'External Care Team'}
					errorFields={errorFields}
					description={
						"Please add the patient's personal provider information to ensure documentation delivery."
					}
					sectionRequired={false}
					hasCheckbox={false}
					yesNoChoice={null}
					addAnother={this.props.addAnother}
					setReferringProviderIsDropdown={
						this.props.setReferringProviderIsDropdown
					}
					setReferringProviderIsNotDropdown={
						this.props.setReferringProviderIsNotDropdown
					}
					externalProviderProps={get(
						this.props,
						'externalProviderProps',
						{}
					)}
					providerSpecialties={
						get(this, 'props.providerSpecialties', null) || []
					}
					isExternalUser={get(this, 'props.isExternalUser', false)}
					activeRole={this.props?.activeRole}
				/>
				{!get(this, 'props.isExternalUser', false) && (
					<CreatePatientV2BodySection
						changeValue={this.props.changeValue}
						title={'Consent'}
						description={
							"Please select the patient's appropriate consent status."
						}
						sectionRequired={true}
						hasCheckbox={false}
						yesNoChoice={null}
						inputFields={[
							{
								required: true,
								fieldHeader: 'Consent to Treat',
								type: 'dropdown',
								placeholder: get(
									this,
									'props.patientConsentedByPartner',
									false
								)
									? 'Consented via Partner'
									: 'Select',
								options: this.getConsentToTreatEnums(),
								value: get(this, 'props.consentToTreat', null),
								header: 'consentToTreat',
								className: get(
									this,
									'props.patientConsentedByPartner',
									false
								)
									? 'patientCreateV2SectionInputDisabledDropdown'
									: '',
								disabled: get(
									this,
									'props.patientConsentedByPartner',
									false
								),
								typeahead: true,
							},
						]
					.concat(
						editMode && userRoleIsCCGCOrProdeng ? [
						{
							required: false,
							fieldHeader: '',
							type: 'simpleCheckboxRow',
							placeholder: null,
							disabled: !autorizationToRecontactIsConsented,
							label: `Patient Authorization to Recontact ${autorizationToRecontactIsConsented ? '('+ authorizationToRecontactConsentVersion +')':''}`,
							value: get(this, 'props.patientAuthorizationToRecontact', null),
							header: 'patientAuthorizationToRecontact',
							className: 'consent-to-text',
							description:
								'Patient consents to Genome Medical using and sharing their Health Information as described in the Patient Authorization to Recontact form.',
						},
					]:[])}
					/>
				)}
				<CreatePatientV2BodySection
					changeValue={this.props.changeValue}
					title={'Flags'}
					description={
						'Please select any patient-specific identifiers here for better quality of care.'
					}
					sectionRequired={false}
					hasCheckbox={false}
					yesNoChoice={null}
					inputFields={[
						{
							required: false,
							fieldHeader: '',
							type: 'checkboxRows',
							placeholder: null,
							options: get(this, 'props.tagsData', []),
							value: get(this, 'props.selectedTags', []),
							header: 'tags',
							className: null,
							duplicateRecordError: get(
								errorFields,
								'duplicateRecord',
								null
							),
						},
					]}
					noBottomBorder={true}
					pendingDuplicatePatients={get(
						this,
						'props.pendingDuplicatePatients',
						[]
					)}
					existingDuplicatePatients={get(
						this,
						'props.existingDuplicatePatients',
						{}
					)}
					addNewPendingUuid={this.props.addNewPendingUuid}
					handleChangePendingUuid={this.props.handleChangePendingUuid}
					updateExistingUuid={this.props.updateExistingUuid}
					removePendingUuid={this.props.removePendingUuid}
					isExternalUser={get(this, 'props.isExternalUser', false)}
					duplicateRecordError={get(
						errorFields,
						'duplicateRecord',
						null
					)}
				/>
			</div>
		);
	}
}

export default CreatePatientV2Body;
