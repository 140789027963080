import * as actionTypes from './actionTypes';
import _ from 'lodash';
import MetricsService from '../service/Metrics';
import { external_metrics_type } from '../Utils/dashboardUtils';

export const startLoadMetricsTotalPatient = () => {
	return { type: actionTypes.LOAD_METRICS_TOTAL_PATIENT_COUNT_START };
};

export function successLoadMetricsTotalPatient(docs) {
	return {
		type: actionTypes.LOAD_METRICS_TOTAL_PATIENT_COUNT_SUCCESS,
		payload: _.get(docs, 'data', {}),
	};
}

export function failureLoadMetricsTotalPatient(error) {
	return {
		type: actionTypes.LOAD_METRICS_TOTAL_PATIENT_COUNT_FAILURE,
		payload: error,
	};
}

export function UnloadMetricsTotalPatient() {
	return { type: actionTypes.UNLOAD_METRICS_TOTAL_PATIENT_COUNT };
}

export const startLoadMetricsTotalEncounter = () => {
	return { type: actionTypes.LOAD_METRICS_TOTAL_ENCOUNTER_COUNT_START };
};

export function successLoadMetricsTotalEncounter(docs) {
	return {
		type: actionTypes.LOAD_METRICS_TOTAL_ENCOUNTER_COUNT_SUCCESS,
		payload: _.get(docs, 'data', {}),
	};
}

export function failureLoadMetricsTotalEncounter(error) {
	return {
		type: actionTypes.LOAD_METRICS_TOTAL_ENCOUNTER_COUNT_FAILURE,
		payload: error,
	};
}

export function UnloadMetricsTotalEncounter() {
	return { type: actionTypes.UNLOAD_METRICS_TOTAL_ENCOUNTER_COUNT };
}

export const getMetricsTotalPatient = () => {
	return (dispatch) => {
		dispatch(startLoadMetricsTotalPatient());
		return MetricsService.get({
			type: external_metrics_type.total_patient_count,
		})
			.then((res) => {
				return dispatch(successLoadMetricsTotalPatient(res.data));
			})
			.catch((err) => {
				dispatch(failureLoadMetricsTotalPatient(err));
			});
	};
};

export const getMetricsTotalEncounter = (start_date, end_date) => {
	return (dispatch) => {
		dispatch(startLoadMetricsTotalEncounter());
		const data = {
			type: external_metrics_type.datewise_encounter_count,
			start_date: start_date,
			end_date: end_date,
		};
		return MetricsService.get(data)
			.then((res) => {
				return dispatch(successLoadMetricsTotalEncounter(res.data));
			})
			.catch((err) => {
				dispatch(failureLoadMetricsTotalEncounter(err));
			});
	};
};

export const resetMetricsTotals = () => {
	return (dispatch) => {
		dispatch(UnloadMetricsTotalPatient());
		dispatch(UnloadMetricsTotalEncounter());
	};
};
