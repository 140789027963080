//React & Redux
import React, { Fragment } from 'react';

//Images
import Edit from './../../../../assets/edit-outreach.svg';
import ReactSVG from 'react-svg';

//Utils
import { CARE_PATIENTS_ENTER_TEST_RESULT } from '../../../../Utils/permissionUtils';

//UI Libraries
import { Layout, Form } from 'gm-element-react';

//Components
import RBAC from '../../../RBAC';

const ViewGeneDetails = (props) => {
	const { editGeneResult, interpretation, item, nonGeneEnum, zygosity } =
		props;

	const interpretelbl = interpretation.find(
		(a) => a.key == item.interpretation
	);
	const zygositylbl = zygosity.find((a) => a.key == item.zygosity);

	const showMutationOnly =
		item.cDnaPos || item.pPosition || item.rsID || item.genoType;
	const showLabComment = item.labComments;
	const showRiskScore = item.riskScore || item.riskScoreNormalRange;
	const showIntrtprete =
		(interpretelbl && interpretelbl.display_name) ||
		(zygositylbl && zygositylbl.display_name);
	const showAdditionalInfo =
		item.clinvarID ||
		item.allele1Repeats ||
		item.allele2Repeats ||
		item.hgvs ||
		item.haploType ||
		item.copyNumber ||
		item.activityScore ||
		item.clinicalComments;
	const nonGeneTestType = nonGeneEnum.find(
		(a) => a.key == item.nonGeneTestType
	);
	const genedisplay =
		item.geneticTestGenesEnumsId != null
			? item.displayName
			: nonGeneTestType
			? nonGeneTestType.display_name
			: '--';

	return (
		<Form className='viewgene-form'>
			<Layout.Row gutter='38'>
				<Layout.Col span='7'>
					<Layout.Row>
						<Layout.Col span='24'>
							<span className='gene-name'>
								{genedisplay || '--'}
								<RBAC
									action={CARE_PATIENTS_ENTER_TEST_RESULT}
									yes={
										<ReactSVG
											data-testId='gene-result-edit-icon'
											src={Edit}
											onClick={() => editGeneResult()}
											className='gene-result-edit-icon'
										/>
									}
								/>
							</span>
						</Layout.Col>
					</Layout.Row>
				</Layout.Col>
				<Layout.Col span='17'>
					{showMutationOnly && (
						<>
							<Layout.Row>
								<Layout.Col
									span='24'
									className='mutation-header'
								>
									mutation info
								</Layout.Col>
							</Layout.Row>
							<Layout.Row gutter='20'>
								<Layout.Col span='6'>
									<label htmlFor='cDnaPos'>c.DNA pos</label>
									<span>{item.cDnaPos || '--'}</span>
								</Layout.Col>
								<Layout.Col span='6'>
									<label htmlFor='pPosition'>
										p.position
									</label>
									<span>{item.pPosition || '--'}</span>
								</Layout.Col>
								<Layout.Col span='6'>
									<label htmlFor='rsID'>rsID</label>
									<span>{item.rsID || '--'}</span>
								</Layout.Col>
								<Layout.Col span='6'>
									<label htmlFor='genoType'>Genotype</label>
									<span>{item.genoType || '--'}</span>
								</Layout.Col>
							</Layout.Row>
						</>
					)}
					{(showRiskScore || showLabComment) && (
						<Layout.Row>
							<Layout.Col
								span='24'
								className={`risk-score-header ${
									(showRiskScore || showLabComment) &&
									!showMutationOnly &&
									!showLabComment &&
									!showIntrtprete &&
									!showAdditionalInfo &&
									'showRiskScore'
								}`}
							>
								risk score
							</Layout.Col>
						</Layout.Row>
					)}
					<Layout.Row gutter='20'>
						<Layout.Col span='12'>
							{(showRiskScore || showLabComment) && (
								<Layout.Row gutter='20'>
									<Layout.Col span='12'>
										<label htmlFor='riskScore'>
											Patient's risk score
										</label>
										<span>{item.riskScore || '--'}</span>
									</Layout.Col>
									<Layout.Col span='12'>
										<label htmlFor='riskScoreNormalRange'>
											Normal range
										</label>
										<span>
											{item.riskScoreNormalRange || '--'}
										</span>
									</Layout.Col>
								</Layout.Row>
							)}
							{(showIntrtprete || showLabComment) && (
								<Fragment>
									<Layout.Row>
										<Layout.Col
											span='24'
											className='interprete-header'
										>
											Interpretation
										</Layout.Col>
									</Layout.Row>
									<Layout.Row>
										<Layout.Col span='12'>
											<label htmlFor='interpretation'>
												Interpretation
											</label>
											<span>
												{(interpretelbl &&
													interpretelbl.display_name) ||
													'--'}
											</span>
										</Layout.Col>
										<Layout.Col span='12'>
											<label
												htmlFor='zygosity'
												className='zygosity'
											>
												Zygosity
											</label>
											<span>
												{(zygositylbl &&
													zygositylbl.display_name) ||
													'--'}
											</span>
										</Layout.Col>
									</Layout.Row>
								</Fragment>
							)}
						</Layout.Col>

						{showLabComment && (
							<Layout.Col span='12'>
								<label htmlFor='labComments'>
									Comments from Lab about the variant
									(optional)
								</label>
								<span>{item.labComments || '--'}</span>
							</Layout.Col>
						)}
					</Layout.Row>

					{showAdditionalInfo && (
						<>
							<Layout.Row>
								<Layout.Col
									span='24'
									className='additional-info-header'
								>
									additional info
								</Layout.Col>
							</Layout.Row>
							<Layout.Row gutter='20'>
								<Layout.Col span='6'>
									<label htmlFor='clinvarID'>
										ClinVar ID - Allele ID
									</label>
									<span>{item.clinvarID || '--'}</span>
								</Layout.Col>
								<Layout.Col span='6'>
									<label htmlFor='allele1Repeats'>
										Allele 1 Repeats
									</label>
									<span>{item.allele1Repeats || '--'}</span>
								</Layout.Col>
								<Layout.Col span='6'>
									<label htmlFor='allele2Repeats'>
										Allele 2 Repeats
									</label>
									<span>{item.allele2Repeats || '--'}</span>
								</Layout.Col>
								<Layout.Col span='6'>
									<label htmlFor='hgvs'>HGVS</label>
									<span>{item.hgvs || '--'}</span>
								</Layout.Col>
							</Layout.Row>
							<Layout.Row gutter='20'>
								<Layout.Col span='6'>
									<label htmlFor='haploType'>Haplotype</label>
									<span>{item.haploType || '--'}</span>
								</Layout.Col>
								<Layout.Col span='6'>
									<label htmlFor='copyNumber'>
										Copy Number
									</label>
									<span>{item.copyNumber || '--'}</span>
								</Layout.Col>
								<Layout.Col span='6'>
									<label htmlFor='activityScore'>
										Activity Score
									</label>
									<span>{item.activityScore || '--'}</span>
								</Layout.Col>
								<Layout.Col
									span='24'
									className='clinician-comments-label'
								>
									<label htmlFor='clinicalComments'>
										Clinician comments - results specific
									</label>
									<span>
										{item.clinicianComments || '--'}
									</span>
								</Layout.Col>
							</Layout.Row>
						</>
					)}
				</Layout.Col>
			</Layout.Row>
		</Form>
	);
};

export default ViewGeneDetails;
