//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import noResultsIcon from './../../../assets/no-results.svg';

class ICDSearchNoResults extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		return (
			<div className='chargeICDSearchPlaceholderContainer'>
				<div className='chargeICDSearchPlaceholderIconAndText'>
					<ReactSVG src={noResultsIcon} />
					<div>No results found</div>
				</div>
			</div>
		);
	}
}

export default ICDSearchNoResults;
