//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//Utils
import { selectedExtProviderRoles, getFromEnumOrFormat } from '../../utils';

//Components
import Icon from '../Common/Icon';

//Styles
import './ExternalCareTeam.css';

const ExternalCareTeam = (props) => {
	const {
		addressLine1 = '',
		city = '',
		isArchived = false,
		phones = {},
		providerFullName = '',
		providerRole = [],
		providerSpecialties = [],
		receiveAllComms = false,
		specialities = [],
		state = '',
	} = props;

	const specialty = specialities?.[0] || '';
	const phoneFax = phones?.fax_office || null;

	return (
		<div className='external-care-team-wrapper'>
			<div className='external-care-team'>
				<span className='provider'>
					<span className='icon'>
						<Icon icon='stethoscope' className='stethoscope' />
					</span>
					<span className='info'>
						<span className='provider-name'>
							{providerFullName}
						</span>
						{!_.isEmpty(specialty) && (
							<span className='location'>
								{getFromEnumOrFormat(
									providerSpecialties,
									specialty,
									'name',
									'display_name'
								)}
							</span>
						)}
						{!_.isEmpty(addressLine1) && (
							<span className='location'>{addressLine1}</span>
						)}
						<span className='location'>{`${city}${
							!_.isEmpty(state) ? ', ' + state : ''
						}`}</span>
						{!_.isEmpty(phoneFax) && (
							<span className='location'>Fax: {phoneFax}</span>
						)}
						{receiveAllComms && (
							<span className='link'>
								Linked to All Encounters
							</span>
						)}
						{isArchived && (
							<span className='link red'>
								{'Provider Archived'}
							</span>
						)}
					</span>
				</span>
				<span className='designation'>
					{_.join(selectedExtProviderRoles([...providerRole]), ', ')}
				</span>
			</div>
		</div>
	);
};

export default ExternalCareTeam;
