//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import MLOOrderPendingLabSubmission from './MLOOrderPendingLabSubmission';
import MLOResultsCapNotSent from './MLOResultsCapNotSent';
import MLOOrderRequestStatusUnknown from './MLOOrderRequestStatusUnknown';
import VisitCompletionStatusUnknown from './VisitCompletionStatusUnknown';
import CAPNotSentVisit from './CAPNotSentVisit';
import InsuranceClaimNotFiled from './InsuranceClaimNotFiled';

export default class DashboardEncounterContainer extends React.Component {
	render() {
		const filter =
			get(this, 'props.history.location.search', '').split('=')[1] ||
			null;

		if (filter == 'order_pending_lab_submission') {
			return <MLOOrderPendingLabSubmission {...this.props} />;
		} else if (filter == 'results_cap_not_sent') {
			return <MLOResultsCapNotSent {...this.props} />;
		} else if (filter == 'order_request_status_unknown') {
			return <MLOOrderRequestStatusUnknown {...this.props} />;
		} else if (filter == 'appointment_status') {
			return <VisitCompletionStatusUnknown {...this.props} />;
		} else if (filter == 'cap_not_sent') {
			return <CAPNotSentVisit {...this.props} />;
		} else if (filter == 'insurance_claim_filed') {
			return <InsuranceClaimNotFiled {...this.props} />;
		} else {
			return <div />;
		}
	}
}
