import * as actionTypes from './actionTypes';
import RiseAnalyticsService from '../service/RiseAnalytics';

const loadingRiseDashboardData = () => {
	return {
		type: actionTypes.LOADING_RISE_DASHBOARD_DATA,
	};
};

export const loadedRiseDashboardData = (data) => {
	return {
		type: actionTypes.LOADED_RISE_DASHBOARD_DATA,
		payload: data,
	};
};

const riseDashboardDataError = (err) => {
	return {
		type: actionTypes.RISE_DASHBOARD_DATA_ERROR,
		payload: err,
	};
};

export function loadRiseDashboardData(data, dimension) {
	return (dispatch) => {
		dispatch(loadingRiseDashboardData());
		RiseAnalyticsService.getRiseDashboardData(data, dimension)
			.then((res) => {
				dispatch(loadedRiseDashboardData(res.data));
			})
			.catch((err) => {
				dispatch(riseDashboardDataError(err));
			});
	};
}

const loadingRiseDashboardExportReport = () => {
	return {
		type: actionTypes.LOADING_RISE_DASHBOARD_EXPORT_REPORT,
	};
};

export const loadedRiseDashboardExportReport = (data) => {
	return {
		type: actionTypes.LOADED_RISE_DASHBOARD_EXPORT_REPORT,
		payload: data,
	};
};

const riseDashboardExportReportError = (err) => {
	return {
		type: actionTypes.RISE_DASHBOARD_EXPORT_REPORT_ERROR,
		payload: err,
	};
};

export function riseDashboardExportReport(data, dimension) {
	return (dispatch) => {
		dispatch(loadingRiseDashboardExportReport());
		RiseAnalyticsService.riseDashboardExportReport(data, dimension)
			.then((res) => {
				dispatch(loadedRiseDashboardExportReport(res.data));
			})
			.catch((err) => {
				dispatch(riseDashboardExportReportError(err));
			});
	};
}
