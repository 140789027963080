import * as actionTypes from '../actions/actionTypes';

const defaultPartnerOrganization = {
	gettingpartnerOrganization: false,
	partnerOrganization: [],
	partnerOrganizationError: null,
};

const defaultProviderAffiliations = {
	gettingProviderAffiliations: false,
	provideraffiliations: [],
	provideraffiliationsError: null,
};

export const exportReport = (state = { report_status: [] }, action) => {
	if (action.type === actionTypes.LOADING_SEND_PROVIDER_REPORTS) {
		const newState = Object.assign({}, state);
		newState.getting_report_status = true;
		newState.report_status = [];
		newState.report_status_error = null;
		return newState;
	} else if (action.type === actionTypes.SEND_PROVIDER_REPORTS) {
		const newState = Object.assign({}, state);
		newState.getting_report_status = false;
		newState.report_status = action.payload || [];
		newState.report_status_error = null;
		return newState;
	} else if (action.type === actionTypes.SEND_PROVIDER_REPORTS_ERROR) {
		const newState = Object.assign({}, state);
		newState.getting_report_status = false;
		newState.report_status = [];
		newState.report_status_error = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const provideraffiliations = (
	state = defaultProviderAffiliations,
	action
) => {
	if (action.type === actionTypes.GETTING_PROVIDER_AFFILIATIONS) {
		const newState = Object.assign({}, state);
		newState.gettingProviderAffiliations = true;
		newState.provideraffiliations = [];
		newState.provideraffiliationsError = null;
		return newState;
	} else if (action.type === actionTypes.PROVIDER_AFFILIATIONS) {
		const newState = Object.assign({}, state);
		newState.gettingProviderAffiliations = false;
		newState.provideraffiliations = action.payload;
		newState.provideraffiliationsError = null;
		return newState;
	} else if (action.type === actionTypes.PROVIDER_AFFILIATIONS_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingProviderAffiliations = false;
		newState.provideraffiliations = [];
		newState.provideraffiliationsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const partnerOrganization = (
	state = defaultPartnerOrganization,
	action
) => {
	if (action.type == actionTypes.LOADING_PARTNER_ORGANIZATION) {
		const newState = Object.assign({}, state);
		newState.gettingpartnerOrganization = true;
		newState.partnerOrganization = [];
		newState.partnerOrganizationError = null;
		return newState;
	} else if (action.type == actionTypes.GET_PARTNER_ORGANIZATION_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.gettingpartnerOrganization = false;
		newState.partnerOrganization = action.payload;
		newState.partnerOrganizationError = null;
		return newState;
	} else if (action.type === actionTypes.GET_PARTNER_ORGANIZATION_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingpartnerOrganization = false;
		newState.partnerOrganization = [];
		newState.partnerOrganizationError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getAllReports = (state = { allreports: [] }, action) => {
	if (action.type === actionTypes.GET_ALL_REPORT_STATUS) {
		const newState = Object.assign({}, state);
		newState.allreports = action.payload || [];
		return newState;
	} else {
		return state;
	}
};

const defaultState = {
	data: [],
	pagination: {},
	error: null,
	loading: false,
	loaded: false,
};

export const providers = (state = defaultState, action = {}) => {
	switch (action.type) {
		case actionTypes.GET_PROVIDERS_LIST_START:
			return {
				...state,
				loading: true,
				error: null,
			};

		case actionTypes.GET_PROVIDERS_LIST_SUCCESS: {
			const pagination = { ...action.payload };
			delete pagination.data;

			return {
				...state,
				loading: false,
				loaded: true,
				data: action.payload.data || [],
				pagination: action.payload.pagination || pagination,
			};
		}
		case actionTypes.GET_PROVIDERS_LIST_FAILURE:
			return {
				...state,
				loading: false,
				loaded: true,
				error: action.payload,
			};

		default:
			return state;
	}
};

export const updateProvider = (state = {}, action) => {
	if (action.type === actionTypes.UPDATING_PROVIDER) {
		const newState = Object.assign({}, state);
		newState.updatingProvider = true;
		newState.updatedProvider = false;
		newState.updatedProviderError = null;
		return newState;
	} else if (action.type === actionTypes.UPDATE_PROVIDER_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.updatingProvider = false;
		newState.updatedProvider = action.payload;
		newState.updatedProviderError = null;
		return newState;
	} else if (action.type === actionTypes.UPDATE_PROVIDER_FAILURE) {
		const newState = Object.assign({}, state);
		newState.updatingProvider = false;
		newState.updatedProvider = false;
		newState.updatedProviderError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const createProvider = (state = {}, action) => {
	if (action.type === actionTypes.CREATING_PROVIDER) {
		const newState = Object.assign({}, state);
		newState.creatingProvider = true;
		newState.createdProvider = false;
		newState.createdProviderError = null;
		return newState;
	} else if (action.type === actionTypes.CREATE_PROVIDER_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.creatingProvider = false;
		newState.createdProvider = action.payload;
		newState.createdProviderError = null;
		return newState;
	} else if (action.type === actionTypes.CREATE_PROVIDER_FAILURE) {
		const newState = Object.assign({}, state);
		newState.creatingProvider = false;
		newState.createdProvider = false;
		newState.createdProviderError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const providerEnums = (state = {}, action) => {
	if (action.type === actionTypes.GETTING_PROVIDER_SPECIALTIES) {
		const newState = Object.assign({}, state);
		newState.gettingProviderSpecialties = true;
		newState.providerSpecialties = false;
		newState.providerSpecialtiesError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_PROVIDER_SPECIALTIES) {
		const newState = Object.assign({}, state);
		newState.gettingProviderSpecialties = false;
		newState.providerSpecialties = action.payload;
		newState.providerSpecialtiesError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_PROVIDER_SPECIALTIES_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingProviderSpecialties = false;
		newState.providerSpecialties = false;
		newState.providerSpecialtiesError = action.payload;
		return newState;
	} else if (action.type === actionTypes.GETTING_PROVIDER_CREDENTIALS) {
		const newState = Object.assign({}, state);
		newState.gettingProviderCredentials = true;
		newState.providerCredentials = false;
		newState.providerCredentialsError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_PROVIDER_CREDENTIALS) {
		const newState = Object.assign({}, state);
		newState.gettingProviderCredentials = false;
		newState.providerCredentials = action.payload;
		newState.providerCredentialsError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_PROVIDER_CREDENTIALS_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingProviderCredentials = false;
		newState.providerCredentials = false;
		newState.providerCredentialsError = action.payload;
		return newState;
	} else {
		return state;
	}
};
