//React & Redux
import React, { useState } from 'react';

//Components
import RadioGroup from '../Common/controls/RadioGroup/RadioGroup';

const radioOptions = () => {
	return [
		{
			key: 'provider_report',
			display_name: (
				<div>
					<div className='report_types_labels'>
						Referral status report
					</div>
					<div className='report_types_desc_labels'>
						Status of each patient referred during a chosen date
						range for the selected Referral Program(s)/Referral
						Partner(s)
					</div>
				</div>
			),
		},
		{
			key: 'new_patient_seen_report',
			display_name: (
				<div>
					<div className='report_types_labels'>
						New patients seen report
					</div>
					<div className='report_types_desc_labels'>
						List of all new patients (by Referral Partners/Referral
						programs) whose first completed encounter falls within a
						chosen date range
					</div>
				</div>
			),
		},
		{
			key: 'completed_encounters_report',
			display_name: (
				<div>
					<div className='report_types_labels'>
						Completed encounters report
					</div>
					<div className='report_types_desc_labels'>
						List of all completed encounters (by Referral
						Partners/Referral programs) during a chosen date range
					</div>
				</div>
			),
		},
	];
};

const ReportTypeSelection = (props) => {
	const { report_type, selectedReportType } = props;
	const [value, setValue] = useState(report_type || 'provider_report');

	const handleChange = (value) => {
		setValue(value);
		selectedReportType(value);
	};

	return (
		<div className='report_type_selection'>
			<div className='select_report_label'>
				{' '}
				Select the type of report you'd like to export{' '}
			</div>
			<div className='report_type_selector_wrapper'>
				<RadioGroup
					options={radioOptions()}
					value={value}
					onChange={handleChange}
				/>
			</div>
		</div>
	);
};

export default ReportTypeSelection;
