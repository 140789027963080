import React from 'react';
import classNames from 'classnames';

const SingleSelectButtons = (props) => {
	const {
		canSaveValue = false,
		resetInternalValue,
		saveValue,
		shouldHaveDropShadow = false,
	} = props;

	return (
		<div
			className={classNames('singleSelectBottomRowContainer', {
				scrollableModalDropShadowBottom: shouldHaveDropShadow,
			})}
		>
			<div className='singleSelectReset' onClick={resetInternalValue}>
				Reset
			</div>
			<div
				data-testId='single-select-save'
				className={classNames('singleSelectSave', {
					singleSelectSaveDisabled: !canSaveValue,
				})}
				onClick={saveValue}
			>
				Save
			</div>
		</div>
	);
};

export default SingleSelectButtons;
