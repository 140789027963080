//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';

//Components
import OutreachGroupList from '../../../../Common/Outreach/OutreachGroupList';

const RORSegment = (props) => {
	const communications = _.get(props, ['communications'], []);

	return (
		<Fragment>
			<OutreachGroupList
				outreachList={communications}
				outreachType={'encounterDetail'}
				outreachEnums={props.outreachEnums}
				outreachStatusEnums={props.outreachStatusEnums}
				patientDeclinedReasonsEnums={props.patientDeclinedReasonsEnums}
				gcRoROutreachStopReasonsEnums={
					props.gcRoROutreachStopReasonsEnums
				}
				addHandleSubmit={props.addOutreachHandleSubmit}
				editHandleSubmit={props.editOutreachHandleSubmit}
				handleFUDeclinedSubmit={props.handleFUDeclinedOutreachSubmit}
				getList={props.getOutreachLog}
				geneTestOrderRequest={props.geneTestOrderRequest}
				showUnableStopOutreachModal={props.showUnableStopOutreachModal}
				closeUnableStopOutreachModal={
					props.closeUnableStopOutreachModal
				}
				getLatestOutreachStatus={props.getLatestOutreachStatus}
			/>
		</Fragment>
	);
};

export default RORSegment;
