import * as actionTypes from '../actions/actionTypes';

const defaultState = {
	loading: false,
	data: {},
	error: null,
	exportData: null,
	exportLoading: false,
	exportError: null,
};

export default (state = { ...defaultState }, action = {}) => {
	if (action.type === actionTypes.LOADING_RISE_DASHBOARD_DATA) {
		const newState = Object.assign({}, state);
		newState.loading = true;
		newState.error = null;
		return newState;
	} else if (action.type === actionTypes.LOADED_RISE_DASHBOARD_DATA) {
		const newState = Object.assign({}, state);
		newState.loading = false;
		newState.data = action.payload;
		newState.error = null;
		return newState;
	} else if (action.type === actionTypes.RISE_DASHBOARD_DATA_ERROR) {
		const newState = Object.assign({}, state);
		newState.loading = false;
		newState.data = {};
		newState.error = action.payload;
		return newState;
	} else if (
		action.type === actionTypes.LOADING_RISE_DASHBOARD_EXPORT_REPORT
	) {
		const newState = Object.assign({}, state);
		newState.exportLoading = true;
		newState.exportError = null;
		return newState;
	} else if (
		action.type === actionTypes.LOADED_RISE_DASHBOARD_EXPORT_REPORT
	) {
		const newState = Object.assign({}, state);
		newState.exportLoading = false;
		newState.exportData = action.payload;
		newState.exportError = null;
		return newState;
	} else if (action.type === actionTypes.RISE_DASHBOARD_EXPORT_REPORT_ERROR) {
		const newState = Object.assign({}, state);
		newState.exportLoading = false;
		newState.exportData = {};
		newState.exportError = action.payload;
		return newState;
	} else {
		return state;
	}
};
