import * as actionTypes from '../actions/actionTypes';

const defaultAddStructuralVariantFinding = {
	addStructuralVariantFinding: {},
	addStructuralVariantFindingLoading: false,
	addStructuralVariantFindingError: null,
};

const defaultStructuralFindingValidation = {
	structuralFindingValidationText: {},
	structuralFindingValidationLoading: false,
	structuralFindingValidationError: null,
};

export const addStructuralVariantFinding = (
	state = defaultAddStructuralVariantFinding,
	action = {}
) => {
	switch (action.type) {
		case actionTypes.ADD_STRUCTURALVARIANT_FINDING_LOADING:
			return {
				...state,
				addStructuralVariantFinding: {},
				addStructuralVariantFindingLoading: true,
				addStructuralVariantFindingError: null,
			};
		case actionTypes.ADD_STRUCTURALVARIANT_FINDING_SUCCESS:
			return {
				...state,
				addStructuralVariantFinding: action.payload,
				addStructuralVariantFindingLoading: false,
				addStructuralVariantFindingError: null,
			};
		case actionTypes.ADD_STRUCTURALVARIANT_FINDING_ERROR:
			return {
				...state,
				addStructuralVariantFinding: {},
				addStructuralVariantFindingLoading: false,
				addStructuralVariantFindingError: action.payload,
			};
		default:
			return state;
	}
};

export const getStructuralFindingValidation = (
	state = defaultStructuralFindingValidation,
	action = {}
) => {
	switch (action.type) {
		case actionTypes.GET_STRUCTURAL_FINDING_VALIDATION_LOADING:
			return {
				...state,
				structuralFindingValidationLoading: true,
				structuralFindingValidationError: null,
			};
		case actionTypes.GET_STRUCTURAL_FINDING_VALIDATION_SUCCESS:
			return {
				...state,
				structuralFindingValidationText: action.payload,
				structuralFindingValidationLoading: false,
				structuralFindingValidationError: null,
			};
		case actionTypes.GET_STRUCTURAL_FINDING_VALIDATION_ERROR:
			return {
				...state,
				structuralFindingValidationLoading: false,
				structuralFindingValidationError: action.payload,
			};
		case actionTypes.RESET_GET_STRUCTURAL_FINDING_VALIDATION:
			return {
				...state,
				structuralFindingValidationLoading: false,
				structuralFindingValidationText: {},
				structuralFindingValidationError: null,
			};
		default:
			return state;
	}
};
