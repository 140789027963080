//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import OutcomeRow from './OutcomeRow.js';

class SelectOutcome extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rows: [
				{
					icon: 'check',
					title: 'Accepted',
					subtitle: 'Go to scheduling',
					stage: 'schedule',
				},
				{
					icon: 'x',
					title: 'Unsuccessful Attempt',
					subtitle: 'Add Outreach',
					stage: 'addOutreach',
				},
				{
					icon: 'noOutreach',
					title:
						get(this, ['props', 'selectedpage'], 'patient') ==
						'RoRDashboard'
							? 'Patient Declined Follow Up'
							: 'Declined Follow Up',
					subtitle:
						get(this, ['props', 'selectedpage'], 'patient') ==
						'RoRDashboard'
							? 'Mark as F/U Declined'
							: 'Mark as Do Not Outreach',
					stage: 'doNotOutreach',
				},
			],
		};
		//bindings
	}
	//functions

	render() {
		return (
			<div className='patientCreateOutreachModalSelectContainer'>
				<div
					style={{
						fontSize: '13px',
						fontFamily: 'RionaSans-Bold',
						color: '#262837',
						marginBottom: '8px',
					}}
				>
					What is the outcome?
				</div>
				{(get(this, 'state.rows', []) || []).map((el) => {
					return (
						<OutcomeRow
							icon={get(el, 'icon', null)}
							title={get(el, 'title', null)}
							subtitle={get(el, 'subtitle', null)}
							stage={get(el, 'stage', null)}
							setStage={this.props.setStage}
							redirectToScheduling={
								this.props.redirectToScheduling
							}
							key={get(el, 'title', null)}
						/>
					);
				})}
			</div>
		);
	}
}

export default SelectOutcome;
