//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';

//Other Libraries
import Datetime from 'react-datetime';
import classnames from 'classnames';

//Utils
import { getMomentDateOrString, getDateString } from './utilsfunctions';

/* Component accept value as a string and return value as a string in onchange and on blur.
when value is valid dateformat (YYYY-MM-DD), it will pass moment object to plugin else it will pass value.
when value is valid dateformat (YYYY-MM-DD), it will convert return string in YYYY-MM-DD else it will return whatever value it has.
*/
export const EncounterDateField = ({
	value,
	onChange,
	placeholder,
	extraclassName,
	disabled = false,
	error,
	disabledDate,
	onBlur,
	onRight,
	istouched = true,
}) => {
	const iserror = istouched && error && !_.isNil(error) && !_.isEmpty(error);
	const currentClassName = classnames(
		iserror ? 'encounter-input-error-field' : 'encounter-input-field',
		extraclassName,
		{ 'calendar-right-encounter': onRight }
	);

	return (
		<Fragment>
			<div className='relative-section'>
				<div className={currentClassName}>
					<Datetime
						dateFormat={'MM/DD/YYYY'}
						timeFormat={false}
						value={getMomentDateOrString(value)}
						isDisabled={disabled}
						onChange={(value) => onChange(getDateString(value))}
						isValidDate={disabledDate}
						onBlur={(value) => onBlur(getDateString(value))}
						strictParsing={true}
						inputProps={{
							disabled: disabled,
							placeholder: placeholder || 'Date',
							autoComplete: 'no',
						}}
						closeOnTab={true}
						closeOnSelect={true} // if we enabled this then sometime blur might not be called from plugin.
						className={iserror ? 'inputFieldDateError' : ''}
					/>
				</div>
				<i className='el-input__icon el-icon-date'></i>
				<div
					className='inputFieldError'
					style={{ top: 'unset', left: 'unset' }}
				>
					{iserror ? error : ''}
				</div>
			</div>
		</Fragment>
	);
};
