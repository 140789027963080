//React & Redux
import React from 'react';
import PropTypes from 'prop-types';

//Lodash
import _ from 'lodash';

/**
 * @param {Object} props
 * @param {string} props.color
 * @param {'DOWN' | 'UP'} props.direction
 */
const DownArrow = (props) => {
	const styles = {
		svg: {
			display: 'flex',
			transform: props.direction === 'UP' ? 'rotate(180deg)' : '',
		},
		path: {
			fill: _.get(props, 'color', '#7b8291'),
			stroke: _.get(props, 'color', '#7b8291'),
		},
	};

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			style={styles.svg}
			width='16'
			height='16'
			viewBox='0 0 16 16'
		>
			<g id='prefix__ic_chevron_left_24px' transform='rotate(-90 8 8)'>
				<path
					id='prefix__Path_371'
					d='M12.94 6.94L12 6l-4 4 4 4 .94-.94L9.887 10z'
					data-name='Path 371'
					transform='translate(-2.667 -2)'
					style={styles.path}
				/>
				<path
					id='prefix__Path_372'
					d='M0 0h16v16H0z'
					data-name='Path 372'
					style={{ fill: 'none' }}
				/>
			</g>
		</svg>
	);
};

DownArrow.propTypes = {
	color: PropTypes.string,
};

export default DownArrow;
