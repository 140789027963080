//React & Redux
import React, { useEffect, useState } from 'react';

//Images
import ReactSVG from 'react-svg';
import add from '../../assets/add.svg';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import AddEditInsuranceModal from './AddEditInsuranceModal.js';
import CommonCheckbox from '../Common/Checkbox.js';
import Td from '../Common/Table/Td';
import Th from '../Common/Table/Th';
import Tr from '../Common/Table/Tr';
import Tbody from '../Common/Table/Tbody';
import Thead from '../Common/Table/Thead';
import Table from '../Common/Table/Table';
import TemplateModalReferralPrograms from '../Templates/TemplateModalReferralPrograms.js';

//Hooks
import { usePrevious } from '../../hooks/usePrevious';

//Styles
import './css/insurance-list.css';

const InsuranceList = (props) => {
	const {
		createInsuranceCompanyInfo,
		enqueueSnackbar,
		getInsuranceCompanies,
		gettingReferralPrograms,
		insuranceInfo: {
			createdInsuranceCompanyError = null,
			creatingInsuranceCompany = false,
			loadedList,
			loadingList,
			updatedInsuranceCompanyError = null,
			updatingInsuranceCompany = false,
		},
		limit,
		referralPrograms,
		updateInsuranceCompanyInfo,
	} = props;

	const previousCreatingInsuranceCompany = usePrevious(
		creatingInsuranceCompany
	);
	const previousUpdatingInsuranceCompany = usePrevious(
		updatingInsuranceCompany
	);

	useEffect(() => {
		getInsuranceCompanies();
	}, []);

	useEffect(() => {
		if (previousCreatingInsuranceCompany && !creatingInsuranceCompany) {
			if (createdInsuranceCompanyError) {
				enqueueSnackbar('Error in adding insurance company', {
					variant: 'error',
					className: 'export_notification',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
			} else {
				enqueueSnackbar('Added insurance company', {
					variant: 'info',
					className: 'export_notification',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				hideAddEditInsurance();
				pageChange(null, 0);
			}
		}
	}, [creatingInsuranceCompany]);

	useEffect(() => {
		if (previousUpdatingInsuranceCompany && !updatingInsuranceCompany) {
			if (updatedInsuranceCompanyError) {
				enqueueSnackbar('Error in updating insurance company', {
					variant: 'error',
					className: 'export_notification',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
			} else {
				enqueueSnackbar('Updated insurance company', {
					variant: 'info',
					className: 'export_notification',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				hideAddEditInsurance();
				pageChange(null, 0);
			}
		}
	}, [updatingInsuranceCompany]);

	const [currentOffset, setCurrentOffset] = useState(0);
	const [editInsuranceInfo, setEditInsuranceInfo] = useState({});
	const [modalData, setModalData] = useState({});
	const [order] = useState('asc');
	const [orderBy] = useState('name');
	const [pageLimit, setPageLimit] = useState(limit);
	const [renderAddEditInsurance, setRenderAddEditInsurance] = useState(false);
	const [renderPrepayExceptionsBool, setRenderPrepayExceptionsBool] =
		useState(false);

	const {
		athenaPackageID = null,
		companyID = null,
		insuranceCompanyName,
		insurance_prepay_exceptions: insurancePrepayExceptions = [],
	} = editInsuranceInfo;

	const showAddEditInsurance = () => {
		setRenderAddEditInsurance(true);
	};

	const hideAddEditInsurance = () => {
		setEditInsuranceInfo({});
		setModalData({});
		setRenderAddEditInsurance(false);
	};

	const handleRowClick = (e, el) => {
		const targetNode = e?.target || document.createElement('span');
		if (targetNode.classList.contains('prePayCell')) {
			if (el?.insurance_prepay_exceptions?.length || 0 > 0)
				renderPrepayExceptions(el);
		} else {
			handleEditInsuranceInfo(el);
		}
	};

	const renderPrepayExceptions = (insuranceInfo) => {
		setEditInsuranceInfo(insuranceInfo);
		setRenderPrepayExceptionsBool(true);
	};

	const closePrepayExceptions = () => {
		setEditInsuranceInfo({});
		setRenderPrepayExceptionsBool(false);
	};

	const handleEditInsuranceInfo = (insuranceInfo) => {
		setEditInsuranceInfo(insuranceInfo);
		setRenderAddEditInsurance(true);
	};

	const pageChange = (e, offset) => {
		fetchNewResults({ limit: pageLimit, page: offset / pageLimit + 1 });
		setCurrentOffset(offset);
	};

	const handleResultsPerPageChange = (newLimit) => {
		fetchNewResults({ limit: newLimit, page: '1' });
		setCurrentOffset(0);
		setPageLimit(newLimit);
	};

	const fetchNewResults = (data = { limit: pageLimit }) => {
		getInsuranceCompanies(data);
	};

	const buildReqData = (data) => {
		data && setModalData(data);
	};

	const addOtherinLastPosition = (companies) => {
		let filteredCompany = [...companies];
		const otherCompany = filteredCompany.find(
			(a) => a.insuranceCompanyName == 'Other'
		);
		if (otherCompany) {
			filteredCompany = _.filter(companies, function (company) {
				return company && company.insuranceCompanyName !== 'Other';
			});
			filteredCompany.push(otherCompany);
		}
		return filteredCompany;
	};

	const getPrepayText = (company) => {
		const {
			consultationPrice = '',
			insurance_prepay_exceptions: exceptions = [],
		} = company;

		let price = consultationPrice;

		if (!price && price !== 0) {
			price = '--';
		} else {
			price = '$' + price;
		}

		if (!Array.isArray(exceptions) || !exceptions.length) {
			return <span className='prePayCell'>{price}</span>;
		} else {
			return (
				<span className='prePayCell prePayText'>
					{`${price} w/ exceptions`}
				</span>
			);
		}
	};

	const getPreformedText = () => {
		const preformedTextArr = [];
		if (Array.isArray(insurancePrepayExceptions)) {
			insurancePrepayExceptions.forEach((exception) => {
				let price = exception.consultation_price || '--';
				if (!price && price !== 0) {
					price = '--';
				}
				preformedTextArr.push(
					`${exception.partner_name || '--'} ($${price})`
				);
			});
		}
		return preformedTextArr.sort();
	};

	const handleAddOrEditInsurance = (exceptions = []) => {
		const exceptionsFormatted = exceptions.map((el) => {
			try {
				if (typeof el.consultation_price === 'string') {
					el.consultation_price = Number(el.consultation_price);
				}
				return el;
			} catch (e) {
				return el;
			}
		});

		const payload = {
			...modalData,
			insurance_prepay_exceptions: exceptionsFormatted,
		};

		if (!editInsuranceInfo || _.isEmpty(editInsuranceInfo)) {
			createInsuranceCompanyInfo(payload);
		} else if (companyID) {
			const modalPackageID = payload.athenaPackageID || null;
			const editInfoPackageID = athenaPackageID;
			if (
				modalPackageID &&
				editInfoPackageID &&
				modalPackageID == editInfoPackageID
			) {
				delete payload.athenaPackageID;
			}
			updateInsuranceCompanyInfo(payload, companyID);
		} else {
			return;
		}
	};

	const tableData = addOtherinLastPosition(loadedList?.data || []);

	const pagination = {
		has_next: loadedList?.pagination?.has_next_page || false,
		has_prev: loadedList?.pagination?.has_prev_page || false,
		limit: loadedList?.pagination?.limit || 0,
		offset: currentOffset,
		total: loadedList?.pagination?.count || 0,
	};

	const sortBy = {
		key: orderBy,
		value: order,
	};

	return (
		<main className='insurance-container'>
			<div className='insurance-list-header'>
				{renderAddEditInsurance && (
					<AddEditInsuranceModal
						{...{
							buildReqData,
							creatingInsuranceCompany,
							editInsuranceInfo,
							handleAddOrEditInsurance,
							hideAddEditInsurance,
							modalData,
							referralPrograms,
							updatingInsuranceCompany,
						}}
					/>
				)}

				{renderPrepayExceptionsBool && (
					<TemplateModalReferralPrograms
						close={closePrepayExceptions}
						preformedText={getPreformedText()}
						subtitle={insuranceCompanyName || '--'}
						title={'PrePay Exceptions'}
					/>
				)}

				<button
					type='button'
					className='btn-add-insurance'
					onClick={showAddEditInsurance}
				>
					<span className='btn-add-create-template'>
						<ReactSVG
							className={'menuItemIconWhite new_encounter'}
							src={add}
						/>
					</span>
					<span className='text_space'>Add Insurance</span>
				</button>
			</div>
			{loadingList || gettingReferralPrograms ? (
				<Loading className='loaderTestManagement' loading={true} />
			) : (
				<Table
					loading={false}
					pagination={pagination}
					handlePageChange={pageChange}
					hasData={!_.isEmpty(tableData)}
					noResultsFound={
						<div className='loadingrow cellText'>No results</div>
					}
					className='tbl-insurance-list'
					sortBy={sortBy}
					resultsPerPage={[25, 50, 100]}
					handleResultsPerPageChange={handleResultsPerPageChange}
				>
					<Thead>
						<Tr>
							<Th>Insurance Company Name</Th>
							<Th>Package Name</Th>
							<Th>Package ID</Th>
							<Th>No Supervision Billing</Th>
							<Th>Use GT Modifier</Th>
							<Th>Auth Req</Th>
							<Th>MD Required</Th>
							<Th>PrePay To Charge</Th>
						</Tr>
					</Thead>
					<Tbody>
						{tableData.map((el) => (
							<Tr
								key={el.id}
								style={{ cursor: 'pointer' }}
								onClick={(e) => handleRowClick(e, el)}
							>
								<Td
									className='cellText'
									minWidth='160px'
									maxWidth='160px'
								>
									{el.insuranceCompanyName}
								</Td>
								<Td
									className='cellText'
									minWidth='160px'
									maxWidth='160px'
								>
									{el.athenaPackageName}
								</Td>
								<Td
									className='cellText'
									minWidth='140px'
									maxWidth='140px'
								>
									{el.athenaPackageID}
								</Td>
								<Td
									className='cellText'
									minWidth='120px'
									maxWidth='120px'
								>
									<CommonCheckbox
										id={el.id}
										textColor={'purple'}
										disabled={true}
										checked={el.noSupervisingBilling}
									/>
								</Td>
								<Td
									className='cellText'
									minWidth='120px'
									maxWidth='120px'
								>
									<CommonCheckbox
										id={el.id}
										textColor={'purple'}
										disabled={true}
										checked={el.useGTModifier}
									/>
								</Td>
								<Td
									className='cellText'
									minWidth='120px'
									maxWidth='120px'
								>
									<CommonCheckbox
										id={el.id}
										textColor={'purple'}
										disabled={true}
										checked={el.isAuthRequired}
									/>
								</Td>
								<Td
									className='cellText'
									minWidth='120px'
									maxWidth='120px'
								>
									<CommonCheckbox
										id={el.id}
										textColor={'purple'}
										disabled={true}
										checked={el.md_required}
									/>
								</Td>
								<Td
									className='cellText'
									minWidth='140px'
									maxWidth='140px'
								>
									{getPrepayText(el)}
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			)}
		</main>
	);
};

export default InsuranceList;
