import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../../../Common/FormFields/Dropdown';
import { useSection } from '../../../../Patients/V3/CreatePatient/sections/useSection';
import {
	states_list,
	provincesList,
	countriesList,
	GetStateWiseTimezone,
	getProvinceWiseTimezone,
	isDisabledTimezone,
	isDisabledTimezoneCanada,
} from '../../../../../utils';
import { useCurrentUser } from '../../../../../hooks/useCurrentUser';
import {
	getDefaultSchedulingOptions,
	getReferralProgramDetail,
} from '../../../../../actions/referralprograms/referralprograms';
import {
	LoadAppointmentProvider,
	LoadPatientConsultations,
} from '../../../../../actions/scheduling';
import { getPatientOutreachRounds } from '../../../../../actions/patient/outreach';

import PhoneNumberField from '../../../../Common/FormFields/PhoneNumberField';

export function ServiceInfoForm({ sectionDataContainerName, isReschedule }) {
	const { allValues, errors, onChange, touched, values, setFieldValue } =
		useSection(sectionDataContainerName);
	const dispatch = useDispatch();

	const {
		allowed_countries,
		defaultSchedulingOptions,
		encounterList,
		patient_country,
		patient_state,
		patientdetail,
		providers,
		referralPrograms,
		schedulingConsultations,
		latestOutreachRound,
	} = useSelector((state) => ({
		allowed_countries:
			state.referralProgramsList?.loadedReferralProgramDetail
				?.allowedCountries,
		defaultSchedulingOptions:
			state.referralProgramsList?.defaultSchedulingOptions?.payload,
		encounterList: state.encounterlist.encounter?.data ?? [],
		patient_country:
			state.patientdetail?.patientdetail[0]?.address[0]?.country,
		patient_state: state.patientdetail?.patientdetail[0]?.address[0]?.state,
		patientdetail: state.patientdetail?.patientdetail[0],
		providers: state.Scheduling?.providers,
		referralPrograms: state.referralPrograms.referralPrograms.data ?? [],
		schedulingConsultations: state.Scheduling?.schedulingConsultations,
		latestOutreachRound: state.outreach.patientOutreachRounds?.data[0],
	}));

	const { isExternal } = useCurrentUser();

	const { associatedProgram, patientId } = allValues.patientInfo;
	const partner_uuid = referralPrograms.find(
		(item) => item.id === associatedProgram
	)?.uuid;

	useEffect(() => {
		if (partner_uuid) {
			dispatch(getDefaultSchedulingOptions({ partner_uuid }));
			dispatch(getReferralProgramDetail({ partner_uuid }));
		}
	}, [partner_uuid]);

	useEffect(() => {
		if (partner_uuid && associatedProgram) {
			dispatch(LoadPatientConsultations(patientId, associatedProgram));
			dispatch(LoadAppointmentProvider(patientId, associatedProgram));
		}
	}, [partner_uuid, associatedProgram]);

	useEffect(() => {
		if (patientdetail?.additional_conference_line) {
			setFieldValue(
				'additionalConferenceLine',
				patientdetail.additional_conference_line
			);
		}
		if (patient_state && patient_state !== values.state) {
			handleChangeState(values.state);
		}

		patientdetail &&
			dispatch(getPatientOutreachRounds(patientdetail.uuid, true));
	}, [patientdetail?.id]);

	useEffect(() => {
		const countryExists = allowed_countries?.includes(values.country);

		if (!countryExists && allowed_countries?.length > 0) {
			if (
				patient_country &&
				allowed_countries.includes(patient_country)
			) {
				handleChangeCountry(patient_country);
			} else {
				handleChangeCountry(allowed_countries[0]);
			}
		}
	}, [allowed_countries]);

	/**
	 * @param {string} specialty
	 */
	useEffect(() => {
		if (defaultSchedulingOptions?.specialties && patientId) {
			const specialtyIds = defaultSchedulingOptions.specialties?.map(
				(specialty) => specialty.id
			);

			if (specialtyIds?.includes(latestOutreachRound?.specialty_id)) {
				setFieldValue(
					'specialty',
					defaultSchedulingOptions.specialties
						.find((s) => s.id === latestOutreachRound?.specialty_id)
						?.name.toLowerCase()
				);
			} else if (
				specialtyIds?.includes(patientdetail?.default_specialty_id)
			) {
				setFieldValue(
					'specialty',
					defaultSchedulingOptions.specialties
						.find(
							(s) => s.id === patientdetail?.default_specialty_id
						)
						?.name.toLowerCase()
				);
			} else {
				setFieldValue(
					'specialty',
					defaultSchedulingOptions.specialties[0].name.toLowerCase()
				);
			}
		}
		if (defaultSchedulingOptions?.consultations && patientId) {
			const consultationIds = defaultSchedulingOptions.consultations?.map(
				(consultation) => consultation.id
			);

			if (
				consultationIds?.includes(latestOutreachRound?.consultation_id)
			) {
				setFieldValue(
					'consultationType',
					latestOutreachRound?.consultation_id
				);
			} else if (
				consultationIds?.includes(
					patientdetail?.default_consultation_id
				)
			) {
				setFieldValue(
					'consultationType',
					patientdetail?.default_consultation_id
				);
			} else {
				setFieldValue(
					'consultationType',
					defaultSchedulingOptions?.consultations[0]?.id
				);
			}
		}
	}, [defaultSchedulingOptions, latestOutreachRound]);

	const handleChangeCountry = (val) => {
		setFieldValue('country', val);
		setFieldValue('state', '');
		setFieldValue('timezone', '');
		setFieldValue('timeslot', '');
	};

	const handleChangeState = (val) => {
		setFieldValue('state', val);
		const newTimezone =
			values.country === 'US'
				? GetStateWiseTimezone(val)[0]?.area
				: getProvinceWiseTimezone(val)[0]?.area;
		setFieldValue('timezone', newTimezone);
	};

	const getCountriesOptions = () =>
		patientId
			? countriesList.filter((item) =>
					allowed_countries?.includes(item.value)
			  )
			: [];

	const getStatesOptions = () =>
		states_list.map((item) => ({ label: item.value, value: item.key }));

	const getProvincesOptions = () =>
		provincesList.map((item) => ({ label: item.value, value: item.key }));

	const consultationsOptions = () => {
		// Include Return of Results (RoR) consultation type if patient has
		// prior encounter with outreach completed or in progress status
		const includeRor = encounterList.some(
			(item) => item.visit_status === 'completed'
		);

		const consultationList = includeRor
			? schedulingConsultations
			: schedulingConsultations?.filter(
					(item) => item.type !== 'follow_up'
			  );
		return consultationList?.map((el) => ({
			key: el.id,
			label: el.name,
			value: el.id,
		}));
	};

	const specialtyOptions = () =>
		defaultSchedulingOptions?.specialties?.map((el) => ({
			key: el.id,
			label: el.display_name,
			// TODO: Find EMR compatible specialty name by ID.
			//
			// defaultSchedulingOptions only contains SBE specialty name,
			// while Visit Schema endpoint has EMR specialty names but
			// without the specialty ID
			//
			// The trick here takes advantage of the fact that lowecased SBE specialty
			// names equal to EMR specialties
			value: el.name.toLowerCase(),
		}));

	const providerOptions = () => {
		if (providers.length > 0 && patientId) {
			const updatedProvider = [
				{ id: null, full_name: 'All Providers' },
				...providers,
			];
			return updatedProvider?.map((el) => ({
				key: el.id,
				label: el.full_name,
				value: el.id,
			}));
		} else {
			return [{ key: null, label: 'All Providers', value: null }];
		}
	};

	return (
		<>
			<div className='column-layout-3'>
				<Dropdown
					required
					key={values.country}
					name='country'
					label='Country'
					options={getCountriesOptions()}
					value={values.country}
					error={touched.country && errors.country}
					onChange={handleChangeCountry}
					disabled={allowed_countries?.length === 1}
				/>
				<Dropdown
					required
					name='state'
					label='State/Province at Time of Visit'
					typeahead
					options={
						values.country === 'US'
							? getStatesOptions()
							: getProvincesOptions()
					}
					value={values.state}
					error={touched.state && errors.state}
					onChange={handleChangeState}
				/>
				<Dropdown
					required
					name='timezone'
					label='Time Zone'
					options={
						values.country === 'US'
							? GetStateWiseTimezone(values.state).map((tz) => ({
									label: tz.name,
									value: tz.area,
							  }))
							: getProvinceWiseTimezone(values.state).map(
									(tz) => ({
										label: tz.name,
										value: tz.area,
									})
							  )
					}
					value={values.timezone}
					error={touched.timezone && errors.timezone}
					onChange={onChange('timezone')}
					disabled={
						values.country === 'US'
							? isDisabledTimezone(values.state)
							: isDisabledTimezoneCanada(values.state)
					}
				/>
				<Dropdown
					required
					disabled={isReschedule}
					name='consultationType'
					label='Consultation Type'
					options={consultationsOptions()}
					value={values.consultationType}
					error={touched.consultationType && errors.consultationType}
					onChange={onChange('consultationType')}
				/>
				<Dropdown
					required
					name='specialty'
					label='Specialty'
					options={specialtyOptions()}
					value={values.specialty}
					error={touched.specialty && errors.specialty}
					onChange={onChange('specialty')}
				/>
				{!isExternal && (
					<Dropdown
						required
						name='provider'
						label='Provider'
						options={providerOptions()}
						value={values.provider}
						error={touched.provider && errors.provider}
						onChange={onChange('provider')}
					/>
				)}
				<PhoneNumberField
					label='Additional Conference Line'
					value={values.additionalConferenceLine}
					error={
						touched.additionalConferenceLine &&
						errors.additionalConferenceLine
					}
					onChange={onChange('additionalConferenceLine')}
				/>
			</div>
		</>
	);
}
