//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../../assets/exit-alert.svg';

//Lodash
import get from 'lodash/get';

class ICDTag extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.toggleCode = this.toggleCode.bind(this);
		this.getTagDisplay = this.getTagDisplay.bind(this);
	}
	//functions

	toggleCode() {
		const code = get(this, 'props.icdCode', {}) || {};
		this.props.toggleCodeSelection(false, get(code, 'uuid', null), code);
	}

	getTagDisplay() {
		const code = get(this, 'props.icdCode', {}) || {};
		const altDisplayKey = get(this, 'props.altDisplayKey', null);
		const tagDisplayFunc = this.props.tagDisplayFunc;

		if (tagDisplayFunc && typeof tagDisplayFunc === 'function') {
			return tagDisplayFunc(code) || '--';
		} else return get(code, altDisplayKey || 'code_name', '--');
	}

	render() {
		const code = get(this, 'props.icdCode', {}) || {};
		const altDisplayKey = get(this, 'props.altDisplayKey', null);
		const tagDisplay = this.getTagDisplay();
		const longTagVariant = get(this, 'props.longTagVariant', false);
		let tagClassName = 'chargeICDSelectedTag';
		if (longTagVariant) tagClassName += ' chargeICDSelectedTagLongVariant';
		return (
			<div
				className={tagClassName}
				style={{ whiteSpace: altDisplayKey ? 'pre' : 'unset' }}
			>
				{tagDisplay}
				{get(this, 'props.hasX', false) && (
					<ReactSVG
						src={xIcon}
						className='chargeICDSelectedTagX'
						onClick={this.toggleCode}
					/>
				)}
			</div>
		);
	}
}

export default ICDTag;
