//React & Redux
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

//Lodash
import _, { get, isEmpty } from 'lodash';
import moment from 'moment';

//Utils
import {
	UTCToCurrentView,
	EncounterTabs,
	RemoveUnderscoreAndCapitalizeInString,
	getFromEnumOrFormat,
} from '../../utils';
import { encounter_types } from '../../Utils/encounterUtils';
import { isGCRole } from '../../Utils/permissionUtils';
import { formulateVisitProvidersEnum } from '../../Utils/dashboardUtils';
import {
	isPartnerInitiatedPreTest,
	isPartnerInitiatedUnsolicitedResult,
} from '../Patients/V2/Encounters/Common/utilsfunctions';

//Actions & Services
import {
	getdashboardencounters,
	resetEncounterList,
	exportEncounterList,
	getActiveGCAs,
	getGCAAssignments,
	assignCCOrGCA,
	getdashboard,
} from '../../actions/dashboard';
import {
	getProviderSpecialties,
	getProvidersList,
} from '../../actions/providers';
import { getspecificencounterenum } from '../../actions/dashboard';
import { setFilter } from '../../actions/appData';

//UI Libraries
import { Loading, Checkbox } from 'gm-element-react';
import classNames from 'classnames';

//Components
import ProviderInfo from '../Common/ProviderInfo';
import MultiSelectCheckbox from './../Filter/MultiSelectCheckbox.js';
import SingleSelect from './../Filter/SingleSelect';
import ResetFilters from './../Filter/ResetFilters';
import { Table, Thead, Tbody, Tr, Th, Td } from '../Common/Table';
import TextWithTooltip from '../Common/TextWithTooltip';
import LinkPopover from '../Common/Popovers/LinkPopover';
import AssignModal from './AssignModal';
import CCGCAAssignBadges from './CCGCAAssignBadges';
import PioneerStatus from './PushToPioneer/PioneerStatus';

//Styles
import './mlo-results-cap-not-sent.css';

// Card name
const FILTER = 'results_cap_not_sent';

const followupCAPCompletedDateKeysFilter = [
	{ external: 'All CAP Status', internal: 'all_cap_status' },
	{ external: 'CAP Complete', internal: 'cap_completed' },
	{ external: 'CAP Incomplete', internal: 'cap_incomplete' },
	{ external: 'CAP Needs Amendment', internal: 'cap_needs_amendment' },
];

class MLOResultsCapNotSent extends Component {
	constructor(props) {
		super(props);

		const cardFilter = this.props.cardFilter || {};

		const assigned_gca_uuid = cardFilter.assigned_gca_uuid || null;
		const isGCA = this.props.currentRole === 'GCA';
		let assignedGcaUuid = null;
		if (isGCA && !assigned_gca_uuid) {
			assignedGcaUuid = [this.props.currentUserUUID || null];
		} else {
			assignedGcaUuid = assigned_gca_uuid || null;
		}

		this.defaultState = {
			offset: 0,
			order_type: 'asc',
			order_by: 'date_received_report',
			assigned_gca_uuid: assignedGcaUuid,
			loading: false,
			selectedEncounters: {},
			limit: get(this, ['props', 'limit'], 25),
			followup_cap_completed_date: 'all_cap_status',
			encounter_type: FILTER,
			visiting_providers: null,
			intialLoaded: false,
			filterList: 'gca',
			showAssignModal: false,
			selectedAssignee: [],
			pioneer_status: [],
		};
		this.state = { ...this.defaultState, ...cardFilter };

		this.reassignGCA = this.reassignGCA.bind(this);
		this.handleResultsPerPageChange =
			this.handleResultsPerPageChange.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleSort = this.handleSort.bind(this);
		this.onSelectAllClick = this.onSelectAllClick.bind(this);
		this.exportEncounterList = this.exportEncounterList.bind(this);
		this.formulateEncountersSingleSelectEnum =
			this.formulateEncountersSingleSelectEnum.bind(this);
		this.getFilter = this.getFilter.bind(this);
		this.handleChangeList = this.handleChangeList.bind(this);
		this.formulateGCAAssignmentEnum =
			this.formulateGCAAssignmentEnum.bind(this);
		this.handleGCAAssignmentChange =
			this.handleGCAAssignmentChange.bind(this);
		this.handlePioneerStatusChange =
			this.handlePioneerStatusChange.bind(this);
	}

	componentDidMount() {
		this.props.getActiveGCAList();
		this.props.callVisitProviderEnum({
			type: 'visit',
			field_name: 'visit_provider',
		});
		this.props.callLabEnum({
			type: 'lab_test_authorization',
			field_name: 'ordering_physician',
		});
		this.props.getDashboardCount();
		this.props.getAllProviderSpecialties();
		this.props.getAllProviders();
	}

	componentDidUpdate(prevProps) {
		if (
			get(prevProps, 'dashboardEncountersLoading', false) &&
			!get(this, 'props.dashboardEncountersLoading', false)
		) {
			if (!get(this, 'props.dashboardEncountersError', false))
				this.props.setCardFilter(this.getFilter());
		}

		if (
			get(prevProps, ['providersLoading'], false) &&
			!get(this, ['props', 'providersLoading'], false) &&
			!get(this, ['state', 'intialLoaded'], false)
		) {
			let visiting_providers = null;
			const visitingProviderFilter = get(
				this,
				['props', 'cardFilter', 'visiting_providers'],
				[]
			);
			if (this.userIsVseeProvider() && !visitingProviderFilter) {
				visiting_providers = [this.props.currentUserUUID || null];
			} else {
				visiting_providers = visitingProviderFilter || null;
			}
			this.setState({ visiting_providers, intialLoaded: true }, () => {
				this.getDashboardAndGCAAssignmentList();
			});
		}

		if (
			get(prevProps, 'assigningCCOrGCA', false) &&
			!get(this, 'props.assigningCCOrGCA', false)
		) {
			if (get(this, 'props.assignedCCOrGCAError', false)) {
				this.props.enqueueSnackbar('Error in assigning CC/GCA', {
					variant: 'error',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
			}
		}
	}

	componentWillUnmount() {
		this.props.resetList();
	}

	getFilter() {
		return {
			offset: get(this, 'state.offset', null),
			order_type: get(this, 'state.order_type', null),
			order_by: get(this, 'state.order_by', null),
			assigned_gca_uuid: get(this, 'state.assigned_gca_uuid', null),
			followup_cap_completed_date: get(
				this,
				'state.followup_cap_completed_date',
				null
			),
			encounter_type: get(this, 'state.encounter_type', null),
			visiting_providers: get(
				this,
				['state', 'visiting_providers'],
				null
			),
		};
	}

	getDashboardAndGCAAssignmentList = () => {
		const {
			offset,
			order_type,
			order_by,
			assigned_gca_uuid = null,
			followup_cap_completed_date,
			encounter_type,
			visiting_providers,
			pioneer_status,
		} = this.state;
		const { limit } = this.props;

		this.props.getDashboard({
			filter: encounter_type,
			offset,
			order_type,
			order_by,
			assigned_gca_uuid,
			limit,
			followup_cap_completed_date,
			visiting_providers,
			pioneer_status,
		});
		this.props.getGCAAssignmentList({ filter: encounter_type });
	};

	reassignGCA() {
		const { selectedEncounters } = this.state;
		const patients = new Set();
		const [assigneeType, assignedUuid] = this.state.selectedAssignee;
		const settingUnassigned = assignedUuid === 'unassigned';

		for (const encounterID in selectedEncounters) {
			patients.add(selectedEncounters[encounterID].user_uuid);
		}

		if (!isEmpty(selectedEncounters)) {
			this.setState({
				loading: true,
			});
			this.props
				.changeGCAAssignment({
					[`assigned_${assigneeType}_uuid`]: settingUnassigned
						? null
						: assignedUuid,
					patients: Array(...patients),
				})
				.then(() => {
					this.setState(
						{
							selectedEncounters: {},
							loading: false,
						},
						() => this.getDashboardAndGCAAssignmentList()
					);
				})
				.catch(() => {
					this.setState({
						loading: false,
					});
				});
		}

		this.setState({
			filterList: 'gca',
			showAssignModal: false,
			selectedAssignee: [],
		});
	}

	handleChangeList(filterList) {
		this.setState({ filterList });
	}

	handleResultsPerPageChange(limit) {
		this.setState({ offset: 0, limit }, () =>
			this.getDashboardAndGCAAssignmentList()
		);
	}

	handlePageChange(e, offset) {
		this.setState({ offset }, () =>
			this.getDashboardAndGCAAssignmentList()
		);
	}

	userIsVseeProvider = () => {
		const providers = get(this, ['props', 'providers'], []);
		const isGC = isGCRole(get(this, ['props', 'currentRole'], ''));
		const currentUserUUID = this.props.currentUserUUID || null;
		const filterProvider = providers.find((a) => a.uuid == currentUserUUID);
		if (isGC & filterProvider) {
			return true;
		}
		return false;
	};

	handleResetFilters = () => {
		this.setState(
			{
				...this.defaultState,
				assigned_gca_uuid:
					(this.props.currentRole || null) === 'GCA'
						? [this.props.currentUserUUID || null]
						: null,
				visiting_providers: this.userIsVseeProvider()
					? [this.props.currentUserUUID || null]
					: null,
			},
			() => this.getDashboardAndGCAAssignmentList()
		);
	};

	handleViewAllAssignments = () => {
		this.setState(
			{
				offset: 0,
				selectedEncounters: {},
				assigned_gca_uuid: [],
			},
			() => this.getDashboardAndGCAAssignmentList()
		);
	};

	handleSort(order_by, order_type) {
		this.setState(
			{
				order_by,
				order_type,
				selectedEncounters: {},
				offset: 0,
			},
			() => {
				this.getDashboardAndGCAAssignmentList();
			}
		);
	}

	handleProviderChange(values) {
		const providersLength = get(
			this,
			['props', 'providers', 'length'],
			null
		);
		let providersValues = [...values];

		if (values.length === providersLength) providersValues = [];
		this.setState(
			{
				visiting_providers: providersValues,
				offset: 0,
				selectedEncounters: {},
			},
			() => this.getDashboardAndGCAAssignmentList()
		);
	}

	handlePioneerStatusChange(values) {
		this.setState(
			{
				pioneer_status: values,
			},
			() => this.getDashboardAndGCAAssignmentList()
		);
	}

	getProviderInfo(type, ordering_physician, visit_provider) {
		let providerenum = [];
		let providerName = '';

		switch (type) {
			case 'lab_test_authorization':
				providerName = ordering_physician;
				providerenum = get(this, ['props', 'orderingphysician'], {});
				return { providerenum, providerName };
			case 'cc-intake':
			case 'group-session':
			case 'visit':
				providerName = visit_provider;
				providerenum = get(
					this,
					['props', 'visitProvider', 'data', 'visit_provider'],
					[]
				);
				return { providerenum, providerName };
			default:
				return { providerenum, providerName };
		}
	}

	filterAssignments(isDeleted) {
		const assignments = this.props['gcaAssignments'] || [];

		return assignments.filter((el) => {
			return (
				get(el, ['is_deleted'], null) === isDeleted &&
				get(el, ['uuid'], null)
			);
		});
	}

	handleGCAAssignmentChange(values) {
		const gcaAssignmentsLength = _.get(
			this,
			['props', 'gcaAssignments', 'length'],
			null
		);
		let gcaValues = [...values];

		if (values.length === gcaAssignmentsLength) gcaValues = [];
		this.setState(
			{
				assigned_gca_uuid: gcaValues,
				offset: 0,
			},
			() => this.getDashboardAndGCAAssignmentList()
		);
	}

	formulateGCAAssignmentEnum() {
		const gcaAssignments = _.get(this, ['props', 'gcaAssignments'], []);
		let gcaEnum = [];

		if (_.isEmpty(gcaAssignments)) return gcaEnum;

		const unassignedObj = _.find(
			gcaAssignments,
			(o) => o.name === 'Unassigned'
		);

		gcaEnum.push({
			display_name: `Unassigned (${unassignedObj.count})`,
			key: 'null',
		});
		gcaEnum.push({ groupingLabel: 'ACTIVE' });

		const activeAssignments = this.filterAssignments(false).map((el) => {
			return {
				display_name: `${_.get(el, ['name'], '--')} (${_.get(
					el,
					['count'],
					0
				)})`,
				key: _.get(el, ['uuid'], null),
			};
		});
		gcaEnum = gcaEnum.concat(activeAssignments);
		gcaEnum.push({ groupingLabel: 'INACTIVE' });

		const inactiveAssignments = this.filterAssignments(true).map((el) => {
			return {
				display_name: `${_.get(el, ['name'], '--')} (${_.get(
					el,
					['count'],
					0
				)})`,
				key: _.get(el, ['uuid'], null),
			};
		});
		gcaEnum = gcaEnum.concat(inactiveAssignments);

		return gcaEnum;
	}

	getEncounterSpecificTime(dos, timezone, type) {
		const tz = type == 'research-data' ? 'America/New_York' : timezone;
		return (
			(dos &&
				moment(dos).isValid() &&
				UTCToCurrentView(
					moment,
					moment(dos),
					tz,
					null,
					'MMM D, YYYY hh:mm A z'
				)) ||
			'--'
		);
	}

	onSelectAllClick(isChecked) {
		if (isChecked) isChecked = !this.isSomeChecked();
		const { selectedEncounters } = this.state;
		const {
			dashboardEncounters: { data },
		} = this.props;
		const dataMap = {};

		for (const enc of data) {
			delete selectedEncounters[enc.uuid];
			dataMap[enc.uuid] = { ...enc };
		}

		if (isChecked) {
			this.setState({
				selectedEncounters: { ...selectedEncounters, ...dataMap },
			});
		} else {
			this.setState({
				selectedEncounters: { ...selectedEncounters },
			});
		}
	}

	onSelectRowClick(isChecked, encounter) {
		const { selectedEncounters } = this.state;

		if (isChecked) {
			this.setState({
				selectedEncounters: {
					...selectedEncounters,
					[encounter.uuid]: encounter,
				},
			});
		} else {
			delete selectedEncounters[encounter.uuid];
			this.setState({
				selectedEncounters: { ...selectedEncounters },
			});
		}
	}

	isAllChecked() {
		const { selectedEncounters } = this.state;
		const {
			dashboardEncounters: { data, total },
		} = this.props;

		for (const { uuid } of data) {
			if (!selectedEncounters[uuid]) return false;
		}

		return total > 0;
	}

	isSomeChecked() {
		const { selectedEncounters } = this.state;
		const {
			dashboardEncounters: { data, limit },
		} = this.props;
		const filterData = _.filter(data, (obj) => {
			return selectedEncounters[obj.uuid];
		});

		return filterData.length > 0 && filterData.length !== limit;
	}

	redirectToEncounterDetail = (enc_uuid, patient_uuid, type, encounter) => {
		this.props.history.push({
			pathname:
				'/app/patientdetail/' +
				patient_uuid +
				'/0/' +
				EncounterTabs.DETAIL +
				'/' +
				enc_uuid +
				'?type=' +
				type,
			state: {
				breadcrumbs: [
					{
						location: FILTER,
						offset: get(this, ['state', 'offset'], 0),
						order_by: get(this, ['state', 'order_by']),
						order_type: get(this, ['state', 'order_type']),
						key: 'dashboard_card',
						assigned_gca_uuid: get(
							this,
							['state', 'assigned_gca_uuid'],
							null
						),
						followup_cap_completed_date: get(
							this,
							['state', 'followup_cap_completed_date'],
							null
						),
						encounter_type: get(
							this,
							['state', 'encounter_type'],
							FILTER
						),
					},
					{
						location: 'Encounter Detail',
						patientFullName:
							get(encounter, ['patient', 'first_name'], '') +
							' ' +
							get(encounter, ['patient', 'last_name'], ''),
					},
				],
			},
		});
	};

	formulateEncountersSingleSelectEnum() {
		const location_state = get(this, 'props.dashboard_data', {});
		const cdt_cap_count =
			get(location_state, 'cdt_caps_ready_to_send', 0) +
			get(
				location_state,
				'non_cdt_caps_ready_to_send_cc_intake_encounters',
				0
			) +
			get(
				location_state,
				'non_cdt_caps_ready_to_send_group_session_encounters',
				0
			) +
			get(
				location_state,
				'non_cdt_caps_ready_to_send_scp_encounters',
				0
			) +
			get(
				location_state,
				'non_cdt_caps_ready_to_send_visit_encounters',
				0
			) +
			get(
				location_state,
				'non_cdt_caps_ready_to_send_tro_encounters',
				0
			) +
			get(location_state, 'non_cdt_caps_result_only_encounters', 0);
		return [
			{
				external: `All Encounters (${cdt_cap_count})`,
				internal: 'results_cap_not_sent',
			},
		].concat(
			[
				{
					external: 'Visit',
					internal: 'non_cdt_caps_ready_to_send_visit_encounters',
				},
				{ external: 'Lab', internal: 'cdt_caps_ready_to_send' },
				{
					external: 'CC-Intake',
					internal: 'non_cdt_caps_ready_to_send_cc_intake_encounters',
				},
				{
					external: 'Group-session',
					internal:
						'non_cdt_caps_ready_to_send_group_session_encounters',
				},
				{
					external: 'SCP',
					internal: 'non_cdt_caps_ready_to_send_scp_encounters',
				},
				{
					external: 'TRO',
					internal: 'non_cdt_caps_ready_to_send_tro_encounters',
				},
				{
					external: 'TRO - Results Only',
					internal: 'non_cdt_caps_result_only_encounters',
				},
			].map((el) => {
				return {
					internal: el.internal,
					external: `${el.external} (${get(
						location_state,
						el.internal,
						0
					)})`,
				};
			})
		);
	}

	handlefollowUpCompleteDate(value) {
		this.setState(
			{
				followup_cap_completed_date: value,
				offset: 0,
				selectedEncounters: {},
			},
			() => {
				this.getDashboardAndGCAAssignmentList();
			}
		);
	}

	exportEncounterList() {
		const gcaUuid = get(this, 'state.assigned_gca_uuid', null);
		let assignedGCAUuid = null;
		if (gcaUuid && get(gcaUuid, 'length', 0) > 0) {
			if (gcaUuid === 'unassigned') {
				assignedGCAUuid = null;
			} else {
				if (Array.isArray(gcaUuid))
					assignedGCAUuid = gcaUuid
						.map((el) => (el === null ? 'null' : el))
						.join(',');
				assignedGCAUuid = gcaUuid;
			}
		}
		const payload = {
			filter: get(this, ['state', 'encounter_type'], FILTER),
			order_type: get(this, ['state', 'order_type'], null),
			order_by: get(this, ['state', 'order_by'], null),
			assigned_gca_uuid: assignedGCAUuid,
			gc_cap_filter: get(this, 'state.followup_cap_completed_date', null),
			visiting_providers: get(
				this,
				['state', 'visiting_providers'],
				null
			),
			pioneer_status: get(this, ['state', 'pioneer_status'], []),
		};

		this.setState({
			loading: true,
		});
		this.props
			.exportList(payload)
			.then(() => {
				this.props.enqueueSnackbar('Report is sent to your email id.', {
					variant: 'info',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				this.setState({
					loading: false,
				});
			})
			.catch(() => {
				this.props.enqueueSnackbar(
					'Something went wrong. Please try again later.',
					{
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
				this.setState({
					loading: false,
				});
			});
	}

	showExpectedLabResult = (obj) => {
		if (!isEmpty(get(obj, 'combined_order', ''))) {
			return get(
				obj,
				['combined_order', 'expected_lab_results_date', 'length'],
				0
			) > 0
				? moment(
						get(obj, [
							'combined_order',
							'expected_lab_results_date',
						])
				  ).format('MMM DD, YYYY')
				: '--';
		}
		return get(obj, ['expected_lab_results_date', 'length'], 0) > 0
			? moment(get(obj, ['expected_lab_results_date'])).format(
					'MMM DD, YYYY'
			  )
			: '--';
	};

	getValueFromEnum(value) {
		return RemoveUnderscoreAndCapitalizeInString(value);
	}

	getCAPCompletedDate(el) {
		const value = get(el, 'followup_cap_completed_date', null);
		return value && value != null
			? moment(value).format('MMM DD, YYYY')
			: '--';
	}

	getCAPCompletedDateLabel(el) {
		const isNotEmpty = (value) => !_.isNil(value) && !isEmpty(value);
		let result = '';
		const followupCAPCompletedDate = get(
			el,
			'followup_cap_completed_date',
			null
		);
		const reportReceiptDate = get(el, 'date_received_report', null);
		const resultCAPSentToPatientDate = get(el, 'cap_sent_to_patient', null);
		if (isNotEmpty(followupCAPCompletedDate)) {
			if (isNotEmpty(reportReceiptDate)) {
				const momentFollowUp = moment(followupCAPCompletedDate);
				const momentReportReceipt = moment(reportReceiptDate);
				if (isNotEmpty(resultCAPSentToPatientDate)) {
					const momentResultCAPSent = moment(
						resultCAPSentToPatientDate
					);

					if (
						momentResultCAPSent.isBefore(momentReportReceipt, 'day')
					) {
						if (
							momentReportReceipt.isAfter(momentFollowUp, 'day')
						) {
							result = (
								<span className='cap-needs-amendment'>
									Needs Amendment
								</span>
							);
						} else {
							result = (
								<span className='cap-was-amended'>
									Was Amended
								</span>
							);
						}
					}
				} else {
					if (momentReportReceipt.isAfter(momentFollowUp, 'day')) {
						result = (
							<span className='cap-needs-amendment'>
								Needs Amendment
							</span>
						);
					}
				}
			}
		} else {
			result = <span className='cap-incomplete'>Incomplete</span>;
		}
		return result;
	}

	handleEncounterTypeChange(value) {
		this.setState(
			{
				encounter_type: value,
				offset: 0,
				selectedEncounters: {},
			},
			() => {
				this.getDashboardAndGCAAssignmentList();
			}
		);
	}

	getEncounterNameFromEnum = (encounter) => {
		if (isPartnerInitiatedPreTest(encounter)) {
			return 'TRO';
		} else if (isPartnerInitiatedUnsolicitedResult(encounter)) {
			return 'TRO - Results Only';
		} else if (!isEmpty(get(encounter, ['consultation_type'], ''))) {
			return get(encounter, ['consultation_type'], '');
		}
		const str = get(encounter, ['type'], '');
		return get(encounter_types, str);
	};

	getPioneerStatus = (encounter) => {
		switch (encounter.pioneer_status) {
			case 'NA':
				return 'N/A';
			case 'Sent':
				return 'Sent';
			case 'Fail to Send':
				return 'Failed to Send';
			case 'Pending':
				return 'Pending';
			default:
				return 'N/A';
		}
	};

	redirectToEncounterDetailURL = (enc_uuid, patient_uuid, type) => {
		return (
			'/app/patientdetail/' +
			patient_uuid +
			'/0/' +
			EncounterTabs.DETAIL +
			'/' +
			enc_uuid +
			'?type=' +
			type
		);
	};

	render() {
		const { data, end, has_next, has_prev, limit, offset, start, total } =
			get(this, ['props', 'dashboardEncounters'], {});
		const pagination = {
			end,
			has_next,
			has_prev,
			limit,
			offset,
			start,
			total,
		};
		const sortBy = {
			key: get(this, ['state', 'order_by'], ''),
			value: get(this, ['state', 'order_type'], ''),
		};
		const { selectedEncounters } = this.state;
		const { currentUserUUID } = this.props;
		const providerSpecialties =
			get(this, 'props.providerSpecialties', null) || [];

		const assignButtonDisabled = isEmpty(selectedEncounters);
		const unassigned = { key: 'unassigned', display_name: 'Unassigned' };
		return (
			<div className='mlo-results-cap-not-sent-wrapper'>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						minWidth: 'inherit',
					}}
				>
					<div style={{ display: 'flex' }}>
						<div
							className={classNames('assign-cc-gca-button', {
								disabled: assignButtonDisabled,
							})}
							onClick={() =>
								this.setState({ showAssignModal: true })
							}
						>
							Assign Patient
						</div>
						<div
							className={
								total > 0 ? 'export_enabled' : 'export_disabled'
							}
							variant='contained'
							color='primary'
							onClick={total ? this.exportEncounterList : null}
						>
							{`Export (${total || 0})`}
						</div>
					</div>
					<div style={{ display: 'flex' }}>
						<ResetFilters
							handleResetFilters={this.handleResetFilters}
						/>

						<MultiSelectCheckbox
							data={this.formulateGCAAssignmentEnum()}
							selected={
								_.get(
									this,
									['state', 'assigned_gca_uuid'],
									[]
								) || []
							}
							title={'Assignments'}
							submit={(values) =>
								this.handleGCAAssignmentChange(values)
							}
							majorPillTitle={'Assignments'}
							defaultValues={[]}
							searchBar={true}
							previewTab={true}
							alwaysNumber={false}
							zeroToAllPillTitle={true}
						/>

						<MultiSelectCheckbox
							data={formulateVisitProvidersEnum(
								get(this, ['props', 'providers'])
							)}
							selected={
								get(
									this,
									['state', 'visiting_providers'],
									[]
								) || []
							}
							title={'Provider'}
							submit={(values) =>
								this.handleProviderChange(values)
							}
							majorPillTitle={'Provider'}
							defaultValues={[]}
							searchBar={true}
							previewTab={true}
							alwaysNumber={false}
							zeroToAllPillTitle={true}
						/>

						<SingleSelect
							data={followupCAPCompletedDateKeysFilter}
							currentValue={get(
								this,
								['state', 'followup_cap_completed_date'],
								'all_cap_status'
							)}
							title={'CAP Status'}
							setStateFunc={(value) =>
								this.handlefollowUpCompleteDate(value)
							}
							defaultValue={'all_cap_status'}
							convertStrsToNums={true}
						/>

						<MultiSelectCheckbox
							data={[
								{ display_name: 'Pending', key: 'Pending' },
								{ display_name: 'Sent', key: 'Sent' },
								{
									display_name: 'Failed to Send',
									key: 'Fail to Send',
								},
								{ display_name: 'N/A', key: 'NA' },
							]}
							selected={
								get(this, ['state', 'pioneer_status'], []) || []
							}
							title={'Pioneer Status'}
							submit={(values) =>
								this.handlePioneerStatusChange(values)
							}
							majorPillTitle={'Pioneer Status'}
							defaultValues={[]}
							searchBar={false}
							previewTab={false}
							alwaysNumber={false}
							zeroToAllPillTitle={true}
						/>

						<SingleSelect
							data={this.formulateEncountersSingleSelectEnum()}
							currentValue={get(
								this,
								['state', 'encounter_type'],
								'results_cap_not_sent'
							)}
							title={'Encounters'}
							setStateFunc={(value) =>
								this.handleEncounterTypeChange(value)
							}
							defaultValue={'results_cap_not_sent'}
							convertStrsToNums={true}
						/>
					</div>
				</div>
				{this.state.showAssignModal && (
					<AssignModal
						{...{
							closeModal: () => {
								this.setState({
									filterList: 'gca',
									showAssignModal: false,
									selectedAssignee: [],
								});
							},
							gcaList: [...this.props.gcaList, unassigned],
							selectListGCA: () =>
								this.setState({ filterList: 'gca' }),
							selectedAssignee: this.state.selectedAssignee,
							selectAssignee: (e) =>
								this.setState({
									selectedAssignee: [
										this.state.filterList,
										e,
									],
								}),
							updateGCAAssignment: this.reassignGCA,
						}}
					/>
				)}
				<div>
					{get(
						this,
						['props', 'dashboardEncountersLoading'],
						false
					) ||
					!_.isArray(data) ||
					get(this, ['state', 'loading'], false) ? (
						<Loading loading={true} className='table-loading' />
					) : (
						<Table
							loading={
								get(
									this,
									['props', 'dashboardEncountersLoading'],
									false
								) && _.isArray(data)
							}
							backToTop
							resultsPerPage={[25, 50, 100]}
							handleResultsPerPageChange={
								this.handleResultsPerPageChange
							}
							pagination={pagination}
							handlePageChange={this.handlePageChange}
							hasData={!isEmpty(data)}
							handleSort={this.handleSort}
							sortBy={sortBy}
							noResultsFound={
								<Fragment>
									<div>No Results Found</div>
									<p>
										Try adjusting your search or filter
										options to find
										<br />
										what you’re looking for.
									</p>
									<span
										onClick={() =>
											this.handleViewAllAssignments()
										}
									>
										View All Assignments
									</span>
									<span className='separator' />
									<span
										onClick={() =>
											this.handleResetFilters()
										}
									>
										Reset Filters
									</span>
								</Fragment>
							}
						>
							<Thead>
								<Tr>
									<Th>
										<Checkbox
											checked={this.isAllChecked()}
											indeterminate={this.isSomeChecked()}
											onChange={this.onSelectAllClick}
											inputProps={{
												'aria-label':
													'Select all encounters',
											}}
										/>
									</Th>
									<Th
										sortable={{
											first_name: {
												display_name: 'first name',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
											email: {
												display_name: 'email',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										Patient
									</Th>
									<Th
										sortable={{
											referral_program: {
												display_name:
													'referral program',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										Referral Program
									</Th>
									<Th
										sortable={{
											consultation_type: {
												display_name:
													'consultation type',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
											date_of_service: {
												display_name:
													'date & time of service',
												sortBy: {
													asc: 'Oldest First',
													desc: 'Newest First',
												},
											},
										}}
									>
										Encounter
									</Th>
									<Th
										sortable={{
											provider_indicated_specialty: {
												display_name:
													'provider indicated specialty',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										Provider Indicated Specialty
									</Th>
									<Th
										sortable={{
											visit_provider: {
												display_name: 'provider',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										Provider
									</Th>
									<Th
										sortable={{
											date_received_report: {
												display_name: 'Report Received',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										Report Received
									</Th>
									<Th>Pioneer Status</Th>
									<Th
										sortable={{
											followup_cap_completed_date: {
												display_name:
													'CAP Completed Date',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										CAP Completed
									</Th>
									<Th>Assignee(s)</Th>
								</Tr>
							</Thead>
							<Tbody>
								{data.map((encounter) => {
									const {
										uuid,
										user_uuid,
										patient: {
											first_name,
											last_name,
											email,
											referral_program,
											timezone,
											high_priority,
											has_guardian,
											assigned_gca_uuid,
										},
										type,
										ordering_physician,
										visit_provider,
										date_of_service,
										provider_indicated_specialty,
									} = encounter;
									const nameMaxWidth =
										high_priority && has_guardian
											? '216px'
											: high_priority || has_guardian
											? '240px'
											: '264px';
									const fullName =
										first_name || last_name
											? first_name + ' ' + last_name
											: '--';
									return (
										<Tr key={uuid}>
											<Td minWidth='56px' maxWidth='56px'>
												<Checkbox
													checked={
														!isEmpty(
															selectedEncounters[
																encounter.uuid
															]
														)
													}
													onChange={(isChecked) =>
														this.onSelectRowClick(
															isChecked,
															encounter
														)
													}
													inputProps={{
														'aria-label': uuid,
													}}
												/>
											</Td>
											<Td maxWidth='296px'>
												<p style={{ display: 'flex' }}>
													<span>
														<LinkPopover
															overflow={true}
															onClick={() =>
																this.redirectToEncounterDetail(
																	uuid,
																	user_uuid,
																	type,
																	encounter
																)
															}
															className='capitalize hover'
															maxWidth={
																nameMaxWidth
															}
															linkTo={this.redirectToEncounterDetailURL(
																uuid,
																user_uuid,
																type,
																encounter
															)}
															options={{
																text: true,
																link: true,
																linkOptions: {
																	text: fullName,
																	style: {
																		textTransform:
																			'capitalize',
																	},
																},
															}}
														>
															{fullName}
														</LinkPopover>
													</span>
													{high_priority && (
														<span className='high-priority' />
													)}
													{has_guardian && (
														<span className='has-guardian' />
													)}
												</p>
												<TextWithTooltip
													className='sub'
													maxWidth='200px'
												>
													{email || '--'}
												</TextWithTooltip>
											</Td>
											<Td maxWidth='190px'>
												{referral_program}
											</Td>
											<Td
												minWidth='160px'
												maxWidth='244px'
											>
												<p>
													<TextWithTooltip
														className='capitalize'
														maxWidth='192px'
													>
														{this.getEncounterNameFromEnum(
															encounter
														)}
													</TextWithTooltip>
												</p>
												<p>
													<TextWithTooltip
														className='sub'
														maxWidth='192px'
													>
														{this.getEncounterSpecificTime(
															date_of_service,
															timezone,
															type
														)}
													</TextWithTooltip>
												</p>
											</Td>
											<Td maxWidth='224px'>
												{getFromEnumOrFormat(
													providerSpecialties,
													provider_indicated_specialty,
													'name',
													'display_name'
												)}
											</Td>
											<Td maxWidth='244px'>
												<ProviderInfo
													fullName={true}
													{...this.getProviderInfo(
														type,
														ordering_physician,
														visit_provider
													)}
												/>
											</Td>
											<Td maxWidth='144px'>
												{get(
													encounter,
													'date_received_report.length',
													0
												) > 0
													? moment(
															get(
																encounter,
																'date_received_report'
															)
													  ).format('MMM DD, YYYY')
													: '-'}
											</Td>

											<Td
												className='pioneer'
												maxWidth='144px'
											>
												<PioneerStatus
													key={encounter.id}
													encounter={encounter}
													getPioneerStatus={this.getPioneerStatus(
														encounter
													)}
													onSuccessfulRetry={
														this
															.getDashboardAndGCAAssignmentList
													}
												/>
											</Td>

											<Td
												maxWidth='156px'
												minWidth='none'
											>
												<p
													className='capitalize'
													style={{
														display: 'flex',
														flexDirection: 'column',
													}}
												>
													{this.getCAPCompletedDate(
														encounter
													)}{' '}
													{this.getCAPCompletedDateLabel(
														encounter
													) || <span>--</span>}
												</p>
											</Td>
											<Td minWidth='none'>
												<CCGCAAssignBadges
													{...{
														assigned_gca_uuid,
														currentUserUuid:
															currentUserUUID,
														gcaList:
															this.props
																.gcaList || [],
													}}
												/>
											</Td>
										</Tr>
									);
								})}
							</Tbody>
						</Table>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		dashboardEncounters: state.dashboardencounters.dashboardencounters,
		dashboardEncountersLoading:
			state.dashboardencounters.dashboardencountersLoading,
		dashboardEncountersError:
			state.dashboardencounters.dashboardencountersError,
		gcaList: state.gcaList.gcaList,
		gcaAssignments: state.gcaList.gcaAssignments,
		providers: get(state, ['providers', 'data'], []),
		providersLoading: get(state, ['providers', 'loading'], false),
		assigningCCOrGCA: state.assignCcGca.assigningCCOrGCA,
		assignedCCOrGCAError: state.assignCcGca.assignedCCOrGCAError,
		currentRole: get(state, 'me.activerole.name', null),
		currentUserUUID: get(state, 'me.user.uuid', null),
		visitProvider: state.dashboardencounters.visitproviderenum,
		orderingphysician: get(
			state,
			'dashboardencounters.orderingphysician.data.ordering_physician',
			{}
		),
		limit: state.uiConfig.table.resultsPerPage,
		labEnum: state.dashboardencounters.labenum,
		dashboard_data: state.dashboard.dashboard_data,
		cardFilter: get(state, 'appData.resultsCAPNotSentCard', null) || {},
		gettingProviderSpecialties: get(
			state,
			'providerEnums.gettingProviderSpecialties',
			false
		),
		providerSpecialties:
			get(state, 'providerEnums.providerSpecialties', null) || [],
		providerSpecialtiesError: get(
			state,
			'providerEnums.providerSpecialtiesError',
			null
		),
	};
};

const mapDispatchToProps = (dispatch) => ({
	getDashboard: (filter) => dispatch(getdashboardencounters(filter)),
	callVisitProviderEnum: (data) => dispatch(getspecificencounterenum(data)),
	callLabEnum: (data) => dispatch(getspecificencounterenum(data)),
	exportList: (data) => dispatch(exportEncounterList(data)),
	resetList: () => dispatch(resetEncounterList()),
	getActiveGCAList: () => dispatch(getActiveGCAs()),
	getGCAAssignmentList: (data) => dispatch(getGCAAssignments(data)),
	changeGCAAssignment: (data) => dispatch(assignCCOrGCA(data)),
	getDashboardCount: () => dispatch(getdashboard()),
	setCardFilter: (data) => dispatch(setFilter('resultsCAPNotSentCard', data)),
	getAllProviderSpecialties: () => dispatch(getProviderSpecialties()),
	getAllProviders: () =>
		dispatch(
			getProvidersList({ pioneer_only: true, order_by: 'first_name' })
		),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MLOResultsCapNotSent);
