import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Dropdown from '../../../../Common/FormFields/Dropdown';
import InputField from '../../../../Common/FormFields/InputField';
import {
	countriesList,
	states_list,
	provincesList,
	addressTypeConst,
} from '../../../../../utils';
import { useSection } from '../../../../Patients/V3/CreatePatient/sections/useSection';
import CheckboxField from '../../../../Common/FormFields/CheckboxField';
import './AddressForm.scss';

export function AddressForm({
	hideCheckBox = false,
	sectionDataContainerName,
	addressType,
}) {
	const { errors, onChange, setFieldValue, touched, values, allValues } =
		useSection(sectionDataContainerName);

	const patientId = allValues.patientInfo?.patientId;

	const [sameBillingShipping, setSameBillingShipping] = useState(false);

	const addressPrefix =
		addressType === addressTypeConst.home
			? 'homeAddress'
			: 'shippingAddress';

	const { patientAddress, allowedCountries } = useSelector((store) => ({
		patientAddress: store.patientdetail?.patientdetail?.[0]?.address?.find(
			(item) => item.type.includes(addressType)
		),
		allowedCountries:
			store.referralProgramsList?.loadedReferralProgramDetail
				?.allowedCountries,
	}));

	useEffect(() => {
		if (patientAddress && patientId) {
			setFieldValue(
				`${addressPrefix}.addressLine1`,
				patientAddress.address_line1
			);
			setFieldValue(
				`${addressPrefix}.addressLine2`,
				patientAddress.address_line2
			);
			setFieldValue(`${addressPrefix}.city`, patientAddress.city);
			setFieldValue(`${addressPrefix}.state`, patientAddress.state);
			setFieldValue(`${addressPrefix}.zip`, patientAddress.zip);
			setFieldValue(`${addressPrefix}.country`, patientAddress.country);
		}
	}, [patientAddress]);

	useEffect(() => {
		const homeAddress = values.homeAddress;
		const shippingAddress = values.shippingAddress;

		if (
			!sameBillingShipping &&
			addressType === addressTypeConst.shipping &&
			JSON.stringify(homeAddress) === JSON.stringify(shippingAddress)
		) {
			setFieldValue(`${addressPrefix}.addressLine1`, '');
			setFieldValue(`${addressPrefix}.addressLine2`, '');
			setFieldValue(`${addressPrefix}.city`, '');
			setFieldValue(`${addressPrefix}.state`, '');
			setFieldValue(`${addressPrefix}.zip`, '');
		} else if (
			sameBillingShipping &&
			addressType === addressTypeConst.shipping
		) {
			setFieldValue(
				`${addressPrefix}.addressLine1`,
				homeAddress.addressLine1
			);
			setFieldValue(
				`${addressPrefix}.addressLine2`,
				homeAddress.addressLine2 ?? ''
			);
			setFieldValue(`${addressPrefix}.city`, homeAddress.city);
			setFieldValue(`${addressPrefix}.state`, homeAddress.state);
			setFieldValue(`${addressPrefix}.zip`, homeAddress.zip);
			setFieldValue(`${addressPrefix}.country`, homeAddress.country);
		}
	}, [sameBillingShipping, values.homeAddress]);

	useEffect(() => {
		if (
			addressType === addressTypeConst.home &&
			!values[addressPrefix].country &&
			allowedCountries?.length > 0
		) {
			setFieldValue(`${addressPrefix}.country`, allowedCountries[0]);
		}
	}, [allowedCountries]);

	const getCountriesOptions = () =>
		countriesList.filter((item) => allowedCountries?.includes(item.value));

	const getStatesOptions = () =>
		states_list.map((item) => ({ label: item.value, value: item.key }));

	const getProvincesOptions = () =>
		provincesList.map((item) => ({ label: item.value, value: item.key }));

	const countryISNonUS = () => {
		return allowedCountries && allowedCountries.find((a) => a != 'US');
	};

	return (
		<div className='address-form column-layout-3'>
			{addressType === addressTypeConst.shipping && !hideCheckBox && (
				<CheckboxField
					className='shipping-address'
					label='Same as billing address'
					value={sameBillingShipping}
					onChange={(value) => setSameBillingShipping(value)}
				/>
			)}
			<InputField
				required
				className='address-line-1'
				name={`${addressPrefix}.addressLine1`}
				label='Address Line 1'
				error={
					touched[addressPrefix]?.addressLine1 &&
					errors[addressPrefix]?.addressLine1
				}
				value={values[addressPrefix].addressLine1}
				onChange={onChange(`${addressPrefix}.addressLine1`)}
				disabled={sameBillingShipping}
			/>
			<InputField
				name={`${addressPrefix}.addressLine2`}
				label='Address Line 2'
				error={
					touched[addressPrefix]?.addressLine2 &&
					errors[addressPrefix]?.addressLine2
				}
				value={values[addressPrefix].addressLine2}
				onChange={onChange(`${addressPrefix}.addressLine2`)}
				disabled={sameBillingShipping}
			/>

			{addressType === addressTypeConst.home && countryISNonUS() && (
				<Dropdown
					required
					typeahead
					name={`${addressPrefix}.country`}
					label='Country'
					placeholder='US'
					options={getCountriesOptions()}
					error={
						touched[addressPrefix]?.country &&
						errors[addressPrefix]?.country
					}
					value={values[addressPrefix].country}
					onChange={onChange(`${addressPrefix}.country`)}
				/>
			)}

			<InputField
				required
				name={`${addressPrefix}.city`}
				label='City'
				error={
					touched[addressPrefix]?.city && errors[addressPrefix]?.city
				}
				value={values[addressPrefix].city}
				onChange={onChange(`${addressPrefix}.city`)}
				disabled={sameBillingShipping}
			/>
			<Dropdown
				required
				typeahead
				name={`${addressPrefix}.state`}
				label={
					values[addressPrefix].country === 'CA'
						? 'Province'
						: 'State'
				}
				placeholder='CA'
				options={
					values[addressPrefix].country === 'CA'
						? getProvincesOptions()
						: getStatesOptions()
				}
				error={
					touched[addressPrefix]?.state &&
					errors[addressPrefix]?.state
				}
				value={values[addressPrefix].state}
				onChange={onChange(`${addressPrefix}.state`)}
				disabled={sameBillingShipping}
			/>
			<InputField
				required
				name={`${addressPrefix}.zip`}
				label={
					values[addressPrefix].country === 'CA'
						? 'Postal Code'
						: 'Zip'
				}
				placeholder='12345'
				error={
					touched[addressPrefix]?.zip && errors[addressPrefix]?.zip
				}
				value={values[addressPrefix].zip}
				onChange={onChange(`${addressPrefix}.zip`)}
				disabled={sameBillingShipping}
			/>
		</div>
	);
}
