//React & Redux
import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

//Lodash
import get from 'lodash/get';
import _ from 'lodash';

//Actions & Serviecs
import {
	getaffiliation,
	getPartnerOrganization,
	resetaffiliation,
} from '../../actions/providers';

//UI Libraries
import { DateRangePicker } from 'react-date-range';
import { Checkbox, Select as SelectReact } from 'gm-element-react';

//Hooks
import { usePrevious } from '../../hooks/usePrevious';

//Styles
import classnames from 'classnames';

const defaultSelection = {
	startDate: new Date(),
	endDate: new Date(),
	key: 'selection',
};

const CustomizedSelection = (props) => {
	const {
		affiliation_id,
		partner_ids,
		getPartnerOrganization,
		affiliation,
		affiliations,
		affiliations_list,
		start_date,
		end_date,
		report_type,
		setDateRanges,
		handleSelectAllRefPrograms,
		selected_affiliation,
		handleSelectAllRefPartners,
		selectedRefPartner,
		resetaffiliation,
		partnerOrganization,
		setNoteInclusion,
		setCSVersion,
		showAffiliationSelector,
		showProviderReportSelector,
		cs_version,
		notes,
	} = props;

	const [allRefSelected, setAllRefSelected] = useState(false);
	const [allAffSelected, setAllAffSelected] = useState(false);
	const [affiliationIds, setAffiliationIds] = useState(affiliation_id);
	const [partnerIds, setPartnerIds] = useState(partner_ids);
	const [selection, setSelection] = useState(defaultSelection);

	const previousAffiliations = usePrevious(affiliations);

	useEffect(() => {
		getPartnerOrganization();
		if (_.get(partner_ids, ['length'], 0) > 0) {
			const affiliationsArr = _.without(partner_ids, 'all');
			affiliation(affiliationsArr);
		}
	}, []);
	useEffect(() => {
		if (
			!_.isEqual(
				get(affiliations, 'data', []),
				get(previousAffiliations, 'data', [])
			)
		) {
			affiliations_list(get(affiliations, 'data', []));
		}
	}, [get(affiliations, 'data', [])]);

	useEffect(() => {
		if (
			!_.isNil(start_date) &&
			!_.isNil(end_date) &&
			(get(selection, 'startDate') != start_date ||
				end_date != get(selection, 'endDate'))
		) {
			setSelection({
				startDate: start_date,
				endDate: end_date,
				key: 'selection',
			});
		}
	}, [start_date, end_date, selection]);

	const handleSelect = (ranges) => {
		if (
			report_type == 'scheduling_reports' &&
			(get(ranges, 'selection.startDate', new Date()) < new Date() ||
				get(ranges, 'selection.endDate', new Date()) < new Date())
		)
			return;

		setSelection({
			startDate: get(ranges, 'selection.startDate', new Date()),
			endDate: get(ranges, 'selection.endDate', new Date()),
			key: 'selection',
		});
		setDateRanges({
			start_date: get(ranges, 'selection.startDate', new Date()),
			end_date: get(ranges, 'selection.endDate', new Date()),
		});
	};

	const handleAffiliationChange = (affiliation_ids) => {
		let tempAllRefSelected = allAffSelected;
		const selectOptions = getSelectOptions();
		let affiliationsArr = affiliation_ids || [];
		const affiliationIdsOLd = affiliationIds;
		const diff = _.difference(affiliationIdsOLd, affiliation_ids);
		const allSelected = affiliation_ids.includes('all');
		const clearAll = diff && diff.length == 1 && diff.includes('all');
		if (selectOptions.length + 1 != affiliation_ids.length) {
			if (clearAll) {
				affiliationsArr = [];
				tempAllRefSelected = false;
			} else if (allSelected && tempAllRefSelected == false) {
				affiliationsArr = (selectOptions || []).map(
					(data) => data && data.value
				);
				affiliationsArr = _.concat(affiliationsArr, ['all']);
				tempAllRefSelected = true;
			} else {
				affiliationsArr = affiliation_ids || [];
				affiliationsArr = _.remove(affiliationsArr, (o) => o != 'all');
				tempAllRefSelected = false;
			}
		}
		setAffiliationIds(affiliationsArr);
		setAllAffSelected(tempAllRefSelected);

		if (
			affiliationsArr.includes('all') &&
			report_type == 'completed_encounters_report'
		) {
			handleSelectAllRefPrograms(true);
		} else {
			handleSelectAllRefPrograms(false);
		}

		affiliationsArr = _.without(affiliationsArr, 'all');
		selected_affiliation(affiliationsArr);
	};

	const handleRefPartnerChange = (partner_ids) => {
		let tempAllRefSelected = allRefSelected;
		const PartnerOptions = getReferralPartnerOptions();
		let partnersArr = partner_ids || [];
		const partnerIdsOLD = partnerIds;
		const diff = _.difference(partnerIdsOLD, partner_ids);
		const allSelected = partner_ids.includes('all');
		const clearAll = diff && diff.length == 1 && diff.includes('all');
		if (PartnerOptions.length + 1 != partner_ids.length) {
			if (clearAll) {
				partnersArr = [];
				tempAllRefSelected = false;
			} else if (allSelected && tempAllRefSelected == false) {
				partnersArr = (PartnerOptions || []).map(
					(data) => data && data.value
				);
				partnersArr = _.concat(partnersArr, ['all']);
				tempAllRefSelected = true;
			} else {
				partnersArr = partner_ids || [];
				partnersArr = _.remove(partnersArr, (o) => o != 'all');
				tempAllRefSelected = false;
			}
		}
		setPartnerIds(partnersArr);
		setAffiliationIds([]);
		setAllRefSelected(tempAllRefSelected);

		if (
			partnersArr.includes('all') &&
			report_type == 'completed_encounters_report'
		) {
			partnersArr = ['all'];
			handleSelectAllRefPartners(true);
		} else {
			partnersArr = _.without(partnersArr, 'all');
			handleSelectAllRefPartners(false);
		}

		selected_affiliation([]);
		selectedRefPartner(partnersArr);
		if (_.get(partnersArr, 'length', 0) > 0) {
			partnersArr = _.without(partnersArr, 'all');
			affiliation(partnersArr);
		} else {
			resetaffiliation();
		}
	};

	const getSelectOptions = () => {
		const options = [];
		if (_.get(partnerIds, ['length'], 0) > 0) {
			const affiliationList = _.orderBy(
				get(affiliations, 'data', []),
				(o) => {
					return _.get(o, 'display_name');
				},
				['asc']
			);
			affiliationList.map((e) => {
				options.push({ value: e.id, label: e.display_name, id: e.id });
			});
		}
		return options;
	};

	const getReferralPartnerOptions = () => {
		const options = [];
		const referralPartnerList = _.orderBy(
			partnerOrganization || [],
			(o) => {
				return _.get(o, 'display_name');
			},
			['asc']
		);
		referralPartnerList.map((e) => {
			options.push({ value: e.key, label: e.display_name, id: e.key });
		});
		return options;
	};

	const selectNotes = (option) => {
		setNoteInclusion(option);
	};

	const selectCSVersion = (option) => {
		setCSVersion(option);
	};

	const affiliationList = getSelectOptions();
	const PartnerOptions = getReferralPartnerOptions();
	const tempPartnerIds = partnerIds || [];
	const tempPartenerIds =
		allRefSelected == true
			? [...tempPartnerIds, 'all']
			: [...tempPartnerIds];
	const allSelectedRefPartner = tempPartenerIds.includes('all');
	const tempAffiliationIds = affiliationIds || [];
	const allSelectedRefProgram = tempAffiliationIds.includes('all');
	return (
		<div className='report_type_selection'>
			<div className='select_report_label'>
				{' '}
				Set the options for your selected report
			</div>
			<div className='report_type_selector_wrapper'>
				{showAffiliationSelector && (
					<div style={{ width: '30%', marginBottom: '26px' }}>
						<div className='select_date_range_report'>
							{' '}
							1. Referral Partner{' '}
						</div>
						<SelectReact
							multiple={true}
							value={tempPartenerIds}
							onChange={(evt) => handleRefPartnerChange(evt)}
							isClearable={true}
							className={classnames(
								'provider_selection_affiliation_reports show-all-pills',
								allSelectedRefPartner ? 'all-selected' : ''
							)}
							placeholder='Select...'
						>
							{PartnerOptions && PartnerOptions.length > 0 && (
								<Fragment>
									<SelectReact.Option
										className={classnames(
											allSelectedRefPartner
												? 'all-selected-option'
												: ''
										)}
										label={'Select All'}
										value={'all'}
									/>
									{PartnerOptions.map((el) => {
										return (
											<SelectReact.Option
												key={el.id}
												label={el.label}
												value={el.value}
											/>
										);
									})}
								</Fragment>
							)}
						</SelectReact>
					</div>
				)}
				{showAffiliationSelector && (
					<div style={{ width: '30%' }}>
						<div className='select_date_range_report'>
							{' '}
							2. Referral Program{' '}
						</div>
						<SelectReact
							multiple={true}
							value={affiliationIds}
							onChange={(evt) => handleAffiliationChange(evt)}
							isClearable={true}
							className={classnames(
								'provider_selection_affiliation_reports show-all-pills',
								allSelectedRefProgram ? 'all-selected' : ''
							)}
							placeholder='Select...'
						>
							{affiliationList && affiliationList.length > 0 && (
								<Fragment>
									<SelectReact.Option
										className='no-pill-visible'
										label={'Select All'}
										value={'all'}
									/>
									{affiliationList.map((el) => {
										return (
											<SelectReact.Option
												key={el.id}
												label={el.label}
												value={el.value}
											/>
										);
									})}
								</Fragment>
							)}
						</SelectReact>
						{showProviderReportSelector && (
							<Checkbox
								className='report_type_checkbox_single report_notes'
								checked={notes}
								indeterminate={false}
								onChange={(evt) => selectNotes(evt)}
							>
								<div style={{ marginLeft: '10px' }}>
									<div className='check_labels'>
										{' '}
										Include CC Notes
									</div>
								</div>
							</Checkbox>
						)}
						{showProviderReportSelector && (
							<Checkbox
								className='report_type_checkbox_single report_notes'
								checked={cs_version}
								indeterminate={false}
								onChange={(evt) => selectCSVersion(evt)}
							>
								<div style={{ marginLeft: '10px' }}>
									<div className='check_labels'>
										{' '}
										CS Version
									</div>
								</div>
							</Checkbox>
						)}
					</div>
				)}

				<div style={{ marginTop: '2vw' }}>
					<div
						className='select_date_range_report' /*style = {{fontSize:'0.9vw' , paddingBottom:'2vw', color:'#777', fontStyle:'italic'}}*/
					>
						3. Date Range
					</div>
					<DateRangePicker
						ranges={[selection]}
						onChange={handleSelect}
					/>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		affiliations: state.provideraffiliations.provideraffiliations,
		partnerOrganization: _.get(
			state,
			[
				'partnerOrganization',
				'partnerOrganization',
				'partner_organizations',
			],
			[]
		),
	};
};

const mapDispatchToProps = (dispatch) => ({
	affiliation: (data) => dispatch(getaffiliation(data)),
	getPartnerOrganization: () => dispatch(getPartnerOrganization()),
	resetaffiliation: () => dispatch(resetaffiliation()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomizedSelection);
