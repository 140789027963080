//React & Redux
import React from 'react';
import { connect } from 'react-redux';

//Lodash
import get from 'lodash/get';
import find from 'lodash/find';

//Utils
import { statusEnum } from '../../../../Utils/medicationsUtils.js';
import {
	UTCDateToFormattedDate,
	RemoveUnderscoreAndCapitalizeFirstLetter,
} from '../../../../utils.js';

//Actions
import {
	addPatientDrugExposureNote,
	getPatientDrugExposures,
} from '../../../../actions/graphql.js';

//Styles
import './medicationsStyles.css';

//Components
import { Table, Thead, Tbody, Tr, Th, Td } from '../../../Common/Table';
import NotesContainer from './../../../Common/Notes/NotesContainer.js';

class Medications extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};

		//bindings
		this.getStatusValue = this.getStatusValue.bind(this);
	}
	//functions

	componentDidUpdate(prevProps, prevState) {
		const patientUuid = get(this, 'props.patientUuid', null);
		if (
			get(prevProps, 'addDrugExposureNoteLoading', false) &&
			!get(this, 'props.addDrugExposureNoteLoading', false)
		) {
			if (get(this, 'props.addDrugExposureNoteError', false)) {
				this.props.enqueueSnackbar(
					'Error in adding drug exposure note',
					{
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
			} else {
				this.props.getPatientDrugExposures(patientUuid);
			}
		}
	}

	getStatusValue(status) {
		if (status) {
			const statusDisplay = find(
				statusEnum,
				(el) => get(el, 'internal', null) === status
			);
			return (
				get(statusDisplay, 'external', null) ||
				RemoveUnderscoreAndCapitalizeFirstLetter(status)
			);
		} else return '--';
	}

	render() {
		const drugExposures = get(this, 'props.drugExposures', null) || [];
		const drugExposuresLoading = get(
			this,
			'props.drugExposuresLoading',
			false
		);
		return (
			<div className='medicationsContainer'>
				<Table
					loading={
						get(this, 'props.loading', false) ||
						drugExposuresLoading
					}
					hasData={get(drugExposures, 'length', 0) > 0}
					containerStyle={{ minWidth: '100%' }}
					style={{ minWidth: '100%' }}
				>
					<Thead>
						<Tr>
							<Th>Drug Name</Th>
							<Th>Asserted On</Th>
							<Th>Status</Th>
							<Th>Notes</Th>
						</Tr>
					</Thead>
					<Tbody>
						{drugExposures.map((drug, i) => {
							const id = get(drug, 'id', null);
							const name = get(drug, 'drug.name', null) || '--';
							const asserted_on = get(drug, 'asserted_on', null);
							const status = get(drug, 'status', null);
							const notes = get(drug, 'notes', null) || [];
							return (
								<Tr key={id}>
									<Td>{name}</Td>
									<Td>
										{UTCDateToFormattedDate(asserted_on)}
									</Td>
									<Td style={{ textTransform: 'capitalize' }}>
										{this.getStatusValue(status)}
									</Td>
									<Td minWidth='400px'>
										<NotesContainer
											notes={notes}
											variant='dense'
											addNoteLoading={get(
												this,
												'props.addDrugExposureNoteLoading',
												false
											)}
											addNoteError={get(
												this,
												'props.addDrugExposureNoteError',
												false
											)}
											addNote={
												this.props
													.addPatientDrugExposureNote
											}
											sourceUUID={id}
											addNoteModalTitle={`New Note - ${name}`}
											emptyMessageEnding={'medication'}
										/>
									</Td>
								</Tr>
							);
						})}
					</Tbody>
				</Table>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	addedDrugExposureNote:
		get(state, 'drugExposures.addedDrugExposureNote', null) || {},
	addDrugExposureNoteLoading: get(
		state,
		'drugExposures.addDrugExposureNoteLoading',
		false
	),
	addDrugExposureNoteError: get(
		state,
		'drugExposures.addDrugExposureNoteError',
		false
	),
});

const mapDispatchToProps = (dispatch) => ({
	addPatientDrugExposureNote: (drugUuid, noteText) =>
		dispatch(addPatientDrugExposureNote(drugUuid, noteText)),
	getPatientDrugExposures: (uuid) => dispatch(getPatientDrugExposures(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Medications);
