//React & Redux
import React, { useEffect, useState } from 'react';

//UI Libraries
import { Dialog } from 'gm-element-react';
import ReactSVG from 'react-svg';
import plusIcon from '../../../assets/add.svg';

//Other Libraries
import classnames from 'classnames';

//Components
import CTAWarningModal from '../../Common/CTAWarningModal';
import DocumentNotificationsTable from './DocumentNotificationsTable';
import Icon from '../../Common/Icon';

//Styles
import '../css/document-notifications.css';
import AddRecipient from './AddRecipient';

const DocumentNotifications = (props) => {
	const {
		externalProviderUsers,
		getReferralProgramDetail,
		partnerUuid,
		affiliationName,
		loadingReferralProgramDetail,
		enqueueSnackbar,
		documentNotificationRecipients,
		updateReferralProgramDefaultDocumentNotificationRecipients,
	} = props;

	const [
		removeDefaultDocumentNotificationRecipientUuid,
		setRemoveDefaultDocumentNotificationRecipientUuid,
	] = useState(null);
	const [
		removeDefaultDocumentNotificationRecipientName,
		setRemoveDefaultDocumentNotificationRecipientName,
	] = useState(null);
	const [showRecipientModal, setShowRecipientModal] = useState(false);
	const [
		showRemoveDefaultDocumentNotificationsModal,
		setShowRemoveDefaultDocumentNotificationsModal,
	] = useState(false);

	useEffect(() => {
		getReferralProgramDetail({ partner_uuid: partnerUuid });
	}, []);

	const closeModalsAndRefetchReferralProgramDetail = () => {
		hideAllModals();
		getReferralProgramDetail({ partner_uuid: partnerUuid });
	};

	const hideAllModals = () => {
		hideRecipientsModal();
		setShowRemoveDefaultDocumentNotificationsModal(false);
	};

	const showRecipientModalFn = () => {
		setShowRecipientModal(true);
	};

	const hideRecipientsModal = () => {
		setShowRecipientModal(false);
	};

	const sendNotification = (message, variant) => {
		enqueueSnackbar(message, {
			variant,
			anchorOrigin: {
				horizontal: 'right',
				vertical: 'bottom',
			},
		});
	};

	const handleUpdateRecipient = async (updatedRecipientUuids) => {
		try {
			await updateReferralProgramDefaultDocumentNotificationRecipients({
				partner_uuid: partnerUuid,
				default_doc_release_recipient: updatedRecipientUuids,
			});
			sendNotification('Successfully updated the recipient', 'success');
			closeModalsAndRefetchReferralProgramDetail();
		} catch (error) {
			sendNotification('Error in updating recipient', 'error');
			closeModalsAndRefetchReferralProgramDetail();
		}
	};

	const removeRecipient = (recipientUuid) => {
		const updatedRecipientUuids = documentNotificationRecipients
			.map((recipient) => recipient.uuid)
			.filter((uuid) => uuid !== recipientUuid);
		handleUpdateRecipient(updatedRecipientUuids);
	};

	const handleRemoveDefaultDocumentNotificationRecipient = (
		recipientUuid
	) => {
		setShowRemoveDefaultDocumentNotificationsModal(true);
		setRemoveDefaultDocumentNotificationRecipientUuid(recipientUuid);

		const recipient = documentNotificationRecipients.find(
			(recipient) => recipient.uuid === recipientUuid
		);
		const fullName = `${recipient.first_name} ${recipient.last_name}`;

		setRemoveDefaultDocumentNotificationRecipientName(fullName);
	};

	const handleConfirmRemoveDefaultDocumentNotificationRecipient = () => {
		removeRecipient(removeDefaultDocumentNotificationRecipientUuid);
	};

	const handleHideRemoveDefaultDocumentNotificationRecipientModal = () => {
		setShowRemoveDefaultDocumentNotificationsModal(false);
		setRemoveDefaultDocumentNotificationRecipientUuid(null);
		setRemoveDefaultDocumentNotificationRecipientName(null);
	};

	const tableLoading = loadingReferralProgramDetail;

	return (
		<div>
			<div className='doc-notification-container-div'>
				<div className='doc-notification-container-title'>
					Document Notification Recipients
				</div>
				<div className='doc-notification-container-description'>
					Configure the administrative representatives associated with{' '}
					<span
						style={{
							textTransform: 'capitalize',
							display: 'inline',
						}}
					>
						{affiliationName}
					</span>{' '}
					who will automatically receive patients' visit or result
					summaries when they are finalized/amended.
				</div>
			</div>
			<div
				className='addTestButton'
				onClick={showRecipientModalFn}
				style={{ width: '140px', marginTop: '24px' }}
			>
				<ReactSVG
					src={plusIcon}
					style={{
						position: 'relative',
						top: '1px',
						marginRight: '8px',
					}}
				/>
				Add Recipient
			</div>
			<div className='default-recipients-table'>
				<DocumentNotificationsTable
					{...{
						documentNotificationRecipients,
						handleRemoveDefaultDocumentNotificationRecipient,
						tableLoading,
					}}
				/>
			</div>
			{showRemoveDefaultDocumentNotificationsModal && (
				<CTAWarningModal
					colorScheme={'red'}
					title={'Delete Recipient'}
					text={`Are you sure you want to delete ${removeDefaultDocumentNotificationRecipientName} as a recipient for ${affiliationName
						.split('-')
						.map(
							(affiliation) =>
								affiliation.charAt(0).toUpperCase() +
								affiliation.slice(1)
						)
						.join('-')}?`}
					mainButtonText={'Delete Recipient'}
					mainFunc={
						handleConfirmRemoveDefaultDocumentNotificationRecipient
					}
					closeFunc={
						handleHideRemoveDefaultDocumentNotificationRecipientModal
					}
				/>
			)}
			{showRecipientModal && (
				<Dialog
					customClass='recipient-add-dialog'
					title={
						<span className='header'>
							<span className='dialog-title no-breadcrumbs'>
								{'Add Recipient'}
							</span>
							<span className={classnames('close-btn-wrapper')}>
								<span
									className='close-btn-icon'
									onClick={hideAllModals}
								>
									<Icon icon='close' className='close-icon' />
								</span>
							</span>
						</span>
					}
					size='tiny'
					modal={true}
					closeOnPressEscape={true}
					closeOnClickModal={false}
					showClose={false}
					visible={showRecipientModal}
					onCancel={hideAllModals}
					style={{
						height: '400px',
					}}
				>
					<div style={{ height: '100%' }}>
						<Dialog.Body>
							<div className='recipient-wrapper'>
								{
									<AddRecipient
										externalProviderUsers={
											externalProviderUsers
										}
										handleUpdateRecipient={
											handleUpdateRecipient
										}
									></AddRecipient>
								}
							</div>
						</Dialog.Body>
					</div>
				</Dialog>
			)}
		</div>
	);
};

export default DocumentNotifications;
