//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	getMomentDateOrString,
	getDateString,
} from '../Encounters/Common/utilsfunctions';

//Other Libraries
import Datetime from 'react-datetime';

/* Component accept value as a string and return value as a string in onchange and on blur. 
when value is valid dateformat (YYYY-MM-DD), it will pass moment object to plugin else it will pass value.
when value is valid dateformat (YYYY-MM-DD), it will convert return string in YYYY-MM-DD else it will return whatever value it has.
*/
export const EncounterDateField = ({
	name,
	value,
	onChange,
	placeholder,
	trim,
	className,
	disabled = false,
	disabledDate,
	onBlur,
	makeCalendarOnly,
}) => {
	return (
		<Datetime
			name={name}
			className={className}
			dateFormat={'MM/DD/YYYY'}
			timeFormat={false}
			value={getMomentDateOrString(value)}
			isDisabled={disabled}
			onChange={(value) => onChange(getDateString(value))}
			isValidDate={disabledDate}
			onBlur={(value) => onBlur(getDateString(value))}
			strictParsing={true}
			inputProps={{
				disabled: disabled,
				placeholder: placeholder || 'Date',
				autoComplete: 'no',
				onKeyDown: makeCalendarOnly,
			}}
			closeOnTab={true}
			closeOnSelect={true} // if we enabled this then sometime blur might not be called from plugin.
		/>
	);
};
