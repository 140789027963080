//React & Redux
import React, { Component } from 'react';

//Lodash
import { filter, get, isArray } from 'lodash';

//Utils
import {
	getDateTimeString,
	isValidDateTimeString,
	getMomentDateTimeOrString,
	dateTimeFormats,
} from '../../Patients/V2/Outreachs/utilfunctions';

//Components
import CreateOutreachModalTopRow from '../../Patients/PatientCreateOutreach/CreateOutreachModalTopRow';
import PatientDetailBox from '../../Patients/PatientCreateOutreach/PatientDetailBox';
import SelectOutcome from '../../Patients/PatientCreateOutreach/SelectOutcome';
import OutreachInputs from '../../Patients/PatientCreateOutreach/OutreachInputs';

//UI Libraries
import { Loading } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

class CreateRoROutreachModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentStage: 'selectOutcome',
			addOutreachHeader: 'Add Outreach',
			doNotOutreachHeader: 'Patient Declined Follow Up',
			addOutreachFields: [
				{
					header: 'Contact Date & Time',
					required: true,
					type: 'dateTime',
					placeholder: 'Select time',
					stateVar: 'createAddOutreachDateTime',
				},
				{
					header: 'Outcome',
					required: true,
					type: 'select',
					placeholder: 'Select outcome',
					stateVar: 'createAddOutreachOutcome',
				},
			],
			doNotOutreachFields: [
				{
					header: 'Declined Date & Time',
					required: true,
					type: 'dateTime',
					placeholder: 'Select time',
					stateVar: 'createDoNotOutreachDateTime',
				},
				{
					header: "Patient's Reason for declining",
					required: true,
					type: 'select',
					placeholder: 'Select reason',
					stateVar: 'createDoNotOutreachReason',
				},
			],
			addOutreachButtons: {
				back: {
					text: 'Back',
					class: 'patientCreateOutreachAddBackButton',
				},
				confirm: {
					text: 'Add Outreach',
					class: 'patientCreateOutreachAddConfirmButton',
					disabled:
						'patientCreateOutreachAddConfirmButton patientCreateOutreachAddConfirmButtonDisabled',
				},
			},
			scheduleButtons: {
				back: {
					text: 'Back',
					class: 'patientCreateOutreachAddBackButton',
				},
				confirm: {
					text: 'Go To Scheduling',
					class: 'patientCreateOutreachAddConfirmButton',
					disabled: 'patientCreateOutreachAddConfirmButton',
				},
			},
			doNotOutreachButtons: {
				back: {
					text: 'Back',
					class: 'doNotOutreachAlertButton updatedDoNotOutreachAlertButtonCancel',
				},
				confirm: {
					text: 'Mark As F/U Declined',
					class: 'doNotOutreachAlertButton updatedDoNotOutreachAlertButtonSave',
					disabled:
						'doNotOutreachAlertButton updatedDoNotOutreachAlertButtonSave updatedDoNotOutreachAlertButtonSaveDisabled',
				},
			},
			loading: false,
			createAddOutreachDateTime: getDateTimeString(moment()),
			createAddOutreachOutcome: null,
			patientCreateAddOutreachNotes: '',
			createDoNotOutreachDateTime: getDateTimeString(moment()),
			createDoNotOutreachReason: null,
		};
		//bindings
		this.renderCurrentStage = this.renderCurrentStage.bind(this);
		this.determineDOB = this.determineDOB.bind(this);
		this.determineDOBDifference = this.determineDOBDifference.bind(this);
		this.setStage = this.setStage.bind(this);
		this.setValue = this.setValue.bind(this);
		this.handleRoRFUDeclinedSubmit =
			this.handleRoRFUDeclinedSubmit.bind(this);
		this.CreateRedirectToScheduling =
			this.CreateRedirectToScheduling.bind(this);
		this.createSetOutreachDefault =
			this.createSetOutreachDefault.bind(this);
		this.patientCreateAddOutreach =
			this.patientCreateAddOutreach.bind(this);
	}

	componentDidMount() {
		const encounter_uuid = get(
			this,
			['props', 'patient', 'encounter_uuid'],
			0
		);
		this.props.callTestOrderRequest(encounter_uuid);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			get(prevProps, 'addOutreachLoading', false) &&
			!get(this, 'props.addOutreachLoading', false)
		) {
			if (get(this, 'props.addedOutreachError', null)) {
				this.props.enqueueSnackbar('Error in adding outreach', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				this.props.enqueueSnackbar('Outreach added', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				this.props.close();
			}
		}
	}

	componentWillUnmount() {
		this.props.callResetEncounters();
	}

	setValue(stateVal, inputVal) {
		if (stateVal) {
			const newState = {};
			newState[stateVal] = inputVal;
			this.setState(newState);
		}
	}

	patientCreateAddOutreach() {
		const patient_uuid = get(this, ['props', 'patient', 'patient_uuid'], 0);
		const strDateTime = get(this, 'state.createAddOutreachDateTime', null);
		if (isValidDateTimeString(strDateTime, dateTimeFormats)) {
			const momentDateTimeObj = getMomentDateTimeOrString(
				strDateTime,
				dateTimeFormats
			);
			const utcString = momentDateTimeObj
				.utc()
				.format('YYYY-MM-DD HH:mm:ss');
			if (utcString !== 'Invalid date') {
				const payload = {
					date_time: utcString,
					contact_method: 'phone',
					type: 'ror_outreach',
					outcome: get(this, 'state.createAddOutreachOutcome', null),
					class: 'outreach',
					encounter_uuid: get(
						this,
						['props', 'patient', 'encounter_uuid'],
						0
					),
					outreach_sequence_id: get(
						this,
						['props', 'patient', 'outreach_seq_no'],
						0
					),
				};
				if (
					get(this, 'state.createAddOutreachOutcome', null) ===
					'other'
				) {
					payload['notes'] = get(
						this,
						'state.patientCreateAddOutreachNotes',
						''
					);
				}
				this.props.addOutreachUponCreate(payload, patient_uuid);
			}
		}
	}

	handleRoRFUDeclinedSubmit() {
		this.rorFUDeclined(
			get(this, 'state.createDoNotOutreachDateTime', null),
			get(this, 'state.createDoNotOutreachReason', null)
		);
	}

	rorFUDeclined(dateTime, reason) {
		if (dateTime && reason) {
			if (isValidDateTimeString(dateTime, dateTimeFormats)) {
				const momentDateTimeObj = getMomentDateTimeOrString(
					dateTime,
					dateTimeFormats
				);
				const utcString = momentDateTimeObj
					.utc()
					.format('YYYY-MM-DD HH:mm:ss');
				const encounter_uuid = get(
					this,
					['props', 'patient', 'encounter_uuid'],
					0
				);
				if (utcString !== 'Invalid date') {
					const data = {
						data: {
							outreach_status: 'followup_declined',
							followup_declined_reason: reason,
							date_time: utcString,
						},
					};
					this.setState({ loading: true });
					this.props
						.handleFollowUpDeclinedOutreach(encounter_uuid, data)
						.then(() => {
							this.setState({ loading: false });
						});
				}
			}
		}
	}

	CreateRedirectToScheduling() {
		this.createSetOutreachDefault();
		this.props.CreateRedirectToScheduling();
	}

	createSetOutreachDefault() {
		const patient_uuid = get(this, ['props', 'patient', 'patient_uuid'], 0);
		const payload = {
			date_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
			contact_method: 'phone',
			type: 'ror_outreach',
			outcome: 'patient_scheduled',
			class: 'outreach',
			encounter_uuid: get(
				this,
				['props', 'patient', 'encounter_uuid'],
				0
			),
			outreach_sequence_id: get(
				this,
				['props', 'patient', 'outreach_seq_no'],
				0
			),
		};
		this.props.addOutreachUponCreate(payload, patient_uuid);
	}

	renderCurrentStage() {
		switch (get(this, 'state.currentStage', 'initial')) {
			case 'selectOutcome':
				return (
					<SelectOutcome
						setStage={this.setStage}
						redirectToScheduling={this.CreateRedirectToScheduling}
						selectedpage='RoRDashboard'
					/>
				);
			case 'schedule':
				return (
					<OutreachInputs
						header={
							<div>
								Are you sure you want to proceed to Scheduling
								for this patient?
								<br />
								<br />A positive Outreach will be logged
								automatically.
							</div>
						}
						buttons={get(this, 'state.scheduleButtons', {}) || {}}
						func={this.CreateRedirectToScheduling}
						goBack={() => this.setStage('selectOutcome')}
						currentStage={get(
							this,
							'state.currentStage',
							'selectOutcome'
						)}
					/>
				);
			case 'addOutreach':
				return (
					<OutreachInputs
						header={get(
							this,
							'state.addOutreachHeader',
							'Please select'
						)}
						options={
							get(this, 'props.outreachEnums.outcomes', []) || []
						}
						fields={get(this, 'state.addOutreachFields', []) || []}
						buttons={
							get(this, 'state.addOutreachButtons', {}) || {}
						}
						func={this.patientCreateAddOutreach}
						goBack={() => this.setStage('selectOutcome')}
						selectedTimeAddOutreach={get(
							this,
							'state.createAddOutreachDateTime',
							null
						)}
						selectedOptionAddOutreach={get(
							this,
							'state.createAddOutreachOutcome',
							null
						)}
						patientCreateAddOutreachNotes={get(
							this,
							'state.patientCreateAddOutreachNotes',
							''
						)}
						setValue={this.setValue}
						currentStage={get(
							this,
							'state.currentStage',
							'selectOutcome'
						)}
					/>
				);
			case 'doNotOutreach':
				return (
					<OutreachInputs
						header={get(
							this,
							'state.doNotOutreachHeader',
							'Please select'
						)}
						options={
							get(
								this,
								'props.patientDeclinedReasonsEnums',
								[]
							) || []
						}
						fields={
							get(this, 'state.doNotOutreachFields', []) || []
						}
						buttons={
							get(this, 'state.doNotOutreachButtons', {}) || {}
						}
						func={this.handleRoRFUDeclinedSubmit}
						goBack={() => this.setStage('selectOutcome')}
						selectedTimeNoOutreach={get(
							this,
							'state.createDoNotOutreachDateTime',
							null
						)}
						selectedOptionNoOutreach={get(
							this,
							'state.createDoNotOutreachReason',
							null
						)}
						setValue={this.setValue}
						currentStage={get(
							this,
							'state.currentStage',
							'selectOutcome'
						)}
					/>
				);
			default:
				console.log('Unrecognized stage.');
		}
	}

	setStage(stage) {
		this.setState({ currentStage: stage });
	}

	determineDOB() {
		const patient = get(this, 'props.patient', {}) || {};
		const dob = get(patient, 'dob', null);
		if (moment(dob).isValid()) {
			return moment(dob).format('MMM DD, YYYY');
		} else return dob || 'DOB Unknown';
	}

	determineDOBDifference() {
		const patient = get(this, 'props.patient', {}) || {};
		const dob = get(patient, 'dob', null);
		if (moment(dob).isValid()) {
			return moment().diff(dob, 'years') + ' yrs old';
		} else return 'Age Unknown';
	}

	filterTestOrderRequest = () => {
		const testOrderRequest = get(
			this,
			'props.encounterTestOrderRequest',
			[]
		);
		const patient = get(this, 'props.patient', {}) || {};

		if (isArray(testOrderRequest)) {
			return filter(testOrderRequest, (obj) => {
				return obj.outreach_seq_no == patient.outreach_seq_no;
			});
		}
		return [];
	};

	render() {
		const patient = get(this, 'props.patient', {}) || {};
		const encounterTestOrderRequestLoading = get(
			this,
			'props.encounterTestOrderRequestLoading',
			null
		);

		return (
			<div className='outreachPopupBackground'>
				<div className='patientCreateOutreachModalContainer'>
					<Loading
						loading={
							get(this, 'props.addOutreachLoading', false) ||
							get(this, 'state.loading', false) ||
							encounterTestOrderRequestLoading
						}
						className='patientCreateOutreachModalContainerLoader'
					>
						<CreateOutreachModalTopRow
							header={'Return of Results Outreach'}
							close={this.props.close}
						/>
						<div className='patientCreateOutreachModalBody'>
							<PatientDetailBox
								determineDOB={this.determineDOB}
								determineDOBDifference={
									this.determineDOBDifference
								}
								patient={{
									uuid: patient.patient_uuid,
									email: patient.patient_email,
									phone: patient.patient_phone,
									provider:
										this.props.getProviderName(patient),
									...patient,
								}}
								selectedpage='RoRDashboard'
								selectPatient={this.props.selectPatient}
								testOrderRequest={this.filterTestOrderRequest()}
								selectPatientURL={this.props.selectPatientURL}
							/>
							{this.renderCurrentStage()}
						</div>
					</Loading>
				</div>
			</div>
		);
	}
}

export default CreateRoROutreachModal;
