import classnames from 'classnames';
import camelCase from 'lodash/camelCase';
import { Checkbox as ElCheckbox } from 'gm-element-react';
import React from 'react';

import { FormField, IFormFieldProps } from './FormField';

import './CheckboxField.scss';

interface IProps extends IFormFieldProps {
	value?: boolean;
	onChange?: (value: string) => void;
	disabled?: boolean;
}

export const CheckboxField: React.FC<IProps> = (props) => {
	const {
		className,
		name,
		label,
		required,
		error,
		value,
		tooltip,
		disabled,
		onChange,
	} = props;
	return (
		<FormField
			className={classnames('gm-checkbox', className)}
			name={name ?? camelCase(label)}
			required={required}
			error={error}
			tooltip={tooltip}
		>
			<ElCheckbox
				label={label}
				checked={value}
				onChange={onChange}
				disabled={disabled}
			/>
		</FormField>
	);
};
