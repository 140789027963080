import * as actionTypes from './actionTypes';

import EncounterService from '../service/Encounter';
import LabOrderService from '../service/LabOrders';
import BillingEncounterService from '../service/Billing';
import { sortVisitProvidersByFirstName } from './../transformers/dashboardTransformers.js';
import {
	formatVisitIndications,
	hydrateTestOrders,
} from './../transformers/patients/encounterTransformer.js';
import get from 'lodash/get';
import _ from 'lodash';

export const encounters = (data) => {
	return {
		type: actionTypes.ENCOUNTER_LIST,
		payload: data,
	};
};

const loadingOrderRequest = () => {
	return {
		type: actionTypes.LOADING_ORDER_REQUEST,
	};
};

export const orderrequest = (data) => {
	return {
		type: actionTypes.ORDER_REQUEST,
		payload: data,
	};
};

const orderRequestError = (err) => {
	return {
		type: actionTypes.ORDER_REQUEST_ERROR,
		payload: err,
	};
};

export const loading = (data) => {
	return {
		type: actionTypes.ENCOUNTER_LIST_LOADING,
		payload: data,
	};
};

const encounterListError = (err) => {
	return {
		type: actionTypes.ENCOUNTER_LIST_ERROR,
		payload: err,
	};
};

/**
 * Retrieves a list of encounters for a given patient.
 * @param {Object} data - Object containing parameters for the encounter list, including:
 * @param {number} data.id - The patient's UUID.
 * @param {number} data.offset - The starting offset for the list.
 * @param {number} data.limit - The maximum number of encounters to retrieve.
 * @returns {Array} - An array of all the encounters of the patient.
 */

export function getencounters(data) {
	return (dispatch) => {
		dispatch(loading(true));
		EncounterService.encounterlist(data)
			.then((res) => {
				dispatch(encounters(res.data));
				dispatch(loading(false));
			})
			.catch((err) => {
				dispatch(loading(false));
				dispatch(encounterListError(err));
			});
	};
}

export function resetencounters() {
	return (dispatch) => {
		dispatch(encounters([]));
	};
}

export function encounterdetail(pid, doClear = false) {
	return (dispatch) => {
		if (doClear) {
			dispatch(clearencounterdetail());
		}
		dispatch(gettingEncounterDetail());
		EncounterService.encounterdetail(pid)
			.then((res) => {
				dispatch(encounterDetail(res.data.data[0]));
			})
			.catch((err) => {
				dispatch(encounterDetailError(err));
			});
	};
}

export const gettingEncounterDetail = () => {
	return {
		type: actionTypes.GETTING_ENCOUNTER_DETAIL,
		payload: true,
	};
};

export const encounterDetail = (data) => {
	return {
		type: actionTypes.ENCOUNTER_DETAIL,
		payload: data,
	};
};

const encounterDetailError = (err) => {
	return {
		type: actionTypes.ENCOUNTER_DETAIL_ERROR,
		payload: err,
	};
};

//edit encounter
export function editencounter(data, isthrowerror = false) {
	return (dispatch) => {
		dispatch(editingEncounter());
		return EncounterService.editencouter(data)
			.then((res) => {
				dispatch(encounteredit(res.data));
				return _.get(res, 'data', {});
			})
			.catch((err) => {
				dispatch(editEncounterFailure(err));
				if (isthrowerror) throw err;
			});
	};
}

export function saveBillingEncounter(data, isthrowerror = false) {
	return (dispatch) => {
		dispatch(editingBillingEncounter());
		return BillingEncounterService.saveBillingEncounter(data.data)
			.then((res) => {
				dispatch(billingencounteredit(res.data));
				return _.get(res, 'data', {});
			})
			.catch((err) => {
				dispatch(editBillingEncounterFailure(err));
				if (isthrowerror) throw err;
			});
	};
}

export const editingEncounter = () => {
	return {
		type: actionTypes.EDITING_ENCOUNTER,
		payload: true,
	};
};

export const editingBillingEncounter = () => {
	return {
		type: actionTypes.EDITING_BILLING_ENCOUNTER,
		payload: true,
	};
};

export const encounteredit = (data) => {
	return {
		type: actionTypes.EDIT_ENCOUNTER,
		payload: data,
	};
};

export const billingencounteredit = (data) => {
	return {
		type: actionTypes.EDIT_BILLING_ENCOUNTER,
		payload: data,
	};
};

export const editEncounterFailure = (err) => {
	return {
		type: actionTypes.EDIT_ENCOUNTER_FAILURE,
		payload: err,
	};
};

export const editBillingEncounterFailure = (err) => {
	return {
		type: actionTypes.EDIT_BILLING_ENCOUNTER_FAILURE,
		payload: err,
	};
};

//create encounter
export function addencounter(data) {
	return (dispatch) => {
		dispatch(addingEncounter());
		return EncounterService.addencounter(data)
			.then((res) => {
				dispatch(addEncounter(res.data));
				return res.data;
			})
			.catch((err) => {
				dispatch(addEncounterError(err));
			});
	};
}

export const addingEncounter = () => {
	return {
		type: actionTypes.ADDING_ENCOUNTER,
		payload: true,
	};
};

export const addEncounter = (data) => {
	return {
		type: actionTypes.ADD_ENCOUNTER,
		payload: data,
	};
};

export const addEncounterError = (err) => {
	return {
		type: actionTypes.ADD_ENCOUNTER_ERROR,
		payload: err,
	};
};

//Update Encounter Status
export function completeEncounter(data) {
	return (dispatch) => {
		dispatch(completingEncounter());
		EncounterService.completeEncounter(data)
			.then(() => {
				dispatch(completedEncounter());
			})
			.catch((err) => {
				dispatch(completedEncounterError(err));
			});
	};
}

export const completingEncounter = () => {
	return {
		type: actionTypes.COMPLETING_ENCOUNTER,
		payload: true,
	};
};

export const completedEncounter = () => {
	return {
		type: actionTypes.COMPLETE_ENCOUNTER,
		payload: false,
	};
};

export const completedEncounterError = (err) => {
	return {
		type: actionTypes.COMPLETE_ENCOUNTER_ERROR,
		payload: err,
	};
};

export function migrateToPioneerRCO(data) {
	return async (dispatch) => {
		dispatch(migratingToPioneerRCO());
		try {
			const response = await EncounterService.migrateToPioneerRCO(data);
			if (response.status === 500) {
				dispatch(migratedToPioneerErrorRCO(response));
				return response;
			}
			dispatch(migratedToPioneerRCO(response));
			return response;
		} catch (error) {
			dispatch(migratedToPioneerErrorRCO(error));
			return error;
		}
	};
}

export const migratedToPioneerRCO = (data) => {
	return {
		type: actionTypes.MIGRATED_TO_PIONEER_RCO,
		payload: data,
	};
};

export const migratingToPioneerRCO = () => {
	return {
		type: actionTypes.MIGRATING_TO_PIONEER_RCO,
		payload: true,
		message: 'Migrating encounter to Pioneer...',
	};
};

export const migratedToPioneerErrorRCO = (error) => {
	return {
		type: actionTypes.MIGRATED_TO_PIONEER_ERROR_RCO,
		payload: error,
	};
};

export function cancelEncounter(data) {
	return async (dispatch) => {
		dispatch(cancellingEncounter());
		try {
			// The backend API responds with 200 for the errors. The error message is in response?.data?.message
			const response = await EncounterService.cancelEncounter(data);
			let parsedResponse;
			if (
				response?.data?.message ||
				response.status < 200 ||
				response.status > 299
			) {
				try {
					parsedResponse = JSON.parse(response?.data?.message);
				} catch (parseError) {
					throw new Error(
						response?.data?.message || 'Internal Server Error'
					);
				}
				throw new Error(parsedResponse?.message);
			}
			dispatch(cancelledEncounter(response));
			return response;
		} catch (error) {
			dispatch(cancelledEncounterError(error));
			throw error;
		}
	};
}

export const cancellingEncounter = () => {
	return {
		type: actionTypes.CANCELLING_ENCOUNTER,
		payload: true,
	};
};

export const cancelledEncounter = () => {
	return {
		type: actionTypes.CANCEL_ENCOUNTER,
		payload: false,
	};
};

export const cancelledEncounterError = (err) => {
	return {
		type: actionTypes.CANCEL_ENCOUNTER_ERROR,
		payload: err,
	};
};

export function uploadDocument(data) {
	return (dispatch) => {
		dispatch(loadingOrderRequest());
		LabOrderService.getToken(data)
			.then((res) => {
				const lab_api_headers = get(res, 'data.data', {});
				data['lab_api_headers'] = lab_api_headers;
				LabOrderService.encounterorderRequest(data).then((res) => {
					dispatch(orderrequest(res.data.data));
				});
			})
			.catch((err) => {
				dispatch(orderRequestError(err));
			});
	};
}

const loadingEncounterGroupsessionSchema = () => {
	return {
		type: actionTypes.LOADING_GROUP_SESSION_SCHEMA,
	};
};

export const encountergroupsessionschema = (data) => {
	return {
		type: actionTypes.GROUP_SESSION_SCHEMA,
		payload: data,
	};
};

const encounterGroupsessionSchemaError = (err) => {
	return {
		type: actionTypes.GROUP_SESSION_SCHEMA_ERROR,
		payload: err,
	};
};

const loadingEncounterCCIntakeSchema = () => {
	return {
		type: actionTypes.LOADING_CC_INTAKE_SCHEMA,
	};
};

export const encounterccintakeschema = (data) => {
	return {
		type: actionTypes.CC_INTAKE_SCHEMA,
		payload: data,
	};
};

const encounterCCIntakeSchemaError = (data) => {
	return {
		type: actionTypes.CC_INTAKE_SCHEMA_ERROR,
		payload: data,
	};
};

const loadingEncounterVisitsSchema = () => {
	return {
		type: actionTypes.LOADING_VISITS_SCHEMA,
	};
};

export const encountervisitsschema = (data) => {
	return {
		type: actionTypes.VISITS_SCHEMA,
		payload: data,
	};
};

const encounterVisitsSchemaError = (err) => {
	return {
		type: actionTypes.VISITS_SCHEMA_ERROR,
		payload: err,
	};
};

const loadingEncounterResearchDataSchema = () => {
	return {
		type: actionTypes.RESEARCH_DATA_SCHEMA,
	};
};
export const encounterresearchdataschema = (data) => {
	return {
		type: actionTypes.RESEARCH_DATA_SCHEMA,
		payload: data,
	};
};
const encounterResearchDataSchemaError = (data) => {
	return {
		type: actionTypes.RESEARCH_DATA_SCHEMA_ERROR,
		payload: data,
	};
};

const loadingVisitProviderEnum = (type) => {
	return {
		type: actionTypes.LOADING_VISIT_PROVIDER_ENUM,
		encountertype: type,
	};
};

export const encounter_visitprovider_enum = (data, type) => {
	return {
		type: actionTypes.VISIT_PROVIDER_ENUM,
		payload: data,
		encountertype: type,
	};
};

const VisitProviderEnumError = (data, type) => {
	return {
		type: actionTypes.VISIT_PROVIDER_ENUM_ERROR,
		payload: data,
		encountertype: type,
	};
};

const getting_encounter_visitconsulationtypes_enum = () => {
	return {
		type: actionTypes.GETTING_VISIT_CONSULTATION_TYPES,
		payload: true,
	};
};

export const encounter_visitconsulationtypes_enum = (data, type) => {
	return {
		type: actionTypes.VISIT_CONSULTATION_TYPES,
		payload: data,
		encountertype: type,
	};
};

const encounter_visitconsulationtypes_enum_error = (err) => {
	return {
		type: actionTypes.VISIT_CONSULTATION_TYPES_ERROR,
		payload: err,
	};
};

const gettingPayorEnums = () => ({
	type: actionTypes.GETTING_PAYOR_ENUM,
	payload: true,
});

const payorEnums = (data) => ({
	type: actionTypes.PAYOR_ENUMS,
	payload: data.data,
});

const payorEnumsError = (err) => ({
	type: actionTypes.PAYOR_ENUMS_ERROR,
	payload: err,
});

const loadingOrderingPhysicianEnum = (type) => {
	return {
		type: actionTypes.LOADING_ORDERING_PHYSICIAN,
		encountertype: type,
	};
};

export const encounter_ordering_physician_enum = (data, type) => {
	return {
		type: actionTypes.ORDERING_PHYSICIAN,
		payload: data,
		encountertype: type,
	};
};

const OrderingPhysicianEnumError = (err, type) => {
	return {
		type: actionTypes.ORDERING_PHYSICIAN_ERROR,
		payload: err,
		encountertype: type,
	};
};

const loadingSpecificEnum = (type, fieldName) => {
	return (dispatch) => {
		if (fieldName == 'visit_provider') {
			dispatch(loadingVisitProviderEnum(type));
		} else {
			dispatch(loadingOrderingPhysicianEnum(type));
		}
	};
};

const SpecificEnumError = (err, type, fieldName) => {
	return (dispatch) => {
		if (fieldName == 'visit_provider') {
			dispatch(VisitProviderEnumError(type, err));
		} else {
			dispatch(OrderingPhysicianEnumError(err, type));
		}
	};
};
const loadingPartnerInvoiceStatus = () => {
	return {
		type: actionTypes.LOADING_PARTNER_INVOICE_STATUS,
	};
};

export const encounter_partner_invoice_status = (data) => {
	return {
		type: actionTypes.PARTNER_INVOICE_STATUS,
		payload: data,
	};
};

const partnerInvoiceStatusError = (err) => {
	return {
		type: actionTypes.PARTNER_INVOICE_STATUS_ERROR,
		payload: err,
	};
};

export const getencountergroupsessionschema = () => {
	return (dispatch) => {
		dispatch(loadingEncounterGroupsessionSchema());
		EncounterService.encounterschema('group-session')
			.then((res) => {
				dispatch(encountergroupsessionschema(res.data));
			})
			.catch((err) => {
				dispatch(encounterGroupsessionSchemaError(err));
			});
	};
};

export const getencounterccintakeschema = () => {
	return (dispatch) => {
		dispatch(loadingEncounterCCIntakeSchema());
		EncounterService.encounterschema('cc-intake')
			.then((res) => {
				dispatch(encounterccintakeschema(res.data));
			})
			.catch((err) => {
				dispatch(encounterCCIntakeSchemaError(err));
			});
	};
};

export const getencountervisitsschema = (params = {}) => {
	return (dispatch) => {
		dispatch(loadingEncounterVisitsSchema());
		EncounterService.encounterschema('visits', params)
			.then((res) => {
				dispatch(encountervisitsschema(res.data));
			})
			.catch((err) => {
				dispatch(encounterVisitsSchemaError(err));
			});
	};
};

//Getting labs enum
export const getencounterlabseschema = () => {
	return (dispatch) => {
		dispatch(gettingLabsEnum());
		EncounterService.encounterschema('labs')
			.then((res) => {
				dispatch(encounterlabsschema(res.data));
			})
			.catch((err) => {
				dispatch(encounterlabsschemaError(err));
			});
	};
};

export const gettingLabsEnum = () => {
	return {
		type: actionTypes.GETTING_LABS_ENUM,
		payload: true,
	};
};

export const encounterlabsschema = (data) => {
	return {
		type: actionTypes.LABS_SCHEMA,
		payload: data,
	};
};

const encounterlabsschemaError = (err) => {
	return {
		type: actionTypes.LABS_SCHEMA_ERROR,
		payload: err,
	};
};

export const getencounterresearchdataschema = () => {
	return (dispatch) => {
		dispatch(loadingEncounterResearchDataSchema());
		EncounterService.encounterschema('research-data')
			.then((res) => {
				dispatch(encounterresearchdataschema(res.data));
			})
			.catch((err) => {
				dispatch(encounterResearchDataSchemaError(err));
			});
	};
};

export const getspecificencounterenum = (data) => {
	return (dispatch) => {
		dispatch(
			loadingSpecificEnum(
				get(data, 'type', ''),
				get(data, 'field_name', '')
			)
		);
		EncounterService.specificencounterenum(
			get(data, 'type', ''),
			get(data, 'field_name', '')
		)
			.then((res) => {
				if (data.field_name == 'visit_provider')
					dispatch(
						encounter_visitprovider_enum(
							sortVisitProvidersByFirstName(res.data),
							data.type
						)
					);
				else
					dispatch(
						encounter_ordering_physician_enum(res.data, data.type)
					);
			})
			.catch((err) => {
				dispatch(
					SpecificEnumError(
						err,
						get(data, 'type', ''),
						get(data, 'field_name', '')
					)
				);
			});
	};
};

export const getVisitConsulationTypesEnums = (limit, page, with_trashed) => {
	return (dispatch) => {
		dispatch(getting_encounter_visitconsulationtypes_enum());
		EncounterService.getVisitConsulatationTypesEnums(
			limit,
			page,
			with_trashed
		)
			.then((res) => {
				dispatch(encounter_visitconsulationtypes_enum(res.data));
			})
			.catch((err) => {
				dispatch(encounter_visitconsulationtypes_enum_error(err));
			});
	};
};

export const getPayorEnums = () => {
	return (dispatch) => {
		dispatch(gettingPayorEnums());
		EncounterService.getPayorEnums()
			.then((res) => {
				dispatch(payorEnums(res.data));
			})
			.catch((err) => {
				dispatch(payorEnumsError(err));
			});
	};
};

export const getresearchinvoicestatusenum = () => {
	return (dispatch) => {
		dispatch(loadingPartnerInvoiceStatus());
		EncounterService.researchpartnerinvoicestatus()
			.then((res) => {
				dispatch(encounter_partner_invoice_status(res.data));
			})
			.catch((err) => {
				dispatch(partnerInvoiceStatusError(err));
			});
	};
};

//Get a billing encounter associated with an encounter uuid
export function getBillingEncounterDetail(data) {
	return (dispatch) => {
		dispatch(gettingBillingEncounterDetail());
		if (data !== 'scp-encounter') {
			EncounterService.getBillingEncounterObject(data)
				.then((res) => {
					dispatch(billingobject(res.data));
				})
				.catch((err) => {
					dispatch(gotBillingEncounterDetailError(err));
				});
		} else {
			// TODO: remove once API is ready
			dispatch(
				billingobject([
					{
						uuid: '3a851613-ff32-4985-96c0-375f97496c41',
						id: 17576,
						encounter_uuid: '0ec0f67c-9632-4509-974f-9a7e6ede1a66',
						created_at: '2020-03-11 03:18:10.132729',
						partner_invoice_date: '',
						partner_invoice_rate: null,
						patient_billing_date: null,
						partner_invoice_status: 'not_sent',
						patient_billing_amount: null,
						insurance_expected_revenue: null,
						procedure_code: '96040,95',
						direct_cc_minutes: 10,
						indirect_cc_minutes: 10,
						units: null,
						athena_claim_id: null,
						athena_claim_err_msg: null,
						athena_payment_batch_id: null,
						athena_authorization_id: null,
						athena_insurance_package_id: null,
						athena_insurance_id: null,
						athena_referring_provider_id: 54,
						athena_rendering_provider_id: null,
					},
				])
			);
		}
	};
}

export function clearBillingEncounter() {
	return (dispatch) => {
		dispatch(billingobject(null));
	};
}

export function getSCPClinicalInfoDetail(uuid) {
	return (dispatch) => {
		dispatch(gettingSCPClinicalInfo());
		EncounterService.getSCPClinicalInfo(uuid)
			.then((res) => {
				dispatch(getSCPClinicalInfoSucess(_.get(res, 'data', [])));
			})
			.catch((err) => {
				dispatch(getSCPClinicalInfoError(err));
			});
	};
}

export const gettingSCPClinicalInfo = () => {
	return {
		type: actionTypes.GETTING_SCP_CLINICAL_INFO,
		payload: true,
	};
};

export const getSCPClinicalInfoSucess = (data) => {
	return {
		type: actionTypes.GETTING_SCP_CLINICAL_INFO_SUCCESS,
		payload: data,
	};
};

export const getSCPClinicalInfoError = (data) => {
	return {
		type: actionTypes.GETTING_SCP_CLINICAL_INFO_ERROR,
		payload: data,
	};
};

export const gettingBillingEncounterDetail = () => {
	return {
		type: actionTypes.LOADING_ENCOUNTER_BILLING_DATA,
		payload: true,
	};
};

export const billingobject = (data) => {
	return {
		type: actionTypes.ENCOUNTER_BILLING_DATA,
		payload: data,
	};
};

export const gotBillingEncounterDetailError = (err) => {
	return {
		type: actionTypes.ENCOUNTER_BILLING_DATA_ERROR,
		payload: err,
	};
};

export function clearencounterdetail() {
	return (dispatch) => {
		dispatch(encounterDetail({}));
	};
}

export const LoadEncounterDetailTestEnumsSuccess = (data) => {
	return {
		type: actionTypes.LOAD_ENCOUNTER_DETAIL_TEST_ENUM_SUCCESS,
		payload: data,
	};
};

export const startLoadEncounterDetailTestEnums = () => {
	return { type: actionTypes.LOAD_ENCOUNTER_DETAIL_TEST_ENUM_START };
};

export const LoadEncounterDetailTestEnumsFailure = (error) => {
	return {
		type: actionTypes.LOAD_ENCOUNTER_DETAIL_TEST_ENUM_FAILURE,
		payload: error,
	};
};

export function LoadEncounterDetailTestEnums(labuuid, params = {}) {
	return (dispatch) => {
		dispatch(startLoadEncounterDetailTestEnums());
		return EncounterService.getTestNameEnums(labuuid, params)
			.then((res) => {
				const tests = get(res, ['data', 'data'], {});
				dispatch(LoadEncounterDetailTestEnumsSuccess(tests));
			})
			.catch((err) => {
				dispatch(LoadEncounterDetailTestEnumsFailure(err));
			});
	};
}

export const startLoadAlternateTestEnums = () => {
	return { type: actionTypes.LOAD_ALTERNATE_TEST_ENUM_START };
};

export const LoadAlternateTestEnumsSuccess = (data) => {
	return {
		type: actionTypes.LOAD_ALTERNATE_TEST_ENUM_SUCCESS,
		payload: data,
	};
};

export const LoadAlternateTestEnumsFailure = (error) => {
	return {
		type: actionTypes.LOAD_ALTERNATE_TEST_ENUM_FAILURE,
		payload: error,
	};
};

export function LoadAlternateTestEnums(labuuid) {
	return (dispatch) => {
		dispatch(startLoadAlternateTestEnums());
		return EncounterService.getTestNameEnums(labuuid)
			.then((res) => {
				const tests = get(res, ['data', 'data'], {});
				dispatch(LoadAlternateTestEnumsSuccess(tests));
			})
			.catch((err) => {
				dispatch(LoadAlternateTestEnumsFailure(err));
			});
	};
}

export const startLoadEditTestOrderTestEnums = () => {
	return { type: actionTypes.LOAD_EDIT_TESTORDER_TEST_ENUM_START };
};

export const LoadEditTestOrderTestEnumsSuccess = (data) => {
	return {
		type: actionTypes.LOAD_EDIT_TESTORDER_TEST_ENUM_SUCCESS,
		payload: data,
	};
};

export const LoadEditTestOrderTestEnumsFailure = (error) => {
	return {
		type: actionTypes.LOAD_EDIT_TESTORDER_TEST_ENUM_FAILURE,
		payload: error,
	};
};

export function LoadEditTestOrderTestEnums(labuuid) {
	return (dispatch) => {
		dispatch(startLoadEditTestOrderTestEnums());
		return EncounterService.getTestNameEnums(labuuid)
			.then((res) => {
				const tests = get(res, ['data', 'data'], {});
				dispatch(LoadEditTestOrderTestEnumsSuccess(tests));
			})
			.catch((err) => {
				dispatch(LoadEditTestOrderTestEnumsFailure(err));
			});
	};
}

export function getICDCodes(query, offset, limit, topOrBottom) {
	return (dispatch) => {
		dispatch(gettingICDCodes(topOrBottom));
		EncounterService.getICDCodes(query, offset, limit)
			.then((res) => {
				dispatch(
					gotICDCodes(
						get(res, 'data', { data: [] }) || { data: [] },
						topOrBottom
					)
				);
			})
			.catch((err) => {
				dispatch(gotICDCodesError(err, topOrBottom));
			});
	};
}

export const gettingICDCodes = (topOrBottom) => {
	return {
		type: actionTypes.GETTING_ICD_CODES,
		payload: true,
		placement: topOrBottom,
	};
};

export const gotICDCodes = (data, topOrBottom) => {
	return {
		type: actionTypes.GOT_ICD_CODES,
		payload: data,
		placement: topOrBottom,
	};
};

export const gotICDCodesError = (err, topOrBottom) => {
	return {
		type: actionTypes.GOT_ICD_CODES_ERROR,
		payload: err,
		placement: topOrBottom,
	};
};

export const encounterReclaimStart = () => {
	return {
		type: actionTypes.ENCOUNTER_RECLAIM_START,
	};
};

export const encounterReclaimSuccess = (data) => {
	return {
		type: actionTypes.ENCOUNTER_RECLAIM_SUCCESS,
		payload: data,
	};
};

export const encounterReclaimFailure = (err) => {
	return {
		type: actionTypes.ENCOUNTER_RECLAIM_FAILURE,
		payload: err,
	};
};

export const encounterReclaimReset = () => {
	return {
		type: actionTypes.ENCOUNTER_RECLAIM_RESET,
	};
};

export function encounterreclaim(encounter_uuid) {
	return (dispatch) => {
		dispatch(encounterReclaimStart());
		EncounterService.reClaim(encounter_uuid)
			.then((res) => {
				dispatch(encounterReclaimSuccess(res));
			})
			.catch((err) => {
				const error = _.get(err, ['response', 'data', 'data'], {});
				dispatch(encounterReclaimFailure(error));
			});
	};
}

export const preauthorizationsEnums = (data) => {
	return {
		type: actionTypes.PREAUTHORIZATION_ENUMS,
		payload: data,
	};
};

export const getPreauthorizationsEnums = () => {
	return (dispatch) => {
		EncounterService.getPreauthorizationsEnums()
			.then((res) => {
				dispatch(preauthorizationsEnums(res.data));
			})
			.catch(() => {});
	};
};

export function preauthorizationsTestOrder(encounter_uuid, data) {
	return EncounterService.preauthorizationsTestOrder(encounter_uuid, data);
}

export const preauthTestOrdersStart = () => {
	return {
		type: actionTypes.LOADING_PRE_AUTH_TEST_ORDERS,
	};
};

export const preauthTestOrdersSuccess = (data) => {
	return {
		type: actionTypes.LOADED_PRE_AUTH_TEST_ORDERS,
		payload: data,
	};
};

export const preauthTestOrdersFailure = (err) => {
	return {
		type: actionTypes.ERROR_PRE_AUTH_TEST_ORDERS,
		payload: err,
	};
};

export function getPreauthorizationTestOrders(encounter_uuid) {
	return (dispatch) => {
		dispatch(preauthTestOrdersStart());
		EncounterService.getPreauthorizationTestOrders(encounter_uuid)
			.then((res) => {
				dispatch(
					preauthTestOrdersSuccess(_.get(res, ['data', 'data'], []))
				);
			})
			.catch((error) => {
				dispatch(preauthTestOrdersFailure(error));
			});
	};
}

export function createTestOrderRequest(encounter_uuid, data, grouped) {
	return EncounterService.addTestOrderRequest(encounter_uuid, data, grouped);
}

export const testOrderRequestStart = () => {
	return {
		type: actionTypes.LOADING_TEST_ORDER_REQUEST,
	};
};

export const testOrderRequestSuccess = (data) => {
	return {
		type: actionTypes.LOADED_TEST_ORDER_REQUEST,
		payload: data,
	};
};

export const testOrderRequestFailure = (err) => {
	return {
		type: actionTypes.ERROR_TEST_ORDER_REQUEST,
		payload: err,
	};
};

export function getTestOrderRequest(encounter_uuid, doClear = true) {
	return (dispatch) => {
		if (doClear) {
			dispatch(testOrderRequestStart());
		}
		EncounterService.getTestOrderRequest(encounter_uuid)
			.then((res) => {
				dispatch(
					testOrderRequestSuccess(
						hydrateTestOrders(_.get(res, ['data', 'data'], []))
					)
				);
			})
			.catch((error) => {
				dispatch(testOrderRequestFailure(error));
			});
	};
}

export function resetTestOrder() {
	return (dispatch) => {
		dispatch(testOrderRequestStart());
	};
}

const loadingGeneTestOrderEnums = () => {
	return {
		type: actionTypes.LOADING_GENE_TEST_ORDER_ENUMS,
	};
};

export const genetestorderenums = (data) => {
	return {
		type: actionTypes.GENE_TEST_ORDER_ENUMS,
		payload: data,
	};
};

const geneTestOrderEnumsError = (err) => {
	return {
		type: actionTypes.GENE_TEST_ORDER_ENUMS_ERROR,
		payload: err,
	};
};

export function getGeneTestEnums() {
	return (dispatch) => {
		dispatch(loadingGeneTestOrderEnums());
		EncounterService.getGeneTestEnums()
			.then((data) => {
				dispatch(genetestorderenums(_.get(data, ['data'], {})));
			})
			.catch((err) => {
				dispatch(geneTestOrderEnumsError(err));
			});
	};
}

//Search for SCP Test Authorization
export const scpTestSearch = (data) => {
	return (dispatch) => {
		dispatch(scpTestAuthSearching());
		EncounterService.scpTestAuthorizationSearch(data)
			.then((res) => {
				dispatch(scpTestAuthSearch(get(res, 'data', {}) || {}));
			})
			.catch((err) => {
				dispatch(scpTestAuthSearchError(err));
			});
	};
};

export const scpTestAuthSearching = () => {
	return {
		type: actionTypes.SCP_TEST_AUTH_SEARCHING,
		payload: true,
	};
};

export const scpTestAuthSearch = (data) => {
	return {
		type: actionTypes.SCP_TEST_AUTH_SEARCH,
		payload: data,
	};
};

export const scpTestAuthSearchError = (err) => {
	return {
		type: actionTypes.SCP_TEST_AUTH_SEARCH_ERROR,
		payload: err,
	};
};

//Bulk update encounters for Test Authorization
export const testBulkUpdate = (data, is_md_auth) => {
	return (dispatch) => {
		dispatch(testBulkUpdating());
		return EncounterService.testBulkUpdate(data, is_md_auth)
			.then((res) => {
				return dispatch(testBulkUpdated(get(res, 'data', {}) || {}));
			})
			.catch((err) => {
				dispatch(testBulkUpdateError(err));
				throw err;
			});
	};
};

export const testBulkUpdating = () => {
	return {
		type: actionTypes.TEST_BULK_UPDATING,
		payload: true,
	};
};

export const testBulkUpdated = (data) => {
	return {
		type: actionTypes.TEST_BULK_UPDATE,
		payload: data,
	};
};

export const testBulkUpdateError = (err) => {
	return {
		type: actionTypes.TEST_BULK_UPDATE_ERROR,
		payload: err,
	};
};

// Update a gene test order given its gene test order uuid
export function updateTestOrder(test_order_uuid, data) {
	return (dispatch) => {
		dispatch(testOrderUpdating());
		return EncounterService.updateTestOrder(test_order_uuid, data)
			.then((res) => {
				dispatch(testOrderUpdated(get(res, 'data', {}) || {}));
				return res;
			})
			.catch((err) => {
				dispatch(testOrderUpdateError(err));
				throw err;
			});
	};
}

export const testOrderUpdating = () => {
	return {
		type: actionTypes.TEST_ORDER_UPDATING,
		payload: true,
	};
};

export const testOrderUpdated = (data) => {
	return {
		type: actionTypes.TEST_ORDER_UPDATE,
		payload: data,
	};
};

export const testOrderUpdateError = (err) => {
	return {
		type: actionTypes.TEST_ORDER_UPDATE_ERROR,
		payload: err,
	};
};

//Get gene test order status enum for Test Authorization view
export function getGeneTestOrderStatusEnum() {
	return (dispatch) => {
		dispatch(gettingGeneTestOrderStatusEnum());
		return EncounterService.getGeneTestOrderStatusEnum()
			.then((res) => {
				dispatch(
					gotGeneTestOrderStatusEnum(
						get(res, 'data.data.gene_test_order_status', []) || []
					)
				);
			})
			.catch((err) => {
				dispatch(gotGeneTestOrderStatusEnumError(err));
			});
	};
}

export const gettingGeneTestOrderStatusEnum = () => {
	return {
		type: actionTypes.GETTING_GENE_TEST_ORDER_STATUS_ENUM,
		payload: true,
	};
};

export const gotGeneTestOrderStatusEnum = (data) => {
	return {
		type: actionTypes.GOT_GENE_TEST_ORDER_STATUS_ENUM,
		payload: data,
	};
};

export const gotGeneTestOrderStatusEnumError = (err) => {
	return {
		type: actionTypes.GOT_GENE_TEST_ORDER_STATUS_ENUM_ERROR,
		payload: err,
	};
};

// Update preauthorization test order given its preauth uuid
export function updatePreAuthTestOrder(preauth_test_order_uuid, data) {
	return EncounterService.updatePreAuthTestOrder(
		preauth_test_order_uuid,
		data
	);
}

//create a new note for a gene test order based on the gene_test_order_uuid provided
export function createTestOrderNote(test_order_uuid, data) {
	return EncounterService.addTestOrderNote(test_order_uuid, data);
}

export const testOrderNotesStart = () => {
	return {
		type: actionTypes.LOADING_TEST_ORDER_NOTES,
	};
};

export const testOrderNotesSuccess = (data) => {
	return {
		type: actionTypes.LOADED_TEST_ORDER_NOTES,
		payload: data,
	};
};

export const testOrderNotesFailure = (err) => {
	return {
		type: actionTypes.ERROR_TEST_ORDER_NOTES,
		payload: err,
	};
};

export function getTestOrderNotes(test_order_uuid) {
	return (dispatch) => {
		dispatch(testOrderNotesStart());
		EncounterService.getTestOrderNotes(test_order_uuid)
			.then((res) => {
				dispatch(
					testOrderNotesSuccess(_.get(res, ['data', 'data'], []))
				);
			})
			.catch((error) => {
				dispatch(testOrderNotesFailure(error));
			});
	};
}

//Creating test order flags
export function createTestOrderFlags(testOrderUuid, payload) {
	return (dispatch) => {
		dispatch(creatingTestOrderFlags());
		EncounterService.createTestOrderFlags(testOrderUuid, payload)
			.then((res) => {
				dispatch(createdTestOrderFlags(_.get(res, 'data', {})));
			})
			.catch((err) => {
				dispatch(createdTestOrderFlagsError(err));
			});
	};
}

export const creatingTestOrderFlags = () => {
	return {
		type: actionTypes.CREATING_TEST_ORDER_FLAGS,
		payload: true,
	};
};

export const createdTestOrderFlags = (data) => {
	return {
		type: actionTypes.CREATED_TEST_ORDER_FLAGS,
		payload: data,
	};
};

export const createdTestOrderFlagsError = (err) => {
	return {
		type: actionTypes.CREATED_TEST_ORDER_FLAGS_ERROR,
		payload: err,
	};
};

//Updating test order flags
export function updateTestOrderFlags(testOrderUuid, payload) {
	return (dispatch) => {
		dispatch(updatingTestOrderFlags());
		EncounterService.updateTestOrderFlags(testOrderUuid, payload)
			.then((res) => {
				dispatch(updatedTestOrderFlags(_.get(res, 'data', {})));
			})
			.catch((err) => {
				dispatch(updatedTestOrderFlagsError(err));
			});
	};
}

export const updatingTestOrderFlags = () => {
	return {
		type: actionTypes.UPDATING_TEST_ORDER_FLAGS,
		payload: true,
	};
};

export const updatedTestOrderFlags = (data) => {
	return {
		type: actionTypes.UPDATED_TEST_ORDER_FLAGS,
		payload: data,
	};
};

export const updatedTestOrderFlagsError = (err) => {
	return {
		type: actionTypes.UPDATED_TEST_ORDER_FLAGS_ERROR,
		payload: err,
	};
};

//Querying audit trail for encounters
export function getAuditTrailEncounter(encounterUuid, payload = {}) {
	return (dispatch) => {
		dispatch(getAuditTrailEncounterStart());
		EncounterService.getAuditTrailEncounter(encounterUuid, payload)
			.then((res) => {
				dispatch(
					getAuditTrailEncounterSuccess(
						_.get(res, ['data', 'data'], [])
					)
				);
			})
			.catch((err) => {
				dispatch(getAuditTrailEncounterError(err));
			});
	};
}

export const getAuditTrailEncounterStart = () => {
	return {
		type: actionTypes.GET_AUDIT_TRAIL_ENCOUNTER_START,
		payload: true,
	};
};

export const getAuditTrailEncounterSuccess = (data) => {
	return {
		type: actionTypes.GET_AUDIT_TRAIL_ENCOUNTER_SUCCESS,
		payload: data,
	};
};

export const getAuditTrailEncounterError = (err) => {
	return {
		type: actionTypes.GET_AUDIT_TRAIL_ENCOUNTER_ERROR,
		payload: err,
	};
};

//Enum for encounter audit trail
export function getEncounterAuditEnum() {
	return (dispatch) => {
		dispatch(getEncounterAuditEnumStart());
		EncounterService.getEncounterAuditEnum()
			.then((res) => {
				dispatch(
					getEncounterAuditEnumSuccess(
						_.get(res, ['data', 'data', 'encounter_fields'], [])
					)
				);
			})
			.catch((err) => {
				dispatch(getEncounterAuditEnumError(err));
			});
	};
}

export const getEncounterAuditEnumStart = () => {
	return {
		type: actionTypes.GET_ENCOUNTER_AUDIT_ENUM_START,
		payload: true,
	};
};

export const getEncounterAuditEnumSuccess = (data) => {
	return {
		type: actionTypes.GET_ENCOUNTER_AUDIT_ENUM_SUCCESS,
		payload: data,
	};
};

export const getEncounterAuditEnumError = (err) => {
	return {
		type: actionTypes.GET_ENCOUNTER_AUDIT_ENUM_ERROR,
		payload: err,
	};
};

//Enum for encounter audit trail - billing
export function getEncounterBillingEnum() {
	return (dispatch) => {
		dispatch(getEncounterBillingEnumStart());
		EncounterService.getEncounterBillingEnum()
			.then((res) => {
				dispatch(
					getEncounterBillingEnumSuccess(
						_.get(res, ['data', 'data', 'billing_fields'], [])
					)
				);
			})
			.catch((err) => {
				console.log(err);
				dispatch(getEncounterBillingEnumError(err));
			});
	};
}

const getEncounterBillingEnumStart = () => {
	return {
		type: actionTypes.GET_ENCOUNTER_BILLING_ENUM_START,
		payload: true,
	};
};

const getEncounterBillingEnumSuccess = (data) => {
	return {
		type: actionTypes.GET_ENCOUNTER_BILLING_ENUM_SUCCESS,
		payload: data,
	};
};

const getEncounterBillingEnumError = (err) => {
	return {
		type: actionTypes.GET_ENCOUNTER_BILLING_ENUM_ERROR,
		payload: err,
	};
};

const loadingAddManualVisitEncounter = () => {
	return {
		type: actionTypes.LOADING_ADD_MANUAL_VISIT_ENCOUNTER,
	};
};

const AddManualVisitEncounter = (data) => {
	return {
		type: actionTypes.ADD_MANUAL_VISIT_ENCOUNTER,
		payload: data,
	};
};

const AddManualVisitEncounterError = (err) => {
	return {
		type: actionTypes.ADD_MANUAL_VISIT_ENCOUNTER_ERROR,
		payload: err,
	};
};

//create manual visit encounter
export function addManualVisitEncouter(data) {
	return (dispatch) => {
		dispatch(loadingAddManualVisitEncounter());
		return EncounterService.addManualVisitEncouter(data)
			.then((res) => {
				dispatch(AddManualVisitEncounter(data));
				return res;
			})
			.catch((err) => {
				dispatch(AddManualVisitEncounterError(err));
				throw err;
			});
	};
}

//Content viewed by patient
export function getContentViewedByPatient(uuid) {
	return (dispatch) => {
		dispatch(getContentViewedByPatientStart());
		EncounterService.getContentViewedByPatient(uuid)
			.then((res) => {
				dispatch(
					getContentViewedByPatientSuccess(
						_.get(res, 'data.data', [])
					)
				);
			})
			.catch((err) => {
				dispatch(getContentViewedByPatientError(err));
			});
	};
}

export const getContentViewedByPatientStart = () => {
	return {
		type: actionTypes.GET_CONTENT_VIEWED_BY_PATIENT_START,
		payload: true,
	};
};

export const getContentViewedByPatientSuccess = (data) => {
	return {
		type: actionTypes.GET_CONTENT_VIEWED_BY_PATIENT_SUCCESSS,
		payload: data,
	};
};

export const getContentViewedByPatientError = (err) => {
	return {
		type: actionTypes.GET_CONTENT_VIEWED_BY_PATIENT_ERROR,
		payload: err,
	};
};

export const gettingEncounterCommunications = (data) => {
	return {
		type: actionTypes.LOAD_ENCOUNTER_COMMUNICATIONS_START,
		payload: data,
	};
};

export const getEncounterCommunicationsSuccess = (data) => {
	return {
		type: actionTypes.LOAD_ENCOUNTER_COMMUNICATIONS_SUCCESS,
		payload: data,
	};
};

export const failureEncounterCommunications = (data) => {
	return {
		type: actionTypes.LOAD_ENCOUNTER_COMMUNICATIONS_FAILURE,
		payload: data,
	};
};

//encounter communications
export function getOutreachs(encounter_uuid, doClear = true) {
	return (dispatch) => {
		if (doClear) {
			dispatch(gettingEncounterCommunications());
		}
		EncounterService.getOutreachs(encounter_uuid)
			.then((res) => {
				dispatch(getEncounterCommunicationsSuccess(res));
			})
			.catch(() => {
				dispatch(failureEncounterCommunications());
			});
	};
}

//FollowUp Declined Outreach
export function followUpDeclinedOutreach(encounterUuid, payload) {
	return EncounterService.followUpDeclinedOutreach(encounterUuid, payload);
}

//Link providers to encounter
const providersLinkingStart = () => {
	return {
		type: actionTypes.PROVIDERS_ENCOUNTER_LINKING_START,
	};
};
const providersLinkingSuccess = (payload) => {
	return {
		type: actionTypes.PROVIDERS_ENCOUNTER_LINKING_SUCCESS,
		payload,
	};
};
const providersLinkingError = (payload) => {
	return {
		type: actionTypes.PROVIDERS_ENCOUNTER_LINKING_ERROR,
		payload,
	};
};
export const linkProvidersEncounter = (data) => {
	return (dispatch) => {
		dispatch(providersLinkingStart());
		EncounterService.linkProviders(data)
			.then((res) => {
				dispatch(providersLinkingSuccess(get(res, 'data', [])));
			})
			.catch((err) => {
				dispatch(providersLinkingError(err));
			});
	};
};

//Unlink provider from encounter
const getUnlinkProviderStart = () => {
	return {
		type: actionTypes.GET_UNLINK_ENCOUNTER_PROVIDER_START,
	};
};
const getUnlinkProviderSuccess = (payload) => {
	return {
		type: actionTypes.GET_UNLINK_ENCOUNTER_PROVIDER_SUCCESS,
		payload,
	};
};
const getUnlinkProviderError = (payload) => {
	return {
		type: actionTypes.GET_UNLINK_ENCOUNTER_PROVIDER_ERROR,
		payload,
	};
};
export const unlinkProviderEncounter = (encounter_uuid, provider_uuid) => {
	return (dispatch) => {
		dispatch(getUnlinkProviderStart());
		EncounterService.unlinkProvider(encounter_uuid, provider_uuid)
			.then((res) => {
				dispatch(getUnlinkProviderSuccess(get(res, 'data', [])));
			})
			.catch((err) => {
				dispatch(getUnlinkProviderError(err));
			});
	};
};

//Get visit indications
export function getVisitIndications() {
	return (dispatch) => {
		dispatch(gettingVisitIndications());
		EncounterService.getVisitIndications()
			.then((res) => {
				dispatch(
					gotVisitIndications(
						formatVisitIndications(
							_.get(res, 'data.data', null) || []
						)
					)
				);
			})
			.catch((err) => {
				dispatch(gotVisitIndicationsError(err));
			});
	};
}

export const gettingVisitIndications = () => {
	return {
		type: actionTypes.GETTING_VISIT_INDICATIONS,
		payload: true,
	};
};

export const gotVisitIndications = (data) => {
	return {
		type: actionTypes.GOT_VISIT_INDICATIONS,
		payload: data,
	};
};

export const gotVisitIndicationsError = (err) => {
	return {
		type: actionTypes.GOT_VISIT_INDICATIONS_ERROR,
		payload: err,
	};
};

//update flag for Test order
export const updateTestOrderRedFlag = (gene_test_order_uuid, data) => {
	return (dispatch) => {
		dispatch(testOrderRedFlagUpdating());
		EncounterService.updateTestOrderRedFlag(gene_test_order_uuid, data)
			.then((res) => {
				dispatch(
					testOrderRedFlagUpdated(
						get(res, ['data', 'data'], {}) || {}
					)
				);
			})
			.catch((err) => {
				dispatch(testOrderRedFlagUpdateError(err));
			});
	};
};

export const testOrderRedFlagUpdating = () => {
	return {
		type: actionTypes.TEST_ORDER_RED_FLAG_UPDATING,
		payload: true,
	};
};

export const testOrderRedFlagUpdated = (data) => {
	return {
		type: actionTypes.TEST_ORDER_RED_FLAG_UPDATE,
		payload: data,
	};
};

export const testOrderRedFlagUpdateError = (err) => {
	return {
		type: actionTypes.TEST_ORDER_RED_FLAG_UPDATE_ERROR,
		payload: err,
	};
};

const getEncounterDocumentsLoading = () => {
	return {
		type: actionTypes.ENCOUNTER_DOCUMENTS_LOADING,
	};
};

const getEncounterDocumentsSuccess = (payload) => {
	return {
		type: actionTypes.ENCOUNTER_DOCUMENTS_SUCCESS,
		payload,
	};
};

const getEncounterDocumentsFailed = (payload) => {
	return {
		type: actionTypes.ENCOUNTER_DOCUMENTS_FAILED,
		payload,
	};
};

export const getEncounterDocuments = (encounter_uuid) => {
	return (dispatch) => {
		dispatch(getEncounterDocumentsLoading());
		EncounterService.getEncounterDocuments(encounter_uuid)
			.then((res) => {
				dispatch(
					getEncounterDocumentsSuccess(get(res, ['data', 'data'], []))
				);
			})
			.catch((err) => {
				dispatch(getEncounterDocumentsFailed(err));
			});
	};
};
