//React & Redux
import React from 'react';

//Components
import Tag from './Tag.js';

const TagSelected = (props) => {
	const { collection, selectedICDCodes = [], toggleCodeSelection } = props;

	return (
		<div className='chargeICDSelectedContainer'>
			<div className='chargeICDSelectedContainerHeader'>SELECTED</div>
			{selectedICDCodes.map((el) => {
				return (
					<Tag
						{...{
							collection,
							hasX: true,
							icdCode: el,
							key: el.partner_id,
							toggleCodeSelection,
						}}
					/>
				);
			})}
		</div>
	);
};

export default TagSelected;
