import * as actionTypes from './actionTypes';
import nucleotideRepeatVariantService from '../service/NucleotideRepeatVariant';
import { deHydrateNucleotideRepeatVariant } from '../transformers/nucleotideRepeatVariantTransformer';

const addNucleotideRepeatVariantFindingLoading = () => {
	return {
		type: actionTypes.ADD_NUCLEOTIDEREPEATVARIANT_FINDING_LOADING,
	};
};

const addNucleotideRepeatVariantFindingSuccess = (payload) => {
	return {
		type: actionTypes.ADD_NUCLEOTIDEREPEATVARIANT_FINDING_SUCCESS,
		payload,
	};
};

const addNucleotideRepeatVariantFindingError = (payload) => {
	return {
		type: actionTypes.ADD_NUCLEOTIDEREPEATVARIANT_FINDING_ERROR,
		payload,
	};
};

export function addNucleotideRepeatVariantFinding(data, diagnosticReportId) {
	return (dispatch) => {
		dispatch(addNucleotideRepeatVariantFindingLoading());
		return nucleotideRepeatVariantService
			.addNucleotideRepeatVariantFinding(
				deHydrateNucleotideRepeatVariant(data),
				diagnosticReportId
			)
			.then((res) => {
				dispatch(
					addNucleotideRepeatVariantFindingSuccess(
						res?.data?.addDiagnosticFinding?.finding?.id || ''
					)
				);
			})
			.catch((err) => {
				dispatch(addNucleotideRepeatVariantFindingError(err));
			});
	};
}

export function createDiagnosticReportAndAddNucleotideRepeatVariantFinding(
	data,
	patientId,
	testOrderId
) {
	return (dispatch) => {
		dispatch(addNucleotideRepeatVariantFindingLoading());
		return nucleotideRepeatVariantService
			.createDiagnosticReport(patientId, testOrderId)
			.then((res) => {
				const diagnosticReportId =
					res?.data?.addDiagnosticReport?.diagnosticReport?.id ||
					null;
				dispatch(
					addNucleotideRepeatVariantFinding(data, diagnosticReportId)
				);
			})
			.catch((err) => {
				dispatch(addNucleotideRepeatVariantFindingError(err));
			});
	};
}

export function updateNucleotideRepeatVariantFinding(data) {
	return (dispatch) => {
		dispatch(addNucleotideRepeatVariantFindingLoading());
		return nucleotideRepeatVariantService
			.updateNucleotideRepeatVariantFinding(
				deHydrateNucleotideRepeatVariant(data),
				data?.uuid || null
			)
			.then((res) => {
				dispatch(
					addNucleotideRepeatVariantFindingSuccess(
						res?.data?.updateDiagnosticFinding?.finding?.id || ''
					)
				);
			})
			.catch((err) => {
				dispatch(addNucleotideRepeatVariantFindingError(err));
			});
	};
}
