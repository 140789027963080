//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Other Libraries
import moment from 'moment';

//Components
import AuditTrailHistory from './AuditTrailHistory.js';
import AuditTrailHistoryInitialPrompt from './AuditTrailHistoryInitialPrompt.js';
import { SelectComponent as Select } from '../../../../Common/SelectComponent';

class AuditTrailData extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.filterEncounterAuditHistory =
			this.filterEncounterAuditHistory.bind(this);
	}
	//functions

	getChangeByUser = (obj) => {
		if (!get(obj, 'is_changed_by_patient')) {
			const allUsers = get(this.props, 'allUsers', []);
			const changedByUser = (allUsers || []).find(
				(el) => el && el.uuid == obj.changed_by
			);
			return get(changedByUser, 'email', '');
		} else {
			return get(this.props, ['patient', 'email'], '');
		}
	};

	filterEncounterAuditHistory() {
		const isVisitStatus =
			get(this, 'props.currentField', null) == 'visit_status';
		let auditHistory = [];
		if (isVisitStatus) {
			if (get(this.props, ['allUsers'], false)) {
				const visitStatusHistory = [
					...get(
						this.props,
						['encounter', 'visit_status_history'],
						[]
					),
				];
				const sortedVisitStatusHistory = visitStatusHistory.sort(
					(a, b) => {
						return (
							moment(b.changed_at).toDate().getTime() -
							moment(a.changed_at).toDate().getTime()
						);
					}
				);
				return (sortedVisitStatusHistory || []).map((el) => {
					return {
						created_at: el.changed_at,
						created_by: el.changed_by,
						username: this.getChangeByUser(el),
						changes: [
							{
								attribute: 'visit_status',
								new_value: el.visit_status,
							},
						],
						object: 'Encounters',
						object_uuid: el.encounter_uuid,
						service_type: 'edit',
					};
				});
			}
		} else {
			auditHistory = get(this, 'props.auditTrailEncounter', []) || [];
		}
		const currentField = get(this, 'props.currentField', null);
		if (Array.isArray(auditHistory) && currentField) {
			return auditHistory.filter((el) => get(el, 'changes.length', 0));
		} else return [];
	}

	isVisitEncounter = () => {
		const encounterType = get(this.props, ['encounter', 'type'], '');
		return ['visit'].includes(encounterType);
	};

	getFieldValues = (encounter) => {
		if (encounter == 'encounterAuditEnum' && this.isVisitEncounter()) {
			const values = get(this, ['props', encounter], []);
			return [
				...values,
				{ display_name: 'Visit Status', key: 'visit_status' },
			];
		} else {
			return get(this, ['props', encounter], []);
		}
	};

	render() {
		const hadSuccessfulCall = get(this, 'props.hadSuccessfulCall', false);
		const auditTrailEncounterLoading = get(
			this,
			'props.auditTrailEncounterLoading',
			null
		);
		const encounter = get(this, 'props.encounterField', null);
		return (
			<div className='auditTrailDataContainer'>
				<div
					className='auditTrailSelectContainer'
					style={{ marginBottom: '20px' }}
				>
					<div className='auditTrailSelectRequired'>*</div>
					<div className='auditTrailSelectTitle'>
						Encounter Section
					</div>
					<Select
						value={encounter}
						placeholder={'Select'}
						onChange={this.props.changeEncounterField}
						options={[
							{
								key: 'encounterAuditEnum',
								value: 'encounterAuditEnum',
								label: 'Visit Details',
							},
							{
								key: 'encounterBillingEnum',
								value: 'encounterBillingEnum',
								label: 'Billing',
							},
						]}
						typeahead={true}
						clearable={true}
					/>
				</div>
				{encounter && (
					<div className='auditTrailSelectContainer'>
						<div className='auditTrailSelectRequired'>*</div>
						<div className='auditTrailSelectTitle'>
							Select A Field
						</div>
						<Select
							value={get(this, 'props.currentField', null)}
							placeholder={'Select'}
							onChange={this.props.changeCurrentField}
							options={this.getFieldValues(encounter).map(
								(el) => {
									return {
										key: el.key,
										label: el.display_name,
										value: el.key,
									};
								}
							)}
							typeahead={true}
							clearable={true}
						/>
					</div>
				)}
				{hadSuccessfulCall || auditTrailEncounterLoading ? (
					<AuditTrailHistory
						auditTrailEncounter={
							this.filterEncounterAuditHistory() || []
						}
						currentField={get(this, 'props.currentField', null)}
						encounterCreationDate={get(
							this,
							'props.encounterCreationDate',
							null
						)}
						encounter={get(this, 'props.encounter', {}) || {}}
						auditTrailEncounterLoading={auditTrailEncounterLoading}
						encounterAuditEnumLoading={get(
							this,
							'props.encounterAuditEnumLoading',
							null
						)}
						relevantEncounterEnum={
							get(this, 'props.relevantEncounterEnum', {}) || {}
						}
					/>
				) : (
					<AuditTrailHistoryInitialPrompt />
				)}
			</div>
		);
	}
}

export default AuditTrailData;
