//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from '../../../assets/exit-alert.svg';

const AuditTrailTitleRow = ({ handleAuditTrail }) => {
	return (
		<div className='auditTrailTitleRow'>
			<span>Audit Trail - Patient Info</span>
			<div
				className='auditTrailClose'
				style={{
					top: '-1px',
					transform: 'scale(1.25)',
					borderTopRightRadius: '4px',
				}}
			>
				<ReactSVG
					src={xIcon}
					style={{
						position: 'relative',
						bottom: '-8px',
						right: '-9px',
					}}
					onClick={handleAuditTrail}
				/>
			</div>
		</div>
	);
};

export default AuditTrailTitleRow;
