//React & Redux
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

//Lodash
import { debounce, isEmpty } from 'lodash';

//UI Libraries
import { Loading, Select, Input, Button } from 'gm-element-react';

//Utils
import {
	documentDescriptionValidation,
	encodeHTML,
} from '../../../../Utils/documentUtils.js';

//Components
import ErrorBoundary from '../../../Common/ErrorBoundary';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious.js';

const ShareDocument = (props) => {
	const {
		clearUsers,
		doShareDocument,
		document,
		hideShareDocument,
		loadUsersWithValidRoles,
		sharingDocument,
		userQuery,
		users = [],
		usersLoading,
	} = props;

	const [descriptionError, setDescriptionError] = useState(false);
	const [formdata, setFormdata] = useState({ message: '', recipients: [] });
	const [loading, setLoading] = useState(false);
	const [query, setQuery] = useState(undefined);

	const prevUsersLoading = usePrevious(usersLoading);

	useEffect(() => {
		return () => clearUsers;
	}, []);

	useEffect(() => {
		if (prevUsersLoading && !usersLoading) {
			userQuery == query && setLoading(false);
		}
	}, [usersLoading]);

	useEffect(() => {
		if (!documentDescriptionValidation(formdata.message)) {
			setDescriptionError(true);
		} else {
			setDescriptionError(false);
		}
	}, [formdata.message]);

	const debouncedLoadUsers = debounce(loadUsersWithValidRoles, 500);

	const shareDoc = () => {
		if (
			isEmpty(validateForm(formdata)) &&
			!!documentDescriptionValidation(formdata.message)
		) {
			doShareDocument(document?.uuid, {
				data: [
					{
						type: 'internal',
						message: encodeHTML(formdata.message),
						user_uuid: formdata.recipients,
					},
				],
			});
		}
	};

	const onFormChange = (key, value) => {
		setDescriptionError(!documentDescriptionValidation(formdata.message));
		setFormdata((state) => ({
			...state,
			[key]: value,
		}));
	};

	const validateForm = (formdata) => {
		const errors = {};
		if (formdata.recipients.length <= 0) {
			errors.recipients = 'Recipients are required.';
		}
		return errors;
	};

	const onSearch = (query) => {
		if (query !== '') {
			debouncedLoadUsers(query);
			setTimeout(() => {
				setLoading(true);
				setQuery(query);
			}, 500);
		}
	};

	return (
		<div className='fullModalBackground'>
			<div className='shareDocumentContainer'>
				{sharingDocument ? (
					<Loading loading={true} style={{ position: 'unset' }} />
				) : (
					<>
						<div className='shareDocumentTopRow'>
							<div className='shareDocumentHeader'>
								<div className='shareDocumentTitle'>
									Share Internally
								</div>
								<div className='sharedocumentname'>
									{document?.file_name || ''}
								</div>
							</div>
							<div className='shareDocumentClose'>
								<i
									className='el-dialog__close el-icon el-icon-close'
									onClick={hideShareDocument}
								></i>
							</div>
						</div>

						<div className='shareDocumentBodySection'>
							<div className='sharebodysectioncol'>
								<div className='sharedocumentinputcontainer'>
									<div className='sharedocumentInputHeaderContainer'>
										<div className='shareDocumentRequired'>
											*
										</div>
										<div className='shareDocumentInputHeader'>
											Recipients
										</div>
									</div>
									<ErrorBoundary>
										<Select
											className={
												'shareDocumentmultiSelect'
											}
											placeholder={'Select'}
											multiple={true}
											filterable={true}
											remote={true}
											remoteMethod={(query) =>
												onSearch(query)
											}
											onChange={(value) =>
												onFormChange(
													'recipients',
													value
												)
											}
											value={''}
											loading={
												users.length == 0 && loading
													? true
													: false
											}
										>
											{users.map((user) => (
												<Select.Option
													key={user.uuid}
													value={user.uuid}
													label={
														user.first_name +
														' ' +
														user.last_name
													}
												>
													{user.first_name +
														' ' +
														user.last_name}
												</Select.Option>
											))}
										</Select>
									</ErrorBoundary>
								</div>
							</div>
							<div className='sharebodysectioncol share-not-mb16'>
								<div className='sharedocumentinputcontainer'>
									<div className='shareDocumentInputHeader'>
										Enter a Message
									</div>
									<Input
										type='textarea'
										autosize={{ minRows: 4, maxRows: 4 }}
										placeholder='Enter a message'
										value={formdata?.message || ''}
										onChange={(value) =>
											onFormChange('message', value)
										}
									/>
									{descriptionError && (
										<div
											className='inputFieldError'
											style={{ position: 'inherit' }}
										>
											Invalid description
										</div>
									)}
								</div>
							</div>
							<div className='sharebodysectioncol'>
								<div className='note'>
									{' '}
									A direct link for this document will be
									emailed to the recipients.
								</div>
							</div>
						</div>
						<div className='shareDocumentFooterSection'>
							<div className='sharebodysectioncol'>
								<div className='sharedocumentinputcontainer'>
									<Button
										data-testId='share-btn'
										className='sharebtn'
										onClick={shareDoc}
										disabled={
											descriptionError ||
											!isEmpty(validateForm(formdata))
										}
									>
										Share
									</Button>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

ShareDocument.propTypes = {
	clearUsers: PropTypes.func.isRequired,
	hideShareDocument: PropTypes.func.isRequired,
	loadUsersWithValidRoles: PropTypes.func.isRequired,
	userQuery: PropTypes.string,
	users: PropTypes.array,
	usersLoading: PropTypes.bool.isRequired,
};

export default ShareDocument;
