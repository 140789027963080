import classnames from 'classnames';
import Select, { components, SingleValue, MultiValue } from 'react-select';

import { FormField, IFormFieldProps } from './FormField';

import './Dropdown.scss';

interface IOptions<T> {
	label: string;
	value: T;
}

interface IProps<T> extends IFormFieldProps {
	disabled?: boolean;
	value?: T;
	onChange: (value: (T | null) | T[]) => void;
	placeholder?: string;
	multiple?: boolean;
	options: IOptions<T>[];
	loading?: boolean;
	clearable?: boolean;
}

/* eslint-disable */
const DropdownIndicator = (props: any) => {
	return (
		<components.DropdownIndicator {...props}>
			<i
				style={{
					fontSize: '12px',
					transform: 'rotate(180deg)',
					position: 'unset',
				}}
				className='el-icon-caret-top '
			/>
		</components.DropdownIndicator>
	);
};

/* eslint-disable */
const ClearIndicator = (props: any) => {
	return (
		<components.ClearIndicator {...props}>
			<i
				style={{
					fontSize: '12px',
					position: 'unset',
				}}
				className='el-icon-circle-close '
			/>
		</components.ClearIndicator>
	);
};

export function Dropdown<T>(props: IProps<T>) {
	const {
		className,
		name,
		label,
		required,
		error,
		tooltip,
		value,
		onChange,
		disabled,
		placeholder,
		multiple,
		options,
		loading,
		clearable,
	} = props;
	let selectedOption;
	if (multiple) {
		selectedOption =
			options?.filter((o) => String(value)?.includes(String(o.value))) ??
			[];
	} else {
		selectedOption = options?.find((o) => o.value === props.value) ?? null;
	}
	return (
		<FormField
			className={classnames('gm-dropdown', className)}
			name={name}
			label={label}
			required={required}
			error={error}
			tooltip={tooltip}
		>
			<Select
				menuPlacement='auto'
				components={{
					IndicatorSeparator: null,
					ClearIndicator,
					DropdownIndicator,
				}}
				classNames={{
					control: (state) =>
						classnames('control', {
							focused: state.isFocused,
							disabled: state.isDisabled,
						}),
					placeholder: () => 'placeholder',
					clearIndicator: () => 'clear-indicator',
				}}
				options={options}
				isLoading={loading}
				isClearable={clearable}
				isDisabled={disabled}
				isMulti={multiple}
				onChange={(v) => {
					if (multiple) {
						return onChange(
							(v as MultiValue<IOptions<T>>).map(
								(item: { value: any }) => item.value
							)
						);
					}
					onChange((v as SingleValue<IOptions<T>>)?.value ?? null);
				}}
				placeholder={placeholder}
				name={name}
				value={selectedOption}
			/>
		</FormField>
	);
}
