import DashboardService from '../service/Dashboard';
import EncounterService from '../service/Encounter';

import * as actionTypes from './actionTypes';

import get from 'lodash/get';

import { hydratePatientsValues } from '../transformers/patients/patientTransformer';
import { callAttemptsFieldOptions } from './../Utils/dashboardUtils';

const loadingDashboard = () => {
	return {
		type: actionTypes.LOADING_DASHBOARD,
	};
};

export const dashboard = (data) => {
	return {
		type: actionTypes.DASHBOARD,
		payload: data,
	};
};

const dashboardError = (err) => {
	return {
		type: actionTypes.DASHBOARD_ERROR,
		payload: err,
	};
};

export const gettingDashboardEncounters = () => {
	return {
		type: actionTypes.GETTING_DASHBOARD_ENCOUNTERS,
		payload: true,
	};
};

export const dashboardencounters = (data) => {
	return {
		type: actionTypes.DASHBOARD_ENCOUNTERS,
		payload: data,
	};
};

export const DashboardEncountersError = (err) => {
	return {
		type: actionTypes.DASHBOARD_ENCOUNTERS_ERROR,
		payload: err,
	};
};

export const gettingDashboardPatients = () => {
	return {
		type: actionTypes.GETTING_DASHBOARD_PATIENTS,
		payload: true,
	};
};

export const dashboardpatients = (data) => {
	return {
		type: actionTypes.DASHBOARD_PATIENTS,
		payload: data,
	};
};

const loadingUpcomingappointments = () => {
	return {
		type: actionTypes.LOADING_UPCOMING_APPOINTMENTS,
	};
};

export const upcomingappointments = (data) => {
	return {
		type: actionTypes.UPCOMING_APPOINTMENTS,
		payload: data,
	};
};

const upcomingappointmentsError = (err) => {
	return {
		type: actionTypes.UPCOMING_APPOINTMENTS_ERROR,
		payload: err,
	};
};

export const dashboardoutreachattempts = (data) => {
	return {
		type: actionTypes.BULK_UPDATE_ATTEMPTS,
		payload: data,
	};
};

export const popReports = (data) => {
	return {
		type: actionTypes.DASHBOARD_POP_REPORTS,
		payload: data,
	};
};

export const updateStatus = (data) => {
	return {
		type: actionTypes.BULK_UPDATE_STATUS,
		payload: data,
	};
};

export const resetupdateStatus = (data) => {
	return {
		type: actionTypes.RESET_BULK_UPDATE,
		payload: data,
	};
};

export const popmodality = (data) => {
	return {
		type: actionTypes.GET_POP_MODALITY,
		payload: data,
	};
};

export const loadingUpdatesurveystatus = () => {
	return {
		type: actionTypes.LOADING_UPDATE_SATISTACTION_SURVEY,
	};
};

export const updatesurveystatus = (data) => {
	return {
		type: actionTypes.UPDATE_SATISTACTION_SURVEY,
		payload: data,
	};
};

export const updatesurveyStatusError = (err) => {
	return {
		type: actionTypes.UPDATE_SATISTACTION_SURVEY_ERROR,
		payload: err,
	};
};

export const loadingExportencounterList = () => {
	return {
		type: actionTypes.LOADING_EXPORT_ENCOUNTER_RECORDS,
	};
};

export const exportencounterlist = (data) => {
	return {
		type: actionTypes.EXPORT_ENCOUNTER_RECORDS,
		payload: data,
	};
};
export const exportencounterListError = (data) => {
	return {
		type: actionTypes.EXPORT_ENCOUNTER_RECORDS_ERROR,
		payload: data,
	};
};

export function exportEncounterList(data) {
	return (dispatch) => {
		dispatch(loadingExportencounterList());
		return DashboardService.exportencounters(data)
			.then((res) => {
				return dispatch(exportencounterlist(res));
			})
			.catch((err) => {
				dispatch(exportencounterListError(err));
				throw err;
			});
	};
}

export function getdashboardattempts() {
	return (dispatch) => {
		dispatch(
			dashboardoutreachattempts({
				data: [
					{ value: 'All', label: 'All outreach attempts' },
					{ value: 1, label: '1st outreach attempt' },
					{ value: 2, label: '2nd outreach attempt' },
				],
			})
		);
	};
}

export function resetupdatestatus() {
	return (dispatch) => {
		DashboardService.dashboardBulkUpdateStatus()
			.then((res) => {
				dispatch(resetupdateStatus(res));
			})
			.catch(() => {});
	};
}

//Non PoP referral programs
export function getdashboardReferralPrograms(filter) {
	return (dispatch) => {
		dispatch(gettingDashboardreferralprograms());
		DashboardService.dashboardReferralPrograms(filter)
			.then((res) => {
				dispatch(
					dashboardreferralprograms({
						data: res,
						attempts: [
							{ value: 'All', label: 'All Outreach Attempts' },
							{ value: 1, label: '1st Outreach Attempt' },
							{ value: 2, label: '2nd Outreach Attempt' },
						],
					})
				);
				//dispatch(dashboardoutreachattempts());
			})
			.catch((err) => {
				dispatch(gotDashboardreferralprogramsError(err));
			});
	};
}

export const gettingDashboardreferralprograms = () => {
	return {
		type: actionTypes.GETTING_DASHBOARD_REFERRAL_PROGRAMS,
		payload: true,
	};
};

export const dashboardreferralprograms = (data) => {
	return {
		type: actionTypes.DASHBOARD_REFERRAL_PROGRAMS,
		payload: data,
	};
};

export const gotDashboardreferralprogramsError = (err) => {
	return {
		type: actionTypes.GOT_DASHBOARD_REFERRAL_PROGRAMS_ERROR,
		payload: err,
	};
};

export function clearDashboard() {
	return (dispatch) => {
		dispatch(dashboard({}));
	};
}

export function getdashboard(cardlist = []) {
	return (dispatch) => {
		dispatch(loadingDashboard());
		DashboardService.dashboard(cardlist)
			.then((res) => {
				const data = get(res, 'data.data', {});
				dispatch(dashboard(data));
			})
			.catch((err) => {
				dispatch(dashboardError(err));
			});
	};
}

export function getdashboardencounters(query) {
	return (dispatch) => {
		dispatch(gettingDashboardEncounters());
		DashboardService.dashboardencounters(query)
			.then((res) => {
				const data = get(res, 'data', {});
				dispatch(dashboardencounters(data));
			})
			.catch((err) => {
				dispatch(DashboardEncountersError(err));
			});
	};
}

export function resetEncounterList() {
	return (dispatch) => {
		dispatch(dashboardencounters({}));
	};
}

export function getupcomingappointments(query) {
	return (dispatch) => {
		dispatch(loadingUpcomingappointments());
		DashboardService.upcomingappointments(query)
			.then((res) => {
				const data = get(res, 'data', {});
				dispatch(upcomingappointments(data));
			})
			.catch((err) => {
				dispatch(upcomingappointmentsError(err));
			});
	};
}
export const encounter_order_request_status_enum = (data, type) => {
	return {
		type: actionTypes.ORDER_REQUEST_ENUM,
		payload: data,
		encountertype: type,
	};
};
export const encounter_order_status_enum = (data, type) => {
	return {
		type: actionTypes.ORDER_STATUS_ENUM,
		payload: data,
		encountertype: type,
	};
};
export const encounter_visit_status_enum = (data, type) => {
	return {
		type: actionTypes.VISIT_STATUS_ENUM,
		payload: data,
		encountertype: type,
	};
};

export const encounter_visit_provider_enum_loading = () => {
	return {
		type: actionTypes.VISIT_PROVIDER_ENUM_LOADING,
		payload: true,
	};
};

export const encounter_visit_provider_enum = (data, type) => {
	return {
		type: actionTypes.VISIT_PROVIDER_ENUM,
		payload: data,
		encountertype: type,
	};
};
export const encounter_visit_provider_enum_loading_scheduling = () => {
	return {
		type: actionTypes.VISIT_PROVIDER_ENUM_SCHEDULING_LOADING,
		payload: true,
	};
};

export const encounter_visit_provider_enum_scheduling = (data, type) => {
	return {
		type: actionTypes.VISIT_PROVIDER_ENUM_SCHEDULING,
		payload: data,
		encountertype: type,
	};
};

export const encounter_lab_enum = (data, type) => {
	return {
		type: actionTypes.LAB_ENUM,
		payload: data,
		encountertype: type,
	};
};

export const encounter_ordering_physician_enum = (data) => {
	return {
		type: actionTypes.ORDERING_PHYSICIAN_ENUM,
		payload: data,
	};
};

export const encounter_claim_status = (data, type) => {
	return {
		type: actionTypes.CLAIM_STATUS_ENUM,
		payload: data,
		encountertype: type,
	};
};

const specificEncounterEnumError = (data) => {
	return {
		type: actionTypes.SPECIFIC_ENCOUNTER_ENUM_ERROR,
		payload: data,
	};
};

export const getspecificencounterenum = (data) => {
	return (dispatch) => {
		if (get(data, 'component', '') === 'scheduling') {
			dispatch(encounter_visit_provider_enum_loading_scheduling());
		} else {
			dispatch(encounter_visit_provider_enum_loading());
		}
		EncounterService.specificencounterenum(
			get(data, 'type', ''),
			get(data, 'field_name', '')
		)
			.then((res) => {
				if (data.field_name == 'order_request_status')
					dispatch(
						encounter_order_request_status_enum(res.data, data.type)
					);
				else if (data.field_name == 'visit_provider')
					if (get(data, 'component', '') === 'scheduling') {
						dispatch(
							encounter_visit_provider_enum_scheduling(
								res.data,
								data.type
							)
						);
					} else {
						dispatch(
							encounter_visit_provider_enum(res.data, data.type)
						);
					}
				else if (data.field_name == 'lab')
					dispatch(encounter_lab_enum(res.data, data.type));
				else if (data.field_name == 'ordering_physician')
					dispatch(
						encounter_ordering_physician_enum(res.data, data.type)
					);
				else if (data.field_name == 'insurance_claim_status')
					dispatch(encounter_claim_status(res.data, data.type));
				else dispatch(encounter_order_status_enum(res.data, data.type));
			})
			.catch((err) => {
				dispatch(specificEncounterEnumError(err));
			});
	};
};

//outreach v2
export const dispatch_DashboardReferralProgramReset = () => {
	return {
		type: actionTypes.V2_DASHBOARD_REFERRAL_PROGRAMS_RESET,
	};
};

const dispatch_OutreachReportsStart = (data) => {
	return {
		type: actionTypes.V2_OUTREACH_REPORTS_START,
		payload: data,
	};
};
const dispatch_OutreachReportsSuccess = (data) => {
	return {
		type: actionTypes.V2_OUTREACH_REPORTS_SUCCESS,
		payload: data,
	};
};
const dispatch_OutreachReportsFailure = (data) => {
	return {
		type: actionTypes.V2_OUTREACH_REPORTS_FAILURE,
		payload: data,
	};
};

//Referral programs
export function getDashboardReferralProgram_V2(flag) {
	return (dispatch) => {
		dispatch(dispatch_DashboardReferralProgramStart());
		return DashboardService.getReferralProgramsV2(flag)
			.then((res) => {
				return dispatch(
					dispatch_DashboardReferralProgramSuccess({
						data: res,
						attempts: callAttemptsFieldOptions,
					})
				);
			})
			.catch((err) => {
				dispatch(dispatch_DashboardReferralProgramFailure(err));
			});
	};
}

const dispatch_DashboardReferralProgramStart = (data) => {
	return {
		type: actionTypes.V2_DASHBOARD_REFERRAL_PROGRAMS_START,
		payload: data,
	};
};
const dispatch_DashboardReferralProgramSuccess = (data) => {
	return {
		type: actionTypes.V2_DASHBOARD_REFERRAL_PROGRAMS_SUCCESS,
		payload: data,
	};
};
const dispatch_DashboardReferralProgramFailure = (data) => {
	return {
		type: actionTypes.V2_DASHBOARD_REFERRAL_PROGRAMS_FAILURE,
		payload: data,
	};
};


export function dashboardOutreachPendingReport_V2(data) {
	return (dispatch) => {
		dispatch(dispatch_OutreachReportsStart());
		return DashboardService.dashboardOutreachPendingReportV2(data)
			.then((res) => {
				return dispatch(dispatch_OutreachReportsSuccess(res.data));
			})
			.catch((err) => {
				dispatch(dispatch_OutreachReportsFailure(err));
			});
	};
}

//Get active CCs
export function getActiveCCs() {
	return (dispatch) => {
		dispatch(gettingActiveCCs());
		return DashboardService.getActiveCCs()
			.then((res) => {
				dispatch(gotActiveCCs(get(res, 'data.data', []) || []));
			})
			.catch((err) => {
				dispatch(gotActiveCCsError(err));
			});
	};
}

const gettingActiveCCs = () => {
	return {
		type: actionTypes.GETTING_ACTIVE_CCS,
		payload: true,
	};
};

const gotActiveCCs = (data) => {
	return {
		type: actionTypes.GOT_ACTIVE_CCS,
		payload: data,
	};
};

const gotActiveCCsError = (err) => {
	return {
		type: actionTypes.GOT_ACTIVE_CCS_ERROR,
		payload: err,
	};
};

//Get active GCAs
export function getActiveGCAs() {
	return (dispatch) => {
		dispatch(gettingActiveGCAs());
		return DashboardService.getActiveGCAs()
			.then((res) => {
				dispatch(gotActiveGCAs(get(res, 'data.data', []) || []));
			})
			.catch((err) => {
				dispatch(gotActiveGCAsError(err));
			});
	};
}

const gettingActiveGCAs = () => {
	return {
		type: actionTypes.GETTING_ACTIVE_GCAS,
		payload: true,
	};
};

const gotActiveGCAs = (data) => {
	return {
		type: actionTypes.GOT_ACTIVE_GCAS,
		payload: data,
	};
};

const gotActiveGCAsError = (err) => {
	return {
		type: actionTypes.GOT_ACTIVE_GCAS_ERROR,
		payload: err,
	};
};

//Get CC Assignments
export function getCCAssignments(data) {
	return (dispatch) => {
		dispatch(getingCCAssignments());
		return DashboardService.getCCAssignments(data)
			.then((res) => {
				dispatch(gotCCAssignments(res?.data?.data || []));
			})
			.catch((err) => {
				dispatch(gotCCAssignmentsError(err));
			});
	};
}

const getingCCAssignments = () => {
	return {
		type: actionTypes.GETTING_CC_ASSIGNMENTS,
		payload: true,
	};
};

const gotCCAssignments = (data) => {
	return {
		type: actionTypes.GOT_CC_ASSIGNMENTS,
		payload: data,
	};
};

const gotCCAssignmentsError = (err) => {
	return {
		type: actionTypes.GOT_CC_ASSIGNMENTS_ERROR,
		payload: err,
	};
};

//Get GCA Assignments
export function getGCAAssignments(data) {
	return (dispatch) => {
		dispatch(gettingGCAAssignments());
		return DashboardService.getGCAAssignments(data)
			.then((res) => {
				dispatch(gotGCAAssignments(res?.data?.data || []));
			})
			.catch((err) => {
				dispatch(gotGCAAssignmentsError(err));
			});
	};
}

const gettingGCAAssignments = () => {
	return {
		type: actionTypes.GETTING_GCA_ASSIGNMENTS,
		payload: true,
	};
};

const gotGCAAssignments = (data) => {
	return {
		type: actionTypes.GOT_GCA_ASSIGNMENTS,
		payload: data,
	};
};

const gotGCAAssignmentsError = (err) => {
	return {
		type: actionTypes.GOT_GCA_ASSIGNMENTS_ERROR,
		payload: err,
	};
};

//CC Assignment
export function assignCCOrGCA(data, opts) {
	return (dispatch) => {
		dispatch(ccOrGCAAssignmentStart());
		return DashboardService.assignCCOrGCA(data)
			.then((res) => {
				return dispatch(
					ccOrGCAAssignmentSuccess(get(res, 'data.data', []) || [])
				);
			})
			.catch((err) => {
				dispatch(ccOrGCAAssignmentError(err));
				if (opts?.throwError) {
					throw err;
				}
			});
	};
}

const ccOrGCAAssignmentStart = () => {
	return {
		type: actionTypes.CC_GCA_ASSIGNMENT_START,
		payload: true,
	};
};

const ccOrGCAAssignmentSuccess = (data) => {
	return {
		type: actionTypes.CC_GCA_ASSIGNMENT_SUCCESS,
		payload: data,
	};
};

const ccOrGCAAssignmentError = (err) => {
	return {
		type: actionTypes.CC_GCA_ASSIGNMENT_ERROR,
		payload: err,
	};
};

const dispatch_DashboardRoROutreachPendingStart = (data) => {
	return {
		type: actionTypes.DASHBOARD_ROR_OUTREACH_PENDING_START,
		payload: data,
	};
};
const dispatch_DashboardRoROutreachPendingSuccess = (data) => {
	return {
		type: actionTypes.DASHBOARD_ROR_OUTREACH_PENDING_SUCCESS,
		payload: data,
	};
};

const dispatch_DashboardRoROutreachPendingFailure = (data) => {
	return {
		type: actionTypes.DASHBOARD_ROR_OUTREACH_PENDING_FAILURE,
		payload: data,
	};
};

export const dispatch_DashboardRoROutreachPendingReset = () => {
	return {
		type: actionTypes.DASHBOARD_ROR_OUTREACH_PENDING_RESET,
	};
};

export function getDashboardRoROutreachPending(data) {
	return (dispatch) => {
		dispatch(dispatch_DashboardRoROutreachPendingStart());
		return DashboardService.getDashboardRoROutreachPending(data)
			.then((res) => {
				return dispatch(
					dispatch_DashboardRoROutreachPendingSuccess(
						hydratePatientsValues(res.data)
					)
				);
			})
			.catch((err) => {
				dispatch(dispatch_DashboardRoROutreachPendingFailure(err));
			});
	};
}

export function dashboardRoROutreachPendingReport(data) {
	return DashboardService.dashboardRoROutreachPendingReport(data);
}
