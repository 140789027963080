//React & Redux
import React, { useState } from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../assets/exit-alert.svg';
import breadcrumbArrow from './../../assets/menu-breadcrumb.svg';

//Lodash
import _ from 'lodash';

//Components
import AddEditInsurance from './AddEditInsurance.js';
import ExceptionsStage from './ExceptionsModal/ExceptionsStage.js';

//Styles
import './css/addedit-insurance.css';
import './../Patients/ChargeInformation/ChargeInformation.css';

const AddEditInsuranceModal = (props) => {
	const {
		buildReqData,
		creatingInsuranceCompany,
		editInsuranceInfo,
		handleAddOrEditInsurance,
		hideAddEditInsurance,
		modalData,
		referralPrograms,
		updatingInsuranceCompany,
	} = props;

	const [currentStage, setCurrentStage] = useState('details');

	const stageClass =
		currentStage === 'details'
			? ' addEditInsuranceContainerDetails'
			: ' addEditInsuranceContainerExceptions';

	const renderStage = () => {
		switch (currentStage) {
			case 'details':
				return (
					<AddEditInsurance
						{...{
							buildReqData,
							changeToExceptionsStage,
							editInsuranceInfo,
							initialValues: {
								...editInsuranceInfo,
								...modalData,
							},
						}}
					/>
				);
			case 'exceptions':
				return (
					<ExceptionsStage
						alternateFinalizeButtonText={getAlternativeButtonText()}
						buttonText={'Add Insurance'}
						func={handleAddOrEditInsurance}
						items={referralPrograms}
						keyForSearch={'display_name'}
						loading={
							creatingInsuranceCompany || updatingInsuranceCompany
						}
						preselectedItems={
							editInsuranceInfo?.insurance_prepay_exceptions || []
						}
						price={getPrice()}
						regressStage={changeToDetailsStage}
					/>
				);
			default:
				console.log('Unrecognized stage.');
		}
	};

	const getAlternativeButtonText = () => {
		if (!editInsuranceInfo || _.isEmpty(editInsuranceInfo)) {
			return 'Add Insurance';
		} else {
			return 'Save';
		}
	};

	const getPrice = () => {
		const { consultationPrice = null } = modalData;
		return consultationPrice || consultationPrice === 0
			? consultationPrice
			: '--';
	};

	const changeToExceptionsStage = () => {
		setCurrentStage('exceptions');
	};

	const changeToDetailsStage = () => {
		setCurrentStage('details');
	};

	const determineTitle = () => {
		if (!editInsuranceInfo || _.isEmpty(editInsuranceInfo)) {
			return 'Add New Insurance';
		} else {
			return 'Edit Insurance';
		}
	};

	return (
		<div className='fullModalBackground'>
			<div className={'addEditInsuranceContainer' + stageClass}>
				<div className='chargeModalTopRow'>
					<div className='chargeModalHeaderBreadcrumbs'>
						<div
							className='chargeModalHeader'
							style={{
								position: 'relative',
								top: '1px',
								left: '0px',
								webkitFontSmoothing: 'antialiased',
							}}
						>
							{determineTitle()}
						</div>
						<div
							className='chargeModalBreadcrumbs'
							style={{ color: '#A0ABB9' }}
						>
							<span
								className={
									currentStage === 'details'
										? 'chargeModalBreadcrumbsCurrent'
										: ''
								}
							>
								Details
							</span>
							<ReactSVG
								src={breadcrumbArrow}
								className='chargeModalBreadcrumbArrow'
							/>
							<span
								className={
									currentStage === 'exceptions'
										? 'chargeModalBreadcrumbsCurrent'
										: ''
								}
							>
								PrePay Exceptions
							</span>
						</div>
					</div>
					<div className='chargeModalClose'>
						<ReactSVG
							src={xIcon}
							style={{
								position: 'relative',
								bottom: '2px',
								right: '1.25px',
							}}
							onClick={hideAddEditInsurance}
							onMouseDown={(e) => e.preventDefault()}
						/>
					</div>
				</div>
				{renderStage()}
			</div>
		</div>
	);
};

export default AddEditInsuranceModal;
