//React & Redux
import React, { useState, useEffect, Fragment } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import { CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER } from '../../../../../Utils/permissionUtils';
import { GetSelectvalue } from '../Common/utilsfunctions';
import {
	TestRecommendationEnums,
	testRecommendedEnum,
	shouldShowBothCTAs,
} from '../../../../../Utils/encounterUtils';
//UI Libraries
import { Checkbox, Button } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';

//Components
import TestOrderRow from '../../TestOrders/TestOrderRow';
import PreAuthTestOrderRow from '../../TestOrders/PreAuthTestOrderRow';
import { RequestAnotherTestButton } from '../VisitEncounters/RequestAnotherTestButton';
import CreateTestOrderModalContainer from '../../TestOrders/CreateTestOrderModalContainer';
import HoldResultReleasePopup from '../Common/HoldResultReleasePopup';
import RBAC from '../../../../RBAC';
import PreAuthTestOrderModalContainer from '../../TestOrders/PreAuthTestOrderModalContainer';

const TestOrderSegment = (props) => {
	const {
		encounter,
		toggleHoldReleaseResultModal,
		encounterTestOrderRequest,
		togglePreAuthTestModal,
		toggleChargeModal,
		showChargeModal,
		getICDCodes,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		enqueueSnackbar,
		ordering_physicianenums,
		encounterschema,
		LoadTestOrderPopupTestEnum,
		orderrequeststatuscascadeenums,
		patientdetail,
		sampletypeenums,
		patientbillingmethodenums,
		mloOrderStatusEnums,
		cancel,
		testOrderNumber,
		user,
		showPreAuthTestModal,
		nonlegacytestOrderPopupTestEnums,
		nonlegacytestOrderPopupTestEnumsLoading,
		LoadAlternateTestEnum,
		nonlegacyalternatetestnameenums,
		preauthorizationsenums,
		createPreauthorizeTestOrder,
		preauthdecisionenums,
		preauthdispatchstatusenums,
		testnameenums,
		orderstatuscascadeenums,
		goToTestTab,
		gotoMLOTestDetail,
		laborderstatusenums,
		outreachStatusEnums,
		updateTestOrder,
		callTestOrderNotes,
		testOrderNotes,
		callTestOrderRequest,
		getOutreachsLog,
		auditTrailEncounterLoading,
		auditTrailEncounter,
		auditTrailEncounterError,
		getEncounterAuditTrail,
		encounterPreauthTestOrders,
		updatePreAuthTestOrder,
		showHoldReleaseResultModal,
		editencouterOnHoldResultRelease,
		saveTestOrderRequest,
	} = props;
	const [holdResultRelease, setHoldResultRelease] = useState(
		_.get(encounter, ['hold_result_release'], null)
	);

	useEffect(() => {
		setHoldResultRelease(_.get(encounter, ['hold_result_release'], null));
	}, [encounter]);

	const onHideReleaseResult = () => {
		setHoldResultRelease(_.get(encounter, ['hold_result_release'], null));
		toggleHoldReleaseResultModal();
	};

	const onChangeHoldReleaseFlag = () => {
		const valHoldResultRelease = !_.isNil(
			_.get(encounter, ['hold_result_release'], null)
		)
			? _.get(encounter, ['hold_result_release'], null)
			: false;
		toggleHoldReleaseResultModal();
		setHoldResultRelease(valHoldResultRelease);
	};

	const canAddTestOrder = () => {
		return (
			_.get(encounter, ['test_recommended'], '') in testRecommendedEnum
		);
	};

	const showBothButtons = () => {
		return _.get(encounter, ['test_recommended'], '') in shouldShowBothCTAs;
	};

	const showHoldResultRelease = () => {
		return _.get(encounterTestOrderRequest, ['length'], 0) > 0;
	};

	const testRecommendedType = _.get(encounter, ['test_recommended'], '');

	return (
		<div>
			<div className='segment-row'>
				<div className='segment-row'>
					<div
						className={classnames(
							'segment-5cols',
							canAddTestOrder()
								? 'with-test-recommendation-button'
								: ''
						)}
					>
						{!canAddTestOrder() && (
							<div className='segment-test-recommendation'>
								<div className='encounter-field-header'>
									Do you recommend test(s) for this patient?
								</div>
								<div className='encounter-field-value'>
									{GetSelectvalue(
										encounter,
										['test_recommended'],
										TestRecommendationEnums
									)}
								</div>
							</div>
						)}
						{canAddTestOrder() && showBothButtons() ? (
							<Fragment>
								<RBAC
									action={
										CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
									}
									yes={
										<RequestAnotherTestButton
											onClick={togglePreAuthTestModal}
											visitStatus={_.get(
												encounter,
												['visit_status'],
												''
											)}
											text={'Pre-Authorization'}
										/>
									}
								/>
								<RBAC
									action={
										CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
									}
									yes={
										<RequestAnotherTestButton
											onClick={toggleChargeModal}
											visitStatus={_.get(
												encounter,
												['visit_status'],
												''
											)}
											text={'Test Order'}
										/>
									}
								/>
							</Fragment>
						) : (
							<RBAC
								action={CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER}
								yes={
									canAddTestOrder() && (
										<RequestAnotherTestButton
											onClick={
												testRecommendedType == 'PA'
													? togglePreAuthTestModal
													: toggleChargeModal
											}
											visitStatus={_.get(
												encounter,
												['visit_status'],
												''
											)}
										/>
									)
								}
							/>
						)}
						<RBAC
							action={CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER}
							yes={
								showHoldResultRelease() && (
									<Fragment>
										<span className='divider' />
										<div className='hold-result-release-wrapper'>
											<Checkbox
												checked={holdResultRelease}
												className='chk-hold-result-release'
											>
												Hold release until ALL results
												received
											</Checkbox>
											<Button
												className='btn-result-release-change-flag'
												onClick={
													onChangeHoldReleaseFlag
												}
												type='text'
											>
												Update flag
											</Button>
										</div>
									</Fragment>
								)
							}
						/>
					</div>
				</div>
			</div>
			{showChargeModal && (
				<div className='popupModal'>
					<CreateTestOrderModalContainer
						hideModal={toggleChargeModal}
						getICDCodes={getICDCodes}
						icdCodes={icdCodes}
						icdCodesLoading={icdCodesLoading}
						icdCodesError={icdCodesError}
						icdCodesPlacement={icdCodesPlacement}
						enqueueSnackbar={enqueueSnackbar}
						selectedICDCodes={_.get(
							encounter,
							'medical_codes',
							null
						)}
						ordering_physicianenums={ordering_physicianenums}
						labenums={_.get(encounterschema, ['lab'], [])}
						LoadTestOrderPopupTestEnum={LoadTestOrderPopupTestEnum}
						nonlegacytestOrderPopupTestEnums={
							nonlegacytestOrderPopupTestEnums
						}
						nonlegacytestOrderPopupTestEnumsLoading={
							nonlegacytestOrderPopupTestEnumsLoading
						}
						orderrequeststatuscascadeenums={
							orderrequeststatuscascadeenums
						}
						encounter={encounter}
						patientdetail={patientdetail}
						sampletypeenums={sampletypeenums}
						patientbillingmethodenums={patientbillingmethodenums}
						mloOrderStatusEnums={mloOrderStatusEnums}
						saveTestOrderRequest={saveTestOrderRequest}
						cancel={cancel}
						testOrderNumber={testOrderNumber}
						user={user}
						encounterICDcodes={_.get(
							encounter,
							'medical_codes',
							[]
						)}
					/>
				</div>
			)}
			{showPreAuthTestModal && (
				<div className='popupModal'>
					<PreAuthTestOrderModalContainer
						testRequestMode='create'
						hidePreAuthTestModal={togglePreAuthTestModal}
						getICDCodes={getICDCodes}
						icdCodes={icdCodes}
						icdCodesLoading={icdCodesLoading}
						icdCodesError={icdCodesError}
						icdCodesPlacement={icdCodesPlacement}
						enqueueSnackbar={enqueueSnackbar}
						selectedICDCodes={_.get(
							encounter,
							'medical_codes',
							null
						)}
						labenums={_.get(encounterschema, ['lab'], [])}
						LoadTestOrderPopupTestEnum={LoadTestOrderPopupTestEnum}
						nonlegacytestOrderPopupTestEnums={
							nonlegacytestOrderPopupTestEnums
						}
						LoadAlternateTestEnum={LoadAlternateTestEnum}
						nonlegacyalternatetestnameenums={
							nonlegacyalternatetestnameenums
						}
						encounter={encounter}
						patientdetail={patientdetail}
						dispatchstatusenums={_.get(
							preauthorizationsenums,
							['dispatch_status'],
							[]
						)}
						preauthorizationdecisionenums={_.get(
							preauthorizationsenums,
							['preauthorization_decision'],
							[]
						)}
						createPreauthorizeTestOrder={
							createPreauthorizeTestOrder
						}
						closeEditTestOrder={cancel}
						preauthdecisionenums={preauthdecisionenums}
						preauthdispatchstatusenums={preauthdispatchstatusenums}
					/>
				</div>
			)}
			<div className='testorder-rows'>
				{_.get(encounterTestOrderRequest, ['length'], 0) > 0 ? (
					encounterTestOrderRequest.map((row, index) => {						
						let _progressbarPathObj = {...row};
						if (row?.orders) {							
							const sortedGroupOrders = _.orderBy(
								row?.orders,
								(o) => {
									return _.get(o, 'order_type', '');
								},
								['asc']
							);
							row = _.get(sortedGroupOrders, ['0'], {});
							_progressbarPathObj.orders = sortedGroupOrders;
						}
						let localorderstatus = _.get(row, 'order_status', null);

						if (
							!_.isNil(_.get(row, ['status_reason'], null)) &&
							!_.isEmpty(_.get(row, ['status_reason'], null))
						) {
							localorderstatus = _.get(
								row,
								['status_reason'],
								null
							);
						}

						return (
							<TestOrderRow
								testorder={{
									lab: _.get(row, 'lab', ''),
									test_name: _.get(
										row,
										'genetic_test_display_name',
										''
									),
									lab_uuid: _.get(row, 'lab_uuid', ''),
									genetic_test_uuid: _.get(
										row,
										'genetic_test_uuid',
										''
									),
									order_status: _.get(
										row,
										'order_status',
										null
									),
									ordering_physician: _.get(
										row,
										'ordering_physician',
										''
									),
									blood_draw_request: _.get(
										row,
										'blood_draw_request',
										null
									),
									patient_billing_method: _.get(
										row,
										'patient_billing_method',
										null
									),
									sample_type: _.get(
										row,
										'sample_type',
										null
									),
									medical_codes: _.get(
										row,
										'medical_codes',
										[]
									),
									test_order_uuid: _.get(row, 'uuid', ''),
									status_reason: _.get(
										row,
										'status_reason',
										null
									),
									localorderstatus: localorderstatus,
									bd_saliva_kit_sent: _.get(
										row,
										'bd_saliva_kit_sent',
										null
									),
									blood_draw_order_date: _.get(
										row,
										'blood_draw_order_date',
										null
									),
									date_received_report: _.get(
										row,
										'date_received_report',
										null
									),
									date_results_released_to_patient: _.get(
										row,
										'date_results_released_to_patient',
										null
									),
									date_test_ordered: _.get(
										row,
										'date_test_ordered',
										null
									),
									expected_lab_results_date: _.get(
										row,
										'expected_lab_results_date',
										null
									),
									history: _.get(row, 'history', null),
									date_test_authorized: _.get(
										row,
										'date_test_authorized',
										null
									),
									positive_results_to_report: _.get(
										row,
										'positive_results_to_report',
										null
									),
									lab_paper_form_only: _.get(
										row,
										'lab_paper_form_only',
										false
									),
									lab_order_status: _.get(
										row,
										'lab_order_status_key_name',
										''
									),
									is_actionable: _.get(
										row,
										'is_actionable',
										false
									),
									can_change_lab_order_status: _.get(
										row,
										'can_change_lab_order_status',
										false
									),
									order_questionnaire: _.get(
										row,
										'order_questionnaire',
										null
									),
									uuid: _.get(row, ['uuid'], ''),
									is_questionnaire_editable: _.get(
										row,
										['is_questionnaire_editable'],
										false
									),
									skip_lab_integration: _.get(
										row,
										['skip_lab_integration'],
										false
									),
								}}
								LoadTestOrderPopupTestEnum={
									LoadTestOrderPopupTestEnum
								}
								nonlegacytestOrderPopupTestEnums={
									nonlegacytestOrderPopupTestEnums
								}
								nonlegacytestOrderPopupTestEnumsLoading={
									nonlegacytestOrderPopupTestEnumsLoading
								}
								ordering_physicianenums={
									ordering_physicianenums
								}
								testnameenums={testnameenums}
								orderrequeststatuscascadeenums={
									orderrequeststatuscascadeenums
								}
								orderstatuscascadeenums={
									orderstatuscascadeenums
								}
								encounterschema={encounterschema}
								getICDCodes={getICDCodes}
								icdCodes={icdCodes}
								icdCodesLoading={icdCodesLoading}
								icdCodesError={icdCodesError}
								icdCodesPlacement={icdCodesPlacement}
								patientdetail={patientdetail}
								encounter={encounter}
								goToTestTab={goToTestTab}
								gotoMLOTestDetail={gotoMLOTestDetail}
								sampletypeenums={sampletypeenums}
								laborderstatusenums={laborderstatusenums}
								patientbillingmethodenums={
									patientbillingmethodenums
								}
								outreachStatusEnums={outreachStatusEnums}
								mloOrderStatusEnums={mloOrderStatusEnums}
								updateTestOrder={updateTestOrder}
								index={index + 1}
								progressbarPathObj={_progressbarPathObj}								
								user={user}
								callTestOrderNotes={callTestOrderNotes}
								testOrderNotes={testOrderNotes}
								orderNoteCount={_.get(
									row,
									'order_note_count',
									0
								)}
								outreachSeqNo={_.get(row, 'outreach_seq_no', 0)}
								testOutreachStatus={_.get(
									row,
									'test_outreach_status',
									''
								)}
								outreachTimeToStart={_.get(
									row,
									'time_to_start',
									null
								)}
								callTestOrderRequest={callTestOrderRequest}
								getOutreachsLog={getOutreachsLog}
								testOrderList={encounterTestOrderRequest}
								auditTrailEncounterLoading={
									auditTrailEncounterLoading
								}
								auditTrailEncounter={auditTrailEncounter}
								auditTrailEncounterError={
									auditTrailEncounterError
								}
								getEncounterAuditTrail={getEncounterAuditTrail}
							/>
						);
					})
				) : (
					<div />
				)}

				{_.get(encounterPreauthTestOrders, ['length'], 0) > 0 ? (
					encounterPreauthTestOrders.map((row, index) => {
						let localorderstatus = _.get(
							row,
							'dispatch_status',
							null
						);

						if (
							!_.isNil(
								_.get(row, ['dispatch_status_reason'], null)
							) &&
							!_.isEmpty(
								_.get(row, ['dispatch_status_reason'], null)
							)
						) {
							localorderstatus = _.get(
								row,
								['dispatch_status_reason'],
								null
							);
						}

						return (
							<PreAuthTestOrderRow
								testorder={{
									lab: _.get(row, 'lab_display_name', ''),
									test_name: _.get(
										row,
										'test_display_name',
										''
									),
									lab_uuid: _.get(row, 'lab_uuid', ''),
									genetic_test_uuid: _.get(
										row,
										'test_uuid',
										''
									),
									alternate_lab_uuid: _.get(
										row,
										['alternate_lab_uuid'],
										''
									),
									alternate_test_uuid: _.get(
										row,
										['alternate_test_uuid'],
										''
									),
									alternate_test_name: _.get(
										row,
										['alternate_test_display_name'],
										''
									),
									alternate_lab: _.get(
										row,
										['alternate_lab_display_name'],
										''
									),
									request_decision: _.get(
										row,
										'request_decision',
										''
									),
									dispatch_status: localorderstatus,
									medical_codes: _.get(
										row,
										'medical_codes',
										[]
									),
									dispatch_date: _.get(
										row,
										'dispatch_date',
										null
									),
									preauth_test_order_uuid: _.get(
										row,
										'uuid',
										''
									),
									history: _.get(row, ['history'], []),
									props_dispatch_status: _.get(
										row,
										'dispatch_status',
										''
									),
									props_dispatch_status_reason: _.get(
										row,
										'dispatch_status_reason',
										''
									),
								}}
								encounterICDcodes={_.get(
									encounter,
									'medical_codes',
									[]
								)}
								ordering_physicianenums={
									ordering_physicianenums
								}
								testnameenums={testnameenums}
								nonlegacytestOrderPopupTestEnums={
									nonlegacytestOrderPopupTestEnums
								}
								orderrequeststatuscascadeenums={
									orderrequeststatuscascadeenums
								}
								orderstatuscascadeenums={
									orderstatuscascadeenums
								}
								encounterschema={encounterschema}
								getICDCodes={getICDCodes}
								icdCodes={icdCodes}
								icdCodesLoading={icdCodesLoading}
								icdCodesError={icdCodesError}
								icdCodesPlacement={icdCodesPlacement}
								encounter={encounter}
								patientdetail={patientdetail}
								cancel={cancel}
								dispatchstatusenums={_.get(
									preauthorizationsenums,
									['dispatch_status'],
									[]
								)}
								preauthorizationdecisionenums={_.get(
									preauthorizationsenums,
									['preauthorization_decision'],
									[]
								)}
								preauthdecisionenums={preauthdecisionenums}
								preauthdispatchstatusenums={
									preauthdispatchstatusenums
								}
								updatePreAuthTestOrder={updatePreAuthTestOrder}
								LoadTestOrderPopupTestEnum={
									LoadTestOrderPopupTestEnum
								}
								LoadAlternateTestEnum={LoadAlternateTestEnum}
								nonlegacyalternatetestnameenums={
									nonlegacyalternatetestnameenums
								}
							/>
						);
					})
				) : (
					<div />
				)}
			</div>
			{showHoldReleaseResultModal && (
				<div className='popupModal'>
					<HoldResultReleasePopup
						chk_hold_release_result={holdResultRelease}
						hideModal={onHideReleaseResult}
						editencouterOnHoldResultRelease={
							editencouterOnHoldResultRelease
						}
						testOrderList={encounterTestOrderRequest}
					/>
				</div>
			)}
		</div>
	);
};
export default TestOrderSegment;
