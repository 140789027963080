import React from 'react';
import { NoteInputField } from './NoteInputField';

export const ProviderNote = ({ values, onChange }) => (
	<div className='notes-div'>
		<h3>Provider Notes</h3>
		<p>
			Please enter any relevant provider notes associated with the
			patient.
		</p>
		<NoteInputField
			name='providerNotes'
			onChange={onChange('enteredProviderNote')}
			value={values?.enteredProviderNote}
		/>
	</div>
);
