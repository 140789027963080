import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import appReducers from '../reducers/index';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
	key: 'root',
	storage: storage,
	whitelist: ['appData', 'uiConfig'],
};

const pReducer = persistReducer(persistConfig, appReducers);

function configureStoreProd(initialState) {
	const middlewares = [thunkMiddleware];

	return createStore(
		pReducer,
		initialState,
		compose(applyMiddleware(...middlewares))
	);
}

function configureStoreDev(initialState) {
	const loggerMiddleware = createLogger({
		level: 'info',
		collapsed: true,
		predicate: () => true,
	});

	const middlewares = [
		thunkMiddleware,
		loggerMiddleware,
		reduxImmutableStateInvariant(),
	];

	const composeEnhancers =
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	const store = createStore(
		pReducer,
		initialState,
		composeEnhancers(applyMiddleware(...middlewares))
	);

	return store;
}

export const store =
	process.env.NODE_ENV === 'production'
		? configureStoreProd()
		: configureStoreDev();

export const persistor = persistStore(store);
