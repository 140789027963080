//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import TestAuthTableRow from './TestAuthTableRow.js';

class TestAuthTableBody extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		const tests = get(this, 'props.tests.data', []) || [];
		const numOfRecords = get(tests, 'length', null);
		if (numOfRecords === 0) {
			return (
				<div className='testAuthTableEmptyBodyContainer'>
					<div className='testAuthTableEmptyBodyMessage'>
						No test requests were found that match the criteria
					</div>
				</div>
			);
		} else {
			return tests.map((el) => {
				return (
					<TestAuthTableRow
						test={el}
						handleItemToggle={this.props.handleItemToggle}
						approveTest={this.props.approveTest}
						rejectTest={this.props.rejectTest}
						selectedTests={
							get(this, 'props.selectedTests', []) || []
						}
						sortedField={this.props.sortedField}
						redirectToEncounter={this.props.redirectToEncounter}
						sexAssignedAtBirthEnums={
							this.props.sexAssignedAtBirthEnums
						}
						orderStatusEnum={this.props.orderStatusEnum}
						showUpdateFlagStatusModal={
							this.props.showUpdateFlagStatusModal
						}
						showRedFlagAuditTrailModal={
							this.props.showRedFlagAuditTrailModal
						}
						getEncounterDocuments={this.props.getEncounterDocuments}
					/>
				);
			});
		}
	}
}

export default TestAuthTableBody;
