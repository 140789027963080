//React & Redux
import React, { Fragment } from 'react';

//Images
import ReactSVG from 'react-svg';
import patientIcon from './../../../assets/contacts.svg';
import personIcon from '../../../assets/person.svg';
import infoIcon from '../../../assets/icons/gray-info.svg';
import visitIcon from '../../../assets/enc-detail/visit.svg';
import referralProgramIcon from '../../../assets/referral-programs.svg';
import testIcon from '../../../assets/test.svg';

//Lodash
import { get, isNil, isEmpty } from 'lodash';

//Utils
import {
	statesTimezones,
	RemoveUnderscoreAndCapitalizeInString,
	UTCToCurrentTimeView,
	getHomeAddress,
	currentAddressKey,
} from '../../../utils';
import { LastCallDateDiffrence } from './../V2/Outreachs/utilfunctions';
import {
	isPartnerInitiatedPreTest,
	isPartnerInitiatedUnsolicitedResult,
} from './../V2/Encounters/Common/utilsfunctions';

//Other Libraries
import Clock from '../../Common/LiveClock';
import moment from 'moment';

//Components
import LinkPopover from '../../Common/Popovers/LinkPopover';

class PatientDetailBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	getUserTimeZone(state) {
		return statesTimezones[state];
	}
	//functions

	getTimestamp(obj) {
		const date1 = moment(get(obj, 'date_of_service', '')).format(
			'YYYY-MM-DD HH:mm:ss'
		);
		const date_time_zone = moment
			.tz(date1, get(obj, ['timezone'], ''))
			.format('ddd, MMM D');

		return `${date_time_zone}, ${UTCToCurrentTimeView(
			moment,
			moment(get(obj, 'date_of_service', '')),
			get(obj, ['timezone'], '')
		)}`;
	}

	getPayor(obj) {
		let payor = '';

		if (!isNil(get(obj, ['payor'], ''))) {
			if (get(obj, ['payor'], '') == 'self_pay') {
				payor = 'Non-insurance';
			} else {
				payor = get(obj, ['payor'], '');
			}
		}

		return payor;
	}

	getResultText = (obj) => {
		let resultText = '';
		const order_status = get(obj, ['order_status'], '');
		const status_reason = get(obj, ['status_reason'], '');

		if (
			order_status == 'results_entered' &&
			status_reason in
				{ result_positive_no_genes: 1, result_positive_with_genes: 1 }
		) {
			resultText = 'Results +Ve';
		} else if (
			order_status == 'results_entered' &&
			status_reason == 'result_negative'
		) {
			resultText = 'Results -Ve';
		}

		return resultText;
	};
	render() {
		const patient = get(this, ['props', 'patient'], {});
		const homeAddress = getHomeAddress(get(patient, 'address', null));
		return (
			<div className='patientCreateOutreachModalPatientDetailContainer'>
				{get(this, ['props', 'selectedpage'], 'patient') ==
					'patient' && (
					<div className='referral-outreach-modal-referral-program-wrapper'>
						<ReactSVG src={referralProgramIcon} className='handshake-icon' />
						<span style={{ fontFamily: 'RionaSans-Bold', marginTop: '-5px', marginLeft: '7px' }}>
							{get(
								this,
								'props.patient.referral_program',
								'Referral Program Unavailable'
							) || 'Referral Program Unavailable'}
						</span>
					</div>
				)}
				<div className='referral-outreach-modal-patient-detail-wrapper'>
					<ReactSVG src={personIcon} className='person-icon' />
					<div className='outreach-modal-patient-detail'>
						{get(this, 'props.selectPatientURL') ? (
							<LinkPopover
								enqueueSnackbar={this.props.enqueueSnackbar}
								linkTo={this.props.selectPatientURL()}
								options={{ link: true }}
							>
								<span
									className='outreach-patient-name'
									onClick={this.props.selectPatient}
								>
									{get(this, 'props.patient.first_name', '')}{' '}
									{get(this, 'props.patient.last_name', '')}
								</span>
							</LinkPopover>
						) : (
							<span
								className='outreach-patient-name-no-link'
								onClick={this.props.selectPatient}
							>
								{get(this, 'props.patient.first_name', '')}{' '}
								{get(this, 'props.patient.last_name', '')}
							</span>
						)}
						<span>
							{this.props.determineDOB()}&nbsp;&nbsp; ·
							&nbsp;&nbsp;{this.props.determineDOBDifference()}
						</span>
					</div>
				</div>

				<div className='referral-outreach-modal-patient-detail-wrapper'>
					<ReactSVG src={patientIcon} className='patient-icon' />
					<div className='outreach-modal-patient-detail'>
						<div className='crt-outrh-modal-pill-wrapper'>
							{get(
								this,
								['props', 'patient', 'high_priority'],
								'false'
							) == 'true' && (
								<div className='priority-pill-wrapper'>
									Priority
								</div>
							)}
							{!isEmpty(
								get(this, ['props', 'patient', 'guardian'], {})
							) &&
								!isEmpty(
									get(
										this,
										[
											'props',
											'patient',
											'guardian',
											'first_name',
										],
										''
									)
								) && (
									<div className='guardian-pill-wrapper'>
										Guardian/ PoA
									</div>
								)}
						</div>
						<div>
							{get(
								this,
								'props.patient.phone',
								'Phone # Unknown'
							) || 'Phone # Unknown'}{' '}
							&nbsp;&nbsp;
							{get(homeAddress, ['state', 'length'], 0) > 0 ? (
								<Fragment>
									· &nbsp;&nbsp;
									<Clock
										format={'hh:mmA'}
										timezone={this.getUserTimeZone(
											get(homeAddress, ['state'], 'CA')
										)}
									/>{' '}
									Local Time
								</Fragment>
							) : (
								''
							)}
						</div>
						<span className='patient-email'>
							{get(this, ['props', 'patient', 'email'], '')}
						</span>
					</div>
				</div>

				{get(this, ['props', 'selectedpage'], 'patient') ==
					'RoRDashboard' && (
					<div className='referral-outreach-modal-encntr-detail-wrapper'>
						<ReactSVG src={visitIcon} className='visit-icon' />
						<div className='outreach-modal-encntr-detail'>
							{isPartnerInitiatedPreTest({
								...patient,
								type: get(patient, 'encounter_type', ''),
							}) ? (
								<span>TRO</span>
							) : isPartnerInitiatedUnsolicitedResult({
									...patient,
									type: get(patient, 'encounter_type', ''),
							  }) ? (
								<span>TRO - Results Only</span>
							) : (
								<span>
									{get(
										this,
										[
											'props',
											'patient',
											'consultation_type',
										],
										''
									)}{' '}
									{!isEmpty(
										get(
											this,
											['props', 'patient', 'tm_platform'],
											''
										)
									) &&
										'(' +
											get(
												this,
												[
													'props',
													'patient',
													'tm_platform',
												],
												''
											) +
											')'}
								</span>
							)}
							<span>
								{this.getTimestamp(
									get(this, ['props', 'patient'])
								)}
							</span>
							<span>
								{get(this, ['props', 'patient', 'provider'])}
							</span>
							<span>
								{this.getPayor(get(this, ['props', 'patient']))}
							</span>
						</div>
					</div>
				)}

				{get(this, ['props', 'selectedpage'], 'patient') ==
					'RoRDashboard' && (
					<div className='referral-outreach-modal-test-detail-wrapper'>
						<ReactSVG src={testIcon} className='test-icon' />
						<div className='outreach-modal-test-detail'>
							{get(this, 'props.testOrderRequest', []).map(
								(obj, i) => {
									return (
										<Fragment>
											{!isEmpty(
												get(
													obj,
													[
														'genetic_test_display_name',
													],
													''
												)
											) && (
												<span
													key={i}
													className='test-name'
												>
													{' '}
													{i + 1}){' '}
													{get(
														obj,
														[
															'genetic_test_display_name',
														],
														''
													)}
												</span>
											)}
											{!isEmpty(
												get(obj, ['lab'], '')
											) && (
												<span>
													{get(obj, ['lab'], '')}
												</span>
											)}
											{!isEmpty(
												get(obj, ['status_reason'], '')
											) && this.getResultText(obj)}
										</Fragment>
									);
								}
							)}
						</div>
					</div>
				)}

				{get(this, ['props', 'selectedpage'], 'patient') ==
					'dashboard' && (
					<div className='referral-outreach-modal-referral-detail-wrapper'>
						<ReactSVG src={infoIcon} className='info-icon' />
						<div className='outreach-modal-referral-detail'>
							<div>
								Insurance:
								<span className='refral-detail-field-val'>
									{!isNil(
										get(
											this,
											[
												'props',
												'patient',
												'insurance_company_type',
											],
											''
										)
									)
										? get(
												this,
												[
													'props',
													'patient',
													'insurance_company_type',
												],
												''
										  )
										: '--'}
								</span>
							</div>
							<div>
								Ref. Program:
								<span className='refral-detail-field-val'>
									{get(
										this,
										[
											'props',
											'patient',
											'referral_program',
										],
										''
									)}
								</span>
							</div>
							<div>
								Last Call:
								<span className='refral-detail-field-val'>
									{get(
										this,
										[
											'props',
											'patient',
											'last_call',
											'length',
										],
										0
									) > 0
										? LastCallDateDiffrence(
												get(
													this,
													[
														'props',
														'patient',
														'last_call',
													],
													''
												)
										  )
										: '--'}
								</span>
							</div>

							<div>
								Patient Created:
								<span className='refral-detail-field-val'>
									{LastCallDateDiffrence(
										get(
											this,
											['props', 'patient', 'created_at'],
											''
										)
									)}
								</span>
							</div>
						</div>
					</div>
				)}

				{get(this, ['props', 'selectedpage'], 'patient') ==
					'RoRDashboard' && (
					<div className='referral-outreach-modal-referral-detail-wrapper'>
						<ReactSVG src={infoIcon} className='info-icon' />
						<div className='outreach-modal-referral-detail'>
							<div>
								Ref. Program:
								<span className='refral-detail-field-val'>
									{get(
										this,
										[
											'props',
											'patient',
											'referral_program',
										],
										''
									)}
								</span>
							</div>
							<div>
								Last Call:
								<span className='refral-detail-field-val'>
									{get(
										this,
										[
											'props',
											'patient',
											'last_call',
											'length',
										],
										0
									) > 0
										? LastCallDateDiffrence(
												get(
													this,
													[
														'props',
														'patient',
														'last_call',
													],
													''
												)
										  )
										: '--'}
								</span>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default PatientDetailBox;
