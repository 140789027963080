//React & Redux
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//Images
import ReactSVG from 'react-svg';
import add_patient from '../../assets/add-patient.svg';

//Lodash
import { get, isNil } from 'lodash';

//Utils
import {
	CARE_PATIENTS_NEEDS_INSURANCE_SELECTION,
	CARE_PATIENTS_CREATE,
} from '../../Utils/permissionUtils';

//Actions & Services
import { getpatients, patients as resetPatient } from '../../actions/patients';
import { resetsearchkey } from '../../actions/Search';
import { getspecificencounterenum } from '../../actions/dashboard';
import {
	getencountergroupsessionschema,
	getencounterccintakeschema,
	getencountervisitsschema,
	getGeneTestEnums,
} from '../../actions/encounter';
import { setFilter } from '../../actions/appData';

//Components
import RBAC from '../RBAC';
import PatientsRecords from './PatientsRecords';
import Searchbar from '../Filter/Searchbar';
import ResetFilters from '../Filter/ResetFilters';
import MoreFilters from '../Filter/MoreFilters';

//UI Libraries
import { Loading } from 'gm-element-react';

//Styles
import './style.css';
import './patient-list.css';
import './patient-list-resp.css';
import './patients-list.css';

//Hooks
import { useDebounce } from '../../hooks/useDebounce';
import { usePrevious } from '../../hooks/usePrevious';

export const Patients = (props) => {
	const {
		actions: {
			callccintakeschema,
			callencountergroupsessionschema,
			callGeneTestEnums,
			callOrderStatusEnum,
			callresetpatient,
			callVisitProviderEnum,
			callvisitsschema,
			patientslist,
			resetSearchKey,
			setPatientFilter,
		},
		ccintakeschema,
		enqueueSnackbar,
		groupsessionschema,
		history,
		limit = 25,
		loading,
		MLOOrderStatus = [],
		orderstatusenum,
		patientError = null,
		patientFilter = {},
		patients,
		searchkey: searchKey = '',
		visitProvider,
		visitsschema,
	} = props;

	const { data = {} } = patients;
	const { data: patientsData = [] } = data;

	const defaultState = searchKey
		? {
				user: '',
				patients: {},
				limit,
				offset: 0,
				query: searchKey,
				sort_column: '',
				sort_order: '',
				create_patient: false,
				patient_athena_package: false,
				showCreatePatientV2: false,
				isAllExpanded: false,
		  }
		: {
				user: '',
				patients: {},
				limit: limit || patientFilter.limit || 25,
				offset: patientFilter.offset || 0,
				query: patientFilter.query || '',
				sort_column: patientFilter.sort_column || '',
				sort_order: patientFilter.sort_order || '',
				create_patient: false,
				patient_athena_package:
					patientFilter.patient_athena_package || false,
				showCreatePatientV2: false,
				isAllExpanded: patientFilter.isAllExpanded || false,
		  };

	const [localState, setLocalState] = useState(defaultState);

	const debouncedQuery = useDebounce(localState.query, 500);

	const previousLoading = usePrevious(loading);
	const previousSearchKey = usePrevious(searchKey);

	const updateLocalState = (obj) => {
		setLocalState((state) => ({
			...state,
			...obj,
		}));
	};

	useEffect(() => {
		window.scrollTo(0, 0);

		patientslist({
			offset: localState.offset || 0,
			q: localState.query || '',
			order_by: localState.sort_column || 'created_at',
			order_type: (localState.sort_order || 'desc').toLowerCase(),
			patient_athena_package: localState.patient_athena_package || false,
			limit: limit || 25,
		});
		callVisitProviderEnum({ type: 'visit', field_name: 'visit_provider' });
		callOrderStatusEnum({ type: 'visit', field_name: 'order_status' });

		callencountergroupsessionschema();
		callccintakeschema();
		callvisitsschema();
		callGeneTestEnums();

		document
			?.getElementById('main-container')
			?.classList?.add('patientscroll');
		document
			?.getElementById('main-container')
			?.classList?.remove('rootscroll');

		return () => {
			document
				?.getElementById('main-container')
				?.classList?.remove('patientscroll');
			document
				?.getElementById('main-container')
				?.classList?.add('rootscroll');
			callresetpatient();
		};
	}, []);

	useEffect(() => {
		if (previousSearchKey !== undefined) {
			updateLocalState({ query: searchKey });
		}

		patientslist({
			offset: localState.offset || 0,
			q: searchKey,
			order_by: localState.sort_column || 'created_at',
			order_type: (localState.sort_order || 'desc').toLowerCase(),
			patient_athena_package: localState.patient_athena_package || false,
			limit: limit || 25,
		});
	}, [searchKey]);

	useEffect(() => {
		if (!isNil(patientError)) {
			enqueueSnackbar(
				patientError?.response?.data?.data?.message ||
					'Error in getting patient list',
				{
					variant: 'error',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				}
			);
		}
	}, [patientError]);

	useEffect(() => {
		if (
			patients?.data &&
			(patients?.data?.query == searchKey || searchKey == undefined)
		) {
			updateLocalState({ patients });
		}
	}, [patients]);

	useEffect(() => {
		previousLoading && !loading && setPatientFilter(getFilter());
	}, [loading]);

	useEffect(() => {
		patientslist({
			offset: 0,
			q: debouncedQuery || '',
			patient_athena_package: localState.patient_athena_package || false,
			limit: limit || 25,
			order_by: localState.sort_column || '',
			order_type: (localState.sort_order || '').toLowerCase(),
		});
	}, [debouncedQuery]);

	const getFilter = () => ({
		isAllExpanded: localState.isAllExpanded || false,
		offset: localState.offset || 0,
		query: localState.query || '',
		patient_athena_package: localState.patient_athena_package || false,
		sort_column: localState.sort_column || '',
		sort_order: localState.sort_order || '',
	});

	const handleAthenaFilterChange = (patient_athena_package) => {
		updateLocalState({
			patient_athena_package,
			offset: 0,
		});
		patientslist({
			offset: 0,
			q: localState.query || '',
			order_by: localState.sort_column || 'created_at',
			order_type: (localState.sort_order || 'desc').toLowerCase(),
			patient_athena_package,
			limit: limit || 25,
		});
	};

	const onSearchChange = (e) => {
		const query = e.target.value;
		if (!query) {
			resetSearchKey();
		}
		updateLocalState({
			query,
			offset: 0,
		});
	};

	const selectPatient = (record) => {
		updateLocalState({ selectedPatient: record });
	};

	const showCreatePatientV2 = () => {
		const currentBreadcrumbs = history?.location?.state?.breadcrumbs || [];
		const createPatientUrl = '/app/patientmanagement/';

		if (Array.isArray(currentBreadcrumbs)) {
			history.push({
				pathname: createPatientUrl,
				state: {
					breadcrumbs: [
						{
							location: 'patients',
							url:
								'/app/patients/?offset=' +
								(localState.patients?.data?.offset || 0),
						},
						{
							location: 'Create Patient',
							url: createPatientUrl,
							state: {},
						},
					],
				},
			});
		}
	};

	const handleResultsPerPageChange = (limit) => {
		updateLocalState({ offset: 0, limit });
		patientslist({
			offset: 0,
			q: localState.query || '',
			order_by: localState.sort_column || 'created_at',
			order_type: (localState.sort_order || 'desc').toLowerCase(),
			patient_athena_package: localState.patient_athena_package || false,
			limit,
		});
	};

	const handlePageChange = (e, offset) => {
		updateLocalState({ offset });
		patientslist({
			offset,
			q: localState.query || '',
			order_by: localState.sort_column || 'created_at',
			order_type: (localState.sort_order || 'desc').toLowerCase(),
			patient_athena_package: localState.patient_athena_package || false,
			limit: limit || 25,
		});
	};

	const handleResetFilters = () => {
		resetSearchKey();
		updateLocalState({
			offset: 0,
			sort_column: 'created_at',
			sort_order: 'desc',
			query: '',
			limit: limit || 25,
			patient_athena_package: false,
		});
		patientslist({
			offset: 0,
			q: '',
			patient_athena_package: false,
			limit: limit || 25,
			order_by: 'created_at',
			order_type: 'desc',
		});
	};

	const handleSort = (order_by, order_type) => {
		updateLocalState({
			offset: 0,
			sort_column: order_by,
			sort_order: order_type,
		});
		patientslist({
			offset: 0,
			q: localState.query || '',
			order_by,
			order_type,
			patient_athena_package: localState.patient_athena_package || false,
			limit: limit || 25,
		});
	};

	const setIsAllExpanded = (isAllExpanded) => {
		updateLocalState({ isAllExpanded });
		setPatientFilter({
			...getFilter(),
			isAllExpanded,
		});
	};

	return (
		<div className='patients-list-wrapper'>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					minWidth: 'inherit',
				}}
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<RBAC
						action={CARE_PATIENTS_CREATE}
						yes={
							<div
								data-testId='create-patient'
								className='create-patient'
								onClick={showCreatePatientV2}
							>
								<ReactSVG src={add_patient} />
								Create Patient
							</div>
						}
					/>
				</div>
				<div style={{ display: 'flex' }}>
					<Searchbar
						setStateFunc={onSearchChange}
						currentValue={localState.query || ''}
						useInputEvtObj={true}
						placeholder='Search'
					/>
					<ResetFilters handleResetFilters={handleResetFilters} />
					<RBAC
						action={CARE_PATIENTS_NEEDS_INSURANCE_SELECTION}
						yes={
							<MoreFilters
								pillTitle='Options'
								title='Options'
								data={[
									{
										type: 'checkbox',
										checked:
											localState.patient_athena_package ||
											false,
										defaultValue: false,
										text: 'Needs Insurance Selection',
										submitKey: 'patient_athena_package',
									},
								]}
								submit={({ patient_athena_package }) => {
									handleAthenaFilterChange(
										patient_athena_package
									);
								}}
							/>
						}
					/>
				</div>
			</div>
			<div>
				{loading || !Array.isArray(patientsData) ? (
					<Loading loading={loading} className='list-loading' />
				) : (
					<PatientsRecords
						{...{
							ccintakeschema,
							data: patients.data || [],
							groupsessionschema,
							handlePageChange,
							handleResetFilters,
							handleResultsPerPageChange,
							handleSort,
							history,
							isAllExpanded: localState.isAllExpanded || false,
							MLOOrderStatus,
							orderStatus:
								orderstatusenum?.data?.order_status || {},
							patientstate: { ...localState },
							selectedPatient: selectPatient,
							setIsAllExpanded: setIsAllExpanded,
							visitProvider:
								visitProvider?.data?.visit_provider || {},
							visitsschema: visitsschema,
						}}
					/>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	ccintakeschema: state.ccintakeschema.schema,
	groupsessionschema: state.groupsessionschema.schema,
	limit: state.uiConfig.table.resultsPerPage,
	loading: state.patients.loading,
	MLOOrderStatus: get(
		state,
		['gentestenums', 'gentestenums', 'order_status'],
		[]
	),
	orderstatusenum: state.dashboardencounters.orderstatusenum,
	patientError: state.patients.patientError,
	patientFilter: get(state, 'appData.patients', null) || {},
	patients: state.patients.patients,
	searchkey: state.searchkey.searchkey,
	visitProvider: state.dashboardencounters.visitproviderenum,
	visitsschema: state.visitsschema.schema,
});

const mapDispatchToProps = (dispatch) => ({
	actions: {
		callccintakeschema: () => dispatch(getencounterccintakeschema()),
		callencountergroupsessionschema: () =>
			dispatch(getencountergroupsessionschema()),
		callGeneTestEnums: () => dispatch(getGeneTestEnums()),
		callOrderStatusEnum: (data) => dispatch(getspecificencounterenum(data)),
		callresetpatient: () => dispatch(resetPatient([])),
		callVisitProviderEnum: (data) =>
			dispatch(getspecificencounterenum(data)),
		callvisitsschema: () => dispatch(getencountervisitsschema()),
		patientslist: (data) => dispatch(getpatients(data)),
		resetSearchKey: () => dispatch(resetsearchkey()),
		setPatientFilter: (data) => dispatch(setFilter('patients', data)),
	},
});

Patients.propTypes = {
	auth: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
