//React & Redux
import React, { Component, Fragment } from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from '../../../../../assets/exit-alert.svg';
import timer_off_icon from '../../../../../assets/icons/timer-off.svg';
import timer_on_icon from '../../../../../assets/icons/timer-24.svg';
//Lodash
import _ from 'lodash';

//Utils
import {
	OutreachWarnMsg,
	OUTREACH_ON_HOLD_WARN_MSG,
	OUTREACH_WAITING_TO_START_WARN_MSG,
	OUTREACH_INPROGRESS_WARN_MSG,
} from './utilsfunctions';

//UI Libraries
import { Checkbox, Button, Loading } from 'gm-element-react';

//Components
import WarningMessageBox from '../../../../Common/WarningMessageBox';

//Styles
import '../../TestOrders/create-test-request-modal.css';

class HoldResultReleasePopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			hold_result_release: _.get(
				this,
				['props', 'chk_hold_release_result'],
				null
			),
			showOutreachWarningMsg: false,
		};
		this.editEncounterData = this.editEncounterData.bind(this);
		this.onChangeHoldRelease = this.onChangeHoldRelease.bind(this);
	}

	editEncounterData = () => {
		const encounterrequestdata = {
			hold_result_release: _.get(
				this,
				['state', 'hold_result_release'],
				null
			),
		};

		this.setState({ loading: true });
		this.props
			.editencouterOnHoldResultRelease(encounterrequestdata)
			.then((data) => {
				this.setState({ loading: false });
				this.props.hideModal();
			})
			.catch((error) => {
				this.setState({ loading: false });
			});
	};

	onChangeHoldRelease(value) {
		this.setState({
			hold_result_release: value,
			showOutreachWarningMsg: true,
		});
	}

	renderMultipleOutreachWarnMsg = (msg, testNames) => {
		return (
			<Fragment>
				<span>{msg}</span>
				{_.map(testNames, (test, i) => {
					return (
						<span>
							{i + 1}) {test}
						</span>
					);
				})}
			</Fragment>
		);
	};

	getOutreachWarningMsg = () => {
		const testOrderList = _.get(this, ['props', 'testOrderList'], {});
		const msgObj = OutreachWarnMsg(testOrderList, {
			holdResultRelease: _.get(
				this,
				['state', 'hold_result_release'],
				false
			),
		});

		if (!_.isEmpty(_.get(msgObj, ['testNames'], []))) {
			switch (msgObj.warnMsg) {
				case OUTREACH_ON_HOLD_WARN_MSG:
					return {
						warningMsg: this.renderMultipleOutreachWarnMsg(
							'ROR Outreach will be on HOLD for the following test(s):',
							msgObj.testNames
						),
						warningIcon: timer_off_icon,
						boxBGColor: 'bg-gray',
						extndWarnText: 'outreach-hold-warn-text',
						extndWarnIcon: 'outreach-hold-warn-icon',
						testNames: msgObj.testNames,
					};
				case OUTREACH_WAITING_TO_START_WARN_MSG:
					return {
						warningMsg: this.renderMultipleOutreachWarnMsg(
							'ROR Outreach will start for the following test(s) in an hour:',
							msgObj.testNames
						),
						warningIcon: timer_on_icon,
						boxBGColor: 'bg-yellow',
						extndWarnText: 'outreach-start-warn-text',
						extndWarnIcon: 'outreach-hold-warn-icon',
						testNames: msgObj.testNames,
					};
				case OUTREACH_INPROGRESS_WARN_MSG:
					return {
						warningMsg: this.renderMultipleOutreachWarnMsg(
							'The following test(s) will be added to the RoR Outreach InProgress:',
							msgObj.testNames
						),
						warningIcon: timer_on_icon,
						boxBGColor: 'bg-yellow',
						extndWarnText: 'outreach-start-warn-text',
						extndWarnIcon: 'outreach-hold-warn-icon',
						testNames: msgObj.testNames,
					};
				default:
					return false;
			}
		}
		return false;
	};

	render() {
		const holdResultWarnMsg = this.getOutreachWarningMsg();

		return (
			<div className='hold-result-release-modal-container'>
				<Loading loading={this.state.loading}>
					<div className='hold-result-release-modal-top-row'>
						<div className='hold-result-release-modal-header'>
							Hold Release Flag
						</div>
						<div className='createTestOrderModalClose'>
							<ReactSVG
								src={xIcon}
								style={{
									position: 'relative',
									bottom: '2px',
									right: '1.25px',
								}}
								onClick={(e) => {
									this.props.hideModal();
								}}
							/>
						</div>
					</div>
					<div className='hold-result-release-modal-body'>
						<Checkbox
							checked={_.get(
								this,
								['state', 'hold_result_release'],
								null
							)}
							className='chk-hold-result-release'
							onChange={(value) =>
								this.onChangeHoldRelease(value)
							}
						>
							Hold release until ALL results received
						</Checkbox>

						<span className='chk-hold-result-release-note'>
							When enabled, patient will not appear on results CAP
							card, nor be notified results are in, until all
							results are received.
						</span>
						{this.state.showOutreachWarningMsg &&
							holdResultWarnMsg && (
								<WarningMessageBox {...holdResultWarnMsg} />
							)}
					</div>
					<div className='hold-result-release-modal-buttonrow'>
						<Button
							className='hold-result-release-save-button'
							onClick={this.editEncounterData}
						>
							Save
						</Button>
					</div>
				</Loading>
			</div>
		);
	}
}

export default HoldResultReleasePopup;
