//React & Redux
import React from 'react';
import PropTypes from 'prop-types';

//Lodash
import { isEmpty, isNil } from 'lodash';

//Other Libraries
import classnames from 'classnames';

//Components
import CommonCheckbox from './../../../../Common/Checkbox.js';
import ExternalProviderForm from './ExternalProviderForm';
import { AMEND, FINALIZE } from '../../../../../Utils/documentUtils';
import DocumentNotice from '../DocumentNotice';

const SelectRecipients = (props) => {
	const {
		canBeReleasedAPI = false,
		document,
		encounterProviders = [],
		formdata = {},
		getSelectedEncounterProviders,
		getSelectedPatientProviders,
		isAlreadyReleasedToPP = false,
		isPreviousReleasedToPP = false,
		isReleasedAPI = false,
		onChange,
		onChangeEncounterProviderData,
		onChangeEncounterProviderSelection,
		onChangePatientProviderData,
		onChangePatientProviderSelection,
		partnerName = '',
		patientName = '',
		patientProviders = [],
		patientTag = '',
		source,
		updateEncounterProvider,
		updatePatientProvider,
	} = props;

	const notes = {
		[FINALIZE]:
			'Document was finalized successfully. You can select recipients below to receive this document or you can skip this step for now.',
		[AMEND]:
			'Document was amended successfully. You can select recipients below to receive this document or you can skip this step for now.',
	};

	const isReleaseToPPDisbled =
		isAlreadyReleasedToPP || isPreviousReleasedToPP;
	const isAlreadyReleasedToPartner = !canBeReleasedAPI && isReleasedAPI;
	const isDocumentReleaseToExtEhr = document?.doc_release_to_ext_ehr || false;

	return (
		<div className='selectRecipientsContainer'>
			<DocumentNotice
				document={document}
				icon='check'
				message={notes[source]}
				theme='gray'
				visible={source == FINALIZE || source == AMEND}
			/>
			<div className='body-section'>
				<div className='releaseDocinputcontainer'>
					<div className='releaseDocInputHeaderContainer'>
						<div className='releaseDocInputHeader'>
							Patient or Guardian
						</div>
					</div>
					<div
						className={classnames(
							'releaseDocInputElementContainer checkbox-container',
							{ active: formdata.release_to_patient }
						)}
					>
						<div
							data-testId='control-row'
							className={classnames('control-row', {
								'is-disabled': isReleaseToPPDisbled,
							})}
							onClick={(e) => {
								e.preventDefault();
								onChange(
									'release_to_patient',
									!formdata.release_to_patient
								);
							}}
						>
							<div className='left-info'>
								<CommonCheckbox
									checked={formdata.release_to_patient}
									className='templateTableCheckbox'
									marginLeftOverride={0}
									disabled={isReleaseToPPDisbled}
								/>
							</div>
							<div className='center-info'>
								<span className='extra-bold-info'>
									{patientName}
								</span>
							</div>
							<div className='right-info'>
								<span className='extra-info'>{patientTag}</span>
							</div>
						</div>
						<div className='control-row'>
							<div className='left-info'></div>
							<div className='center-info'>
								<span className='extra-medium-info'>
									Release via Portal
								</span>
							</div>
						</div>
						{!isNil(formdata?.send_email_notification) && (
							<div
								className='control-row clickable'
								onClick={(e) => {
									e.preventDefault();
									onChange(
										'send_email_notification',
										!formdata.send_email_notification
									);
								}}
							>
								<div className='left-info'></div>
								<div className='center-info1'>
									<CommonCheckbox
										checked={
											formdata.release_to_patient &&
											formdata?.send_email_notification
										}
										marginLeftOverride={0}
									/>
									<span className='extra-medium-info'>
										Send email notification to patient
									</span>
								</div>
							</div>
						)}
						{isAlreadyReleasedToPP && (
							<div className='control-row'>
								<div className='left-info'></div>
								<div className='center-info'>
									<div className='hint-contianer'>
										<span>
											Document has been already released
											to portal
										</span>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				{(canBeReleasedAPI || isReleasedAPI) && (
					<div className='releaseDocinputcontainer'>
						<div className='releaseDocInputHeaderContainer'>
							<div className='releaseDocInputHeader'>
								Referral Program
							</div>
						</div>
						<div
							className={classnames(
								'releaseDocInputElementContainer checkbox-container',
								{ is_disabled: isAlreadyReleasedToPartner },
								{ active: formdata.release_to_partner }
							)}
						>
							<div>
								<div
									className='control-row clickable'
									data-testId='control-row'
									onClick={(e) => {
										e.preventDefault();
										onChange(
											'release_to_partner',
											!formdata.release_to_partner
										);
									}}
								>
									<div className='left-info'>
										<CommonCheckbox
											checked={
												isAlreadyReleasedToPartner
													? false
													: formdata.release_to_partner
											}
											className='templateTableCheckbox'
											marginLeftOverride={0}
										/>
									</div>
									<div className='center-info'>
										<span className='extra-bold-info'>
											{partnerName}
										</span>
									</div>
									<div className='right-info'></div>
								</div>
								<div className='control-row'>
									<div className='left-info'></div>
									<div className='center-info'>
										<span className='extra-medium-info'>
											Release via API
										</span>
									</div>
								</div>
							</div>
							{isAlreadyReleasedToPartner && (
								<div className='control-row'>
									<div className='left-info'></div>
									<div className='center-info'>
										<div className='hint-contianer'>
											<span>
												Document has been already
												released to partner
											</span>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
			{encounterProviders.length > 0 && (
				<div className='body-section'>
					<div className='releaseDocinputcontainer'>
						<div className='releaseDocInputHeaderContainer'>
							<div className='releaseDocInputHeader'>
								External providers linked to encounter
							</div>
						</div>
						{encounterProviders.map((provider) => {
							const uuid = provider.uuid || '';
							const providerFormData =
								getSelectedEncounterProviders(uuid);
							return (
								<ExternalProviderForm
									key={`${uuid}-encounterProvider`}
									onChangeProviderData={
										onChangeEncounterProviderData
									}
									onChangeProviderSelection={
										onChangeEncounterProviderSelection
									}
									providerformdata={providerFormData}
									provider={provider}
									isSelected={!isEmpty(providerFormData)}
									updateProvider={updateEncounterProvider}
								/>
							);
						})}
					</div>
				</div>
			)}

			{patientProviders.length > 0 && (
				<div className='body-section'>
					<div className='releaseDocinputcontainer'>
						<div className='releaseDocInputHeaderContainer'>
							<div className='releaseDocInputHeader'>
								External providers linked to Patient
							</div>
						</div>
						{patientProviders.map((provider) => {
							const uuid = provider.uuid || '';
							const providerFormData =
								getSelectedPatientProviders(uuid);
							return (
								<ExternalProviderForm
									key={`${uuid}-patientProvider`}
									onChangeProviderData={
										onChangePatientProviderData
									}
									onChangeProviderSelection={
										onChangePatientProviderSelection
									}
									providerformdata={providerFormData}
									provider={provider}
									isSelected={!isEmpty(providerFormData)}
									updateProvider={updatePatientProvider}
								/>
							);
						})}
					</div>
				</div>
			)}

			{isDocumentReleaseToExtEhr && (
				<div className='body-section'>
					<div className='releaseDocinputcontainer'>
						<div className='releaseDocInputHeaderContainer'>
							<div className='releaseDocInputHeader'>
								External EHR Linked to Patient
							</div>
						</div>
						<div
							className={classnames(
								'releaseDocInputElementContainer checkbox-container',
								{ active: formdata.release_to_external_EHR }
							)}
						>
							<div
								className='control-row clickable'
								onClick={(e) => {
									e.preventDefault();
									onChange(
										'release_to_external_EHR',
										!formdata.release_to_external_EHR
									);
								}}
							>
								<div className='left-info'>
									<CommonCheckbox
										checked={
											formdata.release_to_external_EHR
										}
										className='templateTableCheckbox'
										marginLeftOverride={0}
									/>
								</div>
								<div className='center-info'>
									<span className='extra-bold-info'>
										Release to External EHR
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			<div className='body-section'>
				<div className='note'>
					Documents can only be sent to External Providers linked to
					the Patient and their encounters.{' '}
				</div>
			</div>
		</div>
	);
};

SelectRecipients.propTypes = {
	document: PropTypes.object.isRequired,
	formdata: PropTypes.object.isRequired,
	patientTag: PropTypes.string.isRequired,
	patientName: PropTypes.string.isRequired,
	isAlreadyReleasedToPP: PropTypes.bool.isRequired,
	isPreviousReleasedToPP: PropTypes.bool.isRequired,
};
export default SelectRecipients;
