//React & Redux
import React, { Component } from 'react';

//Other Libraries
import _ from 'lodash';

//Components
import { imageDefault, providers_images } from '../../Utils/encounterUtils';

//Styles
import './provider-info.css';

class ProviderInfo extends Component {
	ucFirstAllWords = (str, separator = ' ') => {
		const pieces = (str && str.split(separator)) || [];
		for (let i = 0; i < pieces.length; i++) {
			const j = pieces[i].charAt(0).toUpperCase();
			pieces[i] = j + pieces[i].substr(1).toLowerCase();
		}
		return pieces.join(separator);
	};

	getProviderName = (providerenum, providerName) => {
		const fullName = _.get(this, ['props', 'fullName'], false);
		const enum_value = _.find(providerenum, function (o) {
			return o.key == providerName;
		});
		let separator = ' ';

		if (enum_value) {
			providerName = _.get(enum_value, 'display_name', '');
		} else {
			separator = '_';
			providerName = this.ucFirstAllWords(providerName, separator);
		}
		if (providerName && providerName.length > 0) {
			const ProviderNameobj = providerName.split(separator);
			const first_name = _.get(ProviderNameobj, 0, '');
			const middle_name =
				providerName.length > 2
					? ' ' + _.get(ProviderNameobj, 1, '')
					: '';
			const last_name =
				providerName.length > 2
					? ' ' + _.get(ProviderNameobj, 2, '')
					: ' ' + _.get(ProviderNameobj, 1, '');

			return (
				(fullName ? first_name : `${first_name.charAt(0)} .`) +
				middle_name +
				last_name
			);
		} else {
			return '--';
		}
	};

	getProviderImage = (providerName = '--') => {
		let providerImage = null;
		const mappedImage = providers_images[providerName];

		if (providerName && mappedImage) {
			providerImage = <img src={mappedImage} alt='' />;
		} else {
			providerImage = <img src={imageDefault} alt='' />;
		}
		return providerImage;
	};

	render() {
		const { providerenum, providerName } = this.props;
		return (
			<div className='provider-wrapper'>
				<span className='provider-image'>
					{this.getProviderImage(providerName)}
				</span>
				<span className='provider-name'>
					{this.getProviderName(providerenum, providerName)}
				</span>
			</div>
		);
	}
}

export default ProviderInfo;
