import React from 'react';

import get from 'lodash/get';

import ICDTag from './ICDTag.js';

class BillingInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: new Set(),
		};
		//bindings
		this.handleTextChange = this.handleTextChange.bind(this);
		this.regressStage = this.regressStage.bind(this);
		this.canAdvance = this.canAdvance.bind(this);
		this.inputsAreValid = this.inputsAreValid.bind(this);
		this.addError = this.addError.bind(this);
		this.resetErrors = this.resetErrors.bind(this);
		this.finalAction = this.finalAction.bind(this);
	}
	//functions

	async componentDidUpdate(prevProps, prevState) {
		const directCareTime = get(this, 'props.directCareTime', '');
		const indirectCareTime = get(this, 'props.indirectCareTime', '');

		const prevDirectCareTime = get(prevProps, 'directCareTime', '');
		const prevIndirectCareTime = get(prevProps, 'indirectCareTime', '');

		if (
			directCareTime !== prevDirectCareTime ||
			indirectCareTime !== prevIndirectCareTime
		) {
			await this.resetErrors();
			this.inputsAreValid();
		}
	}

	handleTextChange(e, header) {
		const input = get(e, 'target.value', '');
		this.props.setInputValue(header, input);
	}

	regressStage() {
		this.props.setCurrentStage('icd');
	}

	canAdvance() {
		return (
			get(this, 'props.directCareTime', '') &&
			get(this, 'props.indirectCareTime', '') &&
			get(this, 'state.errors', new Set()).size === 0
		);
	}

	inputsAreValid() {
		const directCareTime = get(this, 'props.directCareTime', '') || '';
		const indirectCareTime = get(this, 'props.indirectCareTime', '') || '';

		if (directCareTime.match(/[^\d]/gi)) this.addError('directCareTime');
		if (indirectCareTime.match(/[^\d]/gi))
			this.addError('indirectCareTime');
	}

	addError(fieldName) {
		const currentErrors = get(this, 'state.errors', new Set());
		currentErrors.add(fieldName);
		this.setState({ errors: currentErrors });
	}

	resetErrors() {
		return new Promise((resolve, reject) => {
			this.setState({ errors: new Set() }, resolve);
		});
	}

	finalAction() {
		if (this.canAdvance()) {
			this.props.encounterCall();
		}
	}

	render() {
		const selectedCodes = get(this, 'props.selectedICDCodes', []) || [];
		const buttonClass = this.canAdvance()
			? 'chargeModalActionButton'
			: 'chargeModalActionButton chargeModalActionButtonDisabled';
		return (
			<div className='chargeICDBillingContainer'>
				<div className='chargeICDBillingFieldCollectionContainer'>
					<div className='chargeICDBillingFieldContainer'>
						<div className='chargeICDBillingRequiredField'>*</div>
						<div
							style={{
								fontSize: '13px',
								color: '#262837',
								marginBottom: '3px',
							}}
						>
							ICD-10 Code(s) (Visit)
						</div>
						<div style={{ display: 'flex', flexWrap: 'wrap' }}>
							{selectedCodes.map((el) => {
								return (
									<ICDTag
										icdCode={el}
										toggleCodeSelection={
											this.props.toggleCodeSelection
										}
										hasX={false}
									/>
								);
							})}
						</div>
					</div>

					<div className='chargeICDBillingFieldContainer'>
						<div className='chargeICDBillingRequiredField'>*</div>
						<div className='chargeICDBillingTextField'>
							Procedure Code
						</div>
						<div className='chargeICDBillingTextFieldInputUneditable'>
							96040,95
						</div>
					</div>

					<div className='chargeICDBillingFieldContainer'>
						<div className='chargeICDBillingRequiredField'>*</div>
						<div className='chargeICDBillingTextField'>
							Time Spent{' '}
							<span style={{ fontFamily: 'RionaSans-Bold ' }}>
								Direct
							</span>{' '}
							Clinical Care (Minutes)
						</div>
						<input
							className='chargeICDBillingTextFieldInput'
							value={get(this, 'props.directCareTime', '')}
							onChange={(e) =>
								this.handleTextChange(e, 'directCareTime')
							}
							placeholder='XX Minutes'
						/>
						{get(this, 'state.errors', new Set()).has(
							'directCareTime'
						) ? (
							<div className='chargeICDBillingTextFieldError'>
								Please only enter whole numbers
							</div>
						) : (
							''
						)}
					</div>

					<div className='chargeICDBillingFieldContainer'>
						<div className='chargeICDBillingRequiredField'>*</div>
						<div className='chargeICDBillingTextField'>
							Time Spent{' '}
							<span style={{ fontFamily: 'RionaSans-Bold ' }}>
								Indirect
							</span>{' '}
							Clinical Care (Minutes)
						</div>
						<input
							className='chargeICDBillingTextFieldInput'
							value={get(this, 'props.indirectCareTime', '')}
							onChange={(e) =>
								this.handleTextChange(e, 'indirectCareTime')
							}
							placeholder='XX Minutes'
						/>
						{get(this, 'state.errors', new Set()).has(
							'indirectCareTime'
						) ? (
							<div className='chargeICDBillingTextFieldError'>
								Please only enter whole numbers
							</div>
						) : (
							''
						)}
					</div>
				</div>

				<div className='chargeModalICDButtonRow chargeModalICDButtonRowBilling'>
					<div
						className='chargeModalBackButton'
						onClick={this.regressStage}
					>
						Back
					</div>
					<div className={buttonClass} onClick={this.finalAction}>
						{get(this, 'props.buttonText', 'Finalize')}
					</div>
				</div>
			</div>
		);
	}
}

export default BillingInfo;
