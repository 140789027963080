import * as actionTypes from './actionTypes';
import UserRolesService from '../service/UserRoles';
import _ from 'lodash';

export const startLoadUserRoles = () => {
	return { type: actionTypes.LOAD_USER_ROLES_START };
};

export function successLoadUserRoles(users) {
	return { type: actionTypes.LOAD_USER_ROLES_SUCCESS, payload: users };
}

export function failureLoadUserRoles(error) {
	return { type: actionTypes.LOAD_USER_ROLES_FAILURE, payload: error };
}

export function LoadUserRoles() {
	return (dispatch) => {
		dispatch(startLoadUserRoles());
		return UserRolesService.get()
			.then((res) => {
				return dispatch(
					successLoadUserRoles(_.get(res, ['data', 'data'], []))
				);
			})
			.catch((err) => {
				dispatch(failureLoadUserRoles(err));
			});
	};
}
