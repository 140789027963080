import * as actionTypes from '../../actions/actionTypes';

export const defaultState = {
	genesEnum: [],
	genesTestResultEnums: {},
	addingGeneResult: false,
	addedGeneResult: null,
	addingGeneResultError: null,
	updatingGeneResult: false,
	updatedGeneResult: null,
	updatingGeneResultError: null,
	geneEnum_Edit: [],
};

export default (state = defaultState, action = {}) => {
	switch (action.type) {
		case actionTypes.LOADING_GENE_RESULTS_ENUM:
			return {
				...state,
				genesTestResultEnumsLoading: true,
				geneTestResultEnumsError: null,
			};

		case actionTypes.GETTING_GENE_RESULTS_ENUM:
			return {
				...state,
				genesTestResultEnums: action.payload,
				genesTestResultEnumsLoading: false,
			};

		case actionTypes.GENE_RESULTS_ENUM_ERROR:
			return {
				...state,
				genesTestResultEnumsLoading: false,
				geneTestResultEnumsError: action.payload,
			};

		case actionTypes.LOADING_GENETIC_RESULT_GENES:
			return {
				...state,
				loadingGenesEnum: false,
				geneEnumError: null,
			};

		case actionTypes.GETTING_GENETIC_RESULT_GENES:
			return {
				...state,
				loadingGenesEnum: false,
				genesEnum: action.payload,
			};

		case actionTypes.GENETIC_RESULT_GENES_ERROR:
			return {
				...state,
				loadingGenesEnum: false,
				geneEnumError: action.payload,
			};

		case actionTypes.LOADING_GENETIC_RESULT_GENES_EDIT:
			return {
				...state,
				loadingGeneEnumEdit: true,
				geneEnumEditError: null,
			};

		case actionTypes.GETTING_GENETIC_RESULT_GENES_EDIT:
			return {
				...state,
				loadingGeneEnumEdit: false,
				geneEnum_Edit: action.payload,
			};

		case actionTypes.GENETIC_RESULT_GENES_EDIT_ERROR:
			return {
				...state,
				loadingGeneEnumEdit: true,
				geneEnumEditError: action.payload,
			};

		case actionTypes.ADDING_GENE_RESULTS:
			return {
				...state,
				addingGeneResult: true,
				addedGeneResult: null,
				addingGeneResultError: null,
			};

		case actionTypes.ADDED_GENE_RESULTS:
			return {
				...state,
				addingGeneResult: false,
				addedGeneResult: action.payload,
				addingGeneResultError: null,
			};

		case actionTypes.ADD_GENE_RESULTS_ERROR:
			return {
				...state,
				addingGeneResult: false,
				addedGeneResult: null,
				addingGeneResultError: action.payload,
			};

		case actionTypes.UPDATING_GENE_RESULTS:
			return {
				...state,
				updatingGeneResult: true,
				updatedGeneResult: null,
				updatingGeneResultError: null,
			};

		case actionTypes.UPDATED_GENE_RESULTS:
			return {
				...state,
				updatingGeneResult: false,
				updatedGeneResult: action.payload,
				updatingGeneResultError: null,
			};

		case actionTypes.UPDATE_GENE_RESULTS_ERROR:
			return {
				...state,
				updatingGeneResult: false,
				updatedGeneResult: null,
				updatingGeneResultError: action.payload,
			};

		default:
			return state;
	}
};
