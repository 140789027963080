import React from 'react';

//Lodash
import _ from 'lodash';

//Utils
import { selectedExtProviderRoles } from '../../../../../utils';
import { FAX, EMAIL, modality } from '../../../../../Utils/documentUtils';

//Other Libraries
import classnames from 'classnames';

//Components
import CommonCheckbox from './../../../../Common/Checkbox.js';
import { Input, Radio } from 'gm-element-react';
import InputMask from 'react-input-mask';

import EmailValidator from 'email-validator';
import { isValidNumber } from 'libphonenumber-js';

const ExternalProviderForm = (props) => {
	const uuid = _.get(props, ['provider', 'uuid']);
	const isArchived = _.get(props, ['provider', 'is_archived'], false);
	const isDisbledProviderInput = (key) => {
		const provider = _.get(props, ['provider'], []);
		const providervalue = provider && provider[key];
		return !_.isEmpty(providervalue) && !_.isNil(providervalue);
	};

	const getProviderInputValue = (key) => {
		const provider = _.get(props, ['provider'], []);
		const providervalue = provider && provider[key];
		const formvalue = _.get(props, ['providerformdata', 'value'], '');
		return !_.isEmpty(providervalue) && !_.isNil(providervalue)
			? providervalue
			: formvalue;
	};

	const isValidInput = (key) => {
		const formvalue = _.get(props, ['providerformdata', 'value'], '');
		if (key == FAX) {
			return isValidNumber(formvalue);
		} else if (key == EMAIL) {
			return EmailValidator.validate(formvalue);
		}
		return true;
	};

	const isError = (key) => {
		const formvalue = _.get(props, ['providerformdata', 'value'], '');
		const touch = _.get(props, ['providerformdata', 'touch'], false);
		const apierror = _.get(props, ['providerformdata', 'error'], '');
		if (key == FAX) {
			return (
				(!isArchived &&
					touch &&
					!isDisbledProviderInput(key) &&
					!isValidNumber(formvalue)) ||
				(!isArchived && !_.isEmpty(apierror))
			);
		} else if (key == EMAIL) {
			return (
				(!isArchived &&
					touch &&
					!isDisbledProviderInput(key) &&
					!EmailValidator.validate(formvalue)) ||
				(!isArchived && !_.isEmpty(apierror))
			);
		}
		return false;
	};

	const getErrorMessage = (key) => {
		const apierror = _.get(props, ['providerformdata', 'error'], '');
		if (key == FAX) {
			return !_.isEmpty(apierror) ? apierror : 'Enter valid eFAX';
		} else if (key == EMAIL) {
			return !_.isEmpty(apierror) ? apierror : 'Enter valid Email';
		}
		return '';
	};

	const submitInput = (key) => {
		if (isValidInput(key)) {
			props.updateProvider(uuid, key);
		}
	};

	const clearInput = () => {
		props.onChangeProviderData(uuid, 'value', '');
	};

	return (
		<div
			className={classnames(
				'releaseDocInputElementContainer checkbox-container',
				{ active: props.isSelected }
			)}
		>
			<div
				className='control-row  clickable'
				onClick={(e) => {
					e.preventDefault();
					props.onChangeProviderSelection(uuid, !props.isSelected);
				}}
			>
				<div className='left-info'>
					<CommonCheckbox
						checked={props.isSelected}
						className='templateTableCheckbox'
						marginLeftOverride={0}
					/>
				</div>
				<div className='center-info'>
					<span className='extra-bold-info'>
						{_.get(props, ['provider', 'fullName'], '')}
					</span>
				</div>
				<div className='right-info'>
					<span className='extra-info'>
						{_.join(
							selectedExtProviderRoles(
								_.get(props, ['provider', 'provider_role'], [])
							),
							'/'
						)}
					</span>
				</div>
			</div>
			{isArchived && (
				<div className='control-row'>
					<div className='left-info'></div>
					<div className='enter-info'>
						<div className='archived-text'>Provider Archived</div>
					</div>
				</div>
			)}
			<div style={{ marginTop: '8px' }}>
				<div className='control-row'>
					<div className='left-info'></div>
					<div className='center-info'>
						<div
							className={classnames('provider-medium-controls', {
								disbled: !props.isSelected,
							})}
						>
							<Radio
								value={FAX}
								style={{
									marginBottom:
										_.get(
											props,
											['providerformdata', 'medium'],
											null
										) == FAX
											? '4px'
											: '8px',
								}}
								checked={
									FAX ==
									_.get(
										props,
										['providerformdata', 'medium'],
										null
									)
								}
								onChange={(e) => {
									props.onChangeProviderData(
										uuid,
										'medium',
										FAX
									);
								}}
							>
								{modality[FAX]}
							</Radio>
							{_.get(
								props,
								['providerformdata', 'medium'],
								null
							) == FAX && (
								<div className='provider-input'>
									<InputMask
										placeholder={'+1 (123) 456-7890'}
										className={classnames({
											'input-error': isError(FAX),
										})}
										value={getProviderInputValue(FAX)}
										onChange={(e) => {
											props.onChangeProviderData(
												uuid,
												'value',
												e.target.value
											);
										}}
										disabled={isDisbledProviderInput(FAX)}
										autoComplete='off'
										mask={'+1 (999) 999-9999'}
										maskChar={null}
									/>
									{isError(FAX) && (
										<div className='error-msg'>
											{getErrorMessage(FAX)}
										</div>
									)}
									{!isDisbledProviderInput(FAX) &&
										!isArchived && (
											<div
												className={classnames(
													'quick-actions'
												)}
											>
												<span
													data-testID='submit-input'
													onClick={() =>
														submitInput(FAX)
													}
												>
													Save
												</span>
												<span
													onClick={() =>
														clearInput(FAX)
													}
												>
													Cancel
												</span>
											</div>
										)}
								</div>
							)}

							<Radio
								value={EMAIL}
								style={{
									marginBottom:
										_.get(
											props,
											['providerformdata', 'medium'],
											null
										) == EMAIL
											? '4px'
											: '8px',
								}}
								checked={
									EMAIL ==
									_.get(
										props,
										['providerformdata', 'medium'],
										null
									)
								}
								onChange={(e) => {
									props.onChangeProviderData(
										uuid,
										'medium',
										EMAIL
									);
								}}
							>
								{modality[EMAIL]}
							</Radio>
							{_.get(
								props,
								['providerformdata', 'medium'],
								null
							) == EMAIL && (
								<div className='provider-input'>
									<Input
										placeholder={'email@email.com'}
										className={classnames({
											'input-error': isError(EMAIL),
										})}
										value={getProviderInputValue(EMAIL)}
										disabled={isDisbledProviderInput(EMAIL)}
										onChange={(e) => {
											props.onChangeProviderData(
												uuid,
												'value',
												e
											);
										}}
									/>
									{isError(EMAIL) && (
										<div className='error-msg'>
											{getErrorMessage(EMAIL)}
										</div>
									)}
									{!isDisbledProviderInput(EMAIL) &&
										!isArchived && (
											<div className='quick-actions'>
												<span
													onClick={() =>
														submitInput(EMAIL)
													}
												>
													Save
												</span>
												<span
													onClick={() =>
														clearInput(EMAIL)
													}
												>
													Cancel
												</span>
											</div>
										)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExternalProviderForm;
