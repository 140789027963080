import * as actionTypes from '../actions/actionTypes';

const defaultGetInsuranceCompaniesState = {
	loadingGetInsuranceCompanies: false,
	loadedGetInsuranceCompanies: null,
	errorGetInsuranceCompanies: null,
};

const defaultCreateInsuranceCompanyState = {
	creatingInsuranceCompany: false,
	createdInsuranceCompany: null,
	createdInsuranceCompanyError: null,
};

const defaultUpdateInsuranceCompanyState = {
	updatingInsuranceCompany: false,
	updatedInsuranceCompany: null,
	updatedInsuranceCompanyError: null,
};

export const defaultState = {
	...defaultGetInsuranceCompaniesState,
	...defaultCreateInsuranceCompanyState,
	...defaultUpdateInsuranceCompanyState,
};

export default (state = defaultState, action = {}) => {
	switch (action.type) {
		case actionTypes.LOADING_GET_INSURANCE_COMPANIES:
			return {
				...state,
				loadingGetInsuranceCompanies: true,
			};

		case actionTypes.LOADED_GET_INSURANCE_COMPANIES:
			return {
				...state,
				loadingGetInsuranceCompanies: false,
				loadedGetInsuranceCompanies: action.payload,
				errorGetInsuranceCompanies: null,
			};

		case actionTypes.ERROR_GET_INSURANCE_COMPANIES:
			return {
				...state,
				loadingGetInsuranceCompanies: false,
				loadedGetInsuranceCompanies: null,
				errorGetInsuranceCompanies: action.payload,
			};

		case actionTypes.CREATING_INSURANCE_COMPANY:
			return {
				...state,
				creatingInsuranceCompany: true,
				createdInsuranceCompany: null,
				createdInsuranceCompanyError: null,
			};

		case actionTypes.CREATED_INSURANCE_COMPANY:
			return {
				...state,
				creatingInsuranceCompany: false,
				createdInsuranceCompany: action.payload,
				createdInsuranceCompanyError: null,
			};

		case actionTypes.CREATED_INSURANCE_COMPANY_ERROR:
			return {
				...state,
				creatingInsuranceCompany: false,
				createdInsuranceCompany: null,
				createdInsuranceCompanyError: action.payload,
			};

		case actionTypes.UPDATING_INSURANCE_COMPANY:
			return {
				...state,
				updatingInsuranceCompany: true,
				updatedInsuranceCompany: null,
				updatedInsuranceCompanyError: null,
			};

		case actionTypes.UPDATED_INSURANCE_COMPANY:
			return {
				...state,
				updatingInsuranceCompany: false,
				updatedInsuranceCompany: action.payload,
				updatedInsuranceCompanyError: null,
			};

		case actionTypes.UPDATED_INSURANCE_COMPANY_ERROR:
			return {
				...state,
				updatingInsuranceCompany: false,
				updatedInsuranceCompany: null,
				updatedInsuranceCompanyError: action.payload,
			};

		default:
			return state;
	}
};
