//React/Redux
import React, { Fragment } from 'react';

// Lodash
import { get } from 'lodash';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../assets/close.svg';

//UI Libraries
import { Loading } from 'gm-element-react';

//Style
import './AuditTrailModal.css';

export default function AuditTrailModal({
	hideAuditTrailModal,
	loading,
	loaded,
	logs,
}) {
	const getTitle = (log) => {
		switch (get(log, ['operation', 'name'], '')) {
			case 'role_assigned':
			case 'role_removed':
				return (
					<Fragment>
						<span>{get(log, ['role', 'name'], '')}</span>{' '}
						{get(log, ['operation', 'display_name'])}
					</Fragment>
				);
			case 'partner_assigned':
			case 'partner_removed':
				return (
					<Fragment>
						<span>{get(log, ['partner', 'name'], '')}</span>{' '}
						{get(log, ['operation', 'display_name'])}
					</Fragment>
				);
			case 'name_edited':
				return (
					<Fragment>
						<span>
							{get(log, ['first_name'], null)
								? 'First name'
								: 'Last name'}
						</span>{' '}
						edited to{' '}
						<span>
							{get(log, ['first_name'], '') ||
								get(log, ['last_name'], '')}
						</span>
					</Fragment>
				);
			case 'email_edited':
				return (
					<Fragment>
						<span>Email</span> edited to{' '}
						<span>{get(log, ['email'], '')}</span>
					</Fragment>
				);
			default:
				return (
					<Fragment>
						{get(log, ['operation', 'display_name'])}
					</Fragment>
				);
		}
	};

	return (
		<div className='fullModalBackground'>
			<div className='audit-trail-modal-container'>
				<Loading className='loading' loading={loading}>
					<div className='audit-trail-modal-top-row'>
						<div>Audit Trail</div>
						<ReactSVG
							src={xIcon}
							className='singleSelectModalXIcon audit-trail-modal-top-row-x-icon'
							onClick={hideAuditTrailModal}
						/>
					</div>
					<div className='audit-trail-modal-body'>
						{loaded &&
							logs.map((log, index) => {
								return (
									<div
										key={get(log, ['uuid'], index)}
										className='log'
									>
										<div className='title'>
											{getTitle(log)}
										</div>
										<div>By {get(log, ['by'], '-')}</div>
										<div>On {get(log, ['date'], '-')}</div>
									</div>
								);
							})}
					</div>
				</Loading>
			</div>
		</div>
	);
}
