import get from 'lodash/get';

import * as actionTypes from './actionTypes';
import GraphQLService from '../service/GraphQL';

export function getPatientDrugExposures(uuid) {
	return (dispatch) => {
		dispatch(getPatientDrugExposuresLoading());
		return GraphQLService.getPatientDrugExposures(uuid)
			.then((res) => {
				dispatch(
					getPatientDrugExposuresSuccess(
						get(res, 'data.patient.drugExposures', null)
					)
				);
			})
			.catch((err) => {
				dispatch(getPatientDrugExposuresError(err));
			});
	};
}

const getPatientDrugExposuresLoading = () => {
	return {
		type: actionTypes.PATIENT_DRUG_EXPOSURES_LOADING,
		payload: true,
	};
};

const getPatientDrugExposuresSuccess = (data) => {
	return {
		type: actionTypes.PATIENT_DRUG_EXPOSURES_SUCCESS,
		payload: data,
	};
};

const getPatientDrugExposuresError = (err) => {
	return {
		type: actionTypes.PATIENT_DRUG_EXPOSURES_ERROR,
		payload: err,
	};
};

export function addPatientDrugExposureNote(drugUuid, noteText) {
	return (dispatch) => {
		dispatch(addPatientDrugExposureNoteLoading());
		return GraphQLService.addDrugExposureNote(drugUuid, noteText)
			.then((res) => {
				dispatch(
					addPatientDrugExposureNoteSuccess(
						get(res, 'data.note', null)
					)
				);
			})
			.catch((err) => {
				dispatch(addPatientDrugExposureNoteFailure(err));
			});
	};
}

const addPatientDrugExposureNoteLoading = () => {
	return {
		type: actionTypes.ADD_PATIENT_DRUG_EXPOSURE_NOTE_LOADING,
		payload: true,
	};
};

const addPatientDrugExposureNoteSuccess = (data) => {
	return {
		type: actionTypes.ADD_PATIENT_DRUG_EXPOSURE_NOTE_SUCCESS,
		payload: data,
	};
};

const addPatientDrugExposureNoteFailure = (err) => {
	return {
		type: actionTypes.ADD_PATIENT_DRUG_EXPOSURE_NOTE_ERROR,
		payload: err,
	};
};
