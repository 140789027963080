export const drugExposureEnabledPrograms = new Set([
	'UHG',
	'OptumLabs_WellAtHome',
]);

export const dummyExposureData = [
	{
		name: 'Dummy Drug 1',
		asserted_on: '2021-07-15',
		status: 'active',
		id: 3,
	},
	{
		name: 'Dummy Drug 2',
		asserted_on: '2021-08-25',
		status: 'active',
		id: 7,
	},
	{
		name: 'Dummy Drug 3',
		asserted_on: '2021-09-30',
		status: 'active',
		id: 9,
	},
];

export const statusEnum = [
	{
		internal: 'ACTIVE',
		external: 'Active',
	},
	{
		internal: 'COMPLETED',
		external: 'Completed',
	},
	{
		internal: 'INTENDED',
		external: 'Intended',
	},
	{
		internal: 'UNKNOWN',
		external: 'Unknown',
	},
];
