//React & Redux
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

//Lodash
import _ from 'lodash';

//Utils
import {
	OrderRequestStatusHierarchyEnums,
	OrderStatusHierarchyEnums,
	filterNonLegacyTestEnumes,
	Timezones,
	filterOrderStatusEnumes,
} from './Common/utilsfunctions';
import {
	AddEncounterTypes,
	USTimezones,
	StandradConsultation,
} from '../../../../utils';

//Actions & Services
import {
	getencountergroupsessionschema,
	getspecificencounterenum,
	getencounterccintakeschema,
	getencountervisitsschema,
	LoadEncounterDetailTestEnums,
	addencounter,
	getBillingEncounterDetail,
	clearBillingEncounter,
	addManualVisitEncouter,
	getVisitIndications,
} from '../../../../actions/encounter';
import { getReferralPrograms } from '../../../../actions/patients';
import { LoadAppointmentProvider } from '../../../../actions/scheduling.js';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious';

//Components
import AddGroupSession from './GroupSessionEncounters/AddGroupSession';
import AddCCIntake from './CCIntakeEncounters/AddCCIntake';
import AddMultiVisitEncounter from './VisitEncounters/AddMultiVisitEncounter';
import AddManualVisitEncounter from './VisitEncounters/AddManualVisitEncounter';

//Styles
import './style.css';

const AddEncounter = (props) => {
	const {
		addEncounterType,
		encounterCreateLoading,
		encounterCreateError,
		enqueueSnackbar,
		getEncounterList,
		groupSessionEncounterProps,
		ccIntakeEncounterProps,
		multiVisitEncounterProps,
		visitEncounterProps,
	} = props;

	const previousEncounterCreateLoading = usePrevious(encounterCreateLoading);

	useEffect(() => {
		if (previousEncounterCreateLoading && !encounterCreateLoading) {
			if (encounterCreateError) {
				enqueueSnackbar('Error in creating encounter', {
					variant: 'error',
					className: 'export_notification',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
			} else {
				enqueueSnackbar('Encounter created', {
					variant: 'info',
					className: 'export_notification',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				getEncounterList();
			}
		}
	}, [encounterCreateLoading]);

	const renderComponent = () => {
		if (addEncounterType === AddEncounterTypes.GROUP_SESSION) {
			return <AddGroupSession {...groupSessionEncounterProps} />;
		} else if (addEncounterType === AddEncounterTypes.CC_INTAKE) {
			return <AddCCIntake {...ccIntakeEncounterProps} />;
		} else if (addEncounterType === AddEncounterTypes.MULTI_PATIENT_VISIT) {
			return <AddMultiVisitEncounter {...multiVisitEncounterProps} />;
		} else if (addEncounterType === AddEncounterTypes.MANUAL_VISIT) {
			return <AddManualVisitEncounter {...visitEncounterProps} />;
		}

		return null;
	};
	return (
		<Fragment>
			<section className='encntr-detail'>
				<div className='encounter-body'>{renderComponent()}</div>
			</section>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	testnameenums: _.get(state, ['testEnums'], []),
	groupsessionschema: _.get(
		state,
		['groupsessionschema', 'schema', 'data'],
		{}
	),
	ccintakeschema: _.get(state, ['ccintakeschema', 'schema', 'data'], {}),
	visitsschema: _.get(state, ['visitsschema', 'schema', 'data'], {}),
	ordering_physician: _.get(state, ['orderingphysicianenum'], {}),
	visit_provider: _.get(state, ['visitproviderenum'], {}),
	scheduling_visit_providers: state.Scheduling.providers,
	orderrequest: _.get(state, ['orderrequest', 'orderrequest'], {}),
	encounterCreateLoading: _.get(
		state,
		'addencounter.encounterCreateLoading',
		null
	),
	encounterCreateError: _.get(
		state,
		'addencounter.encounterCreateError',
		null
	),
	billingencounter: _.get(
		state,
		['getBillingEncounterData', 'billingobject', 'data', '0'],
		{}
	),
	visitIndicationsLoading: _.get(
		state,
		'visitIndications.visitIndicationsLoading',
		false
	),
	visitIndications:
		_.get(state, 'visitIndications.visitIndications', null) || [],
	visitIndicationsError: _.get(
		state,
		'visitIndications.visitIndicationsError',
		null
	),
	gettingReferralPrograms: _.get(
		state,
		'referralPrograms.gettingReferralPrograms',
		null
	),
	referralPrograms:
		_.get(state, 'referralPrograms.referralPrograms.data', null) || [],
	referralProgramsError: _.get(
		state,
		'referralPrograms.referralProgramsError',
		null
	),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const {
		testnameenums,
		groupsessionschema,
		ccintakeschema,
		visitsschema,
		ordering_physician,
		visit_provider,
		scheduling_visit_providers,
		encounterCreateLoading,
		encounterCreateError,
		billingencounter,
		visitIndicationsLoading,
		visitIndications,
		visitIndicationsError,
		gettingReferralPrograms,
		referralPrograms,
		referralProgramsError,
	} = stateProps;
	const { dispatch } = dispatchProps;
	const {
		match,
		changeEncounterTab,
		enqueueSnackbar,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		getICDCodes,
		patientdetail,
		getEncounterList,
		showEncounterList,
		addEncounterType,
		primaryPatientInfo,
		history,
	} = ownProps;
	const patient_uuid = _.get(match, ['params', 'patientid'], '');
	const cancel = () => showEncounterList();
	const calladdencounter = (data) => dispatch(addencounter(data));
	const callspecificencounterenum = (data) =>
		dispatch(getspecificencounterenum(data));
	const loadAppointmentProvider = (user_id) =>
		dispatch(LoadAppointmentProvider(user_id));
	const dispatchLoadTestEnums = (lab_uuid) =>
		dispatch(LoadEncounterDetailTestEnums(lab_uuid));
	const LoadTestEnums = (lab_uuid) => {
		if (lab_uuid && lab_uuid != '') dispatchLoadTestEnums(lab_uuid);
	};
	const callgroupsessionschema = () =>
		dispatch(getencountergroupsessionschema());
	const callccintakeschema = () => dispatch(getencounterccintakeschema());
	const callvisitsschema = () => dispatch(getencountervisitsschema());
	const groupSessionOrderStatus = _.get(
		groupsessionschema,
		['order_status'],
		[]
	);
	const groupSessionfilteredOrderStatus = filterOrderStatusEnumes(
		groupSessionOrderStatus
	);
	const ccIntakeOrderStatus = _.get(ccintakeschema, ['order_status'], []);
	const ccIntakefilteredOrderStatus =
		filterOrderStatusEnumes(ccIntakeOrderStatus);
	const getBillingEncounter = (encounter_uuid) =>
		dispatch(getBillingEncounterDetail(encounter_uuid));
	const callClearBillingEncounter = () => dispatch(clearBillingEncounter());
	const callAddManualVisitEncouter = (data) =>
		dispatch(addManualVisitEncouter(data));
	const getAllVisitIndications = () => dispatch(getVisitIndications());
	const getAllReferralPrograms = () => dispatch(getReferralPrograms());

	const gotoRelatedEncounter = (
		patient_uuid,
		encounter_uuid,
		patientFullName
	) => {
		return history.push({
			pathname: `/app/patientdetail/${patient_uuid}/0/2/${encounter_uuid}/`,
			state: {
				breadcrumbs: [
					{
						location: 'patients',
						url: '/app/patients/',
					},
					{
						location: 'Patient Detail',
						patientFullName: patientFullName,
					},
				],
			},
		});
	};

	const groupSessionEncounterProps = {
		callgroupsessionschema,
		groupsessionschema,
		LoadTestEnums,
		testnameenums: _.get(testnameenums, 'data', []),
		testnameLoadedforLabUUID: _.get(testnameenums, ['selectedlabuuid'], ''),
		testnameloading: _.get(testnameenums, ['loading'], false),
		testnameLoadeed: _.get(testnameenums, ['loaded'], false),
		fetchOrderingPhysician: () =>
			callspecificencounterenum({
				type: 'group-session',
				field_name: 'ordering_physician',
			}),
		fetchVisitProvider: () =>
			callspecificencounterenum({
				type: 'group-session',
				field_name: 'visit_provider',
			}),
		ordering_physicianenums: _.get(ordering_physician, 'group-session', []),
		visit_providerenums: _.get(visit_provider, 'group-session', []),
		nonlegacytestnameenums: filterNonLegacyTestEnumes(
			_.get(testnameenums, 'data', [])
		),
		orderstatuscascadeenums: OrderStatusHierarchyEnums(
			groupSessionOrderStatus,
			'key'
		),
		filteredorderstatuscascadeenums: OrderStatusHierarchyEnums(
			groupSessionfilteredOrderStatus,
			'key'
		),
		orderrequeststatuscascadeenums: OrderRequestStatusHierarchyEnums(
			groupsessionschema,
			'order_request_status',
			'key'
		),
		timezoneenums: Timezones,
		calladdencounter: calladdencounter,
		patient_uuid,
		patientdetail,
		cancel,
		changeEncounterTab,
		gettingReferralPrograms,
		referralPrograms,
		referralProgramsError,
		getAllReferralPrograms,
	};
	const ccIntakeEncounterProps = {
		callccintakeschema,
		ccintakeschema,
		LoadTestEnums,
		testnameenums: _.get(testnameenums, 'data', []),
		testnameLoadedforLabUUID: _.get(testnameenums, ['selectedlabuuid'], ''),
		testnameloading: _.get(testnameenums, ['loading'], false),
		testnameLoadeed: _.get(testnameenums, ['loaded'], false),
		fetchOrderingPhysician: () =>
			callspecificencounterenum({
				type: 'cc-intake',
				field_name: 'ordering_physician',
			}),
		fetchVisitProvider: () =>
			callspecificencounterenum({
				type: 'cc-intake',
				field_name: 'visit_provider',
			}),
		orderstatuscascadeenums: OrderStatusHierarchyEnums(
			ccIntakeOrderStatus,
			'key'
		),
		filteredorderstatuscascadeenums: OrderStatusHierarchyEnums(
			ccIntakefilteredOrderStatus,
			'key'
		),
		orderrequeststatuscascadeenums: OrderRequestStatusHierarchyEnums(
			ccintakeschema,
			'order_request_status',
			'key'
		),
		ordering_physicianenums: _.get(ordering_physician, 'cc-intake', []),
		visit_providerenums: _.get(visit_provider, 'cc-intake', []),
		nonlegacytestnameenums: filterNonLegacyTestEnumes(
			_.get(testnameenums, 'data', [])
		),
		timezoneenums: Timezones,
		calladdencounter: calladdencounter,
		patient_uuid,
		patientdetail,
		cancel,
		changeEncounterTab,
		gettingReferralPrograms,
		referralPrograms,
		referralProgramsError,
		getAllReferralPrograms,
	};

	const multiVisitEncounterProps = {
		callvisitsschema,
		visitsschema,
		fetchOrderingPhysician: () =>
			callspecificencounterenum({
				type: 'visit',
				field_name: 'ordering_physician',
			}),
		fetchVisitProvider: () =>
			callspecificencounterenum({
				type: 'visit',
				field_name: 'visit_provider',
			}),
		ordering_physicianenums: _.get(ordering_physician, 'visit', []),
		visit_providerenums: _.get(visit_provider, 'visit', []),
		patient_uuid,
		patientdetail,
		cancel,
		primaryPatientInfo: _.get(ownProps, ['primaryPatientInfo'], {}),
		changeEncounterTab,
		enqueueSnackbar,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		getICDCodes,
		getBillingEncounter,
		billingencounter,
		gotoRelatedEncounter,
		callClearBillingEncounter,
		callAddManualVisitEncouter,
		getAllVisitIndications,
		visitIndicationsLoading,
		visitIndications,
		visitIndicationsError,
		gettingReferralPrograms,
		referralPrograms,
		referralProgramsError,
		getAllReferralPrograms,
	};

	const manual_visit_providers = scheduling_visit_providers.filter(
		(scheduling_visit_providers) =>
			scheduling_visit_providers.is_gmi_provider === true
	);

	const visitEncounterProps = {
		callvisitsschema,
		visitsschema,
		fetchOrderingPhysician: () =>
			callspecificencounterenum({
				type: 'visit',
				field_name: 'ordering_physician',
			}),
		fetchVisitProvider: () => loadAppointmentProvider(patientdetail.id),
		ordering_physicianenums: _.get(ordering_physician, 'visit', []),
		visit_providerenums: _.get(visit_provider, 'visit', []),
		manual_visit_providers,
		patient_uuid,
		patientdetail,
		cancel,
		changeEncounterTab,
		enqueueSnackbar,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		getICDCodes,
		getBillingEncounter,
		billingencounter,
		callClearBillingEncounter,
		callAddManualVisitEncouter,
		consultationTypesEnums: StandradConsultation,
		timezoneenums: USTimezones,
		getAllVisitIndications,
		visitIndicationsLoading,
		visitIndications,
		visitIndicationsError,
		gettingReferralPrograms,
		referralPrograms,
		referralProgramsError,
		getAllReferralPrograms,
	};
	return {
		enqueueSnackbar,
		groupSessionEncounterProps,
		ccIntakeEncounterProps,
		getEncounterList,
		encounterCreateLoading,
		encounterCreateError,
		addEncounterType,
		multiVisitEncounterProps,
		visitEncounterProps,
	};
};

export default connect(mapStateToProps, null, mergeProps)(AddEncounter);
