import React from 'react';

import './CircleIcon.scss';

export function CircleIcon({ color }) {
	// Usual colors:
	// - High priority patient: #fde776
	// - Patient with guardian: #9fdcf5
	return <span style={{ backgroundColor: color }} className='circle-icon' />;
}
