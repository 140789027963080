//React & Redux
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//Images
import ReactSVG from 'react-svg';
import actionRequired from '../../assets/action-required.png';
import completed from '../../assets/completed.png';
import na from '../../assets/na.png';
import export_img from '../../assets/export.svg';
import pencilIcon from '../../assets/edit-patient.svg';

//Lodash
import get from 'lodash/get';
import _ from 'lodash';

//Utils
import {
	getBeginningOfMonthDateObj,
	getBeginningOfYearDateObj,
	getBeginningOfTodayDateObj,
} from '../../Utils/CalendarUtils.js';

//Hooks
import { useDebounce } from '../../hooks/useDebounce';
import { usePrevious } from '../../hooks/usePrevious';

//Actions & Services
import {
	billingEncounterBulkUpdate,
	exportBillingEncounters,
	getBillingDashboardCounts,
	getBillingEncounterDetail,
	getBillingEncountersList,
	getBillingPatientDetail,
	getEncounterEnums,
	getPatientFeeEnum,
	getReferralChannelsList,
	saveBillingDashboardEncounter,
} from '../../actions/billing';
import {
	getTestOrderRequest,
	getPreauthorizationTestOrders,
} from '../../actions/encounter';
import { setFilter } from '../../actions/appData';
import {
	getSexAssignedAtBirth,
	getReferralPrograms,
} from '../../actions/patients';

//UI Libraries
import { Loading } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import BillingDetail from './BillingDetail/BillingDetail';
import BillingTableFN from './BillingTable';
import BulkUpdateModal from './BulkUpdateModal.js';
import Searchbar from '../Filter/Searchbar.js';
import ResetFilters from '../Filter/ResetFilters';
import SingleSelect from '../Filter/SingleSelect.js';
import MultiSelectCheckbox from '../Filter/MultiSelectCheckbox.js';
import MoreFilters from '../Filter/MoreFilters.js';

//Styles
import './style.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const BillingDashboard = (props) => {
	const {
		actions: {
			billingEncounterBulkUpdate,
			exportBillingEncounters,
			getBillingDashboardCounts,
			getBillingEncountersList,
			getBillingEncounterDetail,
			getEncounterEnums,
			getBillingPatientDetail,
			getPatientFeeEnum: getPatientFeeRefundEnum,
			getPreauthorizationTestOrders,
			getReferralChannelsList,
			getSexAssignedAtBirth: getSexAssignedAtBirthEnum,
			getTestOrderRequest,
			saveBillingDashboardEncounter,
			setFilter,
			getReferralPrograms,
		},
		affiliationsPerChannel,
		allAffiliations,
		billingEncounters,
		billingEncountersData,
		billingEncounterBulkUpdating = false,
		billingEncounterBulkUpdatedError = null,
		billingEncountersError = null,
		billingEncountersLoading = false,
		billingFilter,
		billingFilter: { from = null, to = null },
		billingStatusEnums,
		bloodRequestEnums,
		dashboardCounts,
		encounterBillingDetail,
		encounterDetail,
		encounterPreAuthTestOrders,
		encounterTestOrderRequest,
		enqueueSnackbar,
		history,
		isBillingCoordinator,
		labEnums,
		partnerInvoiceEnums,
		patientFeeEnum,
		patientDetail,
		referralChannels,
		sexAssignedAtBirthEnum,
		typeOfTestEnums,
		referralPrograms,
	} = props;

	const defaultFilter = {
		activePage: 0,
		all_referral_programs: [],
		from: undefined,
		needs_attention: false,
		patient_fee_refund: false,
		q: '',
		referral_channel: -1,
		referral_program: [],
		selected_from_date: moment().subtract(30, 'days').format('MM/DD/YYYY'),
		selected_referral_channel: 'All',
		selected_to_date: moment().format('MM/DD/YYYY'),
		to: undefined,
	};

	const [localState, setLocalState] = useState({
		...defaultFilter,
		...billingFilter,
		bulkUpdateInvoiceDate: null,
		bulkUpdatePartnerInvoice: null,
		bulkUpdateInvoiceStatus: 'na',
		from: from ? moment(from).toDate() : undefined,
		initialLoaded: false,
		insurance_expected_revenue: '',
		loading: false,
		partner_invoice_rate: '',
		patient_billing_amount: '',
		renderBulkUpdateModal: null,
		selectedItems: [],
		to: to ? moment(to).toDate() : undefined,
	});

	const debouncedQuery = useDebounce(localState.q, 750);

	const prevBillingEncounterBulkUpdating = usePrevious(
		billingEncounterBulkUpdating
	);
	const prevBillingEncountersLoading = usePrevious(billingEncountersLoading);
	const prevSavingData = usePrevious(localState.saving_data);

	const searchFilteredEncountersProps = [
		localState.from,
		localState.needs_attention,
		localState.patient_fee_refund,
		localState.referral_channel,
		localState.referral_program,
		localState.to,
	];

	const closeDetailViewAndGetEncounterListProps = [
		localState.activePage,
		localState.all_referral_programs,
		localState.limit,
	];

	useEffect(() => {
		window.scrollTo(0, 0);
		getReferralChannelsList();
		getEncounterList();
		getEncounterEnums();
		getSexAssignedAtBirthEnum();
		getPatientFeeRefundEnum();
		getReferralPrograms();
	}, []);

	useEffect(() => {
		closeDetailViewAndGetEncounterList();
	}, [debouncedQuery]);

	useEffect(() => {
		if (prevSavingData && !localState.saving_data) {
			updateLocalState({
				billing_notes: get(encounterDetail, [0, 'billing_notes'], null),
				billing_status:
					get(encounterDetail, [0, 'billing_status', 'length'], 0) ==
					0
						? 'na'
						: get(encounterDetail, [0, 'billing_status'], 'na'),
				insurance_expected_revenue: get(
					encounterBillingDetail,
					'insurance_expected_revenue',
					null
				),
				partner_invoice_date: get(
					encounterBillingDetail,
					'partner_invoice_date'
				),
				partner_invoice_display_date:
					get(
						encounterBillingDetail,
						'partner_invoice_date',
						undefined
					) == null ||
					get(
						encounterBillingDetail,
						'partner_invoice_date.length',
						0
					) == 0
						? undefined
						: moment(
								get(
									encounterBillingDetail,
									'partner_invoice_date',
									undefined
								)
						  ).format('MM/DD/YYYY'),
				partner_invoice_rate: get(
					encounterBillingDetail,
					'partner_invoice_rate',
					null
				),
				partner_invoice_status: get(
					encounterBillingDetail,
					'partner_invoice_status',
					''
				),
				patient_billing_amount: get(
					encounterBillingDetail,
					'patient_billing_amount',
					null
				),
				patient_billing_date: get(
					encounterBillingDetail,
					'patient_billing_date'
				),
				patient_billing_display_date:
					get(
						encounterBillingDetail,
						'patient_billing_date',
						undefined
					) == null ||
					get(
						encounterBillingDetail,
						'patient_billing_date.length',
						0
					) == 0
						? undefined
						: moment(
								get(
									encounterBillingDetail,
									'patient_billing_date',
									undefined
								)
						  ).format('MM/DD/YYYY'),
			});
		}
	}, [localState.saving_data]);

	useEffect(() => {
		if (
			(prevBillingEncountersLoading || false) &&
			!(billingEncountersLoading || false)
		) {
			if (!localState.initialLoaded)
				updateLocalState({ initialLoaded: true });

			if (billingEncountersError) {
				enqueueSnackbar('Error in loading billing encounters', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			}
			setFilter('billing', getFilter());
			if (localState.showDetails || null) {
				closeDetailView();
			}
			if (determineSomeChecked()) {
				toggleAllOff();
			}
		}
	}, [billingEncountersLoading]);

	useEffect(() => {
		if (prevBillingEncounterBulkUpdating && !billingEncounterBulkUpdating) {
			if (billingEncounterBulkUpdatedError) {
				enqueueSnackbar('Error in updating billing encounters', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				enqueueSnackbar('Billing encounters updated', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				closeBulkUpdateModal();
				if (localState.activePage !== 0) {
					updateLocalState({ activePage: 0 });
				} else {
					closeDetailViewAndGetEncounterList();
				}
			}
		}
	}, [billingEncounterBulkUpdating]);

	useEffect(() => {
		closeDetailViewAndGetEncounterList();
	}, [...closeDetailViewAndGetEncounterListProps]);

	useEffect(() => {
		searchFilteredEncounters();
	}, [...searchFilteredEncountersProps]);

	const updateLocalState = (obj) => {
		setLocalState((state) => ({
			...state,
			...obj,
		}));
	};

	const getEncounterList = _.debounce(() => {
		getBillingDashboardCounts({
			q: get(localState, 'q', ''),
			partners:
				get(localState, 'referral_program.length', 0) != 0
					? get(localState, 'referral_program')
					: get(localState, 'all_referral_programs', []),
			referral_channels:
				get(localState, 'referral_channel', -1) != -1
					? [get(localState, 'referral_channel')]
					: [],
			start_date: get(localState, 'from')
				? moment(get(localState, 'from')).format('YYYY-MM-DD')
				: get(
						localState,
						'from',
						moment().subtract(30, 'days').format('YYYY-MM-DD')
				  ),
			end_date: get(localState, 'to')
				? moment(get(localState, 'to')).format('YYYY-MM-DD')
				: get(localState, 'to', moment().format('YYYY-MM-DD')),
		});
		getBillingEncountersList({
			query: {
				q: get(localState, 'q', ''),
				partners:
					get(localState, 'referral_program.length', 0) != 0
						? get(localState, 'referral_program')
						: get(localState, 'all_referral_programs', []),
				referral_channels:
					get(localState, 'referral_channel', -1) != -1
						? [get(localState, 'referral_channel')]
						: [],
				start_date: get(localState, 'from')
					? moment(get(localState, 'from')).format('YYYY-MM-DD')
					: get(
							localState,
							'from',
							moment().subtract(30, 'days').format('YYYY-MM-DD')
					  ),
				end_date: get(localState, 'to')
					? moment(get(localState, 'to')).format('YYYY-MM-DD')
					: get(localState, 'to', moment().format('YYYY-MM-DD')),
				needs_attention: get(localState, 'needs_attention', false),
				patient_fee_refund: get(
					localState,
					'patient_fee_refund',
					false
				),
			},
			limit: get(props, 'limit', 25),
			offset: get(localState, 'activePage', 0),
		});
	}, 750);

	const getFilter = () => ({
		activePage: get(localState, 'activePage', null),
		all_referral_programs: get(localState, 'all_referral_programs', null),
		from: get(localState, 'from', null),
		needs_attention: get(localState, 'needs_attention', null),
		patient_fee_refund: get(localState, 'patient_fee_refund', null),
		q: get(localState, 'q', ''),
		referral_channel: get(localState, 'referral_channel', null),
		referral_program: get(localState, 'referral_program', null),
		selected_from_date: get(localState, 'selected_from_date', null),
		selected_referral_channel: get(
			localState,
			'selected_referral_channel',
			null
		),
		selected_to_date: get(localState, 'selected_to_date', null),
		to: get(localState, 'to', null),
	});

	const handlePageChange = (evt, pageNumber) =>
		updateLocalState({ activePage: pageNumber });

	const handleResetFilters = () => updateLocalState({ ...defaultFilter });

	const getImageName = (value) => {
		if (
			value == 'not_invoiced' ||
			value == 'not_sent' ||
			value == 'not_submitted'
		)
			return actionRequired;
		else if (value == 'invoiced' || value == 'sent' || value == 'submitted')
			return completed;
		else return na;
	};

	const handleReferralChannelSelection = (val) => {
		updateLocalState({
			referral_channel: val,
			referral_program: [],
			referral_program_list: get(affiliationsPerChannel, [val], []),
		});
	};

	const handleReferralProgramSelection = (val) =>
		updateLocalState({ referral_program: val });

	const showDetailForEncounter = (encounter, idx, e) => {
		const { encounter_uuid = '', patient_uuid = '' } = encounter;

		if (
			get(e, 'target', document.createElement('span')).classList.contains(
				'el-checkbox__inner'
			) ||
			get(e, 'target', document.createElement('span')).classList.contains(
				'el-checkbox__original'
			)
		)
			return;

		if (!localState.saving_data) {
			updateLocalState({
				insurance_expected_revenue: '',
				patient_billing_amount: '',
				partner_invoice_rate: '',
				selected_encounter_id: encounter_uuid,
				selected_patient_id: patient_uuid,
				selectedEncounterDetail: encounter,
				selectedEncounterRow: idx,
				showDetails: true,
			});
			getBillingEncounterDetail(encounter_uuid);
			getBillingPatientDetail(patient_uuid);
			getTestOrderRequest(encounter_uuid);
			getPreauthorizationTestOrders(encounter_uuid);
		}
	};

	const closeDetailView = () => {
		updateLocalState({
			encounterDetail: null,
			selectedEncounterRow: -1,
			showDetails: false,
		});
	};

	const closeDetailViewAndGetEncounterList = () => {
		closeDetailView();
		getEncounterList();
	};

	const getFilteredEncounters = (needs_attention) => {
		updateLocalState({
			activePage: 0,
			needs_attention:
				needs_attention !== 'patient_fee_refund'
					? needs_attention
					: false,
			patient_fee_refund:
				needs_attention === 'patient_fee_refund' ? true : false,
		});
	};

	const searchFilteredEncounters = () => {
		const selected_referral_channel = referralChannels.find(
			(el) => el.id == get(localState, 'referral_channel')
		);

		const referral_program = getReferralProgramsList();
		const selected_referral_program = get(
			localState,
			'referral_program',
			[]
		);

		let selected_from_date = get(localState, 'from');
		let selected_to_date = get(localState, 'to');

		const all_referral_programs = [];

		if (get(selected_referral_program, 'length', 0) === 0) {
			(referral_program || []).map((e) => {
				all_referral_programs.push(e.id);
			});
		}

		if (selected_from_date)
			selected_from_date =
				moment(selected_from_date).format('MM/DD/YYYY');
		if (selected_to_date)
			selected_to_date = moment(selected_to_date).format('MM/DD/YYYY');

		updateLocalState({
			all_referral_programs,
			activePage: 0,
			selected_from_date:
				selected_from_date ||
				moment().subtract(30, 'days').format('MM/DD/YYYY'),
			selected_referral_channel: get(
				selected_referral_channel,
				'name',
				'All'
			),
			selected_to_date: selected_to_date || moment().format('MM/DD/YYYY'),
		});
	};

	const handleQueryChange = (evt) => {
		updateLocalState({
			activePage: 0,
			q: evt.target.value,
		});
	};

	const saveEncounterValues = (enc_info, enc_billing_info) => {
		enc_billing_info['encounter_uuid'] = get(enc_info, 'uuid', '');
		if (enc_info['part_of']) {
			delete enc_info['part_of'];
		}
		updateLocalState({ saving_data: true });
		return saveBillingDashboardEncounter({
			encounter: enc_info,
			billing: enc_billing_info,
		}).then((res) => {
			updateLocalState({ saving_data: false });
			getEncounterList();
			if (get(localState, 'needs_attention')) {
				const newUuid = get(billingEncountersData, '[0]', []);
				getBillingEncounterDetail(get(newUuid, 'encounter_uuid'));
				getBillingPatientDetail(get(newUuid, 'patient_uuid'));
			} else {
				getBillingEncounterDetail(get(enc_info, 'uuid'));
			}

			enqueueSnackbar('Changes Saved', {
				variant: 'info',
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'bottom',
				},
			});
		});
	};

	const handleCheckboxChange = (bool, encounter) => {
		const currentSelected = get(localState, 'selectedItems', []);
		const encounterUuid = get(encounter, 'encounter_uuid', null);
		if (Array.isArray(currentSelected) && encounterUuid) {
			if (currentSelected.includes(encounterUuid)) {
				_.remove(currentSelected, (uuid) => uuid === encounterUuid);
			} else {
				currentSelected.push(encounterUuid);
			}
		}
		updateLocalState({ selectedItems: currentSelected });
	};

	const determineIsChecked = (e) => {
		const currentSelected = get(localState, 'selectedItems', []);
		const encounterUuid = get(e, 'encounter_uuid', null);
		if (Array.isArray(currentSelected) && encounterUuid) {
			return currentSelected.includes(encounterUuid);
		}
	};

	const determineAllChecked = () => {
		const encountersLength = billingEncountersData.length;
		const currentSelectedLength = get(
			localState,
			'selectedItems.length',
			0
		);
		return (
			encountersLength === currentSelectedLength && encountersLength !== 0
		);
	};

	const determineSomeChecked = () => {
		const currentSelectedLength = get(
			localState,
			'selectedItems.length',
			0
		);
		return currentSelectedLength && !determineAllChecked();
	};

	const handleAllToggle = () => {
		const currentSelectedLength = get(
			localState,
			'selectedItems.length',
			0
		);
		if (!currentSelectedLength) {
			toggleAllOn();
		} else {
			toggleAllOff();
		}
	};

	const toggleAllOn = () => {
		if (Array.isArray(billingEncountersData)) {
			const newSelected = [];
			billingEncountersData.forEach((el) => {
				const uuid = get(el, 'encounter_uuid', null);
				if (uuid) newSelected.push(uuid);
			});
			updateLocalState({ selectedItems: newSelected });
		}
	};

	const toggleAllOff = () => updateLocalState({ selectedItems: [] });

	const openBulkUpdateModal = () =>
		updateLocalState({ renderBulkUpdateModal: true });

	const closeBulkUpdateModal = () => {
		updateLocalState({
			bulkUpdateInvoiceDate: null,
			bulkUpdateInvoiceStatus: 'na',
			bulkUpdatePartnerInvoice: null,
			renderBulkUpdateModal: false,
		});
	};

	const getLabName = (key) => {
		const lab_enum_object = labEnums.find((o) => o.key == key);
		return get(lab_enum_object, 'display_name', key);
	};

	const getTestName = (key) => {
		const test_enum_object = typeOfTestEnums.find((o) => o.key == key);
		return get(test_enum_object, 'display_name', key);
	};

	const exportEncounters = () => {
		if (billingEncountersData.length > 0) {
			exportBillingEncounters({
				query: {
					q: get(localState, 'q', ''),
					partners:
						get(localState, 'referral_program.length', 0) != 0
							? get(localState, 'referral_program')
							: get(localState, 'all_referral_programs', []),
					referral_channels:
						get(localState, 'referral_channel', -1) != -1
							? [get(localState, 'referral_channel')]
							: [],
					start_date: get(localState, 'from')
						? moment(get(localState, 'from')).format('YYYY-MM-DD')
						: get(
								localState,
								'from',
								moment()
									.subtract(30, 'days')
									.format('YYYY-MM-DD')
						  ),
					end_date: get(localState, 'to')
						? moment(get(localState, 'to')).format('YYYY-MM-DD')
						: get(localState, 'to', moment().format('YYYY-MM-DD')),
					needs_attention: get(localState, 'needs_attention', false),
					patient_fee_refund_filter: get(
						localState,
						'patient_fee_refund',
						false
					),
				},
			});

			enqueueSnackbar('The report will be sent to your email id.', {
				variant: 'info',
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'bottom',
				},
			});
		}
	};

	const constructEncounterEnum = () => {
		const all_count = get(dashboardCounts, 'all_count', 0);
		const needs_attention_count = get(
			dashboardCounts,
			'needs_attention_count',
			0
		);
		return [
			{
				internal: false,
				external: `All Statuses (${all_count})`,
			},
			{
				internal: true,
				external: `Needs Attention (${needs_attention_count})`,
			},
			{
				internal: 'patient_fee_refund',
				external: 'Patient Fee Refund',
			},
		];
	};

	const constructReferralChannelEnum = () => {
		return [
			{
				internal: -1,
				external: 'All Referral Channels',
			},
		].concat(
			referralChannels.map((el) => {
				return {
					internal: get(el, 'id', null),
					external: get(el, 'name', null),
				};
			})
		);
	};

	const constructReferralProgramEnum = () => {
		const referralPrograms = getReferralProgramsList() || [];
		return referralPrograms.map((el) => ({
			key: get(el, 'id', null),
			display_name: get(el, 'display_name', null),
		}));
	};

	const formulateMoreFiltersData = () => [
		{
			type: 'dateRangePicker',
			startDate: get(
				localState,
				'from',
				moment().subtract(30, 'days').toDate()
			),
			endDate: get(localState, 'to', moment().toDate()),
			startDatePlaceholder: get(
				localState,
				'from',
				moment().subtract(30, 'days').format('MM/DD/YYYY')
			),
			endDatePlaceholder: get(
				localState,
				'to',
				moment().format('MM/DD/YYYY')
			),
			autoComplete: 'off',
			isReadOnly: true,
			format: 'MM/dd/yyyy',
			defaultValue: [
				moment().subtract(30, 'days').toDate(),
				moment().toDate(),
			],
			shortcuts: [
				{
					text: 'MTD',
					newValues: [
						getBeginningOfMonthDateObj(),
						getBeginningOfTodayDateObj(),
					],
				},
				{
					text: 'YTD',
					newValues: [
						getBeginningOfYearDateObj(),
						getBeginningOfTodayDateObj(),
					],
				},
			],
		},
	];

	const changeFilterBulk = (filters = {}) => updateLocalState({ ...filters });

	const getReferralProgramsList = () => {
		const list =
			get(localState, 'referral_channel', -1) == -1
				? allAffiliations
				: get(
						affiliationsPerChannel,
						[get(localState, 'referral_channel', -1)],
						[]
				  );

		return _.sortBy(list, [
			function (o) {
				return (o.display_name || '').toLowerCase();
			},
		]);
	};

	const handleBulkDataChange = (header, data) => {
		if (header && (data || data === 0 || data === '')) {
			const newState = {};
			newState[header] = data;
			updateLocalState({ ...newState });
		}
	};

	const resetBulkUpdateData = () => {
		updateLocalState({
			bulkUpdateInvoiceDate: null,
			bulkUpdateInvoiceStatus: 'na',
			bulkUpdatePartnerInvoice: null,
		});
	};

	const billingBulkUpdate = () => {
		const payload = [];
		const currentSelected = get(localState, 'selectedItems', []);
		const bulkUpdateInvoiceDate = get(
			localState,
			'bulkUpdateInvoiceDate',
			null
		);
		const bulkUpdatePartnerInvoice = get(
			localState,
			'bulkUpdatePartnerInvoice',
			null
		);
		const bulkUpdateInvoiceStatus = get(
			localState,
			'bulkUpdateInvoiceStatus',
			null
		);

		currentSelected.forEach((el) => {
			if (el) {
				const billingObj = {};
				billingObj.encounter_uuid = el;
				if (
					bulkUpdateInvoiceDate &&
					moment(bulkUpdateInvoiceDate).isValid()
				) {
					const formattedDate = moment(bulkUpdateInvoiceDate).format(
						'YYYY-MM-DD'
					);
					billingObj.partner_invoice_date = formattedDate;
				}
				if (
					bulkUpdatePartnerInvoice &&
					!isNaN(Number(bulkUpdatePartnerInvoice))
				) {
					billingObj.partner_invoice_rate = Number(
						bulkUpdatePartnerInvoice
					);
				}
				if (bulkUpdateInvoiceStatus)
					billingObj.partner_invoice_status = bulkUpdateInvoiceStatus;

				if (Object.keys(billingObj).length > 1)
					payload.push(billingObj);
			}
		});
		billingEncounterBulkUpdate(payload);
	};

	const handleResultsPerPageChange = (limit) => {
		updateLocalState({
			activePage: 0,
			limit,
		});
	};

	const { selectedEncounterRow = -1, showDetails = false } = localState;

	const exportButtonClass =
		billingEncountersData.length > 0
			? 'btn-export-billing-db'
			: 'btn-export-billing-db modalButtonDisabled';
	const tableWrapperClass = showDetails
		? 'billing_table_wrapper billing_table_side_panel_open'
		: 'billing_table_wrapper';
	const bulkUpdateButtonClass = get(
		localState,
		'renderBulkUpdateModal',
		false
	)
		? 'btn-export-billing-db billingBulkUpdateTriggerButton'
		: 'btn-export-billing-db';
	const renderBulkUI = determineSomeChecked() || determineAllChecked();

	const pagination = {
		end: get(billingEncounters, 'data.end', 0),
		has_next: get(billingEncounters, 'data.has_next', 0),
		has_prev: get(billingEncounters, 'data.has_prev', false),
		limit: get(billingEncounters, 'data.limit', 0),
		offset: get(billingEncounters, 'data.offset', 0),
		start: get(billingEncounters, 'data.start', 0),
		total: get(billingEncounters, 'data.total', 0),
	};

	return (
		<div className='billing-db-wrapper'>
			<section
				className='billing-db-fltr-wrapper'
				style={{
					justifyContent: renderBulkUI
						? 'flex-start'
						: 'space-between',
				}}
			>
				<div
					className={exportButtonClass}
					onClick={exportEncounters}
					style={{ marginRight: renderBulkUI ? '0px' : '39px' }}
				>
					<ReactSVG
						className={'menuItemIconWhite icons-btn-left'}
						src={export_img}
						style={{ height: '40px' }}
					/>
					<span className='btn-export-txt'>Export</span>
				</div>
				{!renderBulkUI ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<Searchbar
							setStateFunc={handleQueryChange}
							currentValue={get(localState, 'q', '') || ''}
							useInputEvtObj={true}
							placeholder='Search'
						/>
						<ResetFilters handleResetFilters={handleResetFilters} />
						<SingleSelect
							data={constructEncounterEnum()}
							currentValue={
								!get(localState, 'patient_fee_refund', false)
									? get(localState, 'needs_attention', false)
									: 'patient_fee_refund'
							}
							title={'Status'}
							setStateFunc={getFilteredEncounters}
							defaultValue={false}
						/>
						<SingleSelect
							data={constructReferralChannelEnum()}
							currentValue={get(
								localState,
								'referral_channel',
								''
							)}
							title={'Referral Channels'}
							setStateFunc={handleReferralChannelSelection}
							convertStrsToNums={true}
							defaultValue={-1}
						/>
						<MultiSelectCheckbox
							data={constructReferralProgramEnum()}
							selected={get(localState, 'referral_program', [])}
							title={'Referral Programs'}
							submit={(values) =>
								handleReferralProgramSelection(values)
							}
							majorPillTitle={'Referral Programs'}
							defaultValues={[]}
							searchBar={true}
							previewTab={true}
							alwaysNumber={false}
							zeroToAllPillTitle={true}
						/>
						<MoreFilters
							data={formulateMoreFiltersData()}
							submit={(filters) =>
								changeFilterBulk({ ...filters })
							}
							alternateModalClass={
								'moreFiltersModalContainerWide'
							}
						/>
					</div>
				) : (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							position: 'relative',
						}}
					>
						<span className='divider' />
						<div
							className={bulkUpdateButtonClass}
							style={{ width: '158px', marginLeft: '0px' }}
							onClick={openBulkUpdateModal}
						>
							<ReactSVG
								className={'menuItemIconWhite icons-btn-left'}
								src={pencilIcon}
								style={{ height: '40px' }}
							/>
							<span className='btn-export-txt'>
								Bulk Update (
								{get(localState, 'selectedItems.length', 0) ||
									0}
								)
							</span>
						</div>
						{get(localState, 'renderBulkUpdateModal', false) && (
							<BulkUpdateModal
								billingBulkUpdate={billingBulkUpdate}
								billingEncounterBulkUpdating={
									billingEncounterBulkUpdating
								}
								closeModal={closeBulkUpdateModal}
								handleBulkDataChange={handleBulkDataChange}
								invoiceDate={get(
									localState,
									'bulkUpdateInvoiceDate',
									null
								)}
								invoiceStatus={get(
									localState,
									'bulkUpdateInvoiceStatus',
									null
								)}
								partnerInvoice={get(
									localState,
									'bulkUpdatePartnerInvoice',
									null
								)}
								partnerInvoiceEnum={partnerInvoiceEnums}
								resetBulkUpdateData={resetBulkUpdateData}
							/>
						)}
					</div>
				)}
			</section>
			{!get(localState, 'initialLoaded', false) ||
			billingEncountersLoading ? (
				<Loading loading={true} className='noBackgroundSpinner'>
					<div style={{ height: '50vh', width: '50vw' }}></div>
				</Loading>
			) : (
				<>
					<div className={tableWrapperClass}>
						<BillingTableFN
							{...{
								billingEncountersData,
								determineAllChecked,
								determineIsChecked,
								determineSomeChecked,
								handleAllToggle,
								handleCheckboxChange,
								handlePageChange,
								handleResetFilters,
								handleResultsPerPageChange,
								getImageName,
								getLabName,
								getTestName,
								pagination,
								selectedEncounterRow,
								showDetailForEncounter,
								showDetails,
								tableWrapperClass,
							}}
						/>
						<div
							id='sliding_detail_panel'
							className='sliding_detail_panel'
							style={{
								display: get(localState, 'showDetails', false)
									? 'block'
									: 'none',
							}}
						>
							<BillingDetail
								{...{
									billingSegmentProps: {
										billingStatusEnums,
										encounterBillingDetail,
										encounterDetail,
										isBillingCoordinator,
										partnerInvoiceEnums,
										patientFeeEnum,
										saveEncounterValues,
										patientDetail,
										referralPrograms,
									},
									closeDetailView,
									encounterDetail,
									encounterInfoSegmentProps: {
										bloodRequestEnums,
										encounterDetail,
										enqueueSnackbar,
										history,
										labEnums,
										patientDetail,
										testOrders: encounterTestOrderRequest,
										testOrdersPreAuth:
											encounterPreAuthTestOrders,
										typeOfTestEnums,
									},
									enqueueSnackbar,
									history,
									patientDetail,
									sexAssignedAtBirthEnum,
								}}
							/>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	allAffiliations: get(
		state,
		'billingaffiliations.billing_affiliations.data.all_affiliations',
		[]
	),
	affiliationsPerChannel: get(
		state,
		'billingaffiliations.billing_affiliations.data.affiliations_per_channel',
		[]
	),
	billingEncounters: get(state, 'billingencounters.billingEncounters', {}),
	billingEncountersData: get(
		state,
		'billingencounters.billingEncounters.data.data',
		[]
	),
	billingStatusEnums: get(
		state,
		'getEncounterEnums.billingEnums.data.billing_status.data.billing_status',
		[]
	),
	billingEncountersLoading: get(
		state,
		'billingencounters.billingEncountersLoading',
		{}
	),
	billingEncountersError: get(
		state,
		'billingencounters.billingEncountersError',
		{}
	),
	billingEncounterBulkUpdated: get(
		state,
		'billingEncounterBulkUpdate.billingEncounterBulkUpdated',
		null
	),
	billingEncounterBulkUpdatedError: get(
		state,
		'billingEncounterBulkUpdate.billingEncounterBulkUpdatedError',
		null
	),
	billingEncounterBulkUpdating: get(
		state,
		'billingEncounterBulkUpdate.billingEncounterBulkUpdating',
		null
	),
	billingFilter: get(state, 'appData.billing', {}),
	bloodRequestEnums: get(
		state,
		'getEncounterEnums.billingEnums.data.lab_enums.data.blood_draw_request',
		[]
	),
	dashboardCounts: get(
		state,
		'getCounts.billing_dashboard_counts.data.data',
		{}
	),
	encounterBillingDetail: get(
		state,
		'getBillingEncounterObject.billingobject.data.data[0]',
		{}
	),
	encounterDetail: get(
		state,
		'getBillingEncounterDetail.billingencounter.data.data[0]',
		{}
	),
	encounterPreAuthTestOrders: get(
		state,
		'encounterPreAuthTestOrders.data',
		[]
	),
	encounterTestOrderRequest: _.orderBy(
		get(state, 'encounterTestOrderRequest.data', []),
		(o) => new moment(get(o, 'created_at', null)),
		['asc']
	),
	isBillingCoordinator: get(
		state,
		'me.activerole.isBillingCoordinator',
		false
	),
	labEnums: get(
		state,
		'getEncounterEnums.billingEnums.data.lab_enums.data.lab',
		[]
	),
	limit: get(state, 'uiConfig.table.resultsPerPage', 25),
	partnerInvoiceEnums: get(
		state,
		'getEncounterEnums.billingEnums.data.partner_invoice_enums.data.partner_invoice_status',
		[]
	),
	patientFeeEnum: get(state, 'patientFeeEnum.patientFeeEnum', []),
	referral_channel: get(state, 'referralchannels.referral_channels.data', {}),
	referralChannels: get(
		state,
		'referralchannels.referral_channels.data.data',
		[]
	),
	patientDetail: get(
		state,
		'getBillingPatientDetail.billingpatient.data.data[0]',
		{}
	),
	sexAssignedAtBirthEnum: get(
		state,
		'sexAssignedAtBirth.sexAssignedAtBirth.sex_assigned_at_birth',
		[]
	),
	timestamp: Object.assign({}, new Date()),
	typeOfTestEnums: get(
		state,
		'getEncounterEnums.billingEnums.data.lab_enums.data.type_of_test',
		[]
	),
	gettingReferralPrograms: _.get(
		state,
		'referralPrograms.gettingReferralPrograms',
		null
	),
	referralPrograms:
		_.get(state, 'referralPrograms.referralPrograms.data', null) || [],
	referralProgramsError: _.get(
		state,
		'referralPrograms.referralProgramsError',
		null
	),
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(
		{
			billingEncounterBulkUpdate,
			exportBillingEncounters,
			getBillingDashboardCounts,
			getBillingEncountersList,
			getBillingEncounterDetail,
			getEncounterEnums,
			getBillingPatientDetail,
			getPatientFeeEnum,
			getPreauthorizationTestOrders,
			getReferralChannelsList,
			getSexAssignedAtBirth,
			getTestOrderRequest,
			saveBillingDashboardEncounter,
			getReferralPrograms,
			setFilter,
		},
		dispatch
	),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingDashboard);
