//React & Redux
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

//Lodash
import _ from 'lodash';

//Utils
import { isValidNumber } from 'libphonenumber-js';
import validPostalCode from 'postcode-validator';
import { states_list } from '../../utils.js';
import { FINANCE_INSURANCE_EDIT_COMPANY_NAME } from '../../Utils/permissionUtils';
import { createStateEnumsOptions } from '../Patients/V2/Encounters/Common/utilsfunctions';

//UI Libraries
import { Loading, Input, Button, Layout } from 'gm-element-react';
import InputMask from 'react-input-mask';

//Components
import RBAC from '../RBAC';
import CommonCheckbox from '../Common/Checkbox.js';
import { SelectComponent as Select } from '../Common/SelectComponent';

//Styles
import './css/addedit-insurance.css';

const validate = (values) => {
	const errors = {};
	const isEmpty = (value) => _([undefined, null, '']).includes(value);
	const addressMatch = (value) =>
		value.match(/((?:[A-Za-z0-9-\—\ '/,.()!@$%#]+$)*[a-zA-Z0-9])/);
	const cityMatch = (value) =>
		value.match(/^[a-zA-Z]+[a-zA-Z\.]+(?:[\s-][a-zA-Z]+[a-zA-Z\.]+)*$/);
	const isDigits = (value) => value.match(/^\d+$/);
	const isDigitsWithTwoPrecision = (value) =>
		value.match(/^\d+(\.\d{1,2})?$/);

	if (isEmpty(values.insuranceCompanyName)) {
		errors.insuranceCompanyName = 'Insurance company name is required';
	}

	if (isEmpty(values.consultationPrice)) {
		errors.consultationPrice = 'Price to charge is required';
	}

	if (
		!isEmpty(values.consultationPrice) &&
		!isDigits(_.toString(values.consultationPrice))
	) {
		errors.consultationPrice = 'Price is invalid';
	}

	if (
		!isEmpty(values.consultationPrice) &&
		isDigits(_.toString(values.consultationPrice)) &&
		Number(values.consultationPrice >= 100000)
	) {
		errors.consultationPrice = 'Price must be less than $100,000';
	}

	if (
		!isEmpty(values.expectedRevenue) &&
		!isDigitsWithTwoPrecision(_.toString(values.expectedRevenue))
	) {
		errors.expectedRevenue = 'Revenue is invalid';
	}

	if (values.phone && !isValidNumber(values.phone)) {
		errors.phone = 'Phone number is invalid';
	}

	if (values.address && !addressMatch(values.address)) {
		errors.address = 'Address contains invalid characters';
	}

	if (values.city && !cityMatch(values.city)) {
		errors.city = 'City is invalid';
	}

	if (values.zip && !validPostalCode.validate(values.zip, 'US')) {
		errors.zip = 'Invalid zip';
	}

	return errors;
};

const alphaNumeric = (value, previousValue) => {
	if (!value) return value;
	return /[^a-zA-Z0-9 ]/i.test(value) ? previousValue : value;
};

const renderField = ({
	className,
	disabled,
	label,
	input,
	isrequired,
	meta,
	placeholder,
	touched,
	trim,
}) => (
	<>
		<label className={`${isrequired ? 'required' : ''}`}>{label}</label>
		<Input
			{...{
				...input,
				className:
					(className || '') + ' erFieldText inputFieldTextTaller',
				disabled: !_([undefined, null, '']).includes(disabled)
					? disabled
					: false,
				placeholder,
				trim,
			}}
		/>

		{touched && meta.error ? (
			<div className='error'>{meta.error}</div>
		) : (
			<div className='no-error'>&nbsp;</div>
		)}
	</>
);

const renderFieldPrice = ({
	disabled,
	label,
	input,
	isrequired,
	meta,
	placeholder,
	touched,
	trim,
}) => (
	<>
		<label className={`${isrequired ? 'required' : ''}`}>{label}</label>
		<div
			className='enabledInputField'
			style={{ height: '36px', width: '160px' }}
		>
			<span
				style={{
					color:
						_.get(input, 'value', '') ||
						_.get(input, 'value', '') === 0
							? '#262837'
							: '#bac3ce',
				}}
			>
				$
			</span>
			<input
				{...{
					...input,
					className: 'enabledInputFieldInner',
					disabled: !_([undefined, null, '']).includes(disabled)
						? disabled
						: false,
					placeholder,
					style: { width: '135px' },
					trim,
				}}
			/>
		</div>

		{touched && meta.error ? (
			<div className='error'>{meta.error}</div>
		) : (
			<div className='no-error'>&nbsp;</div>
		)}
	</>
);

const renderInputMask = (field) => {
	const { label, input, isrequired, meta, touched } = field;

	return (
		<>
			<label className={`${isrequired ? 'required' : ''}`}>{label}</label>
			<InputMask
				{...{
					...field,
					...input,
				}}
			/>
			{touched && meta.error ? (
				<div className='error'>{meta.error}</div>
			) : (
				<div className='no-error'>&nbsp;</div>
			)}
		</>
	);
};

const renderSelect = ({
	label,
	input,
	meta,
	placeholder,
	isrequired,
	touched,
}) => (
	<>
		<label className={`${isrequired ? 'required' : ''}`}>{label}</label>
		<Select
			{...{
				...input,
				className: 'inputFieldDropdown inputFieldDropdownTaller',
				placeholder,
				value: input.value,
			}}
			clearable={true}
			typeahead={true}
			options={createStateEnumsOptions(
				states_list || [],
				'key',
				'value',
				'key'
			)}
			keyOverride={true}
		/>
		{touched && meta.error ? (
			<div className='error'>{meta.error}</div>
		) : (
			<div className='no-error'>&nbsp;</div>
		)}
	</>
);

const renderCheckbox = ({ label, input, meta, touched }) => (
	<>
		<label>{label}</label>
		<CommonCheckbox
			{...{
				...input,
				onChangeFunc: input.onChange,
			}}
		/>
		{touched && meta.error ? (
			<div className='error'>{meta.error}</div>
		) : (
			<div className='no-error'>&nbsp;</div>
		)}
	</>
);

const AddEditInsurance = (props) => {
	const {
		buildReqData,
		changeToExceptionsStage,
		editInsuranceInfo,
		handleSubmit,
		valid,
	} = props;

	const [loading, setLoading] = useState(false);

	const isEditMode = !_.isEmpty(editInsuranceInfo) ? true : false;

	const onSubmit = (values) => {
		buildReqData(values);
		changeToExceptionsStage();
	};

	const renderInsuanceCompanyNameField = (isDisabled = false) => (
		<Field
			name='insuranceCompanyName'
			type='text'
			label='Insurance Company Name'
			placeholder='Insurance ABC'
			trim={true}
			component={renderField}
			isrequired={true}
			normalize={alphaNumeric}
			sensitive={true}
			className={isDisabled ? 'edit-insunance-immutablefield' : ''}
			disabled={isDisabled ? true : false}
		/>
	);

	return (
		<>
			<div className='addEditInsuranceDetailsContainer'>
				<Loading
					loading={loading}
					className='loading-addedit-insurance-form'
				>
					<form
						onSubmit={handleSubmit(onSubmit)}
						className='addedit-insunance-form'
					>
						<Layout.Row>
							<Layout.Col span='24'>
								{isEditMode ? (
									<RBAC
										action={
											FINANCE_INSURANCE_EDIT_COMPANY_NAME
										}
										yes={renderInsuanceCompanyNameField(
											false
										)}
										no={renderInsuanceCompanyNameField(
											true
										)}
									/>
								) : (
									renderInsuanceCompanyNameField(false)
								)}
							</Layout.Col>
						</Layout.Row>
						<Layout.Row>
							<Layout.Col span='24'>
								<Field
									name='athenaPackageName'
									type='text'
									label='Package Name'
									placeholder='1234567890'
									trim={true}
									component={renderField}
								/>
							</Layout.Col>
						</Layout.Row>
						<Layout.Row gutter='16'>
							<Layout.Col span='12'>
								<Field
									name='athenaPackageID'
									type='text'
									label='Athena Package ID'
									placeholder='1234567890'
									trim={true}
									component={renderField}
								/>
							</Layout.Col>
							<Layout.Col span='12'>
								<Field
									name='phone'
									type='text'
									label='Phone Number'
									mask='+1 (999) 999-9999'
									placeholder='+1 (123) 456 - 7890'
									maskChar={null}
									autoComplete='off'
									className='inputFieldTextTaller inputFieldText'
									component={renderInputMask}
								/>
							</Layout.Col>
						</Layout.Row>
						<Layout.Row>
							<Layout.Col span='24'>
								<Field
									name='address'
									type='text'
									label='Address'
									placeholder='123 Main street'
									trim={true}
									component={renderField}
								/>
							</Layout.Col>
						</Layout.Row>
						<Layout.Row gutter='16'>
							<Layout.Col span='12'>
								<Field
									name='city'
									type='text'
									label='City'
									placeholder='San francisco'
									trim={true}
									component={renderField}
								/>
							</Layout.Col>
							<Layout.Col span='6'>
								<Field
									name='state'
									component={renderSelect}
									type='select'
									label='State'
									placeholder='CA'
								/>
							</Layout.Col>
							<Layout.Col span='6'>
								<Field
									name='zip'
									type='text'
									label='Zipcode'
									placeholder='12345'
									trim={true}
									component={renderField}
								/>
							</Layout.Col>
						</Layout.Row>
						<Layout.Row gutter='16'>
							<Layout.Col span='12'>
								<Field
									name='consultationPrice'
									type='text'
									label='Standard PrePay To Charge'
									placeholder='179'
									trim={true}
									component={renderFieldPrice}
									isrequired={true}
								/>
							</Layout.Col>
							<Layout.Col span='12'>
								<Field
									name='expectedRevenue'
									type='text'
									label='Expected Revenue'
									placeholder='179'
									trim={true}
									component={renderFieldPrice}
								/>
							</Layout.Col>
						</Layout.Row>
						<Layout.Row>
							<Layout.Col span='24'>
								<Field
									name='athena_facility_id'
									type='text'
									label='Athena Facility ID'
									placeholder='123'
									trim={true}
									component={renderField}
								/>
							</Layout.Col>
						</Layout.Row>
						<Layout.Row gutter='16'>
							<Layout.Col span='14'>
								<Field
									component={renderCheckbox}
									type='checkbox'
									name='noSupervisingBilling'
									label='No Supervision Billing'
								/>
							</Layout.Col>
						</Layout.Row>
						<Layout.Row gutter='16'>
							<Layout.Col span='14'>
								<Field
									component={renderCheckbox}
									type='checkbox'
									name='useGTModifier'
									label='Use GT Modifier'
								/>
							</Layout.Col>
						</Layout.Row>
						<Layout.Row gutter='16'>
							<Layout.Col span='14'>
								<Field
									component={renderCheckbox}
									type='checkbox'
									name='isAuthRequired'
									label='Auth Req'
								/>
							</Layout.Col>
						</Layout.Row>
						<Layout.Row gutter='16'>
							<Layout.Col span='14'>
								<Field
									component={renderCheckbox}
									type='checkbox'
									name='md_required'
									label='MD Required'
								/>
							</Layout.Col>
						</Layout.Row>
						<Layout.Row className='addedit-insurance-btn-container'>
							<Layout.Col span='12'></Layout.Col>
							<Layout.Col span='12' style={{ float: 'right' }}>
								<Button
									nativeType='submit'
									disabled={!valid}
									className='btn-create-insurance'
								>
									Continue
								</Button>
							</Layout.Col>
						</Layout.Row>
					</form>
				</Loading>
			</div>
		</>
	);
};

AddEditInsurance.propTypes = {
	initialValues: PropTypes.object,
};

// Decorate the form component
export default reduxForm({
	form: 'addeditInsuranceInfo', // a unique name for this form
	validate,
})(AddEditInsurance);
