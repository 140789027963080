//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import alertIcon from './../../assets/alert.svg';

//Lodash
import get from 'lodash/get';

//UI Libraries
import { Loading } from 'gm-element-react';

const TemplateModalBasic = (props) => {
	const { close, func, loading = false, message, title } = props;

	return (
		<div className='confirmationModalContainer'>
			<Loading loading={loading}>
				<div className='confirmationModalHeaderRow'>
					<ReactSVG
						src={alertIcon}
						className='confirmationModalHeaderRowIcon'
					/>
					<div style={{ position: 'relative', bottom: '3px' }}>
						{title || 'Confirmation'}
					</div>
				</div>
				<div className='confirmationModalBodyContainer'>
					{message || 'Are you sure?'}
				</div>
				<div className='confirmationModalButtonRow'>
					<div
						className='confirmationModalButtonRowCancel'
						onClick={(e) => close()}
					>
						Cancel
					</div>
					<div
						className='confirmationModalButtonRowConfirm'
						onClick={(e) => func()}
					>
						{title || 'Accept'}
					</div>
				</div>
			</Loading>
		</div>
	);
};

export default TemplateModalBasic;
