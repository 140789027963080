export const actionVals = [
	'Edit',
	'Duplicate',
	'Archive',
	'Finalize Draft',
	'Edit Referral Programs',
];

export const statusVals = [
	{ internal: 'active', external: 'Active Templates' },
	{ internal: 'draft', external: 'Draft Templates' },
	{ internal: 'archived', external: 'Archived Templates' },
];

export const typeVals = [
	'All Template Types',
	'Administrative Misc',
	'Genetic Test Results',
	'Visit Documentation',
];
