//React & Redux
import React from 'react';
import _ from 'lodash';
import ArrowIcon from '../../assets/chevron-appointment-right.svg';
import ReactSVG from 'react-svg';

const DashboardSummaryCard = (props) => {
	const { cardList, handleCardClick, cardData } = props;
	const renderSingleCard = (cardDetail) => {
		const value = cardData[cardDetail.name];
		const CardNumberClass =
			cardDetail.name == 'criteria_met'
				? 'bucket_cards meets_criteria'
				: 'bucket_cards';
		return (
			<div
				key={cardDetail.name}
				className='rise-stage-card'
				onClick={() =>
					!_.isNil(value) &&
					value != 0 &&
					handleCardClick(cardDetail.name)
				}
			>
				<div className={CardNumberClass}>
					<div
						className={`contentbox ${
							_.isNil(value) || value == 0
								? 'contentbox-disable'
								: ''
						}`}
					>
						<div className='textbox'>
							<p className='card-title'>
								{cardDetail.display_name}
							</p>
							<p className={'card-number'}>
								{cardData[cardDetail.name] || '0'}
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className='dashboard-cards-cont'>
			<div className='patient_cards'>
				<div className='main-grid-container'>
					{false && (
						<ReactSVG src={ArrowIcon} className='left-icon' />
					)}
					<div className='dashboard-grid-container'>
						{_.map(cardList, (el) => {
							return renderSingleCard(el);
						})}
					</div>
					{false && (
						<ReactSVG src={ArrowIcon} className='right-icon' />
					)}
				</div>
			</div>
		</div>
	);
};
export default DashboardSummaryCard;
