import * as actionTypes from './actionTypes';
import PatientService from '../service/Patients';
import get from 'lodash/get';
import _ from 'lodash';
import {
	hydratePatients,
	hydratePatientsValues,
	hydratePatientEditError,
	hydrateEligibilityFields,
	hydrateProviders,
	orderTags,
	orderRaces,
	deHydratePatientInsurance,
} from '../transformers/patients/patientTransformer';

export const patients = (data) => {
	return {
		type: actionTypes.PATIENTS,
		payload: data,
	};
};
export const patientsLoading = () => {
	return {
		type: actionTypes.PATIENTS_LOADING,
	};
};

export const patientsLoadingComplete = () => {
	return {
		type: actionTypes.PATIENTS_LOADING_COMPLETE,
	};
};

const patientsError = (err) => {
	return {
		type: actionTypes.PATIENTS_ERROR,
		payload: err,
	};
};

export const gettingPatientDetail = () => {
	return {
		type: actionTypes.GETTING_PATIENT_DETAIL,
		payload: true,
	};
};

export const patientsDetail = (data) => {
	return {
		type: actionTypes.PATIENT_DETAIL,
		payload: hydratePatients(data) || {},
	};
};

export const patientsDetailError = (err) => {
	return {
		type: actionTypes.PATIENT_DETAIL_ERROR,
		payload: err,
	};
};

export const editPatient = (data) => {
	return {
		type: actionTypes.EDIT_PATIENT,
		payload: data,
	};
};
export const deletePatient = (data) => {
	return {
		type: actionTypes.DELETE_PATIENT,
		payload: data,
	};
};

const globalPatientSearchStart = () => {
	return {
		type: actionTypes.GLOBAL_PATIENT_SEARCH_START,
	};
};

const globalPatientSearchCompleted = (payload) => {
	return {
		type: actionTypes.GLOBAL_PATIENT_SEARCH_COMPLETED,
		payload,
	};
};

const globalPatientSearchError = (payload) => {
	return {
		type: actionTypes.GLOBAL_PATIENT_SEARCH_ERROR,
		payload,
	};
};

export const globalPatientSearchClear = () => {
	return {
		type: actionTypes.GLOBAL_PATIENT_SEARCH_CLEAR,
	};
};

export function getpatients(data) {
	const query = get(data, 'q', '');
	data['q'] = encodeURIComponent(data['q']);
	return (dispatch) => {
		dispatch(patientsLoading());
		PatientService.patients(data)
			.then((res) => {
				dispatch(
					patients({
						data: hydratePatientsValues(res.data),
						q: query,
						offset: get(data, 'offset', 0),
						limit: get(data, 'limit', null),
						primary: get(data, 'primary', null),
					})
				);
				dispatch(patientsLoadingComplete());
			})
			.catch((err) => {
				dispatch(patientsLoadingComplete());
				dispatch(patientsError(err));
			});
	};
}

export function globalPatientSearch(query) {
	return (dispatch) => {
		dispatch(globalPatientSearchStart());
		PatientService.patientsWithoutEncounters({
			q: encodeURIComponent(query),
			offset: 0,
			limit: 50,
		})
			.then((res) => {
				dispatch(
					globalPatientSearchCompleted(
						hydratePatientsValues(res.data.data)
					)
				);
			})
			.catch((err) => {
				dispatch(globalPatientSearchError(err));
			});
	};
}

export function patientdetail(pid, flag) {
	return (dispatch) => {
		if (!flag) dispatch(resetPatientInfo());
		dispatch(gettingPatientDetail());
		return PatientService.patientdetail(pid)
			.then((res) => {
				const hydratedVals = hydratePatientsValues(res.data.data);
				dispatch(patientsDetail(hydratedVals));
				return hydratedVals[0];
			})
			.catch((err) => {
				dispatch(patientsDetailError(err));
			});
	};
}

export function resetPatientInfo() {
	return (dispatch) => {
		dispatch(patientsDetail({}));
	};
}

export const dispatchResetLastDelete = () => {
	return {
		type: actionTypes.RESET_DELETE_PATIENT,
		payload: null,
	};
};

export function resetLastDeletePatient() {
	return (dispatch) => {
		dispatch(dispatchResetLastDelete());
	};
}

export function deletepatient(data) {
	return (dispatch) => {
		PatientService.deletepatient(data)
			.then((res) => {
				dispatch(deletePatient(res.data));
			})
			.catch((err) => {
				dispatch(deletePatient(err));
			});
	};
}

//Add Patient
export function addpatient(data) {
	return async (dispatch) => {
		dispatch(addingPatient());
		try {
			const res = await PatientService.addpatient(data);
			dispatch(addPatient(res.data.data));
			return res.data;
		} catch (err) {
			const errorData =
				err?.response?.data || 'Unexpected error occurred';

			dispatch(addPatientError(errorData || ''));
			throw errorData;
		}
	};
}

export const addingPatient = () => {
	return {
		type: actionTypes.ADDING_PATIENT_LOADING,
		payload: true,
	};
};

export const addPatient = (data) => {
	return {
		type: actionTypes.ADD_PATIENT,
		payload: data,
	};
};

//Referral Programs
export function getReferralPrograms() {
	return (dispatch) => {
		dispatch(dispatchGettingReferralPrograms());
		PatientService.getReferralPrograms()
			.then((res) => {
				dispatch(dispatchReferralPrograms(get(res, 'data', {})));
			})
			.catch((err) => {
				dispatch(dispatchReferralProgramsError(err));
			});
	};
}

export function getAppointmentReferralPrograms() {
	return (dispatch) => {
		dispatch(dispatchGettingAppointmentReferralPrograms());
		PatientService.getAppointmentReferralPrograms()
			.then((res) => {
				dispatch(
					dispatchAppointmentReferralPrograms(get(res, 'data', {}))
				);
			})
			.catch((err) => {
				dispatch(dispatchReferralProgramsError(err));
			});
	};
}

export const dispatchGettingReferralPrograms = () => {
	return {
		type: actionTypes.GETTING_REFERRAL_PROGRAMS,
		payload: true,
	};
};

export const dispatchGettingAppointmentReferralPrograms = () => {
	return {
		type: actionTypes.GETTING_APPT_REFERRAL_PROGRAMS,
		payload: true,
	};
};

export const dispatchReferralPrograms = (data) => {
	return {
		type: actionTypes.GET_REFERRAL_PROGRAMS,
		payload: data,
	};
};

export const dispatchAppointmentReferralPrograms = (data) => {
	return {
		type: actionTypes.GET_APPT_REFERRAL_PROGRAMS,
		payload: data,
	};
};

export const dispatchReferralProgramsError = (err) => {
	return {
		type: actionTypes.GET_REFERRAL_PROGRAMS_ERROR,
		payload: err,
	};
};

export function resetEligibilityFields() {
	return (dispatch) => {
		dispatch(dispatchEligibilityFields([]));
	};
}

//Contact Methods
export function getContactMethods() {
	return (dispatch) => {
		dispatch(gettingContactMethods());
		PatientService.getContactMethods()
			.then((res) => {
				dispatch(dispatchContactMethods(res.data));
			})
			.catch((err) => {
				dispatch(contactMethodsError(err));
			});
	};
}

export const gettingContactMethods = () => {
	return {
		type: actionTypes.GETTING_CONTACT_METHODS,
		payload: true,
	};
};

export const dispatchContactMethods = (data) => {
	return {
		type: actionTypes.CONTACT_METHODS,
		payload: data,
	};
};

export const contactMethodsError = (err) => {
	return {
		type: actionTypes.CONTACT_METHODS_ERROR,
		payload: err,
	};
};

//Contact Outcomes
export function getContactOutcomes() {
	return (dispatch) => {
		dispatch(gettingContactOutcomes());
		PatientService.getContactOutcomes()
			.then((res) => {
				dispatch(
					gotContactOutcomes(
						get(res, 'data.data', []).sort(
							(a, b) => get(a, 'id', 0) - get(b, 'id', 0)
						)
					)
				);
			})
			.catch((err) => {
				dispatch(contactOutcomesError(get(err, 'response.data', {})));
			});
	};
}

export const gettingContactOutcomes = () => {
	return {
		type: actionTypes.GETTING_CONTACT_OUTCOMES,
		payload: true,
	};
};

export const gotContactOutcomes = (data) => {
	return {
		type: actionTypes.CONTACT_OUTCOMES,
		payload: data,
	};
};

export const contactOutcomesError = (error) => {
	return {
		type: actionTypes.CONTACT_OUTCOMES_ERROR,
		payload: error,
	};
};

// Patient Edits

//Update Outreach, Non-address patient update
export function updateOutreach(data) {
	return (dispatch) => {
		dispatch(updatingOutreach());
		PatientService.updateOutreach(data)
			.then((res) => {
				dispatch(updatedOutreach(res));
			})
			.catch((err) => {
				dispatch(
					updatedOutreachError(
						hydratePatientEditError(get(err, 'response.data', '')),
						data.number
					)
				);
			});
	};
}

export const updatingOutreach = (number = '') => {
	return {
		type: actionTypes.UPDATING_OUTREACH + number,
		payload: true,
	};
};

export const updatedOutreach = (data, number = '') => {
	return {
		type: actionTypes.UPDATE_OUTREACH + number,
		payload: get(data, 'data.data', {}),
	};
};

export const updatedOutreachError = (data, number = '') => {
	return {
		type: actionTypes.UPDATE_OUTREACH_ERROR + number,
		payload: data,
	};
};

//Do not outreach (older)
export function getNoOutreachReasons() {
	return (dispatch) => {
		dispatch(gettingNoOutreachReasons());
		PatientService.getNoOutreachReasons()
			.then((res) => {
				dispatch(gotNoOutreachReasons(res));
			})
			.catch((err) => {
				dispatch(gotNoOutreachReasonsError(err));
			});
	};
}

export const gettingNoOutreachReasons = () => {
	return {
		type: actionTypes.GETTING_NO_OUTREACH_REASONS,
		payload: true,
	};
};

export const gotNoOutreachReasons = (data) => {
	return {
		type: actionTypes.GOT_NO_OUTREACH_REASONS,
		payload: get(data, 'data.data.outreach_reason', null),
	};
};

const gotNoOutreachReasonsError = (err) => {
	return {
		type: actionTypes.GOT_NO_OUTREACH_REASONS_ERROR,
		payload: err,
	};
};

//Update Patient Address
export function updateAddress(data) {
	data.payload.forEach((item) => {
		if (!item.country) {
			item.country = 'US';
		}
	});
	return (dispatch) => {
		dispatch(updatingAddress());
		return PatientService.updateAddress(data)
			.then((res) => {
				dispatch(updatedAddress(res));
				return res;
			})
			.catch((err) => {
				dispatch(
					updateAddressError(
						_.get(err, 'response.data.fields', null) || {}
					)
				);
			});
	};
}

export const updatingAddress = () => {
	return {
		type: actionTypes.UPDATING_ADDRESS,
		payload: true,
	};
};

export const updatedAddress = (data) => {
	return {
		type: actionTypes.UPDATE_ADDRESS,
		payload: get(data, 'data.data', {}),
	};
};

export const updateAddressError = (err) => {
	return {
		type: actionTypes.UPDATE_ADDRESS_ERROR,
		payload: err,
	};
};

//Update Patient Relationship
export function updateRelationship(data) {
	return (dispatch) => {
		dispatch(updatingRelationship());
		PatientService.updateRelationship(data)
			.then((res) => {
				dispatch(updatedRelationship(res));
			})
			.catch((err) => {
				dispatch(
					updatedRelationshipError(
						hydratePatientEditError(get(err, 'response.data', ''))
					)
				);
			});
	};
}

export const updatingRelationship = () => {
	return {
		type: actionTypes.UPDATING_RELATIONSHIP,
		payload: true,
	};
};

export const updatedRelationship = (data) => {
	return {
		type: actionTypes.UPDATE_RELATIONSHIP,
		payload: get(data, 'data.data', {}),
	};
};

export const updatedRelationshipError = (err) => {
	return {
		type: actionTypes.UPDATE_RELATIONSHIP_ERROR,
		payload: err,
	};
};

// Clear all patient edit errors
export function clearAllPatientEditErrors() {
	return (dispatch) => {
		dispatch(resetPatientEditErrors());
	};
}

export const resetPatientEditErrors = () => {
	return {
		type: actionTypes.RESET_PATIENT_EDIT_ERRORS,
		payload: true,
	};
};

// End of Patient Edits

//Referral Reasons
export function getReferralReasons() {
	return (dispatch) => {
		dispatch(gettingReferralReasons());
		PatientService.getReferralReasons()
			.then((res) => {
				dispatch(dispatchReferralReasons(res.data.data));
			})
			.catch((err) => {
				dispatch(dispatchReferralReasonsError(err));
			});
	};
}

export const gettingReferralReasons = () => {
	return {
		type: actionTypes.GETTING_REFERRAL_REASONS,
		payload: true,
	};
};

export const dispatchReferralReasons = (data) => {
	return {
		type: actionTypes.REFERRAL_REASONS,
		payload: data,
	};
};

export const dispatchReferralReasonsError = (err) => {
	return {
		type: actionTypes.REFERRAL_REASONS_ERROR,
		payload: err,
	};
};

//Referral Providers
export function getReferralProviders() {
	return (dispatch) => {
		dispatch(gettingReferralProviders());
		PatientService.getReferralProviders()
			.then((res) => {
				dispatch(dispatchReferralProviders(res.data.data));
			})
			.catch((err) => {
				dispatch(dispatchReferralProvidersError(err));
			});
	};
}

export const gettingReferralProviders = () => {
	return {
		type: actionTypes.GETTING_REFERRAL_PROVIDERS,
		payload: true,
	};
};

export const dispatchReferralProviders = (data) => {
	return {
		type: actionTypes.REFERRAL_PROVIDERS,
		payload: data,
	};
};

export const dispatchReferralProvidersError = (err) => {
	return {
		type: actionTypes.REFERRAL_PROVIDERS_ERROR,
		payload: err,
	};
};

//getEligibilityFields
export function getEligibilityFields(data, editBool) {
	return (dispatch) => {
		dispatch(dispatchGettingEligibilityFields());
		PatientService.getEligibilityFields(data)
			.then((res) => {
				dispatch(
					dispatchEligibilityFields(
						hydrateEligibilityFields(
							get(res, 'data.data', []),
							editBool
						)
					)
				);
			})
			.catch((err) => {
				dispatch(dispatchEligibilityFieldsError(err));
			});
	};
}

export const dispatchGettingEligibilityFields = () => {
	return {
		type: actionTypes.ELIGIBILITY_FIELDS_LOADING,
		payload: true,
	};
};

export const dispatchEligibilityFields = (data) => {
	return {
		type: actionTypes.ELIGIBILITY_FIELDS,
		payload: data,
	};
};

export const dispatchEligibilityFieldsError = (err) => {
	return {
		type: actionTypes.ELIGIBILITY_FIELDS_ERROR,
		payload: err,
	};
};

//Consent to treat
export function getConsenttotreat() {
	return (dispatch) => {
		dispatch(gettingConsentToTreat());
		PatientService.getConsenttotreat()
			.then((res) => {
				dispatch(dispatchConsenttotreat(res.data.data));
			})
			.catch((err) => {
				dispatch(dispatchConsenttotreatError(err));
			});
	};
}

export const gettingConsentToTreat = () => {
	return {
		type: actionTypes.GETTING_CONSENT_TO_TREAT,
		payload: true,
	};
};

export const dispatchConsenttotreat = (data) => {
	return {
		type: actionTypes.CONSENT_TO_TREAT,
		payload: data,
	};
};

export const dispatchConsenttotreatError = (err) => {
	return {
		type: actionTypes.CONSENT_TO_TREAT_ERROR,
		payload: err,
	};
};

//Duplicate Patient Check
export function duplicatePatientCheck(data) {
	return (dispatch) => {
		dispatch(loadingDuplicatePatientCheck());
		PatientService.duplicatePatientCheck(data)
			.then((res) => {
				dispatch(duplicatePatientChecked(get(res, 'data', {})));
			})
			.catch((err) => {
				dispatch(duplicatePatientCheckedError(err));
			});
	};
}

export const loadingDuplicatePatientCheck = () => {
	return {
		type: actionTypes.DUPLICATE_PATIENT_CHECK_LOADING,
		payload: true,
	};
};

export const duplicatePatientChecked = (data) => {
	return {
		type: actionTypes.DUPLICATE_PATIENT_CHECK,
		payload: data,
	};
};

export const duplicatePatientCheckedError = (err) => {
	return {
		type: actionTypes.DUPLICATE_PATIENT_CHECK_LOADING_ERROR,
		payload: err,
	};
};

//error
export const addPatientError = (data) => {
	return {
		type: actionTypes.ADD_PATIENT_ERROR,
		payload: data,
	};
};

// SET PASSWORD
export function setPassword(data) {
	return (dispatch) => {
		dispatch(loadingSetPassword());
		PatientService.setPassword(data)
			.then((res) => {
				dispatch(setPasswordSuccess(res.data));
			})
			.catch((err) => {
				dispatch(setPasswordError(get(err, 'response.data', '')));
			});
	};
}

const loadingSetPassword = () => {
	return {
		type: actionTypes.LOADING_SET_PASSWORD,
	};
};

export const setPasswordSuccess = (data) => {
	return {
		type: actionTypes.SET_PASSWORD_SUCCESS,
		payload: data,
	};
};

export const setPasswordError = (data) => {
	return {
		type: actionTypes.SET_PASSWORD_ERROR,
		payload: data,
	};
};

// GET INSURANCE COMPANIES
export function getInsuranceCompanies(data, full_list = false) {
	return (dispatch) => {
		dispatch(gettingInsuranceCompanies());
		PatientService.getInsuranceCompanies(data, full_list)
			.then((res) => {
				dispatch(
					getInsuranceCompaniesSuccess(
						addOtherinLastPosition(get(res, 'data.data', []))
					)
				);
			})
			.catch((err) => {
				dispatch(getInsuranceCompaniesError(err));
			});
	};
}

function addOtherinLastPosition(companies) {
	let filtredCompany = [...companies];
	const Othercompany = filtredCompany.find((a) => a.name == 'Other');
	if (Othercompany) {
		filtredCompany = _.filter(companies, function (company) {
			return company && company.name !== 'Other';
		});
		filtredCompany.push(Othercompany);
	}
	return filtredCompany;
}

const gettingInsuranceCompanies = () => {
	return {
		type: actionTypes.GETTING_INSURANCE_COMPANIES,
		payload: true,
	};
};

export const getInsuranceCompaniesSuccess = (data) => {
	return {
		type: actionTypes.GET_INSURANCE_COMPANIES_SUCCESS,
		payload: data,
	};
};

const getInsuranceCompaniesError = (err) => {
	return {
		type: actionTypes.GET_INSURANCE_COMPANIES_ERROR,
		payload: err,
	};
};

// GET FULL LIST OF INSURANCE COMPANIES
export function getFullListOfInsuranceCompanies(data, full_list = true) {
	return (dispatch) => {
		dispatch(gettingFullListOfInsuranceCompanies());
		PatientService.getInsuranceCompanies(data, full_list)
			.then((res) => {
				dispatch(
					getFullListOfInsuranceCompaniesSuccess(
						addOtherinLastPosition(get(res, 'data.data', []))
					)
				);
			})
			.catch((err) => {
				dispatch(getFullListOfInsuranceCompaniesError(err));
			});
	};
}

const gettingFullListOfInsuranceCompanies = () => {
	return {
		type: actionTypes.GETTING_FULL_LIST_OF_INSURANCE_COMPANIES,
		payload: true,
	};
};

export const getFullListOfInsuranceCompaniesSuccess = (data) => {
	return {
		type: actionTypes.GET_FULL_LIST_OF_INSURANCE_COMPANIES_SUCCESS,
		payload: data,
	};
};

const getFullListOfInsuranceCompaniesError = (err) => {
	return {
		type: actionTypes.GET_FULL_LIST_OF_INSURANCE_COMPANIES_ERROR,
		payload: err,
	};
};

// ADD Insurance
export function saveInsurance(data, uuid) {
	return (dispatch) => {
		PatientService.saveInsurance(deHydratePatientInsurance(data), uuid)
			.then((res) => {
				dispatch(saveInsuranceSuccess(res.data));
			})
			.catch((err) => {
				dispatch(saveInsuranceError(get(err, 'response.data', '')));
			});
	};
}

export const saveInsuranceSuccess = (data) => {
	return {
		type: actionTypes.SAVE_INSURANCE_SUCCESS,
		payload: data,
	};
};

export const saveInsuranceError = (data) => {
	return {
		type: actionTypes.SAVE_INSURANCE_ERROR,
		payload: data,
	};
};

// Save Insurance auth
export function saveInsuranceAuth(data) {
	return (dispatch) => {
		dispatch(loadingSaveInsuranceAuth());
		PatientService.saveInsuranceAuth(data)
			.then((res) => {
				dispatch(saveInsuranceAuthSuccess(res.data));
			})
			.catch((err) => {
				dispatch(saveInsuranceAuthError(get(err, 'response.data', '')));
			});
	};
}

const loadingSaveInsuranceAuth = (data) => {
	return {
		type: actionTypes.LOADING_SAVE_INSURANCE_AUTH,
		payload: data,
	};
};

export const saveInsuranceAuthSuccess = (data) => {
	return {
		type: actionTypes.SAVE_INSURANCE_AUTH_SUCCESS,
		payload: data,
	};
};

export const saveInsuranceAuthError = (data) => {
	return {
		type: actionTypes.SAVE_INSURANCE_AUTH_ERROR,
		payload: data,
	};
};

// Get Insurance Info
export function getInsuranceInfo(patient_uuid, flag = false) {
	return (dispatch) => {
		if (flag) dispatch(resetInsuranceInfo());
		dispatch(getInsuranceInfoStart());
		PatientService.getInsuranceInfo(patient_uuid)
			.then((res) => {
				dispatch(getInsuranceInfoSuccess(res.data.data));
			})
			.catch((err) => {
				dispatch(getInsuranceInfoError(err));
			});
	};
}

export function resetInsuranceInfo() {
	return (dispatch) => {
		dispatch(getInsuranceInfoSuccess({}));
	};
}

const getInsuranceInfoStart = (data) => {
	return {
		type: actionTypes.GET_INSURANCE_INFO_START,
		payload: data,
	};
};

export const getInsuranceInfoSuccess = (data) => {
	return {
		type: actionTypes.GET_INSURANCE_INFO_SUCCESS,
		payload: data,
	};
};

export const getInsuranceInfoError = (data) => {
	return {
		type: actionTypes.GET_INSURANCE_INFO_ERROR,
		payload: data,
	};
};

// GET INSURANCE AUTH
export function getInsuranceAuths(data) {
	return (dispatch) => {
		dispatch(loadingGetInsuranceAuths());
		PatientService.getInsuranceAuths(data)
			.then((res) => {
				dispatch(getInsuranceAuthsSuccess(get(res, 'data.data', [])));
			})
			.catch((err) => {
				dispatch(getInsuranceAuthsError(err));
			});
	};
}

const loadingGetInsuranceAuths = () => {
	return {
		type: actionTypes.LOADING_GET_INSURANCE_AUTHS,
	};
};

export const getInsuranceAuthsSuccess = (data) => {
	return {
		type: actionTypes.GET_INSURANCE_AUTHS_SUCCESS,
		payload: data,
	};
};

const getInsuranceAuthsError = (err) => {
	return {
		type: actionTypes.GET_INSURANCE_AUTHS_ERROR,
		payload: err,
	};
};

export const updatePaymentMethod = (data, patient_uuid) => {
	return PatientService.updatePaymentMethod(data, patient_uuid);
};

export function resetInsuranceAuths() {
	return (dispatch) => {
		dispatch(getInsuranceAuthsSuccess([]));
	};
}

// Add Referring Provider
export function addReferringProvider(data) {
	return (dispatch) => {
		dispatch(addingReferralProvider());
		PatientService.addReferringProvider(data)
			.then((res) => {
				dispatch(addReferringProviderSuccess(res.data));
			})
			.catch((err) => {
				dispatch(
					addReferringProviderError(get(err, 'response.data', ''))
				);
			});
	};
}

export const addingReferralProvider = () => {
	return {
		type: actionTypes.ADDING_REFERRING_PROVIDER,
		payload: true,
	};
};

export const addReferringProviderSuccess = (data) => {
	return {
		type: actionTypes.ADD_REFERRING_PROVIDER_SUCCESS,
		payload: data,
	};
};

export const addReferringProviderError = (data) => {
	return {
		type: actionTypes.ADD_REFERRING_PROVIDER_ERROR,
		payload: data,
	};
};

// Add Referral Reason
export function addReferralReason(data) {
	return (dispatch) => {
		PatientService.addReferralReason(data)
			.then((res) => {
				dispatch(addReferralReasonSuccess(res.data));
			})
			.catch((err) => {
				dispatch(addReferralReasonError(get(err, 'response.data', '')));
			});
	};
}

export const addReferralReasonSuccess = (data) => {
	return {
		type: actionTypes.ADD_REFERRAL_REASON_SUCCESS,
		payload: data,
	};
};

export const addReferralReasonError = (data) => {
	return {
		type: actionTypes.ADD_REFERRAL_REASON_ERROR,
		payload: data,
	};
};

// Add Initial Contact Method
export function addInitialContactMethod(data) {
	return (dispatch) => {
		PatientService.addInitialContactMethod(data)
			.then((res) => {
				dispatch(addInitialContactMethodSuccess(res.data));
			})
			.catch((err) => {
				dispatch(
					addInitialContactMethodError(get(err, 'response.data', ''))
				);
			});
	};
}

export const addInitialContactMethodSuccess = (data) => {
	return {
		type: actionTypes.ADD_CONTACT_METHOD_SUCCESS,
		payload: data,
	};
};

export const addInitialContactMethodError = (data) => {
	return {
		type: actionTypes.ADD_CONTACT_METHOD_ERROR,
		payload: data,
	};
};

//Create Athena patient and Athena insurance (not with appointment completion)
export function createAthenaPatient(uuid) {
	return (dispatch) => {
		dispatch(creatingAthenaPatient());
		PatientService.createAthenaPatient(uuid)
			.then((res) => {
				dispatch(createdAthenaPatientSuccess(get(res, 'data', {})));
			})
			.catch((err) => {
				dispatch(
					createdAthenaPatientError(
						get(
							err,
							'response.data.message',
							'Error creating Athena patient'
						)
					)
				);
			});
	};
}

export const creatingAthenaPatient = (data) => {
	return {
		type: actionTypes.CREATING_ATHENA_PATIENT,
		payload: data,
	};
};

export const createdAthenaPatientSuccess = (data) => {
	return {
		type: actionTypes.CREATED_ATHENA_PATIENT_SUCCESS,
		payload: data,
	};
};

export const createdAthenaPatientError = (err) => {
	return {
		type: actionTypes.CREATED_ATHENA_PATIENT_ERROR,
		payload: err,
	};
};

//Link providers to patient
const providersLinkingStart = () => {
	return {
		type: actionTypes.PROVIDERS_PATIENT_LINKING_START,
	};
};
const providersLinkingSuccess = (payload) => {
	return {
		type: actionTypes.PROVIDERS_PATIENT_LINKING_SUCCESS,
		payload,
	};
};
const providersLinkingError = (payload) => {
	return {
		type: actionTypes.PROVIDERS_PATIENT_LINKING_ERROR,
		payload,
	};
};
export const linkProviders = (patient_uuid, data) => {
	return (dispatch) => {
		dispatch(providersLinkingStart());
		PatientService.linkProviders(patient_uuid, data)
			.then((res) => {
				dispatch(providersLinkingSuccess(get(res, 'data', [])));
			})
			.catch((err) => {
				dispatch(providersLinkingError(err));
			});
	};
};

//Get linked provider to patient
const getLinkedProvidersStart = () => {
	return {
		type: actionTypes.GET_LINKED_PATIENT_PROVIDERS_START,
	};
};
const getLinkedProvidersSuccess = (payload) => {
	return {
		type: actionTypes.GET_LINKED_PATIENT_PROVIDERS_SUCCESS,
		payload,
	};
};
const getLinkedProvidersError = (payload) => {
	return {
		type: actionTypes.GET_LINKED_PATIENT_PROVIDERS_ERROR,
		payload,
	};
};
export const getLinkedProviders = (patient_uuid) => {
	return (dispatch) => {
		dispatch(getLinkedProvidersStart());
		PatientService.getLinkedProviders(patient_uuid)
			.then((res) => {
				dispatch(
					getLinkedProvidersSuccess(
						hydrateProviders(get(res, ['data', 'care_team'], []))
					)
				);
			})
			.catch((err) => {
				dispatch(getLinkedProvidersError(err));
			});
	};
};

//Unlink provider from patient
const getUnlinkProviderStart = () => {
	return {
		type: actionTypes.GET_UNLINK_PATIENT_PROVIDER_START,
	};
};
const getUnlinkProviderSuccess = (payload) => {
	return {
		type: actionTypes.GET_UNLINK_PATIENT_PROVIDER_SUCCESS,
		payload,
	};
};
const getUnlinkProviderError = (payload) => {
	return {
		type: actionTypes.GET_UNLINK_PATIENT_PROVIDER_ERROR,
		payload,
	};
};
export const unlinkProvider = (patient_uuid, provider_uuid) => {
	return (dispatch) => {
		dispatch(getUnlinkProviderStart());
		PatientService.unlinkProvider(patient_uuid, provider_uuid)
			.then((res) => {
				dispatch(getUnlinkProviderSuccess(get(res, 'data', [])));
			})
			.catch((err) => {
				dispatch(getUnlinkProviderError(err));
			});
	};
};

//update linked provider to patient
const getUpdateLinkedProviderStart = () => {
	return {
		type: actionTypes.GET_UPDATE_LINKED_PATIENT_PROVIDER_START,
	};
};
const getUpdateLinkedProviderSuccess = (payload) => {
	return {
		type: actionTypes.GET_UPDATE_LINKED_PATIENT_PROVIDER_SUCCESS,
		payload,
	};
};
const getUpdateLinkedProviderError = (payload) => {
	return {
		type: actionTypes.GET_UPDATE_LINKED_PATIENT_PROVIDER_ERROR,
		payload,
	};
};

export const updateLinkedProvider = (patient_uuid, provider_uuid, data) => {
	return (dispatch) => {
		dispatch(getUpdateLinkedProviderStart());
		PatientService.updateLinkedProvider(patient_uuid, provider_uuid, data)
			.then((res) => {
				dispatch(getUpdateLinkedProviderSuccess(get(res, 'data', [])));
			})
			.catch((err) => {
				dispatch(getUpdateLinkedProviderError(err));
			});
	};
};

//Get patient tags
export const getPatientTags = () => {
	return (dispatch) => {
		dispatch(getPatientTagsStart());
		PatientService.getPatientTags()
			.then((res) => {
				dispatch(
					getPatientTagsSuccess(
						orderTags(get(res, 'data.data', null) || [])
					)
				);
			})
			.catch((err) => {
				dispatch(getPatientTagsError(err));
			});
	};
};

const getPatientTagsStart = () => {
	return {
		type: actionTypes.GET_PATIENT_TAGS_START,
		payload: true,
	};
};
const getPatientTagsSuccess = (payload) => {
	return {
		type: actionTypes.GET_PATIENT_TAGS_SUCCESS,
		payload,
	};
};
const getPatientTagsError = (err) => {
	return {
		type: actionTypes.GET_PATIENT_TAGS_ERROR,
		payload: err,
	};
};

//Gender
export function getGender() {
	return (dispatch) => {
		dispatch(getGenderStart());
		PatientService.getGender()
			.then((res) => {
				dispatch(getGenderSuccess(get(res, 'data.data', null)));
			})
			.catch((err) => {
				dispatch(getGenderError(err));
			});
	};
}

const getGenderStart = () => {
	return {
		type: actionTypes.GET_GENDER_START,
		payload: true,
	};
};

const getGenderSuccess = (payload) => {
	return {
		type: actionTypes.GET_GENDER_SUCCESS,
		payload,
	};
};

const getGenderError = (err) => {
	return {
		type: actionTypes.GET_GENDER_ERROR,
		payload: err,
	};
};

//Pronouns
export function getPronouns() {
	return (dispatch) => {
		dispatch(getPronounsStart());
		PatientService.getPronouns()
			.then((res) => {
				dispatch(getPronounsSuccess(get(res, 'data.data', null)));
			})
			.catch((err) => {
				dispatch(getPronounsError(err));
			});
	};
}

const getPronounsStart = () => {
	return {
		type: actionTypes.GET_PRONOUNS_START,
		payload: true,
	};
};

const getPronounsSuccess = (payload) => {
	return {
		type: actionTypes.GET_PRONOUNS_SUCCESS,
		payload,
	};
};

const getPronounsError = (err) => {
	return {
		type: actionTypes.GET_PRONOUNS_ERROR,
		payload: err,
	};
};

//Sex assigned at birth
export function getSexAssignedAtBirth() {
	return (dispatch) => {
		dispatch(getSexAssignedAtBirthStart());
		PatientService.getSexAssignedAtBirth()
			.then((res) => {
				dispatch(
					getSexAssignedAtBirthSuccess(get(res, 'data.data', null))
				);
			})
			.catch((err) => {
				dispatch(getSexAssignedAtBirthError(err));
			});
	};
}

const getSexAssignedAtBirthStart = () => {
	return {
		type: actionTypes.GET_SEX_ASSIGNED_AT_BIRTH_START,
		payload: true,
	};
};

const getSexAssignedAtBirthSuccess = (payload) => {
	return {
		type: actionTypes.GET_SEX_ASSIGNED_AT_BIRTH_SUCCESS,
		payload,
	};
};

const getSexAssignedAtBirthError = (err) => {
	return {
		type: actionTypes.GET_SEX_ASSIGNED_AT_BIRTH_ERROR,
		payload: err,
	};
};

//Race enum
export function getRaceEnum() {
	return (dispatch) => {
		dispatch(getRaceEnumStart());
		PatientService.getRaceEnum()
			.then((res) => {
				dispatch(
					getRaceEnumSuccess(
						orderRaces(get(res, 'data.data.race', null))
					)
				);
			})
			.catch((err) => {
				dispatch(getRaceEnumError(err));
			});
	};
}

const getRaceEnumStart = () => {
	return {
		type: actionTypes.GET_RACE_ENUM_START,
		payload: true,
	};
};

const getRaceEnumSuccess = (payload) => {
	return {
		type: actionTypes.GET_RACE_ENUM_SUCCESS,
		payload,
	};
};

const getRaceEnumError = (err) => {
	return {
		type: actionTypes.GET_RACE_ENUM_ERROR,
		payload: err,
	};
};

//Ethnicity enum
export function getEthnicityEnum() {
	return (dispatch) => {
		dispatch(getEthnicityEnumStart());
		PatientService.getEthnicityEnum()
			.then((res) => {
				dispatch(
					getEthnicityEnumSuccess(
						get(res, 'data.data.ethnicity', null)
					)
				);
			})
			.catch((err) => {
				dispatch(getEthnicityEnumError(err));
			});
	};
}

const getEthnicityEnumStart = () => {
	return {
		type: actionTypes.GET_ETHNICITY_ENUM_START,
		payload: true,
	};
};

const getEthnicityEnumSuccess = (payload) => {
	return {
		type: actionTypes.GET_ETHNICITY_ENUM_SUCCESS,
		payload,
	};
};

const getEthnicityEnumError = (err) => {
	return {
		type: actionTypes.GET_ETHNICITY_ENUM_ERROR,
		payload: err,
	};
};

export function updateConsentStatus(patientUUId, data) {
	return (dispatch) => {
		dispatch(updatingConsentStatus());
		return PatientService.updateConsentStatus(patientUUId, data)
			.then((res) => {
				dispatch(updatedConsentStatus(res));
				return res;
			})
			.catch((err) => {
				dispatch(
					updateConsentStatusError(get(err, 'response.data', {}))
				);
			});
	};
}

export const updatingConsentStatus = () => {
	return {
		type: actionTypes.UPDATING_CONSENT_STATUS,
		payload: true,
	};
};

export const updatedConsentStatus = (data) => {
	return {
		type: actionTypes.UPDATE_CONSENT_STATUS,
		payload: get(data, 'data', {}),
	};
};

export const updateConsentStatusError = (err) => {
	return {
		type: actionTypes.UPDATE_CONSENT_STATUS_ERROR,
		payload: err,
	};
};

// Send authorization to recontact outreach
export function sendAuthToRecontactOutreach(data) {
	return PatientService.sendAuthToRecontactOutreach(data);
}
