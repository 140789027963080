import classnames from 'classnames';
import React from 'react';

import { SelectComponent } from '../SelectComponent';
import FormField from './FormField';

import './DropdownOld.scss';

/**
 * General Dropdown component
 * @param {Object} props - component props
 * @param {string} props.className - additional classes for the field
 * @param {string} props.label - label for the form field
 * @param {string} props.name - name for the form field
 * @param {string} props.error - error
 * @param {string} props.tooltip - tooltip
 * @param {boolean} props.required - if true displays red asterisk next to label
 *
 * @param {any} props.value - value
 * @param {Array<{ value: string; label: string }>} props.options - options
 * @param {(value: string) => void} props.onChange - change handler
 * @param {boolean} props.clearable - sets the dropdown clearable
 * @param {boolean} props.disabled - sets the dropdown disabled
 * @param {boolean} props.typeahead - makes the dropdown filter options
 * @param {React.Component} [props.customOption] - custom React component for
 * rendering options
 * @param {(query: string) => void} [props.filterFunction] - custom logic to
 * execute when typing into input field (if `typeahead` is enabled)
 * @param {boolean} [props.loading] - if true, displays loading text
 *
 * @returns {JSX.Element}
 */
const Dropdown = function (props) {
	return (
		<FormField
			className={classnames('gm-dropdown-old', props.className)}
			name={props.name}
			label={props.label}
			required={props.required}
			error={props.error}
			tooltip={props.tooltip}
		>
			<SelectComponent
				clearable={props.clearable}
				customOption={props.customOption}
				disabled={props.disabled}
				filterFunction={props.filterFunction}
				multiple={props.multiple}
				name={props.name}
				onChange={props.onChange}
				options={props.options}
				placeholder={props.placeholder}
				typeahead={props.typeahead}
				value={props.value}
				loading={props.loading}
			/>
		</FormField>
	);
};

export default Dropdown;
