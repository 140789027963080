//React & Redux
import React from 'react';

//Components
import Tag from './Tag.js';

const TagSelected = (props) => {
	const { selectedICDCodes = [], toggleCodeSelection } = props;

	return (
		<div className='chargeICDSelectedContainer'>
			<div className='chargeICDSelectedContainerHeader'>SELECTED</div>
			{selectedICDCodes.map((el) => {
				return (
					<Tag
						icdCode={el}
						toggleCodeSelection={toggleCodeSelection}
						hasX={true}
						key={el?.uuid || el?.id || null}
					/>
				);
			})}
		</div>
	);
};

export default TagSelected;
