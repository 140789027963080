//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import AddEditExternalProviderFields from './AddEditExternalProviderFields.js';

const AddEditExternalProviderBody = (props) => {
	const {
		additionalFieldsJSON = [],
		errors = {},
		fieldChange,
		fieldsJSON = [],
		setPhoneFieldFocus = false,
		showAdditionalFields = false,
		showAdditionalFieldsText = false,
		toggleFieldSwitch,
	} = props;

	const arrowTilt = showAdditionalFields ? -90 : 90;

	return (
		<div className='externalProviderModalBody'>
			<AddEditExternalProviderFields
				fieldsJSON={fieldsJSON}
				fieldChange={fieldChange}
				errors={errors}
				setPhoneFieldFocus={setPhoneFieldFocus}
			/>
			<div
				className='externalProviderModalFieldsSwitch purpleText'
				onClick={toggleFieldSwitch}
			>
				{showAdditionalFieldsText ? 'Hide' : 'Show'} Additional Fields
				<i
					className='material-icons externalProviderModalArrow'
					style={{ transform: `rotate(${arrowTilt}deg)` }}
				>
					chevron_right
				</i>
			</div>
			{showAdditionalFields && (
				<AddEditExternalProviderFields
					fieldsJSON={additionalFieldsJSON}
					fieldChange={fieldChange}
					errors={errors}
					showAdditionalFieldsText={showAdditionalFieldsText}
					setPhoneFieldFocus={setPhoneFieldFocus}
				/>
			)}
		</div>
	);
};

export default AddEditExternalProviderBody;
