//React & Redux
import React, { useEffect } from 'react';

//Images
import ReactSVG from 'react-svg';
import searchIcon from './../../../../assets/ic_search.svg';

//Components
import CommonCheckbox from '../../../Common/Checkbox.js';
import RadioGroup from '../../../Common/controls/RadioGroup/RadioGroup';

const CreateNewDocumentV2Template = (props) => {
	const {
		createDocument,
		determineDropShadow,
		patientDetail: { referral_program = 'current referral program' },
		resetToggleAndSearch,
		searchAll,
		selectedTemplate = null,
		setBodyElement,
		setCurrentStage,
		setSearchAll,
		setSearchValue,
		setSelectedTemplate,
		shouldHaveBottomDropShadow,
		shouldHaveTopDropShadow,
		templates,
	} = props;

	const resultsContainer = React.useRef(null);

	useEffect(() => {
		resultsContainer.current && setBodyElement(resultsContainer.current);

		return () => resetToggleAndSearch();
	}, []);

	const handleSearchChange = (e) => {
		setSearchValue(e?.target?.value || '');
	};

	const retreatStage = () => {
		setCurrentStage('type');
	};

	const handleCreateDocument = () => {
		selectedTemplate && createDocument();
	};

	const getSearchInputPlaceholder = () =>
		searchAll
			? `Search within all referral programs`
			: `Search within ${referral_program}`;

	const templatesArr = templates
		.slice()
		.sort((a, b) => ((a?.file_name || '') < (b?.file_name || '') ? -1 : 1));

	const docTemplates = templatesArr.map((el) => ({
		key: el.uuid,
		display_name: el.file_name,
	}));

	const createClass = selectedTemplate
		? 'createDocumentV2ActionButton'
		: 'createDocumentV2ActionButton createDocumentV2ActionButtonDisabled';

	const containerClassTop = shouldHaveTopDropShadow
		? 'createDocumentV2TemplateToggleRow scrollableModalDropShadowTopInside'
		: 'createDocumentV2TemplateToggleRow';

	const containerClassBottom = shouldHaveBottomDropShadow
		? 'createDocumentV2TypeButtonRow scrollableModalDropShadowBottom'
		: 'createDocumentV2TypeButtonRow';

	return (
		<>
			<div className='createDocumentV2TemplateContainer'>
				<div className='createDocumentV2TemplateSearchContainer'>
					<input
						data-testId='template-search'
						className='createDocumentV2TemplateSearchInput'
						placeholder={getSearchInputPlaceholder()}
						onChange={handleSearchChange}
					/>
					<ReactSVG
						src={searchIcon}
						style={{
							position: 'relative',
							bottom: '1px',
							right: '5px',
						}}
					/>
				</div>
				<div className={containerClassTop}>
					<CommonCheckbox
						checked={searchAll}
						onChangeFunc={setSearchAll}
						marginRightOverride={5}
						marginLeftOverride={0}
						topOverride={0}
					/>
					<span style={{ position: 'relative', bottom: '1px' }}>
						Search all referral programs
					</span>
				</div>
				<div
					data-testId='scroll-container'
					className='createDocumentV2TemplateRowsContainer'
					ref={resultsContainer}
					onScroll={() => determineDropShadow(['top', 'bottom'])}
				>
					<RadioGroup
						options={docTemplates}
						value={selectedTemplate}
						onChange={setSelectedTemplate}
					/>
				</div>
			</div>
			<div
				className={containerClassBottom}
				style={{ justifyContent: 'space-between' }}
			>
				<div
					className='createDocumentV2BackButton'
					data-testId='retreat-btn'
					onClick={retreatStage}
				>
					Back
				</div>
				<div
					className={createClass}
					data-testId='create-doc-btn'
					onClick={handleCreateDocument}
				>
					Create Document
				</div>
			</div>
		</>
	);
};

export default CreateNewDocumentV2Template;
