//React & Redux
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

//Lodash
import { startCase, get } from 'lodash';

//Actions & Services
import { getupcomingappointments } from '../../actions/dashboard';
import { setFilter } from '../../actions/appData';

//UI Libraries
import { Table, Tbody, Tr, Td } from '../Common/Table';
import CommonCheckbox from './../Common/Checkbox';

//Other Libraries
import moment from 'moment';

//Hooks
import { usePrevious } from '../../hooks/usePrevious';

//Utils
import * as Utils from './UpcomingAppointments';

//Styles
import './style.css';
import { isGCRole } from '../../Utils/permissionUtils';

export const getTime = (time) => {
	const timezone = moment.tz.guess();
	if (moment(time).isValid()) {
		return (
			'on ' +
			moment(moment.utc(time))
				.local()
				.tz(timezone)
				.format('MMMM Do YYYY, h:mm a z')
		);
	} else {
		return '';
	}
};

export const UpcomingAppointments = (props) => {
	const {
		getDashboardAppointments,
		history,
		loadingUpcomingAppointments,
		upcomingAppointments,
		upcomingApptFilter = {},
		setUpcomingApptFilter,
		activerole,
	} = props;

	const roleIsGC = isGCRole(activerole);

	const [activePage, setActivePage] = useState(0);
	const [showSelfAppt, setShowSelfAppt] = useState(
		roleIsGC ? upcomingApptFilter?.showSelfAppt : false
	);

	const previousLoadingUpcomingAppointments = usePrevious(
		loadingUpcomingAppointments
	);

	useEffect(() => {
		getDashboardAppointments({ offset: 0, provider_only: showSelfAppt });
	}, []);

	useEffect(() => {
		previousLoadingUpcomingAppointments &&
			!loadingUpcomingAppointments &&
			setUpcomingApptFilter(getFilter());
	}, [loadingUpcomingAppointments]);

	const getFilter = () => ({
		showSelfAppt,
	});

	const handlePageChange = (evt, pageNumber) => {
		getDashboardAppointments({
			filter: history?.location?.search || '',
			offset: pageNumber,
			limit: 20,
			provider_only: showSelfAppt,
		});
		setActivePage(pageNumber);
	};

	const handleShowAppointment = (value) => {
		getDashboardAppointments({
			filter: history?.location?.search || '',
			offset: activePage,
			limit: 20,
			provider_only: value,
		});
		setShowSelfAppt(value);
	};
	const getPatientName = (uuid) => {
		const patientsList = upcomingAppointments?.patientslist?.data || [];
		const patient = patientsList.find((o) => o.uuid == uuid);
		return (patient?.first_name || '') + ' ' + (patient?.last_name || '');
	};

	const navigateToPatientDetail = (uuid) => {
		history.push(`/app/patientdetail/${uuid}/0/0/`);
	};

	const getPlatform = (el) => {
		if ((el?.tm_platform?.length || 0) > 0) {
			return ' (' + (el?.tm_platform || '').toLowerCase() + ') ';
		} else {
			return ' ';
		}
	};

	const pagination = {
		limit: 20,
		offset: activePage,
		total: upcomingAppointments?.total || 0,
	};

	const isUpcomingListempty =
		!loadingUpcomingAppointments &&
		get(upcomingAppointments, ['data', 'length'], 0) == 0;
	return (
		<div
			className='patients_dashboard upcoming-section'
			style={{ marginTop: '10px', minHeight: '160px' }}
		>
			<div>
				<div
					className='dashboard_breadcrumb'
					style={{ fontSize: '18px' }}
				>
					{' '}
					Upcoming Appointments{' '}
				</div>
				{roleIsGC && (
					<div className='dashboard_checkbox'>
						<CommonCheckbox
							checked={showSelfAppt}
							onChangeFunc={handleShowAppointment}
							noOnChangeEvtArg={true}
							additionalOnChangeArgs={[!showSelfAppt]}
							topOverride={2}
						/>
						<div
							onClick={(e) =>
								handleShowAppointment(!showSelfAppt)
							}
							className='multiSelectCheckboxLabel'
							style={{
								color: showSelfAppt ? '#7b26c9' : '#525968',
							}}
						>
							Show my appointments
						</div>
					</div>
				)}

				{isUpcomingListempty && (
					<div className='dashboard_nodata'>
						{roleIsGC && showSelfAppt ? (
							<div> You have no upcoming appointments.</div>
						) : (
							<div> There are no upcoming appointments.</div>
						)}
					</div>
				)}
			</div>
			<div>
				<Table
					loading={loadingUpcomingAppointments}
					pagination={pagination}
					limit={20}
					handlePageChange={handlePageChange}
					hasData={(upcomingAppointments?.data?.length || 0) > 0}
					emptyOnNoResults={true}
					className='dashboardAppointmentsTable upcominapttable'
					containerStyle={{
						minWidth: 'unset',
						maxWidth: 'unset',
						width: '100%',
						minHeight: '150px',
					}}
				>
					<Tbody>
						{(upcomingAppointments?.data || []).map(
							(el, idx) =>
								el?.user_uuid &&
								el?.user_uuid != null && (
									<Tr key={idx}>
										<Td>
											<div>
												<span
													style={{
														textTransform:
															'capitalize',
													}}
													className='patient_name_span'
													onClick={() =>
														navigateToPatientDetail(
															el?.user_uuid || ''
														)
													}
												>
													{getPatientName(
														el?.user_uuid || ''
													)}
												</span>
												{' has a ' +
													(
														el?.consultation_type ||
														''
													).toLowerCase() +
													getPlatform(el) +
													'visit with ' +
													startCase(
														(
															el?.visit_provider ||
															''
														).replace(/_/g, ' ')
													) +
													' ' +
													Utils.getTime(
														el?.date_of_service
													)}
											</div>
										</Td>
									</Tr>
								)
						)}
					</Tbody>
				</Table>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	loadingUpcomingAppointments:
		state.upcomingappointments.loadingUpcomingappointments,
	upcomingAppointments: state.upcomingappointments.upcomingappointments,
	upcomingApptFilter: get(state, 'appData.upcomingAppoitment', null) || {},
	activerole: _.get(state, ['me', 'activerole', 'name'], ''),
});

const mapDispatchToProps = (dispatch) => ({
	getDashboardAppointments: (filter) =>
		dispatch(getupcomingappointments(filter)),
	setUpcomingApptFilter: (data) =>
		dispatch(setFilter('upcomingAppoitment', data)),
});

UpcomingAppointments.propTypes = {
	auth: PropTypes.object,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UpcomingAppointments);
