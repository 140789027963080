//React & Redux
import React from 'react';
import classnames from 'classnames';

//lodash
import _ from 'lodash';

//Utils
import { disabledTestRecommendedSelection } from '../Common/utilsfunctions';

//Images
import plusIcon from '../../../../../assets/additional-flag.svg';

//Styles
import './RequestAnotherTestButton.css';

export const RequestAnotherTestButton = (props) => {
	return (
		<div
			className={classnames(
				'request-another-test-button-wrapper',
				disabledTestRecommendedSelection(
					_.get(props, ['visitStatus'], '')
				)
					? 'disable-request-another-test'
					: ''
			)}
		>
			<div
				className='request-another-test-button-container'
				onClick={props.onClick}
			>
				<div className='request-another-test-button'>
					<img
						src={plusIcon}
						alt=''
						className='request-another-img'
					/>
					<span className='request-another-text'>
						{props.text || 'Request Another'}
					</span>
				</div>
			</div>
		</div>
	);
};
