//React & Redux
import React, { Fragment } from 'react';
import DashboardSummaryCard from './DashboardSummaryCard';
import DashboardSummaryDetail from './DashboardSummaryDetail';

const DashboardSummary = (props) => {
	const {
		riseAnalyticsData,
		handlePagination,
		handleResultsPerPageChange,
		handleSummaryTblClick,
	} = props;
	return (
		<Fragment>
			<DashboardSummaryCard {...props} />
			<DashboardSummaryDetail
				riseAnalyticsData={riseAnalyticsData}
				handlePagination={handlePagination}
				handleResultsPerPageChange={handleResultsPerPageChange}
				handleSummaryTblClick={handleSummaryTblClick}
			/>
		</Fragment>
	);
};

export default DashboardSummary;
