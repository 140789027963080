//React & Redux
import React from 'react';

//Images
import visaicon from '../../assets/icon-Visa@2x.png';
import mastercardicon from '../../assets/icon-MasterCard@2x.png';
import americanicon from '../../assets/icon-AmericanExpress@2x.png';

//Lodash
import _ from 'lodash';

//Styles
import './css/payment-stage.css';
import './PreFilledCreditCard.scss';

const brandImageURL = (brand) => {
	let img = visaicon;
	switch (brand.toLowerCase()) {
		case 'visa':
			img = visaicon;
			break;
		case 'mastercard':
			img = mastercardicon;
			break;
		case 'american express':
			img = americanicon;
			break;
		default:
			img = visaicon;
	}
	return img;
};

const PreFilledCreditCard = ({ changePaymentMethod, cardInfo }) => {
	return (
		<div className='insurance-card-container'>
			<div className='insurance-carddetail-leftpart'>
				<div>
					<img src={brandImageURL(_.get(cardInfo, ['brand'], ''))} />
				</div>
				<div className='card-numdetails'>
					<div>
						<span className='card_name'>
							{_.get(cardInfo, ['brand'], '')}
						</span>{' '}
						ending in {_.get(cardInfo, ['last4'], '')}
					</div>
					<div>
						{_.get(cardInfo, ['exp_month'], '')}/
						{_.get(cardInfo, ['exp_year'], '')}
					</div>
				</div>
			</div>
			<div>
				<button
					type='button'
					className='card-display-btn'
					onClick={() => changePaymentMethod(false)}
				>
					Change
				</button>
			</div>
		</div>
	);
};
export default PreFilledCreditCard;
