import alan_rope from '../assets/providers/alan_rope.png';
import audrey_fan from '../assets/providers/audrey_fan.png';
import betsy_swope from '../assets/providers/betsy_swope.png';
import brian_kirmse from '../assets/providers/brian_kirmse.png';
import catherine_fine from '../assets/providers/catherine_fine.png';
import douglas_stewart from '../assets/providers/douglas_steward.png';
import douglas_steward from '../assets/providers/douglas_steward.png';
import elyse_weber from '../assets/providers/elyse-weber.png';
import erynn_gordon from '../assets/providers/erynn_gordon.png';
import hailey_shabazz from '../assets/providers/hailey_shabazz.png';
import jamie_dokson from '../assets/providers/jamie_dokson.png';
import kate_sargent from '../assets/providers/kate_sargent.png';
import kathryn_sargent from '../assets/providers/kathryn_sargent.png';
import lindsay_meyers from '../assets/providers/lindsay_meyers.png';
import maria_daddario from '../assets/providers/maria_daddario.png';
import mary_frances_garber from '../assets/providers/mary_frances_garber.png';
import sarah_kalia from '../assets/providers/sarah_kalia.png';
import scott_weissman from '../assets/providers/scott_weisman.png';
import steven_bleyl from '../assets/providers/steven_bleyl.png';
import tanya_bardakjian from '../assets/providers/tanya_bardakjian.png';

const SCHEDULING_STEPS_ITEMS = [
	{ idx: 0, label: 'Select Patient' },
	{ idx: 1, label: 'Schedule an Appointment' },
	{ idx: 2, label: 'Payment' },
];

export default SCHEDULING_STEPS_ITEMS;

export {
	biologicalSexVariants,
	dependentVariants,
	guardianRelationships,
	GUARDIAN_RELATIONSHIP_ENUM,
} from '@gm/common/const';

export const providers_images_for_appointment = {
	'Alan Rope': alan_rope,
	'Audrey Fan': audrey_fan,
	'Betsy Swope': betsy_swope,
	'Brian Kirmse': brian_kirmse,
	'Catherine Fine': catherine_fine,
	'Douglas Stewart': douglas_stewart,
	'Douglas Steward': douglas_steward,
	'Elyse Weber': elyse_weber,
	'Erynn Gordon': erynn_gordon,
	'Erynn Gordon-Fishman': erynn_gordon,
	'Hailey Shabazz': hailey_shabazz,
	'Jamie Dokson': jamie_dokson,
	'Kate Sargent': kate_sargent,
	'Kathryn Sargent': kathryn_sargent,
	'Lindsay Meyers': lindsay_meyers,
	"Maria D'Addario": maria_daddario,
	'Mary-Frances Garber': mary_frances_garber,
	'Sarah Kalia': sarah_kalia,
	'Scott Weissman': scott_weissman,
	'Steven Bleyl': steven_bleyl,
	'Tanya Bardakjian': tanya_bardakjian,
};

export const encounter_specialty_mapping = {
	cancer: 'Cancer',
	cardiology: 'Cardiology',
	healthy_screening: 'Healthy Screening',
	neurology: 'Neurology',
	other_adult_genetics: 'Other Adult Genetics',
	pediatrics: 'Pediatrics',
	pharmacogenomics: 'Pharmacogenomics',
	prenatal: 'Prenatal',
	reproductive: 'Reproductive',
};

export const ucFirstAllWords = (str, separator = ' ') => {
	const pieces = str.split(separator);
	for (let i = 0; i < pieces.length; i++) {
		const j = pieces[i].charAt(0).toUpperCase();
		pieces[i] = j + pieces[i].substr(1).toLowerCase();
	}
	return pieces.join(separator);
};

export const sleep = (milliseconds) => {
	return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const determineEmailStatus = (email = '') => {
	if (typeof email === 'string') {
		return (
			email.match(/noemail_.*@genomemedical\.com/i) ||
			email.match(/.*@fake\.com/i) ||
			email.match(/.*@noemail\.com/i)
		);
	}
};

export const SCHEDULING_PLATFORM = {
	PIONEER: 'Pioneer',
	VSEE: 'VSee',
};
