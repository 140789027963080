import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getReferralPrograms } from '../../../../actions/patients';

import MultiSelectCheckbox from '../../../Filter/MultiSelectCheckbox';

/**
 * @param {(v: string[]) => void} onChange
 * @param {string[]} value
 */
export function ReferralProgram({ onChange, value }) {
	const referralPrograms = useSelector(
		(store) => store.referralPrograms.referralPrograms.data ?? []
	);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getReferralPrograms());
	}, []);

	return (
		<MultiSelectCheckbox
			data={referralPrograms.map((item) => ({
				key: item.uuid,
				display_name: item.display_name,
			}))}
			selected={value.map(
				(name) =>
					referralPrograms.find((item) => item.display_name === name)
						?.uuid
			)}
			title='Program'
			submit={(v) =>
				onChange(
					v.map(
						(uuid) =>
							referralPrograms.find((i) => i.uuid === uuid)
								.display_name
					)
				)
			}
			majorPillTitle='Program'
			searchBar={true}
			previewTab={true}
			emptyInputFullOutputOverride={false}
		/>
	);
}
