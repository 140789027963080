//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../../../assets/exit-alert.svg';

//Lodash
import get from 'lodash/get';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import DuplicatePatientRecord from './DuplicatePatientRecord.js';
import Pagination from '../../../Common/Pagination';

class DuplicatePatientTooltip extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			currentTotal: 0,
		};
		//bindings
		this.renderDuplicateMatches = this.renderDuplicateMatches.bind(this);
	}
	//functions

	componentDidUpdate(preProps) {
		const recordsBody =
			get(this, 'recordsBody', null) || document.createElement('div');
		const currentHeight = get(recordsBody, 'clientHeight', null);
		if (
			!get(preProps, 'duplicatePatientCheckLoading', false) &&
			get(this.props, 'duplicatePatientCheckLoading', false)
		) {
			if (currentHeight) recordsBody.style.height = `${currentHeight}px`;
		}

		if (
			get(preProps, 'duplicatePatientCheckLoading', false) &&
			!get(this.props, 'duplicatePatientCheckLoading', false)
		) {
			if (currentHeight) recordsBody.style.height = 'unset';
		}

		if (!get(this.props, 'duplicatePatientData.total', null)) {
			if (get(preProps, 'duplicatePatientData.total', null)) {
				this.setState({
					currentTotal: get(
						preProps,
						'duplicatePatientData.total',
						0
					),
				});
			}
		} else {
			if (get(this, 'state.currentTotal', 0)) {
				this.setState({ currentTotal: 0 });
			}
		}
	}

	renderDuplicateMatches() {
		const duplicatePatientData =
			get(this, 'props.duplicatePatientData.data', []) || [];
		if (Array.isArray(duplicatePatientData)) {
			return duplicatePatientData
				.slice()
				.reverse()
				.map((el) => {
					return (
						<DuplicatePatientRecord
							data={el}
							key={el.uuid}
							formPatientFirstName={get(
								this,
								'props.formPatientFirstName',
								null
							)}
							formPatientLastName={get(
								this,
								'props.formPatientLastName',
								null
							)}
							formPatientDob={get(
								this,
								'props.formPatientDob',
								null
							)}
							formPatientEmail={get(
								this,
								'props.formPatientEmail',
								null
							)}
							formPatientNoEmail={get(
								this,
								'props.formPatientNoEmail',
								null
							)}
							formPatientUnbornSelection={get(
								this,
								'props.formPatientUnbornSelection',
								null
							)}
							formPatientGuardianLastName={get(
								this,
								'props.formPatientGuardianLastName',
								null
							)}
						/>
					);
				});
		}
	}

	render() {
		const duplicatePatientCheckLoading = get(
			this,
			'props.duplicatePatientCheckLoading',
			false
		);
		let total = get(this, 'props.duplicatePatientData.total', null);
		if (!total && total !== 0) total = get(this, 'state.currentTotal', 0);
		return (
			<div className='duplicatePatientTooltipContainer'>
				<div className='duplicatePatientTooltipTitleRow'>
					<div style={{ paddingLeft: '12px' }}>
						Potential Duplicates Found
					</div>
					<div
						className='chargeModalClose'
						style={{
							top: '-2px',
							right: '10px',
							transform: 'scale(1.1)',
						}}
					>
						<ReactSVG
							src={xIcon}
							style={{
								position: 'relative',
								bottom: '2px',
								right: '1.25px',
							}}
							onClick={this.props.closeDuplicatePatientTooltip}
						/>
					</div>
				</div>
				<Loading
					loading={
						duplicatePatientCheckLoading ||
						get(this, 'state.loading', false)
					}
					style={{ position: 'relative' }}
				>
					<div
						className='duplicatePatientTooltipBodyContainer'
						ref={(el) => (this.recordsBody = el)}
					>
						{this.renderDuplicateMatches()}
					</div>
					<Pagination
						className='patient_records_pagination patient_page_button mr-0 ml-28 patient-pagination'
						pageSize={3}
						total={total}
						offset={get(this, 'props.offset', 0)}
						layout='prev, pager, next'
						onCurrentChange={this.props.changeOffset}
					/>
				</Loading>
				<div className='duplicatePatientTooltipTail' />
			</div>
		);
	}
}

export default DuplicatePatientTooltip;
