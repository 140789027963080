//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import MenuItem from './MenuItem.js';
import RBAC from '../RBAC';

//Utils
import { shouldBeMasked } from './../../utils.js';
import {
	isExternalRole,
	isPhysicianRole,
	isSuperUserRole,
} from '../../Utils/permissionUtils';

const nonProductionMenu = {};

class MenuItemCollection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentItem: 0,
			right: '20px',
			opacity: 0,
			titledCollections: new Set(['Home', 'Pioneer', 'Updates']),
		};
		//bindings
		this.isTitledCollection = this.isTitledCollection.bind(this);
		this.getCustomStyling = this.getCustomStyling.bind(this);
	}
	//functions

	componentDidUpdate(prevProps, prevState) {
		if (
			!get(prevProps, 'expanded', false) &&
			get(this, 'props.expanded', false)
		) {
			this.setState({ right: '0px', opacity: 1 });
		}

		if (
			get(prevProps, 'expanded', false) &&
			!get(this, 'props.expanded', false)
		) {
			this.setState({ right: '20px', opacity: 0 });
		}
	}

	isTitledCollection() {
		const header = get(this, 'props.collection.header', '');
		return !get(this, 'state.titledCollections', new Set()).has(header);
	}

	getCustomStyling() {
		return get(this, 'props.collection.customStyling', null) || {};
	}

	userHasRisePermission = () => {
		const { role } = this.props;
		const isExternal = isExternalRole(role);
		const isPhysician = isPhysicianRole(role);
		const isSuperUser = isSuperUserRole(role);
		if (!isExternal && !isPhysician && !isSuperUser) {
			return true;
		}
		return false;
	};

	render() {
		const expandedClass = this.props.expanded
			? ' menuItemCollectionExpanded'
			: '';
		return (
			<div
				className={'menuItemCollection' + expandedClass}
				style={this.getCustomStyling()}
			>
				{this.isTitledCollection() && (
					<div
						className='menuItemCollectionHeader'
						style={{
							opacity: get(this, 'state.opacity', 0),
							right: get(this, 'state.right', '0px'),
						}}
					>
						{this.props.expanded
							? get(
									this,
									'props.collection.header',
									''
								).toUpperCase()
							: ''}
					</div>
				)}
				<div className='menuItemsContainer'>
					{this.props.collection.items.map((item, i) => {
						if (
							!shouldBeMasked() &&
							get(item, 'text', '') in nonProductionMenu
						)
							return;
						if (
							get(item, 'text', '') == 'RISE' &&
							!this.userHasRisePermission()
						)
							return;
						return (
							<RBAC
								key={item.text}
								action={item.rbacaction}
								yes={
									<MenuItem
										item={item}
										colIdx={get(this, 'props.colIdx')}
										subIdx={i}
										active={
											get(
												this,
												'props.activeItemIdx',
												''
											) === item.idx
												? true
												: false
										}
										activeItemIdx={get(
											this,
											'props.activeItemIdx',
											''
										)}
										key={item.text}
										expanded={get(
											this,
											'props.expanded',
											''
										)}
										renderIcon={get(
											this,
											'props.renderIcon',
											''
										)}
										navRedirect={get(
											this,
											'props.navRedirect',
											''
										)}
									/>
								}
							/>
						);
					})}
				</div>
			</div>
		);
	}
}

export default MenuItemCollection;
