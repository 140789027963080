//React & Redux
import React, { Component } from 'react';

//Lodash
import _ from 'lodash';

//Components
import PreAuthTestRequestLabInfo from './PreAuthTestRequestLabInfo';
import CreateTestRequestEncntrInfo from './CreateTestRequestEncntrInfo';

import './create-test-request-modal.css';

class PreAuthTestRequestModal extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {}

	render() {
		return (
			<div className='crte-test-rqst-dialog-body edit-test-rqst-dialog-v2'>
				<div className='crte-test-rqst-fields-container'>
					<PreAuthTestRequestLabInfo
						testRequestMode={_.get(
							this,
							['props', 'testRequestMode'],
							''
						)}
						labenums={_.get(this, ['props', 'labenums'], [])}
						nonlegacytestOrderPopupTestEnums={_.get(
							this,
							['props', 'nonlegacytestOrderPopupTestEnums'],
							[]
						)}
						nonlegacyalternatetestnameenums={_.get(
							this,
							['props', 'nonlegacyalternatetestnameenums'],
							[]
						)}
						formdata={_.get(this, ['props', 'formdata'])}
						testorder={_.get(this, ['props', 'testorder'], {})}
						onFormChange={this.props.onFormChange}
						handleDateFieldValidate={
							this.props.handleDateFieldValidate
						}
						preauthdecisionenums={_.get(
							this,
							['props', 'preauthdecisionenums'],
							[]
						)}
						preauthdispatchstatusenums={_.get(
							this,
							['props', 'preauthdispatchstatusenums'],
							[]
						)}
						userSelectedICDCodes={_.get(
							this,
							['props', 'userSelectedICDCodes'],
							null
						)}
					/>
					<CreateTestRequestEncntrInfo
						encounter={_.get(this, ['props', 'encounter'], {})}
						patientdetail={_.get(
							this,
							['props', 'patientdetail'],
							{}
						)}
					/>
				</div>
			</div>
		);
	}
}

export default PreAuthTestRequestModal;
