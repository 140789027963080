//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import warningImg from '../../../../assets/milestone-warning.svg';

//Lodash
import _ from 'lodash';

//Components
import './TestOrderMilestoneWarning.css';

const isVisible = (props) => {
	const stateOrderStatus = _.get(props, 'stateOrderStatus', '');
	const propsOrderStatus = _.get(props, 'propsOrderStatus', '');
	const positiveResultsToReport = _.get(
		props,
		'positiveResultsToReport',
		null
	);
	const warningStates = [
		'requested',
		'patient_declined',
		'approved',
		'rejected',
		'no_test_available',
		'rejected_other',
		'cancelled',
		'order_cancelled_by_provider',
		'order_cancelled_by_patient',
		'order_cancelled_by_lab',
		'sent_to_lab',
		'declined_other',
		'ins_denied_cost',
		'iss_w_test_implications',
		'no_fu_from_patient',
	];
	return (
		((stateOrderStatus === 'results_ready' &&
			propsOrderStatus === 'results_ready') ||
			stateOrderStatus != propsOrderStatus) &&
		(warningStates.includes(stateOrderStatus) ||
			(!_.isNil(positiveResultsToReport) &&
				positiveResultsToReport == true))
	);
};

const selectedValue = (props) => {
	const stateOrderStatus = _.get(props, 'stateOrderStatus', '');
	const mloOrderStatusEnums = _.get(props, ['mloOrderStatusEnums'], []);

	const obj = _.find(mloOrderStatusEnums, (one) => {
		if (one) {
			if (one.key == stateOrderStatus) {
				return one;
			} else if (_.get(one, 'children')) {
				return _.find(
					one.children,
					(two) => two && two.key == stateOrderStatus
				);
			}
		} else {
			return {};
		}
	});
	if (_.get(obj, 'children')) {
		const enums = _.find(
			obj.children,
			(o) => o.key && o.key == stateOrderStatus
		);
		return _.get(enums, 'display_name', '');
	} else {
		return _.get(obj, 'display_name', '');
	}
};

const TestOrderMilestoneWarning = (props) => {
	const _positiveResultsToReport = _.get(
		props,
		'positiveResultsToReport',
		null
	);
	const _milestoneWarningText =
		!_.isNil(_positiveResultsToReport) && _positiveResultsToReport == true
			? `GC will be notified to enter gene info. Changing status to “${selectedValue(
					props
			  )}” is an irreversible action once saved.`
			: `Changing status to “${selectedValue(
					props
			  )}” is an irreversible action once saved.`;

	return (
		isVisible(props) && (
			<div className='milestone-warning-container'>
				<div className='milestone-warning-body'>
					<div className='milestone-warning-icon'>
						<ReactSVG src={warningImg} />
					</div>
					<div className='milestone-warning-text'>
						{_milestoneWarningText}
					</div>
				</div>
			</div>
		)
	);
};

export default TestOrderMilestoneWarning;
