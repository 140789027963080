//React & Redux
import React, { useState, useEffect } from 'react';

//Lodash
import _ from 'lodash';

//Other Libraries
import moment from 'moment';
import classnames from 'classnames';

//Utils
import {
	getTestOrderScruber,
	GetSelectvalue,
} from '../Encounters/Common/utilsfunctions';
import { outreach_pill_bg_color } from '../../../../utils.js';
import {
	CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER,
	CARE_PATIENTS_ENTER_TEST_RESULT,
} from '../../../../Utils/permissionUtils';

//UI Libraries
import { Button } from 'gm-element-react';

//Components
import RBAC from '../../../RBAC';
import Pill from '../../../Common/Pill';
import TextWithTooltip from '../../../Common/TextWithTooltip';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious';

//Styles
import './style.css';

const TestOrderProgressBar = (props) => {
	const {
		order,
		encounter,
		outreachStatusEnums = [],
		mloOrderStatusEnums = [],
		showModalFunc,
		callTestOrderRequest,
		gotoMLOTestDetail,
	} = props;

	const [outreachTimeToStart, setOutreachTimeToStart] = useState(null);
	const [apiCount, setApiCount] = useState(0);
	let outreachTimeTimer = null;

	useEffect(() => {
		outreachTimeInterval();

		return () => {
			clearInterval(outreachTimeTimer);
		};
	}, []);

	const prevOrder = usePrevious(order);

	useEffect(() => {
		if (!_.isNil(prevOrder) && prevOrder != order) {
			const testOutreachStatus = order?.testOutreachStatus || '';
			if (apiCount > 0 && apiCount < 4) {
				if (
					moment(order?.time_to_start ?? null).isValid() &&
					testOutreachStatus == 'waiting_to_start'
				) {
					getOutreachTime();
				} else {
					setApiCount(-1);
				}
			}
		}
	}, [order]);

	const getOutreachTime = () => {
		let outreachToStartMin = 0;
		let outreachToStartSec = 0;
		const testOutreachStatus = order?.test_outreach_status ?? '';
		if (
			moment(order?.time_to_start ?? null).isValid() &&
			testOutreachStatus == 'waiting_to_start'
		) {
			const outreachTimeToStart = moment.utc(order?.time_to_start ?? null);
			const currentutc = moment.utc();

			outreachToStartSec = outreachTimeToStart.diff(
				currentutc,
				'seconds'
			);

			outreachToStartMin = Math.ceil(outreachToStartSec / 60);

			if (currentutc.isSameOrAfter(outreachTimeToStart)) {
				clearInterval(outreachTimeTimer);
				setTimeout(() => {
					setApiCount(apiCount + 1);
					callTestOrderRequest(false);
				}, 20 * 1000);
				setOutreachTimeToStart(1);
			} else {
				setOutreachTimeToStart(outreachToStartMin);
			}
		} else {
			clearInterval(outreachTimeTimer);
		}
	};

	const outreachTimeInterval = () => {
		getOutreachTime();
		outreachTimeTimer = setInterval(() => {
			getOutreachTime();
		}, 15 * 1000);
	};

	const allowEnterResult = (orderStatus) => {
		const allow = {
			results_ready: 1,
		};
		return orderStatus in allow;
	};

	const allowGotoTestLab = (orderStatus) => {
		const allow = {
			results_entered: 1,
		};
		return orderStatus in allow;
	};

	const goToTestDetail = (testOrderUuid) => {
		gotoMLOTestDetail(testOrderUuid);
	};

	const displayEditViewResultLink = (testOrderUuid, orderStatus) => {
		return (
			allowGotoTestLab(orderStatus) && (
				<RBAC
					action={CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER}
					yes={
						<Button
							className='button-text'
							onClick={() => goToTestDetail(testOrderUuid)}
							type='text'
						>
							Edit Results
						</Button>
					}
					no={
						<Button
							className='button-text'
							onClick={() => goToTestDetail(testOrderUuid)}
							type='text'
						>
							View Results
						</Button>
					}
				/>
			)
		);
	};

	const displayEnterResultLink = (testOrderUuid, orderStatus) => {
		return (
			<RBAC
				action={CARE_PATIENTS_ENTER_TEST_RESULT}
				yes={
					allowEnterResult(orderStatus) && (
						<Button
							className='button-text'
							onClick={() => goToTestDetail(testOrderUuid)}
							type='text'
						>
							Enter Results
						</Button>
					)
				}
			/>
		);
	};

	const getShortText = (text, length) => {
		if (text && !_.isEmpty(text) && text.length > length) {
			return (
				<TextWithTooltip multiLine={true} maxWidth='190px'>
					{text}
				</TextWithTooltip>
			);
		} else {
			return text;
		}
	};

	const isShowPill = (testOutreachStatus = '') => {
		const statusEnum = {
			results_entered: 1,
			result_positive_no_genes: 1,
			result_positive_with_genes: 1,
			result_negative: 1,
		};

		let localorderstatus = order?.order_status ?? null;
		if (
			!_.isNil(order?.status_reason ?? null) &&
			!_.isEmpty(order?.status_reason ?? null)
		) {
			localorderstatus = order?.status_reason ?? null;
		}

		const propsorderstatus = localorderstatus;

		if (propsorderstatus in statusEnum) {
			return !(
				testOutreachStatus == 'not_started' &&
				(encounter?.hold_result_release ?? null) != true
			);
		}
		return false;
	};

	const getPillText = (testOutreachStatus = '') => {
		const outreachDisplayName = GetSelectvalue(
			props?.order,
			'test_outreach_status',
			outreachStatusEnums
		);
		const holdResultRelease = encounter?.hold_result_release ?? null;
		let pillText = '';
		switch (testOutreachStatus) {
			case 'not_started':
				if (holdResultRelease == true) {
					pillText = 'Outreach On Hold';
				}
				break;
			case 'waiting_to_start':
				pillText = `Outreach Starts in ${outreachTimeToStart}m`;
				break;
			case 'in_progress':
				pillText = `Outreach ${outreachDisplayName}`;
				break;
			default:
				pillText = `${outreachDisplayName}`;
		}

		return pillText;
	};

	const getProgressbar = (progressbarArray) => {
		return progressbarArray.map((row) => {
			return (
				<li className={row.className} key={row.displayName}>
					<span className='circle' />
					<div
						className={classnames(
							'step-name',
							_.includes(row.displayName, 'Actionable')
								? 'result-actionable'
								: ''
						)}
					>
						{getShortText(
							row.displayName,
							!_.includes(row.displayName, 'Actionable') ? 26 : 38
						)}
					</div>
					<div className='step-date'>{row.displayDate}</div>
				</li>
			);
		});
	};

	const showEditTestOrderPopup = () => {
		showModalFunc(true);
	};

	const getProgressbarArray = getTestOrderScruber(order, mloOrderStatusEnums);
	const orderStatus = order?.order_status ?? '';
	const testOrderUuid = order?.uuid ?? '';
	const testOutreachStatus = order?.test_outreach_status ?? '';
	const orderType = order?.order_type ?? '';

	return (
		<div className='progressbar-wrapper'>
			<div className='progressbar-container'>
				<span className='val-test-name'>
					{
						<RBAC
							action={CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER}
							yes={
								<Button
									className='testorder-label'
									onClick={() => showEditTestOrderPopup()}
									type='text'
								>
									<TextWithTooltip maxWidth='500px'>
										{_.get(
											order,
											['genetic_test_display_name'],
											'--'
										)}
									</TextWithTooltip>
								</Button>
							}
							no={
								<Button
									className='testorder-label disable-btn-extrnl-user'
									type='text'
								>
									<TextWithTooltip maxWidth='500px'>
										{_.get(
											order,
											['genetic_test_display_name'],
											'--'
										)}
									</TextWithTooltip>
								</Button>
							}
						/>
					}
				</span>
				{orderType === 'reflex' && (
					<span className='reflex-order-pill'>
						<Pill
							pillText='Reflex Order'
							pillExtendStyle='gray-pill'
						/>
					</span>
				)}
				<ul className='progressbar' key={order}>
					{getProgressbar(getProgressbarArray)}
				</ul>
				{allowGotoTestLab(orderStatus) &&
					displayEditViewResultLink(testOrderUuid, orderStatus)}
				{allowEnterResult(orderStatus) &&
					displayEnterResultLink(testOrderUuid, orderStatus)}
			</div>
			{isShowPill(testOutreachStatus) && (
				<div className='outreach-pill'>
					<Pill
						pillText={getPillText(testOutreachStatus)}
						pillExtendStyle={
							outreach_pill_bg_color[testOutreachStatus]
						}
					/>
				</div>
			)}
		</div>
	);
}
export default TestOrderProgressBar;
