//React & Redux
import React, { useState } from 'react';

//Lodash
import { isEmpty, isNil } from 'lodash';

//UI Libraries
import { Dialog, Button } from 'gm-element-react';

//Components
import Icon from '../../../Common/Icon';
import RadioGroup from '../../../Common/controls/RadioGroup/RadioGroup';

//Styles
import './css/add-new-finding-select-dialog.css';

const AddNewFindingSelectionDialog = (props) => {
	const {
		hideAddNewFindingSelectDialogFunc,
		navigateToSelectedFindingFunc,
		isCFDNAPrenatalScreeningDisabled,
	} = props;

	const [selectedFinding, setSelectedFinding] = useState('');

	//risk_assessment will be added to enum after risk assessment architecture is built
	const findingEnum = [
		{
			key: 'discrete_variant',
			display_name: 'Discrete Variant',
			disabled: false,
		},
		{
			key: 'structural_variant',
			display_name: 'Structural Variant',
			disabled: false,
		},
		{
			key: 'nucleotide_repeat_variant',
			display_name: 'Nucleotide Repeat Variant',
			disabled: false,
		},
		{
			key: 'cfDNA',
			display_name: 'cfDNA Cancer-Associated Methylation',
			disabled: false,
		},
		{
			key: 'cfDNA_prenatal_screening',
			display_name: 'cfDNA Prenatal Screening',
			disabled: isCFDNAPrenatalScreeningDisabled,
		},
		{ key: 'other', display_name: 'Other', disabled: false },
		// {key: 'risk_assessment', display_name: 'Risk Assessment'},
	];

	const setSelectedFindingFunc = (finding) => {
		setSelectedFinding(finding);
	};

	const isNextButtonDisabled = () => {
		return isEmpty(selectedFinding) || isNil(selectedFinding);
	};

	const handleNextFunc = () => {
		navigateToSelectedFindingFunc(selectedFinding);
	};

	return (
		<Dialog
			customClass='add-finding-select-dialog'
			title={
				<span className='header'>
					<span>Add New Finding</span>
					<span
						className='close-btn-icon'
						onClick={hideAddNewFindingSelectDialogFunc}
					>
						<Icon icon='close' className='close-icon' />
					</span>
				</span>
			}
			size='tiny'
			modal={true}
			closeOnPressEscape={true}
			closeOnClickModal={false}
			showClose={false}
			visible={true}
			onCancel={hideAddNewFindingSelectDialogFunc}
		>
			<Dialog.Body>
				<div className='add-finding-select-dialog-body'>
					<RadioGroup
						options={findingEnum}
						value={''}
						onChange={setSelectedFindingFunc}
					/>
				</div>
			</Dialog.Body>
			<Dialog.Footer className='dialog-footer'>
				<Button
					onClick={hideAddNewFindingSelectDialogFunc}
					className='cancel'
				>
					Cancel
				</Button>
				<Button
					className='next'
					onClick={handleNextFunc}
					disabled={isNextButtonDisabled()}
				>
					Next
				</Button>
			</Dialog.Footer>
		</Dialog>
	);
};

export default AddNewFindingSelectionDialog;
