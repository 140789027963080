//React & Redux
import PropTypes from 'prop-types';
import React, { Component } from 'react';

//Lodash
import _ from 'lodash';

//Styles
import './EmptySlot.css';

const STEP = 30;
class EmptySlot extends Component {
	static propTypes = {
		event: PropTypes.object.isRequired,
	};

	state = {
		slotStart: null,
		top: 0,
		height: 0,
		startDate: null,
		endDate: null,
		hover:false
	};

	get isValid() {
		const { event } = this.props;
		if (!event || !event.end || !event.start) return false;
		const length = (event.end - event.start) / 60000;
		if (length < event.duration) return false;
		return true;
	}

	onMouseMove = (e) => {
		const ev = this.props.event;
		const fullDuration = (ev.end - ev.start) / 60000;
		const rect = this.element.getBoundingClientRect();
		if (
			e.clientX < rect.left ||
			e.clientX > rect.right ||
			e.clientY < rect.top ||
			e.clientY > rect.bottom
		) {
			this.clearHover();
			return;
		}
		const y = e.clientY - rect.top;
		//let count = Math.floor(fullDuration / STEP) - 1//ev.duration /STEP;
		const count = Math.floor(fullDuration / STEP) - ev.duration / STEP; //ev.duration /STEP;
		const slotStep = rect.height / (fullDuration / STEP);
		const delta = ev.duration / STEP / 2 - 0.35;
		let slotStart = Math.round((y - delta * slotStep) / slotStep);
		if (slotStart < 0) slotStart = 0;
		else if (slotStart >= count) slotStart = count;

		const startDate = new Date(+ev.start + slotStart * STEP * 60000);
		const endDate = new Date(+startDate + ev.duration * 60000);

		const newTop = slotStart * slotStep;
		const newHeight = (slotStep * ev.duration) / STEP;

		if (this.state.slotStart !== slotStart) {
			// hack to prevent text flickering
			this.clearTimeLabel();
			/* eslint-disable */
			// this is intentional state change
			this.state.slotStart = slotStart; // prevent setting state twice
			/* eslint-enable */
			this.setState({
				top: newTop,
				height: newHeight,
				startDate,
				endDate,
				slotStart,
				hover:true
			});
			this.props.handleHover(true);
		}
	};

	clearTimeLabel = () => {
		// we intentionally break react rules because this node will be updated on the next render
		if (this.slotTime) {
			this.slotTime.innerHTML = '';
		}
	};

	clearHover = () => {
		if (this.state.slotStart != null) {
			this.setState({
				slotStart: null,
				top: 0,
				height: 0,
				hover:false
			});
			this.props.handleHover(false);
		}
	};

	onMouseLeave = () => {
		this.clearHover();
	};

	onSlotClick = () => {
		const {
			event: { sid, vsee_specialty, state, timezone, consultation_id },
		} = this.props;
		const { startDate, endDate } = this.state;
		this.props.event.onSlotClick(
			sid,
			startDate,
			endDate,
			vsee_specialty,
			state,
			timezone,
			consultation_id
		);
		this.props.handleHover(false);
	};

	getTimeString(dt) {
		const hours = dt.getHours();
		const hoursAmPm = hours > 12 ? hours - 12 : hours;
		const mins = dt.getMinutes();
		return `${hoursAmPm}:${mins < 10 ? '0' + mins : mins} ${
			hours >= 12 ? 'PM' : 'AM'
		}`;
	}

	getStartTimeString = () => {
		return this.getTimeString(this.state.startDate);
	};

	// getEndTimeString = () => {
	//   return this.getTimeString(this.state.endDate);
	// }

	renderSteps = () => {
		// this function renders lines above the event, because the event overlaps grid and we must recover table grid lines
		const {
			event: { start, end },
		} = this.props;
		const stepsCount = (end - start) / 60000 / 30;
		const startMinutes = start.getMinutes();
		let styles = ['solid', 'dashed'];
		if (startMinutes > 55 || startMinutes < 5) {
			styles = ['dashed', 'solid'];
		}
		const stepWidth = 100 / stepsCount;
		return _.range(stepsCount).map((i) => (
			<div
				key={i}
				className={'line ' + styles[i % 2]}
				style={{ top: stepWidth * i + '%', height: stepWidth + '%' }}
			/>
		));
	};

	render() {
		if (!this.isValid) return null;
		const { slotStart, top, height,hover } = this.state;
		return (
			<div
				ref={(e) => (this.element = e)}
				className='full-empty-slot'
				onClick={this.onSlotClick}
				onMouseMove={this.onMouseMove}
				onMouseLeave={this.onMouseLeave}
			>
				{this.renderSteps()}
				{slotStart != null && (
					<div className='slot' style={{ top, height }}>
						<div ref={(o) => (this.slotTime = o)} className='time'>
							{this.getStartTimeString()}
						</div>
						<div className='left-border' />
					</div>
				)}
			</div>
		);
	}
}

export default EmptySlot;
