//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';
import moment from 'moment';
import { find, get, isEmpty, isNil, orderBy } from 'lodash';

//Utils
import {
	getStartOfWeek,
	CreateEmptySlotEvents,
} from '../../Utils/CalendarUtils';
import { GetStateTimezone } from '../../utils.js';

//Components
import CalendarSection from './CalendarSection';
import ScheduleFilters from './ScheduleFilters';
import AppointentAlert from './AppointmentAlert';
import AppointmentErrorDialog from './AppointmentErrorDialog';

//Styles
import './ScheduleSection.css';

class ScheduleStage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			date: null,
			timezone: '',
			consultation_id: undefined,
			state: '',
			provider_id: undefined,
			vsee_specialty: '',
			modality: props.Scheduling?.partnerDetail?.is_phone_only
				? 'phone'
				: 'video',
			showappointmentalert: false,
			showErrorDialog: false,
			error: '',
			errorType: '',
			slotsError: [],
		};
		//bindings
		this.switchDate = this.switchDate.bind(this);
		this.switchDateBack = this.switchDateBack.bind(this);
		this.switchDateForward = this.switchDateForward.bind(this);
		this.switchToToday = this.switchToToday.bind(this);
		this.updateFilter = this.updateFilter.bind(this);
		this.onChange = this.onChange.bind(this);
		this.errorCloseHandler = this.errorCloseHandler.bind(this);
	}
	//functions

	componentDidMount() {
		const {
			getPatientDetail,
			newAppointment,
			PartnerDetail,
			ROREncounter,
			Scheduling,
			setModality,
			specialties,
			defaultSchedulingOptions,
		} = this.props;

		const filtered_specialties = _.filter(specialties, (spec) => {
			const kay = _.get(spec, 'key', '');
			return (
				kay != '' &&
				kay.indexOf('_willing') == -1 &&
				kay != 'no_default'
			);
		});
		const { calendar_date, timezone, uuid, patientInfo } = newAppointment;

		let { vsee_specialty, state, consultation_id, provider_id, modality } =
			newAppointment;

		const defaultConsultaionId = patientInfo?.default_consultation_id;
		const defaultSpecialtyId = patientInfo?.default_specialty_id;
		const providers = get(Scheduling, 'providers', []);
		if (uuid && !isEmpty(uuid) && !isNil(uuid)) {
			getPatientDetail(uuid);
		}
		const isVisitROREncounter =
			ROREncounter && ROREncounter.type == 'visit';

		const date = isNil(calendar_date)
			? getStartOfWeek(new Date())
			: calendar_date;

		if (isEmpty(vsee_specialty)) {
			vsee_specialty =
				PartnerDetail &&
				!isNil(PartnerDetail?.vsee_specialty) &&
				(PartnerDetail?.vsee_specialty || 'no_default') != 'no_default'
					? PartnerDetail?.vsee_specialty || ''
					: '';
			if (isVisitROREncounter) {
				vsee_specialty = ROREncounter?.vsee_specialty;
			}
			if (defaultSpecialtyId) {
				const default_partner_specialties =
					defaultSchedulingOptions.payload.specialties;
				const default_specialty_name =
					default_partner_specialties?.find(
						(item) => item.id === defaultSpecialtyId
					).name || '';
				const default_specialty_obj =
					filtered_specialties.find(
						(item) =>
							item.key === default_specialty_name.toLowerCase()
					) || {};
				const default_specialty = default_specialty_obj['key'];
				vsee_specialty = default_specialty || '';
			}
		}

		vsee_specialty = vsee_specialty
			.replace('_Willing', '')
			.replace('_willing', '');

		if (!consultation_id) {
			const schedulingConsultations =
				Scheduling?.schedulingConsultations || [];
			const sorted = orderBy(schedulingConsultations, ['name'], ['asc']);
			if (sorted.length > 0) {
				consultation_id = get(sorted, ['0', 'id']);
			}
			if (ROREncounter) {
				const consultation = find(sorted || [], (cons) => {
					return cons.type == 'follow_up';
				});
				if (consultation) {
					consultation_id = consultation?.id;
				}
			}
			if (defaultConsultaionId) {
				consultation_id = defaultConsultaionId;
			}
		}
		if (isEmpty(modality)) {
			modality = this.state.modality;
			setModality(modality);
		}
		if (isEmpty(provider_id)) {
			if (isVisitROREncounter) {
				const provider = find(providers || [], (prov) => {
					return prov.uuid == ROREncounter.provider_uuid;
				});
				if (provider) {
					provider_id = provider?.id;
				}
			}
		}
		state = newAppointment?.state || '';

		this.setState(
			{
				vsee_specialty,
				state,
				timezone,
				date,
				consultation_id,
				provider_id,
				modality,
			},
			() => {
				this.onChange();
			}
		);
	}

	componentDidUpdate(prevProps) {
		const { slotsError } = this.props.Scheduling;
		if (
			slotsError?.message.length > 0 &&
			this.state.showErrorDialog === false &&
			slotsError != _.get(prevProps, ['Scheduling', 'slotsError'])
		) {
			this.setState({
				error: _.get(slotsError, 'message'),
				showErrorDialog: true,
				errorType: 'pioneer_configuration_error',
			});
		}
	}

	switchDate(isForward) {
		if (this.isShaded) return;

		const newDate = new Date(this.state.date);
		if (isForward) {
			newDate.setDate(newDate.getDate() + 7);
			this.updateFilter('date', newDate);
		} else {
			const sameweek = moment(newDate).isSame(
				moment(getStartOfWeek(new Date()))
			);
			if (!sameweek) {
				newDate.setDate(newDate.getDate() - 7);
				this.updateFilter('date', newDate);
			} else {
				console.log('back not possible');
			}
		}
	}

	updateFilter(key, value) {
		const state = { ...this.state };
		state[key] = value;
		if (key == 'state') state['timezone'] = GetStateTimezone(value);
		this.setState(state, () => {
			if (key != 'modality') {
				this.props.clearSelectedSlot();
				this.props.filterChangeEvent();
				this.onChange();
			} else {
				this.props.setModality(value);
			}
		});
	}

	switchToToday() {
		this.updateFilter('date', getStartOfWeek(new Date()));
	}

	get isAllFilterSelected() {
		const selectedFilters = { ...this.state };
		return (
			get(selectedFilters, 'vsee_specialty', '') != '' &&
			get(selectedFilters, 'consultation_id', '') != '' &&
			get(selectedFilters, 'state', '') != '' &&
			get(selectedFilters, 'timezone', '') != ''
		);
	}
	get isShaded() {
		const { slotsLoading } = this.props.Scheduling;
		return !(this.isAllFilterSelected && slotsLoading === false);
	}

	switchDateBack = () => {
		this.switchDate(false);
	};

	switchDateForward = () => {
		this.switchDate(true);
	};

	onChange() {
		if (
			!isNil(this.props.newAppointment.id) &&
			!isEmpty(this.state.vsee_specialty) &&
			this.isAllFilterSelected
		) {
			this.props.LoadAppointmentSlot({
				start_time: this.state.date,
				timezone: this.state.timezone,
				consultation_id: this.state.consultation_id,
				patient_id: this.props.newAppointment.id,
				state: this.state.state,
				provider_id: this.state.provider_id,
				vsee_specialty: this.state.vsee_specialty,
				calendar_date: this.state.date,
			});
		}
	}

	showAppointmentAlert = (confFun) => {
		this.setState({
			showappointmentalert: true,
			appointmentalertfun: confFun,
		});
	};

	hideAppointentAlert = () => {
		this.setState({
			showappointmentalert: false,
			appointmentalertfun: () => {},
		});
	};

	//error dialog hide/show
	errorCloseHandler() {
		this.setState({
			showErrorDialog: false,
			error: '',
			errorType: '',
		});
	}

	render() {
		const events = () => {
			return CreateEmptySlotEvents({
				eventsList: this.props.Scheduling.slots,
				reservations: this.props.Scheduling.reservations,
				startDate: this.state.date,
				onSlotClick: (
					id,
					start,
					end,
					vsee_specialty,
					state,
					timezone,
					consultation_id
				) => {
					const timeslot = { id, start, end };
					const currenttimeOfTimezone = moment(new Date()).tz(
						timezone
					);
					const starttimeOfTimezone = moment.tz(
						moment(start).format('YYYY-MM-DDTHH:mm:ss'),
						timezone
					);
					const duration = moment.duration(
						starttimeOfTimezone.diff(currenttimeOfTimezone)
					);
					const hours = duration.asHours();
					const appointmentUpdateObj = {
						timeslot,
						state,
						timezone,
						consultation_id,
					};
					if (vsee_specialty) {
						appointmentUpdateObj.vsee_specialty = vsee_specialty;
					}
					if (hours > 8) {
						this.props.updateAppointmentparams(
							appointmentUpdateObj
						);
					} else {
						this.showAppointmentAlert(() => {
							this.props.updateAppointmentparams(
								appointmentUpdateObj
							);
						});
					}
					return null;
				},
				timeslot: this.props.newAppointment.timeslot,
			});
		};

		const { schedulingConsultations, providers, slotsLoading } =
			this.props.Scheduling;
		return (
			<>
				<div className='schedule-filters'>
					<ScheduleFilters
						schedulingConsultations={schedulingConsultations}
						providers={providers}
						visitProviders={this.props.visitProviders}
						modalities={this.props.modalities}
						updateFilter={this.updateFilter}
						selectedFilters={{ ...this.state }}
						newAppointment={this.props.newAppointment}
						updateNewAppointment={this.updateNewAppointment}
						goToStep={this.props.goToStep}
						isZeroPriceConsultation={
							this.props.isZeroPriceConsultation
						}
						specialties={this.props.specialties}
						showModality={!this.props.PartnerDetail?.is_phone_only}
					/>
				</div>
				<div
					className={`schedule-container ${
						this.isShaded ? 'disabled-calendar' : ''
					}`}
				>
					<div
						className={`schedule-calendar ${
							this.isShaded ? 'disabled-pointerevents' : ''
						}`}
					>
						<CalendarSection
							events={events()}
							date={this.state.date}
							newAppointment={this.props.newAppointment}
							switchDateBack={this.switchDateBack}
							switchDateForward={this.switchDateForward}
							switchToToday={this.switchToToday}
							isShaded={this.isShaded}
							slotsLoading={slotsLoading}
						/>
					</div>
				</div>

				<AppointmentErrorDialog
					showErrorDialog={this.state.showErrorDialog}
					errorCloseHandler={this.errorCloseHandler}
					error={this.state.error}
					errorType={this.state.errorType}
					errorTitle={'Appointment Scheduling Failed'}
					initialErrorMessage={
						'We were unable to schedule this appointment due to '
					}
				/>

				{this.state.showappointmentalert && (
					<AppointentAlert
						save={() => {
							this.state.appointmentalertfun();
							this.hideAppointentAlert();
						}}
						cancel={() => {
							this.hideAppointentAlert();
						}}
					/>
				)}
			</>
		);
	}
}

export default ScheduleStage;
