//React & Redux
import React, { Component } from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from '../../../../../assets/exit-alert.svg';
import contactsIcon from '../../../../../assets/contacts.svg';
import testManagement from '../../../../NavBar/test-management.svg';
import exclamation_img from '../../../../../assets/exclamation.png';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Button, Loading, Input } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Styles
import './reject-testrequest-modal.css';

class RejectTestRequestModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rejectingTestReason: '',
			loading: false,
		};
		this.handleRejectTestRequest = this.handleRejectTestRequest.bind(this);
		this.isSubmitDisabled = this.isSubmitDisabled.bind(this);
		this.determineDOB = this.determineDOB.bind(this);
		this.determineDOBDifference = this.determineDOBDifference.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {}

	handleChange = (value) => {
		this.setState({ rejectingTestReason: value });
	};

	isSubmitDisabled = () => {
		const { rejectingTestReason, loading } = this.state;
		const testOrderUpdating = _.get(this, 'props.testOrderUpdating', false);
		return _.isEmpty(rejectingTestReason) || loading || testOrderUpdating;
	};

	handleRejectTestRequest = () => {
		if (!_.isEmpty(_.get(this, ['state', 'rejectingTestReason'], ''))) {
			return this.props.approveOrRejectSingleTest(
				_.get(this, ['props', 'testOrder', 'test_order_uuid'], ''),
				'rejected',
				{
					order_status_note: _.get(
						this,
						['state', 'rejectingTestReason'],
						''
					),
				},
				{
					patient: {
						first_name:
							_.get(this, 'props.patientDetail.first_name', '') ||
							'--',
						last_name:
							_.get(this, 'props.patientDetail.last_name', '') ||
							'--',
					},
					test_name:
						_.get(this, 'props.testOrder.testname', '') || '--',
				}
			);
		}
	};

	determineDOB = () => {
		const dob = _.get(this, ['props', 'patientDetail', 'dob'], null);

		if (moment(dob).isValid()) {
			return moment(dob).format('MMM DD, YYYY');
		} else return dob || 'DOB Unknown';
	};

	determineDOBDifference = () => {
		const dob = _.get(this, ['props', 'patientDetail', 'dob'], null);

		if (moment(dob).isValid()) {
			return moment().diff(dob, 'years') + ' yrs old';
		} else return 'Age Unknown';
	};

	render() {
		const testOrderUpdating = _.get(this, 'props.testOrderUpdating', false);
		const testBulkUpdating = _.get(this, 'props.testBulkUpdating', false);
		return (
			<div className='reject-testrequest-modal-container'>
				<Loading
					loading={
						this.state.loading ||
						testOrderUpdating ||
						testBulkUpdating
					}
				>
					<div className='reject-testrequest-modal-header'>
						<span className='reject-testrequest-modal-header-text'>
							<img
								src={exclamation_img}
								alt='Reject Test'
								className='alert-reject-test-head'
							/>
							Reject Test Request
						</span>
						<span className='reject-testrequest-modal-close'>
							<ReactSVG
								src={xIcon}
								onClick={(e) => {
									this.props.hideRejectTestModal();
								}}
							/>
						</span>
					</div>
					<div className='reject-testrequest-modal-body'>
						<div className='reject-test-patient-info-container'>
							<ReactSVG
								src={contactsIcon}
								className='contacts-icon'
							/>
							<div className='patient-info-wrapper'>
								<span className='reject-test-patient-name'>
									{_.get(
										this,
										[
											'props',
											'patientDetail',
											'first_name',
										],
										''
									)}{' '}
									{_.get(
										this,
										['props', 'patientDetail', 'last_name'],
										''
									)}
								</span>
								<span className='reject-test-patient-email'>
									{_.get(
										this,
										['props', 'patientDetail', 'email'],
										''
									)}
								</span>
								<span>
									{this.determineDOB()} &nbsp;&nbsp; ·
									&nbsp;&nbsp; {this.determineDOBDifference()}{' '}
									&nbsp;&nbsp; · &nbsp;&nbsp;{' '}
									<span className='patient-gender'>
										{_.get(
											this,
											[
												'props',
												'patientDetail',
												'gender',
											],
											''
										)}
									</span>
								</span>
								<span>
									<span>Ref. Program:</span>
									<span className='refral-prgrm-val'>
										{_.get(
											this,
											[
												'props',
												'patientDetail',
												'referral_program',
											],
											''
										)}
									</span>
								</span>
							</div>
						</div>

						<div className='reject-test-info-container'>
							<div className='reject-test-info-head'>
								<ReactSVG
									src={testManagement}
									className='icon-lab'
								/>
								<div className='reject-test-info-wrapper'>
									<span className='test-name'>
										{_.get(
											this,
											['props', 'testOrder', 'testname'],
											'--'
										)}
									</span>
									<span className='lab-name'>
										{_.get(
											this,
											['props', 'testOrder', 'lab'],
											'--'
										)}
									</span>
								</div>
							</div>
							<div className='reject-test-info-body'>
								<span className='clinical-flag-title'>
									CLINICAL FLAG
								</span>
								<span className='medical-history-content'>
									No items flagged from patient’s
									personal/family medical history
								</span>
								<div className='scp-content-list'>
									<ul>
										<li>
											{' '}
											Patient declined pre-test 1:1 GC
											appointment{' '}
										</li>
										<li>
											Patient attests to reviewing and
											understanding the presented
											educational materials{' '}
										</li>
										<li> Patient consents to testing </li>
									</ul>
								</div>
							</div>
						</div>

						<span className='lbl-rejecting-reason'>
							Reason For Rejecting Test Request
						</span>
						<Input
							type='textarea'
							name='rejecting-test-reason'
							id='rejecting-test-reason'
							maxLength={280}
							autosize={{ minRows: 3, maxRows: 3 }}
							value={_.get(
								this,
								['state', 'rejectingTestReason'],
								''
							)}
							placeholder='Your message'
							onChange={(value) => this.handleChange(value)}
						/>
					</div>
					<div className='reject-testrequest-modal-footer'>
						<Button
							className='btn-reject-testrequest'
							disabled={this.isSubmitDisabled()}
							onClick={this.handleRejectTestRequest}
						>
							Reject Test Request
						</Button>
					</div>
				</Loading>
			</div>
		);
	}
}

export default RejectTestRequestModal;
