import React from 'react';
import InputField from '../../../../Common/FormFields/InputField';
import './NoteInputField.scss';
import classnames from 'classnames';

export const NoteInputField = ({ name, value, onChange }) => {
	return (
		<InputField
			type='textarea'
			name={name}
			className={classnames('note-input-field', {
				'expand-add-comments': !!value,
			})}
			placeholder={'Enter note text'}
			value={value || ''}
			onChange={onChange}
		/>
	);
};
