import React from 'react';

const AuditTrailHistoryRecord = ({ lastItem, byName, onDate, message }) => {
	return (
		<div
			className='auditTrailHistoryRecordContainer'
			style={{
				borderLeft: lastItem ? 'none' : '2px solid #edeff3',
				marginBottom: lastItem ? '0px' : '11px',
				marginLeft: lastItem ? '6px' : '4px',
			}}
		>
			<div className='auditTrailHistoryRecordPoint' />
			<div className='auditTrailHistoryRecordData'>
				<div className='auditTrailHistoryRecordDataMajor'>
					<span
						className='auditTrailHistoryRecordMajorLabel'
						style={{ minWidth: lastItem ? '140px' : '77px' }}
					>
						{message}
					</span>
				</div>
				<div className='auditTrailHistoryRecordDataMinor'>
					By {byName}
				</div>
				<div className='auditTrailHistoryRecordDataMinor'>{onDate}</div>
			</div>
		</div>
	);
};

export default AuditTrailHistoryRecord;
