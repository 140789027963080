//React & Redux
import React, { Fragment } from 'react';

// Lodash
import { isArray } from 'lodash';

//Styles
import './styles.css';

export default function (props) {
	const {
		hasData,
		loading,
		noResultsFound,
		noResultsFoundAltMessage,
		emptyOnNoResults,
	} = props;
	const children = isArray(props.children)
		? props.children
		: [props.children];

	if (!hasData && !loading && !emptyOnNoResults)
		return (
			<tbody>
				<tr>
					<td colSpan='100%' className='no-results'>
						{noResultsFound ? (
							<Fragment>{noResultsFound}</Fragment>
						) : (
							<Fragment>
								<div>
									{props.noResultsFoundAltMessage ||
										'No Results Found'}
								</div>
								{props.handleResetFilters && (
									<Fragment>
										<p>
											Try adjusting your search or filter
											options to find
											<br />
											what you’re looking for.
										</p>
										<span
											onClick={props.handleResetFilters}
										>
											Reset Filters
										</span>
									</Fragment>
								)}
							</Fragment>
						)}
					</td>
				</tr>
			</tbody>
		);
	if (props.doNotClone === true) {
		return <tbody>{children}</tbody>;
	} else {
		return (
			<tbody>
				{children.map((child) =>
					React.cloneElement(child, {
						parent: 'tbody',
						isExpandable: props.isExpandable,
						trs: props.trs,
						isAllExpanded: props.isAllExpanded,
						registerTr: props.registerTr,
						handleToggle: props.handleToggle,
					})
				)}
			</tbody>
		);
	}
}
