import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dropdown from '../../../../Common/FormFields/Dropdown';
import InputField from '../../../../Common/FormFields/InputField';
import {
	getRaceEnum,
	getEthnicityEnum,
	getGender,
	getPronouns,
} from '../../../../../actions/patients';

import { useSection } from '../sections/useSection';

/**
 * Other Demographic Identifiers subsection of Additional Patient Info section
 */
export function OtherDemographicIdentifiers({
	isEdit,
	patientDetail,
	sectionDataContainerName,
}) {
	const { values, touched, errors, onChange, setFieldValue } = useSection(
		sectionDataContainerName
	);
	const { ethnicityEnum, gender, pronouns, raceEnum } = useSelector(
		(store) => ({
			ethnicityEnum: store.ethnicityEnum?.ethnicityEnum ?? [],
			gender: store.gender?.gender?.gender ?? [],
			pronouns: store.pronouns?.pronouns?.pronouns ?? [],
			raceEnum: store.raceEnum?.raceEnum ?? [],
		})
	);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getEthnicityEnum());
		dispatch(getGender());
		dispatch(getPronouns());
		dispatch(getRaceEnum());
	}, []);

	useEffect(() => {
		if (values.isPrimaryPolicyHolder === true) {
			setFieldValue('relationshipToPolicyholder', null);
			setFieldValue('policyholderFirstName', null);
			setFieldValue('policyholderLastName', null);
			setFieldValue('policyholderDOB', null);
		}
	}, [values.isPrimaryPolicyHolder]);

	useEffect(() => {
		if (patientDetail && isEdit) {
			setFieldValue('gender', patientDetail.gender);
			setFieldValue('pronouns', patientDetail.pronouns);
			setFieldValue('preferredName', patientDetail.preferred_name);
			setFieldValue('race', patientDetail.race);
			setFieldValue('secondaryRace', patientDetail.secondary_races);
			setFieldValue('ethnicity', patientDetail.ethnicity);
		}
	}, [patientDetail]);

	const [selectedRace, setSelectedRace] = useState();

	useEffect(() => {
		if (values.race && raceEnum.length > 0) {
			const race = raceEnum.find(
				(item) => item.display_name === values.race,
			);
			setSelectedRace(race);

			if (!race || !race.secondary_races) {
				setFieldValue('secondaryRace', []);
				return;
			}

			if (isEdit) {
				const secondaryIncluded =
					values.secondaryRace?.some((secondaryRace) =>
						race.secondary_races.some(
							(raceItem) =>
								raceItem.display_name === secondaryRace,
						),
					);

				if (!secondaryIncluded) {
					setFieldValue('secondaryRace', []);
				}
			} else if (race.secondary_races.length > 0) {
				setFieldValue('secondaryRace', []);
			}
		}
	}, [values.race, raceEnum.length]);

	return (
		<>
			<h3>Other Demographic Identifiers</h3>
			<div className='other-demographic-identifiers-fields'>
				<Dropdown
					label='Gender'
					name='gender'
					clearable
					options={gender.map((item) => ({
						label: item.display_name,
						value: item.key,
					}))}
					error={touched.gender && errors.gender}
					onChange={onChange('gender')}
					value={values.gender}
				/>
				<Dropdown
					label='Pronouns'
					name='pronouns'
					clearable
					multiple
					options={pronouns.map((item) => ({
						label: item.display_name,
						value: item.key,
					}))}
					error={touched.pronouns && errors.pronouns}
					onChange={onChange('pronouns')}
					value={values.pronouns}
				/>
				<InputField
					label='Preferred Name'
					name='preferredName'
					error={touched.preferredName && errors.preferredName}
					onChange={onChange('preferredName')}
					value={values.preferredName}
				/>
				<Dropdown
					label='Race'
					name='race'
					clearable={!isEdit}
					options={raceEnum.map((item) => ({
						label: item.display_name,
						value: item.display_name,
					}))}
					error={touched.race && errors.race}
					onChange={onChange('race')}
					value={values.race}
				/>
				{selectedRace && selectedRace.secondary_races.length > 0 && (
					<Dropdown
						label='Secondary race'
						name='secondaryRace'
						clearable={!isEdit}
						multiple
						options={selectedRace.secondary_races.map((item) => ({
							label: item.display_name,
							value: item.display_name,
						}))}
						error={touched.secondaryRace && errors.secondaryRace}
						onChange={onChange('secondaryRace')}
						value={values.secondaryRace}
					/>
				)}
				<Dropdown
					label='Ethnicity'
					name='ethnicity'
					clearable={!isEdit}
					options={ethnicityEnum.map((item) => ({
						label: item.display_name,
						value: item.display_name,
					}))}
					error={touched.ethnicity && errors.ethnicity}
					onChange={onChange('ethnicity')}
					value={values.ethnicity}
				/>
			</div>
		</>
	);
}
