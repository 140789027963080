//React & Redux
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

//Images
import ReactSVG from 'react-svg';
import cancelledByUser from '../../assets/cancelled-by.svg';
import noShow from '../../assets/no-show.svg';
import exitAlert from '../../assets/exit-alert.svg';
import noOutreach from '../../assets/do-not-outreach.svg';
import alert from '../../assets/alert.svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';
import classnames from 'classnames';

//Utils
import { ROLE_PHYSICIAN } from '../../Utils/permissionUtils';
import {
	getDateTimeString,
	getMomentDateTimeOrString,
	isValidDateTimeString,
	dateTimeFormats,
} from './V2/Outreachs/utilfunctions.js';
import { getLanguageTagText, getReasonTagText } from './../../utils.js';
import { drugExposureEnabledPrograms } from './../../Utils/medicationsUtils.js';

//Actions & Services
import { patientdetail, getLinkedProviders } from '../../actions/patients';
import {
	resetPatientInfo,
	getConsenttotreat,
	getContactMethods,
	getContactOutcomes,
	getReferralReasons,
	getReferralProviders,
	getReferralPrograms,
	updateOutreach,
	updateAddress,
	setPassword,
	updateRelationship,
	getNoOutreachReasons,
	getEligibilityFields,
	getInsuranceCompanies,
	clearAllPatientEditErrors,
	createAthenaPatient,
	getPatientTags,
	getGender,
	getPronouns,
	getSexAssignedAtBirth,
	getRaceEnum,
	getEthnicityEnum,
} from '../../actions/patients';
import {
	getaffiliation,
	getProviderSpecialties,
} from '../../actions/providers';
import {
	getencounters,
	completeEncounter,
	cancelEncounter,
	getICDCodes,
	editencounter,
	getBillingEncounterDetail,
} from '../../actions/encounter';
import { saveBillingEncounter } from '../../actions/billing';
import {
	getOutreachEnums,
	addOutreachUponCreate,
	doNotOutreach,
	dispatch_ResetOutreachSetting,
	getOutreachSetting,
	getOutreachDeclinedEnum,
} from '../../actions/patient/outreach';
import { getPatientDrugExposures } from '../../actions/graphql';

//UI Libraries
import { Loading, DatePicker, Select, Input } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import PatientInfo from './PatientInfo';
import PatientInfoTab from './PatientInfoTab';
import { EncounterDateTimeField } from './V2/Encounters/Common/EncounterDateTimeField';
import CommonCheckbox from '../Common/Checkbox';
import AppointmentErrorDialog from '../Appointment/AppointmentErrorDialog';

//Styles
import './style.css';
import { Fragment } from 'react';

class PatientDetails extends Component {
	static propTypes = {
		auth: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
			user: '',
			activePage: 0,
			query: '',
			loading: true,
			infoTabWidth: 'calc(100% - 340px)',
			tabFrozen: false,
			appointmentAlert: false,
			loadingAlert: false,
			settingPassword: false,
			settingPasswordSuccess: false,
			outreachAlertInputFields: [
				{
					header: 'Reason for declining outreach',
					type: 'select',
					required: true,
				},
				{
					header: 'Declined date',
					type: 'date',
					required: true,
				},
			],
			updatedOutreachAlert: null,
			updatedOutreachAlertInputFields: [
				{
					header: 'Declined Date & Time',
					type: 'date',
					required: true,
				},
				{
					header: 'Reason For Declining Outreach',
					type: 'select',
					required: true,
				},
				{
					header: 'Declined Via',
					type: 'select',
					subType: 'declined_via',
					required: true,
				},
			],
			doNotOutreachErrorMessage: false,
			initialNoOutreachAlert: false,
			showedInitialNoOutreachAlert: false,
			noOutreachRequested: false,
			updatedNoOutreachRequested: false,
			doNotOutreachDeclinedVia: null,
			doNotResetPatient: false,
			outreachReasons: [],
			enabledDoNotOutreach: true,
			isDrugExposureEnabled: false,
			notifyPatient: true,
			showErrorDialog: false,
			errorMessages: [],
		};
		this.getPatientDetail = this.getPatientDetail.bind(this);
		this.resetPatientDetail = this.resetPatientDetail.bind(this);
		this.activateDoNoResetPatient =
			this.activateDoNoResetPatient.bind(this);
		this.setNoOutreachData = this.setNoOutreachData.bind(this);
		this.getReferralProgramFromDisplayName =
			this.getReferralProgramFromDisplayName.bind(this);
		this.renderPatientEdit = this.renderPatientEdit.bind(this);
		this.hidePatientEdit = this.hidePatientEdit.bind(this);
		this.parsePatientTags = this.parsePatientTags.bind(this);
		this.tabFreeze = this.tabFreeze.bind(this);
		this.tabUnfreeze = this.tabUnfreeze.bind(this);
		this.openAppointmentAlert = this.openAppointmentAlert.bind(this);
		this.renderAlert = this.renderAlert.bind(this);
		this.closeAlert = this.closeAlert.bind(this);
		this.appointmentCancel = this.appointmentCancel.bind(this);
		this.appointmentNoShow = this.appointmentNoShow.bind(this);
		this.openDateTimeDisabledOutreach =
			this.openDateTimeDisabledOutreach.bind(this);
		this.openOutreachAlert = this.openOutreachAlert.bind(this);
		this.renderOutreachAlertInputField =
			this.renderOutreachAlertInputField.bind(this);
		this.renderUpdatedOutreachAlertInputField =
			this.renderUpdatedOutreachAlertInputField.bind(this);
		this.closeOutreachAlert = this.closeOutreachAlert.bind(this);
		this.handleDoNotOutreach = this.handleDoNotOutreach.bind(this);
		this.canUpdateOutreach = this.canUpdateOutreach.bind(this);
		this.handleUpdatedDoNotOutreach =
			this.handleUpdatedDoNotOutreach.bind(this);
		this.setDoNotOutreach = this.setDoNotOutreach.bind(this);
		this.determineInitialOutreachStatus =
			this.determineInitialOutreachStatus.bind(this);
		this.updatedDetermineInitialOutreachStatus =
			this.updatedDetermineInitialOutreachStatus.bind(this);
		this.closeInitialNoOutreachAlert =
			this.closeInitialNoOutreachAlert.bind(this);
		this.doNotOutreach = this.doNotOutreach.bind(this);
		this.updateNameInBreadcrumbs = this.updateNameInBreadcrumbs.bind(this);
		this.getFullName = this.getFullName.bind(this);
		this.determineIsDrugExposureEnabled =
			this.determineIsDrugExposureEnabled.bind(this);
		this.showErrorsDialog = this.showErrorsDialog.bind(this);
		this.hideErrorDialog = this.hideErrorDialog.bind(this);
	}

	getPasswordType = () => {
		return _.get(this.props, 'patientdetail[0].has_password')
			? 'Reset'
			: 'Set';
	};

	showErrorsDialog(errors) {
		this.setState({
			errormessages: errors,
			showErrorDialog: true,
		});
	}

	hideErrorDialog() {
		this.setState({
			errormessages: [],
			showErrorDialog: false,
		});
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		const { patientid, activePage, q } = _.get(
			this.props,
			'match.params',
			{}
		);
		this.setState({ activePage: activePage, query: q });
		this.getPatientDetail();
		this.props.callConsenttotreat();
		this.props.getencouter({ id: patientid, offset: 0 });
		this.props.getInsuranceCompanies({ patient_uuid: patientid });
		this.props.getLinkedProviders(patientid);
		this.props.getGenderEnum();
		this.props.getPronounsEnum();
		this.props.getSexAssignedAtBirthEnum();
		this.props.getRaceEnum();
		this.props.getEthnicityEnum();

		const activerole = _.get(this.props, ['me', 'activerole', 'name'], '');
		const isExternalUser = _.get(
			this.props,
			['me', 'activerole', 'isExternalUser'],
			''
		);
		const restrictedUser = activerole in { [ROLE_PHYSICIAN]: 1 };
		if (!restrictedUser) this.props.GetOutreachSetting(patientid);

		this.props.getRefPrograms();
		this.props.getReferralReasons();
		this.props.getReferralProviders();
		this.props.callConsenttotreat();
		this.props.getContactMethods();
		this.props.getContactOutcomes();
		this.props.getNoOutreachReasons();
		this.props.getAffiliations();
		this.props.getOutreachEnums();
		this.props.getOutreachDeclinedEnum();
		this.props.getPatientTags();
		this.props.getProviderSpecialties();
		if (!isExternalUser) this.props.getPatientDrugExposures(patientid);
	}

	componentDidUpdate(prevProps, prevState) {
		const { patientid, tabtype, activePage, q } = get(
			this.props,
			'match.params',
			{}
		);

		if (
			get(this, 'props.setPasswordResponse') &&
			get(this, 'props.setPasswordResponse.success', false) &&
			get(this.state, 'settingPassword', false)
		) {
			this.props.enqueueSnackbar(
				this.getPasswordType() + ' password email sent',
				{
					variant: 'success',
					className: 'export_notification',
					anchorOrigin: { horizontal: 'center', vertical: 'top' },
				}
			);
			this.setState({
				settingPassword: false,
				settingPasswordSuccess: true,
			});
		}

		// if (get(this, 'props.patientdetail', "")) {
		//     this.setState({ isenablepatientedit: true })
		//     this.setState({ isaddencounter: true })
		// }

		if (
			!_.isEqual(get(this, 'props.patientdetail', []), []) &&
			!_.isEqual(get(this, 'props.patientdetail', {}), {}) &&
			!_.isEqual(this.props.patientdetail, prevProps.patientdetail)
		) {
			this.setState({ loading: false });
			this.determineInitialOutreachStatus();
		} else if (
			this.props.patientdetail === undefined &&
			this.props.patientdetail !== prevProps.patientdetail
		) {
			this.setState({ loading: false });
			this.determineInitialOutreachStatus();
		}

		if (
			prevProps.encounterCancelLoading &&
			!this.props.encounterCancelLoading
		) {
			this.setState({ loadingAlert: false });
		}

		if (
			prevProps.encounterCompleteLoading &&
			!this.props.encounterCompleteLoading
		) {
			this.setState({ loadingAlert: false });
		}

		if (
			prevProps.gettingPatientDetail &&
			!this.props.gettingPatientDetail
		) {
			if (prevProps.patientdetail[0] && this.props.patientdetail[0]) {
				this.setState({
					loadingAlert: false,
				});
				this.closeAlert();
				this.closeOutreachAlert();
			}
			// temporarily disabled to mask eligibility work
			// this.props.getEligibilityFields(this.getReferralProgramFromDisplayName());

			if (
				!get(this, 'props.patientTagsError', false) &&
				get(this, 'props.patientTags', null)
			)
				this.parsePatientTags();
			this.updateNameInBreadcrumbs();
			this.determineIsDrugExposureEnabled();
		}

		if (
			get(prevProps, 'settingDoNotOutreach', false) &&
			!get(this, 'props.settingDoNotOutreach', false)
		) {
			const setDoNotOutreachSuccessMessage =
				get(this, 'props.outreachSetting.enabled', true) === true
					? 'Patient marked as "Do Not Outreach"'
					: 'Successfully disabled "Do Not Outreach"';
			const setDoNotOutreachErrorMessage =
				get(this, 'props.outreachSetting.enabled', true) === true
					? 'Error in marking patient as "Do Not Outreach"'
					: 'Error in disabled "Do Not Outreach"';

			if (get(this, 'props.setDoNotOutreachError', null)) {
				this.props.enqueueSnackbar(setDoNotOutreachErrorMessage, {
					variant: 'error',
					className: 'export_notification',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
			} else {
				this.props.enqueueSnackbar(setDoNotOutreachSuccessMessage, {
					variant: 'info',
					className: 'export_notification',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				this.closeOutreachAlert();
				this.getPatientDetail();
				this.props.GetOutreachSetting(patientid);
			}
		}

		if (
			get(prevProps, 'loading_outrechSetting', false) &&
			!get(this, 'props.loading_outrechSetting', false)
		) {
			if (get(this, 'props.outreachSettingError', false)) {
				this.props.enqueueSnackbar(
					'Error in loading outreach settings',
					{
						variant: 'error',
						className: 'export_notification',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
			} else {
				this.updatedDetermineInitialOutreachStatus();
				this.determineInitialOutreachStatus();
			}
			//remove 'notes' field for reason for disabling "Do Not Outreach"
			this.setState({
				updatedOutreachAlertInputFields:
					this.state.updatedOutreachAlertInputFields.filter((el) => {
						return el.header != 'Notes';
					}),
			});

			if (!_.isNil(get(this, 'props.outreachSetting.enabled', null))) {
				this.setNoOutreachData();
			}
		}

		if (
			get(prevProps, 'gettingOutreachReasons', false) &&
			!get(this, 'props.gettingOutreachReasons', false)
		) {
			if (get(this, 'state.setOutreachReasons', false))
				this.setNoOutreachData();
		}

		if (
			get(prevProps, 'drugExposureLoading', false) &&
			!get(this, 'props.drugExposureLoading', false)
		) {
			if (get(this, 'props.drugExposureError', false)) {
				this.props.enqueueSnackbar(
					'Error in loading patient drug exposures',
					{
						variant: 'error',
						className: 'export_notification',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
			}
		}

		//in case patient detail loads before referral program enum, temporarily disabled to mask eligibility work
		// if (prevProps.gettingReferralPrograms &&
		//     !this.props.gettingReferralPrograms &&
		//     get(this, "props.patientdetail[0].referral_program", "")
		// ) {
		//     this.props.getEligibilityFields(this.getReferralProgramFromDisplayName());
		// }
	}

	componentWillUnmount() {
		if (!get(this, 'state.doNotResetPatient', false))
			this.resetPatientDetail();
		this.props.ResetOutreachSetting();
	}

	activateDoNoResetPatient() {
		return new Promise((resolve, reject) => {
			this.setState({ doNotResetPatient: true }, resolve);
		});
	}

	setNoOutreachData() {
		const outreachEnabled = get(
			this,
			'props.outreachSetting.enabled',
			null
		);
		if (
			outreachEnabled &&
			!_.isNil(get(this, 'props.noOutreachReasons', null))
		) {
			const noOutreachReasonsEnum = _.filter(
				get(this, 'props.noOutreachReasons', null),
				(el) => {
					return el.is_decline_reason && el.is_decline_reason == true;
				}
			);
			this.setState({
				outreachReasons: noOutreachReasonsEnum,
				enabledDoNotOutreach: true,
				setOutreachReasons: true,
			});
		} else {
			const noOutreachReasonsEnum = _.filter(
				get(this, 'props.noOutreachReasons', null),
				(el) => {
					return el.is_decline_reason == false;
				}
			);
			this.setState({
				outreachReasons: noOutreachReasonsEnum,
				enabledDoNotOutreach: false,
				setOutreachReasons: true,
			});
		}

		/* set Do Not Outreach popup's fields label based outreach setting enable flag */
		this.setState({
			updatedOutreachAlertInputFields: [
				{
					header:
						get(this, 'props.outreachSetting.enabled', null) ===
						true
							? 'Declined Date & Time'
							: 'Date & Time',
					type: 'date',
					required: true,
				},
				{
					header:
						get(this, 'props.outreachSetting.enabled', null) ===
						true
							? 'Reason For Declining Outreach'
							: 'Reason For Disabling “Do Not Outreach”',
					type: 'select',
					required: true,
				},
				{
					header:
						get(this, 'props.outreachSetting.enabled', null) ===
						true
							? 'Declined Via'
							: 'Patient Contacted Via',
					type: 'select',
					subType: 'declined_via',
					required: true,
				},
			],
		});
	}

	updateNameInBreadcrumbs() {
		if (!get(this, 'props.history.location.state.breadcrumbs', null)) {
			get(
				this.props.history.replace({
					pathname: window.location.pathname.replace(
						'/ui/app/',
						'/app/'
					),
					state: {
						breadcrumbs: [
							// {
							//     location: 'patients',
							//     url: '/app/patients/'
							// },
							{
								location: 'Patient Detail',
								patientFullName: this.getFullName(),
							},
						],
					},
					hash: window.location.hash,
				})
			);
		} else {
			const oldBreadCrumbs = get(
				this,
				'props.history.location.state.breadcrumbs',
				null
			);
			if (Array.isArray(oldBreadCrumbs)) {
				const newBreadCrumbs = _.cloneDeep(oldBreadCrumbs);
				const patientDetailBreadCrumb = _.find(newBreadCrumbs, (el) => {
					return (
						get(el, 'location', null) === 'Patient Detail' ||
						get(el, 'location', null) === 'Encounter Detail'
					);
				});
				if (patientDetailBreadCrumb) {
					patientDetailBreadCrumb['patientFullName'] =
						this.getFullName();
					get(
						this.props.history.replace({
							pathname: window.location.pathname.replace(
								'/ui/app/',
								'/app/'
							),
							state: {
								breadcrumbs: newBreadCrumbs,
							},
							hash: window.location.hash,
						})
					);
				}
			}
		}
	}

	getFullName() {
		const patient = get(this, 'props.patientdetail[0]', {}) || {};
		const firstName = get(patient, 'first_name', '--') || '--';
		const lastName = get(patient, 'last_name', '--') || '--';
		const fullName = `${firstName} ${lastName}`;
		return fullName;
	}

	determineIsDrugExposureEnabled() {
		const referralProgram = get(
			this,
			'props.patientdetail[0].referral_program',
			null
		);
		const status = drugExposureEnabledPrograms.has(referralProgram) || true; //forced true for now
		this.setState({ isDrugExposureEnabled: status });
	}

	getReferralProgramFromDisplayName() {
		const displayName =
			get(this, 'props.patientdetail[0].referral_program', '') || '';
		if (displayName) {
			if (
				get(
					get(this, 'props.referralPrograms', []) || [],
					'length',
					0
				) > 0
			) {
				const filteredArr = (
					get(this, 'props.referralPrograms', []) || []
				).filter((el) => {
					return get(el, 'display_name', '') === displayName;
				});
				return get(filteredArr, '[0].name', '') || '';
			} else return '';
		} else return '';
	}

	determineInitialOutreachStatus() {
		if (
			!this.state.showedInitialNoOutreachAlert &&
			get(this, 'props.outreachSetting', null)
		) {
			const patient = get(this, 'props.patientdetail[0]', {}) || {};
			this.setState({
				initialNoOutreachAlert:
					get(this, 'props.outreachSetting.enabled', null) === false
						? true
						: false,
				showedInitialNoOutreachAlert: true,
				noOutreachRequested:
					get(this, 'props.outreachSetting.enabled', null) === false
						? true
						: false,
			});
		}
	}

	updatedDetermineInitialOutreachStatus() {
		this.setState({
			updatedNoOutreachRequested: get(
				this,
				'props.outreachSetting.enabled',
				false
			),
		});
	}

	parsePatientTags() {
		const patient = get(this, 'props.patientdetail[0]', {}) || {};
		const isExternalUser = _.get(
			this.props,
			['me', 'activerole', 'isExternalUser'],
			''
		);
		const currentPatientTags = get(patient, 'user_tags', null) || [];
		let hasPriorityTag = false;
		let hasPriorityTagLanguage = null;
		let hasVIPTag = false;
		let hasLanguageLineSupportTag = false;
		let hasLanguageLineSupportTagLanguage = null;
		let hasSpeechImpairedTag = false;
		let hashearingImpairedTag = false;
		let hasVisionImpairedTag = false;
		let hasFinancialAssistanceTag = false;
		let hasFinancialAssistancePerVisitTag = false;
		let hasDeceasedTag = false;
		let hasDischargedTag = false;
		let hasMarketingOptoutTag = false;
		let hasResearchOptoutTag = false;
		let hasNoFurtherEscalationTag = false;
		let hasPatientStoryTag = false;
		const duplicatePatients = get(patient, 'duplicate_patients', []);
		const hasDuplicatePatientTag = !!duplicatePatients.length;

		if (Array.isArray(currentPatientTags)) {
			currentPatientTags.forEach((tag) => {
				const tagType = get(tag, 'name', '');
				switch (tagType) {
					case 'priority_patient':
						hasPriorityTag = true;
						hasPriorityTagLanguage = getReasonTagText(tag);
						break;
					case 'vip_patient':
						hasVIPTag = true;
						break;
					case 'language_line_support':
						hasLanguageLineSupportTag = true;
						hasLanguageLineSupportTagLanguage =
							getLanguageTagText(tag);
						break;
					case 'speech_impaired':
						hasSpeechImpairedTag = true;
						break;
					case 'hearing_impaired':
						hashearingImpairedTag = true;
						break;
					case 'vision_impaired':
						hasVisionImpairedTag = true;
						break;
					case 'financial_assistance':
						hasFinancialAssistanceTag = true;
						break;
					case 'financial_assistance_per_visit':
						hasFinancialAssistancePerVisitTag = true;
						break;
					case 'deceased':
						hasDeceasedTag = true;
						break;
					case 'discharged':
						hasDischargedTag = true;
						break;
					case 'marketing_opt-out':
						hasMarketingOptoutTag = true;
						break;
					case 'research_opt-out':
						hasResearchOptoutTag = true;
						break;
					case 'no_further_escalation':
						hasNoFurtherEscalationTag = true;
						break;
					case 'patient_story':
						if (!isExternalUser) hasPatientStoryTag = true;
						break;
				}
			});
			this.setState({
				hasPriorityTag,
				hasPriorityTagLanguage,
				hasVIPTag,
				hasLanguageLineSupportTag,
				hasLanguageLineSupportTagLanguage,
				hasSpeechImpairedTag,
				hashearingImpairedTag,
				hasVisionImpairedTag,
				hasFinancialAssistanceTag,
				hasFinancialAssistancePerVisitTag,
				hasDeceasedTag,
				hasDischargedTag,
				hasMarketingOptoutTag,
				hasResearchOptoutTag,
				hasNoFurtherEscalationTag,
				hasPatientStoryTag,
				hasDuplicatePatientTag,
				duplicatePatients,
			});
		}
	}

	getPatientDetail(flag) {
		const { patientid, tabtype, activePage, q } = this.props.match.params;
		this.props.getpatientdetail(patientid, flag);
		// this.props.getpatientdetail(get(this, 'props.match.params[0]', 0));
	}

	resetPatientDetail() {
		this.props.resetPatientDetail();
	}

	renderPatientEdit() {
		this.setState({ infoTabWidth: 'calc(100% - 735px)' });
	}

	hidePatientEdit() {
		this.setState({ infoTabWidth: 'calc(100% - 340px)' });
	}

	tabFreeze() {
		this.setState({ tabFrozen: true });
	}

	tabUnfreeze() {
		this.setState({ tabFrozen: false });
	}

	openAppointmentAlert(type, func, uuid) {
		this.setState({ appointmentAlert: true });
		this.setState({ appointmentAlertType: type });
		this.setState({ appointmentAlertFunc: func });
		this.setState({ appointmentAlertUuid: uuid });
	}

	openOutreachAlert(type, uuid) {
		this.setState({ outreachAlert: true });
		this.setState({ outreachAlertType: type });
		//might need patient uuid as well
		this.setState({ outreachAlertUuid: uuid });
	}

	doNotOutreachReasonChange = (val) => {
		if (val == 'other_disable_dno') {
			const _updatedOutreachAlertInputFields =
				this.state.updatedOutreachAlertInputFields;
			const isNotesAvailable = _updatedOutreachAlertInputFields.some(
				(_updatedOutreachAlertInputFields) =>
					_updatedOutreachAlertInputFields.header === 'Notes'
			);

			if (!isNotesAvailable) {
				this.setState({
					doNotOutreachReason: val,
					updatedOutreachAlertInputFields: [
						...this.state.updatedOutreachAlertInputFields,
						{ header: 'Notes', type: 'input', required: true },
					],
				});
			}
		} else {
			this.setState({
				doNotOutreachReason: val,
				updatedOutreachAlertInputFields:
					this.state.updatedOutreachAlertInputFields.filter((el) => {
						return el.header != 'Notes';
					}),
			});
		}
	};

	renderAlert() {
		const patient = get(this, 'props.patientdetail[0]', {});
		let outreachButtonClass =
			'doNotOutreachAlertButton updatedDoNotOutreachAlertButtonSave';
		let disabledOutreachButtonClass =
			'doNotOutreachAlertButton save-disabled-do-not-outreach';

		if (!this.canUpdateOutreach()) {
			outreachButtonClass +=
				' updatedDoNotOutreachAlertButtonSaveDisabled';
			disabledOutreachButtonClass +=
				' disabledDoNotOutreachAlertButtonSaveDisabled';
		}
		if (this.state.appointmentAlertType) {
			let text;
			let options = null;
			let button;
			switch (this.state.appointmentAlertType) {
				case 'cancel':
					text =
						'Are you sure you would like to cancel this appointment?';
					options = (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<CommonCheckbox
								checked={this.state.notifyPatient}
								onChangeFunc={() =>
									this.setState({
										notifyPatient:
											!this.state.notifyPatient,
									})
								}
								marginLeftOverride={0}
								marginRightOverride={10}
								marginBottomOverride={10}
							/>
							<span>Notify patient of cancellation</span>
						</div>
					);
					button = (
						<div
							className='appointmentCancelButton'
							onClick={this.appointmentCancel}
						>
							<ReactSVG
								src={cancelledByUser}
								className='appointmentAlertCancelledByUserIcon appointmentAlertButtonIcon'
							/>
							<span>Yes, Cancel</span>
						</div>
					);
					break;
				case 'noshow':
					text =
						'Are you sure you would like to mark this appointment as No Show?';
					button = (
						<div
							className='appointmentNoShowButton'
							onClick={this.appointmentNoShow}
						>
							<ReactSVG
								src={noShow}
								className='appointmentAlertCancelledByNoShowIcon appointmentAlertButtonIcon'
							/>
							<span
								style={{ position: 'relative', bottom: '1px' }}
							>
								Yes, No Show
							</span>
						</div>
					);
					break;
				default:
					text = 'Case not recognized';
					button = <div>Case not recognized.</div>;
					break;
			}
			return (
				<div className='appointmentAlertContainer'>
					<Loading
						loading={this.state.loadingAlert}
						className='loadingAppointmentAlert'
					>
						<div className='appointmentAlertTopRow'>
							<div style={{ position: 'relative', top: '14px' }}>
								Confirmation
							</div>
							<ReactSVG
								src={exitAlert}
								onClick={this.closeAlert}
								style={{
									width: '28px',
									height: '28px',
									position: 'relative',
									left: '12px',
								}}
							/>
						</div>
						<div
							className='appointmentAlertTextRow'
							style={{
								marginBottom:
									this.state.appointmentAlertType === 'noshow'
										? '24px'
										: '20px',
							}}
						>
							{text}
						</div>
						<div className='appointmentAlertTextRow'>{options}</div>
						<div className='appointmentAlertButtonRow'>
							<div
								className='appointmentAlertKeepButton'
								onClick={this.closeAlert}
							>
								No, Keep it
							</div>
							{button}
						</div>
					</Loading>
				</div>
			);
		} else if (this.state.outreachAlertType) {
			switch (this.state.outreachAlertType) {
				case 'disableOutreach':
					return (
						<div className='doNotOutreachAlertContainer'>
							{this.state.doNotOutreachErrorMessage && (
								<div className='doNotOutreachErrorMessage'>
									Please fill out both fields.
								</div>
							)}
							<Loading
								loading={this.state.loadingAlert}
								className='loadingAppointmentAlert'
								style={{ position: 'unset' }}
							>
								<div className='doNotOutreachAlertContainerTextRow'>
									<span>Do not outreach</span>
									<span className='doNotOutreachAlertContainerTextRowBottom'>
										{`${patient.first_name} ${patient.last_name}`}
										<ReactSVG
											src={alert}
											style={{
												position: 'relative',
												top: '3px',
												left: '2px',
												height: '36px',
											}}
										/>
									</span>
								</div>
								<div className='doNotOutreachAlertInputContainer'>
									{this.state.outreachAlertInputFields.map(
										(el) => {
											return this.renderOutreachAlertInputField(
												el
											);
										}
									)}
								</div>
								<div className='doNotOutreachAlertButtonRow'>
									<div
										className='doNotOutreachAlertButton doNotOutreachAlertButtonCancel'
										onClick={this.closeOutreachAlert}
									>
										Cancel
									</div>
									<div
										className='doNotOutreachAlertButton doNotOutreachAlertButtonDisable'
										onClick={this.handleDoNotOutreach}
									>
										<ReactSVG
											src={noOutreach}
											style={{
												position: 'relative',
												top: '3px',
												left: '2px',
												marginRight: '11px',
												width: '28px',
											}}
										/>
										<span>Disable Outreach</span>
									</div>
								</div>
							</Loading>
						</div>
					);
				case 'updatedDisableOutreach':
					if (!get(this, 'state.doNotOutreachDate'))
						this.setState({
							doNotOutreachDate: getDateTimeString(moment()),
						});
					return (
						<div className='updatedDoNotOutreachAlertContainer'>
							<Loading
								loading={
									this.state.loadingAlert ||
									get(
										this,
										'props.settingDoNotOutreach',
										false
									)
								}
								className='loadingAppointmentAlert'
								style={{
									position: 'unset',
									width: '100%',
									height: '100%',
								}}
							>
								<div className='updatedDoNotOutreachAlertContainerTextRow'>
									{this.state.enabledDoNotOutreach ? (
										<Fragment>
											<ReactSVG
												src={alert}
												className='updatedOutreachAlertIcon'
											/>
											<span
												style={{
													position: 'relative',
													top: '2px',
												}}
											>
												Do Not Outreach
											</span>
										</Fragment>
									) : (
										<span
											style={{
												position: 'relative',
												top: '2px',
											}}
										>
											Disable “Do Not Outreach”
										</span>
									)}
								</div>
								<div className='updatedDoNotOutreachAlertBody'>
									<div
										style={{
											fontSize: '13px',
											color: '#262837',
											paddingLeft: '16px',
											marginBottom: '5px',
										}}
									>
										Patient Name
									</div>
									<div
										className={
											this.state.enabledDoNotOutreach
												? 'doNotOutreachAlertContainerTextRowBottom'
												: 'disable-do-not-outreach-patient-name'
										}
										style={{
											marginBottom: '33px',
											paddingLeft: '16px',
											fontSize: '15px',
										}}
									>
										{`${patient.first_name} ${patient.last_name}`}
									</div>
									<div className='updatedDoNotOutreachAlertInputContainer'>
										{this.state.updatedOutreachAlertInputFields.map(
											(el) => {
												return this.renderUpdatedOutreachAlertInputField(
													el
												);
											}
										)}
									</div>
								</div>
								<div className='updatedDoNotOutreachAlertButtonRow'>
									<span
										className={classnames(
											'doNotOutreachAlertButton',
											get(
												this,
												'props.outreachSetting.enabled',
												null
											) === true
												? 'updatedDoNotOutreachAlertButtonCancel'
												: 'cancel-disabled-do-not-outreach'
										)}
										onClick={this.closeOutreachAlert}
									>
										Cancel
									</span>
									<span
										className={
											get(
												this,
												'props.outreachSetting.enabled',
												null
											) === true
												? outreachButtonClass
												: disabledOutreachButtonClass
										}
										onClick={
											this.handleUpdatedDoNotOutreach
										}
									>
										{get(
											this,
											'props.outreachSetting.enabled',
											null
										) === true
											? 'Do Not Outreach'
											: 'Disable "DNO"'}
									</span>
								</div>
							</Loading>
						</div>
					);
			}
		} else if (this.state.initialNoOutreachAlert) {
			return (
				<div className='initialOutreachAlertContainer'>
					<div className='initialOutreachAlertTopRow'>
						<ReactSVG
							src={noOutreach}
							style={{
								position: 'relative',
								bottom: '3px',
								marginRight: '16px',
								height: '34px',
								width: '28px',
								transform: 'scale(1.3)',
							}}
						/>
						<div>Do Not Outreach</div>
					</div>
					<div className='initialOutreachAlertMessage'>
						{'This patient has been marked as '}
						<span style={{ fontFamily: 'RionaSans-Bold' }}>
							'Do Not Outreach'
						</span>
						{' and wishes not to be contacted.'}
					</div>
					<div className='initialOutreachAlertButtonRow'>
						<div
							className='initialOutreachAlertButton'
							onClick={this.closeInitialNoOutreachAlert}
						>
							<span
								style={{ position: 'relative', bottom: '9px' }}
							>
								Yes, I Understand
							</span>
						</div>
					</div>
				</div>
			);
		}
	}

	renderOutreachAlertInputField(el) {
		const noOutreachReasons = this.state.outreachReasons;
		let input;
		switch (el.type) {
			case 'select':
				input = (
					<Select
						placeholder='Select'
						onChange={(e) => {
							this.setState({ doNotOutreachReason: e });
						}}
						className='doNotOutreachAlertInputField'
					>
						{noOutreachReasons.map((el) => {
							return (
								<Select.Option
									label={el.display_name}
									value={el.key}
								>
									{el.display_name}
								</Select.Option>
							);
						})}
					</Select>
				);
				break;
			case 'date':
				input = (
					<DatePicker
						value={this.state.doNotOutreachDate || ''}
						placeholder='Select Date'
						onChange={(e) => {
							this.setState({ doNotOutreachDate: e });
						}}
						className='doNotOutreachAlertInputField'
					/>
				);
		}
		return (
			<div
				className='doNotOutreachAlertInputFieldContainer'
				key={el.header}
			>
				<div className='doNotOutreachAlertInputFieldHeader'>
					{el.header}
				</div>
				<div className='doNotOutreachAlertInputFieldRow'>
					<div className='doNotOutreachAlertInputFieldRequired'>
						{el.required ? '*' : ''}
					</div>
					{input}
				</div>
			</div>
		);
	}

	renderUpdatedOutreachAlertInputField(el) {
		const noOutreachReasons = this.state.outreachReasons;
		const declinedViaModalities =
			get(this, 'props.outreachDeclinedEnum', []) || [];
		let input;
		switch (get(el, 'type', '')) {
			case 'select':
				if (get(el, 'subType', null) === 'declined_via') {
					input = (
						<Select
							placeholder='Select'
							onChange={(e) => {
								this.setState({ doNotOutreachDeclinedVia: e });
							}}
							className='doNotOutreachAlertInputField updatedDoNotOutreachAlertInputField'
						>
							{declinedViaModalities.map((el) => {
								return (
									<Select.Option
										label={get(el, 'display_name', null)}
										value={get(el, 'key', null)}
									>
										{get(el, 'display_name', null)}
									</Select.Option>
								);
							})}
						</Select>
					);
				} else {
					input = (
						<Select
							placeholder='Select'
							onChange={(val) =>
								this.doNotOutreachReasonChange(val)
							}
							className='doNotOutreachAlertInputField updatedDoNotOutreachAlertInputField'
						>
							{noOutreachReasons.map((el) => {
								return (
									<Select.Option
										label={get(el, 'display_name', null)}
										value={get(el, 'key', null)}
									>
										{get(el, 'display_name', null)}
									</Select.Option>
								);
							})}
						</Select>
					);
				}
				break;
			case 'date':
				input = (
					<EncounterDateTimeField
						value={get(this, 'state.doNotOutreachDate', null) || ''}
						name='date_of_service'
						disabled={false}
						placeholder='Select Date'
						istouched={
							get(this, 'state.doNotOutreachDate', null) || ''
						}
						onChange={(e) => {
							this.setState({ doNotOutreachDate: e });
						}}
						onBlur={() => {}}
						extraclassName={
							'doNotOutreachAlertInputField updatedDoNotOutreachAlertInputField'
						}
					/>
				);
				break;
			case 'input':
				input = (
					<Input
						type='textarea'
						placeholder='Enter Notes'
						name='notes'
						rows={3}
						value={
							get(
								this,
								'state.disabledDoNotOutreachReasonNotes',
								null
							) || ''
						}
						onChange={(e) => {
							this.setState({
								disabledDoNotOutreachReasonNotes: e,
							});
						}}
					/>
				);
				break;
		}
		return (
			<div
				className='doNotOutreachAlertInputFieldContainer'
				key={get(el, 'header', null)}
			>
				<div
					className='doNotOutreachAlertInputFieldHeader'
					style={{ color: '#262837' }}
				>
					{get(el, 'header', null)}
				</div>
				<div className='doNotOutreachAlertInputFieldRow updatedDoNotOutreachAlertInputFieldRow'>
					<div className='updatedDoNotOutreachAlertInputFieldRequired'>
						{get(el, 'required', null) ? '*' : ''}
					</div>
					{input}
				</div>
			</div>
		);
	}

	closeAlert() {
		this.setState({
			appointmentAlert: null,
			appointmentAlertType: null,
			appointmentAlertFunc: null,
			notifyPatient: true,
		});
	}

	appointmentCancel() {
		this.setState({ loadingAlert: true });
		this.props
			.cancelEncounter({
				uuid: this.state.appointmentAlertUuid,
				status: 'cancel',
				notify_patient: this.state.notifyPatient,
			})
			.catch((error) => {
				const errorMessage = error?.message || 'An error occurred.';
				this.showErrorsDialog(errorMessage);
			});
	}

	appointmentNoShow() {
		this.setState({ loadingAlert: true });
		this.props.cancelEncounter({
			uuid: this.state.appointmentAlertUuid,
			status: 'no_show',
		});
	}

	setPassword = (patient_uuid) => {
		this.setState({ settingPassword: true });
		this.props.setPassword(patient_uuid);
	};

	openDateTimeDisabledOutreach() {
		this.setState({
			outreachAlertType: 'updatedDisableOutreach',
			updatedOutreachAlert: true,
		});
	}

	handleDoNotOutreach() {
		if (this.state.doNotOutreachDate && this.state.doNotOutreachReason) {
			this.setDoNotOutreach();
		} else {
			this.setState({ doNotOutreachErrorMessage: true });
		}
	}

	handleUpdatedDoNotOutreach() {
		if (this.canUpdateOutreach()) {
			this.doNotOutreach(
				get(this, 'state.doNotOutreachDate', null),
				this.state.enabledDoNotOutreach ? false : true,
				get(this, 'state.doNotOutreachReason', null),
				get(this, 'props.match.params.patientid', 0),
				get(this, 'state.doNotOutreachDeclinedVia', null)
			);
		}
	}

	canUpdateOutreach() {
		if (
			get(this, 'state.doNotOutreachReason', null) == 'other_disable_dno'
		) {
			return (
				get(this, 'state.doNotOutreachDate', null) &&
				isValidDateTimeString(
					get(this, 'state.doNotOutreachDate', null),
					dateTimeFormats
				) &&
				get(this, 'state.doNotOutreachReason', null) &&
				get(this, 'state.doNotOutreachDeclinedVia', null) &&
				get(this, 'state.disabledDoNotOutreachReasonNotes', null)
			);
		} else {
			return (
				get(this, 'state.doNotOutreachDate', null) &&
				isValidDateTimeString(
					get(this, 'state.doNotOutreachDate', null),
					dateTimeFormats
				) &&
				get(this, 'state.doNotOutreachReason', null) &&
				get(this, 'state.doNotOutreachDeclinedVia', null)
			);
		}
	}

	setDoNotOutreach() {
		this.setState({ loadingAlert: true });
		const patient = get(this, 'props.patientdetail[0]', {});
		const obj = {};
		obj.patient_id = patient.uuid;
		const data = {};
		data.do_not_outreach = true;
		data.outreach_reason = this.state.doNotOutreachReason;
		data.outreach_date = moment(this.state.doNotOutreachDate).format(
			'YYYY-MM-DD'
		);
		obj.payload = data;
		this.props.updateOutreach(obj);
	}

	closeOutreachAlert() {
		this.setState({ outreachAlert: false });
		this.setState({ outreachAlertType: null });
		this.setState({ outreachAlertUuid: null });
		this.setState({ doNotOutreachReason: null });
		this.setState({ doNotOutreachDate: null });
		this.setState({ doNotOutreachErrorMessage: false });
		this.setState({ updatedOutreachAlert: false });
		this.setState({ doNotOutreachDeclinedVia: null });
		this.setState({ disabledDoNotOutreachReasonNotes: null });
	}

	closeInitialNoOutreachAlert() {
		this.setState({ initialNoOutreachAlert: false });
	}

	doNotOutreach(
		dateTime,
		enabled = false,
		reason,
		patient_uuid,
		via,
		setlog = false
	) {
		if (!patient_uuid)
			patient_uuid = get(this, 'props.match.params.patientid', 0);
		if (dateTime && reason) {
			if (isValidDateTimeString(dateTime, dateTimeFormats)) {
				const momentDateTimeObj = getMomentDateTimeOrString(
					dateTime,
					dateTimeFormats
				);
				const utcString = momentDateTimeObj
					.utc()
					.format('YYYY-MM-DD HH:mm:ss');
				if (utcString !== 'Invalid date') {
					let payload = {};
					payload = {
						date_time: utcString,
						enabled: enabled,
						reason: reason,
						via: via,
					};
					if (
						get(this, 'state.doNotOutreachReason', null) ==
						'other_disable_dno'
					) {
						payload = {
							...payload,
							notes: get(
								this,
								'state.disabledDoNotOutreachReasonNotes',
								null
							),
						};
					}
					this.props.doNotOutreach(payload, patient_uuid);
					payload = {
						date_time: utcString,
						contact_method: via,
						type: 'referral_outreach',
						outcome: 'patient_declined',
						class: 'outreach',
					};
					if (setlog)
						this.props.addOutreachUponCreate(
							payload,
							patient_uuid,
							false
						);
				}
			}
		}
	}

	render() {
		const patient = get(this, 'props.patientdetail[0]', {});
		const uuid = get(this, 'props.match.params.patientid', 0);
		if (
			this.state.loading ||
			get(this, 'props.gettingAffiliations', null) ||
			get(this, 'props.gettingContactOutcomes', null) ||
			get(this, 'props.outreachEnumsLoading', null) ||
			get(this, ['props', 'loading_outrechSetting'], false) ||
			get(this, 'props.outreachDeclinedEnumLoading', null) ||
			get(this, 'props.patientTagsLoading', false) ||
			get(this, 'props.ethnicityEnumLoading', false) ||
			get(this, 'props.raceEnumLoading', false)
		) {
			return (
				<Loading
					loading={true}
					className='patient_detail_loader'
					style={{ background: 'transparent' }}
				></Loading>
			);
		} else {
			return (
				<>
					<AppointmentErrorDialog
						showErrorDialog={this.state.showErrorDialog}
						errorCloseHandler={this.hideErrorDialog}
						errorTitle={'Appointment Cancellation Failed'}
						initialErrorMessage={
							'We were unable to schedule this appointment due to '
						}
						messages={_.get(this, ['state', 'errormessages'], [])}
					/>
					<div className='patient_detail pt-marg-btm patientbox-shadow-no paymentlist'>
						{(this.state.appointmentAlert ||
							this.state.outreachAlert ||
							this.state.initialNoOutreachAlert ||
							this.state.updatedOutreachAlert) && (
							<div className='appointmentAlertBackground'>
								{this.renderAlert()}
							</div>
						)}
						<PatientInfo
							{...this.props}
							enqueueSnackbar={this.props.enqueueSnackbar}
							patient={patient}
							getPatientDetail={this.getPatientDetail}
							gettingPatientDetail={
								this.props.gettingPatientDetail
							}
							patientdetailstate={{ ...this.state }}
							resetPatientDetail={this.resetPatientDetail}
							getReferralReasons={this.props.getReferralReasons}
							getReferralProviders={
								this.props.getReferralProviders
							}
							referralReasons={this.props.referralReasons}
							referralProviders={this.props.referralProviders}
							consenttotreat={this.props.consenttotreat}
							renderPatientEdit={this.renderPatientEdit}
							hidePatientEdit={this.hidePatientEdit}
							tabFreeze={this.tabFreeze}
							tabUnfreeze={this.tabUnfreeze}
							referralPrograms={this.props.referralPrograms}
							noOutreachRequested={get(
								this,
								'state.noOutreachRequested',
								false
							)}
							openDateTimeDisabledOutreach={
								this.openDateTimeDisabledOutreach
							}
							updateOutreach={this.props.updateOutreach}
							updatingOutreach={this.props.updatingOutreach}
							updatingOutreachError={
								this.props.updatingOutreachError
							}
							updateAddress={this.props.updateAddress}
							updatingAddress={this.props.updatingAddress}
							updatingAddressError={
								this.props.updatingAddressError
							}
							updateRelationship={this.props.updateRelationship}
							updatingRelationship={
								this.props.updatingRelationship
							}
							updatedRelationshipError={
								this.props.updatedRelationshipError
							}
							eligibilityFields={this.props.eligibilityFields}
							setPassword={this.setPassword}
							settingPassword={this.state.settingPassword}
							settingPasswordSuccess={
								this.state.settingPasswordSuccess
							}
							affiliations={get(this, 'props.affiliations', {
								data: [],
							})}
							gettingAffiliations={this.props.gettingAffiliations}
							clearPatientEditErrors={
								this.props.clearPatientEditErrors
							}
							getEligibilityFields={
								this.props.getEligibilityFields
							}
							eligibilityFieldsLoading={
								this.props.eligibilityFieldsLoading
							}
							getReferralProgramFromDisplayName={
								this.getReferralProgramFromDisplayName
							}
							activateDoNoResetPatient={
								this.activateDoNoResetPatient
							}
							hasPriorityTag={get(
								this,
								'state.hasPriorityTag',
								null
							)}
							hasPriorityTagLanguage={get(
								this,
								'state.hasPriorityTagLanguage',
								null
							)}
							hasVIPTag={get(this, 'state.hasVIPTag', null)}
							hasLanguageLineSupportTag={get(
								this,
								'state.hasLanguageLineSupportTag',
								null
							)}
							hasLanguageLineSupportTagLanguage={get(
								this,
								'state.hasLanguageLineSupportTagLanguage',
								null
							)}
							hasSpeechImpairedTag={get(
								this,
								'state.hasSpeechImpairedTag',
								null
							)}
							hashearingImpairedTag={get(
								this,
								'state.hashearingImpairedTag',
								null
							)}
							hasVisionImpairedTag={get(
								this,
								'state.hasVisionImpairedTag',
								null
							)}
							hasFinancialAssistanceTag={get(
								this,
								'state.hasFinancialAssistanceTag',
								null
							)}
							hasFinancialAssistancePerVisitTag={get(
								this,
								'state.hasFinancialAssistancePerVisitTag',
								null
							)}
							hasDeceasedTag={get(
								this,
								'state.hasDeceasedTag',
								null
							)}
							hasDischargedTag={get(
								this,
								'state.hasDischargedTag',
								null
							)}
							hasMarketingOptoutTag={get(
								this,
								'state.hasMarketingOptoutTag',
								null
							)}
							hasNoFurtherEscalationTag={get(
								this,
								'state.hasNoFurtherEscalationTag',
								null
							)}
							hasResearchOptoutTag={get(
								this,
								'state.hasResearchOptoutTag',
								null
							)}
							hasPatientStoryTag={get(
								this,
								'state.hasPatientStoryTag',
								null
							)}
							hasDuplicatePatientTag={get(
								this,
								'state.hasDuplicatePatientTag',
								null
							)}
							duplicatePatients={get(
								this,
								'state.duplicatePatients',
								[]
							)}
							gender={this.props.gender}
							sexAssignedAtBirth={this.props.sexAssignedAtBirth}
							pronouns={this.props.pronouns}
							providerSpecialties={
								get(this, 'props.providerSpecialties', null) ||
								[]
							}
							raceEnum={get(this, 'props.raceEnum', null) || []}
							ethnicityEnum={
								get(this, 'props.ethnicityEnum', null) || []
							}
						/>
						<PatientInfoTab
							{...this.props}
							uuid={uuid}
							selectedPatient={get(
								this,
								'props.patientdetail',
								{}
							)}
							patientdetailstate={{ ...this.state }}
							contactMethods={this.props.contactMethods}
							gettingContactMethods={
								this.props.gettingContactMethods
							}
							contactOutcomes={this.props.contactOutcomes}
							gettingContactOutcomes={
								this.props.gettingContactOutcomes
							}
							contactOutcomesError={
								this.props.contactOutcomesError
							}
							gettingPatientDetail={
								this.props.gettingPatientDetail
							}
							getPatientDetail={this.getPatientDetail}
							updateOutreach={this.props.updateOutreach}
							updatingOutreach={this.props.updatingOutreach}
							updatingOutreachError={
								this.props.updatingOutreachError
							}
							updatingOutreach1={this.props.updatingOutreach1}
							updatingOutreachError1={
								this.props.updatingOutreachError1
							}
							updatingOutreach2={this.props.updatingOutreach2}
							updatingOutreachError2={
								this.props.updatingOutreachError2
							}
							updatingOutreach3={this.props.updatingOutreach3}
							updatingOutreachError3={
								this.props.updatingOutreachError3
							}
							width={this.state.infoTabWidth}
							tabFrozen={this.state.tabFrozen}
							openAppointmentAlert={this.openAppointmentAlert}
							openOutreachAlert={this.openOutreachAlert}
							encounterCompleteLoading={
								this.props.encounterCompleteLoading
							}
							encounterCompleteError={
								this.props.encounterCompleteError
							}
							encounterCancelLoading={
								this.props.encounterCancelLoading
							}
							encounterEditLoading={
								this.props.encounterEditLoading
							}
							encounterEditError={this.props.encounterEditError}
							editEncounter={this.props.editEncounter}
							getAssociatedBillingEncounter={
								this.props.getBillingEncounter
							}
							associatedBillingEncounter={
								this.props.associatedBillingEncounter
							}
							associatedBillingEncounterLoading={
								this.props.associatedBillingEncounterLoading
							}
							associatedBillingEncounterError={
								this.props.associatedBillingEncounterError
							}
							editBillingEncounter={
								this.props.editBillingEncounter
							}
							billingEncounter={this.props.billingEncounter}
							billingEncounterEditLoading={
								this.props.billingEncounterEditLoading
							}
							billingEncounterEditError={
								this.props.billingEncounterEditError
							}
							completeEncounter={this.props.completeEncounter}
							closeAlert={this.closeAlert}
							noOutreachReasons={this.props.noOutreachReasons}
							outreachEnums={
								get(this, 'props.outreachEnums', {}) || {}
							}
							affiliations={get(this, 'props.affiliations', {
								data: [],
							})}
							gettingAffiliations={this.props.gettingAffiliations}
							creatingAthenaPatient={
								this.props.creatingAthenaPatient
							}
							createdAthenaPatient={
								this.props.createdAthenaPatient
							}
							createdAthenaPatientError={
								this.props.createdAthenaPatientError
							}
							createAthenaPatientAndInsurance={
								this.props.createAthenaPatientAndInsurance
							}
							icdCodes={this.props.icdCodes}
							icdCodesLoading={this.props.icdCodesLoading}
							icdCodesError={this.props.icdCodesError}
							icdCodesPlacement={this.props.icdCodesPlacement}
							getICDCodes={this.props.getICDCodes}
							providerSpecialties={
								get(this, 'props.providerSpecialties', null) ||
								[]
							}
							drugExposures={
								get(this, 'props.drugExposures', null) || []
							}
							drugExposuresLoading={get(
								this,
								'props.drugExposureLoading',
								false
							)}
						/>
					</div>
				</>
			);
		}
	}
}

const mapStateToProps = (state, props) => {
	return {
		patientdetail: state.patientdetail.patientdetail,
		referralPrograms: state.referralPrograms.referralPrograms.data,
		gettingReferralPrograms: state.referralPrograms.gettingReferralPrograms,
		referralProgramsError: state.referralPrograms.referralProgramsError,
		gettingPatientDetail: state.patientdetail.gettingPatientDetail,
		encounter_list: state.encounterlist.encounter,
		consenttotreat: get(
			state,
			['getConsenttotreat', 'consenttotreat', 'consent_to_treat'],
			[]
		),
		contactMethods: state.contactMethods.contactMethods.data,
		gettingContactMethods: state.contactMethods.gettingContactMethods,
		contactOutcomes: state.contactOutcomes.contactOutcomes,
		gettingContactOutcomes: state.contactOutcomes.gettingContactOutcomes,
		contactOutcomesError: state.contactOutcomes.contactOutcomesError,
		referralReasons: state.referralReasons.referralReasons,
		referralProviders: state.referralProviders.referralProviders,
		outreachEnums: state.outreach.outreachEnums,
		outreachEnumsLoading: state.outreach.outreachEnumsLoading,
		outreachEnumsError: state.outreach.outreachEnumsError,
		outreachDeclinedEnumLoading: state.outreach.outreachDeclinedEnumLoading,
		outreachDeclinedEnum: state.outreach.outreachDeclinedEnum,
		outreachDeclinedEnumError: state.outreach.outreachDeclinedEnumError,
		addedOutreach: state.outreach.addedOutreach,
		settingDoNotOutreach: state.outreach.settingDoNotOutreach,
		setDoNotOutreach: state.outreach.setDoNotOutreach,
		setDoNotOutreachError: state.outreach.setDoNotOutreachError,
		updatingOutreach: state.editpatient.updatingOutreach,
		updatingOutreachError: state.editpatient.updatingOutreachError,
		updatingOutreach1: state.editpatient.updatingOutreach1,
		updatingOutreachError1: state.editpatient.updatingOutreachError1,
		updatingOutreach2: state.editpatient.updatingOutreach2,
		updatingOutreachError2: state.editpatient.updatingOutreachError2,
		updatingOutreach3: state.editpatient.updatingOutreach3,
		updatingOutreachError3: state.editpatient.updatingOutreachError3,
		updatingAddress: state.editpatient.updatingAddress,
		updatingAddressError: state.editpatient.updatedAddressError,
		updatingRelationship: state.editpatient.updatingRelationship,
		updatedRelationshipError: state.editpatient.updatedRelationshipError,
		encounterloading: get(state, 'encounterlist.loading', false),
		encounterCompleteLoading: state.editencounter.encounterCompleteLoading,
		encounterCompleteError: state.editencounter.encounterCompleteError,
		encounterCancelLoading: state.editencounter.encounterCancelLoading,
		encounterEditLoading: state.editencounter.encounterEditLoading,
		encounterEditError: state.editencounter.encounterEditError,
		associatedBillingEncounter: _.get(
			state,
			['getBillingEncounterData', 'billingobject', 'data', '0'],
			{}
		),
		associatedBillingEncounterLoading:
			state.getBillingEncounterData.billingobjectLoading,
		associatedBillingEncounterError:
			state.getBillingEncounterData.billingobjectError,
		billingEncounter: state.editencounter.billingEncounter,
		billingEncounterEditLoading:
			state.editencounter.billingEncounterEditLoading,
		billingEncounterEditError:
			state.editencounter.billingEncounterEditError,
		eligibilityFields: get(
			state,
			['eligibilityFields', 'eligibilityFields'],
			[]
		),
		eligibilityFieldsLoading:
			state.eligibilityFields.eligibilityFieldsLoading,
		setPasswordResponse: get(state, ['setpassword'], {}),
		gettingOutreachReasons: state.noOutreach.gettingOutreachReasons,
		noOutreachReasons: state.noOutreach.noOutreachReasons,
		insuranceCompanies: _.get(
			state,
			['insuranceCompanies', 'insuranceCompanies'],
			[]
		),
		affiliations: state.provideraffiliations.provideraffiliations,
		gettingAffiliations:
			state.provideraffiliations.gettingProviderAffiliations,
		creatingAthenaPatient: state.createAthenaPatient.creatingAthenaPatient,
		createdAthenaPatient: state.createAthenaPatient.createdAthenaPatient,
		createdAthenaPatientError:
			state.createAthenaPatient.createdAthenaPatientError,
		icdCodes: state.icdCodes.icdCodes,
		icdCodesLoading: state.icdCodes.icdCodesLoading,
		icdCodesError: state.icdCodes.icdCodesError,
		icdCodesPlacement: state.icdCodes.icdCodesPlacement,
		loading_outrechSetting: get(state, [
			'outreach',
			'loading_outrechSetting',
		]),
		outreachSetting: state.outreach.outreachSetting,
		outreachSettingError: state.outreach.outreachSettingError,
		linkedProviders: _.get(
			state,
			['linkedpatientproviders', 'linkedProviders'],
			[]
		),
		patientTagsLoading: get(state, 'patientTags.patientTagsLoading', false),
		patientTags: get(state, 'patientTags.patientTags', []) || [],
		patientTagsError: get(state, 'patientTags.patientTagsError', {}),
		gender: get(state, 'gender.gender.gender', []),
		pronouns: get(state, ['pronouns', 'pronouns', 'pronouns'], []),
		sexAssignedAtBirth: get(
			state,
			[
				'sexAssignedAtBirth',
				'sexAssignedAtBirth',
				'sex_assigned_at_birth',
			],
			[]
		),
		gettingProviderSpecialties: _.get(
			state,
			'providerEnums.gettingProviderSpecialties',
			false
		),
		providerSpecialties:
			_.get(state, 'providerEnums.providerSpecialties', null) || [],
		providerSpecialtiesError: _.get(
			state,
			'providerEnums.providerSpecialtiesError',
			null
		),
		drugExposures: _.get(state, 'drugExposures.drugExposures', null) || [],
		drugExposureLoading: _.get(
			state,
			'drugExposures.drugExposureLoading',
			false
		),
		drugExposureError: _.get(
			state,
			'drugExposures.drugExposureError',
			false
		),
		raceEnumLoading: _.get(state, 'raceEnum.raceEnumLoading', false),
		raceEnum: _.get(state, 'raceEnum.raceEnum', null) || [],
		raceEnumError: _.get(state, 'raceEnum.raceEnumError', null),
		ethnicityEnumLoading: _.get(
			state,
			'ethnicityEnum.ethnicityEnumLoading',
			false
		),
		ethnicityEnum: _.get(state, 'ethnicityEnum.ethnicityEnum', null) || [],
		ethnicityEnumError: _.get(
			state,
			'ethnicityEnum.ethnicityEnumError',
			null
		),
	};
};

const mapDispatchToProps = (dispatch) => ({
	getpatientdetail: (pid, flag) => dispatch(patientdetail(pid, flag)),
	getRefPrograms: () => dispatch(getReferralPrograms()),
	resetPatientDetail: () => dispatch(resetPatientInfo()),
	getEligibilityFields: (data) => dispatch(getEligibilityFields(data)),
	getencouter: (pid) => dispatch(getencounters(pid)),
	callConsenttotreat: () => dispatch(getConsenttotreat()),
	getContactMethods: () => dispatch(getContactMethods()),
	getContactOutcomes: () => dispatch(getContactOutcomes()),
	getReferralReasons: () => dispatch(getReferralReasons()),
	getReferralProviders: () => dispatch(getReferralProviders()),
	updateOutreach: (data) => dispatch(updateOutreach(data)),
	getOutreachEnums: () => dispatch(getOutreachEnums()),
	editEncounter: (data) => dispatch(editencounter(data, true)),
	getBillingEncounter: (encounter_uuid) =>
		dispatch(getBillingEncounterDetail(encounter_uuid)),
	editBillingEncounter: (data) => dispatch(saveBillingEncounter(data)),
	updateAddress: (data) => dispatch(updateAddress(data)),
	updateRelationship: (data) => dispatch(updateRelationship(data)),
	completeEncounter: (data) => dispatch(completeEncounter(data)),
	cancelEncounter: (data) => dispatch(cancelEncounter(data)),
	setPassword: (data) => dispatch(setPassword(data)),
	getNoOutreachReasons: () => dispatch(getNoOutreachReasons()),
	getInsuranceCompanies: (data) => dispatch(getInsuranceCompanies(data)),
	getAffiliations: () => dispatch(getaffiliation()),
	clearPatientEditErrors: () => dispatch(clearAllPatientEditErrors()),
	createAthenaPatientAndInsurance: (uuid) =>
		dispatch(createAthenaPatient(uuid)),
	getICDCodes: (query, offset, limit, topOrBottom) =>
		dispatch(getICDCodes(query, offset, limit, topOrBottom)),
	addOutreachUponCreate: (data, uuid, loading) =>
		dispatch(addOutreachUponCreate(data, uuid, loading)),
	doNotOutreach: (data, patient_uuid) =>
		dispatch(doNotOutreach(data, patient_uuid)),
	GetOutreachSetting: (patient_uuid) =>
		dispatch(getOutreachSetting(patient_uuid)),
	ResetOutreachSetting: () => dispatch(dispatch_ResetOutreachSetting()),
	getOutreachDeclinedEnum: () => dispatch(getOutreachDeclinedEnum()),
	getLinkedProviders: (patient_uuid) =>
		dispatch(getLinkedProviders(patient_uuid)),
	getPatientTags: () => dispatch(getPatientTags()),
	getGenderEnum: () => dispatch(getGender()),
	getPronounsEnum: () => dispatch(getPronouns()),
	getSexAssignedAtBirthEnum: () => dispatch(getSexAssignedAtBirth()),
	getProviderSpecialties: () => dispatch(getProviderSpecialties()),
	getPatientDrugExposures: (uuid) => dispatch(getPatientDrugExposures(uuid)),
	getRaceEnum: () => dispatch(getRaceEnum()),
	getEthnicityEnum: () => dispatch(getEthnicityEnum()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
