//React & Redux
import React, { useState } from 'react';

//Images

//Other Libraries
import classnames from 'classnames';

import { Dialog, Button, Layout, Popover } from 'gm-element-react';
import { isEmpty, isNil, orderBy } from 'lodash';
import moment from 'moment';

//Images
import ReactSVG from 'react-svg';
import editIcon from '../../../../assets/edit.svg';
import moreinfoicon from './../../../../assets/purple-more-info.svg';

//Styles
import './css/view-discrete-variant-full-detail.css';

//Utils
import { CARE_PATIENTS_ENTER_TEST_RESULT } from '../../../../Utils/permissionUtils';
import {
	originEnum,
	dnaChangeTypeEnum,
	proteinChangeTypeEnum,
	genomicSourceClassEnum,
	allelicStateEnum,
	labClassificationEnum,
	mosaicismEnum,
} from '../../../../utils';

//Components
import Icon from '../../../Common/Icon';
import { Fragment } from 'react';
import RBAC from '../../../RBAC';

const ViewDiscreteVariantFullDetail = (props) => {
	const {
		showAddEditDiscreteVariantModalFunc,
		hideViewDiscreteVariantFullDetailFunc,
		discreteVariantFindings,
	} = props;

	const closeViewDiscreteVariantFullDetail = () => {
		hideViewDiscreteVariantFullDetailFunc();
	};

	const showEditDiscreteVariantModalFunc = () => {
		closeViewDiscreteVariantFullDetail();
		showAddEditDiscreteVariantModalFunc('edit', discreteVariantFindings);
	};

	const getHgvsDNAChangeText = () => {
		let dnaChangeText = '--';
		let dnaChangeType = '';

		if (!isNil(discreteVariantFindings.dnaChangeType)) {
			dnaChangeType = dnaChangeTypeEnum.find(
				(o) => o.key === discreteVariantFindings?.dnaChangeType || ''
			);
			dnaChangeType = '  (' + dnaChangeType?.display_name + ') ';
		}

		if (!isNil(discreteVariantFindings.referenceSequence)) {
			dnaChangeText =
				discreteVariantFindings.referenceSequence +
				':' +
				discreteVariantFindings.dnaChangeDescription +
				dnaChangeType;
		}
		return dnaChangeText;
	};

	const getHgvsProteinChangeText = () => {
		let proteinChangeText = '--';
		let proteinChangeType = '';

		if (
			!isNil(discreteVariantFindings.proteinChangeType) &&
			!isEmpty(discreteVariantFindings.proteinChangeType)
		) {
			proteinChangeType = proteinChangeTypeEnum.find(
				(o) => o.key === discreteVariantFindings?.proteinChangeType
			);
			proteinChangeType = '  (' + proteinChangeType?.display_name + ') ';
		}

		if (!isNil(discreteVariantFindings.proteinChangeDescription)) {
			proteinChangeText =
				discreteVariantFindings.proteinChangeDescription +
				proteinChangeType;
		}
		return proteinChangeText;
	};

	const getProteinChangeType = () => {
		let proteinChangeType = '--';

		if (!isNil(discreteVariantFindings.proteinChangeType)) {
			proteinChangeType = proteinChangeTypeEnum.find(
				(o) => o.key === discreteVariantFindings?.proteinChangeType
			);
			proteinChangeType = proteinChangeType?.display_name;
		}
		return proteinChangeType;
	};

	const getDNAChangeType = () => {
		let dnaChangeType = '--';

		if (!isNil(discreteVariantFindings.dnaChangeType)) {
			dnaChangeType = dnaChangeTypeEnum.find(
				(o) => o.key === discreteVariantFindings?.dnaChangeType || ''
			);
			dnaChangeType = dnaChangeType?.display_name;
		}
		return dnaChangeType;
	};

	const hasHgvsNomenclature =
		discreteVariantFindings?.hasHgvsNomenclature || false;

	const getSortedInterpretations = () => {
		if (Array.isArray(discreteVariantFindings?.interpretations)) {
			return orderBy(
				discreteVariantFindings?.interpretations || [],
				function (p) {
					return new moment(p.interpretedOn);
				},
				['desc']
			);
		}
		return [];
	};

	const getInterpretationText = () => {
		let interpretationText = '--';
		let classification = '--';
		let interpretedOn = null;
		let name = '';
		const sortedInterpretations = getSortedInterpretations();

		sortedInterpretations.map((interpretations, idx) => {
			classification = labClassificationEnum.find(
				(o) => o.key === interpretations.classification
			);
			classification = classification?.display_name;
			interpretedOn = moment(
				interpretations.interpretedOn || null
			).isValid()
				? moment(interpretations.interpretedOn).format('MM.DD.YYYY')
				: '';
			name = interpretations.source?.name;

			interpretationText =
				classification + ' | ' + interpretedOn + ' | ' + name;
			return interpretationText;
		});
		return interpretationText;
	};

	const getOrigin = () => {
		const origin = originEnum.find(
			(o) => o.key === discreteVariantFindings?.origin
		);
		return origin?.display_name || '--';
	};

	const getGenomicSourceClass = () => {
		const genomicSourceClass = genomicSourceClassEnum.find(
			(o) => o.key === discreteVariantFindings?.genomicSourceClass
		);
		return genomicSourceClass?.display_name || '--';
	};

	const getMosaicism = () => {
		const mosaicism = mosaicismEnum.find(
			(o) => o.key === discreteVariantFindings?.mosaicism
		);
		return mosaicism?.display_name || '--';
	};

	const getAllelicState = () => {
		let allelicState = '--';

		if (!isNil(discreteVariantFindings?.allelicState)) {
			allelicState = allelicStateEnum.find(
				(o) => o.key === discreteVariantFindings?.allelicState
			);
			allelicState = allelicState?.display_name;
		}

		return allelicState;
	};

	return (
		<Dialog
			customClass='view-discrete-variant-full-detail-dialog'
			title={
				<span className='header'>
					<span>
						Discrete Variant Finding -{' '}
						{discreteVariantFindings?.genesSymbol?.symbol || '--'}
					</span>
					<Popover
						trigger='hover'
						popperClass={classnames('pp-discrete-variant')}
						content={
							<Fragment>
								<span style={{ display: 'block' }}>
									Name:{' '}
									{discreteVariantFindings?.genesSymbol
										?.name || '--'}
								</span>
								<span style={{ display: 'block' }}>
									Aliases:{' '}
									{(
										discreteVariantFindings?.genesSymbol
											?.aliasSymbols || []
									).join(', ')}
								</span>
								<span style={{ display: 'block' }}>
									Cytogenetic location:{' '}
									{discreteVariantFindings?.genesSymbol
										?.cytogeneticLocation || '--'}
								</span>
							</Fragment>
						}
						key='popover-discrete-variant'
						placement='top'
					>
						<span className='tooltip-info'>
							<img
								src={moreinfoicon}
								className='more-info-icon'
							/>
						</span>
					</Popover>
					<span
						className='close-btn-icon'
						onClick={closeViewDiscreteVariantFullDetail}
					>
						<Icon icon='close' className='close-icon' />
					</span>
				</span>
			}
			size='tiny'
			modal={true}
			closeOnPressEscape={true}
			closeOnClickModal={false}
			showClose={false}
			visible={true}
			onCancel={closeViewDiscreteVariantFullDetail}
		>
			<Dialog.Body>
				<Layout.Row gutter='26'>
					{hasHgvsNomenclature ? (
						<Fragment>
							<Layout.Col span='8'>
								<span className='field-label'>DNA Change</span>
								<span
									className='field-value'
									style={{ wordBreak: 'break-word' }}
								>
									{getHgvsDNAChangeText()}
								</span>
							</Layout.Col>
							<Layout.Col span='8'>
								<span className='field-label'>
									Protein Change
								</span>
								<span className='field-value'>
									{getHgvsProteinChangeText()}
								</span>
							</Layout.Col>
						</Fragment>
					) : (
						<Layout.Col span='16'>
							<span className='field-label'>
								Cytogenomic Nomenclature (ISCN)
							</span>
							<span
								className='field-value'
								style={{ wordBreak: 'break-word' }}
							>
								{discreteVariantFindings?.sequence || '--'}
							</span>
						</Layout.Col>
					)}
					<Layout.Col span='8'>
						<span className='field-label'>Allelic State</span>
						<span className='field-value'>{getAllelicState()}</span>
					</Layout.Col>
				</Layout.Row>

				<Layout.Row gutter='26'>
					<Layout.Col span='8'>
						<span className='field-label'>Interpretation</span>
						<span className='field-value'>
							{getInterpretationText()}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='field-label'>Reference Assembly</span>
						<span className='field-value'>
							{discreteVariantFindings?.referenceAssembly?.name ||
								'--'}
						</span>
					</Layout.Col>
					{!hasHgvsNomenclature && (
						<Layout.Col span='8'>
							<span className='field-label'>DNA Change Type</span>
							<span className='field-value'>
								{getDNAChangeType()}
							</span>
						</Layout.Col>
					)}
				</Layout.Row>

				{!hasHgvsNomenclature && (
					<Layout.Row gutter='26'>
						<Layout.Col span='8'>
							<span className='field-label'>
								Protein Change Type
							</span>
							<span className='field-value'>
								{getProteinChangeType()}
							</span>
						</Layout.Col>
					</Layout.Row>
				)}

				<Layout.Row gutter='26' className='divide-section'>
					<Layout.Col span='8'>
						<span className='field-label'>Origin</span>
						<span className='field-value'>{getOrigin()}</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='field-label'>
							Genomic Source Class
						</span>
						<span className='field-value'>
							{getGenomicSourceClass()}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='field-label'>Mosaicism</span>
						<span className='field-value'>{getMosaicism()}</span>
					</Layout.Col>
				</Layout.Row>

				<Layout.Row
					gutter='26'
					className={
						isEmpty(discreteVariantFindings?.genomicSourceClass) &&
						isEmpty(discreteVariantFindings?.origin) &&
						isEmpty(discreteVariantFindings?.mosaicism)
							? 'divide-section'
							: ''
					}
				>
					<Layout.Col span='8'>
						<span className='field-label'>Allelic Frequency</span>
						<span className='field-value'>
							{discreteVariantFindings?.sampleAllelicFrequency ||
								'--'}
						</span>
					</Layout.Col>
				</Layout.Row>
			</Dialog.Body>

			<Dialog.Footer className='dialog-footer'>
				<RBAC
					action={CARE_PATIENTS_ENTER_TEST_RESULT}
					yes={
						<Button
							className='edit-detail-button'
							onClick={showEditDiscreteVariantModalFunc}
						>
							<ReactSVG
								src={editIcon}
								className='edit-details-icon'
							/>
							<span>Edit Original</span>
						</Button>
					}
				/>
			</Dialog.Footer>
		</Dialog>
	);
};

export default ViewDiscreteVariantFullDetail;
