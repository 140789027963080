//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import TemplateModalBasic from './TemplateModalBasic.js';
import TemplateModalFinalize from './TemplateModalFinalize.js';
import TemplateModalReferralPrograms from './TemplateModalReferralPrograms.js';
import TemplateModalEditReferralPrograms from './EditReferralPrograms/TemplateModalEditReferralPrograms.js';
import TemplateModalChangeType from './TemplateModalChangeType.js';

const TemplateModals = (props) => {
	const {
		archiveTemplate,
		archivingTemplate,
		batchUpdatingTemplatePartners,
		batchUpdatingTemplateTypes,
		changeCurrentTemplateType,
		changeSelectedTemplateType,
		changeTemplateName,
		changeTemplateType,
		changeTemplateTypeLoading,
		changeTypeSelectedType,
		closeAllModals,
		closeFinalizeDraftModal,
		closeReferralProgramEditModal,
		closeReferralProgramModal,
		creatingTemplate,
		duplicateTemplate,
		editingTemplate,
		finalizeDraftSelectedName,
		finalizeDraftSelectedType,
		finalizeTemplate,
		getArrOfOwnerUuids,
		getRefProgramFromUuid,
		getTemplateFromUuid,
		getTypeFromUuid,
		handleBatchUpdateTemplatePartners,
		referralProgramModalNameList = [],
		referralProgramModalTemplate = {},
		referralProgramModalTemplateName = '--',
		referralPrograms = [],
		selectedTemplates = [],
		showArchiveDraftModal,
		showChangeTemplateTypeModal,
		showDuplicateTemplateModal,
		showEditReferralProgramsModal,
		showEditTemplateModal,
		showFinalizeDraftModal,
		showReferralProgramsModal,
		showTemplateDetail,
		singleSelectedTemplateUuid,
		templateTypes,
	} = props;

	const renderModal = () => {
		if (
			showEditTemplateModal ||
			showArchiveDraftModal ||
			showDuplicateTemplateModal
		) {
			return renderBasicModal();
		} else if (showFinalizeDraftModal) {
			return renderFinalizeModal();
		} else if (showEditReferralProgramsModal) {
			return renderEditReferralProgramsModal();
		} else if (showReferralProgramsModal) {
			return renderReferralProgramsModal();
		} else if (showChangeTemplateTypeModal) {
			return renderChangeTemplateTypeModal();
		} else {
			console.log('Unrecognized modal.');
		}
	};

	const renderBasicModal = () => {
		let title;
		let message;
		let loading;
		let func;
		const closeFunc = closeAllModals;
		if (showEditTemplateModal) {
			title = 'Edit Template';
			message =
				'Are you sure you want to edit this template?\n\nChanges get saved automatically and are available immediately. If you need more time to make your changes, you can always "Duplicate" to save as a draft.';
			loading = editingTemplate;
			func = showTemplateDetail;
		} else if (showArchiveDraftModal) {
			title = 'Archive Template';
			message = 'Are you sure you want to archive this template?';
			loading = archivingTemplate;
			func = archiveTemplate;
		} else if (showDuplicateTemplateModal) {
			title = 'Duplicate Template';
			message = 'Are you sure you want to duplicate this template?';
			loading = creatingTemplate;
			func = duplicateTemplate;
		} else {
			console.log('Unrecognized modal.');
			return;
		}
		return (
			<TemplateModalBasic
				title={title}
				message={message}
				loading={loading}
				func={func}
				close={closeFunc}
			/>
		);
	};

	const renderFinalizeModal = () => {
		return (
			<TemplateModalFinalize
				title={'Finalize Draft'}
				template={
					singleSelectedTemplateUuid ||
					get(selectedTemplates, '[0]', null) ||
					{}
				}
				templateTypes={templateTypes}
				templateType={finalizeDraftSelectedType}
				changeTemplateType={changeTemplateType}
				templateName={finalizeDraftSelectedName}
				changeTemplateName={changeTemplateName}
				finalizeDraft={finalizeTemplate}
				finalizeDraftLoading={editingTemplate}
				close={closeFinalizeDraftModal}
			/>
		);
	};

	const getPreselectedItems = () => {
		const currentlySelectedTemplates =
			singleSelectedTemplateUuid || selectedTemplates || [];
		const firstSelectedTemplateUuid =
			get(currentlySelectedTemplates, '[0]', {}) || {};
		const firstSelectedTemplate = getTemplateFromUuid(
			firstSelectedTemplateUuid
		);
		const preselectedItems = [];

		const templateOwnerUuids = getArrOfOwnerUuids(firstSelectedTemplate);
		if (Array.isArray(templateOwnerUuids)) {
			templateOwnerUuids.forEach((el) => {
				const refProgram = getRefProgramFromUuid(el);
				if (refProgram) preselectedItems.push(refProgram);
			});
		}
		return preselectedItems;
	};

	const determineIfAllInitial = () => {
		const currentlySelectedTemplates =
			singleSelectedTemplateUuid || selectedTemplates || [];
		const firstSelectedTemplateUuid = get(
			currentlySelectedTemplates,
			'[0]',
			null
		);
		if (firstSelectedTemplateUuid) {
			const firstSelectedTemplate = getTemplateFromUuid(
				firstSelectedTemplateUuid
			);
			if (get(firstSelectedTemplate, 'all_partners', null)) return true;
			else return false;
		} else return false;
	};

	const renderEditReferralProgramsModal = () => {
		return (
			<TemplateModalEditReferralPrograms
				title={'Edit Referral Programs'}
				buttonText={'Save'}
				loading={batchUpdatingTemplatePartners}
				items={referralPrograms || []}
				close={closeReferralProgramEditModal}
				func={handleBatchUpdateTemplatePartners}
				keyForSearch={'display_name'}
				preselectedItems={getPreselectedItems()}
				allInitial={determineIfAllInitial()}
			/>
		);
	};

	const renderReferralProgramsModal = () => {
		return (
			<TemplateModalReferralPrograms
				title={'Associated Referral Programs'}
				templateName={referralProgramModalTemplateName || '--'}
				referralPrograms={referralProgramModalNameList || []}
				template={referralProgramModalTemplate || {}}
				close={closeReferralProgramModal}
			/>
		);
	};

	const renderChangeTemplateTypeModal = () => {
		return (
			<TemplateModalChangeType
				title={'Change Template Type'}
				template={
					singleSelectedTemplateUuid ||
					get(selectedTemplates, '[0]', null) ||
					{}
				}
				templateTypes={templateTypes}
				templateType={changeTypeSelectedType}
				changeTemplateType={changeCurrentTemplateType}
				changeTemplateTypeLoading={changeTemplateTypeLoading}
				getTypeFromUuid={getTypeFromUuid}
				changeSelectedTemplateType={changeSelectedTemplateType}
				batchUpdatingTemplateTypes={batchUpdatingTemplateTypes}
				close={closeAllModals}
			/>
		);
	};

	if (
		showEditTemplateModal ||
		showArchiveDraftModal ||
		showDuplicateTemplateModal ||
		showFinalizeDraftModal ||
		showEditReferralProgramsModal ||
		showReferralProgramsModal ||
		showChangeTemplateTypeModal
	) {
		return <div className='popupModal'>{renderModal()}</div>;
	} else {
		return '';
	}
};

export default TemplateModals;
