//React & Redux
import React from 'react';
import PropTypes from 'prop-types';

//Images
import previousAppointment from '../../assets/previous-appointment.svg';
import phoneicon from '../../assets/quick-view-phone.svg';
import videoicon from '../../assets/video-modality.svg';

//Lodash
import _ from 'lodash';

//Other Libraries
import moment from 'moment';

class SummaryHistory extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lastEncounter: null,
		};
		//bindings
		this.getspecialty = this.getspecialty.bind(this);
		this.getModalityIcon = this.getModalityIcon.bind(this);
	}
	//functions

	componentDidMount() {
		if (this.props.encounters.length > 0) {
			let encounters = this.props.encounters.slice();
			encounters = encounters.filter(
				(a) => a.type === 'visit' && a.visit_status === 'completed'
			);
			const completedEncounters = encounters.filter(
				(el) => !!el.date_of_service
			);
			const sortedEncounters = completedEncounters.sort((a, b) => {
				return (
					moment(a.date_of_service).toDate().getTime() -
					moment(b.date_of_service).toDate().getTime()
				);
			});
			const lastEncounter = sortedEncounters[sortedEncounters.length - 1];
			this.setState({ lastEncounter });
		}
	}
	getspecialty() {
		const encounter = _.get(this, 'state.lastEncounter', null);
		const selected = !_.isNil(_.get(encounter, ['vsee_specialty'], ''))
			? _.get(encounter, ['vsee_specialty'], '')
					.replace('_Willing', '')
					.replace('_willing', '')
			: '';
		const speciality = _.get(this, ['props', 'specialties'], []).find(
			(c) => c.key === selected
		);
		return _.get(speciality, 'display_name', '');
	}

	getModalityIcon = () => {
		const encounter = _.get(this, 'state.lastEncounter', null);
		let iconModality = '';

		if (_.get(encounter, 'tm_platform', '').toLowerCase() === 'phone') {
			iconModality = (
				<img src={phoneicon} className='aptmnt-smry-modality-icon' />
			);
		} else if (
			_.get(encounter, 'tm_platform', '').toLowerCase() === 'video'
		) {
			iconModality = (
				<img src={videoicon} className='aptmnt-smry-modality-icon' />
			);
		}
		return iconModality;
	};

	render() {
		const encounter = _.get(this, 'state.lastEncounter', null);

		if (encounter) {
			return (
				<React.Fragment>
					<div
						style={{
							width: '100%',
							height: '1px',
							backgroundColor: '#d8dde4',
						}}
					/>
					<div className='scheduleAppointmentSideContainerSection'>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								marginBottom: '20px',
							}}
						>
							<div
								style={{
									height: '28px',
									width: '28px',
									marginLeft: '-4px',
								}}
							>
								<img src={previousAppointment} alt='' />
							</div>
							<div
								style={{
									fontFamily: 'RionaSans-Bold',
									fontSize: '14px',
									color: '#525968',
									marginLeft: '8px',
								}}
							>
								LAST COMPLETED VISIT
							</div>
						</div>
						<div
							className='scheduleAppointmentSideContainerName'
							style={{ marginBottom: '3px' }}
						>
							{encounter.consultation_type ||
								encounter.type ||
								'--'}
						</div>
						<div className='scheduleAppointmentSideContainerPatientText'>
							{this.props.getTimestamp(encounter)}-{' '}
							<span style={{ fontFamily: 'RionaSans-Bold' }}>
								{encounter.state_of_visit || '--'}
							</span>
						</div>
						<div className='scheduleAppointmentSideContainerPatientText'>
							{this.getspecialty()}
						</div>
						<div
							className='scheduleAppointmentSideContainerPatientText scheduleAppointmentSideContainerRow'
							style={{ marginBottom: '3px', marginTop: '2px' }}
						>
							<div className='scheduleAppointmentSideContainerHalf'>
								{this.props.getProviderImage(encounter)}
								{this.props.getProviderName(encounter)}
							</div>
							<div className='scheduleAppointmentSideContainerHalf'>
								{this.getModalityIcon()}
								<span className='tm-platform'>
									{encounter.tm_platform || '--'}
								</span>
							</div>
						</div>
					</div>
				</React.Fragment>
			);
		} else {
			return '';
		}
	}
}

export default SummaryHistory;

SummaryHistory.propTypes = {
	encounters: PropTypes.array.isRequired,
	visitProviders: PropTypes.array.isRequired,
	getProviderImage: PropTypes.func.isRequired,
	getProviderName: PropTypes.func.isRequired,
	getModalityIcon: PropTypes.func.isRequired,
	getShortText: PropTypes.func.isRequired,
	ucFirstAllWords: PropTypes.func.isRequired,
	getTimestamp: PropTypes.func.isRequired,
};
