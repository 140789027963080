//React & Redux
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

//Lodash
import _ from 'lodash';

//Utils
import { providers_images, TestV2TabsIndex } from '../../../../utils';
import { order_status_parent_options as order_status_main_level } from '../../../../Utils/encounterUtils';
import {
	filterNonLegacyTestEnumes,
	MLOORderStatusHierarchyEnums,
	getOrderStatusDisplayName,
} from '../Encounters/Common/utilsfunctions';

//Actions & Services
import { getPatientTests } from './../../../../actions/tests';
import {
	getspecificencounterenum,
	getencountergroupsessionschema,
	getencounterccintakeschema,
	getencountervisitsschema,
	getencounterlabseschema,
	getencounterresearchdataschema,
	LoadEditTestOrderTestEnums,
	updateTestOrder,
	getGeneTestEnums,
	LoadEncounterDetailTestEnums,
	getTestOrderNotes,
	createTestOrderNote,
} from './../../../../actions/encounter';

//Components
import EditTestOrderModalContainer from '../TestOrders/EditTestOrderModalContainer';
import List from './List';
import TestDetail from './TestDetail';

const actionType = {
	list: 'list',
	view: 'view',
	edit: 'edit',
};

const orderStatusColorCode = {
	sent_to_lab: 'blue',
	results_ready: 'yellow',
	results_entered: 'green',
	sample_not_submitted: 'gray',
	order_cancelled_by_provider: 'red',
	order_cancelled_by_patient: 'red',
	order_cancelled_by_lab: 'red',
	patient_not_responsive: 'red',
	awaiting_information_patient: 'gray',
	awaiting_benefits_investigation: 'gray',
	awaiting_information_gc: 'gray',
	awaiting_birth: 'gray',
	awaiting_cc_submission: 'gray',
};

const editTestOrderEnums = [
	'approved',
	'waiting_to_submit',
	'sent_to_lab',
	'cancelled',
	'patient_not_responsive',
	'results_ready',
	'sample_not_submitted',
	'order_cancelled_by_provider',
	'order_cancelled_by_patient',
	'order_cancelled_by_lab',
	'awaiting_benefits_investigation',
	'awaiting_birth',
	'awaiting_information_gc',
	'awaiting_information_patient',
	'awaiting_cc_submission',
];

export const TestsTab = (props) => {
	const {
		actions: {
			callccintakeschema,
			callencountergroupsessionschema,
			callGeneTestEnums,
			calllabsschema,
			callresearchdataschema,
			callspecificencounterenum,
			callTestOrderNotes,
			callvisitsschema,
			getPatientTests,
			LoadTestEnums,
			LoadTestOrderPopupTestEnum = [],
			updateTestOrderModal,
		},
		ccintakeschema,
		groupsessionschema,
		laborderstatusenums = [],
		labsschema,
		MLOOrderStatus = [],
		nonlegacytestOrderPopupTestEnums = [],
		ordering_physician,
		outreachStatusEnums,
		parentProps: {
			changeEncounterTab,
			enqueueSnackbar,
			getICDCodes,
			goToTestTab,
			history,
			icdCodes = [],
			icdCodesError = null,
			icdCodesLoading = false,
			icdCodesPlacement = null,
			match,
			patientdetail,
			location,
			teststep,
			visitProvider,
		},
		patient,
		patientBillingMethodEnums = [],
		researchdataschema,
		sampleTypeEnums = [],
		testnameenums = [],
		testOrderNotes,
		tests,
		user = {},
		visitsschema,
	} = props;

	const [action, setAction] = useState(actionType.list);
	const [showModal, setShowModal] = useState(false);
	const [singleObj, setSingleObj] = useState({});
	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('date_test_ordered');
	const [limit, setLimit] = useState('25');
	const [patient_uuid, setPatientUuid] = useState('');

	useEffect(() => {
		callGeneTestEnums();
		callspecificencounterenum({
			type: 'visit',
			field_name: 'ordering_physician',
		});
	}, []);

	const hideModal = () => {
		setShowModal(false);
	};

	const showTestOrderModal = (obj) => {
		setShowModal(true);
		setSingleObj(obj);
		callspecificencounterenum({
			type: obj.encounterName,
			field_name: 'ordering_physician',
		});
		LoadTestEnums(obj.labUUID);
	};

	const getOrderStatusColor = (status) =>
		_.get(orderStatusColorCode, status, 'gray');

	const getRequestStatus = (value, statusReason, shortPatient = true) => {
		return getOrderStatusDisplayName(
			value,
			statusReason,
			MLOOrderStatus,
			shortPatient
		);
	};

	const viewTestOrder = (test_uuid, positiveResultsToReport = null) => {
		changeEncounterTab(test_uuid, TestV2TabsIndex.DETAIL, {
			TestDetail: { positiveResultsToReport },
		});
	};

	const updateTestOrderModalFunc = (
		test_order_uuid,
		data,
		encounterrequestdata = null,
		notedata
	) => {
		const promiseall = [];

		const promise1 = updateTestOrderModal(test_order_uuid, data);
		promiseall.push(promise1);
		if (!_.isEmpty(notedata)) {
			const promise2 = createTestOrderNote(test_order_uuid, notedata);
			promiseall.push(promise2);
		}

		return Promise.all(promiseall)
			.then((res) => {
				loadPatientTests();
				enqueueSnackbar('Test Order Updated Successfully', {
					variant: 'info',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				return res;
			})
			.catch((error) => {
				enqueueSnackbar('Error in Test Order', {
					variant: 'error',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				throw error;
			});
	};

	const loadPatientTests = () => {
		const filterState = location?.state || {};

		setOrder(_.get(filterState, 'order', 'desc'));
		setOrderBy(_.get(filterState, 'orderBy', 'date_test_ordered'));
		setLimit(_.get(filterState, 'limit', '25'));
		setPatientUuid(patient?.uuid || match?.params?.patientid || '');

		getPatientTests({
			patient_uuid: patient?.uuid || match?.params?.patientid || '',
			order_type: _.get(filterState, 'order', 'desc'),
			order_by: _.get(filterState, 'orderBy', 'date_test_ordered'),
			limit: _.get(filterState, 'limit', '25'),
			mlo: 1,
		});
		getEncounterEnums();
	};

	const getEncounterEnums = () => {
		calllabsschema();
		callvisitsschema();
		callencountergroupsessionschema();
		callccintakeschema();
		callresearchdataschema();
	};

	function getProviderImage(obj) {
		const defaultImage = providers_images.default_image;
		const providerName = _.get(obj, 'providerName', '');
		const mappedImage = providers_images[providerName];
		return mappedImage ? mappedImage : defaultImage;
	}

	function sendNotification(message, type) {
		enqueueSnackbar(message, {
			variant: type,
			className: 'export_notification',
			anchorOrigin: { horizontal: 'center', vertical: 'top' },
		});
	}

	const getProviderName = (name) => {
		if (name && name.length > 0) {
			const providerObj =
				visitProvider && visitProvider.find((a) => a.key === name);
			let providerName = _.get(providerObj, 'display_name', false);
			let separator = ' ';
			if (!providerName) {
				separator = '_';
				providerName = ucFirstAllWords(name, separator);
			}
			const obj = providerName.split(separator);
			const first_name = _.get(obj, 0, '');
			const middle_name =
				providerName.length > 2 ? ' ' + _.get(obj, 1, '') : '';
			const last_name =
				providerName.length > 2
					? ' ' + _.get(obj, 2, '')
					: ' ' + _.get(obj, 1, '');
			return first_name.charAt(0) + '.' + middle_name + last_name;
		} else {
			return '--';
		}
	};

	const ucFirstAllWords = (str, separator) => {
		const pieces = str.split(separator);
		for (let i = 0; i < pieces.length; i++) {
			const j = pieces[i].charAt(0).toUpperCase();
			pieces[i] = j + pieces[i].substr(1).toLowerCase();
		}
		return pieces.join(separator);
	};

	const getEncounterSchema = (data) => {
		switch (data.encounterName) {
			case 'lab_test_authorization':
				return labsschema?.data || {};
			case 'visit':
				return visitsschema?.data || {};
			case 'scp':
				return visitsschema?.data || {};
			case 'group-session':
				return groupsessionschema?.data || {};
			case 'cc-intake':
				return ccintakeschema?.data || {};
			case 'research-data':
				return researchdataschema?.data || {};
			default:
				return {};
		}
	};

	const getOrderingPhysicianEnums = (data) =>
		ordering_physician?.[data]?.encounterName || [];

	const getLocalStatus = (el) => {
		let localorderstatus = _.get(el, 'testOrderStatus', null);
		if (
			!_.isNil(_.get(el, ['statusReason'], null)) &&
			!_.isEmpty(_.get(el, ['statusReason'], null))
		) {
			localorderstatus = _.get(el, ['statusReason'], null);
		}
		return localorderstatus;
	};

	const testStep = teststep || TestV2TabsIndex.LIST;

	return (
		<div className='tests-container'>
			{testStep == TestV2TabsIndex.LIST && (
				<List
					{...{
						editTestOrderEnums,
						getPatientTests,
						getProviderImage,
						getProviderName,
						getRequestStatus,
						loadPatientTests,
						patient: patientdetail?.[0] || {},
						showTestOrderModal,
						tests,
						viewTestOrder,
					}}
				/>
			)}
			{testStep == TestV2TabsIndex.DETAIL && (
				<TestDetail
					{...{
						parentProps: {
							callccintakeschema,
							callencountergroupsessionschema,
							calllabsschema,
							callresearchdataschema,
							callspecificencounterenum,
							callTestOrderNotes,
							callvisitsschema,
							ccintakeschema,
							enqueueSnackbar,
							getICDCodes,
							getOrderStatusColor,
							getProviderImage,
							getProviderName,
							getRequestStatus,
							goToTestTab,
							groupsessionschema,
							history,
							icdCodes,
							icdCodesError,
							icdCodesLoading,
							icdCodesPlacement,
							labsschema,
							LoadTestEnums,
							LoadTestOrderPopupTestEnum,
							match,
							MLOOrderStatus,
							nonlegacytestOrderPopupTestEnums,
							order_status_main_level,
							ordering_physician,
							outreachStatusEnums,
							patientdetail,
							patientBillingMethodEnums,
							positiveResultsToReport:
								location?.state?.TestDetail
									?.positiveResultsToReport || null,
							researchdataschema,
							sampletypeenums: sampleTypeEnums,
							sendNotification,
							testnameenums,
							testOrderNotes,
							updateTestOrderModal,
							user,
							visitsschema,
						},
					}}
				/>
			)}
			{showModal && (
				<div className='popupModal'>
					<EditTestOrderModalContainer
						hideModal={hideModal}
						testorder={{
							lab: _.get(singleObj, 'labName', ''),
							test_name: _.get(singleObj, 'testName', ''),
							lab_uuid: _.get(singleObj, 'labUUID', ''),
							genetic_test_uuid: _.get(
								singleObj,
								'geneticTestUUID',
								''
							),
							order_status: _.get(
								singleObj,
								'testOrderStatus',
								''
							),
							ordering_physician: _.get(
								singleObj,
								'testOrderOrderingPhysician',
								''
							),
							medical_codes: _.get(singleObj, 'medicalCodes', ''),
							blood_draw_request: _.get(
								singleObj,
								'bloodDrawStatus',
								''
							),
							sample_type: _.get(singleObj, 'sampleType', ''),
							test_order_uuid: _.get(
								singleObj,
								'testOrderUUID',
								''
							),
							status_reason: _.get(singleObj, 'statusReason', ''),
							localorderstatus: getLocalStatus(singleObj),
							bd_saliva_kit_sent: _.get(
								singleObj,
								'bdSalivaKitSent',
								''
							),
							blood_draw_order_date: _.get(
								singleObj,
								'bloodDrawOrderDate',
								''
							),
							date_received_report: _.get(
								singleObj,
								'testOrderResultReceivedDate',
								''
							),
							date_results_released_to_patient: _.get(
								singleObj,
								'editTestOrderResultReleaseDate',
								''
							),
							date_test_ordered: _.get(
								singleObj,
								'testOrderDispatchDate',
								''
							),
							expected_lab_results_date: _.get(
								singleObj,
								'expectedLabResultDate',
								''
							),
							history: _.get(singleObj, 'history', []),
							date_test_authorized: _.get(
								singleObj,
								'testOrderAuthDate',
								''
							),
							patient_billing_method: _.get(
								singleObj,
								'patientBillingMethod',
								''
							),
							lab_paper_form_only: _.get(
								singleObj,
								'labPaperFormOnly',
								false
							),
							is_actionable: _.get(
								singleObj,
								'isActionable',
								false
							),
							lab_order_status: _.get(
								singleObj,
								'labOrderStatusKeyName',
								''
							),
							is_questionnaire_editable: _.get(
								singleObj,
								['is_questionnaire_editable'],
								false
							),
							skip_lab_integration: _.get(
								singleObj,
								['skip_lab_integration'],
								false
							),
							order_questionnaire: _.get(
								singleObj,
								['order_questionnaire'],
								{}
							),
						}}
						patientdetail={patientdetail?.[0] || {}}
						encounter={_.get(singleObj, 'encounter', {})}
						LoadTestOrderPopupTestEnum={LoadTestOrderPopupTestEnum}
						encounterschema={getEncounterSchema(singleObj)}
						labenums={_.get(
							getEncounterSchema(singleObj),
							['lab'],
							[]
						)}
						enqueueSnackbar={enqueueSnackbar}
						updateTestOrder={updateTestOrderModalFunc}
						getICDCodes={getICDCodes}
						icdCodes={icdCodes}
						icdCodesLoading={icdCodesLoading}
						icdCodesError={icdCodesError}
						icdCodesPlacement={icdCodesPlacement}
						selectedICDCodes={_.get(singleObj, 'medicalCodes', [])}
						testOrderNumber={
							actionType.view == action
								? 0
								: _.get(singleObj, 'index', 0) + 1
						}
						ordering_physicianenums={getOrderingPhysicianEnums(
							singleObj
						)}
						mloOrderStatusEnums={MLOORderStatusHierarchyEnums(
							MLOOrderStatus
						)}
						sampletypeenums={sampleTypeEnums}
						nonlegacytestOrderPopupTestEnums={
							nonlegacytestOrderPopupTestEnums
						}
						patientbillingmethodenums={patientBillingMethodEnums}
						testnameenums={testnameenums}
						user={user}
						callTestOrderNotes={callTestOrderNotes}
						testOrderNotes={testOrderNotes}
						testOutreachStatus={_.get(
							singleObj,
							['testOutreachStatus'],
							''
						)}
						outreachTimeToStart={_.get(
							singleObj,
							['outreachTimeToStart'],
							null
						)}
						outreachStatusEnums={_.get(
							singleObj,
							['props', 'outreachStatusEnums'],
							''
						)}
						laborderstatusenums={laborderstatusenums}
					/>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	ccintakeschema: state.ccintakeschema.schema,
	groupsessionschema: state.groupsessionschema.schema,
	laborderstatusenums:
		state.gentestenums?.gentestenums?.lab_order_status || [],
	labsschema: state.labsschema.schema,
	MLOOrderStatus: state.gentestenums?.gentestenums?.order_status || [],
	nonlegacytestnameenums: filterNonLegacyTestEnumes(
		state.testEnums?.data || []
	),
	nonlegacytestOrderPopupTestEnums: filterNonLegacyTestEnumes(
		state.editTestOrderTestEnums?.data || []
	),
	ordering_physician: state.orderingphysicianenum,
	outreachStatusEnums:
		state.gentestenums?.gentestenums?.outreach_status || [],
	patientBillingMethodEnums:
		state.gentestenums?.gentestenums?.patient_billing_method || {},
	researchdataschema: state.researchdataschema.schema,
	sampleTypeEnums: state.gentestenums?.gentestenums?.sample_type || {},
	testnameenums: state.testEnums || [],
	testOrderNotes: state.getTestOrderNotes?.data || [],
	tests: state.tests,
	user: state.me?.user || {},
	visitsschema: state.visitsschema.schema,
});

const mapDispatchToProps = (dispatch) => ({
	actions: {
		callccintakeschema: () => dispatch(getencounterccintakeschema()),
		callencountergroupsessionschema: () =>
			dispatch(getencountergroupsessionschema()),
		callGeneTestEnums: () => dispatch(getGeneTestEnums()),
		calllabsschema: () => dispatch(getencounterlabseschema()),
		callresearchdataschema: () =>
			dispatch(getencounterresearchdataschema()),
		callspecificencounterenum: (data) =>
			dispatch(getspecificencounterenum(data)),
		callTestOrderNotes: (test_order_uuid) =>
			dispatch(getTestOrderNotes(test_order_uuid)),
		callvisitsschema: () => dispatch(getencountervisitsschema()),
		getPatientTests: (data) => dispatch(getPatientTests(data)),
		LoadTestEnums: (lab_uuid) =>
			dispatch(LoadEncounterDetailTestEnums(lab_uuid)),
		LoadTestOrderPopupTestEnum: (lab_uuid) =>
			dispatch(LoadEditTestOrderTestEnums(lab_uuid)),
		updateTestOrderModal: (test_order_uuid, data) =>
			dispatch(updateTestOrder(test_order_uuid, data)),
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(TestsTab);
