import axios from 'axios';
import get from 'lodash/get';

class Metrics {
	get = (data) => {
		return axios.get(`/api/metrics/${data.type}`, {
			params: {
				start_date: get(data, 'start_date'),
				end_date: get(data, 'end_date'),
			},
		});
	};
}

export default new Metrics();
