//React & Redux
import React, { Component } from 'react';
import { withRouter } from 'react-router';

//Lodash
import get from 'lodash/get';

//Components
import NavComponent from '../components/NavBar/NavBar';
import PageHeader from './PageHeader/PageHeader';

class UserApp extends Component {
	render() {
		return (
			<div className='NavComponent'>
				<div className='nav_container'>
					{
						<PageHeader
							props={get(this, 'props.props', {})}
							history={get(this, 'props')}
						/>
					}
					{
						<NavComponent
							toggleDimmerOn={this.props.props.toggleDimmerOn}
							toggleDimmerOff={this.props.props.toggleDimmerOff}
						/>
					}
					{this.props.children}
				</div>
			</div>
		);
	}
}
export default withRouter(UserApp);
