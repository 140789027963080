//React & Redux
import React, { Fragment } from 'react';

//Utils
import { getFormattedRange } from '../../../Utils/CalendarUtils';

//UI Libraries
import { Loading } from 'gm-element-react';
import { Calendar, momentLocalizer } from 'react-big-calendar';

//Other Libraries
import moment from 'moment';

//Components
import Event from './../Event';
import Icon from '../../Common/Icon';
import Tooltip from '../../Common/Tooltip';

//Styles
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);
export const MIN_HOUR = 7;
export const MAX_HOUR = 20;

class CalendarSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}

	//functions
	componentDidMount() {
		try {
			const nodes = document.getElementsByClassName('rbc-header');
			for (let i = 0; i < nodes.length; i++) {
				nodes[i].style.opacity = '1';
			}
			this.changePreviousSiblingCSS(
				document.getElementsByClassName('rbc-header rbc-today')[0]
			);
		} catch (error) {}
	}

	componentDidUpdate() {
		try {
			const nodes = document.getElementsByClassName('rbc-header');
			for (let i = 0; i < nodes.length; i++) {
				nodes[i].style.opacity = '1';
			}
			this.changePreviousSiblingCSS(
				document.getElementsByClassName('rbc-header rbc-today')[0]
			);
		} catch (error) {}
	}

	changePreviousSiblingCSS(node) {
		if (node && node != null) {
			const previousSibling = node.previousSibling;
			if (previousSibling && previousSibling) {
				previousSibling.style.opacity = '0.6';
				return this.changePreviousSiblingCSS(previousSibling);
			}
		}
	}

	getMinTime() {
		// it's better to choose TODAY here
		const minTime = new Date();
		minTime.setHours(MIN_HOUR);
		minTime.setMinutes(0);
		minTime.setSeconds(0);
		minTime.setMilliseconds(0);
		return minTime;
	}

	getMaxTime() {
		// it's better to choose TODAY here
		const maxTime = new Date();
		maxTime.setHours(MAX_HOUR);
		maxTime.setMinutes(0);
		maxTime.setSeconds(0);
		maxTime.setMilliseconds(0);
		return maxTime;
	}

	getFormats() {
		return {
			dayFormat: (date, culture, localizer) =>
				localizer.format(date, 'ddd, D', culture),
			timeGutterFormat: (date, culture, localizer) => {
				// It seems there's a bug in react-big-calendar. It thinks that timeGutterFormat should be a string in PropTypes
				if (date.getMinutes() != 0) return '';
				return localizer.format(date, 'h A', culture);
			},
			dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
				if (start.getMonth() === end.getMonth())
					return (
						start.getDate() +
						'—' +
						end.getDate() +
						' ' +
						localizer.format(start, 'MMMM, YYYY', culture)
					);
				return (
					localizer.format(start, 'DD MMMM', culture) +
					' — ' +
					localizer.format(end, ' DD MMMM, YYYY', culture)
				);
			},
		};
	}

	onResize = () => {
		this.setViewMode();
	};

	onNavigate() {
		// nothing =) just prevent Big Calendar blaming on me
	}

	get ToolbarDateHeader() {
		const start = moment(this.props.date);
		const end = moment(this.props.date).endOf('week');
		return getFormattedRange(start, end);
	}

	get TodayDateTooltip() {
		const date = moment(new Date());
		return date.format('dddd, MMMM Do');
	}

	render() {
		const { slotsLoading } = this.props;
		return (
			<Fragment>
				{slotsLoading && (
					<Loading
						style={{
							background: 'transparent',
							position: 'relative',
							top: '150px',
						}}
					/>
				)}
				<div className='schedule-header'>
					<div className='schedule-headerleft'>
						<div className='arrowpage'>
							<Icon
								icon='left-arrow'
								className='pre-arrow'
								onClick={this.props.switchDateBack}
							/>
							<Icon
								icon='right-arrow'
								className='next-arrow'
								onClick={this.props.switchDateForward}
							/>
						</div>
						<div className='calendar-month'>
							{this.ToolbarDateHeader}
						</div>
					</div>
					<Tooltip
						className=''
						disabled={this.props.isShaded}
						visibleArrow={true}
						effect='dark'
						content={this.TodayDateTooltip}
						placement='top-start'
					>
						<button
							type='button'
							className={`schedule-day ${
								this.props.isShaded ? 'daydisabled' : ''
							}`}
							onClick={this.props.switchToToday}
						>
							Today
						</button>
					</Tooltip>
				</div>
				<Calendar
					localizer={localizer}
					className='gm-calendar'
					events={this.props.events}
					views={['day', 'week']}
					defaultView={'week'}
					view={'week'}
					date={this.props.date}
					onView={() => {}}
					min={this.getMinTime()}
					max={this.getMaxTime()}
					timeslots={2}
					step={30}
					toolbar={false}
					formats={this.getFormats()}
					onNavigate={this.onNavigate}
					components={{
						eventWrapper: Event,
					}}
				/>
			</Fragment>
		);
	}
}

export default CalendarSection;
