import React, { useReducer } from 'react';
import Icon from '../../Common/Icon';
import PushToPioneerFailedDialog from './PushToPioneerFailedDialog';

const initialState = {
	showErrorDialog: false,
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SHOW_ERROR_DIALOG':
			return { ...state, showErrorDialog: true };
		case 'HIDE_ERROR_DIALOG':
			return { ...state, showErrorDialog: false };
		default:
			return state;
	}
};

const PioneerStatus = ({ getPioneerStatus, encounter, onSuccessfulRetry }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const handlePioneerStatusClick = () => {
		dispatch({ type: 'SHOW_ERROR_DIALOG' });
	};

	const handleDialogClose = () => {
		dispatch({ type: 'HIDE_ERROR_DIALOG' });
	};

	const pioneerRcoErrorMessage = encounter.pioneer_rco_error_message;

	return (
		<span>
			{getPioneerStatus === 'Sent' && (
				<Icon className='successIcon' icon='check-circle' />
			)}
			{getPioneerStatus === 'Failed to Send' && (
				<span>
					<Icon
						className='warningIcon'
						icon='warning-circle'
						style={{ cursor: 'pointer' }}
						onClick={handlePioneerStatusClick}
					/>
					<span
						onClick={handlePioneerStatusClick}
						style={{
							'text-decoration': 'underline',
							cursor: 'pointer',
						}}
					>
						{getPioneerStatus}
					</span>
					{state.showErrorDialog && (
						<PushToPioneerFailedDialog
							showErrorDialog={state.showErrorDialog}
							encounter={encounter}
							errorCloseHandler={handleDialogClose}
							pioneerRcoErrorMessage={pioneerRcoErrorMessage}
							onSuccessfulRetry={onSuccessfulRetry}
						/>
					)}
				</span>
			)}
			{getPioneerStatus !== 'Failed to Send' && <p>{getPioneerStatus}</p>}
		</span>
	);
};

export default PioneerStatus;
