//React & Redux
import React from 'react';

//Images
import actionRequired from '../../../assets/action-required.png';
import moreinfoicon from '../../../assets/purple-more-info.svg';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Layout, Input } from 'gm-element-react';

//Components
import Tooltip from '../../Common/Tooltip';
import { SelectComponent as Select } from '../../Common/SelectComponent';

//Other Libraries
import moment from 'moment';

//Utils
import { payorSelectOption } from './../../Patients/V2/Encounters/Common/utilsfunctions.js';

const EditInsuranceBillingSegment = (props) => {
	const {
		encounterBillingDetail,
		encounterBillingDetail: {
			athena_claim_id: athenaClaimId = '--',
			insurance_claim_date: insuranceClaimDate = '',
			insurance_claim_status: insuranceClaimStatus = '-',
		},
		handleChange,
		stateData: { insuranceExpectedRevenue = '', paymentMethod },
		referralPrograms,
		patientDetail,
	} = props;

	const onNumberChange = (key, val) => {
		!_.isNaN(_.toNumber(val)) && handleChange(key, val);
	};

	const displayClaimDate = () => {
		return insuranceClaimDate &&
			insuranceClaimDate != null &&
			_.get(insuranceClaimDate, 'length', 0) > 0
			? moment(insuranceClaimDate).format('MM/DD/YYYY')
			: '--';
	};

	const displayValue = (key) => {
		let value = '--';
		const propsValue = encounterBillingDetail[key] || '';

		if (!_.isNil(propsValue) && !_.isEmpty(propsValue)) {
			value = propsValue == 'na' ? 'N/A' : propsValue;
		}

		value = value.replace(/_/g, ' ');
		return value;
	};

	const getPayorOptions = (payorObj) => {
		const payorOptions = payorObj;

		Object.keys(payorOptions).forEach((key) => {
			if (!_.isNil(payorOptions[key].isDisable)) {
				payorOptions[key].disabled = payorOptions[key].isDisable;
				delete payorOptions[key].isDisable;
			}
		});
		return payorOptions;
	};

	const textField = 'erFieldText inputFieldTextTaller';
	const dropdownField = 'inputFieldDropdown inputFieldDropdownTaller';
	const payorOptions = getPayorOptions(
		payorSelectOption(patientDetail, true, referralPrograms)
	);

	return (
		<>
			<Layout.Row gutter='20'>
				<Layout.Col span='24'>
					<div className='test-number-container'>
						<div className='test-number'>Insurance Billing</div>
						<div className='divider'></div>
					</div>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20'>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Payor</span>
					<span className='val-enctr-sgmnt-field'>
						<Select
							value={paymentMethod}
							onChange={(value) =>
								handleChange('payment_method', value)
							}
							name='payment_method'
							className={dropdownField}
							clearable={true}
							typeahead={true}
							options={payorOptions}
						/>
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>
						Claim Status
						{insuranceClaimStatus == 'not_submitted' && (
							<img
								className='action-required'
								src={actionRequired}
								alt=''
							/>
						)}
					</span>
					<span className='val-enctr-sgmnt-field'>
						<Input
							placeholder='--'
							trim={true}
							disabled={true}
							value={displayValue('insurance_claim_status')}
							className={textField}
						/>
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Claim Date</span>
					<span className='val-enctr-sgmnt-field'>
						<Input
							placeholder='--'
							trim={true}
							disabled={true}
							value={displayClaimDate()}
							className={textField}
						/>
					</span>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20'>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Expected revenue</span>
					<span className='val-enctr-sgmnt-field'>
						<Input
							placeholder='$12.34'
							trim={true}
							value={insuranceExpectedRevenue}
							onChange={(value) =>
								onNumberChange(
									'insurance_expected_revenue',
									value
								)
							}
							className={textField}
						/>
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Athena Claim ID</span>
					<span className='val-enctr-sgmnt-field'>
						<Input
							placeholder='--'
							trim={true}
							disabled={true}
							value={athenaClaimId}
							className={textField}
						/>
					</span>
				</Layout.Col>
			</Layout.Row>
		</>
	);
};

export default EditInsuranceBillingSegment;
