import * as actionTypes from './actionTypes';
import structuralVariantService from '../service/StructuralVariant';
import { deHydrateStructuralVariant } from '../transformers/structuralVariantTransformer';

const addStructuralVariantFindingLoading = () => {
	return {
		type: actionTypes.ADD_STRUCTURALVARIANT_FINDING_LOADING,
	};
};

const addStructuralVariantFindingSuccess = (payload) => {
	return {
		type: actionTypes.ADD_STRUCTURALVARIANT_FINDING_SUCCESS,
		payload,
	};
};

const addStructuralVariantFindingError = (payload) => {
	return {
		type: actionTypes.ADD_STRUCTURALVARIANT_FINDING_ERROR,
		payload,
	};
};

export function addStructuralVariantFinding(data, diagnosticReportId) {
	return (dispatch) => {
		dispatch(addStructuralVariantFindingLoading());
		return structuralVariantService
			.addStructuralVariantFinding(
				deHydrateStructuralVariant(data),
				diagnosticReportId
			)
			.then((res) => {
				dispatch(
					addStructuralVariantFindingSuccess(
						res?.data?.addDiagnosticFinding?.finding?.id || ''
					)
				);
			})
			.catch((err) => {
				dispatch(addStructuralVariantFindingError(err));
			});
	};
}

export function createDiagnosticReportAndAddStructuralVariantFindings(
	data,
	patientId,
	testOrderId
) {
	return (dispatch) => {
		dispatch(addStructuralVariantFindingLoading());
		return structuralVariantService
			.createDiagnosticReport(patientId, testOrderId)
			.then((res) => {
				const diagnosticReportId =
					res?.data?.addDiagnosticReport?.diagnosticReport?.id ||
					null;
				dispatch(addStructuralVariantFinding(data, diagnosticReportId));
			})
			.catch((err) => {
				dispatch(addStructuralVariantFindingError(err));
			});
	};
}

export function updateStructuralVariantFinding(data) {
	return (dispatch) => {
		dispatch(addStructuralVariantFindingLoading());
		return structuralVariantService
			.updateStructuralVariantFinding(
				deHydrateStructuralVariant(data),
				data?.uuid || null
			)
			.then((res) => {
				dispatch(
					addStructuralVariantFindingSuccess(
						res?.data?.updateDiagnosticFinding?.finding?.id || ''
					)
				);
			})
			.catch((err) => {
				dispatch(addStructuralVariantFindingError(err));
			});
	};
}

const getStructuralFindingValidationLoading = () => {
	return {
		type: actionTypes.GET_STRUCTURAL_FINDING_VALIDATION_LOADING,
	};
};

const getStructuralFindingValidationSuccess = (payload) => {
	return {
		type: actionTypes.GET_STRUCTURAL_FINDING_VALIDATION_SUCCESS,
		payload,
	};
};

const getStructuralFindingValidationError = (payload) => {
	return {
		type: actionTypes.GET_STRUCTURAL_FINDING_VALIDATION_ERROR,
		payload,
	};
};

export function getStructuralFindingValidation(data) {
	return (dispatch) => {
		dispatch(getStructuralFindingValidationLoading());
		return structuralVariantService
			.getStructuralFindingValidation(deHydrateStructuralVariant(data))
			.then((res) => {
				dispatch(
					getStructuralFindingValidationSuccess(
						res?.data?.diagnosticFindingValidation || {}
					)
				);
			})
			.catch((err) => {
				dispatch(getStructuralFindingValidationError(err));
			});
	};
}

export const resetGetStructuralFindingValidation = () => {
	return {
		type: actionTypes.RESET_GET_STRUCTURAL_FINDING_VALIDATION,
	};
};
