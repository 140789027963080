import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Elements } from '@stripe/react-stripe-js';
import * as stripeJs from '@stripe/stripe-js';

import BillingService from '../../../../../service/Billing';

import { CardPayment } from './CardPayment';

// https://github.com/stripe/react-stripe-js
const stripePromise = stripeJs.loadStripe(
	process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
);

/**
 * Fetch a setup intent for creating a payment method for this patient.
 * @param {string|undefined} patientUuid The patient's UUID
 * @returns {string|undefined} A stripe client secret
 */
function usePaymentTokenizationIntent(patientUuid) {
	const [intent, setIntent] = useState(null);

	useEffect(() => {
		async function makeIntent() {
			if (!patientUuid) {
				return;
			}

			BillingService.makeTokenizedPaymentMethodIntent(patientUuid).then(
				(result) => {
					setIntent(_.get(result, 'data.data.secret', null));
				}
			);
		}
		void makeIntent();
	}, [patientUuid]);

	return intent;
}

export const StripePayment = forwardRef(function StripePayment(
	{ patientUuid, defaultValues },
	ref
) {
	const clientSecret = usePaymentTokenizationIntent(patientUuid);

	/** @type stripeJs.Appearance */
	const appearance = {
		theme: 'flat',
		variables: {
			borderRadius: '4px',
			colorBackground: '#ffffff',
			fontFamily: 'sans-serif',
			fontSize: '14px',
			spacingUnit: '2px',
		},
		rules: {
			'.Input': {
				border: '1px solid #bfcbd9',
			},
			'.Input:focus': {
				boxShadow: 'none',
				border: '1px solid #20a0ff',
			},
			'.Input:hover': {
				border: '1px solid #8391a5',
			},
			'.Label': {
				color: '#525968',
				fontSize: '12px',
				fontWeight: '700',
			},
		},
	};

	return clientSecret ? (
		<Elements stripe={stripePromise} options={{ appearance, clientSecret }}>
			<CardPayment ref={ref} defaultValues={defaultValues} />
		</Elements>
	) : (
		<div>Please wait while Stripe loads...</div>
	);
});

StripePayment.propTypes = {
	patientUuid: PropTypes.string,
	defaultValues: PropTypes.shape({
		name: PropTypes.string,
		address: PropTypes.shape({
			country: PropTypes.string,
			postalCode: PropTypes.string,
		}),
	}),
};
