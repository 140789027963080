//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	getEncounterSpecificTime,
	encounterCheckboxDisplay,
	GetSelectvalue,
} from '../Common/utilsfunctions';

//Other Libraries
import moment from 'moment';

const ResearchDataSegment = (props) => {
	return (
		<div>
			<div className='segment-row segment-research-row'>
				<div className='segment-5cols'>
					<div className='encounter-field-header'>
						Date & Time Of Service
					</div>
					<div className='encounter-field-value'>
						{getEncounterSpecificTime(
							_.get(props, ['encounter', 'date_of_service'], ''),
							'America/New_York'
						)}
					</div>
				</div>
			</div>
			<div className='segment-row segment-research-row'>
				<div className='segment-5cols'>
					<span className='encounter-field-header encounter-patient-value'>
						Patient wants to receive raw uninterpreted genomic data:
					</span>
					<span className='encounter-field-value'>
						{encounterCheckboxDisplay(props, [
							'encounter',
							'uninterpreted_genomic_data',
						])}
					</span>
				</div>
			</div>
			<div className='segment-row segment-research-row'>
				<div className='segment-5cols'>
					<span className='encounter-field-header encounter-patient-value'>
						I want to be notified if any possible medically
						actionable findings have been identified in my data:
					</span>
					<span className='encounter-field-value'>
						{encounterCheckboxDisplay(props, [
							'encounter',
							'possible_medically_actionable_findings',
						])}
					</span>
				</div>
			</div>
			<div className='segment-row segment-research-row'>
				<div className='segment-5cols'>
					<span className='encounter-field-header encounter-patient-value'>
						Patient has confirmed that they understand what they are
						getting from Genome Medical on{' '}
						{moment(_.get(props, ['encounter', 'created_at'], ''))
							.local()
							.format('MMMM Do YYYY')}
						:
					</span>
					<span className='encounter-field-value'>
						{encounterCheckboxDisplay(props, [
							'encounter',
							'patient_disclaimer',
						])}
					</span>
				</div>
			</div>
			<div className='segment-row segment-research-row'>
				<div className='segment-5cols'>
					<div className='encounter-field-header'>
						Medically Actionable Findings Present
					</div>
					<div className='encounter-field-value'>
						{GetSelectvalue(
							props,
							[
								'encounter',
								'medically_actionable_findings_present',
							],
							_.get(
								props,
								[
									'researchdataschema',
									'medically_actionable_findings_present',
								],
								[]
							)
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResearchDataSegment;
