//React & Redux
import React, { Fragment, useState } from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Button, Loading } from 'gm-element-react';

//Components
import { EncounterTextAreaField } from '../Common/EncounterTextAreaField';

const EditAdditionalSegment = (props) => {
	const { encounter, cancel, saveCCIntakeEncounter } = props;

	const [formData, setFormData] = useState({
		notes: _.get(encounter, ['notes'], ''),
	});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	const validateForm = () => {
		const errors = {};
		return errors;
	};

	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		const errors = validateForm(stateData);
		setFormData(stateData);
		setErrors(errors);
	};

	const saveForm = () => {
		const errors = validateForm(formData);

		if (_.isEmpty(errors)) {
			setLoading(true);
			const updatedformdata = _.mapValues(
				formData,
				function (value, key) {
					if (key == 'notes') {
						return value && value.trim();
					}
					if (value == 'empty') return '';
					return value;
				}
			);

			saveCCIntakeEncounter(encounter, updatedformdata)
				.then((result) => {
					setTimeout(() => {
						setLoading(false);
						cancel();
					}, 1000);
				})
				.catch((error) => {
					setLoading(false);
				});
		} else {
			setErrors(errors);
		}
	};

	return (
		<Loading loading={loading}>
			<Fragment>
				<div className='edit-encntr-lbl-cc-notes'>CC NOTES</div>
				<div className='segment-row'>
					<EncounterTextAreaField
						value={_.get(formData, 'notes', '')}
						name='notes'
						onChange={(value) => onFormChange('notes', value)}
					/>
				</div>

				<div className='segment-footer-container'>
					<div className='cancel-btn-link'>
						<Button onClick={() => cancel()}>Cancel</Button>
					</div>
					<div className='save-btn-link'>
						<Button style={{ float: 'right' }} onClick={saveForm}>
							Save
						</Button>
					</div>
				</div>
			</Fragment>
		</Loading>
	);
};
export default EditAdditionalSegment;
