import _ from 'lodash';
export const hydrateOutreachs = (data) => {
	return _.get(data, ['data', 'data'], []).map((d) => hydrateOutreach(d));
};

export const hydrateOutreachSetting = (result) => {
	const data = _.get(result, ['data', 'data'], {});
	return {
		enabled: data.enabled,
		DateTime: data.date_time,
		reason: data.reason,
		userMadeChange: !_.isEmpty(data.user_made_change)
			? hydrateUserMadeChange(data.user_made_change)
			: {},
		encounters: data.encounters,
		via: data.via,
	};
};

export const hydrateUserMadeChange = (data) => {
	return {
		UUID: data.uuid,
		firstName: data.first_name,
		lastName: data.last_name,
		email: data.email,
		fullName:
			_.get(data, 'first_name', '') + ' ' + _.get(data, 'last_name', ''),
	};
};

export const hydrateOutreach = (data) => {
	return {
		UUID: data.uuid,
		encounterType: data.encounter_type,
		encounterSubType: data.encounter_subtype,
		scope: data.scope,
		consultationType: data.consultation_type,
		DOS: data.date_of_service,
		communicationType: data.communication_type,
		holdResultRelease: data.hold_result_release,
		outreachTimeToStart: data.time_to_start,
		groups: data.groups.map((group) => hydrateOutreachGroup(group, data)),
	};
};

export const hydrateOutreachGroup = (data, encounterData) => {
	return {
		outreachSequenceNo: data.outreach_seq_no,
		outreachStatus: data.outreach_status,
		labName: data.lab_name,
		testName: data.test_name,
		testId: data.gene_test_order_uuid,
		outreachRoundSeq: data.outreach_round_seq,
		communications: data.communications.map((communication) =>
			hydrateOutreachCommunication(
				communication,
				encounterData,
				data.outreach_seq_no,
				data.lab_name,
				data.test_name,
				data.gene_test_order_uuid
			)
		),
		outreachRound: hydrateOutreachRound(data.outreach_round),
	};
};

export const hydrateOutreachRound = (data) => {
	if (data == undefined) {
		return null;
	}
	const firstName = _.get(data, 'created_by.first_name', '');
	const lastName = _.get(data, 'created_by.last_name', '');
	const createdBy =
		[firstName, lastName].filter(Boolean).join(' ') || 'Unknown';

	return {
		specialty: _.get(data, 'specialty.display_name', ''),
		consultation: _.get(data, 'consultation.name', ''),
		created_by: createdBy,
		created_at: _.get(data, 'created_at', ''),
		id: _.get(data, 'id'),
		status: _.get(data, 'status'),
		substatus: _.get(data, 'substatus'),
	};
};

export const hydrateOutreachCommunication = (
	data,
	encounterData,
	outreachSequenceNo,
	labName,
	testName,
	testId
) => {
	return {
		emailSubject: data.email_subject,
		medium: data.medium,
		outcome: data.outcome,
		subtype: data.subtype,
		notes: data.notes,
		contactDateTime: data.date_time,
		createdByUUID: data.created_by && data.created_by.uuid,
		createdByFirstName: data.created_by && data.created_by.first_name,
		createdByLastName: data.created_by && data.created_by.last_name,
		isSystem: data.created_by && data.created_by.is_system,
		type: encounterData.communication_type,
		outreachSequenceNo: outreachSequenceNo,
		encounterType: encounterData.encounter_type,
		encounterSubType: encounterData.encounter_subtype,
		consultationType: encounterData.consultation_type,
		DOS: encounterData.date_of_service,
		encounterUUID: encounterData.uuid,
		communicationUUID: data.communication_uuid,
		smsBody: data.sms_body,
		outreachType: data.type,
		subtypeName: data.subtype_name,
		labName: labName,
		testName: testName,
		testId: testId,
	};
};

// Currently not being used
export const mapSMSOutreaches = (communications) => {
	const outreachList = communications || [];
	if (Array.isArray(outreachList)) {
		const outreaches = outreachList;
		const smsOutreaches = outreaches.filter(
			(outreach) =>
				_.get(outreach, 'medium', null) === 'sms' &&
				_.get(outreach, 'outcome', null) === 'sms_sent'
		);
		smsOutreaches.forEach((smsOutreach) => {
			const subType = _.get(smsOutreach, 'subtypeName', null);
			if (typeof subType === 'string' && subType.match('_sms_')) {
				const correspondingSubtypeMatch = subType.match(
					/(referral)_sms(_outreach_\d+)/i
				);
				if (
					correspondingSubtypeMatch &&
					correspondingSubtypeMatch[1] &&
					correspondingSubtypeMatch[2]
				) {
					const correspondingSubtype =
						correspondingSubtypeMatch[1] +
						correspondingSubtypeMatch[2];
					const correspondingOutreach = _.find(
						outreaches,
						(outreach) =>
							_.get(outreach, 'subtypeName', null) ===
							correspondingSubtype
					);
					if (correspondingOutreach) {
						if (
							correspondingOutreach.relatedSMSOutreaches &&
							Array.isArray(
								correspondingOutreach.relatedSMSOutreaches
							)
						) {
							correspondingOutreach.relatedSMSOutreaches.push(
								smsOutreach
							);
						} else {
							correspondingOutreach.relatedSMSOutreaches = [
								smsOutreach,
							];
						}
						smsOutreach.hasEmailOutreach = true;
					}
				}
			} else if (
				typeof subType === 'string' &&
				subType.match('ror_outreach_')
			) {
				const correspondingOutreach = _.find(outreaches, (outreach) => {
					return (
						_.get(outreach, 'subtypeName', null) === subType &&
						_.get(outreach, 'outcome', null) != 'sms_sent'
					);
				});
				if (correspondingOutreach) {
					if (
						correspondingOutreach.relatedSMSOutreaches &&
						Array.isArray(
							correspondingOutreach.relatedSMSOutreaches
						)
					) {
						correspondingOutreach.relatedSMSOutreaches.push(
							smsOutreach
						);
					} else {
						correspondingOutreach.relatedSMSOutreaches = [
							smsOutreach,
						];
					}
					smsOutreach.hasEmailOutreach = true;
				}
			}
		});
		return outreaches.filter(
			(outreach) => !_.get(outreach, 'hasEmailOutreach', false)
		);
	} else return outreachList;
};
