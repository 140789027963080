//React & Redux
import React, { useState } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	getEncounterSpecificTime,
	createEnumsOptions,
} from '../Common/utilsfunctions';

//UI Libraries
import { Button, Loading } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import { EncounterCheckBoxField } from '../Common/EncounterCheckBoxField';
import { EncounterSelectField } from '../Common/EncounterSelectField';

const EditResearchDataSegment = (props) => {
	const { encounter, saveResearchEncounter, cancel, researchdataschema } =
		props;

	const tempFormData = {
		uninterpreted_genomic_data: _.get(
			encounter,
			['uninterpreted_genomic_data'],
			false
		),
		possible_medically_actionable_findings: _.get(
			encounter,
			['possible_medically_actionable_findings'],
			false
		),
		patient_disclaimer: _.get(encounter, ['patient_disclaimer'], false),
		medically_actionable_findings_present: _.get(
			encounter,
			['medically_actionable_findings_present'],
			''
		),
	};
	const [formData, setFormData] = useState(tempFormData);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	const validateForm = (formdata) => {
		const errors = {};
		return errors;
	};
	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		const errors = validateForm(stateData);
		setFormData(stateData);
		setErrors(errors);
	};
	const saveForm = () => {
		const errors = validateForm(formData);
		if (_.isEmpty(errors)) {
			const updatedformdata = _.mapValues(
				formData,
				function (value, key) {
					if (value == 'empty') return '';
					return value;
				}
			);
			setLoading(true);
			saveResearchEncounter(encounter, updatedformdata)
				.then((result) => {
					setTimeout(() => {
						setLoading(false);
						cancel();
					}, 1000);
				})
				.catch((error) => {
					setLoading(false);
				});
		} else {
			setErrors(errors);
		}
	};
	return (
		<Loading loading={loading}>
			<div>
				<div className='segment-row  segment-research-row'>
					<div className='segment-label-row'>
						<div className='segment-5cols'>
							<div className='encounter-field-header'>
								Date & Time of Service
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-5cols'>
							<div className='encounter-input-section'>
								<div className='encounter-field-value'>
									{getEncounterSpecificTime(
										_.get(
											props,
											['encounter', 'date_of_service'],
											''
										),
										'America/New_York'
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row  segment-research-row'>
					<div className='segment-5cols'>
						<div className='encounter-input-section'>
							<EncounterCheckBoxField
								checked={_.get(
									formData,
									['uninterpreted_genomic_data'],
									false
								)}
								name='uninterpreted_genomic_data'
								disabled={false}
								label='Patient wants to receive raw uninterpreted genomic data'
								onChange={(value) =>
									onFormChange(
										'uninterpreted_genomic_data',
										value
									)
								}
							/>
						</div>
					</div>
				</div>
				<div className='segment-row  segment-research-row'>
					<div className='segment-5cols'>
						<div className='encounter-input-section'>
							<EncounterCheckBoxField
								checked={_.get(
									formData,
									['possible_medically_actionable_findings'],
									false
								)}
								name='possible_medically_actionable_findings'
								disabled={false}
								label='I want to be notified if any possible medically actionable findings have been identified in my data'
								onChange={(value) =>
									onFormChange(
										'possible_medically_actionable_findings',
										value
									)
								}
							/>
						</div>
					</div>
				</div>
				<div className='segment-row  segment-research-row'>
					<div className='segment-5cols'>
						<div className='encounter-input-section'>
							<EncounterCheckBoxField
								checked={_.get(
									formData,
									['patient_disclaimer'],
									false
								)}
								name='patient_disclaimer'
								disabled={true}
								label={
									'Patient has confirmed that they understand what they are getting from Genome Medical on ' +
									moment(_.get(encounter, ['created_at'], ''))
										.local()
										.format('MMMM Do YYYY')
								}
								onChange={(value) =>
									onFormChange('patient_disclaimer', value)
								}
							/>
						</div>
					</div>
				</div>
				<div className='segment-row  segment-research-row'>
					<div className='segment-label-row'>
						<div className='segment-2cols segment-research-fix-width'>
							<div className='encounter-field-header'>
								Medically Actionable Findings Present
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-2cols'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										[
											'medically_actionable_findings_present',
										],
										''
									)}
									name='medically_actionable_findings_present'
									options={createEnumsOptions(
										researchdataschema,
										[
											'medically_actionable_findings_present',
										],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'medically_actionable_findings_present',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-footer-container'>
					<div className='cancel-btn-link'>
						<Button onClick={() => cancel()}>Cancel</Button>
					</div>
					<div className='save-btn-link'>
						<Button style={{ float: 'right' }} onClick={saveForm}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</Loading>
	);
};

export default EditResearchDataSegment;
