//React & Redux
import React, { Component } from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Tag } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Images
import name_Icon from './assets/name.png';
import dob_Icon from './assets/dob.png';
import phone_Icon from './assets/phone.png';
import referral_program_Icon from './assets/referral-program.png';
import email_Icon from './assets/email.png';
import dos_Icon from './assets/dos.png';
import coupon_Icon from './assets/coupon.png';
import insurance_Icon from './assets/insurance.png';
import billing_notes_Icon from './assets/billing_notes.png';
import gc_Icon from './assets/gc.png';
import lab_Icon from './assets/lab.png';
import order_status_Icon from './assets/order_status.png';
import test_type_Icon from './assets/test_type.png';
import encounter_Icon from './assets/encounter.png';
import reports_Icon from './assets/reports.png';
import multi_patient_Icon from './assets/multi-patient.png';

//Styles
import './pill.css';

const Pill = (props) => {
	const { inputs, category, condition, id, onDeletePill } = props;

	const getIcon = () => {
		const icon = _.get(category, 'icon');
		let srcIcon = null;
		switch (icon) {
			case 'name':
				srcIcon = name_Icon;
				break;
			case 'dob':
				srcIcon = dob_Icon;
				break;
			case 'phone':
				srcIcon = phone_Icon;
				break;
			case 'referral-program':
				srcIcon = referral_program_Icon;
				break;
			case 'email':
				srcIcon = email_Icon;
				break;
			case 'dos':
				srcIcon = dos_Icon;
				break;
			case 'coupon':
				srcIcon = coupon_Icon;
				break;
			case 'insurance':
				srcIcon = insurance_Icon;
				break;
			case 'billing_notes':
				srcIcon = billing_notes_Icon;
				break;
			case 'gc':
				srcIcon = gc_Icon;
				break;
			case 'lab':
				srcIcon = lab_Icon;
				break;
			case 'test_type':
				srcIcon = test_type_Icon;
				break;
			case 'order_status':
				srcIcon = order_status_Icon;
				break;
			case 'encounter':
				srcIcon = encounter_Icon;
				break;
			case 'reports':
				srcIcon = reports_Icon;
				break;
			case 'multi-patient':
				srcIcon = multi_patient_Icon;
				break;
			default:
				srcIcon = name_Icon;
				break;
		}

		return (
			<span className='category-icon-span'>
				<img
					className={
						'category_images' +
						(icon in { 'referral-program': 1, 'multi-patient': 1 }
							? ' referral_program'
							: '')
					}
					src={srcIcon}
					alt='hello'
				/>
			</span>
		);
	};

	const getLabel = () => {
		const categorytpe = _.get(category, 'key', '');
		const categorydisplayname = _.get(category, 'name', '');
		const conditionkey = condition;

		let input = '';
		if (categorytpe !== 'date_of_service') {
			if (_.size(inputs[0]) > 15) {
				input = inputs[0].substr(0, 15) + '...';
			} else {
				input = inputs[0];
			}
		}
		if (categorytpe === 'date_of_service' && conditionkey === 'between') {
			const dates = inputs;
			return (
				<span className='chiplabel'>
					<span className='chiplablecategoty'>
						{categorydisplayname} {conditionkey}
					</span>{' '}
					{dates &&
						dates[0] &&
						moment(dates[0].replace(/\s/g, '')).format(
							'MMM DD,YYYY'
						)}{' '}
					<span className='chiplablecategoty'> and </span>{' '}
					{dates &&
						dates[1] &&
						moment(dates[1].replace(/\s/g, '')).format(
							'MMM DD,YYYY'
						)}{' '}
				</span>
			);
		} else if (categorytpe === 'date_of_service' || categorytpe === 'dob') {
			return (
				<span className='chiplabel'>
					<span className='chiplablecategoty'>
						{categorydisplayname} {conditionkey}
					</span>{' '}
					{moment(inputs[0]).format('MMM DD,YYYY')}{' '}
				</span>
			);
		} else {
			return (
				<span className='chiplabel'>
					<span className='chiplablecategoty'>
						{categorydisplayname} {conditionkey}
					</span>{' '}
					{input}{' '}
				</span>
			);
		}
	};

	const handleDelete = () => {
		onDeletePill();
	};

	return (
		<div>
			<Tag
				key={id}
				className='pill-tag'
				closable={true}
				onClose={handleDelete}
			>
				<span>
					{getIcon()}
					<span className='chiplabel chiplablecategoty clearpill'>
						{' '}
						{getLabel()}{' '}
					</span>
				</span>
			</Tag>
		</div>
	);
};

export default Pill;
