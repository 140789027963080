//React & Redux
import React, { Fragment, useEffect, useState } from 'react';

//Images
import editpencil from '../../../../../assets/edit.svg';
import imageExpandCollapse from '../../../../../assets/chevron-large.svg';

//Lodash
import _ from 'lodash';

//Utils
import {
	CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER,
	CARE_PATIENTS_PATIENT_ENCOUNTER_ADDITIONAL_INFO,
	CARE_PATIENTS_PATIENT_ENCOUNTER_PROVIDER_OPERATIONS,
	CARE_PATIENTS_PATIENT_ENCOUNTER_BILLING,
} from '../../../../../Utils/permissionUtils';

//UI Libraries
import { Loading } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';

//Components
import VisitDetailSegment from './VisitDetailSegment';
import EditVisitDetailSegment from './EditVisitDetailSegment';
import TestOrderSegment from './TestOrderSegment';
import EditTestOrderSegment from './EditTestOrderSegment';
import PGXSegment from './PGXSegment';
import EditPGXSegment from './EditPGXSegment';
import RORSegment from './RORSegment';
import EditRORSegment from './EditRORSegment';
import BillingSegment from './BillingSegment';
import EditBillingSegment from './EditBillingSegment';
import AdditionalSegment from './AdditionalSegment';
import RBAC from '../../../../RBAC';
import ProviderLinked from '../../../../ExternalProviders/ProviderLinked';
import ProviderAddAnother from '../../../../ExternalProviders/ProviderAddAnother';

const LabEncounterDetail = (props) => {
	const {
		isBillingCoordinator,
		encounter,
		billingencounter,
		calllabschema,
		fetchOrderingPhysician,
		patientdetail,
		upload_documents,
		getPatientFeeRefundEnum,
		labsschema,
		testnameenums,
		nonlegacytestnameenums,
		orderstatuscascadeenums,
		orderrequeststatuscascadeenums,
		ordering_physician,
		patientFeeEnum,
		orderrequest,
		calleditencouter,
		getencouter,
		enqueueSnackbar,
		calleditbillingencouter,
		externalProviderProps,
		filteredorderstatuscascadeenums,
		shouldEditable,
		LoadTestEnums,
		activeRole,
	} = props;
	const defaultSegment = {
		VisitDetailSegment: true,
		TestOrderSegment: !isBillingCoordinator,
		PxInfoSegment: false,
		RORSegment: false,
		BillingSegment: isBillingCoordinator,
		AdditionalSegment: false,
		ExtProvidersSegment: false,
	};
	const [segment, setSegment] = useState(defaultSegment);
	const [EditedSegment, setEditedSegment] = useState('');
	useEffect(() => {
		calllabschema();
		fetchOrderingPhysician();
		const data_lab_patient_uuid = _.get(encounter, ['lab_patient_uuid']);
		const lab_patient_obj = _.find(
			_.get(patientdetail, ['lab_patients'], []),
			function (o) {
				return o.uuid == data_lab_patient_uuid;
			}
		);
		upload_documents({
			partner_id: _.get(
				lab_patient_obj,
				'partner_id',
				_.get(patientdetail, ['partner_id'], 0)
			),
			uuid: _.get(encounter, 'uuid', 0),
		});
		getPatientFeeRefundEnum();
	}, []);

	const makeEdited = (segmentname) => (e) => {
		const tempSegment = { ...segment };
		if (shouldEditable) {
			const EditedSegment = segmentname;
			setEditedSegment(EditedSegment);
			tempSegment[segmentname] = true;
			setSegment(tempSegment);
		}
		e.stopPropagation();
	};

	const makeNonEdited = (segmentname) => {
		const tempSegment = { ...segment };
		setEditedSegment('');
		tempSegment[segmentname] = true;
		setSegment(tempSegment);
	};

	const isAnyEditActive = () => {
		return EditedSegment != '';
	};

	const isInEditMode = (segmentname) => {
		return segmentname == EditedSegment;
	};

	const isExpanded = (segmentname) => {
		return _.get(segment, [segmentname], false) == true;
	};

	const toggleSegment = (segmentname) => {
		const tempSegment = { ...segment };
		const isInEditMod = isInEditMode(segmentname);
		if (tempSegment && !isInEditMod) {
			tempSegment[segmentname] = !tempSegment[segmentname];
			setSegment(tempSegment);
		}
	};

	const visitDetailsProps = (props) => {
		return {
			encounter: {
				date_of_service: _.get(encounter, ['date_of_service']),
				followup_cap_completed_date: _.get(encounter, [
					'followup_cap_completed_date',
				]),
				cap_sent_to_patient: _.get(encounter, ['cap_sent_to_patient']),
				cap_release_method_patient: _.get(encounter, [
					'cap_release_method_patient',
				]),
				cap_sent_date_pcp: _.get(encounter, ['cap_sent_date_pcp']),
				cap_release_method_pcp: _.get(encounter, [
					'cap_release_method_pcp',
				]),
				pioneer_appointment_id: _.get(encounter, [
					'pioneer_appointment_id',
				]),
				state_of_visit: _.get(encounter, ['state_of_visit']),
			},
			labsschema: labsschema,
		};
	};

	const testOrdersProps = () => {
		return {
			encounter: {
				order_request_status: _.get(encounter, [
					'order_request_status',
				]),
				lab: _.get(encounter, ['lab']),
				type_of_test: _.get(encounter, ['type_of_test']),
				ordering_physician: _.get(encounter, ['ordering_physician']),
				date_test_authorized: _.get(encounter, [
					'date_test_authorized',
				]),

				order_status: _.get(encounter, ['order_status']),
				date_test_ordered: _.get(encounter, ['date_test_ordered']),
				expected_lab_results_date: _.get(encounter, [
					'expected_lab_results_date',
				]),
				date_authorized_via_lab_portal: _.get(encounter, [
					'date_authorized_via_lab_portal',
				]),
				date_received_report: _.get(encounter, [
					'date_received_report',
				]),

				date_results_released_to_portal: _.get(encounter, [
					'date_results_released_to_portal',
				]),
				date_results_released_to_patient: _.get(encounter, [
					'date_results_released_to_patient',
				]),
			},
			labsschema: labsschema,
			testnameenums: _.get(testnameenums, ['data'], []),
			nonlegacytestnameenums: nonlegacytestnameenums,
			orderstatuscascadeenums: orderstatuscascadeenums,
			filteredorderstatuscascadeenums: filteredorderstatuscascadeenums,
			orderrequeststatuscascadeenums: orderrequeststatuscascadeenums,
			ordering_physicianenums: ordering_physician,
		};
	};
	const pgxProps = () => {
		return {
			encounter: {
				pharmd: _.get(encounter, ['pharmd']),
				drug_interaction: _.get(encounter, ['drug_interaction']),
				no_of_interactions: _.get(encounter, ['no_of_interactions']),
				drug_contraindications: _.get(encounter, [
					'drug_contraindications',
				]),
				drug_dosage_adjustment_recommended: _.get(encounter, [
					'drug_dosage_adjustment_recommended',
				]),
			},
			labsschema: labsschema,
		};
	};
	const rorProps = () => {
		return {
			encounter: {
				ror_visit_status: _.get(encounter, ['ror_visit_status']),
				ror_date_contacted: _.get(encounter, ['ror_date_contacted']),
				ror_date_recontacted: _.get(encounter, [
					'ror_date_recontacted',
				]),
				ror_date_final_contacted: _.get(encounter, [
					'ror_date_final_contacted',
				]),
			},
			labsschema: labsschema,
		};
	};
	const billingProps = () => {
		return {
			billingencounter: {
				patient_billing_date: _.get(billingencounter, [
					'patient_billing_date',
				]),
				patient_billing_amount: _.get(billingencounter, [
					'patient_billing_amount',
				]),
				billing_status: _.get(billingencounter, ['billing_status']),
				billing_notes: _.get(billingencounter, ['billing_notes']),

				patient_fee_refund: _.get(billingencounter, [
					'patient_fee_refund',
				]),
				patient_fee_refund_reason: _.get(billingencounter, [
					'patient_fee_refund_reason',
				]),
				patient_fee_refund_reason_other: _.get(billingencounter, [
					'patient_fee_refund_reason_other',
				]),
				patient_fee_refund_processed_date: _.get(billingencounter, [
					'patient_fee_refund_processed_date',
				]),
				post_negative_minutes: _.get(
					billingencounter,
					'post_negative_minutes'
				),
			},
			encounter: {
				date_of_service: _.get(encounter, 'date_of_service'),
			},
			labsschema: labsschema,
			patientFeeEnum: patientFeeEnum,
		};
	};
	const additionalInfoProps = (props) => {
		return {
			encounter: {
				notes: _.get(encounter, ['notes']),
			},
			labsschema: labsschema,
			orderrequest: orderrequest,
			patientdetail: patientdetail,
			lab_patient_uuid: _.get(encounter, ['lab_patient_uuid']),
		};
	};

	const saveLabEncounter = (initalProps, formdata, flag = true) => {
		const edited = _.reduce(
			formdata,
			function (result, value, key) {
				return _.isEqual(value, initalProps[key])
					? result
					: result.concat(key);
			},
			[]
		);
		if (edited && _.get(edited, 'length', 0) > 0) {
			const json = {
				data: {
					type: _.get(encounter, ['type']),
					uuid: _.get(encounter, ['uuid']),
				},
			};

			edited.map((e) => {
				if (_.has(formdata, e)) {
					json['data'][e] = _.get(formdata, e, '');
				} else {
					json['data'][e] = _.get(initalProps, [e]);
				}
				if (json['data'][e] == 'empty') {
					json['data'][e] = '';
				}
			});
			return calleditencouter(json)
				.then((data) => {
					if (flag) {
						getencouter(false);
					}
					enqueueSnackbar('Encounter successfully updated', {
						variant: 'info',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
					return data;
				})
				.catch((error) => {
					enqueueSnackbar('Error in edit encounter', {
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
					throw error;
				});
		} else {
			return Promise.resolve({});
		}
	};

	const saveLabBillingEncounter = (
		initalProps,
		formdata,
		updateencounterdata
	) => {
		const edited = _.reduce(
			formdata,
			function (result, value, key) {
				return _.isEqual(value, initalProps[key])
					? result
					: result.concat(key);
			},
			[]
		);

		if (edited && _.get(edited, 'length', 0) > 0) {
			const json = {
				data: {
					uuid: _.get(billingencounter, ['uuid']),
					encounter_uuid: _.get(encounter, ['uuid']),
				},
			};

			edited.map((e) => {
				if (_.has(formdata, e)) {
					json['data'][e] = _.get(formdata, e, '');
				} else {
					json['data'][e] = _.get(initalProps, [e]);
				}

				if (json['data'][e] == 'empty') {
					json['data'][e] = '';
				}
			});
			if (json.data.hasOwnProperty('units')) delete json.data.units;
			if (json.data.hasOwnProperty('units_s0265_95'))
				delete json.data.units_s0265_95;
			return calleditbillingencouter(json)
				.then((data) => {
					getencouter(false);
					if (
						formdata['post_negative_minutes'] ==
						_.get(billingencounter, 'post_negative_minutes')
					) {
						enqueueSnackbar('Encounter successfully updated', {
							variant: 'info',
							anchorOrigin: {
								horizontal: 'right',
								vertical: 'bottom',
							},
						});
					}
					return data;
				})
				.catch((error) => {
					enqueueSnackbar('Error in edit encounter', {
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
					throw error;
				});
		} else {
			return Promise.resolve({});
		}
	};

	return (
		<Fragment>
			{
				<Fragment>
					<div className='encounter-sections'>
						<div className='encounter-section'>
							<div
								className={classnames(
									'encounter-accordian-header',
									{
										'encounter-accordian-header-expanded':
											isExpanded('VisitDetailSegment'),
									}
								)}
								style={{
									cursor: isInEditMode('VisitDetailSegment')
										? 'auto'
										: 'pointer',
								}}
								onClick={() =>
									toggleSegment('VisitDetailSegment')
								}
							>
								<span className='accordian-title'>
									VISIT DETAILS
								</span>
								{!isInEditMode('VisitDetailSegment') && (
									<Fragment>
										{isExpanded('VisitDetailSegment') &&
											!isAnyEditActive() && (
												<RBAC
													action={
														CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
													}
													yes={
														<span>
															<img
																src={editpencil}
																alt='edit-icon'
																onClick={makeEdited(
																	'VisitDetailSegment'
																)}
															/>
														</span>
													}
												/>
											)}
										<span className='accordian-icon'>
											<img
												src={imageExpandCollapse}
												style={{
													transform: isExpanded(
														'VisitDetailSegment'
													)
														? 'rotate(-90deg)'
														: 'rotate(90deg)',
													float: 'right',
												}}
												alt='expand-icon'
											/>
										</span>
									</Fragment>
								)}
							</div>
							<div
								className='encounter-accordian-body'
								style={{
									display: isExpanded('VisitDetailSegment')
										? 'block'
										: 'none',
								}}
							>
								{isInEditMode('VisitDetailSegment') ? (
									<RBAC
										action={
											CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
										}
										yes={
											<EditVisitDetailSegment
												{...visitDetailsProps()}
												saveLabEncounter={
													saveLabEncounter
												}
												cancel={() =>
													makeNonEdited(
														'VisitDetailSegment'
													)
												}
											/>
										}
									/>
								) : (
									<VisitDetailSegment
										{...visitDetailsProps()}
									/>
								)}
							</div>
						</div>

						<div className='encounter-section'>
							<div
								className={classnames(
									'encounter-accordian-header',
									{
										'encounter-accordian-header-expanded':
											isExpanded('TestOrderSegment'),
									}
								)}
								style={{
									cursor: isInEditMode('TestOrderSegment')
										? 'auto'
										: 'pointer',
								}}
								onClick={() =>
									toggleSegment('TestOrderSegment')
								}
							>
								<span className='accordian-title'>
									TEST ORDER(S)
								</span>
								{!isInEditMode('TestOrderSegment') && (
									<Fragment>
										{isExpanded('TestOrderSegment') &&
											!isAnyEditActive() && (
												<RBAC
													action={
														CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
													}
													yes={
														<span>
															<img
																src={editpencil}
																alt='edit-icon'
																onClick={makeEdited(
																	'TestOrderSegment'
																)}
															/>
														</span>
													}
												/>
											)}
										<span className='accordian-icon'>
											<img
												src={imageExpandCollapse}
												style={{
													transform: isExpanded(
														'TestOrderSegment'
													)
														? 'rotate(-90deg)'
														: 'rotate(90deg)',
													float: 'right',
												}}
												alt='expand-icon'
											/>
										</span>
									</Fragment>
								)}
							</div>
							<div
								className='encounter-accordian-body'
								style={{
									display: isExpanded('TestOrderSegment')
										? 'block'
										: 'none',
								}}
							>
								{isInEditMode('TestOrderSegment') ? (
									<RBAC
										action={
											CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
										}
										yes={
											<EditTestOrderSegment
												{...testOrdersProps()}
												saveLabEncounter={
													saveLabEncounter
												}
												LoadTestEnums={LoadTestEnums}
												cancel={() =>
													makeNonEdited(
														'TestOrderSegment'
													)
												}
											/>
										}
									/>
								) : (
									<TestOrderSegment {...testOrdersProps()} />
								)}
							</div>
						</div>

						<div className='encounter-section'>
							<div
								className={classnames(
									'encounter-accordian-header',
									{
										'encounter-accordian-header-expanded':
											isExpanded('ExtProvidersSegment'),
									}
								)}
								style={{
									cursor: isInEditMode('ExtProvidersSegment')
										? 'auto'
										: 'pointer',
								}}
								onClick={() =>
									toggleSegment('ExtProvidersSegment')
								}
							>
								<span className='accordian-title'>
									EXTERNAL PROVIDERS
								</span>
								{!isInEditMode('ExtProvidersSegment') && (
									<Fragment>
										<span className='accordian-icon'>
											<img
												src={imageExpandCollapse}
												style={{
													transform: isExpanded(
														'ExtProvidersSegment'
													)
														? 'rotate(-90deg)'
														: 'rotate(90deg)',
													float: 'right',
												}}
												alt='expand-icon'
											/>
										</span>
									</Fragment>
								)}
							</div>
							<div
								className='encounter-accordian-body'
								style={{
									display: isExpanded('ExtProvidersSegment')
										? 'block'
										: 'none',
								}}
							>
								<Loading
									className='ext-providers-wrapper'
									loading={_.get(
										externalProviderProps,
										'loading',
										false
									)}
								>
									<div className='ext-providers'>
										{_.get(
											externalProviderProps,
											['externalProviders'],
											[]
										).map(
											(el) =>
												el && (
													<ProviderLinked
														linkedType={'encounter'}
														provider={el}
														loading={_.get(
															externalProviderProps,
															'loading',
															false
														)}
														editLinkInfo={_.get(
															externalProviderProps,
															'editLinkInfo'
														)}
														editProviderInfo={_.get(
															externalProviderProps,
															'editProviderInfo'
														)}
														unlinkProviderInfo={_.get(
															externalProviderProps,
															'unlinkProviderInfo'
														)}
														linkProviderToEncounter={_.get(
															externalProviderProps,
															'linkProviderToEncounter'
														)}
														providerSpecialties={
															_.get(
																externalProviderProps,
																'providerSpecialties',
																null
															) || []
														}
														activeRole={activeRole}
													/>
												)
										)}
									</div>
									{!_.isEmpty(
										_.get(
											externalProviderProps,
											'externalProviders',
											[]
										)
									) ? (
										<RBAC
											action={
												CARE_PATIENTS_PATIENT_ENCOUNTER_PROVIDER_OPERATIONS
											}
											yes={
												<ProviderAddAnother
													click={_.get(
														externalProviderProps,
														'handleProviderLinkDialog'
													)}
													title='Link Another Provider'
												/>
											}
										/>
									) : (
										<RBAC
											action={
												CARE_PATIENTS_PATIENT_ENCOUNTER_PROVIDER_OPERATIONS
											}
											yes={
												<ProviderAddAnother
													title='Link External Provider'
													click={_.get(
														externalProviderProps,
														'handleProviderLinkDialog'
													)}
												/>
											}
										/>
									)}
								</Loading>
							</div>
						</div>

						<div className='encounter-section'>
							<div
								className={classnames(
									'encounter-accordian-header',
									{
										'encounter-accordian-header-expanded':
											isExpanded('PxInfoSegment'),
									}
								)}
								style={{
									cursor: isInEditMode('PxInfoSegment')
										? 'auto'
										: 'pointer',
								}}
								onClick={() => toggleSegment('PxInfoSegment')}
							>
								<span className='accordian-title'>
									PGX INFORMATION
								</span>
								{!isInEditMode('PxInfoSegment') && (
									<Fragment>
										{isExpanded('PxInfoSegment') &&
											!isAnyEditActive() && (
												<RBAC
													action={
														CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
													}
													yes={
														<span>
															<img
																src={editpencil}
																alt='edit-icon'
																onClick={makeEdited(
																	'PxInfoSegment'
																)}
															/>
														</span>
													}
												/>
											)}
										<span className='accordian-icon'>
											<img
												src={imageExpandCollapse}
												style={{
													transform: isExpanded(
														'PxInfoSegment'
													)
														? 'rotate(-90deg)'
														: 'rotate(90deg)',
													float: 'right',
												}}
												alt='expand-icon'
											/>
										</span>
									</Fragment>
								)}
							</div>
							<div
								className='encounter-accordian-body'
								style={{
									display: isExpanded('PxInfoSegment')
										? 'block'
										: 'none',
								}}
							>
								{isInEditMode('PxInfoSegment') ? (
									<RBAC
										action={
											CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
										}
										yes={
											<EditPGXSegment
												{...pgxProps()}
												saveLabEncounter={
													saveLabEncounter
												}
												cancel={() =>
													makeNonEdited(
														'PxInfoSegment'
													)
												}
											/>
										}
									/>
								) : (
									<PGXSegment {...pgxProps()} />
								)}
							</div>
						</div>

						<div className='encounter-section'>
							<div
								className={classnames(
									'encounter-accordian-header',
									{
										'encounter-accordian-header-expanded':
											isExpanded('RORSegment'),
									}
								)}
								style={{
									cursor: isInEditMode('RORSegment')
										? 'auto'
										: 'pointer',
								}}
								onClick={() => toggleSegment('RORSegment')}
							>
								<span className='accordian-title'>
									RETURN OF RESULTS (RoR) FOLLOW-UP
								</span>
								{!isInEditMode('RORSegment') && (
									<Fragment>
										{isExpanded('RORSegment') &&
											!isAnyEditActive() && (
												<RBAC
													action={
														CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
													}
													yes={
														<span>
															<img
																src={editpencil}
																alt='edit-icon'
																onClick={makeEdited(
																	'RORSegment'
																)}
															/>
														</span>
													}
												/>
											)}
										<span className='accordian-icon'>
											<img
												src={imageExpandCollapse}
												style={{
													transform: isExpanded(
														'RORSegment'
													)
														? 'rotate(-90deg)'
														: 'rotate(90deg)',
													float: 'right',
												}}
												alt='expand-icon'
											/>
										</span>
									</Fragment>
								)}
							</div>
							<div
								className='encounter-accordian-body'
								style={{
									display: isExpanded('RORSegment')
										? 'block'
										: 'none',
								}}
							>
								{isInEditMode('RORSegment') ? (
									<RBAC
										action={
											CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
										}
										yes={
											<EditRORSegment
												{...rorProps()}
												saveLabEncounter={
													saveLabEncounter
												}
												cancel={() =>
													makeNonEdited('RORSegment')
												}
											/>
										}
									/>
								) : (
									<RORSegment {...rorProps()} />
								)}
							</div>
						</div>

						<RBAC
							action={CARE_PATIENTS_PATIENT_ENCOUNTER_BILLING}
							yes={
								<div className='encounter-section'>
									<div
										className={classnames(
											'encounter-accordian-header',
											{
												'encounter-accordian-header-expanded':
													isExpanded(
														'BillingSegment'
													),
											}
										)}
										style={{
											cursor: isInEditMode(
												'BillingSegment'
											)
												? 'auto'
												: 'pointer',
										}}
										onClick={() =>
											toggleSegment('BillingSegment')
										}
									>
										<span className='accordian-title'>
											BILLING
										</span>
										{!isInEditMode('BillingSegment') && (
											<Fragment>
												{isExpanded('BillingSegment') &&
													!isAnyEditActive() && (
														<RBAC
															action={
																CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
															}
															yes={
																<span>
																	<img
																		src={
																			editpencil
																		}
																		alt='edit-icon'
																		onClick={makeEdited(
																			'BillingSegment'
																		)}
																	/>
																</span>
															}
														/>
													)}
												<span className='accordian-icon'>
													<img
														src={
															imageExpandCollapse
														}
														style={{
															transform:
																isExpanded(
																	'BillingSegment'
																)
																	? 'rotate(-90deg)'
																	: 'rotate(90deg)',
															float: 'right',
														}}
														alt='expand-icon'
													/>
												</span>
											</Fragment>
										)}
									</div>
									<div
										className='encounter-accordian-body'
										style={{
											display: isExpanded(
												'BillingSegment'
											)
												? 'block'
												: 'none',
										}}
									>
										{isInEditMode('BillingSegment') ? (
											<RBAC
												action={
													CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
												}
												yes={
													<EditBillingSegment
														{...billingProps()}
														saveLabBillingEncounter={
															saveLabBillingEncounter
														}
														saveLabEncounter={
															saveLabEncounter
														}
														cancel={() =>
															makeNonEdited(
																'BillingSegment'
															)
														}
													/>
												}
											/>
										) : (
											<BillingSegment
												{...billingProps()}
											/>
										)}
									</div>
								</div>
							}
						/>

						<RBAC
							action={
								CARE_PATIENTS_PATIENT_ENCOUNTER_ADDITIONAL_INFO
							}
							yes={
								<div className='encounter-section'>
									<div
										className={classnames(
											'encounter-accordian-header',
											{
												'encounter-accordian-header-expanded':
													isExpanded(
														'AdditionalSegment'
													),
											}
										)}
										style={{
											cursor: isInEditMode(
												'AdditionalSegment'
											)
												? 'auto'
												: 'pointer',
										}}
										onClick={() =>
											toggleSegment('AdditionalSegment')
										}
									>
										<span className='accordian-title'>
											ADDITIONAL INFO
										</span>
										{!isInEditMode('AdditionalSegment') && (
											<Fragment>
												<span className='accordian-icon'>
													<img
														src={
															imageExpandCollapse
														}
														style={{
															transform:
																isExpanded(
																	'AdditionalSegment'
																)
																	? 'rotate(-90deg)'
																	: 'rotate(90deg)',
															float: 'right',
														}}
														alt='expand-icon'
													/>
												</span>
											</Fragment>
										)}
									</div>
									<div
										className='encounter-accordian-body'
										style={{
											display: isExpanded(
												'AdditionalSegment'
											)
												? 'block'
												: 'none',
										}}
									>
											<AdditionalSegment
												{...additionalInfoProps()}
											/>
										
									</div>
								</div>
							}
						/>
					</div>
				</Fragment>
			}
		</Fragment>
	);
};

export default LabEncounterDetail;
