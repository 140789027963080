import { get } from 'lodash';

export const HOME = 'home';
export const PIONEER = 'pioneer';
export const CARE = 'care';
export const ADVANCED_SEARCH = 'advanced_search';
export const OPERATIONS = 'operations';
export const FINANCE = 'finance';

const FULL_ACCESS = 'full-access';
const READ_ONLY = 'read-only';

export const HOME_DASHBOARD = 'home.dashboard';
export const HOME_DASHBOARD_UPCOMING_APPOINTMENTS =
	'home.dashboard.upcoming_appointments';
export const HOME_DASHBOARD_CARDS = 'home.dashboard.cards';
export const HOME_DASHBOARD_CARDS_APPOINTMENT_STATUS =
	'home.dashboard.cards.appointment_status';
export const HOME_DASHBOARD_CARDS_TEST_ORDER_REQUEST_STATUS_SET =
	'home.dashboard.cards.test_order_request_status_set';
export const HOME_DASHBOARD_CARDS_TEST_ORDER_SENT =
	'home.dashboard.cards.test_order_sent';
export const HOME_DASHBOARD_CARDS_PATIENT_FOLLOWUP_SCHEDULED =
	'home.dashboard.cards.patient_followup_scheduled';
export const HOME_DASHBOARD_CARDS_CAP_NOT_SENT =
	'home.dashboard.cards.cap_not_sent';
export const HOME_DASHBOARD_CARDS_ALL_CDT = 'home.dashboard.cards.all_cdt';
export const HOME_DASHBOARD_CARDS_INSURANCE_CLAIM_FILED =
	'home.dashboard.cards.insurance_claim_filed';
export const HOME_DASHBOARD_CARDS_PATIENT_OUTREACH_PENDING =
	'home.dashboard.cards.patient_outreach_pending';
export const HOME_DASHBOARD_CARDS_APPOINTMENT_ALERTS =
	'home.dashboard.cards.appointment_alerts';
export const HOME_DASHBOARD_CARDS_INITIAL_VISIT_SUMMARY_READY =
	'home.dashboard.cards.initial_visit_summary_ready';
export const HOME_DASHBOARD_CARDS_RESULT_VISIT_SUMMARY_READY =
	'home.dashboard.cards.result_visit_summary_ready';
export const HOME_DASHBOARD_ALL_TIME_METRICS =
	'home.dashboard.all_time_metrics';
export const HOME_DASHBOARD_FOURTEEN_DAYS_METRICS =
	'home.dashboard.fourteen_days_metrics';

export const CARE_PATIENTS = 'care.patients';
export const CARE_APPOINTMENTS = 'care.appointments';
export const CARE_TEST_AUTHORIZATION = 'care.test_authorization';

export const CARE_PATIENTS_LIST = 'care.patients.list';
export const CARE_PATIENTS_PATIENT_SETTINGS = 'care.patients.patient_settings';
export const CARE_PATIENTS_PATIENT_DELETE = 'care.patients.patient_delete';
export const CARE_PATIENTS_PATIENT_OUTREACH_LOG =
	'care.patients.patient.outreach_log';
export const CARE_PATIENTS_PATIENT_NOTES = 'care.patients.patient.notes';
export const CARE_PATIENTS_ADD_PATIENT_NOTES =
	'care.patients.patient.add_notes';
export const CARE_PATIENTS_PATIENT_DOCUMENTS =
	'care.patients.patient.documents';
export const CARE_PATIENTS_PATIENT_PAYMENTS = 'care.patients.patient.payments';
export const CARE_PATIENTS_PATIENT_PAYMENTS_INSURANCE_ACTIONS =
	'care.patients.patient.payments.insurance_actions';
export const CARE_PATIENTS_PATIENT_TESTS = 'care.patients.patient.tests';
export const CARE_PATIENTS_PATIENT_WORKFLOW_DOCS =
	'care.patients.patient_workflow_docs';
export const CARE_PATIENTS_PATIENT_CREATE_DOC = 'care.patients.create_doc';
export const CARE_PATIENTS_PATIENT_UPLOAD_DOC = 'care.patients.upload_doc';
export const CARE_PATIENTS_PATIENT_COMBINE_DOC = 'care.patients.combine_doc';
export const CARE_PATIENTS_PATIENT_ADD_ENCOUNTER =
	'care.patients.patient_add_encounter';
export const CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER =
	'care.patients.patient_edit_encounter';
export const CARE_PATIENTS_PATIENT_EDIT_RoR_OUTREACH =
	'care.patients.patient_edit_ror_outreach';
export const CARE_PATIENTS_PATIENT_ENCOUNTER_BILLING =
	'care.patients.patient_encounter_billing';
export const CARE_PATIENTS_PATIENT_ENCOUNTER_ADDITIONAL_INFO =
	'care.patients.patient_encounter_additional_info';
export const CARE_PATIENTS_PATIENT_ENCOUNTER_PROVIDER_OPERATIONS =
	'care.patients.patient_encounter_provider_operations';
export const CARE_PATIENTS_ENCOUNTER_ADDINFOSEC_EDIT =
	'care.patients.encounter.addinfosec_edit';
export const CARE_PATIENTS_ENCOUNTER_NOTES_EDIT =
	'care.patients.encounter.notes_edit';
export const CARE_PATIENTS_ENCOUNTER_GENETIC_TEST_CRITERIA_EDIT =
	'care.patients.encounter.genetic_test_criteria_edit';
export const CARE_PATIENTS_PATIENT_ENCOUNTER_LIST =
	'care.patients.patient.encounter_list';
export const CARE_PATIENTS_ENCOUNTER_VISIT_ACTIONS =
	'care.patients.encounter.visit_actions';
export const CARE_PATIENTS_ENTER_TEST_RESULT =
	'care.patients.patient_enter_test_result'; // EHR-4365
export const CARE_PATIENTS_CREATE = 'care.patients.create_patient';
export const CARE_PATIENTS_PATIENT_DETAIL = 'care.patients.patient_detail';
export const CARE_PATIENTS_PATIENT_EDIT = 'care.patients.patient_edit';
export const CARE_PATIENTS_PATIENT_PRIORITY = 'care.patients.patient_priority';
export const CARE_PATIENTS_PATIENT_STRIPE_PAYMENT =
	'care.patients.patient_stripe'; // EHR-4403
export const CARE_PATIENTS_PATIENT_DOCUMENT_SHARE =
	'care.patients.patient_document_share';
export const CARE_PATIENTS_PATIENT_DOCUMENT_ARCHIVE =
	'care.patients.patient_document_archive';
export const CARE_PATIENTS_PATIENT_DOCUMENT_TYPE_UPDATE =
	'care.patients.patient_document.update_type';
export const CARE_PATIENTS_PATIENT_DOCUMENT_SETTINGS =
	'care.patients.patient.document_settings';
export const CARE_PATIENTS_PATIENT_GDOC_TOOLBAR =
	'care.patients.patient.gdoc.settings';
export const CARE_PATIENTS_PATIENT_DOCUMENT_RELEASE =
	'care.patients.patient_document_release';
export const CARE_PATIENTS_PATIENT_DOCUMENT_LOG_RELEASE =
	'care.patients.patient_document_log_release';
export const CARE_PATIENTS_PATIENT_DOCUMENT_AUDIT_TRAIL =
	'care.patients.patient_document_audit_trail';
export const CARE_PATIENTS_PATIENT_DOCUMENT_AUDIT_TRAIL_RETRACT =
	'care.patients.patient_document_audit_trail_retract';
export const CARE_PATIENTS_PATIENT_DOCUMENT_EDIT_DOC =
	'care.patients.patient_document.edit_document';

export const CARE_APPOINTMENTS_LIST = 'care.appointments.list';
export const CARE_APPOINTMENTS_SCHEDULING = 'care.appointments.scheduling';
export const CARE_APPOINTMENTS_APPOINTMENT_RESCHEDULING =
	'care.appointments.reschedule';
export const CARE_APPOINTMENTS_APPOINTMENT_CANCEL = 'care.appointments.cancel';
export const CARE_APPOINTMENTS_APPOINTMENT_COMPLETE =
	'care.appointments.completeappointment';
export const CARE_APPOINTMENTS_APPOINTMENT_MODALITY =
	'care.appointments.update_modality';

export const CARE_PATIENTS_NEEDS_INSURANCE_SELECTION =
	'care.patients.needs_insurance_selection'; // EHR-4404
export const CARE_PATIENTS_PATIENT_OUTREACH = 'care.patients.add_outreach';

export const CARE_TEST_ACTIONS = 'care.test_actions';

export const FINANCE_BILLING = 'finance.billing';
export const FINANCE_INSURANCE = 'finance.insurance';

export const FINANCE_BILLING_EXPORT = 'finance.billing.billing_export';
export const FINANCE_BILLING_PATIENT_PATIENT_INVOICE =
	'finance.billing.billing_patient_invoice';
export const FINANCE_BILLING_PATIENT_PATIENT_INSURANCE =
	'finance.billing.billing_patient_insurance';
export const FINANCE_BILLING_PATIENT_PATIENT_PARTNER_INVOICE =
	'finance.billing.billing_partner_invoice';

export const FINANCE_INSURANCE_LIST = 'finance.insurance.list';
export const FINANCE_INSURANCE_EDIT = 'finance.insurance.edit';
export const FINANCE_INSURANCE_EDIT_COMPANY_NAME =
	'finance.insurance.edit.companyname';
export const FINANCE_INSURANCE_ADD = 'finance.insurance.add';

export const OPERATIONS_TESTS = 'operations.test_management';
export const OPERATIONS_REFERRAL_PROGRAMS = 'operations.referral_programs';
export const OPERATIONS_REFERRAL_PROGRAMS_DETAIL =
	'operations.referral_programs.detail';
export const OPERATIONS_REPORTS = 'operations.reports';
export const OPERATIONS_RISE = 'operations.rise';
export const OPERATIONS_TEMPLATE_MANAGEMENT = 'operations.template_management';
export const OPERATIONS_EXTERNAL_PROVIDERS = 'operations.external_providers';
export const OPERATIONS_USER_ACCOUNT_MANAGEMENT =
	'operations.user_account_management';

export const OPERATIONS_TESTS_LIST = 'operations.test_management.test.list';
export const OPERATIONS_TESTS_TEST_DETAIL =
	'operations.test_management.test.view';
export const OPERATIONS_TESTS_ADD_TEST = 'operations.test_management.test.add';

export const OPERATIONS_REPORTS_COMPLETED_ENCOUNTERS =
	'operations.reports.reports_completed_encounters';
export const OPERATIONS_REPORTS_SCHEDULING_SLOTS =
	'operations.reports.reports_scheduling_slots';
export const OPERATIONS_REPORTS_APPOINTMENT_REPORTS =
	'operations.reports.reports_appointment_reports';
export const OPERATIONS_REPORTS_REFERRAL_STATUS =
	'operations.reports.reports_referral_status';
export const OPERATIONS_REPORTS_NEW_PATIENTS =
	'operations.reports.reports_new_patients';
export const RELEASE_NOTES = 'release_notes';

export const ROLE_COUNSELOR = 'Counselor'; //GC
export const ROLE_GCA = 'GCA'; //GC
export const ROLE_PHYSICIAN = 'Physician'; //MD
export const ROLE_CARECOORDINATOR = 'Care Coordinator'; //CC
export const ROLE_PHARMAD = 'PharmD'; //PhamrD
export const ROLE_EXTERNALUSER = 'External Provider - FULL ACCESS'; //External user full access
export const ROLE_EXTERNALUSER_READ_ONLY = 'External Provider - READ ONLY';
export const ROLE_EXTERNALUSER_LAB = 'External Lab';
export const ROLE_NOACCESS = 'No Access'; // No Access
export const ROLE_BILLING_COORDINATOR = 'Billing Coordinator';
export const ROLE_PRODENG = 'ProdEng'; //Product/Engineering
export const ROLE_SUPERUSER = 'Superuser'; //Superuser

export const roleNamesOverride = {
	[ROLE_COUNSELOR]: 'Counselor',
	[ROLE_GCA]: 'GCA',
	[ROLE_PHYSICIAN]: 'Physician',
	[ROLE_CARECOORDINATOR]: 'Care Coordinator',
	[ROLE_PHARMAD]: 'PharmD',
	[ROLE_EXTERNALUSER]: 'External Provider',
	[ROLE_EXTERNALUSER_READ_ONLY]: 'External Provider',
	[ROLE_EXTERNALUSER_LAB]: 'External Lab',
	[ROLE_NOACCESS]: 'No Access',
	[ROLE_SUPERUSER]: 'Superuser',
};

export function isExternalRole(role) {
	return new Set([
		ROLE_EXTERNALUSER,
		ROLE_EXTERNALUSER_READ_ONLY,
		ROLE_EXTERNALUSER_LAB,
	]).has(role);
}

export function isInternalRole(role) {
	return new Set([
		ROLE_COUNSELOR,
		ROLE_GCA,
		ROLE_CARECOORDINATOR,
		ROLE_PHARMAD,
		ROLE_BILLING_COORDINATOR,
		ROLE_PRODENG,
	]).has(role);
}

export function isPhysicianRole(role) {
	return new Set([ROLE_PHYSICIAN]).has(role);
}

export function isBillingCoordinator(role) {
	return new Set([ROLE_BILLING_COORDINATOR]).has(role);
}

export function isCCGCOrProdeng(role) {
	return new Set([
		ROLE_COUNSELOR,
		ROLE_GCA,
		ROLE_CARECOORDINATOR,
		ROLE_PRODENG,
	]).has(role);
}

export function isSuperUserRole(role) {
	return new Set([ROLE_SUPERUSER]).has(role);
}

export function isGCRole(role) {
	return new Set([ROLE_COUNSELOR, ROLE_GCA]).has(role);
}

export function hasPermission(allowedPemissions, permission) {
	const access = get(allowedPemissions, permission, 'hidden');
	return new Set([FULL_ACCESS, READ_ONLY]).has(access) ? true : false;
}
