import React from 'react';

import ReactSVG from 'react-svg';
import xIcon from './../../../assets/exit-alert.svg';
import _ from 'lodash';

class CreateOutreachModalTopRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		return (
			<div className='patientCreateOutreachModalTopRow'>
				<div
					style={{
						fontFamily: 'RionaSans-Bold',
						fontSize: '16px',
						color: '#262837',
					}}
				>
					{_.get(this, ['props', 'header'], 'Referral Outreach')}
				</div>
				<div
					className='chargeModalClose'
					style={{ position: 'relative', top: '-2px' }}
					onClick={(e) => {
						this.props.close();
					}}
				>
					<ReactSVG
						src={xIcon}
						style={{
							position: 'relative',
							bottom: '1px',
							right: '1px',
						}}
					/>
				</div>
			</div>
		);
	}
}

export default CreateOutreachModalTopRow;
