//React & Redux
import React from 'react';

//Lodash
import { get, isEmpty, isNil } from 'lodash';

//Utils
import {
	encounterFieldDisplay,
	getEncounterSpecificTime,
	EncounterDateFieldsDisplay,
	GetMultiSelectvalue,
	GetSelectvalue,
	getProviderName,
	getProviderIcon,
	encounterFreeTextFieldDisplay,
} from '../Common/utilsfunctions';

//Components
import ICDTag from '../../../ChargeInformation/ICDTag.js';

const TRODetailSegment = (props) => {
	let medicalCodes = get(props, 'encounter.medical_codes', []) || [];
	medicalCodes = medicalCodes.slice().sort((a, b) => {
		if (get(a, 'code_name', '') < get(b, 'code_name', '')) return -1;
		else return 1;
	});
	const medicalCodesDisplay =
		medicalCodes.length > 0
			? medicalCodes.map((el) => {
					return (
						<ICDTag
							icdCode={el}
							toggleCodeSelection={() => {}}
							hasX={false}
						/>
					);
			  })
			: '--';

	return (
		<div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Visit State
						</div>
					</div>

					<div className='segment-col'>
						<div className='encounter-field-header'>
							Date & Time Of Service
						</div>
					</div>

					<div className='segment-col'>
						<div className='encounter-field-header'>Specialty</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Visit Provider
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Visit Status
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{get(props, ['encounter', 'state_of_visit'], '--')}
						</div>
					</div>

					<div className='segment-col'>
						<div className='encounter-field-value'>
							{getEncounterSpecificTime(
								get(
									props,
									['encounter', 'date_of_service'],
									''
								),
								get(props, ['encounter', 'timezone'], '')
							)}
						</div>
					</div>

					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'encounter',
								'vsee_specialty',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{getProviderIcon(props, [
								'encounter',
								'visit_provider',
							]) != null && (
								<img
									src={getProviderIcon(props, [
										'encounter',
										'visit_provider',
									])}
									alt=''
								/>
							)}
							{getProviderName(
								props,
								['encounter', 'visit_provider'],
								get(props, ['visit_providerenums'], [])
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'encounter',
								'visit_status',
							])}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Records Requested
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Records Received
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							ICD-10 Code(s) (Visit)
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'records_requested'],
								get(
									props,
									['visitsschema', 'records_requested'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'records_received'],
								get(
									props,
									['visitsschema', 'records_received'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'row',
								flexWrap: 'wrap',
							}}
						>
							{medicalCodesDisplay}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Direct Clinical Care Time (minutes)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Pre-Session Indirect Time (minutes)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Post-Session Indirect Time (minutes)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Post-Test Result CAP Time Spent (minutes)
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'billingencounter',
								'direct_cc_minutes',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'billingencounter',
								'indirect_cc_minutes',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'billingencounter',
								'post_session_indirect_cc_minutes',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'billingencounter',
								'post_negative_minutes',
							])}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Result CAP Completed Date
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Result CAP Sent To Patient On
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Result CAP Sent To PCP On
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'encounter',
								'followup_cap_completed_date',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{!isNil(
								get(props, ['encounter', 'cap_sent_to_patient'])
							) &&
							!isNil(
								get(props, [
									'encounter',
									'cap_release_method_patient',
								])
							) &&
							!isEmpty(
								get(props, ['encounter', 'cap_sent_to_patient'])
							) &&
							!isEmpty(
								get(props, [
									'encounter',
									'cap_release_method_patient',
								])
							)
								? EncounterDateFieldsDisplay(props, [
										'encounter',
										'cap_sent_to_patient',
								  ]) +
								  ' via ' +
								  GetMultiSelectvalue(
										props,
										[
											'encounter',
											'cap_release_method_patient',
										],
										get(
											props,
											[
												'visitsschema',
												'cap_release_method_patient',
											],
											[]
										)
								  )
								: '--'}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{!isNil(
								get(props, ['encounter', 'cap_sent_date_pcp'])
							) &&
							!isNil(
								get(props, [
									'encounter',
									'cap_release_method_pcp',
								])
							) &&
							!isEmpty(
								get(props, ['encounter', 'cap_sent_date_pcp'])
							) &&
							!isEmpty(
								get(props, [
									'encounter',
									'cap_release_method_pcp',
								])
							)
								? EncounterDateFieldsDisplay(props, [
										'encounter',
										'cap_sent_date_pcp',
								  ]) +
								  ' via ' +
								  GetMultiSelectvalue(
										props,
										['encounter', 'cap_release_method_pcp'],
										get(
											props,
											[
												'visitsschema',
												'cap_release_method_pcp',
											],
											[]
										)
								  )
								: '--'}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TRODetailSegment;
