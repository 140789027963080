import React from 'react';

import { Page } from '../Common/V3/Page';

import { Header } from './components/Header';
import { Table } from './components/Table';

import {
	useReferralDashboardData,
	ReferralPageContext,
	useSelectedItems,
} from './hooks';

import './Page.scss';

export function ReferralDashboard() {
	const referralDashboardData = useReferralDashboardData();
	const selectedItems = useSelectedItems();

	return (
		<Page className='referral-dashboard'>
			<ReferralPageContext.Provider
				value={{ referralDashboardData, selectedItems }}
			>
				<Header />
				<Table />
			</ReferralPageContext.Provider>
		</Page>
	);
}
