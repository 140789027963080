import * as actionTypes from './actionTypes';
import cfDNAPrenatalScreeningService from '../service/cfDNAPrenatalScreening';

const addCFDNAPrenatalScreeningFindingLoading = () => {
	return {
		type: actionTypes.ADD_CFDNAPRENATAL_SCREENING_FINDING_LOADING,
	};
};

const addCFDNAPrenatalScreeningFindingSuccess = (payload) => {
	return {
		type: actionTypes.ADD_CFDNAPRENATAL_SCREENING_FINDING_SUCCESS,
		payload,
	};
};

const addCFDNAPrenatalScreeningFindingError = (payload) => {
	return {
		type: actionTypes.ADD_CFDNAPRENATAL_SCREENING_FINDING_ERROR,
		payload,
	};
};

export function addCFDNAPrenatalScreeningFinding(data, diagnosticReportId) {
	return (dispatch) => {
		dispatch(addCFDNAPrenatalScreeningFindingLoading());
		return cfDNAPrenatalScreeningService
			.addCFDNAPrenatalScreeningFinding(data, diagnosticReportId)
			.then((res) => {
				dispatch(
					addCFDNAPrenatalScreeningFindingSuccess(
						res?.data?.addDiagnosticFinding?.finding?.id || ''
					)
				);
			})
			.catch((err) => {
				dispatch(addCFDNAPrenatalScreeningFindingError(err));
			});
	};
}

export function createDiagnosticReportAndAddCFDNAPrenatalScreeningFinding(
	data,
	patientId,
	testOrderId
) {
	return (dispatch) => {
		dispatch(addCFDNAPrenatalScreeningFindingLoading());
		return cfDNAPrenatalScreeningService
			.createDiagnosticReport(patientId, testOrderId)
			.then((res) => {
				const diagnosticReportId =
					res?.data?.addDiagnosticReport?.diagnosticReport?.id ||
					null;
				dispatch(
					addCFDNAPrenatalScreeningFinding(data, diagnosticReportId)
				);
			})
			.catch((err) => {
				dispatch(addCFDNAPrenatalScreeningFindingError(err));
			});
	};
}
