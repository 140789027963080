//React & Redux
import React, { useState } from 'react';

//Components
import ViewGeneDetails from './ViewGeneDetails';
import EditGeneDetails from './EditGeneDetails';
import ViewCFDNACancerGeneDetail from './ViewCFDNACancerGeneDetail';
import ViewRiskAssessmentPredictions from './ViewRiskAssessmentPredictions';
import ViewDiscreteVariant from './ViewDiscreteVariant';
import ViewStructuralVariant from './ViewStructuralVariant';
import ViewNucleotideRepeatVariant from './ViewNucleotideRepeatVariant';
import ViewCFDNAPrenatalScreening from './ViewCFDNAPrenatalScreening';

const ListGene = (props) => {
	const {
		addDiagnosticFindingClinicalNoteError,
		addDiagnosticFindingClinicalNoteLoading,
		addNewDiagnosticFindingClinicalNote,
		callDeleteLastGeneResultFunc,
		callupdateGeneTestFunc,
		clearingGeneticsResultsGenes_Edit,
		data,
		diagnosticReports: {
			discreteVariantFinding,
			observationFinding,
			riskAssessmentFinding,
			structuralVariantFinding,
			nucleotideRepeatVariantFinding,
			cfDNAPrenatalObservationFinding,
		},
		enqueueSnackbar,
		geneEnum_Edit,
		geneList,
		genesEnum,
		getGeneticResultGene_Edit,
		interpretation,
		nonGeneEnum,
		showAddEditDiscreteVariantModalFunc,
		showAddEditStructuralVariantModalFunc,
		showAddEditNucleotideRepeatVariantModalFunc,
		showcfDNACancerModal,
		showViewDiscreteVariantFullDetailFunc,
		showViewStructuralVariantFullDetailFunc,
		showViewCFDNAPrenatalScreeningFullDetailFunc,
		showViewNucleotideRepeatVariantFullDetailFunc,
		updategeneprops,
		zygosity,
	} = props;

	const [id, setId] = useState(0);

	const editGeneResult = (id) => {
		setId(id);
	};

	const isCancel = (geneUUID) => {
		if (id == geneUUID) {
			setId(0);
		}
	};

	return (
		<>
			{(discreteVariantFinding || []).map((finding) => {
				return (
					finding.dnaChangeType !== 'WILD_TYPE' && (
						<ViewDiscreteVariant
							{...{
								key: finding.uuid,
								addDiagnosticFindingClinicalNoteError,
								addDiagnosticFindingClinicalNoteLoading,
								addNewDiagnosticFindingClinicalNote,
								finding,
								showAddEditDiscreteVariantModalFunc,
								showViewDiscreteVariantFullDetailFunc,
							}}
						/>
					)
				);
			})}
			{(structuralVariantFinding || []).map((finding) => {
				return (
					<ViewStructuralVariant
						{...{
							key: finding.uuid,
							addDiagnosticFindingClinicalNoteError,
							addDiagnosticFindingClinicalNoteLoading,
							addNewDiagnosticFindingClinicalNote,
							finding,
							showAddEditStructuralVariantModalFunc,
							showViewStructuralVariantFullDetailFunc,
						}}
					/>
				);
			})}
			{(nucleotideRepeatVariantFinding || []).map((finding) => {
				return (
					<ViewNucleotideRepeatVariant
						{...{
							key: finding.uuid,
							addDiagnosticFindingClinicalNoteError,
							addDiagnosticFindingClinicalNoteLoading,
							addNewDiagnosticFindingClinicalNote,
							finding,
							showAddEditNucleotideRepeatVariantModalFunc,
							showViewNucleotideRepeatVariantFullDetailFunc,
						}}
					/>
				);
			})}
			{(cfDNAPrenatalObservationFinding || []).length > 0 && (
				<ViewCFDNAPrenatalScreening
					showViewCFDNAPrenatalScreeningFullDetailFunc={
						showViewCFDNAPrenatalScreeningFullDetailFunc
					}
					cfDNAPrenatalObservationFinding={
						cfDNAPrenatalObservationFinding
					}
					addDiagnosticFindingClinicalNoteError={
						addDiagnosticFindingClinicalNoteError
					}
					addDiagnosticFindingClinicalNoteLoading={
						addDiagnosticFindingClinicalNoteLoading
					}
					addNewDiagnosticFindingClinicalNote={
						addNewDiagnosticFindingClinicalNote
					}
				/>
			)}
			{(observationFinding || []).map((finding) => {
				return (
					<ViewCFDNACancerGeneDetail
						{...{
							key: finding.uuid,
							addDiagnosticFindingClinicalNoteError,
							addDiagnosticFindingClinicalNoteLoading,
							addNewDiagnosticFindingClinicalNote,
							finding,
							showcfDNACancerModal,
						}}
					/>
				);
			})}
			{(riskAssessmentFinding || []).map((finding) => {
				return (
					<ViewRiskAssessmentPredictions
						{...{
							key: finding.uuid,
							addDiagnosticFindingClinicalNoteError,
							addDiagnosticFindingClinicalNoteLoading,
							addNewDiagnosticFindingClinicalNote,
							riskAssessmentPredictionsReports: finding,
						}}
					/>
				);
			})}
			{geneList.map((item) => {
				return !(id === item.geneUUID) ? (
					<ViewGeneDetails
						{...{
							key: item.geneUUID,
							editGeneResult: () => editGeneResult(item.geneUUID),
							interpretation,
							item,
							nonGeneEnum,
							zygosity,
						}}
					/>
				) : (
					<EditGeneDetails
						{...{
							key: item.geneUUID,
							callDeleteLastGeneResultFunc,
							clearingGeneticsResultsGenes_Edit,
							data,
							enqueueSnackbar,
							geneEnum_Edit,
							geneList,
							genesEnum,
							getGeneticResultGene_Edit,
							interpretation,
							isCancel: () => isCancel(item.geneUUID),
							item,
							nonGeneEnum,
							updateGeneTestResultFunc: (data) =>
								callupdateGeneTestFunc(item.geneUUID, data),
							updategeneprops,
							zygosity,
							discreteVariantFinding,
							observationFinding,
							riskAssessmentFinding,
							structuralVariantFinding,
							cfDNAPrenatalObservationFinding,
							nucleotideRepeatVariantFinding,
						}}
					/>
				);
			})}
		</>
	);
};
export default ListGene;
