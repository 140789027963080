//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Images
import ReactSVG from 'react-svg';
import stopwatchIcon from './../../assets/stopwatch-icon.svg';
import restartIcon from './../../assets/stopwatch-restart.svg';
import startIcon from './../../assets/stopwatch-start.svg';
import pauseIcon from './../../assets/stopwatch-pause.svg';
import stopIcon from './../../assets/stopwatch-stop.svg';

//Styling
import './stopwatch.css';

class Stopwatch extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			timerExtended: false,
			timerRunning: false,
			currentHour: 0,
			currentMinute: 0,
			currentSecond: 0,
			currentTime: null,
			timerID: null,
		};
		//bindings
		this.determineContainerClass = this.determineContainerClass.bind(this);
		this.handleTimerClick = this.handleTimerClick.bind(this);
		this.expandTimer = this.expandTimer.bind(this);
		this.retractTimer = this.retractTimer.bind(this);
		this.startTimer = this.startTimer.bind(this);
		this.pauseTimer = this.pauseTimer.bind(this);
		this.resetTimer = this.resetTimer.bind(this);
		this.setTimerData = this.setTimerData.bind(this);
		this.stopTimerData = this.stopTimerData.bind(this);
		this.getCurrentTime = this.getCurrentTime.bind(this);
		this.isEmptyTime = this.isEmptyTime.bind(this);
		this.setTime = this.setTime.bind(this);
	}
	//functions

	determineContainerClass() {
		const timerExtended = get(this, 'state.timerExtended');
		const basicClass = 'stopwatchContainer';
		const extendedClass = timerExtended ? 'stopwatchContainerExtended' : '';

		if (!timerExtended) {
			return basicClass;
		} else {
			return basicClass + ' ' + extendedClass;
		}
	}

	handleTimerClick() {
		const timerExtended = get(this, 'state.timerExtended', false);
		if (timerExtended) {
			this.retractTimer();
		} else {
			this.expandTimer();
		}
	}

	expandTimer() {
		this.setState({ timerExtended: true });
	}

	retractTimer() {
		this.setState({ timerExtended: false }, this.resetTimer);
	}

	startTimer() {
		this.setState({ timerRunning: true }, this.setTimerData);
	}

	pauseTimer() {
		this.setState({ timerRunning: false }, this.stopTimerData);
	}

	resetTimer() {
		this.setState(
			{
				currentHour: 0,
				currentMinute: 0,
				currentSecond: 0,
				currentTime: null,
				timerRunning: false,
			},
			() => this.stopTimerData(true)
		);
	}

	setTimerData() {
		const timerID = setInterval(this.setTime, 1000);
		const currentHour = get(this, 'state.currentHour', null);
		const currentMinute = get(this, 'state.currentMinute', null);
		const currentSecond = get(this, 'state.currentSecond', null);
		let timeLapse = 0;

		const hasPreviousTime = [
			currentHour,
			currentMinute,
			currentSecond,
		].some((el) => el);
		if (hasPreviousTime) {
			timeLapse =
				currentSecond * 1000 +
				currentMinute * 60 * 1000 +
				currentHour * 60 * 60 * 1000;
		}
		const currentTime = new Date().getTime() - timeLapse;
		this.setState({ timerID, currentTime });
	}

	stopTimerData(flag) {
		const timerID = get(this, 'state.timerID', null);
		const currentTime = get(this, 'state.currentTime', null);
		if (timerID) {
			this.setState(
				{ timerID: null, currentTime: flag ? null : currentTime },
				() => clearInterval(timerID)
			);
		}
	}

	getCurrentTime() {
		const currentHour = JSON.stringify(
			get(this, 'state.currentHour', null) || 0
		);
		let currentMinute = JSON.stringify(
			get(this, 'state.currentMinute', null) || 0
		);
		let currentSecond = JSON.stringify(
			get(this, 'state.currentSecond', null) || 0
		);

		if (get(currentMinute, 'length', 0) === 1)
			currentMinute = '0' + currentMinute;
		if (get(currentSecond, 'length', 0) === 1)
			currentSecond = '0' + currentSecond;

		return [currentHour, currentMinute, currentSecond];
	}

	isEmptyTime() {
		const currentHour = JSON.stringify(
			get(this, 'state.currentHour', null) || 0
		);
		const currentMinute = JSON.stringify(
			get(this, 'state.currentMinute', null) || 0
		);
		const currentSecond = JSON.stringify(
			get(this, 'state.currentSecond', null) || 0
		);

		return currentHour + currentMinute + currentSecond === 0;
	}

	setTime() {
		const currentTime =
			get(this, 'state.currentTime', null) || new Date().getTime();
		const newTime = new Date().getTime();

		const formattedMilliseconds = newTime - currentTime;
		const currentSecond = Math.floor(formattedMilliseconds / 1000) % 60;
		const currentMinute =
			Math.floor(formattedMilliseconds / 1000 / 60) % 60;
		const currentHourUnMod = Math.floor(
			formattedMilliseconds / 1000 / 60 / 60
		);

		if (currentHourUnMod === 10) {
			this.resetTimer();
		} else {
			const currentHour = currentHourUnMod % 10;
			this.setState({
				currentHour,
				currentMinute,
				currentSecond,
			});
		}
	}

	render() {
		const containerClass = this.determineContainerClass();
		const timerExtended = get(this, 'state.timerExtended');
		const timerRunning = get(this, 'state.timerRunning');
		const stopwatchDisplayNumberClass = 'stopwatchClockDisplayNumber';
		const iconButtonClass = 'stopwatchIconButton';
		const playButtonClass = 'stopwatchIconPlay';
		const pauseButtonClass = 'stopwatchIconPause';
		const currentTime = this.getCurrentTime();
		const currentHour = get(currentTime, '[0]', null) || 0;
		const currentMinute = get(currentTime, '[1]', null) || 0;
		const currentSecond = get(currentTime, '[2]', null) || 0;
		return (
			<div className={containerClass}>
				{timerExtended && (
					<React.Fragment>
						<div className='stopwatchClockDisplayContainer'>
							<span
								className={stopwatchDisplayNumberClass}
								style={{ minWidth: '10px' }}
							>
								{currentHour}
							</span>
							:
							<span className={stopwatchDisplayNumberClass}>
								{currentMinute}
							</span>
							:
							<span className={stopwatchDisplayNumberClass}>
								{currentSecond}
							</span>
						</div>
						{timerRunning ? (
							<div
								className={
									iconButtonClass + ' ' + pauseButtonClass
								}
								onClick={this.pauseTimer}
							>
								<ReactSVG src={pauseIcon} />
							</div>
						) : (
							<div
								className={
									iconButtonClass + ' ' + playButtonClass
								}
								onClick={this.startTimer}
							>
								<ReactSVG src={startIcon} />
							</div>
						)}
						<div
							className={iconButtonClass}
							onClick={this.resetTimer}
							style={{ minWidth: '20px' }}
						>
							<ReactSVG src={restartIcon} />
						</div>
					</React.Fragment>
				)}
				<div className={iconButtonClass}>
					<ReactSVG
						src={stopwatchIcon}
						onClick={this.handleTimerClick}
					/>
				</div>
			</div>
		);
	}
}

export default Stopwatch;
