import axios from 'axios';

/**
 * @typedef {object} ReferralFilterData
 * @property {string[]} data.consultations
 * @property {number} data.limit
 * @property {number} data.offset
 * @property {string} data.order_by
 * @property {string} data.order_type
 * @property {string} data.q
 * @property {string} data.referral_date_from
 * @property {string} data.referral_date_to
 * @property {string[]} data.referral_programs
 * @property {string[]} data.specialties
 * @property {string[]} data.status
 * @property {string[]} data.substatus
 * @property {any[]} data.tags
 */

/**
 * @param {ReferralFilterData} data
 */
async function getReferralData(data) {
	const response = await axios.post('/api/referral-dashboard', data);
	return {
		data: hydrateReferralDashboardData(response.data.data),
		paging: {
			limit: response.data.limit,
			offset: response.data.offset,
			start: response.data.start,
			end: response.data.end,
			total: response.data.total,
			hasNext: response.data.has_next,
			hasPrev: response.data.has_prev,
		},
	};
}

function convertPhones(data) {
	return Object.entries(data).reduce((acc, [key, value]) => {
		acc[key] = {
			consentToText: value.consent_to_text,
			type: value.type,
			value: value.value,
		};
		return acc;
	}, {});
}

function convertTags(data) {
	return data.map((item) => ({
		name: item.name,
		uuid: item.uuid,
		tagId: item.tag_id,
		displayName: item.display_name,
		tagProperties: item.tag_properties,
	}));
}

function convertLastOutreach(data) {
	if (!data) {
		return null;
	}

	return Object.entries(data).reduce((acc, [key, value]) => {
		acc[key] = {
			dateTime: value.date_time,
			medium: value.medium,
			subtype: value.subtype,
			type: value.type,
			uuid: value.uuid,
		};
		return acc;
	}, {});
}

function convertPatientData(data) {
	return {
		firstName: data.first_name,
		lastName: data.last_name,
		email: data.email,
		uuid: data.uuid,
		phones: data.phones ? convertPhones(data.phones) : {},
		referralProgram: data.referral_program,
		tags: convertTags(data.tags),
		assignedGca: data.assigned_gca,
		assignedCc: data.assigned_cc,
		hasDuplicatePatients: data.has_duplicate_patients,
		hasGuardian: data.has_guardian,
		highPriority: data.high_priority,
	};
}

function hydrateReferralDashboardData(data) {
	if (!Array.isArray(data)) {
		return [];
	}
	return data.map((item) => ({
		id: item.id,
		uuid: item.uuid,
		createdAt: item.created_at,
		patient: convertPatientData(item.patient),
		specialty: item.specialty,
		consultation: item.consultation,
		encounterUUID: item.encounter_uuid,
		status: item.status,
		substatus: item.substatus,
		lastOutreach: convertLastOutreach(item.last_outreach),
	}));
}

/**
 * @param {ReferralFilterData} data
 */
function exportReferralData(data) {
	return axios.post('/api/referral-dashboard/export', data);
}

export default { exportReferralData, getReferralData };
