//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	encounterFreeTextFieldDisplay,
	valueWithDollar,
	EncounterDateFieldsDisplay,
	GetSelectvalue,
	encounterFieldDisplay,
} from '../Common/utilsfunctions';

const BillingSegment = (props) => {
	const patientFeeRefundReasonOther = _.get(
		props,
		['billingencounter', 'patient_fee_refund_reason_other'],
		null
	);
	return (
		<div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Billing Status (Patient)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Billing Date (Patient)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Billing Amount (Patient)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Post-Test Result CAP Time Spent (minutes)
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['billingencounter', 'billing_status'],
								_.get(
									props,
									['labsschema', 'billing_status'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'billingencounter',
								'patient_billing_date',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{valueWithDollar(props, [
								'billingencounter',
								'patient_billing_amount',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'billingencounter',
								'post_negative_minutes',
							])}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Patient Fee Refund
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Patient Fee Refund Processed Date
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Patient Fee Refund Reason
						</div>
					</div>
					{patientFeeRefundReasonOther && (
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Other Refund Reason
							</div>
						</div>
					)}
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{valueWithDollar(props, [
								'billingencounter',
								'patient_fee_refund',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'billingencounter',
								'patient_fee_refund_processed_date',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFieldDisplay(props, [
								'billingencounter',
								'patient_fee_refund_reason',
							])}
						</div>
					</div>
					{patientFeeRefundReasonOther && (
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{encounterFieldDisplay(props, [
									'billingencounter',
									'patient_fee_refund_reason_other',
								])}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className='segment-row segment-row-title'>
				<div className='segment-col'>BILLING NOTES</div>
			</div>
			<div className='segment-row'>
				<div
					className='segment-col encounter-field-billing-value'
					style={{
						width: '100%',
						whiteSpace: 'pre-wrap',
						wordBreak: 'break-word',
					}}
				>
					{encounterFreeTextFieldDisplay(props, [
						'billingencounter',
						'billing_notes',
					])}
				</div>
			</div>
		</div>
	);
};

export default BillingSegment;
