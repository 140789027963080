//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import HeaderArrow from './../../assets/menu-breadcrumb.svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//UI Libraries
import { Loading } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import AddTestInfo from './AddTestInfo.js';
import AddTestAdditional from './AddTestAdditional.js';
import AddTestConfirm from './AddTestConfirm.js';

//Styles
import './testdetail.css';

class AddTest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			stages: ['Test info', 'Additional', 'Confirm'],
			currentIdx: 0,
			newTest: {
				genetic_test_code: '',
				currently_offered: '',
				version: '',
				display_name: '',
				init_available_date: null,
				lab_uuid: '',
				lab_test_code: '',
				prenatal: '',
				ngs_vs_snp_assay: '',
				clia_confirm_needed: '',
				opportunity_for_reinterpretation: '',
				global_limitations: '',
				gene_specific_limitations: '',
				comments: '',
				tags: [],
				lab_director: '',
				genetic_test_types_enums_uuid: '',
				paper_form_only: false,
				classification: '',
				lab_order_integration: null,
			},
			selectedTags: new Set(),
			errors: new Set(),
		};
		//bindings
		this.renderCurrentStage = this.renderCurrentStage.bind(this);
		this.advanceStage = this.advanceStage.bind(this);
		this.regressStage = this.regressStage.bind(this);
		this.determineDisabled = this.determineDisabled.bind(this);
		this.renderCurrentButtonRow = this.renderCurrentButtonRow.bind(this);
		this.saveTest = this.saveTest.bind(this);
		this.editNewTest = this.editNewTest.bind(this);
		this.handleTagSelect = this.handleTagSelect.bind(this);
		this.validateFields = this.validateFields.bind(this);
		this.errorCheck = this.errorCheck.bind(this);
	}
	//functions

	componentDidMount() {
		this.props.getLabsEnum();
		this.props.getTestTypes();
		this.props.getTags();
		this.props.getOtherEnums();
	}

	async advanceStage() {
		const errors = await this.errorCheck();
		if (!errors) {
			this.setState({ currentIdx: this.state.currentIdx + 1 });
		}
	}

	regressStage() {
		this.setState({ currentIdx: this.state.currentIdx - 1 });
	}

	determineDisabled() {
		if (this.state.currentIdx === 0) {
			if (
				!this.state.newTest.lab_uuid ||
				!this.state.newTest.display_name ||
				!this.state.newTest.version ||
				!this.state.newTest.currently_offered ||
				!this.state.newTest.classification
			) {
				return true;
			}
		}

		if (this.state.currentIdx === 1) {
			if (
				!this.state.newTest.genetic_test_types_enums_uuid ||
				!this.state.newTest.clia_confirm_needed ||
				!this.state.newTest.prenatal ||
				!this.state.newTest.opportunity_for_reinterpretation
			) {
				return true;
			}
		}

		return false;
	}

	renderCurrentButtonRow() {
		const disabled = this.determineDisabled();
		const disableClass = disabled ? ' testSaveButtonDisabled' : '';

		const cancelBtn = (
			<div className='testCancelButton' onClick={this.props.closeAddTest}>
				Cancel
			</div>
		);

		const backBtn = (
			<div
				className='testCancelButton'
				style={{ width: '59px' }}
				onClick={this.regressStage}
			>
				Back
			</div>
		);

		const continueBtn = (
			<div
				className={'testSaveButton' + disableClass}
				style={{ width: '80px' }}
				onClick={this.advanceStage}
			>
				Continue
			</div>
		);

		const saveBtn = (
			<div className='testSaveButton' onClick={this.saveTest}>
				Save
			</div>
		);

		let button1;
		let button2;
		let marginTop;

		switch (this.state.currentIdx) {
			case 0:
				button1 = cancelBtn;
				button2 = continueBtn;
				marginTop = '6px';
				break;
			case 1:
				button1 = backBtn;
				button2 = continueBtn;
				marginTop = '18px';
				break;
			case 2:
				button1 = backBtn;
				button2 = saveBtn;
				marginTop = '43px';
				break;
			default:
				return <div>Error, unrecognized state.</div>;
		}

		return (
			<div className='addTestButtonRow' style={{ marginTop }}>
				{button1}
				{button2}
			</div>
		);
	}

	renderCurrentStage() {
		switch (this.state.currentIdx) {
			case 0:
				return (
					<AddTestInfo
						newTest={this.state.newTest}
						editNewTest={this.editNewTest}
						labs={this.props.labs}
						otherEnums={this.props.otherEnums}
						errors={this.state.errors}
					/>
				);
			case 1:
				return (
					<AddTestAdditional
						newTest={this.state.newTest}
						editNewTest={this.editNewTest}
						testTypes={this.props.testTypes}
						otherEnums={this.props.otherEnums}
						testTags={this.props.testTags}
						selectedTags={this.state.selectedTags}
						handleTagSelect={this.handleTagSelect}
						errors={this.state.errors}
					/>
				);
			case 2:
				return (
					<AddTestConfirm
						newTest={this.state.newTest}
						editNewTest={this.editNewTest}
						labs={this.props.labs}
						testTypes={this.props.testTypes}
						otherEnums={this.props.otherEnums}
						testTags={this.props.testTags}
						errors={this.state.errors}
					/>
				);
			default:
				return <div>Error, unrecognized state.</div>;
		}
	}

	getPaperTRFFromLab = (lab_uuid) => {
		const labEnums = _.get(this, ['props', 'labs'], []);
		const SelectedObj = _.find(labEnums, (obj) => {
			return _.get(obj, 'key') == lab_uuid;
		});
		if (SelectedObj) {
			return _.get(SelectedObj, 'paper_form_only', false);
		}
		return false;
	};

	editNewTest(header, value) {
		const editedTest = this.state.newTest;
		switch (header) {
			case 'currently_offered':
			case 'lab_test_code':
			case 'clia_confirm_needed':
			case 'prenatal':
			case 'opportunity_for_reinterpretation':
			case 'ngs_vs_snp_assay':
			case 'global_limitations':
			case 'gene_specific_limitations':
			case 'comments':
			case 'display_name':
			case 'version':
			case 'classification':
				editedTest[header] = value;
				break;
			case 'lab_uuid':
				editedTest[header] = value;
				editedTest['paper_form_only'] = this.getPaperTRFFromLab(value);
				break;
			//from enums
			case 'test_type':
				editedTest['genetic_test_types_enums_uuid'] = value;
				break;
			case 'lab_order_integration':
				editedTest['lab_order_integration'] = value;
				break;
			//dates
			case 'init_available_date':
				if (!value) {
					editedTest[header] = value;
					break;
				}
				editedTest[header] = moment(value).format('YYYY-MM-DD');
				break;

			//placeholders
			case 'lab_information':
				// console.log("hit placeholder, can't edit editedTest object to update, unclear on which key:value pair for", header, "value is", value);
				break;
		}
		this.setState({ newTest: editedTest });
	}

	handleTagSelect(e, tag) {
		const tagsSet = this.state.selectedTags || new Set();
		const tagNode = e.target;
		if (tagNode.classList.contains('testTagEditSelected')) {
			tagNode.classList.remove('testTagEditSelected');
			tagNode.classList.add('testTagEdit');
			tagsSet.delete(tag);
		} else {
			tagNode.classList.remove('testTagEdit');
			tagNode.classList.add('testTagEditSelected');
			tagsSet.add(tag);
		}
		this.setState({ selectedTags: tagsSet });
		const newTest = this.state.newTest;
		newTest.tags = Array.from(tagsSet);
		this.setState({ newTest });
	}

	errorCheck() {
		return new Promise((resolve, reject) => {
			const test = this.state.newTest;
			const newErrors = new Set();
			switch (this.state.currentIdx) {
				case 0:
					if (
						!test.lab_uuid ||
						get(test, 'lab_uuid', '').trim().length === 0
					) {
						newErrors.add('lab');
					}

					if (
						!test.display_name ||
						get(test, 'display_name', '').trim().length === 0
					) {
						newErrors.add('display_name');
					}

					if (
						!test.version ||
						get(test, 'version', '').trim().length === 0
					) {
						newErrors.add('version');
					}

					if (
						!test.currently_offered ||
						get(test, 'currently_offered', '').trim().length === 0
					) {
						newErrors.add('currently_offered');
					}

					if (
						!test.classification ||
						get(test, 'classification', '').trim().length === 0
					) {
						newErrors.add('classification');
					}

					break;
				case 1:
					if (
						!test.genetic_test_types_enums_uuid ||
						get(test, 'genetic_test_types_enums_uuid', '').trim()
							.length === 0
					) {
						newErrors.add('test_type');
					}

					if (
						!test.clia_confirm_needed ||
						get(test, 'clia_confirm_needed', '').trim().length === 0
					) {
						newErrors.add('clia');
					}

					if (
						!test.prenatal ||
						get(test, 'prenatal', '').trim().length === 0
					) {
						newErrors.add('prenatal');
					}

					if (
						!test.opportunity_for_reinterpretation ||
						get(test, 'opportunity_for_reinterpretation', '').trim()
							.length === 0
					) {
						newErrors.add('reinterpretation');
					}

					// if (!test.tags || test.tags.length === 0) {
					//   newErrors.add("tags");
					// }

					break;
			}
			this.setState({ errors: newErrors }, () => {
				if (this.state.errors.size > 0) resolve(true);
				else resolve(false);
			});
		});
	}

	saveTest() {
		if (this.validateFields()) {
			const newTest = _.cloneDeep(this.state.newTest);
			for (const key in newTest) {
				if (typeof newTest[key] === 'string')
					newTest[key] = newTest[key].trim();
			}
			newTest.genetic_test_code = '';
			newTest.lab_director = '';
			const data = {
				payload: newTest,
			};
			this.setState({ loading: true });

			this.props.createTest(data);
		} else {
			//show error message
		}
	}

	validateFields() {
		//fields individually
		return true;
	}

	render() {
		return (
			<div className='fullScreenDimmer'>
				<div className='addTestContainer'>
					{this.props.gettingLabs ||
					this.props.gettingTestTypes ||
					this.props.gettingTestTags ||
					this.props.gettingOtherEnums ||
					this.props.creatingTest ? (
						<Loading className='addTestLoader' loading={true} />
					) : (
						<React.Fragment>
							<div className='addTestHeader'>
								<span
									className={
										this.state.currentIdx === 0
											? 'addTestSubheaderSelected'
											: 'addTestSubheader'
									}
									style={{ width: '84px' }}
								>
									Test Info
								</span>
								<span className='addTestSubheaderArrow'>
									<ReactSVG src={HeaderArrow} />
								</span>
								<span
									className={
										this.state.currentIdx === 1
											? 'addTestSubheaderSelected'
											: 'addTestSubheader'
									}
									style={{ width: '99px' }}
								>
									Additional
								</span>
								<span className='addTestSubheaderArrow'>
									<ReactSVG src={HeaderArrow} />
								</span>
								<span
									className={
										this.state.currentIdx === 2
											? 'addTestSubheaderSelected'
											: 'addTestSubheader'
									}
									style={{ width: '78px' }}
								>
									Confirm
								</span>
							</div>
							{this.renderCurrentStage()}
							{this.renderCurrentButtonRow()}
						</React.Fragment>
					)}
				</div>
			</div>
		);
	}
}

export default AddTest;
