//React & Redux
import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';

//Images
import ReactSVG from 'react-svg';
import moreinfoicon from '../../../../assets/purple-more-info.svg';
import timerIcon from '../../../../assets/icons/timer-24.svg';
import timer_offIcon from '../../../../assets/icons/timer-off.svg';
import warningImg from '../../../../assets/milestone-warning.svg';
import add from '../Tests/images/add.svg';

//Lodash
import _ from 'lodash';

//Utils
import {
	createEnumsOptions,
	filterOrderStatus,
	CascaderOptions,
	findCascaderHierarchy,
	getSampleTypeGrouping,
	OutreachWarnMsg,
	OUTREACH_ON_HOLD_WARN_MSG,
	OUTREACH_WAITING_TO_START_WARN_MSG,
	OUTREACH_INPROGRESS_WARN_MSG,
	isValidDateString,
	getMomentDateOrString,
} from '../Encounters/Common/utilsfunctions';

//UI Libraries
import {
	Layout,
	Select,
	Cascader,
	Radio,
	Popover,
	Button,
	Input,
} from 'gm-element-react';

//Other Libraries
import moment from 'moment';
import classnames from 'classnames';
import Datetime from 'react-datetime';

//Components
import TestOrderMilestoneWarning from './TestOrderMilestoneWarning';
import WarningMessageBox from '../../../Common/WarningMessageBox';
import { EncounterDateField } from './DateComponent';
import Pill from '../../../Common/Pill';

//Styles
import './create-test-request-modal.css';

const cascaderfields = {
	order_request_status: 1,
	order_status: 1,
};

const DateTypes = ['date_any', 'date_past', 'date_future'];

const EditTestRequestLabInfoAfterSentToLab = (props) => {
	const {
		groupTestorders,
		mloOrderStatusEnums,
		laborderstatusenums = [],
		testOrderList = [],
		outreachSeqNo = 0,
		holdResultRelease = false,
		encounter,
		onFormChangeForMultipleTestOrder,
		stateGroupTestorders,
		hideModal,
		hasPatientNoInsurance,
		reflexAOEQuestions,
		reflexAOEAnswers,
	} = props;

	const [optionsLabOrderStatusEnum, setOptionsLabOrderStatusEnum] =
		useState(laborderstatusenums);
	const [showWarnMsg, setShowWarnMsg] = useState(false);
	const [optionsSampleTypesEnum, setOptionsSampleTypesEnum] = useState(
		_.isArray(
			createEnumsOptions(
				props,
				['sampletypeenums'],
				'key',
				'display_name',
				'key'
			)
		)
			? createEnumsOptions(
					props,
					['sampletypeenums'],
					'key',
					'display_name',
					'key'
			  )
			: []
	);
	const [optionsBDSalivaKitSentEnum, setOptionsBDSalivaKitSentEnum] =
		useState(
			_.isArray(
				createEnumsOptions(
					props,
					['encounterschema', 'bd_saliva_kit_sent'],
					'key',
					'display_name',
					'key'
				)
			)
				? createEnumsOptions(
						props,
						['encounterschema', 'bd_saliva_kit_sent'],
						'key',
						'display_name',
						'key'
				  )
				: []
		);
	const [optionsBloodDrawRequestEnum, setOptionsBloodDrawRequestEnum] =
		useState(
			_.isArray(
				createEnumsOptions(
					props,
					['encounterschema', 'blood_draw_request'],
					'key',
					'display_name',
					'key'
				)
			)
				? createEnumsOptions(
						props,
						['encounterschema', 'blood_draw_request'],
						'key',
						'display_name',
						'key'
				  )
				: []
		);
	const [optionsBillingMethodEnum, setOptionsBillingMethodEnum] = useState(
		_.isArray(
			createEnumsOptions(
				props,
				['patientbillingmethodenums'],
				'key',
				'display_name',
				'key'
			)
		)
			? createEnumsOptions(
					props,
					['patientbillingmethodenums'],
					'key',
					'display_name',
					'key'
			  )
			: []
	);

	const filterMloOrderStatusEnums = () => {
		const mloorderstatus = mloOrderStatusEnums.map((e) => {
			if (_.get(e, ['children', '0'], null) != null) {
				let statusReason = e.children;
				if (e.key == 'cancelled') {
					statusReason = _.filter(e.children, function (o) {
						return o.key !== 'order_cancelled_by_partner';
					});
				} else if (e.key == 'patient_declined') {
					statusReason = _.filter(e.children, function (o) {
						return o.key !== 'declined_via_partner';
					});
				} else statusReason = e.children;

				return {
					key: e.key,
					display_name: e.display_name,
					children: statusReason,
				};
			} else {
				return e;
			}
		});
		return mloorderstatus;
	};

	const renderTestOrderMilestoneWarning = (testorder, formdata) => {
		return (
			<TestOrderMilestoneWarning
				testorder={testorder}
				propsOrderStatus={_.get(testorder, ['localorderstatus'], null)}
				stateOrderStatus={_.get(formdata, ['order_status'], null)}
				mloOrderStatusEnums={mloOrderStatusEnums}
				positiveResultsToReport={_.get(
					formdata,
					['positive_results_to_report'],
					null
				)}
			/>
		);
	};

	const onFormChangeFunc = (key, value, uuid) => {
		const tempGroupOrders = stateGroupTestorders;
		const tempGroupOrder = stateGroupTestorders[uuid];
		if (key in cascaderfields) {
			tempGroupOrder[key] = _.last(value);
		} else if (key === 'date_test_ordered') {
			if (_.isNil(value) || _.isEmpty(value)) {
				tempGroupOrder[key] = '';
				tempGroupOrder['expected_lab_results_date'] = '';
			} else if (isValidDateString(value)) {
				const momentobj = getMomentDateOrString(value);
				tempGroupOrder['expected_lab_results_date'] = moment.isMoment(
					momentobj
				)
					? momentobj.add(35, 'days').format('YYYY-MM-DD')
					: '';
			}
		} else if (key === 'order_status') {
			tempGroupOrder['positive_results_to_report'] = null;
			tempGroupOrder['is_actionable'] = false;
		} else {
			tempGroupOrder[key] = value;
		}

		if (key === 'positive_results_to_report') {
			tempGroupOrder['is_actionable'] = value;
			setShowWarnMsg(true);
		} else if (key === 'hold_result_release') {
			setShowWarnMsg(true);
		}
		tempGroupOrders[uuid] = tempGroupOrder;
		onFormChangeForMultipleTestOrder(tempGroupOrders);
	};

	const singleOrder = (uuid) => {
		return _.find(groupTestorders, (group) => {
			return group.uuid == uuid;
		});
	};

	const filterLabOrderStatusEnums = (labOrderStatusEnum) => {
		const optionsToSkip = ['lab_order_in_progress'];

		return labOrderStatusEnum.filter((e) => !optionsToSkip.includes(e.key));
	};

	const showTestDispatchExpectedDate = (_formdata) => {
		return !(
			_.get(_formdata, ['order_status'], '') in
			{
				results_ready: 1,
				result_positive_no_genes: 1,
				result_positive_with_genes: 1,
				result_negative: 1,
			}
		);
	};

	const onHandleDateFieldValidate = (key, value, uuid, formdata) => {
		const _formdata = {};
		_formdata[key] = value;
		if (key === 'date_test_ordered') {
			if (_.isNil(value) || _.isEmpty(value)) {
				_formdata[key] = '';
				_formdata['expected_lab_results_date'] = '';
			} else if (isValidDateString(value)) {
				const momentobj = getMomentDateOrString(value);
				_formdata['expected_lab_results_date'] = moment.isMoment(
					momentobj
				)
					? momentobj.add(35, 'days').format('YYYY-MM-DD')
					: '';
			}
		}
		const tempGroupOrders = stateGroupTestorders;
		const tempGroupOrder = stateGroupTestorders[uuid];
		tempGroupOrder[key] = value;
		tempGroupOrders[uuid] = tempGroupOrder;
		onFormChangeForMultipleTestOrder(tempGroupOrders);
	};

	const renderMultipleOutreachWarnMsg = (msg, testNames) => {
		return (
			<Fragment>
				<span>{msg}</span>
				{_.map(testNames, (test, i) => {
					return (
						<span>
							{i + 1}) {test}
						</span>
					);
				})}
			</Fragment>
		);
	};

	const OutreachWarnMsgFunc = (flag = true, formdata, testorder) => {
		const currentTest = flag
			? {
					testorderID: _.get(testorder, ['test_order_uuid'], ''),
					holdResultRelease: _.get(
						encounter,
						['hold_result_release'],
						false
					),
					orderStatus: _.get(formdata, ['order_status'], ''),
					outreachSeqNo: outreachSeqNo,
			  }
			: {
					holdResultRelease: holdResultRelease,
			  };
		return OutreachWarnMsg(testOrderList, currentTest);
	};

	const setResultEntryOutreachWarnMsg = () => {
		const msgObj = OutreachWarnMsgFunc();
		switch (msgObj.warnMsg) {
			case OUTREACH_ON_HOLD_WARN_MSG:
				return {
					warningMsg: 'ROR Outreach will be on HOLD for this test.',
					warningIcon: timer_offIcon,
					boxBGColor: 'bg-gray',
					extndWarnText: 'outreach-hold-warn-text',
					extndWarnIcon: 'outreach-hold-warn-icon',
				};
			case OUTREACH_WAITING_TO_START_WARN_MSG:
				return {
					warningMsg:
						'Once saved, you will have 1 hr to change the result before the applicable RoR Outreach starts.',
					warningIcon: timerIcon,
					boxBGColor: 'bg-yellow',
					extndWarnText: 'outreach-start-warn-text',
					extndWarnIcon: 'outreach-hold-warn-icon',
				};
			case OUTREACH_INPROGRESS_WARN_MSG:
				return {
					warningMsg:
						'This test will be added to the RoR Outreach InProgress.',
					warningIcon: timerIcon,
					boxBGColor: 'bg-yellow',
					extndWarnText: 'outreach-start-warn-text',
					extndWarnIcon: 'outreach-hold-warn-icon',
				};
			default:
				return false;
		}
	};

	const setHoldResultOutreachWarnMsg = (formdata, testorder) => {
		const msgObj = OutreachWarnMsgFunc(formdata, testorder);
		switch (msgObj.warnMsg) {
			case OUTREACH_ON_HOLD_WARN_MSG:
				return {
					warningMsg: renderMultipleOutreachWarnMsg(
						'ROR Outreach will be on HOLD for the following test(s):',
						msgObj.testNames
					),
					warningIcon: timer_offIcon,
					boxBGColor: 'bg-gray',
					extndWarnText: 'outreach-hold-warn-text',
					extndWarnIcon: 'outreach-hold-warn-icon',
					testNames: msgObj.testNames,
				};
			case OUTREACH_WAITING_TO_START_WARN_MSG:
				return {
					warningMsg: renderMultipleOutreachWarnMsg(
						'ROR Outreach will start for the following test(s) in an hour:',
						msgObj.testNames
					),
					warningIcon: timerIcon,
					boxBGColor: 'bg-yellow',
					extndWarnText: 'outreach-start-warn-text',
					extndWarnIcon: 'outreach-hold-warn-icon',
					testNames: msgObj.testNames,
				};
			case OUTREACH_INPROGRESS_WARN_MSG:
				return {
					warningMsg: renderMultipleOutreachWarnMsg(
						'The following test(s) will be added to the RoR Outreach InProgress:',
						msgObj.testNames
					),
					warningIcon: timerIcon,
					boxBGColor: 'bg-yellow',
					extndWarnText: 'outreach-start-warn-text',
					extndWarnIcon: 'outreach-hold-warn-icon',
					testNames: msgObj.testNames,
				};
			default:
				return false;
		}
	};

	const isWarnMsgChangeFunc = () => {
		const oldMsgObj = OutreachWarnMsgFunc(false);
		const newMsgObj = OutreachWarnMsgFunc();
		return _.get(oldMsgObj, 'warnMsg') != _.get(newMsgObj, 'warnMsg');
	};

	const showHoldResultWarnMsg = (formdata, testorder) => {
		const holdResultWarnMsg = setHoldResultOutreachWarnMsg(
			formdata,
			testorder
		);
		const isWarnMsgChange = isWarnMsgChangeFunc();

		if (
			showWarnMsg &&
			isWarnMsgChange &&
			holdResultWarnMsg &&
			_.get(holdResultWarnMsg, ['testNames', 'length'], 0) > 0
		)
			return true;
		return false;
	};

	const showResultEntryWarnMsg = (formdata) => {
		const { order_status, positive_results_to_report } = formdata;
		const statuses = {
			result_negative: 1,
			result_positive_no_genes: 1,
			result_positive_with_genes: 1,
			results_entered: 1,
			results_ready: 1,
		};
		const resultEntryWarnMsg = setResultEntryOutreachWarnMsg();
		const isWarnMsgChange = isWarnMsgChangeFunc();
		const isResultentredTest = order_status in statuses;

		if (
			(isWarnMsgChange || showWarnMsg) &&
			resultEntryWarnMsg &&
			isResultentredTest &&
			!_.isNil(positive_results_to_report)
		)
			return true;
		return false;
	};

	const isTestDispatchDateRequired = (formdata, testorder) => {
		let result = false;

		const statusEnum = {
			sent_to_lab: 1,
		};

		const stateorderstatus = _.get(formdata, ['order_status'], {});
		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);

		if (
			stateorderstatus in statusEnum &&
			!(propsorderstatus in statusEnum)
		) {
			result = true;
		} else {
			result = false;
		}

		return result;
	};

	const isExpectedResultsDateRequired = (formdata, testorder) => {
		let result = false;

		const statusEnum = {
			sent_to_lab: 1,
		};

		const stateorderstatus = _.get(formdata, ['order_status'], {});
		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);

		if (
			stateorderstatus in statusEnum &&
			!(propsorderstatus in statusEnum)
		) {
			result = true;
		} else {
			result = false;
		}

		return result;
	};

	const isResultsReceiptDateRequired = (formdata, testorder) => {
		const statusEnum = {
			results_ready: 1,
			result_positive_no_genes: 1,
			result_positive_with_genes: 1,
			result_negative: 1,
		};

		const propsEnum = {
			results_ready: 1,
			results_entered: 1,
		};

		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);
		const stateorderstatus = _.get(formdata, ['order_status'], {});

		if (propsorderstatus in propsEnum) {
			return false;
		} else if (stateorderstatus in statusEnum) {
			return true;
		}

		return false;
	};

	const isOrderStatusDisabled = (testorder) => {
		const statusEnum = {
			declined_other: 1,
			ins_denied_cost: 1,
			iss_w_test_implications: 1,
			no_fu_from_patient: 1,
			declined_via_partner: 1,
			rejected: 1,
			patient_declined: 1,
			no_test_available: 1,
			rejected_other: 1,
			results_ready: 1,
			results_entered: 1,
			result_positive_no_genes: 1,
			result_positive_with_genes: 1,
			result_negative: 1,
		};
		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);
		const cancelledStatusEnum = {
			cancelled: 1,
			order_cancelled_by_patient: 1,
			order_cancelled_by_provider: 1,
			order_cancelled_by_lab: 1,
			order_cancelled_by_partner: 1,
		};
		const declinedStatusEnum = {
			declined_other: 1,
			ins_denied_cost: 1,
			iss_w_test_implications: 1,
			no_fu_from_patient: 1,
		};

		const history = _.get(testorder, 'history', []);

		const sortedHistory = _.orderBy(
			history,
			(o) => {
				return new moment(_.get(o, 'created_at', null));
			},
			['desc']
		);
		let previousState = _.nth(sortedHistory, 1);
		const previousPreviousState = _.nth(sortedHistory, 2);

		if (
			previousState &&
			previousPreviousState &&
			previousState.order_status == 'approved' &&
			previousPreviousState.order_status == 'waiting_to_submit' &&
			previousPreviousState.status_reason == 'awaiting_cc_submission'
		) {
			previousState = previousPreviousState;
		}

		if (
			propsorderstatus in cancelledStatusEnum &&
			!_.isNil(previousState) &&
			previousState.order_status in
				{
					approved: 1,
					requested: 1,
					patient_undecided: 1,
					awaiting_external_approval: 1,
				}
		) {
			return true;
		}

		if (
			_.get(testorder, ['localorderstatus'], null) in declinedStatusEnum
		) {
			const isRequestedTestOrder = _.find(sortedHistory, (e) => {
				return e && e.order_status == 'requested';
			});
			if (isRequestedTestOrder) {
				return true;
			}
			return false;
		}

		if (_.get(testorder, ['localorderstatus'], null) in statusEnum) {
			return true;
		}
		return false;
	};

	const isExternalLabStatusDisabled = (testorder) => {
		return !_.get(testorder, ['can_change_lab_order_status'], true);
	};

	const isTestDispatchDateDisabled = (formdata, testorder) => {
		let result = false;

		const statusEnum = {
			cancelled: 1,
			sent_to_lab: 1,
			results_ready: 1,
			results_entered: 1,
		};

		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);
		const stateorderstatus = _.get(formdata, ['order_status'], {});

		if (propsorderstatus in statusEnum) {
			result = true;
		} else if (stateorderstatus in statusEnum) {
			result = false;
		} else {
			result = true;
		}

		return result;
	};

	const isExpectedResultsDateDisabled = (formdata, testorder) => {
		let result = false;
		const propsEnum = {
			sent_to_lab: 1,
			patient_not_responsive: 1,
			sample_not_submitted: 1,
		};

		const statusEnum = {
			sent_to_lab: 1,
			patient_not_responsive: 1,
			sample_not_submitted: 1,
		};

		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);
		const stateorderstatus = _.get(formdata, ['order_status'], {});
		const _dateTestOrdered = _.get(testorder, ['date_test_ordered'], null);

		if (propsorderstatus in propsEnum) {
			if (
				propsorderstatus == 'patient_not_responsive' &&
				stateorderstatus != 'sent_to_lab' &&
				(_.isNil(_dateTestOrdered) || _.isEmpty(_dateTestOrdered))
			) {
				result = true;
			} else {
				result = false;
			}
		} else if (stateorderstatus in statusEnum) {
			if (
				stateorderstatus == 'patient_not_responsive' &&
				(_.isNil(_dateTestOrdered) || _.isEmpty(_dateTestOrdered))
			) {
				result = true;
			} else {
				result = false;
			}
		} else {
			result = true;
		}

		return result;
	};

	const isResultsReceiptDateDisabled = (formdata) => {
		const statusEnum = {
			results_ready: 1,
			results_entered: 1,
			result_positive_no_genes: 1,
			result_positive_with_genes: 1,
			result_negative: 1,
		};

		const stateorderstatus = _.get(formdata, ['order_status'], {});

		if (stateorderstatus in statusEnum) {
			return false;
		}
		return true;
	};

	const isResultsReleaseDateDisabled = (formdata, testorder) => {
		const statusEnum = {
			results_ready: 1,
			results_entered: 1,
			result_positive_no_genes: 1,
			result_positive_with_genes: 1,
			result_negative: 1,
		};

		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);
		const stateorderstatus = _.get(formdata, ['order_status'], {});

		if (propsorderstatus in statusEnum || stateorderstatus in statusEnum) {
			return false;
		}
		return true;
	};

	const isSampleTypeDisabled = (testorder) => {
		const statusEnum = {
			cancelled: 1,
			order_cancelled_by_patient: 1,
			order_cancelled_by_provider: 1,
			order_cancelled_by_lab: 1,
			declined_other: 1,
			ins_denied_cost: 1,
			iss_w_test_implications: 1,
			no_fu_from_patient: 1,
			rejected: 1,
			patient_declined: 1,
			no_test_available: 1,
			rejected_other: 1,
			results_ready: 1,
			results_entered: 1,
			result_positive_no_genes: 1,
			result_positive_with_genes: 1,
			result_negative: 1,
		};
		if (_.get(testorder, ['localorderstatus'], null) in statusEnum) {
			return true;
		}
		return false;
	};

	const isDisabledBDSalivaKitStatus = (formdata, testorder) => {
		let result = false;

		const statusEnum = {
			awaiting_cc_submission: 1,
			approved: 1,
		};

		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);
		const stateorderstatus = _.get(formdata, ['order_status'], {});
		const dateTestOrdered = _.get(testorder, ['date_test_ordered'], '');

		if (stateorderstatus in statusEnum && propsorderstatus in statusEnum) {
			result = true;
		} else if (
			!_.isEmpty(dateTestOrdered) &&
			(propsorderstatus === 'patient_not_responsive' ||
				stateorderstatus === 'patient_not_responsive')
		) {
			result = false;
		} else if (
			_.isEmpty(dateTestOrdered) &&
			(propsorderstatus === 'patient_not_responsive' ||
				stateorderstatus === 'patient_not_responsive')
		) {
			result = true;
		} else if (
			_.isEmpty(dateTestOrdered) &&
			(propsorderstatus === 'order_cancelled_by_patient' ||
				propsorderstatus === 'order_cancelled_by_provider' ||
				propsorderstatus === 'order_cancelled_by_lab' ||
				stateorderstatus === 'order_cancelled_by_patient' ||
				stateorderstatus === 'order_cancelled_by_lab' ||
				stateorderstatus === 'order_cancelled_by_provider')
		) {
			result = true;
		} else {
			result = false;
		}

		return result;
	};

	const changeTab = (value) => {
		const enctype = 0;
		const params = new URLSearchParams(
			_.get(props, ['location', 'search'], '')
		);
		const order_by = params.get('order_by');
		const order_type = params.get('order_type');
		const offset = params.get('offset');
		props.history.push({
			pathname:
				'/app/patientdetail/' +
				_.get(props, 'match.params.patientid', 0) +
				'/' +
				value +
				'/' +
				enctype,
			search:
				'?offset=' +
				(offset || 0) +
				'&order_by=' +
				(order_by || '') +
				'&order_type=' +
				(order_type || 'DESC'),
			state: _.get(props, 'location.state', {}),
		});
	};

	const renderReflexQuestionField = (
		{
			default_option = null,
			display_text,
			name,
			type,
			is_required,
			options,
			depends_on,
		},
		testorder,
		formdata
	) => {
		const isEditable =
			testorder?.is_questionnaire_editable ||
			/* if test name has change and new test name is for lab integration */
			(!formdata.skip_lab_integration &&
				!_.isNil(testorder.genetic_test_uuid) &&
				!_.isNil(formdata.genetic_test_uuid) &&
				testorder.genetic_test_uuid !== formdata.genetic_test_uuid);

		const isDisabled =
			!isEditable ||
			(!_.isEmpty(depends_on) &&
				_.get(reflexAOEAnswers, [depends_on.name], null) !==
					depends_on.value);

		if (type === 'enum' && !_.isEmpty(options)) {
			return (
				<>
					<span
						className={classnames({
							required: is_required,
						})}
					>
						{display_text}
					</span>
					<Select
						name={name}
						value={_.get(reflexAOEAnswers, [name], default_option)}
						disabled={isDisabled}
					>
						{options.map((option) => (
							<Select.Option
								key={option}
								label={option}
								value={option}
							/>
						))}
					</Select>
				</>
			);
		} else if (DateTypes.includes(type)) {
			return (
				<>
					<span
						className={classnames({
							required: is_required,
						})}
					>
						{display_text}
					</span>
					<Datetime
						value={
							moment(
								_.get(reflexAOEAnswers, [name], default_option)
							).isValid()
								? moment(
										_.get(
											reflexAOEAnswers,
											[name],
											default_option
										)
								  ).toDate()
								: ''
						}
						dateFormat={'MM/DD/YYYY'}
						timeFormat={false}
						isDisabled={isDisabled}
						isValidDate={(current) => {
							const today = moment();
							if (type === 'date_future') {
								return current.isSameOrAfter(today, 'day');
							} else if (type === 'date_past') {
								return current.isSameOrBefore(today, 'day');
							}
						}}
						className='testrequestdatefield'
						inputProps={{
							placeholder: 'MM/DD/YYYY',
						}}
						strictParsing={true}
						closeOnTab={true}
						closeOnSelect={true}
					/>
				</>
			);
		} else if (type === 'text') {
			return (
				<>
					<span
						className={classnames({
							required: is_required,
						})}
					>
						{display_text}
					</span>
					<Input
						name={name}
						value={_.get(reflexAOEAnswers, [name], default_option)}
						placeholder={display_text}
						trim={true}
						disabled={isDisabled}
						maxLength={255}
					/>
				</>
			);
		}

		return null;
	};

	const sampleTypes = getSampleTypeGrouping(optionsSampleTypesEnum);
	const resultEntryWarnMsg = setResultEntryOutreachWarnMsg();

	return (
		<Fragment>
			<section className='create-test-rqst-labinfo-container edit-test-rqst-labinfo-container-v2'>
				{_.map(stateGroupTestorders, (groupOrder, uuid) => {
					const propsGroupOrder = singleOrder(uuid);
					return (
						<>
							{(groupOrder?.order_type ?? 'primary') === 'reflex' && <div className='reflex-order-pill'><Pill pillText='Reflex Order' pillExtendStyle='gray-pill'/></div>}
							<h2 className='module-header'>
								{_.get(
									groupOrder,
									['genetic_test_display_name'],
									''
								)}
							</h2>
							<Layout.Row>
								<Layout.Col span='24' className='required'>
									Test Order Status
								</Layout.Col>
								<Layout.Col span='24'>
									<Cascader
										name={'order_status'}
										value={findCascaderHierarchy(
											mloOrderStatusEnums,
											'key',
											_.get(
												groupOrder,
												['order_status'],
												''
											)
										)}
										onChange={(value) =>
											onFormChangeFunc(
												'order_status',
												value,
												uuid
											)
										}
										showAllLevels={false}
										expandTrigger='hover'
										options={CascaderOptions(
											filterOrderStatus(
												!isOrderStatusDisabled(
													propsGroupOrder
												)
													? filterMloOrderStatusEnums()
													: mloOrderStatusEnums,
												_.get(
													propsGroupOrder,
													['order_status'],
													null
												),
												_.get(
													propsGroupOrder,
													['date_test_ordered'],
													null
												),
												_.get(
													propsGroupOrder,
													['status_reason'],
													''
												),
												_.get(
													propsGroupOrder,
													['history'],
													[]
												)
											),
											'key',
											'display_name',
											'key'
										)}
										disabled={isOrderStatusDisabled(
											propsGroupOrder
										)}
										className='cascade-edit-test-order'
									/>
									{_.get(groupOrder, ['order_status'], '') !==
										'results_ready' &&
										renderTestOrderMilestoneWarning(
											propsGroupOrder,
											groupOrder
										)}
								</Layout.Col>
							</Layout.Row>

							{(groupOrder?.order_type ?? 'primary') ===
								'reflex' &&
								!_.isEmpty(reflexAOEQuestions) &&
								_.isArray(reflexAOEQuestions) &&
								reflexAOEQuestions.map((reflexQuestion) => {
									return (
										<Layout.Row key={reflexQuestion.name}>
											<Layout.Col span='24'>
												{renderReflexQuestionField(
													reflexQuestion,
													propsGroupOrder,
													groupOrder
												)}
											</Layout.Col>
										</Layout.Row>
									);
								})}

							{_.get(propsGroupOrder, ['order_status'], '') ===
								'sent_to_lab' &&
								_.get(groupOrder, ['order_status'], '') ===
									'sent_to_lab' && (
									<Layout.Row gutter='16'>
										<Layout.Col span='24'>
											External Lab Status
										</Layout.Col>
										<Layout.Col span='24'>
											<Select
												name={'lab_order_status'}
												value={_.get(
													groupOrder,
													[
														'lab_order_status_key_name',
													],
													''
												)}
												onChange={(value) =>
													onFormChangeFunc(
														'lab_order_status',
														value,
														uuid
													)
												}
												disabled={isExternalLabStatusDisabled(
													propsGroupOrder
												)}
												placeholder='Select'
												clearable={
													!isExternalLabStatusDisabled(
														propsGroupOrder
													)
												}
												filterable={
													!isExternalLabStatusDisabled(
														propsGroupOrder
													)
												}
												filterMethod={(value) => {
													if (
														!isExternalLabStatusDisabled(
															propsGroupOrder
														)
													) {
														const _labOrderStatusEnum =
															filterLabOrderStatusEnums(
																laborderstatusenums
															);
														if (
															_.isEmpty(value) ||
															_.isNil(value)
														) {
															setOptionsLabOrderStatusEnum(
																_labOrderStatusEnum
															);
														} else {
															const _options =
																_labOrderStatusEnum.filter(
																	(o) =>
																		o.display_name &&
																		o.display_name
																			.toLowerCase()
																			.startsWith(
																				value.toLowerCase()
																			)
																);
															setOptionsLabOrderStatusEnum(
																_options
															);
														}
													}
												}}
											>
												{filterLabOrderStatusEnums(
													optionsLabOrderStatusEnum
												).map((el) => {
													return (
														<Select.Option
															key={el.key}
															label={
																el.display_name
															}
															value={el.key}
														/>
													);
												})}
											</Select>
										</Layout.Col>
									</Layout.Row>
								)}

							{_.get(groupOrder, ['order_status'], '') ===
								'results_ready' && (
								<Layout.Row>
									<Layout.Col
										span='24'
										className='required result-selection-label'
									>
										Are there any findings to report?
										<Popover
											width='250'
											trigger='hover'
											popperClass={classnames(
												'pp-finding-result'
											)}
											content="If the genetic testing report contains no findings or is considered 'normal' or 'negative' overall, please select 'No findings to report'. If there are individual findings to report, even those considered 'negative,' please select 'At least one finding to report.'"
											key='popover-finding-result'
										>
											<span className='tooltip-info'>
												<img src={moreinfoicon} />
											</span>
										</Popover>
									</Layout.Col>
									<Layout.Col
										span='24'
										className='result-select-radio-button-wrapper'
									>
										<Radio.Group
											value={_.get(
												groupOrder,
												['positive_results_to_report'],
												null
											)}
											onChange={(value) =>
												onFormChangeFunc(
													'positive_results_to_report',
													value,
													uuid
												)
											}
										>
											<Radio
												value={true}
												className={
													_.get(
														groupOrder,
														['positive_results_to_report'],
														null
													)
														? 'radio-is-checked'
														: ''
												}
											>
												At least one finding to report
											</Radio>
											<Radio
												value={false}
												className={
													!_.isNil(
														_.get(
															groupOrder,
															['positive_results_to_report'],
															null
														)
													) &&
													!_.get(groupOrder, ['positive_results_to_report'])
														? 'radio-is-checked'
														: ''
												}
											>
												No findings to report
											</Radio>
										</Radio.Group>
									</Layout.Col>

									{_.get(groupOrder, ['order_status'], '') ===
										'results_ready' && (
										<Fragment>
											<Layout.Col
												span='24'
												className='required result-actionable-label'
											>
												Are the results Actionable?
												<Popover
													width='290'
													trigger='hover'
													popperClass={classnames(
														'pp-result-actionable'
													)}
													content={
														<Fragment>
															If there are
															findings, only
															change to no if the
															result is uncertain,
															benign, or variant
															of uncertain
															significance. If
															there are no
															findings to report,
															only change to yes
															if GC has requested
															outreach. If you
															have any questions
															at all, please reach
															out to the counselor
															of the week (COW) in
															the COW slack
															channel.&nbsp;
															<a
																href='https://docs.google.com/document/d/1Hcutji1fkM_JcFyIBzsV9Ble7utY-9w6nslJ6OOT4ic/edit'
																target='_blank'
																className='more-details'
																rel='noreferrer'
															>
																More details
															</a>
														</Fragment>
													}
													key='popover-actioable'
												>
													<span className='tooltip-info'>
														<img
															src={moreinfoicon}
														/>
													</span>
												</Popover>
											</Layout.Col>
											<Layout.Col
												span='10'
												className='result-actionable-yesno-button-wrapper'
											>
												<div
													className={classnames(
														'yes-button result-actionable-yesno-button',
														!_.isNil(
															_.get(
																groupOrder,
																['is_actionable'],
																null
															)
														) && _.get(
															groupOrder,
															['is_actionable']
															)
														&&
														!_.isNil(_.get(
															groupOrder,
															['positive_results_to_report']
															))
															? 'yesno-button-selected'
															: 'yesno-button-unselected'
													)}
													onClick={() =>
														onFormChangeFunc(
															'is_actionable',
															true,
															uuid
														)
													}
												>
													Yes
												</div>
												<div
													className={classnames(
														'result-actionable-yesno-button',
														!_.isNil(
															_.get(
																groupOrder,
																['is_actionable'],
																null
															)
														)  && !_.isNil(_.get(
															groupOrder,
															['positive_results_to_report']
														)) && !_.get(
															groupOrder,
															['is_actionable']
														)
															? 'yesno-button-selected'
															: 'yesno-button-unselected'
													)}
													onClick={() =>
														onFormChangeFunc(
															'is_actionable',
															false,
															uuid
														)
													}
												>
													No
												</div>
											</Layout.Col>
											<Layout.Col>
												{showResultEntryWarnMsg(
													groupOrder
												) && (
													<WarningMessageBox
														{...resultEntryWarnMsg}
													/>
												)}
												{_.get(
													groupOrder,
													['order_status'],
													''
												) === 'results_ready' &&
													renderTestOrderMilestoneWarning()}
											</Layout.Col>
										</Fragment>
									)}
								</Layout.Row>
							)}

							{showTestDispatchExpectedDate(groupOrder) && (
								<Layout.Row gutter='16'>
									<Layout.Col
										span='12'
										className={
											isTestDispatchDateRequired(
												groupOrder,
												propsGroupOrder
											)
												? 'required'
												: ''
										}
									>
										<span>Test Dispatch Date</span>
										<EncounterDateField
											className={'testrequestdatefield'}
											name='date_test_ordered'
											placeholder='MM/DD/YYYY'
											disabled={isTestDispatchDateDisabled(
												groupOrder,
												propsGroupOrder
											)}
											value={_.get(
												groupOrder,
												['date_test_ordered'],
												''
											)}											
											onChange={(value) =>
												onFormChangeFunc(
													'date_test_ordered',
													value,
													uuid
												)
											}
											onBlur={(value) =>
												onHandleDateFieldValidate(
													'date_test_ordered',
													value,
													uuid,
													groupOrder
												)
											}
											disabledDate={(current) => {
												const today = moment();
												const _dateTestAuthorized =
													moment(
														_.get(
															groupOrder,
															[
																'date_test_authorized',
															],
															''
														),
														'YYYY-MM-DD'
													);

												return (
													current.isSameOrBefore(
														today,
														'day'
													) &&
													current.isSameOrAfter(
														_dateTestAuthorized,
														'day'
													)
												);
											}}
											makeCalendarOnly={(event) => {
												if (
													event.keyCode == 8 ||
													event.keyCode == 46
												) {
													return true;
												} else {
													event.preventDefault();
												}
											}}
										/>
									</Layout.Col>
									<Layout.Col
										span='12'
										className={
											isExpectedResultsDateRequired(
												groupOrder,
												propsGroupOrder
											)
												? 'required'
												: ''
										}
									>
										<span>Expected Results Date</span>
										<EncounterDateField
											className={
												'testrequestdatefield calendar-pull-left'
											}
											name='expected_lab_results_date'
											placeholder='MM/DD/YYYY'
											disabled={isExpectedResultsDateDisabled(
												groupOrder,
												propsGroupOrder
											)}
											value={_.get(
												groupOrder,
												['expected_lab_results_date'],
												''
											)}
											onChange={(value) =>
												onFormChangeFunc(
													'expected_lab_results_date',
													value,
													uuid
												)
											}
											onBlur={(value) =>
												onHandleDateFieldValidate(
													'expected_lab_results_date',
													value,
													uuid,
													groupOrder
												)
											}
											disabledDate={(current) => {
												const _dateTestOrdered = moment(
													_.get(
														groupOrder,
														['date_test_ordered'],
														''
													),
													'YYYY-MM-DD'
												);
												return current.isSameOrAfter(
													_dateTestOrdered,
													'day'
												);
											}}
											makeCalendarOnly={(event) => {
												if (
													event.keyCode == 8 ||
													event.keyCode == 46
												) {
													return true;
												} else {
													event.preventDefault();
												}
											}}
										/>
									</Layout.Col>
								</Layout.Row>
							)}

							<Layout.Row gutter='16'>
								<Layout.Col
									span='12'
									className={
										isResultsReceiptDateRequired(
											groupOrder,
											propsGroupOrder
										)
											? 'required'
											: ''
									}
								>
									<span>Results Receipt Date</span>
									<EncounterDateField
										className={'testrequestdatefield'}
										name='date_received_report'
										disabled={isResultsReceiptDateDisabled(
											groupOrder
										)}
										placeholder='MM/DD/YYYY'
										value={_.get(
											groupOrder,
											['date_received_report'],
											''
										)}
										onChange={(value) =>
											onFormChangeFunc(
												'date_received_report',
												value,
												uuid
											)
										}
										onBlur={(value) =>
											onHandleDateFieldValidate(
												'date_received_report',
												value,
												uuid,
												groupOrder
											)
										}
										disabledDate={(current) => {
											const today = moment();
											const _dateTestOrdered = moment(
												_.get(
													groupOrder,
													['date_test_ordered'],
													''
												),
												'YYYY-MM-DD'
											);
											return (
												current.isSameOrBefore(
													today,
													'day'
												) &&
												current.isSameOrAfter(
													_dateTestOrdered,
													'day'
												)
											);
										}}
										makeCalendarOnly={(event) => {
											if (
												event.keyCode == 8 ||
												event.keyCode == 46
											) {
												return true;
											} else {
												event.preventDefault();
											}
										}}
									/>
								</Layout.Col>
								<Layout.Col span='12'>
									<span>Results Release Date</span>
									<EncounterDateField
										className={
											'testrequestdatefield calendar-pull-left'
										}
										name='date_results_released_to_patient'
										placeholder='MM/DD/YYYY'
										disabled={isResultsReleaseDateDisabled(
											groupOrder,
											propsGroupOrder
										)}
										value={_.get(
											groupOrder,
											[
												'date_results_released_to_patient',
											],
											''
										)}
										onChange={(value) =>
											onFormChangeFunc(
												'date_results_released_to_patient',
												value,
												uuid
											)
										}
										onBlur={(value) =>
											onHandleDateFieldValidate(
												'date_results_released_to_patient',
												value,
												uuid,
												groupOrder
											)
										}
										disabledDate={(current) => {
											const today = moment();
											return current.isSameOrBefore(
												today,
												'day'
											);
										}}
										makeCalendarOnly={(event) => {
											if (
												event.keyCode == 8 ||
												event.keyCode == 46
											) {
												return true;
											} else {
												event.preventDefault();
											}
										}}
									/>
								</Layout.Col>
							</Layout.Row>

							{showHoldResultWarnMsg(
								groupOrder,
								propsGroupOrder
							) && (
								// <WarningMessageBox {...holdResultWarnMsg} />
								<WarningMessageBox
									{...setHoldResultOutreachWarnMsg(
										groupOrder,
										propsGroupOrder
									)}
								/>
							)}

							<h2 className='module-header'>Sample Info</h2>

							<Layout.Row gutter='16'>
								<Layout.Col span='12' className='required'>
									<span>Sample Type (Preferred)</span>
									<Select
										name={'sample_type'}
										value={_.get(
											groupOrder,
											['sample_type'],
											''
										)}
										onChange={(value) =>
											onFormChangeFunc(
												'sample_type',
												value,
												uuid
											)
										}
										disabled={isSampleTypeDisabled(
											propsGroupOrder
										)}
										placeholder='Select'
										clearable={true}
										filterable={true}
										filterMethod={(value) => {
											const _optionSampleTypeEnum =
												createEnumsOptions(
													props,
													['sampletypeenums'],
													'key',
													'display_name',
													'key'
												);
											if (
												_.isEmpty(value) ||
												_.isNil(value)
											) {
												setOptionsSampleTypesEnum(
													_optionSampleTypeEnum
												);
											} else {
												const _options =
													_optionSampleTypeEnum.filter(
														(o) =>
															o.label &&
															o.label
																.toLowerCase()
																.startsWith(
																	value.toLowerCase()
																)
													);
												setOptionsSampleTypesEnum(
													_options
												);
											}
										}}
									>
										{_.get(
											sampleTypes,
											'non-others',
											[]
										).map((el) => {
											return (
												<Select.Option
													key={el.value}
													label={el.label}
													value={el.value}
												/>
											);
										})}
										{_.get(
											sampleTypes,
											'others',
											false
										) && (
											<Select.OptionGroup
												key={'other'}
												label='OTHER'
												className='sample-type-other'
											>
												{_.get(
													sampleTypes,
													'others',
													[]
												).map((el) => {
													return (
														<Select.Option
															key={el.value}
															label={el.label}
															value={el.value}
														/>
													);
												})}
											</Select.OptionGroup>
										)}
									</Select>
								</Layout.Col>
								<Layout.Col span='12'>
									<span>BD/Saliva Kit Status</span>
									<Select
										name={'bd_saliva_kit_sent'}
										value={_.get(
											groupOrder,
											['bd_saliva_kit_sent'],
											''
										)}
										disabled={isDisabledBDSalivaKitStatus(
											groupOrder,
											propsGroupOrder
										)}
										onChange={(value) =>
											onFormChangeFunc(
												'bd_saliva_kit_sent',
												value,
												uuid
											)
										}
										placeholder='Select'
										clearable={true}
										filterable={true}
										filterMethod={(value) => {
											if (
												!isDisabledBDSalivaKitStatus(
													groupOrder,
													propsGroupOrder
												)
											) {
												const _bdSalivaKitSentEnum =
													_.isArray(
														createEnumsOptions(
															props,
															[
																'encounterschema',
																'bd_saliva_kit_sent',
															],
															'key',
															'display_name',
															'key'
														)
													)
														? createEnumsOptions(
																props,
																[
																	'encounterschema',
																	'bd_saliva_kit_sent',
																],
																'key',
																'display_name',
																'key'
														  )
														: [];
												if (
													_.isEmpty(value) ||
													_.isNil(value)
												) {
													setOptionsBDSalivaKitSentEnum(
														_bdSalivaKitSentEnum
													);
												} else {
													const _options =
														_bdSalivaKitSentEnum.filter(
															(o) =>
																o.label &&
																o.label
																	.toLowerCase()
																	.startsWith(
																		value.toLowerCase()
																	)
														);
													setOptionsBDSalivaKitSentEnum(
														_options
													);
												}
											}
										}}
									>
										{optionsBDSalivaKitSentEnum.map(
											(el) => {
												return (
													<Select.Option
														key={el.value}
														label={el.label}
														value={el.value}
													/>
												);
											}
										)}
									</Select>
								</Layout.Col>
							</Layout.Row>

							<Layout.Row gutter='16' className='blood-draw-row'>
								<Layout.Col span='12'>
									<span>Blood Draw Date</span>
									<EncounterDateField
										className={
											'testrequestdatefield calendar-pull-up calendar-pull-right'
										}
										name='blood_draw_order_date'
										disabled={false}
										placeholder='MM/DD/YYYY'
										value={_.get(
											groupOrder,
											['blood_draw_order_date'],
											''
										)}
										onChange={(value) =>
											onFormChangeFunc(
												'blood_draw_order_date',
												value,
												uuid
											)
										}
										onBlur={(value) =>
											onHandleDateFieldValidate(
												'blood_draw_order_date',
												value,
												uuid,
												groupOrder
											)
										}
										disabledDate={(current) => {
											const today = moment();
											return current.isSameOrBefore(
												today,
												'day'
											);
										}}
										makeCalendarOnly={(event) => {
											if (
												event.keyCode == 8 ||
												event.keyCode == 46
											) {
												return true;
											} else {
												event.preventDefault();
											}
										}}
									/>
								</Layout.Col>

								<Layout.Col span='12'>
									<span>Blood Draw Status</span>
									<Select
										name={'blood_draw_request'}
										value={_.get(
											groupOrder,
											['blood_draw_request'],
											''
										)}
										onChange={(value) =>
											onFormChangeFunc(
												'blood_draw_request',
												value,
												uuid
											)
										}
										placeholder='Select'
										clearable={true}
										filterable={true}
										filterMethod={(value) => {
											const _bloodDrawRequestEnum =
												_.isArray(
													createEnumsOptions(
														props,
														[
															'encounterschema',
															'blood_draw_request',
														],
														'key',
														'display_name',
														'key'
													)
												)
													? createEnumsOptions(
															props,
															[
																'encounterschema',
																'blood_draw_request',
															],
															'key',
															'display_name',
															'key'
													  )
													: [];
											if (
												_.isEmpty(value) ||
												_.isNil(value)
											) {
												setOptionsBloodDrawRequestEnum(
													_bloodDrawRequestEnum
												);
											} else {
												const _options =
													_bloodDrawRequestEnum.filter(
														(o) =>
															o.label &&
															o.label
																.toLowerCase()
																.startsWith(
																	value.toLowerCase()
																)
													);
												setOptionsBloodDrawRequestEnum(
													_options
												);
											}
										}}
									>
										{optionsBloodDrawRequestEnum.map(
											(el) => {
												return (
													<Select.Option
														key={el.value}
														label={el.label}
														value={el.value}
													/>
												);
											}
										)}
									</Select>
								</Layout.Col>
							</Layout.Row>

							<h2 className='module-header'>Billing Info</h2>

							<Layout.Row
								gutter='16'
								className='test-order-billing-method-row'
							>
								<Layout.Col span='12' className='required'>
									<span>Test Billing Method</span>
									<Select
										name='patient_billing_method'
										value={_.get(
											groupOrder,
											['patient_billing_method'],
											''
										)}
										placeholder='Select'
										onChange={(value) =>
											onFormChangeFunc(
												'patient_billing_method',
												value,
												uuid
											)
										}
										clearable={true}
										filterable={true}
										filterMethod={(value) => {
											const _billingMethodEnum =
												createEnumsOptions(
													props,
													[
														'patientbillingmethodenums',
													],
													'key',
													'display_name',
													'key'
												);
											if (
												_.isEmpty(value) ||
												_.isNil(value)
											) {
												setOptionsBillingMethodEnum(
													_billingMethodEnum
												);
											} else {
												const _options =
													_billingMethodEnum.filter(
														(o) =>
															o.label &&
															o.label
																.toLowerCase()
																.startsWith(
																	value.toLowerCase()
																)
													);
												setOptionsBillingMethodEnum(
													_options
												);
											}
										}}
									>
										{optionsBillingMethodEnum.map((el) => {
											return (
												<Select.Option
													key={el.value}
													label={el.label}
													value={el.value}
												/>
											);
										})}
									</Select>
								</Layout.Col>
							</Layout.Row>

							{hasPatientNoInsurance() && (
								<>
									<Layout.Row gutter='16'>
										<Layout.Col span='24'>
											<div className='noinsunrace-info-container'>
												<div className='noinsunrace-info-body'>
													<div className='milestone-warning-icon'>
														<ReactSVG
															src={warningImg}
														/>
													</div>
													<div className='noinsunrace-info-text'>
														This patient does not
														have any insurance info
														on file. Click the
														button below to add
														insurance.
													</div>
												</div>
											</div>
										</Layout.Col>
									</Layout.Row>

									<Layout.Row gutter='16'>
										<Layout.Col span='24'>
											<Button
												className='add-insuranceinfo-button'
												onClick={() => {
													hideModal();
													changeTab(5);
												}}
											>
												<ReactSVG
													className='add-icon'
													src={add}
												/>
												<span>Add Insurance Info</span>
											</Button>
										</Layout.Col>
									</Layout.Row>
								</>
							)}
						</>
					);
				})}
			</section>
		</Fragment>
	);
};

export default withRouter(EditTestRequestLabInfoAfterSentToLab);
