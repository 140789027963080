//React & Redux
import React, { Fragment } from 'react';

//Lodash
import { isNil, isEmpty } from 'lodash';

//UI Libraries
import { InputNumber } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';

export const EncounterNumberInputField = ({
	name,
	value,
	onChange,
	placeholder,
	trim,
	extraclassName,
	disabled,
	error,
	istouched = true,
	min = 0,
}) => {
	const iserror = istouched && error && !isNil(error) && !isEmpty(error);
	const currentClassName = classnames(
		iserror
			? 'encounter-input-field encounter-input-error-field'
			: 'encounter-input-field',
		extraclassName
	);
	return (
		<Fragment>
			<div className={currentClassName}>
				<InputNumber
					type='text'
					placeholder={placeholder}
					trim={trim}
					disabled={disabled}
					name={name}
					value={value === null ? undefined : value}
					onChange={onChange}
					controls={false}
					min={min}
				/>
			</div>
			<div className='encounter-input-error'>{error}</div>
		</Fragment>
	);
};
