//React & Redux
import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//Actions and Services
import { getPlanTypes } from '../../../actions/enums';
import {
	getInsuranceAuths,
	resetInsuranceAuths,
	saveInsuranceAuth,
	saveInsurance,
	getInsuranceInfo,
	getFullListOfInsuranceCompanies,
} from '../../../actions/patients';

//Utils
import { biologicalSexVariants } from '../../../Utils/appointmentUtils';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import InsuranceInfo from './InsuranceInfo';
import PaymentInfo from './PaymentInfo';
import InsuranceAuthForm from './InsuranceAuthForm';
import InsuranceForm from './InsuranceForm';

//Styles
import './payments-tab.css';
import './insurance-form.css';

//Hooks
import { usePrevious } from '../../../hooks/usePrevious';

const actionType = {
	add: 'add',
	edit: 'edit',
	view: 'view',
	addAuth: 'addAuth',
	editAuth: 'editAuth',
};

export const PaymentsTab = (props) => {
	const {
		createAthenaPatientAndInsurance,
		createdAthenaPatient,
		createdAthenaPatientError,
		creatingAthenaPatient,
		enqueueSnackbar,
		getPatientDetail,
		getPlanTypesEnums,
		gettingPatientDetail,
		insuranceAuthFormActions,
		insuranceAuths,
		insuranceCompanies,
		insuranceFormActions,
		match,
		patientDetail,
		paymentActions,
		planTypesEnums,
		saveInsuranceAuthResponse,
		saveInsuranceResponse,
		getPatientInsuranceInfo,
		patientInsuranceInfo,
		getPatientInsuranceInfoStart,
		getFullListOfInsuranceCompanies,
		fullListOfInsuranceCompanies,
	} = props;

	const [loading, setLoading] = useState(false);
	const [action, setAction] = useState(actionType.view);
	const [authData, setAuthData] = useState({});
	const [postAthenaPatientLoad, setPostAthenaPatientLoad] = useState(false);
	const [isAddPrimaryInsuranceFromForm, setIsAddPrimaryInsuranceFromForm] =
		useState(false);
	const [
		isAddSecondaryInsuranceFromForm,
		setIsAddSecondaryInsuranceFromForm,
	] = useState(false);
	const [isAddLabInsuranceFromForm, setIsAddLabInsuranceFromForm] =
		useState(false);

	const prevCreatingAthenaPatient = usePrevious(creatingAthenaPatient);
	const prevGettingPatientDetail = usePrevious(gettingPatientDetail);

	useEffect(() => {
		getPlanTypesEnums();
		getPatientInsuranceInfo(patientDetail?.[0]?.uuid);
		getFullListOfInsuranceCompanies({
			patient_uuid: patientDetail?.[0]?.uuid,
		});

		document
			.getElementById('patient_encounter_list')
			?.classList.add('patient_payment_list');

		return () => {
			document
				.getElementById('patient_encounter_list')
				?.classList.remove('patient_payment_list');
		};
	}, []);

	useEffect(() => {
		if (!prevCreatingAthenaPatient && creatingAthenaPatient) {
			setLoading(true);
		}

		if (prevCreatingAthenaPatient && !creatingAthenaPatient) {
			if (!createdAthenaPatientError) {
				enqueueSnackbar(
					createdAthenaPatient?.message || 'Athena patient created',
					{
						variant: 'info',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
				getPatientDetail(false);
				setPostAthenaPatientLoad(true);
			} else {
				enqueueSnackbar('Error creating Athena patient', {
					variant: 'error',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				setLoading(false);
			}
		}
	}, [creatingAthenaPatient]);

	useEffect(() => {
		if (prevGettingPatientDetail && !gettingPatientDetail && loading) {
			setLoading(false);
			setPostAthenaPatientLoad(false);
		}
	}, [gettingPatientDetail]);

	const updateAction = (action, data = {}) => {
		setAction(action);
		setAuthData(data);
	};

	const editInsuranceAuth = (obj) => {
		updateAction(actionType.editAuth, obj);
	};

	const patient = useMemo(() => patientDetail?.[0] || {}, patientDetail?.[0]);
	const isAddAuth = action == actionType.addAuth;
	const isActionTypeEdit = action == actionType.edit;

	return (
		<div className='payment-info-tab'>
			{[
				actionType.view,
				actionType.addAuth,
				actionType.editAuth,
			].includes(action) && (
				<InsuranceInfo
					{...{
						actionType,
						createAthenaPatientAndInsurance,
						patient,
						patientDetail,
						postAthenaPatientLoad,
						setAction: updateAction,
						setIsAddPrimaryInsuranceFromForm,
						setIsAddSecondaryInsuranceFromForm,
						setIsAddLabInsuranceFromForm,
						patientInsuranceInfo,
						getPatientInsuranceInfoStart,
					}}
				/>
			)}
			{[actionType.addAuth, actionType.editAuth].includes(action) && (
				<InsuranceAuthForm
					{...{
						actionType,
						authData,
						enqueueSnackbar,
						insuranceAuthFormActions,
						patient,
						saveInsuranceAuthResponse,
						setAction: updateAction,
						title:
							(isAddAuth ? 'New' : 'Edit') +
							' primary insurance authorization',
						patientInsuranceInfo,
					}}
				/>
			)}
			{[actionType.add, actionType.edit].includes(action) && (
				<InsuranceForm
					{...{
						actionType,
						biologicalSexVariants,
						enqueueSnackbar,
						insuranceCompanies,
						insuranceFormActions,
						match,
						patient,
						planTypesEnums,
						saveInsuranceResponse,
						setAction: updateAction,
						isAddPrimaryInsuranceFromForm,
						setIsAddPrimaryInsuranceFromForm,
						isAddSecondaryInsuranceFromForm,
						setIsAddSecondaryInsuranceFromForm,
						isAddLabInsuranceFromForm,
						setIsAddLabInsuranceFromForm,
						getPatientInsuranceInfo,
						patientInsuranceInfo,
						isActionTypeEdit,
						fullListOfInsuranceCompanies,
						getPatientDetail,
					}}
				/>
			)}
			<PaymentInfo
				{...{
					actionType,
					editInsuranceAuth,
					insuranceAuths,
					patient,
					paymentActions,
					setAction: updateAction,
					patientInsuranceInfo,
				}}
			/>
			{loading && (
				<div className='fullscreenSpinner'>
					<Loading
						loading={true}
						style={{ position: 'unset', opacity: '0.7' }}
					/>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	insuranceAuths: state.insuranceAuths?.insuranceAuths || [],
	insuranceCompanies: state.insuranceCompanies?.insuranceCompanies || [],
	planTypesEnums: state.enums?.planTypes?.data || [],
	saveInsuranceAuthResponse: state?.saveinsuranceauth || {},
	saveInsuranceResponse: state?.saveinsurance || {},
	patientInsuranceInfo: state?.getInsuranceInfo?.insuranceInfo || [],
	getPatientInsuranceInfoStart:
		state?.getInsuranceInfo?.insuranceInfoStart || false,
	fullListOfInsuranceCompanies:
		state.fullListOfInsuranceCompanies?.fullListOfinsuranceCompanies || [],
});

const mapDispatchToProps = (dispatch) => ({
	insuranceAuthFormActions: bindActionCreators(
		{
			getInsuranceAuths,
			saveInsuranceAuth,
		},
		dispatch
	),
	insuranceFormActions: bindActionCreators(
		{
			saveInsurance,
		},
		dispatch
	),
	paymentActions: bindActionCreators(
		{
			getInsuranceAuths,
			resetInsuranceAuths,
		},
		dispatch
	),
	getPlanTypesEnums: () => dispatch(getPlanTypes()),
	getPatientInsuranceInfo: (patient_uuid) =>
		dispatch(getInsuranceInfo(patient_uuid, true)),
	getFullListOfInsuranceCompanies: (data) =>
		dispatch(getFullListOfInsuranceCompanies(data, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsTab);
