//React && Redux
import React from 'react';

//Styling
import './CTAWarningModal.css';

//Images
import ReactSVG from 'react-svg';
import alertIcon from '../../assets/alert.svg';

//UI Libraries
import { Loading } from 'gm-element-react';

//Lodash
import get from 'lodash/get';

class CTAWarningModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasFunctions: false,
			loading: false,
		};
		//bindings
		this.triggerClick = this.triggerClick.bind(this);
		this.determineHasFunctions = this.determineHasFunctions.bind(this);
		this.determineMainButtonClass =
			this.determineMainButtonClass.bind(this);
		this.determineMainButtonDisabledClass =
			this.determineMainButtonDisabledClass.bind(this);
		this.determineMinorButtonClass =
			this.determineMinorButtonClass.bind(this);
		this.determineTitleIcon = this.determineTitleIcon.bind(this);
		this.handleMinorClick = this.handleMinorClick.bind(this);
		this.handleMainClick = this.handleMainClick.bind(this);
		this.determineMainButtonStyle =
			this.determineMainButtonStyle.bind(this);
		this.determineButtonRowStyle = this.determineButtonRowStyle.bind(this);
		this.determineBackdropStyle = this.determineBackdropStyle.bind(this);
	}
	//functions

	componentDidMount() {
		const noInitialClick = get(this, 'props.noInitialClick', false);
		if (!noInitialClick) this.triggerClick();
		this.determineHasFunctions();
	}

	triggerClick() {
		get(this, 'container', document.createElement('span')).click();
	}

	determineHasFunctions() {
		const mainFunc = get(this, 'props.mainFunc', null);
		const closeFunc = get(this, 'props.closeFunc', null);
		const hasNoClose = get(this, 'props.noCloseButton', null);
		if (
			typeof mainFunc === 'function' &&
			(typeof closeFunc === 'function' || hasNoClose)
		) {
			this.setState({ hasFunctions: true });
		}
	}

	determineMainButtonClass() {
		const colorScheme = get(this, 'props.colorScheme', '');
		switch (colorScheme) {
			case 'red':
				return 'modalButtonRed';
			case 'purple':
				return 'modalButtonPurple';
			default:
				return 'modalButtonRed';
		}
	}

	determineMainButtonDisabledClass() {
		const mainButtonDisabled = get(this, 'props.mainButtonDisabled', false);
		const colorScheme = get(this, 'props.colorScheme', '');
		if (mainButtonDisabled) {
			switch (colorScheme) {
				case 'purple':
					return 'modalButtonPurpleDisabled';
				default:
					return '';
			}
		} else return '';
	}

	determineMinorButtonClass() {
		const colorScheme = get(this, 'props.colorScheme', '');
		switch (colorScheme) {
			case 'red':
				return 'modalCancelButtonRed';
			case 'purple':
				return 'modalCancelButtonPurple';
			default:
				return 'modalCancelButtonRed';
		}
	}

	determineTitleIcon() {
		const colorScheme =
			get(this, 'props.iconColorScheme', null) ||
			get(this, 'props.colorScheme', '');
		switch (colorScheme) {
			case 'red':
				return (
					<ReactSVG
						src={alertIcon}
						className='modalTitleIcon modalTitleIconRed'
					/>
				);
			case 'yellow':
				return (
					<ReactSVG
						src={alertIcon}
						className='modalTitleIcon modalTitleIconYellow'
					/>
				);
			case 'none':
				return <div />;
			default:
				return (
					<ReactSVG
						src={alertIcon}
						className='modalTitleIcon modalTitleIconRed'
					/>
				);
		}
	}

	handleMinorClick(e) {
		if (get(this, 'state.hasFunctions', false)) {
			this.props.closeFunc();
		}
	}

	handleMainClick(e) {
		if (get(this, 'state.hasFunctions', false)) {
			this.props.mainFunc();
		}
	}

	determineMainButtonStyle() {
		const hasNoClose = get(this, 'props.noCloseButton', null);
		if (hasNoClose) return { marginLeft: 'auto' };
		else return {};
	}

	determineButtonRowStyle() {
		const buttonRowBorder = get(this, 'props.buttonRowBorder', null);
		if (buttonRowBorder) return { borderTop: '1px solid #edeff3' };
		else return {};
	}

	determineBackdropStyle() {
		const customZIndex = get(this, 'props.customZIndex', null);
		if (
			(customZIndex || customZIndex === 0) &&
			typeof customZIndex === 'number'
		)
			return { zIndex: customZIndex };
		else return {};
	}

	render() {
		const loading =
			get(this, 'state.loading', false) ||
			get(this, 'props.loading', false);
		const modalContainerStyling =
			get(this, 'props.modalContainerStyling', null) || {};
		const mainButtonClass =
			this.determineMainButtonClass() +
			' ' +
			this.determineMainButtonDisabledClass();
		const minorButtonClass = this.determineMinorButtonClass();
		const title = get(this, 'props.title', '') || '--';
		const hideTitle = get(this, ['props', 'hideTitle'], false);
		const text = get(this, 'props.text', '') || '--';
		const noBodyText = get(this, 'props.noBodyText', false);
		const mainButtonText = get(this, 'props.mainButtonText', '') || '--';
		const minorButtonText =
			get(this, 'props.minorButtonText', '') || 'Cancel';
		const extraBodyUI = get(this, 'props.extraBodyUI', null);
		const hasNoClose = get(this, 'props.noCloseButton', null);
		const buttonRowBorder = get(this, 'props.buttonRowBorder', null);
		const buttonRowStyle = buttonRowBorder
			? 'CTAWarningModalButtonRow grayBorderTop'
			: 'CTAWarningModalButtonRow';
		return (
			<div
				className='fullModalBackground'
				style={this.determineBackdropStyle()}
			>
				<div
					className='CTAWarningModalContainer'
					ref={(el) => (this.container = el)}
					style={{ ...modalContainerStyling }}
				>
					<Loading loading={loading}>
						{!hideTitle && (
							<div className='CTAWarningModalTitleRow'>
								{this.determineTitleIcon()}
								<div className='modalTitle CTAWarningModalTitle'>
									{title}
								</div>
							</div>
						)}
						<div className='CTAWarningModalBody'>
							{!noBodyText && text}
							{extraBodyUI && (
								<div style={{ width: '100%' }}>
									{extraBodyUI}
								</div>
							)}
						</div>
						<div
							className={buttonRowStyle}
							style={this.determineButtonRowStyle()}
						>
							{!hasNoClose && (
								<div
									className={minorButtonClass}
									onClick={this.handleMinorClick}
								>
									{minorButtonText}
								</div>
							)}
							<div
								className={mainButtonClass}
								onClick={this.handleMainClick}
								style={this.determineMainButtonStyle()}
							>
								{mainButtonText}
							</div>
						</div>
					</Loading>
				</div>
			</div>
		);
	}
}

export default CTAWarningModal;
