//React & Redux
import React from 'react';

//UI Libraries
import { Radio } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';

//Styles
import './RadioButtonPill.css';

const RadioButtonPill = (props) => {
	const { value, onChange, options, className } = props;
	return (
		options && (
			<div className='radio-group-pill-wrapper'>
				<Radio.Group
					value={value}
					onChange={onChange}
					className={className}
				>
					{(options || []).map((el, i) => (
						<Radio.Button
							className={classnames({
								'is-radio-disabled': el.disabled,
							})}
							key={i}
							disabled={el.disabled}
							value={el.value}
						>
							{el.label}
						</Radio.Button>
					))}
				</Radio.Group>
			</div>
		)
	);
};

export default RadioButtonPill;
