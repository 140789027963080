//React & Redux
import React, { Component } from 'react';

//Other Libraries
import _ from 'lodash';

//Components
import PatientCard from '../../Common/PatientCard';

//Styles
import './RelatedEncounterInfoSegment.css';

class RelatedEncounterInfoSegment extends Component {
	render() {
		const parentEncounter = _.get(
			this,
			['props', 'encounter', 'part_of'],
			{}
		);
		const siblingEncounter = _.get(
			this,
			['props', 'encounter', 'sibling_of'],
			[]
		);
		const secondaryEncounter = _.get(
			this,
			['props', 'encounter', 'parent_of'],
			[]
		);

		return (
			<div className='encntr-link-related-info-container'>
				<div className='body'>
					{parentEncounter &&
						!_.isEmpty(parentEncounter.uuid) &&
						parentEncounter.type == 'visit' && (
							<PatientCard
								type='primary'
								providerenums={_.get(
									this,
									['props', 'providerenums'],
									[]
								)}
								providerName={_.get(
									parentEncounter,
									['visit_provider'],
									''
								)}
								name={_.get(parentEncounter, ['name'], '')}
								date_of_service={_.get(
									parentEncounter,
									['date_of_service'],
									''
								)}
								timezone={_.get(
									parentEncounter,
									['timezone'],
									null
								)}
								state_of_visit={_.get(
									parentEncounter,
									['state_of_visit'],
									''
								)}
								encounter_uuid={_.get(
									parentEncounter,
									['uuid'],
									''
								)}
								patient_uuid={_.get(
									parentEncounter,
									['patient_uuid'],
									''
								)}
								gotoRelatedEncounter={
									this.props.gotoRelatedEncounter
								}
								changeEncounterTab={
									this.props.changeEncounterTab
								}
								showLinkIcon={_.get(
									this.props,
									'showLinkIcon',
									true
								)}
								isRelatedEncounterClickable={_.get(
									this,
									['props', 'isRelatedEncounterClickable'],
									true
								)}
							/>
						)}

					{siblingEncounter.length > 0 &&
						siblingEncounter.map((item, idx) => {
							return (
								<PatientCard
									key={idx}
									providerenums={_.get(
										this,
										['props', 'providerenums'],
										[]
									)}
									providerName={_.get(
										item,
										['visit_provider'],
										''
									)}
									name={_.get(item, ['name'], '')}
									date_of_service={_.get(
										item,
										['date_of_service'],
										''
									)}
									timezone={_.get(
										parentEncounter,
										['timezone'],
										null
									)}
									state_of_visit={_.get(
										item,
										['state_of_visit'],
										''
									)}
									encounter_uuid={_.get(item, ['uuid'], '')}
									patient_uuid={_.get(
										item,
										['patient_uuid'],
										''
									)}
									gotoRelatedEncounter={
										this.props.gotoRelatedEncounter
									}
									showLinkIcon={_.get(
										this.props,
										'showLinkIcon',
										true
									)}
									isRelatedEncounterClickable={_.get(
										this,
										[
											'props',
											'isRelatedEncounterClickable',
										],
										true
									)}
								/>
							);
						})}

					{secondaryEncounter.length > 0 &&
						secondaryEncounter.map((item, idx) => {
							return (
								<PatientCard
									key={idx}
									providerenums={_.get(
										this,
										['props', 'providerenums'],
										[]
									)}
									providerName={_.get(
										item,
										['visit_provider'],
										''
									)}
									name={_.get(item, ['name'], '')}
									date_of_service={_.get(
										item,
										['date_of_service'],
										''
									)}
									timezone={_.get(item, ['timezone'], null)}
									state_of_visit={_.get(
										item,
										['state_of_visit'],
										''
									)}
									encounter_uuid={_.get(item, ['uuid'], '')}
									patient_uuid={_.get(
										item,
										['patient_uuid'],
										''
									)}
									gotoRelatedEncounter={
										this.props.gotoRelatedEncounter
									}
									showLinkIcon={_.get(
										this.props,
										'showLinkIcon',
										true
									)}
									isRelatedEncounterClickable={_.get(
										this,
										[
											'props',
											'isRelatedEncounterClickable',
										],
										true
									)}
								/>
							);
						})}
				</div>
			</div>
		);
	}
}

export default RelatedEncounterInfoSegment;
