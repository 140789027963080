import { z, ZodSchema } from 'zod';
import _ from 'lodash';

/**
 * Validates `input` against Zod `schema`.
 * @param schema - The Zod schema to validate against.
 * @param input - The form input to validate.
 * @returns An error object containing validation errors.
 */
export function formValidation<T extends ZodSchema>(
	schema: T,
	input: z.infer<T>
): Record<string, string> {
	const result = schema.safeParse(input);
	if (result.success) {
		return {};
	}

	const errors = result.error.errors.reduce(
		(acc: Record<string, string>, item) => {
			const path = item.path.join('.');
			_.set(acc, path, item.message);
			return acc;
		},
		{}
	);

	return errors;
}
