import * as actionTypes from '../actions/actionTypes';

const defaultNotes = {
	loadingNotes: false,
	notes: [],
	notesError: null,
};

const defaultSubmitNotes = {
	loadingsubmitnotes: false,
	notesdetail: [],
	notesdetailError: null,
};

const defaultDeleteNotes = {
	deleteLoadingNotes: false,
	deleteNotesError: false,
	deleteNotesSuccess: false,
};

export const notes = (state = defaultNotes, action) => {
	if (action.type === actionTypes.LOADING_NOTES) {
		const newState = Object.assign({}, state);
		newState.loadingNotes = true;
		newState.notesError = null;
		return newState;
	} else if (action.type === actionTypes.NOTES) {
		const newState = Object.assign({}, state);
		newState.loadingNotes = false;
		newState.notes = action.payload;
		newState.notesError = null;
		return newState;
	} else if (action.type === actionTypes.NOTES_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingNotes = false;
		newState.notes = [];
		newState.notesError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const submitnotes = (state = defaultSubmitNotes, action) => {
	if (action.type === actionTypes.LOADING_SUBMIT_NOTES) {
		const newState = Object.assign({}, state);
		newState.loadingsubmitnotes = true;
		newState.notesdetail = [];
		newState.notesdetailError = null;
		return newState;
	} else if (action.type === actionTypes.SUBMIT_NOTES) {
		const newState = Object.assign({}, state);
		newState.loadingsubmitnotes = false;
		newState.notesdetail = action.payload;
		newState.notesdetailError = null;
		return newState;
	} else if (action.type === actionTypes.SUBMIT_NOTES_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingsubmitnotes = false;
		newState.notesdetail = [];
		newState.notesdetailError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const fetchnotes = (state = { fetchnotes: false }, action) => {
	if (action.type === actionTypes.FETCH_NOTES) {
		const newState = Object.assign({}, state);
		newState.fetchnotes = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const deletenotes = (state = defaultDeleteNotes, action) => {
	if (action.type === actionTypes.DELETE_LOADING_NOTES) {
		const newState = Object.assign({}, state);
		newState.deleteLoadingNotes = action.payload;
		return newState;
	} else if (action.type === actionTypes.DELETE_NOTES_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.deleteNotesSuccess = action.payload;
		newState.deleteLoadingNotes = false;
		return newState;
	} else if (action.type === actionTypes.DELETE_NOTES_ERROR) {
		const newState = Object.assign({}, state);
		newState.deleteNotesError = action.payload;
		return newState;
	} else {
		return state;
	}
}
