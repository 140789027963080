//React & Redus
import React from 'react';

//Lodash
import get from 'lodash/get';

//Utils
import { roleNamesOverride } from '../../../../../Utils/permissionUtils.js';
import { ucFirstAllWords } from '../Common/utilsfunctions.js';
//Components
import AuditTrailPatientInfo from './AuditTrailPatientInfo.js';
import AuditTrailData from './AuditTrailData.js';

class AuditTrailBody extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	getScheduledBy = () => {
		const { encounter } = this.props;
		const appointment = this.getBookedAppointment();
		let user = {};
		let role = '';
		if (!get(appointment, 'is_changed_by_patient')) {
			user = get(this, 'props.createdByUser');

			//get role
			const roles = get(this, ['props', 'roles'], []);
			const defaultRole = get(user, 'roles', []).find(
				(e) => e.default == true
			);
			const role_uuid = get(defaultRole, 'uuid', '');
			const roleObj = roles.find((e) => e.uuid == role_uuid);
			role = get(roleObj, 'name', '');
		} else {
			user = get(this, 'props.patient');
			role = ucFirstAllWords(get(this, 'props.patient.role'));

			if (
				get(encounter, 'part_of', false) &&
				get(appointment, 'changed_by') ==
					get(encounter, 'part_of.patient_uuid')
			) {
				user = get(encounter, 'part_of', user);
			}
		}
		//override the role
		role = get(roleNamesOverride, role, role);
		const name = `${get(user, 'first_name', '')} ${get(
			user,
			'last_name',
			''
		)}`;
		const email = get(user, 'email', '');
		const date = get(appointment, 'changed_at', '');
		return { name, email, role, date };
	};

	getBookedAppointment = () => {
		const { encounter } = this.props;
		return (
			get(encounter, 'type', '') == 'visit' &&
			(get(encounter, ['visit_status_history'], []) || []).find(
				(e) => e.visit_status == 'booked'
			)
		);
	};

	render() {
		return (
			<div className='auditTrailBody'>
				<AuditTrailPatientInfo
					encounter={get(this, 'props.encounter', {}) || {}}
					patient={get(this, 'props.patient', {}) || {}}
					getCreatedByUser={get(this, ['props', 'getCreatedByUser'])}
					createdByUser={get(this, ['props', 'createdByUser'])}
					roles={get(this, ['props', 'roles'], [])}
					resetUser={get(this, ['props', 'resetUser'])}
					getScheduledBy={this.getScheduledBy}
					getBookedAppointment={this.getBookedAppointment}
				/>
				<AuditTrailData
					auditTrailEncounterLoading={get(
						this,
						'props.auditTrailEncounterLoading',
						null
					)}
					auditTrailEncounter={
						get(this, 'props.auditTrailEncounter', []) || []
					}
					auditTrailEncounterError={get(
						this,
						'props.auditTrailEncounterError',
						null
					)}
					encounterField={get(this, 'props.encounterField', null)}
					changeEncounterField={this.props.changeEncounterField}
					currentField={get(this, 'props.currentField', null)}
					changeCurrentField={this.props.changeCurrentField}
					encounterAuditEnumLoading={get(
						this,
						'props.encounterAuditEnumLoading',
						null
					)}
					encounterAuditEnum={
						get(this, 'props.encounterAuditEnum', []) || []
					}
					encounterBillingEnumLoading={get(
						this,
						'props.encounterBillingEnumLoading',
						null
					)}
					encounterBillingEnum={
						get(this, 'props.encounterBillingEnum', []) || []
					}
					encounterCreationDate={get(
						this,
						'props.encounter.created_at',
						null
					)}
					encounter={get(this, 'props.encounter', {}) || {}}
					hadSuccessfulCall={get(
						this,
						'props.hadSuccessfulCall',
						false
					)}
					relevantEncounterEnum={
						get(this, 'props.relevantEncounterEnum', {}) || {}
					}
					getScheduledBy={this.getScheduledBy}
					getCreatedByUser={get(this, ['props', 'getCreatedByUser'])}
					getAllUsers={get(this, ['props', 'getAllUsers'])}
					allUsers={get(this, ['props', 'allUsers'])}
					patient={get(this, 'props.patient', {}) || {}}
					getBookedAppointment={this.getBookedAppointment}
				/>
			</div>
		);
	}
}

export default AuditTrailBody;
