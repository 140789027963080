import * as actionTypes from './actionTypes';
import UsersService from '../service/Users';
import _ from 'lodash';

export const startLoadUsers = () => {
	return { type: actionTypes.LOAD_USERS_START };
};

export function successLoadUsers(users) {
	return { type: actionTypes.LOAD_USERS_SUCCESS, payload: users };
}

export function failureLoadUsers(error) {
	return { type: actionTypes.LOAD_USERS_FAILURE, payload: error };
}

export function unloadUsers() {
	return { type: actionTypes.UNLOAD_USERS };
}

export function LoadUsers(data) {
	return (dispatch) => {
		dispatch(startLoadUsers());
		return UsersService.get(data)
			.then((res) => {
				const resData = _.get(res, ['data'], {});
				return dispatch(
					successLoadUsers({
						data: _.get(resData, ['data'], []),
						query: _.get(data, 'q', ''),
						pagination: {
							end: _.get(resData, ['end']),
							has_next: _.get(resData, ['has_next']),
							has_prev: _.get(resData, ['has_prev']),
							limit: _.get(resData, ['limit']),
							offset: _.get(resData, ['offset']),
							start: _.get(resData, ['start']),
							total: _.get(resData, ['total']),
						},
					})
				);
			})
			.catch((err) => {
				dispatch(failureLoadUsers(err));
			});
	};
}

export function resetLoadUsers() {
	return (dispatch) => {
		return dispatch(unloadUsers());
	};
}

export const startLoadUser = () => {
	return { type: actionTypes.LOAD_USER_START };
};
export function successLoadUser(user) {
	return { type: actionTypes.LOAD_USER_SUCCESS, payload: user };
}
export function failureLoadUser(error) {
	return { type: actionTypes.LOAD_USER_FAILURE, payload: error };
}
export function unloadUser() {
	return { type: actionTypes.UNLOAD_USER };
}
export function LoadUser(uuid) {
	return (dispatch) => {
		dispatch(startLoadUser());
		return UsersService.getByUUID(uuid)
			.then((res) => {
				return dispatch(
					successLoadUser(_.get(res, ['data', 'data'], {}))
				);
			})
			.catch((err) => {
				dispatch(failureLoadUser(err));
			});
	};
}
export function resetLoadUser() {
	return (dispatch) => {
		return dispatch(unloadUser());
	};
}
