import { gql } from '@apollo/client';

import { client } from './GraphQLConnection';

class CfDNA {
	createDiagnosticReport = (patientID, testOrderID) => {
		return client.mutate({
			mutation: gql`
				mutation {
					addDiagnosticReport(
						input: {
							subjectId: "${patientID}"
							testOrderId: "${testOrderID}"
							status: FINAL
						}
					) {
						diagnosticReport {
							id
						}
					}
				}
			`,
		});
	};

	addDiagnosticFinding = (
		{
			cancerSignalDetected,
			primaryPredictedOrign,
			secondaryPredictedOrign,
		},
		diagnosticReportId
	) => {
		return client.mutate({
			mutation: gql`
				mutation {
					addDiagnosticFinding(
						input: {
							diagnosticReportId: "${diagnosticReportId}"
							finding: {
								observationFinding: {
									category: LABORATORY
									kind: "gm:00000002"
									value: { term: "${cancerSignalDetected ? 'gm:00000061' : 'gm:00000062'}" }
									components: [
										${
											primaryPredictedOrign
												? `{ kind: "gm:00000004", value: { term: "${primaryPredictedOrign}" } }`
												: ''
										}
										${
											secondaryPredictedOrign
												? `{ kind: "gm:00000005", value: { term: "${secondaryPredictedOrign}" } }`
												: ''
										}
									]
								}
							}
						}
					) {
						finding {
							id
						}
					}
				}
			`,
		});
	};

	getDiagnosticReports = (testOrderID) => {
		return client.query({
			query: gql`
				query {
					diagnosticReports(testOrderId: "${testOrderID}") {
						id
						status
						subject {
							... on Patient {
								id
								name
							}
						}
						notes {
							id
							enteredAt
							text
							source {
								id
							}
						}
						reportedAt
						assertedAt
						assertedBy {
							id
							name
						}
						performedBy {
						... on Lab {
							id
							name
						}
						}
			
				findings {
					... on StructuralVariantFinding {
						id
						assessment
						variantType
						chromosomalExtents {
						  chromosome
						  cytogeneticLocation
						  startPosition
						  endPosition
						}
						geneticExtents {
						  gene {
							id
							symbol
							name
							aliasSymbols
							cytogeneticLocation
						  }
						  startExon
						  endExon
						  entireCodingRegion
						}                        
						genomicSourceClass
						cytogenomicNomenclature
						cytogenomicCharacterization
						size {
						  value
						  unit
						}
						origin
						mosaicism						
						notes {
						  id
						  enteredAt
						  text
						  source {
							... on User {
							  name
							}
						  }
						}
						interpretations {
						  interpretedOn
						  classification
						  source {
							id
							name
						  }
						}
						mostRecentInterpretation {
						  interpretedOn
						  source {
							id
							name
						  }
						  classification
						}
						originalReportedInterpretation {
						  classification
						  interpretedOn
						  source {
							id
							name
						  }
						}
						copyNumber
						externalVariantIds
						assertedAt
						assertedBy {
						  id
						  name
						}
						structuralReferenceAssembly: referenceAssembly {
							code
							name
							description
						}
					}        
				  ... on DiscreteVariantFinding {
					id
					notes {
					  enteredAt
					  id
					  text
					  source {
						... on User {
						  name
						}
					  }
					}
					referenceAssembly {
					  code
					  name
					  description
					}
					hgvsNomenclature {
					  transcriptReferenceSequence
					  dnaChange
					  genomicDNAChange
					  proteinChange
					  proteinReferenceSequence
					  genomicReferenceSequence
					}
					cytogenomicNomenclature
					dnaChangeType
					proteinChangeType
					dbsnpId
					gene {
					  id
					  symbol
					  name
					  aliasSymbols,
					  cytogeneticLocation
					}
					allelicFrequency
					assessment
					allelicReadDepth
					allelicState
					externalVariantIds
					origin                    
					genomicSourceClass
					mosaicism                    
					interpretations {
						interpretedOn
						classification
						source {
							id
							name
						}
					}
					mostRecentInterpretation {
						interpretedOn
						source {
							id
							name
						}
						classification
					}
					originalReportedInterpretation {
						classification
						interpretedOn
						source {
							id
							name
						}
					}
					assertedBy {
						id
						name
					}
				  }
				  ... on NucleotideRepeatVariantFinding {
					id
					notes {
					  enteredAt
					  id
					  text
					  source {
						... on User {
						  name
						}
					  }
					}
					referenceAssembly {
					  code
					  name
					  description
					}
					hgvsDNAChange
					referenceSequence																				
					gene {
					  id
					  symbol
					  name
					  aliasSymbols,
					  cytogeneticLocation
					}					
					assessment
					nucleotideGenomicSourceClass: genomicSourceClass										                    
					allele1 {
						repeats {
							comparator
							value
							unit
						}
						characterization
						originalReportedInterpretation {
							classification
							interpretedOn
							source {
								id
								name
							}
						}
						mostRecentInterpretation {
							interpretedOn
							source {
								id
								name
							}
							classification
						}
						interpretations {
							interpretedOn
							classification
							source {
								id
								name
							}
						}
					}
					allele2 {
						repeats {
							comparator
							value
							unit
						}
						characterization
						originalReportedInterpretation {
							classification
							interpretedOn
							source {
								id
								name
							}
						}
						mostRecentInterpretation {
							interpretedOn
							source {
								id
								name
							}
							classification
						}
						interpretations {
							interpretedOn
							classification
							source {
								id
								name
							}
						}
					}
					assertedAt					                    															
					assertedBy {
						id
						name
					}
				  }
				  ... on ObservationFinding {
					id
					category
					notes {
					  enteredAt
					  id
					}
					assertedBy {
					  id
					  name
					}
					kind {
					  id
					  label
					}
					observedAt
					value {
						... on Term {
							id
							label
						}
					}
					value {
						... on Quantity {
							value
							unit
						}
					}
					components {
					  kind {
						id
						label
					  }
					  value {
						... on Term {
						  id
						  label
						}
						... on Quantity {
							value
							unit
						}
					  }
					}
					notes{
						id
						enteredAt
						text
						source {
							... on User {
								name
							}
						}                                    
					}
				  }
				  ... on RiskAssessmentFinding {
						id
						type {
						id
						label
						}
						version
						assessedAt
						assessedBy {
							... on Lab {
								id
								name
							}
						}
						predictions {
							assessed
							timeframe
							rationale
							baselineProbability
							relativeRisk
							probability
							version
							mitigation
							qualitativeRisk {
								id
								label
							}
							outcome {
								id
								label
							}
						}
						notes {
							id
							enteredAt
							text
							source {
								... on User {
									name
								}
							}
						}
					}
				}
			  }
			}
			`,
		});
	};

	updateDiagnosticFinding = ({
		cancerSignalDetected,
		primaryPredictedOrign,
		secondaryPredictedOrign,
		uuid,
	}) => {
		return client.mutate({
			mutation: gql`
				mutation {
					updateDiagnosticFinding(
						input: {
							observationFinding: {
								id: "${uuid}"
								value: { term: "${cancerSignalDetected ? 'gm:00000061' : 'gm:00000062'}" }
								components: [
									${
										primaryPredictedOrign
											? `{ kind: "gm:00000004", value: { term: "${primaryPredictedOrign}" } }`
											: ''
									}
									${
										secondaryPredictedOrign
											? `{ kind: "gm:00000005", value: { term: "${secondaryPredictedOrign}" } }`
											: ''
									}
								]
							}
						}
					) {
						finding {
							id
						}
					}
				}
			`,
		});
	};

	deleteDiagnosticFinding = (diagnosticFindingId) => {
		return client.mutate({
			mutation: gql`
				mutation {
					deleteDiagnosticFinding(
						input: { diagnosticFindingId: "${diagnosticFindingId}" }
					) {
						finding {
							id
						}
					}
				}
			`,
		});
	};

	addDiagnosticFindingClinicalNote = (findingId, text) => {
		return client.mutate({
			mutation: gql`
				mutation ($findingId: ID!, $text: Markdown!) {
					addNote(input: { aboutItemId: $findingId, text: $text }) {
						note {
							id
						}
					}
				}
			`,
			variables: {
				findingId: findingId,
				text: text,
			},
		});
	};
}

export default new CfDNA();
