//React & Redux
import React from 'react';
import { connect } from 'react-redux';

//Lodash
import { get, find, debounce } from 'lodash';
import moment from 'moment';

//Actions & Services
import { setFilter } from '../../actions/appData';
import { LoadUsers } from '../../actions/users';
import {
	loadPartners,
	createUser,
	updateUser,
	getUserAuditTrail,
	clearData,
	deleteUser,
} from '../../actions/userAccounts';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import AddEditUserModal from './AddEditUserModal';
import { Table, Thead, Tbody, Tr, Th, Td } from '../Common/Table';
import ThreeDotsMenu from '../Common/controls/ThreeDotsMenu';
import TextWithTooltip from '../Common/TextWithTooltip';
import Searchbar from '../Filter/Searchbar';
import ResetFilters from '../Filter/ResetFilters';
import MultiSelectCheckbox from '../Filter/MultiSelectCheckbox';
import AuditTrailModal from './AuditTrailModal';
import UserDeleteDialog from './UserDeleteDialog';

//Images
import ReactSVG from 'react-svg';
import plusIcon from './../../assets/add.svg';

//Utils
import { isExternalRole, ROLE_NOACCESS } from '../../Utils/permissionUtils';

//Styles
import './UserAccounts.css';

class UserAccounts extends React.Component {
	constructor(props) {
		super(props);

		const userAccountsFilters = get(
			this,
			['props', 'userAccountsFilters'],
			{}
		);
		this.defaultFilters = {
			order_by: 'name',
			order_type: 'asc',
			q: '',
			role: [],
			offset: 0,
			limit: get(this, ['props', 'limit'], 25),
			partnerUUIDs: [],
		};
		this.state = {
			showAddEditUserModal: false,
			userData: null,
			filters: { ...this.defaultFilters, ...userAccountsFilters },
			showAuditTrailModal: false,
			showDeleteUserDialog: false,
			usersState: get(this, ['props', 'users'], {}),
		};

		this.getUsers = debounce(() => {
			this.props.getUsers({ ...this.state.filters });
		}, 500);
	}

	componentDidMount = () => {
		this.props.getUsers({ ...this.state.filters });
		this.props.getPartners();
	};

	componentDidUpdate = (prevProps) => {
		if (
			!get(prevProps, ['userRolesLoaded'], false) &&
			get(this, ['props', 'userRolesLoaded'], false)
		)
			this.noAccessUUID = get(
				find(get(this, ['props', 'userRoles'], []), [
					'name',
					ROLE_NOACCESS,
				]),
				['uuid'],
				null
			);

		if (
			!get(prevProps, ['createUserLoaded'], false) &&
			get(this, ['props', 'createUserLoaded'], true) &&
			get(this, ['props', 'createUserSuccess'], '')
		) {
			this.props.enqueueSnackbar(
				get(this, ['props', 'createUserSuccess'], ''),
				{
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				}
			);
			this.hideAddEditUserModal();
			this.props.getUsers({ ...this.state.filters });
		}

		if (
			!get(prevProps, ['updateUserLoaded'], false) &&
			get(this, ['props', 'updateUserLoaded'], true) &&
			get(this, ['props', 'updateUserSuccess'], '')
		) {
			this.props.enqueueSnackbar(
				get(this, ['props', 'updateUserSuccess'], ''),
				{
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				}
			);
			this.hideAddEditUserModal();
			this.props.getUsers({ ...this.state.filters });
		}

		if (
			!get(prevProps, ['users', 'loaded']) &&
			get(this, ['props', 'users', 'loaded'], false) &&
			!get(this, ['props', 'users', 'error'])
		) {
			this.props.setUserAccountsFilters({ ...this.state.filters });
			this.setState({
				usersState: this?.props?.users,
			});
		}

		if (
			!get(prevProps, ['deleteUserLoaded'], false) &&
			get(this, ['props', 'deleteUserLoaded'], true)
		) {
			this.hideDeleteUserDialog();
		}

		if (
			!get(prevProps, ['deleteUserLoaded'], false) &&
			get(this, ['props', 'deleteUserLoaded'], true) &&
			get(this, ['props', 'deleteUserSuccess'], '')
		) {
			this.props.enqueueSnackbar(
				get(this, ['props', 'deleteUserSuccess'], ''),
				{
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				}
			);

			const { usersState } = this.state;
			const removeDeletedUser = usersState?.data.filter((el) => {
				return el.uuid != this.state.userData.uuid;
			});
			this.setState({
				usersState: {
					...this.state.usersState,
					data: removeDeletedUser,
				},
			});
		}
	};

	showAddEditUserModal = (userData = null) => {
		this.setState({ showAddEditUserModal: true, userData });
	};

	hideAddEditUserModal = () => {
		this.setState({ showAddEditUserModal: false, userData: null });
		this.props.clearData();
	};

	changeFilterHandler = (key, value) => {
		this.setState(
			{
				filters: {
					...this.state.filters,
					[key]: value,
					offset: 0,
				},
			},
			this.getUsers
		);
	};

	constructEnums = (enums) => {
		return enums.map((obj) => {
			return {
				key: obj.uuid || obj.key,
				display_name: obj.name || obj.value,
			};
		});
	};

	handleResultsPerPageChange = (limit) => {
		this.setState(
			{ filters: { ...this.state.filters, offset: 0, limit } },
			() => {
				this.props.getUsers({ ...this.state.filters });
			}
		);
	};

	handlePageChange = (e, offset) => {
		this.setState({ filters: { ...this.state.filters, offset } }, () => {
			this.props.getUsers({ ...this.state.filters });
		});
	};

	handleResetFilters = () => {
		this.setState({ filters: { ...this.defaultFilters } }, () => {
			this.props.getUsers({ ...this.state.filters });
		});
	};

	handleSort = (order_by, order_type) => {
		this.setState(
			{
				filters: {
					...this.state.filters,
					order_by,
					order_type,
					offset: 0,
				},
			},
			() => {
				this.props.getUsers({ ...this.state.filters });
			}
		);
	};

	showAuditTrailModal = (auditTrailUUID) => {
		this.setState({ showAuditTrailModal: true });
		this.props.getUserAuditTrail(auditTrailUUID);
	};

	hideAuditTrailModal = () => {
		this.setState({ showAuditTrailModal: false });
	};

	showDeleteUserDialog = (userData) => {
		this.setState({ showDeleteUserDialog: true, userData });
	};

	hideDeleteUserDialog = () => {
		this.setState({ showDeleteUserDialog: false, userData: null });
	};

	rolesUUIDToNames = (roles) => {
		return roles.map(({ uuid }) => {
			return get(
				find(get(this, ['props', 'userRoles'], []), ['uuid', uuid]),
				['name'],
				null
			);
		});
	};

	hasExternalRole = (roles) => {
		const externalRoles = this.rolesUUIDToNames(roles);
		let isExternal = false;
		(externalRoles || []).forEach((role) => {
			if (isExternalRole(role)) {
				isExternal = true;
			}
		});
		return isExternal;
	};

	partnersUUIDToNames = (partners) => {
		const selectedPartner = partners.map((uuid) => {
			return get(
				find(get(this, ['props', 'partners'], []), ['uuid', uuid]),
				['name'],
				null
			);
		});
		return selectedPartner.filter((el) => {
			return el != null;
		});
	};

	hasNoAccessRole = (roles) => {
		return !!find(roles, ['uuid', this.noAccessUUID]);
	};

	render = () => {
		const { pagination, data } = this.state.usersState;
		const tableLoading =
			get(this, ['props', 'userRolesLoading'], false) ||
			get(this, ['props', 'users', 'loading'], false);
		const sortBy = {
			key: get(this, ['state', 'filters', 'order_by'], null),
			value: get(this, ['state', 'filters', 'order_type'], null),
		};

		return (
			<div className='user-account-management-wrapper'>
				<div style={{ display: 'flex', minWidth: 'inherit' }}>
					<div
						className='addTestButton'
						onClick={() => this.showAddEditUserModal()}
						style={{ width: '140px' }}
					>
						<ReactSVG
							src={plusIcon}
							style={{
								position: 'relative',
								top: '1px',
								marginRight: '8px',
							}}
						/>
						Add New User
					</div>
					<div className='filter-rightcontainer'>
						<Searchbar
							setStateFunc={(e) =>
								this.changeFilterHandler('q', e.target.value)
							}
							currentValue={
								get(this, ['state', 'filters', 'q'], '') || ''
							}
							useInputEvtObj={true}
							placeholder='Search'
						/>

						<ResetFilters
							handleResetFilters={this.handleResetFilters}
						/>

						<MultiSelectCheckbox
							data={this.constructEnums(
								get(this, ['props', 'userRoles'], [])
							)}
							selected={get(
								this,
								['state', 'filters', 'role'],
								[]
							)}
							title={'Role Type'}
							submit={(values) =>
								this.changeFilterHandler('role', [...values])
							}
							majorPillTitle={'Role Type'}
							defaultValues={get(
								this,
								['defaultFilter', 'role'],
								[]
							)}
							searchBar={false}
							previewTab={false}
							loading={get(
								this,
								['props', 'userRoleLoading'],
								false
							)}
						/>
						<MultiSelectCheckbox
							data={this.constructEnums(
								get(this, ['props', 'partners'], [])
							)}
							selected={get(
								this,
								['state', 'filters', 'partnerUUIDs'],
								[]
							)}
							title={'Referral Programs'}
							submit={(values) =>
								this.changeFilterHandler('partnerUUIDs', [
									...values,
								])
							}
							majorPillTitle={'Referral Programs'}
							defaultValues={get(
								this,
								['defaultFilter', 'partnerUUIDs'],
								[]
							)}
							searchBar={true}
							previewTab={true}
							loading={get(
								this,
								['props', 'userRoleLoading'],
								false
							)}
						/>
					</div>
				</div>
				{get(this, ['state', 'showAddEditUserModal'], false) && (
					<AddEditUserModal
						partners={get(this, ['props', 'partners'], [])}
						partnersLoading={get(
							this,
							['props', 'partnersLoading'],
							false
						)}
						partnersLoaded={get(
							this,
							['props', 'partnersLoaded'],
							true
						)}
						hideAddEditUserModal={this.hideAddEditUserModal}
						userData={get(this, ['state', 'userData'], null)}
						userRoles={get(this, ['props', 'userRoles'], [])}
						createUser={this.props.createUser}
						createUserLoading={this.props.createUserLoading}
						createUserError={this.props.createUserError}
						updateUser={this.props.updateUser}
						updateUserLoading={this.props.updateUserLoading}
						updateUserError={this.props.updateUserError}
					/>
				)}
				{get(this, ['state', 'showAuditTrailModal'], false) && (
					<AuditTrailModal
						hideAuditTrailModal={this.hideAuditTrailModal}
						loading={this.props.auditTrailLoading}
						loaded={this.props.auditTrailLoaded}
						logs={this.props.auditTrail}
						error={this.props.auditTrailError}
					/>
				)}
				{get(this, ['state', 'showDeleteUserDialog'], false) && (
					<UserDeleteDialog
						onCancel={this.hideDeleteUserDialog}
						userData={get(this, ['state', 'userData'], null)}
						deleteUser={this.props.deleteUser}
						deleteUserLoading={this.props.deleteUserLoading}
						enqueueSnackbar={this.props.enqueueSnackbar}
					/>
				)}
				{tableLoading ? (
					<Loading loading={true} style={{ top: '80%' }} />
				) : (
					<Table
						style={{ marginTop: '16px', minWidth: '1204px' }}
						backToTop
						resultsPerPage={[25, 50, 100]}
						handleResultsPerPageChange={
							this.handleResultsPerPageChange
						}
						pagination={pagination}
						handlePageChange={this.handlePageChange}
						hasData={data.length > 0}
						handleResetFilters={this.handleResetFilters}
						handleSort={this.handleSort}
						sortBy={sortBy}
					>
						<Thead>
							<Tr>
								<Th
									sortable={{
										name: {
											display_name: 'name',
											sortBy: {
												asc: 'A to Z',
												desc: 'Z to A',
											},
										},
									}}
								>
									Name
								</Th>
								<Th>Role(s)</Th>
								<Th>Associated Partners</Th>
								<Th
									sortable={{
										created_at: {
											display_name: 'Created On',
											sortBy: {
												asc: 'asc',
												desc: 'desc',
											},
										},
									}}
								>
									Created On
								</Th>
								<Th />
							</Tr>
						</Thead>
						<Tbody>
							{data.map((obj) => {
								const full_name =
									obj.first_name || obj.last_name
										? obj.first_name + ' ' + obj.last_name
										: '--';
								return (
									<Tr key={obj.uuid}>
										<Td minWidth='296px' maxWidth='326px'>
											<p style={{ display: 'flex' }}>
												<TextWithTooltip maxWidth='326px'>
													{full_name}
												</TextWithTooltip>
											</p>
											<TextWithTooltip
												className='sub'
												maxWidth='326px'
											>
												{obj.email || '--'}
											</TextWithTooltip>
										</Td>
										<Td minWidth='350px' maxWidth='400px'>
											{this.rolesUUIDToNames(
												get(obj, ['roles'], [])
											).join(', ') || '--'}
										</Td>
										<Td minWidth='250px' maxWidth='300px'>
											{(this.hasExternalRole(
												get(obj, ['roles'], [])
											) &&
												this.partnersUUIDToNames(
													get(obj, ['partners'], [])
												).join(', ')) ||
												'--'}
										</Td>
										<Td minWidth='198px' maxWidth='216px'>
											{obj.created_at
												? moment(obj.created_at).format(
														'MMMM DD, YYYY'
													)
												: null}
										</Td>
										<Td minWidth='54px' maxWidth='54px'>
											<ThreeDotsMenu
												options={[
													{
														label: 'Edit User Info',
														click: () =>
															this.showAddEditUserModal(
																obj
															),
													},
													{
														label: 'Audit Trail',
														click: () =>
															this.showAuditTrailModal(
																obj.uuid
															),
													},
													{
														label: 'Delete User',
														click: () =>
															this.showDeleteUserDialog(
																obj
															),
													},
												]}
											/>
										</Td>
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				)}
			</div>
		);
	};
}

const mapStateToProps = (state) => ({
	userRoles: get(state, ['UserRoles', 'data'], []),
	userRolesLoading: get(state, ['UserRoles', 'loading'], false),
	userRolesLoaded: get(state, ['UserRoles', 'loaded'], true),
	userRolesError: get(state, ['UserRoles', 'error'], null),
	users: get(state, ['Users'], {}),
	partners: get(state, ['partners', 'data'], []),
	partnersLoading: get(state, ['partners', 'loading'], false),
	partnersLoaded: get(state, ['partners', 'loaded'], true),
	partnersError: get(state, ['partners', 'error'], null),
	createUserSuccess: get(state, ['createUser', 'message'], ''),
	createUserLoading: get(state, ['createUser', 'loading'], false),
	createUserLoaded: get(state, ['createUser', 'loaded'], true),
	createUserError: get(state, ['createUser', 'error'], null),
	updateUserSuccess: get(state, ['updateUser', 'message'], ''),
	updateUserLoading: get(state, ['updateUser', 'loading'], false),
	updateUserLoaded: get(state, ['updateUser', 'loaded'], true),
	updateUserError: get(state, ['updateUser', 'error'], null),
	auditTrail: get(state, ['userAuditTrail', 'data'], []),
	auditTrailLoading: get(state, ['userAuditTrail', 'loading'], false),
	auditTrailLoaded: get(state, ['userAuditTrail', 'loaded'], true),
	auditTrailError: get(state, ['userAuditTrail', 'error'], null),
	limit: state.uiConfig.table.resultsPerPage,
	userAccountsFilters: get(state, ['appData', 'userAccounts'], {}),
	deleteUserSuccess: get(state, ['deleteUser', 'message'], ''),
	deleteUserLoading: get(state, ['deleteUser', 'loading'], false),
	deleteUserLoaded: get(state, ['deleteUser', 'loaded'], true),
	deleteUserError: get(state, ['deleteUser', 'error'], null),
});

const mapDispatchToProps = (dispatch) => ({
	getUsers: (data) => dispatch(LoadUsers(data)),
	getPartners: () => dispatch(loadPartners()),
	createUser: (data) => dispatch(createUser(data)),
	updateUser: (data, uuid) => dispatch(updateUser(data, uuid)),
	getUserAuditTrail: (uuid) => dispatch(getUserAuditTrail(uuid)),
	setUserAccountsFilters: (data) => dispatch(setFilter('userAccounts', data)),
	clearData: () => dispatch(clearData()),
	deleteUser: (uuid, data) => dispatch(deleteUser(uuid, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAccounts);
