import get from 'lodash/get';

export const sortVisitProvidersByLastName = (providers) => {
	if (Array.isArray(get(providers, 'data.visit_provider', null))) {
		providers.data.visit_provider.sort((a, b) => {
			const firstLastName =
				(get(a, 'key', ' _ ') || ' _ ').split('_')[1] || '';
			const secondLastName =
				(get(b, 'key', ' _ ') || ' _ ').split('_')[1] || '';
			return firstLastName > secondLastName ? 1 : -1;
		});
	}
	return providers;
};

export const sortVisitProvidersByFirstName = (providers) => {
	if (Array.isArray(get(providers, 'data.visit_provider', null))) {
		providers.data.visit_provider.sort((a, b) => {
			const firstFirstName =
				(get(a, 'key', ' _ ') || ' _ ').split('_')[0] || '';
			const secondFirstName =
				(get(b, 'key', ' _ ') || ' _ ').split('_')[0] || '';
			return firstFirstName > secondFirstName ? 1 : -1;
		});
	}
	return providers;
};
