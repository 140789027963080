//React & Redux
import React from 'react';

import moment from 'moment';

//Images

//Other Libraries
import { Dialog, Button, Layout } from 'gm-element-react';
import { isNil, isEmpty, size } from 'lodash';

//Images
import ReactSVG from 'react-svg';
import editIcon from '../../../../assets/edit.svg';
//Styles
import './css/view-structural-variant-full-detail.css';

//Utils
import { CARE_PATIENTS_ENTER_TEST_RESULT } from '../../../../Utils/permissionUtils';
import {
	originEnum,
	genomicSourceClassEnum,
	labClassificationEnum,
	structuralVariantTypeEnum,
	structuralVariantUnitEnum,
	mosaicismEnum,
} from '../../../../utils';

//Components
import Icon from '../../../Common/Icon';
import RBAC from '../../../RBAC';
import Pill from '../../../Common/Pill';

const ViewStructuralVariantFullDetail = (props) => {
	const {
		showAddEditStructuralVariantModalFunc,
		hideViewStructuralVariantFullDetailFunc,
		structuralVariantFindings,
	} = props;

	const closeViewStructuralVariantFullDetail = () => {
		hideViewStructuralVariantFullDetailFunc();
	};

	const showEditStructuralVariantModalFunc = () => {
		closeViewStructuralVariantFullDetail();
		showAddEditStructuralVariantModalFunc(
			'edit',
			structuralVariantFindings
		);
	};

	const hasCytogenomicNomenclature =
		!isNil(structuralVariantFindings?.cytogenomicNomenclature) &&
		!isEmpty(structuralVariantFindings?.cytogenomicNomenclature);
	const isDisplayChromosomalExtents =
		size(structuralVariantFindings?.chromosomalExtents || []) > 0;
	const isDisplayGeneticExtents =
		size(structuralVariantFindings?.geneticExtents || []) == 1;

	const getStructuralVariantHeader = () => {
		let structuralVariantHeader = 'Structural Variant Finding';
		if (size(structuralVariantFindings?.geneticExtents || []) == 1) {
			structuralVariantHeader = `Structural Variant Finding - ${structuralVariantFindings?.geneticExtents[0].gene?.symbol}`;
			return structuralVariantHeader;
		} else if (
			size(structuralVariantFindings?.chromosomalExtents) > 0 &&
			!isNil(
				structuralVariantFindings?.chromosomalExtents[0]
					?.cytogeneticLocation
			)
		) {
			structuralVariantHeader = `Structural Variant Finding - ${structuralVariantFindings?.chromosomalExtents[0].cytogeneticLocation}`;
			return structuralVariantHeader;
		} else if (
			size(structuralVariantFindings?.chromosomalExtents) > 0 &&
			!isNil(structuralVariantFindings?.chromosomalExtents[0]?.chromosome)
		) {
			structuralVariantHeader = `Structural Variant Finding - ${structuralVariantFindings?.chromosomalExtents[0]?.chromosome}`;
			return structuralVariantHeader;
		}
		return structuralVariantHeader;
	};

	const getGeneticExtents = () => {
		let geneticExtents = '';
		const startExon =
			structuralVariantFindings?.geneticExtents?.[0]?.startExon;
		const endExon = structuralVariantFindings?.geneticExtents?.[0]?.endExon;
		const geneSymbol =
			structuralVariantFindings?.geneticExtents?.[0]?.gene?.symbol;
		const entireCodingRegion =
			structuralVariantFindings?.geneticExtents?.[0]
				?.entireCodingRegion || false;

		if (startExon && endExon) {
			geneticExtents = `${geneSymbol}, Exons ${startExon}-${endExon}`;
		} else if (entireCodingRegion) {
			geneticExtents = `${geneSymbol}, Entire coding region`;
		} else {
			geneticExtents = geneSymbol;
		}
		return geneticExtents;
	};

	const getLatestInterpretationText = () => {
		let latestInterpretationText = '--';
		const interpretedOn = moment(
			structuralVariantFindings?.mostRecentInterpretation
				?.interpretedOn || null
		).isValid()
			? moment(
					structuralVariantFindings?.mostRecentInterpretation
						?.interpretedOn || null
			  ).format('MM.DD.YYYY')
			: '';
		let mostRecentInterpretationText = '--';

		if (
			!isNil(
				structuralVariantFindings?.mostRecentInterpretation
					?.classification
			)
		) {
			mostRecentInterpretationText = labClassificationEnum.find(
				(o) =>
					o.key ===
					structuralVariantFindings?.mostRecentInterpretation
						?.classification
			);
			mostRecentInterpretationText =
				mostRecentInterpretationText?.display_name;
			latestInterpretationText =
				mostRecentInterpretationText + ' | ' + interpretedOn;
		}

		return latestInterpretationText;
	};

	const getVariantType = () => {
		let variantType = '';
		variantType = structuralVariantTypeEnum.find(
			(o) => o.key === structuralVariantFindings?.variantType
		);
		return variantType?.display_name || '';
	};

	const getChromosomalExtents = () => {
		const cytogeneticLocation =
			structuralVariantFindings?.chromosomalExtents?.[0]
				?.cytogeneticLocation;
		const chromosome =
			structuralVariantFindings?.chromosomalExtents?.[0]?.chromosome;
		const startPosition =
			structuralVariantFindings?.chromosomalExtents?.[0]?.startPosition;
		const endPosition =
			structuralVariantFindings?.chromosomalExtents?.[0]?.endPosition;
		let chromosomalExtentsLabel = '';

		if (cytogeneticLocation && startPosition && endPosition) {
			chromosomalExtentsLabel = `${cytogeneticLocation}, ${startPosition}-${endPosition}`;
		} else if (cytogeneticLocation && !startPosition && !endPosition) {
			chromosomalExtentsLabel = cytogeneticLocation;
		} else if (!cytogeneticLocation && startPosition && endPosition) {
			chromosomalExtentsLabel = `Chr ${chromosome}, ${startPosition}-${endPosition}`;
		} else if (!cytogeneticLocation && !startPosition && !endPosition) {
			chromosomalExtentsLabel = `Chr ${chromosome}`;
		}
		return chromosomalExtentsLabel;
	};

	const getSize = () => {
		let size = '--';
		let unit = '';
		if (!isEmpty(structuralVariantFindings?.sizeValue)) {
			unit = structuralVariantUnitEnum.find(
				(o) => o.key === structuralVariantFindings?.unit
			);
			size = `${structuralVariantFindings?.sizeValue || ''} ${
				(!isNil(structuralVariantFindings?.unit) &&
					!isEmpty(structuralVariantFindings?.unit) &&
					unit?.display_name) ||
				''
			}`;
		}
		return size;
	};

	const getOrigin = () => {
		const origin = originEnum.find(
			(o) => o.key === structuralVariantFindings?.origin
		);
		return origin?.display_name || '--';
	};

	const getGenomicSourceClass = () => {
		const genomicSourceClass = genomicSourceClassEnum.find(
			(o) => o.key === structuralVariantFindings?.genomicSourceClass
		);
		return genomicSourceClass?.display_name || '--';
	};

	const getMosaicism = () => {
		const mosaicism = mosaicismEnum.find(
			(o) => o.key === structuralVariantFindings?.mosaicism
		);
		return mosaicism?.display_name || '--';
	};

	return (
		<Dialog
			customClass='view-structural-variant-full-detail-dialog'
			title={
				<span className='header'>
					{getStructuralVariantHeader()}
					{structuralVariantFindings?.assessment === 'ABSENT' && (
						<Pill
							pillText='Not Detected'
							pillExtendStyle='not-detected-pill'
						/>
					)}
					<span
						className='close-btn-icon'
						onClick={closeViewStructuralVariantFullDetail}
					>
						<Icon icon='close' className='close-icon' />
					</span>
				</span>
			}
			size='tiny'
			modal={true}
			closeOnPressEscape={true}
			closeOnClickModal={false}
			showClose={false}
			visible={true}
			onCancel={closeViewStructuralVariantFullDetail}
		>
			<Dialog.Body>
				<Layout.Row gutter='26'>
					<Layout.Col span='8'>
						<span className='field-label'>Variant Type</span>
						<span
							className='field-value'
							style={{ wordBreak: 'break-word' }}
						>
							{getVariantType()}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='field-label'>size</span>
						<span className='field-value'>{getSize()}</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='field-label'>Copy Number</span>
						<span className='field-value'>
							{structuralVariantFindings?.copyNumber ?? '--'}
						</span>
					</Layout.Col>
				</Layout.Row>

				<Layout.Row gutter='26'>
					<Layout.Col span='24'>
						<span className='field-label'>Reference Assembly</span>
						<span className='field-value'>
							{structuralVariantFindings?.referenceAssembly
								?.name || '--'}
						</span>
					</Layout.Col>
				</Layout.Row>

				{hasCytogenomicNomenclature && (
					<>
						<Layout.Row className='structural-variant-section-top-border'>
							<Layout.Col span='24'>
								<span className='field-label'>
									Cytogenomic Sequence
								</span>
								<span className='field-value'>
									{structuralVariantFindings?.cytogenomicNomenclature ||
										''}
								</span>
							</Layout.Col>
						</Layout.Row>
						<Layout.Row>
							<Layout.Col span='24'>
								<span className='field-label'>
									Cytogenomic Characterization
								</span>
								<span className='field-value'>
									{
										structuralVariantFindings?.cytogenomicCharacterization
									}
								</span>
							</Layout.Col>
						</Layout.Row>
					</>
				)}

				{isDisplayChromosomalExtents && (
					<Layout.Row className='structural-variant-section-top-border'>
						<Layout.Col span='12'>
							<span className='field-label'>
								Chromosomal Extent(s)
							</span>
							<span className='field-value'>
								{getChromosomalExtents()}
							</span>
						</Layout.Col>
					</Layout.Row>
				)}

				{isDisplayGeneticExtents && (
					<Layout.Row className='structural-variant-section-top-border'>
						<Layout.Col span='12'>
							<span className='field-label'>Genetic Extent</span>
							<span className='field-value'>
								{getGeneticExtents()}
							</span>
						</Layout.Col>
					</Layout.Row>
				)}

				<Layout.Row className='structural-variant-section-top-border'>
					<Layout.Col span='24'>
						<span className='field-label'>Interpretation(s)</span>
						<span className='field-value'>
							{getLatestInterpretationText()}
						</span>
					</Layout.Col>
				</Layout.Row>

				<Layout.Row className='structural-variant-section-top-border'>
					<Layout.Col span='8'>
						<span className='field-label'>Origin</span>
						<span className='field-value'>{getOrigin()}</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='field-label'>
							Genomic Source Class
						</span>
						<span className='field-value'>
							{getGenomicSourceClass()}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='field-label'>Mosaicism</span>
						<span className='field-value'>{getMosaicism()}</span>
					</Layout.Col>
				</Layout.Row>
			</Dialog.Body>

			<Dialog.Footer className='dialog-footer'>
				<RBAC
					action={CARE_PATIENTS_ENTER_TEST_RESULT}
					yes={
						<Button
							className='edit-detail-button'
							onClick={showEditStructuralVariantModalFunc}
						>
							<ReactSVG
								src={editIcon}
								className='edit-details-icon'
							/>
							<span>Edit Original</span>
						</Button>
					}
				/>
			</Dialog.Footer>
		</Dialog>
	);
};

export default ViewStructuralVariantFullDetail;
