import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import alan_rope from '../assets/providers/alan_rope.png';
import audrey_fan from '../assets/providers/audrey_fan.png';
import betsy_swope from '../assets/providers/betsy_swope.png';
import brian_kirmse from '../assets/providers/brian_kirmse.png';
import catherine_fine from '../assets/providers/catherine_fine.png';
import douglas_stewart from '../assets/providers/douglas_steward.png';
import douglas_steward from '../assets/providers/douglas_steward.png';
import elyse_weber from '../assets/providers/elyse-weber.png';
import erynn_gordon from '../assets/providers/erynn_gordon.png';
import erynn_gordon_fishman from '../assets/providers/erynn_gordon.png';
import hailey_shabazz from '../assets/providers/hailey_shabazz.png';
import jamie_dokson from '../assets/providers/jamie_dokson.png';
import kate_sargent from '../assets/providers/kate_sargent.png';
import kathryn_sargent from '../assets/providers/kathryn_sargent.png';
import lindsay_meyers from '../assets/providers/lindsay_meyers.png';
import maria_daddario from '../assets/providers/maria_daddario.png';
import mary_frances_garber from '../assets/providers/mary_frances_garber.png';
import sarah_kalia from '../assets/providers/sarah_kalia.png';
import scott_weissman from '../assets/providers/scott_weisman.png';
import steven_bleyl from '../assets/providers/steven_bleyl.png';
import tanya_bardakjian from '../assets/providers/tanya_bardakjian.png';
import pre_appt_icon from '../assets/scp-pre-appt.svg';
import lab_test_authorization_icon from '../assets/lab.svg';
import visit_icon from '../assets/visit.svg';
import groupSession_icon from '../assets/group-session.svg';
import ccIntake_icon from '../assets/cc-intake.svg';
import research_data_icon from '../assets/research-data-return.svg';
import scp_icon from '../assets/scp.svg';
import approved_icon from '../assets/check.svg';
import reject_icon from '../assets/x.svg';
import natural_icon from '../assets/neutral.svg';
import warning_icon from '../assets/warning.svg';
import image_default from '../assets/providers/GC-Default.png';

export const providers_images = {
	alan_rope: alan_rope,
	audrey_fan: audrey_fan,
	betsy_swope: betsy_swope,
	brian_kirmse: brian_kirmse,
	catherine_fine: catherine_fine,
	douglas_stewart: douglas_stewart,
	douglas_steward: douglas_steward,
	elyse_weber: elyse_weber,
	erynn_gordon: erynn_gordon,
	erynn_gordon_fishman: erynn_gordon_fishman,
	hailey_shabazz: hailey_shabazz,
	jamie_dokson: jamie_dokson,
	kate_sargent: kate_sargent,
	kathryn_sargent: kathryn_sargent,
	lindsay_meyers: lindsay_meyers,
	maria_daddario: maria_daddario,
	mary_frances_garber: mary_frances_garber,
	sarah_kalia: sarah_kalia,
	scott_weissman: scott_weissman,
	steven_bleyl: steven_bleyl,
	tanya_bardakjian: tanya_bardakjian,
};

export const encounter_types_images = {
	lab_test_authorization: lab_test_authorization_icon,
	visit: visit_icon,
	Visit: visit_icon,
	'group-session': groupSession_icon,
	'cc-intake': ccIntake_icon,
	'research-data': research_data_icon,
	scp: scp_icon,
};

export const request_status_image = {
	approved: approved_icon,
	rejected: reject_icon,
	requested: approved_icon,
	na: natural_icon,
};

export const order_status_image = {
	sent_to_lab: natural_icon,
	results_ready: approved_icon,
	results_entered: approved_icon,
	sample_not_submitted: warning_icon,
	order_cancelled_by_provider: reject_icon,
	order_cancelled_by_patient: reject_icon,
	order_cancelled_by_lab: reject_icon,
	patient_not_responsive: reject_icon,
	patient_declined: reject_icon,
	cancelled: reject_icon,
	awaiting_information_patient: warning_icon,
	awaiting_benefits_investigation: warning_icon,
	awaiting_information_gc: warning_icon,
	awaiting_birth: warning_icon,
	awaiting_external_approval: warning_icon,
};

export const encounter_types = {
	lab_test_authorization: 'Lab',
	visit: 'Visit',
	Visit: 'Visit',
	'group-session': 'Group session',
	'cc-intake': 'CC Intake',
	'research-data': 'Research Data Return',
	'clinical-review': 'Clinical Review',
	scp: 'SCP',
};

export const visit_status_colors = {
	cancelled_by_patient: 'red',
	cancelled_by_provider: 'red',
	cancelled_by_cc: 'red',
	cancelled_rescheduled_by_provider: 'red',
	cancelled_rescheduled_by_patient: 'red',
	cancelled_by_care_coordinator: 'red',
	cancelled: 'red',
	booked: 'green',
	completed: 'gray-dark',
	upcoming: 'blue',
	noshow: 'yellow',
	no_show: 'yellow',
	reserved: 'yellow',
	requested: 'green',
};

export const order_status_color = {
	sent_to_lab: 'gray',
	results_ready: 'green',
	results_entered: 'green',
	sample_not_submitted: 'yellow',
	order_cancelled_by_provider: 'red',
	order_cancelled_by_patient: 'red',
	order_cancelled_by_lab: 'red',
	patient_not_responsive: 'red',
	patient_declined: 'red',
	cancelled: 'red',
	awaiting_information_patient: 'yellow',
	awaiting_benefits_investigation: 'yellow',
	awaiting_information_gc: 'yellow',
	awaiting_birth: 'yellow',
	awaiting_external_approval: 'yellow',
};

export const request_status_color = {
	approved: 'green',
	rejected: 'red',
	requested: 'green',
	na: 'gray',
};

export const order_request_status_main_level = {
	empty: 1,
	approved: 1,
	not_requested: 1,
	requested: 1,
	na: 1,
};
export const preAuthObj = {
	key: 'preauthorization',
	display_name: 'Preauthorization',
};
export const preAuthFields = {
	not_requested_pa_cnm: 1,
	patient_declined_pa_cm: 1,
	patient_declined_pa_cnm: 1,
	requested_alternate_pa_atr: 1,
	requested_pa_cm: 1,
	requested_pa_cnm: 1,
	patient_pending_pa: 1,
};
export const rejectedObj = {
	key: 'rejected',
	display_name: 'Rejected',
};
export const rejectedFields = {
	no_test_available: 1,
	rejected_other: 1,
};
export const declinedObj = {
	key: 'declined',
	display_name: 'Declined',
};
export const declinedFields = {
	ins_denied_cost: 1,
	iss_w_test_implications: 1,
	no_fu_from_patient: 1,
	declined_other: 1,
};
export const order_status_parent_options = {
	patient_not_responsive: 1,
	pre_auth_only: 1,
	results_ready: 1,
	sample_not_submitted: 1,
	sent_to_lab: 1,
	waiting_to_submit: 1,
	cancelled: 1,
	results_entered: 1,
};
export const order_status_canceled_options = {
	order_cancelled_by_patient: 1,
	order_cancelled_by_provider: 1,
	order_cancelled_by_lab: 1,
};

export const automatedApptPretest = 'automated_appt_pretest';
export const automatedPretest = 'automated_pretest';
export const automatedGCReview = 'automated_gcreview';
export const apptResults = 'appt_results';
export const apptPretest = 'appt_pretest';
export const partnerInitiatedPreTest = 'partner_initiated/pre_test';
export const riskAssessmentOncology = 'risk_assessment/oncology';
export const partnerInitiatedUnsolicitedResult =
	'partner_initiated/unsolicited_result';

export const scp_encounter_sub_types_images = {
	[automatedApptPretest]: pre_appt_icon,
	[apptPretest]: pre_appt_icon,
	[apptResults]: pre_appt_icon,
	[automatedPretest]: pre_appt_icon,
	[partnerInitiatedPreTest]: pre_appt_icon,
	[riskAssessmentOncology]: pre_appt_icon,
	[partnerInitiatedUnsolicitedResult]: pre_appt_icon,
};

export const scp_encounter_sub_types = {
	[automatedApptPretest]: 'Automated/Pre-Test - Appt',
	[apptPretest]: 'Pre-appt/Pre-Test',
	[apptResults]: 'Pre-appt/Results Review',
	[automatedPretest]: 'Automated/Pre-Test',
	[partnerInitiatedPreTest]: 'Partner-Initiated/Pre-Test',
	[riskAssessmentOncology]: 'Risk-assesment/Oncology',
	[partnerInitiatedUnsolicitedResult]: 'partner_initiated/unsolicited_result',
};

export const scp_encounter_sub_types_pre_appt = {
	[automatedApptPretest]: 1,
	[apptPretest]: 1,
	[apptResults]: 1,
	[riskAssessmentOncology]: 1,
};

export const scp_encounter_sub_types_automated_pre_test = {
	[automatedPretest]: 1,
	[automatedGCReview]: 1,
};

export const scp_encounter_sub_types_partner_initiated_pre_test = {
	[partnerInitiatedPreTest]: 1,
};

export const scp_encounter_sub_types_partner_initiated_unsolicited_result = {
	[partnerInitiatedUnsolicitedResult]: 1,
};

export const scp_encouter_allowed_test_request_status = {
	approved: 1,
	not_requested: 1,
	requested: 1,
};

export const scp_encouter_allowed_test_request_status_image = {
	approved: approved_icon,
	requested: approved_icon,
	rejected: natural_icon,
};

export const visit_to_scp_link_title = {
	[automatedApptPretest]: 'Automated',
	[apptPretest]: 'Pre-Appt',
	[apptResults]: 'Pre-Appt',
	[riskAssessmentOncology]: 'Pre-Appt',
};

export const link_visit_status_group = {
	completed: 'completed',
	booked: 'booked',
	cancelled_by_patient: 'cancelled',
	cancelled_by_provider: 'cancelled',
	cancelled_by_cc: 'cancelled',
	cancelled_rescheduled_by_provider: 'cancelled',
	cancelled_rescheduled_by_patient: 'cancelled',
	cancelled_by_care_coordinator: 'cancelled',
	cancelled: 'cancelled',
	noshow: 'cancelled',
	no_show: 'cancelled',
	reserved: 'cancelled',
};

export function getLinkedVisitEncounter(encounter) {
	const { parent_of, date_of_service } = encounter;
	if (!parent_of.length) return null;

	const lookup_priority = ['completed', 'booked', 'cancelled'];
	const group_by_status = {
		completed: [],
		booked: [],
		cancelled: [],
	};
	let found_encounter;

	// finding encounters by status and checking date >= SCP service date
	for (const encounter of parent_of) {
		if (link_visit_status_group[encounter.visit_status]) {
			const encounterDOS = moment(encounter.date_of_service);
			const scpDOS = moment(date_of_service);

			if (encounterDOS.isSameOrAfter(scpDOS)) {
				group_by_status[
					link_visit_status_group[encounter.visit_status]
				].push(encounter);
			}
		}
	}

	// iterating over groups by priority, sorting group by date and finding 1st one
	for (const priority of lookup_priority) {
		const group = group_by_status[priority];
		if (group.length && !found_encounter) {
			found_encounter = _.sortBy(group, function (encounter) {
				return new Date(encounter.date_of_service);
			})[0];
			break;
		}
	}

	return found_encounter &&
		link_visit_status_group[found_encounter.visit_status] !== 'cancelled'
		? found_encounter
		: null;
}

export const TestRecommendationEnums = [
	{ key: 'Yes', display_name: 'Yes, test(s) recommended' },
	{ key: 'PA', display_name: 'Pre-authorization' },
	{ key: 'No', display_name: 'No, a test is not recommended for patient' },
];

export const testRecommendedEnum = {
	Yes: 1,
	PA: 1,
};

export const shouldShowBothCTAs = {
	PA: 1,
};

export function isPrimaryVisit(obj) {
	return (
		_.get(obj, 'type', '') === 'visit' && _.get(obj, 'is_primary', false)
	);
}

export function isSecondaryVisit(obj) {
	return (
		_.get(obj, 'type', '') === 'visit' &&
		_.get(obj, 'is_manual', false) &&
		!_.isEmpty(_.get(obj, 'part_of', null))
	);
}

export function isSecondaryVisitForDetail(obj) {
	return (
		_.get(obj, 'type', '') === 'visit' &&
		_.get(obj, 'is_manual', false) &&
		!_.isEmpty(_.get(obj, ['part_of', 'uuid'], null))
	);
}

export function isManualVisit(obj) {
	return (
		_.get(obj, 'type', '') === 'visit' &&
		_.get(obj, 'is_manual', false) &&
		_.isEmpty(_.get(obj, 'part_of', null))
	);
}

export function isManualVisitDetail(obj) {
	return (
		_.get(obj, 'type', '') === 'visit' &&
		_.get(obj, 'is_manual', false) &&
		_.isEmpty(_.get(obj, ['part_of', 'uuid'], null))
	);
}

export function isMultipleVisit(obj) {
	return isPrimaryVisit(obj) || isSecondaryVisit(obj);
}

export function isMultipleVisitDetail(obj) {
	return isPrimaryVisit(obj) || isSecondaryVisitForDetail(obj);
}

export const imageDefault = image_default;
export const imageNeutral = natural_icon;

export function getUserImage(
	note,
	name,
	containerStyling = {},
	imageStyling = {}
) {
	let userImage = null;
	const noteName = name || _.get(note, ['author'], '');
	const userName = _.toLower(noteName.replace(' ', '_'));
	const mappedImage = providers_images[userName];

	if (userName && mappedImage) {
		userImage = <img src={mappedImage} alt='' style={imageStyling} />;
	} else if (userName !== '')
		userImage = <img src={imageDefault} alt='' style={imageStyling} />;
	else {
		userImage = null;
	}
	return (
		userImage && (
			<span className='user-imgbox' style={containerStyling}>
				{userImage}
			</span>
		)
	);
}

export function canCompleteEncounter(encounter = {}, billingEncounter = {}) {
	const hasVisitCAPCompletedDate = _.get(
		encounter,
		'initial_cap_completed_date',
		null
	);

	const postTestResultCAPTimeSpent = _.get(
		billingEncounter,
		'post_negative_minutes'
	);
	const postSessionIndirectTime = _.get(
		billingEncounter,
		'post_session_indirect_cc_minutes'
	);
	const directClinicalCareTime = _.get(
		billingEncounter,
		'direct_cc_minutes',
		null
	);
	const preSessionIndirectTime = _.get(
		billingEncounter,
		'indirect_cc_minutes',
		null
	);

	const icdCodes = _.get(encounter, 'medical_codes.length', 0);
	const visitIndications = _.get(encounter, 'visit_indications.length', 0);

	const status =
		(directClinicalCareTime || directClinicalCareTime === 0) &&
		(preSessionIndirectTime || preSessionIndirectTime === 0) &&
		(postSessionIndirectTime || postSessionIndirectTime === 0) &&
		(postTestResultCAPTimeSpent || postTestResultCAPTimeSpent === 0) &&
		icdCodes &&
		visitIndications &&
		hasVisitCAPCompletedDate;

	return status;
}
