import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay/PickersDay';
import { PickersArrowSwitcher } from '@mui/x-date-pickers/internals';
import z from 'zod';

import RadioGroupField from '../../../../Common/FormFields/RadioGroupField';

import SchedulingService from '../../../../../service/Scheduling';
import './SchedulingCalendar.scss';

const commonSchema = z.object({
	consultation_id: z.number(),
	patient_id: z.number(),
	partner_id: z.number(),
	provider_id: z.number().optional(),
	state: z.string().min(1),
	timezone: z.string().min(1),
	vsee_specialty: z.string().min(1),
});

const datesSchema = commonSchema.extend({
	month: z.date(),
});

const timesSchema = commonSchema.extend({
	date: z.date(),
});

/**
 * Hook to fetch remote resource, handling errors and manage state.
 * @param {(payload: unknown) => Promise<T>} fetchFunction data fetching
 * function
 * @returns {Array} arr
 * @returns {(payload: unknown) => Promise<Data>} fetchFunction
 * @returns {unknown | null} data - stored result of fetchFunction
 * @returns {boolean} loading - loading indicator
 * @returns {unknown | null} error - error value
 * @returns {() => void} clear - reset data
 */
function useFetchData(fetchFunction) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [data, setData] = useState(null);

	async function fetchData(payload) {
		try {
			setLoading(true);
			setError(null);
			const response = await fetchFunction(payload);
			setData(response.data.data);
			return response.data.data;
		} catch (e) {
			console.error(e);
			setError(e);
		} finally {
			setLoading(false);
		}
	}

	return [fetchData, data, loading, error, () => setData(null)];
}

export function SchedulingCalendar({
	consultationId,
	error,
	onSelect,
	partnerId,
	patientId,
	providerId,
	specialty,
	state,
	timezone = moment.tz.guess(),
	value,
}) {
	const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));
	const [currentDay, setCurrentDay] = useState(null);

	const [fetchDays, availableDays, daysLoading, daysError, clearDays] =
		useFetchData(SchedulingService.getAvailabilityDates);
	const [fetchTimes, availableTimes, timesLoading, timesError, clearTimes] =
		useFetchData(SchedulingService.getAvailabilityTimes);

	useEffect(() => {
		if (patientId) {
			const data = {
				state,
				timezone,
				vsee_specialty: specialty,
				consultation_id: consultationId,
				partner_id: partnerId,
				patient_id: patientId,
				month: currentMonth.toDate(),
			};

			if (providerId) {
				data.provider_id = providerId;
			}

			if (datesSchema.safeParse(data).success) {
				// Clear selected time if switching month
				onSelect(null);
				void fetchDays(data).then((days) => {
					// Automatically select first available day
					if (days?.[0]?.date) {
						clearTimes();
						setCurrentDay(moment(days[0].date));
					}
				});
			} else {
				clearDays();
				clearTimes();
				onSelect(null);
			}
		}

		setCurrentDay(null);
	}, [
		consultationId,
		currentMonth,
		partnerId,
		patientId,
		providerId,
		specialty,
		state,
		timezone,
	]);

	useEffect(() => {
		if (currentDay) {
			const data = {
				state,
				timezone,
				vsee_specialty: specialty,
				consultation_id: consultationId,
				partner_id: partnerId,
				patient_id: patientId,
				date: currentDay.toDate(),
			};

			if (providerId) {
				data.provider_id = providerId;
			}

			if (timesSchema.safeParse(data).success) {
				// Clear selected time if switching day
				onSelect(null);
				void fetchTimes(data);
			}
		}
	}, [currentDay]);

	let TimesComponent = <span>Select a day</span>;

	if (daysLoading) {
		TimesComponent = <span>Loading...</span>;
	} else if (daysError) {
		TimesComponent = (
			<span>
				There was an error:
				{daysError.response?.data?.data?.message ?? daysError.message}
			</span>
		);
	}

	if (currentDay) {
		if (timesLoading) {
			TimesComponent = <span>Loading...</span>;
		} else if (timesError) {
			TimesComponent = (
				<span>
					There was an error:
					{timesError.response?.data?.data?.message ??
						timesError.message}
				</span>
			);
		} else if (availableTimes && timezone) {
			if (availableTimes.length > 0) {
				TimesComponent = (
					<RadioGroupField
						className='time-options'
						options={availableTimes.map((item) => {
							const date = moment(item.start).tz(timezone);
							return {
								display_name: date.format('hh:mm A zz'),
								key: date.toISOString(),
							};
						})}
						onChange={onSelect}
						value={value}
					/>
				);
			} else {
				TimesComponent = (
					<span>
						No appointments available. Please change your filter
						criteria.
					</span>
				);
			}
		}
	}
	return (
		<div className='scheduling-calendar column-layout-2'>
			<div>
				<h3 className='title'>Day</h3>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<DateCalendar
						onMonthChange={setCurrentMonth}
						loading={daysLoading}
						dayOfWeekFormatter={(date) => date.format('dd')}
						onChange={setCurrentDay}
						value={currentDay}
						slots={{
							calendarHeader: (props) => {
								const isCurrentMonth =
									props.currentMonth.isSameOrBefore(
										moment().startOf('month')
									);
								return (
									<PickersArrowSwitcher
										className='calendar-header'
										isNextDisabled={
											daysLoading || timesLoading
										}
										isPreviousDisabled={
											daysLoading || timesLoading
										}
										isPreviousHidden={isCurrentMonth}
										onGoToNext={() =>
											props.onMonthChange(
												moment(props.currentMonth).add(
													1,
													'month'
												),
												'right'
											)
										}
										onGoToPrevious={() =>
											props.onMonthChange(
												moment(
													props.currentMonth
												).subtract(1, 'month'),
												'left'
											)
										}
									>
										<span className='month'>
											{props.currentMonth.format('MMMM')}
										</span>
									</PickersArrowSwitcher>
								);
							},
							day: (props) => {
								const hasSlot = props.daysWithTimeslot.includes(
									props.day.format('YYYY-MM-DD')
								);
								return (
									<PickersDay
										{...props}
										disabled={!hasSlot}
										data-testid={props.day.format(
											'YYYY-MM-DD'
										)}
									/>
								);
							},
						}}
						slotProps={{
							day: {
								daysWithTimeslot:
									availableDays?.map((item) => item.date) ??
									[],
							},
						}}
					/>
				</LocalizationProvider>
			</div>
			<div className='available-times'>
				<div className='container'>
					<h3 className='title'>Time</h3>
					{error && <div className='error'>{error}</div>}
					{TimesComponent}
				</div>
			</div>
		</div>
	);
}
