import { isValidPhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import z from 'zod';

import { getencounters } from '../../../../../actions/encounter';
import { useCurrentUser } from '../../../../../hooks/useCurrentUser';
import FormField from '../../../../Common/FormFields/FormField';
import { Section } from '@gm/common/ui';
import { useSection } from '../../../../Patients/V3/CreatePatient/sections/useSection';

import { AppointmentSummary } from '../components/AppointmentSummary';
import { SchedulingCalendar } from '../components/SchedulingCalendar';
import { ServiceInfoForm } from '../components/ServiceInfoForm';

import './ServiceInfoAndScheduling.scss';

export const initialServiceInfoAndScheduling = {
	country: 'US',
	state: '',
	timezone: '',
	consultationType: '',
	specialty: '',
	provider: null,
	additionalConferenceLine: '',
	timeslot: null,
};

export const schemaServiceInfoAndScheduling = z.object({
	country: z.string().min(1, { message: 'Country is required' }),
	state: z.string().min(1, { message: 'State is required' }),
	timezone: z.string().min(1, { message: 'Timezone is required' }),
	consultationType: z
		.number()
		.min(1, { message: 'Consultation Type is required' }),
	specialty: z.string().min(1, { message: 'Specialty is required' }),
	provider: z.number().nullable(),
	additionalConferenceLine: z
		.string()
		.refine(
			(v) => (v ? isValidPhoneNumber(v) : true),
			'Additional Conference Line is invalid'
		),
	timeslot: z
		.string({ invalid_type_error: 'Time slot is required' })
		.datetime({ message: 'Time slot is required' }),
});

export function ServiceInfoAndScheduling({
	sectionDataContainerName,
	forceShowAppointmentSummary,
	appointmentSummaryLabel,
	appointmentSummaryEncounterUUID,
}) {
	const { allValues, onChange, values, errors, touched } = useSection(
		sectionDataContainerName
	);
	const { isExternal } = useCurrentUser();

	const dispatch = useDispatch();
	useEffect(() => {
		if (allValues.patientInfo.patientUUID) {
			dispatch(
				getencounters({
					id: allValues.patientInfo.patientUUID,
					offset: 0,
					limit: 20,
				})
			);
		}
	}, [allValues.patientInfo.patientUUID]);

	const encounterList = useSelector(
		(store) => store.encounterlist.encounter?.data ?? []
	);
	// If appointmentSummaryEncounterUUID is not specified, fetch latest
	// completed appointment for the selected patient
	const [lastEncounterUUID, setLastEncounterUUID] = useState();
	useEffect(() => {
		async function doFetch() {
			if (!appointmentSummaryEncounterUUID) {
				if (allValues.patientInfo.patientUUID) {
					setLastEncounterUUID(
						encounterList.find(
							(item) => item.visit_status === 'completed'
						)?.uuid
					);
				} else {
					setLastEncounterUUID();
				}
			}
		}
		void doFetch();
	}, [
		appointmentSummaryEncounterUUID,
		allValues.patientInfo.patientUUID,
		encounterList,
	]);

	return (
		<Section title='Service Info & Scheduling'>
			<ServiceInfoForm
				sectionDataContainerName={sectionDataContainerName}
				appointmentSummaryLabel='Current Appointment'
				isReschedule={forceShowAppointmentSummary}
			/>
			{(forceShowAppointmentSummary || !isExternal) &&
				(appointmentSummaryEncounterUUID || lastEncounterUUID) && (
					<FormField
						className='appointment-summary-field'
						label={appointmentSummaryLabel}
					>
						<AppointmentSummary
							encounterUUID={
								appointmentSummaryEncounterUUID ||
								lastEncounterUUID
							}
							showProvider={!isExternal}
						/>
					</FormField>
				)}
			<SchedulingCalendar
				partnerId={allValues.patientInfo.associatedProgram}
				patientId={allValues.patientInfo.patientId}
				providerId={values.provider}
				state={values.state}
				timezone={values.timezone}
				specialty={values.specialty}
				consultationId={values.consultationType}
				onSelect={onChange('timeslot')}
				value={values.timeslot}
				error={touched.timeslot && errors.timeslot}
			/>
		</Section>
	);
}
