//React & Redux
import React from 'react';

//Components
import TagSearch from './TagSearch.js';
import TagSelected from './TagSelected.js';

const TagSelection = (props) => {
	const {
		buttonText,
		canSaveEmpty = false,
		func,
		handleSearchQueryChange,
		icdCodes = [],
		scrollHandler,
		searchQuery,
		selectAll,
		selectedICDCodes = [],
		selectNone,
		setBodyElement,
		shouldHaveBottomDropShadow = false,
		shouldHaveTopDropShadow = false,
		toggleCodeSelection,
	} = props;

	const canAdvance = () => {
		return selectedICDCodes.length !== 0 || canSaveEmpty;
	};

	const advanceStage = () => {
		if (canAdvance()) {
			func(selectedICDCodes);
		}
	};

	const getButtonText = () => {
		return buttonText || 'Save';
	};

	const buttonClass = canAdvance()
		? 'chargeModalActionButton'
		: 'chargeModalActionButton chargeModalActionButtonDisabled';
	const containerClass = shouldHaveBottomDropShadow
		? 'chargeModalICDButtonRow scrollableModalDropShadowBottom'
		: 'chargeModalICDButtonRow';
	return (
		<div className='chargeICDContainer'>
			<div className='chargeICDSearchAndSelectContainer'>
				<TagSearch
					{...{
						searchQuery,
						handleSearchQueryChange,
						icdCodes,
						selectedICDCodes,
						toggleCodeSelection,
						selectAll,
						selectNone,
						setBodyElement,
						scrollHandler,
						shouldHaveTopDropShadow,
					}}
				/>
				<TagSelected
					{...{
						selectedICDCodes,
						toggleCodeSelection,
					}}
				/>
			</div>
			<div className={containerClass}>
				<div className={buttonClass} onClick={advanceStage}>
					{getButtonText()}
				</div>
			</div>
		</div>
	);
};

export default TagSelection;
