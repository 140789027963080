//React & Redux
import { connect } from 'react-redux';

//Lodash
import _ from 'lodash';

const roles = {
	hidden: false,
	'read-only': true,
	'full-access': true,
};

const actionableroles = {
	hidden: false,
	'read-only': false,
	'full-access': true,
};

const checkroles = (myprops, action, actionable) => {
	const useractions = _.get(myprops, ['permissions']);
	const userroles = actionable ? actionableroles : roles;
	const granular_action =
		useractions && action ? useractions[action] : undefined;
	return _.get(userroles, granular_action, false);
};

const RBAC = (props) =>
	checkroles(props.me, props.action, props.actionable) ? props.yes : props.no;

RBAC.defaultProps = {
	yes: null,
	no: null,
};

export { RBAC as CORERBAC };
const mapStateToProps = (state, props) => ({
	me: state.me,
});
export default connect(mapStateToProps)(RBAC);
