//React & Redux
import React, { Fragment, useState, useEffect } from 'react';

//Images
import moreinfoicon from '../../../../../assets/purple-more-info.svg';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Select } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';

//Hooks
import { usePrevious } from '../../../../../hooks/usePrevious';

//Components
import Tooltip from '../../../../Common/Tooltip';

export const EncounterSelectField = ({
	name,
	value,
	onChange,
	placeholder,
	trim,
	extraclassName,
	disabled,
	error,
	options,
	istouched = true,
	typeahead = false,
	clearable = false,
}) => {
	const iserror = istouched && error && !_.isNil(error) && !_.isEmpty(error);
	const currentClassName = classnames(
		iserror ? 'encounter-input-error-field' : 'encounter-input-field',
		extraclassName
	);
	const [optionsEnum, setOptionsEnum] = useState(options);
	const prevOption = usePrevious(options);

	useEffect(() => {
		if (options != prevOption) {
			setOptionsEnum(options);
		}
	}, [options]);

	return (
		<Fragment>
			<div className={currentClassName}>
				<Select
					value={value}
					name={name}
					disabled={disabled}
					onChange={onChange}
					placeholder={placeholder}
					className={
						iserror
							? 'encounterFieldDropdown inputFieldDropdown inputFieldDropdownError'
							: 'encounterFieldDropdown inputFieldDropdown'
					}
					filterable={typeahead}
					clearable={clearable}
					filterMethod={(value) => {
						if (!_.isNil(typeahead) && typeahead) {
							if (_.isEmpty(value) || _.isNil(value)) {
								setOptionsEnum(options);
							} else {
								const _options = options.filter(
									(o) =>
										o.label &&
										o.label
											.toLowerCase()
											.startsWith(value.toLowerCase())
								);
								setOptionsEnum(_options);
							}
						}
					}}
				>
					{(_.isArray(optionsEnum) ? optionsEnum : []).map((el) => {
						if (_.get(el, ['tooltip'], false)) {
							return (
								<Select.Option
									key={el.value}
									value={el.value}
									disabled={_.get(el, ['isDisable'], false)}
								>
									{el.label}
									<Tooltip
										effect='dark'
										content={_.get(el, ['tooltip'], '')}
										placement='left'
									>
										<img src={moreinfoicon} />
									</Tooltip>
								</Select.Option>
							);
						} else {
							return (
								<Select.Option
									key={el.value}
									label={el.label}
									value={el.value}
								/>
							);
						}
					})}
				</Select>
			</div>
			<div
				className='inputFieldError'
				style={{ top: 'unset', left: 'unset' }}
			>
				{iserror ? error : ''}
			</div>
		</Fragment>
	);
};
