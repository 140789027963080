import { useSelector } from 'react-redux';
import { isExternalUser } from '../utils';

/**
 * Provides info about current user
 * @returns {{ isExternal: boolean }} user info
 */
export function useCurrentUser() {
	const me = useSelector((state) => state.me);

	const activeRole = me?.activerole?.name;
	const isExternal = isExternalUser(activeRole);

	return { isExternal };
}
