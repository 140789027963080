//React & Redux
import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//Action & Services
import { getspecificencounterenum } from '../../actions/dashboard';
import {
	getencountervisitsschema,
	getVisitConsulationTypesEnums,
	cancelEncounter,
	getPayorEnums,
} from '../../actions/encounter';
import { getAppointmentReferralPrograms } from '../../actions/patients';
import { LoadAppointments, ChangeModality } from '../../actions/appointments';
import { getProvidersList } from '../../actions/providers';
import { setFilter } from '../../actions/appData';

//Lodash
import get from 'lodash/get';
import _ from 'lodash';
import moment from 'moment';

//Components
import AppointmentList from './AppointmentList.js';
import AppointmentFilter from './AppointmentFilter.js';
import ScheduleAppointment from './ScheduleAppointment.js';

//Hooks
import { usePrevious } from '../../hooks/usePrevious';
import { useDebounce } from '../../hooks/useDebounce';

const less_filter = 'Less filters';
const more_filter = 'More filters';

const defaultfilterstate = {
	offset: 0,
	limit: 20,
	referral_program: '',
	visit_provider: [],
	visit_provider_uuid: [],
	visit_status: ['booked'],
	consultation_type: '',
	tm_platform: '',
	q: '',
	patient_athena_package: false,
	toggleFilter: more_filter,
};

const AppointmentListPage = (props) => {
	const {
		AppointmentFilterProps = {},
		AppointmentListProps,
		enqueueSnackbar,
		GetVisitProviderEnums,
		getAppointments,
		getConsulationTypesEnums,
		_getPayorEnums,
		getAllProviders,
		getRefPrograms,
		getVisitSchema,
		hideScheduleAppointment,
		history = [],
		setAppointmentFilter,
		scheduleAppointment,
		showScheduleAppointment,
	} = props;

	const [filter, setFilter] = useState({
		...defaultfilterstate,
		...(get(AppointmentFilterProps, 'appointmentFilter', null) || {}),
	});

	const previousAppointmentListProps = usePrevious(AppointmentListProps);
	const debouncedQuery = useDebounce(filter, 500);

	useEffect(() => {
		GetVisitProviderEnums();
		getRefPrograms();
		getVisitSchema();
		getConsulationTypesEnums();
		getAppointmentsFunc();
		getAllProviders();
	}, []);

	useEffect(() => {
		if (
			get(previousAppointmentListProps, 'loading', false) &&
			!get(AppointmentListProps, 'loading', false)
		) {
			if (!get(AppointmentListProps, 'error')) {
				setAppointmentFilter(filter);
			}
		}
	}, [AppointmentListProps]);

	useEffect(() => {
		getAppointmentsFunc();
	}, [debouncedQuery]);

	const getAppointmentsFunc = _.debounce(() => {
		getAppointments({ ...filter });
	}, 500);

	const toggleFilterFunc = () => {
		const toggleFilter =
			filter.toggleFilter === more_filter ? less_filter : more_filter;
		const _filter = { ...filter, toggleFilter };

		setFilter(_filter);
		if (toggleFilter === more_filter) {
			updateFilter('referral_program', '');
			setTimeout(() => {
				updateFilter('tm_platform', '');
			}, 100);
			setTimeout(() => {
				updateFilter('vsee_specialty', '');
			}, 100);
			setTimeout(() => {
				updateFilter('patient_athena_package', false);
			}, 100);
		} else {
			setTimeout(() => {
				updateFilter('q', '');
			}, 100);
		}
	};

	const updateFilter = (name, value) => {
		const updatedfilter = { ...filter };
		if (name != 'offset')
			updatedfilter['offset'] = defaultfilterstate.offset;
		updatedfilter[name] = value;
		setFilter(updatedfilter);
	};

	const updateFilterBulk = (filters = {}) => {
		setFilter((prevFilter) => ({
			...prevFilter,
			offset: defaultfilterstate.offset,
			...filters,
		}));
	};

	const handleResetFilters = () => {
		setFilter(defaultfilterstate);
	};

	const navigateToRescheduleAppointmentPage = (encounter_uuid) => {
		history.push({
			pathname: '/app/appointments/reschedule/' + encounter_uuid,
			state: {
				breadcrumbs: [
					{
						location: 'Appointments',
						url: '/app/appointments',
					},
					{
						location: 'Reschedule Appointment',
					},
				],
			},
		});
	};

	const navigateToPatientDetailPymntTab = (patient_uuid, fullName) => {
		const patientuuid = (patient_uuid && patient_uuid) || '0';
		const url = `/app/patientdetail/${patientuuid}/5/0/`;

		history.push({
			pathname: url,
			state: {
				breadcrumbs: [
					{
						location: 'AppointmentsToPatientDetail',
						url: '/app/appointments',
						filter: get(this, 'state.filter'),
					},
					{
						location: 'Patient Detail',
						patientFullName: fullName,
					},
				],
			},
		});
	};

	const NavigateToEncounterDetail = (
		patient_uuid,
		encounter_uuid,
		fullName
	) => {
		if (patient_uuid && encounter_uuid) {
			const pathname = `/app/patientdetail/${patient_uuid}/0/2/${encounter_uuid}/`;
			history.push({
				pathname,
				state: {
					breadcrumbs: [
						{
							location: 'Appointments',
							url: '/app/appointments',
							filter,
						},
						{
							location: 'Patient Detail',
							patientFullName: fullName,
						},
					],
				},
			});
		}
	};

	return scheduleAppointment ? (
		<ScheduleAppointment
			{...AppointmentFilterProps}
			{...AppointmentListProps}
			hideScheduleAppointment={hideScheduleAppointment}
		/>
	) : (
		<>
			<AppointmentFilter
				{...AppointmentFilterProps}
				filter={{ ...filter }}
				changefilter={updateFilter}
				changeFilterBulk={updateFilterBulk}
				toggleFilter={toggleFilterFunc}
				defaultFilter={defaultfilterstate}
				showScheduleAppointment={showScheduleAppointment}
				handleResetFilters={handleResetFilters}
			/>
			<AppointmentList
				{...AppointmentListProps}
				filter={{ ...filter }}
				changefilter={updateFilter}
				getAppointments={() => getAppointmentsFunc()}
				getPayorEnums={_getPayorEnums}
				scheduleAppointment={scheduleAppointment}
				enqueueSnackbar={enqueueSnackbar}
				navigateToRescheduleAppointmentPage={
					navigateToRescheduleAppointmentPage
				}
				navigateToPatientDetailPymntTab={
					navigateToPatientDetailPymntTab
				}
				NavigateToEncounterDetail={NavigateToEncounterDetail}
			/>
		</>
	);
};

AppointmentListPage.propTypes = {
	history: PropTypes.any.isRequired,
};

const GroupAppointment = (appointments) => {
	const appoints =
		appointments && _.isArray(appointments) ? appointments : [];
	const grouped = _.groupBy(appoints, (app) => {
		const dos = moment(app.date_of_service);
		const dosmoment = moment.tz(dos.format('YYYY-MM-DD HH:mm:ss'), 'UTC');
		return moment.tz(dosmoment, app.timezone).format('YYYY-MM-DD');
	});
	const sortedgrouped = _.sortBy(_.keys(grouped), [
		function (key) {
			return moment(key);
		},
	]);
	const grouparray = _.map(sortedgrouped, (key) => {
		return { key: key, data: grouped[key] };
	});
	return grouparray;
};

const mapStateToProps = (state, props) => {
	const { history, scheduleAppointment, enqueueSnackbar } = props;
	const {
		dashboardencounters,
		getAppointmentReferralPrograms,
		visitsschema,
		visitconsultationtypes,
		appointments,
		providers,
	} = state;
	const visit_schema = _.get(
		visitsschema,
		['schema', 'data', 'vsee_specialty'],
		[]
	);
	const vsee_sepcialty_filter_values = _.filter(visit_schema, function (o) {
		return o.key !== 'no_default' && !o.key.includes('_willing');
	});
	const visitProviders = _.get(
		dashboardencounters,
		['visitproviderenum_scheduling', 'data', 'visit_provider'],
		[]
	);
	const visitProvidersLoading = _.get(
		dashboardencounters,
		'visitproviderenumloading_scheduling',
		false
	);
	const referralProviders = _.get(
		getAppointmentReferralPrograms,
		['appointmentReferralPrograms', 'data'],
		[]
	);
	const appointmentStatus = _.get(
		visitsschema,
		['schema', 'data', 'visit_status'],
		[]
	);
	const vsee_specialty = vsee_sepcialty_filter_values;
	const consultationTypes = _.get(
		visitconsultationtypes,
		['VisitConsultationTypes', 'data'],
		[]
	);
	const modalities = _.get(
		visitsschema,
		['schema', 'data', 'tm_platform'],
		[]
	);
	const LoadingAppointments = _.get(appointments, 'loading');
	const LoadedAppointments = _.get(appointments, 'loaded');
	const ErrorAppointments = _.get(appointments, 'error');
	const appointmentData = _.get(appointments, ['data', 'data'], []);
	const appointmentlimit = _.get(
		appointments,
		['data', 'limit'],
		defaultfilterstate.limit
	);
	const appoinmentoffset = _.get(
		appointments,
		['data', 'offset'],
		defaultfilterstate.offset
	);
	const appoinmenttotal = _.get(
		appointments,
		['data', 'total'],
		defaultfilterstate.total
	);
	const appointmentFilter = _.get(state, 'appData.appointments', null) || {};
	const payorEnums = state?.payorEnum?.payorEnum?.payor || [];

	const switchingEncounterModalityError = _.get(
		appointments,
		'switchingEncounterModalityError',
		false
	);
	const switchingEncounterModality = _.get(
		appointments,
		'switchingEncounterModality',
		false
	);
	const switchedEncounterModality = _.get(
		appointments,
		'switchedEncounterModality',
		''
	);

	const encounterCancelLoading = state.editencounter.encounterCancelLoading;

	const appointmentPagination = {
		limit: appointmentlimit,
		offset: appoinmentoffset,
		total: appoinmenttotal,
	};

	const gmi_visit_providers = _.get(providers, ['data'], []);
	const gmi_visit_providers_loading = _.get(providers, ['loading'], []);

	const AppointmentListStateProps = {
		enqueueSnackbar,
		history,
		modalities,
		loading: LoadingAppointments,
		loaded: LoadedAppointments,
		error: ErrorAppointments,
		appointments: GroupAppointment(appointmentData),
		visitProviders,
		visitProvidersLoading,
		appointmentStatus,
		referralProviders,
		appointmentPagination,
		encounterCancelLoading,
		switchingEncounterModalityError: switchingEncounterModalityError,
		switchingEncounterModality: switchingEncounterModality,
		switchedEncounterModality: switchedEncounterModality,
		payorEnums,
	};

	const AppointmentFilterStateProps = {
		history,
		gmi_visit_providers,
		gmi_visit_providers_loading,
		referralProviders,
		appointmentStatus,
		modalities,
		vsee_specialty,
		consultationTypes,
		appointmentFilter,
	};

	return {
		AppointmentListStateProps,
		AppointmentFilterStateProps,
		history,
		enqueueSnackbar,
		showScheduleAppointment: props.showScheduleAppointment,
		hideScheduleAppointment: props.hideScheduleAppointment,
		scheduleAppointment,
	};
};

const mergeProps = (stateProps, dispatchProps) => {
	const { dispatch } = dispatchProps;
	const {
		AppointmentListStateProps,
		AppointmentFilterStateProps,
		history,
		enqueueSnackbar,
		showScheduleAppointment,
		hideScheduleAppointment,
		scheduleAppointment,
	} = stateProps;

	const GetVisitProviderEnums = () =>
		dispatch(
			getspecificencounterenum({
				type: 'visit',
				field_name: 'visit_provider',
				component: 'scheduling',
			})
		);
	const getRefPrograms = () => dispatch(getAppointmentReferralPrograms());
	const getVisitSchema = () => dispatch(getencountervisitsschema());
	const getConsulationTypesEnums = () =>
		dispatch(getVisitConsulationTypesEnums(100, 1, false));
	const getAppointments = (data) => dispatch(LoadAppointments(data));
	const _getPayorEnums = () => dispatch(getPayorEnums());
	const getAllProviders = () =>
		dispatch(
			getProvidersList({ pioneer_only: true, order_by: 'first_name' })
		);
	const appointmentCancel = (uuid, notify_patient) =>
		dispatch(cancelEncounter({ uuid, status: 'cancel', notify_patient }));

	const appointmentNoShow = (uuid) =>
		dispatch(cancelEncounter({ uuid, status: 'no_show' }));
	const switchModality = (encounter_uuid, type) =>
		dispatch(ChangeModality(encounter_uuid, type));
	const setAppointmentFilter = (data) =>
		dispatch(setFilter('appointments', data));

	const AppointmentListProps = {
		...AppointmentListStateProps,
		appointmentCancel,
		appointmentNoShow,
		switchModality,
	};

	const AppointmentFilterProps = {
		...AppointmentFilterStateProps,
	};

	return {
		AppointmentListProps,
		AppointmentFilterProps,
		GetVisitProviderEnums,
		getRefPrograms,
		getVisitSchema,
		getConsulationTypesEnums,
		history,
		enqueueSnackbar,
		showScheduleAppointment,
		hideScheduleAppointment,
		scheduleAppointment,
		getAppointments,
		_getPayorEnums,
		getAllProviders,
		setAppointmentFilter,
	};
};

export default connect(mapStateToProps, null, mergeProps)(AppointmentListPage);
