//React & Redux
import React, { useEffect } from 'react';

//Images
import ReactSVG from 'react-svg';
import docPrint from '../../../../assets/inline-print.svg';
import docDownload from '../../../../assets/inline-download.svg';
import archiveicon from '../../../../assets/inline-archive.svg';
import leftArrow from '../../../../assets/chevron-large-left.svg';
import rotateLeft from '../../../../assets/rotate_left.svg';
import rotateRight from '../../../../assets/rotate_right.svg';
import plusIcon from '../../../../assets/plus.svg';
import minusIcon from '../../../../assets/minus.svg';

//Utils
import { CARE_PATIENTS_PATIENT_DOCUMENT_ARCHIVE } from '../../../../Utils/permissionUtils';
import * as Utils from './DocumentInlineView';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import RBAC from '../../../RBAC';
import Tooltip from '../../../Common/Tooltip';

const HALF_HEIGHT = '50vh';
const HALF_WIDTH = '50vw';

export const changeImageSize = (flag) => {
	const el =
		window.frames['inlineDocFrame'].document.querySelector('body')
			.children[0];
	el.width = flag ? el.width + 100 : el.width < 100 ? 0 : el.width - 100;
	const diff =
		el.getBoundingClientRect().bottom - el.getBoundingClientRect().top;
	const offset = Math.abs(diff) / 2;
	const horizontalDiff =
		el.getBoundingClientRect().right - el.getBoundingClientRect().left;
	const horizontalOffset = Math.abs(horizontalDiff) / 2;
	const currentRotation = el.style.transform;
	el.style.position = 'absolute';
	if (!currentRotation || !currentRotation.match(/^rotate\((-?\d+)deg\)$/)) {
		el.style.top = `calc(${HALF_HEIGHT} - ${offset}px)`;
		el.style.left = `calc(${HALF_WIDTH} - ${horizontalOffset}px)`;
	} else {
		const currentDeg = currentRotation.match(/^rotate\((-?\d+)deg\)$/);
		if (Math.abs(Number(currentDeg[1]) % 180) !== 0) {
			el.style.top = `calc(${HALF_HEIGHT} - ${el.height / 2}px)`;
			el.style.left = `calc(${HALF_WIDTH} - ${el.width / 2}px)`;
		} else {
			el.style.top = `calc(${HALF_HEIGHT} - ${offset}px)`;
			el.style.left = `calc(${HALF_WIDTH} - ${horizontalOffset}px)`;
		}
	}

	if (el.style.maxWidth !== 'unset') el.style.maxWidth = 'unset';
	if (el.style.maxHeight !== 'unset') el.style.maxHeight = 'unset';
};

const DocumentInlineView = (props) => {
	const {
		archiveFile,
		archivingFile,
		docDetail,
		documentActionLoading,
		downloadDocument,
		getInlineDocumentDetail,
		inlineDocDetail,
		inlineDocDetailError,
		inlineDocDetailLoading,
		navigateToDocumentList,
		patientUuid,
		printDocument,
	} = props;

	useEffect(() => {
		getInlineDocumentDetail(docDetail, 'read', patientUuid);
	}, []);

	const styleFrame = () => {
		try {
			const style = document.createElement('style');
			style.innerHTML = `
        body > * {
          margin: auto;
          display: block;
          overflow: auto;
        }

        body {
          display: flex;
        }
      `;
			window.frames['inlineDocFrame'].document.head.appendChild(style);
			const el =
				window.frames['inlineDocFrame'].document.querySelector('body')
					.children[0];
			if (el.tagName.toLowerCase() === 'img') {
				el.style.maxWidth = '95vw';
				el.style.maxHeight = '75vh';
				el.style.transform = 'rotate(0deg)';
			}
		} catch (err) {
			return;
		}
	};

	const rotateImage = (direction) => {
		const el =
			window.frames['inlineDocFrame'].document.querySelector('body')
				.children[0] || {};
		const currentRotation = el?.style?.transform;
		if (!el.style) el.style = {};
		if (!currentRotation) el.style.transform = 'rotate(0deg)';
		else {
			const currentDeg = currentRotation.match(/^rotate\((-?\d+)deg\)$/);
			if (currentDeg) {
				el.style.transform = `rotate(${
					direction === 'ccw'
						? Number(currentDeg[1]) - 90
						: Number(currentDeg[1]) + 90
				}deg)`;
			} else {
				el.style.transform = 'rotate(0deg)';
			}
		}
	};

	const canBeArchived = (obj) => obj?.can_be_archived || false;
	const isLoading =
		inlineDocDetailLoading ||
		inlineDocDetail === undefined ||
		documentActionLoading ||
		archivingFile ||
		inlineDocDetailError;

	return isLoading ? (
		<div className='inlineDocumentViewContainer'>
			<Loading loading={true} style={{ top: '45vh' }} />
		</div>
	) : (
		<div className='inlineDocumentViewContainer'>
			<div className='inlineDocumentViewTopRow'>
				<div className='inlineDocumentViewTitleArrow'>
					<ReactSVG
						src={leftArrow}
						onClick={navigateToDocumentList}
						style={{ cursor: 'pointer' }}
					/>
					<div
						style={{
							position: 'relative',
							top: '5px',
							left: '7px',
						}}
					>
						{docDetail?.file_name || '--'}
					</div>
				</div>
				<div className='inlineDocumentViewButtons'>
					{(inlineDocDetail?.type || '').match(/image/gi) && (
						<>
							<Tooltip
								effect='dark'
								content={'Increase size'}
								placement='bottom'
								className='inlineDocumentTooltip'
							>
								<ReactSVG
									data-testId='change-img-size'
									className='inlineDocumentViewButton'
									src={plusIcon}
									onClick={() => Utils.changeImageSize(true)}
								/>
							</Tooltip>

							<div style={{ width: '12px' }} />

							<Tooltip
								effect='dark'
								content={'Decrease size'}
								placement='bottom'
								className='inlineDocumentTooltip'
							>
								<ReactSVG
									data-testId='change-img-size'
									className='inlineDocumentViewButton'
									src={minusIcon}
									onClick={() => Utils.changeImageSize(false)}
								/>
							</Tooltip>

							<div>
								<div
									className='inlineDocumentViewSeparator'
									style={{ marginRight: '16px' }}
								/>
							</div>

							<Tooltip
								effect='dark'
								content={'Rotate counterclockwise'}
								placement='bottom'
								className='inlineDocumentTooltip'
							>
								<ReactSVG
									data-testId='rotate-img'
									className='inlineDocumentViewButton'
									src={rotateLeft}
									onClick={() => rotateImage('ccw')}
								/>
							</Tooltip>

							<div style={{ width: '12px' }} />

							<Tooltip
								effect='dark'
								content={'Rotate clockwise'}
								placement='bottom'
								className='inlineDocumentTooltip'
							>
								<ReactSVG
									data-testId='rotate-img'
									className='inlineDocumentViewButton'
									src={rotateRight}
									onClick={() => rotateImage('cw')}
								/>
							</Tooltip>

							<div>
								<div
									className='inlineDocumentViewSeparator'
									style={{ marginRight: '20px' }}
								/>
							</div>
						</>
					)}

					<Tooltip
						effect='dark'
						content={'Print'}
						placement='bottom'
						style={{ top: '5px' }}
						className='inlineDocumentTooltip'
					>
						<ReactSVG
							data-testId='print-doc'
							className='inlineDocumentViewButton'
							src={docPrint}
							style={{ transform: 'scale(1.65)' }}
							onClick={(e) => printDocument(e, docDetail)}
						/>
					</Tooltip>

					<div style={{ width: '12px' }} />

					<Tooltip
						effect='dark'
						content={'Download'}
						placement='bottom'
						style={{ top: '3px' }}
						className='inlineDocumentTooltip'
					>
						<ReactSVG
							data-testId='download-doc'
							className='inlineDocumentViewButton'
							src={docDownload}
							style={{ transform: 'scale(1.65)' }}
							onClick={(e) => downloadDocument(e, docDetail)}
						/>
					</Tooltip>

					{canBeArchived(docDetail) && (
						<RBAC
							action={CARE_PATIENTS_PATIENT_DOCUMENT_ARCHIVE}
							yes={
								<div>
									<div
										className='inlineDocumentViewSeparator'
										style={{ margin: '0px 20px' }}
									/>
								</div>
							}
						/>
					)}

					{canBeArchived(docDetail) && (
						<RBAC
							action={CARE_PATIENTS_PATIENT_DOCUMENT_ARCHIVE}
							yes={
								<Tooltip
									effect='dark'
									content={'Archive'}
									placement='bottom'
									style={{ top: '4px' }}
									className='inlineDocumentTooltip'
								>
									<ReactSVG
										data-testId='archive-doc'
										className='inlineDocumentViewButton'
										src={archiveicon}
										style={{ transform: 'scale(1.65)' }}
										onClick={(e) =>
											archiveFile(e, docDetail)
										}
									/>
								</Tooltip>
							}
						/>
					)}
				</div>
			</div>
			<div className='inlineDocumentViewGradientRow' />
			<div className='inlineDocumentViewDocumentContainer'>
				<iframe
					className='inlineDocumentViewDocument'
					src={window.URL.createObjectURL(inlineDocDetail)}
					name='inlineDocFrame'
					onLoad={styleFrame}
				/>
			</div>
		</div>
	);
};

export default DocumentInlineView;
