//React & Redux
import React, { useEffect, useState } from 'react';

//Images
import ReactSVG from 'react-svg';
import editpencil from './../../assets/edit.svg';
import checkIcon from './../../assets/check.svg';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Input, Loading, Button } from 'gm-element-react';
import Iframe from 'react-iframe';

//Styles
import './../ReferralPrograms/css/view-template.css';

const TemplateDetail = (props) => {
	const {
		closeTemplateDetail,
		enqueueSnackbar,
		getSharedDocumentDetail,
		getTemplateFromUuid,
		getTemplateOwnerUuids,
		loadedDocumentDetail,
		loadedDocumentDetailError,
		loadingDocumentDetail,
		openArchiveModal,
		openFinalizeDraftModal,
		openReferralProgramEditModal,
		referralPrograms,
		updateSharedDocument,
		selectedTemplates,
		updatedTemplateError = false,
		updatingTemplate = false,
	} = props;

	const isView = loadedDocumentDetail?.isView || true;

	const [fileName, setFileName] = useState('');
	const [loading, setLoading] = useState(true);
	const [showTitleTextbox, setShowTitleTextbox] = useState(false);
	const [documentUuid, setDocumentUuid] = useState();
	const [partnerUuid, setPartnerUuid] = useState();

	useEffect(() => {
		const setUuids = async () => {
			await setDocumentAndPartnerUuid();
		};

		setUuids();
	}, []);

	useEffect(() => {
		documentUuid &&
			getSharedDocumentDetail({
				document_uuid: documentUuid,
			});
	}, [documentUuid]);

	useEffect(() => {
		if (!loadingDocumentDetail) {
			if (loadedDocumentDetailError) {
				setSnackbarError();
			} else {
				setLoading(false);
				setFileName(loadedDocumentDetail?.fileName || '');
			}
		}
	}, [loadingDocumentDetail]);

	useEffect(() => {
		if (!updatingTemplate) {
			if (updatedTemplateError) {
				enqueueSnackbar('Error in updating template', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				if (loading) {
					setShowTitleTextbox(false);
					documentUuid &&
						getSharedDocumentDetail({
							document_uuid: documentUuid,
						});
				}
			}
		}
	}, [updatingTemplate]);

	const setDocumentAndPartnerUuid = async () => {
		const templateUuid = selectedTemplates[0] || null;
		let partnerUuid;
		const template = getTemplateFromUuid(templateUuid);

		if (templateUuid && template) {
			const documentUuid = template?.uuid || null;
			const templateOwnerUuids = getTemplateOwnerUuids(template);

			if (templateOwnerUuids === 'All') {
				partnerUuid = referralPrograms[0]?.uuid || null;
			} else {
				partnerUuid = templateOwnerUuids[0] || null;
			}

			setDocumentUuid(documentUuid);
			setPartnerUuid(partnerUuid);
		} else {
			enqueueSnackbar('Error in loading template', {
				variant: 'error',
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'bottom',
				},
			});
		}
	};

	const toggleTitleTextbox = () => {
		setShowTitleTextbox(!showTitleTextbox);
	};

	const handleTitle = (value) => {
		setFileName(_.trimStart(value));
	};

	const saveFileName = (fileName) => {
		setLoading(true);
		updateSharedDocument(documentUuid, {
			file_name: _.trim(fileName),
		});
	};

	const determineCanFinalize = () => {
		const templateUuid = selectedTemplates[0] || null;
		const template = getTemplateFromUuid(templateUuid);
		return templateUuid && template
			? (template.document_state?.name || '') !== 'active'
			: false;
	};

	return loadedDocumentDetail && !loading ? (
		<>
			<main
				className='ref-programs-details-container'
				style={{ margin: 'unset' }}
			>
				<section className='view-template-section view-template-head'>
					<div
						className='referral-program-navigation'
						onClick={() => closeTemplateDetail(true)}
						style={{ color: '#7b26c9' }}
					>
						<i className='material-icons'>chevron_left</i>
						Templates
					</div>
					<div className='template-detail-toolbar'>
						<div className='template-detail-toolbar-left'>
							<Loading {...{ loading }}>
								<div className='template-detail-template-name'>
									{showTitleTextbox ? (
										<Input
											value={fileName}
											placeholder='Enter title'
											onChange={handleTitle}
											className='txt-tmplt-name'
										/>
									) : (
										loadedDocumentDetail.fileName || ''
									)}
									{!showTitleTextbox ? (
										<ReactSVG
											onClick={toggleTitleTextbox}
											src={editpencil}
											alt='edit-icon'
											className='template-edit-icon'
											style={{
												cursor: isView
													? 'pointer'
													: 'default',
											}}
										/>
									) : !_.isNil(fileName) &&
									  !_.isEmpty(_.trim(fileName)) ? (
										<Button
											type='text'
											onClick={() =>
												saveFileName(fileName)
											}
											className='btn-save-tmplt-name'
										>
											Save
										</Button>
									) : null}
								</div>
							</Loading>
						</div>
						<div className='template-detail-toolbar-right'>
							<div className='template-action-row'>
								<div
									className='confirmationModalButtonRowCancel'
									onClick={() => openArchiveModal(null, true)}
									style={{
										marginRight: '16px',
										width: '71px',
									}}
								>
									Archive
								</div>
								<div
									className='confirmationModalButtonRowCancel'
									onClick={() =>
										openReferralProgramEditModal(null, true)
									}
									style={{
										marginRight: '16px',
										width: '166px',
									}}
								>
									Edit Referral Programs
								</div>
								{determineCanFinalize() && (
									<div
										className='confirmationModalButtonRowConfirm'
										onClick={() =>
											openFinalizeDraftModal(null, true)
										}
										style={{
											width: '108px',
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'center',
										}}
									>
										<ReactSVG
											src={checkIcon}
											className='template-action-row-finalize-icon'
										/>
										Finalize
									</div>
								)}
							</div>
						</div>
					</div>
				</section>
				<section className='view-template-section'>
					<div className='printBtnB-templates' />
					{loadedDocumentDetail.docLink && isView && (
						<Iframe
							url={loadedDocumentDetail.docLink}
							width='100%'
							height='100%'
							id={'google_docs_iframe'}
							style={{
								border: '0px !important',
								border: 'red solid 1px',
							}}
							position='relative'
						/>
					)}
				</section>
			</main>
		</>
	) : (
		<>
			<Loading loading={true} style={{ top: 'calc(50% - 25px)' }} />
		</>
	);
};

export default TemplateDetail;
