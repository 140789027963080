//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Images
import ReactSVG from 'react-svg';
import xModalIcon from './../../../assets/close.svg';

//Components
import CommentBox from './CommentBox.js';

class ViewNotes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		const notes = get(this, 'props.notes', null) || [];
		const notesLength = get(notes, 'length', 0) || 0;
		const variant = get(this, 'props.variant', null) || 'dense';
		return (
			<div className='fullModalBackground'>
				<div className='viewNotesContainer'>
					<div className='viewNotesHeaderRow'>
						<div className='modalTitle CTAWarningModalTitle'>
							{variant === 'dense'
								? 'All Notes'
								: 'All Clinical Comments'}{' '}
							({notesLength})
						</div>
						<ReactSVG
							src={xModalIcon}
							className='singleSelectModalXIcon viewNotesXIcon'
							onClick={this.props.toggleViewNotesOff}
						/>
					</div>
					<div className='viewNotesBody'>
						{Array.isArray(notes) &&
							notes.map((note, idx) => {
								return (
									<CommentBox
										variant='full'
										note={note}
										idx={idx}
									/>
								);
							})}
					</div>
				</div>
			</div>
		);
	}
}

export default ViewNotes;
