//React & Redux
import React from 'react';
import { connect } from 'react-redux';

//Images
import ReactSVG from 'react-svg';
import Edit from './../../assets/edit-outreach.svg';
import Alert from './../../assets/alert.svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import {
	lab_order_integration_options,
	PaperTRFOnlyEnums,
	TestClassificationEnums,
} from './../../utils.js';
import { createEnumsOptions } from '../Patients/V2/Encounters/Common/utilsfunctions';

//Actions & Services
import {
	getTestDetail,
	getTestTypes,
	getTags,
	getOtherEnums,
	updateTest,
} from './../../actions/tests.js';
import { getencounterlabseschema } from '../../actions/encounter';

//UI Libraries
import { Loading, DatePicker } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import RadioGroup from '../Common/controls/RadioGroup/RadioGroup';
import { SelectComponent as Select } from '../Common/SelectComponent';

//Styles
import './testdetail.css';
import './../Patients/style.css';

class TestDetailView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			renderEdit: false,
			editedTest: {},
			loading: false,
			selectedTags: new Set(),
			testTypes: [],
			editedCycle: false,
			renderCancelAlert: false,
		};
		//bindings
		this.detectURLEdit = this.detectURLEdit.bind(this);
		this.renderEditAndChangeUrl = this.renderEditAndChangeUrl.bind(this);
		this.renderEdit = this.renderEdit.bind(this);
		this.closeEdit = this.closeEdit.bind(this);
		this.closeEditAndChangeUrl = this.closeEditAndChangeUrl.bind(this);
		this.changeUrlToEdit = this.changeUrlToEdit.bind(this);
		this.changeUrlFromEdit = this.changeUrlFromEdit.bind(this);
		this.navigateToTestList = this.navigateToTestList.bind(this);
		this.getTestTags = this.getTestTags.bind(this);
		this.getValue = this.getValue.bind(this);
		this.editTest = this.editTest.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.validateFields = this.validateFields.bind(this);
		this.handleTagSelect = this.handleTagSelect.bind(this);
		this.parseTags = this.parseTags.bind(this);
		this.parseTestTypes = this.parseTestTypes.bind(this);
		this.getTest = this.getTest.bind(this);
		this.getTestTypeVal = this.getTestTypeVal.bind(this);
		this.getTestTypeValInternal = this.getTestTypeValInternal.bind(this);
		this.getTestTypeUuid = this.getTestTypeUuid.bind(this);
		this.getCliaValue = this.getCliaValue.bind(this);
		this.getDateValue = this.getDateValue.bind(this);
		this.getGlobalLimitationsValue =
			this.getGlobalLimitationsValue.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.renderEditTestCancelAlert =
			this.renderEditTestCancelAlert.bind(this);
		this.hideEditTestCancelAlert = this.hideEditTestCancelAlert.bind(this);
		this.hideAlertAndCloseEdit = this.hideAlertAndCloseEdit.bind(this);
		this.getNGSValue = this.getNGSValue.bind(this);
		this.canSave = this.canSave.bind(this);
	}

	componentDidMount() {
		this.getTest();
		this.props.getLabsEnum();
		this.props.getTestTypes();
		this.props.getTags();
		this.props.getOtherEnums();
		if (this.detectURLEdit()) this.renderEdit();
	}

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.gettingTestDetail && !this.props.gettingTestDetail) {
			await this.parseTags();
			if (this.state.loading) {
				this.props.enqueueSnackbar('Test updated successfully', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			}
			this.setState({ loading: false });
			if (this.state.editedCycle) {
				this.setState({ editedCycle: false });
				this.closeEditAndChangeUrl();
			}
		}

		if (prevProps.gettingTestTypes && !this.props.gettingTestTypes) {
			await this.parseTestTypes();
		}

		if (prevProps.updatingTest && !this.props.updatingTest) {
			this.getTest();
			this.setState({ loading: true, editedCycle: true });
		}
	}

	getTest() {
		const test_uuid = get(this, 'props.match.params.id');
		if (test_uuid && test_uuid.length > 0) {
			this.props.getTestDetails(test_uuid);
		}
	}

	navigateToTestList() {
		const filterstate = get(this, ['props', 'location', 'state'], {});
		this.props.history.push({
			pathname: '/app/testmanagement',
			state: filterstate,
		});
	}

	parseTags() {
		return new Promise((resolve, reject) => {
			if (get(this, 'props.testDetail.tags', '')) {
				const tagsSet = new Set();
				if (this.props.testDetail.tags) {
					this.props.testDetail.tags.forEach((tagObj) => {
						tagsSet.add(tagObj.uuid);
					});
				}
				this.setState({ selectedTags: tagsSet }, resolve);
			} else {
				resolve();
			}
		});
	}

	parseTestTypes() {
		return new Promise((resolve, reject) => {
			if (get(this, 'props.testTypes', '')) {
				const parsedTestTypes = this.props.testTypes.map((el) => {
					return {
						internal: el.uuid,
						external: el.display_name,
					};
				});
				this.setState({ testTypes: parsedTestTypes }, resolve);
			} else {
				resolve();
			}
		});
	}

	getTestTags(tags) {
		const tag_names = tags.map((e) => {
			return e.tag;
		});
		return tag_names.join(', ');
	}

	getValue(val) {
		if (val && val.length > 0) {
			return val;
		} else return '--';
	}

	getTestTypeVal(uuid) {
		if (!uuid) return '';

		if (Array.isArray(this.state.testTypes)) {
			const filteredArr = this.state.testTypes.filter((obj) => {
				return obj.internal === uuid;
			});
			if (filteredArr[0]) return filteredArr[0].external;
		}

		return '';
	}

	getTestTypeValInternal(uuid) {
		if (!uuid) return '';

		if (Array.isArray(this.state.testTypes)) {
			const filteredArr = this.state.testTypes.filter((obj) => {
				return obj.internal === uuid;
			});
			if (filteredArr[0]) return filteredArr[0].internal;
		}

		return '';
	}

	getTestTypeUuid(name) {
		if (!name) return '';

		if (Array.isArray(this.state.testTypes)) {
			const filteredArr = this.state.testTypes.filter((obj) => {
				return obj.external === name;
			});

			if (filteredArr[0]) return filteredArr[0].internal;
		}

		return '';
	}

	getCliaValue(val) {
		switch (val) {
			case 'yes' || 'Yes':
				return 'Yes';
			case 'no' || 'No':
				return 'No';
			case 'na' || 'N/A':
				return 'N/A';
			default:
				return '';
		}
	}

	getNGSValue(val) {
		switch (val) {
			case 'ngs' || 'NGS':
				return 'NGS';
			case 'snp' || 'SNP':
				return 'SNP';
			case 'other' || 'Other':
				return 'Other';
			case 'unknown' || 'Unknown':
				return 'Unknown';
			default:
				return '';
		}
	}

	getDateValue() {
		const editedTest = this.state.editedTest;
		const test = get(this, 'props.testDetail', {});
		if (editedTest.init_available_date !== undefined) {
			if (!editedTest.init_available_date) return null;
			else
				return moment(
					editedTest.init_available_date,
					'YYYY-MM-DD'
				).toDate();
		}
		return test.init_available_date
			? moment(test.init_available_date, 'YYYY-MM-DD').toDate()
			: null;
	}

	getGlobalLimitationsValue() {
		const editedTest = this.state.editedTest;
		const test = get(this, 'props.testDetail', {});
		if (editedTest.global_limitations) {
			if (
				typeof editedTest.global_limitations === 'string' &&
				editedTest.global_limitations.length > 0
			) {
				return [editedTest.global_limitations];
			} else if (
				Array.isArray(editedTest.global_limitations) &&
				editedTest.global_limitations.length > 0
			) {
				return editedTest.global_limitations;
			} else return [];
		}

		if (test.global_limitations) {
			if (
				typeof test.global_limitations === 'string' &&
				test.global_limitations.length > 0
			) {
				return [test.global_limitations];
			} else if (
				Array.isArray(test.global_limitations) &&
				test.global_limitations.length > 0
			) {
				return test.global_limitations;
			} else return [];
		}

		return [];
	}

	editTest(header, value) {
		const editedTest = this.state.editedTest;
		switch (header) {
			case 'currently_offered':
			case 'lab_test_code':
			case 'clia_confirm_needed':
			case 'prenatal':
			case 'opportunity_for_reinterpretation':
			case 'ngs_vs_snp_assay':
			case 'global_limitations':
			case 'gene_specific_limitations':
			case 'comments':
			case 'classification':
				editedTest[header] = value;
				break;

			//from enums
			case 'test_type':
				editedTest['genetic_test_types_enums_uuid'] = value;
				break;

			//dates
			case 'init_available_date':
				if (!value) {
					editedTest[header] = value;
					break;
				}
				editedTest[header] = moment(value).format('YYYY-MM-DD');
				break;

			//placeholders
			case 'lab_information':
				// console.log("hit placeholder, can't edit editedTest object to update, unclear on which key:value pair for", header, "value is", value);
				break;
			case 'lab_order_integration':
				editedTest['lab_order_integration'] = value;
				break;
		}
		this.setState({ editedTest });
	}

	handleCancel() {
		this.setState({ renderCancelAlert: true });
	}

	renderEditTestCancelAlert() {
		return (
			<div className='cancelEditTestAlertContainer'>
				<div className='cancelEditTestAlertTopRow'>
					<ReactSVG src={Alert} className='cancelEditTestAlertIcon' />
					<span>Alert</span>
				</div>
				<div
					style={{ marginBottom: '20px' }}
					className='cancelEditTestAlertText'
				>
					Are you sure you want to exit?
				</div>
				<div
					style={{ marginBottom: '28px' }}
					className='cancelEditTestAlertText'
				>
					Any changes you have made will be lost.
				</div>
				<div className='doNotOutreachAlertButtonRow'>
					<div
						className='appointmentAlertKeepButton'
						onClick={this.hideEditTestCancelAlert}
						style={{ width: '70px', color: '#7b8291' }}
					>
						Cancel
					</div>
					<div
						className='testExitEditButton'
						onClick={this.hideAlertAndCloseEdit}
						style={{
							width: '51px',
							paddingLeft: '12px',
							color: 'white',
						}}
					>
						Quit
					</div>
				</div>
			</div>
		);
	}

	hideEditTestCancelAlert() {
		this.setState({ renderCancelAlert: false });
	}

	detectURLEdit() {
		return (
			get(this, 'props.history.location.search', '').toLowerCase() ===
			'?edit=true'
		);
	}

	renderEditAndChangeUrl() {
		this.renderEdit();
		this.changeUrlToEdit();
	}

	renderEdit() {
		this.setState({ renderEdit: true });
	}

	closeEdit() {
		this.setState({ renderEdit: false, editedTest: {} });
	}

	closeEditAndChangeUrl() {
		this.closeEdit();
		this.changeUrlFromEdit();
	}

	changeUrlToEdit() {
		if (!this.detectURLEdit()) {
			this.props.history.replace({
				pathname: get(this, 'props.history.location.pathname', ''),
				search: '?edit=true',
				state: get(this, 'props.history.location.state', {}),
			});
		}
	}

	changeUrlFromEdit() {
		if (this.detectURLEdit()) {
			this.props.history.replace({
				pathname: get(this, 'props.history.location.pathname', ''),
				state: get(this, 'props.history.location.state', {}),
			});
		}
	}

	hideAlertAndCloseEdit() {
		this.hideEditTestCancelAlert();
		this.closeEditAndChangeUrl();
	}

	canSave() {
		if (
			(!get(this, 'state.editedTest.currently_offered', null) &&
				!get(this, 'props.testDetail.currently_offered', null)) ||
			(!get(
				this,
				'state.editedTest.genetic_test_types_enums_uuid',
				null
			) &&
				!get(
					this,
					'props.testDetail.genetic_test_types_enums_uuid',
					null
				)) ||
			(!get(this, 'state.editedTest.clia_confirm_needed', null) &&
				!get(this, 'props.testDetail.clia_confirm_needed', null)) ||
			(!get(this, 'state.editedTest.prenatal', null) &&
				!get(this, 'props.testDetail.prenatal', null)) ||
			(!get(
				this,
				'state.editedTest.opportunity_for_reinterpretation',
				null
			) &&
				!get(
					this,
					'props.testDetail.opportunity_for_reinterpretation',
					null
				)) ||
			(!get(this, 'state.editedTest.classification', null) &&
				!get(this, 'props.testDetail.classification', null))
		) {
			return false;
		} else {
			return true;
		}
	}

	handleSave() {
		if (this.validateFields() && this.canSave()) {
			const editedTest = _.cloneDeep(this.state.editedTest);
			for (const key in editedTest) {
				if (typeof editedTest[key] === 'string')
					editedTest[key] = editedTest[key].trim();
			}
			editedTest.global_limitations = Array.isArray(
				editedTest.global_limitations
			)
				? editedTest.global_limitations[0] || ''
				: '';
			const data = {
				uuid: this.props.testDetail.uuid,
				payload: editedTest,
			};
			this.props.updateTest(data);
			this.setState({ loading: true });
		} else {
			//show error message
		}
	}

	validateFields() {
		//check each edited field for non-empty, non-null value - might not need any validation
		return true;
	}

	handleTagSelect(e, tag) {
		const tagsSet = this.state.selectedTags || new Set();
		const tagNode = e.target;
		if (tagNode.classList.contains('testTagEditSelected')) {
			tagNode.classList.remove('testTagEditSelected');
			tagNode.classList.add('testTagEdit');
			tagsSet.delete(tag);
		} else {
			tagNode.classList.remove('testTagEdit');
			tagNode.classList.add('testTagEditSelected');
			tagsSet.add(tag);
		}
		this.setState({ selectedTags: tagsSet });
		const editedTest = this.state.editedTest;
		editedTest.tags = Array.from(tagsSet);
		this.setState({ editedTest });
	}

	render() {
		const test = get(this, 'props.testDetail', {}) || {};
		const EDIT = this.state.renderEdit;
		const inputFieldText = 'inputFieldText';
		const inputFieldDropdown = 'inputFieldDropdown';
		const formMultiselect = 'formMultiselect';
		const inputFieldTextArea = 'inputFieldTextArea';
		const editedTest = this.state.editedTest;
		const uneditable = EDIT ? ' testInformationUneditable' : '';
		const uneditableText = EDIT ? 'testInformationUneditableText' : '';
		const editTitle = EDIT ? ' testEditTitle' : '';
		const saveButtonClass = this.canSave()
			? 'testSaveButton'
			: 'testSaveButton testSaveButtonDisabled';

		return (
			<div className='testDetailViewContainer'>
				<Loading
					loading={
						this.props.gettingTestDetail ||
						this.props.gettingLabs ||
						this.state.loading ||
						this.props.gettingTestTypes ||
						this.props.gettingTestTags ||
						this.props.updatingTest ||
						this.props.gettingOtherEnums
					}
					className='testDetailLoader'
				>
					{this.state.renderCancelAlert && (
						<div className='cancelEditTestAlertBackground'>
							{this.renderEditTestCancelAlert()}
						</div>
					)}
					<div
						className='test_list_navigator'
						onClick={(evt) => this.navigateToTestList()}
					>
						{' '}
						{'< Test management list'}{' '}
					</div>
					<div className='test_display_name'>
						{EDIT
							? 'Test Edit'
							: this.getValue(get(test, 'display_name', '--'))}
						{!EDIT && (
							<ReactSVG
								src={Edit}
								style={{
									position: 'relative',
									left: '10px',
									bottom: '2px',
									cursor: 'pointer',
								}}
								onClick={this.renderEditAndChangeUrl}
							/>
						)}
					</div>
					<div className='test_category_headers'>
						{' '}
						TEST INFORMATION{' '}
					</div>
					<div className='testInformationContainer'>
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								className={'testInformation'}
								style={{ minWidth: 'calc(40% - 20px)' }}
							>
								<div className={'test_field_title' + editTitle}>
									{' '}
									Lab{' '}
								</div>
								<div
									className={'test_field_detail' + uneditable}
								>
									<span className={uneditableText}>
										{get(
											test,
											'labs[0].display_name',
											'--'
										)}
									</span>
								</div>
							</div>
							<div
								className={'testInformation'}
								style={{ minWidth: 'calc(40% - 20px)' }}
							>
								<div className={'test_field_title' + editTitle}>
									{' '}
									Display name{' '}
								</div>
								<div
									className={'test_field_detail' + uneditable}
								>
									<div
										className={
											uneditableText +
											' test_information_limited'
										}
									>
										{this.getValue(
											get(test, 'display_name', '--')
										)}
									</div>
								</div>
							</div>
							<div
								className={'testInformation'}
								style={{ minWidth: 'calc(10% - 20px)' }}
							>
								<div className={'test_field_title' + editTitle}>
									{' '}
									Version{' '}
								</div>
								<div
									className={'test_field_detail' + uneditable}
								>
									<div
										className={
											uneditableText +
											' test_information_limited'
										}
									>
										{get(test, 'version.length', 0) > 0
											? 'v' + get(test, 'version', '--')
											: '--'}
									</div>
								</div>
							</div>
							<div className='testInformation'>
								{EDIT && (
									<div className='testEditRequiredField'>
										*
									</div>
								)}
								<div className={'test_field_title' + editTitle}>
									{' '}
									Currently Offered?{' '}
								</div>
								{EDIT ? (
									<RadioGroup
										value={
											editedTest.currently_offered ||
											get(test, 'currently_offered', '')
										}
										options={get(
											this,
											'props.otherEnums.currently_offered',
											[]
										)}
										onChange={(e) => {
											this.editTest(
												'currently_offered',
												e
											);
										}}
									/>
								) : (
									<div
										className='test_field_detail'
										style={{ textTransform: 'capitalize' }}
									>
										{' '}
										{get(
											test,
											'currently_offered',
											'--'
										)}{' '}
									</div>
								)}
							</div>
						</div>
						<div className='testInformation'>
							<div className={'test_field_title' + editTitle}>
								{' '}
								Initial available date{' '}
							</div>
							{EDIT ? (
								<DatePicker
									value={this.getDateValue()}
									placeholder='Select'
									onChange={(e) => {
										this.editTest('init_available_date', e);
									}}
									className='testEditInput'
									autoComplete='off'
									isReadOnly={false}
									placeholder='MM/DD/YYYY'
									format='MM/dd/yyyy'
								/>
							) : (
								<div className='test_field_detail'>
									{get(
										test,
										'init_available_date.length',
										0
									) > 0
										? moment(
												get(
													test,
													'init_available_date',
													'--'
												)
										  ).format('MMM D, YYYY')
										: '--'}
								</div>
							)}
						</div>
						<div
							className='testInformation'
							style={{ minWidth: 'calc(40% - 20px)' }}
						>
							<div className={'test_field_title' + editTitle}>
								{' '}
								Test code (as used by lab){' '}
							</div>
							{EDIT ? (
								<input
									value={
										editedTest.lab_test_code !== undefined
											? editedTest.lab_test_code
											: test.lab_test_code
									}
									className={inputFieldText}
									onChange={(e) => {
										this.editTest(
											'lab_test_code',
											e.target.value
										);
									}}
								/>
							) : (
								<div className='test_field_detail test_information_limited'>
									{' '}
									{this.getValue(
										get(test, 'lab_test_code', '--')
									)}{' '}
								</div>
							)}
						</div>
						<div className='testInformation testInformationBottomRow'>
							{EDIT && (
								<div className='testEditRequiredField'>*</div>
							)}
							<div className={'test_field_title' + editTitle}>
								{' '}
								Classification{' '}
							</div>
							{EDIT ? (
								<Select
									value={
										get(editedTest, 'classification', '') ||
										get(test, ['classification', 'name'])
									}
									className={inputFieldDropdown}
									placeholder='Select'
									onChange={(e) => {
										this.editTest('classification', e);
									}}
									typeahead={true}
									clearable={true}
									options={TestClassificationEnums}
								/>
							) : (
								<div
									className='test_field_detail'
									style={{ textTransform: 'capitalize' }}
								>
									{!_.isNil(get(test, 'classification'))
										? get(test, [
												'classification',
												'display_name',
										  ])
										: '--'}
								</div>
							)}
						</div>
						<div
							className={`${
								EDIT && 'readonly-test-info'
							} testInformation`}
							style={{ maxWidth: 'calc(20% - 20px)' }}
						>
							{EDIT && (
								<div className='testEditRequiredField'>*</div>
							)}
							<div className={'test_field_title' + editTitle}>
								{' '}
								Paper TRF only?{' '}
							</div>
							{EDIT ? (
								<RadioGroup
									value={
										editedTest.paper_form_only ||
										get(test, 'labs[0].paper_form_only', '')
									}
									options={PaperTRFOnlyEnums}
								/>
							) : (
								<div
									className='test_field_detail'
									style={{ textTransform: 'capitalize' }}
								>
									{' '}
									{get(test, 'labs[0].paper_form_only') ==
									true
										? 'Yes'
										: 'No'}{' '}
								</div>
							)}
						</div>
						<div
							className='testInformation'
							style={{ width: '100%', marginRight: '0px' }}
						>
							<div className={'test_field_title' + editTitle}>
								{' '}
								Lab info{' '}
							</div>
							{EDIT ? (
								<div
									className={'test_field_detail' + uneditable}
									style={{ width: '100%', height: '66px' }}
								>
									<span
										value={
											get(
												editedTest,
												'labs[0].information',
												''
											) ||
											get(test, 'labs[0].information', '')
										}
										className={uneditableText}
										style={{
											width: '100%',
											height: '66px',
										}}
										onChange={(e) => {
											this.editTest(
												'lab_information',
												e.target.value
											);
										}}
									/>
								</div>
							) : (
								<div
									className='test_field_detail'
									style={{ height: '66px' }}
								>
									{get(test, 'labs[0].information', '--') ||
										'--'}
								</div>
							)}
						</div>
					</div>
					<div className='test_category_headers'>
						{' '}
						ADDITIONAL INFO{' '}
					</div>
					<div className='testInformationContainer'>
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}
						>
							<div className='testInformation testInformationBottomRow'>
								{EDIT && (
									<div className='testEditRequiredField'>
										*
									</div>
								)}
								<div className={'test_field_title' + editTitle}>
									{' '}
									Test type{' '}
								</div>
								{EDIT ? (
									<Select
										value={
											this.getTestTypeVal(
												get(
													editedTest,
													'genetic_test_types_enums_uuid',
													''
												)
											) ||
											this.getTestTypeValInternal(
												get(
													test,
													'test_type[0].uuid',
													''
												)
											)
										}
										className={inputFieldDropdown}
										placeholder='Select'
										onChange={(e) => {
											this.editTest('test_type', e);
										}}
										typeahead={true}
										clearable={true}
										options={createEnumsOptions(
											this,
											['state', 'testTypes'],
											'internal',
											'external',
											'internal'
										)}
									/>
								) : (
									<div className='test_field_detail'>
										{' '}
										{get(
											test,
											'test_type[0].display_name',
											'--'
										)}{' '}
									</div>
								)}
							</div>
							<div className='testInformation testInformationBottomRow'>
								{EDIT && (
									<div className='testEditRequiredField'>
										*
									</div>
								)}
								<div className={'test_field_title' + editTitle}>
									{' '}
									Confirmation recommended?{' '}
								</div>
								{EDIT ? (
									<Select
										value={
											get(
												editedTest,
												'clia_confirm_needed',
												''
											) ||
											get(test, 'clia_confirm_needed', '')
										}
										className={inputFieldDropdown}
										placeholder='Select'
										onChange={(e) => {
											this.editTest(
												'clia_confirm_needed',
												e
											);
										}}
										typeahead={true}
										clearable={true}
										options={createEnumsOptions(
											this,
											[
												'props',
												'otherEnums',
												'clia_confirm_needed',
											],
											'key',
											'display_name',
											'key'
										)}
									/>
								) : (
									<div
										className='test_field_detail'
										style={{ textTransform: 'capitalize' }}
									>
										{get(
											test,
											'clia_confirm_needed',
											'--'
										) == 'na'
											? 'N/A'
											: get(
													test,
													'clia_confirm_needed',
													'--'
											  )}
									</div>
								)}
							</div>
							<div className='testInformation testInformationBottomRow'>
								{EDIT && (
									<div className='testEditRequiredField'>
										*
									</div>
								)}
								<div className={'test_field_title' + editTitle}>
									{' '}
									Prenatal{' '}
								</div>
								{EDIT ? (
									<RadioGroup
										value={
											get(
												editedTest,
												'prenatal',
												''
											).toLowerCase() ||
											(
												get(test, 'prenatal', '') || ''
											).toLowerCase()
										}
										options={get(
											this,
											'props.otherEnums.prenatal',
											[]
										)}
										onChange={(e) => {
											this.editTest('prenatal', e);
										}}
									/>
								) : (
									<div
										className='test_field_detail'
										style={{ textTransform: 'capitalize' }}
									>
										{' '}
										{get(test, 'prenatal', '--')}{' '}
									</div>
								)}
							</div>
							<div className='testInformation testInformationBottomRow'>
								{EDIT && (
									<div className='testEditRequiredField'>
										*
									</div>
								)}
								<div className={'test_field_title' + editTitle}>
									{' '}
									Opp. for reinterpretation?{' '}
								</div>
								{EDIT ? (
									<RadioGroup
										value={
											get(
												editedTest,
												'opportunity_for_reinterpretation',
												''
											) ||
											get(
												test,
												'opportunity_for_reinterpretation',
												''
											)
										}
										options={get(
											this,
											'props.otherEnums.opportunity_for_reinterpretation',
											[]
										)}
										onChange={(e) => {
											this.editTest(
												'opportunity_for_reinterpretation',
												e
											);
										}}
									/>
								) : (
									<div
										className='test_field_detail'
										style={{ textTransform: 'capitalize' }}
									>
										{get(
											test,
											'opportunity_for_reinterpretation',
											'--'
										) || '--'}
									</div>
								)}
							</div>
							<div
								className='testInformation testInformationBottomRow'
								style={{ maxHeight: '75px' }}
							>
								<div className={'test_field_title' + editTitle}>
									{' '}
									NGS vs SNP assay?{' '}
								</div>
								{EDIT ? (
									<RadioGroup
										value={
											get(
												editedTest,
												'ngs_vs_snp_assay',
												''
											) ||
											get(test, 'ngs_vs_snp_assay', '')
										}
										options={get(
											this,
											'props.otherEnums.ngs_vs_snp_assay',
											[]
										)}
										onChange={(e) => {
											this.editTest(
												'ngs_vs_snp_assay',
												e
											);
										}}
									/>
								) : (
									<div className='test_field_detail'>
										{' '}
										{this.getNGSValue(
											get(test, 'ngs_vs_snp_assay', '--')
										)}{' '}
									</div>
								)}
							</div>
						</div>
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div className='testInformation testInformationBottomRow'>
								<div className={'test_field_title' + editTitle}>
									{' '}
									Global limitations{' '}
								</div>
								{EDIT ? (
									<Select
										value={this.getGlobalLimitationsValue()}
										className={formMultiselect}
										placeholder='Select'
										onChange={(e) => {
											this.editTest(
												'global_limitations',
												e
											);
										}}
										multiple={true}
										options={[].map((el) => {
											return {
												key: el.key,
												label: el.display_name,
												value: el.key,
											};
										})}
									/>
								) : (
									<div className='test_field_detail test_information_limited'>
										{this.getValue(
											get(
												test,
												'global_limitations',
												'--'
											)
										)}
									</div>
								)}
							</div>
							<div
								className='testInformation'
								style={{ width: 'calc(40% - 20px)' }}
							>
								<div className={'test_field_title' + editTitle}>
									{' '}
									Gene-specific limitations{' '}
								</div>
								{EDIT ? (
									<input
										value={
											editedTest.gene_specific_limitations !==
											undefined
												? editedTest.gene_specific_limitations
												: test.gene_specific_limitations
										}
										className='inputFieldText'
										onChange={(e) => {
											this.editTest(
												'gene_specific_limitations',
												e.target.value
											);
										}}
										style={{ width: 'calc(100% - 0px)' }}
									/>
								) : (
									<div
										className='test_field_detail'
										style={{
											position: 'relative',
											height: 'unset',
										}}
									>
										{this.getValue(
											get(
												test,
												'gene_specific_limitations',
												'--'
											)
										)}
									</div>
								)}
							</div>
							<div
								className='testInformation'
								style={{
									flexGrow: '1',
									width: 'calc(40% - 20px)',
								}}
							>
								<div className={'test_field_title' + editTitle}>
									{' '}
									Tags{' '}
								</div>
								<div
									className='test_field_detail'
									style={{
										height: '100%',
										display: 'flex',
										flexWrap: 'wrap',
									}}
								>
									<div
										className='testTagContainer detailView testTagContainerDetails'
										style={{
											paddingLeft: '0px',
											marginTop: '0px',
										}}
									>
										{EDIT
											? (
													get(
														this,
														'props.testTags',
														null
													) || []
											  ).map((tag) => {
													return (
														<div
															className={
																get(
																	this,
																	'state.selectedTags',
																	new Set()
																).has(tag.uuid)
																	? 'testTagEditSelected'
																	: 'testTagEdit'
															}
															key={tag.uuid}
															onClick={(e) =>
																this.handleTagSelect(
																	e,
																	tag.uuid
																)
															}
														>
															{tag.tag || '--'}
														</div>
													);
											  })
											: (
													get(test, 'tags', null) ||
													[]
											  ).map((tag) => {
													return (
														<div
															className='testTag'
															key={tag.uuid}
															style={{
																height: '19px',
															}}
														>
															{tag.tag || '--'}
														</div>
													);
											  })}
									</div>
								</div>
							</div>
						</div>
						<div
							className='testInformation'
							style={{
								width: '100%',
								minHeight: '79',
								marginRight: '0px',
								marginBottom: '80px',
							}}
						>
							<div className={'test_field_title' + editTitle}>
								{' '}
								Comments{' '}
							</div>
							{EDIT ? (
								<textarea
									value={
										editedTest.comments !== undefined
											? editedTest.comments
											: test.comments
									}
									className='inputFieldTextArea inputFieldTextArea-mb-0'
									placeholder=''
									onChange={(e) => {
										this.editTest(
											'comments',
											e.target.value
										);
									}}
									style={{
										width: '100%',
										minHeight: '59px',
										height: '58px',
									}}
								/>
							) : (
								<div
									className='test_field_detail test_information_limited testInformationComments'
									style={{
										width: '100%',
										WebkitBoxOrient: 'vertical',
										MozBoxOrient: 'vertical',
									}}
								>
									{this.getValue(get(test, 'comments', '--'))}
								</div>
							)}
						</div>
						<div
							className='testInformation hide-lab-integration-field'
							style={{
								width: '100%',
								minHeight: '79px',
								marginBottom: '80px',
								marginRight: '0px',
							}}
						>
							<div
								className={'test_field_title' + editTitle}
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								{' '}
								Send Order to Lab via Integration?{' '}
								{EDIT ? (
									<RadioGroup
										value={
											editedTest.lab_order_integration ==
											undefined
												? get(
														test,
														'lab_order_integration',
														''
												  )
												: editedTest.lab_order_integration
										}
										options={lab_order_integration_options}
										onChange={(e) => {
											this.editTest(
												'lab_order_integration',
												e
											);
										}}
									/>
								) : (
									<div
										className='test_field_detail'
										style={{ textTransform: 'capitalize' }}
									>
										{get(
											test,
											'lab_order_integration',
											false
										)
											? 'Yes'
											: 'No'}
									</div>
								)}
							</div>
						</div>
					</div>
					{EDIT
						? ''
						: get(test, 'genes.length', 0) > 0 && (
								<React.Fragment>
									<div className='test_category_headers'>
										{' '}
										{'GENES (' +
											get(test, 'genes.length', 0) +
											')'}{' '}
									</div>
									<div className='testTagContainer detailView'>
										{(get(test, 'genes', []) || []).map(
											(e) => {
												return (
													<div
														className='testTag genes'
														style={{
															marginRight: '10px',
														}}
													>
														{' '}
														{get(
															e,
															'gene',
															''
														)}{' '}
													</div>
												);
											}
										)}
									</div>
								</React.Fragment>
						  )}
					{EDIT ? (
						<div className='testButtonRow'>
							<div
								className='testCancelButton'
								onClick={this.handleCancel}
							>
								Cancel
							</div>
							<div
								className={saveButtonClass}
								onClick={this.handleSave}
							>
								Save
							</div>
						</div>
					) : (
						<div style={{ height: '20px', marginBottom: '30px' }} />
					)}
				</Loading>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		testDetail: get(state, 'getTestDetail.testDetail', {}),
		gettingTestDetail: state.getTestDetail.gettingTestDetail,
		labs: state.labsschema.schema,
		gettingLabs: state.labsschema.gettingLabsEnum,
		gettingTestTypes: state.geneticTestEnums.gettingTestTypes,
		gettingTestTags: state.geneticTestEnums.gettingTestTags,
		testTypes: state.geneticTestEnums.testTypes,
		testTags: state.geneticTestEnums.testTags,
		otherEnums: state.geneticTestEnums.otherEnums,
		gettingOtherEnums: state.geneticTestEnums.gettingOtherEnums,
		updatingTest: state.testUpdateCreate.updatingTest,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getTestDetails: (uuid) => dispatch(getTestDetail(uuid)),
	getLabsEnum: () => dispatch(getencounterlabseschema()),
	getTestTypes: () => dispatch(getTestTypes()),
	getTags: () => dispatch(getTags()),
	getOtherEnums: () => dispatch(getOtherEnums()),
	updateTest: (data) => dispatch(updateTest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestDetailView);
