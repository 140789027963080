import axios from 'axios';

class NotesService {
	notes = (data) => {
		return axios.get('/api/notes?id=' + data.id + '&offset=' + data.offset);
	};
	submitnotes = (data) => {
		return axios.post('/api/notes', data);
	};

	deleteNote = (data) => {
		return axios.delete(`/api/notes/?note_uuid=${data.note_uuid}&patient_uuid=${data.patient_uuid}`);
	};
}

export default new NotesService();
