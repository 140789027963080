//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import contactsIcon from '../../assets/contacts.svg';
import redFlagIcon from '../../assets/red-flag.svg';
import greenFlagIcon from '../../assets/green-flag.svg';

//Lodash
import { get, isNil, size } from 'lodash';

//UI Libraries
import { Dialog } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Styles
import './red-flag-audittrail-modal.css';

//Components
import Icon from '../Common/Icon';

const RedFlagAuditTrailDataModal = (props) => {
	const { hideRedFlagAuditTrailModal, patientDetail, testOrder } = props;

	const determineDOB = () => {
		const dob = get(patientDetail, ['dob'], null);

		if (moment(dob).isValid()) {
			return moment(dob).format('MMM DD, YYYY');
		} else return dob || 'DOB Unknown';
	};

	const determineDOBDifference = () => {
		const dob = get(patientDetail, ['dob'], null);

		if (moment(dob).isValid()) {
			return moment().diff(dob, 'years') + ' yrs old';
		} else return 'Age Unknown';
	};

	const displayGreenFlagObj = () => {
		const red_flags_audit_trail = get(
			testOrder,
			['red_flags_audit_trail'],
			[]
		);
		let _displayGreenFlagObj = {};
		if (size(red_flags_audit_trail) > 0) {
			const flagsAuditTrail = _.orderBy(
				red_flags_audit_trail,
				(flag) => moment(get(flag, 'created_at', null)),
				['desc']
			);
			_displayGreenFlagObj = _.find(flagsAuditTrail, (flag) => {
				return _.get(flag, 'action', '') === 'red_flag_cleared';
			});
			return _displayGreenFlagObj;
		} else return '';
	};

	const displayRedFlagObj = () => {
		const red_flags_audit_trail = get(
			testOrder,
			['red_flags_audit_trail'],
			[]
		);
		let _displayRedFlagObj = {};
		if (size(red_flags_audit_trail) > 0) {
			const flagsAuditTrail = _.orderBy(
				red_flags_audit_trail,
				(flag) => moment(get(flag, 'created_at', null)),
				['desc']
			);
			_displayRedFlagObj = _.find(flagsAuditTrail, (flag) => {
				return _.get(flag, 'action', '') === 'red_flag_created';
			});
			return _displayRedFlagObj;
		} else return '';
	};

	const displayRedFlagReason = () => {
		const red_flags = get(testOrder, ['red_flags'], []);
		if (size(red_flags) > 0) {
			return (
				<ul>
					{red_flags.map((flag) => {
						return <li>{flag.message}</li>;
					})}
				</ul>
			);
		} else return '';
	};

	const displayGreenFlagData = displayGreenFlagObj();
	const displayRedFlagData = displayRedFlagObj();

	return (
		<Dialog
			customClass='red-flag-audittrail-dialog'
			title={
				<span className='title-wrapper'>
					<span>Audit Trail</span>
					<span
						className='close-btn-icon'
						onClick={hideRedFlagAuditTrailModal}
					>
						<Icon icon='close' className='close-icon' />
					</span>
				</span>
			}
			modal={true}
			closeOnPressEscape={true}
			closeOnClickModal={true}
			showClose={false}
			visible={true}
		>
			<Dialog.Body>
				<div className='patient-info-container'>
					<ReactSVG src={contactsIcon} className='contacts-icon' />
					<div className='patient-info-wrapper'>
						<span className='audittrail-flag-patient-name'>
							{get(patientDetail, ['first_name'], '')}{' '}
							{get(patientDetail, ['last_name'], '')}
						</span>
						<span className='audittrail-flag-patient-email'>
							{get(patientDetail, ['email'], '')}
						</span>
						<span>
							{determineDOB()} &nbsp;&nbsp; · &nbsp;&nbsp;{' '}
							{determineDOBDifference()}{' '}
							{!isNil(get(patientDetail, ['gender'], '')) ? (
								<span className='patient-gender'>
									&nbsp;&nbsp; · &nbsp;&nbsp;{' '}
									{get(patientDetail, ['gender'], '')}
								</span>
							) : (
								''
							)}
						</span>
						<span>
							<span>Ref. Program:</span>
							<span className='refral-prgrm-val'>
								{get(patientDetail, ['referral_program'], '')}
							</span>
						</span>
					</div>
				</div>

				<div className='green-flag-detail-container'>
					<div className='green-flag-test-info-head'>
						<ReactSVG
							src={greenFlagIcon}
							className='audittrail-green-flag-icon'
						/>
						<div className='green-flag-test-info-wrapper'>
							<span className='label-flag-update'>
								Flag Update
							</span>
							<span className='created-name'>
								{get(displayGreenFlagData, ['user'], '')}
							</span>
							<span className='green-flag-created-at'>
								{moment(
									get(
										displayGreenFlagData,
										['created_at'],
										''
									)
								).isValid()
									? moment(
											moment
												.utc(
													get(
														displayGreenFlagData,
														['created_at'],
														''
													)
												)
												.local()
												.tz(moment.tz.guess())
									  ).format('MMM DD, YYYY hh:mm A z')
									: '--'}
							</span>
						</div>
					</div>
					<div className='change-green-flag-body'>
						{get(displayGreenFlagData, ['reason'], '')}
					</div>
				</div>

				<div className='red-flag-detail-container'>
					<div className='red-flag-test-info-head'>
						<ReactSVG
							src={redFlagIcon}
							className='audittrail-red-flag-icon'
						/>
						<div className='red-flag-test-info-wrapper'>
							<span className='test-name'>
								{get(testOrder, ['testname'], '--')}
							</span>
							<span className='lab-name'>
								{get(testOrder, ['lab'], '--')}
							</span>
							<span className='red-flag-created-at'>
								{moment(
									get(displayRedFlagData, ['created_at'], '')
								).isValid()
									? moment(
											moment
												.utc(
													get(
														displayRedFlagData,
														['created_at'],
														''
													)
												)
												.local()
												.tz(moment.tz.guess())
									  ).format('MMM DD, YYYY hh:mm A z')
									: '--'}
							</span>
						</div>
					</div>
					<div className='flag-reason-list-body'>
						{displayRedFlagReason()}
					</div>
				</div>
			</Dialog.Body>
		</Dialog>
	);
};

export default RedFlagAuditTrailDataModal;
