import moment from 'moment';

import { convertTags } from '../../../Patients/V3/CreatePatient/api';

import { PAYMENT_METHOD } from './sections';
import { addressTypeConst } from '../../../../utils';

/**
 * @typedef {object} PatientInfo
 * @property {any} associatedProgram
 * @property {any} consentToTreat
 * @property {any} dateOfBirth
 * @property {any} guardianFirstName
 * @property {any} guardianLastName
 * @property {any} hasGuardian
 * @property {any} patientEmail
 * @property {any} patientPrimaryPhoneNumber
 * @property {any} preferredName
 * @property {any} relationshipToPatient
 * @property {any} tags
 * @property {boolean} [textNotification]
 */

/**
 * @typedef {object} PaymentInfo
 * @property {'INSURANCE'|'NON_INSURANCE'} paymentMethod
 * @property {any} group
 * @property {any} healthInsurance
 * @property {any} insuranceId
 * @property {any} isPrimaryPolicyHolder
 * @property {any} policyholderBiologicalSex
 * @property {any} policyholderFirstName
 * @property {any} policyholderLastName
 * @property {any} policyholderDOB
 * @property {any} relationshipToPolicyholder
 * @property {string} addressLine1
 * @property {string} addressLine2
 * @property {string} city
 * @property {string} state
 * @property {string} zip
 */

/**
 * @typedef {object} Summary
 * @property {any} couponCode
 */

/**
 * @typedef {object} ServiceInfoAndScheduling
 * @property {any} country
 * @property {any} state
 * @property {any} timezone
 * @property {any} consultationType
 * @property {any} specialty
 * @property {any} provider
 * @property {string} additionalConferenceLine
 * @property {string} timeslot
 */

/**
 * @typedef {object} FormValues
 * @property {PatientInfo} patientInfo
 * @property {ServiceInfoAndScheduling} serviceInfoAndScheduling
 * @property {PaymentInfo} paymentInfo
 */

/**
 * Convert form values to encounter payload
 * @param {FormValues} input
 * @returns {object} Encounter payload details
 */
export function formToEncounterApiPayload(input) {
	const payload = {
		by_phone: true,
		consultation_id: input.serviceInfoAndScheduling.consultationType,
		country: input.serviceInfoAndScheduling.country,
		coupon_code: input.summary.couponCode,
		patient_id: input.patientInfo.patientId,
		provider_id: input.serviceInfoAndScheduling.provider,
		start_time: moment(input.serviceInfoAndScheduling.timeslot)
			.tz(input.serviceInfoAndScheduling.timezone)
			.format('YYYY-MM-DDTHH:mm:ss'),
		state: input.serviceInfoAndScheduling.state,
		timezone: input.serviceInfoAndScheduling.timezone,
		use_insurance:
			input.paymentInfo.paymentMethod === PAYMENT_METHOD.INSURANCE,
		vsee_specialty: input.serviceInfoAndScheduling.specialty,
	};

	if (input.serviceInfoAndScheduling.provider) {
		payload.provider_id = input.serviceInfoAndScheduling.provider;
	}

	if (input.serviceInfoAndScheduling.additionalConferenceLine) {
		payload.additional_conference_line =
			input.serviceInfoAndScheduling.additionalConferenceLine;
	}

	return payload;
}

/**
 * Convert form values to encounter edit payload
 * @param {FormValues} input
 * @returns {object | null} Encounter edit payload details, or null
 */
export function formToEditEncounterApiPayload(input) {
	if (input.serviceInfoAndScheduling.additionalConferenceLine) {
		return {
			additional_conference_line:
				input.serviceInfoAndScheduling.additionalConferenceLine,
		};
	}

	return null;
}

/**
 * Convert form values to encounter payload
 * @param {FormValues} input
 * @returns {object} Encounter payload details
 */
export function formToPatientApiPayload(input) {
	const {
		associatedProgram,
		consentToTreat,
		dateOfBirth,
		guardianFirstName,
		guardianLastName,
		hasGuardian,
		patientEmail,
		patientPrimaryPhoneNumber,
		preferredName,
		relationshipToPatient,
		tags,
		textNotification,
	} = input.patientInfo;

	const payload = {
		consent_to_treat: consentToTreat,
		dob: moment(dateOfBirth).format('YYYY-MM-DD'),
		has_guardian: hasGuardian,
		is_unborn: false,
		partner_id: associatedProgram,
		preferred_name: preferredName,
		user_tags: convertTags(tags),
	};

	if (hasGuardian) {
		payload.users_guardian = {
			first_name: guardianFirstName,
			last_name: guardianLastName,
			phones: {
				primary: {
					value: patientPrimaryPhoneNumber,
					consent_to_text: textNotification,
				},
			},
			relationship: relationshipToPatient,
		};
	} else {
		payload.phones = {
			primary: {
				value: patientPrimaryPhoneNumber,
				consent_to_text: textNotification,
			},
		};
	}
	payload.email = patientEmail;
	return payload;
}

export function formToInsuranceApiPayload(input) {
	const {
		group,
		healthInsurance,
		insuranceId,
		isPrimaryPolicyHolder,
		policyholderBiologicalSex,
		relationshipToPolicyholder,
		policyholderFirstName,
		policyholderLastName,
		policyholderDOB,
	} = input.paymentInfo;
	return {
		insurance: [
			{
				company_name: '',
				group_no: group,
				insurance_company_id: healthInsurance,
				insurance_no: insuranceId,
				insurance_type: 'primary',
				is_policyholder: isPrimaryPolicyHolder,
				policyholder_biological_sex: policyholderBiologicalSex,
				policyholder_first_name: policyholderFirstName,
				policyholder_last_name: policyholderLastName,
				policyholder_dob: policyholderDOB,
				policyholder_relationship: relationshipToPolicyholder,
				visit_insurance: true,
				lab_insurance: false,
			},
		],
	};
}

export function formToAddressApiPayload(input, addressTypes) {
	const { patientInfo, paymentInfo } = input;
	const { homeAddress, shippingAddress } = paymentInfo;

	const isSameAddress =
		JSON.stringify(homeAddress) === JSON.stringify(shippingAddress);
	const fullName = `${patientInfo.patientFirstName} ${patientInfo.patientLastName}`;
	if (isSameAddress) {
		return [
			{
				address_line1: homeAddress.addressLine1,
				address_line2: homeAddress.addressLine2,
				city: homeAddress.city,
				state: homeAddress.state,
				zip: homeAddress.zip,
				type: [
					addressTypeConst.home,
					addressTypeConst.billing,
					addressTypeConst.shipping,
				],
				full_name: fullName,
				country: homeAddress.country,
			},
		];
	}

	return addressTypes.map((addressType) => {
		const address =
			addressType === addressTypeConst.home
				? homeAddress
				: shippingAddress;
		const addressList =
			addressType === addressTypeConst.home
				? [addressTypeConst.home]
				: [addressTypeConst.shipping];

		return {
			address_line1: address.addressLine1,
			address_line2: address.addressLine2,
			city: address.city,
			state: address.state,
			zip: address.zip,
			type: addressList,
			full_name: fullName,
			country: address.country,
		};
	});
}
