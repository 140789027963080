//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import Tooltip from './../Common/Tooltip';

//Other Libraries
import classnames from 'classnames';

//Styles
import './OrderStatusPill.css';

const orderStatusColorCode = {
	sent_to_lab: 'blue',
	results_ready: 'yellow',
	results_entered: 'yellow',
	sample_not_submitted: 'red',
	order_cancelled_by_provider: 'red',
	order_cancelled_by_patient: 'red',
	order_cancelled_by_lab: 'red',
	order_cancelled_by_partner: 'red',
	cancelled: 'red',
	patient_not_responsive: 'red',
	awaiting_information_patient: 'yellow',
	awaiting_benefits_investigation: 'yellow',
	awaiting_information_gc: 'yellow',
	awaiting_cc_submission: 'yellow',
	awaiting_birth: 'yellow',
	multiple: 'gray',
	rejected: 'red',
	rejected_other: 'red',
	no_test_available: 'red',
	patient_declined_pa_cnm: 'red',
	patient_declined_pa_cm: 'red',
	patient_declined: 'red',
	ins_denied_cost: 'red',
	iss_w_test_implications: 'red',
	no_fu_from_patient: 'red',
	declined_other: 'red',
	declined_via_partner: 'red',
	requested: 'yellow',
	result_positive_no_genes: 'yellow',
	result_positive_with_genes: 'green',
	result_negative: 'green',
	pre_auth_only: 'green',
	PA: 'gray',
};

const OrderStatusPill = (props) => {
	const { displayName, value } = props;

	const getOrderStatusColor = (status) => {
		return _.get(orderStatusColorCode, status, 'yellow');
	};

	const getTooltipText = (text) => {
		return (
			<Tooltip
				className='order_status_tooltip'
				effect='dark'
				content={text}
				placement='top'
			>
				<span>{text}</span>
			</Tooltip>
		);
	};

	if (_.isEmpty(displayName)) {
		return <div className='pat-dash'>--</div>;
	} else {
		return (
			<div
				className={classnames(
					'test-order-status-wrapper',
					getOrderStatusColor(value)
				)}
			>
				<div className='test-order-status ellipsed'>
					{getTooltipText(displayName)}
				</div>
			</div>
		);
	}
};

export default OrderStatusPill;
