//React & Redux
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

//Hooks
import { usePrevious } from '../../../hooks/usePrevious';

//Images
import warning from '../../../assets/warning.svg';
import cross from '../../../assets/cross.svg';

//Lodash
import _, { get } from 'lodash';

//Utils
import {
	UTCToCurrentView,
	RemoveUnderscoreAndCapitalizeFirstLetter,
} from '../../../utils';
import { HOME_DASHBOARD_CARDS_RESULT_VISIT_SUMMARY_READY } from '../../../Utils/permissionUtils';

//Actions & Services
import {
	getdashboardencounters,
	getspecificencounterenum,
	resetEncounterList,
} from '../../../actions/dashboard';
import { setFilter } from '../../../actions/appData';

//UI Libraries
import { Loading } from 'gm-element-react';
import Table from '../../Common/Table/Table';
import Thead from '../../Common/Table/Thead';
import Tbody from '../../Common/Table/Tbody';
import Th from '../../Common/Table/Th';
import Tr from '../../Common/Table/Tr';
import Td from '../../Common/Table/Td';

//Other Libraries
import moment from 'moment';

//Components
import RBAC from '../../RBAC';
import SingleSelect from '../../Filter/SingleSelect';
import TextWithTooltip from '../../Common/TextWithTooltip';
import ProviderInfo from '../../Common/ProviderInfo';
import Tags from '../../Common/Tags';

const TIME_FILTERS = [
	{ external: 'All Time', internal: 'all_time' },
	{ external: 'Last 7 Days', internal: 'last_7' },
	{ external: 'Last 14 Days', internal: 'last_14' },
];

export const ResultVisitSummaryReady = (props) => {
	const {
		location = {},
		urlFilter,
		getCardDashboardEncounters,
		callVisitProviderEnum,
		resetList,
		history,
		appointmentStatus = [],
		visitProvider,
		dashboardEncounters: {
			data,
			total,
			start,
			end,
			has_prev,
			has_next,
			offset,
		},
		dashboardEncountersLoading,
		cardFilter,
		setCardFilter,
		tableLimit,
	} = props;

	let locationState = get(location, 'state.breadcrumbs[1]', {});
	if (_.isEmpty(locationState)) {
		locationState = _.get(location, 'state.breadcrumbs[0]', {});
	}

	const preFilter = {
		...locationState,
		...cardFilter,
	};

	const [selectedFilter, setSelectedFilter] = useState(
		get(preFilter, 'selectedFilter', { days: 'last_14' })
	);
	const [activePage, setActivePage] = useState(
		get(preFilter, 'activePage', 0) ? get(preFilter, 'activePage', 0) : 0
	);
	const [orderType, setOrderType] = useState(
		get(preFilter, 'orderType') ? get(preFilter, 'orderType', '') : 'desc'
	);
	const [orderBy, setOrderBy] = useState(
		get(preFilter, 'orderBy')
			? get(preFilter, 'orderBy', '')
			: 'date_of_service'
	);
	const [limit, setLimit] = useState(
		tableLimit || get(preFilter, 'limit') ? get(preFilter, 'limit', '') : 25
	);
	const [order, setOrder] = useState('desc');
	const [query, setQuery] = useState('');

	const prevDashboardEncountersLoading = usePrevious(
		dashboardEncountersLoading
	);

	useEffect(() => {
		getDashboardEncounters();
		callVisitProviderEnum({ type: 'visit', field_name: 'visit_provider' });
		return resetList;
	}, []);

	useEffect(() => {
		getDashboardEncounters();
	}, [activePage, order, orderBy, query, limit, orderType, selectedFilter]);

	useEffect(() => {
		if (prevDashboardEncountersLoading && !dashboardEncountersLoading)
			setCardFilter(getFilter());
	}, [dashboardEncountersLoading]);

	const getFilter = () => {
		return {
			selectedFilter,
			activePage,
			orderType,
			orderBy,
			order,
			query,
		};
	};

	const getDashboardEncounters = () => {
		const timeFilter = _.get(selectedFilter, ['days']);

		if (urlFilter)
			getCardDashboardEncounters({
				filter: `?filter=${urlFilter}`,
				offset: activePage,
				order_type: orderType,
				order_by: orderBy,
				limit,
				time_filter: timeFilter,
			});
	};

	const handlePageChange = (e, pageNumber) => {
		setActivePage(pageNumber);
	};

	const handleDropdownChange = (value, filter) => {
		const newFilter = { ...selectedFilter, [filter]: value };
		setSelectedFilter(newFilter);
		setActivePage(0);
	};

	const getFormattedDate = (dateString) => {
		return dateString ? moment(dateString).format('MMM D, YYYY') : '--';
	};

	const getEncounterSpecificDate = (dateString, timezone, format) => {
		return dateString
			? UTCToCurrentView(
					moment,
					moment(dateString),
					timezone,
					null,
					format
			  )
			: '--';
	};

	const getPatientName = (el) => {
		const patient = _.get(el, 'patient', {});
		const name =
			_.get(patient, 'first_name', '') +
			' ' +
			_.get(patient, 'last_name', '');

		return name;
	};

	const getRedirectURL = (row) => {
		const url =
			'/app/patientdetail/' +
			_.get(row, ['patient', 'uuid'], '0') +
			'/0/2/' +
			_.get(row, ['uuid'], '0') +
			'?type=' +
			_.get(row, ['type'], '');
		return url;
	};

	const redirectToEncounterDetail = (row) => {
		history.push({
			pathname: getRedirectURL(row),
			state: {
				breadcrumbs: [
					{
						location: urlFilter,
						activePage: activePage,
						orderBy: orderBy,
						orderType: orderType,
						limit: limit,
						key: 'dashboard_card',
						selectedFilter: selectedFilter,
					},
					{
						location: 'Encounter Detail',
						patientFullName: getPatientName(row),
					},
				],
			},
		});
	};

	const getVisitStatus = (visitStatus) => {
		const enumValue = _.find(appointmentStatus, function (o) {
			return o.key === visitStatus;
		});
		const key = enumValue ? _.get(enumValue, 'key', '') : null;
		const text = enumValue
			? RemoveUnderscoreAndCapitalizeFirstLetter(
					_.get(enumValue, 'display_name', '')
			  )
			: RemoveUnderscoreAndCapitalizeFirstLetter(
					_.get(visitStatus, 'visit_status', ''),
					'_'
			  );

		return key !== null ? (
			<p>
				<img
					src={key === 'no_show' ? warning : cross}
					className='statusIcon'
				/>
				<span
					className={`appointmentStatus ${
						key === 'no_show'
							? 'appointmentWarning'
							: 'appointmentAlert'
					}`}
				>
					{text}
				</span>
			</p>
		) : (
			'--'
		);
	};

	const handleResetFilters = () => {
		setActivePage(0);
		setOrder('desc');
		setOrderBy('date_of_service');
		setQuery('');
		setLimit(25);
		setSelectedFilter({ days: 'last_14' });
	};

	const handleSort = (orderBy, orderType) => {
		setOrderType(orderType);
		setOrderBy(orderBy);
		setActivePage(0);
	};

	const handleResultsPerPageChange = (limit) => {
		setActivePage(0);
		setLimit(limit);
	};

	const pagination = {
		end: end || 0,
		has_next: has_next || false,
		has_prev: has_prev || false,
		limit: limit || 0,
		offset: offset || 0,
		start: start || 0,
		total: total || 0,
	};

	const sortBy = {
		key: orderBy,
		value: orderType,
	};

	return (
		<RBAC
			action={HOME_DASHBOARD_CARDS_RESULT_VISIT_SUMMARY_READY}
			yes={
				<div className='dashboard-encounter-container'>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							minWidth: 'inherit',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'right',
							}}
						>
							<SingleSelect
								data={TIME_FILTERS}
								currentValue={selectedFilter.days}
								title={'Time Range'}
								setStateFunc={(e) =>
									handleDropdownChange(e, 'days')
								}
								defaultValue={'last_14'}
							/>
						</div>
						{dashboardEncountersLoading ? (
							<Loading
								loading={true}
								className='external-list-loading'
							/>
						) : (
							<Table
								style={{
									marginTop: '16px',
									minWidth: '1204px',
								}}
								loading={false}
								backToTop
								resultsPerPage={[25, 50, 100]}
								handleResultsPerPageChange={
									handleResultsPerPageChange
								}
								pagination={pagination}
								handlePageChange={handlePageChange}
								hasData={!_.isEmpty(data)}
								handleResetFilters={handleResetFilters}
								handleSort={handleSort}
								sortBy={sortBy}
							>
								<Thead>
									<Tr>
										<Th
											sortable={{
												date_of_service: {
													display_name:
														'Date of Service',
													sortBy: {
														asc: 'A to Z',
														desc: 'Z to A',
													},
												},
											}}
										>
											Date of Service
										</Th>
										<Th
											sortable={{
												first_name: {
													display_name: 'Name',
													sortBy: {
														asc: 'A to Z',
														desc: 'Z to A',
													},
												},
											}}
										>
											Name
										</Th>
										<Th
											sortable={{
												email: {
													display_name: 'Email',
													sortBy: {
														asc: 'A to Z',
														desc: 'Z to A',
													},
												},
											}}
										>
											Email
										</Th>
										<Th
											sortable={{
												followup_cap_completed_date: {
													display_name:
														'Result CAP Completed Date',
													sortBy: {
														asc: 'A to Z',
														desc: 'Z to A',
													},
												},
											}}
										>
											Result CAP Completed Date
										</Th>
										<Th
											sortable={{
												visit_provider: {
													display_name: 'Provider',
													sortBy: {
														asc: 'A to Z',
														desc: 'Z to A',
													},
												},
											}}
										>
											Provider
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									{(data || []).map((row, index) => {
										return (
											<Tr
												className='test_list_row'
												key={`grid-${index}`}
											>
												<Td
													minWidth='165px'
													maxWidth='165px'
													className={`${
														orderBy ==
														'date_of_service'
															? 'font-bold'
															: ''
													}`}
												>
													<p>
														{getEncounterSpecificDate(
															_.get(
																row,
																'date_of_service'
															),
															_.get(
																row,
																'timezone'
															),
															'MMM D, YYYY'
														)}
													</p>
													<p className='sub'>
														{getEncounterSpecificDate(
															_.get(
																row,
																'date_of_service'
															),
															_.get(
																row,
																'timezone'
															),
															'h:mm A z'
														)}
													</p>
												</Td>
												<Td
													minWidth='274px'
													maxWidth='274px'
													className={`${
														orderBy == 'first_name'
															? 'font-bold'
															: ''
													}`}
												>
													<p>
														<TextWithTooltip maxWidth='274px'>
															<p
																onClick={() =>
																	redirectToEncounterDetail(
																		row
																	)
																}
																style={{
																	cursor: 'pointer',
																}}
															>
																{
																	row.patient
																		.first_name
																}{' '}
																{
																	row.patient
																		.last_name
																}
																<Tags
																	tags={get(
																		row,
																		'patient.user_tags',
																		[]
																	)}
																/>
															</p>
														</TextWithTooltip>
													</p>
													<p className='sub capitalize'>
														{getFormattedDate(
															row.patient.dob
														)}{' '}
														·{' '}
														{row.patient.gender ||
															'--'}
													</p>
												</Td>
												<Td
													minWidth='382px'
													maxWidth='382px'
													className={`${
														orderBy == 'email'
															? 'font-bold'
															: ''
													}`}
												>
													{row.patient.email}
												</Td>
												<Td
													minWidth='212px'
													maxWidth='212px'
													className={`${
														orderBy ==
														'followup_cap_completed_date'
															? 'font-bold'
															: ''
													}`}
												>
													{getFormattedDate(
														_.get(
															row,
															'followup_cap_completed_date'
														)
													)}
												</Td>
												<Td
													minWidth='212px'
													maxWidth='212px'
													className={`${
														orderBy ==
														'visit_provider'
															? 'font-bold'
															: ''
													}`}
												>
													<ProviderInfo
														providerenum={_.get(
															visitProvider,
															[
																'data',
																'visit_provider',
															],
															[]
														)}
														providerName={_.get(
															row,
															['visit_provider'],
															''
														)}
													/>
												</Td>
											</Tr>
										);
									})}
								</Tbody>
							</Table>
						)}
					</div>
				</div>
			}
		/>
	);
};

const mapStateToProps = (state, props) => {
	return {
		dashboardEncounters: _.get(
			state,
			['dashboardencounters', 'dashboardencounters'],
			{}
		),
		dashboardEncountersLoading: _.get(
			state,
			['dashboardencounters', 'dashboardencountersLoading'],
			false
		),
		urlFilter: 'result_visit_summary_ready',
		appointmentStatus: _.get(
			state,
			['visitsschema', 'schema', 'data', 'visit_status'],
			[]
		),
		cardFilter:
			_.get(state, 'appData.externalResultVisitSummaryReadyCard', null) ||
			{},
		tableLimit: state.uiConfig.table.resultsPerPage,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getCardDashboardEncounters: (filter) =>
		dispatch(getdashboardencounters(filter)),
	callVisitProviderEnum: (data) => dispatch(getspecificencounterenum(data)),
	resetList: () => dispatch(resetEncounterList()),
	setCardFilter: (data) =>
		dispatch(setFilter('externalResultVisitSummaryReadyCard', data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ResultVisitSummaryReady);
