import { gql } from '@apollo/client';
import { client } from './GraphQLConnection';
import { isNil, isEmpty } from 'lodash';

class StructuralVariant {
	createDiagnosticReport = (patientID, testOrderID) => {
		return client.mutate({
			mutation: gql`
				mutation {
					addDiagnosticReport(
						input: {
							subjectId: "${patientID}"
							testOrderId: "${testOrderID}"
							status: FINAL
						}
					) {
						diagnosticReport {
							id
						}
					}
				}
			`,
		});
	};

	addStructuralVariantFinding = (data, diagnosticReportId) => {
		const _structuralVariantFinding =
			!isNil(data?.geneticExtents) &&
			!isEmpty(data?.geneticExtents) &&
			(isEmpty(data?.chromosomalExtents) ||
				isNil(data?.chromosomalExtents))
				? gql`
			mutation {
				addDiagnosticFinding(
				input: {
					diagnosticReportId: "${diagnosticReportId}"
					finding: {
						structuralVariantFinding: {
							assessment:  ${data.assessment}
							variantType: ${data.variantType}
							${
								data.referenceAssemblyCode
									? `referenceAssemblyCode: "${data.referenceAssemblyCode}"`
									: ''
							}
							chromosomalExtents: []
							geneticExtents: [
								{
									geneId: "${data.geneticExtents.geneId}",
									entireCodingRegion: ${data.geneticExtents.entireCodingRegion},
									${
										data.geneticExtents.startExon
											? `startExon: ${data.geneticExtents.startExon}`
											: ''
									},
									${data.geneticExtents.endExon ? `endExon: ${data.geneticExtents.endExon}` : ''}
								}								  	
							]
							externalVariantIds: []
							${
								data.sizeValue
									? `size: { value: "${data.sizeValue}", unit: "${data.unit}" }`
									: ''
							}
							${data.copyNumber ? `copyNumber: ${data.copyNumber}` : ''}
							origin: ${data.origin}
							genomicSourceClass: ${data.genomicSourceClass}							
							${
								data.mosaicism
									? `${
											data.mosaicism
												? `mosaicism: ${data.mosaicism}`
												: ''
									  }`
									: ''
							}
							${
								data.originalReportedClassification
									? `originalReportedClassification: ${data.originalReportedClassification}`
									: ''
							}							
						}
					}
				}
				) {
				finding {
					id
					... on StructuralVariantFinding {
					id
					assessment
					variantType
					chromosomalExtents {
						chromosome
						cytogeneticLocation
						startPosition
						endPosition
					}
					geneticExtents {
						gene {
						id
						symbol
						name
						aliasSymbols
						cytogeneticLocation
						}
						startExon
						endExon
						entireCodingRegion
					}
					referenceAssembly {
						code
						name
						description
					}
					genomicSourceClass
					mosaicism					
					cytogenomicNomenclature
					cytogenomicCharacterization
					size {
						value
						unit
					}
					origin
					notes {
						id
						enteredAt
						text
					}
					interpretations {
						interpretedOn
						classification
						source {
						id
						name
						}
					}
					mostRecentInterpretation {
						interpretedOn
						source {
						id
						name
						}
						classification
					}
					originalReportedInterpretation {
						classification
						interpretedOn
						source {
						id
						name
						}
					}
					copyNumber
					externalVariantIds
					assertedAt
					assertedBy {
						id
						name
					}
					}
				}
				}
			}
			`
				: !isNil(data?.chromosomalExtents) &&
				  !isEmpty(data?.chromosomalExtents) &&
				  (isEmpty(data?.geneticExtents) || isNil(data?.geneticExtents))
				? gql`
			mutation {
				addDiagnosticFinding(
				input: {
					diagnosticReportId: "${diagnosticReportId}"
					finding: {
						structuralVariantFinding: {
							assessment:  ${data.assessment}
							variantType: ${data.variantType}
							${
								data.referenceAssemblyCode
									? `referenceAssemblyCode: "${data.referenceAssemblyCode}"`
									: ''
							}
							chromosomalExtents: [
								{
									chromosome: "${data.chromosomalExtents.chromosome}",
									${
										data.chromosomalExtents
											.cytogeneticLocation
											? `cytogeneticLocation: "${data.chromosomalExtents.cytogeneticLocation}"`
											: ''
									},
									${
										data.chromosomalExtents.startPosition
											? `startPosition: ${data.chromosomalExtents.startPosition}`
											: ''
									},
									${
										data.chromosomalExtents.endPosition
											? `endPosition: ${data.chromosomalExtents.endPosition}`
											: ''
									}			
								}
							]
							geneticExtents: []
							externalVariantIds: []
							${
								data.sizeValue
									? `size: { value: "${data.sizeValue}", unit: "${data.unit}" }`
									: ''
							}
							${data.copyNumber ? `copyNumber: ${data.copyNumber}` : ''}
							origin: ${data.origin}
							genomicSourceClass: ${data.genomicSourceClass}
							${data.mosaicism ? `mosaicism: ${data.mosaicism}` : ''}
							${
								data.originalReportedClassification
									? `originalReportedClassification: ${data.originalReportedClassification}`
									: ''
							}							
						}
					}
				}
				) {
				finding {
					id
					... on StructuralVariantFinding {
					id
					assessment
					variantType
					chromosomalExtents {
						chromosome
						cytogeneticLocation
						startPosition
						endPosition
					}
					geneticExtents {
						gene {
						id
						symbol
						name
						aliasSymbols
						cytogeneticLocation
						}
						startExon
						endExon
						entireCodingRegion
					}
					referenceAssembly {
						code
						name
						description
					}
					genomicSourceClass
					cytogenomicNomenclature
					cytogenomicCharacterization
					size {
						value
						unit
					}
					origin
					mosaicism					
					notes {
						id
						enteredAt
						text
					}
					interpretations {
						interpretedOn
						classification
						source {
						id
						name
						}
					}
					mostRecentInterpretation {
						interpretedOn
						source {
						id
						name
						}
						classification
					}
					originalReportedInterpretation {
						classification
						interpretedOn
						source {
						id
						name
						}
					}
					copyNumber
					externalVariantIds
					assertedAt
					assertedBy {
						id
						name
					}
					}
				}
				}
			}
			`
				: gql`
				mutation {
					addDiagnosticFinding(
					  input: {
						diagnosticReportId: "${diagnosticReportId}"
						finding: {
							structuralVariantFinding: {
								assessment:  ${data.assessment}
								variantType: ${data.variantType}
								${
									data.referenceAssemblyCode
										? `referenceAssemblyCode: "${data.referenceAssemblyCode}"`
										: ''
								}
								chromosomalExtents: []
								geneticExtents: []
								externalVariantIds: []
								${
									data.sizeValue
										? `size: { value: "${data.sizeValue}", unit: "${data.unit}" }`
										: ''
								}
								${data.copyNumber ? `copyNumber: ${data.copyNumber}` : ''}
								${
									data.cytogenomicNomenclature
										? `cytogenomicNomenclature: "${data.cytogenomicNomenclature}"`
										: ''
								}
								${
									data.cytogenomicCharacterization
										? `cytogenomicCharacterization: "${data.cytogenomicCharacterization}"`
										: ''
								}
								origin: ${data.origin}
								${data.mosaicism ? `mosaicism: ${data.mosaicism}` : ''}								
								genomicSourceClass: ${data.genomicSourceClass}
								${
									data.originalReportedClassification
										? `originalReportedClassification: ${data.originalReportedClassification}`
										: ''
								}							
							}
						}
					  }
					) {
					  finding {
						id
						... on StructuralVariantFinding {
						  id
						  assessment
						  variantType
						  chromosomalExtents {
							chromosome
							cytogeneticLocation
							startPosition
							endPosition
						  }
						  geneticExtents {
							gene {
							  id
							  symbol
							  name
							  aliasSymbols
							  cytogeneticLocation
							}
							startExon
							endExon
							entireCodingRegion
						  }
						  referenceAssembly {
							code
							name
							description
						  }
						  genomicSourceClass
						  cytogenomicNomenclature
						  cytogenomicCharacterization
						  size {
							value
							unit
						  }
						  origin
						  mosaicism						  
						  notes {
							id
							enteredAt
							text
						  }
						  interpretations {
							interpretedOn
							classification
							source {
							  id
							  name
							}
						  }
						  mostRecentInterpretation {
							interpretedOn
							source {
							  id
							  name
							}
							classification
						  }
						  originalReportedInterpretation {
							classification
							interpretedOn
							source {
							  id
							  name
							}
						  }
						  copyNumber
						  externalVariantIds
						  assertedAt
						  assertedBy {
							id
							name
						  }
						}
					  }
					}
				}
			`;

		return client.mutate({
			mutation: _structuralVariantFinding,
		});
	};

	updateStructuralVariantFinding = (data = {}, findingId = null) => {
		const _structuralVariantFinding =
			!isNil(data?.geneticExtents) &&
			!isEmpty(data?.geneticExtents) &&
			(isEmpty(data?.chromosomalExtents) ||
				isNil(data?.chromosomalExtents))
				? gql`
			mutation {
				updateDiagnosticFinding(
					input: {
						structuralVariantFinding: {
							id: "${findingId}"
							assessment: ${data.assessment}
							variantType: ${data.variantType}
							externalVariantIds: []
							origin: ${data.origin}
							genomicSourceClass: ${data.genomicSourceClass}
							${data.mosaicism ? `mosaicism: ${data.mosaicism}` : ''}														
							chromosomalExtents: []
							${
								data.referenceAssemblyCode
									? `referenceAssemblyCode: "${data.referenceAssemblyCode}"`
									: ''
							}
							${
								data.sizeValue
									? `size: { value: "${data.sizeValue}", unit: "${data.unit}" }`
									: ''
							}
							${data.copyNumber ? `copyNumber: ${data.copyNumber}` : ''}
							${
								data.originalReportedClassification
									? `originalReportedClassification: ${data.originalReportedClassification}`
									: ''
							}							
							geneticExtents: [
								{
									geneId: "${data.geneticExtents.geneId}",
									entireCodingRegion: ${data.geneticExtents.entireCodingRegion},
									${
										data.geneticExtents.startExon
											? `startExon: ${data.geneticExtents.startExon}`
											: ''
									},
									${data.geneticExtents.endExon ? `endExon: ${data.geneticExtents.endExon}` : ''}
								}								  	
							]														
						}					
					}
				) {
				finding {
					id
					... on StructuralVariantFinding {
					id
					assessment
					variantType
					chromosomalExtents {
						chromosome
						cytogeneticLocation
						startPosition
						endPosition
					}
					geneticExtents {
						gene {
						id
						symbol
						name
						aliasSymbols
						cytogeneticLocation
						}
						startExon
						endExon
						entireCodingRegion
					}
					referenceAssembly {
						code
						name
						description
					}
					genomicSourceClass
					cytogenomicNomenclature
					cytogenomicCharacterization
					size {
						value
						unit
					}
					origin
					mosaicism					
					notes {
						id
						enteredAt
						text
					}
					interpretations {
						interpretedOn
						classification
						source {
						id
						name
						}
					}
					mostRecentInterpretation {
						interpretedOn
						source {
						id
						name
						}
						classification
					}
					originalReportedInterpretation {
						classification
						interpretedOn
						source {
						id
						name
						}
					}
					copyNumber
					externalVariantIds
					assertedAt
					assertedBy {
						id
						name
					}
					}
				}
				}
			}
			`
				: !isNil(data?.chromosomalExtents) &&
				  !isEmpty(data?.chromosomalExtents) &&
				  (isEmpty(data?.geneticExtents) || isNil(data?.geneticExtents))
				? gql`
					mutation {
						updateDiagnosticFinding(
							input: {
								structuralVariantFinding: {
									id: "${findingId}"
									assessment: ${data.assessment}
									variantType: ${data.variantType}
									externalVariantIds: []
									origin: ${data.origin}
									genomicSourceClass: ${data.genomicSourceClass}
									${data.mosaicism ? `mosaicism: ${data.mosaicism}` : ''}																		
									chromosomalExtents: [
										{
											chromosome: "${data.chromosomalExtents.chromosome}",
											${
												data.chromosomalExtents
													.cytogeneticLocation
													? `cytogeneticLocation: "${data.chromosomalExtents.cytogeneticLocation}"`
													: ''
											},
											${
												data.chromosomalExtents
													.startPosition
													? `startPosition: ${data.chromosomalExtents.startPosition}`
													: ''
											},
											${
												data.chromosomalExtents
													.endPosition
													? `endPosition: ${data.chromosomalExtents.endPosition}`
													: ''
											}			
										}
									]
									geneticExtents: []
									${
										data.referenceAssemblyCode
											? `referenceAssemblyCode: "${data.referenceAssemblyCode}"`
											: ''
									}
									${
										data.sizeValue
											? `size: { value: "${data.sizeValue}", unit: "${data.unit}" }`
											: ''
									}
									${data.copyNumber ? `copyNumber: ${data.copyNumber}` : ''}
									${
										data.originalReportedClassification
											? `originalReportedClassification: ${data.originalReportedClassification}`
											: ''
									}
								}
							}
						) {
							finding {
								id
								... on StructuralVariantFinding {
									id
									assessment
									variantType
									chromosomalExtents {
										chromosome
										cytogeneticLocation
										startPosition
										endPosition
									}
									geneticExtents {
										gene {
											id
											symbol
											name
											aliasSymbols
											cytogeneticLocation
										}
										startExon
										endExon
										entireCodingRegion
									}
									referenceAssembly {
										code
										name
										description
									}
									genomicSourceClass
									cytogenomicNomenclature
									cytogenomicCharacterization
									size {
										value
										unit
									}
									origin
									mosaicism									
									notes {
										id
										enteredAt
										text
									}
									interpretations {
										interpretedOn
										classification
										source {
											id
											name
										}
									}
									mostRecentInterpretation {
										interpretedOn
										source {
											id
											name
										}
										classification
									}
									originalReportedInterpretation {
										classification
										interpretedOn
										source {
											id
											name
										}
									}
									copyNumber
									externalVariantIds
									assertedAt
									assertedBy {
										id
										name
									}
								}
							}
						}
					}`
				: gql`
						mutation {
							updateDiagnosticFinding(
								input: {
									structuralVariantFinding: {
										id: "${findingId}"
										assessment: ${data.assessment}
										variantType: ${data.variantType}
										externalVariantIds: []
										origin: ${data.origin}
										genomicSourceClass: ${data.genomicSourceClass}
										${data.mosaicism ? `mosaicism: ${data.mosaicism}` : ''}										
										${
											data.cytogenomicCharacterization
												? `cytogenomicCharacterization: "${data.cytogenomicCharacterization}"`
												: ''
										}
										${
											data.cytogenomicNomenclature
												? `cytogenomicNomenclature: "${data.cytogenomicNomenclature}"`
												: ''
										}
										chromosomalExtents: []
										geneticExtents: []
										${
											data.referenceAssemblyCode
												? `referenceAssemblyCode: "${data.referenceAssemblyCode}"`
												: ''
										}
										${
											data.originalReportedClassification
												? `originalReportedClassification: ${data.originalReportedClassification}`
												: ''
										}
									}
								}
							) {
								finding {
									id
									... on StructuralVariantFinding {
										id
										assessment
										variantType
										chromosomalExtents {
											chromosome
											cytogeneticLocation
											startPosition
											endPosition
										}
										geneticExtents {
											gene {
												id
												symbol
												name
												aliasSymbols
												cytogeneticLocation
											}
											startExon
											endExon
											entireCodingRegion
										}
										referenceAssembly {
											code
											name
											description
										}
										genomicSourceClass
										cytogenomicNomenclature
										cytogenomicCharacterization
										size {
											value
											unit
										}
										origin
										mosaicism										
										notes {
											id
											enteredAt
											text
										}
										interpretations {
											interpretedOn
											classification
											source {
												id
												name
											}
										}
										mostRecentInterpretation {
											interpretedOn
											source {
												id
												name
											}
											classification
										}
										originalReportedInterpretation {
											classification
											interpretedOn
											source {
												id
												name
											}
										}
										copyNumber
										externalVariantIds
										assertedAt
										assertedBy {
											id
											name
										}
									}
								}
							}
						}
				  `;

		return client.mutate({
			mutation: _structuralVariantFinding,
		});
	};

	getStructuralFindingValidation = (data) => {
		const _structuralVariantValidation =
			!isNil(data?.geneticExtents) &&
			!isEmpty(data?.geneticExtents) &&
			(isEmpty(data?.chromosomalExtents) ||
				isNil(data?.chromosomalExtents))
				? gql`
				query {
					diagnosticFindingValidation(
						finding: {
							structuralVariantFinding: {
								assessment: ${data.assessment}
								variantType: ${data.variantType}
								${
									data.referenceAssemblyCode
										? `referenceAssemblyCode: "${data.referenceAssemblyCode}"`
										: ''
								}
								chromosomalExtents: []
								geneticExtents: [
									{
										geneId: "${data.geneticExtents.geneId}",
										entireCodingRegion: ${data.geneticExtents.entireCodingRegion},
										${
											data.geneticExtents.startExon
												? `startExon: ${data.geneticExtents.startExon}`
												: ''
										},
										${data.geneticExtents.endExon ? `endExon: ${data.geneticExtents.endExon}` : ''}
									}								  	
								]
								externalVariantIds: []
								${
									data.sizeValue
										? `size: { value: "${data.sizeValue}", unit: "${data.unit}" }`
										: ''
								}
								${data.copyNumber ? `copyNumber: ${data.copyNumber}` : ''}
								${data.origin ? `origin: ${data.origin}` : `origin: UNKNOWN`}
								${data.mosaicism ? `mosaicism: ${data.mosaicism}` : ''}								
								${
									data.genomicSourceClass
										? `genomicSourceClass: ${data.genomicSourceClass}`
										: `genomicSourceClass: UNKNOWN`
								}								
								${
									data.originalReportedClassification
										? `originalReportedClassification: ${data.originalReportedClassification}`
										: ''
								}                            	
						  	}
						}
					) {
						isValid
						issues {
						  severity
						  message
						  detailMessage
						  paths
						}
					}                                      
				}
				`
				: !isNil(data?.chromosomalExtents) &&
				  !isEmpty(data?.chromosomalExtents) &&
				  (isEmpty(data?.geneticExtents) || isNil(data?.geneticExtents))
				? gql`
				query {
					diagnosticFindingValidation(
						finding: {
							structuralVariantFinding: {
								assessment: ${data.assessment}
								variantType: ${data.variantType}
								${
									data.referenceAssemblyCode
										? `referenceAssemblyCode: "${data.referenceAssemblyCode}"`
										: ''
								}
								chromosomalExtents: [
									{
										chromosome: "${data.chromosomalExtents.chromosome}",
										${
											data.chromosomalExtents
												.cytogeneticLocation
												? `cytogeneticLocation: "${data.chromosomalExtents.cytogeneticLocation}"`
												: ''
										},
										${
											data.chromosomalExtents
												.startPosition
												? `startPosition: ${data.chromosomalExtents.startPosition}`
												: ''
										},
										${
											data.chromosomalExtents.endPosition
												? `endPosition: ${data.chromosomalExtents.endPosition}`
												: ''
										}			
									}
								]
								geneticExtents: []
								externalVariantIds: []
								${
									data.sizeValue
										? `size: { value: "${data.sizeValue}", unit: "${data.unit}" }`
										: ''
								}
								${data.copyNumber ? `copyNumber: ${data.copyNumber}` : ''}
								${data.origin ? `origin: ${data.origin}` : `origin: UNKNOWN`}
								${data.mosaicism ? `mosaicism: ${data.mosaicism}` : ''}								
								${
									data.genomicSourceClass
										? `genomicSourceClass: ${data.genomicSourceClass}`
										: `genomicSourceClass: UNKNOWN`
								}
								${
									data.originalReportedClassification
										? `originalReportedClassification: ${data.originalReportedClassification}`
										: ''
								}                            	
						  	}
						}
					) {
						isValid
						issues {
						  severity
						  message
						  detailMessage
						  paths
						}
					}                                      
				}
				`
				: gql`
				query {
					diagnosticFindingValidation(
						finding: {
							structuralVariantFinding: {
								assessment: ${data.assessment}
								variantType: ${data.variantType}
								${
									data.referenceAssemblyCode
										? `referenceAssemblyCode: "${data.referenceAssemblyCode}"`
										: ''
								}
								chromosomalExtents: []
								geneticExtents: []
								externalVariantIds: []
								${
									data.cytogenomicNomenclature
										? `cytogenomicNomenclature: "${data.cytogenomicNomenclature}"`
										: ''
								}
								${
									data.cytogenomicCharacterization
										? `cytogenomicCharacterization: "${data.cytogenomicCharacterization}"`
										: ''
								}
								${
									data.sizeValue
										? `size: { value: "${data.sizeValue}", unit: "${data.unit}" }`
										: ''
								}
								${data.copyNumber ? `copyNumber: ${data.copyNumber}` : ''}
								${data.origin ? `origin: ${data.origin}` : `origin: UNKNOWN`}
								${data.mosaicism ? `mosaicism: ${data.mosaicism}` : ''}								
								${
									data.genomicSourceClass
										? `genomicSourceClass: ${data.genomicSourceClass}`
										: `genomicSourceClass: UNKNOWN`
								}
								${
									data.originalReportedClassification
										? `originalReportedClassification: ${data.originalReportedClassification}`
										: ''
								}                            	
						  	}
						}
					) {
						isValid
						issues {
						  severity
						  message
						  detailMessage
						  paths
						}
					}                                      
				}
				`;

		return client.query({
			query: _structuralVariantValidation,
		});
	};
}

export default new StructuralVariant();
