import { useElements, useStripe } from '@stripe/react-stripe-js';

/**
 * Hook that returns a callback that can be used to submit payment details.
 */
export function useCreatePaymentMethod() {
	const stripe = useStripe();
	const elements = useElements();

	/**
	 * Submit payment details to Stripe
	 * @param {import('@stripe/stripe-js').BillingDetails} billingDetails
	 * @returns {Promise<import('@stripe/stripe-js').SetupIntent|undefined>} Stripe setup intent
	 */
	async function handleSubmit(billingDetails = {}) {
		if (!stripe || !elements) {
			// stripe is not yet loaded
			return;
		}

		const { error, setupIntent } = await stripe.confirmSetup({
			redirect: 'if_required',
			elements,
			confirmParams: {
				payment_method_data: {
					billing_details: billingDetails,
				},
			},
		});

		if (error) {
			throw new Error(error.message);
		}

		return setupIntent;
	}

	return handleSubmit;
}
