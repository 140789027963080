import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getencountervisitsschema } from '../../../../actions/encounter';

import MultiSelectCheckbox from '../../../Filter/MultiSelectCheckbox';

export function Specialty({ onChange, value }) {
	const visitSchema = useSelector((store) => store.visitsschema.schema?.data);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getencountervisitsschema());
	}, []);

	return (
		<MultiSelectCheckbox
			data={visitSchema?.vsee_specialty
				.filter(
					(item) =>
						item.key !== 'no_default' &&
						!item.key.includes('_willing')
				)
				.map((item) => ({
					key: item.display_name,
					display_name: item.display_name,
				}))}
			selected={value}
			title='Specialty'
			submit={onChange}
			majorPillTitle='Specialty'
			searchBar={false}
			previewTab={false}
			emptyInputFullOutputOverride={false}
		/>
	);
}
