//React & Redux
import React, { Component, Fragment } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	createEnumsOptions,
	filterDispatchStatus,
	CascaderOptions,
	findCascaderHierarchy,
} from '../Encounters/Common/utilsfunctions';

//UI Libraries
import { Layout, Cascader } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import { EncounterDateField } from './DateComponent';
import ICDTag from '../../ChargeInformation/ICDTag';
import PreAuthMilestoneWarning from './PreAuthMilestoneWarning';
import ErrorBoundary from '../../../Common/ErrorBoundary';
import { SelectComponent as Select } from '../../../Common/SelectComponent';

//Styles
import './create-test-request-modal.css';

class PreAuthTestRequestLabInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			optionsLabEnum: _.isArray(
				createEnumsOptions(
					this,
					['props', 'labenums'],
					'key',
					'display_name',
					'key'
				)
			)
				? createEnumsOptions(
						this,
						['props', 'labenums'],
						'key',
						'display_name',
						'key'
				  )
				: [],
		};
		this.handleChange = this.handleChange.bind(this);
		this.isDisabledField = this.isDisabledField.bind(this);
	}

	isDisabledField = (keyname) => {
		const testRequestMode = _.get(this, ['props', 'testRequestMode'], '');

		if (keyname) {
			if (keyname == 'lab_uuid' && testRequestMode === 'edit') {
				return true;
			} else if (
				keyname === 'genetic_test_uuid' &&
				testRequestMode === 'edit'
			) {
				return true;
			} else if (
				keyname === 'request_decision' &&
				testRequestMode === 'edit'
			) {
				return true;
			} else if (
				keyname === 'alternate_lab_uuid' &&
				testRequestMode === 'edit'
			) {
				return true;
			} else if (
				keyname === 'alternate_test_uuid' &&
				testRequestMode === 'edit'
			) {
				return true;
			}
		}
		return false;
	};

	handleChange = (key, value) => {
		if (['request_decision', 'dispatch_status'].includes(key)) {
			const warningFields = _.get(
				this.props,
				['formdata', 'warningFields'],
				[]
			);
			warningFields.push(key);
			this.props.onFormChange('warningFields', warningFields);
		}
		this.props.onFormChange(key, value);
	};

	onHandleDateFieldValidate = (key, value) => {
		this.props.handleDateFieldValidate(key, value);
	};

	isSelectedLabEmpty = () => {
		const lab_uuid = _.get(this, ['props', 'formdata', 'lab_uuid'], '');
		const labschema = _.get(this, ['props', 'labenums'], []);
		const labvalue = labschema.find((a) => a.key == lab_uuid);
		return labvalue && _.get(labvalue, 'display_name', '') == '';
	};

	isSelectedAlternateLabEmpty = () => {
		const alternate_lab_uuid = _.get(
			this,
			['props', 'formdata', 'alternate_lab_uuid'],
			''
		);
		const labschema = _.get(this, ['props', 'labenums'], []);
		const labvalue = labschema.find((a) => a.key == alternate_lab_uuid);
		return labvalue && _.get(labvalue, 'display_name', '') == '';
	};

	isTestNameEditable() {
		const lab_uuid = _.get(this, ['props', 'formdata', 'lab_uuid'], '');
		if (lab_uuid == '') {
			return false;
		} else {
			return !this.isSelectedLabEmpty();
		}
	}

	isAlternateTestNameEditable() {
		const alternate_lab_uuid = _.get(
			this,
			['props', 'formdata', 'alternate_lab_uuid'],
			''
		);
		if (alternate_lab_uuid == '') {
			return false;
		} else {
			return !this.isSelectedAlternateLabEmpty();
		}
	}

	renderICDCodes() {
		let medicalCodes =
			this.props.userSelectedICDCodes ||
			_.get(this, 'props.testorder.medical_codes', []) ||
			[];
		medicalCodes = medicalCodes.slice().sort((a, b) => {
			if (_.get(a, 'code_name', '') < _.get(b, 'code_name', ''))
				return -1;
			else return 1;
		});
		if (medicalCodes.length > 0) {
			return medicalCodes.map((el, i) => {
				return (
					<React.Fragment>
						<ICDTag
							icdCode={el}
							toggleCodeSelection={() => {}}
							hasX={false}
							key={el.code_name}
						/>
					</React.Fragment>
				);
			});
		}
		return null;
	}

	isPreAuthDispatchDateRequired = () => {
		const statusEnum = {
			pa_form_sent: 1,
		};

		const stateorderstatus = _.get(
			this,
			['props', 'formdata', 'dispatch_status'],
			{}
		);

		if (stateorderstatus in statusEnum) {
			return true;
		}

		return false;
	};

	isLabDisabled = () => {
		let result = false;
		const propsEnum = {
			patient_pending_pa: 1,
		};
		const propsorderstatus = _.get(
			this,
			['props', 'testorder', 'request_decision'],
			null
		);
		const testRequestMode = _.get(this, ['props', 'testRequestMode'], '');

		if (testRequestMode === 'create') {
			result = false;
		} else if (propsorderstatus in propsEnum) {
			result = false;
		} else {
			result = true;
		}

		return result;
	};

	isTestNameDisabled = () => {
		let result = false;
		const propsEnum = {
			patient_pending_pa: 1,
		};
		const propsorderstatus = _.get(
			this,
			['props', 'testorder', 'request_decision'],
			null
		);
		const testRequestMode = _.get(this, ['props', 'testRequestMode'], '');

		if (testRequestMode === 'create') {
			result = false;
		} else if (propsorderstatus in propsEnum) {
			result = false;
		} else {
			result = true;
		}

		return result;
	};

	isRequestDecisionDisabled = () => {
		let result = false;
		const propsEnum = {
			patient_pending_pa: 1,
		};
		const propsorderstatus = _.get(
			this,
			['props', 'testorder', 'request_decision'],
			null
		);
		const testRequestMode = _.get(this, ['props', 'testRequestMode'], '');

		if (testRequestMode === 'create') {
			result = false;
		} else if (propsorderstatus in propsEnum) {
			result = false;
		} else {
			result = true;
		}

		return result;
	};

	isPreAuthDispatchDateDisabled = () => {
		let result = false;

		const statusEnum = {
			awaiting_cc_submission: 1,
			declined_dispatch_na: 1,
			awaiting_information_patient: 1,
		};
		const propsEnum = {
			pa_form_sent: 1,
			awaiting_information_patient: 1,
		};

		const propsorderstatus = _.get(
			this,
			['props', 'testorder', 'dispatch_status'],
			null
		);
		const stateorderstatus = _.get(
			this,
			['props', 'formdata', 'dispatch_status'],
			{}
		);
		const testRequestMode = _.get(this, ['props', 'testRequestMode'], '');

		if (testRequestMode === 'create') {
			result = true;
		} else if (propsorderstatus == 'pa_form_sent') {
			result = true;
		} else if (
			propsorderstatus in propsEnum &&
			stateorderstatus != 'pa_form_sent'
		) {
			result = true;
		} else if (stateorderstatus in statusEnum) {
			result = true;
		} else {
			result = false;
		}

		return result;
	};

	isDispatchStatusDisabled = () => {
		let result = false;
		const statusEnum = {
			pa_form_sent: 1,
		};
		const propsEnum = {
			pa_form_sent: 1,
			declined_dispatch_na: 1,
		};
		const decisionEnum = {
			patient_pending_pa: 1,
		};

		const stateorderstatus = _.get(
			this,
			['props', 'formdata', 'dispatch_status'],
			{}
		);
		const propsorderstatus = _.get(
			this,
			['props', 'testorder', 'dispatch_status'],
			null
		);
		const testRequestMode = _.get(this, ['props', 'testRequestMode'], '');
		const requestdecisionstatus = _.get(
			this,
			['props', 'testorder', 'request_decision'],
			null
		);

		if (testRequestMode === 'create') {
			result = true;
		} else if (propsorderstatus in propsEnum) {
			result = true;
		} else if (
			propsorderstatus == 'awaiting_information_patient' &&
			requestdecisionstatus in decisionEnum
		) {
			result = true;
		} else if (stateorderstatus in statusEnum) {
			result = false;
		} else {
			result = false;
		}

		return result;
	};

	isAlternateLabDisabled = () => {
		let result = false;
		const statusEnum = {
			requested_alternate_pa_atr: 1,
		};
		const propsEnum = {
			requested_alternate_pa_atr: 1,
		};
		const stateorderstatus = _.get(
			this,
			['props', 'formdata', 'request_decision'],
			{}
		);
		const propsorderstatus = _.get(
			this,
			['props', 'testorder', 'request_decision'],
			null
		);

		if (propsorderstatus in statusEnum) {
			result = true;
		} else if (stateorderstatus in propsEnum) {
			result = false;
		} else {
			result = true;
		}

		return result;
	};

	isAlternateTestDisabled = () => {
		let result = false;
		const statusEnum = {
			requested_alternate_pa_atr: 1,
		};
		const propsEnum = {
			requested_alternate_pa_atr: 1,
		};
		const stateorderstatus = _.get(
			this,
			['props', 'formdata', 'request_decision'],
			{}
		);
		const propsorderstatus = _.get(
			this,
			['props', 'testorder', 'request_decision'],
			null
		);

		if (propsorderstatus in statusEnum) {
			result = true;
		} else if (stateorderstatus in propsEnum) {
			result = false;
		} else {
			result = true;
		}

		return result;
	};

	renderPreAuthMilestoneWarning = (key) => {
		return _.get(this, ['props', 'formdata', 'warningFields'], []).includes(
			key
		) ? (
			<PreAuthMilestoneWarning
				testorder={_.get(this, ['props', 'testorder'], {})}
				formdata={_.get(this, ['props', 'formdata'], {})}
				preauthdispatchstatusenums={_.get(
					this,
					['props', 'preauthdispatchstatusenums'],
					[]
				)}
				preauthdecisionenums={_.get(
					this,
					['props', 'preauthdecisionenums'],
					[]
				)}
				currentKey={key}
			/>
		) : null;
	};

	getPreauthDecisionDate = () => {
		const history = _.get(this, 'props.testorder.history', []);
		const sortedhistory = _.sortBy(history, function (dateObj) {
			return new Date(dateObj.created_at);
		});
		const preauthDecisionDate = _.find(sortedhistory, (obj) => {
			return obj.dispatch_reason === 'awaiting_cc_submission';
		});

		if (preauthDecisionDate) {
			return moment(
				moment
					.utc(_.get(preauthDecisionDate, 'created_at', null))
					.local()
					.format('YYYY-MM-DD')
			);
		}

		return null;
	};

	getTestNameOptions = (testNameObj) => {
		const testNameOptions = testNameObj;
		return testNameOptions.map((obj) => {
			if (obj.label == 'Galleri') {
				return { ...obj, disabled: true };
			}
			return obj;
		});
	};

	render() {
		return (
			<Fragment>
				<section className='create-test-rqst-labinfo-container pre-auth-test-rqst-labinfo-container'>
					<Layout.Row>
						<Layout.Col span='24' className='required'>
							Lab
						</Layout.Col>
						<Layout.Col span='24'>
							<ErrorBoundary>
								<Select
									value={_.get(
										this,
										['props', 'formdata', 'lab_uuid'],
										''
									)}
									placeholder={
										_.get(
											this,
											['props', 'testRequestMode'],
											''
										) === 'create'
											? 'Select'
											: 'NorthShore University HealthSystem'
									}
									onChange={(value) =>
										this.handleChange('lab_uuid', value)
									}
									disabled={this.isLabDisabled()}
									clearable={true}
									typeahead={true}
									className='smaller-dropdown'
									options={
										_.isArray(
											createEnumsOptions(
												this,
												['props', 'labenums'],
												'key',
												'display_name',
												'key'
											)
										)
											? createEnumsOptions(
													this,
													['props', 'labenums'],
													'key',
													'display_name',
													'key'
											  )
											: []
									}
								/>
							</ErrorBoundary>
						</Layout.Col>
					</Layout.Row>

					<Layout.Row>
						<Layout.Col span='24' className='required'>
							Test Name
						</Layout.Col>
						<Layout.Col span='24'>
							<ErrorBoundary>
								<Select
									value={_.get(
										this,
										[
											'props',
											'formdata',
											'genetic_test_uuid',
										],
										''
									)}
									placeholder='Select'
									disabled={this.isTestNameDisabled()}
									onChange={(value) =>
										this.handleChange(
											'genetic_test_uuid',
											value
										)
									}
									clearable={true}
									typeahead={true}
									className='smaller-dropdown'
									options={
										this.isTestNameEditable() &&
										this.getTestNameOptions(
											_.isArray(
												createEnumsOptions(
													this,
													[
														'props',
														'nonlegacytestOrderPopupTestEnums',
													],
													'key',
													'display_name',
													'key'
												)
											)
												? createEnumsOptions(
														this,
														[
															'props',
															'nonlegacytestOrderPopupTestEnums',
														],
														'key',
														'display_name',
														'key'
												  )
												: []
										)
									}
								/>
							</ErrorBoundary>
						</Layout.Col>
					</Layout.Row>

					<Layout.Row>
						<Layout.Col span='24' className='required'>
							Pre-Authorization Decision
						</Layout.Col>
						<Layout.Col span='24'>
							<ErrorBoundary>
								<Select
									value={_.get(
										this,
										[
											'props',
											'formdata',
											'request_decision',
										],
										''
									)}
									placeholder='Select'
									onChange={(value) =>
										this.handleChange(
											'request_decision',
											value
										)
									}
									disabled={this.isRequestDecisionDisabled()}
									clearable={true}
									typeahead={true}
									className='smaller-dropdown'
									options={
										_.isArray(
											createEnumsOptions(
												this,
												[
													'props',
													'preauthdecisionenums',
												],
												'key',
												'display_name',
												'key'
											)
										)
											? createEnumsOptions(
													this,
													[
														'props',
														'preauthdecisionenums',
													],
													'key',
													'display_name',
													'key'
											  )
											: []
									}
								/>
							</ErrorBoundary>
							{this.renderPreAuthMilestoneWarning(
								'request_decision'
							)}
						</Layout.Col>
					</Layout.Row>

					{_.get(
						this,
						['props', 'formdata', 'request_decision'],
						''
					) === 'requested_alternate_pa_atr' && (
						<Fragment>
							<Layout.Row>
								<Layout.Col span='24' className='required'>
									Alternate Lab
								</Layout.Col>
								<Layout.Col span='24'>
									<ErrorBoundary>
										<Select
											value={_.get(
												this,
												[
													'props',
													'formdata',
													'alternate_lab_uuid',
												],
												''
											)}
											placeholder='Select'
											onChange={(value) =>
												this.handleChange(
													'alternate_lab_uuid',
													value
												)
											}
											disabled={this.isAlternateLabDisabled()}
											clearable={true}
											typeahead={true}
											className='smaller-dropdown'
											options={
												_.isArray(
													createEnumsOptions(
														this,
														['props', 'labenums'],
														'key',
														'display_name',
														'key'
													)
												)
													? createEnumsOptions(
															this,
															[
																'props',
																'labenums',
															],
															'key',
															'display_name',
															'key'
													  )
													: []
											}
										/>
									</ErrorBoundary>
								</Layout.Col>
							</Layout.Row>

							<Layout.Row>
								<Layout.Col span='24' className='required'>
									Alternate Test Name
								</Layout.Col>
								<Layout.Col span='24'>
									<ErrorBoundary>
										<Select
											value={_.get(
												this,
												[
													'props',
													'formdata',
													'alternate_test_uuid',
												],
												''
											)}
											placeholder='Select'
											disabled={this.isAlternateTestDisabled()}
											onChange={(value) =>
												this.handleChange(
													'alternate_test_uuid',
													value
												)
											}
											clearable={true}
											typeahead={true}
											className='smaller-dropdown'
											options={
												this.isAlternateTestNameEditable() &&
												_.isArray(
													createEnumsOptions(
														this,
														[
															'props',
															'nonlegacyalternatetestnameenums',
														],
														'key',
														'display_name',
														'key'
													)
												)
													? createEnumsOptions(
															this,
															[
																'props',
																'nonlegacyalternatetestnameenums',
															],
															'key',
															'display_name',
															'key'
													  )
													: []
											}
										/>
									</ErrorBoundary>
								</Layout.Col>
							</Layout.Row>
						</Fragment>
					)}

					<Layout.Row gutter='16'>
						<Layout.Col span='24'>Dispatch Status</Layout.Col>
						<Layout.Col span='24'>
							<Cascader
								name='dispatch_status'
								value={findCascaderHierarchy(
									_.get(
										this,
										['props', 'preauthdispatchstatusenums'],
										[]
									),
									'key',
									_.get(
										this,
										[
											'props',
											'formdata',
											'dispatch_status',
										],
										''
									)
								)}
								onChange={(value) =>
									this.handleChange('dispatch_status', value)
								}
								showAllLevels={false}
								disabled={this.isDispatchStatusDisabled()}
								expandTrigger='hover'
								options={CascaderOptions(
									filterDispatchStatus(
										_.get(
											this,
											[
												'props',
												'preauthdispatchstatusenums',
											],
											[]
										),
										_.get(
											this,
											[
												'props',
												'testorder',
												'dispatch_status',
											],
											null
										)
									),
									'key',
									'display_name',
									'key'
								)}
								className='cascade-edit-test-order'
							/>
							{this.renderPreAuthMilestoneWarning(
								'dispatch_status'
							)}
						</Layout.Col>
					</Layout.Row>

					<Layout.Row>
						<Layout.Col
							span='24'
							className={
								this.isPreAuthDispatchDateRequired()
									? 'required'
									: ''
							}
						>
							Pre-Authorization Dispatch Date
						</Layout.Col>
						<Layout.Col span='24' className='encounter-input-field'>
							<EncounterDateField
								className={
									'testrequestdatefield calendar-pull-up calendar-pull-right'
								}
								name='dispatch_date'
								placeholder='MM/DD/YYYY'
								disabled={this.isPreAuthDispatchDateDisabled()}
								value={_.get(
									this,
									['props', 'formdata', 'dispatch_date'],
									null
								)}
								onChange={(value) =>
									this.handleChange('dispatch_date', value)
								}
								onBlur={(value) =>
									this.onHandleDateFieldValidate(
										'dispatch_date',
										value
									)
								}
								disabledDate={(current) => {
									const today = moment();
									const _preauthDecisionDate =
										this.getPreauthDecisionDate();

									if (!_.isNil(_preauthDecisionDate)) {
										return (
											current.isSameOrBefore(
												today,
												'day'
											) &&
											current.isSameOrAfter(
												_preauthDecisionDate,
												'day'
											)
										);
									} else {
										return current.isSameOrBefore(
											today,
											'day'
										);
									}
								}}
								makeCalendarOnly={(event) => {
									if (
										event.keyCode == 8 ||
										event.keyCode == 46
									) {
										return true;
									} else {
										event.preventDefault();
									}
								}}
							/>
						</Layout.Col>
					</Layout.Row>

					{_.get(this, ['props', 'testRequestMode'], '') ===
						'edit' && (
						<Layout.Row className='icd-10-btn-wrapper'>
							<Layout.Col span='24'>
								ICD-10 Code(s) (Pre-Auth)
							</Layout.Col>
							<Layout.Col
								span='24'
								className='icd-10-btn-container'
							>
								{this.renderICDCodes()}
							</Layout.Col>
						</Layout.Row>
					)}
				</section>
			</Fragment>
		);
	}
}

export default PreAuthTestRequestLabInfo;
