//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import threeDotMenu from './../../assets/threedot.svg';

//Lodash
import get from 'lodash/get';

//Utils
import { buildCsvStrFromArr, turnUTCIntoLocalDateTime } from './../../utils.js';

//UI Libraries
import { Checkbox, Dropdown } from 'gm-element-react';

//Components
import { Tr, Td } from '../Common/Table';
import ThreeDotsMenu from '../Common/controls//ThreeDotsMenu';

//Other Libraries
import classnames from 'classnames';

const TemplateRow = (props) => {
	const {
		getRefProgNameFromUuid,
		getTemplateOwnerUuids,
		handleTemplateSelectionChange,
		openReferralProgramModal,
		selectedTemplates = [],
		template = {},
		templateIsArchived,
		templateIsDraft,
	} = props;

	const determineIsChecked = () => {
		return selectedTemplates.some((el) => el === get(template, 'uuid', ''));
	};

	const renderReferralPrograms = () => {
		const referralProgramUuids = getTemplateOwnerUuids(template);
		const referralProgramNames = [];
		if (Array.isArray(referralProgramUuids)) {
			referralProgramUuids.forEach((el) =>
				referralProgramNames.push(getRefProgNameFromUuid(el))
			);
		}
		return (
			<div className='templateTableReferralProgramsCell'>
				<span
					className='templateTableReferralProgramsText'
					onClick={(e) =>
						openReferralProgramModal(
							referralProgramNames,
							get(template, 'file_name', '--') || '--',
							template
						)
					}
				>
					{get(template, 'all_partners', null)
						? 'All'
						: buildCsvStrFromArr(referralProgramNames.sort())}
				</span>
			</div>
		);
	};

	const renderMenuOrDisabled = () => {
		if (templateIsArchived(template)) {
			return <ThreeDotsMenu disabled />;
		} else {
			return <ThreeDotsMenu options={renderOptions()} />;
		}
	};

	const handleCommand = (command) => {
		if (typeof get(props, `${command}`, null) === 'function') {
			const template = get(props, 'template', {}) || {};
			if (get(template, 'uuid', ''))
				props[command](get(template, 'uuid', ''));
		}
	};

	const renderOptions = () => {
		const options = [];
		if (canEditTemplate()) {
			options.push({
				label: 'Edit',
				click: (e) => handleCommand('openEditTemplateModal'),
			});
		}
		if (canDuplicateTemplate()) {
			options.push({
				label: 'Duplicate',
				click: (e) => handleCommand('openDuplicateModal'),
			});
		}
		if (canArchiveTemplate()) {
			options.push({
				label: 'Archive',
				click: (e) => handleCommand('openArchiveModal'),
			});
		}
		if (canFinalizeTemplate()) {
			options.push({
				label: 'Finalize Draft',
				click: (e) => handleCommand('openFinalizeDraftModal'),
			});
		}
		if (canEditReferralPrograms()) {
			options.push({
				label: 'Edit Referral Programs',
				click: (e) => handleCommand('openReferralProgramEditModal'),
			});
		}
		if (canChangeTemplateType()) {
			options.push({
				label: 'Change Template Type',
				click: (e) => handleCommand('openChangeTemplateTypeModal'),
			});
		}
		return options;
	};

	const canEditTemplate = () => {
		return !props.templateIsArchived(template);
	};

	const canDuplicateTemplate = () => {
		return !props.templateIsArchived(template);
	};

	const canArchiveTemplate = () => {
		return !props.templateIsArchived(template);
	};

	const canFinalizeTemplate = () => {
		return props.templateIsDraft(template);
	};

	const canEditReferralPrograms = () => {
		return !props.templateIsArchived(template);
	};

	const canChangeTemplateType = () => {
		return !props.templateIsArchived(template);
	};

	return (
		<Tr>
			<Td minWidth='56px' maxWidth='56px'>
				<Checkbox
					checked={determineIsChecked()}
					onChange={(isChecked) =>
						handleTemplateSelectionChange(isChecked, template)
					}
					inputProps={{ 'aria-label': template.uuid }}
				/>
			</Td>
			<Td
				minWidth='320px'
				maxWidth='420px'
				className={classnames({
					templateRowDraftName: templateIsDraft(template),
					templateRowArchiveName: templateIsArchived(template),
				})}
			>
				{get(template, 'file_name', null)}
			</Td>
			<Td maxWidth='224px'>
				{get(template, 'document_type.display_name', null)}
			</Td>
			<Td maxWidth='144px'>
				<p>
					{turnUTCIntoLocalDateTime(
						get(template, 'created_at', ''),
						'MMM D, YYYY'
					)}
				</p>
				<p className='sub'>
					{turnUTCIntoLocalDateTime(
						get(template, 'created_at', ''),
						'hh:mm A'
					)}
				</p>
			</Td>
			<Td maxWidth='144px'>
				<p>
					{turnUTCIntoLocalDateTime(
						get(template, 'updated_at', ''),
						'MMM D, YYYY'
					)}
				</p>
				<p className='sub'>
					{turnUTCIntoLocalDateTime(
						get(template, 'updated_at', ''),
						'hh:mm A'
					)}
				</p>
			</Td>
			<Td minWidth='232px' maxWidth='332px'>
				{renderReferralPrograms()}
			</Td>
			<Td minWidth='unset' maxWidth='unset'>
				{renderMenuOrDisabled()}
			</Td>
		</Tr>
	);
};

export default TemplateRow;
