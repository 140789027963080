//React & Redux
import React, { useEffect, useState } from 'react';

//Images
import ReactSVG from 'react-svg';
import xModalIcon from './../../../../assets/close.svg';

//Utils
import {
	documentDescriptionLengthValidator,
	documentDescripitonRequiredValidation,
	documentDescriptionValidation,
	documentTypeIsAdminMisc,
	documentTypeIsVisit,
	FINALIZE,
} from '../../../../Utils/documentUtils';

//UI Libraries
import { Loading, Select, Cascader, Input } from 'gm-element-react';

const FinalizeAmendDocModal = (props) => {
	const {
		closeFinalizeAmendDocument,
		description: document_description = '',
		document_subtype = {},
		document_type = {},
		documentTypes = [],
		documentTypesLoading,
		encounter_uuid = '',
		encounterStringDisplay,
		encounters = [],
		finalizeAmendDoc,
		getDocumentTypes,
		source,
	} = props;

	const [documentType, setDocumentType] = useState(document_type);
	const [documentSubType, setDocumentSubType] = useState(document_subtype);
	const [relatedEncounter, setRelatedEncounter] = useState(encounter_uuid);
	const [description, setDescription] = useState(document_description);

	useEffect(() => {
		getDocumentTypes();
	}, []);

	const setValue = (e, type) => {
		switch (type) {
			case 'encounter':
				setRelatedEncounter(e);
				break;
			case 'document':
				setDocumentType(e[0] || null);
				setDocumentSubType(e[1] || null);
				break;
			case 'description':
				setDescription(e);
		}
	};

	const errorCheck = () => {
		let errors = false;
		const currentSet = new Set();

		if (!documentType) {
			currentSet.add('documentType');
			errors = true;
		}

		if (getDocumentTypeIsVisit() && !documentSubType) {
			errors = true;
		}

		if (!relatedEncounter && getDocumentTypeIsVisit()) {
			currentSet.add('relatedEncounter');
			errors = true;
		}

		if (getDocumentTypeIsAdminMisc()) {
			if (!documentDescripitonRequiredValidation(description)) {
				currentSet.add('description');
				errors = true;
			} else if (!documentDescriptionLengthValidator(description)) {
				currentSet.add('description');
				errors = true;
			} else {
			}
		}
		if (description && !documentDescriptionValidation(description)) {
			currentSet.add('description');
			errors = true;
		}
		return errors;
	};

	const showErrorOnDocumentType = () =>
		getDocumentTypeIsVisit() && !documentSubType;

	const determineDescritionErrorMessage = () => {
		if (getDocumentTypeIsAdminMisc()) {
			if (!documentDescripitonRequiredValidation(description)) {
				return null;
			} else if (!documentDescriptionLengthValidator(description)) {
				return (
					<div className='doc-type-error'>
						Description should not be more than 40 characters.{' '}
					</div>
				);
			}
		}

		if (description && !documentDescriptionValidation(description)) {
			return <div className='doc-type-error'>Invalid description. </div>;
		}

		return null;
	};

	const getDocType = () =>
		documentTypes.filter((type) => type.value !== 'unassigned');

	const processEncounters = () =>
		encounters.filter((el) => {
			if ((el.type || '') !== 'research-data') {
				return (
					!(el.visit_status || '').includes('cancelled') &&
					(el.visit_status || '') !== 'no_show'
				);
			} else {
				return false;
			}
		});

	const getDocumentTypeIsAdminMisc = () =>
		documentTypeIsAdminMisc(documentType);
	const getDocumentTypeIsVisit = () => documentTypeIsVisit(documentType);

	const onSubmit = () => {
		const payload = {
			document_type: documentType,
			encounter_uuid: relatedEncounter,
			description,
		};
		if (getDocumentTypeIsVisit()) {
			payload['document_subtype'] = documentSubType;
		}
		if (!errorCheck()) {
			finalizeAmendDoc(payload);
		}
	};

	const docTypes = getDocType();
	const documentTypeValue = [documentType, documentSubType].filter(
		(el) => el
	);
	const selectValues = [
		{
			header: 'Document type',
			type: 'document',
			required: true,
			options: docTypes,
			placeholder: 'Select document type',
			value: documentTypeValue,
			component: 'select',
		},
		{
			header: 'Related encounter',
			type: 'encounter',
			required: getDocumentTypeIsVisit() ? true : false,
			options: processEncounters(),
			placeholder: 'Select encounter',
			value: relatedEncounter,
			component: 'select',
		},
		{
			header: getDocumentTypeIsAdminMisc()
				? 'Description (visible to patients upon document release)'
				: 'Description',
			type: 'description',
			required: getDocumentTypeIsAdminMisc() ? true : false,
			placeholder: 'Select encounter',
			value: description,
			component: 'textarea',
		},
	];

	const uploadBtnClass = !errorCheck()
		? ''
		: ' finalize-amend-doc-button-inactive';
	const cascaderClass =
		'createDocumentInputSelect createDocumentV2TypeSelect';

	return (
		<div className='fullModalBackground'>
			<div className='finalize-amend-doc-container'>
				{documentTypesLoading ? (
					<Loading loading={true} style={{ position: 'unset' }} />
				) : (
					<>
						<div className='finalize-amend-doc-header'>
							<div>
								{source == FINALIZE ? 'Finalize' : 'Amend'}{' '}
								Document
							</div>
							<ReactSVG
								src={xModalIcon}
								className='singleSelectModalXIcon finalize-amend-doc-headerXIcon'
								onClick={closeFinalizeAmendDocument}
							/>
						</div>

						<div className='finalize-amend-doc-body'>
							{selectValues.map((obj) => (
								<div
									className='finalize-amend-doc-input-container'
									key={obj.header}
								>
									{obj.required && (
										<div style={{ position: 'relative' }}>
											<div className='finalize-amend-doc-required'>
												*
											</div>
										</div>
									)}
									<div className='finalize-amend-doc-input-header'>
										{obj.header}
									</div>
									{(obj.type || null) === 'encounter' ? (
										<Select
											className={
												'createDocumentInputSelect'
											}
											placeholder={
												obj.placeholder || 'Select'
											}
											value={obj.value}
											onChange={(e) => {
												setValue(e, obj.type);
											}}
										>
											{obj.options.map((el) => (
												<Select.Option
													label={encounterStringDisplay(
														el
													)}
													value={`${el.uuid}`}
													key={`${el.uuid}`}
												>
													{encounterStringDisplay(el)}
												</Select.Option>
											))}
										</Select>
									) : (obj.type || null) === 'description' ? (
										<>
											<Input
												type='textarea'
												name='document-description'
												autosize={false}
												placeholder='Enter a description'
												value={obj.value}
												onChange={(e) =>
													setValue(e, obj.type)
												}
											/>
											{determineDescritionErrorMessage()}
										</>
									) : (
										<>
											<Cascader
												className={cascaderClass}
												placeholder={
													'Select document type'
												}
												value={obj.value}
												onChange={(e) => {
													setValue(e, obj.type);
												}}
												options={obj.options || []}
												expandTrigger={'hover'}
											/>
											{showErrorOnDocumentType() && (
												<div className='doc-type-error'>
													{' '}
													Please choose subtype
												</div>
											)}
										</>
									)}
								</div>
							))}
							<span className='finalize-amend-warn-msg'>
								Please confirm or adjust the document details
								before{' '}
								{source == FINALIZE ? 'finalizing' : 'amending'}{' '}
								the document
							</span>
						</div>

						<div className='finalize-amend-doc-buttonrow'>
							<div
								className='finalize-amend-doc-cancel'
								onClick={closeFinalizeAmendDocument}
							>
								Cancel
							</div>
							<div
								data-testId='finalize-amend-save'
								className={
									'finalize-amend-doc-save' + uploadBtnClass
								}
								onClick={onSubmit}
							>
								{source == FINALIZE ? 'Finalize' : 'Amend'}
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default FinalizeAmendDocModal;
