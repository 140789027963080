import * as actionTypes from '../actions/actionTypes';

export const searchkey = (state = '', action) => {
	if (action.type === actionTypes.GLOBAL_SEARCH) {
		const newState = Object.assign({}, state);
		newState.searchkey = action.payload;
		return newState;
	} else if (action.type === actionTypes.RESET_GLOBAL_SEARCH) {
		const newState = Object.assign({}, state);
		newState.searchkey = action.payload;
		return newState;
	} else {
		return state;
	}
};
