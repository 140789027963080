//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';

const labelstyle = {
	fontFamily: 'RionaSans-Bold',
	fontSize: '12px',
	fill: 'white',
	stroke: 'white',
	strokeWidth: '0',
	textAlign: 'right',
};
const valuestyle = {
	fontFamily: 'RionaSans',
	fontSize: '12px',
	fill: 'white',
	stroke: 'white',
	strokeWidth: '0',
};

const GraphToolTip = ({ axisselected, data, index, x, y }) => {
	const tooltip = {};
	const str =
		x -
		12 +
		',' +
		(y - 26) +
		' ' +
		(x + 10) +
		',' +
		(y - 26) +
		' ' +
		x +
		',' +
		(y - 11);
	if (axisselected.includes('get_started_with_no_results_count')) {
		const axisindex = _.indexOf(
			axisselected,
			'get_started_with_no_results_count'
		);
		tooltip['getStarted'] = data[axisindex][index].y;
	}
	if (axisselected.includes('get_started_with_ror_count')) {
		const axisindex = _.indexOf(axisselected, 'get_started_with_ror_count');
		tooltip['ror'] = data[axisindex][index].y;
	}
	if (axisselected.includes('others_count')) {
		const axisindex = _.indexOf(axisselected, 'others_count');
		tooltip['other'] = data[axisindex][index].y;
	}
	if (_.get(axisselected, 'length', 0) > 0) {
		tooltip['total'] =
			_.get(tooltip, 'getStarted', 0) +
			_.get(tooltip, 'ror', 0) +
			_.get(tooltip, 'other', 0);
	}

	return (
		<Fragment>
			<g>
				<rect
					x={x - 65}
					y={y - 142}
					rx='10'
					ry='10'
					width='126'
					height='120'
					fill='#262837'
				></rect>
				<text x={x - 65 + 48} y={y - 120} style={labelstyle}>
					Total
				</text>
				<text
					x={x - 65 + 90}
					y={y - 120}
					fontWeight='normal'
					style={valuestyle}
				>
					{_.get(tooltip, 'total', 0)}
				</text>
				<text x={x - 65 + 13} y={y - 100} style={labelstyle}>
					Get Started
				</text>
				<text x={x - 65 + 11} y={y - 85} style={labelstyle}>
					w/o Results
				</text>
				<text
					x={x - 65 + 90}
					y={y - 95}
					fontWeight='normal'
					style={valuestyle}
				>
					{_.get(tooltip, 'getStarted', 0)}
				</text>
				<text x={x - 65 + 54} y={y - 60} style={labelstyle}>
					RoR
				</text>
				<text
					x={x - 65 + 90}
					y={y - 60}
					fontWeight='normal'
					style={valuestyle}
				>
					{_.get(tooltip, 'ror', 0)}
				</text>
				<text x={x - 65 + 45} y={y - 40} style={labelstyle}>
					Other
				</text>
				<text
					x={x - 65 + 90}
					y={y - 40}
					fontWeight='normal'
					style={valuestyle}
				>
					{_.get(tooltip, 'other', 0)}
				</text>
				<polygon
					points={str}
					style={{
						fill: 'rgb(38, 40, 55)',
						stroke: 'rgb(38, 40, 55)',
						strokeWidth: '1',
					}}
				/>
			</g>
		</Fragment>
	);
};

export default GraphToolTip;
