import axios from 'axios';

class SessionService {
	session = () => {
		return axios.get(`/api/session`);
	};

	me = () => {
		return axios.get(`/api/me`);
	};

	permission = (activerole) => {
		return axios.get(`/api/me/permissions/emr/ui?role=${activerole}`);
	};

	activerole = () => {
		return axios.get(`/api/me/activerole`);
	};

	changerole = (role_uuid) => {
		return axios.post(`/api/me/activerole`, { role_uuid });
	};
	updateMe = (data) => {
		return axios.put(`/api/me`, data);
	};
}
export default new SessionService();
