//React & Redux
import React, { useState } from 'react';

//Other Libraries
import _ from 'lodash';
import moment from 'moment';

//Components
import OutreachGroupRecord from './OutreachGroupRecord';
import AddOutreach from '../../Patients/V2/Outreachs/AddOutreach';
import RoRFUDeclinedDialog from '../RoRFUDeclinedDialog';
import UnableToStopOutreachDialog from './UnableToStopOutreachDialog';

//Styles
import './OutreachRecord.css';

const OutreachGroupList = (props) => {
	const {
		addHandleSubmit,
		closeUnableStopOutreachModal,
		editHandleSubmit,
		gcRoROutreachStopReasonsEnums,
		geneTestOrderRequest,
		getLatestOutreachStatus,
		getList,
		handleFUDeclinedSubmit,
		outreachEnums = {},
		outreachList,
		outreachSetting,
		outreachStatusEnums = [],
		outreachType,
		patientDeclinedReasonsEnums,
		showUnableStopOutreachModal = false,
		tests,
	} = props;

	const { outcomes = [] } = outreachEnums;

	const [addOutreachDialog, setAddOutreachDialog] = useState(false);
	const [fuDeclinedDialog, setFuDeclinedDialog] = useState(false);
	const [encounterUUID, setEncounterUUID] = useState('');
	const [outcomeDetail, setOutcomeDetail] = useState(undefined);
	const [stopOutreachType, setStopOutreachType] = useState('');

	const getTestId = (testName) => {
		const filteredTest = tests?.data.filter(
			(el) => el.testName == testName
		);
		return _.get(filteredTest, ['0', 'testOrderUUID'], '');
	};

	const showAddOutreachDialog = (obj) => {
		setAddOutreachDialog(true);
		setOutcomeDetail({
			consultation: obj.consultationType,
			contact_method: 'phone',
			DOS: obj.DOS,
			date_time: moment(),
			encounter_uuid: obj.UUID,
			encounterSubType: obj.encounterSubType,
			encounterType: obj.encounterType,
			notes: null,
			outcome: null,
			outreachSequenceNo: obj.outreachSequenceNo,
			type: obj.communicationType,
			testName: obj.testName,
			testId: getTestId(obj.testName),
			roundId: obj.roundId,
			referralOutreachRoundStatus: obj.referralOutreachRoundStatus,
			referralOutreachRoundSubstatus: obj.referralOutreachRoundStatus,
		});
	};

	const showEditOutreachDialog = (obj) => {
		setAddOutreachDialog(true);
		setOutcomeDetail({
			consultation: obj.consultationType,
			contact_method: obj.medium,
			DOS: obj.DOS,
			date_time: obj.contactDateTime,
			encounter_uuid: obj.encounterUUID,
			encounterSubType: obj.encounterSubType,
			encounterType: obj.encounterType,
			notes: obj.notes,
			outcome: obj.outcome,
			outreachSequenceNo: obj.outreachSequenceNo,
			subtypeName: obj.subtypeName,
			type: obj.type,
			UUID: obj.communicationUUID,
			testName: obj.testName,
			testId: getTestId(obj.testName),
		});
	};

	const closeAddOutreachDialog = () => {
		setAddOutreachDialog(false);
		setOutcomeDetail(undefined);
	};

	const showFUDeclinedDialog = (type, encounter_uuid) => {
		setFuDeclinedDialog(true);
		setEncounterUUID(encounter_uuid);
		setStopOutreachType(type);
	};

	const closeFUDeclinedDialog = () => {
		setFuDeclinedDialog(false);
		setEncounterUUID('');
	};

	const isReferralOutreach = (obj) =>
		(obj?.communicationType || '') == 'referral_outreach' ||
		(obj?.communicationType || '') == 'patient_scheduled_appointment' ||
		(obj?.communicationType || '') == 'appointment_reminder' ||
		(obj?.communicationType || '') == 'welcome_sms';
	const isDelayedLabOutreach = (obj) =>
		(obj?.communicationType || '') == 'delayed_lab_outreach';

	const isDisplayPill = (encounterObj, groupObj) =>
		!isReferralOutreach(encounterObj) &&
		!(
			(groupObj?.outreachStatus || '') == 'not_started' &&
			(encounterObj?.holdResultRelease || '') != true
		) &&
		!_.isEmpty(groupObj?.outreachStatus || '');

	const isDisableStopOutreach = (outreachStatus) => {
		const outreachStatusEnum = {
			outreach_na: 1,
			not_started: 1,
			waiting_to_start: 1,
			scheduled_followup: 1,
			outreach_complete: 1,
			followup_declined: 1,
			'': 1,
		};

		return outreachStatus in outreachStatusEnum ? true : false;
	};

	const isDisableAddOutreach = (outreachStatus, encounterObj) => {
		const outreachStatusEnum = {
			not_started: 1,
			waiting_to_start: 1,
			'': 1,
		};

		return outreachStatus in outreachStatusEnum &&
			!isReferralOutreach(encounterObj) &&
			!isDelayedLabOutreach(encounterObj)
			? true
			: false;
	};

	const hasOutreachTypeAvailable = (arr, type) => {
		const res = arr.find((a) => (a?.communicationType || '') == type);
		return res && !_.isEmpty(res);
	};

	const filterOutreach = () => {
		const filteredOutreachList = [];

		if (_.isArray(outreachList)) {
			outreachList.map((encounterObj) => {
				const obj = { ...encounterObj };
				const groups = obj.groups.filter(
					(group) => group.outreachSequenceNo != 0
				);

				obj['groups'] = groups;

				if (!_.isEmpty(groups)) filteredOutreachList.push(obj);
			});
		}

		return filteredOutreachList;
	};

	const emptyOutreachObject = (arr) => {
		const obj = {};

		if (
			outreachType &&
			outreachType == 'outreachTab' &&
			!(
				hasOutreachTypeAvailable(arr, 'referral_outreach') ||
				hasOutreachTypeAvailable(
					arr,
					'patient_scheduled_appointment'
				) ||
				hasOutreachTypeAvailable(arr, 'appointment_reminder') ||
				hasOutreachTypeAvailable(arr, 'welcome_sms')
			)
		) {
			obj['name'] = 'Referral Outreach';
			obj['displayName'] = 'Referral Outreach';
			obj['isReferralOutreach'] = true;
			obj['data'] = [];
			obj['isAddOutreach'] = true;
			obj['isStopOutreach'] = false;
			obj['emptyReferralOutreachMsg'] =
				'We have not reached out to this patient yet';
			obj['communicationType'] = 'referral_outreach';
		} else if (
			outreachType &&
			outreachType == 'encounterDetail' &&
			!hasOutreachTypeAvailable(arr, 'ror_outreach')
		) {
			obj['name'] = 'RoR Outreach';
			obj['displayName'] = 'RoR Outreach';
			obj['isReferralOutreach'] = true;
			obj['data'] = [];
			obj['isAddOutreach'] = false;
			obj['isStopOutreach'] = false;
			obj['emptyReferralOutreachMsg'] =
				'Return of Results Outreach has not started yet.';
			obj['communicationType'] = 'ror_outreach';
		}

		return obj;
	};

	const getEmptyOutreachMessage = (encounterObj, groupObj) => {
		const str = groupObj?.outreachStatus || '';

		if (
			encounterObj.communicationType == 'ror_outreach' &&
			(str in { waiting_to_start: 1 } || str in { not_started: 1 })
		) {
			return 'Return of Results Outreach has not started yet.';
		} else if (
			encounterObj.communicationType === 'ror_outreach' &&
			str === 'in_progress' &&
			groupObj.communications.length === 0
		) {
			return 'We have not reached out to this patient yet';
		} else if (
			encounterObj.communicationType === 'ror_outreach' &&
			str === 'outreach_complete' &&
			groupObj.communications.length === 0
		) {
			return 'Outreach window completed with no RoR outreach attempts';
		} else if (
			encounterObj.communicationType === 'delayed_lab_outreach' &&
			groupObj.communications.length === 0
		) {
			return 'We have not reached out to this patient yet';
		} else if (
			encounterObj.communicationType === 'referral_outreach' &&
			groupObj.communications.length === 0
		)  {
			return 'Outreach Request Received';
		} else {
			return undefined;
		}
	};

	const getOutcomeDisplayName = (value) => {
		const obj = outcomes.find((a) => a.name == value);

		return obj ? obj?.display_name || '' : '--';
	};

	const getOutreachStatusDisplayName = (value) => {
		const obj = outreachStatusEnums.find((a) => a.key == value);

		return obj ? obj?.display_name || '' : '--';
	};

	const getTestNames = (encounterUUID, outreachSeqNo) => {
		const filteredTestOrders = geneTestOrderRequest.filter((obj) => {
			return (
				obj.encounterUUID == encounterUUID &&
				obj.outreachSeqNo == outreachSeqNo
			);
		});

		const testNamesArr = filteredTestOrders.map((obj) => obj.testName);
		return _.join(testNamesArr, ', ');
	};

	const getOutreachList = () => {
		const result = [];
		const outreachList = filterOutreach();
		const emptyOutreachObj = emptyOutreachObject(outreachList);

		outreachList.map((encounterObj) => {
			const ascGroupList = _.orderBy(
				encounterObj.groups || [],
				(o) => o.outreachSequenceNo,
				['desc']
			);

			return ascGroupList.map((groupObj) => {
				const obj = {};

				let referralOutreachDisplayname = 'Referral Outreach';
				let referralOutreachSubTitle = '';

				if (
					isReferralOutreach(encounterObj) &&
					groupObj.outreachRoundSeq != 0
				) {
					const specialty = _.get(
						groupObj,
						'outreachRound.specialty',
						''
					);
					const consultation = _.get(
						groupObj,
						'outreachRound.consultation',
						''
					);

					if (specialty && consultation) {
						referralOutreachDisplayname = `Referral Outreach ${groupObj.outreachRoundSeq} - ${specialty} | ${consultation}`;
					} else if (specialty) {
						referralOutreachDisplayname = `Referral Outreach ${groupObj.outreachRoundSeq} - ${specialty}`;
					} else if (consultation) {
						referralOutreachDisplayname = `Referral Outreach ${groupObj.outreachRoundSeq} - ${consultation}`;
					}

					const tzone = moment.tz.guess();
					const createdAtMoment = moment
						.utc(groupObj.outreachRound.created_at)
						.tz(tzone);
					const createdAt = createdAtMoment.isValid()
						? createdAtMoment.format('MM/DD/YYYY, hh:mm a z')
						: groupObj.outreachRound.created_at;

					referralOutreachSubTitle = `Initiated by: ${groupObj.outreachRound.created_by} | ${createdAt}`;
				}

				obj['displayName'] = !isReferralOutreach(encounterObj)
					? groupObj.outreachStatus == 'not_started' &&
						encounterObj.holdResultRelease != true
						? `RoR Outreach`
						: isDelayedLabOutreach(encounterObj)
							? 'Delayed Lab Outreach'
							: `RoR Outreach Round ${groupObj.outreachSequenceNo}`
					: referralOutreachDisplayname;
				obj['referralOutreachSubTitle'] = referralOutreachSubTitle;
				obj['roundId'] = _.get(groupObj, 'outreachRound.id');
				obj['referralOutreachRoundStatus'] = _.get(
					groupObj,
					'outreachRound.status'
				);
				obj['referralOutreachRoundSubstatus'] = _.get(
					groupObj,
					'outreachRound.substatus'
				);
				obj['outreachStatus'] = groupObj.outreachStatus || '';
				obj['outreachStatusDisplayName'] = getOutreachStatusDisplayName(
					groupObj.outreachStatus || ''
				);
				obj['data'] = filterOutreachGroupList(
					groupObj.communications,
					isReferralOutreach(encounterObj)
				);
				obj['isAddOutreach'] = true;
				obj['isStopOutreach'] =
					!isReferralOutreach(encounterObj) &&
					!isDelayedLabOutreach(encounterObj) &&
					!(
						(groupObj?.outreachStatus || '') in
						{ followup_declined: 1, outreach_stopped: 1 }
					);
				obj['communicationType'] = encounterObj.communicationType || '';
				obj['encounterType'] = encounterObj.encounterType;
				obj['encounterSubType'] = encounterObj.encounterSubType || '';
				obj['consultationType'] = encounterObj.consultationType;
				obj['DOS'] = encounterObj.DOS;
				obj['outreachSequenceNo'] = groupObj.outreachSequenceNo;
				obj['UUID'] = encounterObj.UUID;
				obj['holdResultRelease'] =
					encounterObj.holdResultRelease || false;
				obj['outreachTimeToStart'] =
					encounterObj.outreachTimeToStart || null;
				obj['testNames'] = getTestNames(
					encounterObj.UUID,
					groupObj.outreachSequenceNo
				);
				obj['isDisplayPill'] = isDisplayPill(encounterObj, groupObj);
				obj['isDisableStopOutreach'] = isDisableStopOutreach(
					groupObj.outreachStatus || ''
				);
				obj['isDisableAddOutreach'] = isDisableAddOutreach(
					groupObj.outreachStatus || '',
					encounterObj
				);
				obj['emptyReferralOutreachMsg'] = getEmptyOutreachMessage(
					encounterObj,
					groupObj
				);
				obj['testId'] = groupObj.testId;
				obj['testName'] = groupObj.testName;
				if (!isDelayedLabOutreach(encounterObj)) {
					result.push(obj);
				}
			});
		});

		if (!_.isEmpty(emptyOutreachObj)) result.push(emptyOutreachObj);

		return result;
	};

	const filterOutreachGroupList = (arr, isReferralOutreach) => {
		let ascOutreachList = arr;
		let hasOutreachDisabled = null;

		if (isReferralOutreach) {
			hasOutreachDisabled = (ascOutreachList || []).find(
				(a) => a.subtypeName == 'referral_outreach_disabled'
			);
			ascOutreachList = _.orderBy(arr, (o) => moment(o.contactDateTime), [
				'asc',
			]);

			if (
				hasOutreachDisabled &&
				_.get(outreachSetting, 'enabled') == false
			) {
				ascOutreachList = _.remove(ascOutreachList, (obj) => {
					return (
						obj.communicationUUID !=
						hasOutreachDisabled.communicationUUID
					);
				});
			}
		} else {
			ascOutreachList = _.orderBy(arr, (o) => moment(o.contactDateTime), [
				'asc',
			]);
		}

		let referralOutreachNum = 0;
		const listWithDisplayName = _.map(ascOutreachList, (obj, i) => {
			const tempobj = { ...obj };
			tempobj['outcomeDisplayName'] = getOutcomeDisplayName(
				_.get(obj, ['outcome'], '')
			);
			if (
				_.get(obj, ['type'], '') == 'referral_outreach' &&
				obj.subtypeName != 'referral_outreach_disabled' &&
				obj.subtypeName != 'referral_outreach_enabled'
			) {
				referralOutreachNum = referralOutreachNum + 1;
				tempobj['outreachDisplay'] =
					`Referral Outreach ${referralOutreachNum}`;
			} else if (_.get(obj, ['type'], '') == 'ror_outreach') {
				tempobj['outreachDisplay'] = `RoR Round ${_.get(
					obj,
					['outreachSequenceNo'],
					''
				)}, Outreach ${i + 1}`;
			} else if (_.get(obj, ['type'], '') == 'delayed_lab_outreach') {
				tempobj['outreachDisplay'] = `Outreach ${i + 1}`;
			} else {
				tempobj['outreachDisplay'] = '--';
			}

			tempobj['tempOrder'] = i++;
			return tempobj;
		});

		const descOutreachList = _.orderBy(
			listWithDisplayName,
			(o) => o && o.tempOrder,
			['desc']
		);
		if (hasOutreachDisabled && _.get(outreachSetting, 'enabled') == false) {
			const _hasOutreachDisabled = {
				...hasOutreachDisabled,
				doNotOutreachEnabled: _.get(outreachSetting, 'enabled'),
			};
			return [_hasOutreachDisabled, ...descOutreachList];
		}
		return [...descOutreachList];
	};

	return (
		<div className='outreach-wrapper'>
			{getOutreachList().map((obj) => (
				<OutreachGroupRecord
					key={obj.outreachSequenceNo}
					{...{
						getList,
						outreachGroupRecord: obj,
						outreachType,
						showAddOutreachDialog,
						showEditOutreachDialog,
						showFUDeclinedDialog,
					}}
				/>
			))}
			{addOutreachDialog && (
				<AddOutreach
					{...{
						addHandleSubmit,
						closeAddOutreachDialog,
						editHandleSubmit,
						outcomeDetail,
						outreachEnums,
						tests,
					}}
				/>
			)}
			{fuDeclinedDialog && (
				<RoRFUDeclinedDialog
					{...{
						encounterUUID,
						gcRoROutreachStopReasonsEnums,
						onCancel: closeFUDeclinedDialog,
						onSubmit: handleFUDeclinedSubmit,
						patientDeclinedReasonsEnums,
						stopOutreachType,
					}}
				/>
			)}
			{showUnableStopOutreachModal && (
				<UnableToStopOutreachDialog
					{...{
						closeUnableStopOutreachModal,
						getLatestOutreachStatus,
					}}
				/>
			)}
		</div>
	);
};

export default OutreachGroupList;
