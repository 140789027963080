//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Layout } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

const EncounterTestOrders = (props) => {
	const {
		bloodRequestEnums,
		getProviderImage,
		getProviderName,
		labEnums,
		testOrders,
	} = props;

	const getNameFromEnums = (enums, key) => {
		const enumObj = enums.find((o) => o.key == key);
		return _.get(enumObj, 'display_name', key);
	};

	const displayDate = (value) =>
		_.get(value, 'length', 0) > 0 && moment(value).isValid()
			? moment(value).format('MM/DD/YYYY')
			: '--';

	const getTestOrders = () =>
		testOrders.map((e, i) => (
			<Fragment key={e.genetic_test_display_name}>
				<Layout.Row gutter='20' style={{ marginBottom: '16px' }}>
					<Layout.Col span='24' style={{ paddingRight: 0 }}>
						<div className='test-number-container'>
							<div className='test-number'>
								Test Order #{i + 1}
							</div>
							<div className='divider'></div>
						</div>
					</Layout.Col>
				</Layout.Row>
				<Layout.Row gutter='20' style={{ marginBottom: '16px' }}>
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>Lab</span>
						<span className='val-enctr-sgmnt-field'>
							{_.get(e, 'lab.length', 0) > 0
								? getNameFromEnums(
										labEnums,
										_.get(e, 'lab', '--')
								  )
								: '--'}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>Dispatch Date</span>
						<span className='val-enctr-sgmnt-field'>
							{_.get(e, 'date_test_ordered.length', 0) > 0
								? displayDate(e.date_test_ordered)
								: '--'}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>Test Type</span>
						<span className='val-enctr-sgmnt-field'>
							{e.genetic_test_display_name || '--'}
						</span>
					</Layout.Col>
				</Layout.Row>
				<Layout.Row gutter='20' style={{ marginBottom: '24px' }}>
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>
							Blood Draw Request
						</span>
						<span className='val-enctr-sgmnt-field'>
							{_.get(e, 'blood_draw_request.length', 0) > 0
								? getNameFromEnums(
										bloodRequestEnums,
										_.get(e, 'blood_draw_request', '--')
								  )
								: '--'}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>
							Blood Draw Order Date
						</span>
						<span className='val-enctr-sgmnt-field'>
							{_.get(e, 'blood_draw_order_date.length', 0) > 0
								? displayDate(e.blood_draw_order_date)
								: '--'}
						</span>
					</Layout.Col>
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>
							Ordering Provider
						</span>
						<span className='val-enctr-sgmnt-field'>
							{getProviderImage(e.ordering_physician || '')}
							{getProviderName(e.ordering_physician || '')}
						</span>
					</Layout.Col>
				</Layout.Row>
			</Fragment>
		));

	return <>{testOrders && getTestOrders()}</>;
};

export default EncounterTestOrders;
