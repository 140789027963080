//React & Redux
import React from 'react';

//Images
import placeholderOutreach from '../../../assets/placeholderOutreach.svg';

//Styles
import './EmptyOutreachRecord.css';

const EmptyOutreachRecord = ({ message }) => {
	return (
		<div className='empty-outreach-record-wrapper'>
			<span className='log-empty-icon'>
				<img src={placeholderOutreach} alt='' />
			</span>
			<span className='outrch-content no-reach-content'>
				<span className='inner-outrch-content light-bold-font'>
					{message || 'We have not reached out to this patient yet'}
				</span>
			</span>
		</div>
	);
};

export default EmptyOutreachRecord;
