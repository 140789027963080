import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { duplicatePatientCheck } from '../../../../../actions/patients';

export const useDuplicatePatientCheck = () => {
	const dispatch = useDispatch();
	const duplicateCheckState = useSelector(
		(state) =>
			state.duplicatePatientCheck || {
				duplicatePatientCheckLoading: false,
				duplicatePatientCheckError: null,
				duplicatePatientCheck: null,
			}
	);

	const checkForDuplicatePatient = async (
		patientDemographicsAndContact,
		offset
	) => {
		const payload = {
			offset: offset,
			dob: moment
				.utc(patientDemographicsAndContact.dateOfBirth)
				.format('YYYY-MM-DD'),
			last_name: patientDemographicsAndContact.patientLastName,
			first_name: patientDemographicsAndContact.patientFirstName,
			is_unborn: false,
			...(patientDemographicsAndContact.noEmail
				? {}
				: { email: patientDemographicsAndContact.patientEmail }),
		};
		dispatch(duplicatePatientCheck(payload));
	};

	return {
		checkForDuplicatePatient,
		duplicateCheckState,
		isDuplicatePatient:
			duplicateCheckState.duplicatePatientCheck?.data?.length > 0,
	};
};
