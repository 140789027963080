//React & Redux
import React, { Fragment } from 'react';

//Images
import warningImage from './images/warning.svg';

//UI Libraries
import { Dialog, Button } from 'gm-element-react';

class AppointmentAlert extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Fragment>
				<Dialog
					className='reschedule-proceed-dialog appointment-alert'
					title={
						<Fragment>
							<img
								src={warningImage}
								alt=''
								className='warning-image'
								style={{
									marginRight: '12px',
									height: '24px',
									width: '24px',
								}}
							/>{' '}
							Appointment Alert
						</Fragment>
					}
					size='tiny'
					visible={true}
					onCancel={() => this.props.cancel()}
					lockScroll={false}
					showClose={false}
				>
					<Dialog.Body>
						<div role='note' className='reschedule-proceed-note'>
							For appointments within the next 8 hours, we
							recommend calling the Genome Medical Team directly
							at 877-688-0992 to schedule. We're here to assist
							you!
						</div>
					</Dialog.Body>
					<Dialog.Footer>
						<Button onClick={() => this.props.cancel()}>
							Cancel
						</Button>
						<Button
							onClick={() => this.props.save()}
							type='primary'
						>
							Continue anyway
						</Button>
					</Dialog.Footer>
				</Dialog>
			</Fragment>
		);
	}
}

export default AppointmentAlert;
