import React from 'react';
import get from 'lodash/get';
import ReactSVG from 'react-svg';

import breadcrumbArrow from '../../../../../assets/menu-breadcrumb.svg';

export default function (props) {
	const currentStep = get(props, ['currentStep'], null);

	if (!currentStep) return null;

	return (
		<div className='breadcrumbs'>
			<span
				className={`${
					currentStep === 'search-patient' ? 'active' : ''
				}`}
			>
				Search Patient
			</span>
			<ReactSVG src={breadcrumbArrow} className='gtIcon' />
			<span
				className={`${
					currentStep === 'select-encounter' ? 'active' : ''
				}`}
			>
				Select Encounter
			</span>
		</div>
	);
}
