//React & Redux
import React, { Fragment } from 'react';

//Images
import ReactSVG from 'react-svg';
import checkmarkIcon from '../../../../assets/checkmark.svg';
import moreinfoicon from '../../../../assets/more-info.svg';
import plusIcon from '../../../../assets/add.svg';
import trashIcon from '../../../../assets/delete.svg';
import xIcon from '../../../../assets/exit-alert.svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//UI Libraries
import { Checkbox, Loading, DatePicker } from 'gm-element-react';
import InputMask from 'react-input-mask';

//Other Libraries
import classnames from 'classnames';

//Components
import ProviderLinked from '../../../ExternalProviders/ProviderLinked';
import ProviderAddAnother from '../../../ExternalProviders/ProviderAddAnother';
import ErrorBoundary from './../../../Common/ErrorBoundary';
import CheckboxRow from './../../../Common/controls/CheckboxRow.js';
import CommonCheckbox from './../../../Common/Checkbox.js';
import PatientTag from './../../../Common/PatientTag.js';
import AddressCard from './../../../Common/AddressCard.js';
import Tooltip from '../../../Common/Tooltip';
import RadioButtonPill from '../../../Common/controls/RadioButtonPill';
import { SelectComponent as Select } from '../../../Common/SelectComponent';

class CreatePatientV2BodySection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			toggleEmailTooltip: false,
			loading: false,
			showAdditionalTags: false,
		};
		//bindings
		this.renderInputField = this.renderInputField.bind(this);
		this.handleToggleEmailTooltip =
			this.handleToggleEmailTooltip.bind(this);
		this.togglePatientTagsSwitch = this.togglePatientTagsSwitch.bind(this);
		this.determineTags = this.determineTags.bind(this);
		this.determineIsChecked = this.determineIsChecked.bind(this);
		this.determineSubValue = this.determineSubValue.bind(this);
		this.handleMultiselectCheckbox =
			this.handleMultiselectCheckbox.bind(this);
		this.renderCheckboxBottom = this.renderCheckboxBottom.bind(this);
		this.renderAddressSection = this.renderAddressSection.bind(this);
		this.renderDuplicateFields = this.renderDuplicateFields.bind(this);
		this.getExistingDuplicates = this.getExistingDuplicates.bind(this);
		this.getNewDuplicates = this.getNewDuplicates.bind(this);
	}
	//functions

	renderInputField(el) {
		const hasBooked = get(el, 'hasFutureBookedAppointments', false);
		const sectionInputField =
			'inputField patientCreateV2SectionInputFieldMargin';
		const textField = 'inputFieldText';
		const dropdownField =
			'inputFieldDropdown patientCreateV2SectionDropdownInputFieldWidth';
		const multiselectField = 'formMultiselect';
		const elementClassName = get(el, 'className', '');
		const errorClassText = get(el, 'error', null)
			? ' inputFieldTextErrorState'
			: '';
		const errorClassDropdown = get(el, 'error', null)
			? ' inputFieldDropdownError'
			: '';
		const errorClassCheckboxContainer = get(
			el,
			'duplicateRecordError',
			null
		)
			? ' checkboxRowContainerError'
			: '';
		const isExternalUser = get(this, 'props.isExternalUser', false);

		switch (get(el, 'type', '')) {
			case 'text':
				if (get(el, 'immutableCondition', null)) {
					return (
						<div
							className={`${sectionInputField} ${textField} patientCreateV2SectionTextInputFieldDisabled ${get(
								el,
								'immutableClass',
								''
							)}`}
						>
							{get(el, 'immutableValue', '--')}
						</div>
					);
				} else {
					return (
						<input
							placeholder={get(el, 'placeholder', '')}
							value={get(el, 'value', '')}
							onChange={(e) =>
								this.props.changeValue(
									get(el, 'header', null),
									get(e, 'target.value', '')
								)
							}
							className={`cp-input-element ${sectionInputField} ${textField} ${elementClassName} ${errorClassText}`}
							onFocus={() => {
								hasBooked &&
									this.handleToggleEmailTooltip(true);
							}}
							onBlur={() => {
								hasBooked &&
									this.handleToggleEmailTooltip(false);
							}}
						/>
					);
				}
			case 'textInputMask':
				return (
					<InputMask
						placeholder={get(el, 'placeholder', '')}
						value={get(el, 'value', '')}
						onChange={(e) =>
							this.props.changeValue(
								get(el, 'header', null),
								get(e, 'target.value', '')
							)
						}
						className={`cp-input-mask ${sectionInputField} ${textField} ${elementClassName} ${errorClassText}`}
						autoComplete='off'
						mask={
							get(el, 'inputMaskType', '') === 'date'
								? '99/99/9999'
								: '+1 (999) 999-9999'
						}
						maskChar={null}
					/>
				);
			case 'dropdown':
				return (
					<ErrorBoundary>
						<Select
							value={get(el, 'value', '')}
							onChange={(e) =>
								this.props.changeValue(
									get(el, 'header', null),
									e
								)
							}
							className={`cp-input-dropdown ${sectionInputField} ${dropdownField} ${elementClassName} ${errorClassDropdown}`}
							placeholder={get(el, 'placeholder', '')}
							typeahead={get(el, 'typeahead', false)}
							clearable={get(el, 'typeahead', false)}
							disabled={get(el, 'disabled', false)}
							options={get(el, 'options', []) || []}
						/>
					</ErrorBoundary>
				);
			case 'multiselect':
				return (
					<Select
						value={get(el, 'value', '') || []}
						onChange={(e) =>
							this.props.changeValue(get(el, 'header', null), e)
						}
						className={`cp-multi-select ${sectionInputField} ${multiselectField} ${dropdownField} ${elementClassName} ${errorClassDropdown}`}
						placeholder={get(el, 'placeholder', '')}
						multiple={true}
						typeahead={get(el, 'typeahead', false)}
						clearable={get(el, 'typeahead', false)}
						options={get(el, 'options', []) || []}
					/>
				);
			case 'datepicker':
				return (
					<DatePicker
						placeholder={get(el, 'placeholder', 'MM/DD/YYYY')}
						value={get(el, 'value', '')}
						onChange={(e) =>
							this.props.changeValue(get(el, 'header', null), e)
						}
						className={`cp-date-picker ${sectionInputField} ${textField} ${elementClassName}`}
						autoComplete='off'
						isReadOnly={false}
						format='MM/dd/yyyy'
					/>
				);
			case 'simpleCheckboxes': {
				const checkboxOptions = get(el, 'options', null) || [];
				const selectedOptions = get(el, 'value', null);
				if (Array.isArray(checkboxOptions)) {
					return checkboxOptions.map((option) => {
						const internalValue = get(option, 'internal', '');
						const checked = selectedOptions.includes(internalValue);
						return (
							<div
								key={internalValue}
								className='cp-simpleCheckboxes simpleCheckboxOption'
							>
								<CommonCheckbox
									checked={checked}
									onChangeFunc={(e) =>
										this.props.changeValue(
											get(el, 'header', null),
											this.handleMultiselectCheckbox(
												selectedOptions,
												internalValue,
												e
											)
										)
									}
									topOverride={-1}
									marginLeftOverride={0}
									marginRightOverride={11}
									marginBottomOverride={0}
								/>
								<div
									className='simpleCheckboxOptionLabel'
									style={{
										color: checked ? '#7b26c9' : '#262837',
									}}
								>
									{get(option, 'external', null) || '--'}
								</div>
							</div>
						);
					});
				}
				break;
			}
			case 'checkboxRows': {
				const options = get(el, 'options', null) || [];
				const selectedTags = get(el, 'value', null) || [];
				if (Array.isArray(options) && Array.isArray(selectedTags)) {
					const tags = this.determineTags(options);
					return tags.map((option, idx) => {
						const tagType = get(option, 'tagType', null);
						const hasDropdown = get(option, 'hasDropdown', false);
						const hasTextInput = get(option, 'hasTextInput', false);
						const checked = this.determineIsChecked(
							selectedTags,
							option
						);
						const subValue = this.determineSubValue(
							selectedTags,
							option
						);
						const hiddenForExternalUsers = get(
							option,
							'hiddenForExternalUsers',
							false
						);
						if (isExternalUser && hiddenForExternalUsers)
							return null;

						const getBottomEl = () => {
							switch (true) {
								case hasDropdown:
									return this.renderCheckboxBottom(
										option,
										subValue
									);
								case hasTextInput:
									return this.renderDuplicateFields();
								default:
									return null;
							}
						};

						return (
							<CheckboxRow
								key={idx}
								errorClass={errorClassCheckboxContainer}
								checked={checked}
								label={get(option, 'external', null)}
								onChangeFunc={(e) =>
									this.props.changeValue(tagType, e)
								}
								rightEl={
									<PatientTag
										tagType={tagType}
										customText={subValue || null}
									/>
								}
								bottomEl={getBottomEl()}
								expandedMainRowOverride={{
									paddingBottom: '6px',
								}}
							/>
						);
					});
				}
				break;
			}
			case 'simpleCheckboxRow': {
				const checked = get(el, 'value', false);
				const label = get(el, 'label', null) || '--';
				const header = get(el, 'header', null);
				const disabled = get(el, 'disabled', false);
				const description = get(el, 'description', null);
				const className = get(el, 'className', '');
				return (
					<Fragment>
						<CheckboxRow
							disabled={disabled}
							checked={checked}
							label={label}
							className={className}
							onChangeFunc={(e) =>
								this.props.changeValue(header, e)
							}
							containerStyleOverride={{ marginBottom: '24px' }}
						/>
						{description && (
							<div className={className}>{description}</div>
						)}
					</Fragment>
				);
			}
			case 'radioButtonPill':
				return (
					<RadioButtonPill
						className={classnames(
							'cp-radioButtonPill',
							get(el, 'className', '')
						)}
						options={get(el, 'options', [])}
						value={get(el, 'value', false)}
						onChange={(e) =>
							this.props.changeValue(get(el, 'header', null), e)
						}
					/>
				);
			default:
				console.log('Unrecognized type.');
		}
	}

	determineTags(options) {
		const showAdditionalTags = get(this, 'state.showAdditionalTags', false);
		if (showAdditionalTags) return options;
		else {
			return options.slice(0, 2);
		}
	}

	determineIsChecked(selectedTags, option) {
		return !!_.find(selectedTags, (tag) => {
			return get(tag, 'tagType', null) === get(option, 'tagType', '');
		});
	}

	determineSubValue(selectedTags, option) {
		const tag = _.find(selectedTags, (tag) => {
			return get(tag, 'tagType', null) === get(option, 'tagType', '');
		});
		return get(tag, 'subValue', null);
	}

	renderCheckboxBottom(option, subValue) {
		const assignedTagProperty = (option?.dropdownOptions || []).find(
			(item) => _.get(item, 'value') === subValue?.[0]
		);
		const options = Array.isArray(
			get(option, 'dropdownOptions', null) || []
		)
			? option.dropdownOptions
					.filter(
						(o) => o.value == assignedTagProperty?.value || o.in_use
					)
					.map((o) => ({
						value: o.value,
						label: o.value,
					})) // New format
			: option.map((o) => ({
					value: o,
					label: o,
				})); // Old format
		const header =
			get(option, 'tagType', null) === 'language_line_support'
				? 'Language'
				: get(option, 'external', null) || '--';
		const sectionInputField =
			'inputField patientCreateV2SectionInputFieldMargin';
		const dropdownField =
			'inputFieldDropdown patientCreateV2SectionDropdownInputFieldWidth';
		const multiselectField = 'formMultiselect';
		return (
			<div className='inputFieldContainer' style={{ marginLeft: '27px' }}>
				<div className='inputFieldRequired'>*</div>
				<div className='inputFieldHeader'>{header}</div>
				<div>
					<Select
						value={subValue?.[0]}
						onChange={(e) =>
							this.props.changeValue(
								get(option, 'tagType', '') + 'SubValue',
								e
							)
						}
						className={`cp-checkbox-bottom cp-cbb ${sectionInputField} ${multiselectField} ${dropdownField}`}
						placeholder={'Select'}
						typeahead={true}
						clearable={true}
						options={options}						
					/>
				</div>
			</div>
		);
	}

	getExistingDuplicates() {
		const header = 'UUID';
		const sectionInputField =
			'inputField patientCreateV2SectionInputFieldMargin';
		const textField = 'inputFieldText';
		const existingDuplicatesInput = 'existingDuplicatesInput';

		return Object.keys(this.props.existingDuplicatePatients).map((uuid) => {
			const active = this.props.existingDuplicatePatients[uuid];

			return (
				<div
					key={`existingUuid_${uuid}`}
					className='uuidInputContainer'
				>
					<div>
						<div className='inputFieldHeader'>{header}</div>
						<input
							value={uuid}
							disabled={true}
							className={`${sectionInputField} ${textField} ${existingDuplicatesInput}`}
						/>
					</div>
					{active ? (
						<ReactSVG
							className='cp-updateexisting updateExistingUuidSvgWrapper'
							onClick={() =>
								this.props.updateExistingUuid(uuid, false)
							}
							src={trashIcon}
						/>
					) : (
						<div
							className='cp-updateexisting updateExistingUuidUndoDelete'
							onClick={() =>
								this.props.updateExistingUuid(uuid, true)
							}
						>
							Undo Delete
						</div>
					)}
				</div>
			);
		});
	}

	getNewDuplicates() {
		const header = 'UUID';
		const sectionInputField =
			'inputField patientCreateV2SectionInputFieldMargin';
		const textField = 'inputFieldText';
		const pendingDuplicatesInput = 'pendingDuplicatesInput';

		return this.props.pendingDuplicatePatients.map((uuid, i) => {
			return (
				<div key={`pendingUuid_${i}`} className='uuidInputContainer'>
					<div>
						<div className='inputFieldHeader'>{header}</div>
						<input
							value={uuid}
							placeholder='Enter UUID'
							onChange={(e) =>
								this.props.handleChangePendingUuid(
									e.target.value,
									i
								)
							}
							className={`cp-duplicate-field ${sectionInputField} ${textField} ${pendingDuplicatesInput}`}
						/>
					</div>
					<ReactSVG
						className='updateExistingUuidSvgWrapper'
						onClick={() => this.props.removePendingUuid(i)}
						src={trashIcon}
					/>
				</div>
			);
		});
	}

	renderDuplicateFields() {
		return (
			<div className='inputFieldContainer' style={{ marginLeft: '27px' }}>
				{this.getExistingDuplicates()}
				{this.getNewDuplicates()}
				<div
					className='addAnotherUuidButton'
					onClick={this.props.addNewPendingUuid}
				>
					<ReactSVG
						className='addAnotherUuidSvgWrapper'
						src={plusIcon}
					/>
					<label>Add Another UUID</label>
				</div>
			</div>
		);
	}

	handleMultiselectCheckbox(selectedOptions, selection, boolean) {
		if (boolean) {
			return selectedOptions.concat(selection);
		} else {
			return selectedOptions.filter((el) => el != selection);
		}
	}

	handleToggleEmailTooltip = (status) => {
		this.setState({ toggleEmailTooltip: status || false });
	};

	isExternalProviderSection() {
		const extProviders = _.get(this.props, 'externalProviderProps', []);
		return !_.isEmpty(extProviders) ? true : false;
	}

	togglePatientTagsSwitch() {
		const showAdditionalTags = get(this, 'state.showAdditionalTags', false);
		this.setState({ showAdditionalTags: !showAdditionalTags });
	}

	renderAddressSection() {
		const addressData = get(this, 'props.addressData', null) || [];
		const hasAllTypes = get(this, 'props.hasAllAddressTypes', false);
		const addressErrors = get(this, 'props.addressErrors', null) || [];
		return (
			<React.Fragment>
				{Array.isArray(addressData) &&
					addressData.map((address, i) => {
						return (
							<AddressCard
								address={address}
								variant={'main'}
								action={'edit'}
								actionFunc={() =>
									this.props.openAddressModal(true, i)
								}
								key={get(address, 'type', null)}
								addressErrors={get(
									addressErrors,
									`[${i}]`,
									null
								)}
							/>
						);
					})}
				{!hasAllTypes && (
					<ProviderAddAnother
						title={
							get(addressData, 'length', 0)
								? 'Add Another Address'
								: 'Add Address'
						}
						click={() => this.props.openAddressModal(false)}
						disabled={get(this, 'props.hasAllAddressTypes', false)}
					/>
				)}
			</React.Fragment>
		);
	}

	render() {
		const sectionRequired = get(this, 'props.sectionRequired', false);
		const sectionRecommended = get(this, 'props.sectionRecommended', false);
		const showMedicareQuestion = get(
			this,
			'props.showMedicareQuestion',
			true
		);
		const hasCheckbox = get(this, 'props.hasCheckbox', false);
		const showAdditionalTags = get(this, 'state.showAdditionalTags', false);
		const arrowTilt = showAdditionalTags ? -90 : 90;
		const yesNoChoiceSelectedClass =
			'patientCreateV2SectionYesNoChoice' +
			' ' +
			'patientCreateV2SectionYesNoChoiceSelected';
		const yesNoChoiceUnselectedClass =
			'patientCreateV2SectionYesNoChoice' +
			' ' +
			'patientCreateV2SectionYesNoChoiceUnselected';
		const bottomBorderClass = get(this, 'props.noBottomBorder', false)
			? ' patientCreateV2SectionContainerNoBottomBorder'
			: '';
		let noChoiceClass = !get(this, 'props.yesNoChoice.selection', false)
			? yesNoChoiceSelectedClass
			: yesNoChoiceUnselectedClass;
		let yesChoiceClass = get(this, 'props.yesNoChoice.selection', false)
			? yesNoChoiceSelectedClass
			: yesNoChoiceUnselectedClass;
		if (get(this, 'props.canNotRemoveGuardian', false))
			noChoiceClass += ' patientCreateV2SectionYesNoChoiceDisabled';

		let noChoiceClass2 = yesNoChoiceUnselectedClass;
		let yesChoiceClass2 = yesNoChoiceUnselectedClass;
		if (null != get(this, 'props.yesNoChoice2.selection', null)) {
			noChoiceClass2 = !get(this, 'props.yesNoChoice2.selection', false)
				? yesNoChoiceSelectedClass
				: yesNoChoiceUnselectedClass;
		}
		yesChoiceClass2 = get(this, 'props.yesNoChoice2.selection', false)
			? yesNoChoiceSelectedClass
			: yesNoChoiceUnselectedClass;

		if (get(this, 'props.yesNoChoice.selection', false)) {
			yesChoiceClass2 += ' patientCreateV2SectionYesNoChoiceDisabled';
		}
		if (get(this, 'props.isSCPMode', false)) {
			if (get(this, 'props.yesNoChoice.selection') != false) {
				noChoiceClass += ' patientCreateV2SectionYesNoChoiceDisabled';
			}
			if (get(this, 'props.yesNoChoice.selection') != true) {
				yesChoiceClass += ' patientCreateV2SectionYesNoChoiceDisabled';
			}
			if (get(this, 'props.yesNoChoice2.selection') != false) {
				noChoiceClass2 += ' patientCreateV2SectionYesNoChoiceDisabled';
			}
			if (get(this, 'props.yesNoChoice2.selection') != true) {
				yesChoiceClass2 += ' patientCreateV2SectionYesNoChoiceDisabled';
			}
		}

		return (
			<div
				className={
					'patientCreateV2SectionContainer' + bottomBorderClass
				}
			>
				<div className='patientCreateV2SectionInformationSegment'>
					<div className='patientCreateV2SectionInformationTitle'>
						{get(this, 'props.title', '--')}
					</div>
					<div className='patientCreateV2SectionInformationDescription'>
						{get(this, 'props.description', '--')}
					</div>
					<div
						className='patientCreateV2SectionInformationRequired'
						style={{
							color: sectionRequired ? '#e74c3c' : '#7b8291',
						}}
					>
						{sectionRequired
							? 'Required'
							: sectionRecommended
							? 'Recommended'
							: 'Optional'}
					</div>
					{hasCheckbox && (
						<div className='patientCreateV2SectionInformationCheckbox'>
							<Checkbox
								checked={get(
									this,
									'props.checkboxSelection',
									false
								)}
								onChange={(e) =>
									this.props.changeValue(
										get(this, 'props.checkboxHeader'),
										e
									)
								}
								className='templateTableCheckbox'
							/>
							<div className='patientCreateV2SectionInformationCheckboxText'>
								{get(this, 'props.checkboxText', '--')}
							</div>
						</div>
					)}
				</div>
				<div className='patientCreateV2SectionInputSegment'>
					{get(this, 'props.yesNoChoice', false) && (
						<div className='patientCreateV2SectionYesNoContainer'>
							<div>
								{get(this, 'props.yesNoChoice.text', '--')}
							</div>
							<div className='patientCreateV2SectionYesNoChoices'>
								<div
									className={classnames(
										'cp-yesPill',
										yesChoiceClass
									)}
									onClick={() =>
										this.props.changeValue(
											get(
												this,
												'props.yesNoChoice.header',
												false
											),
											true
										)
									}
								>
									Yes
								</div>
								<div
									className={classnames(
										'cp-noPill',
										noChoiceClass
									)}
									onClick={() => {
										if (
											get(
												this,
												'props.canNotRemoveGuardian',
												false
											)
										)
											return;
										this.props.changeValue(
											get(
												this,
												'props.yesNoChoice.header',
												false
											),
											false
										);
									}}
								>
									No
								</div>
							</div>
						</div>
					)}
					{get(this, 'props.yesNoChoice2', false) &&
						showMedicareQuestion && (
							<div className='patientCreateV2SectionYesNoContainer'>
								<div style={{ display: 'flex' }}>
									{get(
										this,
										'props.yesNoChoice2.required',
										false
									) && <div className='required'>*</div>}
									{get(this, 'props.yesNoChoice2.text', '--')}
								</div>
								<div className='patientCreateV2SectionYesNoChoices'>
									<div
										className={classnames(
											'cp-yesPill2',
											yesChoiceClass2
										)}
										onClick={() =>
											this.props.changeValue(
												get(
													this,
													'props.yesNoChoice2.header',
													false
												),
												true
											)
										}
									>
										Yes
									</div>
									<div
										className={classnames(
											'cp-noPill2',
											noChoiceClass2
										)}
										onClick={() => {
											this.props.changeValue(
												get(
													this,
													'props.yesNoChoice2.header',
													false
												),
												false
											);
										}}
									>
										No
									</div>
								</div>
							</div>
						)}
					<div className='patientCreateV2SectionInputContainer'>
						<Fragment>
							{this.isExternalProviderSection() &&
								get(
									this.props,
									[
										'externalProviderProps',
										'externalProviders',
									],
									[]
								).map((el, index) => {
									return (
										<ProviderLinked
											key={index}
											provider={el}
											editLinkInfo={get(this.props, [
												'externalProviderProps',
												'editLinkInfo',
											])}
											editProviderInfo={get(this.props, [
												'externalProviderProps',
												'editProviderInfo',
											])}
											unlinkProviderInfo={get(
												this.props,
												[
													'externalProviderProps',
													'unlinkProviderInfo',
												]
											)}
											isExternalUser={get(
												this,
												'props.isExternalUser',
												false
											)}
											linkedType={'patientCreateEdit'}
											providerSpecialties={
												get(
													this,
													'props.providerSpecialties',
													null
												) || []
											}
											activeRole={this.props?.activeRole}
										/>
									);
								})}
							{this.isExternalProviderSection() ? (
								get(
									this,
									'props.externalProviderProps.externalProviders',
									[]
								).length > 0 ? (
									<ProviderAddAnother
										click={get(this.props, [
											'externalProviderProps',
											'handleProviderLinkDialog',
										])}
										title='Link Another Provider'
									/>
								) : (
									<ProviderAddAnother
										title='Link External Provider'
										click={get(this.props, [
											'externalProviderProps',
											'handleProviderLinkDialog',
										])}
									/>
								)
							) : null}
							{get(this, 'props.addressSection', false) &&
								this.renderAddressSection()}
							{(get(this, 'props.inputFields', []) || []).map(
								(el, idx) => {
									const isCheckboxType =
										get(el, 'type', null) in
										{
											checkboxRows: 1,
											simpleCheckboxRow: 1,
											simpleCheckboxes: 1,
											radioButtonPill: 1,
										};
									const containerStyling =
										get(
											el,
											'containerStylingOverride',
											null
										) || {};
									const fieldSubheaderLabel = get(
										el,
										'fieldSubHeader',
										null
									);
									let fieldSubHeader = '';
									if (fieldSubheaderLabel) {
										fieldSubHeader = (
											<span className='patientCreateV2SectionInputFieldSubHeader'>
												&nbsp;{fieldSubheaderLabel}
											</span>
										);
									}
									return (
										<Loading
											key={idx}
											loading={get(el, 'loading', false)}
											style={{
												width: isCheckboxType
													? '100%'
													: 'unset',
											}}
										>
											<div
												className='patientCreateV2SectionInputFieldContainer'
												style={{ ...containerStyling }}
											>
												{get(el, 'required', false) && (
													<div className='patientCreateV2RequiredField'>
														*
													</div>
												)}
												{get(
													el,
													'hasFutureBookedAppointments',
													false
												) && (
													<Tooltip
														visible={get(
															this,
															'state.toggleEmailTooltip',
															false
														)}
														content={
															'This patient has a booked appointment'
														}
														placement='top'
														className={get(
															el,
															'tooltipClass',
															''
														)}
														effect='dark'
														manual={true}
													>
														<img
															src={moreinfoicon}
															alt='more info'
															className='more-info'
															onMouseEnter={() => {
																this.handleToggleEmailTooltip(
																	true
																);
															}}
															onMouseLeave={() => {
																this.handleToggleEmailTooltip(
																	false
																);
															}}
														/>
													</Tooltip>
												)}
												<div className='patientCreateV2SectionInputFieldHeader'>
													{get(
														el,
														'fieldHeader',
														null
													)}
													{fieldSubHeader}
													{get(
														el,
														'fieldHeaderTooltip',
														false
													) && (
														<Tooltip
															content={
																get(
																	el,
																	'fieldHeaderTooltip',
																	false
																) || '--'
															}
															placement='top'
															className={
																'inputFieldHeaderInfoIconTooltip'
															}
															effect='dark'
														>
															<ReactSVG
																src={
																	moreinfoicon
																}
																alt='more info'
																className='more-info inputFieldHeaderInfoIcon'
															/>
														</Tooltip>
													)}
												</div>
												{this.renderInputField(el)}
												{get(el, 'error', null) && (
													<div className='patientCreateV2InputError'>
														{get(
															el,
															'error',
															'Invalid data'
														)}
													</div>
												)}
												{get(el, 'type', null) ===
													'checkboxRows' &&
													get(
														el,
														'duplicateRecordError',
														null
													) && (
														<div className='checkboxRowContainerError'>
															{get(
																el,
																'duplicateRecordError',
																null
															)}
														</div>
													)}
												{get(el, 'type', null) ===
													'checkboxRows' && (
													<div
														className='purpleText patientTagsSwitch'
														onClick={
															this
																.togglePatientTagsSwitch
														}
													>
														{showAdditionalTags
															? 'Hide'
															: 'Show'}{' '}
														Additional Flags
														<i
															className='material-icons switchArrow'
															style={{
																transform: `rotate(${arrowTilt}deg)`,
															}}
														>
															chevron_right
														</i>
													</div>
												)}
												{!get(el, 'error', null) &&
													get(
														el,
														'addAnotherUI',
														null
													) &&
													!get(
														el,
														'addAnotherUIActive',
														false
													) && (
														<div
															className='cp-add-another patientCreateV2InputAddAnother'
															onClick={
																this.props
																	.setReferringProviderIsNotDropdown
															}
														>
															+ Add another
														</div>
													)}
												{get(
													el,
													'addAnotherUI',
													null
												) &&
													get(
														el,
														'addAnotherUIActive',
														false
													) &&
													!get(
														el,
														'loading',
														false
													) && (
														<div className='patientCreateV2InputAddAnotherIcons'>
															<ReactSVG
																className={
																	(
																		get(
																			el,
																			'value',
																			''
																		) || ''
																	).trim()
																		? 'patientCreateV2InputAddAnotherIconCheck'
																		: 'patientCreateV2InputAddAnotherIconCheck patientCreateV2InputAddAnotherIconCheckDisabled'
																}
																src={
																	checkmarkIcon
																}
																onClick={() =>
																	this.props.addAnother(
																		'referringProvider'
																	)
																}
															/>
															<ReactSVG
																className='patientCreateV2InputAddAnotherIconX'
																src={xIcon}
																onClick={
																	this.props
																		.setReferringProviderIsDropdown
																}
															/>
														</div>
													)}
											</div>
										</Loading>
									);
								}
							)}
						</Fragment>
					</div>
				</div>
			</div>
		);
	}
}

export default CreatePatientV2BodySection;
