//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';

import _ from 'lodash';

//Components

import './warning-message-box.css';

const WarningMessageBox = (props) => {
	const boxCustomStyling = _.get(props, 'boxCustomStyling', null) || {};
	return (
		<div
			className={`warningbox-container ${props.boxBGColor}`}
			style={{ ...boxCustomStyling }}
		>
			<div className='warningbox-body'>
				<span className={`${props.extndWarnIcon}`}>
					<ReactSVG src={props.warningIcon} />
				</span>
				<span className={`${props.extndWarnText}`}>
					{props.warningMsg}
				</span>
			</div>
		</div>
	);
};

export default WarningMessageBox;
