import { Tooltip as ERTooltip } from 'gm-element-react';
import { isString, isNil } from 'lodash';
import classnames from 'classnames';
import React from 'react';

//Styles
import './Tooltip.scss';

interface IProps {
	className?: string;
	content?: string;
	contentOverflow?: boolean;
	disabled?: boolean;
	placement?: string;
	effect?: string;
	visibleArrow?: boolean;
	visible?: boolean;
	openDelay?: number;
	manual?: boolean;
	children?: React.ReactNode;
}

export const Tooltip: React.FC<IProps> = (props) => {
	const {
		content,
		contentOverflow,
		placement,
		effect,
		disabled,
		visibleArrow,
		visible,
		openDelay,
		manual,
		children,
	} = props;
	const isContentString = isString(content);
	/* eslint-disable */
	const contentStyle: any = {};
	if (isContentString && contentOverflow) {
		contentStyle.textOverflow = 'ellipsis';
		contentStyle.overflow = 'hidden';
		contentStyle.whiteSpace = 'nowrap';
	}
	return (
		<ERTooltip
			placement={placement ?? 'top'}
			className={classnames('GMITooltip', props.className)}
			effect={effect ?? 'dark'}
			content={
				isContentString ? (
					<div style={contentStyle}>{props.content}</div>
				) : (
					props.content
				)
			}
			disabled={disabled ?? false}
			visibleArrow={!isNil(visibleArrow) ? visibleArrow : true}
			visible={visible}
			openDelay={openDelay ?? 0}
			manual={manual ?? false}
		>
			{children}
		</ERTooltip>
	);
};
