//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//UI Libraries
import InputMask from 'react-input-mask';

//Component
import { SelectComponent as Select } from '../SelectComponent';

class Field extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.renderInputField = this.renderInputField.bind(this);
		this.determineWidthClass = this.determineWidthClass.bind(this);
	}
	//functions

	renderInputField() {
		const fieldData = get(this, 'props.fieldData', {}) || {};
		const fieldType = get(fieldData, 'type', null);
		const header = get(fieldData, 'header', null);
		const value = get(fieldData, 'value', null);
		const placeholder = get(fieldData, 'placeholder', null);
		const label = get(fieldData, 'label', null);
		const options = get(fieldData, 'options', []) || [];
		const maskType = get(fieldData, 'maskType', null);
		const internal = get(fieldData, 'internalKey', null);
		const external = get(fieldData, 'externalKey', null);
		const keyOverride = get(fieldData, 'keyOverride', null);
		const error = get(this, 'props.error', null);
		const errorClass = error ? 'inputFieldTextErrorState' : '';
		const typeahead = get(fieldData, 'typeahead', false);
		const clearable = get(fieldData, 'clearable', false);
		switch (fieldType) {
			case 'text':
				return (
					<input
						className={`inputField inputFieldText ${errorClass}`}
						style={{ width: '100%' }}
						onChange={(e) =>
							this.props.fieldChange(
								header,
								get(e, 'target.value', '')
							)
						}
						value={value || ''}
						placeholder={placeholder || label}
					/>
				);
			case 'textMask':
				if (maskType === 'phone') {
					return (
						<InputMask
							placeholder={placeholder || label}
							value={value || ''}
							onChange={(e) =>
								this.props.fieldChange(
									header,
									get(e, 'target.value', '')
								)
							}
							className={`inputField inputFieldText ${errorClass}`}
							style={{ width: '100%' }}
							autoComplete='off'
							mask={'+1 (999) 999-9999'}
							maskChar={null}
						/>
					);
				}
			case 'singleSelect':
				return (
					<Select
						className={`inputField inputFieldDropdown`}
						onChange={(e) => this.props.fieldChange(header, e)}
						value={value || ''}
						placeholder={placeholder || 'Select'}
						typeahead={typeahead}
						clearable={clearable}
						options={options}
						keyOverride={keyOverride}
					/>
				);
			case 'multiselect':
				return (
					<Select
						className={`inputField inputFieldDropdown formMultiselect`}
						style={{ width: '100%' }}
						onChange={(e) => this.props.fieldChange(header, e)}
						value={value}
						placeholder={placeholder || 'Select'}
						multiple={true}
						typeahead={typeahead}
						clearable={clearable}
						options={options || []}
						style={{ width: '100%' }}
					/>
				);
			case 'singleSelectPill':
				return (
					<div className='selectPillFormContainer'>
						{Array.isArray(options) &&
							options.map((el) => {
								const isSelected = get(el, 'key', '') === value;
								const pillClassName = isSelected
									? 'singleSelectPill singleSelectPillSelected'
									: 'singleSelectPill singleSelectPillUnselected';
								const key = get(el, 'key', null);
								return (
									<div
										className={pillClassName}
										onClick={(e) =>
											this.props.fieldChange(
												header,
												isSelected ? null : key
											)
										}
										key={key}
									>
										{get(el, 'display_name', null) || ''}
									</div>
								);
							})}
					</div>
				);
			case 'textArea':
				return (
					<textarea
						className={`inputField inputFieldTextArea`}
						style={{ width: '100%' }}
						onChange={(e) =>
							this.props.fieldChange(
								header,
								get(e, 'target.value', '')
							)
						}
						value={value || ''}
						placeholder={placeholder || label}
					/>
				);
			default:
				console.log('Unrecognized field type.');
				break;
		}
	}

	determineWidthClass() {
		const width = get(this, 'props.fieldData.quartersWidth', 2) || 2;
		switch (width) {
			case 1:
				return 'oneQuarterWidth';
			case 2:
				return 'twoQuarterWidth';
			case 3:
				return 'threeQuarterWidth';
			case 4:
				return 'fourQuarterWidth';
			default:
				console.log('Unrecognized width');
		}
	}

	render() {
		const containerClassname = `inputFieldContainer ${this.determineWidthClass()}`;
		const required = get(this, 'props.fieldData.required', false);
		const recommended = get(this, 'props.fieldData.recommended', false);
		const fieldData = get(this, 'props.fieldData', {}) || {};
		const customStyling =
			get(this, 'props.fieldData.customStyling', null) || {};
		const marginLeft = get(fieldData, 'marginLeft', null);
		const error = get(this, 'props.error', null);
		return (
			<div
				className={containerClassname}
				style={{
					marginLeft: marginLeft ? '16px' : '0px',
					...customStyling,
				}}
			>
				{required && <div className='inputFieldRequired'>*</div>}
				{recommended && <div className='inputFieldRecommended'>*</div>}
				<div className='inputFieldHeader'>
					{get(fieldData, 'label', '') || '--'}
				</div>
				{this.renderInputField()}
				{error && <div className='inputFieldError'>{error}</div>}
			</div>
		);
	}
}

export default Field;
