//React & Redux
import React, { useEffect, useRef } from 'react';

//Images
import TagSearchResultsRow from './TagSearchResultsRow.js';

const TagSearchResults = (props) => {
	const {
		handleInputChange,
		icdCodes = [],
		price,
		scrollHandler,
		selectedICDCodes = [],
		setBodyElement,
		toggleCodeSelection,
	} = props;

	const resultsContainerRef = useRef(null);

	useEffect(() => {
		resultsContainerRef.current &&
			setBodyElement(resultsContainerRef.current);
	}, []);

	const determineIsSelected = (id) =>
		!!selectedICDCodes.filter((el) => (el.partner_id || '') === id).length;

	return (
		<div
			className='chargeICDSearchResultsContainer'
			ref={resultsContainerRef}
			onScroll={() => scrollHandler(['top', 'bottom'])}
		>
			{icdCodes.map((el) => {
				return (
					<TagSearchResultsRow
						{...{
							key: el.uuid,
							handleInputChange,
							icdCode: el,
							isSelected: determineIsSelected(el.id || ''),
							price,
							selectedICDCodes,
							toggleCodeSelection,
						}}
					/>
				);
			})}
		</div>
	);
};

export default TagSearchResults;
