//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

class ButtonRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.handleSave = this.handleSave.bind(this);
	}
	//functions

	handleSave() {
		const canSave = get(this, 'props.canSave', false);
		const ctaFunc = get(this, 'props.CTAFunc', null);
		if (canSave && typeof ctaFunc === 'function') {
			this.props.CTAFunc();
		}
	}

	render() {
		const buttonClass = get(this, 'props.canSave', false)
			? 'modalButton'
			: 'modalButton modalButtonDisabled';
		const buttonText = get(this, 'props.buttonText', null) || 'Save';
		return (
			<div className='externalProviderModalButtonRow'>
				<div
					data-testid='buttonRow'
					className={buttonClass}
					style={{ marginLeft: 'auto' }}
					onClick={this.handleSave}
				>
					{buttonText}
				</div>
			</div>
		);
	}
}

export default ButtonRow;
