//React & Redux
import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

//Lodash
import get from 'lodash/get';

//Components
import AppointmentListPage from './AppointmentListPage';

//Styles
import './style.css';

class AppointmentPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			scheduleAppointment: false,
		};
		//bindings
		this.showScheduleAppointment = this.showScheduleAppointment.bind(this);
		this.hideScheduleAppointment = this.hideScheduleAppointment.bind(this);
	}

	//functions
	showScheduleAppointment(filter = {}) {
		get(this, 'props.history', []).push({
			pathname: '/app/appointments/schedule',
			state: {
				breadcrumbs: [
					{
						location: 'Appointments',
						url: '/app/appointments',
						filter: filter,
					},
					{
						location: 'Schedule Appointment',
						url: '/app/appointments/schedule',
					},
				],
			},
		});
	}

	hideScheduleAppointment() {
		this.setState({ scheduleAppointment: false });
	}

	render() {
		return (
			<Fragment>
				<div className='appointment_page_wrapper'>
					<AppointmentListPage
						history={this.props.history}
						showScheduleAppointment={this.showScheduleAppointment}
						hideScheduleAppointment={this.hideScheduleAppointment}
						scheduleAppointment={this.state.scheduleAppointment}
						enqueueSnackbar={this.props.enqueueSnackbar}
					/>
				</div>
			</Fragment>
		);
	}
}

AppointmentPage.propTypes = {
	history: PropTypes.object.isRequired,
};

export default withRouter(AppointmentPage);
