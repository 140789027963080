//React & Redux
import React, { useEffect, useState, useRef } from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../assets/redX.svg';
import checkIcon from './../../assets/green_checkmark.svg';
import circleMinusFillIcon from './../../assets/icons/circle-minus-fill.svg';
import circleMinusIcon from './../../assets/icons/circle-minus.svg';

//Lodash
import get from 'lodash/get';
import _ from 'lodash';

//Utils
import {
	PatientConsentStatus,
	RemoveUnderscoreAndCapitalizeFirstLetter
} from './../../utils';

//Other Libraries
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';

//Components
import Tooltip from '../Common/Tooltip';

const PatientDemographicGeneralTab = (props) => {
	const {
		patient,
		eligibilityFields,
		consentToTreat,
		enqueueSnackbar,
		determineWidthOverflow,
		consent,
	} = props;

	const [status, setStatus] = useState(null);
	const [tooltipUpdate, setTooltipUpdate] = useState(null);

	useEffect(() => {
		setTooltipUpdate(true);
	}, []);

	const firstGroupRefs = useRef([]);
	const secondGroupRefs = useRef([]);

	const determineConsent = () => {
		const status =
			get(patient, 'consent_to_treat', null) !== 'not_consented';
		setStatus(status);
	};

	const addEligibilityFields = () => {
		return []; //temporarily masked
		const fields = eligibilityFields || [];
		if (get(fields, 'length', 0) > 0 && Array.isArray(fields)) {
			return fields.map((el) => {
				return {
					internal:
						get(el, 'name', 'additionalField') || 'additionalField',
					external:
						get(el, 'label', 'Additional Field') ||
						'Additional Field',
					visible: true,
				};
			});
		} else return [];
	};

	const handleConsent = () => {
		if (get(consentToTreat, 'length') === 0) return '';

		for (const obj of consentToTreat) {
			if (obj.key === get(patient, 'consent_to_treat'))
				return obj.display_name;
		}

		return '';
	};

	const renderTopValue = (el) => {
		return el.timestamp
			? moment(get(patient, [el.internal], '')).isValid()
				? moment(get(patient, [el.internal], '')).format('MMM DD, YYYY')
				: '--'
			: get(patient, [el.internal], '--') || '--';
	};

	const renderData = (el, patient) => {
		if (el.fromEnum) {
			if (el.internal === 'referral_provider_id') {
				const referralProviders = props[el.prop];
				if (!patient[el.internal] && patient[el.internal] !== 0)
					return '--';
				for (const obj of referralProviders) {
					if (obj.id === patient[el.internal]) return obj.name;
				}
				return '--';
			} else if (el.internal === 'referral_reason_ids') {
				if (_.isEqual(patient, {})) return '--';
				const referralReasons = props[el.prop];
				const patientReasons = patient[el.internal];
				if (!patientReasons.length || patientReasons.length === 0)
					return '--';

				let str = '';
				for (let i = 0; i < patientReasons.length; i++) {
					const id = patientReasons[i];
					referralReasons.forEach((obj) => {
						if (obj.id === id) {
							str += obj.name;
							if (i !== patientReasons.length - 1) str += ', ';
						}
					});
				}
				return str;
			}
		} else if (
			el.internal === 'external_patient_id' &&
			!_.isNil(patient[el.internal]) &&
			_.trim(patient[el.internal]).length == 0
		) {
			return '--';
		} else {
			return patient[el.internal] || '--';
		}
	};

	const copyClipboard = (name) => {
		enqueueSnackbar('The ' + name + ' has been copied.', {
			variant: 'success',
			className: 'export_notification',
			anchorOrigin: { horizontal: 'center', vertical: 'top' },
		});
	};

	//the second concat is for masking eligibility work and hard-coding Illumina case
	const firstGroup = [
		{
			internal: 'original_referral_date',
			external: 'Original referral date',
			timestamp: true,
			visible: true,
		},
		{
			internal: 'referral_program',
			external: 'Referral program',
			visible: true,
		},
	]
		.concat(addEligibilityFields() || [])
		.concat([
			{
				internal: 'partner_employee_id',
				external: 'Employee ID',
				hideable: true,
				visible:
					patient.referral_program === 'Illumina' ||
					patient.referral_program === 'Illumina Employee',
			},
			{
				internal: 'employee_relationship',
				external: 'Relationship to employee',
				hideable: true,
				visible:
					patient.referral_program === 'Illumina' ||
					patient.referral_program === 'Illumina Employee',
			},
		]);

	const secondGroup = [
		{
			internal: 'external_patient_id',
			external: 'External patient ID',
		},
		{
			internal: 'warm_referral_src',
			external: 'Warm Referral',
		},
		{
			internal: 'athena_patient_id',
			external: 'Athena patient ID',
		},
		// {
		// 	internal: 'referral_reason_ids',
		// 	external: 'Referral Reason',
		// 	fromEnum: true,
		// 	// fullWidth: true,
		// 	prop: 'referralReasons',
		// },
		{
			internal: 'assigned_cc_name',
			external: 'Assigned CC',
			customStyling: { textTransform: 'capitalize' },
		},
		{
			internal: 'assigned_gca_name',
			external: 'Assigned GCA',
			customStyling: { textTransform: 'capitalize' },
		},
	];

	const consentStatus = consent !== 'not_consented';
	const consentText = consentStatus
		? 'consentText consented'
		: 'consentText notConsented';
	const icon =
		consentStatus && consent ? (
			<ReactSVG src={checkIcon} className='consentIcon' />
		) : consentStatus === false && consent ? (
			<ReactSVG src={xIcon} className='consentIcon' />
		) : (
			<div
				style={{ width: '18px', height: '24px', marginRight: '10px' }}
			/>
		);

	let consentToText = get(
		patient,
		['phones', 'primary', 'consent_to_text'],
		null
	);
	consentToText = get(
		patient,
		['guardian', 'phones', 'primary', 'consent_to_text'],
		consentToText
	);

	let consentToTextComponent = '';

	const consentToTextIcon = consentToText ? (
		<ReactSVG src={checkIcon} className='consentIcon' />
	) : (
		<ReactSVG src={xIcon} className='consentIcon' />
	);
	consentToTextComponent = (
		<div
			className={`consent consentToTextContainer ${
				consentToText ? 'consentedToText' : 'notConsentedToText'
			}`}
		>
			{consentToTextIcon}
			<div
				className={`consentText ${
					consentToText ? 'consentedToText' : 'notConsentedToText'
				}`}
			>
				{`${
					consentToText
						? 'Opted in to Text Message'
						: 'Opted out of Text Message'
				}`}
			</div>
		</div>
	);

	let latestConsentStatus = get(patient, 'latestPatientToRecontactStatusObject.patient_consent.status','');
	const latestConsentVersion = get(patient, 'latestPatientToRecontactStatusObject.consent_version','');


	let clinicalTrialAuthComponent = (
		<div className='consent consentToTextContainer'>
			<ReactSVG src={checkIcon} className='consentIcon' />
			<div className='consentText consented'>
				Patient Authorization to Recontact {`(${latestConsentVersion})`}
			</div>
		</div>
	);

	const authToRecontactSkippedSection = () => {
		return (
			<div className='consent consentToTextContainer authToRecontactSkippedText'>
				<ReactSVG src={circleMinusFillIcon} className='consentIcon' />
				<div className='consentText authToRecontactSkippedText'>
					Patient Auth to Recontact Skipped
				</div>
			</div>
		);
	};

	const authToRecontactNotOfferedSection = () => {
		return (
			<div className='consent consentToTextContainer authToRecontactNotOfferedText'>
				<ReactSVG src={circleMinusIcon} className='consentIcon' />
				<div className='consentText authToRecontactNotOfferedText'>
					Patient Auth to Recontact Not Offered
				</div>
			</div>
		);
	};

	const authToRecontactRevokedSection = () => {
		return (
			<div className='consent consentToTextContainer authToRecontactRevokedText'>
				<ReactSVG src={xIcon} className='consentIcon' />
				<div className='consentText authToRecontactRevokedText'>
					Patient Auth to Recontact Revoked
				</div>
			</div>
		);
	};

	const latestAuthToRecontactStatuses = () => {
		const latestConsentStatus =
			patient?.latestPatientToRecontactStatusObject?.patient_consent
				?.status || null;
		let authToRecontactStatus = '';
		if (latestConsentStatus == PatientConsentStatus.SKIPPED) {
			authToRecontactStatus = authToRecontactSkippedSection();
		} else if (latestConsentStatus == PatientConsentStatus.REVOKED) {
			authToRecontactStatus = authToRecontactRevokedSection();
		} else if (_.isNil(latestConsentStatus)) {
			authToRecontactStatus = authToRecontactNotOfferedSection();
		} else {
			authToRecontactStatus = '';
		}
		return authToRecontactStatus;
	};

	return (
		<div className='generalTabContainer'>
			<div className='consent'>
				{icon}
				<div className={consentText}>
					{handleConsent() ||
						RemoveUnderscoreAndCapitalizeFirstLetter(
							patient.consent_to_treat
						) ||
						''}
				</div>
			</div>
			{consentToTextComponent}
			{latestConsentStatus == PatientConsentStatus.CONSENTED ? clinicalTrialAuthComponent: ''}
			{latestAuthToRecontactStatuses()}
			<div className='demographicGroupContainer'>
				{firstGroup.map((el, i) => {
					if (el.visible) {
						return (
							<div className='demographicItem' key={el.internal}>
								<div className='demographicItemHeader'>
									{el.external}
								</div>
								<div className='demographicItemData'>
									<Tooltip
										content={renderTopValue(el)}
										placement='bottom'
										visibleArrow={false}
										disabled={determineWidthOverflow(
											firstGroupRefs.current[i]
										)}
										className='tooltipUnset'
									>
										<div
											className='demographicItemTooltipText'
											ref={(el) =>
												(firstGroupRefs.current[i] = el)
											}
										>
											{renderTopValue(el)}
										</div>
									</Tooltip>
								</div>
							</div>
						);
					} else {
						return;
					}
				})}
			</div>
			<div className='demographicGroupContainer'>
				{secondGroup.map((el, i) => {
					const width = el.fullWidth ? ' fullWidth' : '';
					const customTextStyling =
						get(el, 'customStyling', null) || {};
					return (
						<div
							className={'demographicItem' + width}
							key={el.internal}
						>
							<div className='demographicItemHeader'>
								{el.external}
							</div>
							<div
								className='demographicItemData'
								style={
									el.internal === 'referral_reason_ids'
										? {}
										: {
												display: 'flex',
												flexDirection: 'row',
										  }
								}
							>
								<div
									className={
										el.internal in
										{
											referral_reason_ids: 1,
											external_patient_id: 1,
										}
											? 'demographicItemIds'
											: 'demographicItemNonIds'
									}
								>
									<Tooltip
										content={renderData(el, patient)}
										placement='bottom'
										visibleArrow={false}
										disabled={determineWidthOverflow(
											secondGroupRefs.current[i]
										)}
										className='tooltipUnset'
									>
										<div
											className='demographicItemTooltipText'
											ref={(el) =>
												(secondGroupRefs.current[i] =
													el)
											}
											style={{ ...customTextStyling }}
										>
											{renderData(el, patient)}
										</div>
									</Tooltip>
								</div>
								&nbsp;
								{el.internal == 'external_patient_id' &&
								_.trim(get(patient, 'external_patient_id', ''))
									.length > 0 ? (
									<CopyToClipboard
										text={get(
											patient,
											'external_patient_id'
										)}
										onCopy={() =>
											copyClipboard('External Patient ID')
										}
									>
										<i className='material-icons copyIcon'>
											file_copy
										</i>
									</CopyToClipboard>
								) : (
									''
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default PatientDemographicGeneralTab;
