/* eslint-disable no-prototype-builtins */
//React & Redux
import React from 'react';
import { connect } from 'react-redux';

//Lodash
import _, { get } from 'lodash';

//Components
import RBAC from '../../../RBAC';
import TitleRow from './TitleRow.js';
import CreatePatientV2Body from './CreatePatientV2Body.js';
import ButtonRow from './ButtonRow.js';
import ProviderLinkDialog from '../../../ExternalProviders/ProviderLinkDialog';
import ProviderUnlinkDialog from '../../../ExternalProviders/ProviderUnlinkDialog';
import AddressModal from './AddressModal/AddressModal.js';
import { biologicalSexVariants } from '../../../../Utils/appointmentUtils';
import { dependentVariants, PatientConsentStatus } from '../../../../utils.js';

//Utils
import {
	excludeConsentViaPartner,
	states_list,
	trimAllTextFields,
	trimPayload,
	determineTopDropShadow,
	determineBottomDropShadow,
	objHasArray,
	getNestedArray,
	determineHasAllAddressTypes,
	currentAddressKey,
	mapAddressErrors,
	createHomeAddressMapping,
	createShippingAddressMapping,
} from './../../../../utils.js';
import { EmailValidator } from './../../../../Utils/validate.js';
import {
	CARE_PATIENTS_PATIENT_SETTINGS,
	CARE_PATIENTS_CREATE,
	ROLE_GCA,
	ROLE_CARECOORDINATOR,
	ROLE_PRODENG,
} from '../../../../Utils/permissionUtils';

//Actions & Services
import { getencounters } from '../../../../actions/encounter';
import {
	getReferralPrograms,
	getReferralReasons,
	getReferralProviders,
	getConsenttotreat,
	getEligibilityFields,
	addpatient,
	updateAddress,
	updateOutreach,
	updateRelationship,
	resetEligibilityFields,
	addReferringProvider,
	duplicatePatientCheck,
	patientdetail,
	resetPatientInfo,
	linkProviders,
	updateLinkedProvider,
	getLinkedProviders,
	unlinkProvider,
	getPatientTags,
	getGender,
	getPronouns,
	getSexAssignedAtBirth,
	getRaceEnum,
	getEthnicityEnum,
	updateConsentStatus,
} from '../../../../actions/patients';
import { getProviderSpecialties } from './../../../../actions/providers.js';
import { getPlanTypes } from '../../../../actions/enums';
import {
	getInsuranceCompanyInfo,
	resetGetInsuranceCompanyDispatch,
} from '../../../../actions/insurancecompany';
import {
	getDefaultSchedulingOptions,
	getReferralProgramDetail,
	resetDefaultSchedulingOptionsDispatch,
} from '../../../../actions/referralprograms/referralprograms';

//UI Libraries
import { Loading } from 'gm-element-react';

//Other Libraries
import { isValidNumber } from 'libphonenumber-js';
import validPostalCode from 'postcode-validator';
import moment from 'moment';

//Styles
import './styles.css';

const OTHER_COMPANY = 2;
const defaultInsuranceState = {
	athena_insurance_id: '',
	athena_insurance_package_id: '',
	athena_insurance_package_name: '',
	company_name: '',
	employer: '',
	group_no: '',
	insurance_address: '',
	insurance_city: '',
	insurance_company_id: '',
	insurance_no: '',
	insurance_state: '',
	insurance_zipcode: '',
	is_policyholder: true,
	phone: '',
	plan_name: '',
	policyholder_biological_sex: '',
	policyholder_dob: null,
	policyholder_first_name: '',
	policyholder_last_name: '',
	policyholder_name: '',
	policyholder_relationship: '',
};

class CreatePatientV2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedReferralProgram: null,
			selectedReferralDate: this.determineIsEdit()
				? null
				: moment().format('MM/DD/YYYY'),
			defaultSpecialty: null,
			defaultConsultationType: null,
			unbornSelection: false,
			medicareSelection: null,
			firstName: '',
			lastName: '',
			dob: '',
			biologicalSex: null,
			guardianSelection: false,
			guardianPrimaryPhone: '',
			guardianPrimaryPhoneType: null,
			guardianPrimaryPhoneConsentToText: false,
			guardianSecondaryPhone: '',
			guardianSecondaryPhoneType: null,
			guardianFirstName: '',
			guardianLastName: '',
			guardianRelationship: null,
			guardianDob: '',
			guardianPronouns: null,
			guardianPreferredName: '',
			email: '',
			primaryPhone: '',
			primaryPhoneType: null,
			primaryPhoneConsentToText: false,
			secondaryPhone: '',
			secondaryPhoneType: null,
			noEmail: false,
			externalProviderID: '',
			referralReason: null,
			referringProvider: null,
			addReferringProviderInput: '',
			referringProviderIsDropdown: true,
			consentToTreat: null,
			preferredName: '',
			sexAssignedAtBirth: null,
			pronouns: null,
			eligibilityRelationships: [
				{ internal: 'Self', external: 'Self' },
				{ internal: 'Child', external: 'Child' },
				{
					internal: 'Spouse or Partner',
					external: 'Spouse or Partner',
				},
				{ internal: 'Other', external: 'Other' },
			],
			guardianRelationships: [
				{ internal: 'Child', external: 'Child' },
				{ internal: 'Parent', external: 'Parent' },
				{
					internal: 'Spouse/Domestic Partner',
					external: 'Spouse/Domestic Partner',
				},
				{ internal: 'Sibling', external: 'Sibling' },
				{ internal: 'Legal Guardian', external: 'Legal Guardian' },
				{ internal: 'Other', external: 'Other' },
			],
			phoneTypes: [
				{ internal: 'Home', external: 'Home' },
				{ internal: 'Office', external: 'Office' },
				{ internal: 'Mobile', external: 'Mobile' },
			],
			loading: false,
			allRequiredFieldsFilled: false,
			errorFields: {},
			addressFields: new Set([
				'address1',
				'address2',
				'city',
				'state',
				'zip',
			]),
			addressChanged: false,
			closeAfterGettingPatientDetail: false,
			canNotRemoveGuardian: false,
			payloadComparisonFields: [
				'consent_to_treat',
				'dob',
				'email',
				'first_name',
				'last_name',
				'gender',
				'original_referral_date',
				'partner_id',
				'primaryPhone',
				'external_patient_id',
				'referral_provider_id',
				'has_guardian',
				'is_noemail',
				'race',
				'secondary_races',
				'ethnicity',
			],
			ignoreSpinners: false,
			eligibilityFields: {
				allFields: [],
				requiredFields: [],
				fieldsJson: [],
			},
			offset: 0,
			offsetOverride: false,
			duplicatePatientTooltip: false,
			duplicatePatientTooltipOpen: false,
			topDropShadow: false,
			bottomDropShadow: false,
			patientNeedsToBeInitialized: true,
			showProviderLinkDialog: false,
			showProviderUnlinkDialog: false,
			showAddEditProviderModal: false,
			externalProviders: [],
			providerUUID: null,
			editingProvider: false,
			editingLinkInfo: false,
			showAddressModal: false,
			addressModalEdit: false,
			addressModalIdx: null,
			addresses: [],
			createAddress1: null,
			createAddress2: null,
			createCity: null,
			createState: null,
			createZip: null,
			createAddress1Shipping: null,
			createAddress2Shipping: null,
			createCityShipping: null,
			createStateShipping: null,
			createZipShipping: null,
			sameShippingAddress: true,
			nonEmptyAddress: false,
			nonEmptyShippingAddress: false,
			hasAllAddressTypes: false,
			addressErrors: [],
			ethnicity: null,
			primaryRace: null,
			secondaryRaces: [],
			duplicatePatients: [],
			existingDuplicatePatients: {},
			pendingDuplicatePatients: [],
			insurance: [],
			primaryinsurance: { ...defaultInsuranceState },
			secondaryinsurance: { ...defaultInsuranceState },
			doesPatientHasSeondaryInsurance: false,
			patientAuthorizationToRecontact: null,
		};
		//bindings
		this.setPatientData = this.setPatientData.bind(this);
		this.setPatientTags = this.setPatientTags.bind(this);
		this.closePatientModalPage = this.closePatientModalPage.bind(this);
		this.changeValue = this.changeValue.bind(this);
		this.updateExistingUuid = this.updateExistingUuid.bind(this);
		this.addNewPendingUuid = this.addNewPendingUuid.bind(this);
		this.handleChangePendingUuid = this.handleChangePendingUuid.bind(this);
		this.removePendingUuid = this.removePendingUuid.bind(this);
		this.formulateEnums = this.formulateEnums.bind(this);
		this.getPatient = this.getPatient.bind(this);
		this.getPatientUuid = this.getPatientUuid.bind(this);
		this.getEncounterList = this.getEncounterList.bind(this);
		this.determineIsEdit = this.determineIsEdit.bind(this);
		this.determineRBACPermission = this.determineRBACPermission.bind(this);
		this.setReferringProviderIsDropdown =
			this.setReferringProviderIsDropdown.bind(this);
		this.setReferringProviderIsNotDropdown =
			this.setReferringProviderIsNotDropdown.bind(this);
		this.setRequiredFields = _.debounce(
			this.setRequiredFields.bind(this),
			100
		);
		this.determineRequiredFieldsFilled =
			this.determineRequiredFieldsFilled.bind(this);
		this.handleCreatePatient = this.handleCreatePatient.bind(this);
		this.determineErrors = this.determineErrors.bind(this);
		this.pipeAddressErrorsIntoState =
			this.pipeAddressErrorsIntoState.bind(this);
		this.pipeCreatePatientAddressErrorsIntoState =
			this.pipeCreatePatientAddressErrorsIntoState.bind(this);
		this.pipeMessagesIntoState = this.pipeMessagesIntoState.bind(this);
		this.setAddedReferringProviderValue =
			this.setAddedReferringProviderValue.bind(this);
		this.determinePatientCreateOutreach =
			this.determinePatientCreateOutreach.bind(this);
		this.determineReferralProgramOutreach =
			this.determineReferralProgramOutreach.bind(this);
		this.determineHasFutureBookedAppointments =
			this.determineHasFutureBookedAppointments.bind(this);
		this.determineFuture = this.determineFuture.bind(this);
		this.determineCanNotRemoveGuardian =
			this.determineCanNotRemoveGuardian.bind(this);
		this.addAnother = this.addAnother.bind(this);
		this.addAnotherProviderUniquenessCheck =
			this.addAnotherProviderUniquenessCheck.bind(this);
		this.currentReferralProgramHasEligibility =
			this.currentReferralProgramHasEligibility.bind(this);
		this.getReferralProgramFromID =
			this.getReferralProgramFromID.bind(this);
		this.processEligibilityFields =
			this.processEligibilityFields.bind(this);
		this.getAllEligibilityFields = this.getAllEligibilityFields.bind(this);
		this.getRequiredEligibilityFields =
			this.getRequiredEligibilityFields.bind(this);
		this.getEligibilityFieldOptions =
			this.getEligibilityFieldOptions.bind(this);
		this.getCombinedDuplicatesArray =
			this.getCombinedDuplicatesArray.bind(this);
		this.openDuplicatePatientTooltip =
			this.openDuplicatePatientTooltip.bind(this);
		this.closeDuplicatePatientTooltip =
			this.closeDuplicatePatientTooltip.bind(this);
		this.changeOffset = this.changeOffset.bind(this);
		this.setBodyElement = this.setBodyElement.bind(this);
		this.determineDropShadow = _.debounce(
			this.determineDropShadow.bind(this),
			100
		);
		this.buildDuplicatePatientCheckRequest =
			this.buildDuplicatePatientCheckRequest.bind(this);
		this.buildCreateRequest = this.buildCreateRequest.bind(this);
		this.getCreatePatientAddressPayload =
			this.getCreatePatientAddressPayload.bind(this);
		this.createEditRequest = this.createEditRequest.bind(this);
		this.createEditPatientRequest =
			this.createEditPatientRequest.bind(this);
		this.createRelationshipEditRequest =
			this.createRelationshipEditRequest.bind(this);
		this.constructTagsPayload = this.constructTagsPayload.bind(this);
		this.handleProviderLinkDialog =
			this.handleProviderLinkDialog.bind(this);
		this.handleProviderUnlinkDialog =
			this.handleProviderUnlinkDialog.bind(this);
		this.addExternalProvider = this.addExternalProvider.bind(this);
		this.editLinkInfo = this.editLinkInfo.bind(this);
		this.editProviderInfo = this.editProviderInfo.bind(this);
		this.unlinkProviderInfo = this.unlinkProviderInfo.bind(this);
		this.getSelectedTags = this.getSelectedTags.bind(this);
		this.openAddressModal = this.openAddressModal.bind(this);
		this.closeAddressModal = this.closeAddressModal.bind(this);
		this.handleAddressModalSave = this.handleAddressModalSave.bind(this);
		this.determinePatientHasAllAddressTypes =
			this.determinePatientHasAllAddressTypes.bind(this);
		this.linkDefaultProviders = this.linkDefaultProviders.bind(this);
	}
	//functions

	async componentDidMount() {
		if (
			get(this, 'props.referralReasonsProp', false) &&
			get(this, 'props.referralProgramsProp', false) &&
			get(this, 'props.referralProvidersProp', false) &&
			get(this, 'props.consenttotreatProp', false) &&
			get(this, 'props.sexAssignedAtBirthProp', false) &&
			get(this, 'props.pronounsProp', false) &&
			get(this, 'props.genderProp', false)
		) {
			await this.formulateEnums();
		}

		if (!get(this, 'props.referralReasonsProp', false))
			this.props.getRefReasons();
		if (!get(this, 'props.referralProgramsProp', false))
			this.props.getRefPrograms();
		if (!get(this, 'props.referralProvidersProp', false))
			this.props.getRefProviders();
		if (!get(this, 'props.consenttotreatProp', false))
			this.props.callConsenttotreat();
		if (this.determineIsEdit()) {
			this.props.getPatientDetail(this.getPatientUuid(), true);
			this.props.getLinkedProviders(this.getPatientUuid());
		}
		const isExternalUser = get(this, 'props.isExternalUser', false);
		this.props.getPatientTags();
		this.props.getGenderEnum();
		this.props.getPronounsEnum();
		this.props.getSexAssignedAtBirthEnum();
		this.props.getProviderSpecialties();
		this.props.getRaceEnum();
		this.props.getEthnicityEnum();
		if (this.getPatientUuid()) {
			this.props.getAllEncounters({
				id: this.getPatientUuid(),
				offset: 0,
				limit: -1,
			});
		}
		if (isExternalUser) {
			this.setState({ consentToTreat: 'not_consented' });
		}
		if (!this.determineIsEdit()) this.props.getPlanTypesEnums();
	}

	async componentDidUpdate(prevProps, prevState) {
		const prevEnumsLoading = [
			get(prevProps, 'gettingReferralPrograms', false),
			get(prevProps, 'referralReasonsLoading', false),
			get(prevProps, 'consentToTreatLoading', false),
			get(prevProps, 'gettingReferralProviders', false),
			get(prevProps, 'patientTagsLoading', false),
			get(prevProps, 'pronounsLoading', false),
			get(prevProps, 'sexAssignedAtBirthLoading', false),
			get(prevProps, 'genderLoading', false),
			get(prevProps, 'raceEnumLoading', false),
			get(prevProps, 'ethnicityEnumLoading', false),
		];
		const currentEnumsLoading = [
			get(this, 'props.gettingReferralPrograms', false),
			get(this, 'props.referralReasonsLoading', false),
			get(this, 'props.consentToTreatLoading', false),
			get(this, 'props.gettingReferralProviders', false),
			get(this, 'props.patientTagsLoading', false),
			get(this, 'props.pronounsLoading', false),
			get(this, 'props.sexAssignedAtBirthLoading', false),
			get(this, 'props.genderLoading', false),
			get(this, 'props.raceEnumLoading', false),
			get(this, 'props.ethnicityEnumLoading', false),
		];

		const currentPartnerUUID = this.getPartnerUUID();
		const previousPartnerUUID = this.getPartnerUUID(prevProps, prevState);
		if (currentPartnerUUID && currentPartnerUUID !== previousPartnerUUID) {
			this.props.getReferralProgramDetail({
				partner_uuid: currentPartnerUUID,
			});
		}
		if (
			prevEnumsLoading.some((el) => el) &&
			!currentEnumsLoading.some((el) => el)
		) {
			await this.formulateEnums();
		}

		if (
			get(prevProps, 'loadingReferralProgramDetail', false) &&
			!get(this, 'props.loadingReferralProgramDetail', false) &&
			!this.determineIsEdit()
		) {
			if (get(this, 'state.guardianSelection', false)) {
				this.changeValue(
					'guardianPrimaryPhoneConsentToText',
					get(
						this.props,
						'referralProgramDetail.defaultSmsConsent',
						false
					)
				);
			} else {
				this.changeValue(
					'primaryPhoneConsentToText',
					get(
						this.props,
						'referralProgramDetail.defaultSmsConsent',
						false
					)
				);
			}
		}

		if (
			get(prevProps, 'unlinkingProvider', false) &&
			!get(this, 'props.unlinkingProvider', false)
		) {
			if (get(this, 'props.unlinkProviderError', false)) {
				this.props.enqueueSnackbar('Error in unlinking the provider', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				this.props.enqueueSnackbar('Provider unlinked', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				const { providerUUID } = this.state;
				this.removeExternalProvider(providerUUID);
			}
		}
		if (
			get(prevProps, 'updatingLinkedProvider', false) &&
			!get(this, 'props.updatingLinkedProvider', false)
		) {
			if (get(this, 'props.updatingLinkedProviderError', false)) {
				this.props.enqueueSnackbar(
					'Error in updating linked provider',
					{
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
			} else {
				this.props.enqueueSnackbar('Linked provider updated', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				this.props.getLinkedProviders(this.getPatientUuid());
			}
		}

		if (
			get(prevProps, 'linkingProviders', false) &&
			!get(this, 'props.linkingProviders', false)
		) {
			if (get(this, 'props.linkingProvidersError', false)) {
				setTimeout(() => {
					this.props.enqueueSnackbar('Error in linking providers', {
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
				}, 5000);
			} else {
				if (this.determineIsEdit()) {
					this.props.enqueueSnackbar('Provider linked', {
						variant: 'info',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
					this.props.getLinkedProviders(this.getPatientUuid());
				} else {
					this.patientCreatedSuccess();
				}
			}
		}
		if (
			get(prevProps, 'addingPatient', false) &&
			!get(this, 'props.addingPatient', false)
		) {
			if (get(this, 'props.addingPatientError', false)) {
				this.props.enqueueSnackbar('Error creating patient', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				await this.pipeMessagesIntoState();
				this.pipeAddressErrorsIntoState(
					get(
						this,
						'props.addingPatientErrorMessage.fields.user_address',
						false
					)
				);
			} else {
				if (this.hasExternalProviders()) {
					const { externalProviders } = this.state;
					this.linkExternalProviders(
						externalProviders,
						get(this.props, 'addpatientresult.addpatient.uuid', 0)
					);
				} else {
					this.patientCreatedSuccess();
				}
			}
		}

		const currentEditCallsLoading = [
			get(this, 'props.updatingAddress', false),
			get(this, 'props.updatingPatient', false),
			get(this, 'props.updatingRelationship', false),
			get(this, 'props.updatingConsent', false),
		];
		const prevEditCallsLoading = [
			get(prevProps, 'updatingAddress', false),
			get(prevProps, 'updatingPatient', false),
			get(prevProps, 'updatingRelationship', false),
			get(prevProps, 'updatingConsent', false),
		];
		const currentEditCallsErrors = [
			get(this, 'props.updatingPatientError', false),
			get(this, 'props.updatingAddressError', false),
			get(this, 'props.updatedRelationshipError', false),
			get(this, 'props.updateConsentError', false),
		];

		if (
			prevEditCallsLoading.some((el) => el) &&
			!currentEditCallsLoading.some((el) => el)
		) {
			if (currentEditCallsErrors.some((el) => el)) {
				this.props.enqueueSnackbar('Error in patient edit', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				if (get(this, 'props.updatingPatientError', false))
					await this.pipeMessagesIntoState(
						get(this, 'props.updatingPatientError', {})
					);
				if (get(this, 'props.updatingAddressError', false))
					this.pipeAddressErrorsIntoState(
						get(this, 'props.updatingAddressError', {})
					);
				if (!currentEditCallsErrors.every((el) => el)) {
					this.props.getPatientDetail(this.getPatientUuid(), true);
				}
			} else {
				this.props.enqueueSnackbar('Patient edit successful', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				this.closePatientModalPage();
			}
		}

		if (
			this.determineIsEdit() &&
			get(prevProps, 'linkedProviders', false) !=
				get(this.props, 'linkedProviders', false)
		) {
			this.setState({
				providerUUID: null,
				externalProviders: get(this.props, ['linkedProviders'], []),
			});
		}

		if (
			get(prevProps, 'gettingPatientDetail', false) &&
			!get(this, 'props.gettingPatientDetail', false)
		) {
			if (
				get(this, 'state.patientNeedsToBeInitialized', false) &&
				this.determineIsEdit() &&
				!_.isEmpty(this.getPatient())
			) {
				await this.setPatientData();
				this.determinePatientHasAllAddressTypes();
			}
		}

		if (
			get(prevProps, 'addReferringProviderLoading', false) &&
			!get(this, 'props.addReferringProviderLoading', false)
		) {
			if (get(this, 'props.addReferringProviderSuccess', false)) {
				this.setState({ ignoreSpinners: true }, () =>
					this.props.getRefProviders()
				);
			} else {
				this.props.enqueueSnackbar(
					'Error in adding referring provider',
					{
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
			}
		}

		if (
			get(prevProps, 'gettingReferralProviders', false) &&
			!get(this, 'props.gettingReferralProviders', false) &&
			get(this, 'state.ignoreSpinners', false)
		) {
			this.setAddedReferringProviderValue();
			this.setState({ ignoreSpinners: false });
			this.setReferringProviderIsDropdown();
		}

		if (
			get(prevState, 'selectedReferralProgram', null) !==
				get(this, 'state.selectedReferralProgram', '') &&
			this.getPartnerUUID()
		) {
			this.props.getDefaultSchedulingOptions({
				partner_uuid: this.getPartnerUUID(),
			});
			if (this.currentReferralProgramHasEligibility()) {
				this.props.getEligibilityFields(
					this.getReferralProgramFromID(),
					this.determineIsEdit()
				);
			} else {
				this.props.callResetEligibilityFields();
				this.processEligibilityFields();
			}
			this.setSCPType();

			if (
				!this.state.selectedReferralProgram &&
				this.state.selectedReferralProgram !== 0
			) {
				this.setState({
					defaultSpecialty: null,
					defaultConsultationType: null,
				});
			}
		}

		const { defaultSchedulingOptions } = this.props;

		if (
			!defaultSchedulingOptions?.loading &&
			defaultSchedulingOptions?.payload !==
				prevProps.defaultSchedulingOptions?.payload
		) {
			const isDefaultSpecialtyPresent =
				defaultSchedulingOptions?.payload?.specialties?.some(
					(specialty) => specialty.id === this.state.defaultSpecialty
				);
			if (!isDefaultSpecialtyPresent) {
				this.setState({
					defaultSpecialty: null,
				});
			}

			const isDefaultConsultationPresent =
				defaultSchedulingOptions?.payload?.consultations?.some(
					(consultation) =>
						consultation.id === this.state.defaultConsultationType
				);

			if (!isDefaultConsultationPresent) {
				this.setState({
					defaultConsultationType: null,
				});
			}
		}

		if (
			get(prevProps, 'eligibilityFieldsLoading', false) &&
			!get(this, 'props.eligibilityFieldsLoading', false)
		) {
			this.processEligibilityFields();
		}

		if (
			get(prevProps, 'duplicatePatientCheckLoading', false) &&
			!get(this, 'props.duplicatePatientCheckLoading', false)
		) {
			if (get(this, 'state.ignoreSpinners', false))
				this.setState({ ignoreSpinners: false });
			if (get(this, 'props.duplicatePatientCheckError', false)) {
				this.props.enqueueSnackbar(
					'Error in checking for duplicate patients',
					{
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
			} else {
				const returnedData = get(
					this,
					'props.duplicatePatientCheckData',
					{}
				);
				if (get(returnedData, 'total', null) === 0) {
					this.buildCreateRequest();
				} else {
					this.setState({
						duplicatePatientTooltip: true,
						duplicatePatientTooltipOpen: true,
					});
				}

				if (get(this, 'state.offsetOverride', null)) {
					this.setState({ offsetOverride: false });
				} else {
					this.setState({ offset: 0 });
				}
			}
		}

		if (
			get(prevState, 'duplicatePatients', null) !=
			get(this, 'state.duplicatePatients', [])
		) {
			this.setState({
				duplicate: !!get(this, 'state.duplicatePatients', []).length,
				existingDuplicatePatients: this.state.duplicatePatients.reduce(
					(acc, curr) => {
						return {
							...acc,
							[curr.uuid]: true,
						};
					},
					{}
				),
			});
		}

		if (
			!get(prevState, 'duplicate', null) &&
			get(this, 'state.duplicate', null) &&
			!get(this, 'state.pendingDuplicatePatients', []).length
		) {
			if (
				!this.getCombinedDuplicatesArray().length &&
				!Object.values(get(this, 'state.existingDuplicatePatients', {}))
					.length
			) {
				this.addNewPendingUuid();
			}
		}

		if (
			(!_.isEqual(
				get(prevState, 'pendingDuplicatePatients', null),
				get(this, 'state.pendingDuplicatePatients', [])
			) ||
				!_.isEqual(
					get(prevState, 'existingDuplicatePatients', null),
					get(this, 'state.existingDuplicatePatients', [])
				)) &&
			!Object.values(get(this, 'state.existingDuplicatePatients', {}))
				.length &&
			!get(this, 'state.pendingDuplicatePatients', []).length
		) {
			this.setState({ duplicate: false });
		}
	}

	componentWillUnmount() {
		this.props.callResetEligibilityFields();
		this.props.resetPatientDetail();
		this.props.resetDefaultSchedulingOptionsDispatch();
	}

	updateExistingUuid(uuid, flag) {
		const newState = { ...this.state.existingDuplicatePatients };
		newState[uuid] = flag;
		this.setState({ existingDuplicatePatients: newState });
	}

	addNewPendingUuid() {
		this.setState((prevState) => ({
			pendingDuplicatePatients: [
				...prevState.pendingDuplicatePatients,
				'',
			],
		}));
	}

	removePendingUuid(i) {
		this.setState((prevState) => {
			const newState = [...prevState.pendingDuplicatePatients];
			newState.splice(i, 1);
			return { pendingDuplicatePatients: newState };
		});
	}

	handleChangePendingUuid(newValue, i) {
		this.setState((prevState) => {
			const values = [...prevState.pendingDuplicatePatients];
			values[i] = newValue;
			return { pendingDuplicatePatients: values };
		});
	}

	linkDefaultProviders(externalProviders) {
		const defaultProviderUuidDict = {};

		this.props.referralProgramDetail.defaultProviders.forEach(
			(provider) => {
				defaultProviderUuidDict[provider.uuid] = {
					provider_role: ['referring_provider'],
					provider_uuid: provider.uuid,
					start_date: moment().format('YYYY-MM-DD'),
					end_date: null,
					receive_all_communications: true,
				};
			}
		);

		externalProviders.forEach((provider) => {
			defaultProviderUuidDict[provider.providerUUID] &&
				delete defaultProviderUuidDict[provider.providerUUID];
		});

		return Object.values(defaultProviderUuidDict);
	}

	linkExternalProviders = (externalProviders, patientUUID) => {
		const careTeam = [];

		!this.determineIsEdit() &&
			careTeam.push(...this.linkDefaultProviders(externalProviders));

		(externalProviders || []).forEach((el) => {
			careTeam.push({
				provider_uuid: get(el, 'providerUUID', null),
				provider_role: get(el, 'providerRole', []),
				start_date: moment().format('YYYY-MM-DD'),
				end_date: null,
				receive_all_communications: get(el, 'receiveAllComms', false),
			});
		});

		this.props.linkProviders(patientUUID, { data: careTeam });
	};

	patientCreatedSuccess = () => {
		this.props.enqueueSnackbar('Patient created', {
			variant: 'info',
			anchorOrigin: {
				horizontal: 'right',
				vertical: 'bottom',
			},
		});
		const pathname =
			'/app/patientdetail/' +
			get(this, 'props.addpatientresult.addpatient.uuid', 0) +
			'/0/' +
			get(this.props, 'patientstate.activePage', 0) +
			'/' +
			get(this.props, 'patientstate.query', '') +
			'?offset=' +
			0;
		this.props.history.push({
			pathname: pathname,
			state: {
				breadcrumbs: [
					{
						location: 'patients',
						url: '/app/patients/',
					},
					{
						location: 'Patient Detail',
						patientCreated: this.determinePatientCreateOutreach(
							get(this, 'props', {})
						),
						patientFullName: `${
							get(this, 'state.firstName', '--') || '--'
						} ${get(this, 'state.lastName', '--') || '--'}`,
					},
				],
			},
		});
	};

	currentReferralProgramIsSCP() {
		const referralProgramId =
			get(this, 'state.selectedReferralProgram', '') || '';
		const referralPrograms = get(this, 'state.referralProgramsEnum', []);
		if (referralProgramId && Array.isArray(referralPrograms)) {
			const filteredArr = referralPrograms.filter((el) => {
				return get(el, 'internal', '') === referralProgramId;
			});
			return get(filteredArr, '[0].programType', '') === 'scp';
		}
	}

	setSCPType() {
		if (!this.determineIsEdit() && this.currentReferralProgramIsSCP()) {
			this.setState({
				medicareSelection: null,
				unbornSelection: false,
				guardianSelection: false,
				guardianPrimaryPhone: '',
				guardianFirstName: '',
				guardianLastName: '',
				guardianRelationship: null,
				guardianDob: '',
			});
		}
	}

	setPatientData() {
		return new Promise((resolve) => {
			const patient = this.getPatient();
			this.setPatientTags();
			const isExternalUser = get(this, 'props.isExternalUser', false);
			let consentToTreat = get(patient, 'consent_to_treat', null);
			if (
				isExternalUser &&
				(_.isNil(consentToTreat) || _.isEmpty(consentToTreat))
			) {
				consentToTreat = 'not_consented';
			}
			this.setState(
				{
					patientNeedsToBeInitialized: false,
					address1: get(patient, 'address.home.address_line1', null),
					address2: get(patient, 'address.home.address_line2', null),
					city: get(patient, 'address.home.city', null),
					state: get(patient, 'address.home.state', null),
					zip: get(patient, 'address.home.zip', null),
					selectedReferralProgram: get(patient, 'partner_id', null),
					selectedReferralDate: moment(
						get(patient, 'original_referral_date', null)
					).isValid()
						? moment(
								get(patient, 'original_referral_date', null)
						  ).format('MM/DD/YYYY')
						: null,
					unbornSelection: get(patient, 'is_unborn', false),
					medicareSelection: get(
						patient,
						'is_medicare_beneficiary',
						false
					),
					firstName: get(patient, 'first_name', '') || '',
					lastName: get(patient, 'last_name', '') || '',
					dob: moment(get(patient, 'dob', null)).isValid()
						? moment(get(patient, 'dob', null)).format('MM/DD/YYYY')
						: '',
					biologicalSex: get(patient, 'gender', null),
					email: get(patient, 'email', '') || '',
					primaryPhone:
						get(patient, 'phones.primary.value', '') || '',
					primaryPhoneType:
						get(patient, 'phones.primary.type', null) || null,
					primaryPhoneConsentToText: get(
						patient,
						'phones.primary.consent_to_text',
						false
					),
					secondaryPhone:
						get(patient, 'phones.secondary.value', '') || '',
					secondaryPhoneType:
						get(patient, 'phones.secondary.type', null) || null,
					guardianSelection: get(patient, 'has_guardian', false),
					guardianFirstName:
						get(patient, 'guardian.first_name', '') || '',
					guardianLastName:
						get(patient, 'guardian.last_name', '') || '',
					guardianPrimaryPhone:
						get(patient, 'guardian.phones.primary.value', '') || '',
					guardianPrimaryPhoneType:
						get(patient, 'guardian.phones.primary.type', null) ||
						null,
					guardianPrimaryPhoneConsentToText: get(
						patient,
						'guardian.phones.primary.consent_to_text',
						false
					),
					guardianSecondaryPhone:
						get(patient, 'guardian.phones.secondary.value', '') ||
						'',
					guardianSecondaryPhoneType:
						get(patient, 'guardian.phones.secondary.type', null) ||
						null,
					guardianRelationship: get(
						patient,
						'guardian.relationship',
						null
					),
					guardianDob: moment(
						get(patient, 'guardian.dob', null)
					).isValid()
						? moment(get(patient, 'guardian.dob', null)).format(
								'MM/DD/YYYY'
						  )
						: '',
					guardianPronouns: get(patient, 'guardian.pronouns', null),
					guardianPreferredName:
						get(patient, 'guardian.preferred_name', '') || '',
					externalProviderID:
						get(patient, 'external_patient_id', '') || '',
					referringProvider: get(
						patient,
						'referral_provider_id',
						null
					),
					referralReason: get(patient, 'referral_reason_ids', null),
					consentToTreat: consentToTreat,
					consentToTreatPatient: consentToTreat,
					relationship: get(patient, 'employee_relationship', null),
					employeeId: get(patient, 'partner_employee_id', null),
					addresses: get(patient, currentAddressKey, []),
					preferredName: get(patient, 'preferred_name', '') || '',
					sexAssignedAtBirth:
						get(patient, 'sex_assigned_at_birth', null) == null
							? 'unknown'
							: get(patient, 'sex_assigned_at_birth', null),
					pronouns: get(patient, 'pronouns', null),
					ethnicity: get(patient, 'ethnicity', null),
					primaryRace: get(patient, 'race', null),
					secondaryRaces: get(patient, 'secondary_races', []),
					duplicatePatients: get(patient, 'duplicate_patients', []),
					existingDuplicatePatients: {},
					patientAuthorizationToRecontact:
						get(
							patient,
							[
								'latestPatientToRecontactStatusObject',
								'patient_consent',
								'status',
							],
							null
						) === PatientConsentStatus.CONSENTED,
				},
				resolve
			);
		});
	}

	setPatientTags() {
		const patient = this.getPatient();
		const patientTags = get(patient, 'user_tags', []);
		const tags = {};
		if (Array.isArray(patientTags)) {
			patientTags.forEach((tag) => {
				const tagName = get(tag, 'name', '');
				const tagProperties = get(tag, 'tagProperties', null);
				const language = get(tagProperties, '[0].language', null);
				const reason = get(tagProperties, '[0].reason', null);
				tags[tagName] = true;
				if (tagProperties) {
					if (language) tags[`${tagName}SubValue`] = language;
					if (reason) tags[`${tagName}SubValue`] = reason;
				}
			});
		}
		this.setState(tags);
	}

	closePatientModalPage() {
		const breadcrumbs = get(
			this,
			'props.history.location.state.breadcrumbs',
			[]
		);
		const pathname =
			'/app/patientdetail/' + this.getPatientUuid() + '/0/' + '0' + '/';
		this.props.history.push({
			pathname: pathname,
			state: {
				breadcrumbs: breadcrumbs.slice(0, -1),
			},
		});
	}

	autopopulateInsuranceFields = (value) => {
		const insurance = {};
		const filterInsurance = this.props.insuranceCompanies.find(
			(el) => el.companyID === value
		);
		insurance['athena_insurance_package_id'] =
			filterInsurance?.athenaPackageID || '';
		insurance.athena_insurance_package_name =
			filterInsurance?.athenaPackageName || '';
		insurance.insurance_address = filterInsurance?.address || '';
		insurance.insurance_city = filterInsurance?.city || '';
		insurance.insurance_state = filterInsurance?.state || '';
		insurance.insurance_zipcode = filterInsurance?.zip || '';
		insurance.phone = filterInsurance?.phone || '';
		insurance.company_name = filterInsurance?.insuranceCompanyName || '';
		return insurance;
	};

	changeValue(header, value) {
		if (header) {
			if (get(this, 'state.duplicatePatientTooltip', false)) {
				this.setState({
					duplicatePatientTooltip: false,
					duplicatePatientTooltipOpen: false,
				});
			}
			if (get(this, 'state.addressFields', new Set()).has(header)) {
				this.setState({ addressChanged: true });
			}
			const newState = {
				primaryinsurance: get(this, 'state.primaryinsurance', {}),
				secondaryinsurance: get(this, 'state.secondaryinsurance', {}),
			};
			if (header === 'primaryinsurance.insurance_company_id' && value) {
				newState['primaryinsurance'] = {
					...newState['primaryinsurance'],
					...this.autopopulateInsuranceFields(value),
				};
			} else if (
				header === 'primaryinsurance.insurance_company_id' &&
				!value
			) {
				newState['primaryinsurance'] = {
					...newState['primaryinsurance'],
					...this.autopopulateInsuranceFields(''),
				};
			}

			if (header === 'primaryinsurance.is_policyholder' && value) {
				newState['primaryinsurance']['policyholder_first_name'] = '';
				newState['primaryinsurance']['policyholder_last_name'] = '';
				newState['primaryinsurance']['policyholder_relationship'] = '';
				newState['primaryinsurance']['policyholder_dob'] = '';
			}

			if (header === 'secondaryinsurance.is_policyholder' && value) {
				newState['secondaryinsurance']['policyholder_first_name'] = '';
				newState['secondaryinsurance']['policyholder_last_name'] = '';
				newState['secondaryinsurance']['policyholder_relationship'] =
					'';
				newState['secondaryinsurance']['policyholder_dob'] = '';
			}

			if (header.split('.')[0] === 'primaryinsurance') {
				newState['primaryinsurance'][header.split('.')[1]] = value;
			} else {
				newState[header] = value;
			}

			if (header === 'secondaryinsurance.insurance_company_id' && value) {
				newState['secondaryinsurance'] = {
					...newState['secondaryinsurance'],
					...this.autopopulateInsuranceFields(value),
				};
			} else if (
				header === 'secondaryinsurance.insurance_company_id' &&
				!value
			) {
				newState['secondaryinsurance'] = {
					...newState['secondaryinsurance'],
					...this.autopopulateInsuranceFields(''),
				};
			}
			if (header.split('.')[0] === 'secondaryinsurance') {
				newState['secondaryinsurance'][header.split('.')[1]] = value;
			} else {
				newState[header] = value;
			}

			if (header === 'unbornSelection' && this.determineIsEdit()) {
				if (
					!get(this, 'state.unbornSelection', false) &&
					value === true
				) {
					newState.canNotRemoveGuardian = true;
					newState.guardianSelection = true;
				} else {
					newState.canNotRemoveGuardian = false;
				}
			}
			if (header == 'unbornSelection' && !this.determineIsEdit()) {
				if (
					!get(this, 'state.unbornSelection', false) &&
					value === true
				) {
					newState.guardianPrimaryPhoneConsentToText = get(
						this.props,
						'referralProgramDetail.defaultSmsConsent',
						false
					);
				} else {
					if (get(this, 'state.guardianSelection', false)) {
						newState.guardianPrimaryPhoneConsentToText = get(
							this.props,
							'referralProgramDetail.defaultSmsConsent',
							false
						);
					} else {
						newState.primaryPhoneConsentToText = get(
							this.props,
							'referralProgramDetail.defaultSmsConsent',
							false
						);
					}
				}
			}
			if (header == 'unbornSelection') {
				if (
					!get(this, 'state.unbornSelection', false) &&
					value === true
				) {
					newState.medicareSelection = false;
				}
			}
			if (header === 'primaryRace') {
				newState.secondaryRaces = [];
			}

			if (header === 'guardianSelection' && !this.determineIsEdit()) {
				if (value == true) {
					newState.guardianPrimaryPhoneConsentToText = get(
						this.props,
						'referralProgramDetail.defaultSmsConsent',
						false
					);
				} else {
					newState.primaryPhoneConsentToText = get(
						this.props,
						'referralProgramDetail.defaultSmsConsent',
						false
					);
				}
			}

			if (header === 'primaryPhoneConsentToText') {
				newState.primaryPhoneConsentToText = value;
			}

			if (header === 'guardianPrimaryPhoneConsentToText') {
				newState.guardianPrimaryPhoneConsentToText = value;
			}

			if (
				header === 'selectedReferralProgram' &&
				!this.determineIsEdit()
			) {
				if (value !== '') {
					const referralProgramEnums = get(
						this,
						'props.referralPrograms.data',
						[]
					);
					const findValue = referralProgramEnums.find(
						(a) => a.id == value
					);
					const request = {
						limit: -1,
						page: 1,
						partner_uuid: findValue?.uuid,
					};
					newState['primaryinsurance'] = { ...defaultInsuranceState };
					newState['secondaryinsurance'] = {
						...defaultInsuranceState,
					};
					this.props.getInsuranceCompanyInfo(request);
					this.props.getReferralProgramDetail({
						partner_uuid: findValue.uuid,
					});
					this.props.getDefaultSchedulingOptions({
						partner_uuid: findValue.uuid,
					});
				} else {
					newState['primaryinsurance'] = { ...defaultInsuranceState };
					newState['secondaryinsurance'] = {
						...defaultInsuranceState,
					};
					this.props.resetGetInsuranceCompany();
				}
			}

			if (
				['defaultSpecialty', 'defaultConsultationType'].includes(header)
			) {
				newState[header] = value;
			}

			this.setState(newState, () => {
				this.setRequiredFields();
			});
		}
	}

	determineRBACPermission() {
		return this.determineIsEdit()
			? CARE_PATIENTS_PATIENT_SETTINGS
			: CARE_PATIENTS_CREATE;
	}

	determinePatientCreateOutreach(newProps) {
		if (this.determineReferralProgramOutreach()) {
			return get(newProps, 'addpatientresult.addpatient.uuid', 0);
		} else {
			return null;
		}
	}

	determineReferralProgramOutreach() {
		const currentPartnerId = get(
			this,
			'state.selectedReferralProgram',
			null
		);
		const referralPrograms = get(this, 'props.referralPrograms.data', []);
		if (Array.isArray(referralPrograms)) {
			const selectedProgram = _.find(referralPrograms, (program) => {
				return get(program, 'id', '') === currentPartnerId;
			});
			return get(
				selectedProgram,
				'referral_outreach_automation_enabled',
				false
			);
		}
	}

	setRequiredFields() {
		const editMode = this.determineIsEdit();
		const isExternalUser = get(this, 'props.isExternalUser', false);
		const nonEmptyAddress =
			!!get(this, 'state.createAddress1', null) ||
			get(this, 'state.createAddress2', null) ||
			get(this, 'state.createCity', null) ||
			get(this, 'state.createState', null) ||
			get(this, 'state.createZip', null);

		const nonEmptyShippingAddress =
			!!get(this, 'state.createAddress1Shipping', null) ||
			get(this, 'state.createAddress2Shipping', null) ||
			get(this, 'state.createCityShipping', null) ||
			get(this, 'state.createStateShipping', null) ||
			get(this, 'state.createZipShipping', null);

		const sameShippingAddress = get(
			this,
			'state.sameShippingAddress',
			true
		);
		let fields = ['selectedReferralProgram', 'selectedReferralDate'];

		const hasPastEncounter = this.getEncounterList().length > 0;
		const defaultSchedulingOptions = get(
			this.props,
			['defaultSchedulingOptions', 'payload'],
			{}
		);
		const hasDefaultSchedulingOptions =
			!_.isEmpty(defaultSchedulingOptions) &&
			!_.isNil(defaultSchedulingOptions);

		fields =
			!hasPastEncounter && hasDefaultSchedulingOptions
				? fields.concat(['defaultSpecialty', 'defaultConsultationType'])
				: fields;

		if (!isExternalUser) {
			fields = fields.concat(['consentToTreat']);
		}

		if (!get(this, 'state.unbornSelection', false) || editMode) {
			fields = fields.concat([
				'firstName',
				'lastName',
				'sexAssignedAtBirth',
			]);

			if (
				!(get(this, 'state.unbornSelection', false) && editMode) ||
				!get(this, 'state.unbornSelection', false)
			) {
				fields = fields.concat(['dob']);
			}
		}

		if (
			get(this, 'state.unbornSelection', false) ||
			get(this, 'state.guardianSelection', false)
		) {
			fields = fields.concat([
				'guardianPrimaryPhone',
				'guardianFirstName',
				'guardianLastName',
				'guardianRelationship',
			]);
		} else {
			fields = fields.concat(['primaryPhone']);
		}

		if (
			get(this, 'state.unbornSelection', false) &&
			get(this, 'state.guardianSelection', false)
		) {
			fields = fields.concat(['guardianDob']);
		}

		if (!get(this, 'state.noEmail', false)) {
			fields = fields.concat(['email']);
		}

		if (nonEmptyAddress && !editMode) {
			fields = fields.concat([
				'createAddress1',
				'createCity',
				'createState',
				'createZip',
			]);
		}

		if (nonEmptyShippingAddress && !editMode && !sameShippingAddress) {
			fields = fields.concat([
				'createAddress1Shipping',
				'createCityShipping',
				'createStateShipping',
				'createZipShipping',
			]);
		}

		if (!editMode && !get(this, 'state.unbornSelection', false)) {
			fields = fields.concat(['ethnicity', 'primaryRace']);
		}

		fields = fields.concat(
			get(this, 'state.eligibilityFields.allFields', []).filter((el) => {
				return !(this.determineIsEdit() && el === 'employeeId');
			})
		);

		const isPrimaryInsuranceRequired = _.every(
			this.state.primaryinsurance,
			(value, key) => {
				if (key == 'is_policyholder' && value == true) return true;
				else if (typeof value == 'string') {
					return _.isEmpty(value);
				} else {
					return _.isNil(value);
				}
			}
		);
		if (!isPrimaryInsuranceRequired) {
			fields = fields.concat([
				'primaryinsurance.insurance_company_id',
				'primaryinsurance.insurance_no',
				'primaryinsurance.phone',
				'primaryinsurance.group_no',
				'primaryinsurance.insurance_address',
				'primaryinsurance.insurance_city',
				'primaryinsurance.insurance_state',
				'primaryinsurance.insurance_zipcode',
				'primaryinsurance.is_policyholder',
				'primaryinsurance.policyholder_biological_sex',
			]);
			if (!get(this, 'state.primaryinsurance.is_policyholder', true)) {
				fields = fields.concat([
					'primaryinsurance.policyholder_first_name',
					'primaryinsurance.policyholder_last_name',
					'primaryinsurance.policyholder_relationship',
				]);
			} else {
				fields = fields.filter(
					(el) =>
						![
							'primaryinsurance.policyholder_first_name',
							'primaryinsurance.policyholder_last_name',
							'primaryinsurance.policyholder_relationship',
						].includes(el)
				);
			}
			if (
				OTHER_COMPANY ===
				this.state.primaryinsurance.insurance_company_id
			) {
				fields = fields.concat(['primaryinsurance.company_name']);
			} else {
				fields = fields.filter(
					(el) => !['primaryinsurance.company_name'].includes(el)
				);
			}
		} else {
			fields = fields.filter(
				(el) =>
					![
						'primaryinsurance.insurance_company_id',
						'primaryinsurance.insurance_no',
						'primaryinsurance.phone',
						'primaryinsurance.group_no',
						'primaryinsurance.insurance_address',
						'primaryinsurance.insurance_city',
						'primaryinsurance.insurance_state',
						'primaryinsurance.insurance_zipcode',
						'primaryinsurance.is_policyholder',
						'primaryinsurance.policyholder_biological_sex',
						'primaryinsurance.policyholder_first_name',
						'primaryinsurance.policyholder_last_name',
						'primaryinsurance.policyholder_relationship',
						'primaryinsurance.company_name',
					].includes(el)
			);
		}

		const isSecondaryInsuranceRequired = _.every(
			this.state.secondaryinsurance,
			(value, key) => {
				if (key == 'is_policyholder' && value == true) return true;
				else if (typeof value == 'string') {
					return _.isEmpty(value);
				} else {
					return _.isNil(value);
				}
			}
		);
		if (
			!isSecondaryInsuranceRequired &&
			this.state.doesPatientHasSeondaryInsurance
		) {
			fields = fields.concat([
				'secondaryinsurance.insurance_company_id',
				'secondaryinsurance.insurance_no',
				'secondaryinsurance.is_policyholder',
				'secondaryinsurance.policyholder_biological_sex',
			]);
			if (!get(this, 'state.secondaryinsurance.is_policyholder', true)) {
				fields = fields.concat([
					'secondaryinsurance.policyholder_first_name',
					'secondaryinsurance.policyholder_last_name',
					'secondaryinsurance.policyholder_relationship',
				]);
			} else {
				fields = fields.filter(
					(el) =>
						![
							'secondaryinsurance.policyholder_first_name',
							'secondaryinsurance.policyholder_last_name',
							'secondaryinsurance.policyholder_relationship',
						].includes(el)
				);
			}
			if (
				OTHER_COMPANY ===
				this.state.primaryinsurance.insurance_company_id
			) {
				fields = fields.concat(['secondaryinsurance.company_name']);
			} else {
				fields = fields.filter(
					(el) => !['secondaryinsurance.company_name'].includes(el)
				);
			}
		} else {
			fields = fields.filter(
				(el) =>
					![
						'secondaryinsurance.insurance_company_id',
						'secondaryinsurance.insurance_no',
						'secondaryinsurance.is_policyholder',
						'secondaryinsurance.policyholder_biological_sex',
						'secondaryinsurance.policyholder_first_name',
						'secondaryinsurance.policyholder_last_name',
						'secondaryinsurance.policyholder_relationship',
						'secondaryinsurance.company_name',
					].includes(el)
			);
		}

		this.setState(
			{
				requiredFields: fields,
				nonEmptyAddress,
				nonEmptyShippingAddress,
			},
			this.determineRequiredFieldsFilled
		);
	}

	determineRequiredFieldsFilled() {
		const requiredFields = get(this, 'state.requiredFields', []);
		let status = true;
		if (Array.isArray(requiredFields)) {
			if (get(requiredFields, 'length', 0) === 0) status = false;
			else {
				requiredFields.forEach((el) => {
					let currentFieldValue = get(this, `state.${el}`, '');
					if (el === 'primaryinsurance.is_policyholder') {
						currentFieldValue = currentFieldValue.toString();
					}
					if (el === 'secondaryinsurance.is_policyholder') {
						currentFieldValue = currentFieldValue.toString();
					}

					if (
						![
							'medicareSelection',
							'primaryPhoneConsentToText',
							'guardianPrimaryPhoneConsentToText',
						].includes(el)
					) {
						if (!currentFieldValue && currentFieldValue !== 0)
							status = false;
					}
					if (el === 'primaryPhone' && currentFieldValue === '+1 (')
						status = false;
				});
			}
		}
		if (
			['language_line_support', 'priority_patient'].some(
				(t) =>
					get(this, `state.${t}`, false) &&
					!get(this, `state.${t}SubValue`, false)
			)
		) {
			status = false;
		}
		this.setState({ allRequiredFieldsFilled: status });
	}

	formulateEnums() {
		return new Promise((resolve) => {
			const referralReasons = get(this, 'props.referralReasons', []).map(
				(el) => {
					const obj = {};
					obj.internal = el.id;
					obj.external = el.name;
					return obj;
				}
			);
			const referralProviders = get(
				this,
				'props.referralProviders',
				[]
			).map((el) => {
				const obj = {};
				obj.internal = el.id;
				obj.external = el.name;
				return obj;
			});
			const referralPrograms = get(
				this,
				'props.referralPrograms.data',
				[]
			).map((el) => {
				const obj = {};
				obj.internal = el.id;
				obj.external = el.display_name;
				obj.alternate = el.name;
				obj.eligibilityRequired = el.eligibility_required;
				obj.programType = el.program_type;
				return obj;
			});
			const consenttotreat = get(this, 'props.consenttotreat', []).map(
				(el) => {
					const obj = {};
					obj.internal = el.key;
					obj.external = el.display_name;
					return obj;
				}
			);
			const patientTags = get(this, 'props.patientTags', []).map((el) => {
				const name = get(el, 'name', null);
				const label = get(el, 'display_name', null);
				const value = get(el, 'id', null);
				const hiddenForExternalUsers = get(
					el,
					'hiddenForExternalUsers',
					false
				);
				const obj = {};
				const hasDropdown = el?.tag_properties && objHasArray(Object.values(el?.tag_properties)[0]) || false;
				obj.internal = value;
				obj.external = label;
				obj.tagType = name;
				obj.hiddenForExternalUsers = hiddenForExternalUsers;
				obj.hasDropdown = hasDropdown;
				obj.dropdownOptions =
					el?.tag_properties && getNestedArray(Object.values(el?.tag_properties)[0]) || null;
				return obj;
			});

			const pronounsEnum = get(this, 'props.pronouns', []).map((el) => {
				const obj = {};
				obj.internal = el.key;
				obj.external = el.display_name;
				return obj;
			});

			let sexAssignedAtBirthEnum = [];
			if (this.determineIsEdit()) {
				sexAssignedAtBirthEnum = _.filter(
					get(this, 'props.sexAssignedAtBirth', []),
					(el) => {
						if (el.key == 'unknown') {
							return (
								get(this, 'state.sexAssignedAtBirth', null) ==
									null ||
								get(this, 'state.sexAssignedAtBirth', null) ==
									'unknown'
							);
						}
						return true;
					}
				);
				sexAssignedAtBirthEnum = sexAssignedAtBirthEnum.map((el) => {
					const obj = {};
					obj.internal = el.key;
					obj.external = el.display_name;
					return obj;
				});
			} else {
				sexAssignedAtBirthEnum = _.filter(
					get(this, 'props.sexAssignedAtBirth', []),
					(el) => el && el.key != 'unknown'
				);
				sexAssignedAtBirthEnum = sexAssignedAtBirthEnum.map((el) => {
					const obj = {};
					obj.internal = el.key;
					obj.external = el.display_name;
					return obj;
				});
			}

			const gendersEnum = get(this, 'props.gender', []).map((el) => {
				const obj = {};
				obj.internal = el.key;
				obj.external = el.display_name;
				return obj;
			});

			const raceEnum = get(this, 'props.raceEnum', []).map((el) => {
				const obj = {};
				obj.internal = el.display_name;
				obj.external = el.display_name;
				if (Array.isArray(get(el, 'secondary_races', null))) {
					obj.subOptions = el.secondary_races.map((secondaryRace) => {
						const obj = {};
						obj.internal = secondaryRace.display_name;
						obj.external = secondaryRace.display_name;
						return obj;
					});
				}
				return obj;
			});

			const ethnicityEnum = get(this, 'props.ethnicityEnum', []).map(
				(el) => {
					const obj = {};
					obj.internal = el.display_name;
					obj.external = el.display_name;
					return obj;
				}
			);

			let customTags = [
				{
					editOnly: true,
					external: 'Duplicate Patient Record',
					tagType: 'duplicate',
					hasTextInput: true,
				},
			];

			customTags = customTags.filter(({ editOnly }) => {
				if (editOnly) return this.determineIsEdit();
				return true;
			});

			const combinedTags = [...patientTags, ...customTags];
			const patient = get(this, 'props.patientDetail', {});
			const defaultSpecialtyId = patient?.default_specialty_id;
			const defaultConsultationId = patient?.default_consultation_id;
			this.setState({
				defaultSpecialty: defaultSpecialtyId,
				defaultConsultationType: defaultConsultationId,
			});
			const partnerUUID = get(patient, 'partner_uuid', null);
			if (partnerUUID) {
				this.props.getDefaultSchedulingOptions({
					partner_uuid: partnerUUID,
				});
			}

			this.setState(
				{
					referralReasonsEnum: referralReasons,
					referralProvidersEnum: referralProviders,
					referralProgramsEnum: referralPrograms,
					consentToTreatEnum: consenttotreat,
					patientTagsEnum: combinedTags,
					pronounsEnum: pronounsEnum,
					sexAssignedAtBirthEnum: sexAssignedAtBirthEnum,
					gendersEnum: gendersEnum,
					raceEnum: raceEnum,
					ethnicityEnum: ethnicityEnum,
				},
				resolve
			);
		});
	}

	getPatient() {
		return get(this, 'props.patientDetail', {});
	}

	getPatientUuid() {
		const breadcrumbs = get(
			this,
			'props.history.location.state.breadcrumbs',
			[]
		);
		const lastBreadcrumbItem = Array.isArray(breadcrumbs)
			? breadcrumbs[get(breadcrumbs, 'length', 0) - 1]
			: null;

		if (lastBreadcrumbItem)
			return get(lastBreadcrumbItem, 'state.patientUuid', null);
	}

	getEncounterList() {
		if (this.getPatientUuid()) {
			return this.props.encounterList.encounter.data || [];
		} else {
			return [];
		}
	}

	determineIsEdit() {
		const breadcrumbs = get(
			this,
			'props.history.location.state.breadcrumbs',
			[]
		);
		const lastBreadcrumbItem = Array.isArray(breadcrumbs)
			? breadcrumbs[get(breadcrumbs, 'length', 0) - 1]
			: null;

		if (lastBreadcrumbItem)
			return get(lastBreadcrumbItem, 'state.edit', null);
	}

	setReferringProviderIsDropdown() {
		const errorFields = get(this, 'state.errorFields', {});
		if (errorFields.hasOwnProperty('referringProvider')) {
			delete errorFields.referringProvider;
			this.setState({ errorFields });
		}
		this.setState({
			referringProviderIsDropdown: true,
			addReferringProviderInput: '',
		});
	}

	setReferringProviderIsNotDropdown() {
		const errorFields = get(this, 'state.errorFields', {});
		if (errorFields.hasOwnProperty('referringProvider')) {
			delete errorFields.referringProvider;
			this.setState({ errorFields });
		}
		this.setState({ referringProviderIsDropdown: false });
	}

	handleCreatePatient() {
		if (get(this, 'state.allRequiredFieldsFilled', false)) {
			if (!this.determineErrors()) {
				if (
					get(this, 'state.duplicatePatientTooltip', false) ||
					this.determineIsEdit()
				) {
					this.buildCreateRequest();
				} else {
					this.buildDuplicatePatientCheckRequest();
				}
			}
		}
	}

	determineErrors() {
		const errorFields = {};
		const nonEmptyAddress = get(this, 'state.nonEmptyAddress', false);
		const nonEmptyShippingAddress = get(
			this,
			'state.nonEmptyShippingAddress',
			false
		);
		const sameShippingAddress = get(
			this,
			'state.sameShippingAddress',
			true
		);
		const primaryinsurance = get(this, ['state', 'primaryinsurance'], {});

		const nameMaxLength = 45;
		const firstName = get(this, 'state.firstName', '') || '';
		const lastName = get(this, 'state.lastName', '') || '';
		const createZip = get(this, 'state.createZip', '');
		const createZipShipping = get(this, 'state.createZipShipping', '');

		const addressFieldsRegex =
			/((?:[A-Za-z0-9-— '/,.()!@$%#]+$)*[a-zA-Z0-9])/;

		if (primaryinsurance) {
			if (
				!_.isEmpty(
					get(this, 'state.primaryinsurance.insurance_address', '')
				) &&
				!(
					get(this, 'state.primaryinsurance.insurance_address', '') ||
					''
				).match(addressFieldsRegex)
			) {
				errorFields['primaryinsurance.insurance_address'] =
					'Address is invalid';
			}

			if (
				!_.isEmpty(
					get(this, 'state.primaryinsurance.insurance_city', '')
				) &&
				!(
					get(this, 'state.primaryinsurance.insurance_city', '') || ''
				).match(addressFieldsRegex)
			) {
				errorFields['primaryinsurance.insurance_city'] =
					'City is invalid';
			}

			if (
				!_.isEmpty(
					get(this, 'state.primaryinsurance.insurance_zipcode', '')
				) &&
				!validPostalCode.validate(
					get(this, 'state.primaryinsurance.insurance_zipcode', '') ||
						'',
					'US'
				)
			) {
				errorFields['primaryinsurance.insurance_zipcode'] =
					'Zipcode is invalid';
			}
			if (
				!_.isEmpty(get(this, 'state.primaryinsurance.phone', '')) &&
				!isValidNumber(
					get(this, 'state.primaryinsurance.phone', '') || ''
				)
			)
				errorFields['primaryinsurance.phone'] =
					'Phone number is invalid';
		}

		if (nonEmptyAddress) {
			if (
				!(get(this, 'state.createAddress1', '') || '').match(
					addressFieldsRegex
				)
			) {
				errorFields['createAddress1'] = 'Address line 1 is invalid';
			}

			if (
				get(this, 'state.createAddress2', '') &&
				!(get(this, 'state.createAddress2', '') || '').match(
					addressFieldsRegex
				)
			) {
				errorFields['createAddress2'] = 'Address line 2 is invalid';
			}

			if (
				!(get(this, 'state.createCity', '') || '').match(
					addressFieldsRegex
				)
			) {
				errorFields['createCity'] = 'City is invalid';
			}

			if (!validPostalCode.validate(createZip || '', 'US')) {
				errorFields['createZip'] = 'Zipcode is invalid';
			}
		}

		if (nonEmptyShippingAddress && !sameShippingAddress) {
			if (
				!(get(this, 'state.createAddress1Shipping', '') || '').match(
					addressFieldsRegex
				)
			) {
				errorFields['createAddress1Shipping'] =
					'Address line 1 is invalid';
			}

			if (
				get(this, 'state.createAddress2Shipping', '') &&
				!(get(this, 'state.createAddress2Shipping', '') || '').match(
					addressFieldsRegex
				)
			) {
				errorFields['createAddress2Shipping'] =
					'Address line 2 is invalid';
			}

			if (
				!(get(this, 'state.createCityShipping', '') || '').match(
					addressFieldsRegex
				)
			) {
				errorFields['createCityShipping'] = 'City is invalid';
			}

			if (!validPostalCode.validate(createZipShipping || '', 'US')) {
				errorFields['createZipShipping'] = 'Zipcode is invalid';
			}
		}

		if (
			!(
				get(this, 'state.selectedReferralDate', '') &&
				moment(
					get(this, 'state.selectedReferralDate', ''),
					'MM/DD/YYYY',
					true
				).isValid()
			)
		) {
			errorFields['selectedReferralDate'] = 'Referral date is invalid';
		}

		const nameRegex = /^(?:'?[A-z]+[-. ']?)+$/;

		if (
			get(this, 'state.unbornSelection', false) ||
			get(this, 'state.guardianSelection', false)
		) {
			if (
				!isValidNumber(
					get(this, 'state.guardianPrimaryPhone', '') || ''
				)
			)
				errorFields['guardianPrimaryPhone'] = 'Phone number is invalid';
			if (
				!_.isEmpty(get(this, 'state.guardianSecondaryPhone', '')) &&
				!isValidNumber(
					get(this, 'state.guardianSecondaryPhone', '') || ''
				)
			)
				errorFields['guardianSecondaryPhone'] =
					'Phone number is invalid';

			if (
				!(get(this, 'state.guardianFirstName', '') || '').match(
					nameRegex
				)
			)
				errorFields['guardianFirstName'] = 'First name is invalid';
			if (
				!(get(this, 'state.guardianLastName', '') || '').match(
					nameRegex
				)
			)
				errorFields['guardianLastName'] = 'Last name is invalid';
		}

		if (
			get(this, 'state.unbornSelection', false) &&
			get(this, 'state.guardianSelection', false)
		) {
			if (
				!(
					get(this, 'state.guardianDob', '') &&
					moment(
						get(this, 'state.guardianDob', ''),
						'MM/DD/YYYY',
						true
					).isValid()
				)
			)
				errorFields['guardianDob'] = 'DOB is invalid';
		}

		if (!get(this, 'state.unbornSelection', false)) {
			if (!firstName.match(nameRegex))
				errorFields['firstName'] = 'First name is invalid';
			if (firstName.length > nameMaxLength)
				errorFields['firstName'] = 'First name is too long';
			if (!lastName.match(nameRegex))
				errorFields['lastName'] = 'Last name is invalid';
			if (lastName.length > nameMaxLength)
				errorFields['lastName'] = 'Last name is too long';
			if (
				!(
					get(this, 'state.dob', '') &&
					moment(
						get(this, 'state.dob', ''),
						'MM/DD/YYYY',
						true
					).isValid()
				)
			) {
				errorFields['dob'] = 'DOB is invalid';
			}
			if (!get(this, 'state.guardianSelection', false)) {
				if (!isValidNumber(get(this, 'state.primaryPhone', '') || ''))
					errorFields['primaryPhone'] = 'Phone number is invalid';
				if (
					!_.isEmpty(get(this, 'state.secondaryPhone', '')) &&
					!isValidNumber(get(this, 'state.secondaryPhone', '') || '')
				)
					errorFields['secondaryPhone'] = 'Phone number is invalid';
			}
		} else {
			if (this.determineIsEdit()) {
				if (!firstName.match(nameRegex))
					errorFields['firstName'] = 'First name is invalid';
				if (firstName.length > nameMaxLength)
					errorFields['firstName'] = 'First name is too long';
				if (!lastName.match(nameRegex))
					errorFields['lastName'] = 'Last name is invalid';
				if (lastName.length > nameMaxLength)
					errorFields['lastName'] = 'Last name is too long';
				if (
					get(this, 'state.dob', '') &&
					!moment(
						get(this, 'state.dob', ''),
						'MM/DD/YYYY',
						true
					).isValid()
				) {
					errorFields['dob'] = 'DOB is invalid';
				}
			}
		}

		if (!get(this, 'state.noEmail', false)) {
			if (!EmailValidator.validate(get(this, 'state.email', '') || ''))
				errorFields['email'] = 'Email is invalid';
		}

		if (this.determineIsEdit()) {
			if (
				get(this, 'state.duplicate', true) &&
				get(this, 'state.pendingDuplicatePatients', []).length &&
				!this.getCombinedDuplicatesArray().length
			) {
				errorFields['duplicateRecord'] = 'Please enter patient UUID';
			} else if (
				get(this, 'state.duplicate', true) &&
				!this.getCombinedDuplicatesArray().length
			) {
				errorFields['duplicateRecord'] =
					'Please add a UUID or remove the Duplicate tag';
			}
		}

		this.setState({ errorFields });
		return Object.keys(errorFields).length;
	}

	pipeAddressErrorsIntoState(errors) {
		if (this.determineIsEdit()) {
			const addresses = get(this, 'state.addresses', []);
			if (errors && Array.isArray(addresses)) {
				const addressErrors = mapAddressErrors(addresses, errors);
				if (addressErrors) this.setState({ addressErrors });
			}
		} else {
			this.pipeCreatePatientAddressErrorsIntoState(errors);
		}
	}

	pipeCreatePatientAddressErrorsIntoState(errors = {}) {
		const addressErrorObj = {};
		for (const type in errors) {
			const typeArr = type.split('-');
			if (
				typeArr.includes('home') ||
				(typeArr.includes('home') && typeArr.includes('delivery'))
			) {
				for (const field in errors[type]) {
					const message =
						Object.values(errors[type][field])[0] ||
						'Invalid value';
					const internalField = createHomeAddressMapping[field];
					if (internalField) addressErrorObj[internalField] = message;
				}
			} else if (typeArr.includes('delivery')) {
				for (const field in errors[type]) {
					const message =
						Object.values(errors[type][field])[0] ||
						'Invalid value';
					const internalField = createShippingAddressMapping[field];
					if (internalField) addressErrorObj[internalField] = message;
				}
			}
		}
		const errorFields = get(this, 'state.errorFields', null) || {};
		this.setState({ errorFields: { ...errorFields, ...addressErrorObj } });
	}

	pipeMessagesIntoState(error) {
		return new Promise((resolve) => {
			const errorFields = {};
			const messages =
				error || get(this, 'props.addingPatientErrorMessage', {});
			const fields = get(messages, 'fields', {});
			for (const field in fields) {
				let message = get(Object.values(fields[field]), '[0]', '');
				if (message) {
					let fieldName;
					switch (field) {
						case 'email':
							fieldName = 'email';
							break;
						case 'first_name':
							fieldName = 'firstName';
							break;
						case 'last_name':
							fieldName = 'lastName';
							break;
						case 'users_guardian.first_name':
							fieldName = 'guardianFirstName';
							break;
						case 'users_guardian.last_name':
							fieldName = 'guardianLastName';
							break;
						case 'gender':
							fieldName = 'biologicalSex';
							break;
						case 'dob':
							fieldName = 'dob';
							break;
						case 'phone': {
							fieldName = 'phone';
							message = fields[field];
							let primaryPhoneField = 'primaryPhone';
							let secondPhoneField = 'secondaryPhone';
							if (
								get(this, 'state.unbornSelection', false) ||
								get(this, 'state.guardianSelection', false)
							) {
								primaryPhoneField = 'guardianPrimaryPhone';
								secondPhoneField = 'guardianSecondaryPhone';
							}
							if (get(message, 'primary')) {
								errorFields[primaryPhoneField] = Object.values(
									get(message, 'primary', {})
								);
							}
							if (get(message, 'secondary')) {
								errorFields[secondPhoneField] = Object.values(
									get(message, 'secondary', {})
								);
							}
							break;
						}
						case 'primaryPhone':
							fieldName = 'phones.primary.value';
							break;
						case 'primaryPhoneType':
							fieldName = 'phones.primary.type';
							break;
						case 'primaryPhoneConsentToText':
							fieldName = 'phones.primary.consent_to_text';
							break;
						case 'secondaryPhone':
							fieldName = 'phones.secondary.value';
							break;
						case 'secondaryPhoneType':
							fieldName = 'phones.secondary.type';
							break;
						case 'guardianPrimaryPhone':
							fieldName = 'user_guardian.phones.primary.value';
							break;
						case 'guardianPrimaryPhoneType':
							fieldName = 'user_guardian.phones.primary.type';
							break;
						case 'guardianPrimaryPhoneConsentToText':
							fieldName =
								'user_guardian.phones.primary.consent_to_text';
							break;
						case 'guardianSecondaryPhone':
							fieldName = 'user_guardian.phones.secondary.value';
							break;
						case 'guardianSecondaryPhoneType':
							fieldName = 'user_guardian.phones.secondary.type';
							break;
						case 'guardianSecondaryConsentToText':
							fieldName =
								'user_guardian.phones.secondary.consent_to_text';
							break;
						case 'users_guardian.dob':
							fieldName = 'guardianDob';
							break;
						case 'partner_id':
							fieldName = 'selectedReferralProgram';
							break;
						case 'original_referral_date':
							fieldName = 'selectedReferralDate';
							break;
						case 'users_guardian.relationship':
							fieldName = 'guardianRelationship';
							break;
						case 'address_line1':
							fieldName = 'address1';
							break;
						case 'address_line2':
							fieldName = 'address2';
							break;
						case 'city':
							fieldName = 'city';
							break;
						case 'state':
							fieldName = 'state';
							break;
						case 'zip':
							fieldName = 'zip';
							break;
						case 'employeeId':
						case 'country_code':
							fieldName = 'employeeId';
							break;
						case 'relationship':
							fieldName = 'relationship';
							break;
						case 'employeeReferralCode':
							fieldName = 'employeeReferralCode';
							break;
						case 'sexAssignedAtBirth':
							fieldName = 'sexAssignedAtBirth';
							break;
						case 'race':
							fieldName = 'primaryRace';
							break;
						case 'secondary_races':
							fieldName = 'secondaryRaces';
							break;
						case 'ethnicity':
							fieldName = 'ethnicity';
							break;
						case 'duplicate_record':
							fieldName = 'duplicateRecord';
							message = get(
								fields,
								'duplicate_record.primary.validFormat',
								''
							);
							break;
						default:
							fieldName = field;
							break;
					}
					errorFields[fieldName] = message;
				}
			}
			const guardianFields = get(fields, 'users_guardian', null);
			if (
				typeof guardianFields === 'object' &&
				!Array.isArray(guardianFields) &&
				guardianFields
			) {
				for (const guardianField in guardianFields) {
					let message = get(
						Object.values(guardianFields[guardianField]),
						'[0]',
						''
					);
					if (message) {
						let guardianFieldName;
						switch (guardianField) {
							case 'guardianPrimaryPhone':
								guardianFieldName = 'guardianPrimaryPhone';
								message = 'Phone number is invalid';
								break;
							case 'guardianSecondaryPhone':
								guardianFieldName = 'guardianSecondaryPhone';
								message = 'Phone number is invalid';
								break;
							case 'first_name':
								guardianFieldName = 'guardianFirstName';
								break;
							case 'last_name':
								guardianFieldName = 'guardianLastName';
								break;
							case 'relationship':
								guardianFieldName = 'guardianRelationship';
								break;
							case 'dob':
								guardianFieldName = 'guardianDob';
								break;
						}
						errorFields[guardianFieldName] = message;
					}
				}
			}
			this.setState({ errorFields }, resolve);
		});
	}

	setAddedReferringProviderValue() {
		const inputtedValue = get(this, 'state.addReferringProviderInput', '');
		if (inputtedValue) {
			const referralProviders = get(
				this,
				'state.referralProvidersEnum',
				[]
			);
			const newReferralProvider = _.find(referralProviders, (el) => {
				return get(el, 'external', null) === inputtedValue;
			});
			if (newReferralProvider) {
				this.setState(
					{ referringProvider: get(newReferralProvider, 'internal') },
					() => this.setState({ addReferringProviderInput: '' })
				);
			}
		}
	}

	determineHasFutureBookedAppointments() {
		const encounters = this.getEncounterList() || [];
		return !!_.find(encounters, (el) => {
			return (
				el.type === 'visit' &&
				el.visit_status == 'booked' &&
				this.determineFuture(el)
			);
		});
	}

	determineFuture(encounter) {
		const timeOfService = get(
			encounter,
			'date_of_service',
			'1970-01-01 00:00:00'
		);
		const timeOfServiceObj = moment.utc(timeOfService).toDate();
		const currentTimeObj = new Date();

		if (
			timeOfServiceObj.getUTCHours() === 0 &&
			timeOfServiceObj.getUTCMinutes() === 0 &&
			timeOfServiceObj.getUTCSeconds() === 0 &&
			timeOfServiceObj.getUTCMilliseconds() === 0
		) {
			currentTimeObj.setUTCHours(0);
			currentTimeObj.setUTCMinutes(0);
			currentTimeObj.setUTCSeconds(0);
			currentTimeObj.setUTCMilliseconds(0);
		}

		return timeOfServiceObj.getTime() >= currentTimeObj.getTime();
	}

	determineCanNotRemoveGuardian() {
		const patient = this.getPatient();
		return this.determineIsEdit() && get(patient, 'has_guardian', false);
	}

	addAnother(header) {
		switch (header) {
			case 'referringProvider':
				if (
					(
						get(this, 'state.addReferringProviderInput', '') || ''
					).trim() &&
					this.addAnotherProviderUniquenessCheck()
				) {
					this.props.addReferringProvider({
						name: get(
							this,
							'state.addReferringProviderInput',
							null
						),
					});
				}
				break;
		}
	}

	addAnotherProviderUniquenessCheck() {
		const inputtedValue = get(this, 'state.addReferringProviderInput', '');
		const referralProviders = get(this, 'state.referralProvidersEnum', []);
		const newReferralProvider = _.find(referralProviders, (el) => {
			return get(el, 'external', null) === inputtedValue;
		});
		if (newReferralProvider) {
			const errorFields = get(this, 'state.errorFields', {});
			errorFields['referringProvider'] = 'Provider already exists';
			this.setState({ errorFields });
			return false;
		} else return true;
	}

	currentReferralProgramHasEligibility() {
		const referralProgramId =
			get(this, 'state.selectedReferralProgram', '') || '';
		const referralPrograms = get(this, 'state.referralProgramsEnum', []);
		if (referralProgramId && Array.isArray(referralPrograms)) {
			const filteredArr = referralPrograms.filter((el) => {
				return get(el, 'internal', '') === referralProgramId;
			});
			return get(filteredArr, '[0].eligibilityRequired', false);
		} else return '';
	}

	getPartnerUUID(props = this.props, state = this.state) {
		const referralProgramEnums = get(props, 'referralPrograms.data', []);
		const selectedRef = get(state, 'selectedReferralProgram', null);
		const findValue = referralProgramEnums.find((a) => a.id == selectedRef);
		return findValue?.uuid;
	}

	getReferralProgramFromID() {
		const referralProgramId =
			get(this, 'state.selectedReferralProgram', '') || '';
		const referralPrograms = get(this, 'state.referralProgramsEnum', []);
		if (referralProgramId && Array.isArray(referralPrograms)) {
			const filteredArr = referralPrograms.filter((el) => {
				return get(el, 'internal', '') === referralProgramId;
			});
			return get(filteredArr, '[0].alternate', '') || '';
		} else return '';
	}

	processEligibilityFields() {
		const fields = get(this, 'props.eligibilityFields', []);
		if (Array.isArray(fields)) {
			this.setState(
				{
					eligibilityFields: {
						allFields: this.getAllEligibilityFields(),
						requiredFields: this.getRequiredEligibilityFields(),
					},
				},
				this.setRequiredFields
			);
		} else {
			this.setState(
				{
					eligibilityFields: {
						allFields: [],
						requiredFields: [],
					},
				},
				this.setRequiredFields
			);
		}
	}

	getAllEligibilityFields() {
		const fields = get(this, 'props.eligibilityFields', []);
		return fields.map((el) => get(el, 'name', ''));
	}

	getRequiredEligibilityFields() {
		const fields = get(this, 'props.eligibilityFields', []);
		return fields
			.filter((el) => get(el, 'required', false))
			.map((el) => get(el, 'name', ''));
	}

	getEligibilityFieldOptions(el) {
		const values = get(el, 'values', []);
		if (Array.isArray(values)) {
			return values.map((el) => {
				return {
					key: el,
					label: el,
					value: el,
				};
			});
		}
	}

	getCombinedDuplicatesArray() {
		const existingDuplicatePatients = get(
			this,
			'state.existingDuplicatePatients',
			{}
		);
		const transformedExisting = Object.keys(
			existingDuplicatePatients
		).filter((uuid) => existingDuplicatePatients[uuid]);
		const pendingDuplicatePatients = get(
			this,
			'state.pendingDuplicatePatients',
			[]
		);
		return [...transformedExisting, ...pendingDuplicatePatients].filter(
			(uuid) => !!uuid
		);
	}

	openDuplicatePatientTooltip() {
		this.setState({ duplicatePatientTooltipOpen: true });
	}

	closeDuplicatePatientTooltip() {
		this.setState({ duplicatePatientTooltipOpen: false });
	}

	openAddressModal(isEdit, idx) {
		this.setState({
			showAddressModal: true,
			addressModalEdit: isEdit,
			addressModalIdx: idx,
		});
	}

	closeAddressModal() {
		this.setState({
			showAddressModal: false,
			addressModalEdit: false,
			addressModalIdx: null,
		});
	}

	changeOffset(e, offset) {
		this.setState(
			{ offset: offset, ignoreSpinners: true, offsetOverride: true },
			this.buildDuplicatePatientCheckRequest
		);
	}

	setBodyElement(el) {
		if (el)
			this.setState({ bodyElement: el }, () =>
				this.determineDropShadow(['top', 'bottom'])
			);
	}

	determineDropShadow(sides) {
		if (Array.isArray(sides) && get(this, 'state.bodyElement', null)) {
			sides.forEach((side) => {
				let shadow;
				switch (side) {
					case 'top':
						shadow = determineTopDropShadow(
							get(this, 'state.bodyElement', null)
						);
						this.setState({ topDropShadow: shadow });
						break;
					case 'bottom':
						shadow = determineBottomDropShadow(
							get(this, 'state.bodyElement', null)
						);
						this.setState({ bottomDropShadow: shadow });
						break;
					default:
						console.log('Unrecognized modal side.');
						break;
				}
			});
		}
	}

	buildDuplicatePatientCheckRequest() {
		const payload = {
			offset: get(this, 'state.offsetOverride', null)
				? get(this, 'state.offset', 0)
				: 0,
			dob: moment.utc(get(this, 'state.dob', null)).format('YYYY-MM-DD'),
			email: get(this, 'state.email', null),
			last_name: get(this, 'state.lastName', null),
			first_name: get(this, 'state.firstName', null),
			is_unborn: get(this, 'state.unbornSelection', false),
		};

		if (get(this, 'state.noEmail', false)) {
			delete payload.email;
		}

		if (get(this, 'state.unbornSelection', false)) {
			delete payload.dob;
			payload.first_name = 'Baby';
			payload.last_name = get(this, 'state.guardianLastName', null);
		}

		this.props.duplicatePatientCheck(trimAllTextFields(payload));
	}

	isSecondaryInsuranceEmpty() {
		return _.every(this.state.secondaryinsurance, (value, key) => {
			let result = false;
			if (key == 'is_policyholder' && value == true) result = true;
			else if (typeof value == 'string') {
				result = _.isEmpty(value);
			} else {
				result = _.isNil(value);
			}
			return result;
		});
	}

	buildCreateRequest() {
		const eligibilityFields = get(
			this,
			'state.eligibilityFields.allFields',
			[]
		);
		const patient = this.getPatient();

		this.setState({ addressErrors: [] });

		let payload = {
			consent_to_treat: get(this, 'state.consentToTreat', null),
			dob: moment.utc(get(this, 'state.dob', null)).format('YYYY-MM-DD'),
			email: get(this, 'state.email', null),
			first_name: get(this, 'state.firstName', null),
			last_name: get(this, 'state.lastName', null),
			gender: get(this, 'state.biologicalSex', null),
			original_referral_date: moment
				.utc(get(this, 'state.selectedReferralDate', null))
				.toDate(),
			partner_id: get(this, 'state.selectedReferralProgram', null),
			// add next two lines with CS-505
			// default_specialty_id: this.state.defaultSpecialty || null,
			// default_consultation_id: this.state.defaultConsultationType || null,
			sex_assigned_at_birth: get(this, 'state.sexAssignedAtBirth', null),
			external_patient_id: get(this, 'state.externalProviderID', null),
			referral_reasons: get(this, 'state.referralReason', []),
			insurance: [],
		};

		const disableDefaultSpecialtyPayload = !this.state.defaultSpecialty;
		payload = !disableDefaultSpecialtyPayload
			? {
					...payload,
					default_specialty_id: this.state.defaultSpecialty,
			  }
			: payload;

		const disableDefaultConsultationTypePayload =
			!this.state.defaultConsultationType;
		payload = !disableDefaultConsultationTypePayload
			? {
					...payload,
					default_consultation_id: this.state.defaultConsultationType,
			  }
			: payload;

		if (get(this, 'state.primaryinsurance.insurance_company_id', 0) > 0) {
			const primaryinsuranceObj = get(this, 'state.primaryinsurance', {});
			if (
				!_.isNil(get(primaryinsuranceObj, ['policyholder_dob'])) &&
				!_.isEmpty(get(primaryinsuranceObj, ['policyholder_dob']))
			) {
				primaryinsuranceObj['policyholder_dob'] = moment
					.utc(get(primaryinsuranceObj, 'policyholder_dob', ''))
					.format('YYYY-MM-DD');
			}
			payload.insurance.push({
				...primaryinsuranceObj,
				insurance_type: 'primary',
			});
		}
		if (!this.isSecondaryInsuranceEmpty()) {
			const secondaryinsuranceObj = get(
				this,
				'state.secondaryinsurance',
				{}
			);
			if (
				!_.isNil(get(secondaryinsuranceObj, ['policyholder_dob'])) &&
				!_.isEmpty(get(secondaryinsuranceObj, ['policyholder_dob']))
			) {
				secondaryinsuranceObj['policyholder_dob'] = moment
					.utc(get(secondaryinsuranceObj, 'policyholder_dob', ''))
					.format('YYYY-MM-DD');
			}
			payload.insurance.push({
				..._.omitBy(
					secondaryinsuranceObj,
					(v) => _.isUndefined(v) || _.isNull(v) || v === ''
				),
				insurance_type: 'secondary',
			});
		}

		const patientPhones = {};
		if (!_.isEmpty(get(this, 'state.primaryPhone', ''))) {
			patientPhones['primary'] = {
				type: get(this, 'state.primaryPhoneType', null),
				value: get(this, 'state.primaryPhone', null),
				consent_to_text: get(
					this,
					'state.primaryPhoneConsentToText',
					false
				),
			};
		}
		if (!_.isEmpty(get(this, 'state.secondaryPhone', ''))) {
			patientPhones['secondary'] = {
				type: get(this, 'state.secondaryPhoneType', null),
				value: get(this, 'state.secondaryPhone', null),
			};
		}
		payload.phones = patientPhones;

		if (get(this, 'state.referringProvider', null))
			payload.referral_provider_id = get(
				this,
				'state.referringProvider',
				null
			);

		if (this.determineIsEdit()) {
			payload.preferred_name = get(this, 'state.preferredName', null);
			payload.pronouns = get(this, 'state.pronouns', []);
			payload.sex_assigned_at_birth =
				!_.isNil(get(this, 'state.sexAssignedAtBirth', null)) &&
				get(this, 'state.sexAssignedAtBirth', null) != 'unknown'
					? get(this, 'state.sexAssignedAtBirth', null)
					: undefined;
		} else {
			if (get(this, 'state.preferredName', null))
				payload.preferred_name = get(this, 'state.preferredName', null);
			if (get(this, 'state.pronouns.length', 0) > 0) {
				payload.pronouns = get(this, 'state.pronouns', []);
			}
			payload.sex_assigned_at_birth = !_.isNil(
				get(this, 'state.sexAssignedAtBirth', null)
			)
				? get(this, 'state.sexAssignedAtBirth', null)
				: undefined;
		}

		if (this.props.referralProgramDetail?.showMedicareQuestion === false) {
			if (this.props.patientDetail.is_medicare_beneficiary === true) {
				this.setState({
					medicareSelection: false,
					// this logic is in reverse mode cause of line 2903
				});
				payload.is_medicare_beneficiary = true;
			} else {
				this.setState({
					medicareSelection: true,
					// this logic is in reverse mode cause of line 2903
				});
				payload.is_medicare_beneficiary = false;
			}
		}

		if (get(this, 'state.medicareSelection', null) != null) {
			if (get(this, 'state.medicareSelection', false)) {
				payload.is_medicare_beneficiary = true;
			} else {
				payload.is_medicare_beneficiary = false;
			}
		}
		if (get(this, 'state.unbornSelection', false)) {
			if (!this.determineIsEdit()) {
				delete payload.first_name;
				delete payload.last_name;
				delete payload.dob;
				delete payload.gender;
			} else {
				if (!get(this, 'state.dob', '')) delete payload.dob;
			}
			payload.is_unborn = true;
			payload.is_medicare_beneficiary = false;
		} else {
			payload.is_unborn = false;
		}

		if (
			get(this, 'state.unbornSelection', false) ||
			get(this, 'state.guardianSelection', false)
		) {
			payload.has_guardian = true;
			payload.users_guardian = {
				first_name: get(this, 'state.guardianFirstName', null),
				last_name: get(this, 'state.guardianLastName', null),
				relationship: get(this, 'state.guardianRelationship', null),
			};
			const guardianPhones = {};
			if (!_.isEmpty(get(this, 'state.guardianPrimaryPhone', ''))) {
				guardianPhones['primary'] = {
					type: get(this, 'state.guardianPrimaryPhoneType', null),
					value: get(this, 'state.guardianPrimaryPhone', null),
					consent_to_text: get(
						this,
						'state.guardianPrimaryPhoneConsentToText',
						false
					),
				};
			}
			if (!_.isEmpty(get(this, 'state.guardianSecondaryPhone', ''))) {
				guardianPhones['secondary'] = {
					type: get(this, 'state.guardianSecondaryPhoneType', null),
					value: get(this, 'state.guardianSecondaryPhone', null),
				};
			}
			payload['users_guardian']['phones'] = guardianPhones;

			if (get(this, 'state.unbornSelection', false)) {
				payload.users_guardian.dob = moment
					.utc(get(this, 'state.guardianDob', null))
					.format('YYYY-MM-DD');
			}
			if (this.determineIsEdit()) {
				payload.users_guardian.preferred_name = get(
					this,
					'state.guardianPreferredName',
					null
				);
				payload.users_guardian.pronouns = get(
					this,
					'state.guardianPronouns',
					[]
				);
			} else {
				if (get(this, 'state.guardianPreferredName', null))
					payload.users_guardian.preferred_name = get(
						this,
						'state.guardianPreferredName',
						null
					);
				if (get(this, 'state.guardianPronouns.length', 0) > 0) {
					payload.users_guardian.pronouns = get(
						this,
						'state.guardianPronouns',
						[]
					);
				}
			}

			delete payload.phones;
		} else {
			payload.has_guardian = false;
		}

		if (get(this, 'state.noEmail', false)) {
			payload.is_noemail = true;
			if (payload.email) delete payload.email;
			if (payload.users_guardian && payload.users_guardian.email)
				delete payload.users_guardian.email;
		} else {
			payload.is_noemail = false;
		}

		if (this.determineIsEdit()) {
			const addresses = get(this, 'state.addresses', null);
			const patientId = get(patient, 'id', null);
			if (Array.isArray(addresses) && get(addresses, 'length', 0)) {
				payload.user_address = _.cloneDeep(addresses);
				payload.user_address.forEach((address) => {
					if (!get(address, 'address_line2', null))
						delete address.address_line2;
					if (address.created_at) delete address.created_at;
					if (address.modified_at) delete address.modified_at;
					if (
						get(this, 'state.unbornSelection', false) ||
						get(this, 'state.guardianSelection', false)
					) {
						address.full_name = `${get(
							this,
							'state.guardianFirstName',
							null
						)} ${get(this, 'state.guardianLastName', null)}`;
					} else {
						address.full_name = `${get(
							this,
							'state.firstName',
							null
						)} ${get(this, 'state.lastName', null)}`;
					}
					if (!get(address, 'user_id', null) && patientId)
						address.user_id = patientId;
				});
			}
		} else {
			const createPatientAddress = this.getCreatePatientAddressPayload();
			if (createPatientAddress)
				payload.user_address = createPatientAddress;
		}

		if (Array.isArray(eligibilityFields)) {
			eligibilityFields.forEach((el) => {
				if (el) payload[el] = get(this, `state.${el}`, null);
			});
			if (payload.hasOwnProperty('employeeId'))
				payload.employee_id = payload.employeeId;
			if (payload.hasOwnProperty('relationship'))
				payload.relationship_to_patient = payload.relationship;
			if (this.currentReferralProgramHasEligibility()) {
				payload.partner_name = this.getReferralProgramFromID();
			}
		}

		const tagsPayload = this.constructTagsPayload();
		if (tagsPayload) payload.user_tags = tagsPayload;

		const ethnicity = get(this, 'state.ethnicity', null);
		const primaryRace = get(this, 'state.primaryRace', null);
		const secondaryRaces = get(this, 'state.secondaryRaces', []);
		const creatingUnbornPatient =
			!this.determineIsEdit() &&
			get(this, 'state.unbornSelection', false);

		if (ethnicity && !creatingUnbornPatient) {
			payload.ethnicity = ethnicity;
		}

		if (
			primaryRace &&
			Array.isArray(secondaryRaces) &&
			!creatingUnbornPatient
		) {
			payload.race = primaryRace;
			payload.secondary_races = secondaryRaces;
		}

		if (!get(this, 'state.duplicate', true)) {
			payload.duplicate_patient_uuid = [];
		} else {
			payload.duplicate_patient_uuid = this.getCombinedDuplicatesArray();
		}

		payload = trimPayload(_.cloneDeep(payload));
		if (this.determineIsEdit()) {
			this.createEditRequest(payload);
		} else {
			this.props.callAddPatient(payload);
		}
	}

	getCreatePatientAddressPayload() {
		if (get(this, 'state.nonEmptyAddress', false)) {
			const createAddress1 = get(this, 'state.createAddress1', null);
			const createAddress2 = get(this, 'state.createAddress2', null);
			const createCity = get(this, 'state.createCity', null);
			const createState = get(this, 'state.createState', null);
			const createZip = get(this, 'state.createZip', null);
			const createAddress1Shipping = get(
				this,
				'state.createAddress1Shipping',
				null
			);
			const createAddress2Shipping = get(
				this,
				'state.createAddress2Shipping',
				null
			);
			const createCityShipping = get(
				this,
				'state.createCityShipping',
				null
			);
			const createStateShipping = get(
				this,
				'state.createStateShipping',
				null
			);
			const createZipShipping = get(
				this,
				'state.createZipShipping',
				null
			);
			const sameShippingAddress = get(
				this,
				'state.sameShippingAddress',
				true
			);
			const nonEmptyShippingAddress = get(
				this,
				'state.nonEmptyShippingAddress',
				false
			);
			const payload = [];
			const homeAddress = {
				address_line1: createAddress1,
				city: createCity,
				state: createState,
				zip: createZip,
				country: 'US',
				type: ['home'].concat(sameShippingAddress ? ['delivery'] : []),
			};
			if (createAddress2) homeAddress.address_line2 = createAddress2;
			payload.push(homeAddress);

			if (!sameShippingAddress && nonEmptyShippingAddress) {
				const shippingAddress = {
					address_line1: createAddress1Shipping,
					city: createCityShipping,
					state: createStateShipping,
					zip: createZipShipping,
					type: ['delivery'],
					country: 'US',
				};
				if (createAddress2Shipping)
					shippingAddress.address_line2 = createAddress2Shipping;
				payload.push(shippingAddress);
			}

			payload.forEach((address) => {
				if (
					get(this, 'state.unbornSelection', false) ||
					get(this, 'state.guardianSelection', false)
				) {
					address.full_name = `${get(
						this,
						'state.guardianFirstName',
						null
					)} ${get(this, 'state.guardianLastName', null)}`;
				} else {
					address.full_name = `${get(
						this,
						'state.firstName',
						null
					)} ${get(this, 'state.lastName', null)}`;
				}
			});

			return payload;
		} else return null;
	}

	createEditRequest(payload = {}) {
		this.createAddressRequest(payload);
		this.UpdateConsentStatusRequest();
		this.createEditPatientRequest(payload);
	}

	createAddressRequest(payload) {
		if (
			payload.hasOwnProperty('user_address') &&
			get(this, 'state.addressChanged', true)
		) {
			const data = {};
			const patient = this.getPatient();
			const addressObj = payload.user_address;
			data.patient_id = get(patient, 'uuid', null);
			data.payload = addressObj;
			this.props.updateAddress(data);
			delete payload.user_address;
		}
	}

	UpdateConsentStatusRequest() {
		const { updateConsentStatus } = this.props;
		const patient = this.getPatient();
		const latestPatientToRecontactStatusObject = _.get(
			patient,
			['latestPatientToRecontactStatusObject'],
			null
		);
		if (
			get(
				latestPatientToRecontactStatusObject,
				['patient_consent', 'status'],
				null
			) == PatientConsentStatus.CONSENTED &&
			get(this.state, ['patientAuthorizationToRecontact'], null) == false
		) {
			const data = {
				consent_version_id: get(patient, [
					'latestPatientToRecontactStatusObject',
					'patient_consent',
					'consent_version_id',
				]),
				status: PatientConsentStatus.REVOKED,
			};
			updateConsentStatus(get(patient, 'uuid'), data);
		}
	}

	createEditPatientRequest(payload) {
		const patient = this.getPatient();
		if (payload.hasOwnProperty('user_address')) delete payload.user_address;

		if (payload.hasOwnProperty('relationship')) {
			this.createRelationshipEditRequest(payload.relationship);
			delete payload.relationship;
		}
		if (payload.hasOwnProperty('relationship_to_patient'))
			delete payload.relationship_to_patient;
		if (payload.hasOwnProperty('partner_name')) delete payload.partner_name;

		get(this, 'state.payloadComparisonFields', []).forEach((el) => {
			if (
				payload.hasOwnProperty(el) &&
				_.isEqual(payload[el], get(patient, el, null))
			) {
				if (payload.hasOwnProperty('race') && el === 'secondary_races')
					return;
				delete payload[el];
			}
		});

		if (
			payload.hasOwnProperty('referral_reasons') &&
			_.isEqual(
				payload.referral_reasons,
				get(patient, 'referral_reason_ids', null)
			)
		)
			delete payload.referral_reasons;

		const data = {};
		data.patient_id = get(patient, 'uuid', null);
		data.payload = payload;
		this.props.editPatient(data);
	}

	createRelationshipEditRequest(relationship) {
		const patient = this.getPatient();
		const method = get(patient, 'employee_relationship', null)
			? 'patch'
			: 'post';

		const payload = {
			relationship: relationship,
		};

		const obj = {
			patient_id: get(patient, 'uuid', null),
			method: method,
			payload,
		};

		this.props.updateRelationship(obj);
	}

	constructTagsPayload() {
		let selectedTags = this.getSelectedTags() || [];
		selectedTags = selectedTags.filter(
			(tag) => !!get(tag, 'internalValue', null)
		);
		if (Array.isArray(selectedTags)) {
			return selectedTags.map((tag) => {
				const internalValue = get(tag, 'internalValue', null);
				const subValue = get(tag, 'subValue', null);
				const tagType = get(tag, 'tagType', null);

				if (tagType === 'duplicate') return;

				const tagObj = { tag_id: internalValue };

				if (subValue) {
					switch (tagType) {
						case 'language_line_support':
							tagObj.tag_properties = [{ language: typeof subValue === 'string' ? [subValue] : subValue }];
							break;
						case 'priority_patient':
							tagObj.tag_properties = [{ reason: typeof subValue === 'string' ? [subValue] : subValue }];
							break;
					}
				}
				return tagObj;
			});
		}
	}

	getSelectedProvider = () => {
		const { providerUUID, externalProviders } = this.state;
		return (externalProviders || []).find(
			(el) => el.providerUUID == providerUUID
		);
	};

	handleProviderLinkDialog = () => {
		this.setState(
			{ showProviderLinkDialog: !this.state.showProviderLinkDialog },
			() => {
				if (!this.state.showProviderLinkDialog) {
					this.setState({
						providerUUID: null,
						editingProvider: false,
						editingLinkInfo: false,
					});
				}
			}
		);
	};

	handleProviderUnlinkDialog = () => {
		this.setState(
			{ showProviderUnlinkDialog: !this.state.showProviderUnlinkDialog },
			() => {
				if (!this.state.showProviderUnlinkDialog) {
					this.setState({ providerUUID: null });
				}
			}
		);
	};

	unlinkProviderInfo = (providerUUID) => {
		this.setState({ providerUUID }, () => {
			this.handleProviderUnlinkDialog();
		});
	};

	handleProviderUnlink = () => {
		const selectedProvider = this.getSelectedProvider();
		if (get(selectedProvider, 'savedRecord', false)) {
			const { patientUUID, providerUUID } = selectedProvider;
			this.props.unlinkProvider(patientUUID, providerUUID);
		} else {
			const { providerUUID } = this.state;
			this.removeExternalProvider(providerUUID);
		}
	};

	editLinkInfo = (providerUUID) => {
		this.setState({
			providerUUID,
			showProviderLinkDialog: true,
			editingLinkInfo: true,
		});
	};

	editProviderInfo = (providerUUID) => {
		this.setState({
			providerUUID,
			showProviderLinkDialog: true,
			editingProvider: true,
		});
	};

	getExternalProviderProps() {
		const _defaultProviders = this.props?.referralProgramDetail?.defaultProviders;
		const _externalProviders = get(this.state, 'externalProviders', []).map(ep => ({
			...ep,
			isDefaultProvider: _defaultProviders && _defaultProviders.some(dp => dp.uuid === ep.providerUUID) || false,
		}));
		return {
			handleProviderLinkDialog: this.handleProviderLinkDialog,
			handleProviderUnlinkDialog: this.handleProviderUnlinkDialog,
			editLinkInfo: this.editLinkInfo,
			editProviderInfo: this.editProviderInfo,
			unlinkProviderInfo: this.unlinkProviderInfo,
			externalProviders: _externalProviders,
		};
	}

	addExternalProvider = (data) => {
		if (get(data, 'savedRecord', false)) {
			this.setState(
				{ showProviderLinkDialog: false, providerUUID: null },
				() => {
					const { editingProvider } = this.state;
					if (editingProvider) {
						this.setState({ editingProvider: false }, () => {
							this.props.getLinkedProviders(
								this.getPatientUuid()
							);
						});
					} else {
						const {
							patientUUID,
							providerUUID,
							providerRole,
							receiveAllComms,
						} = data;
						this.props.updateLinkedProvider(
							patientUUID,
							providerUUID,
							{
								provider_role: providerRole || [],
								receive_all_communications:
									receiveAllComms || false,
							}
						);
					}
				}
			);
		} else {
			const { editingProvider } = this.state;
			if (this.determineIsEdit() && !editingProvider) {
				this.setState(
					{ showProviderLinkDialog: false, providerUUID: null },
					() => {
						this.linkExternalProviders(
							[data],
							this.getPatientUuid()
						);
					}
				);
			} else {
				const selectedProvider = this.getSelectedProvider();
				const externalProviders = [...this.state.externalProviders];
				if (selectedProvider) {
					if (editingProvider) {
						const { providerUUID } = data;
						const index = _.findIndex(externalProviders, {
							providerUUID: providerUUID,
						});
						if (get(externalProviders, index)) {
							const updatedData = {
								...selectedProvider,
								...data,
							};
							externalProviders[index] = this.determineIsEdit()
								? { ...updatedData, savedRecord: true }
								: updatedData;
						}
						this.setState({
							externalProviders: externalProviders,
							showProviderLinkDialog: false,
							providerUUID: null,
							editingProvider: false,
						});
					} else {
						const { providerUUID } = selectedProvider;
						const index = _.findIndex(externalProviders, {
							providerUUID: providerUUID,
						});
						if (get(externalProviders, index))
							externalProviders[index] = data;
						this.setState({
							externalProviders: externalProviders,
							showProviderLinkDialog: false,
							providerUUID: null,
						});
					}
				} else {
					const { externalProviders } = this.state;
					const updated = _.concat(externalProviders, data);
					this.setState({
						externalProviders: updated,
						showProviderLinkDialog: false,
						providerUUID: null,
					});
				}
			}
		}
	};

	removeExternalProvider = (providerUUID) => {
		const { externalProviders } = this.state;
		const updated = (externalProviders || []).filter(
			(el) => el.providerUUID != providerUUID
		);
		this.setState({
			externalProviders: updated,
			showProviderUnlinkDialog: false,
		});
	};

	hasExternalProviders = () => {
		const { externalProviders } = this.state;
		const selectedExternalProvidersToAdd = !_.isEmpty(externalProviders);
		const defaultProvidersToAdd = !_.isEmpty(
			this.props.referralProgramDetail.defaultProviders
		);
		return selectedExternalProvidersToAdd || defaultProvidersToAdd
			? true
			: false;
	};

	getSelectedTags() {
		const tagsEnum = get(this, 'state.patientTagsEnum', []);
		const selectedTags = [];
		if (Array.isArray(tagsEnum)) {
			tagsEnum.forEach((el) => {
				const tagType = get(el, 'tagType', '');
				const internalValue = get(el, 'internal', null);
				const tagValue = {};
				const tagSubValue = get(this, `state.${tagType}SubValue`, null);
				if (get(this, `state.${tagType}`, false)) {
					tagValue.tagType = tagType;
					tagValue.internalValue = internalValue;
					if (get(el, 'hasDropdown', false))
						tagValue.subValue = tagSubValue;
					selectedTags.push(tagValue);
				}
			});
		}
		return selectedTags;
	}

	determinePatientHasAllAddressTypes() {
		const addresses = get(this, 'state.addresses', []);
		this.setState({
			hasAllAddressTypes: determineHasAllAddressTypes(addresses),
		});
	}

	handleAddressModalSave(data) {
		if (data) {
			this.setState(
				{ addresses: data, addressChanged: true },
				() => {
					this.determinePatientHasAllAddressTypes();
					this.closeAddressModal();
				},
				this.setRequiredFields()
			);
		}
	}

	userRoleIsCCGCOrProdeng() {
		const { userRole } = this.props;
		return new Set([ROLE_GCA, ROLE_CARECOORDINATOR, ROLE_PRODENG]).has(
			userRole
		);
	}

	render() {
		const loading =
			get(this, 'state.loading', false) ||
			get(this, 'props.gettingReferralPrograms', false) ||
			get(this, 'props.gettingReferralProviders', false) ||
			get(this, 'props.referralReasonsLoading', false) ||
			get(this, 'props.consentToTreatLoading', false) ||
			get(this, 'props.addingPatient', false) ||
			get(this, 'props.updatingAddress', false) ||
			get(this, 'props.updatingPatient', false) ||
			get(this, 'props.gettingPatientDetail', false) ||
			get(this, 'props.updatingRelationship', false) ||
			get(this, 'props.updatingConsent', false) ||
			get(this, 'props.duplicatePatientCheckLoading', false) ||
			get(this, 'props.linkingProviders', false) ||
			get(this, 'props.linkingProviders', false) ||
			get(this, 'props.gettingLinkedProviders', false) ||
			get(this, 'props.updatingLinkedProvider', false) ||
			get(this, 'props.unlinkingProvider', false) ||
			get(this, 'props.patientTagsLoading', false) ||
			get(this, 'props.genderLoading', false) ||
			get(this, 'props.pronounsLoading', false) ||
			get(this, 'props.sexAssignedAtBirthLoading', false) ||
			get(this, 'props.raceEnumLoading', false) ||
			get(this, 'props.ethnicityEnumLoading', false);
		const allRequiredFieldsFilled =
			get(this, 'state.allRequiredFieldsFilled', false) &&
			get(this, 'state.referringProviderIsDropdown', false);
		if (
			(loading && !get(this, 'state.ignoreSpinners', false)) ||
			(this.determineIsEdit() &&
				get(this, 'state.patientNeedsToBeInitialized', false))
		) {
			return (
				<div className='patientCreateComponentContainer'>
					<Loading
						loading={true}
						className='patientCreateV2Spinner'
					/>
				</div>
			);
		} else {
			return (
				<RBAC
					action={this.determineRBACPermission()}
					yes={
						<div className='patientCreateComponentContainer'>
							<div className='patientCreateV2Container'>
								<TitleRow
									alternativeTitle={
										this.determineIsEdit()
											? 'Edit Patient'
											: null
									}
									shouldHaveDropShadow={get(
										this,
										'state.topDropShadow',
										false
									)}
								/>
								<CreatePatientV2Body
									editMode={this.determineIsEdit()}
									changeValue={this.changeValue}
									selectedReferralProgram={get(
										this,
										'state.selectedReferralProgram',
										null
									)}
									selectedReferralDate={get(
										this,
										'state.selectedReferralDate',
										null
									)}
									unbornSelection={get(
										this,
										'state.unbornSelection',
										false
									)}
									medicareSelection={get(
										this,
										'state.medicareSelection',
										null
									)}
									firstName={get(this, 'state.firstName', '')}
									lastName={get(this, 'state.lastName', '')}
									dob={get(this, 'state.dob', '')}
									biologicalSex={get(
										this,
										'state.biologicalSex',
										null
									)}
									guardianSelection={get(
										this,
										'state.guardianSelection',
										false
									)}
									guardianPrimaryPhone={get(
										this,
										'state.guardianPrimaryPhone',
										''
									)}
									guardianPrimaryPhoneType={get(
										this,
										'state.guardianPrimaryPhoneType',
										null
									)}
									guardianPrimaryPhoneConsentToText={get(
										this,
										'state.guardianPrimaryPhoneConsentToText',
										null
									)}
									guardianSecondaryPhone={get(
										this,
										'state.guardianSecondaryPhone',
										''
									)}
									guardianSecondaryPhoneType={get(
										this,
										'state.guardianSecondaryPhoneType',
										null
									)}
									guardianFirstName={get(
										this,
										'state.guardianFirstName',
										''
									)}
									guardianLastName={get(
										this,
										'state.guardianLastName',
										''
									)}
									guardianRelationship={get(
										this,
										'state.guardianRelationship',
										null
									)}
									guardianDob={get(
										this,
										'state.guardianDob',
										''
									)}
									guardianPronouns={get(
										this,
										'state.guardianPronouns',
										null
									)}
									guardianPreferredName={get(
										this,
										'state.guardianPreferredName',
										null
									)}
									email={get(this, 'state.email', '')}
									primaryPhone={get(
										this,
										'state.primaryPhone',
										''
									)}
									primaryPhoneType={get(
										this,
										'state.primaryPhoneType',
										null
									)}
									primaryPhoneConsentToText={get(
										this,
										'state.primaryPhoneConsentToText',
										null
									)}
									secondaryPhone={get(
										this,
										'state.secondaryPhone',
										''
									)}
									secondaryPhoneType={get(
										this,
										'state.secondaryPhoneType',
										null
									)}
									noEmail={get(this, 'state.noEmail', false)}
									externalProviderID={get(
										this,
										'state.externalProviderID',
										false
									)}
									referralReason={get(
										this,
										'state.referralReason',
										null
									)}
									referringProvider={get(
										this,
										'state.referringProvider',
										null
									)}
									consentToTreat={get(
										this,
										'state.consentToTreat',
										null
									)}
									consentToTreatPatient={get(
										this,
										'state.consentToTreatPatient',
										null
									)}
									guardianRelationships={get(
										this,
										'state.guardianRelationships',
										[]
									)}
									phoneTypes={get(
										this,
										'state.phoneTypes',
										[]
									)}
									referralReasonsEnum={get(
										this,
										'state.referralReasonsEnum',
										[]
									)}
									referralProvidersEnum={get(
										this,
										'state.referralProvidersEnum',
										[]
									)}
									referralProgramsEnum={get(
										this,
										'state.referralProgramsEnum',
										[]
									)}
									consentToTreatEnum={get(
										this,
										'state.consentToTreatEnum',
										[]
									)}
									genderEnum={get(
										this,
										'state.gendersEnum',
										[]
									)}
									preferredName={get(
										this,
										'state.preferredName',
										''
									)}
									sexAssignedAtBirth={get(
										this,
										'state.sexAssignedAtBirth',
										null
									)}
									pronouns={get(this, 'state.pronouns', null)}
									sexAssignedAtBirthEnum={get(
										this,
										'state.sexAssignedAtBirthEnum',
										[]
									)}
									pronounsEnum={get(
										this,
										'state.pronounsEnum',
										[]
									)}
									eligibilityRelationships={get(
										this,
										'state.eligibilityRelationships',
										[]
									)}
									statesEnum={states_list || []}
									errorFields={get(
										this,
										'state.errorFields',
										{}
									)}
									hasFutureBookedAppointments={this.determineHasFutureBookedAppointments()}
									canNotRemoveGuardian={
										this.determineCanNotRemoveGuardian() ||
										get(
											this,
											'state.canNotRemoveGuardian',
											false
										)
									}
									addAnother={this.addAnother}
									addReferringProviderInput={get(
										this,
										'state.addReferringProviderInput',
										''
									)}
									referringProviderIsDropdown={get(
										this,
										'state.referringProviderIsDropdown',
										true
									)}
									setReferringProviderIsDropdown={
										this.setReferringProviderIsDropdown
									}
									setReferringProviderIsNotDropdown={
										this.setReferringProviderIsNotDropdown
									}
									addReferringProviderLoading={
										get(
											this,
											'props.addReferringProviderLoading',
											false
										) ||
										get(
											this,
											'props.gettingReferralProviders',
											false
										)
									}
									eligibilityFieldsLoading={get(
										this,
										'props.eligibilityFieldsLoading',
										false
									)}
									eligibilityFieldsParsed={get(
										this,
										'state.eligibilityFields',
										{}
									)}
									eligibilityFields={get(
										this,
										'props.eligibilityFields',
										[]
									)}
									fullState={{ ...get(this, 'state', {}) }}
									getEligibilityFieldOptions={
										this.getEligibilityFieldOptions
									}
									patientConsentedByPartner={
										get(
											this.getPatient(),
											'consent_to_treat',
											false
										) === 'consented_via_partner'
									}
									scrollHandler={this.determineDropShadow}
									setBodyElement={this.setBodyElement}
									isSCPMode={this.currentReferralProgramIsSCP()}
									externalProviderProps={this.getExternalProviderProps()}
									isExternalUser={get(
										this,
										'props.isExternalUser',
										false
									)}
									tagsData={get(
										this,
										'state.patientTagsEnum',
										[]
									)}
									selectedTags={this.getSelectedTags()}
									openAddressModal={this.openAddressModal}
									hasAllAddressTypes={get(
										this,
										'state.hasAllAddressTypes',
										false
									)}
									addressData={get(
										this,
										'state.addresses',
										[]
									)}
									addressErrors={get(
										this,
										'state.addressErrors',
										[]
									)}
									createAddress1={get(
										this,
										'state.createAddress1',
										null
									)}
									createAddress2={get(
										this,
										'state.createAddress2',
										null
									)}
									createCity={get(
										this,
										'state.createCity',
										null
									)}
									createState={get(
										this,
										'state.createState',
										null
									)}
									createZip={get(
										this,
										'state.createZip',
										null
									)}
									createAddress1Shipping={get(
										this,
										'state.createAddress1Shipping',
										null
									)}
									createAddress2Shipping={get(
										this,
										'state.createAddress2Shipping',
										null
									)}
									createCityShipping={get(
										this,
										'state.createCityShipping',
										null
									)}
									createStateShipping={get(
										this,
										'state.createStateShipping',
										null
									)}
									createZipShipping={get(
										this,
										'state.createZipShipping',
										null
									)}
									sameShippingAddress={get(
										this,
										'state.sameShippingAddress',
										true
									)}
									nonEmptyAddress={get(
										this,
										'state.nonEmptyAddress',
										false
									)}
									nonEmptyShippingAddress={get(
										this,
										'state.nonEmptyShippingAddress',
										false
									)}
									providerSpecialties={get(
										this,
										'props.providerSpecialties',
										[]
									)}
									raceEnum={get(this, 'state.raceEnum', [])}
									ethnicityEnum={get(
										this,
										'state.ethnicityEnum',
										[]
									)}
									ethnicity={get(
										this,
										'state.ethnicity',
										null
									)}
									primaryRace={get(
										this,
										'state.primaryRace',
										null
									)}
									secondaryRaces={get(
										this,
										'state.secondaryRaces',
										[]
									)}
									existingDuplicatePatients={get(
										this,
										'state.existingDuplicatePatients',
										{}
									)}
									pendingDuplicatePatients={get(
										this,
										'state.pendingDuplicatePatients',
										[]
									)}
									updateExistingUuid={this.updateExistingUuid}
									addNewPendingUuid={this.addNewPendingUuid}
									handleChangePendingUuid={
										this.handleChangePendingUuid
									}
									removePendingUuid={this.removePendingUuid}
									primaryinsurance={get(
										this,
										'state.primaryinsurance',
										[]
									)}
									secondaryinsurance={get(
										this,
										'state.secondaryinsurance',
										[]
									)}
									doesPatientHasSeondaryInsurance={
										this.state
											.doesPatientHasSeondaryInsurance
									}
									planTypesEnums={this.props.planTypesEnums.map(
										(el) => {
											return {
												internal: el.name,
												external: el.display_name,
											};
										}
									)}
									insuranceCompanies={this.props.insuranceCompanies.map(
										(el) => {
											return {
												key: el.companyID,
												label: el.insuranceCompanyName,
												value: el.companyID,
											};
										}
									)}
									biologicalSexVariants={biologicalSexVariants.map(
										(el) => {
											return {
												internal: el.value,
												external: el.label,
											};
										}
									)}
									dependentVariants={dependentVariants.map(
										(el) => {
											return {
												key: el.label,
												value: el.value,
												label: el.label,
											};
										}
									)}
									OTHER_COMPANY={OTHER_COMPANY}
									defaultSchedulingOptions={
										this.props.defaultSchedulingOptions
											.payload
									}
									defaultSpecialty={
										this.state.defaultSpecialty
									}
									defaultConsultationType={
										this.state.defaultConsultationType
									}
									encounters={this.getEncounterList() || []}
									schedulingPlatform={
										this.props.referralProgramDetail
											?.schedulingPlatform
									}
									showMedicareQuestion={
										this.props.referralProgramDetail
											?.showMedicareQuestion
									}
									patientAuthorizationToRecontact={get(
										this,
										'state.patientAuthorizationToRecontact',
										false
									)}
									autorizationToRecontactIsConsented={
										get(
											this.getPatient(),
											[
												'latestPatientToRecontactStatusObject',
												'patient_consent',
												'status',
											],
											null
										) === PatientConsentStatus.CONSENTED
									}
									authorizationToRecontactConsentVersion={get(
										this.getPatient(),
										[
											'latestPatientToRecontactStatusObject',
											'consent_version',
										],
										null
									)}
									userRoleIsCCGCOrProdeng={this.userRoleIsCCGCOrProdeng()}
									activeRole={this.props?.userRole}
								/>
								<ButtonRow
									canAdvance={allRequiredFieldsFilled}
									handleCreatePatient={
										this.handleCreatePatient
									}
									alternativeButtonText={
										this.determineIsEdit() ? 'Save' : null
									}
									duplicatePatientTooltip={get(
										this,
										'state.duplicatePatientTooltip',
										false
									)}
									duplicatePatientData={get(
										this,
										'props.duplicatePatientCheckData',
										{}
									)}
									duplicatePatientTooltipOpen={get(
										this,
										'state.duplicatePatientTooltipOpen',
										false
									)}
									openDuplicatePatientTooltip={
										this.openDuplicatePatientTooltip
									}
									closeDuplicatePatientTooltip={
										this.closeDuplicatePatientTooltip
									}
									offset={get(this, 'state.offset', 0)}
									changeOffset={this.changeOffset}
									duplicatePatientCheckLoading={get(
										this,
										'props.duplicatePatientCheckLoading',
										false
									)}
									formPatientFirstName={get(
										this,
										'state.firstName',
										null
									)}
									formPatientLastName={get(
										this,
										'state.lastName',
										null
									)}
									formPatientDob={get(
										this,
										'state.dob',
										null
									)}
									formPatientEmail={get(
										this,
										'state.email',
										null
									)}
									formPatientNoEmail={get(
										this,
										'state.noEmail',
										null
									)}
									formPatientUnbornSelection={get(
										this,
										'state.unbornSelection',
										null
									)}
									formPatientMedicareSelection={get(
										this,
										'state.medicareSelection',
										null
									)}
									formPatientGuardianLastName={get(
										this,
										'state.guardianLastName',
										null
									)}
									shouldHaveDropShadow={get(
										this,
										'state.bottomDropShadow',
										false
									)}
								/>
							</div>
							{get(
								this,
								'state.showProviderLinkDialog',
								false
							) && (
								<ProviderLinkDialog
									visible={this.state.showProviderLinkDialog}
									cancel={this.handleProviderLinkDialog}
									confirm={this.handleProviderLinkDialog}
									addExternalProvider={
										this.addExternalProvider
									}
									selectedProvider={this.getSelectedProvider()}
									selectedProviders={get(
										this.state,
										'externalProviders',
										[]
									)}
									enqueueSnackbar={this.props.enqueueSnackbar}
									editingProvider={get(
										this.state,
										'editingProvider',
										false
									)}
									editingLinkInfo={get(
										this.state,
										'editingLinkInfo',
										false
									)}
								/>
							)}
							{get(
								this,
								'state.showProviderUnlinkDialog',
								false
							) && (
								<ProviderUnlinkDialog
									content={
										'Are you sure you want to unlink the external provider from this patient record?'
									}
									visible={
										this.state.showProviderUnlinkDialog
									}
									cancel={this.handleProviderUnlinkDialog}
									confirm={this.handleProviderUnlink}
								/>
							)}
							{get(this, 'state.showAddressModal', false) && (
								<AddressModal
									edit={get(
										this,
										'state.addressModalEdit',
										false
									)}
									idx={get(
										this,
										'state.addressModalIdx',
										null
									)}
									closeAddressModal={this.closeAddressModal}
									handleAddressModalSave={
										this.handleAddressModalSave
									}
									addressData={get(
										this,
										'state.addresses',
										[]
									)}
									addressErrors={get(
										this,
										'state.addressErrors',
										[]
									)}
								/>
							)}
						</div>
					}
					no={<div />}
				/>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	gettingReferralPrograms: state.referralPrograms.gettingReferralPrograms,
	referralPrograms: state.referralPrograms.referralPrograms,
	referralProgramsError: state.referralPrograms.referralProgramsError,
	referralReasonsLoading: state.referralReasons.referralReasonsLoading,
	referralReasons: state.referralReasons.referralReasons,
	referralReasonsError: state.referralReasons.referralReasonsError,
	gettingReferralProviders: state.referralProviders.gettingReferralProviders,
	referralProviders: state.referralProviders.referralProviders,
	referralProvidersError: state.referralProviders.referralProvidersError,
	consentToTreatLoading: state.getConsenttotreat.consentToTreatLoading,
	consenttotreat: excludeConsentViaPartner(
		get(
			state,
			['getConsenttotreat', 'consenttotreat', 'consent_to_treat'],
			[]
		),
		'key'
	),
	consentToTreatError: state.getConsenttotreat.consentToTreatError,
	addpatientresult: get(state, ['addpatient'], {}),
	gettingPatientDetail: state.patientdetail.gettingPatientDetail,
	patientDetail: get(state, 'patientdetail.patientdetail[0]', {}),
	addingPatient: state.addpatient.addingPatient,
	addingPatientErrorMessage: state.addpatient.message,
	addingPatientError: state.addpatient.addingPatientError,
	addReferringProviderResponse: get(state, ['addreferringprovider'], {}),
	addReferringProviderSuccess: state.addreferringprovider.success,
	addReferringProviderLoading:
		state.addreferringprovider.addingReferringProvider,
	addReferringProviderPayload: state.addreferringprovider.payload,
	addReferralReasonResponse: get(state, ['addreferralreason']),
	addInitialContactMethodResponse: get(state, ['addinitialcontactmethod']),
	updatingAddress: state.editpatient.updatingAddress,
	updatingAddressError: state.editpatient.updatedAddressError,
	updatingPatient: state.editpatient.updatingOutreach,
	updatingPatientError: state.editpatient.updatingOutreachError,
	eligibilityFields: get(
		state,
		['eligibilityFields', 'eligibilityFields'],
		[]
	),
	encounterList: state.encounterlist,
	eligibilityFieldsLoading: state.eligibilityFields.eligibilityFieldsLoading,
	updatingRelationship: state.editpatient.updatingRelationship,
	updatedRelationshipError: state.editpatient.updatedRelationshipError,
	duplicatePatientCheckLoading:
		state.duplicatePatientCheck.duplicatePatientCheckLoading,
	duplicatePatientCheckData:
		state.duplicatePatientCheck.duplicatePatientCheck,
	duplicatePatientCheckError:
		state.duplicatePatientCheck.duplicatePatientCheckError,
	linkingProviders: state.linkpatientproviders.linkingProviders,
	linkingProvidersError: state.linkpatientproviders.linkingProvidersError,
	gettingLinkedProviders: get(
		state,
		['linkedpatientproviders', 'gettingProviders'],
		false
	),
	linkedProviders: get(
		state,
		['linkedpatientproviders', 'linkedProviders'],
		[]
	),
	unlinkingProvider: state.unlinkpatientprovider.unlinkingProvider,
	unlinkProviderError: state.unlinkpatientprovider.unlinkProviderError,
	unlinkProviderResult: state.unlinkpatientprovider.unlinkProviderResult,
	updatingLinkedProvider:
		state.updatelinkedpatientprovider.updatingLinkedProvider,
	updatingLinkedProviderError:
		state.updatelinkedpatientprovider.updatingLinkedProviderError,
	updatedLinkedProvider:
		state.updatelinkedpatientprovider.updatedLinkedProvider,
	isExternalUser: get(state, 'me.activerole.isExternalUser', false),
	userRole: get(state, 'me.activerole.name'),
	patientTagsLoading: get(state, 'patientTags.patientTagsLoading', false),
	patientTags: get(state, 'patientTags.patientTags', []),
	patientTagsError: get(state, 'patientTags.patientTagsError', {}),
	genderLoading: get(state, 'gender.genderLoading', false),
	gender: get(state, 'gender.gender.gender', []),
	genderError: get(state, 'gender.genderError', {}),
	pronounsLoading: get(state, 'pronouns.pronounsLoading', false),
	pronouns: get(state, ['pronouns', 'pronouns', 'pronouns'], []),
	pronounsError: get(state, 'pronouns.pronounsError', {}),
	sexAssignedAtBirthLoading: get(
		state,
		'sexAssignedAtBirth.sexAssignedAtBirthLoading',
		false
	),
	sexAssignedAtBirth: get(
		state,
		['sexAssignedAtBirth', 'sexAssignedAtBirth', 'sex_assigned_at_birth'],
		[]
	),
	sexAssignedAtBirthError: get(
		state,
		'sexAssignedAtBirth.sexAssignedAtBirthError',
		{}
	),
	gettingProviderSpecialties: get(
		state,
		'providerEnums.gettingProviderSpecialties',
		false
	),
	providerSpecialties: get(state, 'providerEnums.providerSpecialties', []),
	providerSpecialtiesError: get(
		state,
		'providerEnums.providerSpecialtiesError',
		null
	),
	raceEnumLoading: get(state, 'raceEnum.raceEnumLoading', false),
	raceEnum: get(state, 'raceEnum.raceEnum', []),
	raceEnumError: get(state, 'raceEnum.raceEnumError', null),
	ethnicityEnumLoading: get(
		state,
		'ethnicityEnum.ethnicityEnumLoading',
		false
	),
	ethnicityEnum: get(state, 'ethnicityEnum.ethnicityEnum', []),
	ethnicityEnumError: get(state, 'ethnicityEnum.ethnicityEnumError', null),
	insuranceCompanies:
		state.insurancecompany?.loadedGetInsuranceCompanies?.data || [],
	planTypesEnums: state.enums?.planTypes?.data || [],
	referralProgramDetail:
		state.referralProgramsList.loadedReferralProgramDetail,
	loadingReferralProgramDetail:
		state.referralProgramsList.loadingReferralProgramDetail,
	defaultSchedulingOptions: {
		loading: state.referralProgramsList.defaultSchedulingOptions.loading,
		payload: state.referralProgramsList.defaultSchedulingOptions.payload,
		error: state.referralProgramsList.defaultSchedulingOptions.error,
		updatingConsent: state.updateConsentStatus.updatingConsent,
		updateConsentError: state.updateConsentStatus.updateConsentError,
	},
});

const mapDispatchToProps = (dispatch) => ({
	getPatientDetail: (patientUuid, flag) =>
		dispatch(patientdetail(patientUuid, flag)),
	resetPatientDetail: () => dispatch(resetPatientInfo()),
	getRefPrograms: () => dispatch(getReferralPrograms()),
	getRefReasons: () => dispatch(getReferralReasons()),
	getRefProviders: () => dispatch(getReferralProviders()),
	callConsenttotreat: () => dispatch(getConsenttotreat()),
	getEligibilityFields: (data, editBool) =>
		dispatch(getEligibilityFields(data, editBool)),
	callAddPatient: (data) => dispatch(addpatient(data)),
	callResetEligibilityFields: () => dispatch(resetEligibilityFields()),
	addReferringProvider: (data) => dispatch(addReferringProvider(data)),
	updateAddress: (data) => dispatch(updateAddress(data)),
	updateRelationship: (data) => dispatch(updateRelationship(data)),
	editPatient: (data) => dispatch(updateOutreach(data)),
	duplicatePatientCheck: (data) => dispatch(duplicatePatientCheck(data)),
	linkProviders: (patient_uuid, data) =>
		dispatch(linkProviders(patient_uuid, data)),
	updateLinkedProvider: (patient_uuid, provider_uuid, data) =>
		dispatch(updateLinkedProvider(patient_uuid, provider_uuid, data)),
	getLinkedProviders: (patient_uuid) =>
		dispatch(getLinkedProviders(patient_uuid)),
	getAllEncounters: (data) => dispatch(getencounters(data)),
	unlinkProvider: (patient_uuid, provider_uuid) =>
		dispatch(unlinkProvider(patient_uuid, provider_uuid)),
	getPatientTags: () => dispatch(getPatientTags()),
	getGenderEnum: () => dispatch(getGender()),
	getPronounsEnum: () => dispatch(getPronouns()),
	getSexAssignedAtBirthEnum: () => dispatch(getSexAssignedAtBirth()),
	getProviderSpecialties: () => dispatch(getProviderSpecialties()),
	getRaceEnum: () => dispatch(getRaceEnum()),
	getEthnicityEnum: () => dispatch(getEthnicityEnum()),
	getPlanTypesEnums: () => dispatch(getPlanTypes()),
	getInsuranceCompanyInfo: (data) => dispatch(getInsuranceCompanyInfo(data)),
	resetGetInsuranceCompany: () =>
		dispatch(resetGetInsuranceCompanyDispatch()),
	getReferralProgramDetail: (data) =>
		dispatch(getReferralProgramDetail(data)),
	getDefaultSchedulingOptions: (data) =>
		dispatch(getDefaultSchedulingOptions(data)),
	resetDefaultSchedulingOptionsDispatch: () =>
		dispatch(resetDefaultSchedulingOptionsDispatch()),
	updateConsentStatus: (patientUUID, data) =>
		dispatch(updateConsentStatus(patientUUID, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePatientV2);
