import * as actionTypes from './actionTypes';

export const updateResultsPerPage = (payload) => {
	return {
		type: actionTypes.UPDATE_RESULTS_PER_PAGE,
		payload,
	};
};

export const snackBarSuccessAction = (message) => {
	return {
		type: actionTypes.SHOW_SNACKBAR,
		payload: { variant: 'info', message },
	};
};

export const snackBarErrorAction = (message) => {
	return {
		type: actionTypes.SHOW_SNACKBAR,
		payload: { variant: 'error', message },
	};
};

export const snackBarForSuccess = (message) => {
	return (dispatch) => {
		dispatch(snackBarSuccessAction(message));
	};
};

export const snackBarForError = (message) => {
	return (dispatch) => {
		dispatch(snackBarErrorAction(message));
	};
};
