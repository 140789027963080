const timeslotalreadytaken =
	'The time slot is already taken, please choose another slot.';
const unabletoproccesspayment = 'Unable to process payment at this time';
const failedtoauthorizedpayment =
	'We failed to authorize required amount on your card. Please update your payment method.';
const appointmentslotnotavailable =
	'Appointment slot is not available for booking, please try another slot';
const usernamealreadytaken =
	'Your username has been taken. Please select another one.';
const providernotavailable =
	'Requested provider is not available for appointments on requested time.';
const slotnotfound = 'Requested slot was not found';
const providertemporarynotavaialble =
	'Selected provider is temporary unavailable for appointments.';
export const errorshardcoded = [
	timeslotalreadytaken,
	unabletoproccesspayment,
	failedtoauthorizedpayment,
	appointmentslotnotavailable,
	usernamealreadytaken,
	providernotavailable,
	slotnotfound,
	providertemporarynotavaialble,
];
export const ScheduleActionableErrors = [
	timeslotalreadytaken,
	appointmentslotnotavailable,
	providertemporarynotavaialble,
	slotnotfound,
	providernotavailable,
];
export const ScheduleNonActionableErrors = [usernamealreadytaken];
export const PaymentActionableErrors = [failedtoauthorizedpayment];
export const PaymentNonActionableErrors = [unabletoproccesspayment];
export const appointmenterrors = {
	ScheduleActionableErrors: ScheduleActionableErrors,
	ScheduleNonActionableErrors: ScheduleNonActionableErrors,
	PaymentActionableErrors: PaymentActionableErrors,
	PaymentNonActionableErrors: PaymentNonActionableErrors,
};
