//React & Redux
import React, { Component } from 'react';
import PropTypes from 'prop-types';

//Lodash
import _ from 'lodash';

//Styles
import './SelectedAppointmentSlot.css';

class SelectedAppointmentSlot extends Component {
	static propTypes = {
		event: PropTypes.object.isRequired,
	};
	state = {
		top: 0,
		height: 0,
	};
	getTimeString(dt) {
		const hours = dt.getHours();
		const hoursAmPm = hours > 12 ? hours - 12 : hours;
		const mins = dt.getMinutes();
		return `${hoursAmPm}:${mins < 10 ? '0' + mins : mins} ${
			hours >= 12 ? 'PM' : 'AM'
		}`;
	}
	renderSteps = () => {
		// this function renders lines above the event, because the event overlaps grid and we must recover table grid lines
		const {
			event: { start, end },
		} = this.props;
		const stepsCount = (end - start) / 60000 / 30;
		const startMinutes = start.getMinutes();
		let styles = ['solid', 'dashed'];
		if (startMinutes > 55 || startMinutes < 5) {
			styles = ['dashed', 'solid'];
		}
		const stepWidth = 100 / stepsCount;
		return _.range(stepsCount).map((i) => (
			<div
				key={i}
				className={'line ' + styles[i % 2]}
				style={{ top: stepWidth * i + '%', height: stepWidth + '%' }}
			/>
		));
	};

	render() {
		const { top, height } = this.state;

		return (
			<div ref={(e) => (this.element = e)} className='full-empty-slot'>
				{this.renderSteps()}
				<div className='slot active'>
					<div ref={(o) => (this.slotTime = o)} className='time'>
						{this.getTimeString(this.props.event.start)}
					</div>
					<div className='left-border' />
				</div>
			</div>
		);
	}
}

export default SelectedAppointmentSlot;
