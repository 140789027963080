import axios from 'axios';
import get from 'lodash/get';

class LabOrderService {
	encounterorderRequest = (data) => {
		return axios.post('/api/encounterorderRequest', data);
	};

	getToken = (data) => {
		return axios.get('/api/token?partner_id=' + get(data, 'partner_id'));
	};
}

export default new LabOrderService();
