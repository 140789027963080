import * as actionTypes from '../actions/actionTypes';

const defaultReferralChannelState = {
	loadingReferralChannels: false,
	referral_channels: [],
	referralChannelsError: null,
};

const defaultBillingCountState = {
	loadingBillingDashboardCounts: false,
	billing_dashboard_counts: {},
	billingDashboardCountsError: null,
};

const defaultBillingEncounterDetail = {
	loadingBillingEncounter: false,
	billingencounter: {},
	billingEncounterError: null,
};

const defaultBillingEncounterObject = {
	loadingBillingObject: false,
	billingobject: {},
	billingObjectError: null,
};

const defaultBillingPatientDetail = {
	loadingbillingPatient: false,
	billingpatient: {},
	billingpatientError: null,
};

const defaultBillingEncounterEnums = {
	loadingbillingEnums: false,
	billingEnums: {},
	billingEnumsError: null,
};

const defaultExportBillingEncounter = {
	loadingExportbilling: false,
	exportbilling: null,
	exportbillingError: null,
};

export const billingEncounters = (
	state = { billingEncounters: [] },
	action
) => {
	if (action.type === actionTypes.BILLING_ENCOUNTERS) {
		const newState = Object.assign({}, state);
		newState.billingEncountersLoading = false;
		newState.billingEncounters = action.payload;
		newState.billingEncountersError = null;
		return newState;
	} else if (action.type === actionTypes.GETTING_BILLING_ENCOUNTERS) {
		const newState = Object.assign({}, state);
		newState.billingEncountersLoading = true;
		newState.billingEncounters = [];
		newState.billingEncountersError = null;
		return newState;
	} else if (action.type === actionTypes.BILLING_ENCOUNTERS_ERROR) {
		const newState = Object.assign({}, state);
		newState.billingEncountersLoading = false;
		newState.billingEncounters = [];
		newState.billingEncountersError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const referralchannels = (
	state = defaultReferralChannelState,
	action
) => {
	if (action.type === actionTypes.LOADING_REFERRAL_CHANNELS) {
		const newState = Object.assign({}, state);
		newState.loadingReferralChannels = true;
		newState.referralChannelsError = null;
		return newState;
	} else if (action.type === actionTypes.REFERRAL_CHANNELS) {
		const newState = Object.assign({}, state);
		newState.loadingReferralChannels = false;
		newState.referral_channels = action.payload;
		return newState;
	} else if (action.type === actionTypes.REFERRAL_CHANNELS_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingReferralChannels = false;
		newState.referralChannelsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const billingaffiliations = (
	state = { billing_affiliations: [] },
	action
) => {
	if (action.type === actionTypes.BILLING_AFFILIATIONS) {
		const newState = Object.assign({}, state);
		newState.billing_affiliations = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getCounts = (state = defaultBillingCountState, action) => {
	if (action.type === actionTypes.LOADING_BILLING_DASHBOARD_COUNTS) {
		const newState = Object.assign({}, state);
		newState.loadingBillingDashboardCounts = true;
		newState.billingDashboardCountsError = null;
		return newState;
	} else if (action.type === actionTypes.BILLING_DASHBOARD_COUNTS) {
		const newState = Object.assign({}, state);
		newState.loadingBillingDashboardCounts = false;
		newState.billing_dashboard_counts = action.payload;
		return newState;
	} else if (action.type === actionTypes.BILLING_DASHBOARD_COUNTS_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingBillingDashboardCounts = false;
		newState.billingDashboardCountsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getBillingEncounterDetail = (
	state = defaultBillingEncounterDetail,
	action
) => {
	if (action.type === actionTypes.LOADING_BILLING_ENCOUNTER_DETAIL) {
		const newState = Object.assign({}, state);
		newState.loadingBillingEncounter = true;
		newState.billingEncounterError = null;
		return newState;
	} else if (action.type === actionTypes.BILLING_ENCOUNTER_DETAIL) {
		const newState = Object.assign({}, state);
		newState.loadingBillingEncounter = false;
		newState.billingencounter = action.payload;
		return newState;
	} else if (action.type === actionTypes.BILLING_ENCOUNTER_DETAIL_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingBillingEncounter = false;
		newState.billingEncounterError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getBillingPatientDetail = (
	state = defaultBillingPatientDetail,
	action
) => {
	if (action.type === actionTypes.LOADING_BILLING_PATIENT_DETAIL) {
		const newState = Object.assign({}, state);
		newState.loadingbillingPatient = true;
		newState.billingpatientError = null;
		return newState;
	} else if (action.type === actionTypes.BILLING_PATIENT_DETAIL) {
		const newState = Object.assign({}, state);
		newState.loadingbillingPatient = false;
		newState.billingpatient = action.payload;
		return newState;
	} else if (action.type === actionTypes.BILLING_PATIENT_DETAIL_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingbillingPatient = false;
		newState.billingpatientError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getBillingEncounterObject = (
	state = defaultBillingEncounterObject,
	action
) => {
	if (action.type === actionTypes.LOADING_ENCOUNTER_BILLING_OBJECT) {
		const newState = Object.assign({}, state);
		newState.loadingBillingObject = true;
		newState.billingObjectError = null;
		return newState;
	} else if (action.type === actionTypes.ENCOUNTER_BILLING_OBJECT) {
		const newState = Object.assign({}, state);
		newState.loadingBillingObject = false;
		newState.billingobject = action.payload;
		return newState;
	} else if (action.type === actionTypes.ENCOUNTER_BILLING_OBJECT_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingBillingObject = false;
		newState.billingObjectError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getEncounterEnums = (
	state = defaultBillingEncounterEnums,
	action
) => {
	if (action.type === actionTypes.LOADING_BILLING_ENCOUNTER_ENUMS) {
		const newState = Object.assign({}, state);
		newState.loadingbillingEnums = true;
		newState.billingEnumsError = null;
		return newState;
	} else if (action.type === actionTypes.BILLING_ENCOUNTER_ENUMS) {
		const newState = Object.assign({}, state);
		newState.loadingbillingEnums = false;
		newState.billingEnums = action.payload;
		return newState;
	} else if (action.type === actionTypes.BILLING_ENCOUNTER_ENUMS_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingbillingEnums = false;
		newState.billingEnumsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getExportBillingEncounter = (
	state = defaultExportBillingEncounter,
	action
) => {
	if (action.type === actionTypes.LOADING_EXPORT_BILLINGS_REPORT) {
		const newState = Object.assign({}, state);
		newState.loadingExportbilling = true;
		newState.exportbillingError = null;
		return newState;
	} else if (action.type === actionTypes.EXPORT_BILLINGS_REPORT) {
		const newState = Object.assign({}, state);
		newState.loadingExportbilling = false;
		newState.exportbilling = action.payload;
		return newState;
	} else if (action.type === actionTypes.EXPORT_BILLINGS_REPORT_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingExportbilling = false;
		newState.exportbillingError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const saveEncounter = (state = { encounter_saved: '' }, action) => {
	if (action.type === actionTypes.SAVE_ENCOUNTER_DATA) {
		const newState = Object.assign({}, state);
		newState.encounter_saved = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const billingEncounterBulkUpdate = (state = {}, action) => {
	if (action.type === actionTypes.BILLING_ENCOUNTER_BULK_UPDATING) {
		const newState = Object.assign({}, state);
		newState.billingEncounterBulkUpdating = true;
		newState.billingEncounterBulkUpdated = null;
		newState.billingEncounterBulkUpdatedError = null;
		return newState;
	} else if (action.type === actionTypes.BILLING_ENCOUNTER_BULK_UPDATED) {
		const newState = Object.assign({}, state);
		newState.billingEncounterBulkUpdating = false;
		newState.billingEncounterBulkUpdated = action.payload;
		newState.billingEncounterBulkUpdatedError = null;
		return newState;
	} else if (
		action.type === actionTypes.BILLING_ENCOUNTER_BULK_UPDATED_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.billingEncounterBulkUpdating = false;
		newState.billingEncounterBulkUpdated = null;
		newState.billingEncounterBulkUpdatedError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const patientFeeEnum = (state = {}, action) => {
	if (action.type === actionTypes.PATIENT_FEE_ENUM_LOADING) {
		const newState = Object.assign({}, state);
		newState.patientFeeEnumLoading = true;
		newState.patientFeeEnum = null;
		newState.patientFeeEnumError = null;
		return newState;
	} else if (action.type === actionTypes.PATIENT_FEE_ENUM_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.patientFeeEnumLoading = false;
		newState.patientFeeEnum = action.payload;
		newState.patientFeeEnumError = null;
		return newState;
	} else if (action.type === actionTypes.PATIENT_FEE_ENUM_ERROR) {
		const newState = Object.assign({}, state);
		newState.patientFeeEnumLoading = false;
		newState.patientFeeEnum = null;
		newState.patientFeeEnumError = action.type;
		return newState;
	} else {
		return state;
	}
};
