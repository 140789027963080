import classnames from 'classnames';
import { camelCase } from 'lodash';
import React from 'react';

import MoreInfoIcon from '../assets/purple-more-info.svg';

import { Tooltip } from '../Tooltip';

import './FormField.scss';

export interface IFormFieldProps {
	className?: string;
	label?: string;
	name?: string;
	error?: string;
	tooltip?: string;
	required?: boolean;
	children?: React.ReactNode;
}

export const FormField: React.FC<IFormFieldProps> = (props) => {
	const { className, name, label, required, error, tooltip, children } =
		props;
	return (
		<div
			data-testid={name ?? camelCase(label)}
			className={classnames('gm-form-field', className, {
				'has-error': !!error,
			})}
		>
			{label && (
				<label>
					{label}
					{required && (
						<>
							&nbsp;<span className='required'>*</span>
						</>
					)}
					{tooltip && (
						<Tooltip
							content={tooltip}
							className='tooltip'
							effect='dark'
						>
							<img src={MoreInfoIcon} alt='More info' />
						</Tooltip>
					)}
				</label>
			)}
			{children}
			{error && <span className='error'>{error}</span>}
		</div>
	);
};
