import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { getInsuranceCompanyInfo } from '../../../../../actions/insurancecompany';
import { dependentVariants } from '../../../../../utils';
import DatePickerField from '../../../../Common/FormFields/DatePickerField';
import { biologicalSexVariants } from '../../../../../Utils/appointmentUtils';
import Dropdown from '../../../../Common/FormFields/Dropdown';
import InputField from '../../../../Common/FormFields/InputField';
import RadioGroupField from '../../../../Common/FormFields/RadioGroupField';

import { useSection } from '../sections/useSection';

import './InsuranceDetails.scss';

/**
 * Insurance details subsection of Additional Patient Info section
 *
 * @param {boolean} required if true, sets all fields required by default
 * @param {boolean} preFillForm if true, sets field values based on patientDetails
 */
export const InsuranceDetails = function ({
	className,
	preFillForm,
	referralProgramId,
	required,
	sectionDataContainerName,
}) {
	const { values, touched, errors, onChange, setFieldValue } = useSection(
		sectionDataContainerName,
	);

	const { insuranceCompanies, patientDetails, referralPrograms } =
		useSelector((store) => ({
			insuranceCompanies:
				store.insurancecompany.loadedGetInsuranceCompanies?.data,
			patientDetails: store.patientdetail?.patientdetail?.[0],
			referralPrograms:
				store.referralPrograms.referralPrograms.data ?? [],
		}));

	const dispatch = useDispatch();

	const [selectedReferralProgram, setSelectedReferralProgram] =
		useState(null);
	const [loadedExistingInsuranceDetails, setLoadedExistingInsuranceDetails] =
		useState(false);

	useEffect(() => {
		if (patientDetails && preFillForm) {
			const primaryInsurance = patientDetails.insurances?.find(
				(item) => item.insurance_type === 'primary',
			);
			if (primaryInsurance) {
				setFieldValue(
					'healthInsurance',
					primaryInsurance.insurance_company.id,
				);
				setFieldValue(
					'insuranceId',
					primaryInsurance.insurance.insurance_no,
				);
				setFieldValue('group', primaryInsurance.insurance.group_no);
				setFieldValue(
					'isPrimaryPolicyHolder',
					primaryInsurance.is_policyholder,
				);
				setFieldValue(
					'policyholderBiologicalSex',
					primaryInsurance.policyholder_biological_sex,
				);
				setFieldValue(
					'relationshipToPolicyholder',
					primaryInsurance.policyholder_relationship || null,
				);
				setFieldValue(
					'policyholderFirstName',
					primaryInsurance.policyholder_first_name || null,
				);
				setFieldValue(
					'policyholderLastName',
					primaryInsurance.policyholder_last_name || null,
				);
				setFieldValue(
					'policyholderDOB',
					primaryInsurance.policyholder_dob || null,
				);
				setLoadedExistingInsuranceDetails(true);
			}
		}
	}, [patientDetails]);

	useEffect(() => {
		if (referralProgramId) {
			const refProgram = referralPrograms.find(
				(item) => item.id === referralProgramId,
			);
			if (refProgram && selectedReferralProgram !== refProgram) {
				setSelectedReferralProgram(refProgram);
				if (!refProgram?.insurance_enabled && values.healthInsurance) {
					setFieldValue('healthInsurance', null);
				}
				const partnerUuid = refProgram?.uuid;
				const request = {
					limit: -1,
					page: 1,
					partner_uuid: partnerUuid,
				};
				dispatch(getInsuranceCompanyInfo(request));
			}
		} else {
			if (selectedReferralProgram) {
				setSelectedReferralProgram(null);
			}
		}
	}, [referralProgramId, referralPrograms]);

	useEffect(() => {
		if (values.isPrimaryPolicyHolder) {
			setFieldValue('relationshipToPolicyholder', null);
			setFieldValue('policyholderFirstName', null);
			setFieldValue('policyholderLastName', null);
			setFieldValue('policyholderDOB', null);
		}
	}, [values.isPrimaryPolicyHolder]);

	useEffect(() => {
		// Prevents purging insurance details if loading and setting patient's
		// current insurance
		const resetInsuranceFields = () => {
			setFieldValue('insuranceId', '');
			setFieldValue('group', '');
			setFieldValue('isPrimaryPolicyHolder', true);
			setFieldValue('relationshipToPolicyholder', null);
			setFieldValue('policyholderFirstName', null);
			setFieldValue('policyholderLastName', null);
			setFieldValue('policyholderDOB', null);
			setFieldValue('policyholderBiologicalSex', null);
		};

		if (loadedExistingInsuranceDetails) {
			const primaryInsurance = patientDetails?.insurances?.find(
				(item) => item.insurance_type === 'primary',
			);

			if (
				values.healthInsurance !==
				primaryInsurance?.insurance_company?.id
			) {
				resetInsuranceFields();
			}

			setLoadedExistingInsuranceDetails(false);
		} else {
			resetInsuranceFields();
		}
	}, [values.healthInsurance]);

	// Reset insurance details if partner (Associated program) changes and new
	// partner does not support the selected insurance
	useEffect(() => {
		if (values.healthInsurance) {
			if (
				insuranceCompanies &&
				!insuranceCompanies.find(
					(it) => it.companyID === values.healthInsurance,
				)
			) {
				setFieldValue('healthInsurance', null);
			}
		}
	}, [insuranceCompanies]);

	const insuranceOptions = referralProgramId
		? (insuranceCompanies?.map((item) => ({
				label: item.insuranceCompanyName,
				value: item.companyID,
			})) ?? [])
		: [];

	const policyholderBiologicalSexField = (
		<Dropdown
			label="Policyholder's biological sex"
			name='policyholderBiologicalSex'
			required={required || !!values.healthInsurance}
			disabled={!values.healthInsurance}
			options={biologicalSexVariants}
			error={
				touched.policyholderBiologicalSex &&
				errors.policyholderBiologicalSex
			}
			onChange={onChange('policyholderBiologicalSex')}
			value={values.policyholderBiologicalSex}
		/>
	);
	return (
		<div className={classnames('insurance-fields', className)}>
			<Dropdown
				clearable={!required}
				required={required}
				label='Health Insurance'
				disabled={!selectedReferralProgram?.insurance_enabled}
				options={insuranceOptions}
				error={touched.healthInsurance && errors.healthInsurance}
				onChange={(v) =>
					setFieldValue('healthInsurance', v === '' ? null : v)
				}
				value={values.healthInsurance}
			/>
			<InputField
				label='Insurance ID #'
				name='insuranceId'
				required={required || !!values.healthInsurance}
				disabled={!values.healthInsurance}
				error={touched.insuranceId && errors.insuranceId}
				onChange={onChange('insuranceId')}
				value={values.insuranceId}
			/>
			<InputField
				label='Group #'
				name='group'
				required={required || !!values.healthInsurance}
				disabled={!values.healthInsurance}
				error={touched.group && errors.group}
				onChange={onChange('group')}
				value={values.group}
			/>
			<RadioGroupField
				label='Is patient the primary policy holder?'
				name='isPatientThePrimaryPolicyHolder'
				required={required || !!values.healthInsurance}
				options={[
					{
						key: 'true',
						display_name: 'Yes',
						disabled: !values.healthInsurance,
					},
					{
						key: 'false',
						display_name: 'No',
						disabled: !values.healthInsurance,
					},
				]}
				error={
					touched.isPrimaryPolicyHolder &&
					errors.isPrimaryPolicyHolder
				}
				onChange={(v) =>
					setFieldValue('isPrimaryPolicyHolder', v === 'true')
				}
				value={values.isPrimaryPolicyHolder?.toString()}
			/>
			{values.isPrimaryPolicyHolder === true &&
				policyholderBiologicalSexField}
			{values.isPrimaryPolicyHolder === false && (
				<>
					<InputField
						label="Policyholder's First Name"
						name='policyholderFirstName'
						required={true}
						error={
							touched.policyholderFirstName &&
							errors.policyholderFirstName
						}
						onChange={onChange('policyholderFirstName')}
						value={values.policyholderFirstName}
					/>
					<InputField
						label="Policyholder's Last Name"
						name='policyholderLastName'
						required={true}
						error={
							touched.policyholderLastName &&
							errors.policyholderLastName
						}
						onChange={onChange('policyholderLastName')}
						value={values.policyholderLastName}
					/>
					{policyholderBiologicalSexField}
					<DatePickerField
						required
						label={"Policyholder's DOB"}
						value={values.policyholderDOB}
						error={
							touched.policyholderDOB && errors.policyholderDOB
						}
						onChange={(v) => {							
							const formattedDate = v
								? moment(v).format('YYYY-MM-DD')
								: null;
							setFieldValue('policyholderDOB', formattedDate);
						}}
					/>
					<Dropdown
						label='Relationship to policyholder'
						name='relationshipToPolicyholder'
						required
						options={dependentVariants}
						error={
							touched.relationshipToPolicyholder &&
							errors.relationshipToPolicyholder
						}
						onChange={onChange('relationshipToPolicyholder')}
						value={values.relationshipToPolicyholder}
					/>
				</>
			)}
		</div>
	);
};
