import React from 'react';
import CircleBadge from '../Common/CircleBadge';
import './CCGCAAssignBadges.css';

const CCGCAAssignBadges = (props) => {
	const {
		assigned_cc_uuid,
		assigned_gca_uuid,
		ccList,
		currentUserUuid,
		gcaList,
	} = props;

	const getDisplayName = (uuid, type) => {
		const list = type === 'cc' ? ccList : gcaList;
		const obj = list.find((el) => el.key == uuid);
		return obj?.display_name || '';
	};

	const isUnassigned = (str) => !str || str === 'None';

	if (isUnassigned(assigned_gca_uuid) && isUnassigned(assigned_cc_uuid)) {
		return (
			<span className='badge-container'>
				<CircleBadge isReverse={true} displayName='' isActive={false} />
			</span>
		);
	}

	return (
		<span className='badge-container'>
			{!isUnassigned(assigned_gca_uuid) ? (
				<span>
					<CircleBadge
						isReverse={true}
						displayName={getDisplayName(assigned_gca_uuid, 'gca')}
						isActive={currentUserUuid == assigned_gca_uuid}
						type='gca'
					/>
				</span>
			) : null}
			{!isUnassigned(assigned_cc_uuid) ? (
				<span>
					<CircleBadge
						isReverse={true}
						displayName={getDisplayName(assigned_cc_uuid, 'cc')}
						isActive={currentUserUuid == assigned_cc_uuid}
						type='cc'
					/>
				</span>
			) : null}
		</span>
	);
};

export default CCGCAAssignBadges;
