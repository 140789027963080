//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import ICDSearch from './ICDSearch.js';
import ICDSelected from './ICDSelected.js';

class ICDSelection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.canAdvance = this.canAdvance.bind(this);
		this.advanceStage = this.advanceStage.bind(this);
		this.getButtonText = this.getButtonText.bind(this);
	}
	//functions

	canAdvance() {
		if (get(this, 'props.emptyAllowed', false)) return true;
		else {
			return get(this, 'props.selectedICDCodes.length', 0) !== 0;
		}
	}

	advanceStage() {
		if (this.canAdvance()) {
			if (this.props.singleStage) {
				this.props.closeChargeModalAndDispatch(
					this.props.selectedICDCodes
				);
			} else {
				this.props.setCurrentStage('billing');
			}
		}
	}

	getButtonText() {
		const buttonText = get(this, 'props.buttonText', null);
		if (this.props.singleStage) {
			if (get(this, 'props.title', null) === 'Edit ICD-10 Code(s)')
				return 'Save ICD-10 Code(s)';
			else if (buttonText) return buttonText;
			else return 'Add ICD-10 Code(s)';
		} else {
			return 'Next';
		}
	}

	render() {
		const buttonClass = this.canAdvance()
			? 'chargeModalActionButton'
			: 'chargeModalActionButton chargeModalActionButtonDisabled';
		return (
			<div className='chargeICDContainer'>
				<div className='chargeICDSearchAndSelectContainer'>
					<ICDSearch
						searchQuery={this.props.searchQuery}
						handleSearchQueryChange={
							this.props.handleSearchQueryChange
						}
						icdCodes={get(this, 'props.icdCodes', [])}
						selectedICDCodes={get(
							this,
							'props.selectedICDCodes',
							[]
						)}
						toggleCodeSelection={this.props.toggleCodeSelection}
						icdCodesLoading={this.props.icdCodesLoading}
						initialQueryLoading={this.props.initialQueryLoading}
						topLoading={this.props.topLoading}
						bottomLoading={this.props.bottomLoading}
						lazyLoadTop={this.props.lazyLoadTop}
						lazyLoadBottom={this.props.lazyLoadBottom}
						noPlaceHolder={get(this, 'props.noPlaceHolder', false)}
						localSearchKey={get(this, 'props.localSearchKey', null)}
						noDescription={get(this, 'props.noDescription', false)}
						filter={get(this, 'props.filter', null)}
						selectedFilter={
							get(this, 'props.selectedFilter', null) || []
						}
						changeFilterValue={this.props.changeFilterValue}
						filterHeader={get(this, 'props.filterHeader', null)}
						reverseVariant={get(
							this,
							'props.reverseVariant',
							false
						)}
						altCheckbox={get(this, 'props.altCheckbox', null)}
						toggleCodeSelectionAltCheckbox={
							this.props.toggleCodeSelectionAltCheckbox
						}
						handleCodeFreeTextChange={
							this.props.handleCodeFreeTextChange
						}
						freeTextKey={get(this, 'props.freeTextKey', null)}
					/>
					<ICDSelected
						selectedICDCodes={this.props.selectedICDCodes}
						toggleCodeSelection={this.props.toggleCodeSelection}
						localSearchKey={get(this, 'props.localSearchKey', null)}
						tagDisplayFunc={this.props.tagDisplayFunc}
						longTagVariant={get(
							this,
							'props.longTagVariant',
							false
						)}
					/>
				</div>
				<div className='chargeModalICDButtonRow'>
					<div className={buttonClass} onClick={this.advanceStage}>
						{this.getButtonText()}
					</div>
				</div>
			</div>
		);
	}
}

export default ICDSelection;
