import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getActiveCCs } from '../../../../actions/dashboard';
import MultiSelectCheckbox from '../../../Filter/MultiSelectCheckbox';

export function Assignee({ onChange, value }) {
	const dispatch = useDispatch();
	const ccList = useSelector((store) => store.ccList.ccList);

	useEffect(() => {
		dispatch(getActiveCCs());
	}, []);

	return (
		<MultiSelectCheckbox
			data={[...ccList]
				.sort((a, b) => a.display_name.localeCompare(b.display_name))
				.concat({ key: '', display_name: 'Unassigned' })}
			selected={value}
			title='Assignee'
			submit={onChange}
			majorPillTitle='Assignee'
			searchBar={true}
			previewTab={true}
			emptyInputFullOutputOverride={false}
		/>
	);
}
