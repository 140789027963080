//React & Redux
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

//Images
import editpencil from '../../../../assets/edit-gray.svg';
import checkicon from '../../../../assets/check-gray.svg';
import checkbutton from '../../../../assets/check-white.svg';
import dropdownicon from '../../../../assets/dropdown.svg';
import archiveicon from '../../../../assets/archive.svg';

//Lodash
import { isEmpty, isNil, omitBy } from 'lodash';

//Utils
import { CARE_PATIENTS_PATIENT_GDOC_TOOLBAR } from '../../../../Utils/permissionUtils';
import { FINALIZE, AMEND } from '../../../../Utils/documentUtils';

//UI Libraries
import { Dropdown, Button, Loading } from 'gm-element-react';

//Other Libraries
import Iframe from 'react-iframe';
import classnames from 'classnames';

//Components
import RBAC from '../../../RBAC';
import ReleaseDocumentModal from './ReleaseDocument/ReleaseDocumentModal.js';
import FinalizeAmendDocModal from './FinalizeAmendDocModal.js';

//Styles
import './documents.css';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious';

const SUCCESS_FINALIZE_MESSAGE = 'Document is Finalized.';
const FAIL_FINALIZE_MESSAGE = 'Document is not Finalized.';
const SUCCESS_AMEND_MESSAGE = 'Document is amended.';
const FAIL_AMEND_MESSAGE = 'Document is not amended.';
const infoObj = {
	variant: 'info',
	anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
};

const failObj = {
	variant: 'error',
	anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
};

const DocumentDetail = (props) => {
	const {
		archivedDocument,
		archivingDocument,
		clearEncounterState,
		dispatchFinalize,
		doreleasedocument: doReleaseDocument,
		document,
		documentTypesForTemplate = [],
		documentTypesLoading,
		encounter,
		encounterStringDisplay,
		encounters = [],
		enqueueSnackbar,
		error,
		finalizeDocument,
		finalizedDocument,
		finalizingDocument,
		finalizingDocumentError,
		getDocumentDetail,
		getDocumentTypes,
		getEncounter,
		getPatientLinkedProviders,
		gettingEncounterDetailLoading,
		gettingLinkedProviders,
		loaded,
		match: { params },
		navigateToDocumentList,
		patientdetail: patientDetail,
		PatientlinkedProviders: patientLinkedProviders,
		releasedDocumentError,
		releasingDocument,
		resetFinalizeDispatch,
		setModal,
		showChargeModal,
		unloadDetailDocument,
		updateProvider: updateProvider,
	} = props;

	const [processingCommand, setProcessingCommand] = useState('');
	const [releaseDocument, setReleaseDocument] = useState({});
	const [releaseDocumentSource, setReleaseDocumentSource] = useState('');
	const [renderFinalizeAmendDocModal, setRenderFinalizeAmendDocModal] =
		useState(false);
	const [renderReleaseDocument, setRenderReleaseDocument] = useState(false);

	const prevArchivingDocument = usePrevious(archivingDocument);
	const prevDispatchFinalize = usePrevious(dispatchFinalize);
	const prevFinalizingDocument = usePrevious(finalizingDocument);
	const prevReleasingDocument = usePrevious(releasingDocument);

	useEffect(() => {
		unloadDetailDocument();
		if (params.encounterid != 0) {
			getDocumentDetail(params.encounterid);
		}

		return () => {
			unloadDetailDocument();
			resetFinalizeDispatch();
		};
	}, []);

	useEffect(() => {
		!archivingDocument &&
			prevArchivingDocument &&
			archivedDocument &&
			navigateToDocumentList();
	}, [archivingDocument]);

	useEffect(() => {
		!prevDispatchFinalize && dispatchFinalize && handleCommand(FINALIZE);
	}, [dispatchFinalize]);

	useEffect(() => {
		if (!finalizingDocument && prevFinalizingDocument) {
			if (isNil(finalizingDocumentError)) {
				switch (processingCommand) {
					case FINALIZE:
						showReleaseDocument(finalizedDocument, FINALIZE);
						enqueueSnackbar(SUCCESS_FINALIZE_MESSAGE, infoObj);
						break;
					case AMEND:
						showReleaseDocument(finalizedDocument, AMEND);
						enqueueSnackbar(SUCCESS_AMEND_MESSAGE, infoObj);
						break;
				}
			} else {
				switch (processingCommand) {
					case FINALIZE:
						enqueueSnackbar(FAIL_FINALIZE_MESSAGE, failObj);
						break;
					case AMEND:
						enqueueSnackbar(FAIL_AMEND_MESSAGE, failObj);
						break;
				}
			}
			setProcessingCommand('');
		}
	}, [finalizingDocument]);

	useEffect(() => {
		if (!releasingDocument && prevReleasingDocument) {
			if (isNil(releasedDocumentError)) {
				enqueueSnackbar('Document released.', infoObj);
				navigateToDocumentList();
			} else {
				enqueueSnackbar('Error in Document released.', failObj);
			}
		}
	}, [releasingDocument]);

	const isEditable = () => {
		const fileExtention =
			document && !isNil(document.file_extension_id)
				? document.file_extension_id
				: '';
		return ['doc', 'docx'].includes(fileExtention.toLowerCase());
	};

	const isArchivedDisabled = () =>
		!loaded || archivingDocument || document.deleted_by.uuid;

	const determineFinalizeAction = () => {
		if (document.encounter || null) {
			if (determineIsVisitEncounter(encounterUuid)) {
				showChargeModal(encounterUuid);
			} else {
				handleCommand(FINALIZE);
			}
		} else {
			handleCommand(FINALIZE);
		}
	};

	const determineIsVisitEncounter = (encounterUuid) => {
		if (Array.isArray(encounters)) {
			const filteredList = encounters.filter(
				(el) => (el.uuid || null) === encounterUuid
			);
			if ((filteredList[0]?.type || '') === 'visit')
				return filteredList[0];
		}
		return false;
	};

	const handleCommand = (command) => {
		setRenderFinalizeAmendDocModal(true);
		setProcessingCommand(command);
	};

	const finalizeAmendDoc = (data) => {
		setRenderFinalizeAmendDocModal(false);
		if ((document.uuid || '') !== '')
			finalizeDocument(document.uuid || '', omitBy(data, isEmpty));
	};

	const isFinalizeStage = () => {
		const documentVersions = document.children?.length || 0;
		if (document.is_editable === true && documentVersions === 0)
			return true;
		return false;
	};

	const isAmendStage = () => {
		const documentVersions = document.children?.length || 0;
		if (document.is_editable === true && documentVersions > 0) return true;
		return false;
	};

	const getActionComponent = () => {
		const is_disable_finalize =
			finalizingDocument || !canBeArchived(document);

		if (document.is_editable != undefined) {
			if (isFinalizeStage()) {
				return (
					<button
						type='button'
						disabled={is_disable_finalize}
						className='doc-toolbar-button'
						onClick={() => handleCommand(FINALIZE)}
					>
						<span className='doc-button-check'>
							<img src={checkbutton} alt='check-icon' />
						</span>
						Finalize
					</button>
				);
			}
			if (isAmendStage()) {
				return (
					<Dropdown
						disabled={is_disable_finalize}
						onCommand={handleCommand}
						menu={
							<Dropdown.Menu>
								<Dropdown.Item
									disabled={is_disable_finalize}
									command={AMEND}
								>
									<div>
										<img
											src={editpencil}
											alt='edit-icon'
											className='pencil-docicon'
										/>{' '}
										<span className='finaldoc-dropmenutext'>
											{' '}
											Amend
										</span>
									</div>
								</Dropdown.Item>
							</Dropdown.Menu>
						}
					>
						<Button
							disabled={is_disable_finalize}
							className='finalize-dropdown'
						>
							<div>
								<img
									src={checkicon}
									alt='check-icon'
									className='check-grayicon'
								/>{' '}
								<span className='finaldoc-text'>
									{' '}
									Finalized
								</span>{' '}
								<span className='finaldoc-droparrow'>
									<img src={dropdownicon} />
								</span>
							</div>
						</Button>
					</Dropdown>
				);
			}
		}

		return null;
	};

	const showArchivalDialogue = () => setModal('archival', document.uuid || 0);

	const canBeArchived = (obj) => obj.can_be_archived || false;

	const showReleaseDocument = (document, source) => {
		setRenderReleaseDocument(true);
		setReleaseDocument(document);
		setReleaseDocumentSource(source);
	};

	const hideReleaseDocument = () => {
		setRenderReleaseDocument(false);
		setReleaseDocument({});
		setReleaseDocumentSource('');
		navigateToDocumentList();
	};

	const formulateDocTypes = () => {
		if (Array.isArray(documentTypesForTemplate)) {
			return documentTypesForTemplate.map((el) => {
				const subTypes = el.subtypes || null;
				const docType = {
					value: el.key || null,
					label: el.display_name || null,
				};
				if (Array.isArray(subTypes) && subTypes.length > 0) {
					docType.children = subTypes.map((subType) => ({
						label: subType.display_name || '',
						value: subType.key || null,
					}));
				}
				return docType;
			});
		} else {
			return documentTypesForTemplate;
		}
	};

	const closeFinalizeAmendDocument = () => {
		setRenderFinalizeAmendDocModal(false);
		setProcessingCommand('');
	};

	return loaded && !error && isEditable() ? (
		<div>
			<div className='doc-detail-header-section'>
				<div
					className='doc-detail-navigation'
					onClick={navigateToDocumentList}
					style={{ width: '100px' }}
				>
					<span>
						<i className='material-icons'>chevron_left</i>
					</span>{' '}
					Documents list
				</div>
				<div className='doc-detail-toolbar'>
					<div className='doc-detail-toolbar-left'>
						<div className='doc-detail-doc-name'>
							{document.file_name || ''}
						</div>
					</div>
					<RBAC
						action={CARE_PATIENTS_PATIENT_GDOC_TOOLBAR}
						yes={
							<div className='doc-detail-toolbar-right'>
								<div className='icon-gap'>
									<button
										disabled={isArchivedDisabled()}
										onClick={showArchivalDialogue}
									>
										<img
											src={archiveicon}
											alt='archive-icon'
										/>
									</button>
								</div>

								<div className='icon-divider'></div>
								<div>{getActionComponent()}</div>
							</div>
						}
					/>
				</div>
			</div>
			{finalizingDocument ? (
				<Loading loading={true} style={{ top: 'calc(50vh - 160px)' }} />
			) : (
				<>
					<div className='doc-detail-body-section'>
						<div
							className={classnames(
								'printBtnB',
								!canBeArchived(document) ? 'preview' : ''
							)}
						/>
						<Iframe
							url={document.doc_link}
							width='100%'
							height='100%'
							id='google_docs_iframe'
							style={{ border: '0px !important' }}
							position='relative'
						/>
					</div>
				</>
			)}
			{renderReleaseDocument && !isEmpty(releaseDocument) && (
				<ReleaseDocumentModal
					{...{
						clearEncounterState,
						doReleaseDocument,
						document: releaseDocument,
						encounter,
						getEncounter,
						getPatientLinkedProviders,
						gettingEncounterDetailLoading,
						gettingLinkedProviders,
						hideReleaseDocument,
						patientDetail,
						patientLinkedProviders,
						source: releaseDocumentSource || '',
						releasingDocument,
						updateProvider,
					}}
				/>
			)}
			{renderFinalizeAmendDocModal && (
				<FinalizeAmendDocModal
					{...{
						closeFinalizeAmendDocument,
						description: document.description || '',
						document_subtype:
							document.document_subtype?.name || null,
						document_type: document.document_type?.name || null,
						documentTypes: formulateDocTypes(),
						documentTypesLoading,
						encounter_uuid: document.encounter || '',
						encounterStringDisplay,
						encounters,
						finalizeAmendDoc,
						getDocumentTypes,
						source: processingCommand,
					}}
				/>
			)}
		</div>
	) : (
		<div />
	);
};

DocumentDetail.propTypes = {
	history: PropTypes.object,
	uuid: PropTypes.string,
	documentTypes: PropTypes.array,
	document: PropTypes.object,
	getDocumentDetail: PropTypes.func.isRequired,
	archiveDocument: PropTypes.func.isRequired,
	archivingDocument: PropTypes.bool,
	archivedDocument: PropTypes.object,
	archivingDocumentError: PropTypes.object,
	finalizingDocument: PropTypes.bool,
	finalizedDocument: PropTypes.object,
	finalizingDocumentError: PropTypes.object,
	finalizeDocument: PropTypes.func.isRequired,
	enqueueSnackbar: PropTypes.func.isRequired,
	unloadDetailDocument: PropTypes.func.isRequired,
};

export default DocumentDetail;
