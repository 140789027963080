import moment from 'moment';
import { get } from 'lodash';

import { UTCToCurrent } from './../utils';

export const hydrateAuditTrail = (data) => {
	const hydratedLogs = [];

	for (const log of data) {
		hydratedLogs.push({
			original: log,
			operation: get(log, ['operation'], {}),
			partner: get(log, ['partner'], {}),
			role: get(log, ['role'], {}),
			email: get(log, ['email'], ''),
			first_name: get(log, ['first_name'], ''),
			last_name: get(log, ['last_name'], ''),
			date: UTCToCurrent(moment, moment.tz(log.created_at, 'UTC')).format(
				'MMM Do, YYYY hh:mma'
			),
			by: `${get(log, ['created_by', 'first_name'], '')} ${get(
				log,
				['created_by', 'last_name'],
				''
			)}`,
			uuid: get(log, ['uuid'], ''),
		});
	}

	return hydratedLogs;
};
