import * as actionTypes from '../actionTypes';
import ReferralDocumentsService from '../../service/ReferralPrograms';
import _ from 'lodash';

//dispatchers
export const startReferralDocumentsDispatch = () => {
	return {
		type: actionTypes.LOADING_REFERRAL_DOCUMENTS,
	};
};

export const successReferralDocumentsDispatch = (data) => {
	return {
		type: actionTypes.LOADED_REFERRAL_DOCUMENTS,
		payload: data,
	};
};

export const faliureReferralDocumentsDispatch = (error) => {
	return {
		type: actionTypes.ERROR_REFERRAL_DOCUMENTS,
		payload: error,
	};
};

export const startCreatingTemplate = () => {
	return { type: actionTypes.LOADING_CREATE_TEMPLATE };
};

export const successCreatingTemplateDispatch = (data) => {
	return {
		type: actionTypes.LOADED_CREATE_TEMPLATE,
		payload: data,
	};
};

export const failureCreatingTemplate = (error) => {
	return {
		type: actionTypes.ERROR_CREATE_TEMPLATE,
		payload: error,
	};
};

export const startUpdatingTemplate = () => {
	return { type: actionTypes.LOADING_UPDATE_TEMPLATE };
};

export const successUpdatingTemplateDispatch = (data) => {
	return {
		type: actionTypes.LOADED_UPDATE_TEMPLATE,
		payload: data,
	};
};

export const failureUpdatingTemplate = (error) => {
	return {
		type: actionTypes.ERROR_UPDATE_TEMPLATE,
		payload: error,
	};
};

export const startViewTemplate = () => {
	return { type: actionTypes.LOADING_VIEW_TEMPLATE };
};

export const successViewTemplateDispatch = (data) => {
	return {
		type: actionTypes.LOADED_VIEW_TEMPLATE,
		payload: data,
	};
};

export const failureViewTemplate = (error) => {
	return {
		type: actionTypes.ERROR_VIEW_TEMPLATE,
		payload: error,
	};
};
export const startReferralDocumentDetailDispatch = () => {
	return {
		type: actionTypes.LOADING_REFERRAL_DOCUMENT_DETAIL,
	};
};

export const successReferralDocumentDetailDispatch = (data) => {
	return {
		type: actionTypes.LOADED_REFERRAL_DOCUMENT_DETAIL,
		payload: data,
	};
};
export const startWorkflowDocumentsDispatch = () => {
	return {
		type: actionTypes.LOADING_WORKFLOW_DOCUMENTS,
	};
};

export const successWorkflowDocumentsDispatch = (data) => {
	return {
		type: actionTypes.LOADED_WORKFLOW_DOCUMENTS,
		payload: data,
	};
};

export const faliureReferralDocumentDetailDispatch = (error) => {
	return {
		type: actionTypes.ERROR_REFERRAL_DOCUMENT_DETAIL,
		payload: error,
	};
};
export const faliureWorkflowDocumentsDispatch = (error) => {
	return {
		type: actionTypes.ERROR_WORKFLOW_DOCUMENTS,
		payload: error,
	};
};

export const startCreateBillingInfoDispatch = () => {
	return { type: actionTypes.LOADING_CREATE_BILLING_INFO };
};

export const successCreateBillingInfoDispatch = (data) => {
	return {
		type: actionTypes.LOADED_CREATE_BILLING_INFO,
		payload: data,
	};
};

export const failureCreateBillingInfoDispatch = (error) => {
	return {
		type: actionTypes.ERROR_CREATE_BILLING_INFO,
		payload: error,
	};
};

export const startUpdateBillingInfoDispatch = () => {
	return { type: actionTypes.LOADING_UPDATE_BILLING_INFO };
};

export const successUpdateBillingInfoDispatch = (data) => {
	return {
		type: actionTypes.LOADED_UPDATE_BILLING_INFO,
		payload: data,
	};
};

export const failureUpdateBillingInfoDispatch = (error) => {
	return {
		type: actionTypes.ERROR_UPDATE_BILLING_INFO,
		payload: error,
	};
};

const startArchivingDetailTemplate = () => {
	return { type: actionTypes.ARCHIVE_TEMPLATE_START };
};

const successArchivingDetailTemplate = (doc) => {
	return {
		type: actionTypes.ARCHIVE_TEMPLATE_SUCCESS,
		payload: doc,
	};
};

const failureArchivingDetailTemplate = (error) => {
	return {
		type: actionTypes.ARCHIVE_TEMPLATE_FAILURE,
		payload: error,
	};
};

export function getReferralDocuments(data) {
	return (dispatch) => {
		dispatch(startReferralDocumentsDispatch());
		ReferralDocumentsService.getPartnerDocuments(data)
			.then((res) => {
				dispatch(successReferralDocumentsDispatch(res));
			})
			.catch((error) => {
				dispatch(faliureReferralDocumentsDispatch(error));
			});
	};
}
export function getWorkflowDocuments(data) {
	return (dispatch) => {
		dispatch(startWorkflowDocumentsDispatch());
		ReferralDocumentsService.getPartnerDocuments(data)
			.then((res) => {
				dispatch(successWorkflowDocumentsDispatch(res));
			})
			.catch((error) => {
				dispatch(faliureWorkflowDocumentsDispatch(error));
			});
	};
}

//documenttype, partneruuid, filename, url, category
export function createTemplate(data) {
	return (dispatch) => {
		dispatch(startCreatingTemplate());
		return ReferralDocumentsService.createTemplate(data)
			.then((res) => {
				return dispatch(successCreatingTemplateDispatch(res));
			})
			.catch((err) => {
				dispatch(failureCreatingTemplate(err));
			});
	};
}

export function updateTemplate(uuid, data) {
	return (dispatch) => {
		dispatch(startUpdatingTemplate());
		return ReferralDocumentsService.updateTemplate(uuid, data)
			.then((res) => {
				return dispatch(successUpdatingTemplateDispatch(res));
			})
			.catch((err) => {
				dispatch(failureUpdatingTemplate(err));
			});
	};
}

//update shared document
export function updateSharedDocument(uuid, data) {
	return (dispatch) => {
		dispatch(startUpdatingTemplate());
		return ReferralDocumentsService.updateSharedDocument(uuid, data)
			.then((res) => {
				return dispatch(successUpdatingTemplateDispatch(res));
			})
			.catch((err) => {
				dispatch(failureUpdatingTemplate(err));
			});
	};
}

export function resetReferralDocuments() {
	return (dispatch) => {
		dispatch({ type: actionTypes.RESET_REFERRAL_DOCUMENTS });
	};
}

export function getDocumentDetail(data) {
	return (dispatch) => {
		dispatch(startReferralDocumentDetailDispatch());
		ReferralDocumentsService.getPartnerDocumentDetail(data)
			.then((res) => {
				dispatch(successReferralDocumentDetailDispatch(res));
			})
			.catch((error) => {
				dispatch(faliureReferralDocumentDetailDispatch(error));
			});
	};
}

export function getSharedDocumentDetail(data) {
	return (dispatch) => {
		dispatch(startReferralDocumentDetailDispatch());
		ReferralDocumentsService.getSharedDocumentDetail(data)
			.then((res) => {
				dispatch(successReferralDocumentDetailDispatch(res));
			})
			.catch((error) => {
				dispatch(faliureReferralDocumentDetailDispatch(error));
			});
	};
}

export function resetWorkflowDocuments() {
	return (dispatch) => {
		dispatch({ type: actionTypes.RESET_WORKFLOW_DOCUMENTS });
	};
}

export function createBillingInfo(data) {
	return (dispatch) => {
		dispatch(startCreateBillingInfoDispatch());
		ReferralDocumentsService.createBillingInfo(data)
			.then((res) => {
				dispatch(successCreateBillingInfoDispatch(res));
			})
			.catch((err) => {
				dispatch(failureCreateBillingInfoDispatch(err));
			});
	};
}

export function updateBillingInfo(data) {
	return (dispatch) => {
		dispatch(startUpdateBillingInfoDispatch());
		ReferralDocumentsService.updateBillingInfo(data)
			.then((res) => {
				dispatch(successUpdateBillingInfoDispatch(res));
			})
			.catch((err) => {
				dispatch(failureUpdateBillingInfoDispatch(err));
			});
	};
}

//Archive Template for document
export function archiveTemplate(partner_uuid, document_uuid) {
	return (dispatch) => {
		dispatch(startArchivingDetailTemplate());
		return ReferralDocumentsService.archiveTemplate(
			partner_uuid,
			document_uuid
		)
			.then((res) => {
				dispatch(
					successArchivingDetailTemplate(_.get(res, 'data.data', {}))
				);
			})
			.catch((err) => {
				dispatch(failureArchivingDetailTemplate(err));
			});
	};
}
