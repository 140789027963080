//React & Redux
import React, { Fragment } from 'react';

//Lodash
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';

//UI Libraries
import { Layout, Form, Button, Popover } from 'gm-element-react';

//Images
import ReactSVG from 'react-svg';
import editIcon from '../../../../assets/edit.svg';
import moreinfoicon from './../../../../assets/purple-more-info.svg';

//Styles
import './css/view-nucleotide-repeat-variant.css';

//Utils
import { CARE_PATIENTS_ENTER_TEST_RESULT } from '../../../../Utils/permissionUtils';
import { labClassificationNucleotideVariantEnum } from '../../../../utils';

//Other Libraries
import classnames from 'classnames';

//Components
import RBAC from '../../../RBAC';
import NotesContainer from '../../../Common/Notes/NotesContainer';
import Pill from '../../../Common/Pill';

const ViewNucleotideRepeatVariant = (props) => {
	const {
		addDiagnosticFindingClinicalNoteError = null,
		addDiagnosticFindingClinicalNoteLoading = false,
		addNewDiagnosticFindingClinicalNote,
		finding,
		showViewNucleotideRepeatVariantFullDetailFunc,
		showAddEditNucleotideRepeatVariantModalFunc,
	} = props;

	const uuid = finding?.uuid || null;
	const notes = finding?.notes || [];

	const getLatestInterpretationText = () => {
		let latestInterpretationText = '--';
		const mostRecentInterpretationTextAllele1 =
			labClassificationNucleotideVariantEnum.find(
				(o) =>
					o.key ===
					finding?.allele1?.mostRecentInterpretation?.classification
			);
		const mostRecentInterpretationTextAllele2 =
			labClassificationNucleotideVariantEnum.find(
				(o) =>
					o.key ===
					finding?.allele2?.mostRecentInterpretation?.classification
			);

		const isOnlyClassificationAllele1Normal =
			!isNil(
				finding?.allele1?.mostRecentInterpretation?.classification
			) &&
			!isNil(
				finding?.allele2?.mostRecentInterpretation?.classification
			) &&
			finding?.allele1?.mostRecentInterpretation?.classification ===
				'NORMAL' &&
			finding?.allele2?.mostRecentInterpretation?.classification !==
				'NORMAL';

		const isOnlyClassificationAllele2Normal =
			!isNil(
				finding?.allele1?.mostRecentInterpretation?.classification
			) &&
			!isNil(
				finding?.allele2?.mostRecentInterpretation?.classification
			) &&
			finding?.allele1?.mostRecentInterpretation?.classification !==
				'NORMAL' &&
			finding?.allele2?.mostRecentInterpretation?.classification ===
				'NORMAL';

		const isDisplayBothClassification =
			!isNil(
				finding?.allele1?.mostRecentInterpretation?.classification
			) &&
			!isNil(finding?.allele2?.mostRecentInterpretation?.classification);

		const interpretedOnAllele1 = moment(
			finding?.allele1?.mostRecentInterpretation?.interpretedOn || null
		).isValid()
			? moment(
					finding?.allele1?.mostRecentInterpretation?.interpretedOn ||
						null
			  ).format('MM/DD/YY')
			: '';

		const interpretedOnAllele2 = moment(
			finding?.allele2?.mostRecentInterpretation?.interpretedOn || null
		).isValid()
			? moment(
					finding?.allele2?.mostRecentInterpretation?.interpretedOn ||
						null
			  ).format('MM/DD/YY')
			: '';

		if (isOnlyClassificationAllele1Normal) {
			latestInterpretationText =
				mostRecentInterpretationTextAllele2?.display_name +
				' | ' +
				interpretedOnAllele2;
		} else if (isOnlyClassificationAllele2Normal) {
			latestInterpretationText =
				mostRecentInterpretationTextAllele1?.display_name +
				' | ' +
				interpretedOnAllele1;
		} else if (isDisplayBothClassification) {
			latestInterpretationText =
				mostRecentInterpretationTextAllele1?.display_name +
				' | ' +
				interpretedOnAllele1 +
				', ' +
				mostRecentInterpretationTextAllele2?.display_name +
				' | ' +
				interpretedOnAllele2;
		} else {
			latestInterpretationText =
				mostRecentInterpretationTextAllele1?.display_name +
				' | ' +
				interpretedOnAllele1;
		}

		return latestInterpretationText;
	};

	const getLatestInterpretationTooltip = () => {
		let latestInterpretationTooltip = '';

		if (
			!isNil(
				finding?.allele1?.mostRecentInterpretation?.classification ||
					null
			)
		) {
			latestInterpretationTooltip =
				finding?.allele1?.mostRecentInterpretation?.source?.name;
		} else if (
			!isNil(
				finding?.allele2?.mostRecentInterpretation?.classification ||
					null
			)
		) {
			latestInterpretationTooltip =
				finding?.allele2?.mostRecentInterpretation?.source?.name;
		}
		return latestInterpretationTooltip;
	};

	const displayRepeatsAllele1 = () => {
		let repeatsAlleleVal = '--';
		if (
			!isEmpty(finding?.allele1?.repeats?.value) &&
			!isNil(finding?.allele1?.repeats?.value)
		) {
			repeatsAlleleVal = finding?.allele1?.repeats?.value;

			if (
				!isEmpty(finding?.allele2?.repeats?.value) &&
				!isNil(finding?.allele2?.repeats?.value)
			) {
				repeatsAlleleVal =
					repeatsAlleleVal + ',' + finding?.allele2?.repeats?.value;
			}
		}
		return repeatsAlleleVal;
	};

	return (
		<Fragment>
			<Form className='view-nucleotide-repeat-variant-info'>
				<Layout.Row
					gutter='20'
					className='nucleotide-repeat-variant-title'
				>
					<Layout.Col>
						Nucleotide Repeat Variant Finding -{' '}
						{finding?.genesSymbol?.symbol || '--'}
						<Popover
							trigger='hover'
							popperClass={classnames(
								'pp-nucleotide-repeat-variant'
							)}
							content={
								<Fragment>
									<span style={{ display: 'block' }}>
										Name:{' '}
										{finding?.genesSymbol?.name || '--'}
									</span>
									<span style={{ display: 'block' }}>
										Aliases:{' '}
										{(
											finding?.genesSymbol
												?.aliasSymbols || []
										).join(', ')}
									</span>
									<span style={{ display: 'block' }}>
										Cytogenetic location:{' '}
										{finding?.genesSymbol
											?.cytogeneticLocation || '--'}
									</span>
								</Fragment>
							}
							key='popover-nucleotide-repeat-variant'
							placement='top'
						>
							<span className='tooltip-info'>
								<img
									src={moreinfoicon}
									className='more-info-icon'
								/>
							</span>
						</Popover>
						{finding?.assessment === 'ABSENT' && (
							<Pill
								pillText='Not Detected'
								pillExtendStyle='not-detected-pill'
							/>
						)}
					</Layout.Col>
				</Layout.Row>
				<Layout.Row gutter='32' className='clinical-comments-container'>
					<Layout.Col
						span='16'
						className='nucleotide-repeat-variant-result-section'
					>
						<Layout.Row className='result-details-label'>
							<Layout.Col span='20'>Result Details</Layout.Col>
						</Layout.Row>

						<Layout.Row>
							<Layout.Col span='12'>
								<label htmlFor='repeats'>Repeats</label>
								<span className='field-val'>
									{displayRepeatsAllele1()}
								</span>
							</Layout.Col>
							<Layout.Col span='12'>
								<label htmlFor='latestInterpretation'>
									Latest Interpretation
									{!isEmpty(
										getLatestInterpretationTooltip()
									) && (
										<Popover
											trigger='hover'
											popperClass={classnames(
												'pp-latest-interpretation'
											)}
											content={
												<Fragment>
													{getLatestInterpretationTooltip()}
												</Fragment>
											}
											key='popover-latest-interpretation'
											placement='top'
										>
											<span className='tooltip-info'>
												<img
													src={moreinfoicon}
													className='more-info-icon'
												/>
											</span>
										</Popover>
									)}
								</label>
								<span className='field-val'>
									{getLatestInterpretationText()}
								</span>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row>
							<Layout.Col span='18'>
								<Button
									className='nucleotide-repeat-variant-view-full-details'
									type='text'
									onClick={() =>
										showViewNucleotideRepeatVariantFullDetailFunc(
											finding
										)
									}
								>
									View full details
								</Button>
							</Layout.Col>
							<Layout.Col span='6'>
								<RBAC
									action={CARE_PATIENTS_ENTER_TEST_RESULT}
									yes={
										<Button
											className='edit-detail-button'
											onClick={() =>
												showAddEditNucleotideRepeatVariantModalFunc(
													'edit',
													finding
												)
											}
										>
											<ReactSVG
												src={editIcon}
												className='edit-detail-icon'
											/>
											Edit Original
										</Button>
									}
								/>
							</Layout.Col>
						</Layout.Row>
					</Layout.Col>

					<Layout.Col span='8'>
						<span className='clinical-comments-label'>
							Clinical Comments
						</span>
						<NotesContainer
							notes={notes}
							variant='cfDNA'
							addNoteLoading={
								addDiagnosticFindingClinicalNoteLoading
							}
							addNoteError={addDiagnosticFindingClinicalNoteError}
							addNote={addNewDiagnosticFindingClinicalNote}
							sourceUUID={uuid}
							addNoteModalTitle='New Comment — Nucleotide Repeat Variant'
							emptyMessageClass='empty-clinical-comments-msg'
							isShowListIcon={false}
							hasRBACAddComment={true}
							RBACAddCommentAction={
								CARE_PATIENTS_ENTER_TEST_RESULT
							}
						/>
					</Layout.Col>
				</Layout.Row>
			</Form>
		</Fragment>
	);
};

export default ViewNucleotideRepeatVariant;
