//React & Redux
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

//Other Libraries
import classnames from 'classnames';

//Lodash
import get from 'lodash/get';

//Styles
import './AppointmentSection.css';

class AppointmentSection extends React.Component {
	render() {
		const { heading, description, isRequired, horizontalLine, isOptional } =
			this.props;
		return (
			<Fragment>
				<div
					className={classnames(
						'AppointmentSection',
						horizontalLine ? 'AppointmentSectionHorizontalLine' : ''
					)}
					style={{
						margin: get(this, 'props.marginAuto')
							? 'auto'
							: 'unset',
						marginBottom: '24px',
					}}
				>
					<div className='AppointmentSectionLeft'>
						<div className='AppointmentSectionHeading'>
							{heading}
						</div>
						<div className='AppointmentSectionDescription'>
							{description}
						</div>
						{isRequired && (
							<div className='AppointmentSectionRequired'>
								Required
							</div>
						)}
						{isOptional && (
							<div className='AppointmentSectionOptional'>
								Optional
							</div>
						)}
					</div>
					<div className='AppointmentSectionRight'>
						{this.props.children}
					</div>
				</div>
			</Fragment>
		);
	}
}

AppointmentSection.propTypes = {
	heading: PropTypes.array.isRequired,
	description: PropTypes.array.isRequired,
};

export default AppointmentSection;
