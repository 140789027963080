import moment from 'moment';
import qs from 'query-string';
import React, {
	useEffect,
	useState,
	createContext,
	useContext,
	useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { requestAuthorizationStatus } from '../../actions/httpHandlers';
import { useIsTabActive } from '../../hooks/useIsActiveTab';
import SessionService from '../../service/Session';
import {
	clearAccessToken,
	getAccessToken,
	setAccessToken,
} from '../../service/Storage';

/**
 * A falling edge detector to know when API starts responding 401 Unauthorized.
 *
 * @returns true when the last API request was successful but current one
 * failed, false otherwise
 */
const useAuthorizationDetector = () => {
	const [prevValue, setPrevValue] = useState(true);
	const requestAuthorizationStatus = useSelector(
		(store) => store.httpHandlers?.requestAuthorizationStatus ?? true
	);

	useEffect(() => {
		setPrevValue(requestAuthorizationStatus);
	}, [requestAuthorizationStatus]);

	return prevValue === true && requestAuthorizationStatus === false;
};

export const AUTH_MODE = {
	AUTH0: 'AUTH0',
	GMI_SSO: 'GMI_SSO',
};

const AuthContext = createContext({
	isAuthenticated: null,
	authMode: null,
	isLoading: true,
});

/**
 * Hook that provides authentication information
 */
export const useAuthProvider = () => {
	const { isAuthenticated, authMode, isLoading } = useContext(AuthContext);
	return { isAuthenticated, isLoading, authMode };
};

/**
 * HOC for class-based React components that can't use `useAuthProvider`
 */
export const withAuthProvider = (Comp) => {
	return (props) => {
		const authProps = useAuthProvider();
		return <Comp {...props} auth={authProps} />;
	};
};

function useAccessToken() {
	const [token, setToken] = useState(getAccessToken());
	useEffect(() => {
		function handler() {
			if (token !== getAccessToken()) {
				setToken(token);
			}
		}
		window.addEventListener('storage', handler);
		return () => window.removeEventListener('storage', handler);
	}, [token]);

	return [
		token,
		(value) => {
			setAccessToken(value);
			setToken(value);
		},
	];
}

function useRefreshAccessToken({ onFail }) {
	const [accessToken, setToken] = useAccessToken();
	/**
	 * @type moment.Moment | null
	 */
	let exp = null;
	if (accessToken?.startsWith('Bearer')) {
		const [_scheme, token] = accessToken.split(' ');
		const [_header, payload] = token.split('.');

		try {
			const parsedPayload = JSON.parse(atob(payload));
			exp = moment.unix(parsedPayload.exp);
		} catch {}
	}

	const { getAccessTokenSilently } = useAuth0();
	const timeoutRef = useRef();

	async function refreshAccessToken() {
		try {
			const at = await getAccessTokenSilently();
			setToken(`Bearer ${at}`);
		} catch (e) {
			if (e.message === 'Unknown or invalid refresh token.') {
				await logout();
			}
			throw e;
		}
	}

	useEffect(() => {
		if (exp) {
			const threshold = moment(exp).subtract(30, 'seconds');
			timeoutRef.current = setTimeout(
				() => {
					void refreshAccessToken().catch(onFail);
				},
				exp.diff(threshold, 'milliseconds')
			);
		}
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [accessToken]);

	return {
		refreshAccessToken,
	};
}

/**
 * Handles access to pages that require auth; renders login flow selector page;
 * determines auth mode (SSO or Auth0)
 */
const AuthProvider = function (props) {
	const dispatch = useDispatch();
	const lostAccess = useAuthorizationDetector();
	const [isAuthenticated, setIsAuthenticated] = useState(null);
	const [authMode, setAuthMode] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const isTabVisible = useIsTabActive();

	async function redirectToLogin() {
		const { location } = props;
		if (location.pathname.startsWith('/login')) {
			// The authenticated routes are under /ui
			// We don't want to redirect if we're already under /login or similar anonymous routes.
			return;
		}

		window.location.href = `${process.env.REACT_APP_REDIRECT_BASE_URL}login`;
	}

	const { refreshAccessToken } = useRefreshAccessToken({
		onFail: redirectToLogin,
	});

	useEffect(() => {
		// https://web.dev/articles/bfcache#update-data-after-restore
		function handler(e) {
			if (e.persisted) {
				redirectToLogin();
			}
		}

		window.addEventListener('pageshow', handler);

		return () => {
			window.removeEventListener('pageshow', handler);
		};
	}, []);

	useEffect(() => {
		// To avoid ending up with both SSO and Auth0 access tokens at the same
		// time, Auth0 access token (in localStorage) should be cleared after
		// successful login through SSO (redirects to `/ui/signincallback`).
		// Auth0 login flow will append `?auth0` as a query parameter:
		// `/ui/signincallback?auth0` to signal the app to not clear the just
		// obtained access token.
		const { location } = props;
		if (
			location.pathname === '/signincallback' &&
			qs.parse(location.search)?.auth0 !== 'true'
		) {
			clearAccessToken();
		}
	}, [location]);

	useEffect(() => {
		async function handleLostAccess() {
			if (lostAccess) {
				try {
					await refreshAccessToken();
					setIsAuthenticated(true);
				} catch {
					setIsAuthenticated(false);
					await redirectToLogin();
				}
			}
		}
		void handleLostAccess();
	}, [lostAccess]);

	async function testAuth() {
		try {
			// attempt to fetch user information
			await SessionService.me();
			setIsAuthenticated(true);
			// If token is stored in localStorage, user is authenticated through
			// Auth0
			setAuthMode(getAccessToken() ? AUTH_MODE.AUTH0 : AUTH_MODE.GMI_SSO);
			dispatch(requestAuthorizationStatus(true));
			setIsLoading(false);
		} catch (e) {
			// Auth0 login successful, however there's no EMR account with that
			// email address
			if (e.response?.data?.message === 'Missing account') {
				window.location.href = e.response.data.redirectTo;
				return;
			}
			setIsAuthenticated(false);
			setAuthMode(null);
			setIsLoading(false);
		}
	}

	useEffect(() => {
		if (isTabVisible) {
			void testAuth();
		}
	}, [isTabVisible]);

	return (
		<AuthContext.Provider value={{ isAuthenticated, isLoading, authMode }}>
			{!isLoading && isAuthenticated && props.children}
		</AuthContext.Provider>
	);
};

export default withRouter(AuthProvider);
