//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Utils
import { determineWidthOverflow, PATIENT_TAG_DEFINITION } from './../../utils.js';

//Components
import Tooltip from '../Common/Tooltip';
import LinkPopover from '../Common/Popovers/LinkPopover';

class PatientTag extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			duplicatePatients: [],
		};
	}
	//functions

	componentDidMount() {
		this.forceUpdate();
		this.setState({
			duplicatePatients: this.props.duplicatePatients || [],
		});
	}

	render() {
		const type = get(this, 'props.tagType', {}) || {};
		const tag = PATIENT_TAG_DEFINITION[type];
		const noMarginBottom = get(this, 'props.noMarginBottom', false);
		const customText = get(this, 'props.customText', null);
		const customTextPrefix = get(tag, 'customTextPrefix', '');
		const tagText =
			(customText && customTextPrefix + customText) ||
			get(tag, 'text', null) ||
			'--';
		const customStyling = get(this, 'props.customStyling', null) || {};
		return (
			<div
				className='patientInfoBoxTag'
				style={{
					backgroundColor: get(tag, 'bgColor', null) || 'black',
					color: get(tag, 'textColor', null) || 'white',
					marginBottom: noMarginBottom ? '0px' : '8px',
					...customStyling,
				}}
			>
				{type === 'duplicate' && this.state.duplicatePatients.length ? (
					<LinkPopover
						enqueueSnackbar={this.props.enqueueSnackbar}
						options={{
							iconAlignment: 'left',
							multiChildElements:
								this.state.duplicatePatients.map((user) => {
									const {
										first_name = '',
										last_name = '',
										uuid = '',
									} = user;

									return {
										enqueueSnackbar:
											this.props.enqueueSnackbar,
										linkTo: `/app/patientdetail/${uuid}/0/0`,
										options: {
											iconAlignment: 'left',
											link: true,
											linkOptions: {
												style: {
													marginRight: '8px',
													marginLeft: '4px',
												},
												text: `View ${first_name} ${last_name}`,
											},
											text: true,
										},
									};
								}),
						}}
					>
						<div
							className='patientTag'
							ref={(el) => (this.tagTextEl = el)}
						>
							{tagText}
						</div>
					</LinkPopover>
				) : (
					<Tooltip
						className='duplicatePatientTooltipOverflowTooltip'
						effect='dark'
						content={tagText}
						placement='bottom'
						disabled={determineWidthOverflow(this.tagTextEl)}
					>
						<div
							className='patientTag'
							ref={(el) => (this.tagTextEl = el)}
						>
							{tagText}
						</div>
					</Tooltip>
				)}
			</div>
		);
	}
}

export default PatientTag;
