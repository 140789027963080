//React & Redux
import React from 'react';
import PropTypes from 'prop-types';

//Lodash
import _ from 'lodash';

//Images
import ReactSVG from 'react-svg';
import link from './../../assets/icons/link.svg';
import pencil from './../../assets/icons/pencil.svg';
import video from './../../assets/icons/video.svg';
import phone from './../../assets/icons/phone.svg';
import close from './../../assets/icons/close.svg';
import search from './../../assets/icons/search.svg';
import info from './../../assets/icons/info.svg';
import calendar from './../../assets/icons/calendar.svg';
import timerHelp from './../../assets/icons/timer-help.svg';
import leftArrow from './../../assets/icons/left-arrow.svg';
import rightArrow from './../../assets/icons/right-arrow.svg';
import dollarCircle from './../../assets/icons/dollar-circle.svg';
import warningCircle from './../../assets/icons/warning-circle.svg';
import warningCircleRed from './../../assets/icons/warning-circle-red.svg';
import checkCircle from './../../assets/icons/check-circle.svg';
import calendarUserPlus from './../../assets/icons/calendar-user-plus.svg';
import calendarClose from './../../assets/icons/calendar-close.svg';
import downArrow from './../../assets/icons/down-arrow.svg';
import rightArrowThin from './../../assets/icons/right-arrow-thin.svg';
import stethoscope from './../../assets/icons/stethoscope.svg';
import threeDots from './../../assets/icons/three-dots.svg';
import copy from './../../assets/icons/copy.svg';
import externalLink from './../../assets/icons/external-link.svg';

const icons = {
	pencil: pencil,
	link: link,
	video: video,
	phone: phone,
	close: close,
	search: search,
	info: info,
	calendar: calendar,
	'timer-help': timerHelp,
	'left-arrow': leftArrow,
	'right-arrow': rightArrow,
	'warning-circle': warningCircle,
	'warning-circle-red': warningCircleRed,
	'dollor-circle': dollarCircle,
	'check-circle': checkCircle,
	'calendar-user-plus': calendarUserPlus,
	'calendar-close': calendarClose,
	'down-arrow': downArrow,
	'right-arrow-thin': rightArrowThin,
	stethoscope: stethoscope,
	'three-dots': threeDots,
	copy: copy,
	'external-link': externalLink,
};

class Icon extends React.Component {
	constructor(props) {
		super(props);
	}

	getIcon() {
		const { icon } = this.props;
		return _.get(icons, icon, '');
	}

	doNothing() {
		return;
	}

	render() {
		const { className, onClick, style } = this.props;
		return (
			<ReactSVG
				src={this.getIcon()}
				className={className || ''}
				onClick={onClick ? onClick : this.doNothing}
				style={style ? style : {}}
			/>
		);
	}
}

Icon.propTypes = {
	icon: PropTypes.string.isRequired,
	className: PropTypes.string,
	onClick: PropTypes.func,
	style: PropTypes.object,
};

export default Icon;
