//React & Redux
import React from 'react';

//Styles
import './ProviderAddAnother.css';

const ProviderAddAnother = (props) => {
	const { click, disabled, title = '--' } = props;

	const containerClass = disabled
		? 'provider-add-another provider-add-another-disabled'
		: 'provider-add-another';
	return (
		<div className={containerClass} onClick={disabled ? () => {} : click}>
			<span className='add-another'>{title}</span>
		</div>
	);
};

export default ProviderAddAnother;
