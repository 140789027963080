//React & Redux
import React, { useState, useEffect } from 'react';
import { Select } from 'gm-element-react';

//Lodash
import { isNil } from 'lodash';

//Hooks
import { usePrevious } from '../../hooks/usePrevious';

export const SelectComponent = ({
	name = '',
	value,
	onChange,
	placeholder,
	className,
	disabled,
	options,
	typeahead = false,
	clearable = false,
	multiple = false,
	keyOverride = false,
	style = {},
	customOption = null,
	optionClass,
	filterFunction,
	loading,
}) => {
	const [optionsEnum, setOptionsEnum] = useState(options);
	const prevOption = usePrevious(options);

	useEffect(() => {
		if (options != prevOption) {
			setOptionsEnum(options);
		}
	}, [options]);

	return (
		<Select
			loading={loading}
			value={value}
			name={name}
			disabled={disabled}
			onChange={onChange}
			placeholder={placeholder}
			className={className}
			multiple={multiple}
			style={{ style }}
			filterable={typeahead}
			clearable={clearable}
			filterMethod={(val) => {
				if (!_.isNil(typeahead) && typeahead) {
					if (filterFunction) {
						filterFunction(val);
					} else {
						if (_.isEmpty(val) || _.isNil(val)) {
							setOptionsEnum(options);
						} else {
							const _options = options.filter((o) => {
								if (keyOverride) {
									return (
										o.label
											.toString()
											.toLowerCase()
											.startsWith(val.toLowerCase()) ||
										o.key
											.toString()
											.toLowerCase()
											.startsWith(val.toLowerCase())
									);
								} else {
									return o.label
										.toString()
										.toLowerCase()
										.startsWith(val.toLowerCase());
								}
							});
							setOptionsEnum(_options);
						}
					}
				}
			}}
		>
			{(_.isArray(optionsEnum) ? optionsEnum : []).map((el) => {
				return (
					<Select.Option
						key={el.key ?? el.value}
						value={el.value}
						label={keyOverride ? el.key : el.label}
						disabled={!isNil(el.disabled) ? el.disabled : false}
						className={!isNil(optionClass) ? optionClass : ''}
					>
						{customOption ? customOption(el) : el.label}
					</Select.Option>
				);
			})}
		</Select>
	);
};
