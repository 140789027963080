//React & Redux
import React, { useState, useEffect } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	getMomentDateOrStringNonStrick,
	isValidDateStringOREmpty,
	createEnumsOptions,
} from '../Common/utilsfunctions';

//UI Libraries
import { Button, Loading } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import { EncounterTextAreaField } from '../Common/EncounterTextAreaField';
import { EncounterNumberInputField } from '../Common/EncounterNumberInputField';
import { EncounterInputField } from '../Common/EncounterInputField';
import { EncounterSelectField } from '../Common/EncounterSelectField';
import { EncounterDateField } from '../Common/EncounterDateField';

const datefields = {
	patient_fee_refund_processed_date: 1,
};

const EditBillingSegment = (props) => {
	const {
		encounter,
		billingencounter,
		cancel,
		saveResearchEncounter,
		saveResearchBillingEncounter,
	} = props;

	const tempFormData = {
		billing_notes: _.get(billingencounter, ['billing_notes'], ''),
		patient_fee_refund: _.get(
			billingencounter,
			['patient_fee_refund'],
			null
		),
		patient_fee_refund_processed_date: _.get(
			billingencounter,
			['patient_fee_refund_processed_date'],
			''
		),
		patient_fee_refund_reason: _.get(
			billingencounter,
			'patient_fee_refund_reason',
			null
		),
		patient_fee_refund_reason_other: _.get(
			billingencounter,
			'patient_fee_refund_reason_other',
			null
		),
	};
	const updatedformdata = _.mapValues(tempFormData, function (value, key) {
		if (key in datefields) {
			if (key == 'patient_fee_refund_processed_date' && _.isNil(value)) {
				return '';
			}
			return getMomentDateOrStringNonStrick(value);
		}
		return value;
	});
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [touchedAll, setTouchAll] = useState(false);

	useEffect(() => {
		setFormData(updatedformdata);
	}, []);

	const validateForm = (objData) => {
		let errors = {};
		const datetimeerors = _.omitBy(
			_.mapValues(datefields, function (value, key) {
				if (!isValidDateStringOREmpty(_.get(objData, key, ''))) {
					return 'Invalid date';
				}
				return undefined;
			}),
			_.isUndefined
		);

		errors = { ...datetimeerors };
		if (
			_.get(objData, 'patient_fee_refund_reason', '') === 'other' &&
			_.isEmpty(_.get(objData, 'patient_fee_refund_reason_other', ''))
		) {
			errors['patient_fee_refund_reason_other'] =
				'Other refund reason is required';
		}
		return errors;
	};

	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		let errors = {};
		if (key in datefields) {
			if (_.isNil(value)) {
				stateData[key] = null;
			} else if (moment(value).isValid()) {
				stateData[key] = moment(value).format('YYYY-MM-DD');
			}
		} else {
			stateData[key] = value;
		}
		errors = validateForm(stateData);
		setErrors(errors);
		setFormData(stateData);
	};
	const handleDateFieldValidate = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		const errors = validateForm(stateData);
		setErrors(errors);
		setFormData(stateData);
	};
	const saveForm = () => {
		const errors = validateForm(formData);
		if (_.isEmpty(errors)) {
			const updatedformdata = _.mapValues(
				formData,
				function (value, key) {
					if (key == 'billing_notes') return value && value.trim();
					if (
						key == 'patient_fee_refund_processed_date' &&
						value === ''
					) {
						return null;
					}
					if (key == 'patient_fee_refund' && value === undefined) {
						return null;
					}
					if (
						key === 'patient_fee_refund_reason_other' &&
						value === ''
					)
						return null;
					if (value == 'empty') return '';
					return value;
				}
			);

			if (
				updatedformdata.patient_fee_refund_reason != 'other' &&
				updatedformdata.patient_fee_refund_reason_other
			) {
				updatedformdata.patient_fee_refund_reason_other = null;
			}
			if (
				updatedformdata['post_negative_minutes'] === '' ||
				updatedformdata['post_negative_minutes'] == undefined
			)
				updatedformdata['post_negative_minutes'] = null;
			setLoading(true);
			saveResearchBillingEncounter(encounter, updatedformdata)
				.then((result) => {
					setTimeout(() => {
						saveResearchEncounter(encounter, updatedformdata).then(
							(res) => {
								setTimeout(() => {
									setLoading(false);
									cancel();
								}, 1000);
							}
						);
					}, 1000);
				})
				.catch((error) => {
					setLoading(false);
				});
		} else {
			setTouchAll(true);
			setErrors(errors);
		}
	};

	const patientFeeRefundReasonOther =
		_.get(formData, ['patient_fee_refund_reason'], null) === 'other';

	return (
		<Loading loading={loading}>
			<div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Fee Refund
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Fee Refund Processed Date
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Fee Refund Reason
							</div>
						</div>
						{patientFeeRefundReasonOther && (
							<div className='segment-col'>
								<div className='encounter-field-header required'>
									Other Refund Reason
								</div>
							</div>
						)}
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								<EncounterNumberInputField
									name='patient_fee_refund'
									value={_.get(
										formData,
										['patient_fee_refund'],
										null
									)}
									onChange={(value) =>
										onFormChange(
											'patient_fee_refund',
											value
										)
									}
									min='1'
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									name='patient_fee_refund_processed_date'
									value={_.get(
										formData,
										['patient_fee_refund_processed_date'],
										''
									)}
									placeholder='Date'
									error={_.get(
										errors,
										['patient_fee_refund_processed_date'],
										''
									)}
									onChange={(value) =>
										onFormChange(
											'patient_fee_refund_processed_date',
											value
										)
									}
									onBlur={(value) =>
										handleDateFieldValidate(
											'patient_fee_refund_processed_date',
											value
										)
									}
									disabledDate={(current) => {
										const yesterday = _.get(
											encounter,
											['date_of_service'],
											''
										);
										const momentcurrent = moment(current);
										if (!_.isNil(yesterday))
											return momentcurrent.isAfter(
												moment(yesterday).clone()
											);
										else return true;
									}}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								<EncounterSelectField
									value={_.get(formData, [
										'patient_fee_refund_reason',
									])}
									name='patient_fee_refund_reason'
									extraclassName='noTextBreak'
									options={createEnumsOptions(
										props,
										'patientFeeEnum',
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'patient_fee_refund_reason',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						{patientFeeRefundReasonOther && (
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterInputField
										placeholder='Specify other reason'
										trim={true}
										value={_.get(formData, [
											'patient_fee_refund_reason_other',
										])}
										error={_.get(
											errors,
											['patient_fee_refund_reason_other'],
											''
										)}
										name='patient_fee_refund_reason_other'
										disabled={false}
										onChange={(value) =>
											onFormChange(
												'patient_fee_refund_reason_other',
												value
											)
										}
										istouched={touchedAll}
										maxLength={50}
									/>
								</div>
							</div>
						)}
					</div>
				</div>

				<div className='edit-encntr-lbl-billing-notes'>
					BILLING NOTES
				</div>
				<div className='segment-row segment-research-row'>
					<EncounterTextAreaField
						value={_.get(formData, ['billing_notes'], '')}
						name='billing_notes'
						onChange={(value) =>
							onFormChange('billing_notes', value)
						}
					/>
				</div>
				<div className='segment-footer-container'>
					<div className='cancel-btn-link'>
						<Button onClick={() => cancel()}>Cancel</Button>
					</div>
					<div className='save-btn-link'>
						<Button style={{ float: 'right' }} onClick={saveForm}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</Loading>
	);
};

export default EditBillingSegment;
