//React & Redux
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withAuth0 } from '@auth0/auth0-react';

//Images
import ReactSVG from 'react-svg';
import Arrow from '../NavBar/menu-breadcrumb.png';
import checkMark from './check-mark.svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import { dashboard_key_title } from '../../Utils/dashboardUtils';
import { CARE_PATIENTS } from '../../Utils/permissionUtils';

//Actions & Services
import { getpatients } from '../../actions/patients';
import { getsearchkey, resetsearchkey } from '../../actions/Search';
import { resetStore } from '../../actions/store';
import { changeCurrentRole, switchRole } from '../../actions/me';
import { clearAccessToken } from '../../service/Storage';

//UI Libraries
import { Dropdown } from 'gm-element-react';

//Other Libraries
import { v4 } from 'uuid';

//Components
import DownArrowIcon from '../Icons/DownArrow';
import SearchPatient from '../Common/SearchPatient';
import Stopwatch from './Stopwatch';
import RBAC from '../RBAC';
import CTAWarningModal from '../Common/CTAWarningModal';
import { withAuthProvider, AUTH_MODE } from '../Login/AuthProvider';

//Styles
import './style.css';

class PageHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null,
			open: false,
			hasFocus: false,
			advancedSearchIconHover: false,
			navMenuIconHover: false,
			switchModal: false,
		};
		//bindings
		this.handleRedirect = this.handleRedirect.bind(this);
		this.redirectBackToPatient = this.redirectBackToPatient.bind(this);
		this.renderLogoutMenuTriggerElement =
			this.renderLogoutMenuTriggerElement.bind(this);
		this.handleCommand = this.handleCommand.bind(this);
	}

	static propTypes = {
		auth: PropTypes.object,
	};

	getName = () => {
		const { user } = this.props;
		const firstName = get(user, 'first_name', '');
		const lastName = get(user, 'last_name', '');
		return `${firstName} ${get(lastName, '0', '')}`;
	};

	getCurrentrole = () => {
		const availableRoles = this.getAvailableRoles();

		return _.get(
			_.find(availableRoles, ['uuid', this.props.activeRole]),
			['name'],
			''
		);
	};

	handleToggle = (status) => {
		const open = get(this, 'state.open', false);
		const isNotUndefined = status !== undefined;
		this.setState((state) => ({ open: isNotUndefined ? status : !open }));
	};

	dologout = () => {
		const { authMode } = this.props.auth;
		if (authMode === AUTH_MODE.AUTH0) {
			const { logout } = this.props.auth0;
			void logout({
				logoutParams: {
					returnTo: window.location.origin,
				},
			}).then(() => {
				clearAccessToken();
			});
		} else {
			window.location.href = `${process.env.REACT_APP_REDIRECT_BASE_URL}oauth/dologout`;
		}
		this.props.resetStore();
	};

	renderLogoutMenuTriggerElement() {
		const iconHover = get(this, 'state.navMenuIconHover', false);
		const menuOpen = get(this, 'state.open', false);
		const availableRoles = this.getAvailableRoles();

		return (
			<div className='popoverTarget'>
				{availableRoles.length > 1 ? (
					<span
						className='user-name truncate'
						style={{
							color:
								iconHover || menuOpen ? '#7B26C9' : '#7b8291',
						}}
					>
						{this.getName()} - <b>{this.getCurrentrole()}</b>
					</span>
				) : (
					<span
						className='user-name truncate'
						style={{
							color:
								iconHover || menuOpen ? '#7B26C9' : '#7b8291',
						}}
					>
						{this.getName()}
					</span>
				)}
				<span className='user-caret'>
					<DownArrowIcon
						color={iconHover || menuOpen ? '#7B26C9' : '#7b8291'}
					/>
				</span>
			</div>
		);
	}

	handleCommand(action) {
		const { activeRole } = this.props;
		switch (action) {
			case 'logout':
				this.dologout();
				break;
			default:
				switchRole(activeRole, action).then((res) => {
					if (res.status === 'SELECTED_ROLE') {
						const SwitchModalObj = {
							roleUUID: action,
						};
						this.setState({
							switchModal: true,
							SwitchModalObj,
						});
					} else if (
						res.status === 'CURRENT' ||
						res.status === 'DEFAULT'
					) {
						const SwitchModalObj = {
							roleUUID:
								res.status === 'CURRENT'
									? activeRole
									: res.roleuuid,
						};
						this.setState({
							switchModal: true,
							SwitchModalObj,
						});
					}
					return res;
				});
		}
	}

	renderSwitchRoleModal() {
		return (
			<CTAWarningModal
				noCloseButton={true}
				colorScheme='purple'
				hideTitle={true}
				text={
					'It appears that your permissions to view the pages on the portal were updated. Please refresh using the button below to continue using the portal.'
				}
				mainButtonText='Refresh'
				buttonRowBorder={true}
				mainFunc={() => {
					this.props.changeCurrentRole(
						_.get(this.state, ['SwitchModalObj', 'roleUUID'], '')
					);
				}}
				customZIndex={100001}
				noInitialClick={true}
			/>
		);
	}

	createBreadcrumbsAndRedirect(e) {
		const location_state = get(this, 'props.history.location.state', {});
		location_state['breadcrumbs'] = [
			{
				location: e.location,
				offset: e.offset,
				order_by: e.order_by,
				order_type: e.order_type,
				referral_program_id: e.referral_program_id,
				referral_program_display: e.referral_program_display,
				attempts: e.attempts,
				key: 'dashboard_card',
				encounter_type: e.encounter_type,
				cap_filter_status: e.cap_filter_status,
				followup_cap_completed_date: e.followup_cap_completed_date,
				time_filter: e.time_filter,
				type_filter: e.type_filter,
				assigned_cc_uuid: e.assigned_cc_uuid,
				showCreateOutreachModal: e.showCreateOutreachModal,
				selectedPatient: e.selectedPatient,
				idx: e.idx,
				time_filter: e.time_filter,
				orderType: e.orderType,
				orderBy: e.orderBy,
			},
		];
		if (e.current) location_state['current'] = e.current;

		this.props.history.history.push(
			e.location == 'ror_outreach_pending'
					? '/app/dashboard/patients/ror?filter=ror_outreach_pending'
					: '/app/dashboard/encounters?filter=' + e.location,
			location_state
		);
	}

	redirectBackToPatient(e, isLastItem) {
		const location_state = get(this, 'props.history.location.state', {});

		const breadcrumbs =
			get(this, 'props.history.location.state.breadcrumbs', []) || [];
		const breadcrumbsLength = get(breadcrumbs, 'length', 0);
		const patientUuid = get(
			breadcrumbs,
			`[${breadcrumbsLength - 1}].state.patientUuid`,
			null
		);

		if (patientUuid && !isLastItem) {
			const pathname = '/app/patientdetail/' + patientUuid + '/0/0/';
			location_state['breadcrumbs'] = location_state['breadcrumbs'].slice(
				0,
				-1
			);
			this.props.history.history.push(pathname, location_state);
		}
	}

	createBreadcrumbsAndRedirectEnhancedSearch(e) {
		const location_state = get(this, 'props.history.location.state', {});
		location_state['selected_pill'] = get(e, 'selected_pill', []);
		this.props.history.history.push('/app/advancedsearch', location_state);
	}

	redirectToRefProgram(e) {
		const location_state = get(this, 'props.history.location.state', {});
		location_state['breadcrumbs'] = [
			{
				offset: e.offset,
				order_by: e.order_by,
				order_type: e.order_type,
				query: e.query,
			},
		];

		this.props.history.history.push(
			'/app/referralprograms',
			location_state
		);
	}

	redirectToAptmntListPage(e) {
		const location_state = get(this, 'props.history.location.state', {});
		location_state['breadcrumbs'] = [
			{
				filter: get(location_state, 'breadcrumbs[0].filter', null),
				location: 'Appointments',
				url: '/app/appointments',
			},
		];

		this.props.history.history.push('/app/appointments', location_state);
	}

	redirectToTestMngmnt(e) {
		const location_state = get(this, 'props.history.location.state', {});
		location_state['breadcrumbs'] = [
			{
				tags: e.tags,
				testTypes: e.testTypes,
				labs: e.labs,
				offered: e.offered,
				order: e.order,
				orderBy: e.orderBy,
				activePage: e.activePage,
			},
		];

		this.props.history.history.push('/app/testmanagement', location_state);
	}

	handleRedirect(name, breadcrumbs) {
		const location_state = get(this, 'props.history.location.state', {});
		location_state['breadcrumbs'] = [
			{
				location: 'Appointments',
				url: '/app/appointments',
			},
		];
		switch (name) {
			case 'Appointments':
				this.props.history.history.push('/app/appointments');
				break;
			default:
				console.log('Unrecognized case for header redirect.');
				break;
		}
	}

	redirectToAdvancedSearch = () => {
		this.props.history.history.push('/app/advancedsearch');
	};

	redirectToTestAuth = (e) => {
		const location_state = get(this, 'props.history.location.state', {});
		location_state['breadcrumbs'] = [
			{
				location: 'Test Authorization',
				url: '/app/testauthorization',
				searchData: get(
					location_state,
					'breadcrumbs[0].searchData',
					null
				),
			},
		];

		this.props.history.history.push(
			'/app/testauthorization',
			location_state
		);
	};

	redirectToRise = (e) => {
		const location_state = get(this, 'props.history.location.state', {});
		location_state['breadcrumbs'] = [
			{
				location: 'RISE',
				url: '/app/rise',
				selectedCard: get(
					location_state,
					'breadcrumbs[0].selectedCard',
					null
				),
				CardPageData: get(
					location_state,
					'breadcrumbs[0].CardPageData',
					null
				),
			},
		];
		this.props.history.history.push('/app/rise', location_state);
	};

	getBreadcrumbs(breadcrumbs, arrowIcon) {
		let return_div = '';
		//if(breadcrumbs.length == 3){
		const length = breadcrumbs.length;
		const deep = length > 1;
		return_div = breadcrumbs.map((e, idx) => {
			const isLastItem = idx === length - 1;

			if (e.location == 'dashboard')
				return (
					<div>
						<Link className='current-menu-item' to='/app/dashboard'>
							{'Dashboard'}
						</Link>
					</div>
				);

			if (e.location == 'patients')
				return (
					<div>
						<Link className='current-menu-item' to={e.url}>
							{'Patients'}
						</Link>
					</div>
				);

			if (e.key == 'dashboard_card')
				return (
					<div>
						{idx !== 0 && arrowIcon}
						<div
							className='current-menu-item'
							onClick={(evt) =>
								this.createBreadcrumbsAndRedirect(e)
							}
							style={{ cursor: 'pointer' }}
						>
							{get(dashboard_key_title, e.location, 'Encounters')}
						</div>
					</div>
				);

			if (e.location == 'Encounter Detail')
				return (
					<div>
						{arrowIcon}
						<div
							className={
								isLastItem
									? 'main-menu-item'
									: 'current-menu-item'
							}
							style={{
								maxWidth: '280px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								textTransform: 'capitalize',
								cursor: isLastItem ? 'unset' : 'pointer',
							}}
							onClick={(evt) =>
								this.redirectBackToPatient(e, isLastItem)
							}
						>
							{get(e, 'patientFullName', '--') || '--'}
						</div>
					</div>
				);

			if (e.location == 'Patient Detail')
				return (
					<div>
						{deep && idx !== 0 && arrowIcon}
						<div
							className={
								isLastItem
									? 'main-menu-item'
									: 'current-menu-item'
							}
							style={{
								maxWidth: '280px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								textTransform: 'capitalize',
								cursor: isLastItem ? 'unset' : 'pointer',
							}}
							onClick={(evt) =>
								this.redirectBackToPatient(e, isLastItem)
							}
						>
							{get(e, 'patientFullName', '--') || '--'}
						</div>
					</div>
				);

			if (e.location == 'Advanced Search')
				return (
					<div>
						<div
							onClick={(evt) =>
								this.createBreadcrumbsAndRedirectEnhancedSearch(
									e
								)
							}
							className={
								deep ? 'current-menu-item' : 'main-menu-item'
							}
							style={{ cursor: 'pointer' }}
							// to={e.url || '/app/advancedsearch'}
						>
							{'Advanced Search'}
						</div>
					</div>
				);

			if (e.location == 'Appointments' || e.location == 'Referrals') {
				return (
					<div>
						<div
							className={
								deep ? 'current-menu-item' : 'main-menu-item'
							}
							onClick={(e) => this.redirectToAptmntListPage(e)}
							style={{ cursor: 'pointer' }}
						>
							{'Appointments'}
						</div>
					</div>
				);
			}

			if (e.location == 'Create Patient')
				return (
					<div>
						{' '}
						{idx !== 0 && arrowIcon}{' '}
						<div className='main-menu-item'>{'Create Patient'}</div>{' '}
					</div>
				);

			if (
				e.location === 'Edit Patient' ||
				e.location === 'Edit Patient Legacy'
			) {
				const label = e.location;

				return (
					<div>
						{idx !== 0 && arrowIcon}
						<div className='main-menu-item'>{label}</div>
					</div>
				);
			}

			if (e.location == 'Schedule Appointment')
				return (
					<div>
						{' '}
						{arrowIcon}{' '}
						<div className='main-menu-item'>
							{'Schedule appointment'}
						</div>{' '}
					</div>
				);

			if (e.location == 'Reschedule Appointment')
				return (
					<div>
						{' '}
						{arrowIcon}{' '}
						<div className='main-menu-item'>{'Reschedule'}</div>{' '}
					</div>
				);

			if (e.location == 'Referral program')
				return (
					<div
						className='current-menu-item'
						onClick={(evt) => this.redirectToRefProgram(e)}
						style={{ cursor: 'pointer' }}
					>
						{'Referral programs management'}
					</div>
				);

			if (e.location == 'Referral program detail')
				return (
					<div>
						{' '}
						{arrowIcon}{' '}
						<div className='main-menu-item'>
							{'Referral program detail'}
						</div>{' '}
					</div>
				);

			if (e.location == 'AppointmentsToPatientDetail')
				return (
					<div
						className='current-menu-item'
						onClick={(evt) => this.redirectToAptmntListPage(e)}
						style={{ cursor: 'pointer' }}
					>
						{'Appointments'}
					</div>
				);

			if (e.location == 'Test management')
				return (
					<div
						className='current-menu-item'
						onClick={(evt) => this.redirectToTestMngmnt(e)}
						style={{ cursor: 'pointer' }}
					>
						{'Test Management'}
					</div>
				);

			if (e.location == 'Test Detail')
				return (
					<div>
						{' '}
						{arrowIcon}{' '}
						<div className='main-menu-item'>{'Test Detail'}</div>{' '}
					</div>
				);

			if (e.location == 'Test Authorization')
				return (
					<div
						className='current-menu-item'
						onClick={(e) => this.redirectToTestAuth(e)}
						style={{ cursor: 'pointer' }}
					>
						{'Test Authorization'}
					</div>
				);
			if (e.location == 'RISE')
				return (
					<div
						className='current-menu-item'
						onClick={(e) => this.redirectToRise(e)}
						style={{ cursor: 'pointer' }}
					>
						{'RISE'}
					</div>
				);
		});
		//}
		return <div style={{ display: 'flex' }}> {return_div} </div>;
	}

	getPageTitle() {
		let filter = get(this, 'props.history.location.state.current', '');
		const breadcrumbs = get(
			this,
			'props.history.location.state.breadcrumbs',
			[]
		);
		let pathname = get(this, 'props.history.location.pathname', '');
		if (pathname.indexOf('/app/patientdetail') != -1)
			pathname = '/app/patientdetail';
		else if (pathname.indexOf('/app/patients') != -1)
			pathname = '/app/patients';
		else if (pathname.indexOf('/app/test/') != -1) pathname = '/app/test';
		else if (pathname.indexOf('/app/referralprogramdetail/') != -1)
			pathname = '/app/referralprogramdetail';
		else if (pathname.indexOf('/app/appointments/schedule') != -1)
			pathname = '/app/appointments/schedule';
		else if (pathname.indexOf('/app/appointments/reschedule') != -1)
			pathname = '/app/appointments/reschedule';
		else if (pathname.indexOf('/app/appointments') != -1)
			pathname = '/app/appointments';
		else if (pathname.indexOf('/app/insurance') != -1)
			pathname = '/app/insurance';
		else if (pathname.indexOf('/app/templates') != -1)
			pathname = '/app/templates';
		else if (pathname.indexOf('/app/testauthorization') != -1)
			pathname = '/app/testauthorization';
		else if (pathname.indexOf('/app/patientmanagement') != -1)
			pathname = '/app/patientmanagement';
		else if (pathname.indexOf('/app/releasenotes') != -1)
			pathname = '/app/releasenotes';

		if (!filter || filter.length == 0) {
			const query = get(this, 'props.history.location.search', '').split(
				'='
			);
			filter = query.length > 1 ? query[1] : '';
		}

		const arrowIcon = (
			<div style={{ float: 'left' }}>
				<div className='rectangle-left'></div>
				<div className='rectangle'>
					<img src={Arrow} alt='' />
				</div>
				<div className='rectangle-left'></div>
			</div>
		);
		switch (pathname) {
			case '/app/dashboard':
				return <div className='main-menu-item'>{'Dashboard'}</div>;
			case '/app/providers':
				return <div className='main-menu-item'>{'Reports'}</div>;
			case '/app/billing':
				return <div className='main-menu-item'>{'Billing'}</div>;
			case '/app/patients':
				return <div className='main-menu-item'>{'Patients'}</div>;
			case '/app/referraldashboard':
				return (
					<div className='main-menu-item'>
						Referrals for Appointment Scheduling
					</div>
				);
			case '/app/order_request':
				return <div className='main-menu-item'>{'Lab Order'}</div>;
			case '/app/dashboard/encounters':
				return (
					<div>
						<div className='main-menu-item capitalize'>
							{get(dashboard_key_title, filter, 'Encounters')}
						</div>
					</div>
				);
			case '/app/dashboard/outreach/patients/v2':
				return (
					<div>
						<div className='main-menu-item'>
							{'Patient Outreach Pending'}
						</div>
					</div>
				);
			case '/app/dashboard/patients/ror':
				return (
					<div>
						<div className='main-menu-item'>
							{'RoR Outreach Pending'}
						</div>
					</div>
				);
			case '/app/patientdetail':
				if (breadcrumbs.length > 0) {
					return this.getBreadcrumbs(breadcrumbs, arrowIcon);
				} else {
					return <div className='main-menu-item'>{'--'}</div>;
				}
			case '/app/patientmanagement':
				if (breadcrumbs.length > 0) {
					return this.getBreadcrumbs(breadcrumbs, arrowIcon);
				} else {
					return (
						<div className='main-menu-item'>{'Create Patient'}</div>
					);
				}
			case '/app/advancedsearch':
				return (
					<div className='main-menu-item'>{'Advanced Search'}</div>
				);
			case '/app/testmanagement':
				return (
					<div className='main-menu-item'>{'Test management'}</div>
				);
			case '/app/test':
				if (breadcrumbs.length > 0) {
					return this.getBreadcrumbs(breadcrumbs, arrowIcon);
				} else {
					return (
						<div className='main-menu-item'>{'Test Detail'}</div>
					);
				}
			case '/app/referralprograms':
				return (
					<div className='main-menu-item'>
						{'Referral programs management'}
					</div>
				);
			case '/app/referralprogramdetail':
				if (breadcrumbs.length > 0) {
					return this.getBreadcrumbs(breadcrumbs, arrowIcon);
				} else {
					return (
						<div className='main-menu-item'>
							{'Referral program detail'}
						</div>
					);
				}
			case '/app/appointments':
				return <div className='main-menu-item'>{'Appointments'}</div>;
			case '/app/appointments/schedule':
				if (breadcrumbs.length > 0) {
					return this.getBreadcrumbs(breadcrumbs, arrowIcon);
				} else {
					return (
						<div style={{ display: 'flex' }}>
							<div>
								{' '}
								<Link
									className='current-menu-item'
									to='/app/appointments'
								>
									{'Appointments'}
								</Link>
							</div>
							<div>
								{' '}
								{arrowIcon}{' '}
								<div className='main-menu-item'>
									{'Schedule appointment'}
								</div>{' '}
							</div>
						</div>
					);
				}
			case '/app/appointments/reschedule':
				if (breadcrumbs.length > 0) {
					return this.getBreadcrumbs(breadcrumbs, arrowIcon);
				} else {
					return (
						<div style={{ display: 'flex' }}>
							<div>
								{' '}
								<Link
									className='current-menu-item'
									to='/app/appointments'
								>
									{'Appointments'}
								</Link>
							</div>
							<div>
								{' '}
								{arrowIcon}{' '}
								<div className='main-menu-item'>
									{'Reschedule'}
								</div>{' '}
							</div>
						</div>
					);
				}
			case '/app/testauthorization':
				return (
					<div className='main-menu-item'>{'Test Authorization'}</div>
				);
			case '/app/insurance':
				return (
					<div className='main-menu-item'>
						{'Insurance management'}
					</div>
				);
			case '/app/templates':
				return <div className='main-menu-item'>{'Templates'}</div>;
			case '/app/externalproviders':
				return (
					<div className='main-menu-item'>{'External Providers'}</div>
				);
			case '/app/releasenotes':
				return <div className='main-menu-item'>{'Release Notes'}</div>;
			case '/app/useraccounts':
				return <div className='main-menu-item'>{'User Accounts'}</div>;
			case '/app/rise':
				return <div className='main-menu-item'>{'RISE'}</div>;
			default:
				return <div className='main-menu-item'>{'Dashboard'}</div>;
		}
		return <div className='main-menu-item'>{'Dashboard'}</div>;
	}

	getAvailableRoles = () => {
		const { roles, userRoles } = this.props;
		const mapper = [];

		for (const { uuid } of roles) {
			mapper.push({
				uuid,
				name: get(_.find(userRoles, ['uuid', uuid]), ['name']),
			});
		}

		return mapper;
	};

	render() {
		const { className, activeRole, auth } = this.props;
		const availableRoles = this.getAvailableRoles();

		return (
			<Fragment>
				<div className={classnames('top-nav fix-header', className)}>
					<div className='logo_container'>{this.getPageTitle()}</div>
					<div className='search-box'>
						<Stopwatch />
						<div className='search-bar'>
							{
								<RBAC
									action={CARE_PATIENTS}
									yes={
										<SearchPatient
											history={this.props.history}
										/>
									}
								/>
							}
						</div>
						<div
							className={classnames('nav-avtar', {
								small: availableRoles.length === 1,
							})}
							onMouseEnter={() =>
								this.setState({ navMenuIconHover: true })
							}
							onMouseLeave={() =>
								this.setState({ navMenuIconHover: false })
							}
						>
							<Dropdown
								trigger='click'
								onCommand={this.handleCommand}
								onVisibleChange={this.handleToggle}
								menu={
									<Dropdown.Menu>
										{availableRoles.length > 1 && (
											<Fragment>
												<Dropdown.Item
													disabled
													className='view-as'
												>
													View As
												</Dropdown.Item>
												{this.getAvailableRoles().map(
													({ uuid, name }) => {
														return (
															<Dropdown.Item
																command={uuid}
																key={uuid}
																disabled={
																	uuid ===
																	activeRole
																}
																className={classnames(
																	'roles',
																	{
																		active:
																			uuid ===
																			activeRole,
																	}
																)}
															>
																{name}{' '}
																{uuid ===
																	activeRole && (
																	<ReactSVG
																		src={
																			checkMark
																		}
																	/>
																)}
															</Dropdown.Item>
														);
													}
												)}
												<Dropdown.Item
													className='divider'
													disabled
												>
													<hr />
												</Dropdown.Item>
											</Fragment>
										)}
										{auth.authMode !== AUTH_MODE.AUTH0 && (
											<Dropdown.Item command='settings'>
												Settings
											</Dropdown.Item>
										)}
										<Dropdown.Item command='logout'>
											Logout
										</Dropdown.Item>
									</Dropdown.Menu>
								}
							>
								{this.renderLogoutMenuTriggerElement()}
							</Dropdown>
						</div>
					</div>
				</div>
				{this.state.switchModal && this.renderSwitchRoleModal()}
			</Fragment>
		);
	}
}
const mapStateToProps = (state, props) => ({
	patients: state.patients.patients,
	loading: state.patients.loading,
	isAuthenticate: state.session.isAuthenticate,
	searchkey: state.searchkey.searchkey,
	user: get(state, 'me.user', {}),
	activeRole: get(state, ['me', 'activerole', 'uuid'], ''),
	roles: get(state, ['me', 'roles'], []),
	userRoles: get(state, ['UserRoles', 'data'], []),
});

const mapDispatchToProps = (dispatch) => ({
	patientslist: (data) => dispatch(getpatients(data)),
	getsearch: (data) => dispatch(getsearchkey(data)),
	resetsearch: () => dispatch(resetsearchkey()),
	changeCurrentRole: (currentRole, role) =>
		dispatch(changeCurrentRole(currentRole, role)),
	resetStore: () => dispatch(resetStore()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withAuth0(withAuthProvider(PageHeader)));
