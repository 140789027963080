import get from 'lodash/get';

import * as actionTypes from './actionTypes';
import EnhancedSearchService from '../service/EnhancedSearch';
import { hydratePatientsValues } from '../transformers/patients/patientTransformer';

export const searched_results = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_RESULTS,
		payload: data,
	};
};

const loadingsearchAffiliation = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_AFFILIATIONS,
	};
};

export const searchaffiliation = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_AFFILIATIONS,
		payload: data,
	};
};

const searchaffiliationError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_AFFILIATIONS_ERROR,
		payload: err,
	};
};

const loadingGetSearchOrderingPhysicians = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_ORDERING_PHYSICIANS,
	};
};

export const searchorderingphysician = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_ORDERING_PHYSICIANS,
		payload: data,
	};
};

const getSearchOrderingPhysiciansError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_ORDERING_PHYSICIANS_ERROR,
		payload: err,
	};
};

const loadingSearchVisitproviders = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_VISIT_PROVIDERS,
	};
};

export const searchvisitproviders = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_VISIT_PROVIDERS,
		payload: data,
	};
};

const searchvisitprovidersError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_VISIT_PROVIDERS_ERROR,
		payload: err,
	};
};

const loadingSearchspecialty = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_SPECIALTY,
	};
};

export const searchspecialty = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_SPECIALTY,
		payload: data,
	};
};

const searchspecialtyError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_SPECIALTY_ERROR,
		payload: err,
	};
};

const loadingSearchtestnames = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_TEST_NAMES,
	};
};

export const searchtestnames = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_TEST_NAMES,
		payload: data,
	};
};

const searchtestnamesError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_TEST_NAMES_ERROR,
		payload: err,
	};
};

const loadingSearchtestresults = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_TEST_NAMES,
	};
};

export const searchtestresults = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_TEST_RESULTS,
		payload: data,
	};
};

const searchtestresultsError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_VISIT_STATUS_ERROR,
		payload: err,
	};
};

const loadingSearchvisitstatus = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_VISIT_STATUS,
	};
};

export const searchvisitstatus = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_VISIT_STATUS,
		payload: data,
	};
};

const loadingSearchActionability = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_ACTIONABILITY,
	};
};

export const searchActionability = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_ACTIONABILITY,
		payload: data,
	};
};

const searchActionabilityError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_ACTIONABILITY_ERROR,
		payload: err,
	};
};

const searchvisitstatusError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_VISIT_STATUS_ERROR,
		payload: err,
	};
};

const loadingAdvancedSearchlabs = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_LABS,
	};
};

export const advancedsearchlabs = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_LABS,
		payload: data,
	};
};

const advancedsearchlabsError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_LABS_ERROR,
		payload: err,
	};
};

const loadingSearchOrderStatus = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_ORDER_STATUS,
	};
};

export const searchOrderStatus = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_ORDER_STATUS,
		payload: data,
	};
};

const searchOrderStatusError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_ORDER_STATUS_ERROR,
		payload: err,
	};
};

const loadingSearchDispatchStatus = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_DISPATCH_STATUS,
	};
};

export const searchDispatchStatus = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_DISPATCH_STATUS,
		payload: data,
	};
};

const searchDispatchStatusError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_DISPATCH_STATUS_ERROR,
		payload: err,
	};
};

const loadingSearchRequestDecision = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_REQUEST_DECISION,
	};
};

export const searchRequestDecision = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_REQUEST_DECISION,
		payload: data,
	};
};

const searchRequestDecisionError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_REQUEST_DECISION_ERROR,
		payload: err,
	};
};

const loadingSearchReferringProviderName = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_REFERRING_PROVIDER_NAME,
	};
};

export const searchReferringProviderName = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_REFERRING_PROVIDER_NAME,
		payload: data,
	};
};

const searchReferringProviderNameError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_REFERRING_PROVIDER_NAME_ERROR,
		payload: err,
	};
};

const loadingSearchVisitState = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_VISIT_STATE,
	};
};

export const searchVisitState = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_VISIT_STATE,
		payload: data,
	};
};

const searchVisitStateError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_VISIT_STATE_ERROR,
		payload: err,
	};
};

const loadingMultiPatientStatus = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_VISIT_STATE,
	};
};

export const multiPatientStatus = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_MULTIPATIENT_STATUS,
		payload: data,
	};
};

const multiPatientStatusError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_MULTIPATIENT_STATUS_ERROR,
		payload: err,
	};
};

const loadingSearchTestType = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_TEST_TYPES,
	};
};

export const searchTestType = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_TEST_TYPES,
		payload: data,
	};
};

const searchTestTypeError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_TEST_TYPES_ERROR,
		payload: err,
	};
};

const loadingEncounterTypes = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_ENCOUNTER_TYPES,
	};
};

export const encounterTypes = (data) => {
	return {
		type: actionTypes.ENHANCED_ENCOUNTER_TYPES,
		payload: data,
	};
};

const encounterTypesError = (err) => {
	return {
		type: actionTypes.ENHANCED_ENCOUNTER_TYPES_ERROR,
		payload: err,
	};
};

const loadingSearchOutreachStatus = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_OUTREACH_STATUS,
	};
};

export const searchOutreachStatus = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_OUTREACH_STATUS,
		payload: data,
	};
};

const searchOutreachStatusError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_OUTREACH_STATUS_ERROR,
		payload: err,
	};
};

export const searchReportExport = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_RESULTS_EXPORT,
		payload: data,
	};
};

export function getEnhancedSearchEncounterTypes(data) {
	return (dispatch) => {
		dispatch(loadingEncounterTypes());
		EnhancedSearchService.getEncounterTypes(data)
			.then((res) => {
				dispatch(encounterTypes({ data: res.data }));
			})
			.catch((err) => {
				dispatch(encounterTypesError(err));
			});
	};
}

export function getSearchedResults(data) {
	return (dispatch) => {
		EnhancedSearchService.getResults(data)
			.then((res) => {
				dispatch(
					searched_results({ data: hydratePatientsValues(res.data) })
				);
			})
			.catch(() => {});
	};
}

export function getSearchAffiliations() {
	return (dispatch) => {
		dispatch(loadingsearchAffiliation());
		EnhancedSearchService.searchAffiliations()
			.then((res) => {
				dispatch(searchaffiliation({ data: res.data }));
			})
			.catch((err) => {
				dispatch(searchaffiliationError(err));
			});
	};
}

export function getSearchOrderingPhysicians() {
	return (dispatch) => {
		dispatch(loadingGetSearchOrderingPhysicians());
		EnhancedSearchService.searchOrderingPhysicians()
			.then((res) => {
				dispatch(searchorderingphysician({ data: res.data }));
			})
			.catch((err) => {
				dispatch(getSearchOrderingPhysiciansError(err));
			});
	};
}

export function getSearchVisitProviders() {
	return (dispatch) => {
		dispatch(loadingSearchVisitproviders());
		EnhancedSearchService.searchVisitProviders()
			.then((res) => {
				dispatch(searchvisitproviders({ data: res.data }));
			})
			.catch((err) => {
				dispatch(searchvisitprovidersError(err));
			});
	};
}

export function getSearchSpecialty() {
	return (dispatch) => {
		dispatch(loadingSearchspecialty());
		EnhancedSearchService.searchSpecialty()
			.then((res) => {
				dispatch(searchspecialty({ data: res.data }));
			})
			.catch((err) => {
				dispatch(searchspecialtyError(err));
			});
	};
}

export function getEnhancedSearchTestNames(data) {
	return (dispatch) => {
		dispatch(loadingSearchtestnames());
		EnhancedSearchService.searchTestNames(data)
			.then((res) => {
				dispatch(searchtestnames({ data: res.data }));
			})
			.catch((err) => {
				dispatch(searchtestnamesError(err));
			});
	};
}

export function getEnhancedSearchTestResults() {
	return (dispatch) => {
		dispatch(loadingSearchtestresults());
		EnhancedSearchService.searchTestResults()
			.then((res) => {
				dispatch(searchtestresults({ data: res.data }));
			})
			.catch((err) => {
				dispatch(searchtestresultsError(err));
			});
	};
}

export function getSearchVisitStatus() {
	return (dispatch) => {
		dispatch(loadingSearchvisitstatus());
		EnhancedSearchService.searchVisitStatus()
			.then((res) => {
				dispatch(searchvisitstatus({ data: res.data }));
			})
			.catch((err) => {
				dispatch(searchvisitstatusError(err));
			});
	};
}

export function getActionability() {
	return (dispatch) => {
		dispatch(loadingSearchActionability());
		EnhancedSearchService.searchActionability()
			.then((res) => {
				dispatch(searchActionability({ data: res.data }));
			})
			.catch((err) => {
				dispatch(searchActionabilityError(err));
			});
	};
}

export function getEnhancedSearchLabs(data) {
	return (dispatch) => {
		dispatch(loadingAdvancedSearchlabs());
		EnhancedSearchService.searchLabs(data)
			.then((res) => {
				dispatch(advancedsearchlabs({ data: res.data }));
			})
			.catch((err) => {
				dispatch(advancedsearchlabsError(err));
			});
	};
}

export function getEnhancedSearchOrderStatus() {
	return (dispatch) => {
		dispatch(loadingSearchOrderStatus());
		EnhancedSearchService.searchOrderStatus()
			.then((res) => {
				dispatch(searchOrderStatus({ data: res.data }));
			})
			.catch((err) => {
				dispatch(searchOrderStatusError(err));
			});
	};
}

export function getSearchDispatchStatus() {
	return (dispatch) => {
		dispatch(loadingSearchDispatchStatus());
		EnhancedSearchService.searchDispatchStatus()
			.then((res) => {
				dispatch(searchDispatchStatus({ data: res.data }));
			})
			.catch((err) => {
				dispatch(searchDispatchStatusError(err));
			});
	};
}

export function getSearchRequestDecision() {
	return (dispatch) => {
		dispatch(loadingSearchRequestDecision());
		EnhancedSearchService.searchRequestDecision()
			.then((res) => {
				dispatch(searchRequestDecision({ data: res.data }));
			})
			.catch((err) => {
				dispatch(searchRequestDecisionError(err));
			});
	};
}

export function getSearchReferringProviderName() {
	return (dispatch) => {
		dispatch(loadingSearchReferringProviderName());
		EnhancedSearchService.searchReferringProviderName()
			.then((res) => {
				dispatch(searchReferringProviderName({ data: res.data }));
			})
			.catch((err) => {
				dispatch(searchReferringProviderNameError(err));
			});
	};
}

export function getSearchVisitState() {
	return (dispatch) => {
		dispatch(loadingSearchVisitState());
		EnhancedSearchService.searchVisitState()
			.then((res) => {
				dispatch(searchVisitState({ data: res.data }));
			})
			.catch((err) => {
				dispatch(searchVisitStateError(err));
			});
	};
}

export function getMultiPatientStatus(data) {
	return (dispatch) => {
		dispatch(loadingMultiPatientStatus());
		EnhancedSearchService.searchMultiPatientStatus(data)
			.then((res) => {
				dispatch(multiPatientStatus({ data: res.data }));
			})
			.catch((err) => {
				dispatch(multiPatientStatusError(err));
			});
	};
}

export function getEnhancedSearchTestType(data) {
	return (dispatch) => {
		dispatch(loadingSearchTestType());
		EnhancedSearchService.searchTestType(data)
			.then((res) => {
				dispatch(searchTestType({ data: res.data }));
			})
			.catch((err) => {
				dispatch(searchTestTypeError(err));
			});
	};
}

export function getSearchOutreachStatus() {
	return (dispatch) => {
		dispatch(loadingSearchOutreachStatus());
		EnhancedSearchService.searchOutreachStatus()
			.then((res) => {
				dispatch(searchOutreachStatus({ data: res.data }));
			})
			.catch((err) => {
				dispatch(searchOutreachStatusError(err));
			});
	};
}

export function getSearchExportReport(data) {
	return (dispatch) => {
		EnhancedSearchService.getSearchExportReport(data)
			.then((res) => {
				dispatch(searchReportExport({ data: res.data }));
			})
			.catch(() => {});
	};
}

export function resetSearchData() {
	return (dispatch) => {
		dispatch(searched_results({ data: [] }));
	};
}

const loadingAdvancedSearchPatientTags = () => {
	return {
		type: actionTypes.LOADING_ENHANCED_SEARCH_PATIENT_TAGS,
	};
};

export const advancedsearchPatientTags = (data) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_PATIENT_TAGS,
		payload: data,
	};
};

const advancedsearchPatientTagsError = (err) => {
	return {
		type: actionTypes.ENHANCED_SEARCH_PATIENT_TAGS_ERROR,
		payload: err,
	};
};

export function getEnhancedSearchPatientTag(data) {
	return (dispatch) => {
		dispatch(loadingAdvancedSearchPatientTags());
		EnhancedSearchService.searchPatientTags(data)
			.then((res) => {
				dispatch(
					advancedsearchPatientTags({ data: get(res, 'data', null) })
				);
			})
			.catch((err) => {
				dispatch(advancedsearchPatientTagsError(err));
			});
	};
}
