//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import MoreFiltersBody from './MoreFiltersBody.js';
import SingleSelectTitleRow from './SingleSelectTitleRow.js';
import SingleSelectButtons from './SingleSelectButtons.js';

class MoreFiltersModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			internalValue: {},
			changed: false,
		};
		for (const field of get(this, 'props.data', []) || []) {
			if (field.type === 'singleSelect') {
				this.state.internalValue[field.submitKey] = field.selected;
			} else if (field.type === 'checkbox') {
				this.state.internalValue[field.submitKey] = field.checked;
			}
		}
		//bindings
		this.bodyClickHandler = this.bodyClickHandler.bind(this);
		this.setInternalValue = this.setInternalValue.bind(this);
		this.resetInternalValue = this.resetInternalValue.bind(this);
		this.saveValue = this.saveValue.bind(this);
		this.canSaveValue = this.canSaveValue.bind(this);
	}
	//functions

	componentDidMount() {
		if (get(this, 'modalElement', null)) {
			document
				.querySelector('body')
				.addEventListener('click', this.bodyClickHandler, {
					capture: true,
				});
		}
	}

	componentWillUnmount() {
		if (get(this, 'modalElement', null)) {
			document
				.querySelector('body')
				.removeEventListener('click', this.bodyClickHandler);
		}
	}

	bodyClickHandler(e) {
		const currentNode = e.target;
		const pillElement = get(this, 'props.pillElement', null);
		const modalElement = get(this, 'modalElement', null);
		const dateRangePicker = document.querySelector('.el-date-range-picker');
		if (modalElement && pillElement) {
			if (
				!modalElement.contains(currentNode) &&
				!pillElement.contains(currentNode) &&
				document.querySelector('body') !== currentNode
			) {
				if (dateRangePicker && dateRangePicker.contains(currentNode)) {
					//do nothing
				} else {
					this.props.closeModal();
				}
			}
		}
	}

	setInternalValue(key, value) {
		const currentValues = get(this, 'state.internalValue', {}) || {};
		currentValues[key] = value;
		this.setState({ internalValue: currentValues, changed: true });
	}

	resetInternalValue() {
		const internalValue = {};
		const data = get(this, 'props.data', []) || [];
		if (Array.isArray(data)) {
			data.forEach((el) => {
				const key = get(el, 'submitKey', '');
				switch (get(el, 'type', null)) {
					case 'dateRangePicker':
						internalValue.dateRange = get(
							el,
							'defaultValue',
							undefined
						);
						break;
					case 'singleSelect':
						internalValue[key] = get(el, 'defaultValue', '');
						break;
					case 'checkbox':
						internalValue[key] = get(el, 'defaultValue', false);
						break;
					default:
						console.log('Unrecognized data type.');
				}
			});
			this.setState({ internalValue, changed: true });
		}
	}

	saveValue() {
		const data = get(this, 'props.data', []) || [];
		if (this.canSaveValue() && Array.isArray(data)) {
			const filters = {};
			data.forEach((el) => {
				const key = get(el, 'submitKey', '');
				switch (get(el, 'type', null)) {
					case 'dateRangePicker':
						filters['from'] = get(
							this,
							'state.internalValue.dateRange',
							[]
						)[0];
						filters['to'] = get(
							this,
							'state.internalValue.dateRange',
							[]
						)[1];
						break;
					case 'singleSelect':
						if (
							key &&
							get(this, ['state', 'internalValue', key], '') !==
								get(el, ['selected'], '')
						)
							filters[get(el, ['submitKey'], '')] = get(
								this,
								['state', 'internalValue', key],
								''
							);
						break;
					case 'checkbox':
						if (
							key &&
							get(
								this,
								['state', 'internalValue', key],
								false
							) !== get(el, ['checked'], false)
						)
							filters[get(el, ['submitKey'], '')] = get(
								this,
								['state', 'internalValue', key],
								false
							);
						break;
					default:
						console.log('Unrecognized filter type.');
						break;
				}
			});
			if (this.props.submit) this.props.submit({ ...filters });
			this.props.closeModal();
		}
	}

	canSaveValue() {
		return get(this, 'state.changed', false);
	}

	render() {
		const alternateModalClass = get(
			this,
			'props.alternateModalClass',
			null
		);
		const modalClassName = alternateModalClass
			? 'moreFiltersModalContainer ' + alternateModalClass
			: 'moreFiltersModalContainer';
		return (
			<div
				className={modalClassName}
				ref={(el) => (this.modalElement = el)}
			>
				<SingleSelectTitleRow
					title={get(this, 'props.title', '') || 'More Filters'}
					closeModal={this.props.closeModal}
				/>
				<MoreFiltersBody
					data={get(this, 'props.data', []) || []}
					internalValue={get(this, 'state.internalValue', {}) || {}}
					setInternalValue={this.setInternalValue}
				/>
				<SingleSelectButtons
					resetInternalValue={this.resetInternalValue}
					saveValue={this.saveValue}
					canSaveValue={this.canSaveValue()}
				/>
			</div>
		);
	}
}

export default MoreFiltersModal;
