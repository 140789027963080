import * as actionTypes from '../actions/actionTypes';

const partnersDefaultState = {
	loading: false,
	loaded: false,
	data: [],
	error: null,
};

export const partners = (state = { ...partnersDefaultState }, action = {}) => {
	switch (action.type) {
		case actionTypes.LOAD_PARTNERS_START:
			return {
				...state,
				loading: true,
				loaded: false,
				error: null,
			};

		case actionTypes.LOAD_PARTNERS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				data: action.payload,
				error: null,
			};

		case actionTypes.LOAD_PARTNERS_FAILURE:
			return {
				...state,
				loading: false,
				loaded: true,
				error: action.payload,
			};

		default:
			return state;
	}
};

const createUserDefaultState = {
	loading: false,
	loaded: false,
	message: '',
	error: null,
};

export const createUser = (
	state = { ...createUserDefaultState },
	action = {}
) => {
	switch (action.type) {
		case actionTypes.CREATE_USER_START:
			return {
				...state,
				loading: true,
				loaded: false,
				message: '',
				error: null,
			};

		case actionTypes.CREATE_USER_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				message: action.payload,
				error: null,
			};

		case actionTypes.CREATE_USER_FAILURE:
			return {
				...state,
				loading: false,
				loaded: true,
				message: '',
				error: action.payload,
			};

		case actionTypes.CREATE_USER_CLEAR:
			return {
				...state,
				loading: false,
				loaded: false,
				message: '',
				error: null,
			};

		default:
			return state;
	}
};

const updateUserDefaultState = {
	loading: false,
	loaded: false,
	message: '',
	error: null,
};

export const updateUser = (
	state = { ...updateUserDefaultState },
	action = {}
) => {
	switch (action.type) {
		case actionTypes.UPDATE_USER_START:
			return {
				...state,
				loading: true,
				loaded: false,
				message: '',
				error: null,
			};

		case actionTypes.UPDATE_USER_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				message: action.payload,
				error: null,
			};

		case actionTypes.UPDATE_USER_FAILURE:
			return {
				...state,
				loading: false,
				loaded: true,
				message: '',
				error: action.payload,
			};

		case actionTypes.UPDATE_USER_CLEAR:
			return {
				...state,
				loading: false,
				loaded: false,
				message: '',
				error: null,
			};

		default:
			return state;
	}
};

const userAuditTrailDefaultState = {
	loading: false,
	loaded: false,
	data: [],
	error: null,
};

export const userAuditTrail = (
	state = { ...userAuditTrailDefaultState },
	action = {}
) => {
	switch (action.type) {
		case actionTypes.GET_AUDIT_TRAIL_USER_START:
			return {
				...state,
				loading: true,
				loaded: false,
				error: null,
			};

		case actionTypes.GET_AUDIT_TRAIL_USER_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				data: action.payload,
				error: null,
			};

		case actionTypes.GET_AUDIT_TRAIL_USER_FAILURE:
			return {
				...state,
				loading: false,
				loaded: true,
				error: action.payload,
			};

		default:
			return state;
	}
};

const deleteUserDefaultState = {
	loading: false,
	loaded: false,
	message: '',
	error: null,
};

export const deleteUser = (
	state = { ...deleteUserDefaultState },
	action = {}
) => {
	switch (action.type) {
		case actionTypes.DELETE_USER_START:
			return {
				...state,
				loading: true,
				loaded: false,
				message: '',
				error: null,
			};

		case actionTypes.DELETE_USER_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				message: action.payload,
				error: null,
			};

		case actionTypes.DELETE_USER_FAILURE:
			return {
				...state,
				loading: false,
				loaded: true,
				message: '',
				error: action.payload,
			};

		default:
			return state;
	}
};
