//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

class MenuItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			right: '20px',
			opacity: 0,
		};
		//bindings
		this.activateMenuItem = this.activateMenuItem.bind(this);
	}
	//functions

	componentDidUpdate(prevProps, prevState) {
		if (
			!get(prevProps, 'expanded', false) &&
			get(this, 'props.expanded', false)
		) {
			this.setState({ right: '0px', opacity: 1 });
		}

		if (
			get(prevProps, 'expanded', false) &&
			!get(this, 'props.expanded', false)
		) {
			this.setState({ right: '20px', opacity: 0 });
		}
	}

	activateMenuItem() {
		this.props.navRedirect(
			get(this, 'props.item.path', ''),
			get(this, 'props.item.text', '')
		);
	}

	render() {
		const activeIcon = this.props.active ? ' menuItemIconActive' : '';
		const activeText = this.props.active ? ' menuItemTextActive' : '';
		return (
			<div
				className={'menuItem' + activeText}
				onClick={this.activateMenuItem}
				style={{ marginTop: this.props.subIdx > 0 ? '8px' : '0px' }}
			>
				<div className='menuItemIconContainer'>
					{this.props.renderIcon(
						get(this, 'props.item.text', ''),
						this.props.active
					)}
				</div>
				<div
					className='menuItemText'
					style={{
						right: get(this, 'state.right', '0px'),
						opacity: get(this, 'state.opacity', 0),
					}}
				>
					{this.props.expanded ? (
						<div className='expandedText'>
							{get(this, 'props.item.text', '')}
						</div>
					) : (
						''
					)}
				</div>
			</div>
		);
	}
}

export default MenuItem;
