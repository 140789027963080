import classnames from 'classnames';
import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSVG from 'react-svg';

import { Dropdown, Loading } from 'gm-element-react';

import { assignCCOrGCA, getActiveCCs } from '../../../actions/dashboard';
import dropdownIcon from '../../../assets/dropdown.svg';
import { enqueueSnackbar } from '../../../utils';

import { ReferralPageContext } from '../hooks';

import './AssignTo.scss';
import { AxiosError } from 'axios';

export function AssignTo() {
	const dispatch = useDispatch();
	const ccList = useSelector((store) => store.ccList.ccList);
	useEffect(() => {
		dispatch(getActiveCCs());
	}, []);

	const { referralDashboardData, selectedItems } =
		useContext(ReferralPageContext);

	const [loading, setLoading] = useState(false);

	const isDisabled = !selectedItems.selectedItems.length;

	return (
		<div>
			{loading && <Loading loading fullscreen />}
			<Dropdown
				menu={
					<Dropdown.Menu>
						<div className='filterDropdownDashboardList'>
							{[...ccList]
								.sort((a, b) =>
									a.display_name.localeCompare(b.display_name)
								)
								.map((item) => (
									<Dropdown.Item
										command={item.key}
										key={item.key}
									>
										{item.display_name}
									</Dropdown.Item>
								))}
							<Dropdown.Item command={null}>
								Unassigned
							</Dropdown.Item>
						</div>
					</Dropdown.Menu>
				}
				onCommand={async (uuid) => {
					try {
						setLoading(true);
						await dispatch(
							assignCCOrGCA(
								{
									assigned_cc_uuid: uuid,
									patients: selectedItems.selectedItems,
									ensure_update_index:
										'emr_referral_dashboard',
								},
								{ throwError: true }
							)
						);
						await referralDashboardData.refetch();
						enqueueSnackbar('Assignment completed', {
							variant: 'success',
						});
					} catch (error) {
						let errorMessage = error.message;
						if (error instanceof AxiosError) {
							errorMessage = error.response?.data?.data?.message;
						}
						enqueueSnackbar(
							`Error during assignment: ${errorMessage}`
						);
					} finally {
						setLoading(false);
					}
				}}
				trigger='click'
				className='filterDropdownElement filterDropdownDashboard'
				disabled={isDisabled}
			>
				<div
					className={classnames(
						'filterDropdownButton filterDropdownButtonDashboard',
						{ filterDropdownButtonDisabled: isDisabled }
					)}
				>
					Assign to
					{selectedItems.selectedItems.length ? (
						<> ({selectedItems.selectedItems.length})</>
					) : null}
					<ReactSVG
						src={dropdownIcon}
						className='filterDropdownButtonIcon'
					/>
				</div>
			</Dropdown>
		</div>
	);
}
