//React & Redux
import React, { Fragment, useState, useEffect } from 'react';

//Images
import editImage from './images/edit-list.svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import {
	CARE_PATIENTS_ENTER_TEST_RESULT,
	CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER,
} from '../../../../Utils/permissionUtils';

//UI Libraries
import { Loading } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import Table from '../../../Common/Table/Table';
import Thead from '../../../Common/Table/Thead';
import Tbody from '../../../Common/Table/Tbody';
import Th from '../../../Common/Table/Th';
import Tr from '../../../Common/Table/Tr';
import Td from '../../../Common/Table/Td';
import RBAC from '../../../RBAC';
import OrderStatusPill from '../../../Common/OrderStatusPill';

//Styles
import './css/list.css';

const DEFAULT_LIMIT = 25;

const dash = <span className='pat-dash'>--</span>;

const List = (props) => {
	const {
		editTestOrderEnums = [],
		getPatientTests,
		getProviderImage = '',
		getProviderName = '',
		getRequestStatus = '',
		loadPatientTests,
		patient = {},
		showTestOrderModal,
		tests = {},
		viewTestOrder,
	} = props;

	const [activePage, setActivePage] = useState(0);
	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('date_test_ordered');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		loadPatientTests();
	}, []);

	useEffect(() => {
		getPatientTests({
			patient_uuid: patient?.uuid || '',
			offset: activePage,
			order_type: order,
			order_by: orderBy,
			mlo: 1,
		});
	}, [order, orderBy, activePage, loading]);

	const handlePageChange = (evt, pageNumber) => {
		getPatientTests({
			patient_uuid: patient?.uuid || '',
			offset: pageNumber,
			limit: DEFAULT_LIMIT,
			order_type: order,
			order_by: orderBy,
			mlo: 1,
		});
		setActivePage(pageNumber);
	};

	const createSortHandler = (order_by, order_type) => {
		setOrder(order_type);
		setOrderBy(order_by);
		setActivePage(0);
		setLoading(false);
	};

	const _viewTestOrder = (obj) => {
		if (
			editTestOrderEnums.includes(obj.testOrderStatus) &&
			_.isEmpty(obj.testOrderResultReceivedDate)
		) {
			showTestOrderModal(obj);
		} else {
			viewTestOrder(obj.testOrderUUID);
		}
	};

	const renderTableRows = (el, index, isEnableRowClick) => {
		const obj = { ...el, index };
		return (
			<Tr
				key={'test-order-' + el.testOrderUUID}
				onClick={
					isEnableRowClick &&
					el.testOrderStatus != 'results_ready' &&
					el.testOrderStatus != 'results_entered'
						? (evt) => _viewTestOrder(obj)
						: null
				}
				style={{
					cursor:
						isEnableRowClick &&
						el.testOrderStatus != 'results_ready' &&
						el.testOrderStatus != 'results_entered'
							? 'pointer'
							: 'auto',
					position: 'relative',
				}}
				highlight={true}
			>
				<Td minWidth='120px' maxWidth='120px'>
					{el.testOrderDispatchDate
						? moment(el.testOrderDispatchDate).format(
								'MMM DD, YYYY'
						  )
						: dash}
				</Td>
				<Td minWidth='135px' maxWidth='135px'>
					{el.labName}
				</Td>
				<Td minWidth='145px' maxWidth='145px'>
					{el.testName}
				</Td>
				<Td minWidth='160px' maxWidth='160px'>
					<div className='provider'>
						<img
							className='provider-image'
							src={getProviderImage(el)}
							alt=''
						/>
						<div className='provider-name'>
							{getProviderName(el.providerName)}
						</div>
					</div>
				</Td>
				<Td minWidth='122px' maxWidth='122px'>
					<OrderStatusPill
						value={
							!_.isEmpty(el.statusReason)
								? el.statusReason
								: el.testOrderStatus
						}
						displayName={getRequestStatus(
							el.testOrderStatus,
							el.statusReason,
							false
						)}
					/>
				</Td>
				<Td
					minWidth='124px'
					maxWidth='124px'
					style={{ cursor: 'pointer' }}
				>
					{
						<RBAC
							action={CARE_PATIENTS_ENTER_TEST_RESULT}
							yes={
								el.testOrderStatus == 'results_ready' && (
									<div
										className='edit-result'
										onClick={(evt) => {
											evt.stopPropagation();
											_viewTestOrder(obj, true);
										}}
									>
										<div className='edit-result-icon'>
											<img src={editImage} />
										</div>
										<div className='edit-result-text'>
											Enter Results
										</div>
									</div>
								)
							}
						/>
					}
					{el.testOrderStatus == 'results_entered' && (
						<div
							className='edit-result'
							onClick={(evt) => {
								evt.stopPropagation();
								_viewTestOrder(obj);
							}}
						>
							<RBAC
								action={CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER}
								yes={
									<Fragment>
										<div className='edit-result-icon'>
											<img src={editImage} />
										</div>
										<div className='edit-result-text'>
											Edit Results
										</div>
									</Fragment>
								}
								no={
									<div className='edit-result-text'>
										View Results
									</div>
								}
							/>
						</div>
					)}
				</Td>
			</Tr>
		);
	};

	const pagination = {
		has_next: get(tests, 'tests.pagination.has_next', false),
		has_prev: get(tests, 'tests.pagination.has_prev', false),
		limit: DEFAULT_LIMIT,
		offset: activePage,
		total: get(tests, 'tests.pagination.total', 0),
	};
	const sortBy = {
		key: orderBy,
		value: order,
	};

	return (
		<div className='list-container'>
			{tests.gettingTests === true ? (
				<Loading loading={true} className='tests-list-loader' />
			) : (
				<Table
					loading={false}
					pagination={pagination}
					handlePageChange={handlePageChange}
					hasData={!_.isEmpty(_.get(tests, 'tests.data', []))}
					noResultsFound={
						<div className='loadingrow'>No test found</div>
					}
					className='test-doctable'
					sortBy={sortBy}
					containerStyle={{ minWidth: '100%' }}
					style={{ minWidth: '100%' }}
					handleSort={createSortHandler}
				>
					<Thead>
						<Tr>
							<Th
								sortable={{
									date_test_ordered: {
										display_name: 'Dispatch date',
										sortBy: {
											asc: 'Oldest First',
											desc: 'Newest First',
										},
									},
								}}
							>
								Dispatch date
							</Th>
							<Th
								sortable={{
									lab: {
										display_name: 'Lab',
										sortBy: {
											asc: 'A to Z',
											desc: 'Z to A',
										},
									},
								}}
							>
								Lab
							</Th>
							<Th
								sortable={{
									genetic_test_display_name: {
										display_name: 'Test Name',
										sortBy: {
											asc: 'A to Z',
											desc: 'Z to A',
										},
									},
								}}
							>
								Test name
							</Th>
							<Th
								sortable={{
									visit_provider: {
										display_name: 'Provider',
										sortBy: {
											asc: 'A to Z',
											desc: 'Z to A',
										},
									},
								}}
							>
								Provider
							</Th>
							<Th>Order status</Th>
							<Th>&nbsp;</Th>
						</Tr>
					</Thead>
					<Tbody>
						{get(tests, 'tests.data', []).map((el, index) => {
							return (
								<RBAC
									action={
										CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
									}
									yes={renderTableRows(el, index, true)}
									no={renderTableRows(el, index, false)}
								/>
							);
						})}
					</Tbody>
				</Table>
			)}
		</div>
	);
};

export default List;
