//React & Redux
import React, { Component, Fragment } from 'react';

//Images
import actionRequired from '../../../assets/action-required.png';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Layout } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

const ViewInsuranceBillingSegment = (props) => {
	const {
		encounterBillingDetail,
		encounterBillingDetail: {
			athena_claim_id: athenaClaimId = '--',
			insurance_claim_date: insuranceClaimDate = '',
			insurance_claim_status: insuranceClaimStatus = '-',
			insurance_expected_revenue: insuranceExpectedRevenue = '',
		},
	} = props;

	const displayvalue = (key) => {
		let value = '--';
		const propsValue = encounterBillingDetail[key] || '';
		if (!_.isNil(propsValue) && !_.isEmpty(propsValue)) {
			if (propsValue == 'na') {
				value = 'N/A';
			} else value = propsValue;
		}
		if (value === 'self_pay') {
			value = 'Non-insurance';
		}
		value = value.replace(/_/g, ' ');
		return value;
	};

	const displayClaimDate = () =>
		insuranceClaimDate &&
		insuranceClaimDate != null &&
		_.get(insuranceClaimDate, 'length', 0) > 0
			? moment(insuranceClaimDate).format('MM/DD/YYYY')
			: '--';

	const displayExpectedRevenue = () =>
		!_.isNil(insuranceExpectedRevenue)
			? '$' + insuranceExpectedRevenue
			: '--';

	return (
		<>
			<Layout.Row gutter='20'>
				<Layout.Col span='24'>
					<div className='test-number-container'>
						<div className='test-number'>Insurance Billing</div>
						<div className='divider'></div>
					</div>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20'>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Payor</span>
					<span className='val-enctr-sgmnt-field'>
						{displayvalue('payor')}
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>
						Claim Status
						{insuranceClaimStatus == 'not_submitted' && (
							<img
								className='action-required'
								src={actionRequired}
								alt=''
							/>
						)}
					</span>
					<span className='val-enctr-sgmnt-field'>
						{displayvalue('insurance_claim_status')}
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Claim Date</span>
					<span className='val-enctr-sgmnt-field'>
						{displayClaimDate()}
					</span>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20'>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Expected Revenue</span>
					<span className='val-enctr-sgmnt-field'>
						{displayExpectedRevenue()}
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Athena Claim ID</span>
					<span className='val-enctr-sgmnt-field'>
						{athenaClaimId}
					</span>
				</Layout.Col>
			</Layout.Row>
		</>
	);
};

export default ViewInsuranceBillingSegment;
