//React & Redux
import React, { useState, useEffect } from 'react';

//Images
import ReactSVG from 'react-svg';
import whiteEditIcon from './../../../../../assets/edit.svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import {
	encounterFieldDisplay,
	getEncounterSpecificTime,
	createEnumsOptions,
	isValidDateStringOREmpty,
	getMomentDateOrStringNonStrick,
	GetSelectvalue,
	isPartnerInitiatedUnsolicitedResult,
} from '../Common/utilsfunctions';

//UI Libraries
import { Button, Loading } from 'gm-element-react';

//Components
import { EncounterInputField } from '../Common/EncounterInputField';
import { EncounterSelectField } from '../Common/EncounterSelectField';
import { EncounterDateField } from '../Common/EncounterDateField';
import { EncounterMultiSelectField } from '../Common/EncounterMultiSelectField';
import { EncounterNumberInputField } from '../Common/EncounterNumberInputField';
import ChargeModal from '../../../ChargeInformation/ChargeModal.js';
import ICDTag from '../../../ChargeInformation/ICDTag.js';

const datefields = {
	followup_cap_completed_date: 1,
	initial_cap_completed_date: 1,
};

const EditVisitDetailSegment = (props) => {
	const {
		encounter,
		billingencounter,
		cancel,
		getICDCodes,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		enqueueSnackbar,
		saveSCPEncounter,
		visit_providerenums,
		visitsschema,
		saveSCPBillingEncounter,
	} = props;

	const tempFormData = {
		scheduling_poc: _.get(encounter, ['scheduling_poc'], ''),
		relationship_to_patient: _.get(
			encounter,
			['relationship_to_patient'],
			''
		),
		records_requested: _.get(encounter, ['records_requested'], ''),
		followup_cap_completed_date: _.get(
			encounter,
			['followup_cap_completed_date'],
			''
		),
		initial_cap_completed_date: _.get(
			encounter,
			['initial_cap_completed_date'],
			''
		),
		records_received: _.get(encounter, ['records_received'], ''),
	};
	const tempBillingFormData = {
		direct_cc_minutes: _.get(billingencounter, ['direct_cc_minutes'], null),
		indirect_cc_minutes: _.get(
			billingencounter,
			['indirect_cc_minutes'],
			null
		),
		post_session_indirect_cc_minutes: _.get(
			billingencounter,
			['post_session_indirect_cc_minutes'],
			null
		),
		post_negative_minutes: get(
			billingencounter,
			['post_negative_minutes'],
			''
		),
	};
	/* some time we are getting date and time in datetime fields so we are converting it */
	const updatedformdata = _.mapValues(tempFormData, function (value, key) {
		if (key in datefields) return getMomentDateOrStringNonStrick(value);
		return value;
	});

	const [formData, setFormData] = useState({});
	const [billingData, setBillingData] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [showChargeModal, setShowChargeModal] = useState(false);
	const [chargeModalEmptyAllowed, setChargeModalEmptyAllowed] =
		useState(false);
	const [selectedICDCodes, setSelectedICDCodes] = useState(null);

	useEffect(() => {
		setFormData(updatedformdata);
		setBillingData(tempBillingFormData);
	}, []);

	const validateForm = (formdata) => {
		let errors = {};
		const datetimeerors = _.omitBy(
			_.mapValues(datefields, function (value, key) {
				if (!isValidDateStringOREmpty(_.get(formdata, key, ''))) {
					return 'Invalid date';
				}
				return undefined;
			}),
			_.isUndefined
		);
		errors = { ...datetimeerors };
		return errors;
	};
	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		let stateErrors = {};
		stateData[key] = value;
		if (!(key in datefields)) {
			const _stateData = { ...stateData };
			stateErrors = validateForm(_stateData);
		}
		setErrors(stateErrors);
		setFormData(stateData);
	};

	const onBillingFormChange = (key, value) => {
		const stateData = { ...billingData };
		let stateErrors = {};
		stateData[key] = value;
		if (!(key in datefields)) {
			const _stateData = { ...stateData, ...formData };
			stateErrors = validateForm(_stateData);
		}
		setErrors(stateErrors);
		setBillingData(stateData);
	};

	const handleDateFieldValidate = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		const errors = validateForm(stateData);
		setErrors(errors);
		setFormData(stateData);
	};

	const renderICDCodes = () => {
		let medicalCodes =
			selectedICDCodes || get(encounter, 'medical_codes', []) || [];
		medicalCodes = medicalCodes.slice().sort((a, b) => {
			if (get(a, 'code_name', '') < get(b, 'code_name', '')) return -1;
			else return 1;
		});
		if (medicalCodes.length > 0) {
			return medicalCodes.map((el, i) => {
				const button =
					i === medicalCodes.length - 1 ? (
						<div
							className='icdEditIcon'
							onClick={visibleChargeModalEmptyAllowed}
						>
							<ReactSVG
								src={whiteEditIcon}
								className='icdEditIconSVG'
							/>
							<span
								style={{ position: 'relative', right: '7px' }}
							>
								Edit
							</span>
						</div>
					) : (
						''
					);
				return (
					<React.Fragment>
						<ICDTag
							icdCode={el}
							toggleCodeSelection={() => {}}
							hasX={false}
						/>
						{button}
					</React.Fragment>
				);
			});
		} else {
			return (
				<div className='transparentBtn' onClick={visibleChargeModal}>
					Add ICD-10 code(s)
				</div>
			);
		}
	};
	const visibleChargeModal = () => {
		setShowChargeModal(true);
		setChargeModalEmptyAllowed(false);
	};

	const visibleChargeModalEmptyAllowed = () => {
		setShowChargeModal(true);
		setChargeModalEmptyAllowed(true);
	};

	const hideChargeModal = () => {
		setShowChargeModal(false);
		setChargeModalEmptyAllowed(false);
	};

	const renderChargeModal = () => {
		return (
			<ChargeModal
				hideChargeModal={hideChargeModal}
				title={
					chargeModalEmptyAllowed
						? 'Edit ICD-10 Code(s)'
						: 'Add ICD-10 Code(s)'
				}
				getICDCodes={getICDCodes}
				icdCodes={icdCodes}
				icdCodesLoading={icdCodesLoading}
				icdCodesError={icdCodesError}
				icdCodesPlacement={icdCodesPlacement}
				enqueueSnackbar={enqueueSnackbar}
				selectedICDCodes={
					selectedICDCodes || get(encounter, 'medical_codes', null)
				}
				encounterUuid={get(encounter, 'uuid', null)}
				emptyAllowed={chargeModalEmptyAllowed}
				encounterEditLoading={null}
				encounterEditError={null}
				editEncounter={null}
				willUseBilling={false}
				singleStage={true}
				billingEncounter={null}
				editBillingEncounter={null}
				billingEncounterEditLoading={null}
				billingEncounterEditError={null}
				getAssociatedBillingEncounter={null}
				associatedBillingEncounter={null}
				associatedBillingEncounterLoading={null}
				associatedBillingEncounterError={null}
				closeChargeModalAndDispatch={closeChargeModalAndDispatch}
			/>
		);
	};
	const closeChargeModalAndDispatch = (selectedCodes) => {
		if (
			get(selectedCodes, 'length', 0) === 0 &&
			!Array.isArray(selectedCodes)
		)
			return;
		const medicalCodes = formatSelectedCodes(selectedCodes);
		setSelectedICDCodes(medicalCodes);
		setShowChargeModal(false);
		setChargeModalEmptyAllowed(false);
		onFormChange('medical_codes', medicalCodes);
	};

	const formatSelectedCodes = (selectedCodes) => {
		const medicalCodes = [];
		selectedCodes.forEach((el) => {
			if (get(el, 'uuid', '') && get(el, 'code_name', '')) {
				medicalCodes.push({
					code_name: get(el, 'code_name', null),
					uuid: get(el, 'uuid', null),
				});
			}
		});
		return medicalCodes;
	};

	const saveForm = () => {
		const errors = validateForm(formData);
		if (_.isEmpty(errors)) {
			const updated_billingencounter_formdata = _.mapValues(
				billingData,
				function (value, key) {
					if (value == 'empty') return '';
					if (
						(key in
							{
								direct_cc_minutes: 1,
								indirect_cc_minutes: 1,
								units: 1,
							} &&
							value === '') ||
						value == undefined
					) {
						return null;
					}
					if (
						key == 'post_negative_minutes' &&
						(_.isNil(value) || value === '')
					) {
						return null;
					}
					return value;
				}
			);

			const updated_encounter_data = _.mapValues(
				formData,
				function (value, key) {
					if (key in datefields && _.isNil(value)) {
						return '';
					}
					if (value == 'empty') return '';
					return value;
				}
			);

			setLoading(true);
			saveSCPBillingEncounter(
				billingencounter,
				updated_billingencounter_formdata,
				updated_encounter_data
			)
				.then(() => {
					setTimeout(() => {
						saveSCPEncounter(
							encounter,
							updated_encounter_data
						).then(() => {
							setTimeout(() => {
								setLoading(false);
								cancel();
							}, 1000);
						});
					}, 1000);
				})
				.catch(() => {
					setLoading(false);
				});
		} else {
			setErrors(errors);
		}
	};

	return (
		<Loading loading={loading}>
			{showChargeModal && (
				<div className='popupModal'>{renderChargeModal()}</div>
			)}
			<div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Scheduling POC
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Visit State
							</div>
						</div>

						<div className='segment-col'>
							<div className='encounter-field-header'>
								Visit Timezone
							</div>
						</div>

						<div className='segment-col'>
							<div className='encounter-field-header'>
								Relationship To Patient
							</div>
						</div>

						<div className='segment-col'>
							<div className='encounter-field-header'>
								Date & Time Of Service
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={_.get(
										formData,
										['scheduling_poc'],
										''
									)}
									name='scheduling_poc'
									onChange={(value) =>
										onFormChange('scheduling_poc', value)
									}
									placeholder='John Doe'
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={_.get(
										encounter,
										['state_of_visit'],
										''
									)}
									name='state_of_visit'
									disabled={true}
								/>
							</div>
						</div>

						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={_.get(encounter, ['timezone'], '--')}
									name='timezone'
									disabled={true}
								/>
							</div>
						</div>

						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['relationship_to_patient'],
										''
									)}
									name='relationship_to_patient'
									options={createEnumsOptions(
										visitsschema,
										['relationship_to_patient'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'relationship_to_patient',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={getEncounterSpecificTime(
										_.get(
											encounter,
											['date_of_service'],
											''
										),
										_.get(encounter, ['timezone'], '')
									)}
									name='date_of_service'
									disabled={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								TM Platform
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Consultation Type
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Specialty
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Visit Provider
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Visit Status
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										encounter,
										['tm_platform'],
										''
									)}
									name='tm_platform'
									disabled={true}
									options={createEnumsOptions(
										visitsschema,
										['tm_platform'],
										'key',
										'display_name',
										'key'
									)}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										encounter,
										['consultation_type'],
										''
									)}
									name='consultation_type'
									disabled={true}
									options={[
										{
											key: _.get(
												encounter,
												['consultation_type'],
												''
											),
											label: _.get(
												encounter,
												['consultation_type'],
												''
											),
											value: _.get(
												encounter,
												['consultation_type'],
												''
											),
										},
									]}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={encounterFieldDisplay(encounter, [
										'vsee_specialty',
									])}
									name='vsee_specialty'
									disabled={true}
									options={[
										{
											key: encounterFieldDisplay(
												encounter,
												['vsee_specialty']
											),
											label: encounterFieldDisplay(
												encounter,
												['vsee_specialty']
											),
											value: encounterFieldDisplay(
												encounter,
												['vsee_specialty']
											),
										},
									]}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={GetSelectvalue(
										encounter,
										['visit_provider'],
										visit_providerenums
									)}
									name='visit_provider'
									disabled={true}
									options={[
										{
											key: GetSelectvalue(
												encounter,
												['visit_provider'],
												visit_providerenums
											),
											label: GetSelectvalue(
												encounter,
												['visit_provider'],
												visit_providerenums
											),
											value: GetSelectvalue(
												encounter,
												['visit_provider'],
												visit_providerenums
											),
										},
									]}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={encounterFieldDisplay(encounter, [
										'visit_status',
									])}
									name='visit_status'
									disabled={true}
									options={[
										{
											key: encounterFieldDisplay(
												encounter,
												['visit_status']
											),
											label: encounterFieldDisplay(
												encounter,
												['visit_status']
											),
											value: encounterFieldDisplay(
												encounter,
												['visit_status']
											),
										},
									]}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Records Requested
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Records Received
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Referring Provider (RP)
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								RP Contact Info
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								RP Recommended Test
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['records_requested'],
										''
									)}
									name='records_requested'
									options={createEnumsOptions(
										visitsschema,
										['records_requested'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('records_requested', value)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['records_received'],
										''
									)}
									name='records_received'
									options={createEnumsOptions(
										visitsschema,
										['records_received'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('records_received', value)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={encounterFieldDisplay(encounter, [
										'referring_provider_name',
									])}
									name='referring_provider_name'
									disabled={true}
									placeholder='Paul Buelieu'
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={encounterFieldDisplay(encounter, [
										'referring_provider_fax_email',
									])}
									name='referring_provider_fax_email'
									disabled={true}
									placeholder='email@email.com'
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={encounterFieldDisplay(encounter, [
										'requested_test_via_provider',
									])}
									name='requested_test_via_provider'
									disabled={true}
									placeholder='Test 1'
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								ICD-10 Code(s) (Visit)
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'row',
								flexWrap: 'wrap',
							}}
						>
							{renderICDCodes()}
						</div>
					</div>
				</div>
				{!isPartnerInitiatedUnsolicitedResult(encounter) && (
					<div className='segment-row'>
						<div className='segment-label-row'>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Visit CAP Completed Date
								</div>
							</div>
							<div className='segment-2cols'>
								<div className='encounter-field-header'>
									Visit CAP Sent To Patient On
								</div>
							</div>
							<div className='segment-2cols'>
								<div className='encounter-field-header'>
									Visit CAP Sent To PCP On
								</div>
							</div>
						</div>
						<div className='segment-value-row'>
							<div className='segment-col'>
								<div className='encounter-input-section '>
									<EncounterDateField
										value={_.get(formData, [
											'initial_cap_completed_date',
										])}
										name='initial_cap_completed_date'
										placeholder='Date'
										error={_.get(
											errors,
											['initial_cap_completed_date'],
											''
										)}
										onChange={(value) =>
											onFormChange(
												'initial_cap_completed_date',
												value
											)
										}
										onBlur={(value) =>
											handleDateFieldValidate(
												'initial_cap_completed_date',
												value
											)
										}
									/>
								</div>
							</div>
							<div className='segment-2cols'>
								<div className='encounter-multi-cols-section'>
									<div className='encounter-input-section multi-first-col'>
										<EncounterDateField
											value={_.get(
												encounter,
												['initial_visit_summary_sent'],
												''
											)}
											name='initial_visit_summary_sent'
											placeholder='Date'
											disabled={true}
										/>
									</div>
									<div className='via_seprator'> via </div>

									<div className='encounter-input-section multi-two-col'>
										<EncounterMultiSelectField
											value={_.get(
												encounter,
												[
													'visit_summary_release_method_patient',
												],
												[]
											)}
											name='visit_summary_release_method_patient'
											options={createEnumsOptions(
												visitsschema,
												[
													'visit_summary_release_method_patient',
												],
												'key',
												'display_name',
												'key'
											)}
											disabled={true}
										/>
									</div>
								</div>
							</div>
							<div className='segment-2cols'>
								<div className='encounter-multi-cols-section'>
									<div className='encounter-input-section multi-first-col relative-section'>
										<EncounterDateField
											value={_.get(
												encounter,
												['visit_summary_release_date_pcp'],
												''
											)}
											name='visit_summary_release_date_pcp'
											placeholder='Date'
											disabled={true}
										/>
									</div>
									<div className='via_seprator'> via </div>
									<div className='encounter-input-section multi-two-col'>
										<EncounterMultiSelectField
											value={_.get(
												encounter,
												[
													'visit_summary_release_method_pcp',
												],
												[]
											)}
											name='visit_summary_release_method_pcp'
											options={createEnumsOptions(
												visitsschema,
												[
													'visit_summary_release_method_pcp',
												],
												'key',
												'display_name',
												'key'
											)}
											disabled={true}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{isPartnerInitiatedUnsolicitedResult(encounter) && (
					<div className='segment-row'>
						<div className='segment-label-row'>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Direct Clinical Care Time (minutes)
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Pre-Session Indirect Time (minutes)
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Post-Session Indirect Time (minutes)
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Post-Test Result CAP Time Spent (minutes)
								</div>
							</div>
						</div>
						<div className='segment-value-row'>
							<div className='segment-col'>
								<div className='encounter-input-section '>
									<EncounterNumberInputField
										defaultValue={_.get(
											billingData,
											['direct_cc_minutes'],
											null
										)}
										value={_.get(
											billingData,
											['direct_cc_minutes'],
											null
										)}
										name='direct_cc_minutes'
										onChange={(value) =>
											onBillingFormChange(
												'direct_cc_minutes',
												value
											)
										}
										error={_.get(
											errors,
											['direct_cc_minutes'],
											''
										)}
									/>
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterNumberInputField
										defaultValue={_.get(
											billingData,
											['indirect_cc_minutes'],
											null
										)}
										value={_.get(
											billingData,
											['indirect_cc_minutes'],
											null
										)}
										name='indirect_cc_minutes'
										onChange={(value) =>
											onBillingFormChange(
												'indirect_cc_minutes',
												value
											)
										}
										error={_.get(
											errors,
											['indirect_cc_minutes'],
											''
										)}
									/>
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterNumberInputField
										defaultValue={_.get(
											billingData,
											[
												'post_session_indirect_cc_minutes',
											],
											null
										)}
										value={_.get(
											billingData,
											[
												'post_session_indirect_cc_minutes',
											],
											null
										)}
										name='post_session_indirect_cc_minutes'
										onChange={(value) =>
											onBillingFormChange(
												'post_session_indirect_cc_minutes',
												value
											)
										}
										error={_.get(
											errors,
											[
												'post_session_indirect_cc_minutes',
											],
											''
										)}
									/>
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterNumberInputField
										defaultValue={_.get(
											billingData,
											['post_negative_minutes'],
											null
										)}
										value={_.get(
											billingData,
											['post_negative_minutes'],
											null
										)}
										name='post_negative_minutes'
										onChange={(value) =>
											onBillingFormChange(
												'post_negative_minutes',
												value
											)
										}
										error={_.get(
											errors,
											['post_negative_minutes'],
											''
										)}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Result CAP Completed Date
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-field-header'>
								Result CAP Sent To Patient On
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-field-header'>
								Result CAP Sent To PCP On
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(formData, [
										'followup_cap_completed_date',
									])}
									name='followup_cap_completed_date'
									placeholder='Date'
									error={_.get(
										errors,
										['followup_cap_completed_date'],
										''
									)}
									onChange={(value) =>
										onFormChange(
											'followup_cap_completed_date',
											value
										)
									}
									onBlur={(value) =>
										handleDateFieldValidate(
											'followup_cap_completed_date',
											value
										)
									}
								/>
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-multi-cols-section'>
								<div className='encounter-input-section multi-first-col'>
									<EncounterDateField
										value={_.get(
											encounter,
											['cap_sent_to_patient'],
											''
										)}
										name='cap_sent_to_patient'
										placeholder='Date'
										disabled={true}
									/>
								</div>
								<div className='via_seprator'> via </div>
								<div className='encounter-input-section multi-two-col'>
									<EncounterMultiSelectField
										value={_.get(
											encounter,
											['cap_release_method_patient'],
											[]
										)}
										name='cap_release_method_patient'
										options={createEnumsOptions(
											visitsschema,
											['cap_release_method_patient'],
											'key',
											'display_name',
											'key'
										)}
										disabled={true}
									/>
								</div>
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-multi-cols-section'>
								<div className='encounter-input-section multi-first-col'>
									<EncounterDateField
										value={_.get(
											encounter,
											['cap_sent_date_pcp'],
											''
										)}
										name='cap_sent_date_pcp'
										placeholder='Date'
										disabled={true}
									/>
								</div>
								<div className='via_seprator'> via </div>
								<div className='encounter-input-section multi-two-col'>
									<EncounterMultiSelectField
										value={_.get(
											encounter,
											['cap_release_method_pcp'],
											[]
										)}
										name='cap_release_method_pcp'
										options={createEnumsOptions(
											visitsschema,
											['cap_release_method_pcp'],
											'key',
											'display_name',
											'key'
										)}
										disabled={true}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-footer-container'>
					<div className='cancel-btn-link'>
						<Button onClick={() => cancel()}>Cancel</Button>
					</div>
					<div className='save-btn-link'>
						<Button style={{ float: 'right' }} onClick={saveForm}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</Loading>
	);
};

export default EditVisitDetailSegment;
