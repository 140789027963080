//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import { numberFormat } from './../../../Utils/dashboardUtils';

//Styles
import './encounterscores.css';

const EncounterScores = ({ PatientTotals }) => {
	return (
		<Fragment>
			<div className='headerow'>
				<h3>All Time</h3>
				<h5>(To date)</h5>
			</div>
			<div className='scrorerow refferalrow'>
				<label className='lbltitle'>Total Referrals</label>
				<label className='lblscore'>
					{numberFormat(_.get(PatientTotals, ['total_referrals']))}
				</label>
			</div>
			<div className='scrorerow totalrow'>
				<label className='lbltitle'>Total Completed Encounters</label>
				<label className='lblscore'>
					{numberFormat(
						_.get(PatientTotals, ['total_completed_encounters'])
					)}
				</label>
			</div>
			<div className='scrorerow totalcounts'>
				<div className='countrow getstarted'>
					<label className='lbltitle'>
						Total Get Started w/o Results
					</label>
					<label className='lblscore'>
						{numberFormat(
							_.get(PatientTotals, [
								'total_get_started_with_no_results',
							])
						)}
					</label>
				</div>
				<div className='countrow ror'>
					<label className='lbltitle'>Total Return of Results</label>
					<label className='lblscore'>
						{numberFormat(
							_.get(PatientTotals, ['total_get_started_with_ror'])
						)}
					</label>
				</div>
				<div className='countrow other'>
					<label className='lbltitle'>Total Other</label>
					<label className='lblscore'>
						{numberFormat(_.get(PatientTotals, ['total_others']))}
					</label>
				</div>
			</div>
		</Fragment>
	);
};

export default EncounterScores;
