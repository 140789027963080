//React & Redux
import React from 'react';
import { Redirect } from 'react-router-dom';

//Lodash
import _ from 'lodash';

//Utils
import { DocumentTabIndex } from '../../utils';

const RedirectRoute = (props) => {
	const actionname =
		_.get(props, ['match', 'params', 'actionname']) ||
		_.get(props, ['actionname']);
	const toPath = _.get(props, ['toPath']) || '/app';
	switch (actionname) {
		case 'view':
			return (
				<Redirect
					to={`/app/patientdetail/${_.get(props, [
						'match',
						'params',
						'param1',
					])}/6/${DocumentTabIndex.VIEWDETAIL}/${_.get(props, [
						'match',
						'params',
						'param2',
					])}`}
				/>
			);
		case 'appredirect':
			return <Redirect to={toPath} />;
		default:
			return <Redirect to='/app' />;
	}
};
export default RedirectRoute;
