import { gql } from '@apollo/client';

import { client } from './GraphQLConnection';
import { isNil } from 'lodash';

class DiscreteVariant {
	getReferenceAssembliesEnum = () => {
		return client.query({
			query: gql`
				query {
					referenceAssemblies {
						code
						description
						name
					}
				}
			`,
		});
	};

	getGeneticResultGenesSymbolEnum = (data = {}) => {
		const _variantType = data?.variantType || 'discrete';

		return client.query({
			query: gql`
				query {
					genes(symbolQuery: "${data?.query || ''}", matchAliasSymbols:true) {
						id
						symbol
						name
						aliasSymbols
						cytogeneticLocation
						${
							_variantType === 'structuralVariant'
								? ''
								: `suggestedTranscriptReferenceSequence(referenceAssemblyCode: "${data?.referenceAssembly}", labId: "${data?.labId}")`
						}
					}
				}
			`,
		});
	};

	createDiagnosticReport = (patientID, testOrderID) => {
		return client.mutate({
			mutation: gql`
				mutation {
					addDiagnosticReport(
						input: {
							subjectId: "${patientID}"
							testOrderId: "${testOrderID}"
							status: FINAL
						}
					) {
						diagnosticReport {
							id
						}
					}
				}
			`,
		});
	};

	addDiscreteVariantFinding = (data, diagnosticReportId) => {
		const _hgvsNomenclature = !isNil(data?.hgvsNomenclature?.proteinChange)
			? `hgvsNomenclature: {
										dnaChange: "${data.hgvsNomenclature.dnaChange}"        
										transcriptReferenceSequence: "${data.hgvsNomenclature.transcriptReferenceSequence}"
										proteinChange: "${data.hgvsNomenclature.proteinChange}"
									}`
			: `hgvsNomenclature: {
										dnaChange: "${data.hgvsNomenclature.dnaChange}"        
										transcriptReferenceSequence: "${data.hgvsNomenclature.transcriptReferenceSequence}"                                        
									}`;

		return client.mutate({
			mutation: gql`
				mutation {
					addDiagnosticFinding(
						input: {
							diagnosticReportId: "${diagnosticReportId}" 
							finding: {
								discreteVariantFinding: {
									allelicState: ${data.allelicState}
									referenceAssemblyCode: "${data.referenceAssemblyCode}"
									${
										data.cytogenomicNomenclature
											? `cytogenomicNomenclature: "${data.cytogenomicNomenclature}"`
											: _hgvsNomenclature
									}
									geneId: "${data.geneId}"
									originalReportedClassification: ${
										data.originalReportedClassification
									}                                                                     
									genomicSourceClass: ${data.genomicSourceClass}
									origin: ${data.origin}
									${data.mosaicism ? `mosaicism: ${data.mosaicism}` : ''} 
									${
										data.proteinChangeType
											? `proteinChangeType: ${data.proteinChangeType}`
											: ''
									}  
									${data.allelicFrequency ? `allelicFrequency: "${data.allelicFrequency}"` : ''}
									${
										data.dnaChangeType
											? `dnaChangeType: ${data.dnaChangeType}`
											: ''
									}                                      
									assessment: ${data.assessment}
									externalVariantIds: []                                  
								}
							}
						}
					) {
						finding {
							... on DiscreteVariantFinding {
								id
								assessment
								notes {
									id
									enteredAt
									text
								}
								referenceAssembly {
									code
									name
									description
								}
								origin
								mosaicism
								hgvsNomenclature {
									transcriptReferenceSequence
									dnaChange
									genomicDNAChange
									proteinChange
									proteinReferenceSequence
									genomicReferenceSequence
								}
								cytogenomicNomenclature
								dnaChangeType
								proteinChangeType
								dbsnpId
								gene {
									id
									symbol
									name
									aliasSymbols
								}
								allelicFrequency
								assessment
								allelicReadDepth
								allelicState                                
								externalVariantIds
								interpretations {
									interpretedOn
									classification
									source {
										id
										name
									}
								}
								mostRecentInterpretation {
									interpretedOn
									source {
										id
										name
									}
									classification
								}
								originalReportedInterpretation {
									classification
									interpretedOn
									source {
										id
										name
									}
								}
							}
						}
					}
				}	
			`,
		});
	};

	updateDiscreteVariantFinding = (data = {}, findingId = null) => {
		return client.mutate({
			mutation: gql`
				mutation {
					updateDiagnosticFinding(
						input: {
							discreteVariantFinding: {
								id: "${findingId}"
								geneId: "${data.geneId}"
								referenceAssemblyCode: "${data.referenceAssemblyCode}"
								${
									data.cytogenomicNomenclature
										? `cytogenomicNomenclature: "${data.cytogenomicNomenclature}"`
										: `hgvsNomenclature: {
										dnaChange: "${data.hgvsNomenclature.dnaChange}"
										transcriptReferenceSequence: "${
											data.hgvsNomenclature
												.transcriptReferenceSequence
										}"
										${
											data.hgvsNomenclature.proteinChange
												? `proteinChange: "${data.hgvsNomenclature.proteinChange}"`
												: ''
										} 
									}`
								}                                
								allelicState: ${data.allelicState}                               
								originalReportedClassification: ${data.originalReportedClassification}
								genomicSourceClass: ${data.genomicSourceClass}
								origin: ${data.origin}
								${
									data.mosaicism
										? `mosaicism: ${data.mosaicism}`
										: ''
								}                                     
								${
									data.proteinChangeType
										? `proteinChangeType: ${data.proteinChangeType}`
										: ''
								}  
								${data.allelicFrequency ? `allelicFrequency: "${data.allelicFrequency}"` : ''}
								${
									data.dnaChangeType
										? `dnaChangeType: ${data.dnaChangeType}`
										: ''
								}                                                                                                 
								assessment: ${data.assessment}                                
								externalVariantIds: []
							}
						}
					) {
						finding {
							... on DiscreteVariantFinding {
								id
								assessment
								notes {
								  id
								  enteredAt
								  text
								}
								referenceAssembly {
								  code
								  name
								  description
								}
								origin
								hgvsNomenclature {
								  transcriptReferenceSequence
								  dnaChange
								  genomicDNAChange
								  proteinChange
								  proteinReferenceSequence
								  genomicReferenceSequence
								}
								cytogenomicNomenclature
								dnaChangeType
								proteinChangeType
								dbsnpId
								gene {
								  id
								  symbol
								  name
								  aliasSymbols
								}
								allelicFrequency
								assessment
								allelicReadDepth
								allelicState
								externalVariantIds
								interpretations {
								  interpretedOn
								  classification
								  source {
									id
									name
								  }
								}
								mostRecentInterpretation {
								  interpretedOn
								  source {
									id
									name
								  }
								  classification
								}
								originalReportedInterpretation {
								  classification
								  interpretedOn
								  source {
									id
									name
								  }
								}
							}
						}
					}
				} 
			`,
		});
	};

	getDiagnosticFindingValidation = (data) => {
		return client.query({
			query: gql`
				query {
					diagnosticFindingValidation(
						finding: {
						  discreteVariantFinding: {
							assessment: ${data.assessment}
							${data.origin ? `origin: ${data.origin}` : `origin: UNKNOWN`}
							referenceAssemblyCode: "${
								data.referenceAssemblyCode
							}"                            
							geneId: "${data.geneId}"
							${data.dnaChangeType ? `dnaChangeType: ${data.dnaChangeType}` : ''}
							${data.proteinChangeType ? `proteinChangeType: ${data.proteinChangeType}` : ''}
							${
								data.cytogenomicNomenclature
									? `cytogenomicNomenclature: "${data.cytogenomicNomenclature}"`
									: `hgvsNomenclature: {
									${
										data.hgvsNomenclature.dnaChange
											? `dnaChange: "${data.hgvsNomenclature.dnaChange}"`
											: ''
									}
									${
										data.hgvsNomenclature
											.transcriptReferenceSequence
											? `transcriptReferenceSequence: "${data.hgvsNomenclature.transcriptReferenceSequence}"`
											: ''
									}                                
									${
										data.hgvsNomenclature.proteinChange
											? `proteinChange: "${data.hgvsNomenclature.proteinChange}"`
											: ''
									}
									${
										data.proteinReferenceSequence
											? `proteinReferenceSequence: "${data.proteinReferenceSequence}"`
											: ''
									}
									${data.genomicDNAChange ? `genomicDNAChange: "${data.genomicDNAChange}"` : ''}
									${
										data.genomicReferenceSequence
											? `genomicReferenceSequence: "${data.genomicReferenceSequence}"`
											: ''
									}                                
								}`
							}                              
							externalVariantIds: ["CLINVAR:9538", "ENSEMBL:ENST00000229768.9"]                                                        
							${
								data.genomicSourceClass
									? `genomicSourceClass: ${data.genomicSourceClass}`
									: `genomicSourceClass: UNKNOWN`
							}                                                                                    
							allelicState: ${data.allelicState}
							${
								data.allelicFrequency
									? `allelicFrequency: "${data.allelicFrequency}"`
									: ''
							}                                                      
							originalReportedClassification: ${data.originalReportedClassification}
						  }
						}
					) {
						isValid
						issues {
						  severity
						  message
						  detailMessage
						  paths
						}
					}                                      
				}
			`,
		});
	};
}

export default new DiscreteVariant();
