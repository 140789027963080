//React & Redux
import React, { useState, useEffect } from 'react';

//Images
import ReactSVG from 'react-svg';
import whiteEditIcon from './../../../../../assets/edit.svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import {
	encounterFieldDisplay,
	getEncounterSpecificTime,
	createEnumsOptions,
	isValidDateStringOREmpty,
	getMomentDateOrStringNonStrick,
	GetSelectvalue,
	visitIndicationsFilterEnum,
	visitIndicationsCheckboxEnum,
	getVisitIndicationTagDisplay,
	addVisitIndicationDisplayNames,
	determineIsAfterIndicationsGoLive,
	isAppointmentStarted
} from '../Common/utilsfunctions';

import { SCHEDULING_PLATFORM } from '../../../../../Utils/appointmentUtils';

//UI Libraries
import { Button, Loading } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';
import moment from 'moment';
import { isValidPhoneNumber } from 'libphonenumber-js';

//Components
import { EncounterInputField } from '../Common/EncounterInputField';
import { EncounterSelectField } from '../Common/EncounterSelectField';
import { EncounterDateField } from '../Common/EncounterDateField';
import { EncounterMultiSelectField } from '../Common/EncounterMultiSelectField';
import { EncounterRadioGroupField } from '../Common/EncounterRadioGroupField';
import { EncounterNumberInputField } from '../Common/EncounterNumberInputField';
import PhoneNumberField from '../../../../Common/FormFields/PhoneNumberField';
import Tooltip from './../../../../Common/Tooltip';
import ChargeModal from './../../../ChargeInformation/ChargeModal.js';
import ICDTag from './../../../ChargeInformation/ICDTag.js';
import ListModal from './../../../../Common/ListModal.js';

const datefields = {
	followup_cap_completed_date: 1,
	visit_summary_release_date_pcp: 1,
	initial_cap_completed_date: 1,
	cap_sent_date_pcp: 1,
};

const EditVisitDetailSegment = (props) => {
	const {
		encounter,
		billingencounter,
		cancel,
		getICDCodes,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		enqueueSnackbar,
		visitIndicationsEnum,
		saveVisitBillingEncounter,
		saveVisitEncounter,
		visit_providerenums,
		visitsschema,
	} = props;

	const schedulingPlatform = _.get(encounter, ['schedulingPlatform'], '');
	const isPioneer = schedulingPlatform === SCHEDULING_PLATFORM.PIONEER;
	const tempFormData = {
		scheduling_poc: _.get(encounter, ['scheduling_poc'], ''),
		visit_provider_uuid: _.get(encounter, ['visit_provider_uuid'], ''),
		vsee_specialty: _.get(encounter, ['vsee_specialty'], ''),
		relationship_to_patient: _.get(
			encounter,
			['relationship_to_patient'],
			''
		),
		records_requested: _.get(encounter, ['records_requested'], ''),
		followup_cap_completed_date: _.get(
			encounter,
			['followup_cap_completed_date'],
			''
		),
		initial_cap_completed_date: _.get(
			encounter,
			['initial_cap_completed_date'],
			''
		),
		records_received: _.get(encounter, ['records_received'], ''),
		provider_indicated_specialty:
			_.get(encounter, ['provider_indicated_specialty']) == null
				? _.get(encounter, ['vsee_specialty'])
				: _.get(encounter, ['provider_indicated_specialty']),
		visit_summary_release_date_pcp: _.get(
			encounter,
			['visit_summary_release_date_pcp'],
			''
		),
		visit_summary_release_method_pcp: _.get(
			encounter,
			['visit_summary_release_method_pcp'],
			[]
		),
		cap_sent_date_pcp: _.get(encounter, ['cap_sent_date_pcp'], ''),
		cap_release_method_pcp: _.get(
			encounter,
			['cap_release_method_pcp'],
			[]
		),
		is_escalated: _.get(encounter, ['is_escalated'], false),
		supervising_physician: _.get(encounter, ['supervising_physician'], ''),
		additional_conference_line: _.get(encounter, ['additional_conference_line'], '')
	};
	const tempbillingFormData = {
		direct_cc_minutes: _.get(billingencounter, ['direct_cc_minutes'], null),
		indirect_cc_minutes: _.get(
			billingencounter,
			['indirect_cc_minutes'],
			null
		),
		post_session_indirect_cc_minutes: _.get(
			billingencounter,
			['post_session_indirect_cc_minutes'],
			null
		),
		post_negative_minutes: _.get(
			billingencounter,
			['post_negative_minutes'],
			null
		),
		units: _.get(billingencounter, ['units'], null),
		units_s0265_95: _.get(billingencounter, ['units_s0265_95'], null),
	};
	/* some time we are getting date and time in datetime fields so we are converting it */
	const updatedFormData = _.mapValues(tempFormData, function (value, key) {
		if (key in datefields) return getMomentDateOrStringNonStrick(value);
		return value;
	});

	const [formData, setFormData] = useState({});
	const [billingFormData, setBillingFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [showChargeModal, setShowChargeModal] = useState(false);
	const [chargeModalEmptyAllowed, setChargeModalEmptyAllowed] =
		useState(false);
	const [selectedICDCodes, setSelectedICDCodes] = useState(null);
	const [showVisitIndicationsModal, setShowVisitIndicationsModal] =
		useState(false);
	const [
		visitIndicationsModalEmptyAllowed,
		setVisitIndicationsModalEmptyAllowed,
	] = useState(false);
	const [selectedVisitIndications, setSelectedVisitIndications] =
		useState(null);
	const [showVisitIndicationsListModal, setShowVisitIndicationsListModal] =
		useState(false);

	useEffect(() => {
		setFormData(updatedFormData);
		setBillingFormData(tempbillingFormData);
	}, []);

	const validateForm = (formdata) => {
		let errors = {};
		const datetimeerors = _.omitBy(
			_.mapValues(datefields, function (value, key) {
				if (!isValidDateStringOREmpty(_.get(formdata, key, ''))) {
					return 'Invalid date';
				}
				return undefined;
			}),
			_.isUndefined
		);

		errors = { ...datetimeerors };

		if (
			!_.isNil(formdata['post_negative_minutes']) &&
			!_.isInteger(_.get(formdata, 'post_negative_minutes', []))
		) {
			errors['post_negative_minutes'] =
				'Please enter a non-decimal number';
		}
		if (
			!_.isNil(formdata['direct_cc_minutes']) &&
			!_.isInteger(formdata['direct_cc_minutes'])
		) {
			errors['direct_cc_minutes'] = 'Please enter a non-decimal number';
		}
		if (
			!_.isNil(formdata['indirect_cc_minutes']) &&
			!_.isInteger(formdata['indirect_cc_minutes'])
		) {
			errors['indirect_cc_minutes'] = 'Please enter a non-decimal number';
		}
		if (
			!_.isNil(formdata['post_session_indirect_cc_minutes']) &&
			!_.isInteger(formdata['post_session_indirect_cc_minutes'])
		) {
			errors['post_session_indirect_cc_minutes'] =
				'Please enter a non-decimal number';
		}
		if(
			!_.isEmpty(formdata['additional_conference_line']) &&
			!isValidPhoneNumber(formdata['additional_conference_line'])
		) {
			errors['additional_conference_line'] = 'Please enter a valid phone number';
		}
		return errors;
	};

	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		let stateErrors = {};
		stateData[key] = value;
		if (!(key in datefields)) {
			const _stateData = { ...stateData, ...billingFormData };
			stateErrors = validateForm(_stateData);
		}
		setErrors(stateErrors);
		setFormData(stateData);
	};

	const onBillingFormChange = (key, value) => {
		const stateData = { ...billingFormData };
		let stateErrors = {};
		stateData[key] = value;
		if (!(key in datefields)) {
			const _stateData = { ...stateData, ...formData };
			stateErrors = validateForm(_stateData);
		}
		setErrors(stateErrors);
		setBillingFormData(stateData);
	};
	const handleDateFieldValidate = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		const errors = validateForm(stateData);
		setErrors(errors);
		setFormData(stateData);
	};
	const renderICDCodes = () => {
		let medicalCodes =
			selectedICDCodes || get(encounter, 'medical_codes', []) || [];
		medicalCodes = medicalCodes.slice().sort((a, b) => {
			return get(a, 'code_name', '') < get(b, 'code_name', '') ? -1 : 1;
		});
		if (medicalCodes.length > 0) {
			return medicalCodes.map((el, i) => {
				const button =
					i === medicalCodes.length - 1 && !isPioneer ? (
						<div
							className='icdEditIcon'
							onClick={visibleChargeModalEmptyAllowed}
						>
							<ReactSVG
								src={whiteEditIcon}
								className='icdEditIconSVG'
							/>
							<span
								style={{ position: 'relative', right: '7px' }}
							>
								Edit
							</span>
						</div>
					) : (
						''
					);
				return (
					<React.Fragment>
						<ICDTag
							icdCode={el}
							toggleCodeSelection={() => {}}
							hasX={false}
						/>
						{button}
					</React.Fragment>
				);
			});
		} else {
			return (
				<div
					className={classnames('transparentBtn', {
						disabled: isPioneer,
					})}
					onClick={isPioneer ? undefined : visibleChargeModal}
				>
					Add ICD-10 code(s)
				</div>
			);
		}
	};

	const visibleChargeModal = () => {
		setShowChargeModal(true);
		setChargeModalEmptyAllowed(false);
	};

	const visibleChargeModalEmptyAllowed = () => {
		setShowChargeModal(true);
		setChargeModalEmptyAllowed(true);
	};

	const hideChargeModal = () => {
		setShowChargeModal(false);
		setChargeModalEmptyAllowed(false);
	};
	const renderChargeModal = () => {
		return (
			<ChargeModal
				hideChargeModal={hideChargeModal}
				title={
					chargeModalEmptyAllowed
						? 'Edit ICD-10 Code(s)'
						: 'Add ICD-10 Code(s)'
				}
				getICDCodes={getICDCodes}
				icdCodes={icdCodes}
				icdCodesLoading={icdCodesLoading}
				icdCodesError={icdCodesError}
				icdCodesPlacement={icdCodesPlacement}
				enqueueSnackbar={enqueueSnackbar}
				selectedICDCodes={
					selectedICDCodes || get(encounter, 'medical_codes', null)
				}
				encounterUuid={get(encounter, 'uuid', null)}
				emptyAllowed={chargeModalEmptyAllowed}
				encounterEditLoading={null}
				encounterEditError={null}
				editEncounter={null}
				willUseBilling={false}
				singleStage={true}
				billingEncounter={null}
				editBillingEncounter={null}
				billingEncounterEditLoading={null}
				billingEncounterEditError={null}
				getAssociatedBillingEncounter={null}
				associatedBillingEncounter={null}
				associatedBillingEncounterLoading={null}
				associatedBillingEncounterError={null}
				closeChargeModalAndDispatch={closeChargeModalAndDispatch}
			/>
		);
	};
	const closeChargeModalAndDispatch = (selectedCodes) => {
		if (
			get(selectedCodes, 'length', 0) === 0 &&
			!Array.isArray(selectedCodes)
		)
			return;
		const medicalCodes = formatSelectedCodes(selectedCodes);
		setSelectedICDCodes(medicalCodes);
		setShowChargeModal(false);
		setChargeModalEmptyAllowed(false);
		onFormChange('medical_codes', medicalCodes);
	};

	const formatSelectedCodes = (selectedCodes) => {
		const medicalCodes = [];
		selectedCodes.forEach((el) => {
			if (get(el, 'uuid', '') && get(el, 'code_name', '')) {
				medicalCodes.push({
					code_name: get(el, 'code_name', null),
					uuid: get(el, 'uuid', null),
				});
			}
		});
		return medicalCodes;
	};
	const renderIndications = () => {
		let tempVisitIndications = addVisitIndicationDisplayNames(
			selectedVisitIndications ||
				get(encounter, 'visit_indications', []) ||
				[],
			visitIndicationsEnum
		);
		tempVisitIndications = tempVisitIndications.slice().sort((a, b) => {
			if (get(a, 'display_name', '') < get(b, 'display_name', ''))
				return -1;
			else return 1;
		});
		const visitIndicationsLength = tempVisitIndications.length;
		const firstThree = tempVisitIndications.slice(0, 3);
		if (visitIndicationsLength > 0) {
			return firstThree.map((el, i) => {
				const last = i === firstThree.length - 1;
				const button =
					last && !isPioneer ? (
						<div
							className='icdEditIcon'
							onClick={visibleVisitIndicationsEmptyAllowed}
						>
							<ReactSVG
								src={whiteEditIcon}
								className='icdEditIconSVG'
							/>
							<span
								style={{ position: 'relative', right: '7px' }}
							>
								Edit
							</span>
						</div>
					) : (
						''
					);
				const viewAll =
					visitIndicationsLength > 3 && last ? (
						<div
							className='purpleText purpleTextSmall'
							onClick={visibleVisitIndicationsListModal}
							style={{ marginLeft: 'auto' }}
						>
							View all ({visitIndicationsLength})
						</div>
					) : (
						''
					);
				return (
					<React.Fragment>
						<ICDTag
							icdCode={el}
							altDisplayKey='display_name'
							toggleCodeSelection={() => {}}
							tagDisplayFunc={getVisitIndicationTagDisplay}
							longTagVariant={true}
							hasX={false}
						/>
						{button}
						{viewAll}
					</React.Fragment>
				);
			});
		} else {
			return (
				<div
					className={classnames('transparentBtn', {
						disabled: isPioneer,
					})}
					onClick={
						isPioneer ? undefined : visibleVisitIndicationsModal
					}
				>
					Add Indication(s)
				</div>
			);
		}
	};

	const visibleVisitIndicationsModal = () => {
		setShowVisitIndicationsModal(true);
		setVisitIndicationsModalEmptyAllowed(false);
	};

	const visibleVisitIndicationsEmptyAllowed = () => {
		setShowVisitIndicationsModal(true);
		setVisitIndicationsModalEmptyAllowed(true);
	};

	const hideVisitIndicationsModal = () => {
		setShowVisitIndicationsModal(false);
		setVisitIndicationsModalEmptyAllowed(false);
	};

	const renderVisitIndicationsModal = () => {
		return (
			<ChargeModal
				hideChargeModal={hideVisitIndicationsModal}
				title={
					visitIndicationsModalEmptyAllowed
						? 'Edit Indication(s)'
						: 'Add Indication(s)'
				}
				icdCodes={visitIndicationsEnum}
				icdCodesLoading={false}
				icdCodesError={null}
				icdCodesPlacement={'bottom'}
				getICDCodes={null}
				noLoading={true}
				noPlaceHolder={true}
				onlyLocalSearch={true}
				localSearchKey={'display_name'}
				localSortKey={'sorting_name'}
				filter={visitIndicationsFilterEnum}
				filterKey={'specialty_display_names'}
				freeTextKey={'other_indications'}
				enqueueSnackbar={enqueueSnackbar}
				selectedICDCodes={addVisitIndicationDisplayNames(
					selectedVisitIndications ||
						get(encounter, 'visit_indications', null),
					visitIndicationsEnum
				)}
				encounterUuid={get(encounter, 'uuid', null)}
				emptyAllowed={visitIndicationsModalEmptyAllowed}
				buttonText={
					visitIndicationsModalEmptyAllowed
						? 'Save'
						: 'Add Indication(s)'
				}
				filterHeader={'Filter results by specialty'}
				altCheckbox={visitIndicationsCheckboxEnum}
				tagDisplayFunc={getVisitIndicationTagDisplay}
				longTagVariant={true}
				noDescription={true}
				reverseVariant={true}
				encounterEditLoading={null}
				encounterEditError={null}
				editEncounter={null}
				willUseBilling={false}
				singleStage={true}
				billingEncounter={null}
				editBillingEncounter={null}
				billingEncounterEditLoading={null}
				billingEncounterEditError={null}
				getAssociatedBillingEncounter={null}
				associatedBillingEncounter={null}
				associatedBillingEncounterLoading={null}
				associatedBillingEncounterError={null}
				notUsingIcd={true}
				closeChargeModalAndDispatch={
					closeVisitIndicationsModalAndDispatch
				}
			/>
		);
	};
	const closeVisitIndicationsModalAndDispatch = (visitIndications) => {
		if (
			get(visitIndications, 'length', 0) === 0 &&
			!Array.isArray(visitIndications)
		)
			return;
		const formattedVisitIndications =
			formatVisitIndications(visitIndications);
		setSelectedVisitIndications(formattedVisitIndications);
		setShowVisitIndicationsModal(false);
		setVisitIndicationsModalEmptyAllowed(false);
		onFormChange('visit_indications', formattedVisitIndications);
	};
	const formatVisitIndications = (visitIndications) => {
		const formattedVisitIndications = [];
		visitIndications.forEach((el) => {
			if (get(el, 'uuid', '')) {
				formattedVisitIndications.push({
					uuid: get(el, 'uuid', null),
					personal_hx: get(el, 'personal_hx', null) || false,
					family_hx: get(el, 'family_hx', null) || false,
					other_indications: get(el, 'other_indications', null),
					display_name: get(el, 'display_name', null),
				});
			}
		});
		return formattedVisitIndications;
	};

	const visibleVisitIndicationsListModal = () => {
		setShowVisitIndicationsListModal(true);
	};

	const hideVisitIndicationsListModal = () => {
		setShowVisitIndicationsListModal(false);
	};

	const renderVisitIndicationsListModal = () => {
		const visitIndicationTags = addVisitIndicationDisplayNames(
			selectedVisitIndications ||
				get(encounter, 'visit_indications', null),
			visitIndicationsEnum
		);
		const visitIndicationTagsLength = get(visitIndicationTags, 'length', 0);
		const visitIndicationTagsHTML = visitIndicationTags.map((tag) => {
			return (
				<ICDTag
					icdCode={tag}
					altDisplayKey='display_name'
					toggleCodeSelection={() => {}}
					tagDisplayFunc={getVisitIndicationTagDisplay}
					longTagVariant={true}
					hasX={false}
				/>
			);
		});
		return (
			<ListModal
				htmlContent={visitIndicationTagsHTML}
				title={`All Indications (${visitIndicationTagsLength})`}
				close={hideVisitIndicationsListModal}
			/>
		);
	};

	const determineIsSaveVisitDetailDisabled = () => {
		const hasVisitCAPCompletedDate = get(
			formData,
			'initial_cap_completed_date',
			null
		);
		const hasResultCAPCompletedDate = get(
			formData,
			'followup_cap_completed_date',
			null
		);

		const postTestResultCAPTimeSpent =
			_.get(billingencounter, 'post_negative_minutes') ||
			_.get(billingFormData, 'post_negative_minutes');
		const postSessionIndirectTime =
			_.get(billingencounter, 'post_session_indirect_cc_minutes') ||
			_.get(billingFormData, 'post_session_indirect_cc_minutes');
		const directClinicalCareTime =
			_.get(billingencounter, 'direct_cc_minutes', null) ||
			_.get(billingFormData, 'direct_cc_minutes');
		const preSessionIndirectTime =
			_.get(billingencounter, 'indirect_cc_minutes', null) ||
			_.get(billingFormData, 'indirect_cc_minutes');

		const encounterDateOfService = _.get(
			encounter,
			'date_of_service',
			null
		);
		const tempSelectedVisitIndications =
			selectedVisitIndications ||
			get(encounter, 'visit_indications', []) ||
			[];
		const statusDate = determineIsAfterIndicationsGoLive(
			encounterDateOfService
		);
		const statusLength = get(tempSelectedVisitIndications, 'length', 0) > 0;
		const requiresIndications = statusDate && !statusLength;

		if (hasVisitCAPCompletedDate) {
			const status =
				(directClinicalCareTime || directClinicalCareTime === 0) &&
				(preSessionIndirectTime || preSessionIndirectTime === 0) &&
				(postSessionIndirectTime || postSessionIndirectTime === 0);
			if (!status && requiresIndications)
				return 'visitCAPCompletedDateIndications';
			else if (!status) return 'visitCAPCompletedDate';
			else if (requiresIndications) return 'indications';
		}

		if (hasResultCAPCompletedDate) {
			const status =
				postTestResultCAPTimeSpent || postTestResultCAPTimeSpent === 0;
			if (!status) return 'resultCAPCompletedDate';
		}
	};

	const determineSaveTooltipMessage = (disabilityCondition) => {
		const visitCapIndications =
			disabilityCondition === 'visitCAPCompletedDateIndications';
		if (
			disabilityCondition === 'visitCAPCompletedDate' ||
			visitCapIndications
		) {
			return (
				<React.Fragment>
					<div style={{ marginTop: '4px' }}>
						1. Direct Clinical Care Time (minutes)
					</div>

					<div style={{ marginTop: '4px' }}>
						2. Pre-Session Indirect Time (minutes)
					</div>

					<div style={{ marginTop: '4px' }}>
						3. Post-Session Indirect Time (minutes)
					</div>

					{visitCapIndications && (
						<div style={{ marginTop: '4px' }}>4. Indications</div>
					)}
				</React.Fragment>
			);
		} else if (disabilityCondition === 'resultCAPCompletedDate') {
			return (
				<div style={{ marginTop: '4px' }}>
					1. Post-Test Result CAP Time Spent (minutes)
				</div>
			);
		} else if (disabilityCondition === 'indications') {
			return <div style={{ marginTop: '4px' }}>1. Indications</div>;
		} else return null;
	};

	const saveForm = () => {
		const _stateData = { ...formData, ...billingFormData };
		const errors = validateForm(_stateData);
		if (_.isEmpty(errors)) {
			const updated_billingencounter_formdata = _.mapValues(
				billingFormData,
				function (value, key) {
					if (
						(key in
							{
								direct_cc_minutes: 1,
								indirect_cc_minutes: 1,
								post_session_indirect_cc_minutes: 1,
								post_negative_minutes: 1,
							} &&
							value === '') ||
						value == undefined
					) {
						return null;
					}
					return value;
				}
			);
			const updated_encounter_data = _.mapValues(
				formData,
				function (value, key) {
					if (key == 'visit_indications') {
						return value.map((indication) => {
							const formattedIndication = { ...indication };
							if (formattedIndication.display_name)
								delete formattedIndication.display_name;
							if (
								formattedIndication.hasOwnProperty(
									'other_indications'
								) &&
								!formattedIndication.other_indications
							) {
								delete formattedIndication.other_indications;
							}
							return formattedIndication;
						});
					}
					if (value == 'empty') return '';
					return value;
				}
			);

			setLoading(true);
			saveVisitBillingEncounter(
				billingencounter,
				updated_billingencounter_formdata,
				updated_encounter_data
			)
				.then(() => {
					setTimeout(() => {
						saveVisitEncounter(
							encounter,
							updated_encounter_data
						).then(() => {
							setTimeout(() => {
								setLoading(false);
								cancel();
							}, 1000);
						})
						.catch(() => {
							setLoading(false);
						});
					}, 1000);
				})
				.catch(() => {
					setLoading(false);
				});
		} else {
			setErrors(errors);
		}
	};

	const isDisableAdditionalConferenceField = () => {
		const appointmentTime = moment.utc(
			_.get(encounter, ['date_of_service'], ''),
			'YYYY-MM-DD HH:mm:ss'
		);
		const currentUTC = moment.utc();
		return appointmentTime.isBefore(currentUTC)
	}

	const isCPTS026595 = _.get(billingencounter, ['cpt_s0265_95'], false);
	const allowEscalate =
		isCPTS026595 &&
		!_.isEmpty(_.get(formData, ['supervising_physician'], ''));
	const isSaveVisitDetailDisabled = determineIsSaveVisitDetailDisabled();
	const saveButtonClass = isSaveVisitDetailDisabled
		? 'is-disabled editVisitSave'
		: 'editVisitSave';
	return (
		<Loading loading={loading}>
			{showChargeModal && (
				<div className='popupModal'>{renderChargeModal()}</div>
			)}
			{showVisitIndicationsModal && (
				<div className='popupModal'>
					{renderVisitIndicationsModal()}
				</div>
			)}
			{showVisitIndicationsListModal && (
				<div className='popupModal'>
					{renderVisitIndicationsListModal()}
				</div>
			)}
			<div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Scheduling POC
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Visit State
							</div>
						</div>

						<div className='segment-col'>
							<div className='encounter-field-header'>
								Visit Timezone
							</div>
						</div>

						<div className='segment-col'>
							<div className='encounter-field-header'>
								Relationship To Patient
							</div>
						</div>

						<div className='segment-col'>
							<div className='encounter-field-header'>
								Date & Time Of Service
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={_.get(
										formData,
										['scheduling_poc'],
										''
									)}
									name='scheduling_poc'
									onChange={(value) =>
										onFormChange('scheduling_poc', value)
									}
									placeholder='John Doe'
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={_.get(
										encounter,
										['state_of_visit'],
										''
									)}
									name='state_of_visit'
									disabled={true}
								/>
							</div>
						</div>

						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={_.get(encounter, ['timezone'], '--')}
									name='timezone'
									disabled={true}
								/>
							</div>
						</div>

						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['relationship_to_patient'],
										''
									)}
									name='relationship_to_patient'
									options={createEnumsOptions(
										visitsschema,
										['relationship_to_patient'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'relationship_to_patient',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={getEncounterSpecificTime(
										_.get(
											encounter,
											['date_of_service'],
											''
										),
										_.get(encounter, ['timezone'], '')
									)}
									name='date_of_service'
									disabled={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								TM Platform
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Consultation Type
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Requested Specialty
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Visit Provider
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Visit Status
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										encounter,
										['tm_platform'],
										''
									)}
									name='tm_platform'
									disabled={true}
									options={createEnumsOptions(
										visitsschema,
										['tm_platform'],
										'key',
										'display_name',
										'key'
									)}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										encounter,
										['consultation_type'],
										''
									)}
									name='consultation_type'
									disabled={true}
									options={[
										{
											key: _.get(
												encounter,
												['consultation_type'],
												''
											),
											label: _.get(
												encounter,
												['consultation_type'],
												''
											),
											value: _.get(
												encounter,
												['consultation_type'],
												''
											),
										},
									]}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
                                        formData,
                                        ['vsee_specialty'],
                                        ''
                                    )}
									name='vsee_specialty'
									disabled={!(_.get(encounter,'visit_status', '') == 'booked' && isAppointmentStarted(encounter))}
									options={_.filter(
										createEnumsOptions(
											props,
											['partnerLevelSpecialities'],
											'key',
											'label',
											'value'
										),
										function (o) {
											return (
												o.label.indexOf(
													'Willing'
												) == -1 &&
												o.label !== 'No Default'
											);
										}
                                    )}
                                    onChange={(value) =>
                                        onFormChange(
                                            'vsee_specialty',
                                            value
                                        )
                                    }
                                    error={_.get(
                                        errors,
                                        ['vsee_specialty'],
                                        ''
                                    )}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
                                        formData,
                                        ['visit_provider_uuid'],
                                        ''
                                    )}
									name='visit_provider_uuid'
									disabled={!(_.get(encounter,'visit_status', '') == 'booked' && isAppointmentStarted(encounter))}
									options={createEnumsOptions(
                                                props,
                                                ['manual_visit_providers'],
                                                'uuid',
                                                'full_name',
                                                'uuid'
                                    )}
                                    onChange={(value) =>
                                        onFormChange('visit_provider_uuid', value)
                                    }
                                    error={_.get(
                                        errors,
                                        ['visit_provider_uuid'],
                                        ''
                                    )}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={GetSelectvalue(
										encounter,
										['visit_status'],
										_.get(
											visitsschema,
											['visit_status'],
											[]
										)
									)}
									name='visit_status'
									disabled={true}
									options={[
										{
											key: GetSelectvalue(
												encounter,
												['visit_status'],
												_.get(
													visitsschema,
													['visit_status'],
													[]
												)
											),
											label: GetSelectvalue(
												encounter,
												['visit_status'],
												_.get(
													visitsschema,
													['visit_status'],
													[]
												)
											),
											value: GetSelectvalue(
												encounter,
												['visit_status'],
												_.get(
													visitsschema,
													['visit_status'],
													[]
												)
											),
										},
									]}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Records Requested
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Records Received
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Referring Provider (RP)
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								RP Contact Info
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								RP Recommended Test
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['records_requested'],
										''
									)}
									name='records_requested'
									options={createEnumsOptions(
										visitsschema,
										['records_requested'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('records_requested', value)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['records_received'],
										''
									)}
									name='records_received'
									options={createEnumsOptions(
										visitsschema,
										['records_received'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('records_received', value)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={encounterFieldDisplay(encounter, [
										'referring_provider_name',
									])}
									name='referring_provider_name'
									disabled={true}
									placeholder='Paul Buelieu'
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={encounterFieldDisplay(encounter, [
										'referring_provider_fax_email',
									])}
									name='referring_provider_fax_email'
									disabled={true}
									placeholder='email@email.com'
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={encounterFieldDisplay(encounter, [
										'requested_test_via_provider',
									])}
									name='requested_test_via_provider'
									disabled={true}
									placeholder='Test 1'
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Provider Indicated Specialty
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								ICD-10 Code(s) (Visit)
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Indications
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Escalated to Supervising Physician?
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Supervising Physician
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['provider_indicated_specialty'],
										''
									)}
									name='provider_indicated_specialty'
									disabled={false}
									options={_.filter(
										createEnumsOptions(
											visitsschema,
											['vsee_specialty'],
											'key',
											'display_name',
											'key'
										),
										function (o) {
											return (
												o.label.indexOf('Willing') ==
													-1 &&
												o.label !== 'No Default'
											);
										}
									)}
									onChange={(value) =>
										onFormChange(
											'provider_indicated_specialty',
											value
										)
									}
									placeholder='Select'
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div
							className='segment-col'
							style={{
								display: 'flex',
								flexDirection: 'row',
								flexWrap: 'wrap',
							}}
							disabled={isPioneer}
						>
							{renderICDCodes()}
						</div>
						<div
							className='segment-col'
							style={{
								display: 'flex',
								flexDirection: 'row',
								flexWrap: 'wrap',
							}}
							disabled={isPioneer}
						>
							{renderIndications()}
						</div>
						<div className='segment-col'>
							<div
								className={classnames(
									'encounter-input-section is_escalated_section',
									!allowEscalate ? 'is-disabled' : ''
								)}
							>
								<EncounterRadioGroupField
									name={'is_escalated'}
									value={_.get(
										formData,
										['is_escalated'],
										''
									)}
									options={[
										{ label: 'Yes', value: true },
										{ label: 'No', value: false },
									]}
									onChange={(value) =>
										onFormChange('is_escalated', value)
									}
									error={_.get(errors, ['is_escalated'])}
									disabled={!allowEscalate}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['supervising_physician'],
										''
									)}
									name='supervising_physician'
									disabled={true}
									options={createEnumsOptions(
										visitsschema,
										['supervising_physician'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'supervising_physician',
											value
										)
									}
									placeholder='Select'
									error={_.get(errors, [
										'supervising_physician',
									])}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Direct Clinical Care Time (minutes)
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Pre-Session Indirect Time (minutes)
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Post-Session Indirect Time (minutes)
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Post-Test Result CAP Time Spent (minutes)
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								<EncounterNumberInputField
									defaultValue={_.get(
										billingFormData,
										['direct_cc_minutes'],
										null
									)}
									value={_.get(
										billingFormData,
										['direct_cc_minutes'],
										null
									)}
									name='direct_cc_minutes'
									onChange={(value) =>
										onBillingFormChange(
											'direct_cc_minutes',
											value
										)
									}
									disabled={isPioneer}
									error={_.get(
										errors,
										['direct_cc_minutes'],
										''
									)}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								<EncounterNumberInputField
									defaultValue={_.get(
										billingFormData,
										['indirect_cc_minutes'],
										null
									)}
									value={_.get(
										billingFormData,
										['indirect_cc_minutes'],
										null
									)}
									name='indirect_cc_minutes'
									onChange={(value) =>
										onBillingFormChange(
											'indirect_cc_minutes',
											value
										)
									}
									disabled={isPioneer}
									error={_.get(
										errors,
										['indirect_cc_minutes'],
										''
									)}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								<EncounterNumberInputField
									defaultValue={_.get(
										billingFormData,
										['post_session_indirect_cc_minutes'],
										null
									)}
									value={_.get(
										billingFormData,
										['post_session_indirect_cc_minutes'],
										null
									)}
									name='post_session_indirect_cc_minutes'
									onChange={(value) =>
										onBillingFormChange(
											'post_session_indirect_cc_minutes',
											value
										)
									}
									disabled={isPioneer}
									error={_.get(
										errors,
										['post_session_indirect_cc_minutes'],
										''
									)}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								<EncounterNumberInputField
									defaultValue={_.get(
										billingFormData,
										['post_negative_minutes'],
										null
									)}
									value={_.get(
										billingFormData,
										['post_negative_minutes'],
										null
									)}
									name='post_negative_minutes'
									onChange={(value) =>
										onBillingFormChange(
											'post_negative_minutes',
											value
										)
									}
									disabled={isPioneer}
									error={_.get(
										errors,
										['post_negative_minutes'],
										''
									)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Visit CAP Completed Date
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-field-header'>
								Visit CAP Sent To Patient On
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-field-header'>
								Visit CAP Sent To PCP On
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section '>
								<EncounterDateField
									value={_.get(formData, [
										'initial_cap_completed_date',
									])}
									name='initial_cap_completed_date'
									placeholder='Date'
									error={_.get(
										errors,
										['initial_cap_completed_date'],
										''
									)}
									onChange={(value) =>
										onFormChange(
											'initial_cap_completed_date',
											value
										)
									}
									disabled={isPioneer}
									onBlur={(value) =>
										handleDateFieldValidate(
											'initial_cap_completed_date',
											value
										)
									}
								/>
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-multi-cols-section'>
								<div className='encounter-input-section multi-first-col'>
									<EncounterDateField
										value={_.get(
											encounter,
											['initial_visit_summary_sent'],
											''
										)}
										name='initial_visit_summary_sent'
										placeholder='Date'
										disabled={true}
									/>
								</div>
								<div className='via_seprator'> via </div>

								<div className='encounter-input-section multi-two-col'>
									<EncounterMultiSelectField
										value={_.get(
											encounter,
											[
												'visit_summary_release_method_patient',
											],
											''
										)}
										name='visit_summary_release_method_patient'
										options={createEnumsOptions(
											visitsschema,
											[
												'visit_summary_release_method_patient',
											],
											'key',
											'display_name',
											'key'
										)}
										extraclassName='multi-selectfield-disable'
										disabled={true}
									/>
								</div>
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-multi-cols-section'>
								<div className='encounter-input-section multi-first-col relative-section'>
									<EncounterDateField
										value={_.get(
											encounter,
											['visit_summary_release_date_pcp'],
											''
										)}
										name='visit_summary_release_date_pcp'
										placeholder='Date'
										disabled={true}
									/>
								</div>
								<div className='via_seprator'> via </div>
								<div className='encounter-input-section multi-two-col'>
									<EncounterMultiSelectField
										value={_.get(
											encounter,
											[
												'visit_summary_release_method_pcp',
											],
											''
										)}
										name='visit_summary_release_method_pcp'
										options={createEnumsOptions(
											visitsschema,
											[
												'visit_summary_release_method_pcp',
											],
											'key',
											'display_name',
											'key'
										)}
										extraclassName='multi-selectfield-disable'
										disabled={true}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Result CAP Completed Date
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-field-header'>
								Result CAP Sent To Patient On
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-field-header'>
								Result CAP Sent To PCP On
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(formData, [
										'followup_cap_completed_date',
									])}
									name='followup_cap_completed_date'
									placeholder='Date'
									error={_.get(
										errors,
										['followup_cap_completed_date'],
										''
									)}
									onChange={(value) =>
										onFormChange(
											'followup_cap_completed_date',
											value
										)
									}
									onBlur={(value) =>
										handleDateFieldValidate(
											'followup_cap_completed_date',
											value
										)
									}
								/>
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-multi-cols-section'>
								<div className='encounter-input-section multi-first-col'>
									<EncounterDateField
										value={_.get(
											encounter,
											['cap_sent_to_patient'],
											''
										)}
										name='cap_sent_to_patient'
										placeholder='Date'
										disabled={true}
									/>
								</div>
								<div className='via_seprator'> via </div>
								<div className='encounter-input-section multi-two-col'>
									<EncounterMultiSelectField
										value={_.get(
											encounter,
											['cap_release_method_patient'],
											''
										)}
										name='cap_release_method_patient'
										options={createEnumsOptions(
											visitsschema,
											['cap_release_method_patient'],
											'key',
											'display_name',
											'key'
										)}
										extraclassName='multi-selectfield-disable'
										disabled={true}
									/>
								</div>
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-multi-cols-section'>
								<div className='encounter-input-section multi-first-col'>
									<EncounterDateField
										value={_.get(
											encounter,
											['cap_sent_date_pcp'],
											''
										)}
										name='cap_sent_date_pcp'
										placeholder='Date'
										disabled={true}
									/>
								</div>
								<div className='via_seprator'> via </div>
								<div className='encounter-input-section multi-two-col'>
									<EncounterMultiSelectField
										value={_.get(
											encounter,
											['cap_release_method_pcp'],
											''
										)}
										name='cap_release_method_pcp'
										options={createEnumsOptions(
											visitsschema,
											['cap_release_method_pcp'],
											'key',
											'display_name',
											'key'
										)}
										extraclassName='multi-selectfield-disable'
										disabled={true}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Pioneer Appointment ID
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Additional Conference Line
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								{_.get(encounter, 'pioneer_appointment_id') ||
									'N/A'}
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								<PhoneNumberField
									value={formData?.additional_conference_line || ''}
									onChange={(value) =>
										onFormChange(
											'additional_conference_line',
											value
										)
									}
									disabled={isDisableAdditionalConferenceField()}
									error={errors?.additional_conference_line || ''}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className='segment-footer-container'>
					<div className='cancel-btn-link'>
						<Button onClick={() => cancel()}>Cancel</Button>
					</div>
					<div className='save-btn-link encounterTT'>
						<Tooltip
							placement='top'
							content={
								<div style={{ fontSize: '12px' }}>
									<div>
										Complete the following field(s) before
										saving:
									</div>
									{determineSaveTooltipMessage(
										isSaveVisitDetailDisabled
									)}
								</div>
							}
							disabled={!isSaveVisitDetailDisabled}
						>
							<Button
								className={saveButtonClass}
								style={{ float: 'right' }}
								onClick={() => {
									!isSaveVisitDetailDisabled && saveForm();
								}}
							>
								Save
							</Button>
						</Tooltip>
					</div>
				</div>
			</div>
		</Loading>
	);
};

export default EditVisitDetailSegment;
