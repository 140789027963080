//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	getEncounterSpecificTime,
	EncounterDateFieldsDisplay,
	GetMultiSelectvalue,
	GetSelectvalue,
	getProviderName,
	getProviderIcon,
	encounterFreeTextFieldDisplay,
} from '../Common/utilsfunctions';

const VisitDetailSegment = (props) => {
	return (
		<Fragment>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Scheduling POC
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Date & Time Of Service
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Relationship To Patient
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Visit State
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFreeTextFieldDisplay(props, [
								'encounter',
								'scheduling_poc',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{getEncounterSpecificTime(
								_.get(
									props,
									['encounter', 'date_of_service'],
									''
								),
								''
							)}
						</div>
					</div>

					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'relationship_to_patient'],
								_.get(
									props,
									[
										'ccintakeschema',
										'relationship_to_patient',
									],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFreeTextFieldDisplay(props, [
								'encounter',
								'state_of_visit',
							])}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							TM Platform
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Consultation Type
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Visit Provider
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Visit Status
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Patient Status
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'tm_platform'],
								_.get(
									props,
									['ccintakeschema', 'tm_platform'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{encounterFreeTextFieldDisplay(props, [
								'encounter',
								'consultation_type',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{getProviderIcon(props, [
								'encounter',
								'visit_provider',
							]) != null && (
								<img
									src={getProviderIcon(props, [
										'encounter',
										'visit_provider',
									])}
									alt=''
								/>
							)}
							{getProviderName(
								props,
								['encounter', 'visit_provider'],
								_.get(props, ['visit_providerenums'], [])
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'visit_status'],
								_.get(
									props,
									['ccintakeschema', 'visit_status'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'patient_status'],
								_.get(
									props,
									['ccintakeschema', 'patient_status'],
									[]
								)
							)}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Results Requested By PCP
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Results Requested By Patient
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'results_requested_by_pcp'],
								_.get(
									props,
									[
										'ccintakeschema',
										'results_requested_by_pcp',
									],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'results_requested_by_patient'],
								_.get(
									props,
									[
										'ccintakeschema',
										'results_requested_by_patient',
									],
									[]
								)
							)}
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Result CAP Completed Date
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Result CAP Sent To Patient On
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Result CAP Sent To PCP On
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{EncounterDateFieldsDisplay(props, [
								'encounter',
								'followup_cap_completed_date',
							])}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{!_.isNil(
								_.get(props, [
									'encounter',
									'cap_sent_to_patient',
								])
							) &&
							!_.isNil(
								_.get(props, [
									'encounter',
									'cap_release_method_patient',
								])
							) &&
							!_.isEmpty(
								_.get(props, [
									'encounter',
									'cap_sent_to_patient',
								])
							) &&
							!_.isEmpty(
								_.get(props, [
									'encounter',
									'cap_release_method_patient',
								])
							)
								? EncounterDateFieldsDisplay(props, [
										'encounter',
										'cap_sent_to_patient',
								  ]) +
								  ' via ' +
								  GetMultiSelectvalue(
										props,
										[
											'encounter',
											'cap_release_method_patient',
										],
										_.get(
											props,
											[
												'ccintakeschema',
												'cap_release_method_patient',
											],
											[]
										)
								  )
								: '--'}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{!_.isNil(
								_.get(props, ['encounter', 'cap_sent_date_pcp'])
							) &&
							!_.isNil(
								_.get(props, [
									'encounter',
									'cap_release_method_pcp',
								])
							) &&
							!_.isEmpty(
								_.get(props, ['encounter', 'cap_sent_date_pcp'])
							) &&
							!_.isEmpty(
								_.get(props, [
									'encounter',
									'cap_release_method_pcp',
								])
							)
								? EncounterDateFieldsDisplay(props, [
										'encounter',
										'cap_sent_date_pcp',
								  ]) +
								  ' via ' +
								  GetMultiSelectvalue(
										props,
										['encounter', 'cap_release_method_pcp'],
										_.get(
											props,
											[
												'ccintakeschema',
												'cap_release_method_pcp',
											],
											[]
										)
								  )
								: '--'}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default VisitDetailSegment;
