//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import Field from './Field.js';

class FormBody extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		const fieldsJSON = get(this, 'props.fieldsJSON', []);
		const errors = get(this, 'props.errors', {});
		return (
			<div className='formModalBodyContainer'>
				{Array.isArray(fieldsJSON) &&
					fieldsJSON.map((el) => {
						if (get(el, 'type', null) === 'plainText') {
							const text = get(el, 'text', null) || '';
							const className = get(el, 'className', null) || '';
							return <div className={className}>{text}</div>;
						} else {
							return (
								<Field
									fieldData={el}
									fieldChange={this.props.fieldChange}
									key={get(el, 'header', null)}
									error={get(
										errors,
										get(el, 'header', null),
										null
									)}
								/>
							);
						}
					})}
			</div>
		);
	}
}

export default FormBody;
