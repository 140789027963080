import _ from 'lodash';
import { get } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { isValidNumber } from 'libphonenumber-js';
import { enqueueSnackbar as notiEnqueueSnackbar } from 'notistack';

import validPostalCode from 'postcode-validator';
import EmailValidator from 'email-validator';

import alan_rope from './assets/providers/alan_rope.png';
import audrey_fan from './assets/providers/audrey_fan.png';
import betsy_swope from './assets/providers/betsy_swope.png';
import brian_kirmse from './assets/providers/brian_kirmse.png';
import catherine_fine from './assets/providers/catherine_fine.png';
import douglas_stewart from './assets/providers/douglas_steward.png';
import elyse_weber from './assets/providers/elyse_weber.png';
import erynn_gordon from './assets/providers/erynn_gordon.png';
import hailey_shabazz from './assets/providers/hailey_shabazz.png';
import jamie_dokson from './assets/providers/jamie_dokson.png';
import kate_sargent from './assets/providers/kate_sargent.png';
import kathryn_sargent from './assets/providers/kathryn_sargent.png';
import lindsay_meyers from './assets/providers/lindsay_meyers.png';
import maria_daddario from './assets/providers/maria_daddario.png';
import mary_frances_garber from './assets/providers/mary_frances_garber.png';
import sarah_kalia from './assets/providers/sarah_kalia.png';
import scott_weissman from './assets/providers/scott_weisman.png';
import steven_bleyl from './assets/providers/steven_bleyl.png';
import tanya_bardakjian from './assets/providers/tanya_bardakjian.png';
import GC_Default from './assets/providers/GC-Default.png';

export const report_types = {
	completed_encounters_report: 'Completed Encounters Report',
	new_patient_seen_report: 'New Patients Seen Report',
	provider_report: 'Referral Status Report',
	scheduling_reports: 'Scheduling report',
	appointment_reports: 'Appointment report',
	total_availability_and_total_booked: 'Total availability and total booked',
	next_slot_availability: 'Time to next availability',
	all_availability: 'All availability slots',
	all_appointments: 'All appointments',
	specialty: 'Matching % for specialty requested',
};

export const address = [
	'address_line1',
	'address_line2',
	'city',
	'state',
	'zip',
];

export const encounter_type = {
	lab_test_authorization: 'Lab',
	visit: 'Visit',
	Visit: 'Visit',
	'group-session': 'Group session',
	'cc-intake': 'CC Intake',
	'research-data': 'Research Data Return',
	scp: 'SCP',
};
export const SortOrderKey = {
	ASC: 'ASC',
	DESC: 'DESC',
};
export const patient_theme = {
	palette: {
		primary: {
			main: '#A0ABB9',
		},
		secondary: {
			main: '#7B26C9',
		},
	},
	typography: {
		fontFamily: 'RionaSans, serif !important',
		useNextVariants: true,
	},
};

export const theme = {
	palette: {
		primary: {
			main: '#A0ABB9',
		},
	},
};

export const dependentVariants = [
	{ label: 'Spouse or Partner', value: 'spouse' },
	{ label: 'Child', value: 'child' },
	{ label: 'Parent', value: 'parent' },
	{ label: 'Other', value: 'other' },
];
export const providers_images = {
	default_image: GC_Default,
	alan_rope: alan_rope,
	audrey_fan: audrey_fan,
	betsy_swope: betsy_swope,
	brian_kirmse: brian_kirmse,
	catherine_fine: catherine_fine,
	douglas_stewart: douglas_stewart,
	elyse_weber: elyse_weber,
	erynn_gordon: erynn_gordon,
	erynn_gordon_fishman: erynn_gordon,
	hailey_shabazz: hailey_shabazz,
	jamie_dokson: jamie_dokson,
	kate_sargent: kate_sargent,
	kathryn_sargent: kathryn_sargent,
	lindsay_meyers: lindsay_meyers,
	maria_daddario: maria_daddario,
	mary_frances_garber: mary_frances_garber,
	sarah_kalia: sarah_kalia,
	scott_weissman: scott_weissman,
	steven_bleyl: steven_bleyl,
	tanya_bardakjian: tanya_bardakjian,
};

export const countriesList = [
	{ label: 'US', value: 'US' },
	{ label: 'Canada', value: 'CA' },
];

export const states_list = [
	{ key: 'AL', value: 'Alabama' },
	{ key: 'AK', value: 'Alaska' },
	{ key: 'AZ', value: 'Arizona' },
	{ key: 'AR', value: 'Arkansas' },
	{ key: 'CA', value: 'California' },
	{ key: 'CO', value: 'Colorado' },
	{ key: 'CT', value: 'Connecticut' },
	{ key: 'DE', value: 'Delaware' },
	{ key: 'DC', value: 'District of Columbia' },
	{ key: 'FL', value: 'Florida' },
	{ key: 'GA', value: 'Georgia' },
	{ key: 'HI', value: 'Hawaii' },
	{ key: 'ID', value: 'Idaho' },
	{ key: 'IL', value: 'Illinois' },
	{ key: 'IN', value: 'Indiana' },
	{ key: 'IA', value: 'Iowa' },
	{ key: 'KS', value: 'Kansas' },
	{ key: 'KY', value: 'Kentucky' },
	{ key: 'LA', value: 'Louisiana' },
	{ key: 'ME', value: 'Maine' },
	{ key: 'MD', value: 'Maryland' },
	{ key: 'MA', value: 'Massachusetts' },
	{ key: 'MI', value: 'Michigan' },
	{ key: 'MN', value: 'Minnesota' },
	{ key: 'MS', value: 'Mississippi' },
	{ key: 'MO', value: 'Missouri' },
	{ key: 'MT', value: 'Montana' },
	{ key: 'NE', value: 'Nebraska' },
	{ key: 'NV', value: 'Nevada' },
	{ key: 'NH', value: 'New Hampshire' },
	{ key: 'NJ', value: 'New Jersey' },
	{ key: 'NM', value: 'New Mexico' },
	{ key: 'NY', value: 'New York' },
	{ key: 'NC', value: 'North Carolina' },
	{ key: 'ND', value: 'North Dakota' },
	{ key: 'OH', value: 'Ohio' },
	{ key: 'OK', value: 'Oklahoma' },
	{ key: 'OR', value: 'Oregon' },
	{ key: 'PA', value: 'Pennsylvania' },
	{ key: 'RI', value: 'Rhode Island' },
	{ key: 'SC', value: 'South Carolina' },
	{ key: 'SD', value: 'South Dakota' },
	{ key: 'TN', value: 'Tennessee' },
	{ key: 'TX', value: 'Texas' },
	{ key: 'UT', value: 'Utah' },
	{ key: 'VT', value: 'Vermont' },
	{ key: 'VA', value: 'Virginia' },
	{ key: 'WA', value: 'Washington' },
	{ key: 'WV', value: 'West Virginia' },
	{ key: 'WI', value: 'Wisconsin' },
	{ key: 'WY', value: 'Wyoming' },
	{ key: 'AA', value: 'Armed Forces Americas' },
	{ key: 'AE', value: 'Armed Forces Europe' },
	{ key: 'AP', value: 'Armed Forces Pacific' },
	{ key: 'AS', value: 'American Samoa' },
	{ key: 'GU', value: 'Guam' },
	{ key: 'MP', value: 'Northern Mariana Islands' },
	{ key: 'PR', value: 'Puerto Rico' },
	{ key: 'VI', value: 'Virgin Islands' },
];

export const statesTimezones = {
	AL: 'America/Chicago',
	AK: 'America/Anchorage',
	AZ: moment.tz(new Date(), 'America/Los_Angeles').isDST()
		? 'America/Los_Angeles'
		: 'America/Phoenix',
	AR: 'America/Chicago',
	CA: 'America/Los_Angeles',
	CO: 'America/Denver',
	CT: 'America/New_York',
	DE: 'America/New_York',
	DC: 'America/New_York',
	FL: 'America/New_York',
	GA: 'America/New_York',
	HI: 'Pacific/Honolulu',
	ID: 'America/Denver',
	IL: 'America/Chicago',
	IN: 'America/New_York',
	IA: 'America/Chicago',
	KS: 'America/Chicago',
	KY: 'America/New_York',
	LA: 'America/Chicago',
	ME: 'America/New_York',
	MD: 'America/New_York',
	MA: 'America/New_York',
	MI: 'America/New_York',
	MN: 'America/Chicago',
	MS: 'America/Chicago',
	MO: 'America/Chicago',
	MT: 'America/Denver',
	NE: 'America/Chicago',
	NV: 'America/Los_Angeles',
	NH: 'America/New_York',
	NJ: 'America/New_York',
	NM: 'America/Denver',
	NY: 'America/New_York',
	NC: 'America/New_York',
	ND: 'America/Chicago',
	OH: 'America/New_York',
	OK: 'America/Chicago',
	OR: 'America/Los_Angeles',
	PA: 'America/New_York',
	RI: 'America/New_York',
	SC: 'America/New_York',
	SD: 'America/Chicago',
	TN: 'America/Chicago',
	TX: 'America/Chicago',
	UT: 'America/Denver',
	VT: 'America/New_York',
	VA: 'America/New_York',
	WA: 'America/Los_Angeles',
	WV: 'America/New_York',
	WI: 'America/Chicago',
	WY: 'America/Denver',
	AS: 'Pacific/Pago_Pago',
	GU: 'Pacific/Guam',
	MP: 'Pacific/Guam',
	PR: 'America/Puerto_Rico',
	VI: 'America/Puerto_Rico',
};

export const USTimezones = [
	{
		gmtOffset: '(GMT-05:00)',
		name: 'Eastern Time',
		area: 'America/New_York',
	},
	{ gmtOffset: '(GMT-06:00)', name: 'Central Time', area: 'America/Chicago' },
	{ gmtOffset: '(GMT-07:00)', name: 'Mountain Time', area: 'America/Denver' },
	{
		gmtOffset: '(GMT-07:00)',
		name: 'Mountain Time - Arizona',
		area: 'America/Phoenix',
	},
	{
		gmtOffset: '(GMT-08:00)',
		name: 'Pacific Time',
		area: 'America/Los_Angeles',
	},
	{
		gmtOffset: '(GMT-09:00)',
		name: 'Alaska Standard Time',
		area: 'America/Anchorage',
	},
	{
		gmtOffset: '(GMT-04:00)',
		name: 'Atlantic Standard Time',
		area: 'America/Puerto_Rico',
	},
	{ gmtOffset: '(GMT-10:00)', name: 'Hawaii Time', area: 'Pacific/Honolulu' },
	{ gmtOffset: '(GMT+10:00)', name: 'Chamorro Time', area: 'Pacific/Guam' },
	{
		gmtOffset: '(GMT-11:00)',
		name: 'Samoa Standard Time',
		area: 'Pacific/Pago_Pago',
	},
];

export const TimezoneObjects = {
	'America/New_York': {
		gmtOffset: '(GMT-05:00)',
		name: 'Eastern Time',
		area: 'America/New_York',
	},
	'America/Chicago': {
		gmtOffset: '(GMT-06:00)',
		name: 'Central Time',
		area: 'America/Chicago',
	},
	'America/Denver': {
		gmtOffset: '(GMT-07:00)',
		name: 'Mountain Time',
		area: 'America/Denver',
	},
	'America/Phoenix': {
		gmtOffset: '(GMT-07:00)',
		name: 'Mountain Time - Arizona',
		area: 'America/Phoenix',
	},
	'America/Los_Angeles': {
		gmtOffset: '(GMT-08:00)',
		name: 'Pacific Time',
		area: 'America/Los_Angeles',
	},
	'America/Anchorage': {
		gmtOffset: '(GMT-09:00)',
		name: 'Alaska Standard Time',
		area: 'America/Anchorage',
	},
	'America/Puerto_Rico': {
		gmtOffset: '(GMT-04:00)',
		name: 'Atlantic Standard Time',
		area: 'America/Puerto_Rico',
	},
	'Pacific/Honolulu': {
		gmtOffset: '(GMT-10:00)',
		name: 'Hawaii Time',
		area: 'Pacific/Honolulu',
	},
	'Pacific/Guam': {
		gmtOffset: '(GMT+10:00)',
		name: 'Chamorro Time',
		area: 'Pacific/Guam',
	},
	'Pacific/Pago_Pago': {
		gmtOffset: '(GMT-11:00)',
		name: 'Samoa Standard Time',
		area: 'Pacific/Pago_Pago',
	},
};
export const MultiTimezonesState = {
	AK: [
		{
			gmtOffset: '(GMT-10:00)',
			name: 'Hawaii Time',
			area: 'Pacific/Honolulu',
		},
		{
			gmtOffset: '(GMT-09:00)',
			name: 'Alaska Standard Time',
			area: 'America/Anchorage',
		},
	],
	AZ: [
		{
			gmtOffset: '(GMT-07:00)',
			name: 'Mountain Time - Arizona',
			area: 'America/Phoenix',
		},
		{
			gmtOffset: '(GMT-08:00)',
			name: 'Pacific Time',
			area: 'America/Los_Angeles',
		},
	],
	FL: [
		{
			gmtOffset: '(GMT-06:00)',
			name: 'Central Time',
			area: 'America/Chicago',
		},
		{
			gmtOffset: '(GMT-05:00)',
			name: 'Eastern Time',
			area: 'America/New_York',
		},
	],
	ID: [
		{
			gmtOffset: '(GMT-08:00)',
			name: 'Pacific Time',
			area: 'America/Los_Angeles',
		},
		{
			gmtOffset: '(GMT-07:00)',
			name: 'Mountain Time',
			area: 'America/Denver',
		},
	],
	IN: [
		{
			gmtOffset: '(GMT-06:00)',
			name: 'Central Time',
			area: 'America/Chicago',
		},
		{
			gmtOffset: '(GMT-05:00)',
			name: 'Eastern Time',
			area: 'America/New_York',
		},
	],
	KS: [
		{
			gmtOffset: '(GMT-07:00)',
			name: 'Mountain Time',
			area: 'America/Denver',
		},
		{
			gmtOffset: '(GMT-06:00)',
			name: 'Central Time',
			area: 'America/Chicago',
		},
	],
	KY: [
		{
			gmtOffset: '(GMT-06:00)',
			name: 'Central Time',
			area: 'America/Chicago',
		},
		{
			gmtOffset: '(GMT-05:00)',
			name: 'Eastern Time',
			area: 'America/New_York',
		},
	],
	MI: [
		{
			gmtOffset: '(GMT-06:00)',
			name: 'Central Time',
			area: 'America/Chicago',
		},
		{
			gmtOffset: '(GMT-05:00)',
			name: 'Eastern Time',
			area: 'America/New_York',
		},
	],
	ND: [
		{
			gmtOffset: '(GMT-07:00)',
			name: 'Mountain Time',
			area: 'America/Denver',
		},
		{
			gmtOffset: '(GMT-06:00)',
			name: 'Central Time',
			area: 'America/Chicago',
		},
	],
	NE: [
		{
			gmtOffset: '(GMT-07:00)',
			name: 'Mountain Time',
			area: 'America/Denver',
		},
		{
			gmtOffset: '(GMT-06:00)',
			name: 'Central Time',
			area: 'America/Chicago',
		},
	],
	NV: [
		{
			gmtOffset: '(GMT-08:00)',
			name: 'Pacific Time',
			area: 'America/Los_Angeles',
		},
		{
			gmtOffset: '(GMT-07:00)',
			name: 'Mountain Time',
			area: 'America/Denver',
		},
	],
	OR: [
		{
			gmtOffset: '(GMT-08:00)',
			name: 'Pacific Time',
			area: 'America/Los_Angeles',
		},
		{
			gmtOffset: '(GMT-07:00)',
			name: 'Mountain Time',
			area: 'America/Denver',
		},
	],
	SD: [
		{
			gmtOffset: '(GMT-07:00)',
			name: 'Mountain Time',
			area: 'America/Denver',
		},
		{
			gmtOffset: '(GMT-06:00)',
			name: 'Central Time',
			area: 'America/Chicago',
		},
	],
	TX: [
		{
			gmtOffset: '(GMT-07:00)',
			name: 'Mountain Time',
			area: 'America/Denver',
		},
		{
			gmtOffset: '(GMT-06:00)',
			name: 'Central Time',
			area: 'America/Chicago',
		},
	],
	TN: [
		{
			gmtOffset: '(GMT-06:00)',
			name: 'Central Time',
			area: 'America/Chicago',
		},
		{
			gmtOffset: '(GMT-05:00)',
			name: 'Eastern Time',
			area: 'America/New_York',
		},
	],
};
export const issMultiTimezoneState = function (state) {
	const states = [
		'AK',
		'AZ',
		'FL',
		'ID',
		'IN',
		'KS',
		'KY',
		'MI',
		'ND',
		'NE',
		'NV',
		'OR',
		'SD',
		'TX',
		'TN',
	];
	return states.includes(state);
};
export const isTimezoneAvailableInState = (state) => {
	return !_.isNil(statesTimezones[state]);
};

export const GetStateWiseTimezone = function (state) {
	if (!state) return [];
	if (issMultiTimezoneState(state)) {
		return MultiTimezonesState[state];
	} else if (isTimezoneAvailableInState(state)) {
		const timezone = statesTimezones[state];
		return [TimezoneObjects[timezone]];
	}
	return USTimezones;
};

export const provincesList = [
	{ key: 'AB', value: 'Alberta' },
	{ key: 'BC', value: 'British Columbia' },
	{ key: 'MB', value: 'Manitoba' },
	{ key: 'NB', value: 'New Brunswick' },
	{ key: 'NL', value: 'Newfoundland and Labrador' },
	{ key: 'NS', value: 'Nova Scotia' },
	{ key: 'NT', value: 'Northwest Territories' },
	{ key: 'NU', value: 'Nunavut' },
	{ key: 'ON', value: 'Ontario' },
	{ key: 'PE', value: 'Prince Edward Island' },
	{ key: 'QC', value: 'Quebec' },
	{ key: 'SK', value: 'Saskatchewan' },
	{ key: 'YT', value: 'Yukon' },
];

export const provincesTimezones = {
	AB: 'America/Edmonton',
	BC: 'America/Vancouver',
	MB: 'America/Winnipeg',
	NB: 'America/Moncton',
	NL: 'America/St_Johns',
	NS: 'America/Halifax',
	ON: 'America/Toronto',
	PE: 'America/Halifax',
	QC: 'America/Montreal',
	SK: 'America/Regina',
	NT: 'America/Yellowknife',
	NU: 'America/Iqaluit',
	YT: 'America/Whitehorse',
};

export const multiTimezonesProvince = {
	ON: [
		{
			gmtOffset: '(GMT-05:00)',
			name: 'Eastern Time',
			area: 'America/Toronto',
		},
		{
			gmtOffset: '(GMT-06:00)',
			name: 'Central Time',
			area: 'America/Winnipeg',
		},
	],
	QC: [
		{
			gmtOffset: '(GMT-05:00)',
			name: 'Eastern Time',
			area: 'America/Toronto',
		},
		{
			gmtOffset: '(GMT-04:00)',
			name: 'Atlantic Time',
			area: 'America/Halifax',
		},
	],
	NU: [
		{
			gmtOffset: '(GMT-07:00)',
			name: 'Mountain Time',
			area: 'America/Edmonton',
		},
		{
			gmtOffset: '(GMT-05:00)',
			name: 'Eastern Time',
			area: 'America/Toronto',
		},
		{
			gmtOffset: '(GMT-06:00)',
			name: 'Central Time',
			area: 'America/Winnipeg',
		},
	],
};

export const isMultiTimezoneProvince = function (province) {
	const provinces = ['ON', 'QC', 'NU'];
	return provinces.includes(province);
};

export const isTimezoneAvailableInProvince = (province) => {
	return !_.isNil(provincesTimezones[province]);
};

export const getProvinceWiseTimezone = function (province) {
	if (!province) return [];
	if (isMultiTimezoneProvince(province)) {
		return multiTimezonesProvince[province];
	} else if (isTimezoneAvailableInProvince(province)) {
		const timezone = provincesTimezones[province];
		return [
			{
				gmtOffset: '(GMT' + moment.tz(timezone).format('Z') + ')',
				name: moment.tz(timezone).zoneName(),
				area: timezone,
			},
		];
	}
	return [];
};

export const isDisabledTimezone = (state) => {
	return !issMultiTimezoneState(state) && isTimezoneAvailableInState(state);
};

export const isDisabledTimezoneCanada = (province) => {
	return (
		!isMultiTimezoneProvince(province) &&
		isTimezoneAvailableInProvince(province)
	);
};

export const DocumentTabIndex = {
	LIST: 0,
	DETAIL: 1,
	VIEWDETAIL: 2,
};

export const TestV2TabsIndex = {
	LIST: 0,
	DETAIL: 1,
};

export const EncounterTabs = {
	LIST: 0,
	// ADD: 1,
	DETAIL: 2,
};

export const AddEncounterTypes = {
	GROUP_SESSION: 'group-session',
	CC_INTAKE: 'cc-intake',
	MULTI_PATIENT_VISIT: 'multi-patient-visit',
	MANUAL_VISIT: 'manual_visit',
};

export const updatedGenders = [
	{ value: 'Female', key: 'female' },
	{ value: 'Male', key: 'male' },
	{ value: 'Transgender - MTF', key: 'transgender - male to female' },
	{ value: 'Transgender - FTM', key: 'transgender - female to male' },
	{ value: 'Unknown - unborn child', key: 'unknown - unborn child' },
	{ value: 'Other', key: 'other' },
];

export const GetStateTimezone = function (state) {
	if (!state) return null;
	return statesTimezones[state];
};

export const GetZoneDatetime = function (moments, date, zone) {
	const date1 = moments.tz(date.format('YYYY-MM-DD HH:mm:ss'), zone);
	return date1.utc().format('YYYY-MM-DD HH:mm:ss');
};

export const checkObjectProperties = function (obj) {
	for (const key in obj) {
		if (obj[key] === null || obj[key] == '') return true;
	}
	return false;
};

export const UTCToCurrent = function (moments, date) {
	const date1 = moments.tz(date.format('YYYY-MM-DD HH:mm:ss'), 'UTC');
	return date1.local();
};

export const UTCToCurrentView = function (
	moments,
	date,
	timezone,
	hourMinute,
	format = 'MMM D, YYYY hh:mm a z'
) {
	const date1 = moments.tz(date.format('YYYY-MM-DD HH:mm:ss'), 'UTC');

	if (timezone && timezone.length > 0) {
		if (hourMinute === false)
			return moments.tz(date1, timezone).format('MMM D, YYYY');
		const date_time_zone = moments.tz(date1, timezone).format(format);
		return date_time_zone;
	} else {
		const tzone = moment.tz.guess();
		if (hourMinute === false)
			return moments.tz(date1, tzone).format('MMM D, YYYY');
		return moments.tz(date1, tzone).format(format);
	}
};

export const UTCToCurrentDateView = function (moments, date, timezone) {
	const date1 = moments.tz(date.format('YYYY-MM-DD HH:mm:ss'), 'UTC');

	if (timezone && timezone.length > 0) {
		const date_time_zone = moments
			.tz(date1, timezone)
			.format('MMM D, YYYY');
		return date_time_zone;
	} else {
		const tzone = moment.tz.guess();
		return moments.tz(date1, tzone).format('MMM D, YYYY');
	}
};

export const UTCToCurrentTimeView = function (
	moments,
	date,
	timezone,
	format = 'h:mm A z'
) {
	const date1 = moments.tz(date.format('YYYY-MM-DD HH:mm:ss'), 'UTC');

	if (timezone && timezone.length > 0) {
		const date_time_zone = moments.tz(date1, timezone).format(format);
		return date_time_zone;
	} else {
		const tzone = moment.tz.guess();
		return moments.tz(date1, tzone).format(format);
	}
};

export const UTCToCurrentViewForPatient = function (moments, date, timezone) {
	const date1 = moments.tz(date.format('YYYY-MM-DD HH:mm:ss'), 'UTC');

	if (timezone && timezone.length > 0) {
		const date_time_zone = moments
			.tz(date1, timezone)
			.format('MMM D, YYYY');
		return date_time_zone;
	} else {
		const tzone = moment.tz.guess();
		return moments.tz(date1, tzone).format('MMM D, YYYY');
	}
};

export const capitalizeFirstLetter = function (string) {
	if (string && string.length > 0) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	} else return string;
};

export const RemoveUnderscoreAndCapitalizeInString = function (string) {
	if (string in { n_a: 1, na: 1 }) return 'N/A';

	if (string && string.length > 0) {
		const string_modified = string.replace(/_/g, ' ');
		const string_arr = string_modified.split(' ');
		const string_arr_capitalized = _.map(string_arr, (e) => {
			if (!(e in { of: 1, by: 1, to: 1, in: 1, not: 1, via: 1 }))
				return _.capitalize(e);
			return e;
		});
		return string_arr_capitalized.join(' ');
	} else return string;
};

export const RemoveUnderscoreAndCapitalizeFirstLetter = function (string) {
	if (string && string.length > 0) {
		const string_modified = string.replace(/_/g, ' ');
		const string_arr = string_modified.split(' ');
		const string_arr_capitalized = _.map(string_arr, (e) => {
			if (!(e in { of: 1, by: 1, to: 1, in: 1, not: 1, via: 1 }))
				return _.upperFirst(e);
			return e;
		});
		return string_arr_capitalized.join(' ');
	} else return string;
};

export const precision = function (a) {
	if (!isFinite(a)) return 0;
	let e = 1;
	let p = 0;
	while (Math.round(a * e) / e !== a) {
		e *= 10;
		p++;
	}
	return p;
};

export const buildCsvStrFromArr = function (arr) {
	if (!arr || !Array.isArray(arr)) return '--';
	if (arr.length === 1) return arr[0];

	let str = arr[0];
	for (let i = 1; i < arr.length; i++) {
		str += ', ' + arr[i];
	}
	return str;
};

export const turnUTCIntoLocalDateTime = function (
	timestamp,
	format = 'MM/DD/YYYY hh:mm A'
) {
	if (typeof timestamp !== 'string') return timestamp;
	if (!moment(timestamp).isValid()) return timestamp;

	const momentUTC = moment.utc(timestamp);
	if (momentUTC === 'Invalid date') return timestamp;
	const localMoment = momentUTC.local().format(format);
	if (localMoment === 'Invalid date') return timestamp;
	return localMoment;
};

export const excludeConsentViaPartner = function (options, key) {
	return _.filter(options, function (o) {
		return o[key] !== 'consented_via_partner';
	});
};

export const getPatientGender = function (gender) {
	const obj = updatedGenders.find((o) => o.key == gender);
	const genderValue = _.get(obj, 'value', '');
	if (!_.isEmpty(genderValue)) {
		return genderValue;
	} else {
		if (
			!gender ||
			gender.toLowerCase() in { unknown: 1, not_known: 1, 'not known': 1 }
		)
			gender = 'Sex Unknown';
		return gender;
	}
};

export const getRegUrlFromRefProgram = function (refProgram) {
	const registrationURL = _.get(refProgram, 'registrationURL', null);
	if (registrationURL) {
		const programType = _.get(refProgram, 'programType');
		let baseStr = 'https://portal';
		const env = process.env.REACT_APP_GMI_ENV;
		if (env !== 'prod' && env !== 'local') {
			if (env === 'stage') {
				baseStr += '.staging';
			} else {
				baseStr += `.${env}`;
			}
		} else if (env === 'local') baseStr += '.dev';
		baseStr += '.genomemedical.com/';
		if (programType === 'scp') baseStr += 'scp/';
		baseStr += 'registration/';
		baseStr += registrationURL;
		return baseStr;
	} else return '--';
};

export const trimAllTextFields = function (data) {
	if (data && !Array.isArray(data) && typeof data === 'object') {
		for (const key in data) {
			if (typeof data[key] === 'string') data[key] = data[key].trim();
		}
		return data;
	} else return data;
};

export const outreach_pill_bg_color = {
	outreach_na: 'gray-pill',
	not_started: 'gray-pill',
	waiting_to_start: 'yellow-pill',
	in_progress: 'yellow-pill',
	scheduled_followup: 'green-pill',
	outreach_complete: 'green-pill',
	followup_declined: 'pink-pill',
	outreach_stopped: 'pink-pill',
	completed: 'green-pill',
};

export function determineTopDropShadow(bodyElement) {
	if (!bodyElement) return false;
	if (
		(get(bodyElement, 'scrollHeight', 0) || 0) >
		(get(bodyElement, 'clientHeight', 0) || 0)
	) {
		if ((get(bodyElement, 'scrollTop', 0) || 0) > 0) return true;
		else {
			return false;
		}
	} else {
		return false;
	}
}

export function determineBottomDropShadow(bodyElement) {
	if (!bodyElement) return false;
	if (
		(get(bodyElement, 'scrollHeight', 0) || 0) >
		(get(bodyElement, 'clientHeight', 0) || 0)
	) {
		if (
			(get(bodyElement, 'scrollTop', 0) || 0) +
				(get(bodyElement, 'clientHeight', 0) || 0) !==
			(get(bodyElement, 'scrollHeight', 0) || 0)
		) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
}

export function determineWidthOverflow(el) {
	if (_.isElement(el) && el) {
		const offsetWidth = get(el, 'offsetWidth', 0);
		const scrollWidth = get(el, 'scrollWidth', 0);
		if (scrollWidth > offsetWidth) return false;
		else return true;
	} else {
		return false;
	}
}

export function determineHeightOverflow(el) {
	if (_.isElement(el) && el) {
		const offsetHeight = get(el, 'offsetHeight', 0);
		const scrollHeight = get(el, 'scrollHeight', 0);
		if (scrollHeight > offsetHeight) return false;
		else return true;
	} else {
		return false;
	}
}

export function isExternalUser(userRole) {
	if (userRole) {
		return new Set([
			'External Provider - FULL ACCESS',
			'External Lab',
			'External Provider - READ ONLY',
		]).has(userRole);
	} else return true;
}

export function returnCcGcaCount(obj) {
	if (obj) {
		if (get(obj, 'count', null) === 0) return 0;
		else return get(obj, 'count', null) || '--';
	} else return '--';
}

export function arrayToString(data) {
	if (Array.isArray(data)) {
		if (get(data, 'length', 0)) {
			return data.map((el) => (el === null ? 'null' : el)).join(',');
		} else return null;
	} else return data;
}

export function getRemainingCharacterCount(message = '', maxLength = 280) {
	const currentLength = get(message, 'length', 0);
	const difference =
		typeof maxLength === 'number' ? maxLength - currentLength : 0;
	return `${difference} ${
		difference === 1 ? 'character' : 'characters'
	} remaining`;
}

/**
 * Selectively shows/hides an element or elements based on which environment the app is running on.
 * @param {('prod'|'stage'|'qa'|'dev'|'local')[]} maskedOn Array of strings denoting on which environments an element should be masked.
 * @returns A boolean indicating if the element should be displayed on the given environments, as denoted by the arguments given.
 */
export function shouldBeMasked(maskedOn = ['stage', 'prod']) {
	return !new Set(maskedOn).has(process.env.REACT_APP_GMI_ENV);
}

export const PaperTRFOnlyEnums = [
	{ key: true, display_name: 'Yes' },
	{ key: false, display_name: 'No' },
];

export function requiredFieldsFilled(fields) {
	if (Array.isArray(fields)) {
		for (const field of fields) {
			if (Array.isArray(field)) {
				if (get(field, 'length', 0) === 0) return false;
			} else if (typeof field === 'string') {
				if (field.trim() === '') return false;
			} else if (field === null || field === undefined || isNaN(field)) {
				return false;
			}
		}
		return true;
	} else return false;
}

export function validPhoneNumbers(phoneNumbers) {
	if (Array.isArray(phoneNumbers)) {
		for (const number of phoneNumbers) {
			if (typeof number !== 'string') return false;
			if (!isValidNumber(number)) return false;
		}
		return true;
	} else return false;
}

export function validEmails(emails) {
	if (Array.isArray(emails)) {
		for (const email of emails) {
			if (typeof email !== 'string') return false;
			if (!EmailValidator.validate(email)) return false;
		}
		return true;
	} else return false;
}

export function validZipCodes(zipCodes) {
	if (Array.isArray(zipCodes)) {
		for (const zip of zipCodes) {
			if (typeof zip !== 'string') return false;
			if (!validPostalCode.validate(zip, 'US')) return false;
		}
		return true;
	} else return false;
}

export function trimPayload(payload) {
	if (_.isPlainObject(payload) || Array.isArray(payload)) {
		for (const key in payload) {
			if (typeof payload[key] === 'string')
				payload[key] = payload[key].trim();
			else if (
				_.isPlainObject(payload[key]) ||
				Array.isArray(payload[key])
			)
				trimPayload(payload[key]);
		}
	}
	return payload;
}

export const extProviderRoles = [
	{
		value: 'primary_care_provider',
		label: 'Primary Care Provider',
		shortLabel: 'PCP',
	},
	{
		value: 'referring_provider',
		label: 'Referring Provider',
		shortLabel: 'RP',
	},
];

export function selectedExtProviderRoles(selectedValues) {
	const selectedLables = [];
	const label = (selectedValues || []).sort().map((el) => {
		const obj = el && (extProviderRoles || []).find((o) => o.value == el);
		return _.get(obj, 'shortLabel', '');
	});
	if (!_.isEmpty(label)) {
		selectedLables.push(...label);
	}
	return selectedLables;
}

export function basicUTCDateTime() {
	return moment().utc().format('YYYY-MM-DD HH:mm:ss');
}

export const contactMethods = [
	{ key: 'phone', display_name: 'Phone' },
	{ key: 'email', display_name: 'Email' },
];

export function titleFirstNameLastName(provider) {
	if (provider) {
		const title = get(provider, 'title', null)
			? get(provider, 'title', null) + ' '
			: '';
		const firstName = get(provider, 'first_name', null) || '--';
		const lastName = get(provider, 'last_name', null) || '--';
		return title + firstName + ' ' + lastName;
	} else return '--';
}

export const TestClassificationEnums = [
	{
		key: 'standard_genetic',
		display_name: 'Standard Genetic',
		label: 'Standard Genetic',
		value: 'standard_genetic',
	},
	{
		key: 'pharmacogenomic',
		display_name: 'Pharmacogenomic',
		label: 'Pharmacogenomic',
		value: 'pharmacogenomic',
	},
];

export function getFieldErrorMessage(field) {
	const type = get(field, 'type', null);
	const subType = get(field, 'subType', null);
	switch (type) {
		case 'phoneNumber':
			if (subType === 'fax') {
				return 'Fax number is invalid';
			} else {
				return 'Phone number is invalid';
			}
		case 'email':
			return 'Email is invalid';
		case 'name':
			if (subType === 'firstName') {
				return 'First name is invalid';
			} else if (subType === 'lastName') {
				return 'Last name is invalid';
			} else return 'Name is invalid';
		case 'address':
			if (subType === 'addressLine') {
				return 'Address line is invalid';
			} else if (subType === 'city') {
				return 'City is invalid';
			} else if (subType === 'zip') {
				return 'Zip is invalid';
			} else return 'Invalid value';
		case 'number':
			if (subType === 'NPI') {
				return 'NPI number should be 10 digits';
			} else return 'Invalid number';
		default:
			return 'Invalid value';
	}
}

export function fullFieldsValidation(fields) {
	if (Array.isArray(fields)) {
		const invalidFields = {};
		fields.forEach((field) => {
			const type = get(field, 'type', null);
			const value = get(field, 'value', null);
			const header = get(field, 'header', null);
			const subType = get(field, 'subType', null);
			const length = get(field, 'length', null);
			const errorMessage = getFieldErrorMessage(field);
			let error = false;
			if (type && header && value && typeof value === 'string') {
				switch (type) {
					case 'phoneNumber':
						if (!isValidNumber(value)) {
							error = true;
						}
						break;
					case 'email':
						if (!EmailValidator.validate(value)) {
							error = true;
						}
						break;
					case 'name':
						if (!value.match(/^(?:'?[A-z0-9]+[-. ']?)+$/)) {
							error = true;
						}
						break;
					case 'address':
						if (subType === 'zip') {
							if (!validPostalCode.validate(value, 'US')) {
								error = true;
							}
						} else {
							if (
								!value.match(
									/((?:[A-Za-z0-9-— '/,.()!@$%#]+$)*[a-zA-Z0-9])/
								)
							) {
								error = true;
							}
						}
						break;
					case 'number':
						if (!value.match(/^[0-9]+$/)) {
							error = true;
						} else {
							if (length && value.length !== length) error = true;
						}
				}
				if (error && header && errorMessage) {
					invalidFields[header] = errorMessage;
				}
			}
		});
		return invalidFields;
	} else return {};
}

export function displaySpecificTimeZoneLocalTime(timezone) {
	if (!_.isEmpty(timezone)) {
		return moment().tz(timezone).format('hh:mm A') + ' Local Time';
	}
	return '--';
}

export function UTCDateToFormattedDate(date, altMessage) {
	if (!_.isEmpty(date) && moment(date).isValid()) {
		return moment(date).format('MMM DD, YYYY');
	} else {
		return altMessage || '--';
	}
}

export function displayAge(dob) {
	if (!_.isEmpty(dob) && moment(dob).isValid()) {
		return moment().diff(moment(dob), 'years') + ' yrs old';
	} else {
		return 'Age Unknown';
	}
}

export function objHasArray(obj) {
	let hasArray = false;
	if (_.isObject(obj)) {
		for (const key in obj) {
			if (Array.isArray(obj[key])) hasArray = true;
		}
	}
	return hasArray;
}

export function getNestedArray(obj) {
	if (_.isObject(obj)) {
		for (const key in obj) {
			if (Array.isArray(obj[key])) return obj[key];
		}
		return null;
	} else return null;
}

export function getReasonTagText(tag) {
	if (get(tag, 'name', null) === 'priority_patient') {
		const tagProperties = get(tag, 'tagProperties', null);
		const reason = get(tagProperties, '[0].reason', null);
		if (reason) {
			return Array.isArray(reason) ? reason.join(', ') : `${reason}`;
		} else {
			return 'Priority';
		}
	} else return '--';
}

export function getLanguageTagText(tag) {
	if (get(tag, 'name', null) === 'language_line_support') {
		const tagProperties = get(tag, 'tagProperties', null);
		const language = get(tagProperties, '[0].language', null);
		if (language) {
			return `${language}`;
		} else {
			return 'Language';
		}
	} else return '--';
}

export const StandradConsultation = [
	{
		key: 'Get Started - w/Results',
		label: 'Get Started - w/Results',
		value: 'Get Started - w/Results',
	},
	{
		key: 'Get Started - No Results',
		label: 'Get Started - No Results',
		value: 'Get Started - No Results',
	},
	{
		key: 'Return of Results',
		label: 'Return of Results',
		value: 'Return of Results',
	},
];

export const addressTypes = [
	{ display_name: 'Shipping', key: 'delivery' },
	{ display_name: 'Billing', key: 'billing' },
	{ display_name: 'Home', key: 'home' },
];

export const addressTypeConst = {
	shipping: 'delivery',
	billing: 'billing',
	home: 'home',
};

export const outreachTypes = {
	referralOutreach: 'referral_outreach',
};

export const referralOutreachStatuses = {
	inProgress: 'in_progress',
	followupRequired: 'followup_required',
	outreachCompleted: 'outreach_completed',
};

export const referralOutreachSubstatuses = {
	notScheduled: 'not_scheduled',
	scheduled: 'scheduled',
	doNotOutreach: 'do_not_outreach',
};

export const outreachOutcomes = {
	leftVoicemail: 'left_voicemail',
	unableToLeaveVoicemail: 'unable_to_leave_voicemail',
	patientCallback: 'patient_call_back',
	patientDeclined: 'patient_declined',
	other: 'other',
};

export function determineHasAllAddressTypes(addresses) {
	if (Array.isArray(addresses)) {
		const addressSet = new Set();
		addresses.forEach((address) => {
			const patientAddressTypes = get(address, 'type', null) || [];
			if (Array.isArray(patientAddressTypes)) {
				patientAddressTypes.forEach((type) => addressSet.add(type));
			}
		});
		return addressTypes.every((type) =>
			addressSet.has(get(type, 'key', null))
		);
	}
	return false;
}

export function getExistingAddressTypes(addresses, idx) {
	if (Array.isArray(addresses)) {
		const addressSet = new Set();
		addresses.forEach((address, i) => {
			if (i === idx) return;
			const patientAddressTypes = get(address, 'type', null) || [];
			if (Array.isArray(patientAddressTypes)) {
				patientAddressTypes.forEach((type) => addressSet.add(type));
			}
		});
		return Array.from(addressSet);
	}
	return [];
}

export function singleAddressIsBilling(address) {
	if (address) {
		const types = get(address, 'type', null) || [];
		if (Array.isArray(types)) {
			return types.includes('billing');
		} else return false;
	} else return false;
}

export function getHomeAddress(addresses) {
	if (addresses && Array.isArray(addresses)) {
		const homeAddress = _.find(addresses, (address) => {
			const types = get(address, 'type', null) || [];
			if (Array.isArray(types)) return types.includes('home');
		});
		return homeAddress || null;
	} else return null;
}

export function getShippingAddress(addresses) {
	if (addresses && Array.isArray(addresses)) {
		const shippingAddress = _.find(addresses, (address) => {
			const types = get(address, 'type', null) || [];
			if (Array.isArray(types)) return types.includes('delivery');
		});
		return shippingAddress || null;
	} else return null;
}

export function getHomeState(addresses) {
	if (addresses && Array.isArray(addresses)) {
		return get(getHomeAddress(addresses), 'state', null);
	} else return null;
}

export function getShippingState(addresses) {
	if (addresses && Array.isArray(addresses)) {
		return get(getShippingAddress(addresses), 'state', null);
	} else return null;
}

export function mapAddressErrors(patientAddresses, errors) {
	if (Array.isArray(patientAddresses) && errors) {
		const addressErrors = [];
		const addresses = _.cloneDeep(patientAddresses);
		addresses.forEach((address, addressIdx) => {
			const addressErrorObj = {};
			for (const type in errors) {
				const typeArr = type.split('-').sort();
				let addressTypes = get(address, 'type', null) || [];
				if (Array.isArray(addressTypes))
					addressTypes = addressTypes.sort();
				if (_.isEqual(addressTypes, typeArr)) {
					for (const field in errors[type]) {
						const message =
							Object.values(errors[type][field])[0] ||
							'Invalid value';
						addressErrorObj[field] = message;
					}
					addressErrors[addressIdx] = addressErrorObj;
				}
			}
		});
		return addressErrors;
	} else return null;
}

export const createHomeAddressMapping = {
	address_line1: 'createAddress1',
	address_line2: 'createAddress2',
	city: 'createCity',
	state: 'createState',
	zip: 'createZip',
};

export const createShippingAddressMapping = {
	address_line1: 'createAddress1Shipping',
	address_line2: 'createAddress2Shipping',
	city: 'createCityShipping',
	state: 'createStateShipping',
	zip: 'createZipShipping',
};

export const currentAddressKey = 'address';

export function getFromEnumOrFormat(
	enumArr,
	objValue,
	internalKeyName = 'value',
	externalKeyName = 'display_name'
) {
	if (Array.isArray(enumArr) && objValue) {
		const matchedValue = _.find(
			enumArr,
			(enumValue) => enumValue[internalKeyName] === objValue
		);
		if (matchedValue) return matchedValue[externalKeyName];
		else return RemoveUnderscoreAndCapitalizeInString(objValue);
	} else return '--';
}

//TODO: remove once integrated with BE(EHR-9234/EHR-9232)
export const primaryPredictedOrignEnum = [
	{ display_name: 'Anus', key: 'GM:00000035' },
	{ display_name: 'Bladder, urothelial tract', key: 'GM:00000036' },
	{ display_name: 'Bone and soft tissue', key: 'GM:00000038' },
	{ display_name: 'Breast', key: 'GM:00000039' },
	{ display_name: 'Cervix', key: 'GM:00000040' },
	{ display_name: 'Colon, rectum', key: 'GM:00000041' },
	{ display_name: 'Head and Neck', key: 'GM:00000043' },
	{ display_name: 'Hematopoietic and Lymphoid Organs', key: 'GM:00000064' },
	{ display_name: 'Kidney', key: 'GM:00000044' },
	{ display_name: 'Liver, bile duct', key: 'GM:00000045' },
	{ display_name: 'Lung', key: 'GM:00000046' },
	{ display_name: 'Lymphoid lineage', key: 'GM:00000047' },
	{
		display_name: 'Melanocyte-containing tissues / Skin',
		key: 'GM:00000063',
	},
	{ display_name: 'Melanocytic lineage', key: 'GM:00000048' },
	{ display_name: 'Myeloid lineage', key: 'GM:00000049' },
	{
		display_name: 'Neuroendocrine cells of lung or other organs',
		key: 'GM:00000050',
	},
	{ display_name: 'Ovary', key: 'GM:00000051' },
	{ display_name: 'Pancreas, gallbladder', key: 'GM:00000052' },
	{ display_name: 'Plasma cell lineage', key: 'GM:00000054' },
	{ display_name: 'Prostate', key: 'GM:00000055' },
	{ display_name: 'Stomach, esophagus', key: 'GM:00000056' },
	{ display_name: 'Thyroid gland', key: 'GM:00000058' },
	{ display_name: 'Uterus', key: 'GM:00000059' },
];

//TODO: remove once integrated with BE(EHR-9234/EHR-9232)
export const secondaryPredictedOrignEnum = [
	{ display_name: 'Anus', key: 'GM:00000035' },
	{ display_name: 'Bladder, urothelial tract', key: 'GM:00000036' },
	{ display_name: 'Bone and soft tissue', key: 'GM:00000038' },
	{ display_name: 'Breast', key: 'GM:00000039' },
	{ display_name: 'Cervix', key: 'GM:00000040' },
	{ display_name: 'Colon, rectum', key: 'GM:00000041' },
	{ display_name: 'Head and Neck', key: 'GM:00000043' },
	{ display_name: 'Hematopoietic and Lymphoid Organs', key: 'GM:00000064' },
	{ display_name: 'Kidney', key: 'GM:00000044' },
	{ display_name: 'Liver, bile duct', key: 'GM:00000045' },
	{ display_name: 'Lung', key: 'GM:00000046' },
	{ display_name: 'Lymphoid lineage', key: 'GM:00000047' },
	{
		display_name: 'Melanocyte-containing tissues / Skin',
		key: 'GM:00000063',
	},
	{ display_name: 'Melanocytic lineage', key: 'GM:00000048' },
	{ display_name: 'Myeloid lineage', key: 'GM:00000049' },
	{
		display_name: 'Neuroendocrine cells of lung or other organs',
		key: 'GM:00000050',
	},
	{ display_name: 'Ovary', key: 'GM:00000051' },
	{ display_name: 'Pancreas, gallbladder', key: 'GM:00000052' },
	{ display_name: 'Plasma cell lineage', key: 'GM:00000054' },
	{ display_name: 'Prostate', key: 'GM:00000055' },
	{ display_name: 'Stomach, esophagus', key: 'GM:00000056' },
	{ display_name: 'Thyroid gland', key: 'GM:00000058' },
	{ display_name: 'Uterus', key: 'GM:00000059' },
];

export const geneticCriteriaEnum = [
	{
		internal: 'meets_criteria',
		external: 'Yes',
	},
	{
		internal: 'does_not_meet_criteria',
		external: 'No',
	},
];
export const lab_order_integration_options = [
	{ key: true, display_name: 'Yes' },
	{ key: false, display_name: 'No' },
];

export const nomenclatureRadioEnum = [
	{ key: 'HGVS', display_name: 'HGVS' },
	{ key: 'ISCN', display_name: 'ISCN' },
];

export const structuralNomenclatureRadioEnum = [
	{ key: 'geneticExtents', display_name: 'Segmental - Genetic' },
	{ key: 'chromosomalExtents', display_name: 'Segmental - Chromosomal' },
	{ key: 'ISCN', display_name: 'Cytogenomic (ISCN)' },
];
export const allelicStateEnum = [
	{ key: 'HOMOZYGOUS', display_name: 'Homozygous' },
	{ key: 'HETEROZYGOUS', display_name: 'Heterozygous' },
	{ key: 'HEMIZYGOUS', display_name: 'Hemizygous' },
	{ key: 'HOMOPLASMIC', display_name: 'Homoplasmic' },
	{ key: 'HETEROPLASMIC', display_name: 'Heteroplasmic' },
];

export const referenceAssemblyEnum = [
	{ key: 'GRCh37 (hg19)', display_name: 'GRCh37 (hg19)' },
	{ key: 'GRCh38 (hg38)', display_name: 'GRCh38 (hg38)' },
	{ key: 'NCBI Build 36.1 (hg18)', display_name: 'NCBI Build 36.1 (hg18)' },
	{ key: 'NCBI Build 35 (hg17)', display_name: 'NCBI Build 35 (hg17)' },
	{ key: 'NCBI Build 34 (hg16)', display_name: 'NCBI Build 34 (hg16)' },
];

export const assessmentEnum = [
	{ key: 'PRESENT', display_name: 'Present' },
	{ key: 'ABSENT', display_name: 'Absent' },
	{ key: 'INDETERMINATE', display_name: 'Indeterminate' },
];

export const labClassificationEnum = [
	{ key: 'BENIGN', display_name: 'Benign' },
	{ key: 'LIKELY_BENIGN', display_name: 'Likely Benign' },
	{ key: 'UNCERTAIN_SIGNIFICANCE', display_name: 'Uncertain Significance' },
	{ key: 'LIKELY_PATHOGENIC', display_name: 'Likely Pathogenic' },
	{ key: 'PATHOGENIC', display_name: 'Pathogenic' },
	{ key: 'INCREASED_RISK', display_name: 'Increased Risk' },
	{ key: 'PROTECTIVE', display_name: 'Protective' },
	{ key: 'BENIGN_REPORTABLE', display_name: 'Benign Reportable' },
	{ key: 'PREMUTATION_ALLELE', display_name: 'Premutation Allele' },
	{ key: 'INTERMEDIATE_ALLELE', display_name: 'Intermediate Allele' },
	{ key: 'OTHER', display_name: 'Other' },
];

export const labClassificationNucleotideVariantEnum = [
	{ key: 'NORMAL', display_name: 'Normal' },
	{
		key: 'INTERMEDIATE_MUTATION',
		display_name: 'Intermediate/Gray Mutation',
	},
	{ key: 'PREMUTATION', display_name: 'Premutation' },
	{ key: 'FULL_MUTATION', display_name: 'Positive/Full Mutation' },
];

export const allelePreSetEnum = [
	{ key: 'EXPANDED', display_name: 'Expanded' },
	{ key: 'NORMAL', display_name: 'Normal' },
	{ key: 'REDUCED', display_name: 'Reduced' },
];

export const dnaChangeTypeEnum = [
	{ key: 'WILD_TYPE', display_name: 'Wild type' },
	{ key: 'DELETION', display_name: 'Deletion' },
	{ key: 'DUPLICATION', display_name: 'Duplication' },
	{ key: 'INSERTION', display_name: 'Insertion' },
	{ key: 'INSERTION_AND_DELETION', display_name: 'Insertion and deletion' },
	{ key: 'INVERSION', display_name: 'Inversion' },
	{ key: 'SUBSTITUTION', display_name: 'Substitution' },
];

export const proteinChangeTypeEnum = [
	{ key: 'WILD_TYPE', display_name: 'Wild type' },
	{ key: 'DELETION', display_name: 'Deletion' },
	{ key: 'DUPLICATION', display_name: 'Duplication' },
	{ key: 'FRAMESHIFT', display_name: 'Frameshift' },
	{ key: 'INTRONIC', display_name: 'Intronic' },
	{ key: 'INITIATING_METHIONINE', display_name: 'Initiating methionine' },
	{ key: 'INSERTION', display_name: 'Insertion' },
	{ key: 'INSERTION_AND_DELETION', display_name: 'Insertion and deletion' },
	{ key: 'MISSENSE', display_name: 'Missense' },
	{ key: 'NONSENSE', display_name: 'Nonsense' },
	{ key: 'SILENT', display_name: 'Silent' },
	{ key: 'SPLICE_SITE', display_name: 'Splice site' },
	{ key: 'STOP_CODON_MUTATION', display_name: 'Stop codon mutation' },
];

export const genomicSourceClassEnum = [
	{ key: 'GERMLINE', display_name: 'Germline' },
	{ key: 'SOMATIC', display_name: 'Somatic' },
	{ key: 'FETAL', display_name: 'Fetal' },
	{ key: 'LIKELY_GERMLINE', display_name: 'Likely germline' },
	{ key: 'LIKELY_SOMATIC', display_name: 'Likely somatic' },
	{ key: 'LIKELY_FETAL', display_name: 'Likely fetal' },
	{ key: 'UNKNOWN', display_name: 'Unknown' },
];

export const originEnum = [
	{ key: 'MATERNAL', display_name: 'Maternal' },
	{ key: 'PATERNAL', display_name: 'Paternal' },
	{ key: 'DE_NOVO', display_name: 'De novo' },
	{ key: 'UNKNOWN', display_name: 'Unknown' },
];

export const mosaicismEnum = [
	{ key: 'DETECTED', display_name: 'Detected' },
	{ key: 'SUSPECTED', display_name: 'Suspected' },
	{ key: 'NOT_DETECTED', display_name: 'Not Detected' },
];

export function getNotesEnum(isExternalUser) {
	const notesEnum = [
		{ key: 'internal', display_name: 'Internal Note', internalOnly: true },
		{ key: 'billing', display_name: 'Billing Note', internalOnly: true },
		{ key: 'provider', display_name: 'Provider Note', internalOnly: false },
	];
	return isExternalUser
		? notesEnum.filter((notesObj) => !notesObj.internalOnly)
		: notesEnum;
}

export const structuralVariantUnitEnum = [
	{ key: 'M[base]', display_name: 'Mb' },
	{ key: 'k[base]', display_name: 'kb' },
];

export const structuralVariantTypeEnum = [
	{ key: 'DELETION', display_name: 'Deletion' },
	{ key: 'DUPLICATION', display_name: 'Duplication' },
	{ key: 'TRIPLICATION', display_name: 'Triplication' },
	{ key: 'AREA_OF_HOMOZYGOSITY', display_name: 'Area of Homozygosity' },
	{ key: 'BALANCED_TRANSLOCATION', display_name: 'Balanced Translocation' },
	{
		key: 'UNBALANCED_TRANSLOCATION',
		display_name: 'Unbalanced Translocation',
	},
	{
		key: 'ROBERTSONIAN_TRANSLOCATION',
		display_name: 'Robertsonian Translocation',
	},
	{ key: 'PERICENTRIC_INVERSION', display_name: 'Pericentric Inversion' },
	{ key: 'PARACENTRIC_INVERSION', display_name: 'Paracentric Inversion' },
	{ key: 'TRISOMY', display_name: 'Trisomy' },
	{ key: 'MONOSOMY', display_name: 'Monosomy' },
	{ key: 'TRIPLOIDY', display_name: 'Triploidy' },
	{ key: 'RING_CHROMOSOME', display_name: 'Ring Chromosome' },
	{ key: 'MARKER_CHROMOSOME', display_name: 'Marker Chromosome' },
];

export const chromosomeEnum = [
	{ key: '1', display_name: '1' },
	{ key: '2', display_name: '2' },
	{ key: '3', display_name: '3' },
	{ key: '4', display_name: '4' },
	{ key: '5', display_name: '5' },
	{ key: '6', display_name: '6' },
	{ key: '7', display_name: '7' },
	{ key: '8', display_name: '8' },
	{ key: '9', display_name: '9' },
	{ key: '10', display_name: '10' },
	{ key: '11', display_name: '11' },
	{ key: '12', display_name: '12' },
	{ key: '13', display_name: '13' },
	{ key: '14', display_name: '14' },
	{ key: '15', display_name: '15' },
	{ key: '16', display_name: '16' },
	{ key: '17', display_name: '17' },
	{ key: '18', display_name: '18' },
	{ key: '19', display_name: '19' },
	{ key: '20', display_name: '20' },
	{ key: '21', display_name: '21' },
	{ key: '22', display_name: '22' },
	{ key: 'X', display_name: 'X' },
	{ key: 'Y', display_name: 'Y' },
];

export const fetalSexEnum = [
	{ key: 'GM:00000061', display_name: 'Y DNA detected (male)' },
	{ key: 'GM:00000062', display_name: 'Y DNA not detected (female)' },
	{ key: '', display_name: 'Sex chromosomes not reported' },
];

export const cfDNAPrenatalRiskRadioEnum = [
	{ key: 'GM:00000106', display_name: 'High' },
	{ key: 'GM:00000108', display_name: 'Unchanged' },
	{ key: 'GM:00000107', display_name: 'Low' },
	{ key: 'not_tested', display_name: 'Not Tested' },
];

export const priorRiskItemEnum = {
	'gm:00000069': 1,
	'gm:00000072': 1,
	'gm:00000073': 1,
	'gm:00000074': 1,
	'gm:00000071': 1,
	'gm:00000075': 1,
	'gm:00000076': 1,
	'gm:00000077': 1,
	'gm:00000078': 1,
	'gm:00000079': 1,
	'gm:00000080': 1,
	'gm:00000081': 1,
	'gm:00000082': 1,
	'gm:00000083': 1,
	'gm:00000084': 1,
	'gm:00000085': 1,
	'gm:00000086': 1,
};

export const residualRiskItemEnum = {
	'gm:00000121': 1,
	'gm:00000123': 1,
	'gm:00000124': 1,
	'gm:00000125': 1,
	'gm:00000122': 1,
	'gm:00000120': 1,
	'gm:00000114': 1,
	'gm:00000116': 1,
	'gm:00000117': 1,
	'gm:00000118': 1,
	'gm:00000112': 1,
	'gm:00000113': 1,
	'gm:00000115': 1,
	'gm:00000119': 1,
	'gm:00000127': 1,
	'gm:00000111': 1,
	'gm:00000126': 1,
};

export const PatientConsentStatus = {
	SKIPPED: 'skipped',
	CONSENTED: 'consented',
	REVOKED: 'revoked',
	PROCESSING: 'processing',
};

// Wraps notistack's enqueueSnackbar() with some default values
export function enqueueSnackbar(message, options) {
	notiEnqueueSnackbar(message, {
		...options,
		variant: options?.variant ?? 'error',
		anchorOrigin: options?.anchorOrigin ?? {
			horizontal: 'right',
			vertical: 'bottom',
		},
	});
}

export const PATIENT_TAG = {
	GUARDIAN: 'guardian',
	NO_OUTREACH: 'noOutreach',
	DECEASED: 'deceased',
	PRIORITY_PATIENT: 'priority_patient',
	PRIORITY: 'priority',
	PATIENT_STORY: 'patient_story',
	DISCHARGED: 'discharged',
	VISION_IMPAIRED: 'vision_impaired',
	HEARING_IMPAIRED: 'hearing_impaired',
	SPEECH_IMPAIRED: 'speech_impaired',
	FINANCIAL_ASSISTANCE: 'financial_assistance',
	FINANCIAL_ASSISTANCE_PER_VISIT: 'financial_assistance_per_visit',
	LANGUAGE_LINE_SUPPORT: 'language_line_support',
	VIP_PATIENT: 'vip_patient',
	MARKETING_OPT_OUT: 'marketing_opt-out',
	RESEARCH_OPT_OUT: 'research_opt-out',
	NO_FURTHER_ESCALATION: 'no_further_escalation',
	DUPLICATE: 'duplicate',
	FEATURE: 'feature',
	ENHANCEMENT: 'enhancement',
};

export const PATIENT_TAG_DEFINITION = {
	[PATIENT_TAG.GUARDIAN]: {
		text: 'Guardian/ PoA',
		bgColor: '#9fdcf5',
		textColor: '#262837',
	},
	[PATIENT_TAG.NO_OUTREACH]: {
		text: 'Do Not Outreach',
		bgColor: '#f4a4a5',
		textColor: '#262837',
	},
	[PATIENT_TAG.DECEASED]: {
		text: 'Deceased',
		bgColor: '#f4a4a5',
		textColor: '#262837',
	},
	[PATIENT_TAG.PRIORITY]: {
		text: 'Priority',
		bgColor: '#fddc3f',
		textColor: '#262837',
		customTextPrefix: 'Priority: ',
	},
	[PATIENT_TAG.PRIORITY_PATIENT]: {
		text: 'Priority',
		bgColor: '#fddc3f',
		textColor: '#262837',
		customTextPrefix: 'Priority: ',
	},
	[PATIENT_TAG.PATIENT_STORY]: {
		text: 'Patient Story',
		bgColor: '#bac3ce',
		textColor: '#262837',
	},
	[PATIENT_TAG.DISCHARGED]: {
		text: 'Discharged',
		bgColor: '#f4a4a5',
		textColor: '#262837',
	},
	[PATIENT_TAG.VISION_IMPAIRED]: {
		text: 'Vision Impaired',
		bgColor: '#ffcb9f',
		textColor: '#262837',
	},
	[PATIENT_TAG.HEARING_IMPAIRED]: {
		text: 'Hearing Impaired',
		bgColor: '#ffcb9f',
		textColor: '#262837',
	},
	[PATIENT_TAG.SPEECH_IMPAIRED]: {
		text: 'Speech Impaired',
		bgColor: '#ffcb9f',
		textColor: '#262837',
	},
	[PATIENT_TAG.FINANCIAL_ASSISTANCE]: {
		text: 'Fin. Assist.',
		bgColor: '#b6d98a',
		textColor: '#262837',
	},
	[PATIENT_TAG.FINANCIAL_ASSISTANCE_PER_VISIT]: {
		text: 'Fin. Assist. Per visit',
		bgColor: '#b6d98a',
		textColor: '#262837',
	},
	[PATIENT_TAG.LANGUAGE_LINE_SUPPORT]: {
		text: 'Language',
		bgColor: '#ffcb9f',
		textColor: '#262837',
		customTextPrefix: 'Language: ',
	},
	[PATIENT_TAG.VIP_PATIENT]: {
		text: 'VIP Patient',
		bgColor: '#bac3ce',
		textColor: '#262837',
	},
	[PATIENT_TAG.MARKETING_OPT_OUT]: {
		text: 'Marketing Opt-Out',
		bgColor: '#bac3ce',
		textColor: '#262837',
	},
	[PATIENT_TAG.RESEARCH_OPT_OUT]: {
		text: 'Research Opt-Out',
		bgColor: '#bac3ce',
		textColor: '#262837',
	},
	[PATIENT_TAG.NO_FURTHER_ESCALATION]: {
		text: 'No Further Escalation',
		bgColor: '#f4a4a5',
		textColor: '#262837',
	},
	[PATIENT_TAG.DUPLICATE]: {
		text: 'Duplicate Record',
		bgColor: '#bac3ce',
		textColor: '#262837',
	},
	[PATIENT_TAG.FEATURE]: {
		text: 'Feature',
		bgColor: '#a2ffad',
		textColor: '#262837',
	},
	[PATIENT_TAG.ENHANCEMENT]: {
		text: 'Enhancement',
		bgColor: '#ffcb9f',
		textColor: '#262837',
	},
};
