import { Formik } from 'formik';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { submitnotes } from '../../../../actions/notes';
import { uploadFile } from '../../../../actions/documents';
import {
	patientdetail,
	getLinkedProviders,
	addpatient,
	linkProviders,
	unlinkProvider,
	updateLinkedProvider,
} from '../../../../actions/patients';
import { getencounters } from '../../../../actions/encounter';
import { Page } from '../../../Common/V3/Page';
import { Header } from '../../../Common/V3/Header';
import { ModalWindow } from '../../../Common/V3/ModalWindow';

import PatientService from '../../../../service/Patients';

import { Footer } from './components/Footer';
import {
	AdditionalPatientInfo,
	ExternalCareTeam,
	NotesAndDocuments,
	PatientDemographicsAndContact,
	ServiceAndProgram,
} from './sections';

import './page.scss';

import { initialValues, validateForm } from './validation';
import {
	formToApiPayload,
	formToAddNotesApiPayload,
	formToUploadDocumentsApiPayload,
	formToProvidersApiPayload,
	formToInsuranceApiPayload,
	formToProviderUpdateApiPayload,
} from './api';

export const CreatePatientPage = (props) => {
	const [patientCreationError, setPatientCreationError] = useState(null);
	const dispatch = useDispatch();

	const breadcrumbsItems = props.location?.state?.breadcrumbs;
	const lastBreadcrumbItem =
		breadcrumbsItems?.[breadcrumbsItems.length - 1] ?? null;
	const isEdit = lastBreadcrumbItem?.state?.edit ?? false;
	const existingPatientUUID = useMemo(
		() => (isEdit ? (lastBreadcrumbItem?.state?.patientUuid ?? '') : ''),
		[isEdit, lastBreadcrumbItem]
	);

	useEffect(() => {
		if (existingPatientUUID) {
			dispatch(patientdetail(existingPatientUUID));
			dispatch(getLinkedProviders(existingPatientUUID));
			dispatch(
				getencounters({
					id: existingPatientUUID,
					offset: 0,
					limit: -1,
				})
			);
		}
	}, [existingPatientUUID]);

	const { defaultProviders, patientDetail, linkedProviders } = useSelector(
		(store) => ({
			defaultProviders:
				store?.referralProgramsList?.loadedReferralProgramDetail
					?.defaultProviders,
			linkedProviders: store?.linkedpatientproviders?.linkedProviders,
			patientDetail: store?.patientdetail?.patientdetail[0],
		})
	);

	const doLinkProviders = (values, patientUUID) => {
		const { externalProviders } = values.externalCareTeam;

		if (isEdit) {
			const providersToLink = externalProviders.filter(
				(provider) =>
					!linkedProviders
						.map((p) => p.providerUUID)
						.includes(provider.providerUUID)
			);

			const providersToUpdate = externalProviders.filter((provider) =>
				linkedProviders
					.map((p) => p.providerUUID)
					.includes(provider.providerUUID)
			);

			const providersToUnlink = linkedProviders.filter(
				(provider) =>
					!externalProviders
						.map((p) => p.providerUUID)
						.includes(provider.providerUUID)
			);

			if (providersToLink.length > 0) {
				dispatch(
					linkProviders(
						patientUUID,
						formToProvidersApiPayload(providersToLink)
					)
				);
			}

			if (providersToUnlink.length > 0) {
				providersToUnlink.forEach((provider) => {
					dispatch(
						unlinkProvider(patientUUID, provider.providerUUID)
					);
				});
			}

			if (providersToUpdate.length > 0) {
				providersToUpdate.forEach((provider) => {
					dispatch(
						updateLinkedProvider(
							patientUUID,
							provider.providerUUID,
							formToProviderUpdateApiPayload(provider)
						)
					);
				});
			}
		} else {
			if (
				(externalProviders && externalProviders.length > 0) ||
				(defaultProviders && defaultProviders.length > 0)
			) {
				dispatch(
					linkProviders(
						patientUUID,
						formToProvidersApiPayload(
							externalProviders,
							defaultProviders
						)
					)
				);
			}
		}
	};

	const doSubmitProviderNotes = (enteredProviderNote, patientUUID) => {
		if (enteredProviderNote && enteredProviderNote.length > 0) {
			dispatch(
				submitnotes(
					formToAddNotesApiPayload(
						enteredProviderNote,
						'provider',
						props.me.user.email,
						patientUUID
					)
				)
			);
		}
	};

	const doSubmitInternalNotes = (enteredInternalNote, patientUUID) => {
		if (enteredInternalNote && enteredInternalNote.length > 0) {
			dispatch(
				submitnotes(
					formToAddNotesApiPayload(
						enteredInternalNote,
						'internal',
						props.me.user.email,
						patientUUID
					)
				)
			);
		}
	};

	const doUploadDocuments = (documentData, patientUUID) => {
		if (documentData) {
			documentData.forEach((formData) => {
				dispatch(
					uploadFile(
						formToUploadDocumentsApiPayload(formData, patientUUID)
					)
				);
			});
		}
	};

	const errorMessage = (obj) => {
		try {
			if (obj && typeof obj === 'object') {
				const fieldValue = Object.values(obj.fields || {})
					.flatMap((value) =>
						typeof value === 'object' ? Object.values(value) : value
					)
					.filter((value) => typeof value === 'string')
					.join(' ');

				if (fieldValue) {
					return fieldValue;
				} else if (obj.message) {
					return obj.message;
				}
			}
			if (obj && typeof obj == 'string') {
				return obj;
			}
			return 'Invalid Error';
		} catch (error) {
			return 'Invalid Error';
		}
	};

	async function handleSubmit(values) {
		let patientUUID = existingPatientUUID || null;
		let addPatientResponse;
		const additionalErrors = [];

		try {
			if (patientUUID) {
				await PatientService.updatePatient(
					patientUUID,
					formToApiPayload(values)
				);
				if (values.additionalPatientInfo?.healthInsurance) {
					await PatientService.saveInsurance(
						formToInsuranceApiPayload(values),
						patientUUID
					);
				}
			} else {
				addPatientResponse = await dispatch(
					addpatient(formToApiPayload(values))
				);
				patientUUID = addPatientResponse?.data?.uuid;
			}
		} catch (e) {
			let error = e.message;
			if (e.response?.data?.message) {
				if (e.response.data.message?.message) {
					error = e.response.data.message.message.toString();
				} else {
					error = e.response.data.message.toString();
				}
			}
			if (
				e.response?.data?.fields?.email?.unique ==
				'This email has already been used.'
			) {
				error = e.response.data.fields.email.unique.toString();
			}
			setPatientCreationError(errorMessage(error));
			throw e.response?.data;
		}

		const additionalRequests = [doLinkProviders(values, patientUUID)];

		if (!existingPatientUUID) {
			const { enteredProviderNote, enteredInternalNote, documentData } =
				values.notesAndDocuments;

			additionalRequests.push(
				doSubmitProviderNotes(enteredProviderNote, patientUUID),
				doSubmitInternalNotes(enteredInternalNote, patientUUID),
				doUploadDocuments(documentData, patientUUID)
			);
		}

		const additionalResults = await Promise.allSettled(additionalRequests);
		additionalResults.forEach((result) => {
			if (result.status === 'rejected') {
				additionalErrors.push(result.reason);
			}
		});

		if (additionalErrors.length > 0) {
			props.enqueueSnackbar(
				'Patient created, some data could not be saved: ' +
					additionalErrors.join(', '),
				{
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				}
			);
			throw additionalErrors;
		}

		return patientUUID;
	}

	return (
		<Page className='createPatientV3'>
			{patientCreationError && (
				<ModalWindow
					onCancel={() => setPatientCreationError(null)}
					title='Patient Creation error'
				>
					<p>
						We were unable to create this patient because of the
						following reason:
					</p>
					<p>{patientCreationError}</p>
					<p>Please contact your support team for assistance</p>
				</ModalWindow>
			)}
			<div className='box patientCreateV3Container'>
				{
					<Formik
						initialValues={initialValues}
						validate={validateForm}
						onSubmit={handleSubmit}
						validateOnChange={false}
						validateOnBlur={false}
					>
						<>
							<Header>
								{isEdit ? 'Edit Patient' : 'Create Patient'}
							</Header>
							<ServiceAndProgram
								isEdit={isEdit}
								patientDetail={patientDetail}
								sectionDataContainerName='serviceAndProgram'
							/>
							<PatientDemographicsAndContact
								isEdit={isEdit}
								patientDetail={patientDetail}
								sectionDataContainerName='patientDemographicsAndContact'
							/>
							<ExternalCareTeam
								enqueueSnackbar={props.enqueueSnackbar}
								existingPatientUUID={existingPatientUUID}
								isEdit={isEdit}
								linkedProviders={linkedProviders}
								defaultProviders={defaultProviders}
								sectionDataContainerName='externalCareTeam'
								activeRole={props?.me?.activerole?.name}
							/>
							{!isEdit && (
								<NotesAndDocuments
									sectionDataContainerName='notesAndDocuments'
									isExternalUser={
										props.me.activerole.isExternalUser
									}
								/>
							)}
							<AdditionalPatientInfo
								isEdit={isEdit}
								patientDetail={patientDetail}
								sectionDataContainerName='additionalPatientInfo'
								activeRole={props?.me?.activerole?.name}
							/>
							<Footer
								enqueueSnackbar={props.enqueueSnackbar}
								history={props.history}
								isEdit={isEdit}
								existingPatientUUID={existingPatientUUID}
							/>
						</>
					</Formik>
				}
			</div>
		</Page>
	);
};

export default withRouter(connect(CreatePatientPage));
