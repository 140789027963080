//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../../assets/exit-alert.svg';

//Lodash
import get from 'lodash/get';

class Tag extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.toggleCode = this.toggleCode.bind(this);
	}
	//functions

	toggleCode() {
		const code = get(this, 'props.icdCode', {}) || {};
		this.props.toggleCodeSelection(false, get(code, 'uuid', null), code);
	}

	render() {
		const code = get(this, 'props.icdCode', {}) || {};
		return (
			<div className='chargeICDSelectedTag' style={{ whiteSpace: 'pre' }}>
				<div>{get(code, 'display_name', '--')}</div>
				{get(this, 'props.hasX', false) && (
					<ReactSVG
						src={xIcon}
						className='chargeICDSelectedTagX'
						onClick={this.toggleCode}
					/>
				)}
			</div>
		);
	}
}

export default Tag;
