import axios from 'axios';
import _ from 'lodash';
import get from 'lodash/get';

class EnhancedSearchService {
	buildContext = (data) => {
		const context_fields = get(data, 'context', []);
		let context_param = '';

		_.map(context_fields, (e, i) => {
			context_param =
				context_param +
				'context_name' +
				(i + 1) +
				'=' +
				e.name +
				'&context_value' +
				(i + 1) +
				'=' +
				e.value +
				(i == 0 ? '&' : '');
		});

		return context_param;
	};

	getResults = (data) => {
		const url = '/api/search/advanced/results';
		return axios.post(url, data);
	};

	searchAffiliations = () => {
		const url = '/api/search/affiliations';
		return axios.get(url);
	};

	searchOrderingPhysicians = () => {
		const url = '/api/search/ordering-physicians';
		return axios.get(url);
	};

	searchVisitProviders = () => {
		const url = '/api/search/visit-providers';
		return axios.get(url);
	};

	searchSpecialty = () => {
		return axios.get('/api/search/specialty');
	};

	searchTestNames = (data) => {
		return axios.get('/api/search/test-name?' + this.buildContext(data));
	};

	searchTestResults = () => {
		return axios.get('/api/search/test-results');
	};

	searchVisitStatus = () => {
		return axios.get('/api/search/visit-status');
	};

	searchActionability = () => {
		return axios.get('/api/search/actionable');
	};

	searchLabs = (data) => {
		const url = '/api/search/labs?';
		return axios.get(url + this.buildContext(data));
	};

	searchOrderStatus = () => {
		const url = '/api/search/order-status';
		return axios.get(url);
	};

	searchDispatchStatus = () => {
		return axios.get('/api/search/dispatchstatus');
	};

	searchRequestDecision = () => {
		return axios.get('/api/search/requestdecision');
	};
	searchReferringProviderName = () => {
		return axios.get('/api/search/referringprovidername');
	};
	searchVisitState = () => {
		return axios.get('/api/search/visitstate');
	};

	searchMultiPatientStatus = (data) => {
		return axios.get(
			'/api/search/multipatientstatus?' + this.buildContext(data)
		);
	};

	searchTestType = (data) => {
		const url = '/api/search/test-types?' + this.buildContext(data);
		return axios.get(url);
	};
	getEncounterTypes = (data) => {
		const url = '/api/search/encounter-types?' + this.buildContext(data);
		return axios.get(url);
	};

	searchOutreachStatus = () => {
		return axios.get('/api/search/outreach-status');
	};

	getSearchExportReport = (data) => {
		const url = '/api/search/advanced/export';
		return axios.post(url, data);
	};

	searchPatientTags = (data) => {
		const url = '/api/search/tag-name?';
		return axios.get(url + this.buildContext(data));
	};
}
export default new EnhancedSearchService();
