//React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

//Lodash
import get from 'lodash/get';
import _ from 'lodash';

//Utils
import {
	EnhancedSearchHeader,
	encounter_keys_dict,
} from '../../../Utils/searchUtils';

//Components
import Table from '../../Common/Table/Table';
import Thead from '../../Common/Table/Thead';
import Tbody from '../../Common/Table/Tbody';
import Tr from '../../Common/Table/Tr';
import Th from '../../Common/Table/Th';
import Td from '../../Common/Table/Td';
import Pagination from '../../Common/Pagination';
//Other Libraries
import moment from 'moment';

//Styles
import './style.css';

const EnhancedSearchTable = (props) => {
	const {
		sort_column,
		searchdata,
		handleSortChange,
		column,
		searchresult,
		handlePageChange,
		activePage,
		selected_pill,
		history,
	} = props;

	const [encounter_keys_available, setEncounterKeys] = useState([]);

	const encounter_keys_expected = [
		'date_of_service',
		'order_status',
		'lab',
		'type_of_test',
		'ordering_physician',
		'visit_provider',
		'vsee_specialty',
		'provider_indicated_specialty',
		'visit_status',
		'coupon_code',
		'payor',
		'external_patient_id',
		'test_name',
		'test_result',
		'dispatch_status',
		'request_decision',
		'state_of_visit',
		'referring_provider_name',
		'multipatient_visit',
		'outreach_status',
		'tag_display_name',
		'gene_symbol',
	];
	const keys_present = _.keys(_.get(searchresult, ['data', '0'], []));

	useEffect(() => {
		const encounter_keys_available = _.intersection(
			keys_present,
			encounter_keys_expected
		);
		setEncounterKeys(encounter_keys_available);
	}, [_.get(searchresult, ['data', '0'], false)]);

	const getvalue = (obj, data) => {
		if (obj.key == 'dob') {
			return (
				<Td key={obj.key} minWidth='116px' maxWidth='116px'>
					{get(data, [get(obj, 'key', ''), 'length'], 0) > 0
						? moment(
								get(data, get(obj, 'key', ''), 'length', '--')
						  ).format('MMM DD,YYYY')
						: '--'}
				</Td>
			);
		} else if (obj.key == 'email') {
			return (
				<Td key={obj.key} minWidth='188px' maxWidth='188px'>
					{get(data, [get(obj, 'key', ''), 'length'], 0) > 0
						? get(data, get(obj, 'key', ''), '--')
						: '--'}
				</Td>
			);
		} else if (obj.key == 'phone') {
			return (
				<Td key={obj.key} minWidth='150px' maxWidth='150px'>
					{get(data, [get(obj, 'key', ''), 'length'], 0) > 0
						? get(data, get(obj, 'key', ''), '--')
						: '--'}
				</Td>
			);
		} else
			return (
				<Td key={obj.key} minWidth='126px' maxWidth='126px'>
					{get(data, [get(obj, 'key', ''), 'length'], 0) > 0
						? get(data, get(obj, 'key', ''), '--')
						: '--'}
				</Td>
			);
	};

	const redirectToPatient = (uuid, firstName, lastName) => {
		history.push({
			pathname:
				'/app/patientdetail/' +
				uuid +
				'/0/' +
				0 +
				'/' +
				'' +
				'?offset=' +
				(activePage || 0),
			state: {
				breadcrumbs: [
					{
						location: 'Advanced Search',
						url: '/app/advancedsearch',
						selected_pill: selected_pill,
						activePage: activePage,
						sort_column: sort_column,
						column: column,
					},
					{
						location: 'Patient Detail',
						patientFullName: `${firstName || '--'} ${
							lastName || '--'
						}`,
					},
				],
			},
		});
	};

	const getEncounterLevelvalue = (obj, data) => {
		if (obj == 'date_of_service') {
			return (
				<Td key={obj.key} minWidth='126px' maxWidth='126px'>
					{get(data, [obj, 'length'], 0) > 0
						? moment(get(data, obj, 'length', '--')).format(
								'MMM DD,YYYY'
						  )
						: '--'}
				</Td>
			);
		}
		return (
			<Td key={obj.key} minWidth='126px' maxWidth='126px'>
				{get(data, [obj, 'length'], 0) > 0
					? obj === 'test_result'
						? _.capitalize(get(data, obj, '--'))
						: get(data, obj, '--')
					: '--'}
			</Td>
		);
	};

	const renderHeader = () => {
		const headers = getColumns();
		const patientheader = headers.filter((a) => a.type === 'fixed');
		const result = [];
		patientheader.map((e, i) => {
			result.push({
				display: get(e, 'display', ''),
				name: get(e, 'key', ''),
				isSortable: get(e, 'issortable', false),
				type: get(e, 'type', ''),
			});
		});
		encounter_keys_available.map((e, i) => {
			result.push({
				display: encounter_keys_dict[e],
				name: encounter_keys_dict[e],
				isSortable: false,
			});
		});
		return result;
	};

	const renderBody = (e) => {
		const headers = getColumns();
		const patientheader = headers.filter((a) => a.type === 'fixed');
		const result = [];
		patientheader.map((ee, i) => {
			result.push(getvalue(ee, e));
		});
		{
			encounter_keys_available.map((ee, i) => {
				result.push(getEncounterLevelvalue(ee, e));
			});
		}
		return result;
	};
	const getColumns = () => {
		let columns = [];
		const fixedcolums = EnhancedSearchHeader.filter(
			(a) => a.type == 'fixed'
		);
		const dynamic = EnhancedSearchHeader.filter((a) => a.type == 'dynamic');
		const optional = EnhancedSearchHeader.filter(
			(a) => a.type == 'optional'
		);
		const dynamicselected = EnhancedSearchHeader.filter((a) => {
			return (
				a.type == 'dynamic' &&
				((searchdata || []).find((a1) => a1.category.key == a.key)
					? true
					: false)
			);
		});
		let dynamicnotselected = _.difference(dynamic, dynamicselected);
		let optionalselected = EnhancedSearchHeader.filter((a) => {
			return (
				a.type == 'optional' &&
				((searchdata || []).find((a1) => a1.category.key == a.key)
					? true
					: false)
			);
		});
		//sort optional selected element sorted with optional order.
		optionalselected = optionalselected.sort((a, b) => {
			const aindex = _.findIndex(optional, function (el) {
				return el.key === a.key && el.type == a.type;
			});
			const bindex = _.findIndex(optional, function (el) {
				return el.key === b.key && el.type == b.type;
			});

			return aindex - bindex;
		});

		columns = _.concat(columns, fixedcolums, dynamic);
		let consumeindex = 0;

		dynamicnotselected = _.reverse(dynamicnotselected);

		//replace optional element with dynamic not selected element.
		dynamicnotselected.forEach((element) => {
			if (optionalselected.length - (consumeindex + 1) >= 0) {
				const consumableelement = optionalselected[consumeindex];
				const bindex = _.findIndex(columns, function (el) {
					return el.key === element.key && el.type == element.type;
				});
				columns[bindex] = consumableelement;
				consumeindex++;
			}
		});

		//rearraging the sort order based on the master utils order.
		columns = columns.sort((a, b) => {
			const aindex = _.findIndex(EnhancedSearchHeader, function (el) {
				return el.key === a.key && el.type == a.type;
			});
			const bindex = _.findIndex(EnhancedSearchHeader, function (el) {
				return el.key === b.key && el.type == b.type;
			});

			return aindex - bindex;
		});
		//remove element if it is more than 9.
		return _.dropRight(columns, columns.length - 9);
	};

	const paginatedDisplay = () => {
		const end = get(searchresult, ['end'], 0);
		const start = get(searchresult, ['start'], 0);
		const total = get(searchresult, ['total'], 0);
		return start + 1 + ' - ' + (end + 1) + ' of ' + total;
	};

	const data = get(searchresult, 'data', []);
	const pagination = {
		has_next: get(searchresult, ['has_next_page'], false),
		has_prev: get(searchresult, ['has_prev_page'], false),
		limit: get(searchresult, ['limit'], 0),
		offset: get(searchresult, ['offset'], false),
		total: get(searchresult, ['total'], 0),
		end: get(searchresult, ['end'], false),
		start: get(searchresult, ['start'], false),
	};
	const sortBy = {
		key: column,
		value: sort_column,
	};
	return (
		<div className='col-md-12 enhanced_search'>
			{data.length > 0 && (
				<div className='col-md-offset-7 col-md-5 cont-cent-box'>
					<div className='pg-no-list'>{paginatedDisplay()}</div>
					<Pagination
						className='appointment-pagination'
						pageSize={_.get(pagination, ['limit'], 20)}
						total={_.get(pagination, ['total'], 0)}
						offset={_.get(pagination, ['offset'], 0)}
						layout='prev, pager, next'
						onCurrentChange={handlePageChange}
					/>
				</div>
			)}

			<Table
				loading={false}
				handleSort={handleSortChange}
				sortBy={sortBy}
				className={'custom-table2'}
				containerStyle={{ maxWidth: '100%', width: '100%' }}
				hasData={data.length > 0}
				noResultsFound={
					<div className='loadingrow cellText'>No results</div>
				}
			>
				<Thead>
					<Tr>
						{renderHeader().map((e, i) => {
							if (e.isSortable)
								return (
									<Th
										key={`table-header-${e.display}`}
										style={{}}
										className={
											get(e, 'type', '') == 'fixed'
												? 'fixed'
												: ''
										}
										sortable={{
											[e.name]: {
												display_name: e.display,
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										{' '}
										{e.display}{' '}
									</Th>
								);
							else
								return (
									<Th className={`enhance-col-${e.name}`}>
										{' '}
										{e.display}{' '}
									</Th>
								);
						})}
					</Tr>
				</Thead>
				<Tbody>
					{get(data, 'length', 0) > 0 &&
						data.map((e, i) => {
							return (
								<Tr
									className='enhanced-table-row'
									key={`table-row-${get(e, 'uuid', 0)}`}
									onClick={(evt) =>
										redirectToPatient(
											get(e, 'uuid', 0),
											get(e, 'first_name', '--'),
											get(e, 'last_name', '--')
										)
									}
								>
									{renderBody(e).map((td) => {
										return td;
									})}
								</Tr>
							);
						})}
				</Tbody>
			</Table>
		</div>
	);
};

EnhancedSearchTable.propTypes = {
	sort_column: PropTypes.string.isRequired,
	handlePageChange: PropTypes.func.isRequired,
	handleSortChange: PropTypes.func.isRequired,
	searchdata: PropTypes.array.isRequired,
	searchresult: PropTypes.array.isRequired,
	history: PropTypes.any.isRequired,
};

export default EnhancedSearchTable;
