//React & Redux
import React, { useState } from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Button, Loading } from 'gm-element-react';

//Components
import { EncounterTextAreaField } from '../Common/EncounterTextAreaField';

const EditAdditionalSegment = (props) => {
	const { encounter, cancel, saveGroupSessionEncounter } = props;
	const [formData, setFormData] = useState({
		notes: _.get(encounter, ['notes'], ''),
	});
	const [loading, setLoading] = useState(false);

	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		setFormData(stateData);
	};

	const saveForm = () => {
		setLoading(true);
		const updatedformdata = _.mapValues(formData, function (value, key) {
			return value && value.trim();
		});
		saveGroupSessionEncounter(encounter, updatedformdata)
			.then((result) => {
				setTimeout(() => {
					setLoading(false);
					cancel();
				}, 1000);
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	return (
		<Loading loading={loading}>
			<div>
				<div className='edit-encntr-lbl-cc-notes'>CC NOTES</div>
				<div className='segment-row'>
					<EncounterTextAreaField
						value={_.get(formData, ['notes'], '')}
						name='notes'
						disabled={false}
						onChange={(value) => onFormChange('notes', value)}
					/>
				</div>

				<div className='segment-footer-container'>
					<div className='cancel-btn-link'>
						<Button onClick={() => cancel()}>Cancel</Button>
					</div>
					<div className='save-btn-link'>
						<Button style={{ float: 'right' }} onClick={saveForm}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</Loading>
	);
};
export default EditAdditionalSegment;
