//React & Redux
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

//Actions & Services
import { me } from '../../actions/me';
import { LoadUserRoles } from '../../actions/userroles';
import { noaccessrestricted } from '../../actions/accessrestricted';
import { retryExceeded } from '../../actions/httpHandlers';
import { changeCurrentRole } from '../../actions/me';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import { shouldBeMasked } from '../../utils';
import { isCCGCOrProdeng } from '../../Utils/permissionUtils.js';

//UI Libraries
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { MessageBox, i18n } from 'gm-element-react';
import locale from 'gm-element-react/src/locale/lang/en';

//Other Libraries
import classnames from 'classnames';

//Components
import Routes from './../../routes';
import NoAccess from './NoAccess';
import SnackBar from '../SnackBar/SnackBar';
import CTAWarningModal from './../Common/CTAWarningModal.js';
import Checkbox from './../Common/Checkbox.js';

//Styles
import './style.css';
import './inputs.css';

i18n.use(locale);

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dimmerOverlay: false,
			dimmerOverlayZIndex: '-1',
			interval: -1,
			releaseNotesRefresh: false,
			updateReceived: false,
		};
		this.toggleDimmerOn = this.toggleDimmerOn.bind(this);
		this.toggleDimmerOff = this.toggleDimmerOff.bind(this);
		this.callMe = this.callMe.bind(this);
		this.renderReleaseNotesCheckbox =
			this.renderReleaseNotesCheckbox.bind(this);
		this.releaseNotesRefreshToggle =
			this.releaseNotesRefreshToggle.bind(this);
		this.refreshRedirection = this.refreshRedirection.bind(this);
	}

	async componentDidMount() {
		await this.callMe();
		const timeinterval = 30 * 60000; //30 minutes
		this.props.loaduserroles();
		const interval = setInterval(async () => {
			await this.callMe();
		}, timeinterval);
		this.setState({ interval: interval });
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.retryExceeded !== this.props.retryExceeded &&
			this.props.retryExceeded &&
			!window.reloadpopup
		) {
			window.reloadpopup = true;
			MessageBox.msgbox({
				title: 'An intermittent internal error occurred.',
				message: (
					<div>
						Please record your unsaved changes offline,{' '}
						<b>reload the page </b> and retry with those changes.{' '}
					</div>
				),
				showCancelButton: false,
				showClose: false,
				confirmButtonText: 'OK',
				confirmButtonClass: 'auto-refresh-confirm',
				customClass: 'refresh-message',
			}).then(() => {
				window.reloadpopup = false;
				this.props.resetRetryExceeded();
			});
			setTimeout(() => {
				const node = document.querySelector('.refresh-message');
				if (node)
					get(
						node,
						['parentNode'],
						document.createElement('div')
					).style.zIndex = '100000';
			});
		}

		if (
			prevProps.showSwitchModal !== this.props.showSwitchModal &&
			!prevProps.showSwitchModal &&
			this.props.showSwitchModal
		) {
			clearInterval(this.state.interval);
			this.setState({ interval: -1 });
		}
	}

	callMe = async () => {
		await this.props.getme();
	};

	gotoHome = () => {
		this.props
			.getme()
			.then(() => {
				setTimeout(() => {
					this.props.NoaccessRestricted();
				}, 1000);
			})
			.catch(() => {});
	};

	renderDimmer = () => {
		return (
			<div
				className='overlayDimmer'
				style={{
					opacity: this.state.dimmerOverlay ? '1' : 0,
					zIndex: _.get(this, 'state.dimmerOverlayZIndex', '-1'),
				}}
			/>
		);
	};

	toggleDimmerOn() {
		this.setState({ dimmerOverlay: true, dimmerOverlayZIndex: '11000' });
	}

	toggleDimmerOff() {
		this.setState({ dimmerOverlay: false }, () => {
			setTimeout(() => {
				if (!_.get(this, 'state.dimmerOverlay', false)) {
					this.setState({ dimmerOverlayZIndex: '-1' });
				}
			}, 230);
		});
	}

	renderEnvironmentBanner() {
		const environment = process.env.REACT_APP_GMI_ENV;
		if (environment !== 'prod') {
			switch (environment) {
				case 'dev':
				case 'local':
					return <div className='envBanner dev'>Dev Environment</div>;
				case 'qa':
					return <div className='envBanner qa'>QA Environment</div>;
				default:
					return;
			}
		}
	}

	renderRefreshModalV2() {
		return (
			<CTAWarningModal
				colorScheme='purple'
				iconColorScheme='yellow'
				title='New Platform Enhancements Available'
				text='New enhancements have been added to the Genome Medical platform! For more details, click the Release Notes icon on the lower left of your screen. Please refresh your page to enjoy the new features. Thank you!'
				mainButtonText='Release Notes'
				minorButtonText='Close'
				buttonRowBorder={true}
				mainFunc={this.redirectToReleaseNotes}
				closeFunc={this.onClose}
				customZIndex={100001}
				noInitialClick={true}
			/>
		);
	}

	renderSwtichRoleModal() {
		return (
			<CTAWarningModal
				noCloseButton={true}
				colorScheme='purple'
				hideTitle={true}
				text={
					'It appears that your permissions to view the pages on the portal were updated. Please refresh using the button below to continue using the portal.'
				}
				mainButtonText='Refresh'
				buttonRowBorder={true}
				mainFunc={() => {
					this.props.changeCurrentRole(
						_.get(this.props, ['showSwitchModal'])
					);
				}}
				customZIndex={100001}
				noInitialClick={true}
			/>
		);
	}

	renderReleaseNotesCheckbox() {
		const role = get(this, 'props.me.activerole.name', null);
		const canSeeReleaseNotes = isCCGCOrProdeng(role);
		if (canSeeReleaseNotes) {
			return (
				<div className='refreshModalCheckboxRow'>
					<Checkbox
						onChangeFunc={this.releaseNotesRefreshToggle}
						checked={get(this, 'state.releaseNotesRefresh', false)}
						marginLeftOverride={0}
					/>
					<div style={{ marginTop: '3px' }}>View Release Notes</div>
				</div>
			);
		} else return;
	}

	releaseNotesRefreshToggle(e) {
		this.setState({ releaseNotesRefresh: e });
	}

	refreshRedirection() {
		const releaseNotesRefresh = get(
			this,
			'state.releaseNotesRefresh',
			false
		);
		if (releaseNotesRefresh) {
			window.location.pathname = '/ui/app/releasenotes';
		} else {
			window.location.reload(true);
		}
	}

	onClose() {
		window.location.reload(true);
	}
	redirectToReleaseNotes() {
		window.location.pathname = '/ui/app/releasenotes';
	}

	getPageHeader() {
		let pathname = _.get(this, 'props.history.location.pathname', '');
		if (pathname.indexOf('/app/patientdetail') != -1)
			pathname = '/app/patientdetail';
		else if (pathname.indexOf('/app/patients') != -1)
			pathname = '/app/patients';
		return pathname;
	}

	isUserLoggedin = () => {
		const user = _.get(this, ['props', 'me', 'user'], {});
		const portals = _.get(this, ['props', 'me', 'portals'], []);
		const permissions = _.get(this, ['props', 'me', 'permissions'], {});
		return (
			!_.isEmpty(user) &&
			!_.isEmpty(permissions) &&
			portals.includes('emr_ui')
		);
	};

	isPortalAccessRestricted = () => {
		const user = _.get(this, ['props', 'me', 'user'], {});
		const portals = _.get(this, ['props', 'me', 'portals'], []);
		return !_.isEmpty(user) && !portals.includes('emr_ui');
	};

	render() {
		const childProps = {
			enqueueSnackbar: enqueueSnackbar,
			toggleDimmerOn: this.toggleDimmerOn,
			toggleDimmerOff: this.toggleDimmerOff,
			me: this.props.me,
		};
		const showRefreshModal = get(this, 'props.showRefreshModal', false);
		const showSwitchModal = get(this, 'props.showSwitchModal', false);
		return (
			<Fragment>
				{(this.props.accessrestricted &&
					this.props.accessrestricted == true) ||
				this.isPortalAccessRestricted() ? (
					<NoAccess gotoHome={this.gotoHome} />
				) : (
					this.isUserLoggedin() && (
						<div
							className={classnames(
								' App container top-mobi ',
								this.getPageHeader() === '/app/patients'
									? 'pat-main-container'
									: ''
							)}
							id='App1'
						>
							{this.renderDimmer()}
							{this.renderEnvironmentBanner()}
							{showRefreshModal && this.renderRefreshModalV2()}
							<Routes childProps={childProps} />
						</div>
					)
				)}
				{showSwitchModal && this.renderSwtichRoleModal()}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	accessrestricted: state.accessrestricted.accessrestricted,
	me: state.me,
	showRefreshModal: state.httpHandlers.showRefreshModal,
	showSwitchModal: state.httpHandlers.showSwitchModal,
	retryExceeded: state.httpHandlers.retryExceeded,
});

const mapDispatchToProps = (dispatch) => ({
	NoaccessRestricted: () => dispatch(noaccessrestricted()),
	getme: () => dispatch(me()),
	loaduserroles: () => dispatch(LoadUserRoles()),
	resetRetryExceeded: () => dispatch(retryExceeded(false)),
	changeCurrentRole: (role) => dispatch(changeCurrentRole(role)),
});
const MyApp = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

function IntegrationNotistack() {
	return (
		<SnackbarProvider
			maxSnack={3}
			autoHideDuration={3000}
			className='snack_notification'
		>
			<MyApp />
			{shouldBeMasked() && <SnackBar />}
		</SnackbarProvider>
	);
}

export default IntegrationNotistack;
