//React & Redux
import React, { Fragment, useState, useEffect } from 'react';

//Images
import ReactSVG from 'react-svg';
import completeVisit from '../../../../assets/complete-visit.svg';
import joinZoom from '../../../../assets/join-zoom.svg';
import checkIcon from '../../../../assets/check.svg';
import warningIcon from '../../../../assets/warning.svg';
import closeIcon from '../../../../assets/x.svg';
import visitToScp from '../../../../assets/visit-to-scp.svg';
import scpToVisit from '../../../../assets/visit.svg';
import multipleVisitIcon from '../../../../assets/multi-visit.svg';

//Lodash
import _ from 'lodash';

//Utils
import {
	isAppointmentStarted,
	GetSelectvalue,
	displayEncounterTypeWithSubtype,
} from './Common/utilsfunctions';
import {
	CARE_PATIENTS_PATIENT_ENCOUNTER_LIST,
	CARE_PATIENTS_ENCOUNTER_VISIT_ACTIONS,
	CARE_APPOINTMENTS_APPOINTMENT_COMPLETE,
} from '../../../../Utils/permissionUtils';
import { EncounterTabs } from './../../../../utils';
import {
	encounter_types,
	encounter_types_images,
	visit_status_colors,
	scp_encounter_sub_types_images,
	scp_encounter_sub_types_pre_appt,
	scp_encounter_sub_types_automated_pre_test,
	scp_encounter_sub_types_partner_initiated_pre_test,
	visit_to_scp_link_title,
	getLinkedVisitEncounter,
	isMultipleVisit,
	isPrimaryVisit,
	isSecondaryVisitForDetail,
	isManualVisitDetail,
	isMultipleVisitDetail,
	canCompleteEncounter,
} from '../../../../Utils/encounterUtils';

//UI Libraries
import { Loading } from 'gm-element-react';

//Other Libraries
import moment from 'moment';
import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';

//Components
import AppointmentMenu from '../../AppointmentMenu.js';
import Tooltip from './../../../Common/Tooltip';
import RBAC from '../../../RBAC';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious';

//Styles
import './encounter-header.css';

const EncounterHeader = (props) => {
	const {
		encounterCompleteLoading,
		encounterCompleteError,
		enqueueSnackbar,
		getencouter,
		getPatientDetail,
		getEncounterList,
		closeAlert,
		encounterCancelLoading,
		changeEncounterTab,
		encounter,
		billingencounter,
		completeEncounter,
		initialEncounterGet,
		openAppointmentAlert,
		openAuditTrail,
		activeRole,
	} = props;
	const [isLoadingCompleteVisit, setIsLoadingCompleteVisit] = useState(false);

	const previousEncounterCompleteError = usePrevious(encounterCompleteError);
	const previousEncounterCompleteLoading = usePrevious(
		encounterCompleteLoading
	);
	const previousEncounterCancelLoading = usePrevious(encounterCancelLoading);
	useEffect(() => {
		if (previousEncounterCompleteLoading && !encounterCompleteLoading) {
			if (!previousEncounterCompleteError && encounterCompleteError) {
				enqueueSnackbar('Unable to complete encounter', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				getencouter(false);
				getPatientDetail();
				getEncounterList();
				setTimeout(() => {
					closeAlert();
					setIsLoadingCompleteVisit(false);
				}, 500);
			}
		}
	}, [encounterCompleteLoading]);

	useEffect(() => {
		if (previousEncounterCancelLoading && !encounterCancelLoading) {
			getencouter(false);
			closeAlert();
			getEncounterList();
		}
	}, [encounterCancelLoading]);

	const goToPatientList = () => {
		changeEncounterTab(0, EncounterTabs.LIST);
	};

	const getConsultationSubType = (obj) => {
		return obj.type &&
			obj.consultation_type &&
			(obj.type == 'visit' || obj.type == 'scp')
			? ' - ' + obj.consultation_type
			: '';
	};

	const ZoomLink = () => {
		return _.get(encounter, ['zoom_host_join_url'], '');
	};

	const handleZoomLinkOpen = () => {
		if (ZoomLink() != '') {
			window.open(ZoomLink(), '_blank');
		}
		return false;
	};

	const copyClipboard = (name) => {
		enqueueSnackbar('The ' + name + ' has been copied.', {
			variant: 'success',
			className: 'export_notification',
			anchorOrigin: { horizontal: 'center', vertical: 'top' },
		});
	};

	const isVisit = () => {
		const type = _.get(encounter, ['type'], '');
		return type === 'visit';
	};

	const isPartOfPresent = () => {
		const part_of = _.get(encounter, ['part_of'], '');
		return (
			part_of &&
			part_of.type === 'scp' &&
			part_of.subtype &&
			part_of.uuid &&
			scpLinkTitle()
		);
	};

	const scpLinkTitle = () => {
		const subtype = _.get(encounter, ['part_of', 'subtype'], '');
		return visit_to_scp_link_title[subtype]
			? `SCP - ${visit_to_scp_link_title[subtype]}`
			: null;
	};

	const renderLinkedVisitButton = (encounter) => {
		const subType = _.get(encounter, 'encounter_subtype', false);
		if (!_.get(visit_to_scp_link_title, subType, false)) return null;

		const linkedEncounter = getLinkedVisitEncounter(encounter);

		return (
			<button
				className={`btn-scp-to-visit ${
					!linkedEncounter ? 'disabled' : ''
				}`}
				onClick={() =>
					getEncounter(
						linkedEncounter ? linkedEncounter.uuid : null,
						!linkedEncounter
					)
				}
			>
				<ReactSVG className={'icon-scp-to-visit'} src={scpToVisit} />
				<span>
					{linkedEncounter
						? `Visit - ${linkedEncounter.consultation_type}`
						: 'No Appointment'}
				</span>
			</button>
		);
	};

	const getEncounter = (uuid, isDisabled) => {
		if (!isDisabled) changeEncounterTab(uuid, 2);
	};

	const isBooked = () => {
		const status = _.get(encounter, ['visit_status'], '');
		return status === 'booked';
	};

	const getVisitStatusColor = (status) => {
		if (status === 'completed') {
			return 'green';
		}
		return _.get(visit_status_colors, status, 'green');
	};

	const handleCompleteEncounter = (canCompleteVisitEncounter) => {
		if (canCompleteVisitEncounter) {
			setIsLoadingCompleteVisit(true);
			completeEncounter({ uuid: _.get(encounter, ['uuid'], '') });
		}
	};

	const showVisitStatusIcon = (visitStatus) => {
		if (visitStatus === 'booked') {
			return (
				<img
					src={checkIcon}
					alt='check-icon'
					className='visitstatus-icon'
				/>
			);
		} else if (visitStatus === 'completed' || visitStatus === 'requested') {
			return (
				<img
					src={checkIcon}
					alt='check-icon'
					className='visitstatus-icon'
				/>
			);
		} else if (
			visitStatus === 'cancelled_by_patient' ||
			visitStatus === 'cancelled_by_provider' ||
			visitStatus === 'cancelled_by_cc' ||
			visitStatus === 'cancelled_rescheduled_by_provider' ||
			visitStatus === 'cancelled_rescheduled_by_patient' ||
			visitStatus === 'cancelled_by_care_coordinator' ||
			visitStatus === 'cancelled'
		) {
			return (
				<img
					src={closeIcon}
					alt='check-icon'
					className='visitstatus-icon'
				/>
			);
		} else if (visitStatus === 'no_show') {
			return (
				<img
					src={warningIcon}
					alt='check-icon'
					className='visitstatus-icon'
				/>
			);
		}
	};

	const calculateTimePeriod = () => {
		const appointmenttime = moment.utc(
			_.get(encounter, ['date_of_service'], ''),
			'YYYY-MM-DD HH:mm:ss'
		);
		const currentutc = moment.utc();
		const ms = appointmenttime.diff(currentutc);
		const d = moment.duration(ms);
		const days = d.asDays();
		const hours = d.asHours();
		const minutes = d.asMinutes();
		let humanreadable = '';
		let humanreadableprefix = '';

		if (hours > 24) {
			humanreadableprefix = 'Starts in ';
			humanreadable =
				Math.floor(days) + ' day' + (Math.floor(days) !== 1 ? 's' : '');
		} else if (minutes > 99) {
			humanreadableprefix = 'Starts in ';
			humanreadable =
				Math.floor(hours) +
				' hour' +
				(Math.floor(hours) !== 1 ? 's' : '');
		} else if (minutes <= 99 && minutes >= 0) {
			humanreadableprefix = 'Starts in ';
			humanreadable =
				Math.floor(minutes) +
				' minute' +
				(Math.floor(minutes) !== 1 ? 's' : '');
		} else if (minutes >= -30) {
			humanreadableprefix = 'In session - ';
			humanreadable =
				30 +
				Math.floor(minutes) +
				' min' +
				(30 + Math.floor(minutes) !== 1 ? 's' : '') +
				' remaining';
		} else {
			humanreadableprefix = 'Overdue - ';
			humanreadable = '0 mins remaining';
		}

		return (
			<span>
				<span
					style={{
						textTransform: 'capitalize',
						fontFamily: 'RionaSans',
						fontSize: '13px',
					}}
				>
					{humanreadableprefix}
				</span>{' '}
				<span
					style={{
						textTransform: 'capitalize',
						fontFamily: 'RionaSans',
						fontSize: '13px',
						fontWeight: 'bold',
					}}
				>
					{humanreadable}
				</span>
			</span>
		);
	};
	const isSCPEncoutnerWithSubType = (encounter) => {
		return (
			_.get(encounter, 'type', '') == 'scp' &&
			_.get(encounter, 'encounter_subtype', false)
		);
	};

	const getMultipleVisitType = (el) => {
		if (isMultipleVisit(el)) {
			if (isPrimaryVisit(el)) {
				return ' (Primary) ';
			} else if (isSecondaryVisitForDetail(el)) {
				return ' (Secondary) ';
			} else {
				return '';
			}
		} else {
			return '';
		}
	};

	const getVisitTypeDisplay = (el) => {
		if (isMultipleVisitDetail(el)) {
			if (isPrimaryVisit(el)) {
				return ' (Primary) ';
			} else if (isSecondaryVisitForDetail(el)) {
				return ' (Secondary) ';
			} else {
				return '';
			}
		} else if (isManualVisitDetail(el)) {
			return ' (Manual) ';
		} else {
			return '';
		}
	};

	const completeVisitTooltipMessage = () => {
		return (
			<React.Fragment>
				<div style={{ marginTop: '4px' }}>1. Indications</div>

				<div style={{ marginTop: '4px' }}>
					2. Direct Clinical Care Time (minutes)
				</div>

				<div style={{ marginTop: '4px' }}>
					3. Pre-Session Indirect Time (minutes)
				</div>

				<div style={{ marginTop: '4px' }}>
					4. Post-Session Indirect Time (minutes)
				</div>

				<div style={{ marginTop: '4px' }}>
					5. Post-Test Result CAP Time Spent (minutes)
				</div>

				<div style={{ marginTop: '4px' }}>
					6. ICD-10 Code(s) (Visit)
				</div>

				<div style={{ marginTop: '4px' }}>
					7. Visit CAP Completed Date
				</div>
			</React.Fragment>
		);
	};

	const schema = {
		visit: 'visitsschema',
		lab_test_authorization: 'labsschema',
		'group-session': 'groupsessionschema',
		'cc-intake': 'ccintakeschema',
	}[_.get(encounter, 'type', '')];
	const isPreAppt = _.get(
		scp_encounter_sub_types_pre_appt,
		_.get(encounter, 'encounter_subtype', ''),
		false
	);
	const isAutomatedPreTest = _.get(
		scp_encounter_sub_types_automated_pre_test,
		_.get(encounter, 'encounter_subtype', ''),
		false
	);
	const isPartnerInitiatedPreTest = _.get(
		scp_encounter_sub_types_partner_initiated_pre_test,
		_.get(encounter, 'encounter_subtype', ''),
		false
	);
	const canCompleteVisitEncounter = canCompleteEncounter(
		encounter,
		billingencounter
	);
	const visitButtonClass = canCompleteVisitEncounter
		? 'btn-complete-visit'
		: 'modalButtonPurpleDisabled btn-complete-visit';
	return (
		<Fragment>
			<section className='header-wrapper'>
				<div className='header-container'>
					<RBAC
						action={CARE_PATIENTS_PATIENT_ENCOUNTER_LIST}
						yes={
							<span
								className='enctr-list-backlink'
								onClick={() => goToPatientList()}
							>
								&#60; Encounter List
							</span>
						}
					/>

					{/* 2nd row */}
					<div className='second-row-wrapper'>
						<span className='enctr-typr-cnslt-type'>
							{isSCPEncoutnerWithSubType(encounter) ? (
								<Fragment>
									<img
										src={
											scp_encounter_sub_types_images[
												_.get(
													encounter,
													'encounter_subtype',
													''
												)
											]
										}
										className='patient-enctr-details-visiticon'
									/>
									{displayEncounterTypeWithSubtype(encounter)}
								</Fragment>
							) : (
								<Fragment>
									{!_.isEmpty(
										getMultipleVisitType(encounter)
									) ? (
										<img src={multipleVisitIcon} alt='' />
									) : (
										<img
											src={
												encounter_types_images[
													_.get(encounter, 'type', '')
												]
											}
											className='patient-enctr-details-visiticon'
										/>
									)}
									{
										encounter_types[
											_.get(encounter, 'type', '')
										]
									}
									<span className='multivisit-type'>
										{getVisitTypeDisplay(encounter)}
									</span>
									{getConsultationSubType(encounter)}
								</Fragment>
							)}
						</span>

						{isVisit() && isPartOfPresent() && (
							<button
								type='button'
								className='btn-visit-to-scp'
								onClick={() =>
									getEncounter(encounter.part_of.uuid)
								}
							>
								<ReactSVG
									className={'icon-visit-to-scp'}
									src={visitToScp}
								/>
								<span>{scpLinkTitle()}</span>
							</button>
						)}

						{isSCPEncoutnerWithSubType(encounter) &&
							renderLinkedVisitButton(encounter)}

						{isVisit() && isBooked() && (
							<RBAC
								action={CARE_PATIENTS_ENCOUNTER_VISIT_ACTIONS}
								yes={
									<Fragment>
										{isAppointmentStarted(encounter) && (
											<RBAC
												action={
													CARE_APPOINTMENTS_APPOINTMENT_COMPLETE
												}
												yes={
													<Loading
														key={
															'loader-complete-visit'
														}
														loading={
															isLoadingCompleteVisit
														}
														className='loading-btn-complete-visit'
														style={{
															marginLeft:
																isVisit() &&
																isPartOfPresent()
																	? '12px'
																	: 'auto',
														}}
													>
														<Tooltip
															placement='top'
															content={
																<div
																	style={{
																		fontSize:
																			'12px',
																	}}
																>
																	<div>
																		Complete
																		the
																		following
																		field(s)
																		before
																		saving:
																	</div>
																	{completeVisitTooltipMessage()}
																</div>
															}
															disabled={
																canCompleteVisitEncounter
															}
														>
															<button
																type='button'
																className={
																	visitButtonClass
																}
																onClick={(e) =>
																	handleCompleteEncounter(
																		canCompleteVisitEncounter
																	)
																}
															>
																<ReactSVG
																	className={
																		'icon-complete-visit'
																	}
																	src={
																		completeVisit
																	}
																/>
																<span>
																	{
																		'complete visit'
																	}
																</span>
															</button>
														</Tooltip>
													</Loading>
												}
											/>
										)}
										{encounter.tm_platform !== 'phone' && (
											<button
												type='button'
												className='btn-join-zoom'
												onClick={handleZoomLinkOpen}
												style={{
													marginLeft:
														(isVisit() &&
															isPartOfPresent()) ||
														isAppointmentStarted(
															encounter
														)
															? '12px'
															: 'auto',
												}}
											>
												<ReactSVG
													className={'icon-join-zoom'}
													src={joinZoom}
												/>
												<span>{'Join zoom'}</span>
											</button>
										)}
									</Fragment>
								}
							/>
						)}

						{initialEncounterGet && (
							<Fragment>
								<AppointmentMenu
									openAppointmentAlert={openAppointmentAlert}
									appointmentStarted={isAppointmentStarted(
										encounter
									)}
									encounter={encounter}
									openAuditTrail={openAuditTrail}
									activeRole={activeRole}
									isBookedVisit={isVisit() && isBooked()}
								/>
							</Fragment>
						)}
					</div>

					{/* 3nd row */}
					{!isPreAppt && !isAutomatedPreTest && (
						<div className='third-row-wrapper'>
							{isVisit() && isBooked() ? (
								calculateTimePeriod()
							) : (
								<span
									className={classnames(
										'aptmnt-status status-' +
											getVisitStatusColor(
												_.get(
													encounter,
													'visit_status',
													''
												)
											)
									)}
								>
									{showVisitStatusIcon(
										_.get(encounter, 'visit_status', '')
									)}{' '}
									{_.get(encounter, 'visit_status', '')
										? GetSelectvalue(
												encounter,
												['visit_status'],
												_.get(
													props,
													[schema, 'visit_status'],
													[]
												)
										  )
										: ''}
								</span>
							)}
							{isVisit() &&
								isBooked() &&
								encounter.tm_platform !== 'phone' && (
									<RBAC
										action={
											CARE_PATIENTS_ENCOUNTER_VISIT_ACTIONS
										}
										yes={
											<div className='copy-host-link'>
												Copy Host Link
												<CopyToClipboard
													text={ZoomLink()}
													onCopy={() =>
														copyClipboard(
															'Host link'
														)
													}
												>
													<i className='material-icons copyIcon Encounter-details-Copy-Host-icon'>
														file_copy
													</i>
												</CopyToClipboard>
											</div>
										}
									/>
								)}
						</div>
					)}
				</div>
			</section>
		</Fragment>
	);
};

export default EncounterHeader;
