import * as actionTypes from './actionTypes';
import ProviderService from '../service/Providers';
import {
	hydrateProviders,
	hydrateProviderCreateUpdateErrors,
} from '../transformers/providers/providerTransformers';
import get from 'lodash/get';

export const getAllReports = (data) => {
	return {
		type: actionTypes.GET_ALL_REPORT_STATUS,
		payload: data,
	};
};

//Get providers
export function getaffiliation(data) {
	return (dispatch) => {
		dispatch(gettingProviderAffiliations());
		ProviderService.provideraffiliations(data)
			.then((res) => {
				dispatch(
					providerAffiliations({ data: get(res, 'data.data', []) })
				);
			})
			.catch((err) => {
				dispatch(providerAffiliationsError(err));
			});
	};
}

export function resetaffiliation() {
	return (dispatch) => {
		dispatch(providerAffiliations({ data: [] }));
	};
}

export const gettingProviderAffiliations = () => {
	return {
		type: actionTypes.GETTING_PROVIDER_AFFILIATIONS,
		payload: true,
	};
};

export const providerAffiliations = (data) => {
	return {
		type: actionTypes.PROVIDER_AFFILIATIONS,
		payload: data,
	};
};

const providerAffiliationsError = (err) => {
	return {
		type: actionTypes.PROVIDER_AFFILIATIONS_ERROR,
		payload: err,
	};
};

const gettingReportStatus = () => {
	return {
		type: actionTypes.LOADING_SEND_PROVIDER_REPORTS,
		payload: true,
	};
};

const reportStatus = (data) => {
	return {
		type: actionTypes.SEND_PROVIDER_REPORTS,
		payload: data,
	};
};

const reportStatusError = (err) => {
	return {
		type: actionTypes.SEND_PROVIDER_REPORTS_ERROR,
		payload: err,
	};
};

export function exportProviderReport(data) {
	return (dispatch) => {
		dispatch(gettingReportStatus());
		ProviderService.exportReport(data)
			.then((res) => {
				dispatch(reportStatus({ data: get(res, 'data.data', []) }));
			})
			.catch((err) => {
				dispatch(reportStatusError(err));
			});
	};
}

export function resetExportStatus() {
	return (dispatch) => {
		dispatch(reportStatus({ data: '' }));
	};
}

export function getAllReportStatus(data) {
	return (dispatch) => {
		ProviderService.getAllReports(data)
			.then((res) => {
				dispatch(getAllReports({ data: get(res, 'data.data', []) }));
			})
			.catch(() => {});
	};
}

export function getPartnerOrganization() {
	return (dispatch) => {
		dispatch(gettingPartnerOrganization());
		ProviderService.partnerOrganizations()
			.then((res) => {
				dispatch(
					getpartnerOrganizationSuccess(get(res, 'data.data', []))
				);
			})
			.catch((err) => {
				dispatch(getpartnerOrganizationError(err));
			});
	};
}

const gettingPartnerOrganization = () => {
	return {
		type: actionTypes.LOADING_PARTNER_ORGANIZATION,
		payload: true,
	};
};

const getpartnerOrganizationSuccess = (data) => {
	return {
		type: actionTypes.GET_PARTNER_ORGANIZATION_SUCCESS,
		payload: data,
	};
};

const getpartnerOrganizationError = (err) => {
	return {
		type: actionTypes.GET_PARTNER_ORGANIZATION_ERROR,
		payload: err,
	};
};

const startGetProvidersList = () => {
	return {
		type: actionTypes.GET_PROVIDERS_LIST_START,
	};
};

const getProvidersListSuccess = (payload) => {
	return {
		type: actionTypes.GET_PROVIDERS_LIST_SUCCESS,
		payload,
	};
};

const getProvidersListFailure = (payload) => {
	return {
		type: actionTypes.GET_PROVIDERS_LIST_FAILURE,
		payload,
	};
};

export const getProvidersList = (params) => {
	return (dispatch) => {
		dispatch(startGetProvidersList());
		ProviderService.listProviders({ ...params })
			.then((res) => {
				dispatch(getProvidersListSuccess(get(res, 'data', [])));
			})
			.catch((err) => {
				dispatch(getProvidersListFailure(err));
			});
	};
};

export const searchProviders = (params) => {
	return (dispatch) => {
		dispatch(startGetProvidersList());
		ProviderService.searchProviders({ ...params })
			.then((res) => {
				dispatch(getProvidersListSuccess(get(res, 'data', [])));
			})
			.catch((err) => {
				dispatch(getProvidersListFailure(err));
			});
	};
};

export const getProviders = (params) => {
	return (dispatch) => {
		dispatch(startGetProvidersList());
		ProviderService.searchProviders({ ...params })
			.then((res) => {
				dispatch(
					getProvidersListSuccess({
						data: hydrateProviders(get(res, 'data.data', [])),
					})
				);
			})
			.catch((err) => {
				dispatch(getProvidersListFailure(err));
			});
	};
};

//Reset provider list
export const resetProviderState = () => {
	return (dispatch) => {
		dispatch(getProvidersListSuccess([]));
	};
};

//Updating a provider
export const updateProvider = (providerUuid, payload) => {
	return (dispatch) => {
		dispatch(updatingProvider());
		return ProviderService.updateProvider(providerUuid, payload)
			.then((res) => {
				dispatch(updatedProvider(res));
				return res;
			})
			.catch((err) => {
				dispatch(
					updatedProviderError(
						hydrateProviderCreateUpdateErrors(
							get(err, 'response.data.fields', {}) || {}
						)
					)
				);
				throw err;
			});
	};
};

const updatingProvider = () => {
	return {
		type: actionTypes.UPDATING_PROVIDER,
		payload: true,
	};
};

const updatedProvider = (payload) => {
	return {
		type: actionTypes.UPDATE_PROVIDER_SUCCESS,
		payload: payload,
	};
};

const updatedProviderError = (err) => {
	return {
		type: actionTypes.UPDATE_PROVIDER_FAILURE,
		payload: err,
	};
};

//Creating a provider
export const createProvider = (payload) => async (dispatch) => {
	let data;
	dispatch(creatingProvider());
	await ProviderService.createProvider(payload)
		.then((res) => {
			dispatch(createdProvider(res));
			data = res?.data?.data?.uuid;
		})
		.catch((err) => {
			dispatch(
				createdProviderError(
					hydrateProviderCreateUpdateErrors(
						get(err, 'response.data.fields', {}) || {}
					)
				)
			);
		});
	if (data) return Promise.resolve(data);
};

const creatingProvider = () => {
	return {
		type: actionTypes.CREATING_PROVIDER,
		payload: true,
	};
};

const createdProvider = (payload) => {
	return {
		type: actionTypes.CREATE_PROVIDER_SUCCESS,
		payload: payload,
	};
};

const createdProviderError = (err) => {
	return {
		type: actionTypes.CREATE_PROVIDER_FAILURE,
		payload: err,
	};
};

//Get provider specialties
export const getProviderSpecialties = () => {
	return (dispatch) => {
		dispatch(gettingProviderSpecialties());
		ProviderService.getProviderSpecialties()
			.then((res) => {
				dispatch(
					gotProviderSpecialties(
						get(res, 'data.data.provider_specialties')
					)
				);
			})
			.catch((err) => {
				dispatch(gotProviderSpecialtiesError(err));
			});
	};
};

const gettingProviderSpecialties = () => {
	return {
		type: actionTypes.GETTING_PROVIDER_SPECIALTIES,
		payload: true,
	};
};

const gotProviderSpecialties = (payload) => {
	return {
		type: actionTypes.GOT_PROVIDER_SPECIALTIES,
		payload: payload,
	};
};

const gotProviderSpecialtiesError = (err) => {
	return {
		type: actionTypes.GOT_PROVIDER_SPECIALTIES_ERROR,
		payload: err,
	};
};

//Get provider credentials
export const getProviderCredentials = () => {
	return (dispatch) => {
		dispatch(gettingProviderCredentials());
		ProviderService.getProviderCredentials()
			.then((res) => {
				dispatch(
					gotProviderCredentials(
						get(res, 'data.data.provider_credentials')
					)
				);
			})
			.catch((err) => {
				dispatch(gotProviderCredentialsError(err));
			});
	};
};

const gettingProviderCredentials = () => {
	return {
		type: actionTypes.GETTING_PROVIDER_CREDENTIALS,
		payload: true,
	};
};

const gotProviderCredentials = (payload) => {
	return {
		type: actionTypes.GOT_PROVIDER_CREDENTIALS,
		payload: payload,
	};
};

const gotProviderCredentialsError = (err) => {
	return {
		type: actionTypes.GOT_PROVIDER_CREDENTIALS_ERROR,
		payload: err,
	};
};
