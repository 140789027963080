import * as Sentry from '@sentry/react';
import axios from 'axios';
import { Loading } from 'gm-element-react';
import { get } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { v4 } from 'uuid';

import App from './components/App/App';
import AuthProvider from './components/Login/AuthProvider';
import UnAuthenticatedWrapper from './components/Login/unAuthenticatedWrapper';
import { Auth0Wrapper } from './components/Login/Auth0Wrapper';
import Auth0Login from './components/Login/Auth0Login';
import LoginWithEmail from './components/Login/LoginWithEmail';
import { unregister } from './registerServiceWorker';
import { getAccessToken } from './service/Storage';
import { store, persistor } from './store/configureStore';
import { successStatus, failedStatus } from './Utils/httpHandlers';

// Import styles from the @gm/common
import '@gm/common/style';
import './app.css';
import './index.css';

if (process.env.REACT_APP_GMI_ENV != 'prod')
	console.log(`app running on ${process.env.REACT_APP_GMI_ENV} environment`);
window.updateReceived = false;
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.REACT_APP_GMI_ENV,
		release: process.env.REACT_APP_GMI_TAG,
	});
}

axios.defaults.withCredentials = true;
axios.defaults.timeout = 60 * 4 * 1000 + 3000; // 4 mins
axios.interceptors.request.use(async (request) => {
	const gmiRole = get(store.getState(), ['me', 'activerole', 'uuid'], null);
	if (gmiRole) request.headers['gmi-role'] = gmiRole;
	request.headers['gmi-version'] = process.env.REACT_APP_GMI_TAG || '';
	if (request.headers['retry-counts'] === undefined)
		request.headers['retry-counts'] = 0;

	if ((!request.data) instanceof FormData) {
		request.headers['Content-Type'] = 'application/json;charset=UTF-8';
		request.headers['Accept'] = 'application/json;charset=UTF-8';
	}
	request.headers['gmi-request'] = v4();
	const token = getAccessToken();
	if (token) {
		request.headers['gmi-token'] = token;
	}

	return request;
});

axios.interceptors.response.use(
	(response) => {
		return successStatus(response, 'axios');
	},
	(error) => {
		return failedStatus(error, 'axios');
	}
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<div className='rootscroll' id='main-container'>
		<p className='browstext'>
			Oops! You will require a device with wider screen to run our app or
			maximize your browser window size.
		</p>
		<div className='lessres'>
			<Provider store={store}>
				<PersistGate
					loading={<Loading className='initialAppLoader' />}
					persistor={persistor}
				>
					<Auth0Wrapper>
						<Router>
							<UnAuthenticatedWrapper>
								<Switch>
									<Route
										exact
										path='/login'
										render={(props) => (
											<Auth0Login {...props} />
										)}
									/>									
									<Route
										exact
										path='/login/team'
										render={(props) => (
											<Auth0Login
												organizationId={
													process.env
														.REACT_APP_AUTH0_TEAM_ORG_ID
												}
												{...props}
											/>
										)}
									/>
									<Route
										exact
										path='/login/internal'
										render={(props) => (
											<Auth0Login
												organizationId={
													process.env
														.REACT_APP_AUTH0_INTERNAL_ORG_ID
												}
												{...props}
											/>
										)}
									/>
								</Switch>
							</UnAuthenticatedWrapper>
						</Router>
						<Router basename={'/ui'}>
							<AuthProvider>
								<Route>
									<App />
								</Route>
							</AuthProvider>
						</Router>
					</Auth0Wrapper>
				</PersistGate>
			</Provider>
		</div>
	</div>
);
unregister();
