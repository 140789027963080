//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//UI Libraries
import { Select, Cascader } from 'gm-element-react';

//Utils
import {
	documentDescriptionLengthValidator,
	documentDescripitonRequiredValidation,
	documentDescriptionValidation,
	documentTypeIsAdminMisc,
	documentTypeIsVisit,
} from '../../../../Utils/documentUtils.js';

const CreateNewDocumentV2Type = (props) => {
	const {
		description,
		documentSubType,
		documentType = null,
		documentTypes = [],
		encounterStringDisplay,
		encounters = [],
		relatedEncounter = null,
		setCurrentStage,
		setValue,
	} = props;

	const advanceStage = () => {
		canAdvance() && setCurrentStage('template');
	};

	const canAdvance = () => {
		if (documentTypeIsVisit(documentType)) {
			return documentType && relatedEncounter && isValidDescription();
		} else {
			return documentType && isValidDescription();
		}
	};

	const isValidDescription = () => {
		if (documentTypeIsAdminMisc(documentType)) {
			if (!documentDescripitonRequiredValidation(description)) {
				return false;
			} else if (!documentDescriptionLengthValidator(description)) {
				return false;
			}
		}
		if (description && !documentDescriptionValidation(description)) {
			return false;
		}
		return true;
	};

	const determineDescritionErrorMessage = () => {
		if (documentTypeIsAdminMisc(documentType)) {
			if (!documentDescripitonRequiredValidation(description)) {
				return null;
			} else if (!documentDescriptionLengthValidator(description)) {
				return (
					<div
						className='inputFieldError'
						style={{ position: 'absolute', top: '85px' }}
					>
						Description should not be more than 40 characters.{' '}
					</div>
				);
			}
		}

		if (description && !documentDescriptionValidation(description)) {
			return (
				<div
					className='inputFieldError'
					style={{ position: 'absolute', top: '85px' }}
				>
					Invalid description.{' '}
				</div>
			);
		}

		return null;
	};

	const documentTypeValue = [documentType, documentSubType].filter(
		(el) => el
	);
	const encounterMessage = documentTypeIsVisit(documentType)
		? 'Related Encounter can be changed later if necessary.'
		: 'Please select a Related Encounter if applicable. This can be changed later if necessary.';
	const selectValues = [
		{
			header: 'Document type',
			type: 'document',
			required: true,
			options: documentTypes,
			placeholder: 'Select document type',
			value: documentTypeValue,
			element: 'cascader',
		},
		{
			header: 'Related encounter',
			type: 'encounter',
			required: documentTypeIsVisit(documentType),
			encounterMessage: encounterMessage,
			options: encounters,
			placeholder: 'Select encounter',
			value: relatedEncounter,
			element: 'select',
		},
		{
			header: documentTypeIsAdminMisc(documentType)
				? 'Description (visible to patients upon document release)'
				: 'Description',
			type: 'description',
			required: documentTypeIsAdminMisc(documentType),
			encounterMessage: false,
			options: null,
			placeholder: 'Enter a description',
			value: description,
			element: 'textarea',
		},
	];
	const buttonClass = canAdvance()
		? 'createDocumentV2ActionButton'
		: 'createDocumentV2ActionButton createDocumentV2ActionButtonDisabled';

	return (
		<>
			<div className='createDocumentV2TypeContainer createDocumentV2TypeContainerBody'>
				{selectValues.map((obj) => (
					<div
						className='createDocumentInputContainer'
						data-testId='create-document-fields'
						key={obj.header}
						style={{ top: 'unset' }}
					>
						{obj.required && (
							<div style={{ position: 'relative' }}>
								{' '}
								<div className='createDocumentRequired'>*</div>
							</div>
						)}
						<div className='createDocumentInputHeader'>
							{obj.header}
						</div>
						{obj.element === 'select' ? (
							<>
								<Select
									className={
										'createDocumentInputSelect createDocumentV2TypeSelect'
									}
									placeholder={obj.placeholder || 'Select'}
									value={obj.value}
									onChange={(e) => setValue(e, obj.type)}
								>
									{obj.options.map((el) => {
										if (obj.type === 'encounter') {
											return (
												<Select.Option
													label={encounterStringDisplay(
														el
													)}
													value={`${el.uuid}`}
													key={`${el.uuid}`}
												>
													{encounterStringDisplay(el)}
												</Select.Option>
											);
										} else {
											return (
												<Select.Option
													label={
														el.display_name ||
														'Select'
													}
													value={el.key}
													key={el.key}
												>
													{el.display_name}
												</Select.Option>
											);
										}
									})}
								</Select>
								{obj.encounterMessage && (
									<div className='createDocumentRelatedEncounter'>
										{obj.encounterMessage || '--'}
									</div>
								)}
							</>
						) : get(obj, 'element', null) === 'cascader' ? (
							<Cascader
								className={
									'createDocumentInputSelect createDocumentV2TypeSelect'
								}
								placeholder={obj.placeholder || 'Select'}
								value={obj.value}
								onChange={(e) => setValue(e, obj.type)}
								options={obj.options || []}
								expandTrigger={'hover'}
							/>
						) : (
							<>
								<textarea
									data-testId='create-document-textarea'
									value={obj.value}
									placeholder={obj.placeholder}
									className='uploadDocumentDescription createDocumentTextArea'
									onChange={(e) => setValue(e, obj.type)}
								/>
								{determineDescritionErrorMessage()}
							</>
						)}
					</div>
				))}
			</div>
			<div className='createDocumentV2TypeButtonRow'>
				<div
					data-testId='advance-stage'
					className={buttonClass}
					onClick={advanceStage}
				>
					Next
				</div>
			</div>
		</>
	);
};

export default CreateNewDocumentV2Type;
