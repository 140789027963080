import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Sentry from '@sentry/react';

import { Loading } from 'gm-element-react';

import { RescheduleBookAppointment } from '../../../../actions/scheduling';
import EncounterService from '../../../../service/Encounter';
import { Header } from '../../../Common/V3/Header';
import { ModalWindow } from '../../../Common/V3/ModalWindow';
import { Page } from '../../../Common/V3/Page';
import RescheduleReasonDialog from '../../rescheduleAppointment/RescheduleReasonDialog';

import {
	ServiceInfoAndScheduling,
	Summary,
} from '../ScheduleAppointment/sections';

import {
	formToEditEncounterApiPayload,
	formToRescheduleApiPayload,
} from './api';
import { DataLoader } from './components/DataLoader';
import { initialValues, schema, validateForm } from './validation';

import './Page.scss';

export function RescheduleAppointment(props) {
	const [scheduleError, setScheduleError] = useState(null);
	const [showRescheduleReasonDialog, setShowRescheduleReasonDialog] =
		useState(null);
	return (
		<Page className='scheduleAppointmentV2'>
			<div className='scheduleAppointmentV2Container'>
				<Formik
					initialValues={initialValues}
					validate={(values) => validateForm(schema, values)}
					validateOnBlur={false}
					validateOnChange={false}
					onSubmit={async (values) => {
						try {
							const uuid = props.match.params.encounter_uuid;
							const payload = formToRescheduleApiPayload(values);
							await RescheduleBookAppointment(uuid, payload);

							await EncounterService.editencouter({
								data: {
									uuid,
									...formToEditEncounterApiPayload(values),
								},
							});

							const redirectUrl = `/app/patientdetail/${values.patientInfo.patientUUID}/0/2/${uuid}`;
							props.history.push(redirectUrl);
						} catch (e) {
							// Extract error message, might need to be tweaked
							// to be able produce meaningful message for common
							// error scenarios.
							let errorMessage = e.message;
							if (e.response?.data?.message) {
								if (e.response.data.message?.message) {
									errorMessage =
										e.response.data.message.message.toString();
								} else {
									errorMessage =
										e.response.data.message.toString();
								}
							}
							setScheduleError(errorMessage);
							console.error(e);
							Sentry.captureException(e);
						}
					}}
				>
					{({
						isSubmitting,
						setErrors,
						setFieldValue,
						setTouched,
						submitForm,
						validateForm,
						values,
					}) => (
						<>
							<DataLoader
								encounterUUID={
									props.match.params.encounter_uuid
								}
							/>
							{isSubmitting && <Loading fullscreen loading />}
							{scheduleError && (
								<ModalWindow
									onCancel={() => setScheduleError(null)}
									title='Appointment scheduling error'
								>
									<p>
										We were unable to schedule this
										appointment because of the following
										reason:
									</p>
									<p>{scheduleError}</p>
									<p>
										Please contact your support team for
										assistance
									</p>
								</ModalWindow>
							)}
							<div className='box main-form'>
								<Header>Rechedule Appointment</Header>
								<ServiceInfoAndScheduling
									sectionDataContainerName='serviceInfoAndScheduling'
									forceShowAppointmentSummary
									appointmentSummaryLabel='Current Appointment'
									appointmentSummaryEncounterUUID={
										props.match.params.encounter_uuid
									}
								/>
							</div>
							<div className='box summary'>
								<Summary
									sectionDataContainerName='summary'
									showPaymentSection={false}
									showLinkToPatientAccount
									submitForm={async () => {
										const err = await validateForm();
										setErrors(err);
										setTouched(err);
										if (Object.keys(err).length === 0) {
											setShowRescheduleReasonDialog(true);
										}
									}}
								/>
							</div>
							<RescheduleReasonDialog
								show_reschedulereasondialog={
									showRescheduleReasonDialog
								}
								RescheduleReasonDialogCancel={() =>
									setShowRescheduleReasonDialog(false)
								}
								reschedule_reason={values.rescheduleReason}
								setRescheduleReason={(v) =>
									setFieldValue('rescheduleReason', v)
								}
								callRescheduleAppointment={() => {
									setShowRescheduleReasonDialog(false);
									void submitForm();
								}}
							/>
						</>
					)}
				</Formik>
			</div>
		</Page>
	);
}
