//React & Redux
import React, { Fragment } from 'react';

//Lodash
import moment from 'moment';
import _ from 'lodash';
import get from 'lodash/get';

//UI Libraries
import Autocomplete from 'react-autocomplete';
import { Loading } from 'gm-element-react';

//Components
import AppointmentPatientInfoForm from './AppointmentPatientInfoForm.js';
import AppointmentSection from './AppointmentSection';

//Styles
import './css/select-patient.css';

class SelectPatientStage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchInput: '',
			haveReceivedResults: false,
			selectedPatient: null,
			loading: false,
			form: {
				firstName: '',
				lastName: '',
				DOB: '',
				partnerId: '',
				email: '',
				consentToTreat: '',
			},
		};
		//bindings
		this.handleInput = this.handleInput.bind(this);
		this.handleSearch = _.debounce(this.handleSearch.bind(this), 300);
		this.handleSelect = this.handleSelect.bind(this);
		this.blurInput = this.blurInput.bind(this);
		this.makeRequestObj = this.makeRequestObj.bind(this);
		this.formatRegex = this.formatRegex.bind(this);
		this.renderMenu = this.renderMenu.bind(this);
		this.renderPatientItem = this.renderPatientItem.bind(this);
		this.renderInputField = this.renderInputField.bind(this);
		this.determineItems = this.determineItems.bind(this);
		this.determineInputValue = this.determineInputValue.bind(this);
		this.showLoading = this.showLoading.bind(this);
		this.handleNavigateToAppointmentList =
			this.handleNavigateToAppointmentList.bind(this);
	}

	//functions
	componentDidMount() {
		const preloadedPatient = get(
			this,
			'props.history.location.state.breadcrumbs[1].patientData',
			null
		);
		const refInput = get(this, 'autocompleteInput.refs.input', null);
		if (refInput) {
			refInput.placeholder = 'Search by name or email';
			refInput.parentNode.style.zIndex = '1';
		}
		this.props.getConsenttotreat();
		this.props.getRefPrograms();
		this.props.getPlanTypesEnums();
		if (this.props.newAppointment && this.props.newAppointment.uuid) {
			this.props.getPatientDetail(this.props.newAppointment.uuid);
			const { id, uuid, firstName, lastName } = this.props.newAppointment;
			this.setState({
				selectedPatient: { id, uuid, firstName, lastName },
			});
		}
		if (preloadedPatient) {
			if (
				get(preloadedPatient, 'firstName', '') &&
				get(preloadedPatient, 'lastName', '') &&
				get(preloadedPatient, 'uuid', '') &&
				get(preloadedPatient, 'id', '')
			)
				this.handleSearch(
					`${get(preloadedPatient, 'lastName', '')}, ${get(
						preloadedPatient,
						'firstName',
						''
					)}`
				);
			if (!_.isNil(get(preloadedPatient, 'encounter_uuid', null))) {
				this.props.setROREncounterUUID(
					get(preloadedPatient, 'encounter_uuid')
				);
			}
			this.handleSelect(JSON.stringify(preloadedPatient));
			get(this, 'props.history', []).replace({
				pathname: '/app/appointments/schedule',
				state: {
					breadcrumbs: [
						{
							location: 'Appointments',
							url: '/app/appointments',
						},
						{
							location: 'Schedule Appointment',
							url: '/app/appointments/schedule',
						},
					],
				},
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.patientSearchResultsLoading &&
			!this.props.patientSearchResultsLoading &&
			!this.state.haveReceivedResults &&
			get(this, 'state.searchInput', '').length > 0
		) {
			this.setState({ haveReceivedResults: true });
		}

		if (
			get(prevState, 'searchInput', '').length > 0 &&
			get(this, 'state.searchInput', '').length === 0
		) {
			this.setState({ haveReceivedResults: false });
		}

		if (
			get(prevProps, 'gettingPatientDetail', false) &&
			!get(this, 'props.gettingPatientDetail', false)
		) {
			if (get(this, 'props.patientdetailError', null)) {
				this.props.enqueueSnackbar('Error in getting patient data', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			}
		}

		if (this.props.patientDetail != prevProps.patientDetail) {
			const newuuid = _.get(
				this.props,
				['patientDetail', '0', 'uuid'],
				0
			);
			const currentuuid = _.get(prevProps, [
				'patientDetail',
				'0',
				'uuid',
			]);
			const patient = get(this.props, 'patientDetail[0]', {});
			const form = { ...this.state.form };
			if (newuuid != currentuuid) {
				form['firstName'] = patient.first_name;
				form['lastName'] = patient.last_name;
				form['email'] = patient.email;
				form['consentToTreat'] = patient.consent_to_treat;

				form['primaryPhone'] = _.get(
					patient,
					['phones', 'primary', 'value'],
					''
				);
				form['primaryPhoneType'] = _.get(
					patient,
					['phones', 'primary', 'type'],
					null
				);
				form['secondaryPhone'] = _.get(
					patient,
					['phones', 'secondary', 'value'],
					''
				);
				form['secondaryPhoneType'] = _.get(
					patient,
					['phones', 'secondary', 'type'],
					null
				);

				form['hasGuardian'] = _.get(patient, ['has_guardian'], false);
				form['isUnBorn'] = _.get(patient, ['is_unborn'], false);
				form['guardianFirstName'] = _.get(
					patient,
					['guardian', 'first_name'],
					''
				);
				form['guardianLastName'] = _.get(
					patient,
					['guardian', 'last_name'],
					''
				);
				form['guardianDOB'] = _.get(patient, ['guardian', 'dob'], '');
				form['guardianRelationship'] = _.get(
					patient,
					['guardian', 'relationship'],
					''
				);
				form['guardianPrimaryPhone'] = _.get(
					patient,
					['guardian', 'phones', 'primary', 'value'],
					''
				);
				form['guardianPrimaryPhoneType'] = _.get(
					patient,
					['guardian', 'phones', 'primary', 'type'],
					null
				);
				form['guardianSecondaryPhone'] = _.get(
					patient,
					['guardian', 'phones', 'secondary', 'value'],
					''
				);
				form['guardianSecondaryPhoneType'] = _.get(
					patient,
					['guardian', 'phones', 'secondary', 'type'],
					null
				);
			}
			this.setState({ form });
			this.setState({ addressMethod: 'post' });
		}
	}

	handleInput(e) {
		this.setState({ searchInput: e.target.value }, this.handleSearch);
	}

	handleSearch(nameStr) {
		if (!_.isEmpty(_.trim(this.state.searchInput))) {
			const obj = this.makeRequestObj(nameStr || this.state.searchInput);
			this.props.getPatientsSearch(obj);
		}
	}

	handleSelect(e) {
		let obj;
		try {
			obj = JSON.parse(e);
		} catch (err) {
			obj = {
				firstName: null,
				lastName: null,
				uuid: null,
				id: null,
			};
		}
		this.setState(
			{
				selectedPatient: obj,
				searchInput: `${obj.lastName}, ${obj.firstName}`,
			},
			this.blurInput
		);
		this.props.setAppointmentPatient(obj);
	}

	blurInput() {
		get(
			this,
			'autocompleteInput.refs.input',
			document.createElement('input')
		).blur();
	}

	makeRequestObj(str) {
		return {
			q: str,
			component: 'scheduling',
		};
	}

	formatRegex(str) {
		return _.escapeRegExp(str);
	}

	renderPatientItem(patient) {
		const firstName = get(patient, 'first_name', '') || '';
		const lastName = get(patient, 'last_name', '') || '';
		const email = get(patient, 'email', '') || '';

		const firstNameMatchesArr = firstName.match(
			new RegExp(`(${this.formatRegex(this.state.searchInput)})`, 'i')
		);
		const firstNameFirstText = firstName.slice(
			0,
			get(firstNameMatchesArr, 'index', 0)
		);
		const firstNameSecondText = firstName.slice(
			get(firstNameMatchesArr, 'index', 0),
			get(firstNameMatchesArr, 'index', 0) +
				get(firstNameMatchesArr, '[1].length', 0)
		);
		const firstNameThirdText = firstName.slice(
			get(firstNameMatchesArr, 'index', 0) +
				get(firstNameMatchesArr, '[1].length', 0),
			get(firstName, 'length', 0)
		);

		const lastNameMatchesArr = lastName.match(
			new RegExp(`(${this.formatRegex(this.state.searchInput)})`, 'i')
		);
		const lastNameFirstText = lastName.slice(
			0,
			get(lastNameMatchesArr, 'index', 0)
		);
		const lastNameSecondText = lastName.slice(
			get(lastNameMatchesArr, 'index', 0),
			get(lastNameMatchesArr, 'index', 0) +
				get(lastNameMatchesArr, '[1].length', 0)
		);
		const lastNameThirdText = lastName.slice(
			get(lastNameMatchesArr, 'index', 0) +
				get(lastNameMatchesArr, '[1].length', 0),
			get(lastName, 'length', 0)
		);

		const emailMatchesArr = email.match(
			new RegExp(`(${this.formatRegex(this.state.searchInput)})`, 'i')
		);
		const emailFirstText = email.slice(0, get(emailMatchesArr, 'index', 0));
		const emailSecondText = email.slice(
			get(emailMatchesArr, 'index', 0),
			get(emailMatchesArr, 'index', 0) +
				get(emailMatchesArr, '[1].length', 0)
		);
		const emailThirdText = email.slice(
			get(emailMatchesArr, 'index', 0) +
				get(emailMatchesArr, '[1].length', 0),
			get(email, 'length', 0)
		);

		return (
			<div
				className='scheduleAppointmentInputFieldItem'
				key={get(patient, 'uuid', '') || get(patient, 'email', '')}
			>
				<div className='scheduleAppointmentInputFieldItemName'>
					<span>{lastNameFirstText}</span>
					<span className='bolded'>{lastNameSecondText}</span>
					<span>{lastNameThirdText}</span>, &nbsp;
					<span>{firstNameFirstText}</span>
					<span className='bolded'>{firstNameSecondText}</span>
					<span>{firstNameThirdText}</span>
				</div>
				<div className='scheduleAppointmentInputFieldItemEmail'>
					<span>{emailFirstText}</span>
					<span className='bolded'>{emailSecondText}</span>
					<span>{emailThirdText}</span>
				</div>
			</div>
		);
	}

	renderEmptyOption(obj) {
		return (
			<div className='scheduleAppointmentInputFieldItem'>
				<span className='scheduleAppointmentInputFieldEmptyMessage'>
					{obj.text || 'No matching patient'}
				</span>
			</div>
		);
	}

	renderInputField(props) {
		return (
			<input
				{...props}
				className='inputField inputFieldText'
				style={{ width: '99%', marginBottom: 'unset' }}
			/>
		);
	}

	renderMenu(items, value, style) {
		const patients = get(this, 'props.patientSearchResults.data.data', []);
		if (
			(patients.length === 0 || !Array.isArray(patients)) &&
			get(this, 'state.haveReceivedResults', false) &&
			get(this, 'state.searchInput', '')
		) {
			return (
				<div
					className='scheduleAppointmentInputSearchResults'
					children={items}
					style={{
						padding: '4px 5px',
						fontSize: '13px',
						color: '#7b8291',
					}}
				/>
			);
		} else {
			return (
				<div
					className='scheduleAppointmentInputSearchResults'
					style={{ ...style }}
					children={items}
				/>
			);
		}
	}

	determineItems() {
		const items = get(this, 'props.patientSearchResults.data.data', []);
		const searchQuery = this.state.searchInput || '';

		if (searchQuery.length === 0) return [];
		else if (searchQuery && items.length > 0) return items;
		else {
			if (this.props.patientSearchResultsLoading) return [];
			else
				return [
					{
						text: 'No matching patient',
						first_name: 'first_name',
						last_name: 'last_name',
						email: 'email',
						dob: 'dob',
						id: 'id',
						phone: 'phone',
						referral_program: 'referral_program',
						uuid: 'uuid',
					},
				];
		}
	}

	determineInputValue() {
		const patientsArr = get(
			this,
			'props.patientSearchResults.data.data',
			[]
		);
		const filteredArr = patientsArr.filter(
			(el) => el.uuid === this.state.uuid
		);
		if (filteredArr && filteredArr[0])
			return `${filteredArr[0].last_name}, ${filteredArr[0].first_name}`;
	}

	showLoading(val) {
		this.setState({ loading: val });
	}

	handleNavigateToAppointmentList() {
		this.props.history.push('/app/appointments');
	}

	render() {
		const { rorEncounterUUID, encountersLoading, providers } = this.props;
		const isPatientApptInfoFormShow = !_.isNil(rorEncounterUUID)
			? !encountersLoading &&
			  this.state.form.firstName &&
			  _.get(providers, ['length'], 0) > 0
			: this.state.form.firstName;
		return (
			<Fragment>
				<div className='schedulepatientbg'>
					<Loading
						loading={
							this.state.loading ||
							get(this, 'props.selectPatientLoading', false)
						}
						className='loading-select-patient-form'
					>
						<AppointmentSection
							heading='Select Patient'
							description='Search and select the Patient first to proceed with scheduling'
							isRequired={true}
							horizontalLine={isPatientApptInfoFormShow}
						>
							<div className='scheduleAppointmentSelectPatientContainer'>
								<div className='scheduleAppointmentInputContainer'>
									<div className='appointmentRequiredField'>
										*
									</div>
									<div className='scheduleAppointmentInputTitle'>
										Search for a Patient
									</div>
									<Autocomplete
										style={{ width: '60%', zIndex: '1' }}
										value={this.state.searchInput}
										getItemValue={(item) => {
											return JSON.stringify({
												uuid: item.uuid,
												firstName: item.first_name,
												lastName: item.last_name,
												id: item.id,
											});
										}}
										onChange={(e) => this.handleInput(e)}
										onSelect={(e) =>
											get(
												this,
												'props.patientSearchResults.data.data',
												[]
											).length > 0
												? this.handleSelect(e)
												: false
										}
										items={this.determineItems()}
										renderItem={
											get(
												this,
												'props.patientSearchResults.data.data',
												[]
											).length === 0
												? this.renderEmptyOption
												: this.renderPatientItem
										}
										renderInput={this.renderInputField}
										renderMenu={this.renderMenu}
										placeholder={'Search by name or email'}
										ref={(el) =>
											(this.autocompleteInput = el)
										}
									/>
								</div>
							</div>
						</AppointmentSection>
						{isPatientApptInfoFormShow && (
							<Fragment>
								<AppointmentPatientInfoForm
									initialValues={{
										...this.state.form,
										guardianDOB:
											this.state.form.guardianDOB &&
											moment(
												this.state.form.guardianDOB
											).isValid()
												? moment(
														this.state.form
															.guardianDOB
												  ).format('MM/DD/YYYY')
												: null,
									}}
									consenttotreat={this.props.consenttotreat}
									onSubmit={this.props.sendRequest}
									handleNavigateToAppointmentList={
										this.handleNavigateToAppointmentList
									}
									determineEmailStatus={
										this.props.determineEmailStatus
									}
									openAddressModal={
										this.props.openAddressModal
									}
									hasAllAddressTypes={get(
										this,
										'props.hasAllAddressTypes',
										false
									)}
									addresses={
										get(this, 'props.addresses', null) || []
									}
									renderSwitch={this.props.renderSwitch}
									addressCompletionWarning={get(
										this,
										'props.addressCompletionWarning',
										false
									)}
									renderAddressSection={
										this.props.renderAddressSection
									}
									setFirstStageSubmitAttempt={
										this.props.setFirstStageSubmitAttempt
									}
								/>
							</Fragment>
						)}
					</Loading>
				</div>
			</Fragment>
		);
	}
}

export default SelectPatientStage;
