//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../assets/exit-alert.svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Components
import Tooltip from './Tooltip';

//CSS
import './ListModal.css';

class ListModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tooltipVisible: false,
		};
		//bindings
		this.renderHTMLContent = this.renderHTMLContent.bind(this);
		this.renderTextOnlyContent = this.renderTextOnlyContent.bind(this);
		this.determineWidthOverflow = this.determineWidthOverflow.bind(this);
	}
	//functions

	componentDidMount() {
		this.determineWidthOverflow(this.subtitle);
	}

	renderHTMLContent() {
		const htmlContent = get(this, 'props.htmlContent', null);
		if (htmlContent) {
			return htmlContent;
		}
	}

	renderTextOnlyContent() {
		const textContent = get(this, 'props.textContent', null);
		if (Array.isArray(textContent) && get(textContent, 'length', 0) > 0) {
			return textContent.map((el) => {
				return (
					<div className='listModalBodyRow' key={el}>
						{el || '--'}
					</div>
				);
			});
		} else {
			return (
				<div className='listModalBodyRow'>{textContent || '--'}</div>
			);
		}
	}

	determineWidthOverflow(el = this.subtitle) {
		if (_.isElement(el) && el) {
			const offsetWidth = get(el, 'offsetWidth', 0);
			const scrollWidth = get(el, 'scrollWidth', 0);
			if (scrollWidth > offsetWidth)
				this.setState({ tooltipVisible: true });
			else this.setState({ tooltipVisible: false });
		} else {
			this.setState({ tooltipVisible: false });
		}
	}

	render() {
		const tooltipVisible = get(this, 'state.tooltipVisible', false);
		const textOnly = get(this, 'props.textOnly', false);
		const subtitle = get(this, 'props.subtitle', null);
		return (
			<div className='listModalContainer'>
				<div className='listModalTopRowContainer'>
					<div className='listModalTopRowTitleSubtitleContainer'>
						<div
							className='listModalTopRowTitle'
							style={{ paddingTop: subtitle ? 'unset' : '8px' }}
						>
							{get(this, 'props.title', null) ||
								'Associated list'}
						</div>
						{subtitle && (
							<Tooltip
								className='alert_dob_tooltip'
								effect='dark'
								content={subtitle}
								placement='top-start'
								disabled={!tooltipVisible}
							>
								<div
									className='listModalTopRowSubtitle'
									ref={(el) => (this.subtitle = el)}
								>
									{subtitle}
								</div>
							</Tooltip>
						)}
					</div>
					<div className='chargeModalClose'>
						<ReactSVG
							src={xIcon}
							style={{
								position: 'relative',
								bottom: '2px',
								right: '1.25px',
							}}
							onClick={(e) => {
								this.props.close();
							}}
						/>
					</div>
				</div>
				<div className='listModalBodyContainer'>
					{textOnly
						? this.renderTextOnlyContent()
						: this.renderHTMLContent()}
				</div>
			</div>
		);
	}
}

export default ListModal;
