//React & Redux
import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Button } from 'gm-element-react';
//Styles
import './pill.css';

class ClearPill extends Component {
	render() {
		const { onClick } = this.props;
		return (
			<div style={{ float: 'left' }}>
				<Button
					className='pill-clear-btn'
					type='primary'
					onClick={onClick}
				>
					Clear
				</Button>
			</div>
		);
	}
}
ClearPill.propTypes = {
	onClick: PropTypes.func.isRequired,
};

export default ClearPill;
