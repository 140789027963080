import classnames from 'classnames';
import React from 'react';
import InputMask from 'react-input-mask';

import { FormField, IFormFieldProps } from './FormField';

import './PhoneNumberField.scss';

interface IProps extends IFormFieldProps {
	value?: string;
	onChange: (value: string) => void;
	disabled?: boolean;
	mask?: string;
	placeholder?: string;
}

export const PhoneNumberField: React.FC<IProps> = (props) => {
	const {
		className,
		name,
		label,
		required,
		error,
		value,
		tooltip,
		onChange,
		disabled,
		mask,
		placeholder,
	} = props;
	return (
		<FormField
			className={classnames('gm-phone-number', className)}
			name={name}
			label={label}
			required={required}
			error={error}
			tooltip={tooltip}
		>
			<InputMask
				name={name}
				onChange={(event) => {
					onChange(event.target.value);
				}}
				value={value}
				disabled={disabled}
				className={classnames('inputFieldText el-input__inner', {
					'is-disabled': disabled,
				})}
				mask={mask ?? '+1 (999) 999-9999'}
				placeholder={placeholder}
			/>
		</FormField>
	);
};
