import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loading } from 'gm-element-react';

import { encounterdetail } from '../../../../../actions/encounter';
import {
	patientdetail,
	getReferralPrograms,
} from '../../../../../actions/patients';
import {
	getDefaultSchedulingOptions,
	getReferralProgramDetail,
} from '../../../../../actions/referralprograms/referralprograms';
import { LoadAppointmentProvider } from '../../../../../actions/scheduling';

function useEncounterAndPatient(encounterUUID) {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(encounterdetail(encounterUUID));
	}, []);
	const { encounter, loadingEncounter } = useSelector((store) => ({
		encounter: store.encounterdetail.encounter,
		loadingEncounter: store.encounterdetail.gettingEncounterDetailLoading,
	}));

	useEffect(() => {
		if (encounter.user_uuid && !loadingEncounter) {
			dispatch(patientdetail(encounter.user_uuid));
			dispatch(
				getDefaultSchedulingOptions({
					partner_uuid: encounter.partner_uuid,
				})
			);
			dispatch(getReferralPrograms());
			dispatch(
				getReferralProgramDetail({
					partner_uuid: encounter.partner_uuid,
				})
			);
		}
	}, [encounter, loadingEncounter]);

	const {
		patient,
		loadingPatient,
		referralProgramsList,
		scheduling,
		referrals,
	} = useSelector((store) => ({
		patient: store.patientdetail.patientdetail?.[0],
		loadingPatient: store.patientdetail.gettingPatientDetail,
		referralProgramsList: store.referralProgramsList,
		scheduling: store.Scheduling,
		referrals: store.referralPrograms?.referralPrograms?.data,
	}));

	useEffect(() => {
		if (patient && !loadingPatient) {
			dispatch(LoadAppointmentProvider(patient.id));
		}
	}, [patient, loadingPatient]);

	return {
		encounter,
		patient,
		provider: scheduling.providers.find(
			(it) => it.uuid === encounter.provider_uuid
		),
		program: referrals?.find((it) => it.uuid === encounter.partner_uuid),
		loading:
			loadingEncounter ||
			loadingPatient ||
			!patient ||
			referralProgramsList.loadedReferralProgramDetail.loading ||
			referralProgramsList.defaultSchedulingOptions.loading ||
			!scheduling.providers.length ||
			referrals?.gettingReferralPrograms,
	};
}

/**
 * Fetches and sets encounter and patient information into Formik state
 */
export function DataLoader({ encounterUUID }) {
	const { encounter, patient, provider, program, loading } =
		useEncounterAndPatient(encounterUUID);
	const { setFieldValue } = useFormikContext();

	useEffect(() => {
		if (!loading) {
			if (patient) {
				setFieldValue('patientInfo.patientId', patient.id);
				setFieldValue('patientInfo.patientUUID', patient.uuid);
				setFieldValue(
					'patientInfo.patientFirstName',
					patient.first_name
				);
				setFieldValue('patientInfo.patientLastName', patient.last_name);
				setFieldValue('patientInfo.dateOfBirth', patient.dob);
			}

			if (program) {
				setFieldValue('patientInfo.associatedProgram', program.id);
			}

			if (provider) {
				setFieldValue('serviceInfoAndScheduling.provider', provider.id);
			}

			if (encounter) {
				setFieldValue(
					'serviceInfoAndScheduling.timezone',
					encounter.timezone
				);
				setFieldValue(
					'serviceInfoAndScheduling.country',
					encounter.country_of_visit
				);
				setFieldValue(
					'serviceInfoAndScheduling.state',
					encounter.state_of_visit
				);
				setFieldValue(
					'serviceInfoAndScheduling.consultationType',
					encounter.consulatation_id
				);
				setFieldValue(
					'serviceInfoAndScheduling.specialty',
					encounter.vsee_specialty
				);

				setFieldValue(
					'serviceInfoAndScheduling.additionalConferenceLine',
					encounter.additional_conference_line ?? ''
				);
			}
		}
	}, [encounter, patient, provider, program, loading]);

	return loading ? <Loading fullscreen loading /> : null;
}
