import { useEffect, useState } from 'react';
import SessionService from '../../service/Session';

/**
 * Handles access to pages that do not require auth; 
 */
const unAuthenticatedWrapper = function (props) {
	const [isAuthenticated, setIsAuthenticated] = useState(null);
	
	useEffect(() => {
		async function check() {
			try {
				await SessionService.me();
				setIsAuthenticated(true);
			} catch {
				setIsAuthenticated(false);
			}
		}
		void check();
	}, []);

	return !isAuthenticated && props.children;
};

export default unAuthenticatedWrapper;
