//React & Redux
import React from 'react';

//UI Libraries
import { Dialog, Layout } from 'gm-element-react';

//Other Libraries

//Utils
import {
	fetalSexEnum,
	cfDNAPrenatalRiskRadioEnum,
	priorRiskItemEnum,
	residualRiskItemEnum,
} from '../../../../utils';

//Styles
import './css/view-cfdna-prenatalscreening-full-detail.css';

//Components
import Icon from '../../../Common/Icon';

const fieldNameLabelArray = [
	{
		groupLabel: 'Aneuploidies',
		subGroup: [
			{
				id: 'gm:00000128',
				subGroupLabel: 'Trisomy 13',
			},
			{
				id: 'gm:00000130',
				subGroupLabel: 'Trisomy 18',
			},
			{
				id: 'gm:00000131',
				subGroupLabel: 'Trisomy 21',
			},
			{
				id: 'gm:00000132',
				subGroupLabel: 'Trisomy 22',
			},
			{
				id: 'gm:00000129',
				subGroupLabel: 'Trisomy 16',
			},
			{
				id: 'gm:00000133',
				subGroupLabel: 'Triplody',
			},
		],
	},
	{
		groupLabel: 'Microdeletions',
		subGroup: [
			{
				id: 'gm:00000134',
				subGroupLabel: '1p36 deletion syndrome',
			},
			{
				id: 'gm:00000135',
				subGroupLabel: 'Wolf-Hirschhorn syndrome',
			},
			{
				id: 'gm:00000136',
				subGroupLabel: 'Cri-du-chat syndrome',
			},
			{
				id: 'gm:00000137',
				subGroupLabel: '8q24 deletion syndrome',
			},
			{
				id: 'gm:00000138',
				subGroupLabel: 'Jacobsen syndrome',
			},
			{
				id: 'gm:00000139',
				subGroupLabel: 'Angelman syndrome/Prader-Willi syndrome',
			},
			{
				id: 'gm:00000140',
				subGroupLabel: '22q11 deletion syndrome',
			},
		],
	},
	{
		groupLabel: 'Sex Aneuploides',
		subGroup: [
			{
				id: 'gm:00000141',
				subGroupLabel: 'X',
			},
			{
				id: 'gm:00000142',
				subGroupLabel: 'XXY',
			},
			{
				id: 'gm:00000143',
				subGroupLabel: 'XYY',
			},
			{
				id: 'gm:00000144',
				subGroupLabel: 'XXX',
			},
		],
	},
];

const ViewCFDNAPrenatalScreeningFullDetail = (props) => {
	const {
		hideViewCFDNAPrenatalScreeningFullDetailFunc,
		cfDNAPrenatalScreeningFindings = [],
	} = props;

	const closeViewCFDNAPrenatalScreeningFullDetail = () => {
		hideViewCFDNAPrenatalScreeningFullDetailFunc();
	};

	const displayFetalFraction = () => {
		let _fetalFractionVal = '--';
		let _fetalFractionUnit = '';

		for (const key in cfDNAPrenatalScreeningFindings) {
			if (
				cfDNAPrenatalScreeningFindings[
					key
				]?.riskName?.id.toLowerCase() === 'gm:00000065'
			) {
				_fetalFractionVal =
					cfDNAPrenatalScreeningFindings[key]?.riskType?.value;
				_fetalFractionUnit =
					cfDNAPrenatalScreeningFindings[key]?.riskType?.unit;
			}
		}
		return _fetalFractionVal + _fetalFractionUnit;
	};

	const displayFetalSex = () => {
		let _fetalSex = '--';
		for (const key in cfDNAPrenatalScreeningFindings) {
			if (
				cfDNAPrenatalScreeningFindings[
					key
				]?.riskName?.id.toLowerCase() === 'gm:00000066'
			) {
				const _fetalSexEnum = { 'gm:00000061': 1, 'gm:00000062': 1 };

				if (
					cfDNAPrenatalScreeningFindings[
						key
					]?.riskType?.id.toLowerCase() in _fetalSexEnum
				) {
					const fetalSex = fetalSexEnum.find(
						(f) =>
							f.key.toLowerCase() ===
							cfDNAPrenatalScreeningFindings[
								key
							]?.riskType?.id.toLowerCase()
					);
					_fetalSex =
						fetalSex?.display_name ||
						'Sex chromosomes not reported';
				}
			}
		}
		return _fetalSex;
	};

	return (
		<Dialog
			customClass='view-cfdna-prenatal-screening-fulldetail-dialog'
			title={
				<span className='header'>
					cfDNA Prenatal Screening Finding
					<span
						className='close-btn-icon'
						onClick={closeViewCFDNAPrenatalScreeningFullDetail}
					>
						<Icon icon='close' className='close-icon' />
					</span>
				</span>
			}
			size='tiny'
			modal={true}
			closeOnPressEscape={true}
			closeOnClickModal={false}
			showClose={false}
			visible={true}
			onCancel={closeViewCFDNAPrenatalScreeningFullDetail}
		>
			<Dialog.Body>
				<section className='group-section'>
					<Layout.Row gutter='26'>
						<Layout.Col span='10'>
							<span className='field-label'>Fetal Fraction</span>
							<span className='field-value'>
								{displayFetalFraction()}
							</span>
						</Layout.Col>
						<Layout.Col span='8'>
							<span className='field-label'>Fetal Sex</span>
							<span className='field-value'>
								{displayFetalSex()}
							</span>
						</Layout.Col>
					</Layout.Row>
				</section>

				<AneuploidiesGroup
					fieldNameLabelArray={fieldNameLabelArray}
					cfDNAPrenatalScreeningFindings={
						cfDNAPrenatalScreeningFindings
					}
				/>
			</Dialog.Body>

			<Dialog.Footer className='dialog-footer'></Dialog.Footer>
		</Dialog>
	);
};

export default ViewCFDNAPrenatalScreeningFullDetail;

const getCFDNAPrenatalRiskType = (riskTypeID) => {
	const cfDNAPrenatalRiskType = cfDNAPrenatalRiskRadioEnum.find(
		(r) => r.key.toLowerCase() === riskTypeID
	);
	return cfDNAPrenatalRiskType?.display_name || '--';
};

const AneuploidiesGroup = (props) => {
	const { fieldNameLabelArray = {}, cfDNAPrenatalScreeningFindings = [] } =
		props;
	return fieldNameLabelArray.map((item) => {
		return (
			<section key={item.groupLabel} className='group-section'>
				<h2 className='module-header'>{item.groupLabel}</h2>
				{item.subGroup.map((item) => {
					return (
						<RiskItem
							key={item.subGroupLabel}
							riskSubGroup={item}
							cfDNAPrenatalScreeningFindings={
								cfDNAPrenatalScreeningFindings
							}
						/>
					);
				})}
			</section>
		);
	});
};

const RiskItem = (props) => {
	const { riskSubGroup = {}, cfDNAPrenatalScreeningFindings = [] } = props;
	let priorRiskVal = '';
	let residualRiskVal = '';

	return cfDNAPrenatalScreeningFindings.map((finding) => {
		if (finding?.riskName?.id.toLowerCase() === riskSubGroup.id) {
			const _riskTypeID = finding?.riskType?.id.toLowerCase();

			finding.riskPriorResidual.map((item) => {
				if (item?.kind?.id.toLowerCase() in priorRiskItemEnum) {
					priorRiskVal = item?.value?.value;
				}

				if (item?.kind?.id.toLowerCase() in residualRiskItemEnum) {
					residualRiskVal = item?.value?.value;
				}
			});

			return (
				<Layout.Row key={riskSubGroup.uuid} gutter='12'>
					<Layout.Col className='risk-group-label'>
						{riskSubGroup.subGroupLabel}
					</Layout.Col>
					<Layout.Col span='10'>
						<div className='field-label'>Risk</div>
						<span className='field-value'>
							{getCFDNAPrenatalRiskType(_riskTypeID)}
						</span>
					</Layout.Col>
					<Layout.Col span='7'>
						<span className='field-label'>Prior Test Risk</span>
						<span className='field-value'>
							{priorRiskVal || '--'}
						</span>
					</Layout.Col>
					<Layout.Col span='7'>
						<span className='field-label'>Residual Risk</span>
						<span className='field-value'>
							{residualRiskVal || '--'}
						</span>
					</Layout.Col>
				</Layout.Row>
			);
		}
	});
};
