//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import checkmarkChecked from './../../assets/checkmark.svg';
import checkmarkUnchecked from './../../assets/export.svg';

//Lodash
import { get } from 'lodash';

//Utils

//UI Libraries
import { DateRangePicker } from 'gm-element-react';

//Components
import CommonCheckbox from './../Common/Checkbox.js';

class MoreFiltersBody extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.handleChange = this.handleChange.bind(this);
		this.getCurrentValueDateRange =
			this.getCurrentValueDateRange.bind(this);
		this.renderElement = this.renderElement.bind(this);
		this.formulateShortcuts = this.formulateShortcuts.bind(this);
	}
	//functions

	handleChange(e) {
		if (get(this, 'props.hasSetStateFunc', false)) {
			const value = get(e, 'target.value', null);
			if (value === 'false') {
				this.props.setInternalValue(false);
			} else if (value === 'true') {
				this.props.setInternalValue(true);
			} else if (
				get(this, 'props.convertStrsToNums', false) &&
				value.match(/^-?\d+$/gi)
			) {
				this.props.setInternalValue(Number(value));
			} else {
				this.props.setInternalValue(value);
			}
		}
	}

	getCurrentValueDateRange(el) {
		if (
			(get(this, 'props.internalValue.dateRange', []) || []).length === 0
		) {
			return [get(el, 'startDate', null), get(el, 'endDate', null)];
		} else return get(this, 'props.internalValue.dateRange', null);
	}

	renderElement(el, i) {
		if (el) {
			const title = get(el, 'title', null);
			const internalKey = get(el, 'submitKey', '');

			switch (get(el, 'type', null)) {
				case 'dateRangePicker':
					return (
						<div className='moreFiltersSubGroup' key={i}>
							<div className='moreFiltersSubTitle'>
								Date Range
							</div>
							<DateRangePicker
								className='moreFiltersDateRangePicker'
								value={this.getCurrentValueDateRange(el)}
								placeholder={get(
									el,
									'endDatePlaceholder',
									null
								)}
								format={get(el, 'format', 'MM/dd/yyyy')}
								onChange={(e) =>
									this.props.setInternalValue('dateRange', e)
								}
								isReadOnly={true}
								align={'left'}
								shortcuts={
									this.formulateShortcuts(
										get(el, 'shortcuts', null),
										i
									) || null
								}
								ref={(el) =>
									(this['dateRangerPicker' + i] = el)
								}
							/>
						</div>
					);
				case 'singleSelect':
					if (get(el, ['data'], []).length) {
						const internalValues = get(
							this,
							['props', 'internalValue', internalKey],
							''
						);
						return (
							<div
								className='moreFiltersSubGroup'
								key={i}
								style={{ marginBottom: '24px' }}
							>
								<div className='moreFiltersSubTitle'>
									<span>{title}</span>
								</div>
								<div className='moreFiltersOptions'>
									{(get(el, ['data'], []) || []).map(
										(option, i) => {
											const isSelected =
												internalValues === option.key;
											return (
												<div
													className={
														isSelected
															? 'selected'
															: ''
													}
													onClick={() =>
														this.props.setInternalValue(
															internalKey,
															option.key
														)
													}
													key={i}
												>
													{option.display_name}
												</div>
											);
										}
									)}
								</div>
							</div>
						);
					}
					break;
				case 'checkbox':
					const internalValue = get(
						this,
						['props', 'internalValue', internalKey],
						false
					);
					return (
						<div
							className='moreFiltersSubGroup'
							key={i}
							style={{ padding: 'unset' }}
						>
							<div
								className='moreFiltersSubTitle'
								style={{ paddingLeft: '16px', margin: 'unset' }}
							>
								{get(el, 'title', null)}
							</div>
							<div
								className='singleSelectModalRow'
								onClick={(e) => {
									this.props.setInternalValue(
										internalKey,
										!internalValue
									);
								}}
							>
								<CommonCheckbox
									checked={internalValue}
									onChangeFunc={this.props.setInternalValue}
									noOnChangeEvtArg={true}
									additionalOnChangeArgs={[
										internalKey,
										!internalValue,
									]}
									topOverride={2}
								/>
								<div
									className='multiSelectCheckboxLabel'
									style={{
										color: internalValue
											? '#7b26c9'
											: '#262837',
									}}
								>
									{get(el, 'text', null)}
								</div>
							</div>
						</div>
					);
			}
		}
	}

	formulateShortcuts(shortcuts, i) {
		if (shortcuts && Array.isArray(shortcuts)) {
			return shortcuts.map((el) => {
				return {
					text: get(el, 'text', 'Date'),
					onClick: (e) => {
						this.props.setInternalValue(
							'dateRange',
							get(el, 'newValues', null)
						);
						this['dateRangerPicker' + i].togglePickerVisible();
					},
				};
			});
		} else return null;
	}

	render() {
		const data = get(this, 'props.data', []) || [];
		return (
			<div className='moreFiltersModalBodyContainer'>
				{data.map((el, i) => {
					return this.renderElement(el, i);
				})}
			</div>
		);
	}
}

export default MoreFiltersBody;
