//React & Redux
import React, { useState } from 'react';

//Other Libraries
import { get, isEmpty, isNil, toNumber } from 'lodash';
import { Dialog, Button, Layout, Select, Input } from 'gm-element-react';

//Styles
import './css/add-edit-cfdna-prenatalscreening-modal.css';

//Components
import Icon from '../../../Common/Icon';
import RadioGroup from '../../../Common/controls/RadioGroup/RadioGroup';

//Utils
import { fetalSexEnum, cfDNAPrenatalRiskRadioEnum } from '../../../../utils';

const fieldNameLabelArray = [
	{
		groupLabel: 'Aneuploidies',
		subGroup: [
			{
				subGroupName: 'trisomy13',
				subGroupLabel: 'Trisomy 13',
			},
			{
				subGroupName: 'trisomy18',
				subGroupLabel: 'Trisomy 18',
			},
			{
				subGroupName: 'trisomy21',
				subGroupLabel: 'Trisomy 21',
			},
			{
				subGroupName: 'trisomy22',
				subGroupLabel: 'Trisomy 22',
			},
			{
				subGroupName: 'trisomy16',
				subGroupLabel: 'Trisomy 16',
			},
			{
				subGroupName: 'triplody',
				subGroupLabel: 'Triplody',
			},
		],
	},
	{
		groupLabel: 'Microdeletions',
		subGroup: [
			{
				subGroupName: 'oneP36DeletionSyndrome',
				subGroupLabel: '1p36 deletion syndrome',
			},
			{
				subGroupName: 'wolfHirschhornSyndrome',
				subGroupLabel: 'Wolf-Hirschhorn syndrome',
			},
			{
				subGroupName: 'criDuChatSyndrome',
				subGroupLabel: 'Cri-du-chat syndrome',
			},
			{
				subGroupName: 'eightQ24DeletionSyndrome',
				subGroupLabel: '8q24 deletion syndrome',
			},
			{
				subGroupName: 'jacobsenSyndrome',
				subGroupLabel: 'Jacobsen syndrome',
			},
			{
				subGroupName: 'angelmanPraderWilliSyndrome',
				subGroupLabel: 'Angelman syndrome/Prader-Willi syndrome',
			},
			{
				subGroupName: 'twenty2Q11DeletionSyndrome',
				subGroupLabel: '22q11 deletion syndrome',
			},
		],
	},
	{
		groupLabel: 'Sex Aneuploides',
		subGroup: [
			{
				subGroupName: 'xSex',
				subGroupLabel: 'X',
			},
			{
				subGroupName: 'xxySex',
				subGroupLabel: 'XXY',
			},
			{
				subGroupName: 'xyySex',
				subGroupLabel: 'XYY',
			},
			{
				subGroupName: 'xxxSex',
				subGroupLabel: 'XXX',
			},
		],
	},
];

const AddEditCFDNAPrenatalScreeningModal = (props) => {
	const {
		hideAddEditCFDNAPrenatalScreeningModalFunc,
		modalMode = 'add',
		saveCFDNAPrenatalScreeningFinding,
	} = props;

	const [formdata, setFormdata] = useState({
		fetalFraction: null,
		fetalSex: null,
		trisomy13: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		trisomy18: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		trisomy21: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		trisomy22: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		trisomy16: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		triplody: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		oneP36DeletionSyndrome: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		wolfHirschhornSyndrome: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		criDuChatSyndrome: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		eightQ24DeletionSyndrome: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		jacobsenSyndrome: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		angelmanPraderWilliSyndrome: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		twenty2Q11DeletionSyndrome: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		xSex: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		xxySex: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		xyySex: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
		xxxSex: {
			risk: null,
			priorTestRisk: null,
			afterTestRisk: null,
			priorTestRiskUnit: null,
			afterTestRiskUnit: null,
		},
	});
	const [errors, setErrors] = useState({
		errors: {},
	});

	const isEditMode = () => {
		return modalMode == 'edit';
	};

	const closeAddEditCFDNAPrenatalScreeningModal = () => {
		hideAddEditCFDNAPrenatalScreeningModalFunc();
	};

	const validateForm = (formdata) => {
		const _errors = {};
		const _formdata = formdata;

		if (_formdata['formdata']) {
			delete _formdata['formdata'];
		}

		if (
			isNil(_formdata['fetalFraction']) ||
			isEmpty(_formdata['fetalFraction'])
		) {
			_errors['fetalFraction'] = 'Fetal Fraction is required';
		}
		if (isNil(_formdata['fetalSex'])) {
			_errors['fetalSex'] = 'Fetal Sex is required';
		}

		for (const key in _formdata) {
			if (typeof _formdata[key] === 'object') {
				if (
					isNil(get(_formdata[key], 'risk')) ||
					isEmpty(get(_formdata[key], 'risk'))
				) {
					_errors[key] = `${key} risk is required`;
				}
			}
		}

		for (const key in _formdata) {
			if (typeof _formdata[key] === 'object') {
				if (
					!isNil(get(_formdata[key], 'priorTestRisk')) &&
					!isEmpty(get(_formdata[key], 'priorTestRisk')) &&
					!get(_formdata[key], 'priorTestRisk').match(
						/^(\d*\.{0,1}\d+%{0,1}|(<|>)?\d*\/{1}\d+)$/
					)
				) {
					_errors[key + 'priorTestRisk'] = 'Incorrect entry format';
				}
				if (
					!isNil(get(_formdata[key], 'afterTestRisk')) &&
					!isEmpty(get(_formdata[key], 'afterTestRisk')) &&
					!get(_formdata[key], 'afterTestRisk').match(
						/^(\d*\.{0,1}\d+%{0,1}|(<|>)?\d*\/{1}\d+)$/
					)
				) {
					_errors[key + 'afterTestRisk'] = 'Incorrect entry format';
				}
			}
		}
		return _errors;
	};

	const onFormChange = (subGroupName, key, value) => {
		let errors = {};
		formdata[subGroupName][key] = value;
		errors = validateForm(formdata);
		setFormdata((state) => ({
			...state,
			formdata,
		}));
		setErrors(errors);
	};

	const onFetalFractionChange = (key, val) => {
		if (!isNaN(toNumber(val))) {
			setFormdata((state) => ({
				...state,
				[key]: val,
			}));
		}
	};

	const onFetalSexChange = (key, val) => {
		let errors = {};
		formdata[key] = val;
		errors = validateForm(formdata);
		setFormdata((state) => ({
			...state,
			[key]: val,
		}));
		setErrors(errors);
	};

	const isDisableSavedButtonFunc = () => {
		const errors = validateForm(formdata);
		if (isEmpty(errors)) {
			return false;
		} else {
			return true;
		}
	};

	const subtractFractionVal = (val) => {
		const splitVal = val.replace('<', '').replace(' ', '').split('/');
		let _numerator = 0;
		let _denominator = 0;
		let subtractedVal = val;

		_numerator = splitVal[0];
		_denominator = splitVal[1];

		if (_numerator / _denominator > 0.5) {
			subtractedVal = _denominator - _numerator + '/' + _denominator;
		} else {
			subtractedVal = _numerator + '/' + _denominator;
		}

		return subtractedVal;
	};

	const subtractPercentageVal = (val) => {
		let subtractedVal = val.replace(/%/g, '');

		if (!isNaN(val)) {
			if (val > 50) {
				subtractedVal = 100 - val;
			}
			if (val > 0.5 && val < 1) {
				subtractedVal = 1.0 - val;
			}
		}
		return subtractedVal.toString();
	};

	const saveTestResultData = () => {
		const _formdata = formdata;

		closeAddEditCFDNAPrenatalScreeningModal();

		for (const key in _formdata) {
			if (typeof _formdata[key] === 'object') {
				const _priorTestRisk = get(_formdata[key], 'priorTestRisk');
				const _afterTestRisk = get(_formdata[key], 'afterTestRisk');

				if (
					!isNil(get(_formdata[key], 'priorTestRisk')) &&
					get(_formdata[key], 'priorTestRisk').match(
						/^\d*\.{0,1}\d+%{0,1}$/
					)
				) {
					_formdata[key]['priorTestRiskUnit'] = '%{risk}';
					_formdata[key]['priorTestRisk'] = get(
						_formdata[key],
						'priorTestRisk'
					).replace(/%/g, '');

					if (_formdata[key]['risk'] === 'GM:00000107') {
						_formdata[key]['priorTestRisk'] =
							subtractPercentageVal(_priorTestRisk);
					}
				}

				if (
					!isNil(get(_formdata[key], 'priorTestRisk')) &&
					get(_formdata[key], 'priorTestRisk').match(
						/^(<|>)?\d*\/{1}\d+$/
					)
				) {
					_formdata[key]['priorTestRiskUnit'] = '{risk}';
					if (_formdata[key]['risk'] === 'GM:00000107') {
						_formdata[key]['priorTestRisk'] =
							subtractFractionVal(_priorTestRisk);
					}
				}

				if (
					!isNil(get(_formdata[key], 'afterTestRisk')) &&
					get(_formdata[key], 'afterTestRisk').match(
						/^\d*\.{0,1}\d+%{0,1}$/
					)
				) {
					_formdata[key]['afterTestRiskUnit'] = '%{risk}';
					_formdata[key]['afterTestRisk'] = get(
						_formdata[key],
						'afterTestRisk'
					).replace(/%/g, '');

					if (_formdata[key]['risk'] === 'GM:00000107') {
						_formdata[key]['afterTestRisk'] =
							subtractPercentageVal(_afterTestRisk);
					}
				}

				if (
					!isNil(get(_formdata[key], 'afterTestRisk')) &&
					get(_formdata[key], 'afterTestRisk').match(
						/^(<|>)?\d*\/{1}\d+$/
					)
				) {
					_formdata[key]['afterTestRiskUnit'] = '{risk}';
					if (_formdata[key]['risk'] === 'GM:00000107') {
						_formdata[key]['afterTestRisk'] =
							subtractFractionVal(_afterTestRisk);
					}
				}
			}
		}

		setFormdata((state) => ({
			...state,
			formdata: _formdata,
		}));

		saveCFDNAPrenatalScreeningFinding(
			{
				..._formdata,
			},
			isEditMode()
		);
	};

	return (
		<Dialog
			customClass='add-edit-cfdna-prenatalscreening-dialog'
			title={
				<span className='header'>
					<span>
						{isEditMode() ? 'Edit' : 'New'} cfDNA Prenatal Screening
						Finding
					</span>
					<span
						className='close-btn-icon'
						onClick={closeAddEditCFDNAPrenatalScreeningModal}
					>
						<Icon icon='close' className='close-icon' />
					</span>
				</span>
			}
			size='tiny'
			modal={true}
			closeOnPressEscape={true}
			closeOnClickModal={false}
			showClose={false}
			visible={true}
			onCancel={closeAddEditCFDNAPrenatalScreeningModal}
		>
			<Dialog.Body>
				<section className='basic-information-module'>
					<h2 className='module-header'>Basic Information</h2>
					<Layout.Row gutter='12'>
						<Layout.Col span='7' className='required'>
							<span>Fetal Fraction (%)</span>
							<Input
								name='fetalFraction'
								value={formdata?.fetalFraction}
								placeholder='ex: 10'
								onChange={(value) =>
									onFetalFractionChange(
										'fetalFraction',
										value
									)
								}
							/>
						</Layout.Col>
						<Layout.Col span='7' offset='1' className='required'>
							<span>Fetal Sex</span>
							<Select
								name='fetalSex'
								value={formdata?.fetalSex}
								onChange={(value) =>
									onFetalSexChange('fetalSex', value)
								}
							>
								{fetalSexEnum.map((item, idx) => {
									return (
										<Select.Option
											key={idx}
											label={item.display_name}
											value={item.key}
										/>
									);
								})}
							</Select>
						</Layout.Col>
					</Layout.Row>
				</section>

				<AneuploidiesGroup
					fieldNameLabelArray={fieldNameLabelArray}
					onFieldChange={onFormChange}
					formData={formdata}
					errors={errors}
				/>
			</Dialog.Body>
			<Dialog.Footer className='dialog-footer'>
				{!isEditMode() && (
					<Button
						className='save'
						disabled={isDisableSavedButtonFunc()}
						onClick={() => {
							saveTestResultData();
						}}
					>
						Add Finding
					</Button>
				)}
			</Dialog.Footer>
		</Dialog>
	);
};

export const AneuploidiesGroup = (props) => {
	const { fieldNameLabelArray = {}, onFieldChange, formData, errors } = props;
	return (
		<>
			{fieldNameLabelArray.map((item) => {
				return (
					<section key={item.groupLabel} className='group-section'>
						<h2 className='module-header'>{item.groupLabel}</h2>
						{item.subGroup.map((item) => {
							return (
								<RiskItem
									key={item.subGroupLabel}
									riskSubGroup={item}
									onFieldChange={onFieldChange}
									formData={formData}
									errors={errors}
								/>
							);
						})}
					</section>
				);
			})}
		</>
	);
};

export const RiskItem = (props) => {
	const { riskSubGroup = {}, onFieldChange, formData, errors } = props;

	return (
		<>
			<Layout.Row key={riskSubGroup.subGroupLabel} gutter='12'>
				<Layout.Col className='risk-group-label'>
					{riskSubGroup.subGroupLabel}
				</Layout.Col>
				<Layout.Col span='12'>
					<div className='risk-label required'>Risk</div>
					<RadioGroup
						options={cfDNAPrenatalRiskRadioEnum}
						value={get(formData, [
							riskSubGroup.subGroupName,
							'risk',
						])}
						onChange={(value) =>
							onFieldChange(
								riskSubGroup.subGroupName,
								'risk',
								value
							)
						}
					/>
				</Layout.Col>
				<Layout.Col span='6' className='prior-test-risk-col'>
					<span className='prior-test-risk-label'>
						Prior Test Risk
					</span>
					<Input
						name={`${riskSubGroup.subGroupName}PriorTestRisk`}
						value={get(formData, [
							riskSubGroup.subGroupName,
							'priorTestRisk',
						])}
						placeholder='ex: 99.7%, 95/100'
						onChange={(value) =>
							onFieldChange(
								riskSubGroup.subGroupName,
								'priorTestRisk',
								value
							)
						}
					/>
					<div className='input-error-text'>
						{get(
							errors,
							[`${riskSubGroup.subGroupName}priorTestRisk`],
							''
						)}
					</div>
				</Layout.Col>
				<Layout.Col span='6' className='after-test-risk-col'>
					<span className='after-test-risk-label'>
						After Test Risk
					</span>
					<Input
						name={`${riskSubGroup.subGroupName}AfterTestRisk`}
						value={get(formData, [
							riskSubGroup.subGroupName,
							'afterTestRisk',
						])}
						placeholder='ex: 99.9%, >1/10000'
						onChange={(value) =>
							onFieldChange(
								riskSubGroup.subGroupName,
								'afterTestRisk',
								value
							)
						}
					/>
					<div className='input-error-text'>
						{get(
							errors,
							[`${riskSubGroup.subGroupName}afterTestRisk`],
							''
						)}
					</div>
				</Layout.Col>
			</Layout.Row>
		</>
	);
};

export default AddEditCFDNAPrenatalScreeningModal;
