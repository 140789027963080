//React & Redux
import React from 'react';
import PropTypes from 'prop-types';

//Images
import multipleVisitIcon from '../../assets/multi-visit.svg';

//Lodash
import { find, get, isEmpty, orderBy } from 'lodash';

//Other Libraries
import classnames from 'classnames';
import moment from 'moment';
import Clock from '../Common/LiveClock';

// Components
import { Table, Thead, Tbody, Th, Tr, Td } from '../Common/Table';
import TextWithTooltip from '../Common/TextWithTooltip';
import Tags from '../Common/Tags';

//Styles
import './style.css';

//Utils
import {
	UTCToCurrentDateView,
	UTCToCurrentTimeView,
	RemoveUnderscoreAndCapitalizeInString,
	EncounterTabs,
	statesTimezones,
	getHomeAddress,
	currentAddressKey,
} from '../../utils';
import {
	imageDefault,
	providers_images,
	encounter_types_images,
	encounter_types,
	visit_status_colors,
	scp_encounter_sub_types_images,
	isMultipleVisit,
	isPrimaryVisit,
	isSecondaryVisit,
	isManualVisit,
} from '../../Utils/encounterUtils';
import {
	displayEncounterTypeWithSubtype,
	getOrderStatusDisplayNameForAll,
} from './V2/Encounters/Common/utilsfunctions';

//Components
import OrderStatusPill from '../Common/OrderStatusPill';
import Tooltip from '../Common/Tooltip';

const dash = <span className='pat-dash'>--</span>;

const PatientsRecords = (props) => {
	const {
		ccintakeschema,
		data = {},
		groupsessionschema,
		handlePageChange,
		handleResetFilters,
		handleResultsPerPageChange,
		handleSort,
		history,
		isAllExpanded = false,
		MLOOrderStatus = [],
		orderStatus: orderStatusEnums = {},
		patientstate,
		selectedPatient: doSelectPatient,
		setIsAllExpanded,
		visitProvider = [],
		visitsschema,
	} = props;

	const {
		data: patientsData = [],
		encounter_info = [],
		end = 0,
		has_next = false,
		has_prev = false,
		limit = 0,
		offset = 0,
		start = 0,
		total = 0,
	} = data;

	const {
		activePage = 0,
		patients = {},
		query = '',
		sort_column = '',
		sort_order = 'DESC',
	} = patientstate;

	const selectPatient = (selectedPatient) => {
		let pathname =
			'/app/patientdetail/' +
			(selectedPatient.uuid || 0) +
			'/0/' +
			activePage +
			'/' +
			query +
			'?offset=' +
			(patients.data?.offset || 0);

		if (sort_column === null || sort_column === '') {
			history.push({
				pathname: pathname,
				state: {
					breadcrumbs: [
						{
							location: 'patients',
							url:
								'/app/patients/?offset=' +
								(patients.data?.offset || 0),
						},
						{
							location: 'Patient Detail',
							patientFullName: `${
								selectedPatient.first_name || '--'
							} ${selectedPatient.last_name || '--'}`,
						},
					],
				},
			});
		} else {
			pathname =
				pathname +
				'?order_by=' +
				sort_column +
				'&order_type=' +
				sort_order;

			history.push({
				pathname: pathname,
				state: {
					breadcrumbs: [
						{
							location: 'patients',
							url:
								'/app/patients/?order_by=' +
								sort_column +
								'&order_type=' +
								sort_order +
								'&offset=' +
								(patients.data?.offset || 0),
						},
						{
							location: 'Patient Detail',
							patientFullName: `${
								selectedPatient.first_name || '--'
							} ${selectedPatient.last_name || '--'}`,
						},
					],
				},
			});
		}
		doSelectPatient(selectedPatient);
	};

	const redirectToEncounterDetail = (enc_uuid, type, patient) => {
		history.push({
			pathname:
				'/app/patientdetail/' +
				(patient?.uuid || '') +
				'/' +
				0 +
				'/' +
				EncounterTabs.DETAIL +
				'/' +
				enc_uuid +
				'/',
			search:
				'?type=' +
				type +
				'&offset=' +
				(patients.data?.offset || 0) +
				'&order_by=' +
				sort_column +
				'&order_type=' +
				sort_order,
			state: {
				breadcrumbs: [
					{
						location: 'patients',
						url:
							'/app/patients/?order_by=' +
							sort_column +
							'&order_type=' +
							sort_order +
							'&offset=' +
							(patients.data?.offset || 0),
					},
					{
						location: 'Patient Detail',
						patientFullName: `${patient.first_name || '--'} ${
							patient.last_name || '--'
						}`,
					},
				],
			},
		});
	};

	const getVisitStatus = (encounter) => {
		const encounterType = encounter?.type || '';
		const visitStatus = encounter?.visit_status || null;
		if (!visitStatus) return '--';
		switch (encounterType) {
			case 'group-session':
				const groupSession =
					groupsessionschema?.data?.visit_status || [];
				const groupSessionRow = groupSession.find(
					(a) => a.key === visitStatus
				);
				return groupSessionRow?.display_name || '--';
			case 'cc-intake':
				const ccIntake = ccintakeschema?.data?.visit_status || [];
				const ccIntakeRow = ccIntake.find((a) => a.key === visitStatus);
				return ccIntakeRow?.display_name || '--';
			case 'visit':
				const visit = visitsschema?.data?.visit_status || [];
				const visitRow = visit.find((a) => a.key === visitStatus);
				return visitRow?.display_name || '--';
			default:
				return '--';
		}
	};

	const getOrderStatusDisplayNamePatientList = (
		orderStatus,
		statusReason
	) => {
		if (!isEmpty(statusReason)) {
			return getOrderStatusDisplayNameForAll(
				orderStatus,
				statusReason,
				MLOOrderStatus,
				false
			);
		} else {
			const enum_value = find(
				orderStatusEnums,
				(o) => o.key === orderStatus
			);
			if (enum_value) {
				return enum_value.display_name || '';
			} else {
				return RemoveUnderscoreAndCapitalizeInString(orderStatus);
			}
		}
	};

	const getEncounterSpecificPatientDate = (dos, timezone, type) => {
		const tz = type === 'research-data' ? 'America/New_York' : timezone;
		return UTCToCurrentDateView(moment, moment(dos), tz);
	};

	const getEncounterSpecificPatientTime = (dos, timezone, type) => {
		const tz = type === 'research-data' ? 'America/New_York' : timezone;
		return UTCToCurrentTimeView(moment, moment(dos), tz);
	};

	const getProviderImage = (obj) => {
		let providerImage = null;
		const type = get(obj, 'type', '');
		switch (type) {
			case 'lab_test_authorization':
			case 'scp':
				const labProviderName = get(obj, 'ordering_physician', '');
				const mappedImageLab = providers_images[labProviderName];
				if (labProviderName && mappedImageLab) {
					providerImage = <img src={mappedImageLab} alt='' />;
				} else if (labProviderName !== '')
					providerImage = <img src={imageDefault} alt='' />;
				else providerImage = <img src={imageDefault} alt='' />;
				break;
			case 'cc-intake':
			case 'group-session':
			case 'visit':
				const providerName = get(obj, 'visit_provider', '');
				const mappedImage = providers_images[providerName];
				if (providerName && mappedImage) {
					providerImage = <img src={mappedImage} alt='' />;
				} else if (providerName !== '')
					providerImage = <img src={imageDefault} alt='' />;
				else providerImage = null;
				break;
			case 'clinical-review':
				providerImage = null;
				break;
			default:
				providerImage = null;
		}
		return (
			providerImage && (
				<span className='patient-imgbox'>{providerImage}</span>
			)
		);
	};

	const ucFirstAllWords = (str, separator = ' ') => {
		const pieces = (str && str.split(separator)) || [];
		for (let i = 0; i < pieces.length; i++) {
			const j = pieces[i].charAt(0).toUpperCase();
			pieces[i] = j + pieces[i].substr(1).toLowerCase();
		}
		return pieces.join(separator);
	};

	const getProviderName = (obj) => {
		let result = '';
		let providerName = '';
		const type = get(obj, 'type', '');
		let separator = ' ';
		let enum_value;
		switch (type) {
			case 'lab_test_authorization':
			case 'scp':
				const enumslab = visitsschema?.data?.ordering_physician || [];
				enum_value = find(
					enumslab,
					(o) => o.key === get(obj, 'ordering_physician', '--')
				);
				if (enum_value) {
					providerName = get(enum_value, 'display_name', '');
				} else {
					separator = '_';
					providerName = ucFirstAllWords(
						get(obj, 'ordering_physician', ''),
						separator
					);
				}
				break;
			case 'cc-intake':
			case 'group-session':
			case 'visit':
				enum_value = find(
					visitProvider,
					(o) => o.key === get(obj, 'visit_provider', '--')
				);
				if (enum_value) {
					providerName = get(enum_value, 'display_name', '');
				} else {
					separator = '_';
					providerName = ucFirstAllWords(
						get(obj, 'visit_provider', ''),
						separator
					);
				}
				break;
			case 'clinical-review':
				providerName = '';
				break;
			default:
				providerName = '';
		}
		if (providerName && providerName.length > 0) {
			const ProviderNameobj = providerName.split(separator);
			const first_name = get(ProviderNameobj, 0, '');
			const middle_name =
				providerName.length > 2
					? ' ' + get(ProviderNameobj, 1, '')
					: '';
			const last_name =
				providerName.length > 2
					? ' ' + get(ProviderNameobj, 2, '')
					: ' ' + get(ProviderNameobj, 1, '');
			result = first_name + middle_name + last_name;
			return <span className='prv-name'>{result}</span>;
		} else {
			return dash;
		}
	};

	const getEncounterText = (text) => {
		if (text && !isEmpty(text) && text !== '--') {
			return (
				<Tooltip
					className='name_tooltip'
					content={text}
					placement='top-start'
				>
					<span>{text}</span>
				</Tooltip>
			);
		} else {
			return dash;
		}
	};

	const getVisitStatusColor = (status) => {
		return get(visit_status_colors, status, 'green');
	};

	const getConsultationSubType = (obj) => {
		return obj.type && obj.consultation_type && obj.type === 'visit'
			? ' - ' + obj.consultation_type
			: '';
	};

	const sortedEncounterInfo = (encounter) => {
		const encounterInfo =
			encounter && Array.isArray(encounter) ? encounter : [];
		return orderBy(
			encounterInfo,
			(o) => new moment(get(o, 'created_at', null)),
			['desc']
		);
	};

	const isSCPEncoutnerWithSubType = (encounter) => {
		return (
			get(encounter, 'type', '') == 'scp' &&
			get(encounter, 'encounter_subtype', false)
		);
	};

	const getMultipleVisitType = (el) => {
		if (isMultipleVisit(el)) {
			if (isPrimaryVisit(el)) {
				return ' (Primary) ';
			} else if (isSecondaryVisit(el)) {
				return ' (Secondary) ';
			} else {
				return '';
			}
		} else if (isManualVisit(el)) {
			return ' (Manual) ';
		} else {
			return '';
		}
	};

	const pagination = {
		end,
		has_next,
		has_prev,
		limit,
		offset,
		start,
		total,
	};

	const sortBy = {
		key: sort_column || '',
		value: sort_order || '',
	};

	return (
		<Table
			style={{ marginTop: '16px', minWidth: '1204px' }}
			loading={false}
			backToTop
			resultsPerPage={[25, 50, 100]}
			handleResultsPerPageChange={handleResultsPerPageChange}
			pagination={pagination}
			handlePageChange={handlePageChange}
			hasData={!isEmpty(patientsData)}
			handleResetFilters={handleResetFilters}
			handleSort={handleSort}
			sortBy={sortBy}
			isExpandable
			isAllExpanded={isAllExpanded}
			setIsAllExpanded={setIsAllExpanded}
		>
			<Thead>
				<Tr>
					<Th
						sortable={{
							first_name: {
								display_name: 'first name',
								sortBy: {
									asc: 'A to Z',
									desc: 'Z to A',
								},
							},
							last_name: {
								display_name: 'last name',
								sortBy: {
									asc: 'A to Z',
									desc: 'Z to A',
								},
							},
						}}
					>
						Patient
					</Th>
					<Th>Date Of Birth</Th>
					<Th>Phone Number</Th>
					<Th
						sortable={{
							referral_program: {
								display_name: 'referral program',
								sortBy: {
									asc: 'A to Z',
									desc: 'Z to A',
								},
							},
						}}
					>
						Referral Program
					</Th>
					<Th>External Patient ID</Th>
				</Tr>
			</Thead>
			<Tbody>
				{patientsData.map((patient, i) => {
					const {
						dob,
						email,
						external_patient_id,
						first_name,
						has_duplicate_patients,
						has_guardian,
						last_name,
						phone,
						referral_program,
						user_tags,
						uuid,
					} = patient;
					const homeAddress = getHomeAddress(
						get(patient, currentAddressKey, null)
					);
					const state = get(homeAddress, ['state'], '');
					const combinedTags = [...user_tags];
					has_duplicate_patients &&
						combinedTags.push({
							display_name: 'Dup. Record',
							name: 'duplicate',
						});
					has_guardian &&
						combinedTags.push({
							display_name: 'Guardian',
							name: 'guardian',
						});

					return (
						<Tr
							onClick={() => selectPatient(patient)}
							child={
								encounter_info[uuid] && (
									<Table>
										<Tbody>
											{sortedEncounterInfo(
												get(encounter_info, uuid, [])
											).map((el) => {
												return (
													<>
														<Tr
															onClick={() =>
																redirectToEncounterDetail(
																	el.uuid,
																	el.type,
																	patient
																)
															}
														>
															<Td
																minWidth='132px'
																maxWidth='132px'
															>
																<p>
																	{get(
																		el,
																		[
																			'date_of_service',
																			'length',
																		],
																		0
																	)
																		? getEncounterSpecificPatientDate(
																				get(
																					el,
																					'date_of_service',
																					''
																				),
																				get(
																					el,
																					[
																						'timezone',
																					],
																					''
																				),
																				get(
																					el,
																					'type',
																					''
																				)
																		  )
																		: '--'}
																</p>
																<p className='sub'>
																	{get(
																		el,
																		[
																			'date_of_service',
																			'length',
																		],
																		0
																	)
																		? getEncounterSpecificPatientTime(
																				get(
																					el,
																					'date_of_service',
																					''
																				),
																				get(
																					el,
																					[
																						'timezone',
																					],
																					''
																				),
																				get(
																					el,
																					'type',
																					''
																				)
																		  )
																		: '--'}
																</p>
															</Td>
															<Td
																minWidth='360px'
																maxWidth='360px'
																className='encounter-type'
															>
																{isSCPEncoutnerWithSubType(
																	el
																) ? (
																	<>
																		<div className='provider-typeimg provider-typeimg-scp'>
																			{scp_encounter_sub_types_images[
																				get(
																					el,
																					'encounter_subtype',
																					''
																				)
																			] ? (
																				<img
																					src={
																						scp_encounter_sub_types_images[
																							get(
																								el,
																								'encounter_subtype',
																								''
																							)
																						]
																					}
																					alt=''
																				/>
																			) : null}
																		</div>
																		<div className='prov-typemain'>
																			<div className='prov-type ellipsed'>
																				{' '}
																				{getEncounterText(
																					displayEncounterTypeWithSubtype(
																						el
																					)
																				)}
																			</div>
																		</div>
																	</>
																) : (
																	<>
																		<div className='provider-typeimg'>
																			{isMultipleVisit(
																				el
																			) ? (
																				<img
																					src={
																						multipleVisitIcon
																					}
																				/>
																			) : encounter_types_images[
																					el
																						.type
																			  ] ? (
																				<img
																					src={
																						encounter_types_images[
																							el
																								.type
																						]
																					}
																					alt=''
																				/>
																			) : null}
																		</div>
																		<div className='prov-typemain'>
																			<div className='prov-type ellipsed'>
																				{' '}
																				{getEncounterText(
																					encounter_types[
																						el
																							.type
																					] +
																						getMultipleVisitType(
																							el
																						) +
																						getConsultationSubType(
																							el
																						)
																				)}
																			</div>
																			<div
																				className={classnames(
																					'visit-status ellipsed',
																					'status-' +
																						getVisitStatusColor(
																							get(
																								el,
																								'visit_status',
																								''
																							)
																						)
																				)}
																			>
																				{getEncounterText(
																					getVisitStatus(
																						el
																					)
																				)}
																			</div>
																		</div>
																	</>
																)}
															</Td>
															<Td
																minWidth='250px'
																maxWidth='250px'
															>
																<p className='small'>
																	Provider
																</p>
																<p>
																	{getProviderImage(
																		el
																	)}
																	{getProviderName(
																		el
																	)}
																</p>
															</Td>
															<Td
																minWidth='250px'
																maxWidth='250px'
															>
																<p className='small'>
																	Order Status
																</p>
																<p>
																	{get(
																		el,
																		'order_status.length',
																		0
																	) > 0 ||
																	get(
																		el,
																		'test_recommended',
																		''
																	) ===
																		'PA' ? (
																		get(
																			el,
																			'order_status',
																			''
																		) ===
																		'multiple' ? (
																			<OrderStatusPill
																				displayName='Multiple'
																				value={get(
																					el,
																					'order_status',
																					''
																				)}
																			/>
																		) : get(
																				el,
																				'test_recommended',
																				''
																		  ) ===
																		  'PA' ? (
																			<OrderStatusPill
																				displayName='Pre-Auth'
																				value={get(
																					el,
																					'test_recommended',
																					''
																				)}
																			/>
																		) : (
																			<OrderStatusPill
																				displayName={getOrderStatusDisplayNamePatientList(
																					get(
																						el,
																						'order_status',
																						''
																					),
																					get(
																						el,
																						'status_reason',
																						''
																					)
																				)}
																				value={
																					!isEmpty(
																						get(
																							el,
																							'status_reason',
																							''
																						)
																					)
																						? get(
																								el,
																								'status_reason',
																								''
																						  )
																						: get(
																								el,
																								'order_status',
																								''
																						  )
																				}
																			/>
																		)
																	) : (
																		'--'
																	)}
																</p>
															</Td>
															<Td>&nbsp;</Td>
														</Tr>
														<Tr role='separator' />
													</>
												);
											})}
										</Tbody>
									</Table>
								)
							}
						>
							<Td minWidth='264px' maxWidth='370px'>
								<p>
									<TextWithTooltip
										maxWidth={
											264 -
											get(combinedTags, 'length', 0) *
												20 +
											'px'
										}
										placement='top-start'
									>
										{first_name || last_name
											? first_name + ' ' + last_name
											: '--'}
									</TextWithTooltip>
									<Tags tags={combinedTags} isDisabledTooltip={false} />
								</p>
								<p className='sub'>
									<TextWithTooltip
										maxWidth='264px'
										placement='top-start'
									>
										{email || '--'}
									</TextWithTooltip>
								</p>
							</Td>
							<Td minWidth='144px' maxWidth='172px'>
								{dob
									? moment(dob).format('MMM DD, YYYY')
									: '--'}
							</Td>
							<Td minWidth='172px' maxWidth='200px'>
								<p>{phone || '--'}</p>
								<p className='sub'>
									{state ? (
										<>
											<Clock
												format={'hh:mm A'}
												timezone={
													statesTimezones[state]
												}
											/>{' '}
											Local Time
										</>
									) : (
										'--'
									)}
								</p>
							</Td>
							<Td minWidth='198px' maxWidth='224px'>
								{referral_program || '--'}
							</Td>
							<Td minWidth='198px' maxWidth='224px'>
								{external_patient_id || '--'}
							</Td>
						</Tr>
					);
				})}
			</Tbody>
		</Table>
	);
};

PatientsRecords.propTypes = {
	auth: PropTypes.object,
};

export default PatientsRecords;
