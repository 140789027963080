//React & Redux
import React from 'react';

//Components
import MultiSelectSearchHeader from './MultiSelectCheckBoxBody/MultiSelectSearchHeader';
import MultiSelectPreviewTab from './MultiSelectCheckBoxBody/MultiSelectPreviewTab';
import MultiSelectRadioFilter from './MultiSelectCheckBoxBody/MultiSelectRadioFilter';
import MultiSelectList from './MultiSelectCheckBoxBody/MultiSelectList';

const MultiSelectCheckboxBody = (props) => {
	const {
		data = [],
		filteredData = [],
		handleChangeList,
		handleSearch,
		hideSelectAll = false,
		internalValues = [],
		radioFilterOptions: {
			filterKey = '',
			filterName = '',
			filterOptions = [],
			isMultiple = false,
		},
		previewTab = false,
		searchBar = false,
		searchQuery = '',
		searchReset,
		selectAll,
		selectNone,
		toggleCheck,
	} = props;

	return (
		<div className='multi-select-checkbox-modal-body-container'>
			<div
				className='multi-select-checkbox-actions'
				style={{
					flex: previewTab ? '0 0 320px' : 'unset',
				}}
			>
				<MultiSelectSearchHeader
					{...{
						data,
						handleSearch,
						hideSelectAll,
						internalValues,
						searchBar,
						searchQuery,
						searchReset,
						selectAll,
						selectNone,
					}}
				/>

				{isMultiple ? (
					<MultiSelectRadioFilter
						{...{
							filterKey,
							filterName,
							filterOptions,
							handleChangeList,
						}}
					/>
				) : null}

				<MultiSelectList
					{...{
						filteredData,
						internalValues,
						isMultiple,
						toggleCheck,
					}}
				/>
			</div>
			{previewTab && (
				<MultiSelectPreviewTab
					{...{ data, internalValues, toggleCheck }}
				/>
			)}
		</div>
	);
};

export default MultiSelectCheckboxBody;
