//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import { GetSelectvalue } from '../Common/utilsfunctions';

const PGXSegment = (props) => {
	return (
		<Fragment>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							PharmD Identification
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Drug Interaction
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							# of Interaction(s)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Drug/Gene Interaction
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Drug Dos Adj Recommended
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'pharmd'],
								_.get(props, ['ccintakeschema', 'pharmd'], [])
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'drug_interaction'],
								_.get(
									props,
									['ccintakeschema', 'drug_interaction'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'no_of_interactions'],
								_.get(
									props,
									['ccintakeschema', 'no_of_interactions'],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								['encounter', 'drug_contraindications'],
								_.get(
									props,
									[
										'ccintakeschema',
										'drug_contraindications',
									],
									[]
								)
							)}
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							{GetSelectvalue(
								props,
								[
									'encounter',
									'drug_dosage_adjustment_recommended',
								],
								_.get(
									props,
									[
										'ccintakeschema',
										'drug_dosage_adjustment_recommended',
									],
									[]
								)
							)}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default PGXSegment;
