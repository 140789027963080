//React & Redux
import React from 'react';

//UI Libraries
import { Input } from 'gm-element-react';

//Styles
import './Textarea.css';

const Textarea = React.forwardRef((props, ref) => {
	const { className, ...otherProps } = props;
	const classNames = ['gmiTextArea', className].join(' ');
	return (
		<Input
			type='textarea'
			ref={ref}
			className={classNames}
			{...otherProps}
		/>
	);
});

export default Textarea;
