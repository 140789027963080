//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../../../../assets/close.svg';

//Components
import TopRow from './../../../../Common/FormModal/TopRow.js';
import FormBody from './../../../../Common/FormModal/FormBody.js';
import ButtonRow from './../../../../Common/FormModal/ButtonRow.js';

//Libraries
import { Loading } from 'gm-element-react';

//Styles
import './AddressModal.css';

//Utils
import {
	states_list,
	addressTypes,
	getExistingAddressTypes,
	requiredFieldsFilled,
	fullFieldsValidation,
} from './../../../../../utils.js';
import {
	createStateEnumsOptions,
	createEnumsOptions,
} from '../../Encounters/Common/utilsfunctions';

class AddressModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			requiredFields: ['type', 'address_line1', 'city', 'state', 'zip'],
			addressTypes: addressTypes,
			addressData: [],
			canSave: false,
			dataSet: false,
			loading: false,
		};
		//bindings
		this.setAddressData = this.setAddressData.bind(this);
		this.filterAddressEnum = this.filterAddressEnum.bind(this);
		this.constructFieldJSON = this.constructFieldJSON.bind(this);
		this.gatherRequiredFields = this.gatherRequiredFields.bind(this);
		this.gatherAddressLines = this.gatherAddressLines.bind(this);
		this.fieldChange = this.fieldChange.bind(this);
		this.canSave = _.debounce(this.canSave.bind(this), 150);
		this.saveData = this.saveData.bind(this);
		this.errorCheck = this.errorCheck.bind(this);
		this.hasErrors = this.hasErrors.bind(this);
		this.clearErrors = this.clearErrors.bind(this);
		this.getAvailableAddressTypes =
			this.getAvailableAddressTypes.bind(this);
	}
	//functions

	componentDidMount() {
		this.setAddressData();
	}

	setAddressData() {
		const addressData = _.cloneDeep(
			get(this, 'props.addressData', null) || []
		);
		const nonEditInitialAddressData = _.cloneDeep(
			get(this, 'props.nonEditInitialAddressData', null) || {}
		);
		const idx = get(this, 'props.idx', null);
		const isEdit = get(this, 'props.edit', false);
		const addressDataLength = get(addressData, 'length', 0) || 0;
		const addressErrors =
			get(this, `props.addressErrors[${idx}]`, null) || {};
		if (
			Array.isArray(addressData) &&
			(isEdit ? typeof idx === 'number' : true)
		) {
			if (get(this, 'props.edit', false)) {
				this.setState(
					{
						addressData: addressData,
						idx: idx,
						errors: { ...addressErrors },
					},
					this.filterAddressEnum
				);
			} else {
				this.setState(
					{
						addressData: addressData.concat({
							...nonEditInitialAddressData,
						}),
						idx: addressDataLength,
					},
					this.filterAddressEnum
				);
			}
		}
	}

	filterAddressEnum() {
		const addressData = get(this, 'state.addressData', null) || [];
		const idx = get(this, 'state.idx', null);
		const existingAddressTypes = getExistingAddressTypes(addressData, idx);
		const availableAddressTypes = _.cloneDeep(addressTypes).map((type) => {
			if (existingAddressTypes.includes(get(type, 'key', null)))
				type.disabled = true;
			return type;
		});
		this.setState({ addressTypes: availableAddressTypes, dataSet: true });
	}

	getAvailableAddressTypes() {
		const _addressTypes = get(this, 'state.addressTypes', null) || [];

		Object.keys(_addressTypes).forEach((key) => {
			_addressTypes[key].label = _addressTypes[key].display_name;
			_addressTypes[key].value = _addressTypes[key].key;
		});
		return _addressTypes;
	}

	constructFieldJSON() {
		const availableAddressTypes = this.getAvailableAddressTypes();
		const idx = get(this, 'state.idx', null);
		return [
			{
				label: 'Address Type',
				header: 'type',
				value: get(this, `state.addressData[${idx}].type`, null) || [],
				type: 'multiselect',
				required: true,
				internalKey: 'key',
				externalKey: 'display_name',
				options: availableAddressTypes,
				customStyling: { marginBottom: '5px' },
				quartersWidth: 4,
				typeahead: false,
				clearable: false,
			},
			{
				header: null,
				type: 'plainText',
				text: 'You can select multiple address types here',
				className: 'addressModalPlainText',
			},
			{
				label: 'Address 1',
				header: 'address_line1',
				value: get(
					this,
					`state.addressData[${idx}].address_line1`,
					null
				),
				type: 'text',
				quartersWidth: 4,
				required: true,
			},
			{
				label: 'Address 2',
				header: 'address_line2',
				value: get(
					this,
					`state.addressData[${idx}].address_line2`,
					null
				),
				type: 'text',
				quartersWidth: 4,
			},
			{
				label: 'City',
				header: 'city',
				value: get(this, `state.addressData[${idx}].city`, null),
				type: 'text',
				required: true,
				quartersWidth: 2,
			},
			{
				label: 'State',
				header: 'state',
				value: get(this, `state.addressData[${idx}].state`, null),
				type: 'singleSelect',
				internalKey: 'key',
				externalKey: 'value',
				keyOverride: true,
				options: createStateEnumsOptions(
					states_list,
					'key',
					'value',
					'key'
				),
				placeholder: 'CA',
				required: true,
				quartersWidth: 1,
				marginLeft: true,
				typeahead: true,
				clearable: true,
			},
			{
				label: 'Zipcode',
				header: 'zip',
				value: get(this, `state.addressData[${idx}].zip`, null),
				type: 'text',
				placeholder: '12345',
				quartersWidth: 1,
				marginLeft: true,
				required: true,
			},
		];
	}

	fieldChange(header, newValue) {
		if (header) {
			const addressData = get(this, 'state.addressData', null) || [];
			const idx = get(this, 'state.idx', null);
			const addressObj = get(addressData, `[${idx}]`, null) || {};
			addressObj[header] = newValue;
			this.setState({ addressData }, () => {
				this.canSave();
			});
		}
	}

	gatherRequiredFields() {
		const requiredFields = get(this, 'state.requiredFields', []);
		const idx = get(this, 'state.idx', null);
		return requiredFields.map((el) => {
			return get(this, `state.addressData[${idx}].${el}`, null);
		});
	}

	gatherAddressLines() {
		const idx = get(this, 'state.idx', null);
		return [
			{
				type: 'address',
				value: get(
					this,
					`state.addressData[${idx}].address_line1`,
					null
				),
				header: 'address_line1',
				subType: 'addressLine',
			},
			{
				type: 'address',
				value: get(
					this,
					`state.addressData[${idx}].address_line2`,
					null
				),
				header: 'address_line2',
				subType: 'addressLine',
			},
			{
				type: 'address',
				value: get(this, `state.addressData[${idx}].city`, null),
				header: 'city',
				subType: 'city',
			},
			{
				type: 'address',
				value: get(this, `state.addressData[${idx}].zip`, null),
				header: 'zip',
				subType: 'zip',
			},
		].filter((el) => get(el, 'value', null));
	}

	canSave() {
		const requiredFields = this.gatherRequiredFields();
		const canSave = requiredFieldsFilled(requiredFields);
		this.setState({ canSave });
	}

	async saveData() {
		const addressData = get(this, 'state.addressData', null) || [];
		const idx = get(this, 'props.idx', null);
		const isEdit = get(this, 'props.edit', false);
		const returnIdx = isEdit ? idx : idx + 1;
		await this.clearErrors();
		await this.errorCheck();
		if (Array.isArray(addressData) && !this.hasErrors()) {
			this.props.handleAddressModalSave(addressData, returnIdx);
		}
	}

	errorCheck() {
		return new Promise((resolve, reject) => {
			const addressLines = this.gatherAddressLines() || [];
			const errorFields = fullFieldsValidation(addressLines);
			this.setState({ errors: errorFields }, resolve);
		});
	}

	hasErrors() {
		return get(
			Object.keys(get(this, 'state.errors', {}) || {}),
			'length',
			0
		);
	}

	clearErrors() {
		return new Promise((resolve, reject) => {
			this.setState(
				{
					errors: {},
				},
				resolve
			);
		});
	}

	render() {
		const errors = get(this, 'state.errors', null) || {};
		const isEdit = get(this, 'props.edit', false);
		const title = isEdit ? 'Edit Address' : 'Add New Address';
		const buttonText = isEdit ? 'Save' : 'Add Address';
		const loading =
			!get(this, 'state.dataSet', null) ||
			get(this, 'state.loading', null);
		return (
			<div className='fullModalBackground'>
				<div className='formModal addressModalContainer'>
					{loading ? (
						<Loading loading={true} className='formModalLoader' />
					) : (
						<React.Fragment>
							<TopRow
								title={title}
								close={this.props.closeAddressModal}
								customStyling={{ height: '42px' }}
							/>
							<FormBody
								fieldsJSON={this.constructFieldJSON()}
								errors={errors}
								fieldChange={this.fieldChange}
							/>
							<ButtonRow
								CTAFunc={this.saveData}
								canSave={get(this, 'state.canSave', false)}
								buttonText={buttonText}
							/>
						</React.Fragment>
					)}
				</div>
			</div>
		);
	}
}

export default AddressModal;
