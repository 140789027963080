//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import searchIcon from './../../../assets/ic_search.svg';
import xIcon from './../../../assets/exit-alert.svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import {
	determineTopDropShadow,
	determineBottomDropShadow,
} from './../../../utils.js';

//Components
import ICDSearchResults from './ICDSearchResults.js';
import ICDSearchPlaceholder from './ICDSearchPlaceholder.js';
import ICDSearchNoResults from './ICDSearchNoResults.js';
import CommonCheckbox from './../../Common/Checkbox.js';

class ICDSearch extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.searchReset = this.searchReset.bind(this);
		this.renderComponent = this.renderComponent.bind(this);
		this.determineDropShadow = _.debounce(
			this.determineDropShadow.bind(this),
			100
		);
	}
	//functions

	handleSearchChange(e) {
		const input = get(e, 'target.value', '') || '';
		this.props.handleSearchQueryChange(input);
	}

	searchReset() {
		this.props.handleSearchQueryChange('');
	}

	renderComponent() {
		const query = get(this, 'props.searchQuery', '') || '';
		const noPlaceHolder = get(this, 'props.noPlaceHolder', false);
		if (get(query, 'length', 0) > 0 || noPlaceHolder) {
			if (
				get(this, 'props.icdCodes.length', 0) === 0 &&
				!get(this, 'props.initialQueryLoading', false)
			) {
				return <ICDSearchNoResults />;
			} else {
				return (
					<ICDSearchResults
						icdCodes={this.props.icdCodes}
						selectedICDCodes={this.props.selectedICDCodes}
						toggleCodeSelection={this.props.toggleCodeSelection}
						searchQuery={this.props.searchQuery}
						icdCodesLoading={this.props.icdCodesLoading}
						initialQueryLoading={this.props.initialQueryLoading}
						topLoading={this.props.topLoading}
						bottomLoading={this.props.bottomLoading}
						lazyLoadTop={this.props.lazyLoadTop}
						lazyLoadBottom={this.props.lazyLoadBottom}
						determineDropShadow={this.determineDropShadow}
						setBodyElement={this.setBodyElement}
						localSearchKey={get(this, 'props.localSearchKey', null)}
						noDescription={get(this, 'props.noDescription', false)}
						topDropShadow={get(this, 'state.topDropShadow', false)}
						bottomDropShadow={get(
							this,
							'state.bottomDropShadow',
							false
						)}
						filter={get(this, 'props.filter', null)}
						reverseVariant={get(
							this,
							'props.reverseVariant',
							false
						)}
						altCheckbox={get(this, 'props.altCheckbox', null)}
						toggleCodeSelectionAltCheckbox={
							this.props.toggleCodeSelectionAltCheckbox
						}
						handleCodeFreeTextChange={
							this.props.handleCodeFreeTextChange
						}
						freeTextKey={get(this, 'props.freeTextKey', null)}
					/>
				);
			}
		} else {
			return (
				<ICDSearchPlaceholder
					encounterICDcodes={this.props.encounterICDcodes || []}
				/>
			);
		}
	}

	determineDropShadow(sides, element) {
		if (get(this, 'props.initialQueryLoading', false)) {
			this.setState({ topDropShadow: false, bottomDropShadow: false });
		} else {
			if (Array.isArray(sides) && element) {
				sides.forEach((side) => {
					let shadow;
					switch (side) {
						case 'top':
							shadow = determineTopDropShadow(element);
							this.setState({ topDropShadow: shadow });
							break;
						case 'bottom':
							shadow = determineBottomDropShadow(element);
							this.setState({ bottomDropShadow: shadow });
							break;
						default:
							console.log('Unrecognized modal side.');
							break;
					}
				});
			}
		}
	}

	render() {
		const query = get(this, 'props.searchQuery', '') || '';
		const filter = get(this, 'props.filter', null);
		const selectedFilter = get(this, 'props.selectedFilter', null) || [];
		const displayFilter =
			filter &&
			Array.isArray(filter) &&
			selectedFilter &&
			Array.isArray(selectedFilter);
		const filterHeader = get(this, 'props.filterHeader', null);
		return (
			<div className='chargeICDSearchContainer'>
				{get(this, 'state.topDropShadow', false) &&
					!get(this, 'props.initialQueryLoading', false) &&
					!get(this, 'props.topLoading', false) &&
					query && (
						<div
							className='scrollableModalDropShadowTopAbsolute'
							style={{ top: '48px' }}
						/>
					)}
				{get(this, 'state.bottomDropShadow', false) &&
					!get(this, 'props.initialQueryLoading', false) &&
					!get(this, 'props.bottomLoading', false) &&
					query && (
						<div className='scrollableModalDropShadowBottomAbsolute' />
					)}
				<div className='chargeICDSearchBarContainer'>
					<input
						className='chargeICDSearchInput'
						placeholder='Search'
						onChange={(e) => this.handleSearchChange(e)}
						value={query}
					/>
					{get(query, 'length', 0) > 0 ? (
						<ReactSVG
							className='chargeICDSearchXIcon'
							src={xIcon}
							style={{
								position: 'relative',
								bottom: '6px',
								left: '2px',
							}}
							onClick={this.searchReset}
						/>
					) : (
						<ReactSVG
							className='chargeICDSearchIcon'
							src={searchIcon}
							style={{
								position: 'relative',
								bottom: '1px',
								right: '5px',
							}}
						/>
					)}
				</div>
				{displayFilter && (
					<React.Fragment>
						<div className='chargeICDFilterHeader'>
							{filterHeader || 'Filter results'}
						</div>
						<div className='chargeICDFilterContainer'>
							{filter.map((option) => {
								const internalValue = get(
									option,
									'internal',
									''
								);
								const checked =
									selectedFilter.includes(internalValue);
								return (
									<div
										className='simpleCheckboxOption'
										key={internalValue}
									>
										<CommonCheckbox
											checked={checked}
											onChangeFunc={(e) =>
												this.props.changeFilterValue(
													e,
													internalValue
												)
											}
											topOverride={-1}
											marginLeftOverride={0}
											marginRightOverride={11}
											marginBottomOverride={0}
										/>
										<div
											className='simpleCheckboxOptionLabel'
											style={{
												color: checked
													? '#7b26c9'
													: '#262837',
											}}
										>
											{get(option, 'external', null) ||
												'--'}
										</div>
									</div>
								);
							})}
						</div>
					</React.Fragment>
				)}
				{this.renderComponent()}
			</div>
		);
	}
}

export default ICDSearch;
