//React & Redux
import React, { Fragment, useState, useEffect } from 'react';

//Other Libraries
import { isEmpty, isNil, debounce } from 'lodash';
import { Dialog, Button, Layout, Select, Input } from 'gm-element-react';

//Styles
import './css/add-edit-nucleotide-repeat-variant-modal.css';

//Images
import ReactSVG from 'react-svg';
import trashIcon from '../../../../assets/delete.svg';

//Components
import Icon from '../../../Common/Icon';

//Utils
import {
	labClassificationNucleotideVariantEnum,
	genomicSourceClassEnum,
	allelePreSetEnum,
} from '../../../../utils';

const comparatorAlleleEnum = {
	'<': 'LT',
	'>': 'GT',
	'>=': 'GE',
	'<=': 'LE',
};

const AddEditNucleotideRepeatVariantModal = (props) => {
	const {
		modalMode = 'add',
		hideAddEditNucleotideRepeatVariantModalFunc,
		geneticResultGenesSymbolEnum = [],
		getReferenceAssembliesEnum,
		referenceAssembliesEnum,
		getGeneticResultGenesSymbol,
		resetGetGeneticResultGenesSymbol,
		labUUID = null,
		saveNucleotideRepeatVariantFinding,
		nucleotideRepeatVariantFindings = {},
		deleteNucleotideRepeatVariantFinding,
	} = props;

	const [formdata, setFormdata] = useState({
		geneId: 0,
		referenceAssembly: 'grch37',
		referenceSequence: null,
		numericAllele1: null,
		preSetAllele1: null,
		labClassificationAllele1: null,
		numericAllele2: null,
		preSetAllele2: null,
		labClassificationAllele2: null,
		dnaChange: null,
		genomicSourceClass: null,
	});
	const [genesSearchLoading, setGenesSearchLoading] = useState(false);
	const [errors, setErrors] = useState({
		errors: {},
	});
	const [geneSymbolLabel, setGeneSymbolLabel] = useState('');

	useEffect(() => {
		getReferenceAssembliesEnum();

		if (
			isEditMode() &&
			!isNil(nucleotideRepeatVariantFindings?.genesSymbol?.symbol) &&
			!isEmpty(nucleotideRepeatVariantFindings?.genesSymbol?.symbol)
		) {
			const data = {
				query: nucleotideRepeatVariantFindings?.genesSymbol?.symbol,
				referenceAssembly:
					nucleotideRepeatVariantFindings?.referenceAssembly?.code,
				labId: labUUID,
			};
			getGeneticResultGenesSymbol(data);
		}

		if (
			!isEmpty(nucleotideRepeatVariantFindings) &&
			!isNil(nucleotideRepeatVariantFindings) &&
			isEditMode()
		) {
			setFormdata({
				referenceAssembly:
					nucleotideRepeatVariantFindings?.referenceAssembly?.code ||
					'none',
				geneId:
					nucleotideRepeatVariantFindings?.genesSymbol?.id || null,
				numericAllele1:
					nucleotideRepeatVariantFindings?.allele1?.repeats?.value ||
					null,
				preSetAllele1:
					nucleotideRepeatVariantFindings?.allele1
						?.characterization || null,
				numericAllele2:
					nucleotideRepeatVariantFindings?.allele2?.repeats?.value ||
					null,
				preSetAllele2:
					nucleotideRepeatVariantFindings?.allele2
						?.characterization || null,
				labClassificationAllele1:
					nucleotideRepeatVariantFindings
						?.originalReportedInterpretationAllele1
						?.classification || null,
				labClassificationAllele2:
					nucleotideRepeatVariantFindings
						?.originalReportedInterpretationAllele2
						?.classification || null,
				referenceSequence:
					nucleotideRepeatVariantFindings?.referenceSequence || null,
				dnaChange:
					nucleotideRepeatVariantFindings?.hgvsDNAChange || null,
				genomicSourceClass:
					nucleotideRepeatVariantFindings?.genomicSourceClass || null,
			});
		}

		return () => {
			resetGetGeneticResultGenesSymbol();
		};
	}, []);

	useEffect(() => {
		if (formdata.geneId != 0) {
			const data = {
				query: geneSymbolLabel,
				referenceAssembly: formdata?.referenceAssembly,
				labId: labUUID,
			};
			getGeneticResultGenesSymbol(data);
		}
	}, [formdata?.referenceAssembly]);

	useEffect(() => {
		if (formdata.geneId != 0) {
			geneticResultGenesSymbolEnum.forEach((gene) => {
				setFormdata({
					...formdata,
					referenceSequence:
						gene.suggestedTranscriptReferenceSequence || null,
				});
			});
		}
	}, [geneticResultGenesSymbolEnum]);

	const isEditMode = () => {
		return modalMode == 'edit';
	};

	const closeAddEditNucleotideRepeatVariantModal = () => {
		hideAddEditNucleotideRepeatVariantModalFunc();
	};

	const isDisableSavedButtonFunc = () => {
		const errors = validateForm(formdata);
		if (isEmpty(errors)) {
			return false;
		} else {
			return true;
		}
	};

	const validateForm = (formdata) => {
		const errors = {};

		if (formdata.geneId == 0) {
			errors.geneId = 'Please select gene symbol';
		}

		if (
			(isNil(formdata.numericAllele1) ||
				isEmpty(formdata.numericAllele1)) &&
			(isNil(formdata.preSetAllele1) || isEmpty(formdata.preSetAllele1))
		) {
			errors.numericAllele1_empty =
				'First allele numeric or pre set is required';
		}

		if (
			isNil(formdata.labClassificationAllele1) ||
			isEmpty(formdata.labClassificationAllele1)
		) {
			errors.labClassificationAllele1 =
				'Please select lab classification';
		}

		if (
			!isNil(formdata.numericAllele1) &&
			!isEmpty(formdata.numericAllele1) &&
			!formdata.numericAllele1.match(/^(<|>|>=|<=)?\d+(\.\d{1,6})?$/)
		) {
			errors.numericAllele1 = 'Incorrect entry format';
		}

		if (
			!isNil(formdata.numericAllele2) &&
			!isEmpty(formdata.numericAllele2) &&
			!formdata.numericAllele2.match(/^(<|>|>=|<=)?\d+(\.\d{1,6})?$/)
		) {
			errors.numericAllele2 = 'Incorrect entry format';
		}

		if (
			!isNil(formdata.dnaChange) &&
			!isEmpty(formdata.dnaChange) &&
			!formdata.dnaChange.match(/^[cmn]\.\S[^\t\n\r\f\v]{0,}$/)
		) {
			errors.dnaChange = 'Incorrect entry format';
		}

		return errors;
	};

	const getQueryGeneticResultGene = debounce((query) => {
		getGeneticResultGenesSymbol({
			query: query,
			referenceAssembly: formdata?.referenceAssembly || 'grch37',
			labId: labUUID,
		});
		setGeneSymbolLabel(query);
	}, 500);

	const onGenesSymbolSearch = (query) => {
		if (query !== '') {
			setGenesSearchLoading(false);
			if (query.length > 1) getQueryGeneticResultGene(query);
		} else {
			formdata.geneId = 0;
			setFormdata(formdata);
		}
	};

	const onFormChange = (key, value) => {
		let errors = {};
		formdata[key] = value;
		errors = validateForm(formdata);
		setFormdata(formdata);
		setErrors(errors);
		if (key == 'geneId') {
			if (!isEmpty(geneticResultGenesSymbolEnum)) {
				geneticResultGenesSymbolEnum.forEach((gene) => {
					if (gene.id && gene.id === value) {
						setFormdata((state) => ({
							...state,
							referenceSequence:
								gene.suggestedTranscriptReferenceSequence ||
								null,
						}));
					}
				});
			}
		}
	};

	const saveTestResultData = () => {
		const _formdata = formdata;

		closeAddEditNucleotideRepeatVariantModal();

		if (_formdata?.genomicSourceClass === null) {
			_formdata.genomicSourceClass = 'UNKNOWN';
		}

		if (!isNil(_formdata?.numericAllele1)) {
			_formdata.comparatorAllele1 =
				comparatorAlleleEnum[
					_formdata?.numericAllele1.split(/\d/)[0]
				] || _formdata?.numericAllele1.split(/\d/)[0];
			_formdata.numericAllele1 = _formdata?.numericAllele1.replace(
				/[^.0-9]/g,
				''
			);
		} else {
			delete _formdata.comparatorAllele1;
			delete _formdata.numericAllele1;
		}

		if (!isNil(_formdata?.numericAllele2)) {
			_formdata.comparatorAllele2 =
				comparatorAlleleEnum[
					_formdata?.numericAllele2.split(/\d/)[0]
				] || _formdata?.numericAllele2.split(/\d/)[0];
			_formdata.numericAllele2 = _formdata?.numericAllele2.replace(
				/[^.0-9]/g,
				''
			);
		} else {
			delete _formdata.comparatorAllele2;
			delete _formdata.numericAllele2;
		}

		saveNucleotideRepeatVariantFinding(
			{
				..._formdata,
				uuid: nucleotideRepeatVariantFindings?.uuid || null,
			},
			isEditMode()
		);
	};

	const _deleteNucleotideRepeatVariantFinding = () => {
		if (nucleotideRepeatVariantFindings?.uuid) {
			closeAddEditNucleotideRepeatVariantModal();
			deleteNucleotideRepeatVariantFinding(
				nucleotideRepeatVariantFindings?.uuid
			);
		}
	};

	return (
		<Dialog
			customClass='add-edit-nucleotide-repeat-variant-dialog'
			title={
				<span className='header'>
					<span>
						{isEditMode() ? 'Edit' : 'New'} Nucleotide Repeat
						Variant Finding
					</span>
					<span
						className='close-btn-icon'
						onClick={closeAddEditNucleotideRepeatVariantModal}
					>
						<Icon icon='close' className='close-icon' />
					</span>
				</span>
			}
			size='tiny'
			modal={true}
			closeOnPressEscape={true}
			closeOnClickModal={false}
			showClose={false}
			visible={true}
			onCancel={closeAddEditNucleotideRepeatVariantModal}
		>
			<Dialog.Body>
				<section className='basic-information-module'>
					<h2 className='module-header'>Basic Information</h2>
					<Layout.Row gutter='12'>
						<Layout.Col span='8' className='required'>
							<span>Gene Symbol</span>
							<Select
								name='geneId'
								value={formdata.geneId}
								onChange={(value) =>
									onFormChange('geneId', value)
								}
								filterable={true}
								remote={true}
								clearable={true}
								placeholder='Gene Symbol'
								remoteMethod={(val) => onGenesSymbolSearch(val)}
								loading={genesSearchLoading}
							>
								{geneticResultGenesSymbolEnum.map((el) => {
									return (
										<Select.Option
											key={el.id}
											label={el.symbol}
											value={el.id}
										/>
									);
								})}
							</Select>
						</Layout.Col>
						<Layout.Col span='8'>
							<span>Reference Sequence</span>
							<Input
								name='referenceSequence'
								value={formdata?.referenceSequence}
								onChange={(value) =>
									onFormChange('referenceSequence', value)
								}
								placeholder='Enter reference sequence'
							/>
						</Layout.Col>
						<Layout.Col span='8'>
							<span>Reference Assembly</span>
							<Select
								name='referenceAssembly'
								value={formdata?.referenceAssembly || 'grch37'}
								onChange={(value) =>
									onFormChange('referenceAssembly', value)
								}
								placeholder='Select'
							>
								{referenceAssembliesEnum &&
									referenceAssembliesEnum.map((item, idx) => {
										return (
											<Select.Option
												key={idx}
												label={item.name}
												value={item.code}
											/>
										);
									})}
							</Select>
						</Layout.Col>
					</Layout.Row>
				</section>

				<section className='identification-module'>
					<h2 className='module-header'>
						Identification & Interpretation
					</h2>
					<Layout.Row gutter='12' className='mt-32'>
						<Layout.Col>
							<div className='repeat-allele-title required'>
								Repeat - First Allele
							</div>
							<div className='repeat-allele-subtitle'>
								Fill in either "Numeric" or "Pre-Set" fields in
								addition to "Lab Classification."
							</div>
						</Layout.Col>
					</Layout.Row>
					<Layout.Row gutter='12'>
						<Layout.Col span='16'>
							<Layout.Row gutter='12' className='mt-zero'>
								<Layout.Col span='12'>
									<span>Numeric</span>
									<Input
										name='numericAllele1'
										value={formdata?.numericAllele1}
										onChange={(value) =>
											onFormChange(
												'numericAllele1',
												value
											)
										}
										placeholder='ex: >40'
									/>
									<span className='or-label'>OR</span>
									<div className='input-error-text'>
										{errors?.numericAllele1 || ''}
									</div>
								</Layout.Col>
								<Layout.Col span='12'>
									<span>Pre-Set</span>
									<Select
										name='preSetAllele1'
										value={formdata?.preSetAllele1}
										onChange={(value) =>
											onFormChange('preSetAllele1', value)
										}
										placeholder='Expanded'
									>
										{allelePreSetEnum.map((item, idx) => {
											return (
												<Select.Option
													key={idx}
													label={item.display_name}
													value={item.key}
												/>
											);
										})}
									</Select>
								</Layout.Col>
							</Layout.Row>
						</Layout.Col>
						<Layout.Col
							span='8'
							style={{
								paddingLeft: '25px',
								paddingRight: '6px',
								borderLeft: '1px solid #d8dde4',
								marginLeft: '-20px',
							}}
						>
							<Layout.Row gutter='12' className='mt-zero'>
								<Layout.Col className='required'>
									<span>Lab Classification</span>
									<Select
										name='labClassificationAllele1'
										value={
											formdata?.labClassificationAllele1
										}
										onChange={(value) =>
											onFormChange(
												'labClassificationAllele1',
												value
											)
										}
										placeholder='Select'
									>
										{labClassificationNucleotideVariantEnum.map(
											(item, idx) => {
												return (
													<Select.Option
														key={idx}
														label={
															item.display_name
														}
														value={item.key}
													/>
												);
											}
										)}
									</Select>
								</Layout.Col>
							</Layout.Row>
						</Layout.Col>
					</Layout.Row>

					<Layout.Row gutter='12' className='mt-32'>
						<Layout.Col>
							<div className='repeat-allele-title'>
								Repeat - Second Allele
							</div>
							<div className='repeat-allele-subtitle'>
								Fill in either "Numeric" or "Pre-Set" fields in
								addition to "Lab Classification."
							</div>
						</Layout.Col>
					</Layout.Row>
					<Layout.Row gutter='12'>
						<Layout.Col span='16'>
							<Layout.Row gutter='12' className='mt-zero'>
								<Layout.Col span='12'>
									<span>Numeric</span>
									<Input
										name='numericAllele2'
										value={formdata?.numericAllele2}
										onChange={(value) =>
											onFormChange(
												'numericAllele2',
												value
											)
										}
										placeholder='ex: >40'
									/>
									<span className='or-label'>OR</span>
									<div className='input-error-text'>
										{errors?.numericAllele2 || ''}
									</div>
								</Layout.Col>
								<Layout.Col span='12'>
									<span>Pre-Set</span>
									<Select
										name='preSetAllele2'
										value={formdata?.preSetAllele2}
										onChange={(value) =>
											onFormChange('preSetAllele2', value)
										}
										placeholder='Expanded'
									>
										{allelePreSetEnum.map((item, idx) => {
											return (
												<Select.Option
													key={idx}
													label={item.display_name}
													value={item.key}
												/>
											);
										})}
									</Select>
								</Layout.Col>
							</Layout.Row>
						</Layout.Col>
						<Layout.Col
							span='8'
							style={{
								paddingLeft: '25px',
								paddingRight: '6px',
								borderLeft: '1px solid #d8dde4',
								marginLeft: '-20px',
							}}
						>
							<Layout.Row gutter='12' className='mt-zero'>
								<Layout.Col>
									<span>Lab Classification</span>
									<Select
										name='labClassificationAllele2'
										value={
											formdata?.labClassificationAllele2
										}
										onChange={(value) =>
											onFormChange(
												'labClassificationAllele2',
												value
											)
										}
										placeholder='Select'
									>
										{labClassificationNucleotideVariantEnum.map(
											(item, idx) => {
												return (
													<Select.Option
														key={idx}
														label={
															item.display_name
														}
														value={item.key}
													/>
												);
											}
										)}
									</Select>
								</Layout.Col>
							</Layout.Row>
						</Layout.Col>
					</Layout.Row>

					<Layout.Row gutter='12'>
						<Layout.Col>
							<div>DNA Change</div>
							<Input
								name='dnaChange'
								value={formdata?.dnaChange}
								onChange={(value) =>
									onFormChange('dnaChange', value)
								}
								placeholder='ex: c.-45+179_-45+184[>30]'
							/>
							<div className='input-error-text'>
								{errors?.dnaChange || ''}
							</div>
						</Layout.Col>
					</Layout.Row>
				</section>

				<section className='additional-information-module'>
					<h2 className='module-header'>Additional Information</h2>
					<Layout.Row gutter='12'>
						<Layout.Col span='8'>
							<span>Genomic Source Class</span>
							<Select
								name='genomicSourceClass'
								value={formdata?.genomicSourceClass}
								onChange={(value) =>
									onFormChange('genomicSourceClass', value)
								}
								placeholder='Select genomic source'
							>
								{genomicSourceClassEnum.map((item, idx) => {
									return (
										<Select.Option
											key={idx}
											label={item.display_name}
											value={item.key}
										/>
									);
								})}
							</Select>
						</Layout.Col>
					</Layout.Row>
				</section>
			</Dialog.Body>
			<Dialog.Footer className='dialog-footer'>
				{!isEditMode() && (
					<>
						<Button
							className='next'
							disabled={isDisableSavedButtonFunc()}
							onClick={() => {
								saveTestResultData();
							}}
						>
							Add Finding
						</Button>
					</>
				)}
				{isEditMode() && (
					<Fragment>
						{!isEmpty(
							nucleotideRepeatVariantFindings?.assertedBy
						) &&
							nucleotideRepeatVariantFindings?.assertedBy ===
								'User' && (
								<Button
									className='delete-variant'
									onClick={() =>
										_deleteNucleotideRepeatVariantFinding()
									}
								>
									<ReactSVG
										src={trashIcon}
										className='delete-variant-icon'
									/>
									<span>Delete Finding</span>
								</Button>
							)}
						<Button
							className='save'
							disabled={isDisableSavedButtonFunc()}
							onClick={() => {
								saveTestResultData();
							}}
						>
							Save
						</Button>
					</Fragment>
				)}
			</Dialog.Footer>
		</Dialog>
	);
};

export default AddEditNucleotideRepeatVariantModal;
