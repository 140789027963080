//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Components
import SingleSelectPill from './SingleSelectPill.js';
import MoreFiltersModal from './MoreFiltersModal.js';

//Styles
import './styles.css';

class MoreFilters extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
		};
		//bindings
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.setPillElement = this.setPillElement.bind(this);
		this.setLabel = this.setLabel.bind(this);
	}
	//functions

	openModal() {
		this.setState({ modalOpen: true });
	}

	closeModal() {
		this.setState({ modalOpen: false });
	}

	setPillElement(el) {
		if (el) this.setState({ pillElement: el });
	}

	setLabel() {
		const data = get(this, 'props.data', []) || [];
		let baseStr = get(this, ['props', 'pillTitle'], 'More');
		if (Array.isArray(data)) {
			const filteredData = data.filter((el) => {
				return !_.isEqual(
					get(el, 'selected', null),
					get(el, 'defaultValues', null)
				);
			});
			const filteredDataLength = get(filteredData, 'length', 0);
			if (filteredDataLength) baseStr += `: ${filteredDataLength}`;
		}

		return baseStr;
	}

	render() {
		return (
			<div className='singleSelectContainer'>
				<SingleSelectPill
					label={this.setLabel()}
					modalOpen={get(this, 'state.modalOpen', false)}
					openModal={this.openModal}
					closeModal={this.closeModal}
					setPillElement={this.setPillElement}
				/>
				{get(this, 'state.modalOpen', false) && (
					<MoreFiltersModal
						closeModal={this.closeModal}
						title={get(this, 'props.title', '') || 'More Filters'}
						data={get(this, 'props.data', []) || []}
						currentValues={get(this, 'props.currentValues', null)}
						setStateFunc={this.props.setStateFunc}
						submit={this.props.submit}
						hasSetStateFunc={get(
							this,
							'state.hasSetStateFunc',
							false
						)}
						convertStrsToNums={get(
							this,
							'props.convertStrsToNums',
							false
						)}
						pillElement={get(this, 'state.pillElement', null)}
						alternateModalClass={get(
							this,
							'props.alternateModalClass',
							null
						)}
					/>
				)}
			</div>
		);
	}
}

export default MoreFilters;
