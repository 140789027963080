//React & Redux
import React, { Fragment } from 'react';

//Lodash
import { get } from 'lodash';

//UI Libraries
import { Layout, Form, Button } from 'gm-element-react';

//Images
import ReactSVG from 'react-svg';
import Edit from './../../../../assets/edit-outreach.svg';

//Styles
import './css/view-cfdna-cancer-genedetail.css';

//Utils
import { CARE_PATIENTS_ENTER_TEST_RESULT } from '../../../../Utils/permissionUtils';

//Components
import RBAC from '../../../RBAC';
import NotesContainer from '../../../Common/Notes/NotesContainer';
import { isFeatureEnabled } from '../../../../Utils/featureFlags';

const ViewCFDNACancerGeneDetail = (props) => {
	const {
		addDiagnosticFindingClinicalNoteError,
		addDiagnosticFindingClinicalNoteLoading,
		addNewDiagnosticFindingClinicalNote,
		finding = {},
		showcfDNACancerModal,
	} = props;

	const uuid = get(finding, ['uuid'], null);
	const notes = get(finding, ['notes'], []);

	return (
		<Fragment>
			<Form className='view-cfdna-cancer-gene-form'>
				<Layout.Row gutter='20' className='cfDNA-title'>
					<Layout.Col>
						Cell-Free DNA (cfDNA) Cancer-Associated Methylation
					</Layout.Col>
				</Layout.Row>
				<Layout.Row gutter='32' className='clinical-comments-container'>
					<Layout.Col span='16' className='cfDNA-result-section'>
						<Layout.Row className='cfDNA-result-details-label'>
							<Layout.Col>Result Details</Layout.Col>
						</Layout.Row>

						<Layout.Row>
							<Layout.Col span='8'>
								<label htmlFor='primaryPredictedOrign'>
									Cancer Signal
								</label>
								<span className='field-val'>
									{get(finding, ['cancerSignal']) || '--'}
								</span>
							</Layout.Col>
							<Layout.Col span='8'>
								<label htmlFor='primaryPredictedOrign'>
									Top Predicted Origin
								</label>
								<span className='field-val'>
									{get(finding, ['primary', 'value']) || '--'}
								</span>
							</Layout.Col>
							{
								isFeatureEnabled('SECONDARY_ORIGIN_ENABLE', {allowCookie: true,}) ?
									(get(finding, ['secondary', 'value']) &&
										<Layout.Col span='8'>
											<label htmlFor='primaryPredictedOrign'>
												Secondary Predicted Origin
											</label>
											<span className='field-val'>
												{get(finding, ['secondary', 'value'])}
											</span>
										</Layout.Col>
									) :
									<Layout.Col span='8'>
										<label htmlFor='primaryPredictedOrign'>
											Secondary Predicted Origin
										</label>
										<span className='field-val'>
											{get(finding, ['secondary', 'value']) || '--'}
										</span>
									</Layout.Col>
							}
						</Layout.Row>

						<Layout.Row>
							<Layout.Col span='6' push='18'>
								<RBAC
									action={CARE_PATIENTS_ENTER_TEST_RESULT}
									yes={
										<Button
											className='edit-details-button'
											onClick={() =>
												showcfDNACancerModal(
													'edit',
													finding
												)
											}
										>
											<ReactSVG
												src={Edit}
												className='cfDNA-result-edit-icon'
											/>
											Edit Original
										</Button>
									}
								/>
							</Layout.Col>
						</Layout.Row>
					</Layout.Col>

					<Layout.Col span='8'>
						<span className='cfDNA-clinical-comments-label'>
							Clinical Comments
						</span>
						<NotesContainer
							notes={notes}
							variant='cfDNA'
							addNoteLoading={
								addDiagnosticFindingClinicalNoteLoading || false
							}
							addNoteError={
								addDiagnosticFindingClinicalNoteError || null
							}
							addNote={addNewDiagnosticFindingClinicalNote}
							sourceUUID={uuid}
							addNoteModalTitle='New Comment — cfDNA Cancer'
							emptyMessageClass='empty-clinical-comments-msg'
							isShowListIcon={false}
							hasRBACAddComment={true}
							RBACAddCommentAction={
								CARE_PATIENTS_ENTER_TEST_RESULT
							}
						/>
					</Layout.Col>
				</Layout.Row>
			</Form>
		</Fragment>
	);
};

export default ViewCFDNACancerGeneDetail;
