//React & Redux
import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

//Images
import ReactSVG from 'react-svg';
import moreinfoicon from '../../../../assets/purple-more-info.svg';
import timerIcon from '../../../../assets/icons/timer-24.svg';
import timer_offIcon from '../../../../assets/icons/timer-off.svg';
import warningImg from '../../../../assets/milestone-warning.svg';
import add from '../Tests/images/add.svg';

//Lodash
import _, { isNil } from 'lodash';

//Utils
import {
	createEnumsOptions,
	filterOrderStatus,
	CascaderOptions,
	findCascaderHierarchy,
	OrderRequestFinalState,
	currentForm,
	TESTREQUEST,
	getSampleTypeGrouping,
	OutreachWarnMsg,
	OUTREACH_ON_HOLD_WARN_MSG,
	OUTREACH_WAITING_TO_START_WARN_MSG,
	OUTREACH_INPROGRESS_WARN_MSG,
	determineDropdownQuestionnaire,
	determineShippingAddressRequired,
	createStateEnumsOptions,
} from '../Encounters/Common/utilsfunctions';
import { states_list } from './../../../../utils.js';

//UI Libraries
import {
	Layout,
	Select,
	Cascader,
	Checkbox,
	Radio,
	Popover,
	Input,
	Button,
} from 'gm-element-react';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious';

//Other Libraries
import moment from 'moment';
import classnames from 'classnames';
import Datetime from 'react-datetime';

//Components
import TestOrderMilestoneWarning from './TestOrderMilestoneWarning';
import WarningMessageBox from '../../../Common/WarningMessageBox';
import { EncounterDateField } from './DateComponent';
import ICDTag from '../../ChargeInformation/ICDTag';
import ErrorBoundary from '../../../Common/ErrorBoundary';

//Styles
import './create-test-request-modal.css';

const DateTypes = ['date_any', 'date_past', 'date_future'];

const EditTestRequestLabInfo = (props) => {
	const {
		userSelectedICDCodes,
		testorder = {},
		formdata = {},
		onFormChange,
		onResultActionChange,
		handleDateFieldValidate,
		mloOrderStatusEnums = [],
		laborderstatusenums = [],
		labenums = [],
		testOrderList = [],
		holdResultRelease = false,
		outreachSeqNo = 0,
		nonlegacytestOrderPopupTestEnums,
		shippingAddressFormData = {},
		onShippingAddressFormChange,
		onAddAnotherTestNameFormChange,
		genetic_test2,
		genetic_test3,
		testName2Enums,
		testName3Enums,
		reflexAOEQuestions,
		reflexAOEAnswers = [],
		setReflexAnswers,
		hideModal,
		hasPatientNoInsurance,
	} = props;

	const [isSelectedPositiveResult, setIsSelectedPositiveResult] =
		useState(null);
	const [showWarnMsg, setShowWarnMsg] = useState(false);
	const [isResultActionable, setIsResultActionable] = useState(null);
	const [optionsLabEnum, setOptionsLabEnum] = useState(labenums);
	const [addAnotherTest2, setAddAnotherTest2] = useState({
		addTest2: !_.isEmpty(genetic_test2?.testNameUuid) ? 'yes' : 'no',
		test_name2: !_.isEmpty(genetic_test2?.testNameUuid)
			? genetic_test2?.testNameUuid
			: undefined,
		enums: !_.isEmpty(testName2Enums) ? testName2Enums : [],
	});
	const [addAnotherTest3, setAddAnotherTest3] = useState({
		addTest3: !_.isEmpty(genetic_test3?.testNameUuid) ? 'yes' : 'no',
		test_name3: !_.isEmpty(genetic_test3?.testNameUuid)
			? genetic_test3?.testNameUuid
			: undefined,
		enums: !_.isEmpty(testName3Enums) ? testName3Enums : [],
	});
	const [optionsTestNameEnum, setOptionsTestNameEnum] = useState(
		_.isArray(
			createEnumsOptions(
				props,
				['nonlegacytestOrderPopupTestEnums'],
				'key',
				'display_name',
				'key'
			)
		)
			? createEnumsOptions(
					props,
					['nonlegacytestOrderPopupTestEnums'],
					'key',
					'display_name',
					'key'
			  )
			: []
	);
	const [optionsOrderingPhysicianEnum, setOptionsOrderingPhysicianEnum] =
		useState(
			createEnumsOptions(
				props,
				['ordering_physicianenums'],
				'key',
				'display_name',
				'key'
			)
		);
	const [optionsBillingMethodEnum, setOptionsBillingMethodEnum] = useState(
		_.isArray(
			createEnumsOptions(
				props,
				['patientbillingmethodenums'],
				'key',
				'display_name',
				'key'
			)
		)
			? createEnumsOptions(
					props,
					['patientbillingmethodenums'],
					'key',
					'display_name',
					'key'
			  )
			: []
	);
	const [optionsLabOrderStatusEnum, setOptionsLabOrderStatusEnum] =
		useState(laborderstatusenums);
	const [optionsBDSalivaKitSentEnum, setOptionsBDSalivaKitSentEnum] =
		useState(
			_.isArray(
				createEnumsOptions(
					props,
					['encounterschema', 'bd_saliva_kit_sent'],
					'key',
					'display_name',
					'key'
				)
			)
				? createEnumsOptions(
						props,
						['encounterschema', 'bd_saliva_kit_sent'],
						'key',
						'display_name',
						'key'
				  )
				: []
		);
	const [optionsBloodDrawRequestEnum, setOptionsBloodDrawRequestEnum] =
		useState(
			_.isArray(
				createEnumsOptions(
					props,
					['encounterschema', 'blood_draw_request'],
					'key',
					'display_name',
					'key'
				)
			)
				? createEnumsOptions(
						props,
						['encounterschema', 'blood_draw_request'],
						'key',
						'display_name',
						'key'
				  )
				: []
		);
	const [optionsStatesEnum, setOptionsStatesEnum] = useState(
		createStateEnumsOptions(states_list, 'key', 'value', 'key')
	);
	const [optionsSampleTypesEnum, setOptionsSampleTypesEnum] = useState(
		_.isArray(
			createEnumsOptions(
				props,
				['sampletypeenums'],
				'key',
				'display_name',
				'key'
			)
		)
			? createEnumsOptions(
					props,
					['sampletypeenums'],
					'key',
					'display_name',
					'key'
			  )
			: []
	);

	const prevNonlegacytestOrderPopupTestEnums = usePrevious(
		nonlegacytestOrderPopupTestEnums
	);

	useEffect(() => {
		const _testNameEnum = createEnumsOptions(
			props,
			['nonlegacytestOrderPopupTestEnums'],
			'key',
			'display_name',
			'key'
		);

		setOptionsTestNameEnum(_testNameEnum);

		if (
			!isNil(prevNonlegacytestOrderPopupTestEnums) &&
			JSON.stringify(nonlegacytestOrderPopupTestEnums) !==
				JSON.stringify(prevNonlegacytestOrderPopupTestEnums)
		) {
			setAddAnotherTest2((oldState) => ({
				...oldState,
				addTest2: !_.isEmpty(genetic_test2?.testNameUuid) ? 'yes' : 'no',
				test_name2: !_.isEmpty(genetic_test2?.testNameUuid)
					? genetic_test2?.testNameUuid
					: undefined,
				enums: !_.isEmpty(testName2Enums) ? testName2Enums : [],
			}));
			setAddAnotherTest3((oldState) => ({
				...oldState,
				addTest3: !_.isEmpty(genetic_test3?.testNameUuid) ? 'yes' : 'no',
				test_name3: !_.isEmpty(genetic_test3?.testNameUuid)
					? genetic_test3?.testNameUuid
					: undefined,
				enums: !_.isEmpty(testName3Enums) ? testName3Enums : [],
			}));
		}
	}, [nonlegacytestOrderPopupTestEnums]);

	const renderICDCodes = () => {
		let medicalCodes =
			userSelectedICDCodes || _.get(testorder, 'medical_codes', []) || [];
		medicalCodes = medicalCodes.slice().sort((a, b) => {
			if (_.get(a, 'code_name', '') < _.get(b, 'code_name', ''))
				return -1;
			else return 1;
		});
		if (medicalCodes.length > 0) {
			return medicalCodes.map((el, i) => {
				return (
					<React.Fragment>
						<ICDTag
							icdCode={el}
							toggleCodeSelection={() => {}}
							hasX={false}
							key={el.code_name}
						/>
					</React.Fragment>
				);
			});
		}
		return null;
	};

	const onFormChangeFunc = (key, value) => {
		if (key === 'positive_results_to_report') {
			if (value === true) {
				setIsSelectedPositiveResult(true);
			} else if (value === false) {
				setIsSelectedPositiveResult(false);
			} else {
				setIsSelectedPositiveResult(null);
			}
			setShowWarnMsg(true);
			/* change results actionable based on finding to report selection */
			onResultActionChangeFunc(value);
		} else if (key === 'hold_result_release') {
			setShowWarnMsg(true);
		} else if (key === 'genetic_test_uuid') {
			setAddAnotherTest2((oldState) => ({
				...oldState,
				addTest2: 'no',
				test_name2: undefined,
				enums: [],
			}));
			setAddAnotherTest3((oldState) => ({
				...oldState,
				addTest3: 'no',
				test_name3: undefined,
				enums: [],
			}));
		}
		onFormChange(key, value);
	};

	const onShippingAddressFormChangeFunc = (key, value) => {
		onShippingAddressFormChange(key, value);
	};

	const onResultActionChangeFunc = (value) => {
		/* use to set css class for yes/no choice  */
		if (value === true) {
			setIsResultActionable(true);
		} else if (value === false) {
			setIsResultActionable(false);
		} else {
			setIsResultActionable(null);
		}
		/* use to check if yes/no choice is selected, base on that make save button disable  */
		if (typeof value === 'boolean') {
			onResultActionChange(value);
		}
		/* use to set the value to save in BE */
		onFormChangeFunc('is_actionable', value);
	};

	const onHandleDateFieldValidate = (key, value) => {
		handleDateFieldValidate(key, value);
	};

	const isLabDisabled = () => {
		const propsEnum = {
			rejected_other: 1,
			no_test_available: 1,
		};

		const statusEnum = {
			patient_undecided: 1,
			requested: 1,
			declined_other: 1,
			ins_denied_cost: 1,
			iss_w_test_implications: 1,
			no_fu_from_patient: 1,
			rejected_other: 1,
			no_test_available: 1,
			awaiting_external_approval: 1,
			approved: 1,
		};

		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);
		const stateorderstatus = _.get(formdata, ['order_status'], {});
		const labName = _.get(testorder, ['lab'], '');
		const testName = _.get(testorder, ['test_name'], '');

		if (
			!(
				_.includes(_.toLower(labName), 'other') ||
				_.includes(_.toLower(testName), 'other')
			) &&
			propsorderstatus in propsEnum
		) {
			return true;
		} else if (stateorderstatus in statusEnum) {
			return false;
		}

		return true;
	};

	const isTestNameDisabled = () => {
		const propsEnum = {
			rejected_other: 1,
			no_test_available: 1,
		};

		const statusEnum = {
			patient_undecided: 1,
			requested: 1,
			declined_other: 1,
			ins_denied_cost: 1,
			iss_w_test_implications: 1,
			no_fu_from_patient: 1,
			rejected_other: 1,
			no_test_available: 1,
			awaiting_external_approval: 1,
			approved: 1,
		};

		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);
		const stateorderstatus = _.get(formdata, ['order_status'], {});
		const labName = _.get(testorder, ['lab'], '');
		const testName = _.get(testorder, ['test_name'], '');

		if (
			!(
				_.includes(_.toLower(labName), 'other') ||
				_.includes(_.toLower(testName), 'other')
			) &&
			propsorderstatus in propsEnum
		) {
			return true;
		} else if (stateorderstatus in statusEnum) {
			return false;
		}

		return true;
	};

	const isTestAuthorizeDateDisabled = () => {
		const statusEnum = {
			approved: 1,
			awaiting_cc_submission: 1,
		};

		const statestatusEnum = {
			rejected: 1,
			approved: 1,
			awaiting_cc_submission: 1,
			rejected_other: 1,
			no_test_available: 1,
		};
		const rejectedenums = {
			rejected_other: 1,
			no_test_available: 1,
		};

		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);
		const stateorderstatus = _.get(formdata, ['order_status'], {});

		if (
			propsorderstatus in statusEnum ||
			(!(propsorderstatus in rejectedenums) &&
				stateorderstatus in statestatusEnum)
		) {
			return false;
		}

		return true;
	};

	const isTestDispatchDateDisabled = () => {
		let result = false;

		const statusEnum = {
			cancelled: 1,
			sent_to_lab: 1,
			results_ready: 1,
			results_entered: 1,
		};

		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);
		const stateorderstatus = _.get(formdata, ['order_status'], {});

		if (propsorderstatus in statusEnum) {
			result = true;
		} else if (stateorderstatus in statusEnum) {
			result = false;
		} else {
			result = true;
		}

		return result;
	};

	const showTestDispatchExpectedDate = () => {
		return !(
			_.get(formdata, ['order_status'], '') in
			{
				results_ready: 1,
				result_positive_no_genes: 1,
				result_positive_with_genes: 1,
				result_negative: 1,
			}
		);
	};

	const isOrderStatusDisabled = () => {
		const statusEnum = {
			declined_other: 1,
			ins_denied_cost: 1,
			iss_w_test_implications: 1,
			no_fu_from_patient: 1,
			declined_via_partner: 1,
			rejected: 1,
			patient_declined: 1,
			no_test_available: 1,
			rejected_other: 1,
			results_ready: 1,
			results_entered: 1,
			result_positive_no_genes: 1,
			result_positive_with_genes: 1,
			result_negative: 1,
		};
		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);
		const cancelledStatusEnum = {
			cancelled: 1,
			order_cancelled_by_patient: 1,
			order_cancelled_by_provider: 1,
			order_cancelled_by_lab: 1,
			order_cancelled_by_partner: 1,
		};
		const declinedStatusEnum = {
			declined_other: 1,
			ins_denied_cost: 1,
			iss_w_test_implications: 1,
			no_fu_from_patient: 1,
		};

		const history = _.get(testorder, 'history', []);

		const sortedHistory = _.orderBy(
			history,
			(o) => {
				return new moment(_.get(o, 'created_at', null));
			},
			['desc']
		);
		let previousState = _.nth(sortedHistory, 1);
		const previousPreviousState = _.nth(sortedHistory, 2);

		if (
			previousState &&
			previousPreviousState &&
			previousState.order_status == 'approved' &&
			previousPreviousState.order_status == 'waiting_to_submit' &&
			previousPreviousState.status_reason == 'awaiting_cc_submission'
		) {
			previousState = previousPreviousState;
		}

		if (
			propsorderstatus in cancelledStatusEnum &&
			!_.isNil(previousState) &&
			previousState.order_status in
				{
					approved: 1,
					requested: 1,
					patient_undecided: 1,
					awaiting_external_approval: 1,
				}
		) {
			return true;
		}

		if (
			_.get(testorder, ['localorderstatus'], null) in declinedStatusEnum
		) {
			const isRequestedTestOrder = _.find(sortedHistory, (e) => {
				return e && e.order_status == 'requested';
			});
			if (isRequestedTestOrder) {
				return true;
			}
			return false;
		}

		if (_.get(testorder, ['localorderstatus'], null) in statusEnum) {
			return true;
		}
		return false;
	};

	const isDisabledBDSalivaKitStatus = () => {
		let result = false;

		const statusEnum = {
			awaiting_cc_submission: 1,
			approved: 1,
		};

		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);
		const stateorderstatus = _.get(formdata, ['order_status'], {});
		const dateTestOrdered = _.get(testorder, ['date_test_ordered'], '');

		if (stateorderstatus in statusEnum && propsorderstatus in statusEnum) {
			result = true;
		} else if (
			!_.isEmpty(dateTestOrdered) &&
			(propsorderstatus === 'patient_not_responsive' ||
				stateorderstatus === 'patient_not_responsive')
		) {
			result = false;
		} else if (
			_.isEmpty(dateTestOrdered) &&
			(propsorderstatus === 'patient_not_responsive' ||
				stateorderstatus === 'patient_not_responsive')
		) {
			result = true;
		} else if (
			_.isEmpty(dateTestOrdered) &&
			(propsorderstatus === 'order_cancelled_by_patient' ||
				propsorderstatus === 'order_cancelled_by_provider' ||
				propsorderstatus === 'order_cancelled_by_lab' ||
				stateorderstatus === 'order_cancelled_by_patient' ||
				stateorderstatus === 'order_cancelled_by_lab' ||
				stateorderstatus === 'order_cancelled_by_provider')
		) {
			result = true;
		} else {
			result = false;
		}

		return result;
	};

	const isExpectedResultsDateDisabled = () => {
		let result = false;
		const propsEnum = {
			sent_to_lab: 1,
			patient_not_responsive: 1,
			sample_not_submitted: 1,
		};

		const statusEnum = {
			sent_to_lab: 1,
			patient_not_responsive: 1,
			sample_not_submitted: 1,
		};

		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);
		const stateorderstatus = _.get(formdata, ['order_status'], {});
		const _dateTestOrdered = _.get(testorder, ['date_test_ordered'], null);

		if (propsorderstatus in propsEnum) {
			if (
				propsorderstatus == 'patient_not_responsive' &&
				stateorderstatus != 'sent_to_lab' &&
				(_.isNil(_dateTestOrdered) || _.isEmpty(_dateTestOrdered))
			) {
				result = true;
			} else {
				result = false;
			}
		} else if (stateorderstatus in statusEnum) {
			if (
				stateorderstatus == 'patient_not_responsive' &&
				(_.isNil(_dateTestOrdered) || _.isEmpty(_dateTestOrdered))
			) {
				result = true;
			} else {
				result = false;
			}
		} else {
			result = true;
		}

		return result;
	};

	const isResultsReceiptDateDisabled = () => {
		const statusEnum = {
			results_ready: 1,
			results_entered: 1,
			result_positive_no_genes: 1,
			result_positive_with_genes: 1,
			result_negative: 1,
		};

		const stateorderstatus = _.get(formdata, ['order_status'], {});

		if (stateorderstatus in statusEnum) {
			return false;
		}
		return true;
	};

	const isSampleTypeDisabled = () => {
		const statusEnum = {
			cancelled: 1,
			order_cancelled_by_patient: 1,
			order_cancelled_by_provider: 1,
			order_cancelled_by_lab: 1,
			declined_other: 1,
			ins_denied_cost: 1,
			iss_w_test_implications: 1,
			no_fu_from_patient: 1,
			rejected: 1,
			patient_declined: 1,
			no_test_available: 1,
			rejected_other: 1,
			results_ready: 1,
			results_entered: 1,
			result_positive_no_genes: 1,
			result_positive_with_genes: 1,
			result_negative: 1,
		};
		if (_.get(testorder, ['localorderstatus'], null) in statusEnum) {
			return true;
		}
		return false;
	};

	const isOrderingPhysicanDisabled = () => {
		const statusEnum = {
			declined_other: 1,
			ins_denied_cost: 1,
			iss_w_test_implications: 1,
			no_fu_from_patient: 1,
			rejected: 1,
			patient_declined: 1,
			no_test_available: 1,
			rejected_other: 1,
		};
		if (_.get(testorder, ['order_status'], null) in statusEnum) {
			return true;
		}
		return false;
	};

	const isResultsReleaseDateDisabled = () => {
		const statusEnum = {
			results_ready: 1,
			results_entered: 1,
			result_positive_no_genes: 1,
			result_positive_with_genes: 1,
			result_negative: 1,
		};

		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);
		const stateorderstatus = _.get(formdata, ['order_status'], {});

		if (propsorderstatus in statusEnum || stateorderstatus in statusEnum) {
			return false;
		}
		return true;
	};

	const isExternalLabStatusDisabled = () => {
		return !_.get(testorder, ['can_change_lab_order_status'], true);
	};

	const isTestAuthorizeDateRequired = () => {
		const statusEnum = {
			rejected: 1,
			approved: 1,
			awaiting_cc_submission: 1,
			rejected_other: 1,
			no_test_available: 1,
		};

		const stateorderstatus = _.get(formdata, ['order_status'], {});

		if (stateorderstatus in statusEnum) {
			return true;
		}

		return false;
	};

	const isOrderingPhysicianRequired = () => {
		const statusEnum = {
			rejected: 1,
			approved: 1,
			awaiting_cc_submission: 1,
			rejected_other: 1,
			no_test_available: 1,
		};

		const stateorderstatus = _.get(formdata, ['order_status'], {});

		if (stateorderstatus in statusEnum) {
			return true;
		}

		return false;
	};

	const isResultsReceiptDateRequired = () => {
		const statusEnum = {
			results_ready: 1,
			result_positive_no_genes: 1,
			result_positive_with_genes: 1,
			result_negative: 1,
		};

		const propsEnum = {
			results_ready: 1,
			results_entered: 1,
		};

		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);
		const stateorderstatus = _.get(formdata, ['order_status'], {});

		if (propsorderstatus in propsEnum) {
			return false;
		} else if (stateorderstatus in statusEnum) {
			return true;
		}

		return false;
	};

	const isTestDispatchDateRequired = () => {
		let result = false;

		const statusEnum = {
			sent_to_lab: 1,
		};

		const stateorderstatus = _.get(formdata, ['order_status'], {});
		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);

		if (
			stateorderstatus in statusEnum &&
			!(propsorderstatus in statusEnum)
		) {
			result = true;
		} else {
			result = false;
		}

		return result;
	};

	const isExpectedResultsDateRequired = () => {
		let result = false;

		const statusEnum = {
			sent_to_lab: 1,
		};

		const stateorderstatus = _.get(formdata, ['order_status'], {});
		const propsorderstatus = _.get(testorder, ['localorderstatus'], null);

		if (
			stateorderstatus in statusEnum &&
			!(propsorderstatus in statusEnum)
		) {
			result = true;
		} else {
			result = false;
		}

		return result;
	};

	const renderTestOrderMilestoneWarning = () => {
		return (
			<TestOrderMilestoneWarning
				testorder={testorder}
				propsOrderStatus={_.get(testorder, ['localorderstatus'], null)}
				stateOrderStatus={_.get(formdata, ['order_status'], null)}
				mloOrderStatusEnums={mloOrderStatusEnums}
				positiveResultsToReport={_.get(
					formdata,
					['positive_results_to_report'],
					null
				)}
			/>
		);
	};

	const getOrderRequestedDate = () => {
		const history = _.get(testorder, 'history', []);
		const requestedDate = _.find(history, (obj) => {
			return obj.order_status === 'requested';
		});

		if (requestedDate) {
			return moment(
				moment
					.utc(_.get(requestedDate, 'created_at', null))
					.local()
					.format('YYYY-MM-DD')
			);
		}

		return null;
	};

	const renderMultipleOutreachWarnMsg = (msg, testNames) => {
		return (
			<Fragment>
				<span>{msg}</span>
				{_.map(testNames, (test, i) => {
					return (
						<span>
							{i + 1}) {test}
						</span>
					);
				})}
			</Fragment>
		);
	};

	const OutreachWarnMsgFunc = (flag = true) => {
		const currentTest = flag
			? {
					testorderID: _.get(testorder, ['test_order_uuid'], ''),
					holdResultRelease: _.get(
						formdata,
						['hold_result_release'],
						false
					),
					orderStatus: _.get(formdata, ['order_status'], ''),
					outreachSeqNo: outreachSeqNo,
			  }
			: {
					holdResultRelease: holdResultRelease,
			  };
		return OutreachWarnMsg(testOrderList, currentTest);
	};

	const setResultEntryOutreachWarnMsg = () => {
		const msgObj = OutreachWarnMsgFunc();
		switch (msgObj.warnMsg) {
			case OUTREACH_ON_HOLD_WARN_MSG:
				return {
					warningMsg: 'ROR Outreach will be on HOLD for this test.',
					warningIcon: timer_offIcon,
					boxBGColor: 'bg-gray',
					extndWarnText: 'outreach-hold-warn-text',
					extndWarnIcon: 'outreach-hold-warn-icon',
				};
			case OUTREACH_WAITING_TO_START_WARN_MSG:
				return {
					warningMsg:
						'Once saved, you will have 1 hr to change the result before the applicable RoR Outreach starts.',
					warningIcon: timerIcon,
					boxBGColor: 'bg-yellow',
					extndWarnText: 'outreach-start-warn-text',
					extndWarnIcon: 'outreach-hold-warn-icon',
				};
			case OUTREACH_INPROGRESS_WARN_MSG:
				return {
					warningMsg:
						'This test will be added to the RoR Outreach InProgress.',
					warningIcon: timerIcon,
					boxBGColor: 'bg-yellow',
					extndWarnText: 'outreach-start-warn-text',
					extndWarnIcon: 'outreach-hold-warn-icon',
				};
			default:
				return false;
		}
		return false;
	};

	const setHoldResultOutreachWarnMsg = () => {
		const msgObj = OutreachWarnMsgFunc();
		switch (msgObj.warnMsg) {
			case OUTREACH_ON_HOLD_WARN_MSG:
				return {
					warningMsg: renderMultipleOutreachWarnMsg(
						'ROR Outreach will be on HOLD for the following test(s):',
						msgObj.testNames
					),
					warningIcon: timer_offIcon,
					boxBGColor: 'bg-gray',
					extndWarnText: 'outreach-hold-warn-text',
					extndWarnIcon: 'outreach-hold-warn-icon',
					testNames: msgObj.testNames,
				};
			case OUTREACH_WAITING_TO_START_WARN_MSG:
				return {
					warningMsg: renderMultipleOutreachWarnMsg(
						'ROR Outreach will start for the following test(s) in an hour:',
						msgObj.testNames
					),
					warningIcon: timerIcon,
					boxBGColor: 'bg-yellow',
					extndWarnText: 'outreach-start-warn-text',
					extndWarnIcon: 'outreach-hold-warn-icon',
					testNames: msgObj.testNames,
				};
			case OUTREACH_INPROGRESS_WARN_MSG:
				return {
					warningMsg: renderMultipleOutreachWarnMsg(
						'The following test(s) will be added to the RoR Outreach InProgress:',
						msgObj.testNames
					),
					warningIcon: timerIcon,
					boxBGColor: 'bg-yellow',
					extndWarnText: 'outreach-start-warn-text',
					extndWarnIcon: 'outreach-hold-warn-icon',
					testNames: msgObj.testNames,
				};
			default:
				return false;
		}
		return false;
	};

	const isWarnMsgChangeFunc = () => {
		const oldMsgObj = OutreachWarnMsgFunc(false);
		const newMsgObj = OutreachWarnMsgFunc();
		return _.get(oldMsgObj, 'warnMsg') != _.get(newMsgObj, 'warnMsg');
	};

	const showHoldResultWarnMsg = () => {
		const holdResultWarnMsg = setHoldResultOutreachWarnMsg();
		const isWarnMsgChange = isWarnMsgChangeFunc();

		if (
			showWarnMsg &&
			isWarnMsgChange &&
			holdResultWarnMsg &&
			_.get(holdResultWarnMsg, ['testNames', 'length'], 0) > 0
		)
			return true;
		return false;
	};

	const showResultEntryWarnMsg = () => {
		const { order_status, positive_results_to_report } = formdata;
		const statuses = {
			result_negative: 1,
			result_positive_no_genes: 1,
			result_positive_with_genes: 1,
			results_entered: 1,
			results_ready: 1,
		};
		const resultEntryWarnMsg = setResultEntryOutreachWarnMsg();
		const isWarnMsgChange = isWarnMsgChangeFunc();
		const isResultentredTest = order_status in statuses;

		if (
			(isWarnMsgChange || showWarnMsg) &&
			resultEntryWarnMsg &&
			isResultentredTest &&
			!_.isNil(positive_results_to_report)
		)
			return true;
		return false;
	};

	const filterLabOrderStatusEnums = (labOrderStatusEnum) => {
		const optionsToSkip = ['lab_order_in_progress'];

		return labOrderStatusEnum.filter((e) => !optionsToSkip.includes(e.key));
	};

	const filterMloOrderStatusEnums = () => {
		const mloorderstatus = mloOrderStatusEnums.map((e) => {
			if (_.get(e, ['children', '0'], null) != null) {
				let statusReason = e.children;
				if (e.key == 'cancelled') {
					statusReason = _.filter(e.children, function (o) {
						return o.key !== 'order_cancelled_by_partner';
					});
				} else if (e.key == 'patient_declined') {
					statusReason = _.filter(e.children, function (o) {
						return o.key !== 'declined_via_partner';
					});
				} else statusReason = e.children;

				return {
					key: e.key,
					display_name: e.display_name,
					children: statusReason,
				};
			} else {
				return e;
			}
		});
		return mloorderstatus;
	};

	const handleAddAnotherTest2 = (key, value) => {
		const _addAnotherTest2 = addAnotherTest2;
		_addAnotherTest2[key] = value;
		onAddAnotherTestNameFormChange(key, value);
		if ((key = 'addTest2' && value == 'yes')) {
			const testName = _.get(formdata, ['genetic_test_uuid'], '');
			const findTestDetail = _.find(
				nonlegacytestOrderPopupTestEnums || [],
				{ key: testName }
			);
			if (findTestDetail && findTestDetail.group) {
				const tempArr = [];
				const testName1GroupUUID = _.get(
					findTestDetail,
					['group', 'uuid'],
					''
				);
				_.forEach(nonlegacytestOrderPopupTestEnums, function (value) {
					if (
						_.get(value, ['group', 'uuid'], '') ===
							testName1GroupUUID &&
						value.key !== findTestDetail.key
					) {
						tempArr.push({
							key: value?.key,
							value: value?.key,
							label: value?.display_name,
						});
					}
				});
				_addAnotherTest2['enums'] = tempArr;
			}
		}
		setAddAnotherTest2((oldState) => ({
			...oldState,
			..._addAnotherTest2,
		}));
		if (addAnotherTest3.addTest3 !== 'yes') {
			setAddAnotherTest3((oldState) => ({
				...oldState,
				addTest3: 'no',
				test_name3: undefined,
				enums: [],
			}));
		} else {
			const _addAnotherTest3 = {};
			const testName = _.get(formdata, ['genetic_test_uuid'], '');
			const testName2Key = value;
			const findTestDetail = _.find(
				nonlegacytestOrderPopupTestEnums || [],
				{ key: testName }
			);
			if (findTestDetail && findTestDetail.group) {
				const tempArr = [];
				const testName1GroupUUID = _.get(
					findTestDetail,
					['group', 'uuid'],
					''
				);
				_.forEach(nonlegacytestOrderPopupTestEnums, function (value) {
					if (
						_.get(value, ['group', 'uuid'], '') ===
							testName1GroupUUID &&
						value.key !== findTestDetail.key &&
						value.key !== testName2Key
					) {
						tempArr.push({
							key: value?.key,
							value: value?.key,
							label: value?.display_name,
						});
					}
				});
				_addAnotherTest3['enums'] = tempArr;
			}

			setAddAnotherTest3((oldState) => ({
				...oldState,
				enums: _addAnotherTest3.enums,
			}));
		}
	};

	const handleAddAnotherTest3 = (key, value) => {
		const _addAnotherTest3 = addAnotherTest3;
		_addAnotherTest3[key] = value;
		onAddAnotherTestNameFormChange(key, value);
		if ((key = 'addTest3' && value == 'yes')) {
			const testName = _.get(formdata, ['genetic_test_uuid'], '');
			const testName2Key = _.get(addAnotherTest2, ['test_name2'], '');
			const testName1Detail = _.find(
				nonlegacytestOrderPopupTestEnums || [],
				{ key: testName }
			);
			if (testName1Detail && testName1Detail.group) {
				const tempArr = [];
				const testName1GroupUUID = _.get(
					testName1Detail,
					['group', 'uuid'],
					''
				);
				_.forEach(nonlegacytestOrderPopupTestEnums, function (value) {
					if (
						_.get(value, ['group', 'uuid'], '') ===
							testName1GroupUUID &&
						value.key !== testName1Detail.key &&
						value.key !== testName2Key
					) {
						tempArr.push({
							key: value?.key,
							value: value?.key,
							label: value?.display_name,
						});
					}
				});
				_addAnotherTest3['enums'] = tempArr;
			}
		} else if (addAnotherTest2.addTest2 === 'yes') {
			const _addAnotherTest2 = {};
			const testName = _.get(formdata, ['genetic_test_uuid'], '');
			const testName3Key = value;
			const findTestDetail = _.find(
				nonlegacytestOrderPopupTestEnums || [],
				{ key: testName }
			);
			if (findTestDetail && findTestDetail.group) {
				const tempArr = [];
				const testName1GroupUUID = _.get(
					findTestDetail,
					['group', 'uuid'],
					''
				);
				_.forEach(nonlegacytestOrderPopupTestEnums, function (value) {
					if (
						_.get(value, ['group', 'uuid'], '') ===
							testName1GroupUUID &&
						value.key !== findTestDetail.key &&
						value.key !== testName3Key
					) {
						tempArr.push({
							key: value?.key,
							value: value?.key,
							label: value?.display_name,
						});
					}
				});
				_addAnotherTest2['enums'] = tempArr;
			}

			setAddAnotherTest2((oldState) => ({
				...oldState,
				enums: _addAnotherTest2.enums,
			}));
		}

		setAddAnotherTest3((oldState) => ({
			...oldState,
			..._addAnotherTest3,
		}));
	};

	const handleReflexQuestionFieldChange = (key, value) => {
		const newReflexAnswers = {
			...reflexAOEAnswers,
			[key]: value,
		};

		for (const { name, depends_on, default_option } of reflexAOEQuestions) {
			if (
				!_.isEmpty(depends_on) &&
				depends_on?.name === key &&
				depends_on?.value !== value
			)
				newReflexAnswers[name] = '';
			else if (!_.isNil(default_option)) {
				newReflexAnswers[name] = default_option;
			}	
		}

		setReflexAnswers({ ...newReflexAnswers });
	};

	const isRequiredReflexQuestion = (required, dependsOn) => {
		if (required === 'yes') {
			return true;
		} else if (
			required === 'conditional' &&
			!_.isEmpty(dependsOn) &&
			_.get(reflexAOEAnswers, [dependsOn.name], null) === dependsOn.value
		) {
			return true;
		} else if (required === 'no') {
			return false;
		}
		return false;
	};

	const renderReflexQuestionField = ({
		default_option = null,
		display_text,
		name,
		type,
		is_required,
		options,
		depends_on,
	}) => {
		const isEditable =
			testorder?.is_questionnaire_editable ||
			/* if test name has change and new test name is for lab integration */
			(!formdata.skip_lab_integration &&
				!_.isNil(testorder.genetic_test_uuid) &&
				!_.isNil(formdata.genetic_test_uuid) &&
				testorder.genetic_test_uuid !== formdata.genetic_test_uuid);

		const isDisabled =
			!isEditable ||
			(!_.isEmpty(depends_on) &&
				_.get(reflexAOEAnswers, [depends_on.name], null) !==
					depends_on.value);

		if (type === 'enum' && !_.isEmpty(options)) {
			return (
				<>
					<span
						className={classnames({
							required: isRequiredReflexQuestion(
								is_required,
								depends_on
							),
						})}
					>
						{display_text}
					</span>
					<Select
						name={name}
						value={_.get(reflexAOEAnswers, [name], default_option)}
						disabled={isDisabled}
						onChange={(value) =>
							handleReflexQuestionFieldChange(name, value)
						}
					>
						{options.map((option) => (
							<Select.Option
								key={option}
								label={option}
								value={option}
							/>
						))}
					</Select>
				</>
			);
		} else if (DateTypes.includes(type)) {
			return (
				<>
					<span
						className={classnames({
							required: isRequiredReflexQuestion(
								is_required,
								depends_on
							),
						})}
					>
						{display_text}
					</span>
					<Datetime
						value={
							moment(
								_.get(reflexAOEAnswers, [name], default_option)
							).isValid()
								? moment(
										_.get(reflexAOEAnswers, [name], default_option)
								  ).toDate()
								: ''
						}
						dateFormat={'MM/DD/YYYY'}
						timeFormat={false}
						isDisabled={isDisabled}
						onChange={(value) =>
							handleReflexQuestionFieldChange(name, value)
						}
						isValidDate={(current) => {
							const today = moment();
							if (type === 'date_future') {
								return current.isSameOrAfter(today, 'day');
							} else if (type === 'date_past') {
								return current.isSameOrBefore(today, 'day');
							}
						}}
						className='testrequestdatefield'
						inputProps={{
							placeholder: 'MM/DD/YYYY',
						}}
						strictParsing={true}
						closeOnTab={true}
						closeOnSelect={true}
					/>
				</>
			);
		} else if (type === 'text') {
			return (
				<>
					<span
						className={classnames({
							required: isRequiredReflexQuestion(
								is_required,
								depends_on
							),
						})}
					>
						{display_text}
					</span>
					<Input
						name={name}
						value={_.get(reflexAOEAnswers, [name], default_option)}
						placeholder={display_text}
						trim={true}
						disabled={isDisabled}
						onChange={(value) =>
							handleReflexQuestionFieldChange(name, value)
						}
						maxLength={255}
					/>
				</>
			);
		}

		return null;
	};

	const medicalCodes =
		userSelectedICDCodes || _.get(testorder, 'medical_codes', []) || [];
	const sampleTypes = getSampleTypeGrouping(optionsSampleTypesEnum);
	const labName = _.get(testorder, ['lab'], '');
	const testName = _.get(testorder, ['test_name'], '');
	const resultEntryWarnMsg = setResultEntryOutreachWarnMsg();
	const holdResultWarnMsg = setHoldResultOutreachWarnMsg();
	const geneticTestUuid = _.get(formdata, 'genetic_test_uuid');
	const stateorderstatus = _.get(formdata, ['order_status'], {});
	const dropdownQuestionnaire = determineDropdownQuestionnaire(
		geneticTestUuid,
		nonlegacytestOrderPopupTestEnums
	);
	const showShippingAddress = determineShippingAddressRequired(
		geneticTestUuid,
		nonlegacytestOrderPopupTestEnums,
		stateorderstatus
	);
	const labIntegrationEnabled = _.find(
		nonlegacytestOrderPopupTestEnums || [],
		{ key: geneticTestUuid }
	)?.lab_integration_enabled;
	const testName1group =
		_.find(nonlegacytestOrderPopupTestEnums || [], {
			key: formdata?.genetic_test_uuid,
		})?.group || {};
	const testNameGroup = _.filter(
		nonlegacytestOrderPopupTestEnums || [],
		function (to) {
			return (
				!_.isEmpty(to.group.uuid) &&
				!_.isNil(to.group.uuid) &&
				testName1group?.uuid === to.group.uuid
			);
		}
	);

	const changeTab = (value) => {
		const enctype = 0;
		const params = new URLSearchParams(
			_.get(props, ['location', 'search'], '')
		);
		const order_by = params.get('order_by');
		const order_type = params.get('order_type');
		const offset = params.get('offset');
		props.history.push({
			pathname:
				'/app/patientdetail/' +
				_.get(props, 'match.params.patientid', 0) +
				'/' +
				value +
				'/' +
				enctype,
			search:
				'?offset=' +
				(offset || 0) +
				'&order_by=' +
				(order_by || '') +
				'&order_type=' +
				(order_type || 'DESC'),
			state: _.get(props, 'location.state', {}),
		});
	};

	return (
		<Fragment>
			<section className='create-test-rqst-labinfo-container edit-test-rqst-labinfo-container-v2'>
				<h2 className='module-header'>Test Info</h2>

				{currentForm(testorder) == TESTREQUEST ? (
					<Fragment>
						<Layout.Row>
							<Layout.Col span='24' className='required'>
								Lab
							</Layout.Col>
							<Layout.Col span='24'>
								<ErrorBoundary>
									<Select
										value={_.get(
											formdata,
											['lab_uuid'],
											''
										)}
										placeholder='Select'
										onChange={(value) =>
											onFormChangeFunc('lab_uuid', value)
										}
										disabled={isLabDisabled()}
										clearable={true}
										filterable={true}
										className='smaller-dropdown'
										filterMethod={(value) => {
											if (!isLabDisabled()) {
												if (
													_.isEmpty(value) ||
													_.isNil(value)
												) {
													setOptionsLabEnum(labenums);
												} else {
													const _options =
														labenums.filter(
															(o) =>
																o.display_name &&
																o.display_name
																	.toLowerCase()
																	.startsWith(
																		value.toLowerCase()
																	)
														);
													setOptionsLabEnum(_options);
												}
											}
										}}
									>
										{optionsLabEnum.map((el) => {
											return (
												<Select.Option
													label={
														el.display_name || ' '
													}
													value={el.key}
													className='select-lab'
													key={el.key}
												>
													<span className='lab-name'>
														{el.display_name}
													</span>
													{el.paper_form_only ==
														true && (
														<span className='lab-paper-trf'>
															Paper TRF
														</span>
													)}
												</Select.Option>
											);
										})}
									</Select>
								</ErrorBoundary>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row className='test-name'>
							<Layout.Col span='24' className='required'>
								{!formdata?.skip_lab_integration &&
								labIntegrationEnabled &&
								testNameGroup.length > 1
									? 'Test Name (1)'
									: 'Test Name'}
							</Layout.Col>
							<Layout.Col span='24'>
								<ErrorBoundary>
									<Select
										value={_.get(
											formdata,
											['genetic_test_uuid'],
											''
										)}
										placeholder='Select'
										disabled={isTestNameDisabled()}
										onChange={(value) =>
											onFormChangeFunc(
												'genetic_test_uuid',
												value
											)
										}
										clearable={true}
										filterable={true}
										className='smaller-dropdown'
										filterMethod={(value) => {
											if (!isTestNameDisabled()) {
												const _testNameEnum =
													createEnumsOptions(
														props,
														[
															'nonlegacytestOrderPopupTestEnums',
														],
														'key',
														'display_name',
														'key'
													);
												if (
													_.isEmpty(value) ||
													_.isNil(value)
												) {
													setOptionsTestNameEnum(
														_testNameEnum
													);
												} else {
													const _options =
														_testNameEnum.filter(
															(o) =>
																o.label &&
																o.label
																	.toLowerCase()
																	.startsWith(
																		value.toLowerCase()
																	)
														);
													setOptionsTestNameEnum(
														_options
													);
												}
											}
										}}
									>
										{optionsTestNameEnum.map((el) => {
											return (
												<Select.Option
													className='mlo-test-name-list'
													key={el.value}
													label={el.label}
													value={el.value}
												/>
											);
										})}
									</Select>
								</ErrorBoundary>
							</Layout.Col>
						</Layout.Row>

						{_.get(formdata, ['genetic_test_uuid'], '') &&
							!_.isEmpty(nonlegacytestOrderPopupTestEnums) &&
							labIntegrationEnabled && (
								<Layout.Row className='row-checkbox-order-test-manually'>
									<Layout.Col span='24'>
										<Checkbox
											checked={
												formdata?.skip_lab_integration
											}
											onChange={(value) => {
												onFormChangeFunc(
													'skip_lab_integration',
													value
												);
											}}
											className='chk-order-test-manually'
										>
											Order test(s) manually
										</Checkbox>
									</Layout.Col>
								</Layout.Row>
							)}

						{_.get(formdata, ['genetic_test_uuid'], '') &&
							!_.isEmpty(nonlegacytestOrderPopupTestEnums) &&
							!labIntegrationEnabled && (
								<Layout.Row className='integration-not-supported'>
									<Layout.Col span='24'>
										<div className='milestone-error-container'>
											<div className='milestone-error-body'>
												<div className='milestone-error-icon'>
													<ReactSVG
														src={warningImg}
													/>
												</div>
												<div className='milestone-error-text'>
													Please place this order
													manually. This test cannot
													be ordered through EMR
													integration.
												</div>
											</div>
										</div>
									</Layout.Col>
								</Layout.Row>
							)}

						{labIntegrationEnabled &&
							!_.isEmpty(testName1group) &&
							testNameGroup.length > 1 &&
							!formdata?.skip_lab_integration && (
								<Fragment>
									<Layout.Row className='add-another-test'>
										<Layout.Col
											span='24'
											className='required'
										>
											Add another simultaneous test?
										</Layout.Col>
										<Layout.Col span='24'>
											<Radio.Group
												className='gmi-radio-group add-another-test-radio'
												value={addAnotherTest2.addTest2}
												onChange={(value) =>
													handleAddAnotherTest2(
														'addTest2',
														value
													)
												}
											>
												<Radio value={'no'}>No</Radio>
												<Radio value={'yes'}>Yes</Radio>
											</Radio.Group>
										</Layout.Col>
									</Layout.Row>
									{addAnotherTest2.addTest2 === 'yes' && (
										<Fragment>
											<Layout.Row>
												<Layout.Col
													span='24'
													className='required'
												>
													Test Name (2)
												</Layout.Col>
												<Layout.Col span='24'>
													<ErrorBoundary>
														<Select
															name='anotherTestName'
															value={
																addAnotherTest2.test_name2
															}
															placeholder='Select'
															onChange={(value) =>
																handleAddAnotherTest2(
																	'test_name2',
																	value
																)
															}
															className='smaller-dropdown'
															clearable={true}
															filterable={true}
														>
															{addAnotherTest2.enums.map(
																(el) => {
																	return (
																		<Select.Option
																			className='mlo-test-name-list'
																			key={
																				el.value
																			}
																			label={
																				el.label
																			}
																			value={
																				el.value
																			}
																		/>
																	);
																}
															)}
														</Select>
													</ErrorBoundary>
												</Layout.Col>
											</Layout.Row>
										</Fragment>
									)}

									{labIntegrationEnabled &&
										!_.isEmpty(testName1group) &&
										testNameGroup.length > 2 &&
										!formdata?.skip_lab_integration &&
										(addAnotherTest2?.addTest2 === 'yes' ||
											addAnotherTest3.addTest3 ===
												'yes') && (
											<Layout.Row className='add-another-test'>
												<Layout.Col
													span='24'
													className='required'
												>
													Add another simultaneous test?
												</Layout.Col>
												<Layout.Col span='24'>
													<Radio.Group
														className='gmi-radio-group add-another-test-radio'
														value={
															addAnotherTest3.addTest3
														}
														onChange={(value) =>
															handleAddAnotherTest3(
																'addTest3',
																value
															)
														}
													>
														<Radio value={'no'}>
															No
														</Radio>
														<Radio value={'yes'}>
															Yes
														</Radio>
													</Radio.Group>
												</Layout.Col>
											</Layout.Row>
										)}
									{addAnotherTest3.addTest3 === 'yes' && (
										<Layout.Row>
											<Layout.Col
												span='24'
												className='required'
											>
												Test Name (3)
											</Layout.Col>
											<Layout.Col span='24'>
												<ErrorBoundary>
													<Select
														name='test_name3'
														value={
															addAnotherTest3.test_name3
														}
														placeholder='Select'
														onChange={(value) =>
															handleAddAnotherTest3(
																'test_name3',
																value
															)
														}
														className='smaller-dropdown'
														clearable={true}
														filterable={true}
													>
														{addAnotherTest3.enums.map(
															(el) => {
																return (
																	<Select.Option
																		className='mlo-test-name-list'
																		key={
																			el.value
																		}
																		label={
																			el.label
																		}
																		value={
																			el.value
																		}
																	/>
																);
															}
														)}
													</Select>
												</ErrorBoundary>
											</Layout.Col>
										</Layout.Row>
									)}
								</Fragment>
							)}

						{!formdata?.skip_lab_integration && !_.isEmpty(reflexAOEQuestions) && _.isArray(reflexAOEQuestions) &&
							reflexAOEQuestions.map((reflexQuestion) => {								
								return (
									<Layout.Row key={reflexQuestion.name}>
										<Layout.Col span='24'>
											{renderReflexQuestionField(reflexQuestion)}
										</Layout.Col>
									</Layout.Row>
								);
							})
						}

						<Layout.Row className='order-request-status'>
							<Layout.Col span='24' className='required'>
								Order Request Status
							</Layout.Col>
							<Layout.Col span='24'>
								<Cascader
									name={'order_status'}
									value={findCascaderHierarchy(
										mloOrderStatusEnums,
										'key',
										_.get(formdata, ['order_status'], '')
									)}
									onChange={(value) =>
										onFormChangeFunc('order_status', value)
									}
									showAllLevels={false}
									disabled={isOrderStatusDisabled()}
									expandTrigger='hover'
									options={CascaderOptions(
										filterOrderStatus(
											!isOrderStatusDisabled()
												? filterMloOrderStatusEnums()
												: mloOrderStatusEnums,
											_.get(
												testorder,
												['order_status'],
												null
											),
											_.get(
												testorder,
												['date_test_ordered'],
												null
											),
											_.get(
												testorder,
												['status_reason'],
												''
											),
											_.get(testorder, ['history'], [])
										),
										'key',
										'display_name',
										'key'
									)}
									className='cascade-edit-test-order'
								/>
								{renderTestOrderMilestoneWarning()}
							</Layout.Col>
						</Layout.Row>

						{dropdownQuestionnaire && (
							<Layout.Row>
								<Layout.Col span='24' className='required'>
									Patient Details Requested by Lab (check all
									that apply)
								</Layout.Col>
								<Layout.Col span='24'>
									<Select
										name='order_questionnaire'
										value={_.get(
											formdata,
											['order_questionnaire'],
											''
										)}
										placeholder='Select'
										onChange={(value) =>
											onFormChangeFunc(
												'order_questionnaire',
												value
											)
										}
										multiple={true}
										className='test-request-multiselect'
										filterable={true}
										clearable={true}
									>
										{dropdownQuestionnaire.map((el) => {
											return (
												<Select.Option
													className='mlo-test-name-list'
													key={el.option}
													label={el.display_text}
													value={el.option}
												/>
											);
										})}
									</Select>
								</Layout.Col>
							</Layout.Row>
						)}

						<Layout.Row gutter='16'>
							<Layout.Col
								span='12'
								className={
									isOrderingPhysicianRequired()
										? 'required'
										: ''
								}
							>
								<span>Ordering physician</span>
								<Select
									name='ordering_physician'
									value={_.get(
										formdata,
										['ordering_physician'],
										''
									)}
									onChange={(value) =>
										onFormChangeFunc(
											'ordering_physician',
											value
										)
									}
									disabled={
										_.get(
											testorder,
											['order_request_status'],
											null
										) in OrderRequestFinalState ||
										isOrderingPhysicanDisabled()
									}
									placeholder='Select'
									clearable={true}
									filterable={true}
									filterMethod={(value) => {
										if (!isOrderingPhysicanDisabled()) {
											const _orderingPhysicianEnum =
												createEnumsOptions(
													props,
													['ordering_physicianenums'],
													'key',
													'display_name',
													'key'
												);
											if (
												_.isEmpty(value) ||
												_.isNil(value)
											) {
												setOptionsOrderingPhysicianEnum(
													_orderingPhysicianEnum
												);
											} else {
												const _options =
													_orderingPhysicianEnum.filter(
														(o) =>
															o.label &&
															o.label
																.toLowerCase()
																.startsWith(
																	value.toLowerCase()
																)
													);
												setOptionsOrderingPhysicianEnum(
													_options
												);
											}
										}
									}}
								>
									{optionsOrderingPhysicianEnum.map(
										(el, i) => {
											return (
												<Select.Option
													key={el.value}
													label={el.label}
													value={el.value}
												/>
											);
										}
									)}
								</Select>
							</Layout.Col>
							<Layout.Col
								span='12'
								className={
									isTestAuthorizeDateRequired()
										? 'required'
										: ''
								}
							>
								<span>Test Authorization Date</span>
								<EncounterDateField
									className={
										'testrequestdatefield calendar-pull-left calendar-pull-up'
									}
									name='date_test_authorized'
									placeholder='MM/DD/YYYY'
									disabled={isTestAuthorizeDateDisabled()}
									value={_.get(
										formdata,
										['date_test_authorized'],
										''
									)}
									onChange={(value) =>
										onFormChangeFunc(
											'date_test_authorized',
											value
										)
									}
									onBlur={(value) =>
										onHandleDateFieldValidate(
											'date_test_authorized',
											value
										)
									}
									disabledDate={(current) => {
										const today = moment();
										const _requestedDate =
											getOrderRequestedDate();

										if (!_.isNil(_requestedDate)) {
											return (
												current.isSameOrBefore(
													today,
													'day'
												) &&
												current.isSameOrAfter(
													_requestedDate,
													'day'
												)
											);
										} else {
											return current.isSameOrBefore(
												today,
												'day'
											);
										}
									}}
									makeCalendarOnly={(event) => {
										if (
											event.keyCode == 8 ||
											event.keyCode == 46
										) {
											return true;
										} else {
											event.preventDefault();
										}
									}}
								/>
							</Layout.Col>
						</Layout.Row>

						{medicalCodes.length > 0 && (
							<Layout.Row className='icd-10-btn-wrapper'>
								<Layout.Col span='24'>
									ICD-10 Code(s) (Test Request)
								</Layout.Col>
								<Layout.Col
									span='24'
									className='icd-10-btn-container'
								>
									{renderICDCodes()}
								</Layout.Col>
							</Layout.Row>
						)}						

						{showHoldResultWarnMsg() && (
							<WarningMessageBox {...holdResultWarnMsg} />
						)}

						<h2 className='module-header'>Sample Info</h2>

						<Layout.Row gutter='16'>
							<Layout.Col span='12' className='required'>
								<span>Sample Type (Preferred)</span>
								<Select
									name={'sample_type'}
									value={_.get(formdata, ['sample_type'], '')}
									onChange={(value) =>
										onFormChangeFunc('sample_type', value)
									}
									disabled={isSampleTypeDisabled()}
									placeholder='Select'
									clearable={true}
									filterable={true}
									filterMethod={(value) => {
										const _optionSampleTypeEnum =
											createEnumsOptions(
												props,
												['sampletypeenums'],
												'key',
												'display_name',
												'key'
											);
										if (
											_.isEmpty(value) ||
											_.isNil(value)
										) {
											setOptionsSampleTypesEnum(
												_optionSampleTypeEnum
											);
										} else {
											const _options =
												_optionSampleTypeEnum.filter(
													(o) =>
														o.label &&
														o.label
															.toLowerCase()
															.startsWith(
																value.toLowerCase()
															)
												);
											setOptionsSampleTypesEnum(_options);
										}
									}}
								>
									{_.get(sampleTypes, 'non-others', []).map(
										(el) => {
											return (
												<Select.Option
													key={el.value}
													label={el.label}
													value={el.value}
												/>
											);
										}
									)}
									{_.get(sampleTypes, 'others', false) && (
										<Select.OptionGroup
											key={'other'}
											label='OTHER'
											className='sample-type-other'
										>
											{_.get(
												sampleTypes,
												'others',
												[]
											).map((el) => {
												return (
													<Select.Option
														key={el.value}
														label={el.label}
														value={el.value}
													/>
												);
											})}
										</Select.OptionGroup>
									)}
								</Select>
							</Layout.Col>
						</Layout.Row>

						<h2 className='module-header'>Billing Info</h2>

						<Layout.Row gutter='16'>
							<Layout.Col span='12' className='required'>
								<span>Test Billing Method</span>
								<Select
									name='patient_billing_method'
									value={_.get(
										formdata,
										['patient_billing_method'],
										false
									)}
									placeholder='Select'
									onChange={(value) =>
										onFormChangeFunc(
											'patient_billing_method',
											value
										)
									}
									clearable={true}
									filterable={true}
									filterMethod={(value) => {
										const _billingMethodEnum =
											createEnumsOptions(
												props,
												['patientbillingmethodenums'],
												'key',
												'display_name',
												'key'
											);
										if (
											_.isEmpty(value) ||
											_.isNil(value)
										) {
											setOptionsBillingMethodEnum(
												_billingMethodEnum
											);
										} else {
											const _options =
												_billingMethodEnum.filter(
													(o) =>
														o.label &&
														o.label
															.toLowerCase()
															.startsWith(
																value.toLowerCase()
															)
												);
											setOptionsBillingMethodEnum(
												_options
											);
										}
									}}
								>
									{optionsBillingMethodEnum.map((el) => {
										return (
											<Select.Option
												key={el.value}
												label={el.label}
												value={el.value}
											/>
										);
									})}
								</Select>
							</Layout.Col>
						</Layout.Row>

						{hasPatientNoInsurance() && (
							<>
								<Layout.Row gutter='16'>
									<Layout.Col span='24'>
										<div className='noinsunrace-info-container'>
											<div className='noinsunrace-info-body'>
												<div className='milestone-warning-icon'>
													<ReactSVG
														src={warningImg}
													/>
												</div>
												<div className='noinsunrace-info-text'>
													This patient does not have
													any insurance info on file.
													Click the button below to
													add insurance.
												</div>
											</div>
										</div>
									</Layout.Col>
								</Layout.Row>

								<Layout.Row gutter='16'>
									<Layout.Col span='24'>
										<Button
											className='add-insuranceinfo-button'
											onClick={() => {
												hideModal();
												changeTab(5);
											}}
										>
											<ReactSVG
												className='add-icon'
												src={add}
											/>
											<span>Add Insurance Info</span>
										</Button>
									</Layout.Col>
								</Layout.Row>
							</>
						)}

						{showShippingAddress && (
							<Fragment>
								<h2 className='module-header'>
									Shipping Address
								</h2>

								<Layout.Row>
									<Layout.Col span='24' className='required'>
										<span>Address Line 1</span>
										<Input
											name='patient_address_line1'
											value={_.get(
												shippingAddressFormData,
												['patient_address_line1'],
												''
											)}
											placeholder='Address Line 1'
											onChange={(value) =>
												onShippingAddressFormChangeFunc(
													'patient_address_line1',
													value
												)
											}
											trim={true}
										/>
									</Layout.Col>
								</Layout.Row>

								<Layout.Row gutter='16'>
									<Layout.Col span='8'>
										<span>Address Line 2</span>
										<Input
											name='patient_address_line2'
											value={_.get(
												shippingAddressFormData,
												['patient_address_line2'],
												''
											)}
											placeholder='Address Line 2'
											onChange={(value) =>
												onShippingAddressFormChangeFunc(
													'patient_address_line2',
													value
												)
											}
											trim={true}
										/>
									</Layout.Col>
									<Layout.Col span='16' className='required'>
										<span>City</span>
										<Input
											name='patient_shipping_address_city'
											value={_.get(
												shippingAddressFormData,
												[
													'patient_shipping_address_city',
												],
												''
											)}
											placeholder='Address'
											onChange={(value) =>
												onShippingAddressFormChangeFunc(
													'patient_shipping_address_city',
													value
												)
											}
											trim={true}
										/>
									</Layout.Col>
								</Layout.Row>
								<Layout.Row gutter='16'>
									<Layout.Col span='6' className='required'>
										<span>State</span>
										<Select
											name='patient_shipping_address_state'
											value={_.get(
												shippingAddressFormData,
												[
													'patient_shipping_address_state',
												],
												''
											)}
											onChange={(value) =>
												onShippingAddressFormChangeFunc(
													'patient_shipping_address_state',
													value
												)
											}
											placeholder='--'
											clearable={true}
											filterable={true}
											filterMethod={(val) => {
												const _states =
													createStateEnumsOptions(
														states_list,
														'key',
														'value',
														'key'
													);
												if (
													_.isEmpty(val) ||
													_.isNil(val)
												) {
													setOptionsStatesEnum(
														_states
													);
												} else {
													const _options =
														_states.filter(
															(o) =>
																o.label
																	.toString()
																	.toLowerCase()
																	.startsWith(
																		val.toLowerCase()
																	) ||
																o.key
																	.toLowerCase()
																	.startsWith(
																		val.toLowerCase()
																	)
														);
													setOptionsStatesEnum(
														_options
													);
												}
											}}
										>
											{optionsStatesEnum.map((el, i) => (
												<Select.Option
													key={el.key}
													label={el.value}
													value={el.value}
												>
													{el.label}
												</Select.Option>
											))}
										</Select>
									</Layout.Col>
									<Layout.Col span='10' className='required'>
										<span>Zip Code</span>
										<Input
											name='patient_shipping_address_zipcode`'
											value={_.get(
												shippingAddressFormData,
												[
													'patient_shipping_address_zipcode',
												],
												''
											)}
											placeholder='Zip Code'
											onChange={(value) =>
												onShippingAddressFormChangeFunc(
													'patient_shipping_address_zipcode',
													value
												)
											}
											trim={true}
										/>
									</Layout.Col>
								</Layout.Row>
							</Fragment>
						)}
					</Fragment>
				) : (
					<Fragment>
						{(_.includes(_.toLower(labName), 'other') ||
							_.includes(_.toLower(testName), 'other')) && (
							<Layout.Row>
								<Layout.Col span='24' className='required'>
									Lab
								</Layout.Col>
								<Layout.Col span='24'>
									<ErrorBoundary>
										<Select
											value={_.get(
												formdata,
												['lab_uuid'],
												''
											)}
											placeholder='Select'
											onChange={(value) =>
												onFormChangeFunc(
													'lab_uuid',
													value
												)
											}
											clearable={true}
											filterable={true}
											filterMethod={(value) => {
												if (
													_.isEmpty(value) ||
													_.isNil(value)
												) {
													setOptionsLabEnum(labenums);
												} else {
													const _options =
														labenums.filter(
															(o) =>
																o.display_name &&
																o.display_name
																	.toLowerCase()
																	.startsWith(
																		value.toLowerCase()
																	)
														);
													setOptionsLabEnum(_options);
												}
											}}
										>
											{optionsLabEnum.map((el) => {
												return (
													<Select.Option
														label={
															el.display_name ||
															' '
														}
														value={el.key}
														className='select-lab'
														key={el.key}
													>
														<span className='lab-name'>
															{el.display_name}
														</span>
														{el.paper_form_only ==
															true && (
															<span className='lab-paper-trf'>
																Paper TRF
															</span>
														)}
													</Select.Option>
												);
											})}
										</Select>
									</ErrorBoundary>
								</Layout.Col>
							</Layout.Row>
						)}

						{(_.includes(_.toLower(labName), 'other') ||
							_.includes(_.toLower(testName), 'other')) && (
							<Layout.Row>
								<Layout.Col span='24' className='required'>
									Test Name
								</Layout.Col>
								<Layout.Col span='24'>
									<ErrorBoundary>
										<Select
											value={_.get(
												formdata,
												['genetic_test_uuid'],
												''
											)}
											placeholder='Select'
											onChange={(value) =>
												onFormChangeFunc(
													'genetic_test_uuid',
													value
												)
											}
											clearable={true}
											filterable={true}
											filterMethod={(value) => {
												const _testNameEnum =
													createEnumsOptions(
														props,
														[
															'nonlegacytestOrderPopupTestEnums',
														],
														'key',
														'display_name',
														'key'
													);
												if (
													_.isEmpty(value) ||
													_.isNil(value)
												) {
													setOptionsTestNameEnum(
														_testNameEnum
													);
												} else {
													const _options =
														_testNameEnum.filter(
															(o) =>
																o.label &&
																o.label
																	.toLowerCase()
																	.startsWith(
																		value.toLowerCase()
																	)
														);
													setOptionsTestNameEnum(
														_options
													);
												}
											}}
										>
											{optionsTestNameEnum.map((el) => {
												return (
													<Select.Option
														className='mlo-test-name-list'
														key={el.value}
														label={el.label}
														value={el.value}
													/>
												);
											})}
										</Select>
									</ErrorBoundary>
								</Layout.Col>
							</Layout.Row>
						)}

						{_.get(formdata, ['genetic_test_uuid'], '') &&
							!_.isEmpty(nonlegacytestOrderPopupTestEnums) &&
							labIntegrationEnabled &&
							_.get(testorder, ['genetic_test_uuid'], '') !==
								_.get(formdata, ['genetic_test_uuid'], '') &&
							!_.isEmpty(
								_.get(testorder, ['date_test_ordered'], '')
							) &&
							_.includes(
								_.toLower(testorder?.test_name),
								'other'
							) && (
								<Layout.Row className='row-checkbox-order-test-manually aaaaaaaa'>
									<Layout.Col span='24'>
										<Checkbox
											checked={
												formdata?.skip_lab_integration
											}
											onChange={(value) => {
												onFormChangeFunc(
													'skip_lab_integration',
													value
												);
											}}
											className='chk-order-test-manually'
											disabled={true}
										>
											Order test(s) manually
										</Checkbox>
									</Layout.Col>
								</Layout.Row>
							)}

						<Layout.Row>
							<Layout.Col span='24' className='required'>
								Test Order Status
							</Layout.Col>
							<Layout.Col span='24'>
								<Cascader
									name={'order_status'}
									value={findCascaderHierarchy(
										mloOrderStatusEnums,
										'key',
										_.get(formdata, ['order_status'], '')
									)}
									onChange={(value) =>
										onFormChangeFunc('order_status', value)
									}
									showAllLevels={false}
									expandTrigger='hover'
									options={CascaderOptions(
										filterOrderStatus(
											!isOrderStatusDisabled()
												? filterMloOrderStatusEnums()
												: mloOrderStatusEnums,
											_.get(
												testorder,
												['order_status'],
												null
											),
											_.get(
												testorder,
												['date_test_ordered'],
												null
											),
											_.get(
												testorder,
												['status_reason'],
												''
											),
											_.get(testorder, ['history'], [])
										),
										'key',
										'display_name',
										'key'
									)}
									disabled={isOrderStatusDisabled()}
									className='cascade-edit-test-order'
								/>
								{_.get(formdata, ['order_status'], '') !==
									'results_ready' &&
									renderTestOrderMilestoneWarning()}
							</Layout.Col>
						</Layout.Row>

						{!formdata?.skip_lab_integration &&
							!_.isEmpty(reflexAOEQuestions) &&
							_.isArray(reflexAOEQuestions) &&
							reflexAOEQuestions.map((reflexQuestion) => {
								return (
									<Layout.Row key={reflexQuestion.name}>
										<Layout.Col span='24'>
											{renderReflexQuestionField(
												reflexQuestion
											)}
										</Layout.Col>
									</Layout.Row>
								);
							})}

						{_.get(testorder, ['order_status'], '') ===
							'sent_to_lab' &&
							_.get(formdata, ['order_status'], '') ===
								'sent_to_lab' && (
								<Layout.Row gutter='16'>
									<Layout.Col span='24'>
										External Lab Status
									</Layout.Col>
									<Layout.Col span='24'>
										<Select
											name={'lab_order_status'}
											value={_.get(
												formdata,
												['lab_order_status'],
												''
											)}
											onChange={(value) =>
												onFormChangeFunc(
													'lab_order_status',
													value
												)
											}
											disabled={isExternalLabStatusDisabled()}
											placeholder='Select'
											clearable={
												!isExternalLabStatusDisabled()
											}
											filterable={
												!isExternalLabStatusDisabled()
											}
											filterMethod={(value) => {
												if (
													!isExternalLabStatusDisabled()
												) {
													const _labOrderStatusEnum =
														filterLabOrderStatusEnums(
															laborderstatusenums
														);
													if (
														_.isEmpty(value) ||
														_.isNil(value)
													) {
														setOptionsLabOrderStatusEnum(
															_labOrderStatusEnum
														);
													} else {
														const _options =
															_labOrderStatusEnum.filter(
																(o) =>
																	o.display_name &&
																	o.display_name
																		.toLowerCase()
																		.startsWith(
																			value.toLowerCase()
																		)
															);
														setOptionsLabOrderStatusEnum(
															_options
														);
													}
												}
											}}
										>
											{filterLabOrderStatusEnums(
												optionsLabOrderStatusEnum
											).map((el) => {
												return (
													<Select.Option
														key={el.key}
														label={el.display_name}
														value={el.key}
													/>
												);
											})}
										</Select>
									</Layout.Col>
								</Layout.Row>
							)}

						{_.get(formdata, ['order_status'], '') ===
							'results_ready' && (
							<Layout.Row>
								<Layout.Col
									span='24'
									className='required result-selection-label'
								>
									Are there any findings to report?
									<Popover
										width='250'
										trigger='hover'
										popperClass={classnames(
											'pp-finding-result'
										)}
										content="If the genetic testing report contains no findings or is considered 'normal' or 'negative' overall, please select 'No findings to report'. If there are individual findings to report, even those considered 'negative,' please select 'At least one finding to report.'"
										key='popover-finding-result'
									>
										<span className='tooltip-info'>
											<img src={moreinfoicon} />
										</span>
									</Popover>
								</Layout.Col>
								<Layout.Col
									span='24'
									className='result-select-radio-button-wrapper'
								>
									<Radio.Group
										value={_.get(
											formdata,
											['positive_results_to_report'],
											null
										)}
										onChange={(value) =>
											onFormChangeFunc(
												'positive_results_to_report',
												value
											)
										}
									>
										<Radio
											value={true}
											className={
												isSelectedPositiveResult
													? 'radio-is-checked'
													: ''
											}
										>
											At least one finding to report
										</Radio>
										<Radio
											value={false}
											className={
												!_.isNil(
													isSelectedPositiveResult
												) && !isSelectedPositiveResult
													? 'radio-is-checked'
													: ''
											}
										>
											No findings to report
										</Radio>
									</Radio.Group>
								</Layout.Col>

								{_.get(formdata, ['order_status'], '') ===
									'results_ready' && (
									<Fragment>
										<Layout.Col
											span='24'
											className='required result-actionable-label'
										>
											Are the results Actionable?
											<Popover
												width='290'
												trigger='hover'
												popperClass={classnames(
													'pp-result-actionable'
												)}
												content={
													<Fragment>
														If there are findings,
														only change to no if the
														result is uncertain,
														benign, or variant of
														uncertain significance.
														If there are no findings
														to report, only change
														to yes if GC has
														requested outreach. If
														you have any questions
														at all, please reach out
														to the counselor of the
														week (COW) in the COW
														slack channel.&nbsp;
														<a
															href='https://docs.google.com/document/d/1Hcutji1fkM_JcFyIBzsV9Ble7utY-9w6nslJ6OOT4ic/edit'
															target='_blank'
															className='more-details'
															rel='noreferrer'
														>
															More details
														</a>
													</Fragment>
												}
												key='popover-actioable'
											>
												<span className='tooltip-info'>
													<img src={moreinfoicon} />
												</span>
											</Popover>
										</Layout.Col>
										<Layout.Col
											span='10'
											className='result-actionable-yesno-button-wrapper'
										>
											<div
												className={classnames(
													'yes-button result-actionable-yesno-button',
													!_.isNil(
														isResultActionable
													) && isResultActionable
														? 'yesno-button-selected'
														: 'yesno-button-unselected'
												)}
												onClick={() =>
													onResultActionChangeFunc(
														true
													)
												}
											>
												Yes
											</div>
											<div
												className={classnames(
													'result-actionable-yesno-button',
													!_.isNil(
														isResultActionable
													) && !isResultActionable
														? 'yesno-button-selected'
														: 'yesno-button-unselected'
												)}
												onClick={() =>
													onResultActionChangeFunc(
														false
													)
												}
											>
												No
											</div>
										</Layout.Col>
										<Layout.Col>
											{showResultEntryWarnMsg() && (
												<WarningMessageBox
													{...resultEntryWarnMsg}
												/>
											)}
											{_.get(
												formdata,
												['order_status'],
												''
											) === 'results_ready' &&
												renderTestOrderMilestoneWarning()}
										</Layout.Col>
									</Fragment>
								)}
							</Layout.Row>
						)}

						{showTestDispatchExpectedDate() && (
							<Layout.Row gutter='16'>
								<Layout.Col
									span='12'
									className={
										isTestDispatchDateRequired()
											? 'required'
											: ''
									}
								>
									<span>Test Dispatch Date</span>
									<EncounterDateField
										className={'testrequestdatefield'}
										name='date_test_ordered'
										placeholder='MM/DD/YYYY'
										disabled={isTestDispatchDateDisabled()}
										value={_.get(
											formdata,
											['date_test_ordered'],
											''
										)}
										onChange={(value) =>
											onFormChangeFunc(
												'date_test_ordered',
												value
											)
										}
										onBlur={(value) =>
											onHandleDateFieldValidate(
												'date_test_ordered',
												value
											)
										}
										disabledDate={(current) => {
											const today = moment();
											const _dateTestAuthorized = moment(
												_.get(
													formdata,
													['date_test_authorized'],
													''
												),
												'YYYY-MM-DD'
											);

											return (
												current.isSameOrBefore(
													today,
													'day'
												) &&
												current.isSameOrAfter(
													_dateTestAuthorized,
													'day'
												)
											);
										}}
										makeCalendarOnly={(event) => {
											if (
												event.keyCode == 8 ||
												event.keyCode == 46
											) {
												return true;
											} else {
												event.preventDefault();
											}
										}}
									/>
								</Layout.Col>
								<Layout.Col
									span='12'
									className={
										isExpectedResultsDateRequired()
											? 'required'
											: ''
									}
								>
									<span>Expected Results Date</span>
									<EncounterDateField
										className={
											'testrequestdatefield calendar-pull-left'
										}
										name='expected_lab_results_date'
										placeholder='MM/DD/YYYY'
										disabled={isExpectedResultsDateDisabled()}
										value={_.get(
											formdata,
											['expected_lab_results_date'],
											''
										)}
										onChange={(value) =>
											onFormChangeFunc(
												'expected_lab_results_date',
												value
											)
										}
										onBlur={(value) =>
											onHandleDateFieldValidate(
												'expected_lab_results_date',
												value
											)
										}
										disabledDate={(current) => {
											const _dateTestOrdered = moment(
												_.get(
													formdata,
													['date_test_ordered'],
													''
												),
												'YYYY-MM-DD'
											);
											return current.isSameOrAfter(
												_dateTestOrdered,
												'day'
											);
										}}
										makeCalendarOnly={(event) => {
											if (
												event.keyCode == 8 ||
												event.keyCode == 46
											) {
												return true;
											} else {
												event.preventDefault();
											}
										}}
									/>
								</Layout.Col>
							</Layout.Row>
						)}

						<Layout.Row gutter='16'>
							<Layout.Col
								span='12'
								className={
									isResultsReceiptDateRequired()
										? 'required'
										: ''
								}
							>
								<span>Results Receipt Date</span>
								<EncounterDateField
									className={'testrequestdatefield'}
									name='date_received_report'
									disabled={isResultsReceiptDateDisabled()}
									placeholder='MM/DD/YYYY'
									value={_.get(
										formdata,
										['date_received_report'],
										''
									)}
									onChange={(value) =>
										onFormChangeFunc(
											'date_received_report',
											value
										)
									}
									onBlur={(value) =>
										onHandleDateFieldValidate(
											'date_received_report',
											value
										)
									}
									disabledDate={(current) => {
										const today = moment();
										const _dateTestOrdered = moment(
											_.get(
												formdata,
												['date_test_ordered'],
												''
											),
											'YYYY-MM-DD'
										);
										return (
											current.isSameOrBefore(
												today,
												'day'
											) &&
											current.isSameOrAfter(
												_dateTestOrdered,
												'day'
											)
										);
									}}
									makeCalendarOnly={(event) => {
										if (
											event.keyCode == 8 ||
											event.keyCode == 46
										) {
											return true;
										} else {
											event.preventDefault();
										}
									}}
								/>
							</Layout.Col>
							<Layout.Col span='12'>
								<span>Results Release Date</span>
								<EncounterDateField
									className={
										'testrequestdatefield calendar-pull-left'
									}
									name='date_results_released_to_patient'
									placeholder='MM/DD/YYYY'
									disabled={isResultsReleaseDateDisabled()}
									value={_.get(
										formdata,
										['date_results_released_to_patient'],
										''
									)}
									onChange={(value) =>
										onFormChangeFunc(
											'date_results_released_to_patient',
											value
										)
									}
									onBlur={(value) =>
										onHandleDateFieldValidate(
											'date_results_released_to_patient',
											value
										)
									}
									disabledDate={(current) => {
										const today = moment();
										return current.isSameOrBefore(
											today,
											'day'
										);
									}}
									makeCalendarOnly={(event) => {
										if (
											event.keyCode == 8 ||
											event.keyCode == 46
										) {
											return true;
										} else {
											event.preventDefault();
										}
									}}
								/>
							</Layout.Col>
						</Layout.Row>						

						{showHoldResultWarnMsg() && (
							<WarningMessageBox {...holdResultWarnMsg} />
						)}

						<h2 className='module-header'>Sample Info</h2>

						<Layout.Row gutter='16'>
							<Layout.Col span='12' className='required'>
								<span>Sample Type (Preferred)</span>
								<Select
									name={'sample_type'}
									value={_.get(formdata, ['sample_type'], '')}
									onChange={(value) =>
										onFormChangeFunc('sample_type', value)
									}
									disabled={isSampleTypeDisabled()}
									placeholder='Select'
									clearable={true}
									filterable={true}
									filterMethod={(value) => {
										const _optionSampleTypeEnum =
											createEnumsOptions(
												props,
												['sampletypeenums'],
												'key',
												'display_name',
												'key'
											);
										if (
											_.isEmpty(value) ||
											_.isNil(value)
										) {
											setOptionsSampleTypesEnum(
												_optionSampleTypeEnum
											);
										} else {
											const _options =
												_optionSampleTypeEnum.filter(
													(o) =>
														o.label &&
														o.label
															.toLowerCase()
															.startsWith(
																value.toLowerCase()
															)
												);
											setOptionsSampleTypesEnum(_options);
										}
									}}
								>
									{_.get(sampleTypes, 'non-others', []).map(
										(el) => {
											return (
												<Select.Option
													key={el.value}
													label={el.label}
													value={el.value}
												/>
											);
										}
									)}
									{_.get(sampleTypes, 'others', false) && (
										<Select.OptionGroup
											key={'other'}
											label='OTHER'
											className='sample-type-other'
										>
											{_.get(
												sampleTypes,
												'others',
												[]
											).map((el) => {
												return (
													<Select.Option
														key={el.value}
														label={el.label}
														value={el.value}
													/>
												);
											})}
										</Select.OptionGroup>
									)}
								</Select>
							</Layout.Col>
							<Layout.Col span='12'>
								<span>BD/Saliva Kit Status</span>
								<Select
									name={'bd_saliva_kit_sent'}
									value={_.get(
										formdata,
										['bd_saliva_kit_sent'],
										''
									)}
									disabled={isDisabledBDSalivaKitStatus()}
									onChange={(value) =>
										onFormChangeFunc(
											'bd_saliva_kit_sent',
											value
										)
									}
									placeholder='Select'
									clearable={true}
									filterable={true}
									filterMethod={(value) => {
										if (!isDisabledBDSalivaKitStatus()) {
											const _bdSalivaKitSentEnum =
												_.isArray(
													createEnumsOptions(
														props,
														[
															'encounterschema',
															'bd_saliva_kit_sent',
														],
														'key',
														'display_name',
														'key'
													)
												)
													? createEnumsOptions(
															props,
															[
																'encounterschema',
																'bd_saliva_kit_sent',
															],
															'key',
															'display_name',
															'key'
													  )
													: [];
											if (
												_.isEmpty(value) ||
												_.isNil(value)
											) {
												setOptionsBDSalivaKitSentEnum(
													_bdSalivaKitSentEnum
												);
											} else {
												const _options =
													_bdSalivaKitSentEnum.filter(
														(o) =>
															o.label &&
															o.label
																.toLowerCase()
																.startsWith(
																	value.toLowerCase()
																)
													);
												setOptionsBDSalivaKitSentEnum(
													_options
												);
											}
										}
									}}
								>
									{optionsBDSalivaKitSentEnum.map((el) => {
										return (
											<Select.Option
												key={el.value}
												label={el.label}
												value={el.value}
											/>
										);
									})}
								</Select>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row gutter='16' className='blood-draw-row'>
							<Layout.Col span='12'>
								<span>Blood Draw Date</span>
								<EncounterDateField
									className={
										'testrequestdatefield calendar-pull-up calendar-pull-right'
									}
									name='blood_draw_order_date'
									disabled={false}
									placeholder='MM/DD/YYYY'
									value={_.get(
										formdata,
										['blood_draw_order_date'],
										''
									)}
									onChange={(value) =>
										onFormChangeFunc(
											'blood_draw_order_date',
											value
										)
									}
									onBlur={(value) =>
										onHandleDateFieldValidate(
											'blood_draw_order_date',
											value
										)
									}
									disabledDate={(current) => {
										const today = moment();
										return current.isSameOrBefore(
											today,
											'day'
										);
									}}
									makeCalendarOnly={(event) => {
										if (
											event.keyCode == 8 ||
											event.keyCode == 46
										) {
											return true;
										} else {
											event.preventDefault();
										}
									}}
								/>
							</Layout.Col>

							<Layout.Col span='12'>
								<span>Blood Draw Status</span>
								<Select
									name={'blood_draw_request'}
									value={_.get(
										formdata,
										['blood_draw_request'],
										''
									)}
									onChange={(value) =>
										onFormChangeFunc(
											'blood_draw_request',
											value
										)
									}
									placeholder='Select'
									clearable={true}
									filterable={true}
									filterMethod={(value) => {
										const _bloodDrawRequestEnum = _.isArray(
											createEnumsOptions(
												props,
												[
													'encounterschema',
													'blood_draw_request',
												],
												'key',
												'display_name',
												'key'
											)
										)
											? createEnumsOptions(
													props,
													[
														'encounterschema',
														'blood_draw_request',
													],
													'key',
													'display_name',
													'key'
											  )
											: [];
										if (
											_.isEmpty(value) ||
											_.isNil(value)
										) {
											setOptionsBloodDrawRequestEnum(
												_bloodDrawRequestEnum
											);
										} else {
											const _options =
												_bloodDrawRequestEnum.filter(
													(o) =>
														o.label &&
														o.label
															.toLowerCase()
															.startsWith(
																value.toLowerCase()
															)
												);
											setOptionsBloodDrawRequestEnum(
												_options
											);
										}
									}}
								>
									{optionsBloodDrawRequestEnum.map((el) => {
										return (
											<Select.Option
												key={el.value}
												label={el.label}
												value={el.value}
											/>
										);
									})}
								</Select>
							</Layout.Col>
						</Layout.Row>

						<h2 className='module-header'>Billing Info</h2>

						<Layout.Row
							gutter='16'
							className='test-order-billing-method-row'
						>
							<Layout.Col span='12' className='required'>
								<span>Test Billing Method</span>
								<Select
									name='patient_billing_method'
									value={_.get(
										formdata,
										['patient_billing_method'],
										''
									)}
									placeholder='Select'
									onChange={(value) =>
										onFormChangeFunc(
											'patient_billing_method',
											value
										)
									}
									clearable={true}
									filterable={true}
									filterMethod={(value) => {
										const _billingMethodEnum =
											createEnumsOptions(
												props,
												['patientbillingmethodenums'],
												'key',
												'display_name',
												'key'
											);
										if (
											_.isEmpty(value) ||
											_.isNil(value)
										) {
											setOptionsBillingMethodEnum(
												_billingMethodEnum
											);
										} else {
											const _options =
												_billingMethodEnum.filter(
													(o) =>
														o.label &&
														o.label
															.toLowerCase()
															.startsWith(
																value.toLowerCase()
															)
												);
											setOptionsBillingMethodEnum(
												_options
											);
										}
									}}
								>
									{optionsBillingMethodEnum.map((el) => {
										return (
											<Select.Option
												key={el.value}
												label={el.label}
												value={el.value}
											/>
										);
									})}
								</Select>
							</Layout.Col>
						</Layout.Row>

						{hasPatientNoInsurance() && (
							<>
								<Layout.Row gutter='16'>
									<Layout.Col span='24'>
										<div className='noinsunrace-info-container'>
											<div className='noinsunrace-info-body'>
												<div className='milestone-warning-icon'>
													<ReactSVG
														src={warningImg}
													/>
												</div>
												<div className='noinsunrace-info-text'>
													This patient does not have
													any insurance info on file.
													Click the button below to
													add insurance.
												</div>
											</div>
										</div>
									</Layout.Col>
								</Layout.Row>

								<Layout.Row gutter='16'>
									<Layout.Col span='24'>
										<Button
											className='add-insuranceinfo-button'
											onClick={() => {
												hideModal();
												changeTab(5);
											}}
										>
											<ReactSVG
												className='add-icon'
												src={add}
											/>
											<span>Add Insurance Info</span>
										</Button>
									</Layout.Col>
								</Layout.Row>
							</>
						)}
					</Fragment>
				)}
			</section>
		</Fragment>
	);
};

export default withRouter(EditTestRequestLabInfo);
