//React & Redux
import React, { Fragment } from 'react';

//Lodash
import { get, isString } from 'lodash';

// Component
import TextWithTooltip from '../TextWithTooltip';

export default function (props) {
	const isContentString = isString(props.children);
	const minWidth = get(props, ['minWidth'], '126px');
	const maxWidth = get(props, ['maxWidth'], '144px');
	const children = props.children || '--';
	const className = get(props, ['className'], '');
	const multiLine = get(props, ['multiLine'], '');
	const rowSpan = get(props, ['rowSpan'], '1');

	return (
		<Fragment>
			<td
				style={{
					...get(props, ['style'], {}),
					minWidth,
					maxWidth,
				}}
				onClick={(event) => {
					if (props.onClick) {
						event.stopPropagation();
						props.onClick();
					}
				}}
				className={className}
				rowSpan={rowSpan}
			>
				{isContentString ? (
					<TextWithTooltip maxWidth={maxWidth} multiLine={multiLine}>
						{children}
					</TextWithTooltip>
				) : (
					<div>{children}</div>
				)}
			</td>
		</Fragment>
	);
}
