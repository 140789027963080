import EmailValidator from 'email-validator';
import { isValidPhoneNumber } from 'libphonenumber-js';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import z from 'zod';

import { getSexAssignedAtBirth } from '../../../../../actions/patients';
import {
	GUARDIAN_RELATIONSHIP_ENUM,
	guardianRelationships,
} from '../../../../../Utils/appointmentUtils';

import CheckboxField from '../../../../Common/FormFields/CheckboxField';
import DatePickerField from '../../../../Common/FormFields/DatePickerField';
import Dropdown from '../../../../Common/FormFields/Dropdown';
import InputField from '../../../../Common/FormFields/InputField';
import PhoneNumberField from '../../../../Common/FormFields/PhoneNumberField';
import { Section } from '@gm/common/ui';
import { Tags } from '../../../../Common/V3/Tags';
import { DuplicatePatient } from '../../../../Common/V3/DuplicatePatient';

import { useSection } from './useSection';

import './PatientDemographicsAndContact.scss';

/**
 * Zod validation schema for the section
 */
export const schemaPatientDemographicsAndContact = z
	.object({
		accessionNumber: z.string().nullable(),
		dateOfBirth: z.string().nullable(),
		externalPatientId: z.string().nullable(),
		guardianFirstName: z.string().nullable(),
		guardianLastName: z.string().nullable(),
		hasGuardian: z.boolean(),
		noEmail: z.boolean(),
		patientEmail: z.string().nullable(),
		patientFirstName: z.string(),
		patientLastName: z.string(),
		patientPrimaryPhoneNumber: z.string(),
		relationshipToPatient: z
			.enum(guardianRelationships.map((item) => item.value))
			.nullable(),
		sexAssignedAtBirth: z.string().nullable(),
		tags: z.record(
			z.string(),
			z
				.boolean()
				.or(
					z.record(
						z.string(),
						z
							.array(z.string())
							.min(1, { message: 'Tag details are required' })
					)
				)
		),
		textNotification: z.boolean().optional(),
		duplicatePatientUuids: z.array(
			z.string().uuid({ message: 'UUID is invalid' })
		),
	})
	.superRefine((val, ctx) => {
		if (!val.patientFirstName) {
			ctx.addIssue({
				path: ['patientFirstName'],
				code: z.ZodIssueCode.custom,
				message: 'First name is required',
			});
		}

		if (!val.patientLastName) {
			ctx.addIssue({
				path: ['patientLastName'],
				code: z.ZodIssueCode.custom,
				message: 'Last name is required',
			});
		}

		if (!val.sexAssignedAtBirth) {
			ctx.addIssue({
				path: ['sexAssignedAtBirth'],
				code: z.ZodIssueCode.custom,
				message: 'Sex assigned at birth is required',
			});
		}

		if (!val.dateOfBirth) {
			ctx.addIssue({
				path: ['dateOfBirth'],
				code: z.ZodIssueCode.custom,
				message: 'Date of birth is required',
			});
		}

		if (val.noEmail) {
			if (
				val.patientEmail !== null &&
				!val.patientEmail.includes('noemail')
			) {
				ctx.addIssue({
					path: ['patientEmail'],
					code: z.ZodIssueCode.invalid_type,
					message: 'Email must be null',
				});
			}
		} else {
			if (!val.patientEmail) {
				ctx.addIssue({
					path: ['patientEmail'],
					code: z.ZodIssueCode.custom,
					message: 'Email is required',
				});
			} else if (!EmailValidator.validate(val.patientEmail)) {
				ctx.addIssue({
					path: ['patientEmail'],
					code: z.ZodIssueCode.custom,
					message: 'Email is invalid',
				});
			}
		}

		if (!val.patientPrimaryPhoneNumber) {
			ctx.addIssue({
				path: ['patientPrimaryPhoneNumber'],
				code: z.ZodIssueCode.custom,
				message: 'Phone number is required',
			});
		} else if (!isValidPhoneNumber(val.patientPrimaryPhoneNumber)) {
			ctx.addIssue({
				path: ['patientPrimaryPhoneNumber'],
				code: z.ZodIssueCode.custom,
				message: 'Phone number is invalid',
			});
		}

		if (val.hasGuardian) {
			if (!val.guardianFirstName) {
				ctx.addIssue({
					path: ['guardianFirstName'],
					code: z.ZodIssueCode.custom,
					message: 'First name is required',
				});
			}

			if (!val.guardianLastName) {
				ctx.addIssue({
					path: ['guardianLastName'],
					code: z.ZodIssueCode.custom,
					message: 'Last name is required',
				});
			}

			if (!val.patientPrimaryPhoneNumber) {
				ctx.addIssue({
					path: ['patientPrimaryPhoneNumber'],
					code: z.ZodIssueCode.custom,
					message: 'Phone number is required',
				});
			}

			if (!val.relationshipToPatient) {
				ctx.addIssue({
					path: ['relationshipToPatient'],
					code: z.ZodIssueCode.custom,
					message: 'Relationship to patient is required',
				});
			}
		}
	});

/**
 * Initial values for the section
 */
export const initialPatientDemographicsAndContact = {
	accessionNumber: null,
	dateOfBirth: null,
	externalPatientId: null,
	guardianFirstName: '',
	guardianLastName: '',
	hasGuardian: false,
	noEmail: false,
	patientEmail: '',
	patientFirstName: '',
	patientLastName: '',
	patientPrimaryPhoneNumber: '',
	relationshipToPatient: null,
	sexAssignedAtBirth: null,
	tags: {},
	textNotification: true,
	duplicatePatientUuids: [],
};

export const PatientDemographicsAndContact = function ({
	isEdit,
	patientDetail,
	sectionDataContainerName,
}) {
	const { setFieldValue, errors, onChange, touched, values } = useSection(
		sectionDataContainerName
	);

	const { sexAssignedAtBirthEnum } = useSelector((store) => ({
		sexAssignedAtBirthEnum:
			store.sexAssignedAtBirth?.sexAssignedAtBirth?.[
				'sex_assigned_at_birth'
			] ?? [],
	}));

	const sexAssignedAtBirthOptions = sexAssignedAtBirthEnum?.filter(
		(el) => el.key !== 'unknown'
	);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getSexAssignedAtBirth());
	}, []);

	useEffect(() => {
		if (values.noEmail && !isEdit) {
			setFieldValue('patientEmail', null);
		}
	}, [values.noEmail]);

	useEffect(() => {
		if (!values.hasGuardian) {
			setFieldValue('guardianFirstName', null);
			setFieldValue('guardianLastName', null);
			setFieldValue('relationshipToPatient', null);
		} else if (values.hasGuardian && patientDetail?.has_guardian) {
			const { first_name, last_name, relationship } =
				patientDetail.guardian || {};
			setFieldValue('guardianFirstName', first_name);
			setFieldValue('guardianLastName', last_name);
			setFieldValue('relationshipToPatient', relationship);
		}
	}, [values.hasGuardian, patientDetail]);

	useEffect(() => {
		if (patientDetail && isEdit) {
			setFieldValue('patientFirstName', patientDetail.first_name);
			setFieldValue('patientLastName', patientDetail.last_name);
			setFieldValue(
				'sexAssignedAtBirth',
				patientDetail.sex_assigned_at_birth
			);
			setFieldValue('dateOfBirth', patientDetail.dob);
			setFieldValue('patientEmail', patientDetail.email);
			setFieldValue(
				'patientPrimaryPhoneNumber',
				patientDetail.phones?.primary?.value
			);
			setFieldValue(
				'textNotification',
				patientDetail.phones?.primary?.consent_to_text
			);
			if (patientDetail.is_noemail) {
				setFieldValue('noEmail', true);
			}
			if (patientDetail.has_guardian) {
				setFieldValue('hasGuardian', patientDetail.has_guardian);
				setFieldValue(
					'guardianFirstName',
					patientDetail.guardian?.first_name
				);
				setFieldValue(
					'guardianLastName',
					patientDetail.guardian?.last_name
				);
				setFieldValue(
					'patientPrimaryPhoneNumber',
					patientDetail.guardian?.phones?.primary?.value
				);
				setFieldValue(
					'textNotification',
					patientDetail.guardian?.phones?.primary?.consent_to_text
				);
				setFieldValue(
					'relationshipToPatient',
					patientDetail.guardian?.relationship
				);
			}
			if (patientDetail.external_patient_id) {
				setFieldValue(
					'externalPatientId',
					patientDetail?.external_patient_id
				);
			}
			if (patientDetail.external_patient_id) {
				setFieldValue(
					'accessionNumber',
					patientDetail?.accession_number
				);
			}
			if (patientDetail.user_tags.length > 0) {
				setFieldValue(
					'tags',
					patientDetail.user_tags?.reduce((acc, item) => {
						acc[item.tagId] = item.tagProperties
							? item.tagProperties[0]
							: true;
						return acc;
					}, {}) ?? {},
				);
			}
			if (
				patientDetail.duplicate_patients &&
				patientDetail.duplicate_patients.length > 0
			) {
				const duplicatePatients = patientDetail.duplicate_patients.map(
					(item) => item.uuid
				);

				setFieldValue('duplicatePatientUuids', duplicatePatients);
			}
		}
	}, [patientDetail]);

	const existingDuplicatePatients = () => {
		if (
			patientDetail.duplicate_patients &&
			patientDetail.duplicate_patients.length > 0
		) {
			return patientDetail.duplicate_patients;
		}
		return [];
	};

	return (
		<Section
			className='patient-demographics-and-contact'
			title='Patient Demographics &amp; Contact'
		>
			<div className='demographics'>
				<div className='input-row'>
					<InputField
						required
						name='patientFirstName'
						label="Patient's First Name"
						value={values.patientFirstName}
						error={
							touched.patientFirstName && errors.patientFirstName
						}
						onChange={onChange('patientFirstName')}
					/>
					<InputField
						required
						name='patientLastName'
						label="Patient's Last Name"
						value={values.patientLastName}
						error={
							touched.patientLastName && errors.patientLastName
						}
						onChange={onChange('patientLastName')}
					/>
					<Dropdown
						required
						name='sexAssignedAtBirth'
						label='Sex Assigned at Birth'
						value={values.sexAssignedAtBirth}
						error={
							touched.sexAssignedAtBirth &&
							errors.sexAssignedAtBirth
						}
						onChange={onChange('sexAssignedAtBirth')}
						options={sexAssignedAtBirthOptions.map((item) => ({
							label: item.display_name,
							value: item.key,
						}))}
					/>
				</div>
				<div>
					<CheckboxField
						name='checkIfPatientHasGuardian'
						label='Check if patient has Guardian/Power of Attorney'
						value={values.hasGuardian}
						onChange={onChange('hasGuardian')}
					/>
				</div>
				<div className='input-row'>
					<DatePickerField
						label='Date of Birth'
						name='dateOfBirth'
						className='date-of-birth'
						required
						error={touched.dateOfBirth && errors.dateOfBirth}
						value={values.dateOfBirth}
						onChange={(val) => {
							setFieldValue('dateOfBirth', val ?? null)
						}}
						placeholder='MM/DD/YYYY'
						disabledFutureDates={true}
						isClearable={true}
					/>
					{values.hasGuardian && (
						<>
							<InputField
								required
								name='guardianFirstName'
								label="Guardian's First Name"
								value={values.guardianFirstName}
								error={
									touched.guardianFirstName &&
									errors.guardianFirstName
								}
								onChange={onChange('guardianFirstName')}
							/>
							<InputField
								required
								name='guardianLastName'
								label="Guardian's Last Name"
								value={values.guardianLastName}
								error={
									touched.guardianLastName &&
									errors.guardianLastName
								}
								onChange={onChange('guardianLastName')}
							/>
						</>
					)}
					<div>
						<InputField
							required={!values.noEmail}
							name='patientEmail'
							label={
								values.hasGuardian
									? "Guardian's Email"
									: "Patient's Email"
							}
							disabled={values.noEmail}
							value={values.patientEmail}
							error={touched.patientEmail && errors.patientEmail}
							onChange={onChange('patientEmail')}
						/>
						<CheckboxField
							tooltip='If a patient has no email, they will not be able to self schedule via the patient portal. They can schedule appointments via phone call.'
							name='checkIfPatientHasNoEmail'
							disabled={
								isEdit &&
								(!values.patientEmail ||
									(values.patientEmail.length > 0 &&
										!values.patientEmail.includes(
											'noemail',
										)))
							}
							label={
								values.hasGuardian
									? 'Check if guardian has no email'
									: 'Check if patient has no email'
							}
							value={values.noEmail}
							onChange={onChange('noEmail')}
						/>
					</div>
					<div>
						<PhoneNumberField
							required
							name='patientPrimaryPhoneNumber'
							label={
								values.hasGuardian
									? "Guardian's Primary Phone Number"
									: "Patient's Primary Phone Number"
							}
							value={values.patientPrimaryPhoneNumber}
							error={
								touched.patientPrimaryPhoneNumber &&
								errors.patientPrimaryPhoneNumber
							}
							onChange={onChange('patientPrimaryPhoneNumber')}
						/>
						<CheckboxField
							tooltip='By checking this box, you confirm that the patient agrees to receive texts from Genome Medical about its genetic services.'
							name='sendPatientTextNotification'
							label={
								values.hasGuardian
									? 'Send guardian text notification'
									: 'Send patient text notification'
							}
							value={values.textNotification}
							onChange={onChange('textNotification')}
						/>
					</div>
					{values.hasGuardian && (
						<Dropdown
							label='Relationship to Patient'
							name='relationshipToPatient'
							required
							options={guardianRelationships}
							error={
								touched.relationshipToPatient &&
								errors.relationshipToPatient
							}
							onChange={onChange('relationshipToPatient')}
							value={values.relationshipToPatient}
						/>
					)}
				</div>
				{values.relationshipToPatient &&
					values.relationshipToPatient !==
						GUARDIAN_RELATIONSHIP_ENUM.PARENT && (
						<p>
							Documentation must be provided before the patient's
							visit to validate this relationship. Please upload
							the documentation below or reach out to the
							Guardian/POA to advise them that it must be uploaded
							prior to the visit.
						</p>
					)}
			</div>
			<hr />
			<h3>External Patient Identifiers</h3>
			<p>
				If you would like to connect this patient to a record outside of
				the GMI system, please enter one of the external patient
				identifiers. Click the icon to learn more about each ID type.
			</p>
			<div className='external-patient-identifiers'>
				<InputField
					tooltip='Enter an External Patient ID to connect this patient record with external Electronic Medical Record (EMR) systems for easy data integration and access'
					name='externalPatientId'
					label='External Patient ID'
					value={values.externalPatientId}
					error={
						touched.externalPatientId && errors.externalPatientId
					}
					onChange={onChange('externalPatientId')}
				/>
				<InputField
					tooltip='Enter an Accession or Test Request Form number to connect the patient encounter with external Electronic Medical Record (EMR) systems'
					name='accessionNumber'
					label='Accession/TRF Number'
					value={values.accessionNumber}
					error={touched.accessionNumber && errors.accessionNumber}
					onChange={onChange('accessionNumber')}
				/>
			</div>
			<hr />
			<h3>Flags</h3>
			<p>If applicable, add relevant flags to the patient account</p>
			<Tags
				errors={errors.tags}
				setFieldValue={setFieldValue}
				touched={touched.tags}
				values={values.tags}
			>
				{(isOpen) =>
					isOpen &&
					isEdit && (
						<DuplicatePatient
							value={values.duplicatePatientUuids}
							onChange={(updatedValues) =>
								setFieldValue(
									'duplicatePatientUuids',
									updatedValues
								)
							}
							error={errors.duplicatePatientUuids}
							existingDuplicatePatientsUuid={existingDuplicatePatients()}
						/>
					)
				}
			</Tags>
		</Section>
	);
};
