//React & Redux
import React from 'react';

//Images
import actionRequired from '../../../assets/action-required.png';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Layout, Input, DatePicker } from 'gm-element-react';

//Utils
import { createEnumsOptions } from '../../Patients/V2/Encounters/Common/utilsfunctions';

//Components
import { SelectComponent as Select } from '../../Common/SelectComponent';

const EditPartnerInvoiceSegment = (props) => {
	const {
		handleChange,
		partnerInvoiceEnums,
		stateData: {
			partnerInvoiceDate = '',
			partnerInvoiceRate = '',
			partnerInvoiceStatus = '',
		},
	} = props;

	const onNumberChange = (key, val) => {
		!_.isNaN(_.toNumber(val)) && handleChange(key, val);
	};

	const textField = 'erFieldText inputFieldTextTaller';
	const dropdownField = 'inputFieldDropdown inputFieldDropdownTaller';
	const datePickerField = 'inputFieldDatePicker inputFieldDatePickerTaller';

	return (
		<>
			<Layout.Row gutter='20'>
				<Layout.Col span='24'>
					<div className='test-number-container'>
						<div className='test-number'>Partner Invoice</div>
						<div className='divider'></div>
					</div>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20'>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>
						Invoice Status
						{partnerInvoiceStatus == 'not_sent' && (
							<img
								className='action-required'
								src={actionRequired}
								alt=''
							/>
						)}
					</span>
					<span className='val-enctr-sgmnt-field'>
						<Select
							value={partnerInvoiceStatus}
							onChange={(value) =>
								handleChange('partner_invoice_status', value)
							}
							name='partner_invoice_status'
							className={dropdownField}
							clearable={true}
							typeahead={true}
							options={
								_.isArray(
									createEnumsOptions(
										props,
										['partnerInvoiceEnums'],
										'key',
										'display_name',
										'key'
									)
								)
									? createEnumsOptions(
											props,
											['partnerInvoiceEnums'],
											'key',
											'display_name',
											'key'
									  )
									: []
							}
						/>
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Invoice Date</span>
					<span className='val-enctr-sgmnt-field'>
						<DatePicker
							placeholder='Select Date'
							format={'MM/dd/yyyy'}
							value={partnerInvoiceDate}
							onChange={(value) =>
								handleChange('partner_invoice_date', value)
							}
							isReadOnly={true}
							className={datePickerField}
						/>
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Partner Invoice</span>
					<span className='val-enctr-sgmnt-field'>
						<Input
							placeholder='$12.34'
							trim={true}
							value={partnerInvoiceRate}
							onChange={(value) =>
								onNumberChange('partner_invoice_rate', value)
							}
							className={textField}
						/>
					</span>
				</Layout.Col>
			</Layout.Row>
		</>
	);
};

export default EditPartnerInvoiceSegment;
