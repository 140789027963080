//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Layout } from 'gm-element-react';

const ViewBillingNotesSegment = (props) => {
	const {
		encounterBillingDetail: { billing_notes = null },
	} = props;

	return (
		<>
			<Layout.Row gutter='20'>
				<Layout.Col span='24'>
					<div className='test-number-container'>
						<div className='test-number'>Billing Notes</div>
						<div className='divider'></div>
					</div>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20'>
				<Layout.Col span='24'>
					<span className='lbl-enctr-sgmnt'>{billing_notes}</span>
				</Layout.Col>
			</Layout.Row>
		</>
	);
};

export default ViewBillingNotesSegment;
