//React & Redux
import React from 'react';

//Components
import NoOutreach from './NoOutreach';
import OutreachRecord from './OutreachRecord';
import EmptyOutreachRecord from './EmptyOutreachRecord';

const OutreachList = (props) => {
	const { outreachList, showEditOutreachDialog } = props;
	const getList = () =>
		outreachList.map((outreach) => {
			const {
				hasEmailOutreach = false,
				subtype = '',
				type = '',
				smsBody = null,
				outreachType = '',
				emailSubject = null,
			} = outreach;
			if (hasEmailOutreach && !smsBody && !emailSubject) return;
			if (
				subtype in
					{ 'ROR Patient Declined': 1, 'ROR Outreach Stopped': 1 } &&
				type == 'ror_outreach'
			) {
				return (
					<NoOutreach
						key={outreach.communicationUUID}
						outreach={outreach}
					/>
				);
			} else {
				return outreachType !== 'patient_scheduled_appointment' &&
					outreachType !== 'appointment_reminder' ? (
					<OutreachRecord
						key={outreach.communicationUUID}
						outreach={outreach}
						showEditOutreachDialog={showEditOutreachDialog}
					/>
				) : null;
			}
		});

	return (
		<div className='outreachlist-container'>
			{outreachList.length ? getList() : <EmptyOutreachRecord />}
		</div>
	);
};

export default OutreachList;
