import axios from 'axios';
import { isEmpty } from 'lodash';
import get from 'lodash/get';

class Users {
	get = (data) => {
		const payload = {
			order_by: get(data, 'order_by', 'name'),
			order_type: get(data, 'order_type', 'asc'),
			offset: get(data, 'offset', 0),
			limit: get(data, 'limit', 500),
		};
		if (!isEmpty(get(data, ['role'], [])))
			payload['role'] = get(data, 'role', []);
		if (!isEmpty(get(data, ['q'], []))) payload['q'] = get(data, 'q', []);
		if (!isEmpty(get(data, ['partnerUUIDs'], [])))
			payload['partners'] = get(data, 'partnerUUIDs', []);

		return axios.post('/api/users/search', payload);
	};

	getByUUID = (uuid) => {
		return axios.get(`/api/users/${uuid}`);
	};
}

export default new Users();
