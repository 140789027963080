//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import ICDTag from './ICDTag.js';

class ICDSelected extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		const selectedCodes = get(this, 'props.selectedICDCodes', []) || [];
		return (
			<div className='chargeICDSelectedContainer'>
				<div className='chargeICDSelectedContainerHeader'>SELECTED</div>
				{selectedCodes.map((el) => {
					return (
						<ICDTag
							icdCode={el}
							toggleCodeSelection={this.props.toggleCodeSelection}
							hasX={true}
							altDisplayKey={get(
								this,
								'props.localSearchKey',
								null
							)}
							tagDisplayFunc={this.props.tagDisplayFunc}
							longTagVariant={get(
								this,
								'props.longTagVariant',
								false
							)}
							key={el.uuid}
						/>
					);
				})}
			</div>
		);
	}
}

export default ICDSelected;
