//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';
import moment from 'moment';

//Utils
import { imageDefault } from '../../../Utils/encounterUtils';
import { providers_images_for_appointment } from '../../../Utils/appointmentUtils';

//UI Libraries
import { Dialog, Button } from 'gm-element-react';

//Components
import Icon from '../../Common/Icon';

//Styles
import '../css/reschedule-confirm-dialog.css';

class RescheduleConfirmDialog extends React.Component {
	constructor(props) {
		super(props);

		//bindings
		this.handleConfirmDialogNoClick =
			this.handleConfirmDialogNoClick.bind(this);
	}

	getAppointmentTime() {
		if (!_.isNil(_.get(this, ['props', 'details', 'start_time'], null))) {
			const timezone = _.get(this, ['props', 'details', 'timezone']);
			const start = moment(
				_.get(this, ['props', 'details', 'start_time'])
			);
			const end = moment(_.get(this, ['props', 'details', 'end_time']));
			const timezonedate = moment.tz(timezone);
			return (
				start.format('dddd, MMM D, h:mm A') +
				' - ' +
				end.format('h:mm A') +
				timezonedate.format(' z')
			);
		}
		return '';
	}

	getspecialty() {
		const specialties = _.get(this, ['props', 'specialties'], []);
		const selected = !_.isNil(
			_.get(this, ['props', 'details', 'vsee_specialty'], null)
		)
			? _.get(this, ['props', 'details', 'vsee_specialty'], '')
					.replace('_Willing', '')
					.replace('_willing', '')
			: '';
		const speciality = specialties.find((c) => c.key === selected);
		return _.get(speciality, 'display_name', '');
	}

	getLegacyProviderIcon() {
		const provider_id = _.get(this, ['props', 'details', 'provider_id']);
		const providers = _.get(this, ['props', 'providers'], []);

		let providerImage = (
			<img style={{ height: '100%' }} src={imageDefault} alt='' />
		);

		if (provider_id) {
			const provider = _.find(providers, function (o) {
				return o.id == provider_id;
			});
			const full_name = _.get(provider, 'full_name', '');

			const mappedImage = providers_images_for_appointment[full_name];
			if (full_name && mappedImage) {
				providerImage = (
					<img className='provider-img' src={mappedImage} alt='' />
				);
			} else if (full_name != '')
				providerImage = (
					<img className='provider-img' src={imageDefault} alt='' />
				);
		} else {
			providerImage = (
				<img className='provider-img' src={imageDefault} alt='' />
			);
		}
		return providerImage;
	}
	getProviderName() {
		const provider_id = _.get(this, ['props', 'details', 'provider_id']);
		const providers = _.get(this, ['props', 'providers'], []);
		let providerName = '';
		const separator = ' ';
		if (provider_id) {
			const provider = _.find(providers, function (o) {
				return o.id == provider_id;
			});
			providerName = this.ucFirstAllWords(
				_.get(provider, 'full_name', '')
			);
			if (providerName.length > 0) {
				const ProviderNameobj = providerName.split(separator);
				const first_name = _.get(ProviderNameobj, 0, '');
				const middle_name =
					providerName.length > 2
						? ' ' + _.get(ProviderNameobj, 1, '')
						: '';
				const last_name =
					providerName.length > 2
						? ' ' + _.get(ProviderNameobj, 2, '')
						: ' ' + _.get(ProviderNameobj, 1, '');
				const result =
					first_name.charAt(0) + '.' + middle_name + last_name;
				return result;
			} else {
				return '--';
			}
		} else {
			return 'All Providers';
		}
	}
	ucFirstAllWords(str, separator = ' ') {
		const pieces = str.split(separator);
		for (let i = 0; i < pieces.length; i++) {
			const j = pieces[i].charAt(0).toUpperCase();
			pieces[i] = j + pieces[i].substr(1).toLowerCase();
		}
		return pieces.join(separator);
	}

	getAppointmentModalityIcon() {
		const modality = _.get(this, ['props', 'details', 'modality'], '');
		if (modality.toLowerCase() == 'video')
			return <Icon icon='video' className='videoic' />;
		return <Icon icon='phone' className='videoic' />;
	}

	handleConfirmDialogNoClick() {
		this.props.closeRescheduleConfirmDialog();
		this.props.handleNavigateToAppointmentList();
	}

	render() {
		return (
			<Fragment>
				<Dialog
					className='reschedule-proceed-dialog'
					title={
						<Fragment>
							<Icon
								icon='warning-circle'
								className='warning-image'
							/>{' '}
							Proceed to rescheduling?
						</Fragment>
					}
					size='tiny'
					visible={_.get(
						this,
						['props', 'show_rescheduleconfirmdialog'],
						true
					)}
					onCancel={() => this.props.closeRescheduleConfirmDialog()}
					lockScroll={false}
					showClose={false}
				>
					<Dialog.Body>
						<div role='note' className='reschedule-proceed-note'>
							By rescheduling, your original appointment will be
							cancelled and a new appointment will be scheduled.
						</div>
						<div className='title-new-appointment'>
							New appointment
						</div>
						<div
							className='consultation-type'
							style={{ marginBottom: '3px' }}
						>
							{_.get(this, [
								'props',
								'details',
								'consultation_type',
							])}
						</div>
						<div className='encounter-timestamp'>
							{this.getAppointmentTime()} -
							<span className='encounter-state-visit'>
								{' '}
								{_.get(this, ['props', 'details', 'state'])}
							</span>
						</div>
						<div className='specialty'>{this.getspecialty()}</div>
						<div style={{ display: 'flex' }}>
							<div className='providerinfo-container'>
								<span>{this.getLegacyProviderIcon()}</span>
								<span className='provider-name'>
									{this.getProviderName()}
								</span>
							</div>
							<div className='modality-container'>
								<span className='modality-icon'>
									{this.getAppointmentModalityIcon()}
								</span>
								<span className='modality-text'>
									{_.get(this, [
										'props',
										'details',
										'modality',
									])}
								</span>
							</div>
						</div>
					</Dialog.Body>
					<Dialog.Footer>
						<Button
							onClick={() => this.handleConfirmDialogNoClick()}
						>
							No
						</Button>
						<Button
							type='primary'
							onClick={() =>
								this.props.handleConfirmDialogClick()
							}
						>
							Yes
						</Button>
					</Dialog.Footer>
				</Dialog>
			</Fragment>
		);
	}
}

export default RescheduleConfirmDialog;
