//React & Redux
import React from 'react';

//Images
import actionRequired from '../../../assets/action-required.png';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Layout } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

const ViewPartnerInvoiceSegment = (props) => {
	const {
		encounterBillingDetail: {
			partner_invoice_date: partnerInvoiceDate = '',
			partner_invoice_rate: partnerInvoiceRate = null,
			partner_invoice_status: partnerInvoiceStatus = '',
		},
		partnerInvoiceEnums,
	} = props;

	const displayInvoiceStatus = () => {
		const invoiceObject = partnerInvoiceEnums.find(
			(a) => a.key == partnerInvoiceStatus
		);
		return invoiceObject
			? _.get(invoiceObject, 'display_name', '--')
			: partnerInvoiceStatus || '--';
	};

	const displayInvoiceDate = () =>
		_.get(partnerInvoiceDate, 'length', 0) > 0
			? moment(partnerInvoiceDate).format('MMM D, YYYY')
			: '--';

	const displayPartnerInvoice = () =>
		!_.isNil(partnerInvoiceRate) ? '$' + partnerInvoiceRate : '--';

	return (
		<>
			<Layout.Row gutter='20'>
				<Layout.Col span='24'>
					<div className='test-number-container'>
						<div className='test-number'>Partner Invoice</div>
						<div className='divider'></div>
					</div>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20'>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>
						Invoice Status
						{partnerInvoiceStatus == 'not_sent' && (
							<img
								className='action-required'
								src={actionRequired}
								alt=''
							/>
						)}
					</span>
					<span className='val-enctr-sgmnt-field'>
						{displayInvoiceStatus()}
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Invoice Date</span>
					<span className='val-enctr-sgmnt-field'>
						{displayInvoiceDate()}
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Partner Invoice</span>
					<span className='val-enctr-sgmnt-field'>
						{displayPartnerInvoice()}
					</span>
				</Layout.Col>
			</Layout.Row>
		</>
	);
};

export default ViewPartnerInvoiceSegment;
