//React & Redux
import React from 'react';
import { withRouter } from 'react-router-dom';

//Images
import ReactSVG from 'react-svg';
import threeDot from '../../assets/threedot.svg';

//Lodash
import get from 'lodash/get';

//Utils
import { isExternalUser } from './../../utils.js';
import {
	CARE_APPOINTMENTS_APPOINTMENT_CANCEL,
	CARE_APPOINTMENTS_APPOINTMENT_RESCHEDULING,
} from '../../Utils/permissionUtils';

//Components
import RBAC from '../RBAC';
class AppointmentMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			menuOpen: false,
		};
		//bindings
		this.openMenu = this.openMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
		this.navigateToRescheduleAppointmentPage =
			this.navigateToRescheduleAppointmentPage.bind(this);
		this.openAlert = this.openAlert.bind(this);
		this.openAuditTrail = this.openAuditTrail.bind(this);
		//this.closeAlert = this.closeAlert.bind(this);
		// this.appointmentCancel = this.appointmentCancel.bind(this);
		// this.appointmentNoShow = this.appointmentNoShow.bind(this);
	}
	//functions

	componentWillUnmount() {
		document
			.querySelector('body')
			.removeEventListener('click', this.closeMenu, { capture: true });
	}

	openMenu(e) {
		this.setState({ menuOpen: true });
		this.placeholderRow.classList.add('appointmentMenuContainerClicked');
		document
			.querySelector('body')
			.addEventListener('click', this.closeMenu, { capture: true });
	}

	closeMenu(e) {
		if (
			(this.option1 ? !this.option1.contains(e.target) : true) &&
			(this.option2 ? !this.option2.contains(e.target) : true) &&
			(this.option3 ? !this.option3.contains(e.target) : true) &&
			(this.option4 ? !this.option4.contains(e.target) : true)
		) {
			// e.stopPropagation();
			this.setState({ menuOpen: false });
			this.placeholderRow.classList.remove(
				'appointmentMenuContainerClicked'
			);
			document
				.querySelector('body')
				.removeEventListener('click', this.closeMenu, {
					capture: true,
				});
		} else {
			// The option 2 for rescheduling is taking to a new component, therefore the option is excluded from the setTimeout().
			// The appointment menu would be unmounted and the setState action could be performed after that.
			if (this.option2 ? !this.option2.contains(e.target) : true) {
				setTimeout(() => {
					this.setState({ menuOpen: false });
					this.placeholderRow.classList.remove(
						'appointmentMenuContainerClicked'
					);
					document
						.querySelector('body')
						.removeEventListener('click', this.closeMenu, {
							capture: true,
						});
				}, 1);
			}
		}
	}

	openAlert(e, type, func) {
		e.stopPropagation();
		this.props.openAppointmentAlert(type, func, this.props.encounter.uuid);
	}

	openAuditTrail(e, uuid) {
		e.stopPropagation();
		this.props.openAuditTrail(get(this, 'props.encounter.uuid', null));
	}

	navigateToRescheduleAppointmentPage(encounter_uuid) {
		this.props.history.push({
			pathname: '/app/appointments/reschedule/' + encounter_uuid,
			state: {
				breadcrumbs: [
					{
						location: 'Appointments',
						url: '/app/appointments',
					},
					{
						location: 'Reschedule Appointment',
					},
				],
			},
		});
	}

	render() {
		const activeRole = get(this, 'props.activeRole', null);
		const threeDotMenuClass = get(this, 'state.menuOpen', false)
			? 'encounterThreeDotMenu encounterThreeDotMenuOpen'
			: 'encounterThreeDotMenu';
		const isBookedVisit = get(this, 'props.isBookedVisit', null);
		return (
			<div className='appointmentMenuContainer'>
				<div
					className='appointmentMenuPlaceholderRow encounterMenu'
					onClick={this.openMenu}
					ref={(el) => (this.placeholderRow = el)}
				>
					<ReactSVG src={threeDot} className={threeDotMenuClass} />
				</div>
				{this.state.menuOpen && (
					<div className='appointmentMenuOptionContainer'>
						{isBookedVisit && (
							<RBAC
								action={CARE_APPOINTMENTS_APPOINTMENT_CANCEL}
								yes={
									<div
										className='appointmentMenuOption'
										onClick={(e) =>
											this.openAlert(
												e,
												'cancel',
												this.appointmentCancel
											)
										}
										ref={(el) => (this.option1 = el)}
									>
										<span className='appointmentMenuOptionText'>
											Cancel Appointment
										</span>
									</div>
								}
							/>
						)}

						<RBAC
							action={CARE_APPOINTMENTS_APPOINTMENT_RESCHEDULING}
							yes={
								<div
									className={
										isBookedVisit &&
										!this.props.appointmentStarted
											? 'appointmentMenuOption'
											: 'appointmentMenuOption appointmentMenuOptionDisabled'
									}
									ref={(el) => (this.option2 = el)}
									onClick={() => {
										if (
											isBookedVisit &&
											!this.props.appointmentStarted
										) {
											this.navigateToRescheduleAppointmentPage(
												get(
													this,
													'props.encounter.uuid',
													null
												)
											);
										}
									}}
								>
									<span className='appointmentMenuOptionText'>
										Reschedule Appointment
									</span>
								</div>
							}
						/>

						{isBookedVisit && this.props.appointmentStarted && (
							<RBAC
								action={CARE_APPOINTMENTS_APPOINTMENT_CANCEL}
								yes={
									<div
										className='appointmentMenuOption'
										onClick={(e) =>
											this.openAlert(
												e,
												'noshow',
												this.appointmentNoShow
											)
										}
										ref={(el) => (this.option3 = el)}
									>
										<span className='appointmentMenuOptionText'>
											No Show
										</span>
									</div>
								}
							/>
						)}
						{!isExternalUser(activeRole) && (
							<div
								className='appointmentMenuOption'
								onClick={(e) =>
									this.openAuditTrail(
										e,
										get(this, 'props.encounter.uuid', null)
									)
								}
								ref={(el) => (this.option4 = el)}
							>
								<span className='appointmentMenuOptionText'>
									Audit Trail
								</span>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default withRouter(AppointmentMenu);
