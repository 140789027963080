import _ from 'lodash';

export const hydrateReferralPrograms = (data) => {
	const temp = (data && data.data) || {};
	return {
		data: temp.data.map((d) => hydrateReferralProgram(d)) || [],
		total: temp.total,
		start: temp.start,
		offset: temp.offset,
		limit: temp.limit,
		has_prev: temp.has_prev,
		has_next: temp.has_next,
		end: temp.end,
	};
};

export const hydrateReferralProgram = (data) => {
	return {
		name: data && data.name,
		displayName: data && data.display_name,
		eligibility: data && data.eligibility_required,
		selfScheduling: data && data.allow_patients_self_scheduling,
		createdDate: data && data.created_at,
		modifiedDate: data && data.modified_at,
		referralID: data && data.id,
		referralUUID: data && data.uuid,
		selfRegistrationEnabled: data && data.self_registration_enabled,
		programType: data && data.program_type,
		registrationURL: data && data.registration_url,
	};
};
export const hydratePartnerDocuments = (data) => {
	const temp = (data && data.data) || {};
	return {
		data: temp.data.map((d) => hydratePartnerDocument(d)) || [],
		total: temp.total,
	};
};

export const hydratePartnerDocument = (data) => {
	return (
		(data && {
			name: data.file_name,
			documentType: data.document_type && data.document_type.display_name,
			documentTypeValue: data.document_type && data.document_type.name,
			URL: data.external_storage_id,
			createdDate: data.created_at,
			modifiedDate: data.updated_at,
			documentUUID: data.uuid,
			isBlank: data.is_blank,
			isView: !data.is_blank && !_.get(data, 'deleted_by.uuid', null),
		}) ||
		{}
	);
};

export const mappingSorting = {
	name: 'name',
	displayName: 'display_name',
	eligibility: 'eligibility_required',
	selfScheduling: 'allow_patients_self_scheduling',
	createdDate: 'created_at',
	modifiedDate: 'modified_at',
};

export const mapDocumentSorting = {
	name: 'file_name',
	documentType: 'document_type',
	createdDate: 'created',
	modifiedDate: 'updated',
};

export const hydrateCreateTemplate = (data, category) => {
	const res = (data && data.data && data.data.data) || {};

	return (
		{
			docLink: res.doc_link,
			displayName:
				res && res.document_type && res.document_type.display_name,
			name: res && res.document_type && res.document_type.name,
			externalStorageID: res && res.external_storage_id,
			storageDisplayName:
				res &&
				res.external_storage_type &&
				res.external_storage_type.display_name,
			fileExtensionID: res && res.file_extension_id,
			fileName: res && res.file_name,
			fileSize: res && res.file_size,
			isBlank: res && res.is_blank,
			isEditable: res && res.is_editable,
			typeID: res && res.type_id,
			documentUUID: res && res.uuid,
			category: category,
		} || {}
	);
};

export const hydrateUpdateTemplate = (data) => {
	const res = (data && data.data && data.data.data) || {};

	return (
		{
			docLink: res.doc_link,
			displayName:
				res && res.document_type && res.document_type.display_name,
			name: res && res.document_type && res.document_type.name,
			externalStorageID: res && res.external_storage_id,
			storageDisplayName:
				res &&
				res.external_storage_type &&
				res.external_storage_type.display_name,
			fileExtensionID: res && res.file_extension_id,
			fileName: res && res.file_name,
			fileSize: res && res.file_size,
			isBlank: res && res.is_blank,
			isEditable: res && res.is_editable,
			typeID: res && res.type_id,
			documentUUID: res && res.uuid,
		} || {}
	);
};

export const hydratePartnerDocumentDetail = (data) => {
	const res = (data && data.data && data.data.data) || undefined;

	return (
		(res && {
			fileName: res.file_name,
			docLink: res.doc_link,
			isBlank: res.is_blank,
			isEditable: res.is_editable,
			externalStorageID: res.external_storage_id,
			fileExtensionID: res.file_extension_id,
			isView: !res.is_blank && !_.get(res, 'deleted_by.uuid', null),
			documentTypeName: res.document_type && res.document_type.name,
		}) ||
		null
	);
};

export const hydrateReferralProgramDetail = (data) => {
	const res =
		(data && data.data && data.data.data && _.head(data.data.data)) ||
		undefined;
	return (
		(res && {
			insurancePackageID: _.get(res, 'insurance_package_id', ''),
			insurancePackageName: _.get(res, 'insurance_package_name', ''),
			allowedCountries: _.get(res, 'allowed_countries', ''),
			addressLine1:
				res.insurance_package_address &&
				_.get(res, ['insurance_package_address', 'address_line1'], ''),
			addressLine2:
				res.insurance_package_address &&
				_.get(res, ['insurance_package_address', 'address_line2'], ''),
			city:
				res.insurance_package_address &&
				_.get(res, ['insurance_package_address', 'city'], ''),
			state:
				res.insurance_package_address &&
				_.get(res, ['insurance_package_address', 'state'], ''),
			zipCode:
				res.insurance_package_address &&
				_.get(res, ['insurance_package_address', 'zip'], ''),
			isSelectedSpecialties:
				(res && res.is_selected_specialties) || false,
			partnerUUID: res && res.uuid,
			defaultProviders: res && res.default_provider,
			defaultSmsConsent:
				(res &&
					(res.send_sms_appointment_notification ||
						res.send_sms_referral_outreach ||
						res.send_ror_outreach_notifications)) ||
				false,
			externalProviderUsers: res && res.external_provider_users,
			schedulingPlatform: res?.scheduling_platform,
			isPhoneOnly: res?.is_phone_only,
			showMedicareQuestion: res?.show_medicare_question,
			requireShippingAddress: res?.require_shipping_address,
		}) ||
		null
	);
};

export const dehydrateBillingInfo = (data) => {
	return {
		insurance_package_address: {
			address_line1: data && data.address1,
			address_line2: data && data.address2,
			city: data && data.city,
			state: data && data.billingState,
			zip: data && data.zipCode,
		},
		insurance_package_id: data && data.athenaPackageID,
		insurance_package_name: data && data.athenaPackageName,
	};
};
