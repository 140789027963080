//React & Redux
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

//Styles
import './NotAvailableSlot.css';

class NotAvailableSlot extends Component {
	static propTypes = {
		event: PropTypes.object.isRequired,
	};

	render() {
		return (
			<div
				className={classnames('na-slot', {
					reserved: this.props.event.status === 'reserved',
				})}
			>
				<div className='na-hover'>
					<div className='na-text'></div>
				</div>
			</div>
		);
	}
}

export default NotAvailableSlot;
