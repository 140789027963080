import * as actionTypes from './actionTypes';

//Set filter within appData
export function setFilter(component, filterData) {
	return (dispatch) => {
		if (component && filterData)
			return dispatch(setAppDataFilter(component, filterData));
	};
}

const setAppDataFilter = (component, filterData) => {
	return {
		type: actionTypes.SET_APPDATA_FILTER,
		payload: filterData,
		component: component,
	};
};
