import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../assets/exit-alert.svg';

//UI Libraries
import RadioGroup from '../Common/controls/RadioGroup/RadioGroup';

//Other Libraries
import classNames from 'classnames';

//Styles
import './assign-modal.css';

const AssignModal = (props) => {
	const {
		closeModal,
		gcaList,
		selectAssignee,
		selectedAssignee,
		updateGCAAssignment,
	} = props;

	return (
		<div className='assign-modal-background'>
			<div className='assign-modal-container'>
				<div className='assign-modal-header'>
					<div className='assign-modal-header-title'>
						Assign Patient
					</div>
					<div className='assign-modal-close-x' onClick={closeModal}>
						<ReactSVG
							className='assign-modal-close-x-icon'
							src={xIcon}
						/>
					</div>
				</div>
				<div className='assign-modal-body'>
					<RadioGroup
						onChange={selectAssignee}
						options={gcaList}
						value={selectedAssignee[1] || null}
					/>
				</div>
				<div className='assign-modal-footer'>
					<div className='footer-button' onClick={closeModal}>
						Cancel
					</div>
					<div
						className={classNames('footer-button', {
							disabled: !selectedAssignee.length,
						})}
						onClick={updateGCAAssignment}
					>
						Assign
					</div>
				</div>
			</div>
		</div>
	);
};

export default AssignModal;
