//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import patientIcon from './../../../../../assets/person.svg';
import multipleVisitIcon from '../../../../../assets/multi-visit.svg';
import infoIcon from '../../../../../assets/more-info.svg';

//Lodash
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

//Utils
import {
	encounter_types,
	scp_encounter_sub_types_images,
	encounter_types_images,
	isMultipleVisit,
	isPrimaryVisit,
	isSecondaryVisitForDetail,
} from '../../../../../Utils/encounterUtils';
import {
	isSCPEncounterWithSubType,
	getConsultationSubType,
	getEncounterSpecificTime,
	displayEncounterTypeWithSubtype,
} from '../Common/utilsfunctions';

//Components
import Tooltip from '../../../../Common/Tooltip';

class AuditTrailPatientInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions
	componentDidMount() {
		const appointment = this.props.getBookedAppointment();
		const uuid = get(appointment, 'changed_by');
		if (uuid && !get(appointment, 'is_changed_by_patient')) {
			this.props.getCreatedByUser(uuid);
		}
	}

	componentWillUnmount() {
		this.props.resetUser();
	}

	getMultipleVisitType = (el) => {
		if (isMultipleVisit(el)) {
			if (isPrimaryVisit(el)) {
				return ' (Primary) ';
			} else if (isSecondaryVisitForDetail(el)) {
				return ' (Secondary) ';
			} else {
				return '';
			}
		} else {
			return '';
		}
	};

	render() {
		const encounter = get(this, 'props.encounter', {}) || {};
		const { email, role, date } = this.props.getScheduledBy();
		return (
			<div className='auditTrailPatientInfoContainer'>
				<div className='auditTrailPatientInfoRow'>
					<ReactSVG
						className='auditTrailPatientIcon auditTrailInfoIcon'
						src={patientIcon}
					/>
					<span style={{ textTransform: 'capitalize' }}>
						{get(this, 'props.patient.first_name', '' || '--')}{' '}
						{get(this, 'props.patient.last_name', '' || '--')}
					</span>
				</div>
				<div className='auditTrailPatientInfoRow'>
					{isSCPEncounterWithSubType(encounter) ? (
						<React.Fragment>
							<ReactSVG
								src={
									scp_encounter_sub_types_images[
										get(encounter, 'encounter_subtype', '')
									]
								}
								className='auditTrailPatientIcon auditTrailInfoIcon'
							/>
							<span
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<span>
									{displayEncounterTypeWithSubtype(encounter)}
								</span>
								<span className='auditTrailInfoSubtitle'>
									{getEncounterSpecificTime(
										get(encounter, 'date_of_service', ''),
										get(encounter, 'timezone', '')
									)}
									,{' '}
									{get(encounter, 'state_of_visit', '--') ||
										'--'}
								</span>
							</span>
						</React.Fragment>
					) : (
						<React.Fragment>
							{!isEmpty(this.getMultipleVisitType(encounter)) ? (
								<ReactSVG
									src={multipleVisitIcon}
									className='auditTrailPatientIcon auditTrailInfoIcon'
								/>
							) : (
								<ReactSVG
									src={
										encounter_types_images[
											get(encounter, 'type', '')
										]
									}
									className='auditTrailPatientIcon auditTrailInfoIcon'
								/>
							)}
							<span
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<span>
									{
										encounter_types[
											get(encounter, 'type', '')
										]
									}
									<span className='multivisit-type'>
										{this.getMultipleVisitType(encounter)}
									</span>
									{getConsultationSubType(encounter)}
								</span>
								<span className='auditTrailInfoSubtitle'>
									{getEncounterSpecificTime(
										get(encounter, 'date_of_service', ''),
										get(encounter, 'timezone', '')
									)}
									,{' '}
									{get(encounter, 'state_of_visit', '--') ||
										'--'}
								</span>
							</span>
						</React.Fragment>
					)}
				</div>
				{this.props.getBookedAppointment() && role && email && (
					<div className='auditTrailPatientInfoRow'>
						<ReactSVG
							className='auditTrailPatientIcon infoIcon auditTrailInfoIcon'
							src={infoIcon}
						/>
						<div className='auditTrailPatientInfoText inline-tooltip'>
							<div style={{ float: 'left' }}>
								{`Scheduled by:`}&nbsp;
							</div>
							<div style={{ float: 'left' }}>
								<Tooltip
									effect='dark'
									content={
										<span>
											{role}
											<br />
											{email}
										</span>
									}
									placement='top'
								>
									<span>{email}</span>
								</Tooltip>
								{','}
							</div>
							<div style={{ float: 'left' }}>
								{`on ${getEncounterSpecificTime(date)}`}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}
export default AuditTrailPatientInfo;
