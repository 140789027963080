//React & Redux
import React, { Fragment, useState, useEffect } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	imageDefault,
	providers_images,
	getUserImage,
} from '../../../../Utils/encounterUtils';

//UI Libraries
import { Layout, Input, Button, Tabs } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import CreateTestRequestEncntrInfo from './CreateTestRequestEncntrInfo';
import TestRequestInfoTab from './TestRequestInfoTab';

//Styles
import './test-order-notes.css';

const statusEnum = {
	approved: 1,
	awaiting_cc_submission: 1,
	sent_to_lab: 1,
	sample_not_submitted: 1,
	patient_not_responsive: 1,
	order_cancelled_by_patient: 1,
	order_cancelled_by_provider: 1,
	order_cancelled_by_lab: 1,
	results_ready: 1,
	results_entered: 1,
	result_positive_no_genes: 1,
	result_positive_with_genes: 1,
	result_negative: 1,
	awaiting_cc_submission: 1,
};

const NotesTestOrder = (props) => {
	const {
		handleDraftNotes,
		selectedICDCodes,
		draftNotes = [],
		noteList = [],
		ordering_physicianenums = [],
		sampletypeenums = [],
		patientbillingmethodenums = [],
		formdata = {},
		testorder = {},
		tabvalue = '',
		encounter = {},
		encounterschema = {},
		patientdetail = {},
		user = {},
	} = props;

	const [note, setNote] = useState('');
	const [isShowButtons, setIsShowButtons] = useState(false);

	useEffect(() => {
		if (!_.isEmpty(note)) {
			setIsShowButtons(true);
		} else {
			setIsShowButtons(false);
		}
	}, [note]);

	const handleChange = (value) => {
		setNote(value);
	};

	const addDraftNote = () => {
		const userFullName =
			_.get(user, ['first_name'], '') +
			' ' +
			_.get(user, ['last_name'], '');

		const addNoteObj = {
			author: userFullName,
			note_drafted_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
			note: note,
		};

		setNote('');
		setIsShowButtons(false);

		handleDraftNotes([addNoteObj, ...draftNotes]);
	};

	const clearDraftNote = () => {
		setNote('');
		setIsShowButtons(false);
	};

	const deleteDraftNote = (item) => {
		const _draftNotes = draftNotes;
		const draftNoteAfterDelete = _.filter(
			_draftNotes,
			function (note, index) {
				return index != item;
			}
		);
		handleDraftNotes(draftNoteAfterDelete);
	};

	return (
		<Fragment>
			<div className='test-order-edit-notes-wrapper'>
				<section className='edit-notes-fields-container'>
					<Layout.Row gutter='16'>
						<Layout.Col span='24'>
							<Input
								name='note'
								type='textarea'
								value={note}
								placeholder='Add a note'
								autosize={{ minRows: 1, maxRows: 4 }}
								className='txt-note'
								onChange={(value) => handleChange(value)}
							/>
						</Layout.Col>
					</Layout.Row>
					<Layout.Row
						gutter='16'
						className={isShowButtons ? 'show' : 'hidden'}
					>
						<Layout.Col span='24'>
							<Button
								className='btn-add-note'
								onClick={() => addDraftNote()}
							>
								Add Note
							</Button>
							<Button
								className='btn-clear-note'
								onClick={() => clearDraftNote()}
								type='text'
							>
								Clear
							</Button>
						</Layout.Col>
					</Layout.Row>
					<section className='notes-list-wrapper'>
						{noteList.map((note, index) => {
							return (
								<div className='notes-list-container'>
									{getUserImage(note)}
									<div className='note-content'>
										<span className='username-wrapper'>
											<span
												className={
													note.note_type === 'draft'
														? 'draft-username'
														: 'note-username'
												}
											>
												{note.author}{' '}
												{note.note_type === 'draft' && (
													<span>(Draft)</span>
												)}
											</span>
											{note.note_type === 'draft' && (
												<Button
													className='btn-delete-draft'
													onClick={() =>
														deleteDraftNote(index)
													}
													type='text'
												>
													Delete Draft
												</Button>
											)}
										</span>
										<span className='note-time'>
											{note.note_type === 'draft'
												? moment
														.utc(
															note.note_drafted_time
														)
														.local()
														.format(
															'MMM DD, YYYY hh:mm A'
														)
												: moment
														.utc(note.created_at)
														.local()
														.format(
															'MMM DD, YYYY hh:mm A'
														)}
										</span>
										<span
											className={
												note.note_type === 'draft'
													? 'draft-text'
													: 'note-text'
											}
										>
											{note.note}
										</span>
									</div>
								</div>
							);
						})}
					</section>
				</section>
				{_.get(testorder, ['localorderstatus'], null) in statusEnum ? (
					<Tabs
						key={tabvalue}
						activeName='testRequestInfoTab'
						className='test-order-encntr-testrequest-tabs-wrapper'
					>
						<Tabs.Pane label='Encounter' name='encntrInfoTab'>
							<CreateTestRequestEncntrInfo
								encounter={encounter}
								encounterschema={encounterschema}
								patientdetail={patientdetail}
							/>
						</Tabs.Pane>
						<Tabs.Pane
							label='Test Request'
							name='testRequestInfoTab'
						>
							<TestRequestInfoTab
								testorder={testorder}
								ordering_physicianenums={
									ordering_physicianenums
								}
								selectedICDCodes={selectedICDCodes}
								sampletypeenums={sampletypeenums}
								patientbillingmethodenums={
									patientbillingmethodenums
								}
								formdata={formdata}
							/>
						</Tabs.Pane>
					</Tabs>
				) : (
					<CreateTestRequestEncntrInfo
						encounter={encounter}
						encounterschema={encounterschema}
						patientdetail={patientdetail}
					/>
				)}
			</div>
		</Fragment>
	);
};

export default NotesTestOrder;
