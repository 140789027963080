//React & Redux
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

//Images
import ReactSVG from 'react-svg';
import dropdownIcon from './../../assets/dropdown.svg';

//Lodash
import _ from 'lodash';
import moment from 'moment';

//Utils
import {
	UTCToCurrentView,
	EncounterTabs,
	getFromEnumOrFormat,
} from '../../utils';

//Actions & Services
import {
	getdashboardencounters,
	resetEncounterList,
	exportEncounterList,
	getActiveGCAs,
	getGCAAssignments,
	assignCCOrGCA,
} from '../../actions/dashboard';
import { getProvidersList } from '../../actions/providers';
import { getProviderSpecialties } from '../../actions/providers';
import { getspecificencounterenum } from '../../actions/dashboard';
import { setFilter } from '../../actions/appData';

//UI Libraries
import { Loading, Dropdown, Checkbox } from 'gm-element-react';

//Components
import ProviderInfo from '../Common/ProviderInfo';
import MultiSelectCheckbox from './../Filter/MultiSelectCheckbox.js';
import ResetFilters from './../Filter/ResetFilters';
import { Table, Thead, Tbody, Tr, Th, Td } from '../Common/Table';
import TextWithTooltip from '../Common/TextWithTooltip';
import CircleBadge from '../Common/CircleBadge';
import LinkPopover from '../Common/Popovers/LinkPopover';

//Styles
import './visit-completion-status-unknown.css';

//Utils
import { isGCRole } from '../../Utils/permissionUtils';
import { formulateVisitProvidersEnum } from '../../Utils/dashboardUtils';

// Card name
const FILTER = 'appointment_status';

class VisitCompletionStatusUnknown extends Component {
	constructor(props) {
		super(props);

		const cardFilter = _.get(this, 'props.cardFilter', null) || {};
		const assigned_gca_uuid = _.get(cardFilter, 'assigned_gca_uuid', null);
		const isGCA = _.get(this, 'props.currentRole', null) === 'GCA';
		let assignedGCAUUID = null;
		if (isGCA && !assigned_gca_uuid) {
			assignedGCAUUID = [_.get(this, ['props', 'currentUserUUID'], null)];
		} else {
			assignedGCAUUID = assigned_gca_uuid || null;
		}
		this.defaultState = {
			offset: 0,
			order_type: 'asc',
			order_by: 'date_of_service',
			assigned_gca_uuid: assignedGCAUUID,
			visiting_providers: null,
			loading: false,
			selectedEncounters: {},
			limit: _.get(this, ['props', 'limit'], 25),
			intialLoaded: false,
		};
		this.state = { ...this.defaultState, ...cardFilter };

		this.getFilter = this.getFilter.bind(this);
		this.gcaReassignment = this.gcaReassignment.bind(this);
		this.handleResultsPerPageChange =
			this.handleResultsPerPageChange.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleSort = this.handleSort.bind(this);
		this.onSelectAllClick = this.onSelectAllClick.bind(this);
		this.exportEncounterList = this.exportEncounterList.bind(this);
	}

	componentDidMount() {
		this.props.getActiveGCAList();
		this.props.callVisitProviderEnum({
			type: 'visit',
			field_name: 'visit_provider',
		});
		this.props.callLabEnum({
			type: 'lab_test_authorization',
			field_name: 'ordering_physician',
		});
		this.props.getAllProviderSpecialties();
		this.props.getAllProviders();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			_.get(prevProps, 'dashboardEncountersLoading', false) &&
			!_.get(this, 'props.dashboardEncountersLoading', false)
		) {
			if (!_.get(this, 'props.dashboardEncountersError', false))
				this.props.setCardFilter(this.getFilter());
		}
		if (
			_.get(prevProps, ['providersLoading'], false) &&
			!_.get(this, ['props', 'providersLoading'], false) &&
			!_.get(this, ['state', 'intialLoaded'], false)
		) {
			let visiting_providers = null;
			const visitingProviderFilter = _.get(
				this,
				['props', 'cardFilter', 'visiting_providers'],
				[]
			);
			if (this.userIsVseeProvider() && !visitingProviderFilter) {
				visiting_providers = [
					_.get(this, ['props', 'currentUserUUID'], null),
				];
			} else {
				visiting_providers = visitingProviderFilter || null;
			}
			this.setState({ visiting_providers, intialLoaded: true }, () => {
				this.getDashboardAndGCAAssignmentList();
			});
		}
	}
	componentWillUnmount() {
		this.props.resetList();
	}

	getFilter() {
		return {
			offset: _.get(this, 'state.offset', null),
			order_type: _.get(this, 'state.order_type', null),
			order_by: _.get(this, 'state.order_by', null),
			assigned_gca_uuid: _.get(this, 'state.assigned_gca_uuid', null),
			visiting_providers: _.get(
				this,
				['state', 'visiting_providers'],
				null
			),
		};
	}

	getDashboardAndGCAAssignmentList = () => {
		const {
			offset,
			order_type,
			order_by,
			assigned_gca_uuid,
			visiting_providers,
		} = this.state;
		const { limit } = this.props;

		this.props.getDashboard({
			filter: FILTER,
			offset,
			order_type,
			order_by,
			assigned_gca_uuid,
			limit,
			visiting_providers,
		});
		this.props.getGCAAssignmentList({ filter: FILTER });
	};

	renderAssignDropdown() {
		const { selectedEncounters } = this.state;

		if (_.isEmpty(selectedEncounters)) return this.renderDisabledButton();

		return this.renderDropdownButton();
	}

	gcaReassignment(assigned_gca_uuid) {
		const { selectedEncounters } = this.state;
		const patients = new Set();

		for (const encounterID in selectedEncounters) {
			patients.add(selectedEncounters[encounterID].user_uuid);
		}

		if (!_.isEmpty(selectedEncounters)) {
			this.setState({
				loading: true,
			});
			this.props
				.changeGCAAssignment({
					assigned_gca_uuid,
					patients: Array(...patients),
				})
				.then((res) => {
					this.setState(
						{
							selectedEncounters: {},
							loading: false,
						},
						() => this.getDashboardAndGCAAssignmentList()
					);
				})
				.catch((err) => {
					this.setState({
						loading: false,
					});
				});
		}
	}

	renderOptions() {
		const activeGCAs = _.get(this, 'props.gcaList', []) || [];

		if (Array.isArray(activeGCAs)) {
			const listItems = activeGCAs
				.slice()
				.sort((a, b) => {
					return _.get(a, 'display_name', null) >
						_.get(b, 'display_name', '')
						? 1
						: -1;
				})
				.map((el) => {
					return (
						<Dropdown.Item
							command={_.get(el, 'key', undefined)}
							key={_.get(el, 'key', undefined)}
						>
							{_.get(el, 'display_name', '--') || '--'}
						</Dropdown.Item>
					);
				})
				.concat(
					<Dropdown.Item command={null} key='unassigned'>
						Unassigned
					</Dropdown.Item>
				);
			return (
				<div className='filterDropdownDashboardList'>{listItems}</div>
			);
		}

		return <div />;
	}

	renderDropdownButton() {
		const { selectedEncounters } = this.state;

		return (
			<Dropdown
				menu={<Dropdown.Menu>{this.renderOptions()}</Dropdown.Menu>}
				onCommand={this.gcaReassignment}
				trigger='click'
				className='filterDropdownElement filterDropdownDashboard'
			>
				<div className='filterDropdownButton filterDropdownButtonDashboard'>
					Assign to ({Object.keys(selectedEncounters).length})
					<ReactSVG
						src={dropdownIcon}
						className='filterDropdownButtonIcon'
					/>
				</div>
			</Dropdown>
		);
	}

	renderDisabledButton() {
		return (
			<div className='filterDropdownButton filterDropdownButtonDisabled filterDropdownButtonDashboard'>
				Assign to
				<ReactSVG
					src={dropdownIcon}
					className='filterDropdownButtonIcon'
				/>
			</div>
		);
	}

	handleResultsPerPageChange(limit) {
		this.setState({ offset: 0, limit }, () =>
			this.getDashboardAndGCAAssignmentList()
		);
	}

	handlePageChange(e, offset) {
		this.setState({ offset }, () =>
			this.getDashboardAndGCAAssignmentList()
		);
	}

	userIsVseeProvider = () => {
		const providers = _.get(this, ['props', 'providers'], []);
		const isGC = isGCRole(_.get(this, ['props', 'currentRole'], ''));
		const currentUserUUID = _.get(this, ['props', 'currentUserUUID'], null);
		const filterProvider = providers.find((a) => a.uuid == currentUserUUID);
		if (isGC & filterProvider) {
			return true;
		}
		return false;
	};

	handleResetFilters = () => {
		this.setState(
			{
				...this.defaultState,
				assigned_gca_uuid:
					_.get(this, 'props.currentRole', null) === 'GCA'
						? [_.get(this, ['props', 'currentUserUUID'], null)]
						: null,
				visiting_providers: this.userIsVseeProvider()
					? [_.get(this, ['props', 'currentUserUUID'], null)]
					: null,
			},
			() => this.getDashboardAndGCAAssignmentList()
		);
	};

	handleViewAllAssignments = () => {
		this.setState(
			{
				offset: 0,
				selectedEncounters: {},
				assigned_gca_uuid: [],
			},
			() => this.getDashboardAndGCAAssignmentList()
		);
	};

	handleSort(order_by, order_type) {
		this.setState(
			{
				order_by,
				order_type,
				offset: 0,
				selectedEncounters: {},
			},
			() => {
				this.getDashboardAndGCAAssignmentList();
			}
		);
	}

	handleProviderChange(values) {
		const providersLength = _.get(
			this,
			['props', 'providers', 'length'],
			null
		);
		let providersValues = [...values];

		if (values.length === providersLength) providersValues = [];
		this.setState(
			{
				visiting_providers: providersValues,
				offset: 0,
				selectedEncounters: {},
			},
			() => this.getDashboardAndGCAAssignmentList()
		);
	}

	handleGCAAssignmentChange(values) {
		const gcaAssignmentsLength = _.get(
			this,
			['props', 'gcaAssignments', 'length'],
			null
		);
		let gcaValues = [...values];

		if (values.length === gcaAssignmentsLength) gcaValues = [];
		this.setState(
			{
				assigned_gca_uuid: gcaValues,
				offset: 0,
				selectedEncounters: {},
			},
			() => this.getDashboardAndGCAAssignmentList()
		);
	}

	getProviderInfo(type, ordering_physician, visit_provider) {
		let providerenum = [];
		let providerName = '';

		switch (type) {
			case 'lab_test_authorization':
				providerName = ordering_physician;
				providerenum = _.get(this, ['props', 'orderingphysician'], {});
				return { providerenum, providerName };
			case 'cc-intake':
			case 'group-session':
			case 'visit':
				providerName = visit_provider;
				providerenum = _.get(
					this,
					['props', 'visitProvider', 'data', 'visit_provider'],
					[]
				);
				return { providerenum, providerName };
			default:
				return { providerenum, providerName };
		}
	}

	filterAssignments(isDeleted) {
		const assignments = _.get(this, ['props', 'gcaAssignments'], []);
		if (Array.isArray(assignments)) {
			return assignments.filter((el) => {
				return (
					_.get(el, ['is_deleted'], null) === isDeleted &&
					_.get(el, ['uuid'], null)
				);
			});
		}

		return [];
	}

	formulateGCAAssignmentEnum() {
		const gcaAssignments = _.get(this, ['props', 'gcaAssignments'], []);
		let gcaEnum = [];

		if (_.isEmpty(gcaAssignments)) return gcaEnum;

		const unassignedObj = _.find(
			gcaAssignments,
			(o) => o.name === 'Unassigned'
		);

		gcaEnum.push({
			display_name: `Unassigned (${unassignedObj.count})`,
			key: 'null',
		});
		gcaEnum.push({ groupingLabel: 'ACTIVE' });

		const activeAssignments = this.filterAssignments(false).map((el) => {
			return {
				display_name: `${_.get(el, ['name'], '--')} (${_.get(
					el,
					['count'],
					0
				)})`,
				key: _.get(el, ['uuid'], null),
			};
		});
		gcaEnum = gcaEnum.concat(activeAssignments);
		gcaEnum.push({ groupingLabel: 'INACTIVE' });

		const inactiveAssignments = this.filterAssignments(true).map((el) => {
			return {
				display_name: `${_.get(el, ['name'], '--')} (${_.get(
					el,
					['count'],
					0
				)})`,
				key: _.get(el, ['uuid'], null),
			};
		});
		gcaEnum = gcaEnum.concat(inactiveAssignments);

		return gcaEnum;
	}

	getEncounterSpecificTime(dos, timezone, type) {
		const tz = type == 'research-data' ? 'America/New_York' : timezone;
		return UTCToCurrentView(
			moment,
			moment(dos),
			tz,
			null,
			'MMM D, YYYY hh:mmA z'
		);
	}

	onSelectAllClick(isChecked) {
		if (isChecked) isChecked = !this.isSomeChecked();
		const { selectedEncounters } = this.state;
		const {
			dashboardEncounters: { data },
		} = this.props;
		const dataMap = {};

		for (const enc of data) {
			delete selectedEncounters[enc.uuid];
			dataMap[enc.uuid] = { ...enc };
		}

		if (isChecked) {
			this.setState({
				selectedEncounters: { ...selectedEncounters, ...dataMap },
			});
		} else {
			this.setState({
				selectedEncounters: { ...selectedEncounters },
			});
		}
	}

	onSelectRowClick(isChecked, encounter) {
		const { selectedEncounters } = this.state;

		if (isChecked) {
			this.setState({
				selectedEncounters: {
					...selectedEncounters,
					[encounter.uuid]: encounter,
				},
			});
		} else {
			delete selectedEncounters[encounter.uuid];
			this.setState({
				selectedEncounters: { ...selectedEncounters },
			});
		}
	}

	isAllChecked() {
		const { selectedEncounters } = this.state;
		const {
			dashboardEncounters: { data, total },
		} = this.props;

		for (const { uuid } of data) {
			if (!selectedEncounters[uuid]) return false;
		}

		return total > 0;
	}

	isSomeChecked() {
		const { selectedEncounters } = this.state;
		const {
			dashboardEncounters: { data, end },
		} = this.props;
		const filterData = _.filter(data, (obj) => {
			return selectedEncounters[obj.uuid];
		});

		return filterData.length > 0 && filterData.length !== end + 1;
	}

	redirectToEncounterDetail = (enc_uuid, patient_uuid, type, encounter) => {
		this.props.history.push({
			pathname:
				'/app/patientdetail/' +
				patient_uuid +
				'/0/' +
				EncounterTabs.DETAIL +
				'/' +
				enc_uuid +
				'?type=' +
				type,
			state: {
				breadcrumbs: [
					{
						location: FILTER,
						offset: _.get(this, ['state', 'offset'], 0),
						order_by: _.get(this, ['state', 'order_by']),
						order_type: _.get(this, ['state', 'order_type']),
						key: 'dashboard_card',
						assigned_gca_uuid: _.get(
							this,
							['state', 'assigned_gca_uuid'],
							null
						),
					},
					{
						location: 'Encounter Detail',
						patientFullName:
							_.get(encounter, ['patient', 'first_name'], '') +
							' ' +
							_.get(encounter, ['patient', 'last_name'], ''),
					},
				],
			},
		});
	};

	exportEncounterList() {
		const gcaUuid = _.get(this, 'state.assigned_gca_uuid', null);
		let assignedgcaUuid = null;
		if (gcaUuid && _.get(gcaUuid, 'length', 0) > 0) {
			if (gcaUuid === 'unassigned') {
				assignedgcaUuid = null;
			} else {
				if (Array.isArray(gcaUuid))
					assignedgcaUuid = gcaUuid
						.map((el) => (el === null ? 'null' : el))
						.join(',');
				assignedgcaUuid = gcaUuid;
			}
		}
		const payload = {
			filter: FILTER,
			order_type: _.get(this, ['state', 'order_type'], null),
			order_by: _.get(this, ['state', 'order_by'], null),
			assigned_gca_uuid: assignedgcaUuid,
			visiting_providers: _.get(
				this,
				['state', 'visiting_providers'],
				null
			),
		};

		this.setState({
			loading: true,
		});
		this.props
			.exportList(payload)
			.then((res) => {
				this.props.enqueueSnackbar('Report is sent to your email id.', {
					variant: 'info',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				this.setState({
					loading: false,
				});
			})
			.catch((err) => {
				this.props.enqueueSnackbar(
					'Something went wrong. Please try again later.',
					{
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
				this.setState({
					loading: false,
				});
			});
	}

	getGCADisplayName = (assigned_gca_uuid) => {
		const obj = (_.get(this, 'props.gcaList', []) || []).find(
			(el) => el && el.key && el.key == assigned_gca_uuid
		);
		return _.get(obj, 'display_name', '');
	};

	redirectToEncounterDetailURL = (enc_uuid, patient_uuid, type) => {
		return (
			'/app/patientdetail/' +
			patient_uuid +
			'/0/' +
			EncounterTabs.DETAIL +
			'/' +
			enc_uuid +
			'?type=' +
			type
		);
	};

	render() {
		const { data, end, has_next, has_prev, limit, offset, start, total } =
			_.get(this, ['props', 'dashboardEncounters'], {});
		const pagination = {
			end,
			has_next,
			has_prev,
			limit,
			offset,
			start,
			total,
		};
		const sortBy = {
			key: _.get(this, ['state', 'order_by'], ''),
			value: _.get(this, ['state', 'order_type'], ''),
		};
		const { selectedEncounters } = this.state;
		const { currentUserUUID } = this.props;
		const providerSpecialties =
			_.get(this, 'props.providerSpecialties', null) || [];
		return (
			<div className='visit-completion-status-unknown-wrapper'>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						minWidth: 'inherit',
					}}
				>
					<div style={{ display: 'flex' }}>
						{this.renderAssignDropdown()}
						<div
							className={
								total > 0 ? 'export_enabled' : 'export_disabled'
							}
							variant='contained'
							color='primary'
							onClick={total ? this.exportEncounterList : null}
						>
							{`Export (${total || 0})`}
						</div>
					</div>
					<div style={{ display: 'flex' }}>
						<ResetFilters
							handleResetFilters={this.handleResetFilters}
						/>

						<MultiSelectCheckbox
							data={this.formulateGCAAssignmentEnum()}
							selected={
								_.get(
									this,
									['state', 'assigned_gca_uuid'],
									[]
								) || []
							}
							title={'Assignments'}
							submit={(values) =>
								this.handleGCAAssignmentChange(values)
							}
							majorPillTitle={'Assignments'}
							defaultValues={[]}
							searchBar={true}
							previewTab={true}
							alwaysNumber={false}
							zeroToAllPillTitle={true}
						/>
						<MultiSelectCheckbox
							data={formulateVisitProvidersEnum(
								_.get(this, ['props', 'providers'])
							)}
							selected={
								_.get(
									this,
									['state', 'visiting_providers'],
									[]
								) || []
							}
							title={'Provider'}
							submit={(values) =>
								this.handleProviderChange(values)
							}
							majorPillTitle={'Provider'}
							defaultValues={[]}
							searchBar={true}
							previewTab={true}
							alwaysNumber={false}
							zeroToAllPillTitle={true}
						/>
					</div>
				</div>
				<div>
					{_.get(
						this,
						['props', 'dashboardEncountersLoading'],
						false
					) ||
					!_.isArray(data) ||
					_.get(this, ['state', 'loading'], false) ? (
						<Loading loading={true} className='list-loading' />
					) : (
						<Table
							loading={
								_.get(
									this,
									['props', 'dashboardEncountersLoading'],
									false
								) && _.isArray(data)
							}
							backToTop
							resultsPerPage={[25, 50, 100]}
							handleResultsPerPageChange={
								this.handleResultsPerPageChange
							}
							pagination={pagination}
							handlePageChange={this.handlePageChange}
							hasData={!_.isEmpty(data)}
							handleSort={this.handleSort}
							sortBy={sortBy}
							noResultsFound={
								<Fragment>
									<div>No Results Found</div>
									<p>
										Try adjusting your search or filter
										options to find
										<br />
										what you’re looking for.
									</p>
									<span
										onClick={() =>
											this.handleViewAllAssignments()
										}
									>
										View All Assignments
									</span>
									<span className='separator' />
									<span
										onClick={() =>
											this.handleResetFilters()
										}
									>
										Reset Filters
									</span>
								</Fragment>
							}
						>
							<Thead>
								<Tr>
									<Th>
										<Checkbox
											checked={this.isAllChecked()}
											indeterminate={this.isSomeChecked()}
											onChange={this.onSelectAllClick}
											inputProps={{
												'aria-label':
													'Select all encounters',
											}}
										/>
									</Th>
									<Th
										sortable={{
											first_name: {
												display_name: 'first name',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
											email: {
												display_name: 'email',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										Patient
									</Th>
									<Th
										sortable={{
											referral_program: {
												display_name:
													'referral program',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										Referral Program
									</Th>
									<Th
										sortable={{
											consultation_type: {
												display_name:
													'consultation type',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
											date_of_service: {
												display_name:
													'date & time of service',
												sortBy: {
													asc: 'Oldest First',
													desc: 'Newest First',
												},
											},
										}}
									>
										Encounter
									</Th>
									<Th
										sortable={{
											provider_indicated_specialty: {
												display_name:
													'provider indicated specialty',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										Provider Indicated Specialty
									</Th>
									<Th
										sortable={{
											visit_provider: {
												display_name: 'provider',
												sortBy: {
													asc: 'A to Z',
													desc: 'Z to A',
												},
											},
										}}
									>
										Provider
									</Th>
									<Th />
								</Tr>
							</Thead>
							<Tbody>
								{data.map((encounter) => {
									const {
										uuid,
										user_uuid,
										patient: {
											first_name,
											last_name,
											email,
											referral_program,
											high_priority,
											has_guardian,
											assigned_gca_uuid,
										},
										date_of_service,
										timezone,
										consultation_type,
										type,
										ordering_physician,
										visit_provider,
										provider_indicated_specialty,
									} = encounter;
									const nameMaxWidth =
										high_priority && has_guardian
											? '216px'
											: high_priority || has_guardian
											? '240px'
											: '264px';
									const fullName =
										first_name || last_name
											? first_name + ' ' + last_name
											: '--';
									return (
										<Tr
											key={uuid}
											highlight={
												!_.isEmpty(
													selectedEncounters[
														encounter.uuid
													]
												)
											}
										>
											<Td minWidth='56px' maxWidth='56px'>
												<Checkbox
													checked={
														!_.isEmpty(
															selectedEncounters[
																encounter.uuid
															]
														)
													}
													onChange={(isChecked) =>
														this.onSelectRowClick(
															isChecked,
															encounter
														)
													}
													inputProps={{
														'aria-label': uuid,
													}}
												/>
											</Td>
											<Td maxWidth='296px'>
												<p style={{ display: 'flex' }}>
													<span>
														<LinkPopover
															overflow={true}
															onClick={() =>
																this.redirectToEncounterDetail(
																	uuid,
																	user_uuid,
																	type,
																	encounter
																)
															}
															className='capitalize hover'
															maxWidth={
																nameMaxWidth
															}
															linkTo={this.redirectToEncounterDetailURL(
																uuid,
																user_uuid,
																type,
																encounter
															)}
															options={{
																text: true,
																link: true,
																linkOptions: {
																	text: fullName,
																	style: {
																		textTransform:
																			'capitalize',
																	},
																},
															}}
														>
															{fullName}
														</LinkPopover>
													</span>
													{high_priority && (
														<span className='high-priority' />
													)}
													{has_guardian && (
														<span className='has-guardian' />
													)}
												</p>
												<TextWithTooltip
													className='sub'
													maxWidth='264px'
												>
													{email || '--'}
												</TextWithTooltip>
											</Td>
											<Td maxWidth='224px'>
												{referral_program || '--'}
											</Td>
											<Td maxWidth='224px'>
												<p>
													<TextWithTooltip
														className='capitalize'
														maxWidth='192px'
													>
														{consultation_type ||
															'--'}
													</TextWithTooltip>
												</p>
												<p>
													<TextWithTooltip
														className='sub'
														maxWidth='192px'
													>
														{this.getEncounterSpecificTime(
															date_of_service,
															timezone,
															type
														) || '--'}
													</TextWithTooltip>
												</p>
											</Td>
											<Td maxWidth='224px'>
												{getFromEnumOrFormat(
													providerSpecialties,
													provider_indicated_specialty,
													'name',
													'display_name'
												)}
											</Td>
											<Td maxWidth='224px'>
												<ProviderInfo
													fullName={true}
													{...this.getProviderInfo(
														type,
														ordering_physician,
														visit_provider
													)}
												/>
											</Td>
											<Td minWidth='56px'>
												<CircleBadge
													displayName={this.getGCADisplayName(
														assigned_gca_uuid
													)}
													isActive={
														currentUserUUID ==
														assigned_gca_uuid
													}
												/>
											</Td>
										</Tr>
									);
								})}
							</Tbody>
						</Table>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		dashboardEncounters: state.dashboardencounters.dashboardencounters,
		dashboardEncountersLoading:
			state.dashboardencounters.dashboardencountersLoading,
		dashboardEncountersError:
			state.dashboardencounters.dashboardencountersError,
		gcaList: state.gcaList.gcaList,
		gcaAssignmentsLoading: state.gcaList.gcaAssignmentsLoading,
		gcaAssignments: state.gcaList.gcaAssignments,
		gcaAssignmentsError: state.gcaList.gcaAssignmentsError,
		providers: _.get(state, ['providers', 'data'], []),
		providersLoading: _.get(state, ['providers', 'loading'], false),
		assigningCCOrGCA: state.assignCcGca.assigningCCOrGCA,
		assignedCCOrGCA: state.assignCcGca.assignedCCOrGCA,
		assignedCCOrGCAError: state.assignCcGca.assignedCCOrGCAError,
		currentRole: _.get(state, 'me.activerole.name', null),
		currentUserUUID: _.get(state, 'me.user.uuid', null),
		visitProvider: state.dashboardencounters.visitproviderenum,
		orderingphysician: _.get(
			state,
			'dashboardencounters.orderingphysician.data.ordering_physician',
			{}
		),
		limit: state.uiConfig.table.resultsPerPage,
		cardFilter:
			_.get(state, 'appData.visitCompletionStatusUnknownCard', null) ||
			{},
		gettingProviderSpecialties: _.get(
			state,
			'providerEnums.gettingProviderSpecialties',
			false
		),
		providerSpecialties:
			_.get(state, 'providerEnums.providerSpecialties', null) || [],
		providerSpecialtiesError: _.get(
			state,
			'providerEnums.providerSpecialtiesError',
			null
		),
	};
};

const mapDispatchToProps = (dispatch) => ({
	getDashboard: (filter) => dispatch(getdashboardencounters(filter)),
	callVisitProviderEnum: (data) => dispatch(getspecificencounterenum(data)),
	callLabEnum: (data) => dispatch(getspecificencounterenum(data)),
	exportList: (data) => dispatch(exportEncounterList(data)),
	resetList: () => dispatch(resetEncounterList()),
	getActiveGCAList: () => dispatch(getActiveGCAs()),
	getGCAAssignmentList: (data) => dispatch(getGCAAssignments(data)),
	changeGCAAssignment: (data) => dispatch(assignCCOrGCA(data)),
	setCardFilter: (data) =>
		dispatch(setFilter('visitCompletionStatusUnknownCard', data)),
	getAllProviderSpecialties: () => dispatch(getProviderSpecialties()),
	getAllProviders: () =>
		dispatch(
			getProvidersList({ pioneer_only: true, order_by: 'first_name' })
		),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(VisitCompletionStatusUnknown);
