//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import timeIcon from './../../../../../assets/time-icon.svg';

class AuditTrailHistoryInitialPrompt extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		return (
			<div className='auditTrailHistoryInitialPromptContainer'>
				<ReactSVG src={timeIcon} />
				<div className='auditTrailHistoryInitialPromptMajor'>
					Select A Field
				</div>
				<div className='auditTrailHistoryInitialPromptMinor'>
					Select a field above to view its audit trail.
				</div>
			</div>
		);
	}
}

export default AuditTrailHistoryInitialPrompt;
