import React from 'react';

//UI Libraries
import RadioGroup from '../../Common/controls/RadioGroup/RadioGroup';

const MultiSelectRadioFilter = (props) => {
	const {
		filterKey,
		filterName = '',
		filterOptions,
		handleChangeList,
	} = props;

	return (
		<div className='radio-filter'>
			<span>{filterName}</span>
			<RadioGroup
				onChange={handleChangeList}
				options={filterOptions}
				value={filterKey}
			/>
		</div>
	);
};

export default MultiSelectRadioFilter;
