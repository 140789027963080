//React & Redux
import React, { useEffect, useRef, useState } from 'react';

//Components
import Textarea from '../../Common/controls/Textarea/Textarea';
import RadioButtonGroup from '../../Common/controls/RadioButtonGroup.js';

//Hooks
import { usePrevious } from '../../../hooks/usePrevious';

const AddNote = (props) => {
	const {
		enqueueSnackbar,
		postNoteForPatient,
		submitNoteError,
		submitNoteLoading,
		submitNoteSuccess,
		currentNoteTypeAdd,
		setCurrentNoteTypeAdd,
		currentNoteTypeView,
		notesEnum,
	} = props;

	const [enteredNote, setEnteredNote] = useState('');
	const [error, setError] = useState('');
	const [isAddNoteExpanded, setIsAddNoteExpanded] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);

	const noteTextareaRef = useRef();

	const prevSubmitNoteLoading = usePrevious(submitNoteLoading);

	useEffect(() => {
		if (prevSubmitNoteLoading && !submitNoteLoading) {
			if (submitNoteSuccess) {
				setEnteredNote('');
				setError('');
				setIsAddNoteExpanded(false);
				setIsDisabled(false);
				enqueueSnackbar('Note added successfully', {
					variant: 'success',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
			} else if (submitNoteError) {
				setIsDisabled(false);
				enqueueSnackbar(
					submitNoteError.response?.data?.message ||
						'Error submitting note',
					{
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
			}
		}
	}, [submitNoteError, submitNoteLoading, submitNoteSuccess]);

	const expandAddNote = (e) => {
		if (!isAddNoteExpanded) setCurrentNoteTypeAdd(currentNoteTypeView);
		setIsAddNoteExpanded(true);
		e.target.rows = 3;
	};

	const handleCommentChange = (val) => {
		setEnteredNote(val);
	};

	const addNoteForPatient = () => {
		if (typeof enteredNote === 'string') {
			const noteToSubmit = enteredNote.trim();
			if (noteToSubmit.length === 0) {
				setError('Please enter note');
			} else {
				setIsDisabled(true);
				postNoteForPatient(noteToSubmit, currentNoteTypeAdd);
				noteTextareaRef.current.refs.textarea.style.height = null;
			}
		}
	};

	const cancelAddNote = () => {
		setEnteredNote('');
		setError('');
		setIsAddNoteExpanded(!isAddNoteExpanded);
		noteTextareaRef.current.refs.textarea.style.height = null;
	};

	const radioGroupData = () => {
		return {
			internalKey: 'key',
			displayKey: 'display_name',
			data: notesEnum,
			currentValue: currentNoteTypeAdd,
			onChangeFunc: setCurrentNoteTypeAdd,
			rowVariant: true,
		};
	};

	const containerClassName =
		'add-notes-container' +
		(isAddNoteExpanded ? ' add-notes-container-expanded' : '');

	return (
		<div className={containerClassName}>
			<Textarea
				name='txt-add-comment'
				id='txt-add-comment'
				autosize={true}
				className={
					isAddNoteExpanded
						? 'expand-add-comment'
						: 'collapse-add-comment'
				}
				placeholder='Write a note...'
				value={enteredNote}
				onChange={handleCommentChange}
				onClick={expandAddNote}
				onFocus={expandAddNote}
				ref={noteTextareaRef}
			/>
			<div className='note-type-button-row'>
				<div className='note-type-options'>
					{isAddNoteExpanded && (
						<RadioButtonGroup {...radioGroupData()} />
					)}
				</div>
				<div className='note-buttons'>
					<button
						type='button'
						className={
							'btn-cancel-comment ' +
							(isAddNoteExpanded ? 'show' : 'hidden')
						}
						onClick={cancelAddNote}
					>
						Cancel
					</button>
					<button
						type='button'
						className={isAddNoteExpanded ? 'show' : 'hidden'}
						id='btn-add-comment'
						disabled={isDisabled}
						onClick={addNoteForPatient}
					>
						Submit
					</button>
				</div>
			</div>

			<p className='errortext errp notes-error' id='component-error-text'>
				{error}
			</p>
		</div>
	);
};

export default AddNote;
