import * as actionTypes from '../actions/actionTypes';

const defaultAddNucleotideRepeatVariantFinding = {
	addNucleotideRepeatVariantFinding: {},
	addNucleotideRepeatVariantFindingLoading: false,
	addNucleotideRepeatVariantFindingError: null,
};

export const addNucleotideRepeatVariantFinding = (
	state = defaultAddNucleotideRepeatVariantFinding,
	action = {}
) => {
	switch (action.type) {
		case actionTypes.ADD_NUCLEOTIDEREPEATVARIANT_FINDING_LOADING:
			return {
				...state,
				addNucleotideRepeatVariantFinding: {},
				addNucleotideRepeatVariantFindingLoading: true,
				addNucleotideRepeatVariantFindingError: null,
			};

		case actionTypes.ADD_NUCLEOTIDEREPEATVARIANT_FINDING_SUCCESS:
			return {
				...state,
				addNucleotideRepeatVariantFinding: action.payload,
				addNucleotideRepeatVariantFindingLoading: false,
				addNucleotideRepeatVariantFindingError: null,
			};

		case actionTypes.ADD_NUCLEOTIDEREPEATVARIANT_FINDING_ERROR:
			return {
				...state,
				addNucleotideRepeatVariantFinding: {},
				addNucleotideRepeatVariantFindingLoading: false,
				addNucleotideRepeatVariantFindingError: action.payload,
			};

		default:
			return state;
	}
};
