import React from 'react';

//Images
import ReactSVG from 'react-svg';
import contactsIcon from './../../../../../assets/contacts.svg';
import stethoscopeIcon from './../../../../../assets/icons/stethoscope.svg';

//Lodash
import { isEmpty, isNil, join, map } from 'lodash';

//Utils
import { selectedExtProviderRoles } from '../../../../../utils';
import { modality } from '../../../../../Utils/documentUtils';

//Components
import DocumentNotice from '../DocumentNotice';

const ReviewAndConfirm = (props) => {
	const {
		document,
		document: {
			document_subtype,
			document_type,
			doc_release_to_ext_ehr = false,
		},
		formdata = {},
		getEncounterProvider,
		getPatientProvider,
		partnerName = '',
		patientName = '',
		patientTag = '',
	} = props;

	const {
		release_to_patient: releaseToPatient = false,
		release_to_partner: releaseToPartner = false,
		selectedEncounterProviders = [],
		selectedPatientProviders = [],
		release_to_external_EHR = false,
	} = formdata;

	const isReleaseToExtProvider =
		!isEmpty(selectedEncounterProviders) ||
		!isEmpty(selectedPatientProviders);
	const documentType = document_type?.name || '';
	const documentSubtype = document_subtype?.name || '';
	const both = releaseToPatient && isReleaseToExtProvider;
	const propsDocReleaseToExtEHR = doc_release_to_ext_ehr;
	const stateReleaseToExternalEHR = release_to_external_EHR;

	const capType =
		documentSubtype === 'visit_summary'
			? 'Visit CAP'
			: documentSubtype === 'results_summary'
			? 'Result CAP'
			: '';

	return (
		<div className='rncContainer'>
			<div className='body-section'>
				<DocumentNotice
					document={document}
					icon='warning'
					message={`The related encounter’s ${capType} ${
						releaseToPatient ? 'Sent to patient' : ''
					} ${both ? '&' : ''} ${both ? capType : ''} ${
						isReleaseToExtProvider ? 'Sent to PCP' : ''
					} ${both ? 'dates' : 'date'} will be updated accordingly.`}
					visible={
						documentType == 'visit_documentation' &&
						['results_summary', 'visit_summary'].includes(
							documentSubtype
						)
					}
				/>
				{releaseToPatient && (
					<>
						<div className='releaseDocinputcontainer'>
							<div className='releaseDocInputHeaderContainer'>
								<div className='releaseDocInputHeader'>
									Patient or Guardian
								</div>
							</div>
							<div className='releaseDocValueContainer'>
								<div className='row-container'>
									<div className='icon'>
										<ReactSVG
											src={contactsIcon}
											style={{
												height: '24px',
												width: '24px',
											}}
										/>
									</div>
									<div className='right-side-container'>
										<div className='full-label-container'>
											<div className='flex-container'>
												<div className='lable-container'>
													<div className='text-bold-info'>
														{patientName}
													</div>
													<div className='text-medium-info'>
														Release via Portal
													</div>
												</div>
												<div className='extra-info-container'>
													<span className='extra-info'>
														{patientTag}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
				{releaseToPartner && (
					<div className='releaseDocinputcontainer'>
						<div className='releaseDocInputHeaderContainer'>
							<div className='releaseDocInputHeader'>
								Referral Program
							</div>
						</div>
						<div className='releaseDocValueContainer'>
							<div className='row-container'>
								<div className='icon'>
									<ReactSVG
										src={contactsIcon}
										style={{
											height: '24px',
											width: '24px',
										}}
									/>
								</div>
								<div className='right-side-container'>
									<div className='full-label-container'>
										<div className='flex-container'>
											<div className='lable-container'>
												<div className='text-bold-info'>
													{partnerName}
												</div>
												<div className='text-medium-info'>
													Release via API
												</div>
											</div>
											<div className='extra-info-container'></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>

			<div className='body-section'>
				{!isEmpty(selectedEncounterProviders) && (
					<div className='releaseDocinputcontainer'>
						<div className='releaseDocInputHeaderContainer'>
							<div className='releaseDocInputHeader'>
								External provider linked to encounter
							</div>
						</div>
						{map(
							selectedEncounterProviders,
							(selectedProvider, uuid) => {
								const provider =
									getEncounterProvider(uuid) || {};
								const medium = selectedProvider?.medium;
								const providerValue =
									provider && provider[medium];
								const formValue = selectedProvider?.value || '';
								const value =
									!isEmpty(providerValue) &&
									!isNil(providerValue)
										? providerValue
										: formValue;
								const roles = provider.provider_role || [];
								const isArchived =
									provider.is_archived || false;
								const displayMedium = modality?.medium || '';
								return (
									<div
										key={`encounter-provider-${uuid}`}
										className='releaseDocValueContainer'
									>
										<div className='row-container'>
											<div className='icon'>
												<ReactSVG
													src={stethoscopeIcon}
													style={{
														height: '24px',
														width: '24px',
													}}
												/>
											</div>
											<div className='right-side-container'>
												<div className='full-label-container'>
													<div className='flex-container'>
														<div className='lable-container'>
															<div className='text-bold-info'>
																{provider.fullName ||
																	''}
															</div>
														</div>
														<div className='extra-info-container'>
															<span className='extra-info'>
																{' '}
																{join(
																	selectedExtProviderRoles(
																		roles
																	),
																	'/'
																)}
															</span>
														</div>
													</div>
												</div>
												{isArchived && (
													<div className='full-label-container'>
														<div className='archived-text'>
															Provider Archived
														</div>
													</div>
												)}
												<div className='full-label-container'>
													<div className='text-medium-info'>
														Release via{' '}
														{displayMedium}
													</div>
													<div className='text-medium-info'>
														{value}
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							}
						)}
					</div>
				)}
			</div>

			<div className='body-section'>
				{!isEmpty(selectedPatientProviders) && (
					<div className='releaseDocinputcontainer'>
						<div className='releaseDocInputHeaderContainer'>
							<div className='releaseDocInputHeader'>
								External providers linked to Patient
							</div>
						</div>
						{map(
							selectedPatientProviders,
							(selectedProvider, uuid) => {
								const provider = getPatientProvider(uuid) || {};
								const medium = selectedProvider?.medium;
								const providerValue =
									provider && provider[medium];
								const formValue = selectedProvider?.value || '';
								const value =
									!isEmpty(providerValue) &&
									!isNil(providerValue)
										? providerValue
										: formValue;
								const roles = provider.provider_role || [];
								const isArchived =
									provider.is_archived || false;
								const displayMedium = modality?.medium || '';
								return (
									<div
										key={`patient-provider-${uuid}`}
										className='releaseDocValueContainer'
									>
										<div className='row-container'>
											<div className='icon'>
												<ReactSVG
													src={stethoscopeIcon}
													style={{
														height: '24px',
														width: '24px',
													}}
												/>
											</div>
											<div className='right-side-container'>
												<div className='full-label-container'>
													<div className='flex-container'>
														<div className='lable-container'>
															<div className='text-bold-info'>
																{provider.fullName ||
																	''}
															</div>
														</div>
														<div className='extra-info-container'>
															<span className='extra-info'>
																{' '}
																{join(
																	selectedExtProviderRoles(
																		roles
																	),
																	'/'
																)}
															</span>
														</div>
													</div>
												</div>
												{isArchived && (
													<div className='full-label-container'>
														<div className='archived-text'>
															Provider Archived
														</div>
													</div>
												)}
												<div className='full-label-container'>
													<div className='text-medium-info'>
														Release via{' '}
														{displayMedium}
													</div>
													<div className='text-medium-info'>
														{value}
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							}
						)}
					</div>
				)}
			</div>

			<div className='body-section'>
				{stateReleaseToExternalEHR && propsDocReleaseToExtEHR && (
					<div className='releaseDocinputcontainer'>
						<div className='releaseDocInputHeaderContainer'>
							<div className='releaseDocInputHeader'>
								External EHR Linked to Patient
							</div>
						</div>
						<div className='releaseDocValueContainer'>
							<div className='row-container'>
								<div className='icon'>
									<ReactSVG
										src={contactsIcon}
										style={{
											height: '24px',
											width: '24px',
										}}
									/>
								</div>
								<div className='right-side-container'>
									<div className='full-label-container'>
										<div className='flex-container'>
											<div className='lable-container'>
												<div className='text-bold-info'>
													Release to External EHR
												</div>
												<div className='text-medium-info'>
													Release via API
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>

			<div className='body-section'>
				<div className='note'>
					Sending documents via Email, eFAX, or EHR is a
					non-retractable action.
				</div>
			</div>
		</div>
	);
};

export default ReviewAndConfirm;
