import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import * as Sentry from '@sentry/react';

import { Loading } from 'gm-element-react';

import { bookAppointment } from '../../../../actions/appointments';
import EncounterService from '../../../../service/Encounter';
import PatientService from '../../../../service/Patients';
import { Page } from '../../../Common/V3/Page';
import { Header } from '../../../Common/V3/Header';

import { addressTypeConst } from '../../../../utils';
import { ModalWindow } from '../../../Common/V3/ModalWindow';

import {
	formToEncounterApiPayload,
	formToPatientApiPayload,
	formToInsuranceApiPayload,
	formToAddressApiPayload,
	formToEditEncounterApiPayload,
} from './api';
import {
	PatientInfo,
	PaymentInfo,
	ServiceInfoAndScheduling,
	Summary,
	PAYMENT_METHOD,
} from './sections';
import { getInitialValues, schema, validateForm } from './validation';

import './Page.scss';

/** @typedef {import('@stripe/stripe-js').SetupIntent} SetupIntent */

export function ScheduleAppointment(props) {
	// Patient data is populated when navigating from encounter list page.
	const patientData = props.location.state?.breadcrumbs?.[1]?.patientData;

	const paymentInfoRef = useRef(null);
	const [scheduleError, setScheduleError] = useState(null);
	return (
		<Page className='scheduleAppointmentV2'>
			<div className='scheduleAppointmentV2Container'>
				<Formik
					initialValues={getInitialValues({
						patientId: patientData?.id,
						patientUUID: patientData?.uuid,
					})}
					validate={(values) => validateForm(schema, values)}
					validateOnBlur={false}
					validateOnChange={false}
					onSubmit={async (values) => {
						const {
							paymentMethod,
							consultationPrice,
							insurancePrice,
							useSavedCard,
						} = values.paymentInfo;
						try {
							const isNonInsuranceAndZeroPrice =
								paymentMethod ===
									PAYMENT_METHOD.NON_INSURANCE &&
								consultationPrice !== 0;
							const isInsuranceAndZeroPrice =
								paymentMethod === PAYMENT_METHOD.INSURANCE &&
								insurancePrice !== 0;
							const isValidPayment =
								(isNonInsuranceAndZeroPrice ||
									isInsuranceAndZeroPrice) &&
								!useSavedCard;
							if (isValidPayment) {
								/** @type SetupIntent */
								const stripeResponse =
									await paymentInfoRef.current.createPaymentMethod();
								await PatientService.updatePaymentMethod(
									{ setup_intent_id: stripeResponse.id },
									values.patientInfo.patientUUID
								);
							}
							await PatientService.updatePatient(
								values.patientInfo.patientUUID,
								formToPatientApiPayload(values)
							);

							const addressTypes = values.paymentInfo
								.requireShippingAddress
								? [addressTypeConst.shipping]
								: [];

							if (
								!(
									paymentMethod ==
										PAYMENT_METHOD.NON_INSURANCE &&
									values.paymentInfo.consultationPrice === 0
								)
							) {
								addressTypes.push(addressTypeConst.home);
							}

							// Update addresses based on the determined addressTypes
							if (addressTypes.length > 0) {
								await PatientService.updateAddress({
									patient_id: values.patientInfo.patientUUID,
									payload: formToAddressApiPayload(
										values,
										addressTypes
									),
								});
							}

							if (
								values.paymentInfo.paymentMethod ===
								PAYMENT_METHOD.INSURANCE
							) {
								await PatientService.saveInsurance(
									formToInsuranceApiPayload(values),
									values.patientInfo.patientUUID
								);
							}
							const response = await bookAppointment(
								formToEncounterApiPayload(values)
							);
							const uuid = response.data.data[0].uuid;

							const editPayload =
								formToEditEncounterApiPayload(values);
							if (editPayload) {
								await EncounterService.editencouter({
									data: {
										uuid,
										...editPayload,
									},
								});
							}

							props.history.push(
								`/app/patientdetail/${values.patientInfo.patientUUID}/0/2/${uuid}`
							);
						} catch (e) {
							// Extract error message, might need to be tweaked
							// to be able produce meaningful message for common
							// error scenarios.
							let errorMessage = e.message;
							if (e.response?.data?.message) {
								if (e.response.data.message?.message) {
									errorMessage =
										e.response.data.message.message.toString();
								} else {
									errorMessage =
										e.response.data.message.toString();
								}
							}
							setScheduleError(errorMessage);
							console.error(e);
							Sentry.captureException(e);
						}
					}}
				>
					{({ isSubmitting, submitForm }) => (
						<>
							{isSubmitting && <Loading fullscreen loading />}
							{scheduleError && (
								<ModalWindow
									onCancel={() => setScheduleError(null)}
									title='Appointment scheduling error'
								>
									<p>
										We were unable to schedule this
										appointment because of the following
										reason:
									</p>
									<p>{scheduleError}</p>
									<p>
										Please contact your support team for
										assistance
									</p>
								</ModalWindow>
							)}
							<div className='box main-form'>
								<Header>Schedule Appointment</Header>
								<PatientInfo sectionDataContainerName='patientInfo' />
								<ServiceInfoAndScheduling
									sectionDataContainerName='serviceInfoAndScheduling'
									appointmentSummaryLabel='Prior Appointment'
								/>
								<PaymentInfo
									sectionDataContainerName='paymentInfo'
									ref={paymentInfoRef}
								/>
							</div>
							<div className='box summary'>
								<Summary
									sectionDataContainerName='summary'
									submitForm={submitForm}
								/>
							</div>
						</>
					)}
				</Formik>
			</div>
		</Page>
	);
}
