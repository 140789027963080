//React & Redux
import React, { useEffect, useState } from 'react';

//Images
import checkIcon from './images/check.svg';
import crossIcon from './images/cross.svg';
import bgImage from './images/stripe-branding.jpg';
import editIcon from './images/edit-auth.svg';

//Lodash
import { isEmpty, isNil } from 'lodash';

//Utils
import {
	CARE_PATIENTS_PATIENT_STRIPE_PAYMENT,
	CARE_PATIENTS_PATIENT_PAYMENTS_INSURANCE_ACTIONS,
} from '../../../Utils/permissionUtils';

//UI Libraries
import { Button } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import RBAC from '../../RBAC';

const dash = (
	<span className='dash' style={{ fontFamily: 'none' }}>
		--
	</span>
);

const PaymentInfo = (props) => {
	const {
		actionType,
		editInsuranceAuth,
		insuranceAuths,
		patient: { has_payment_method = false },
		paymentActions: { getInsuranceAuths, resetInsuranceAuths },
		setAction,
		patientInsuranceInfo,
	} = props;

	const [insuranceCompanyType, setInsuranceCompanyType] = useState('');

	useEffect(() => {
		return () => {
			resetInsuranceAuths();
		};
	}, []);

	useEffect(() => {
		const primaryInsurance =
			(!isEmpty(patientInsuranceInfo) &&
				!isNil(patientInsuranceInfo) &&
				(patientInsuranceInfo || []).filter(
					(el) =>
						el.insurance_type === 'primary' &&
						el.visit_insurance === true &&
						el.lab_insurance === false
				)?.[0]) ||
			{};
		primaryInsurance?.insurance_company?.name &&
			primaryInsurance?.insurance_id &&
			getInsuranceAuths({
				policyholder_insurance_id: primaryInsurance?.insurance_id,
			});

		if (!isEmpty(primaryInsurance) && !isNil(primaryInsurance)) {
			setInsuranceCompanyType(primaryInsurance?.insurance_company?.name);
		}
	}, [patientInsuranceInfo]);

	const openStripe = () => {
		window.open('http://www.stripe.com/', '_blank');
	};

	const getData = (obj, field) => {
		switch (field) {
			case 'athena_insurance_package_id':
				return obj[field] != null ? obj[field] : dash;
			case 'authorization_start_date':
			case 'authorization_end_date':
				return !isEmpty(obj[field] || '')
					? moment(obj[field] || '').format('MMM D, YYYY')
					: dash;
			default:
				return !isEmpty(obj[field] || '') ? obj[field] : dash;
		}
	};

	return (
		<div className='payment-rightblock'>
			{insuranceCompanyType && (
				<div className='auth-info'>
					<div className='auth-info-container'>
						<div className='auth-header'>
							Insurance authorizations (for primary insurance
							only)
						</div>
						<ul className='auth-info-list'>
							{insuranceAuths &&
								insuranceAuths.map((data) => (
									<li className='auth-content' key={data.id}>
										<div className='pkg-id'>
											{' '}
											<span>
												Package ID:{' '}
												{getData(
													data,
													'athena_insurance_package_id'
												)}
											</span>
											<RBAC
												action={
													CARE_PATIENTS_PATIENT_PAYMENTS_INSURANCE_ACTIONS
												}
												yes={
													<img
														data-testId='edit-ins'
														onClick={() =>
															editInsuranceAuth(
																data
															)
														}
														className='edit-auth-link'
														src={editIcon}
														alt=''
													/>
												}
											/>
										</div>
										<div className='insurance-auth'>
											Insurance auth #{' '}
											{data.insurance_authorization_number ||
												dash}
										</div>
										<div className='insurance-date'>
											{getData(
												data,
												'authorization_start_date'
											)}{' '}
											-{' '}
											{getData(
												data,
												'authorization_end_date'
											)}
										</div>
									</li>
								))}
						</ul>
						{insuranceAuths.length === 0 && (
							<div className='no-insurance-label'>
								{' '}
								No Insurance Authorizations Added{' '}
							</div>
						)}
						<RBAC
							action={
								CARE_PATIENTS_PATIENT_PAYMENTS_INSURANCE_ACTIONS
							}
							yes={
								<div className='auth-button-container'>
									<div
										data-testId='auth-btn'
										className='auth-button'
										onClick={() =>
											setAction(actionType.addAuth)
										}
									>
										<div className='auth-button-text'>
											Add Authorization
										</div>
									</div>
								</div>
							}
						/>
					</div>
				</div>
			)}
			{insuranceCompanyType && <div className='payment-separator' />}
			<RBAC
				action={CARE_PATIENTS_PATIENT_STRIPE_PAYMENT}
				yes={
					<div className='stripe-info'>
						<div className='stripe-info-container'>
							<div
								className='stripe-payment-bg-content'
								style={{
									backgroundImage: `url(${bgImage})`,
									backgroundRepeat: 'no-repeat',
								}}
							>
								<div className='stripe-payment-bg-content-inner'>
									<div className='stripe-payment-text1'>
										Stripe Payment
									</div>
									<img
										className='info-badge'
										src={
											has_payment_method
												? checkIcon
												: crossIcon
										}
										alt=''
									/>
								</div>
							</div>
							<div className='stripe-payment-content'>
								<div className='stripe-payment-text2'>
									{has_payment_method ? (
										'This patient has a form of payment in Stripe'
									) : (
										<div>
											This patient <b>does not</b> have a
											form of payment in Stripe
										</div>
									)}
								</div>
								<Button
									onClick={openStripe}
									className='open-stripe-button'
								>
									Open Stripe
								</Button>
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default PaymentInfo;
