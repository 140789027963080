import * as actionTypes from './actionTypes';
import SessionService from '../service/Session';

export const authenticate = (payload) => {
	return {
		type: actionTypes.AUTHENTICATE,
		payload: payload,
	};
};

export const session = (payload) => {
	return {
		type: actionTypes.SESSION,
		payload: payload,
	};
};

export function getsession() {
	return (dispatch) => {
		SessionService.session()
			.then(() => {
				dispatch(session());
			})
			.catch(() => {});
	};
}

export function getInterval(value) {
	return {
		type: actionTypes.INTERVAL,
		payload: value,
	};
}
