//React && Redux
import React from 'react';

//Styling
import './AddEditUserModal.css';
import classnames from 'classnames';

//UI Libraries
import { Loading, Select } from 'gm-element-react';
import moment from 'moment';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../assets/close.svg';

//Lodash
import { get, values, find, isNull, isEmpty } from 'lodash';

//Components
import { EncounterDateTimeField } from '../Patients/V2/Encounters/Common/EncounterDateTimeField';
import ErrorBoundary from '../Common/ErrorBoundary';

//Utils
import { validEmails } from '../../utils';
import {
	ROLE_EXTERNALUSER,
	ROLE_EXTERNALUSER_READ_ONLY,
	ROLE_EXTERNALUSER_LAB,
	ROLE_NOACCESS,
	ROLE_SUPERUSER,
} from '../../Utils/permissionUtils';

export default class AddEditUserModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			form: {
				first_name: get(props, ['userData', 'first_name'], ''),
				last_name: get(props, ['userData', 'last_name'], ''),
				email: get(props, ['userData', 'email'], ''),
				defaultRole: get(
					find(get(props, ['userData', 'roles'], []), [
						'default',
						true,
					]),
					['uuid'],
					null
				),
				secondaryRoles: get(props, ['userData', 'roles'], [])
					.filter((role) => !role.default)
					.map(({ uuid }) => uuid),
				partners: get(props, ['userData', 'partners'], []),
				scheduledInvitationDateTime: '',
			},
			errors: {},
		};
	}

	handleSave = (isFormValid) => {
		if (isFormValid) {
			const userUUID = get(this, ['props', 'userData', 'uuid'], null);
			const {
				first_name,
				last_name,
				email,
				defaultRole,
				secondaryRoles,
				partners,
				scheduledInvitationDateTime,
			} = this.state.form;
			const formData = {
				first_name,
				last_name,
				email,
				roles: [
					{
						uuid: defaultRole,
						default: true,
					},
					...secondaryRoles.map((uuid) => ({ uuid, default: false })),
				],
				partners: partners || [],
			};

			if (scheduledInvitationDateTime)
				formData.scheduled_invitation_date_time =
					scheduledInvitationDateTime;

			if (userUUID) this.props.updateUser(formData, userUUID);
			else this.props.createUser(formData);
		}
	};

	handleFieldChange = (field, value) => {
		const isExternalEmail = !get(
			this,
			['state', 'form', 'email'],
			''
		).endsWith('@genomemedical.com');
		const newForm = {
			...this.state.form,
			[field]: value,
		};
		if (field === 'email') {
			newForm.defaultRole = [];
			newForm.secondaryRoles = [];
			newForm.partners = [];
		} else if (field === 'defaultRole') {
			newForm.secondaryRoles = newForm.secondaryRoles.filter(
				(uuid) => uuid && uuid !== value
			);
			if (
				// Secondary roles cannot be assigned to external users
				isExternalEmail ||
				// Don't copy No Access role from primary role to secondary role,
				// if the user was no access but is being granted a new role.
				[ROLE_NOACCESS].includes(
					this.roleUUIDToName(this.state.form.defaultRole)
				) ||
				// Don't populate secondary roles if the user's new primary role is No Access.
				[ROLE_NOACCESS].includes(this.roleUUIDToName(value))
			) {
				newForm.secondaryRoles = [];
			} else if (!isEmpty(this.state.form.defaultRole)) {
				newForm.secondaryRoles.push(this.state.form.defaultRole);
			}
		} else if (field === 'scheduledInvitationDateTime') {
			const isInTheFuture = moment(value).isAfter(moment());
			const newErrorState = Object.assign({}, this.state.errors);

			if (!value) {
				delete newErrorState.scheduledInvitationDateTime;
			} else if (!isInTheFuture) {
				newErrorState.scheduledInvitationDateTime =
					'Please select a date and time in the future';
			} else {
				delete newErrorState.scheduledInvitationDateTime;
			}

			this.setState({ errors: newErrorState });
			const utcDateTime = value
				? moment(value).utc().format('YYYY-MM-DD HH:mm:ss')
				: null;
			newForm.scheduledInvitationDateTime = utcDateTime;
		}

		if (!this.isExternalRoleSelected(newForm)) {
			delete newForm['partners'];
		}

		this.setState({ form: newForm });
	};

	handleRemoveTag = (value) => {
		if (this.roleUUIDToName(value) === ROLE_SUPERUSER) {
			this.setState({
				form: {
					...this.state.form,
					secondaryRoles: [...this.state.form.secondaryRoles, value],
				},
			});
		}
	};

	getUserRoles = (isSecondary = false, isExternalEmail = false) => {
		let userRoles = get(this, ['props', 'userRoles'], []);

		if (isExternalEmail)
			userRoles = userRoles.filter(({ name }) =>
				[
					ROLE_EXTERNALUSER,
					ROLE_EXTERNALUSER_READ_ONLY,
					ROLE_EXTERNALUSER_LAB,
				].includes(name)
			);
		if (isSecondary)
			userRoles = userRoles.filter(
				({ uuid }) => uuid !== this.state.form.defaultRole
			);

		return userRoles;
	};

	isExternalRoleSelected = (data) => {
		const newForm = data || this.state.form;
		for (const { uuid, name } of get(this, ['props', 'userRoles'], [])) {
			if (
				[
					ROLE_EXTERNALUSER,
					ROLE_EXTERNALUSER_READ_ONLY,
					ROLE_EXTERNALUSER_LAB,
				].includes(name) &&
				[newForm.defaultRole, ...newForm.secondaryRoles].includes(uuid)
			)
				return true;
		}

		return false;
	};

	roleUUIDToName = (uuid) => {
		return get(
			find(get(this, ['props', 'userRoles'], []), ['uuid', uuid]),
			['name'],
			null
		);
	};

	errorsPresent = () => !isEmpty(this.state.errors);

	render = () => {
		const { first_name, last_name, email, partners, defaultRole } = get(
			this,
			['state', 'form'],
			{}
		);
		const isAddUserMode = isNull(get(this, ['props', 'userData'], null));
		const title = isAddUserMode ? 'Add New User' : 'Edit User';
		const buttonText = isAddUserMode ? 'Add User' : 'Save';
		const isExternalEmail = !get(
			this,
			['state', 'form', 'email'],
			''
		).endsWith('@genomemedical.com');
		let isFormValid =
			!isEmpty(first_name) &&
			!isEmpty(last_name) &&
			validEmails([email]) &&
			!isEmpty(defaultRole);
		isFormValid = this.isExternalRoleSelected()
			? isFormValid && !isEmpty(partners)
			: isFormValid;

		return (
			<div className='fullModalBackground'>
				<div className='add-edit-user-modal-container'>
					<Loading
						className='loading'
						loading={
							this.props.partnersLoading ||
							this.props.createUserLoading ||
							this.props.updateUserLoading
						}
					>
						<div className='add-edit-user-modal-top-row'>
							<div>{title}</div>
							<ReactSVG
								src={xIcon}
								className='singleSelectModalXIcon add-edit-user-modal-top-row-x-icon'
								onClick={this.props.hideAddEditUserModal}
							/>
						</div>
						<div className='add-edit-user-modal-body'>
							<div className='add-edit-user-modal-fields-container'>
								<div className='inputFieldContainer'>
									<div className='inputFieldRequired'>*</div>
									<div className='inputFieldHeader'>
										First Name
									</div>
									<input
										className='inputField inputFieldText add-edit-user-modal-two-quarter-width-text'
										onChange={(e) =>
											this.handleFieldChange(
												'first_name',
												get(e, ['target', 'value'], '')
											)
										}
										value={get(
											this,
											['state', 'form', 'first_name'],
											''
										)}
										placeholder='First Name'
									/>
									<div className='error'>
										{values(
											get(
												this,
												[
													'props',
													'createUserError',
													'fields',
													'first_name',
												],
												{}
											)
										)[0] ||
											values(
												get(
													this,
													[
														'props',
														'updateUserError',
														'fields',
														'first_name',
													],
													{}
												)
											)[0] ||
											''}
									</div>
								</div>
								<div
									className='inputFieldContainer'
									style={{ marginLeft: '16px' }}
								>
									<div className='inputFieldRequired'>*</div>
									<div className='inputFieldHeader'>
										Last Name
									</div>
									<input
										className='inputField inputFieldText add-edit-user-modal-two-quarter-width-text'
										onChange={(e) =>
											this.handleFieldChange(
												'last_name',
												get(e, 'target.value', '')
											)
										}
										value={get(
											this,
											['state', 'form', 'last_name'],
											''
										)}
										placeholder='Last Name'
									/>
									<div className='error'>
										{values(
											get(
												this,
												[
													'props',
													'createUserError',
													'fields',
													'last_name',
												],
												{}
											)
										)[0] ||
											values(
												get(
													this,
													[
														'props',
														'updateUserError',
														'fields',
														'last_name',
													],
													{}
												)
											)[0] ||
											''}
									</div>
								</div>
								<div className='inputFieldContainer'>
									<div className='inputFieldRequired'>*</div>
									<div className='inputFieldHeader'>
										Email Address
									</div>
									<input
										disabled={!isAddUserMode}
										className='inputField inputFieldText add-edit-user-modal-four-quarter-width-text'
										onChange={(e) =>
											this.handleFieldChange(
												'email',
												get(e, 'target.value', '')
											)
										}
										value={get(
											this,
											['state', 'form', 'email'],
											''
										)}
										placeholder='Email Address'
									/>
									<div className='error'>
										{values(
											get(
												this,
												[
													'props',
													'createUserError',
													'fields',
													'email',
												],
												{}
											)
										)[0] ||
											values(
												get(
													this,
													[
														'props',
														'updateUserError',
														'fields',
														'email',
													],
													{}
												)
											)[0] ||
											''}
									</div>
								</div>
								<div className='inputFieldContainer'>
									<div className='inputFieldRequired'>*</div>
									<div className='inputFieldHeader'>
										{isExternalEmail
											? 'Role'
											: 'Default Role'}
									</div>
									<ErrorBoundary>
										<Select
											className='inputField inputFieldDropdown add-edit-user-modal-four-quarter-width-dropdown'
											disabled={
												!validEmails([
													get(
														this,
														[
															'state',
															'form',
															'email',
														],
														''
													),
												]) ||
												[ROLE_SUPERUSER].includes(
													this.roleUUIDToName(
														this.state.form
															.defaultRole
													)
												)
											}
											value={get(
												this,
												[
													'state',
													'form',
													'defaultRole',
												],
												null
											)}
											onChange={(value) =>
												this.handleFieldChange(
													'defaultRole',
													value
												)
											}
										>
											{this.getUserRoles(
												false,
												isExternalEmail
											).map(({ uuid, name }) => {
												return (
													<Select.Option
														key={uuid}
														label={name}
														value={uuid}
														disabled={[
															ROLE_NOACCESS,
															ROLE_SUPERUSER,
														].includes(name)}
													>
														{name}
													</Select.Option>
												);
											})}
										</Select>
									</ErrorBoundary>
									<div className='error'>
										{values(
											get(
												this,
												[
													'props',
													'createUserError',
													'fields',
													'roles',
												],
												{}
											)
										)[0] ||
											values(
												get(
													this,
													[
														'props',
														'updateUserError',
														'fields',
														'roles',
													],
													{}
												)
											)[0] ||
											''}
									</div>
								</div>
								{!isExternalEmail && (
									<div className='inputFieldContainer'>
										<div className='inputFieldHeader'>
											Secondary Role(s)
										</div>
										<ErrorBoundary>
											<Select
												className='inputField inputFieldDropdown add-edit-user-modal-four-quarter-width-dropdown'
												disabled={
													!validEmails([
														get(
															this,
															[
																'state',
																'form',
																'email',
															],
															''
														),
													]) ||
													get(
														this,
														[
															'state',
															'form',
															'secondaryRoles',
														],
														[]
													).some((secondaryRole) =>
														[
															ROLE_SUPERUSER,
														].includes(
															secondaryRole
														)
													) ||
													[ROLE_NOACCESS].includes(
														this.roleUUIDToName(
															this.state.form
																.defaultRole
														)
													)
												}
												multiple={true}
												value={get(
													this,
													[
														'state',
														'form',
														'secondaryRoles',
													],
													[]
												)}
												onChange={(value) =>
													this.handleFieldChange(
														'secondaryRoles',
														value
													)
												}
												onRemoveTag={
													this.handleRemoveTag
												}
											>
												{this.getUserRoles(
													true,
													isExternalEmail
												).map(({ uuid, name }) => {
													return (
														<Select.Option
															key={uuid}
															label={name}
															value={uuid}
															disabled={[
																ROLE_NOACCESS,
																ROLE_SUPERUSER,
															].includes(name)}
														>
															{name}
														</Select.Option>
													);
												})}
											</Select>
										</ErrorBoundary>
										<div className='error'>
											{values(
												get(
													this,
													[
														'props',
														'createUserError',
														'fields',
														'roles',
													],
													{}
												)
											)[0] ||
												values(
													get(
														this,
														[
															'props',
															'updateUserError',
															'fields',
															'roles',
														],
														{}
													)
												)[0] ||
												''}
										</div>
									</div>
								)}
								{this.isExternalRoleSelected() && (
									<div className='inputFieldContainer'>
										<div className='inputFieldRequired'>
											*
										</div>
										<div className='inputFieldHeader'>
											Associated Partner(s)
										</div>
										<ErrorBoundary>
											<Select
												className='inputField inputFieldDropdown add-edit-user-modal-four-quarter-width-dropdown'
												multiple={true}
												value={get(
													this,
													[
														'state',
														'form',
														'partners',
													],
													[]
												)}
												onChange={(value) =>
													this.handleFieldChange(
														'partners',
														value
													)
												}
											>
												{get(
													this,
													['props', 'partners'],
													[]
												).map(
													({
														display_name,
														uuid,
													}) => {
														return (
															<Select.Option
																key={uuid}
																label={
																	display_name
																}
																value={uuid}
															>
																{display_name}
															</Select.Option>
														);
													}
												)}
											</Select>
										</ErrorBoundary>
										<div className='error'>
											{values(
												get(
													this,
													[
														'props',
														'createUserError',
														'fields',
														'partners',
													],
													{}
												)
											)[0] ||
												values(
													get(
														this,
														[
															'props',
															'updateUserError',
															'fields',
															'partners',
														],
														{}
													)
												)[0] ||
												''}
										</div>
									</div>
								)}
								{isAddUserMode && (
									<div
										className='inputFieldContainer'
										style={{ width: '160px' }}
									>
										<div className='inputFieldHeader'>
											Send Invite Date (Optional)
										</div>
										<ErrorBoundary>
											<br />
											<EncounterDateTimeField
												value={
													this.state
														.scheduledInvitationDateTime ||
													''
												}
												name='scheduled_invitation_date_time'
												disabled={false}
												placeholder='Select Date'
												onChange={(value) =>
													this.handleFieldChange(
														'scheduledInvitationDateTime',
														value
													)
												}
												onBlur={() => {}}
												disabledDate={(current) =>
													moment(current).isAfter(
														moment().subtract(
															1,
															'day'
														)
													)
												}
												extraclassName={
													'updatedDoNotOutreachAlertInputField'
												}
											/>
										</ErrorBoundary>
										<div className='error'>
											{this.state.errors
												.scheduledInvitationDateTime ||
												values(
													get(
														this,
														[
															'props',
															'createUserError',
															'fields',
															'scheduled_invitation_date_time',
														],
														{}
													)
												)[0] ||
												values(
													get(
														this,
														[
															'props',
															'updateUserError',
															'fields',
															'scheduled_invitation_date_time',
														],
														{}
													)
												)[0] ||
												''}
										</div>
									</div>
								)}
							</div>
						</div>
						<div className='add-edit-user-modal-button-row'>
							<div
								className={classnames('modalButton', {
									modalButtonDisabled:
										this.errorsPresent() || !isFormValid,
								})}
								style={{ marginLeft: 'auto' }}
								onClick={() => this.handleSave(isFormValid)}
							>
								{buttonText}
							</div>
						</div>
					</Loading>
				</div>
			</div>
		);
	};
}
