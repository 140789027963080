import * as actionTypes from '../actions/actionTypes';

export const defaultState = {
	planTypes: {
		loading: false,
		data: [],
		error: null,
	},
};

export default (state = defaultState, action = {}) => {
	switch (action.type) {
		case actionTypes.GETTING_PLAN_TYPES:
			return {
				...state,
				planTypes: {
					loading: true,
					data: [],
					error: null,
				},
			};

		case actionTypes.GOT_PLAN_TYPES:
			return {
				...state,
				planTypes: {
					loading: false,
					data: action.payload,
					error: null,
				},
			};

		case actionTypes.GOT_PLAN_TYPES_ERROR:
			return {
				...state,
				planTypes: {
					loading: false,
					error: action.payload,
				},
			};

		default:
			return state;
	}
};
