/* eslint-disable react/jsx-key */
//React & Redux
import React from 'react';
import { connect } from 'react-redux';

//Lodash
import clone from 'lodash/clone';
import get from 'lodash/get';
import intersection from 'lodash/intersection';

//Styles
import './ReleaseNotes.css';

//Components
import MultiSelectCheckbox from './../Filter/MultiSelectCheckbox';
import PatientTag from './../Common/PatientTag.js';
import LinkPopover from './../Common/Popovers/LinkPopover';

//UI Libraries
import { Dialog, Loading } from 'gm-element-react';

//Utils
import {
	filterEnum,
	releaseNotesJSON,
	externalUserReleaseNotesJSON,
} from '../../Utils/releaseNotesUtils.js';
import { ROLE_PRODENG, isExternalRole } from '../../Utils/permissionUtils';
import { updateMe, me } from '../../actions/me.js';

const assets = '../../assets/release-notes/';

class ReleaseNotes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filterBy: [],
			showImgDialog: false,
		};
		//bindings
		this.changeFilterHandler = this.changeFilterHandler.bind(this);
		this.filterJSON = this.filterJSON.bind(this);
		this.toggleDialog = this.toggleDialog.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
		this.determineDialogStyle = this.determineDialogStyle.bind(this);
	}
	//functions

	async componentDidMount() {
		const lastViewedReleaseNoteVersion = _.get(
			this,
			['props', 'me', 'user', 'last_viewed_release_note_version'],
			''
		);
		if (lastViewedReleaseNoteVersion !== process.env.REACT_APP_GMI_TAG) {
			const data = {
				last_viewed_release_note_version: process.env.REACT_APP_GMI_TAG,
			};
			this.props.updateMe(data);
		}
	}

	componentDidUpdate = (prevProps) => {
		if (
			!get(prevProps, ['updateMeLoaded'], false) &&
			get(this, ['props', 'updateMeLoaded'], true) &&
			get(this, ['props', 'updateMeData'], '')
		) {
			this.props.getme();
		}
	};

	changeFilterHandler(data) {
		this.setState({ filterBy: data });
	}

	filterJSON() {
		const role = get(this, ['props', 'me', 'activerole', 'name'], null);
		const filterBy = get(this, 'state.filterBy', null) || [];
		const relaseNotes = isExternalRole(role)
			? externalUserReleaseNotesJSON
			: releaseNotesJSON;
		if (
			Array.isArray(filterBy) &&
			get(filterBy, 'length', 0) > 0 &&
			relaseNotes
		) {
			return relaseNotes.map((sprint) => {
				const newSprint = clone(sprint);
				const featureItems = get(sprint, 'featureItems', null);
				const otherItems = get(sprint, 'otherItems', null);

				[
					{ key: 'featureItems', value: featureItems },
					{ key: 'otherItems', value: otherItems },
				].forEach((obj) => {
					const key = get(obj, 'key', null);
					const value = get(obj, 'value', null);
					if (Array.isArray(value)) {
						newSprint[key] = value.filter((item) => {
							const tags = get(item, 'tags', null);
							if (Array.isArray(tags)) {
								return (
									get(
										intersection(filterBy, tags),
										'length',
										0
									) > 0
								);
							} else return false;
						});
					}
				});
				return newSprint;
			});
		} else return relaseNotes;
	}

	toggleDialog(image) {
		this.setState({ showImgDialog: image });
	}

	closeDialog() {
		this.setState({ showImgDialog: false });
	}

	determineDialogStyle(currentImage) {
		const image = get(this, currentImage, null);
		const imgHeight = get(image, 'height', null);
		const currentImageState = get(this, 'state.showImgDialog', false);
		if (
			image &&
			typeof imgHeight === 'number' &&
			currentImage === currentImageState
		) {
			return {
				top: `calc(45vh - min(${Math.floor(
					imgHeight / 2
				)}px, calc(95vh / 2)))`,
			};
		} else return {};
	}

	render() {
		const loading = get(this, ['props', 'updateMeLoading'], false);
		const releaseNotes = this.filterJSON() || [];
		const role = get(this, ['props', 'me', 'activerole', 'name'], null);
		if (loading) {
			return <Loading loading={true} className='patient_detail_loader' />;
		}
		return (
			<div className='releaseNotesContainer'>
				<div className='releaseNotesBanner'>
					<div className='releaseNotesBannerTitle'>
						Genome Medical Portal
					</div>
					<div className='releaseNotesBannerSubtitle'>
						Release Notes
					</div>
					{role === ROLE_PRODENG && process.env.REACT_APP_GMI_TAG && (
						<div>Version - {process.env.REACT_APP_GMI_TAG}</div>
					)}
				</div>
				<div className='releaseNotesMainContainer'>
					<div className='releaseNotesFilterRow'>
						<MultiSelectCheckbox
							data={filterEnum}
							selected={get(this, 'state.filterBy', null) || []}
							title={'Filter By'}
							submit={(values) =>
								this.changeFilterHandler(values)
							}
							majorPillTitle={'Filter By'}
							defaultValues={get(
								this,
								['defaultFilter', 'title'],
								[]
							)}
							searchBar={false}
							previewTab={false}
							loading={null}
						/>
					</div>
					<div>
						{Array.isArray(releaseNotes) &&
							releaseNotes.map((el) => {
								const releaseDate =
									get(el, 'releaseDate', null) || '--';
								const featureItems =
									get(el, 'featureItems', null) || [];
								const otherItems =
									get(el, 'otherItems', null) || [];
								if (
									get(featureItems, 'length', 0) > 0 ||
									get(otherItems, 'length', 0) > 0
								) {
									return (
										<div className='releaseNotesSprintContainer'>
											<div className='releaseNotesSprintDate'>
												{releaseDate}
											</div>
											<div className='releaseNotesSprintItemsContainer'>
												{Array.isArray(featureItems) &&
													featureItems.map(
														(featureItem) => {
															const header =
																get(
																	featureItem,
																	'title',
																	null
																) || '';
															const tags =
																get(
																	featureItem,
																	'tags',
																	null
																) || [];
															const images = get(
																featureItem,
																'media',
																null
															);
															const description =
																get(
																	featureItem,
																	'majorDescription',
																	null
																) || '';
															const subItems =
																get(
																	featureItem,
																	'subItems',
																	null
																) || [];
															const link = get(
																featureItem,
																'link',
																null
															);
															const link_text =
																get(
																	featureItem,
																	'link_text',
																	''
																);
															const show_tag_title =
																get(
																	featureItem,
																	'show_tag_title',
																	true
																);
															return (
																<div className='releaseNotesSprintItemContainer'>
																	<div className='releaseNotesSprintItemHeader'>
																		<span className='releaseNotesSprintItemHeaderText'>
																			{
																				header
																			}
																		</span>
																		{Array.isArray(
																			tags
																		) &&
																			tags.map(
																				(
																					tag
																				) =>
																					show_tag_title && (
																						<PatientTag
																							tagType = {tag}
																						/>
																						
																					)
																			)}
																	</div>
																	{description ? (
																		<div className='releaseNotesSprintItemDescription'>
																			{
																				description
																			}
																		</div>
																	) : (
																		<div
																			style={{
																				height: '10px',
																			}}
																		/>
																	)}
																	{link ? (
																		<div className='releaseNotesSprintItemDescription'>
																			<LinkPopover
																				enqueueSnackbar={
																					this
																						.props
																						.enqueueSnackbar
																				}
																				linkTo={
																					link
																				}
																				options={{
																					text: true,
																					link: true,
																					copy: true,
																				}}
																			>
																				<span
																					className='regUrlCopyContainer'
																					style={{
																						fontSize:
																							'16px',
																					}}
																				>
																					{
																						link_text
																					}
																				</span>
																			</LinkPopover>
																		</div>
																	) : (
																		<div></div>
																	)}
																	<div className='releaseNotesSprintItemMedia'>
																		{Array.isArray(
																			images
																		) &&
																			images.map(
																				(
																					image
																				) => {
																					return (
																						<React.Fragment>
																							<img
																								src={
																									image
																								}
																								onClick={(
																									e
																								) =>
																									this.toggleDialog(
																										image
																									)
																								}
																							/>
																							<Dialog
																								title=''
																								size='large'
																								visible={
																									get(
																										this,
																										'state.showImgDialog',
																										false
																									) ===
																									image
																								}
																								onCancel={
																									this
																										.closeDialog
																								}
																								lockScroll={
																									false
																								}
																								className='searchHelpDialog'
																								style={this.determineDialogStyle(
																									image
																								)}
																							>
																								<Dialog.Body>
																									<div>
																										<img
																											src={
																												image
																											}
																											alt='Image'
																											className='releaseNotesSprintItemDialogImage'
																											ref={(
																												el
																											) =>
																												(this[
																													image
																												] =
																													el)
																											}
																										/>
																									</div>
																								</Dialog.Body>
																							</Dialog>
																						</React.Fragment>
																					);
																				}
																			)}
																	</div>
																	{Array.isArray(
																		subItems
																	) &&
																		subItems.map(
																			(
																				subItem
																			) => {
																				const subItemHeader =
																					get(
																						subItem,
																						'header',
																						null
																					) ||
																					'';
																				const subItemDescription =
																					get(
																						subItem,
																						'description',
																						null
																					) ||
																					'';
																				const subItemImages =
																					get(
																						subItem,
																						'media',
																						null
																					);
																				const subList =
																					get(
																						subItem,
																						'subList',
																						null
																					);
																				const subItemLinks =
																					get(
																						subItem,
																						'links',
																						null
																					);
																				return (
																					<React.Fragment>
																						{subItemHeader && (
																							<div className='releaseNotesSprintItemSubheaderText'>
																								{
																									subItemHeader
																								}
																							</div>
																						)}
																						{subItemDescription && (
																							<div className='releaseNotesSprintItemDescription'>
																								{
																									subItemDescription
																								}
																							</div>
																						)}

																						<div className='releaseNotesSprintItemMedia'>
																							{Array.isArray(
																								subItemImages
																							) &&
																								subItemImages.map(
																									(
																										image
																									) => {
																										return (
																											<React.Fragment>
																												<img
																													src={
																														image
																													}
																													onClick={(
																														e
																													) =>
																														this.toggleDialog(
																															image
																														)
																													}
																												/>
																												<Dialog
																													title=''
																													size='large'
																													visible={
																														get(
																															this,
																															'state.showImgDialog',
																															false
																														) ===
																														image
																													}
																													onCancel={
																														this
																															.closeDialog
																													}
																													lockScroll={
																														false
																													}
																													className='searchHelpDialog'
																													style={this.determineDialogStyle(
																														image
																													)}
																												>
																													<Dialog.Body>
																														<div>
																															<img
																																src={
																																	image
																																}
																																alt='Image'
																																className='releaseNotesSprintItemDialogImage'
																																ref={(
																																	el
																																) =>
																																	(this[
																																		image
																																	] =
																																		el)
																																}
																															/>
																														</div>
																													</Dialog.Body>
																												</Dialog>
																											</React.Fragment>
																										);
																									}
																								)}
																						</div>
																						<div>
																							{Array.isArray(
																								subList
																							) &&
																								subList.map(
																									(
																										list
																									) => {
																										return (
																											<React.Fragment>
																												<ul>
																													<li>
																														<div className='sublist-item'>
																															{
																																list.header
																															}
																														</div>
																													</li>
																													<div className='sublist-item-description'>
																														{
																															list.description
																														}
																													</div>
																													<div
																														className='releaseNotesSprintItemMedia'
																														style={{
																															'margin-top':
																																'12px',
																														}}
																													>
																														{Array.isArray(
																															get(
																																list,
																																'media',
																																null
																															)
																														) &&
																															get(
																																list,
																																'media',
																																null
																															).map(
																																(
																																	image
																																) => {
																																	return (
																																		<React.Fragment>
																																			<img
																																				src={
																																					image
																																				}
																																				onClick={(
																																					e
																																				) =>
																																					this.toggleDialog(
																																						image
																																					)
																																				}
																																			/>
																																			<Dialog
																																				title=''
																																				size='large'
																																				visible={
																																					get(
																																						this,
																																						'state.showImgDialog',
																																						false
																																					) ===
																																					image
																																				}
																																				onCancel={
																																					this
																																						.closeDialog
																																				}
																																				lockScroll={
																																					false
																																				}
																																				className='searchHelpDialog'
																																				style={this.determineDialogStyle(
																																					image
																																				)}
																																			>
																																				<Dialog.Body>
																																					<div>
																																						<img
																																							src={
																																								image
																																							}
																																							alt='Image'
																																							className='releaseNotesSprintItemDialogImage'
																																							ref={(
																																								el
																																							) =>
																																								(this[
																																									image
																																								] =
																																									el)
																																							}
																																						/>
																																					</div>
																																				</Dialog.Body>
																																			</Dialog>
																																		</React.Fragment>
																																	);
																																}
																															)}
																													</div>
																												</ul>
																											</React.Fragment>
																										);
																									}
																								)}
																						</div>
																						{Array.isArray(
																							subItemLinks
																						) &&
																							subItemLinks.map(
																								(
																									linkitem
																								) => {
																									return (
																										<div className='releaseNotesSprintItemDescription'>
																											<LinkPopover
																												enqueueSnackbar={
																													this
																														.props
																														.enqueueSnackbar
																												}
																												linkTo={
																													linkitem.link
																												}
																												options={{
																													text: true,
																													link: true,
																													copy: true,
																												}}
																											>
																												<span
																													className='regUrlCopyContainer'
																													style={{
																														fontSize:
																															'16px',
																													}}
																												>
																													{
																														linkitem.text
																													}
																												</span>
																											</LinkPopover>
																										</div>
																									);
																								}
																							)}
																					</React.Fragment>
																				);
																			}
																		)}
																</div>
															);
														}
													)}
												{Array.isArray(otherItems) &&
													get(
														otherItems,
														'length',
														0
													) > 0 && (
														<div className='releaseNotesSprintOtherContainer'>
															<div className='releaseNotesSprintItemHeaderText'>
																Fixes and
																Enhancements
															</div>
															<ul className='releaseNotesSprintOtherList'>
																{otherItems.map(
																	(item) => {
																		const description =
																			get(
																				item,
																				'majorDescription',
																				null
																			) ||
																			'--';
																		const tags =
																			get(
																				item,
																				'tags',
																				null
																			);
																		const otherItemImages =
																			get(
																				item,
																				'media',
																				null
																			);
																		return (
																			<div>
																				<li
																					className=''
																					style={{
																						marginBottom:
																							'8px',
																					}}
																				>
																					{
																						description
																					}
																				</li>
																				<div className='releaseNotesSprintItemMedia'>
																					{Array.isArray(
																						otherItemImages
																					) &&
																						otherItemImages.map(
																							(
																								image
																							) => {
																								return (
																									<React.Fragment>
																										<img
																											src={
																												image
																											}
																											onClick={(
																												e
																											) =>
																												this.toggleDialog(
																													image
																												)
																											}
																										/>
																										<Dialog
																											title=''
																											size='large'
																											visible={
																												get(
																													this,
																													'state.showImgDialog',
																													false
																												) ===
																												image
																											}
																											onCancel={
																												this
																													.closeDialog
																											}
																											lockScroll={
																												false
																											}
																											className='searchHelpDialog'
																											style={this.determineDialogStyle(
																												image
																											)}
																										>
																											<Dialog.Body>
																												<div>
																													<img
																														src={
																															image
																														}
																														alt='Image'
																														className='releaseNotesSprintItemDialogImage'
																														ref={(
																															el
																														) =>
																															(this[
																																image
																															] =
																																el)
																														}
																													/>
																												</div>
																											</Dialog.Body>
																										</Dialog>
																									</React.Fragment>
																								);
																							}
																						)}
																				</div>
																			</div>
																		);
																	}
																)}
															</ul>
														</div>
													)}
											</div>
										</div>
									);
								}
							})}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	me: state.me,
	updateMeLoading: get(state, ['me', 'updateMeLoading'], false),
	updateMeLoaded: get(state, ['me', 'updateMeLoaded'], true),
	UpdateMeError: get(state, ['me', 'UpdateMeError'], null),
	updateMeData: get(state, ['me', 'updateMeData'], ''),
});

const mapDispatchToProps = (dispatch) => ({
	getme: () => dispatch(me()),
	updateMe: (data) => dispatch(updateMe(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotes);
