import * as actionTypes from '../actionTypes';

import OutreachService from '../../service/Outreach';

import get from 'lodash/get';
import _ from 'lodash';

export const dispatch_OutreachsStart = (data) => {
	return {
		type: actionTypes.LOAD_OUTREACHS_START,
		payload: data,
	};
};
export const dispatch_OutreachsSuccess = (data) => {
	return {
		type: actionTypes.LOAD_OUTREACHS_SUCCESS,
		payload: data,
	};
};
export const dispatch_OutreachsFailure = (data) => {
	return {
		type: actionTypes.LOAD_OUTREACHS_FAILURE,
		payload: data,
	};
};
export const dispatchResetOutreachs = () => {
	return {
		type: actionTypes.RESET_OUTREACHS,
	};
};

//dont outreach

export const dispatch_OutreachSettingStart = (data) => {
	return {
		type: actionTypes.LOAD_OUTREACH_SETTING_START,
		payload: data,
	};
};

export const dispatch_OutreachSettingSuccess = (data) => {
	return {
		type: actionTypes.LOAD_OUTREACH_SETTING_SUCCESS,
		payload: data,
	};
};

export const dispatch_OutreachSettingFailure = (data) => {
	return {
		type: actionTypes.LOAD_OUTREACH_SETTING_FAILURE,
		payload: data,
	};
};

export const dispatch_ResetOutreachSetting = () => {
	return {
		type: actionTypes.RESET_OUTREACH_SETTING,
	};
};

//get outreach-related enums
export function getOutreachEnums() {
	return (dispatch) => {
		dispatch(loadingOutreachEnums());
		OutreachService.getOutreachEnums()
			.then((res) => {
				dispatch(
					dispatch_OutreachEnums(_.get(res, ['data', 'data'], {}))
				);
			})
			.catch((err) => {
				dispatch(dispatch_OutreachEnumsError(err));
			});
	};
}

export const loadingOutreachEnums = () => {
	return {
		type: actionTypes.LOADING_OUTREACH_ENUMS,
		payload: true,
	};
};

export const dispatch_OutreachEnums = (data) => {
	return {
		type: actionTypes.OUTREACH_ENUMS,
		payload: data,
	};
};

export const dispatch_OutreachEnumsError = (err) => {
	return {
		type: actionTypes.OUTREACH_ENUMS_ERROR,
		payload: err,
	};
};

export function getOutreachs(patient_uuid, doClear = true) {
	return (dispatch) => {
		if (doClear) {
			dispatch(dispatch_OutreachsStart());
		}
		OutreachService.getOutreachs(patient_uuid)
			.then((res) => {
				dispatch(dispatch_OutreachsSuccess(res));
			})
			.catch(() => {
				dispatch(dispatch_OutreachsFailure());
			});
	};
}

//add outreach
export function addOutreach(data, patient_uuid) {
	return OutreachService.addOutreach(data, patient_uuid);
}

export function updateOutreachRound(patient_uuid, roundId, data) {
	return OutreachService.updateOutreachRound(patient_uuid, roundId, data);
}

//add outreach upon patient create
export function addOutreachUponCreate(data, patientUuid, isLoading = true) {
	return (dispatch) => {
		if (isLoading) dispatch(addingOutreachUponCreate());
		return OutreachService.addOutreach(data, patientUuid)
			.then((res) => {
				return dispatch(
					addedOutreachUponCreate(_.get(res, 'data', {}))
				);
			})
			.catch((err) => {
				return dispatch(addedOutreachUponCreateError(err));
			});
	};
}

export const addingOutreachUponCreate = () => {
	return {
		type: actionTypes.ADDING_OUTREACH,
		payload: true,
	};
};

export const addedOutreachUponCreate = (data) => {
	return {
		type: actionTypes.ADDED_OUTREACH_SUCCESS,
		payload: data,
	};
};

export const addedOutreachUponCreateError = (err) => {
	return {
		type: actionTypes.ADDED_OUTREACH_FAILURE,
		payload: err,
	};
};

//updating outreach
export function updateOutreach(data, patient_uuid, communication_uuid) {
	return OutreachService.updateOutreach(
		data,
		patient_uuid,
		communication_uuid
	);
}

//Updated Do Not Outreach
export function doNotOutreach(data, patient_uuid) {
	return (dispatch) => {
		dispatch(settingDoNotOutreach());
		return OutreachService.doNotOutreach(data, patient_uuid)
			.then((res) => {
				return dispatch(setDoNotOutreach(res));
			})
			.catch((err) => {
				return dispatch(setDoNotOutreachError(err));
			});
	};
}

export const settingDoNotOutreach = () => {
	return {
		type: actionTypes.SETTING_DO_NOT_OUTREACH,
		payload: true,
	};
};

export const setDoNotOutreach = (data) => {
	return {
		type: actionTypes.SET_DO_NOT_OUTREACH,
		payload: get(data, 'data', {}),
	};
};

export const setDoNotOutreachError = (err) => {
	return {
		type: actionTypes.SET_DO_NOT_OUTREACH_ERROR,
		payload: err,
	};
};

//Get outreach settings for patient
export function getOutreachSetting(patient_uuid) {
	return (dispatch) => {
		dispatch(dispatch_OutreachSettingStart());
		OutreachService.getOutreachSetting(patient_uuid)
			.then((res) => {
				dispatch(dispatch_OutreachSettingSuccess(res));
			})
			.catch((err) => {
				dispatch(dispatch_OutreachSettingFailure(err));
			});
	};
}

export function getOutreachDeclinedEnum() {
	return (dispatch) => {
		dispatch(gettingOutreachDeclinedEnum());
		OutreachService.getOutreachDeclinedEnum()
			.then((res) => {
				dispatch(
					gotOutreachDeclinedEnum(
						get(res, 'data.data.outreach_declined_via', {}) || {}
					)
				);
			})
			.catch((err) => {
				dispatch(gotOutreachDeclinedEnumError(err));
			});
	};
}

export const gettingOutreachDeclinedEnum = () => {
	return {
		type: actionTypes.GETTING_OUTREACH_DECLINED_ENUM,
		payload: true,
	};
};

export const gotOutreachDeclinedEnum = (res) => {
	return {
		type: actionTypes.GOT_OUTREACH_DECLINED_ENUM,
		payload: res,
	};
};

export const gotOutreachDeclinedEnumError = (err) => {
	return {
		type: actionTypes.GOT_OUTREACH_DECLINED_ENUM_ERROR,
		payload: err,
	};
};

//Get outreach round for patient
export function getPatientOutreachRounds(patient_uuid, isOnlyLatest = false) {
	return (dispatch) => {
		dispatch(gettingPatientOutreachRounds());
		OutreachService.getPatientOutreachRounds(patient_uuid, isOnlyLatest)
			.then((res) => {
				dispatch(gotPatientOutreachRoundsSuccess(res));
			})
			.catch((err) => {
				dispatch(gotPatientOutreachRoundsFailure(err));
			});
	};
}

export const gettingPatientOutreachRounds = () => {
	return {
		type: actionTypes.GETTING_PATIENT_OUTREACH_ROUNDS,
		payload: true,
	};
};

export const gotPatientOutreachRoundsSuccess = (res) => {
	return {
		type: actionTypes.GOT_PATIENT_OUTREACH_ROUNDS_SUCCESS,
		payload: get(res, 'data', {}),
	};
};

export const gotPatientOutreachRoundsFailure = (err) => {
	return {
		type: actionTypes.GOT_PATIENT_OUTREACH_ROUNDS_FAILURE,
		payload: err,
	};
};
