//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../assets/exit-alert.svg';

//Lodash
import get from 'lodash/get';

//UI Libraries
import { Select, Loading } from 'gm-element-react';

const TemplateModalChangeType = (props) => {
	const {
		batchUpdatingTemplateTypes = false,
		changeSelectedTemplateType,
		changeTemplateType,
		close,
		getTypeFromUuid,
		templateType,
		templateTypes = [],
		template = '--',
		title,
	} = props;

	const canChangeType = () => {
		return templateType;
	};

	const handleChangeType = () => {
		if (canChangeType()) changeSelectedTemplateType();
	};

	const buttonClass = canChangeType()
		? 'confirmationModalButtonRowConfirm'
		: 'confirmationModalButtonRowConfirm filterDropdownButtonDisabled';

	return (
		<div className='confirmationModalContainer'>
			<Loading loading={batchUpdatingTemplateTypes}>
				<div
					className='confirmationModalHeaderRow'
					style={{ justifyContent: 'space-between' }}
				>
					<div style={{ position: 'relative', bottom: '-1px' }}>
						{title || 'Confirmation'}
					</div>
					<div
						className='chargeModalClose'
						style={{ position: 'relative', top: '0px' }}
					>
						<ReactSVG
							src={xIcon}
							style={{
								position: 'relative',
								bottom: '2px',
								right: '1.25px',
							}}
							onClick={(e) => {
								close();
							}}
						/>
					</div>
				</div>
				<div
					className='confirmationModalBodyContainer confirmationModalBodyDropdownContainer'
					style={{ justifyContent: 'unset' }}
				>
					<div className='confirmationModalDropdownFieldTitle'>
						Current Template Type
					</div>
					<div
						className='referralProgramsModalTopRowTitle'
						style={{ fontSize: '14px', marginBottom: '16px' }}
					>
						{getTypeFromUuid(template) || '--'}
					</div>

					<div
						className='confirmationModalDropdownContainer'
						style={{ marginBottom: '20px' }}
					>
						<div className='confirmationModalRequiredField'>*</div>
						<div className={'confirmationModalDropdownFieldTitle'}>
							{' '}
							New Template Type{' '}
						</div>
						<Select
							value={templateType}
							placeholder={'Select'}
							onChange={(e) => {
								changeTemplateType(e);
							}}
							className='confirmationModalDropdownEl'
						>
							{templateTypes.map((el, i) => {
								return (
									<Select.Option
										label={
											get(el, 'display_name', '--') ||
											'--'
										}
										value={get(el, 'key', null)}
										key={get(el, 'key', i)}
									>
										<div className='confirmationModalDropdownElementText'>
											{get(el, 'display_name', '--')}
										</div>
									</Select.Option>
								);
							})}
						</Select>
					</div>
				</div>
				<div className='confirmationModalButtonRow'>
					<div />
					<div
						className={buttonClass}
						onClick={(e) => handleChangeType()}
					>
						Save
					</div>
				</div>
			</Loading>
		</div>
	);
};

export default TemplateModalChangeType;
