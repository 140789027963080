//React & Redux
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

//Images
import ReactSVG from 'react-svg';
import breadcrumbArrow from '../../../../../assets/menu-breadcrumb.svg';

//Transformer
import { hydrateProviderCreateUpdateErrors } from '../../../../../transformers/providers/providerTransformers';

//Lodash
import { capitalize, find, get, isEmpty, isNil, map } from 'lodash';

//Other Libraries
import classnames from 'classnames';

//Components
import { Loading, Button } from 'gm-element-react';
import SelectRecipients from './SelectRecipients.js';
import ReviewAndConfirm from './ReviewAndConfirm.js';

//Utils
import {
	FAX,
	EMAIL,
	FINALIZE,
	AMEND,
} from '../../../../../Utils/documentUtils';

//Hooks
import { usePrevious } from '../../../../../hooks/usePrevious';

const STEP_SELECT_RECIPIENTS = 'SELECT_RECIPIENTS';
const STEP_REVIEW_CONFIRM = 'REVIEW_CONFIRM';

const CONTEXT_ENCOUNTER = 'Encounter';
const CONTEXT_PATIENT = 'Patient';

const defaultselectedProviderObject = {
	medium: FAX,
	value: '',
	touch: false,
	error: '',
};

const ReleaseDocumentModal = (props) => {
	const {
		clearEncounterState,
		doReleaseDocument,
		document: {
			can_be_released_api = false,
			encounter,
			previously_released_to_pp,
			released_api = false,
			released_to_pp,
			uuid,
			doc_release_to_ext_ehr = false,
			send_email_notification = null,
		},
		encounter: encounterObj = {},
		getEncounter,
		getPatientLinkedProviders,
		gettingEncounterDetailLoading = false,
		gettingLinkedProviders,
		hideReleaseDocument,
		patientDetail = {},
		patientLinkedProviders = [],
		source = '',
		releasingDocument,
		updateProvider,
	} = props;

	const { providers = [] } = encounterObj;

	const [activeStep, setActiveStep] = useState(STEP_SELECT_RECIPIENTS);
	const [formdata, setFormdata] = useState({
		release_to_partner: false,
		release_to_patient: false,
		selectedEncounterProviders: {},
		selectedPatientProviders: patientLinkedProviders.reduce(
			(acc, provider) => {
				if (provider.defaultDocumentReleaseEnabled) {
					const value = { ...defaultselectedProviderObject };

					const hasFax = !!provider.phones?.fax_office;
					const hasEmail = !!provider.email;

					// Prefer email (no need for else branch as default medium is FAX)
					if ((hasEmail && hasFax) || hasEmail) {
						value.medium = EMAIL;
					}
					acc[provider.providerUUID] = value;
				}
				return acc;
			},
			{}
		),
		type: 'released',
		release_to_external_EHR: true,
		send_email_notification: null,
	});
	const [isEncounterNeeded, setIsEncounterNeeded] = useState(false);
	const [isProviderUpdating, setIsProviderUpdating] = useState(false);
	const [
		didEncounterProviderPreSelected,
		setDidEncounterProviderPreSelected,
	] = useState(false);

	const prevGettingEncounterDetailLoading = usePrevious(
		gettingEncounterDetailLoading
	);

	useEffect(() => {
		if (previously_released_to_pp || [FINALIZE, AMEND].includes(source)) {
			const newObj = { ...formdata };
			newObj['release_to_patient'] = true;
			if (can_be_released_api) {
				newObj['release_to_partner'] = true;
			}
			setFormdata(newObj);
		}

		setFormdata((form) => ({
			...form,
			send_email_notification: send_email_notification,
		}));
		if (!isEmpty(encounter) && !isNil(encounter)) {
			getEncounter(encounter);
			setIsEncounterNeeded(true);
		}

		return () => clearEncounterState();
	}, []);

	useEffect(() => {
		const isEncounterProviderLoaded = isEncounterNeeded
			? !gettingEncounterDetailLoading
			: true;
		const needUpdate =
			!gettingEncounterDetailLoading !=
			!prevGettingEncounterDetailLoading;
		if (
			!didEncounterProviderPreSelected &&
			isEncounterProviderLoaded &&
			needUpdate
		) {
			const encounterProviders = getEncounterProvider();
			const selectedEncounterProviders = {};
			encounterProviders.forEach(({ uuid }) => {
				const providerObj = getProvider(CONTEXT_ENCOUNTER, uuid);
				const defaultProviderObject = {
					...defaultselectedProviderObject,
				};
				const hasFax =
					!isEmpty(get(providerObj, [FAX])) &&
					!isNil(get(providerObj, [FAX]));
				const hasEmail =
					!isEmpty(get(providerObj, [EMAIL])) &&
					!isNil(get(providerObj, [EMAIL]));
				if (!hasFax && hasEmail) {
					defaultProviderObject.medium = EMAIL;
				}
				selectedEncounterProviders[uuid] = { ...defaultProviderObject };
			});
			setFormdata((form) => ({
				...form,
				selectedEncounterProviders,
			}));
			setDidEncounterProviderPreSelected(true);
		}
	}, [gettingEncounterDetailLoading]);

	useEffect(() => {
		// After encounter providers are loaded, check if they are already
		// present in `selectedPatientProviders`. If yes, move them to
		// `selectedEncounterProviders`.
		const patientProviderUUIDs = Object.keys(
			formdata.selectedPatientProviders
		);
		const providersToMove = providers
			.filter((p) => patientProviderUUIDs.includes(p.uuid))
			.map((p) => p.uuid);
		if (providersToMove.length > 0) {
			setFormdata((form) => {
				const selectedEncounterProviders = {
					...form.selectedEncounterProviders,
				};
				const selectedPatientProviders = {
					...form.selectedPatientProviders,
				};

				providersToMove.forEach((p) => {
					selectedEncounterProviders[p] = selectedPatientProviders[p];
					delete selectedPatientProviders[p];
				});

				return {
					...form,
					selectedEncounterProviders,
					selectedPatientProviders,
				};
			});
		}
	}, [providers]);

	const onChangeProviderSelection = (context, uuid, value) => {
		let contextKey = '';
		if (context === CONTEXT_ENCOUNTER) {
			contextKey = 'selectedEncounterProviders';
		} else if (context === CONTEXT_PATIENT) {
			contextKey = 'selectedPatientProviders';
		}
		if (!isEmpty(contextKey)) {
			const selectedProviders = { ...formdata[contextKey] };
			const newObj = { ...formdata };
			if (value) {
				const provider = getProvider(context, uuid);
				const defaultProviderObject = {
					...defaultselectedProviderObject,
				};
				const hasFax =
					!isEmpty(get(provider, [FAX])) &&
					!isNil(get(provider, [FAX]));
				const hasEmail =
					!isEmpty(get(provider, [EMAIL])) &&
					!isNil(get(provider, [EMAIL]));
				if (!hasFax && hasEmail) {
					defaultProviderObject.medium = EMAIL;
				}
				selectedProviders[uuid] = { ...defaultProviderObject };
			} else {
				delete selectedProviders[uuid];
			}
			newObj[contextKey] = selectedProviders;
			setFormdata(newObj);
		}
	};

	const onChangeProviderData = (context, uuid, key, value) => {
		let contextKey = '';
		if (context === CONTEXT_ENCOUNTER) {
			contextKey = 'selectedEncounterProviders';
		} else if (context === CONTEXT_PATIENT) {
			contextKey = 'selectedPatientProviders';
		}
		if (!isEmpty(contextKey)) {
			const selectedProviders = { ...formdata[contextKey] };
			const selectedProvider = { ...selectedProviders[uuid] };
			const newObj = { ...formdata };
			if (isEmpty(selectedProvider)) {
				selectedProvider.medium = null;
				selectedProvider.value = '';
				selectedProvider.touch = false;
			}
			selectedProvider[key] = value;
			if (key === 'medium') {
				selectedProvider.value = '';
				selectedProvider.touch = false;
				selectedProvider.error = '';
			}
			if (key === 'value') {
				selectedProvider.touch = true;
				selectedProvider.error = '';
			}
			selectedProviders[uuid] = selectedProvider;
			newObj[contextKey] = selectedProviders;
			setFormdata(newObj);
		}
	};

	const getEncounterProvider = () => {
		return providers.map((provider) => ({
			uuid: provider.uuid,
			email: get(provider, 'email', ''),
			fax: get(provider, ['phones', 'fax_office'], ''),
			provider_role: provider.role || [],
			is_archived: provider.is_archived || false,
			fullName: `${provider.name}`,
			phones: get(provider, ['phones'], {}),
		}));
	};

	const getPatientProvider = () => {
		let encounterProviders = [];
		if (isEncounterNeeded) {
			if (!gettingEncounterDetailLoading) {
				encounterProviders = getEncounterProvider();
			} else {
				return [];
			}
		}

		const unfilteredProviders = patientLinkedProviders.map((provider) => ({
			uuid: provider.providerUUID,
			email: get(provider, 'email', ''),
			fax: get(provider, ['phones', 'fax_office'], ''),
			provider_role: provider.providerRole || [],
			title: provider.title,
			is_archived: provider.isArchived || false,
			fullName: provider.providerFullName,
			phones: get(provider, ['phones'], {}),
		}));

		return unfilteredProviders.filter((prov) => {
			const hasEncounterProvider = encounterProviders.find(
				(encprov) => encprov.uuid == prov.uuid
			);
			return !hasEncounterProvider;
		});
	};

	const getSelectedProviders = (context, uuid) => {
		let contextKey = '';
		if (context == CONTEXT_ENCOUNTER) {
			contextKey = 'selectedEncounterProviders';
		} else if (context == CONTEXT_PATIENT) {
			contextKey = 'selectedPatientProviders';
		}
		return formdata[contextKey]?.[uuid] || {};
	};

	const getProvider = (context, uuid) => {
		let providers = [];
		if (context == CONTEXT_ENCOUNTER) {
			providers = getEncounterProvider();
		} else if (context == CONTEXT_PATIENT) {
			providers = getPatientProvider();
		}
		return providers.find((obj) => obj && obj.uuid == uuid);
	};

	const createReleaseDocumentObject = (formdata, provider) => {
		const dynamicMediumData = {
			medium: formdata.medium,
		};
		if (formdata.medium === FAX) {
			dynamicMediumData.fax = provider[FAX];
		}
		if (formdata.medium === EMAIL) {
			dynamicMediumData.email = provider[EMAIL];
		}

		return {
			type: 'released',
			recipient: {
				type: 'provider',
				uuid: get(provider, 'uuid'),
			},
			...dynamicMediumData,
		};
	};

	const submitReleaseDocument = () => {
		const updatedFormData = {};
		const referralProvider = {};
		const releaseExternalEHR = {};
		const propsDocumentReleaseToExtEhr = doc_release_to_ext_ehr;
		if (formdata.release_to_patient) {
			updatedFormData.type = formdata.type;
			updatedFormData.medium = 'patient_portal';
			if (!isNil(formdata?.send_email_notification)) {
				updatedFormData.send_email_notification =
					formdata.send_email_notification;
			}
		}

		if (
			formdata.release_to_partner &&
			can_be_released_api &&
			!released_api
		) {
			referralProvider.type = 'released';
			referralProvider.medium = 'api';
			referralProvider.recipient = {
				type: 'referral_program',
				uuid: null,
			};
		}

		if (formdata.release_to_external_EHR && propsDocumentReleaseToExtEhr) {
			releaseExternalEHR.medium = 'external_ehr';
			(releaseExternalEHR.recipient = {
				type: 'provider',
				uuid: null,
			}),
				(releaseExternalEHR.type = 'released');
		}

		const encounterProviders = map(
			formdata.selectedEncounterProviders || [],
			(selectedProvider, uuid) => {
				const provider = getProvider(CONTEXT_ENCOUNTER, uuid);
				return createReleaseDocumentObject(selectedProvider, provider);
			}
		);

		const patientProviders = map(
			formdata.selectedPatientProviders || [],
			(selectedProvider, uuid) => {
				const provider = getProvider(CONTEXT_PATIENT, uuid);
				return createReleaseDocumentObject(selectedProvider, provider);
			}
		);

		const releasedArray = [];

		if (!isEmpty(updatedFormData)) {
			releasedArray.push({
				...updatedFormData,
			});
		}

		if (!isEmpty(referralProvider)) {
			releasedArray.push({
				...referralProvider,
			});
		}

		if (!isEmpty(releaseExternalEHR)) {
			releasedArray.push({
				...releaseExternalEHR,
			});
		}

		doReleaseDocument(uuid, {
			data: [
				...releasedArray,
				...encounterProviders,
				...patientProviders,
			],
		});
	};

	const fnUpdateProvider = (context, uuid, key) => {
		const selectedProvider = getSelectedProviders(context, uuid);
		const value = selectedProvider?.value;
		const provider = getProvider(context, uuid);

		let payload = {};

		if (key === EMAIL) {
			payload = { email: value };
		} else if (key === FAX) {
			payload = {
				phones: { ...(provider?.phones || {}), fax_office: value },
			};
		}
		if (!isEmpty(payload)) {
			setIsProviderUpdating(true);
			updateProvider(uuid, payload)
				.then(() => {
					if (context == CONTEXT_ENCOUNTER) {
						getEncounter(encounter);
					} else if (context == CONTEXT_PATIENT) {
						getPatientLinkedProviders();
					}

					setIsProviderUpdating(false);
					onChangeProviderData(context, uuid, 'error', '');
				})
				.catch((err) => {
					const apiErrors = hydrateProviderCreateUpdateErrors(
						err.response?.data?.fields || {}
					);
					let val = '';

					for (const field in apiErrors) {
						const message = get(
							Object.values(apiErrors[field]),
							'[0]',
							''
						);
						if (message && typeof message === 'string') {
							if (field === 'email' && isEmpty(val)) {
								val = message;
							} else if (
								field === 'phones_fax_office' &&
								isEmpty(val)
							) {
								val = message;
							}
						}
					}

					setIsProviderUpdating(false);
					if (!isEmpty(val)) {
						onChangeProviderData(context, uuid, 'error', val);
					}
				});
		}
	};

	const onChange = (key, value) => {
		const newObj = { ...formdata };
		newObj[key] = value;
		setFormdata(newObj);
	};

	const isActiveStep = (step) => activeStep === step;
	const isNotSourceType = (src) => source !== src;

	const getPatientName = () => {
		if (patientDetail.has_guardian) {
			return (
				capitalize(patientDetail.guardian?.first_name || '') +
				' ' +
				capitalize(patientDetail.guardian?.last_name || '')
			);
		} else {
			return (
				capitalize(patientDetail.first_name || '') +
				' ' +
				capitalize(patientDetail.last_name || '')
			);
		}
	};

	const getPatientTag = () =>
		patientDetail.has_guardian ? 'Guardian' : 'Patient';

	const isDisabledContinue = () => {
		const selectedEncounterProviders = formdata.selectedEncounterProviders;
		const selectedPatientProviders = formdata.selectedPatientProviders;
		const releaseToEncounterProvider =
			!isEmpty(selectedEncounterProviders) &&
			!isNil(selectedPatientProviders);
		const releaseToPatientProvider =
			!isEmpty(selectedPatientProviders) &&
			!isNil(selectedPatientProviders);
		const isValidEncounterSelection =
			releaseToEncounterProvider && isValidSelection(CONTEXT_ENCOUNTER);
		const isValidPatientSelection =
			releaseToPatientProvider && isValidSelection(CONTEXT_PATIENT);
		const propsDocumentReleaseToExtEhr = doc_release_to_ext_ehr;
		const stateReleaseToExternalEHR = formdata.release_to_external_EHR;

		return !(
			(formdata.release_to_patient ||
				formdata.release_to_partner ||
				releaseToEncounterProvider ||
				releaseToPatientProvider ||
				(stateReleaseToExternalEHR && propsDocumentReleaseToExtEhr)) &&
			(!releaseToEncounterProvider || isValidEncounterSelection) &&
			(!releaseToPatientProvider || isValidPatientSelection)
		);
	};

	const isValidSelection = (context) => {
		let selectedProviders = {};
		let providers = [];

		if (context == CONTEXT_ENCOUNTER) {
			selectedProviders = formdata.selectedEncounterProviders;
			providers = getEncounterProvider();
		} else if (context == CONTEXT_PATIENT) {
			selectedProviders = formdata.selectedPatientProviders;
			providers = getPatientProvider();
		}

		const invalidselected = find(
			selectedProviders,
			(selectedProvider, uuid) => {
				const provider = getProvider(context, uuid);
				return (
					!(
						provider &&
						selectedProvider &&
						selectedProvider.medium
					) ||
					isEmpty(provider[selectedProvider.medium]) ||
					isNil(provider[selectedProvider.medium])
				);
			}
		);

		return !invalidselected;
	};

	const isEncounterProviderLoaded = isEncounterNeeded
		? !gettingEncounterDetailLoading
		: true;
	const loading =
		releasingDocument ||
		isProviderUpdating ||
		!isEncounterProviderLoaded ||
		gettingLinkedProviders;

	return (
		<div className='fullModalBackground'>
			<div className='release-document-modal-container'>
				{loading ? (
					<Loading loading={true} style={{ position: 'unset' }} />
				) : (
					<div className='top-container'>
						<div className='releaseDocumentTopRow'>
							<div className='title-container'>
								<div className='releaseDocumentTitle'>
									Release Document
								</div>
								<div className='steps-container'>
									<div
										className={classnames('step', {
											active: isActiveStep(
												STEP_SELECT_RECIPIENTS
											),
										})}
									>
										Select Recipients
									</div>
									<ReactSVG
										src={breadcrumbArrow}
										className='step-arrow'
									/>
									<div
										className={classnames('step', {
											active: isActiveStep(
												STEP_REVIEW_CONFIRM
											),
										})}
									>
										Review and Confirm
									</div>
								</div>
							</div>
							<div
								data-testId='log-doc-close'
								className='releaseDocumentClose'
								onClick={hideReleaseDocument}
							>
								<i className='el-dialog__close el-icon el-icon-close'></i>
							</div>
						</div>
						<div className='releaseDocumentBody'>
							{activeStep === STEP_SELECT_RECIPIENTS && (
								<SelectRecipients
									{...{
										canBeReleasedAPI: can_be_released_api,
										document: props.document,
										encounterProviders:
											getEncounterProvider(),
										formdata,
										getSelectedEncounterProviders: (uuid) =>
											getSelectedProviders(
												CONTEXT_ENCOUNTER,
												uuid
											),
										getSelectedPatientProviders: (uuid) =>
											getSelectedProviders(
												CONTEXT_PATIENT,
												uuid
											),
										isAlreadyReleasedToPP: released_to_pp,
										isPreviousReleasedToPP:
											previously_released_to_pp,
										isReleasedAPI: released_api,
										onChange,
										onChangeEncounterProviderData: (
											uuid,
											key,
											value
										) =>
											onChangeProviderData(
												CONTEXT_ENCOUNTER,
												uuid,
												key,
												value
											),
										onChangeEncounterProviderSelection: (
											uuid,
											value
										) =>
											onChangeProviderSelection(
												CONTEXT_ENCOUNTER,
												uuid,
												value
											),
										onChangePatientProviderData: (
											uuid,
											key,
											value
										) =>
											onChangeProviderData(
												CONTEXT_PATIENT,
												uuid,
												key,
												value
											),
										onChangePatientProviderSelection: (
											uuid,
											value
										) =>
											onChangeProviderSelection(
												CONTEXT_PATIENT,
												uuid,
												value
											),
										partnerName:
											patientDetail.referral_program ||
											'',
										patientName: getPatientName(),
										patientProviders: getPatientProvider(),
										patientTag: getPatientTag(),
										source,
										updateEncounterProvider: (uuid, key) =>
											fnUpdateProvider(
												CONTEXT_ENCOUNTER,
												uuid,
												key
											),
										updatePatientProvider: (uuid, key) =>
											fnUpdateProvider(
												CONTEXT_PATIENT,
												uuid,
												key
											),
									}}
								/>
							)}

							{activeStep === STEP_REVIEW_CONFIRM && (
								<ReviewAndConfirm
									{...{
										canBeReleasedToPartner:
											can_be_released_api &&
											!released_api,
										document: props.document,
										formdata,
										getEncounterProvider: (uuid) =>
											getProvider(
												CONTEXT_ENCOUNTER,
												uuid
											),
										getPatientProvider: (uuid) =>
											getProvider(CONTEXT_PATIENT, uuid),
										partnerName:
											patientDetail.referral_program ||
											'',
										patientName: getPatientName(),
										patientTag: getPatientTag(),
									}}
								/>
							)}
						</div>

						<div className='releaseDocumentFooter'>
							{isActiveStep(STEP_SELECT_RECIPIENTS) && (
								<>
									<Button
										className='releasedocument-continue-btn'
										disabled={isDisabledContinue()}
										onClick={() =>
											setActiveStep(STEP_REVIEW_CONFIRM)
										}
									>
										Continue
									</Button>
									{isNotSourceType('LIST') && (
										<Button
											className='releasedocument-skip-btn'
											onClick={hideReleaseDocument}
										>
											Skip
										</Button>
									)}
								</>
							)}

							{isActiveStep(STEP_REVIEW_CONFIRM) && (
								<>
									<Button
										className='releasedocument-continue-btn'
										onClick={submitReleaseDocument}
									>
										Release Document
									</Button>
									<Button
										className='releasedocument-skip-btn'
										onClick={() =>
											setActiveStep(
												STEP_SELECT_RECIPIENTS
											)
										}
									>
										Back
									</Button>
								</>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

ReleaseDocumentModal.propTypes = {
	hideReleaseDocument: PropTypes.func.isRequired,
	document: PropTypes.object.isRequired,
};

export default ReleaseDocumentModal;
