import React, { useEffect, useRef, useState } from 'react';
import ReactSVG from 'react-svg';
import { get, debounce, filter } from 'lodash';
import { Button } from 'gm-element-react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Breadcrumbs from './Breadcrumbs';
import SearchPatient from './SearchPatient';
import SelectEncounter from './SelectEncounter';
import {
	determineTopDropShadow,
	determineBottomDropShadow,
} from '../../../../../utils';
import { getpatients } from '../../../../../actions/patients';

import xIcon from '../../../../../assets/exit-alert.svg';
import './styles.css';

const PAGINATION_LIMIT = 7;
const steps = ['search-patient', 'select-encounter'];

const AddMultiVisitEncounterModal = (props) => {
	const {
		searchPatients,
		handleSubmit,
		hideMultiVisitEncounterModal,
		currentPatient,
		patientSearchResult,
	} = props;

	const defaultPatient = {
		loading: false,
		query: '',
		pagination: {
			offset: 0,
		},
		selected: null,
	};
	const defaultEncounter = {
		selected: null,
	};

	let tempTimeout = null;
	const bodyElement = useRef();

	const [topDropShadow, setTopDropShadow] = useState(false);
	const [bottomDropShadow, setBottomDropShadow] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [patient, setPatient] = useState(defaultPatient);
	const [encounter, setEncounter] = useState(defaultEncounter);

	useEffect(() => {
		const { query, pagination } = patient;
		if (query.length > 1) {
			tempTimeout = setTimeout(() => {
				clearTimeout(tempTimeout);
				const { query } = patient;
				if (query.length > 1) {
					setPatient((state) => ({
						...state,
						loading: false,
					}));
					searchPatients({
						q: query,
						limit: PAGINATION_LIMIT,
						primary: true,
						offset: _.get(pagination, 'offset', 0),
					});
				}
			}, 500);
		}
	}, [_.get(patient, 'query'), _.get(patient, 'pagination')]);

	const handleQueryChange = (evt) => {
		if (tempTimeout) clearTimeout(tempTimeout);

		const query = get(evt, ['target', 'value'], '');
		const newState = {
			...defaultPatient,
			query,
			loading: query.length > 1,
		};
		setPatient(newState);
	};

	const clearQuery = () => {
		setTopDropShadow(false);
		setBottomDropShadow(false);
		setCurrentStep(0);
		setPatient(defaultPatient);
		setEncounter(defaultEncounter);
		determineDropShadow(['top', 'bottom']);
	};

	const handlePageChange = (e, offset) => {
		setPatient((state) => ({ ...state, pagination: { offset } }));
	};
	const handleStep = (step) => {
		if (step) {
			const tempCurrentStep = currentStep + step;
			if (tempCurrentStep < steps.length && patient.selected) {
				if (step < 0) setEncounter({ ...defaultEncounter });
				setCurrentStep(tempCurrentStep);
				determineDropShadow(['top', 'bottom']);
			} else if (patient.selected && encounter.selected) {
				handleSubmit({ patient, encounter });
				hideMultiVisitEncounterModal();
			}
		}
	};

	const handleSelectPatient = (e, selected) => {
		if (selected.uuid !== currentPatient)
			setPatient((state) => ({ ...state, selected }));
	};

	const getEncounters = () => {
		const {
			selected: { uuid },
		} = patient;
		const {
			patients: {
				data: { encounter_info },
			},
		} = patientSearchResult;
		const encounters = encounter_info[uuid] || [];

		return filter(
			encounters,
			(encounter) =>
				get(encounter, ['type'], '') === 'visit' &&
				get(encounter, ['visit_status'], '') === 'completed' &&
				get(encounter, ['is_manual']) != true
		);
	};

	const handleSelectEncounter = (e, selected) => {
		setEncounter({ selected });
	};

	const determineShadow = (sides) => {
		if (Array.isArray(sides) && bodyElement.current) {
			sides.forEach((side) => {
				let shadow;
				switch (side) {
					case 'top':
						shadow = determineTopDropShadow(bodyElement.current);
						setTopDropShadow(shadow);
						break;
					case 'bottom':
						shadow = determineBottomDropShadow(bodyElement.current);
						setBottomDropShadow(shadow);
						break;
					default:
						console.log('Unrecognized modal side.');
						break;
				}
			});
		}
	};
	const determineDropShadow = debounce(
		(sildes) => determineShadow(sildes),
		500
	);

	return (
		<div className='popupModal'>
			<div className='addMultiVisitModal'>
				<div className={`topRow ${topDropShadow ? 'shadow' : ''}`}>
					<div>
						<p className='title'>Create Multi-patient Visit </p>
						<Breadcrumbs currentStep={steps[currentStep]} />
					</div>
					<ReactSVG
						src={xIcon}
						onClick={() => hideMultiVisitEncounterModal()}
						className='closeModal'
					/>
				</div>
				<div
					className='body'
					onScroll={(e) => determineDropShadow(['top', 'bottom'])}
					ref={bodyElement}
				>
					{!currentStep ? (
						<SearchPatient
							patient={patient}
							patientSearchResult={patientSearchResult}
							paginationLimit={PAGINATION_LIMIT}
							clearQuery={clearQuery}
							handleQueryChange={handleQueryChange}
							handleSelectPatient={handleSelectPatient}
							handlePageChange={handlePageChange}
							currentPatient={currentPatient}
						/>
					) : (
						<SelectEncounter
							patient={get(patient, ['selected'], {})}
							selectedEncounter={get(encounter, ['selected'], {})}
							encounters={getEncounters()}
							handleSelectEncounter={handleSelectEncounter}
						/>
					)}
				</div>
				<div
					className={`bottomRow ${bottomDropShadow ? 'shadow' : ''}`}
				>
					{currentStep && (
						<Button className='back' onClick={() => handleStep(-1)}>
							Back
						</Button>
					)}
					<Button
						className={`continue ${
							(!currentStep && patient.selected === null) ||
							(currentStep && encounter.selected === null)
								? 'disabled'
								: ''
						}`}
						onClick={() => handleStep(1)}
					>
						Continue
					</Button>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state, props) => {
	const { patients, loading } = state.patients;
	return {
		patientSearchResult: { patients, loading },
	};
};

const mapDispatchToProps = (dispatch) => ({
	searchPatients: (data) => dispatch(getpatients(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddMultiVisitEncounterModal);
