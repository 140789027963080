//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//Components
import ViewPatientInvoiceSegment from './ViewPatientInvoiceSegment';
import ViewInsuranceBillingSegment from './ViewInsuranceBillingSegment';
import ViewPartnerInvoiceSegment from './ViewPartnerInvoiceSegment';
import ViewBillingNotesSegment from './ViewBillingNotesSegment';

const ViewBillingSegment = (props) => {
	const {
		billingStatusEnums,
		encounterBillingDetail,
		encounterDetail,
		partnerInvoiceEnums,
		patientFeeEnum,
	} = props;

	return (
		<div className='body'>
			<ViewPatientInvoiceSegment
				{...{
					billingStatusEnums,
					encounterDetail,
					encounterBillingDetail,
					patientFeeEnum,
				}}
			/>
			<ViewInsuranceBillingSegment {...{ encounterBillingDetail }} />
			<ViewPartnerInvoiceSegment
				{...{
					encounterBillingDetail,
					partnerInvoiceEnums,
				}}
			/>
			<ViewBillingNotesSegment {...{ encounterBillingDetail }} />
		</div>
	);
};

export default ViewBillingSegment;
