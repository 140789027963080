import * as actionTypes from './actionTypes';
import NotesService from '../service/Notes';

const loadingNotes = () => {
	return {
		type: actionTypes.LOADING_NOTES,
		payload: true,
	};
};

export const notes = (data) => {
	return {
		type: actionTypes.NOTES,
		payload: data,
	};
};

const notesError = (err) => {
	return {
		type: actionTypes.NOTES_ERROR,
		payload: err,
	};
};

export const fetchNotes = (data) => {
	return {
		type: actionTypes.FETCH_NOTES,
		payload: data,
	};
};

export function getnotes(pid) {
	return (dispatch) => {
		dispatch(loadingNotes());
		NotesService.notes(pid)
			.then((res) => {
				dispatch(notes(res.data));
			})
			.catch((err) => {
				dispatch(notesError(err));
			});
	};
}

const loadingSubmitNotes = () => {
	return {
		type: actionTypes.LOADING_SUBMIT_NOTES,
		payload: true,
	};
};

const submitNotes = (data) => {
	return {
		type: actionTypes.SUBMIT_NOTES,
		payload: data,
	};
};

const submitNotesError = (err) => {
	return {
		type: actionTypes.SUBMIT_NOTES_ERROR,
		payload: err,
	};
};

export function submitnotes(data) {
	return (dispatch) => {
		dispatch(loadingSubmitNotes());
		return NotesService.submitnotes(data)
			.then((res) => {
				dispatch(submitNotes(res.data.data));
			})
			.catch((err) => {
				dispatch(submitNotesError(err));
			});
	};
}

export function deleteNotesError(err) {
	return {
		type: actionTypes.DELETE_NOTES_ERROR,
		payload: err,
	};
};

export function deleteNotesSuccess(data) {
	return {
		type: actionTypes.DELETE_NOTES_SUCCESS,
		payload: data,
	};
};

export function deleteLoadingNotes() {
	return {
		type: actionTypes.DELETE_LOADING_NOTES,
		payload: true,
	};
}

export function deleteNote(data) {
    return (dispatch) => {
		dispatch(deleteLoadingNotes());
        try {
            return NotesService.deleteNote(data)
			.then((res) => {
				dispatch(deleteNotesSuccess('Note deleted successfully'));
				return true
			})
			.catch((err) => {
				dispatch(deleteNotesError(err));
				throw err
			});
        } catch (err) {
            dispatch(deleteNotesError(err));
			throw err
        }
    }
};