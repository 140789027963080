//React & Redux
import React, { useEffect, useState } from 'react';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Components
import CommonCheckbox from '../../Common/Checkbox.js';

const TagSearchResultsRow = (props) => {
	const {
		handleInputChange,
		icdCode = {},
		isSelected = false,
		price = '--',
		selectedICDCodes = [],
		toggleCodeSelection,
	} = props;

	const { display_name = '--', id = null } = icdCode;

	const [input, setInput] = useState(price);

	useEffect(() => {
		if (isSelected && Array.isArray(selectedICDCodes)) {
			const selectedCode = selectedICDCodes.find(
				(el) => el.partner_id === id
			);
			let price = get(selectedCode, 'consultation_price', '--');
			if (!price && price !== 0) price = '--';
			setInput(price);
		}
	}, []);

	useEffect(() => {
		isSelected && handleInputChange(input, icdCode);
	}, [isSelected]);

	useEffect(() => {
		handleInputChange(input, icdCode);
	}, [input]);

	const handleOnInputChange = (e) => {
		setInput(e.target.value);
	};

	const checkboxClass = isSelected
		? 'chargeICDSearchResultRowCheckbox chargeICDSearchResultRowCheckboxSelected'
		: 'chargeICDSearchResultRowCheckbox';

	const textClass = isSelected ? ' chargeICDSearchResultRowSelectedText' : '';

	return (
		<div
			className='chargeICDSearchResultRow'
			style={{ justifyContent: 'space-between' }}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}
			>
				<CommonCheckbox
					additionalOnChangeArgs={[id, icdCode, input]}
					checked={isSelected}
					className={checkboxClass}
					onChangeFunc={toggleCodeSelection}
				/>
				<div
					className={'chargeICDSearchResultRowTitle' + textClass}
					style={{ maxWidth: '230px' }}
				>
					{display_name}
				</div>
			</div>
			{isSelected ? (
				<div className='enabledInputField'>
					$
					<input
						className='enabledInputFieldInner'
						onChange={handleOnInputChange}
						value={input}
					/>
				</div>
			) : (
				<div className='disabledInputField'>${price}</div>
			)}
		</div>
	);
};

export default TagSearchResultsRow;
