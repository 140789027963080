//React & Redux
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//Images
import ReactSVG from 'react-svg';
import archiveicon from '../../../../assets/archive.svg';
import alertIcon from '../../../../assets/alert.svg';

//Lodash
import { find, get, sortBy } from 'lodash';

//Actions & Services
import {
	LoadDocumentTypesForTemplate,
	LoadDocumentTypesForPatient,
	clearLoadDocumentTypesForTemplate,
	clearLoadDocumentTypesForPatient,
	CreateV2Document,
	uploadFile,
	UnloadDocument,
	LoadV2Documents,
	LoadDocumentDetail,
	getTemplatesFlexible,
	archiveV2Document,
	finalizeV2Document,
	UnloadDetailDocument,
	loadInlineDocumentDetail,
	documentAction,
	getWorkflowDocs,
	updateDocTypeAction,
	shareDocument,
	LoadDocumentMediums,
	LoadDocumentRecipients,
	logReleaseDocument,
	releaseDocument,
	combineDocuments,
} from '../../../../actions/documents';
import { LoadUsers, resetLoadUsers } from '../../../../actions/users';
import {
	encounterdetail,
	clearencounterdetail,
	getencounterlabseschema,
	getencounters,
} from '../../../../actions/encounter.js';
import { setFilter } from '../../../../actions/appData';

import {
	getReferralPrograms,
	getLinkedProviders,
} from '../../../../actions/patients';

import {
	updateProvider,
	getProviders,
	getProviderSpecialties,
	resetProviderState,
} from '../../../../actions/providers';

import { DocumentTabIndex, encounter_type } from '../../../../utils.js';
import {
	isPartnerInitiatedPreTest,
	isPartnerInitiatedUnsolicitedResult,
} from '../Encounters/Common/utilsfunctions';
import {
	ROLE_EXTERNALUSER,
	ROLE_PHYSICIAN,
	ROLE_EXTERNALUSER_READ_ONLY,
	ROLE_EXTERNALUSER_LAB,
} from '../../../../Utils/permissionUtils';

//Components
import DocumentList from './DocumentList';
import DocumentDetail from './DocumentDetail';
import DocumentViewDetail from './DocumentViewDetail';
import ChargeModal from '../../ChargeInformation/ChargeModal.js';

//Styles
import './style.css';
import './LogReleaseDocument.css';
import './ReleaseDocument/ReleaseDocumentModal.css';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious';

const DocumentsTab = (props) => {
	const {
		clearDocumentTypesForPatient,
		clearDocumentTypesForTemplate,
		documentDetailProps,
		documentListProps,
		documentViewDetailProps,
		documentsFilter,
		documentsFilter: {
			archiveSlider = false,
			documentListOrderBy: orderBy = 'encounter',
			documentListOrderType: orderType = 'desc',
		},
		enqueueSnackbar,
		getDocumentTemplates,
		getDocumentTemplatesFlexible,
		getDocumentTypesForPatient,
		getDocumentTypesForTemplate,
		getDocumentsV2,
		getEncouters,
		getInlineDocumentDetail,
		getWorkflowAffiliationDocs,
		innerstep,
		performDocumentAction,
		setDocumentsFilter,
		unloadDocument,
		updateDocumentType,
		uuid = '',
	} = props;

	const [showModal, setShowModal] = useState(false);
	const [showChargeModal, setShowChargeModal] = useState(false);
	const [partnerUuid, setPartnerUuid] = useState(null);
	const [dispatchFinalize, setDispatchFinalize] = useState(false);
	const [documentListOrderBy] = useState(orderBy);
	const [documentListOrderType] = useState(orderType);
	const [icd10Encounter, setIcd10Encounter] = useState(null);
	const [
		{
			modalAlertIcon,
			modalButton,
			modalDescription,
			modalHeader,
			modalHeight,
		},
		setModalState,
	] = useState({});

	const prevArchivingDocument = usePrevious(
		documentDetailProps.archivingDocument
	);

	useEffect(() => {
		getDocument(!archiveSlider, documentListOrderBy, documentListOrderType);
		const activeRole = get(
			documentListProps,
			['me', 'activerole', 'name'],
			''
		);
		const restrictedUser =
			activeRole in
			{
				[ROLE_PHYSICIAN]: 1,
				[ROLE_EXTERNALUSER]: 1,
				[ROLE_EXTERNALUSER_LAB]: 1,
				[ROLE_EXTERNALUSER_READ_ONLY]: 1,
			};
		if (!restrictedUser) {
			getWorkflowAffiliationDocs(formulatePartnerUuid());
		}
		getEncouters({
			id: uuid,
			offset: 0,
			limit: -1,
		});

		return () => {
			unloadDocument();
			clearDocumentTypesForTemplate();
			clearDocumentTypesForPatient();
		};
	}, []);

	useEffect(() => {
		if (
			!documentDetailProps.archivingDocument &&
			prevArchivingDocument &&
			documentDetailProps.archivedDocument
		) {
			documentDetailProps.navigateToDocumentList();
			documentDetailProps.enqueueSnackbar('Document archived.', {
				variant: 'info',
				anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
			});
		}
	}, [documentDetailProps.archivingDocument]);

	useEffect(() => {
		if (innerstep == DocumentTabIndex.LIST) {
			getDocument(
				!archiveSlider,
				documentListOrderBy,
				documentListOrderType
			);
			getEncouters({
				id: uuid,
				offset: 0,
				limit: -1,
			});
		}
	}, [innerstep]);

	const formulatePartnerUuid = () => {
		const patientPartnerId =
			documentListProps?.patientDetail?.partner_id || null;
		const affiliations = documentListProps?.affiliations?.data || [];
		const partnerUuidObj = affiliations.find(
			(affiliation) => affiliation.id === patientPartnerId
		);
		const partnerUuid = partnerUuidObj?.uuid || null;

		setPartnerUuid(partnerUuid);
		return partnerUuid;
	};

	const getComponent = () => {
		switch (innerstep) {
			case DocumentTabIndex.DETAIL:
				return (
					<DocumentDetail
						{...{
							...documentDetailProps,
							dispatchFinalize,
							encounterStringDisplay,
							getDocumentTypes: getDocumentTypesForTemplate,
							hideChargeModal,
							renderChargeModal,
							resetFinalizeDispatch,
							setModal,
							showChargeModal: handleShowChargeModal,
						}}
					/>
				);
			case DocumentTabIndex.VIEWDETAIL:
				return (
					<DocumentViewDetail
						{...{
							...documentViewDetailProps,
							downloadDocument,
							printDocument,
							setModal,
						}}
					/>
				);
			case DocumentTabIndex.LIST:
			default:
				return (
					<DocumentList
						{...{
							...documentListProps,
							createDocument,
							downloadDocument,
							enqueueSnackbar:
								documentDetailProps.enqueueSnackbar,
							getDocument,
							getDocumentTemplates,
							getDocumentTemplatesFlexible,
							getDocumentTypesForTemplate,
							getDocumentTypesForPatient,
							getInlineDocumentDetail,
							printDocument,
							partnerUuid,
							setModal,
							uploadDocument,
							updateDocumentType,
							getSpecialty,
							documentListOrderBy,
							documentListOrderType,
							setDocumentsFilter,
							documentsFilter,
							encounterStringDisplay,
							combineDocument,
						}}
					/>
				);
		}
	};

	const encounterStringDisplay = (encounter = {}) => {
		let encounterType = encounter_type[encounter.type] || '--';
		if (encounterType === 'SCP' && isPartnerInitiatedPreTest(encounter))
			encounterType = 'TRO';
		else if (
			encounterType === 'SCP' &&
			isPartnerInitiatedUnsolicitedResult(encounter)
		) {
			encounterType = 'TRO - Results Only';
		}
		const consultationType = encounter.consultation_type || '';
		const dateOfService =
			encounter.date_of_service || ''.split(' ')[0] || '--';
		return `${encounterType},${
			consultationType ? ' ' + consultationType.trim() + ',' : ''
		} ${dateOfService}`;
	};

	const archiveDocumentHandler = (uuid) => {
		documentDetailProps.archiveDocument(uuid || 0);
	};

	const getDocument = (active, order_by, order_type, show_loader) => {
		if (uuid != 0) {
			getDocumentsV2(
				uuid,
				'gdrive',
				active,
				order_by,
				order_type,
				show_loader
			);
		}
	};

	const createDocument = (
		encounteruuid,
		documenttype,
		patientuuid,
		template,
		description,
		documentSubType
	) => {
		documentListProps.createDocument(
			encounteruuid,
			documenttype,
			patientuuid,
			template,
			description,
			documentSubType
		);
	};

	const uploadDocument = (
		encounter_uuid,
		document_type,
		description,
		document_file,
		patientuuid,
		document_side,
		documentSubType
	) => {
		const formData = new FormData();
		if (encounter_uuid) formData.append('encounter_uuid', encounter_uuid);
		if (document_type) formData.append('document_type', document_type);
		if (description) formData.append('description', description);
		if (document_file) formData.append('document_file', document_file);
		if (document_side) formData.append('document_side', document_side);
		if (documentSubType)
			formData.append('document_subtype', documentSubType);
		const data = { payload: formData, patientuuid: patientuuid };

		documentListProps.uploadDocument(data);
	};

	const combineDocument = (data) => {
		documentListProps.combineDocumentAPI(data);
	};

	const downloadDocument = (e, documentObj) => {
		if (e != null) {
			e.preventDefault();
			e.stopPropagation();
		}
		performDocumentAction(documentObj, 'downloaded', uuid);
	};

	const printDocument = (e, documentObj) => {
		e.preventDefault();
		e.stopPropagation();
		performDocumentAction(documentObj, 'printed', uuid);
	};

	const setModal = (type, data) => {
		const handleOnClick = () => {
			archiveDocumentHandler(data);
			handleHideModal();
		};

		switch (type) {
			case 'archival':
				{
					const alertImage = (
						<ReactSVG
							src={alertIcon}
							style={{ position: 'relative', right: '4px' }}
						/>
					);
					const header = 'Confirmation';
					const description =
						'Are you sure you want to move this document to archive?';
					const button = (
						<div
							className='archivePopupButton'
							onClick={handleOnClick}
						>
							<ReactSVG
								src={archiveicon}
								style={{
									position: 'relative',
									bottom: '6px',
									right: '4px',
								}}
							/>
							<span>Archive</span>
						</div>
					);
					const height = 175;
					handleShowModal(
						alertImage,
						header,
						description,
						button,
						height
					);
				}
				break;
			default:
		}
	};

	const handleShowModal = (
		alertIcon,
		header,
		description,
		button,
		height
	) => {
		setShowModal(true);
		setModalState({
			modalAlertIcon: alertIcon,
			modalButton: button,
			modalDescription: description,
			modalHeader: header,
			modalHeight: height,
		});
	};

	const renderModal = () => {
		if (showModal) {
			return (
				<div
					className='documentsTabModalContainer'
					style={{ height: `${modalHeight}px` || '175px' }}
				>
					<div className='documentsTabModalHeaderRow'>
						{modalAlertIcon}{' '}
						<span style={{ position: 'relative', bottom: '2px' }}>
							{modalHeader}
						</span>
					</div>
					<div className='documentsTabModalDescription'>
						{modalDescription}
					</div>
					<div className='documentsTabModalButtonRow'>
						<div
							className='documentsTabModalCancelButton'
							onClick={handleHideModal}
						>
							Cancel
						</div>
						{modalButton}
					</div>
				</div>
			);
		}
	};

	const handleHideModal = () => {
		setShowModal(false);
		setModalState({});
	};

	const handleShowChargeModal = (encounterUuid) => {
		setShowChargeModal(true);
		setIcd10Encounter(encounterUuid);
	};

	const hideChargeModal = () => {
		setShowChargeModal(false);
	};

	const closeChargeModalAndDispatch = () => {
		hideChargeModal();
		dispatchFinalizeCommand();
	};

	const dispatchFinalizeCommand = () => {
		setDispatchFinalize(true);
	};

	const resetFinalizeDispatch = () => {
		setDispatchFinalize(false);
	};

	const getSpecialty = (specialty, noData = '--') => {
		const specialties = documentListProps.providerSpecialties || [];
		if (specialty && Array.isArray(specialties)) {
			const specialtyObj = find(specialties, ['name', specialty]);
			return specialtyObj?.display_name || noData;
		} else {
			return noData;
		}
	};

	const renderChargeModal = () => (
		<ChargeModal
			hideChargeModal={hideChargeModal}
			title={'Finalize Document'}
			getICDCodes={documentDetailProps.getICDCodes || null}
			icdCodes={documentDetailProps.icdCodes || null}
			icdCodesError={documentDetailProps.icdCodesError || null}
			icdCodesLoading={documentDetailProps.icdCodesLoading || null}
			icdCodesPlacement={documentDetailProps.icdCodesPlacement || null}
			enqueueSnackbar={enqueueSnackbar}
			selectedICDCodes={null}
			encounterUuid={icd10Encounter}
			shouldGetEncounter={true}
			getEncounter={documentDetailProps.getEncounter || null}
			icd10EncounterObj={documentDetailProps.encounter || null}
			gettingEncounterDetailError={
				documentDetailProps.gettingEncounterDetailError || null
			}
			gettingEncounterDetailLoading={
				documentDetailProps.gettingEncounterDetailLoading || null
			}
			unmountFunctions={[documentDetailProps.clearEncounterState || null]}
			encounterEditLoading={
				documentDetailProps.encounterEditLoading || null
			}
			encounterEditError={documentDetailProps.encounterEditError || null}
			editEncounter={documentDetailProps.editEncounter || null}
			willUseBilling={true}
			billingEncounter={documentDetailProps.billingEncounter || null}
			editBillingEncounter={
				documentDetailProps.editBillingEncounter || null
			}
			billingEncounterEditLoading={
				documentDetailProps.billingEncounterEditLoading || null
			}
			billingEncounterEditError={
				documentDetailProps.billingEncounterEditError || null
			}
			getAssociatedBillingEncounter={
				documentDetailProps.getAssociatedBillingEncounter || null
			}
			associatedBillingEncounter={
				documentDetailProps.associatedBillingEncounter || null
			}
			associatedBillingEncounterLoading={
				documentDetailProps.associatedBillingEncounterLoading || null
			}
			associatedBillingEncounterError={
				documentDetailProps.associatedBillingEncounterError || null
			}
			closeChargeModalAndDispatch={closeChargeModalAndDispatch}
		/>
	);

	return (
		<div>
			{showModal && <div className='popupModal'>{renderModal()}</div>}
			{showChargeModal && (
				<div className='popupModal'>{renderChargeModal()}</div>
			)}
			{getComponent()}
		</div>
	);
};

DocumentsTab.propTypes = {
	documentListProps: PropTypes.object,
	documentDetailProps: PropTypes.object,
	getDocumentTypesForTemplate: PropTypes.func.isRequired,
	getDocumentTypesForPatient: PropTypes.func.isRequired,
	uuid: PropTypes.string,
	innerstep: PropTypes.number,
};

const mapStateToProps = (state, props) => {
	const { history, patientdetail, uuid, innerstep, affiliations, me } = props;
	const {
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		getICDCodes,
		icdCodesPlacement,
		encounterEditLoading,
		encounterEditError,
		editEncounter,
		editBillingEncounter,
		billingEncounter,
		billingEncounterEditLoading,
		billingEncounterEditError,
		getAssociatedBillingEncounter,
		associatedBillingEncounter,
		associatedBillingEncounterLoading,
		associatedBillingEncounterError,
	} = props;
	const encounters = get(state, ['encounterlist', 'encounter', 'data'], []);
	const encountersLoading = get(state, ['encounterlist', 'loading'], false);
	const userRoles = get(state, ['UserRoles', 'data'], []);
	const PatientlinkedProviders = get(
		state,
		['linkedpatientproviders', 'linkedProviders'],
		[]
	);
	const gettingLinkedProviders = get(
		state,
		['linkedpatientproviders', 'gettingProviders'],
		false
	);
	const providers = get(state, ['providers', 'data'], []);
	const providerSpecialties = get(
		state,
		'providerEnums.providerSpecialties',
		[]
	);
	const documentsFilter = get(state, 'appData.documents', {});
	const {
		documentTypesForTemplate,
		documentTypesForTemplateError,
		documentTypesForTemplateLoading,
		documentTypesForTemplateLoaded,
		documentTypesForPatient,
		documentTypesForPatientError,
		documentTypesForPatientLoading,
		documentTypesForPatientLoaded,
		data,
		loading,
		loaded,
		error,
		docDetailLoading,
		docDetailLoaded,
		docDetailError,
		docDetail,
		templatesLoading,
		templatesLoaded,
		templatesError,
		templates,
		creatingDocument,
		createdDocument,
		creatingDocumentError,
		fileUploading,
		fileUploaded,
		fileUpload,
		fileUploadError,
		archivingDocument,
		archivedDocument,
		archivingDocumentError,
		finalizingDocument,
		finalizedDocument,
		finalizingDocumentError,
		inlineDocDetail,
		inlineDocDetailLoading,
		inlineDocDetailLoaded,
		inlineDocDetailError,
		documentAction,
		documentActionLoading,
		documentActionLoaded,
		documentActionError,
		affiliationWorkflowDocs,
		affiliationWorkflowDocsLoading,
		affiliationWorkflowDocsLoaded,
		affiliationWorkflowDocsError,
		updatingDocumentType,
		updatedDocumentType,
		updatingDocumentTypeError,
		sharingDocument,
		sharedDocument,
		sharingDocumentError,
		documentMediums,
		documentMediumsError,
		documentMediumsLoading,
		documentMediumsLoaded,
		documentRecipients,
		documentRecipientsError,
		documentRecipientsLoading,
		documentRecipientsLoaded,
		loggingReleaseDocument,
		loggedReleaseDocument,
		loggingReleaseDocumentError,
		releasingDocument,
		releasedDocument,
		releasedDocumentError,
		combiningDocument,
		combinedDocument,
		combineDocumentError,
	} = state.documents;

	const {
		encounter,
		gettingEncounterDetailLoading,
		gettingEncounterDetailError,
	} = state.encounterdetail;

	const updatingDocumentRelatedEncounter = get(
		state,
		'documents.updatingDocumentRelatedEncounter',
		null
	);
	const updatedDocumentRelatedEncounter = get(
		state,
		'documents.updatedDocumentRelatedEncounter',
		null
	);
	const updatedDocumentRelatedEncounterError = get(
		state,
		'documents.updatedDocumentRelatedEncounterError',
		null
	);

	const users = get(state, ['Users', 'data'], []);
	const userQuery = get(state, ['Users', 'query'], undefined);
	const usersLoading = get(state, ['Users', 'loading'], false);
	const labs = get(state, ['labsschema', 'schema', 'data', 'lab'], []);
	const referralPrograms = get(
		state,
		['referralPrograms', 'referralPrograms', 'data'],
		[]
	);
	const documentListStateProps = {
		affiliations,
		history,
		me,
		patientDetail: get(patientdetail, '0', {}),
		uuid,
		documentTypesForTemplate: sortBy(documentTypesForTemplate, [
			function (o) {
				return o.display_name;
			},
		]),
		documentTypesForTemplateError,
		documentTypesForTemplateLoading,
		documentTypesForTemplateLoaded,
		documentTypesForPatient: sortBy(documentTypesForPatient, [
			function (o) {
				return o.display_name;
			},
		]),
		documentTypesForPatientError,
		documentTypesForPatientLoading,
		documentTypesForPatientLoaded,
		documents: data,
		encounters: encounters,
		encountersLoading,
		loading,
		loaded,
		error,
		templatesLoading,
		templatesLoaded,
		templatesError,
		templates,
		creatingDocument,
		createdDocument,
		creatingDocumentError,
		archivingDocument,
		archivedDocument,
		archivingDocumentError,
		fileUploading,
		fileUploaded,
		fileUpload,
		fileUploadError,
		inlineDocDetail,
		inlineDocDetailLoading,
		inlineDocDetailLoaded,
		inlineDocDetailError,
		documentAction,
		documentActionLoading,
		documentActionLoaded,
		documentActionError,
		affiliationWorkflowDocs,
		affiliationWorkflowDocsLoading,
		affiliationWorkflowDocsLoaded,
		affiliationWorkflowDocsError,
		updatingDocumentType,
		updatedDocumentType,
		updatingDocumentTypeError,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		getICDCodes,
		encounterEditLoading,
		encounterEditError,
		editEncounter,
		editBillingEncounter,
		billingEncounter,
		billingEncounterEditLoading,
		billingEncounterEditError,
		getAssociatedBillingEncounter,
		associatedBillingEncounter,
		associatedBillingEncounterLoading,
		associatedBillingEncounterError,
		users,
		sharingDocument,
		sharedDocument,
		sharingDocumentError,
		userRoles,
		usersLoading,
		userQuery,
		updatingDocumentRelatedEncounter,
		updatedDocumentRelatedEncounter,
		updatedDocumentRelatedEncounterError,
		documentMediums,
		documentMediumsError,
		documentMediumsLoading,
		documentMediumsLoaded,
		documentRecipients,
		documentRecipientsError,
		documentRecipientsLoading,
		documentRecipientsLoaded,
		labs,
		referralPrograms,
		loggingReleaseDocument,
		loggedReleaseDocument,
		loggingReleaseDocumentError,
		releasingDocument,
		releasedDocument,
		releasedDocumentError,
		encounter,
		PatientlinkedProviders,
		gettingLinkedProviders,
		gettingEncounterDetailLoading,
		gettingEncounterDetailError,
		providers,
		providerSpecialties,
		combiningDocument,
		combinedDocument,
		combineDocumentError,
	};

	const documentDetailStateProps = {
		history,
		patientdetail: get(patientdetail, '0', {}),
		uuid,
		documentTypesForTemplate,
		documentTypesForPatient,
		document: docDetail,
		loading: docDetailLoading,
		error: docDetailError,
		loaded: docDetailLoaded,
		encounters: encounters,
		encountersLoading,
		archivingDocument,
		archivedDocument,
		archivingDocumentError,
		finalizingDocument,
		finalizedDocument,
		finalizingDocumentError,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		getICDCodes,
		encounterEditLoading,
		encounterEditError,
		editEncounter,
		editBillingEncounter,
		billingEncounter,
		billingEncounterEditLoading,
		billingEncounterEditError,
		getAssociatedBillingEncounter,
		associatedBillingEncounter,
		associatedBillingEncounterLoading,
		associatedBillingEncounterError,
		encounter,
		gettingEncounterDetailLoading,
		gettingEncounterDetailError,
		releasingDocument,
		releasedDocument,
		releasedDocumentError,
		PatientlinkedProviders,
		gettingLinkedProviders,
	};

	const documentViewDetailStateProps = {
		document: docDetail,
		inlineDocDetail,
		inlineDocDetailLoading,
		inlineDocDetailLoaded,
		inlineDocDetailError,
		documentActionLoading,
		documentActionLoaded,
		documentActionError,
		uuid,
		loading: docDetailLoading,
		archivingDocument,
	};

	return {
		documentDetailStateProps,
		documentListStateProps,
		uuid,
		innerstep,
		updatedDocumentType,
		documentViewDetailStateProps,
		documentsFilter,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { changeEncounterTab, match } = ownProps;
	const { enqueueSnackbar } = ownProps;
	const {
		documentDetailStateProps,
		documentListStateProps,
		uuid,
		innerstep,
		updatedDocumentType,
		documentViewDetailStateProps,
		documentsFilter,
	} = stateProps;
	const getDocumentsV2 = (
		patientuuid,
		type,
		active,
		order_by,
		order_type,
		show_loader
	) =>
		dispatch(
			LoadV2Documents(
				patientuuid,
				type,
				active,
				order_by,
				order_type,
				show_loader
			)
		);
	const unloadDocument = () => dispatch(UnloadDocument());
	const unloadDetailDocument = () => dispatch(UnloadDetailDocument());
	const getDocumentTypesForTemplate = () =>
		dispatch(LoadDocumentTypesForTemplate());
	const getDocumentTypesForPatient = () =>
		dispatch(LoadDocumentTypesForPatient());
	const clearDocumentTypesForTemplate = () =>
		dispatch(clearLoadDocumentTypesForTemplate());
	const clearDocumentTypesForPatient = () =>
		dispatch(clearLoadDocumentTypesForPatient());
	const getDocumentTemplatesFlexible = (uuid, docType, q) =>
		dispatch(getTemplatesFlexible(uuid, docType, q, 'active', 'templates'));
	const createDocument = (
		encounteruuid,
		documenttype,
		patientuuid,
		template,
		description,
		documentSubType
	) =>
		dispatch(
			CreateV2Document(
				encounteruuid,
				documenttype,
				patientuuid,
				template,
				description,
				documentSubType
			)
		);
	const uploadDocument = (data) => dispatch(uploadFile(data));
	const navigateToDocumentDetail = (uuid) =>
		changeEncounterTab(uuid, DocumentTabIndex.DETAIL);
	const navigateToViewDocumentDetail = (uuid) =>
		changeEncounterTab(uuid, DocumentTabIndex.VIEWDETAIL);
	const navigateToDocumentList = () =>
		changeEncounterTab(0, DocumentTabIndex.LIST);
	const getDocumentDetail = (document_uuid) =>
		dispatch(LoadDocumentDetail(uuid, document_uuid));
	const archiveDocument = (document_uuid) =>
		dispatch(archiveV2Document(uuid, document_uuid));
	const finalizeDocument = (document_uuid, data) =>
		dispatch(finalizeV2Document(uuid, document_uuid, data));
	const getInlineDocumentDetail = (documentObj, action, patientUuid) =>
		dispatch(loadInlineDocumentDetail(documentObj, action, patientUuid));
	const performDocumentAction = (documentObj, action, patientUuid) =>
		dispatch(documentAction(documentObj, action, patientUuid));
	const getWorkflowAffiliationDocs = (partner_uuid) =>
		dispatch(getWorkflowDocs(partner_uuid));
	const updateDocumentType = (docuemnt_uuid, data) =>
		dispatch(updateDocTypeAction(uuid, docuemnt_uuid, data));
	const loadUsers = (data) => dispatch(LoadUsers(data));
	const doShareDocument = (document_uuid, data) =>
		dispatch(shareDocument(uuid, document_uuid, data));
	const getEncounter = (uuid, doclear = true) =>
		dispatch(encounterdetail(uuid, doclear));
	const clearEncounterState = () => dispatch(clearencounterdetail());
	const clearUsers = () => dispatch(resetLoadUsers());
	const getDocumentMediums = (param, recipient_type) =>
		dispatch(LoadDocumentMediums(param, recipient_type));
	const getDocumentRecipients = (param) =>
		dispatch(LoadDocumentRecipients(param));
	const callLabsSchema = () => dispatch(getencounterlabseschema());
	const getRefPrograms = () => dispatch(getReferralPrograms());
	const doLogReleaseDocument = (document_uuid, data) =>
		dispatch(logReleaseDocument(uuid, document_uuid, data));
	const doreleasedocument = (document_uuid, data) =>
		dispatch(releaseDocument(uuid, document_uuid, data));
	const getPatientLinkedProviders = () => dispatch(getLinkedProviders(uuid));
	const callUpdateProvider = (provider_uuid, payload) =>
		dispatch(updateProvider(provider_uuid, payload));
	const getAllProviders = (data) => dispatch(getProviders(data));
	const getAllProviderSpecialties = (data) =>
		dispatch(getProviderSpecialties(data));
	const resetAllProviderState = () => dispatch(resetProviderState());
	const setDocumentsFilter = (data) => dispatch(setFilter('documents', data));
	const getEncouters = (data) => dispatch(getencounters(data));
	const combineDocumentAPI = (data) => dispatch(combineDocuments(uuid, data));

	const documentListProps = {
		...documentListStateProps,
		createDocument,
		navigateToDocumentDetail,
		navigateToViewDocumentDetail,
		uploadDocument,
		getDocumentTemplatesFlexible,
		loadUsers,
		doShareDocument,
		clearUsers,
		getDocumentMediums,
		getDocumentRecipients,
		callLabsSchema,
		getRefPrograms,
		doLogReleaseDocument,
		doreleasedocument,
		getPatientLinkedProviders,
		getEncounter,
		clearEncounterState,
		updateProvider: callUpdateProvider,
		getAllProviders,
		getAllProviderSpecialties,
		resetAllProviderState,
		combineDocumentAPI,
	};

	const documentDetailProps = {
		...documentDetailStateProps,
		getDocumentDetail,
		match,
		navigateToDocumentList,
		archiveDocument,
		finalizeDocument,
		enqueueSnackbar,
		unloadDetailDocument,
		getEncounter,
		clearEncounterState,
		doreleasedocument,
		getPatientLinkedProviders,
		updateProvider: callUpdateProvider,
	};

	const documentViewDetailProps = {
		...documentViewDetailStateProps,
		match,
		navigateToDocumentList,
		getInlineDocumentDetail,
		getDocumentDetail,
		navigateToViewDocumentDetail,
		enqueueSnackbar,
		unloadDetailDocument,
	};

	return {
		documentDetailProps,
		documentListProps,
		documentViewDetailProps,
		uuid,
		innerstep,
		getDocumentsV2,
		unloadDocument,
		getDocumentTypesForTemplate,
		getDocumentTypesForPatient,
		clearDocumentTypesForTemplate,
		clearDocumentTypesForPatient,
		getInlineDocumentDetail,
		performDocumentAction,
		getWorkflowAffiliationDocs,
		updateDocumentType,
		updatedDocumentType,
		enqueueSnackbar,
		getDocumentTemplatesFlexible,
		getAllProviders,
		getAllProviderSpecialties,
		resetAllProviderState,
		setDocumentsFilter,
		documentsFilter,
		getEncouters,
	};
};

export default connect(mapStateToProps, null, mergeProps)(DocumentsTab);
