//React & Redux
import React, { useEffect, useState } from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../../../assets/exit-alert.svg';

//Lodash
import { isEmpty, isNil, omitBy } from 'lodash';

//UI Libraries
import { Loading, Select, Cascader, Input, Button } from 'gm-element-react';

//Utils
import { CARE_PATIENTS_PATIENT_DOCUMENT_TYPE_UPDATE } from '../../../../Utils/permissionUtils';
import {
	documentDescriptionValidation,
	documentDescriptionLengthValidator,
	documentDescripitonRequiredValidation,
	documentTypeIsVisit,
	documentTypeIsAdminMisc,
	documentTypeIsRiskAssessment,
} from '../../../../Utils/documentUtils.js';

//Components
import RBAC from '../../../RBAC';

const EditDocumentDetail = (props) => {
	const {
		document: {
			can_change_encounter = false,
			can_change_type = false,
			deleted_at,
			description: document_description = '',
			document_subtype,
			document_type,
			encounter = null,
			file_extension_id,
			is_editable,
			parent = null,
			uuid: documentUuid = null,
		},
		documentTypesForPatient,
		documentTypesForPatientLoading = false,
		documentTypesForTemplate,
		documentTypesForTemplateLoading = false,
		encounterStringDisplay,
		encounters,
		encountersLoading = false,
		formulateDocTypes,
		getDocumentTypesForPatient,
		getDocumentTypesForTemplate,
		hideEditDocumentDetail,
		updateDocTypeHandler,
		updatingDocumentType = false,
		verifyDoc,
	} = props;

	const [description, setDescription] = useState(document_description);
	const [documentSubType, setDocumentSubType] = useState(
		document_subtype?.name || null
	);
	const [documentType, setDocumentType] = useState(
		document_type?.name || null
	);
	const [relatedEncounter, setRelatedEncounter] = useState(encounter);

	useEffect(() => {
		getDocumentTypesForPatient();
		getDocumentTypesForTemplate();
	}, []);

	const errorCheck = () => {
		const isDocumentTypeVisit = documentTypeIsVisit(documentType);

		let errors = false;

		if (!documentType) {
			errors = true;
		}

		if (can_change_type && isDocumentTypeVisit && !documentSubType) {
			errors = true;
		}

		if (can_change_encounter && isDocumentTypeVisit && !relatedEncounter) {
			errors = true;
		}

		if (documentTypeIsAdminMisc(documentType)) {
			if (!documentDescriptionValidation(description)) {
				errors = true;
			} else if (!documentDescriptionLengthValidator(description)) {
				errors = true;
			}
		}
		if (description && !documentDescriptionValidation(description)) {
			errors = true;
		}

		return errors;
	};

	const showErrorOnDocumentType = () =>
		can_change_type &&
		documentTypeIsVisit(documentType) &&
		!documentSubType;

	const determineDescritionErrorMessage = () => {
		if (documentTypeIsAdminMisc(documentType)) {
			if (!documentDescripitonRequiredValidation(description)) {
				return null;
			} else if (!documentDescriptionLengthValidator(description)) {
				return (
					<div
						className='inputFieldError'
						style={{ position: 'absolute', top: '100%' }}
					>
						Description should not be more than 40 characters.{' '}
					</div>
				);
			}
		}

		if (description && !documentDescriptionValidation(description)) {
			return (
				<div
					className='inputFieldError'
					style={{ position: 'absolute', top: '100%' }}
				>
					Invalid description.{' '}
				</div>
			);
		}

		return null;
	};

	const compareObj = (propsObj, stateObj) => {
		const payload = {};

		Object.keys(stateObj).forEach((key) => {
			if (propsObj[key] != stateObj[key]) {
				payload[key] = stateObj[key];
			}
		});

		return payload;
	};

	const changeDocumentDetail = () => {
		const payload = compareObj(
			{
				document_subtype: document_subtype?.name || null,
				document_type: document_type?.name || null,
				encounter_uuid: encounter,
				description: document_description,
			},
			{
				document_subtype: documentSubType,
				document_type: documentType,
				encounter_uuid: relatedEncounter,
				description,
			}
		);

		if (payload.document_subtype) {
			payload.document_type = 'visit_documentation';
		}

		if (!errorCheck()) {
			if (!isEmpty(payload)) {
				updateDocTypeHandler(documentUuid, omitBy(payload, isNil));
			} else {
				hideEditDocumentDetail();
			}
		}
	};

	const setValue = (e, type) => {
		switch (type) {
			case 'encounter':
				setRelatedEncounter(e);
				break;
			case 'document':
				setDocumentType(e[0] || null);
				setDocumentSubType(e[1] || null);
				break;
			case 'description':
				setDescription(e);
		}
	};

	const displayDocumentType = () => {
		const templateTypes =
			is_editable || (file_extension_id === 'pdf' && !isEmpty(parent));
		const IsRiskAssessmentDocType =
			documentTypeIsRiskAssessment(documentType);
		let docTypes = formulateDocTypes(
			templateTypes ? documentTypesForTemplate : documentTypesForPatient,
			true
		);
		docTypes = IsRiskAssessmentDocType
			? docTypes
			: docTypes.filter(
					(type) => !['risk_assessment'].includes(type.value)
			  );
		return (
			<RBAC
				action={CARE_PATIENTS_PATIENT_DOCUMENT_TYPE_UPDATE}
				yes={
					<>
						<Cascader
							data-testId='cascader'
							className={'doc-type-input createDocumentInputSelect createDocumentV2TypeSelect'}
							placeholder={'Select'}
							value={[
								documentType || '--',
								documentSubType || '--',
							].filter((el) => el)}
							onChange={(e) => setValue(e, 'document')}
							options={docTypes}
							expandTrigger={'hover'}
							disabled={
								!isNil(deleted_at) || !can_change_type
									? true
									: false
							}
						></Cascader>
						{showErrorOnDocumentType() && (
							<div className='error-type'>
								{' '}
								Please choose subtype
							</div>
						)}
					</>
				}
				no={
					<>
						<Cascader
							className={'doc-type-input createDocumentInputSelect createDocumentV2TypeSelect'}
							placeholder={'Select'}
							value={[
								documentType || '--',
								documentSubType || '--',
							].filter((el) => el)}
							options={docTypes}
							expandTrigger={'hover'}
							disabled={true}
						/>
					</>
				}
			/>
		);
	};

	const buttonClass = !errorCheck()
		? 'createDocumentV2ActionButton'
		: 'createDocumentV2ActionButton createDocumentV2ActionButtonDisabled';

	return encountersLoading ||
		updatingDocumentType ||
		documentTypesForPatientLoading ||
		documentTypesForTemplateLoading ? (
		<div className='fullModalBackground'>
			<div className='editDocumentContainer'>
				<Loading
					loading={true}
					style={{ top: 'calc(50% - 25px)', position: 'initial' }}
				/>
			</div>
		</div>
	) : (
		<div className='fullModalBackground'>
			<div className='editDocumentContainer'>
				<div className='createDocumentV2TopRow'>
					<div className='createDocumentV2HeaderBreadcrumbs'>
						<div
							className='createDocumentV2Header'
							style={{ position: 'relative', top: '9px' }}
						>
							{verifyDoc ? 'Verify' : 'Edit'} Document Details
						</div>
					</div>
					<div className='createDocumentV2Close'>
						<ReactSVG
							data-testId='hide-doc-detail'
							src={xIcon}
							style={{
								position: 'relative',
								bottom: '2px',
								right: '1.25px',
							}}
							onClick={() => hideEditDocumentDetail(true)}
						/>
					</div>
				</div>
				<div className='editDocumentBodyContainer'>
					<div className='createDocumentInputContainer'>
						{
							<div style={{ position: 'relative' }}>
								<div className='createDocumentRequired'>*</div>
							</div>
						}
						<div className='createDocumentInputHeader'>
							Document Type
						</div>
						{displayDocumentType()}
					</div>

					<div className='createDocumentInputContainer'>
						{can_change_encounter &&
							documentTypeIsVisit(documentType) && (
								<div style={{ position: 'relative' }}>
									<div className='createDocumentRequired'>
										*
									</div>
								</div>
							)}
						<div className='createDocumentInputHeader'>
							Related Encounter
						</div>
						<RBAC
							action={CARE_PATIENTS_PATIENT_DOCUMENT_TYPE_UPDATE}
							yes={
								<Select
									className={
										'createDocumentInputSelect createDocumentV2TypeSelect'
									}
									placeholder={'Select'}
									value={relatedEncounter}
									onChange={(e) => setValue(e, 'encounter')}
									style={{ width: 'unset' }}
									disabled={!can_change_encounter}
								>
									{encounters.map((el) => (
										<Select.Option
											label={encounterStringDisplay(el)}
											value={`${el.uuid}`}
											key={`${el.uuid}`}
										>
											{encounterStringDisplay(el)}
										</Select.Option>
									))}
								</Select>
							}
							no={
								<Select
									className={
										'createDocumentInputSelect createDocumentV2TypeSelect'
									}
									placeholder={'Select'}
									value={relatedEncounter}
									style={{ width: 'unset' }}
									disabled={true}
								>
									{encounters.map((el) => (
										<Select.Option
											label={encounterStringDisplay(el)}
											value={`${el.uuid}`}
											key={`${el.uuid}`}
										>
											{encounterStringDisplay(el)}
										</Select.Option>
									))}
								</Select>
							}
						/>
					</div>

					<div className='createDocumentInputContainer'>
						{documentTypeIsAdminMisc(documentType) && (
							<div style={{ position: 'relative' }}>
								<div className='createDocumentRequired'>*</div>
							</div>
						)}
						<div className='createDocumentInputHeader'>
							Description{' '}
							{documentTypeIsAdminMisc(documentType) &&
								'(visible to patients upon document release)'}
						</div>
						<Input
							type='textarea'
							name='document-description'
							autosize={false}
							placeholder='Enter a description'
							value={description}
							onChange={(e) => setValue(e, 'description')}
						/>
						{determineDescritionErrorMessage()}
					</div>
				</div>
				<div
					className='createDocumentV2TypeButtonRow'
					style={{
						bottom: '-20px',
						borderTop: '2px solid #edeff38c',
					}}
				>
					{verifyDoc ? (
						<div className='verify-doc-buttons'>
							<Button
								data-testId='cancel'
								className='cancel-verification'
								onClick={() => hideEditDocumentDetail(true)}
							>
								Cancel
							</Button>
							<div
								className={buttonClass}
								onClick={changeDocumentDetail}
							>
								Next
							</div>
						</div>
					) : (
						<div
							data-testId='save-close'
							className={buttonClass}
							onClick={changeDocumentDetail}
							style={{ position: 'relative', bottom: '2px' }}
						>
							Save & Close
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default EditDocumentDetail;
