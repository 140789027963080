import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { object, number, boolean, ZodIssueCode } from 'zod';

import Dropdown from '../../../../Common/FormFields/Dropdown';
import { Section } from '@gm/common/ui';
import { getDefaultSchedulingOptions } from '../../../../../actions/referralprograms/referralprograms';
import { getReferralPrograms } from '../../../../../actions/patients';
import { getReferralProgramDetail } from '../../../../../actions/referralprograms/referralprograms';

import { useSection } from './useSection';
import './ServiceAndProgram.scss';

const firstEncounterConsultationTypeError = 'Consultation type is required';
const firstEncounterSpecialtyTypeError = 'Specialty type is required';

/**
 * Zod validation schema for the section
 */
export const schemaServiceAndProgram = object({
	referralProgram: number({
		required_error: 'Referral program is required',
		invalid_type_error: 'Referral program is required',
	}),
	firstEncounterConsultationType: number({
		invalid_type_error: firstEncounterConsultationTypeError,
	}).nullable(),
	firstEncounterSpecialtyType: number({
		invalid_type_error: firstEncounterSpecialtyTypeError,
	}).nullable(),
	disabledServiceAndProgram: boolean(),
}).superRefine((value, ctx) => {
	if (!value.disabledServiceAndProgram) {
		if (!value.firstEncounterConsultationType) {
			ctx.addIssue({
				path: ['firstEncounterConsultationType'],
				code: ZodIssueCode.required_error,
				message: firstEncounterConsultationTypeError,
			});
		}
		if (!value.firstEncounterSpecialtyType) {
			ctx.addIssue({
				path: ['firstEncounterSpecialtyType'],
				code: ZodIssueCode.required_error,
				message: firstEncounterSpecialtyTypeError,
			});
		}
	}
});

/**
 * Initial values for the section
 */
export const initialServiceAndProgram = {
	referralProgram: undefined,
	firstEncounterConsultationType: undefined,
	firstEncounterSpecialtyType: undefined,
	disabledServiceAndProgram: false,
};

export function ServiceAndProgram({
	sectionDataContainerName,
	patientDetail,
	isEdit,
}) {
	const { errors, onChange, setFieldValue, touched, values } = useSection(
		sectionDataContainerName
	);

	const { referralPrograms, schedulingOptions, encounterList } = useSelector(
		(store) => ({
			referralPrograms:
				store.referralPrograms.referralPrograms.data ?? [],
			schedulingOptions:
				store.referralProgramsList.defaultSchedulingOptions.payload,
			encounterList: store?.encounterlist?.encounter?.data,
		})
	);
	const dispatch = useDispatch();

	const disabledServiceAndProgram = encounterList?.some(
		(encounter) => encounter.visit_status === 'booked'
	);

	useEffect(() => {
		dispatch(getReferralPrograms());
	}, []);

	useEffect(() => {
		if (disabledServiceAndProgram) {
			setFieldValue(
				'disabledServiceAndProgram',
				disabledServiceAndProgram
			);
		}
	}, [disabledServiceAndProgram]);

	useEffect(() => {
		if (
			referralPrograms.length === 1 &&
			values.referralProgram !== referralPrograms[0].id
		) {
			setFieldValue('referralProgram', referralPrograms[0].id);
		}

		const referralProgramId = patientDetail?.partner_id;

		const shouldUpdateFields =
			referralPrograms.length > 0 &&
			(referralProgramId || referralPrograms.length === 1);

		if (shouldUpdateFields) {
			const defaultSpecialtyId = patientDetail?.default_specialty_id;
			const defaultConsultationId =
				patientDetail?.default_consultation_id;

			setFieldValue(
				'firstEncounterConsultationType',
				defaultConsultationId
			);
			setFieldValue('firstEncounterSpecialtyType', defaultSpecialtyId);

			if (
				referralProgramId &&
				isEdit &&
				referralProgramId !== values.referralProgram
			) {
				setFieldValue('referralProgram', referralProgramId);
			}
		}
	}, [referralPrograms.length, patientDetail]);

	useEffect(() => {
		if (
			isEdit &&
			Object.keys(schedulingOptions).length > 0 &&
			values?.firstEncounterSpecialtyType &&
			values?.firstEncounterConsultationType
		) {
			const resetFieldIfInvalid = (options, field, value) => {
				if (!options?.some((item) => item.id === value)) {
					setFieldValue(field, undefined);
				}
			};

			resetFieldIfInvalid(
				schedulingOptions?.specialties,
				'firstEncounterSpecialtyType',
				values?.firstEncounterSpecialtyType
			);
			resetFieldIfInvalid(
				schedulingOptions?.consultations,
				'firstEncounterConsultationType',
				values?.firstEncounterConsultationType
			);
		}
	}, [isEdit, schedulingOptions]);

	useEffect(() => {
		const { referralProgram } = values;
		if (referralProgram && referralPrograms.length > 0) {
			const referralProgramDetails = referralPrograms.find(
				(item) => item.id === referralProgram
			);

			if (referralProgramDetails?.uuid) {
				const partnerUuid = referralProgramDetails.uuid;
				dispatch(
					getDefaultSchedulingOptions({ partner_uuid: partnerUuid })
				);
				dispatch(
					getReferralProgramDetail({ partner_uuid: partnerUuid })
				);
			}
		}

		if (!isEdit) {
			setFieldValue('firstEncounterSpecialtyType', undefined);
			setFieldValue('firstEncounterConsultationType', undefined);
		}
	}, [values.referralProgram, referralPrograms.length]);

	return (
		<Section className='service-and-program' title='Service &amp; Program'>
			<Dropdown
				required
				disabled={
					(isEdit && disabledServiceAndProgram) ||
					referralPrograms.length === 1
				}
				typeahead
				name='referralProgram'
				label='Referral Program'
				value={values.referralProgram}
				onChange={onChange('referralProgram')}
				options={referralPrograms.map((item) => ({
					label: item.display_name,
					value: item.id,
				}))}
				error={touched.referralProgram && errors.referralProgram}
			/>
			<Dropdown
				required={!disabledServiceAndProgram}
				disabled={isEdit && disabledServiceAndProgram}
				name='firstEncounterConsultationType'
				label='First Encounter Consultation Type'
				value={values.firstEncounterConsultationType}
				onChange={onChange('firstEncounterConsultationType')}
				options={schedulingOptions.consultations?.map((item) => ({
					label: item.name,
					value: item.id,
				}))}
				error={
					touched.firstEncounterConsultationType &&
					errors.firstEncounterConsultationType
				}
			/>
			<Dropdown
				required={!disabledServiceAndProgram}
				disabled={isEdit && disabledServiceAndProgram}
				name='firstEncounterSpecialtyType'
				label='First Encounter Specialty Type'
				value={values.firstEncounterSpecialtyType}
				onChange={onChange('firstEncounterSpecialtyType')}
				options={schedulingOptions.specialties?.map((item) => ({
					label: item.display_name,
					value: item.id,
				}))}
				error={
					touched.firstEncounterSpecialtyType &&
					errors.firstEncounterSpecialtyType
				}
				tooltip='This will pre-determine the first visit'
			/>
		</Section>
	);
}
