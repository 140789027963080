//React & Redux
import React, { useState } from 'react';

//Lodash
import { get } from 'lodash';

//UI Libraries
import TemplateRow from './TemplateRow.js';
import { Loading, Checkbox } from 'gm-element-react';

// Components
import { Table, Thead, Tbody, Tr, Th } from '../Common/Table';

const TemplateTable = (props) => {
	const {
		addSelectedTemplate,
		getRefProgNameFromUuid,
		getTemplateOwnerUuids,
		handleAllTemplatesToggle,
		handlePageChange,
		handleResetFilters,
		handleResultsPerPageChange,
		handleSort,
		handleTemplateSelectionChange,
		headerFields,
		isAllChecked,
		isLoading,
		isSomeChecked,
		limit,
		openArchiveModal,
		openChangeTemplateTypeModal,
		openDuplicateModal,
		openEditTemplateModal,
		openFinalizeDraftModal,
		openReferralProgramEditModal,
		openReferralProgramModal,
		order_by = '',
		order_type = '',
		pagination,
		removeSelectedTemplate,
		selectedTemplates,
		templateIsArchived,
		templateIsDraft,
		templates,
	} = props;

	const [loading, setLoading] = useState(false);
	const [noTemplates, setNoTemplates] = useState(false);

	const isNowLoading = isLoading() || loading;

	const sortBy = {
		key: order_by,
		value: order_type,
	};

	if (isNowLoading) {
		return (
			<Loading
				loading={true}
				style={{
					position: 'absolute',
					top: 'calc(50% - 50px)',
					left: 'calc(50%)',
				}}
			/>
		);
	} else {
		return (
			<Table
				style={{ marginTop: '16px', minWidth: '1204px' }}
				loading={isNowLoading}
				backToTop
				resultsPerPage={[25, 50, 100, 'All']}
				handleResultsPerPageChange={handleResultsPerPageChange}
				pagination={pagination}
				handlePageChange={handlePageChange}
				hasData={templates.length > 0}
				handleResetFilters={handleResetFilters}
				handleSort={handleSort}
				sortBy={sortBy}
				limit={limit}
			>
				<Thead>
					<Tr>
						<Th>
							<Checkbox
								checked={isAllChecked()}
								indeterminate={isSomeChecked()}
								onChange={(isChecked) =>
									handleAllTemplatesToggle(isChecked)
								}
							/>
						</Th>
						<Th
							sortable={{
								file_name: {
									display_name: 'Template Name',
									sortBy: {
										asc: 'A to Z',
										desc: 'Z to A',
									},
								},
							}}
						>
							Template Name
						</Th>
						<Th
							sortable={{
								document_type: {
									display_name: 'Type',
									sortBy: {
										asc: 'A to Z',
										desc: 'Z to A',
									},
								},
							}}
						>
							Type
						</Th>
						<Th
							sortable={{
								created_at: {
									display_name: 'Created',
									sortBy: {
										asc: 'Oldest First',
										desc: 'Newest First',
									},
								},
							}}
						>
							Created
						</Th>
						<Th
							sortable={{
								updated_at: {
									display_name: 'Modified',
									sortBy: {
										asc: 'A to Z',
										desc: 'Z to A',
									},
								},
							}}
						>
							Modified
						</Th>
						<Th>Referral Programs</Th>
						<Th />
					</Tr>
				</Thead>
				<Tbody>
					{templates.map((el, i) => {
						return (
							<TemplateRow
								template={el}
								headerFields={headerFields}
								selectedTemplates={selectedTemplates}
								handleTemplateSelectionChange={
									handleTemplateSelectionChange
								}
								addSelectedTemplate={addSelectedTemplate}
								removeSelectedTemplate={removeSelectedTemplate}
								getTemplateOwnerUuids={getTemplateOwnerUuids}
								getRefProgNameFromUuid={getRefProgNameFromUuid}
								key={get(el, 'uuid', i)}
								openReferralProgramModal={
									openReferralProgramModal
								}
								order_by={order_by || 'created_at'}
								templateIsArchived={templateIsArchived}
								templateIsDraft={templateIsDraft}
								openEditTemplateModal={openEditTemplateModal}
								openDuplicateModal={openDuplicateModal}
								openArchiveModal={openArchiveModal}
								openFinalizeDraftModal={openFinalizeDraftModal}
								openReferralProgramEditModal={
									openReferralProgramEditModal
								}
								openChangeTemplateTypeModal={
									openChangeTemplateTypeModal
								}
							/>
						);
					})}
				</Tbody>
			</Table>
		);
	}
};

export default TemplateTable;
