//React & Redux
import React from 'react';

//Images

//Lodash
import get from 'lodash/get';

//Utils
import { CARE_TEST_ACTIONS } from '../../Utils/permissionUtils';

//UI Libraries
import { Select } from 'gm-element-react';

//Components
import RBAC from '../RBAC';
import MultiSelectCheckbox from '../Filter/MultiSelectCheckbox';
import Searchbar from '../Filter/Searchbar';
import SingleSelect from './../Filter/SingleSelect';
import ResetFilters from './../Filter/ResetFilters';
import MoreFilters from './../Filter/MoreFilters';

class TestAuthFilterActionRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions
	getTests = () => {
		return get(this, ['props', 'testAuthEnums', 'genetic_tests'], [])
			.filter((el) => get(el, 'uuid', null) !== 'all')
			.map((el) => {
				return {
					display_name: `${el.name} (${el.count})`,
					key: el.uuid,
				};
			});
	};

	orderStatusEnum = () => {
		return get(this, ['props', 'testAuthEnums', 'order_status'], []).map(
			(el) => {
				return {
					external: `${el.display_name} (${el.count})`,
					internal: el.name,
				};
			}
		);
	};

	constructReferralProgramEnum = () => {
		return get(this, ['props', 'testAuthEnums', 'referral_programs'], [])
			.filter((el) => get(el, 'uuid', null) !== 'all')
			.map((el) => {
				return {
					key: get(el, 'uuid', null),
					display_name: `${get(el, 'name', '')} (${get(
						el,
						'count',
						''
					)})`,
				};
			});
	};

	getStatesList = () => {
		return get(this, ['props', 'testAuthEnums', 'patient_states'], [])
			.filter((el) => get(el, 'state', null) !== 'All')
			.map((el) => {
				return {
					key: get(el, 'state', null),
					display_name: `${get(el, 'state', '')} (${get(
						el,
						'count',
						''
					)})`,
				};
			});
	};

	redFlagFilterEnum = () => {
		return get(this, ['props', 'testAuthEnums', 'flag_types'], [])
			.filter((el) => get(el, 'flag_type', null) !== 'All')
			.map((el) => {
				return {
					key: get(el, 'flag_type', null),
					display_name: `${get(el, 'flag_type', '')} (${get(
						el,
						'count',
						''
					)})`,
				};
			});
	};

	render() {
		return (
			<div className='testAuthFilterActionRow'>
				<RBAC
					action={CARE_TEST_ACTIONS}
					yes={
						<div
							className={`testAuthApproveSelectedButton ${
								get(this, ['props', 'selectedLength'], 0) ==
									0 && 'disabled'
							}`}
							onClick={
								get(this, ['props', 'selectedLength'], 0) !=
									0 && this.props.approveBulk
							}
						>
							<span>
								Approve Selected ({this.props.selectedLength})
							</span>
						</div>
					}
				/>

				<div className='testauth-filter-container'>
					<Searchbar
						setStateFunc={(e) =>
							this.props.updateFilters(
								'searchQuery',
								get(e, ['target', 'value'], '')
							)
						}
						currentValue={get(this, 'props.searchQuery', '')}
						useInputEvtObj={true}
						placeholder='Search'
					/>

					<ResetFilters
						handleResetFilters={this.props.resetFilters}
					/>

					<MultiSelectCheckbox
						data={this.getStatesList()}
						title={'Patient State'}
						majorPillTitle={'Patient State'}
						selected={get(
							this,
							['props', 'patientShippingState'],
							[]
						)}
						searchBar={true}
						previewTab={true}
						submit={(e) =>
							this.props.updateFilters('patientShippingState', e)
						}
						loading={get(
							this,
							['props', 'testAuthEnumsLoading'],
							false
						)}
						componentClassName='patient-state-select-dropdown'
					/>

					<MultiSelectCheckbox
						data={this.constructReferralProgramEnum()}
						selected={get(this, ['props', 'referralPrograms'], [])}
						title={'Referral Programs'}
						submit={(values) =>
							this.props.updateFilters('referralPrograms', values)
						}
						majorPillTitle={'Referral Programs'}
						defaultValues={[]}
						searchBar={true}
						previewTab={true}
						alwaysNumber={false}
						zeroToAllPillTitle={true}
						loading={get(
							this,
							['props', 'testAuthEnumsLoading'],
							false
						)}
						componentClassName='referral-programs-select-dropdown'
					/>

					<MultiSelectCheckbox
						data={this.getTests()}
						title={'Tests'}
						majorPillTitle={'Tests'}
						selected={get(this, ['props', 'testsFilter'], [])}
						searchBar={true}
						previewTab={true}
						submit={(e) =>
							this.props.updateFilters('testsFilter', e)
						}
						loading={get(
							this,
							['props', 'testAuthEnumsLoading'],
							false
						)}
					/>

					<MultiSelectCheckbox
						data={this.redFlagFilterEnum()}
						title={'Clinical Flags'}
						majorPillTitle={'Clinical Flags'}
						selected={get(this, ['props', 'redFlagFilter'], [])}
						searchBar={true}
						previewTab={true}
						submit={(e) =>
							this.props.updateFilters('redFlagFilter', e)
						}
						loading={get(
							this,
							['props', 'testAuthEnumsLoading'],
							false
						)}
					/>

					<SingleSelect
						data={this.orderStatusEnum()}
						currentValue={get(
							this,
							'props.resultsStatusFilter',
							'requested'
						)}
						title={'Request Status'}
						setStateFunc={(e) =>
							this.props.updateFilters('resultsStatusFilter', e)
						}
						defaultValue='requested'
						loading={get(
							this,
							['props', 'testAuthEnumsLoading'],
							false
						)}
					/>

					<MoreFilters
						pillTitle='More'
						title='More Filters'
						data={[
							{
								type: 'checkbox',
								title: 'List Viewing Options',
								checked: get(this, ['props', 'cases'], true),
								defaultValue: true,
								text: 'Show only the requests I’m licensed to authorize',
								submitKey: 'cases',
							},
						]}
						submit={(values) =>
							this.props.updateFilters(
								'cases',
								get(values, ['cases'], true)
							)
						}
					/>

					{/* <Select
            className="templatesFilterDropdown"
            placeholder={"Cases"}
            value={get(this, "props.casesFilter", "licensed_states")}
            onChange={(e) => { this.props.updateFilters("casesFilter", e) }}
          >
            {(get(this, "props.cases", []) || []).map(el => {
              return (
                <Select.Option
                  label={get(el, "display_name", "--")}
                  value={get(el, "key", null)}
                  key={get(el, "key", null)}
                >
                  {get(el, "display_name", "--")}
                </Select.Option>
              )
            })}
          </Select> */}
				</div>
			</div>
		);
	}
}

export default TestAuthFilterActionRow;
