import { Loading as ELLoading } from 'gm-element-react';
import React from 'react';

import './Loading.scss';

/**
 * A fullscreen, center aligned, spinning loading indicator
 */
export const Loading: React.FC = () => {
	return (
		<div className='gm-loading'>
			<ELLoading loading fullscreen />
		</div>
	);
};
