//React & Redux
import React from 'react';

//UI Libraries
import { Dialog, Loading, Button } from 'gm-element-react';

const SendAuthToRecontactDialog = (props) => {
	const {
		sendAuthToRecontactDialogLoading,
		hideSendAuthToRecontactDialog,
		authToRecontactOutreachFunc,
	} = props;

	return (
		<Dialog
			customClass='send-auth-to-recontact-dialog'
			title='Send Authorization to Recontact'
			size='tiny'
			modal={true}
			closeOnClickModal={false}
			showClose={true}
			visible={true}
			onCancel={() => hideSendAuthToRecontactDialog()}
		>
			<Loading
				loading={sendAuthToRecontactDialogLoading}
				className='loader-send-auth-recontact'
			>
				<Dialog.Body>
					Are you sure you would like to send authorization to
					recontact to this patient?
				</Dialog.Body>
			</Loading>
			<Dialog.Footer className='dialog-footer'>
				<Button
					className='btn-send-auth-to-recontact-cancel'
					onClick={() => hideSendAuthToRecontactDialog(false)}
				>
					Cancel
				</Button>
				<Button
					className='btn-send-auth-to-recontact-confirm'
					onClick={() => authToRecontactOutreachFunc()}
				>
					<span className='btn-deceased-text'>Confirm</span>
				</Button>
			</Dialog.Footer>
		</Dialog>
	);
};

export default SendAuthToRecontactDialog;
