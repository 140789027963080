//React & Redux
import React from 'react';
import PropTypes from 'prop-types';

//Images
import up from '../../../assets/up.svg';
import down from '../../../assets/down.svg';

//UI Libraries
import { Checkbox } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';

const FilterCard = ({
	data,
	readonly = false,
	checkedcolor,
	onChange,
	disabled,
}) => {
	const ispositive = data && data.change && data.change >= 0;
	const changeextraclass = ispositive ? 'green' : 'red';
	const percentage =
		Math.round((Math.abs(data.change) + Number.EPSILON) * 100) / 100;
	const isZero = percentage == 0 ? true : false;
	return (
		<div
			className={classnames(
				'filtercard',
				readonly ? 'filtercard-readonly' : '',
				!readonly && disabled ? 'disabled-filtercard' : ''
			)}
			onClick={
				!readonly && !disabled
					? () => onChange(data.filterkey, !data.selected)
					: null
			}
		>
			<div className='titlerow'>
				<span>{data.title}</span>
				<span className={classnames('check', checkedcolor)}>
					{!readonly && (
						<Checkbox
							disabled={disabled}
							checked={data.selected}
							onChange={() =>
								onChange(data.filterkey, !data.selected)
							}
						/>
					)}
				</span>
			</div>
			<div className='datarow'>
				<span className='total'>{data.total}</span>
				<span
					className={classnames(
						'change',
						isZero ? 'gray' : changeextraclass
					)}
				>
					{!isZero && (
						<span>
							{ispositive ? (
								<img src={up} />
							) : (
								<img src={down} className='down' />
							)}
						</span>
					)}
					{percentage}%
				</span>
			</div>
		</div>
	);
};

FilterCard.propTypes = {
	readonly: PropTypes.bool,
	changefilter: PropTypes.func,
};

export default FilterCard;
