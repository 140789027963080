import React from 'react';

import get from 'lodash/get';

import ReactSVG from 'react-svg';
import xIcon from './../../../../assets/exit-alert.svg';

class TitleRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		const containerClass = get(this, 'props.shouldHaveDropShadow', false)
			? 'patientCreateV2TitleRow scrollableModalDropShadowTop'
			: 'patientCreateV2TitleRow';
		return (
			<div className={containerClass}>
				<div>
					{get(this, 'props.alternativeTitle', null) ||
						' Create Patient'}
				</div>
				{/* <div className="chargeModalClose" style={{ top: '0px' }}>

        </div> */}
			</div>
		);
	}
}

export default TitleRow;
