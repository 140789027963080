//React & Redux
import React, { useState } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	createEnumsOptions,
	CascaderOptions,
	findCascaderHierarchy,
	isValidDateStringOREmpty,
	isValidDateString,
	getMomentDateOrString,
	getMomentDateOrStringNonStrick,
} from '../Common/utilsfunctions';
import { preAuthFields } from './../../../../../Utils/encounterUtils';

//UI Libraries
import { Button, Loading } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import { EncounterSelectField } from '../Common/EncounterSelectField';
import { EncounterDateField } from '../Common/EncounterDateField';
import { EncounterCascaderField } from '../Common/EncounterCascaderField';
import { EncounterCheckBoxField } from '../Common/EncounterCheckBoxField';

const datefields = {
	date_test_ordered: 1,
	expected_lab_results_date: 1,
	date_received_report: 1,
	date_results_released_to_patient: 1,
	cap_sent_to_patient: 1,
	blood_draw_order_date: 1,
	date_test_authorized: 1,
};
const cascaderfields = {
	order_request_status: 1,
	order_status: 1,
};

const EditTestOrderSegment = (props) => {
	const {
		encounter,
		visitsschema,
		nonlegacytestnameenums,
		testnameenums,
		LoadTestEnums,
		saveSCPEncounter,
		cancel,
	} = props;

	const tempFormData = {
		order_status: _.get(encounter, ['order_status'], ''),
		lab: _.get(encounter, ['lab'], ''),
		test_name: _.get(encounter, ['test_name'], ''),
		multiple_labs: _.get(encounter, ['multiple_labs'], false),
		order_request_status: _.get(encounter, ['order_request_status'], ''),
		ordering_physician: _.get(encounter, ['ordering_physician'], ''),
		date_test_authorized: _.get(encounter, ['date_test_authorized'], ''),
		bd_saliva_kit_sent: _.get(encounter, ['bd_saliva_kit_sent'], ''),
		date_test_ordered: _.get(encounter, ['date_test_ordered'], ''),
		expected_lab_results_date: _.get(
			encounter,
			['expected_lab_results_date'],
			''
		),
		date_received_report: _.get(encounter, ['date_received_report'], ''),
		date_results_released_to_patient: _.get(
			encounter,
			['date_results_released_to_patient'],
			''
		),
		blood_draw_request: _.get(encounter, ['blood_draw_request'], 'na'),
		blood_draw_order_date: _.get(encounter, ['blood_draw_order_date'], ''),
	};
	/* some time we are getting date and time in datetime fields so we are converting it */
	const updatedformdata = _.mapValues(tempFormData, function (value, key) {
		if (key in datefields) return getMomentDateOrStringNonStrick(value);
		return value;
	});

	const [formData, setFormData] = useState(updatedformdata);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	const isLabEditable = () => {
		let result = false;
		const order_status = _.get(formData, ['order_status'], '');
		const noneditableorder_status = {
			results_entered: 1,
			results_ready: 1,
		};
		const test_name = _.get(formData, ['test_name'], '');
		const lab = _.get(formData, ['lab'], '');
		if (!(order_status in noneditableorder_status)) {
			if (lab == '' && test_name == '') {
				result = true;
			} else if (test_name == '') {
				result = true;
			} else if (lab != '' && test_name != '') {
				/* check that test_name should be is_legacy false */
				result = !islegacyTest(test_name);
			} else {
				result = false;
			}
		}
		return result;
	};

	const isSelectedLabEmpty = () => {
		const lab = _.get(formData, ['lab'], '');
		const labschema = _.get(visitsschema, ['lab'], []);
		const labvalue = labschema.find((a) => a.key == lab);
		return labvalue && _.get(labvalue, 'display_name', '') == '';
	};
	const isSelectedTestNameEmpty = () => {
		const testName = _.get(formData, ['test_name'], '');
		const testvalue = (nonlegacytestnameenums || []).find(
			(a) => a.key == testName
		);
		return testvalue && _.get(testvalue, 'display_name', '') == '';
	};

	const isTestNameEditable = () => {
		let result = false;
		const order_status = _.get(formData, ['order_status'], '');
		const noneditableorder_status = {
			results_entered: 1,
			results_ready: 1,
		};
		const test_name = _.get(formData, ['test_name'], '');
		const lab = _.get(formData, ['lab'], '');
		if (!(order_status in noneditableorder_status)) {
			if (lab == '') {
				result = false;
			} else if (lab != '' && test_name == '') {
				result = !isSelectedLabEmpty();
			} else if (lab != '' && test_name != '') {
				/* check that TestType should be is_legacy false */
				result = !islegacyTest(test_name);
			} else {
				result = true;
			}
		}
		return result;
	};

	const islegacyTest = (test) => {
		const unfiltertest = testnameenums || [];
		const matchtest = _.find(unfiltertest, function (element) {
			return element && element.key && element.key === test;
		});
		if (!matchtest) return false;
		return (
			matchtest &&
			_.isBoolean(matchtest.is_legacy) &&
			matchtest.is_legacy == true
		);
	};

	const isOrderStatusReadOnly = () => {
		return _.get(encounter, ['order_status'], '') == 'results_entered';
	};

	const isDateTestOrderedEditable = () => {
		return _.get(formData, ['order_status'], '') == 'sent_to_lab';
	};

	const isExpectedLabResultsDateEditable = () => {
		return isValidDateString(_.get(formData, ['date_test_ordered'], ''));
	};

	const isDateReceivedReportEditable = () => {
		return _.get(formData, ['order_status'], '') == 'results_ready';
	};
	const validateForm = (formdata) => {
		let errors = {};
		const datetimeerors = _.omitBy(
			_.mapValues(datefields, function (value, key) {
				if (!isValidDateStringOREmpty(_.get(formdata, key, ''))) {
					return 'Invalid date';
				}
				return undefined;
			}),
			_.isUndefined
		);

		errors = { ...datetimeerors };

		const EmptyLab =
			_.isEmpty(_.get(formdata, 'lab', '')) || isSelectedLabEmpty();
		const TestNameEmpty =
			_.isEmpty(_.get(formdata, 'test_name', '')) ||
			isSelectedTestNameEmpty();
		if (
			formdata['order_status'] == 'sent_to_lab' &&
			_.get(formdata, ['date_test_ordered', 'length'], 0) == 0
		) {
			errors['date_test_ordered'] = 'Test Dispatch Date is required';
		}
		if (
			formdata['order_status'] == 'results_ready' &&
			_.get(formdata, ['date_received_report', 'length'], 0) == 0
		) {
			errors['date_received_report'] = 'Report Receipt Date is required';
		}
		if (
			_.isEmpty(_.get(formdata, 'lab', '')) &&
			!_.isEmpty(_.get(formdata, 'test_name', ''))
		) {
			errors['lab'] = 'Lab is required';
		}
		if (
			EmptyLab &&
			(!_.isEmpty(_.get(formdata, 'order_status', '')) ||
				!(
					_.get(formdata, 'order_request_status') in
					{
						'': 1,
						empty: 1,
						na: 1,
						not_requested: 1,
						patient_pending_pa: 1,
					}
				))
		) {
			errors['lab'] = 'Lab is required';
		}
		if (
			TestNameEmpty &&
			!_.isEmpty(_.get(formdata, 'lab', '')) &&
			!isSelectedLabEmpty()
		) {
			errors['test_name'] = 'Test Name is required';
		}
		if (
			TestNameEmpty &&
			!EmptyLab &&
			(!_.isEmpty(_.get(formdata, 'order_status', '')) ||
				!(
					_.get(formdata, 'order_request_status') in
					{
						'': 1,
						empty: 1,
						na: 1,
						not_requested: 1,
						patient_pending_pa: 1,
					}
				))
		) {
			errors['test_name'] = 'Test Name is required';
		}
		if (
			_.isEmpty(_.get(formdata, ['ordering_physician'])) &&
			_.get(formdata, 'order_request_status') in
				{ approved: 1, no_test_available: 1, rejected_other: 1 } &&
			!(
				_.get(formdata, ['order_status']) in
				{ awaiting_external_approval: 1 }
			)
		) {
			errors['ordering_physician'] = 'Ordering Physician is required';
		}

		if (
			_.isEmpty(_.get(formdata, ['ordering_physician'])) &&
			!_.isEmpty(_.get(formdata, ['order_status'])) &&
			!(
				_.get(formdata, ['order_status']) in
				{ awaiting_external_approval: 1 }
			) &&
			!(_.get(formdata, 'order_request_status') in preAuthFields)
		) {
			errors['ordering_physician'] = 'Ordering Physician is required';
		}
		if (
			_.get(formdata, ['date_test_authorized', 'length'], 0) == 0 &&
			_.get(formdata, 'order_request_status') in
				{ approved: 1, no_test_available: 1, rejected_other: 1 } &&
			!(
				_.get(formdata, ['order_status']) in
				{ awaiting_external_approval: 1 }
			)
		) {
			errors['date_test_authorized'] =
				'Test Authorization date is required';
		}

		if (
			_.get(formdata, ['date_test_authorized', 'length'], 0) == 0 &&
			!_.isEmpty(_.get(formdata, ['order_status'])) &&
			!(
				_.get(formdata, ['order_status']) in
				{ awaiting_external_approval: 1 }
			) &&
			!(_.get(formdata, 'order_request_status') in preAuthFields)
		) {
			errors['date_test_authorized'] =
				'Test Authorization date is required';
		}
		if (
			!_.isEmpty(_.get(formdata, ['order_status'])) &&
			_.get(formdata, 'order_request_status') in
				{
					'': 1,
					empty: 1,
					na: 1,
					not_requested: 1,
					declined_other: 1,
					ins_denied_cost: 1,
					iss_w_test_implications: 1,
					no_fu_from_patient: 1,
					no_test_available: 1,
					rejected_other: 1,
				}
		) {
			errors['order_request_status'] = 'Order request status is required';
		}

		return errors;
	};

	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		let stateErrors = {};
		stateData[key] = value;
		if (key === 'date_test_ordered') {
			if (_.isNil(value) || _.isEmpty(value)) {
				stateData[key] = '';
				stateData['expected_lab_results_date'] = '';
			} else if (isValidDateString(value)) {
				const momentobj = getMomentDateOrString(value);
				stateData['expected_lab_results_date'] = moment.isMoment(
					momentobj
				)
					? momentobj.add(35, 'days').format('YYYY-MM-DD')
					: '';
			}
		} else if (key == 'lab') {
			LoadTestEnums(value);
			stateData['test_name'] = '';
			stateErrors = validateForm(stateData);
			setErrors(stateErrors);
		} else if (key in cascaderfields) {
			stateData[key] = _.last(value);
		}

		if (!(key in datefields)) {
			stateErrors = validateForm(stateData);
		}
		setFormData(stateData);
		setErrors(stateErrors);
	};

	const handleDateFieldValidate = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		if (key === 'date_test_ordered') {
			if (_.isNil(value) || _.isEmpty(value)) {
				stateData[key] = '';
				stateData['expected_lab_results_date'] = '';
			} else if (isValidDateString(value)) {
				const momentobj = getMomentDateOrString(value);
				stateData['expected_lab_results_date'] = moment.isMoment(
					momentobj
				)
					? momentobj.add(35, 'days').format('YYYY-MM-DD')
					: '';
			}
		}
		errors = validateForm(stateData);
		setErrors(errors);
		setFormData(stateData);
	};

	const saveForm = () => {
		const errors = validateForm(formData);
		if (_.isEmpty(errors)) {
			const updatedformdata = _.mapValues(
				formData,
				function (value, key) {
					if (key in datefields && _.isNil(value)) {
						return '';
					}
					if (key in cascaderfields) {
						if (_.isArray(value)) {
							return _.last(value);
						}
					}
					if (value == 'empty') return '';
					return value;
				}
			);
			setLoading(true);
			saveTROEncounter(encounter, updatedformdata)
				.then((result) => {
					setTimeout(() => {
						setLoading(false);
						cancel();
					}, 1000);
				})
				.catch((error) => {
					setLoading(false);
				});
		} else {
			setErrors(errors);
		}
	};

	CancelForm = () => {
		LoadTestEnums(_.get(encounter, ['lab'], ''));
		cancel();
	};

	return (
		<Loading loading={loading}>
			<div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Order Request Status
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>Lab</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Test Name
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Ordering Physician
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Test Authorization Date
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterCascaderField
									name={'order_request_status'}
									value={findCascaderHierarchy(
										orderrequeststatuscascadeenums,
										'key',
										_.get(
											formData,
											['order_request_status'],
											''
										)
									)}
									options={CascaderOptions(
										orderrequeststatuscascadeenums,
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'order_request_status',
											value
										)
									}
									error={_.get(
										errors,
										['order_request_status'],
										''
									)}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(formData, ['lab'], '')}
									name='lab'
									disabled={!isLabEditable()}
									options={createEnumsOptions(
										visitsschema,
										['lab'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('lab', value)
									}
									error={_.get(errors, ['lab'], '')}
									typeahead={true}
									clearable={true}
								/>
							</div>
							<div className='encounter-input-section'>
								<EncounterCheckBoxField
									disabled={!isLabEditable()}
									checked={
										_.get(
											formData,
											['multiple_labs'],
											false
										) == true
											? true
											: undefined
									}
									label={
										<span className='encounter-field-header'>
											Multiple Labs
										</span>
									}
									name={'multiplelabel'}
									onChange={(value) =>
										onFormChange('multiple_labs', value)
									}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(formData, ['test_name'], '')}
									name='test_name'
									disabled={!isTestNameEditable()}
									options={
										isTestNameEditable()
											? createEnumsOptions(
													props,
													['nonlegacytestnameenums'],
													'key',
													'display_name',
													'key'
											  )
											: createEnumsOptions(
													props,
													['testnameenums'],
													'key',
													'display_name',
													'key'
											  )
									}
									onChange={(value) =>
										onFormChange('test_name', value)
									}
									error={_.get(errors, ['test_name'], '')}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['ordering_physician'],
										''
									)}
									name='ordering_physician'
									options={createEnumsOptions(
										props,
										['ordering_physicianenums'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'ordering_physician',
											value
										)
									}
									error={_.get(
										errors,
										['ordering_physician'],
										''
									)}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(
										formData,
										['date_test_authorized'],
										''
									)}
									name='date_test_authorized'
									placeholder='Date'
									onChange={(value) =>
										onFormChange(
											'date_test_authorized',
											value
										)
									}
									error={_.get(
										errors,
										['date_test_authorized'],
										''
									)}
									onBlur={(value) =>
										handleDateFieldValidate(
											'date_test_authorized',
											value
										)
									}
									onRight={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Order Status
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								BD Saliva Kit Status
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Test Dispatch Date
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Expected Lab Result Date
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Report Receipt Date
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterCascaderField
									name={'order_status'}
									disabled={isOrderStatusReadOnly()}
									value={findCascaderHierarchy(
										orderstatuscascadeenums,
										'key',
										_.get(formData, ['order_status'], '')
									)}
									options={
										isOrderStatusReadOnly()
											? CascaderOptions(
													orderstatuscascadeenums,
													'key',
													'display_name',
													'key'
											  )
											: CascaderOptions(
													filteredorderstatuscascadeenums,
													'key',
													'display_name',
													'key'
											  )
									}
									onChange={(value) =>
										onFormChange('order_status', value)
									}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['bd_saliva_kit_sent'],
										''
									)}
									name='bd_saliva_kit_sent'
									options={createEnumsOptions(
										visitsschema,
										['bd_saliva_kit_sent'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'bd_saliva_kit_sent',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(
										formData,
										['date_test_ordered'],
										''
									)}
									name='date_test_ordered'
									disabled={!isDateTestOrderedEditable()}
									onChange={(value) =>
										onFormChange('date_test_ordered', value)
									}
									error={_.get(
										errors,
										['date_test_ordered'],
										''
									)}
									onBlur={(value) =>
										handleDateFieldValidate(
											'date_test_ordered',
											value
										)
									}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(
										formData,
										['expected_lab_results_date'],
										''
									)}
									name='expected_lab_results_date'
									disabled={
										!isExpectedLabResultsDateEditable()
									}
									onChange={(value) =>
										onFormChange(
											'expected_lab_results_date',
											value
										)
									}
									disabledDate={(current) => {
										const yesterday = _.get(
											formData,
											'date_test_ordered'
										);
										const momentcurrent = moment(current);
										if (!_.isNil(yesterday))
											return momentcurrent.isAfter(
												moment(yesterday)
													.clone()
													.add(34, 'days')
											);
										else return true;
									}}
									placeholder='Date'
									error={_.get(
										errors,
										['expected_lab_results_date'],
										''
									)}
									onBlur={(value) =>
										handleDateFieldValidate(
											'expected_lab_results_date',
											value
										)
									}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(
										formData,
										['date_received_report'],
										''
									)}
									name='date_received_report'
									disabled={!isDateReceivedReportEditable()}
									onChange={(value) =>
										onFormChange(
											'date_received_report',
											value
										)
									}
									error={_.get(
										errors,
										['date_received_report'],
										''
									)}
									onBlur={(value) =>
										handleDateFieldValidate(
											'date_received_report',
											value
										)
									}
									onRight={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Result Release Date
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Blood Draw Request
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Blood Draw Order Date
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(
										formData,
										['date_results_released_to_patient'],
										''
									)}
									name='date_results_released_to_patient'
									onChange={(value) =>
										onFormChange(
											'date_results_released_to_patient',
											value
										)
									}
									error={_.get(
										errors,
										['date_results_released_to_patient'],
										''
									)}
									onBlur={(value) =>
										handleDateFieldValidate(
											'date_results_released_to_patient',
											value
										)
									}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['blood_draw_request'],
										''
									)}
									name='blood_draw_request'
									options={createEnumsOptions(
										visitsschema,
										['blood_draw_request'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'blood_draw_request',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(
										formData,
										['blood_draw_order_date'],
										''
									)}
									name='blood_draw_order_date'
									onChange={(value) =>
										onFormChange(
											'blood_draw_order_date',
											value
										)
									}
									error={_.get(
										errors,
										['blood_draw_order_date'],
										''
									)}
									onBlur={(value) =>
										handleDateFieldValidate(
											'blood_draw_order_date',
											value
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-footer-container'>
					<div className='cancel-btn-link'>
						<Button onClick={CancelForm}>Cancel</Button>
					</div>
					<div className='save-btn-link'>
						<Button style={{ float: 'right' }} onClick={saveForm}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</Loading>
	);
};

export default EditTestOrderSegment;
