import * as actionTypes from '../actions/actionTypes';

export const defaultStateDocumentTypesForTemplate = {
	documentTypesForTemplate: [],
	documentTypesForTemplateError: null,
	documentTypesForTemplateLoading: false,
	documentTypesForTemplateLoaded: false,
};

export const defaultStateDocumentTypesForPatient = {
	documentTypesForPatient: [],
	documentTypesForPatientError: null,
	documentTypesForPatientLoading: false,
	documentTypesForPatientLoaded: false,
};

export const defaultStateDocumentTypesForWorkflow = {
	documentTypesForWorkflow: [],
	documentTypesForWorkflowError: null,
	documentTypesForWorkflowLoading: false,
	documentTypesForWorkflowLoaded: false,
};

export const defaultDocumentDetail = {
	docDetailLoading: false,
	docDetailLoaded: false,
	docDetailError: null,
	docDetail: {},
};

const archiveDocumentState = {
	archivingDocument: false,
	archivedDocument: null,
	archivingDocumentError: null,
};

const defaultFinalizeDocumentState = {
	finalizingDocument: false,
	finalizedDocument: null,
	finalizingDocumentError: null,
};

const updateDocumentTypeState = {
	updatingDocumentType: false,
	updatedDocumentType: null,
	updatingDocumentTypeError: null,
};

const defaultshareDocumentState = {
	sharingDocument: false,
	sharedDocument: null,
	sharingDocumentError: null,
};

export const defaultStateDocumentMediums = {
	documentMediums: [],
	documentMediumsError: null,
	documentMediumsLoading: false,
	documentMediumsLoaded: false,
};

export const defaultStateDocumentRecipients = {
	documentRecipients: [],
	documentRecipientsError: null,
	documentRecipientsLoading: false,
	documentRecipientsLoaded: false,
};

const defaultLogReleaseDocumentState = {
	loggingReleaseDocument: false,
	loggedReleaseDocument: null,
	loggingReleaseDocumentError: null,
};

const defaultAuditTrailState = {
	auditTrail: [],
	auditTrailError: null,
	auditTrailLoading: false,
	auditTrailLoaded: false,
};

const defaultReleaseDocumentState = {
	releasingDocument: false,
	releasedDocument: null,
	releasedDocumentError: null,
};

export const defaultState = {
	data: [],
	error: null,
	loading: false,
	loaded: false,
	creatingDocument: false,
	createdDocument: null,
	creatingDocumentError: null,
	...defaultStateDocumentTypesForTemplate,
	...defaultStateDocumentTypesForPatient,
	...defaultStateDocumentTypesForWorkflow,
	...defaultDocumentDetail,
	...archiveDocumentState,
	...defaultFinalizeDocumentState,
	...updateDocumentTypeState,
	...defaultshareDocumentState,
	...defaultStateDocumentMediums,
	...defaultStateDocumentRecipients,
	...defaultLogReleaseDocumentState,
	...defaultAuditTrailState,
	...defaultReleaseDocumentState,
};

export default (state = defaultState, action = {}) => {
	switch (action.type) {
		case actionTypes.LOAD_PATIENT_DOCUMENTS_START:
			return {
				...state,
				loading: action.payload,
				loaded: false,
				error: null,
			};

		case actionTypes.LOAD_PATIENT_DOCUMENTS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: null,
				data: action.payload,
			};

		case actionTypes.LOAD_PATIENT_DOCUMENTS_FAILURE:
			return {
				...state,
				loading: false,
				loaded: true,
				error: action.payload,
			};

		case actionTypes.UNLOAD_PATIENT_DOCUMENTS:
			return {
				...state,
				data: [],
				error: null,
				loading: false,
				loaded: false,
			};

		case actionTypes.V2_CREATING_DOCUMENTS:
			return {
				...state,
				creatingDocument: true,
				createdDocument: null,
				creatingDocumentError: null,
			};

		case actionTypes.V2_CREATED_DOCUMENTS:
			return {
				...state,
				creatingDocument: false,
				createdDocument: action.payload,
				creatingDocumentError: null,
			};

		case actionTypes.V2_CREATING_DOCUMENTS_FAILURE:
			return {
				...state,
				creatingDocument: false,
				createdDocument: null,
				creatingDocumentError: action.payload,
			};

		case actionTypes.LOAD_DOCUMENT_TYPES_TEMPLATE_START:
			return {
				...state,
				documentTypesForTemplate: [],
				documentTypesForTemplateError: null,
				documentTypesForTemplateLoading: true,
				documentTypesForTemplateLoaded: false,
			};

		case actionTypes.LOAD_DOCUMENT_TYPES_TEMPLATE_SUCCESS:
			return {
				...state,
				documentTypesForTemplate: action.payload,
				documentTypesForTemplateError: null,
				documentTypesForTemplateLoading: false,
				documentTypesForTemplateLoaded: true,
			};

		case actionTypes.LOAD_DOCUMENT_TYPES_TEMPLATE_FAILURE:
			return {
				...state,
				documentTypesForTemplateLoading: false,
				documentTypesForTemplateLoaded: true,
				documentTypesForTemplateError: action.payload,
				documentTypesForTemplate: [],
			};

		case actionTypes.LOAD_DOCUMENT_TYPES_TEMPLATE_CLEAR:
			return {
				...state,
				defaultStateDocumentTypesForTemplate,
			};

		case actionTypes.LOAD_DOCUMENT_TYPES_PATIENT_START:
			return {
				...state,
				documentTypesForPatient: [],
				documentTypesForPatientError: null,
				documentTypesForPatientLoading: true,
				documentTypesForPatientLoaded: false,
			};

		case actionTypes.LOAD_DOCUMENT_TYPES_PATIENT_SUCCESS:
			return {
				...state,
				documentTypesForPatient: action.payload,
				documentTypesForPatientError: null,
				documentTypesForPatientLoading: false,
				documentTypesForPatientLoaded: true,
			};

		case actionTypes.LOAD_DOCUMENT_TYPES_PATIENT_FAILURE:
			return {
				...state,
				documentTypesForPatientLoading: false,
				documentTypesForPatientLoaded: true,
				documentTypesForPatientError: action.payload,
				documentTypesForPatient: [],
			};

		case actionTypes.LOAD_DOCUMENT_TYPES_PATIENT_CLEAR:
			return {
				...state,
				defaultStateDocumentTypesForPatient,
			};

		case actionTypes.LOAD_DOCUMENT_TYPES_WORKFLOW_START:
			return {
				...state,
				documentTypesForWorkflow: [],
				documentTypesForWorkflowError: null,
				documentTypesForWorkflowLoading: true,
				documentTypesForWorkflowLoaded: false,
			};

		case actionTypes.LOAD_DOCUMENT_TYPES_WORKFLOW_SUCCESS:
			return {
				...state,
				documentTypesForWorkflow: action.payload,
				documentTypesForWorkflowError: null,
				documentTypesForWorkflowLoading: false,
				documentTypesForWorkflowLoaded: true,
			};

		case actionTypes.LOAD_DOCUMENT_TYPES_WORKFLOW_FAILURE:
			return {
				...state,
				documentTypesForWorkflowLoading: false,
				documentTypesForWorkflowLoaded: true,
				documentTypesForWorkflowError: action.payload,
				documentTypesForWorkflow: [],
			};

		case actionTypes.LOAD_DOCUMENT_TYPES_WORKFLOW_CLEAR:
			return {
				...state,
				defaultStateDocumentTypesForWorkflow,
			};

		case actionTypes.LOAD_DETAIL_DOCUMENT_START:
			return {
				...state,
				docDetail: {},
				docDetailLoading: true,
				docDetailLoaded: false,
				docDetailError: null,
			};

		case actionTypes.LOAD_DETAIL_DOCUMENT_SUCCESS:
			return {
				...state,
				docDetail: action.payload,
				docDetailLoading: false,
				docDetailLoaded: true,
				docDetailError: null,
			};

		case actionTypes.UNLOAD_DETAIL_DOCUMENT:
			return {
				...state,
				...defaultDocumentDetail,
			};

		case actionTypes.LOAD_DETAIL_DOCUMENT_FAILURE:
			return {
				...state,
				docDetail: {},
				docDetailLoading: false,
				docDetailLoaded: true,
				docDetailError: action.payload,
			};

		case actionTypes.INLINE_DOCUMENT_DETAIL_START:
			return {
				...state,
				inlineDocDetail: null,
				inlineDocDetailLoading: true,
				inlineDocDetailLoaded: false,
				inlineDocDetailError: null,
			};

		case actionTypes.INLINE_DOCUMENT_DETAIL_SUCCESS:
			return {
				...state,
				inlineDocDetail: action.payload,
				inlineDocDetailLoading: false,
				inlineDocDetailLoaded: true,
				inlineDocDetailError: null,
			};

		case actionTypes.INLINE_DOCUMENT_DETAIL_FAILURE:
			return {
				...state,
				inlineDocDetail: null,
				inlineDocDetailLoading: false,
				inlineDocDetailLoaded: true,
				inlineDocDetailError: action.payload,
			};

		case actionTypes.DOCUMENT_ACTION_START:
			return {
				...state,
				documentAction: action.payload,
				documentActionLoading: true,
				documentActionLoaded: false,
				documentActionError: null,
			};

		case actionTypes.DOCUMENT_ACTION_SUCCESS:
			return {
				...state,
				documentActionLoading: false,
				documentActionLoaded: true,
				documentActionError: null,
			};

		case actionTypes.DOCUMENT_ACTION_FAILURE:
			return {
				...state,
				documentActionLoading: false,
				documentActionLoaded: true,
				documentActionError: action.payload,
			};

		case actionTypes.V2_LOAD_TEMPLATES_START:
			return {
				...state,
				templatesLoading: true,
				templatesLoaded: false,
				templatesError: null,
			};

		case actionTypes.V2_LOAD_TEMPLATES_SUCCESS: {
			const {
				data,
				end,
				has_next,
				has_prev,
				limit,
				offset,
				start,
				total,
			} = action.payload;
			return {
				...state,
				templatesLoading: false,
				templatesLoaded: true,
				templatesError: null,
				templates: data,
				templatesPagination: {
					end,
					has_next,
					has_prev,
					limit,
					offset,
					start,
					total,
				},
			};
		}

		case actionTypes.V2_LOAD_TEMPLATES_FAILURE:
			return {
				...state,
				templatesLoading: false,
				templatesLoaded: true,
				templatesError: action.payload,
			};

		case actionTypes.CREATING_TEMPLATE:
			return {
				...state,
				creatingTemplate: true,
				createdTemplate: null,
				createdTemplateError: null,
			};

		case actionTypes.CREATED_TEMPLATE:
			return {
				...state,
				creatingTemplate: false,
				createdTemplate: action.payload,
				createdTemplateError: null,
			};

		case actionTypes.CREATED_TEMPLATE_ERROR:
			return {
				...state,
				creatingTemplate: false,
				createdTemplate: null,
				createdTemplateError: action.payload,
			};

		case actionTypes.EDITING_TEMPLATE:
			return {
				...state,
				editingTemplate: true,
				editedTemplate: null,
				editedTemplateError: null,
			};

		case actionTypes.EDITED_TEMPLATE:
			return {
				...state,
				editingTemplate: false,
				editedTemplate: action.payload,
				editedTemplateError: null,
			};

		case actionTypes.EDITED_TEMPLATE_ERROR:
			return {
				...state,
				editingTemplate: false,
				editedTemplate: null,
				editedTemplateError: action.payload,
			};

		case actionTypes.V2_LOAD_WORKFLOW_DOCS_START:
			return {
				...state,
				affiliationWorkflowDocsLoading: true,
				affiliationWorkflowDocsLoaded: false,
				affiliationWorkflowDocsError: null,
			};

		case actionTypes.V2_LOAD_WORKFLOW_DOCS_SUCCESS:
			return {
				...state,
				affiliationWorkflowDocs: action.payload,
				affiliationWorkflowDocsLoading: false,
				affiliationWorkflowDocsLoaded: true,
				affiliationWorkflowDocsError: null,
			};

		case actionTypes.V2_LOAD_WORKFLOW_DOCS_FAILURE:
			return {
				...state,
				affiliationWorkflowDocsLoading: false,
				affiliationWorkflowDocsLoaded: true,
				affiliationWorkflowDocsError: action.payload,
			};

		case actionTypes.V2_UPLOAD_FILE_START:
			return {
				...state,
				fileUploading: true,
				fileUploaded: false,
				fileUploadError: null,
			};

		case actionTypes.V2_UPLOAD_FILE_SUCCESS:
			return {
				...state,
				fileUploading: false,
				fileUploaded: true,
				fileUpload: action.payload,
				fileUploadError: null,
			};

		case actionTypes.V2_UPLOAD_FILE_FAILURE:
			return {
				...state,
				fileUploading: false,
				fileUploaded: true,
				fileUploadError: action.payload,
			};

		case actionTypes.V2_ARCHIVE_DOCUMENT_START:
			return {
				...state,
				archivingDocument: true,
				archivedDocument: null,
				archivingDocumentError: null,
			};

		case actionTypes.V2_ARCHIVE_DOCUMENT_SUCCESS:
			return {
				...state,
				archivingDocument: false,
				archivedDocument: action.payload,
				archivingDocumentError: null,
			};

		case actionTypes.V2_ARCHIVE_DOCUMENT_FAILURE:
			return {
				...state,
				archivingDocument: false,
				archivedDocument: null,
				archivingDocumentError: action.payload,
			};

		case actionTypes.ARCHIVING_TEMPLATE:
			return {
				...state,
				archivingTemplate: true,
				archivedTemplate: null,
				archivedTemplateError: null,
			};

		case actionTypes.ARCHIVED_TEMPLATE:
			return {
				...state,
				archivingTemplate: false,
				archivedTemplate: action.payload,
				archivedTemplateError: null,
			};

		case actionTypes.ARCHIVED_TEMPLATE_ERROR:
			return {
				...state,
				archivingTemplate: false,
				archivedTemplate: null,
				archivedTemplateError: action.payload,
			};

		case actionTypes.BATCH_UPDATING_TEMPLATE_PARTNERS:
			return {
				...state,
				batchUpdatingTemplatePartners: true,
				batchUpdatedTemplatePartners: null,
				batchUpdatedTemplatePartnersError: null,
			};

		case actionTypes.BATCH_UPDATED_TEMPLATE_PARTNERS:
			return {
				...state,
				batchUpdatingTemplatePartners: false,
				batchUpdatedTemplatePartners: action.payload,
				batchUpdatedTemplatePartnersError: null,
			};

		case actionTypes.BATCH_UPDATED_TEMPLATE_PARTNERS_ERROR:
			return {
				...state,
				batchUpdatingTemplatePartners: false,
				batchUpdatedTemplatePartners: null,
				batchUpdatedTemplatePartnersError: action.payload,
			};

		case actionTypes.BATCH_UPDATING_TEMPLATE_TYPE:
			return {
				...state,
				batchUpdatingTemplateTypes: true,
				batchUpdatedTemplateType: null,
				batchUpdatedTemplateTypeError: null,
			};

		case actionTypes.BATCH_UPDATED_TEMPLATE_TYPE:
			return {
				...state,
				batchUpdatingTemplateTypes: false,
				batchUpdatedTemplateType: action.payload,
				batchUpdatedTemplateTypeError: null,
			};

		case actionTypes.BATCH_UPDATED_TEMPLATE_TYPE_ERROR:
			return {
				...state,
				batchUpdatingTemplateTypes: false,
				batchUpdatedTemplateType: null,
				batchUpdatedTemplateTypeError: action.payload,
			};

		case actionTypes.V2_FINALIZING_DOCUMENT_START:
			return {
				...state,
				finalizingDocument: true,
				finalizedDocument: null,
				finalizingDocumentError: null,
			};

		case actionTypes.V2_FINALIZED_DOCUMENT_SUCCESS:
			return {
				...state,
				finalizingDocument: false,
				finalizedDocument: action.payload,
				finalizingDocumentError: null,
			};

		case actionTypes.V2_FINALIZING_DOCUMENT_FAILURE:
			return {
				...state,
				finalizingDocument: false,
				finalizedDocument: null,
				finalizingDocumentError: action.payload,
			};

		case actionTypes.UPDATE_DOCUMENT_TYPE_START:
			return {
				...state,
				updatingDocumentType: true,
				updatedDocumentType: null,
				updatingDocumentTypeError: null,
			};

		case actionTypes.UPDATE_DOCUMENT_TYPE_SUCCESS:
			return {
				...state,
				updatingDocumentType: false,
				updatedDocumentType: action.payload,
				updatingDocumentTypeError: null,
			};

		case actionTypes.UPDATE_DOCUMENT_TYPE_FAILURE:
			return {
				...state,
				updatingDocumentType: false,
				updatedDocumentType: null,
				updatingDocumentTypeError: action.payload,
			};

		case actionTypes.SHARE_DOCUMENT_START:
			return {
				...state,
				sharingDocument: true,
				sharedDocument: null,
				sharingDocumentError: null,
			};

		case actionTypes.SHARE_DOCUMENT_SUCCESS:
			return {
				...state,
				sharingDocument: false,
				sharedDocument: action.payload,
				sharingDocumentError: null,
			};

		case actionTypes.SHARE_DOCUMENT_FAILURE:
			return {
				...state,
				sharingDocument: false,
				sharedDocument: null,
				sharingDocumentError: action.payload,
			};

		case actionTypes.UPDATING_DOCUMENT_RELATED_ENCOUNTER:
			return {
				...state,
				updatingDocumentRelatedEncounter: true,
				updatedDocumentRelatedEncounter: null,
				updatedDocumentRelatedEncounterError: null,
			};

		case actionTypes.UPDATE_DOCUMENT_ENCOUNTER_SUCCESS:
			return {
				...state,
				updatingDocumentRelatedEncounter: false,
				updatedDocumentRelatedEncounter: action.payload,
				updatedDocumentRelatedEncounterError: null,
			};

		case actionTypes.UPDATE_DOCUMENT_ENCOUNTER_FAILURE:
			return {
				...state,
				updatingDocumentRelatedEncounter: false,
				updatedDocumentRelatedEncounter: null,
				updatedDocumentRelatedEncounterError: action.payload,
			};

		case actionTypes.LOAD_DOCUMENT_MEDIUMS_START:
			return {
				...state,
				documentMediums: [],
				documentMediumsError: null,
				documentMediumsLoading: true,
				documentMediumsLoaded: false,
			};

		case actionTypes.LOAD_DOCUMENT_MEDIUMS_SUCCESS:
			return {
				...state,
				documentMediums: action.payload,
				documentMediumsError: null,
				documentMediumsLoading: false,
				documentMediumsLoaded: true,
			};

		case actionTypes.LOAD_DOCUMENT_MEDIUMS_FAILURE:
			return {
				...state,
				documentMediumsLoading: false,
				documentMediumsLoaded: true,
				documentMediumsError: action.payload,
				documentMediums: [],
			};

		case actionTypes.LOAD_DOCUMENT_RECIPIENTS_START:
			return {
				...state,
				documentRecipients: [],
				documentRecipientsError: null,
				documentRecipientsLoading: true,
				documentRecipientsLoaded: false,
			};

		case actionTypes.LOAD_DOCUMENT_RECIPIENTS_SUCCESS:
			return {
				...state,
				documentRecipients: action.payload,
				documentRecipientsError: null,
				documentRecipientsLoading: false,
				documentRecipientsLoaded: true,
			};

		case actionTypes.LOAD_DOCUMENT_RECIPIENTS_FAILURE:
			return {
				...state,
				documentRecipientsLoading: false,
				documentRecipientsLoaded: true,
				documentRecipientsError: action.payload,
				documentRecipients: [],
			};

		case actionTypes.LOG_RELEASE_DOCUMENT_START:
			return {
				...state,
				loggingReleaseDocument: true,
				loggedReleaseDocument: null,
				loggingReleaseDocumentError: null,
			};

		case actionTypes.LOG_RELEASE_DOCUMENT_SUCCESS:
			return {
				...state,
				loggingReleaseDocument: false,
				loggedReleaseDocument: action.payload,
				loggingReleaseDocumentError: null,
			};

		case actionTypes.LOG_RELEASE_DOCUMENT_FAILURE:
			return {
				...state,
				loggingReleaseDocument: false,
				loggedReleaseDocument: null,
				loggingReleaseDocumentError: action.payload,
			};

		case actionTypes.GET_DOCUMENT_AUDIT_TRAIL_START:
			return {
				...state,
				auditTrail: [],
				auditTrailError: null,
				auditTrailLoading: true,
				auditTrailLoaded: false,
			};

		case actionTypes.GET_DOCUMENT_AUDIT_TRAIL_SUCCESS:
			return {
				...state,
				auditTrail: action.payload,
				auditTrailError: null,
				auditTrailLoading: false,
				auditTrailLoaded: true,
			};

		case actionTypes.GET_DOCUMENT_AUDIT_TRAIL_FAILURE:
			return {
				...state,
				auditTrail: [],
				auditTrailError: action.payload,
				auditTrailLoading: false,
				auditTrailLoaded: true,
			};

		case actionTypes.RELEASE_DOCUMENT_START:
			return {
				...state,
				releasingDocument: true,
				releasedDocument: null,
				releasedDocumentError: null,
			};

		case actionTypes.RELEASE_DOCUMENT_SUCCESS:
			return {
				...state,
				releasingDocument: false,
				releasedDocument: action.payload,
				releasedDocumentError: null,
			};

		case actionTypes.RELEASE_DOCUMENT_FAILURE:
			return {
				...state,
				releasingDocument: false,
				releasedDocument: null,
				releasedDocumentError: action.payload,
			};

		case actionTypes.COMBINE_DOCUMENT_START:
			return {
				...state,
				combiningDocument: true,
				combinedDocument: null,
				combineDocumentError: null,
			};

		case actionTypes.COMBINE_DOCUMENT_SUCCESS:
			return {
				...state,
				combiningDocument: false,
				combinedDocument: action.payload,
				combineDocumentError: null,

			};

		case actionTypes.COMBINE_DOCUMENT_FAILURE:
			return {
				...state,
				combiningDocument: false,
				combinedDocument: null,
				combineDocumentError: action.payload,
			};

		default:
			return state;
	}
};
