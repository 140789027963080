import _ from 'lodash';
import { errorshardcoded } from './errorconstant';

export const ErrorTransformers = (error) => {
	const errorMessages = {};
	const fields = _.get(error, ['response', 'data', 'data', 'fields'], []);
	if (fields) {
		for (const key in fields) {
			try {
				// replacing get because it doesn't work well with error objects
				const newDescriptionArr = Object.values(fields[key]);
				const newDescription = _.get(newDescriptionArr, '[0]', ''); //rendering one error message for now
				if (newDescription) errorMessages[key] = newDescription;
			} catch (e) {
				continue;
			}
		}
	}
	return {
		error: { ...error },
		errorMessages,
	};
};

export const InsuranceErrorTransformers = (error) => {
	const errorMessages = {
		primary: {},
		secondary: {},
	};
	const messages = [];
	const fields = _.get(error, ['response', 'data', 'reason', 'fields'], []);
	const primary = _.get(fields, 'primary', {});
	const secondary = _.get(fields, 'secondary', {});
	if (primary) {
		for (const key in primary) {
			try {
				// replacing get because it doesn't work well with error objects
				const newDescriptionArr = Object.values(primary[key]);
				const newDescription = _.get(newDescriptionArr, '[0]', ''); //rendering one error message for now
				if (newDescription)
					errorMessages['primary'][key] = newDescription;
			} catch (e) {
				continue;
			}
		}
	}
	if (secondary) {
		for (const key in secondary) {
			try {
				// replacing get because it doesn't work well with error objects
				const newDescriptionArr = Object.values(secondary[key]);
				const newDescription = _.get(newDescriptionArr, '[0]', ''); //rendering one error message for now
				if (newDescription)
					errorMessages['secondary'][key] = newDescription;
			} catch (e) {
				continue;
			}
		}
	}
	return {
		error: { ...error },
		errorMessages,
		messages,
	};
};

export const PaymentErrorTransformers = (error) => {
	const errorMessages = {};
	const messages = [];
	const fields = _.get(error, ['response', 'data', 'fields'], []);
	const parameter = _.get(
		error,
		['response', 'data', 'data', 'parameter'],
		null
	);
	const message = _.get(error, ['response', 'data', 'message'], null);
	if (fields) {
		for (let key in fields) {
			try {
				// replacing get because it doesn't work well with error objects
				const newDescriptionArr = Object.values(fields[key]);
				if (key == 'exp_month' || key == 'exp_year') key = 'expirydate';
				const newDescription = _.get(newDescriptionArr, '[0]', ''); //rendering one error message for now
				if (newDescription) errorMessages[key] = newDescription;
			} catch (e) {
				continue;
			}
		}
	}
	if (parameter === 'number') {
		errorMessages['number'] = message || 'Your card number is incorrect.';
	}
	if (message == "Your card's expiration month is invalid.") {
		errorMessages['expirydate'] =
			"Your card's expiration month is invalid.";
	}
	if (parameter === 'cvc') {
		errorMessages['cvc'] =
			message || "Your card's security code is invalid.";
	}
	if (
		errorshardcoded.includes(
			_.get(error, ['response', 'data', 'message', 'message'], '')
		)
	) {
		messages.push(
			_.get(error, ['response', 'data', 'message', 'message'], '')
		);
	} else if (
		_.get(error, ['response', 'data', 'message', 'message'], '') != ''
	) {
		messages.push(
			_.get(error, ['response', 'data', 'message', 'message'], '')
		);
	} else if (message) {
		messages.push(message);
	}
	return {
		error: { ...error },
		errorMessages,
		messages,
	};
};
