import React, { Fragment } from 'react';

export default function (props) {
	return (
		<Fragment>
			<thead>
				{React.cloneElement(props.children, {
					parent: 'thead',
					hasData: props.hasData,
					handleSort: props.handleSort,
					sortBy: props.sortBy,
					isExpandable: props.isExpandable,
					trs: props.trs,
					isAllExpanded: props.isAllExpanded,
					registerTr: props.registerTr,
					handleToggle: props.handleToggle,
				})}
			</thead>
		</Fragment>
	);
}
