import * as actionTypes from '../actions/actionTypes';

const defaultReferenceAssembliesEnum = {
	referenceAssembliesEnumLoading: false,
	referenceAssembliesEnum: [],
	referenceAssembliesEnumError: null,
};

const defaultGeneticResultGenesSymbolEnum = {
	geneticResultGenesSymbolEnumLoading: false,
	geneticResultGenesSymbolEnum: [],
	geneticResultGenesSymbolEnumError: null,
};

const defaultAddDiscreteVariantFinding = {
	addDiscreteVariantFinding: {},
	addDiscreteVariantFindingLoading: false,
	addDiscreteVariantFindingError: null,
};

const defaultDiagnosticFindingValidation = {
	diagnosticFindingValidationText: {},
	diagnosticFindingValidationLoading: false,
	diagnosticFindingValidationError: null,
};

export const referenceAssembliesEnum = (
	state = defaultReferenceAssembliesEnum,
	action = {}
) => {
	switch (action.type) {
		case actionTypes.GET_REFERENCE_ASSEMBLIES_ENUM_LOADING:
			return {
				...state,
				referenceAssembliesEnumLoading: true,
				referenceAssembliesEnumError: null,
			};

		case actionTypes.GET_REFERENCE_ASSEMBLIES_ENUM_SUCCESS:
			return {
				...state,
				referenceAssembliesEnum: action.payload,
				referenceAssembliesEnumLoading: false,
				referenceAssembliesEnumError: null,
			};

		case actionTypes.GET_REFERENCE_ASSEMBLIES_ENUM_ERROR:
			return {
				...state,
				referenceAssembliesEnumLoading: false,
				referenceAssembliesEnumError: action.payload,
			};

		default:
			return state;
	}
};

export const geneticResultGenesSymbolEnum = (
	state = defaultGeneticResultGenesSymbolEnum,
	action = {}
) => {
	switch (action.type) {
		case actionTypes.GET_GENETIC_RESULT_GENES_SYMBOL_ENUM_LOADING:
			return {
				...state,
				geneticResultGenesSymbolEnumLoading: true,
				geneticResultGenesSymbolEnumError: null,
			};

		case actionTypes.GET_GENETIC_RESULT_GENES_SYMBOL_ENUM_SUCCESS:
			return {
				...state,
				geneticResultGenesSymbolEnum: action.payload,
				geneticResultGenesSymbolEnumLoading: false,
				geneticResultGenesSymbolEnumError: null,
			};

		case actionTypes.GET_GENETIC_RESULT_GENES_SYMBOL_ENUM_ERROR:
			return {
				...state,
				geneticResultGenesSymbolEnumLoading: false,
				geneticResultGenesSymbolEnumError: action.payload,
			};

		case actionTypes.RESET_GET_GENETIC_RESULT_GENES_SYMBOL_ENUM:
			return {
				...state,
				geneticResultGenesSymbolEnumLoading: false,
				geneticResultGenesSymbolEnum: [],
			};

		default:
			return state;
	}
};

export const addDiscreteVariantFinding = (
	state = defaultAddDiscreteVariantFinding,
	action = {}
) => {
	switch (action.type) {
		case actionTypes.ADD_DISCRETEVARIANT_FINDING_LOADING:
			return {
				...state,
				addDiscreteVariantFinding: {},
				addDiscreteVariantFindingLoading: true,
				addDiscreteVariantFindingError: null,
			};

		case actionTypes.ADD_DISCRETEVARIANT_FINDING_SUCCESS:
			return {
				...state,
				addDiscreteVariantFinding: action.payload,
				addDiscreteVariantFindingLoading: false,
				addDiscreteVariantFindingError: null,
			};

		case actionTypes.ADD_DISCRETEVARIANT_FINDING_ERROR:
			return {
				...state,
				addDiscreteVariantFinding: {},
				addDiscreteVariantFindingLoading: false,
				addDiscreteVariantFindingError: action.payload,
			};

		default:
			return state;
	}
};

export const getDiagnosticFindingValidation = (
	state = defaultDiagnosticFindingValidation,
	action = {}
) => {
	switch (action.type) {
		case actionTypes.GET_DIAGNOSTIC_FINDING_VALIDATION_LOADING:
			return {
				...state,
				diagnosticFindingValidationLoading: true,
				diagnosticFindingValidationError: null,
			};

		case actionTypes.GET_DIAGNOSTIC_FINDING_VALIDATION_SUCCESS:
			return {
				...state,
				diagnosticFindingValidationText: action.payload,
				diagnosticFindingValidationLoading: false,
				diagnosticFindingValidationError: null,
			};

		case actionTypes.GET_DIAGNOSTIC_FINDING_VALIDATION_ERROR:
			return {
				...state,
				diagnosticFindingValidationLoading: false,
				diagnosticFindingValidationError: action.payload,
			};

		case actionTypes.RESET_GET_DIAGNOSTIC_FINDING_VALIDATION:
			return {
				...state,
				diagnosticFindingValidationLoading: false,
				diagnosticFindingValidationText: {},
				diagnosticFindingValidationError: null,
			};

		default:
			return state;
	}
};
