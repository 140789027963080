import * as actionTypes from '../actions/actionTypes';

export const defaultState = {
	showRefreshModal: false,
	showSwitchModal: false,
};

export default (state = defaultState, action = {}) => {
	switch (action.type) {
		case actionTypes.REFRESH_MODAL:
			return state.showRefreshModal
				? { ...state }
				: {
						...state,
						showRefreshModal: true,
				  };
		case actionTypes.RETRY_EXCEEDED:
			return {
				...state,
				retryExceeded: action.payload,
			};
		case actionTypes.SWITCH_ROLE_MODAL:
			return state.showSwitchModal
				? { ...state }
				: {
						...state,
						showSwitchModal: action.payload,
				  };
		case actionTypes.REQUEST_AUTHORIZATION_STATUS:
			return { ...state, requestAuthorizationStatus: action.payload };
		default:
			return state;
	}
};
