//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Cascader } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';

export const EncounterCascaderField = ({
	name,
	value,
	onChange,
	placeholder,
	trim,
	extraclassName,
	disabled,
	error,
	options,
	istouched = true,
}) => {
	const iserror = istouched && error && !_.isNil(error) && !_.isEmpty(error);
	const currentClassName = classnames(
		iserror ? 'encounter-input-error-field' : 'encounter-input-field',
		extraclassName
	);
	return (
		<Fragment>
			<div className={currentClassName}>
				<Cascader
					value={value}
					showAllLevels={false}
					disabled={disabled}
					onChange={onChange}
					options={options}
					expandTrigger='hover'
				/>
			</div>
			<div className='encounter-input-error'>{iserror ? error : ''}</div>
		</Fragment>
	);
};
