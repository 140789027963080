//React & Redux
import React from 'react';

//Lodash
import moment from 'moment';
import _ from 'lodash';

//Components
import SummaryPatientInfo from './SummaryPatientInfo.js';
import SummaryAppointmentPreview from './SummaryAppointmentPreview.js';
import SummaryPayment from './SummaryPayment.js';
import SummaryHistory from './SummaryHistory.js';
import SummaryCouponCode from './SummaryCouponCode';
import SummaryWarning from './SummaryWarning';

//Styles
import './css/patient-summary.css';

class ScheduleAppointmentSidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.isCouponCodeShow = this.isCouponCodeShow.bind(this);
	}

	isCouponCodeShow() {
		const activeItem = _.get(this, ['props', 'activeItem'], -1);
		const { useInsurance } = _.get(this, ['props', 'newAppointment'], {});
		return (
			(activeItem == 2 && useInsurance == false) ||
			(activeItem == 1 && !_.isNil(this.props.couponCodeSuccess))
		);
	}

	isWarning() {
		if (
			!_.isNil(
				_.get(this, ['props', 'newAppointment', 'timeslot'], null)
			) &&
			_.get(this, ['props', 'newAppointment', 'timeslot', 'start']) &&
			_.get(this, ['props', 'newAppointment', 'timeslot', 'end'])
		) {
			const timezone = _.get(this, [
				'props',
				'newAppointment',
				'timezone',
			]);
			const start = moment(this.props.newAppointment.timeslot.start);
			const currenttimeOfTimezone = moment(new Date()).tz(timezone);
			const starttimeOfTimezone = moment.tz(
				moment(start).format('YYYY-MM-DDTHH:mm:ss'),
				timezone
			);
			const duration = moment.duration(
				starttimeOfTimezone.diff(currenttimeOfTimezone)
			);
			const hours = duration.asHours();
			return hours <= 8;
		}
		return false;
	}
	//functions

	render() {
		const newAppointment = _.get(this, ['props', 'newAppointment'], {});
		const patient = _.get(newAppointment, 'patientInfo');

		return (
			<div className='scheduleAppointmentSideContainer'>
				<div className='scheduleAppointmentSideContainerTitle'>
					Summary
				</div>
				{newAppointment &&
				!_.isNil(_.get(newAppointment, 'uuid')) &&
				!_.isEmpty(patient) ? (
					<SummaryPatientInfo
						patient={patient}
						determineEmailStatus={this.props.determineEmailStatus}
						referralPrograms={this.props.referralPrograms}
					/>
				) : (
					<div className='scheduleAppointmentSideContainerEmpty'>
						Please select a patient
					</div>
				)}
				{!_.isNil(
					_.get(
						this,
						['props', 'newAppointment', 'timeslot', 'start'],
						null
					)
				) && (
					<SummaryAppointmentPreview
						newAppointment={this.props.newAppointment}
						selectedconsultation={this.props.selectedconsultation}
						selectedProvider={this.props.selectedProvider}
						Scheduling={this.props.Scheduling}
						specialties={this.props.specialties}
					/>
				)}
				{this.isCouponCodeShow() == true &&
					this.props.isZeroPriceConsultation() === false && (
						<SummaryCouponCode
							newAppointment={this.props.newAppointment}
							couponCodeSuccess={this.props.couponCodeSuccess}
							loadingCouponCodeDetail={
								this.props.loadingCouponCodeDetail
							}
							resetCouponCodeDetails={
								this.props.resetCouponCodeDetails
							}
							getApplyCouponCodeDetails={
								this.props.getApplyCouponCodeDetails
							}
							couponCodeWarning={this.props.couponCodeWarning}
							couponCodeError={this.props.couponCodeError}
							activeItem={this.props.activeItem}
							consulationPrice={_.get(
								this.props.selectedconsultation(),
								'price'
							)}
							selectedconsultation={
								this.props.selectedconsultation
							}
						/>
					)}
				{!_.isNil(
					_.get(
						this,
						['props', 'newAppointment', 'timeslot', 'start'],
						null
					)
				) &&
					this.props.activeItem !== 1 && (
						<SummaryPayment
							payment={this.props.calculatePrice()}
							isZeroPriceConsultation={
								this.props.isZeroPriceConsultation
							}
							ZeroFeeBookAppointment={
								this.props.ZeroFeeBookAppointment
							}
							NonZeroFeeBookAppointment={
								this.props.NonZeroFeeBookAppointment
							}
							activeItem={this.props.activeItem}
							nonzerobookbtnenabled={
								this.props.nonzerobookbtnenabled
							}
							useInsurance={
								this.props.newAppointment.useInsurance
							}
						/>
					)}
				{!_.isNil(
					_.get(
						this,
						['props', 'newAppointment', 'timeslot', 'start'],
						null
					)
				) && <SummaryWarning iswarning={this.isWarning()} />}
				{_.get(this, 'props.encountersList.data', []).length > 0 && (
					<SummaryHistory
						encounters={_.get(
							this,
							'props.encountersList.data',
							[]
						)}
						visitProviders={_.get(
							this,
							['props', 'visitProviders'],
							[]
						)}
						getProviderImage={this.props.getProviderImage}
						getProviderName={this.props.getProviderName}
						getModalityIcon={this.props.getModalityIcon}
						getShortText={this.props.getShortText}
						ucFirstAllWords={this.props.ucFirstAllWords}
						getTimestamp={this.props.getTimestamp}
						specialties={this.props.specialties}
					/>
				)}
			</div>
		);
	}
}

export default ScheduleAppointmentSidebar;
