import axios from 'axios';
import get from 'lodash/get';
import _ from 'lodash';

class ProviderService {
	exportReport = (data) => {
		const url = '/api/report/export';
		let payload = {
			referral_programs: get(data, 'referral_programs'),
			referral_partners: get(data, 'referral_partners'),
			start: get(data, 'start_date', new Date()),
			end: get(data, 'end_date', new Date()),
			report_type: get(
				data,
				'report_type',
				'completed_encounters_report'
			),
			report_subtype: get(data, 'report_subtypes', []),
			notes: get(data, 'notes', 'false'),
			cs_version: get(data, 'cs_version', 'false'),
		};

		return axios.post(url, payload);
	};

	getAllReports = (data) => {
		const url =
			'/api/report/providers/logs?offset=' + get(data, 'offset', 0);
		return axios.get(url);
	};

	provideraffiliations = (data) => {
		let url = '/api/provdier-affiliations';
		if (data && data.length > 0) {
			url = url + '?partner_organization_id=' + data;
		}
		return axios.get(url);
	};
	
	partnerOrganizations = () => {
		const url = '/api/enums/partner-organizations';
		return axios.get(url);
	};

	listProviders = (params) => {
		return axios.get('/api/providers', {
			params: { ...params },
		});
	};

	searchProviders = (body) => {
		let url = '/api/providers/search';
		const omit = ['q'];

		if (!_.isEmpty(body.q)) url += `?q=${body.q}`;
		if (_.isEmpty(_.get(body, ['title'], null))) omit.push('title');
		if (_.isEmpty(_.get(body, ['specialties'], null)))
			omit.push('specialties');
		if (_.isEmpty(_.get(body, ['state'], null))) omit.push('state');

		return axios.post(url, _.omit(body, omit));
	};

	updateProvider = (providerUuid, data) => {
		const url = `/api/providers/${providerUuid}`;
		return axios.patch(url, data);
	};

	createProvider = (data) => {
		const url = `/api/providers`;
		return axios.post(url, data);
	};

	getProviderSpecialties = () => {
		const url = '/api/enums/provider-specialties';
		return axios.get(url);
	};

	getProviderCredentials = () => {
		const url = '/api/enums/provider-credentials';
		return axios.get(url);
	};
}

export default new ProviderService();
