//React & Redux
import React from 'react';
import { Table, Thead, Tbody, Th, Tr, Td } from '../../Common/Table';
import moment from 'moment';

const CriteriaNotMetTable = (props) => {
	const {
		riseAnalyticsData,
		dashboardCardList,
		selectedCard,
		handlePagination,
		handleResultsPerPageChange,
		selectPatient,
		handleSort,
		CardPageData,
	} = props;
	const total = _.get(riseAnalyticsData, ['totals', selectedCard], []);
	const limit = _.get(riseAnalyticsData, ['limit'], []);
	const offset = _.get(riseAnalyticsData, ['offset'], []);

	const pagination = {
		limit,
		offset,
		total,
	};
	const sortBy = {
		key: _.get(CardPageData, ['order_by'], ''),
		value: _.get(CardPageData, ['order_by_direction'], ''),
	};

	return (
		<div>
			<Table
				loading={false}
				backToTop
				pagination={pagination}
				handlePageChange={(evt, value) =>
					handlePagination(selectedCard, value)
				}
				hasData={!_.isEmpty(dashboardCardList)}
				handleSort={handleSort}
				sortBy={sortBy}
				resultsPerPage={[25, 50, 100]}
				handleResultsPerPageChange={(value) =>
					handleResultsPerPageChange(selectedCard, value)
				}
				noResultsFound={<div>No Results Found</div>}
				className='test-management-table'
			>
				<Thead>
					<Tr>
						<Th
							sortable={{
								patient_id: {
									display_name: 'Patient ID',
									sortBy: {
										asc: 'A to Z',
										desc: 'Z to A',
									},
								},
							}}
						>
							Patient ID
						</Th>
						<Th
							sortable={{
								first_name: {
									display_name: 'First Name',
									sortBy: {
										asc: 'A to Z',
										desc: 'Z to A',
									},
								},
							}}
						>
							First Name
						</Th>
						<Th
							sortable={{
								last_name: {
									display_name: 'Last Name',
									sortBy: {
										asc: 'A to Z',
										desc: 'Z to A',
									},
								},
							}}
						>
							Last Name
						</Th>
						<Th
							sortable={{
								dob: {
									display_name: 'Date of Birth',
									sortBy: {
										asc: 'Oldest First',
										desc: 'Newest First',
									},
								},
							}}
						>
							Date of Birth
						</Th>
						<Th>Phone Number</Th>
						<Th
							sortable={{
								hcra_completed_date: {
									display_name: 'Date HCRA Completed',
									sortBy: {
										asc: 'Oldest First',
										desc: 'Newest First',
									},
								},
							}}
						>
							Date HCRA Completed
						</Th>
						<Th
							sortable={{
								referral_program: {
									display_name: 'Referral Program',
									sortBy: {
										asc: 'A to Z',
										desc: 'Z to A',
									},
								},
							}}
						>
							Referral Program
						</Th>
					</Tr>
				</Thead>
				<Tbody>
					{(dashboardCardList || []).map((item, i) => {
						const {
							patient_id,
							first_name,
							last_name,
							dob,
							phone,
							referral_partner,
							referral_program,
							hcra_completed_date,
						} = item;
						return (
							<Tr>
								<Td
									className={`${
										!_.isNil(patient_id)
											? 'ext_patient_id'
											: ''
									}`}
									onClick={() =>
										!_.isNil(patient_id) &&
										selectPatient(item)
									}
								>
									{patient_id}
								</Td>
								<Td>{first_name}</Td>
								<Td>{last_name}</Td>
								<Td>
									{moment(dob).isValid()
										? moment(dob).format('MMM D, YYYY')
										: ''}
								</Td>
								<Td>{phone}</Td>
								<Td>
									{moment(hcra_completed_date).isValid()
										? moment(hcra_completed_date).format(
												'MMM D, YYYY'
										  )
										: ''}
								</Td>
								<Td>{referral_program}</Td>
							</Tr>
						);
					})}
				</Tbody>
			</Table>
		</div>
	);
};

export default CriteriaNotMetTable;
