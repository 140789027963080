//React & Reduxs
import React from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	encounterFreeTextFieldDisplay,
	GetSelectvalue,
} from '../Common/utilsfunctions';

const AdditionalSegment = (props) => {
	return (
		<div>
			<div className='segment-row segment-row-title'>
				<div className='segment-col'>CC NOTES</div>
			</div>
			<div className='segment-row'>
				<div
					className='segment-col encounter-field-billing-value'
					style={{ width: '100%', whiteSpace: 'pre' }}
				>
					{encounterFreeTextFieldDisplay(props, [
						'encounter',
						'notes',
					])}
				</div>
			</div>
		</div>
	);
};

export default AdditionalSegment;
