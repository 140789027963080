//React & Redux
import React, { useState, useEffect } from 'react';

//Lodash
import _ from 'lodash';

//Other Libraries
import moment from 'moment';

//UI Libraries
import { Button, Loading } from 'gm-element-react';

//Utils
import {
	encounterFieldDisplay,
	EncounterDateFieldsDisplay,
	createEnumsOptions,
	valueWithDollar,
	isValidDateStringOREmpty,
	getMomentDateOrStringNonStrick,
	handleOtherDropdownOption,
	payorSelectOption,
} from '../Common/utilsfunctions';

//Components
import { EncounterInputField } from '../Common/EncounterInputField';
import { EncounterNumberInputField } from '../Common/EncounterNumberInputField';
import { EncounterSelectField } from '../Common/EncounterSelectField';
import { EncounterDateField } from '../Common/EncounterDateField';
import { EncounterTextAreaField } from '../Common/EncounterTextAreaField';
import CommonCheckbox from './../../../../Common/Checkbox.js';

const datefields = {
	insurance_claim_date: 1,
	patient_billing_date: 1,
	patient_fee_refund_processed_date: 1,
};

const EditBillingSegment = (props) => {
	const {
		encounter,
		billingencounter,
		cancel,
		saveVisitEncounter,
		saveVisitBillingEncounter,
		isPatientFeeDisabled,
		visitsschema,
		patientdetail,
		referralPrograms = [],
	} = props;

	const tempFormData = {
		billing_status: _.get(billingencounter, ['billing_status'], ''),
		coupon_code: _.get(billingencounter, ['coupon_code'], ''),
		insurance_claim_status: _.get(
			billingencounter,
			['insurance_claim_status'],
			''
		),
		insurance_claim_date: _.get(
			billingencounter,
			['insurance_claim_date'],
			''
		),
		billing_notes: _.get(billingencounter, ['billing_notes'], ''),
		patient_pay: _.get(billingencounter, ['patient_pay'], null),
		units: _.get(billingencounter, ['units'], null),
		units_s0265_95: _.get(billingencounter, ['units_s0265_95'], null),
		patient_fee_refund: _.get(
			billingencounter,
			['patient_fee_refund'],
			null
		),
		patient_fee_refund_decision: _.get(
			billingencounter,
			['patient_fee_refund_decision'],
			null
		),
		patient_fee_refund_processed_date: _.get(
			billingencounter,
			['patient_fee_refund_processed_date'],
			''
		),
		post_negative_minutes: _.get(
			billingencounter,
			'post_negative_minutes',
			null
		),
		patient_fee_refund_reason: _.get(
			billingencounter,
			'patient_fee_refund_reason',
			null
		),
		patient_fee_refund_reason_other: _.get(
			billingencounter,
			'patient_fee_refund_reason_other',
			null
		),
		payment_method:
			_.get(billingencounter, 'payor') === 'self_pay'
				? 'self_pay'
				: _.get(billingencounter, 'payor') === 'undetermined'
				? ''
				: 'insurance',
	};
	const updatedformdata = _.mapValues(tempFormData, function (value, key) {
		if (key in datefields) {
			if (key == 'insurance_claim_date' && _.isNil(value)) {
				return '';
			}
			if (key == 'patient_fee_refund_processed_date' && _.isNil(value)) {
				return '';
			}
			return getMomentDateOrStringNonStrick(value);
		}
		return value;
	});

	const [formData, setFormData] = useState({});
	const [encounterFormData, setEncounterFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [touchedAll, setTouchedAll] = useState(false);

	useEffect(() => {
		setFormData(updatedformdata);
	}, []);

	const validateForm = (formdata) => {
		let errors = {};
		const datetimeerors = _.omitBy(
			_.mapValues(datefields, function (value, key) {
				if (!isValidDateStringOREmpty(_.get(formdata, key, ''))) {
					return 'Invalid date';
				}
				return undefined;
			}),
			_.isUndefined
		);

		errors = { ...datetimeerors };

		if (
			_.get(formdata, 'patient_fee_refund_reason', '') === 'other' &&
			_.isEmpty(_.get(formdata, 'patient_fee_refund_reason_other', ''))
		) {
			errors['patient_fee_refund_reason_other'] =
				'Other refund reason is required';
		}

		return errors;
	};

	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		let errors = {};

		stateData[key] = value;
		if (!(key in datefields)) {
			const _stateData = { ...encounterFormData, ...stateData };
			errors = validateForm(_stateData);
		}
		setErrors(errors);
		setFormData(stateData);
	};

	const handleDateFieldValidate = (key, value) => {
		const stateData = { ...formData };
		const stateEncounterData = { ...encounterFormData };
		stateData[key] = value;
		const _stateData = { ...stateEncounterData, ...stateData };
		const errors = validateForm(_stateData);
		setErrors(errors);
		setFormData(stateData);
	};
	const onNumberChange = (key, value) => {
		if (_.isNaN(_.toNumber(value))) {
			return;
		}
		onFormChange(key, value);
	};
	const saveForm = () => {
		const errors = validateForm(formData);

		if (_.isEmpty(errors)) {
			const updatedformdata = _.mapValues(
				formData,
				function (value, key) {
					if (
						key in datefields &&
						_.isNil(value) &&
						key != 'insurance_claim_date'
					)
						// back-end accept null for insurance_claim_date
						return '';
					else if (key == 'insurance_claim_date' && value == '')
						return null;
					else if (
						key == 'patient_fee_refund_processed_date' &&
						value === ''
					)
						return null;
					else if (key == 'patient_fee_refund' && value === undefined)
						return null;
					else if (key === 'patient_pay') return +value;
					else if (
						key === 'patient_fee_refund_reason_other' &&
						value === ''
					)
						return null;
					if (value == 'empty') return '';
					return value;
				}
			);

			const updated_encounter_data = encounterFormData;
			if (
				updatedformdata.patient_fee_refund_reason != 'other' &&
				updatedformdata.patient_fee_refund_reason_other
			) {
				updatedformdata.patient_fee_refund_reason_other = null;
			}
			//commented as we always wants to pass payment_method as a part of ticket https://genomemedical.atlassian.net/browse/EHR-12721
			// if (updatedformdata.payment_method === tempFormData.payment_method) delete updatedformdata.payment_method;
			if (
				updatedformdata.payment_method === '' ||
				updatedformdata.payment_method == 'undetermined'
			)
				delete updatedformdata.payment_method;

			setLoading(true);
			saveVisitBillingEncounter(
				encounter,
				updatedformdata,
				updated_encounter_data
			)
				.then((result) => {
					setTimeout(() => {
						saveVisitEncounter(
							encounter,
							updated_encounter_data
						).then((res) => {
							setTimeout(() => {
								setLoading(false);
								cancel();
							}, 1000);
						});
					}, 1000);
				})
				.catch((error) => {
					if (
						error.response?.data?.code === 422 &&
						error.response?.data?.data.message ===
							'Coupon code could not be found.'
					) {
						setErrors({
							...errors,
							coupon_code:
								'Coupon with specified code could not be found',
						});
					}
					setLoading(false);
				});
		} else {
			setTouchedAll(true);
			setErrors(errors);
		}
	};

	const patientFeeRefundReasonOther =
		_.get(formData, ['patient_fee_refund_reason'], null) === 'other';

	return (
		<Loading loading={loading}>
			<div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>Payor</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Coupon Code
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['payment_method'],
										''
									)}
									name='payment_method'
									options={payorSelectOption(
										patientdetail,
										true,
										referralPrograms
									)}
									placeholder='Select'
									onChange={(value) =>
										onFormChange('payment_method', value)
									}
									extraclassName={'select-payor'}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={_.get(formData, ['coupon_code'], '')}
									name='coupon_code'
									onChange={(value) =>
										onFormChange('coupon_code', value)
									}
									error={_.get(errors, ['coupon_code'], '')}
									disabled={!props.isBillingCoordinator}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Payment Status
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Fee
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Billing Status (Patient)
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Billing Date (Patient)
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Billing Amount (Patient)
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={encounterFieldDisplay(
										billingencounter,
										['patient_payment']
									)}
									name='patient_payment'
									disabled={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									placeholder='$12.34'
									trim={true}
									value={_.get(formData, ['patient_pay'], 0)}
									name='patient_pay'
									disabled={isPatientFeeDisabled}
									onChange={(value) =>
										onNumberChange('patient_pay', value)
									}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['billing_status'],
										''
									)}
									name='billing_status'
									options={createEnumsOptions(
										visitsschema,
										['billing_status'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('billing_status', value)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={EncounterDateFieldsDisplay(
										billingencounter,
										['patient_billing_date']
									)}
									name='patient_billing_date'
									disabled={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={valueWithDollar(billingencounter, [
										'patient_billing_amount',
									])}
									name='patient_billing_amount'
									disabled={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Fee Refund
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Fee Refund Decision
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Fee Refund Processed Date
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Fee Refund Reason
							</div>
						</div>
						{patientFeeRefundReasonOther && (
							<div className='segment-col'>
								<div className='encounter-field-header required'>
									Other Refund Reason
								</div>
							</div>
						)}
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								<EncounterNumberInputField
									name='patient_fee_refund'
									value={_.get(
										formData,
										['patient_fee_refund'],
										null
									)}
									onChange={(value) =>
										onFormChange(
											'patient_fee_refund',
											value
										)
									}
									min='1'
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								<EncounterSelectField
									value={_.get(formData, [
										'patient_fee_refund_decision',
									])}
									name='patient_fee_refund_decision'
									options={[
										{ key: null, value: null, label: '' },
										{
											key: 'refund',
											value: 'refund',
											label: 'Refund',
										},
										{
											key: 'not_refund',
											value: 'not_refund',
											label: 'Not Refund',
										},
									]}
									onChange={(value) =>
										onFormChange(
											'patient_fee_refund_decision',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									name='patient_fee_refund_processed_date'
									value={_.get(
										formData,
										['patient_fee_refund_processed_date'],
										''
									)}
									placeholder='Date'
									error={_.get(
										errors,
										['patient_fee_refund_processed_date'],
										''
									)}
									onChange={(value) =>
										onFormChange(
											'patient_fee_refund_processed_date',
											value
										)
									}
									onBlur={(value) =>
										handleDateFieldValidate(
											'patient_fee_refund_processed_date',
											value
										)
									}
									disabledDate={(current) => {
										const yesterday = _.get(
											encounter,
											['date_of_service'],
											''
										);
										const momentcurrent = moment(current);
										if (!_.isNil(yesterday))
											return momentcurrent.isAfter(
												moment(yesterday).clone()
											);
										else return true;
									}}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-value'>
								<EncounterSelectField
									value={_.get(formData, [
										'patient_fee_refund_reason',
									])}
									name='patient_fee_refund_reason'
									extraclassName='noTextBreak'
									options={createEnumsOptions(
										props,
										'patientFeeEnum',
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'patient_fee_refund_reason',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						{patientFeeRefundReasonOther && (
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterInputField
										placeholder='Specify other reason'
										trim={true}
										value={_.get(formData, [
											'patient_fee_refund_reason_other',
										])}
										error={_.get(
											errors,
											['patient_fee_refund_reason_other'],
											''
										)}
										name='patient_fee_refund_reason_other'
										disabled={false}
										onChange={(value) =>
											onFormChange(
												'patient_fee_refund_reason_other',
												value
											)
										}
										istouched={touchedAll}
										maxLength={50}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='segment-row segment-row-title'>
					<div className='segment-col'>INSURANCE CLAIM</div>
				</div>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Insurance Claim Status
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Athena Claim ID
							</div>
						</div>
						<div className='segment-3cols'>
							<div className='encounter-field-header'>
								Athena Claim Error Message(s)
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['insurance_claim_status'],
										''
									)}
									name='insurance_claim_status'
									options={createEnumsOptions(
										visitsschema,
										['insurance_claim_status'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'insurance_claim_status',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={encounterFieldDisplay(
										billingencounter,
										['athena_claim_id']
									)}
									name='patient_billing_date'
									disabled={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>--</div>
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Insurance Claim Date
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							CPT 96040,95 (Unit)
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							CPT S0265,95 (Unit)
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-input-section'>
							<EncounterDateField
								value={_.get(
									formData,
									['insurance_claim_date'],
									''
								)}
								name='insurance_claim_date'
								onChange={(value) =>
									onFormChange('insurance_claim_date', value)
								}
								error={_.get(
									errors,
									['insurance_claim_date'],
									''
								)}
								onBlur={(value) =>
									handleDateFieldValidate(
										'insurance_claim_date',
										value
									)
								}
							/>
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-multi-cols-section'>
							<div>
								<CommonCheckbox
									disabled={true}
									checked={_.get(
										billingencounter,
										['cpt_96040_95'],
										true
									)}
									marginLeftOverride={0}
									marginRightOverride={0}
								/>
							</div>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={
										_.get(formData, ['units'], '--') == null
											? '--'
											: _.get(formData, ['units'], '--')
									}
									name=''
									disabled={true}
								/>
							</div>
						</div>
					</div>

					<div className='segment-col'>
						<div className='encounter-multi-cols-section'>
							<div>
								<CommonCheckbox
									disabled={true}
									checked={_.get(
										billingencounter,
										['cpt_s0265_95'],
										false
									)}
									marginLeftOverride={0}
									marginRightOverride={0}
								/>
							</div>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={
										_.get(
											formData,
											['units_s0265_95'],
											'--'
										) == null
											? '--'
											: _.get(
													formData,
													['units_s0265_95'],
													'--'
											  )
									}
									name=''
									disabled={true}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Encounter UUID
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Insurance Expected Revenue
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Athena Insurance Package ID
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Athena Insurance Record ID
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Athena Authorization ID
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							<EncounterInputField
								value={encounterFieldDisplay(encounter, [
									'uuid',
								])}
								name=''
								disabled={true}
							/>
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							<EncounterInputField
								value={valueWithDollar(billingencounter, [
									'insurance_expected_revenue',
								])}
								name=''
								disabled={true}
							/>
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							<EncounterInputField
								value={encounterFieldDisplay(billingencounter, [
									'athena_insurance_package_id',
								])}
								name=''
								disabled={true}
							/>
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							<EncounterInputField
								value={encounterFieldDisplay(billingencounter, [
									'athena_insurance_id',
								])}
								name=''
								disabled={true}
							/>
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							<EncounterInputField
								value={encounterFieldDisplay(billingencounter, [
									'athena_authorization_id',
								])}
								name=''
								disabled={true}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className='segment-row'>
				<div className='segment-label-row'>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Athena Supervising Provider ID
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Athena Rendering Provider ID
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Athena Referring Provider ID
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-header'>
							Athena Payment Batch ID
						</div>
					</div>
				</div>
				<div className='segment-value-row'>
					<div className='segment-col'>
						<div className='encounter-input-section'>
							<EncounterInputField
								value={encounterFieldDisplay(billingencounter, [
									'athena_supervising_provider_id',
								])}
								name=''
								disabled={true}
							/>
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-input-section'>
							<EncounterInputField
								value={encounterFieldDisplay(billingencounter, [
									'athena_rendering_provider_id',
								])}
								name=''
								disabled={true}
							/>
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-field-value'>
							<EncounterInputField
								value={encounterFieldDisplay(billingencounter, [
									'athena_referring_provider_id',
								])}
								name=''
								disabled={true}
							/>
						</div>
					</div>
					<div className='segment-col'>
						<div className='encounter-input-section'>
							<EncounterInputField
								value={encounterFieldDisplay(billingencounter, [
									'athena_payment_batch_id',
								])}
								name=''
								disabled={true}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='edit-encntr-lbl-billing-notes'>BILLING NOTES</div>

			<div className='segment-row'>
				<EncounterTextAreaField
					value={_.get(formData, ['billing_notes'], '')}
					name='billing_notes'
					onChange={(value) => onFormChange('billing_notes', value)}
				/>
			</div>

			<div className='segment-footer-container'>
				<div className='cancel-btn-link'>
					<Button onClick={() => cancel()}>Cancel</Button>
				</div>
				<div className='save-btn-link'>
					<Button style={{ float: 'right' }} onClick={saveForm}>
						Save
					</Button>
				</div>
			</div>
		</Loading>
	);
};

export default EditBillingSegment;
