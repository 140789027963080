import axios from 'axios';
import _ from 'lodash';
import mime from 'mime';

const mappingFields = {
	dateAdded: 'created_at',
	name: 'file_name',
	type: 'type',
	lastModified: 'updated_at',
	kind: 'file_extension_id',
	addedBy: 'created_by',
	encounter: 'encounter',
};

let cancelController;

class DocumentsService {
	_printIframe = null;

	listV2 = (patient_uuid, type, active, order_by, order_type) => {
		cancelController?.abort();
		cancelController = new AbortController();

		let searchParams = '';
		if (type) searchParams += `&type=${type}`;
		if (active) searchParams += `&active=${active}`;
		if (order_by) searchParams += `&order_by=${mappingFields[order_by]}`;
		if (order_type) searchParams += `&order_type=${order_type}`;
		searchParams += `&groups=true`;
		return axios.get(
			`/api/patients/${patient_uuid}/documents?${searchParams}`,
			{
				signal: cancelController.signal,
			}
		);
	};

	download = (patientuuid, data) => {
		const url =
			'/api/documentdetail?id=' + patientuuid + '&docid=' + data.id;
		return axios({
			url: url,
			method: 'GET',
			responseType: 'blob', // important
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', data.file_name);
			document.body.appendChild(link);
			link.click();
		});
	};

	print = (patientuuid, data) => {
		const url =
			'/api/documentdetail?id=' + patientuuid + '&docid=' + data.id;
		let iframe = this._printIframe;
		if (_.isNil(this._printIframe)) {
			iframe = this._printIframe = document.createElement('iframe');
			document.body.appendChild(iframe);
			iframe.style.display = 'none';
			iframe.onload = function () {
				setTimeout(function () {
					iframe.focus();
					iframe.contentWindow.print();
				}, 1);
			};
		}
		iframe.src = url;
	};

	getTypes = (param) => {
		return axios.get(
			`/api/enums/documents/types${param ? `?category=${param}` : ''}`
		);
	};

	createDocument = (
		encounteruuid,
		documenttype,
		patientuuid,
		template,
		description,
		documentSubType
	) => {
		const url = '/api/patients/' + patientuuid + '/documents'; //type=gdrive param in apiUrl
		const data = {
			document_type: documenttype,
			encounter_uuid: encounteruuid,
			template_uuid: template,
		};
		if (!data.encounter_uuid) delete data.encounter_uuid;
		if (!data.template) delete data.template;
		if (description) data.description = description;
		if (documentSubType) data.document_subtype = documentSubType;
		return axios.post(url, data);
	};

	get = (patient_uuid, document_uuid) => {
		return axios.get(
			`/api/patients/${patient_uuid}/documents/${document_uuid}?type=gdrive`
		);
	};

	inlineDocumentDetail = (documentObj, action = 'read', patientUuid) => {
		const endpoint = `/api/document/s3docs?doc_uuid=${
			documentObj.uuid
		}&patient_uuid=${patientUuid}&service_type=${
			action || 'read'
		}&type=s3&extension=.${documentObj.file_extension_id}`;
		return axios({
			url: endpoint,
			method: 'GET',
			responseType: 'blob',
		});
	};

	documentAction = (documentObj, action, patientUuid) => {
		const endpoint = `/api/document/s3docs?doc_uuid=${
			documentObj.uuid
		}&patient_uuid=${patientUuid}&service_type=${
			action || ''
		}&type=s3&extension=.${documentObj.file_extension_id}`;
		return axios({
			url: endpoint,
			method: 'GET',
			responseType: 'blob',
		}).then((res) => {
			const blob = res.data;
			let type;
			let contentType;
			let newBlob;
			switch (action) {
				case 'downloaded': {
					contentType = mime.getType(
						_.get(documentObj, 'file_extension_id', '')
					);
					newBlob = blob.slice(
						0,
						blob.size,
						contentType || blob.type
					);
					const dlUrl = window.URL.createObjectURL(newBlob);
					const anchor = document.createElement('a');
					anchor.href = dlUrl;
					anchor.setAttribute('download', documentObj.file_name);
					document.querySelector('body').appendChild(anchor);
					anchor.click();
					anchor.remove();
					break;
				}
				case 'printed': {
						contentType = mime.getType(
						_.get(documentObj, 'file_extension_id', '')
					);
					newBlob = blob.slice(
						0,
						blob.size,
						contentType || blob.type
					);
					const printUrl = window.URL.createObjectURL(newBlob);
					const iframeEl = this._printIframe
						? this._printIframe
						: document.createElement('iframe');
					iframeEl.src = printUrl;
					iframeEl.style.display = 'none';
					iframeEl.style.position = 'absolute';
					document.querySelector('body').appendChild(iframeEl);
					iframeEl.onload = function () {
						setTimeout(function () {
							iframeEl.focus();
							iframeEl.contentWindow.print();
						});
					};
					break;
				}
				default:
					throw new Error('Unrecognized document action.');
			}
			return true;
		});
	};

	getTemplatesFlexible = (
		uuid,
		docType,
		q,
		document_state,
		category,
		order_by,
		order_type,
		offset,
		limit
	) => {
		const payload = {};
		if (uuid) {
			if (Array.isArray(uuid)) {
				payload['partner_uuids'] = uuid;
			} else {
				payload['partner_uuids'] = [uuid];
			}
		} else {
			payload['partner_uuids'] = [];
		}
		if (docType) {
			if (Array.isArray(docType)) {
				payload['document_type'] = docType;
			} else {
				payload['document_type'] = [docType];
			}
		} else {
			payload['document_type'] = [];
		}
		if (q) payload['q'] = q;
		if (document_state) {
			if (Array.isArray(document_state)) {
				payload['document_state'] = document_state;
			} else {
				payload['document_state'] = [document_state];
			}
		} else {
			payload['document_state'] = [];
		}
		if (category) payload['category'] = category;
		if (order_by) payload['order_by'] = order_by;
		if (order_type) payload['order_type'] = order_type;
		if (_.isNumber(offset)) payload['offset'] = offset;
		if (_.isNumber(limit)) payload['limit'] = limit;
		return axios.post(`/api/partners/documents/search`, payload);
	};

	createTemplate = (
		document_type,
		file_name,
		partner_uuids,
		clone_from_template_uuid
	) => {
		const payload = {
			document_type,
			file_name,
		};
		if (partner_uuids) payload['partner_uuids'] = partner_uuids;
		if (clone_from_template_uuid)
			payload['clone_from_template_uuid'] = clone_from_template_uuid;

		return axios.post(`/api/partners/documents/template/create`, payload);
	};

	editTemplate = (
		document_uuid,
		document_type,
		file_name,
		partner_uuids,
		template_state
	) => {
		const payload = {
			document_type,
			file_name,
		};
		if (partner_uuids) payload['partner_uuids'] = partner_uuids;
		if (template_state) payload['template_state'] = template_state;

		return axios.post(`/api/partners/documents/${document_uuid}`, payload);
	};

	archiveTemplate = (document_uuid) => {
		return axios.post(
			`/api/partners/documents/template/archive/${document_uuid}`
		);
	};

	batchUpdateTemplatePartners = (
		document_uuids,
		partner_uuids,
		document_type
	) => {
		const data = {
			document_uuids,
		};
		if (partner_uuids) data['partner_uuids'] = partner_uuids;
		if (document_type) data['document_type'] = document_type;
		return axios.post(`/api/partners/documents/templates/update`, data);
	};

	getWorkflowDocs = (uuid) => {
		return axios.get(`/api/patients/${uuid}/documents/workflowdocs`);
	};

	uploadFile = async (data) => {
		const res = await axios.put(
			`/api/patients/${data.patientuuid}/documents`,
			data.payload
		);
		return res;
	};

	archiveDocument = (patient_uuid, document_uuid) => {
		return axios.post(
			`/api/archivedocument/${patient_uuid}/${document_uuid}?type=gdrive`
		);
	};

	finalizeDocument = (patient_uuid, document_uuid, data) => {
		return axios.post(
			`/api/patients/${patient_uuid}/documents/${document_uuid}/finalize`,
			data
		);
	};

	updateDocumentType = (patient_uuid, document_uuid, payload) => {
		const url = `/api/patients/${patient_uuid}/documents/${document_uuid}`;
		const data = { data: payload };
		return axios.post(url, data);
	};

	shareDocument = (patient_uuid, document_uuid, body) => {
		return axios.post(
			`/api/patients/${patient_uuid}/documents/${document_uuid}/share`,
			body
		);
	};

	updateDocumentRelatedEncounter(
		patientUuid,
		documentUuid,
		documentType,
		newEncounterUuid
	) {
		const payload = {
			document_type: documentType,
			encounter_uuid: newEncounterUuid,
		};
		return axios.post(
			`/api/patients/${patientUuid}/documents/${documentUuid}/update`,
			payload
		);
	}

	getShareMediums = (param, recipient_type = null) => {
		return axios.get(
			`/api/enums/documents/share/mediums${
				param ? `?type=${param}` : ''
			}${recipient_type ? `&recipient_type=${recipient_type}` : ''}`
		);
	};

	getShareRecipients = (param) => {
		return axios.get(
			`/api/enums/documents/share/recipients${
				param ? `?type=${param}` : ''
			}`
		);
	};

	getAuditTrail = (patientUUID, documentUUID) => {
		return axios.get(
			`/api/patients/${patientUUID}/documents/${documentUUID}/activity`
		);
	};

	retractDocument = (patientUUID, documentUUID) => {
		return axios.get(
			`/api/patients/${patientUUID}/documents/${documentUUID}/retract`
		);
	};

	combineDocument = async (patient_uuid, data) => {
		return axios.post(
			`/api/patients/${patient_uuid}/merge-documents`,
			data
		);
	};
}

export default new DocumentsService();
