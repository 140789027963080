//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../../../../assets/exit-alert.svg';

class AuditTrailTitleRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		return (
			<div className='auditTrailTitleRow'>
				<span>Audit Trail</span>
				<div
					className='auditTrailClose'
					style={{
						top: '-1px',
						transform: 'scale(1.25)',
						borderTopRightRadius: '4px',
					}}
				>
					<ReactSVG
						src={xIcon}
						style={{
							position: 'relative',
							bottom: '-8px',
							right: '-9px',
						}}
						onClick={this.props.closeAuditTrail}
					/>
				</div>
			</div>
		);
	}
}

export default AuditTrailTitleRow;
