//React & Redux
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//Actions & Services
import {
	createInsuranceCompanyInfo,
	getInsuranceCompanyInfo,
	updateInsuranceCompanyInfo,
} from '../../actions/insurancecompany';
import { getReferralPrograms } from '../../actions/patients';

//Components
import InsuranceList from './InsuranceList.js';

const InsuranceContainer = (props) => {
	const {
		actions: {
			createInsuranceCompanyInfo,
			getInsuranceCompanyInfo,
			getReferralPrograms,
			updateInsuranceCompanyInfo,
		},
		enqueueSnackbar,
		insuranceInfo,
		limit = 25,
		referralPrograms: {
			gettingReferralPrograms = false,
			referralPrograms = [],
		},
	} = props;

	useEffect(() => {
		getReferralPrograms();
	}, []);

	const getInsuranceCompanies = (data = { limit, page: '1' }) => {
		const request = {
			limit,
			page: '1',
			...data,
		};
		getInsuranceCompanyInfo(request);
	};

	return (
		<InsuranceList
			{...{
				createInsuranceCompanyInfo,
				enqueueSnackbar,
				getInsuranceCompanies,
				gettingReferralPrograms,
				insuranceInfo,
				limit,
				referralPrograms,
				updateInsuranceCompanyInfo,
			}}
		/>
	);
};

const mapStateToProps = (state) => ({
	insuranceInfo: {
		loadingList: state.insurancecompany.loadingGetInsuranceCompanies,
		loadedList: state.insurancecompany.loadedGetInsuranceCompanies,
		creatingInsuranceCompany:
			state.insurancecompany.creatingInsuranceCompany,
		createdInsuranceCompany: state.insurancecompany.createdInsuranceCompany,
		createdInsuranceCompanyError:
			state.insurancecompany.createdInsuranceCompanyError,
		updatingInsuranceCompany:
			state.insurancecompany.updatingInsuranceCompany,
		updatedInsuranceCompany: state.insurancecompany.updatedInsuranceCompany,
		updatedInsuranceCompanyError:
			state.insurancecompany.updatedInsuranceCompanyError,
	},
	referralPrograms: {
		referralPrograms: state.referralPrograms.referralPrograms.data,
		gettingReferralPrograms: state.referralPrograms.gettingReferralPrograms,
		referralProgramsError: state.referralPrograms.referralProgramsError,
	},
	limit: state.uiConfig.table.resultsPerPage,
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(
		{
			createInsuranceCompanyInfo,
			getInsuranceCompanyInfo,
			getReferralPrograms,
			updateInsuranceCompanyInfo,
		},
		dispatch
	),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceContainer);
