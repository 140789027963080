//React & Redux
import React, { useEffect, useRef } from 'react';

//Lodash
import get from 'lodash/get';

//Components
import TagSearchResultsRow from './TagSearchResultsRow.js';

const TagSearchResults = (props) => {
	const {
		icdCodes = [],
		scrollHandler,
		selectedICDCodes = [],
		setBodyElement,
		toggleCodeSelection,
	} = props;

	const resultsContainer = useRef(null);

	useEffect(() => {
		if (resultsContainer) setBodyElement(resultsContainer);
	}, []);

	const determineIsSelected = (uuid) => {
		return !!(selectedICDCodes || []).filter(
			(el) => get(el, 'uuid', '') === uuid
		).length;
	};

	return (
		<div
			className='chargeICDSearchResultsContainer'
			ref={resultsContainer}
			onScroll={(e) => scrollHandler(['top', 'bottom'])}
		>
			{(icdCodes || []).map((el, i) => {
				return (
					<TagSearchResultsRow
						icdCode={el}
						key={el.uuid}
						selectedICDCodes={selectedICDCodes}
						toggleCodeSelection={toggleCodeSelection}
						isSelected={determineIsSelected(get(el, 'uuid', ''))}
						topIntersectionObserver={i === 0}
						bottomIntersectionObserver={i === icdCodes.length}
					/>
				);
			})}
		</div>
	);
};

export default TagSearchResults;
