import classnames from 'classnames';
import React from 'react';

import './Section.scss';

interface IProps {
	className?: string;
	title?: string;
	children: React.ReactNode;
}

export const Section: React.FC<IProps> = (props) => {
	const { className, title, children } = props;
	return (
		<section className={classnames('page-section', className)}>
			<h2>{title}</h2>
			<div className='body'>{children}</div>
		</section>
	);
};
