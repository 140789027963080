import React from 'react';

export default class ErrorBoundary extends React.Component {
	state = { error: null, errorInfo: null };

	componentDidCatch(error, errorInfo) {
		this.setState({ error, errorInfo });
		// TODO
		// Submit error logs to server
	}

	render() {
		return this.props.children;
	}
}
