//React & Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

//Lodash
import _ from 'lodash';

//Utils
import { ROLE_PHYSICIAN, ROLE_SUPERUSER } from '../../Utils/permissionUtils';

//Actions and Services
import StorageService from '../../service/Storage';

class Signincallback extends Component {
	constructor(props) {
		super(props);
	}

	RedirectUser = () => {
		const user = _.get(this, ['props', 'me', 'user'], {});
		if (!_.isEmpty(user)) {
			const activerole = _.get(
				this,
				['props', 'me', 'activerole', 'name'],
				''
			);
			let rolebasedpath = '/app/dashboard';
			if (activerole === ROLE_SUPERUSER)
				rolebasedpath = '/app/useraccounts';
			else if (activerole == ROLE_PHYSICIAN)
				rolebasedpath = '/app/testauthorization';

			let path = StorageService.RedirectURL();
			path = path ? path : rolebasedpath;
			StorageService.CLEARRedirectURL();
			return <Redirect to={path} />;
		}
		return null;
	};

	render() {
		return <div>{this.RedirectUser()}</div>;
	}
}

const mapStateToProps = (state, props) => ({
	me: state.me,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Signincallback);
