import axios from 'axios';
import { get } from 'lodash';
import { deHydratePreauthorizationsTestOrder } from './../transformers/patients/encounterTransformer';
import { hydrateOutreachs } from './../transformers/patients/outreachTransformer';

let cancelController;

class EncouterService {
	encounterlist = (data) => {
		return axios.get(
			'/api/encounters?id=' +
				data.id +
				'&offset=' +
				data.offset +
				'&order_by=' +
				get(data, 'order_by', 'created_at') +
				'&order_type=' +
				get(data, 'order_type', 'desc') +
				'&limit=' +
				get(data, 'limit', '20')
		);
	};

	encounterdetail = (uuid) => {
		return axios.get(`/api/encounters/${uuid}`);
	};

	editencouter = (data) => {
		if (data.data.type == 'visit')
			return axios.put('/api/encounters/visits', data);
		else if (data.data.type == 'cc-intake')
			return axios.put('/api/encounters/cc_intakes', data);
		else if (data.data.type == 'group-session')
			return axios.put('/api/encounters/groupsessions', data);
		else if (data.data.type == 'research-data')
			return axios.put('/api/encounters/research_datas', data);
		else if (data.data.type == 'scp')
			return axios.put('/api/encounters/scp', data);
		else return axios.put(`/api/encounters/${data.data.uuid}`, data);
	};

	completeEncounter = (data) => {
		return axios.post(`/api/encounters/visits/${data.uuid}/complete`);
	};

	migrateToPioneerRCO = async (data) => {
		const response = await axios.post(
			`/api/encounters/${data.uuid}/rco/pioneer`,
			{},
			{
				validateStatus: function (status) {
					return status >= 200 && status <= 500;
				},
			}
		);
		return response;
	};

	cancelEncounter = async (data) => {
		const response = await axios.post(
			`/api/encounters/${data.uuid}/appointment_status/${data.status}/notify_patient/${data.notify_patient}`,
			{},
			{
				validateStatus: function (status) {
					return status >= 200 && status <= 500;
				},
			}
		);
		return response;
	};

	addencounter = (data) => {
		if (data.data.type == 'cc-intake')
			return axios.post('/api/encounters/cc_intakes', data);
		else return axios.post('/api/encounters/groupsessions', data);
	};

	encounterschema = (value, params) => {
		const queryString = get(params, 'partner_uuid')
			? `?partner_uuid=${params.partner_uuid}`
			: '';
		return axios.get(`/api/encounters/enums/schema/${value}${queryString}`);
	};

	specificencounterenum = (type, field_name) => {
		const encountertype =
			type == 'lab_test_authorization'
				? 'labs'
				: type == 'visit'
				? 'visits'
				: type;
		return axios.get(
			'/api/encounters/enums/dynamicenums?type=' +
				encountertype +
				'&field_name=' +
				field_name
		);
	};

	researchpartnerinvoicestatus = () => {
		return axios.get(
			'/api/encounter/research_datas/partner_invoice_status'
		);
	};

	getBillingEncounterObject = (uuid) => {
		return axios.get(`/api/encounters/${uuid}/billing`);
	};

	getTestNameEnums = (labuuid, params = {}) => {
		let url = `/api/enums/genetic-test/test-name/${labuuid}`;
		if (params.include_currently_not_offered_or_legacy_tests) {
			url += `?include_currently_not_offered_or_legacy_tests=${params.include_currently_not_offered_or_legacy_tests}`;
		}
		return axios.get(url);
	};

	getVisitConsulatationTypesEnums = (
		limit = 100,
		page = 1,
		with_trashed = false
	) => {
		return axios.get(
			`/api/encounters/enums/visits/consultations?limit=${limit}&page=${page}&with_trashed=${with_trashed}`
		);
	};

	getPayorEnums = () => {
		return axios.get(`/api/encounters/enums/billing/payor`);
	};

	getICDCodes = (query = '', offset = 0, limit = 100) => {
		return axios.get(
			`/api/medical-codes?q=${
				query || ''
			}&offset=${offset}&limit=${limit}`
		);
	};

	reClaim = (encounter_uuid = 0) => {
		return axios.post(`/api/encounters/${encounter_uuid}/claim`);
	};

	preauthorizationsTestOrder = (encounter_uuid = 0, data) => {
		const body = { data: deHydratePreauthorizationsTestOrder(data) };

		return axios.post(
			`/api/encounters/${encounter_uuid}/preauthorizations`,
			body
		);
	};

	getPreauthorizationsEnums = () => {
		return axios.get('/api/enums/preauthorizations');
	};

	getPreauthorizationTestOrders = (encounter_uuid) => {
		return axios.get(`/api/encounters/${encounter_uuid}/preauthorizations`);
	};

	getTestOrderRequest = (encounter_uuid, grouped = true) => {
		cancelController?.abort();
		cancelController = new AbortController();

		return axios.get(
			`/api/encounters/${encounter_uuid}/gene-test-orders?grouped=${grouped}`,
			{ signal: cancelController.signal }
		);
	};

	addTestOrderRequest = (encounter_uuid, data, grouped) => {
		return axios.post(
			`/api/encounters/${encounter_uuid}/gene-test-orders?grouped=${grouped}`,
			data
		);
	};

	getGeneTestEnums = () => {
		return axios.get(`/api/enums/genetic-test/gene-test-order`);
	};

	getGeneTestOrderStatusEnum = () => {
		return axios.get('/api/me/enums/gene-test-order-status');
	};

	scpTestAuthorizationSearch = (data) => {
		return axios.post(`/api/genetic-tests/gene-test-orders/search`, data);
	};

	testBulkUpdate = (data, is_md_auth = true) => {
		return axios.post(
			`/api/me/genetic-tests/gene-test-orders/bulk-update?is_md_auth=${is_md_auth}`,
			data
		);
	};

	getSCPClinicalInfo = (uuid) => {
		return axios.get(`/api/encounters/${uuid}/clinical-info`);
	};

	// Update a gene test order given its gene test order uuid
	updateTestOrder = (test_order_uuid, data) => {
		return axios.put(
			`/api/genetic-tests/gene-test-orders/${test_order_uuid}`,
			data
		);
	};

	// Update preauthorization test order given its preauth uuid
	updatePreAuthTestOrder = (preauth_test_order_uuid, data) => {
		return axios.put(
			`/api/genetic-tests/preauthorizations/${preauth_test_order_uuid}`,
			data
		);
	};

	//create a new note for a gene test order based on the gene_test_order_uuid provided
	addTestOrderNote = (test_order_uuid, data) => {
		return axios.post(
			`/api/genetic-tests/gene-test-orders/${test_order_uuid}/notes`,
			data
		);
	};

	//retrieves list of notes based on the gene_test_order_uuid provided
	getTestOrderNotes = (test_order_uuid) => {
		return axios.get(
			`/api/genetic-tests/gene-test-orders/${test_order_uuid}/notes?limit=100`
		);
	};

	//Create gene test order flags
	createTestOrderFlags = (test_order_uuid, data) => {
		return axios.post(
			`/api/genetic-tests/gene-test-orders/${test_order_uuid}/flags`,
			data
		);
	};

	//Update gene test order flags
	updateTestOrderFlags = (test_order_uuid, data) => {
		return axios.put(
			`/api/genetic-tests/gene-test-orders/${test_order_uuid}/flags`,
			data
		);
	};

	getAuditTrailEncounter = (encounterUuid, payload = {}) => {
		return axios.post(`/api/audit-trail/${encounterUuid}`, payload);
	};

	getEncounterAuditEnum = () => {
		return axios.get('/api/enums/audit-trail-history');
	};

	getEncounterBillingEnum = () => {
		return axios.get('/api/enums/billing-history');
	};

	addManualVisitEncouter = (data) => {
		return axios.post('/api/encounters/visits/manual-creation', data);
	};

	getContentViewedByPatient = (uuid) => {
		return axios.get(
			`/api/encounters/${uuid}/scp-content-viewed-by-patient`
		);
	};

	getOutreachs = (encounterUuid) => {
		const url = `/api/encounters/${encounterUuid}/outreach`;
		return axios.get(url).then((data) => hydrateOutreachs(data));
	};

	followUpDeclinedOutreach = (encounterUuid, data) => {
		return axios.put(`/api/encounters/${encounterUuid}`, data);
	};

	linkProviders = (data) => {
		return axios.post('/api/encounters/providers', data);
	};

	unlinkProvider = (encounter_uuid, provider_uuid) => {
		return axios.delete(
			`/api/encounters/${encounter_uuid}/providers/${provider_uuid}`
		);
	};

	getVisitIndications = () => {
		return axios.get('/api/visit-indications');
	};

	updateTestOrderRedFlag = (gene_test_order_uuid, data) => {
		return axios.patch(
			`/api/genetic-tests/gene-test-orders/${gene_test_order_uuid}/red-flags`,
			data
		);
	};

	getEncounterDocuments = (encounter_uuid) => {
		return axios.get(`/api/encounters/${encounter_uuid}/documents`);
	};
}

export default new EncouterService();
