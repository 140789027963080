//React & Redux
import React from 'react';

//Lodash
import { find, sortBy } from 'lodash';

//Components
import SingleSelectPill from './SingleSelectPill';
import MultiSelectCheckboxModal from './MultiSelectCheckboxModal';

//Styles
import './styles.css';

//Other Libraries
import classnames from 'classnames';

class MultiSelectCheckbox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasSubmitFunc: false,
			modalOpen: false,
			pillElement: null,
			selected: [...(this.props.selected || [])],
		};

		//bindings
		this.checkHasSubmitFunc = this.checkHasSubmitFunc.bind(this);
		this.findLabel = this.findLabel.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.setPillElement = this.setPillElement.bind(this);
		this.saveSelected = this.saveSelected.bind(this);
	}
	//functions

	componentDidMount() {
		this.checkHasSubmitFunc();
	}

	checkHasSubmitFunc() {
		if (this.props.submit) {
			this.setState({ hasSubmitFunc: true });
		}
	}

	saveSelected(selected) {
		this.setState({ selected: [...selected] }, () =>
			this.props.submit(selected)
		);
	}

	findLabel() {
		const data = this.props.data || null;
		const dataItemLength = Array.isArray(data)
			? data.filter((el) => !el.groupingLabel || null).length
			: 0;
		const selected = this.props.selected || [];
		const selectedLength = this.props.selected?.length || 0;
		const majorPillTitle = this.props.majorPillTitle || null;

		if (this.props.alwaysNumber || false) {
			return `${majorPillTitle}: ${
				selectedLength === dataItemLength ||
				(selectedLength === 0 &&
					(!this.props.emptyInputFullOutputOverride || false))
					? 'All'
					: selectedLength
			}`;
		}

		if (
			Array.isArray(data) &&
			dataItemLength &&
			Array.isArray(selected) &&
			majorPillTitle
		) {
			if (selectedLength > 1)
				return `${majorPillTitle}: ${
					selectedLength === dataItemLength ? 'All' : selectedLength
				}`;
			else if (selectedLength) {
				const currentOption = find(
					data,
					(el) => selected.indexOf(el.key || null) !== -1
				);
				return (
					majorPillTitle +
					': ' +
					(currentOption ? currentOption.display_name : '')
				);
			} else if (
				(!this.props.emptyInputFullOutputOverride || false) &&
				selectedLength === 0
			) {
				return `${majorPillTitle}: All`;
			} else return majorPillTitle;
		} else return majorPillTitle;
	}

	openModal() {
		this.setState({ modalOpen: true });
	}

	closeModal() {
		this.setState({ modalOpen: false });
	}

	setPillElement(el) {
		if (el) this.setState({ pillElement: el });
	}

	sortData = () => {
		if (this.props.sortingKey) {
			const sortKey = this.props.sortingKey || '';
			const data = this.props.data || [];
			return sortBy(data, [(o) => (o[sortKey] || '').toLowerCase()]);
		} else {
			return this.props.data || [];
		}
	};

	render() {
		const { radioFilterOptions = {} } = this.props;
		return (
			<div
				className={classnames(
					`multi-select-checkbox-container ${
						this?.props?.componentClassName || ''
					}`
				)}
			>
				<SingleSelectPill
					label={this.findLabel()}
					modalOpen={this.state.modalOpen}
					openModal={this.openModal}
					closeModal={this.closeModal}
					setPillElement={this.setPillElement}
				/>
				{this.state.modalOpen && (
					<MultiSelectCheckboxModal
						{...{
							allowsEmptyOutput:
								this.props.allowsEmptyOutput || false,
							closeModal: this.closeModal,
							data: this.sortData(),
							defaultValues: this.props.defaultValues,
							emptyInputFullOutputOverride:
								this.props.emptyInputFullOutputOverride ||
								false,
							handleChangeList: this.props.handleChangeList,
							hasSubmitFunc: this.state.hasSubmitFunc || false,
							hideSelectAll: this.props.hideSelectAll || false,
							loading: this.props.loading || false,
							pillElement: this.state.pillElement || null,
							previewTab: this.props.previewTab,
							radioFilterOptions,
							saveSelected: this.saveSelected,
							searchBar: this.props.searchBar,
							selected: this.props.selected || [],
							title: this.props.title,
						}}
					/>
				)}
			</div>
		);
	}
}

export default MultiSelectCheckbox;
