//React & Redux
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

//Lodash
import { isEmpty } from 'lodash';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import DocumentInlineView from './DocumentInlineView.js';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious.js';

const DocumentViewDetail = (props) => {
	const {
		archivingDocument,
		document: docDetail = {},
		documentActionError,
		documentActionLoading,
		downloadDocument,
		enqueueSnackbar,
		getDocumentDetail,
		getInlineDocumentDetail,
		inlineDocDetail,
		inlineDocDetailError,
		inlineDocDetailLoading,
		loading = false,
		match: { params },
		navigateToDocumentList,
		printDocument,
		setModal,
		unloadDetailDocument,
		uuid,
	} = props;

	const prevDocumentActionError = usePrevious(documentActionError);
	const prevInlineDocDetailError = usePrevious(inlineDocDetailError);

	useEffect(() => {
		unloadDetailDocument();
		fngetDocumentDetail(params.encounterid || 0);

		return () => unloadDetailDocument();
	}, []);

	useEffect(() => {
		documentActionError &&
			!prevDocumentActionError &&
			enqueueFailedLoadDocument();
	}, [documentActionError]);

	useEffect(() => {
		inlineDocDetailError &&
			!prevInlineDocDetailError &&
			enqueueFailedLoadDocument();
	}, [inlineDocDetailError]);

	useEffect(() => {
		!isEmpty(docDetail) &&
			docDetail.is_editable &&
			navigateToDocumentList();
	}, [docDetail]);

	const enqueueFailedLoadDocument = () => {
		enqueueSnackbar('Failed to load document.', {
			variant: 'error',
			anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
		});
	};

	const fngetDocumentDetail = (document_uuid) => {
		document_uuid != 0 && getDocumentDetail(document_uuid);
	};

	const archiveFile = (e, doc) => {
		e.stopPropagation();
		setModal('archival', doc.uuid);
	};

	return !isEmpty(docDetail) &&
		(docDetail?.uuid || '') == (params.encounterid || 0) ? (
		<DocumentInlineView
			{...{
				archiveFile,
				archivingFile: archivingDocument,
				docDetail,
				documentActionLoading,
				downloadDocument,
				getInlineDocumentDetail,
				inlineDocDetail,
				inlineDocDetailError,
				inlineDocDetailLoading,
				navigateToDocumentList,
				patientUuid: uuid,
				printDocument,
			}}
		/>
	) : loading ? (
		<Loading style={{ top: 'calc(50vh - 160px)' }} />
	) : null;
};

DocumentViewDetail.propTypes = {
	getInlineDocumentDetail: PropTypes.func.isRequired,
	navigateToDocumentList: PropTypes.func.isRequired,
	printDocument: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	uuid: PropTypes.string.isRequired,
};

export default DocumentViewDetail;
