import * as actionTypes from './actionTypes';
import BillingService from '../service/Billing';
import get from 'lodash/get';

const loadReferralChannelsList = () => {
	return {
		type: actionTypes.LOADING_REFERRAL_CHANNELS,
	};
};

export const referralchannelslist = (data) => {
	return {
		type: actionTypes.REFERRAL_CHANNELS,
		payload: data,
	};
};

const referralChannelsListError = (err) => {
	return {
		type: actionTypes.REFERRAL_CHANNELS_ERROR,
		payload: err,
	};
};

export const billingAffiliations = (data) => {
	return {
		type: actionTypes.BILLING_AFFILIATIONS,
		payload: data,
	};
};

const loadingBillingDashboardCounts = () => {
	return {
		type: actionTypes.LOADING_BILLING_DASHBOARD_COUNTS,
	};
};

export const billingDashboardCounts = (data) => {
	return {
		type: actionTypes.BILLING_DASHBOARD_COUNTS,
		payload: data,
	};
};

const billingDashboardCountsError = (err) => {
	return {
		type: actionTypes.BILLING_DASHBOARD_COUNTS_ERROR,
		payload: err,
	};
};
const loadingBillingEncounter = () => {
	return {
		type: actionTypes.LOADING_BILLING_ENCOUNTER_DETAIL,
	};
};

export const billingEncounter = (data) => {
	return {
		type: actionTypes.BILLING_ENCOUNTER_DETAIL,
		payload: data,
	};
};

const billingEncounterError = (err) => {
	return {
		type: actionTypes.BILLING_ENCOUNTER_DETAIL_ERROR,
		payload: err,
	};
};

const loadBillingPatient = () => {
	return {
		type: actionTypes.LOADING_BILLING_PATIENT_DETAIL,
	};
};

export const billingPatient = (data) => {
	return {
		type: actionTypes.BILLING_PATIENT_DETAIL,
		payload: data,
	};
};

const billingPatientError = (data) => {
	return {
		type: actionTypes.BILLING_PATIENT_DETAIL_ERROR,
		payload: data,
	};
};

const loadingBillingobject = () => {
	return {
		type: actionTypes.LOADING_ENCOUNTER_BILLING_OBJECT,
	};
};

export const billingobject = (data) => {
	return {
		type: actionTypes.ENCOUNTER_BILLING_OBJECT,
		payload: data,
	};
};

const billingObjectError = (err) => {
	return {
		type: actionTypes.ENCOUNTER_BILLING_OBJECT_ERROR,
		payload: err,
	};
};

const loadingEncounterEnums = () => {
	return {
		type: actionTypes.LOADING_BILLING_ENCOUNTER_ENUMS,
	};
};

export const encounterEnums = (data) => {
	return {
		type: actionTypes.BILLING_ENCOUNTER_ENUMS,
		payload: data,
	};
};

const encounterEnumsError = (data) => {
	return {
		type: actionTypes.BILLING_ENCOUNTER_ENUMS_ERROR,
		payload: data,
	};
};

export const saveEncounterData = (data) => {
	return {
		type: actionTypes.SAVE_ENCOUNTER_DATA,
		payload: data,
	};
};

const loadingExportBillingEncountersReport = () => {
	return {
		type: actionTypes.LOADING_EXPORT_BILLINGS_REPORT,
	};
};

export const exportBillingEncountersReport = (data) => {
	return {
		type: actionTypes.EXPORT_BILLINGS_REPORT,
		payload: data,
	};
};

const exportBillingEncountersReportError = (data) => {
	return {
		type: actionTypes.EXPORT_BILLINGS_REPORT_ERROR,
		payload: data,
	};
};

export function resetBillingaffiliation() {
	return (dispatch) => {
		dispatch(billingAffiliations({ data: [] }));
	};
}

export function getBillingaffiliation(data) {
	return (dispatch) => {
		dispatch(resetBillingaffiliation());
		BillingService.billingaffiliations(data)
			.then((res) => {
				dispatch(billingAffiliations({ data: res.data.data }));
			})
			.catch(() => {});
	};
}

//Get billing encounters
export function getBillingEncountersList(data) {
	return (dispatch) => {
		dispatch(gettingBillingEncounters());
		BillingService.billingEncounters(data)
			.then((res) => {
				dispatch(billingEncounters({ data: res.data }));
			})
			.catch((err) => {
				dispatch(billingEncountersError(err));
			});
	};
}

export const gettingBillingEncounters = () => {
	return {
		type: actionTypes.GETTING_BILLING_ENCOUNTERS,
		payload: true,
	};
};

export const billingEncounters = (data) => {
	return {
		type: actionTypes.BILLING_ENCOUNTERS,
		payload: data,
	};
};

export const billingEncountersError = (err) => {
	return {
		type: actionTypes.BILLING_ENCOUNTERS_ERROR,
		payload: err,
	};
};

export function getReferralChannelsList(data) {
	return (dispatch) => {
		dispatch(loadReferralChannelsList());
		BillingService.referralchannels(data)
			.then((res) => {
				dispatch(
					referralchannelslist({ data: res.data.referralchannels })
				);
				dispatch(billingAffiliations({ data: res.data }));
			})
			.catch((err) => {
				dispatch(referralChannelsListError(err));
			});
	};
}

export function getBillingDashboardCounts(data) {
	return (dispatch) => {
		dispatch(loadingBillingDashboardCounts());
		BillingService.getCounts(data)
			.then((res) => {
				dispatch(billingDashboardCounts({ data: res.data }));
			})
			.catch((err) => {
				dispatch(billingDashboardCountsError(err));
			});
	};
}

export function getBillingEncounterDetail(data) {
	return (dispatch) => {
		dispatch(loadingBillingEncounter());
		BillingService.getBillingEncounterDetail(data)
			.then((res) => {
				dispatch(billingEncounter({ data: res.data }));
			})
			.catch((err) => {
				dispatch(billingEncounterError(err));
			});
		dispatch(loadingBillingobject());
		BillingService.getBillingEncounterObject(data)
			.then((res) => {
				dispatch(billingobject({ data: res.data }));
			})
			.catch((err) => {
				dispatch(billingObjectError(err));
			});
	};
}

export function getBillingPatientDetail(data) {
	return (dispatch) => {
		dispatch(loadBillingPatient());
		BillingService.getBillingPatientDetail(data)
			.then((res) => {
				dispatch(billingPatient({ data: res.data }));
			})
			.catch((err) => {
				dispatch(billingPatientError(err));
			});
	};
}

export function getEncounterEnums(data) {
	return (dispatch) => {
		dispatch(loadingEncounterEnums());
		BillingService.getEncounterEnums(data)
			.then((res) => {
				dispatch(encounterEnums({ data: res.data }));
			})
			.catch((err) => {
				dispatch(encounterEnumsError(err));
			});
	};
}

export function saveEncounterDetails(data) {
	return (dispatch) => {
		BillingService.saveEncounter(data).then((res) => {
			dispatch(saveEncounterData({ data: res.data }));
		});
	};
}

//Save/edit billing encounters
export function saveBillingEncounter(data) {
	return (dispatch) => {
		dispatch(savingBillingEncounterDetails());
		BillingService.saveBillingEncounter(data)
			.then((res) => {
				dispatch(saveBillingEncounterDetails({ data: res.data }));
			})
			.catch((err) => {
				dispatch(saveBillingEncounterDetailsError(err));
			});
	};
}

export const savingBillingEncounterDetails = () => {
	return {
		type: actionTypes.SAVING_ENCOUNTER_BILLING_DATA,
		payload: true,
	};
};

export const saveBillingEncounterDetails = (data) => {
	return {
		type: actionTypes.SAVE_ENCOUNTER_BILLING_DATA,
		payload: data,
	};
};

export const saveBillingEncounterDetailsError = (err) => {
	return {
		type: actionTypes.SAVING_ECOUNTER_BILLING_DATA_ERROR,
		payload: err,
	};
};

export function saveBillingDashboardEncounter(data) {
	return () => {
		const promiseall = [];
		const promise1 = BillingService.saveEncounter(data.encounter);
		promiseall.push(promise1);
		const promise2 = BillingService.updateBillingEncounter(data.billing);
		promiseall.push(promise2);
		return Promise.all(promiseall);
	};
}

export function resetEncounterInfo() {
	return (dispatch) => {
		dispatch(billingEncounter({ data: {} }));
	};
}

export function exportBillingEncounters(data) {
	return (dispatch) => {
		dispatch(loadingExportBillingEncountersReport());
		BillingService.exportBillingReport(data)
			.then((res) => {
				dispatch(exportBillingEncountersReport({ data: res.data }));
			})
			.catch((err) => {
				dispatch(exportBillingEncountersReportError(err));
			});
	};
}

//Bulk update for billing encounters
export function billingEncounterBulkUpdate(data) {
	return (dispatch) => {
		dispatch(billingEncounterBulkUpdating());
		BillingService.billingEncounterBulkUpdate(data)
			.then((res) => {
				dispatch(billingEncounterBulkUpdated(res));
			})
			.catch((err) => {
				dispatch(billingEncounterBulkUpdatedError(err));
			});
	};
}

export function billingEncounterBulkUpdating() {
	return {
		type: actionTypes.BILLING_ENCOUNTER_BULK_UPDATING,
		payload: true,
	};
}

export function billingEncounterBulkUpdated(data) {
	return {
		type: actionTypes.BILLING_ENCOUNTER_BULK_UPDATED,
		payload: data,
	};
}

export function billingEncounterBulkUpdatedError(err) {
	return {
		type: actionTypes.BILLING_ENCOUNTER_BULK_UPDATED_ERROR,
		payload: err,
	};
}

//Patient fee refund enum
export function getPatientFeeEnum() {
	return (dispatch) => {
		dispatch(gettingPatientFeeEnum());
		BillingService.getPatientFeeEnum()
			.then((res) => {
				dispatch(
					gotPatientFeeEnum(
						get(res, 'data.data.patient_fee_refund_reason', null) ||
							[]
					)
				);
			})
			.catch((err) => {
				dispatch(gotPatientFeeEnumError(err));
			});
	};
}

export const gettingPatientFeeEnum = () => {
	return {
		type: actionTypes.PATIENT_FEE_ENUM_LOADING,
		payload: true,
	};
};

export const gotPatientFeeEnum = (data) => {
	return {
		type: actionTypes.PATIENT_FEE_ENUM_SUCCESS,
		payload: data,
	};
};

export const gotPatientFeeEnumError = (err) => {
	return {
		type: actionTypes.PATIENT_FEE_ENUM_ERROR,
		payload: err,
	};
};
