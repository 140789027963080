//React & Redux
import React, { useEffect, useState, Fragment } from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import {
	VictoryChart,
	VictoryAxis,
	VictoryBar,
	VictoryStack,
	VictoryLabel,
	VictoryTooltip,
	Flyout,
} from 'victory';

//Other Libraries
import moment from 'moment';

//Components
import FilterCard from './FilterCard';
import GraphToolTip from './GraphToolTip';

//Styles
import './encountergraphs.css';

const defaultAxes = {
	get_started_with_no_results_count: {
		sortorder: 1,
		colorstoke: '#9c5dd7',
	},
	get_started_with_ror_count: {
		sortorder: 2,
		colorstoke: '#70cbf1',
	},
	others_count: {
		sortorder: 3,
		colorstoke: '#bac3ce',
	},
};

const chartfiltercards = [
	{
		name: 'completed_encounters',
		filterkey: 'completed_visit_count',
		title: 'Total \nCompleted',
		readonly: true,
		checkedcolor: '',
	},
	{
		name: 'get_started_with_no_results',
		filterkey: 'get_started_with_no_results_count',
		title: 'Get Started \nw/o Results',
		readonly: false,
		checkedcolor: 'getstarted',
	},
	{
		name: 'get_started_with_ror',
		filterkey: 'get_started_with_ror_count',
		title: 'Return of \nResults',
		readonly: false,
		checkedcolor: 'ror',
	},
	{
		name: 'others',
		filterkey: 'others_count',
		title: 'Other',
		readonly: false,
		checkedcolor: 'other',
	},
];

const EncounterGraphs = (props) => {
	const { EncounterTotals = {} } = props;

	const [axisSelected, setAxisSelected] = useState([]);

	useEffect(() => {
		const axes = _.keysIn(defaultAxes);
		updateAxisSelected(axes);
	}, []);

	const updateAxisSelected = (axes) => {
		const sortedAxes = _.sortBy(axes, (val) => {
			return _.get(defaultAxes, [val, 'sortorder']);
		});
		setAxisSelected(sortedAxes);
	};

	const transformIntoGraphData = () => {
		const datewise_visits = _.get(EncounterTotals, 'datewise_visits', []);
		const getstarted = [];
		const ror = [];
		const other = [];
		const dateTickValues = [];
		_.map(datewise_visits, (obj) => {
			getstarted.push({
				x: obj.date,
				y: obj.get_started_with_no_results_count,
				total: obj.completed_visit_count,
			});
			ror.push({
				x: obj.date,
				y: obj.get_started_with_ror_count,
				total: obj.completed_visit_count,
			});
			other.push({
				x: obj.date,
				y: obj.others_count,
				total: obj.completed_visit_count,
			});
			dateTickValues.push(obj.date);
		});

		const chartData = [];
		const colorstoke = [];
		let max = 0;

		axisSelected.map((axisname) => {
			colorstoke.push(_.get(defaultAxes, [axisname, 'colorstoke']));
			switch (axisname) {
				case 'get_started_with_no_results_count':
					chartData.push([...getstarted]);
					break;
				case 'get_started_with_ror_count':
					chartData.push([...ror]);
					break;
				case 'others_count':
					chartData.push([...other]);
					break;
			}
		});

		if (!_.isEmpty(chartData) && _.get(chartData, 'length', 0) > 0) {
			const length = _.get(chartData, ['0', 'length'], 0);
			const axislength = _.get(chartData, ['length'], 0);

			for (let i = 0; i < length; i++) {
				let total = 0;
				for (let j = 0; j < axislength; j++) {
					const y = _.get(chartData, [j, i, 'y'], 0);
					total = total + (_.isNumber(y) ? y : 0);
				}
				max = max < total ? total : max;
			}
		}

		const ticks = makeTicks(0, max, 5);
		return { chartData, colorstoke, ticks, dateTickValues };
	};

	const makeTicks = (min, max, length) => {
		const ticks = [];
		const different = Math.ceil((max - min) / length);
		const iteration = max > length ? length : max;
		if (different > 0) {
			let total = 0;
			for (let i = 0; i <= iteration; i++) {
				ticks.push(total);
				total = total + different;
			}
		}
		return ticks;
	};

	const filterAxis = (name, checked) => {
		let updated = [...axisSelected];
		if (checked) {
			if (!updated.includes(name)) {
				updated = [...updated, name];
				updateAxisSelected(updated);
			}
		} else {
			if (updated.includes(name)) {
				updated = _.remove(updated, (val) => val !== name);
				updateAxisSelected(updated);
			}
		}
	};

	const transformedData = transformIntoGraphData();
	const data = _.get(transformedData, 'chartData', []);
	const colorStroke = _.get(transformedData, 'colorstoke', []);
	const ticksValues = _.get(transformedData, ['ticks'], []);
	const completedEncounters = _.get(
		EncounterTotals,
		['completed_encounters', 'count'],
		0
	);
	const hasData =
		_.get(data, 'length', 0) > 0 &&
		completedEncounters != 0 &&
		ticksValues.length > 0;
	const dateTickValues = _.get(transformedData, ['dateTickValues'], []);
	return !_.isEmpty(EncounterTotals) ? (
		<Fragment>
			<div className='topsection'>
				<div className='headerow'>
					<h3>Last 14 Days</h3>
				</div>
			</div>
			<div className='chartfiltersection'>
				{chartfiltercards.map((card) => {
					return (
						<FilterCard
							data={{
								title: card.title,
								total: _.get(EncounterTotals, [
									card.name,
									'count',
								]),
								change: _.get(EncounterTotals, [
									card.name,
									'change',
								]),
								filterkey: card.filterkey,
								selected: axisSelected.includes(card.filterkey),
							}}
							checkedcolor={card.checkedcolor}
							disabled={
								_.get(
									EncounterTotals,
									[card.name, 'count'],
									0
								) == 0
							}
							key={card.name}
							name={card.name}
							readonly={card.readonly}
							onChange={filterAxis}
						/>
					);
				})}
			</div>
			<div className='bottomsection'>
				<VictoryChart
					height={382}
					width={673}
					padding={{ top: 50, bottom: 50, left: 30, right: 0 }}
					domainPadding={{ x: 30, y: 20 }}
				>
					<VictoryAxis
						dependentAxis
						crossAxis={false}
						tickValues={ticksValues}
						style={{
							axis: { stroke: 'transparent' },
							grid: {
								stroke: hasData ? '#edeff3' : '',
								zIndex: '0',
							},
						}}
						tickLabelComponent={
							<VictoryLabel
								style={{
									display: !hasData ? 'none' : '',
									fontSize: 11,
									stroke: 'transparent',
									fill: '#7b8291',
									fontFamily: 'RionaSans',
									fontWeight: 'normal',
									letterSpacing: 'normal',
									padding: 10,
									WebkitFontSmoothing: 'antialiased',
								}}
							/>
						}
						tickFormat={(tick) => `${tick}`}
					/>
					<VictoryStack
						colorScale={colorStroke}
						animate={{
							easing: 'bounce',
							onExit: {
								duration: 100,
								before: (datum) => ({
									opacity: 1,
									_y: datum._y,
								}),
							},
							onEnter: {
								duration: 100,
								before: () => ({ opacity: 0.5, _y: 0 }),
								after: (datum) => ({
									opacity: 1,
									_y: datum._y,
								}),
							},
						}}
					>
						{!hasData && (
							<svg>
								<VictoryLabel
									lineHeight='1.43'
									verticalAnchor='middle'
									textAnchor='middle'
									x={'52%'}
									y={'50%'}
									text='NO DATA'
									style={{
										fontSize: '14px',
										stroke: 'transparent',
										fill: '#7b8291',
										fontFamily: 'RionaSans-Medium',
										fontWeight: '500',
										letterSpacing: 'normal',
										textAlign: 'center',
										lineHeight: '1.43',
										WebkitFontSmoothing: 'antialiased',
									}}
								/>
							</svg>
						)}
						{data.map((arr, i) => {
							return (
								<VictoryBar
									barWidth={12}
									data={arr}
									key={i}
									name={'chart-' + i}
									style={
										!hasData
											? { data: { display: 'none' } }
											: { data: { cursor: 'pointer' } }
									}
									labels={() => ''}
									labelComponent={
										<VictoryTooltip
											cornerRadius={5}
											flyoutWidth={120}
											flyoutComponent={
												<GraphToolTip
													data={data}
													axisselected={axisSelected}
												/>
											}
										/>
									}
								/>
							);
						})}
					</VictoryStack>

					<VictoryAxis
						style={{
							axis: { stroke: '#edeff3' },
						}}
						tickValues={dateTickValues}
						tickFormat={(x) => {
							const todaymoment = moment();
							const isvalidmoment = moment(x).isValid();
							const datamoment = isvalidmoment ? moment(x) : '';
							if (
								isvalidmoment &&
								todaymoment.isSame(datamoment, 'day')
							) {
								return `Today`;
							} else if (isvalidmoment) {
								let str = datamoment.format('MMM');
								str = str + '\n' + datamoment.format('D');
								return str;
							} else {
								return '';
							}
						}}
						tickLabelComponent={
							<VictoryLabel
								lineHeight={1.2}
								style={{
									fontSize: 10,
									fill: ({ index }) => {
										const todaymoment = moment();
										const date = _.get(
											dateTickValues,
											[index],
											''
										);
										const isvalidmoment =
											moment(date).isValid();
										const datamoment = isvalidmoment
											? moment(date)
											: '';
										if (
											isvalidmoment &&
											todaymoment.isSame(
												datamoment,
												'day'
											)
										) {
											return `#262837`;
										} else {
											return '#7b8291';
										}
									},
									fontFamily: ({ index }) => {
										const todaymoment = moment();
										const date = _.get(
											dateTickValues,
											[index],
											''
										);
										const isvalidmoment =
											moment(date).isValid();
										const datamoment = isvalidmoment
											? moment(date)
											: '';
										if (
											isvalidmoment &&
											todaymoment.isSame(
												datamoment,
												'day'
											)
										) {
											return `RionaSans-Bold`;
										} else {
											return 'RionaSans';
										}
									},
									stroke: 'transparent',
									//fontFamily: "RionaSans",
									letterSpacing: 'normal',
									padding: 10,
								}}
							/>
						}
					/>
				</VictoryChart>
			</div>
		</Fragment>
	) : null;
};

export default EncounterGraphs;
