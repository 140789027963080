import { gql } from '@apollo/client';
import { client } from './GraphQLConnection';
import { isNil, isEmpty } from 'lodash';

class cfDNAPrenatalScreening {
	createDiagnosticReport = (patientID, testOrderID) => {
		return client.mutate({
			mutation: gql`
				mutation {
					addDiagnosticReport(
						input: {
							subjectId: "${patientID}"
							testOrderId: "${testOrderID}"
							status: FINAL
						}
					) {
						diagnosticReport {
							id
						}
					}
				}
			`,
		});
	};

	addCFDNAPrenatalScreeningFinding = (data, diagnosticReportId) => {
		const _interpretation =
			data.fetalSex === 'GM:00000061'
				? 'GM:00000067'
				: data.fetalSex === 'GM:00000062'
				? 'GM:00000068'
				: null;

		return client.mutate({
			mutation: gql`
				mutation {
					fetalFraction: addDiagnosticFinding(
						input: {
							diagnosticReportId: "${diagnosticReportId}"
							finding: {
								observationFinding: {
									category: LABORATORY
									kind: "gm:00000065"
									value: {
										quantity: {
											value: "${data.fetalFraction}",
											unit: "%" 
										}                
									}			
								}
							}
						}
					) {
						finding {
							id
						}
					}

					${
						!isEmpty(data.fetalSex)
							? `fetalSex: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000066"
										value: { term: "${data.fetalSex}" }
										${!isNil(_interpretation) ? `interpretation: "${_interpretation}"` : ''}									
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

					${
						data.trisomy13.risk !== 'not_tested'
							? `trisomy13: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000128"
										value: { term: "${data.trisomy13.risk}" }
										components: [
											${
												!isNil(
													data.trisomy13.afterTestRisk
												)
													? `{ 
															kind: "gm:00000121", 
															value: { 
																quantity: {
																	value: "${data.trisomy13.afterTestRisk}", 
																	unit: "${data.trisomy13.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data.trisomy13.priorTestRisk
												)
													? `{ 
															kind: "gm:00000069",
															value: { 
																quantity: {
																	value: "${data.trisomy13.priorTestRisk}", 
																	unit: "${data.trisomy13.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]							
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

					${
						data.trisomy18.risk !== 'not_tested'
							? `trisomy18: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000130"
										value: { term: "${data.trisomy18.risk}" }
										components: [
											${
												!isNil(
													data.trisomy18.afterTestRisk
												)
													? `{ 
															kind: "gm:00000123", 
															value: { 
																quantity: {
																	value: "${data.trisomy18.afterTestRisk}", 
																	unit: "${data.trisomy18.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data.trisomy18.priorTestRisk
												)
													? `{ 
															kind: "gm:00000072",
															value: { 
																quantity: {
																	value: "${data.trisomy18.priorTestRisk}", 
																	unit: "${data.trisomy18.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																			
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}
					
					${
						data.trisomy21.risk !== 'not_tested'
							? `trisomy21: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000131"
										value: { term: "${data.trisomy21.risk}" }
										components: [
											${
												!isNil(
													data.trisomy21.afterTestRisk
												)
													? `{ 
															kind: "gm:00000124", 
															value: { 
																quantity: {
																	value: "${data.trisomy21.afterTestRisk}", 
																	unit: "${data.trisomy21.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data.trisomy21.priorTestRisk
												)
													? `{ 
															kind: "gm:00000073",
															value: { 
																quantity: {
																	value: "${data.trisomy21.priorTestRisk}", 
																	unit: "${data.trisomy21.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																				
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

					${
						data.trisomy22.risk !== 'not_tested'
							? `trisomy22: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000132"
										value: { term: "${data.trisomy22.risk}" }
										components: [
											${
												!isNil(
													data.trisomy22.afterTestRisk
												)
													? `{ 
															kind: "gm:00000125", 
															value: { 
																quantity: {
																	value: "${data.trisomy22.afterTestRisk}", 
																	unit: "${data.trisomy22.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data.trisomy22.priorTestRisk
												)
													? `{ 
															kind: "gm:00000074",
															value: { 
																quantity: {
																	value: "${data.trisomy22.priorTestRisk}", 
																	unit: "${data.trisomy22.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																				
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

					${
						data.trisomy16.risk !== 'not_tested'
							? `trisomy16: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000129"
										value: { term: "${data.trisomy16.risk}" }
										components: [
											${
												!isNil(
													data.trisomy16.afterTestRisk
												)
													? `{ 
															kind: "gm:00000122", 
															value: { 
																quantity: {
																	value: "${data.trisomy16.afterTestRisk}", 
																	unit: "${data.trisomy16.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data.trisomy16.priorTestRisk
												)
													? `{ 
															kind: "gm:00000071",
															value: { 
																quantity: {
																	value: "${data.trisomy16.priorTestRisk}", 
																	unit: "${data.trisomy16.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																			
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

					${
						data.triplody.risk !== 'not_tested'
							? `triplody: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000133"
										value: { term: "${data.triplody.risk}" }
										components: [
											${
												!isNil(
													data.triplody.afterTestRisk
												)
													? `{ 
															kind: "gm:00000120", 
															value: { 
																quantity: {
																	value: "${data.triplody.afterTestRisk}", 
																	unit: "${data.triplody.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data.triplody.priorTestRisk
												)
													? `{ 
															kind: "gm:00000075",
															value: { 
																quantity: {
																	value: "${data.triplody.priorTestRisk}", 
																	unit: "${data.triplody.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																			
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

					${
						data.oneP36DeletionSyndrome.risk !== 'not_tested'
							? `oneP36SyndromeMicrodeletions: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000134"
										value: { term: "${data.oneP36DeletionSyndrome.risk}" }
										components: [
											${
												!isNil(
													data.oneP36DeletionSyndrome
														.afterTestRisk
												)
													? `{ 
															kind: "gm:00000114", 
															value: { 
																quantity: {
																	value: "${data.oneP36DeletionSyndrome.afterTestRisk}", 
																	unit: "${data.oneP36DeletionSyndrome.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data.oneP36DeletionSyndrome
														.priorTestRisk
												)
													? `{ 
															kind: "gm:00000076",
															value: { 
																quantity: {
																	value: "${data.oneP36DeletionSyndrome.priorTestRisk}", 
																	unit: "${data.oneP36DeletionSyndrome.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																			
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

					${
						data.wolfHirschhornSyndrome.risk !== 'not_tested'
							? `wolfHirschhornSyndromeMicrodeletions: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000135"
										value: { term: "${data.wolfHirschhornSyndrome.risk}" }
										components: [
											${
												!isNil(
													data.wolfHirschhornSyndrome
														.afterTestRisk
												)
													? `{ 
															kind: "gm:00000116", 
															value: { 
																quantity: {
																	value: "${data.wolfHirschhornSyndrome.afterTestRisk}", 
																	unit: "${data.wolfHirschhornSyndrome.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data.wolfHirschhornSyndrome
														.priorTestRisk
												)
													? `{ 
															kind: "gm:00000077",
															value: { 
																quantity: {
																	value: "${data.wolfHirschhornSyndrome.priorTestRisk}", 
																	unit: "${data.wolfHirschhornSyndrome.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																			
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

					${
						data.criDuChatSyndrome.risk !== 'not_tested'
							? `criDuChatSyndromeMicrodeletions: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000136"
										value: { term: "${data.criDuChatSyndrome.risk}" }
										components: [
											${
												!isNil(
													data.criDuChatSyndrome
														.afterTestRisk
												)
													? `{ 
															kind: "gm:00000117", 
															value: { 
																quantity: {
																	value: "${data.criDuChatSyndrome.afterTestRisk}", 
																	unit: "${data.criDuChatSyndrome.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data.criDuChatSyndrome
														.priorTestRisk
												)
													? `{ 
															kind: "gm:00000078",
															value: { 
																quantity: {
																	value: "${data.criDuChatSyndrome.priorTestRisk}", 
																	unit: "${data.criDuChatSyndrome.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																			
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

					${
						data.eightQ24DeletionSyndrome.risk !== 'not_tested'
							? `eightQ24SyndromeMicrodeletions: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000137"
										value: { term: "${data.eightQ24DeletionSyndrome.risk}" }
										components: [
											${
												!isNil(
													data
														.eightQ24DeletionSyndrome
														.afterTestRisk
												)
													? `{ 
															kind: "gm:00000118", 
															value: { 
																quantity: {
																	value: "${data.eightQ24DeletionSyndrome.afterTestRisk}", 
																	unit: "${data.eightQ24DeletionSyndrome.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data
														.eightQ24DeletionSyndrome
														.priorTestRisk
												)
													? `{ 
															kind: "gm:00000079",
															value: { 
																quantity: {
																	value: "${data.eightQ24DeletionSyndrome.priorTestRisk}", 
																	unit: "${data.eightQ24DeletionSyndrome.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																			
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

					${
						data.jacobsenSyndrome.risk !== 'not_tested'
							? `jacobsenSyndromeMicrodeletions: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000138"
										value: { term: "${data.jacobsenSyndrome.risk}" }
										components: [
											${
												!isNil(
													data.jacobsenSyndrome
														.afterTestRisk
												)
													? `{ 
															kind: "gm:00000112", 
															value: { 
																quantity: {
																	value: "${data.jacobsenSyndrome.afterTestRisk}", 
																	unit: "${data.jacobsenSyndrome.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data.jacobsenSyndrome
														.priorTestRisk
												)
													? `{ 
															kind: "gm:00000080",
															value: { 
																quantity: {
																	value: "${data.jacobsenSyndrome.priorTestRisk}", 
																	unit: "${data.jacobsenSyndrome.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																		
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

					${
						data.angelmanPraderWilliSyndrome.risk !== 'not_tested'
							? `angelmanPraderWilliSyndromeMicrodeletions: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000139"
										value: { term: "${data.angelmanPraderWilliSyndrome.risk}" }
										components: [
											${
												!isNil(
													data
														.angelmanPraderWilliSyndrome
														.afterTestRisk
												)
													? `{ 
															kind: "gm:00000113", 
															value: { 
																quantity: {
																	value: "${data.angelmanPraderWilliSyndrome.afterTestRisk}", 
																	unit: "${data.angelmanPraderWilliSyndrome.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data
														.angelmanPraderWilliSyndrome
														.priorTestRisk
												)
													? `{ 
															kind: "gm:00000081",
															value: { 
																quantity: {
																	value: "${data.angelmanPraderWilliSyndrome.priorTestRisk}", 
																	unit: "${data.angelmanPraderWilliSyndrome.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																			
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

					${
						data.twenty2Q11DeletionSyndrome.risk !== 'not_tested'
							? `twenty2Q11SyndromeMicrodeletions: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000140"
										value: { term: "${data.twenty2Q11DeletionSyndrome.risk}" }
										components: [
											${
												!isNil(
													data
														.twenty2Q11DeletionSyndrome
														.afterTestRisk
												)
													? `{ 
															kind: "gm:00000115", 
															value: { 
																quantity: {
																	value: "${data.twenty2Q11DeletionSyndrome.afterTestRisk}", 
																	unit: "${data.twenty2Q11DeletionSyndrome.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data
														.twenty2Q11DeletionSyndrome
														.priorTestRisk
												)
													? `{ 
															kind: "gm:00000082",
															value: { 
																quantity: {
																	value: "${data.twenty2Q11DeletionSyndrome.priorTestRisk}", 
																	unit: "${data.twenty2Q11DeletionSyndrome.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																			
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

					${
						data.xSex.risk !== 'not_tested'
							? `xSexAneuploides: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000141"
										value: { term: "${data.xSex.risk}" }
										components: [
											${
												!isNil(data.xSex.afterTestRisk)
													? `{ 
															kind: "gm:00000119", 
															value: { 
																quantity: {
																	value: "${data.xSex.afterTestRisk}", 
																	unit: "${data.xSex.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(data.xSex.priorTestRisk)
													? `{ 
															kind: "gm:00000083",
															value: { 
																quantity: {
																	value: "${data.xSex.priorTestRisk}", 
																	unit: "${data.xSex.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																			
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

					${
						data.xxySex.risk !== 'not_tested'
							? `xxySexAneuploides: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000142"
										value: { term: "${data.xxySex.risk}" }
										components: [
											${
												!isNil(
													data.xxySex.afterTestRisk
												)
													? `{ 
															kind: "gm:00000127", 
															value: { 
																quantity: {
																	value: "${data.xxySex.afterTestRisk}", 
																	unit: "${data.xxySex.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data.xxySex.priorTestRisk
												)
													? `{ 
															kind: "gm:00000084",
															value: { 
																quantity: {
																	value: "${data.xxySex.priorTestRisk}", 
																	unit: "${data.xxySex.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																			
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

					${
						data.xyySex.risk !== 'not_tested'
							? `xyySexAneuploides: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000143"
										value: { term: "${data.xyySex.risk}" }
										components: [
											${
												!isNil(
													data.xyySex.afterTestRisk
												)
													? `{ 
															kind: "gm:00000111", 
															value: { 
																quantity: {
																	value: "${data.xyySex.afterTestRisk}", 
																	unit: "${data.xyySex.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data.xyySex.priorTestRisk
												)
													? `{ 
															kind: "gm:00000085",
															value: { 
																quantity: {
																	value: "${data.xyySex.priorTestRisk}", 
																	unit: "${data.xyySex.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																			
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}					

					${
						data.xxxSex.risk !== 'not_tested'
							? `xxxSexAneuploides: addDiagnosticFinding(
							input: {
								diagnosticReportId: "${diagnosticReportId}"
								finding: {
									observationFinding: {
										category: LABORATORY
										kind: "gm:00000144"
										value: { term: "${data.xxxSex.risk}" }
										components: [
											${
												!isNil(
													data.xxxSex.afterTestRisk
												)
													? `{ 
															kind: "gm:00000126", 
															value: { 
																quantity: {
																	value: "${data.xxxSex.afterTestRisk}", 
																	unit: "${data.xxxSex.afterTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
											${
												!isNil(
													data.xxxSex.priorTestRisk
												)
													? `{ 
															kind: "gm:00000086",
															value: { 
																quantity: {
																	value: "${data.xxxSex.priorTestRisk}", 
																	unit: "${data.xxxSex.priorTestRiskUnit}"
																} 
															} 
														}`
													: ''
											}
										]																			
									}
								}
							}
						) {
							finding {
								id
							}
						}`
							: ''
					}

				}
			`,
		});
	};
}

export default new cfDNAPrenatalScreening();
