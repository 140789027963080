//React & Redux
import React, { Component } from 'react';

//Other Libraries
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';

//Styles
import './PatientCard.css';

//images
import linkicon from '../../assets/quick-view-link.svg';

//Components
import ProviderInfo from './ProviderInfo';

//Utils
import { UTCToCurrentTimeView, EncounterTabs } from '../../utils';

class PatientCard extends Component {
	getTimestamp = () => {
		const _date_of_service = moment.tz(
			moment(_.get(this, ['props', 'date_of_service'], '')).format(
				'YYYY-MM-DD HH:mm:ss'
			),
			'UTC'
		);
		const date_time_zone = moment
			.tz(_date_of_service, _.get(this, ['props', 'timezone'], ''))
			.format('ddd, MMM D');

		return `${date_time_zone}, ${UTCToCurrentTimeView(
			moment,
			moment(_.get(this, ['props', 'date_of_service'], '')),
			_.get(this, ['props', 'timezone'], '')
		)}`;
	};

	navigateToEncounterDetail = () => {
		const { patient_uuid, encounter_uuid, name } = this.props;
		this.props.gotoRelatedEncounter(patient_uuid, encounter_uuid, name);
	};

	render() {
		const { type, name, state_of_visit } = this.props;
		return (
			<div className='patient-card-wrapper'>
				<div
					className={classnames(
						'patient-card',
						_.get(
							this,
							['props', 'isRelatedEncounterClickable'],
							true
						) == true
							? 'clickable'
							: ''
					)}
					onClick={() =>
						_.get(
							this,
							['props', 'isRelatedEncounterClickable'],
							true
						) && this.navigateToEncounterDetail()
					}
				>
					<span
						className={classnames(
							'type',
							type == 'primary' ? 'primary' : ''
						)}
					>
						{type == 'primary' ? 'Primary' : 'Secondary'}
					</span>
					<span className='name'>
						{name}{' '}
						{_.get(this.props, 'showLinkIcon', true) && (
							<img src={linkicon} className='link-icon' />
						)}
					</span>
					<span className='date'>
						{this.getTimestamp()} - {state_of_visit}
					</span>
					<ProviderInfo
						providerenum={_.get(
							this,
							['props', 'providerenums'],
							[]
						)}
						providerName={_.get(
							this,
							['props', 'providerName'],
							''
						)}
					/>
				</div>
			</div>
		);
	}
}

export default PatientCard;
