import React, { Component, Fragment } from 'react';

//Images
import ReactSVG from 'react-svg';
import expandCollapseIcon from './expand-collapse.svg';

// Lodash
import { get, isArray, isEmpty } from 'lodash';

// Other library
import { v4 } from 'uuid';
import classnames from 'classnames';

export default class Tr extends Component {
	uuid;

	componentDidMount() {
		const hasChild = !isEmpty(this.props.child);
		const isExpandable = get(this, ['props', 'isExpandable'], false);

		if (hasChild && isExpandable) {
			this.uuid = v4();
			this.props.registerTr(this.uuid, this.props.parent);
		}
	}

	render() {
		const children = isArray(this.props.children)
			? this.props.children
			: [this.props.children];
		const hasChild = !isEmpty(this.props.child);
		const isExpandable = get(this, ['props', 'isExpandable'], false);
		const isAllExpanded = get(this, ['props', 'isAllExpanded'], false);
		const role = get(this, ['props', 'role'], null);
		const isExpanded =
			isExpandable && hasChild && this.props.trs[this.uuid];

		if (role === 'separator') return <tr className='separator' />;

		return (
			<Fragment>
				<tr
					onClick={this.props.onClick && this.props.onClick}
					className={classnames({
						highlight: this.props.highlight,
						'on-click': !!this.props.onClick,
						'is-expanded': isExpanded,
					})}
				>
					{this.props.doNotClone === true
						? children
						: children.map((child, i) =>
								React.cloneElement(child, {
									key: `Tr-child-idx-${i}`,
									hasData: this.props.hasData,
									handleSort: this.props.handleSort,
									sortBy: this.props.sortBy,
								})
						  )}
					{isExpandable && this.props.parent === 'thead' && (
						<th
							className={classnames(
								'toggle-button',
								'has-child',
								'no-hover',
								{
									'is-expanded': isAllExpanded,
								}
							)}
						>
							<ReactSVG
								data-testId='toggle-button'
								src={expandCollapseIcon}
								onClick={() => {
									if (this.props.hasData)
										this.props.handleToggle();
								}}
							/>
						</th>
					)}
					{isExpandable && this.props.parent === 'tbody' && (
						<td
							className={classnames('toggle-button', {
								'has-child': hasChild,
								'is-expanded': this.props.trs[this.uuid],
							})}
						>
							{hasChild && (
								<ReactSVG
									src={expandCollapseIcon}
									onClick={(event) => {
										if (hasChild) {
											event.stopPropagation();
											this.props.handleToggle(this.uuid);
										}
									}}
								/>
							)}
						</td>
					)}
				</tr>
				{isExpanded && (
					<tr className='child'>
						<td colSpan='100%'>
							<div className='child-wrapper'>
								{this.props.child}
							</div>
						</td>
					</tr>
				)}
			</Fragment>
		);
	}
}
