import * as actionTypes from '../actions/actionTypes';
import { sortEncounterSchemaValues } from './Encounter';

const defaultDashboard = {
	loadingDashboard: false,
	dashboard_data: {},
	dashboardError: null,
};

const defaultUpcomingappointments = {
	loadingUpcomingappointments: false,
	upcomingappointments: [],
	upcomingappointmentsError: null,
};

const defaultExportEncounterRecords = {
	loadingExportRecords: false,
	exportRecords: null,
	exportRecordsError: null,
};

const defaultUpdateSurveyStatus = {
	loadingUpdateSurveyStatus: false,
	updateSurveyStatus: null,
	updateSurveyStatusError: null,
};

const defaultCCListState = {
	ccsLoading: false,
	ccList: [],
	ccListError: null,
	ccAssignmentsLoading: false,
	ccAssignments: null,
	ccAssignmentsError: null,
};

const defaultGCAListState = {
	gcasLoading: false,
	gcaList: [],
	gcaListError: null,
	gcaAssignmentsLoading: false,
	gcaAssignments: null,
	gcaAssignmentsError: null,
};

const defaultAssignCcGcaState = {
	assigningCCOrGCA: false,
	assignedCCOrGCA: null,
	assignedCCOrGCAError: null,
};

export const dashboard = (state = defaultDashboard, action) => {
	if (action.type === actionTypes.LOADING_DASHBOARD) {
		const newState = Object.assign({}, state);
		newState.loadingDashboard = true;
		newState.dashboard_data = {};
		newState.dashboardError = null;
		return newState;
	} else if (action.type === actionTypes.DASHBOARD) {
		const newState = Object.assign({}, state);
		newState.loadingDashboard = false;
		newState.dashboard_data = action.payload;
		newState.dashboardError = null;
		return newState;
	} else if (action.type === actionTypes.DASHBOARD_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingDashboard = false;
		newState.dashboard_data = {};
		newState.dashboardError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const dashboardReferralPrograms = (
	state = { dashboardaffiliations: [] },
	action
) => {
	if (action.type === actionTypes.DASHBOARD_REFERRAL_PROGRAMS) {
		const newState = Object.assign({}, state);
		newState.dashboardaffiliations = action.payload;
		newState.gettingDashboardAffiliations = false;
		newState.gettingDashboardAffiliationsError = null;
		return newState;
	} else if (
		action.type === actionTypes.GETTING_DASHBOARD_REFERRAL_PROGRAMS
	) {
		const newState = Object.assign({}, state);
		newState.dashboardaffiliations = null;
		newState.gettingDashboardAffiliations = true;
		newState.gettingDashboardAffiliationsError = null;
		return newState;
	} else if (
		action.type === actionTypes.GOT_DASHBOARD_REFERRAL_PROGRAMS_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.dashboardaffiliations = null;
		newState.gettingDashboardAffiliations = false;
		newState.gettingDashboardAffiliationsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const dashboardBulkUpdateStatus = (
	state = { update_status: [] },
	action
) => {
	if (action.type == actionTypes.BULK_UPDATE_STATUS) {
		const newState = Object.assign({}, state);
		newState.update_status = action.payload;
		return newState;
	} else if (action.type == actionTypes.RESET_BULK_UPDATE) {
		const newState = Object.assign({}, state);
		newState.update_status = {};
		return newState;
	} else {
		return state;
	}
};

export const dashboardencounters = (
	state = {
		dashboardencounters: [],
		orderstatusenum: {},
		orderrequeststatusenum: {},
		visitproviderenum: {},
		visitproviderenum_scheduling: {},
		labenum: {},
		claimstatus: {},
	},
	action
) => {
	if (action.type === actionTypes.GETTING_DASHBOARD_ENCOUNTERS) {
		const newState = Object.assign({}, state);
		newState.dashboardencounters = [];
		newState.dashboardencountersLoading = true;
		newState.dashboardencountersError = false;
		return newState;
	} else if (action.type === actionTypes.DASHBOARD_ENCOUNTERS) {
		const newState = Object.assign({}, state);
		newState.dashboardencounters = action.payload;
		newState.dashboardencountersLoading = false;
		newState.dashboardencountersError = null;
		return newState;
	} else if (action.type === actionTypes.DASHBOARD_ENCOUNTERS_ERROR) {
		const newState = Object.assign({}, state);
		newState.dashboardencounters = [];
		newState.dashboardencountersLoading = false;
		newState.dashboardencountersError = action.payload;
		return newState;
	}

	if (action.type == actionTypes.ORDER_STATUS_ENUM) {
		const newState = Object.assign({}, state);
		newState.orderstatusenum = action.payload;

		return newState;
	} else if (action.type == actionTypes.ORDER_REQUEST_ENUM) {
		const newState = Object.assign({}, state);
		newState.orderrequeststatusenum = action.payload;

		return newState;
	} else if (action.type === actionTypes.VISIT_PROVIDER_ENUM_LOADING) {
		const newState = Object.assign({}, state);
		newState.visitproviderenumloading = true;
		newState.specificEncounterEnumError = null;
		return newState;
	} else if (action.type == actionTypes.VISIT_PROVIDER_ENUM) {
		const newState = Object.assign({}, state);
		newState.visitproviderenumloading = false;
		newState.visitproviderenum = action.payload;
		return newState;
	} else if (
		action.type == actionTypes.VISIT_PROVIDER_ENUM_SCHEDULING_LOADING
	) {
		const newState = Object.assign({}, state);
		newState.visitproviderenumloading_scheduling = true;
		newState.specificEncounterEnumError = null;
		return newState;
	} else if (action.type == actionTypes.VISIT_PROVIDER_ENUM_SCHEDULING) {
		const newState = Object.assign({}, state);
		newState.visitproviderenumloading_scheduling = false;
		newState.visitproviderenum_scheduling =
			sortEncounterSchemaValues(action); //action.payload;
		return newState;
	} else if (action.type == actionTypes.LAB_ENUM) {
		const newState = Object.assign({}, state);
		newState.labenum = action.payload;
		return newState;
	} else if (action.type == actionTypes.ORDERING_PHYSICIAN_ENUM) {
		const newState = Object.assign({}, state);
		newState.orderingphysician = action.payload;
		return newState;
	} else if (action.type == actionTypes.CLAIM_STATUS_ENUM) {
		const newState = Object.assign({}, state);
		newState.claimstatus = action.payload;
		return newState;
	} else if (action.type == actionTypes.SPECIFIC_ENCOUNTER_ENUM_ERROR) {
		const newState = Object.assign({}, state);
		newState.visitproviderenumloading = false;
		newState.visitproviderenumloading_scheduling = false;
		newState.specificEncounterEnumError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const dashboardpatients = (
	state = {
		dashboardpatients: [],
		orderstatusenum: {},
		orderrequeststatusenum: {},
	},
	action
) => {
	if (action.type === actionTypes.GETTING_DASHBOARD_PATIENTS) {
		const newState = {
			...state,
			dashboardPatientsLoading: true,
		};
		return newState;
	}

	if (action.type === actionTypes.DASHBOARD_PATIENTS) {
		const newState = Object.assign({}, state);
		newState.dashboardpatients = action.payload;
		newState.dashboardPatientsLoading = false;
		return newState;
	}
	if (action.type == actionTypes.ORDER_STATUS_ENUM) {
		const newState = Object.assign({}, state);
		newState.orderstatusenum = action.payload;

		return newState;
	} else if (action.type == actionTypes.ORDER_REQUEST_ENUM) {
		const newState = Object.assign({}, state);
		newState.orderrequeststatusenum = action.payload;

		return newState;
	} else {
		return state;
	}
};

export const dashboardpatientsV2 = (
	state = { dashboardpatients: [], referralprogram: {} },
	action
) => {
	if (
		action.type === actionTypes.V2_DASHBOARD_REFERRAL_PROGRAMS_START
	) {
		const newState = {
			...state,
			referralprogram: null,
			referralProgramsLoading: true,
			referralProgramsError: null,
		};
		return newState;
	} else if (
		action.type === actionTypes.V2_DASHBOARD_REFERRAL_PROGRAMS_SUCCESS
	) {
		const newState = {
			...state,
			referralprogram: action.payload,
			referralProgramsLoading: false,
			referralProgramsError: null,
		};
		return newState;
	} else if (
		action.type === actionTypes.V2_DASHBOARD_REFERRAL_PROGRAMS_FAILURE
	) {
		const newState = {
			...state,
			referralprogram: null,
			referralProgramsLoading: false,
			referralProgramsError: action.payload,
		};
		return newState;
	} else if (
		action.type === actionTypes.V2_DASHBOARD_REFERRAL_PROGRAMS_RESET
	) {
		const newState = {
			...state,
			referralprogram: {},
		};
		return newState;
	} else {
		return state;
	}
};

export const upcomingappointments = (
	state = defaultUpcomingappointments,
	action
) => {
	if (action.type === actionTypes.LOADING_UPCOMING_APPOINTMENTS) {
		const newState = Object.assign({}, state);
		newState.loadingUpcomingappointments = true;
		newState.upcomingappointments = [];
		newState.upcomingappointmentsError = null;
		return newState;
	} else if (action.type === actionTypes.UPCOMING_APPOINTMENTS) {
		const newState = Object.assign({}, state);
		newState.loadingUpcomingappointments = false;
		newState.upcomingappointments = action.payload;
		newState.upcomingappointmentsError = null;
		return newState;
	} else if (action.type === actionTypes.UPCOMING_APPOINTMENTS_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingUpcomingappointments = false;
		newState.upcomingappointments = [];
		newState.upcomingappointmentsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const assignCcGca = (state = defaultAssignCcGcaState, action) => {
	switch (action.type) {
		case actionTypes.CC_GCA_ASSIGNMENT_START: {
			const newState = Object.assign({}, state);
			newState.assigningCCOrGCA = true;
			newState.assignedCCOrGCA = null;
			newState.assignedCCOrGCAError = null;
			return newState;
		}

		case actionTypes.CC_GCA_ASSIGNMENT_SUCCESS: {
			const newState = Object.assign({}, state);
			newState.assigningCCOrGCA = false;
			newState.assignedCCOrGCA = action.payload;
			newState.assignedCCOrGCAError = null;
			return newState;
		}

		case actionTypes.CC_GCA_ASSIGNMENT_ERROR: {
			const newState = Object.assign({}, state);
			newState.assigningCCOrGCA = false;
			newState.assignedCCOrGCA = null;
			newState.assignedCCOrGCAError = action.payload;
			return newState;
		}

		default:
			return state;
	}
};

export const ccList = (state = defaultCCListState, action) => {
	switch (action.type) {
		case actionTypes.GETTING_ACTIVE_CCS: {
			const newState = Object.assign({}, state);
			newState.ccsLoading = true;
			newState.ccList = [];
			newState.ccListError = null;
			return newState;
		}

		case actionTypes.GOT_ACTIVE_CCS: {
			const newState = Object.assign({}, state);
			newState.ccsLoading = false;
			newState.ccList = action.payload;
			newState.ccListError = null;
			return newState;
		}

		case actionTypes.GOT_ACTIVE_CCS_ERROR: {
			const newState = Object.assign({}, state);
			newState.ccsLoading = false;
			newState.ccList = [];
			newState.ccListError = action.payload;
			return newState;
		}

		case actionTypes.GETTING_CC_ASSIGNMENTS: {
			const newState = Object.assign({}, state);
			newState.ccAssignmentsLoading = true;
			newState.ccAssignments = null;
			newState.ccAssignmentsError = action.payload;
			return newState;
		}

		case actionTypes.GOT_CC_ASSIGNMENTS: {
			const newState = Object.assign({}, state);
			newState.ccAssignmentsLoading = false;
			newState.ccAssignments = action.payload;
			newState.ccAssignmentsError = null;
			return newState;
		}

		case actionTypes.GOT_CC_ASSIGNMENTS_ERROR: {
			const newState = Object.assign({}, state);
			newState.ccAssignmentsLoading = false;
			newState.ccAssignments = null;
			newState.ccAssignmentsError = action.payload;
			return newState;
		}

		default:
			return state;
	}
};

export const gcaList = (state = defaultGCAListState, action) => {
	switch (action.type) {
		case actionTypes.GETTING_ACTIVE_GCAS: {
			const newState = Object.assign({}, state);
			newState.gcasLoading = true;
			newState.gcaList = [];
			newState.gcaListError = null;
			return newState;
		}

		case actionTypes.GOT_ACTIVE_GCAS: {
			const newState = Object.assign({}, state);
			newState.gcasLoading = false;
			newState.gcaList = action.payload;
			newState.gcaListError = null;
			return newState;
		}

		case actionTypes.GOT_ACTIVE_GCAS_ERROR: {
			const newState = Object.assign({}, state);
			newState.gcasLoading = false;
			newState.gcaList = [];
			newState.gcaListError = action.payload;
			return newState;
		}

		case actionTypes.GETTING_GCA_ASSIGNMENTS: {
			const newState = Object.assign({}, state);
			newState.gcaAssignmentsLoading = true;
			newState.gcaAssignments = null;
			newState.gcaAssignmentsError = action.payload;
			return newState;
		}

		case actionTypes.GOT_GCA_ASSIGNMENTS: {
			const newState = Object.assign({}, state);
			newState.gcaAssignmentsLoading = false;
			newState.gcaAssignments = action.payload;
			newState.gcaAssignmentsError = null;
			return newState;
		}

		case actionTypes.GOT_GCA_ASSIGNMENTS_ERROR: {
			const newState = Object.assign({}, state);
			newState.gcaAssignmentsLoading = false;
			newState.gcaAssignments = null;
			newState.gcaAssignmentsError = action.payload;
			return newState;
		}

		default:
			return state;
	}
};

export const dashboardroroutreach = (
	state = { dashboardroroutreach: [], referralprogram: {} },
	action
) => {
	if (action.type === actionTypes.DASHBOARD_ROR_OUTREACH_PENDING_START) {
		const newState = {
			...state,
			dashboardroroutreachLoading: true,
		};
		return newState;
	} else if (
		action.type === actionTypes.DASHBOARD_ROR_OUTREACH_PENDING_SUCCESS
	) {
		const newState = Object.assign({}, state);
		newState.dashboardroroutreach = action.payload;
		newState.dashboardroroutreachLoading = false;
		return newState;
	} else if (
		action.type === actionTypes.DASHBOARD_ROR_OUTREACH_PENDING_FAILURE
	) {
		const newState = {
			...state,
			dashboardroroutreachLoading: false,
			dashboardroroutreachError: action.payload,
		};
		return newState;
	} else if (
		action.type === actionTypes.DASHBOARD_ROR_OUTREACH_PENDING_RESET
	) {
		const newState = Object.assign({}, state);
		newState.dashboardroroutreach = [];
		newState.dashboardroroutreachLoading = false;
		return newState;
	} else if (
		action.type === actionTypes.V2_DASHBOARD_REFERRAL_PROGRAMS_START
	) {
		const newState = {
			...state,
			referralprogram: null,
			referralProgramsLoading: true,
			referralProgramsError: null,
		};
		return newState;
	} else if (
		action.type === actionTypes.V2_DASHBOARD_REFERRAL_PROGRAMS_SUCCESS
	) {
		const newState = {
			...state,
			referralprogram: action.payload,
			referralProgramsLoading: false,
			referralProgramsError: null,
		};
		return newState;
	} else if (
		action.type === actionTypes.V2_DASHBOARD_REFERRAL_PROGRAMS_FAILURE
	) {
		const newState = {
			...state,
			referralprogram: null,
			referralProgramsLoading: false,
			referralProgramsError: action.payload,
		};
		return newState;
	} else if (
		action.type === actionTypes.V2_DASHBOARD_REFERRAL_PROGRAMS_RESET
	) {
		const newState = {
			...state,
			referralprogram: {},
		};
		return newState;
	} else {
		return state;
	}
};

export const exportEncounterRecord = (
	state = defaultExportEncounterRecords,
	action
) => {
	if (action.type === actionTypes.LOADING_EXPORT_ENCOUNTER_RECORDS) {
		const newState = Object.assign({}, state);
		newState.loadingExportRecords = true;
		newState.exportRecords = null;
		newState.exportRecordsError = null;
		return newState;
	} else if (action.type === actionTypes.EXPORT_ENCOUNTER_RECORDS) {
		const newState = Object.assign({}, state);
		newState.loadingExportRecords = false;
		newState.exportRecords = action.payload;
		newState.exportRecordsError = null;
		return newState;
	} else if (action.type === actionTypes.EXPORT_ENCOUNTER_RECORDS_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingExportRecords = false;
		newState.exportRecords = null;
		newState.exportRecordsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const updateSurveyStatus = (
	state = defaultUpdateSurveyStatus,
	action
) => {
	if (action.type === actionTypes.LOADING_EXPORT_ENCOUNTER_RECORDS) {
		const newState = Object.assign({}, state);
		newState.loadingUpdateSurveyStatus = true;
		newState.updateSurveyStatus = null;
		newState.updateSurveyStatusError = null;
		return newState;
	} else if (action.type === actionTypes.EXPORT_ENCOUNTER_RECORDS) {
		const newState = Object.assign({}, state);
		newState.loadingUpdateSurveyStatus = false;
		newState.updateSurveyStatus = action.payload;
		newState.updateSurveyStatusError = null;
		return newState;
	} else if (action.type === actionTypes.EXPORT_ENCOUNTER_RECORDS_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingUpdateSurveyStatus = false;
		newState.updateSurveyStatus = null;
		newState.updateSurveyStatusError = action.payload;
		return newState;
	} else {
		return state;
	}
};
