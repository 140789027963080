import { gql } from '@apollo/client';

import { client } from './GraphQLConnection';

class NucleotideRepeatVariant {
	createDiagnosticReport = (patientID, testOrderID) => {
		return client.mutate({
			mutation: gql`
				mutation {
					addDiagnosticReport(
						input: {
							subjectId: "${patientID}"
							testOrderId: "${testOrderID}"
							status: FINAL
						}
					) {
						diagnosticReport {
							id
						}
					}
				}
			`,
		});
	};

	addNucleotideRepeatVariantFinding = (data, diagnosticReportId) => {
		return client.mutate({
			mutation: gql`
				mutation {
					addDiagnosticFinding(
						input: {
							diagnosticReportId: "${diagnosticReportId}" 
							finding: {
								nucleotideRepeatVariantFinding: {
									geneId: "${data.geneId}"
									${
										data.referenceSequence
											? `referenceSequence: "${data.referenceSequence}"`
											: ''
									}
									${
										data.referenceAssemblyCode
											? `referenceAssemblyCode: "${data.referenceAssemblyCode}"`
											: ''
									}
									allele1: {
										originalReportedClassification: ${data.originalReportedClassificationAllele1}
										${
											data.characterizationAllele1
												? `characterization: ${data.characterizationAllele1}`
												: ''
										}
										${
											data.alleleRpeats1.value
												? `repeats: {
													value: "${data.alleleRpeats1.value}"
													unit: "{repeats}"
													${
														data.alleleRpeats1
															.comparator
															? `comparator: ${data.alleleRpeats1.comparator}`
															: ''
													} 
												}`
												: ''
										}																				
									}
									allele2: {
										${
											data.originalReportedClassificationAllele2
												? `originalReportedClassification: ${data.originalReportedClassificationAllele2}`
												: ''
										}
										${
											data.characterizationAllele2
												? `characterization: ${data.characterizationAllele2}`
												: ''
										}
										${
											data.alleleRpeats2.value
												? `repeats: {
													value: "${data.alleleRpeats2.value}"
													unit: "{repeats}"
													${
														data.alleleRpeats2
															.comparator
															? `comparator: ${data.alleleRpeats2.comparator}`
															: ''
													}
												}`
												: ''
										}
									}
									${
										data.dnaChangeType
											? `hgvsDNAChange: "${data.dnaChangeType}"`
											: ''
									}                                                                      
									genomicSourceClass: ${
										data.genomicSourceClass
									}																                                     
									assessment: ${data.assessment}
								}
							}
						}
					) {
						finding {
							... on NucleotideRepeatVariantFinding {
								id
								assessment
								gene {
									id
									symbol
									name
									aliasSymbols
									cytogeneticLocation
								}
								allele1 {
									repeats {
										comparator
										value
										unit
									}
									characterization
									origin
									originalReportedInterpretation {
										interpretedOn
										classification
										source {
											id
											name
											__typename
										}
									}
									mostRecentInterpretation {
										interpretedOn
										classification
										source {
											id
											name
											__typename
										}
									}
									interpretations {
										interpretedOn
										classification
										source {
											id
											name
											__typename
										}
									}
								}
								allele2 {
									repeats {
										comparator
										value
										unit
									}
									characterization
									origin
									originalReportedInterpretation {
										interpretedOn
										classification
										source {
											id
											name
											__typename
										}
									}
									mostRecentInterpretation {
										interpretedOn
										classification
										source {
											id
											name
											__typename
										}
									}
									interpretations {
										interpretedOn
										classification
										source {
											id
											name
											__typename
										}
									}
								}
								referenceAssembly {
									code
									name
									description
								}
								hgvsDNAChange
								referenceSequence
								genomicSourceClass
								assertedAt
								assertedBy {
									id
									name
									__typename
								}
							}
						}
					}
				}
			`,
		});
	};

	updateNucleotideRepeatVariantFinding = (data = {}, findingId = null) => {
		return client.mutate({
			mutation: gql`				
				mutation {
					updateDiagnosticFinding(
						input: {
							nucleotideRepeatVariantFinding: {
								id: "${findingId}"
								geneId: "${data.geneId}"
								${
									data.referenceSequence
										? `referenceSequence: "${data.referenceSequence}"`
										: ''
								}
								${
									data.referenceAssemblyCode
										? `referenceAssemblyCode: "${data.referenceAssemblyCode}"`
										: ''
								}
								allele1: {
									originalReportedClassification: ${data.originalReportedClassificationAllele1}
									${
										data.characterizationAllele1
											? `characterization: ${data.characterizationAllele1}`
											: ''
									}
									${
										data.alleleRpeats1.value
											? `repeats: {
												value: "${data.alleleRpeats1.value}"
												unit: "{repeats}"
												${
													data.alleleRpeats1
														.comparator
														? `comparator: ${data.alleleRpeats1.comparator}`
														: ''
												} 
											}`
											: ''
									}																				
								}
								allele2: {
									${
										data.originalReportedClassificationAllele2
											? `originalReportedClassification: ${data.originalReportedClassificationAllele2}`
											: ''
									}
									${
										data.characterizationAllele2
											? `characterization: ${data.characterizationAllele2}`
											: ''
									}
									${
										data.alleleRpeats2.value
											? `repeats: {
												value: "${data.alleleRpeats2.value}"
												unit: "{repeats}"
												${
													data.alleleRpeats2
														.comparator
														? `comparator: ${data.alleleRpeats2.comparator}`
														: ''
												}
											}`
											: ''
									}
								}
								${
									data.dnaChangeType
										? `hgvsDNAChange: "${data.dnaChangeType}"`
										: ''
								}                                                                      
								genomicSourceClass: ${
									data.genomicSourceClass
								}																                                     
								assessment: ${data.assessment}
							}
						}
					) {
						finding {
							id
							... on NucleotideRepeatVariantFinding {
								id
								assessment
								gene {
									id
									symbol
									name
									aliasSymbols
									cytogeneticLocation
								}
								allele1 {
									repeats {
										comparator
										value
										unit
									}
									characterization
									origin
									originalReportedInterpretation {
										interpretedOn
										classification
										source {
											id
											name
											__typename
										}
									}
									mostRecentInterpretation {
										interpretedOn
										classification
										source {
											id
											name
											__typename
										}
									}
									interpretations {
										interpretedOn
										classification
										source {
											id
											name
											__typename
										}
									}
								}
								allele2 {
									repeats {
										comparator
										value
										unit
									}
									characterization
									origin
									originalReportedInterpretation {
										interpretedOn
										classification
										source {
											id
											name
											__typename
										}
									}
									mostRecentInterpretation {
										interpretedOn
										classification
										source {
											id
											name
											__typename
										}
									}
									interpretations {
										interpretedOn
										classification
										source {
											id
											name
											__typename
										}
									}
								}
								referenceAssembly {
									code
									name
									description
								}
								hgvsDNAChange
								referenceSequence
								genomicSourceClass
								assertedAt
								assertedBy {
									id
									name
									__typename
								}
							}
						}
					}
				}
			`,
		});
	};
}

export default new NucleotideRepeatVariant();
