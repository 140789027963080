//React & Redux
import React from 'react';
import { connect } from 'react-redux';

//Lodash
import _ from 'lodash';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

//Utils
import {
	ROLE_COUNSELOR,
	ROLE_CARECOORDINATOR,
	ROLE_GCA,
} from '../../Utils/permissionUtils';

//Actions & Services
import {
	scpTestSearch,
	editencounter,
	testBulkUpdate,
	updateTestOrder,
	getGeneTestOrderStatusEnum,
	updateTestOrderRedFlag,
	getEncounterDocuments,
} from './../../actions/encounter.js';
import { getSexAssignedAtBirth } from './../../actions/patients';
import { setFilter } from '../../actions/appData';
import { getTestAuthEnums } from './../../actions/tests.js';
import {
	loadInlineDocumentDetail,
	documentAction,
} from '../../actions/documents';

//Components
import TestAuthFilterActionRow from './TestAuthFilterActionRow.js';
import TestAuthTable from './TestAuthTable.js';
import RejectTestRequestModal from './../Patients/V2/Encounters/RejectTestRequestModal/RejectTestRequestModal';
import UpdateFlagForTestRequestModal from './UpdateFlagForTestRequestModal';
import RedFlagAuditTrailDataModal from './RedFlagAuditTrailDataModal';
import DocumentsListModal from './DocumentsListModal';
import DocumentInlineView from '../Patients/V2/Documents/DocumentInlineView';

//Styles
import './test-authorization.css';

const resultsStatus = [
	{ display_name: 'Ready for MD Auth', key: 'ready_md_auth' },
	// {display_name: "Flagged for GC Review", key: "flagged_gc_review"} //removed for MVP
];

const cases = [
	{ display_name: 'All Cases', key: 'all' },
	{ display_name: 'My Licensed State(s)', key: 'licensed_states' },
];

const TABLELIMITMAX = 50;

class TestAuthorization extends React.Component {
	constructor(props) {
		super(props);

		const activerole = _.get(
			this,
			['props', 'me', 'activerole', 'name'],
			''
		);
		const IsCounselor = activerole == ROLE_COUNSELOR || ROLE_GCA;
		const IsCoordinator = activerole == ROLE_CARECOORDINATOR;
		const isGCOrCC = IsCounselor || IsCoordinator;
		const testAuthorizationFilter =
			get(this, 'props.testAuthorizationFilter', null) || {};
		this.defaultFilter = {
			searchQuery: '',
			resultsStatusFilter: 'requested',
			testsFilter: [],
			referralPrograms: [],
			cases: true,
			sortedField: 'date_test_requested',
			sortDirection: 'desc',
			limit: 50,
			offset: 0,
			isGCOrCC: isGCOrCC,
			patientShippingState: [],
			redFlagFilter: [],
		};
		this.state = {
			selectedTests: [],
			headerChecked: false,
			showRejectTestModal: false,
			rejectTestRowData: {},
			snackbarDataSingle: null,
			showUpdateFlagStatusModal: false,
			updateFlagStatusModalData: {},
			showRedFlagAuditTrailModal: false,
			redFlagAuditTrailData: {},
			showEncounterDocumentsList: false,
			showDocument: false,
			selectedTest: {},
			selectedDoc: {},
			...this.defaultFilter,
			...testAuthorizationFilter,
		};
		//bindings
		this.checkTableLimitMax = this.checkTableLimitMax.bind(this);
		this.determineItemsSelected = this.determineItemsSelected.bind(this);
		this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this);
		this.getFilter = this.getFilter.bind(this);
		this.updateFilters = this.updateFilters.bind(this);
		this.handleSort = this.handleSort.bind(this);
		this.changeOffset = this.changeOffset.bind(this);
		this.handleItemToggle = this.handleItemToggle.bind(this);
		this.toggleItemOn = this.toggleItemOn.bind(this);
		this.toggleItemOff = this.toggleItemOff.bind(this);
		this.handleAllToggle = this.handleAllToggle.bind(this);
		this.toggleAllOn = this.toggleAllOn.bind(this);
		this.toggleAllOff = this.toggleAllOff.bind(this);
		this.redirectToEncounter = this.redirectToEncounter.bind(this);
		this.approveTest = this.approveTest.bind(this);
		this.rejectTest = this.rejectTest.bind(this);
		this.approveOrRejectSingleTest =
			this.approveOrRejectSingleTest.bind(this);
		this.approveBulk = this.approveBulk.bind(this);
		this.makeSearchRequest = _.debounce(this.makeSearchRequest, 150);
		this.hideRejectTestModal = this.hideRejectTestModal.bind(this);
		this.updateTestOrder = this.updateTestOrder.bind(this);
		this.handleResultsPerPageChange =
			this.handleResultsPerPageChange.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.resetSnackbarData = this.resetSnackbarData.bind(this);
		this.resetFilters = this.resetFilters.bind(this);
		this.showUpdateFlagStatusModalFunc =
			this.showUpdateFlagStatusModalFunc.bind(this);
		this.hideUpdateFlagForTestRequestModal =
			this.hideUpdateFlagForTestRequestModal.bind(this);
		this.removeTestOrderRedFlag = this.removeTestOrderRedFlag.bind(this);
		this.showRedFlagAuditTrailModalFunc =
			this.showRedFlagAuditTrailModalFunc.bind(this);
		this.hideRedFlagAuditTrailModal =
			this.hideRedFlagAuditTrailModal.bind(this);
	}
	//functions

	async componentDidMount() {
		await this.checkTableLimitMax();
		this.props.getGeneTestOrderStatusEnum();
		this.makeSearchRequest(true);
		this.props.getSexAssignedAtBirthEnum();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			get(prevProps, 'testAuthSearchLoading', false) &&
			!get(this, 'props.testAuthSearchLoading', false)
		) {
			if (get(this, 'props.testAuthSearchError', false)) {
				this.props.enqueueSnackbar(
					'Error in searching for genetic tests',
					{
						variant: 'error',
						className: 'export_notification',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
			} else {
				this.props.setTestAuthorizationFilter(this.getFilter());
			}
		}

		if (
			get(prevProps, 'gettingGeneticTests', false) &&
			!get(this, 'props.gettingGeneticTests', false)
		) {
			if (get(this, 'props.geneticTestsError', false)) {
				this.props.enqueueSnackbar(
					'Error in searching for genetic tests',
					{
						variant: 'error',
						className: 'export_notification',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
			}
		}

		const snackbarDataSingle = get(this, 'state.snackbarDataSingle', null);
		if (
			(get(prevProps, 'testOrderUpdating', false) &&
				!get(this, 'props.testOrderUpdating', false)) ||
			(snackbarDataSingle &&
				get(prevProps, 'testBulkUpdating', false) &&
				!get(this, 'props.testBulkUpdating', false))
		) {
			if (
				get(this, 'props.testOrderUpdatedError', false) ||
				get(this, 'props.testBulkUpdatedError', false)
			) {
				this.props.enqueueSnackbar(
					`User ${_.get(
						this,
						['props', 'me', 'user', 'email'],
						''
					)} is not licensed for this state`,
					{
						variant: 'error',
						className: 'export_notification',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
			} else {
				const snackbarData = snackbarDataSingle || {};
				const patientName = get(snackbarData, 'patientName');
				const testName = get(snackbarData, 'testName');
				const action = get(snackbarData, 'action');
				this.hideRejectTestModal();
				this.props.enqueueSnackbar(
					`${patientName}'s ${testName} test request has been ${action}`,
					{
						variant: action === 'approved' ? 'success' : 'error',
						className: 'export_notification',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
				this.makeSearchRequest();
			}
			this.resetSnackbarData();
		}

		if (
			get(prevProps, 'testBulkUpdating', false) &&
			!get(this, 'props.testBulkUpdating', false) &&
			!snackbarDataSingle
		) {
			if (get(this, 'props.testBulkUpdatedError', false)) {
				this.props.enqueueSnackbar(
					'Error in bulk updating genetic tests',
					{
						variant: 'error',
						className: 'export_notification',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
			} else {
				const testRequestsCount = get(
					this,
					'state.selectedTests.length',
					0
				);
				const message =
					testRequestsCount === 1
						? 'test request has been approved'
						: 'test requests have been approved';
				this.props.enqueueSnackbar(`${testRequestsCount} ${message}`, {
					variant: 'success',
					className: 'export_notification',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				this.makeSearchRequest();
			}
		}

		if (
			get(prevProps, 'referralProgramsLoading', false) &&
			!get(this, 'props.referralProgramsLoading', false)
		) {
			if (get(this, 'props.referralProgramsError', false)) {
				this.props.enqueueSnackbar(
					'Error in searching for referral programs',
					{
						variant: 'error',
						className: 'export_notification',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
			}
		}

		if (
			get(prevProps, 'testOrderRedFlagUpdating', false) &&
			!get(this, 'props.testOrderRedFlagUpdating', false)
		) {
			if (get(this, 'props.testOrderRedFlagUpdatedError', false)) {
				this.props.enqueueSnackbar(
					'Error while changing from red flag to green flag',
					{
						variant: 'error',
						className: 'export_notification',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
			} else {
				this.hideUpdateFlagForTestRequestModal();
				this.props.enqueueSnackbar('Flag Status updated successfully', {
					variant: 'success',
					className: 'export_notification',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				this.makeSearchRequest();
			}
		}
	}

	checkTableLimitMax() {
		return new Promise((resolve, reject) => {
			const currentLimit = get(this, 'state.limit', null) || 50;
			if (currentLimit > TABLELIMITMAX) {
				this.setState({ limit: 50 }, resolve);
			} else {
				resolve();
			}
		});
	}

	determineItemsSelected() {
		return get(this, 'state.selectedTests.length', 0) > 0;
	}

	handleSearchQueryChange(e) {
		const newStr = get(e, 'target.value', '');
		if (newStr || newStr === '') {
			this.setState({ searchQuery: newStr }, this.makeSearchRequest);
		}
	}

	getFilter() {
		return {
			searchQuery: get(this, 'state.searchQuery', ''),
			resultsStatusFilter: get(
				this,
				'state.resultsStatusFilter',
				'requested'
			),
			testsFilter: get(this, 'state.testsFilter', []),
			referralPrograms: get(this, 'state.referralPrograms', []),
			cases: get(this, 'state.cases', true),
			sortedField: get(this, 'state.sortedField', 'date_test_requested'),
			sortDirection: 'desc',
			limit: get(this, 'state.limit', 50),
			offset: get(this, 'state.offset', 0),
			patientShippingState: get(this, 'state.patientShippingState', []),
			redFlagFilter: get(this, 'state.redFlagFilter', []),
		};
	}

	updateFilters(header, value) {
		const newState = {};
		if (header === 'cases') {
			newState.patientShippingState = [];
			newState.referralPrograms = [];
			newState.redFlagFilter = [];
			newState.testsFilter = [];
		}
		if (header && get(this, `state.${header}`, null) !== value) {
			newState[header] = value;
		}
		this.setState(newState, this.makeSearchRequest);
	}

	handleSort(header, order_type = 'desc') {
		if (header) {
			this.setState(
				{
					sortedField: header,
					sortDirection: order_type,
				},
				this.makeSearchRequest
			);
		}
	}

	changeOffset(e, offset) {
		if (offset || offset === 0) {
			this.setState(
				{
					offset: offset,
				},
				() => this.makeSearchRequest(true)
			);
		}
	}

	handleResultsPerPageChange(limit) {
		this.setState(
			{
				offset: 0,
				limit,
			},
			this.makeSearchRequest
		);
	}

	handlePageChange(e, offset) {
		this.setState(
			{
				offset,
			},
			() => this.makeSearchRequest(true)
		);
	}

	handleItemToggle(e, uuid) {
		if (e) {
			this.toggleItemOn(uuid);
		} else {
			this.toggleItemOff(uuid);
		}
	}

	toggleItemOn(uuid) {
		if (uuid) {
			const selectedTests = get(this, 'state.selectedTests', []) || [];
			selectedTests.push(uuid);
			this.setState({ selectedTests });
		}
	}

	toggleItemOff(uuid) {
		if (uuid) {
			const selectedTests = get(this, 'state.selectedTests', []) || [];
			_.remove(selectedTests, (el) => el === uuid);
			this.setState({ selectedTests });
		}
	}

	handleAllToggle(e) {
		if (this.determineItemsSelected()) {
			this.toggleAllOff();
		} else {
			this.toggleAllOn();
		}
	}

	toggleAllOn() {
		const allTests = get(this, 'props.testAuthData.data', []) || [];
		const selectedTests = get(this, 'state.selectedTests', []) || [];
		allTests.forEach((el) => {
			const uuid = get(el, 'order.uuid', null);
			if (
				!selectedTests.includes(uuid) &&
				get(el, ['can_change_order'], true)
			) {
				selectedTests.push(uuid);
			}
		});
		this.setState({ selectedTests });
	}

	toggleAllOff() {
		this.setState({ selectedTests: [] });
	}

	redirectToEncounter(selectedPatient, patientUuid, encounterUuid) {
		const pathName = `/app/patientdetail/${patientUuid}/0/2/${encounterUuid}`;
		(get(this, 'props.history', []) || []).push({
			pathname: pathName,
			state: {
				breadcrumbs: [
					{
						location: 'Test Authorization',
						url: '/app/testauthorization',
						searchData: {
							searchQuery: get(this, 'state.searchQuery', ''),
							resultsStatusFilter: get(
								this,
								'state.resultsStatusFilter',
								'requested'
							),
							testsFilter: get(this, 'state.testsFilter', []),
							referralPrograms: get(
								this,
								'state.referralPrograms',
								[]
							),
							cases: get(this, 'state.cases', true),
							sortedField: get(
								this,
								'state.sortedField',
								'date_test_requested'
							),
							sortDirection: 'desc',
							limit: get(this, 'state.limit', 50),
							offset: get(this, 'state.offset', 0),
							patientShippingState: get(
								this,
								'state.patientShippingState',
								[]
							),
							redFlagFilter: get(this, 'state.redFlagFilter', []),
						},
					},
					{
						location: 'Patient Detail',
						patientFullName: `${
							get(selectedPatient, 'first_name', '--') || '--'
						} ${get(selectedPatient, 'last_name', '--') || '--'}`,
					},
				],
			},
		});
	}

	approveTest(uuid, test) {
		this.approveOrRejectSingleTest(uuid, 'approved', null, test);
	}

	rejectTest(uuid, test) {
		const rejectTestRowData = {
			patientDetail: {
				dob: _.get(test, ['patient', 'dob'], null),
				first_name: _.get(test, ['patient', 'first_name'], ''),
				last_name: _.get(test, ['patient', 'last_name'], ''),
				email: _.get(test, ['patient', 'email'], '--'),
				gender: _.get(test, ['patient', 'gender'], ''),
				referral_program: _.get(
					test,
					['patient', 'referral_program'],
					'--'
				),
			},
			testOrder: {
				testname: _.get(test, ['test_name'], ''),
				lab: _.get(test, ['lab', 'display_name'], ''),
				test_order_uuid: _.get(test, 'order.uuid', null),
			},
		};

		this.setState({
			showRejectTestModal: true,
			rejectTestRowData,
		});
	}

	hideRejectTestModal = () => {
		this.setState({
			showRejectTestModal: false,
			rejectTestRowData: {},
		});
	};

	updateTestOrder = (testOrderUUID, data) => {
		return this.props.updateTestOrder(testOrderUUID, data);
	};

	approveBulk(singleUuid, action, extraPayload, singleTestAction) {
		if (
			get(this, 'state.selectedTests.length', 0) ||
			(singleUuid && action)
		) {
			let payload = {
				order_status: action || 'approved',
				uuid:
					singleUuid && typeof singleUuid === 'string'
						? [singleUuid]
						: get(this, 'state.selectedTests', []) || [],
				date_test_authorized: moment
					.utc()
					.format('YYYY-MM-DD HH:mm:ss'),
			};
			const isMDAuth = true;
			if (action === 'rejected') payload.status_reason = 'rejected_other';
			if (extraPayload) payload = { ...payload, ...extraPayload };
			this.props.testBulkUpdate(payload, isMDAuth);
		}
	}

	approveOrRejectSingleTest(uuid, action, extraPayload, test) {
		const payload = {};
		if (uuid && action) {
			this.approveBulk(uuid, action, extraPayload, true);
			this.setState({
				snackbarDataSingle: {
					action,
					patientName: `${
						get(test, 'patient.first_name', null) || '--'
					} ${get(test, 'patient.last_name', null) || '--'}`,
					testName: get(test, 'test_name', null) || '--',
				},
			});
		}
	}

	removeTestOrderRedFlag(test_order_uuid, data) {
		this.props.updateTestOrderRedFlag(test_order_uuid, data);
	}

	makeSearchRequest(offsetOverride) {
		const enumsPayload = {
			cases: get(this, ['state', 'cases'], true)
				? 'my_licensed_states'
				: 'all',
			order_status: get(
				this,
				['state', 'resultsStatusFilter'],
				'requested'
			),
			referral_program: get(this, ['state', 'referralPrograms'], []),
			tests: get(this, ['state', 'testsFilter'], []),
			patient_states: get(this, 'state.patientShippingState', []),
			flag_types: get(this, 'state.redFlagFilter', []),
		};
		if (isEmpty(enumsPayload.referral_program))
			delete enumsPayload.referral_program;
		if (isEmpty(enumsPayload.tests)) delete enumsPayload.tests;
		this.props.getTestAuthEnums(enumsPayload);

		const listPayload = {
			cases: get(this, ['state', 'cases'], true)
				? 'my_licensed_states'
				: 'all',
			limit: get(this, ['state', 'limit'], 50),
			offset: !offsetOverride ? 0 : get(this, ['state', 'offset'], 0),
			order_by: get(
				this,
				['state', 'sortedField'],
				'date_test_requested'
			),
			order_status: get(
				this,
				['state', 'resultsStatusFilter'],
				'requested'
			),
			order_type: get(this, ['state', 'sortDirection'], 'desc'),
			patient_name: get(this, ['state', 'searchQuery'], ''),
			referral_program: get(this, ['state', 'referralPrograms'], []),
			tests: get(this, 'state.testsFilter', []),
			patient_states: get(this, 'state.patientShippingState', []),
			flag_types: get(this, 'state.redFlagFilter', []),
		};
		if (isEmpty(listPayload.patient_name)) delete listPayload.patient_name;
		if (isEmpty(listPayload.referral_program))
			delete listPayload.referral_program;
		if (isEmpty(listPayload.tests)) delete listPayload.tests;
		this.props.testAuthSearch(listPayload);

		this.toggleAllOff();
		if (!offsetOverride) this.setState({ offset: 0 });
	}

	resetSnackbarData() {
		this.setState({ snackbarDataSingle: null });
	}

	resetFilters() {
		this.setState(
			get(this, 'defaultFilter', null) || {},
			this.makeSearchRequest
		);
	}

	showUpdateFlagStatusModalFunc(test) {
		const updateFlagStatusModalData = {
			patientDetail: {
				dob: _.get(test, ['patient', 'dob'], null),
				first_name: _.get(test, ['patient', 'first_name'], ''),
				last_name: _.get(test, ['patient', 'last_name'], ''),
				email: _.get(test, ['patient', 'email'], '--'),
				gender: _.get(test, ['patient', 'gender'], ''),
				referral_program: _.get(
					test,
					['patient', 'referral_program'],
					'--'
				),
			},
			testOrder: {
				testname: _.get(test, ['test_name'], ''),
				lab: _.get(test, ['lab', 'display_name'], ''),
				test_order_uuid: _.get(test, 'order.uuid', null),
				red_flags: _.get(test, 'order.red_flags', []),
			},
		};

		this.setState({
			showUpdateFlagStatusModal: true,
			updateFlagStatusModalData,
		});
	}

	hideUpdateFlagForTestRequestModal() {
		this.setState({
			showUpdateFlagStatusModal: false,
			updateFlagStatusModalData: {},
		});
	}

	showRedFlagAuditTrailModalFunc(test) {
		const redFlagAuditTrailData = {
			patientDetail: {
				dob: _.get(test, ['patient', 'dob'], null),
				first_name: _.get(test, ['patient', 'first_name'], ''),
				last_name: _.get(test, ['patient', 'last_name'], ''),
				email: _.get(test, ['patient', 'email'], '--'),
				gender: _.get(test, ['patient', 'gender'], ''),
				referral_program: _.get(
					test,
					['patient', 'referral_program'],
					'--'
				),
			},
			testOrder: {
				testname: _.get(test, ['test_name'], ''),
				lab: _.get(test, ['lab', 'display_name'], ''),
				test_order_uuid: _.get(test, 'order.uuid', null),
				red_flags: _.get(test, 'order.red_flags', []),
				red_flags_audit_trail: _.get(
					test,
					'order.red_flags_audit_trail',
					[]
				),
			},
		};

		this.setState({
			showRedFlagAuditTrailModal: true,
			redFlagAuditTrailData,
		});
	}

	hideRedFlagAuditTrailModal() {
		this.setState({
			showRedFlagAuditTrailModal: false,
			redFlagAuditTrailData: {},
		});
	}

	getEncounterDocuments = (selectedTest = {}) => {
		this.setState(
			{ showEncounterDocumentsList: true, selectedTest },
			() => {
				this.props.getEncounterDocuments(selectedTest.uuid);
			}
		);
	};

	hideEncounterDocumentsList = () => {
		this.setState({ showEncounterDocumentsList: false, selectedTest: {} });
	};

	handleDocClick = (selectedDoc) => {
		this.setState({
			showDocument: true,
			selectedDoc,
			showEncounterDocumentsList: false,
		});
	};

	downloadDocument = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.props.performDocumentAction(
			this.state.selectedDoc,
			'downloaded',
			this.state.selectedTest.patient.uuid
		);
	};

	printDocument = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.props.performDocumentAction(
			this.state.selectedDoc,
			'printed',
			this.state.selectedTest.patient.uuid
		);
	};

	closeDocumentDetail = () => {
		this.setState({
			showEncounterDocumentsList: false,
			showDocument: false,
			selectedTest: {},
			selectedDoc: {},
		});
	};

	render() {
		const selectedLength = get(this, 'state.selectedTests.length', 0) || 0;
		return (
			<div className='testAuthContainer'>
				<TestAuthFilterActionRow
					determineItemsSelected={this.determineItemsSelected}
					handleSearchQueryChange={this.handleSearchQueryChange}
					updateFilters={this.updateFilters}
					resetFilters={this.resetFilters}
					resultsStatus={resultsStatus}
					cases={cases}
					tests={get(this, 'props.geneticTests', []) || []}
					selectedLength={selectedLength}
					searchQuery={get(this, 'state.searchQuery', '')}
					resultsStatusFilter={get(
						this,
						'state.resultsStatusFilter',
						null
					)}
					testsFilter={get(this, 'state.testsFilter', null)}
					approveBulk={this.approveBulk}
					orderStatusEnum={
						get(this, 'props.geneTestOrderStatusEnum', []) || []
					}
					isGCOrCC={get(this, 'state.isGCOrCC', null)}
					referralPrograms={get(
						this,
						['state', 'referralPrograms'],
						[]
					)}
					cases={get(this, ['state', 'cases'], true)}
					testAuthEnums={get(this, ['props', 'testAuthEnums'], {})}
					testAuthEnumsLoading={get(
						this,
						['props', 'testAuthEnumsLoading'],
						false
					)}
					patientShippingState={get(
						this,
						'state.patientShippingState',
						[]
					)}
					redFlagFilter={get(this, 'state.redFlagFilter', [])}
				/>
				<TestAuthTable
					sortedField={get(
						this,
						'state.sortedField',
						'date_test_requested'
					)}
					sortDirection={get(this, 'state.sortDirection', 'desc')}
					handleSort={this.handleSort}
					offset={get(this, 'state.offset', 0) || 0}
					changeOffset={this.changeOffset}
					headerChecked={get(this, 'state.headerChecked', false)}
					determineItemsSelected={this.determineItemsSelected}
					handleItemToggle={this.handleItemToggle}
					handleAllToggle={this.handleAllToggle}
					approveTest={this.approveTest}
					rejectTest={this.rejectTest}
					tests={get(this, 'props.testAuthData', {}) || {}}
					testAuthSearchLoading={get(
						this,
						'props.testAuthSearchLoading',
						false
					)}
					testOrderUpdating={get(
						this,
						'props.testOrderUpdating',
						false
					)}
					testBulkUpdating={get(
						this,
						'props.testBulkUpdating',
						false
					)}
					gettingGeneticTestsDropdown={get(
						this,
						'props.gettingGeneticTests',
						false
					)}
					selectedTests={get(this, 'state.selectedTests', []) || []}
					redirectToEncounter={this.redirectToEncounter}
					orderStatusEnum={
						get(this, 'props.geneTestOrderStatusEnum', []) || []
					}
					geneTestOrderStatusEnumLoading={get(
						this,
						'props.geneTestOrderStatusEnumLoading',
						false
					)}
					order_by={get(
						this,
						'state.sortedField',
						'date_test_requested'
					)}
					order_type={get(this, 'state.sortDirection', 'desc')}
					handleResultsPerPageChange={this.handleResultsPerPageChange}
					limit={get(this, ['state', 'limit'])}
					handlePageChange={this.handlePageChange}
					handleResetFilters={this.resetFilters}
					sexAssignedAtBirthLoading={get(
						this,
						['props', 'sexAssignedAtBirthLoading'],
						false
					)}
					sexAssignedAtBirthEnums={get(
						this,
						['props', 'sexAssignedAtBirthEnums'],
						[]
					)}
					showUpdateFlagStatusModal={
						this.showUpdateFlagStatusModalFunc
					}
					showRedFlagAuditTrailModal={
						this.showRedFlagAuditTrailModalFunc
					}
					getEncounterDocuments={this.getEncounterDocuments}
				/>
				{_.get(this, 'state.showRejectTestModal', false) && (
					<div className='popupModal'>
						<RejectTestRequestModal
							hideRejectTestModal={this.hideRejectTestModal}
							updateTestOrder={this.updateTestOrder}
							approveOrRejectSingleTest={
								this.approveOrRejectSingleTest
							}
							testOrderUpdating={get(
								this,
								'props.testOrderUpdating',
								false
							)}
							testBulkUpdating={get(
								this,
								'props.testBulkUpdating',
								false
							)}
							{...this.state.rejectTestRowData}
						/>
					</div>
				)}
				{_.get(this, 'state.showUpdateFlagStatusModal', false) && (
					<div className='popupModal'>
						<UpdateFlagForTestRequestModal
							testOrderRedFlagUpdating={get(
								this,
								'props.testOrderRedFlagUpdating',
								false
							)}
							removeTestOrderRedFlag={this.removeTestOrderRedFlag}
							hideUpdateFlagForTestRequestModal={
								this.hideUpdateFlagForTestRequestModal
							}
							{...this.state.updateFlagStatusModalData}
						/>
					</div>
				)}
				{_.get(this, 'state.showRedFlagAuditTrailModal', false) && (
					<div className='popupModal'>
						<RedFlagAuditTrailDataModal
							hideRedFlagAuditTrailModal={
								this.hideRedFlagAuditTrailModal
							}
							{...this.state.redFlagAuditTrailData}
						/>
					</div>
				)}
				{_.get(
					this,
					['state', 'showEncounterDocumentsList'],
					false
				) && (
					<div className='popupModal'>
						<DocumentsListModal
							test={this.state.selectedTest}
							hideEncounterDocumentsList={
								this.hideEncounterDocumentsList
							}
							encounterDocumentsLoading={
								this.props.encounterDocumentsLoading
							}
							encounterDocuments={this.props.encounterDocuments}
							encounterDocumentsError={
								this.props.encounterDocumentsError
							}
							handeDocClick={this.handleDocClick}
						/>
					</div>
				)}
				{_.get(this, ['state', 'showDocument'], false) && (
					<div className='document'>
						<DocumentInlineView
							docDetail={this.state.selectedDoc}
							downloadDocument={this.downloadDocument}
							getInlineDocumentDetail={
								this.props.getInlineDocumentDetail
							}
							inlineDocDetail={this.props.inlineDocDetail}
							inlineDocDetailError={
								this.props.inlineDocDetailError
							}
							inlineDocDetailLoading={
								this.props.inlineDocDetailLoading
							}
							documentActionLoading={
								this.props.documentActionLoading
							}
							navigateToDocumentList={this.closeDocumentDetail}
							patientUuid={this.state.selectedTest.patient.uuid}
							printDocument={this.printDocument}
						/>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	me: state.me,
	testAuthSearchLoading:
		get(state, 'testAuthorization.testAuthSearchLoading', null) || null,
	testAuthData: get(state, 'testAuthorization.testAuthData', null) || null,
	testAuthSearchError:
		get(state, 'testAuthorization.testAuthSearchError', null) || null,
	gettingGeneticTests:
		get(state, 'geneticTests.gettingGeneticTests', null) || null,
	geneticTests: get(state, 'geneticTests.geneticTests', []) || [],
	geneticTestsError:
		get(state, 'geneticTests.geneticTestsError', null) || null,
	encounterEditLoading: state.editencounter.encounterEditLoading,
	encounterEditError: state.editencounter.encounterEditError,
	testBulkUpdating: get(state, 'testBulkUpdate.testBulkUpdating', null),
	testBulkUpdated: get(state, 'testBulkUpdate.testBulkUpdated', null),
	testBulkUpdatedError: get(
		state,
		'testBulkUpdate.testBulkUpdatedError',
		null
	),
	testOrderUpdating: get(state, 'testOrder.testOrderUpdating', null),
	testOrderUpdated: get(state, 'testOrder.testOrderUpdated', null),
	testOrderUpdatedError: get(state, 'testOrder.testOrderUpdatedError', null),
	geneTestOrderStatusEnum:
		get(state, 'geneTestOrderStatusEnum.geneTestOrderStatusEnum', []) || [],
	geneTestOrderStatusEnumLoading: get(
		state,
		'geneTestOrderStatusEnum.geneTestOrderStatusEnumLoading',
		null
	),
	geneTestOrderStatusEnumError: get(
		state,
		'geneTestOrderStatusEnum.geneTestOrderStatusEnumError',
		null
	),
	testAuthorizationFilter:
		get(state, 'appData.testAuthorization', null) || {},
	testAuthEnums: get(state, ['testAuthEnums', 'testAuthEnums'], {}),
	testAuthEnumsLoading: get(
		state,
		['testAuthEnums', 'testAuthEnumsLoading'],
		false
	),
	testAuthEnumsError: get(
		state,
		['testAuthEnums', 'testAuthEnumsError'],
		null
	),
	sexAssignedAtBirthLoading: get(
		state,
		'sexAssignedAtBirth.sexAssignedAtBirthLoading',
		false
	),
	sexAssignedAtBirthEnums: get(
		state,
		['sexAssignedAtBirth', 'sexAssignedAtBirth', 'sex_assigned_at_birth'],
		[]
	),
	sexAssignedAtBirthError: get(
		state,
		'sexAssignedAtBirth.sexAssignedAtBirthError',
		{}
	),
	testOrderRedFlagUpdating: get(
		state,
		'updateTestOrderRedFlag.testOrderRedFlagUpdating',
		null
	),
	testOrderRedFlagUpdated: get(
		state,
		'updateTestOrderRedFlag.testOrderRedFlagUpdated',
		null
	),
	testOrderRedFlagUpdatedError: get(
		state,
		'updateTestOrderRedFlag.testOrderRedFlagUpdatedError',
		null
	),
	encounterDocumentsLoading: get(
		state,
		['encounterDocuments', 'loading'],
		false
	),
	encounterDocuments: get(state, ['encounterDocuments', 'data'], null),
	encounterDocumentsError: get(state, ['encounterDocuments', 'error'], null),
	inlineDocDetail: get(state, ['documents', 'inlineDocDetail']),
	inlineDocDetailError: get(state, ['documents', 'inlineDocDetailError']),
	inlineDocDetailLoading: get(state, ['documents', 'inlineDocDetailLoading']),
	documentActionLoading: get(state, ['documents', 'documentActionLoading']),
});

const mapDispatchToProps = (dispatch) => ({
	testAuthSearch: (data) => dispatch(scpTestSearch(data)),
	editEncounter: (data) => dispatch(editencounter(data)),
	testBulkUpdate: (data) => dispatch(testBulkUpdate(data)),
	updateTestOrder: (uuid, data) => dispatch(updateTestOrder(uuid, data)),
	getGeneTestOrderStatusEnum: () => dispatch(getGeneTestOrderStatusEnum()),
	setTestAuthorizationFilter: (data) =>
		dispatch(setFilter('testAuthorization', data)),
	getTestAuthEnums: (data) => dispatch(getTestAuthEnums(data)),
	getSexAssignedAtBirthEnum: () => dispatch(getSexAssignedAtBirth()),
	updateTestOrderRedFlag: (gene_test_order_uuid, data) =>
		dispatch(updateTestOrderRedFlag(gene_test_order_uuid, data)),
	getEncounterDocuments: (encounter_uuid) =>
		dispatch(getEncounterDocuments(encounter_uuid)),
	getInlineDocumentDetail: (documentObj, action, patientUuid) =>
		dispatch(loadInlineDocumentDetail(documentObj, action, patientUuid)),
	performDocumentAction: (documentObj, action, patientUuid) =>
		dispatch(documentAction(documentObj, action, patientUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestAuthorization);
