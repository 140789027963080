import EmailValidator from 'email-validator';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import z from 'zod';

import { patientdetail } from '../../../../../actions/patients';
import { Section } from '@gm/common/ui';
import { Tags } from '../../../../Common/V3/Tags';
import { SelectPatient } from '../components/SelectPatient';
import { useSection } from '../../../../Patients/V3/CreatePatient/sections/useSection';

import './PatientInfo.scss';
import { PatientInfoForm } from '../components/PatientInfoForm';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useFormikContext } from 'formik';

export const initialPatientInfo = {
	associatedProgram: null,
	consentToTreat: null,
	dateOfBirth: null,
	guardianFirstName: null,
	guardianLastName: null,
	hasGuardian: false,
	patientEmail: '',
	patientFirstName: '',
	patientId: null,
	patientLastName: '',
	patientPrimaryPhoneNumber: '',
	patientUUID: '',
	preferredName: null,
	relationshipToPatient: null,
	tags: {},
	textNotification: true,
};

export const schemaPatientInfo = z
	.object({
		associatedProgram: z.number({
			invalid_type_error: 'Associated Program is required',
		}),
		consentToTreat: z
			.string({ invalid_type_error: 'Consent to Treat is required' })
			.min(1, { message: 'Consent to Treat is required' })
			.refine(
				(v) => v !== 'not_consented',
				"To proceed with scheduling, please obtain patient's consent to treat.",
			),
		dateOfBirth: z
			.string()
			.nullable(),
		guardianFirstName: z.string().nullable(),
		guardianLastName: z.string().nullable(),
		hasGuardian: z.boolean(),
		patientEmail: z
			.string()
			.min(1, { message: 'Email is required' })
			.refine((v) => EmailValidator.validate(v), 'Email is invalid'),
		patientId: z.number({
			invalid_type_error: 'Selecting a patient is required',
			required_error: 'Selecting a patient is required',
		}),
		patientPrimaryPhoneNumber: z
			.string()
			.min(1, { message: 'Phone number is required' })
			.refine((v) => isValidPhoneNumber(v), 'Phone number is invalid'),
		patientUUID: z
			.string()
			.uuid({ message: 'Selecting a patient is required' }),
		preferredName: z.string().nullable(),
		relationshipToPatient: z.string().nullable(),
		tags: z.record(
			z.string(),
			z
				.boolean()
				.or(
					z.record(
						z.string(),
						z
							.array(z.string())
							.min(1, { message: 'Tag details are required' }),
					),
				),
		),
		textNotification: z.boolean().optional(),
	})
	.superRefine((val, ctx) => {
		if (val.hasGuardian) {
			if (!val.guardianFirstName) {
				ctx.addIssue({
					path: ['guardianFirstName'],
					code: z.ZodIssueCode.custom,
					message: 'First name is required',
				});
			}

			if (!val.guardianLastName) {
				ctx.addIssue({
					path: ['guardianLastName'],
					code: z.ZodIssueCode.custom,
					message: 'Last name is required',
				});
			}

			if (!val.relationshipToPatient) {
				ctx.addIssue({
					path: ['relationshipToPatient'],
					code: z.ZodIssueCode.custom,
					message: 'Relationship to patient is required',
				});
			}
		}
		if (!val.dateOfBirth) {
			ctx.addIssue({
				path: ['dateOfBirth'],
				code: z.ZodIssueCode.custom,
				message: 'Date of birth is required',
			});
		}
	});

export function PatientInfo({ sectionDataContainerName }) {
	const dispatch = useDispatch();
	const { resetForm } = useFormikContext();
	const { errors, setFieldValue, touched, values } = useSection(
		sectionDataContainerName,
	);

	const { patientDetails } = useSelector((store) => ({
		patientDetails: store.patientdetail?.patientdetail?.[0],
	}));

	useEffect(() => {
		if (values.patientUUID) {
			dispatch(patientdetail(values.patientUUID));
		}
	}, [values.patientUUID]);

	useEffect(() => {
		if (patientDetails && values.patientUUID) {
			if (!values.patientId) {
				setFieldValue('patientId', patientDetails.id);
			}
			setFieldValue('patientFirstName', patientDetails.first_name);
			setFieldValue('patientLastName', patientDetails.last_name);
			setFieldValue('patientPrimaryPhoneNumber', patientDetails.phone);
			// CS-2112: Copy initial text consent from original phone number.
			// We decided that the text consent (or lack thereof) transfers to a new phone number that might be entered during scheduling.
			setFieldValue(
				'textNotification',
				patientDetails.phones?.primary?.consent_to_text,
			);
			setFieldValue('consentToTreat', patientDetails.consent_to_treat);
			setFieldValue('preferredName', patientDetails.preferred_name);
			setFieldValue('patientEmail', patientDetails.email);
			setFieldValue('associatedProgram', patientDetails.partner_id);
			setFieldValue('hasGuardian', patientDetails.has_guardian);
			setFieldValue('dateOfBirth', patientDetails.dob);
			setFieldValue(
				'tags',
				patientDetails.user_tags?.reduce((acc, item) => {
					acc[item.tagId] = item.tagProperties
						? item.tagProperties[0]
						: true;
					return acc;
				}, {}) ?? {},
			);
			if (patientDetails.has_guardian) {
				const { first_name, last_name, phone, phones, relationship } =
					patientDetails.guardian;
				setFieldValue('guardianFirstName', first_name);
				setFieldValue('guardianLastName', last_name);
				setFieldValue('patientPrimaryPhoneNumber', phone);
				setFieldValue(
					'textNotification',
					phones?.primary?.consent_to_text,
				);
				setFieldValue('relationshipToPatient', relationship);
			}
		}
	}, [patientDetails]);

	useEffect(() => {
		if (!values.hasGuardian) {
			setFieldValue('guardianFirstName', null);
			setFieldValue('guardianLastName', null);
			setFieldValue('relationshipToPatient', null);
		}
	}, [values.hasGuardian]);

	return (
		<>
			<Section className='select-a-patient' title='Select a patient'>
				<SelectPatient
					onChange={(id, uuid) => {
						resetForm();
						setFieldValue('patientId', id);
						setFieldValue('patientUUID', uuid);
					}}
					value={values.patientId}
					touched={touched.patientId}
					error={errors.patientId}
				/>
			</Section>
			<Section className='patient-info' title='Patient Info'>
				<PatientInfoForm
					sectionDataContainerName={sectionDataContainerName}
				/>
				<h3>Flags</h3>
				<p>If applicable, add relevant flags to the patient account</p>
				<Tags
					setFieldValue={setFieldValue}
					values={values.tags}
					errors={errors.tags}
					touched={touched.tags}
				/>
			</Section>
		</>
	);
}
