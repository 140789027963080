//React & Redux
import React, { Component, Fragment } from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Form, Input, Loading, Select } from 'gm-element-react';

//Styles
import './css/workflow-document-form.css';

class WorkFlowDocumentForm extends Component {
	constructor(props) {
		super(props);
		const { partner_uuid, categoryType } = this.props;
		this.state = {
			savingData: false,
			uuid: '',
			form: {
				partneruuid: partner_uuid,
				filename: '',
				documenttype: 'general',
				url: '',
				category: categoryType.WORKFLOW_DOCUMENT,
			},
			rules: {
				filename: [
					{
						required: true,
						message: 'Please enter Title',
						trigger: 'blur',
					},
				],
				documentype: [
					{
						required: true,
						message: 'Please enter Workflow document type',
						trigger: 'blur',
					},
				],
				url: [
					{
						required: true,
						type: 'url',
						message: 'Please enter valid Workflow URL',
						trigger: 'blur',
					},
				],
			},
		};
	}

	componentDidMount() {
		this.props.resetWorkflowDocuments();
		this.props.getLoadDocumentTypes();
		this.referralDocumentsApiFunc();
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (
			newProps.workflowDocuments.loaded !=
				this.props.workflowDocuments.loaded &&
			!newProps.workflowDocuments.loading
		) {
			if (
				newProps.workflowDocuments &&
				newProps.workflowDocuments.loaded &&
				newProps.workflowDocuments.loaded.data &&
				newProps.workflowDocuments.loaded.data.length > 0
			) {
				const data =
					_.head(newProps.workflowDocuments.loaded.data) || {};
				const form = this.state.form;
				if (data.documentUUID) {
					form['filename'] = data.name;
					form['documenttype'] = data.documentTypeValue;
					form['url'] = data.URL;
					this.setState({ form });
				}
				this.setState({ uuid: data.documentUUID });
			}
		}
		if (this.state.savingData && newProps != this.props) {
			this.props.sendNotification(
				'Data has been saved successfully.',
				'success'
			);
			this.setState({ savingData: false }, this.referralDocumentsApiFunc);
		}
	}

	componentWillUnmount() {
		this.props.clearLoadDocumentTypes();
	}

	handleSubmit(e) {
		e.preventDefault();
		this.refs.form.validate((valid) => {
			if (valid) {
				this.setState({ savingData: true });
				if (this.state.uuid) {
					this.updateReferralDocument(
						this.state.uuid,
						this.state.form
					);
				} else {
					this.createReferralDocument(this.state.form);
				}
				return false;
			} else {
				return false;
			}
		});
	}

	handleReset = (e) => {
		e.preventDefault();
		this.refs.form.resetFields();
		if (this.props && this.props.goToReferralProgramsList) {
			this.props.goToReferralProgramsList();
		} else {
			this.props.ChangeTab(this.props.Tabs.CLINICALTEMPLATE);
		}
	};

	onChange(key, value) {
		this.setState({
			form: Object.assign({}, this.state.form, { [key]: value }),
		});
	}

	handleChange = (event) => {
		this.onChange(event.target.name, event.target.value);
	};

	onSubmit(e) {
		e.preventDefault();
	}

	getReferralDocument = (data) => {
		this.props.getReferralDocument(data);
	};

	createReferralDocument = (data) => {
		this.props.getCreateTemplate(data);
	};

	updateReferralDocument = (uuid, data) => {
		this.props.getUpdateTemplate(uuid, data);
	};

	isLoading() {
		return (
			_.get(this, 'state.savingData', false) ||
			!_.get(this.props, 'workflowDocuments.loaded', false) ||
			_.get(this, 'props.workflowDocuments.loading', false)
		);
	}

	referralDocumentsApiFunc() {
		const { partner_uuid, categoryType } = this.props;
		this.props.getWorkDocuments({
			partner_uuid: partner_uuid,
			category: categoryType.WORKFLOW_DOCUMENT,
		});
	}

	render() {
		const { documentTypes } = this.props;
		return (
			<Fragment>
				<Loading
					loading={this.isLoading()}
					className='loader-workflow-document'
				>
					<Form
						ref='form'
						className='workflow-form'
						model={this.state.form}
						rules={this.state.rules}
						onSubmit={this.onSubmit.bind(this)}
					>
						<div className='col-12'>
							<Form.Item label='Title' prop='filename'>
								<Input
									value={this.state.form.filename}
									placeholder='Lorem ipsum'
									trim={true}
									onChange={this.onChange.bind(
										this,
										'filename'
									)}
								/>
							</Form.Item>
						</div>
						<div className='col-12'>
							<Form.Item
								label='Workflow document type'
								prop='documenttype'
							>
								<Select
									value={this.state.form.documenttype}
									placeholder='General'
									disabled={true}
									onChange={this.onChange.bind(
										this,
										'documenttype'
									)}
									className='inputFieldDropdown inputFieldDropdownTaller'
								>
									{(documentTypes || []).map((el) => {
										return (
											<Select.Option
												key={'WFDT_' + el.display_name}
												label={el.display_name}
												value={el.key}
											/>
										);
									})}
								</Select>
							</Form.Item>
						</div>
						<div className='col-12'>
							<Form.Item label='Workflow URL' prop='url'>
								<Input
									value={this.state.form.url}
									placeholder='http://www.url.com'
									trim={true}
									onChange={this.onChange.bind(this, 'url')}
									className='erFieldText inputFieldTextTaller'
								/>
							</Form.Item>
						</div>
						<div className='col-12 link-note'>
							Please paste the line item link from the spreadsheet
							above
						</div>
						<div className='col-12'>
							<div className='action-buttons'>
								<div
									className='cancel-button'
									onClick={this.handleReset.bind(this)}
								>
									<span className='text'>Cancel</span>
								</div>
								<div
									className='save-button'
									onClick={this.handleSubmit.bind(this)}
								>
									<span className='text'>Save</span>
								</div>
							</div>
						</div>
					</Form>
				</Loading>
			</Fragment>
		);
	}
}
export default WorkFlowDocumentForm;
