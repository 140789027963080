import moment, { Moment } from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import InputMask from 'react-input-mask';

import { FormField, IFormFieldProps } from './FormField';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerField.scss';

interface IProps extends IFormFieldProps {
	minDate?: Moment;
	onChange: (val: string) => void;
	value?: string | Moment;
	placeholder?: string;
	disabledFutureDates?: boolean;
	isClearable?: boolean;
}

// Date formats which Moment is expecting to accept
const formats = ['MM/DD/YYYY', 'YYYY-MM-DD'];

function adjustUtcToLocalMoment(input: string | Moment): Moment {
	return moment(moment.utc(input, formats).format(moment.HTML5_FMT.DATE));
}

function adjustUtcToLocal(input: string | Moment): Date {
	return adjustUtcToLocalMoment(input).toDate();
}

function formatLocalDateToOutputFormat(input: Date): string {
	return moment(input).format('MM/DD/YYYY');
}

export const DatePickerField: React.FC<IProps> = (props: IProps) => {
	const {
		className,
		name,
		label,
		required,
		error,
		value,
		tooltip,
		disabledFutureDates = false,
		onChange,
		placeholder,
		isClearable,
	} = props;

	const selectedDate =
		value && moment(value, formats).isValid()
			? adjustUtcToLocal(value)
			: null;

	// If specified, don't allow selection of dates after today
	const maxDate = disabledFutureDates ? moment().toDate() : undefined;

	return (
		<FormField
			className={classnames('gm-date-picker', className)}
			name={name}
			label={label}
			required={required}
			error={error}
			tooltip={tooltip}
		>
			<DatePicker
				name={name}
				placeholderText={placeholder}
				selected={selectedDate}
				dateFormat='MM/dd/yyyy'
				maxDate={maxDate}
				onChange={(date: Date | null) => {
					onChange(date ? formatLocalDateToOutputFormat(date) : '');
				}}
				isClearable={isClearable}
				showMonthDropdown
				showYearDropdown
				dropdownMode='select'
				customInput={<InputMask mask='99/99/9999' maskChar={null} />}
			/>
		</FormField>
	);
};
