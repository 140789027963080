import { gql } from '@apollo/client';
import { client } from './GraphQLConnection';

class GraphQL {
	getPatientDrugExposures = (uuid) => {
		return client.query({
			query: gql`
				{
					patient(id: "${uuid}") {
						id
						name
						drugExposures {
							id
							drug {
								code
								name
							}
							status
							asserted_on
							asserted_by {
								... on User {
									id
									name
								}
							}
							notes {
								id
								text
								enteredAt
								source {
									... on User {
										id
										name
									}
								}
							}
						}
					}
				}
			`,
		});
	};

	addDrugExposureNote = (aboutItemId, text) => {
		return client.mutate({
			mutation: gql`
				mutation ($aboutItemId: ID!, $text: Markdown!) {
					addNote(input: { aboutItemId: $aboutItemId, text: $text }) {
						note {
							id
							source {
								id
								name
							}
							enteredAt
							text
						}
					}
				}
			`,
			variables: {
				aboutItemId: aboutItemId,
				text: text,
			},
		});
	};
}

export default new GraphQL();
