import * as actionTypes from '../actions/actionTypes';

const UserRolesDefaultState = {
	data: [],
	error: null,
	loading: false,
	loaded: false,
};

export const defaultState = {
	...UserRolesDefaultState,
};

export default (state = defaultState, action = {}) => {
	switch (action.type) {
		case actionTypes.LOAD_USER_ROLES_START:
			return {
				...state,
				loading: true,
				loaded: false,
				error: null,
			};

		case actionTypes.LOAD_USER_ROLES_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: null,
				data: action.payload,
			};

		case actionTypes.LOAD_USER_ROLES_FAILURE:
			return {
				...state,
				loading: false,
				loaded: true,
				error: action.payload,
			};

		default:
			return state;
	}
};
