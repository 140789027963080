import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { addressTypeConst } from '../../../../../utils';

import { InsuranceDetails } from '../../../../Patients/V3/CreatePatient/components/InsuranceDetails';
import { CreditCardForm } from '../../../../Patients/V3/CreatePatient/components/CreditCardForm';
import { useSection } from '../../../../Patients/V3/CreatePatient/sections/useSection';
import PreFilledCreditCard from '../../../PreFilledCreditCard';

import { AddressForm } from '../components/AddressForm';

export const InsurancePayment = forwardRef(function InsurancePayment(
	{ sectionDataContainerName, hasSavedCard },
	ref
) {
	const { allValues, setFieldValue, values } = useSection(
		sectionDataContainerName
	);

	const { patientDetails } = useSelector((store) => ({
		patientDetails: store.patientdetail?.patientdetail?.[0],
	}));

	const cardInfo = {
		brand: patientDetails?.brand,
		last4: patientDetails?.last4,
		exp_year: patientDetails?.exp_year,
		exp_month: patientDetails?.exp_month,
	};

	return (
		<>
			<InsuranceDetails
				required
				className='column-layout-3'
				sectionDataContainerName={sectionDataContainerName}
				referralProgramId={allValues.patientInfo.associatedProgram}
				preFillForm={allValues.patientInfo.patientId}
			/>
			{/* Note: Although this field is labeled as 'Billing address', it is represented as the 'Home address' on the backend. */}

			<h3>Billing Address</h3>
			<AddressForm
				sectionDataContainerName={sectionDataContainerName}
				addressType={addressTypeConst.home}
			/>

			{values.insurancePrice !== null && values.insurancePrice !== 0 && (
				<>
					<h3 className='credit-card-heading'>
						Credit Card Information
					</h3>
					{allValues.patientInfo.patientId &&
					hasSavedCard &&
					values.useSavedCard ? (
						<PreFilledCreditCard
							cardInfo={cardInfo}
							changePaymentMethod={() =>
								setFieldValue('useSavedCard', false)
							}
						/>
					) : (
						<CreditCardForm
							ref={ref}
							required
							sectionDataContainerName={sectionDataContainerName}
							referralProgramId={
								allValues.patientInfo.associatedProgram
							}
						/>
					)}
				</>
			)}
		</>
	);
});
