//React & Redux
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

//Hooks
import { usePrevious } from '../../hooks/usePrevious';

//Lodash
import _ from 'lodash';

//Actions & Services
import {
	createProvider,
	updateProvider,
	getProviderSpecialties,
	getProviderCredentials,
	getProviders,
	resetProviderState,
} from '../../actions/providers';

//UI Libraries
import { Dialog, Loading } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';

//Utils
import { hydrateProvider } from '../../transformers/providers/providerTransformers';

//Components
import ProviderForm from '../ExternalProviders/ProviderForm';
import ProviderLinkForm from '../ExternalProviders/ProviderLinkForm';
import Icon from '../Common/Icon';

//Styles
import './ProviderLinkDialog.css';

const STAGE_ADD_EDIT_PROVIDER = 'STAGE_ADD_EDIT_PROVIDER';
const STAGE_LINK_PROVIDER = 'STAGE_LINK_PROVIDER';

const ProviderLinkDialog = (props) => {
	const {
		addExternalProvider,
		cancel,
		createdProvider = {},
		createdProviderError,
		createProvider: createProviderProp,
		creatingProvider = false,
		editingLinkInfo: editingLinkInfoProp = false,
		editingProvider: editingProviderProp = false,
		enqueueSnackbar,
		getProviders: getProvidersProp,
		getProviderCredentials,
		getProviderSpecialties,
		gettingProviders = false,
		linkedTo,
		providerCredentials = [],
		providers = [],
		providerSpecialties = [],
		resetProviderState,
		selectedProvider,
		selectedProviders = [],
		updatedProvider = {},
		updatedProviderError,
		updateProvider: updateProviderProp,
		updatingProvider = false,
		visible,
	} = props;

	const [stage, setStage] = useState(STAGE_ADD_EDIT_PROVIDER);
	const [formData, setFormData] = useState({});
	const [existingProvider, setExistingProvider] = useState(false);
	const [editingProvider, setEditingProvider] = useState(editingProviderProp);
	const [editingLinkInfo, setEditingLinkInfo] = useState(editingLinkInfoProp);
	const [editProviderData, setEditProviderData] = useState({});
	const [searchingProvider, setSearchingProvider] = useState(false);
	const [extProviderPayload, setExtProviderPayload] = useState({});

	useEffect(() => {
		getProviderEnums();
		if (!_.isEmpty(selectedProvider) && !editingProvider) {
			setStage(STAGE_LINK_PROVIDER);
			setFormData(Object.assign({}, formData, selectedProvider));
		} else {
			setStage(STAGE_ADD_EDIT_PROVIDER);
			setFormData(Object.assign({}, formData, selectedProvider));
			if (editingProvider) {
				const fullName = selectedProvider?.providerFullName || '';
				getProviders(fullName);
			}
		}
		if (Array.isArray(providers)) setProviderData();
		return resetProviderState;
	}, []);

	const prevCreatingProviderProp = usePrevious(creatingProvider);
	const prevUpdatingProviderProp = usePrevious(updatingProvider);
	const prevGettingProvidersProp = usePrevious(gettingProviders);

	useEffect(() => {
		if (prevCreatingProviderProp && !creatingProvider) {
			if (createdProviderError) {
				enqueueSnackbar('Error in creating provider', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				const provider = hydrateProvider(
					createdProvider?.data.data || {}
				);
				setStage(STAGE_LINK_PROVIDER);
				setFormData(Object.assign({}, provider));
			}
		}
	}, [creatingProvider]);

	useEffect(() => {
		if (prevUpdatingProviderProp && !updatingProvider) {
			if (updatedProviderError) {
				enqueueSnackbar('Error in updating provider', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				enqueueSnackbar('Provider updated', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				const data = hydrateProvider(
					_.get(updatedProvider, 'data.data.0', {})
				);
				handleProviderSubmit(data);
			}
		}
	}, [updatingProvider]);

	useEffect(() => {
		if (prevGettingProvidersProp && !gettingProviders) {
			setProviderData();
		}
	}, [gettingProviders]);

	const setProviderData = () => {
		const { providerUUID } = formData;
		const obj = providers.find(
			(el) => _.get(el, ['rawData', 'uuid']) == providerUUID
		);
		setEditProviderData(_.get(obj, 'rawData', {}));
	};

	const handleProviderSubmit = (values) => {
		if (editingProvider) {
			addExternalProvider(values);
			setFormData({});
			setStage(STAGE_ADD_EDIT_PROVIDER);
		} else {
			const { providerUUID } = values;
			const provider = getProvider(providerUUID);
			setStage(STAGE_LINK_PROVIDER);
			setFormData(Object.assign({}, values, provider));
		}
	};

	const handleProviderLinkSubmit = (values) => {
		const newFormData = Object.assign({}, formData, values);
		addExternalProvider(newFormData);
		setFormData({});
		setStage(STAGE_ADD_EDIT_PROVIDER);
	};

	const goBack = (q) => {
		setStage(STAGE_ADD_EDIT_PROVIDER);
		setExistingProvider(true);
		getProviders(q);
	};

	const getProvider = (providerUUID) => {
		return providers.find((el) => el && el.providerUUID == providerUUID);
	};

	const getSpecialty = (specialty, noData = '--') => {
		if (specialty && Array.isArray(providerSpecialties)) {
			const specialtyObj = _.find(providerSpecialties, [
				'name',
				specialty,
			]);
			return specialtyObj?.display_name || noData;
		} else return noData;
	};

	const createProvider = (payload) => {
		if (payload) {
			setExtProviderPayload(payload);
			createProviderProp(payload);
		}
	};

	const updateProvider = (providerUUID, payload) => {
		if (providerUUID && payload) {
			setExtProviderPayload(payload);
			updateProviderProp(providerUUID, payload);
		}
	};

	const getProviderEnums = () => {
		getProviderCredentials();
		if (!_.get(providerSpecialties, 'length', 0) > 0) {
			getProviderSpecialties();
		}
	};

	const getProviders = (q = '') => {
		getProvidersProp({ is_gmi_provider: false, limit: 100, q: q });
	};

	const handleSearchingProvider = (value) => {
		setSearchingProvider(value);
	};

	const isLoadingData = () => {
		return gettingProviders || creatingProvider || updatingProvider;
	};

	const isLoading = () => {
		return !searchingProvider && isLoadingData();
	};

	const { savedRecord } = formData;
	const hasBreadcrumbs = !savedRecord && !editingProvider && !editingLinkInfo;
	const receiveAllComms = formData.receiveAllComms ?? true;

	return (
		<Dialog
			customClass='provider-link-dialog'
			title={
				<span className='header'>
					<span
						className={classnames(
							'dialog-title',
							!hasBreadcrumbs ? 'no-breadcrumbs' : ''
						)}
					>
						{editingLinkInfo
							? 'Edit Link Info'
							: editingProvider
							? 'Edit External Provider'
							: 'Link External Provider'}
						{hasBreadcrumbs && (
							<span className='breadcrumbs'>
								<span
									className={classnames(
										stage == STAGE_ADD_EDIT_PROVIDER
											? 'active'
											: ''
									)}
								>
									{'Provider Info'}
								</span>
								<span>
									<Icon
										className='right-arrow-thin'
										icon='right-arrow-thin'
									/>
								</span>
								<span
									className={classnames(
										stage == STAGE_LINK_PROVIDER
											? 'active'
											: ''
									)}
								>
									{'Patient Level Link Info'}
								</span>
							</span>
						)}
					</span>
					<span
						className={classnames(
							'close-btn-wrapper',
							isLoadingData() ? 'isReadOnly' : ''
						)}
					>
						<span className='close-btn-icon' onClick={cancel}>
							<Icon icon='close' className='close-icon' />
						</span>
					</span>
				</span>
			}
			size='tiny'
			modal={true}
			closeOnPressEscape={true}
			closeOnClickModal={false}
			showClose={false}
			visible={visible}
			onCancel={cancel}
		>
			<Dialog.Body>
				<div className='provider-wrapper'>
					<Loading className='provider-loading' loading={isLoading()}>
						{stage == STAGE_ADD_EDIT_PROVIDER && (
							<ProviderForm
								initialValues={formData}
								onSubmit={handleProviderSubmit}
								{...{
									providers,
									selectedProviders,
									existingProvider,
									getProviders,
									providerSpecialties,
									providerCredentials,
									createProvider,
									creatingProvider,
									createdProviderError,
									updateProvider,
									updatingProvider,
									updatedProviderError,
									editingProvider,
									editProviderData,
									getSpecialty,
									gettingProviders,
									handleSearchingProvider,
									searchingProvider,
								}}
							/>
						)}
						{stage == STAGE_LINK_PROVIDER && (
							<ProviderLinkForm
								initialValues={{
									...formData,
									receiveAllComms,
								}}
								onSubmit={handleProviderLinkSubmit}
								goBack={goBack}
								linkedTo={linkedTo}
								editingLinkInfo={editingLinkInfo}
							/>
						)}
					</Loading>
				</div>
			</Dialog.Body>
		</Dialog>
	);
};

const mapStateToProps = (state) => ({
	creatingProvider: state.createProvider?.creatingProvider || false,
	createdProvider: state.createProvider?.createdProvider || null,
	createdProviderError: state.createProvider?.createdProviderError || null,
	updatingProvider: state.updateProvider?.updatingProvider || false,
	updatedProvider: state.updateProvider?.updatedProvider || null,
	updatedProviderError: state.updateProvider?.updatedProviderError || null,
	gettingProviderSpecialties:
		state.providerEnums?.gettingProviderSpecialties || false,
	providerSpecialties: state.providerEnums?.providerSpecialties || [],
	providerSpecialtiesError:
		state.providerEnums?.providerSpecialtiesError || null,
	gettingProviderCredentials:
		state.providerEnums?.gettingProviderCredentials || false,
	providerCredentials: state.providerEnums?.providerCredentials || [],
	providerCredentialsError:
		state.providerEnums?.providerCredentialsError || null,
	gettingProviders: state.providers?.loading || false,
	providers: state.providers?.data || [],
});

const mapDispatchToProps = (dispatch) => ({
	createProvider: (payload) => dispatch(createProvider(payload)),
	updateProvider: (providerUuid, payload) =>
		dispatch(updateProvider(providerUuid, payload)),
	getProviderSpecialties: () => dispatch(getProviderSpecialties()),
	getProviderCredentials: () => dispatch(getProviderCredentials()),
	getProviders: (data) => dispatch(getProviders(data)),
	resetProviderState: () => dispatch(resetProviderState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderLinkDialog);
