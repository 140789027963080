import * as actionTypes from './actionTypes';
import EnumsService from '../service/Enums';
import get from 'lodash/get';

export function getPlanTypes() {
	return (dispatch) => {
		dispatch(gettingPlanTypes());
		EnumsService.planTypesEnums()
			.then((res) => {
				dispatch(
					gotPlanTypes(
						get(res, ['data', 'data', 'insurance_plan_types'], [])
					)
				);
			})
			.catch((err) => {
				dispatch(gotPlanTypesError(err));
			});
	};
}

const gettingPlanTypes = () => {
	return {
		type: actionTypes.GETTING_PLAN_TYPES,
		payload: true,
	};
};

const gotPlanTypes = (data) => {
	return {
		type: actionTypes.GOT_PLAN_TYPES,
		payload: data,
	};
};

const gotPlanTypesError = (err) => {
	return {
		type: actionTypes.GOT_PLAN_TYPES_ERROR,
		payload: err,
	};
};
