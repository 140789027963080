import _ from 'lodash';
import moment from 'moment';

export const isEmpty = (value) => _([undefined, null, '']).includes(value);

export const required =
	(label, exact = false) =>
	(value) => {
		return !isEmpty(value)
			? undefined
			: exact
			? label
			: label
			? `${label} is required`
			: 'Required';
	};

export const notEmptyObject =
	(label, exact = false) =>
	(value) =>
		!_.isEmpty(value)
			? undefined
			: exact
			? label
			: label
			? `${label} is required`
			: 'Required';

export const MatchAddress = (label) => (value) =>
	!isEmpty(value)
		? /((?:[A-Za-z0-9-— '/,.()!@$%#]+$)*[a-zA-Z0-9])/.test(value)
			? undefined
			: `${label} is Invalid`
		: undefined;

export const MatchCity = (label) => (value) =>
	!isEmpty(value)
		? /^[a-zA-Z]+[a-zA-Z.]+(?:[\s-][a-zA-Z]+[a-zA-Z.]+)*$/.test(value)
			? undefined
			: `${label} is Invalid`
		: undefined;
export const email = (value) =>
	/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? undefined
		: 'Invalid e-mail address';

export const optionalEmail = (value) => (value ? email(value) : undefined);

export const phone = (value) =>
	value.replace(/[^\d]/g, '').length === 11
		? undefined
		: 'Invalid phone number';

export const phoneOrEmpty = (value) => (value ? phone(value) : undefined);

export const dateOrEmpty =
	(message, format = 'YYYY-MM-DD') =>
	(value) =>
		value && value != '' ? validdate(value, format, message) : undefined;

export const validdate = (value, format, message) => {
	return moment(value, format, true).isValid()
		? undefined
		: message || 'Invalid Date';
};

export const validPastDate = (value, format, message) => {
	return moment(value, format, true).isValid() &&
		moment(value, format, true).isBefore(moment())
		? undefined
		: message || 'Invalid Date';
};

export const validPastDateNoMessage = (value, format) => {
	return (
		moment(value, format, true).isValid() &&
		moment(value, format, true).isBefore(moment())
	);
};

export const validNonEmptyDate =
	(message = 'Invalid DOB', format = 'MM/DD/YYYY') =>
	(value) =>
		validPastDate(value, format, message);

export const optionRequired = (label) => (option) =>
	required(label)(option && option.value !== undefined);

export const validName = (label) => (value) =>
	/^[A-Za-z .]+$/.test(value)
		? undefined
		: `${label || 'The field'} must contain only letters and dots.`;

export const strongPassword = (value) =>
	!value ||
	value.length < 8 ||
	!value.match(/\d/) ||
	!value.match(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/) ||
	!value.match(/[A-Z]/) ||
	!value.match(/[a-z]/)
		? 'Password must be at least 8 characters long including at least one uppercase letter, one lowercase letter, one digit and one special character.'
		: undefined;

export const validZipCode = (label) => (value) =>
	!isEmpty(value)
		? /^\b\d{5}(-\d{4})?\b$/.test(value)
			? undefined
			: `${label || 'Zip code'} must be a valid US 5 or 5+4 digit code.`
		: undefined;

export const matchPassword = (values) =>
	values.password && values.password !== values.passwordRepeat
		? { passwordRepeat: "Passwords don't match" }
		: {};

export const descriptionText = (value) =>
	!value || /^[\w.,!?"'’|/()&$ :;\-+%\s]+$/.test(value)
		? undefined
		: 'The field must contain only letters, numbers and punctuation marks';

export const questionnaireText = (value) =>
	!value || /^[\w.,!?"'’|/()&$ :;\-+%\s]+$/.test(value)
		? undefined
		: 'The field must contain only letters, numbers and punctuation marks';

export const referText = (value) =>
	!value || /^[\w.,!?"'’|/()&$:;\-+%@#\s]+$/.test(value)
		? undefined
		: 'The field can contain only letters, numbers and punctuation marks';

export const assistantText = (value) =>
	!value || /^[\w.,!?"'’|/()&$:;\-+%@#\s]+$/.test(value)
		? undefined
		: 'The field can contain only letters, numbers and punctuation marks';

export const shouldBeTrue = (value) =>
	value === true ? undefined : 'Required';

export const validDob = (value) => {
	const wrongDay =
		"The day doesn't look right. Be sure to use a 2-digit number that is a day of the month.";
	if (_.isEmpty(value)) return 'Date of birth is required';
	if (typeof value === 'string') return undefined; // the string is some value from the backend in future
	const month = value.month;
	if (typeof month !== 'number') return 'Month is required';
	const year = parseInt(value.year);
	if (isNaN(year) || year < 1000 || year > 9999)
		return "The year doesn't look right. Be sure to use four digits.";
	const day = parseInt(value.day);
	if (isNaN(day) || day < 1 || day > 31) return wrongDay;
	const date = moment([year, month, day]);
	if (!date.isValid()) return wrongDay; // 31 february and cases like that
	return dateOfBirth(
		"The date doesn't look right. Be sure to use your actual date of birth."
	)([year, month, day]);
};

export const dateOfBirth = (message) => (value) => {
	const msg = message || "The date doesn't look right";
	const date = moment(value);
	if (
		!date.isValid() ||
		date.isBefore(moment().add('year', -120)) ||
		date.isAfter(moment().add('year', -1))
	) {
		return msg;
	}
	return undefined;
};

export const fileName = (value) =>
	!value || /^[\w.,!?"'’|()&$:;\-+%\s[\]]+$/.test(value)
		? undefined
		: 'The field must contain only letters, numbers and punctuation marks';

export const ChooseOneCheckbox = (values) =>
	!(
		values.uninterpretedGenomicData ||
		values.possibleMedicallyActionableFindings
	)
		? {
				possibleMedicallyActionableFindings: 'Please make a selection.',
		  }
		: {};

export const validateCardNumber = (value) => {
	if (
		/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/.test(
			value.replace(/\s+/g, '')
		)
	) {
		return undefined;
	} else {
		return 'card number format is invalid';
	}
};

export const isSelfPayText = (text) => {
	return (
		!_.isEmpty(text) &&
		text.replace(/[^a-zA-Z]/g, '').toLowerCase() == 'selfpay'
	);
};

export const invalidInsuranceCompany = (ignore, value) => {
	return ignore
		? undefined
		: !isSelfPayText(value)
		? undefined
		: 'Invalid company name';
};

export const EmailValidator = {
	validate: (email, lengthOverride) => {
		return (
			email &&
			typeof email === 'string' &&
			email.length <= (lengthOverride || 140) &&
			email.match(/^[a-zA-Z0-9._%+-]+@[-\w]+(\.\w+)+$/gi)
		);
	},
};
