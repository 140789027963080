import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import {
	ROLE_GCA,
	ROLE_CARECOORDINATOR,
	ROLE_PRODENG,
} from '../../../../../Utils/permissionUtils';
import {
	excludeConsentViaPartner,
	PatientConsentStatus
} from '../../../../../utils';
import Dropdown from '../../../../Common/FormFields/Dropdown';
import CheckboxField from '../../../../Common/FormFields/CheckboxField';
import {
	getConsenttotreat,
} from '../../../../../actions/patients';

import './PatientConsentSection.scss';
import { useSection } from '../sections/useSection';

/**
 * Patient Consent subsection of Additional Patient Info section
 */
export function PatientConsentSection({
	isEdit,
	patientDetail,
	sectionDataContainerName,
	activeRole,
}) {
	const { values, touched, errors, onChange, setFieldValue } = useSection(
		sectionDataContainerName
	);
	const { consentToTreatEnum } = useSelector(
		(store) => ({
			consentToTreatEnum: store.getConsenttotreat?.consenttotreat?.consent_to_treat ?? []
		})
	);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getConsenttotreat());
	}, []);

	useEffect(() => {
		if (patientDetail && isEdit) {
			setFieldValue('consentToTreat', patientDetail.consent_to_treat);
			setFieldValue('checkIfConsentToText', (patientDetail?.latestPatientToRecontactStatusObject?.patient_consent?.status || null) === PatientConsentStatus.CONSENTED);			
		}
	}, [patientDetail]);

	const userRoleIsCCGCOrProdeng = () => {
		return [ROLE_GCA, ROLE_CARECOORDINATOR, ROLE_PRODENG].includes(activeRole);
	};
	const authorizationToRecontactIsConsented = (patientDetail?.latestPatientToRecontactStatusObject?.patient_consent?.status || null) === PatientConsentStatus.CONSENTED;
	const authorizationToRecontactConsentVersion = patientDetail?.latestPatientToRecontactStatusObject?.consent_version || null;
	
	return (
		<>
			<h3>Consent</h3>
			<p>
				Please select the patient’s appropriate consent status.
			</p>
			<div className='consent-to-treat-dropdown-wrapper'>
				<Dropdown
					required
					label='Consent to Treat'
					name='consentToTreat'
					clearable
					options={consentToTreatEnum.map((item) => ({
						label: item.display_name,
						value: item.key,
					}))}
					error={touched.consentToTreat && errors.consentToTreat}
					onChange={onChange('consentToTreat')}
					value={values.consentToTreat}
					className='consent-to-treat'
				/>				
			</div>
			{userRoleIsCCGCOrProdeng() && <div className='patient-auth-to-recontact-wrapper'>
				<CheckboxField
					name='checkIfConsentToText'
					label={`Patient Authorization to Recontact ${authorizationToRecontactIsConsented ? '('+ authorizationToRecontactConsentVersion +')' : ''}`}
					disabled={!authorizationToRecontactIsConsented}
					value={values.checkIfConsentToText}
					onChange={onChange('checkIfConsentToText')}					
				/>
				<span className={classnames('consent-to-text-description', { 'consent-to-text-description-disabled': !authorizationToRecontactIsConsented})}>
					Patient consents to Genome Medical using and sharing their Health Information as described in the Patient Authorization to Recontact form.
				</span>
			</div>}
		</>
	);
}