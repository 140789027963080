import React, { useState } from 'react';
import { ModalWindow } from '../../../../Common/V3/ModalWindow';

export function ConsentVerbiage() {
	const [showConsentVerbiage, setShowConsentVerbiage] = useState(false);

	const verbiageHandler = () => {
		setShowConsentVerbiage(false);
	};

	return (
		<>
			<span
				className='purpleText show-hide-button'
				onClick={() => setShowConsentVerbiage(true)}
			>
				View Consent Verbiage
			</span>
			{showConsentVerbiage && (
				<ModalWindow
					onCancel={verbiageHandler}
					withIcon={false}
					title='Consent to Treat Verbiage'
				>
					<p>
						Genome Medical offers web-based telegenetics services.
						In order to participate in Genome Medical’s telegenetics
						services you must provide your consent for treatment by
						Genome Medical and agree to Genome Medical’s Notice of
						Privacy Practices. You can find the full document at{' '}
						<a href='https://portal.genomemedical.com/privacy'>
							portal.genomemedical.com/privacy
						</a>
						{'. '}
						You can also request that a copy be emailed to you.
						Please refer to this notice if you have questions
						regarding how we protect your health information.
					</p>
				</ModalWindow>
			)}
		</>
	);
}

export default ConsentVerbiage;
