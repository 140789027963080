//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import launchIcon from './../../../../assets/launch.svg';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Other Libraries
import moment from 'moment';

//Components
import Tooltip from '../../../Common/Tooltip';

class DuplicatePatientRecord extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.getDOB = this.getDOB.bind(this);
		this.getName = this.getName.bind(this);
		this.handlePatientRedirection =
			this.handlePatientRedirection.bind(this);
		this.determineWidthOverflow = this.determineWidthOverflow.bind(this);
	}
	//functions

	componentDidMount() {
		this.forceUpdate();
	}

	getDOB() {
		const data = get(this, 'props.data', {}) || {};
		return moment(get(data, 'dob', null)).isValid()
			? moment(get(data, 'dob', null)).format('MM/DD/YYYY')
			: 'DOB Unknown';
	}

	getName() {
		const data = get(this, 'props.data', {}) || {};
		return (
			get(data, 'first_name', '--') + ' ' + get(data, 'last_name', '--')
		);
	}

	handlePatientRedirection() {
		const data = get(this, 'props.data', {}) || {};
		const patientUuid = get(data, 'uuid', null);
		const pathName = `/ui/app/patientdetail/${patientUuid}/0/0`;
		window.open(pathName, '_blank');
	}

	determineWidthOverflow(el) {
		if (_.isElement(el) && el) {
			const offsetWidth = get(el, 'offsetWidth', 0);
			const scrollWidth = get(el, 'scrollWidth', 0);
			if (scrollWidth > offsetWidth) return false;
			else return true;
		} else {
			return false;
		}
	}

	render() {
		const data = get(this, 'props.data', {}) || {};
		const redColor = '#e74c3c';
		const grayColor = '#7b8291';
		let nameColor;
		if (get(this, 'props.formPatientUnbornSelection', null)) {
			nameColor =
				(
					'Baby' +
					(get(this, 'props.formPatientGuardianLastName', '') || '')
				).toLowerCase() ===
				(
					get(data, 'first_name', '--') + get(data, 'last_name', '--')
				).toLowerCase()
					? redColor
					: '#262837';
		} else {
			nameColor =
				(
					(get(this, 'props.formPatientFirstName', '') || '') +
					(get(this, 'props.formPatientLastName', '') || '')
				).toLowerCase() ===
				(
					get(data, 'first_name', '--') + get(data, 'last_name', '--')
				).toLowerCase()
					? redColor
					: '#262837';
		}
		const dobColor =
			get(this, 'props.formPatientDob', '') === this.getDOB()
				? redColor
				: grayColor;
		const emailColor =
			!get(this, 'props.formPatientNoEmail', false) &&
			(get(this, 'props.formPatientEmail', '') || '').toLowerCase() ===
				(get(data, 'email', '--') || '--').toLowerCase()
				? redColor
				: grayColor;

		return (
			<div
				className='duplicatePatientTooltipRecordContainer'
				onClick={(e) => this.handlePatientRedirection()}
			>
				<div className='duplicatePatientTooltipRecordInfoContainer'>
					<Tooltip
						className='duplicatePatientTooltipOverflowTooltip'
						effect='dark'
						content={this.getName()}
						placement='bottom'
						disabled={this.determineWidthOverflow(this.nameText)}
					>
						<div
							className='duplicatePatientTooltipRecordInfoName'
							style={{ color: nameColor }}
							ref={(el) => (this.nameText = el)}
						>
							{this.getName()}
						</div>
					</Tooltip>
					<Tooltip
						className='duplicatePatientTooltipOverflowTooltipOther'
						effect='dark'
						content={get(data, 'email', '--')}
						placement='top'
						disabled={this.determineWidthOverflow(this.emailText)}
					>
						<div
							className='duplicatePatientTooltipRecordInfoOther duplicatePatientTooltipRecordInfoEmail'
							style={{ color: emailColor }}
							ref={(el) => (this.emailText = el)}
						>
							{get(data, 'email', '--')}
						</div>
					</Tooltip>
					<div
						className='duplicatePatientTooltipRecordInfoOther'
						style={{ color: dobColor }}
					>
						{this.getDOB()}
					</div>
				</div>
				<div className='duplicatePatientTooltipRecordLaunchIconContainer'>
					<ReactSVG
						src={launchIcon}
						className='duplicatePatientTooltipRecordLaunchIcon'
					/>
				</div>
			</div>
		);
	}
}

export default DuplicatePatientRecord;
