//React & Redux
import React, { Fragment } from 'react';

//Lodash
import { isEmpty, isNil, size } from 'lodash';
import moment from 'moment';

//UI Libraries
import { Layout, Form, Button, Popover } from 'gm-element-react';

//Images
import ReactSVG from 'react-svg';
import editIcon from '../../../../assets/edit.svg';
import moreinfoicon from './../../../../assets/purple-more-info.svg';

//Styles
import './css/view-structural-variant.css';

//Utils
import { CARE_PATIENTS_ENTER_TEST_RESULT } from '../../../../Utils/permissionUtils';
import {
	labClassificationEnum,
	structuralVariantUnitEnum,
	structuralVariantTypeEnum,
} from '../../../../utils';

//Other Libraries
import classnames from 'classnames';

//Components
import RBAC from '../../../RBAC';
import NotesContainer from '../../../Common/Notes/NotesContainer';
import Pill from '../../../Common/Pill';

const ViewStructuralVariant = (props) => {
	const {
		addDiagnosticFindingClinicalNoteError = null,
		addDiagnosticFindingClinicalNoteLoading = false,
		addNewDiagnosticFindingClinicalNote,
		finding,
		showAddEditStructuralVariantModalFunc,
		showViewStructuralVariantFullDetailFunc,
	} = props;

	const uuid = finding?.uuid || null;
	const notes = finding?.notes || [];
	const hasCytogenomicNomenclature =
		!isNil(finding?.cytogenomicNomenclature) &&
		!isEmpty(finding?.cytogenomicNomenclature);
	const hasCytogenomicCharacterization =
		!isNil(finding?.cytogenomicCharacterization) &&
		!isEmpty(finding?.cytogenomicCharacterization);
	const isDisplayGeneticExtents = size(finding?.geneticExtents || []) === 1;
	const isDisplayChromosomalExtents =
		size(finding?.chromosomalExtents || []) > 0 &&
		size(finding?.geneticExtents || []) !== 1;
	const getLatestInterpretationText = () => {
		let latestInterpretationText = '--';
		const interpretedOn = moment(
			finding?.mostRecentInterpretation?.interpretedOn || null
		).isValid()
			? moment(
					finding?.mostRecentInterpretation?.interpretedOn || null
			  ).format('MM.DD.YYYY')
			: '';
		let mostRecentInterpretationText = '--';

		if (!isNil(finding?.mostRecentInterpretation?.classification)) {
			mostRecentInterpretationText = labClassificationEnum.find(
				(o) =>
					o.key === finding?.mostRecentInterpretation?.classification
			);
			mostRecentInterpretationText =
				mostRecentInterpretationText?.display_name;
			latestInterpretationText =
				mostRecentInterpretationText + ' | ' + interpretedOn;
		}

		return latestInterpretationText;
	};

	const getLatestInterpretationTooltip = () => {
		let latestInterpretationTooltip = '';

		if (!isNil(finding?.mostRecentInterpretation?.classification || null)) {
			latestInterpretationTooltip =
				finding?.mostRecentInterpretation?.source?.name;
		}
		return latestInterpretationTooltip;
	};

	const getStructuralVariantHeader = () => {
		let structuralVariantHeader = 'Structural Variant Finding';
		if (size(finding?.geneticExtents || []) == 1) {
			structuralVariantHeader = `Structural Variant Finding - ${finding?.geneticExtents[0].gene?.symbol}`;
			return structuralVariantHeader;
		} else if (
			size(finding?.chromosomalExtents) > 0 &&
			!isNil(finding?.chromosomalExtents[0]?.cytogeneticLocation)
		) {
			structuralVariantHeader = `Structural Variant Finding - ${finding?.chromosomalExtents[0].cytogeneticLocation}`;
			return structuralVariantHeader;
		} else if (
			size(finding?.chromosomalExtents) > 0 &&
			!isNil(finding?.chromosomalExtents[0]?.chromosome)
		) {
			structuralVariantHeader = `Structural Variant Finding - ${finding?.chromosomalExtents[0]?.chromosome}`;
			return structuralVariantHeader;
		}
		return structuralVariantHeader;
	};

	const isShowUCSCGenomeBrowserLink = () => {
		if (size(finding?.chromosomalExtents) > 0) {
			return true;
		}
		return false;
	};

	const getUCSCGenomeBrowserLink = () => {
		let UCSCGenomeBrowserLink =
			'https://genome.ucsc.edu/cgi-bin/hgTracks?position=chr1';
		const chromosome = finding?.chromosomalExtents[0]?.chromosome;
		const chromosomalExtentStartPosition =
			finding?.chromosomalExtents[0]?.startPosition;
		const chromosomalExtentEndPosition =
			finding?.chromosomalExtents[0]?.endPosition;
		const cytogeneticLocation =
			finding?.chromosomalExtents[0]?.cytogeneticLocation;
		const chromsome = finding?.chromosomalExtents[0]?.chromsome || '1';

		if (
			!isNil(chromosomalExtentStartPosition) &&
			!isNil(chromosomalExtentEndPosition)
		) {
			UCSCGenomeBrowserLink = `https://genome.ucsc.edu/cgi-bin/hgTracks?position=chr${chromosome}%3A${chromosomalExtentStartPosition}-${chromosomalExtentEndPosition}`;
		} else if (!isNil(cytogeneticLocation)) {
			UCSCGenomeBrowserLink = `https://genome.ucsc.edu/cgi-bin/hgTracks?position=${cytogeneticLocation}`;
		} else if (
			isNil(chromosomalExtentStartPosition) &&
			isNil(chromosomalExtentEndPosition) &&
			isNil(cytogeneticLocation)
		) {
			UCSCGenomeBrowserLink = `https://genome.ucsc.edu/cgi-bin/hgTracks?position=chr${chromsome}`;
		}
		return UCSCGenomeBrowserLink;
	};

	const getChromosomalExtents = () => {
		const cytogeneticLocation =
			finding?.chromosomalExtents?.[0]?.cytogeneticLocation;
		const chromosome = finding?.chromosomalExtents?.[0]?.chromosome;
		const startPosition = finding?.chromosomalExtents?.[0]?.startPosition;
		const endPosition = finding?.chromosomalExtents?.[0]?.endPosition;
		let chromosomalExtentsLabel = '';

		if (cytogeneticLocation && startPosition && endPosition) {
			chromosomalExtentsLabel = `${cytogeneticLocation}, ${startPosition}-${endPosition}`;
		} else if (cytogeneticLocation && !startPosition && !endPosition) {
			chromosomalExtentsLabel = cytogeneticLocation;
		} else if (!cytogeneticLocation && startPosition && endPosition) {
			chromosomalExtentsLabel = `Chr ${chromosome}, ${startPosition}-${endPosition}`;
		} else if (!cytogeneticLocation && !startPosition && !endPosition) {
			chromosomalExtentsLabel = `Chr ${chromosome}`;
		}
		return chromosomalExtentsLabel;
	};

	const getSize = () => {
		let size = '--';
		let unit = '';
		if (!isEmpty(finding?.sizeValue)) {
			unit = structuralVariantUnitEnum.find(
				(o) => o.key === finding?.unit
			);
			size = `${finding?.sizeValue || ''} ${
				(!isNil(finding?.unit) &&
					!isEmpty(finding?.unit) &&
					unit?.display_name) ||
				''
			}`;
		}
		return size;
	};

	const getVariantType = () => {
		let variantType = '';
		variantType = structuralVariantTypeEnum.find(
			(o) => o.key === finding?.variantType
		);
		return variantType?.display_name || '';
	};

	const getGeneticExtents = () => {
		const geneSymbol = finding?.geneticExtents?.[0]?.gene?.symbol;
		const startExon = finding?.geneticExtents?.[0]?.startExon;
		const endExon = finding?.geneticExtents?.[0]?.endExon;
		const entireCodingRegion =
			finding?.geneticExtents?.[0]?.entireCodingRegion;
		let geneticExtentsLabel = '';

		if (startExon && endExon) {
			geneticExtentsLabel = `${geneSymbol}, Exons ${startExon}-${endExon}`;
		} else if (entireCodingRegion) {
			geneticExtentsLabel = `${geneSymbol}, Entire coding region`;
		} else {
			geneticExtentsLabel = geneSymbol;
		}
		return geneticExtentsLabel;
	};

	return (
		<Fragment>
			<Form className='view-structural-variant-info'>
				<Layout.Row gutter='20' className='structural-variant-title'>
					<Layout.Col>
						{getStructuralVariantHeader()}
						{finding?.assessment === 'ABSENT' && (
							<Pill
								pillText='Not Detected'
								pillExtendStyle='not-detected-pill'
							/>
						)}
					</Layout.Col>
				</Layout.Row>
				<Layout.Row gutter='32' className='clinical-comments-container'>
					<Layout.Col
						span='16'
						className='structural-variant-result-section'
					>
						<Layout.Row className='result-details-label'>
							<Layout.Col span='17'>Result Details</Layout.Col>
							{isShowUCSCGenomeBrowserLink() && (
								<Layout.Col span='7'>
									<a
										href={getUCSCGenomeBrowserLink()}
										target='_blank'
										className='link-UCSC-genome-browser'
										rel='noreferrer'
									>
										UCSC Genome Browser
									</a>
								</Layout.Col>
							)}
						</Layout.Row>

						{hasCytogenomicNomenclature && (
							<Layout.Row>
								<Layout.Col span='24'>
									<label htmlFor='cytogenomicSequence'>
										Cytogenomic Sequence
									</label>
									<span className='field-val'>
										{finding?.cytogenomicNomenclature || ''}
									</span>
								</Layout.Col>
							</Layout.Row>
						)}
						{hasCytogenomicCharacterization && (
							<Layout.Row className='structural-variant-section-bottom-border'>
								<Layout.Col span='24'>
									<label htmlFor='cytogenomicCharacterization'>
										Cytogenomic Characterization
									</label>
									<span className='field-val'>
										{finding?.cytogenomicCharacterization ||
											''}
									</span>
								</Layout.Col>
							</Layout.Row>
						)}

						<Layout.Row>
							{isDisplayChromosomalExtents && (
								<Layout.Col span='12'>
									<label htmlFor='chromosomalExtents'>
										Chromosomal Extent(s)
									</label>
									<span className='field-val'>
										{getChromosomalExtents()}
									</span>
								</Layout.Col>
							)}
							{isDisplayGeneticExtents && (
								<Layout.Col span='12'>
									<label htmlFor='geneticExtents'>
										Genetic Extent
									</label>
									<span className='field-val'>
										{getGeneticExtents()}
									</span>
								</Layout.Col>
							)}
						</Layout.Row>

						<Layout.Row className='structural-variant-section-bottom-border'>
							<Layout.Col span='12'>
								<label htmlFor='copyNumber'>Copy Number</label>
								<span className='field-val'>
									{finding?.copyNumber ?? '--'}
								</span>
							</Layout.Col>
							<Layout.Col span='12'>
								<label htmlFor='size'>size</label>
								<span className='field-val'>{getSize()}</span>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row>
							<Layout.Col span='12'>
								<label htmlFor='variantType'>
									Variant Type
								</label>
								<span className='field-val'>
									{getVariantType()}
								</span>
							</Layout.Col>
							<Layout.Col span='12'>
								<label htmlFor='latestInterpretation'>
									Latest Interpretation
									{!isEmpty(
										getLatestInterpretationTooltip()
									) && (
										<Popover
											trigger='hover'
											popperClass={classnames(
												'pp-latest-interpretation'
											)}
											content={
												<Fragment>
													{getLatestInterpretationTooltip()}
												</Fragment>
											}
											key='popover-latest-interpretation'
											placement='top'
										>
											<span className='tooltip-info'>
												<img
													src={moreinfoicon}
													className='more-info-icon'
												/>
											</span>
										</Popover>
									)}
								</label>
								<span className='field-val'>
									{getLatestInterpretationText()}
								</span>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row>
							<Layout.Col span='18'>
								<Button
									className='structural-variant-view-full-details'
									type='text'
									onClick={() =>
										showViewStructuralVariantFullDetailFunc(
											finding
										)
									}
								>
									View full details
								</Button>
							</Layout.Col>
							<Layout.Col span='6'>
								<RBAC
									action={CARE_PATIENTS_ENTER_TEST_RESULT}
									yes={
										<Button
											className='edit-detail-button'
											onClick={() =>
												showAddEditStructuralVariantModalFunc(
													'edit',
													finding
												)
											}
										>
											<ReactSVG
												src={editIcon}
												className='edit-detail-icon'
											/>
											Edit Original
										</Button>
									}
								/>
							</Layout.Col>
						</Layout.Row>
					</Layout.Col>

					<Layout.Col span='8'>
						<span className='clinical-comments-label'>
							Clinical Comments
						</span>
						<NotesContainer
							notes={notes}
							variant='cfDNA'
							addNoteLoading={
								addDiagnosticFindingClinicalNoteLoading
							}
							addNoteError={addDiagnosticFindingClinicalNoteError}
							addNote={addNewDiagnosticFindingClinicalNote}
							sourceUUID={uuid}
							addNoteModalTitle='New Comment — Structural Variant'
							emptyMessageClass='empty-clinical-comments-msg'
							isShowListIcon={false}
							hasRBACAddComment={true}
							RBACAddCommentAction={
								CARE_PATIENTS_ENTER_TEST_RESULT
							}
						/>
					</Layout.Col>
				</Layout.Row>
			</Form>
		</Fragment>
	);
};

export default ViewStructuralVariant;
