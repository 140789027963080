//React & Redux
import React, { useState, useRef, useEffect } from 'react';
import Autocomplete from 'react-autocomplete';

//UI Libraries
import { Button } from 'gm-element-react';
import ReactSVG from 'react-svg';
import searchIcon from '../../../assets/ic_search.svg';

//Styles
import '../css/document-notifications.css';

const AddRecipient = ({ externalProviderUsers, handleUpdateRecipient }) => {
	const [searchInput, setSearchInput] = useState('');
	const [selectedUserUUIDs, setSelectedUserUUIDs] = useState([]);
	const [filteredSuggestions, setFilteredSuggestions] = useState([]);
	const [filteredExternalProviderUsers, setFilteredExternalProviderUsers] =
		useState([]);

	const scrollableMenuRef = useRef(null);

	useEffect(() => {
		const initialSelectedUsers = externalProviderUsers
			.filter(
				(providerUser) =>
					providerUser.is_default_doc_release_recipient === true
			)
			.map((providerUser) => providerUser.user.uuid);

		setSelectedUserUUIDs(initialSelectedUsers);

		const filteredUsers = externalProviderUsers.filter(
			(providerUser) => !providerUser.is_default_doc_release_recipient
		);
		setFilteredExternalProviderUsers(filteredUsers);
	}, [externalProviderUsers]);

	useEffect(() => {
		const initialSuggestions = externalProviderUsers;
		setFilteredSuggestions(initialSuggestions);
	}, [externalProviderUsers]);

	const handleInput = (e) => {
		const inputValue = e.target.value;
		setSearchInput(inputValue);

		const filteredSuggestions = filteredExternalProviderUsers.filter(
			(user) => {
				const fullName = `${user.user.first_name} ${user.user.last_name}`;
				const email = user.user.email;
				return (
					(fullName
						.toLowerCase()
						.includes(inputValue.toLowerCase()) ||
						email
							.toLowerCase()
							.includes(inputValue.toLowerCase())) &&
					!selectedUserUUIDs.includes(user.user.uuid)
				);
			}
		);

		setFilteredSuggestions(filteredSuggestions);
	};

	const handleSelect = (value) => {
		const selectedUser = filteredExternalProviderUsers.find((user) => {
			const fullName = `${user.user.first_name} ${user.user.last_name}`;
			const email = user.user.email;
			return (
				`${fullName} | ${email}` === value &&
				!selectedUserUUIDs.includes(user.user.uuid)
			);
		});

		if (selectedUser) {
			setSelectedUserUUIDs((prevSelectedUsers) => [
				...prevSelectedUsers,
				selectedUser.user.uuid,
			]);

			setFilteredExternalProviderUsers((prevFilteredUsers) => [
				...prevFilteredUsers,
				{
					user: selectedUser.user,
					is_default_doc_release_recipient: true,
				},
			]);
		}
	};

	const renderInputField = (props) => {
		return (
			<div className='autocomplete-input-container'>
				<input
					{...props}
					className='inputField inputFieldText autocomplete-input-field'
					placeholder='Search by name or email'
				/>
				<ReactSVG
					src={searchIcon}
					className='doc-notification-recipients-search-icon'
				/>
			</div>
		);
	};

	const renderMenu = (items) => {
		return (
			<div className='autocomplete-menu' ref={scrollableMenuRef}>
				{items}
			</div>
		);
	};

	const renderItem = (item, isHighlighted) => {
		const itemClassName = isHighlighted
			? 'autocomplete-item-highlighted'
			: 'autocomplete-item';

		return (
			<div key={item} className={itemClassName}>
				{item}
			</div>
		);
	};

	const suggestions = filteredSuggestions.map((user) => {
		return `${user.user.first_name} ${user.user.last_name} | ${user.user.email}`;
	});

	return (
		<div>
			<div className='top-text-div'>
				Search for a user by email or name. Users must exist in GMIP.
			</div>
			<div className='auto-complete-div'>
				<Autocomplete
					value={searchInput}
					getItemValue={(item) => item}
					onChange={handleInput}
					onSelect={handleSelect}
					items={suggestions}
					renderItem={renderItem}
					renderInput={renderInputField}
					renderMenu={renderMenu}
				/>
			</div>
			<div className='table-div'>
				<table className='user-table'>
					<thead>
						<tr>
							<th className='table-header'>Name</th>
							<th className='table-header'>Email</th>
						</tr>
					</thead>
					<tbody>
						{selectedUserUUIDs.map((uuid) => {
							const user = externalProviderUsers.find(
								(user) => user.user.uuid === uuid
							);

							if (user) {
								return (
									<tr key={uuid} className='table-row'>
										<td className='table-cell'>
											{user.user.first_name +
												' ' +
												user.user.last_name}
										</td>
										<td className='table-cell'>
											{user.user.email}
										</td>
									</tr>
								);
							}

							return '';
						})}
					</tbody>
				</table>
			</div>
			<div className='el-dialog__footer'>
				<Button
					disabled={false}
					onClick={() => {
						handleUpdateRecipient(selectedUserUUIDs);
					}}
					className='confirm-button'
				>
					Save
				</Button>
			</div>
		</div>
	);
};

export default AddRecipient;
