//React & Redux
import React, { useState, useEffect, useRef } from 'react';

//Images
import ReactSVG from 'react-svg';
import whiteEditIcon from './../../../../../assets/edit.svg';

//Other Libraries
import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';
import get from 'lodash/get';
import classnames from 'classnames';

//UI Libraries
import { Button, Loading, Dialog } from 'gm-element-react';

//Components
import { ApiErrorModal } from './ApiErrorModal';

//Utils
import {
	createEnumsOptions,
	isValidDateTimeString,
	isValidDateStringOREmpty,
	GetSelectvalue,
	visitIndicationsFilterEnum,
	visitIndicationsCheckboxEnum,
	getVisitIndicationTagDisplay,
	addVisitIndicationDisplayNames,
	payorSelectOption,
} from '../Common/utilsfunctions';
import { isEmpty } from '../../../../../Utils/validate';
import {
	GetZoneDatetime,
	EncounterTabs,
	states_list,
	provincesList,
	countriesList,
} from '../../../../../utils';

//Components
import { EncounterDateTimeField } from '../Common/EncounterDateTimeField';
import { EncounterSelectField } from '../Common/EncounterSelectField';
import { EncounterDateField } from '../Common/EncounterDateField';
import { EncounterMultiSelectField } from '../Common/EncounterMultiSelectField';
import { EncounterTextAreaField } from '../Common/EncounterTextAreaField';
import { EncounterInputField } from '../Common/EncounterInputField';
import { EncounterRadioGroupField } from '../Common/EncounterRadioGroupField';
import ChargeModal from './../../../ChargeInformation/ChargeModal.js';
import ICDTag from '../../../ChargeInformation/ICDTag';
import ListModal from './../../../../Common/ListModal.js';

//Styles
import './add-multi-visitencounter.css';

const datefields = {
	initial_cap_completed_date: 1,
	visit_summary_release_date_pcp: 1,
	followup_cap_completed_date: 1,
	cap_sent_date_pcp: 1,
	cap_sent_to_patient: 1,
	initial_visit_summary_sent: 1,
	patient_fee_refund_processed_date: 1,
};
const datetimefields = {
	date_of_service: 1,
};

const AddManualVisitEncounter = (props) => {
	const {
		callvisitsschema,
		fetchOrderingPhysician,
		fetchVisitProvider,
		getAllVisitIndications,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		getICDCodes,
		enqueueSnackbar,
		visitIndicationsEnum,
		visitIndications,
		patientdetail,
		callAddManualVisitEncouter,
		changeEncounterTab,
		cancel,
		visitsschema,
		referralPrograms,
		getAllReferralPrograms,
	} = props;

	const tempFormData = {
		scheduling_poc: '',
		timezone: '',
		country_of_visit: 'US',
		state_of_visit: '',
		date_of_service: '',
		tm_platform: '',
		consultation_type: '',
		visit_provider_uuid: '',
		visit_status: 'completed',
		vsee_specialty: '',
		initial_cap_completed_date: '',
		followup_cap_completed_date: '',
		initial_visit_summary_sent: '',
		visit_summary_release_method_patient: [],
		cap_sent_to_patient: '',
		cap_release_method_patient: [],
		visit_summary_release_date_pcp: '',
		visit_summary_release_method_pcp: [],
		cap_sent_date_pcp: '',
		cap_release_method_pcp: [],
		medical_codes: [],
		records_requested: '',
		records_received: '',
		provider_indicated_specialty: '',
		supervising_physician: null,

		pharmd: null,
		drug_interaction: null,
		no_of_interactions: null,
		drug_contraindications: null,
		drug_dosage_adjustment_recommended: null,

		payor: '',
		coupon_code: '',
		patient_pay: '',
		billing_status: 'na',
		insurance_claim_status: '',
		billing_notes: '',

		notes: '',
	};
	const sectionRefs = useRef({});
	const [formData, setFormData] = useState(tempFormData);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [showChargeModal, setShowChargeModal] = useState(false);
	const [chargeModalEmptyAllowed, setChargeModalEmptyAllowed] =
		useState(false);
	const [selectedICDCodes, setSelectedICDCodes] = useState([]);
	const [touched, setTouched] = useState({});
	const [selectedVisitIndications, setSelectedVisitIndications] =
		useState(null);
	const [showVisitIndicationsModal, setShowVisitIndicationsModal] =
		useState(false);
	const [
		visitIndicationsModalEmptyAllowed,
		setVisitIndicationsModalEmptyAllowed,
	] = useState(false);
	const [showVisitIndicationsListModal, setShowVisitIndicationsListModal] =
		useState(false);
	const [confirmSubmit, setConfirmSubmit] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [apiError, setApiError] = useState(null);

	useEffect(() => {
		callvisitsschema();
		fetchOrderingPhysician();
		fetchVisitProvider();
		getAllVisitIndications();
		getAllReferralPrograms();
	}, []);

	const renderICDCodes = () => {
		let medicalCodes = [...selectedICDCodes];
		medicalCodes = medicalCodes.slice().sort((a, b) => {
			if (_.get(a, 'code_name', '') < _.get(b, 'code_name', ''))
				return -1;
			else return 1;
		});
		if (medicalCodes.length > 0) {
			return medicalCodes.map((el, i) => {
				const button =
					i === medicalCodes.length - 1 ? (
						<div
							className='icdEditIcon'
							onClick={showChargeModalEmptyAllowed}
						>
							<ReactSVG
								src={whiteEditIcon}
								className='icdEditIconSVG'
							/>
							<span
								style={{ position: 'relative', right: '7px' }}
							>
								Edit
							</span>
						</div>
					) : (
						''
					);
				return (
					<React.Fragment>
						<ICDTag
							icdCode={el}
							toggleCodeSelection={() => {}}
							hasX={false}
						/>
						{button}
					</React.Fragment>
				);
			});
		} else {
			return (
				<div className='transparentBtn' onClick={visibleChargeModal}>
					Add ICD-10 code(s)
				</div>
			);
		}
	};
	const visibleChargeModal = () => {
		setShowChargeModal(true);
		setChargeModalEmptyAllowed(false);
	};
	const showChargeModalEmptyAllowed = () => {
		setShowChargeModal(true);
		setChargeModalEmptyAllowed(true);
	};
	const hideChargeModal = () => {
		setShowChargeModal(false);
		setChargeModalEmptyAllowed(false);
	};
	const closeChargeModalAndDispatch = (selectedCodes) => {
		if (
			_.get(selectedCodes, 'length', 0) === 0 &&
			!Array.isArray(selectedCodes)
		)
			return;
		const medicalCodes = formatSelectedCodes(selectedCodes);
		setSelectedICDCodes(medicalCodes);
		setShowChargeModal(false);
		setChargeModalEmptyAllowed(false);
		onFormChange('medical_codes', medicalCodes);
	};
	const formatSelectedCodes = (selectedCodes) => {
		const medicalCodes = [];
		selectedCodes.forEach((el) => {
			if (_.get(el, 'uuid', '') && _.get(el, 'code_name', '')) {
				medicalCodes.push({
					code_name: _.get(el, 'code_name', null),
					uuid: _.get(el, 'uuid', null),
				});
			}
		});
		return medicalCodes;
	};

	const renderChargeModal = () => {
		return (
			<ChargeModal
				hideChargeModal={hideChargeModal}
				title={
					chargeModalEmptyAllowed
						? 'Edit ICD-10 Code(s)'
						: 'Add ICD-10 Code(s)'
				}
				icdCodes={icdCodes}
				icdCodesLoading={icdCodesLoading}
				icdCodesError={icdCodesError}
				icdCodesPlacement={icdCodesPlacement}
				getICDCodes={getICDCodes}
				enqueueSnackbar={enqueueSnackbar}
				selectedICDCodes={selectedICDCodes}
				encounterUuid={null}
				emptyAllowed={chargeModalEmptyAllowed}
				encounterEditLoading={null}
				encounterEditError={null}
				editEncounter={null}
				willUseBilling={false}
				singleStage={true}
				billingEncounter={null}
				editBillingEncounter={null}
				billingEncounterEditLoading={null}
				billingEncounterEditError={null}
				getAssociatedBillingEncounter={null}
				associatedBillingEncounter={null}
				associatedBillingEncounterLoading={null}
				associatedBillingEncounterError={null}
				closeChargeModalAndDispatch={closeChargeModalAndDispatch}
			/>
		);
	};

	const renderIndications = () => {
		let tempVisitIndications = addVisitIndicationDisplayNames(
			selectedVisitIndications || [],
			visitIndications
		);
		tempVisitIndications = tempVisitIndications.slice().sort((a, b) => {
			if (get(a, 'display_name', '') < get(b, 'display_name', ''))
				return -1;
			else return 1;
		});
		const visitIndicationsLength = tempVisitIndications.length;
		const firstThree = tempVisitIndications.slice(0, 3);
		if (visitIndicationsLength > 0) {
			return firstThree.map((el, i) => {
				const last = i === firstThree.length - 1;
				const button = last ? (
					<div
						className='icdEditIcon'
						onClick={showVisitIndicationsEmptyAllowed}
					>
						<ReactSVG
							src={whiteEditIcon}
							className='icdEditIconSVG'
						/>
						<span style={{ position: 'relative', right: '7px' }}>
							Edit
						</span>
					</div>
				) : (
					''
				);
				const viewAll =
					visitIndicationsLength > 3 && last ? (
						<div
							className='purpleText purpleTextSmall'
							onClick={visibleVisitIndicationsListModal}
							style={{ marginLeft: 'auto' }}
						>
							View all ({visitIndicationsLength})
						</div>
					) : (
						''
					);
				return (
					<React.Fragment>
						<ICDTag
							icdCode={el}
							altDisplayKey='display_name'
							toggleCodeSelection={() => {}}
							tagDisplayFunc={getVisitIndicationTagDisplay}
							longTagVariant={true}
							hasX={false}
						/>
						{button}
						{viewAll}
					</React.Fragment>
				);
			});
		} else {
			return (
				<div
					className='transparentBtn'
					onClick={visibleVisitIndicationsModal}
				>
					Add Indication(s)
				</div>
			);
		}
	};

	const visibleVisitIndicationsModal = () => {
		setShowVisitIndicationsModal(true);
		setVisitIndicationsModalEmptyAllowed(false);
	};

	const showVisitIndicationsEmptyAllowed = () => {
		setShowVisitIndicationsModal(true);
		setVisitIndicationsModalEmptyAllowed(true);
	};

	const hideVisitIndicationsModal = () => {
		setShowVisitIndicationsModal(false);
		setVisitIndicationsModalEmptyAllowed(false);
	};

	const renderVisitIndicationsModal = () => {
		const visitIndicationsEnum = [...visitIndications];
		return (
			<ChargeModal
				hideChargeModal={hideVisitIndicationsModal}
				title={
					visitIndicationsModalEmptyAllowed
						? 'Edit Indication(s)'
						: 'Add Indication(s)'
				}
				icdCodes={visitIndicationsEnum || []}
				icdCodesLoading={false}
				icdCodesError={null}
				icdCodesPlacement={'bottom'}
				getICDCodes={null}
				noLoading={true}
				noPlaceHolder={true}
				onlyLocalSearch={true}
				localSearchKey={'display_name'}
				filter={visitIndicationsFilterEnum}
				localSortKey={'sorting_name'}
				filterKey={'specialty_display_names'}
				freeTextKey={'other_indications'}
				enqueueSnackbar={enqueueSnackbar}
				selectedICDCodes={addVisitIndicationDisplayNames(
					selectedVisitIndications,
					visitIndicationsEnum
				)}
				encounterUuid={null}
				emptyAllowed={visitIndicationsModalEmptyAllowed}
				buttonText={
					visitIndicationsModalEmptyAllowed
						? 'Save'
						: 'Add Indication(s)'
				}
				filterHeader={'Filter results by specialty'}
				altCheckbox={visitIndicationsCheckboxEnum}
				tagDisplayFunc={getVisitIndicationTagDisplay}
				longTagVariant={true}
				noDescription={true}
				reverseVariant={true}
				encounterEditLoading={null}
				encounterEditError={null}
				editEncounter={null}
				willUseBilling={false}
				singleStage={true}
				billingEncounter={null}
				editBillingEncounter={null}
				billingEncounterEditLoading={null}
				billingEncounterEditError={null}
				getAssociatedBillingEncounter={null}
				associatedBillingEncounter={null}
				associatedBillingEncounterLoading={null}
				associatedBillingEncounterError={null}
				notUsingIcd={true}
				closeChargeModalAndDispatch={
					closeVisitIndicationsModalAndDispatch
				}
			/>
		);
	};

	const closeVisitIndicationsModalAndDispatch = (visitIndications) => {
		if (
			get(visitIndications, 'length', 0) === 0 &&
			!Array.isArray(visitIndications)
		)
			return;
		const formattedVisitIndications =
			formatVisitIndications(visitIndications);
		setSelectedVisitIndications(formattedVisitIndications);
		setShowVisitIndicationsModal(false);
		setVisitIndicationsModalEmptyAllowed(false);
		onFormChange('visit_indications', formattedVisitIndications);
	};
	const formatVisitIndications = (visitIndications) => {
		const formattedVisitIndications = [];
		visitIndications.forEach((el) => {
			if (get(el, 'uuid', '')) {
				formattedVisitIndications.push({
					uuid: get(el, 'uuid', null),
					personal_hx: get(el, 'personal_hx', null) || false,
					family_hx: get(el, 'family_hx', null) || false,
					other_indications: get(el, 'other_indications', null),
					display_name: get(el, 'display_name', null),
				});
			}
		});
		return formattedVisitIndications;
	};

	const visibleVisitIndicationsListModal = () => {
		setShowVisitIndicationsListModal(true);
	};

	const hideVisitIndicationsListModal = () => {
		setShowVisitIndicationsListModal(false);
	};

	const renderVisitIndicationsListModal = () => {
		const visitIndicationTags = addVisitIndicationDisplayNames(
			selectedVisitIndications,
			visitIndicationsEnum
		);
		const visitIndicationTagsLength = get(visitIndicationTags, 'length', 0);
		const visitIndicationTagsHTML = visitIndicationTags.map((tag) => {
			return (
				<ICDTag
					icdCode={tag}
					altDisplayKey='display_name'
					toggleCodeSelection={() => {}}
					tagDisplayFunc={getVisitIndicationTagDisplay}
					longTagVariant={true}
					hasX={false}
				/>
			);
		});
		return (
			<ListModal
				htmlContent={visitIndicationTagsHTML}
				title={`All Indications (${visitIndicationTagsLength})`}
				close={hideVisitIndicationsListModal}
			/>
		);
	};

	const validateForm = (formdata) => {
		let errors = {};
		const dateerors = _.omitBy(
			_.mapValues(datefields, function (value, key) {
				if (!isValidDateStringOREmpty(_.get(formdata, key, ''))) {
					return 'Invalid Date';
				}
				return undefined;
			}),
			_.isUndefined
		);

		const datetimeerors = _.omitBy(
			_.mapValues(datetimefields, function (value, key) {
				if (!isValidDateTimeString(_.get(formdata, key, ''))) {
					return 'Invalid Date and Time';
				}
				return undefined;
			}),
			_.isUndefined
		);

		const isDigits = (value) => value.match(/^[\d.]+$/);

		errors = { ...dateerors, ...datetimeerors };

		if (isEmpty(_.get(formdata, ['timezone']))) {
			errors['timezone'] = 'Visit Timezone is required';
		}

		if (isEmpty(_.get(formdata, ['date_of_service']))) {
			errors['date_of_service'] = 'Date & Time of Service is required';
		}

		if (isEmpty(_.get(formdata, ['visit_provider_uuid']))) {
			errors['visit_provider_uuid'] = 'Visit Provider is required';
		}

		if (isEmpty(_.get(formdata, ['state_of_visit']))) {
			errors['state_of_visit'] = 'Visit state is required';
		}
		if (isEmpty(_.get(formdata, ['country_of_visit']))) {
			errors['country_of_visit'] = 'Country is required';
		}
		if (isEmpty(_.get(formdata, ['tm_platform']))) {
			errors['tm_platform'] = 'TM Platform is required';
		}
		if (isEmpty(_.get(formdata, ['consultation_type']))) {
			errors['consultation_type'] = 'Consultation Type is required';
		}
		if (isEmpty(_.get(formdata, ['vsee_specialty']))) {
			errors['vsee_specialty'] = 'Requested Specialty is required';
		}
		if (isEmpty(_.get(formdata, ['payor']))) {
			errors['payor'] = 'Payor is required';
		}

		if (isEmpty(_.get(formdata, ['patient_pay']))) {
			errors['patient_pay'] = 'Patient Fee is required';
		}

		if (
			!isEmpty(_.get(formdata, ['patient_pay'])) &&
			!isDigits(_.toString(_.get(formdata, ['patient_pay'])))
		) {
			errors['patient_pay'] = 'Patient Fee is invalid';
		}

		if (
			_.isEmpty(_.get(formdata, 'initial_visit_summary_sent', '')) &&
			!_.isEmpty(
				_.get(formdata, 'visit_summary_release_method_patient', [])
			)
		) {
			errors['initial_visit_summary_sent'] =
				'Visit Summary Release Date(Patient) is required';
		}

		if (
			_.isEmpty(
				_.get(formdata, 'visit_summary_release_method_patient', '')
			) &&
			!_.isEmpty(_.get(formdata, 'initial_visit_summary_sent', []))
		) {
			errors['visit_summary_release_method_patient'] =
				'Visit Summary release method(Patient) is required';
		}

		if (
			_.isEmpty(_.get(formdata, 'cap_sent_to_patient', '')) &&
			!_.isEmpty(_.get(formdata, 'cap_release_method_patient', []))
		) {
			errors['cap_sent_to_patient'] = 'CAP sent to patient is required';
		}

		if (
			_.isEmpty(_.get(formdata, 'cap_release_method_patient', '')) &&
			!_.isEmpty(_.get(formdata, 'cap_sent_to_patient', []))
		) {
			errors['cap_release_method_patient'] =
				'CAP release method(Patient) is required';
		}

		if (
			_.isEmpty(_.get(formdata, 'visit_summary_release_date_pcp', '')) &&
			!_.isEmpty(_.get(formdata, 'visit_summary_release_method_pcp', []))
		) {
			errors['visit_summary_release_date_pcp'] =
				'Visit Summary release date(PCP) is required';
		}

		if (
			_.isEmpty(
				_.get(formdata, 'visit_summary_release_method_pcp', '')
			) &&
			!_.isEmpty(_.get(formdata, 'visit_summary_release_date_pcp', []))
		) {
			errors['visit_summary_release_method_pcp'] =
				'Visit Summary release method(PCP) is required';
		}

		if (
			_.isEmpty(_.get(formdata, 'cap_sent_date_pcp', '')) &&
			!_.isEmpty(_.get(formdata, 'cap_release_method_pcp', []))
		) {
			errors['cap_sent_date_pcp'] = 'CAP sent date (PCP) is required';
		}

		if (
			_.isEmpty(_.get(formdata, 'cap_release_method_pcp', '')) &&
			!_.isEmpty(_.get(formdata, 'cap_sent_date_pcp', []))
		) {
			errors['cap_release_method_pcp'] =
				'CAP release method(PCP) is required';
		}

		if (
			formdata['drug_interaction'] == 'yes' &&
			_.get(formdata, ['no_of_interactions', 'length'], 0) == 0
		) {
			errors['no_of_interactions'] = '# of Interaction is required';
		}

		return errors;
	};
	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		let errors = {};
		let updatedtouched = {};
		stateData[key] = value;
		if (!(key in datefields) && !(key in datetimefields)) {
			errors = validateForm(stateData);
			updatedtouched = { ...touched, [key]: true };
		}
		setErrors(errors);
		setTouched(updatedtouched);
		setFormData(stateData);
	};

	const handleDateFieldValidate = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		const errors = validateForm(stateData);
		const updatedtouched = { ...touched, [key]: true };
		setErrors(errors);
		setTouched(updatedtouched);
		setFormData(stateData);
	};

	const touchAll = () => {
		return _.mapValues(formData, (o) => {
			return true;
		});
	};

	const saveForm = () => {
		const errors = validateForm(formData);

		if (_.isEmpty(errors)) {
			setConfirmSubmit(true);
			setIsDisabled(false);
		} else {
			const touched = touchAll();
			setErrors(errors);
			setTouched(touched);
			setTimeout(() => {
				navigateToErrorFields(errors);
			}, 100);
		}
	};

	const addEncounter = () => {
		const stateData = { ...formData };
		const errors = validateForm(stateData);
		setIsDisabled(true);
		if (_.isEmpty(errors)) {
			stateData['user_id'] = patientdetail.id;
			stateData['user_uuid'] = patientdetail.uuid;
			stateData['is_manual'] = true;
			stateData['billing_status'] = setBillingStatusFieldValue();
			stateData['insurance_claim_status'] =
				setInsuranceClaimFieldsValue();
			stateData['patient_pay'] = _.toInteger(
				_.get(stateData, 'patient_pay')
			);
			if (
				stateData['visit_indications'] &&
				Array.isArray(stateData['visit_indications'])
			) {
				stateData['visit_indications'] = stateData[
					'visit_indications'
				].map((indication) => {
					const formattedIndication = { ...indication };
					if (formattedIndication.display_name)
						delete formattedIndication.display_name;
					if (
						formattedIndication.hasOwnProperty(
							'other_indications'
						) &&
						!formattedIndication.other_indications
					) {
						delete formattedIndication.other_indications;
					}
					return formattedIndication;
				});
			}
			const dos = stateData['date_of_service'];
			const momentdos = moment(dos, 'YYYY-MM-DD HH:mm:ss', true);
			const timezone = stateData['timezone'];
			stateData['date_of_service'] = GetZoneDatetime(
				moment,
				momentdos,
				timezone
			);
			if (stateData['payor'] !== 'self_pay') {
				let primaryInsurance = {};
				if (patientdetail?.insurances?.length > 0) {
					primaryInsurance = _.get(
						patientdetail.insurances.filter(
							(el) => el.insurance_type === 'primary'
						),
						'[0]',
						{}
					);
				}
				stateData['insurance_company_id'] = _.get(
					primaryInsurance,
					'insurance.insurance_company_id',
					''
				);
			}
			setConfirmSubmit(false);
			setLoading(true);
			callAddManualVisitEncouter({ data: stateData })
				.then((result) => {
					setLoading(false);
					if (
						!_.isEmpty(_.get(result, ['data', 'data', 'uuid'], ''))
					) {
						changeEncounterTab(
							_.get(result, ['data', 'data', 'uuid'], ''),
							EncounterTabs.DETAIL
						);
					} else {
						cancel();
						setIsDisabled(false);
					}
				})
				.catch((error) => {
					const errorMessage =
						error?.response?.data?.reason ??
						error?.message ??
						'Unknown error';
					const touched = touchAll();
					setIsDisabled(false);
					setLoading(false);
					setTouched(touched);
					setApiError(errorMessage);
				});
		}
	};

	const navigateToErrorFields = (errors) => {
		const visitkeys = [
			'initial_visit_summary_sent',
			'visit_summary_release_method_patient',
			'cap_sent_to_patient',
			'cap_release_method_patient',
			'visit_provider_uuid',
			'visit_summary_release_date_pcp',
			'visit_summary_release_method_pcp',
			'cap_sent_date_pcp',
			'cap_release_method_pcp',
			'scheduling_poc',
			'state_of_visit',
			'country_of_visit',
			'timezone',
			'relationship_to_patient',
			'date_of_service',
			'tm_platform',
			'consultation_type',
			'vsee_specialty',
			'records_requested',
			'records_received',
			'provider_indicated_specialty',
			'initial_cap_completed_date',
			'followup_cap_completed_date',
		];

		const Pgxkeys = ['no_of_interactions'];

		const Billingkeys = ['payor', 'patient_pay'];

		const keys = _.keys(errors);

		if (!_.isEmpty(_.intersection(keys, visitkeys))) {
			const scrollposition = _.get(
				sectionRefs,
				['current', 'visitdetail', 'offsetTop'],
				40
			);
			window.scrollTo({
				top: scrollposition - 0,
				left: 0,
				behavior: 'smooth',
			});
		} else if (!_.isEmpty(_.intersection(keys, Pgxkeys))) {
			const scrollposition = _.get(
				sectionRefs,
				['current', 'pgx', 'offsetTop'],
				40
			);
			window.scrollTo({
				top: scrollposition - 30,
				left: 0,
				behavior: 'smooth',
			});
		} else if (!_.isEmpty(_.intersection(keys, Billingkeys))) {
			const scrollposition = _.get(
				sectionRefs,
				['current', 'billing', 'offsetTop'],
				40
			);
			window.scrollTo({
				top: scrollposition - 30,
				left: 0,
				behavior: 'smooth',
			});
		}
	};

	const setBillingStatusFieldValue = () => {
		const { payor, patient_pay } = formData;
		const _patientPay =
			!_.isNil(patient_pay) && !_.isEmpty(patient_pay) ? patient_pay : 0;
		let billingStatus = '';

		if (payor == 'self_pay' && _patientPay == 0) {
			billingStatus = 'na';
		} else if (payor == 'self_pay' && _patientPay != 0) {
			billingStatus = 'not_invoiced';
		} else if (payor != 'self_pay' && _patientPay == 0) {
			billingStatus = 'na';
		} else if (payor != 'self_pay' && _patientPay != 0) {
			billingStatus = 'not_invoiced';
		}

		return billingStatus;
	};

	const setInsuranceClaimFieldsValue = () => {
		const { payor, patient_pay } = formData;
		const _patientPay =
			!_.isNil(patient_pay) && !_.isEmpty(patient_pay) ? patient_pay : 0;
		let insuranceClaimStatus = '';

		if (payor == 'self_pay' && _patientPay == 0) {
			insuranceClaimStatus = 'na';
		} else if (payor == 'self_pay' && _patientPay != 0) {
			insuranceClaimStatus = 'na';
		} else if (payor != 'self_pay' && _patientPay == 0) {
			insuranceClaimStatus = 'not_submitted';
		} else if (payor != 'self_pay' && _patientPay != 0) {
			insuranceClaimStatus = 'not_submitted';
		}

		return insuranceClaimStatus;
	};

	const renderVisitDetail = () => {
		return (
			<div
				className='encounter-section'
				ref={(section) =>
					(sectionRefs.current['visitdetail'] = section)
				}
			>
				{showChargeModal && (
					<div className='popupModal'>{renderChargeModal()}</div>
				)}
				{showVisitIndicationsModal && (
					<div className='popupModal'>
						{renderVisitIndicationsModal()}
					</div>
				)}
				{showVisitIndicationsListModal && (
					<div className='popupModal'>
						{renderVisitIndicationsListModal()}
					</div>
				)}
				<div
					className={classnames(
						'encounter-accordian-header',
						'encounter-accordian-header-expanded'
					)}
					style={{ cursor: 'pointer' }}
				>
					<span className='accordian-title'>VISIT DETAILS</span>
				</div>
				<div className='encounter-accordian-body editable'>
					<div>
						<div className='segment-row'>
							<div className='segment-label-row'>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Scheduling POC
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header required'>
										Visit Country
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header required'>
										Visit State/ Province
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header required'>
										Visit Timezone
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Relationship To Patient
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header required'>
										Date & Time Of Service
									</div>
								</div>
							</div>

							<div className='segment-value-row'>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterInputField
											value={_.get(
												formData,
												['scheduling_poc'],
												''
											)}
											name='scheduling_poc'
											onChange={(value) =>
												onFormChange(
													'scheduling_poc',
													value
												)
											}
											placeholder='John Doe'
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											name='country_of_visit'
											value={_.get(
												formData,
												['country_of_visit'],
												''
											)}
											placeholder='Select'
											onChange={(value) =>
												onFormChange(
													'country_of_visit',
													value
												)
											}
											istouched={_.get(
												touched,
												['country_of_visit'],
												false
											)}
											error={_.get(
												errors,
												['country_of_visit'],
												''
											)}
											options={countriesList.map(
												(el, i) => {
													return {
														key: i,
														label: el.key,
														value: el.value,
													};
												}
											)}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											name='state_of_visit'
											value={_.get(
												formData,
												['state_of_visit'],
												''
											)}
											placeholder='Select'
											onChange={(value) =>
												onFormChange(
													'state_of_visit',
													value
												)
											}
											istouched={_.get(
												touched,
												['state_of_visit'],
												false
											)}
											error={_.get(
												errors,
												['state_of_visit'],
												''
											)}
											options={
												formData?.country_of_visit ===
												'US'
													? states_list.map(
															(el, i) => {
																return {
																	key: i,
																	label: el.key,
																	value: el.key,
																};
															}
													  )
													: provincesList.map(
															(el, i) => {
																return {
																	key: i,
																	label: el.key,
																	value: el.key,
																};
															}
													  )
											}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['timezone'],
												''
											)}
											name='timezone'
											placeholder={'Select'}
											istouched={_.get(
												touched,
												['timezone'],
												false
											)}
											error={_.get(
												errors,
												['timezone'],
												''
											)}
											options={createEnumsOptions(
												props,
												['timezoneenums'],
												'name',
												'name',
												'area'
											)}
											onChange={(value) =>
												onFormChange('timezone', value)
											}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['relationship_to_patient'],
												''
											)}
											name='relationship_to_patient'
											options={createEnumsOptions(
												visitsschema,
												['relationship_to_patient'],
												'key',
												'display_name',
												'key'
											)}
											onChange={(value) =>
												onFormChange(
													'relationship_to_patient',
													value
												)
											}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterDateTimeField
											value={_.get(
												formData,
												['date_of_service'],
												''
											)}
											name='date_of_service'
											disabled={false}
											placeholder={moment
												.tz(moment(), moment.tz.guess())
												.format(
													'MMM D, YYYY hh:mm a z'
												)}
											istouched={_.get(
												touched,
												['date_of_service'],
												false
											)}
											error={_.get(
												errors,
												['date_of_service'],
												''
											)}
											onChange={(value) =>
												onFormChange(
													'date_of_service',
													value
												)
											}
											onBlur={(value) =>
												handleDateFieldValidate(
													'date_of_service',
													value
												)
											}
											onRight={true}
											disabledDate={(current) => {
												const momentcurrent =
													moment(current);
												return momentcurrent.isBefore(
													moment()
												);
											}}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className='segment-row'>
							<div className='segment-label-row'>
								<div className='segment-col'>
									<div className='encounter-field-header required'>
										TM Platform
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header required'>
										Consultation Type
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header required'>
										Requested Specialty
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header required'>
										Visit Provider
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header required'>
										Visit Status{' '}
									</div>
								</div>
							</div>
							<div className='segment-value-row'>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['tm_platform'],
												''
											)}
											name='tm_platform'
											options={createEnumsOptions(
												visitsschema,
												['tm_platform'],
												'key',
												'display_name',
												'key'
											)}
											onChange={(value) =>
												onFormChange(
													'tm_platform',
													value
												)
											}
											istouched={_.get(
												touched,
												['tm_platform'],
												false
											)}
											error={_.get(
												errors,
												['tm_platform'],
												''
											)}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['consultation_type'],
												''
											)}
											name='consultation_type'
											options={createEnumsOptions(
												props,
												'consultationTypesEnums',
												'key',
												'label',
												'value'
											)}
											onChange={(value) =>
												onFormChange(
													'consultation_type',
													value
												)
											}
											istouched={_.get(
												touched,
												['consultation_type'],
												false
											)}
											error={_.get(
												errors,
												['consultation_type'],
												''
											)}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['vsee_specialty'],
												''
											)}
											name='vsee_specialty'
											options={_.filter(
												createEnumsOptions(
													visitsschema,
													['vsee_specialty'],
													'key',
													'display_name',
													'key'
												),
												function (o) {
													return (
														o.label.indexOf(
															'Willing'
														) == -1 &&
														o.label !== 'No Default'
													);
												}
											)}
											onChange={(value) =>
												onFormChange(
													'vsee_specialty',
													value
												)
											}
											istouched={_.get(
												touched,
												['vsee_specialty'],
												false
											)}
											error={_.get(
												errors,
												['vsee_specialty'],
												''
											)}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['visit_provider_uuid'],
												''
											)}
											name='visit_provider_uuid'
											options={createEnumsOptions(
												props,
												['manual_visit_providers'],
												'uuid',
												'full_name',
												'uuid'
											)}
											onChange={(value) =>
												onFormChange(
													'visit_provider_uuid',
													value
												)
											}
											istouched={_.get(
												touched,
												['visit_provider_uuid'],
												false
											)}
											error={_.get(
												errors,
												['visit_provider_uuid'],
												''
											)}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={GetSelectvalue(
												formData,
												['visit_status'],
												_.get(
													visitsschema,
													['visit_status'],
													[]
												)
											)}
											name='visit_status'
											disabled={true}
											options={[
												{
													key: GetSelectvalue(
														formData,
														['visit_status'],
														_.get(
															visitsschema,
															['visit_status'],
															[]
														)
													),
													label: GetSelectvalue(
														formData,
														['visit_status'],
														_.get(
															visitsschema,
															['visit_status'],
															[]
														)
													),
													value: GetSelectvalue(
														formData,
														['visit_status'],
														_.get(
															visitsschema,
															['visit_status'],
															[]
														)
													),
												},
											]}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className='segment-row'>
							<div className='segment-label-row'>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Records Requested
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Records Received
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Referring Provider (RP)
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										RP Contact Info
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										RP Recommended Test
									</div>
								</div>
							</div>
							<div className='segment-value-row'>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['records_requested'],
												''
											)}
											name='records_requested'
											placeholder={'Select'}
											options={createEnumsOptions(
												visitsschema,
												['records_requested'],
												'key',
												'display_name',
												'key'
											)}
											onChange={(value) =>
												onFormChange(
													'records_requested',
													value
												)
											}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												['records_received'],
												''
											)}
											name='records_received'
											placeholder={'Select'}
											options={createEnumsOptions(
												visitsschema,
												['records_received'],
												'key',
												'display_name',
												'key'
											)}
											onChange={(value) =>
												onFormChange(
													'records_received',
													value
												)
											}
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterInputField
											value={'--'}
											name='referring_provider_name'
											placeholder='--'
											disabled={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterInputField
											value={'--'}
											name='referring_provider_fax_email'
											placeholder='--'
											disabled={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterInputField
											value={'--'}
											name='requested_test_via_provider'
											placeholder='--'
											disabled={true}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className='segment-row'>
							<div className='segment-label-row'>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Provider Indicated Specialty
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										ICD-10 Code(s) (Visit)
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Indications
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Escalated to Supervising Physician?
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Supervising Physician
									</div>
								</div>
							</div>
							<div className='segment-value-row'>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={_.get(
												formData,
												[
													'provider_indicated_specialty',
												],
												''
											)}
											name='provider_indicated_specialty'
											disabled={false}
											options={_.filter(
												createEnumsOptions(
													visitsschema,
													['vsee_specialty'],
													'key',
													'display_name',
													'key'
												),
												function (o) {
													return (
														o.label.indexOf(
															'Willing'
														) == -1 &&
														o.label !== 'No Default'
													);
												}
											)}
											onChange={(value) =>
												onFormChange(
													'provider_indicated_specialty',
													value
												)
											}
											placeholder='Select'
											typeahead={true}
											clearable={true}
										/>
									</div>
								</div>
								<div
									key='icd-render'
									className='segment-col'
									style={{
										display: 'flex',
										flexDirection: 'row',
										flexWrap: 'wrap',
									}}
								>
									{renderICDCodes()}
								</div>
								<div
									key='indication-render'
									id='indication-render'
									className='segment-col'
									style={{
										display: 'flex',
										flexDirection: 'row',
										flexWrap: 'wrap',
									}}
								>
									{renderIndications()}
								</div>
								<div className='segment-col'>
									<div
										className={classnames(
											'encounter-input-section is_escalated_section is-disabled'
										)}
									>
										<EncounterRadioGroupField
											name={'is_escalated'}
											value={'--'}
											options={[
												{ label: 'Yes', value: true },
												{ label: 'No', value: false },
											]}
											disabled={true}
										/>
									</div>
								</div>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterSelectField
											value={''}
											name='supervising_physician'
											options={createEnumsOptions(
												visitsschema,
												['supervising_physician'],
												'key',
												'display_name',
												'key'
											)}
											placeholder='Select'
											disabled={true}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className='segment-row'>
							<div className='segment-label-row'>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Visit CAP Completed Date
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-field-header'>
										Visit CAP Sent To Patient On
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-field-header'>
										Visit CAP Sent To PCP On
									</div>
								</div>
							</div>
							<div className='segment-value-row'>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterDateField
											value={_.get(
												formData,
												['initial_cap_completed_date'],
												''
											)}
											name='initial_cap_completed_date'
											onChange={(value) =>
												onFormChange(
													'initial_cap_completed_date',
													value
												)
											}
											error={_.get(
												errors,
												['initial_cap_completed_date'],
												''
											)}
											onBlur={(value) =>
												handleDateFieldValidate(
													'initial_cap_completed_date',
													value
												)
											}
											disabled={true}
										/>
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-multi-cols-section'>
										<div className='encounter-input-section multi-first-col'>
											<EncounterDateField
												value={_.get(
													formData,
													[
														'initial_visit_summary_sent',
													],
													''
												)}
												name='initial_visit_summary_sent'
												error={_.get(
													errors,
													[
														'initial_visit_summary_sent',
													],
													''
												)}
												onChange={(value) =>
													onFormChange(
														'initial_visit_summary_sent',
														value
													)
												}
												onBlur={(value) =>
													handleDateFieldValidate(
														'initial_visit_summary_sent',
														value
													)
												}
											/>
										</div>
										<div className='via_seprator'>
											{' '}
											via{' '}
										</div>
										<div className='encounter-input-section multi-two-col'>
											<EncounterMultiSelectField
												value={_.get(
													formData,
													[
														'visit_summary_release_method_patient',
													],
													''
												)}
												name='visit_summary_release_method_patient'
												error={_.get(
													errors,
													[
														'visit_summary_release_method_patient',
													],
													''
												)}
												options={createEnumsOptions(
													visitsschema,
													[
														'visit_summary_release_method_patient',
													],
													'key',
													'display_name',
													'key'
												)}
												onChange={(value) =>
													onFormChange(
														'visit_summary_release_method_patient',
														value
													)
												}
												placeholder={'Email'}
											/>
										</div>
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-multi-cols-section'>
										<div className='encounter-input-section multi-first-col'>
											<EncounterDateField
												value={_.get(
													formData,
													[
														'visit_summary_release_date_pcp',
													],
													''
												)}
												name='visit_summary_release_date_pcp'
												error={_.get(
													errors,
													[
														'visit_summary_release_date_pcp',
													],
													''
												)}
												onChange={(value) =>
													onFormChange(
														'visit_summary_release_date_pcp',
														value
													)
												}
												onBlur={(value) =>
													handleDateFieldValidate(
														'visit_summary_release_date_pcp',
														value
													)
												}
											/>
										</div>
										<div className='via_seprator'>
											{' '}
											via{' '}
										</div>
										<div className='encounter-input-section multi-two-col'>
											<EncounterMultiSelectField
												value={_.get(
													formData,
													[
														'visit_summary_release_method_pcp',
													],
													''
												)}
												name='visit_summary_release_method_pcp'
												error={_.get(
													errors,
													[
														'visit_summary_release_method_pcp',
													],
													''
												)}
												options={createEnumsOptions(
													visitsschema,
													[
														'visit_summary_release_method_pcp',
													],
													'key',
													'display_name',
													'key'
												)}
												onChange={(value) =>
													onFormChange(
														'visit_summary_release_method_pcp',
														value
													)
												}
												placeholder={'Email'}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='segment-row'>
							<div className='segment-label-row'>
								<div className='segment-col'>
									<div className='encounter-field-header'>
										Result CAP Completed Date
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-field-header'>
										Result CAP Sent To Patient On
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-field-header'>
										Result CAP Sent To PCP On
									</div>
								</div>
							</div>
							<div className='segment-value-row'>
								<div className='segment-col'>
									<div className='encounter-input-section'>
										<EncounterDateField
											value={_.get(
												formData,
												['followup_cap_completed_date'],
												''
											)}
											name='followup_cap_completed_date'
											error={_.get(
												errors,
												['followup_cap_completed_date'],
												''
											)}
											onChange={(value) =>
												onFormChange(
													'followup_cap_completed_date',
													value
												)
											}
											onBlur={(value) =>
												handleDateFieldValidate(
													'followup_cap_completed_date',
													value
												)
											}
											disabled={true}
										/>
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-multi-cols-section'>
										<div className='encounter-input-section multi-first-col'>
											<EncounterDateField
												value={_.get(
													formData,
													['cap_sent_to_patient'],
													''
												)}
												name='cap_sent_to_patient'
												error={_.get(
													errors,
													['cap_sent_to_patient'],
													''
												)}
												onChange={(value) =>
													onFormChange(
														'cap_sent_to_patient',
														value
													)
												}
												onBlur={(value) =>
													handleDateFieldValidate(
														'cap_sent_to_patient',
														value
													)
												}
											/>
										</div>
										<div className='via_seprator'>
											{' '}
											via{' '}
										</div>
										<div className='encounter-input-section multi-two-col'>
											<EncounterMultiSelectField
												value={_.get(
													formData,
													[
														'cap_release_method_patient',
													],
													''
												)}
												name='cap_release_method_patient'
												error={_.get(
													errors,
													[
														'cap_release_method_patient',
													],
													''
												)}
												options={createEnumsOptions(
													visitsschema,
													[
														'cap_release_method_patient',
													],
													'key',
													'display_name',
													'key'
												)}
												onChange={(value) =>
													onFormChange(
														'cap_release_method_patient',
														value
													)
												}
												placeholder={'Email'}
											/>
										</div>
									</div>
								</div>
								<div className='segment-2cols'>
									<div className='encounter-multi-cols-section'>
										<div className='encounter-input-section multi-first-col'>
											<EncounterDateField
												value={_.get(
													formData,
													['cap_sent_date_pcp'],
													''
												)}
												name='cap_sent_date_pcp'
												error={_.get(
													errors,
													['cap_sent_date_pcp'],
													''
												)}
												onChange={(value) =>
													onFormChange(
														'cap_sent_date_pcp',
														value
													)
												}
												onBlur={(value) =>
													handleDateFieldValidate(
														'cap_sent_date_pcp',
														value
													)
												}
											/>
										</div>
										<div className='via_seprator'>
											{' '}
											via{' '}
										</div>
										<div className='encounter-input-section multi-two-col'>
											<EncounterMultiSelectField
												value={_.get(
													formData,
													['cap_release_method_pcp'],
													''
												)}
												name='cap_release_method_pcp'
												error={_.get(
													errors,
													['cap_release_method_pcp'],
													''
												)}
												options={createEnumsOptions(
													visitsschema,
													['cap_release_method_pcp'],
													'key',
													'display_name',
													'key'
												)}
												onChange={(value) =>
													onFormChange(
														'cap_release_method_pcp',
														value
													)
												}
												placeholder={'Email'}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderTestOrderDetail = () => {
		return (
			<div
				className='encounter-section'
				ref={(section) => {
					sectionRefs.current['testorder'] = section;
				}}
			>
				<div
					className={classnames(
						'encounter-accordian-header',
						'encounter-accordian-header-expanded'
					)}
					style={{ cursor: 'pointer' }}
				>
					<span className='accordian-title'>TEST ORDER(S)</span>
				</div>
				<div className='encounter-accordian-body'>
					<div className='post-creation-notice'>
						These fields will be made available after the encounter
						is created.
					</div>
				</div>
			</div>
		);
	};

	const renderPGxDetail = () => {
		return (
			<div
				className='encounter-section'
				ref={(section) => {
					sectionRefs.current['pgx'] = section;
				}}
			>
				<div
					className={classnames(
						'encounter-accordian-header',
						'encounter-accordian-header-expanded'
					)}
					style={{ cursor: 'pointer' }}
				>
					<span className='accordian-title'>PGX INFORMATION</span>
				</div>
				<div className='encounter-accordian-body editable'>
					<div className='segment-row'>
						<div className='segment-label-row'>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									PharmD Identification
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Drug Interaction
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									# of Interaction(s)
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Drug/Gene Interaction
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Drug Dos Adj Recommended
								</div>
							</div>
						</div>
						<div className='segment-value-row'>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterSelectField
										value={_.get(formData, ['pharmd'], '')}
										name='pharmd'
										options={createEnumsOptions(
											visitsschema,
											['pharmd'],
											'key',
											'display_name',
											'key'
										)}
										onChange={(value) =>
											onFormChange('pharmd', value)
										}
										typeahead={true}
										clearable={true}
									/>
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterSelectField
										value={_.get(
											formData,
											['drug_interaction'],
											''
										)}
										name='drug_interaction'
										options={createEnumsOptions(
											visitsschema,
											['drug_interaction'],
											'key',
											'display_name',
											'key'
										)}
										onChange={(value) =>
											onFormChange(
												'drug_interaction',
												value
											)
										}
										typeahead={true}
										clearable={true}
									/>
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterSelectField
										value={_.get(
											formData,
											['no_of_interactions'],
											''
										)}
										name='no_of_interactions'
										options={createEnumsOptions(
											visitsschema,
											['no_of_interactions'],
											'key',
											'display_name',
											'key'
										)}
										onChange={(value) =>
											onFormChange(
												'no_of_interactions',
												value
											)
										}
										error={_.get(
											errors,
											['no_of_interactions'],
											''
										)}
										typeahead={true}
										clearable={true}
									/>
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterSelectField
										value={_.get(
											formData,
											['drug_contraindications'],
											''
										)}
										name='drug_contraindications'
										options={createEnumsOptions(
											visitsschema,
											['drug_contraindications'],
											'key',
											'display_name',
											'key'
										)}
										onChange={(value) =>
											onFormChange(
												'drug_contraindications',
												value
											)
										}
										typeahead={true}
										clearable={true}
									/>
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterSelectField
										value={_.get(
											formData,
											[
												'drug_dosage_adjustment_recommended',
											],
											''
										)}
										name='drug_dosage_adjustment_recommended'
										options={createEnumsOptions(
											visitsschema,
											[
												'drug_dosage_adjustment_recommended',
											],
											'key',
											'display_name',
											'key'
										)}
										onChange={(value) =>
											onFormChange(
												'drug_dosage_adjustment_recommended',
												value
											)
										}
										typeahead={true}
										clearable={true}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderRoRDetail = () => {
		return (
			<div
				className='encounter-section'
				ref={(section) => {
					sectionRefs.current['ror'] = section;
				}}
			>
				<div
					className={classnames(
						'encounter-accordian-header',
						'encounter-accordian-header-expanded'
					)}
					style={{ cursor: 'pointer' }}
				>
					<span className='accordian-title'>
						RETURN OF RESULTS (RoR) FOLLOW-UP
					</span>
				</div>
				<div className='encounter-accordian-body'>
					<div className='post-creation-notice'>
						These fields will be made available after the encounter
						is created.
					</div>
				</div>
			</div>
		);
	};

	const renderBillingDetail = () => {
		return (
			<div
				className='encounter-section'
				ref={(section) => {
					sectionRefs.current['billing'] = section;
				}}
			>
				<div
					className={classnames(
						'encounter-accordian-header',
						'encounter-accordian-header-expanded'
					)}
					style={{ cursor: 'pointer' }}
				>
					<span className='accordian-title'>BILLING</span>
				</div>
				<div className='encounter-accordian-body editable'>
					<div className='segment-row'>
						<div className='segment-label-row'>
							<div className='segment-col'>
								<div className='encounter-field-header required'>
									Payor
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Coupon Code
								</div>
							</div>
						</div>
						<div className='segment-value-row'>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterSelectField
										value={_.get(formData, ['payor'], '')}
										name='payor'
										istouched={_.get(
											touched,
											['payor'],
											false
										)}
										options={payorSelectOption(
											patientdetail,
											false,
											referralPrograms
										)}
										placeholder='Select'
										error={_.get(errors, ['payor'], '')}
										onChange={(value) =>
											onFormChange('payor', value)
										}
										extraclassName={'select-payor'}
										typeahead={true}
										clearable={true}
									/>
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterInputField
										value={_.get(
											formData,
											['coupon_code'],
											''
										)}
										name='coupon_code'
										placeholder='--'
										onChange={(value) =>
											onFormChange('coupon_code', value)
										}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className='segment-row'>
						<div className='segment-label-row'>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Patient Payment Status
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-field-header required'>
									Patient Fee
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Billing Status (Patient)
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Billing Date (Patient)
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Billing Amount (Patient)
								</div>
							</div>
						</div>
						<div className='segment-value-row'>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterInputField
										value={''}
										name='patient_payment'
										placeholder='--'
										disabled={true}
									/>
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterInputField
										value={_.get(
											formData,
											['patient_pay'],
											''
										)}
										name='patient_pay'
										istouched={_.get(
											touched,
											['patient_pay'],
											false
										)}
										placeholder='$0'
										onChange={(value) =>
											onFormChange('patient_pay', value)
										}
										error={_.get(
											errors,
											['patient_pay'],
											''
										)}
									/>
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterSelectField
										value={_.get(
											formData,
											['billing_status'],
											''
										)}
										name='billing_status'
										options={createEnumsOptions(
											visitsschema,
											['billing_status'],
											'key',
											'display_name',
											'key'
										)}
										placeholder='N/A'
										disabled={true}
									/>
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterInputField
										value={''}
										name='patient_billing_date'
										placeholder='--'
										disabled={true}
									/>
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterInputField
										value={''}
										name='patient_billing_amount'
										placeholder='--'
										disabled={true}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className='segment-row'>
						<div className='segment-label-row'>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Patient Fee Refund
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Patient Fee Refund Decision
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-field-header'>
									Patient Fee Refund Processed Date
								</div>
							</div>
						</div>
						<div className='segment-value-row'>
							<div className='segment-col'>
								<div className='encounter-field-value'>
									<EncounterInputField
										name='patient_fee_refund'
										value={''}
										placeholder='--'
										disabled={true}
									/>
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-field-value'>
									<EncounterSelectField
										value={''}
										name='patient_fee_refund_decision'
										options={[
											{
												key: null,
												value: null,
												label: '',
											},
											{
												key: 'refund',
												value: 'refund',
												label: 'Refund',
											},
											{
												key: 'not_refund',
												value: 'not_refund',
												label: 'Not Refund',
											},
										]}
										placeholder='Select'
										disabled={true}
									/>
								</div>
							</div>
							<div className='segment-col'>
								<div className='encounter-input-section'>
									<EncounterDateField
										name='patient_fee_refund_processed_date'
										value={_.get(
											formData,
											[
												'patient_fee_refund_processed_date',
											],
											''
										)}
										placeholder='MM/DD/YYYY'
										error={_.get(
											errors,
											[
												'patient_fee_refund_processed_date',
											],
											''
										)}
										onChange={(value) =>
											onFormChange(
												'patient_fee_refund_processed_date',
												value
											)
										}
										onBlur={(value) =>
											handleDateFieldValidate(
												'patient_fee_refund_processed_date',
												value
											)
										}
										disabledDate={(current) => {
											const yesterday = _.get(
												formData,
												['date_of_service'],
												''
											);
											const momentcurrent =
												moment(current);
											if (!_.isNil(yesterday))
												return momentcurrent.isAfter(
													moment(yesterday).clone()
												);
											else return true;
										}}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className='segment-row segment-row-title'>
						<div className='segment-col'>INSURANCE CLAIM</div>
						<div className='post-creation-notice top-margin'>
							These fields will be made available after the
							encounter is created.
						</div>
					</div>

					<div className='add-encntr-lbl-billing-notes'>
						BILLING NOTES
					</div>
					<div className='segment-row encounter-field-billing-value'>
						<EncounterTextAreaField
							value={_.get(formData, ['billing_notes'], '')}
							name='billing_notes'
							disabled={false}
							onChange={(value) =>
								onFormChange('billing_notes', value)
							}
							placeholder='Content'
						/>
					</div>
				</div>
			</div>
		);
	};

	const renderAdditionalDetail = () => {
		return (
			<div
				className='encounter-section'
				ref={(section) => {
					sectionRefs.current['additional'] = section;
				}}
			>
				<div
					className={classnames(
						'encounter-accordian-header',
						'encounter-accordian-header-expanded'
					)}
					style={{ cursor: 'pointer' }}
				>
					<span className='accordian-title'>ADDITIONAL INFO</span>
				</div>
				<div className='encounter-accordian-body'>
					<div className='add-encntr-lbl-cc-notes'>CC NOTES</div>
					<div className='segment-row'>
						<EncounterTextAreaField
							value={_.get(formData, ['notes'], '')}
							name='notes'
							disabled={false}
							onChange={(value) => onFormChange('notes', value)}
							placeholder='Content'
						/>
					</div>
				</div>
			</div>
		);
	};

	return (
		<Loading loading={loading}>
			<div className='encounter-sections'>
				{renderVisitDetail()}
				{renderTestOrderDetail()}
				{renderPGxDetail()}
				{renderRoRDetail()}
				{renderBillingDetail()}
				{renderAdditionalDetail()}
			</div>
			<div className='addencounter-footer-container'>
				<div className='cancel-btn-link'>
					<Button onClick={() => cancel()}>Cancel</Button>
				</div>
				<div className='save-btn-link'>
					<Button
						style={{ float: 'right' }}
						onClick={() => saveForm()}
					>
						Save
					</Button>
				</div>
			</div>
			<Dialog
				size='tiny'
				visible={confirmSubmit}
				onCancel={() => setConfirmSubmit(false)}
				lockScroll={false}
				showClose={false}
				closeOnPressEscape={false}
				closeOnClickModal={false}
			>
				<Dialog.Body>
					<span>Are you sure you want to add encounter?</span>
				</Dialog.Body>
				<Dialog.Footer className='dialog-footer'>
					<Button onClick={() => setConfirmSubmit(false)}>
						Cancel
					</Button>
					<Button
						disabled={isDisabled}
						type='primary'
						onClick={() => addEncounter()}
					>
						Submit
					</Button>
				</Dialog.Footer>
			</Dialog>
			<ApiErrorModal
				message={apiError}
				isVisible={!!apiError}
				onClose={() => setApiError(null)}
			/>
		</Loading>
	);
};

export default AddManualVisitEncounter;
