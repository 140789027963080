import _ from 'lodash';

import * as actionTypes from './actionTypes';
import UserAccountsService from '../service/UserAccounts';
import { hydrateAuditTrail } from '../transformers/userAccountsTransformers';

function startLoadPartners() {
	return { type: actionTypes.LOAD_PARTNERS_START };
}

function successLoadPartners(payload) {
	return { type: actionTypes.LOAD_PARTNERS_SUCCESS, payload };
}

function failureLoadPartners(payload) {
	return { type: actionTypes.LOAD_PARTNERS_FAILURE, payload };
}

export function loadPartners() {
	return (dispatch) => {
		dispatch(startLoadPartners());
		return UserAccountsService.getPartnersList()
			.then((res) => {
				return dispatch(
					successLoadPartners(
						_.orderBy(
							_.get(res, ['data', 'data'], []),
							[(user) => user.display_name.trim().toLowerCase()],
							['asc']
						)
					)
				);
			})
			.catch((err) => {
				dispatch(failureLoadPartners(err));
			});
	};
}

function createUserStart() {
	return { type: actionTypes.CREATE_USER_START };
}

function createUserSuccess(payload) {
	return { type: actionTypes.CREATE_USER_SUCCESS, payload };
}

function createUserFailure(payload) {
	return { type: actionTypes.CREATE_USER_FAILURE, payload };
}

function createUserClear() {
	return { type: actionTypes.CREATE_USER_CLEAR };
}

export function createUser(data) {
	return (dispatch) => {
		dispatch(createUserStart());
		return UserAccountsService.createUser(data)
			.then((res) => {
				return dispatch(
					createUserSuccess(
						_.get(
							res,
							['data', 'message'],
							'User created successfully!'
						)
					)
				);
			})
			.catch((err) => {
				dispatch(
					createUserFailure(
						_.get(
							err,
							['response', 'data'],
							'Something went wrong.'
						)
					)
				);
			});
	};
}

function updateUserStart() {
	return { type: actionTypes.UPDATE_USER_START };
}

function updateUserSuccess(payload) {
	return { type: actionTypes.UPDATE_USER_SUCCESS, payload };
}

function updateUserFailure(payload) {
	return { type: actionTypes.UPDATE_USER_FAILURE, payload };
}

function updateUserClear() {
	return { type: actionTypes.UPDATE_USER_CLEAR };
}

export function updateUser(data, uuid) {
	return (dispatch) => {
		dispatch(updateUserStart());
		return UserAccountsService.updateUser(data, uuid)
			.then((res) => {
				return dispatch(
					updateUserSuccess(
						_.get(
							res,
							['data', 'message'],
							'User updated successfully!'
						)
					)
				);
			})
			.catch((err) => {
				dispatch(
					updateUserFailure(
						_.get(
							err,
							['response', 'data'],
							'Something went wrong.'
						)
					)
				);
			});
	};
}

function startGetUserAuditTrail() {
	return { type: actionTypes.GET_AUDIT_TRAIL_USER_START };
}

function successGetUserAuditTrail(payload) {
	return { type: actionTypes.GET_AUDIT_TRAIL_USER_SUCCESS, payload };
}

function failureGetUserAuditTrail(payload) {
	return { type: actionTypes.GET_AUDIT_TRAIL_USER_FAILURE, payload };
}

export function getUserAuditTrail(userUUID) {
	return (dispatch) => {
		dispatch(startGetUserAuditTrail());
		return UserAccountsService.getAuditTrail(userUUID)
			.then((res) => {
				return dispatch(
					successGetUserAuditTrail(
						hydrateAuditTrail(
							_.get(res, ['data', 'data', 'data'], [])
						)
					)
				);
			})
			.catch((err) => {
				dispatch(failureGetUserAuditTrail(err));
			});
	};
}

export function clearData() {
	return (dispatch) => {
		dispatch(createUserClear());
		dispatch(updateUserClear());
	};
}

function deleteUserStart() {
	return { type: actionTypes.DELETE_USER_START };
}

function deleteUserSuccess(payload) {
	return { type: actionTypes.DELETE_USER_SUCCESS, payload };
}

function deleteUserFailure(payload) {
	return { type: actionTypes.DELETE_USER_FAILURE, payload };
}

export function deleteUser(uuid, data) {
	return (dispatch) => {
		dispatch(deleteUserStart());
		return UserAccountsService.deleteUser(uuid, data)
			.then((res) => {
				return dispatch(
					deleteUserSuccess(
						_.get(
							res,
							['data', 'message'],
							'User deleted successfully!'
						)
					)
				);
			})
			.catch((err) => {
				dispatch(
					deleteUserFailure(
						_.get(
							err,
							['response', 'data'],
							'Something went wrong.'
						)
					)
				);
			});
	};
}
