import React from 'react';

import AuditTrailData from './AuditTrailData';

const AuditTrailBody = ({ smsConsentLog, patientName, recontactConsentLog }) => {
	return (
		<div className='auditTrailBody'>
			<AuditTrailData
				smsConsentLog={smsConsentLog}
				recontactConsentLog = {recontactConsentLog}
				patientName={patientName}
			/>
		</div>
	);
};

export default AuditTrailBody;
