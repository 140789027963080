import axios from 'axios';

import {
	dehydrateInsuranceCompanyInfo,
	hydrateInsuranceCompanies,
} from '../transformers/insuranceCompanyTransformer';

import get from 'lodash/get';

class InsuranceCompanyService {
	getInsuranceCompanyInfo = (data) => {
		let url = '/api/insurance/companies';
		url += `?limit=${get(data, 'limit', '20')}&page=${get(
			data,
			'page',
			'1'
		)}&order_by=name&order_type=asc&partner_uuid=${get(
			data,
			'partner_uuid',
			''
		)}`;
		return axios
			.get(url)
			.then((res) => res && hydrateInsuranceCompanies(res));
	};

	addInsuranceCompanyInfo = (data) => {
		const url = '/api/insurance/companies';
		const body = dehydrateInsuranceCompanyInfo(data);
		return axios.post(url, body);
	};

	updateInsuranceCompanyInfo = (data, companyId) => {
		const url = '/api/insurance/companies/' + companyId;
		const body = dehydrateInsuranceCompanyInfo(data);
		return axios.post(url, body);
	};
}

export default new InsuranceCompanyService();
