//React & Redux
import React from 'react';

//Images
import actionRequired from '../../../assets/action-required.png';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Layout, Input, DatePicker } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Utils
import { createEnumsOptions } from '../../Patients/V2/Encounters/Common/utilsfunctions';

//Components
import { SelectComponent as Select } from '../../Common/SelectComponent';
import { InputField } from '../../Common/InputField';

const EditPatientInvoiceSegment = (props) => {
	const {
		billingStatusEnums,
		encounterBillingDetail: {
			patient_fee_refund: patientFeeRefund = '',
			patient_payment: patientPayment = '',
		},
		encounterDetail: {
			date_of_service: dateOfService = '',
			type,
			visit_status,
		},
		errors,
		handleChange,
		isBillingCoordinator,
		patientFeeEnum,
		stateData: {
			billingStatus = '',
			couponCode,
			patientFeeRefundReason,
			patientBillingAmount,
			patientBillingDate,
			patientFeeRefundDecision,
			patientFeeRefundProcessedDate,
			patientFeeRefundReasonOther,
			patientPay,
		},
	} = props;

	const refundDecisionOptions = [
		{ key: null, label: '', value: null },
		{ key: 'Refund', label: 'Refund', value: 'refund' },
		{ key: 'Not Refund', label: 'Not Refund', value: 'not_refund' },
	];

	const onNumberChange = (key, val) => {
		!_.isNaN(_.toNumber(val)) && handleChange(key, val);
	};

	const isPatientFeeDisabled = () => {
		return !(
			isBillingCoordinator &&
			type === 'visit' &&
			visit_status === 'completed'
		);
	};

	const determineDisabledDate = (current) => {
		const yesterday = dateOfService;
		const momentCurrent = moment(current);
		if (!_.isNil(yesterday))
			return momentCurrent.isBefore(moment(yesterday).clone());
		return true;
	};

	const textField = 'erFieldText inputFieldTextTaller';
	const dropdownField = 'inputFieldDropdown inputFieldDropdownTaller';
	const datePickerField = 'inputFieldDatePicker inputFieldDatePickerTaller';

	return (
		<>
			<Layout.Row gutter='20'>
				<Layout.Col span='24'>
					<div className='test-number-container'>
						<div className='test-number'>Patient Invoice</div>
						<div className='divider'></div>
					</div>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20'>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Coupon Code</span>
					<InputField
						trim={true}
						disabled={isPatientFeeDisabled()}
						value={couponCode}
						className={textField}
						onChange={(value) => handleChange('coupon_code', value)}
						error={errors['coupon_code']}
						extraErrorStyleOverride={{
							left: 'unset',
							position: 'relative',
							top: 'unset',
						}}
					/>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Payment Status</span>
					<span className='val-enctr-sgmnt-field'>
						<Input
							placeholder='--'
							trim={true}
							disabled={true}
							value={patientPayment}
							className={textField}
						/>
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Payment Fee</span>
					<span className='val-enctr-sgmnt-field'>
						<Input
							placeholder='$12.34'
							trim={true}
							disabled={isPatientFeeDisabled()}
							value={patientPay}
							onChange={(value) =>
								onNumberChange('patient_pay', value)
							}
							className={textField}
						/>
					</span>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20'>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>
						Billing Status (patient)
						{billingStatus == 'not_invoiced' && (
							<img
								className='action-required'
								src={actionRequired}
								alt=''
							/>
						)}
					</span>
					<span className='val-enctr-sgmnt-field'>
						<Select
							value={billingStatus}
							onChange={(value) =>
								handleChange('billing_status', value)
							}
							name='billing_status'
							className={dropdownField}
							clearable={true}
							typeahead={true}
							options={
								_.isArray(
									createEnumsOptions(
										props,
										['billingStatusEnums'],
										'key',
										'display_name',
										'key'
									)
								)
									? createEnumsOptions(
											props,
											['billingStatusEnums'],
											'key',
											'display_name',
											'key'
									  )
									: []
							}
						/>
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Billing Date</span>
					<span className='val-enctr-sgmnt-field'>
						<DatePicker
							placeholder='Select Date'
							format={'MM/dd/yyyy'}
							value={patientBillingDate}
							onChange={(value) =>
								handleChange('patient_billing_date', value)
							}
							isReadOnly={true}
							className={datePickerField}
						/>
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Billing Amount</span>
					<span className='val-enctr-sgmnt-field'>
						<Input
							placeholder='$12.34'
							trim={true}
							value={patientBillingAmount}
							onChange={(value) =>
								onNumberChange('patient_billing_amount', value)
							}
							className={textField}
						/>
					</span>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20' className='patient-fee-section'>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>Patient Fee Refund</span>
					<span className='val-enctr-sgmnt-field'>
						<Input
							placeholder='--'
							trim={true}
							disabled={true}
							value={patientFeeRefund}
							className={textField}
						/>
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>
						Patient Fee Refund Decision
					</span>
					<span className='val-enctr-sgmnt-field'>
						<Select
							value={patientFeeRefundDecision}
							onChange={(value) =>
								handleChange(
									'patient_fee_refund_decision',
									value
								)
							}
							name='patient_fee_refund_decision'
							className={dropdownField}
							clearable={true}
							typeahead={true}
							options={refundDecisionOptions}
						/>
					</span>
				</Layout.Col>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>
						Patient Fee Refund Processed Date
					</span>
					<span className='val-enctr-sgmnt-field'>
						<DatePicker
							placeholder='Select Date'
							format={'MM/dd/yyyy'}
							value={patientFeeRefundProcessedDate}
							onChange={(value) =>
								handleChange(
									'patient_fee_refund_processed_date',
									value
								)
							}
							isReadOnly={true}
							disabledDate={(current) =>
								determineDisabledDate(current)
							}
							className={datePickerField}
						/>
					</span>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='20' className='patient-fee-section'>
				<Layout.Col span='8'>
					<span className='lbl-enctr-sgmnt'>
						Patient Fee Refund Reason
					</span>
					<span className='val-enctr-sgmnt-field'>
						<Select
							value={patientFeeRefundReason}
							onChange={(value) =>
								handleChange('patient_fee_refund_reason', value)
							}
							name='patient_fee_refund_reason'
							className={dropdownField}
							clearable={true}
							typeahead={true}
							options={
								_.isArray(
									createEnumsOptions(
										props,
										['patientFeeEnum'],
										'key',
										'display_name',
										'key'
									)
								)
									? createEnumsOptions(
											props,
											['patientFeeEnum'],
											'key',
											'display_name',
											'key'
									  )
									: []
							}
						/>
					</span>
				</Layout.Col>
				{patientFeeRefundReason === 'other' && (
					<Layout.Col span='8'>
						<span className='lbl-enctr-sgmnt'>
							Other Refund Reason
						</span>
						<span>
							<Input
								placeholder='Specify other reason'
								trim={true}
								value={patientFeeRefundReasonOther}
								onChange={(value) =>
									handleChange(
										'patient_fee_refund_reason_other',
										value
									)
								}
								className={textField}
							/>
						</span>
					</Layout.Col>
				)}
			</Layout.Row>
		</>
	);
};

export default EditPatientInvoiceSegment;
