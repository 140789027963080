//React & Redux
import React, { useState, useEffect } from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from '../../../../assets/exit-alert.svg';
import breadcrumbArrow from '../../../../assets/menu-breadcrumb.svg';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Button, Loading } from 'gm-element-react';

//Utils
import {
	determineDropdownQuestionnaire,
	buildQuestionnairePayload,
	questionnaireNoneValue,
	determineShippingAddressRequired,
	isValidDateStringOREmpty,
} from '../Encounters/Common/utilsfunctions';
import validPostalCode from 'postcode-validator';

//Other Libraries
import moment from 'moment';
import EmailValidator from 'email-validator';
import { isValidPhoneNumber } from 'libphonenumber-js';

//Components
import CreateTestRequestModal from './CreateTestRequestModal';
import AOEQuestions from './AOEQuestions';
import ICDSelectionComponent from '../../ChargeInformation/ICDSelectionComponent';

//Styles
import './create-test-request-modal.css';

const CREATEFORMSTEP = 'createform';
const AOESTEP = 'aoe';
const ICDSTEP = 'icd';
const cascaderfields = {
	order_status: 1,
};
const RequiredFields = [
	'lab',
	'test_name',
	'order_status',
	'sample_type',
	'patient_billing_method',
];
const PatientDeclinedSubCategories = {
	declined_other: 1,
	no_fu_from_patient: 1,
	iss_w_test_implications: 1,
	ins_denied_cost: 1,
};

const DateTypes = ['date_any', 'date_past', 'date_future'];

const CreateTestOrderModalContainer = (props) => {
	const {
		testOrderNumber = 1,
		encounter = {},
		patientdetail = {},
		testnameenums = [],
		sampletypeenums = [],
		patientbillingmethodenums = [],
		mloOrderStatusEnums = [],
		encounterICDcodes = [],
		ordering_physicianenums = [],
		nonlegacytestOrderPopupTestEnumsLoading = false,
		nonlegacytestOrderPopupTestEnums = [],
		orderrequeststatuscascadeenums = [],
		labenums = [],
		testorder = {},
		visitsschema = {},
		user = {},
		icdCodesError,
		icdCodesLoading,
		icdCodesPlacement,
		icdCodes,
		getICDCodes,
		enqueueSnackbar,
		LoadTestOrderPopupTestEnum,
		saveTestOrderRequest,
		hideModal,
		cancel,
	} = props;

	const [currentStep, setCurrentStep] = useState(CREATEFORMSTEP);
	const [formdata, setFormdata] = useState({
		lab: '',
		test_name: '',
		ordering_physician: '',
		order_status: '',
		sample_type: '',
		note: '',
		patient_billing_method: '',
		order_questionnaire: [],
		lab_integration_enabled: false,
	});
	const [aoeQuestions, setAOEQuestions] = useState([]);
	const [aoeAnswers, setAOEAnswers] = useState({});
	const [errors, setErrors] = useState({});
	const [selectedICDCodes, setSelectedICDCodes] = useState([]);
	const [loading, setLoading] = useState(false);
	const [userSelectedICDCodes, setUserSelectedICDCodes] = useState(null);
	const [selectedTestRequestFields, setSelectedTestRequestFields] = useState(
		{}
	);
	const [shippingAddressDataErrors, setShippingAddressDataErrors] = useState(
		{}
	);
	const [shippingAddressFormData, setShippingAddressFormData] = useState({});
	const [
		addAnotherTestNameFormDataErrors,
		setAddAnotherTestNameFormDataErrors,
	] = useState({});
	const [addAnotherTestNameFormData, setAddAnotherTestNameFormData] =
		useState({
			addTest2: 'no',
			test_name2: undefined,
			addTest3: 'no',
			test_name3: undefined,
		});
	const [reflexAOEQuestions, setReflexAOEQuestions] = useState([]);
	const [reflexAOEAnswers, setReflexAOEAnswers] = useState({});
	const [aoeAnswersErrors, setAoeAnswersErrors] = useState({});

	useEffect(() => {
		const _encounterICDcodes = encounterICDcodes;
		const selectedICDCodes = _encounterICDcodes.map((obj) => {
			return obj;
		});
		setSelectedICDCodes(selectedICDCodes);
		const isShippingAddressExist = !_.isEmpty(
			_.filter(_.get(patientdetail, ['address'], []), (o) => {
				return _.get(o, ['type'], []).includes('delivery');
			})
		);
		const _shippingAddress = _.filter(
			_.get(patientdetail, ['address'], []),
			(o) => {
				return _.get(o, ['type'], []).includes('delivery');
			}
		);
		const shippingAddressFormData = {
			patient_address_line1: isShippingAddressExist
				? _.get(_shippingAddress, ['0', 'address_line1'], '')
				: '',
			patient_address_line2: isShippingAddressExist
				? _.get(_shippingAddress, ['0', 'address_line2'], '')
				: '',
			patient_shipping_address_city: isShippingAddressExist
				? _.get(_shippingAddress, ['0', 'city'], '')
				: '',
			patient_shipping_address_state: isShippingAddressExist
				? _.get(_shippingAddress, ['0', 'state'], '')
				: '',
			patient_shipping_address_zipcode: isShippingAddressExist
				? _.get(_shippingAddress, ['0', 'zip'], '')
				: '',
		};
		setShippingAddressFormData(shippingAddressFormData);
	}, []);

	useEffect(() => {
		setLoading(nonlegacytestOrderPopupTestEnumsLoading?.loading || false);
	}, [nonlegacytestOrderPopupTestEnumsLoading]);

	const onFormChange = (key, value) => {
		const _formdata = formdata;
		const prevQuestionnaireFormData =
			_.cloneDeep(_formdata['order_questionnaire']) || [];
		formdata[key] = value;
		if (key == 'lab') {
			LoadTestOrderPopupTestEnum(value);
			_formdata[key] = value;
			_formdata['test_name'] = '';
			_formdata['order_questionnaire'] = [];
			setAddAnotherTestNameFormData((oldState) => ({
				...oldState,
				test_name2: '',
				addTest2: '',
				test_name3: '',
				addTest3: '',
			}));
			_formdata['lab_integration_enabled'] = false;
			setAOEAnswers({});
			setReflexAOEAnswers({});
		}
		if (key == 'test_name') {
			const testData = _.find(nonlegacytestOrderPopupTestEnums || [], {
				key: value,
			});
			_formdata[key] = value;
			_formdata['order_questionnaire'] = [];
			if (!_.isEmpty(value) && !_.isNil(value)) {
				const testName = _.find(
					nonlegacytestOrderPopupTestEnums || [],
					{ key: value }
				);
				_formdata['lab_integration_enabled'] =
					testName?.lab_integration_enabled || false;
			} else {
				setAOEAnswers({});
				setReflexAOEAnswers({});
			}
			setAddAnotherTestNameFormData((oldState) => ({
				...oldState,
				test_name2: '',
				addTest2: '',
				test_name3: '',
				addTest3: '',
			}));
			if (
				!_.isEmpty(testData?.order_questionnaire) &&
				_.isArray(testData?.order_questionnaire)
			) {
				setAOEQuestions(testData?.order_questionnaire);
				for (const {
					default_option,
					name,
				} of testData.order_questionnaire) {
					if (default_option)
						setAOEAnswers((prevState) => {
							return {
								...prevState,
								[name]: default_option,
							};
						});
				}
			}
			if (
				!_.isEmpty(testData?.reflex_questionnaire) &&
				_.isArray(testData?.reflex_questionnaire)
			) {
				setReflexAOEQuestions(testData?.reflex_questionnaire || []);
				for (const {
					default_option,
					name,
				} of testData.reflex_questionnaire) {
					if (default_option)
						setReflexAOEAnswers((prevState) => {
							return {
								...prevState,
								[name]: default_option,
							};
						});
				}
			} else {
				setReflexAOEQuestions([]);
			}
		}
		if (key == 'order_questionnaire') {
			if (
				Array.isArray(value) &&
				value.includes(questionnaireNoneValue) &&
				!prevQuestionnaireFormData.includes(questionnaireNoneValue)
			) {
				_formdata['order_questionnaire'] = [questionnaireNoneValue];
			}

			if (
				Array.isArray(value) &&
				value.length === 2 &&
				prevQuestionnaireFormData.length === 1 &&
				prevQuestionnaireFormData.includes(questionnaireNoneValue)
			) {
				_.remove(
					_formdata['order_questionnaire'],
					(el) => el === questionnaireNoneValue
				);
			}
		}
		if (key == 'lab_integration_enabled') {
			_formdata[key] = value;
		}
		if (key in cascaderfields) {
			_formdata[key] = _.last(value);
		}
		const _errors = validateForm(_formdata);
		setErrors(_errors);
		setFormdata(_formdata);
	};

	const onShippingAddressFormChange = (key, value) => {
		const _shippingAddressFormData = shippingAddressFormData;
		let _shippingAddressDataErrors = {};

		_shippingAddressFormData[key] = value;
		_shippingAddressDataErrors = validateShippingAddressForm(
			_shippingAddressFormData
		);
		setShippingAddressDataErrors(_shippingAddressDataErrors);
		setShippingAddressFormData(_shippingAddressFormData);
	};

	const setSelectedICDCodesFunc = (selectedICDCodes) => {
		if (selectedICDCodes && Array.isArray(selectedICDCodes)) {
			setSelectedICDCodes(selectedICDCodes.slice());
		}
	};

	const validateShippingAddressForm = (shippingAddressFormData) => {
		const shippingAddressDataErrors = {};
		const requiresShippingAddressFields = determineShippingAddressRequired(
			formdata['test_name'],
			nonlegacytestOrderPopupTestEnums,
			_.get(formdata, ['order_status'], {})
		);

		if (requiresShippingAddressFields) {
			if (
				_.isEmpty(shippingAddressFormData['patient_address_line1']) ||
				_.isNil(shippingAddressFormData['patient_address_line1'])
			) {
				shippingAddressDataErrors['patient_address_line1'] =
					'Address is required';
			}

			if (
				!_.isEmpty(shippingAddressFormData['patient_address_line1`']) &&
				!shippingAddressFormData['patient_address_line1'].match(
					/((?:[A-Za-z0-9-\—\ '/,.()!@$%#]+$)*[a-zA-Z0-9])/
				)
			) {
				shippingAddressDataErrors['patient_address_line1'] =
					'Address is invalid';
			}

			if (
				_.isEmpty(
					shippingAddressFormData['patient_shipping_address_city']
				) ||
				_.isNil(
					shippingAddressFormData['patient_shipping_address_city']
				)
			) {
				shippingAddressDataErrors['patient_shipping_address_city'] =
					'City is required';
			}

			if (
				!_.isEmpty(
					shippingAddressFormData['patient_shipping_address_city']
				) &&
				!/^[a-zA-Z,\d\s-'.`]+$/.test(
					shippingAddressFormData['patient_shipping_address_city']
				)
			) {
				shippingAddressDataErrors['patient_shipping_address_city'] =
					'City is invalid';
			}

			if (
				_.isEmpty(
					shippingAddressFormData['patient_shipping_address_state']
				) ||
				_.isNil(
					shippingAddressFormData['patient_shipping_address_state']
				)
			) {
				shippingAddressDataErrors['patient_shipping_address_state'] =
					'State is required';
			}

			if (
				_.isEmpty(
					shippingAddressFormData['patient_shipping_address_zipcode']
				) ||
				_.isNil(
					shippingAddressFormData['patient_shipping_address_zipcode']
				)
			) {
				shippingAddressDataErrors['patient_shipping_address_zipcode'] =
					'Zip is required';
			}

			if (
				!_.isEmpty(
					shippingAddressFormData['patient_shipping_address_zipcode']
				) &&
				!validPostalCode.validate(
					shippingAddressFormData['patient_shipping_address_zipcode'],
					'US'
				)
			) {
				shippingAddressDataErrors['patient_shipping_address_zipcode'] =
					'Zip is invalid';
			}
		}

		return shippingAddressDataErrors;
	};

	const validateAddAnotherTestNameForm = (addAnotherTestNameFormData) => {
		const addAnotherTestNameFormDataErrors = {};

		if (
			addAnotherTestNameFormData['addTest2'] == 'yes' &&
			(_.isNil(addAnotherTestNameFormData['test_name2']) ||
				_.isEmpty(addAnotherTestNameFormData['test_name2']))
		) {
			addAnotherTestNameFormDataErrors['test_name2'] =
				'Test Name 2 is required';
		}

		if (
			addAnotherTestNameFormData['addTest3'] == 'yes' &&
			(_.isNil(addAnotherTestNameFormData['test_name3']) ||
				_.isEmpty(addAnotherTestNameFormData['test_name3']))
		) {
			addAnotherTestNameFormDataErrors['test_name3'] =
				'Test Name 3 is required';
		}

		return addAnotherTestNameFormDataErrors;
	};

	const onAddAnotherTestNameFormChange = (key, value) => {
		const _addAnotherTestNameFormData = addAnotherTestNameFormData;
		let _addAnotherTestNameFormDataErrors = {};

		_addAnotherTestNameFormData[key] = value;
		_addAnotherTestNameFormDataErrors = validateAddAnotherTestNameForm(
			_addAnotherTestNameFormData
		);
		setAddAnotherTestNameFormDataErrors(_addAnotherTestNameFormDataErrors);
		setAddAnotherTestNameFormData((oldState) => ({
			...oldState,
			key: value,
		}));
	};

	const saveUserICDCodes = () => {
		setLoading(true);
		const medical_codes = selectedICDCodes.map((obj) => ({
			code_name: obj.code_name,
			uuid: obj.uuid,
		}));
		let groupedTestOrder = false;
		let newdata = {
			lab: formdata['lab'],
			sample_type: formdata['sample_type'],
			genetic_test_uuid: formdata['test_name'],
			ordering_physician: formdata['ordering_physician'],
			order_status: formdata['order_status'],
			medical_codes: medical_codes,
			patient_billing_method: formdata['patient_billing_method'],
			skip_lab_integration: !formdata['lab_integration_enabled'],
		};

		const newdataArray = [];
		let testOrderData = '';

		if (formdata['order_status'] in PatientDeclinedSubCategories) {
			newdata = {
				...newdata,
				order_status: 'patient_declined',
				status_reason: formdata['order_status'] || '',
			};
		} else {
			newdata = {
				...newdata,
				order_status: formdata['order_status'],
				status_reason: '',
			};
		}

		if (formdata['order_questionnaire']) {
			const questionnaire = determineDropdownQuestionnaire(
				formdata['test_name'],
				nonlegacytestOrderPopupTestEnums
			);
			const questionnairePayload = buildQuestionnairePayload(
				formdata['order_questionnaire'],
				questionnaire
			);
			if (questionnairePayload) {
				newdata = {
					...newdata,
					order_questionnaire: questionnairePayload,
				};
			}
		}

		if (formdata?.lab_integration_enabled && !_.isEmpty(aoeAnswers)) {
			const order_questionnaire = {};

			for (const [key, value] of Object.entries(aoeAnswers)) {
				if (value) {
					const question = _.find(aoeQuestions, { name: key });
					order_questionnaire[question?.question_text] = [
						DateTypes.includes(question.type)
							? moment(value).format('YYYY-MM-DD')
							: value,
					];
				}
			}

			if (!_.isEmpty(order_questionnaire))
				newdata = {
					...newdata,
					order_questionnaire,
				};
		}

		if (
			formdata?.lab_integration_enabled &&
			!_.isEmpty(reflexAOEAnswers) &&
			!_.isNil(reflexAOEAnswers?.is_reflex)
		) {
			const reflex_questionnaire = {};

			for (const [key, value] of Object.entries(reflexAOEAnswers)) {
				if (value) {
					const reflex_question = _.find(reflexAOEQuestions, {
						name: key,
					});
					reflex_questionnaire[reflex_question?.question_text] = [
						DateTypes.includes(reflex_question.type)
							? moment(value).format('YYYY-MM-DD')
							: value,
					];
				}
			}

			if (!_.isEmpty(reflex_questionnaire))
				newdata = {
					...newdata,
					reflex_questionnaire,
				};
		}

		newdataArray.push(newdata);
		if (
			!_.isNil(addAnotherTestNameFormData['test_name2']) &&
			!_.isEmpty(addAnotherTestNameFormData['test_name2']) &&
			addAnotherTestNameFormData['addTest2'] === 'yes' &&
			formdata?.lab_integration_enabled
		) {
			const addTestName2 = { ...newdata };
			delete addTestName2.genetic_test_uuid;
			addTestName2.genetic_test_uuid = addAnotherTestNameFormData['test_name2'];
			newdataArray.push(addTestName2);
		}
		if (
			!_.isNil(addAnotherTestNameFormData['test_name3']) &&
			!_.isEmpty(addAnotherTestNameFormData['test_name3']) &&
			addAnotherTestNameFormData['addTest3'] === 'yes' &&
			formdata?.lab_integration_enabled
		) {
			const addTestName3 = { ...newdata };
			delete addTestName3.genetic_test_uuid;
			addTestName3.genetic_test_uuid =
				addAnotherTestNameFormData['test_name3'];
			newdataArray.push(addTestName3);
		}

		let notedata = [];
		if (!_.isEmpty(formdata['note'])) {
			const userFullName =
				_.get(user, ['first_name'], '') +
				' ' +
				_.get(user, ['last_name'], '');

			notedata = [
				{
					author: userFullName,
					note: _.get(formdata, ['note'], ''),
					note_drafted_time: moment()
						.utc()
						.format('YYYY-MM-DD HH:mm:ss'),
				},
			];
		}

		const requiresShippingAddressFields = determineShippingAddressRequired(
			formdata['test_name'],
			nonlegacytestOrderPopupTestEnums,
			_.get(formdata, ['order_status'], {})
		);
		let _shippingAddressFormData = shippingAddressFormData;

		if (requiresShippingAddressFields) {
			const data = {};
			const addressArr = _.cloneDeep(
				_.get(patientdetail, ['address'], [])
			);
			const addressObjectsWithoutDelivery = [];

			addressArr.forEach((obj) => {
				if (obj.type.includes('delivery') && obj.type.length !== 1) {
					let idx;
					const newAddressWithoutDelivery = { ...obj };
					_.remove(newAddressWithoutDelivery.type, (t, i) => {
						if (t === 'delivery') {
							idx = i;
							return true;
						}
					});
					_.remove(
						newAddressWithoutDelivery.user_address_type_id,
						(typeId, i) => {
							if (i === idx) {
								return true;
							}
						}
					);
					addressObjectsWithoutDelivery.push(
						newAddressWithoutDelivery
					);
				} else if (!obj.type.includes('delivery')) {
					addressObjectsWithoutDelivery.push(obj);
				}
			});

			const {
				patient_address_line1,
				patient_address_line2,
				patient_shipping_address_city,
				patient_shipping_address_state,
				patient_shipping_address_zipcode,
			} = shippingAddressFormData;

			const newAddress = {};

			newAddress.full_name =
				`${_.get(patientdetail, 'first_name', '')} ${_.get(
					patientdetail,
					'last_name',
					''
				)}` || '';
			newAddress.address_line1 = patient_address_line1 || '';
			newAddress.address_line2 = patient_address_line2 || '';
			newAddress.city = patient_shipping_address_city || '';
			newAddress.state = patient_shipping_address_state || '';
			newAddress.zip = patient_shipping_address_zipcode || '';
			newAddress.user_id = patientdetail.address?.[0]?.user_id || null;
			newAddress.type = ['delivery'];

			data.patient_id = _.get(patientdetail, 'uuid', null);
			data.payload = [...addressObjectsWithoutDelivery, newAddress];
			_shippingAddressFormData = data;
		} else {
			_shippingAddressFormData = '';
		}

		if (
			((!_.isNil(addAnotherTestNameFormData['test_name2']) &&
				!_.isEmpty(addAnotherTestNameFormData['test_name2'])) ||
				(!_.isEmpty(addAnotherTestNameFormData['test_name3']) &&
					!_.isEmpty(addAnotherTestNameFormData['test_name3']))) &&
			(addAnotherTestNameFormData['addTest2'] === 'yes' ||
				addAnotherTestNameFormData['addTest3'] === 'yes') &&
			formdata?.lab_integration_enabled
		) {
			testOrderData = newdataArray;
			groupedTestOrder = true;
		} else {
			testOrderData = newdata;
		}

		return saveTestOrderRequest(
			testOrderData,
			notedata,
			_shippingAddressFormData,
			groupedTestOrder
		)
			.then(() => {
				setLoading(false);
				hideModal();
				cancel();
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const validateForm = (formdata) => {
		const errors = {};
		RequiredFields.map((key) => {
			if (
				_.isNil(_.get(formdata, key)) ||
				_.isEmpty(_.get(formdata, key)) ||
				(key == 'order_status' && _.get(formdata, key) == 'empty')
			) {
				errors[key] = true;
			}
		});

		const requiresQuestionnaireFields = determineDropdownQuestionnaire(
			formdata['test_name'],
			nonlegacytestOrderPopupTestEnums
		);
		if (
			requiresQuestionnaireFields &&
			_.get(formdata, 'order_questionnaire.length', 0) === 0
		) {
			errors['order_questionnaire'] = true;
		}

		return errors;
	};

	const isNextDisabled = () => {
		const error = validateForm(formdata);
		const shippingAddressDataErrors = validateShippingAddressForm(
			shippingAddressFormData
		);
		const requiresShippingAddressFields = determineShippingAddressRequired(
			formdata['test_name'],
			nonlegacytestOrderPopupTestEnums,
			_.get(formdata, ['order_status'], {})
		);
		if (requiresShippingAddressFields) {
			return !_.isEmpty(shippingAddressDataErrors) || !_.isEmpty(error);
		}

		if (
			addAnotherTestNameFormData['addTest2'] === 'yes' ||
			addAnotherTestNameFormData['addTest3'] === 'yes'
		) {
			const addAnotherTestNameFormDataErrors =
				validateAddAnotherTestNameForm(addAnotherTestNameFormData);
			return (
				!_.isEmpty(addAnotherTestNameFormDataErrors) ||
				!_.isEmpty(error)
			);
		}
		return !_.isEmpty(error);
	};

	const navigateToCurrentStep = () => {
		let hasAOEAnswerError = false;
		if (!_.isEmpty(aoeQuestions)) {
			for (const { name, type } of aoeQuestions) {
				if (
					type === 'email' &&
					name in aoeAnswers &&
					!_.isEmpty(aoeAnswers[name]) &&
					!EmailValidator.validate(aoeAnswers[name])
				) {
					setAoeAnswersErrors((prevState) => {
						return {
							...prevState,
							[name]: 'Email is invalid',
						};
					});
					hasAOEAnswerError = true;
				} else if (
					type === 'phone' &&
					name in aoeAnswers &&
					!_.isEmpty(aoeAnswers[name]) &&
					!isValidPhoneNumber(aoeAnswers[name])
				) {
					setAoeAnswersErrors((prevState) => {
						return {
							...prevState,
							[name]: 'Phone number is invalid',
						};
					});
					hasAOEAnswerError = true;
				} else if (
					DateTypes.includes(type) &&
					name in aoeAnswers &&
					!_.isEmpty(aoeAnswers[name]) &&
					!isValidDateStringOREmpty(aoeAnswers[name])
				) {
					setAoeAnswersErrors((prevState) => {
						return {
							...prevState,
							[name]: 'Date is invalid',
						};
					});
					hasAOEAnswerError = true;
				} else {
					setAoeAnswersErrors((prevState) => {
						return {
							...prevState,
							[name]: '',
						};
					});
				}
			}
			if (!hasAOEAnswerError) {
				setCurrentStep(ICDSTEP);
			}
		}
	};

	const isRequiredReflexAOEQuestion = (required, dependsOn, answers) => {
		if (required === 'yes') {
			return true;
		} else if (
			required === 'conditional' &&
			!_.isEmpty(dependsOn) &&
			_.get(answers, [dependsOn.name], null) === dependsOn.value
		) {
			return true;
		} else if (required === 'no') {
			return false;
		}
		return false;
	};

	const isAOEFormValid = () => {
		if (!_.isEmpty(aoeQuestions)) {
			for (const {
				name,
				is_required,
				type,
				depends_on,
			} of aoeQuestions) {
				if (DateTypes.includes(type)) {
					if (
						isRequiredReflexAOEQuestion(
							is_required,
							depends_on,
							aoeAnswers
						) &&
						(!_.has(aoeAnswers, name) ||
							!moment(_.get(aoeAnswers, name)).isValid())
					) {
						return false;
					}
				} else {
					if (
						isRequiredReflexAOEQuestion(
							is_required,
							depends_on,
							aoeAnswers
						) &&
						(!_.has(aoeAnswers, name) ||
							_.toString(_.get(aoeAnswers, name)).length === 0 ||
							_.isNil(_.get(aoeAnswers, name)))
					) {
						return false;
					}
				}
			}
		}

		return true;
	};

	const isReflexAOEFormValid = () => {
		if (!_.isEmpty(reflexAOEQuestions)) {
			for (const {
				name,
				is_required,
				type,
				depends_on,
			} of reflexAOEQuestions) {
				if (DateTypes.includes(type)) {
					if (
						isRequiredReflexAOEQuestion(
							is_required,
							depends_on,
							reflexAOEAnswers
						) &&
						(!_.has(reflexAOEAnswers, name) ||
							!moment(_.get(reflexAOEAnswers, name)).isValid())
					) {
						return false;
					}
				} else {
					if (
						isRequiredReflexAOEQuestion(
							is_required,
							depends_on,
							reflexAOEAnswers
						) &&
						(!_.has(reflexAOEAnswers, name) ||
							_.isEmpty(_.get(reflexAOEAnswers, name)) ||
							_.isNil(_.get(reflexAOEAnswers, name)))
					) {
						return false;
					}
				}
			}
		}

		return true;
	};

	const hasPatientNoInsurance = () => {
		const lab = _.get(formdata, ['lab'], '');
		const labvalue = labenums.find((a) => a.key === lab);
		if (
			(formdata?.patient_billing_method || '').toLowerCase() ===
				'insurance' &&
			_.isEmpty(patientdetail?.insurances || []) &&
			(labvalue?.display_name || '').toLowerCase() === 'invitae'
		) {
			return true;
		}
		return false;
	};

	return (
		<div className='createTestOrderModalContainer create-testorder-modal-container-v2'>
			<Loading loading={loading}>
				<div className='createTestOrderModalTopRow'>
					<div className='createTestOrderModalHeaderBreadcrumbs'>
						<div className='createTestOrderModalHeader'>
							Request Test Order #{testOrderNumber}
						</div>
						<div className='createTestOrderModalBreadcrumbs'>
							<span
								className={
									currentStep == CREATEFORMSTEP
										? 'createTestOrderModalBreadcrumbsCurrent'
										: ''
								}
							>
								Enter Test information
							</span>
							{_.get(formdata, ['test_name'], null) &&
								_.get(
									formdata,
									['lab_integration_enabled'],
									false
								) && (
									<>
										<ReactSVG
											src={breadcrumbArrow}
											className='createTestOrderModalBreadcrumbArrow'
										/>
										<span
											className={
												currentStep == AOESTEP
													? 'createTestOrderModalBreadcrumbsCurrent'
													: ''
											}
										>
											Ask on Order Entry Questions
										</span>
									</>
								)}
							<ReactSVG
								src={breadcrumbArrow}
								className='createTestOrderModalBreadcrumbArrow'
							/>
							<span
								className={
									currentStep == ICDSTEP
										? 'createTestOrderModalBreadcrumbsCurrent'
										: ''
								}
							>
								Select Test Request ICD-10 Code(s)
							</span>
						</div>
					</div>
					<div className='createTestOrderModalClose'>
						<ReactSVG
							src={xIcon}
							style={{
								position: 'relative',
								bottom: '2px',
								right: '1.25px',
							}}
							onClick={(e) => {
								hideModal();
							}}
						/>
					</div>
				</div>
				<div className='crte-test-rqst-dialog crte-test-rqst-dialog-v2'>
					{currentStep === CREATEFORMSTEP && (
						<CreateTestRequestModal
							ordering_physicianenums={ordering_physicianenums}
							labenums={labenums}
							nonlegacytestOrderPopupTestEnums={
								nonlegacytestOrderPopupTestEnums
							}
							orderrequeststatuscascadeenums={
								orderrequeststatuscascadeenums
							}
							selectedTestRequestFields={
								selectedTestRequestFields
							}
							userSelectedICDCodes={userSelectedICDCodes}
							formdata={formdata}
							onFormChange={onFormChange}
							visitsschema={visitsschema}
							testorder={testorder}
							testnameenums={testnameenums}
							encounter={encounter}
							patientdetail={patientdetail}
							sampletypeenums={sampletypeenums}
							patientbillingmethodenums={
								patientbillingmethodenums
							}
							mloOrderStatusEnums={mloOrderStatusEnums}
							testOrderNumber={testOrderNumber}
							shippingAddressFormData={shippingAddressFormData}
							onShippingAddressFormChange={
								onShippingAddressFormChange
							}
							addAnotherTestNameFormData={
								addAnotherTestNameFormData
							}
							onAddAnotherTestNameFormChange={
								onAddAnotherTestNameFormChange
							}
							reflexAOEQuestions={reflexAOEQuestions}
							reflexAOEAnswers={reflexAOEAnswers}
							setReflexAnswers={setReflexAOEAnswers}
							hideModal={hideModal}
							hasPatientNoInsurance={hasPatientNoInsurance}
						/>
					)}
					{currentStep === AOESTEP && (
						<AOEQuestions
							isEditable={true}
							questions={aoeQuestions}
							answers={aoeAnswers}
							setAnswer={setAOEAnswers}
							encounter={encounter}
							patientdetail={patientdetail}
							aoeAnswersErrors={aoeAnswersErrors}
						/>
					)}
					{currentStep === ICDSTEP && (
						<ICDSelectionComponent
							icdCodesError={icdCodesError}
							icdCodesLoading={icdCodesLoading}
							icdCodesPlacement={icdCodesPlacement}
							icdCodes={icdCodes}
							selectedICDCodes={selectedICDCodes}
							getICDCodes={getICDCodes}
							enqueueSnackbar={enqueueSnackbar}
							setSelectedICDCodes={setSelectedICDCodesFunc}
							encounterICDcodes={encounterICDcodes}
						/>
					)}
				</div>
				{currentStep == CREATEFORMSTEP && (
					<div className='createTestOrderModalButtonRow justify-content-end'>
						<Button
							className='createTestOrderModalActionButton'
							disabled={
								isNextDisabled() ||
								!isReflexAOEFormValid() ||
								hasPatientNoInsurance()
							}
							onClick={() => {
								setCurrentStep(
									!formdata?.lab_integration_enabled
										? ICDSTEP
										: AOESTEP
								);
							}}
						>
							Next
						</Button>
					</div>
				)}
				{currentStep == AOESTEP && (
					<div className='createTestOrderModalButtonRow justify-content-between'>
						<Button
							className='createTestOrderModalBackButton'
							onClick={() => {
								setCurrentStep(CREATEFORMSTEP);
							}}
						>
							{' '}
							BACK
						</Button>
						<Button
							className='createTestOrderModalActionButton'
							disabled={!isAOEFormValid()}
							onClick={() => navigateToCurrentStep()}
						>
							Next
						</Button>
					</div>
				)}
				{currentStep == ICDSTEP && (
					<div className='createTestOrderModalButtonRow justify-content-between'>
						<Button
							className='createTestOrderModalBackButton'
							onClick={() => {
								setCurrentStep(
									!formdata?.lab_integration_enabled
										? CREATEFORMSTEP
										: AOESTEP
								);
							}}
						>
							{' '}
							BACK
						</Button>
						<Button
							className='createTestOrderModalActionButton'
							disabled={loading}
							onClick={saveUserICDCodes}
						>
							Save
						</Button>
					</div>
				)}
			</Loading>
		</div>
	);
};

export default CreateTestOrderModalContainer;
