import React, { Component, Fragment } from 'react';
import get from 'lodash/get';

import RBAC from '../RBAC';
import AddMultiVisitEncounterModal from './V2/Encounters/AddMultiVisitEncounter/AddMultiVisitEncounterModal';
import { CARE_PATIENTS_PATIENT_ENCOUNTER_LIST } from '../../Utils/permissionUtils';
import EncounterList from './EncounterList';
import AddEncounterV2 from './V2/Encounters/AddEncounter';
import { AddEncounterTypes } from '../../utils';

const CHILD_COMPONENTS = {
	ENCOUNTER_LIST: 'encounter-list',
	ADD_ENCOUNTER: 'add-encounter',
};

export default class EncounterListContainer extends Component {
	constructor() {
		super();
		this.state = {
			showMultiPatientVisitEncounterModal: false,
			currentView: CHILD_COMPONENTS.ENCOUNTER_LIST,
			addEncounterType: null,
			primaryPatientInfo: {},
		};
	}

	renderComponent() {
		const { currentView } = this.state;

		if (currentView === CHILD_COMPONENTS.ADD_ENCOUNTER) {
			return (
				<AddEncounterV2
					changeEncounterTab={this.props.changeEncounterTab}
					patientdetail={get(this, 'props.patientdetail[0]', {})}
					match={this.props.match}
					openAppointmentAlert={this.props.openAppointmentAlert}
					getEncounterList={this.props.getEncounterList}
					enqueueSnackbar={this.props.enqueueSnackbar}
					addEncounterType={this.state.addEncounterType}
					icdCodes={this.props.icdCodes}
					icdCodesLoading={this.props.icdCodesLoading}
					icdCodesError={this.props.icdCodesError}
					icdCodesPlacement={this.props.icdCodesPlacement}
					getICDCodes={this.props.getICDCodes}
					showEncounterList={() => {
						this.setState({
							currentView: CHILD_COMPONENTS.ENCOUNTER_LIST,
						});
						this.props.resetPaginationCounter();
					}}
					primaryPatientInfo={this.state.primaryPatientInfo}
					history={this.props.history}
				/>
			);
		}

		return (
			<RBAC
				action={CARE_PATIENTS_PATIENT_ENCOUNTER_LIST}
				yes={
					<EncounterList
						{...this.props}
						updateAddEncountedType={(addEncounterType) =>
							this.setState({ addEncounterType })
						}
						showAddEncounter={() =>
							this.setState({
								currentView: CHILD_COMPONENTS.ADD_ENCOUNTER,
							})
						}
						showMultiPatientVisitEncounterModal={() => {
							this.setState({
								showMultiPatientVisitEncounterModal: true,
							});
						}}
					/>
				}
			/>
		);
	}

	render() {
		return (
			<Fragment>
				{this.state.showMultiPatientVisitEncounterModal && (
					<AddMultiVisitEncounterModal
						currentPatient={get(this, ['props', 'uuid'], null)}
						hideMultiVisitEncounterModal={() => {
							this.setState({
								showMultiPatientVisitEncounterModal: false,
							});
						}}
						handleSubmit={(data) => {
							const _primaryPatientInfo = {
								patient: get(data, ['patient', 'selected'], {}),
								encounter: get(
									data,
									['encounter', 'selected'],
									{}
								),
							};

							this.setState({
								currentView: CHILD_COMPONENTS.ADD_ENCOUNTER,
								addEncounterType:
									AddEncounterTypes.MULTI_PATIENT_VISIT,
								primaryPatientInfo: _primaryPatientInfo,
							});
						}}
					/>
				)}
				{this.renderComponent()}
			</Fragment>
		);
	}
}
