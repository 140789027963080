import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPatientTags } from '../../../../actions/patients';

import MultiSelectCheckbox from '../../../Filter/MultiSelectCheckbox';

//Utils
import { PATIENT_TAG } from '../../../../utils';

/**
 * Structure for representing tag value
 * @typedef {Array<{ name: string; property?: Record<string, string[]>}>} TagValue
 *
 * Legacy tag property format
 * @typedef {string[]} LegacyTagProperty
 *
 * Advanced tag property format
 * @typedef {{values: Array<{ value: string; in_use: boolean; }>}} AdvancedTagProperty
 *
 * Tag descriptor
 * @typedef {{ id: number; uuid: string; created_at: string; name: string; display_name: string; tag_properties: Record<string, LegacyTagProperty | AdvancedTagProperty> | null;}} PatientTag
 */

/**
 * @param {PatientTag[]} patientTags
 * @param {TagValue} value
 * @param {string[]} selectedTags
 * @returns {TagValue}
 */
function setTagValue(patientTags, value, selectedTags) {
	let worklist = [...selectedTags];
	// Remove values
	const newValue = value.filter((item) => {
		const idx = worklist.findIndex((tag) => item.name === tag);
		if (idx > -1) {
			delete worklist[idx];
			return true;
		}
		return false;
	});

	// Add new values
	worklist.forEach((item) => {
		const definition = patientTags.find((it) => it.name === item);
		if (definition) {
			const defaultValue = { name: definition.name };
			if (definition.tag_properties) {
				defaultValue.property = Object.entries(
					definition.tag_properties
				).reduce((acc, [k, v]) => {
					if (Array.isArray(v)) {
						// legacy tag format, v is a list of strings
						acc[k] = [...v];
					} else {
						// advanced tag format, v is an object
						acc[k] = v.values.map((value) => value.value);
					}
					return acc;
				}, {});
			}
			newValue.push(defaultValue);
		}
	});

	return newValue;
}

/**
 * @param {TagValue} value
 * @param {string} tag
 * @param {string} property
 * @param {string[]} selectedProperties
 * @returns {TagValue}
 */
function setPropertyValue(value, tag, property, selectedProperties) {
	const newValue = [...value];
	const tagValue = newValue.find((item) => item.name === tag);
	if (tagValue) {
		tagValue.property[property] = selectedProperties;
	}
	return newValue;
}

const SUBTAG_SETTINGS = {
	[PATIENT_TAG.PRIORITY_PATIENT]: {
		reason: {
			name: 'Priority',
			searchBar: false,
		},
	},
	[PATIENT_TAG.LANGUAGE_LINE_SUPPORT]: {
		language: {
			name: 'Language',
			previewTab: true,
		},
	},
};

/**
 * @param {object} props
 * @param {() => void} props.onChange
 * @param {TagValue} props.value
 */
export function Tags({ onChange, value }) {
	/** @type {PatientTag[]} */
	const patientTags = useSelector(
		(store) => store.patientTags?.patientTags ?? []
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getPatientTags());
	}, []);

	const selectedTagsWithProperties = patientTags
		.filter(
			(v) =>
				v.tag_properties &&
				value.map((item) => item.name).includes(v.name)
		)
		.map((v) => {
			const properties = Object.entries(v.tag_properties).reduce(
				(acc, [key, options]) => {
					if (Array.isArray(options.values)) {
						acc[key] = options.values.map((o) => o.value);
					} else {
						acc[key] = options;
					}
					return acc;
				},
				{}
			);
			return {
				name: v.name,
				displayName: v.display_name,
				properties,
			};
		});
	return (
		<>
			{selectedTagsWithProperties.flatMap((v) => {
				return Object.entries(v.properties).map(
					([propertyName, options]) => {
						const subtagSettings =
							SUBTAG_SETTINGS[v.name]?.[propertyName];
						return (
							<MultiSelectCheckbox
								key={propertyName}
								data={options.map((item) => ({
									display_name: item,
									key: item,
								}))}
								selected={
									Array.isArray(
										value.find((val) => val.name === v.name)
											?.property[propertyName]
									)
										? value.find(
												(val) => val.name === v.name
											)?.property[propertyName]
										: options
								}
								title={subtagSettings?.name ?? v.displayName}
								submit={(val) =>
									onChange(
										setPropertyValue(
											value,
											v.name,
											propertyName,
											val
										)
									)
								}
								majorPillTitle={
									subtagSettings?.name ?? v.displayName
								}
								searchBar={subtagSettings?.searchBar ?? true}
								previewTab={subtagSettings?.previewTab ?? false}
								emptyInputFullOutputOverride={true}
							/>
						);
					}
				);
			})}
			<MultiSelectCheckbox
				data={patientTags.map((item) => ({
					display_name: item.display_name,
					key: item.name,
				}))}
				selected={value.map((item) => item.name)}
				title='Flags'
				submit={(v) => onChange(setTagValue(patientTags, value, v))}
				majorPillTitle='Flags'
				searchBar={false}
				previewTab={false}
				allowsEmptyOutput
				emptyInputFullOutputOverride
			/>
		</>
	);
}
