import * as actionTypes from '../actions/actionTypes';

const defaultAddcfDNAPrenatalScreeningFinding = {
	addCFDNAPrenatalScreeningFinding: {},
	addCFDNAPrenatalScreeningFindingLoading: false,
	addCFDNAPrenatalScreeningFindingError: null,
};

export const addCFDNAPrenatalScreeningFinding = (
	state = defaultAddcfDNAPrenatalScreeningFinding,
	action = {}
) => {
	switch (action.type) {
		case actionTypes.ADD_CFDNAPRENATAL_SCREENING_FINDING_LOADING:
			return {
				...state,
				addCFDNAPrenatalScreeningFinding: {},
				addCFDNAPrenatalScreeningFindingLoading: true,
				addCFDNAPrenatalScreeningFindingError: null,
			};
		case actionTypes.ADD_CFDNAPRENATAL_SCREENING_FINDING_SUCCESS:
			return {
				...state,
				addCFDNAPrenatalScreeningFinding: action.payload,
				addCFDNAPrenatalScreeningFindingLoading: false,
				addCFDNAPrenatalScreeningFindingError: null,
			};
		case actionTypes.ADD_CFDNAPRENATAL_SCREENING_FINDING_ERROR:
			return {
				...state,
				addCFDNAPrenatalScreeningFinding: {},
				addCFDNAPrenatalScreeningFindingLoading: false,
				addCFDNAPrenatalScreeningFindingError: action.payload,
			};
		default:
			return state;
	}
};
