//Lodash
import document_finalize from '../assets/release-notes/document_finalize.gif';
import log_manual_release from '../assets/release-notes/log_manual_release.gif';
import audit_trail from '../assets/release-notes/audit_trail.gif';
import global_search from '../assets/release-notes/global_search.gif';
import open_new_tab from '../assets/release-notes/open_new_tab.gif';
import document_list from '../assets/release-notes/document_list.gif';
import disable_dno from '../assets/release-notes/disable_dno.png';
import settings from '../assets/release-notes/settings.png';
import mfa from '../assets/release-notes/2fa.png';
import stopwatch from '../assets/release-notes/stopwatch.png';
import stopwatchHidden from '../assets/release-notes/stopwatch-hidden.png';
import scanning from '../assets/release-notes/scanning.png';
import scanned from '../assets/release-notes/scanned.png';
import longweekend from '../assets/release-notes/longweekend.png';
import cfDNA from '../assets/release-notes/cfDNA-add.png';
import cfDNA_view from '../assets/release-notes/cfDNA-finding.png';
import gm_bugs from '../assets/release-notes/gmbugs.png';
import thanksgiving from '../assets/release-notes/thanksgiving-gm.gif';
import duplicate_tag from '../assets/release-notes/duplicate-tag.png';
import access_duplicate from '../assets/release-notes/access-duplicate.png';
import add_indications from '../assets/release-notes/add-indications.png';
import indications_modal from '../assets/release-notes/indications-modal.png';
import indications_view from '../assets/release-notes/indications-view.png';
import expected_revenue from '../assets/release-notes/expected-revenue.png';
import holidays from '../assets/release-notes/holidays-gmi.gif';
import view_as from '../assets/release-notes/view-as.png';
import dst from '../assets/release-notes/dst.png';
import complete_appointment from '../assets/release-notes/complete-visit.png';
import patient_viewed from '../assets/release-notes/patient-viewed.png';
import notes from '../assets/release-notes/notes.gif';
import dash_provider_export from '../assets/release-notes/dashprovexport.png';
import filter_provider from '../assets/release-notes/filterprovider.gif';
import cancel_notify from '../assets/release-notes/cancelnotify.png';
import aoe_grail from '../assets/release-notes/aoegrail.png';
import link_provider from '../assets/release-notes/linkprovider.png';
import payor_column from '../assets/release-notes/payor-column.png';
import patient_insurance from '../assets/release-notes/patient-insurance.png';
import patient_undecided from '../assets/release-notes/patient-undecided.png';
import pgx_date from '../assets/release-notes/pgx-date.png';
import discrete_variant from '../assets/release-notes/discrete-variant.gif';
import patient_not_responsive from '../assets/release-notes/patient-not-responsive.png';
import send_invite_date from '../assets/release-notes/send-invite-date.gif';
import insurance_verbiage from '../assets/release-notes/insurance-verbiage.png';
import type_ahead from '../assets/release-notes/type-ahead.gif';
import dashboard from '../assets/release-notes/dashboard.gif';
import internal_share from '../assets/release-notes/internal-share.png';
import external_id from '../assets/release-notes/external-id.png';
import notes_documents from '../assets/release-notes/notes-documents.png';
import document_upload from '../assets/release-notes/document-upload.png';
import insurance_fields from '../assets/release-notes/insurance-fields.png';
import patient_info from '../assets/release-notes/patient-info.png';
import service_scheduling from '../assets/release-notes/service-scheduling.png';
import payment_info from '../assets/release-notes/payment-info.png';
import get_started from '../assets/release-notes/get-started.png';
import appointment_selection from '../assets/release-notes/appointment-selection.png';
import insurance_page from '../assets/release-notes/insurance-page.png';
import shipping_address from '../assets/release-notes/shipping-address.png';
import review_details from '../assets/release-notes/review-details.png';
import credit_card from '../assets/release-notes/credit-card.png';
import referral_dashboard from '../assets/release-notes/referral_dashboard.png';

export const filterEnum = [
	{ display_name: 'New Feature', key: 'feature' },
	{ display_name: 'Enhancement', key: 'enhancement' },
	{ display_name: 'Bug Fix', key: 'bug' },
];

export const releaseNotesJSON = [
	{
		releaseDate: 'June 12th, 2024',
		featureItems: [
			{
				title: 'New Patient Creation and Scheduling Page',
				tags: ['feature'],
				show_tag_title: true,
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'These release notes, aimed at internal users of GMIP, provide details for the new GMIP Scheduling Page and New Patient Creation Page. These updates are designed to streamline workflows and improve user experience for both internal and external users.',
				subItems: [],
			},
			{
				title: 'New Patient Creation Page',
				tags: ['feature'],
				show_tag_title: false,
				internal: true,
				media: [],
				link: null,
				link_text: null,
				majorDescription: '',
				subItems: [
					{
						header: '',
						description: '',
						media: null,
						subList: [
							{
								header: 'First Encounter Consultation Type & Specialty Type',
								description:
									"Determines the type of appointment for the patient's first visit, ensuring correct appointment selection to avoid rescheduling and canceling.",
								media: null,
							},
							{
								header: 'Removed Unborn Patients Checkbox',
								description:
									'We removed the checkbox to indicate the retirement of the unborn patient workflow.',
								media: null,
							},
							{
								header: 'Patients with Guardians',
								description:
									'Requires details about the guardian, including name, email, phone number, and relationship to the patient. If not a parent, a validating document is required.',
								media: null,
							},
							{
								header: 'Editing Guardian Information',
								description:
									'Users can edit or remove guardian information when creating a patient or scheduling an appointment.',
								media: null,
							},
							{
								header: 'External Patient ID/Accession Number',
								description:
									'Used for linking patients in our system to partner systems, the Accession Number is tied to the Pioneer Accession Number when scheduling.',
								media: [external_id],
							},
							{
								header: 'Notes Field',
								description:
									'Internal users can add Internal and Provider Notes. Limited to 1 note per field, no character limit.',
								media: [notes_documents],
							},
							{
								header: 'Document Upload',
								description:
									'Up to 3 documents can be uploaded during patient creation, automatically added to the patient profile but not linked to encounters. Additional documents can be uploaded later. The document size limit is 10MB per document.',
								media: [document_upload],
							},
							{
								header: 'Reduced Insurance Fields',
								description:
									'Reduced the number of fields in the insurance section. The fields are optional and are used for billing team to determine insurance eligibility.',
								media: [insurance_fields],
							},
							{
								header: 'Create & Schedule vs. Create',
								description:
									'"Create & Schedule" navigates directly to scheduling with the patient selected. "Create" takes users to the patient\'s profile for further actions.',
								media: [],
							},
						],
					},
				],
			},
			{
				title: 'New Scheduling Page',
				tags: ['feature'],
				show_tag_title: false,
				internal: true,
				media: [],
				link: null,
				link_text: null,
				majorDescription: ' ',
				subItems: [
					{
						header: 'Patient Info',
						description: ' ',
						media: [patient_info],
						subList: [
							{
								header: 'Phone Number Field',
								description:
									'Only accepts U.S. or Canadian phone numbers.',
								media: null,
							},
							{
								header: 'Removing PoA/Guardian Info',
								description:
									'Uncheck the PoA/Guardian checkbox to remove incorrect information.',
								media: null,
							},
							{
								header: 'Associated Program',
								description:
									'You can now edit the patient’s program when scheduling.',
								media: null,
							},
							{
								header: 'Consent to Treat',
								description:
									'Consent to treat dropdown is moved from the patient creation page to the scheduling page.',
								media: null,
							},
							{
								header: 'Preferred Name',
								description:
									'You can capture the patient’s preferred name when scheduling.',
								media: null,
							},
							{
								header: 'Flags',
								description:
									'Add/remove flags to highlight special patient needs when scheduling.',
								media: null,
							},
							{
								header: 'Create & Schedule vs. Create',
								description:
									'"Create & Schedule" navigates directly to scheduling with the patient selected. "Create" takes users to the patient\'s profile for further actions.',
								media: [],
							},
						],
					},
					{
						header: 'Service & Scheduling',
						description: ' ',
						media: [service_scheduling],
						subList: [
							{
								header: 'Cleared Information on Program Change',
								description:
									"Information is cleared when changing the patient's program due to varying service types and supported countries.",
								media: null,
							},
							{
								header: 'Country',
								description:
									'A new field to indicate if a patient is located outside of the U.S. at the time of appointment. The program in the Admin Portal sets the supported countries list.',
								media: null,
							},
							{
								header: 'Additional Conference Line',
								description:
									'Allows adding interpreter phone numbers or other family members to the visit, synced in Pioneer > Appointment Details.',
								media: null,
							},
							{
								header: 'Prior Appointments',
								description:
									'If the patient had a previous appointment, you can see the details of the appointment in this section.',
								media: null,
							},
							{
								header: 'Preferred Name',
								description:
									'You can capture the patient’s preferred name when scheduling.',
								media: null,
							},
							{
								header: 'Monthly Calendar View',
								description:
									'We replaced the legacy weekly week with the monthly view of the calendar to enable quick search of available appointments and reduce loading time.',
								media: null,
							},
						],
					},
					{
						header: 'Payment Info',
						description: ' ',
						media: [payment_info],
						subList: [
							{
								header: 'Insurance Fields',
								description:
									'If the fields are disabled, this indicates the patient’s program does not use insurance. Contact the Client Success Team for further questions.',
								media: null,
							},
							{
								header: 'Credit Card Fields',
								description:
									'Credit card information is needed when the appointment’s payment mode is self-pay, or if there is a visit deposit we need to collect for insurance billing.',
								media: null,
							},
							{
								header: 'Billing Address',
								description:
									'The billing address is required for processing self-pay visit fees, or insurance billing.',
								media: null,
							},
						],
					},
					{
						header: 'Shipping Address',
						description:
							'For programs that require test ordering, a shipping address is required at the time of scheduling to facilitate test kit delivery.',
						media: null,
					},
				],
			},
			{
				title: 'Patient Portal Self-Scheduling',
				tags: ['feature'],
				show_tag_title: true,
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'In keeping with the changes to the patient creation and scheduling changes in GMIP, the Patient Portal Self-Scheduling flow has been redesigned and streamlined. Many changes were made in direct response to patient and clinician feedback, allowing for a smoother and less cumbersome experience for new patients',
				subItems: [
					{
						subList: [
							{
								header: 'Condensed Get Started Page',
								description:
									'\u25CB Less demographic information required.\n\u25CB No optional fields - only required information is requested.\n\u25CB No address until contextually relevant.',
								media: [get_started],
							},
							{
								header: 'Streamlined Appointment Selection Process',
								description:
									'\u25CB Removed Select Visit Details screen for text to schedule patients, only including it for patients coming from a landing page.\n\u25CB Enhanced calendar functionality with a more mobile friendly design and the ability to scroll from week to week.\n\u25CB Appointment Details displayed and dynamically changing as selections are made.',
								media: [appointment_selection],
							},
							{
								header: 'Updated Insurance Page',
								description:
									'\u25CB Accessible billing details.\n\u25CB Address asked in-context as needed (and no longer asked as the first step of the flow).',
								media: [insurance_page],
							},
							{
								header: 'Redesigned Credit Card page',
								description:
									'\u25CB Only needed information requested.',
								media: [credit_card],
							},
							{
								header: 'New Shipping Address Screen',
								description:
									'\u25CB Only appears when contextually appropriate (i.e. when Test Plan is active).\n\u25CB Ability to instantly fill fields with Billing Address data.',
								media: [shipping_address],
							},
							{
								header: 'Review All Details Screen',
								description:
									'\u25CB All appointment details displayed in one place before booking, with the ability to go back and make changes as needed.',
								media: [review_details],
							},
						],
					},
				],
			},
		],
	},
	{
		releaseDate: 'August 18th, 2022',
		featureItems: [
			{
				title: 'Secondary Insurance',
				tags: ['feature', 'enhancement'],
				internal: true,
				media: [],
				link: null,
				link_text: null,
				majorDescription:
					'Internal GMS Portal users and patients can now add secondary insurance information for patients. If a patient’s primary insurance company doesn’t cover all or part of a service we provide, this allows us to have the secondary insurance information ready and available to submit a claim. We are also using the secondary insurance section to support subsidy billing.\n\nThe Athena integration has also been updated so that all of the secondary insurance information for a patient gets stored in the correct place.\n\nSecondary insurance information can be added during the Create Patient workflow, on the Payments tab of the patient record, during the appointment scheduling workflow, or in the patient portal. In all cases, the secondary insurance fields will only appear if the user chooses to add the information.',
			},
		],
		otherItems: [
			{
				title: 'Documents',
				tags: ['enhancement'],
				internal: true,
				media: [internal_share],
				link: null,
				majorDescription:
					'Sharing is caring 💞 — you can now use "Share Internally" for files with the ".doc" file extension, just like PDFs!',
			},
		],
	},
	{
		releaseDate: 'August 4th, 2022',
		otherItems: [
			{
				title: 'Billing',
				tags: ['enhancement'],
				internal: true,
				media: [],
				link: null,
				majorDescription:
					'In a recent release, we added the "MD Required" flag to the Insurance screen to populate a Supervising Physician in an encounter even if Physician Billing is not active. Up until now, we were not always handling the assignment of the CPT code correctly. With this release, we have added logic to correctly handle all combinations of "No Supervision Billing" and "MD Required" flags so that the Supervising Physician and CPT code are correctly assigned in all cases.',
			},
		],
	},
	{
		releaseDate: 'July 21st, 2022',
		featureItems: [
			{
				title: 'Default Providers',
				tags: ['feature'],
				internal: true,
				media: [dashboard],
				link: null,
				link_text: null,
				majorDescription:
					"Introducing: Default Providers!\n\nThis is a new feature where Referral Programs can be assigned default providers. Assigning and unassigning happen in the new Default Providers dashboard(inside the Referral Programs module). Any new patient created will automatically receive any Default Providers currently linked to the new patient's selected Referral Program. When Default Providers are linked during patient creation, they will automatically receive all communications and are designated as Referring Providers. Raise your hand if you like saving time! 🙋",
			},
		],
		otherItems: [
			{
				title: 'Test Orders',
				tags: ['enhancement'],
				internal: true,
				media: [],
				link: null,
				majorDescription:
					'By default, results will now appear in Delayed Labs after 35 days, instead of after 21. When adding test information on the UI, the date pickers will default to this 35-day length, as well.',
			},
			{
				title: 'Billing',
				tags: ['enhancement'],
				internal: true,
				media: [],
				link: null,
				majorDescription:
					'In the Billing dashboard, newly created encounters will no longer have the "Partner Invoice" column filled if they are set to be paid by insurance.',
			},
		],
	},
	{
		releaseDate: 'July 7th, 2022',
		otherItems: [
			{
				title: 'Dashboard',
				tags: ['enhancement'],
				internal: true,
				media: [patient_not_responsive],
				link: null,
				majorDescription:
					'When viewing the Orders Pending Lab Submission card, items with a status of "Patient Not Responsive" are no longer shown.',
			},
			{
				title: 'Patient',
				tags: ['enhancement'],
				internal: true,
				media: [send_invite_date],
				link: null,
				majorDescription:
					'You asked for it! Now you can designate a future date and time for invite emails to be sent to new users. Leaving this new field blank will send the email upon patient creation — AKA the existing functionality.',
			},
			{
				title: 'Dashboard',
				tags: ['enhancement'],
				internal: true,
				media: [insurance_verbiage],
				link: null,
				majorDescription:
					'Verbiage related to insurance authorization has been updated to be more understandable now that secondary insurances are supported.',
			},
			{
				title: 'Patient',
				tags: ['enhancement'],
				internal: true,
				media: [type_ahead],
				link: null,
				majorDescription:
					'Type-ahead dropdowns are now found throughout the portal. These enhanced fields allow you to narrow your choices by typing — try it out!',
			},
		],
	},
	{
		releaseDate: 'June 9th, 2022',
		featureItems: [
			{
				title: 'Discrete Variants',
				tags: ['feature'],
				internal: true,
				media: [discrete_variant],
				link: null,
				link_text: null,
				majorDescription:
					"We're excited to roll out Discrete Variants as the second genetic test result type to use the Clinical Data Model (CDM)! This continues the work of cfDNA Methylation (e.g., Grail Galleri results) and includes a new and improved UI for manual entry that will help ensure the results you enter are accurate and consistent. Now we can finally search for patients by gene name in Advanced Search too!! Next up will be structural variants, genotypes (e.g., PGx results), and cfDNA for prenatal tests.",
			},
		],
		otherItems: [
			{
				title: 'Patient',
				tags: ['enhancement'],
				internal: true,
				media: [patient_insurance],
				link: null,
				majorDescription:
					'Users can now add insurance information during the patient create flow.',
			},
			{
				title: 'Dashboard',
				tags: ['enhancement'],
				internal: true,
				media: [patient_undecided],
				link: null,
				majorDescription:
					'When viewing the Orders Pending Lab Submission card, items with a status of "Patient Undecided" are no longer shown.',
			},
			{
				title: 'Dashboard',
				tags: ['enhancement'],
				internal: true,
				media: [],
				link: null,
				majorDescription:
					'In the Delayed Lab Results card, items that are more than 6 months old are no longer shown.',
			},
			{
				title: 'Encounter',
				tags: ['enhancement'],
				internal: true,
				media: [pgx_date],
				link: null,
				majorDescription:
					'When updating an Encounter (Visits or TRO), you can now record the date for which Post-Session Indirect Time occurred.',
			},
		],
	},
	{
		releaseDate: 'May 26th, 2022',
		otherItems: [
			{
				title: 'Patient Notes',
				tags: ['enhancement'],
				internal: true,
				media: [payor_column],
				link: null,
				majorDescription:
					'The Appointments table now has a column to display Payor information.',
			},
			{
				title: 'Encounter',
				tags: ['bug'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Our new Notes feature (see below) had a bug where the time zone of the user was not being used/respected. This is now fixed — sorry for assuming all you users are on GMT! 😅🇬🇧🇮🇪',
			},
		],
	},
	{
		releaseDate: 'May 12th, 2022',
		otherItems: [
			{
				title: 'Patient Notes',
				tags: ['enhancement'],
				internal: true,
				media: [notes],
				link: null,
				majorDescription:
					'Now you can add patient notes in three flavors! When viewing a patient, notes are now divided into three groups — Internal, Billing, and Provider. The updated UI lets you filter your view and add specific note types.',
			},
			{
				title: 'Dashboard',
				tags: ['enhancement'],
				internal: true,
				media: [dash_provider_export],
				link: null,
				majorDescription:
					'The Provider Indicated Specialty field is now shown in applicable exported reports.',
			},
			{
				title: 'Appointments',
				tags: ['enhancement'],
				internal: true,
				media: [aoe_grail],
				link: null,
				majorDescription:
					"Users can now select a patient's AOE details when ordering Grail Galleri tests.",
			},
			{
				title: 'Dashboard',
				tags: ['enhancement'],
				internal: true,
				media: [filter_provider],
				link: null,
				majorDescription:
					'These dashboard cards now have filtering by Provider available: Visit Completion Status Unknown, Order Request Status Unknown, CAP Not Sent (Visit), Results CAP Not Sent.',
			},
			{
				title: 'Appointments',
				tags: ['enhancement'],
				internal: true,
				media: [cancel_notify],
				link: null,
				majorDescription:
					'When canceling an appointment, you now have the choice to notify/not notify the patient of cancelation.',
			},
			{
				title: 'Patient',
				tags: ['enhancement'],
				internal: true,
				media: [link_provider],
				link: null,
				majorDescription:
					'Linking an External Provider will now, by default, link that provider to all encounters for that patient.',
			},
			{
				title: 'Dashboard',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'In the Orders Pending Lab Submission dashboard card, items with order statuses of "Awaiting Benefits Investigation" are no longer displayed.',
			},
			{
				title: 'Encounter',
				tags: ['bug'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'An issue where CC minutes were not saving correctly has been fixed.',
			},
			{
				title: 'Encounter',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Caroline Bertsch and Chandler Means — you've been added to the Visit Provider list!",
			},
		],
	},
	{
		releaseDate: 'Apr 21st, 2022',
		otherItems: [
			{
				title: 'Appointments',
				tags: ['enhancement'],
				internal: true,
				media: [complete_appointment],
				link: null,
				majorDescription:
					"Now Indications, Direct Clinical Care Time, Pre and Post-Session Indirect Times, Post-Test Result CAP Time, ICD-10 Code(s), and Visit CAP Completed Date need to be filled in order to mark a Visit 'complete'.",
			},
			{
				title: 'Documents',
				tags: ['enhancement'],
				internal: true,
				media: [patient_viewed],
				link: null,
				majorDescription:
					'You can now see when the patient last viewed the document from the 3-dot menu > Audit trail.',
			},
			{
				title: 'Documents',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We fixed a bug where changing a document type inadvertently updated the template name internally.',
			},
		],
	},
	{
		releaseDate: 'Apr 14th, 2022',
		otherItems: [
			{
				title: 'Appointments',
				tags: ['enhancement'],
				internal: true,
				media: [dst],
				link: null,
				majorDescription:
					'Appointments for Arizona patients will now reflect PDT considering the Daylight time-saving changes.',
			},
			{
				title: 'Documents',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'You will now have an option to download documents directly from the three-dot menu rather than opening them first.',
			},
			{
				title: 'Patients',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Internal users can now add a 'Patient story' tag to patient records in the patient create/edit flow.",
			},
			{
				title: 'Appointments',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Providers can now choose to view only their appointments on the 'Upcoming Appointments' table on the dashboard.",
			},
		],
	},

	{
		releaseDate: 'Mar 31st, 2022',
		otherItems: [
			{
				title: 'Appointments',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We added something extra to support better provider selection during appointment scheduling and will be filtering the list based on configurations per affiliation under the admin portal such as drug and prior auth checks, and whether we need to show some selected providers ONLY while scheduling.',
			},
		],
	},
	{
		releaseDate: 'Mar 24th, 2022',
		otherItems: [
			{
				title: 'Encounter',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Well, you haven't really joined us until you fail to create a manual encounter and we add you to the providers' list, have you? Welcome to the GM family, Alesha. Rest assured, we are working on a better way to welcome new members.",
			},
			{
				title: 'Order',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"You can now add a subsequent test order for a patient if they come back and request a test AFTER pre-auth is added. You don't have to create a manual encounter for this workflow anymore.",
			},
			{
				title: 'Billing',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Chris, you will now be able to update the payor field for a patient encounter via Provider Portal. You won't have to raise a support request for this change.",
			},
		],
	},
	{
		releaseDate: 'Mar 10th, 2022',
		otherItems: [
			{
				title: 'Results',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We have updated our 'Results received' email notifications logic to be based on the Actionability of the results rather than positive or negative. For non-actionable results, oncall@genomemedical.com will continue to receive the alerts, whereas, for actionable results, the email will also go to the ordering/approving provider along with oncall@genomemedical.com.",
			},
			{
				title: 'Encounter',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We know there were some hiccups...but finally, we added you to the Visit Provider list, Heather, Wendi, and Bryan. Welcome to the GM family.',
			},
			{
				title: 'Billing',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'billing@genomemedical.com is now added to the recipient list along with coordinator@genomemedical.com for the email triggered as a result of failed payment for an appointment.',
			},
			{
				title: 'Provider',
				tags: ['bug'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"This week we learned that the fax number for the external provider didn't like itself to be the last one that was updated. It refused to be saved. 🤷‍♀️ It took some convincing but everything is back to normal now.",
			},
		],
	},
	{
		releaseDate: 'Mar 3rd, 2022',
		otherItems: [
			{
				title: 'Outreach',
				tags: ['bug'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"The 'Stop Outreach' button from the RoR outreach section was temporarily missing from the portal. We found it and have put it back to work.",
			},
			{
				title: 'Patient',
				tags: ['bug'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"While we were working very hard to not let our internal enhancements and changes impact you, we did leave a bug crawling around. It caused the tooltip to not appear when you hovered on the 'Referral Program' name on patient details sidebar. We have fixed it and things should be back to the way they were.",
			},
			{
				title: 'Outreach',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'There was an issue we found and fixed where the timer showed the incorrect time left for outreach to start.',
			},
		],
	},
	{
		releaseDate: 'Feb 17th, 2022',
		otherItems: [
			{
				title: 'Dashboard',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We have added an ability to filter by Provider Indicated Specialty on CAP Not Sent (Visit), Delayed Lab Results, and Orders Pending Lab Submission cards. We won't lie, it turned out to be more complicated than we thought, so if you see any issues please let us know.",
			},
			{
				title: 'Encounter',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We have made some enhancements to how we capture Visit Indications allowing some indications to be part of multiple categories/specialties used as filters and internal custom sorting with some data cleanup to follow.',
			},
			{
				title: 'Test',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"If a test order was marked as 'Patient Declined' before it was 'Requested', you can now change it to 'Requested' state. You don't need to create a new test order as a workaround for this anymore.",
			},
		],
	},
	{
		releaseDate: 'Feb 3rd, 2022',
		otherItems: [
			{
				title: 'Results',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We have updated the names of a few options in the Cancer signal origins list that you see while entering findings for the Grail Galleri cfDNA test.',
			},
			{
				title: 'Dashboard',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"To make your lives easier, we have added a new column 'Provider Indicated Specialty' to the Dashboards cards' list so that you have all the information you need to assign patients to the right team member.",
			},
			{
				title: 'Search',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Advanced search now allows searching by exact email address by using the 'email is' condition.",
			},
		],
	},
	{
		releaseDate: 'Jan 27th, 2022',
		otherItems: [
			{
				title: 'Documents',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'The description will now be a required field while creating/uploading Administrative/Misc documents for patients.',
			},
			{
				title: 'Test Auth',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We will now be displaying the patient's age in years in addition to their DOB on the MD Test Authorization dashboard.",
			},
			{
				title: 'Encounters',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Some order statuses like 'Rejected' or 'Declined' have reasons associated with them. You have all the rights to view them on the list upfront rather than digging to the details view. That said, the reasons attached with order status (if applicable), will now be visible on the Patient and Encounter list views.",
			},
			{
				title: 'Test',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"You can now revert the test order status from Canceled to its prior state from the UI. No more writing an email to Product Support. We keep giving you reasons to love us every week, don't we? 😉",
			},
		],
	},
	{
		releaseDate: 'Jan 20th, 2022',
		otherItems: [
			{
				title: 'Encounters',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We have added 5 new visit indications to the list - Ataxia, Kidney Disease, Autoimmune/Autoinflammatory, Vascular Malformation, and Other Arterial Disease.',
			},
			{
				title: 'Users',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Do you remember the update where you started seeing your role on the top right with a new dropdown option 'View As'? Because it was intended to help team members manage multiple views of the portal under a single account, role in the top right and the additional options will only be visible to users with multiple roles.",
			},
			{
				title: 'Encounters',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We have noticed that lately amended reports are being received for patients which require 'Results receipt date' to be updated. You do not need to send an email to Product Support for that anymore. The field will now be editable in the UI so you can continue to amend CAPs without being blocked.",
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'While you may not see a lot of bigger updates to the portal, there are some major enhancements being done behind the scenes to support upcoming feature builds. Stay tuned!',
			},
		],
	},
	{
		releaseDate: 'Jan 13th, 2022',
		otherItems: [
			{
				title: 'Insurance',
				tags: ['bug'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Chris, thanks for bringing to our attention how we literally missed the point while implementing the Expected Revenue field. You can now add decimal values for the field and, we promise we won't stop you anymore.",
			},
			{
				title: 'Encounters',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Visit Indications will be a required field while entering CAP Completion Date for all the Visit encounters with the date of service on or after 01/13/2022.',
			},
			{
				title: 'Encounters',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We are live with an enhanced provider matching algorithm being used to match providers during the appointment scheduling process based on weighted criteria like willing specialty, number of licensed states, and provider employment status. This feature also allows filtering providers based on configured affiliation associations and certain affiliation-requested conditions like 'Drug Tested' and 'Prior Auth' via the Admin portal.",
			},
		],
	},
	{
		releaseDate: 'Jan 6th, 2022',
		otherItems: [
			{
				title: 'User',
				tags: ['enhancement'],
				internal: true,
				media: [view_as],
				link: null,
				majorDescription:
					"The role for your account will now be visible alongside your name on the top right. You will also see a new option 'View As' in the dropdown menu when you click on it. You can choose to view the portal with a different role by selecting from the list available to you under that option. It will cause the portal to refresh, so we advise you to not switch roles if you have unsaved changes. Note: This feature is ONLY for internal users.",
			},
			{
				title: 'Encounters',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We have added 4 new indications to the visit indications list - Known Pathogenic Variant (Cancer), Known Pathogenic Variant (Repro), Known Pathogenic Variant (Cardio), Known Pathogenic Variant (Rare Disease).',
			},
			{
				title: 'Billing',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"'Patient Fee Refund Reason' is now added to billing export as well as the right panel that opens when you click on any record in the billing dashboard list.",
			},
		],
	},
	{
		releaseDate: 'Dec 23rd, 2021',
		otherItems: [
			{
				title: 'Encounters',
				tags: ['enhancement'],
				internal: true,
				media: [expected_revenue],
				link: null,
				majorDescription:
					"Kathy- This update is especially for you! We have added a new field 'Expected Revenue' to our Insurance Management Dashboard which will automatically populate the 'Expected Revenue' field in the billing section of the encounter based on the insurance company associated with it (Payor). You don't have to manually update encounters one at a time anymore.",
			},
			{
				title: 'Patient',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We have also made updates to display names for a couple of gender options that you see during patient create/edits and views: 'Transgender - MTF' to 'Transgender Female', 'Transgender - FTM' to 'Transgender Male' and 'Genderqueer/gender-fluid/gender non-conforming' to 'Non-binary/genderqueer/gender non-conforming'.",
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We have some very exciting features which are currently undergoing a much-needed quality check. You will be seeing those very soon after the holidays.',
			},
			{
				title: 'Patient',
				tags: ['enhancement'],
				internal: true,
				media: [holidays],
				link: null,
				majorDescription:
					'We know everyone has been working hard all year. Now, time to kick back and relax. Have a sleighful of fun this holiday season!!',
			},
		],
	},
	{
		releaseDate: 'Dec 16th, 2021',
		featureItems: [
			{
				title: 'Visit Indication',
				tags: ['feature'],
				internal: true,
				media: [add_indications, indications_modal, indications_view],
				link: null,
				majorDescription:
					"We’re aligning how our GCs are able to document a patient encounter with a common practice from the profession! We’re rolling out a variable that allows clinicians to better describe why the patient wanted an appointment using an indication from a list of the most common reasons patients seek genetic counseling. This opens the door to a number of data analysis pathways that deepen our understanding of who we are working with. This feature includes:\n- a searchable, filterable list of indications\n- specification of a personal vs. family history of any indication\n- free text “Other” boxes for clarification on more infrequent visit reasons\n- a cap on the display number so as not to overwhelm the Visit Details screen. This list is likely to grow in the future to account for frequently seen 'Other's, and we will modify past visits to align with the new indication.",
			},
		],
		otherItems: [
			{
				title: 'Encounters',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We have also added a new field 'Patient Fee Refund Reason' under Encounters > Billing section that will help Clinical and RCM teams to understand if there are service deviancies or just billing issues in general.",
			},
		],
	},
	{
		releaseDate: 'Dec 9th, 2021',
		otherItems: [
			{
				title: 'Results',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Kai Li, as per your request we have stopped the automated emails that were triggered whenever a test result was entered with 'at least one finding to report'.",
			},
			{
				title: 'Patient',
				tags: ['enhancement'],
				internal: true,
				media: [duplicate_tag, access_duplicate],
				link: null,
				majorDescription:
					"You now have the ability to link multiple/duplicate patient accounts during the 'Edit patient' flow and quickly access the linked accounts from the left pane on the 'Patient Detail' view.",
			},
			{
				title: 'Search',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"You can now find all the patients with Actionable/Non-actionable findings using the search term 'Actionability' on advanced search.",
			},
		],
	},
	{
		releaseDate: 'Nov 18th, 2021',
		otherItems: [
			{
				title: 'Encounter',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Welcome to the family, Caitlin! You are now added to the Visit Providers’ list on the portal - our way of welcoming you.',
			},
			{
				title: 'Patient',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We have added a new field to be able to track whether a patient with a mobile phone is consenting to text or not. You will see this during patient create and edit flows when you select patient phone type as 'Mobile'.",
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: [thanksgiving],
				link: null,
				majorDescription:
					'Next portal updates will be in two weeks. Sending early thanksgiving wishes your way! (and be sure to get your thanksgiving pants ready)',
			},
		],
	},
	{
		releaseDate: 'Nov 11th, 2021',
		otherItems: [
			{
				title: 'Dashboard',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Jamie and Maria, no more scary numbers because our Patient Outreach Pending Card just got smarter. Now you can choose an 'Activation Date' in the Admin Portal for any affiliation while turning Referral Outreach Automation ON, and we will show only the patients created on and after that date on the PoP card on the Provider Portal.",
			},
			{
				title: 'Test Order',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We had built a flow in the past where we were able to automatically receive External Lab Status for the test orders from the labs which were integrated with GM. Now, if a lab is NOT integrated with GM, but external lab status is readily available, you can also update it manually in the test order record. ',
			},
			{
				title: 'Results',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"As part of revamping our test results entry flow, we are replacing the 'Results Entered' test order status with 'Findings Available'. You will see this change on all the encounter lists. This is just one of the many enhancements to follow.",
			},
			{
				title: 'Patient',
				tags: ['bug'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We fixed a bug where if you searched and tried to export by phone number on advanced search, the report wasn't getting generated.",
			},
			{
				title: 'Patient',
				tags: ['bug'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'There was a minor UX issue where the notification box that shows up after an appointment was booked was not displaying the provider name. We fixed that.',
			},
			{
				title: 'Dashboard',
				tags: ['bug'],
				internal: true,
				media: [gm_bugs],
				link: null,
				majorDescription:
					"We also deployed a hotfix for a bug where CCs were not able to 'Unassign' the patients once they were already assigned to a member of the team.",
			},
		],
	},
	{
		releaseDate: 'Oct 28th, 2021',
		otherItems: [
			{
				title: 'Patient',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"'Priority' tag is renamed as 'STAT', and now you have the ability to also select the reason for marking a patient as such.",
			},
			{
				title: 'Encounters',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Kai Li, per your request we have now added 'minutes' to the PGx time field labels.",
			},
			{
				title: 'Results',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"In the process of enhancing our test results entry flow and encouraging all findings to be entered, we have dropped the word negative from the No Findings labels. This will make it clear that individual findings, including those potentially considered 'negative' (e.g., VUS, benign, normal) can be entered. Findings are then marked as either actionable or not actionable.",
			},
			{
				title: 'Patient',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"You know how sometimes you do everything right but can't understand why something failed?  Hold your thoughts fam, we are talking about Patient creation, not life. We have enhanced our error messages to better indicate if the phone number you entered is a non-US number.",
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We will be back with some faboolous updates in two weeks. Till then, be afraid.. be very afraid. Have a creepy-crawly Halloween. 👻 ',
			},
		],
	},
	{
		releaseDate: 'Oct 21st, 2021',
		otherItems: [
			{
				title: 'Patient',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We have introduced new fields to capture patients' Race and Ethnicity to accommodate Genomics PLCs Ethnicity and Race intake forms. You will have access to these fields during create/edit workflows and the selections will be visible on the Patient Detail screen.",
			},
			{
				title: 'External Provider',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Hey Scott, we have now added 'N/A' to the credentials dropdown while adding External Providers as you requested.",
			},
			{
				title: 'Search',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Did you want to search for all the priority patients but couldn't find an easy way? Well, we enhanced our advanced search to support not just that but searching using any patient tags. All you need to type is 'Patient tag', make selections, and we will do the rest. The only thing we can't support right now is searching for Halloween costume ideas, so buck up and get ready for Halloween :D ",
			},
		],
	},
	{
		releaseDate: 'Oct 14th, 2021',
		featureItems: [
			{
				title: 'Cancer cfDNA Results',
				tags: ['feature'],
				internal: true,
				media: [cfDNA, cfDNA_view],
				link: null,
				majorDescription:
					'We are excited to announce the first genetic test type to use the new Clinical Data Model (CDM)! Cancer cfDNA results from the Grail Galleri test can be entered through our new and improved Results UI and will be stored and managed in the CDM. We ultimately decided to leave out the mascot--a perky blue whale known as the Methylation Cetacean.',
			},
		],
		otherItems: [
			{
				title: 'Encounter',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'You can now view the history of visit status for any Visit encounter.',
			},
		],
	},
	{
		releaseDate: 'Sep 23rd, 2021',
		otherItems: [
			{
				title: 'Encounter',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We have added new time spent fields - Pre-Session Indirect Time, Post-Session Indirect Time, and PGx Post CAP Indirect Time for Visit and TRO Encounters under PGx Information section.',
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We were hard at work prepping for the Grail Seeker workflow so you may not see a lot of updates here but we haven't forgotten you. There are some exciting updates with everything 'pumpkin spice flavored' to follow ;) Happy Fall Y'all!!",
			},
		],
	},
	{
		releaseDate: 'Sep 9th, 2021',
		featureItems: [
			{
				title: '',
				tags: ['enhancement'],
				show_tag_title: false,
				internal: true,
				media: [longweekend],
				link: null,
				majorDescription:
					"Hola!! This week's release is lighter with some under the hood enhancements, minor bug fixes, and major recovery from the long weekend.",
				subItems: [
					{
						header: '',
						description:
							'On that note, we hope you had a fun Labor Day weekend.',
						media: null,
					},
				],
			},
		],
		otherItems: [
			{
				title: '2FA',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We have recently seen issues with our invite and 2FA emails going to spam. We have updated the sender's address to enhance our email reputation and prevent that from happening. And guess what? By doing this, we are on our way to kill yet another spreadsheet. You will now see a reduced number of entries on the failed email list while we continue to work towards eliminating the issue entirely.",
			},
		],
	},
	{
		releaseDate: 'Sep 2nd, 2021',
		featureItems: [
			{
				title: 'Anti-virus File Scan',
				tags: ['feature'],
				internal: true,
				media: [scanning, scanned],
				link: null,
				link_text: null,
				majorDescription:
					"Files you upload to the portal will now be scanned for viruses and other malware, and will not be allowed any access or actions if it's detected as malicious. Don't worry, it will still be on the list to haunt you forever! :D",
			},
		],
		otherItems: [
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Everyone is different... and all we needed was a TRO encounter to remind us of that :) We were using consultation type to show up as the title whenever documents were grouped by encounters, which ended up displaying 'N/A' for TRO encounters. We fixed it to show 'TRO' for such groups.",
			},
		],
	},
	{
		releaseDate: 'Aug 26th, 2021',
		otherItems: [
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'This week is mostly behind-the-scenes, laying the groundwork for bigger improvements to come but if you happen to notice the improved dashboard performance- it took blood, sweat, and (sometimes) tears but we got there.',
			},
		],
	},
	{
		releaseDate: 'Aug 19th, 2021',
		otherItems: [
			{
				title: 'Encounters',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We saved you the roundtrip by moving direct/indirect time spent fields to Visit Details section, alongside CAP Sent Date fields.',
			},
			{
				title: 'TRO',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'When the patient submits a request to order the Galleri test, it will now auto-create a TRO encounter on our GM Portal. No more spreadsheets fam!',
			},
			{
				title: 'Test Orders',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'As soon as a TRO order is approved/rejected, visit provider name and visit state will be auto-populated with that of approving MD. We will also mark the visit status as Completed.',
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We are constantly working to improve the portal experience. Stay tuned!',
			},
		],
	},
	{
		releaseDate: 'Aug 12th, 2021',
		featureItems: [
			{
				title: 'Stopwatch',
				tags: ['feature'],
				internal: true,
				media: [stopwatchHidden, stopwatch],
				link: null,
				link_text: null,
				majorDescription:
					'You needed to track the time for your tasks? Say no more! We built a GM stopwatch, just for you. So, stop watcha doin and check it out :D ',
			},
		],
		otherItems: [
			{
				title: 'Billing',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Encounter UUID, Requested and Provider Indicated Specialty, and Time Spent fields are now part of exported billing report.',
			},
			{
				title: 'Advanced Search',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We appreciate your feedback, Scott. The total count on advanced search is back with pagination options on the top. No more scrolling all the way down only to start it over on the next page!!',
			},
			{
				title: 'Encounters',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Well, we missed the whole point of the calendar, didn't we? The current date indicator is back on all calendars :)",
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"That's all for now. Have a great week, people!",
			},
		],
	},
	{
		releaseDate: 'Aug 5th, 2021',
		otherItems: [
			{
				title: 'Billing',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"In compliance with visits’ billing for the Supervising Physician code, we have updated the supervisor assignment logic to be based on the MD's licensed state matching with the visit state.",
			},
			{
				title: 'Billing',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'S-Code billing logic doesn’t exclude any states anymore because the logic for Supervising Physician assignment was updated.',
			},
			{
				title: 'Billing',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'You can now see the audit trail for billing-related fields.',
			},
			{
				title: 'Encounter',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Welcome to the fam, Peyton. You are now added to the Visit Providers’ list on the portal.',
			},
		],
	},

	{
		releaseDate: 'Jul 29th, 2021',
		otherItems: [
			{
				title: 'Billing',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We will now be using the delivery address to determine the required state licensure for test orders and visit state.',
			},
			{
				title: 'Encounters',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We have renamed the Time Spent fields for Visit encounters, added a new field 'Post-Session Indirect Time (minutes)', and updated units calculation logic.",
			},
			{
				title: 'Encounters',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'There is added validation to fill the Time Spent fields before entering Visit CAP/Results CAP Completed Date.',
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We have made a number of changes under the hood to improve the performance and speed so you get the best portal experience. :)',
			},
		],
	},

	{
		releaseDate: 'Jul 15th, 2021',
		featureItems: [
			{
				title: 'Security',
				tags: ['feature'],
				internal: true,
				media: [settings, mfa],
				link: null,
				link_text: null,
				majorDescription:
					"We are tightening the security of our Provider Portal by introducing the notion of a Trusted Device. If at all a login is done from an untrusted device, a two-factor-authentication (2FA) will occur via a verification email being sent carrying a 2FA code required to complete the login process. We advise you to log out and log back in \n - from your work PC then please ensure to click on the 'Trust this device' checkbox. \n- if ever you log on from an Incognito browser or a new laptop (or cleared the browser cache for some odd reason), you will be sent the 2FA code as mentioned above. \n - if you, for some reason, have to use a non-work laptop to access the portal, please leave the 'Trust this device' checkbox unchecked as good security practice. \n \n You can view your active logged-in devices from Profile -> Settings (we encourage to keep this down to only one remembered device as good security practice). \n \nFor some reason, if you do not trust a device in that list, you may revoke access of that device by clicking on 'Revoke'. If you click on 'Forgot Password' from an untrusted device, you will be sent a 2FA code during login after the password reset.",
			},
		],
		otherItems: [
			{
				title: 'Billing',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'New Jersey and Maine didn’t wanna be in our S Code billing party so we took them off :(',
			},
			{
				title: 'Templates',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Smart words now support Pre-auth Test Request fields such as test name, dispatch status, and reason.',
			},
			{
				title: 'Scheduling',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Except for patients having their home state as Oregon, Idaho, Nebraska, Kansas, Texas, North Dakota, South Dakota, Florida, Michigan, Indiana, Kentucky, Tennessee, and Alaska, which have multiple timezones, you will not be able to edit timezones during appointment scheduling.',
			},
			{
				title: 'Documents',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'The Zendesk ticket that gets created after a fax failure during release will now include the failure reason as well.',
			},
		],
	},
	{
		releaseDate: 'Jul 8th, 2021',
		otherItems: [
			{
				title: 'Documents',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Answers to the form submitted by Illumina patients for the Galleri test will now show up as a pdf under Patient > Documents.',
			},
			{
				title: 'Documents Release',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'In Care Plan, we now have the ability to release Result Summary and/or Result Report to the referring partner at the same time through integrations when our Clinical Team is releasing these documents to the GMI Patient Portal. This will ensure these documents are shared with the referring partner without manual intervention by the Clinical Team.',
			},
			{
				title: 'TRO',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We will now be using the patient's home address state instead of delivery address state when assigning an MD to review the test order.",
			},
			{
				title: 'Scheduling',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"During appointment scheduling, the patient's state will no longer be editable on the calendar screen. The state can be updated on the first step in the scheduling process.",
			},
			{
				title: 'Providers',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We have added Chrissy Keywan to the provider list.',
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We are continuing to fix minor bugs and make your experience better.',
			},
		],
	},
	{
		releaseDate: 'Jun 24th, 2021',
		featureItems: [
			{
				title: 'Patient',
				tags: ['feature'],
				internal: true,
				media: [disable_dno],
				link: null,
				link_text: null,
				majorDescription:
					"We made it easy to enable outreach for patients who were marked as 'Do Not Outreach' previously. No more PS requests fam, you can enable it via UI.",
			},
		],
		otherItems: [
			{
				title: 'Appointments',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Leigha, Fatima, and Joanna - you are now added to the visit provider list.',
			},
			{
				title: 'Patient',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'You can now search by external patient id as well on the global search.',
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We are continuing to fix bugs and making improvements to enhance the portal experience.',
			},
		],
	},
	{
		releaseDate: 'Jun 17th, 2021',
		otherItems: [
			{
				title: 'Billing',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Washington, Texas, Alabama, Florida, Louisiana, North Carolina, North Dakota, Pennsylvania, South Carolina, Washington DC, West Virginia, and Wisconsin are now excluded from S Code billing.',
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We have fixed some more minor bugs and made some improvements.',
			},
		],
	},
	{
		releaseDate: 'Jun 3rd, 2021',
		otherItems: [
			{
				title: 'Billing',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Billing report export will now have the test name as well.',
			},
			{
				title: 'Appointments',
				tags: ['bug'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'When the second card was also declined after the first during appointment scheduling, it was not evident to the user. We made sure it will be indicated correctly on the UI with this release.',
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We have fixed some more minor bugs and made some improvements.',
			},
		],
	},
	{
		releaseDate: 'May 27th, 2021',
		otherItems: [
			{
				title: 'Appointments',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Multispecialty is now available for selection under specialties during scheduling. It can also be selected as Provider Indicated and Requested Specialty for encounters.',
			},
			{
				title: 'Tests',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Canceled encounters will not have an option of adding tests anymore.',
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We have made a number of changes under the hood to improve the performance and speed so you get the best portal experience. :)',
			},
		],
	},
	{
		releaseDate: 'May 20th, 2021',
		otherItems: [
			{
				title: 'Documents',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We updated the naming for Visit Documents by adding sub-type to make those easily identifiable.',
			},
			{
				title: 'Result cap card',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We updated the logic for Results CAP Not Sent card to exclude the patients for whom Genome Medical will not be releasing results.',
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We are constantly working to improve the portal experience. Stay tuned!',
			},
		],
	},
	{
		releaseDate: 'May 6th, 2021',
		otherItems: [
			{
				title: 'Patient Detail',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'CCs assigned to the patient will now be shown on the patient detail view.',
			},
			{
				title: 'Test Order',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'External lab order status associated with a test order will be shown in the test order scrubber on Encounter detail view making it easier to understand the current state of the order.',
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'This release might seem light, but there are a whole lot of updates on the way that require a little more TLC. Stay tuned!',
			},
		],
	},
	{
		releaseDate: 'Apr 29th, 2021',
		otherItems: [
			{
				title: 'Search',
				tags: ['bug'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Search bar on the appointment list was not working as expected sometimes. We fixed it!',
			},
			{
				title: 'Patient Create',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'To make it easier to input addresses for a patient during creation, we moved the fields to the form. No more hiding behind the modal!',
			},
			{
				title: 'GC Status',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"GC Review status will now be auto-updated to 'Sent to Patient' when CCs release 'Visit Documentation-Result Summary' to the patients.",
			},
		],
	},
	{
		releaseDate: 'Apr 22nd, 2021',
		otherItems: [
			{
				title: 'Results',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'In preparation for more configurable RoR outreach, we are separating the result of a test from its actionability. You will now start to see two separate questions when entering results in both the test order modal and the result detail page. Helpful hints will now guide you in choosing the correct options.',
			},
			{
				title: 'Cards',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Order status shown on the test card will now show the new result and actionability information accordingly.',
			},
			{
				title: 'Email',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Notification emails that GCs receive when results are uploaded will now take them directly to the relevant patient’s documents tab.',
			},
			{
				title: 'Appointment',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'You can now schedule a Return of Results consultation for any type of completed encounter.',
			},
			{
				title: 'Providers',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We stopped discriminating against providers in fancy cities. City names with apostrophes are now allowed... Rejoice, Martha’s Vineyardians!',
			},
			{
				title: 'Results',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We have added new tests into our database and updated gene lists for Invitae, Blueprint Genetics, and Quest.',
			},
			{
				title: 'Cards',
				tags: ['bug'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Minor fix to Results CAP Not Sent card to ensure we are capturing test results that come back after a Return of Results session.',
			},
		],
	},
	{
		releaseDate: 'Apr 15th, 2021',
		otherItems: [
			{
				title: 'Address',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'In preparation for some of the TRO module integrations, we’ve made updates to address collection on both the GM Portal and Patient Portal. Both Home & Shipping addresses are now required during scheduling.',
			},
			{
				title: 'Gender',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'In our efforts to be more inclusive, we are including a way to capture a patient’s preferred name and pronouns to help address them better. We are also making “Sex Assigned at Birth” required and “Gender” optional in our data model.',
			},
			{
				title: 'Templates',
				tags: ['bug'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Fixed a bug on the templates tab where the reset button wasn’t working properly.',
			},
			{
				title: 'Documents',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'You can now edit document details for pdfs generated from Google Docs as well.',
			},
		],
	},
	{
		releaseDate: 'Apr 8th, 2021',
		otherItems: [
			{
				title: 'Document Release',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Document release modal will now show you the name of the document being released.',
			},
			{
				title: 'Advanced Search',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'You can now search for a particular outreach status in the advanced search.',
			},
			{
				title: 'Misc',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'This release might seem light, but there are a whole lot of updates on the way that require a little more TLC. Stay tuned!',
			},
		],
	},
	{
		releaseDate: 'Apr 1st, 2021',
		featureItems: [
			{
				title: 'Document List',
				tags: ['feature'],
				internal: true,
				media: [document_list],
				link: null,
				link_text: null,
				majorDescription:
					'We have a brand new Patient documents list UI that introduces a clearer and cleaner look, multiple grouping options like by encounter or by document type, status pills on Google docs, etc. We are also trying something new with this release where you can opt in and out of the new UI during this preview period. We would love your feedback during this preview period.',
			},
		],
		otherItems: [
			{
				title: 'Result Fields',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'The test results data model has been enhanced to include a few new PGx-specific fields - HGVS, Copy Number, and Activity Score.',
			},
			{
				title: 'Document Release',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Document release modal will now show you a warning about which encounter fields will be changed so that you are aware of the changes before going through with the release.',
			},
			{
				title: 'Patient Notes',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'You will now start seeing a counter on the Patient record “Notes” tab to indicate how many notes have been entered.',
			},
		],
	},
	{
		releaseDate: 'Mar 25th, 2021',
		otherItems: [
			{
				title: 'Specialty',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We added a new Specialty category called “Cancer - Somatic” per the Cancer team’s request. This option will be available during scheduling in both the patient portal and the GM portal.',
			},
			{
				title: 'Audit Trail',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'A document’s audit trail now shows internal sharing activity as well.',
			},
			{
				title: 'Tests',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We have added new tests into our database and updated gene lists for Invitae, Blueprint Genetics, GeneDx, Quest, and Labcorp.',
			},
			{
				title: 'Insurance',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'The billing staff can now edit Insurance name info in the GM portal’s Insurance Management tab.',
			},
		],
	},
	{
		releaseDate: 'Mar 18th, 2021',
		featureItems: [
			{
				title: 'Return of Results Automation',
				tags: ['feature'],
				internal: true,
				media: null,
				link: 'https://docs.google.com/presentation/u/0/d/1JEF0tI1Ov8tWihpik2MNvs-3oRoVkxXys0OJQAvElX0/edit',
				link_text: 'RoR Training Slides',
				majorDescription:
					'In this major feature release, we have reworked the Return of Results outreach protocol and interfaces to reduce the manual outreach effort made by CCs today. Automated emails plus a more streamlined outreach call flow make it faster and easier to perform the outreach. This new feature also allows us to outreach for multiple tests together (using the hold flag) or separately (through multiple outreach rounds).',
			},
			{
				title: 'Manual Visit Encounter',
				tags: ['feature'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'You can now create “Visit” encounters manually by going to Patient Detail > Encounters Tab > New Encounter > Visit (Manual)',
			},
		],
		otherItems: [
			{
				title: 'Filters',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Do you get annoyed when you have to reselect filters and redo table sorting every time you come to a page with tables? Say no more fam! Filters and table sorting stays put now even when you navigate back and forth within the portal or close the tab/logout.',
			},
			{
				title: 'Filters',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Since we are saving your filter and sort preferences on a page-by-page basis, we figured you’d need an easy way to reset those preferences back to page defaults. There is a new reset button in the filters section for you to do just that.',
			},
			{
				title: 'Billing',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Delaware didn’t wanna be in our S Code billing party so we took it off :(',
			},
			{
				title: 'Patients List',
				tags: ['bug'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Priority patients weren’t showing up at the top of the list when the “Needs Insurance Selection” filter was enabled. We fixed it now, though.',
			},
		],
	},
	{
		releaseDate: 'Mar 11th, 2021',
		featureItems: [
			{
				title: 'Dashboard List',
				tags: ['feature'],
				internal: true,
				link: null,
				media: [open_new_tab],
				majorDescription:
					'We heard your feedback!! Links to patient detail within the dashboard cards will now also show a toolbar to open in a new tab.',
			},
		],
		otherItems: [
			{
				title: 'Dashboard List',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'No yin without yang - you were able to change filters/sorting for patients list, now you can reset them back, too.',
			},
			{
				title: 'General',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'This release might seem light, but there are a whole lot of updates on the way that requires a little more TLC. Stay tuned :)',
			},
		],
	},
	{
		releaseDate: 'Mar 4th, 2021',
		featureItems: [
			{
				title: 'Global Search',
				tags: ['feature'],
				internal: true,
				media: [global_search],
				link: null,
				majorDescription:
					'Global search bar in the header now shows suggestions as you type! You can then click on the suggested patient and go straight into their details saving time and effort. We have included email, DoB, and patient tags as identifiers in the suggestions to better help you pick the right patient.',
			},
		],
		otherItems: [
			{
				title: 'Documents List',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We have consolidated the many different ways of editing document related information into an 'Edit Document Details' button and modal.",
			},
			{
				title: 'Open links in new window',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Links to other parts of the portal or to external pages will now show a tooltip on hover with extra options, like opening the link in a new tab.',
			},
			{
				title: 'Refresh popup updates',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"The alert modal you see when we push changes to the portal can now take you to what's new if you want it to :)",
			},
			{
				title: 'Patients list view',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Patients list view has been updated to match our new table design. We’ve also cleaned up the buttons and filters to make it consistent with the rest of the portal. We're now also showing all enabled patient tags next to the patient name in the form of colored dots.",
			},
			{
				title: 'Doc release email',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'The reply-to email address in the document release notification email has been updated to clinical@genomemedical.com and option 2 after phone number has been added.',
			},
			{
				title: 'Doc release',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Priority patients will now be indicated as STAT patients on the Fax cover sheet for document release.',
			},
			{
				title: 'Doc release',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Fax/email failure tickets in Zendesk will also show priority patients as STAT patients.',
			},
			{
				title: 'Test results',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'You can now change the test result from negative to positive without having to add gene info.',
			},
			{
				title: 'Dashboard',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"We are constantly working on improving overall dashboard and cards' performance. If you didn't notice the speed yet, we promise you will soon!",
			},
		],
	},
	{
		releaseDate: 'Feb 25th, 2021',
		featureItems: [
			{
				title: 'Release Notes',
				tags: ['feature'],
				internal: true,
				media: null,
				link: null,
				majorDescription: 'This feature needs no introduction :)',
			},
		],
		otherItems: [
			{
				title: 'Dashboard PoP Card',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Tired of waiting on PoP card to load? We hear you! We are making our query faster so you don’t have to wait after clicking on the card to load the patients list.',
			},
			{
				title: 'Navbar',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Minor update to the navigation dock to make it easier to identify which tab you are in.',
			},
			{
				title: 'Templates',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					"Don't change what isn't broken! We accidentally made the templates list paginated when it didn't use to be - Sorry Scott! This update fixes that.",
			},
			{
				title: 'Test & Genes Metadata',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We have added new tests into our database and updated gene lists for Invitae, Blueprint Genetics, Prevention and GeneDx.',
			},
		],
	},
	{
		releaseDate: 'Feb 18th, 2021',
		featureItems: [
			{
				title: 'Patient Tags',
				tags: ['feature'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'With tags, we have introduced an improved way of identifying patients so that we can serve them better. Moreover, we have enabled the ability to select tags during the patient creation process.',
			},
			{
				title: 'Document Release',
				tags: ['feature'],
				internal: true,
				media: [document_finalize],
				majorDescription:
					"In this major feature release, we have enabled the ability to release documents to patients and their providers right from the portal. With just a few clicks, documents can be released to the patient's portal or to providers through fax or email.",
				subItems: [
					{
						header: 'Log Manual Release',
						description:
							'Shared a document via Email? We made sure you can log that too!',
						media: [log_manual_release],
					},
					{
						header: 'Audit Trail',
						description:
							'Want to know what a document went through? We’ve got you covered.',
						media: [audit_trail],
					},
				],
			},
		],
		otherItems: [
			{
				title: 'Phone Number Type',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'We have made enhancements to the patient contact information fields. We will now be able to better identify what type of phone number we have on file for a patient and even record a secondary phone number if needed.',
			},
			{
				title: 'Scheduling Flow',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Scheduling flow step 1 has been redesigned for better usability. We have also introduced guardian and phone number type fields to make it easier to edit patient information during this process.',
			},
			{
				title: 'Appointment Search',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'You can now search within the appointment list by patient UUIDs.',
			},
			{
				title: 'External Providers',
				tags: ['enhancement'],
				internal: true,
				media: null,
				link: null,
				majorDescription:
					'Provider NPI number has added validation - only numbers, 10 digits.',
				subItems: null,
			},
		],
	},
];

export const externalUserReleaseNotesJSON = [
	{
		releaseDate: 'December 11th, 2024',
		featureItems: [
			{
				title: 'Referral Dashboard',
				tags: ['feature'],
				internal: true,
				media: [],
				link: null,
				link_text: null,
				majorDescription:
					'We are excited to announce that a new platform feature "Referral Dashboard" is now live! Below you will find a screenshot example of how to access this new feature, an overview of the available fields, and key functionality.',
				subItems: [
					{
						header: '',
						description: 'The dashboard includes the following fields:',
						media: null,
						subList: [
							{
								header: 'Referral Date',
								description:
									'When the referral was created.',
								media: null,
							},
							{
								header: 'Patient Name & Phone Number',
								description:
									'Links to the patient profile. Flags like priority or interpreter-needed appear as icons here.',
								media: null,
							},
							{
								header: 'Program Name & Referral Status',
								description:
									'Tracks the referral’s progress through various stages.',
								media: null,
							},
							{
								header: 'Last Outreached',
								description:
									'Logs the latest outreach attempt.',
								media: null,
							},
							{
								header: 'Consultation Type & Specialty',
								description:
									'Displays referral-specific details if provided.',
								media: null,
							},
							{
								header: 'Schedule Button',
								description:
									'Opens the scheduling page for the patient.',
								media: [],
							},
						],
					},
					{
						header: '',
						description: 'Key functions include:',
						media: null,
						subList: [
							{
								header: 'Create a Patient',
								description:
									'Opens the page to submit a new referral.',
								media: null,
							},
							{
								header: 'Export',
								description:
									'Export referral data as a CSV file based on the filter set. (exports based on current filters)',
								media: null,
							},
							{
								header: 'Program Name & Referral Status',
								description:
									'Tracks the referral’s progress through various stages.',
								media: null,
							},
							{
								header: 'Search',
								description:
									'Find referrals by patient name, UUID, or email.',
								media: null,
							},
							{
								header: 'Schedule Appointments',
								description:
									'Use the schedule button to open the scheduling page.',
								media: null,
							},
							{
								header: 'Filter Fields',
								description:
									'Referral date, Program name, Referral status, Consultation type, Specialty, and Patient flags.',
								media: [referral_dashboard],
							},
						],
					},
				],
			},
			{
				title: '',
				tags: ['feature'],
				show_tag_title: false,
				internal: false,
				media: null,
				link: null,
				majorDescription:
					"If you have any questions or need further clarification, please don't hesitate to reach out to your Client Success Manager or the Client Success Team at CS@genomemedical.com.",
			},
		],
	},
	{
		releaseDate: 'June 12th, 2024',
		featureItems: [
			{
				title: '',
				tags: ['feature'],
				show_tag_title: false,
				internal: false,
				media: null,
				link: null,
				majorDescription:
					"We are excited to let you know that the platform enhancements are now live!\nTo ensure a smooth transition and to help everyone get acquainted with the new features, we've prepared comprehensive training materials and FAQs linked below. We encourage you to review the training materials and familiarize yourself with changes. As a reminder, the enhancements that went live include:",
				subItems: [
					{
						header: '',
						description: '',
						media: null,
						subList: [
							{
								header: 'Streamlined Patient Creation',
								description:
									'We’ve simplified the process for creating new patient records. With fewer input fields, and an optimized setup, your team can now refer patients more efficiently.',
								media: null,
							},
							{
								header: 'Revamped Scheduling Workflow',
								description:
									'Our consolidated user interface will make scheduling appointments easier for your staff and patients. We’ve also enhanced the clarity of billing information to reduce confusion for you and our patients.',
								media: null,
							},
						],
						links: [
							{
								link: 'https://docs.google.com/presentation/d/1N2gwj5jwo0eg7ZY2_dQuRsi27JYoBM1Wd468FAtX32E/edit#slide=id.g27092a2832a_0_1287',
								text: 'Training materials',
							},
							{
								link: 'https://docs.google.com/document/d/1sMerk30Vr1qSSILy7WfL6XPQfoakyOrYgJ1ABmtDO4Y/edit#heading=h.rvvsibdn60n1',
								text: 'FAQs',
							},
						],
					},
				],
			},
			{
				title: '',
				tags: ['feature'],
				show_tag_title: false,
				internal: false,
				media: null,
				link: null,
				majorDescription:
					"Thank you for your continued support and enthusiasm throughout this process. We believe these enhancements will greatly improve our platform and enhance the user experience for everyone. If you have any questions or need further clarification, please don't hesitate to reach out to your Client Success Team at CS@genomemedical.com.",
			},
		],
	},
];
