import axios from 'axios';
import get from 'lodash/get';

class Appointments {
	get = (data) => {
		return axios.get('/api/encounters/visits', {
			params: {
				offset: get(data, 'offset', 0),
				limit: get(data, 'limit', 20),
				referral_program: get(data, 'referral_program', ''),
				visit_provider: get(data, 'visit_provider', []),
				visit_provider_uuid: get(data, 'visit_provider_uuid', []),
				visit_status: get(data, 'visit_status', []),
				consultation_type: get(data, 'consultation_type', ''),
				tm_platform: get(data, 'tm_platform', ''),
				q: get(data, 'q', ''),
				vsee_specialty: get(data, 'vsee_specialty', ''),
				patient_athena_package: get(
					data,
					'patient_athena_package',
					false
				),
				payor: get(data, 'payor', ''),
			},
		});
	};
	switchModality = (encounter_uuid, type) => {
		return axios.post(
			`/api/encounters/visits/${encounter_uuid}/switchmodality`,
			{
				type: type,
			}
		);
	};

	bookAppointment = async (data) => {
		const res = await axios.post('/api/encounters/visits', data);
		return res;
	};
}

export default new Appointments();
