//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Utils
import {
	encounter_specialty_mapping,
	ucFirstAllWords,
} from './../../Utils/appointmentUtils.js';

//Components
import Icon from '../Common/Icon';

class AppointmentSnackbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.slideOffSnackbar = this.slideOffSnackbar.bind(this);
	}
	//functions

	slideOffSnackbar() {
		const localSnackbarContainer = this.snackbar;
		const macroLevelSnackbarContainer = get(
			localSnackbarContainer,
			'parentNode.parentNode.parentNode.parentNode',
			document.createElement('div')
		);
		if (
			macroLevelSnackbarContainer.classList.contains(
				'appointmentCreateSnackbarContainer'
			)
		) {
			macroLevelSnackbarContainer.classList.add(
				'appointmentCreateSnackbarContainerClosing'
			);
		}
	}

	render() {
		const encounter = get(this, 'props.encounter', {}) || {};
		const name = `${get(this, 'props.patient.last_name', '--') || '--'}, ${
			get(this, 'props.patient.first_name', '--') || '--'
		}`;
		const type = get(encounter, 'consultation_type', '--') || '--';
		const timeslotObj = get(this, 'props.appointmentTimeslot', {}) || {};
		const state = get(encounter, 'state_of_visit', '--') || '--';
		const specialty = get(encounter, 'vsee_specialty', '--') || '--';
		const modality = get(encounter, 'tm_platform', '--') || '--';

		return (
			<div
				className='appointmentCreateSnackbar'
				ref={(el) => (this.snackbar = el)}
			>
				<div className='appointmentCreateSnackbarTopRow'>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<Icon
							icon='check-circle'
							style={{
								marginRight: '4px',
								position: 'relative',
								bottom: '3px',
							}}
						/>
						<span
							style={{
								position: 'relative',
								bottom: '3px',
								left: '1px',
							}}
						>
							Appointment Created
						</span>
					</div>
					<Icon
						icon='close'
						style={{
							position: 'relative',
							bottom: '8px',
							height: '16px',
							width: '16px',
						}}
						onClick={this.slideOffSnackbar}
						className='appointmentCreateSnackbarX'
					/>
				</div>
				<div
					className='appointmentCreateSnackbarText'
					style={{ marginTop: '14px' }}
				>
					{name}
				</div>
				<div
					className='appointmentCreateSnackbarText'
					style={{ marginTop: '14px', marginBottom: '6px' }}
				>
					{type}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginTop: '2px',
						marginBottom: '5px',
					}}
				>
					<div className='appointmentCreateSnackbarSmallText'>
						{this.props.getTimestamp(encounter, timeslotObj)} -{' '}
					</div>
					<div
						className='appointmentCreateSnackbarSmallText'
						style={{ fontFamily: 'RionaSans-Bold' }}
					>
						&nbsp; {state}
					</div>
				</div>
				<div className='appointmentCreateSnackbarSmallText'>
					{encounter_specialty_mapping[specialty] ||
						ucFirstAllWords(
							specialty
								.replace('_willing', '')
								.replace('_Willing', '')
								.replace(/_/g, ' ')
						)}
				</div>
				<div
					className='scheduleAppointmentSideContainerPatientText scheduleAppointmentSideContainerRow'
					style={{ marginBottom: '1px', marginTop: '5px' }}
				>
					<div className='scheduleAppointmentSideContainerHalf'>
						{this.props.getProviderImage(encounter, true)}
						{this.props.getProviderName(encounter, true)}
					</div>
					<div className='scheduleAppointmentSideContainerHalf'>
						{this.props.getModalityIcon(encounter, true)}
						<span
							className='tm-platform'
							style={{ color: '#ffffff', marginLeft: '4px' }}
						>
							{modality}
						</span>
					</div>
				</div>
			</div>
		);
	}
}

export default AppointmentSnackbar;
