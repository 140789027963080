//Lodash
import _, { get } from 'lodash';

//Utils
import {
	RemoveUnderscoreAndCapitalizeInString,
	UTCToCurrentView,
	precision,
} from '../../../../../utils';
import {
	order_status_parent_options as order_status_main_level,
	order_status_canceled_options,
	order_request_status_main_level,
	preAuthObj,
	preAuthFields,
	rejectedObj,
	rejectedFields,
	declinedObj,
	declinedFields,
	providers_images,
	imageDefault,
	scp_encounter_sub_types_pre_appt,
	scp_encounter_sub_types,
	scp_encounter_sub_types_partner_initiated_pre_test,
	scp_encounter_sub_types_partner_initiated_unsolicited_result,
	encounter_types,
} from '../../../../../Utils/encounterUtils';

//Other Libraries
import moment from 'moment';
import 'moment-timezone';

export const emptylabel = ' ';

export const encounterFieldDisplay = (object, children) => {
	const value = get(object, children, '');
	if (
		!_.isNumber(value) &&
		(_.isNil(value) || _.isEmpty(value) || get(value, 'length', 0) == 0)
	) {
		return '--';
	}
	let displayName = RemoveUnderscoreAndCapitalizeInString(
		get(object, children, '')
	);
	if (displayName === 'Self Pay') {
		displayName = 'Non-insurance';
	}
	return displayName;
};

export const encounterFreeTextFieldDisplay = (object, children) => {
	const value = get(object, children, '');

	if (_.isNil(value) || _.isEmpty(value) || get(value, 'length', 0) == 0) {
		return '--';
	}
	return get(object, children, '');
};

export const encounterCheckboxDisplay = (object, children) => {
	const value = get(object, children, false);
	if (value && value == true) {
		return 'Yes';
	}
	return 'No';
};
export const getEncounterSpecificTime = (datetimestr, timezone) => {
	return datetimestr != ''
		? UTCToCurrentView(moment, moment.tz(datetimestr, 'UTC'), timezone)
		: '--';
};

export const EncounterDateFieldsDisplay = (object, children) => {
	return moment(get(object, children, '')).isValid()
		? moment(get(object, children, '')).local().format('MMM D, YYYY')
		: encounterFieldDisplay(get(object, children, ''));
};

export const EncounterDateTimeFieldsDisplay = (object, children) => {
	return moment(get(object, children, '')).isValid()
		? moment(get(object, children, '')).local().format('MMM D, YYYY HH:mm')
		: encounterFieldDisplay(get(object, children, ''));
};

export const GetMultiSelectvalue = (object, children, enums) => {
	const val = [];
	const value = get(object, children);
	if (_.isNil(value) || _.isEmpty(value) || get(value, 'length', 0) == 0) {
		return '-';
	}
	(value || []).map((a) => {
		const temp = enums.filter((b) => b.key === a);
		temp.length > 0 && val.push(temp[0].display_name);
	});
	return val.length > 0 ? val.toString() : '-';
};

export const GetSelectvalue = (object, children, enums) => {
	const value = get(object, children, '');
	if (_.isNil(value) || _.isEmpty(value) || get(value, 'length', 0) == 0) {
		return '--';
	}
	const val = (enums || []).filter(
		(a) => a.key && a.key.toUpperCase() === value.toUpperCase()
	);
	return !_.isEmpty(val)
		? get(val[0], 'display_name', '-')
		: value != ''
		? value
		: '-';
};

export const valueWithDollar = (object, children) => {
	const value = get(object, children, null);
	if (_.isNull(value)) {
		return '--';
	}
	const nannumber = parseFloat(value);
	if (!isNaN(nannumber)) {
		if (precision(parseFloat(value)) <= 0) {
			return '$' + parseInt(value);
		} else {
			return '$' + value;
		}
	} else {
		return value;
	}
};

export const createEnumsOptions = (
	object,
	children,
	keyname,
	labelname,
	valuename
) => {
	return get(object, children, []).map((el) => {
		const option = {};
		option.label = el[labelname] == '' ? emptylabel : el[labelname];
		if (keyname === 'visit_provider_uuid') {
			option.key = keyname;
			option.value = el['uuid'];
		} else {
			option.key = el[keyname];
			option.value = el[valuename];
		}
		return option;
	});
};

export const createStateEnumsOptions = (
	object,
	keyname,
	labelname,
	valuename
) => {
	return (object || []).map((el) => {
		const option = {};
		option.key = el[keyname];
		option.label = el[labelname] == '' ? emptylabel : el[labelname];
		option.value = el[valuename];
		return option;
	});
};

export const DatePickerValue = (object, children) => {
	return moment(get(object, children, ''), 'YYYY-MM-DD').isValid()
		? moment(get(object, children, ''), 'YYYY-MM-DD').toDate()
		: null;
};

export const DateTimePickerValue = (object, children) => {
	return moment(get(object, children, ''), 'YYYY-MM-DD hh:mm').isValid()
		? moment(get(object, children, ''), 'YYYY-MM-DD hh:mm').toDate()
		: null;
};

export const OrderRequestStatusCascaderEnums = (
	object,
	children,
	keyname,
	labelname,
	valuename
) => {
	const enums = get(object, children, []);
	const order_request_status_options = _.filter(enums, function (o) {
		return o[keyname] in order_request_status_main_level;
	});

	order_request_status_options.splice(3, 0, preAuthObj);
	order_request_status_options.splice(2, 0, declinedObj);
	order_request_status_options.splice(5, 0, rejectedObj);
	order_request_status_options.join();

	const order_request_status_cascader_options = (
		order_request_status_options || []
	).map((e) => {
		if (e.key == 'preauthorization') {
			const children = _.filter(enums, function (o) {
				return (
					!(o[keyname] in order_request_status_main_level) &&
					o[keyname] in preAuthFields
				);
			});
			const cascader_children = (children || []).map((e) => {
				const option = {};
				option.key = e[keyname];
				option.label = e[labelname] == '' ? emptylabel : e[labelname];
				option.value = e[valuename];
				return option;
			});
			const option = {};
			option.key = e[keyname];
			option.label = e[labelname] == '' ? emptylabel : e[labelname];
			option.value = e[valuename];
			option.children = cascader_children;
			return option;
		} else if (e.key == 'rejected') {
			const children = _.filter(enums, function (o) {
				return (
					!(o[keyname] in order_request_status_main_level) &&
					o[keyname] in rejectedFields
				);
			});
			const cascader_children = (children || []).map((e) => {
				const option = {};
				option.key = e[keyname];
				option.label = e[labelname] == '' ? emptylabel : e[labelname];
				option.value = e[valuename];
				return option;
			});
			const option = {};
			option.key = e[keyname];
			option.label = e[labelname] == '' ? emptylabel : e[labelname];
			option.value = e[valuename];
			option.children = cascader_children;
			return option;
		} else if (e.key == 'declined') {
			const children = _.filter(enums, function (o) {
				return (
					!(o[keyname] in order_request_status_main_level) &&
					o[keyname] in declinedFields
				);
			});
			const cascader_children = (children || []).map((e) => {
				const option = {};
				option.key = e[keyname];
				option.label = e[labelname] == '' ? emptylabel : e[labelname];
				option.value = e[valuename];
				return option;
			});
			const option = {};
			option.key = e[keyname];
			option.label = e[labelname] == '' ? emptylabel : e[labelname];
			option.value = e[valuename];
			option.children = cascader_children;
			return option;
		} else {
			const option = {};
			option.key = e[keyname];
			option.label = e[labelname] == '' ? emptylabel : e[labelname];
			option.value = e[valuename];
			return option;
		}
	});
	return order_request_status_cascader_options;
};

export const CascaderOptions = (enumsdata, keyname, labelname, valuename) => {
	const enums = _.isArray(enumsdata) ? enumsdata : [];

	const options = enums.map((el) => {
		const parent = {};
		parent.key = el[keyname];
		parent.label = el[labelname] == '' ? emptylabel : el[labelname];
		parent.value = el[valuename];
		if (_.isArray(el.children)) {
			const children = el.children.map((els) => {
				const firstlevelchild = {};
				firstlevelchild.key = els[keyname];
				firstlevelchild.label =
					els[labelname] == '' ? emptylabel : els[labelname];
				firstlevelchild.value = els[valuename];
				return firstlevelchild;
			});
			parent.children = children;
		}
		return parent;
	});
	return options;
};

export const findCascaderHierarchy = (enums, keyname, element) => {
	const hierachy = [];
	element = element == 'empty' ? '' : element;
	const firstchild = _.find(enums || [], function (o) {
		return o[keyname] == element;
	});
	if (firstchild) {
		hierachy.push(firstchild[keyname]);
	} else {
		_.map(enums || [], function (o) {
			if (_.isArray(o.children)) {
				const secondchild = _.find(o.children, function (child) {
					return child[keyname] == element;
				});
				if (secondchild) {
					hierachy.push(o[keyname]);
					hierachy.push(secondchild[keyname]);
				}
			}
		});
	}
	return hierachy;
};

export const OrderStatusHierarchyEnums = (enums, keyname) => {
	if (!_.isArray(enums)) {
		return [];
	}
	const order_status_options = _.filter(enums, function (o) {
		return o[keyname] in order_status_main_level;
	});

	const hierarchyEnums = (order_status_options || []).map((e) => {
		if (e[keyname] == 'waiting_to_submit') {
			const children = _.filter(enums, function (o) {
				return (
					!(o[keyname] in order_status_main_level) &&
					!(o.key in order_status_canceled_options)
				);
			});
			const obj = {
				...e,
				children,
			};
			return obj;
		} else if (e[keyname] == 'cancelled') {
			const children = _.filter(enums, function (o) {
				return o[keyname] in order_status_canceled_options;
			});
			const obj = {
				...e,
				children,
			};
			return obj;
		} else {
			return e;
		}
	});
	return hierarchyEnums;
};

export const MLOORderStatusHierarchyEnums = (arr) => {
	const mloorderstatus = (arr || []).map((e) => {
		if (get(e, ['status_reason', '0'], null) != null) {
			return {
				key: e.key,
				display_name: e.display_name,
				children: e.status_reason,
			};
		} else {
			return {
				key: e.key,
				display_name: e.display_name,
			};
		}
	});
	return mloorderstatus;
};

export const OrderRequestStatusHierarchyEnums = (object, children, keyname) => {
	const enums = get(object, children, []);

	const order_request_status_options = _.filter(enums, function (o) {
		return o[keyname] in order_request_status_main_level;
	});
	order_request_status_options.splice(3, 0, preAuthObj);
	order_request_status_options.splice(2, 0, declinedObj);
	order_request_status_options.splice(5, 0, rejectedObj);
	order_request_status_options.join();

	const order_request_status_cascader_options = (
		order_request_status_options || []
	).map((e) => {
		if (e.key == 'preauthorization') {
			const children = _.filter(enums, function (o) {
				return (
					!(o[keyname] in order_request_status_main_level) &&
					o[keyname] in preAuthFields
				);
			});
			const obj = {
				...e,
				children,
			};
			return obj;
		} else if (e.key == 'rejected') {
			const children = _.filter(enums, function (o) {
				return (
					!(o[keyname] in order_request_status_main_level) &&
					o[keyname] in rejectedFields
				);
			});
			const obj = {
				...e,
				children,
			};
			return obj;
		} else if (e.key == 'declined') {
			const children = _.filter(enums, function (o) {
				return (
					!(o[keyname] in order_request_status_main_level) &&
					o[keyname] in declinedFields
				);
			});
			const obj = {
				...e,
				children,
			};
			return obj;
		} else {
			return e;
		}
	});
	return order_request_status_cascader_options;
};

export const filterNonLegacyTestEnumes = (unfilter_test_enums) => {
	let filterenums = _.filter(unfilter_test_enums, (element) => {
		return (
			element &&
			_.isBoolean(element.is_legacy) &&
			element.is_legacy == false
		);
	});

	if (_.isArray(filterenums))
		filterenums = _.sortBy(filterenums, [
			function (o) {
				return o.display_name.toLowerCase();
			},
		]);

	const hydrateenums = _.isArray(filterenums)
		? filterenums.map(function (e) {
				return {
					display_name: e.display_name,
					key: e.key,
					order_questionnaire: get(e, 'order_questionnaire', null),
					reflex_questionnaire: get(e, 'reflex_questionnaire', null),
					required_address: get(e, 'required_address', []),
					lab_integration_enabled: e.lab_integration_enabled,
					group: e?.group || {},
				};
		  })
		: [];
	return hydrateenums;
};

export const filterOrderStatusEnumes = (unfilter_test_enums) => {
	const filterenums = _.filter(unfilter_test_enums, (element) => {
		return element && element.key && element.key != 'results_entered';
	});
	return filterenums;
};

export const isAppointmentStarted = (encounter) => {
	if (
		!_.isNil(encounter) &&
		!_.isNil(encounter.date_of_service) &&
		moment(encounter.date_of_service).isValid()
	) {
		const appointmentDateObj = moment(
			encounter.date_of_service,
			'YYYY-MM-DD HH:mm:ss'
		).toDate();
		const appointmentTime =
			appointmentDateObj.getTime() -
			appointmentDateObj.getTimezoneOffset() * 60000;
		const currentTime = new Date().getTime();

		if (currentTime >= appointmentTime) {
			return true;
		} else {
			return false;
		}
	}
	return false;
};

export const getProviderName = (object, children, enumslist) => {
	const enums = _.isArray(enumslist) ? enumslist : [];
	const enum_value = _.find(enums, function (o) {
		return o.key == get(object, children, '--');
	});
	let providerName = '';
	let separator = ' ';

	if (enum_value) {
		providerName = get(enum_value, 'display_name', '');
	} else {
		separator = '_';
		providerName = ucFirstAllWords(get(object, children, ''), separator);
	}
	if (providerName.length > 0) {
		const ProviderNameobj = providerName.split(separator);
		const first_name = get(ProviderNameobj, 0, '');
		const middle_name =
			providerName.length > 2 ? ' ' + get(ProviderNameobj, 1, '') : '';
		const last_name =
			providerName.length > 2
				? ' ' + get(ProviderNameobj, 2, '')
				: ' ' + get(ProviderNameobj, 1, '');
		const result = first_name.charAt(0) + '.' + middle_name + last_name;
		return result;
	} else {
		return '--';
	}
};
export const getProviderNameForLab = (object, children) => {
	let providerName = get(object, children, '--');
	providerName = providerName
		? ucFirstAllWords(providerName.replace(/_/g, ' '))
		: '';
	const separator = ' ';
	if (providerName && providerName.length > 0) {
		const ProviderNameobj = providerName.split(separator);
		const first_name = get(ProviderNameobj, 0, '');
		const middle_name =
			providerName.length > 2 ? ' ' + get(ProviderNameobj, 1, '') : '';
		const last_name =
			providerName.length > 2
				? ' ' + get(ProviderNameobj, 2, '')
				: ' ' + get(ProviderNameobj, 1, '');
		const result = first_name.charAt(0) + '.' + middle_name + last_name;
		return result;
	} else {
		return '--';
	}
};

export const getProviderIcon = (object, children) => {
	const providerName = get(object, children, '');
	const mappedImage = providers_images[providerName];
	if (providerName && mappedImage) {
		return mappedImage;
	} else if (providerName != '') return imageDefault;
	return null;
};

export const ucFirstAllWords = (str, separator = ' ') => {
	const pieces = str ? str.split(separator) : [];
	for (let i = 0; i < pieces.length; i++) {
		const j = pieces[i].charAt(0).toUpperCase();
		pieces[i] = j + pieces[i].substr(1).toLowerCase();
	}
	return pieces.join(separator);
};

export const getMomentDateOrString = (datetimestr) => {
	return moment(datetimestr, 'YYYY-MM-DD', true).isValid()
		? moment(datetimestr, 'YYYY-MM-DD', true)
		: datetimestr;
};

export const getMomentDateOrStringNonStrick = (datetimestr) => {
	return moment(datetimestr, 'YYYY-MM-DD').isValid()
		? moment(datetimestr, 'YYYY-MM-DD').format('YYYY-MM-DD')
		: datetimestr;
};

export const getDateString = (datetime) => {
	return moment.isMoment(datetime)
		? moment(datetime).format('YYYY-MM-DD')
		: datetime;
};

export const isValidDateString = (datetimestr) => {
	return moment(datetimestr, 'YYYY-MM-DD', true).isValid();
};
export const isValidDateStringOREmpty = (datetimestr) => {
	return datetimestr == '' || isValidDateString(datetimestr);
};

export const getMomentDateTimeOrString = (datetimestr) => {
	return moment(datetimestr, 'YYYY-MM-DD HH:mm:ss', true).isValid()
		? moment(datetimestr, 'YYYY-MM-DD HH:mm:ss', true)
		: datetimestr;
};

export const getDateTimeString = (datetime) => {
	return moment.isMoment(datetime)
		? moment(datetime).format('YYYY-MM-DD HH:mm:ss')
		: datetime;
};

export const isValidDateTimeString = (datetimestr) => {
	return moment(datetimestr, 'YYYY-MM-DD HH:mm:ss', true).isValid();
};
export const isValidDateTimeStringOREmpty = (datetimestr) => {
	return datetimestr == '' || isValidDateTimeString(datetimestr);
};

export const getCalculatedUnits = (data) => {
	const total_cc_minutes =
		(_.round(get(data, 'direct_cc_minutes')) || 0) +
			(_.round(get(data, 'indirect_cc_minutes')) || 0) +
			(_.round(get(data, 'post_session_indirect_cc_minutes')) || 0) || 0;
	if (
		get(data, 'athena_insurance_package_id') == null ||
		get(data, 'athena_insurance_package_id') == '' ||
		total_cc_minutes == 0
	)
		return null;

	if (get(data, 'athena_insurance_package_id') in { 11082: 1, 555153: 1 }) {
		if (total_cc_minutes >= 1 && total_cc_minutes <= 22) {
			return 1;
		} else if (total_cc_minutes >= 23 && total_cc_minutes <= 37) {
			return 2;
		} else if (total_cc_minutes >= 38) {
			return 3;
		}
	} else {
		if (
			get(data, 'direct_cc_minutes') == 0 ||
			get(data, 'direct_cc_minutes') == null
		)
			return null;
		else if (get(data, 'direct_cc_minutes') <= 45) {
			return 1;
		} else return 2;
	}
};

export const getCalculatedScodeUnits = (data) => {
	if (_.round(get(data, 'direct_cc_minutes', 0)) < 8) return 0;
	else if (
		get(data, 'direct_cc_minutes', 0) >= 8 &&
		get(data, 'direct_cc_minutes', 0) <= 22
	) {
		return 1;
	} else if (
		get(data, 'direct_cc_minutes', 0) >= 23 &&
		get(data, 'direct_cc_minutes', 0) <= 37
	)
		return 2;
	else if (
		_.round(get(data, 'direct_cc_minutes', 0)) >= 38 &&
		_.round(get(data, 'direct_cc_minutes', 0)) <= 52
	)
		return 3;
	else if (_.round(get(data, 'direct_cc_minutes', 0)) >= 53) return 4;
};

export const Timezones = [
	{
		display_name: '(GMT-05:00) Eastern Time',
		key: 'America/New_York',
	},
	{
		display_name: '(GMT-06:00) Central Time',
		key: 'America/Chicago',
	},

	{
		display_name: '(GMT-07:00) Mountain Time',
		key: 'America/Denver',
	},
	{
		display_name: '(GMT-07:00) Mountain Time - Arizona',
		key: 'America/Phoenix',
	},
	{
		display_name: '(GMT-08:00) Pacific Time',
		key: 'America/Los_Angeles',
	},
	{
		display_name: '(GMT-09:00) Alaska Standard Time',
		key: 'America/Anchorage',
	},
	{
		display_name: '(GMT-10:00) Hawaii Time',
		key: 'Pacific/Honolulu',
	},
];

export const OrderRequestFinalState = {
	rejected: 1,
	declined: 1,
	no_test_available: 1,
	rejected_other: 1,
};

const orderStatusInitialState = [
	'patient_undecided',
	'patient_declined',
	'requested',
];
const patientUndecidedState = [
	'patient_undecided',
	'patient_declined',
	'requested',
];
const requestedState = [
	'requested',
	'patient_declined',
	'awaiting_external_approval',
	'approved',
	'rejected',
];
const awaitingExternalApprovalState = [
	'awaiting_external_approval',
	'approved',
	'rejected',
	'patient_declined',
];
const waitingToSubmitState = [
	'waiting_to_submit',
	'sent_to_lab',
	'cancelled',
	'patient_not_responsive',
];
const approvedState = [
	'waiting_to_submit',
	'sent_to_lab',
	'cancelled',
	'patient_not_responsive',
];
const sentToLabState = [
	'sent_to_lab',
	'patient_not_responsive',
	'sample_not_submitted',
	'cancelled',
	'results_ready',
];
const patientNonResponsiveState = [
	'patient_not_responsive',
	'cancelled',
	'waiting_to_submit',
	'sent_to_lab',
];
const patientNonResponsiveAfterTestDispatchState = [
	'patient_not_responsive',
	'sent_to_lab',
	'sample_not_submitted',
	'cancelled',
	'results_ready',
];
const sampleNotSubmittedState = [
	'sent_to_lab',
	'patient_not_responsive',
	'sample_not_submitted',
	'cancelled',
	'results_ready',
];
const resultsReadyState = ['results_ready', 'results_entered'];
const cancelledState = [
	'cancelled',
	'order_cancelled_by_provider',
	'order_cancelled_by_patient',
	'order_cancelled_by_lab',
	'order_cancelled_by_partner',
];
const patientDeclinedState = [
	'patient_declined',
	'declined_other',
	'ins_denied_cost',
	'iss_w_test_implications',
	'no_fu_from_patient',
	'declined_via_partner',
];
const patientRejectedState = [
	'rejected',
	'no_test_available',
	'rejected_other',
];
const resultsEnteredState = ['results_entered'];
const orderCancelledBeforeSentToLab = ['cancelled', 'approved'];
const orderCancelledBeforeSentToLabPatientNotResponsive = [
	'cancelled',
	'patient_not_responsive',
];
const orderCancelledAfterSentToLabPatientNotResponsive = [
	'cancelled',
	'patient_not_responsive',
];
const orderCancelledAfterSentToLabSampleNotSubmitted = [
	'cancelled',
	'sample_not_submitted',
];
const orderCancelledAfterSentToLab = ['cancelled', 'sent_to_lab'];
const orderCancelledBeforeSentToLabAwaitingInfo = [
	'cancelled',
	'waiting_to_submit',
];
const orderDeclinedWhileCreatingRequest = [
	'patient_undecided',
	'patient_declined',
	'requested',
];

const validnextstatus = {
	null: orderStatusInitialState,
	patient_undecided: patientUndecidedState,
	requested: requestedState,
	awaiting_external_approval: awaitingExternalApprovalState,
	waiting_to_submit: waitingToSubmitState,
	sent_to_lab: sentToLabState,
	patient_not_responsive: patientNonResponsiveState,
	sample_not_submitted: sampleNotSubmittedState,
	results_ready: resultsReadyState,
	approved: approvedState,
	cancelled: cancelledState,
	patient_declined: patientDeclinedState,
	rejected: patientRejectedState,
	results_entered: resultsEnteredState,
	patientNonResponsiveAfterTestDispatchState:
		patientNonResponsiveAfterTestDispatchState,
	orderCancelledAfterSentToLabPatientNotResponsive:
		orderCancelledAfterSentToLabPatientNotResponsive,
	orderCancelledAfterSentToLabSampleNotSubmitted:
		orderCancelledAfterSentToLabSampleNotSubmitted,
	orderCancelledAfterSentToLab: orderCancelledAfterSentToLab,
	orderCancelledBeforeSentToLab: orderCancelledBeforeSentToLab,
	orderCancelledBeforeSentToLabPatientNotResponsive:
		orderCancelledBeforeSentToLabPatientNotResponsive,
	orderCancelledBeforeSentToLabAwaitingInfo:
		orderCancelledBeforeSentToLabAwaitingInfo,
	orderDeclinedWhileCreatingRequest: orderDeclinedWhileCreatingRequest,
};

export const TestOrderStatus = {
	sent_to_lab: 1,
	resultreceived: 1,
	Result_entered: 1,
};
export const OrderRequestWaitingToSubmitStatuses = {
	awaiting_benefits_investigation: 1,
	awaiting_birth: 1,
	awaiting_external_approval: 1,
	awaiting_information_gc: 1,
	awaiting_information_patient: 1,
};
export const OrderRequestCancelledByStatus = {
	order_cancelled_by_provider: 1,
	order_cancelled_by_patient: 1,
	order_cancelled_by_lab: 1,
	order_cancelled_by_partner: 1,
};

export const filterOrderStatus = (
	cascadeenums,
	currentstate = null,
	date_test_ordered = null,
	status_reason = '',
	history = []
) => {
	let validstatus = [];
	let previousState = {};

	if (
		currentstate == 'patient_not_responsive' &&
		!_.isEmpty(date_test_ordered) &&
		!_.isNil(date_test_ordered)
	) {
		validstatus =
			validnextstatus['patientNonResponsiveAfterTestDispatchState'];
	} else {
		validstatus =
			validnextstatus[_.isNil(currentstate) ? 'null' : currentstate];
	}

	if (currentstate in { cancelled: 1 }) {
		const sortedHistory = _.orderBy(
			history,
			(o) => {
				return new moment(get(o, 'created_at', null));
			},
			['desc']
		);
		previousState = _.nth(sortedHistory, 1);
		const previousPreviousState = _.nth(sortedHistory, 2);

		if (
			previousState.order_status == 'approved' &&
			previousPreviousState.order_status == 'waiting_to_submit' &&
			previousPreviousState.status_reason == 'awaiting_cc_submission'
		) {
			previousState = previousPreviousState;
		}

		if (!_.isEmpty(date_test_ordered) && !_.isNil(date_test_ordered)) {
			if (previousState.order_status == 'patient_not_responsive') {
				validstatus =
					validnextstatus[
						'orderCancelledAfterSentToLabPatientNotResponsive'
					];
			} else if (previousState.order_status == 'sample_not_submitted') {
				validstatus =
					validnextstatus[
						'orderCancelledAfterSentToLabSampleNotSubmitted'
					];
			} else if (previousState.order_status == 'sent_to_lab') {
				validstatus = validnextstatus['orderCancelledAfterSentToLab'];
			}
		} else {
			if (previousState.order_status == 'patient_not_responsive') {
				validstatus =
					validnextstatus[
						'orderCancelledBeforeSentToLabPatientNotResponsive'
					];
			} else if (previousState.order_status == 'waiting_to_submit') {
				validstatus =
					validnextstatus[
						'orderCancelledBeforeSentToLabAwaitingInfo'
					];
			}
		}
	}

	if (currentstate in { patient_declined: 1 }) {
		validstatus = validnextstatus['orderDeclinedWhileCreatingRequest'];
	}

	let nextstatus = _.filter(cascadeenums, (el) => {
		return (
			el &&
			el.key &&
			_.isArray(validstatus) &&
			validstatus.includes(el.key)
		);
	});

	if (currentstate in { cancelled: 1 }) {
		nextstatus = _.map(nextstatus, (el) => {
			const temp = el;
			if (el.key == 'cancelled' && el.children) {
				temp['children'] = _.filter(el.children, (sr) => {
					return sr.key == status_reason;
				});
			}
			if (el.key == previousState.order_status && el.children) {
				temp['children'] = _.filter(el.children, (sr) => {
					return sr.key == previousState.status_reason;
				});
			}
			return temp;
		});
	}

	if (currentstate in { patient_declined: 1 }) {
		nextstatus = _.map(nextstatus, (el) => {
			const temp = el;
			if (el.key == 'patient_declined' && el.children) {
				temp['children'] = _.filter(el.children, (sr) => {
					return sr.key == status_reason;
				});
			}
			return temp;
		});
	}

	return nextstatus;
};

/* start: Pre authorization  */
const DispatchStatusState = [
	'pa_form_sent',
	'waiting_to_submit',
	'declined_dispatch_na',
];

const validDispatchStatusNext = {
	awaiting_cc_submission: DispatchStatusState,
	awaiting_information_patient: DispatchStatusState,
	declined_dispatch_na: DispatchStatusState,
	pa_form_sent: DispatchStatusState,
};

export const filterDispatchStatus = (cascadeenums, currentstate = null) => {
	const validstatus =
		validDispatchStatusNext[_.isNil(currentstate) ? 'null' : currentstate];
	const nextstatus = _.filter(cascadeenums, (el) => {
		return (
			el &&
			el.key &&
			_.isArray(validstatus) &&
			validstatus.includes(el.key)
		);
	});
	return nextstatus;
};
/* End: Pre authorization  */

export const filterTestOrderStatus = (cascadeenums) => {
	const validstatus = [];
	const nextstatus = _.filter(cascadeenums, (el) => {
		return (
			el &&
			el.key &&
			_.isArray(validstatus) &&
			validstatus.includes(el.key)
		);
	});
	if (_.isArray(nextstatus)) {
		const returnstatuses = OrderStatusHierarchyEnums(nextstatus, 'key');
		return returnstatuses;
	} else return [];
};

export const isSCPEncounterWithSubType = (encounter) => {
	return (
		get(encounter, 'type', '') == 'scp' &&
		get(encounter, 'encounter_subtype', false)
	);
};

export const getConsultationSubType = (obj) => {
	return obj.type &&
		obj.consultation_type &&
		(obj.type == 'visit' || obj.type == 'scp')
		? ' - ' + obj.consultation_type
		: '';
};

export const TestRequestStatus = {
	requested: 1,
	patient_undecided: 1,
	patient_declined: 1,
	declined_other: 1,
	ins_denied_cost: 1,
	iss_w_test_implications: 1,
	no_fu_from_patient: 1,
	rejected: 1,
	no_test_available: 1,
	rejected_other: 1,
	awaiting_external_approval: 1,
};

export const TESTORDER = 'TESTORDER';
export const TESTREQUEST = 'TESTREQUEST';

export const currentForm = (testorder) => {
	const order_status = get(testorder, ['localorderstatus'], null);
	if (!_.isEmpty(order_status) && order_status in TestRequestStatus) {
		return TESTREQUEST;
	} else {
		return TESTORDER;
	}
};

export const testOrderProgressBar = {
	requested: {
		className: 'done',
		date: 'created_at',
		status_reason: false,
		status_note: null,
	},
	patient_declined: {
		className: 'stop',
		date: 'created_at',
		status_reason: true,
		status_note: null,
	},
	awaiting_external_approval: {
		className: 'inprogress',
		line: 'yellow',
		date: null,
		status_reason: false,
		status_note: null,
	},
	approved: {
		className: 'done',
		date: 'date_test_authorized',
		status_reason: false,
		status_note: null,
	},
	rejected: {
		className: 'stop',
		line: 'red',
		date: 'date_test_authorized',
		status_reason: false,
		status_note: 'order_status_note',
	},
	cancelled: {
		className: 'stop',
		date: 'created_at',
		status_reason: true,
		status_note: null,
	},
	sent_to_lab: {
		className: 'done',
		date: 'date_test_ordered',
		status_reason: false,
		status_note: null,
	},
	results_ready: {
		className: 'done',
		date: 'date_received_report',
		status_reason: false,
		status_note: null,
	},
	results_entered: {
		className: 'done',
		date: 'created_at',
		status_reason: true,
		status_note: null,
	},
	patient_undecided: {
		className: 'inprogress',
		date: null,
		status_reason: false,
		status_note: null,
	},
	waiting_to_submit: {
		className: 'inprogress',
		date: null,
		status_reason: true,
		status_note: null,
	},
	patient_not_responsive: {
		className: 'inprogress',
		date: null,
		status_reason: false,
		status_note: null,
	},

	sample_not_submitted: {
		className: 'inprogress',
		date: null,
		status_reason: false,
		status_note: null,
	},
};

export const MLOWaitingStates = {
	sample_not_submitted: 1,
	patient_not_responsive: 1,
	waiting_to_submit: 1,
	patient_undecided: 1,
	awaiting_external_approval: 1,
};

export const MLOSuccessStates = {
	requested: 1,
	approved: 1,
	sent_to_lab: 1,
	results_ready: 1,
	results_entered: 1,
};

export const MLOEndStates = {
	patient_declined: 1,
	rejected: 1,
	cancelled: 1,
};

export const scrubber = [
	{
		index: 1,
		success: {
			requested: () => {
				return true;
			},
		},
		waiting: {
			patient_undecided: () => true,
		},
		stop: {
			patient_declined: () => {
				return true;
			},
		},
	},
	{
		index: 2,
		success: {
			approved: () => true,
		},
		waiting: {
			awaiting_external_approval: () => {
				return true;
			},
		},
		stop: {
			patient_declined: (testorder) => {
				const history = get(testorder, 'history', []);
				const hasRequestedElement = _.find(history, (e) => {
					return e.order_status == 'requested';
				});
				return !_.isNil(hasRequestedElement);
			},
			rejected: () => {
				return true;
			},
		},
	},
	{
		index: 3,
		success: {
			sent_to_lab: () => true,
		},
		waiting: {
			waiting_to_submit: () => {
				return true;
			},
			patient_not_responsive: () => {
				return true;
			},
		},
		stop: {
			cancelled: () => {
				return true;
			},
		},
	},
	{
		index: 4,
		success: {
			results_ready: () => true,
		},
		waiting: {
			patient_not_responsive: (testorder) => {
				return !(
					_.isEmpty(testorder.date_test_ordered) ||
					_.isNil(testorder.date_test_ordered)
				);
			},
			sample_not_submitted: () => true,
		},
		stop: {
			cancelled: (testorder) => {
				return !(
					_.isEmpty(testorder.date_test_ordered) ||
					_.isNil(testorder.date_test_ordered)
				);
			},
		},
	},
	{
		index: 5,
		success: {
			results_entered: () => true,
		},
		waiting: {},
		stop: {},
	},
];

export const LabOrderStatusClassMapping = {
	prepare_to_send: 'inprogress',
	send_to_lab_completed: 'inprogress',
	send_to_lab_failed: 'stop',
	lab_order_order_placed: 'inprogress',
	lab_order_kit_shipped: 'inprogress',
	lab_order_kit_received: 'inprogress',
	lab_order_result_ready: 'inprogress',
	lab_order_cancelled: 'stop',
	lab_order_processing_sample: 'inprogress',
	lab_order_quantity_not_sufficient: 'stop',
	lab_order_order_received: 'inprogress',
	queued: 'inprogress',
	lab_order_in_progress: 'inprogress',
	lab_order_order_failed: 'stop',
	cancel_failed: 'stop',
	check_status_failed: 'stop',
};

export const getTestOrderScruber = (
	TestOrder,
	mloOrderStatusEnums,
	customScrubber = false
) => {
	const success = _.keys(MLOSuccessStates);
	let order_status = get(TestOrder, 'order_status', '');
	let status_reason = get(TestOrder, 'status_reason', '');
	if (order_status == 'approved') {
		order_status = 'waiting_to_submit';
		status_reason = 'awaiting_cc_submission';
	}

	const getClassName = (order_status, status_reason, className) => {
		if (
			order_status == 'results_entered' &&
			status_reason == 'result_positive_no_genes'
		) {
			return 'inprogress';
		}
		return className;
	};

	const getDisplayName = (
		mloOrderStatusEnums,
		status,
		reason,
		showreason
	) => {
		let displayname = '';
		const enumObj = mloOrderStatusEnums.find((a) => a.key == status);

		if (!(status in { waiting_to_submit: 1, results_entered: 1 })) {
			displayname += get(enumObj, 'display_name', '');
		}

		/* when circle is gray and stauts = results entered */
		if (status == 'results_entered' && showreason != true) {
			displayname += get(enumObj, 'display_name', '');
		}

		if (
			!_.isNil(reason) &&
			!_.isNil(get(enumObj, 'children', null)) &&
			showreason == true
		) {
			const statusReasonObj = get(enumObj, 'children', []).find(
				(a) => a.key == reason
			);
			if (!(status in { waiting_to_submit: 1, results_entered: 1 })) {
				displayname += ' - ';
			}
			displayname += get(statusReasonObj, 'display_name', '');
		}
		return displayname;
	};

	const getDisplayDate = (progressbarPathObj, fieldName, orderStatus) => {
		let result = '';
		if (fieldName == null) {
			result = '';
		} else if (fieldName == 'created_at') {
			const historyobj = _.find(
				get(progressbarPathObj, 'history', []),
				(a) => a.order_status == orderStatus
			);
			const date = historyobj && historyobj.created_at;
			if (!_.isNil(date)) {
				result = moment.utc(date).local().format('MMM DD YYYY');
			}
		} else if (
			!_.isNil(fieldName) &&
			!_.isEmpty(get(progressbarPathObj, fieldName)) &&
			!_.isNil(get(progressbarPathObj, fieldName))
		)
			result = moment(get(progressbarPathObj, fieldName)).format(
				'MMM DD YYYY'
			);

		return result;
	};

	const getOrderStatusNote = (progressbarPathObj, fieldName, orderStatus) => {
		let result = '';

		if (fieldName == null) {
			result = '';
		} else if (fieldName == 'order_status_note') {
			const historyobj = _.find(
				get(progressbarPathObj, 'history', []),
				(a) => a.order_status == orderStatus
			);
			const order_status_note =
				historyobj && historyobj.order_status_note;
			if (!_.isNil(order_status_note)) {
				result = order_status_note;
			}
		}

		return result;
	};

	const findScubberObject = (status_key) => {
		return _.find(_.reverse([...scrubber]), (obj) => {
			if (_.isFunction(obj[status_key][order_status])) {
				return obj[status_key][order_status](TestOrder);
			}
		});
	};

	const scrubberDispayObjectArray = (
		successstatus,
		greystatus,
		labStatus = []
	) => {
		const successarray = _.map([...successstatus], (key) => {
			const config = testOrderProgressBar[key];
			let displayName = getDisplayName(
				mloOrderStatusEnums,
				key,
				status_reason,
				config.status_reason
			);
			if (key == 'results_entered') {
				displayName += ` ${
					get(TestOrder, 'is_actionable', false) == true
						? '(Actionable)'
						: '(Non-Actionable)'
				}`;
			}

			return {
				displayName: displayName,
				displayDate: getDisplayDate(TestOrder, config.date, key),
				className: getClassName(key, status_reason, config.className),
				orderStatusNote: getOrderStatusNote(
					TestOrder,
					config.status_note,
					key
				),
			};
		});

		const labarray = _.map(labStatus, () => {
			const lab_order_status_history = get(
				TestOrder,
				'lab_order_status_history'
			);
			const selectedLabObj = _.find(
				lab_order_status_history,
				(history) => {
					return (
						history.key_name ==
						get(TestOrder, 'lab_status_history_key', '')
					);
				}
			);
			const createdDate = get(selectedLabObj, 'created_at', null);

			return {
				displayName: get(selectedLabObj, 'display_name', ''),
				displayDate: !_.isNil(createdDate)
					? moment
							.tz(createdDate, 'UTC')
							.local()
							.format('MMM DD YYYY')
					: '',
				className:
					LabOrderStatusClassMapping[
						get(selectedLabObj, 'key_name', '')
					],
			};
		});
		const greyarray = _.map(greystatus, (key) => {
			return {
				displayName: getDisplayName(
					mloOrderStatusEnums,
					key,
					status_reason
				),
				displayDate: '',
				className: '',
			};
		});
		return [...successarray, ...labarray, ...greyarray];
	};

	if (order_status in MLOSuccessStates) {
		const element = findScubberObject('success');
		const current_status_index =
			element && element.index ? element.index : 0;
		const successstatus = _.take(success, current_status_index);
		let labStatus = [];
		if (
			order_status == 'sent_to_lab' &&
			!_.isNil(get(TestOrder, 'lab_status_history_key', ''))
		) {
			labStatus = [get(TestOrder, 'lab_status_history_key', '')];
		}
		const labStatusIndex = labStatus.length;
		const greystatus = _.takeRight(
			success,
			5 - current_status_index - labStatusIndex
		);

		if (customScrubber) {
			return scrubberDispayObjectArray(
				['results_ready', 'results_entered'],
				[],
				labStatus
			);
		} else {
			return scrubberDispayObjectArray(
				successstatus,
				greystatus,
				labStatus
			);
		}
	} else if (order_status in MLOWaitingStates) {
		const element = findScubberObject('waiting');
		const current_status_index =
			element && element.index ? element.index : 0;
		const successstatus = _.take(success, current_status_index - 1);
		const greystatus = _.takeRight(success, 5 - current_status_index);
		return scrubberDispayObjectArray(
			[...successstatus, order_status],
			greystatus
		);
	} else if (order_status in MLOEndStates) {
		const element = findScubberObject('stop');
		const current_status_index =
			element && element.index ? element.index : 0;
		const successstatus = _.take(success, current_status_index - 1);
		const greystatus = _.takeRight(success, 5 - current_status_index);

		if (
			!_.isNil(get(TestOrder, 'lab_status_history_key', '')) &&
			get(TestOrder, 'lab_status_history_key', '') == 'cancel_failed'
		) {
			let labStatus = [];
			if (order_status == 'cancelled') {
				labStatus = [get(TestOrder, 'lab_status_history_key', '')];
			}
			return scrubberDispayObjectArray(
				[...successstatus],
				greystatus,
				labStatus
			);
		}
		return scrubberDispayObjectArray(
			[...successstatus, order_status],
			greystatus
		);
	}
	return scrubberDispayObjectArray([], success);
};

export function getSampleTypeGrouping(sampleTypes) {
	const nonOtherEnums = ['blood', 'saliva', 'buccal_swab'];
	const sortedData = _.orderBy(sampleTypes, 'display_name', 'asc');
	const others = [];
	const nonOthers = [];
	sortedData.forEach((data) => {
		if (!nonOtherEnums.includes(data.value)) {
			others.push(data);
		} else {
			nonOthers.push(data);
		}
	});
	return { others: others, 'non-others': nonOthers };
}

const filterTestOrders = (testOrderList, testInfo) => {
	const { holdResultRelease, outreachSeqNo, testorderID, orderStatus } =
		testInfo;
	const orderStatusArr =
		holdResultRelease == true
			? [
					'patient_undecided',
					'requested',
					'approved',
					'awaiting_external_approval',
					'waiting_to_submit',
					'sent_to_lab',
					'results_ready',
					'results_entered',
					'result_positive_no_genes',
					'result_positive_with_genes',
					'result_negative',
			  ]
			: [
					'results_ready',
					'results_entered',
					'result_positive_no_genes',
					'result_positive_with_genes',
					'result_negative',
			  ];

	let filterTestOrders = _.map(testOrderList, (obj) => {
		const _obj = { ...obj };
		if (_obj.uuid == testorderID) {
			_obj['order_status'] = orderStatus;
		}
		return _obj;
	});

	const latestOutreachSeqNo = () => {
		const latestTestOrder = _.head(
			_.orderBy(filterTestOrders, (obj) => obj.outreach_seq_no, 'desc')
		);

		if (
			latestTestOrder &&
			latestTestOrder.test_outreach_status in
				{ not_started: 1, waiting_to_start: 1, in_progress: 1 }
		) {
			return latestTestOrder.outreach_seq_no;
		}
		return 0;
	};

	filterTestOrders = _.filter(filterTestOrders, (obj) => {
		return (
			obj.outreach_seq_no == 0 ||
			obj.outreach_seq_no == null ||
			obj.outreach_seq_no == outreachSeqNo ||
			obj.outreach_seq_no == latestOutreachSeqNo()
		);
	});

	filterTestOrders = _.filter(filterTestOrders, (obj) => {
		return _.includes(orderStatusArr, obj.order_status);
	});

	return filterTestOrders;
};

export const OUTREACH_ON_HOLD_WARN_MSG = 'OUTREACH_ON_HOLD_WARN_MSG';
export const OUTREACH_WAITING_TO_START_WARN_MSG =
	'OUTREACH_WAITING_TO_START_WARN_MSG';
export const OUTREACH_INPROGRESS_WARN_MSG = 'OUTREACH_INPROGRESS_WARN_MSG';

function outreachWarnMsg(filterTestOrder, testInfo) {
	let result = '';
	const { holdResultRelease } = testInfo;

	const resultEnteredTest = _.find(filterTestOrder, (obj) => {
		return (
			obj.order_status in
			{
				result_negative: 1,
				result_positive_no_genes: 1,
				result_positive_with_genes: 1,
				results_entered: 1,
				results_ready: 1,
			}
		);
	});

	const notResultEnteredTest = _.find(filterTestOrder, (obj) => {
		return !(
			obj.order_status in
			{
				result_negative: 1,
				result_positive_no_genes: 1,
				result_positive_with_genes: 1,
				results_entered: 1,
				results_ready: 1,
			}
		);
	});

	const findOutreachStatus = _.find(filterTestOrder, (obj) => {
		return obj.test_outreach_status == 'in_progress';
	});
	if (!_.isEmpty(filterTestOrder)) {
		if (findOutreachStatus) {
			result = OUTREACH_INPROGRESS_WARN_MSG;
		} else if (
			(holdResultRelease && !notResultEnteredTest) ||
			!holdResultRelease
		) {
			result = OUTREACH_WAITING_TO_START_WARN_MSG;
		} else if (
			holdResultRelease &&
			resultEnteredTest &&
			notResultEnteredTest
		) {
			result = OUTREACH_ON_HOLD_WARN_MSG;
		}
	}
	return result;
}

const getTestNames = (testList, currentTest) => {
	const statuses = {
		result_negative: 1,
		result_positive_no_genes: 1,
		result_positive_with_genes: 1,
		results_entered: 1,
	};
	const filterTestList = _.filter(testList, (test) => {
		return (
			test.uuid != currentTest.testorderID &&
			test.order_status in statuses
		);
	});
	return _.map(filterTestList, (test) => {
		return test.genetic_test_display_name;
	});
};

export const OutreachWarnMsg = (testOrderList, currentTest) => {
	const filterTestOrder = filterTestOrders(testOrderList, currentTest);
	const warnMsg = outreachWarnMsg(filterTestOrder, currentTest);
	const testNames = getTestNames(filterTestOrder, currentTest);

	return { warnMsg, testNames };
};

export const disabledTestRecommendedSelection = (visitStatus) => {
	const visitStatusEnum = {
		cancelled_rescheduled_by_provider: 1,
		cancelled_rescheduled_by_patient: 1,
		cancelled_by_care_coordinator: 1,
		cancelled_by_patient: 1,
		cancelled_by_provider: 1,
		cancelled: 1,
		no_show: 1,
	};

	if (
		!_.isNil(visitStatus) &&
		!_.isEmpty(visitStatus) &&
		visitStatus in visitStatusEnum
	) {
		return true;
	}
	return false;
};

export const getSCPEncounterSubType = (obj) => {
	const hasSubType =
		obj.type &&
		obj.encounter_subtype &&
		obj.type == 'scp' &&
		get(scp_encounter_sub_types, obj.encounter_subtype, false);
	if (hasSubType) {
		if (
			get(scp_encounter_sub_types_pre_appt, obj.encounter_subtype, false)
		) {
			return ' - Pre-Appointment';
		} else if (
			get(
				scp_encounter_sub_types_partner_initiated_pre_test,
				obj.encounter_subtype,
				false
			)
		) {
			return 'TRO';
		} else if (
			get(
				scp_encounter_sub_types_partner_initiated_unsolicited_result,
				obj.encounter_subtype,
				false
			)
		) {
			return 'TRO - Results Only';
		} else {
			return ' - ' + get(scp_encounter_sub_types, obj.encounter_subtype);
		}
	} else {
		return '';
	}
};
export const displayEncounterTypeWithSubtype = (encounter) => {
	const type = encounter_types[get(encounter, 'type', '')];
	const hasPartnerInitiatedPreTest = isPartnerInitiatedPreTest(encounter);
	if (
		hasPartnerInitiatedPreTest ||
		_.get(
			scp_encounter_sub_types_partner_initiated_unsolicited_result,
			_.get(encounter, ['encounter_subtype'], ''),
			false
		)
	) {
		return getSCPEncounterSubType(encounter);
	}
	return type + getSCPEncounterSubType(encounter);
};

export const isPartnerInitiatedPreTest = (encounter) => {
	return (
		isSCPEncounterWithSubType(encounter) &&
		get(
			scp_encounter_sub_types_partner_initiated_pre_test,
			get(encounter, 'encounter_subtype', ''),
			false
		)
	);
};

export const isPartnerInitiatedUnsolicitedResult = (encounter) => {
	return (
		isSCPEncounterWithSubType(encounter) &&
		get(
			scp_encounter_sub_types_partner_initiated_unsolicited_result,
			get(encounter, 'encounter_subtype', ''),
			false
		)
	);
};

export const visitIndicationsFilterEnum = [
	{
		internal: 'Cancer',
		external: 'Cancer',
	},
	{
		internal: 'Cardiology',
		external: 'Cardiology',
	},
	{
		internal: 'Reproductive',
		external: 'Reproductive',
	},
	{
		internal: 'Rare Disease',
		external: 'Rare Disease',
	},
];

export const visitIndicationsCheckboxEnum = [
	{
		internal: 'personal_hx',
		external: 'Personal Hx',
	},
	{
		internal: 'family_hx',
		external: 'Family Hx',
	},
];

export const getVisitIndicationTagDisplay = (indication) => {
	if (indication) {
		const displayName = get(indication, 'display_name', null);
		const personalHX = get(indication, 'personal_hx', null);
		const familyHX = get(indication, 'family_hx', null);
		const freeText = get(indication, 'other_indications', null);
		let str = '';
		str += displayName || '--';
		if (freeText) str += ` (${freeText})`;
		if (personalHX) str += `, Personal Hx`;
		if (familyHX) str += `, Family Hx`;
		return str;
	} else return '--';
};

export const addVisitIndicationDisplayNames = (
	selectedIndications,
	indicationsEnum
) => {
	if (Array.isArray(selectedIndications) && Array.isArray(indicationsEnum)) {
		const indications = _.cloneDeep(selectedIndications);
		return indications.map((indication) => {
			const matchingEnumVal = _.find(
				indicationsEnum,
				(el) => get(el, 'uuid', '') === get(indication, 'uuid', null)
			);
			if (matchingEnumVal && !indication.display_name)
				indication.display_name = matchingEnumVal.display_name;
			return indication;
		});
	} else return selectedIndications;
};

const indicationsRequirementGoLiveDate = '2022-01-13 00:00:00';

export const determineIsAfterIndicationsGoLive = (encounterDateOfService) => {
	if (moment(encounterDateOfService).isValid()) {
		const serviceDate = moment.utc(encounterDateOfService);
		const goLiveDate = moment.utc(indicationsRequirementGoLiveDate);
		return serviceDate.isSameOrAfter(goLiveDate);
	} else return false;
};

export const getOrderStatusDisplayName = (
	orderStatus,
	statusReason,
	orderStatusEnums,
	shortPatient = true
) => {
	const order_status =
		orderStatus == 'approved' ? 'waiting_to_submit' : orderStatus;
	const status_reason =
		orderStatus == 'approved' ? 'awaiting_cc_submission' : statusReason;
	const obj =
		orderStatusEnums &&
		!_.isEmpty(orderStatusEnums) &&
		orderStatusEnums.find((a) => a.key == order_status);
	let status = get(
		obj,
		'display_name',
		RemoveUnderscoreAndCapitalizeInString(orderStatus)
	);
	if (
		orderStatus in
		{ waiting_to_submit: 1, cancelled: 1, results_entered: 1 }
	) {
		const reasonobj = get(obj, 'status_reason', []).find((a) => {
			return a.key == status_reason;
		});
		status = get(
			reasonobj,
			'display_name',
			RemoveUnderscoreAndCapitalizeInString(orderStatus)
		);
	}
	return status && shortPatient ? status.replace('Patient', 'Pt.') : status;
};

export const getOrderStatusDisplayNameForAll = (
	orderStatus,
	statusReason,
	orderStatusEnums,
	shortPatient = true
) => {
	const order_status =
		orderStatus == 'approved' ? 'waiting_to_submit' : orderStatus;
	const status_reason =
		orderStatus == 'approved' ? 'awaiting_cc_submission' : statusReason;
	const obj =
		orderStatusEnums &&
		!_.isEmpty(orderStatusEnums) &&
		orderStatusEnums.find((a) => a.key == order_status);
	let status = get(
		obj,
		'display_name',
		RemoveUnderscoreAndCapitalizeInString(orderStatus)
	);
	if (!_.isNil(statusReason)) {
		const reasonobj = get(obj, 'status_reason', []).find((a) => {
			return a && a.key == status_reason;
		});
		status = get(
			reasonobj,
			'display_name',
			RemoveUnderscoreAndCapitalizeInString(orderStatus)
		);
	}
	return status && shortPatient ? status.replace('Patient', 'Pt.') : status;
};

export const payorName = (patientdetail) => {
	let primaryInsurance = {};
	if (patientdetail?.insurances?.length > 0) {
		primaryInsurance = get(
			patientdetail.insurances.filter(
				(el) => el.insurance_type === 'primary'
			),
			'[0]',
			{}
		);
	}
	const hasInsurance =
		!_.isNil(get(primaryInsurance, ['insurance_company', 'name'], '')) &&
		!_.isEmpty(get(primaryInsurance, ['insurance_company', 'name'], ''));
	const hasOtherInsurance =
		get(primaryInsurance, ['insurance_company', 'name'], '') == 'Other'
			? true
			: false;
	let insuranceName = '';

	if (hasOtherInsurance) {
		insuranceName =
			'Other - ' +
			get(primaryInsurance, ['insurance', 'company_name'], '');
	} else {
		insuranceName = get(
			primaryInsurance,
			['insurance_company', 'name'],
			''
		);
	}

	if (hasInsurance) return insuranceName;
	else return 'Insurance Unavailable';
};

export const payorSelectOption = (
	patientdetail,
	editFlag,
	partnerEnum = []
) => {
	const patientPartnerUuid = get(patientdetail, 'partner_uuid', null);
	let matchingPartner = null;
	let matchingPartnerInsuranceEnabled = false;
	let matchingPartnerInsuranceOnly = false;
	if (Array.isArray(partnerEnum)) {
		matchingPartner = _.find(
			partnerEnum,
			(partner) => get(partner, 'uuid', '') === patientPartnerUuid
		);
		if (matchingPartner) {
			matchingPartnerInsuranceEnabled = get(
				matchingPartner,
				'insurance_enabled',
				false
			);
			matchingPartnerInsuranceOnly = get(
				matchingPartner,
				'insurance_only',
				false
			);
		}
	}
	let primaryInsurance = {};
	if (patientdetail?.insurances?.length > 0) {
		primaryInsurance = get(
			patientdetail.insurances.filter(
				(el) => el.insurance_type === 'primary'
			),
			'[0]',
			{}
		);
	}
	const hasInsurance =
		!_.isNil(get(primaryInsurance, ['insurance_company', 'name'], '')) &&
		!_.isEmpty(get(primaryInsurance, ['insurance_company', 'name'], ''));
	const hasOtherInsurance =
		get(primaryInsurance, ['insurance_company', 'name'], '') == 'Other'
			? true
			: false;
	let insuranceName = '';
	const payorSelectOption = [];

	if (!matchingPartnerInsuranceOnly) {
		payorSelectOption.push({
			key: 'self_pay',
			label: 'Non-insurance',
			value: 'self_pay',
		});
	} else {
		payorSelectOption.push({
			key: 'self_pay',
			label: 'Non-insurance Unavailable',
			value: 'self_pay',
			tooltip: "This patient's referral program is insurance-only",
			isDisable: true,
		});
	}

	if (hasOtherInsurance) {
		insuranceName =
			'Other - ' +
			get(primaryInsurance, ['insurance', 'company_name'], '');
	} else {
		insuranceName = get(
			primaryInsurance,
			['insurance_company', 'name'],
			''
		);
	}
	if (hasInsurance && matchingPartnerInsuranceEnabled) {
		const keyValue = editFlag ? 'insurance' : insuranceName;
		payorSelectOption.push({
			key: keyValue,
			label: insuranceName,
			value: keyValue,
		});
	} else {
		payorSelectOption.push({
			key: 'insurance_unavailable',
			label: 'Insurance Unavailable',
			value: 'Insurance Unavailable',
			tooltip:
				matchingPartner && !matchingPartnerInsuranceEnabled
					? "This patient's referral program is not insurance-enabled"
					: 'To select insurance as payor, please add it under patient payment first',
			isDisable: true,
		});
	}

	return payorSelectOption;
};

export const determineDropdownQuestionnaire = (
	selectedTestUUID = '',
	testEnum
) => {
	if (selectedTestUUID && Array.isArray(testEnum)) {
		const matchingTest = _.find(testEnum, (test) => {
			return get(test, 'key', null) === selectedTestUUID;
		});
		const matchingTestType = get(
			matchingTest,
			'order_questionnaire.type',
			null
		);
		const matchingTestOptions = get(
			matchingTest,
			'order_questionnaire.options',
			null
		);
		if (
			matchingTest &&
			matchingTestType === 'dropdown' &&
			Array.isArray(matchingTestOptions)
		) {
			return matchingTestOptions;
		} else return false;
	} else return false;
};

export const determineShippingAddressRequired = (
	selectedTestUUID = '',
	testEnum,
	stateorderstatus
) => {
	const statusEnum = {
		requested: 1,
		patient_undecided: 1,
		awaiting_external_approval: 1,
		declined_other: 1,
		ins_denied_cost: 1,
		iss_w_test_implications: 1,
		no_fu_from_patient: 1,
		'': 1,
	};

	if (selectedTestUUID && Array.isArray(testEnum)) {
		const matchingTest = _.find(testEnum, (test) => {
			return get(test, 'key', null) === selectedTestUUID;
		});
		const requiredAddress = get(matchingTest, ['required_address'], []);

		if (matchingTest && Array.isArray(requiredAddress)) {
			return (
				_.includes(requiredAddress, 'shipping') &&
				stateorderstatus in statusEnum
			);
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const buildQuestionnairePayload = (
	questionnaireSelected,
	questionnaireEnum
) => {
	if (Array.isArray(questionnaireSelected && questionnaireEnum)) {
		const payload = {};
		questionnaireEnum.forEach((question) => {
			const text = get(question, 'option', null);
			if (questionnaireSelected.includes(text)) {
				payload[text] = ['yes'];
			} else {
				payload[text] = ['no'];
			}
		});
		return payload;
	} else return null;
};

export const changeQuestionnaireToMultiselect = (questionnaire) => {
	if (
		Array.isArray(questionnaire) &&
		_.isObject(get(questionnaire, '[0]', null))
	) {
		const questionnaireBody = questionnaire[0];
		const multiselect = [];
		for (const key in questionnaireBody) {
			const answer = questionnaireBody[key];
			if (Array.isArray(answer) && answer[0] === 'yes')
				multiselect.push(key);
		}
		return multiselect;
	} else return questionnaire;
};

export const questionnaireNoneValue = 'None of the above';
