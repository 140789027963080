//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import checkIcon from './../../../assets/green-check.svg';
import xIcon from './../../../assets/red-x.svg';
import noOutreachIcon from './../../../assets/no-call.svg';
import arrowIcon from './../../../assets/nesting.svg';

//Lodash
import get from 'lodash/get';

class OutcomeRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.renderIcon = this.renderIcon.bind(this);
		this.handleStageChange = this.handleStageChange.bind(this);
	}
	//functions

	renderIcon() {
		const icon = get(this, 'props.icon', '');
		switch (icon) {
			case 'check':
				return (
					<ReactSVG
						src={checkIcon}
						style={{
							marginRight: '11px',
							position: 'relative',
							top: '7px',
						}}
					/>
				);
			case 'x':
				return (
					<ReactSVG
						src={xIcon}
						style={{
							marginRight: '11px',
							position: 'relative',
							top: '7px',
							right: '1px',
						}}
					/>
				);
			case 'noOutreach':
				return (
					<ReactSVG
						src={noOutreachIcon}
						style={{
							marginRight: '11px',
							position: 'relative',
							top: '6px',
							right: '1px',
						}}
					/>
				);
			default:
				console.log('Unrecognized icon.');
		}
	}

	handleStageChange() {
		const stage = get(this, 'props.stage', '');
		if (stage) {
			this.props.setStage(stage);
		}
	}

	render() {
		return (
			<div
				className='patientCreateOutreachModalOutcomeRow'
				onClick={this.handleStageChange}
			>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					{this.renderIcon()}
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div
							style={{
								fontSize: '14px',
								fontFamily: 'RionaSans-Bold',
							}}
						>
							{get(this, 'props.title', '')}
						</div>
						<div style={{ fontSize: '12px', color: '#7b8291' }}>
							{get(this, 'props.subtitle', '')}
						</div>
					</div>
				</div>
				<ReactSVG src={arrowIcon} />
			</div>
		);
	}
}

export default OutcomeRow;
