import * as actionTypes from '../actions/actionTypes';

const defaultDrugExposureState = {
	drugExposures: [],
	drugExposureLoading: false,
	drugExposureError: null,
};

export const drugExposures = (
	state = defaultDrugExposureState,
	action = {}
) => {
	switch (action.type) {
		case actionTypes.PATIENT_DRUG_EXPOSURES_LOADING:
			return {
				...state,
				drugExposureLoading: true,
				drugExposureError: null,
			};
		case actionTypes.PATIENT_DRUG_EXPOSURES_SUCCESS:
			return {
				...state,
				drugExposures: action.payload,
				drugExposureLoading: false,
				drugExposureError: null,
			};
		case actionTypes.PATIENT_DRUG_EXPOSURES_ERROR:
			return {
				...state,
				drugExposures: [],
				drugExposureLoading: false,
				drugExposureError: action.payload,
			};

		case actionTypes.ADD_PATIENT_DRUG_EXPOSURE_NOTE_LOADING:
			return {
				...state,
				addedDrugExposureNote: null,
				addDrugExposureNoteLoading: true,
				addDrugExposureNoteError: null,
			};
		case actionTypes.ADD_PATIENT_DRUG_EXPOSURE_NOTE_SUCCESS:
			return {
				...state,
				addedDrugExposureNote: action.payload,
				addDrugExposureNoteLoading: false,
				addDrugExposureNoteError: null,
			};
		case actionTypes.ADD_PATIENT_DRUG_EXPOSURE_NOTE_ERROR:
			return {
				...state,
				addedDrugExposureNote: null,
				addDrugExposureNoteLoading: false,
				addDrugExposureNoteError: action.payload,
			};
		default:
			return state;
	}
};
