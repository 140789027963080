//React & Redux
import React from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { stopSubmit, setSubmitFailed } from 'redux-form';

//Images
import phoneicon from '../../assets/quick-view-phone.svg';
import zoomIcon from '../../assets/zoom.png';
import alertIcon from '../../assets/alert.svg';

//Lodash
import moment from 'moment';
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import {
	GetStateTimezone,
	getShippingState,
	trimPayload,
	currentAddressKey,
	determineHasAllAddressTypes,
	getExistingAddressTypes,
	singleAddressIsBilling,
	mapAddressErrors,
} from '../../utils.js';
import { sleep, determineEmailStatus } from '../../Utils/appointmentUtils';
import { hydratePatientEditError } from '../../transformers/patients/patientTransformer';
import { imageDefault, providers_images } from '../../Utils/encounterUtils';
import { UTCToCurrentTimeView } from '../../utils';

//Actions and Services
import {
	getpatients,
	patientdetail,
	getConsenttotreat,
	getReferralPrograms,
	updateOutreach,
	updateAddress,
} from './../../actions/patients.js';
import {
	getencounters,
	getencountervisitsschema,
	resetencounters,
} from '../../actions/encounter';
import {
	LoadAppointmentSlots,
	UpdatePatientDetail,
	LoadAppointmentProvider,
	SETAPPOINTMENTPARAMS,
	TOGGLEAPPOINTMENTPATIENT,
	SetAppointmentPatient,
	LoadAppointmentPartnerDetailbyId,
	ApplyCouponCode,
	ResetCouponCodeDetails,
	ClearSelectedSlot,
	SetModality,
	ResetNewAppointment,
	FilterChangeEvent,
} from './../../actions/scheduling.js';
import { getDefaultSchedulingOptions } from './../../actions/referralprograms/referralprograms';
import {
	zeroFeeBookAppointment,
	nonZeroBookAppointment,
} from './../../actions/appointments';
import { getPlanTypes } from '../../actions/enums';

//UI Libraries
import { Loading } from 'gm-element-react';

//Components
import ScheduleAppointmentSidebar from './ScheduleAppointmentSidebar.js';
import SelectPatientStage from './SelectPatientStage.js';
import ScheduleStage from './ScheduleStage.js';
import PaymentStage from './PaymentStage.js';
import ScheduleWizardSteps from './ScheduleWizardSteps.js';
import AppointmentSnackbar from './AppointmentSnackbar.js';
import AppointmentErrorDialog from './AppointmentErrorDialog';
import AddressModal from './../Patients/V2/CreatePatient/AddressModal/AddressModal.js';
import RadioButtonPill from '../Common/controls/RadioButtonPill';
import ProviderAddAnother from '../ExternalProviders/ProviderAddAnother';
import AddressCard from '../Common/AddressCard.js';
import WarningMessageBox from '../Common/WarningMessageBox.js';
import Tooltip from '../Common/Tooltip';

const dash = <span className='pat-dash'>--</span>;

const renderSwitch = ({ label, input, meta, options, isrequired }) => {
	return (
		<div className='input-row'>
			<label
				className={`bold-label ${isrequired ? 'required' : ''}`}
				style={{ position: 'relative' }}
			>
				{isrequired && <span className='inputFieldRequired'>*</span>}
				{label}
			</label>
			<RadioButtonPill {...input} options={options} />
			{meta && meta.touched && meta.error && (
				<span className='error'>{meta.error}</span>
			)}
		</div>
	);
};

class ScheduleAppointment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentIdx: 0,
			selectedAppointment: null,
			payment: null,
			nonzerobookbtnenabled: true,
			payor: 'insurance',
			loading: false,
			showErrorDialog: false,
			closeHandler: () => {},
			errormessages: [],
			addressErrors: [],
			rorEncounterUUID: null, //Do not put any logic on this key. as it never reset. use findROREncounter() instead of
			addresses: [],
			hasAllAddressTypes: false,
			showAddressModal: false,
			addressModalEdit: false,
			addressModalIdx: null,
			addressChanged: false,
			paymentAddressChanged: false,
			sendingPaymentStageAddressRequest: false,
			intermediateSpinner: false,
			firstStageSubmitAttempted: false,
		};
		//bindings
		this.renderCurrentStage = this.renderCurrentStage.bind(this);
		this.goToStep = this.goToStep.bind(this);
		this.updatePatientDetail = this.updatePatientDetail.bind(this);
		this.setPatientAddress = this.setPatientAddress.bind(this);
		this.selectedconsultation = this.selectedconsultation.bind(this);
		this.selectedProvider = this.selectedProvider.bind(this);
		this.isZeroPriceConsultation = this.isZeroPriceConsultation.bind(this);
		this.getProviderImage = this.getProviderImage.bind(this);
		this.getProviderName = this.getProviderName.bind(this);
		this.getShortText = this.getShortText.bind(this);
		this.ucFirstAllWords = this.ucFirstAllWords.bind(this);
		this.getTimestamp = this.getTimestamp.bind(this);
		this.getModalityIcon = this.getModalityIcon.bind(this);
		this.ZeroFeeBookAppointment = this.ZeroFeeBookAppointment.bind(this);
		this.NonZeroFeeBookAppointment =
			this.NonZeroFeeBookAppointment.bind(this);
		this.isNonZeroFeeBookAppointmentEnabled =
			this.isNonZeroFeeBookAppointmentEnabled.bind(this);
		this.PaymentStageChange = this.PaymentStageChange.bind(this);
		this.setNonzerobookbtnDisbled =
			this.setNonzerobookbtnDisbled.bind(this);
		this.setPayor = this.setPayor.bind(this);
		this.errorCloseHandler = this.errorCloseHandler.bind(this);
		this.showErrorsDialog = this.showErrorsDialog.bind(this);
		this.calculatePrice = this.calculatePrice.bind(this);
		this.setROREncounterUUID = this.setROREncounterUUID.bind(this);
		this.findROREncounter = this.findROREncounter.bind(this);
		this.sendNonAddressRequest = this.sendNonAddressRequest.bind(this);
		this.sendAddressRequest = this.sendAddressRequest.bind(this);
		this.sendRequest = this.sendRequest.bind(this);
		this.sendPaymentStateAddressRequest =
			this.sendPaymentStateAddressRequest.bind(this);
		this.determinePatientHasAllAddressTypes =
			this.determinePatientHasAllAddressTypes.bind(this);
		this.determineAddressCompletionWarning =
			this.determineAddressCompletionWarning.bind(this);
		this.handleAddressModalSave = this.handleAddressModalSave.bind(this);
		this.selectedAddressIsBilling =
			this.selectedAddressIsBilling.bind(this);
		this.openAddressModal = this.openAddressModal.bind(this);
		this.closeAddressModal = this.closeAddressModal.bind(this);
		this.renderAddressSection = this.renderAddressSection.bind(this);
		this.getAddressCompletionWarningString =
			this.getAddressCompletionWarningString.bind(this);
		this.selectAddress = this.selectAddress.bind(this);
		this.selectedAddressIsNotBilling =
			this.selectedAddressIsNotBilling.bind(this);
		this.hasNoBillingAddress = this.hasNoBillingAddress.bind(this);
		this.selectedAddressIsNotBillingAndNoBillingAddress =
			this.selectedAddressIsNotBillingAndNoBillingAddress.bind(this);
		this.getSelectedAddressId = this.getSelectedAddressId.bind(this);
		this.setAddressErrors = this.setAddressErrors.bind(this);
		this.setFirstStageSubmitAttempt =
			this.setFirstStageSubmitAttempt.bind(this);
	}

	//functions

	componentDidMount() {
		this.props.resetPatientEncounters();
	}

	componentDidUpdate(prevProps) {
		if (
			_.get(prevProps, 'gettingPatientDetail', false) &&
			!_.get(this, 'props.gettingPatientDetail', false)
		) {
			if (!_.get(this, 'props.patientDetailError', false)) {
				if (get(this, 'state.sendingPaymentStageAddressRequest', false))
					this.setPatientAddress(true);
				else {
					if (!_.get(this, 'props.updatedAddressError', false))
						this.setPatientAddress();
				}
			}
		}

		if (
			_.get(prevProps, 'updatingAddress', false) &&
			!_.get(this, 'props.updatingAddress', false)
		) {
			if (!_.get(this, 'props.updatedAddressError', false)) {
				this.setState({ addressErrors: [] });
				if (
					get(this, 'state.sendingPaymentStageAddressRequest', false)
				) {
					const uuid = get(this, 'props.newAppointment.uuid', null);
					this.props.getPatientDetail(uuid, true);
				}
			} else {
				this.setState({ sendingPaymentStageAddressRequest: false });
				this.setAddressErrors(
					_.get(this, 'props.updatedAddressError', false)
				);
			}
		}

		if (
			_.get(this, ['props', 'patientDetail', '0', 'id'], '') !=
				_.get(prevProps, ['patientDetail', '0', 'id'], '') &&
			_.get(this.props, ['patientDetail', '0', 'partner_id'], 0) != 0
		) {
			this.props.getAppointmentPartnerDetailbyId(
				_.get(this.props, ['patientDetail', '0', 'partner_id'], 0)
			);
			this.props.getAppointmentProvider(
				_.get(this.props, ['patientDetail', '0', 'id'], 0)
			);
			const defaultstate = getShippingState(
				_.get(this.props, ['patientDetail', '0', 'address'], null)
			);
			const { state } = this.props.newAppointment;
			if (!state || _.isEmpty(state)) {
				const timezone = GetStateTimezone(defaultstate);
				this.props.updateAppointmentparams({
					state: defaultstate,
					timezone,
				});
			}

			this.props.updateAppointmentparams({
				patientInfo: {
					..._.get(this.props, ['patientDetail', '0'], {}),
				},
			});
		}
		if (
			_.get(prevProps, ['PartnerDetail']) !=
			_.get(this.props, ['PartnerDetail'])
		) {
			const partner_uuid = _.get(this.props, ['PartnerDetail', 'uuid']);
			this.props.getDefaultSchedulingOptions({
				partner_uuid: partner_uuid,
			});
			if (
				_.get(
					this.props,
					['PartnerDetail', 'is_selected_specialties'],
					false
				)
			) {
				this.props.getVisitSchema({
					partner_uuid: _.get(this.props, ['PartnerDetail', 'uuid']),
				});
			} else {
				this.props.getVisitSchema();
			}
		}
		if (
			_.get(this, ['props', 'Scheduling', 'couponCodeSuccess']) !=
			_.get(prevProps, ['Scheduling', 'couponCodeSuccess'])
		) {
			this.PaymentStageChange();
		}
		if (
			_.get(this, ['props', 'newAppointment', 'useInsurance']) !=
			_.get(prevProps, ['newAppointment', 'useInsurance'])
		) {
			this.PaymentStageChange();
		}
	}

	componentWillUnmount() {
		this.props.resetNewAppointment();
	}

	renderSnackbar(appointment, patient, timeslot) {
		this.props.enqueueSnackbar(
			<AppointmentSnackbar
				encounter={appointment}
				getProviderImage={this.getProviderImage}
				getProviderName={this.getProviderName}
				getShortText={this.getShortText}
				ucFirstAllWords={this.ucFirstAllWords}
				getTimestamp={this.getTimestamp}
				getModalityIcon={this.getModalityIcon}
				closeSnackbar={this.props.closeSnackbar}
				appointmentTimeslot={
					timeslot || get(this, 'props.newAppointment.timeslot', {})
				}
				patient={
					patient || get(this, 'props.newAppointment.patientInfo', {})
				}
			/>,
			{
				anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				autoHideDuration: 10000,
				className: 'appointmentCreateSnackbarContainer',
			}
		);
	}

	selectedconsultation() {
		const consultations = _.get(
			this,
			['props', 'Scheduling', 'schedulingConsultations'],
			[]
		);
		const consultation_id = _.get(this, [
			'props',
			'Scheduling',
			'newAppointment',
			'consultation_id',
		]);
		return (
			_.isArray(consultations) &&
			consultation_id &&
			consultations.find((c) => c.id === consultation_id)
		);
	}

	selectedProvider() {
		const providers = _.get(this, ['props', 'Scheduling', 'providers'], []);
		const provider_id = _.get(this, [
			'props',
			'Scheduling',
			'newAppointment',
			'provider_id',
		]);
		return (
			_.isArray(providers) &&
			provider_id &&
			providers.find((c) => c.id === provider_id)
		);
	}

	setPatientAddress(isPaymentStage) {
		const addresses =
			get(this, `props.patientDetail[0]${currentAddressKey}`, null) || [];
		if (addresses) {
			this.setState(
				{
					addresses,
					selectedAddressIdx: isPaymentStage
						? get(this, 'state.selectedAddressIdx', null)
						: null,
					addressChanged: false,
					paymentAddressChanged: false,
					sendingPaymentStageAddressRequest: false,
					intermediateSpinner: isPaymentStage ? true : false,
					firstStageSubmitAttempted: false,
				},
				() => {
					this.determinePatientHasAllAddressTypes();
					this.determineAddressCompletionWarning();
					if (isPaymentStage) this.NonZeroFeeBookAppointment();
				}
			);
		}
	}

	updatePatientDetail(data) {
		if (!_.isEmpty(data.address) || !_.isEmpty(data.outreach)) {
			return this.props
				.updatePatientDetail(data)
				.then((res) => {
					const failure = [];
					const success = [];
					res.forEach((cres) => {
						if (cres.status && cres.status == 'rejected') {
							failure.push(cres.reason);
						} else if (cres.status && cres.status == 'fulfilled') {
							success.push(_.get(cres, ['value', 'type'], ''));
						}
					});
					if (_.get(failure, 'length', -1) == 0) {
						this.refs.SelectPatientStage.showLoading(false);
						if (getShippingState(_.get(data, 'address.payload'))) {
							const state = getShippingState(
								_.get(data, 'address.payload')
							);
							const timezone = GetStateTimezone(state);
							this.props.updateAppointmentparams({
								state,
								timezone,
								timeslot: {},
							});
							this.props.filterChangeEvent();
						}

						if (
							_.get(
								data,
								['outreach', 'payload', 'has_guardian'],
								false
							)
						) {
							if (
								_.get(
									data,
									[
										'outreach',
										'payload',
										'users_guardian',
										'phones',
										'primary',
										'value',
									],
									''
								) != ''
							) {
								const { patientInfo } =
									this.props.newAppointment;
								this.props.updateAppointmentparams({
									patientInfo: {
										...patientInfo,
										phone: _.get(data, [
											'outreach',
											'payload',
											'users_guardian',
											'phones',
											'primary',
											'value',
										]),
									},
								});
							}
						} else {
							if (
								_.get(
									data,
									[
										'outreach',
										'payload',
										'phones',
										'primary',
										'value',
									],
									''
								) != ''
							) {
								const { patientInfo } =
									this.props.newAppointment;
								this.props.updateAppointmentparams({
									patientInfo: {
										...patientInfo,
										phone: _.get(data, [
											'outreach',
											'payload',
											'phones',
											'primary',
											'value',
										]),
									},
								});
							}
						}
						if (
							_.get(data, ['outreach', 'payload', 'email'], '') !=
							''
						) {
							const { patientInfo } = this.props.newAppointment;
							this.props.updateAppointmentparams({
								patientInfo: {
									...patientInfo,
									email: _.get(data, [
										'outreach',
										'payload',
										'email',
									]),
								},
							});
						}
						this.goToStep(1);
					} else {
						if (!_.isEmpty(success)) {
							/* if there are some request success and some failures. we need to update the patient detail. */
							const { uuid } = this.props.newAppointment;
							this.props.getPatientDetail(uuid, true);
							/* to ensure the resonse will come and update the props ,we will sleep execute in catch block for some milliseconds */
						}
						if (
							getShippingState(_.get(data, 'address.payload')) &&
							success.includes('address')
						) {
							const state = getShippingState(
								_.get(data, 'address.payload')
							);
							const timezone = GetStateTimezone(state);
							this.props.updateAppointmentparams({
								state,
								timezone,
								timeslot: {},
							});
							this.props.filterChangeEvent();
						}
						if (success.includes('outreach')) {
							const { patientInfo } = this.props.newAppointment;

							if (
								_.get(
									data,
									['outreach', 'payload', 'has_guardian'],
									false
								)
							) {
								if (
									_.get(
										data,
										[
											'outreach',
											'payload',
											'users_guardian',
											'phones',
											'primary',
											'value',
										],
										''
									) != ''
								) {
									patientInfo.phone = _.get(data, [
										'outreach',
										'payload',
										'users_guardian',
										'phones',
										'primary',
										'value',
									]);
								}
							} else {
								if (
									_.get(
										data,
										[
											'outreach',
											'payload',
											'phones',
											'primary',
											'value',
										],
										''
									) != ''
								) {
									patientInfo.phone = _.get(data, [
										'outreach',
										'payload',
										'phones',
										'primary',
										'value',
									]);
								}
							}
							if (
								_.get(
									data,
									['outreach', 'payload', 'email'],
									''
								) != ''
							) {
								patientInfo.email = _.get(data, [
									'outreach',
									'payload',
									'email',
								]);
							}
							this.props.updateAppointmentparams({ patientInfo });
						}
						throw failure;
					}
				})
				.catch((err) => {
					const error0 = hydratePatientEditError(
						_.get(err, ['0', 'error', 'response', 'data'], '')
					);
					const error1 = hydratePatientEditError(
						_.get(err, ['1', 'error', 'response', 'data'], '')
					);
					let errormessages = {};
					if (error0 && error0.errorMessages) {
						errormessages = { ...error0.errorMessages };
					}
					if (error1 && error1.errorMessages) {
						errormessages = {
							...errormessages,
							...error1.errorMessages,
						};
					}
					sleep(1000).then(() => {
						this.refs.SelectPatientStage.showLoading(false);
					});
					throw new SubmissionError({ ...errormessages });
				});
		} else {
			this.refs.SelectPatientStage.showLoading(false);
			this.goToStep(1);
		}
	}

	goToStep(currentIdx) {
		this.setState({
			currentIdx,
			addressChanged: null,
			addressErrors: [],
			firstStageSubmitAttempted: false,
		});
	}

	PaymentStageChange() {
		setTimeout(() => {
			const nonzerobookbtnenabled =
				this.isNonZeroFeeBookAppointmentEnabled();
			this.setState({ nonzerobookbtnenabled });
		}, 200);
	}

	calculatePrice() {
		let consulatationPrice = _.get(this.selectedconsultation(), 'price');
		const useInsurance = _.get(
			this,
			['props', 'newAppointment', 'useInsurance'],
			false
		);
		const insurancePrice = _.get(this, [
			'props',
			'newAppointment',
			'insurancePrice',
		]);
		const insurance_company_id = _.get(
			this,
			['props', 'newAppointment', 'insurance_company_id'],
			0
		);
		const couponCodeSuccess = _.get(this, ['props', 'couponCodeSuccess']);
		if (consulatationPrice !== undefined) {
			if (useInsurance) {
				if (insurance_company_id !== 0)
					consulatationPrice = insurancePrice;
			} else {
				if (couponCodeSuccess && !_.isNil(couponCodeSuccess)) {
					const discountValue = _.get(
						this,
						[
							'props',
							'couponCodeSuccess',
							'discount_info',
							'discount_value',
						],
						0
					);
					consulatationPrice =
						consulatationPrice - discountValue >= 0
							? consulatationPrice - discountValue
							: 0;
				}
			}
		}
		return consulatationPrice;
	}

	isZeroPriceConsultation() {
		const { uuid, id, timeslot, consultation_id } =
			this.props.newAppointment;
		const selectedconsultation = this.selectedconsultation();
		const insurance_only = _.get(
			this,
			['props', 'PartnerDetail', 'insurance_only'],
			false
		);
		return (
			!_.isEmpty(timeslot) &&
			consultation_id &&
			uuid &&
			id &&
			selectedconsultation &&
			selectedconsultation.price == 0 &&
			this.props.PartnerDetail &&
			(insurance_only === false ||
				selectedconsultation.is_predefined == true) &&
			this.state.payor === 'self-pay'
		);
	}

	isNonZeroFeeBookAppointmentEnabled() {
		const isCardChange = _.get(this, [
			'props',
			'newAppointment',
			'isCardChange',
		]);
		const price = this.calculatePrice();
		const couponcodeAvaiable = !_.isNil(this.props.couponCodeSuccess);
		if (this.refs.PaymentStage) {
			const { useInsurance, insurancePrice } = this.props.newAppointment;
			const {
				CreditCardSection,
				InsuranceSection,
				SecondaryInsuranceSection,
			} = this.refs.PaymentStage.getFormRefs();
			let ISInsuranceValid = false;
			let ISCreditCardValid = false;
			let ISSecondaryInsuranceValid = false;
			if (useInsurance) {
				if (InsuranceSection && InsuranceSection.valid) {
					ISInsuranceValid = true;
				}
				if (insurancePrice == 0) {
					ISCreditCardValid = true;
				} else {
					if (CreditCardSection && CreditCardSection.valid) {
						ISCreditCardValid = true;
					} else if (CreditCardSection && !CreditCardSection.valid) {
						ISCreditCardValid = false;
					} else if (isCardChange == true) {
						ISCreditCardValid = true;
					}
				}
			} else {
				ISInsuranceValid = true;
			}
			if (useInsurance) {
				if (
					SecondaryInsuranceSection &&
					SecondaryInsuranceSection.valid
				) {
					ISSecondaryInsuranceValid = true;
				} else if (!SecondaryInsuranceSection) {
					ISSecondaryInsuranceValid = true;
				} else {
					ISSecondaryInsuranceValid = false;
				}
			} else {
				ISSecondaryInsuranceValid = true;
			}
			if (useInsurance === false) {
				const zeropricewithcoupon =
					price != undefined && price == 0 && couponcodeAvaiable;
				if (CreditCardSection && CreditCardSection.valid) {
					ISCreditCardValid = true;
				} else if (CreditCardSection && !CreditCardSection.valid) {
					ISCreditCardValid = false;
				} else if (isCardChange == true) {
					ISCreditCardValid = true;
				} else if (zeropricewithcoupon) {
					ISCreditCardValid = true;
				}
			}
			return !(
				ISCreditCardValid &&
				ISInsuranceValid &&
				ISSecondaryInsuranceValid &&
				(!CreditCardSection ||
					this.selectedAddressIsBilling() ||
					this.selectedAddressIsNotBillingAndNoBillingAddress())
			);
		}
		return true;
	}

	setNonzerobookbtnDisbled() {
		this.setState({ nonzerobookbtnenabled: true });
	}

	setPayor(payType) {
		this.setState({ payor: payType });
	}

	ZeroFeeBookAppointment() {
		const NewAppointment = this.props.newAppointment;
		const data = {
			use_insurance: false,
			consultation_id: NewAppointment.consultation_id,
			patient_id: NewAppointment.id,
			start_time: NewAppointment.timeslot.start,
			state: NewAppointment.state,
			timezone: NewAppointment.timezone,
			vsee_specialty: NewAppointment.vsee_specialty,
			modality: NewAppointment.modality,
			provider_id: NewAppointment.timeslot.id,
		};
		this.setState({ loading: true });
		zeroFeeBookAppointment(data)
			.then((appointment) => {
				this.setState({ loading: false });
				this.renderSnackbar(
					appointment,
					get(this, 'props.newAppointment.patientInfo', {}),
					get(this, 'props.newAppointment.timeslot', {})
				);
				this.props.history.push('/app/appointments');
			})
			.catch((error) => {
				this.setState({ loading: false });
				if (
					_.get(error, ['ScheduleNonActionableErrors', 'length'], 0) >
					0
				) {
					this.showErrorsDialog(
						error.ScheduleNonActionableErrors,
						() => {
							this.hideErrorDialog();
						}
					);
				} else if (
					_.get(error, ['PaymentNonActionableErrors', 'length'], 0) >
					0
				) {
					this.showErrorsDialog(
						error.PaymentNonActionableErrors,
						() => {
							this.hideErrorDialog();
						}
					);
				} else if (
					_.get(error, ['ScheduleActionableErrors', 'length'], 0) > 0
				) {
					this.showErrorsDialog(
						error.ScheduleActionableErrors,
						() => {
							this.goToStep(1);
							this.hideErrorDialog();
						}
					);
				} else if (
					_.get(error, ['PaymentActionableErrors', 'length'], 0) > 0
				) {
					this.showErrorsDialog(error.PaymentActionableErrors, () => {
						this.hideErrorDialog();
					});
				} else if (
					_.get(error, ['NonCategoriesErrors', 'length'], 0) > 0
				) {
					this.showErrorsDialog(error.NonCategoriesErrors, () => {
						this.hideErrorDialog();
					});
				}
			});
	}

	NonZeroFeeBookAppointment() {
		const NewAppointment = this.props.newAppointment;
		const { couponCodeSuccess } = this.props;
		let coupon_code;
		if (!_.isNil(couponCodeSuccess) && !_.isEmpty(couponCodeSuccess)) {
			coupon_code = _.isEmpty(_.get(couponCodeSuccess, 'code', ''))
				? undefined
				: _.get(couponCodeSuccess, 'code', '');
		}
		const {
			CreditCardSection,
			InsuranceSection,
			SecondaryInsuranceSection,
		} = this.refs.PaymentStage.getFormRefs();
		if (InsuranceSection) {
			coupon_code = undefined;
		}
		const data = {};
		if (
			CreditCardSection &&
			CreditCardSection.values &&
			!_.isEmpty(CreditCardSection.values)
		) {
			if (
				get(this, 'state.paymentAddressChanged', false) ||
				this.selectedAddressIsNotBillingAndNoBillingAddress()
			) {
				this.sendPaymentStateAddressRequest();
				return;
			}
			data['CreditCardSection'] = {
				data: {
					...CreditCardSection.values,
					user_addresses_id: this.getSelectedAddressId(),
				},
				patient_uuid: NewAppointment.uuid,
			};
		}
		if (
			InsuranceSection &&
			InsuranceSection.values &&
			!_.isEmpty(InsuranceSection.values)
		) {
			const insurances = [];
			insurances.push({
				...InsuranceSection.values,
				insurance_type: 'primary',
				visit_insurance: true,
				lab_insurance:false,
				group_no: InsuranceSection.values.group_number,
				policyholder_dob: InsuranceSection.values.policyholder_dob
					? moment(InsuranceSection.values.policyholder_dob).format(
							'YYYY-MM-DD'
					  )
					: '',
				patient_uuid: NewAppointment.uuid,
			});
			if (
				SecondaryInsuranceSection &&
				SecondaryInsuranceSection.values &&
				!_.isEmpty(SecondaryInsuranceSection.values)
			) {
				const secInsurance = {
					...SecondaryInsuranceSection.values,
					insurance_type: 'secondary',
					visit_insurance: true,
					lab_insurance:false,
					group_no: SecondaryInsuranceSection.values.group_number,
					policyholder_dob: SecondaryInsuranceSection.values
						.policyholder_dob
						? moment(
								SecondaryInsuranceSection.values
									.policyholder_dob
						  ).format('YYYY-MM-DD')
						: '',
					patient_uuid: NewAppointment.uuid,
				};
				if (
					SecondaryInsuranceSection.values.insurance_company_id == 0
				) {
					delete secInsurance['insurance_company_id'];
				}
				insurances.push({ ...secInsurance });
			}
			data['InsuranceSection'] = {
				insurances,
				patient_uuid: NewAppointment.uuid,
			};
		}
		data['bookAppointment'] = {
			use_insurance: NewAppointment.useInsurance,
			consultation_id: NewAppointment.consultation_id,
			patient_id: NewAppointment.id,
			start_time: NewAppointment.timeslot.start,
			state: NewAppointment.state,
			timezone: NewAppointment.timezone,
			vsee_specialty: NewAppointment.vsee_specialty,
			modality: NewAppointment.modality,
			coupon_code: coupon_code,
			provider_id: NewAppointment.timeslot.id,
		};
		this.setState({ loading: true, intermediateSpinner: false });
		nonZeroBookAppointment(data)
			.then((visitencounter) => {
				this.setState({ loading: false });
				this.renderSnackbar(
					visitencounter,
					get(this, 'props.newAppointment.patientInfo', {}),
					get(this, 'props.newAppointment.timeslot', {})
				);
				this.props.history.push('/app/appointments');
			})
			.catch((error) => {
				this.setState({ loading: false });
				if (
					_.get(error, ['ScheduleNonActionableErrors', 'length'], 0) >
					0
				) {
					this.showErrorsDialog(
						error.ScheduleNonActionableErrors,
						() => {
							this.hideErrorDialog();
						}
					);
				} else if (
					_.get(error, ['PaymentNonActionableErrors', 'length'], 0) >
					0
				) {
					this.showErrorsDialog(
						error.PaymentNonActionableErrors,
						() => {
							this.hideErrorDialog();
						}
					);
				} else if (
					_.get(error, ['ScheduleActionableErrors', 'length'], 0) > 0
				) {
					this.showErrorsDialog(
						error.ScheduleActionableErrors,
						() => {
							this.goToStep(1);
							this.hideErrorDialog();
						}
					);
				} else if (
					!_.isEmpty(_.get(error, ['PaymentFieldsErrors'], {})) ||
					!_.isEmpty(_.get(error, ['InsuranceFieldsErrors'], {}))
				) {
					if (
						!_.isEmpty(
							_.get(
								error,
								['InsuranceFieldsErrors', 'primary'],
								{}
							)
						)
					) {
						const keyserror = Object.keys(
							error.InsuranceFieldsErrors.primary
						);
						this.props.dispatchfunc(
							stopSubmit(
								'scheduleappointmentinsuranceform',
								error.InsuranceFieldsErrors.primary
							)
						);
						this.props.dispatchfunc(
							setSubmitFailed(
								'scheduleappointmentinsuranceform',
								keyserror
							)
						);
					}
					if (
						!_.isEmpty(
							_.get(
								error,
								['InsuranceFieldsErrors', 'secondary'],
								{}
							)
						)
					) {
						const keyserror = Object.keys(
							error.InsuranceFieldsErrors.secondary
						);
						this.props.dispatchfunc(
							stopSubmit(
								'scheduleScheduligappointmentinsuranceform',
								error.InsuranceFieldsErrors.secondary
							)
						);
						this.props.dispatchfunc(
							setSubmitFailed(
								'scheduleScheduligappointmentinsuranceform',
								keyserror
							)
						);
					}
					if (!_.isEmpty(_.get(error, ['PaymentFieldsErrors'], {}))) {
						const keyserror = Object.keys(
							error.PaymentFieldsErrors
						);
						this.props.dispatchfunc(
							stopSubmit(
								'scheduleappointmentcreditcardform',
								error.PaymentFieldsErrors
							)
						);
						this.props.dispatchfunc(
							setSubmitFailed(
								'scheduleappointmentcreditcardform',
								keyserror
							)
						);
					}
				} else if (
					_.get(error, ['PaymentActionableErrors', 'length'], 0) > 0
				) {
					this.showErrorsDialog(error.PaymentActionableErrors, () => {
						this.hideErrorDialog();
					});
				} else if (
					_.get(error, ['NonCategoriesErrors', 'length'], 0) > 0
				) {
					this.showErrorsDialog(error.NonCategoriesErrors, () => {
						this.hideErrorDialog();
					});
				}
			});
	}

	errorCloseHandler() {
		if (this.state.closeHandler) this.state.closeHandler();
	}

	showErrorsDialog(errors, handler) {
		this.setState({
			errormessages: errors,
			closeHandler: handler,
			showErrorDialog: true,
		});
	}

	hideErrorDialog() {
		this.setState({
			errormessages: [],
			closeHandler: () => {},
			showErrorDialog: false,
		});
	}

	setROREncounterUUID = (rorEncounterUUID) => {
		this.setState({ rorEncounterUUID });
	};

	findROREncounter = () => {
		const rorEncounterUUID = this.state.rorEncounterUUID;
		const encounters = _.get(this, ['props', 'encountersList', 'data'], []);
		if (
			!_.isNil(rorEncounterUUID) &&
			_.isArray(encounters) &&
			!_.isEmpty(encounters)
		) {
			const encounter = _.find(encounters, (enc) => {
				return enc.uuid == rorEncounterUUID;
			});
			return encounter;
		}
	};

	determinePatientHasAllAddressTypes() {
		const addresses = get(this, 'state.addresses', null) || [];
		this.setState({
			hasAllAddressTypes: determineHasAllAddressTypes(addresses),
		});
	}

	determineAddressCompletionWarning() {
		const addresses = get(this, 'state.addresses', null) || [];
		const currentTypes = getExistingAddressTypes(addresses);
		if (Array.isArray(currentTypes)) {
			if (
				currentTypes.includes('home') &&
				currentTypes.includes('delivery')
			) {
				this.setState({ addressCompletionWarning: false });
			} else {
				this.setState({ addressCompletionWarning: true });
			}
		}
	}

	handleAddressModalSave(data) {
		if (data) {
			const addressChangeData = {};
			const stageIdx = get(this, 'state.currentIdx', 0);
			if (stageIdx === 0) addressChangeData.addressChanged = true;
			if (stageIdx === 2) addressChangeData.paymentAddressChanged = true;

			if (stageIdx === 2) addressChangeData.selectedAddressIdx = null;
			this.setState({ addresses: data, ...addressChangeData }, () => {
				this.determinePatientHasAllAddressTypes();
				this.closeAddressModal();
				this.determineAddressCompletionWarning();
				if (stageIdx === 2) this.PaymentStageChange();
			});
		}
	}

	selectedAddressIsBilling() {
		const selectedAddressIdx = get(this, 'state.selectedAddressIdx', null);
		const addresses = get(this, 'state.addresses', null) || [];
		if (typeof selectedAddressIdx === 'number') {
			return singleAddressIsBilling(
				get(addresses, `[${selectedAddressIdx}]`, null) || {}
			);
		} else return false;
	}

	openAddressModal(isEdit, idx, nonEditInitialAddressData) {
		this.setState({
			showAddressModal: true,
			addressModalEdit: isEdit,
			addressModalIdx: idx,
			nonEditInitialAddressData: nonEditInitialAddressData,
		});
	}

	closeAddressModal() {
		this.setState({
			showAddressModal: false,
			addressModalEdit: false,
			addressModalIdx: null,
			nonEditInitialAddressData: null,
		});
	}

	sendNonAddressRequest(form) {
		const patientDetail = get(this, ['props', 'patientDetail', '0'], {});
		const outreachData = {};
		const outreachPayload = {};
		if (form.consentToTreat !== patientDetail.consent_to_treat)
			outreachPayload.consent_to_treat = form.consentToTreat;
		if (form.email !== patientDetail.email)
			outreachPayload.email = form.email;

		const primaryPhone = _.get(
			patientDetail,
			['phones', 'primary', 'value'],
			''
		);
		const primaryPhoneType = _.get(
			patientDetail,
			['phones', 'primary', 'type'],
			null
		);
		const secondaryPhone = _.get(
			patientDetail,
			['phones', 'secondary', 'value'],
			''
		);
		const secondaryPhoneType = _.get(
			patientDetail,
			['phones', 'secondary', 'type'],
			null
		);

		if (
			form.primaryPhone !== primaryPhone ||
			form.primaryPhoneType !== primaryPhoneType ||
			form.secondaryPhone !== secondaryPhone ||
			form.secondaryPhoneType !== secondaryPhoneType
		) {
			const phones = {};
			if (!_.isEmpty(form.primaryPhone)) {
				phones['primary'] = {
					value: form.primaryPhone,
					type: form.primaryPhoneType,
				};
			}
			if (!_.isEmpty(form.secondaryPhone)) {
				phones['secondary'] = {
					value: form.secondaryPhone,
					type: form.secondaryPhoneType,
				};
			}
			if (!_.isEmpty(phones)) {
				outreachPayload.phones = phones;
			}
		}

		const guardianPayload = {};
		if (form.hasGuardian) {
			outreachPayload.has_guardian = form.hasGuardian;
			guardianPayload.first_name = form.guardianFirstName;
			guardianPayload.last_name = form.guardianLastName;
			guardianPayload.dob =
				(form.guardianDOB &&
					moment(form.guardianDOB).isValid() &&
					moment(form.guardianDOB).format('YYYY-MM-DD')) ||
				form.guardianDOB;
			guardianPayload.relationship = form.guardianRelationship;

			const guardianPhones = {};
			if (!_.isEmpty(form.guardianPrimaryPhone)) {
				guardianPhones['primary'] = {
					value: form.guardianPrimaryPhone,
					type: form.guardianPrimaryPhoneType,
				};
			}
			if (!_.isEmpty(form.guardianSecondaryPhone)) {
				guardianPhones['secondary'] = {
					value: form.guardianSecondaryPhone,
					type: form.guardianSecondaryPhoneType,
				};
			}
			if (!_.isEmpty(guardianPhones)) {
				guardianPayload.phones = guardianPhones;
			}
			outreachPayload.users_guardian = guardianPayload;
			outreachPayload.is_unborn = _.get(
				patientDetail,
				['is_unborn'],
				false
			);
		} else {
			delete outreachPayload['guardianPayload'];
		}
		if (!_.isEmpty(outreachPayload)) {
			outreachData.patient_id = _.get(patientDetail, 'uuid', null);
			outreachData.payload = trimPayload(_.cloneDeep(outreachPayload));
		}
		return outreachData;
	}

	sendAddressRequest(form, addBillingToSelected) {
		const patient = get(this, ['props', 'patientDetail', '0'], null) || {};
		const addresses = get(this, 'state.addresses', null);
		const patientId = get(patient, 'id', null);
		const fullName =
			get(form, 'firstName', null) && get(form, 'lastName', null)
				? `${form['firstName']} ${form['lastName']}`
				: `${get(patient, 'first_name', null)} ${get(
						patient,
						'last_name',
						null
				  )}`;
		const selectedAddressIdx = get(this, 'state.selectedAddressIdx', null);
		const addressData = {};
		if (Array.isArray(addresses) && get(addresses, 'length', 0)) {
			addressData.payload = trimPayload(_.cloneDeep(addresses));
			addressData.payload.forEach((address, i) => {
				const addressType = get(address, 'type', null);
				if (!get(address, 'address_line2', null))
					delete address.address_line2;
				if (address.created_at) delete address.created_at;
				if (address.modified_at) delete address.modified_at;
				address.full_name = fullName;
				if (!get(address, 'user_id', null) && patientId)
					address.user_id = patientId;
				if (
					i === selectedAddressIdx &&
					addBillingToSelected &&
					Array.isArray(addressType)
				) {
					if (!addressType.includes('billing'))
						address.type = [...address.type, 'billing'];
				}
			});
		}

		addressData.method = 'post';
		addressData.patient_id = get(patient, 'uuid', null);
		return addressData;
	}

	sendPaymentStateAddressRequest() {
		const addBillingToSelected =
			this.selectedAddressIsNotBillingAndNoBillingAddress();
		const addressData = this.sendAddressRequest(null, addBillingToSelected);
		this.setState({ sendingPaymentStageAddressRequest: true }, () => {
			this.props.updateAddressPayment(addressData);
		});
	}

	sendRequest(reqData, skipNonAddress) {
		const data = {};
		const outreach = skipNonAddress
			? null
			: this.sendNonAddressRequest(reqData);
		const address = get(this, 'state.addressChanged', false)
			? this.sendAddressRequest(reqData)
			: null;
		this.refs.SelectPatientStage.showLoading(true);
		if (outreach) data.outreach = outreach;
		if (address) data.address = address;
		return this.updatePatientDetail(data);
	}

	renderCurrentStage() {
		switch (this.state.currentIdx) {
			case 0:
				return (
					<SelectPatientStage
						patientSearchResults={this.props.patientSearchResults}
						patientSearchResultsLoading={
							this.props.patientSearchResultsLoading
						}
						getPatientsSearch={this.props.getPatientsSearch}
						patientDetail={this.props.patientDetail}
						gettingPatientDetail={this.props.gettingPatientDetail}
						patientdetailError={this.props.patientdetailError}
						getPatientDetail={this.props.getPatientDetail}
						getConsenttotreat={this.props.getConsenttotreat}
						consenttotreat={this.props.consenttotreat}
						referralPrograms={this.props.referralPrograms}
						getRefPrograms={this.props.getRefPrograms}
						getPatientEncouters={this.props.getPatientEncouters}
						encountersList={this.props.encountersList}
						encountersLoading={this.props.encountersLoading}
						encountersCompleteLoading={
							this.props.encountersCompleteLoading
						}
						encountersCancelLoading={
							this.props.encountersCancelLoading
						}
						updatePatientDetail={this.updatePatientDetail}
						updateAppointmentparams={
							this.props.updateAppointmentparams
						}
						toggleAppointmentPatient={
							this.props.toggleAppointmentPatient
						}
						setAppointmentPatient={this.props.setAppointmentPatient}
						newAppointment={this.props.newAppointment}
						history={this.props.history}
						ref={'SelectPatientStage'}
						enqueueSnackbar={this.props.enqueueSnackbar}
						determineEmailStatus={determineEmailStatus}
						setROREncounterUUID={this.setROREncounterUUID}
						rorEncounterUUID={this.state.rorEncounterUUID}
						providers={_.get(
							this.props,
							['Scheduling', 'providers'],
							[]
						)}
						hasAllAddressTypes={get(
							this,
							'state.hasAllAddressTypes',
							false
						)}
						addresses={get(this, 'state.addresses', null) || []}
						addressErrors={
							get(this, 'state.addressErrors', null) || []
						}
						openAddressModal={this.openAddressModal}
						addressCompletionWarning={get(
							this,
							'state.addressCompletionWarning',
							false
						)}
						sendRequest={this.sendRequest}
						renderSwitch={renderSwitch}
						renderAddressSection={this.renderAddressSection}
						setFirstStageSubmitAttempt={
							this.setFirstStageSubmitAttempt
						}
						getPlanTypesEnums={this.props.getPlanTypesEnums}
					/>
				);
			case 1:
				return (
					<ScheduleStage
						Scheduling={this.props.Scheduling}
						modalities={this.props.modalities}
						specialties={this.props.specialties}
						visitProviders={this.props.visitProviders}
						updateAppointmentparams={
							this.props.updateAppointmentparams
						}
						defaultSchedulingOptions={
							this.props.defaultSchedulingOptions
						}
						newAppointment={this.props.newAppointment}
						LoadAppointmentSlot={this.props.LoadAppointmentSlot}
						goToStep={this.goToStep}
						PartnerDetail={this.props.PartnerDetail}
						clearSelectedSlot={this.props.clearSelectedSlot}
						setModality={this.props.setModality}
						isZeroPriceConsultation={this.isZeroPriceConsultation}
						getPatientDetail={this.props.getPatientDetail}
						filterChangeEvent={this.props.filterChangeEvent}
						ROREncounter={this.findROREncounter()}
					/>
				);
			case 2:
				return (
					<PaymentStage
						patientDetail={this.props.patientDetail}
						insuranceCompanies={this.props.insuranceCompanies}
						planTypesEnums={this.props.planTypesEnums}
						PartnerDetail={this.props.PartnerDetail}
						updateAppointmentparams={
							this.props.updateAppointmentparams
						}
						newAppointment={this.props.newAppointment}
						goToStep={this.goToStep}
						isZeroPriceConsultation={this.isZeroPriceConsultation}
						ref={'PaymentStage'}
						PaymentStageChange={this.PaymentStageChange}
						setNonzerobookbtnDisbled={this.setNonzerobookbtnDisbled}
						setPayor={this.setPayor}
						calculatePrice={this.calculatePrice}
						couponCodeSuccess={this.props.couponCodeSuccess}
						resetCouponCodeDetails={
							this.props.resetCouponCodeDetails
						}
						selectedconsultation={this.selectedconsultation}
						renderSwitch={renderSwitch}
						renderAddressSection={this.renderAddressSection}
						addresses={get(this, 'state.addresses', null) || []}
						addressErrors={
							get(this, 'state.addressErrors', null) || []
						}
						selectedAddressIdx={get(
							this,
							'state.selectedAddressIdx',
							null
						)}
					/>
				);
			default:
				return <div>Error, unrecognized state.</div>;
		}
	}

	getProviderImage(obj, snackbarBool) {
		const imageClass = snackbarBool
			? 'patient-imgbox patient-imgbox-no-border'
			: 'patient-imgbox';
		let providerImage = null;
		const providerName = get(obj, 'visit_provider', '');
		const mappedImage = providers_images[providerName];
		if (providerName && mappedImage) {
			providerImage = <img src={mappedImage} alt='' />;
		} else if (providerName != '')
			providerImage = <img src={imageDefault} alt='' />;
		else providerImage = null;
		return (
			providerImage && <span className={imageClass}>{providerImage}</span>
		);
	}

	getProviderName(obj, snackbarBool) {
		let result = '';
		let providerName = '';
		let separator = ' ';
		const enums = get(this, ['props', 'visitProviders'], []);
		const enum_value = _.find(enums, function (o) {
			return o.key == get(obj, 'visit_provider', '--');
		});
		if (enum_value) {
			providerName = get(enum_value, 'display_name', '');
		} else {
			separator = '_';
			providerName = this.ucFirstAllWords(
				get(obj, 'visit_provider', ''),
				separator
			);
		}

		if (providerName.length > 0) {
			const ProviderNameobj = providerName.split(separator);
			const first_name = get(ProviderNameobj, 0, '');
			const middle_name =
				providerName.length > 2
					? ' ' + get(ProviderNameobj, 1, '')
					: '';
			const last_name =
				providerName.length > 2
					? ' ' + get(ProviderNameobj, 2, '')
					: ' ' + get(ProviderNameobj, 1, '');
			result = first_name.charAt(0) + '.' + middle_name + last_name;
			return (
				<span
					className='provider-name'
					style={{
						color: snackbarBool ? 'white' : '#262837',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{this.getShortText(result, 12)}
				</span>
			);
		} else {
			return dash;
		}
	}

	getShortText = (text, length) => {
		if (text && !_.isEmpty(text) && text.length > length) {
			return (
				<Tooltip
					name='name_tooltip'
					className='name_tooltip'
					disableFocusListener
					disableTouchListener
					title={text}
					placement='top'
					content={text}
				>
					<span>{text.substring(0, length) + '...'}</span>
				</Tooltip>
			);
		} else {
			return text;
		}
	};

	ucFirstAllWords(str, separator = ' ') {
		const pieces = str.split(separator);
		for (let i = 0; i < pieces.length; i++) {
			const j = pieces[i].charAt(0).toUpperCase();
			pieces[i] = j + pieces[i].substr(1).toLowerCase();
		}
		return pieces.join(separator);
	}

	getTimestamp(encounter, timeslotObj) {
		const date1 = moment.tz(
			moment(get(encounter, 'date_of_service', '')).format(
				'YYYY-MM-DD HH:mm:ss'
			),
			'UTC'
		);
		const date_time_zone = moment
			.tz(date1, get(encounter, ['timezone'], ''))
			.format('dddd, MMM D');
		const timezoneLocale =
			get(this, 'props.newAppointment.timezone', '') ||
			get(encounter, ['timezone'], '');
		const timezoneDate = moment.tz(timezoneLocale);
		if (get(timeslotObj, 'start', '') && get(timeslotObj, 'end', '')) {
			const start = get(timeslotObj, 'start', '');
			const end = get(timeslotObj, 'end', '');
			return `${date_time_zone}, ${moment(start).format(
				'h:mm A'
			)} - ${moment(end).format('h:mm A')} ${timezoneDate.format(' z')}`;
		} else {
			return `${date_time_zone}, ${UTCToCurrentTimeView(
				moment,
				moment(get(encounter, 'date_of_service', '')),
				get(encounter, ['timezone'], '')
			)}`;
		}
	}

	getModalityIcon(encounter, fromSnackbar) {
		const modality = get(encounter, 'tm_platform', '') || '';
		let component;
		switch (modality.toLowerCase()) {
			case 'phone':
				if (fromSnackbar) {
					component = (
						<img
							src={phoneicon}
							className='appointmentCreateSnackbarPhone'
						/>
					);
					return (
						<span className='appointmentCreateSnackbarPhone'>
							{component}
						</span>
					);
				} else {
					component = <img src={phoneicon} />;
				}
				break;
			case 'video':
			case 'zoom':
				component = <img src={zoomIcon} />;
				break;
			default:
				component = (
					<div
						style={{
							height: '18px',
							width: '18px',
							backgroundColor: '#edeff3',
							borderRadius: '3px',
						}}
					/>
				);
		}
		return <span>{component}</span>;
	}

	setFirstStageSubmitAttempt() {
		return new Promise((resolve) => {
			this.setState({ firstStageSubmitAttempted: true }, resolve);
		});
	}

	renderAddressSection() {
		const addressData = get(this, 'state.addresses', null) || [];
		const currentTypes = getExistingAddressTypes(addressData);
		const hasShippingNotHome =
			currentTypes.includes('delivery') && !currentTypes.includes('home');
		const hasHomeNotShipping =
			currentTypes.includes('home') && !currentTypes.includes('delivery');
		const hasBoth =
			currentTypes.includes('home') && currentTypes.includes('delivery');
		const hasNeither =
			!currentTypes.includes('home') &&
			!currentTypes.includes('delivery');
		const hasBilling = currentTypes.includes('billing');
		const titleAddition = hasShippingNotHome
			? 'Home '
			: hasHomeNotShipping
			? 'Shipping '
			: hasBoth
			? 'Another '
			: '';
		const showAddressCompletionWarning = get(
			this,
			'state.addressCompletionWarning',
			false
		);
		const addressCompletionWarningText =
			this.getAddressCompletionWarningString();
		const firstStage = get(this, 'state.currentIdx', null) === 0;
		const thirdStage = get(this, 'state.currentIdx', null) === 2;
		const addAnotherCondition = firstStage ? !hasBoth : !hasBilling;
		const addAnotherThirdStage = !hasBilling
			? 'Billing '
			: get(addressData, 'length', 0)
			? 'Another '
			: '';
		const selectedAddressIdx = get(this, 'state.selectedAddressIdx', null);
		const addressErrors = get(this, 'state.addressErrors', null) || [];
		const firstStageSubmitAttempted = get(
			this,
			'state.firstStageSubmitAttempted',
			false
		);
		const nonEditInitialAddressData =
			firstStage && hasNeither ? { type: ['home', 'delivery'] } : null;
		return (
			<>
				{Array.isArray(addressData) &&
					addressData.map((address, i) => {
						return (
							<AddressCard
								address={address}
								variant={'main'}
								action={firstStage ? 'edit' : 'select'}
								actionFunc={
									firstStage
										? () => this.openAddressModal(true, i)
										: () => this.selectAddress(i)
								}
								isSelected={
									thirdStage
										? selectedAddressIdx === i
										: false
								}
								key={get(address, 'address_line1', null)}
								threeDotMenu={thirdStage ? true : false}
								threeDotMenuOptions={[
									{
										label: 'Edit Address',
										click: () =>
											this.openAddressModal(true, i),
									},
								]}
								addressErrors={get(
									addressErrors,
									`[${i}]`,
									null
								)}
							/>
						);
					})}
				{addAnotherCondition && (
					<ProviderAddAnother
						title={`Add ${firstStage ? titleAddition : ''}${
							thirdStage ? addAnotherThirdStage : ''
						}Address`}
						click={() =>
							this.openAddressModal(
								false,
								null,
								nonEditInitialAddressData
							)
						}
					/>
				)}
				{firstStage &&
					showAddressCompletionWarning &&
					firstStageSubmitAttempted && (
						<WarningMessageBox
							warningMsg={addressCompletionWarningText}
							warningIcon={alertIcon}
							boxBGColor={'bg-red'}
							boxCustomStyling={{ marginTop: '4px' }}
							extndWarnText={'shippingAddressWarningText'}
							extndWarnIcon={'blackWarningAlertIcon'}
						/>
					)}
			</>
		);
	}

	getAddressCompletionWarningString() {
		const addressData = get(this, 'state.addresses', null) || [];
		const currentTypes = getExistingAddressTypes(addressData);
		const needBoth =
			'Home and Shipping addresses are required. Please enter them in order to continue.';
		const needHome =
			'Home Address is required. Please enter it or select existing address as Home as well in order to continue.';
		const needShipping =
			'Shipping Address is required. Please enter it or select existing address as Shipping as well in order to continue.';
		if (Array.isArray(currentTypes)) {
			const hasHome = currentTypes.includes('home');
			const hasShipping = currentTypes.includes('delivery');
			if (hasHome && hasShipping) return needBoth;
			else if (hasHome && !hasShipping) return needShipping;
			else if (hasShipping && !hasHome) return needHome;
			else return needBoth;
		} else {
			return needBoth;
		}
	}

	selectAddress(i) {
		this.setState({ selectedAddressIdx: i }, this.PaymentStageChange);
	}

	selectedAddressIsNotBilling() {
		const selectedAddressIdx = get(this, 'state.selectedAddressIdx', null);
		const addresses = get(this, 'state.addresses', null) || [];
		if (
			typeof selectedAddressIdx === 'number' &&
			Array.isArray(addresses)
		) {
			const selectedAddress =
				get(addresses, `[${selectedAddressIdx}]`, null) || {};
			return !singleAddressIsBilling(selectedAddress);
		} else return false;
	}

	hasNoBillingAddress() {
		const addresses = get(this, 'state.addresses', null) || [];
		if (Array.isArray(addresses)) {
			return !getExistingAddressTypes(addresses).includes('billing');
		} else return false;
	}

	selectedAddressIsNotBillingAndNoBillingAddress() {
		return this.selectedAddressIsNotBilling() && this.hasNoBillingAddress();
	}

	getSelectedAddressId() {
		const addresses = get(this, 'state.addresses', null) || [];
		const selectedAddressIdx = get(this, 'state.selectedAddressIdx', null);
		return get(
			addresses,
			`[${selectedAddressIdx}].user_addresses_id`,
			null
		);
	}

	setAddressErrors(errors) {
		const addresses = get(this, 'state.addresses', null) || [];
		if (errors && Array.isArray(addresses)) {
			const addressErrors = mapAddressErrors(addresses, errors);
			if (addressErrors) this.setState({ addressErrors });
		}
	}

	render() {
		const loaderClass = this.state.loading
			? 'schedule-aptmnt-loader schedule-aptmnt-loader-border-radius'
			: 'schedule-aptmnt-loader';
		const loading =
			this.state.loading ||
			get(this, 'state.intermediateSpinner', false) ||
			get(this, 'state.sendingPaymentStageAddressRequest', false);
		return (
			<>
				<div className='scheduleAppointmentContainer'>
					<AppointmentErrorDialog
						showErrorDialog={this.state.showErrorDialog}
						errorCloseHandler={this.errorCloseHandler}
						errorTitle={'Appointment Scheduling Failed'}
						hideErrorDialog={this.hideErrorDialog}
						initialErrorMessage={
							'We were unable to schedule this appointment due to '
						}
						messages={_.get(this, ['state', 'errormessages'], [])}
					/>
					<Loading loading={loading} className={loaderClass}>
						<div className='schedule-appointment-wrapper'>
							<div className='scheduleAppointmentMainContainer'>
								<div className='scheduleAppointmentNavRow'>
									<ScheduleWizardSteps
										activeItem={this.state.currentIdx}
										goToStep={this.goToStep}
										isZeroPriceConsultation={
											this.isZeroPriceConsultation
										}
									/>
								</div>
								<div className='scheduleAppointmentContentContainer '>
									{this.renderCurrentStage()}
								</div>
							</div>
							<>
								<ScheduleAppointmentSidebar
									selectedAppointment={
										this.state.selectedAppointment
									}
									encountersList={this.props.encountersList}
									encountersLoading={
										this.props.encountersLoading
									}
									encountersCompleteLoading={
										this.props.encountersCompleteLoading
									}
									encountersCancelLoading={
										this.props.encountersCancelLoading
									}
									visitProviders={_.get(
										this,
										['props', 'visitProviders'],
										[]
									)}
									couponCodeSuccess={
										this.props.couponCodeSuccess
									}
									getApplyCouponCodeDetails={
										this.props.getApplyCouponCodeDetails
									}
									referralPrograms={
										this.props.referralPrograms
									}
									resetCouponCodeDetails={
										this.props.resetCouponCodeDetails
									}
									selectedconsultation={
										this.selectedconsultation
									}
									selectedProvider={this.selectedProvider}
									newAppointment={this.props.newAppointment}
									Scheduling={this.props.Scheduling}
									loadingCouponCodeDetail={
										this.props.loadingCouponCodeDetail
									}
									isZeroPriceConsultation={
										this.isZeroPriceConsultation
									}
									couponCodeWarning={
										this.props.couponCodeWarning
									}
									couponCodeError={this.props.couponCodeError}
									getProviderImage={this.getProviderImage}
									getProviderName={this.getProviderName}
									getShortText={this.getShortText}
									ucFirstAllWords={this.ucFirstAllWords}
									getTimestamp={this.getTimestamp}
									getModalityIcon={this.getModalityIcon}
									ZeroFeeBookAppointment={
										this.ZeroFeeBookAppointment
									}
									NonZeroFeeBookAppointment={
										this.NonZeroFeeBookAppointment
									}
									activeItem={this.state.currentIdx}
									nonzerobookbtnenabled={
										this.state.nonzerobookbtnenabled
									}
									payor={this.state.payor}
									specialties={this.props.specialties}
									getServiceProvideImages={
										this.getServiceProvideImages
									}
									calculatePrice={this.calculatePrice}
									determineEmailStatus={determineEmailStatus}
								/>
							</>
						</div>
						{get(this, 'state.showAddressModal', false) && (
							<AddressModal
								edit={get(
									this,
									'state.addressModalEdit',
									false
								)}
								idx={get(this, 'state.addressModalIdx', null)}
								closeAddressModal={this.closeAddressModal}
								handleAddressModalSave={
									this.handleAddressModalSave
								}
								addressData={
									get(this, 'state.addresses', null) || []
								}
								addressErrors={
									get(this, 'state.addressErrors', null) || []
								}
								nonEditInitialAddressData={
									get(
										this,
										'state.nonEditInitialAddressData',
										null
									) || {}
								}
							/>
						)}
					</Loading>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		patientSearchResults: state.patients.patients,
		patientSearchResultsLoading: state.patients.loading,
		patientDetail: state.patientdetail.patientdetail,
		gettingPatientDetail: state.patientdetail.gettingPatientDetail,
		patientDetailError: state.patientdetail.patientdetailError,
		updatingAddress: state.editpatient.updatingAddress,
		updatedAddressError: state.editpatient.updatedAddressError,
		consenttotreat: _.get(
			state,
			['getConsenttotreat', 'consenttotreat', 'consent_to_treat'],
			[]
		),
		referralPrograms: _.get(
			state,
			['referralPrograms', 'referralPrograms'],
			[]
		),
		Scheduling: state.Scheduling,
		encountersList: state.encounterlist.encounter,
		encountersLoading: _.get(state, 'encounterlist.loading', false),
		encountersCompleteLoading: state.editencounter.encounterCompleteLoading,
		encountersCancelLoading: state.editencounter.encounterCancelLoading,
		newAppointment: state.Scheduling.newAppointment,
		modalities: _.get(
			state,
			['visitsschema', 'schema', 'data', 'tm_platform'],
			[]
		),
		visitProviders: _.get(
			state,
			['visitsschema', 'schema', 'data', 'visit_provider'],
			[]
		),
		specialties: _.get(
			state,
			['visitsschema', 'schema', 'data', 'vsee_specialty'],
			[]
		),
		PartnerDetail: state.Scheduling.partnerDetail,
		couponCodeSuccess: _.get(
			state,
			['Scheduling', 'couponCodeSuccess'],
			''
		),
		couponCodeWarning: _.get(
			state,
			['Scheduling', 'couponCodeWarning'],
			''
		),
		couponCodeError: _.get(state, ['Scheduling', 'couponCodeError'], ''),
		loadingCouponCodeDetail: _.get(
			state,
			['Scheduling', 'loadingCouponCodeDetail'],
			''
		),
		getProviderImage: props.getProviderImage,
		getProviderName: props.getProviderName,
		getShortText: props.getShortText,
		ucFirstAllWords: props.ucFirstAllWords,
		getTimestamp: props.getTimestamp,
		insuranceCompanies: _.get(
			state,
			['insuranceCompanies', 'insuranceCompanies'],
			[]
		),
		planTypesEnums: _.get(state, ['enums', 'planTypes', 'data'], []),
		defaultSchedulingOptions: {
			payload:
				state.referralProgramsList.defaultSchedulingOptions.payload,
			error: state.referralProgramsList.defaultSchedulingOptions.error,
		},
	};
};

const mapDispatchToProps = (dispatch) => ({
	getPatientsSearch: (data) => dispatch(getpatients(data)),
	getPatientDetail: (pid, flag) => dispatch(patientdetail(pid, flag)),
	getConsenttotreat: () => dispatch(getConsenttotreat()),
	getRefPrograms: () => dispatch(getReferralPrograms()),
	LoadAppointmentSlot: (data) => dispatch(LoadAppointmentSlots(data)),
	getPatientEncouters: (pid) => dispatch(getencounters(pid)),
	updateOutreach: (data) => dispatch(updateOutreach(data)),
	updateAddressPayment: (data) => dispatch(updateAddress(data)),
	updatePatientDetail: (data) => dispatch(UpdatePatientDetail(data)),
	getAppointmentProvider: (user_id) =>
		dispatch(LoadAppointmentProvider(user_id)),
	updateAppointmentparams: (data) => dispatch(SETAPPOINTMENTPARAMS(data)),
	getDefaultSchedulingOptions: (data) =>
		dispatch(getDefaultSchedulingOptions(data)),
	toggleAppointmentPatient: (data) =>
		dispatch(TOGGLEAPPOINTMENTPATIENT(data)),
	setAppointmentPatient: (data) => dispatch(SetAppointmentPatient(data)),
	getVisitSchema: (params) => dispatch(getencountervisitsschema(params)),
	getAppointmentPartnerDetailbyId: (partner_id) =>
		dispatch(LoadAppointmentPartnerDetailbyId(partner_id)),
	getApplyCouponCodeDetails: (patient_uuid, data) =>
		dispatch(ApplyCouponCode(patient_uuid, data)),
	resetCouponCodeDetails: () => dispatch(ResetCouponCodeDetails()),
	clearSelectedSlot: () => dispatch(ClearSelectedSlot()),
	setModality: (modality) => dispatch(SetModality(modality)),
	resetNewAppointment: () => dispatch(ResetNewAppointment()),
	filterChangeEvent: () => dispatch(FilterChangeEvent()),
	resetPatientEncounters: () => dispatch(resetencounters()),
	getPlanTypesEnums: () => dispatch(getPlanTypes()),
	dispatchfunc: dispatch,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ScheduleAppointment);
