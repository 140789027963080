//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//UI Libraries
import { Checkbox } from 'gm-element-react';

//Components
import CommonCheckbox from './../../Common/Checkbox.js';

class ICDSearchResultRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		const icdCode = get(this, 'props.icdCode', {}) || {};
		const codeUuid = get(icdCode, 'uuid', null);
		const selected = get(this, 'props.isSelected', false);
		const codeIsTextEnabled = get(icdCode, 'textEnabled', false);
		const codeIsDisabled = get(icdCode, 'disabled', null);
		const checkboxClass = selected
			? 'chargeICDSearchResultRowCheckbox chargeICDSearchResultRowCheckboxSelected'
			: 'chargeICDSearchResultRowCheckbox';
		const textClass = selected
			? ' chargeICDSearchResultRowSelectedText'
			: '';
		const localSearchKey =
			get(this, 'props.localSearchKey', null) || 'code_name';
		const noDescription = get(this, 'props.noDescription', false);
		const filter = get(this, 'props.filter', null);
		const altCheckbox = get(this, 'props.altCheckbox', null);
		const altCheckboxSelection =
			get(this, 'props.altCheckboxSelection', null) || [];
		const freeText = get(this, 'props.freeText', null) || '';
		const rowStyling = get(this, 'props.rowStyling', null) || {};
		if (filter) rowStyling.minHeight = '53px';
		const reverseVariant = get(this, 'props.reverseVariant', false);
		let containerClass = 'chargeICDSearchResultRow';
		if (reverseVariant)
			containerClass += ' chargeICDSearchResultRowReverse';
		if (altCheckbox)
			containerClass += ' chargeICDSearchResultRowAltCheckbox';
		if (!!selected && codeIsTextEnabled)
			containerClass += ' chargeICDSearchResultRowTextEnabled';
		if (codeIsDisabled)
			containerClass += ' chargeICDSearchResultRowDisabled';
		return (
			<div
				className={containerClass}
				ref={(el) => (this.row = el)}
				style={{ ...rowStyling }}
			>
				{altCheckbox &&
				Array.isArray(altCheckbox) &&
				Array.isArray(altCheckboxSelection) ? (
					<div className='chargeICDSearchResultRowAltCheckboxContainer'>
						{altCheckbox.map((checkbox) => {
							const internalValue = get(
								checkbox,
								'internal',
								null
							);
							const checked =
								altCheckboxSelection.includes(internalValue);
							return (
								<div
									className='simpleCheckboxOption'
									key={internalValue}
								>
									<CommonCheckbox
										checked={checked}
										onChangeFunc={(e) =>
											this.props.toggleCodeSelectionAltCheckbox(
												e,
												internalValue,
												codeUuid,
												icdCode
											)
										}
										topOverride={-1}
										marginLeftOverride={0}
										marginRightOverride={11}
										marginBottomOverride={0}
										disabled={codeIsDisabled}
									/>
									<div
										className='simpleCheckboxOptionLabel'
										style={{
											color: checked
												? '#7b26c9'
												: '#262837',
										}}
									>
										{get(checkbox, 'external', null) ||
											'--'}
									</div>
								</div>
							);
						})}
					</div>
				) : (
					<CommonCheckbox
						className={checkboxClass}
						checked={this.props.isSelected}
						onChangeFunc={this.props.toggleCodeSelection}
						additionalOnChangeArgs={[codeUuid, icdCode]}
						disabled={codeIsDisabled}
					/>
				)}
				<div className={'chargeICDSearchResultRowTitle' + textClass}>
					{get(icdCode, localSearchKey, '--')}
				</div>
				{!!selected && codeIsTextEnabled && (
					<input
						className='inputFieldText chargeICDSearchResultRowInput'
						value={freeText}
						onChange={(e) =>
							this.props.handleCodeFreeTextChange(
								e,
								codeUuid,
								icdCode
							)
						}
					/>
				)}
				{!noDescription && (
					<div
						className={
							'chargeICDSearchResultRowDescription' + textClass
						}
					>
						{get(icdCode, 'code_desc', '--')}
					</div>
				)}
			</div>
		);
	}
}

export default ICDSearchResultRow;
