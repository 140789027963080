import * as actionTypes from './actionTypes';

import InsuranceCompanyService from '../service/InsuranceCompany';

export const startGetInsuranceCompanyDispatch = () => {
	return {
		type: actionTypes.LOADING_GET_INSURANCE_COMPANIES,
	};
};

export const successGetInsuranceCompanyDispatch = (data) => {
	return {
		type: actionTypes.LOADED_GET_INSURANCE_COMPANIES,
		payload: data,
	};
};

export const faliureGetInsuranceCompanyDispatch = (error) => {
	return {
		type: actionTypes.ERROR_GET_INSURANCE_COMPANIES,
		payload: error,
	};
};

export const resetGetInsuranceCompanyDispatch = () => {
	return {
		type: actionTypes.LOADED_GET_INSURANCE_COMPANIES,
		payload: null,
	};
};

export function createInsuranceCompanyInfo(data) {
	return (dispatch) => {
		dispatch(creatingInsuranceCompany());
		InsuranceCompanyService.addInsuranceCompanyInfo(data)
			.then((res) => {
				dispatch(createdInsuranceCompany(res));
			})
			.catch((err) => {
				dispatch(createdInsuranceCompanyError(err));
			});
	};
}

export const creatingInsuranceCompany = () => {
	return {
		type: actionTypes.CREATING_INSURANCE_COMPANY,
		payload: true,
	};
};

export const createdInsuranceCompany = (res) => {
	return {
		type: actionTypes.CREATED_INSURANCE_COMPANY,
		payload: res,
	};
};

export const createdInsuranceCompanyError = (err) => {
	return {
		type: actionTypes.CREATED_INSURANCE_COMPANY_ERROR,
		payload: err,
	};
};

export function updateInsuranceCompanyInfo(data, companyId) {
	return (dispatch) => {
		dispatch(updatingInsuranceCompany());
		InsuranceCompanyService.updateInsuranceCompanyInfo(data, companyId)
			.then((res) => {
				dispatch(updatedInsuranceCompany(res));
			})
			.catch((err) => {
				dispatch(updatedInsuranceCompanyError(err));
			});
	};
}

export const updatingInsuranceCompany = () => {
	return {
		type: actionTypes.UPDATING_INSURANCE_COMPANY,
		payload: true,
	};
};

export const updatedInsuranceCompany = (res) => {
	return {
		type: actionTypes.UPDATED_INSURANCE_COMPANY,
		payload: res,
	};
};

export const updatedInsuranceCompanyError = (err) => {
	return {
		type: actionTypes.UPDATED_INSURANCE_COMPANY_ERROR,
		payload: err,
	};
};

export function getInsuranceCompanyInfo(data) {
	return (dispatch) => {
		dispatch(startGetInsuranceCompanyDispatch());
		InsuranceCompanyService.getInsuranceCompanyInfo(data)
			.then((res) => {
				dispatch(successGetInsuranceCompanyDispatch(res));
			})
			.catch((error) => {
				dispatch(faliureGetInsuranceCompanyDispatch(error));
			});
	};
}
