//React & Redux
import React, { Component, Fragment, useState } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import {
	createEnumsOptions,
	createStateEnumsOptions,
	isValidDateTimeString,
	isValidDateStringOREmpty,
} from '../Common/utilsfunctions';
import {
	UTCToCurrent,
	GetZoneDatetime,
	states_list,
} from '../../../../../utils';
import { isEmpty } from '../../../../../Utils/validate';

//Other Libraries
import moment from 'moment';
import 'moment-timezone';

//UI Libraries
import { Button, Loading } from 'gm-element-react';

//Components
import { EncounterInputField } from '../Common/EncounterInputField';
import { EncounterSelectField } from '../Common/EncounterSelectField';
import { EncounterDateField } from '../Common/EncounterDateField';
import { EncounterMultiSelectField } from '../Common/EncounterMultiSelectField';
import { EncounterDateTimeField } from '../Common/EncounterDateTimeField';

const datefields = {
	followup_cap_completed_date: 1,
	cap_sent_date_pcp: 1,
};

const datetimefields = {
	date_of_service: 1,
};

const EditVisitDetailSegment = (props) => {
	const { encounter, cancel, saveCCIntakeEncounter, ccintakeschema } = props;

	const tempFormData = {
		scheduling_poc: _.get(encounter, ['scheduling_poc'], ''),
		date_of_service:
			_.get(encounter, ['date_of_service', 'length'], 0) > 0
				? UTCToCurrent(
						moment,
						moment.tz(
							_.get(encounter, ['date_of_service'], ''),
							'UTC'
						)
				  ).format('YYYY-MM-DD HH:mm:ss')
				: '',
		timezone: moment.tz.guess(),
		relationship_to_patient: _.get(
			encounter,
			['relationship_to_patient'],
			''
		),
		tm_platform: _.get(encounter, ['tm_platform'], ''),
		visit_provider: _.get(encounter, ['visit_provider'], ''),
		visit_status: _.get(encounter, ['visit_status'], ''),
		patient_status: _.get(encounter, ['patient_status'], ''),
		results_requested_by_pcp: _.get(
			encounter,
			['results_requested_by_pcp'],
			''
		),
		followup_cap_completed_date: _.get(
			encounter,
			['followup_cap_completed_date'],
			''
		),
		results_requested_by_patient: _.get(
			encounter,
			['results_requested_by_patient'],
			''
		),
		cap_sent_date_pcp: _.get(encounter, ['cap_sent_date_pcp'], ''),
		cap_release_method_pcp: _.get(
			encounter,
			['cap_release_method_pcp'],
			[]
		),
		state_of_visit: _.get(encounter, ['state_of_visit'], ''),
	};

	const [formData, setFormData] = useState(tempFormData);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	const validateForm = (formdata) => {
		let errors = {};
		const dateerors = _.omitBy(
			_.mapValues(datefields, function (value, key) {
				if (!isValidDateStringOREmpty(_.get(formdata, key, ''))) {
					return 'Invalid date';
				}
				return undefined;
			}),
			_.isUndefined
		);

		const datetimeerors = _.omitBy(
			_.mapValues(datetimefields, function (value, key) {
				if (!isValidDateTimeString(_.get(formdata, key, ''))) {
					return 'Invalid date and time';
				}
				return undefined;
			}),
			_.isUndefined
		);
		errors = { ...dateerors, ...datetimeerors };

		if (isEmpty(_.get(formdata, 'visit_provider', ''))) {
			errors['visit_provider'] = 'Visit Provider is required';
		}

		if (isEmpty(_.get(formdata, ['timezone']))) {
			errors['timezone'] = 'Timezone is required';
		}

		if (!isValidDateTimeString(_.get(formdata, ['date_of_service']))) {
			errors['date_of_service'] = 'Invalid Date Time';
		}
		if (isEmpty(_.get(formdata, ['date_of_service']))) {
			errors['date_of_service'] = 'date of service is required';
		}
		if (isEmpty(_.get(formdata, ['visit_provider']))) {
			errors['visit_provider'] = 'visit provider is required';
		}
		return errors;
	};

	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		let stateErrors = {};
		stateData[key] = value;
		if (!(key in datefields) && !(key in datetimefields)) {
			stateErrors = validateForm(stateData);
		}
		setErrors(stateErrors);
		setFormData(stateData);
	};
	const handleDateTimeFieldValidate = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		const errors = validateForm(stateData);
		setErrors(errors);
		setFormData(stateData);
	};
	const handleDateFieldValidate = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		const errors = validateForm(stateData);
		setErrors(errors);
		setFormData(stateData);
	};
	const saveForm = () => {
		const errors = validateForm(formData);
		if (_.isEmpty(errors)) {
			const updatedformdata = _.mapValues(
				formData,
				function (value, key) {
					if (key == 'date_of_service') {
						if (
							moment(value, 'YYYY-MM-DD HH:mm:ss', true).isValid()
						) {
							const momentdos = moment(
								value,
								'YYYY-MM-DD HH:mm:ss',
								true
							);
							const timezone = formData['timezone'];
							return GetZoneDatetime(moment, momentdos, timezone);
						} else {
							/* ideally this should never execute bt safer side if happends the dos should not changed.*/
							return _.get(encounter, ['date_of_service'], '');
						}
					}

					if (key in datefields && _.isNil(value)) {
						return '';
					}

					if (value == 'empty') return '';
					return value;
				}
			);
			delete updatedformdata['timezone'];
			setLoading(true);
			saveCCIntakeEncounter(encounter, updatedformdata)
				.then((result) => {
					setTimeout(() => {
						setLoading(false);
						cancel();
					}, 1000);
				})
				.catch(() => {
					setLoading(false);
				});
		} else {
			setErrors(errors);
		}
	};

	return (
		<Fragment>
			<Loading loading={loading}>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Scheduling POC
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Date & Time Of Service
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Timezone
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Relationship To Patient
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Visit State
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterInputField
									value={_.get(
										formData,
										['scheduling_poc'],
										''
									)}
									name='scheduling_poc'
									onChange={(value) =>
										onFormChange('scheduling_poc', value)
									}
									placeholder='John Doe'
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateTimeField
									value={_.get(
										formData,
										['date_of_service'],
										''
									)}
									name='date_of_service'
									disabled={false}
									placeholder='Date'
									error={_.get(
										errors,
										['date_of_service'],
										''
									)}
									onChange={(value) =>
										onFormChange('date_of_service', value)
									}
									onBlur={(value) =>
										handleDateTimeFieldValidate(
											'date_of_service',
											value
										)
									}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(formData, ['timezone'], '')}
									name='timezone'
									placeholder={moment.tz.guess()}
									options={createEnumsOptions(
										props,
										['timezoneenums'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('timezone', value)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['relationship_to_patient'],
										''
									)}
									name='relationship_to_patient'
									options={createEnumsOptions(
										ccintakeschema,
										['relationship_to_patient'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'relationship_to_patient',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['state_of_visit'],
										''
									)}
									name='state_of_visit'
									options={createStateEnumsOptions(
										states_list,
										'key',
										'value',
										'key'
									)}
									onChange={(value) =>
										onFormChange('state_of_visit', value)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								TM Platform
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Consultation Type
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Visit Provider
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Visit Status
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Patient Status
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(formData, ['tm_platform'], '')}
									name='tm_platform'
									options={createEnumsOptions(
										props,
										['ccintakeschema', 'tm_platform'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('tm_platform', value)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										encounter,
										['consultation_type'],
										''
									)}
									name='consultation_type'
									disabled={true}
									options={[
										{
											key: _.get(
												encounter,
												['consultation_type'],
												''
											),
											label: _.get(
												encounter,
												['consultation_type'],
												''
											),
											value: _.get(
												encounter,
												['consultation_type'],
												''
											),
										},
									]}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['visit_provider'],
										''
									)}
									name='visit_provider'
									options={createEnumsOptions(
										props,
										['visit_providerenums'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('visit_provider', value)
									}
									error={_.get(
										errors,
										['visit_provider'],
										''
									)}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['visit_status'],
										''
									)}
									name='visit_status'
									options={createEnumsOptions(
										ccintakeschema,
										['visit_status'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('visit_status', value)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										encounter,
										['patient_status'],
										''
									)}
									name='patient_status'
									options={createEnumsOptions(
										ccintakeschema,
										['patient_status'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('patient_status', value)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Results Requested By PCP
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Results Requested By Patient
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										encounter,
										['results_requested_by_pcp'],
										''
									)}
									name='results_requested_by_pcp'
									options={createEnumsOptions(
										ccintakeschema,
										['results_requested_by_pcp'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'results_requested_by_pcp',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										encounter,
										['results_requested_by_patient'],
										''
									)}
									name='results_requested_by_patient'
									options={createEnumsOptions(
										ccintakeschema,
										['results_requested_by_patient'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'results_requested_by_patient',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Result CAP Completed Date
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-field-header'>
								Result CAP Sent To Patient On
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-field-header'>
								Result CAP Sent To PCP On
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterDateField
									value={_.get(
										formData,
										['followup_cap_completed_date'],
										''
									)}
									name='followup_cap_completed_date'
									error={_.get(
										errors,
										['followup_cap_completed_date'],
										''
									)}
									onChange={(value) =>
										onFormChange(
											'followup_cap_completed_date',
											value
										)
									}
									onBlur={(value) =>
										handleDateFieldValidate(
											'followup_cap_completed_date',
											value
										)
									}
								/>
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-multi-cols-section'>
								<div className='encounter-input-section multi-first-col'>
									<EncounterDateField
										value={_.get(
											encounter,
											['cap_sent_to_patient'],
											''
										)}
										name='cap_sent_to_patient'
										disabled={true}
									/>
								</div>
								<div className='via_seprator'> via </div>
								<div className='encounter-input-section multi-two-col'>
									<EncounterMultiSelectField
										value={_.get(
											encounter,
											['cap_release_method_patient'],
											''
										)}
										name='cap_release_method_patient'
										options={createEnumsOptions(
											ccintakeschema,
											['cap_release_method_patient'],
											'key',
											'display_name',
											'key'
										)}
										extraclassName='multi-selectfield-disable'
										disabled={true}
									/>
								</div>
							</div>
						</div>
						<div className='segment-2cols'>
							<div className='encounter-multi-cols-section'>
								<div className='encounter-input-section multi-first-col'>
									<EncounterDateField
										value={_.get(
											encounter,
											['cap_sent_date_pcp'],
											''
										)}
										name='cap_sent_date_pcp'
										disabled={true}
									/>
								</div>
								<div className='via_seprator'> via </div>
								<div className='encounter-input-section multi-two-col'>
									<EncounterMultiSelectField
										value={_.get(
											encounter,
											['cap_release_method_pcp'],
											''
										)}
										name='cap_release_method_pcp'
										options={createEnumsOptions(
											ccintakeschema,
											['cap_release_method_pcp'],
											'key',
											'display_name',
											'key'
										)}
										extraclassName='multi-selectfield-disable'
										disabled={true}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='segment-footer-container'>
					<div className='cancel-btn-link'>
						<Button onClick={() => cancel()}>Cancel</Button>
					</div>
					<div className='save-btn-link'>
						<Button style={{ float: 'right' }} onClick={saveForm}>
							Save
						</Button>
					</div>
				</div>
			</Loading>
		</Fragment>
	);
};

export default EditVisitDetailSegment;
