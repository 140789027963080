//React & Redux
import React from 'react';

//UI Libraries
import { Radio } from 'gm-element-react';

//Styles
import './RadioGroup.css';

const RadioGroup = (props) => {
	const { onChange, options = [], value } = props;

	return options ?? null ? (
		<Radio.Group
			className='gmi-radio-group'
			{...{
				onChange,
				value,
			}}
		>
			{options.map((el) => (
				<Radio
					key={`radio-${el.key}`}
					{...{
						value: el.key,
						onChange,
						disabled: el.disabled || false,
					}}
				>
					{el.display_name}
				</Radio>
			))}
		</Radio.Group>
	) : null;
};

export default RadioGroup;
