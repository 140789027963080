//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';
import moment from 'moment';

//UI Libraries
import { TestClassificationEnums } from './../../utils.js';

class AddTestConfirm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
		this.getLabName = this.getLabName.bind(this);
		this.getFromOtherEnum = this.getFromOtherEnum.bind(this);
		this.getTestType = this.getTestType.bind(this);
		this.getTagName = this.getTagName.bind(this);
	}
	//functions

	getLabName(uuid) {
		if (!uuid) return '--';

		const filteredArr = this.props.labs.filter((el) => {
			return uuid === el.key;
		});

		if (filteredArr[0]) return filteredArr[0].display_name;
		else return '--';
	}

	getFromOtherEnum(internal, enumList) {
		if (!internal) return '--';

		const filteredArr = enumList.filter((el) => {
			return internal === el.key;
		});

		if (filteredArr[0]) return filteredArr[0].display_name;
		else return '--';
	}

	getTestType(uuid) {
		if (!uuid) return '--';

		const filteredArr = this.props.testTypes.filter((el) => {
			return uuid === el.uuid;
		});

		if (filteredArr[0]) return filteredArr[0].display_name;
		else return '--';
	}

	getTagName(uuid) {
		if (!uuid) return '--';

		const filteredArr = this.props.testTags.filter((el) => {
			return uuid === el.uuid;
		});

		if (filteredArr[0]) return filteredArr[0].tag;
		else return '--';
	}

	getClassification(internal, enumList) {
		if (_.isNil(internal)) return '--';

		const filteredArr = enumList.filter((el) => {
			return internal === el.key;
		});

		if (filteredArr[0]) return filteredArr[0].display_name;
		else return '--';
	}

	render() {
		const test = this.props.newTest;
		const editTitle = ' testEditTitle';
		return (
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'wrap',
				}}
			>
				<div className='test_category_headers testCreateHeaders'>
					{' '}
					TEST INFORMATION{' '}
				</div>

				<div
					className={'testInformation'}
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className={'test_field_title' + editTitle}> Lab </div>
					<div className={'test_field_detail'}>
						<div>{this.getLabName(test.lab_uuid)}</div>
					</div>
				</div>

				<div
					className={'testInformation'}
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Display name{' '}
					</div>
					<div className={'test_field_detail'}>
						<div className={' test_information_limited'}>
							{get(test, 'display_name', '--')}
						</div>
					</div>
				</div>

				<div
					className={'testInformation testInformationNewEnd'}
					style={{ width: 'calc(33.3%)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Version{' '}
					</div>
					<div className={'test_field_detail'}>
						<div className={' test_information_limited'}>
							{get(test, 'version', '--')}
						</div>
					</div>
				</div>

				<div
					className={'testInformation'}
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Currently offered{' '}
					</div>
					<div className={'test_field_detail'}>
						<div>
							{this.getFromOtherEnum(
								test.currently_offered,
								this.props.otherEnums.currently_offered
							)}
						</div>
					</div>
				</div>

				<div
					className={'testInformation'}
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Classification{' '}
					</div>
					<div className={'test_field_detail'}>
						<div>
							{this.getClassification(
								test.classification,
								TestClassificationEnums
							)}
						</div>
					</div>
				</div>

				<div
					className={'testInformation'}
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Initial available date{' '}
					</div>
					<div className={'test_field_detail'}>
						<div>
							{test.init_available_date
								? moment(
										test.init_available_date,
										'YYYY-MM-DD'
								  ).format('MMM DD, YYYY')
								: '--'}
						</div>
					</div>
				</div>

				<div
					className={'testInformation testInformationNewEnd'}
					style={{ width: 'calc(33.3%)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Test code (as used by lab){' '}
					</div>
					<div className={'test_field_detail'}>
						<div className='test_information_limited test_information_limitedFullWidth'>
							{test.lab_test_code || ''}
						</div>
					</div>
				</div>

				<div
					className={'testInformation testInformationNewEnd'}
					style={{
						width: 'calc(100%)',
						height: '58px',
						marginBottom: '22px',
					}}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Lab info{' '}
					</div>
					<div className={'test_field_detail'}>
						<div
							className='test_information_limited'
							style={{ maxWidth: 'unset' }}
						>
							{test.lab_info || '--'}
						</div>
					</div>
				</div>

				<div className='test_category_headers testCreateHeaders'>
					{' '}
					ADDITIONAL INFO{' '}
				</div>

				<div
					className={'testInformation'}
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Test type{' '}
					</div>
					<div className={'test_field_detail'}>
						<div>
							{this.getTestType(
								test.genetic_test_types_enums_uuid
							)}
						</div>
					</div>
				</div>

				<div
					className={'testInformation'}
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Confirmation recommended?{' '}
					</div>
					<div className={'test_field_detail'}>
						<div>
							{this.getFromOtherEnum(
								test.clia_confirm_needed,
								this.props.otherEnums.clia_confirm_needed
							)}
						</div>
					</div>
				</div>

				<div
					className={'testInformation testInformationNewEnd'}
					style={{ width: 'calc(33.3%)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Prenatal{' '}
					</div>
					<div className={'test_field_detail'}>
						<div>
							{this.getFromOtherEnum(
								test.prenatal,
								this.props.otherEnums.prenatal
							)}
						</div>
					</div>
				</div>

				<div
					className={'testInformation'}
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Opp. for reinterpretation?{' '}
					</div>
					<div className={'test_field_detail'}>
						<div>
							{this.getFromOtherEnum(
								test.opportunity_for_reinterpretation,
								this.props.otherEnums
									.opportunity_for_reinterpretation
							)}
						</div>
					</div>
				</div>

				<div
					className='testInformation testInformationNewEnd'
					style={{ flexGrow: '1', width: 'calc(66.6%)' }}
				>
					<div className={'test_field_title' + editTitle}> Tags </div>
					<div
						className='test_field_detail'
						style={{
							height: '100%',
							display: 'flex',
							flexWrap: 'wrap',
						}}
					>
						<div
							className='testTagContainer detailView'
							style={{ paddingLeft: '0px', marginTop: '0px' }}
						>
							{get(test, 'tags', []).map((uuid) => {
								return (
									<div className={'testTag'} key={uuid}>
										{this.getTagName(uuid)}
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<div
					className={'testInformation'}
					style={{ width: 'calc(33.3% - 20px)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						NGS vs SNP assay?
					</div>
					<div className={'test_field_detail'}>
						<div>
							{this.getFromOtherEnum(
								test.ngs_vs_snp_assay,
								this.props.otherEnums.ngs_vs_snp_assay
							)}
						</div>
					</div>
				</div>

				<div
					className={'testInformation testInformationNewEnd'}
					style={{ width: 'calc(66.6%)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Global limitations{' '}
					</div>
					<div className={'test_field_detail'}>
						<div>{test.global_limitations || '--'}</div>
					</div>
				</div>

				<div
					className={'testInformation'}
					style={{ width: 'calc(100%)' }}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Gene-specific limitations{' '}
					</div>
					<div
						className={'test_field_detail'}
						style={{ height: 'unset' }}
					>
						<div
							className='test_information_limited test_information_limitedFullWidth'
							style={{
								maxHeight: 'unset',
								overflow: 'unset',
								whiteSpace: 'pre-line',
								textOverflow: 'unset',
							}}
						>
							{test.gene_specific_limitations || '--'}
						</div>
					</div>
				</div>

				<div
					className={'testInformation testInformationNewEnd'}
					style={{
						width: 'calc(100%)',
						height: '68px',
						marginBottom: '10px',
					}}
				>
					<div className={'test_field_title' + editTitle}>
						{' '}
						Comments{' '}
					</div>
					<div className={'test_field_detail'}>
						<div
							className='test_information_limited test_information_limitedFullWidth testInformationComments'
							style={{
								WebkitBoxOrient: 'vertical',
								MozBoxOrient: 'vertical',
							}}
						>
							{test.comments || '--'}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AddTestConfirm;
