//React & Redux
import React from 'react';
import classnames from 'classnames';

//Images
import addInsuranceButton from './images/add.svg';
import editInsuranceButton from './images/edit.svg';

//Lodash
import { isEmpty, isNil, get } from 'lodash';

//Utils
import { dependentVariants } from '../../../utils.js';
import {
	CARE_PATIENTS_NEEDS_INSURANCE_SELECTION,
	CARE_PATIENTS_PATIENT_PAYMENTS_INSURANCE_ACTIONS,
} from '../../../Utils/permissionUtils';

//UI Libraries
import { Loading } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import CTAMenu from './CTAMenu.js';
import RBAC from '../../RBAC';

//Images
import ReactSVG from 'react-svg';
import add from '../../../assets/add.svg';

const dash = (
	<div className='dash' style={{ fontFamily: 'none' }}>
		--
	</div>
);

const InsuranceInfo = (props) => {
	const {
		actionType,
		createAthenaPatientAndInsurance,
		patient,
		patientDetail,
		postAthenaPatientLoad,
		setAction,
		patientInsuranceInfo,
		getPatientInsuranceInfoStart,
		setIsAddPrimaryInsuranceFromForm,
		setIsAddSecondaryInsuranceFromForm,
		setIsAddLabInsuranceFromForm,
	} = props;

	const isInsuranceEmpty = (nsurance) => {
		return _.every(nsurance, (value, key) => {
			let result = false;
			if (key == 'is_policyholder' && value == true) result = true;
			else if (typeof value == 'string') {
				result = _.isEmpty(value);
			} else {
				result = _.isNil(value);
			}
			return result;
		});
	};

	const formatePayloadForInsurance = (payload) => {
		if (isInsuranceEmpty(payload)) {
			return payload;
		}
		return {
			insurance_id: get(payload, 'insurance_id', ''),
			insurance_plan_type: get(payload, 'insurance_company.name', ''),
			insurance_type: get(payload, 'insurance_type', ''),
			is_policyholder: get(payload, 'is_policyholder', ''),
			patient_uuid: get(payload, 'patient_uuid', ''),
			policyholder_biological_sex: get(
				payload,
				'policyholder_biological_sex',
				''
			),
			policyholder_dob: get(payload, 'policyholder_dob', ''),
			policyholder_first_name: get(
				payload,
				'policyholder_first_name',
				''
			),
			policyholder_last_name: get(payload, 'policyholder_last_name', ''),
			policyholder_name: get(payload, 'policyholder_name', ''),
			policyholder_relationship: get(
				payload,
				'policyholder_relationship',
				''
			),
			athena_insurance_id: get(
				payload,
				'insurance.athena_insurance_id',
				''
			),
			athena_insurance_package_id: get(
				payload,
				'insurance.athena_insurance_package_id',
				''
			),
			athena_insurance_package_name: get(
				payload,
				'insurance.athena_insurance_package_name',
				''
			),
			company_name: get(payload, 'insurance.company_name', ''),
			employer: get(payload, 'insurance.employer', ''),
			group_no: get(payload, 'insurance.group_no', ''),
			insurance_address: get(payload, 'insurance.insurance_address', ''),
			insurance_city: get(payload, 'insurance.insurance_city', ''),
			insurance_company_id: get(
				payload,
				'insurance.insurance_company_id',
				''
			),
			insurance_no: get(payload, 'insurance.insurance_no', ''),
			insurance_plan_name: get(
				payload,
				'insurance_plan_type.display_name',
				''
			),
			insurance_state: get(payload, 'insurance.insurance_state', ''),
			insurance_zipcode: get(payload, 'insurance.insurance_zipcode', ''),
			phone: get(payload, 'insurance.phone', ''),
			insurance_company_type: get(payload, 'insurance_company.name', ''),
		};
	};

	const primaryInsurance =
		!isEmpty(patientInsuranceInfo) &&
		!isNil(patientInsuranceInfo) &&
		formatePayloadForInsurance(
			(patientInsuranceInfo || []).filter(
				(el) =>
					el.insurance_type === 'primary' &&
					el.visit_insurance === true &&
					el.lab_insurance === false
			)?.[0] || {}
		);

	const secondaryInsurance =
		!isEmpty(patientInsuranceInfo) &&
		!isNil(patientInsuranceInfo) &&
		formatePayloadForInsurance(
			(patientInsuranceInfo || []).filter(
				(el) =>
					el.insurance_type === 'secondary' &&
					el.visit_insurance === true &&
					el.lab_insurance === false
			)?.[0] || {}
		);

	const labInsurance =
		!isEmpty(patientInsuranceInfo) &&
		!isNil(patientInsuranceInfo) &&
		formatePayloadForInsurance(
			(patientInsuranceInfo || []).filter(
				(el) =>
					el.insurance_type === 'primary' &&
					el.visit_insurance === false &&
					el.lab_insurance === true
			)?.[0] || {}
		);

	const getData = (obj, field) => {
		switch (field) {
			case 'athena_insurance_package_id':
				return obj[field] != null ? obj[field] : dash;
			case 'policyholder_dob':
				return !isEmpty(obj[field] || '')
					? moment(obj[field] || '').format('MMM D, YYYY')
					: dash;
			case 'policyholder_relationship':
				const relationshipObj = dependentVariants.find(
					(a) => a && a.value === (obj[field] || '')
				);
				return (relationshipObj && relationshipObj.label) || dash;
			case 'insurance_plan_name':
				return obj.insurance_plan_name || '';
			case 'athena_insurance_id':
				return isNil(obj[field]) ? dash : obj[field];
			default:
				return !isEmpty(obj[field] || '') ? obj[field] : dash;
		}
	};

	const hasOther = primaryInsurance.insurance_company_type === 'Other';

	let insuranceName = '';
	if (hasOther) {
		insuranceName = 'Other - ' + primaryInsurance.company_name;
	} else {
		insuranceName = primaryInsurance.insurance_company_type;
	}

	const secondaryHasOther =
		secondaryInsurance.insurance_company_type === 'Other';

	let secondaryInsuranceName = '';
	if (secondaryHasOther) {
		secondaryInsuranceName = 'Other - ' + secondaryInsurance.company_name;
	} else {
		secondaryInsuranceName = secondaryInsurance.insurance_company_type;
	}

	const labHasOther = labInsurance.insurance_company_type === 'Other';

	let labInsuranceName = '';
	if (labHasOther) {
		labInsuranceName = 'Other - ' + labInsurance.company_name;
	} else {
		labInsuranceName = labInsurance.insurance_company_type;
	}

	const loading =
		(isEmpty(patient) && !postAthenaPatientLoad) ||
		getPatientInsuranceInfoStart;

	return (
		<div className='payment-leftblock'>
			<div>
				<Loading loading={loading} className='insurance-view-loader'>
					{
						<div
							className={classnames(
								'insurance-info',
								loading ? 'loading' : ''
							)}
						>
							<div className='col-12'>
								<div className='insurance-header'>
									<div
										style={{
											display: 'flex',
											alignContent: 'center',
										}}
									>
										Patient's Insurance Card
										{(primaryInsurance.insurance_company_type ||
											!isInsuranceEmpty(
												secondaryInsurance
											) ||
											!isInsuranceEmpty(
												labInsurance
											)) && (
											<RBAC
												action={
													CARE_PATIENTS_PATIENT_PAYMENTS_INSURANCE_ACTIONS
												}
												yes={
													<div
														data-testId='edit-btn'
														onClick={() => {
															setAction(
																actionType.edit
															);
														}}
														className='insurance-edit-button'
													>
														<img
															src={
																editInsuranceButton
															}
															alt=''
															style={{
																position:
																	'relative',
																bottom: '5px',
															}}
														/>
													</div>
												}
											/>
										)}
									</div>
									<RBAC
										action={
											CARE_PATIENTS_NEEDS_INSURANCE_SELECTION
										}
										yes={
											<div>
												<CTAMenu
													{...{
														createAthenaPatientAndInsurance,
														patientDetail,
													}}
												/>
											</div>
										}
									/>
								</div>
							</div>
							{!isInsuranceEmpty(primaryInsurance) && (
								<div className='col-12'>Primary Insurance</div>
							)}
							{!primaryInsurance.insurance_company_type &&
								isInsuranceEmpty(secondaryInsurance) &&
								isInsuranceEmpty(labInsurance) && (
									<>
										<div className='no-insurance-label'>
											There is no insurance info
										</div>
										<RBAC
											action={
												CARE_PATIENTS_PATIENT_PAYMENTS_INSURANCE_ACTIONS
											}
											yes={
												<div
													data-testId='add-btn'
													onClick={() =>
														setAction(
															actionType.add
														)
													}
													className='insurance-add-button'
												>
													<img
														className='insurance-add-button-image'
														src={addInsuranceButton}
														alt=''
													/>
													<span className='insurance-add-button-text'>
														Add Insurance Info
													</span>
												</div>
											}
										/>
									</>
								)}
							{primaryInsurance.insurance_company_type && (
								<>
									<div className='col-12'>
										<div className='insurance-label'>
											Health company
										</div>
										<div className='insurance-text wrap'>
											{insuranceName}
										</div>
									</div>
									<div className='col-12 clearfix'>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Insurance ID #
											</div>
											<div className='insurance-text wrap'>
												{getData(
													primaryInsurance,
													'insurance_no'
												)}
											</div>
										</div>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Insurance phone number
											</div>
											<div className='insurance-text wrap'>
												{getData(
													primaryInsurance,
													'phone'
												)}
											</div>
										</div>
										<div className='col-4'>
											<div className='insurance-label'>
												Plan type
											</div>
											<div className='insurance-text wrap'>
												{getData(
													primaryInsurance,
													'insurance_plan_name'
												)}
											</div>
										</div>
									</div>
									<div className='col-12 clearfix'>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Group #
											</div>
											<div className='insurance-text wrap'>
												{getData(
													primaryInsurance,
													'group_no'
												)}
											</div>
										</div>
										<div className='col-4'>
											<div className='insurance-label'>
												Employer
											</div>
											<div className='insurance-text wrap'>
												{getData(
													primaryInsurance,
													'employer'
												)}
											</div>
										</div>
										<div className='col-4 pad-right-20px'></div>
									</div>
									<div className='col-12'>
										<div className='insurance-label'>
											Insurance address
										</div>
										<div className='insurance-text wrap'>
											<div className='insurance_address_text'>
												{primaryInsurance.insurance_address
													? `${getData(
															primaryInsurance,
															'insurance_address'
													  )}`
													: dash}
											</div>
											{primaryInsurance.insurance_city
												? getData(
														primaryInsurance,
														'insurance_city'
												  ) + ','
												: ''}
											&nbsp;
											{primaryInsurance.insurance_state
												? getData(
														primaryInsurance,
														'insurance_state'
												  )
												: ''}
											&nbsp;
											{primaryInsurance.insurance_zipcode
												? getData(
														primaryInsurance,
														'insurance_zipcode'
												  )
												: ''}
										</div>
									</div>
									<div className='col-12 clearfix'>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Is patient the primary
												policyholder?
											</div>
											<div className='insurance-text wrap'>
												{primaryInsurance.is_policyholder
													? 'Yes'
													: 'No'}
											</div>
										</div>
										{primaryInsurance.is_policyholder && (
											<div className='col-4 pad-right-20px'>
												<div className='insurance-label'>
													Policyholder's biological
													sex
												</div>
												<div className='insurance-text wrap capitalize'>
													{getData(
														primaryInsurance,
														'policyholder_biological_sex'
													)}
												</div>
											</div>
										)}
										{!primaryInsurance.is_policyholder && (
											<div className='col-4 pad-right-20px'>
												<div className='insurance-label'>
													Policyholder full name
												</div>
												<div className='insurance-text wrap'>
													{primaryInsurance.policyholder_first_name
														? getData(
																primaryInsurance,
																'policyholder_first_name'
														  )
														: ''}
													&nbsp;
													{primaryInsurance.policyholder_last_name
														? getData(
																primaryInsurance,
																'policyholder_last_name'
														  )
														: ''}
												</div>
											</div>
										)}
									</div>
									{!primaryInsurance.is_policyholder && (
										<div className='col-12 clearfix'>
											<div className='col-4 pad-right-20px'>
												<div className='insurance-label'>
													Policyholder's biological
													sex
												</div>
												<div className='insurance-text wrap capitalize'>
													{getData(
														primaryInsurance,
														'policyholder_biological_sex'
													)}
												</div>
											</div>
											<div className='col-4 pad-right-20px'>
												<div className='insurance-label'>
													Relationship to policyholder
												</div>
												<div className='insurance-text wrap ucase'>
													{getData(
														primaryInsurance,
														'policyholder_relationship'
													)}
												</div>
											</div>
											<div className='col-4'>
												<div className='insurance-label'>
													Policyholder's DOB
												</div>
												<div className='insurance-text wrap'>
													{getData(
														primaryInsurance,
														'policyholder_dob'
													)}
												</div>
											</div>
										</div>
									)}
									<div className='col-12 clearfix'>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Athena insurance package ID
											</div>
											<div className='insurance-text wrap'>
												{getData(
													primaryInsurance,
													'athena_insurance_package_id'
												)}
											</div>
										</div>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Athena insurance name
											</div>
											<div className='insurance-text wrap'>
												{getData(
													primaryInsurance,
													'athena_insurance_package_name'
												)}
											</div>
										</div>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Athena patient insurance record
												ID
											</div>
											<div className='insurance-text wrap'>
												{getData(
													primaryInsurance,
													'athena_insurance_id'
												)}
											</div>
										</div>
									</div>
									<div className='insurance-info-action-buttons-container'>
										{isInsuranceEmpty(primaryInsurance) && (
											<div
												type='button'
												className='btn-plus-primary-insurance'
												onClick={() => {
													setAction(actionType.add),
														setIsAddPrimaryInsuranceFromForm(
															true
														);
												}}
											>
												<ReactSVG
													className={
														'btn-plus-icon new_encounter'
													}
													src={add}
												/>
												Add Primary Insurance
											</div>
										)}
										{isInsuranceEmpty(secondaryInsurance) &&
											isInsuranceEmpty(labInsurance) && (
												<div
													type='button'
													className='btn-plus-lab-insurance'
													onClick={() => {
														setAction(
															actionType.add
														),
															setIsAddLabInsuranceFromForm(
																true
															);
													}}
												>
													<ReactSVG
														className='btn-plus-icon'
														src={add}
													/>
													Add Lab Insurance
												</div>
											)}
										{isInsuranceEmpty(
											secondaryInsurance
										) && (
											<div
												type='button'
												className='btn-plus-secondary-insurance'
												onClick={() => {
													setAction(actionType.add),
														setIsAddSecondaryInsuranceFromForm(
															true
														);
												}}
											>
												<ReactSVG
													className={
														'btn-plus-icon new_encounter'
													}
													src={add}
												/>
												Add Secondary Insurance
											</div>
										)}
									</div>
								</>
							)}
							{!isInsuranceEmpty(secondaryInsurance) && (
								<>
									<div className='divBorderBottomInfo'></div>
									<div className='col-12'>
										Secondary Insurance
									</div>
									<div className='col-12'>
										<div className='insurance-label'>
											Health company
										</div>
										<div className='insurance-text wrap'>
											{secondaryInsuranceName}
										</div>
									</div>
									<div className='col-12 clearfix'>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Insurance ID #
											</div>
											<div className='insurance-text wrap'>
												{getData(
													secondaryInsurance,
													'insurance_no'
												)}
											</div>
										</div>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Insurance phone number
											</div>
											<div className='insurance-text wrap'>
												{getData(
													secondaryInsurance,
													'phone'
												)}
											</div>
										</div>
										<div className='col-4'>
											<div className='insurance-label'>
												Plan type
											</div>
											<div className='insurance-text wrap'>
												{getData(
													secondaryInsurance,
													'insurance_plan_name'
												)}
											</div>
										</div>
									</div>
									<div className='col-12 clearfix'>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Group #
											</div>
											<div className='insurance-text wrap'>
												{getData(
													secondaryInsurance,
													'group_no'
												)}
											</div>
										</div>
										<div className='col-4'>
											<div className='insurance-label'>
												Employer
											</div>
											<div className='insurance-text wrap'>
												{getData(
													secondaryInsurance,
													'employer'
												)}
											</div>
										</div>
										<div className='col-4 pad-right-20px'></div>
									</div>
									<div className='col-12 clearfix'>
										<div className='col-12 pad-right-20px'>
											<div className='insurance-label'>
												Insurance address
											</div>
											<div className='insurance-text wrap'>
												<div className='insurance_address_text'>
													{secondaryInsurance.insurance_address
														? `${getData(
																secondaryInsurance,
																'insurance_address'
														  )}`
														: dash}
												</div>
												{secondaryInsurance.insurance_city
													? getData(
															secondaryInsurance,
															'insurance_city'
													  ) + ','
													: ''}
												{secondaryInsurance.insurance_state
													? getData(
															secondaryInsurance,
															'insurance_state'
													  )
													: ''}
												{secondaryInsurance.insurance_zipcode
													? getData(
															secondaryInsurance,
															'insurance_zipcode'
													  )
													: ''}
											</div>
										</div>
									</div>
									<div className='col-12 clearfix'>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Is patient the primary
												policyholder?
											</div>
											<div className='insurance-text wrap'>
												{secondaryInsurance.is_policyholder
													? 'Yes'
													: 'No'}
											</div>
										</div>
										{secondaryInsurance.is_policyholder && (
											<div className='col-4 pad-right-20px'>
												<div className='insurance-label'>
													Policyholder's biological
													sex
												</div>
												<div className='insurance-text wrap capitalize'>
													{getData(
														secondaryInsurance,
														'policyholder_biological_sex'
													)}
												</div>
											</div>
										)}
										{!secondaryInsurance.is_policyholder && (
											<div className='col-4 pad-right-20px'>
												<div className='insurance-label'>
													Policyholder full name
												</div>
												<div className='insurance-text wrap'>
													{secondaryInsurance.policyholder_first_name
														? getData(
																secondaryInsurance,
																'policyholder_first_name'
														  )
														: ''}
													&nbsp;
													{secondaryInsurance.policyholder_last_name
														? getData(
																secondaryInsurance,
																'policyholder_last_name'
														  )
														: ''}
												</div>
											</div>
										)}
									</div>
									{!secondaryInsurance.is_policyholder && (
										<div className='col-12 clearfix'>
											<div className='col-4 pad-right-20px'>
												<div className='insurance-label'>
													Policyholder's biological
													sex
												</div>
												<div className='insurance-text wrap capitalize'>
													{getData(
														secondaryInsurance,
														'policyholder_biological_sex'
													)}
												</div>
											</div>
											<div className='col-4 pad-right-20px'>
												<div className='insurance-label'>
													Relationship to policyholder
												</div>
												<div className='insurance-text wrap ucase'>
													{getData(
														secondaryInsurance,
														'policyholder_relationship'
													)}
												</div>
											</div>
											<div className='col-4'>
												<div className='insurance-label'>
													Policyholder's DOB
												</div>
												<div className='insurance-text wrap'>
													{getData(
														secondaryInsurance,
														'policyholder_dob'
													)}
												</div>
											</div>
										</div>
									)}
									<div className='col-12 clearfix'>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Athena insurance package ID
											</div>
											<div className='insurance-text wrap'>
												{getData(
													secondaryInsurance,
													'athena_insurance_package_id'
												)}
											</div>
										</div>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Athena insurance name
											</div>
											<div className='insurance-text wrap'>
												{getData(
													secondaryInsurance,
													'athena_insurance_package_name'
												)}
											</div>
										</div>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Athena patient insurance record
												ID
											</div>
											<div className='insurance-text wrap'>
												{getData(
													secondaryInsurance,
													'athena_insurance_id'
												)}
											</div>
										</div>
									</div>
								</>
							)}
							{!isInsuranceEmpty(secondaryInsurance) &&
								isInsuranceEmpty(labInsurance) && (
									<div
										type='button'
										className='btn-plus-lab-insurance'
										onClick={() => {
											setAction(actionType.add),
												setIsAddLabInsuranceFromForm(
													true
												);
										}}
									>
										<ReactSVG
											className='btn-plus-icon'
											src={add}
										/>
										Add Lab Insurance
									</div>
								)}
							{!isInsuranceEmpty(labInsurance) && (
								<>
									{primaryInsurance.insurance_company_type && (
										<div className='divBorderBottomInfo'></div>
									)}
									<div className='col-12'>Lab Insurance</div>
									<div className='col-12'>
										<div className='insurance-label'>
											Health company
										</div>
										<div className='insurance-text wrap'>
											{labInsuranceName}
										</div>
									</div>
									<div className='col-12 clearfix'>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Insurance ID #
											</div>
											<div className='insurance-text wrap'>
												{getData(
													labInsurance,
													'insurance_no'
												)}
											</div>
										</div>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Insurance phone number
											</div>
											<div className='insurance-text wrap'>
												{getData(labInsurance, 'phone')}
											</div>
										</div>
										<div className='col-4'>
											<div className='insurance-label'>
												Plan type
											</div>
											<div className='insurance-text wrap'>
												{getData(
													labInsurance,
													'insurance_plan_name'
												)}
											</div>
										</div>
									</div>
									<div className='col-12 clearfix'>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Group #
											</div>
											<div className='insurance-text wrap'>
												{getData(
													labInsurance,
													'group_no'
												)}
											</div>
										</div>
										<div className='col-4'>
											<div className='insurance-label'>
												Employer
											</div>
											<div className='insurance-text wrap'>
												{getData(
													labInsurance,
													'employer'
												)}
											</div>
										</div>
										<div className='col-4 pad-right-20px'></div>
									</div>
									<div className='col-12 clearfix'>
										<div className='col-12 pad-right-20px'>
											<div className='insurance-label'>
												Insurance address
											</div>
											<div className='insurance-text wrap'>
												<div className='insurance_address_text'>
													{labInsurance.insurance_address
														? `${getData(
																labInsurance,
																'insurance_address'
														  )}`
														: dash}
												</div>
												{labInsurance.insurance_city
													? getData(
															labInsurance,
															'insurance_city'
													  ) + ','
													: ''}
												{labInsurance.insurance_state
													? getData(
															labInsurance,
															'insurance_state'
													  )
													: ''}
												{labInsurance.insurance_zipcode
													? getData(
															labInsurance,
															'insurance_zipcode'
													  )
													: ''}
											</div>
										</div>
									</div>
									<div className='col-12 clearfix'>
										<div className='col-4 pad-right-20px'>
											<div className='insurance-label'>
												Is patient the primary
												policyholder?
											</div>
											<div className='insurance-text wrap'>
												{labInsurance.is_policyholder
													? 'Yes'
													: 'No'}
											</div>
										</div>
										{labInsurance.is_policyholder && (
											<div className='col-4 pad-right-20px'>
												<div className='insurance-label'>
													Policyholder's biological
													sex
												</div>
												<div className='insurance-text wrap capitalize'>
													{getData(
														labInsurance,
														'policyholder_biological_sex'
													)}
												</div>
											</div>
										)}
										{!labInsurance.is_policyholder && (
											<div className='col-4 pad-right-20px'>
												<div className='insurance-label'>
													Policyholder full name
												</div>
												<div className='insurance-text wrap'>
													{labInsurance.policyholder_first_name
														? getData(
																labInsurance,
																'policyholder_first_name'
														  )
														: ''}
													&nbsp;
													{labInsurance.policyholder_last_name
														? getData(
															labInsurance,
																'policyholder_last_name'
														  )
														: ''}
												</div>
											</div>
										)}
									</div>
									{!labInsurance.is_policyholder && (
										<div className='col-12 clearfix'>
											<div className='col-4 pad-right-20px'>
												<div className='insurance-label'>
													Policyholder's biological
													sex
												</div>
												<div className='insurance-text wrap capitalize'>
													{getData(
														labInsurance,
														'policyholder_biological_sex'
													)}
												</div>
											</div>
											<div className='col-4 pad-right-20px'>
												<div className='insurance-label'>
													Relationship to policyholder
												</div>
												<div className='insurance-text wrap ucase'>
													{getData(
														labInsurance,
														'policyholder_relationship'
													)}
												</div>
											</div>
											<div className='col-4'>
												<div className='insurance-label'>
													Policyholder's DOB
												</div>
												<div className='insurance-text wrap'>
													{getData(
														labInsurance,
														'policyholder_dob'
													)}
												</div>
											</div>
										</div>
									)}
									<div className='insurance-info-action-buttons-container'>
										{isInsuranceEmpty(primaryInsurance) && (
											<div
												type='button'
												className='btn-plus-primary-insurance'
												onClick={() => {
													setAction(actionType.add),
														setIsAddPrimaryInsuranceFromForm(
															true
														);
												}}
											>
												<ReactSVG
													className={
														'btn-plus-icon new_encounter'
													}
													src={add}
												/>
												Add Primary Insurance
											</div>
										)}
									</div>
								</>
							)}
						</div>
					}
				</Loading>
			</div>
		</div>
	);
};

export default InsuranceInfo;
