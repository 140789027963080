//React & Redux
import React from 'react';
import PropTypes from 'prop-types';

//Lodash
import _ from 'lodash';

const AdvancedSearch = (props) => {
	const styles = {
		svg: {
			display: 'flex',
		},
		path: {
			fill: _.get(props, 'color', '#7b8291'),
			stroke: _.get(props, 'color', '#7b8291'),
			strokeLineCap: 'round',
			strokeLineJoin: 'round',
			strokeWidth: '1.5px',
		},
	};

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='17.5'
			height='14.591'
			style={styles.svg}
			viewBox='0 0 17.5 14.591'
		>
			<g
				id='prefix__sliders_1_'
				data-name='sliders (1)'
				transform='translate(-.25 -2.25)'
			>
				<path
					style={styles.path}
					id='prefix__Line_410'
					d='M0 5.091L0 0'
					className='prefix__cls-1'
					data-name='Line 410'
					transform='translate(3.182 11)'
				/>
				<path
					style={styles.path}
					id='prefix__Line_411'
					d='M0 5.091L0 0'
					className='prefix__cls-1'
					data-name='Line 411'
					transform='translate(3.182 3)'
				/>
				<path
					style={styles.path}
					id='prefix__Line_412'
					d='M0 6.545L0 0'
					className='prefix__cls-1'
					data-name='Line 412'
					transform='translate(9 9.545)'
				/>
				<path
					style={styles.path}
					id='prefix__Line_413'
					d='M0 3.636L0 0'
					className='prefix__cls-1'
					data-name='Line 413'
					transform='translate(9 3)'
				/>
				<path
					style={styles.path}
					id='prefix__Line_414'
					d='M0 3.636L0 0'
					className='prefix__cls-1'
					data-name='Line 414'
					transform='translate(14.818 12.455)'
				/>
				<path
					style={styles.path}
					id='prefix__Line_415'
					d='M0 6.545L0 0'
					className='prefix__cls-1'
					data-name='Line 415'
					transform='translate(14.818 3)'
				/>
				<path
					style={styles.path}
					id='prefix__Line_416'
					d='M0 0L4.364 0'
					className='prefix__cls-1'
					data-name='Line 416'
					transform='translate(1 11)'
				/>
				<path
					style={styles.path}
					id='prefix__Line_417'
					d='M0 0L4.364 0'
					className='prefix__cls-1'
					data-name='Line 417'
					transform='translate(6.818 6.636)'
				/>
				<path
					style={styles.path}
					id='prefix__Line_418'
					d='M0 0L4.364 0'
					className='prefix__cls-1'
					data-name='Line 418'
					transform='translate(12.636 12.455)'
				/>
			</g>
		</svg>
	);
};

AdvancedSearch.propTypes = {
	color: PropTypes.string,
};

export default AdvancedSearch;
