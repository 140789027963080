//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import outreachEnvelop from '../../../assets/outreachEnvelop.svg';
import othermethodOutreach from '../../../assets/othermethodOutreach.svg';
import OutreachPhone from '../../../assets/OutreachPhone.svg';
import doNotOutreachIcon from '../../../assets/do-not-outreach.svg';
import disableNoOutreachIcon from '../../../assets/phone-check.svg';

//Other Libraries
import _ from 'lodash';
import moment from 'moment';
import classnames from 'classnames';

//Utils
import { encounter_types } from '../../../Utils/encounterUtils';
import {
	isPartnerInitiatedPreTest,
	isPartnerInitiatedUnsolicitedResult,
} from '../../Patients/V2/Encounters/Common/utilsfunctions';
import {
	CARE_PATIENTS_PATIENT_EDIT_RoR_OUTREACH,
	CARE_PATIENTS_PATIENT_OUTREACH,
} from '../../../Utils/permissionUtils';

//Components
import RBAC from '../../RBAC';

//Styles
import './OutreachRecord.css';

const OutreachRecord = (props) => {
	const { outreach = {}, showEditOutreachDialog } = props;

	const {
		consultationType,
		contactDateTime = null,
		createdByFirstName = '',
		createdByLastName = '',
		DOS,
		doNotOutreachEnabled = true,
		encounterSubType,
		encounterType,
		emailSubject = '',
		medium = '',
		outcome = '',
		outcomeDisplayName = 'email',
		outreachDisplay,
		isSystem = null,
		notes,
		relatedSMSOutreaches,
		subtypeName,
		smsBody = '',
		outreachType = '',
		type = '',
		labName = '',
		testName = '',
	} = outreach;
	const isReferralOutreachDisabled =
		!_.isEmpty(subtypeName) &&
		!_.isNil(subtypeName) &&
		subtypeName == 'referral_outreach_disabled';
	const isReferralOutreachEnabled =
		!_.isEmpty(subtypeName) &&
		!_.isNil(subtypeName) &&
		subtypeName == 'referral_outreach_enabled';

	const displayDateTimeField = (value) =>
		moment(value).isValid()
			? moment
					.utc(value)
					.tz(moment.tz.guess())
					.format('MM/DD/YYYY hh:mm a z')
			: value;

	const displayContactMethodImage = (contactMethod) => {
		switch (true) {
			case isReferralOutreachDisabled:
				return (
					<ReactSVG
						src={doNotOutreachIcon}
						className='icon-do-not-outreach'
					/>
				);
			case isReferralOutreachEnabled:
				return (
					<ReactSVG
						src={disableNoOutreachIcon}
						className='icon-disabled-do-not-outreach'
					/>
				);
			case contactMethod === 'phone':
				return <ReactSVG src={OutreachPhone} />;
			case contactMethod === 'email':
				return <ReactSVG src={outreachEnvelop} />;
			case contactMethod === 'other':
			default:
				return <ReactSVG src={othermethodOutreach} />;
		}
	};

	const outreachSubject = () => {
		const consultation =
			encounterType == 'visit' ? `- ${consultationType}` : '';
		if (outcome === 'sms_sent') {
			return displaySmsOutreach();
		} else if (type == 'ror_outreach') {
			return `RoR Outreach - ${
				isPartnerInitiatedPreTest({
					...outreach,
					type: encounterType,
					encounter_subtype: encounterSubType,
				})
					? 'TRO'
					: isPartnerInitiatedUnsolicitedResult({
								...outreach,
								type: encounterType,
								encounter_subtype: encounterSubType,
						  })
						? 'TRO - Results Only'
						: encounter_types[encounterType]
			}${consultation ? ` ${consultation}` : ``}, ${moment(DOS).format(
				'YYYY-MM-DD'
			)}`;
		} else if (type == 'delayed_lab_outreach') {
			return `Delayed Lab Outreach - ${encounterType} ${consultation}, ${moment(
				DOS
			).format('YYYY-MM-DD')} - ${labName} ${testName}`;
		} else if (isSystem && outcome !== 'sms_sent') {
			return emailSubject;
		} else if (isReferralOutreachDisabled) {
			return 'Do Not Outreach';
		} else if (isReferralOutreachEnabled) {
			return '"Do Not Outreach" Disabled';
		} else {
			return displaySubTypeValue(outreach, outreachDisplay);
		}
	};

	const displaySubTypeValue = (outreach, outreachDisplay) => {
		let displaySubtype = outreach.subtype || outreachDisplay;
		if (!displaySubtype || displaySubtype === '--') {
			displaySubtype = outreachType;
			if (displaySubtype === 'welcome_sms') {
				displaySubtype = 'Welcome SMS';
			}
			if (displaySubtype === 'referral_outreach') {
				displaySubtype = 'Referral Outreach';
			}
			if (displaySubtype === 'ror_outreach') {
				displaySubtype = 'ROR Outreach';
			}
		}
		return displaySubtype;
	};
	const displaySmsOutreach = () => {
		return (
			<div>
				<div>
					{type === 'ror_outreach'
						? 'ROR Outreach Text'
						: 'Referral Outreach Text'}
				</div>
				<span className='outreach-sms-body'>{smsBody}</span>
			</div>
		);
	};

	const displaySentByField = () =>
		'by ' +
		(isSystem ? 'System' : createdByFirstName + ' ' + createdByLastName);

	const displayOutcomeField = () => {
		const hasRelatedSMSOutreaches =
			relatedSMSOutreaches &&
			Array.isArray(relatedSMSOutreaches) &&
			relatedSMSOutreaches.length;

		if (isReferralOutreachDisabled || isReferralOutreachEnabled) {
			return notes || '--';
		} else if (outcome === 'sms_sent') {
			return (
				<span className='inner-outrch-content outcome'>SMS Sent</span>
			);
		} else if (outcome != 'other') {
			if (hasRelatedSMSOutreaches && outcome === 'sms_sent') {
				return (
					<span style={{ display: 'flex' }}>
						{outcomeDisplayName},{' '}
						<span className='inner-outrch-content outcome'>
							&nbsp; SMS Sent
						</span>
					</span>
				);
			} else {
				return outcomeDisplayName;
			}
		} else {
			return notes ? `Other: ${notes}` : 'Other: --';
		}
	};

	const renderContent = (isDisabledForExternal) => {
		const className = classnames('outreach-record-wrapper', {
			'referral-outreach-disabled': isReferralOutreachDisabled,
			'referral-outreach-enabled': isReferralOutreachEnabled,
			'css-cursor-pointer':
				isDisabledForExternal &&
				!isSystem &&
				!isReferralOutreachDisabled &&
				!isReferralOutreachEnabled,
			'do-not-outreach-enabled': !doNotOutreachEnabled,
		});

		const displaySubtype = displaySubTypeValue(outreach, outreachDisplay);

		return (
			<div
				data-testId='edit-outreach'
				className={className}
				onClick={() =>
					isDisabledForExternal &&
					!isSystem &&
					!isReferralOutreachDisabled &&
					!isReferralOutreachEnabled &&
					showEditOutreachDialog(outreach)
				}
			>
				<span className='outrch-icon'>
					{displayContactMethodImage(medium)}
				</span>
				<div className='outrch-content'>
					<span className='inner-outrch-content'>
						<span className='outreach-date'>
							{displayDateTimeField(contactDateTime)}
						</span>
						<span className='ror-group-text'>
							{isReferralOutreachDisabled ||
							isReferralOutreachEnabled
								? displaySentByField()
								: displaySubtype || '--'}
						</span>
					</span>
					<span className='inner-outrch-content'>
						<span className='outrch-email-subject'>
							{outreachSubject()}
						</span>
						<span
							className={`outrch-created-by ${
								isReferralOutreachDisabled ||
								isReferralOutreachEnabled
									? 'text-capitalize'
									: ''
							}`}
						>
							{isReferralOutreachDisabled ||
							isReferralOutreachEnabled
								? `Via ${medium}`
								: displaySentByField()}
						</span>
					</span>
					<span className='inner-outrch-content outcome'>
						{displayOutcomeField()}
					</span>
				</div>
			</div>
		);
	};

	return (
		<>
			{type == 'ror_outreach' ? (
				<RBAC
					action={CARE_PATIENTS_PATIENT_EDIT_RoR_OUTREACH}
					yes={renderContent(true)}
					no={renderContent(false)}
				/>
			) : (
				<RBAC
					action={CARE_PATIENTS_PATIENT_OUTREACH}
					yes={renderContent(true)}
					no={renderContent(false)}
				/>
			)}
		</>
	);
};

export default OutreachRecord;
