//React & Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';

//Lodash
import get from 'lodash/get';

//Utils
import {
	CARE_PATIENTS_PATIENT_OUTREACH_LOG,
	CARE_PATIENTS_PATIENT_NOTES,
	CARE_PATIENTS_PATIENT_DOCUMENTS,
	CARE_PATIENTS_PATIENT_PAYMENTS,
	ROLE_PHYSICIAN,
	ROLE_EXTERNALUSER,
	ROLE_EXTERNALUSER_READ_ONLY,
	ROLE_EXTERNALUSER_LAB,
	CARE_PATIENTS_PATIENT_TESTS,
} from '../../Utils/permissionUtils';
import { shouldBeMasked, getNotesEnum } from '../../utils';

//Actions & Services
import { getnotes, submitnotes, deleteNote } from '../../actions/notes';
import { getencounters } from '../../actions/encounter';
import {
	getspecificencounterenum,
} from '../../actions/dashboard';

//UI Libraries
import { Tabs } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';

//Components
import EncounterTab from './EncounterTab';
import NotesTab from './Notes/NotesTab';
import OutreachLogTab from './V2/Outreachs/OutreachLogTab';
import DocumentsTab from './V2/Documents/DocumentsTab';
import PaymentsTab from './Payments/PaymentsTab';
import TestsTabV2 from './V2/Tests/TestsTab';
import MedicationsTab from './V2/Medications/Medications.js';
import RBAC from '../RBAC';

//Styles
import './style.css';

class PatientInfoTab extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 0,
			selectedEncounter: null,
			encounter_id: '',
			offset: 0,
			activePage: 1,
			notesoffset: 0,
			notesactivePage: 1,
		};
		this.handleCreateEncounter = this.handleCreateEncounter.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.viewEncounterDetails = this.viewEncounterDetails.bind(this);
		this.postNoteForPatient = this.postNoteForPatient.bind(this);
		this.deleteNoteForPatient = this.deleteNoteForPatient.bind(this);
		this.getNoteCounts = this.getNoteCounts.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.changeEncounterTab = this.changeEncounterTab.bind(this);
		this.resetPaginationCounter = this.resetPaginationCounter.bind(this);
		this.getEncounterList = this.getEncounterList.bind(this);
		this.changeTab = this.changeTab.bind(this);
		this.renderTabHeaders = this.renderTabHeaders.bind(this);
		this.determineHasPermission = this.determineHasPermission.bind(this);
	}
	//functions

	componentDidMount() {
		this.props.callVisitProviderEnum({
			type: 'visit',
			field_name: 'visit_provider',
		});

		const activerole = get(this.props, ['me', 'activerole', 'name'], '');
		const restrictedUser = activerole in { [ROLE_PHYSICIAN]: 1 };
		const externalUser =
			activerole in
			{
				[ROLE_EXTERNALUSER_READ_ONLY]: 1,
				[ROLE_EXTERNALUSER]: 1,
				[ROLE_EXTERNALUSER_LAB]: 1,
			};
		if (!restrictedUser) {
			this.getNotesForPatient();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			get(prevProps, 'submitNoteLoading', false) &&
			!get(this, 'props.submitNoteLoading', false)
		) {
			if (get(this, 'props.submitNoteSuccess', false)) {
				this.getNotesForPatient();
			}
		}

		if (
			get(prevProps, 'deleteLoadingNotes', false) !==
			get(this, 'props.deleteLoadingNotes', false)
		) {
			this.getNotesForPatient();
		}
	}

	getEncounterList() {
		const props = this.props;
		this.props.getencouter({
			id: get(props, 'uuid', 0),
			offset: get(this, 'state.offset'),
		});
	}

	handlePageChange(evt, pageNumber) {
		this.props.getencouter({
			id: get(this, 'props.uuid', 0),
			offset: pageNumber,
		});
		this.setState({ activePage: pageNumber });
	}

	viewEncounterDetails(encounter) {
		this.setState({
			selectedEncounter: encounter,
			encounter_id: encounter.uuid,
		});
	}

	handleCreateEncounter(evt) {
		const isEncounterCreateMode = get(
			this,
			'state.isEncounterCreateMode',
			false
		);
		this.setState({
			isEncounterCreateMode: !isEncounterCreateMode,
		});
	}

	handleClose() {
		this.setState({
			isEncounterCreateMode: false,
		});
	}

	getNotesForPatient() {
		const props = this.props;
		this.props.getNotes({
			id: get(props, 'uuid', 0),
			offset: get(this, 'state.notesoffset'),
		});
		this.setState({ notesactivePage: get(this, 'state.notesoffset') });
	}

	postNoteForPatient(entered_note, noteType) {
		const json = {
			data: {
				author: get(this, 'props.username', ''),
				note: entered_note,
				date_created: new Date(),
				type: noteType,
			},
			patient_uuid: get(this, 'props.match.params.patientid', 0),
		};
		return this.props.submitnotes(json);
	}

	deleteNoteForPatient(note_uuid, patient_uuid) {
		const json = {
			patient_uuid: patient_uuid,
			note_uuid: note_uuid,
		};
		return this.props.deletenote(json);
	}

	getNoteCounts() {
		const isExternalUser = get(
			this,
			'props.me.activerole.isExternalUser',
			true
		);
		const notes = get(this, 'props.noteslist.data', null) || [];
		const notesEnum = getNotesEnum(isExternalUser);

		const counts = {};
		let total = 0;

		if (Array.isArray(notes) && Array.isArray(notesEnum)) {
			notesEnum.forEach((noteObj) => {
				const noteType = noteObj.key;
				const notesOfCurrentType = notes.filter(
					(note) => note.type === noteType
				);
				const numberOfCurrentNotes =
					get(notesOfCurrentType, 'length', null) || 0;
				counts[noteType] = numberOfCurrentNotes;
				total += numberOfCurrentNotes;
			});
			counts.total = total;
		}
		return counts;
	}

	changeEncounterTab(uuid, value, state = {}) {
		const params = new URLSearchParams(
			get(this, ['props', 'location', 'search'], '')
		);
		const order_by = params.get('order_by');
		const order_type = params.get('order_type');
		const offset = params.get('offset');
		const patientid = get(this, 'props.match.params.patientid', 0);
		const tabtype = get(this.props, 'match.params.tabtype', 0);
		const locationstate = Object.assign(
			get(this, 'props.location.state', {}),
			state
		);
		this.setState({ activePage: 0 });
		this.props.history.push({
			pathname:
				'/app/patientdetail/' +
				patientid +
				'/' +
				tabtype +
				'/' +
				value +
				'/' +
				uuid +
				'/',
			search:
				'?offset=' +
				(offset || 0) +
				'&order_by=' +
				(order_by || '') +
				'&order_type=' +
				(order_type || 'DESC'),
			state: locationstate,
		});
	}

	resetPaginationCounter() {
		this.setState({ activePage: 0 });
	}

	changeTab = (value) => {
		const enctype = 0;
		const params = new URLSearchParams(
			get(this, ['props', 'location', 'search'], '')
		);
		const order_by = params.get('order_by');
		const order_type = params.get('order_type');
		const offset = params.get('offset');
		this.props.history.push({
			pathname:
				'/app/patientdetail/' +
				get(this, 'props.match.params.patientid', 0) +
				'/' +
				value +
				'/' +
				enctype,
			search:
				'?offset=' +
				(offset || 0) +
				'&order_by=' +
				(order_by || '') +
				'&order_type=' +
				(order_type || 'DESC'),
			state: get(this, 'props.location.state', {}),
		});
	};

	renderTabHeaders() {
		const tabs = [];
		const activeTab = parseInt(get(this.props, 'match.params.tabtype', 0));
		const isDrugExposureDisabled = !get(
			this,
			'props.drugExposures.length',
			false
		);

		tabs.push(
			<Tabs.Pane
				className={
					'tabhead' + (activeTab == 0 ? ' active' : ' non-active')
				}
				label={<span className={'tab_labels '}>Encounters</span>}
				name={0}
			/>
		);

		if (this.determineHasPermission(CARE_PATIENTS_PATIENT_OUTREACH_LOG)) {
			tabs.push(
				<Tabs.Pane
					className={
						'tabhead' +
						(activeTab == 8 ? ' active opacity-one' : ' non-active')
					}
					label={<span className={'tab_labels '}>Outreach Log</span>}
					name={8}
				/>
			);
		}

		if (this.determineHasPermission(CARE_PATIENTS_PATIENT_NOTES)) {
			tabs.push(
				<Tabs.Pane
					className={
						activeTab == 2 ? ' active opacity-one' : 'non-active'
					}
					label={
						<span className={'tab_labels'}>
							Notes ({get(this.getNoteCounts(), 'total', '0')})
						</span>
					}
					name={2}
				/>
			);
		}

		if (this.determineHasPermission(CARE_PATIENTS_PATIENT_DOCUMENTS)) {
			tabs.push(
				<Tabs.Pane
					className={
						'tabhead' +
						(activeTab == 6 ? ' active opacity-one' : ' non-active')
					}
					label={<span className={'tab_labels '}>Documents</span>}
					name={6}
				/>
			);
		}

		if (this.determineHasPermission(CARE_PATIENTS_PATIENT_PAYMENTS)) {
			tabs.push(
				<Tabs.Pane
					className={
						'tabhead' +
						(activeTab == 5 ? ' active opacity-one' : ' non-active')
					}
					label={<span className={'tab_labels '}>Payments</span>}
					name={5}
				/>
			);
		}

		if (this.determineHasPermission(CARE_PATIENTS_PATIENT_TESTS)) {
			tabs.push(
				<Tabs.Pane
					className={
						'tabhead' +
						(activeTab == 9 ? ' active opacity-one' : ' non-active')
					}
					label={<span className={'tab_labels '}>Tests</span>}
					name={9}
				/>
			);
		}

		tabs.push(
			<Tabs.Pane
				className={
					'tabhead' + (activeTab == 10 ? ' active' : ' non-active')
				}
				label={<span className={'tab_labels '}>Medications</span>}
				name={10}
				disabled={isDrugExposureDisabled}
				style={{
					opacity: isDrugExposureDisabled ? '0.5' : '1',
					cursor: isDrugExposureDisabled ? 'no-drop' : 'pointer',
				}}
			/>
		);

		return tabs;
	}

	determineHasPermission(permissionStr) {
		if (permissionStr) {
			const permissions = get(this, 'props.me.permissions', null) || {};
			return get(permissions, permissionStr, null) === 'full-access';
		}
	}

	render() {
		const { className } = this.props;
		const activeTab = parseInt(get(this.props, 'match.params.tabtype', 0));
		const frozen = this.props.tabFrozen;
		const isDrugExposureDisabled = !get(
			this,
			'props.drugExposures.length',
			false
		);
		return (
			<div className={classnames('EncounterList', className)} style={{}}>
				{frozen ? <div className='whiteOverlay' /> : ''}
				<div
					id='patient_encounter_list'
					className='patient_encounter_list'
					style={{ paddingLeft: '0px', paddingRight: '0px' }}
				>
					<Tabs
						value={parseInt(
							get(this.props, 'match.params.tabtype', 0)
						)}
						textColor='primary'
						className='patient_info_tabs'
						fullWidth={false}
						id='encounter_tabs'
						variant='scrollable'
						style={{ borderBottom: '1px solid #d8dde4' }}
						onTabClick={(tab) => {
							const value = get(tab, ['props', 'name'], 0);
							this.changeTab(value);
						}}
					>
						{this.renderTabHeaders()}
					</Tabs>

					{parseInt(get(this.props, 'match.params.tabtype', 0)) ===
						0 && (
						<div
							className='patientInfoTab'
							style={{ paddingLeft: '8px', paddingRight: '8px' }}
						>
							<EncounterTab
								{...this.props}
								isEncounterCreateMode={get(
									this,
									'state.isEncounterCreateMode',
									false
								)}
								handleClose={this.handleClose}
								handleCreateEncounter={
									this.handleCreateEncounter
								}
								viewEncounterDetails={this.viewEncounterDetails}
								handlePageChange={this.handlePageChange}
								activePage={this.state.activePage}
								visitProvider={get(
									this,
									'props.visitProvider.data.visit_provider',
									[]
								)}
								changeEncounterTab={this.changeEncounterTab}
								resetPaginationCounter={
									this.resetPaginationCounter
								}
								encounterstep={parseInt(
									get(this.props, 'match.params.enctype', 0)
								)}
								getEncounterList={this.getEncounterList}
								openAppointmentAlert={
									this.props.openAppointmentAlert
								}
								encounterCompleteLoading={
									this.props.encounterCompleteLoading
								}
								encounterCompleteError={
									this.props.encounterCompleteError
								}
								encounterCancelLoading={
									this.props.encounterCancelLoading
								}
								completeEncounter={this.props.completeEncounter}
								closeAlert={this.props.closeAlert}
								providerSpecialties={
									get(
										this,
										'props.providerSpecialties',
										null
									) || []
								}
							/>
						</div>
					)}

					{parseInt(get(this.props, 'match.params.tabtype', 0)) ===
						8 && (
						<div
							className='patientInfoTab'
							style={{ paddingLeft: '8px', paddingRight: '8px' }}
						>
							<RBAC
								action={CARE_PATIENTS_PATIENT_OUTREACH_LOG}
								yes={
									<OutreachLogTab
										enqueueSnackbar={
											this.props.enqueueSnackbar
										}
										match={this.props.match}
										noOutreachReasons={
											this.props.noOutreachReasons
										}
									/>
								}
							/>
						</div>
					)}

					{parseInt(get(this.props, 'match.params.tabtype', 0)) ===
						2 && (
						<div className='patientInfoTab'>
							<RBAC
								action={CARE_PATIENTS_PATIENT_NOTES}
								yes={
									<NotesTab
										addNoteProps={{
											enqueueSnackbar:
												this.props.enqueueSnackbar,
											postNoteForPatient:
												this.postNoteForPatient,
											deleteNoteForPatient:
												this.deleteNoteForPatient,
											deleteNoteError:
												this.props.deleteNoteError,
											deleteNoteSuccess:
												this.props.deleteNoteSuccess,
											deleteLoadingNotes:
												this.props.deleteLoadingNotes,
											submitNoteError:
												this.props.submitNoteError,
											submitNoteLoading:
												this.props.submitNoteLoading,
											submitNoteSuccess:
												this.props.submitNoteSuccess,
											loadingNotes:
												this.props.loadingNotes,
											notesError: this.props.notesError,
										}}
										notesList={this.props.noteslist}
										isExternalUser={get(
											this,
											'props.me.activerole.isExternalUser',
											true
										)}
										noteCounts={this.getNoteCounts()}
										currentLoggedInUser={
											this.props.me.user.email
										}
									/>
								}
							/>
						</div>
					)}

					{parseInt(get(this.props, 'match.params.tabtype', 0)) ===
						6 && (
						<div className='patientInfoTab'>
							<RBAC
								action={CARE_PATIENTS_PATIENT_DOCUMENTS}
								yes={
									<DocumentsTab
										{...{
											...this.props,
											affiliations:
												this.props.affiliations,
											changeEncounterTab:
												this.changeEncounterTab,
											innerstep: parseInt(
												get(
													this.props,
													'match.params.enctype',
													0
												)
											),
											gettingAffiliations:
												this.props.gettingAffiliations,
										}}
									/>
								}
							/>
						</div>
					)}

					{parseInt(get(this.props, 'match.params.tabtype', 0)) ===
						5 && (
						<div className='patientInfoTab'>
							<RBAC
								action={CARE_PATIENTS_PATIENT_PAYMENTS}
								yes={
									<PaymentsTab
										{...{
											createAthenaPatientAndInsurance:
												this.props
													.createAthenaPatientAndInsurance,
											createdAthenaPatient:
												this.props.createdAthenaPatient,
											createdAthenaPatientError:
												this.props
													.createdAthenaPatientError,
											creatingAthenaPatient:
												this.props
													.creatingAthenaPatient,
											enqueueSnackbar:
												this.props.enqueueSnackbar,
											getPatientDetail:
												this.props.getPatientDetail,
											getPlanTypesEnums:
												this.props.getPlanTypesEnums,
											gettingPatientDetail:
												this.props.gettingPatientDetail,
											match: this.props.match,
											patientDetail:
												this.props.patientdetail,
										}}
									/>
								}
							/>
						</div>
					)}

					{parseInt(get(this.props, 'match.params.tabtype', 0)) ===
						9 && (
						<div className='patientInfoTab'>
							<RBAC
								action={CARE_PATIENTS_PATIENT_TESTS}
								yes={
									<TestsTabV2
										{...{
											parentProps: {
												changeEncounterTab:
													this.changeEncounterTab,
												enqueueSnackbar:
													this.props.enqueueSnackbar,
												getICDCodes:
													this.props.getICDCodes,
												goToTestTab: () =>
													this.changeTab(9),
												history: this.props.history,
												icdCodes: this.props.icdCodes,
												icdCodesError:
													this.props.icdCodesError,
												icdCodesLoading:
													this.props.icdCodesLoading,
												icdCodesPlacement:
													this.props
														.icdCodesPlacement,
												match: this.props.match,
												location: this.props.location,
												patient: this.props.patient,
												patientdetail:
													this.props.patientdetail,
												teststep: parseInt(
													this.props.match?.params
														?.enctype || 0
												),
												visitProvider:
													this.props.visitProvider
														?.data
														?.visit_provider || [],
											},
										}}
									/>
								}
							/>
						</div>
					)}

					{parseInt(get(this.props, 'match.params.tabtype', 10)) ===
						10 && (
						<div
							className='patientInfoTab'
							style={{ height: 'calc(100% - 49px)' }}
						>
							<MedicationsTab
								drugExposures={
									get(this, 'props.drugExposures', null) || []
								}
								drugExposuresLoading={get(
									this,
									'props.drugExposureLoading',
									false
								)}
								patientUuid={get(this, 'props.uuid', null)}
								enqueueSnackbar={this.props.enqueueSnackbar}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	encounter_list: state.encounterlist?.encounter,
	noteslist: state.notes?.notes,
	submitnoteresult: state.notes?.notesdetail,
	loadingNotes: state.notes?.loadingNotes,
	notesError: state.notes?.notesError,
	fetchnotes: state.fetchnotes,
	visitProvider: state.dashboardencounters?.visitproviderenum,
	me: state.me,
	submitNoteLoading: state.submitnotes?.loadingsubmitnotes,
	submitNoteSuccess: state.submitnotes?.notesdetail,
	submitNoteError: state.submitnotes?.notesdetailError,
	deleteNoteSuccess: state.deletenotes?.deleteNotesSuccess,
	deleteNoteError: state.deletenotes?.deleteNotesError,
	deleteLoadingNotes: state.deletenotes?.deleteLoadingNotes
});

const mapDispatchToProps = (dispatch) => ({
	getencouter: (pid) => dispatch(getencounters(pid)),
	getNotes: (pid) => dispatch(getnotes(pid)),
	submitnotes: (data) => dispatch(submitnotes(data)),
	deletenote: (data) => dispatch(deleteNote(data)),
	callVisitProviderEnum: (data) => dispatch(getspecificencounterenum(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfoTab);
