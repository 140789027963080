import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { usePrevious } from '../../../../hooks/usePrevious';

import SingleSelectPill from '../../../Filter/SingleSelectPill';
import SingleSelectTitleRow from '../../../Filter/SingleSelectTitleRow';
import SingleSelectButtons from '../../../Filter/SingleSelectButtons';
import DateRangeFilter from '../../../Filter/DateRangeFilter';
import RadioGroup from '../../../Common/controls/RadioGroup/RadioGroup';

import './ReferralDate.scss';

/**
 * @typedef {{ from: string; to: string }} Value
 */

const OPTIONS = {
	LAST7: { VALUE: 'LAST7', LABEL: 'Last 7 Days' },
	LAST30: { VALUE: 'LAST30', LABEL: 'Last 30 Days' },
	CUSTOM: { VALUE: 'CUSTOM', LABEL: 'Custom' },
};

function generateDateRange(option, range) {
	switch (option) {
		case OPTIONS.LAST7.VALUE:
			return {
				from: moment()
					.subtract(7, 'days')
					.startOf('day')
					.format('YYYY-MM-DD'),
				to: moment().endOf('day').format('YYYY-MM-DD'),
			};
		case OPTIONS.LAST30.VALUE:
			return {
				from: moment()
					.subtract(30, 'days')
					.startOf('day')
					.format('YYYY-MM-DD'),
				to: moment().endOf('day').format('YYYY-MM-DD'),
			};
		case OPTIONS.CUSTOM.VALUE:
			return {
				from: moment(range.from).format('YYYY-MM-DD'),
				to: moment(range.to).format('YYYY-MM-DD'),
			};
		default:
			return DEFAULT_RANGE;
	}
}

/**
 * @param {Value} value
 * @returns {string}
 */
function determineInternalValue(value) {
	const to = moment(value.to);
	if (!to.isSame(moment(), 'day')) {
		return OPTIONS.CUSTOM.VALUE;
	}

	const from = moment(value.from);

	switch (to.diff(from, 'days')) {
		case 7:
			return OPTIONS.LAST7.VALUE;
		case 30:
			return OPTIONS.LAST30.VALUE;
		default:
			return OPTIONS.CUSTOM.VALUE;
	}
}

const DEFAULT_RANGE = { from: null, to: null };

/**
 * @param {Object} props
 * @param {(value: Value) => void} props.onChange
 * @param {Value} props.value
 */
export function ReferralDate({ onChange, value }) {
	const [modalOpen, setModalOpen] = useState(false);
	const [internalValue, setInternalValue] = useState(OPTIONS.LAST7.VALUE);
	const [range, setRange] = useState(DEFAULT_RANGE);

	const prevValue = usePrevious(value);
	useEffect(() => {
		if (
			value.from &&
			value.to &&
			// Only sync value to internalValue if value has changed
			(prevValue?.from !== value.from || prevValue?.to !== value.to)
		) {
			const internal = determineInternalValue(value);
			if (internal === OPTIONS.CUSTOM.VALUE) {
				setRange({
					from: moment(value.from).toDate(),
					to: moment(value.to).toDate(),
				});
			} else {
				setRange(DEFAULT_RANGE);
			}
			setInternalValue(internal);
		}
	}, [value]);

	// Logic to close filter if clicking outside of the modal
	const filterRef = useRef(null);
	const datePickerRef = useRef(null);
	useEffect(() => {
		function bodyClickHandler(e) {
			const isDatePickerOpen =
				!!datePickerRef.current?.pickerProxy?.tnode;
			// do not close filter if currently selecting date range
			if (!isDatePickerOpen && !filterRef.current?.contains(e.target)) {
				setModalOpen(false);
			}
		}

		document
			.querySelector('body')
			.addEventListener('click', bodyClickHandler, { capture: true });

		return () =>
			document
				.querySelector('body')
				.removeEventListener('click', bodyClickHandler);
	}, []);

	return (
		<div className='referral-date singleSelectContainer' ref={filterRef}>
			<SingleSelectPill
				label={`Referral Date: ${
					OPTIONS[determineInternalValue(value)].LABEL
				}`}
				modalOpen={modalOpen}
				openModal={() => setModalOpen(true)}
				closeModal={() => setModalOpen(false)}
				setPillElement={() => {}}
			/>
			{modalOpen && (
				<div className='singleSelectModalContainer'>
					<SingleSelectTitleRow
						title='Referral Date'
						closeModal={() => setModalOpen(false)}
					/>
					<div className='singleSelectModalBodyContainer'>
						<RadioGroup
							options={Object.values(OPTIONS).map((option) => ({
								display_name: option.LABEL,
								key: option.VALUE,
							}))}
							value={internalValue}
							onChange={setInternalValue}
						/>
						{internalValue === OPTIONS.CUSTOM.VALUE && (
							<DateRangeFilter
								startDate={range.from}
								endDate={range.to}
								startDatePlaceholder='Start'
								endDatePlaceholder='End'
								onChange={setRange}
								externalRef={datePickerRef}
							/>
						)}
					</div>
					<SingleSelectButtons
						resetInternalValue={() => {
							setInternalValue(OPTIONS.LAST30.VALUE);
							setRange(DEFAULT_RANGE);
						}}
						saveValue={() => {
							onChange(generateDateRange(internalValue, range));
							setModalOpen(false);
						}}
						canSaveValue={() => true}
					/>
				</div>
			)}
		</div>
	);
}
