import * as actionTypes from '../actions/actionTypes';

const defaultReferralProgramsState = {
	gettingReferralPrograms: false,
	referralPrograms: [],
	referralProgramsError: null,
};

const defaultPatientsState = {
	patients: [],
	loading: false,
	patientError: null,
};

const defaultGlobalPatientsState = {
	patients: [],
	loading: false,
	error: null,
};

export const patients = (state = defaultPatientsState, action) => {
	if (action.type === actionTypes.PATIENTS) {
		const newState = Object.assign({}, state);
		newState.patients = action.payload;
		newState.patientError = null;
		return newState;
	} else if (action.type === actionTypes.PATIENTS_LOADING) {
		const newState = Object.assign({}, state);
		newState.loading = true;
		newState.patientError = null;
		return newState;
	} else if (action.type === actionTypes.PATIENTS_LOADING_COMPLETE) {
		const newState = Object.assign({}, state);
		newState.loading = false;
		return newState;
	} else if (action.type === actionTypes.PATIENTS_ERROR) {
		const newState = Object.assign({}, state);
		newState.patientError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const globalPatients = (
	state = defaultGlobalPatientsState,
	{ type, payload }
) => {
	if (type === actionTypes.GLOBAL_PATIENT_SEARCH_START) {
		return {
			...state,
			patients: [],
			loading: true,
			error: null,
		};
	} else if (type === actionTypes.GLOBAL_PATIENT_SEARCH_COMPLETED) {
		return {
			...state,
			patients: payload,
			loading: false,
			error: null,
		};
	} else if (type === actionTypes.GLOBAL_PATIENT_SEARCH_ERROR) {
		return {
			...state,
			patients: [],
			loading: false,
			error: payload,
		};
	} else if (type === actionTypes.GLOBAL_PATIENT_SEARCH_CLEAR) {
		return {
			...state,
			patients: [],
			loading: false,
			error: null,
		};
	}

	return { ...state };
};

export const patientdetail = (state = { patientdetail: [] }, action) => {
	if (action.type === actionTypes.PATIENT_DETAIL) {
		const newState = Object.assign({}, state);
		newState.patientdetail = action.payload;
		newState.gettingPatientDetail = false;
		newState.patientdetailError = null;
		return newState;
	} else if (action.type === actionTypes.GETTING_PATIENT_DETAIL) {
		const newState = Object.assign({}, state);
		newState.gettingPatientDetail = true;
		newState.patientdetailError = null;
		return newState;
	} else if (action.type === actionTypes.PATIENT_DETAIL_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingPatientDetail = false;
		newState.patientdetailError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const editpatient = (
	state = {
		patientdetail: [],
		updatingOutreach: false,
		updatingAddress: false,
	},
	action
) => {
	if (action.type === actionTypes.EDIT_PATIENT) {
		const newState = Object.assign({}, state);
		newState.patientdetail = action.payload;
		return newState;
	} else if (action.type === actionTypes.UPDATING_OUTREACH) {
		const newState = Object.assign({}, state);
		newState[
			'updatingOutreach' +
				(action.type.slice(-1).match(/\d/) ? action.type.slice(-1) : '')
		] = true;
		newState.updatingOutreachError = null;
		newState.updatingOutreachError1 = null;
		newState.updatingOutreachError2 = null;
		newState.updatingOutreachError3 = null;
		return newState;
	} else if (action.type === actionTypes.UPDATE_OUTREACH) {
		// will need more abstraction for numbered cases for outreach
		const newState = Object.assign({}, state);
		newState[
			'updatingOutreach' +
				(action.type.slice(-1).match(/\d/) ? action.type.slice(-1) : '')
		] = false;
		return newState;
	} else if (action.type === actionTypes.UPDATE_OUTREACH_ERROR) {
		// will need more abstraction for numbered cases for outreach
		const newState = Object.assign({}, state);
		newState[
			'updatingOutreach' +
				(action.type.slice(-1).match(/\d/) ? action.type.slice(-1) : '')
		] = false;
		newState.updatingOutreachError = action.payload;
		return newState;
	} else if (action.type === actionTypes.UPDATING_ADDRESS) {
		const newState = Object.assign({}, state);
		newState.updatingAddress = true;
		newState.updatedAddressError = null;
		return newState;
	} else if (action.type === actionTypes.UPDATE_ADDRESS) {
		const newState = Object.assign({}, state);
		newState.updatingAddress = false;
		newState.updatedAddress = action.payload;
		return newState;
	} else if (action.type === actionTypes.UPDATE_ADDRESS_ERROR) {
		const newState = Object.assign({}, state);
		newState.updatingAddress = false;
		newState.updatedAddressError = action.payload;
		return newState;
	} else if (action.type === actionTypes.UPDATING_RELATIONSHIP) {
		const newState = Object.assign({}, state);
		newState.updatingRelationship = true;
		newState.updatedRelationshipError = null;
		return newState;
	} else if (action.type === actionTypes.UPDATE_RELATIONSHIP) {
		const newState = Object.assign({}, state);
		newState.updatingRelationship = false;
		return newState;
	} else if (action.type === actionTypes.UPDATE_RELATIONSHIP_ERROR) {
		const newState = Object.assign({}, state);
		newState.updatingRelationship = false;
		newState.updatedRelationshipError = action.payload;
		return newState;
	} else if (action.type === actionTypes.RESET_PATIENT_EDIT_ERRORS) {
		const newState = Object.assign({}, state);
		newState.updatingOutreachError = null;
		newState.updatingOutreachError1 = null;
		newState.updatingOutreachError2 = null;
		newState.updatingOutreachError3 = null;
		newState.updatedAddressError = null;
		newState.updatedRelationshipError = null;
		return newState;
	} else {
		return state;
	}
};

export const deletepatient = (state = { patientdelete: [] }, action) => {
	if (action.type === actionTypes.DELETE_PATIENT) {
		const newState = Object.assign({}, state);
		newState.patientdelete = action.payload;
		return newState;
	} else if (action.type === actionTypes.RESET_DELETE_PATIENT) {
		const newState = Object.assign({}, state);
		newState.patientdelete = false;
		return newState;
	} else {
		return state;
	}
};

export const addpatient = (state = { addpatient: [] }, action) => {
	if (action.type === actionTypes.ADDING_PATIENT_LOADING) {
		const newState = Object.assign({}, state);
		newState.addpatient = {};
		newState.addingPatient = true;
		newState.addingPatientError = false;
		newState.message = null;
		return newState;
	} else if (action.type === actionTypes.ADD_PATIENT) {
		const newState = Object.assign({}, state);
		newState.addpatient = action.payload;
		newState.addingPatient = false;
		newState.addingPatientError = false;
		newState.message = action.message;
		return newState;
	} else if (action.type === actionTypes.ADD_PATIENT_ERROR) {
		const newState = Object.assign({}, state);
		newState.addpatient = {};
		newState.addingPatient = false;
		newState.addingPatientError = true;
		newState.message = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getReferralPrograms = (
	state = defaultReferralProgramsState,
	action
) => {
	if (action.type === actionTypes.GET_REFERRAL_PROGRAMS) {
		const newState = Object.assign({}, state);
		newState.referralPrograms = action.payload;
		newState.gettingReferralPrograms = false;
		newState.referralProgramsError = null;
		return newState;
	} else if (action.type === actionTypes.GETTING_REFERRAL_PROGRAMS) {
		const newState = Object.assign({}, state);
		newState.referralPrograms = [];
		newState.gettingReferralPrograms = true;
		newState.referralProgramsError = null;
		return newState;
	} else if (action.type === actionTypes.GET_REFERRAL_PROGRAMS_ERROR) {
		const newState = Object.assign({}, state);
		newState.referralPrograms = [];
		newState.gettingReferralPrograms = false;
		newState.referralProgramsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getAppointmentReferralPrograms = (
	state = { appointmentReferralPrograms: [] },
	action
) => {
	if (action.type === actionTypes.GET_APPT_REFERRAL_PROGRAMS) {
		const newState = Object.assign({}, state);
		newState.appointmentReferralPrograms = action.payload;
		newState.gettingReferralPrograms = false;
		newState.referralProgramsError = null;
		return newState;
	} else if (action.type === actionTypes.GETTING_APPT_REFERRAL_PROGRAMS) {
		const newState = Object.assign({}, state);
		newState.appointmentReferralPrograms = [];
		newState.gettingReferralPrograms = true;
		newState.referralProgramsError = null;
		return newState;
	} else if (action.type === actionTypes.GET_APPT_REFERRAL_PROGRAMS_ERROR) {
		const newState = Object.assign({}, state);
		newState.appointmentReferralPrograms = [];
		newState.gettingReferralPrograms = false;
		newState.referralProgramsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getContactMethods = (state = { contactMethods: [] }, action) => {
	if (action.type === actionTypes.CONTACT_METHODS) {
		const newState = Object.assign({}, state);
		newState.contactMethods = action.payload;
		newState.gettingContactMethods = false;
		newState.contactMethodsError = null;
		return newState;
	} else if (action.type === actionTypes.GETTING_CONTACT_METHODS) {
		const newState = Object.assign({}, state);
		newState.gettingContactMethods = true;
		newState.contactMethodsError = null;
		return newState;
	} else if (action.type === actionTypes.CONTACT_METHODS_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingContactMethods = false;
		newState.contactMethods = [];
		newState.contactMethodsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getContactOutcomes = (state = { contactOutcomes: [] }, action) => {
	if (action.type === actionTypes.GETTING_CONTACT_OUTCOMES) {
		const newState = Object.assign({}, state);
		newState.contactOutcomes = null;
		newState.gettingContactOutcomes = true;
		newState.contactOutcomesError = null;
		return newState;
	} else if (action.type === actionTypes.CONTACT_OUTCOMES) {
		const newState = Object.assign({}, state);
		newState.contactOutcomes = action.payload;
		newState.gettingContactOutcomes = false;
		newState.contactOutcomesError = null;
		return newState;
	} else if (action.type === actionTypes.CONTACT_OUTCOMES_ERROR) {
		const newState = Object.assign({}, state);
		newState.contactOutcomes = null;
		newState.gettingContactOutcomes = false;
		newState.contactOutcomesError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getReferralReasons = (state = { referralReasons: [] }, action) => {
	if (action.type === actionTypes.GETTING_REFERRAL_REASONS) {
		const newState = Object.assign({}, state);
		newState.referralReasonsLoading = true;
		newState.referralReasons = [];
		newState.referralReasonsError = null;
		return newState;
	} else if (action.type == actionTypes.REFERRAL_REASONS) {
		const newState = Object.assign({}, state);
		newState.referralReasonsLoading = false;
		newState.referralReasons = action.payload;
		newState.referralReasonsError = null;
		return newState;
	} else if (action.type == actionTypes.REFERRAL_REASONS_ERROR) {
		const newState = Object.assign({}, state);
		newState.referralReasonsLoading = false;
		newState.referralReasons = [];
		newState.referralReasonsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getReferralProviders = (
	state = { referralProviders: [] },
	action
) => {
	if (action.type === actionTypes.GETTING_REFERRAL_PROVIDERS) {
		const newState = Object.assign({}, state);
		newState.gettingReferralProviders = true;
		newState.referralProviders = [];
		newState.referralProvidersError = null;
		return newState;
	} else if (action.type == actionTypes.REFERRAL_PROVIDERS) {
		const newState = Object.assign({}, state);
		newState.gettingReferralProviders = false;
		newState.referralProviders = action.payload;
		newState.referralProvidersError = null;
		return newState;
	} else if (action.type === actionTypes.REFERRAL_PROVIDERS_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingReferralProviders = false;
		newState.referralProviders = [];
		newState.referralProvidersError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getEligibilityFields = (
	state = { eligibilityFields: [] },
	action
) => {
	if (action.type == actionTypes.ELIGIBILITY_FIELDS) {
		const newState = Object.assign({}, state);
		newState.eligibilityFields = action.payload;
		newState.eligibilityFieldsLoading = false;
		return newState;
	} else if (action.type === actionTypes.ELIGIBILITY_FIELDS_LOADING) {
		const newState = Object.assign({}, state);
		newState.eligibilityFields = [];
		newState.eligibilityFieldsLoading = true;
		return newState;
	} else if (action.type === actionTypes.ELIGIBILITY_FIELDS_ERROR) {
		const newState = Object.assign({}, state);
		newState.eligibilityFields = [];
		newState.eligibilityFieldsLoading = false;
		return newState;
	} else {
		return state;
	}
};

export const getConsenttotreat = (state = { consenttotreat: [] }, action) => {
	if (action.type === actionTypes.GETTING_CONSENT_TO_TREAT) {
		const newState = Object.assign({}, state);
		newState.consentToTreatLoading = true;
		newState.consenttotreat = null;
		newState.consentToTreatError = null;
		return newState;
	} else if (action.type == actionTypes.CONSENT_TO_TREAT) {
		const newState = Object.assign({}, state);
		newState.consentToTreatLoading = false;
		newState.consenttotreat = action.payload;
		newState.consentToTreatError = null;
		return newState;
	} else if (action.type == actionTypes.CONSENT_TO_TREAT_ERROR) {
		const newState = Object.assign({}, state);
		newState.consentToTreatLoading = false;
		newState.consenttotreat = null;
		newState.consentToTreatError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const setpassword = (state = { success: false }, action) => {
	if (action.type === actionTypes.LOADING_SET_PASSWORD) {
		const newState = Object.assign({}, state);
		newState.loadingSetPassword = true;
		newState.setPasswordError = null;
		return newState;
	} else if (action.type === actionTypes.SET_PASSWORD_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.loadingSetPassword = false;
		newState.success = action.payload.success;
		return newState;
	} else if (action.type === actionTypes.SET_PASSWORD_ERROR) {
		const newState = Object.assign({}, state);
		newState.success = action.payload.success;
		newState.loadingSetPassword = false;
		newState.setPasswordError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const saveinsurance = (state = { success: false }, action) => {
	if (action.type === actionTypes.SAVE_INSURANCE_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.success = true;
		newState.payload = action.payload;
		return newState;
	} else if (action.type === actionTypes.SAVE_INSURANCE_ERROR) {
		const newState = Object.assign({}, state);
		newState.success = false;
		newState.payload = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const saveinsuranceauth = (state = { success: false }, action) => {
	if (action.type === actionTypes.LOADING_SAVE_INSURANCE_AUTH) {
		const newState = Object.assign({}, state);
		newState.loadingSaveInsuranceAuth = true;
		newState.saveInsuranceAuthError = null;
		return newState;
	} else if (action.type === actionTypes.SAVE_INSURANCE_AUTH_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.loadingSaveInsuranceAuth = false;
		(newState.success = true), (newState.payload = action.payload);
		return newState;
	} else if (action.type === actionTypes.SAVE_INSURANCE_AUTH_ERROR) {
		const newState = Object.assign({}, state);
		(newState.success = false), (newState.payload = action.payload);
		newState.loadingSaveInsuranceAuth = false;
		newState.saveInsuranceAuthError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getInsuranceCompanies = (state = {}, action) => {
	if (action.type === actionTypes.GETTING_INSURANCE_COMPANIES) {
		const newState = Object.assign({}, state);
		newState.gettingInsuranceCompanies = true;
		newState.getInsuranceCompaniesError = null;
		return newState;
	} else if (action.type == actionTypes.GET_INSURANCE_COMPANIES_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.gettingInsuranceCompanies = false;
		newState.insuranceCompanies = action.payload;
		newState.getInsuranceCompaniesError = null;
		return newState;
	} else if (action.type === actionTypes.GET_INSURANCE_COMPANIES_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingInsuranceCompanies = false;
		newState.insuranceCompanies = null;
		newState.getInsuranceCompaniesError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getFullListOfInsuranceCompanies = (state = {}, action) => {
	if (action.type === actionTypes.GETTING_FULL_LIST_OF_INSURANCE_COMPANIES) {
		const newState = Object.assign({}, state);
		newState.gettingFullListOfInsuranceCompanies = true;
		newState.getFullListOfInsuranceCompaniesError = null;
		return newState;
	} else if (
		action.type == actionTypes.GET_FULL_LIST_OF_INSURANCE_COMPANIES_SUCCESS
	) {
		const newState = Object.assign({}, state);
		newState.gettingFullListOfInsuranceCompanies = false;
		newState.fullListOfinsuranceCompanies = action.payload;
		newState.getFullListOfInsuranceCompaniesError = null;
		return newState;
	} else if (
		action.type === actionTypes.GET_FULL_LIST_OF_INSURANCE_COMPANIES_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.gettingFullListOfInsuranceCompanies = false;
		newState.fullListOfinsuranceCompanies = null;
		newState.getFullListOfInsuranceCompaniesError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getInsuranceInfo = (state = {}, action) => {
	if (action.type == actionTypes.GET_INSURANCE_INFO_START) {
		const newState = Object.assign({}, state);
		newState.insuranceInfoStart = true;
		newState.insuranceInfoError = null;
		return newState;
	} else if (action.type == actionTypes.GET_INSURANCE_INFO_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.insuranceInfoStart = false;
		newState.insuranceInfo = action.payload;
		return newState;
	} else if (action.type == actionTypes.GET_INSURANCE_INFO_ERROR) {
		const newState = Object.assign({}, state);
		newState.insuranceInfoStart = false;
		newState.insuranceInfoError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getInsuranceAuths = (state = {}, action) => {
	if (action.type == actionTypes.LOADING_GET_INSURANCE_AUTHS) {
		const newState = Object.assign({}, state);
		newState.loadingInsuranceAuths = true;
		newState.insuranceAuthsError = null;
		return newState;
	} else if (action.type == actionTypes.GET_INSURANCE_AUTHS_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.loadingInsuranceAuths = false;
		newState.insuranceAuths = action.payload;
		return newState;
	} else if (action.type == actionTypes.GET_INSURANCE_AUTHS_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingInsuranceAuths = false;
		newState.insuranceAuthsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const noOutreach = (state = {}, action) => {
	if (action.type === actionTypes.GETTING_NO_OUTREACH_REASONS) {
		const newState = Object.assign({}, state);
		newState.gettingOutreachReasons = true;
		newState.noOutreachReasonsError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_NO_OUTREACH_REASONS) {
		const newState = Object.assign({}, state);
		newState.gettingOutreachReasons = false;
		newState.noOutreachReasons = action.payload;
		newState.noOutreachReasonsError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_NO_OUTREACH_REASONS_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingOutreachReasons = false;
		newState.noOutreachReasons = null;
		newState.noOutreachReasonsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const addreferringprovider = (state = { success: false }, action) => {
	if (action.type === actionTypes.ADDING_REFERRING_PROVIDER) {
		const newState = Object.assign({}, state);
		newState.success = false;
		newState.addingReferringProvider = true;
		newState.payload = null;
		return newState;
	} else if (action.type === actionTypes.ADD_REFERRING_PROVIDER_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.success = true;
		newState.addingReferringProvider = false;
		newState.payload = action.payload;
		return newState;
	} else if (action.type === actionTypes.ADD_REFERRING_PROVIDER_ERROR) {
		const newState = Object.assign({}, state);
		newState.success = false;
		newState.addingReferringProvider = false;
		newState.payload = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const addreferralreason = (state = { success: false }, action) => {
	if (action.type === actionTypes.ADD_REFERRAL_REASON_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.success = true;
		newState.payload = action.payload;
		return newState;
	} else if (action.type === actionTypes.ADD_REFERRAL_REASON_ERROR) {
		const newState = Object.assign({}, state);
		newState.success = false;
		newState.payload = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const addinitialcontactmethod = (state = { success: false }, action) => {
	if (action.type === actionTypes.ADD_CONTACT_METHOD_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.success = true;
		newState.payload = action.payload;
		return newState;
	} else if (action.type === actionTypes.ADD_CONTACT_METHOD_ERROR) {
		const newState = Object.assign({}, state);
		newState.success = false;
		newState.payload = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const createAthenaPatient = (state = {}, action) => {
	if (action.type === actionTypes.CREATING_ATHENA_PATIENT) {
		const newState = Object.assign({}, state);
		newState.creatingAthenaPatient = true;
		newState.createdAthenaPatient = null;
		newState.createdAthenaPatientError = null;
		return newState;
	} else if (action.type === actionTypes.CREATED_ATHENA_PATIENT_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.creatingAthenaPatient = false;
		newState.createdAthenaPatient = action.payload;
		newState.createdAthenaPatientError = null;
		return newState;
	} else if (action.type === actionTypes.CREATED_ATHENA_PATIENT_ERROR) {
		const newState = Object.assign({}, state);
		newState.creatingAthenaPatient = false;
		newState.createdAthenaPatient = null;
		newState.createdAthenaPatientError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const duplicatePatientCheck = (state = {}, action) => {
	if (action.type === actionTypes.DUPLICATE_PATIENT_CHECK_LOADING) {
		const newState = Object.assign({}, state);
		newState.duplicatePatientCheckLoading = true;
		newState.duplicatePatientCheck = null;
		newState.duplicatePatientCheckError = null;
		return newState;
	} else if (action.type === actionTypes.DUPLICATE_PATIENT_CHECK) {
		const newState = Object.assign({}, state);
		newState.duplicatePatientCheckLoading = false;
		newState.duplicatePatientCheck = action.payload;
		newState.duplicatePatientCheckError = null;
		return newState;
	} else if (
		action.type === actionTypes.DUPLICATE_PATIENT_CHECK_LOADING_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.duplicatePatientCheckLoading = false;
		newState.duplicatePatientCheck = null;
		newState.duplicatePatientCheckError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const linkproviders = (state = { linkproviders: [] }, action) => {
	if (action.type === actionTypes.PROVIDERS_PATIENT_LINKING_START) {
		const newState = Object.assign({}, state);
		newState.linkedProviders = {};
		newState.linkingProviders = true;
		newState.linkingProvidersError = false;
		newState.message = null;
		return newState;
	} else if (action.type === actionTypes.PROVIDERS_PATIENT_LINKING_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.linkedProviders = action.payload;
		newState.linkingProviders = false;
		newState.linkingProvidersError = false;
		newState.message = action.message;
		return newState;
	} else if (action.type === actionTypes.PROVIDERS_PATIENT_LINKING_ERROR) {
		const newState = Object.assign({}, state);
		newState.linkedProviders = {};
		newState.linkingProviders = false;
		newState.linkingProvidersError = true;
		newState.message = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const linkedproviders = (state = { linkedproviders: [] }, action) => {
	if (action.type === actionTypes.GET_LINKED_PATIENT_PROVIDERS_START) {
		const newState = Object.assign({}, state);
		newState.linkedProviders = [];
		newState.gettingProviders = true;
		newState.gettingProvidersError = false;
		newState.message = null;
		return newState;
	} else if (
		action.type === actionTypes.GET_LINKED_PATIENT_PROVIDERS_SUCCESS
	) {
		const newState = Object.assign({}, state);
		newState.linkedProviders = action.payload;
		newState.gettingProviders = false;
		newState.gettingProvidersError = false;
		newState.message = action.message;
		return newState;
	} else if (action.type === actionTypes.GET_LINKED_PATIENT_PROVIDERS_ERROR) {
		const newState = Object.assign({}, state);
		newState.linkedProviders = [];
		newState.gettingProviders = false;
		newState.gettingProvidersError = true;
		newState.message = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const unlinkprovider = (state = { unlinkprovider: [] }, action) => {
	if (action.type === actionTypes.GET_UNLINK_PATIENT_PROVIDER_START) {
		const newState = Object.assign({}, state);
		newState.unlinkProviderResult = {};
		newState.unlinkingProvider = true;
		newState.unlinkProviderError = false;
		newState.message = null;
		return newState;
	} else if (
		action.type === actionTypes.GET_UNLINK_PATIENT_PROVIDER_SUCCESS
	) {
		const newState = Object.assign({}, state);
		newState.unlinkProviderResult = action.payload;
		newState.unlinkingProvider = false;
		newState.unlinkProviderError = false;
		newState.message = action.message;
		return newState;
	} else if (action.type === actionTypes.GET_UNLINK_PATIENT_PROVIDER_ERROR) {
		const newState = Object.assign({}, state);
		newState.unlinkProviderResult = {};
		newState.unlinkingProvider = false;
		newState.unlinkProviderError = true;
		newState.message = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const updatelinkedprovider = (
	state = { updatelinkedprovider: [] },
	action
) => {
	if (action.type === actionTypes.GET_UPDATE_LINKED_PATIENT_PROVIDER_START) {
		const newState = Object.assign({}, state);
		newState.updatedLinkedprovider = {};
		newState.updatingLinkedProvider = true;
		newState.updatingLinkedProviderError = false;
		newState.message = null;
		return newState;
	} else if (
		action.type === actionTypes.GET_UPDATE_LINKED_PATIENT_PROVIDER_SUCCESS
	) {
		const newState = Object.assign({}, state);
		newState.updatedLinkedprovider = action.payload;
		newState.updatingLinkedProvider = false;
		newState.updatingLinkedProviderError = false;
		newState.message = action.message;
		return newState;
	} else if (
		action.type === actionTypes.GET_UPDATE_LINKED_PATIENT_PROVIDER_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.updatedLinkedprovider = {};
		newState.updatingLinkedProvider = false;
		newState.updatingLinkedProviderError = true;
		newState.message = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const patientTags = (state = {}, action) => {
	if (action.type === actionTypes.GET_PATIENT_TAGS_START) {
		const newState = Object.assign({}, state);
		newState.patientTagsLoading = true;
		newState.patientTags = null;
		newState.patientTagsError = null;
		return newState;
	} else if (action.type === actionTypes.GET_PATIENT_TAGS_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.patientTagsLoading = false;
		newState.patientTags = action.payload;
		newState.patientTagsError = null;
		return newState;
	} else if (action.type === actionTypes.GET_PATIENT_TAGS_ERROR) {
		const newState = Object.assign({}, state);
		newState.patientTagsLoading = false;
		newState.patientTags = null;
		newState.patientTagsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const gender = (state = {}, action) => {
	if (action.type === actionTypes.GET_GENDER_START) {
		const newState = Object.assign({}, state);
		newState.genderLoading = true;
		newState.gender = null;
		newState.genderError = null;
		return newState;
	} else if (action.type === actionTypes.GET_GENDER_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.genderLoading = false;
		newState.gender = action.payload;
		newState.genderError = null;
		return newState;
	} else if (action.type === actionTypes.GET_GENDER_ERROR) {
		const newState = Object.assign({}, state);
		newState.genderLoading = false;
		newState.gender = null;
		newState.genderError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const pronouns = (state = {}, action) => {
	if (action.type === actionTypes.GET_PRONOUNS_START) {
		const newState = Object.assign({}, state);
		newState.pronounsLoading = true;
		newState.pronouns = null;
		newState.pronounsError = null;
		return newState;
	} else if (action.type === actionTypes.GET_PRONOUNS_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.pronounsLoading = false;
		newState.pronouns = action.payload;
		newState.pronounsError = null;
		return newState;
	} else if (action.type === actionTypes.GET_PRONOUNS_ERROR) {
		const newState = Object.assign({}, state);
		newState.pronounsLoading = false;
		newState.pronouns = null;
		newState.pronounsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const sexAssignedAtBirth = (state = {}, action) => {
	if (action.type === actionTypes.GET_SEX_ASSIGNED_AT_BIRTH_START) {
		const newState = Object.assign({}, state);
		newState.sexAssignedAtBirthLoading = true;
		newState.sexAssignedAtBirth = null;
		newState.sexAssignedAtBirthError = null;
		return newState;
	} else if (action.type === actionTypes.GET_SEX_ASSIGNED_AT_BIRTH_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.sexAssignedAtBirthLoading = false;
		newState.sexAssignedAtBirth = action.payload;
		newState.sexAssignedAtBirthError = null;
		return newState;
	} else if (action.type === actionTypes.GET_SEX_ASSIGNED_AT_BIRTH_ERROR) {
		const newState = Object.assign({}, state);
		newState.sexAssignedAtBirthLoading = false;
		newState.sexAssignedAtBirth = null;
		newState.sexAssignedAtBirthError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const raceEnum = (state = {}, action) => {
	if (action.type === actionTypes.GET_RACE_ENUM_START) {
		const newState = Object.assign({}, state);
		newState.raceEnumLoading = true;
		newState.raceEnum = null;
		newState.raceEnumError = null;
		return newState;
	} else if (action.type === actionTypes.GET_RACE_ENUM_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.raceEnumLoading = false;
		newState.raceEnum = action.payload;
		newState.raceEnumError = null;
		return newState;
	} else if (action.type === actionTypes.GET_RACE_ENUM_ERROR) {
		const newState = Object.assign({}, state);
		newState.raceEnumLoading = false;
		newState.raceEnum = null;
		newState.raceEnumError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const ethnicityEnum = (state = {}, action) => {
	if (action.type === actionTypes.GET_ETHNICITY_ENUM_START) {
		const newState = Object.assign({}, state);
		newState.ethnicityEnumLoading = true;
		newState.ethnicityEnum = null;
		newState.ethnicityEnumError = null;
		return newState;
	} else if (action.type === actionTypes.GET_ETHNICITY_ENUM_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.ethnicityEnumLoading = false;
		newState.ethnicityEnum = action.payload;
		newState.ethnicityEnumError = null;
		return newState;
	} else if (action.type === actionTypes.GET_ETHNICITY_ENUM_ERROR) {
		const newState = Object.assign({}, state);
		newState.ethnicityEnumLoading = false;
		newState.ethnicityEnum = null;
		newState.ethnicityEnumError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const updateConsentStatus = (state = {}, action) => {
	if (action.type === actionTypes.UPDATING_CONSENT_STATUS) {
		const newState = Object.assign({}, state);
		newState.updatingConsent = true;
		newState.updatedConsentData = null;
		newState.updateConsentError = null;
		return newState;
	} else if (action.type === actionTypes.UPDATE_CONSENT_STATUS) {
		const newState = Object.assign({}, state);
		newState.updatingConsent = false;
		newState.updatedConsentData = action.payload;
		newState.updateConsentError = null;
		return newState;
	} else if (action.type === actionTypes.UPDATE_CONSENT_STATUS_ERROR) {
		const newState = Object.assign({}, state);
		newState.updatingConsent = false;
		newState.updatedConsentData = null;
		newState.updateConsentError = action.payload;
		return newState;
	} else {
		return state;
	}
};
