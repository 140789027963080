//React & Redux
import React, { Fragment, useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import {
	OrderRequestStatusHierarchyEnums,
	OrderStatusHierarchyEnums,
	filterNonLegacyTestEnumes,
	Timezones,
	filterOrderStatusEnumes,
	MLOORderStatusHierarchyEnums,
} from './Common/utilsfunctions';
import { TestV2TabsIndex, isExternalUser } from '../../../../utils';
import { hydrateProviders } from '../../../../transformers/patients/encounterTransformer';

//Actions & Services
import {
	encounterdetail,
	getencountervisitsschema,
	getBillingEncounterDetail,
	LoadEncounterDetailTestEnums,
	LoadAlternateTestEnums,
	LoadEditTestOrderTestEnums,
	editencounter,
	getencountergroupsessionschema,
	getspecificencounterenum,
	getencounterccintakeschema,
	getresearchinvoicestatusenum,
	getencounterresearchdataschema,
	clearencounterdetail,
	getencounterlabseschema,
	uploadDocument,
	saveBillingEncounter,
	encounterreclaim,
	encounterReclaimReset,
	getPreauthorizationsEnums,
	getGeneTestEnums,
	getTestOrderRequest,
	getSCPClinicalInfoDetail,
	getPreauthorizationTestOrders,
	getTestOrderNotes,
	updateTestOrder,
	getAuditTrailEncounter,
	getEncounterAuditEnum,
	getEncounterBillingEnum,
	createTestOrderFlags,
	updateTestOrderFlags,
	getContentViewedByPatient,
	getOutreachs,
	linkProvidersEncounter,
	unlinkProviderEncounter,
	getVisitIndications,
	updateTestOrderRedFlag,
	testBulkUpdate,
} from '../../../../actions/encounter';
import {
	linkProviders,
	getLinkedProviders,
	updateLinkedProvider,
	getReferralPrograms,
	updateAddress,
} from '../../../../actions/patients';
import { LoadUser, LoadUsers, resetLoadUser } from '../../../../actions/users';
import { getProviderSpecialties } from './../../../../actions/providers.js';
import { getPatientFeeEnum } from './../../../../actions/billing.js';
import { LoadAppointmentProvider } from '../../../../actions/scheduling.js';
import {getDefaultSchedulingOptions} from '../../../../actions/referralprograms/referralprograms'
//Other Libraries
import moment from 'moment';

//Components
import EncounterHeader from './EncounterHeader';
import EncounterBody from './EncounterBody';
import AuditTrail from './AuditTrail/AuditTrail.js';
import ProviderLinkDialog from '../../../ExternalProviders/ProviderLinkDialog';
import ProviderUnlinkDialog from '../../../ExternalProviders/ProviderUnlinkDialog';

//Styles
import './style.css';
import { usePrevious } from '../../../../hooks/usePrevious';

const EncounterDetail = (props) => {
	const {
		getAuditEncounterEnum,
		getBillingEncounterEnum,
		clearencounterDetail,
		reclaimEncounterReset,
		activeRole,
		getencouter,
		getBillingEncounter,
		encounterBodyProps,
		encounter_uuid,
		unlinkProviderFromEncounter,
		patient_uuid,
		linkProvidersToEncounter,
		encounterLinkedProviders,
		getttingLinkedProvidersPatient,
		linkingProvidersPatient,
		linkingProvidersEncounter,
		updatingLinkedProviderPatient,
		unlinkingProviderEncounter,
		providerSpecialties,
		setReloadProviders,
		updateLinkedProviderPatient,
		linkProvidersToPatient,
		encounterHeaderProps,
		auditTrailEncounterLoading,
		auditTrailEncounter,
		auditTrailEncounterError,
		billingEncounterUUID,
		encounterAuditEnum,
		encounterAuditEnumLoading,
		encounterBillingEnum,
		encounterBillingEnumLoading,
		getEncounterAuditTrail,
		enqueueSnackbar,
		linkedProviders,
		getLinkedAllProviders,
		updatingLinkedProviderPatientError,
		reloadProviders,
		linkingProvidersPatientError,
		linkingProvidersEncounterError,
		unlinkingProviderEncounterError,
		getAllReferralPrograms,
		getDefaultSchedulingEnums
	} = props;

	const [auditTrail, setAuditTrail] = useState(false);
	const [auditTrailEncounterUuid, setAuditTrailEncounterUuid] =
		useState(null);
	const [initialEncounterGet, setInitialEncounterGet] = useState(false);
	const [showProviderLinkDialog, setshowProviderLinkDialog] = useState(false);
	const [showProviderUnlinkDialog, setShowProviderUnlinkDialog] =
		useState(false);
	const [externalProviders, setExternalProviders] = useState([]);
	const [providerUUID, setProviderUUID] = useState(null);
	const [linkedTo] = useState('encounter');
	const [editingProvider, setEditingProvider] = useState(false);
	const [editingLinkInfo, setEditingLinkInfo] = useState(false);
	const [expandExtProviders, setExpandExtProviders] = useState(false);
	const [flagSuccessFunc, setFlagSuccessFunc] = useState(null);

	const previousEncounterUUID = usePrevious(encounter_uuid);
	const previousEncounterHeaderProps = usePrevious(encounterHeaderProps);
	const previousEncounterBodyProps = usePrevious(encounterBodyProps);

	const previousLinkingProvidersPatient = usePrevious(
		linkingProvidersPatient
	);
	const previousLinkingProvidersEncounter = usePrevious(
		linkingProvidersEncounter
	);
	const previousUpdatingLinkedProviderPatient = usePrevious(
		updatingLinkedProviderPatient
	);
	const previousUnlinkingProviderEncounter = usePrevious(
		unlinkingProviderEncounter
	);
	const previousLinkedProviders = usePrevious(linkedProviders);

	useEffect(() => {
		handleGetEncouter();
		if (!isExternalUser(activeRole)) {
			getAuditEncounterEnum();
			getBillingEncounterEnum();
		}
		getDefaultSchedulingEnums();
		getAllReferralPrograms();
		return () => {
			clearencounterDetail();
			reclaimEncounterReset();
		};
	}, []);

	useEffect(() => {
		if (encounter_uuid !== previousEncounterUUID) {
			handleGetEncouter(true);
		}
	}, [encounter_uuid]);

	useEffect(() => {
		if (
			get(
				previousEncounterHeaderProps,
				'gettingEncounterDetailLoading',
				false
			) &&
			!get(encounterHeaderProps, 'gettingEncounterDetailLoading', false)
		) {
			if (reloadProviders) {
				getLinkedAllProviders(patient_uuid);
			}
			if (
				!initialEncounterGet &&
				!get(encounterHeaderProps, 'gettingEncounterDetailError', null)
			) {
				setInitialEncounterGet(true);
			}
			setReloadProviders(false);
			setProviderUUID(null);
			setExpandExtProviders(true);
			setEditingProvider(false);
			setEditingLinkInfo(false);
			setExternalProviders(linkedProviders);
			getCombinedExtProviders();
		}
	}, [get(encounterHeaderProps, 'gettingEncounterDetailLoading', false)]);

	useEffect(() => {
		if (
			get(previousEncounterBodyProps, 'creatingTestOrderFlags', false) &&
			!get(encounterBodyProps, 'creatingTestOrderFlags', false)
		) {
			if (get(encounterBodyProps, 'createdTestOrderFlagsError', false)) {
				enqueueSnackbar('Error in adding flags', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				if (
					get(
						encounterBodyProps,
						'visitEncounterProps.callTestOrderRequest',
						null
					)
				) {
					encounterBodyProps.visitEncounterProps.callTestOrderRequest();
					if (flagSuccessFunc) flagSuccessFunc();
				}
				enqueueSnackbar('Flags added successfully', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			}
			setFlagSuccessFunc(null);
		}
	}, [get(encounterBodyProps, 'creatingTestOrderFlags', false)]);

	useEffect(() => {
		if (
			get(previousEncounterBodyProps, 'updatingTestOrderFlags', false) &&
			!get(encounterBodyProps, 'updatingTestOrderFlags', false)
		) {
			if (get(encounterBodyProps, 'updatedTestOrderFlagsError', false)) {
				enqueueSnackbar('Error in updating flags', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				if (
					get(
						encounterBodyProps,
						'visitEncounterProps.callTestOrderRequest',
						null
					)
				) {
					encounterBodyProps.visitEncounterProps.callTestOrderRequest();
					if (flagSuccessFunc) flagSuccessFunc();
				}
				enqueueSnackbar('Flags updated successfully', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			}
			setFlagSuccessFunc(null);
		}
	}, [get(encounterBodyProps, 'updatingTestOrderFlags', false)]);

	useEffect(() => {
		if (
			get(
				previousEncounterBodyProps,
				'visitEncounterProps.contentViewedByPatientLoading',
				false
			) &&
			!get(
				encounterBodyProps,
				'visitEncounterProps.contentViewedByPatientLoading',
				false
			)
		) {
			if (
				get(
					encounterBodyProps,
					'visitEncounterProps.contentViewedByPatientError',
					null
				)
			) {
				enqueueSnackbar('Error in getting content viewed by patient', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			}
		}
	}, [
		get(
			encounterBodyProps,
			'visitEncounterProps.contentViewedByPatientLoading',
			false
		),
	]);

	useEffect(() => {
		if (previousLinkingProvidersPatient && !linkingProvidersPatient) {
			if (linkingProvidersPatientError) {
				enqueueSnackbar('Error in linking providers to patient', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				setReloadProviders(true);
				linkProviderToEncounter(providerUUID);
			}
		}
	}, [linkingProvidersPatient]);

	useEffect(() => {
		if (previousLinkingProvidersEncounter && !linkingProvidersEncounter) {
			if (linkingProvidersEncounterError) {
				enqueueSnackbar('Error in linking providers to encounter', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				enqueueSnackbar('Provider linked', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				setReloadProviders(true);
				handleGetEncouter(null);
			}
		}
	}, [linkingProvidersEncounter]);

	useEffect(() => {
		if (
			previousUpdatingLinkedProviderPatient &&
			!updatingLinkedProviderPatient
		) {
			if (updatingLinkedProviderPatientError) {
				enqueueSnackbar('Error in updating provider', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				enqueueSnackbar('Provider updated', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				setReloadProviders(true);
				handleGetEncouter(null);
			}
		}
	}, [updatingLinkedProviderPatient]);

	useEffect(() => {
		if (previousUnlinkingProviderEncounter && !unlinkingProviderEncounter) {
			if (unlinkingProviderEncounterError) {
				enqueueSnackbar('Error in unlinking provider', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				enqueueSnackbar('Provider unlinked', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				setReloadProviders(true);
				handleGetEncouter(null);
			}
		}
	}, [unlinkingProviderEncounter]);
	useEffect(() => {
		if (previousLinkedProviders != linkedProviders) {
			setProviderUUID(null);
			setExpandExtProviders(true);
			setEditingProvider(false);
			setEditingLinkInfo(false);
			setExternalProviders(linkedProviders);
			getCombinedExtProviders();
		}
	}, [linkedProviders]);

	const { defaultProviders } = useSelector(
		(store) => ({
			defaultProviders:
				store?.referralProgramsList?.loadedReferralProgramDetail
					?.defaultProviders,			
		})
	);

	const handleGetEncouter = (doclear) => {
		getencouter(encounter_uuid, doclear);
		getBillingEncounter(encounter_uuid);
	};

	const openAuditTrail = (encounterUuid) => {
		if (encounterUuid) {
			setAuditTrail(true);
			setAuditTrailEncounterUuid(encounterUuid);
		}
	};

	const closeAuditTrail = () => {
		setAuditTrail(false);
		setAuditTrailEncounterUuid(null);
	};

	const createTestRequestFlags = (testOrderUuid, payload, successFunc) => {
		if (testOrderUuid && payload && successFunc) {
			setFlagSuccessFunc(successFunc);
			encounterBodyProps.createTestRequestFlags(testOrderUuid, payload);
		}
	};

	const updateTestRequestFlags = (testOrderUuid, payload, successFunc) => {
		if (testOrderUuid && payload) {
			setFlagSuccessFunc(successFunc);
			encounterBodyProps.updateTestRequestFlags(testOrderUuid, payload);
		}
	};

	const getSelectedProvider = () => {
		return (externalProviders || []).find(
			(el) => el.providerUUID == providerUUID
		);
	};

	const handleProviderLinkDialog = () => {
		const tempShowProviderLinkDialog = !showProviderLinkDialog;
		setshowProviderLinkDialog(tempShowProviderLinkDialog);
		if (!tempShowProviderLinkDialog) {
			setProviderUUID(null);
			setEditingProvider(false);
			setEditingLinkInfo(false);
		}
	};

	const handleProviderUnlinkDialog = () => {
		const tempShowProviderUnlinkDialog = !showProviderUnlinkDialog;
		setShowProviderUnlinkDialog(tempShowProviderUnlinkDialog);
		if (!tempShowProviderUnlinkDialog) {
			setProviderUUID(null);
		}
	};

	const unlinkProviderInfo = (providerUUID) => {
		setProviderUUID(providerUUID);
		handleProviderUnlinkDialog();
	};

	const handleProviderUnlink = () => {
		const selectedProvider = getSelectedProvider();
		const { providerUUID } = selectedProvider;
		setShowProviderUnlinkDialog(false);
		unlinkProviderFromEncounter(encounter_uuid, providerUUID);
	};

	const editLinkInfo = (providerUUID) => {
		setProviderUUID(providerUUID);
		setshowProviderLinkDialog(true);
		setEditingLinkInfo(true);
	};

	const editProviderInfo = (providerUUID) => {
		setProviderUUID(providerUUID);
		setshowProviderLinkDialog(true);
		setEditingProvider(true);
	};

	const linkProviderToEncounter = (providerUUID) => {
		if (providerUUID) {
			linkProvidersToEncounter({
				encounter_providers: [
					{
						patient_uuid: patient_uuid,
						encounter_uuid: encounter_uuid,
						provider_uuid: providerUUID,
					},
				],
			});
		}
	};

	const getCombinedExtProviders = () => {
		const tempExternalProviders = [];
		const encounterProvider = encounterLinkedProviders;
		const patientProviders = [...linkedProviders];

		patientProviders.forEach((item) => {
			tempExternalProviders.push(item);
		});
		encounterProvider.forEach((item) => {
			const obj = patientProviders.find(
				(el) => el && el.providerUUID == item.providerUUID
			);
			if (!_.isEmpty(obj)) {
				const index = _.findIndex(tempExternalProviders, {
					providerUUID: item.providerUUID,
				});
				tempExternalProviders[index] = {
					...obj,
					linkedToEncounter: _.get(item, 'linkedToEncounter', false),
				};
			} else {
				tempExternalProviders.push(item);
			}
		});
		//sorting
		const extPro = [...tempExternalProviders];
		const criteria1 = extPro.filter(
			(el) =>
				el &&
				!el.isArchived &&
				el.linkedToEncounter &&
				el.linkedToPatient
		);
		const criteria2 = extPro.filter(
			(el) =>
				el &&
				el.isArchived &&
				el.linkedToEncounter &&
				el.linkedToPatient
		);
		const criteria3 = extPro.filter(
			(el) =>
				el &&
				!el.isArchived &&
				el.linkedToEncounter &&
				!el.linkedToPatient
		);
		const criteria4 = extPro.filter(
			(el) =>
				el &&
				!el.isArchived &&
				!el.linkedToEncounter &&
				el.linkedToPatient
		);
		const criteria5 = extPro.filter(
			(el) =>
				el &&
				el.isArchived &&
				el.linkedToEncounter &&
				!el.linkedToPatient
		);
		const criteria6 = extPro.filter(
			(el) =>
				el &&
				el.isArchived &&
				!el.linkedToEncounter &&
				el.linkedToPatient
		);
		const extProCombine = [
			...(criteria1 || []),
			...(criteria2 || []),
			...(criteria3 || []),
			...(criteria4 || []),
			...(criteria5 || []),
			...(criteria6 || []),
		];
		setExternalProviders([...extProCombine]);
	};

	const getExternalProviderProps = () => {
		
		const _externalProviders = externalProviders.map(ep => ({
			...ep,
			isDefaultProvider: defaultProviders && defaultProviders.some(dp => dp.uuid === ep.providerUUID) || false,
		}));

		return {
			handleProviderLinkDialog: handleProviderLinkDialog,
			handleProviderUnlinkDialog: handleProviderUnlinkDialog,
			editLinkInfo: editLinkInfo,
			editProviderInfo: editProviderInfo,
			unlinkProviderInfo: unlinkProviderInfo,
			linkProviderToEncounter: linkProviderToEncounter,
			externalProviders: _externalProviders,
			expandExtProviders: expandExtProviders,
			loading:
				getttingLinkedProvidersPatient ||
				linkingProvidersPatient ||
				linkingProvidersEncounter ||
				updatingLinkedProviderPatient ||
				unlinkingProviderEncounter,
			providerSpecialties: providerSpecialties || [],
		};
	};

	const addExternalProvider = (data) => {
		const selectedProvider = getSelectedProvider();
		if (selectedProvider) {
			if (editingProvider) {
				setEditingProvider(false);
				setshowProviderLinkDialog(false);
				setProviderUUID(null);
				setReloadProviders(true);
				handleGetEncouter();
			} else {
				const { providerUUID } = selectedProvider;
				const index = _.findIndex(externalProviders, {
					providerUUID: providerUUID,
				});
				externalProviders && externalProviders.splice(index, data);
				setExternalProviders(externalProviders);
				setshowProviderLinkDialog(false);
				const { patientUUID, providerRole } = data;
				updateLinkedProviderPatient(patientUUID, data.providerUUID, {
					provider_role: providerRole || [],
					receive_all_communications: false,
				});
			}
		} else {
			setshowProviderLinkDialog(false);
			setProviderUUID(_.get(data, 'providerUUID', null));
			linkProvidersToPatient(patient_uuid, {
				data: [
					{
						patient_uuid: patient_uuid,
						provider_uuid: _.get(data, 'providerUUID', null),
						provider_role: _.get(data, 'providerRole', []),
						start_date: moment().format('YYYY-MM-DD'),
						end_date: null,
						receive_all_communications: false,
					},
				],
			});
		}
	};

	return (
		<Fragment>
			<section className='encntr-detail'>
				<EncounterHeader
					{...encounterHeaderProps}
					getencouter={(doclear) => handleGetEncouter(doclear)}
					openAuditTrail={openAuditTrail}
					activeRole={activeRole}
					initialEncounterGet={initialEncounterGet}
				/>
				<EncounterBody
					{...encounterBodyProps}
					getencouter={(doclear) => handleGetEncouter(doclear)}
					createTestRequestFlags={createTestRequestFlags}
					updateTestRequestFlags={updateTestRequestFlags}
					externalProviderProps={getExternalProviderProps()}
				/>
			</section>
			{auditTrail && (
				<AuditTrail
					auditTrailBodyProps={{
						allUsers: get(
							encounterBodyProps,
							'visitEncounterProps.allUsers'
						),
						createdByUser: get(
							encounterBodyProps,
							'visitEncounterProps.createdByUser'
						),
						getAllUsers: get(
							encounterBodyProps,
							'visitEncounterProps.getAllUsers'
						),
						getCreatedByUser: get(
							encounterBodyProps,
							'visitEncounterProps.getCreatedByUser'
						),
						resetUser: get(
							encounterBodyProps,
							'visitEncounterProps.resetUser'
						),
						roles: get(
							encounterBodyProps,
							'visitEncounterProps.roles',
							[]
						),
					}}
					auditTrailEncounterProps={{
						auditTrailEncounterLoading: auditTrailEncounterLoading,
						auditTrailEncounter: auditTrailEncounter || [],
						auditTrailEncounterError: auditTrailEncounterError,
					}}
					closeAuditTrail={closeAuditTrail}
					billingEncounterUUID={billingEncounterUUID}
					encounter={get(encounterHeaderProps, 'encounter', {})}
					encounterAuditBillingProps={{
						encounterAuditEnum: encounterAuditEnum,
						encounterAuditEnumLoading: encounterAuditEnumLoading,
						encounterBillingEnum: encounterBillingEnum || [],
						encounterBillingEnumLoading:
							encounterBillingEnumLoading,
					}}
					encounterUuid={auditTrailEncounterUuid}
					encounterValueEnums={{
						ccIntakeEncounterProps: get(
							encounterBodyProps,
							'ccIntakeEncounterProps.ccintakeschema',
							{}
						),
						groupSessionEncounterProps: get(
							encounterBodyProps,
							'groupSessionEncounterProps.groupsessionschema',
							{}
						),
						labEncounterProps: get(
							encounterBodyProps,
							'labEncounterProps.labsschema',
							{}
						),
						researchEncounterProps: get(
							encounterBodyProps,
							'researchEncounterProps.researchdataschema',
							{}
						),
						visitEncounterProps: get(
							encounterBodyProps,
							'visitEncounterProps.visitsschema',
							{}
						),
					}}
					getEncounterAuditTrail={getEncounterAuditTrail}
					enqueueSnackbar={enqueueSnackbar}
					patient={get(
						encounterBodyProps,
						'visitEncounterProps.patientdetail',
						{}
					)}
				/>
			)}
			{showProviderLinkDialog && (
				<ProviderLinkDialog
					linkedTo={linkedTo}
					visible={showProviderLinkDialog}
					cancel={handleProviderLinkDialog}
					confirm={handleProviderLinkDialog}
					addExternalProvider={addExternalProvider}
					selectedProvider={getSelectedProvider()}
					selectedProviders={externalProviders}
					enqueueSnackbar={enqueueSnackbar}
					editingProvider={editingProvider}
					editingLinkInfo={editingLinkInfo}
					providerSpecialties={providerSpecialties || []}
				/>
			)}
			{showProviderUnlinkDialog && (
				<ProviderUnlinkDialog
					content={
						'Are you sure you want to unlink the external provider from this encounter?'
					}
					visible={showProviderUnlinkDialog}
					cancel={handleProviderUnlinkDialog}
					confirm={handleProviderUnlink}
				/>
			)}
		</Fragment>
	);
};

const mapStateToProps = (state, props) => {
	const linkingProvidersPatient = _.get(
		state,
		'linkpatientproviders.linkingProviders'
	);
	const linkingProvidersPatientError = _.get(
		state,
		'linkpatientproviders.linkingProvidersError'
	);
	const linkingProvidersEncounter = _.get(
		state,
		'linkencounterproviders.linkingProviders'
	);
	const linkingProvidersEncounterError = _.get(
		state,
		'linkencounterproviders.linkingProvidersError'
	);
	const updatingLinkedProviderPatient = _.get(
		state,
		'updatelinkedpatientprovider.updatingLinkedProvider'
	);
	const updatingLinkedProviderPatientError = _.get(
		state,
		'updatelinkedpatientprovider.updatingLinkedProviderError'
	);
	const unlinkingProviderEncounter = _.get(
		state,
		'unlinkencounterprovider.unlinkingProvider'
	);
	const unlinkingProviderEncounterError = _.get(
		state,
		'unlinkencounterprovider.unlinkProviderError'
	);
	const getttingLinkedProvidersPatient = _.get(
		state,
		'linkedpatientproviders.gettingProviders',
		false
	);

	const encounter_type = _.get(state, 'encounterdetail.encounter.type', '');
	const encounter = _.get(state, 'encounterdetail.encounter', {});
	const gettingEncounterDetailLoading = _.get(
		state,
		'encounterdetail.gettingEncounterDetailLoading',
		null
	);
	const gettingEncounterDetailError = _.get(
		state,
		'encounterdetail.gettingEncounterDetailError',
		null
	);
	const billingencounter = _.get(
		state,
		['getBillingEncounterData', 'billingobject', 'data', '0'],
		{}
	);
	const billingEncounterUUID = _.get(billingencounter, ['uuid'], null);
	const scpClinicalInfo = _.get(
		state,
		['getSCPClinicalInfoData', 'clinicalInfo', 'data'],
		[]
	);
	const visitsschema = _.get(state, ['visitsschema', 'schema', 'data'], {});
	const testnameenums = _.get(state, ['testEnums'], []);
	const alternatetestnameenums = _.get(state, ['alternateTestEnums'], []);
	const testOrderPopupTestEnums = _.get(
		state,
		['editTestOrderTestEnums'],
		[]
	);
	const nonlegacytestOrderPopupTestEnumsLoading = _.get(
		state,
		['editTestOrderTestEnums'],
		false
	);
	const groupsessionschema = _.get(
		state,
		['groupsessionschema', 'schema', 'data'],
		{}
	);
	const ccintakeschema = _.get(
		state,
		['ccintakeschema', 'schema', 'data'],
		{}
	);
	const ordering_physician = _.get(state, ['orderingphysicianenum'], {});
	const visit_provider = _.get(state, ['visitproviderenum'], {});
	const labsschema = _.get(state, ['labsschema', 'schema', 'data'], {});
	const researchdataschema = _.get(
		state,
		['researchdataschema', 'schema', 'data'],
		{}
	);
	const partnerinvoicestatus = _.get(state, ['partnerinvoicestatus'], []);
	const patientdetail = _.get(
		state,
		['patientdetail', 'patientdetail', '0'],
		{}
	);
	const partnerUUID =  _.get(state,['patientdetail', 'patientdetail', '0','partner_uuid'],{});
	const orderrequest = _.get(state, ['orderrequest', 'orderrequest'], {});
	const encounterReclaim = _.get(state, ['encounterReclaim'], {});
	const preauthorizationsEnums = _.get(state, ['preauthorizationsEnums'], {});
	const encounterPreauthTestOrders = _.get(
		state,
		['encounterPreauthTestOrders', 'data'],
		[]
	);
	const sampleTypeEnums = _.get(
		state,
		['gentestenums', 'gentestenums', 'sample_type'],
		{}
	);
	const patientBillingMethodEnums = _.get(
		state,
		['gentestenums', 'gentestenums', 'patient_billing_method'],
		{}
	);
	const MLOOrderStatus = _.get(
		state,
		['gentestenums', 'gentestenums', 'order_status'],
		[]
	);
	const outreachStatusEnums = _.get(
		state,
		['gentestenums', 'gentestenums', 'outreach_status'],
		[]
	);
	const patientDeclinedReasonsEnums = _.get(
		state,
		['gentestenums', 'gentestenums', 'declined_reasons'],
		[]
	);
	const labOrderStatusEnums = _.get(
		state,
		['gentestenums', 'gentestenums', 'lab_order_status'],
		[]
	);
	const gcRoROutreachStopReasonsEnums = _.get(
		state,
		['gentestenums', 'gentestenums', 'ror_outreach_stop_reasons'],
		[]
	);
	const encounterTestOrderRequest = _.orderBy(
		_.get(state, ['encounterTestOrderRequest', 'data'], []),
		(o) => {
			return new moment(_.get(o, 'created_at', null));
		},
		['asc']
	);
	const encounterTestOrderRequestLoading = _.get(
		state,
		'encounterTestOrderRequest.loading',
		null
	);
	const preauthdispatchstatus = _.get(
		state,
		['preauthorizationsEnums', 'preauthorizationsenums', 'dispatch_status'],
		[]
	);
	const preauthdecisionenums = _.get(
		state,
		[
			'preauthorizationsEnums',
			'preauthorizationsenums',
			'preauthorization_decision',
		],
		[]
	);
	const user = _.get(state, ['me', 'user'], {});
	const activeRole = _.get(state, ['me', 'activerole', 'name'], '');
	const isBillingCoordinator = _.get(
		state,
		['me', 'activerole', 'isBillingCoordinator'],
		false
	);
	const testOrderNotes = _.get(state, ['getTestOrderNotes', 'data'], []);
	const auditTrailEncounterLoading = _.get(
		state,
		'auditTrailEncounter.auditTrailEncounterLoading',
		null
	);
	const auditTrailEncounter =
		_.get(state, 'auditTrailEncounter.auditTrailEncounter', []) || [];
	const auditTrailEncounterError = _.get(
		state,
		'auditTrailEncounter.auditTrailEncounterError',
		null
	);
	const encounterAuditEnumLoading = _.get(
		state,
		'auditTrailEncounter.encounterAuditEnumLoading',
		null
	);
	const encounterAuditEnum =
		_.get(state, 'auditTrailEncounter.encounterAuditEnum', []) || [];
	const encounterAuditEnumError = _.get(
		state,
		'auditTrailEncounter.encounterAuditEnumError',
		null
	);
	const encounterBillingEnumLoading = _.get(
		state,
		'auditTrailEncounter.encounterBillingEnumLoading',
		null
	);
	const encounterBillingEnum =
		_.get(state, 'auditTrailEncounter.encounterBillingEnum', []) || [];
	const encounterBillingEnumError = _.get(
		state,
		'auditTrailEncounter.encounterBillingEnumError',
		null
	);
	const creatingTestOrderFlags = _.get(
		state,
		'testOrder.creatingTestOrderFlags',
		null
	);
	const createdTestOrderFlags =
		_.get(state, 'testOrder.createdTestOrderFlags', {}) || {};
	const createdTestOrderFlagsError = _.get(
		state,
		'testOrder.createdTestOrderFlagsError',
		null
	);
	const updatingTestOrderFlags = _.get(
		state,
		'testOrder.updatingTestOrderFlags',
		null
	);
	const updatedTestOrderFlags =
		_.get(state, 'testOrder.updatedTestOrderFlags', {}) || {};
	const updatedTestOrderFlagsError = _.get(
		state,
		'testOrder.updatedTestOrderFlagsError',
		null
	);
	const contentViewedByPatientLoading = _.get(
		state,
		'contentViewedByPatient.contentViewedByPatientLoading',
		null
	);
	const contentViewedByPatient =
		_.get(state, 'contentViewedByPatient.contentViewedByPatient', []) || [];
	const contentViewedByPatientError = _.get(
		state,
		'contentViewedByPatient.contentViewedByPatientError',
		null
	);
	const createdByUser = _.get(state, ['User', 'data'], {});
	const allUsers = _.get(state, ['Users', 'data'], {});
	const roles = _.get(state, ['UserRoles', 'data'], []);
	const encounterCommunications = _.get(
		state,
		['encounterCommunications', 'data'],
		[]
	);
	const outreachEnums = _.get(state, ['outreach', 'outreachEnums'], []);
	const encounterLinkedProviders = hydrateProviders(
		_.get(encounter, 'providers', [])
	);
	const testOrderUpdating = _.get(
		state,
		['testOrder', 'testOrderUpdating'],
		false
	);
	const gettingProviderSpecialties = _.get(
		state,
		'providerEnums.gettingProviderSpecialties',
		false
	);
	const providerSpecialties =
		_.get(state, 'providerEnums.providerSpecialties', null) || [];
	const providerSpecialtiesError = _.get(
		state,
		'providerEnums.providerSpecialtiesError',
		null
	);
	const patientFeeEnum =
		_.get(state, 'patientFeeEnum.patientFeeEnum', null) || [];
	const patientFeeEnumLoading = _.get(
		state,
		'patientFeeEnum.patientFeeEnumLoading',
		false
	);
	const patientFeeEnumError = _.get(
		state,
		'patientFeeEnum.patientFeeEnumError',
		false
	);
	const visitIndicationsLoading = _.get(
		state,
		'visitIndications.visitIndicationsLoading',
		false
	);
	const visitIndications =
		_.get(state, 'visitIndications.visitIndications', null) || [];
	const visitIndicationsError = _.get(
		state,
		'visitIndications.visitIndicationsError',
		null
	);
	const gettingReferralPrograms = _.get(
		state,
		'referralPrograms.gettingReferralPrograms',
		null
	);
	const referralPrograms =
		_.get(state, 'referralPrograms.referralPrograms.data', null) || [];
	const referralProgramsError = _.get(
		state,
		'referralPrograms.referralProgramsError',
		null
	);
	const testOrderRedFlagUpdating = _.get(
		state,
		'updateTestOrderRedFlag.testOrderRedFlagUpdating',
		null
	);
	const testOrderRedFlagUpdated = _.get(
		state,
		'updateTestOrderRedFlag.testOrderRedFlagUpdated',
		null
	);
	const testOrderRedFlagUpdatedError = _.get(
		state,
		'updateTestOrderRedFlag.testOrderRedFlagUpdatedError',
		null
	);
	const defaultSchedulingOptionssSpeciality = _.get(state,['referralProgramsList','defaultSchedulingOptions','payload','specialties'],[]);

	const partnerLevelSpecialities = defaultSchedulingOptionssSpeciality && defaultSchedulingOptionssSpeciality.map((el) => ({
		key: el.id,
		label: el.display_name,
		value: el.name.toLowerCase(),
	}));
	const scheduling_visit_providers= state.Scheduling.providers

	return {
		encounter_type,
		encounter,
		gettingEncounterDetailLoading,
		gettingEncounterDetailError,
		visitsschema,
		billingencounter,
		billingEncounterUUID,
		testnameenums,
		alternatetestnameenums,
		testOrderPopupTestEnums,
		nonlegacytestOrderPopupTestEnumsLoading,
		groupsessionschema,
		ccintakeschema,
		ordering_physician,
		visit_provider,
		researchdataschema,
		partnerinvoicestatus,
		labsschema,
		orderrequest,
		patientdetail,
		encounterReclaim,
		preauthorizationsEnums,
		encounterPreauthTestOrders,
		sampleTypeEnums,
		patientBillingMethodEnums,
		MLOOrderStatus,
		outreachStatusEnums,
		encounterTestOrderRequest,
		encounterTestOrderRequestLoading,
		scpClinicalInfo,
		preauthdecisionenums,
		preauthdispatchstatus,
		user,
		activeRole,
		isBillingCoordinator,
		testOrderNotes,
		auditTrailEncounterLoading,
		auditTrailEncounter,
		auditTrailEncounterError,
		encounterAuditEnumLoading,
		encounterAuditEnum,
		encounterAuditEnumError,
		encounterBillingEnumLoading,
		encounterBillingEnum,
		encounterBillingEnumError,
		creatingTestOrderFlags,
		createdTestOrderFlags,
		createdTestOrderFlagsError,
		updatingTestOrderFlags,
		updatedTestOrderFlags,
		updatedTestOrderFlagsError,
		contentViewedByPatientLoading,
		contentViewedByPatient,
		contentViewedByPatientError,
		createdByUser,
		allUsers,
		roles,
		encounterCommunications,
		outreachEnums,
		patientDeclinedReasonsEnums,
		labOrderStatusEnums,
		gcRoROutreachStopReasonsEnums,
		linkingProvidersPatient,
		linkingProvidersPatientError,
		linkingProvidersEncounter,
		linkingProvidersEncounterError,
		updatingLinkedProviderPatient,
		updatingLinkedProviderPatientError,
		unlinkingProviderEncounter,
		unlinkingProviderEncounterError,
		getttingLinkedProvidersPatient,
		encounterLinkedProviders,
		testOrderUpdating,
		gettingProviderSpecialties,
		providerSpecialties,
		providerSpecialtiesError,
		patientFeeEnum,
		patientFeeEnumLoading,
		patientFeeEnumError,
		visitIndicationsLoading,
		visitIndications,
		visitIndicationsError,
		gettingReferralPrograms,
		referralPrograms,
		referralProgramsError,
		testOrderRedFlagUpdating,
		testOrderRedFlagUpdated,
		testOrderRedFlagUpdatedError,
		scheduling_visit_providers,
		partnerUUID,
		partnerLevelSpecialities
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const {
		encounter_type,
		encounter,
		gettingEncounterDetailLoading,
		gettingEncounterDetailError,
		visitsschema,
		billingencounter,
		billingEncounterUUID,
		testnameenums,
		alternatetestnameenums,
		testOrderPopupTestEnums,
		nonlegacytestOrderPopupTestEnumsLoading,
		groupsessionschema,
		ccintakeschema,
		ordering_physician,
		visit_provider,
		researchdataschema,
		partnerinvoicestatus,
		labsschema,
		orderrequest,
		patientdetail,
		encounterReclaim,
		preauthorizationsEnums,
		encounterPreauthTestOrders,
		sampleTypeEnums,
		patientBillingMethodEnums,
		MLOOrderStatus,
		outreachStatusEnums,
		encounterTestOrderRequest,
		encounterTestOrderRequestLoading,
		scpClinicalInfo,
		preauthdecisionenums,
		preauthdispatchstatus,
		user,
		testOrderNotes,
		activeRole,
		isBillingCoordinator,
		auditTrailEncounterLoading,
		auditTrailEncounter,
		auditTrailEncounterError,
		encounterAuditEnumLoading,
		encounterAuditEnum,
		encounterAuditEnumError,
		encounterBillingEnumLoading,
		encounterBillingEnum,
		encounterBillingEnumError,
		creatingTestOrderFlags,
		createdTestOrderFlags,
		createdTestOrderFlagsError,
		updatingTestOrderFlags,
		updatedTestOrderFlags,
		updatedTestOrderFlagsError,
		contentViewedByPatientLoading,
		contentViewedByPatient,
		contentViewedByPatientError,
		createdByUser,
		allUsers,
		roles,
		encounterCommunications,
		outreachEnums,
		patientDeclinedReasonsEnums,
		labOrderStatusEnums,
		gcRoROutreachStopReasonsEnums,
		linkingProvidersPatient,
		linkingProvidersPatientError,
		linkingProvidersEncounter,
		linkingProvidersEncounterError,
		updatingLinkedProviderPatient,
		updatingLinkedProviderPatientError,
		unlinkingProviderEncounter,
		unlinkingProviderEncounterError,
		getttingLinkedProvidersPatient,
		encounterLinkedProviders,
		testOrderUpdating,
		gettingProviderSpecialties,
		providerSpecialties,
		providerSpecialtiesError,
		patientFeeEnum,
		patientFeeEnumLoading,
		patientFeeEnumError,
		visitIndicationsLoading,
		visitIndications,
		visitIndicationsError,
		gettingReferralPrograms,
		referralPrograms,
		referralProgramsError,
		testOrderRedFlagUpdating,
		testOrderRedFlagUpdated,
		testOrderRedFlagUpdatedError,
		scheduling_visit_providers,
		partnerUUID,
		partnerLevelSpecialities
	} = stateProps;
	const { dispatch } = dispatchProps;
	const {
		match,
		changeEncounterTab,
		enqueueSnackbar,
		completeEncounter,
		openAppointmentAlert,
		closeAlert,
		encounterCompleteLoading,
		encounterCancelLoading,
		encounterCompleteError,
		getPatientDetail,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		getICDCodes,
		getEncounterList,
		history,
		location,
		linkedProviders,
		setReloadProviders,
		reloadProviders,
	} = ownProps;

	const linkProvidersToPatient = (patient_uuid, data) =>
		dispatch(linkProviders(patient_uuid, data));
	const linkProvidersToEncounter = (data) =>
		dispatch(linkProvidersEncounter(data));
	const getLinkedAllProviders = (patient_uuid) =>
		dispatch(getLinkedProviders(patient_uuid));
	const getAllReferralPrograms = () => dispatch(getReferralPrograms());
	const updateLinkedProviderPatient = (patient_uuid, provider_uuid, data) =>
		dispatch(updateLinkedProvider(patient_uuid, provider_uuid, data));
	const unlinkProviderFromEncounter = (encounter_uuid, provider_uuid) =>
		dispatch(unlinkProviderEncounter(encounter_uuid, provider_uuid));

	const clearencounterDetail = () => dispatch(clearencounterdetail());
	const getencouter = (encounter_uuid, doclear) =>
		dispatch(encounterdetail(encounter_uuid, doclear));
	const getBillingEncounter = (encounter_uuid) =>
		dispatch(getBillingEncounterDetail(encounter_uuid));
	const callscpclinicalinfodetail = (encounter_uuid) =>
		dispatch(getSCPClinicalInfoDetail(encounter_uuid));
	const createTestRequestFlags = (testOrderUuid, payload) =>
		dispatch(createTestOrderFlags(testOrderUuid, payload));
	const getAllContentViewedByPatient = (encounterUuid) =>
		dispatch(getContentViewedByPatient(encounterUuid));
	const updateTestRequestFlags = (testOrderUuid, payload) =>
		dispatch(updateTestOrderFlags(testOrderUuid, payload));
	const callvisitsschema = () => dispatch(getencountervisitsschema());
	const callspecificencounterenum = (data) =>
		dispatch(getspecificencounterenum(data));
	const dispatchLoadTestEnums = (lab_uuid, params) =>
		dispatch(LoadEncounterDetailTestEnums(lab_uuid, params));
	const LoadTestEnums = (lab_uuid) => {
		if (lab_uuid && lab_uuid != '')
			dispatchLoadTestEnums(lab_uuid, {
				include_currently_not_offered_or_legacy_tests: true,
			});
	};
	const dispatchLoadAlternateTestEnums = (lab_uuid) =>
		dispatch(LoadAlternateTestEnums(lab_uuid));
	const LoadAlternateTestEnum = (lab_uuid) => {
		if (lab_uuid && lab_uuid != '') {
			dispatchLoadAlternateTestEnums(lab_uuid);
		}
	};
	const dispatchLoadEditTestOrderTestEnums = (lab_uuid) =>
		dispatch(LoadEditTestOrderTestEnums(lab_uuid));
	const LoadTestOrderPopupTestEnum = (lab_uuid) => {
		if (lab_uuid && lab_uuid != '') {
			dispatchLoadEditTestOrderTestEnums(lab_uuid);
		}
	};
	const callgroupsessionschema = () =>
		dispatch(getencountergroupsessionschema());
	const callccintakeschema = () => dispatch(getencounterccintakeschema());
	const callresearchdataschema = () =>
		dispatch(getencounterresearchdataschema());
	const getpatientinvoicestatusenum = () =>
		dispatch(getresearchinvoicestatusenum());
	const calleditencouter = (data) => dispatch(editencounter(data, true));
	const calltestBulkUpdate = (data, isMDAuth) =>
		dispatch(testBulkUpdate(data, isMDAuth));
	const calleditbillingencouter = (data) =>
		dispatch(saveBillingEncounter(data, true));
	const encounter_uuid = _.get(match, ['params', 'encounterid'], 0);
	const patient_uuid = _.get(match, ['params', 'patientid'], 0);
	const calllabschema = () => dispatch(getencounterlabseschema());
	const upload_documents = (data) => dispatch(uploadDocument(data));
	const visitOrderStatus = _.get(visitsschema, ['order_status'], []);
	const visitfilteredOrderStatus = filterOrderStatusEnumes(visitOrderStatus);
	const groupSessionOrderStatus = _.get(
		groupsessionschema,
		['order_status'],
		[]
	);
	const groupSessionfilteredOrderStatus = filterOrderStatusEnumes(
		groupSessionOrderStatus
	);
	const ccIntakeOrderStatus = _.get(ccintakeschema, ['order_status'], []);
	const ccIntakefilteredOrderStatus =
		filterOrderStatusEnumes(ccIntakeOrderStatus);
	const labOrderStatus = _.get(labsschema, ['order_status_lab'], []);
	const labfilteredOrderStatus = filterOrderStatusEnumes(labOrderStatus);
	const reclaimEncounter = () => dispatch(encounterreclaim(encounter_uuid));
	const reclaimEncounterReset = () => dispatch(encounterReclaimReset());
	const callPreauthorizationsEnums = () =>
		dispatch(getPreauthorizationsEnums());
	const callPreauthorizationTestOrders = () =>
		dispatch(getPreauthorizationTestOrders(encounter_uuid));
	const callGeneTestEnums = () => dispatch(getGeneTestEnums());
	const callTestOrderRequest = (flag = true) =>
		dispatch(getTestOrderRequest(encounter_uuid, flag));
	const MLOOrderStatusEnums = MLOORderStatusHierarchyEnums(MLOOrderStatus);
	const callTestOrderNotes = (test_order_uuid) =>
		dispatch(getTestOrderNotes(test_order_uuid));
	const UpdateTestOrder = (test_order_uuid, data) =>
		dispatch(updateTestOrder(test_order_uuid, data));
	const getEncounterAuditTrail = (encounterUuid, payload) =>
		dispatch(getAuditTrailEncounter(encounterUuid, payload));
	const getAuditEncounterEnum = () => dispatch(getEncounterAuditEnum());
	const getBillingEncounterEnum = () => dispatch(getEncounterBillingEnum());
	const getOutreachsLog = (flag = true) =>
		dispatch(getOutreachs(encounter_uuid, flag));
	const getProviderSpecialtiesEnum = () => dispatch(getProviderSpecialties());
	const getPatientFeeRefundEnum = () => dispatch(getPatientFeeEnum());
	const getAllVisitIndications = () => dispatch(getVisitIndications());
	const getLatestOutreachStatus = (flag = true) => {
		dispatch(getOutreachs(encounter_uuid, flag));
		dispatch(getTestOrderRequest(encounter_uuid, flag));
	};
	const updatePatientAddress = (data) => dispatch(updateAddress(data));

	const goToTestTab = () => {
		return history.push({
			pathname:
				'/app/patientdetail/' +
				patient_uuid +
				'/7/0' +
				'/' +
				encounter_uuid +
				'/',
			state: _.get(location, 'state', {}),
		});
	};
	const goToDocumentTab = () => {
		return history.push({
			pathname:
				'/app/patientdetail/' +
				patient_uuid +
				'/6/0' +
				'/' +
				encounter_uuid +
				'/',
			state: _.get(location, 'state', {}),
		});
	};

	const gotoMLOTestDetail = (test_uuid) => {
		const location_state = Object.assign(_.get(location, 'state', {}));
		return history.push({
			state: location_state,
			pathname: `/app/patientdetail/${patient_uuid}/9/${TestV2TabsIndex.DETAIL}/${test_uuid}/`,
		});
	};

	const getCreatedByUser = (uuid) => dispatch(LoadUser(uuid));
	const getAllUsers = (uuid) => dispatch(LoadUsers(uuid));
	const resetUser = () => dispatch(resetLoadUser());
	const loadAppointmentProvider = (user_id) =>
		dispatch(LoadAppointmentProvider(user_id));

	const preauthdispatchstatusenums = MLOORderStatusHierarchyEnums(
		preauthdispatchstatus
	);

	const gotoRelatedEncounter = (
		patient_uuid,
		encounter_uuid,
		patientFullName
	) => {
		const _state = _.get(location, ['state'], {});
		const _breadcrumbs = _.get(location, ['state', 'breadcrumbs'], []);
		const _index = _.findIndex(_breadcrumbs, (obj) => {
			return _.get(obj, 'location', '') == 'Patient Detail';
		});

		if (_index != -1) {
			_breadcrumbs[_index]['patientFullName'] = patientFullName;
		}

		return history.push({
			pathname: `/app/patientdetail/${patient_uuid}/0/2/${encounter_uuid}/`,
			state: {
				..._state,
				breadcrumbs: _breadcrumbs,
			},
		});
	};

	const callUpdateTestOrderRedFlag = (gene_test_order_uuid, data) =>
		dispatch(updateTestOrderRedFlag(gene_test_order_uuid, data));

	const manual_visit_providers = scheduling_visit_providers.filter(
		(scheduling_visit_providers) =>
			scheduling_visit_providers.is_gmi_provider === true
	);

	const getDefaultSchedulingEnums= () => dispatch(getDefaultSchedulingOptions({partner_uuid:partnerUUID}));

	const visitEncounterProps = {
		callvisitsschema,
		visitsschema,
		encounter,
		gettingEncounterDetailLoading,
		gettingEncounterDetailError,
		encounter_type,
		encounter_uuid,
		billingencounter,
		scpClinicalInfo,
		LoadTestEnums,
		LoadAlternateTestEnum,
		LoadTestOrderPopupTestEnum,
		testnameenums,
		alternatetestnameenums,
		testOrderPopupTestEnums,
		fetchOrderingPhysician: () =>
			callspecificencounterenum({
				type: 'visit',
				field_name: 'ordering_physician',
			}),
		fetchVisitProvider: () =>
			loadAppointmentProvider(patientdetail.id),
		callSCPClinicalInfo: () =>
			callscpclinicalinfodetail(_.get(encounter, 'uuid', '0')),
		ordering_physician: _.get(ordering_physician, 'visit', []),
		visit_provider: _.get(visit_provider, 'visit', []),
		nonlegacytestnameenums: filterNonLegacyTestEnumes(
			_.get(testnameenums, 'data', [])
		),
		nonlegacyalternatetestnameenums: filterNonLegacyTestEnumes(
			_.get(alternatetestnameenums, 'data', [])
		),
		nonlegacytestOrderPopupTestEnums: filterNonLegacyTestEnumes(
			_.get(testOrderPopupTestEnums, 'data', [])
		),
		nonlegacytestOrderPopupTestEnumsLoading,
		orderstatuscascadeenums: OrderStatusHierarchyEnums(
			visitOrderStatus,
			'key'
		),
		filteredorderstatuscascadeenums: OrderStatusHierarchyEnums(
			visitfilteredOrderStatus,
			'key'
		),
		orderrequeststatuscascadeenums: OrderRequestStatusHierarchyEnums(
			visitsschema,
			'order_request_status',
			'key'
		),
		calleditencouter,
		calltestBulkUpdate,
		calleditbillingencouter,
		shouldEditable:
			!_.isEmpty(encounter) &&
			!_.isEmpty(billingencounter) &&
			!_.isEmpty(visitsschema) &&
			_.get(visit_provider, ['visit', 'length'], 0) != 0 &&
			_.get(ordering_physician, ['visit', 'length'], 0) != 0,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		getICDCodes,
		patientdetail,
		reclaimEncounter,
		encounterReclaim,
		callPreauthorizationsEnums,
		preauthorizationsEnums,
		callPreauthorizationTestOrders,
		encounterPreauthTestOrders,
		sampleTypeEnums,
		callGeneTestEnums,
		callTestOrderRequest,
		patientBillingMethodEnums,
		MLOOrderStatusEnums,
		outreachStatusEnums,
		encounterTestOrderRequest,
		encounterTestOrderRequestLoading,
		goToTestTab,
		gotoMLOTestDetail,
		goToDocumentTab,
		preauthdecisionenums,
		preauthdispatchstatusenums,
		user,
		testOrderNotes,
		callTestOrderNotes,
		UpdateTestOrder,
		activeRole,
		isBillingCoordinator,
		changeEncounterTab,
		gotoRelatedEncounter,
		getAllContentViewedByPatient,
		contentViewedByPatientLoading,
		contentViewedByPatient,
		contentViewedByPatientError,
		getCreatedByUser,
		getAllUsers,
		createdByUser,
		allUsers,
		roles,
		resetUser,
		getOutreachsLog,
		encounterCommunications,
		outreachEnums,
		patientDeclinedReasonsEnums,
		labOrderStatusEnums,
		gcRoROutreachStopReasonsEnums,
		patient_uuid,
		linkedProviders,
		getLatestOutreachStatus,
		testOrderUpdating,
		auditTrailEncounterLoading,
		auditTrailEncounter,
		auditTrailEncounterError,
		getEncounterAuditTrail,
		patientFeeEnum,
		patientFeeEnumLoading,
		patientFeeEnumError,
		getPatientFeeRefundEnum,
		getAllVisitIndications,
		visitIndicationsLoading,
		visitIndications,
		visitIndicationsError,
		gettingReferralPrograms,
		referralPrograms,
		referralProgramsError,
		updatePatientAddress,
		getPatientDetail,
		testOrderRedFlagUpdating,
		testOrderRedFlagUpdated,
		testOrderRedFlagUpdatedError,
		callUpdateTestOrderRedFlag,
		manual_visit_providers,
		partnerLevelSpecialities
	};
	const groupSessionEncounterProps = {
		callgroupsessionschema,
		groupsessionschema,
		encounter,
		gettingEncounterDetailLoading,
		gettingEncounterDetailError,
		encounter_type,
		encounter_uuid,
		billingencounter,
		scpClinicalInfo,
		LoadTestEnums,
		LoadAlternateTestEnum,
		LoadTestOrderPopupTestEnum,
		testnameenums,
		fetchOrderingPhysician: () =>
			callspecificencounterenum({
				type: 'group-session',
				field_name: 'ordering_physician',
			}),
		fetchVisitProvider: () =>
			callspecificencounterenum({
				type: 'group-session',
				field_name: 'visit_provider',
			}),
		ordering_physician: _.get(ordering_physician, 'group-session', []),
		visit_provider: _.get(visit_provider, 'group-session', []),
		nonlegacytestnameenums: filterNonLegacyTestEnumes(
			_.get(testnameenums, 'data', [])
		),
		nonlegacytestOrderPopupTestEnums: filterNonLegacyTestEnumes(
			_.get(testOrderPopupTestEnums, 'data', [])
		),
		nonlegacytestOrderPopupTestEnumsLoading,
		nonlegacyalternatetestnameenums: filterNonLegacyTestEnumes(
			_.get(alternatetestnameenums, 'data', [])
		),
		orderstatuscascadeenums: OrderStatusHierarchyEnums(
			groupSessionOrderStatus,
			'key'
		),
		filteredorderstatuscascadeenums: OrderStatusHierarchyEnums(
			groupSessionfilteredOrderStatus,
			'key'
		),
		orderrequeststatuscascadeenums: OrderRequestStatusHierarchyEnums(
			groupsessionschema,
			'order_request_status',
			'key'
		),
		calleditencouter,
		calltestBulkUpdate,
		calleditbillingencouter,
		shouldEditable:
			!_.isEmpty(encounter) &&
			!_.isEmpty(billingencounter) &&
			!_.isEmpty(groupsessionschema) &&
			_.get(visit_provider, ['group-session', 'length'], 0) != 0 &&
			_.get(ordering_physician, ['group-session', 'length'], 0) != 0,
		timezoneenums: Timezones,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		getICDCodes,
		patientdetail,
		callPreauthorizationsEnums,
		preauthorizationsEnums,
		callPreauthorizationTestOrders,
		encounterPreauthTestOrders,
		sampleTypeEnums,
		callGeneTestEnums,
		callTestOrderRequest,
		patientBillingMethodEnums,
		MLOOrderStatusEnums,
		outreachStatusEnums,
		encounterTestOrderRequest,
		encounterTestOrderRequestLoading,
		goToTestTab,
		gotoMLOTestDetail,
		preauthdecisionenums,
		preauthdispatchstatusenums,
		user,
		testOrderNotes,
		callTestOrderNotes,
		UpdateTestOrder,
		getOutreachsLog,
		encounterCommunications,
		outreachEnums,
		patientDeclinedReasonsEnums,
		labOrderStatusEnums,
		gcRoROutreachStopReasonsEnums,
		patient_uuid,
		linkedProviders,
		activeRole,
		isBillingCoordinator,
		getLatestOutreachStatus,
		auditTrailEncounterLoading,
		auditTrailEncounter,
		auditTrailEncounterError,
		getEncounterAuditTrail,
		patientFeeEnum,
		patientFeeEnumLoading,
		patientFeeEnumError,
		getPatientFeeRefundEnum,
		gettingReferralPrograms,
		referralPrograms,
		referralProgramsError,
		updatePatientAddress,
		getPatientDetail,
	};

	const ccIntakeEncounterProps = {
		callccintakeschema,
		ccintakeschema,
		encounter,
		gettingEncounterDetailLoading,
		gettingEncounterDetailError,
		encounter_type,
		encounter_uuid,
		billingencounter,
		scpClinicalInfo,
		LoadTestEnums,
		LoadAlternateTestEnum,
		LoadTestOrderPopupTestEnum,
		testnameenums,
		fetchOrderingPhysician: () =>
			callspecificencounterenum({
				type: 'cc-intake',
				field_name: 'ordering_physician',
			}),
		fetchVisitProvider: () =>
			callspecificencounterenum({
				type: 'cc-intake',
				field_name: 'visit_provider',
			}),
		orderstatuscascadeenums: OrderStatusHierarchyEnums(
			ccIntakeOrderStatus,
			'key'
		),
		filteredorderstatuscascadeenums: OrderStatusHierarchyEnums(
			ccIntakefilteredOrderStatus,
			'key'
		),
		orderrequeststatuscascadeenums: OrderRequestStatusHierarchyEnums(
			ccintakeschema,
			'order_request_status',
			'key'
		),
		ordering_physician: _.get(ordering_physician, 'cc-intake', []),
		visit_provider: _.get(visit_provider, 'cc-intake', []),
		nonlegacytestnameenums: filterNonLegacyTestEnumes(
			_.get(testnameenums, 'data', [])
		),
		nonlegacytestOrderPopupTestEnums: filterNonLegacyTestEnumes(
			_.get(testOrderPopupTestEnums, 'data', [])
		),
		nonlegacytestOrderPopupTestEnumsLoading,
		nonlegacyalternatetestnameenums: filterNonLegacyTestEnumes(
			_.get(alternatetestnameenums, 'data', [])
		),
		calleditencouter,
		calltestBulkUpdate,
		calleditbillingencouter,
		shouldEditable:
			!_.isEmpty(encounter) &&
			!_.isEmpty(billingencounter) &&
			!_.isEmpty(ccintakeschema) &&
			_.get(visit_provider, ['cc-intake', 'length'], 0) != 0 &&
			_.get(ordering_physician, ['cc-intake', 'length'], 0) != 0,
		timezoneenums: Timezones,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		getICDCodes,
		patientdetail,
		callPreauthorizationsEnums,
		preauthorizationsEnums,
		callPreauthorizationTestOrders,
		encounterPreauthTestOrders,
		sampleTypeEnums,
		callGeneTestEnums,
		callTestOrderRequest,
		patientBillingMethodEnums,
		MLOOrderStatusEnums,
		outreachStatusEnums,
		encounterTestOrderRequest,
		encounterTestOrderRequestLoading,
		goToTestTab,
		gotoMLOTestDetail,
		preauthdecisionenums,
		preauthdispatchstatusenums,
		user,
		testOrderNotes,
		callTestOrderNotes,
		UpdateTestOrder,
		getOutreachsLog,
		encounterCommunications,
		outreachEnums,
		patientDeclinedReasonsEnums,
		labOrderStatusEnums,
		gcRoROutreachStopReasonsEnums,
		patient_uuid,
		linkedProviders,
		activeRole,
		getLatestOutreachStatus,
		isBillingCoordinator,
		auditTrailEncounterLoading,
		auditTrailEncounter,
		auditTrailEncounterError,
		getEncounterAuditTrail,
		patientFeeEnum,
		patientFeeEnumLoading,
		patientFeeEnumError,
		getPatientFeeRefundEnum,
		gettingReferralPrograms,
		referralPrograms,
		referralProgramsError,
		updatePatientAddress,
		getPatientDetail,
	};

	const researchEncounterProps = {
		callresearchdataschema,
		researchdataschema,
		encounter,
		gettingEncounterDetailLoading,
		gettingEncounterDetailError,
		encounter_type,
		encounter_uuid,
		billingencounter,
		scpClinicalInfo,
		getpatientinvoicestatusenum,
		partnerinvoicestatus,
		calleditencouter,
		calleditbillingencouter,
		shouldEditable: !_.isEmpty(encounter) && !_.isEmpty(researchdataschema),
		linkedProviders,
		patientFeeEnum,
		patientFeeEnumLoading,
		patientFeeEnumError,
		getPatientFeeRefundEnum,
		activeRole,
	};

	const labEncounterProps = {
		calllabschema,
		labsschema,
		encounter,
		gettingEncounterDetailLoading,
		gettingEncounterDetailError,
		encounter_type,
		encounter_uuid,
		billingencounter,
		scpClinicalInfo,
		LoadTestEnums,
		LoadAlternateTestEnum,
		ordering_physician: _.get(
			ordering_physician,
			'lab_test_authorization',
			[]
		),
		orderstatuscascadeenums: OrderStatusHierarchyEnums(
			labOrderStatus,
			'key'
		),
		filteredorderstatuscascadeenums: OrderStatusHierarchyEnums(
			labfilteredOrderStatus,
			'key'
		),
		orderrequeststatuscascadeenums: OrderRequestStatusHierarchyEnums(
			labsschema,
			'order_request_status',
			'key'
		),
		fetchOrderingPhysician: () =>
			callspecificencounterenum({
				type: 'lab_test_authorization',
				field_name: 'ordering_physician',
			}),
		shouldEditable: !_.isEmpty(encounter) && !_.isEmpty(labsschema),
		upload_documents,
		patientdetail,
		orderrequest,
		calleditencouter,
		calleditbillingencouter,
		linkedProviders,
		activeRole,
		isBillingCoordinator,
		patientFeeEnum,
		patientFeeEnumLoading,
		patientFeeEnumError,
		getPatientFeeRefundEnum,
	};

	const encounterBodyProps = {
		encounter_type,
		getBillingEncounter,
		visitEncounterProps,
		groupSessionEncounterProps,
		ccIntakeEncounterProps,
		researchEncounterProps,
		labEncounterProps,
		enqueueSnackbar,
		createTestRequestFlags,
		updateTestRequestFlags,
		creatingTestOrderFlags,
		createdTestOrderFlags,
		createdTestOrderFlagsError,
		updatingTestOrderFlags,
		updatedTestOrderFlags,
		updatedTestOrderFlagsError,
	};

	const encounterHeaderProps = {
		changeEncounterTab,
		encounter,
		billingencounter,
		gettingEncounterDetailLoading,
		gettingEncounterDetailError,
		enqueueSnackbar,
		encounter_type,
		completeEncounter,
		openAppointmentAlert,
		encounterCompleteLoading,
		encounterCancelLoading,
		encounterCompleteError,
		encounter_uuid,
		getPatientDetail,
		closeAlert,
		getEncounterList,
		visitsschema,
		labsschema,
		ccintakeschema,
		groupsessionschema,
	};

	return {
		encounter_uuid,
		billingEncounterUUID,
		encounter_type,
		encounterBodyProps,
		getencouter,
		getBillingEncounter,
		enqueueSnackbar,
		clearencounterDetail,
		encounterHeaderProps,
		reclaimEncounterReset,
		auditTrailEncounterLoading,
		auditTrailEncounter,
		auditTrailEncounterError,
		getEncounterAuditTrail,
		encounterAuditEnumLoading,
		encounterAuditEnum,
		encounterAuditEnumError,
		encounterBillingEnumLoading,
		encounterBillingEnum,
		encounterBillingEnumError,
		getAuditEncounterEnum,
		getBillingEncounterEnum,
		activeRole,
		isBillingCoordinator,
		linkedProviders,
		getLinkedAllProviders,
		linkProvidersToPatient,
		linkProvidersToEncounter,
		updateLinkedProviderPatient,
		unlinkProviderFromEncounter,
		patient_uuid,
		linkingProvidersPatient,
		linkingProvidersPatientError,
		linkingProvidersEncounter,
		linkingProvidersEncounterError,
		updatingLinkedProviderPatient,
		updatingLinkedProviderPatientError,
		unlinkingProviderEncounter,
		unlinkingProviderEncounterError,
		getttingLinkedProvidersPatient,
		encounterLinkedProviders,
		getProviderSpecialtiesEnum,
		gettingProviderSpecialties,
		providerSpecialties,
		providerSpecialtiesError,
		setReloadProviders,
		reloadProviders,
		getAllReferralPrograms,
		getDefaultSchedulingEnums
	};
};

export default connect(mapStateToProps, null, mergeProps)(EncounterDetail);
