import React from 'react';

//Images
import multipleVisitIcon from '../../assets/multi-visit.svg';

//Utils
import {
	encounter_types_images,
	isMultipleVisit,
} from '../../Utils/encounterUtils';
import {
	isPartnerInitiatedPreTest,
	isPartnerInitiatedUnsolicitedResult,
} from '../Patients/V2/Encounters/Common/utilsfunctions';

//Lodash
import _ from 'lodash';

//UI Libraries
import Table from '../Common/Table/Table';
import Thead from '../Common/Table/Thead';
import Tbody from '../Common/Table/Tbody';
import Th from '../Common/Table/Th';
import Tr from '../Common/Table/Tr';
import Td from '../Common/Table/Td';
import { Checkbox } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import CommonCheckbox from '../Common/Checkbox.js';
import Tooltip from '../Common/Tooltip';

const encounterTypes = {
	lab_test_authorization: 'Lab',
	visit: 'Visit',
	Visit: 'Visit',
	'group-session': 'Group session',
	'cc-intake': 'CC Intake',
	'Lab Test Authorization': 'Lab',
	'research-data': 'Research Data',
	'Group-Session': 'Group Session',
	'Research-Data': 'Research Data',
	scp: 'SCP',
};

const BillingTable = (props) => {
	const {
		billingEncountersData,
		determineAllChecked,
		determineIsChecked,
		determineSomeChecked,
		handleAllToggle,
		handleCheckboxChange,
		handlePageChange,
		handleResetFilters,
		handleResultsPerPageChange,
		getImageName,
		getLabName,
		getTestName,
		pagination,
		selectedEncounterRow,
		showDetailForEncounter,
		showDetails,
	} = props;

	const getTableBody = () =>
		billingEncountersData.map((encounter = {}, i) => {
			const {
				billing_status: billingStatus = 'na',
				date_of_service: dateOfService = '',
				encounter_type: encounterType = '',
				insurance_claim_status: insuranceClaimStatus = 'na',
				lab = '',
				name = '',
				partner_invoice_status: partnerInvoiceStatus = 'na',
				referral_program: referralProgram = '',
				type_of_test: typeOfTest = '',
			} = encounter;

			return (
				encounter && (
					<Tr
						key={'fragment_' + i}
						className={
							'pattr billing' +
							(selectedEncounterRow == i ? ' selected' : '')
						}
						id={'patient_record_' + i}
						style={{ cursor: 'pointer' }}
						onClick={(e) => showDetailForEncounter(encounter, i, e)}
					>
						<Td key={'checkbox'} minWidth='10px' maxWidth='10px'>
							<CommonCheckbox
								onChangeFunc={handleCheckboxChange}
								additionalOnChangeArgs={[encounter]}
								checked={determineIsChecked(encounter)}
								className='templateTableCheckbox'
								marginLeftOverride={4}
							/>
						</Td>
						<Td>{referralProgram || '-'}</Td>
						<Td>
							<Tooltip
								id='name_tooltip'
								className='name_tooltip'
								content={name}
								placement='top-center'
							>
								<div
									style={{
										maxWidth: '215px',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
									}}
								>
									{name}
								</div>
							</Tooltip>
						</Td>
						<Td>
							{moment(dateOfService).isValid
								? moment(dateOfService).format('MMM D, YYYY')
								: ''}
						</Td>
						<Td
							minWidth={showDetails ? '10px' : '40px'}
							maxWidth={showDetails ? '10px' : '40px'}
						>
							<div className={billingStatus}>
								<img
									className='patient_invoice_status_icon'
									src={getImageName(billingStatus)}
									alt=''
								/>
							</div>
						</Td>
						<Td
							minWidth={showDetails ? '10px' : '40px'}
							maxWidth={showDetails ? '10px' : '40px'}
						>
							<div className={partnerInvoiceStatus}>
								<img
									className='patient_invoice_status_icon'
									src={getImageName(partnerInvoiceStatus)}
									alt=''
								/>
							</div>
						</Td>
						<Td
							minWidth={showDetails ? '10px' : '40px'}
							maxWidth={showDetails ? '10px' : '40px'}
						>
							<div className={insuranceClaimStatus}>
								<img
									className='patient_invoice_status_icon'
									height='16px'
									width='32px'
									src={getImageName(insuranceClaimStatus)}
									alt=''
								/>
							</div>
						</Td>
						<Td className={showDetails ? 'hidden' : ''}>
							{isMultipleVisit({
								...encounter,
								type: encounterType,
							}) ? (
								<img src={multipleVisitIcon} />
							) : encounter_types_images[encounterType] ? (
								<img
									src={encounter_types_images[encounterType]}
									alt=''
								/>
							) : null}
							{isPartnerInitiatedPreTest({
								...encounter,
								type: encounterType,
							})
								? 'TRO'
								: isPartnerInitiatedUnsolicitedResult({
										...encounter,
										type: encounterType,
								  })
								? 'TRO - Results Only'
								: encounterTypes[encounterType]}
						</Td>
						<Td className={showDetails ? 'hidden' : ''}>
							<div style={{ display: 'flex' }}>
								<div style={{ marginRight: '0.5vw' }}>
									{lab == 'Multiple' ? (
										<div className='multiple-labs'>
											Multiple
										</div>
									) : (
										getLabName(lab) || '--'
									)}
								</div>
							</div>
						</Td>
						<Td className={showDetails ? 'hidden' : ''}>
							{typeOfTest == 'Multiple' ? (
								<div className='multiple-labs'>Multiple</div>
							) : (
								getTestName(typeOfTest) || '--'
							)}
						</Td>
					</Tr>
				)
			);
		});

	return (
		<div
			className='billing_dashboard'
			style={{ width: showDetails ? '60%' : '100%' }}
		>
			<div className='billing_enc_list_wrapper'>
				<Table
					style={{ marginTop: '16px' }}
					loading={false}
					backToTop
					resultsPerPage={[25, 50, 100]}
					handleResultsPerPageChange={handleResultsPerPageChange}
					pagination={pagination}
					handlePageChange={handlePageChange}
					hasData={!_.isEmpty(billingEncountersData)}
					handleResetFilters={handleResetFilters}
				>
					<Thead>
						<Tr>
							<Th>
								<Checkbox
									indeterminate={determineSomeChecked()}
									checked={determineAllChecked()}
									className='templateTableCheckbox'
									onChange={handleAllToggle}
								/>
							</Th>
							<Th>Referral Program</Th>
							<Th>Name</Th>
							<Th>Date of Service</Th>
							<Th>Patient Invoice</Th>
							<Th>Partner Invoice</Th>
							<Th>Insurance Claim</Th>
							<Th className={showDetails ? 'hidden' : ''}>
								Encounter Type
							</Th>
							<Th className={showDetails ? 'hidden' : ''}>Lab</Th>
							<Th className={showDetails ? 'hidden' : ''}>
								Test Type
							</Th>
						</Tr>
					</Thead>
					<Tbody>{getTableBody()}</Tbody>
				</Table>
			</div>
		</div>
	);
};

export default BillingTable;
