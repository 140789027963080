//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Dialog, Button, Radio } from 'gm-element-react';

//Styles
import '../css/reschedule-confirm-dialog.css';

class RescheduleReasonDialog extends React.Component {
	constructor(props) {
		super(props);
	}
	isBtnDisabled() {
		return _.isUndefined(this.props.reschedule_reason);
	}

	render() {
		return (
			<Fragment>
				<Dialog
					className='reschedule-reason-dialog'
					title={<Fragment>Select reschedule reason</Fragment>}
					size='tiny'
					visible={_.get(
						this,
						['props', 'show_reschedulereasondialog'],
						false
					)}
					onCancel={() => this.props.RescheduleReasonDialogCancel()}
					lockScroll={false}
					showClose={false}
				>
					<Dialog.Body>
						<Radio.Group
							value={this.props.reschedule_reason}
							onChange={this.props.setRescheduleReason}
						>
							<Radio value={'requested_by_patient'}>
								Requested by patient
							</Radio>
							<Radio value={'requested_by_provider'}>
								Requested by provider
							</Radio>
						</Radio.Group>
					</Dialog.Body>
					<Dialog.Footer className='reschedule-reason-dialog-footer'>
						<Button
							onClick={() =>
								this.props.RescheduleReasonDialogCancel()
							}
						>
							Cancel
						</Button>
						<Button
							disabled={this.isBtnDisabled()}
							type='primary'
							onClick={() =>
								this.props.callRescheduleAppointment()
							}
						>
							Confirm
						</Button>
					</Dialog.Footer>
				</Dialog>
			</Fragment>
		);
	}
}

export default RescheduleReasonDialog;
