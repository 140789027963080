import moment from 'moment';

/**
 * @param {Record<string, boolean | Record<string, string>} input
 * @returns {Array<{ tag_id: number; tag_properties?: Array<Record<string, string>>}>}
 */
export function convertTags(input) {
	return Object.entries(input).reduce((acc, [id, value]) => {
		if (!value) {
			return acc;
		}
		const tagObject = { tag_id: parseInt(id, 10) };
		if (typeof value !== 'boolean') {
			tagObject.tag_properties = [value];
		}
		acc.push(tagObject);
		return acc;
	}, []);
}

/**
 * Converts form data into Insurance API payload
 * @param {Object} input
 * @returns {Object}
 */
export function formToInsuranceApiPayload(input) {
	const {
		group,
		healthInsurance,
		insuranceId,
		isPrimaryPolicyHolder,
		policyholderBiologicalSex,
		relationshipToPolicyholder,
		policyholderFirstName,
		policyholderLastName,
		policyholderDOB,
	} = input.additionalPatientInfo;

	return {
		insurance: [
			{
				company_name: '',
				group_no: group,
				insurance_company_id: healthInsurance,
				insurance_no: insuranceId,
				insurance_type: 'primary',
				is_policyholder: isPrimaryPolicyHolder,
				policyholder_biological_sex: policyholderBiologicalSex,
				policyholder_first_name: policyholderFirstName,
				policyholder_last_name: policyholderLastName,
				policyholder_dob: policyholderDOB,
				policyholder_relationship: relationshipToPolicyholder,
				visit_insurance: true,
				lab_insurance: false,
			},
		],
	};
}

/**
 * Converts form data into API payload
 * @param {Object} input
 * @returns {Object}
 */
export function formToApiPayload(input) {
	const payload = {
		accession_number: input.patientDemographicsAndContact.accessionNumber,
		consent_to_treat:
			input.additionalPatientInfo?.consentToTreat ?? 'not_consented',
		default_consultation_id:
			input.serviceAndProgram.firstEncounterConsultationType ?? undefined,
		default_specialty_id:
			input.serviceAndProgram.firstEncounterSpecialtyType ?? undefined,
		dob: moment(input.patientDemographicsAndContact.dateOfBirth).format(
			'YYYY-MM-DD'
		),
		external_patient_id:
			input.patientDemographicsAndContact.externalPatientId,
		first_name: input.patientDemographicsAndContact.patientFirstName,
		is_noemail: input.patientDemographicsAndContact.noEmail,
		is_unborn: false,
		last_name: input.patientDemographicsAndContact.patientLastName,
		original_referral_date: moment.utc().toDate(),
		partner_id: input.serviceAndProgram.referralProgram,
		preferred_name: input.additionalPatientInfo.preferredName,
		sex_assigned_at_birth:
			input.patientDemographicsAndContact.sexAssignedAtBirth,
		user_tags: convertTags(input.patientDemographicsAndContact.tags),
		duplicate_patient_uuid:
			input.patientDemographicsAndContact.duplicatePatientUuids,
	};

	if (!input.patientDemographicsAndContact.noEmail) {
		payload.email = input.patientDemographicsAndContact.patientEmail;
	}

	if (input.additionalPatientInfo.healthInsurance) {
		payload.insurance = [
			{
				company_name: '',
				group_no: input.additionalPatientInfo.group,
				insurance_company_id:
					input.additionalPatientInfo.healthInsurance,
				insurance_no: input.additionalPatientInfo.insuranceId,
				insurance_type: 'primary',
				is_policyholder:
					input.additionalPatientInfo.isPrimaryPolicyHolder,
				policyholder_biological_sex:
					input.additionalPatientInfo.policyholderBiologicalSex,
				policyholder_relationship:
					input.additionalPatientInfo.relationshipToPolicyholder,
				policyholder_first_name:
					input.additionalPatientInfo.policyholderFirstName,
				policyholder_last_name:
					input.additionalPatientInfo.policyholderLastName,
				policyholder_dob: input.additionalPatientInfo.policyholderDOB,
			},
		];
	}

	payload.gender = input.additionalPatientInfo.gender || null;
	payload.pronouns =
		input.additionalPatientInfo.pronouns?.length > 0
			? input.additionalPatientInfo.pronouns
			: null;

	if (input.additionalPatientInfo.race) {
		payload.race = input.additionalPatientInfo.race;
		if (input.additionalPatientInfo.secondaryRace.length > 0) {
			payload.secondary_races = input.additionalPatientInfo.secondaryRace;
		} else {
			payload.secondary_races = [];
		}
	}
	if (input.additionalPatientInfo.ethnicity) {
		payload.ethnicity = input.additionalPatientInfo.ethnicity;
	}

	const phones = {
		primary: {
			type: null, // Is type required?
			value: input.patientDemographicsAndContact
				.patientPrimaryPhoneNumber,
			consent_to_text:
				input.patientDemographicsAndContact.textNotification,
		},
	};

	if (input.patientDemographicsAndContact.hasGuardian) {
		// `has_guardian` MUST be specified before `users_guardian` or SBE
		// throws an error
		payload.has_guardian = true;
		payload.users_guardian = {
			email: input.patientDemographicsAndContact.patientEmail,
			first_name: input.patientDemographicsAndContact.guardianFirstName,
			last_name: input.patientDemographicsAndContact.guardianLastName,
			phones,
			relationship:
				input.patientDemographicsAndContact.relationshipToPatient,
		};
	} else {
		payload.has_guardian = false;
		payload.phones = phones;
	}
	return payload;
}

export function formToProvidersApiPayload(providers, defaultProviders = []) {
	const startDate = moment().format('YYYY-MM-DD');

	const combinedProviders = [...defaultProviders, ...providers].map(
		(provider) => ({
			provider_uuid: provider.uuid ?? provider.providerUUID,
			provider_role: provider.providerRole ?? ['referring_provider'],
			start_date: provider.startDate ?? startDate,
			end_date: null,
			receive_all_communications:
				provider.receiveAllComms !== undefined
					? provider.receiveAllComms
					: true,
			is_gmi_provider: false,
		})
	);

	const duplicateUuids = new Set();

	const uniqueProviders = combinedProviders.filter((provider) => {
		if (!duplicateUuids.has(provider.provider_uuid)) {
			duplicateUuids.add(provider.provider_uuid);
			return true;
		}
		return false;
	});

	return {
		data: uniqueProviders,
	};
}

export function formToProviderUpdateApiPayload(provider) {
	return {
		provider_role: provider.providerRole ?? ['referring_provider'],
		receive_all_communications:
			provider.receiveAllComms !== undefined
				? provider.receiveAllComms
				: true,
	};
}

export function formToAddNotesApiPayload(
	enteredNote,
	noteType,
	userEmail,
	patientUUID
) {
	const json = {
		data: {
			note: enteredNote,
			author: userEmail,
			date_created: new Date(),
			type: noteType,
		},
		patient_uuid: patientUUID,
	};
	return json;
}

export function formToUploadDocumentsApiPayload(documentData, patientUUID) {
	const json = {
		payload: documentData,
		patientuuid: patientUUID,
	};
	return json;
}
