//React & Redux
import React, { Component } from 'react';

//Other Libraries
import _ from 'lodash';

//Components

//Styles
import './pill.css';

class Pill extends Component {
	render() {
		const { pillText, pillExtendStyle } = this.props;
		return (
			<div className='gmi-pill-wrapper'>
				<span className={`pill ${pillExtendStyle}`}>{pillText}</span>
			</div>
		);
	}
}

export default Pill;
