//React & Redux
import React, { Fragment } from 'react';

//Lodash
import moment from 'moment';
import _, { isEmpty } from 'lodash';

//Utils
import {
	CreateEmptySlotEvents,
	getStartOfWeek,
} from './../../../Utils/CalendarUtils';
import { GetStateTimezone, getShippingState } from './../../../utils.js';

//Components
import CalendarSection from './CalendarSection';
import RescheduleFilters from './RescheduleFilters';
import AppointentAlert from '../AppointmentAlert';

//Styles
import './../ScheduleSection.css';

class RescheduleStage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			date: null,
			timezone: '',
			consultation_id: undefined,
			state: '',
			provider_id: undefined,
			vsee_specialty: '',
			modality: 'video',
			consultation_type: undefined,
			showappointmentalert: false,
			appointmentalertfun: () => {},
		};
		//bindings
		this.switchDate = this.switchDate.bind(this);
		this.switchDateBack = this.switchDateBack.bind(this);
		this.switchDateForward = this.switchDateForward.bind(this);
		this.switchToToday = this.switchToToday.bind(this);
		this.updateFilter = this.updateFilter.bind(this);
		this.onChange = this.onChange.bind(this);
		this.configureButtonDisabled = this.configureButtonDisabled.bind(this);
	}
	//functions

	componentDidMount() {
		const {
			consultation_type,
			vsee_specialty,
			consultation_id,
			tm_platform,
			date_of_service,
		} = this.props.encounterdetail;
		const date =
			date_of_service && !_.isNil(date_of_service)
				? getStartOfWeek(moment(date_of_service).toDate())
				: getStartOfWeek(new Date());
		const specialties = _.get(this.props, 'specialties', []);
		let vseespecialty = '';
		if (specialties.find((e) => e && e.key == vsee_specialty)) {
			vseespecialty = vsee_specialty
				? vsee_specialty.replace('_Willing', '').replace('_willing', '')
				: vsee_specialty;
		}
		const defaultstate = getShippingState(
			_.get(this.props, ['patientDetail', 'address'], null)
		);
		const defaultTimezone = GetStateTimezone(defaultstate);
		this.setState(
			{
				date: date,
				timezone: defaultTimezone,
				consultation_id: consultation_id,
				state: defaultstate,
				vsee_specialty: vseespecialty,
				modality: tm_platform,
				consultation_type: consultation_type,
			},
			() => {
				this.onChange();
			}
		);
	}

	switchDate(isForward) {
		if (this.isShaded) return;

		const newDate = new Date(this.state.date);
		if (isForward) {
			newDate.setDate(newDate.getDate() + 7);
			this.updateFilter('date', newDate);
		} else {
			const sameweek = moment(newDate).isSame(
				moment(getStartOfWeek(new Date()))
			);
			if (!sameweek) {
				newDate.setDate(newDate.getDate() - 7);
				this.updateFilter('date', newDate);
			} else {
				console.log('back not possible');
			}
		}
	}

	updateFilter(key, value) {
		const state = { ...this.state };
		state[key] = value;
		if (key == 'state') state['timezone'] = GetStateTimezone(value);
		this.setState(state, () => {
			if (key != 'modality') {
				this.props.clearSelectedSlotParams();
				this.onChange();
			} else {
				this.props.setModality(value);
			}
		});
	}

	switchToToday() {
		this.updateFilter('date', getStartOfWeek(new Date()));
	}

	get isAllFilterSelected() {
		const selectedFilters = { ...this.state };
		return (
			_.get(selectedFilters, 'vsee_specialty', '') != '' &&
			_.get(selectedFilters, 'consultation_id', '') != '' &&
			_.get(selectedFilters, 'state', '') != '' &&
			_.get(selectedFilters, 'timezone', '') != ''
		);
	}
	get isShaded() {
		const { slotsLoading } = this.props.Scheduling;
		return !(this.isAllFilterSelected && slotsLoading === false);
	}

	switchDateBack = () => {
		this.switchDate(false);
	};

	switchDateForward = () => {
		this.switchDate(true);
	};

	onChange() {
		if (this.isAllFilterSelected && !isEmpty(this.state.vsee_specialty)) {
			this.props.LoadAppointmentSlot({
				start_time: this.state.date,
				timezone: this.state.timezone,
				consultation_id: this.state.consultation_id,
				patient_id: _.get(this, ['props', 'patientDetail', 'id'], 0),
				state: this.state.state,
				provider_id: this.state.provider_id,
				vsee_specialty: this.state.vsee_specialty,
				calendar_date: this.state.date,
				appointment_id: _.get(
					this,
					['props', 'encounterdetail', 'id'],
					0
				),
			});
		}
	}
	configureButtonDisabled = () => {
		return (
			!this.isAllFilterSelected ||
			_.isNil(_.get(this, ['props', 'selectedSlot', 'start_time'], null))
		);
	};
	showAppointmentAlert = (confFun) => {
		this.setState({
			showappointmentalert: true,
			appointmentalertfun: confFun,
		});
	};

	hideAppointentAlert = () => {
		this.setState({
			showappointmentalert: false,
			appointmentalertfun: () => {},
		});
	};

	render() {
		const events = () => {
			const selectedSlot = _.get(this, ['props', 'selectedSlot'], {});
			return CreateEmptySlotEvents({
				eventsList: this.props.Scheduling.slots,
				reservations: this.props.Scheduling.reservations,
				startDate: this.state.date,
				onSlotClick: (
					id,
					start,
					end,
					vsee_specialty,
					state,
					timezone,
					consultation_id
				) => {
					const currenttimeOfTimezone = moment(new Date()).tz(
						timezone
					);
					const starttimeOfTimezone = moment.tz(
						moment(start).format('YYYY-MM-DDTHH:mm:ss'),
						timezone
					);
					const duration = moment.duration(
						starttimeOfTimezone.diff(currenttimeOfTimezone)
					);
					const hours = duration.asHours();
					const appointmentUpdateObj = {
						start_time: start,
						timezone,
						consultation_id,
						state,
						provider_id: id,
						modality: this.state.modality,
						end_time: end,
					};
					if (vsee_specialty) {
						appointmentUpdateObj.vsee_specialty = vsee_specialty;
					}
					if (hours > 8) {
						this.props.setSelectedSlotParams(appointmentUpdateObj);
					} else {
						this.showAppointmentAlert(() => {
							this.props.setSelectedSlotParams(
								appointmentUpdateObj
							);
						});
					}

					return null;
				},
				timeslot: {
					start: selectedSlot.start_time,
					end: selectedSlot.end_time,
				},
			});
		};

		const { schedulingConsultations, providers, slotsLoading } =
			this.props.Scheduling;
		return (
			<Fragment>
				<div className='schedule-filters'>
					<RescheduleFilters
						schedulingConsultations={schedulingConsultations}
						providers={providers}
						visitProviders={this.props.visitProviders}
						modalities={this.props.modalities}
						updateFilter={this.updateFilter}
						selectedFilters={{ ...this.state }}
						showRescheduleConfirmDialog={
							this.props.showRescheduleConfirmDialog
						}
						configureButtonDisabled={this.configureButtonDisabled}
						handleNavigateToAppointmentList={
							this.props.handleNavigateToAppointmentList
						}
						specialties={this.props.specialties}
						showModality={!this.props.partnerDetail?.isPhoneOnly}
					/>
				</div>
				<div
					className={`schedule-container ${
						this.isShaded ? 'disabled-calendar' : ''
					}`}
				>
					<div
						className={`schedule-calendar ${
							this.isShaded ? 'disabled-pointerevents' : ''
						}`}
					>
						<CalendarSection
							events={events()}
							date={this.state.date}
							switchDateBack={this.switchDateBack}
							switchDateForward={this.switchDateForward}
							switchToToday={this.switchToToday}
							isShaded={this.isShaded}
							slotsLoading={slotsLoading}
						/>
					</div>
				</div>
				{this.state.showappointmentalert && (
					<AppointentAlert
						save={() => {
							this.state.appointmentalertfun();
							this.hideAppointentAlert();
						}}
						cancel={() => {
							this.hideAppointentAlert();
						}}
					/>
				)}
			</Fragment>
		);
	}
}

export default RescheduleStage;
