import { get } from 'lodash';

import * as actionTypes from './actionTypes';
import discreteVariantService from '../service/DiscreteVariant';
import { deHydrateDiscreteVariant } from '../transformers/discreteVariantTransformer';

const getReferenceAssembliesEnumLoading = () => {
	return {
		type: actionTypes.GET_REFERENCE_ASSEMBLIES_ENUM_LOADING,
	};
};

const getReferenceAssembliesEnumSuccess = (payload) => {
	return {
		type: actionTypes.GET_REFERENCE_ASSEMBLIES_ENUM_SUCCESS,
		payload,
	};
};

const getReferenceAssembliesEnumError = (payload) => {
	return {
		type: actionTypes.GET_REFERENCE_ASSEMBLIES_ENUM_ERROR,
		payload,
	};
};

export function getReferenceAssembliesEnum() {
	return (dispatch) => {
		dispatch(getReferenceAssembliesEnumLoading());
		return discreteVariantService
			.getReferenceAssembliesEnum()
			.then((res) => {
				dispatch(
					getReferenceAssembliesEnumSuccess(
						res?.data?.referenceAssemblies || []
					)
				);
			})
			.catch((err) => {
				dispatch(getReferenceAssembliesEnumError(err));
			});
	};
}

const getGeneticResultGenesSymbolEnumLoading = () => {
	return {
		type: actionTypes.GET_GENETIC_RESULT_GENES_SYMBOL_ENUM_LOADING,
	};
};

const getGeneticResultGenesSymbolEnumSuccess = (payload) => {
	return {
		type: actionTypes.GET_GENETIC_RESULT_GENES_SYMBOL_ENUM_SUCCESS,
		payload,
	};
};

const getGeneticResultGenesSymbolEnumError = (payload) => {
	return {
		type: actionTypes.GET_GENETIC_RESULT_GENES_SYMBOL_ENUM_ERROR,
		payload,
	};
};

export function getGeneticResultGenesSymbol(query) {
	return (dispatch) => {
		dispatch(getGeneticResultGenesSymbolEnumLoading());
		return discreteVariantService
			.getGeneticResultGenesSymbolEnum(query)
			.then((res) => {
				dispatch(
					getGeneticResultGenesSymbolEnumSuccess(
						res?.data?.genes || []
					)
				);
			})
			.catch((err) => {
				dispatch(getGeneticResultGenesSymbolEnumError(err));
			});
	};
}

export const resetGetGeneticResultGenesSymbol = () => {
	return {
		type: actionTypes.RESET_GET_GENETIC_RESULT_GENES_SYMBOL_ENUM,
	};
};

const addDiscreteVariantFindingLoading = () => {
	return {
		type: actionTypes.ADD_DISCRETEVARIANT_FINDING_LOADING,
	};
};

const addDiscreteVariantFindingSuccess = (payload) => {
	return {
		type: actionTypes.ADD_DISCRETEVARIANT_FINDING_SUCCESS,
		payload,
	};
};

const addDiscreteVariantFindingError = (payload) => {
	return {
		type: actionTypes.ADD_DISCRETEVARIANT_FINDING_ERROR,
		payload,
	};
};

export function addDiscreteVariantFinding(data, diagnosticReportId) {
	return (dispatch) => {
		dispatch(addDiscreteVariantFindingLoading());
		return discreteVariantService
			.addDiscreteVariantFinding(
				deHydrateDiscreteVariant(data),
				diagnosticReportId
			)
			.then((res) => {
				dispatch(
					addDiscreteVariantFindingSuccess(
						res?.data?.addDiagnosticFinding?.finding?.id || ''
					)
				);
			})
			.catch((err) => {
				dispatch(addDiscreteVariantFindingError(err));
			});
	};
}

export function createDiagnosticReportAndAddDiscreteVariantFindings(
	data,
	patientId,
	testOrderId
) {
	return (dispatch) => {
		dispatch(addDiscreteVariantFindingLoading());
		return discreteVariantService
			.createDiagnosticReport(patientId, testOrderId)
			.then((res) => {
				const diagnosticReportId =
					res?.data?.addDiagnosticReport?.diagnosticReport?.id ||
					null;
				dispatch(addDiscreteVariantFinding(data, diagnosticReportId));
			})
			.catch((err) => {
				dispatch(addDiscreteVariantFindingError(err));
			});
	};
}

export function updateDiscreteVariantFinding(data) {
	return (dispatch) => {
		dispatch(addDiscreteVariantFindingLoading());
		return discreteVariantService
			.updateDiscreteVariantFinding(
				deHydrateDiscreteVariant(data),
				data?.uuid || null
			)
			.then((res) => {
				dispatch(
					addDiscreteVariantFindingSuccess(
						get(res, [
							'data',
							'updateDiagnosticFinding',
							'finding',
							'id',
						])
					)
				);
			})
			.catch((err) => {
				dispatch(addDiscreteVariantFindingError(err));
			});
	};
}

const getDiagnosticFindingValidationLoading = () => {
	return {
		type: actionTypes.GET_DIAGNOSTIC_FINDING_VALIDATION_LOADING,
	};
};

const getDiagnosticFindingValidationSuccess = (payload) => {
	return {
		type: actionTypes.GET_DIAGNOSTIC_FINDING_VALIDATION_SUCCESS,
		payload,
	};
};

const getDiagnosticFindingValidationError = (payload) => {
	return {
		type: actionTypes.GET_DIAGNOSTIC_FINDING_VALIDATION_ERROR,
		payload,
	};
};

export function getDiagnosticFindingValidation(data) {
	return (dispatch) => {
		dispatch(getDiagnosticFindingValidationLoading());
		return discreteVariantService
			.getDiagnosticFindingValidation(deHydrateDiscreteVariant(data))
			.then((res) => {
				dispatch(
					getDiagnosticFindingValidationSuccess(
						res?.data?.diagnosticFindingValidation || {}
					)
				);
			})
			.catch((err) => {
				dispatch(getDiagnosticFindingValidationError(err));
			});
	};
}

export const resetGetDiagnosticFindingValidation = () => {
	return {
		type: actionTypes.RESET_GET_DIAGNOSTIC_FINDING_VALIDATION,
	};
};
