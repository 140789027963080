import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	GUARDIAN_RELATIONSHIP_ENUM,
	guardianRelationships,
} from '../../../../../Utils/appointmentUtils';
import {
	getConsenttotreat,
	getReferralPrograms,
} from '../../../../../actions/patients';
import CheckboxField from '../../../../Common/FormFields/CheckboxField';
import DatePickerField from '../../../../Common/FormFields/DatePickerField';
import InputField from '../../../../Common/FormFields/InputField';
import PhoneNumberField from '../../../../Common/FormFields/PhoneNumberField';
import Dropdown from '../../../../Common/FormFields/Dropdown';

import { useSection } from '../../../../Patients/V3/CreatePatient/sections/useSection';

import './PatientInfoForm.scss';
import ConsentVerbiage from './ConsentVerbiage';

export function PatientInfoForm({ sectionDataContainerName }) {
	const { errors, onChange, setFieldValue, touched, values } = useSection(
		sectionDataContainerName
	);

	const dispatch = useDispatch();

	const { consentToTreatEnums, referralPrograms } = useSelector((store) => ({
		consentToTreatEnums:
			store.getConsenttotreat.consenttotreat?.consent_to_treat ?? [],
		referralPrograms: store.referralPrograms.referralPrograms.data ?? [],
	}));

	useEffect(() => {
		dispatch(getConsenttotreat());
		dispatch(getReferralPrograms());
	}, []);

	const Phone = (
		<PhoneNumberField
			required
			label={
				values.hasGuardian
					? "Guardian's Primary Phone Number"
					: 'Primary Phone Number'
			}
			value={values.patientPrimaryPhoneNumber}
			error={
				touched.patientPrimaryPhoneNumber &&
				errors.patientPrimaryPhoneNumber
			}
			onChange={onChange('patientPrimaryPhoneNumber')}
		/>
	);

	const DateOfBirth = (
		<DatePickerField
			required
			label={
				values.hasGuardian ? "Patient's Date of Birth" : 'Date of Birth'
			}
			value={values.dateOfBirth}
			error={touched.dateOfBirth && errors.dateOfBirth}
			onChange={(v) => setFieldValue('dateOfBirth', v ?? null)}
			name="dateOfBirth"
			className="date-of-birth"
			placeholder='MM/DD/YYYY'
			disabledFutureDates={true}
			isClearable={true}  			                          
		/>
	);

	const Email = (
		<InputField
			required
			label={values.hasGuardian ? "Guardian's Email" : 'Email'}
			value={values.patientEmail}
			error={touched.patientEmail && errors.patientEmail}
			onChange={onChange('patientEmail')}
		/>
	);
	const PreferredName = (
		<InputField
			label={
				values.hasGuardian
					? "Patient's Preferred Name"
					: 'Preferred Name'
			}
			value={values.preferredName}
			error={touched.preferredName && errors.preferredName}
			onChange={onChange('preferredName')}
		/>
	);

	const AssociatedProgram = (
		<Dropdown
			required
			label={
				values.hasGuardian
					? "Patient's Associated Program"
					: 'Associated Program'
			}
			options={referralPrograms.map((item) => ({
				label: item.display_name,
				value: item.id,
			}))}
			value={values.associatedProgram}
			error={touched.associatedProgram && errors.associatedProgram}
			onChange={onChange('associatedProgram')}
			typeahead
		/>
	);
	const ConsentToTreat = (
		<div>
			<Dropdown
				required
				label='Consent to Treat'
				options={consentToTreatEnums.map((item) => ({
					label: item.display_name,
					value: item.key,
				}))}
				value={values.consentToTreat}
				error={touched.consentToTreat && errors.consentToTreat}
				onChange={onChange('consentToTreat')}
			/>
			<ConsentVerbiage />
		</div>
	);

	const GuardianFirstName = (
		<InputField
			required
			label="Guardian's First Name"
			value={values.guardianFirstName}
			error={touched.guardianFirstName && errors.guardianFirstName}
			onChange={onChange('guardianFirstName')}
		/>
	);

	const GuardianLastName = (
		<InputField
			required
			label="Guardian's Last Name"
			value={values.guardianLastName}
			error={touched.guardianLastName && errors.guardianLastName}
			onChange={onChange('guardianLastName')}
		/>
	);

	const RelationshipToPatient = (
		<Dropdown
			label='Relationship to Patient'
			required
			options={guardianRelationships}
			error={
				touched.relationshipToPatient && errors.relationshipToPatient
			}
			onChange={onChange('relationshipToPatient')}
			value={values.relationshipToPatient}
		/>
	);

	return (
		<>
			<div className='column-layout-3'>
				<InputField
					disabled
					label={
						values.hasGuardian
							? "Patient's First Name"
							: 'First Name'
					}
					value={values.patientFirstName}
					error={touched.patientFirstName && errors.patientFirstName}
					onChange={onChange('patientFirstName')}
				/>
				<InputField
					disabled
					label={
						values.hasGuardian ? "Patient's Last Name" : 'Last Name'
					}
					value={values.patientLastName}
					error={touched.patientLastName && errors.patientLastName}
					onChange={onChange('patientLastName')}
				/>
				{values.hasGuardian ? DateOfBirth : Phone}
			</div>
			<CheckboxField
				className='guardian-checkbox'
				label='Check if patient has Guardian/Power of Attorney'
				value={values.hasGuardian}
				onChange={onChange('hasGuardian')}
			/>
			<div className='column-layout-3'>
				{values.hasGuardian ? (
					<>
						{AssociatedProgram}
						{ConsentToTreat}
						{PreferredName}
						{GuardianFirstName}
						{GuardianLastName}
						{Email}
						{Phone}
						{RelationshipToPatient}
					</>
				) : (
					<>
						{Email}
						{DateOfBirth}
						{AssociatedProgram}
						{ConsentToTreat}
						{PreferredName}
					</>
				)}
			</div>
			{values.relationshipToPatient &&
				values.relationshipToPatient !==
					GUARDIAN_RELATIONSHIP_ENUM.PARENT && (
					<p>
						Documentation must be provided before the patient's
						visit to validate this relationship. Please upload the
						documentation or reach out to the Guardian/POA to advise
						them that it must be uploaded prior to the visit.
					</p>
				)}
		</>
	);
}
