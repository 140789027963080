//React & Redux
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

//Components
import EmptySlot from './EmptySlot';
import AppointmentSlot from './AppointmentSlot';
import NotAvailableSlot from './NotAvailableSlot';
import SelectedAppointmentSlot from './SelectedAppointmentSlot';

export const EMPTY = 'EMPTY';
export const APPOINTMENT = 'APPOINTMENT';
export const NOT_AVAILABLE_SLOT = 'NA';
export const SELECTED = 'SELECTED';
const eventStyle = {
	position: 'absolute',
	left: 0,
	width: '100%',
	top: 0,
	height: '100%',
};
const MIN_HOUR = 7;
const MAX_HOUR = 21;
const SLOT_IN_HOUR = 2;
const NUMBER_OF_DAYS = 7;
const BUFFER = 4;
const SELECTED_SLOT_ZINDEX = ((MAX_HOUR-MIN_HOUR)* SLOT_IN_HOUR *NUMBER_OF_DAYS)+BUFFER ;
const HOVER_SLOT_ZINDEX = SELECTED_SLOT_ZINDEX * 2;


class Event extends React.Component {
	static propTypes = {
		event: PropTypes.object.isRequired,
	};

	minHour;
	maxHour;

	state = {
		hover:false
	};

	calcZIndex() {
		const startDate = this.props.event.start;
		const startHour = startDate.getHours();
		const startQuarters = Math.round(startDate.getMinutes() / 30);
		const hourDiff = MAX_HOUR-startHour;
		return ((hourDiff*2) - startQuarters+3);

	}


	calcBounds() {
		const startDate = this.props.event.start;
		const endDate = this.props.event.end;

		const startHour = startDate.getHours();
		const startQuarters = Math.round(startDate.getMinutes() / 15);

		const endHour = endDate.getHours();
		const endQuarters = Math.round(endDate.getMinutes() / 15);

		if (startHour < this.minHour || startHour > MAX_HOUR) {
			console.error(
				`Condition failed:  startHour < ${MIN_HOUR} || startHour > ${MAX_HOUR}`
			); // eslint-disable-line
			return null;
		}

		if (endHour < this.minHour || endHour > MAX_HOUR) {
			console.error(
				`Condition failed:  endHour < ${MIN_HOUR} || endHour > ${MAX_HOUR}`
			); // eslint-disable-line
			return null;
		}
		const delta = MAX_HOUR - MIN_HOUR;
		return {
			top:
				(((startHour - MIN_HOUR) * 4 + startQuarters) * 100) /
					(4 * delta) +
				'%',
			height:
				(((endHour - startHour) * 4 + endQuarters - startQuarters) *
					100) /
					(4 * delta) +
				'%',
		};
	}

	handleHover=(hover)=>{
		this.setState({hover});
	}

	renderEvent = () => {
		switch (this.props.event.type) {
			case EMPTY:
				return <EmptySlot event={this.props.event} handleHover={this.handleHover} />;
			case APPOINTMENT:
				return <AppointmentSlot event={this.props.event} />;
			case NOT_AVAILABLE_SLOT:
				return <NotAvailableSlot event={this.props.event} />;
			case SELECTED:
				return <SelectedAppointmentSlot event={this.props.event} />;
		}
	};

	render() {
		const {type} = this.props.event;
		const {hover}=this.state;
		const bounds = this.calcBounds();
		const zIndex = this.calcZIndex();
		const display = bounds ? 'block' : 'none';
		const className = classnames('gm-event', {
			'event-border-right': this.props.event.start.getDay() === 6

		});
		return (
			<div
				className={className}
				style={{
					...eventStyle,
					...bounds,
					display,
					zIndex:type == SELECTED?(zIndex+SELECTED_SLOT_ZINDEX):hover?(zIndex+HOVER_SLOT_ZINDEX):zIndex,
				}}
			>
				{this.renderEvent()}
			</div>
		);
	}
}

export default Event;
