//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import pdfIcon from '../../assets/pdf.svg';
import userIcon from '../../assets/user.svg';
import clockIcon from '../../assets/clock.svg';

//Lodash
import { isNull } from 'lodash';

//UI Libraries
import { Loading, Dialog } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';
import moment from 'moment';

//Styles
import './documents-list-modal.css';

//Components
import Icon from '../Common/Icon';
import Tooltip from '../Common/Tooltip';

// Utils
import { isPartnerInitiatedPreTest } from '../Patients/V2/Encounters/Common/utilsfunctions';

const DocumentsListModal = ({
	encounterDocuments = [],
	encounterDocumentsLoading = false,
	hideEncounterDocumentsList,
	test = {},
	handeDocClick,
}) => {
	const modalTitle = `${
		isPartnerInitiatedPreTest(test.encounter)
			? 'TRO'
			: !test.encounter.consultation_type && test.encounter.type === 'scp'
			? 'SCP'
			: !test.encounter.consultation_type &&
			  test.encounter.type === 'lab_test_authorization'
			? 'Lab'
			: test.encounter.consultation_type || 'N/A'
	} ${
		test.encounter.date_of_service
			? `- ${moment(test.encounter.date_of_service).format(
					'MMM D, YYYY'
			  )}`
			: ''
	}`;

	const getDocTime = (doc) => {
		const isModified = doc.created_at !== doc.updated_at;
		let time = isModified ? 'Modified ' : 'Added ';
		const key = isModified ? 'updated_at' : 'created_at';
		const docTimeFromNow = moment
			.duration(moment().diff(moment(doc[key])))
			.asMinutes();

		if (docTimeFromNow < 60) {
			time += `${parseInt(docTimeFromNow)} mins ago`;
		} else if (docTimeFromNow >= 60 && docTimeFromNow < 1440) {
			time += `${parseInt(docTimeFromNow / 60)} hrs ago`;
		} else if (docTimeFromNow >= 1440 && docTimeFromNow < 10080) {
			time += `${parseInt(docTimeFromNow / 1440)} days ago`;
		} else {
			time += moment(doc[key]).format('MMM D, YYYY');
		}

		return time;
	};

	const renderDocumentRow = (doc) => (
		<div className='document-row-container'>
			<ReactSVG className='document-icon' src={pdfIcon} alt='pdf icon' />
			<div className='display-content'>
				<div
					className={classnames({
						italic: doc.visit_document_status === 'draft',
					})}
				>
					<div
						className={classnames('file-name', {
							'is-archived': !!doc.deleted_at,
						})}
					>
						{doc.file_name || '--'}
					</div>
					<div className='patient-info'>
						<Tooltip
							placement='top'
							content={
								<div>
									Added By:
									<br />
									<span>
										{(doc.created_by?.first_name || '') +
											(doc.created_by?.last_name
												? ' ' + doc.created_by.last_name
												: '')}
									</span>
								</div>
							}
						>
							<ReactSVG src={userIcon} alt={`user icon`} />
							<span>
								{(doc.created_by?.first_name || '') +
									(doc.created_by?.last_name
										? ' ' + doc.created_by.last_name
										: '')}
							</span>
						</Tooltip>
						<Tooltip
							placement='top'
							content={
								<>
									<div>
										Added On:
										<br />
										{moment(doc.created_at).format(
											'MMM D, YYYY hh:mm A'
										)}
									</div>
									{doc.created_at !== doc.updated_at && (
										<>
											<div className='separator' />
											<div>
												Last Modified:
												<br />
												{moment(doc.updated_at).format(
													'MMM D, YYYY hh:mm A'
												)}
											</div>
										</>
									)}
								</>
							}
						>
							<ReactSVG src={clockIcon} alt={`clock icon`} />
							{getDocTime(doc)}
						</Tooltip>
					</div>
					<div className='description'>{doc.description}</div>
				</div>
				<div
					className={classnames({
						'doc-status': doc.visit_document_status,
						yellow: doc.visit_document_status === 'draft',
						green: doc.visit_document_status === 'finalized',
						orange: doc.visit_document_status === 'amended',
					})}
				>
					{doc.visit_document_status}
				</div>
			</div>
		</div>
	);

	return (
		<Dialog
			customClass='documents-list-dialog'
			title={
				<span className='title-wrapper'>
					<span>{modalTitle}</span>
					<span
						className='close-btn-icon'
						onClick={hideEncounterDocumentsList}
					>
						<Icon icon='close' className='close-icon' />
					</span>
				</span>
			}
			modal={true}
			closeOnPressEscape={true}
			closeOnClickModal={true}
			showClose={false}
			visible={true}
			onCancel={hideEncounterDocumentsList}
		>
			<Dialog.Body>
				{encounterDocumentsLoading && (
					<Loading loading={true} className='loader' />
				)}
				{!encounterDocumentsLoading && (
					<div className='document-list'>
						{(encounterDocuments || []).map((doc) => (
							<div
								data-testId='doc-list-item'
								key={doc.uuid}
								onClick={() => handeDocClick(doc)}
								className={classnames({
									'is-malicious': doc.is_malicious,
									scanning: isNull(doc.is_malicious),
								})}
							>
								{doc.is_malicious ? (
									<Tooltip
										placement='top'
										content='This file contains a virus and is blocked'
									>
										{renderDocumentRow(doc)}
									</Tooltip>
								) : isNull(doc.is_malicious) ? (
									<Tooltip
										placement='top'
										content='Scanning for viruses'
									>
										{renderDocumentRow(doc)}
									</Tooltip>
								) : (
									renderDocumentRow(doc)
								)}
							</div>
						))}
					</div>
				)}
			</Dialog.Body>
		</Dialog>
	);
};

export default DocumentsListModal;
