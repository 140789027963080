//React & Redux
import React, { useState } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import { createEnumsOptions } from '../Common/utilsfunctions';
import {
	CARE_PATIENTS_ENCOUNTER_NOTES_EDIT
} from '../../../../../Utils/permissionUtils';

//UI Libraries
import { Button, Loading } from 'gm-element-react';

//Components
import { EncounterSelectField } from '../Common/EncounterSelectField';
import { EncounterTextAreaField } from '../Common/EncounterTextAreaField';
import RBAC from '../../../../RBAC';

const EditAdditionalSegment = (props) => {
	const { encounter, saveTROEncounter, cancel, visitsschema } = props;
	const tempFormData = {
		notes: _.get(encounter, ['notes'], '')
	};
	const [formData, setFormData] = useState(tempFormData);
	const [loading, setLoading] = useState(false);

	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		setFormData(stateData);
	};

	const saveForm = () => {
		setLoading(true);
		const updatedformdata = _.mapValues(formData, function (value, key) {
			if (key == 'notes') return value && value.trim();
			if (value == 'empty') return '';
			return value;
		});
		saveTROEncounter(encounter, updatedformdata)
			.then((result) => {
				setTimeout(() => {
					setLoading(false);
					cancel();
				}, 1000);
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	return (
		<Loading loading={loading}>
			<div>
				<div className='edit-encntr-lbl-cc-notes'>CC NOTES</div>
				<div className='segment-row'>
					<RBAC
						action={CARE_PATIENTS_ENCOUNTER_NOTES_EDIT}
						yes={
							<EncounterTextAreaField
								value={_.get(formData, ['notes'], '')}
								name='notes'
								onChange={(value) =>
									onFormChange('notes', value)
								}
							/>
						}
						no={
							<EncounterTextAreaField
								value={_.get(formData, ['notes'], '')}
								name='notes'
								disabled={true}
								onChange={(value) => {}}
							/>
						}
					/>
				</div>

				<div className='segment-footer-container'>
					<div className='cancel-btn-link'>
						<Button onClick={() => cancel()}>Cancel</Button>
					</div>
					<div className='save-btn-link'>
						<Button style={{ float: 'right' }} onClick={saveForm}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</Loading>
	);
};

export default EditAdditionalSegment;
