//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Components
import AppointmentAlert from './AppointmentAlerts';
import InitialVisitSummaryReady from './InitialVisitSummaryReady';
import ResultVisitSummaryReady from './ResultVisitSummaryReady';

//Styles
import './DashboardEncounterContainer.css';

const DashboardEncounterContainer = (props) => {
	const { history = {} } = props;

	const filter = get(history, 'location.search', '').split('=')[1] || null;

	if (filter == 'appointment_alerts') {
		return <AppointmentAlert {...props} />;
	} else if (filter == 'initial_visit_summary_ready') {
		return <InitialVisitSummaryReady {...props} />;
	} else if (filter == 'result_visit_summary_ready') {
		return <ResultVisitSummaryReady {...props} />;
	} else {
		return <div />;
	}
};

export default DashboardEncounterContainer;
