import * as actionTypes from './actionTypes';
import TestService from '../service/Tests.js';
import get from 'lodash/get';
import { hydrateTestOrders } from '../transformers/patients/testOrderTransformer';

export const gettingTests = () => {
	return {
		type: actionTypes.GETTING_TESTS,
		payload: true,
	};
};

export const tests = (data) => {
	return {
		type: actionTypes.TESTS,
		payload: data,
	};
};

const getTestsError = (err) => {
	return {
		type: actionTypes.GET_TESTS_ERROR,
		payload: err,
	};
};

export function getTests(data) {
	return (dispatch) => {
		dispatch(gettingTests());
		TestService.getTests(data)
			.then((res) => {
				dispatch(tests(get(res, 'data', {})));
			})
			.catch((err) => {
				dispatch(getTestsError(err));
			});
	};
}

export const gettingPatientTests = (data) => {
	return {
		type: actionTypes.GETTING_TESTS,
		payload: data,
	};
};

export function getPatientTests(data) {
	return (dispatch) => {
		dispatch(gettingPatientTests(true));
		TestService.getPatientTests(data)
			.then((res) => {
				dispatch(tests(hydrateTestOrders(get(res, 'data', {}))));
			})
			.catch(() => {
				dispatch(tests({ 'no-data': true }));
			});
	};
}

export function getTestDetail(uuid) {
	return (dispatch) => {
		dispatch(gettingTestDetail());
		TestService.getTestDetail(uuid).then((res) => {
			dispatch(testdetail(get(res, 'data', {})));
		});
	};
}

export function resetTestDetail() {
	return (dispatch) => {
		dispatch(testdetail({}));
	};
}

export function getOrderTestDetail(uuid) {
	return (dispatch) => {
		dispatch(gettingTestDetail());
		TestService.getOrderTestDetail(uuid)
			.then((res) => {
				dispatch(testdetail(res));
			})
			.catch((err) => {
				dispatch(testDetailError(err));
			});
	};
}

export const gettingTestDetail = () => {
	return {
		type: actionTypes.GETTING_TEST_DETAIL,
		payload: true,
	};
};
export const testdetail = (data) => {
	return {
		type: actionTypes.TEST_DETAIL,
		payload: data,
	};
};

const testDetailError = (err) => {
	return {
		type: actionTypes.TEST_DETAIL_ERROR,
		payload: err,
	};
};

export function resetTest(uuid, data) {
	return (dispatch) => {
		dispatch(resetingTest());
		TestService.resetTest(uuid, data)
			.then((res) => {
				dispatch(doneResetTest(get(res, 'data', {})));
			})
			.catch((err) => {
				dispatch(resetTestError(err));
			});
	};
}

export const resetingTest = () => {
	return {
		type: actionTypes.TEST_RESETTING,
		payload: true,
	};
};

export const doneResetTest = (data) => {
	return {
		type: actionTypes.TEST_REST_DONE,
		payload: data,
	};
};

const resetTestError = (err) => {
	return {
		type: actionTypes.TEST_REST_ERROR,
		payload: err,
	};
};

export function updateTest(data) {
	return (dispatch) => {
		dispatch(updatingTest());
		TestService.updateTest(data).then((res) => {
			dispatch(updatedTest(get(res, 'data', {})));
		});
	};
}

export const updatingTest = () => {
	return {
		type: actionTypes.UPDATING_TEST,
		payload: true,
	};
};

export const updatedTest = (data) => {
	return {
		type: actionTypes.UPDATED_TEST,
		payload: data,
	};
};

export function createTest(data) {
	return (dispatch) => {
		dispatch(creatingTest());
		TestService.createTest(data)
			.then((res) => {
				dispatch(createdTest(get(res, 'data', {})));
			})
			.catch((err) => {
				dispatch(createdTestError(err));
			});
	};
}

export const creatingTest = () => {
	return {
		type: actionTypes.CREATING_TEST,
		payload: true,
	};
};

export const createdTest = (data) => {
	return {
		type: actionTypes.CREATED_TEST,
		payload: data,
	};
};

export const createdTestError = (err) => {
	return {
		type: actionTypes.CREATED_TEST_ERROR,
		payload: err,
	};
};

export function getTestTypes() {
	return (dispatch) => {
		dispatch(gettingTestTypes());
		TestService.getTestTypes()
			.then((res) => {
				dispatch(gotTestTypes(get(res, 'data.data', [])));
			})
			.catch((err) => {
				dispatch(gotTestTypesError(err));
			});
	};
}

export const gettingTestTypes = () => {
	return {
		type: actionTypes.GETTING_TEST_TYPES,
		payload: true,
	};
};

export const gotTestTypes = (data) => {
	return {
		type: actionTypes.GOT_TEST_TYPES,
		payload: data,
	};
};

export const gotTestTypesError = (err) => {
	return {
		type: actionTypes.GOT_TEST_TYPES_ERROR,
		payload: err,
	};
};

export function getTags() {
	return (dispatch) => {
		dispatch(gettingTags());
		TestService.getTags()
			.then((res) => {
				dispatch(gotTags(get(res, 'data.data', [])));
			})
			.catch((err) => {
				dispatch(gotTagsError(err));
			});
	};
}

export const gettingTags = () => {
	return {
		type: actionTypes.GETTING_TEST_TAGS,
		payload: true,
	};
};

export const gotTags = (data) => {
	return {
		type: actionTypes.GOT_TEST_TAGS,
		payload: data,
	};
};

export const gotTagsError = (err) => {
	return {
		type: actionTypes.GOT_TEST_TAGS_ERROR,
		payload: err,
	};
};

export function getOtherEnums() {
	return (dispatch) => {
		dispatch(gettingOtherEnums());
		TestService.getOtherEnums()
			.then((res) => {
				dispatch(gotOtherEnums(get(res, 'data.data', {})));
			})
			.catch((err) => {
				dispatch(gotOtherEnumsError(err));
			});
	};
}

export const gettingOtherEnums = () => {
	return {
		type: actionTypes.GETTING_OTHER_ENUMS,
		payload: true,
	};
};

export const gotOtherEnums = (data) => {
	return {
		type: actionTypes.GOT_OTHER_ENUMS,
		payload: data,
	};
};

export const gotOtherEnumsError = (err) => {
	return {
		type: actionTypes.GOT_OTHER_ENUMS_ERROR,
		payload: err,
	};
};

const getTestAuthEnumsStarted = () => {
	return {
		type: actionTypes.GET_TEST_AUTH_ENUMS_STARTED,
	};
};

const getTestAuthEnumsSuccess = (payload) => {
	return {
		type: actionTypes.GET_TEST_AUTH_ENUMS_SUCCESS,
		payload,
	};
};

const getTestAuthEnumsFailed = (payload) => {
	return {
		type: actionTypes.GET_TEST_AUTH_ENUMS_FAILED,
		payload,
	};
};

export function getTestAuthEnums(data) {
	return (dispatch) => {
		dispatch(getTestAuthEnumsStarted());
		TestService.getTestAuthEnums(data)
			.then((res) => {
				dispatch(
					getTestAuthEnumsSuccess(get(res, ['data', 'data'], {}))
				);
			})
			.catch((err) => {
				dispatch(getTestAuthEnumsFailed(err));
			});
	};
}

export function getGeneticTests() {
	return (dispatch) => {
		dispatch(gettingGeneticTests());
		TestService.getGeneticTests()
			.then((res) => {
				dispatch(gotGeneticTests(get(res, 'data.data', {})));
			})
			.catch((err) => {
				dispatch(gotGeneticTestsError(err));
			});
	};
}

export const gettingGeneticTests = () => {
	return {
		type: actionTypes.GETTING_GENETIC_TESTS,
		payload: true,
	};
};

export const gotGeneticTests = (data) => {
	return {
		type: actionTypes.GOT_GENETIC_TESTS,
		payload: data,
	};
};

export const gotGeneticTestsError = (err) => {
	return {
		type: actionTypes.GOT_GENETIC_TESTS_ERROR,
		payload: err,
	};
};
