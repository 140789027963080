import axios from 'axios';
import {
	hydrateOutreachs,
	hydrateOutreachSetting,
} from '../transformers/patients/outreachTransformer';

class OutreachService {
	getOutreachEnums = () => {
		const url = `/api/enums/communications`;
		return axios.get(url);
	};

	getOutreachs = (patient_uuid) => {
		const url = `/api/patients/${patient_uuid}/outreach`;
		return axios.get(url).then((data) => hydrateOutreachs(data));
	};

	addOutreach = (data, patient_uuid) => {
		const url = `/api/patients/${patient_uuid}/communications`;
		const body = data;
		return axios.post(url, body);
	};

	updateOutreach = (data, patient_uuid, communication_uuid) => {
		const url = `/api/patients/${patient_uuid}/communications/${communication_uuid}`;
		const body = data;
		return axios.post(url, body);
	};

	doNotOutreach = (data, patient_uuid = 0) => {
		const url = `/api/patients/${patient_uuid}/outreach/settings`;
		const body = data;
		return axios.post(url, body);
	};

	getOutreachSetting = (patient_uuid) => {
		const url = `/api/patients/${patient_uuid}/outreach/settings`;
		return axios.get(url).then((data) => hydrateOutreachSetting(data));
	};

	getOutreachDeclinedEnum = () => {
		return axios.get('/api/enums/patients/outreach_declined_via');
	};

	createOutreachRound = (patientUuid, data) => {
		return axios.post(
			`/api/patients/${patientUuid}/referral-outreach-round`,
			data
		);
	};

	updateOutreachRound = (patientUuid, roundId, data) => {
		return axios.patch(
			`/api/patients/${patientUuid}/referral-outreach-round/${roundId}`,
			data
		);
	};

	getPatientOutreachRounds = (patientUuid, isOnlyLatest) => {
		let url = `/api/patients/${patientUuid}/referral-outreach-round`;

		if (isOnlyLatest) {
			url += '?latest=true';
		}

		return axios.get(url);
	};
}

export default new OutreachService();
