//React & Redux
import React, { Fragment } from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Select } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';

export const EncounterMultiSelectField = ({
	name,
	value,
	onChange,
	placeholder,
	trim,
	extraclassName,
	disabled,
	error,
	options,
	istouched = true,
}) => {
	const iserror = istouched && error && !_.isNil(error) && !_.isEmpty(error);
	const currentClassName = classnames(
		iserror ? 'encounter-input-error-field' : 'encounter-input-field',
		extraclassName
	);
	return (
		<Fragment>
			<div className={currentClassName}>
				<Select
					value={value}
					disabled={disabled}
					onChange={onChange}
					multiple={true}
					placeholder={placeholder || 'Select'}
					className={iserror ? 'inputFieldDropdownError' : ''}
				>
					{(_.isArray(options) ? options : []).map((el) => {
						return (
							<Select.Option
								key={el.value}
								label={el.label}
								value={el.value}
							/>
						);
					})}
				</Select>
			</div>
			<div className='encounter-input-error'>{iserror ? error : ''}</div>
		</Fragment>
	);
};
