//React & Redux
import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

//Hooks
import { usePrevious } from '../../hooks/usePrevious';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Layout, Select, Form, Button } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';

//Components
import AddEditExternalProviderModal from '../ExternalProviders/AddEditExternalProviderModal';
import ErrorBoundary from '../Common/ErrorBoundary';

//Styles
import './ProviderForm.css';

const ProviderForm = (props) => {
	const {
		createdProviderError = false,
		createProvider,
		creatingProvider = false,
		editingProvider = false,
		editProviderData = {},
		existingProviderProp = false,
		getProviders,
		getSpecialty,
		handleSearchingProvider,
		initialValues = {},
		linkExistingProvider,
		linkingDefaultProviders = false,
		onSubmit,
		providerCredentials,
		providers: providersProp = [],
		providerSpecialties,
		searchingProvider,
		selectedProviders = [],
		updatedProviderError = false,
		updateProvider,
		updatingProvider = false,
	} = props;

	const providerFormRef = useRef(null);

	const rules = {
		providerUUID: [
			{
				required: false,
				message: 'External Provider is required',
				trigger: 'change',
			},
			{
				validator: (rule, value, callback) => {
					if (isAlreadySelected(value)) {
						callback(new Error('Provider is already linked.'));
					} else {
						callback();
					}
				},
			},
		],
	};

	const [form, setForm] = useState({});
	const [canAddEditProvider, setCanAddEditProvider] = useState(false);
	const [existingProvider, setExistingProvider] =
		useState(existingProviderProp);
	const [extProviderPayload, setExtProviderPayload] = useState({});
	const [searchQuery, setSearchQuery] = useState('');
	const [providers, setProviders] = useState(providersProp);

	const prevProvidersProp = usePrevious(providersProp);

	useEffect(() => {
		setInitialValues();
	}, []);

	useEffect(() => {
		if (prevProvidersProp != providersProp) {
			setProviders(providersProp);
			handleSearchingProvider(false);
		}
	}, [providersProp]);

	useEffect(() => {
		if (searchQuery) searchProviders();
	}, [searchQuery]);

	const isAlreadySelected = (value) => {
		const obj = selectedProviders.find(
			(el) => el && el.providerUUID == value
		);
		return !_.isEmpty(obj) ? true : false;
	};

	const setInitialValues = () => {
		setForm({
			providerUUID: initialValues?.providerUUID || null,
		});
	};

	const onSearch = (query) => {
		onChange('providerUUID', null);
		if (query !== '') {
			setSearchQuery(query);
			setProviders([]);
		} else {
			setSearchQuery('');
			setProviders([]);
		}
	};

	const searchProviders = _.debounce(() => {
		searchAllProviders(searchQuery);
	}, 500);

	const searchAllProviders = (searchQuery) => {
		handleSearchingProvider(true);
		getProviders(searchQuery);
	};

	const onSubmitForm = (e) => {
		e.preventDefault();
		if (linkingDefaultProviders) {
			if (editingProvider) {
				const { uuid } = editProviderData;
				updateProvider(uuid, extProviderPayload);
			} else {
				if (existingProvider) {
					providerFormRef &&
						providerFormRef.current &&
						providerFormRef.current.validate((valid) => {
							if (valid) {
								linkExistingProvider(
									[form.providerUUID] || ['']
								);
							} else {
								return false;
							}
						});
				} else {
					createProvider(extProviderPayload).then((uuid) => {
						linkExistingProvider([uuid] || ['']);
					});
				}
			}
		} else {
			if (existingProvider) {
				providerFormRef &&
					providerFormRef.current &&
					providerFormRef.current.validate((valid) => {
						if (valid) {
							onSubmit(form);
						} else {
							return false;
						}
					});
			} else {
				if (editingProvider) {
					const { uuid } = editProviderData;
					updateProvider(uuid, extProviderPayload);
				} else {
					createProvider(extProviderPayload);
				}
			}
		}
	};

	const onChange = (key, value) => {
		setForm(Object.assign({}, form, { [key]: value }));
		if (key == 'providerUUID') handleExistingProvider(value);
	};

	const handleExistingProvider = (value) => {
		setExistingProvider(!_.isEmpty(value) ? true : false);
	};

	const setExternalSubmit = (value, extProviderPayload) => {
		setCanAddEditProvider(value);
		setExtProviderPayload(extProviderPayload);
	};

	const isDisabled = () => {
		if (canAddEditProvider) {
			return false;
		} else if (existingProvider && !_.isNil(form?.providerUUID || null)) {
			return false;
		} else {
			return true;
		}
	};

	const hideProviderModal = () => {
		return false;
	};

	const isExtProviderAddEditLoaded = () => {
		if (editingProvider) {
			return !_.isEmpty(editProviderData);
		} else {
			return true;
		}
	};

	return (
		<Form
			ref={providerFormRef}
			className='provider-form'
			model={form}
			rules={rules}
		>
			<div className='provider-form-inner'>
				{!editingProvider && (
					<Fragment>
						<Layout.Row>
							<Layout.Col span='24'>
								<div className='section-container'>
									<div className='section-label'>
										Select Existing
									</div>
									<div className='section-divider'></div>
								</div>
							</Layout.Col>
						</Layout.Row>
						<Layout.Row style={{ marginBottom: '35px' }}>
							<Layout.Col span='24'>
								<Form.Item
									label='External Provider'
									prop='providerUUID'
								>
									<ErrorBoundary>
										<Select
											className='ext-provider-filter'
											filterable={true}
											clearable={true}
											value={form.providerUUID || null}
											placeholder='Type to search'
											onChange={(e) =>
												onChange('providerUUID', e)
											}
											remote={true}
											remoteMethod={onSearch}
											loading={searchingProvider}
										>
											{(providers || []).map(
												(e) =>
													e && (
														<Select.Option
															key={e.providerUUID}
															label={`${e.providerFullName}`}
															value={
																e.providerUUID
															}
														>
															<span className='pro-wrapper'>
																<span className='pro-item'>
																	<span className='pro-info'>
																		<span className='pro-name'>
																			{
																				e.providerFullName
																			}
																		</span>
																		<span className='pro-location'>
																			{`${_.get(
																				e,
																				[
																					'city',
																				],
																				''
																			)}${
																				!_.isEmpty(
																					_.get(
																						e,
																						[
																							'state',
																						],
																						''
																					)
																				)
																					? ', ' +
																					  _.get(
																							e,
																							[
																								'state',
																							],
																							''
																					  )
																					: ''
																			}`}
																		</span>
																	</span>
																</span>
																<span className='pro-specialty'>
																	{getSpecialty(
																		_.get(
																			e,
																			'specialities.0',
																			''
																		),
																		''
																	)}
																</span>
															</span>
														</Select.Option>
													)
											)}
										</Select>
									</ErrorBoundary>
								</Form.Item>
							</Layout.Col>
						</Layout.Row>
						<Layout.Row
							className={classnames(
								'or-add-new',
								existingProvider ? 'disabled' : ''
							)}
						>
							<Layout.Col span='24'>
								<div className='section-container'>
									<div className='section-label'>
										Or Add New
									</div>
									<div className='section-divider'></div>
								</div>
							</Layout.Col>
						</Layout.Row>
					</Fragment>
				)}
				<Layout.Row>
					<Layout.Col
						span='24'
						className={existingProvider ? 'disabled' : ''}
					>
						<div
							className={classnames(
								'add-edit-ext-proivder',
								existingProvider ? 'disabled' : ''
							)}
						>
							{isExtProviderAddEditLoaded() && (
								<AddEditExternalProviderModal
									{...{
										createProvider,
										creatingProvider,
										createdProviderError,
										editProviderData,
										editingProvider,
										hideProviderModal,
										providerCredentials,
										providerSpecialties,
										setExternalSubmit,
										updateProvider,
										updatedProviderError,
										updatingProvider,
									}}
								/>
							)}
						</div>
					</Layout.Col>
				</Layout.Row>
			</div>
			<Layout.Row>
				<Form.Item className='el-dialog__footer'>
					<Button
						disabled={isDisabled()}
						onClick={onSubmitForm}
						className='confirm-button'
					>
						{linkingDefaultProviders || editingProvider
							? 'Save'
							: 'Next'}
					</Button>
				</Form.Item>
			</Layout.Row>
		</Form>
	);
};

ProviderForm.propTypes = {
	initialValues: PropTypes.object,
	onSubmit: PropTypes.func,
};

export default ProviderForm;
