//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import xIcon from './../../assets/close.svg';
import searchIcon from './../../assets/ic_search.svg';

//Lodash
import get from 'lodash/get';

class SearchbarExpanded extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inputFocused: false,
			closing: false,
			closed: true,
		};
		//bindings
		this.handleChange = this.handleChange.bind(this);
		this.expandWidth = this.expandWidth.bind(this);
		this.bodyClickHandler = this.bodyClickHandler.bind(this);
		this.setDelayedClose = this.setDelayedClose.bind(this);
		this.clearInput = this.clearInput.bind(this);
		this.handleInputFocus = this.handleInputFocus.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleContainerClick = this.handleContainerClick.bind(this);
	}
	//functions

	componentDidMount() {
		document
			.querySelector('body')
			.addEventListener('click', this.bodyClickHandler, {
				capture: true,
			});
		if (get(this, 'props.currentValue', false)) {
			this.expandedSearchBar.click();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.currentValue !== prevProps.currentValue)
			this.expandWidth();
	}

	componentWillUnmount() {
		document
			.querySelector('body')
			.removeEventListener('click', this.bodyClickHandler);
	}

	bodyClickHandler(e) {
		const currentNode = e.target;
		if (
			get(this, 'expandedSearchBar', null) &&
			get(this, 'searchBarInput', null) &&
			!get(this, 'state.closed', false)
		) {
			if (
				!this.expandedSearchBar.contains(currentNode) &&
				!get(this, 'props.currentValue', null)
			) {
				this.searchBarInput.style.width = '0px';
				this.searchBarInput.style.marginRight = '0px';
				this.searchBarInput.style.padding = '0px';
				this.searchBarInput.style.tabIndex = '-1';
				this.searchBarInput.style.opacity = '0';
				this.expandedSearchBar.style.cursor = 'pointer';
				this.setDelayedClose();
			}
		}
	}

	setDelayedClose() {
		this.setState({ closing: true });
		setTimeout(() => {
			this.setState({ closed: true, closing: false });
		}, 500);
	}

	expandWidth() {
		this.setState({ closed: false });
		const searchBarInput = get(
			this,
			'searchBarInput',
			document.createElement('span')
		);
		searchBarInput.style.width = '154px';
		searchBarInput.style.marginRight = '6px';
		searchBarInput.style.padding = '1px 2px';
		searchBarInput.style.tabIndex = 'unset';
		searchBarInput.style.opacity = '1';
		get(
			this,
			'expandedSearchBar',
			document.createElement('span')
		).style.cursor = 'unset';
		setTimeout(() => searchBarInput.focus(), 10);
	}

	handleChange(e) {
		if (get(this, 'props.hasSetStateFunc', false)) {
			this.props.setStateFunc(
				get(this, 'props.useInputEvtObj', false)
					? e
					: get(e, 'target.value', '')
			);
		}
	}

	clearInput(e) {
		if (get(this, 'props.currentValue', '')) {
			this.handleChange({
				target: {
					value: '',
				},
			});
		}
	}

	handleInputFocus(e) {
		this.setState({ inputFocused: true });
	}

	handleInputBlur(e) {
		this.setState({ inputFocused: false });
	}

	handleContainerClick(e) {
		if (get(this, 'state.closed', false)) {
			this.expandWidth();
		}
	}

	render() {
		const inputClassName = get(this, 'state.inputFocused', false)
			? 'expandedSearchbarContainer expandedSearchbarContainerActive'
			: 'expandedSearchbarContainer';
		return (
			<div
				data-testId='expanded-searchbar-container'
				className={inputClassName}
				ref={(el) => (this.expandedSearchBar = el)}
				onClick={this.handleContainerClick}
			>
				<input
					data-testId='sb-input'
					className='expandedSearchbarInput'
					onChange={this.handleChange}
					value={get(this, 'props.currentValue', '')}
					onFocus={this.handleInputFocus}
					onBlur={this.handleInputBlur}
					placeholder={
						get(this, 'state.closing', false)
							? ''
							: get(this, 'props.placeholder', '') || 'Search'
					}
					ref={(el) => (this.searchBarInput = el)}
				/>
				{get(this, 'props.currentValue', '') ? (
					<ReactSVG
						data-testId='sb-clear'
						src={xIcon}
						className='expandedSearchBarXIcon'
						onClick={this.clearInput}
					/>
				) : (
					<ReactSVG
						src={searchIcon}
						className='retractedSearchBarSearchIcon'
						style={{
							position: 'relative',
							bottom: '8px',
							right: '15px',
							transform: 'scale(1.06)',
						}}
					/>
				)}
			</div>
		);
	}
}

export default SearchbarExpanded;
