import React, { Fragment } from 'react';
import { Loading } from 'gm-element-react';
import ReactSVG from 'react-svg';
import get from 'lodash/get';

import searchIcon from '../../../../../assets/icd-search.svg';
import inputSearchIcon from '../../../../../assets/pat-search-icon.svg';
import clearSearchIcon from '../../../../../assets/exit-alert.svg';

//Components
import Pagination from '../../../../Common/Pagination';

export function SearchForPatient() {
	return (
		<div className='searchForPatient'>
			<ReactSVG src={searchIcon} className='searchIcon' />
			<div>Search for a Patient</div>
		</div>
	);
}

export function PatientsList(props) {
	return (
		<div className='patientsList'>
			{props.patients.map((patient) => {
				return (
					<div
						key={patient.uuid}
						className={`${
							patient.uuid === props.currentPatient
								? 'disabled'
								: props.selectedPatient &&
								  props.selectedPatient.uuid === patient.uuid
								? 'selected'
								: ''
						}`}
						onClick={(e) => props.handleSelectPatient(e, patient)}
					>
						<div className='radioButton'>
							<div />
						</div>
						<div className='content'>
							<div className='name'>
								{patient.first_name + ' ' + patient.last_name}
							</div>
							<div className='email'>{patient.email}</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export function SearchBox(props) {
	return (
		<div className='searchBox'>
			<input
				type='text'
				placeholder='Search'
				value={props.query}
				onChange={props.handleQueryChange}
			/>
			{props.query.length ? (
				<ReactSVG
					src={clearSearchIcon}
					className='clearSearchIcon'
					onClick={props.clearSearch}
				/>
			) : (
				<ReactSVG src={inputSearchIcon} className='inputSearchIcon' />
			)}
		</div>
	);
}

export default function (props) {
	const { paginationLimit, patient, patientSearchResult, currentPatient } =
		props;
	const isLoading =
		get(patient, ['loading'], false) ||
		get(patientSearchResult, ['loading'], false);

	return (
		<Fragment>
			<SearchBox
				query={get(patient, ['query'], '')}
				handleQueryChange={props.handleQueryChange}
				clearSearch={props.clearQuery}
			/>
			<Fragment>
				{get(patient, ['query', 'length'], 0) > 1 ? (
					<Loading
						loading={isLoading}
						className='patientsListContainer'
					>
						{get(
							patientSearchResult,
							['patients', 'data', 'data', 'length'],
							0
						) > 0 &&
						get(patient, ['query', 'length'], 0) > 1 &&
						!isLoading ? (
							<Fragment>
								<PatientsList
									patients={get(
										patientSearchResult,
										['patients', 'data', 'data'],
										[]
									)}
									selectedPatient={get(
										patient,
										['selected'],
										{}
									)}
									handleSelectPatient={
										props.handleSelectPatient
									}
									currentPatient={currentPatient}
								/>
								{get(
									patientSearchResult,
									['patients', 'data', 'total'],
									0
								) > paginationLimit && (
									<Pagination
										className='appointment-pagination'
										pageSize={paginationLimit}
										total={get(
											patientSearchResult,
											['patients', 'data', 'total'],
											0
										)}
										offset={get(
											patient,
											['pagination', 'offset'],
											0
										)}
										layout='prev, pager, next'
										onCurrentChange={props.handlePageChange}
									/>
								)}
							</Fragment>
						) : null}
					</Loading>
				) : (
					<SearchForPatient />
				)}
			</Fragment>
		</Fragment>
	);
}
