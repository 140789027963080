import React, { useState } from 'react';

const RetractDocument = (props) => {
	const { handleRetract } = props;

	const [retractClicked, setRetractClicked] = useState(false);

	return (
		<div className='retract'>
			{retractClicked ? (
				<div>
					<i>Are you sure?</i>{' '}
					<span data-testId='retract-clicked' onClick={handleRetract}>
						Yes
					</span>{' '}
					<div className='dot' />{' '}
					<span
						data-testId='cancel'
						onClick={() => setRetractClicked(false)}
					>
						Cancel
					</span>
				</div>
			) : (
				<span
					data-testId='retract-not-clicked'
					onClick={() => setRetractClicked(true)}
				>
					Retract
				</span>
			)}
		</div>
	);
};

export default RetractDocument;
