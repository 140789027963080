//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//Utils
import { currentAddressKey, getHomeAddress } from '../../../utils';

//UI Libraries
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Layout } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Components
import Tooltip from '../../Common/Tooltip';

//Styles
import '../css/patient-detail.css';

const PatientDetail = (props) => {
	const {
		closeDetailView,
		enqueueSnackbar,
		patientDetail,
		patientDetail: {
			email = '',
			external_patient_id: externalPatientId = '',
			first_name: firstName = '',
			last_name: lastName = '',
			phone = '',
			referral_program: referralProgram = '',
			uuid = '',
		},
		sex_assigned_at_birth: sexAssignedAtBirth = '',
		sexAssignedAtBirthEnum,
	} = props;

	const getDOBYr = (patient) => {
		if (_.get(patient, 'dob') && _.get(patient, 'dob') != null) {
			return (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div>
						{moment(_.get(patient, 'dob')).format('MMM D, YYYY')}
					</div>
					<div>
						{moment().diff(patient.dob, 'years') + ' yrs '}
						{moment(_.get(patient, 'dob')).format('MM/DD/YYYY')}
					</div>
				</div>
			);
		} else {
			return 'DOB Unknown';
		}
	};

	const getPatientAddress = (address) => {
		if (address == null) return '--';

		const value =
			_.get(address, 'address_line1', '--') +
			(_.get(address, 'address_line2') &&
			_.get(address, 'address_line2') != null
				? ', ' + _.get(address, 'address_line2')
				: '') +
			(_.get(address, 'city') && _.get(address, 'city') != null
				? '\n' + _.get(address, 'city')
				: '') +
			(_.get(address, 'state') && _.get(address, 'state') != null
				? ', ' + _.get(address, 'state')
				: '') +
			(_.get(address, 'zip') && _.get(address, 'zip') != null
				? ' ' + _.get(address, 'zip')
				: '');

		return value || '--';
	};

	const copyUUID = (text) => {
		enqueueSnackbar(text + ' has been copied.', {
			variant: 'success',
			className: 'export_notification',
			anchorOrigin: { horizontal: 'center', vertical: 'top' },
		});
	};

	const getSexAssignedAtBirth = (sexAssignedAtBirth) => {
		const sexAssignedAtBirthVal = sexAssignedAtBirthEnum.find(
			(o) => o.key == sexAssignedAtBirth
		);
		return sexAssignedAtBirthVal
			? _.get(sexAssignedAtBirthVal, 'display_name', '')
			: '';
	};

	const getPhoneDetails = (type) => {
		if (_.get(patientDetail, 'has_guardian', false)) {
			if (_.get(patientDetail, ['guardian', 'phones', type], null)) {
				const phoneDetails = _.get(
					patientDetail,
					['guardian', 'phones', type],
					{}
				);
				return (
					phoneDetails.value +
					' (' +
					_.capitalize(type) +
					(phoneDetails.type
						? '-' + _.capitalize(phoneDetails.type)
						: '') +
					') (Guardian)'
				);
			}
		} else if (_.get(patientDetail, ['phones', type], null)) {
			const phoneDetails = _.get(patientDetail, ['phones', type], {});
			return (
				phoneDetails.value +
				' (' +
				_.capitalize(type) +
				(phoneDetails.type
					? '-' + _.capitalize(phoneDetails.type)
					: '') +
				')'
			);
		}
		return null;
	};
	const homeAddress = getHomeAddress(
		_.get(patientDetail, currentAddressKey, null)
	);

	return (
		<section className='billing-patient-detail-wrapper'>
			<Layout.Row>
				<Layout.Col span='23'>
					<span className='patient-name'>
						{firstName + ' ' + lastName}
					</span>
				</Layout.Col>
				<Layout.Col span='1' className='icon-close'>
					<i
						className='material-icons search'
						onClick={closeDetailView}
					>
						{' '}
						close{' '}
					</i>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='16' type='flex'>
				<Layout.Col span='11'>
					<div className='patient-uuid'>
						<Tooltip
							id='uuid_tooltip'
							className='uuid_tooltip'
							content='Patient UUID'
							placement='bottom-end'
						>
							<span>{uuid}</span>
						</Tooltip>
						{_.get(uuid, 'length', 0) > 0 && (
							<CopyToClipboard
								text={uuid}
								onCopy={() => copyUUID('Patient UUID')}
							>
								<i className='material-icons copy-icon'>
									{' '}
									file_copy{' '}
								</i>
							</CopyToClipboard>
						)}
					</div>
					<div className='external-patient-id'>
						<Tooltip
							id='uuid_tooltip'
							className='uuid_tooltip'
							content='External Patient ID'
							placement='bottom-end'
						>
							<span>{externalPatientId}</span>
						</Tooltip>
						{!_.isNil(externalPatientId) &&
							_.get(externalPatientId, 'length', 0) > 0 && (
								<CopyToClipboard
									text={externalPatientId}
									onCopy={() =>
										copyUUID('External patient ID')
									}
								>
									<i className='material-icons copy-icon'>
										{' '}
										file_copy{' '}
									</i>
								</CopyToClipboard>
							)}
					</div>
				</Layout.Col>
				<Layout.Col span='6'>
					<div className='dob'>{getDOBYr(patientDetail)}</div>
				</Layout.Col>
				<Layout.Col span='7'>
					<div className='biological-sex'>
						{getSexAssignedAtBirth(sexAssignedAtBirth)}
					</div>
					<div className='referral-prgm'>{referralProgram}</div>
				</Layout.Col>
			</Layout.Row>
			<Layout.Row gutter='16' type='flex' style={{ marginTop: '12px' }}>
				<Layout.Col span='11'>
					<div className='patient-email'>{email}</div>
					<div className='patient-phone'>
						{getPhoneDetails('primary') ||
						getPhoneDetails('secondary') ? (
							<>
								{getPhoneDetails('primary') && (
									<span>{getPhoneDetails('primary')}</span>
								)}
								<br></br>
								{getPhoneDetails('secondary') && (
									<span>{getPhoneDetails('secondary')}</span>
								)}
							</>
						) : (
							'Phone # Unknown'
						)}
					</div>
				</Layout.Col>
				<Layout.Col span='6'>
					<div className='lbl-state'>Visit State</div>
					<div className='patient-state'>
						{_.get(homeAddress, 'state', '') || '--'}
					</div>
				</Layout.Col>
				<Layout.Col span='7'>
					<div className='lbl-homeaddress'>Home Address</div>
					<div className='patient-address'>
						{getPatientAddress(homeAddress)}
					</div>
				</Layout.Col>
			</Layout.Row>
		</section>
	);
};

export default PatientDetail;
