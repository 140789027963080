import classnames from 'classnames';
import React, { useContext } from 'react';
import { withRouter } from 'react-router';

import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { enqueueSnackbar } from '../../../utils';
import { CARE_PATIENTS_CREATE } from '../../../Utils/permissionUtils';
import ReferralDashboard from '../../../service/ReferralDashboard';
import Button from '../../Common/Button';
import RBAC from '../../RBAC';
import { ReferralPageContext } from '../hooks';

import './Buttons.scss';
import { AssignTo } from './AssignTo';

export const Buttons = withRouter(function (props) {
	const { isExternal } = useCurrentUser();
	const { referralDashboardData } = useContext(ReferralPageContext);
	const { filters, order, paging } = referralDashboardData;

	return (
		<div className='container'>
			<div className='buttons'>
				<RBAC
					action={CARE_PATIENTS_CREATE}
					yes={
						<Button
							onClick={() =>
								props.history.push('/app/patientmanagement')
							}
						>
							Create Patient
						</Button>
					}
				/>
				{!isExternal && <AssignTo />}
				{!isExternal && (
					<span
						className={classnames('export-button', 'purpleText', {
							disabled: !paging?.total,
						})}
						onClick={async () => {
							if (!paging?.total) {
								return;
							}

							await ReferralDashboard.exportReferralData({
								...filters,
								...order,
							});
							enqueueSnackbar('Exported', { variant: 'success' });
						}}
					>
						Export {paging && `(${paging?.total})`}
					</span>
				)}
			</div>
		</div>
	);
});
