//React & Redux
import React, { Fragment, Component } from 'react';

//Lodash
import _ from 'lodash';

//UI Libraries
import { Layout } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Utils
import {
	getProviderName,
	getProviderIcon,
	GetSelectvalue,
} from '../Encounters/Common/utilsfunctions';

//Components
import ICDTag from '../../ChargeInformation/ICDTag';

//Styles
import './create-test-request-modal.css';

class TestRequestInfoTab extends Component {
	constructor(props) {
		super(props);
		this.outreachTimeTimer = null;
		this.state = {
			outreachTimeToStart: '',
		};
	}

	componentDidMount() {
		this.outreachTimeInterval();
	}

	componentWillUnmount() {
		clearInterval(this.outreachTimeTimer);
	}

	renderICDCodes = () => {
		let medicalCodes = _.get(this, 'props.selectedICDCodes', []) || [];
		medicalCodes = medicalCodes.slice().sort((a, b) => {
			if (_.get(a, 'code_name', '') < _.get(b, 'code_name', ''))
				return -1;
			else return 1;
		});
		if (medicalCodes.length > 0) {
			return medicalCodes.map((el, i) => {
				return (
					<React.Fragment>
						<ICDTag
							icdCode={el}
							toggleCodeSelection={() => {}}
							hasX={false}
							key={el.code_name}
						/>
					</React.Fragment>
				);
			});
		}
		return null;
	};

	showTestDispatchExpectedDate = () => {
		return (
			_.get(this, ['props', 'formdata', 'order_status'], '') in
			{
				results_ready: 1,
				result_positive_no_genes: 1,
				result_positive_with_genes: 1,
				result_negative: 1,
			}
		);
	};

	getOutreachTime = () => {
		let outreachToStartMin = 0;
		let outreachToStartSec = 0;
		const testOutreachStatus = _.get(
			this,
			['props', 'testOutreachStatus'],
			''
		);

		if (
			moment(
				_.get(this, ['props', 'outreachTimeToStart'], null)
			).isValid() &&
			testOutreachStatus == 'waiting_to_start'
		) {
			const outreachTimeToStart = moment.utc(
				_.get(this, ['props', 'outreachTimeToStart'], null)
			);
			const currentutc = moment.utc();

			outreachToStartSec = outreachTimeToStart.diff(
				currentutc,
				'seconds'
			);
			outreachToStartMin = Math.ceil(outreachToStartSec / 60);

			if (currentutc.isSameOrAfter(outreachTimeToStart)) {
				clearInterval(this.outreachTimeTimer);
				this.setState({ outreachTimeToStart: 1 });
			} else {
				this.setState({ outreachTimeToStart: outreachToStartMin });
			}
		} else {
			clearInterval(this.outreachTimeTimer);
		}
	};

	outreachTimeInterval = () => {
		this.getOutreachTime();
		this.outreachTimeTimer = setInterval(() => {
			this.getOutreachTime();
		}, 15 * 1000);
	};

	getOutreachStatus = () => {
		const testOutreachStatus = _.get(
			this,
			['props', 'testOutreachStatus'],
			''
		);
		const outreachDisplayName = GetSelectvalue(
			this,
			['props', 'testOutreachStatus'],
			_.get(this, ['props', 'outreachStatusEnums'], [])
		);
		const holdResultRelease = _.get(
			this,
			['props', 'holdResultRelease'],
			null
		);
		let outreachStatusText = '—';

		switch (testOutreachStatus) {
			case 'not_started':
				if (holdResultRelease == true) {
					outreachStatusText = 'On Hold';
				}
				break;
			case 'waiting_to_start':
				outreachStatusText = `Starts in ${this.state.outreachTimeToStart}m`;
				break;
			case 'outreach_complete':
				outreachStatusText = 'Completed';
				break;
			default:
				outreachStatusText = outreachDisplayName;
		}

		return outreachStatusText;
	};

	displayTestName = () => {
		const _groupTestorders = _.get(this, 'props.groupTestorders', []);

		if (_groupTestorders.length > 1) {
			return _.map(
				_.get(this, 'props.groupTestorders', []),
				(groupOrder) => {
					return (
						<div>
							{_.get(
								groupOrder,
								['genetic_test_display_name'],
								''
							)}
						</div>
					);
				}
			);
		} else {
			return !_.isEmpty(_.get(this, 'props.testorder.test_name', ''))
				? _.get(this, 'props.testorder.test_name', '')
				: '--';
		}
	};

	render() {
		return (
			<Fragment>
				<section className='test-rqst-info-tab-container'>
					<Layout.Row>
						<Layout.Col span='24'>Lab</Layout.Col>
					</Layout.Row>
					<Layout.Row>
						<Layout.Col span='24' className='field-value'>
							{!_.isEmpty(_.get(this, 'props.testorder.lab', ''))
								? _.get(this, 'props.testorder.lab', '')
								: '--'}
							{_.get(
								this,
								'props.testorder.lab_paper_form_only',
								false
							) == true
								? ' (Paper TRF)'
								: ''}
						</Layout.Col>
					</Layout.Row>

					<Layout.Row>
						<Layout.Col span='24'>
							Test Name
							{_.get(this, 'props.groupTestorders', []).length > 1
								? '(s)'
								: ''}
						</Layout.Col>
					</Layout.Row>
					<Layout.Row>
						<Layout.Col span='24' className='field-value'>
							{this.displayTestName()}
						</Layout.Col>
					</Layout.Row>

					<Layout.Row>
						<Layout.Col span='24'>Ordering Physician</Layout.Col>
					</Layout.Row>
					<Layout.Row>
						<Layout.Col span='24' className='field-value'>
							{_.get(
								this,
								['props', 'testorder', 'ordering_physician'],
								false
							) ? (
								<div className='enctr-sumry-prvdr-name'>
									{getProviderIcon(this.props, [
										'testorder',
										'ordering_physician',
									]) != null && (
										<div className='patient-imgbox'>
											<img
												src={getProviderIcon(
													this.props,
													[
														'testorder',
														'ordering_physician',
													]
												)}
												alt=''
											/>
										</div>
									)}
									<div className='provider-name'>
										{getProviderName(
											this,
											[
												'props',
												'testorder',
												'ordering_physician',
											],
											_.get(
												this,
												[
													'props',
													'ordering_physicianenums',
												],
												[]
											)
										)}
									</div>
								</div>
							) : (
								'--'
							)}
						</Layout.Col>
					</Layout.Row>

					<Fragment>
						<Layout.Row>
							<Layout.Col span='24'>
								Sample Type (Preferred)
							</Layout.Col>
						</Layout.Row>
						<Layout.Row>
							<Layout.Col
								span='24'
								className='padding-bottom-22 field-value'
							>
								{GetSelectvalue(
									this,
									['props', 'testorder', 'sample_type'],
									_.get(
										this,
										['props', 'sampletypeenums'],
										[]
									)
								)}
							</Layout.Col>
						</Layout.Row>
					</Fragment>

					<Layout.Row>
						<Layout.Col span='24'>
							Test Authorization Date
						</Layout.Col>
						<Layout.Col
							span='24'
							className='padding-bottom-22 field-value'
						>
							{!_.isEmpty(
								_.get(
									this,
									'props.testorder.date_test_authorized',
									''
								)
							)
								? moment(
										_.get(
											this,
											'props.testorder.date_test_authorized',
											''
										)
								  ).format('MMM DD, YYYY')
								: '--'}
						</Layout.Col>
					</Layout.Row>

					<Layout.Row>
						<Layout.Col span='24' className='test-request-code'>
							ICD-10 Code(s)
						</Layout.Col>
						<Layout.Col span='24' className='padding-bottom-22'>
							<div className='icd-10-containers'>
								{this.renderICDCodes()}
							</div>
						</Layout.Col>
					</Layout.Row>
					{this.showTestDispatchExpectedDate() && (
						<Fragment>
							<Layout.Row>
								<Layout.Col span='24'>
									Test Dispatch Date
								</Layout.Col>
								<Layout.Col
									span='24'
									className='padding-bottom-22 field-value'
								>
									{!_.isEmpty(
										_.get(
											this,
											'props.testorder.date_test_ordered',
											''
										)
									)
										? moment(
												_.get(
													this,
													'props.testorder.date_test_ordered',
													''
												)
										  ).format('MMM DD, YYYY')
										: '--'}
								</Layout.Col>
							</Layout.Row>

							<Layout.Row>
								<Layout.Col span='24'>
									Expected Results Date
								</Layout.Col>
								<Layout.Col span='24' className='field-value'>
									{!_.isEmpty(
										_.get(
											this,
											'props.formdata.expected_lab_results_date',
											''
										)
									)
										? moment(
												_.get(
													this,
													'props.formdata.expected_lab_results_date',
													''
												)
										  ).format('MMM DD, YYYY')
										: '--'}
								</Layout.Col>
							</Layout.Row>

							<Layout.Row>
								<Layout.Col span='24'>
									RoR Outreach Status
								</Layout.Col>
								<Layout.Col span='24' className='field-value'>
									{this.getOutreachStatus()}
								</Layout.Col>
							</Layout.Row>
						</Fragment>
					)}
				</section>
			</Fragment>
		);
	}
}

export default TestRequestInfoTab;
