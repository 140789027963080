import React from 'react';
import AuditTrailBody from './AuditTrailBody';
import AuditTrailTitleRow from './AuditTrailTitleRow';

const AuditTrail = ({ smsConsentLog, handleAuditTrail, patientName, recontactConsentLog }) => {
	return (
		<div className='fullModalBackground patientAuditTrail'>
			<div className='auditTrailContainer'>
				<AuditTrailTitleRow handleAuditTrail={handleAuditTrail} />
				<AuditTrailBody
					smsConsentLog={smsConsentLog}
					recontactConsentLog = {recontactConsentLog}
					patientName={patientName}
				/>
			</div>
		</div>
	);
};

export default AuditTrail;
