//React & Redux
import React, { useEffect, useState } from 'react';

//Components
import AuditTrailTitleRow from './AuditTrailTitleRow.js';
import AuditTrailBody from './AuditTrailBody.js';

//Styles
import './styles.css';

const AuditTrail = (props) => {
	const {
		auditTrailBodyProps,
		auditTrailBodyProps: { getAllUsers },
		auditTrailEncounterProps,
		auditTrailEncounterProps: {
			auditTrailEncounterError,
			auditTrailEncounterLoading,
		},
		billingEncounterUUID,
		closeAuditTrail,
		enqueueSnackbar,
		encounter,
		encounterAuditBillingProps,
		encounterUuid,
		encounterValueEnums,
		getEncounterAuditTrail: getEncounterAuditTrailFromProps,
		patient,
	} = props;

	const {
		ccIntakeEncounterProps,
		groupSessionEncounterProps,
		labEncounterProps,
		researchEncounterProps,
		visitEncounterProps,
	} = encounterValueEnums;

	const [currentField, setCurrentField] = useState(null);
	const [encounterField, setEncounterField] = useState(null);
	const [hadSuccessfulCall, setHadSuccessfulCall] = useState(false);
	const [includeNewValue] = useState(true);
	const [includeOldValue] = useState(true);
	const [limit] = useState(-1);
	const [offset] = useState(0);

	useEffect(() => {
		if (auditTrailEncounterLoading === false) {
			if (auditTrailEncounterError) {
				enqueueSnackbar('Error fetching audit trail', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			} else {
				if (!hadSuccessfulCall) setHadSuccessfulCall(true);
			}
		}
	}, [auditTrailEncounterLoading]);

	useEffect(() => {
		if (currentField == 'visit_status') {
			setHadSuccessfulCall(true);
			getAllUsers({ limit: -1, offset: 0 });
		} else {
			getEncounterAuditTrail();
		}
	}, [currentField]);

	const getEncounterAuditTrail = () => {
		const uuid =
			encounterField === 'encounterAuditEnum'
				? encounterUuid
				: billingEncounterUUID;

		if (uuid && currentField) {
			const payload = {
				fieldname: [currentField],
				includeNewValue,
				includeOldValue,
				limit,
				offset,
			};

			getEncounterAuditTrailFromProps(uuid, payload);
		}
	};

	const changeEncounterField = (encounterField) => {
		if (encounterField) {
			setEncounterField(encounterField);
			setCurrentField(null);
			setHadSuccessfulCall(false);
		}
	};

	const changeCurrentField = (value) => {
		if (value) {
			setCurrentField(value);
			setHadSuccessfulCall(false);
		}
	};

	const getRelevantEncounterEnum = () => {
		switch (encounter.type || '') {
			case 'visit':
			case 'scp':
				return visitEncounterProps;
			case 'group-session':
				return groupSessionEncounterProps;
			case 'cc-intake':
				return ccIntakeEncounterProps;
			case 'research-data':
				return researchEncounterProps;
			case 'lab_test_authorization':
				return labEncounterProps;
			default:
				return {};
		}
	};

	return (
		<div className='fullModalBackground'>
			<div className='auditTrailContainer'>
				<AuditTrailTitleRow {...{ closeAuditTrail }} />
				<AuditTrailBody
					{...{
						...auditTrailBodyProps,
						...auditTrailEncounterProps,
						changeCurrentField,
						changeEncounterField,
						currentField,
						encounter,
						...encounterAuditBillingProps,
						encounterField,
						hadSuccessfulCall,
						patient,
						relevantEncounterEnum: getRelevantEncounterEnum(),
					}}
				/>
			</div>
		</div>
	);
};

export default AuditTrail;
