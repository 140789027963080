import _ from 'lodash';
import get from 'lodash/get';

import moment from 'moment';
import { PatientConsentStatus } from '../../utils';

export const deHydratePatientInsurance = (data) => {
	return {
		insurance:
			data &&
			data.map((d) => {
				if (d.insurance_type === 'secondary') {
					let secondarydata = deHydratePatient(d);
					if (secondarydata.insurance_company_id === 0) {
						secondarydata = _.omit(secondarydata, [
							'insurance_company_id',
						]);
					}
					return secondarydata;
				} else {
					return deHydratePatient(d);
				}
			}),
	};
};

export const deHydratePatient = (data) => {
	return {
		...data,
		insurance_company_id: parseInt(data.insurance_company_id) || 0,
		company_name: data.company_name || '',
		group_no: data.group_number,
		policyholder_dob: data.policyholder_dob
			? moment(data.policyholder_dob).format('YYYY-MM-DD')
			: '',
		policyholder_name: data.policyholder_name || '',
		policyholder_relationship: data.policyholder_relationship || '',
	};
};

export const hydratePatients = (data) => {
	return _.isArray(data) && data.map((d) => hydratePatient(d));
};

function getConsentHistory(consent) {
	let consentHistory = [];
	const consentVersions = consent.consent_versions;
	consentVersions.map((version) => {
		if (
			version.patient_consent &&
			version.patient_consent.consent_history
		) {
			version.patient_consent.consent_history.map((history) => {
				consentHistory.push({
					...history,
					consent_version: version.consent_version,
				});
			});
		}
	});
	return consentHistory;
}

function getLatestPatientToReconatctStatusObject(consent) {
	let result = null;
	if (!consent) {
		return null;
	}

	const findStatus = (status) =>
		consent.consent_versions.find(
			(con) => con.patient_consent && con.patient_consent.status == status
		);

	if (findStatus(PatientConsentStatus.REVOKED)) {
		result = findStatus(PatientConsentStatus.REVOKED);
	} else if (findStatus(PatientConsentStatus.CONSENTED)) {
		result = findStatus(PatientConsentStatus.CONSENTED);
	} else if (findStatus(PatientConsentStatus.PROCESSING)) {
		result = findStatus(PatientConsentStatus.PROCESSING);
	} else if (findStatus(PatientConsentStatus.SKIPPED)) {
		result = findStatus(PatientConsentStatus.SKIPPED);
	}
	return result;
}

export const hydratePatient = (data) => {
	let patientAuthorizationToRecontact = _.find(data.consents, function (o) {
		return o.type == 'patient_authorization_to_recontact';
	});

	return {
		...data,
		group_number: data.insurance_group_number,
		is_policyholder:
			data.is_policyholder == null ? true : data.is_policyholder,
		latestPatientToRecontactStatusObject:
			patientAuthorizationToRecontact &&
			getLatestPatientToReconatctStatusObject(
				patientAuthorizationToRecontact
			),
		consentHistory:
			patientAuthorizationToRecontact &&
			getConsentHistory(patientAuthorizationToRecontact),
	};
};

export const hydratePatientsValues = (data) => {
	if (Array.isArray(data))
		return data.map((patient) => hydratePatientValues(patient));
	else if (_.isObject(data)) {
		if (Array.isArray(get(data, 'data', null))) {
			data.data = data.data.map((patient) =>
				hydratePatientValues(patient)
			);
			return data;
		}
		return hydratePatientValues(data);
	} else return data;
};

function convertTags(data) {
	return data.map((item) => ({
		name: item.name,
		uuid: item.uuid,
		tagId: item.tag_id,
		displayName: item.display_name,
		tagProperties: item.tag_properties,
	}));
}

export const hydratePatientValues = (patient) => {
	patient.user_tags = patient?.user_tags
		? convertTags(patient.user_tags)
		: [];

	if (patient && _.isObject(patient) && !get(patient, 'phone', null)) {
		const patientPhone = getPatientPhoneValue(patient);
		if (patientPhone) patient.phone = patientPhone;
		return patient;
	} else return patient;
};

export const getPatientPhoneValue = (patient) => {
	if (patient && _.isObject(patient)) {
		const patientPhone =
			get(patient, 'guardian.phones.primary.value', null) ||
			get(patient, 'guardian.phones.secondary.value', null) ||
			get(patient, 'phones.primary.value', null) ||
			get(patient, 'phones.secondary.value', null);
		return patientPhone;
	} else return patient;
};

export const hydratePatientEditError = (error) => {
	const errorMessages = {};
	if (error.fields) {
		for (const key in error.fields) {
			const newKey = servicesToEMRBE[key] ? servicesToEMRBE[key] : key;
			if (newKey == 'users_guardian') {
				try {
					const guardianErrors = _.get(
						error,
						['fields', 'users_guardian'],
						{}
					);
					if (_.get(guardianErrors, 'dob')) {
						errorMessages['guardianDOB'] = Object.values(
							guardianErrors.dob
						);
					}
					if (_.get(guardianErrors, 'first_name')) {
						errorMessages['guardianFirstName'] = Object.values(
							guardianErrors.first_name
						);
					}
					if (_.get(guardianErrors, 'last_name')) {
						errorMessages['guardianLastName'] = Object.values(
							guardianErrors.last_name
						);
					}
					if (_.get(guardianErrors, 'phone')) {
						errorMessages['guardianPrimaryPhone'] = Object.values(
							guardianErrors.phone
						);
					}
					if (_.get(guardianErrors, 'relationship')) {
						errorMessages['guardianRelationship'] = Object.values(
							guardianErrors.relationship
						);
					}
				} catch (e) {
					continue;
				}
			} else {
				try {
					// replacing get because it doesn't work well with error objects
					const newDescriptionArr = Object.values(error.fields[key]);
					const newDescription = get(newDescriptionArr, '[0]', ''); //rendering one error message for now
					if (newDescription) errorMessages[newKey] = newDescription;
				} catch (e) {
					continue;
				}
			}
		}
	}
	return {
		...error,
		errorMessages,
	};
};

export const hydrateEligibilityFields = (data, editBool) => {
	if (!Array.isArray(data) || get(data, 'length', 0) <= 0) return [];
	if (editBool) {
		return data.filter((el) => {
			return !(get(el, 'name', '') || '').match(/referralcode/i);
		});
	} else {
		return data;
	}
};

const servicesToEMRBE = {
	referral_reasons: 'referral_reason_ids',
	referral_provider: 'referral_program',
	relationship: 'employee_relationship',
};

export const hydrateProviders = (data) => {
	return _.isArray(data) && data.map((d) => hydrateProvider(d));
};

export const hydrateProvider = (data) => {
	return {
		savedRecord: true,
		linkedToPatient: true,
		patientUUID: data.patient_uuid,
		providerUUID: data.provider_uuid,
		title: data.title,
		providerFullName: `${data.provider_first_name} ${data.provider_last_name}`,
		providerRole: data.provider_role || [],
		receiveAllComms: data.receive_all_communications || false,
		city: _.get(data, ['address', 'office', 'city'], ''),
		state: _.get(data, ['address', 'office', 'state'], ''),
		addressLine1: _.get(data, ['address', 'office', 'address_line1'], ''),
		specialities: _.get(data, ['specialties'], []),
		startDate: _.get(data, 'start_date', null),
		endDate: _.get(data, 'end_date', null),
		isArchived: _.get(data, 'is_archived', false),
		phones: data.phones,
		email: data.email,
		defaultDocumentReleaseEnabled: data.default_document_release_enabled,
	};
};

export const orderTags = (tags) => {
	const hiddenForExternalUsers = new Set(['patient_story']);
	if (Array.isArray(tags)) {
		return [
			'priority_patient',
			'language_line_support',
			'speech_impaired',
			'hearing_impaired',
			'vision_impaired',
			'financial_assistance',
			'financial_assistance_per_visit',
			'deceased',
			'discharged',
			'marketing_opt-out',
			'research_opt-out',
			'no_further_escalation',
			'patient_story',
		]
			.map((tag) => _.find(tags, (el) => _.get(el, 'name', null) === tag))
			.filter((el) => el)
			.map((el) => {
				if (hiddenForExternalUsers.has(_.get(el, 'name', null)))
					el.hiddenForExternalUsers = true;
				return el;
			});
	} else return tags;
};

export const orderRaces = (races) => {
	if (Array.isArray(races)) {
		const endElementDisplayNames = new Set([
			'Other',
			'Mixed Race',
			'Prefer not to say',
		]);
		const endUnsortedRaces = [];
		const beginningSortedRaces = [];
		races.forEach((race) => {
			if (endElementDisplayNames.has(get(race, 'display_name', null)))
				endUnsortedRaces.push(race);
			else beginningSortedRaces.push(race);
		});
		beginningSortedRaces.sort((a, b) =>
			get(a, 'display_name', null) > get(b, 'display_name', null) ? 1 : -1
		);
		endUnsortedRaces.sort((a, b) =>
			get(a, 'display_name', null) > get(b, 'display_name', null) ? 1 : -1
		);
		const sortedRaces = beginningSortedRaces.concat(endUnsortedRaces);
		sortedRaces.forEach((race) => {
			const secondaryRaces = get(race, 'secondary_races', null);
			if (
				Array.isArray(secondaryRaces) &&
				get(secondaryRaces, 'length', 0) > 0
			) {
				race.secondary_races = orderRaces(race.secondary_races);
			}
		});
		return sortedRaces;
	} else return races;
};
