//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import doNotOutreachIcon from '../../../assets/do-not-outreach.svg';

//Other Libraries
import moment from 'moment';

//Styles
import './NoOutreach.css';

const NoOutreach = (props) => {
	const { outreach } = props;

	const {
		contactDateTime = null,
		createdByFirstName = '',
		createdByLastName = '',
		isDoNotOutreach = false,
		isSystem = null,
		notes,
		outreachSequenceNo,
		subtype = '',
	} = outreach;

	const displayDateTimeField = (value) =>
		moment(value).isValid()
			? moment.tz(value, 'UTC').local().format('MM/DD/YYYY hh:mm a')
			: value;

	const displaySentByField = () =>
		'by ' +
		(isSystem ? 'System' : createdByFirstName + ' ' + createdByLastName);

	const displayOutcomeField = () => notes || '--';

	const displaySubjectField = () => {
		if (isDoNotOutreach) {
			return `Do Not Outreach`;
		} else if (subtype.includes('ROR Outreach Stopped')) {
			return `Round ${outreachSequenceNo} - Outreach Stopped`;
		} else if (subtype.includes('ROR Patient Declined')) {
			return `Round ${outreachSequenceNo} - Patient Declined Follow Up`;
		}
		return '';
	};

	return (
		<div className='no-outreach-wrapper'>
			<div className='donot-outrch-icon'>
				<ReactSVG src={doNotOutreachIcon} />
			</div>
			<div className='nooutrch-content'>
				<div className='inner-nooutrch-content'>
					<span className='nooutreach-display-name'>
						{displaySubjectField()}
					</span>
					<span className='nooutreach-date'>
						{displayDateTimeField(contactDateTime)}
					</span>
				</div>
				<div className='inner-nooutrch-content'>
					<span className='nooutrch-outcome'>
						{displayOutcomeField()}
					</span>
					<span className='nooutrch-created-by'>
						{displaySentByField()}
					</span>
				</div>
			</div>
		</div>
	);
};

export default NoOutreach;
