import classnames from 'classnames';
import React from 'react';

import { FormField, IFormFieldProps } from './FormField';
import { IProps as IRadioGroupProps, RadioGroup } from './RadioGroup';

import './RadioGroupField.scss';

interface IProps extends IFormFieldProps, IRadioGroupProps {}

export const RadioGroupField: React.FC<IProps> = (props) => {
	const {
		className,
		name,
		label,
		required,
		error,
		value,
		options,
		onChange,
	} = props;
	return (
		<FormField
			className={classnames('gm-radio-group', className)}
			error={error}
			label={label}
			name={name}
			required={required}
		>
			<RadioGroup onChange={onChange} options={options} value={value} />
		</FormField>
	);
};
