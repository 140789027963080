//React & Redux
import React, { Fragment, useState, useEffect, useRef } from 'react';

//Other Libraries
import {
	isEmpty,
	debounce,
	isNil,
	get,
	isNaN,
	toNumber,
	omitBy,
	groupBy,
	size,
	flatten,
	uniq,
	isInteger,
} from 'lodash';
import {
	Dialog,
	Button,
	Layout,
	Select,
	Input,
	Loading,
	Popover,
} from 'gm-element-react';

//Images
import ReactSVG from 'react-svg';
import trashIcon from '../../../../assets/delete.svg';
import imageExpandCollapse from '../../../../assets/icons/black_chevron_arrow.svg';
import warningicon from '../../../../assets/warning.svg';
import infoIcon from '../../../../assets/icons/info.svg';
import errorIcon from '../../../Appointment/images/error.svg';
import moreinfoicon from '../../../../assets/purple-more-info.svg';
//Styles
import './css/add-edit-structural-variant-modal.css';

//Other Libraries
import classnames from 'classnames';

//Hooks
import { usePrevious } from '../../../../hooks/usePrevious';

//Components
import Icon from '../../../Common/Icon';
import RadioGroup from '../../../Common/controls/RadioGroup/RadioGroup';

//Utils
import {
	structuralNomenclatureRadioEnum,
	structuralVariantTypeEnum,
	labClassificationEnum,
	structuralVariantUnitEnum,
	genomicSourceClassEnum,
	originEnum,
	chromosomeEnum,
	mosaicismEnum,
} from '../../../../utils';

const fieldHightlightValidationMapping = {
	variant_type: 'variantType',
	chromosomal_extents: 'chromosome',
	cytogenetic_location: 'cytogeneticLocation',
	genomicSourceClass: 'genomicSourceClass',
	origin: 'origin',
	mosaicism: 'mosaicism',
};

const AddEditStructuralVariantModal = (props) => {
	const {
		hideAddEditStructuralVariantModalFunc,
		modalMode = 'add',
		getReferenceAssembliesEnum,
		referenceAssembliesEnum,
		saveStructuralVariantFinding,
		getGeneticResultGenesSymbol,
		structuralVariantFindings = {},
		geneticResultGenesSymbolEnum = [],
		resetGetGeneticResultGenesSymbol,
		labUUID = null,
		deleteStructuralVariantFinding,
		getStructuralFindingValidation,
		structuralFindingValidationText,
		structuralFindingValidationLoading,
		structuralFindingValidationError,
		resetGetStructuralFindingValidation,
	} = props;

	const [formdata, setFormdata] = useState({
		geneId: null,
		variantType: null,
		referenceAssembly: 'grch37',
		referenceSequence: null,
		sequence: null,
		labClassification: null,
		genomicSourceClass: null,
		origin: null,
		characterization: null,
		startExon: null,
		endExon: null,
		sizeValue: null,
		unit: null,
		copyNumber: null,
		chromosome: null,
		cytogeneticLocation: null,
		startPosition: null,
		endPosition: null,
		entireCodingRegion: false,
		mosaicism: null,
	});
	const [nomenclature, setNomenclature] = useState('geneticExtents');
	const [genesSearchLoading, setGenesSearchLoading] = useState(false);
	const [errors, setErrors] = useState({
		errors: {},
	});
	const [isDisableSaveButton, setIsDisableSaveButton] = useState(true);
	const [geneSymbolLabel, setGeneSymbolLabel] = useState('');
	const [loading, setLoading] = useState(structuralFindingValidationLoading);
	const [isDisableChromosomeField, setIsDisableChromosomeField] =
		useState(false);
	const [fieldHighlightForError, setFieldHighlightForError] = useState([]);
	const [showErrorMessageSection, setShowErrorMessageSection] =
		useState(false);

	const prevStructuralFindingValidationText = usePrevious(
		structuralFindingValidationText
	);
	const prevStructuralFindingValidationLoading = usePrevious(
		structuralFindingValidationLoading
	);

	const validationMessageContainerRef = useRef();

	useEffect(() => {
		getReferenceAssembliesEnum();

		if (
			isEditMode() &&
			!isNil(
				structuralVariantFindings?.geneticExtents?.[0]?.gene?.symbol
			) &&
			!isEmpty(
				structuralVariantFindings?.geneticExtents?.[0]?.gene?.symbol
			)
		) {
			const data = {
				query: structuralVariantFindings?.geneticExtents?.[0]?.gene
					?.symbol,
				referenceAssembly:
					structuralVariantFindings?.referenceAssembly?.code,
				labId: labUUID,
				variantType: 'structuralVariant',
			};
			getGeneticResultGenesSymbol(data);
			setGeneSymbolLabel(
				structuralVariantFindings?.geneticExtents?.[0]?.gene?.symbol
			);
		}

		if (
			!isEmpty(structuralVariantFindings) &&
			!isNil(structuralVariantFindings) &&
			isEditMode()
		) {
			setFormdata({
				variantType: structuralVariantFindings?.variantType || null,
				allelicState: structuralVariantFindings?.allelicState || null,
				referenceAssembly:
					structuralVariantFindings?.referenceAssembly?.code ||
					'none',
				geneId:
					structuralVariantFindings?.geneticExtents?.[0]?.gene?.id ||
					null,
				startExon:
					structuralVariantFindings?.geneticExtents?.[0]?.startExon ||
					null,
				endExon:
					structuralVariantFindings?.geneticExtents?.[0]?.endExon ||
					null,
				entireCodingRegion:
					structuralVariantFindings?.geneticExtents?.[0]
						?.entireCodingRegion || false,
				chromosome:
					structuralVariantFindings?.chromosomalExtents?.[0]
						?.chromosome || null,
				cytogeneticLocation:
					structuralVariantFindings?.chromosomalExtents?.[0]
						?.cytogeneticLocation || null,
				startPosition:
					structuralVariantFindings?.chromosomalExtents?.[0]
						?.startPosition || null,
				endPosition:
					structuralVariantFindings?.chromosomalExtents?.[0]
						?.endPosition || null,
				sizeValue: structuralVariantFindings?.sizeValue || null,
				unit: structuralVariantFindings?.unit || null,
				copyNumber: structuralVariantFindings?.copyNumber ?? null,
				referenceSequence:
					structuralVariantFindings?.referenceSequence || null,
				sequence: structuralVariantFindings?.sequence || null,
				characterization:
					structuralVariantFindings?.characterization || null,
				labClassification:
					structuralVariantFindings?.labClassification || null,
				genomicSourceClass:
					structuralVariantFindings?.genomicSourceClass || null,
				origin: structuralVariantFindings?.origin || null,
				mosaicism: structuralVariantFindings?.mosaicism || null,
			});
			setNomenclature(
				structuralVariantFindings?.hasGeneticExtents || false
					? 'geneticExtents'
					: structuralVariantFindings?.hasChromosomalExtents || false
					? 'chromosomalExtents'
					: 'ISCN'
			);
		}

		return () => {
			resetGetGeneticResultGenesSymbol();
			resetGetStructuralFindingValidation();
		};
	}, []);

	useEffect(() => {
		if (!isNil(formdata['geneId'])) {
			const data = {
				query: geneSymbolLabel,
				referenceAssembly: formdata?.referenceAssembly,
				labId: labUUID,
				variantType: 'structuralVariant',
			};
			getGeneticResultGenesSymbol(data);
		}
	}, [formdata?.referenceAssembly]);

	useEffect(() => {
		if (
			structuralFindingValidationLoading !=
			prevStructuralFindingValidationLoading
		) {
			setLoading(structuralFindingValidationLoading);
		}
	}, [structuralFindingValidationLoading]);

	useEffect(() => {
		if (
			structuralFindingValidationText !=
				prevStructuralFindingValidationText &&
			get(structuralFindingValidationText, ['isValid'], false) == true
		) {
			setIsDisableSaveButton(false);
		}
		if (
			structuralFindingValidationText !=
				prevStructuralFindingValidationText &&
			!isEmpty(get(structuralFindingValidationText, ['issues'], []))
		) {
			const issues = get(structuralFindingValidationText, ['issues'], []);
			let paths = issues.map((item) => {
				if (size(get(item, ['paths', '0'], [])) > 0) {
					return get(item, ['paths', '0'], []);
				}
			});
			paths = uniq(flatten(paths));
			const fieldNameArray = paths.map((p) => {
				return fieldHightlightValidationMapping[p];
			});

			setShowErrorMessageSection(true);
			setFieldHighlightForError(fieldNameArray);

			setTimeout(() => {
				validationMessageContainerRef.current.scrollIntoView(true);
			}, 80);
		} else {
			setFieldHighlightForError([]);
		}
	}, [structuralFindingValidationText]);

	const isEditMode = () => {
		return modalMode == 'edit';
	};

	const getQueryGeneticResultGene = debounce((query) => {
		const data = {
			query: query,
			referenceAssembly: formdata?.referenceAssembly || 'grch37',
			labId: labUUID,
			variantType: 'structuralVariant',
		};
		getGeneticResultGenesSymbol(data);
		setGeneSymbolLabel(query);
	}, 500);

	const closeAddEditStructuralVariantModal = () => {
		hideAddEditStructuralVariantModalFunc();
	};

	const validateForm = (formdata) => {
		const errors = {};

		if (
			nomenclature === 'geneticExtents' &&
			(isNil(formdata['geneId']) || formdata['geneId'] === '')
		) {
			errors['geneId'] = 'Please select gene symbol';
		}

		if (
			isNil(formdata['variantType']) ||
			isEmpty(formdata['variantType'])
		) {
			errors['variantType'] = 'Variant Type is required';
		}

		if (
			nomenclature === 'chromosomalExtents' &&
			(isNil(formdata['chromosome']) || isEmpty(formdata['chromosome']))
		) {
			errors['chromosome'] = 'Chromosome is required';
		}

		if (
			nomenclature === 'ISCN' &&
			(isNil(formdata['sequence']) || isEmpty(formdata['sequence']))
		) {
			errors['sequence'] = 'Sequence is required';
		}

		if (
			!isNil(formdata['startExon']) &&
			formdata['startExon'] !== '' &&
			!isInteger(toNumber(formdata['startExon']))
		) {
			errors['startExon'] = 'Please enter a non-decimal number';
		}

		if (
			!isNil(formdata['endExon']) &&
			formdata['endExon'] !== '' &&
			!isInteger(toNumber(formdata['endExon']))
		) {
			errors['endExon'] = 'Please enter a non-decimal number';
		}

		if (
			!isNil(formdata['startExon']) &&
			!isNil(formdata['endExon']) &&
			formdata['startExon'] !== '' &&
			formdata['endExon'] !== '' &&
			isInteger(toNumber(formdata['startExon'])) &&
			isInteger(toNumber(formdata['endExon'])) &&
			toNumber(formdata.endExon) < toNumber(formdata.startExon)
		) {
			errors['endExon'] =
				'End exon must be greater then or equal to the exon Start';
		}

		if (
			!isNil(formdata['endExon']) &&
			formdata['endExon'] !== '' &&
			(formdata['startExon'] === '' || isNil(formdata['startExon'])) &&
			isInteger(toNumber(formdata['endExon']))
		) {
			errors['startExon'] =
				'Start exon must be specified when end exon is specified';
		}

		if (
			!isNil(formdata['startExon']) &&
			formdata['startExon'] !== '' &&
			(formdata['endExon'] === '' || isNil(formdata['endExon'])) &&
			isInteger(toNumber(formdata['startExon']))
		) {
			errors['endExon'] =
				'End exon must be specified when start exon is specified';
		}

		if (
			!isNil(formdata['startPosition']) &&
			formdata['startPosition'] !== '' &&
			!isInteger(toNumber(formdata['startPosition']))
		) {
			errors['startPosition'] = 'Please enter a non-decimal number';
		}

		if (
			!isNil(formdata['endPosition']) &&
			formdata['endPosition'] !== '' &&
			!isInteger(toNumber(formdata['endPosition']))
		) {
			errors['endPosition'] = 'Please enter a non-decimal number';
		}

		if (
			!isNil(formdata['startPosition']) &&
			!isNil(formdata['endPosition']) &&
			formdata['startPosition'] !== '' &&
			formdata['endPosition'] !== '' &&
			isInteger(toNumber(formdata['startPosition'])) &&
			isInteger(toNumber(formdata['endPosition'])) &&
			toNumber(formdata.endPosition) < toNumber(formdata['startPosition'])
		) {
			errors['endPosition'] =
				'End Position must be greater then or equal to the Position Start';
		}

		if (
			!isNil(formdata['endPosition']) &&
			formdata['endPosition'] !== '' &&
			(formdata['startPosition'] === '' ||
				isNil(formdata['startPosition'])) &&
			isInteger(toNumber(formdata['endPosition']))
		) {
			errors['startPosition'] =
				'Start position must be specified when end position is specified';
		}

		if (
			!isNil(formdata['startPosition']) &&
			formdata['startPosition'] !== '' &&
			(formdata['endPosition'] === '' ||
				isNil(formdata['endPosition'])) &&
			isInteger(toNumber(formdata['startPosition']))
		) {
			errors['endPosition'] =
				'End position must be specified when start position is specified';
		}

		if (
			!isNil(formdata['copyNumber']) &&
			!isEmpty(formdata['copyNumber']) &&
			!isInteger(toNumber(formdata['copyNumber']))
		) {
			errors['copyNumber'] = 'Please enter a non-decimal number';
		}

		if (
			!isNil(formdata['cytogeneticLocation']) &&
			!isEmpty(formdata['cytogeneticLocation']) &&
			!formdata['cytogeneticLocation'].match(
				/^(?<chromosome>1[0-9]|2[0-2]|[1-9]|X|Y)(?:(?<arm>[pq])(?:(?:(?<region>[1-9])(?:(?<band>[1-9])(?:\.(?<subband>\d{0,2}))?)?)?|(?<cen>cen)|(?<ter>ter)))?(?:(?<start_loc>([pq])(?:(?:([1-9])(?:([1-9])(?:\.(\d{0,2}))?)?)?|cen|ter))\-(?<end_loc>([pq])(?:(?:([1-9])(?:([1-9])(?:\.(\d{0,2}))?)?)?|cen|ter)))?$/
			)
		) {
			errors['cytogeneticLocation'] = 'Incorrect entry format';
		}

		if (
			!isNil(formdata['cytogeneticLocation']) &&
			!isEmpty(formdata['cytogeneticLocation']) &&
			formdata['cytogeneticLocation'].match(
				/^(?<chromosome>1[0-9]|2[0-2]|[1-9]|X|Y)(?:(?<arm>[pq])(?:(?:(?<region>[1-9])(?:(?<band>[1-9])(?:\.(?<subband>\d{0,2}))?)?)?|(?<cen>cen)|(?<ter>ter)))?(?:(?<start_loc>([pq])(?:(?:([1-9])(?:([1-9])(?:\.(\d{0,2}))?)?)?|cen|ter))\-(?<end_loc>([pq])(?:(?:([1-9])(?:([1-9])(?:\.(\d{0,2}))?)?)?|cen|ter)))?$/
			)
		) {
			const _startLoc = formdata['cytogeneticLocation'].match(
				/^(?<chromosome>1[0-9]|2[0-2]|[1-9]|X|Y)(?:(?<arm>[pq])(?:(?:(?<region>[1-9])(?:(?<band>[1-9])(?:\.(?<subband>\d{0,2}))?)?)?|(?<cen>cen)|(?<ter>ter)))?(?:(?<start_loc>([pq])(?:(?:([1-9])(?:([1-9])(?:\.(\d{0,2}))?)?)?|cen|ter))\-(?<end_loc>([pq])(?:(?:([1-9])(?:([1-9])(?:\.(\d{0,2}))?)?)?|cen|ter)))?$/
			).groups.start_loc;
			const _endLoc = formdata['cytogeneticLocation'].match(
				/^(?<chromosome>1[0-9]|2[0-2]|[1-9]|X|Y)(?:(?<arm>[pq])(?:(?:(?<region>[1-9])(?:(?<band>[1-9])(?:\.(?<subband>\d{0,2}))?)?)?|(?<cen>cen)|(?<ter>ter)))?(?:(?<start_loc>([pq])(?:(?:([1-9])(?:([1-9])(?:\.(\d{0,2}))?)?)?|cen|ter))\-(?<end_loc>([pq])(?:(?:([1-9])(?:([1-9])(?:\.(\d{0,2}))?)?)?|cen|ter)))?$/
			).groups.end_loc;

			if (!isNil(_startLoc) && !isNil(_endLoc) && _startLoc === _endLoc) {
				const _cytogeneticLocation = formdata[
					'cytogeneticLocation'
				].includes('-')
					? formdata['cytogeneticLocation'].split('-')[0]
					: '';
				errors[
					'cytogeneticLocation'
				] = `Cytogenetic Location should be specified as ${_cytogeneticLocation}`;
			}
		}

		if (
			!isNil(formdata['sizeValue']) &&
			!isEmpty(formdata['sizeValue']) &&
			(isNil(formdata['unit']) || formdata['unit'].trim().length === 0)
		) {
			errors['unit'] = 'Unit is required';
		}

		if (
			!isNil(formdata['unit']) &&
			!isEmpty(formdata['unit']) &&
			(isNil(formdata['sizeValue']) ||
				formdata['sizeValue'].trim().length === 0)
		) {
			errors['sizeValue'] = 'Size is required';
		}

		return errors;
	};

	const selectNomenclatureFunc = (nomenclature) => {
		setNomenclature(nomenclature);
	};

	const onFormChange = (key, value) => {
		let errors = {};
		formdata[key] = value;
		setFormdata(formdata);
		errors = validateForm(formdata);
		setErrors(errors);
		setIsDisableSaveButton(true);
		if (key == 'geneId') {
			if (!isEmpty(geneticResultGenesSymbolEnum)) {
				geneticResultGenesSymbolEnum.forEach((gene) => {
					if (gene.id && gene.id === value) {
						setFormdata({
							...formdata,
							referenceSequence:
								gene.suggestedTranscriptReferenceSequence ||
								null,
						});
					}
				});
			}
		}

		if (key == 'entireCodingRegion' && value) {
			setFormdata({
				...formdata,
				startExon: null,
				endExon: null,
			});
		}
	};

	const onVariantTypeChange = (key, value) => {
		const _structuralVariantTypeForISCN = {
			BALANCED_TRANSLOCATION: 1,
			UNBALANCED_TRANSLOCATION: 1,
			ROBERTSONIAN_TRANSLOCATION: 1,
			RING_CHROMOSOME: 1,
			MARKER_CHROMOSOME: 1,
		};

		const _structuralVariantTypeForgeneticExtents = {
			DELETION: 1,
			DUPLICATION: 1,
			TRIPLICATION: 1,
			AREA_OF_HOMOZYGOSITY: 1,
			PERICENTRIC_INVERSION: 1,
			PARACENTRIC_INVERSION: 1,
			TRISOMY: 1,
			MONOSOMY: 1,
			TRIPLOIDY: 1,
		};

		if (!isEditMode() && value in _structuralVariantTypeForISCN) {
			setNomenclature('ISCN');
		} else if (
			!isEditMode() &&
			value in _structuralVariantTypeForgeneticExtents
		) {
			setNomenclature('geneticExtents');
		}
		onFormChange(key, value);
	};

	const onExonChange = (key, val) => {
		!isNaN(toNumber(val)) && onFormChange(key, val);
	};

	const onChromosomalPositionChange = (key, val) => {
		!isNaN(toNumber(val)) && onFormChange(key, val);
	};

	const onSizeValueChange = (key, val) => {
		!isNaN(toNumber(val)) && onFormChange(key, val);
	};

	const onCopyNumberChange = (key, val) => {
		!isNaN(toNumber(val)) && onFormChange(key, val);
	};

	const onCytogeneticLocationChange = (key, val) => {
		const cytogeneticLocationRegEx =
			/^(?<chromosome>1[0-9]|2[0-2]|[1-9]|X|Y)(?:(?<arm>[pq])(?:(?:(?<region>[1-9])(?:(?<band>[1-9])(?:\.(?<subband>\d{0,2}))?)?)?|(?<cen>cen)|(?<ter>ter)))?(?:(?<start_loc>([pq])(?:(?:([1-9])(?:([1-9])(?:\.(\d{0,2}))?)?)?|cen|ter))\-(?<end_loc>([pq])(?:(?:([1-9])(?:([1-9])(?:\.(\d{0,2}))?)?)?|cen|ter)))?$/;

		if (
			!isNil(val) &&
			val.trim().length !== 0 &&
			val.match(cytogeneticLocationRegEx)
		) {
			const chromosomeValue = val.match(cytogeneticLocationRegEx).groups
				.chromosome;
			onFormChange('chromosome', chromosomeValue || null);
			setIsDisableChromosomeField(true);
		} else {
			onFormChange('chromosome', null);
			setIsDisableChromosomeField(false);
		}
		onFormChange(key, val);
	};

	const onGenesSymbolSearch = (query) => {
		if (query !== '') {
			setGenesSearchLoading(false);
			if (query.length > 1) getQueryGeneticResultGene(query);
		} else {
			formdata['geneId'] = null;
			setFormdata(formdata);
		}
	};

	const isDisableSavedButtonFunc = () => {
		const errors = validateForm(formdata);
		if (
			!isNil(structuralFindingValidationText) &&
			!isEmpty(structuralFindingValidationText) &&
			get(structuralFindingValidationText, ['isValid'], false) == true &&
			isEmpty(errors) &&
			!isDisableSaveButton &&
			!loading
		) {
			return false;
		} else {
			return true;
		}
	};

	const entireCodingRegionEnum = [
		{ key: true, display_name: 'Yes' },
		{ key: false, display_name: 'No' },
	];

	const isDisableCheckEntriesButton = () => {
		const errors = validateForm(formdata);
		const _structuralVariantFindings = structuralVariantFindings;
		const _changeFields = omitBy(formdata, (value, key) => {
			if (isNil(value)) {
				return true;
			}
			if (key == 'geneId') {
				return (
					get(
						_structuralVariantFindings,
						['geneticExtents', '0', 'gene', 'id'],
						null
					) == value
				);
			}
			if (key == 'referenceAssembly') {
				return (
					get(
						_structuralVariantFindings,
						['referenceAssembly', 'code'],
						'grch37'
					) == value
				);
			}
			return _structuralVariantFindings[key] == value;
		});

		if (isEmpty(_changeFields) || !isEmpty(errors) || loading) {
			return true;
		}
		return false;
	};

	const saveTestResultData = () => {
		let _formdata = formdata;

		closeAddEditStructuralVariantModal();

		if (nomenclature === 'geneticExtents') {
			_formdata['cytogeneticLocation'] = null;
			_formdata['chromosome'] = null;
			_formdata['startPosition'] = null;
			_formdata['endPosition'] = null;
			_formdata['sequence'] = null;
			_formdata['characterization'] = null;
		}

		if (nomenclature === 'chromosomalExtents') {
			_formdata['geneId'] = null;
			_formdata['startExon'] = null;
			_formdata['endExon'] = null;
			_formdata['entireCodingRegion'] = null;
			_formdata['sequence'] = null;
			_formdata['characterization'] = null;
		}

		if (nomenclature === 'ISCN') {
			_formdata['geneId'] = null;
			_formdata['startExon'] = null;
			_formdata['endExon'] = null;
			_formdata['entireCodingRegion'] = null;
			_formdata['cytogeneticLocation'] = null;
			_formdata['chromosome'] = null;
			_formdata['startPosition'] = null;
			_formdata['endPosition'] = null;
		}

		if (get(_formdata, 'genomicSourceClass') === null) {
			_formdata['genomicSourceClass'] = 'UNKNOWN';
		}

		if (get(_formdata, 'origin') === null) {
			_formdata['origin'] = 'UNKNOWN';
		}

		if (get(_formdata, 'referenceAssembly') === 'none') {
			_formdata['referenceAssembly'] = null;
		}

		_formdata = { ..._formdata, nomenclature: nomenclature };

		saveStructuralVariantFinding(
			{
				..._formdata,
				uuid: structuralVariantFindings?.uuid || null,
			},
			isEditMode()
		);
	};

	const _deleteStructuralVariantFinding = () => {
		if (structuralVariantFindings?.uuid) {
			closeAddEditStructuralVariantModal();
			deleteStructuralVariantFinding(structuralVariantFindings?.uuid);
		}
	};

	const checkEntries = () => {
		const _formdata = formdata;
		setLoading(true);

		if (nomenclature === 'geneticExtents') {
			_formdata['cytogeneticLocation'] = null;
			_formdata['chromosome'] = null;
			_formdata['startPosition'] = null;
			_formdata['endPosition'] = null;
			_formdata['sequence'] = null;
			_formdata['characterization'] = null;
		}

		if (nomenclature === 'chromosomalExtents') {
			_formdata['geneId'] = null;
			_formdata['startExon'] = null;
			_formdata['endExon'] = null;
			_formdata['entireCodingRegion'] = null;
			_formdata['sequence'] = null;
			_formdata['characterization'] = null;
		}

		if (nomenclature === 'ISCN') {
			_formdata['geneId'] = null;
			_formdata['startExon'] = null;
			_formdata['endExon'] = null;
			_formdata['entireCodingRegion'] = null;
			_formdata['cytogeneticLocation'] = null;
			_formdata['chromosome'] = null;
			_formdata['startPosition'] = null;
			_formdata['endPosition'] = null;
		}

		getStructuralFindingValidation({
			..._formdata,
		});
	};

	const displayFindingValidationMessage = () => {
		if (!isEmpty(get(structuralFindingValidationText, ['issues'], []))) {
			const issues = get(structuralFindingValidationText, ['issues'], []);
			const groupByIssues = groupBy(issues, 'severity');
			const errorMessageArray = groupByIssues['ERROR'];
			const warningMessageArray = groupByIssues['WARNING'];
			const infoMessageArray = groupByIssues['INFO'];

			return (
				<div
					className='validation-message-container'
					ref={validationMessageContainerRef}
				>
					{_.isArray(errorMessageArray) &&
						size(errorMessageArray) > 0 && (
							<Fragment>
								<span className='message-type-title'>
									<img
										src={errorIcon}
										alt=''
										className='warning-icon'
									/>
									<span>
										{size(errorMessageArray) > 1
											? 'Variants invalid'
											: 'Variant invalid'}
									</span>
								</span>
								{errorMessageArray.map((data) => {
									const isDetailMessage =
										!isNil(
											get(data, ['detailMessage'], '')
										) &&
										!isEmpty(
											get(data, ['detailMessage'], '')
										);
									return (
										<Fragment key={data.message}>
											<span className='error-message'>
												{data.message}
											</span>
											{isDetailMessage && (
												<Popover
													trigger='hover'
													popperClass={classnames(
														'pp-variant-validation'
													)}
													content={data.detailMessage}
													key='popover-variant-validation'
													placement='top'
												>
													<span className='tooltip-info'>
														<img
															src={moreinfoicon}
															className='more-info-icon'
														/>
													</span>
												</Popover>
											)}
										</Fragment>
									);
								})}
							</Fragment>
						)}
					{_.isArray(warningMessageArray) &&
						size(warningMessageArray) > 0 && (
							<Fragment>
								<span className='message-type-title'>
									<img
										src={warningicon}
										alt=''
										className='warning-icon'
									/>
									<span>
										{size(warningMessageArray) > 1
											? 'Warning Messages'
											: 'Warning Message'}
									</span>
								</span>
								{warningMessageArray.map((data) => {
									const isDetailMessage =
										!isNil(
											get(data, ['detailMessage'], '')
										) &&
										!isEmpty(
											get(data, ['detailMessage'], '')
										);
									return (
										<Fragment key={data.message}>
											<span>{data.message}</span>
											{isDetailMessage && (
												<Popover
													trigger='hover'
													popperClass={classnames(
														'pp-variant-validation'
													)}
													content={data.detailMessage}
													key='popover-variant-validation'
													placement='top'
												>
													<span className='tooltip-info'>
														<img
															src={moreinfoicon}
															className='more-info-icon'
														/>
													</span>
												</Popover>
											)}
										</Fragment>
									);
								})}
							</Fragment>
						)}
					{_.isArray(infoMessageArray) && size(infoMessageArray) > 0 && (
						<Fragment>
							<span className='message-type-title'>
								<img
									src={infoIcon}
									alt=''
									className='info-icon'
								/>
								<span>
									{size(infoMessageArray) > 1
										? 'Info Messages'
										: 'Info Message'}
								</span>
							</span>
							{infoMessageArray.map((data) => {
								const isDetailMessage =
									!isNil(get(data, ['detailMessage'], '')) &&
									!isEmpty(get(data, ['detailMessage'], ''));
								return (
									<Fragment key={data.message}>
										<span className='info-message'>
											{data.message}
										</span>
										{isDetailMessage && (
											<Popover
												trigger='hover'
												popperClass={classnames(
													'pp-variant-validation'
												)}
												content={data.detailMessage}
												key='popover-variant-validation'
												placement='top'
											>
												<span className='tooltip-info'>
													<img
														src={moreinfoicon}
														className='more-info-icon'
													/>
												</span>
											</Popover>
										)}
									</Fragment>
								);
							})}
						</Fragment>
					)}
				</div>
			);
		} else {
			return '';
		}
	};

	return (
		<Dialog
			customClass='add-edit-structural-variant-dialog'
			title={
				<span className='header'>
					<span>
						{isEditMode() ? 'Edit' : 'New'} Structural Variant
						Finding
					</span>
					<span
						className='close-btn-icon'
						onClick={closeAddEditStructuralVariantModal}
					>
						<Icon icon='close' className='close-icon' />
					</span>
				</span>
			}
			size='tiny'
			modal={true}
			closeOnPressEscape={true}
			closeOnClickModal={false}
			showClose={false}
			visible={true}
			onCancel={closeAddEditStructuralVariantModal}
		>
			<Dialog.Body>
				<Loading loading={loading}>
					<section className='basic-information-module'>
						<h2 className='module-header'>Basic Information</h2>
						<Layout.Row gutter='12'>
							<Layout.Col span='8' className='required'>
								<span>Variant Type</span>
								<Select
									name='variantType'
									value={formdata?.variantType}
									onChange={(value) =>
										onVariantTypeChange(
											'variantType',
											value
										)
									}
									placeholder='Select'
									className={
										fieldHighlightForError.includes(
											'variantType'
										)
											? 'validation-field-hightlight'
											: ''
									}
								>
									{structuralVariantTypeEnum.map((item) => {
										return (
											<Select.Option
												key={item.key}
												label={item.display_name}
												value={item.key}
											/>
										);
									})}
								</Select>
							</Layout.Col>
							<Layout.Col
								span='8'
								className='required'
								offset='1'
							>
								<span>Reference Assembly</span>
								<Select
									name='referenceAssembly'
									value={
										formdata?.referenceAssembly || 'grch37'
									}
									onChange={(value) =>
										onFormChange('referenceAssembly', value)
									}
									placeholder='Select'
								>
									{referenceAssembliesEnum &&
										referenceAssembliesEnum.map((item) => {
											return (
												<Select.Option
													key={item.code}
													label={item.name}
													value={item.code}
												/>
											);
										})}
									<Select.Option label='None' value='none' />
								</Select>
							</Layout.Col>
						</Layout.Row>
					</section>

					<section className='identification-module'>
						<h2 className='module-header'>Identification</h2>
						<Layout.Row>
							<Layout.Col span='4'>
								<span className='nomenclature-label'>
									Nomenclature
								</span>
							</Layout.Col>
							<Layout.Col
								span='20'
								className='nomenclature-radiobutton-container'
							>
								<RadioGroup
									options={structuralNomenclatureRadioEnum}
									value={nomenclature || ''}
									onChange={selectNomenclatureFunc}
								/>
							</Layout.Col>
						</Layout.Row>

						{nomenclature == 'geneticExtents' && (
							<Fragment>
								<Layout.Row gutter='12'>
									<Layout.Col>
										<span className='geneticExtent-label'>
											Genetic Extent
										</span>
									</Layout.Col>
								</Layout.Row>
								<Layout.Row
									gutter='12'
									style={{ marginTop: '8px' }}
									type='flex'
								>
									<Layout.Col span='7' className='required'>
										<span>Gene Symbol</span>
										<Select
											name='geneId'
											value={formdata.geneId}
											onChange={(value) =>
												onFormChange('geneId', value)
											}
											filterable={true}
											remote={true}
											clearable={true}
											placeholder='Gene Symbol'
											remoteMethod={(val) =>
												onGenesSymbolSearch(val)
											}
											loading={genesSearchLoading}
										>
											{geneticResultGenesSymbolEnum.map(
												(el) => {
													return (
														<Select.Option
															key={el.id}
															label={el.symbol}
															value={el.id}
														/>
													);
												}
											)}
										</Select>
									</Layout.Col>
									<Layout.Col span='3' offset='1'>
										<span>Exon Start</span>
										<Input
											name='startExon'
											id='startExon'
											value={formdata?.startExon}
											onChange={(value) =>
												onExonChange('startExon', value)
											}
											placeholder='ex: 1'
											disabled={
												formdata?.entireCodingRegion
													? true
													: false
											}
										/>
										<div className='input-error-text'>
											{get(errors, ['startExon'], '')}
										</div>
									</Layout.Col>
									<Layout.Col span='3'>
										<span>Exon End</span>
										<Input
											name='endExon'
											id='endExon'
											value={formdata?.endExon}
											onChange={(value) =>
												onExonChange('endExon', value)
											}
											placeholder='ex: 10'
											disabled={
												formdata?.entireCodingRegion
													? true
													: false
											}
										/>
										<div className='input-error-text'>
											{get(errors, ['endExon'], '')}
										</div>
									</Layout.Col>
									<Layout.Col
										span='6'
										className='required'
										offset='1'
									>
										<span>Entire Coding Region</span>
										<RadioGroup
											options={entireCodingRegionEnum}
											value={
												formdata?.entireCodingRegion ||
												false
											}
											onChange={(value) =>
												onFormChange(
													'entireCodingRegion',
													value
												)
											}
										/>
									</Layout.Col>
								</Layout.Row>
							</Fragment>
						)}

						{nomenclature == 'chromosomalExtents' && (
							<Fragment>
								<Layout.Row gutter='12'>
									<Layout.Col>
										<span className='geneticExtent-label'>
											Chromosomal Extent
										</span>
									</Layout.Col>
								</Layout.Row>
								<Layout.Row
									gutter='12'
									style={{ marginTop: '8px' }}
									type='flex'
								>
									<Layout.Col span='7'>
										<span>Cytogenetic Location</span>
										<Input
											name='cytogeneticLocation'
											id='cytogeneticLocation'
											value={
												formdata?.cytogeneticLocation
											}
											onChange={(value) =>
												onCytogeneticLocationChange(
													'cytogeneticLocation',
													value
												)
											}
											placeholder='ex: 2p12.11'
											className={
												fieldHighlightForError.includes(
													'cytogeneticLocation'
												)
													? 'validation-field-hightlight'
													: ''
											}
										/>
										<div className='input-error-text'>
											{get(
												errors,
												['cytogeneticLocation'],
												''
											)}
										</div>
									</Layout.Col>
									<Layout.Col
										span='7'
										className='required'
										offset='1'
									>
										<span>Chromosome</span>
										<Select
											name='chromosome'
											value={formdata?.chromosome}
											onChange={(value) =>
												onFormChange(
													'chromosome',
													value
												)
											}
											placeholder='Select'
											disabled={isDisableChromosomeField}
											className={
												fieldHighlightForError.includes(
													'chromosome'
												)
													? 'validation-field-hightlight'
													: ''
											}
										>
											{chromosomeEnum.map((item) => {
												return (
													<Select.Option
														key={item.key}
														label={
															item.display_name
														}
														value={item.key}
													/>
												);
											})}
										</Select>
										<div className='input-error-text'>
											{get(errors, ['chromosome'], '')}
										</div>
									</Layout.Col>
									<Layout.Col span='4' offset='1'>
										<span>Position Start</span>
										<Input
											name='startPosition'
											id='startPosition'
											value={formdata?.startPosition}
											onChange={(value) =>
												onChromosomalPositionChange(
													'startPosition',
													value
												)
											}
											placeholder='ex: 47450938'
										/>
										<div className='input-error-text'>
											{get(errors, ['startPosition'], '')}
										</div>
									</Layout.Col>
									<Layout.Col span='4'>
										<span>Position End</span>
										<Input
											name='endPosition'
											id='endPosition'
											value={formdata?.endPosition}
											onChange={(value) =>
												onChromosomalPositionChange(
													'endPosition',
													value
												)
											}
											placeholder='ex: 47645809'
										/>
										<div className='input-error-text'>
											{get(errors, ['endPosition'], '')}
										</div>
									</Layout.Col>
								</Layout.Row>
							</Fragment>
						)}

						{(nomenclature === 'geneticExtents' ||
							nomenclature === 'chromosomalExtents') && (
							<Fragment>
								<Layout.Row
									gutter='12'
									style={{ marginTop: '32px' }}
								>
									<Layout.Col>
										<span className='geneticExtent-label'>
											Other Information
										</span>
									</Layout.Col>
								</Layout.Row>
								<Layout.Row
									gutter='12'
									style={{ marginTop: '8px' }}
									type='flex'
								>
									<Layout.Col
										span='4'
										style={{ width: '14.66%' }}
									>
										<span>Size</span>
										<Input
											name='sizeValue'
											id='sizeValue'
											value={formdata?.sizeValue}
											onChange={(value) =>
												onSizeValueChange(
													'sizeValue',
													value
												)
											}
											placeholder='Variant size'
										/>
										<div className='input-error-text'>
											{get(errors, ['sizeValue'], '')}
										</div>
									</Layout.Col>
									<Layout.Col
										span='4'
										className='unitCopyNumContainer'
									>
										<span>Units</span>
										<RadioGroup
											options={structuralVariantUnitEnum}
											value={formdata?.unit || ''}
											onChange={(value) =>
												onFormChange('unit', value)
											}
										/>
										<div className='input-error-text'>
											{get(errors, ['unit'], '')}
										</div>
									</Layout.Col>
									<Layout.Col span='7'>
										<span>Copy Number</span>
										<Input
											name='copyNumber'
											id='copyNumber'
											value={formdata?.copyNumber}
											onChange={(value) =>
												onCopyNumberChange(
													'copyNumber',
													value
												)
											}
											placeholder='Copy number'
										/>
										<div className='input-error-text'>
											{get(errors, ['copyNumber'], '')}
										</div>
									</Layout.Col>
								</Layout.Row>
							</Fragment>
						)}

						{nomenclature == 'ISCN' && (
							<Fragment>
								<Layout.Row gutter='12'>
									<Layout.Col span='15' className='required'>
										<span>Sequence</span>
									</Layout.Col>
									<Layout.Col span='15'>
										<Input
											name='sequence'
											id='sequence'
											value={formdata?.sequence}
											onChange={(value) =>
												onFormChange('sequence', value)
											}
											placeholder='Enter a single finding ISCN'
										/>
									</Layout.Col>
								</Layout.Row>
								<Layout.Row gutter='12'>
									<Layout.Col span='15'>
										<span>Characterization</span>
									</Layout.Col>
									<Layout.Col span='15'>
										<Input
											type='textarea'
											rows={3}
											name='characterization'
											id='characterization'
											value={formdata?.characterization}
											onChange={(value) =>
												onFormChange(
													'characterization',
													value
												)
											}
											placeholder='Enter the finding result interpretation'
										/>
									</Layout.Col>
								</Layout.Row>
							</Fragment>
						)}
					</section>

					<section className='interpretation-module'>
						<h2 className='module-header'>Interpretation</h2>
						<Layout.Row>
							<Layout.Col span='8'>
								<span>Lab Classification</span>
								<Select
									name='labClassification'
									value={formdata?.labClassification}
									onChange={(value) =>
										onFormChange('labClassification', value)
									}
									placeholder='Select'
								>
									{labClassificationEnum.map((item) => {
										return (
											<Select.Option
												key={item.key}
												label={item.display_name}
												value={item.key}
											/>
										);
									})}
								</Select>
							</Layout.Col>
						</Layout.Row>
					</section>

					<section className='additional-information-module'>
						<h2 className='module-header'>
							Additional Information
						</h2>
						<Layout.Row gutter='12'>
							<Layout.Col span='8'>
								<span>Genomic Source Class</span>
								<Select
									name='genomicSourceClass'
									value={formdata?.genomicSourceClass}
									onChange={(value) =>
										onFormChange(
											'genomicSourceClass',
											value
										)
									}
									placeholder='Select'
								>
									{genomicSourceClassEnum.map((item) => {
										return (
											<Select.Option
												key={item.key}
												label={item.display_name}
												value={item.key}
											/>
										);
									})}
								</Select>
							</Layout.Col>
							<Layout.Col span='8'>
								<div>Origin</div>
								<Select
									name='origin'
									value={formdata?.origin}
									onChange={(value) =>
										onFormChange('origin', value)
									}
									placeholder='Select'
								>
									{originEnum.map((item) => {
										return (
											<Select.Option
												key={item.key}
												label={item.display_name}
												value={item.key}
											/>
										);
									})}
								</Select>
							</Layout.Col>
							<Layout.Col span='8'>
								<span>Mosaicism</span>
								<Select
									name='mosaicism'
									value={formdata?.mosaicism}
									onChange={(value) =>
										onFormChange('mosaicism', value)
									}
									placeholder='Select mosaicism'
								>
									{mosaicismEnum.map((item) => {
										return (
											<Select.Option
												key={item.key}
												label={item.display_name}
												value={item.key}
											/>
										);
									})}
								</Select>
							</Layout.Col>
						</Layout.Row>
					</section>

					{showErrorMessageSection === true &&
						displayFindingValidationMessage()}
					{!isEmpty(structuralFindingValidationError) && (
						<div className='validation-message-container'>
							{!isEmpty(
								get(
									structuralFindingValidationError,
									['message'],
									null
								)
							)
								? structuralFindingValidationError.message
								: ''}
						</div>
					)}
				</Loading>
			</Dialog.Body>
			<Dialog.Footer className='dialog-footer'>
				{!isEditMode() && (
					<Fragment>
						<Button
							disabled={isDisableCheckEntriesButton()}
							className='check-entries'
							onClick={() => {
								checkEntries();
							}}
							type='text'
						>
							Check Entries
						</Button>
						<Button
							className='save'
							disabled={isDisableSavedButtonFunc()}
							onClick={() => {
								saveTestResultData();
							}}
						>
							Add Finding
						</Button>
					</Fragment>
				)}
				{isEditMode() && (
					<Fragment>
						{!isEmpty(structuralVariantFindings?.assertedBy) &&
							structuralVariantFindings?.assertedBy ===
								'User' && (
								<Button
									disabled={loading}
									className='delete-variant'
									onClick={() =>
										_deleteStructuralVariantFinding()
									}
								>
									<ReactSVG
										src={trashIcon}
										className='delete-variant-icon'
									/>
									<span>Delete Finding</span>
								</Button>
							)}
						<Button
							disabled={isDisableCheckEntriesButton()}
							className='check-entries'
							onClick={() => {
								checkEntries();
							}}
							type='text'
						>
							Check Entries
						</Button>
						<Button
							className='save'
							disabled={isDisableSavedButtonFunc()}
							onClick={() => {
								saveTestResultData();
							}}
						>
							Save
						</Button>
					</Fragment>
				)}
			</Dialog.Footer>
		</Dialog>
	);
};

export default AddEditStructuralVariantModal;
