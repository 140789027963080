import { useEffect, useState, useCallback } from 'react';

export const useIsTabActive = () => {
	const [isTabVisible, setIsTabVisible] = useState(true);

	const handleVisibilityChange = useCallback(() => {
		setIsTabVisible(document.visibilityState === 'visible');
	}, []);

	useEffect(() => {
		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => {
			document.removeEventListener(
				'visibilitychange',
				handleVisibilityChange
			);
		};
	}, []);

	return isTabVisible;
};
