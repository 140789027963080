//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Other Libraries
import { CopyToClipboard } from 'react-copy-to-clipboard';

//Components
import AddressCard from './../Common/AddressCard.js';

const PatientDemographicContactTab = (props) => {
	const { enqueueSnackbar, addresses } = props;

	const copyClipboard = (name) => {
		enqueueSnackbar('The ' + name + ' has been copied.', {
			variant: 'success',
			className: 'export_notification',
			anchorOrigin: { horizontal: 'center', vertical: 'top' },
		});
	};

	return (
		<div
			className={'demographicItem fullWidth'}
			style={{ marginTop: '18px' }}
		>
			<div className='patientSideBarAddressHeader'>PATIENT ADDRESSES</div>
			{Array.isArray(addresses) &&
				addresses.map((address) => {
					return (
						<AddressCard
							address={address}
							variant={'sidebar'}
							action={null}
							key={get(address, 'address_line1', null)}
							enqueueSnackbar={enqueueSnackbar}
						/>
					);
				})}
		</div>
	);
};

export default PatientDemographicContactTab;
