import React from 'react';

import { Buttons } from './Buttons';
import { Filters } from './filters';

import './Header.scss';

export function Header() {
	return (
		<div className='header'>
			<Buttons />
			<div className='placeholder' />
			<Filters />
		</div>
	);
}
