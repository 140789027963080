//React & Redux
import React from 'react';

//UI Libraries
import { Layout, Form, Button } from 'gm-element-react';

//Other Libraries

//Utils
import {
	fetalSexEnum,
	priorRiskItemEnum,
	residualRiskItemEnum,
} from '../../../../utils';

//Styles
import './css/view-cfdna-prenatal-screening.css';

//Utils
import { CARE_PATIENTS_ENTER_TEST_RESULT } from '../../../../Utils/permissionUtils';

//Components
import NotesContainer from '../../../Common/Notes/NotesContainer';

const cfDNAPrenatalFindingEnum = {
	'gm:00000128': 'Trisomy 13',
	'gm:00000130': 'Trisomy 18',
	'gm:00000131': 'Trisomy 21',
	'gm:00000132': 'Trisomy 22',
	'gm:00000129': 'Trisomy 16',
	'gm:00000133': 'Triplody',
	'gm:00000134': '1p36 deletion syndrome',
	'gm:00000135': 'Wolf-Hirschhorn syndrome',
	'gm:00000136': 'Cri-du-chat syndrome',
	'gm:00000137': '8q24 deletion syndrome',
	'gm:00000138': 'Jacobsen syndrome',
	'gm:00000139': 'Angelman syndrome/Prader-Willi syndrome',
	'gm:00000140': '22q11 deletion syndrome',
	'gm:00000141': 'X',
	'gm:00000142': 'XXY',
	'gm:00000143': 'XYY',
	'gm:00000144': 'XXX',
};

const ViewCFDNAPrenatalScreening = (props) => {
	const {
		addDiagnosticFindingClinicalNoteError = null,
		addDiagnosticFindingClinicalNoteLoading = false,
		addNewDiagnosticFindingClinicalNote,
		showViewCFDNAPrenatalScreeningFullDetailFunc,
		cfDNAPrenatalObservationFinding,
	} = props;

	let uuid = '';
	let notes = [];

	const displayFetalFraction = () => {
		let _fetalFractionVal = '--';
		let _fetalFractionUnit = '';

		for (const key in cfDNAPrenatalObservationFinding) {
			if (
				cfDNAPrenatalObservationFinding[
					key
				]?.riskName?.id.toLowerCase() === 'gm:00000065'
			) {
				_fetalFractionVal =
					cfDNAPrenatalObservationFinding[key]?.riskType?.value;
				_fetalFractionUnit =
					cfDNAPrenatalObservationFinding[key]?.riskType?.unit;

				notes = cfDNAPrenatalObservationFinding[key]?.notes || [];

				uuid = cfDNAPrenatalObservationFinding[key]?.uuid;
			}
		}
		return _fetalFractionVal + _fetalFractionUnit;
	};

	const displayFetalSex = () => {
		let _fetalSex = '--';

		for (const key in cfDNAPrenatalObservationFinding) {
			if (
				cfDNAPrenatalObservationFinding[
					key
				]?.riskName?.id.toLowerCase() === 'gm:00000066'
			) {
				const _fetalSexEnum = { 'gm:00000061': 1, 'gm:00000062': 1 };

				if (
					cfDNAPrenatalObservationFinding[
						key
					]?.riskType?.id.toLowerCase() in _fetalSexEnum
				) {
					const fetalSex = fetalSexEnum.find(
						(f) =>
							f.key.toLowerCase() ===
							cfDNAPrenatalObservationFinding[
								key
							]?.riskType?.id.toLowerCase()
					);
					_fetalSex =
						fetalSex?.display_name ||
						'Sex chromosomes not reported';
				}
			}
		}
		return _fetalSex;
	};

	const getCFDNAPrenatalRiskName = (id) => {
		return cfDNAPrenatalFindingEnum[id] || '--';
	};

	const displayHighRiskItem = (finding) => {
		let _highRiskItem = '';
		let riskName = '';
		let priorRiskVal = '';
		let residualRiskVal = '';

		if (
			finding?.isHighRiskType &&
			finding?.riskType?.id.toLowerCase() === 'gm:00000106'
		) {
			const _riskNameID = finding?.riskName?.id.toLowerCase();
			riskName = getCFDNAPrenatalRiskName(_riskNameID);

			finding?.riskPriorResidual.map((item) => {
				if (item?.kind?.id.toLowerCase() in priorRiskItemEnum) {
					priorRiskVal = item?.value?.value;
				}
				if (item?.kind?.id.toLowerCase() in residualRiskItemEnum) {
					residualRiskVal = item?.value?.value;
				}
			});

			_highRiskItem = (
				<span className='field-val'>
					{riskName} (Prior: {priorRiskVal || '--'}, Residual:
					{residualRiskVal || '--'})
				</span>
			);
		}
		return _highRiskItem;
	};

	return (
		<>
			<Form className='view-cfdna-prenatal-screening-info'>
				<Layout.Row
					gutter='20'
					className='cfdna-prenatal-screening-title'
				>
					<Layout.Col>
						Cell-Free DNA (cfDNA) Prenatal Screening
					</Layout.Col>
				</Layout.Row>
				<Layout.Row gutter='32' className='clinical-comments-container'>
					<Layout.Col
						span='16'
						className='cfdna-prenatal-screening-section'
					>
						<Layout.Row className='result-details-label'>
							<Layout.Col span='17'>Result Details</Layout.Col>
						</Layout.Row>

						<Layout.Col span='12'>
							<Layout.Row>
								<Layout.Col span='8'>
									<label htmlFor='fetalFraction'>
										Fetal Fraction
									</label>
									<span className='field-val'>
										{displayFetalFraction()}
									</span>
								</Layout.Col>
								<Layout.Col span='16'>
									<label htmlFor='fetalSex'>Fetal Sex</label>
									<span className='field-val'>
										{displayFetalSex()}
									</span>
								</Layout.Col>
							</Layout.Row>
						</Layout.Col>

						<Layout.Col span='12'>
							<Layout.Row>
								<Layout.Col>
									<label htmlFor='highRiskResults'>
										High Risk Results
									</label>
									{cfDNAPrenatalObservationFinding.map(
										(finding) => {
											return displayHighRiskItem(finding);
										}
									)}
								</Layout.Col>
							</Layout.Row>
						</Layout.Col>

						<Layout.Row>
							<Layout.Col span='18'>
								<Button
									className='structural-variant-view-full-details'
									type='text'
									onClick={() =>
										showViewCFDNAPrenatalScreeningFullDetailFunc(
											cfDNAPrenatalObservationFinding
										)
									}
								>
									View full details
								</Button>
							</Layout.Col>
						</Layout.Row>
					</Layout.Col>

					<Layout.Col span='8'>
						<span className='clinical-comments-label'>
							Clinical Comments
						</span>
						<NotesContainer
							notes={notes}
							variant='cfDNA'
							addNoteLoading={
								addDiagnosticFindingClinicalNoteLoading
							}
							addNoteError={addDiagnosticFindingClinicalNoteError}
							addNote={addNewDiagnosticFindingClinicalNote}
							sourceUUID={uuid}
							addNoteModalTitle='New Comment — cfDNA Prenatal Screening'
							emptyMessageClass='empty-clinical-comments-msg'
							isShowListIcon={false}
							hasRBACAddComment={true}
							RBACAddCommentAction={
								CARE_PATIENTS_ENTER_TEST_RESULT
							}
						/>
					</Layout.Col>
				</Layout.Row>
			</Form>
		</>
	);
};

export default ViewCFDNAPrenatalScreening;
