//React & Redux
import React, { useState } from 'react';

//Lodash
import get from 'lodash/get';

//Components
import AddEditExternalProviderInputField from './AddEditExternalProviderInputField.js';

const AddEditExternalProviderFields = (props) => {
	const {
		errors = {},
		fieldChange,
		fieldsJSON = [],
		setPhoneFieldFocus = false,
	} = props;

	return (
		<div className='externalProviderModalFieldsContainer'>
			{Array.isArray(fieldsJSON) &&
				fieldsJSON.map((el) => {
					return (
						<AddEditExternalProviderInputField
							fieldData={el}
							fieldChange={fieldChange}
							key={get(el, 'header', null)}
							errors={errors}
							setPhoneFieldFocus={setPhoneFieldFocus}
						/>
					);
				})}
		</div>
	);
};

export default AddEditExternalProviderFields;
