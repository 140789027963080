//React & Redux
import React, { Component } from 'react';

//Lodash
import { get, isString, parseInt } from 'lodash';

//Other Libraries
import classnames from 'classnames';

//UI Libraries
import Tooltip from './../../Common/Tooltip';

//Styles
import './style.css';

class TextWithTooltip extends Component {
	state = {
		overflowing: false,
	};

	isContentString = isString(this.props.children);

	componentDidMount() {
		if (this.isContentString) {
			const multiLine = get(this, ['props', 'multiLine'], false);
			const { style } = this.element;
			const { width, height } = this.element.getBoundingClientRect();
			const maxWidth = parseInt(style.maxWidth);
			const maxHeight = parseInt(style.maxHeight);

			if (height > maxHeight || (!multiLine && width >= maxWidth)) {
				this.setState({ overflowing: true });
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.children != prevProps.children) {
			if (this.isContentString) {
				const multiLine = get(this, ['props', 'multiLine'], false);
				const { style } = this.element;
				const { width, height } = this.element.getBoundingClientRect();
				const maxWidth = parseInt(style.maxWidth);
				const maxHeight = parseInt(style.maxHeight);

				if (height > maxHeight || (!multiLine && width >= maxWidth)) {
					this.setState({ overflowing: true });
				}
			}
		}
	}

	render() {
		const isOverflowing = get(this, ['state', 'overflowing'], false);
		const classNames = get(this, ['props', 'className'], '');
		const multiLine = get(this, ['props', 'multiLine'], false);
		const maxWidth = get(this, ['props', 'maxWidth'], '144px');
		const stateMaxWidth = maxWidth
			? parseInt(maxWidth) - 32 + 'px'
			: maxWidth;
		const placement = get(this, ['props', 'placement'], null);
		const { classes } = this.props;

		return (
			<span
				ref={(el) => (this.element = el)}
				style={{
					...get(this, ['props', 'style'], {}),
					maxWidth: stateMaxWidth,
					maxHeight: get(this, ['props', 'maxHeight'], '40px'),
				}}
				className={classnames(
					'text-with-tooltip',
					{
						'single-line': !multiLine,
						'multi-line': multiLine,
						overflowing: isOverflowing,
					},
					classNames
				)}
			>
				{isOverflowing ? (
					<Tooltip
						content={this.props.children}
						placement={placement}
					>
						<span>{this.props.children}</span>
					</Tooltip>
				) : (
					this.props.children
				)}
			</span>
		);
	}
}

export default TextWithTooltip;
