//React & Redux
import React, { useState } from 'react';
import PropTypes from 'prop-types';

//Lodash
import _ from 'lodash';

//Utils
import { extProviderRoles } from '../../utils';

//UI Libraries
import { Layout, Form, Button } from 'gm-element-react';

//Components
import Icon from '../Common/Icon';
import CheckBoxButton from '../Common/controls/CheckBoxButton';

//Styles
import './ProviderLinkForm.css';

const ProviderLinkForm = (props) => {
	const {
		editingLinkInfo,
		goBack,
		initialValues = {},
		linkedTo,
		onSubmit: onSubmitProps,
	} = props;

	const defaultFormValues = {
		providerRole: ['referring_provider'],
		...initialValues,
	};

	const [form, setForm] = useState(defaultFormValues);

	const onSubmit = (e) => {
		e.preventDefault();
		onSubmitProps(form);
	};

	const onChange = (key, value) => {
		setForm((form) => ({
			...form,
			[key]: value,
		}));
	};

	const handleProviderRole = (value) => {
		onChange('providerRole', value);
	};

	const handleReceiveAllComms = (value) => {
		const val =
			_.isArray(value) &&
			!_.isEmpty(value) &&
			value.includes('receiveAllComms')
				? true
				: false;
		onChange('receiveAllComms', val);
	};

	const isEditingLinkInfo = () => {
		const { savedRecord } = initialValues;
		return savedRecord || editingLinkInfo;
	};

	return (
		<Form className='provider-link-form' model={form}>
			<div className='provider-link-form-inner'>
				{!_.isEmpty(initialValues) && (
					<Layout.Row>
						<Layout.Col span='24'>
							<div className='provider-info'>
								<span className='provider'>
									<span className='icon'>
										<Icon
											icon='stethoscope'
											className='stethoscope'
										/>
									</span>
									<span className='info'>
										<span className='pro-name'>{`${_.get(
											initialValues,
											['providerFullName'],
											''
										)}`}</span>
										<span className='location'>{`${_.get(
											initialValues,
											['city'],
											''
										)}${
											!_.isEmpty(
												_.get(
													initialValues,
													['state'],
													''
												)
											)
												? ', ' +
												  _.get(
														initialValues,
														['state'],
														''
												  )
												: ''
										}`}</span>
									</span>
								</span>
								<span className='designation'>
									{_.get(initialValues, ['title'], '')}
								</span>
							</div>
						</Layout.Col>
					</Layout.Row>
				)}
				<Layout.Row>
					<Layout.Col span='24'>
						<Form.Item
							label={
								<span>
									<span className='required-asterisk'>*</span>
									Provider Role
								</span>
							}
							prop='providerRole'
						>
							<CheckBoxButton
								value={[...(form.providerRole || [])]}
								onChange={handleProviderRole}
								options={extProviderRoles}
							/>
						</Form.Item>
					</Layout.Col>
				</Layout.Row>
				{linkedTo != 'encounter' && (
					<Layout.Row>
						<Layout.Col span='24'>
							<Form.Item
								label='Other Options'
								prop='receiveAllComms'
							>
								<CheckBoxButton
									value={
										form.receiveAllComms
											? ['receiveAllComms']
											: []
									}
									onChange={handleReceiveAllComms}
									options={[
										{
											value: 'receiveAllComms',
											label: 'Link provider to all new encounters of this patient.',
										},
									]}
								/>
							</Form.Item>
							<div className='note'>
								{
									'Linking allows providers to receive a copy of all encounter level patient documentation'
								}
							</div>
						</Layout.Col>
					</Layout.Row>
				)}
			</div>
			<Layout.Row>
				<Form.Item
					className='el-dialog__footer'
					style={isEditingLinkInfo() ? { display: 'block' } : {}}
				>
					{!isEditingLinkInfo() && (
						<Button
							disabled={false}
							onClick={() =>
								goBack(
									_.get(
										initialValues,
										['providerFullName'],
										''
									)
								)
							}
							className='cancel-button'
						>
							{'Back'}
						</Button>
					)}
					<Button
						disabled={
							_.isNil(form.providerUUID) ||
							_.isEmpty(form.providerRole)
						}
						onClick={onSubmit}
						className='confirm-button'
					>
						{`${isEditingLinkInfo() ? 'Save' : 'Link Provider'}`}
					</Button>
				</Form.Item>
			</Layout.Row>
		</Form>
	);
};

ProviderLinkForm.propTypes = {
	initialValues: PropTypes.object,
	onSubmit: PropTypes.func,
};

export default ProviderLinkForm;
