import axios from 'axios';
import get from 'lodash/get';

import { store } from './../store/configureStore';
import StorageService from './../service/Storage';
import { getInterval } from './../actions/session';
import { accessrestricted } from './../actions/accessrestricted';
import {
	showRefreshModal,
	retryExceeded,
	showSwitchRoleModal,
	requestAuthorizationStatus,
} from './../actions/httpHandlers';
import { switchRole } from './../actions/me';

export function successStatus(response, client) {
	if (get(response, ['headers', 'updated'], 'no') == 'yes') {
		setTimeout(() => {
			store.dispatch(showRefreshModal());
		}, 3000);
	}
	if (get(response, ['headers', 'roleupdated'], 'false') === 'true')
		window.location.pathname = '/';
	if (get(response, ['headers', 'invalid-role'], 'false') === 'true')
		switchRole().then((res) => {
			if (res.status == 'DEFAULT')
				store.dispatch(showSwitchRoleModal(res.roleuuid));
		});
	if (client === 'axios') return response;
}

export function failedStatus(error, client) {
	if (
		get(error, ['response', 'headers', 'invalid-role'], 'false') === 'true'
	) {
		switchRole().then((res) => {
			if (res.status == 'DEFAULT')
				store.dispatch(showSwitchRoleModal(res.roleuuid));
		});
	} else if (error && error.response && 401 === error.response.status) {
		clearInterval(store.getState().interval.interval);
		store.dispatch(getInterval(0));

		if (window.location.pathname.startsWith('/ui')) {
			const pathname = window.location.pathname.replace('/ui', '');
			StorageService.setRedirectURL(pathname);
		}

		store.dispatch(requestAuthorizationStatus(false));
		return Promise.reject(error);
	} else if (
		error &&
		error.response &&
		error.response.status &&
		403 === error.response.status &&
		error.response.data == 'csrf is not valid'
	) {
		const retrycount = +get(
			error,
			['config', 'headers', 'retry-counts'],
			null
		);
		const nextretrycount = isNaN(retrycount) ? 1 : retrycount + 1;

		if (nextretrycount < 3 && client === 'axios') {
			error.config.headers['retry-counts'] = nextretrycount;
			return new Promise((resolve) => {
				setTimeout(resolve, 1500);
			}).then(() => {
				return axios.request(error.config);
			});
		} else {
			store.dispatch(retryExceeded());
			if (client === 'axios') return Promise.reject(error);
		}
	} else if (
		error &&
		error.response &&
		error.response.status &&
		403 === error.response.status
	) {
		store.dispatch(accessrestricted());
	} else if (client === 'axios') return Promise.reject(error);
}
