//React & Redux
import React from 'react';

//UI Libraries
import { Radio } from 'gm-element-react';

//Styles
import './RadioGroup.scss';

export interface IOptions {
	display_name: string;
	key: string;
	disabled?: boolean;
}

export interface IProps {
	value?: string;
	onChange: (value: string) => void;
	options?: IOptions[];
}

export const RadioGroup: React.FC<IProps> = (props) => {
	const { options, value, onChange } = props;
	return (
		<Radio.Group
			className='gmi-radio-group'
			{...{
				onChange,
				value,
			}}
		>
			{options?.map((el) => (
				<Radio
					key={`radio-${el.key}`}
					{...{
						value: el.key,
						onChange,
						disabled: el.disabled ?? false,
					}}
				>
					{el.display_name}
				</Radio>
			))}
		</Radio.Group>
	);
};
