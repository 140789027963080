import axios from 'axios';

class RiseAnalyticsService {
	getRiseDashboardData = (data, dimension) => {
		const url = '/api/report/rise-analytics/' + dimension;
		return axios.post(url, data);
	};
	riseDashboardExportReport = (data, dimension) => {
		const url = '/api/report/rise-analytics/' + dimension + '/export';
		return axios.post(url, data);
	};
}

export default new RiseAnalyticsService();
