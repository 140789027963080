//React & Redux
import React, { Fragment, useState } from 'react';

//Lodash
import { get, isEmpty, isNil, size, orderBy } from 'lodash';
import moment from 'moment';

//UI Libraries
import { Layout, Form } from 'gm-element-react';

//Images
import imageExpandCollapse from '../../../../assets/chevron-large.svg';

//Styles
import './css/view-risk-assessment-predictions.css';

//Utils
import { CARE_PATIENTS_ENTER_TEST_RESULT } from '../../../../Utils/permissionUtils';

//Other Libraries

//Components
import NotesContainer from '../../../Common/Notes/NotesContainer';
import Tooltip from '../../../Common/Tooltip.js';

const ViewRiskAssessmentPredictions = (props) => {
	const {
		addDiagnosticFindingClinicalNoteError,
		addNewDiagnosticFindingClinicalNote,
		addDiagnosticFindingClinicalNoteLoading,
		riskAssessmentPredictionsReports: {
			type,
			version,
			uuid,
			predictions,
			notes,
		},
	} = props;

	const [showAccordian, setShowAccordian] = useState(false);

	const handleToggleAccordian = (accordianNumber) => {
		setShowAccordian((state) => ({
			...state,
			[accordianNumber]: !showAccordian[accordianNumber],
		}));
	};

	const getRiskAssessmentTypeVersion = (type, version) => {
		return `Risk Assessment - ${type || '--'}  (v${version})`;
	};

	const getTimeframe = (prediction) => {
		if (!isNil(get(prediction, ['timeframe'], ''))) {
			const timeframe = moment
				.duration(get(prediction, ['timeframe'], 'Lifetime'))
				.humanize();
			return timeframe == 'a year'
				? '(1 Year)'
				: timeframe == 'a month'
				? '(1 Month)'
				: `(${timeframe})`;
		}
		return '(Lifetime)';
	};

	const getAssessedText = (prediction) => {
		let qualitativeRisk = get(prediction, ['qualitativeRisk', 'label'], '');
		let probability = get(prediction, ['probability'], null);
		let relativeRisk = get(prediction, ['relativeRisk'], null);
		let baselineProbability = get(
			prediction,
			['baselineProbability'],
			null
		);
		let comma = '';
		let assessedText = 'Not Assessed';

		qualitativeRisk =
			!isNil(qualitativeRisk) && !isEmpty(qualitativeRisk)
				? qualitativeRisk
				: '';
		probability =
			!isNil(probability) && !isEmpty(probability)
				? !isEmpty(qualitativeRisk)
					? `- ${(probability * 100).toFixed(1)}%`
					: `${(probability * 100).toFixed(1)}%`
				: '';
		comma =
			(!isNil(relativeRisk) &&
				!isEmpty(relativeRisk) &&
				!isNil(qualitativeRisk) &&
				!isEmpty(qualitativeRisk)) ||
			(!isNil(probability) && !isEmpty(probability))
				? ','
				: '';
		relativeRisk =
			!isNil(relativeRisk) && !isEmpty(relativeRisk)
				? `${comma} ${relativeRisk} RR`
				: '';
		baselineProbability =
			!isNil(baselineProbability) && !isEmpty(baselineProbability)
				? !isNil(relativeRisk) && !isEmpty(relativeRisk)
					? ` (Baseline ${(baselineProbability * 100).toFixed(1)} %)`
					: `, (Baseline ${(baselineProbability * 100).toFixed(1)} %)`
				: '';

		if (!get(prediction, ['assessed'], false)) {
			return assessedText;
		} else {
			assessedText =
				qualitativeRisk +
				probability +
				relativeRisk +
				baselineProbability;
			return assessedText;
		}
	};

	const getSortedPredictions = (predictions) => {
		return orderBy(
			predictions || [],
			[
				function (p) {
					return get(p, ['outcome', 'label'], '');
				},
				function (p) {
					return get(p, ['timeframe'], '');
				},
			],
			['asc', 'asc']
		);
	};

	const sortedPredictions = getSortedPredictions(predictions);

	return (
		<Fragment>
			<Form className='view-risk-assessment-predictions-form'>
				<Layout.Row gutter='20' className='risk-assessment-title'>
					<Layout.Col>
						{getRiskAssessmentTypeVersion(type, version)}
					</Layout.Col>
				</Layout.Row>
				<Layout.Row gutter='32' className='clinical-comments-container'>
					<Layout.Col span='16' className='risk-assessment-section'>
						<Layout.Row className='predictions-label'>
							<Layout.Col>Predictions</Layout.Col>
						</Layout.Row>

						<Layout.Row>
							<Layout.Col span='24'>
								<div className='predictions-container'>
									{(sortedPredictions || []).map(
										(prediction, idx) => {
											return (
												<div
													className='prediction-accordian-wrapper'
													key={idx}
												>
													<div
														className={
															'prediction-accordian-header'
														}
													>
														<span className='outcome-label'>
															{get(
																prediction,
																[
																	'outcome',
																	'label',
																],
																''
															)}{' '}
															{getTimeframe(
																prediction
															)}
														</span>
														<span className='assessed-text'>
															{getAssessedText(
																prediction
															)}
														</span>
														<span className='accordian-icon'>
															<img
																src={
																	imageExpandCollapse
																}
																onClick={() =>
																	handleToggleAccordian(
																		idx
																	)
																}
																style={{
																	transform:
																		showAccordian[
																			idx
																		]
																			? 'rotate(-90deg)'
																			: 'rotate(90deg)',
																}}
																alt='expand-icon'
															/>
														</span>
													</div>
													<div
														className='prediction-accordian-body'
														style={{
															display:
																showAccordian[
																	idx
																]
																	? 'block'
																	: 'none',
														}}
													>
														<div className='rationale-label'>
															Rationale
														</div>
														<div className='rationale-value'>
															{!isNil(
																get(
																	prediction,
																	[
																		'rationale',
																	],
																	''
																)
															) &&
															!isEmpty(
																get(
																	prediction,
																	[
																		'rationale',
																	],
																	''
																)
															) ? (
																<Tooltip
																	content={get(
																		prediction,
																		[
																			'rationale',
																		],
																		'--'
																	)}
																	disabled={
																		size(
																			get(
																				prediction,
																				[
																					'rationale',
																				],
																				'--'
																			)
																		) < 180
																	}
																	placement='bottom'
																	visibleArrow={
																		false
																	}
																>
																	<div
																		style={{
																			overflow:
																				'hidden',
																			textOverflow:
																				'ellipsis',
																			webkitBoxOrient:
																				'vertical',
																			webkitLineClamp:
																				'2',
																			display:
																				'-webkit-box',
																		}}
																	>
																		{get(
																			prediction,
																			[
																				'rationale',
																			],
																			'--'
																		)}
																	</div>
																</Tooltip>
															) : (
																'--'
															)}
														</div>
													</div>
												</div>
											);
										}
									)}
								</div>
							</Layout.Col>
						</Layout.Row>
					</Layout.Col>

					<Layout.Col span='8' className='clinical-comments-section'>
						<span className='riskassessment-clinical-comments-label'>
							Clinical Comments
						</span>
						<NotesContainer
							notes={notes || []}
							variant='cfDNA'
							addNoteLoading={
								addDiagnosticFindingClinicalNoteLoading || false
							}
							addNoteError={
								addDiagnosticFindingClinicalNoteError || null
							}
							addNote={addNewDiagnosticFindingClinicalNote}
							sourceUUID={uuid || null}
							addNoteModalTitle='New Comment — Risk Assessment'
							emptyMessageClass='empty-clinical-comments-msg'
							isShowListIcon={false}
							hasRBACAddComment={true}
							RBACAddCommentAction={
								CARE_PATIENTS_ENTER_TEST_RESULT
							}
						/>
					</Layout.Col>
				</Layout.Row>
			</Form>
		</Fragment>
	);
};

export default ViewRiskAssessmentPredictions;
