//React & Redux
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

//Images
import exclamation_img from '../../assets/exclamation.png';

//Lodash
import get from 'lodash/get';
import _ from 'lodash';

//Actions & Services
import { deletepatient, resetLastDeletePatient } from '../../actions/patients';
import { getencounters } from '../../actions/encounter';

//UI Libraries
import PropTypes from 'prop-types';
import arrowGenerator from '../../utilsTooltip';
import {
	Checkbox,
	Loading,
	Notification,
	Button,
	Input,
	Dialog,
} from 'gm-element-react';

//Other Libraries
import moment from 'moment';
import classnames from 'classnames';

//Components
import Tooltip from '../Common/Tooltip';
import Textarea from '../Common/controls/Textarea/Textarea';

//Styles
import './PatientDeleteDialog.css';

class PatientDeleteDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: false,
			reason: '',
			accept: false,
			arrowRef: null,
		};
	}

	componentDidUpdate(prevProps) {

		const currentDeleteStatus = _.get(this.props, ['lastdeletedpatient', 'patientdelete'], false);
		const prevDeleteStatus = _.get(prevProps, ['lastdeletedpatient', 'patientdelete'], false);
		
		if (currentDeleteStatus !== prevDeleteStatus && currentDeleteStatus) {
			setTimeout(() => {
				this.setState({
					deletingPatientProgress: false,
				});
				this.notify(
					'success',
					'Success',
					'Patient has been deleted successfully'
				);
				this.props.history.push('/app/patients/');
				this.props.resetLastDelete();
			}, 1000);
		}
	}

	notify(type, title, message) {
		Notification({
			title: title,
			message: message,
			type: type,
		});
	}

	validate() {
		let isInvalidReason = false;
		let isInvalidImpectArea = false;

		const reason = get(this, 'state.reason', '');
		const accept = get(this, 'state.accept', false);

		if (reason.trim().length === 0) {
			isInvalidReason = true;
		}
		if (accept === false) {
			isInvalidImpectArea = true;
		}
		this.setState(
			this.updateObject(this.state, {
				isInvalidReason: isInvalidReason,
				isInvalidImpectArea: isInvalidImpectArea,
			})
		);
		return !isInvalidImpectArea && !isInvalidReason;
	}

	deletePatientRecord() {
		const reason = get(this, 'state.reason', '');
		const accept = get(this, 'state.accept', false);

		if (reason.trim().length !== 0 && accept === true && this.validate()) {
			const hasUpcomingAppointments =
				get(this.props, 'encounter_list.data', [])
					.map((e, i) => {
						const isUpcoming =
							get(e, 'visit_status', '') == 'booked' &&
							get(e, 'type', '') != 'lab'
								? true
								: false;
						return isUpcoming ? true : false;
					})
					.indexOf(true) > -1;

			if (hasUpcomingAppointments) {
				this.setState(this.updateObject(this.state, { error: true }));
			} else {
				const data = {
					data: {
						user_id: this.props.patient.id,
						user_uuid: this.props.patient.uuid,
						reason: this.state.reason,
					},
				};
				this.props.deletepatient(data);
				this.setState({
					deletingPatientProgress: true,
				});
			}
		}
	}

	handleReasonChange(val) {
		this.setState(this.updateObject(this.state, { reason: val }));
	}

	handleImpactFieldChange = (name) => (event) => {
		this.setState(this.updateObject(this.state, { [name]: event }));
	};

	handleCancelPatient = () => {
		this.setState(
			this.updateObject(this.state, {
				error: false,
				reason: '',
				accept: false,
				isInvalidImpectArea: false,
				isInvalidReason: false,
			})
		);
	};

	updateObject = (oldState, newObject) => {
		return {
			...oldState,
			...newObject,
		};
	};

	render() {
		const { reason, accept, isInvalidImpectArea, isInvalidReason, error } =
			this.state;
		const { patient } = this.props;
		const { classes } = this.props;

		const isValidated =
			!_.isEmpty(get(this.state, 'reason', '')) &&
			this.state.accept === true;

		let errorSummary = '';
		if (error) {
			errorSummary = (
				<div className='error-summary'>
					<img src={exclamation_img} alt='' className='error-image' />
					{
						'This patient cannot be deleted because there is a booked appointment.'
					}
				</div>
			);
		}

		const patientButtonClass = isValidated
			? 'delete-patient'
			: 'delete-patient disabled';
		const deleteButton = (
			<Button
				onClick={(evt) => this.deletePatientRecord()}
				className={classnames(patientButtonClass)}
			>
				Delete Patient
			</Button>
		);

		const deleteButtonTooltip = (
			<Tooltip
				content={
					'Please fill reason for deletion and confirm by checking the checkbox'
				}
				placement='top'
				disabled={isValidated}
			>
				{deleteButton}
			</Tooltip>
		);

		let deleteButtonComponent = null;
		if (!isValidated) {
			deleteButtonComponent = deleteButtonTooltip;
		} else {
			deleteButtonComponent = deleteButton;
		}

		return (
			<Dialog
				customClass='PatientDeleteDialog'
				title={
					<div className='header'>
						<div className='title'>
							<b>{'Deleting'}</b>
						</div>
						<div className='error-title'>
							<b>
								{get(patient, 'first_name', '')}{' '}
								{get(patient, 'last_name', '')}
							</b>
							&nbsp;
							<img
								src={exclamation_img}
								alt=''
								style={{ height: '1.2vw', marginTop: '-4px' }}
							/>
						</div>
					</div>
				}
				modal='true'
				closeOnPressEscape='true'
				closeOnClickModal='true'
				showClose={false}
				visible={get(this, 'props.delete_dialog', false)}
			>
				<Dialog.Body>
					<Loading
						loading={get(
							this,
							'state.deletingPatientProgress',
							false
						)}
						className='report_loader'
					>
						{errorSummary}

						<div className='clearfix'>
							<span className='float-left'>{patient.email}</span>
							<span className='float-right encounters'>
								<b>
									{get(
										this.props,
										'encounter_list.data.length',
										'0'
									)}
								</b>{' '}
								{'Total encounters'}
							</span>
						</div>
						<div className='dob'>
							{get(patient, 'dob') && get(patient, 'dob') != null
								? moment(get(patient, 'dob')).format(
										'MM/DD/YYYY'
								  )
								: ''}
						</div>
						<div>{patient.referral_program}</div>
						<br />
						<label style={{ fontWeight: 'normal' }}>
							{'Define the reason for deletion'}
						</label>
						<Textarea
							name='reason'
							id='reason'
							rows={4}
							trim={true}
							className='reason-field'
							placeholder='Type the reason here'
							value={reason}
							onChange={(val) => this.handleReasonChange(val)}
							onBlur={(val) => this.handleReasonChange(val)}
						/>
						<br />

						<Checkbox
							checked={accept}
							onChange={this.handleImpactFieldChange('accept')}
							value='accept'
							className='chk-confirm-delete-patient'
						>
							By checking this box, I confirm that I am deleting
							this patient, and I understand that I will no longer
							be able to perform the following actions on this
							patient
						</Checkbox>
						<ul className='impact-area-summary'>
							<li>{'View/edit patient information'}</li>
							<li>
								{'Schedule appointments or create lab orders'}
							</li>
							<li>{'View/edit billing information'}</li>
							<li>
								{'Be able to view this patient in report(s)'}
							</li>
						</ul>
					</Loading>
				</Dialog.Body>
				<Dialog.Footer>
					<Button
						onClick={this.props.onCancel}
						className={classnames('cancel-patient')}
					>
						Cancel
					</Button>
					{deleteButtonComponent}
				</Dialog.Footer>
			</Dialog>
		);
	}
}

const mapStateToProps = (state, props) => ({
	encounter_list: state.encounterlist.encounter,
	lastdeletedpatient: state.deletepatient,
});

const mapDispatchToProps = (dispatch) => ({
	deletepatient: (data) => dispatch(deletepatient(data)),
	getencouter: (pid) => dispatch(getencounters(pid)),
	resetLastDelete: () => dispatch(resetLastDeletePatient()),
});

PatientDeleteDialog.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PatientDeleteDialog);
