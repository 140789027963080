import React, { useState, useEffect } from 'react';
import UploadNewDocument from '../../../V2/Documents/UploadNewDocument';
import ReactSVG from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { LoadDocumentTypesForPatient } from '../../../../../actions/documents';
import uploadIcon from './../../../../../assets/upload.svg';
import fileIcon from './../../../../../assets/file.svg';
import xIcon from './../../../../../assets/exit-alert.svg';
import Button from '../../../../Common/Button';
import './DocumentUpload.scss';

const formulateDocTypes = (documentTypes, noUnassignedFlag) => {
	return Array.isArray(documentTypes)
		? documentTypes
				.filter(
					({ key }) =>
						key !== 'visit_documentation' &&
						!(noUnassignedFlag && key === 'unassigned')
				)
				.map(({ key, display_name, subtypes }) => ({
					value: key,
					label: display_name,
					children:
						Array.isArray(subtypes) && subtypes.length > 0
							? subtypes.map((subType) => ({
									value: subType.key,
									label: subType.display_name,
							  }))
							: undefined,
				}))
		: [];
};

export const DocumentUpload = ({ setFieldValue }) => {
	const dispatch = useDispatch();
	const documentTypesForPatient = useSelector(
		(state) => state.documents.documentTypesForPatient || []
	);
	const documentTypesForPatientLoading = useSelector(
		(state) => state.documents.documentTypesForPatientLoading
	);
	const [showUpload, setShowUpload] = useState(false);
	const [fileNames, setFileNames] = useState([]);
	const [formDataArray, setFormDataArray] = useState([]);

	useEffect(() => {
		dispatch(LoadDocumentTypesForPatient());
	}, [dispatch]);

	const handleUploadDocument = (
		documentType,
		description,
		file,
		documentSide
	) => {
		const formData = new FormData();
		if (documentType) formData.append('document_type', documentType);
		if (description) formData.append('description', description);
		if (file) formData.append('document_file', file);
		if (documentSide) formData.append('document_side', documentSide);
		setShowUpload(false);
		setFormDataArray((prevFormDataArray) => [
			...prevFormDataArray,
			formData,
		]);
		setFieldValue('documentData', [...formDataArray, formData]);
		setFileNames((prevFileNames) => [...prevFileNames, file?.name]);
	};

	const removeFile = (fileName) => {
		setFileNames((prevFileNames) =>
			prevFileNames.filter((name) => name !== fileName)
		);
		setFormDataArray((prevFormDataArray) =>
			prevFormDataArray.filter(
				(formData) => formData.get('document_file').name !== fileName
			)
		);
	};

	const showUploadModal = () => setShowUpload(true);

	return (
		<div className='documents'>
			<h3>Documents</h3>
			<p>You may upload up to three patient documents</p>
			<div
				className={`upload-document-filename-row ${
					fileNames.length > 0 ? 'has-file' : ''
				}`}
			>
				<div className='two-col-div'>
					{fileNames.map((fileName, index) => (
						<div
							key={index}
							className='upload-document-filename-content'
						>
							<ReactSVG
								className='react-svg-icon'
								src={xIcon}
								onClick={() => removeFile(fileName)}
							/>
							<ReactSVG
								className='upload-document-filename-icon'
								src={fileIcon}
							/>
							<div className='upload-document-filename-text'>
								{fileName}
							</div>
						</div>
					))}
				</div>
			</div>
			<Button
				onClick={showUploadModal}
				inverted
				disabled={fileNames.length === 3}
			>
				<span className='button-content'>
					<ReactSVG className='menuItemIconActive' src={uploadIcon} />
					<span className='upload-button-text'>Upload Document</span>
				</span>
			</Button>

			{showUpload && (
				<UploadNewDocument
					documentTypes={formulateDocTypes(documentTypesForPatient)}
					showEncounter={false}
					documentTypesLoading={documentTypesForPatientLoading}
					fileUploading={false}
					getDocumentTypesForPatient={() =>
						dispatch(LoadDocumentTypesForPatient())
					}
					hideDocumentUpload={() => setShowUpload(false)}
					uploadDocument={handleUploadDocument}
				/>
			)}
		</div>
	);
};
