//React & Redux
import React, { useState, useEffect } from 'react';

//Hooks
import { usePrevious } from '../../../hooks/usePrevious';

//Utils
import { CARE_PATIENTS_ADD_PATIENT_NOTES } from '../../../Utils/permissionUtils';
import { getNotesEnum } from './../../../utils.js';

//Components
import RBAC from '../../RBAC';
import AddNote from './AddNote';
import NotesList from './NotesList';

const NotesTab = (props) => {
	const { addNoteProps, notesList, isExternalUser, noteCounts, currentLoggedInUser } = props;

	const { loadingNotes = false, notesError = false } = addNoteProps;

	const { data: notes = [] } = notesList;

	const [currentNoteTypeAdd, setCurrentNoteTypeAdd] = useState(
		isExternalUser ? 'provider' : 'internal'
	);
	const [currentNoteTypeView, setCurrentNoteTypeView] = useState(
		isExternalUser ? 'provider' : 'internal'
	);

	const prevNoteLoading = usePrevious(loadingNotes);

	const notesEnum = getNotesEnum(isExternalUser);

	useEffect(() => {
		if (prevNoteLoading && !loadingNotes) {
			if (!notesError) {
				setCurrentNoteTypeView(currentNoteTypeAdd);
			}
		}
	}, [loadingNotes]);

	const getFilteredNotes = () => {
		if (Array.isArray(notes)) {
			return notes.filter((note) => note.type === currentNoteTypeView);
		} else return [];
	};

	return (
		<>
			<RBAC
				action={CARE_PATIENTS_ADD_PATIENT_NOTES}
				yes={
					<AddNote
						{...addNoteProps}
						currentNoteTypeAdd={currentNoteTypeAdd}
						setCurrentNoteTypeAdd={setCurrentNoteTypeAdd}
						currentNoteTypeView={currentNoteTypeView}
						notesEnum={notesEnum}
					/>
				}
			/>
			<NotesList
				{...addNoteProps}
				notes={getFilteredNotes()}
				currentNoteTypeView={currentNoteTypeView}
				setCurrentNoteTypeView={setCurrentNoteTypeView}
				notesEnum={notesEnum}
				noteCounts={noteCounts}
				currentLoggedInUser={currentLoggedInUser}
			/>
		</>
	);
};

export default NotesTab;
