//React & Redux
import React, { useState } from 'react';
import classnames from 'classnames';

//Lodash
import _ from 'lodash';

//Utils
import {
	createEnumsOptions,
	disabledTestRecommendedSelection,
} from '../Common/utilsfunctions';
import { TestRecommendationEnums } from '../../../../../Utils/encounterUtils';

//UI Libraries
import { Button, Loading } from 'gm-element-react';

//Components
import { EncounterRadioGroupField } from '../Common/EncounterRadioGroupField';
import CreateTestOrderModalContainer from '../../TestOrders/CreateTestOrderModalContainer';
import PreAuthTestOrderModalContainer from '../../TestOrders/PreAuthTestOrderModalContainer';

const EditTestOrderSegment = (props) => {
	const {
		encounter,
		cancel,
		saveCCIntakeEncounter,
		getICDCodes,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		enqueueSnackbar,
		ordering_physicianenums,
		encounterschema,
		LoadTestOrderPopupTestEnum,
		nonlegacytestOrderPopupTestEnums,
		nonlegacytestOrderPopupTestEnumsLoading,
		orderrequeststatuscascadeenums,
		patientdetail,
		sampletypeenums,
		patientbillingmethodenums,
		mloOrderStatusEnums,
		saveTestOrderRequest,
		testOrderNumber,
		user,
		LoadAlternateTestEnum,
		nonlegacyalternatetestnameenums,
		preauthorizationsenums,
		createPreauthorizeTestOrder,
		preauthdecisionenums,
		preauthdispatchstatusenums,
	} = props;

	const tempFormData = {
		test_recommended: _.get(encounter, ['test_recommended'], ''),
	};

	const [formData, setFormData] = useState(tempFormData);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [showChargeModal, setShowChargeModal] = useState(false);
	const [showPreAuthTestModal, setShowPreAuthTestModal] = useState(false);
	const [touched, setTouched] = useState({});

	const validateForm = (formdata) => {
		const errors = {};
		if (formdata && _.isEmpty(formdata['test_recommended'])) {
			errors['test_recommended'] = 'Required';
		}
		return errors;
	};

	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		const stateErrors = validateForm(stateData);
		const touched = touchField(key);
		setErrors(stateErrors);
		setTouched(touched);
		setFormData(stateData);
	};

	const handleContinue = () => {
		const test_recommended = _.get(formData, ['test_recommended'], '');
		if (test_recommended === 'Yes') {
			setShowChargeModal(true);
		} else if (test_recommended === 'PA') {
			setShowPreAuthTestModal(true);
		}
	};

	const touchField = (fieldName) => {
		const _touched = { ...touched };
		_touched[fieldName] = true;
		return _touched;
	};

	const touchAll = () => {
		const touched = _.mapValues(formData, (o) => {
			return true;
		});
		return touched;
	};

	const SaveForm = () => {
		const errors = validateForm(formData);
		const touched = touchAll();
		if (_.isEmpty(errors)) {
			const updatedformdata = _.mapValues(
				formData,
				function (value, key) {
					if (value == 'empty') return '';
					return value;
				}
			);
			setLoading(true);
			saveCCIntakeEncounter(encounter, updatedformdata)
				.then((result) => {
					setTimeout(() => {
						setLoading(false);
						cancel();
					}, 1000);
				})
				.catch((error) => {
					setLoading(false);
				});
		} else {
			setErrors(errors);
			setTouched(touched);
		}
	};

	const hideModal = () => {
		const formdata = {
			test_recommended: '',
		};
		setFormData(formdata);
		setShowChargeModal(false);
	};

	const hidePreAuthTestModal = () => {
		const formdata = {
			test_recommended: '',
		};

		setFormData(formdata);
		setShowPreAuthTestModal(false);
	};

	return (
		<Loading loading={loading}>
			<div>
				<div
					className={classnames(
						'segment-row',
						disabledTestRecommendedSelection(
							_.get(encounter, ['visit_status'], '')
						)
							? 'disable-testrecommended-selection'
							: ''
					)}
				>
					<div className='segment-label-row'>
						<div className='segment-5cols'>
							<div className='encounter-field-header edit-recommend-test-header'>
								Do you recommend test(s) for this patient?
							</div>
						</div>
					</div>
					<div
						className='segment-value-row'
						style={{ marginTop: '16px' }}
					>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterRadioGroupField
									name={'test_recommended'}
									value={_.get(
										formData,
										['test_recommended'],
										''
									)}
									options={createEnumsOptions(
										{
											TestRecommendationEnums:
												TestRecommendationEnums,
										},
										['TestRecommendationEnums'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('test_recommended', value)
									}
									istouched={_.get(
										touched,
										['test_recommended'],
										true
									)}
									error={_.get(errors, ['test_recommended'])}
									disabled={disabledTestRecommendedSelection(
										_.get(encounter, ['visit_status'], '')
									)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-footer-container'>
					<div className='cancel-btn-link'>
						<Button onClick={cancel}>Cancel</Button>
					</div>
					<div className='save-btn-link'>
						{
							//EHR-4279
							_.get(formData, ['test_recommended'], '') ==
								'Yes' ||
							_.get(formData, ['test_recommended'], '') ==
								'PA' ? (
								<Button
									style={{ float: 'right' }}
									disabled={
										!_.isEmpty(validateForm(formData))
									}
									onClick={handleContinue}
								>
									Continue
								</Button>
							) : (
								<Button
									style={{ float: 'right' }}
									disabled={
										!_.isEmpty(validateForm(formData))
									}
									onClick={SaveForm}
								>
									Save
								</Button>
							)
						}
					</div>
				</div>
			</div>
			{showChargeModal && (
				<div className='popupModal'>
					<CreateTestOrderModalContainer
						hideModal={hideModal}
						getICDCodes={getICDCodes}
						icdCodes={icdCodes}
						icdCodesLoading={icdCodesLoading}
						icdCodesError={icdCodesError}
						icdCodesPlacement={icdCodesPlacement}
						enqueueSnackbar={enqueueSnackbar}
						selectedICDCodes={_.get(
							encounter,
							'medical_codes',
							null
						)}
						ordering_physicianenums={ordering_physicianenums}
						labenums={_.get(encounterschema, ['lab'], [])}
						LoadTestOrderPopupTestEnum={LoadTestOrderPopupTestEnum}
						nonlegacytestOrderPopupTestEnums={
							nonlegacytestOrderPopupTestEnums
						}
						nonlegacytestOrderPopupTestEnumsLoading={
							nonlegacytestOrderPopupTestEnumsLoading
						}
						orderrequeststatuscascadeenums={
							orderrequeststatuscascadeenums
						}
						encounter={encounter}
						patientdetail={patientdetail}
						sampletypeenums={sampletypeenums}
						patientbillingmethodenums={patientbillingmethodenums}
						mloOrderStatusEnums={mloOrderStatusEnums}
						saveTestOrderRequest={saveTestOrderRequest}
						cancel={cancel}
						testOrderNumber={testOrderNumber}
						user={user}
					/>
				</div>
			)}
			{showPreAuthTestModal && (
				<div className='popupModal'>
					<PreAuthTestOrderModalContainer
						testRequestMode='create'
						hidePreAuthTestModal={hidePreAuthTestModal}
						getICDCodes={getICDCodes}
						icdCodes={icdCodes}
						icdCodesLoading={icdCodesLoading}
						icdCodesError={icdCodesError}
						icdCodesPlacement={icdCodesPlacement}
						enqueueSnackbar={enqueueSnackbar}
						selectedICDCodes={_.get(
							encounter,
							'medical_codes',
							null
						)}
						labenums={_.get(encounterschema, ['lab'], [])}
						TestEnum={LoadTestOrderPopupTestEnum}
						LoadTestOrderPopupTestEnum={LoadTestOrderPopupTestEnum}
						nonlegacytestOrderPopupTestEnums={
							nonlegacytestOrderPopupTestEnums
						}
						LoadAlternateTestEnum={LoadAlternateTestEnum}
						nonlegacyalternatetestnameenums={
							nonlegacyalternatetestnameenums
						}
						encounter={encounter}
						patientdetail={patientdetail}
						dispatchstatusenums={_.get(
							preauthorizationsenums,
							['dispatch_status'],
							[]
						)}
						preauthorizationdecisionenums={_.get(
							preauthorizationsenums,
							['preauthorization_decision'],
							[]
						)}
						createPreauthorizeTestOrder={
							createPreauthorizeTestOrder
						}
						closeEditTestOrder={cancel}
						preauthdecisionenums={preauthdecisionenums}
						preauthdispatchstatusenums={preauthdispatchstatusenums}
					/>
				</div>
			)}
		</Loading>
	);
};

export default EditTestOrderSegment;
