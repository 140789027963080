//React & Redux
import React, { useState, Fragment } from 'react';

//Lodash
import _ from 'lodash';

//Utils
import { createEnumsOptions } from '../Common/utilsfunctions';

//UI Libraries
import { Button, Loading } from 'gm-element-react';

//Components
import { EncounterSelectField } from '../Common/EncounterSelectField';

const EditPGXSegment = (props) => {
	const { encounter, cancel, saveGroupSessionEncounter, groupsessionschema } =
		props;

	const tempFormData = {
		pharmd: _.get(encounter, ['pharmd'], ''),
		drug_interaction: _.get(encounter, ['drug_interaction'], ''),
		no_of_interactions: _.get(encounter, ['no_of_interactions'], ''),
		drug_contraindications: _.get(
			encounter,
			['drug_contraindications'],
			''
		),
		drug_dosage_adjustment_recommended: _.get(
			encounter,
			['drug_dosage_adjustment_recommended'],
			''
		),
	};

	const [formData, setFormData] = useState(tempFormData);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	const validateForm = (formdata) => {
		const errors = {};
		if (
			formdata['drug_interaction'] == 'yes' &&
			_.get(formdata, ['no_of_interactions', 'length'], 0) == 0
		) {
			errors['no_of_interactions'] = '# of Interaction is required';
		}
		return errors;
	};

	const onFormChange = (key, value) => {
		const stateData = { ...formData };
		stateData[key] = value;
		const errors = validateForm(stateData);
		setErrors(errors);
		setFormData(stateData);
	};

	const saveForm = () => {
		const errors = validateForm(formData);
		if (_.isEmpty(errors)) {
			const updatedformdata = _.mapValues(
				formData,
				function (value, key) {
					if (value == 'empty') return '';
					return value;
				}
			);
			setLoading(true);
			saveGroupSessionEncounter(encounter, updatedformdata)
				.then((result) => {
					setTimeout(() => {
						setLoading(false);
						cancel();
					}, 1000);
				})
				.catch((error) => {
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	};

	return (
		<Loading loading={loading}>
			<Fragment>
				<div className='segment-row'>
					<div className='segment-label-row'>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								PharmD Identification
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Drug Interaction
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								# of Interaction(s)
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Drug/Gene Interaction
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-field-header'>
								Drug Dos Adj Recommended
							</div>
						</div>
					</div>
					<div className='segment-value-row'>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(formData, ['pharmd'], '')}
									name='pharmd'
									options={createEnumsOptions(
										groupsessionschema,
										['pharmd'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('pharmd', value)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['drug_interaction'],
										''
									)}
									name='drug_interaction'
									options={createEnumsOptions(
										groupsessionschema,
										['drug_interaction'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange('drug_interaction', value)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['no_of_interactions'],
										''
									)}
									name='no_of_interactions'
									options={createEnumsOptions(
										groupsessionschema,
										['no_of_interactions'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'no_of_interactions',
											value
										)
									}
									error={_.get(
										errors,
										['no_of_interactions'],
										''
									)}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['drug_contraindications'],
										''
									)}
									name='drug_contraindications'
									options={createEnumsOptions(
										groupsessionschema,
										['drug_contraindications'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'drug_contraindications',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
						<div className='segment-col'>
							<div className='encounter-input-section'>
								<EncounterSelectField
									value={_.get(
										formData,
										['drug_dosage_adjustment_recommended'],
										''
									)}
									name='drug_dosage_adjustment_recommended'
									options={createEnumsOptions(
										groupsessionschema,
										['drug_dosage_adjustment_recommended'],
										'key',
										'display_name',
										'key'
									)}
									onChange={(value) =>
										onFormChange(
											'drug_dosage_adjustment_recommended',
											value
										)
									}
									typeahead={true}
									clearable={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='segment-footer-container'>
					<div className='cancel-btn-link'>
						<Button onClick={cancel}>Cancel</Button>
					</div>
					<div className='save-btn-link'>
						<Button style={{ float: 'right' }} onClick={saveForm}>
							Save
						</Button>
					</div>
				</div>
			</Fragment>
		</Loading>
	);
};

export default EditPGXSegment;
