//React & Redux
import React, { useEffect } from 'react';
import TotalScreenedTable from './DashboardTables/TotalScreenedTable';
import AppointmentScheduleTable from './DashboardTables/AppointmentScheduleTable';
import CriteriaNotMetTable from './DashboardTables/CriteriaNotMetTable';
import HCRAPendingTable from './DashboardTables/HCRAPendingTable';
import MeetsCriteriaTable from './DashboardTables/MeetsCriteriaTable';
import ConsiderCounseling from './DashboardTables/ConsiderCounseling';

import _ from 'lodash';

const DashboardList = (props) => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	const renderHtml = () => {
		const {
			selectedCard,
			riseAnalyticsData,
			handlePagination,
			handleResultsPerPageChange,
			selectPatient,
			handleSort,
			CardPageData,
		} = props;
		switch (selectedCard) {
			case 'complete':
				return (
					<TotalScreenedTable
						selectedCard={selectedCard}
						riseAnalyticsData={riseAnalyticsData}
						handlePagination={handlePagination}
						handleResultsPerPageChange={handleResultsPerPageChange}
						selectPatient={selectPatient}
						dashboardCardList={getDashboardList('complete')}
						handleSort={handleSort}
						CardPageData={CardPageData}
					/>
				);
			case 'incomplete':
				return (
					<HCRAPendingTable
						selectedCard={selectedCard}
						riseAnalyticsData={riseAnalyticsData}
						handlePagination={handlePagination}
						handleResultsPerPageChange={handleResultsPerPageChange}
						selectPatient={selectPatient}
						dashboardCardList={getDashboardList('incomplete')}
						handleSort={handleSort}
						CardPageData={CardPageData}
					/>
				);
			case 'criteria_met':
				return (
					<MeetsCriteriaTable
						selectedCard={selectedCard}
						riseAnalyticsData={riseAnalyticsData}
						handlePagination={handlePagination}
						handleResultsPerPageChange={handleResultsPerPageChange}
						selectPatient={selectPatient}
						dashboardCardList={getDashboardList('criteria_met')}
						handleSort={handleSort}
						CardPageData={CardPageData}
					/>
				);
			case 'criteria_not_met':
				return (
					<CriteriaNotMetTable
						selectedCard={selectedCard}
						riseAnalyticsData={riseAnalyticsData}
						handlePagination={handlePagination}
						handleResultsPerPageChange={handleResultsPerPageChange}
						selectPatient={selectPatient}
						dashboardCardList={getDashboardList('criteria_not_met')}
						handleSort={handleSort}
						CardPageData={CardPageData}
					/>
				);
			case 'appointments_scheduled':
				return (
					<AppointmentScheduleTable
						selectedCard={selectedCard}
						riseAnalyticsData={riseAnalyticsData}
						handlePagination={handlePagination}
						handleResultsPerPageChange={handleResultsPerPageChange}
						selectPatient={selectPatient}
						dashboardCardList={getDashboardList(
							'appointments_scheduled'
						)}
						handleSort={handleSort}
						CardPageData={CardPageData}
					/>
				);
			case 'consider_counseling':
				return (
					<ConsiderCounseling
						selectedCard={selectedCard}
						riseAnalyticsData={riseAnalyticsData}
						handlePagination={handlePagination}
						handleResultsPerPageChange={handleResultsPerPageChange}
						selectPatient={selectPatient}
						dashboardCardList={getDashboardList(
							'consider_counseling'
						)}
						handleSort={handleSort}
						CardPageData={CardPageData}
					/>
				);

			default:
				return null;
				break;
		}
	};

	const getDashboardList = (selectedCard) => {
		const dashboardCardList = _.get(
			props.riseAnalyticsData,
			[selectedCard],
			[]
		);
		return dashboardCardList;
	};

	return <div className='rise-dashrd-list'>{renderHtml()}</div>;
};

export default DashboardList;
