export const PATIENTS_LOADING = 'PATIENTS_LOADING';
export const PATIENTS_LOADING_COMPLETE = 'PATIENTS_LOADING_COMPLETE';
export const PATIENTS = 'PATIENTS';
export const PATIENTS_ERROR = 'PATIENTS_ERROR';
export const GLOBAL_PATIENT_SEARCH_START = 'GLOBAL_PATIENT_SEARCH_START';
export const GLOBAL_PATIENT_SEARCH_COMPLETED =
	'GLOBAL_PATIENT_SEARCH_COMPLETED';
export const GLOBAL_PATIENT_SEARCH_ERROR = 'GLOBAL_PATIENT_SEARCH_ERROR';
export const GLOBAL_PATIENT_SEARCH_CLEAR = 'GLOBAL_PATIENT_SEARCH_CLEAR';

export const GETTING_PATIENT_DETAIL = 'GETTING_PATIENT_DETAIL';
export const PATIENT_DETAIL = 'PATIENT_DETAIL';
export const PATIENT_DETAIL_ERROR = 'PATIENT_DETAIL_ERROR';
export const EDIT_PATIENT = 'EDIT_PATIENT';
export const DELETE_PATIENT = 'DELETE_PATIENT';
export const RESET_DELETE_PATIENT = 'RESET_DELETE_PATIENT';

export const LOADING_DASHBOARD = 'LOADING_DASHBOARD';
export const DASHBOARD = 'DASHBOARD';
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR';

export const LOADING_NOTES = 'LOADING_NOTES';
export const NOTES = 'NOTES';
export const NOTES_ERROR = 'NOTES_ERROR';

export const LOADING_SUBMIT_NOTES = 'LOADING_SUBMIT_NOTES';
export const SUBMIT_NOTES = 'SUBMIT_NOTES';
export const SUBMIT_NOTES_ERROR = 'SUBMIT_NOTES_ERROR';
export const DELETE_NOTES_ERROR = 'DELETE_NOTES_ERROR';
export const DELETE_NOTES_SUCCESS = 'DELETE_NOTES_SUCCESS';
export const DELETE_LOADING_NOTES = 'DELETE_LOADING_NOTES'

export const ENCOUNTER_DETAIL = 'ENCOUNTER_DETAIL';
export const ENCOUNTER_DETAIL_ERROR = 'ENCOUNTER_DETAIL_ERROR';
export const FETCH_NOTES = 'FETCH_NOTES';
export const AUTHENTICATE = 'AUTHENTICATE';
export const ADD_PATIENT = 'ADD_PATIENT';
export const ADDING_PATIENT_LOADING = 'ADDING_PATIENT_LOADING';
export const GET_REFERRAL_PROGRAMS = 'GET_REFERRAL_PROGRAMS';
export const GETTING_REFERRAL_PROGRAMS = 'GETTING_REFERRAL_PROGRAMS';
export const GET_REFERRAL_PROGRAMS_ERROR = 'GET_REFERRAL_PROGRAMS_ERROR';

export const AFFILIATIONS = 'AFFILIATIONS';

export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const CREATE_LAB_PATIENT_ERROR = 'CREATE_LAB_PATIENT_ERROR';
export const GLOBAL_SEARCH = 'GLOBAL_SEARCH';
export const RESET_GLOBAL_SEARCH = 'RESET_GLOBAL_SEARCH';

export const ADD_ENCOUNTER = 'ADD_ENCOUNTER';
export const ADDING_ENCOUNTER = 'ADDING_ENCOUNTER';
export const ADD_ENCOUNTER_ERROR = 'ADD_ENCOUNTER_ERROR';

export const LOADING_ADD_MANUAL_VISIT_ENCOUNTER =
	'LOADING_ADD_MANUAL_VISIT_ENCOUNTER';
export const ADD_MANUAL_VISIT_ENCOUNTER = 'ADD_MANUAL_VISIT_ENCOUNTER';
export const ADD_MANUAL_VISIT_ENCOUNTER_ERROR =
	'ADD_MANUAL_VISIT_ENCOUNTER_ERROR';

export const GETTING_DASHBOARD_ENCOUNTERS = 'GETTING_DASHBOARD_ENCOUNTERS';
export const DASHBOARD_ENCOUNTERS = 'DASHBOARD_ENCOUNTERS';
export const DASHBOARD_ENCOUNTERS_ERROR = 'DASHBOARD_ENCOUNTERS_ERROR';

export const LOADING_UPCOMING_APPOINTMENTS = 'LOADING_UPCOMING_APPOINTMENTS';
export const UPCOMING_APPOINTMENTS = 'UPCOMING_APPOINTMENTS';
export const UPCOMING_APPOINTMENTS_ERROR = 'UPCOMING_APPOINTMENTS_ERROR';

export const SESSION = 'SESSION';

export const LOADING_ORDER_REQUEST = 'LOADING_ORDER_REQUEST';
export const ORDER_REQUEST = 'ORDER_REQUEST';
export const ORDER_REQUEST_ERROR = 'ORDER_REQUEST_ERROR';

export const EDIT_ENCOUNTER = 'EDIT_ENCOUNTER';
export const EDITING_ENCOUNTER = 'EDITING_ENCOUNTER';
export const EDIT_ENCOUNTER_FAILURE = 'EDIT_ENCOUNTER_FAILURE';
export const INTERVAL = 'INTERVAL';

export const LOADING_GROUP_SESSION_SCHEMA = 'LOADING_GROUP_SESSION_SCHEMA';
export const GROUP_SESSION_SCHEMA = 'GROUP_SESSION_SCHEMA';
export const GROUP_SESSION_SCHEMA_ERROR = 'GROUP_SESSION_SCHEMA_ERROR';

export const LOADING_CC_INTAKE_SCHEMA = 'LOADING_CC_INTAKE_SCHEMA';
export const CC_INTAKE_SCHEMA = 'CC_INTAKE_SCHEMA';
export const CC_INTAKE_SCHEMA_ERROR = 'CC_INTAKE_SCHEMA_ERROR';

export const LOADING_VISITS_SCHEMA = 'LOADING_VISITS_SCHEMA';
export const VISITS_SCHEMA = 'VISITS_SCHEMA';
export const VISITS_SCHEMA_ERROR = 'VISITS_SCHEMA_ERROR';

export const LABS_SCHEMA = 'LABS_SCHEMA';
export const LABS_SCHEMA_ERROR = 'LABS_SCHEMA_ERROR';

export const LOADING_RESEARCH_DATA_SCHEMA = 'LOADING_RESEARCH_DATA_SCHEMA';
export const RESEARCH_DATA_SCHEMA = 'RESEARCH_DATA_SCHEMA';
export const RESEARCH_DATA_SCHEMA_ERROR = 'RESEARCH_DATA_SCHEMA_ERROR';

export const LOADING_VISIT_PROVIDER_ENUM = 'LOADING_VISIT_PROVIDER_ENUM';
export const VISIT_PROVIDER_ENUM = 'VISIT_PROVIDER_ENUM';
export const VISIT_PROVIDER_ENUM_LOADING = 'VISIT_PROVIDER_ENUM_LOADING';
export const VISIT_PROVIDER_ENUM_ERROR = 'VISIT_PROVIDER_ENUM_ERROR';

export const VISIT_PROVIDER_ENUM_SCHEDULING = 'VISIT_PROVIDER_ENUM_SCHEDULING';
export const VISIT_PROVIDER_ENUM_SCHEDULING_LOADING =
	'VISIT_PROVIDER_ENUM_SCHEDULING_LOADING';

export const GETTING_VISIT_CONSULTATION_TYPES =
	'GETTING_VISIT_CONSULTATION_TYPES';
export const VISIT_CONSULTATION_TYPES = 'VISIT_CONSULTATION_TYPES';
export const VISIT_CONSULTATION_TYPES_ERROR = 'VISIT_CONSULTATION_TYPES_ERROR';

export const GETTING_PAYOR_ENUM = 'GETTING_PAYOR_ENUM';
export const PAYOR_ENUMS = 'PAYOR_ENUMS';
export const PAYOR_ENUMS_ERROR = 'PAYOR_ENUMS_ERROR';

export const LOADING_ORDERING_PHYSICIAN = 'LOADING_ORDERING_PHYSICIAN';
export const ORDERING_PHYSICIAN = 'ORDERING_PHYSICIAN';
export const ORDERING_PHYSICIAN_ERROR = 'ORDERING_PHYSICIAN_ERROR';

export const ORDER_REQUEST_ENUM = 'ORDER_REQUEST_ENUM';
export const ORDER_STATUS_ENUM = 'ORDER_STATUS_ENUM';
export const VISIT_STATUS_ENUM = 'VISIT_STATUS_ENUM';
export const SPECIFIC_ENCOUNTER_ENUM_ERROR = 'SPECIFIC_ENCOUNTER_ENUM_ERROR';

export const LOADING_SEND_PROVIDER_REPORTS = 'LOADING_SEND_PROVIDER_REPORTS';
export const SEND_PROVIDER_REPORTS = 'SEND_PROVIDER_REPORTS';
export const SEND_PROVIDER_REPORTS_ERROR = 'SEND_PROVIDER_REPORTS_ERROR';

export const GET_ALL_REPORT_STATUS = 'GET_ALL_REPORT_STATUS';
export const GETTING_DASHBOARD_PATIENTS = 'GETTING_DASHBOARD_PATIENTS';
export const DASHBOARD_PATIENTS = 'DASHBOARD_PATIENTS';

export const GETTING_ACTIVE_CCS = 'GETTING_ACTIVE_CCS';
export const GOT_ACTIVE_CCS = 'GOT_ACTIVE_CCS';
export const GOT_ACTIVE_CCS_ERROR = 'GOT_ACTIVE_CCS_ERROR';

export const GETTING_ACTIVE_GCAS = 'GETTING_ACTIVE_GCAS';
export const GOT_ACTIVE_GCAS = 'GOT_ACTIVE_GCAS';
export const GOT_ACTIVE_GCAS_ERROR = 'GOT_ACTIVE_GCAS_ERROR';

export const CC_GCA_ASSIGNMENT_START = 'CC_GCA_ASSIGNMENT_START';
export const CC_GCA_ASSIGNMENT_SUCCESS = 'CC_GCA_ASSIGNMENT_SUCCESS';
export const CC_GCA_ASSIGNMENT_ERROR = 'CC_GCA_ASSIGNMENT_ERROR';

export const GETTING_CC_ASSIGNMENTS = 'GETTING_CC_ASSIGNMENTS';
export const GOT_CC_ASSIGNMENTS = 'GOT_CC_ASSIGNMENTS';
export const GOT_CC_ASSIGNMENTS_ERROR = 'GOT_CC_ASSIGNMENTS_ERROR';

export const GETTING_GCA_ASSIGNMENTS = 'GETTING_GCA_ASSIGNMENTS';
export const GOT_GCA_ASSIGNMENTS = 'GOT_GCA_ASSIGNMENTS';
export const GOT_GCA_ASSIGNMENTS_ERROR = 'GOT_GCA_ASSIGNMENTS_ERROR';

export const GETTING_PROVIDER_AFFILIATIONS = 'GETTING_PROVIDER_AFFILIATIONS';
export const PROVIDER_AFFILIATIONS = 'PROVIDER_AFFILIATIONS';
export const PROVIDER_AFFILIATIONS_ERROR = 'PROVIDER_AFFILIATIONS_ERROR';

export const GETTING_BILLING_ENCOUNTERS = 'GETTING_BILLING_ENCOUNTERS';
export const BILLING_ENCOUNTERS = 'BILLING_ENCOUNTERS';
export const BILLING_ENCOUNTERS_ERROR = 'BILLING_ENCOUNTERS_ERROR';

export const LOADING_REFERRAL_CHANNELS = 'LOADING_REFERRAL_CHANNELS';
export const REFERRAL_CHANNELS = 'REFERRAL_CHANNELS';
export const REFERRAL_CHANNELS_ERROR = 'REFERRAL_CHANNELS_ERROR';

export const BILLING_AFFILIATIONS = 'BILLING_AFFILIATIONS';

export const LOADING_BILLING_DASHBOARD_COUNTS =
	'LOADING_BILLING_DASHBOARD_COUNTS';
export const BILLING_DASHBOARD_COUNTS = 'BILLING_DASHBOARD_COUNTS';
export const BILLING_DASHBOARD_COUNTS_ERROR = 'BILLING_DASHBOARD_COUNTS_ERROR';

export const LOADING_BILLING_ENCOUNTER_DETAIL =
	'LOADING_BILLING_ENCOUNTER_DETAIL';
export const BILLING_ENCOUNTER_DETAIL = 'BILLING_ENCOUNTER_DETAIL';
export const BILLING_ENCOUNTER_DETAIL_ERROR = 'BILLING_ENCOUNTER_DETAIL_ERROR';

export const LOADING_BILLING_PATIENT_DETAIL = 'LOADING_BILLING_PATIENT_DETAIL';
export const BILLING_PATIENT_DETAIL = 'BILLING_PATIENT_DETAIL';
export const BILLING_PATIENT_DETAIL_ERROR = 'BILLING_PATIENT_DETAIL_ERROR';

export const LOADING_ENCOUNTER_BILLING_OBJECT =
	'LOADING_ENCOUNTER_BILLING_OBJECT';
export const ENCOUNTER_BILLING_OBJECT = 'ENCOUNTER_BILLING_OBJECT';
export const ENCOUNTER_BILLING_OBJECT_ERROR = 'ENCOUNTER_BILLING_OBJECT_ERROR';

export const LOADING_BILLING_ENCOUNTER_ENUMS =
	'LOADING_BILLING_ENCOUNTER_ENUMS';
export const BILLING_ENCOUNTER_ENUMS = 'BILLING_ENCOUNTER_ENUMS';
export const BILLING_ENCOUNTER_ENUMS_ERROR = 'BILLING_ENCOUNTER_ENUMS_ERROR';

export const SAVE_ENCOUNTER_DATA = 'SAVE_ENCOUNTER_DATA';
export const SAVE_ENCOUNTER_BILLING_DATA = 'SAVE_ENCOUNTER_BILLING_DATA';
export const SAVING_ENCOUNTER_BILLING_DATA = 'SAVING_ENCOUNTER_BILLING_DATA';
export const SAVING_ECOUNTER_BILLING_DATA_ERROR =
	'SAVING_ECOUNTER_BILLING_DATA_ERROR';

export const LOADING_PARTNER_INVOICE_STATUS = 'LOADING_PARTNER_INVOICE_STATUS';
export const PARTNER_INVOICE_STATUS = 'PARTNER_INVOICE_STATUS';
export const PARTNER_INVOICE_STATUS_ERROR = 'PARTNER_INVOICE_STATUS_ERROR';

export const LOADING_EXPORT_BILLINGS_REPORT = 'LOADING_EXPORT_BILLINGS_REPORT';
export const EXPORT_BILLINGS_REPORT = 'EXPORT_BILLINGS_REPORT';
export const EXPORT_BILLINGS_REPORT_ERROR = 'EXPORT_BILLINGS_REPORT_ERROR';

export const ENCOUNTER_BILLING_DATA = 'ENCOUNTER_BILLING_DATA';
export const LOADING_ENCOUNTER_BILLING_DATA = 'LOADING_ENCOUNTER_BILLING_DATA';
export const ENCOUNTER_BILLING_DATA_ERROR = 'ENCOUNTER_BILLING_DATA_ERROR';

export const ENHANCED_SEARCH_RESULTS = 'ENHANCED_SEARCH_RESULTS';

export const LOADING_ENHANCED_SEARCH_AFFILIATIONS =
	'LOADING_ENHANCED_SEARCH_AFFILIATIONS';
export const ENHANCED_SEARCH_AFFILIATIONS = 'ENHANCED_SEARCH_AFFILIATIONS';
export const ENHANCED_SEARCH_AFFILIATIONS_ERROR =
	'ENHANCED_SEARCH_AFFILIATIONS_ERROR';

export const LOADING_ENHANCED_SEARCH_ORDERING_PHYSICIANS =
	'LOADING_ENHANCED_SEARCH_ORDERING_PHYSICIANS';
export const ENHANCED_SEARCH_ORDERING_PHYSICIANS =
	'ENHANCED_SEARCH_ORDERING_PHYSICIANS';
export const ENHANCED_SEARCH_ORDERING_PHYSICIANS_ERROR =
	'ENHANCED_SEARCH_ORDERING_PHYSICIANS_ERROR';

export const LOADING_ENHANCED_SEARCH_VISIT_PROVIDERS =
	'LOADING_ENHANCED_SEARCH_VISIT_PROVIDERS';
export const ENHANCED_SEARCH_VISIT_PROVIDERS =
	'ENHANCED_SEARCH_VISIT_PROVIDERS';
export const ENHANCED_SEARCH_VISIT_PROVIDERS_ERROR =
	'ENHANCED_SEARCH_VISIT_PROVIDERS_ERROR';

export const LOADING_ENHANCED_SEARCH_SPECIALTY =
	'LOADING_ENHANCED_SEARCH_SPECIALTY';
export const ENHANCED_SEARCH_SPECIALTY = 'ENHANCED_SEARCH_SPECIALTY';
export const ENHANCED_SEARCH_SPECIALTY_ERROR =
	'ENHANCED_SEARCH_SPECIALTY_ERROR';

export const LOADING_ENHANCED_SEARCH_VISIT_STATUS =
	'LOADING_ENHANCED_SEARCH_VISIT_STATUS';
export const ENHANCED_SEARCH_VISIT_STATUS = 'ENHANCED_SEARCH_VISIT_STATUS';
export const ENHANCED_SEARCH_VISIT_STATUS_ERROR =
	'ENHANCED_SEARCH_VISIT_STATUS_ERROR';

export const LOADING_ENHANCED_SEARCH_ACTIONABILITY =
	'LOADING_ENHANCED_SEARCH_ACTIONABILITY';
export const ENHANCED_SEARCH_ACTIONABILITY = 'ENHANCED_SEARCH_ACTIONABILITY';
export const ENHANCED_SEARCH_ACTIONABILITY_ERROR =
	'ENHANCED_SEARCH_ACTIONABILITY_ERROR';

export const LOADING_ENHANCED_SEARCH_LABS = 'LOADING_ENHANCED_SEARCH_LABS';
export const ENHANCED_SEARCH_LABS = 'ENHANCED_SEARCH_LABS';
export const ENHANCED_SEARCH_LABS_ERROR = 'ENHANCED_SEARCH_LABS_ERROR';

export const LOADING_ENHANCED_SEARCH_ORDER_STATUS =
	'LOADING_ENHANCED_SEARCH_ORDER_STATUS';
export const ENHANCED_SEARCH_ORDER_STATUS = 'ENHANCED_SEARCH_ORDER_STATUS';
export const ENHANCED_SEARCH_ORDER_STATUS_ERROR =
	'ENHANCED_SEARCH_ORDER_STATUS_ERROR';

export const LOADING_ENHANCED_SEARCH_TEST_TYPES =
	'LOADING_ENHANCED_SEARCH_TEST_TYPES';
export const ENHANCED_SEARCH_TEST_TYPES = 'ENHANCED_SEARCH_TEST_TYPES';
export const ENHANCED_SEARCH_TEST_TYPES_ERROR =
	'ENHANCED_SEARCH_TEST_TYPES_ERROR';

export const LOADING_ENHANCED_ENCOUNTER_TYPES =
	'LOADING_ENHANCED_ENCOUNTER_TYPES';
export const ENHANCED_ENCOUNTER_TYPES = 'ENHANCED_ENCOUNTER_TYPES';
export const ENHANCED_ENCOUNTER_TYPES_ERROR = 'ENHANCED_ENCOUNTER_TYPES_ERROR';

export const ENHANCED_SEARCH_RESULTS_EXPORT = 'ENHANCED_SEARCH_RESULTS_EXPORT';

export const LOADING_ENHANCED_SEARCH_TEST_NAMES =
	'LOADING_ENHANCED_SEARCH_TEST_NAMES';
export const ENHANCED_SEARCH_TEST_NAMES = 'ENHANCED_SEARCH_TEST_NAMES';
export const ENHANCED_SEARCH_TEST_NAMES_ERROR =
	'ENHANCED_SEARCH_TEST_NAMES_ERROR';

export const LOADING_ENHANCED_SEARCH_TEST_RESULTS =
	'LOADING_ENHANCED_SEARCH_TEST_RESULTS';
export const ENHANCED_SEARCH_TEST_RESULTS = 'ENHANCED_SEARCH_TEST_RESULTS';
export const ENHANCED_SEARCH_TEST_RESULTS_ERROR =
	'ENHANCED_SEARCH_TEST_RESULTS_ERROR';

export const LOADING_ENHANCED_SEARCH_DISPATCH_STATUS =
	'LOADING_ENHANCED_SEARCH_DISPATCH_STATUS';
export const ENHANCED_SEARCH_DISPATCH_STATUS =
	'ENHANCED_SEARCH_DISPATCH_STATUS';
export const ENHANCED_SEARCH_DISPATCH_STATUS_ERROR =
	'ENHANCED_SEARCH_DISPATCH_STATUS_ERROR';

export const LOADING_ENHANCED_SEARCH_OUTREACH_STATUS =
	'LOADING_ENHANCED_SEARCH_OUTREACH_STATUS';
export const ENHANCED_SEARCH_OUTREACH_STATUS =
	'ENHANCED_SEARCH_OUTREACH_STATUS';
export const ENHANCED_SEARCH_OUTREACH_STATUS_ERROR =
	'ENHANCED_SEARCH_OUTREACH_STATUS_ERROR';

export const LOADING_ENHANCED_SEARCH_REQUEST_DECISION =
	'LOADING_ENHANCED_SEARCH_REQUEST_DECISION';
export const ENHANCED_SEARCH_REQUEST_DECISION =
	'ENHANCED_SEARCH_REQUEST_DECISION';
export const ENHANCED_SEARCH_REQUEST_DECISION_ERROR =
	'ENHANCED_SEARCH_REQUEST_DECISION_ERROR';

export const LOADING_ENHANCED_SEARCH_REFERRING_PROVIDER_NAME =
	'LOADING_ENHANCED_SEARCH_REFERRING_PROVIDER_NAME';
export const ENHANCED_SEARCH_REFERRING_PROVIDER_NAME =
	'ENHANCED_SEARCH_REFERRING_PROVIDER_NAME';
export const ENHANCED_SEARCH_REFERRING_PROVIDER_NAME_ERROR =
	'ENHANCED_SEARCH_REFERRING_PROVIDER_NAME_ERROR';

export const LOADING_ENHANCED_SEARCH_VISIT_STATE =
	'LOADING_ENHANCED_SEARCH_VISIT_STATE';
export const ENHANCED_SEARCH_VISIT_STATE = 'ENHANCED_SEARCH_VISIT_STATE';
export const ENHANCED_SEARCH_VISIT_STATE_ERROR =
	'ENHANCED_SEARCH_VISIT_STATE_ERROR';

export const LOADING_ENHANCED_SEARCH_MULTIPATIENT_STATUS =
	'LOADING_ENHANCED_SEARCH_MULTIPATIENT_STATUS';
export const ENHANCED_SEARCH_MULTIPATIENT_STATUS =
	'ENHANCED_SEARCH_MULTIPATIENT_STATUS';
export const ENHANCED_SEARCH_MULTIPATIENT_STATUS_ERROR =
	'ENHANCED_SEARCH_MULTIPATIENT_STATUS_ERROR';

export const LOADING_ENHANCED_SEARCH_PATIENT_TAGS =
	'LOADING_ENHANCED_SEARCH_PATIENT_TAGS';
export const ENHANCED_SEARCH_PATIENT_TAGS = 'ENHANCED_SEARCH_PATIENT_TAGS';
export const ENHANCED_SEARCH_PATIENT_TAGS_ERROR =
	'ENHANCED_SEARCH_PATIENT_TAGS_ERROR';

export const GETTING_CONTACT_METHODS = 'GETTING_CONTACT_METHODS';
export const CONTACT_METHODS = 'CONTACT_METHODS';
export const CONTACT_METHODS_ERROR = 'CONTACT_METHODS_ERROR';

export const GETTING_CONTACT_OUTCOMES = 'GETTING_CONTACT_OUTCOMES';
export const CONTACT_OUTCOMES = 'CONTACT_OUTCOMES';
export const CONTACT_OUTCOMES_ERROR = 'CONTACT_OUTCOMES_ERROR';
export const GETTING_REFERRAL_REASONS = 'GETTING_REFERRAL_REASONS';
export const REFERRAL_REASONS = 'REFERRAL_REASONS';
export const REFERRAL_REASONS_ERROR = 'REFERRAL_REASONS_ERROR';
export const GETTING_REFERRAL_PROVIDERS = 'GETTING_REFERRAL_PROVIDERS';
export const REFERRAL_PROVIDERS = 'REFERRAL_PROVIDERS';
export const REFERRAL_PROVIDERS_ERROR = 'REFERRAL_PROVIDERS_ERROR';
export const ELIGIBILITY_FIELDS = 'ELIGIBILITY_FIELDS';
export const ELIGIBILITY_FIELDS_LOADING = 'ELIGIBILITY_FIELDS_LOADING';
export const ELIGIBILITY_FIELDS_ERROR = 'ELIGIBILITY_FIELDS_ERROR';
export const GETTING_CONSENT_TO_TREAT = 'GETTING_CONSENT_TO_TREAT';
export const CONSENT_TO_TREAT = 'CONSENT_TO_TREAT';
export const CONSENT_TO_TREAT_ERROR = 'CONSENT_TO_TREAT_ERROR';
export const ADD_PATIENT_ERROR = 'ADD_PATIENT_ERROR';

export const LAB_ENUM = 'LAB_ENUM';
export const CLAIM_STATUS_ENUM = 'CLAIM_STATUS_ENUM';
export const ACCESS_RESTRICTED_CHANGE = 'ACCESS_RESTRICTED_CHANGE';
export const GETTING_DASHBOARD_REFERRAL_PROGRAMS =
	'GETTING_DASHBOARD_REFERRAL_PROGRAMS';
export const DASHBOARD_REFERRAL_PROGRAMS = 'DASHBOARD_REFERRAL_PROGRAMS';
export const GOT_DASHBOARD_REFERRAL_PROGRAMS_ERROR =
	'GOT_DASHBOARD_REFERRAL_PROGRAMS_ERROR';
export const DASHBOARD_POP_REPORTS = 'DASHBOARD_POP_REPORTS';

export const BULK_UPDATE_ATTEMPTS = 'BULK_UPDATE_ATTEMPTS';

export const BULK_UPDATE_STATUS = 'BULK_UPDATE_STATUS';
export const RESET_BULK_UPDATE = 'RESET_BULK_UPDATE';
export const GET_POP_MODALITY = 'GET_POP_MODALITY';
export const UPDATING_OUTREACH = 'UPDATING_OUTREACH';
export const UPDATE_OUTREACH = 'UPDATE_OUTREACH';
export const UPDATE_OUTREACH_ERROR = 'UPDATE_OUTREACH_ERROR';
export const UPDATING_ADDRESS = 'UPDATING_ADDRESS';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_ADDRESS_ERROR = 'UPDATE_ADDRESS_ERROR';
export const UPDATING_RELATIONSHIP = 'UPDATING_RELATIONSHIP';
export const UPDATE_RELATIONSHIP = 'UPDATE_RELATIONSHIP';
export const UPDATE_RELATIONSHIP_ERROR = 'UPDATE_RELATIONSHIP_ERROR';
export const RESET_PATIENT_EDIT_ERRORS = 'RESET_PATIENT_EDIT_ERRORS';

export const GETTING_TESTS = 'GETTING_TESTS';
export const TESTS = 'TESTS';
export const GET_TESTS_ERROR = 'GET_TESTS_ERROR';

export const LOADING_UPDATE_SATISTACTION_SURVEY =
	'LOADING_UPDATE_SATISTACTION_SURVEY';
export const UPDATE_SATISTACTION_SURVEY = 'UPDATE_SATISTACTION_SURVEY';
export const UPDATE_SATISTACTION_SURVEY_ERROR =
	'UPDATE_SATISTACTION_SURVEY_ERROR';

export const TEST_DETAIL = 'TEST_DETAIL';
export const GETTING_TEST_DETAIL = 'GETTING_TEST_DETAIL';
export const TEST_DETAIL_ERROR = 'TEST_DETAIL_ERROR';

export const LOADING_EXPORT_ENCOUNTER_RECORDS =
	'LOADING_EXPORT_ENCOUNTER_RECORDS';
export const EXPORT_ENCOUNTER_RECORDS = 'EXPORT_ENCOUNTER_RECORDS';
export const EXPORT_ENCOUNTER_RECORDS_ERROR = 'EXPORT_ENCOUNTER_RECORDS_ERROR';

export const ENCOUNTER_LIST_LOADING = 'ENCOUNTER_LIST_LOADING';
export const ENCOUNTER_LIST = 'ENCOUNTER_LIST';
export const ENCOUNTER_LIST_ERROR = 'ENCOUNTER_LIST_ERROR';

export const GETTING_ENCOUNTER_DETAIL = 'GETTING_ENCOUNTER_DETAIL';
export const COMPLETING_ENCOUNTER = 'COMPLETING_ENCOUNTER';
export const COMPLETE_ENCOUNTER = 'COMPLETE_ENCOUNTER';
export const COMPLETE_ENCOUNTER_ERROR = 'COMPLETE_ENCOUNTER_ERROR';

export const MIGRATING_TO_PIONEER_RCO = 'MIGRATING_TO_PIONEER_RCO';
export const MIGRATED_TO_PIONEER_RCO = 'MIGRATED_TO_PIONEER_RCO';
export const MIGRATED_TO_PIONEER_ERROR_RCO = 'MIGRATED_TO_PIONEER_ERROR_RCO';

export const CANCELLING_ENCOUNTER = 'CANCELLING_ENCOUNTER';
export const CANCEL_ENCOUNTER = 'CANCEL_ENCOUNTER';
export const CANCEL_ENCOUNTER_ERROR = 'CANCEL_ENCOUNTER_ERROR';

export const LOADING_SET_PASSWORD = 'LOADING_SET_PASSWORD';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR';

export const LOAD_PATIENT_DOCUMENTS_START = 'LOAD_PATIENT_DOCUMENTS_START';
export const LOAD_PATIENT_DOCUMENTS_SUCCESS = 'LOAD_PATIENT_DOCUMENTS_SUCCESS';
export const LOAD_PATIENT_DOCUMENTS_FAILURE = 'LOAD_PATIENT_DOCUMENTS_FAILURE';
export const UNLOAD_PATIENT_DOCUMENTS = 'UNLOAD_PATIENT_DOCUMENTS';

export const GETTING_NO_OUTREACH_REASONS = 'GETTING_NO_OUTREACH_REASONS';
export const GOT_NO_OUTREACH_REASONS = 'GOT_NO_OUTREACH_REASONS';
export const GOT_NO_OUTREACH_REASONS_ERROR = 'GOT_NO_OUTREACH_REASONS_ERROR';

export const LOAD_ENCOUNTER_DETAIL_TEST_ENUM_START =
	'LOAD_ENCOUNTER_DETAIL_TEST_ENUM_START';
export const LOAD_ENCOUNTER_DETAIL_TEST_ENUM_SUCCESS =
	'LOAD_ENCOUNTER_DETAIL_TEST_ENUM_SUCCESS';
export const LOAD_ENCOUNTER_DETAIL_TEST_ENUM_FAILURE =
	'LOAD_ENCOUNTER_DETAIL_TEST_ENUM_FAILURE';

export const LOAD_ALTERNATE_TEST_ENUM_START = 'LOAD_ALTERNATE_TEST_ENUM_START';
export const LOAD_ALTERNATE_TEST_ENUM_SUCCESS =
	'LOAD_ALTERNATE_TEST_ENUM_SUCCESS';
export const LOAD_ALTERNATE_TEST_ENUM_FAILURE =
	'LOAD_ALTERNATE_TEST_ENUM_FAILURE';

export const LOAD_EDIT_TESTORDER_TEST_ENUM_START =
	'LOAD_EDIT_TESTORDER_TEST_ENUM_START';
export const LOAD_EDIT_TESTORDER_TEST_ENUM_SUCCESS =
	'LOAD_EDIT_TESTORDER_TEST_ENUM_SUCCESS';
export const LOAD_EDIT_TESTORDER_TEST_ENUM_FAILURE =
	'LOAD_EDIT_TESTORDER_TEST_ENUM_FAILURE';

export const EDITING_BILLING_ENCOUNTER = 'EDITING_BILLING_ENCOUNTER';
export const EDIT_BILLING_ENCOUNTER = 'EDIT_BILLING_ENCOUNTER';
export const EDIT_BILLING_ENCOUNTER_FAILURE = 'EDIT_BILLING_ENCOUNTER_FAILURE';

export const GETTING_LABS_ENUM = 'GETTING_LABS_ENUM';

export const GETTING_TEST_TYPES = 'GETTING_TEST_TYPES';
export const GOT_TEST_TYPES = 'GOT_TEST_TYPES';
export const GOT_TEST_TYPES_ERROR = 'GOT_TEST_TYPES_ERROR';

export const GETTING_TEST_TAGS = 'GETTING_TEST_TAGS';
export const GOT_TEST_TAGS = 'GOT_TEST_TAGS';
export const GOT_TEST_TAGS_ERROR = 'GOT_TEST_TAGS_ERROR';

export const GETTING_OTHER_ENUMS = 'GETTING_OTHER_ENUMS';
export const GOT_OTHER_ENUMS = 'GOT_OTHER_ENUMS';
export const GOT_OTHER_ENUMS_ERROR = 'GOT_OTHER_ENUMS_ERROR';

export const UPDATING_TEST = 'UPDATING_TEST';
export const UPDATED_TEST = 'UPDATED_TEST';

export const CREATING_TEST = 'CREATING_TEST';
export const CREATED_TEST = 'CREATED_TEST';
export const CREATED_TEST_ERROR = 'CREATED_TEST_ERROR';

export const ORDERING_PHYSICIAN_ENUM = 'ORDERING_PHYSICIAN_ENUM';

/* v2 indicates that this is version2 functionality of document and it is refering to gdrive */
export const V2_LOAD_DOCUMENTS_START = 'V2_LOAD_DOCUMENTS_START';
export const V2_LOAD_DOCUMENTS_SUCCESS = 'V2_LOAD_DOCUMENTS_SUCCESS';
export const V2_LOAD_DOCUMENTS_FAILURE = 'V2_LOAD_DOCUMENTS_FAILURE';
export const V2_UNLOAD_DOCUMENTS = 'V2_UNLOAD_DOCUMENTS';
export const V2_CREATING_DOCUMENTS = 'V2_CREATING_DOCUMENTS';
export const V2_CREATED_DOCUMENTS = 'V2_CREATED_DOCUMENTS';
export const V2_CREATING_DOCUMENTS_FAILURE = 'V2_CREATING_DOCUMENTS_FAILURE';

//Document Type: template
export const LOAD_DOCUMENT_TYPES_TEMPLATE_START =
	'LOAD_DOCUMENT_TYPES_TEMPLATE_START';
export const LOAD_DOCUMENT_TYPES_TEMPLATE_SUCCESS =
	'LOAD_DOCUMENT_TYPES_TEMPLATE_SUCCESS';
export const LOAD_DOCUMENT_TYPES_TEMPLATE_FAILURE =
	'LOAD_DOCUMENT_TYPES_TEMPLATE_FAILURE';
export const LOAD_DOCUMENT_TYPES_TEMPLATE_CLEAR =
	'LOAD_DOCUMENT_TYPES_TEMPLATE_CLEAR';

//Document Type: workflow
export const LOAD_DOCUMENT_TYPES_WORKFLOW_START =
	'LOAD_DOCUMENT_TYPES_WORKFLOW_START';
export const LOAD_DOCUMENT_TYPES_WORKFLOW_SUCCESS =
	'LOAD_DOCUMENT_TYPES_WORKFLOW_SUCCESS';
export const LOAD_DOCUMENT_TYPES_WORKFLOW_FAILURE =
	'LOAD_DOCUMENT_TYPES_WORKFLOW_FAILURE';
export const LOAD_DOCUMENT_TYPES_WORKFLOW_CLEAR =
	'LOAD_DOCUMENT_TYPES_WORKFLOW_CLEAR';

//Document Type: patient
export const LOAD_DOCUMENT_TYPES_PATIENT_START =
	'LOAD_DOCUMENT_TYPES_PATIENT_START';
export const LOAD_DOCUMENT_TYPES_PATIENT_SUCCESS =
	'LOAD_DOCUMENT_TYPES_PATIENT_SUCCESS';
export const LOAD_DOCUMENT_TYPES_PATIENT_FAILURE =
	'LOAD_DOCUMENT_TYPES_PATIENT_FAILURE';
export const LOAD_DOCUMENT_TYPES_PATIENT_CLEAR =
	'LOAD_DOCUMENT_TYPES_PATIENT_CLEAR';

export const V2_START_UPLOAD_DOCUMENT = 'V2_START_UPLOAD_DOCUMENT';
export const V2_SUCCESS_UPLOAD_DOCUMENT = 'V2_SUCCESS_UPLOAD_DOCUMENT';
export const V2_FAILURE_UPLOAD_DOCUMENT = 'V2_FAILURE_UPLOAD_DOCUMENT';
export const LOAD_DETAIL_DOCUMENT_START = 'LOAD_DETAIL_DOCUMENT_START';
export const LOAD_DETAIL_DOCUMENT_SUCCESS = 'LOAD_DETAIL_DOCUMENT_SUCCESS';
export const LOAD_DETAIL_DOCUMENT_FAILURE = 'LOAD_DETAIL_DOCUMENT_FAILURE';
export const UNLOAD_DETAIL_DOCUMENT = 'UNLOAD_DETAIL_DOCUMENT';
export const V2_LOAD_TEMPLATES_START = 'V2_LOAD_TEMPLATES_START';
export const V2_LOAD_TEMPLATES_SUCCESS = 'V2_LOAD_TEMPLATES_SUCCESS';
export const V2_LOAD_TEMPLATES_FAILURE = 'V2_LOAD_TEMPLATES_FAILURE';
export const V2_UPLOAD_FILE_START = 'V2_UPLOAD_FILE_START';
export const V2_UPLOAD_FILE_SUCCESS = 'V2_UPLOAD_FILE_SUCCESS';
export const V2_UPLOAD_FILE_FAILURE = 'V2_UPLOAD_FILE_FAILURE';
export const UPDATING_DOCUMENT_RELATED_ENCOUNTER =
	'UPDATING_DOCUMENT_RELATED_ENCOUNTER';
export const UPDATE_DOCUMENT_ENCOUNTER_SUCCESS =
	'UPDATE_DOCUMENT_ENCOUNTER_SUCCESS';
export const UPDATE_DOCUMENT_ENCOUNTER_FAILURE =
	'UPDATE_DOCUMENT_ENCOUNTER_FAILURE';
export const LOAD_DOCUMENT_MEDIUMS_START = 'LOAD_DOCUMENT_MEDIUMS_START';
export const LOAD_DOCUMENT_MEDIUMS_SUCCESS = 'LOAD_DOCUMENT_MEDIUMS_SUCCESS';
export const LOAD_DOCUMENT_MEDIUMS_FAILURE = 'LOAD_DOCUMENT_MEDIUMS_FAILURE';
export const LOAD_DOCUMENT_RECIPIENTS_START = 'LOAD_DOCUMENT_RECIPIENTS_START';
export const LOAD_DOCUMENT_RECIPIENTS_SUCCESS =
	'LOAD_DOCUMENT_RECIPIENTS_SUCCESS';
export const LOAD_DOCUMENT_RECIPIENTS_FAILURE =
	'LOAD_DOCUMENT_RECIPIENTS_FAILURE';

//S3 documents
export const INLINE_DOCUMENT_DETAIL_START = 'INLINE_DOCUMENT_DETAIL_START';
export const INLINE_DOCUMENT_DETAIL_SUCCESS = 'INLINE_DOCUMENT_DETAIL_SUCCESS';
export const INLINE_DOCUMENT_DETAIL_FAILURE = 'INLINE_DOCUMENT_DETAIL_FAILURE';

export const DOCUMENT_ACTION_START = 'DOCUMENT_ACTION_START';
export const DOCUMENT_ACTION_SUCCESS = 'DOCUMENT_ACTION_SUCCESS';
export const DOCUMENT_ACTION_FAILURE = 'DOCUMENT_ACTION_FAILURE';

export const COMBINE_DOCUMENT_START = 'COMBINE_DOCUMENT_START';
export const COMBINE_DOCUMENT_SUCCESS = 'COMBINE_DOCUMENT_SUCCESS';
export const COMBINE_DOCUMENT_FAILURE = 'COMBINE_DOCUMENT_FAILURE';

export const GETTING_INSURANCE_COMPANIES = 'GETTING_INSURANCE_COMPANIES';
export const GET_INSURANCE_COMPANIES_SUCCESS =
	'GET_INSURANCE_COMPANIES_SUCCESS';
export const GET_INSURANCE_COMPANIES_ERROR = 'GET_INSURANCE_COMPANIES_ERROR';

export const GETTING_FULL_LIST_OF_INSURANCE_COMPANIES =
	'GETTING_FULL_LIST_OF_INSURANCE_COMPANIES';
export const GET_FULL_LIST_OF_INSURANCE_COMPANIES_SUCCESS =
	'GET_FULL_LIST_OF_INSURANCE_COMPANIES_SUCCESS';
export const GET_FULL_LIST_OF_INSURANCE_COMPANIES_ERROR =
	'GET_FULL_LIST_OF_INSURANCE_COMPANIES_ERROR';

export const SAVE_INSURANCE_SUCCESS = 'SAVE_INSURANCE_SUCCESS';
export const SAVE_INSURANCE_ERROR = 'SAVE_INSURANCE_ERROR';

export const LOADING_SAVE_INSURANCE_AUTH = 'LOADING_SAVE_INSURANCE_AUTH';
export const SAVE_INSURANCE_AUTH_SUCCESS = 'SAVE_INSURANCE_AUTH_SUCCESS';
export const SAVE_INSURANCE_AUTH_ERROR = 'SAVE_INSURANCE_AUTH_ERROR';

export const LOADING_CREATE_INSURANCE_COMPANIES =
	'LOADING_CREATE_INSURANCE_COMPANIES';
export const LOADED_CREATE_INSURANCE_COMPANIES =
	'LOADED_CREATE_INSURANCE_COMPANIES';
export const ERROR_CREATE_INSURANCE_COMPANIES =
	'ERROR_CREATE_INSURANCE_COMPANIES';

export const V2_ARCHIVE_DOCUMENT_START = 'V2_ARCHIVE_DOCUMENT_START';
export const V2_ARCHIVE_DOCUMENT_SUCCESS = 'V2_ARCHIVE_DOCUMENT_SUCCESS';
export const V2_ARCHIVE_DOCUMENT_FAILURE = 'V2_ARCHIVE_DOCUMENT_FAILURE';

export const GETTING_GENE_RESULTS_ENUM = 'GETTING_GENE_RESULTS_ENUM';
export const LOADING_GENE_RESULTS_ENUM = 'LOADING_GENE_RESULTS_ENUM';
export const GENE_RESULTS_ENUM_ERROR = 'GENE_RESULTS_ENUM_ERROR';

export const GETTING_GENETIC_RESULT_GENES = 'GETTING_GENETIC_RESULT_GENES';
export const LOADING_GENETIC_RESULT_GENES = 'LOADING_GENETIC_RESULT_GENES';
export const GENETIC_RESULT_GENES_ERROR = 'GENETIC_RESULT_GENES_ERROR';

export const LOADING_GENETIC_RESULT_GENES_EDIT =
	'LOADING_GENETIC_RESULT_GENES_EDIT';
export const GETTING_GENETIC_RESULT_GENES_EDIT =
	'GETTING_GENETIC_RESULT_GENES_EDIT';
export const GENETIC_RESULT_GENES_EDIT_ERROR =
	'GENETIC_RESULT_GENES_EDIT_ERROR';

export const ADDING_GENE_RESULTS = 'ADDING_GENE_RESULTS';
export const ADDED_GENE_RESULTS = 'ADDED_GENE_RESULTS';
export const ADD_GENE_RESULTS_ERROR = 'ADD_GENE_RESULTS_ERROR';

export const UPDATING_GENE_RESULTS = 'UPDATING_GENE_RESULTS';
export const UPDATED_GENE_RESULTS = 'UPDATED_GENE_RESULTS';
export const UPDATE_GENE_RESULTS_ERROR = 'UPDATE_GENE_RESULTS_ERROR';

export const GETTING_GENE_TEST_ORDER_STATUS_ENUM =
	'GETTING_GENE_TEST_ORDER_STATUS_ENUM';
export const GOT_GENE_TEST_ORDER_STATUS_ENUM =
	'GOT_GENE_TEST_ORDER_STATUS_ENUM';
export const GOT_GENE_TEST_ORDER_STATUS_ENUM_ERROR =
	'GOT_GENE_TEST_ORDER_STATUS_ENUM_ERROR';

export const V2_FINALIZING_DOCUMENT_START = 'V2_FINALIZING_DOCUMENT_START';
export const V2_FINALIZED_DOCUMENT_SUCCESS = 'V2_FINALIZED_DOCUMENT_SUCCESS';
export const V2_FINALIZING_DOCUMENT_FAILURE = 'V2_FINALIZING_DOCUMENT_FAILURE';

export const TEST_RESETTING = 'TEST_RESETTING';
export const TEST_REST_DONE = 'TEST_REST_DONE';
export const TEST_REST_ERROR = 'TEST_REST_ERROR';

export const LOADING_REFERRAL_PROGRAMS = 'LOADING_REFERRAL_PROGRAM';
export const LOADED_REFERRAL_PROGRAMS = 'LOADED_REFERRAL_PROGRAM';
export const ERROR_REFERRAL_PROGRAMS = 'ERROR_REFERRAL_PROGRAM';

export const V2_LOAD_WORKFLOW_DOCS_START = 'V2_LOAD_WORKFLOW_DOCS_START';
export const V2_LOAD_WORKFLOW_DOCS_SUCCESS = 'V2_LOAD_WORKFLOW_DOCS_SUCCESS';
export const V2_LOAD_WORKFLOW_DOCS_FAILURE = 'V2_LOAD_WORKFLOW_DOCS_FAILURE';
export const GET_REFERRAL_DOCUMENT_SUCCESS = 'GET_REFERRAL_DOCUMENT_SUCCESS';
export const GET_REFERRAL_DOCUMENT_ERROR = 'GET_REFERRAL_DOCUMENT_ERROR';
export const CREATE_REFERRAL_DOCUMENT_SUCCESS =
	'CREATE_REFERRAL_DOCUMENT_SUCCESS';
export const CREATE_REFERRAL_DOCUMENT_ERROR = 'CREATE_REFERRAL_DOCUMENT_ERROR';
export const UPDATE_REFERRAL_DOCUMENT_SUCCESS =
	'UPDATE_REFERRAL_DOCUMENT_SUCCESS';
export const UPDATE_REFERRAL_DOCUMENT_ERROR = 'UPDATE_REFERRAL_DOCUMENT_ERROR';
export const LOADING_REFERRAL_DOCUMENTS = 'LOADING_REFERRAL_DOCUMENTS';
export const LOADED_REFERRAL_DOCUMENTS = 'LOADED_REFERRAL_DOCUMENTS';
export const ERROR_REFERRAL_DOCUMENTS = 'ERROR_REFERRAL_DOCUMENTS';

export const LOADING_WORKFLOW_DOCUMENTS = 'LOADING_WORKFLOW_DOCUMENTS';
export const LOADED_WORKFLOW_DOCUMENTS = 'LOADED_WORKFLOW_DOCUMENTS';
export const ERROR_WORKFLOW_DOCUMENTS = 'ERROR_WORKFLOW_DOCUMENTS';

export const LOADING_CREATE_TEMPLATE = 'LOADING_CREATE_TEMPLATE';
export const LOADED_CREATE_TEMPLATE = 'LOADED_CREATE_TEMPLATE';
export const ERROR_CREATE_TEMPLATE = 'ERROR_CREATE_TEMPLATE';

//create template for Templates tab
export const CREATING_TEMPLATE = 'CREATING_TEMPLATE';
export const CREATED_TEMPLATE = 'CREATED_TEMPLATE';
export const CREATED_TEMPLATE_ERROR = 'CREATED_TEMPLATE_ERROR';

//edit template for Templates tab
export const EDITING_TEMPLATE = 'EDITING_TEMPLATE';
export const EDITED_TEMPLATE = 'EDITED_TEMPLATE';
export const EDITED_TEMPLATE_ERROR = 'EDITED_TEMPLATE_ERROR';

//archive template for Templates tab
export const ARCHIVING_TEMPLATE = 'ARCHIVING_TEMPLATE';
export const ARCHIVED_TEMPLATE = 'ARCHIVED_TEMPLATE';
export const ARCHIVED_TEMPLATE_ERROR = 'ARCHIVED_TEMPLATE_ERROR';

//update template partners for Templates tab (batch operation)
export const BATCH_UPDATING_TEMPLATE_PARTNERS =
	'BATCH_UPDATING_TEMPLATE_PARTNERS';
export const BATCH_UPDATED_TEMPLATE_PARTNERS =
	'BATCH_UPDATED_TEMPLATE_PARTNERS';
export const BATCH_UPDATED_TEMPLATE_PARTNERS_ERROR =
	'BATCH_UPDATED_TEMPLATE_PARTNERS_ERROR';

//update template type for Templates tab (batch operation)
export const BATCH_UPDATING_TEMPLATE_TYPE = 'BATCH_UPDATING_TEMPLATE_TYPE';
export const BATCH_UPDATED_TEMPLATE_TYPE = 'BATCH_UPDATED_TEMPLATE_TYPE';
export const BATCH_UPDATED_TEMPLATE_TYPE_ERROR =
	'BATCH_UPDATED_TEMPLATE_TYPE_ERROR';

export const LOADING_UPDATE_TEMPLATE = 'LOADING_UPDATE_TEMPLATE';
export const LOADED_UPDATE_TEMPLATE = 'LOADED_UPDATE_TEMPLATE';
export const ERROR_UPDATE_TEMPLATE = 'ERROR_UPDATE_TEMPLATE';

export const LOADING_VIEW_TEMPLATE = 'LOADING_VIEW_TEMPLATE';
export const LOADED_VIEW_TEMPLATE = 'LOADED_VIEW_TEMPLATE';
export const ERROR_VIEW_TEMPLATE = 'ERROR_VIEW_TEMPLATE';

export const RESET_REFERRAL_DOCUMENTS = 'RESET_REFERRAL_DOCUMENTS';

export const LOADING_REFERRAL_DOCUMENT_DETAIL =
	'LOADING_REFERRAL_DOCUMENT_DETAIL';
export const LOADED_REFERRAL_DOCUMENT_DETAIL =
	'LOADED_REFERRAL_DOCUMENT_DETAIL';
export const ERROR_REFERRAL_DOCUMENT_DETAIL = 'ERROR_REFERRAL_DOCUMENT_DETAIL';
export const RESET_WORKFLOW_DOCUMENTS = 'RESET_WORKFLOW_DOCUMENTS';

export const LOADING_REFERRAL_PROGRAM_DETAIL =
	'LOADING_REFERRAL_PROGRAM_DETAIL';
export const LOADED_REFERRAL_PROGRAM_DETAIL = 'LOADED_REFERRAL_PROGRAM_DETAIL';
export const ERROR_REFERRAL_PROGRAM_DETAIL = 'ERROR_REFERRAL_PROGRAM_DETAIL';
export const RESET_REFERRAL_PROGRAM_DETAIL = 'RESET_REFERRAL_PROGRAM_DETAIL';

export const LOADING_DEFAULT_SCHEDULING_OPTIONS =
	'LOADING_DEFAULT_SCHEDULING_OPTIONS';
export const LOADED_DEFAULT_SCHEDULING_OPTIONS =
	'LOADED_DEFAULT_SCHEDULING_OPTIONS';
export const ERROR_DEFAULT_SCHEDULING_OPTIONS =
	'ERROR_DEFAULT_SCHEDULING_OPTIONS';
export const RESET_DEFAULT_SCHEDULING_OPTIONS =
	'RESET_DEFAULT_SCHEDULING_OPTIONS';

//Referral program default providers
export const LOADING_CREATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER =
	'LOADING_CREATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER';
export const LOADED_CREATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER =
	'LOADED_CREATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER';
export const ERROR_CREATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER =
	'ERROR_CREATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER';

export const LOADING_UPDATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER =
	'LOADING_UPDATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER';
export const LOADED_UPDATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER =
	'LOADED_UPDATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER';
export const ERROR_UPDATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER =
	'ERROR_UPDATE_REFERRAL_PROGRAM_DEFAULT_PROVIDER';

export const CLEAR_DEFAULT_PROVIDERS_STATE = 'CLEAR_DEFAULT_PROVIDERS_STATE';

export const LOADING_UPDATE_REFERRAL_PROGRAM_DEFAULT_DOCUMENT_RECIPIENT =
	'LOADING_UPDATE_REFERRAL_PROGRAM_DEFAULT_DOCUMENT_RECIPIENT';
export const LOADED_UPDATE_REFERRAL_PROGRAM_DEFAULT_DOCUMENT_RECIPIENT =
	'LOADED_UPDATE_REFERRAL_PROGRAM_DEFAULT_DOCUMENT_RECIPIENT';
export const ERROR_UPDATE_REFERRAL_PROGRAM_DEFAULT_DOCUMENT_RECIPIENT =
	'ERROR_UPDATE_REFERRAL_PROGRAM_DEFAULT_DOCUMENT_RECIPIENT';

export const LOADING_CREATE_BILLING_INFO = 'LOADING_CREATE_BILLING_INFO';
export const LOADED_CREATE_BILLING_INFO = 'LOADED_CREATE_BILLING_INFO';
export const ERROR_CREATE_BILLING_INFO = 'ERROR_CREATE_BILLING_INFO';

export const LOADING_UPDATE_BILLING_INFO = 'LOADING_UPDATE_BILLING_INFO';
export const LOADED_UPDATE_BILLING_INFO = 'LOADED_UPDATE_BILLING_INFO';
export const ERROR_UPDATE_BILLING_INFO = 'ERROR_UPDATE_BILLING_INFO';

//Billing encounter bulk update
export const BILLING_ENCOUNTER_BULK_UPDATING =
	'BILLING_ENCOUNTER_BULK_UPDATING';
export const BILLING_ENCOUNTER_BULK_UPDATED = 'BILLING_ENCOUNTER_BULK_UPDATED';
export const BILLING_ENCOUNTER_BULK_UPDATED_ERROR =
	'BILLING_ENCOUNTER_BULK_UPDATED_ERROR';

export const LOADING_PARTNER_ORGANIZATION = 'LOADING_PARTNER_ORGANIZATION';
export const GET_PARTNER_ORGANIZATION_SUCCESS =
	'GET_PARTNER_ORGANIZATION_SUCCESS';
export const GET_PARTNER_ORGANIZATION_ERROR = 'GET_PARTNER_ORGANIZATION_ERROR';

export const ARCHIVE_TEMPLATE_START = 'ARCHIVE_TEMPLATE_START';
export const ARCHIVE_TEMPLATE_SUCCESS = 'ARCHIVE_TEMPLATE_SUCCESS';
export const ARCHIVE_TEMPLATE_FAILURE = 'ARCHIVE_TEMPLATE_FAILURE';

export const LOAD_APPOINTMENTS_START = 'LOAD_APPOINTMENTS_START';
export const LOAD_APPOINTMENTS_SUCCESS = 'LOAD_APPOINTMENTS_SUCCESS';
export const LOAD_APPOINTMENTS_FAILURE = 'LOAD_APPOINTMENTS_FAILURE';
export const GET_APPT_REFERRAL_PROGRAMS = 'GET_APPT_REFERRAL_PROGRAMS';
export const GETTING_APPT_REFERRAL_PROGRAMS = 'GETTING_APPT_REFERRAL_PROGRAMS';
export const GET_APPT_REFERRAL_PROGRAMS_ERROR =
	'GET_APPT_REFERRAL_PROGRAMS_ERROR';

export const SWITCH_MODALITY_START = 'SWITCH_MODALITY_START';
export const SWITCH_MODALITY_SUCCESS = 'SWITCH_MODALITY_SUCCESS';
export const SWITCH_MODALITY_FAILURE = 'SWITCH_MODALITY_FAILURE';

/* Scheduling Appointment */
export const LOAD_APPOINTMENT_SLOTS_START = 'LOAD_APPOINTMENT_SLOTS_START';
export const LOAD_APPOINTMENT_SLOTS_SUCCESS = 'LOAD_APPOINTMENT_SLOTS_SUCCESS';
export const LOAD_APPOINTMENT_SLOTS_FAILURE = 'LOAD_APPOINTMENT_SLOTS_FAILURE';

/* User (PATIENT) specific Consultations */
export const LOAD_PATIENT_CONSULTATIONS_START =
	'LOAD_PATIENT_CONSULTATIONS_START';
export const LOAD_PATIENT_CONSULTATIONS_SUCCESS =
	'LOAD_PATIENT_CONSULTATIONS_SUCCESS';
export const LOAD_PATIENT_CONSULTATIONS_FAILURE =
	'LOAD_PATIENT_CONSULTATIONS_FAILURE';

/* GET PROVIDER  this is the proxy provider list of service backend*/
export const LOAD_APPOINTMENT_PROVIDER_START =
	'LOAD_APPOINTMENT_PROVIDER_START';
export const LOAD_APPOINTMENT_PROVIDER_SUCCESS =
	'LOAD_APPOINTMENT_PROVIDER_SUCCESS';
export const LOAD_APPOINTMENT_PROVIDER_FAILURE =
	'LOAD_APPOINTMENT_PROVIDER_FAILURE';
export const CLEAR_APPOINTMENT_PROVIDER = 'CLEAR_APPOINTMENT_PROVIDER';

/* GET Providers list */
export const GET_PROVIDERS_LIST_START = 'GET_PROVIDERS_LIST_START';
export const GET_PROVIDERS_LIST_SUCCESS = 'GET_PROVIDERS_LIST_SUCCESS';
export const GET_PROVIDERS_LIST_FAILURE = 'GET_PROVIDERS_LIST_FAILURE';

export const SET_APPOINTMENT_PARAMS = 'SET_APPOINTMENT_PARAMS';
export const TOGGLE_APPOINTMENT_PATIENT = 'TOGGLE_APPOINTMENT_PATIENT';
export const RESET_NEW_APPOINTMENT = 'RESET_NEW_APPOINTMENT';
export const CLEAR_APPOINTMENT_PARTNER_DETAIL_START =
	'CLEAR_APPOINTMENT_PARTNER_DETAIL_START';

/* GET Partner by partner id - this is the proxy service backend*/
export const LOAD_APPOINTMENT_PARTNER_DETAIL_START =
	'LOAD_APPOINTMENT_PARTNER_DETAIL_START';
export const LOAD_APPOINTMENT_PARTNER_DETAIL_SUCCESS =
	'LOAD_APPOINTMENT_PARTNER_DETAIL_SUCCESS';
export const LOAD_APPOINTMENT_PARTNER_DETAIL_FAILURE =
	'LOAD_APPOINTMENT_PARTNER_DETAIL_FAILURE';

/* apply coupon code for payment step in book appointment */
export const LOADING_APPLY_COUPONCODE = 'LOADING_APPLY_COUPONCODE';
export const LOADED_APPLY_COUPONCODE = 'LOADED_APPLY_COUPONCODE';
export const ERROR_APPLY_COUPONCODE = 'ERROR_APPLY_COUPONCODE';
export const RESET_APPLY_COUPONCODE = 'RESET_APPLY_COUPONCODE';

/* get insurance company */
export const LOADING_GET_INSURANCE_COMPANIES =
	'LOADING_GET_INSURANCE_COMPANIES';
export const LOADED_GET_INSURANCE_COMPANIES = 'LOADED_GET_INSURANCE_COMPANIES';
export const ERROR_GET_INSURANCE_COMPANIES = 'ERROR_GET_INSURANCE_COMPANIES';

export const CREATING_INSURANCE_COMPANY = 'CREATING_INSURANCE_COMPANY';
export const CREATED_INSURANCE_COMPANY = 'CREATED_INSURANCE_COMPANY';
export const CREATED_INSURANCE_COMPANY_ERROR =
	'CREATED_INSURANCE_COMPANY_ERROR';
export const UPDATING_INSURANCE_COMPANY = 'UPDATING_INSURANCE_COMPANY';
export const UPDATED_INSURANCE_COMPANY = 'UPDATED_INSURANCE_COMPANY';
export const UPDATED_INSURANCE_COMPANY_ERROR =
	'UPDATED_INSURANCE_COMPANY_ERROR';

/* get insurance info */
export const GET_INSURANCE_INFO_START = 'GET_INSURANCE_INFO_START';
export const GET_INSURANCE_INFO_SUCCESS = 'GET_INSURANCE_INFO_SUCCESS';
export const GET_INSURANCE_INFO_ERROR = 'GET_INSURANCE_INFO_ERROR';

export const LOADING_GET_INSURANCE_AUTHS = 'LOADING_GET_INSURANCE_AUTHS';
export const GET_INSURANCE_AUTHS_SUCCESS = 'GET_INSURANCE_AUTHS_SUCCESS';
export const GET_INSURANCE_AUTHS_ERROR = 'GET_INSURANCE_AUTHS_ERROR';

//add referring providers
export const ADDING_REFERRING_PROVIDER = 'ADDING_REFERRING_PROVIDER';
export const ADD_REFERRING_PROVIDER_SUCCESS = 'ADD_REFERRING_PROVIDER_SUCCESS';
export const ADD_REFERRING_PROVIDER_ERROR = 'ADD_REFERRING_PROVIDER_ERROR';

//add referral reason
export const ADD_REFERRAL_REASON_ERROR = 'ADD_REFERRAL_REASON_ERROR';
export const ADD_REFERRAL_REASON_SUCCESS = 'ADD_REFERRAL_REASON_SUCCESS';

//add contact method
export const ADD_CONTACT_METHOD_ERROR = 'ADD_CONTACT_METHOD_ERROR';
export const ADD_CONTACT_METHOD_SUCCESS = 'ADD_CONTACT_METHOD_SUCCESS';

//update doc type in document list document tab
export const UPDATE_DOCUMENT_TYPE_START = 'UPDATE_DOCUMENT_TYPE_START';
export const UPDATE_DOCUMENT_TYPE_SUCCESS = 'UPDATE_DOCUMENT_TYPE_SUCCESS';
export const UPDATE_DOCUMENT_TYPE_FAILURE = 'UPDATE_DOCUMENT_TYPE_FAILURE';

export const ME_SUCCESS = 'ME_SUCCESS';

export const UPDATE_ME_START = 'UPDATE_ME_START';
export const UPDATE_ME_SUCCESS = 'UPDATE_ME_SUCCESS';
export const UPDATE_ME_FAILURE = 'UPDATE_ME_FAILURE';

//Create Athena patient
export const CREATING_ATHENA_PATIENT = 'CREATING_ATHENA_PATIENT';
export const CREATED_ATHENA_PATIENT_SUCCESS = 'CREATED_ATHENA_PATIENT_SUCCESS';
export const CREATED_ATHENA_PATIENT_ERROR = 'CREATED_ATHENA_PATIENT_ERROR';

//Get ICD Codes
export const GETTING_ICD_CODES = 'GETTING_ICD_CODES';
export const GOT_ICD_CODES = 'GOT_ICD_CODES';
export const GOT_ICD_CODES_ERROR = 'GOT_ICD_CODES_ERROR';

export const LOAD_USERS_START = 'LOAD_USERS_START';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAILURE = 'LOAD_USERS_FAILURE';
export const UNLOAD_USERS = 'UNLOAD_USERS';

export const LOAD_USER_START = 'LOAD_USER_START';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';
export const UNLOAD_USER = 'UNLOAD_USER';

export const SHARE_DOCUMENT_START = 'SHARE_DOCUMENT_START';
export const SHARE_DOCUMENT_SUCCESS = 'SHARE_DOCUMENT_SUCCESS';
export const SHARE_DOCUMENT_FAILURE = 'SHARE_DOCUMENT_FAILURE';

export const LOG_RELEASE_DOCUMENT_START = 'LOG_RELEASE_DOCUMENT_START';
export const LOG_RELEASE_DOCUMENT_SUCCESS = 'LOG_RELEASE_DOCUMENT_SUCCESS';
export const LOG_RELEASE_DOCUMENT_FAILURE = 'LOG_RELEASE_DOCUMENT_FAILURE';

export const RELEASE_DOCUMENT_START = 'RELEASE_DOCUMENT_START';
export const RELEASE_DOCUMENT_SUCCESS = 'RELEASE_DOCUMENT_SUCCESS';
export const RELEASE_DOCUMENT_FAILURE = 'RELEASE_DOCUMENT_FAILURE';

export const LOAD_USER_ROLES_START = 'LOAD_USER_ROLES_START';
export const LOAD_USER_ROLES_SUCCESS = 'LOAD_USER_ROLES_SUCCESS';
export const LOAD_USER_ROLES_FAILURE = 'LOAD_USER_ROLES_FAILURE';

export const LOAD_PARTNERS_START = 'LOAD_PARTNERS_START';
export const LOAD_PARTNERS_SUCCESS = 'LOAD_PARTNERS_SUCCESS';
export const LOAD_PARTNERS_FAILURE = 'LOAD_PARTNERS_FAILURE';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const CREATE_USER_CLEAR = 'CREATE_USER_CLEAR';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_CLEAR = 'UPDATE_USER_CLEAR';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const USER_PASSWORD_RESET_START = 'USER_PASSWORD_RESET_START';
export const USER_PASSWORD_RESET_SUCCESS = 'USER_PASSWORD_RESET_SUCCESS';
export const USER_PASSWORD_RESET_FAILURE = 'USER_PASSWORD_RESET_FAILURE';

export const GET_AUDIT_TRAIL_USER_START = 'GET_AUDIT_TRAIL_USER_START';
export const GET_AUDIT_TRAIL_USER_SUCCESS = 'GET_AUDIT_TRAIL_USER_SUCCESS';
export const GET_AUDIT_TRAIL_USER_FAILURE = 'GET_AUDIT_TRAIL_USER_FAILURE';

//Outreach
export const LOADING_OUTREACH_ENUMS = 'LOADING_OUTREACH_ENUMS';
export const OUTREACH_ENUMS = 'OUTREACH_ENUMS';
export const OUTREACH_ENUMS_ERROR = 'OUTREACH_ENUMS_ERROR';

export const LOAD_OUTREACHS_START = 'LOAD_OUTREACHS_START';
export const LOAD_OUTREACHS_FAILURE = 'LOAD_OUTREACHS_FAILURE';
export const LOAD_OUTREACHS_SUCCESS = 'LOAD_OUTREACHS_SUCCESS';
export const RESET_OUTREACHS = 'RESET_OUTREACHS';

export const LOAD_ENCOUNTER_COMMUNICATIONS_START =
	'LOAD_ENCOUNTER_COMMUNICATIONS_START';
export const LOAD_ENCOUNTER_COMMUNICATIONS_FAILURE =
	'LOAD_ENCOUNTER_COMMUNICATIONS_FAILURE';
export const LOAD_ENCOUNTER_COMMUNICATIONS_SUCCESS =
	'LOAD_ENCOUNTER_COMMUNICATIONS_SUCCESS';

export const ADDING_OUTREACH = 'ADDING_OUTREACH';
export const ADDED_OUTREACH_SUCCESS = 'ADDED_OUTREACH_SUCCESS';
export const ADDED_OUTREACH_FAILURE = 'ADDED_OUTREACH_FAILURE';

export const SETTING_DO_NOT_OUTREACH = 'SETTING_DO_NOT_OUTREACH';
export const SET_DO_NOT_OUTREACH = 'SET_DO_NOT_OUTREACH';
export const SET_DO_NOT_OUTREACH_ERROR = 'SET_DO_NOT_OUTREACH_ERROR';
export const LOAD_OUTREACH_SETTING_START = 'LOAD_OUTREACH_SETTING_START';
export const LOAD_OUTREACH_SETTING_FAILURE = 'LOAD_OUTREACH_SETTING_FAILURE';
export const LOAD_OUTREACH_SETTING_SUCCESS = 'LOAD_OUTREACH_SETTING_SUCCESS';
export const RESET_OUTREACH_SETTING = 'RESET_OUTREACH_SETTING';

export const GETTING_OUTREACH_DECLINED_ENUM = 'GETTING_OUTREACH_DECLINED_ENUM';
export const GOT_OUTREACH_DECLINED_ENUM = 'GOT_OUTREACH_DECLINED_ENUM';
export const GOT_OUTREACH_DECLINED_ENUM_ERROR =
	'GOT_OUTREACH_DECLINED_ENUM_ERROR';

export const ENCOUNTER_RECLAIM_START = 'ENCOUNTER_RECLAIM_START';
export const ENCOUNTER_RECLAIM_SUCCESS = 'ENCOUNTER_RECLAIM_SUCCESS';
export const ENCOUNTER_RECLAIM_FAILURE = 'ENCOUNTER_RECLAIM_FAILURE';
export const ENCOUNTER_RECLAIM_RESET = 'ENCOUNTER_RECLAIM_RESET';

export const V2_DASHBOARD_REFERRAL_PROGRAMS_START =
	'V2_DASHBOARD_REFERRAL_PROGRAMS_START';
export const V2_DASHBOARD_REFERRAL_PROGRAMS_SUCCESS =
	'V2_DASHBOARD_REFERRAL_PROGRAMS_SUCCESS';
export const V2_DASHBOARD_REFERRAL_PROGRAMS_FAILURE =
	'V2_DASHBOARD_REFERRAL_PROGRAMS_FAILURE';
export const V2_DASHBOARD_REFERRAL_PROGRAMS_RESET =
	'V2_DASHBOARD_REFERRAL_PROGRAMS_RESET';

export const V2_OUTREACH_REPORTS_START = 'V2_LOADING_OUTREACHREPORTS_START';
export const V2_OUTREACH_REPORTS_SUCCESS = 'V2_LOADED_OUTREACHREPORTS_SUCCESS';
export const V2_OUTREACH_REPORTS_FAILURE = 'V2_ERROR_OUTREACHREPORTS_FAILURE';

export const DASHBOARD_ROR_OUTREACH_PENDING_START =
	'DASHBOARD_ROR_OUTREACH_PENDING_START';
export const DASHBOARD_ROR_OUTREACH_PENDING_SUCCESS =
	'DASHBOARD_ROR_OUTREACH_PENDING_SUCCESS';
export const DASHBOARD_ROR_OUTREACH_PENDING_FAILURE =
	'DASHBOARD_ROR_OUTREACH_PENDING_FAILURE';
export const DASHBOARD_ROR_OUTREACH_PENDING_RESET =
	'DASHBOARD_ROR_OUTREACH_PENDING_RESET';

//Duplicate patient check
export const DUPLICATE_PATIENT_CHECK_LOADING =
	'DUPLICATE_PATIENT_CHECK_LOADING';
export const DUPLICATE_PATIENT_CHECK = 'DUPLICATE_PATIENT_CHECK';
export const DUPLICATE_PATIENT_CHECK_LOADING_ERROR =
	'DUPLICATE_PATIENT_CHECK_LOADING_ERROR';

/* external metrics data */
export const LOAD_METRICS_TOTAL_PATIENT_COUNT_START =
	'LOAD_METRICS_TOTAL_PATIENT_COUNT_START';
export const LOAD_METRICS_TOTAL_PATIENT_COUNT_SUCCESS =
	'LOAD_METRICS_TOTAL_PATIENT_COUNT_SUCCESS';
export const LOAD_METRICS_TOTAL_PATIENT_COUNT_FAILURE =
	'LOAD_METRICS_TOTAL_PATIENT_COUNT_FAILURE';
export const UNLOAD_METRICS_TOTAL_PATIENT_COUNT =
	'UNLOAD_METRICS_TOTAL_PATIENT_COUNT';

export const LOAD_METRICS_TOTAL_ENCOUNTER_COUNT_START =
	'LOAD_METRICS_TOTAL_ENCOUNTER_COUNT_START';
export const LOAD_METRICS_TOTAL_ENCOUNTER_COUNT_SUCCESS =
	'LOAD_METRICS_TOTAL_ENCOUNTER_COUNT_SUCCESS';
export const LOAD_METRICS_TOTAL_ENCOUNTER_COUNT_FAILURE =
	'LOAD_METRICS_TOTAL_ENCOUNTER_COUNT_FAILURE';
export const UNLOAD_METRICS_TOTAL_ENCOUNTER_COUNT =
	'UNLOAD_METRICS_TOTAL_ENCOUNTER_COUNT';

/* Preauthorization Test Order */
export const PREAUTHORIZATION_ENUMS = 'PREAUTHORIZATION_ENUMS';

/* get preauthorization Test Orders */
export const LOADING_PRE_AUTH_TEST_ORDERS = 'LOADING_PRE_AUTH_TEST_ORDERS';
export const LOADED_PRE_AUTH_TEST_ORDERS = 'LOADED_PRE_AUTH_TEST_ORDERS';
export const ERROR_PRE_AUTH_TEST_ORDERS = 'ERROR_PRE_AUTH_TEST_ORDERS';

export const LOADING_TEST_ORDER_REQUEST = 'LOADING_TEST_ORDER_REQUEST';
export const LOADED_TEST_ORDER_REQUEST = 'LOADED_TEST_ORDER_REQUEST';
export const ERROR_TEST_ORDER_REQUEST = 'ERROR_TEST_ORDER_REQUEST';

export const LOADING_GENE_TEST_ORDER_ENUMS = 'LOADING_GENE_TEST_ORDER_ENUMS';
export const GENE_TEST_ORDER_ENUMS = 'GENE_TEST_ORDER_ENUMS';
export const GENE_TEST_ORDER_ENUMS_ERROR = 'GENE_TEST_ORDER_ENUMS_ERROR';

export const TEST_ORDER_UPDATING = 'TEST_ORDER_UPDATING';
export const TEST_ORDER_UPDATE = 'TEST_ORDER_UPDATE';
export const TEST_ORDER_UPDATE_ERROR = 'TEST_ORDER_UPDATE_ERROR';

export const CREATING_TEST_ORDER_FLAGS = 'CREATING_TEST_ORDER_FLAGS';
export const CREATED_TEST_ORDER_FLAGS = 'CREATED_TEST_ORDER_FLAGS';
export const CREATED_TEST_ORDER_FLAGS_ERROR = 'CREATED_TEST_ORDER_FLAGS_ERROR';

export const UPDATING_TEST_ORDER_FLAGS = 'UPDATING_TEST_ORDER_FLAGS';
export const UPDATED_TEST_ORDER_FLAGS = 'UPDATED_TEST_ORDER_FLAGS';
export const UPDATED_TEST_ORDER_FLAGS_ERROR = 'UPDATED_TEST_ORDER_FLAGS_ERROR';

//SCP Test Authorization
export const SCP_TEST_AUTH_SEARCHING = 'SCP_TEST_AUTH_SEARCHING';
export const SCP_TEST_AUTH_SEARCH = 'SCP_TEST_AUTH_SEARCH';
export const SCP_TEST_AUTH_SEARCH_ERROR = 'SCP_TEST_AUTH_SEARCH_ERROR';

export const GET_TEST_AUTH_ENUMS_STARTED = 'GET_TEST_AUTH_ENUMS_STARTED';
export const GET_TEST_AUTH_ENUMS_SUCCESS = 'GET_TEST_AUTH_ENUMS_SUCCESS';
export const GET_TEST_AUTH_ENUMS_FAILED = 'GET_TEST_AUTH_ENUMS_FAILED';

export const GETTING_GENETIC_TESTS = 'GETTING_GENETIC_TESTS';
export const GOT_GENETIC_TESTS = 'GOT_GENETIC_TESTS';
export const GOT_GENETIC_TESTS_ERROR = 'GOT_GENETIC_TESTS_ERROR';

export const TEST_BULK_UPDATING = 'TEST_BULK_UPDATING';
export const TEST_BULK_UPDATE = 'TEST_BULK_UPDATE';
export const TEST_BULK_UPDATE_ERROR = 'TEST_BULK_UPDATE_ERROR';

export const GETTING_SCP_CLINICAL_INFO = 'GETTING_SCP_CLINICAL_INFO';
export const GETTING_SCP_CLINICAL_INFO_SUCCESS =
	'GETTING_SCP_CLINICAL_INFO_SUCCESS';
export const GETTING_SCP_CLINICAL_INFO_ERROR =
	'GETTING_SCP_CLINICAL_INFO_ERROR';

export const TEST_ORDER_RED_FLAG_UPDATING = 'TEST_ORDER_RED_FLAG_UPDATING';
export const TEST_ORDER_RED_FLAG_UPDATE = 'TEST_ORDER_RED_FLAG_UPDATE';
export const TEST_ORDER_RED_FLAG_UPDATE_ERROR =
	'TEST_ORDER_RED_FLAG_UPDATE_ERROR';

export const ENCOUNTER_DOCUMENTS_LOADING = 'ENCOUNTER_DOCUMENTS_LOADING';
export const ENCOUNTER_DOCUMENTS_SUCCESS = 'ENCOUNTER_DOCUMENTS_SUCCESS';
export const ENCOUNTER_DOCUMENTS_FAILED = 'ENCOUNTER_DOCUMENTS_FAILED';

// Test Order Notes
export const LOADING_TEST_ORDER_NOTES = 'LOADING_TEST_ORDER_NOTES';
export const LOADED_TEST_ORDER_NOTES = 'LOADED_TEST_ORDER_NOTES';
export const ERROR_TEST_ORDER_NOTES = 'ERROR_TEST_ORDER_NOTES';

//Audit trail
export const GET_AUDIT_TRAIL_ENCOUNTER_START =
	'GET_AUDIT_TRAIL_ENCOUNTER_START';
export const GET_AUDIT_TRAIL_ENCOUNTER_SUCCESS =
	'GET_AUDIT_TRAIL_ENCOUNTER_SUCCESS';
export const GET_AUDIT_TRAIL_ENCOUNTER_ERROR =
	'GET_AUDIT_TRAIL_ENCOUNTER_ERROR';

export const GET_ENCOUNTER_AUDIT_ENUM_START = 'GET_ENCOUNTER_AUDIT_ENUM_START';
export const GET_ENCOUNTER_AUDIT_ENUM_SUCCESS =
	'GET_ENCOUNTER_AUDIT_ENUM_SUCCESS';
export const GET_ENCOUNTER_AUDIT_ENUM_ERROR = 'GET_ENCOUNTER_AUDIT_ENUM_ERROR';

export const GET_ENCOUNTER_BILLING_ENUM_START =
	'GET_ENCOUNTER_BILLING_ENUM_START';
export const GET_ENCOUNTER_BILLING_ENUM_SUCCESS =
	'GET_ENCOUNTER_BILLING_ENUM_SUCCESS';
export const GET_ENCOUNTER_BILLING_ENUM_ERROR =
	'GET_ENCOUNTER_BILLING_ENUM_ERROR';

//Content viewed by patient
export const GET_CONTENT_VIEWED_BY_PATIENT_START =
	'GET_CONTENT_VIEWED_BY_PATIENT_START';
export const GET_CONTENT_VIEWED_BY_PATIENT_SUCCESSS =
	'GET_CONTENT_VIEWED_BY_PATIENT_SUCCESSS';
export const GET_CONTENT_VIEWED_BY_PATIENT_ERROR =
	'GET_CONTENT_VIEWED_BY_PATIENT_ERROR';

// UI Config
export const UPDATE_RESULTS_PER_PAGE = 'UPDATE_RESULTS_PER_PAGE';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const RESET_SNACKBAR = 'RESET_SNACKBAR';

//Provider linking to patients
export const PROVIDERS_PATIENT_LINKING_START =
	'PROVIDERS_PATIENT_LINKING_START';
export const PROVIDERS_PATIENT_LINKING_SUCCESS =
	'PROVIDERS_PATIENT_LINKING_SUCCESS';
export const PROVIDERS_PATIENT_LINKING_ERROR =
	'PROVIDERS_PATIENT_LINKING_ERROR';

//Get linked providers to patient
export const GET_LINKED_PATIENT_PROVIDERS_START =
	'GET_LINKED_PATIENT_PROVIDERS_START';
export const GET_LINKED_PATIENT_PROVIDERS_SUCCESS =
	'GET_LINKED_PATIENT_PROVIDERS_SUCCESS';
export const GET_LINKED_PATIENT_PROVIDERS_ERROR =
	'GET_LINKED_PATIENT_PROVIDERS_ERROR';

//Unlink the provider
export const GET_UNLINK_PATIENT_PROVIDER_START =
	'GET_UNLINK_PATIENT_PROVIDER_START';
export const GET_UNLINK_PATIENT_PROVIDER_SUCCESS =
	'GET_UNLINK_PATIENT_PROVIDER_SUCCESS';
export const GET_UNLINK_PATIENT_PROVIDER_ERROR =
	'GET_UNLINK_PATIENT_PROVIDER_ERROR';

//Update linked provider
export const GET_UPDATE_LINKED_PATIENT_PROVIDER_START =
	'GET_UPDATE_LINKED_PATIENT_PROVIDER_START';
export const GET_UPDATE_LINKED_PATIENT_PROVIDER_SUCCESS =
	'GET_UPDATE_LINKED_PATIENT_PROVIDER_SUCCESS';
export const GET_UPDATE_LINKED_PATIENT_PROVIDER_ERROR =
	'GET_UPDATE_LINKED_PATIENT_PROVIDER_ERROR';

//Create provider
export const CREATING_PROVIDER = 'CREATING_PROVIDER';
export const CREATE_PROVIDER_SUCCESS = 'CREATE_PROVIDER_SUCCESS';
export const CREATE_PROVIDER_FAILURE = 'CREATE_PROVIDER_FAILURE';

//Update provider
export const UPDATING_PROVIDER = 'UPDATING_PROVIDER';
export const UPDATE_PROVIDER_SUCCESS = 'UPDATE_PROVIDER_SUCCESS';
export const UPDATE_PROVIDER_FAILURE = 'UPDATE_PROVIDER_FAILURE';

//Provider linking to encounter
export const PROVIDERS_ENCOUNTER_LINKING_START =
	'PROVIDERS_ENCOUNTER_LINKING_START';
export const PROVIDERS_ENCOUNTER_LINKING_SUCCESS =
	'PROVIDERS_ENCOUNTER_LINKING_SUCCESS';
export const PROVIDERS_ENCOUNTER_LINKING_ERROR =
	'PROVIDERS_ENCOUNTER_LINKING_ERROR';

//Provider specialties enum
export const GETTING_PROVIDER_SPECIALTIES = 'GETTING_PROVIDER_SPECIALTIES';
export const GOT_PROVIDER_SPECIALTIES = 'GOT_PROVIDER_SPECIALTIES';
export const GOT_PROVIDER_SPECIALTIES_ERROR = 'GOT_PROVIDER_SPECIALTIES_ERROR';

//Provider credentials enum
export const GETTING_PROVIDER_CREDENTIALS = 'GETTING_PROVIDER_CREDENTIALS';
export const GOT_PROVIDER_CREDENTIALS = 'GOT_PROVIDER_CREDENTIALS';
export const GOT_PROVIDER_CREDENTIALS_ERROR = 'GOT_PROVIDER_CREDENTIALS_ERROR';

//Document audit trail
export const GET_DOCUMENT_AUDIT_TRAIL_START = 'GET_DOCUMENT_AUDIT_TRAIL_START';
export const GET_DOCUMENT_AUDIT_TRAIL_SUCCESS =
	'GET_DOCUMENT_AUDIT_TRAIL_SUCCESS';
export const GET_DOCUMENT_AUDIT_TRAIL_FAILURE =
	'GET_DOCUMENT_AUDIT_TRAIL_FAILURE';

//provider unlink from encounter
export const GET_UNLINK_ENCOUNTER_PROVIDER_START =
	'GET_UNLINK_ENCOUNTER_PROVIDER_START';
export const GET_UNLINK_ENCOUNTER_PROVIDER_SUCCESS =
	'GET_UNLINK_ENCOUNTER_PROVIDER_SUCCESS';
export const GET_UNLINK_ENCOUNTER_PROVIDER_ERROR =
	'GET_UNLINK_ENCOUNTER_PROVIDER_ERROR';

//Patient tags enum
export const GET_PATIENT_TAGS_START = 'GET_PATIENT_TAGS_START';
export const GET_PATIENT_TAGS_SUCCESS = 'GET_PATIENT_TAGS_SUCCESS';
export const GET_PATIENT_TAGS_ERROR = 'GET_PATIENT_TAGS_ERROR';

//appData
export const SET_APPDATA_FILTER = 'SET_APPDATA_FILTER';

//Gender enum
export const GET_GENDER_START = 'GET_GENDER_START';
export const GET_GENDER_SUCCESS = 'GET_GENDER_SUCCESS';
export const GET_GENDER_ERROR = 'GET_GENDER_ERROR';

//Pronouns enum
export const GET_PRONOUNS_START = 'GET_PRONOUNS_START';
export const GET_PRONOUNS_SUCCESS = 'GET_PRONOUNS_SUCCESS';
export const GET_PRONOUNS_ERROR = 'GET_PRONOUNS_ERROR';

//Sex assigned at birth enum
export const GET_SEX_ASSIGNED_AT_BIRTH_START =
	'GET_SEX_ASSIGNED_AT_BIRTH_START';
export const GET_SEX_ASSIGNED_AT_BIRTH_SUCCESS =
	'GET_SEX_ASSIGNED_AT_BIRTH_SUCCESS';
export const GET_SEX_ASSIGNED_AT_BIRTH_ERROR =
	'GET_SEX_ASSIGNED_AT_BIRTH_ERROR';

// Drug exposures (GraphQL)
export const PATIENT_DRUG_EXPOSURES_LOADING = 'PATIENT_DRUG_EXPOSURES_LOADING';
export const PATIENT_DRUG_EXPOSURES_SUCCESS = 'PATIENT_DRUG_EXPOSURES_SUCCESS';
export const PATIENT_DRUG_EXPOSURES_ERROR = 'PATIENT_DRUG_EXPOSURES_ERROR';

export const ADD_PATIENT_DRUG_EXPOSURE_NOTE_LOADING =
	'ADD_PATIENT_DRUG_EXPOSURE_NOTE_LOADING';
export const ADD_PATIENT_DRUG_EXPOSURE_NOTE_SUCCESS =
	'ADD_PATIENT_DRUG_EXPOSURE_NOTE_SUCCESS';
export const ADD_PATIENT_DRUG_EXPOSURE_NOTE_ERROR =
	'ADD_PATIENT_DRUG_EXPOSURE_NOTE_ERROR';

// Diagnostic Reports (GraphQL)
export const GET_DIAGNOSTIC_REPORTS_LOADING = 'GET_DIAGNOSTIC_REPORTS_LOADING';
export const GET_DIAGNOSTIC_REPORTS_SUCCESS = 'GET_DIAGNOSTIC_REPORTS_SUCCESS';
export const GET_DIAGNOSTIC_REPORTS_ERROR = 'GET_DIAGNOSTIC_REPORTS_ERROR';
export const RESET_GET_DIAGNOSTIC_REPORTS = 'RESET_GET_DIAGNOSTIC_REPORTS';

export const ADD_DIAGNOSTIC_FINDING_LOADING = 'ADD_DIAGNOSTIC_FINDING_LOADING';
export const ADD_DIAGNOSTIC_FINDING_SUCCESS = 'ADD_DIAGNOSTIC_FINDING_SUCCESS';
export const ADD_DIAGNOSTIC_FINDING_ERROR = 'ADD_DIAGNOSTIC_FINDING_ERROR';

// Diagnostic Reports Clinical Note
export const ADD_DIAGNOSTIC_FINDING_CLINICAL_NOTE_LOADING =
	'ADD_DIAGNOSTIC_FINDING_CLINICAL_NOTE_LOADING';
export const ADD_DIAGNOSTIC_FINDING_CLINICAL_NOTE_SUCCESS =
	'ADD_DIAGNOSTIC_FINDING_CLINICAL_NOTE_SUCCESS';
export const ADD_DIAGNOSTIC_FINDING_CLINICAL_NOTE_ERROR =
	'ADD_DIAGNOSTIC_FINDING_CLINICAL_NOTE_ERROR';

// Risk Assessment Reports (GraphQL)

// REFERENCE_ASSEMBLIES_ENUM (GraphQL)
export const GET_REFERENCE_ASSEMBLIES_ENUM_LOADING =
	'GET_REFERENCE_ASSEMBLIES_ENUM_LOADING';
export const GET_REFERENCE_ASSEMBLIES_ENUM_SUCCESS =
	'GET_REFERENCE_ASSEMBLIES_ENUM_SUCCESS';
export const GET_REFERENCE_ASSEMBLIES_ENUM_ERROR =
	'GET_REFERENCE_ASSEMBLIES_ENUM_ERROR';

// Genetic Result Genes Symbol Enum  getGeneticResultGenesSymbolEnumLoading (GraphQL)
export const GET_GENETIC_RESULT_GENES_SYMBOL_ENUM_LOADING =
	'GET_GENETIC_RESULT_GENES_SYMBOL_ENUM_LOADING';
export const GET_GENETIC_RESULT_GENES_SYMBOL_ENUM_SUCCESS =
	'GET_GENETIC_RESULT_GENES_SYMBOL_ENUM_SUCCESS';
export const GET_GENETIC_RESULT_GENES_SYMBOL_ENUM_ERROR =
	'GET_GENETIC_RESULT_GENES_SYMBOL_ENUM_ERROR';
export const RESET_GET_GENETIC_RESULT_GENES_SYMBOL_ENUM =
	'RESET_GET_GENETIC_RESULT_GENES_SYMBOL_ENUM';

// Add Diagnostic Reports for Discrete Variant Finding (GraphQL)
export const ADD_DISCRETEVARIANT_FINDING_LOADING =
	'ADD_DISCRETEVARIANT_FINDING_LOADING';
export const ADD_DISCRETEVARIANT_FINDING_SUCCESS =
	'ADD_DISCRETEVARIANT_FINDING_SUCCESS';
export const ADD_DISCRETEVARIANT_FINDING_ERROR =
	'ADD_DISCRETEVARIANT_FINDING_ERROR';

// Add Diagnostic Reports for Structural Variant Finding (GraphQL)
export const ADD_STRUCTURALVARIANT_FINDING_LOADING =
	'ADD_STRUCTURALVARIANT_FINDING_LOADING';
export const ADD_STRUCTURALVARIANT_FINDING_SUCCESS =
	'ADD_STRUCTURALVARIANT_FINDING_SUCCESS';
export const ADD_STRUCTURALVARIANT_FINDING_ERROR =
	'ADD_STRUCTURALVARIANT_FINDING_ERROR';

// Add Diagnostic Reports for NucleotideRepeatVariant Finding (GraphQL)
export const ADD_NUCLEOTIDEREPEATVARIANT_FINDING_LOADING =
	'ADD_NUCLEOTIDEREPEATVARIANT_FINDING_LOADING';
export const ADD_NUCLEOTIDEREPEATVARIANT_FINDING_SUCCESS =
	'ADD_NUCLEOTIDEREPEATVARIANT_FINDING_SUCCESS';
export const ADD_NUCLEOTIDEREPEATVARIANT_FINDING_ERROR =
	'ADD_NUCLEOTIDEREPEATVARIANT_FINDING_ERROR';

// Diagnostic Finding Validation
export const GET_DIAGNOSTIC_FINDING_VALIDATION_LOADING =
	'GET_DIAGNOSTIC_FINDING_VALIDATION_LOADING';
export const GET_DIAGNOSTIC_FINDING_VALIDATION_SUCCESS =
	'GET_DIAGNOSTIC_FINDING_VALIDATION_SUCCESS';
export const GET_DIAGNOSTIC_FINDING_VALIDATION_ERROR =
	'GET_DIAGNOSTIC_FINDING_VALIDATION_ERROR';
export const RESET_GET_DIAGNOSTIC_FINDING_VALIDATION =
	'RESET_GET_DIAGNOSTIC_FINDING_VALIDATION';

// Structural Finding Validation
export const GET_STRUCTURAL_FINDING_VALIDATION_LOADING =
	'GET_STRUCTURAL_FINDING_VALIDATION_LOADING';
export const GET_STRUCTURAL_FINDING_VALIDATION_SUCCESS =
	'GET_STRUCTURAL_FINDING_VALIDATION_SUCCESS';
export const GET_STRUCTURAL_FINDING_VALIDATION_ERROR =
	'GET_STRUCTURAL_FINDING_VALIDATION_ERROR';
export const RESET_GET_STRUCTURAL_FINDING_VALIDATION =
	'RESET_GET_STRUCTURAL_FINDING_VALIDATION';

// Add Diagnostic Reports for cfDNA Prenatal Screening Finding (GraphQL)
export const ADD_CFDNAPRENATAL_SCREENING_FINDING_LOADING =
	'ADD_CFDNAPRENATAL_SCREENING_FINDING_LOADING';
export const ADD_CFDNAPRENATAL_SCREENING_FINDING_SUCCESS =
	'ADD_CFDNAPRENATAL_SCREENING_FINDING_SUCCESS';
export const ADD_CFDNAPRENATAL_SCREENING_FINDING_ERROR =
	'ADD_CFDNAPRENATAL_SCREENING_FINDING_ERROR';

// Refresh Popup
export const REFRESH_MODAL = 'REFRESH_MODAL';
export const SWITCH_ROLE_MODAL = 'SWITCH_ROLE_MODAL';

// Retry Exceeded
export const RETRY_EXCEEDED = 'RETRY_EXCEEDED';

//Race/ethnicity enums
export const GET_RACE_ENUM_START = 'GET_RACE_ENUM_START';
export const GET_RACE_ENUM_SUCCESS = 'GET_RACE_ENUM_SUCCESS';
export const GET_RACE_ENUM_ERROR = 'GET_RACE_ENUM_ERROR';
export const GET_ETHNICITY_ENUM_START = 'GET_ETHNICITY_ENUM_START';
export const GET_ETHNICITY_ENUM_SUCCESS = 'GET_ETHNICITY_ENUM_SUCCESS';
export const GET_ETHNICITY_ENUM_ERROR = 'GET_ETHNICITY_ENUM_ERROR';

//Patient fee enum
export const PATIENT_FEE_ENUM_LOADING = 'PATIENT_FEE_ENUM_LOADING';
export const PATIENT_FEE_ENUM_SUCCESS = 'PATIENT_FEE_ENUM_SUCCESS';
export const PATIENT_FEE_ENUM_ERROR = 'PATIENT_FEE_ENUM_ERROR';

//Visit indications
export const GETTING_VISIT_INDICATIONS = 'GETTING_VISIT_INDICATIONS';
export const GOT_VISIT_INDICATIONS = 'GOT_VISIT_INDICATIONS';
export const GOT_VISIT_INDICATIONS_ERROR = 'GOT_VISIT_INDICATIONS_ERROR';

//Insurance plan types
export const GETTING_PLAN_TYPES = 'GETTING_PLAN_TYPES';
export const GOT_PLAN_TYPES = 'GOT_PLAN_TYPES';
export const GOT_PLAN_TYPES_ERROR = 'GOT_PLAN_TYPES_ERROR';

//Rise Analaytic
export const LOADING_RISE_DASHBOARD_DATA = 'LOADING_RISE_DASHBOARD_DATA';
export const LOADED_RISE_DASHBOARD_DATA = 'LOADED_RISE_DASHBOARD_DATA';
export const RISE_DASHBOARD_DATA_ERROR = 'RISE_DASHBOARD_DATA_ERROR';

export const LOADING_RISE_DASHBOARD_EXPORT_REPORT =
	'LOADING_RISE_DASHBOARD_EXPORT_REPORT';
export const LOADED_RISE_DASHBOARD_EXPORT_REPORT =
	'LOADED_RISE_DASHBOARD_EXPORT_REPORT';
export const RISE_DASHBOARD_EXPORT_REPORT_ERROR =
	'RISE_DASHBOARD_EXPORT_REPORT_ERROR';

export const UPDATING_CONSENT_STATUS = 'UPDATING_CONSENT_STATUS';
export const UPDATE_CONSENT_STATUS = 'UPDATE_CONSENT_STATUS';
export const UPDATE_CONSENT_STATUS_ERROR = 'UPDATE_CONSENT_STATUS_ERROR';

// HTTP handler
export const REQUEST_AUTHORIZATION_STATUS = 'REQUEST_AUTHORIZATION_STATUS';

export const GETTING_PATIENT_OUTREACH_ROUNDS = 'GETTING_PATIENT_OUTREACH_ROUNDS';
export const GOT_PATIENT_OUTREACH_ROUNDS_SUCCESS = 'GOT_PATIENT_OUTREACH_ROUNDS_SUCCESS';
export const GOT_PATIENT_OUTREACH_ROUNDS_FAILURE = 'GOT_PATIENT_OUTREACH_ROUNDS_FAILURE';