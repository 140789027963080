import * as actionTypes from '../actions/actionTypes';
import _ from 'lodash';

const defaultTestEnums = {
	data: [],
	selectedlabuuid: undefined,
	error: null,
	loading: false,
	loaded: false,
};

const defaultAlternateTestEnums = {
	data: [],
	selectedlabuuid: undefined,
	error: null,
	loading: false,
	loaded: false,
};

const defaultEditTestOrderTestEnums = {
	data: [],
	selectedlabuuid: undefined,
	error: null,
	loading: false,
	loaded: false,
};

const defaultEncounterReclaim = {
	data: null,
	error: null,
	loading: false,
	loaded: false,
};

const defaultTestOrderRequest = {
	data: null,
	error: null,
	loading: false,
	loaded: false,
};

const defaultPreauthTestOrders = {
	data: null,
	error: null,
	loading: false,
	loaded: false,
};

const defaultTestOrderNotes = {
	data: null,
	error: null,
	loading: false,
	loaded: false,
};

const defaultCommunications = {
	loading: false,
	data: null,
	loaded: false,
	error: null,
};

const defaultEncounterLabsSchema = {
	gettingLabsEnum: false,
	schema: {},
	schemaError: null,
};

const defaultGeneTestOrderStatusEnum = {
	geneTestOrderStatusEnumLoading: false,
	geneTestOrderStatusEnum: null,
	geneTestOrderStatusEnumError: null,
};

const defaultTestAuthorization = {
	testAuthSearchLoading: false,
	testAuthData: null,
	testAuthSearchError: null,
};

const defaultVisitSchema = {
	loadingSchema: false,
	schema: {},
	schemaError: null,
};
const defaultGroupsessionSchema = {
	loadingSchema: false,
	schema: {},
	schemaError: null,
};
const defaultCCIntakeSchema = {
	loadingSchema: false,
	schema: {},
	schemaError: null,
};
const defaultResearchDataSchema = {
	loadingSchema: false,
	schema: {},
	schemaError: null,
};

const defaultEncounterList = {
	loading: false,
	encounter: [],
	encounterError: null,
};

const defaultPartnerInvoiceStatus = {
	loadingPartnerInvoiceStatus: false,
	'research-data': [],
	partnerInvoiceStatusError: null,
};

const defaultOrderRequest = {
	loadingOrderRequest: false,
	orderrequest: {},
	orderRequestError: null,
};

const defaultGeneTestEnums = {
	loadingGeneTestEnums: false,
	gentestenums: {},
	geneTestEnumsError: null,
};

const defaultEncounterDocuments = {
	loading: false,
	data: [],
	error: null,
};

const initialStateMigrateToPioneerRCO = {
	migratedDataRCO: null,
	migrationErrorRCO: null,
	isMigratingRCO: false,
};

export const migrateToPioneerRCO = (
	state = initialStateMigrateToPioneerRCO,
	action
) => {
	switch (action.type) {
		case actionTypes.MIGRATING_TO_PIONEER_RCO:
			return {
				...state,
				isMigratingRCO: true,
			};
		case actionTypes.MIGRATED_TO_PIONEER_RCO:
			return {
				...state,
				isMigratingRCO: false,
				migratedDataRCO: action.payload,
				migrationErrorRCO: null,
			};
		case actionTypes.MIGRATED_TO_PIONEER_ERROR_RCO:
			return {
				...state,
				isMigratingRCO: false,
				migrationErrorRCO: action.payload,
			};
		default:
			return state;
	}
};

export const encounterlist = (state = defaultEncounterList, action) => {
	if (action.type == actionTypes.ENCOUNTER_LIST_LOADING) {
		const newState = Object.assign({}, state);
		newState.loading = action.payload;
		newState.encounterError = null;
		return newState;
	} else if (action.type === actionTypes.ENCOUNTER_LIST) {
		const newState = Object.assign({}, state);
		newState.loading = false;
		newState.encounter = action.payload;
		return newState;
	} else if (action.type == actionTypes.ENCOUNTER_LIST_ERROR) {
		const newState = Object.assign({}, state);
		newState.loading = false;
		newState.encounterError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const encounterdetail = (state = { encounter: {} }, action) => {
	if (action.type === actionTypes.ENCOUNTER_DETAIL) {
		const newState = Object.assign({}, state);
		newState.encounter = action.payload;
		newState.gettingEncounterDetailLoading = false;
		newState.gettingEncounterDetailError = false;
		return newState;
	} else if (action.type === actionTypes.GETTING_ENCOUNTER_DETAIL) {
		const newState = Object.assign({}, state);
		newState.gettingEncounterDetailLoading = true;
		newState.gettingEncounterDetailError = false;
		return newState;
	} else if (action.type === actionTypes.ENCOUNTER_DETAIL_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingEncounterDetailLoading = false;
		newState.gettingEncounterDetailError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const addencounter = (state = { encounter: {} }, action) => {
	if (action.type === actionTypes.ADD_ENCOUNTER) {
		const newState = Object.assign({}, state);
		newState.encounterCreateLoading = false;
		newState.encounter = action.payload;
		newState.encounterCreateError = null;
		return newState;
	} else if (action.type === actionTypes.ADDING_ENCOUNTER) {
		const newState = Object.assign({}, state);
		newState.encounterCreateLoading = true;
		newState.encounter = {};
		newState.encounterCreateError = null;
		return newState;
	} else if (action.type === actionTypes.ADD_ENCOUNTER_ERROR) {
		const newState = Object.assign({}, state);
		newState.encounterCreateLoading = false;
		newState.encounter = {};
		newState.encounterCreateError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const editencounter = (state = { encounter: {} }, action) => {
	if (action.type === actionTypes.EDIT_ENCOUNTER) {
		const newState = Object.assign({}, state);
		newState.encounter = action.payload;
		newState.encounterEditLoading = false;
		newState.encounterEditError = null;
		return newState;
	} else if (action.type === actionTypes.EDITING_ENCOUNTER) {
		const newState = Object.assign({}, state);
		newState.encounter = null;
		newState.encounterEditLoading = true;
		newState.encounterEditError = null;
		return newState;
	} else if (action.type === actionTypes.EDIT_ENCOUNTER_FAILURE) {
		const newState = Object.assign({}, state);
		newState.encounter = null;
		newState.encounterEditLoading = false;
		newState.encounterEditError = action.payload;
		return newState;
	} else if (action.type === actionTypes.SAVING_ENCOUNTER_BILLING_DATA) {
		const newState = Object.assign({}, state);
		newState.billingEncounter = null;
		newState.billingEncounterEditLoading = true;
		newState.billingEncounterEditError = null;
		return newState;
	} else if (action.type === actionTypes.SAVE_ENCOUNTER_BILLING_DATA) {
		const newState = Object.assign({}, state);
		newState.billingEncounter = action.payload;
		newState.billingEncounterEditLoading = false;
		newState.billingEncounterEditError = null;
		return newState;
	} else if (action.type === actionTypes.SAVING_ECOUNTER_BILLING_DATA_ERROR) {
		const newState = Object.assign({}, state);
		newState.billingEncounter = null;
		newState.billingEncounterEditLoading = false;
		newState.billingEncounterEditError = action.payload;
		return newState;
	} else if (action.type === actionTypes.COMPLETING_ENCOUNTER) {
		const newState = Object.assign({}, state);
		newState.encounterCompleteLoading = true;
		newState.encounterCompleteError = null;
		return newState;
	} else if (action.type === actionTypes.COMPLETE_ENCOUNTER) {
		const newState = Object.assign({}, state);
		newState.encounterCompleteLoading = false;
		newState.encounterCompleteError = null;
		return newState;
	} else if (action.type === actionTypes.COMPLETE_ENCOUNTER_ERROR) {
		const newState = Object.assign({}, state);
		newState.encounterCompleteLoading = false;
		newState.encounterCompleteError = action.payload;
		return newState;
	} else if (action.type === actionTypes.CANCELLING_ENCOUNTER) {
		const newState = Object.assign({}, state);
		newState.encounterCancelLoading = true;
		newState.encounterCancelLoadingError = null;
		return newState;
	} else if (action.type === actionTypes.CANCEL_ENCOUNTER) {
		const newState = Object.assign({}, state);
		newState.encounterCancelLoading = false;
		newState.encounterCancelLoadingError = null;
		return newState;
	} else if (action.type === actionTypes.CANCEL_ENCOUNTER_ERROR) {
		const newState = Object.assign({}, state);
		newState.encounterCancelLoading = false;
		newState.encounterCancelLoadingError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const orderrequest = (state = defaultOrderRequest, action) => {
	if (action.type === actionTypes.LOADING_ORDER_REQUEST) {
		const newState = Object.assign({}, state);
		newState.loadingOrderRequest = true;
		newState.orderRequestError = null;
		return newState;
	} else if (action.type === actionTypes.ORDER_REQUEST) {
		const newState = Object.assign({}, state);
		newState.loadingOrderRequest = false;
		newState.orderrequest = action.payload;
		return newState;
	} else if (action.type === actionTypes.ORDER_REQUEST_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingOrderRequest = false;
		newState.orderRequestError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const encountergroupsessionschema = (
	state = defaultGroupsessionSchema,
	action
) => {
	if (action.type === actionTypes.LOADING_GROUP_SESSION_SCHEMA) {
		const newState = Object.assign({}, state);
		newState.loadingSchema = true;
		newState.schemaError = null;
		return newState;
	} else if (action.type === actionTypes.GROUP_SESSION_SCHEMA) {
		const newState = Object.assign({}, state);
		newState.loadingSchema = false;
		newState.schema = sortEncounterSchemaValues(action); //action.payload;
		newState.schemaError = null;
		return newState;
	} else if (action.type === actionTypes.GROUP_SESSION_SCHEMA_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingSchema = false;
		newState.schemaError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const encounterccintakeschema = (
	state = defaultCCIntakeSchema,
	action
) => {
	if (action.type === actionTypes.LOADING_CC_INTAKE_SCHEMA) {
		const newState = Object.assign({}, state);
		newState.loadingSchema = true;
		newState.schemaError = null;
		return newState;
	} else if (action.type === actionTypes.CC_INTAKE_SCHEMA) {
		const newState = Object.assign({}, state);
		newState.loadingSchema = false;
		newState.schema = sortEncounterSchemaValues(action); //action.payload;
		newState.schemaError = null;
		return newState;
	} else if (action.type === actionTypes.CC_INTAKE_SCHEMA_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingSchema = false;
		newState.schemaError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const encountervisitsschema = (state = defaultVisitSchema, action) => {
	if (action.type === actionTypes.LOADING_VISITS_SCHEMA) {
		const newState = Object.assign({}, state);
		newState.loadingSchema = true;
		newState.schemaError = null;
		return newState;
	} else if (action.type === actionTypes.VISITS_SCHEMA) {
		const newState = Object.assign({}, state);
		newState.loadingSchema = false;
		newState.schema = sortEncounterSchemaValues(action); //action.payload;
		newState.schemaError = null;
		return newState;
	} else if (action.type === actionTypes.VISITS_SCHEMA_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingSchema = false;
		newState.schemaError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const encounterlabsschema = (
	state = defaultEncounterLabsSchema,
	action
) => {
	if (action.type === actionTypes.LABS_SCHEMA) {
		const newState = Object.assign({}, state);
		newState.gettingLabsEnum = false;
		newState.schema = sortEncounterSchemaValues(action); //{data: sorted_list}; //action.payload;
		newState.schemaError = null;
		return newState;
	} else if (action.type === actionTypes.GETTING_LABS_ENUM) {
		const newState = Object.assign({}, state);
		newState.gettingLabsEnum = true;
		newState.schema = null;
		newState.schemaError = null;
		return newState;
	} else if (action.type === actionTypes.LABS_SCHEMA_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingLabsEnum = false;
		newState.schema = null;
		newState.schemaError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const encounterresearchdataschema = (
	state = defaultResearchDataSchema,
	action
) => {
	if (action.type === actionTypes.LOADING_RESEARCH_DATA_SCHEMA) {
		const newState = Object.assign({}, state);
		newState.loadingSchema = true;
		newState.schemaError = null;
		return newState;
	} else if (action.type === actionTypes.RESEARCH_DATA_SCHEMA) {
		const newState = Object.assign({}, state);
		newState.loadingSchema = false;
		newState.schema = sortEncounterSchemaValues(action);
		newState.schemaError = null;
		return newState;
	} else if (action.type === actionTypes.RESEARCH_DATA_SCHEMA_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingSchema = false;
		newState.schemaError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const visitproviderenum = (
	state = {
		'group-session': [],
		'cc-intake': [],
		lab_test_authorization: [],
		visit: [],
	},
	action
) => {
	if (action.type === actionTypes.LOADING_VISIT_PROVIDER_ENUM) {
		const newState = Object.assign({}, state);
		newState['loading'] = true;
		newState[
			`loading-${_.get(action, 'encountertype', 'encounter')}`
		] = true;
		newState[`error-${_.get(action, 'encountertype', 'encounter')}`] = null;
		newState['error'] = null;
		return newState;
	} else if (action.type === actionTypes.VISIT_PROVIDER_ENUM) {
		const newState = Object.assign({}, state);
		newState[action.encountertype] = _.get(
			sortEncounterSchemaValues(action),
			['data', 'visit_provider'],
			[]
		); //{data: sorted_list}; //action.payload;
		newState[
			`loading-${_.get(action, 'encountertype', 'encounter')}`
		] = false;
		newState[`error-${_.get(action, 'encountertype', 'encounter')}`] = null;
		newState['error'] = null;
		return newState;
	} else if (action.type === actionTypes.VISIT_PROVIDER_ENUM_ERROR) {
		const newState = Object.assign({}, state);
		newState['loading'] = false;
		newState[
			`loading-${_.get(action, 'encountertype', 'encounter')}`
		] = false;
		newState[`error-${_.get(action, 'encountertype', 'encounter')}`] =
			action.payload;
		newState['error'] = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const visitconsultationtypes = (
	state = { VisitConsultationTypes: [] },
	action
) => {
	if (action.type === actionTypes.GETTING_VISIT_CONSULTATION_TYPES) {
		const newState = Object.assign({}, state);
		newState.gettingvisitConsultationTypes = true;
		newState.visitConsultationTypesError = null;
		return newState;
	} else if (action.type === actionTypes.VISIT_CONSULTATION_TYPES) {
		const newState = Object.assign({}, state);
		newState.gettingvisitConsultationTypes = false;
		newState.VisitConsultationTypes = action.payload;
		newState.visitConsultationTypesError = null;
		return newState;
	} else if (action.type === actionTypes.VISIT_CONSULTATION_TYPES_ERROR) {
		const newState = Object.assign({}, state);
		newState.gettingvisitConsultationTypes = false;
		newState.VisitConsultationTypes = [];
		newState.visitConsultationTypesError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const payorEnum = (
	state = { gettingPayorEnum: false, payorEnum: [], payorEnumError: null },
	action
) => {
	switch (action.type) {
		case actionTypes.GETTING_PAYOR_ENUM:
			return {
				...state,
				gettingPayorEnum: true,
				payorEnumError: null,
			};

		case actionTypes.PAYOR_ENUMS:
			return {
				...state,
				gettingPayorEnum: false,
				payorEnum: action.payload,
				payorEnumError: null,
			};

		case actionTypes.PAYOR_ENUMS_ERROR:
			return {
				...state,
				gettingPayorEnum: false,
				payorEnum: [],
				payorEnumError: action.payload,
			};

		default:
			return state;
	}
};

export const orderingphysicianenum = (
	state = {
		'group-session': [],
		'cc-intake': [],
		lab_test_authorization: [],
		visit: [],
	},
	action
) => {
	if (action.type === actionTypes.LOADING_ORDERING_PHYSICIAN) {
		const newState = Object.assign({}, state);
		newState['loading'] = true;
		newState[
			`loading-${_.get(action, 'encountertype', 'encounter')}`
		] = true;
		newState[`error-${_.get(action, 'encountertype', 'encounter')}`] = null;
		newState['error'] = null;
		return newState;
	} else if (action.type === actionTypes.ORDERING_PHYSICIAN) {
		const newState = Object.assign({}, state);
		newState[action.encountertype] = _.get(
			sortEncounterSchemaValues(action),
			['data', 'ordering_physician'],
			[]
		);
		newState['loading'] = false;
		newState[
			`loading-${_.get(action, 'encountertype', 'encounter')}`
		] = false;
		newState[`error-${_.get(action, 'encountertype', 'encounter')}`] = null;
		newState['error'] = null;
		return newState;
	} else if (action.type === actionTypes.ORDERING_PHYSICIAN_ERROR) {
		const newState = Object.assign({}, state);
		newState['loading'] = false;
		newState[
			`loading-${_.get(action, 'encountertype', 'encounter')}`
		] = false;
		newState[`error-${_.get(action, 'encountertype', 'encounter')}`] =
			action.payload;
		newState['error'] = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const partnerinvoicestatus = (
	state = defaultPartnerInvoiceStatus,
	action
) => {
	if (action.type === actionTypes.LOADING_PARTNER_INVOICE_STATUS) {
		const newState = Object.assign({}, state);
		newState.loadingPartnerInvoiceStatus = true;
		newState.partnerInvoiceStatusError = null;
		newState['research-data'] = _.get(
			sortEncounterSchemaValues(action),
			['data', 'partner_invoice_status'],
			[]
		);
		return newState;
	} else if (action.type === actionTypes.PARTNER_INVOICE_STATUS) {
		const newState = Object.assign({}, state);
		newState.loadingPartnerInvoiceStatus = false;
		newState['research-data'] = _.get(
			sortEncounterSchemaValues(action),
			['data', 'partner_invoice_status'],
			[]
		);
		return newState;
	} else if (action.type === actionTypes.PARTNER_INVOICE_STATUS_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingPartnerInvoiceStatus = false;
		newState.partnerInvoiceStatusError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const sortEncounterSchemaValues = (data) => {
	const keys = Object.keys(_.get(data, 'payload.data', []));
	const sorted_list = {};
	keys.map((e) => {
		const unsortable_values = {
			'N/A': 1,
			Other: 1,
			Undetermined: 1,
			'Other Adult Genetics': 1,
		};
		const ignored_values = {
			scheduled: 1,
			not_scheduled: 1,
			patient_unreachable: 1,
		};
		let values = _.get(data, ['payload', 'data', e], []);

		if (e in { no_of_interactions: 1 }) {
			sorted_list[e] = values;
		} else {
			if (e === 'ror_visit_status') {
				values = _.filter(values, function (o) {
					return !(o.key in ignored_values);
				});
			}
			const sortable_values = _.filter(values, function (o) {
				return !(o.display_name in unsortable_values);
			});
			const values_to_be_added = _.filter(values, function (o) {
				return o.display_name in unsortable_values;
			});

			let sorted_values = _.sortBy(sortable_values, [
				function (o) {
					return o.display_name;
				},
			]);
			if (values_to_be_added.length > 0) {
				sorted_values = sorted_values.concat(values_to_be_added);
			}
			sorted_list[e] = sorted_values;
		}
	});
	return { data: sorted_list };
};

export const getBillingEncounterData = (
	state = { billingobject: {} },
	action
) => {
	if (action.type === actionTypes.ENCOUNTER_BILLING_DATA) {
		const newState = Object.assign({}, state);
		newState.billingobject = action.payload;
		newState.billingobjectLoading = false;
		newState.billingobjectError = null;
		return newState;
	} else if (action.type === actionTypes.LOADING_ENCOUNTER_BILLING_DATA) {
		const newState = Object.assign({}, state);
		newState.billingobject = null;
		newState.billingobjectLoading = true;
		newState.billingobjectError = null;
		return newState;
	} else if (action.type === actionTypes.ENCOUNTER_BILLING_DATA_ERROR) {
		const newState = Object.assign({}, state);
		newState.billingobject = null;
		newState.billingobjectLoading = false;
		newState.billingobjectError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const TestEnums = (state = defaultTestEnums, action = {}) => {
	switch (action.type) {
		case actionTypes.LOAD_ENCOUNTER_DETAIL_TEST_ENUM_START:
			return {
				...state,
				loading: true,
				loaded: false,
				error: null,
				selectedlabuuid: undefined,
				data: [],
			};

		case actionTypes.LOAD_ENCOUNTER_DETAIL_TEST_ENUM_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: null,
				data: _.get(action.payload, 'genetic_tests', []),
				selectedlabuuid: _.get(
					action.payload,
					'selctedlabuuid',
					undefined
				),
			};

		case actionTypes.LOAD_ENCOUNTER_DETAIL_TEST_ENUM_FAILURE:
			return {
				...state,
				loading: false,
				loaded: true,
				data: [],
				error: action.payload,
				selectedlabuuid: undefined,
			};

		default:
			return state;
	}
};

export const AlternateTestEnums = (
	state = defaultAlternateTestEnums,
	action = {}
) => {
	switch (action.type) {
		case actionTypes.LOAD_ALTERNATE_TEST_ENUM_START:
			return {
				...state,
				loading: true,
				loaded: false,
				error: null,
				selectedlabuuid: undefined,
				data: [],
			};

		case actionTypes.LOAD_ALTERNATE_TEST_ENUM_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: null,
				data: _.get(action.payload, 'genetic_tests', []),
				selectedlabuuid: _.get(
					action.payload,
					'selctedlabuuid',
					undefined
				),
			};

		case actionTypes.LOAD_ALTERNATE_TEST_ENUM_FAILURE:
			return {
				...state,
				loading: false,
				loaded: true,
				data: [],
				error: action.payload,
				selectedlabuuid: undefined,
			};

		default:
			return state;
	}
};

export const EditTestOrderTestEnums = (
	state = defaultEditTestOrderTestEnums,
	action = {}
) => {
	switch (action.type) {
		case actionTypes.LOAD_EDIT_TESTORDER_TEST_ENUM_START:
			return {
				...state,
				loading: true,
				loaded: false,
				error: null,
				selectedlabuuid: undefined,
				data: [],
			};

		case actionTypes.LOAD_EDIT_TESTORDER_TEST_ENUM_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: null,
				data: _.get(action.payload, 'genetic_tests', []),
				selectedlabuuid: _.get(
					action.payload,
					'selctedlabuuid',
					undefined
				),
			};

		case actionTypes.LOAD_EDIT_TESTORDER_TEST_ENUM_FAILURE:
			return {
				...state,
				loading: false,
				loaded: true,
				data: [],
				error: action.payload,
				selectedlabuuid: undefined,
			};

		default:
			return state;
	}
};

export const icdCodes = (state = [], action = {}) => {
	if (action.type === actionTypes.GETTING_ICD_CODES) {
		const newState = Object.assign({}, state);
		newState.icdCodesLoading = true;
		newState.icdCodesError = null;
		newState.icdCodesPlacement = null;
		newState.icdCodesPlacement = action.placement;
		return newState;
	} else if (action.type === actionTypes.GOT_ICD_CODES) {
		const newState = Object.assign({}, state);
		newState.icdCodes = action.payload;
		newState.icdCodesLoading = false;
		newState.icdCodesError = null;
		newState.icdCodesPlacement = action.placement;
		return newState;
	} else if (action.type === actionTypes.GOT_ICD_CODES_ERROR) {
		const newState = Object.assign({}, state);
		newState.icdCodes = null;
		newState.icdCodesLoading = false;
		newState.icdCodesError = action.payload;
		newState.icdCodesPlacement = action.placement;
		return newState;
	} else {
		return state;
	}
};

export const Reclaim = (state = defaultEncounterReclaim, action = {}) => {
	if (action.type === actionTypes.ENCOUNTER_RECLAIM_START) {
		const newState = Object.assign({}, state);
		newState.data = null;
		newState.error = null;
		newState.loading = true;
		newState.loaded = false;
		return newState;
	} else if (action.type === actionTypes.ENCOUNTER_RECLAIM_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.data = action.payload;
		newState.error = null;
		newState.loading = false;
		newState.loaded = true;
		return newState;
	} else if (action.type === actionTypes.ENCOUNTER_RECLAIM_FAILURE) {
		const newState = Object.assign({}, state);
		newState.data = null;
		newState.error = action.payload;
		newState.loading = false;
		newState.loaded = true;
		return newState;
	} else if (action.type === actionTypes.ENCOUNTER_RECLAIM_RESET) {
		return {
			...state,
			...defaultEncounterReclaim,
		};
	} else {
		return state;
	}
};

export const getPreauthorizationsEnums = (
	state = { preauthorizationsenums: {} },
	action
) => {
	if (action.type === actionTypes.PREAUTHORIZATION_ENUMS) {
		const newState = Object.assign({}, state);
		newState.preauthorizationsenums = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getPreauthorizationTestOrders = (
	state = defaultPreauthTestOrders,
	action = {}
) => {
	if (action.type === actionTypes.LOADING_PRE_AUTH_TEST_ORDERS) {
		const newState = Object.assign({}, state);
		newState.data = null;
		newState.error = null;
		newState.loading = true;
		newState.loaded = false;
		return newState;
	} else if (action.type === actionTypes.LOADED_PRE_AUTH_TEST_ORDERS) {
		const newState = Object.assign({}, state);
		newState.data = action.payload;
		newState.error = null;
		newState.loading = false;
		newState.loaded = true;
		return newState;
	} else if (action.type === actionTypes.ERROR_PRE_AUTH_TEST_ORDERS) {
		const newState = Object.assign({}, state);
		newState.data = null;
		newState.error = action.payload;
		newState.loading = false;
		newState.loaded = true;
		return newState;
	} else {
		return state;
	}
};

export const TestOrderRequest = (
	state = defaultTestOrderRequest,
	action = {}
) => {
	if (action.type === actionTypes.LOADING_TEST_ORDER_REQUEST) {
		const newState = Object.assign({}, state);
		newState.data = null;
		newState.error = null;
		newState.loading = true;
		newState.loaded = false;
		return newState;
	} else if (action.type === actionTypes.LOADED_TEST_ORDER_REQUEST) {
		const newState = Object.assign({}, state);
		newState.data = action.payload;
		newState.error = null;
		newState.loading = false;
		newState.loaded = true;
		return newState;
	} else if (action.type === actionTypes.ERROR_TEST_ORDER_REQUEST) {
		const newState = Object.assign({}, state);
		newState.data = null;
		newState.error = action.payload;
		newState.loading = false;
		newState.loaded = true;
		return newState;
	} else {
		return state;
	}
};

export const testAuthorization = (state = defaultTestAuthorization, action) => {
	if (action.type === actionTypes.SCP_TEST_AUTH_SEARCHING) {
		const newState = Object.assign({}, state);
		newState.testAuthSearchLoading = true;
		newState.testAuthData = null;
		newState.testAuthSearchError = null;
		return newState;
	} else if (action.type === actionTypes.SCP_TEST_AUTH_SEARCH) {
		const newState = Object.assign({}, state);
		newState.testAuthSearchLoading = false;
		newState.testAuthData = action.payload;
		newState.testAuthSearchError = null;
		return newState;
	} else if (action.type === actionTypes.SCP_TEST_AUTH_SEARCH_ERROR) {
		const newState = Object.assign({}, state);
		newState.testAuthSearchLoading = false;
		newState.testAuthData = null;
		newState.testAuthSearchError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const geneTestOrderStatusEnum = (
	state = defaultGeneTestOrderStatusEnum,
	action
) => {
	if (action.type === actionTypes.GETTING_GENE_TEST_ORDER_STATUS_ENUM) {
		const newState = Object.assign({}, state);
		newState.geneTestOrderStatusEnumLoading = true;
		newState.geneTestOrderStatusEnum = null;
		newState.geneTestOrderStatusEnumError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_GENE_TEST_ORDER_STATUS_ENUM) {
		const newState = Object.assign({}, state);
		newState.geneTestOrderStatusEnumLoading = false;
		newState.geneTestOrderStatusEnum = action.payload;
		newState.geneTestOrderStatusEnumError = null;
		return newState;
	} else if (
		action.type === actionTypes.GOT_GENE_TEST_ORDER_STATUS_ENUM_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.geneTestOrderStatusEnumLoading = false;
		newState.geneTestOrderStatusEnum = null;
		newState.geneTestOrderStatusEnumError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getGeneTestEnums = (state = defaultGeneTestEnums, action) => {
	if (action.type === actionTypes.LOADING_GENE_TEST_ORDER_ENUMS) {
		const newState = Object.assign({}, state);
		newState.loadingGeneTestEnums = true;
		newState.geneTestEnumsError = null;
		return newState;
	} else if (action.type === actionTypes.GENE_TEST_ORDER_ENUMS) {
		const newState = Object.assign({}, state);
		newState.loadingGeneTestEnums = false;
		newState.gentestenums = action.payload;
		return newState;
	} else if (action.type === actionTypes.GENE_TEST_ORDER_ENUMS_ERROR) {
		const newState = Object.assign({}, state);
		newState.loadingGeneTestEnums = false;
		newState.geneTestEnumsError = null;
		return newState;
	} else {
		return state;
	}
};

export const testBulkUpdate = (state = {}, action) => {
	if (action.type === actionTypes.TEST_BULK_UPDATING) {
		const newState = Object.assign({}, state);
		newState.testBulkUpdating = true;
		newState.testBulkUpdated = null;
		newState.testBulkUpdatedError = null;
		return newState;
	} else if (action.type === actionTypes.TEST_BULK_UPDATE) {
		const newState = Object.assign({}, state);
		newState.testBulkUpdating = false;
		newState.testBulkUpdated = action.payload;
		newState.testBulkUpdatedError = null;
		return newState;
	} else if (action.type === actionTypes.TEST_BULK_UPDATE_ERROR) {
		const newState = Object.assign({}, state);
		newState.testBulkUpdating = false;
		newState.testBulkUpdated = null;
		newState.testBulkUpdatedError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const testOrder = (state = {}, action) => {
	if (action.type === actionTypes.TEST_ORDER_UPDATING) {
		const newState = Object.assign({}, state);
		newState.testOrderUpdating = true;
		newState.testOrderUpdated = null;
		newState.testOrderUpdatedError = null;
		return newState;
	} else if (action.type === actionTypes.TEST_ORDER_UPDATE) {
		const newState = Object.assign({}, state);
		newState.testOrderUpdating = false;
		newState.testOrderUpdated = action.payload;
		newState.testOrderUpdatedError = null;
		return newState;
	} else if (action.type === actionTypes.TEST_ORDER_UPDATE_ERROR) {
		const newState = Object.assign({}, state);
		newState.testOrderUpdating = false;
		newState.testOrderUpdated = null;
		newState.testOrderUpdatedError = action.payload;
		return newState;
	} else if (action.type === actionTypes.CREATING_TEST_ORDER_FLAGS) {
		const newState = Object.assign({}, state);
		newState.creatingTestOrderFlags = true;
		newState.createdTestOrderFlags = null;
		newState.createdTestOrderFlagsError = null;
		return newState;
	} else if (action.type === actionTypes.CREATED_TEST_ORDER_FLAGS) {
		const newState = Object.assign({}, state);
		newState.creatingTestOrderFlags = false;
		newState.createdTestOrderFlags = action.payload;
		newState.createdTestOrderFlagsError = null;
		return newState;
	} else if (action.type === actionTypes.CREATED_TEST_ORDER_FLAGS_ERROR) {
		const newState = Object.assign({}, state);
		newState.creatingTestOrderFlags = false;
		newState.createdTestOrderFlags = null;
		newState.createdTestOrderFlagsError = action.payload;
		return newState;
	} else if (action.type === actionTypes.UPDATING_TEST_ORDER_FLAGS) {
		const newState = Object.assign({}, state);
		newState.updatingTestOrderFlags = true;
		newState.updatedTestOrderFlags = null;
		newState.updatedTestOrderFlagsError = null;
		return newState;
	} else if (action.type === actionTypes.UPDATED_TEST_ORDER_FLAGS) {
		const newState = Object.assign({}, state);
		newState.updatingTestOrderFlags = false;
		newState.updatedTestOrderFlags = action.payload;
		newState.updatedTestOrderFlagsError = null;
		return newState;
	} else if (action.type === actionTypes.UPDATED_TEST_ORDER_FLAGS_ERROR) {
		const newState = Object.assign({}, state);
		newState.updatingTestOrderFlags = false;
		newState.updatedTestOrderFlags = null;
		newState.updatedTestOrderFlagsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getSCPClinicalInfoData = (
	state = { clinicalInfo: {} },
	action
) => {
	if (action.type === actionTypes.GETTING_SCP_CLINICAL_INFO_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.clinicalInfo = action.payload;
		newState.clinicalInfoLoading = false;
		newState.clinicalInfoError = null;
		return newState;
	} else if (action.type === actionTypes.GETTING_SCP_CLINICAL_INFO) {
		const newState = Object.assign({}, state);
		newState.clinicalInfo = null;
		newState.clinicalInfoLoading = true;
		newState.clinicalInfoError = null;
		return newState;
	} else if (action.type === actionTypes.GETTING_SCP_CLINICAL_INFO_ERROR) {
		const newState = Object.assign({}, state);
		newState.clinicalInfo = null;
		newState.clinicalInfoLoading = false;
		newState.clinicalInfoError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getTestOrderNotes = (
	state = defaultTestOrderNotes,
	action = {}
) => {
	if (action.type === actionTypes.LOADING_TEST_ORDER_NOTES) {
		const newState = Object.assign({}, state);
		newState.data = null;
		newState.error = null;
		newState.loading = true;
		newState.loaded = false;
		return newState;
	} else if (action.type === actionTypes.LOADED_TEST_ORDER_NOTES) {
		const newState = Object.assign({}, state);
		newState.data = action.payload;
		newState.error = null;
		newState.loading = false;
		newState.loaded = true;
		return newState;
	} else if (action.type === actionTypes.ERROR_TEST_ORDER_NOTES) {
		const newState = Object.assign({}, state);
		newState.data = null;
		newState.error = action.payload;
		newState.loading = false;
		newState.loaded = true;
		return newState;
	} else {
		return state;
	}
};

export const auditTrailEncounter = (state = {}, action = {}) => {
	if (action.type === actionTypes.GET_AUDIT_TRAIL_ENCOUNTER_START) {
		const newState = Object.assign({}, state);
		newState.auditTrailEncounterLoading = true;
		newState.auditTrailEncounter = null;
		newState.auditTrailEncounterError = null;
		return newState;
	} else if (action.type === actionTypes.GET_AUDIT_TRAIL_ENCOUNTER_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.auditTrailEncounterLoading = false;
		newState.auditTrailEncounter = action.payload;
		newState.auditTrailEncounterError = null;
		return newState;
	} else if (action.type === actionTypes.GET_AUDIT_TRAIL_ENCOUNTER_ERROR) {
		const newState = Object.assign({}, state);
		newState.auditTrailEncounterLoading = false;
		newState.auditTrailEncounter = null;
		newState.auditTrailEncounterError = action.payload;
		return newState;
	} else if (action.type === actionTypes.GET_ENCOUNTER_AUDIT_ENUM_START) {
		const newState = Object.assign({}, state);
		newState.encounterAuditEnumLoading = true;
		newState.encounterAuditEnum = null;
		newState.encounterAuditEnumError = null;
		return newState;
	} else if (action.type === actionTypes.GET_ENCOUNTER_AUDIT_ENUM_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.encounterAuditEnumLoading = false;
		newState.encounterAuditEnum = action.payload;
		newState.encounterAuditEnumError = null;
		return newState;
	} else if (action.type === actionTypes.GET_ENCOUNTER_AUDIT_ENUM_ERROR) {
		const newState = Object.assign({}, state);
		newState.encounterAuditEnumLoading = false;
		newState.encounterAuditEnum = null;
		newState.encounterAuditEnumError = action.payload;
		return newState;
	} else if (action.type === actionTypes.GET_ENCOUNTER_BILLING_ENUM_START) {
		const newState = Object.assign({}, state);
		newState.encounterBillingEnumLoading = true;
		newState.encounterBillingEnum = null;
		newState.encounterBillingEnumError = null;
		return newState;
	} else if (action.type === actionTypes.GET_ENCOUNTER_BILLING_ENUM_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.encounterBillingEnumLoading = false;
		newState.encounterBillingEnum = action.payload;
		newState.encounterBillingEnumError = null;
		return newState;
	} else if (action.type === actionTypes.GET_ENCOUNTER_BILLING_ENUM_ERROR) {
		const newState = Object.assign({}, state);
		newState.encounterBillingEnumLoading = false;
		newState.encounterBillingEnum = null;
		newState.encounterBillingEnumError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const contentViewedByPatient = (state = {}, action = {}) => {
	if (action.type === actionTypes.GET_CONTENT_VIEWED_BY_PATIENT_START) {
		const newState = Object.assign({}, state);
		newState.contentViewedByPatientLoading = true;
		newState.contentViewedByPatient = null;
		newState.contentViewedByPatientError = null;
		return newState;
	} else if (
		action.type === actionTypes.GET_CONTENT_VIEWED_BY_PATIENT_SUCCESSS
	) {
		const newState = Object.assign({}, state);
		newState.contentViewedByPatientLoading = false;
		newState.contentViewedByPatient = action.payload;
		newState.contentViewedByPatientError = null;
		return newState;
	} else if (
		action.type === actionTypes.GET_CONTENT_VIEWED_BY_PATIENT_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.contentViewedByPatientLoading = false;
		newState.contentViewedByPatient = null;
		newState.contentViewedByPatientError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const encounterCommunications = (
	state = defaultCommunications,
	action = {}
) => {
	if (action.type === actionTypes.LOAD_ENCOUNTER_COMMUNICATIONS_START) {
		return {
			...state,
			loading: true,
			loaded: false,
			data: null,
			error: null,
		};
	} else if (
		action.type === actionTypes.LOAD_ENCOUNTER_COMMUNICATIONS_SUCCESS
	) {
		return {
			...state,
			loading: false,
			loaded: true,
			data: action.payload,
			error: null,
		};
	} else if (
		action.type === actionTypes.LOAD_ENCOUNTER_COMMUNICATIONS_FAILURE
	) {
		return {
			...state,
			loading: false,
			loaded: true,
			data: null,
			error: action.payload,
		};
	} else {
		return state;
	}
};

export const linkproviders = (state = { linkproviders: [] }, action) => {
	if (action.type === actionTypes.PROVIDERS_ENCOUNTER_LINKING_START) {
		const newState = Object.assign({}, state);
		newState.linkedProviders = {};
		newState.linkingProviders = true;
		newState.linkingProvidersError = false;
		newState.message = null;
		return newState;
	} else if (
		action.type === actionTypes.PROVIDERS_ENCOUNTER_LINKING_SUCCESS
	) {
		const newState = Object.assign({}, state);
		newState.linkedProviders = action.payload;
		newState.linkingProviders = false;
		newState.linkingProvidersError = false;
		newState.message = action.message;
		return newState;
	} else if (action.type === actionTypes.PROVIDERS_ENCOUNTER_LINKING_ERROR) {
		const newState = Object.assign({}, state);
		newState.linkedProviders = {};
		newState.linkingProviders = false;
		newState.linkingProvidersError = true;
		newState.message = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const unlinkprovider = (state = { unlinkprovider: [] }, action) => {
	if (action.type === actionTypes.GET_UNLINK_ENCOUNTER_PROVIDER_START) {
		const newState = Object.assign({}, state);
		newState.unlinkProviderResult = {};
		newState.unlinkingProvider = true;
		newState.unlinkProviderError = false;
		newState.message = null;
		return newState;
	} else if (
		action.type === actionTypes.GET_UNLINK_ENCOUNTER_PROVIDER_SUCCESS
	) {
		const newState = Object.assign({}, state);
		newState.unlinkProviderResult = action.payload;
		newState.unlinkingProvider = false;
		newState.unlinkProviderError = false;
		newState.message = action.message;
		return newState;
	} else if (
		action.type === actionTypes.GET_UNLINK_ENCOUNTER_PROVIDER_ERROR
	) {
		const newState = Object.assign({}, state);
		newState.unlinkProviderResult = {};
		newState.unlinkingProvider = false;
		newState.unlinkProviderError = true;
		newState.message = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const visitIndications = (state = {}, action) => {
	if (action.type === actionTypes.GETTING_VISIT_INDICATIONS) {
		const newState = Object.assign({}, state);
		newState.visitIndicationsLoading = true;
		newState.visitIndications = null;
		newState.visitIndicationsError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_VISIT_INDICATIONS) {
		const newState = Object.assign({}, state);
		newState.visitIndicationsLoading = false;
		newState.visitIndications = action.payload;
		newState.visitIndicationsError = null;
		return newState;
	} else if (action.type === actionTypes.GOT_VISIT_INDICATIONS_ERROR) {
		const newState = Object.assign({}, state);
		newState.visitIndicationsLoading = false;
		newState.visitIndications = null;
		newState.visitIndicationsError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const updateTestOrderRedFlag = (state = {}, action) => {
	if (action.type === actionTypes.TEST_ORDER_RED_FLAG_UPDATING) {
		const newState = Object.assign({}, state);
		newState.testOrderRedFlagUpdating = true;
		newState.testOrderRedFlagUpdated = null;
		newState.testOrderRedFlagUpdatedError = null;
		return newState;
	} else if (action.type === actionTypes.TEST_ORDER_RED_FLAG_UPDATE) {
		const newState = Object.assign({}, state);
		newState.testOrderRedFlagUpdating = false;
		newState.testOrderRedFlagUpdated = action.payload;
		newState.testOrderRedFlagUpdatedError = null;
		return newState;
	} else if (action.type === actionTypes.TEST_ORDER_RED_FLAG_UPDATE_ERROR) {
		const newState = Object.assign({}, state);
		newState.testOrderRedFlagUpdating = false;
		newState.testOrderRedFlagUpdated = null;
		newState.testOrderRedFlagUpdatedError = action.payload;
		return newState;
	} else {
		return state;
	}
};

export const getEncounterDocuments = (
	state = defaultEncounterDocuments,
	action
) => {
	if (action.type === actionTypes.ENCOUNTER_DOCUMENTS_LOADING) {
		const newState = Object.assign({}, state);
		newState.loading = true;
		newState.data = [];
		newState.error = null;
		return newState;
	} else if (action.type === actionTypes.ENCOUNTER_DOCUMENTS_SUCCESS) {
		const newState = Object.assign({}, state);
		newState.loading = false;
		newState.data = action.payload;
		newState.error = null;
		return newState;
	} else if (action.type === actionTypes.ENCOUNTER_DOCUMENTS_FAILED) {
		const newState = Object.assign({}, state);
		newState.loading = false;
		newState.data = [];
		newState.error = action.payload;
		return newState;
	} else {
		return state;
	}
};
