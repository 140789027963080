//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import searchIcon from './../../../assets/ic_search.svg';
import xIcon from './../../../assets/exit-alert.svg';

//Lodash
import get from 'lodash/get';

//Components
import TagSearchResults from './TagSearchResults.js';

const TagSearch = (props) => {
	const {
		handleInputChange,
		handleSearchQueryChange,
		icdCodes,
		price,
		scrollHandler,
		searchQuery = '',
		selectedICDCodes,
		setBodyElement,
		shouldHaveTopDropShadow = false,
		toggleCodeSelection,
	} = props;

	const handleSearchChange = (e) => {
		handleSearchQueryChange(e.target.value);
	};

	const clearSearch = () => {
		handleSearchQueryChange('');
	};

	const getSearchResults = () => (
		<TagSearchResults
			{...{
				handleInputChange,
				icdCodes,
				price,
				scrollHandler,
				selectedICDCodes,
				setBodyElement,
				toggleCodeSelection,
			}}
		/>
	);

	const query = searchQuery;
	const containerClass = shouldHaveTopDropShadow
		? 'chargeICDSearchRowContainer chargeICDSearchRowContainerLessPadding scrollableModalDropShadowTopInside'
		: 'chargeICDSearchRowContainer chargeICDSearchRowContainerLessPadding';

	return (
		<div className='chargeICDSearchContainer'>
			<div className={containerClass}>
				<div
					className='chargeICDSearchBarContainer refProgMultiselectSearchbarContainer'
					style={{ width: '100%' }}
				>
					<input
						className='chargeICDSearchInput'
						placeholder='Search'
						onChange={handleSearchChange}
						value={query}
					/>
					{searchQuery.length ? (
						<ReactSVG
							className='chargeICDSearchXIcon'
							src={xIcon}
							style={{
								position: 'relative',
								bottom: '6px',
								left: '2px',
							}}
							onClick={clearSearch}
						/>
					) : (
						<ReactSVG
							className='chargeICDSearchIcon'
							src={searchIcon}
							style={{
								position: 'relative',
								bottom: '1px',
								right: '5px',
							}}
						/>
					)}
				</div>
			</div>
			{getSearchResults()}
		</div>
	);
};

export default TagSearch;
