//React & Redux
import React from 'react';

//Lodash
import { isEmpty } from 'lodash';

//Other Libraries
import classnames from 'classnames';

//Styles
import './CircleBadge.css';

//Components
import Tooltip from '../Common/Tooltip';

const Badge = (props) => {
	const { isActive, initials } = props;
	return (
		<span className={classnames('circle-badge', isActive ? 'active' : '')}>
			<span className='circle-badge-text'>{initials || '—'}</span>
		</span>
	);
};

export default class CircleBadge extends React.Component {
	getInitials = (displayName) => {
		return (
			displayName &&
			displayName
				.match(/(\b\S)?/g)
				.join('')
				.match(/(^\S|\S$)?/g)
				.join('')
				.toUpperCase()
		);
	};

	render() {
		const { displayName, isActive, type = 'cc', isReverse } = this.props;
		const initials = this.getInitials(displayName);
		const badgeProps = { isActive, initials };
		const displayNameLabel =
			type === 'cc' ? (
				<b>{displayName} (CC)</b>
			) : (
				<b>{displayName} (GCA)</b>
			);

		return (
			<span
				className={classnames(
					'circle-badge-wrapper',
					isReverse ? 'reverse' : ''
				)}
			>
				{!isEmpty(initials) ? (
					<Tooltip
						className='circle-badge-tooltip'
						content={
							<span className='circle-badge-tooltip-text'>
								Assigned to:
								<br />
								{displayNameLabel}
							</span>
						}
						placement='top'
					>
						<Badge {...badgeProps} />
					</Tooltip>
				) : (
					<Badge {...badgeProps} />
				)}
			</span>
		);
	}
}
