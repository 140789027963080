//React & Redux
import React from 'react';
import PropTypes from 'prop-types';

//Images
import cap_not_sent from '../../assets/cap_not_sent.png';
import lab_cap from '../../assets/lab_cap.png';

//Utils
import {
	key_color_map,
	key_img_map,
	dashboard_key_title,
} from '../../Utils/dashboardUtils';

//Components
import Tooltip from '../Common/Tooltip';

//Styles
import './style.css';

const PatientStageCards = (props) => {
	const {
		dashboardData = {},
		history,
		key = '',
		obj = '',
		total,
		v2 = false,
	} = props;

	const redirectToEncounters = (e) => {
		switch (e) {
			case 'ror_outreach_pending':
				return history.push({
					pathname: '/app/dashboard/patients/ror',
					search: '?filter=' + e,
					state: {
						current: e,
						breadcrumbs: [
							{
								location: dashboard_key_title[e] || 'Patients',
								offset: 0,
								order_by: null,
								order_type: null,
								key: 'dashboard_card',
								search: '?filter=' + e,
							},
						],
					},
				});
			default:
				return history.push({
					pathname: '/app/dashboard/encounters',
					search: '?filter=' + e,
					state: {
						current: e,
						all: dashboardData,
						breadcrumbs: [
							{
								location:
									dashboard_key_title[e] || 'Encounters',
								offset: 0,
								order_by: null,
								order_type: null,
								key: 'dashboard_card',
								search: '?filter=' + e,
							},
						],
					},
				});
		}
	};

	const count = dashboardData[obj] || 0;

	return obj !== 'cdt_caps_ready_to_send' ? (
		<div key={key} className='patient-stage-card'>
			<div
				style={{
					opacity: count > 0 ? '1' : '0.5',
					cursor: count > 0 ? 'pointer' : '',
				}}
				onClick={() => {
					count > 0 && redirectToEncounters(obj);
				}}
				className={count > 0 ? 'bucket_cards' : ''}
			>
				<div className='contentbox iconleftgap'>
					<div
						className='iconcolor-box'
						style={{
							boxShadow: `${key_color_map[obj]?.shadow || ''}`,
							background: `${
								key_color_map[obj]?.background || ''
							}`,
							lineHeight:
								obj == 'cap_not_sent' ||
								obj == 'cdt_caps_ready_to_send' ||
								obj == 'cdt_caps_ready_to_send'
									? '2.85vw'
									: '4.5vw',
						}}
					>
						{obj == 'cap_not_sent' ? (
							<img
								className='patient_invoice_status'
								src={cap_not_sent}
								alt=''
								style={{ height: '1.5vw' }}
							/>
						) : obj == 'cdt_caps_ready_to_send' ||
						  obj == 'cdt_caps_ready_to_send' ? (
							<img
								className='patient_invoice_status'
								src={lab_cap}
								alt=''
								style={{ height: '1.5vw' }}
							/>
						) : (
							<i className='material-icons'>
								{key_img_map[obj] || ''}
							</i>
						)}
					</div>
					<div className='text-right textbox'>
						<p className='card-title'>
							{v2
								? 'Patient Outreach Pending V2'
								: dashboard_key_title[obj] || ''}
						</p>
						<p className='Patient-text'>{count}</p>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div key={key} className='patient-stage-card'>
			<div
				style={{
					opacity: total > 0 ? '1' : '0.5',
					cursor: total > 0 ? 'pointer' : '',
				}}
				onClick={() =>
					total > 0 &&
					redirectToEncounters(
						obj == 'cdt_caps_ready_to_send'
							? 'results_cap_not_sent'
							: 'all_cdt'
					)
				}
				className={total > 0 ? 'bucket_cards' : ''}
			>
				<Tooltip
					className='cap_not_sent_tooltip'
					effect='dark'
					content={
						<div style={{ display: 'flex', marginTop: '-2px' }}>
							<div className='tooltip_left_enc_type_labels'>
								<div>Visit</div>
								<div>CC-intake</div>
								<div>Group session</div>
								<div>Lab</div>
								<div>SCP</div>
								<div>TRO</div>
								<div>TRO - Results Only</div>
							</div>
							<div className='tooltip_right_enc_count_labels'>
								<div>
									{dashboardData.non_cdt_caps_ready_to_send_visit_encounters ||
										0}
								</div>
								<div>
									{dashboardData.non_cdt_caps_ready_to_send_cc_intake_encounters ||
										0}
								</div>
								<div>
									{dashboardData.non_cdt_caps_ready_to_send_group_session_encounters ||
										0}
								</div>
								<div>
									{dashboardData.cdt_caps_ready_to_send || 0}
								</div>
								<div>
									{dashboardData.non_cdt_caps_ready_to_send_scp_encounters ||
										0}
								</div>
								<div>
									{dashboardData.non_cdt_caps_ready_to_send_tro_encounters ||
										0}
								</div>
								<div>
									{dashboardData.non_cdt_caps_result_only_encounters ||
										0}
								</div>
							</div>
						</div>
					}
					placement='bottom'
					visible={true}
				>
					<div className='contentbox iconleftgap'>
						<div
							className='iconcolor-box'
							style={{
								boxShadow: `${
									key_color_map[obj]?.shadow || ''
								}`,
								background: `${
									key_color_map[obj]?.background || ''
								}`,
								lineHeight:
									obj == 'cap_not_sent' ||
									obj == 'cdt_caps_ready_to_send' ||
									obj == 'cdt_caps_ready_to_send'
										? '2.85vw'
										: '4.5vw',
							}}
						>
							{obj == 'cap_not_sent' ? (
								<img
									className='patient_invoice_status'
									src={cap_not_sent}
									alt=''
									style={{ height: '1.5vw' }}
								/>
							) : obj == 'cdt_caps_ready_to_send' ||
							  obj == 'cdt_caps_ready_to_send' ? (
								<img
									className='patient_invoice_status'
									src={lab_cap}
									alt=''
									style={{ height: '1.5vw' }}
								/>
							) : (
								<i className='material-icons'>
									{key_img_map[obj] || ''}
								</i>
							)}
						</div>

						<div className='text-right textbox'>
							<p className='card-title'>
								{dashboard_key_title[obj] || ''}
							</p>
							<p className='Patient-text'>{total}</p>
						</div>
					</div>
				</Tooltip>
			</div>
		</div>
	);
};

PatientStageCards.propTypes = {
	auth: PropTypes.object,
};

export default PatientStageCards;
