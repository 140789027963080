//React & Redux
import React, { useState } from 'react';

//Images
import ReactSVG from 'react-svg';
import contactsIcon from '../../assets/contacts.svg';
import redFlagIcon from '../../assets/red-flag.svg';
import greenFlagIcon from '../../assets/green-flag.svg';

//Lodash
import { get, isNil, size, trim, isEmpty } from 'lodash';

//UI Libraries
import { Button, Loading, Input, Dialog } from 'gm-element-react';

//Other Libraries
import moment from 'moment';

//Styles
import './update-flag-testrequest-modal.css';

//Components
import Icon from '../Common/Icon';

const UpdateFlagForTestRequestModal = (props) => {
	const {
		hideUpdateFlagForTestRequestModal,
		patientDetail,
		testOrder,
		removeTestOrderRedFlag,
		testOrderRedFlagUpdating = false,
	} = props;

	const [flagChangeReason, setFlagChangeReason] = useState('');

	const determineDOB = () => {
		const dob = get(patientDetail, ['dob'], null);

		if (moment(dob).isValid()) {
			return moment(dob).format('MMM DD, YYYY');
		} else return dob || 'DOB Unknown';
	};

	const determineDOBDifference = () => {
		const dob = get(patientDetail, ['dob'], null);

		if (moment(dob).isValid()) {
			return moment().diff(dob, 'years') + ' yrs old';
		} else return 'Age Unknown';
	};

	const displayFlagAndReason = () => {
		const red_flags = get(testOrder, ['red_flags'], []);
		if (size(red_flags) > 0) {
			return (
				<ul>
					{red_flags.map((flag) => {
						return <li>{flag.message}</li>;
					})}
				</ul>
			);
		} else return '';
	};

	const isButtonUpdateFlagDisabled = () => {
		return isEmpty(trim(flagChangeReason)) || testOrderRedFlagUpdating;
	};

	const handleChange = (value) => {
		setFlagChangeReason(value);
	};

	const handleUpdateFlagRequest = () => {
		if (!isEmpty(flagChangeReason)) {
			return removeTestOrderRedFlag(
				get(testOrder, ['test_order_uuid'], ''),
				{
					comment: trim(flagChangeReason),
					enabled: false,
				}
			);
		}
	};

	return (
		<Dialog
			customClass='update-flag-test-request-dialog'
			title={
				<span className='title-wrapper'>
					<span>Update Flag Status</span>
					<span
						className={`close-btn-icon ${
							testOrderRedFlagUpdating
								? 'disabled-close-btn-icon'
								: ''
						}`}
						onClick={hideUpdateFlagForTestRequestModal}
					>
						<Icon icon='close' className='close-icon' />
					</span>
				</span>
			}
			modal={true}
			closeOnPressEscape={true}
			closeOnClickModal={true}
			showClose={false}
			visible={true}
		>
			<Dialog.Body>
				<Loading loading={testOrderRedFlagUpdating}>
					<div className='update-flag-patient-info-container'>
						<ReactSVG
							src={contactsIcon}
							className='contacts-icon'
						/>
						<div className='patient-info-wrapper'>
							<span className='update-flag-patient-name'>
								{get(patientDetail, ['first_name'], '')}{' '}
								{get(patientDetail, ['last_name'], '')}
							</span>
							<span className='update-flag-patient-email'>
								{get(patientDetail, ['email'], '')}
							</span>
							<span>
								{determineDOB()} &nbsp;&nbsp; · &nbsp;&nbsp;{' '}
								{determineDOBDifference()}{' '}
								{!isNil(get(patientDetail, ['gender'], '')) ? (
									<span className='patient-gender'>
										&nbsp;&nbsp; · &nbsp;&nbsp;{' '}
										{get(patientDetail, ['gender'], '')}
									</span>
								) : (
									''
								)}
							</span>
							<span>
								<span>Ref. Program:</span>
								<span className='refral-prgrm-val'>
									{get(
										patientDetail,
										['referral_program'],
										''
									)}
								</span>
							</span>
						</div>
					</div>

					<div className='update-flag-test-info-container'>
						<div className='update-flag-test-info-head'>
							<ReactSVG
								src={redFlagIcon}
								className='update-flag-red-flag-icon'
							/>
							<div className='update-flag-test-info-wrapper'>
								<span className='test-name'>
									{get(testOrder, ['testname'], '--')}
								</span>
								<span className='lab-name'>
									{get(testOrder, ['lab'], '--')}
								</span>
								<span className='red-flag-created-date-timestamp'>
									{moment(
										moment
											.utc(
												get(
													testOrder,
													[
														'red_flags',
														'0',
														'created_at',
													],
													''
												)
											)
											.local()
											.tz(moment.tz.guess())
									).format('MMM DD, YYYY hh:mm A z')}
								</span>
							</div>
						</div>
						<div className='flag-reason-list-body'>
							{displayFlagAndReason()}
						</div>
					</div>

					<div className='update-flag-new-flag-status-container'>
						<span className='label-new-flag-status'>
							New Flag Status:
						</span>
						<ReactSVG
							src={greenFlagIcon}
							className='update-flag-green-flag-icon'
						/>
					</div>

					<div className='lbl-flag-change-reason'>
						Briefly describe why you’re changing the flag status.
					</div>
					<Input
						type='textarea'
						name='flag-change-reason'
						maxLength={280}
						autosize={{ minRows: 3, maxRows: 3 }}
						value={flagChangeReason}
						onChange={(value) => handleChange(value)}
						placeholder='Your message'
					/>
				</Loading>
			</Dialog.Body>
			<Dialog.Footer>
				<Button
					className='btn-cancel'
					disabled={testOrderRedFlagUpdating}
					onClick={hideUpdateFlagForTestRequestModal}
				>
					Cancel
				</Button>
				<Button
					className='btn-update-flag'
					disabled={isButtonUpdateFlagDisabled()}
					onClick={handleUpdateFlagRequest}
				>
					Update
				</Button>
			</Dialog.Footer>
		</Dialog>
	);
};

export default UpdateFlagForTestRequestModal;
