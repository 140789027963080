import axios from 'axios';
import {
	hydrateReferralPrograms,
	mappingSorting,
	hydratePartnerDocuments,
	mapDocumentSorting,
	hydrateCreateTemplate,
	hydrateUpdateTemplate,
	hydratePartnerDocumentDetail,
	dehydrateBillingInfo,
	hydrateReferralProgramDetail,
} from '../transformers/referralProgramTransformer';
import get from 'lodash/get';

class ReferralProgramsService {
	getReferralPrograms = (data) => {
		return axios
			.get('/api/get_referral_programs', {
				params: {
					offset: get(data, 'offset', 0),
					order_by: mappingSorting[get(data, 'order_by', 'name')],
					order_type: get(data, 'order_type', ''),
					limit: get(data, 'limit', 20),
					q: get(data, 'query', ''),
				},
			})
			.then((res) => res && hydrateReferralPrograms(res));
	};

	getReferralDocument = (uuid) => {
		return axios.get('/api/referral-document?uuid=' + uuid);
	};

	createReferralDocument = async (data) => {
		const response = await axios.post(
			'/api/referral-document/create',
			data.payload
		);
		return response;
	};

	updateReferralDocument = async (data) => {
		const response = await axios.put(
			'/api/referral-document/update' + data.uuid,
			data.payload
		);
		return response;
	};

	getPartnerDocuments = (data) => {
		return axios
			.get(`/api/partners/${data.partner_uuid}/documents`, {
				params: {
					category: data && data.category,
					order_type: data && data.order_type,
					order_by: data && mapDocumentSorting[data.order_by],
					offset: data && data.offset,
					limit: data && data.limit,
					active: data && data.active,
					paginate: data && data.paginate,
					file_type: data && data.file_type,
				},
			})
			.then((res) => res && hydratePartnerDocuments(res));
	};

	createTemplate = (data) => {
		const partneruuid = data && data.partneruuid;
		const url = '/api/partners/' + partneruuid + '/documents';
		data = {
			document_type: data && data.documenttype,
			file_name: data && data.filename,
			template_uuid: data && data.template,
			url: data && data.url,
			category: data && data.category,
		};
		return axios
			.post(url, data)
			.then(
				(res) =>
					res && hydrateCreateTemplate(res, get(data, 'category'))
			);
	};

	updateTemplate = (uuid, data) => {
		const partneruuid = data && data.partneruuid;
		const url = '/api/partners/' + partneruuid + '/documents/' + uuid;
		data = {
			document_type: data && data.documenttype,
			file_name: data && data.filename,
			template_uuid: data && data.template,
			url: data && data.url,
			category: data && data.category,
		};
		//if (!data.template) delete data.template;
		return axios
			.post(url, data)
			.then((res) => res && hydrateUpdateTemplate(res));
	};

	updateSharedDocument = (uuid, data) => {
		const url = '/api/partners/documents/' + uuid;
		const payload = {};
		if (get(data, 'document_type', ''))
			payload['document_type'] = get(data, 'document_type', '');
		if (get(data, 'file_name', ''))
			payload['file_name'] = get(data, 'file_name', '');
		if (get(data, 'partner_uuids', ''))
			payload['partner_uuids'] = get(data, 'partner_uuids', '');
		if (get(data, 'template_state', ''))
			payload['template_state'] = get(data, 'template_state', '');
		return axios
			.put(url, payload)
			.then((res) => res && hydrateUpdateTemplate(res));
	};

	getPartnerDocumentDetail = (data) => {
		return axios
			.get(
				`/api/partners/${data.partner_uuid}/documents/${data.document_uuid}`,
				{
					params: {},
				}
			)
			.then((res) => res && hydratePartnerDocumentDetail(res));
	};

	getSharedDocumentDetail = (data) => {
		return axios
			.get(`/api/partners/documents/${data.document_uuid}`, {
				params: {},
			})
			.then((res) => res && hydratePartnerDocumentDetail(res));
	};

	getReferralProgramDetail = (data) => {
		return axios
			.get(`/api/partners/${data.partner_uuid}`, {})
			.then((res) => res && hydrateReferralProgramDetail(res));
	};

	getDefaultSchedulingOptions = (data) => {
		return axios
			.get(
				`/api/partners/${data.partner_uuid}/default-scheduling-options/enum`,
				{}
			)
			.then((res) => res.data.data);
	};

	createBillingInfo = (data) => {
		const partneruuid = data && data.partneruuid;
		const url = '/api/partners/' + partneruuid + '/billing';

		const body = {
			data: dehydrateBillingInfo(data),
		};
		return axios.post(url, body);
	};

	updateBillingInfo = (data) => {
		const partneruuid = data && data.partneruuid;
		const url = '/api/partners/' + partneruuid + '/billing';

		const body = {
			data: dehydrateBillingInfo(data),
		};

		return axios.put(url, body);
	};

	archiveTemplate = (partner_uuid, document_uuid) => {
		return axios.post(
			`/api/archivetemplate/${partner_uuid}/${document_uuid}`
		);
	};

	getReferralProgramDetailById = (partner_id) => {
		return axios.get(`/api/partners?partner_id=${partner_id}`);
	};

	createReferralProgramDefaultProvider = ({
		partner_uuid,
		provider_uuids,
	}) => {
		const url = `/api/partners/${partner_uuid}`;
		const body = { provider_uuids };

		return axios.post(url, body);
	};

	updateReferralProgramDefaultProvider = ({
		partner_uuid,
		provider_uuids,
	}) => {
		const url = `/api/partners/${partner_uuid}`;
		const body = { provider_uuids };

		return axios.put(url, body);
	};

	updateReferralProgramDefaultNotificationRecipient = ({
		partner_uuid,
		default_doc_release_recipient,
	}) => {
		const url = `/api/partners/${partner_uuid}`;
		const body = { default_doc_release_recipient };

		return axios.put(url, body);
	};
}

export default new ReferralProgramsService();
