import React, { useEffect, useRef, useState } from 'react';
import uploadIcon from './assets/upload.svg';
import fileIcon from './assets/file.svg';
import xIcon from './assets/exit-alert.svg';

import './FileDropZone.scss';

interface FileDropZoneProps {
	setFile: (file: File | null) => void;
	fileName: string | null;
	setFileName: (name: string | null) => void;
	setSizeError: (error: boolean) => void;
	sizeError: boolean;
}

export const FileDropZone: React.FC<FileDropZoneProps> = ({
	setFile,
	fileName,
	setFileName,
	setSizeError,
	sizeError,
}) => {
	const [fileDialogOpen, setFileDialogOpen] = useState<boolean>(false);
	const [fileDialogSM, setFileDialogSM] = useState<{
		mousemove: number | null;
		bodyFocus: number | null;
	}>({
		mousemove: null,
		bodyFocus: null,
	});
	const [fileTypeError, setFileTypeError] = useState<boolean | null>(null);

	const dropContainer = useRef<HTMLDivElement | null>(null);
	const inputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		return () => {
			window.removeEventListener('focus', bodyFocusHandler);
			window.document.removeEventListener('mousemove', mousemoveHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const bodyFocusHandler = () => {
		if (fileDialogOpen) {
			const currentSM = fileDialogSM;
			currentSM.bodyFocus = new Date().getTime();
			setFileDialogSM(currentSM);

			if (currentSM.bodyFocus && currentSM.mousemove) {
				if (
					Math.abs(currentSM.bodyFocus - currentSM.mousemove) < 1200
				) {
					setFileDialogClosed();
				}
			}
		}
	};

	const mousemoveHandler = () => {
		if (fileDialogOpen) {
			const currentSM = fileDialogSM;
			currentSM.mousemove = new Date().getTime();
			setFileDialogSM(currentSM);

			if (currentSM.bodyFocus && currentSM.mousemove) {
				if (
					Math.abs(currentSM.bodyFocus - currentSM.mousemove) < 1200
				) {
					setFileDialogClosed();
				}
			}
		} else {
			setFileDialogClosed();
		}
	};

	const handleClickFileDialogOpen = () => {
		setFileDialogOpen(true);
		addInactiveClass();
		window.addEventListener('focus', bodyFocusHandler);
		window.document.addEventListener('mousemove', mousemoveHandler);
	};

	const setFileDialogClosed = () => {
		setFileDialogOpen(false);
		setFileDialogSM({ mousemove: null, bodyFocus: null });
		removeInactiveClass();
		removeDragActiveClass();
		window.removeEventListener('focus', bodyFocusHandler);
		window.document.removeEventListener('mousemove', mousemoveHandler);
	};

	const handleFile = (
		e: React.ChangeEvent<HTMLInputElement> | null,
		file?: File
	) => {
		resetFileStateOnly();
		const theFile = file ?? e?.target.files?.[0];
		if (theFile) {
			if (checkSize(theFile.size)) {
				setFileTypeError(false);
				setSizeError(false);
				if (fileTypeHasError(theFile)) {
					setFileTypeError(true);
				} else {
					loadFile(theFile);
					setFile(theFile);
				}
			} else {
				handleSetSizeError();
			}
		}
	};

	const checkSize = (bytes: number): boolean => {
		const MAX_DOCUMENT_SIZE = 10000000;
		return bytes < MAX_DOCUMENT_SIZE;
	};

	const handleSetSizeError = () => {
		setSizeError(true);
		resetFileState();
	};

	const loadFile = (file: File) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			setFileContents(file);
		};
		reader.readAsDataURL(file);
	};

	const setFileContents = (file: File) => {
		if (inputRef.current?.files) {
			setFileName(file.name);
		}
	};

	const resetFileState = () => {
		(inputRef.current ?? document.createElement('input')).value = '';
		setFile(null);
		setFileName(null);
	};

	const resetFileStateOnly = () => {
		setFile(null);
		setFileName(null);
	};

	const addDragActiveClass = () => {
		if (dropContainer.current) {
			dropContainer.current.classList.add(
				'uploadDocumentUploadDropContainerDragActive'
			);
		}
	};

	const removeDragActiveClass = () => {
		if (dropContainer.current) {
			dropContainer.current.classList.remove(
				'uploadDocumentUploadDropContainerDragActive'
			);
		}
	};

	const addInactiveClass = () => {
		if (dropContainer.current) {
			dropContainer.current.classList.add(
				'upload-document-upload-drop-container-inactive'
			);
			dropContainer.current.classList.remove(
				'upload-document-upload-drop-container'
			);
		}
	};

	const removeInactiveClass = () => {
		if (dropContainer.current) {
			dropContainer.current.classList.remove(
				'upload-document-upload-drop-container-inactive'
			);
			dropContainer.current.classList.add(
				'upload-document-upload-drop-container'
			);
		}
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.stopPropagation();
		e.preventDefault();
		addDragActiveClass();
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.stopPropagation();
		e.preventDefault();
		if (fileDialogOpen) return;
		removeDragActiveClass();
		handleFile(null, e.dataTransfer.files[0]);
	};

	const fileTypeHasError = (file: File): boolean => {
		const fileName = file.name || '';
		if (!fileName.includes('.')) return true;
		const splitFileName = fileName.split('.');
		const extension = splitFileName[splitFileName.length - 1];
		if (
			!new Set(['pdf', 'jpeg', 'jpg', 'gif', 'png']).has(
				extension.toLowerCase()
			)
		)
			return true;

		return false;
	};

	const sizeWarningClass = sizeError
		? 'upload-document-upload-size-warning-animated'
		: 'upload-document-upload-size-warning';

	return (
		<div
			className='upload-document-upload-drop-container'
			onDragOver={handleDragOver}
			onDragLeave={removeDragActiveClass}
			onDrop={handleDrop}
			ref={dropContainer}
		>
			{fileTypeError && (
				<div className='upload-document-size-error'>
					The format of the file cannot be uploaded.
				</div>
			)}
			<img src={uploadIcon} style={{ height: '36px', width: '32px' }} />
			<div className='upload-document-upload-drop-text'>
				Drop file here or
				<div style={{ cursor: 'pointer' }}>
					<label
						className='upload-document-upload-drop-text-purple'
						onClick={handleClickFileDialogOpen}
					>
						<input
							type='file'
							className='upload-document-upload-input'
							onChange={handleFile}
							multiple={false}
							ref={inputRef}
							accept='.gif, .jpeg, .png, .pdf, .jpg'
						/>
						click to select
					</label>
				</div>
			</div>
			<div className={sizeWarningClass}>
				(PDF, JPEG, JPG, GIF, or PNG. Max file size: 10 MB)
			</div>
			<div className='upload-document-filename-row'>
				{fileName && (
					<>
						<div className='upload-document-filename-icon'>
							<img src={fileIcon} />
							<div className='upload-document-filename-icon-filename'>
								{fileName}
							</div>
						</div>
						<img
							data-testId='reset-file-state'
							src={xIcon}
							style={{
								cursor: 'pointer',
								position: 'relative',
								top: '2px',
							}}
							onClick={resetFileState}
						/>
					</>
				)}
			</div>
		</div>
	);
};
