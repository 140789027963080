import React from 'react';

import './Header.scss';

export const Header = function ({ children }) {
	return (
		<header className='page-header'>
			<h1>{children}</h1>
		</header>
	);
};
