//React & Redux
import React from 'react';

//Images
import ReactSVG from 'react-svg';
import placeholderIcon from './../../../assets/icd-search.svg';

//Lodash
import _ from 'lodash';

class ICDSearchPlaceholder extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions

	render() {
		const encounterICDcodes = _.get(
			this,
			['props', 'encounterICDcodes'],
			[]
		);
		let additionalstring = '';
		if (_.get(encounterICDcodes, 'length') > 0) {
			const names = _.map(encounterICDcodes, (val) => {
				return val.code_name;
			});
			additionalstring =
				' or search for Encounter ICD-10 codes: ' + names.join(', ');
		}
		return (
			<div className='chargeICDSearchPlaceholderContainer'>
				<div className='chargeICDSearchPlaceholderIconAndText'>
					<ReactSVG src={placeholderIcon} />
					<div className='placeholder-text'>
						Start searching to view relevant ICD-10 codes{' '}
						{`${additionalstring}`}
					</div>
				</div>
			</div>
		);
	}
}

export default ICDSearchPlaceholder;
