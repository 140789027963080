//React & Redux
import React from 'react';
import classnames from 'classnames';

//Images
import appointmentSchedule from '../../../assets/appointment-schedule-24.svg';
import cap_not_sent from '../../../assets/cap_not_sent_black-24.svg';
import lab_cap from '../../../assets/lab_cap_black-24.svg';

//Lodash
import _ from 'lodash';

//Styles
import './patientstage-cards.css';

const PatientStageCards = (props) => {
	const { history, data = {} } = props;

	const { total = 0, card = '', title = '' } = data;

	const redirectToEncounters = (e) => {
		history.push({
			pathname: '/app/dashboard/encounters',
			search: '?filter=' + e,
		});
	};

	const getPatientStageCardIcon = (card) => {
		if (card == 'appointment_alerts') {
			return <img src={appointmentSchedule} alt='appointment alerts' />;
		} else if (card == 'initial_visit_summary_ready') {
			return <img src={cap_not_sent} alt='initial visit' />;
		} else if (card == 'result_visit_summary_ready') {
			return <img src={lab_cap} alt='results visit' />;
		}
	};

	return (
		<div
			className={classnames(
				'patient-stage-cards',
				'has-patient-stage-cards'
			)}
			onClick={() => {
				redirectToEncounters(card);
			}}
		>
			<div className='title-wrapper'>
				<span className='patient-stage-card-title'>{title}</span>
				<span className='patient-stage-card-icon-wrapper'>
					{getPatientStageCardIcon(card)}
				</span>
			</div>
			<div className='data-wrapper'>
				<span className='patient-stage-card-total'>{total}</span>
				{<span className='view-detail'>View Details</span>}
			</div>
		</div>
	);
};

export default PatientStageCards;
