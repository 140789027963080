import React from 'react';

// Lodash
import get from 'lodash/get';

// Style
import './style.css';

import { PatientTagIndicator } from './PatientTagIndicator';

export default function (props) {
	const tags = get(props, ['tags'], []);
	
	if (!tags.length) return null;

	return tags?.map((tag) => {
		let subtag;
		if (tag.tagProperties?.length === 1) {
			const properties = Object.keys(tag.tagProperties[0]);
			if (properties.length === 1) {
				const propertyValue = tag.tagProperties[0][properties[0]];
				subtag = Array.isArray(propertyValue)
					? propertyValue.join(',')
					: propertyValue;
			}
		}
		return (
			<PatientTagIndicator
				key={tag.name}
				tagType={tag.name}
				subtag={subtag}
				isDisabledTooltip={props?.isDisabledTooltip || false}
			/>
		);
	});	
}
