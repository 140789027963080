//React & Redux
import React, { Fragment } from 'react';

//Lodash
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';

//UI Libraries
import { Layout, Form, Button, Popover } from 'gm-element-react';

//Images
import ReactSVG from 'react-svg';
import editIcon from '../../../../assets/edit.svg';
import moreinfoicon from './../../../../assets/purple-more-info.svg';

//Styles
import './css/view-discrete-variant.css';

//Utils
import { CARE_PATIENTS_ENTER_TEST_RESULT } from '../../../../Utils/permissionUtils';
import {
	dnaChangeTypeEnum,
	proteinChangeTypeEnum,
	labClassificationEnum,
	allelicStateEnum,
} from '../../../../utils';

//Other Libraries
import classnames from 'classnames';

//Components
import RBAC from '../../../RBAC';
import NotesContainer from '../../../Common/Notes/NotesContainer';
import Pill from '../../../Common/Pill';

const ViewDiscreteVariant = (props) => {
	const {
		addDiagnosticFindingClinicalNoteError = null,
		addDiagnosticFindingClinicalNoteLoading = false,
		addNewDiagnosticFindingClinicalNote,
		finding,
		showAddEditDiscreteVariantModalFunc,
		showViewDiscreteVariantFullDetailFunc,
	} = props;

	const uuid = finding?.uuid || null;
	const notes = finding?.notes || [];
	const dbsnpLink =
		'https://identifiers.org/' + (finding?.dbsnpId).toLowerCase();

	const getHgvsDNAChangeText = () => {
		let dnaChangeText = '';
		let dnaChangeType = '';

		if (!isNil(finding.dnaChangeType)) {
			dnaChangeType = dnaChangeTypeEnum.find(
				(o) => o.key === finding?.dnaChangeType || ''
			);
			dnaChangeType = '  (' + dnaChangeType?.display_name + ') ';
		}

		if (!isNil(finding.dnaChangeDescription)) {
			dnaChangeText = finding.dnaChangeDescription + dnaChangeType;
		}
		return dnaChangeText;
	};

	const getHgvsProteinChangeText = () => {
		let proteinChangeText = '--';
		let proteinChangeType = '';

		if (!isNil(finding.proteinChangeType)) {
			proteinChangeType = proteinChangeTypeEnum.find(
				(o) => o.key === finding?.proteinChangeType || ''
			);
			proteinChangeType = '  (' + proteinChangeType?.display_name + ') ';
		}

		if (!isNil(finding.proteinChangeDescription)) {
			proteinChangeText =
				finding.proteinChangeDescription + proteinChangeType;
		}
		return proteinChangeText;
	};

	const getLatestInterpretationText = () => {
		let latestInterpretationText = '--';
		const interpretedOn = moment(
			finding?.mostRecentInterpretation?.interpretedOn || null
		).isValid()
			? moment(
					finding?.mostRecentInterpretation?.interpretedOn || null
			  ).format('MM.DD.YYYY')
			: '';
		let mostRecentInterpretationText = '--';

		if (!isNil(finding?.mostRecentInterpretation?.classification)) {
			mostRecentInterpretationText = labClassificationEnum.find(
				(o) =>
					o.key === finding?.mostRecentInterpretation?.classification
			);
			mostRecentInterpretationText =
				mostRecentInterpretationText?.display_name;
			latestInterpretationText =
				mostRecentInterpretationText + ' | ' + interpretedOn;
		}

		return latestInterpretationText;
	};

	const getLatestInterpretationTooltip = () => {
		let latestInterpretationTooltip = '';

		if (!isNil(finding?.mostRecentInterpretation?.classification || null)) {
			latestInterpretationTooltip =
				finding?.mostRecentInterpretation?.source?.name;
		}
		return latestInterpretationTooltip;
	};

	const getAllelicState = () => {
		let allelicState = '--';

		if (!isNil(finding?.allelicState)) {
			allelicState = allelicStateEnum.find(
				(o) => o.key === finding?.allelicState
			);
			allelicState = allelicState?.display_name;
		}

		return allelicState;
	};

	const hasHgvsNomenclature = finding?.hasHgvsNomenclature || false;

	return (
		<Fragment>
			<Form className='view-discrete-variant-info'>
				<Layout.Row gutter='20' className='discrete-variant-title'>
					<Layout.Col>
						Discrete Variant Finding -{' '}
						{finding?.genesSymbol?.symbol || '--'}
						<Popover
							trigger='hover'
							popperClass={classnames('pp-discrete-variant')}
							content={
								<Fragment>
									<span style={{ display: 'block' }}>
										Name:{' '}
										{finding?.genesSymbol?.name || '--'}
									</span>
									<span style={{ display: 'block' }}>
										Aliases:{' '}
										{(
											finding?.genesSymbol
												?.aliasSymbols || []
										).join(', ')}
									</span>
									<span style={{ display: 'block' }}>
										Cytogenetic location:{' '}
										{finding?.genesSymbol
											?.cytogeneticLocation || '--'}
									</span>
								</Fragment>
							}
							key='popover-discrete-variant'
							placement='top'
						>
							<span className='tooltip-info'>
								<img
									src={moreinfoicon}
									className='more-info-icon'
								/>
							</span>
						</Popover>
						{finding?.assessment === 'ABSENT' && (
							<Pill
								pillText='Not Detected'
								pillExtendStyle='not-detected-pill'
							/>
						)}
					</Layout.Col>
				</Layout.Row>
				<Layout.Row gutter='32' className='clinical-comments-container'>
					<Layout.Col
						span='16'
						className='discrete-variant-result-section'
					>
						<Layout.Row className='result-details-label'>
							<Layout.Col span='20'>Result Details</Layout.Col>
							{!isEmpty(finding?.dbsnpId) && (
								<Layout.Col span='4'>
									<a
										href={dbsnpLink}
										target='_blank'
										className='link-dbsnp'
										rel='noreferrer'
									>
										dbSNP
									</a>
								</Layout.Col>
							)}
						</Layout.Row>

						{hasHgvsNomenclature ? (
							<Layout.Row>
								<Layout.Col span='12'>
									<label htmlFor='primaryPredictedOrign'>
										DNA Change
									</label>
									<span className='field-val'>
										{getHgvsDNAChangeText()}
									</span>
								</Layout.Col>
								<Layout.Col span='12'>
									<label htmlFor='primaryPredictedOrign'>
										Protein Change
									</label>
									<span className='field-val'>
										{getHgvsProteinChangeText()}
									</span>
								</Layout.Col>
							</Layout.Row>
						) : (
							<Layout.Row>
								<Layout.Col span='24'>
									<label htmlFor='primaryPredictedOrign'>
										Cytogenomic Nomenclature (ISCN)
									</label>
									<span className='field-val'>
										{finding?.sequence || '--'}
									</span>
								</Layout.Col>
							</Layout.Row>
						)}

						<Layout.Row>
							<Layout.Col span='12'>
								<label htmlFor='primaryPredictedOrign'>
									Allelic State
								</label>
								<span className='field-val'>
									{getAllelicState()}
								</span>
							</Layout.Col>
							<Layout.Col span='12'>
								<label htmlFor='primaryPredictedOrign'>
									Latest Interpretation
									{!isEmpty(
										getLatestInterpretationTooltip()
									) && (
										<Popover
											trigger='hover'
											popperClass={classnames(
												'pp-latest-interpretation'
											)}
											content={
												<Fragment>
													{getLatestInterpretationTooltip()}
												</Fragment>
											}
											key='popover-latest-interpretation'
											placement='top'
										>
											<span className='tooltip-info'>
												<img
													src={moreinfoicon}
													className='more-info-icon'
												/>
											</span>
										</Popover>
									)}
								</label>
								<span className='field-val'>
									{getLatestInterpretationText()}
								</span>
							</Layout.Col>
						</Layout.Row>

						<Layout.Row>
							<Layout.Col span='18'>
								<Button
									className='discrete-variant-view-full-details'
									type='text'
									onClick={() =>
										showViewDiscreteVariantFullDetailFunc(
											finding
										)
									}
								>
									View full details
								</Button>
							</Layout.Col>
							<Layout.Col span='6'>
								<RBAC
									action={CARE_PATIENTS_ENTER_TEST_RESULT}
									yes={
										<Button
											className='edit-detail-button'
											onClick={() =>
												showAddEditDiscreteVariantModalFunc(
													'edit',
													finding
												)
											}
										>
											<ReactSVG
												src={editIcon}
												className='edit-detail-icon'
											/>
											Edit Original
										</Button>
									}
								/>
							</Layout.Col>
						</Layout.Row>
					</Layout.Col>

					<Layout.Col span='8'>
						<span className='clinical-comments-label'>
							Clinical Comments
						</span>
						<NotesContainer
							notes={notes}
							variant='cfDNA'
							addNoteLoading={
								addDiagnosticFindingClinicalNoteLoading
							}
							addNoteError={addDiagnosticFindingClinicalNoteError}
							addNote={addNewDiagnosticFindingClinicalNote}
							sourceUUID={uuid}
							addNoteModalTitle='New Comment — Discrete Variant'
							emptyMessageClass='empty-clinical-comments-msg'
							isShowListIcon={false}
							hasRBACAddComment={true}
							RBACAddCommentAction={
								CARE_PATIENTS_ENTER_TEST_RESULT
							}
						/>
					</Layout.Col>
				</Layout.Row>
			</Form>
		</Fragment>
	);
};

export default ViewDiscreteVariant;
