//React & Redux
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

//Lodash
import { isEmpty, isNil, isUndefined, mapValues, omitBy, sortBy } from 'lodash';

//UI Libraries
import { Loading, Select, Input, Button } from 'gm-element-react';
import InputMask from 'react-input-mask';

// Component
import ErrorBoundary from '../../../Common/ErrorBoundary';
import DocumentNotice from './DocumentNotice';

//Other Libraries
import moment from 'moment';
import EmailValidator from 'email-validator';
import { isValidNumber } from 'libphonenumber-js';
import classnames from 'classnames';

//Hooks
import { useDebounce } from '../../../../hooks/useDebounce';

const datefields = { activity_date: 1 };

const PATIENT = 'patient';
const PROVIDER = 'provider';
const LAB = 'lab';
const REFERRAL_PROGRAM = 'referral_program';
const OTHER = 'other';

const FAX = 'fax';
const EMAIL = 'email';
const POSTAL_MAIL = 'postal_mail';
const rank = {
	[LAB]: 3,
	[OTHER]: 4,
	[PATIENT]: 1,
	[REFERRAL_PROGRAM]: 2,
	[FAX]: 102,
	[EMAIL]: 101,
	[POSTAL_MAIL]: 103,
};

const LogReleaseDocument = (props) => {
	const {
		document = {},
		documentMediums = [],
		documentMediumsLoading,
		documentRecipients = [],
		documentRecipientsLoading,
		doLogReleaseDocument,
		downloadDocument,
		getAllProviderSpecialties,
		getAllProviders,
		getDocumentMediums,
		getDocumentRecipients,
		getPatientLinkedProviders,
		getSpecialty,
		hideLogReleaseDocument,
		labs = [],
		loggingReleaseDocument,
		patientDetail,
		providers = [],
		referralPrograms = [],
		resetAllProviderState,
	} = props;

	const [disabledRefProgram, setDisabledRefProgram] = useState(false);
	const [formdata, setFormdata] = useState({
		type: 'logged_release',
		recipient_type: null,
		recipient_uuid: null,
		recipient_description: null,
		medium: null,
		email: null,
		fax: null,
		activity_date: null,
	});
	const [returnedProviders, setReturnedProviders] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [searchingProvider, setSearchingProvider] = useState(false);

	const debouncedSearchQuery = useDebounce(searchQuery, 500);

	useEffect(() => {
		getDocumentRecipients('logged_release');
		getPatientLinkedProviders();
		getAllProviderSpecialties();

		return () => resetAllProviderState();
	}, []);

	useEffect(() => {
		setReturnedProviders(providers);
		setSearchingProvider(false);
	}, [providers]);

	useEffect(() => {
		debouncedSearchQuery !== '' && searchAllProviders(debouncedSearchQuery);
	}, [debouncedSearchQuery]);

	const logReleaseDoc = () => {
		const errors = validateForm(formdata);
		if (isEmpty(errors)) {
			const updatedformdata = mapValues(formdata, (value, key) => {
				if (key in datefields)
					return moment(value, 'MM/DD/YYYY', true).isValid()
						? moment(value, 'MM/DD/YYYY', true).format('YYYY-MM-DD')
						: value;
				return value;
			});
			doLogReleaseDocument(document.uuid, {
				data: [
					{
						type: updatedformdata.type,
						medium: updatedformdata.medium,
						email: updatedformdata.email,
						fax: updatedformdata.fax,
						activity_date: updatedformdata.activity_date,
						recipient: {
							type: updatedformdata.recipient_type,
							uuid: updatedformdata.recipient_uuid,
							description: updatedformdata.recipient_description,
						},
					},
				],
			});
		}
	};

	const onFormChange = (key, value) => {
		const newObj = { ...formdata };
		newObj[key] = value;
		if (key === 'recipient_type') {
			getDocumentMediums('logged_release', newObj.recipient_type || '');
			newObj.recipient_description = null;
			if (value === PATIENT) {
				newObj.recipient_uuid = null;
				newObj.medium = POSTAL_MAIL;
				newObj.fax = null;
				newObj.email = null;
			} else if (value === PROVIDER) {
				newObj.recipient_uuid = null;
				newObj.medium = POSTAL_MAIL;
				newObj.fax = null;
				newObj.email = null;
			} else if (value === REFERRAL_PROGRAM) {
				const partnerId = patientDetail?.partner_id;
				const obj = referralPrograms.find(
					(el) => el && el.id == partnerId
				);
				const recipient_uuid = obj?.uuid || null;
				newObj.recipient_uuid = recipient_uuid;
				setDisabledRefProgram(isNil(recipient_uuid));
			} else {
				newObj.recipient_uuid = null;
			}
		} else if (key === 'medium') {
			newObj.fax = null;
			newObj.email = null;
		}

		setFormdata(newObj);
	};

	const sortedDocumentRecipients = () => {
		let sortedarray = sortBy(documentRecipients, (el) => rank[el.name]);
		const otherObj = sortedarray.find((el) => el && el.name == 'other');
		if (!isEmpty(otherObj)) {
			sortedarray = sortedarray.filter((el) => el && el.name != 'other');
			sortedarray.push(otherObj);
		}
		return sortedarray;
	};

	const sortedDocumentMediums = () =>
		sortBy(documentMediums, (el) => rank[el.name]);

	const validateForm = (formdata) => {
		let errors = {};
		const dateErrors = omitBy(
			mapValues(datefields, (value, key) => {
				if (
					!isEmpty(formdata[key] || '') &&
					!moment(formdata[key] || '', 'MM/DD/YYYY', true).isValid()
				) {
					return 'Invalid date';
				}
				return undefined;
			}),
			isUndefined
		);

		errors = { ...dateErrors };
		if (
			isNil(formdata.recipient_type) ||
			isEmpty(formdata.recipient_type)
		) {
			errors.recipient_type = 'Document Sent To is required';
		}

		if (isNil(formdata.medium) || isEmpty(formdata.medium)) {
			errors.medium = 'Document Sent Via is required';
		}

		if (isNil(formdata.activity_date) || isEmpty(formdata.activity_date)) {
			errors.activity_date = 'Document Sent On is required';
		}

		if (
			formdata.recipient_type == LAB &&
			(isEmpty(formdata.recipient_uuid) || isNil(formdata.recipient_uuid))
		) {
			errors.recipient_uuid = 'Lab is required.';
		}

		if (
			formdata.recipient_type == REFERRAL_PROGRAM &&
			(isEmpty(formdata.recipient_uuid) || isNil(formdata.recipient_uuid))
		) {
			errors.recipient_uuid = 'Referral Program is required.';
		}

		if (
			formdata.recipient_type == PROVIDER &&
			(isEmpty(formdata.recipient_uuid) || isNil(formdata.recipient_uuid))
		) {
			errors.recipient_uuid = 'Provider is required.';
		}

		if (
			formdata.recipient_type == OTHER &&
			(isEmpty(formdata.recipient_description) ||
				isNil(formdata.recipient_description))
		) {
			errors.recipient_description = "Recipient's Full Name is required.";
		}

		if (
			formdata.medium == EMAIL &&
			(isEmpty(formdata.email) || isNil(formdata.email))
		) {
			errors.email = 'Email is required.';
		}

		if (
			formdata.medium == FAX &&
			(isEmpty(formdata.fax) || isNil(formdata.fax))
		) {
			errors.fax = 'Fax is required.';
		}

		if (
			formdata.medium == EMAIL &&
			!EmailValidator.validate(formdata.email)
		) {
			errors.email = 'Email is invalid';
		}

		if (formdata.medium == FAX && !isValidNumber(formdata.fax || '')) {
			errors.fax = 'fax is invalid';
		}

		return errors;
	};

	const onSearch = (query) => {
		onFormChange('recipient_uuid', null);
		if (query !== '') {
			setSearchQuery(query);
			setReturnedProviders([]);
		} else {
			setSearchQuery('');
			setReturnedProviders([]);
		}
	};

	const searchAllProviders = (searchQuery) => {
		setSearchingProvider(true);
		getAllProviders({
			is_gmi_provider: false,
			limit: 100,
			q: searchQuery,
		});
	};

	const componentshouldShow = (component) => {
		switch (component) {
			case 'Medium':
				return (
					!isEmpty(formdata.recipient_type) &&
					!isNil(formdata.recipient_type)
				);
			case 'ActivityDate':
				return !isEmpty(formdata.medium) && !isNil(formdata.medium);
			case 'Note':
				return !isEmpty(formdata.medium) && !isNil(formdata.medium);
			default:
				return false;
		}
	};

	const componentshouldDisbled = (component) => {
		if (component == 'Medium') {
			const recipient_type = formdata?.recipient_type || '';
			return recipient_type == PATIENT || recipient_type == PROVIDER;
		}
	};

	const isReleaseToPatient = formdata.recipient_type || '' == PATIENT;
	const isReleaseToExtProvider = formdata.recipient_type || '' == PROVIDER;
	const documentType = document.document_type?.name || '';
	const both = isReleaseToPatient && isReleaseToExtProvider;

	const documentSubtype = document.document_subtype?.name || '';
	const capType =
		documentSubtype == 'visit_summary'
			? 'Visit CAP'
			: documentSubtype == 'results_summary'
			? 'Result CAP'
			: '';

	return (
		<div className='fullModalBackground'>
			<div className='logReleaseDocumentContainer'>
				{loggingReleaseDocument || documentRecipientsLoading ? (
					<Loading loading={true} style={{ position: 'unset' }} />
				) : (
					<>
						<div className='logDocumentTopRow'>
							<div className='logDocumentTitle'>
								Log Manual Release
							</div>
							<div className='logDocumentClose'>
								<i
									data-testId='log-doc-close'
									className='el-dialog__close el-icon el-icon-close'
									onClick={() => hideLogReleaseDocument()}
								></i>
							</div>
						</div>

						<div className='logDocumentBodySection'>
							<div className='logDocumentBodySectionContainer'>
								<div className='logDocBodysectioncol'>
									<DocumentNotice
										document={document}
										handleDownload={() =>
											downloadDocument(null, document)
										}
										icon='warning'
										message={`The related encounter’s ${capType} ${
											isReleaseToPatient
												? 'Sent to patient'
												: ''
										} ${both ? '&' : ''} ${
											both ? capType : ''
										} ${
											isReleaseToExtProvider
												? 'Sent to PCP'
												: ''
										} ${
											both ? 'dates' : 'date'
										} will be updated accordingly.`}
										visible={
											(isReleaseToPatient ||
												isReleaseToExtProvider) &&
											documentType ==
												'visit_documentation' &&
											[
												'results_summary',
												'visit_summary',
											].includes(documentSubtype)
										}
									/>
								</div>

								<div className='logDocBodysectioncol'>
									<div
										className={classnames(
											'logDocinputcontainer',
											documentMediumsLoading
												? 'isReadonly'
												: ''
										)}
									>
										<div className='logDocInputHeaderContainer'>
											<div className='required'>*</div>
											<div className='logDocInputHeader'>
												Document Sent To
											</div>
										</div>
										<div className='logDocInputElementContainer'>
											<Select
												placeholder={'Select'}
												onChange={(val) =>
													onFormChange(
														'recipient_type',
														val
													)
												}
												value={
													formdata.recipient_type ||
													null
												}
											>
												{sortedDocumentRecipients().map(
													(recipient) => (
														<Select.Option
															key={recipient.name}
															value={
																recipient.name
															}
															label={
																recipient.display_name
															}
														></Select.Option>
													)
												)}
											</Select>
										</div>
									</div>
									{formdata.recipient_type === PATIENT && (
										<div className='logDocinputcontainer'>
											<div className='logDocInputHeaderContainer'>
												<div className='required'>
													*
												</div>
												<div className='logDocInputHeader'>
													Recipient's Full Name
												</div>
											</div>
											<div className='logDocInputElementContainer'>
												<Input
													placeholder={'Full Name'}
													value={
														(patientDetail?.first_name ||
															'') +
														' ' +
														(patientDetail?.last_name ||
															'')
													}
													disabled={true}
												/>
											</div>
										</div>
									)}

									{formdata.recipient_type === PROVIDER && (
										<div className='logDocinputcontainer'>
											<div className='logDocInputHeaderContainer'>
												<div className='required'>
													*
												</div>
												<div className='logDocInputHeader'>
													Recipient's Full Name
												</div>
											</div>
											<div className='logDocInputElementContainer'>
												<ErrorBoundary>
													<Select
														className='ext-provider-filter'
														filterable={true}
														clearable={true}
														value={
															formdata.recipient_uuid
														}
														onChange={(val) =>
															onFormChange(
																'recipient_uuid',
																val
															)
														}
														placeholder='Type to search'
														remote={true}
														remoteMethod={onSearch}
														loading={
															searchingProvider
														}
													>
														{returnedProviders.map(
															(e) =>
																e && (
																	<Select.Option
																		key={
																			e.providerUUID
																		}
																		label={`${e.providerFullName}`}
																		value={
																			e.providerUUID
																		}
																	>
																		<span className='pro-wrapper'>
																			<span className='pro-item'>
																				<span className='pro-info'>
																					<span className='pro-name'>
																						{
																							e.providerFullName
																						}
																					</span>
																					<span className='pro-location'>{`${
																						e.city ||
																						''
																					}${
																						!isEmpty(
																							e.state
																						)
																							? ', ' +
																							  e.state
																							: ''
																					}`}</span>
																				</span>
																			</span>
																			<span className='pro-specialty'>
																				{getSpecialty(
																					e
																						.specialities?.[0] ||
																						''
																				)}
																			</span>
																		</span>
																	</Select.Option>
																)
														)}
													</Select>
												</ErrorBoundary>
											</div>
										</div>
									)}

									{formdata.recipient_type == OTHER && (
										<div className='logDocinputcontainer'>
											<div className='logDocInputHeaderContainer'>
												<div className='required'>
													*
												</div>
												<div className='logDocInputHeader'>
													Recipient's Full Name
												</div>
											</div>
											<div className='logDocInputElementContainer'>
												<Input
													placeholder={'Full Name'}
													value={
														formdata.recipient_description
													}
													onChange={(val) =>
														onFormChange(
															'recipient_description',
															val
														)
													}
												/>
											</div>
										</div>
									)}

									{formdata.recipient_type == LAB && (
										<div className='logDocinputcontainer'>
											<div className='logDocInputHeaderContainer'>
												<div className='required'>
													*
												</div>
												<div className='logDocInputHeader'>
													Lab
												</div>
											</div>
											<div className='logDocInputElementContainer'>
												<Select
													placeholder={'Select'}
													onChange={(val) =>
														onFormChange(
															'recipient_uuid',
															val
														)
													}
													value={
														formdata.recipient_uuid ||
														null
													}
												>
													{labs.map((medium) => (
														<Select.Option
															key={medium.key}
															value={medium.key}
															label={
																medium.display_name ||
																' '
															}
														/>
													))}
												</Select>
											</div>
										</div>
									)}

									{formdata.recipient_type ==
										REFERRAL_PROGRAM && (
										<div className='logDocinputcontainer'>
											<div className='logDocInputHeaderContainer'>
												<div className='required'>
													*
												</div>
												<div className='logDocInputHeader'>
													Referral Program
												</div>
											</div>
											<div className='logDocInputElementContainer'>
												<Select
													placeholder={'Select'}
													onChange={(val) =>
														onFormChange(
															'recipient_uuid',
															val
														)
													}
													value={
														formdata.recipient_uuid ||
														null
													}
													disabled={
														disabledRefProgram
													}
												>
													{referralPrograms.map(
														(medium) => (
															<Select.Option
																key={
																	medium.uuid
																}
																value={
																	medium.uuid
																}
																label={
																	medium.display_name ||
																	' '
																}
															/>
														)
													)}
												</Select>
											</div>
										</div>
									)}

									{componentshouldShow('Medium') && (
										<Loading
											loading={documentMediumsLoading}
										>
											<div className='logDocinputcontainer'>
												<div className='logDocInputHeaderContainer'>
													<div className='required'>
														*
													</div>
													<div className='logDocInputHeader'>
														Document Sent Via
													</div>
												</div>
												<div className='logDocInputElementContainer'>
													<Select
														placeholder={'Select'}
														onChange={(val) =>
															onFormChange(
																'medium',
																val
															)
														}
														value={
															formdata.medium ||
															null
														}
														disabled={componentshouldDisbled(
															'Medium'
														)}
													>
														{sortedDocumentMediums().map(
															(medium) => (
																<Select.Option
																	key={
																		medium.name
																	}
																	value={
																		medium.name
																	}
																	label={
																		medium.display_name
																	}
																/>
															)
														)}
													</Select>
												</div>
											</div>
										</Loading>
									)}

									{formdata.medium == EMAIL && (
										<div className='logDocinputcontainer'>
											<div className='logDocInputHeaderContainer'>
												<div className='required'>
													*
												</div>
												<div className='logDocInputHeader'>
													Recipient's Email
												</div>
											</div>
											<div className='logDocInputElementContainer'>
												<Input
													placeholder={
														'email@email.com'
													}
													value={formdata.email}
													onChange={(val) =>
														onFormChange(
															'email',
															val
														)
													}
												/>
											</div>
										</div>
									)}

									{formdata.medium == FAX && (
										<div className='logDocinputcontainer'>
											<div className='logDocInputHeaderContainer'>
												<div className='required'>
													*
												</div>
												<div className='logDocInputHeader'>
													Recipient's Fax
												</div>
											</div>
											<div className='logDocInputElementContainer'>
												<InputMask
													placeholder={
														'+1 (123) 456-7890'
													}
													value={formdata.fax}
													onChange={(e) =>
														onFormChange(
															'fax',
															e.target.value
														)
													}
													autoComplete='off'
													mask={'+1 (999) 999-9999'}
													maskChar={null}
												/>
											</div>
										</div>
									)}

									{componentshouldShow('ActivityDate') && (
										<div className='logDocinputcontainer'>
											<div className='logDocInputHeaderContainer'>
												<div className='required'>
													*
												</div>
												<div className='logDocInputHeader'>
													Document Sent On
												</div>
											</div>
											<div className='logDocInputElementContainer'>
												<InputMask
													name='activity_date'
													disabled={false}
													placeholder='MM/DD/YYYY'
													mask={'99/99/9999'}
													maskChar={null}
													autoComplete='off'
													value={
														formdata.activity_date ||
														''
													}
													onChange={(e) =>
														onFormChange(
															'activity_date',
															e.target.value
														)
													}
												/>
											</div>
										</div>
									)}
								</div>

								{componentshouldShow('Note') && (
									<div className='logDocBodysectioncol'>
										<div className='note'>
											{' '}
											This manual log will be reflected in
											the document's audit trail.
										</div>
									</div>
								)}
							</div>
						</div>
						<div className='logDocumentFooterSection'>
							<div className='logDocBodysectioncol'>
								<div className='sharedocumentinputcontainer'>
									<Button
										className='logreleasebtn'
										onClick={logReleaseDoc}
										disabled={
											!isEmpty(validateForm(formdata))
										}
									>
										Log Manual Release
									</Button>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

LogReleaseDocument.propTypes = {
	hideLogReleaseDocument: PropTypes.func.isRequired,
	document: PropTypes.object.isRequired,
	downloadDocument: PropTypes.func.isRequired,
	doLogReleaseDocument: PropTypes.func.isRequired,
};

export default LogReleaseDocument;
