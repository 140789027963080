//React & Redux
import React, { Fragment, useEffect, useRef, useState } from 'react';

//Images
import editpencil from '../../../../../assets/edit.svg';
import imageExpandCollapse from '../../../../../assets/chevron-large.svg';

//Lodash
import _ from 'lodash';

//Utils
import {
	CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER,
	CARE_PATIENTS_ENCOUNTER_ADDINFOSEC_EDIT,
	CARE_PATIENTS_PATIENT_ENCOUNTER_ADDITIONAL_INFO,
	CARE_PATIENTS_PATIENT_ENCOUNTER_PROVIDER_OPERATIONS,
	CARE_PATIENTS_PATIENT_ENCOUNTER_BILLING,
} from '../../../../../Utils/permissionUtils';
import {
	scp_encounter_sub_types_pre_appt,
	scp_encounter_sub_types_automated_pre_test,
	scp_encounter_sub_types_partner_initiated_pre_test,
} from '../../../../../Utils/encounterUtils';

import { isPartnerInitiatedUnsolicitedResult } from '../Common/utilsfunctions';

//Actions & Services
import { createTestOrderNote } from '../../../../../actions/encounter';
import {
	addOutreach,
	updateOutreach,
} from '../../../../../actions/patient/outreach';
import { followUpDeclinedOutreach } from '../../../../../actions/encounter';

//UI Libraries
import { Loading } from 'gm-element-react';

//Other Libraries
import classnames from 'classnames';

//Components
import SCPDetailSegment from './SCPDetailSegment';
import EditSCPDetailSegment from './EditSCPDetailSegment';
import TestOrderSegment from './TestOrderSegment';
import EditTestOrderSegment from './EditTestOrderSegment';
import RORSegment from './RORSegment';
import BillingSegment from './BillingSegment';
import EditBillingSegmnet from './EditBillingSegment';
import AdditionalSegment from './AdditionalSegment';
import EditAdditionalSegment from './EditAdditionalSegment';
import PatientClinicalInfo from './PatientClinicalInfo';
import EditPatientClinicalInfo from './EditPatientClinicalInfo';
import ContentReviewedByPatient from './ContentReviewedByPatient';
import PGXSegment from './PGXSegment';
import EditPGXSegment from './EditPGXSegment';
import RBAC from '../../../../RBAC';
import ProviderLinked from '../../../../ExternalProviders/ProviderLinked';
import ProviderAddAnother from '../../../../ExternalProviders/ProviderAddAnother';
import { usePrevious } from '../../../../../hooks/usePrevious';

const SCPEncounterDetail = (props) => {
	const {
		billingencounter,
		externalProviderProps,
		callvisitsschema,
		LoadTestEnums,
		fetchOrderingPhysician,
		fetchVisitProvider,
		callGeneTestEnums,
		callTestOrderRequest,
		callSCPClinicalInfo,
		getOutreachsLog,
		getPatientFeeRefundEnum,
		encounter,
		encounterReclaim,
		getencouter,
		UpdateTestOrder,
		enqueueSnackbar,
		reclaimEncounter,
		visitsschema,
		visit_provider,
		testnameenums,
		nonlegacytestnameenums,
		orderstatuscascadeenums,
		filteredorderstatuscascadeenums,
		orderrequeststatuscascadeenums,
		ordering_physician,
		preauthorizationsEnums,
		goToDocumentTab,
		sampleTypeEnums,
		labOrderStatusEnums,
		patientBillingMethodEnums,
		outreachStatusEnums,
		testOrderUpdating,
		auditTrailEncounterLoading,
		auditTrailEncounter,
		auditTrailEncounterError,
		getEncounterAuditTrail,
		getLatestOutreachStatus,
		gcRoROutreachStopReasonsEnums,
		patientDeclinedReasonsEnums,
		outreachEnums,
		encounterCommunications,
		patientdetail,
		patientFeeEnum,
		calleditbillingencouter,
		calleditencouter,
		encounterTestOrderRequest,
		gotoMLOTestDetail,
		callTestOrderNotes,
		testOrderNotes,
		activeRole,
		creatingTestOrderFlags,
		updatingTestOrderFlags,
		encounterTestOrderRequestLoading,
		isBillingCoordinator,
		getICDCodes,
		icdCodes,
		icdCodesLoading,
		icdCodesError,
		icdCodesPlacement,
		MLOOrderStatusEnums,
		LoadTestOrderPopupTestEnum,
		nonlegacytestOrderPopupTestEnums,
		createTestRequestFlags,
		updateTestRequestFlags,
		scpClinicalInfo,
		contentViewedByPatientLoading,
		contentViewedByPatient,
		getAllContentViewedByPatient,
		user,
		shouldEditable,
		encounter_uuid,
		patient_uuid,
	} = props;
	const defaultSegment = {
		SCPDetailSegment: false,
		TestOrderSegment: !isBillingCoordinator,
		PxInfoSegment: false,
		RORSegment: false,
		BillingSegment: isBillingCoordinator,
		AdditionalSegment: false,
		PatientClinicalInfo: true,
		CoveredEducationalContents: false,
		SCPTestOrderSegment: true,
		ContentReviewedByPatient: false,
		ExtProvidersSegment: _.get(
			externalProviderProps,
			'expandExtProviders',
			false
		),
	};
	const sectionRefs = useRef([]);
	const [segment, setSegment] = useState(defaultSegment);
	const [EditedSegment, setEditedSegment] = useState('');
	const [billingViewLoading, setBillingViewLoading] = useState(false);
	const [isPreAppt, setIsPreAppt] = useState(false);
	const [isAutomatedPreTest, setIsAutomatedPreTest] = useState(false);
	const [isPartnerInitiatedPreTest, setIsPartnerInitiatedPreTest] =
		useState(false);
	const [showUnableStopOutreachModal, setShowUnableStopOutreachModal] =
		useState(false);

	const previousEncounterReclaim = usePrevious(encounterReclaim);

	useEffect(() => {
		callvisitsschema();
		LoadTestEnums(_.get(encounter, ['lab'], ''));
		fetchOrderingPhysician();
		fetchVisitProvider();
		callGeneTestEnums();
		callTestOrderRequest();
		callSCPClinicalInfo();
		getOutreachsLog();
		getPatientFeeRefundEnum();

		const isPreAppt = checkPreAppt();
		setIsPreAppt(isPreAppt);
		if (isPreAppt) {
			if (segment['TestOrderSegment'] == true)
				toggleSegment('TestOrderSegment');
		}
		const isAutomatedPreTest = checkAutomatedPreTest();
		const isPartnerInitiatedPreTest = checkPartnerInitiatedPreTest();
		setIsAutomatedPreTest(isAutomatedPreTest);
		setIsPartnerInitiatedPreTest(isPartnerInitiatedPreTest);
		const orderStatus = _.get(encounter, ['order_request_status'], '');
		if (isAutomatedPreTest && orderStatus == 'requested') {
			toggleSegment('SCPTestOrderSegment');
		}
	}, []);

	useEffect(() => {
		if (
			_.get(previousEncounterReclaim, ['loading']) &&
			!_.get(encounterReclaim, ['loading'])
		) {
			getencouter(false);
			setTimeout(() => {
				setBillingViewLoading(false);
			}, 1000);
		}
	}, [encounterReclaim]);

	const handleUpdateTestOrder = (
		test_order_uuid,
		data,
		encounterrequestdata,
		notedata
	) => {
		const promiseall = [];

		const promise1 = UpdateTestOrder(test_order_uuid, data);
		promiseall.push(promise1);

		if (!_.isEmpty(notedata)) {
			const promise3 = createTestOrderNote(test_order_uuid, notedata);
			promiseall.push(promise3);
		}
		return Promise.all(promiseall)
			.then((result) => {
				getencouter(false);
				callTestOrderRequest();
				getOutreachsLog();
				enqueueSnackbar('Test Order updated successfully', {
					variant: 'info',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				return result;
			})
			.catch((error) => {
				enqueueSnackbar('Error while updating Test Order', {
					variant: 'error',
					anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				});
				throw error;
			});
	};

	const handleReclaimEncounter = () => {
		setBillingViewLoading(true);
		reclaimEncounter();
	};

	const makeEdited = (segmentname) => (e) => {
		const tempSegment = { ...segment };
		if (shouldEditable) {
			const EditedSegment = segmentname;
			setEditedSegment(EditedSegment);
			tempSegment[segmentname] = true;
			setSegment(tempSegment);
		}
		e.stopPropagation();
	};

	const makeNonEdited = (segmentname) => {
		const tempSegment = { ...segment };
		setEditedSegment('');
		tempSegment[segmentname] = true;
		setSegment(tempSegment);
	};

	const isAnyEditActive = () => {
		return EditedSegment != '';
	};

	const isInEditMode = (segmentname) => {
		return segmentname == EditedSegment;
	};

	const isExpanded = (segmentname) => {
		return _.get(segment, [segmentname], false) == true;
	};

	const toggleSegment = (segmentname) => {
		const tempSegment = { ...segment };
		const isInEditMod = isInEditMode(segmentname);
		if (tempSegment && !isInEditMod) {
			tempSegment[segmentname] = !tempSegment[segmentname];
			setSegment(tempSegment);
		}
	};

	const navigateAndExpand = (ref, key) => {
		const scrollHeight = document.body.scrollHeight;
		const windowHeight = window.innerHeight;
		const scrollPosition = _.get(
			sectionRefs,
			['current', ref, 'offsetTop'],
			40
		);
		const offset = scrollHeight - windowHeight > scrollPosition ? 60 : 0;
		window.scrollTo({
			top: scrollPosition - offset,
			left: 0,
			behavior: 'smooth',
		});
		if (!segment[key]) toggleSegment(key);
	};

	const scpDetailsProps = () => {
		return {
			enqueueSnackbar: enqueueSnackbar,
			encounter: {
				scheduling_poc: _.get(encounter, ['scheduling_poc']),
				state_of_visit: _.get(encounter, ['state_of_visit']),
				timezone: _.get(encounter, ['timezone']),
				relationship_to_patient: _.get(encounter, [
					'relationship_to_patient',
				]),
				date_of_service: _.get(encounter, ['date_of_service']),

				tm_platform: _.get(encounter, ['tm_platform']),
				consultation_type: _.get(encounter, ['consultation_type']),
				vsee_specialty: _.get(encounter, ['vsee_specialty']),
				visit_provider: _.get(encounter, ['visit_provider']),
				visit_status: _.get(encounter, ['visit_status']),

				records_requested: _.get(encounter, ['records_requested']),
				records_received: _.get(encounter, ['records_received']),
				referring_provider_name: _.get(encounter, [
					'referring_provider_name',
				]),
				referring_provider_fax_email: _.get(encounter, [
					'referring_provider_fax_email',
				]),
				requested_test_via_provider: _.get(encounter, [
					'requested_test_via_provider',
				]),

				initial_cap_completed_date: _.get(encounter, [
					'initial_cap_completed_date',
				]),
				initial_visit_summary_sent: _.get(encounter, [
					'initial_visit_summary_sent',
				]),
				visit_summary_release_method_patient: _.get(encounter, [
					'visit_summary_release_method_patient',
				]),

				followup_cap_completed_date: _.get(encounter, [
					'followup_cap_completed_date',
				]),
				cap_sent_to_patient: _.get(encounter, ['cap_sent_to_patient']),
				cap_release_method_patient: _.get(encounter, [
					'cap_release_method_patient',
				]),
				visit_summary_release_date_pcp: _.get(encounter, [
					'visit_summary_release_date_pcp',
				]),
				visit_summary_release_method_pcp: _.get(encounter, [
					'visit_summary_release_method_pcp',
				]),
				cap_sent_date_pcp: _.get(encounter, ['cap_sent_date_pcp']),
				cap_release_method_pcp: _.get(encounter, [
					'cap_release_method_pcp',
				]),

				medical_codes: _.get(encounter, 'medical_codes', []),
				uuid: _.get(encounter, 'uuid', []),
				type: _.get(encounter, 'type', ''),
				encounter_subtype: _.get(encounter, 'encounter_subtype', false),
			},
			billingencounter: {
				direct_cc_minutes: _.get(
					billingencounter,
					['direct_cc_minutes'],
					null
				),
				indirect_cc_minutes: _.get(
					billingencounter,
					['indirect_cc_minutes'],
					null
				),
				post_session_indirect_cc_minutes: _.get(
					billingencounter,
					['post_session_indirect_cc_minutes'],
					null
				),
				post_negative_minutes: _.get(billingencounter, [
					'post_negative_minutes',
				]),
			},
			visitsschema: visitsschema,
			visit_providerenums: visit_provider,
		};
	};

	const testOrdersProps = () => {
		return {
			encounter: {
				order_request_status: _.get(encounter, [
					'order_request_status',
				]),
				lab: _.get(encounter, ['lab']),
				multiple_labs: _.get(encounter, ['multiple_labs'], false),
				test_name: _.get(encounter, ['test_name']),
				ordering_physician: _.get(encounter, ['ordering_physician']),
				date_test_authorized: _.get(encounter, [
					'date_test_authorized',
				]),

				bd_saliva_kit_sent: _.get(encounter, ['bd_saliva_kit_sent']),
				order_status: _.get(encounter, ['order_status']),
				date_test_ordered: _.get(encounter, ['date_test_ordered']),
				expected_lab_results_date: _.get(encounter, [
					'expected_lab_results_date',
				]),
				date_received_report: _.get(encounter, [
					'date_received_report',
				]),

				date_results_released_to_patient: _.get(encounter, [
					'date_results_released_to_patient',
				]),
				blood_draw_request: _.get(encounter, ['blood_draw_request']),
				blood_draw_order_date: _.get(encounter, [
					'blood_draw_order_date',
				]),
				uuid: _.get(encounter, 'uuid', []),
			},
			visitsschema: visitsschema,
			testnameenums: _.get(testnameenums, ['data'], []),
			nonlegacytestnameenums: nonlegacytestnameenums,
			orderstatuscascadeenums: orderstatuscascadeenums,
			filteredorderstatuscascadeenums: filteredorderstatuscascadeenums,
			orderrequeststatuscascadeenums: orderrequeststatuscascadeenums,
			ordering_physicianenums: ordering_physician,
			preauthorizationsenums: _.get(
				preauthorizationsEnums,
				['preauthorizationsenums'],
				{}
			),
			goToDocumentTab: goToDocumentTab,
			sampletypeenums: sampleTypeEnums,
			laborderstatusenums: labOrderStatusEnums,
			patientbillingmethodenums: patientBillingMethodEnums,
			outreachStatusEnums: outreachStatusEnums,
			testOrderUpdating: testOrderUpdating,
			auditTrailEncounterLoading: auditTrailEncounterLoading,
			auditTrailEncounter: auditTrailEncounter,
			auditTrailEncounterError: auditTrailEncounterError,
			getEncounterAuditTrail: getEncounterAuditTrail,
		};
	};

	const pgxProps = () => {
		return {
			encounter: {
				drug_contraindications: _.get(encounter, [
					'drug_contraindications',
				]),
				drug_dosage_adjustment_recommended: _.get(encounter, [
					'drug_dosage_adjustment_recommended',
				]),
				drug_interaction: _.get(encounter, ['drug_interaction']),
				no_of_interactions: _.get(encounter, ['no_of_interactions']),
				pgx_post_cap_indirect_time: _.get(encounter, [
					'pgx_post_cap_indirect_time',
				]),
				pgx_post_session_indirect_time: _.get(encounter, [
					'pgx_post_session_indirect_time',
				]),
				pgx_post_session_indirect_time_date: _.get(encounter, [
					'pgx_post_session_indirect_time_date',
				]),
				pgx_pre_session_indirect_time: _.get(encounter, [
					'pgx_pre_session_indirect_time',
				]),
				pharmd: _.get(encounter, ['pharmd']),
			},
			visitsschema: visitsschema,
		};
	};

	const rorProps = () => {
		return {
			encounter: {
				ror_visit_status: _.get(encounter, ['ror_visit_status']),
				ror_date_contacted: _.get(encounter, ['ror_date_contacted']),
				ror_date_recontacted: _.get(encounter, [
					'ror_date_recontacted',
				]),
				ror_date_final_contacted: _.get(encounter, [
					'ror_date_final_contacted',
				]),
				outreach_status: _.get(encounter, ['outreach_status']),
			},
			visitsschema: visitsschema,
			outreachStatusEnums: outreachStatusEnums,
			communications: encounterCommunications,
			outreachEnums: outreachEnums,
			patientDeclinedReasonsEnums: patientDeclinedReasonsEnums,
			gcRoROutreachStopReasonsEnums: gcRoROutreachStopReasonsEnums,
			addOutreachHandleSubmit: addOutreachHandleSubmit,
			editOutreachHandleSubmit: editOutreachHandleSubmit,
			handleFUDeclinedOutreachSubmit: handleFUDeclinedOutreachSubmit,
			getOutreachLog: () => getOutreachsLog(false),
			geneTestOrderRequest: filterTestOrderRequest(),
			showUnableStopOutreachModal: showUnableStopOutreachModal,
			closeUnableStopOutreachModal: closeUnableStopOutreachModal,
			getLatestOutreachStatus: getLatestOutreachStatus,
			callTestOrderRequest: callTestOrderRequest,
		};
	};

	const billingProps = () => {
		return {
			encounter: {
				uuid: _.get(encounter, ['uuid']),
				date_of_service: _.get(encounter, ['date_of_service']),
				medical_codes: _.get(encounter, ['medical_codes']),
				initial_cap_completed_date: _.get(encounter, [
					'initial_cap_completed_date',
				]),
				order_request_status: _.get(encounter, [
					'order_request_status',
				]),
			},
			billingencounter: {
				patient_billing_date: _.get(billingencounter, [
					'patient_billing_date',
				]),
				patient_billing_amount: _.get(billingencounter, [
					'patient_billing_amount',
				]),
				athena_claim_id: _.get(billingencounter, ['athena_claim_id']),
				direct_cc_minutes: _.get(billingencounter, [
					'direct_cc_minutes',
				]),
				indirect_cc_minutes: _.get(billingencounter, [
					'indirect_cc_minutes',
				]),
				units: _.get(billingencounter, ['units']),
				procedure_code: _.get(billingencounter, ['procedure_code']),
				insurance_expected_revenue: _.get(billingencounter, [
					'insurance_expected_revenue',
				]),
				athena_insurance_package_id: _.get(billingencounter, [
					'athena_insurance_package_id',
				]),
				athena_payment_batch_id: _.get(billingencounter, [
					'athena_payment_batch_id',
				]),
				athena_insurance_id: _.get(billingencounter, [
					'athena_insurance_id',
				]),
				athena_authorization_id: _.get(billingencounter, [
					'athena_authorization_id',
				]),
				athena_rendering_provider_id: _.get(billingencounter, [
					'athena_rendering_provider_id',
				]),
				athena_referring_provider_id: _.get(billingencounter, [
					'athena_referring_provider_id',
				]),
				payor: _.get(billingencounter, ['payor']),
				coupon_code: _.get(billingencounter, ['coupon_code']),
				athena_claim_err_msg: _.get(billingencounter, [
					'athena_claim_err_msg',
				]),
				patient_payment: _.get(billingencounter, ['patient_payment']),
				patient_pay: _.get(billingencounter, ['patient_pay']),
				billing_status: _.get(billingencounter, ['billing_status']),
				post_negative_minutes: _.get(billingencounter, [
					'post_negative_minutes',
				]),
				insurance_claim_status: _.get(billingencounter, [
					'insurance_claim_status',
				]),
				insurance_claim_date: _.get(billingencounter, [
					'insurance_claim_date',
				]),

				billing_notes: _.get(billingencounter, ['billing_notes']),

				patient_fee_refund: _.get(billingencounter, [
					'patient_fee_refund',
				]),
				patient_fee_refund_reason: _.get(billingencounter, [
					'patient_fee_refund_reason',
				]),
				patient_fee_refund_reason_other: _.get(billingencounter, [
					'patient_fee_refund_reason_other',
				]),
				patient_fee_refund_processed_date: _.get(billingencounter, [
					'patient_fee_refund_processed_date',
				]),
			},
			visitsschema: visitsschema,
			reclaimEncounter: handleReclaimEncounter,
			encounterReclaim: encounterReclaim,
			patientdetail: patientdetail,
			patientFeeEnum: patientFeeEnum,
		};
	};

	const additionalInfoProps = () => {
		return {
			encounter: {
				notes: _.get(encounter, ['notes']),
			},
			visitsschema: visitsschema,
		};
	};

	const saveSCPEncounter = (initalProps, formdata) => {
		const promiseall = [];
		const edited = _.reduce(
			formdata,
			function (result, value, key) {
				return _.isEqual(value, initalProps[key])
					? result
					: result.concat(key);
			},
			[]
		);

		if (edited && _.get(edited, 'length', 0) > 0) {
			const json = {
				data: {
					type: _.get(encounter, ['type']),
					uuid: _.get(encounter, ['uuid']),
				},
			};

			edited.map((e) => {
				if (_.has(formdata, e)) {
					json['data'][e] = _.get(formdata, e, '');
				} else {
					json['data'][e] = _.get(initalProps, [e]);
				}
				if (json['data'][e] == 'empty') {
					json['data'][e] = '';
				}
			});
			const promise2 = calleditencouter(json);
			promiseall.push(promise2);

			return Promise.all(promiseall)
				.then((result) => {
					getencouter(false);
					enqueueSnackbar('Encounter successfully updated', {
						variant: 'info',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
					return result;
				})
				.catch((error) => {
					enqueueSnackbar('Error in edit encounter', {
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
					throw error;
				});
		} else return Promise.resolve({});
	};
	const saveSCPBillingEncounter = (
		initalProps,
		formdata,
		updateencounterdata
	) => {
		const edited = _.reduce(
			formdata,
			function (result, value, key) {
				return _.isEqual(value, initalProps[key])
					? result
					: result.concat(key);
			},
			[]
		);

		if (edited && _.get(edited, 'length', 0) > 0) {
			const json = {
				data: {
					uuid: _.get(billingencounter, ['uuid']),
					encounter_uuid: _.get(encounter, ['uuid']),
				},
			};

			edited.map((e) => {
				const makeObjNull = {
					patient_fee_refund_reason: 1,
					patient_fee_refund_reason_other: 1,
				};

				if (_.has(formdata, e)) {
					json['data'][e] = _.get(formdata, e, '');
				} else {
					json['data'][e] = _.get(initalProps, [e]);
				}

				if (json['data'][e] == 'empty') {
					json['data'][e] = '';
				}

				if (e in makeObjNull && json['data'][e] == '') {
					json['data'][e] = null;
				}
			});
			if (json.data.hasOwnProperty('units')) delete json.data.units;
			if (json.data.hasOwnProperty('units_s0265_95'))
				delete json.data.units_s0265_95;
			return calleditbillingencouter(json)
				.then((data) => {
					getencouter(false);
					if (
						formdata['post_negative_minutes'] ==
						_.get(billingencounter, 'post_negative_minutes')
					) {
						enqueueSnackbar('Encounter successfully updated', {
							variant: 'info',
							anchorOrigin: {
								horizontal: 'right',
								vertical: 'bottom',
							},
						});
					}
					return data;
				})
				.catch((error) => {
					enqueueSnackbar('Error in edit encounter', {
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
					throw error;
				});
		} else {
			return Promise.resolve({});
		}
	};

	const checkPreAppt = () => {
		const subType = _.get(encounter, 'encounter_subtype', '');
		return _.get(scp_encounter_sub_types_pre_appt, subType, false);
	};
	const checkAutomatedPreTest = () => {
		const subType = _.get(encounter, 'encounter_subtype', '');
		return _.get(
			scp_encounter_sub_types_automated_pre_test,
			subType,
			false
		);
	};
	const checkPartnerInitiatedPreTest = () => {
		const subType = _.get(encounter, 'encounter_subtype', '');
		return _.get(
			scp_encounter_sub_types_partner_initiated_pre_test,
			subType,
			false
		);
	};

	const handleFUDeclinedOutreachSubmit = (encounter_uuid, data) => {
		return followUpDeclinedOutreach(encounter_uuid, data)
			.then((res) => {
				if (
					_.get(res, ['data', 'data', 'ror_advanced'], true) == false
				) {
					setShowUnableStopOutreachModal(true);
				} else {
					getOutreachsLog();
					callTestOrderRequest();
					enqueueSnackbar(
						'Outreach mark as followup declined successfully.',
						{
							variant: 'info',
							anchorOrigin: {
								horizontal: 'right',
								vertical: 'bottom',
							},
						}
					);
				}
				return res;
			})
			.catch((err) => {
				enqueueSnackbar(
					'Error while outreach mark as followup declined.',
					{
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					}
				);
				throw err;
			});
	};

	const compareFields = (currentData, initalData) => {
		const formdata = {};

		if (
			!_.isEqual(
				_.get(currentData, 'date_time'),
				_.get(initalData, 'contactDateTime')
			)
		)
			formdata['date_time'] = currentData['date_time'];
		if (
			!_.isEqual(
				_.get(currentData, 'contact_method'),
				_.get(initalData, ['contactMethod', 'name'])
			)
		)
			formdata['contact_method'] = currentData['contact_method'];
		if (
			!_.isEqual(
				_.get(currentData, 'type'),
				_.get(initalData, ['type', 'name'])
			)
		)
			formdata['type'] = currentData['type'];
		if (
			!_.isEqual(
				_.get(currentData, 'outcome'),
				_.get(initalData, ['outcome', 'name'])
			)
		)
			formdata['outcome'] = currentData['outcome'];
		if (_.get(currentData, 'outcome') == 'other')
			formdata['notes'] = currentData['notes'];
		if (_.get(currentData, 'type') == 'ror_outreach') {
			formdata['encounter_uuid'] = currentData['encounter_uuid'];
			formdata['outreach_sequence_id'] =
				currentData['outreach_sequence_id'];
		}
		return formdata;
	};

	const addOutreachHandleSubmit = (data) => {
		return addOutreach(data, patient_uuid)
			.then(() => {
				getOutreachsLog();
				enqueueSnackbar('Outreach saved successfully.', {
					variant: 'info',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
			})
			.catch((err) => {
				enqueueSnackbar('Error in outreach.', {
					variant: 'error',
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'bottom',
					},
				});
				throw err;
			});
	};

	const editOutreachHandleSubmit = (
		formdata,
		communication_uuid,
		initalProps
	) => {
		const updatedData = compareFields(formdata, initalProps);

		if (!_.isEmpty(updatedData)) {
			return updateOutreach(updatedData, patient_uuid, communication_uuid)
				.then(() => {
					getOutreachsLog();
					enqueueSnackbar('Outreach update successfully.', {
						variant: 'info',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
				})
				.catch((err) => {
					enqueueSnackbar('Error in outreach.', {
						variant: 'error',
						anchorOrigin: {
							horizontal: 'right',
							vertical: 'bottom',
						},
					});
					throw err;
				});
		} else {
			return Promise.resolve();
		}
	};
	const filterTestOrderRequest = () => {
		if (_.isArray(encounterTestOrderRequest)) {
			return _.map(encounterTestOrderRequest, (obj) => {
				return {
					testName: obj.genetic_test_display_name,
					encounterUUID: encounter_uuid,
					outreachSeqNo: obj.outreach_seq_no,
				};
			});
		}
		return [];
	};

	const closeUnableStopOutreachModal = () => {
		setShowUnableStopOutreachModal(false);
	};

	const encounterDetailsSection = () => {
		return (
			<div
				className='encounter-section'
				ref={(section) => {
					sectionRefs.current['encounter_details'] = section;
				}}
			>
				<div
					className={classnames('encounter-accordian-header', {
						'encounter-accordian-header-expanded':
							isExpanded('SCPDetailSegment'),
					})}
					style={{
						cursor: isInEditMode('SCPDetailSegment')
							? 'auto'
							: 'pointer',
					}}
					onClick={() => toggleSegment('SCPDetailSegment')}
				>
					<span className='accordian-title'>ENCOUNTER DETAILS</span>
					{!isInEditMode('SCPDetailSegment') && (
						<Fragment>
							{isExpanded('SCPDetailSegment') &&
								!isAnyEditActive() && (
									<RBAC
										action={
											CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
										}
										yes={
											<span>
												<img
													src={editpencil}
													alt='edit-icon'
													onClick={makeEdited(
														'SCPDetailSegment'
													)}
												/>
											</span>
										}
									/>
								)}
							<span className='accordian-icon'>
								<img
									src={imageExpandCollapse}
									style={{
										transform: isExpanded(
											'SCPDetailSegment'
										)
											? 'rotate(-90deg)'
											: 'rotate(90deg)',
										float: 'right',
									}}
									alt='expand-icon'
								/>
							</span>
						</Fragment>
					)}
				</div>
				<div
					className='encounter-accordian-body'
					style={{
						display: isExpanded('SCPDetailSegment')
							? 'block'
							: 'none',
					}}
				>
					{isInEditMode('SCPDetailSegment') ? (
						<RBAC
							action={CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER}
							yes={
								<EditSCPDetailSegment
									{...scpDetailsProps()}
									saveSCPEncounter={saveSCPEncounter}
									saveSCPBillingEncounter={
										saveSCPBillingEncounter
									}
									cancel={() =>
										makeNonEdited('SCPDetailSegment')
									}
									icdCodes={icdCodes}
									icdCodesLoading={icdCodesLoading}
									icdCodesError={icdCodesError}
									icdCodesPlacement={icdCodesPlacement}
									getICDCodes={getICDCodes}
								/>
							}
						/>
					) : (
						<SCPDetailSegment {...scpDetailsProps()} />
					)}
				</div>
			</div>
		);
	};

	return (
		<Fragment>
			{
				<Fragment>
					<div className='encounter-sections'>
						<div
							className='encounter-section'
							ref={(section) => {
								sectionRefs.current['requested_test_order'] =
									section;
							}}
						>
							<div
								className={classnames(
									'encounter-accordian-header',
									{
										'encounter-accordian-header-expanded':
											isExpanded('TestOrderSegment'),
									}
								)}
								style={{
									opacity: isPreAppt ? '0.5' : '1',
									cursor: isPreAppt
										? 'not-allowed'
										: isInEditMode('TestOrderSegment')
										? 'auto'
										: 'pointer',
								}}
								onClick={() =>
									isPreAppt
										? null
										: toggleSegment('TestOrderSegment')
								}
							>
								<span className='accordian-title'>
									{!isAutomatedPreTest &&
										!isPartnerInitiatedPreTest &&
										!isPartnerInitiatedUnsolicitedResult(
											encounter
										) &&
										'REQUESTED'}{' '}
									TEST ORDER
								</span>
								{!isInEditMode('TestOrderSegment') && (
									<Fragment>
										{/* {isExpanded('TestOrderSegment') && !isAnyEditActive() &&
                                    <RBAC
                                        action={CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER}
                                        yes={(<span><img src={editpencil} alt="edit-icon" onClick={makeEdited('TestOrderSegment')} /></span>)}
                                    />
                                } */}
										<span className='accordian-icon'>
											<img
												src={imageExpandCollapse}
												style={{
													transform: isExpanded(
														'TestOrderSegment'
													)
														? 'rotate(-90deg)'
														: 'rotate(90deg)',
													float: 'right',
												}}
												alt='expand-icon'
											/>
										</span>
									</Fragment>
								)}
							</div>
							<div
								className='scp-encounter-accordian-body'
								style={{
									display: isExpanded('TestOrderSegment')
										? 'block'
										: 'none',
								}}
							>
								{isInEditMode('TestOrderSegment') ? (
									<RBAC
										action={
											CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
										}
										yes={
											<EditTestOrderSegment
												{...testOrdersProps()}
												saveSCPEncounter={
													saveSCPEncounter
												}
												LoadTestEnums={LoadTestEnums}
												nonlegacytestnameenums={
													nonlegacytestnameenums
												}
												cancel={() =>
													makeNonEdited(
														'TestOrderSegment'
													)
												}
												getICDCodes={getICDCodes}
												icdCodes={icdCodes}
												icdCodesLoading={
													icdCodesLoading
												}
												icdCodesError={icdCodesError}
												icdCodesPlacement={
													icdCodesPlacement
												}
												encounter={encounter}
												patientdetail={patientdetail}
											/>
										}
									/>
								) : (
									<TestOrderSegment
										{...testOrdersProps()}
										getICDCodes={getICDCodes}
										icdCodes={icdCodes}
										icdCodesLoading={icdCodesLoading}
										icdCodesError={icdCodesError}
										icdCodesPlacement={icdCodesPlacement}
										encounter={encounter}
										patientdetail={patientdetail}
										navigateAndExpand={navigateAndExpand}
										saveSCPEncounter={saveSCPEncounter}
										updateTestOrder={handleUpdateTestOrder}
										mloOrderStatusEnums={
											MLOOrderStatusEnums
										}
										encounterTestOrderRequest={
											encounterTestOrderRequest
										}
										LoadTestOrderPopupTestEnum={
											LoadTestOrderPopupTestEnum
										}
										nonlegacytestOrderPopupTestEnums={
											nonlegacytestOrderPopupTestEnums
										}
										gotoMLOTestDetail={gotoMLOTestDetail}
										user={user}
										callTestOrderNotes={callTestOrderNotes}
										testOrderNotes={testOrderNotes}
										activeRole={activeRole}
										createTestRequestFlags={
											createTestRequestFlags
										}
										updateTestRequestFlags={
											updateTestRequestFlags
										}
										isPreAppt={isPreAppt}
										creatingTestOrderFlags={
											creatingTestOrderFlags
										}
										updatingTestOrderFlags={
											updatingTestOrderFlags
										}
										encounterTestOrderRequestLoading={
											encounterTestOrderRequestLoading
										}
										callTestOrderRequest={
											callTestOrderRequest
										}
										isPartnerInitiatedPreTest={
											isPartnerInitiatedPreTest
										}
										getOutreachsLog={getOutreachsLog}
									/>
								)}
							</div>
						</div>

						<div
							className='encounter-section'
							ref={(section) => {
								sectionRefs.current['patient_clinical_info'] =
									section;
							}}
						>
							<div
								className={classnames(
									'encounter-accordian-header',
									{
										'encounter-accordian-header-expanded':
											isExpanded('PatientClinicalInfo'),
									}
								)}
								style={{
									cursor: isInEditMode('PatientClinicalInfo')
										? 'auto'
										: 'pointer',
								}}
								onClick={() =>
									toggleSegment('PatientClinicalInfo')
								}
							>
								<span className='accordian-title'>
									PERTINENT CLINICAL INFO
								</span>
								{!isInEditMode('PatientClinicalInfo') && (
									<Fragment>
										{/* {isExpanded('PatientClinicalInfo') && !isAnyEditActive() &&
                                    <RBAC
                                        action={CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER}
                                        yes={(
                                            <span><img src={editpencil} alt="edit-icon" onClick={makeEdited('PatientClinicalInfo')} /></span>
                                        )} />
                                } */}

										<span className='accordian-icon'>
											<img
												src={imageExpandCollapse}
												style={{
													transform: isExpanded(
														'PatientClinicalInfo'
													)
														? 'rotate(-90deg)'
														: 'rotate(90deg)',
													float: 'right',
												}}
												alt='expand-icon'
											/>
										</span>
									</Fragment>
								)}
							</div>
							<div
								className='encounter-accordian-body'
								style={{
									display: isExpanded('PatientClinicalInfo')
										? 'block'
										: 'none',
								}}
							>
								{isInEditMode('PatientClinicalInfo') ? (
									<RBAC
										action={
											CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
										}
										yes={
											<EditPatientClinicalInfo
												{...additionalInfoProps()}
												saveSCPEncounter={
													saveSCPEncounter
												}
												cancel={() =>
													makeNonEdited(
														'PatientClinicalInfo'
													)
												}
											/>
										}
									/>
								) : (
									<PatientClinicalInfo
										{...testOrdersProps()}
										showAboutSection={!isPreAppt}
										scpClinicalInfo={scpClinicalInfo}
									/>
								)}
							</div>
						</div>

						{isPartnerInitiatedUnsolicitedResult(encounter) &&
							encounterDetailsSection()}

						{isPartnerInitiatedUnsolicitedResult(encounter) && (
							<div
								className='encounter-section'
								ref={(section) => {
									sectionRefs.current['pgx_info'] = section;
								}}
							>
								<div
									className={classnames(
										'encounter-accordian-header',
										{
											'encounter-accordian-header-expanded':
												isExpanded('PxInfoSegment'),
										}
									)}
									style={{
										cursor: isInEditMode('PxInfoSegment')
											? 'auto'
											: 'pointer',
									}}
									onClick={() =>
										toggleSegment('PxInfoSegment')
									}
								>
									<span className='accordian-title'>
										PGX INFORMATION
									</span>
									{!isInEditMode('PxInfoSegment') && (
										<Fragment>
											{isExpanded('PxInfoSegment') &&
												!isAnyEditActive() && (
													<RBAC
														action={
															CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
														}
														yes={
															<span>
																<img
																	src={
																		editpencil
																	}
																	alt='edit-icon'
																	onClick={makeEdited(
																		'PxInfoSegment'
																	)}
																/>
															</span>
														}
													/>
												)}
											<span className='accordian-icon'>
												<img
													src={imageExpandCollapse}
													style={{
														transform: isExpanded(
															'PxInfoSegment'
														)
															? 'rotate(-90deg)'
															: 'rotate(90deg)',
														float: 'right',
													}}
													alt='expand-icon'
												/>
											</span>
										</Fragment>
									)}
								</div>
								<div
									className='encounter-accordian-body'
									style={{
										display: isExpanded('PxInfoSegment')
											? 'block'
											: 'none',
									}}
								>
									{isInEditMode('PxInfoSegment') ? (
										<RBAC
											action={
												CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
											}
											yes={
												<EditPGXSegment
													{...pgxProps()}
													saveSCPEncounter={
														saveSCPEncounter
													}
													cancel={() =>
														makeNonEdited(
															'PxInfoSegment'
														)
													}
												/>
											}
										/>
									) : (
										<PGXSegment {...pgxProps()} />
									)}
								</div>
							</div>
						)}

						<div className='encounter-section'>
							<div
								className={classnames(
									'encounter-accordian-header',
									{
										'encounter-accordian-header-expanded':
											isExpanded('ExtProvidersSegment'),
									}
								)}
								style={{
									cursor: isInEditMode('ExtProvidersSegment')
										? 'auto'
										: 'pointer',
								}}
								onClick={() =>
									toggleSegment('ExtProvidersSegment')
								}
							>
								<span className='accordian-title'>
									EXTERNAL PROVIDERS
								</span>
								{!isInEditMode('ExtProvidersSegment') && (
									<Fragment>
										<span className='accordian-icon'>
											<img
												src={imageExpandCollapse}
												style={{
													transform: isExpanded(
														'ExtProvidersSegment'
													)
														? 'rotate(-90deg)'
														: 'rotate(90deg)',
													float: 'right',
												}}
												alt='expand-icon'
											/>
										</span>
									</Fragment>
								)}
							</div>
							<div
								className='encounter-accordian-body'
								style={{
									display: isExpanded('ExtProvidersSegment')
										? 'block'
										: 'none',
								}}
							>
								<Loading
									className='ext-providers-wrapper'
									loading={_.get(
										externalProviderProps,
										'loading',
										false
									)}
								>
									<div className='ext-providers'>
										{_.get(
											externalProviderProps,
											['externalProviders'],
											[]
										).map(
											(el) =>
												el && (
													<ProviderLinked
														linkedType={'encounter'}
														provider={el}
														loading={_.get(
															externalProviderProps,
															'loading',
															false
														)}
														editLinkInfo={_.get(
															externalProviderProps,
															'editLinkInfo'
														)}
														editProviderInfo={_.get(
															externalProviderProps,
															'editProviderInfo'
														)}
														unlinkProviderInfo={_.get(
															externalProviderProps,
															'unlinkProviderInfo'
														)}
														linkProviderToEncounter={_.get(
															externalProviderProps,
															'linkProviderToEncounter'
														)}
														providerSpecialties={
															_.get(
																externalProviderProps,
																'providerSpecialties',
																null
															) || []
														}
														activeRole={activeRole}
													/>
												)
										)}
									</div>
									{!_.isEmpty(
										_.get(
											externalProviderProps,
											'externalProviders',
											[]
										)
									) ? (
										<RBAC
											action={
												CARE_PATIENTS_PATIENT_ENCOUNTER_PROVIDER_OPERATIONS
											}
											yes={
												<ProviderAddAnother
													click={_.get(
														externalProviderProps,
														'handleProviderLinkDialog'
													)}
													title='Link Another Provider'
												/>
											}
										/>
									) : (
										<RBAC
											action={
												CARE_PATIENTS_PATIENT_ENCOUNTER_PROVIDER_OPERATIONS
											}
											yes={
												<ProviderAddAnother
													title='Link External Provider'
													click={_.get(
														externalProviderProps,
														'handleProviderLinkDialog'
													)}
												/>
											}
										/>
									)}
								</Loading>
							</div>
						</div>

						<div
							className='encounter-section'
							ref={(section) => {
								sectionRefs.current['content_reviewed'] =
									section;
							}}
						>
							<div
								className={classnames(
									'encounter-accordian-header',
									{
										'encounter-accordian-header-expanded':
											isExpanded(
												'ContentReviewedByPatient'
											),
									}
								)}
								style={{
									cursor: isInEditMode(
										'ContentReviewedByPatient'
									)
										? 'auto'
										: 'pointer',
								}}
								onClick={() =>
									toggleSegment('ContentReviewedByPatient')
								}
							>
								<span className='accordian-title'>
									CONTENT REVIEWED BY PATIENT
								</span>
								{!isInEditMode('ContentReviewedByPatient') && (
									<Fragment>
										<span className='accordian-icon'>
											<img
												src={imageExpandCollapse}
												style={{
													transform: isExpanded(
														'ContentReviewedByPatient'
													)
														? 'rotate(-90deg)'
														: 'rotate(90deg)',
													float: 'right',
												}}
												alt='expand-icon'
											/>
										</span>
									</Fragment>
								)}
							</div>
							<div
								className='encounter-accordian-body'
								style={{
									display: isExpanded(
										'ContentReviewedByPatient'
									)
										? 'block'
										: 'none',
									paddingRight: '10px',
								}}
							>
								<ContentReviewedByPatient
									getAllContentViewedByPatient={
										getAllContentViewedByPatient
									}
									contentViewedByPatientLoading={
										contentViewedByPatientLoading
									}
									contentViewedByPatient={
										contentViewedByPatient || []
									}
									encounterUuid={_.get(
										encounter,
										'uuid',
										null
									)}
								/>
							</div>
						</div>

						{!isPartnerInitiatedUnsolicitedResult(encounter) &&
							encounterDetailsSection()}

						<div
							className='encounter-section'
							ref={(section) => {
								sectionRefs.current['ror_follow_up'] = section;
							}}
						>
							<div
								className={classnames(
									'encounter-accordian-header',
									{
										'encounter-accordian-header-expanded':
											isExpanded('RORSegment'),
									}
								)}
								style={{ cursor: 'pointer' }}
								onClick={() => toggleSegment('RORSegment')}
							>
								<span className='accordian-title'>
									RETURN OF RESULTS (RoR) FOLLOW-UP
								</span>
								<span className='accordian-icon'>
									<img
										src={imageExpandCollapse}
										style={{
											transform: isExpanded('RORSegment')
												? 'rotate(-90deg)'
												: 'rotate(90deg)',
											float: 'right',
										}}
										alt='expand-icon'
									/>
								</span>
							</div>
							<div
								className='encounter-accordian-body'
								style={{
									display: isExpanded('RORSegment')
										? 'block'
										: 'none',
								}}
							>
								<RORSegment {...rorProps()} />
							</div>
						</div>

						<RBAC
							action={CARE_PATIENTS_PATIENT_ENCOUNTER_BILLING}
							yes={
								<div
									className='encounter-section'
									ref={(section) => {
										sectionRefs.current['billing'] =
											section;
									}}
								>
									<div
										className={classnames(
											'encounter-accordian-header',
											{
												'encounter-accordian-header-expanded':
													isExpanded(
														'BillingSegment'
													),
											}
										)}
										style={{
											cursor: isInEditMode(
												'BillingSegment'
											)
												? 'auto'
												: 'pointer',
										}}
										onClick={() =>
											toggleSegment('BillingSegment')
										}
									>
										<span className='accordian-title'>
											BILLING
										</span>
										{!isInEditMode('BillingSegment') && (
											<Fragment>
												{isExpanded('BillingSegment') &&
													!isAnyEditActive() && (
														<RBAC
															action={
																CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
															}
															yes={
																<span>
																	<img
																		src={
																			editpencil
																		}
																		alt='edit-icon'
																		onClick={makeEdited(
																			'BillingSegment'
																		)}
																	/>
																</span>
															}
														/>
													)}
												<span className='accordian-icon'>
													<img
														src={
															imageExpandCollapse
														}
														style={{
															transform:
																isExpanded(
																	'BillingSegment'
																)
																	? 'rotate(-90deg)'
																	: 'rotate(90deg)',
															float: 'right',
														}}
														alt='expand-icon'
													/>
												</span>
											</Fragment>
										)}
									</div>
									<div
										className='encounter-accordian-body'
										style={{
											display: isExpanded(
												'BillingSegment'
											)
												? 'block'
												: 'none',
										}}
									>
										{isInEditMode('BillingSegment') ? (
											<RBAC
												action={
													CARE_PATIENTS_PATIENT_EDIT_ENCOUNTER
												}
												yes={
													<EditBillingSegmnet
														{...billingProps()}
														saveSCPEncounter={
															saveSCPEncounter
														}
														saveSCPBillingEncounter={
															saveSCPBillingEncounter
														}
														cancel={() =>
															makeNonEdited(
																'BillingSegment'
															)
														}
													/>
												}
											/>
										) : (
											<BillingSegment
												{...billingProps()}
												loading={billingViewLoading}
											/>
										)}
									</div>
								</div>
							}
						/>

						<RBAC
							action={
								CARE_PATIENTS_PATIENT_ENCOUNTER_ADDITIONAL_INFO
							}
							yes={
								<div
									className='encounter-section'
									ref={(section) => {
										sectionRefs.current['additional_info'] =
											section;
									}}
								>
									<div
										className={classnames(
											'encounter-accordian-header',
											{
												'encounter-accordian-header-expanded':
													isExpanded(
														'AdditionalSegment'
													),
											}
										)}
										style={{
											cursor: isInEditMode(
												'AdditionalSegment'
											)
												? 'auto'
												: 'pointer',
										}}
										onClick={() =>
											toggleSegment('AdditionalSegment')
										}
									>
										<span className='accordian-title'>
											ADDITIONAL INFO
										</span>
										{!isInEditMode('AdditionalSegment') && (
											<Fragment>
												<span className='accordian-icon'>
													<img
														src={
															imageExpandCollapse
														}
														style={{
															transform:
																isExpanded(
																	'AdditionalSegment'
																)
																	? 'rotate(-90deg)'
																	: 'rotate(90deg)',
															float: 'right',
														}}
														alt='expand-icon'
													/>
												</span>
											</Fragment>
										)}
									</div>
									<div
										className='encounter-accordian-body'
										style={{
											display: isExpanded(
												'AdditionalSegment'
											)
												? 'block'
												: 'none',
										}}
									>
										{isInEditMode('AdditionalSegment') ? (
											<RBAC
												action={
													CARE_PATIENTS_ENCOUNTER_ADDINFOSEC_EDIT
												}
												yes={
													<EditAdditionalSegment
														{...additionalInfoProps()}
														saveSCPEncounter={
															saveSCPEncounter
														}
														cancel={() =>
															makeNonEdited(
																'AdditionalSegment'
															)
														}
													/>
												}
											/>
										) : (
											<AdditionalSegment
												{...additionalInfoProps()}
											/>
										)}
									</div>
								</div>
							}
						/>
					</div>
				</Fragment>
			}
		</Fragment>
	);
};

export default SCPEncounterDetail;
