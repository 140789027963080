//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';
import find from 'lodash/find';

//Utils
import { determineWidthOverflow, addressTypes } from './../../utils.js';

//Images
import ReactSVG from 'react-svg';
import locationIcon from './../../assets/location.svg';

//Components
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from './Icon.js';
import ThreeDotsMenu from './controls/ThreeDotsMenu.js';
import RadioButtonGroup from './controls/RadioButtonGroup.js';

//Styles
import './AddressCard.css';

class AddressCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasFunc: false,
		};
		//bindings
		this.determineHasActionFunc = this.determineHasActionFunc.bind(this);
		this.getVariantClass = this.getVariantClass.bind(this);
		this.getActionClass = this.getActionClass.bind(this);
		this.getVariantAddressClass = this.getVariantAddressClass.bind(this);
		this.getVariantIconClass = this.getVariantIconClass.bind(this);
		this.getVariantAddressTypesClass =
			this.getVariantAddressTypesClass.bind(this);
		this.getDisplayAddress = this.getDisplayAddress.bind(this);
		this.displayAddressTypes = this.displayAddressTypes.bind(this);
		this.getMainAddress = this.getMainAddress.bind(this);
		this.getCityStateZip = this.getCityStateZip.bind(this);
		this.getAddressCopyStr = this.getAddressCopyStr.bind(this);
		this.textCopied = this.textCopied.bind(this);
		this.renderIcon = this.renderIcon.bind(this);
		this.renderSelection = this.renderSelection.bind(this);
	}
	//functions

	async componentDidMount() {
		this.determineHasActionFunc();
	}

	determineHasActionFunc() {
		const actionFunc = get(this, 'props.actionFunc', null);
		const hasFunc = actionFunc && typeof actionFunc === 'function';
		this.setState({ hasFunc });
	}

	getVariantClass() {
		const variant = get(this, 'props.variant', null);
		switch (variant) {
			case 'main':
				return 'addressCardMainVariant';
			case 'sidebar':
				return 'addressCardSidebarVariant';
			default:
				console.log('Unrecognized address card variant.');
				return '';
		}
	}

	getActionClass() {
		const action = get(this, 'props.action', null);
		const isSelected = get(this, 'props.isSelected', false);
		const selectionClass = isSelected ? ' addressCardSelected' : '';
		switch (action) {
			case 'select':
				return 'addressCardActionable' + selectionClass;
			case 'edit':
				return 'addressCardActionable';
			default:
				return '';
		}
	}

	getVariantAddressClass() {
		const variant = get(this, 'props.variant', null);
		switch (variant) {
			case 'main':
				return 'addressCardAddressMainVariant';
			case 'sidebar':
				return 'addressCardAddressSidebarVariant';
			default:
				console.log('Unrecognized address card variant.');
				return '';
		}
	}

	getVariantIconClass() {
		const variant = get(this, 'props.variant', null);
		switch (variant) {
			case 'main':
				return 'addressCardMainVariantIcon';
			case 'sidebar':
				return 'addressCardSidebarVariantIcon';
			default:
				console.log('Unrecognized address card variant.');
				return '';
		}
	}

	getVariantAddressTypesClass() {
		const variant = get(this, 'props.variant', null);
		switch (variant) {
			case 'main':
				return 'addressCardAddressTypesMainVariant';
			case 'sidebar':
				return 'addressCardAddressTypesSidebarVariant';
			default:
				console.log('Unrecognized address card variant.');
				return '';
		}
	}

	getDisplayAddress(address) {
		if (address && typeof address === 'string') {
			return (
				get(
					find(
						addressTypes,
						(addressType) =>
							address === get(addressType, 'key', null)
					),
					'display_name',
					null
				) || address
			);
		} else return '--';
	}

	displayAddressTypes() {
		const addressTypes = get(this, 'props.address.type', null) || [];
		if (Array.isArray(addressTypes)) {
			return addressTypes
				.map((address) => this.getDisplayAddress(address))
				.join(', ');
		} else return '--';
	}

	getMainAddress() {
		const variant = get(this, 'props.variant', null);
		const address = get(this, 'props.address', null) || {};
		const addressSecondLine = get(address, 'address_line2', null);
		if (variant === 'sidebar') {
			return `${get(address, 'address_line1', null) || '--'}${
				addressSecondLine ? '\n' + addressSecondLine : ''
			}`;
		} else {
			return `${get(address, 'address_line1', null) || '--'}${
				addressSecondLine ? ', ' + addressSecondLine : ''
			}`;
		}
	}

	getCityStateZip() {
		const address = get(this, 'props.address', null) || {};
		return `${get(address, 'city', null) || '--'}, ${
			get(address, 'state', null) || '--'
		} ${get(address, 'zip', null) || '--'}`;
	}

	getAddressCopyStr() {
		const address = get(this, 'props.address', null) || {};
		const addressString =
			get(address, 'address_line1', '') +
			', ' +
			(get(address, 'address_line2.length', 0) > 0
				? get(address, 'address_line2', ' ') + ', '
				: ' ') +
			get(address, 'city', '') +
			(get(address, 'city.length', 0) > 0 ? ', ' : ' ') +
			get(address, 'state', '') +
			' ' +
			get(address, 'zip', '');
		return addressString;
	}

	textCopied() {
		if (typeof get(this, 'props.enqueueSnackbar', null) == 'function') {
			this.props.enqueueSnackbar('Address copied', {
				variant: 'success',
				className: 'export_notification',
				anchorOrigin: { horizontal: 'center', vertical: 'top' },
			});
		}
	}

	renderIcon() {
		const iconClass = `addressCardLocationIcon ${this.getVariantIconClass()}`;
		return <ReactSVG src={locationIcon} className={iconClass} />;
	}

	renderSelection() {
		return (
			<RadioButtonGroup
				data={[
					{
						isBlank: true,
						isSelected: get(this, 'props.isSelected', null),
					},
				]}
				customStyling={{
					position: 'relative',
					bottom: '5px',
					right: '11px',
				}}
			/>
		);
	}

	render() {
		const mainClass = `addressCardContainer ${this.getVariantClass()} ${this.getActionClass()}`;
		const errors = get(this, 'props.addressErrors', null);
		const action = get(this, 'props.action', null);
		const errorClass = errors ? ' addressCardActionableError' : '';
		const iconContainerClass =
			action === 'select'
				? 'addressCardIconSelection addressCardRadioSelection'
				: 'addressCardIconSelection';
		const addressClass = `addressCardAddress ${this.getVariantAddressClass()}`;
		const iconClass = `addressCardLocationIcon ${this.getVariantIconClass()}`;
		const addressTypesClass = `addressCardAddressTypes ${this.getVariantAddressTypesClass()}`;
		const hasFunc = get(this, 'state.hasFunc', null);
		const actionFunc = get(this, 'props.actionFunc', null);
		const mainAddress = this.getMainAddress();
		const cityStateZip = this.getCityStateZip();
		const isMainVariant = get(this, 'props.variant', null) === 'main';
		const isSidebarVariant = get(this, 'props.variant', null) === 'sidebar';
		const mainAddressClass = isSidebarVariant
			? 'addressCardAddressSidebarVariantMainAddress'
			: '';
		const threeDotMenu = get(this, 'props.threeDotMenu', false);
		const threeDotMenuOptions =
			get(this, 'props.threeDotMenuOptions', null) || [];
		return (
			<div
				className={mainClass + errorClass}
				onClick={hasFunc ? actionFunc : () => {}}
			>
				<div className='addressCardDataContainer'>
					<div className='addressCardIconAndAddressContainer'>
						<div className={iconContainerClass}>
							{action === 'select'
								? this.renderSelection()
								: this.renderIcon()}
						</div>
						<div className={addressClass}>
							<div className={mainAddressClass}>
								{mainAddress}
							</div>
							<div>{cityStateZip}</div>
							{isSidebarVariant && (
								<div className={addressTypesClass}>
									{this.displayAddressTypes()}
								</div>
							)}
						</div>
					</div>
					<div className='addressCardRightHalf'>
						{isMainVariant ? (
							<div className={addressTypesClass}>
								{this.displayAddressTypes()}
							</div>
						) : (
							<CopyToClipboard
								text={this.getAddressCopyStr()}
								onCopy={this.textCopied}
							>
								<Icon
									className='LinkIcon CopyLinkIcon addressCardCopyIcon'
									icon='copy'
								/>
							</CopyToClipboard>
						)}
						{threeDotMenu && (
							<ThreeDotsMenu options={threeDotMenuOptions} />
						)}
					</div>
				</div>
				{errors && (
					<div className='addressCardErrorText'>
						Address entered is invalid. Please correct it to
						proceed.
					</div>
				)}
			</div>
		);
	}
}

export default AddressCard;
