import _ from 'lodash';

export const dehydrateInsuranceCompanyInfo = (data) => {
	return {
		athena_insurance_package_id: data && data.athenaPackageID,
		athena_insurance_package_name: data && data.athenaPackageName,
		athena_insurance_package_phone: data && data.phone,
		consultation_price: data && data.consultationPrice,
		expected_revenue: data && data.expectedRevenue,
		name: data && data.insuranceCompanyName,
		athena_insurance_package_address: data && data.address,
		athena_insurance_package_city: data && data.city,
		athena_insurance_package_state: data && data.state,
		athena_insurance_package_zip: data && data.zip,
		insurance_prepay_exceptions:
			(data && data.insurance_prepay_exceptions) || [],
		no_supervising_billing: data.noSupervisingBilling,
		use_gt_modifier: data.useGTModifier,
		is_auth_required: data.isAuthRequired,
		md_required: data.md_required,
		athena_facility_id: data.athena_facility_id,
	};
};

export const hydrateInsuranceCompanies = (data) => {
	const dataObj = (data && data.data) || {};

	dataObj.data = dataObj.data.map((d) => hydrateInsuranceCompany(d)) || [];

	return dataObj;
};

export const hydrateInsuranceCompany = (data) => {
	let address = '';

	if (!_.isNil(_.get(data, 'athena_insurance_package_address', null))) {
		address += _.get(data, 'athena_insurance_package_address', '');
	}
	if (!_.isNil(_.get(data, 'athena_insurance_package_city', null))) {
		address =
			address + ` ${_.get(data, 'athena_insurance_package_city', '')},`;
	}
	if (!_.isNil(_.get(data, 'athena_insurance_package_state', null))) {
		address =
			address + ` ${_.get(data, 'athena_insurance_package_state', '')}`;
	}
	if (!_.isNil(_.get(data, 'athena_insurance_package_zip', null))) {
		address =
			address + ` ${_.get(data, 'athena_insurance_package_zip', '')}`;
	}

	return (
		(data && {
			companyID: _.get(data, 'id', ''),
			insuranceCompanyName: _.get(data, 'name', ''),
			athenaPackageName: _.get(data, 'athena_insurance_package_name', ''),
			athenaPackageID: _.get(data, 'athena_insurance_package_id', ''),
			athenaFullAddress: address,
			address: _.get(data, 'athena_insurance_package_address', ''),
			city: _.get(data, 'athena_insurance_package_city', ''),
			state: _.get(data, 'athena_insurance_package_state', ''),
			zip: _.get(data, 'athena_insurance_package_zip', ''),
			phone: _.get(data, 'athena_insurance_package_phone', ''),
			consultationPrice: _.get(data, 'consultation_price', ''),
			expectedRevenue: _.get(data, 'expected_revenue', ''),
			insurance_prepay_exceptions:
				_.get(data, 'insurance_prepay_exceptions', []) || [],
			noSupervisingBilling: _.get(data, 'no_supervising_billing', false),
			useGTModifier: _.get(data, 'use_gt_modifier', false),
			isAuthRequired: _.get(data, 'is_auth_required', false),
			md_required: _.get(data, 'md_required', false),
			athena_facility_id: _.get(data, 'athena_facility_id', ''),
		}) ||
		{}
	);
};

export const fieldsMapping = {
	athena_insurance_package_id: 'athenaPackageID',
	athena_insurance_package_name: 'athenaPackageName',
	athena_insurance_package_phone: 'phone',
	consultation_price: 'consultationPrice',
	expected_revenue: 'expectedRevenue',
	name: 'insuranceCompanyName',
	athena_insurance_package_address: 'address',
	athena_insurance_package_city: 'city',
	athena_insurance_package_state: 'state',
	athena_insurance_package_zip: 'zip',
	no_supervising_billing: 'noSupervisingBilling',
	use_gt_modifier: 'useGTModifier',
	is_auth_required: 'isAuthRequired',
};
