//React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';

//Images
import demogif from './SearchDemo.gif';

//Utils
import { allowed_search_categories_display } from '../../../Utils/searchUtils';

//UI Libraries
import { Dialog, Button } from 'gm-element-react';

//Styles
import './style.css';
import alertIcon from '../../../assets/advanced_search_error.svg';

const InvalidCategoryError = (props) => {
	const [dialogVisible, setDialogVisible] = useState(false);

	const { searchValue } = props;

	return (
		<div className='col-md-12' style={{ marginTop: '1vw' }}>
			<div
				className='col-md-12 errvaluediv'
				style={{ display: searchValue.length == 0 ? 'none' : '' }}
			>
				<ReactSVG
					className='float-left'
					src={alertIcon}
					style={{ height: 20, width: 20 }}
				/>
				<div className='textdiv'>
					Your Search -{' '}
					<span className='valuespan'>
						{' '}
						&ldquo;{searchValue}&rdquo;{' '}
					</span>{' '}
					- does not match any categories
				</div>
			</div>
			<div
				className='suggestions_label'
				style={{ height: searchValue.length == 0 ? '1vw' : '4vw' }}
			>
				<div style={{ float: 'left' }}>Suggestions: </div>
				<div className='help_gif' style={{ float: 'right' }}>
					<Button
						className='suggestions_label help_button'
						type='text'
						onClick={() => setDialogVisible(true)}
					>
						Need help to get started?{' '}
					</Button>
				</div>
			</div>
			<div className='category_suggestions'>
				<div className='category_suggestions'>
					{allowed_search_categories_display.map((e) => {
						return <li key={e}> {e} </li>;
					})}
				</div>
			</div>
			<Dialog
				title=''
				size='large'
				visible={dialogVisible}
				onCancel={() => setDialogVisible(false)}
				lockScroll={false}
				className='searchHelpDialog'
			>
				{
					<Dialog.Body>
						<div>
							<img
								style={{ width: '100%' }}
								src={demogif}
								alt='hello'
							/>
						</div>
					</Dialog.Body>
				}
			</Dialog>
		</div>
	);
};
InvalidCategoryError.propTypes = {
	searchValue: PropTypes.string.isRequired,
};

export default InvalidCategoryError;
