import { filter, get, isEmpty } from 'lodash';

export const keys_map = {
	pending_sample_collection: 'Pending Sample Collection',
	ready_to_order: 'Ready to Order',
	ready_to_schedule_results_review: 'Ready to Schedule Results Review',
	event_note: 'For noting lab orders',
	sentiment_sat: 'for satification survey?',
	monetization_for: 'Billing',
};
export const stages = [
	'ready_to_order',
	'pending_sample_collection',
	'ready_to_schedule_results_review',
	'event_note',
	'sentiment_sat',
	'monetization_for',
	'ready_to_schedule_results_review',
	'event_note',
	'sentiment_sat',
	'monetization_for',
];

export const dashboard_key_title = {
	questionnaire_status: 'Visit questionnaire pending',
	appointment_status: 'Visit completion status unknown',
	order_pending_lab_submission: 'Orders pending lab submission',
	patient_followup_scheduled: 'Return of Results visit pending',
	insurance_claim_filed: 'Insurance claim not filed',
	pending_sample_collection: 'Pending Sample Collection',
	ready_to_order: 'Ready to Order',
	ready_to_schedule_results_review: 'Ready to schedule results review',
	ror_outreach_pending: 'RoR Outreach Pending',
	cap_not_sent: 'CAP not sent (visit)',
	all_cdt: 'Results CAP Not Sent',
	non_cdt_caps_ready_to_send_cc_intake_encounters: 'Results CAP Not Sent',
	non_cdt_caps_ready_to_send_group_session_encounters: 'Results CAP Not Sent',
	non_cdt_caps_ready_to_send_visit_encounters: 'Results CAP Not Sent',
	cdt_caps_ready_to_send: 'Results CAP Not Sent',
	results_cap_not_sent: 'Results CAP Not Sent',
	non_cdt_caps_ready_to_send_scp_encounters: 'Results CAP Not Sent',
	appointment_alerts: 'Appointment Alerts',
	initial_visit_summary_ready: 'Initial Visit Summary Ready',
	result_visit_summary_ready: 'Result Visit Summary Ready',
	order_request_status_unknown: 'Order Request status unknown',
};
export const dashboard_keys = [
	//'questionnaire_status',
	'appointment_status',
	'order_request_status_unknown',
	'order_pending_lab_submission',
	'ror_outreach_pending',
	'cap_not_sent',
	'cdt_caps_ready_to_send',
	'insurance_claim_filed',
];

export const key_img_map = {
	patient_followup_scheduled: 'calendar_today',
	appointment_status: 'update',
	insurance_claim_filed: 'receipt',
	questionnaire_status: 'event_note',
	billing_checkout_pending: 'assignment',
	ready_to_schedule_results_review: 'event',
	ready_to_order: 'event',
	ror_outreach_pending: 'calendar_today',
	cap_not_sent: '',
	lab_results_not_reported: '',
	order_pending_lab_submission: 'description',
	cdt_caps_ready_to_send: '',
	order_request_status_unknown: 'assignment',
};
export const key_color_map = {
	pending_sample_collection: {
		background: 'red',
		shadow: 'yellow',
	},
	patient_followup_scheduled: {
		background: `linear-gradient(60deg, #ffa726, #fb8c00)`,
		shadow: '0 0.87vw 1.46vw -0.7vw rgba(255, 152, 0, 0.28), 0 0.29vw 1.46vw 0 rgba(0, 0, 0, 0.12), 0 0.51vw 0.58vw -0.36vw rgba(255, 152, 0, 0.2)',
	},
	appointment_status: {
		background: '#0cc3b2',
		shadow: ' 0 0.87vw 1.46vw -0.7vw   rgba(12, 195, 178, 0.28),   0 0.29vw 1.46vw 0   rgba(0, 0, 0, 0.12),   0 0.51vw 0.58vw -0.36vw    rgba(12, 195, 178, 0.2)',
	},
	insurance_claim_filed: {
		background: 'linear-gradient(60deg, #66bb6a, #43a047)',
		shadow: '0 0.87vw 1.46vw -0.7vw   rgba(76, 175, 80, 0.28), 0 0.29vw 1.46vw 0   rgba(0, 0, 0, 0.12), 0 0.51vw 0.58vw -0.36vw   rgba(76, 175, 80, 0.2)',
	},
	questionnaire_status: {
		background: '#0cc3b2',
		shadow: ' 0 0.87vw 1.46vw -0.7vw   rgba(12, 195, 178, 0.28),   0 0.29vw 1.46vw 0   rgba(0, 0, 0, 0.12),   0 0.51vw 0.58vw -0.36vw    rgba(12, 195, 178, 0.2)',
	},
	billing_checkout_pending: {
		background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
		shadow: ' 0 0.87vw 1.46vw -0.7vw   rgba(156, 39, 176, 0.28),   0 0.29vw 1.46vw 0   rgba(0, 0, 0, 0.12),0 0.51vw 0.58vw -0.36vw rgba(156, 39, 176, 0.2)',
	},
	ready_to_schedule_results_review: {
		background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
		shadow: ' 0 0.87vw 1.46vw -0.7vw   rgba(156, 39, 176, 0.28),   0 0.29vw 1.46vw 0   rgba(0, 0, 0, 0.12),0 0.51vw 0.58vw -0.36vw rgba(156, 39, 176, 0.2)',
	},
	ready_to_order: {
		background: 'linear-gradient(60deg, #26c6da, #00acc1)',
		shadow: '0 0.87vw 1.46vw -0.7vw   rgba(0, 188, 212, 0.28),   0 0.29vw 1.46vw 0    rgba(0, 0, 0, 0.12),  0 0.51vw 0.58vw -0.36vw   rgba(0, 188, 212, 0.2)',
	},
	ror_outreach_pending: {
		background: `linear-gradient(60deg, #ffa726, #fb8c00)`,
		shadow: '0 0.87vw 1.46vw -0.7vw rgba(255, 152, 0, 0.28), 0 0.29vw 1.46vw 0 rgba(0, 0, 0, 0.12), 0 0.51vw 0.58vw -0.36vw rgba(255, 152, 0, 0.2)',
	},

	order_request_status_unknown: {
		background: `linear-gradient(60deg, #ffa726, #fb8c00)`,
		shadow: '0 0.87vw 1.46vw -0.7vw rgba(255, 152, 0, 0.28), 0 0.29vw 1.46vw 0 rgba(0, 0, 0, 0.12), 0 0.51vw 0.58vw -0.36vw rgba(255, 152, 0, 0.2)',
	},
	cap_not_sent: {
		background: `linear-gradient(60deg, #ffa726, #fb8c00)`,
		shadow: '0 0.87vw 1.46vw -0.7vw rgba(255, 152, 0, 0.28), 0 0.29vw 1.46vw 0 rgba(0, 0, 0, 0.12), 0 0.51vw 0.58vw -0.36vw rgba(255, 152, 0, 0.2)',
	},
	cdt_caps_ready_to_send: {
		background: `linear-gradient(60deg, #ffa726, #fb8c00)`,
		shadow: '0 0.87vw 1.46vw -0.7vw rgba(255, 152, 0, 0.28), 0 0.29vw 1.46vw 0 rgba(0, 0, 0, 0.12), 0 0.51vw 0.58vw -0.36vw rgba(255, 152, 0, 0.2)',
	},
	default: {
		background: '#0cc3b2',
		shadow: ' 0 0.87vw 1.46vw -0.7vw   rgba(12, 195, 178, 0.28),   0 0.29vw 1.46vw 0   rgba(0, 0, 0, 0.12),   0 0.51vw 0.58vw -0.36vw    rgba(12, 195, 178, 0.2)',
	},
	order_pending_lab_submission: {
		background: `linear-gradient(60deg, #ffa726, #fb8c00)`,
		shadow: '0 0.87vw 1.46vw -0.7vw rgba(255, 152, 0, 0.28), 0 0.29vw 1.46vw 0 rgba(0, 0, 0, 0.12), 0 0.51vw 0.58vw -0.36vw rgba(255, 152, 0, 0.2)',
	},
};
export const ignore_keys = {
	pending_sample_collection: 1,
	ready_to_order: 1,
	ready_to_schedule_results_review: 1,
	patient_followup_scheduled: 1,
};
export const callAttemptsFieldOptions = [
	{ value: 'All', label: 'All Call Attempts' },
	{ value: '0', label: '0 Call Attempts' },
	{ value: '1', label: '1 Call Attempts' },
	{ value: '>1', label: '>1 Call Attempts' },
];
export const external_dashboard_keys = [
	'appointment_alerts',
	'initial_visit_summary_ready',
	'result_visit_summary_ready',
];

export const external_metrics_type = {
	total_patient_count: 'external_total_patient_count',
	datewise_encounter_count: 'external_datewise_visit_count',
};

export const numberFormat = (number) => {
	return isNaN(number) ? '--' : new Intl.NumberFormat('en-US').format(number);
};

export const formulateVisitProvidersEnum = (visitProviders) => {
	let providerEnums = [];

	if (isEmpty(visitProviders)) return providerEnums;

	providerEnums.push({ groupingLabel: 'ACTIVE' });
	const filterActiveProvider = filter(visitProviders, (el) => {
		return el.deleted_at == null;
	});

	const activeProviders = filterActiveProvider.map((el) => {
		return {
			display_name: `${get(el, ['full_name'], '--')}`,
			key: get(el, ['uuid'], null),
		};
	});
	providerEnums = providerEnums.concat(activeProviders);
	providerEnums.push({ groupingLabel: 'INACTIVE' });

	const filterInactiveProvider = filter(visitProviders, (el) => {
		return el.deleted_at != null;
	});

	const inactiveProviders = filterInactiveProvider.map((el) => {
		return {
			display_name: `${get(el, ['full_name'], '--')}`,
			key: get(el, ['uuid'], null),
		};
	});
	providerEnums = providerEnums.concat(inactiveProviders);

	return providerEnums;
};
