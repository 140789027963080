import { isNil, omitBy, pickBy, toNumber } from 'lodash';

const omitNullEmptyGeneticExtentsValue = (data) => {
	return pickBy(
		{
			geneId: data.geneId,
			entireCodingRegion: data.entireCodingRegion,
			startExon:
				!isNil(data.startExon) && !isNaN(data.startExon)
					? toNumber(data.startExon)
					: data.startExon,
			endExon:
				!isNil(data.endExon) && !isNaN(data.endExon)
					? toNumber(data.endExon)
					: data.endExon,
		},
		(value) => {
			return !(value === '' || value === null || value === undefined);
		}
	);
};

const omitNullEmptyChromosomalExtentsValue = (data) => {
	return pickBy(
		{
			chromosome: data.chromosome,
			cytogeneticLocation: data.cytogeneticLocation,
			startPosition: isNaN(data.startPosition)
				? toNumber(data.startPosition)
				: data.startPosition,
			endPosition: isNaN(data.endPosition)
				? toNumber(data.endPosition)
				: data.endPosition,
		},
		(value) => {
			return !(value === '' || value === null || value === undefined);
		}
	);
};

export const deHydrateStructuralVariant = (data) => {
	const _deHydrateStructuralVariant = omitBy(
		{
			nomenclature: data.nomenclature,
			assessment: 'PRESENT',
			variantType: data.variantType,
			referenceAssemblyCode: data.referenceAssembly,
			geneticExtents: omitNullEmptyGeneticExtentsValue(data),
			chromosomalExtents: omitNullEmptyChromosomalExtentsValue(data),
			cytogenomicNomenclature: data.sequence,
			cytogenomicCharacterization: data.characterization,
			unit: data.unit,
			sizeValue: !isNaN(data.sizeValue)
				? toNumber(data.sizeValue)
				: data.sizeValue,
			copyNumber: !isNaN(data.copyNumber)
				? toNumber(data.copyNumber)
				: data.copyNumber,
			origin: data.origin,
			genomicSourceClass: data.genomicSourceClass,
			mosaicism: data.mosaicism,
			originalReportedClassification: data.labClassification,
		},
		isNil
	);
	return _deHydrateStructuralVariant;
};
