//React & Redux
import React from 'react';

//Lodash
import get from 'lodash/get';

//Styling
import './notes.css';

//Components
import CommentBox from './CommentBox.js';
import ViewNotes from './ViewNotes.js';
import CTAWarningModal from './../CTAWarningModal.js';
import RBAC from '../../RBAC';

//Images
import ReactSVG from 'react-svg';
import listIcon from './../../../assets/list-view.svg';
import addIcon from './../../../assets/add.svg';
import purpleAddIcon from './../../../assets/additional-flag.svg';

class NotesContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showAddNote: false,
			showViewNotes: false,
			noteText: '',
		};
		//bindings
		this.toggleAddNoteOn = this.toggleAddNoteOn.bind(this);
		this.toggleAddNoteOff = this.toggleAddNoteOff.bind(this);
		this.toggleViewNotesOn = this.toggleViewNotesOn.bind(this);
		this.toggleViewNotesOff = this.toggleViewNotesOff.bind(this);
		this.onChangeNoteText = this.onChangeNoteText.bind(this);
		this.hasValidAddNoteText = this.hasValidAddNoteText.bind(this);
		this.addNote = this.addNote.bind(this);
		this.addNoteTextArea = this.addNoteTextArea.bind(this);
		this.handleViewAllNotes = this.handleViewAllNotes.bind(this);
		this.emptyNotesMessage = this.emptyNotesMessage.bind(this);
		this.trimmedLengthCheck = this.trimmedLengthCheck.bind(this);
	}
	//functions

	componentDidUpdate(prevProps, prevState) {
		if (
			get(prevProps, 'addNoteLoading', false) &&
			!get(this, 'props.addNoteLoading', false)
		) {
			if (!get(this, 'props.addNoteError', false)) {
				this.toggleAddNoteOff();
			}
		}
	}

	toggleAddNoteOn() {
		this.setState({ showAddNote: true });
	}

	toggleAddNoteOff() {
		this.setState({ showAddNote: false, noteText: '' });
	}

	toggleViewNotesOn() {
		this.setState({ showViewNotes: true });
	}

	toggleViewNotesOff() {
		this.setState({ showViewNotes: false });
	}

	onChangeNoteText(e) {
		const text = get(e, 'target.value', null) || '';
		this.setState({ noteText: text });
	}

	trimmedLengthCheck() {
		const noteText = get(this, 'state.noteText', null) || '';
		const noteTextLength = get(noteText.trim(), 'length', null) || 0;

		return noteTextLength > 0;
	}

	hasValidAddNoteText() {
		const noteText = get(this, 'state.noteText', null) || '';
		const noteTextLength = get(noteText, 'length', null) || 0;
		if (typeof noteText === 'string') {
			return this.trimmedLengthCheck() && noteTextLength < 10000;
		} else return false;
	}

	addNote() {
		const sourceUUID = get(this, 'props.sourceUUID', null);
		const noteText = get(this, 'state.noteText', null) || '';
		if (this.hasValidAddNoteText() && sourceUUID) {
			this.props.addNote(sourceUUID, noteText.trim());
		}
	}

	addNoteTextArea() {
		const noteText = get(this, 'state.noteText', null) || '';
		const noteTooLong =
			!this.hasValidAddNoteText() && this.trimmedLengthCheck();
		const variant = get(this, 'props.variant', null) || 'dense';
		return (
			<div style={{ position: 'relative' }}>
				<textarea
					className='inputFieldTextArea'
					style={{
						height: '156px',
						width: '337px',
						marginBottom: '6px',
					}}
					value={noteText}
					onChange={this.onChangeNoteText}
					placeholder={
						variant === 'dense'
							? 'Add a medication-specific note'
							: 'Add a finding-specific comment'
					}
				/>
				{noteTooLong && (
					<div className='inputFieldError' style={{ top: '158px' }}>
						Note too long
					</div>
				)}
			</div>
		);
	}

	handleViewAllNotes() {
		const notes = get(this, 'props.notes', null) || [];
		const notesLength = get(notes, 'length', 0);
		if (notesLength > 1) this.toggleViewNotesOn();
	}

	emptyNotesMessage() {
		const variant = get(this, 'props.variant', null) || 'dense';
		const emptyMessageClass =
			get(this, 'props.emptyMessageClass', null) ||
			'commentBoxEmptyMessage';
		const emptyMessageEnding =
			get(this, 'props.emptyMessageEnding', null) || 'item';

		if (variant === 'cfDNA') {
			return (
				<div className={emptyMessageClass}>
					Nothing here yet. Click the button below to add the first
					comment on this finding.
				</div>
			);
		} else if (variant === 'dense') {
			return (
				<div className={emptyMessageClass}>
					Nothing here yet. Click the button to add the first note on
					this {emptyMessageEnding}.
				</div>
			);
		}
	}

	render() {
		const notes = get(this, 'props.notes', null) || [];
		const firstNote = get(notes, '[0]', null);
		const notesLength = get(notes, 'length', 0);
		const moreThanOneNote = notesLength > 1;
		const notesIconContainerClass = moreThanOneNote
			? 'notesIconContainer notesIconContainerActive'
			: 'notesIconContainer notesIconContainerInactive';
		const notesIconClass = moreThanOneNote
			? 'commentBoxNotesIcon commentBoxNotesIconActive'
			: 'commentBoxNotesIcon commentBoxNotesIconInactive';
		const notesViewAllTextClass = moreThanOneNote
			? 'notesViewAllText notesViewAllTextActive'
			: 'notesViewAllText notesViewAllTextInactive';
		const showAddNote = get(this, 'state.showAddNote', false);
		const showViewNotes = get(this, 'state.showViewNotes', false);
		const modal = showAddNote || showViewNotes;
		const variant = get(this, 'props.variant', null) || 'dense';
		const addNoteModalTitle =
			get(this, 'props.addNoteModalTitle', null) || 'New Note';
		const isShowListIcon = get(this, 'props.isShowListIcon', true);
		const hasRBACAddComment = get(this, 'props.hasRBACAddComment', false);
		const RBACAddCommentAction = get(this, 'props.RBACAddCommentAction');

		return (
			<div className='notesContainer'>
				{firstNote ? (
					<CommentBox variant={variant} note={firstNote} />
				) : (
					this.emptyNotesMessage()
				)}

				{variant === 'dense' ? (
					<div
						className='notesBottomRow'
						style={{ marginTop: firstNote ? '16px' : '6px' }}
					>
						{notesLength ? (
							<div
								className={notesIconContainerClass}
								onClick={this.handleViewAllNotes}
							>
								{isShowListIcon && (
									<ReactSVG
										src={listIcon}
										className={notesIconClass}
									/>
								)}
								<div className={notesViewAllTextClass}>
									View all notes{' '}
									{notesLength > 1 ? `(${notesLength})` : ''}
								</div>
							</div>
						) : (
							<div />
						)}
						<button
							type='button'
							className='btn-bg createdocbtn'
							onClick={this.toggleAddNoteOn}
							style={{ width: '118px' }}
						>
							<span className='button-space'>
								<ReactSVG
									className={
										'menuItemIconWhite new_encounter'
									}
									src={addIcon}
								/>
							</span>
							<span className='text_space'>{'Add Note'}</span>
						</button>
					</div>
				) : (
					<div className='notesBottomRow'>
						{notesLength ? (
							<div
								className='btn-view-all-container'
								onClick={this.handleViewAllNotes}
							>
								{isShowListIcon && (
									<ReactSVG
										src={listIcon}
										className={notesIconClass}
									/>
								)}
								<div className={notesViewAllTextClass}>
									View all{' '}
									{notesLength > 1 ? `(${notesLength})` : ''}
								</div>
							</div>
						) : (
							<div />
						)}

						{hasRBACAddComment ? (
							<RBAC
								action={RBACAddCommentAction}
								yes={
									<button
										type='button'
										className='btn-add-comment'
										onClick={this.toggleAddNoteOn}
									>
										<span className='button-space'>
											<ReactSVG
												className={'icon-plus-purple'}
												src={purpleAddIcon}
											/>
										</span>
										<span className='text_space'>
											Add Comment
										</span>
									</button>
								}
							/>
						) : (
							<button
								type='button'
								className='btn-add-comment'
								onClick={this.toggleAddNoteOn}
							>
								<span className='button-space'>
									<ReactSVG
										className={'icon-plus-purple'}
										src={purpleAddIcon}
									/>
								</span>
								<span className='text_space'>Add Comment</span>
							</button>
						)}
					</div>
				)}
				{showAddNote && (
					<CTAWarningModal
						loading={get(this, 'props.addNoteLoading', false)}
						closeFunc={this.toggleAddNoteOff}
						colorScheme='purple'
						mainFunc={this.addNote}
						extraBodyUI={this.addNoteTextArea()}
						iconColorScheme='none'
						title={addNoteModalTitle}
						noBodyText={true}
						mainButtonText='Save'
						mainButtonDisabled={!this.hasValidAddNoteText()}
						buttonRowBorder={true}
						modalContainerStyling={{
							minHeight: '350px',
							top: 'calc(50vh - 160px)',
						}}
					/>
				)}

				{showViewNotes && (
					<ViewNotes
						notes={notes}
						toggleViewNotesOff={this.toggleViewNotesOff}
						variant={variant}
					/>
				)}
			</div>
		);
	}
}

export default NotesContainer;
