import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import PatientCreateOutreachModal from '../../Patients/PatientCreateOutreach/PatientCreateOutreachModal';
import {
	enqueueSnackbar,
	referralOutreachStatuses,
	referralOutreachSubstatuses,
} from '../../../utils';
import {
	getContactMethods,
	getContactOutcomes,
	patientdetail,
	getNoOutreachReasons,
} from '../../../actions/patients';
import {
	getOutreachEnums,
	getOutreachDeclinedEnum,
	doNotOutreach,
	addOutreachUponCreate,
	updateOutreachRound,
} from '../../../actions/patient/outreach';
import {
	isValidDateTimeString,
	dateTimeFormats,
	getMomentDateTimeOrString,
	getDateTimeString,
} from '../../Patients/V2/Outreachs/utilfunctions';

export const AddOutreach = withRouter(function (props) {
	const [value, setValue] = useState({});
	const dispatch = useDispatch();
	const {
		contactMethods,
		contactOutcomes,
		noOutreachReasons,
		outreachEnums,
		patient,
		patientLoading,
		settingDoNotOutreach,
	} = useSelector((store) => ({
		contactMethods: store.contactMethods.contactMethods.data,
		contactOutcomes: store.contactOutcomes.contactOutcomes,
		noOutreachReasons: store.noOutreach.noOutreachReasons,
		outreachEnums: store.outreach.outreachEnums,
		patient: store.patientdetail.patientdetail[0] ?? {},
		patientLoading: store.patientdetail.gettingPatientDetail,
		settingDoNotOutreach: store.outreach.settingDoNotOutreach,
	}));

	useEffect(() => {
		dispatch(getContactMethods());
		dispatch(getContactOutcomes());
		dispatch(getOutreachEnums());
		dispatch(getOutreachDeclinedEnum());
		dispatch(getNoOutreachReasons());
	}, []);

	useEffect(() => {
		if (props.patientUuid) {
			dispatch(patientdetail(props.patientUuid));
			setValue({
				patientCreateAddOutreachDateTime: getDateTimeString(moment()),
				patientCreateDoNotOutreachDateTime: getDateTimeString(moment()),
			});
		}
	}, [props.patientUuid]);

	if (!props.patientUuid || patientLoading !== false) {
		return null;
	}
	return (
		<PatientCreateOutreachModal
			close={props.close}
			enqueueSnackbar={enqueueSnackbar}
			patient={patient}
			contactMethods={contactMethods}
			contactOutcomes={contactOutcomes}
			noOutreachReasons={noOutreachReasons}
			outreachEnums={outreachEnums}
			settingDoNotOutreach={settingDoNotOutreach}
			setValue={(key, val) => setValue({ ...value, [key]: val })}
			patientCreateAddOutreachDateTime={
				value.patientCreateAddOutreachDateTime
			}
			patientCreateAddOutreachNotes={value.patientCreateAddOutreachNotes}
			patientCreateAddOutreachOutcome={
				value.patientCreateAddOutreachOutcome
			}
			patientCreateDoNotOutreachReason={
				value.patientCreateDoNotOutreachReason
			}
			patientCreateDoNotOutreachDateTime={
				value.patientCreateDoNotOutreachDateTime
			}
			patientCreateAddOutreach={async () => {
				if (
					!isValidDateTimeString(
						value.patientCreateAddOutreachDateTime,
						dateTimeFormats
					)
				) {
					return;
				}
				const utcString = getMomentDateTimeOrString(
					value.patientCreateAddOutreachDateTime,
					dateTimeFormats
				)
					.utc()
					.format('YYYY-MM-DD HH:mm:ss');

				const payload = {
					date_time: utcString,
					contact_method: 'phone',
					type: 'referral_outreach',
					outcome: value.patientCreateAddOutreachOutcome ?? null,
					class: 'outreach',
					outreach_round_id: props.outreachRoundId,
				};
				if (value.patientCreateAddOutreachOutcome === 'other') {
					payload['notes'] = value.patientCreateAddOutreachNotes;
				}
				await dispatch(
					addOutreachUponCreate(payload, props.patientUuid)
				);
				if (
					props.outreachStatus ===
					referralOutreachStatuses.followupRequired
				) {
					await updateOutreachRound(
						props.patientUuid,
						props.outreachRoundId,
						{
							status: referralOutreachStatuses.outreachCompleted,
							substatus: referralOutreachSubstatuses.notScheduled,
						}
					);
				}
				enqueueSnackbar('Successfully logged outreach', {
					variant: 'success',
				});
				props.close();
			}}
			patientCreateSetDoNotOutreach={async () => {
				if (
					!(
						value.patientCreateDoNotOutreachDateTime &&
						value.patientCreateDoNotOutreachReason
					)
				) {
					return;
				}
				if (
					!isValidDateTimeString(
						value.patientCreateDoNotOutreachDateTime,
						dateTimeFormats
					)
				) {
					return;
				}
				const utcString = getMomentDateTimeOrString(
					value.patientCreateDoNotOutreachDateTime,
					dateTimeFormats
				)
					.utc()
					.format('YYYY-MM-DD HH:mm:ss');

				const payload = {
					date_time: utcString,
					enabled: false,
					reason: value.patientCreateDoNotOutreachReason,
					via: 'phone',
				};

				if (
					value.patientCreateDoNotOutreachReason ===
					'other_disable_dno'
				) {
					payload.notes =
						value.disabledDoNotOutreachReasonNotes || null;
				}
				await dispatch(doNotOutreach(payload, props.patientUuid));
				await dispatch(
					addOutreachUponCreate(
						{
							date_time: utcString,
							contact_method: 'phone',
							type: 'referral_outreach',
							outcome: 'patient_declined',
							class: 'outreach',
							outreach_round_id: props.outreachRoundId,
						},
						props.patientUuid,
						false
					)
				);
				enqueueSnackbar('Successfully logged outreach', {
					variant: 'success',
				});
				props.close();
			}}
			patientCreateRedirectToScheduling={() => {
				const payload = {
					date_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
					contact_method: 'phone',
					type: 'referral_outreach',
					outcome: 'patient_scheduled',
					class: 'outreach',
					outreach_round_id: props.outreachRoundId,
				};
				if (value.patientCreateAddOutreachOutcome === 'other') {
					payload['notes'] = value.patientCreateAddOutreachNotes;
				}

				dispatch(addOutreachUponCreate(payload, props.patientUuid));

				props.history.push({
					pathname: '/app/appointments/schedule',
					state: {
						breadcrumbs: [
							{
								location: 'Appointments',
								url: '/app/appointments',
							},
							{
								location: 'Schedule Appointment',
								url: '/app/appointments/schedule',
								patientData: {
									firstName: patient?.first_name ?? '',
									lastName: patient?.last_name ?? '',
									id: patient?.id ?? 0,
									uuid: props.patientUuid,
								},
							},
						],
					},
				});
			}}
		/>
	);
});
