//React & Redux
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

//Lodash
import _ from 'lodash';

//Utils
import {
	external_dashboard_keys,
	dashboard_key_title,
} from './../../../Utils/dashboardUtils';
import {
	HOME_DASHBOARD_UPCOMING_APPOINTMENTS,
	HOME_DASHBOARD_ALL_TIME_METRICS,
	HOME_DASHBOARD_FOURTEEN_DAYS_METRICS,
	HOME_DASHBOARD_CARDS_APPOINTMENT_ALERTS,
	HOME_DASHBOARD_CARDS_INITIAL_VISIT_SUMMARY_READY,
	HOME_DASHBOARD_CARDS_RESULT_VISIT_SUMMARY_READY,
} from '../../../Utils/permissionUtils';

//Actions & Services
import {
	getdashboard,
	getupcomingappointments,
} from '../../../actions/dashboard';
import {
	getMetricsTotalPatient,
	getMetricsTotalEncounter,
} from '../../../actions/metrics';

//Components
import PatientStageCards from './PatientStageCards';
import UpcomingAppointments from './UpcomingAppointments';
import EncounterGraphs from './EncounterGraphs';
import EncounterScores from './EncounterScores';
import RBAC from '../../RBAC';

//Other Libraries
import moment from 'moment';

//Styles
import './dashboard.css';

const PERMISSION_MAPPER = {
	appointment_alerts: HOME_DASHBOARD_CARDS_APPOINTMENT_ALERTS,
	initial_visit_summary_ready:
		HOME_DASHBOARD_CARDS_INITIAL_VISIT_SUMMARY_READY,
	result_visit_summary_ready: HOME_DASHBOARD_CARDS_RESULT_VISIT_SUMMARY_READY,
};

export const Dashboard = (props) => {
	const {
		dashboard_data = 0,
		EncounterTotals,
		getDashboard,
		getDashboardAppointments,
		getmetricstotalencounter,
		getmetricstotalpatient,
		history,
		PatientTotals,
		upcomingAppointments = {},
		loadingUpcomingappointments = false,
	} = props;

	useEffect(() => {
		window.scrollTo(0, 0);
		getDashboardAppointments({ offset: 0 });
		getDashboard(external_dashboard_keys);
		getmetricstotalpatient();
		const end_date = moment().format('YYYY-MM-DD');
		const start_date = moment().subtract(13, 'days').format('YYYY-MM-DD');
		getmetricstotalencounter(start_date, end_date);
	}, []);

	return (
		<div className='extrnl-dashbrd-wrapper'>
			<div className='extrnl-dashbrd-left-panel'>
				<section className='extrnl-dashbrd-card-wrapper'>
					{(external_dashboard_keys || []).map((e, i) => {
						return (
							<RBAC
								action={PERMISSION_MAPPER[e]}
								yes={
									<PatientStageCards
										key={i}
										history={history}
										data={{
											card: e,
											title: _.get(
												dashboard_key_title,
												e,
												''
											),
											total: _.get(
												dashboard_data,
												[e],
												0
											),
										}}
									/>
								}
							/>
						);
					})}
				</section>
				<section className='extrnl-dashbrd-graphs-wrapper'>
					<RBAC
						action={HOME_DASHBOARD_ALL_TIME_METRICS}
						yes={
							<div className='encounter-scores-wrapper'>
								<EncounterScores
									PatientTotals={PatientTotals}
								/>
							</div>
						}
					/>
					<RBAC
						action={HOME_DASHBOARD_FOURTEEN_DAYS_METRICS}
						yes={
							<div className='encounter-charts-wrapper'>
								<EncounterGraphs
									EncounterTotals={EncounterTotals}
								/>
							</div>
						}
					/>
				</section>
			</div>
			<div className='extrnl-dashbrd-right-panel'>
				<RBAC
					action={HOME_DASHBOARD_UPCOMING_APPOINTMENTS}
					yes={
						<UpcomingAppointments
							upcoming_appointments={upcomingAppointments}
							history={history}
							getDashboardAppointments={getDashboardAppointments}
							loadingUpcomingappointments={
								loadingUpcomingappointments
							}
						/>
					}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state, props) => {
	return {
		dashboard_data: _.get(state, ['dashboard', 'dashboard_data'], {}),
		upcomingAppointments: _.get(
			state,
			['upcomingappointments', 'upcomingappointments'],
			{}
		),
		loadingUpcomingappointments: _.get(
			state,
			['upcomingappointments', 'loadingUpcomingappointments'],
			false
		),
		EncounterTotals: _.get(state, ['metrics', 'EncounterTotals'], {}),
		PatientTotals: _.get(state, ['metrics', 'PatientTotals'], {}),
	};
};

const mapDispatchToProps = (dispatch) => ({
	getDashboard: (cardlist) => dispatch(getdashboard(cardlist)),
	getDashboardAppointments: (filter) =>
		dispatch(getupcomingappointments(filter)),
	getmetricstotalpatient: () => dispatch(getMetricsTotalPatient()),
	getmetricstotalencounter: (start_date, end_date) =>
		dispatch(getMetricsTotalEncounter(start_date, end_date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
