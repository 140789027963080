//React & Redux
import React, { Fragment } from 'react';

//Other Libraries
import ReactSVG from 'react-svg';
import get from 'lodash/get';
import moment from 'moment';
import 'moment-timezone';

import contactsIcon from '../../../../../assets/contacts.svg';
import visitIcon from '../../../../../assets/visit.svg';

//Components
import ProviderInfo from '../../../../Common/ProviderInfo';
import {
	UTCToCurrentDateView,
	UTCToCurrentTimeView,
} from '../../../../../utils';

export function PatientInfo(props) {
	const {
		info: { dob, email, first_name, last_name, referral_program, gender },
	} = props;
	const age = dob ? moment().diff(moment(dob, 'YYYY'), 'years') : null;

	return (
		<div className='patientInfo'>
			<ReactSVG src={contactsIcon} />
			<div>
				<div className='name'>{first_name + ' ' + last_name}</div>
				<div className='email'>{email || '--'}</div>
				<div>
					<span className='dob'>
						{dob ? moment(dob).format('MMM DD, YYYY') : '--'}
					</span>
					·<span className='age'>{age ? `${age}yrs old` : '--'}</span>
					·<span className='gender'>{gender || '--'}</span>
				</div>
				<div className='refProgram'>
					Ref. Program:<span>{referral_program || '--'}</span>
				</div>
			</div>
		</div>
	);
}

export function EncounterList(props) {
	const { list, selected, handleSelectEncounter } = props;

	if (list.length) {
		return (
			<div className='encountersListContainer'>
				<div>Completed Encounters</div>
				<div className='encountersList'>
					{list.map((encounter) => {
						const tz =
							get(encounter, ['type'], '') === 'research-data'
								? 'America/New_York'
								: get(encounter, ['timezone'], '');
						const date = UTCToCurrentDateView(
							moment,
							moment(get(encounter, ['date_of_service'], '')),
							tz
						);
						const time = UTCToCurrentTimeView(
							moment,
							moment(get(encounter, ['date_of_service'], '')),
							tz
						);
						return (
							<div
								key={encounter.uuid}
								className={`${
									selected && selected.uuid === encounter.uuid
										? 'selected'
										: ''
								}`}
								onClick={(e) =>
									handleSelectEncounter(e, encounter)
								}
							>
								<div className='radioButton'>
									<div />
								</div>
								<div className='content'>
									<div className='dateAndTime'>
										<div>{date}</div>
										<div>{time}</div>
									</div>
									<div className='encounterInfo'>
										<ReactSVG src={visitIcon} />
										<div>
											<div>
												{get(
													encounter,
													['consultation_type'],
													''
												)}
											</div>
											<div>
												{get(
													encounter,
													['visit_status'],
													''
												)}
											</div>
										</div>
									</div>
									<div className='providerInfo'>
										<ProviderInfo
											providerName={get(
												encounter,
												['provider'],
												''
											)}
										/>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	return (
		<div className='noEncounters'>
			<div>No Results Found</div>
			<div>
				This patient doesn't have any completed encounters. Please
				select another patient.
			</div>
		</div>
	);
}

export default function (props) {
	const { patient, selectedEncounter, encounters } = props;

	return (
		<Fragment>
			<PatientInfo info={patient} />
			<EncounterList
				list={encounters}
				selected={selectedEncounter}
				handleSelectEncounter={props.handleSelectEncounter}
			/>
		</Fragment>
	);
}
