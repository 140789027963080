//React & Redux
import React from 'react';

//Lodash
import _ from 'lodash';

//Utils
import { imageDefault, providers_images } from '../../../Utils/encounterUtils';

//Other Libraries
import moment from 'moment';

//Components
import Icon from '../../Common/Icon';
import Tooltip from '../../Common/Tooltip';

class CurrentAppointmentView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		//bindings
	}
	//functions
	getAppointmentTime() {
		const dos = moment(
			_.get(this, ['props', 'encounterdetail', 'date_of_service'])
		);
		const endtime = moment(
			_.get(this, ['props', 'encounterdetail', 'end_time'])
		);
		const timezone = _.get(this, ['props', 'encounterdetail', 'timezone']);
		const dosmoment = moment.tz(dos.format('YYYY-MM-DD HH:mm:ss'), 'UTC');
		const endtimemoment = moment.tz(
			endtime.format('YYYY-MM-DD HH:mm:ss'),
			'UTC'
		);
		let date_time_zone = '';
		let endtime_zone = '';

		if (timezone && timezone.length > 0) {
			date_time_zone = moment
				.tz(dosmoment, timezone)
				.format('dddd, MMM D, h:mm A');
			endtime_zone = moment
				.tz(endtimemoment, timezone)
				.format('h:mm A z ');
			return date_time_zone + ' - ' + endtime_zone;
		} else {
			const tzone = moment.tz.guess();
			date_time_zone = moment
				.tz(dosmoment, tzone)
				.format('dddd, MMM D, h:mm A');
			endtime_zone = moment.tz(endtimemoment, tzone).format('h:mm A z ');
			return date_time_zone + ' - ' + endtime_zone;
		}
	}

	getspecialty() {
		const selected = _.get(
			this,
			['props', 'encounterdetail', 'vsee_specialty'],
			''
		)
			.replace('_Willing', '')
			.replace('_willing', '');
		const specialties = _.get(this, ['props', 'specialties'], []);
		const speciality = specialties.find((c) => c.key === selected);
		return _.get(speciality, 'display_name', '');
	}

	getAppointmentModalityIcon() {
		const modality = _.get(
			this,
			['props', 'encounterdetail', 'tm_platform'],
			''
		);
		if (modality.toLowerCase() == 'video')
			return <Icon icon='video' className='videoic' />;
		return <Icon icon='phone' className='videoic' />;
	}

	getAppointmentProviderImage() {
		let providerImage = null;
		const providerName = _.get(
			this,
			['props', 'encounterdetail', 'visit_provider'],
			''
		);
		const mappedImage = providers_images[providerName];

		if (providerName && mappedImage) {
			providerImage = <img src={mappedImage} alt='' />;
		} else if (providerName != '')
			providerImage = <img src={imageDefault} alt='' />;
		else {
			providerImage = null;
		}
		return (
			providerImage && (
				<span className='patient-imgbox'>{providerImage}</span>
			)
		);
	}

	getAppointmentProviderName() {
		const providerName = _.get(
			this,
			['props', 'encounterdetail', 'visit_provider'],
			''
		).replace('_', ' ');

		return (
			<span className='provider-name'>
				{this.getShortText(
					_.map(providerName.split(' '), _.upperFirst).join(' '),
					12
				)}
			</span>
		);
	}

	getShortText = (text, length) => {
		if (text && !_.isEmpty(text) && text.length > length) {
			return (
				<Tooltip placement='top' content={text}>
					<span>{text.substring(0, length) + '...'}</span>
				</Tooltip>
			);
		} else {
			return text;
		}
	};

	render() {
		const payment = _.get(this, 'props.payment', {});

		return (
			<React.Fragment>
				<div></div>
				<div className='sideContainerBorder' />
				<div className='scheduleAppointmentSideContainerSection'>
					<div
						className='scheduleAppointmentSideContainerName'
						style={{ marginBottom: '3px' }}
					>
						{_.get(
							this,
							['props', 'encounterdetail', 'consultation_type'],
							''
						)}
					</div>
					<div className='scheduleAppointmentSideContainerPatientText'>
						{this.getAppointmentTime()} -{' '}
						<span style={{ fontFamily: 'RionaSans-Bold' }}>
							{' '}
							{_.get(
								this,
								['props', 'encounterdetail', 'state_of_visit'],
								''
							)}
						</span>
					</div>
					<div className='scheduleAppointmentSideContainerPatientText'>
						{this.getspecialty()}
					</div>
					<div
						className='scheduleAppointmentSideContainerPatientText scheduleAppointmentSideContainerRow'
						style={{ marginBottom: '3px' }}
					>
						<div className='scheduleAppointmentSideContainerHalf'>
							{this.getAppointmentProviderImage()}
							{this.getAppointmentProviderName()}
						</div>
						<div className='scheduleAppointmentSideContainerHalf'>
							<span style={{ height: '18px', width: '18px' }}>
								{this.getAppointmentModalityIcon()}
							</span>
							<div style={{ textTransform: 'capitalize' }}>
								{_.get(
									this,
									['props', 'encounterdetail', 'tm_platform'],
									''
								)}
							</div>
						</div>
					</div>
					<div className='scheduleAppointmentSideContainerPatientText aptmnt-status'>
						{_.get(
							this,
							['props', 'encounterdetail', 'visit_status'],
							''
						)}
					</div>
					<div className='scheduleAppointmentSideContainerPatientText'>
						<span>Visit fee: </span>
						<span className='reschedule-val-visitfee'>{`$${
							payment ? payment : 0
						}`}</span>
					</div>
				</div>
				<div className='sideContainerBorder' />
			</React.Fragment>
		);
	}
}

export default CurrentAppointmentView;
