//React & Redux
import React from 'react';

//Utils
import { isPartnerInitiatedPreTest } from './Common/utilsfunctions';

//Components
import VisitEncounterDetail from './VisitEncounters/VisitEncounterDetail';
import GroupSessionEncounterDetail from './GroupSessionEncounters/GroupSessionEncounterDetail';
import CCIntakeEncounterDetail from './CCIntakeEncounters/CCIntakeEncounterDetail';
import ResearchEncounterDetail from './ResearchEncounters/ResearchEncounterDetail';
import LabEncounterDetail from './LabEncounters/LabEncounterDetail';
import SCPEncounterDetail from './SCPEncounters/SCPEncounterDetail';
import TROEncounterDetail from './TROEncounters/TROEncounterDetail';

//Lodash
import get from 'lodash/get';

//Style
import './css/hold-release-checkbox.css';

const EncounterBody = (props) => {
	const { encounter_type } = props;
	let component;
	let encounterType = encounter_type;

	if (encounter_type == 'scp') {
		encounterType = encounter_type;
		const encounter =
			(props.visitEncounterProps &&
				props.visitEncounterProps.encounter) ||
			{};
		if (isPartnerInitiatedPreTest(encounter)) {
			encounterType = 'TRO';
		}
	}

	switch (encounterType) {
		case 'visit':
			component = (
				<VisitEncounterDetail
					{...props.visitEncounterProps}
					externalProviderProps={props.externalProviderProps}
					getencouter={props.getencouter}
					enqueueSnackbar={props.enqueueSnackbar}
				/>
			);
			break;
		case 'group-session':
			component = (
				<GroupSessionEncounterDetail
					{...props.groupSessionEncounterProps}
					externalProviderProps={props.externalProviderProps}
					getencouter={props.getencouter}
					enqueueSnackbar={props.enqueueSnackbar}
				/>
			);
			break;
		case 'cc-intake':
			component = (
				<CCIntakeEncounterDetail
					{...props.ccIntakeEncounterProps}
					externalProviderProps={props.externalProviderProps}
					getencouter={props.getencouter}
					enqueueSnackbar={props.enqueueSnackbar}
				/>
			);
			break;
		case 'research-data':
			component = (
				<ResearchEncounterDetail
					{...props.researchEncounterProps}
					externalProviderProps={props.externalProviderProps}
					getencouter={props.getencouter}
					enqueueSnackbar={props.enqueueSnackbar}
				/>
			);
			break;
		case 'lab_test_authorization':
			component = (
				<LabEncounterDetail
					{...props.labEncounterProps}
					externalProviderProps={props.externalProviderProps}
					getencouter={props.getencouter}
					enqueueSnackbar={props.enqueueSnackbar}
				/>
			);
			break;
		case 'scp':
			component = (
				<SCPEncounterDetail
					{...props.visitEncounterProps}
					externalProviderProps={props.externalProviderProps}
					getencouter={props.getencouter}
					enqueueSnackbar={props.enqueueSnackbar}
					createTestRequestFlags={props.createTestRequestFlags}
					updateTestRequestFlags={props.updateTestRequestFlags}
					creatingTestOrderFlags={props.creatingTestOrderFlags}
					updatingTestOrderFlags={props.updatingTestOrderFlags}
				/>
			);
			break;
		case 'TRO':
			component = (
				<TROEncounterDetail
					{...props.visitEncounterProps}
					externalProviderProps={props.externalProviderProps}
					getencouter={props.getencouter}
					enqueueSnackbar={props.enqueueSnackbar}
					createTestRequestFlags={props.createTestRequestFlags}
					updateTestRequestFlags={props.updateTestRequestFlags}
					creatingTestOrderFlags={props.creatingTestOrderFlags}
					updatingTestOrderFlags={props.updatingTestOrderFlags}
				/>
			);
			break;
		default:
			component = null;
			break;
	}

	return <div className='encounter-body'>{component}</div>;
};

export default EncounterBody;
