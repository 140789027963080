//React & Redux
import React from 'react';

//Images
import exclamation_img from '../../assets/exclamation.png';

//Lodash
import get from 'lodash/get';

//Actions & Services

//UI Libraries
import { Loading, Button, Dialog } from 'gm-element-react';

//Other Libraries

//Components
import Textarea from '../Common/controls/Textarea/Textarea';

//Styles
import './UserDeleteDialog.css';

class UserDeleteDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			reason: '',
		};
	}

	handleReasonChange(val) {
		this.setState({ reason: val });
	}

	deleteUserRecord() {
		const reason = get(this, 'state.reason', '');
		if (reason.trim().length !== 0) {
			const data = {
				data: {
					reason: reason,
				},
			};
			this.props.deleteUser(this.props.userData.uuid, data);
		}
	}

	disableDeleteButton() {
		return (
			this.state.reason.trim().length === 0 ||
			this.state.reason.trim().length > 2048 ||
			this.props.deleteUserLoading
		);
	}

	render() {
		const { reason } = this.state;
		const { userData } = this.props;

		return (
			<Dialog
				customClass='user-delete-dialog'
				title={
					<div className='header'>
						<div className='title'>
							<b>{'Deleting'}</b>
						</div>
						<div className='error-title'>
							<b>
								{get(userData, 'first_name', '')}{' '}
								{get(userData, 'last_name', '')}
							</b>
							&nbsp;
							<img
								src={exclamation_img}
								alt=''
								style={{ height: '1.2vw', marginTop: '-4px' }}
							/>
						</div>
					</div>
				}
				modal='true'
				closeOnPressEscape='true'
				closeOnClickModal='true'
				showClose={false}
				visible={true}
			>
				<Dialog.Body>
					<Loading
						loading={this.props.deleteUserLoading}
						className='report_loader'
					>
						<label className='delete-reason-label required'>
							Define the reason for deletion
						</label>
						<Textarea
							name='reason'
							id='reason'
							rows={4}
							className='reason-field'
							placeholder='Type the reason here'
							value={reason}
							onChange={(val) => this.handleReasonChange(val)}
						/>
					</Loading>
				</Dialog.Body>
				<Dialog.Footer>
					<Button
						onClick={this.props.onCancel}
						className='cancel-user'
					>
						Cancel
					</Button>
					<Button
						onClick={() => this.deleteUserRecord()}
						className='delete-user'
						disabled={this.disableDeleteButton()}
					>
						Delete User
					</Button>
				</Dialog.Footer>
			</Dialog>
		);
	}
}

export default UserDeleteDialog;
