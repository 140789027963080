//React & Redux
import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

//Lodash
import _ from 'lodash';
import get from 'lodash/get';

//Utils
import { isExternalUser, UTCToCurrentTimeView } from '../../utils.js';
import {
	imageDefault,
	providers_images,
	visit_status_colors,
} from '../../Utils/encounterUtils';
import {
	CARE_APPOINTMENTS_APPOINTMENT_MODALITY,
	CARE_APPOINTMENTS_APPOINTMENT_CANCEL,
} from '../../Utils/permissionUtils';

//UI Libraries
import { Dropdown, Popover, Dialog, Button, Loading } from 'gm-element-react';

//Other Libraries
import moment from 'moment';
import classnames from 'classnames';

//Components
import RBAC from '../RBAC';
import Icon from './../Common/Icon';
import LinkPopover from './../Common/Popovers/LinkPopover';
import Tooltip from './../Common/Tooltip';
import Pagination from './../Common/Pagination';
import CommonCheckbox from '../Common/Checkbox.js';
import AppointmentErrorDialog from '../Appointment/AppointmentErrorDialog';

//Styles
import './AppointmentList.css';

//Hooks
import { usePrevious } from '../../hooks/usePrevious';

const NO_SHOW = 'no_show';
const CANCEL = 'cancel';
const AppointmentList = (props) => {
	const {
		appointmentCancel,
		appointmentNoShow,
		appointmentPagination = {},
		appointmentStatus = [],
		appointments = [],
		changefilter,
		encounterCancelLoading,
		enqueueSnackbar,
		getAppointments,
		getPayorEnums,
		loaded = false,
		modalities = [],
		NavigateToEncounterDetail,
		navigateToPatientDetailPymntTab,
		navigateToRescheduleAppointmentPage,
		payorEnums,
		switchModality,
		switchedEncounterModality,
		switchingEncounterModality,
		switchingEncounterModalityError,
		visitProviders = [],
	} = props;

	const me = useSelector((store) => store.me);
	const [showModalityErrorDialog, setShowModalityErrorDialog] =
		useState(false);
	const [showModalityConfirmDialog, setShowModalityConfirmDialog] =
		useState(false);
	const [dialogShow, setDialogShow] = useState(false);
	const [dialogType, setDialogType] = useState('');
	const [dialogLoading, setDialogLoading] = useState(false);
	const [updatingModality, setUpdatingModality] = useState(false);
	const [updatingModalityUuid, setUpdatingModalityUuid] = useState(null);
	const [updatingModalityAppointment, setUpdatingModalityAppointment] =
		useState(null);
	const [updatingModalityType, setUpdatingModalityType] = useState(null);
	const [dialogConfirmFun, setDialogConfirmFun] = useState(() => {});
	const [visible] = useState(false);
	const [notifyPatient, setNotifyPatient] = useState(true);
	const [errorMessages, setErrorMessages] = useState([]);
	const [showErrorDialog, setShowErrorDialog] = useState(false);
	const previousEncounterCancelLoading = usePrevious(encounterCancelLoading);
	const previousSwitchingEncounterModality = usePrevious(
		switchingEncounterModality
	);
	const dummyDivRef = useRef();

	const showErrorsDialog = (errors) => {
		setErrorMessages(errors);
		setShowErrorDialog(true);
	};

	const errorCloseHandler = () => {
		setShowErrorDialog(false);
	};

	useEffect(() => {
		getPayorEnums();
	}, []);

	useEffect(() => {
		if (previousEncounterCancelLoading && !encounterCancelLoading) {
			resetDialog();
			getAppointments();
		}
	}, [encounterCancelLoading]);

	useEffect(() => {
		if (
			previousSwitchingEncounterModality != switchingEncounterModality &&
			previousSwitchingEncounterModality == true &&
			switchingEncounterModality == false &&
			updatingModality
		) {
			let message = 'Modality has been updated successfully';
			const isError =
				switchingEncounterModalityError || !switchedEncounterModality
					? true
					: false;

			if (isError) {
				message = _.get(
					switchingEncounterModalityError,
					'response.data.reason.message',
					'Unable to update modality'
				);
			}

			sendNotification(message, isError ? 'error' : 'success');
			setShowModalityConfirmDialog(false);
			setUpdatingModality(false);
			setUpdatingModalityUuid(null);
			setUpdatingModalityAppointment(null);
			setUpdatingModalityType(null);

			getAppointments();
		}
	}, [switchingEncounterModality]);

	const closeModalityErrorDialog = () => {
		setShowModalityErrorDialog(false);
	};

	const closeModalityConfirmDialog = () => {
		setShowModalityConfirmDialog(false);
	};

	const sendNotification = (message, type) => {
		enqueueSnackbar(message, {
			variant: type,
			className: 'export_notification',
			anchorOrigin: { horizontal: 'center', vertical: 'top' },
		});
	};

	const changeModality = (appointment, type) => {
		if (!updatingModality) {
			const encounter_uuid = _.get(appointment, ['uuid'], 0);

			setUpdatingModalityUuid(encounter_uuid);
			switchModality(encounter_uuid, type);
			setShowModalityConfirmDialog(false);
			setUpdatingModality(true);
		}
	};

	const handlePageChange = (evt, offset) => {
		changefilter('offset', offset);
	};

	const viewAppointmentHandler = (appointment) => {
		const { uuid, user_uuid } = appointment;
		const fullName = `${
			get(appointment, 'patient.first_name', '--') || '--'
		} ${get(appointment, 'patient.last_name', '--') || '--'}`;
		NavigateToEncounterDetail(user_uuid, uuid, fullName);
	};

	const NavigateToEncounterDetailURL = (appointment) => {
		const { uuid, user_uuid } = appointment;
		return `/app/patientdetail/${user_uuid}/0/2/${uuid}/`;
	};

	const resetDialog = () => {
		setDialogShow(false);
		setDialogType('');
		setDialogLoading(false);
		setDialogConfirmFun(() => {});
		setNotifyPatient(true);
	};

	const handleDialog = async () => {
		if (dialogType === CANCEL) {
			try {
				setDialogLoading(true);
				await appointmentCancel(dialogConfirmFun, notifyPatient);
			} catch (error) {
				const errorMessage = error?.message || 'An error occurred.';
				showErrorsDialog(errorMessage);
			} finally {
				setDialogLoading(false);
			}
		} else if (dialogType == NO_SHOW) {
			appointmentNoShow(dialogConfirmFun);
			setTimeout(() => {
				setDialogLoading(true);
			}, 100);
		}
	};

	const showRemoveDialog = (status, appointment) => {
		const isAppointmentStarted = isAppointmentStartedFunc(appointment);
		//dummyDivRef.current.click(); // required to hide the popover
		const { uuid } = appointment;
		if (
			status === NO_SHOW &&
			isAppointmentStarted &&
			uuid &&
			isAppointmentBooked(appointment)
		) {
			setTimeout(() => {
				setDialogShow(true);
				setDialogType(NO_SHOW);
				setDialogConfirmFun(uuid);
			}, 200);
		} else if (
			status === CANCEL &&
			uuid &&
			isAppointmentBooked(appointment)
		) {
			setTimeout(() => {
				setDialogShow(true);
				setDialogType(CANCEL);
				setDialogConfirmFun(uuid);
			}, 200);
		}
	};

	const navigatePaymentTab = (appointment) => {
		const { user_uuid } = appointment;
		const fullName = `${
			get(appointment, 'patient.first_name', '--') || '--'
		} ${get(appointment, 'patient.last_name', '--') || '--'}`;
		dummyDivRef.current.click();
		navigateToPatientDetailPymntTab(user_uuid, fullName);
	};

	const navigatePaymentTabURL = (appointment) => {
		const { user_uuid } = appointment;
		return `/app/patientdetail/${user_uuid}/5/0/`;
	};

	const closePopoverDialog = () => {
		dummyDivRef.current.click();
	};

	const isAppointmentBooked = (appointment) => {
		const status = _.get(appointment, 'visit_status', '');
		return status === 'booked';
	};

	const getGroupDate = (group) => {
		const stringdate = _.get(group, 'key', '');
		const date = moment(stringdate, 'YYYY-MM-DD');
		const timestamp = date.unix();
		const day = date.format('DD');
		const monthday = date.format('MMM, ddd');
		return { day, monthday, timestamp };
	};

	const isTodayDate = (group) => {
		const stringdate = _.get(group, 'key', '');
		const today = moment().format('YYYY-MM-DD');
		return today == stringdate;
	};

	const ucFirstAllWords = (str, separator = ' ') => {
		const pieces = str.split(separator);
		for (let i = 0; i < pieces.length; i++) {
			const j = pieces[i].charAt(0).toUpperCase();
			pieces[i] = j + pieces[i].substr(1).toLowerCase();
		}
		return pieces.join(separator);
	};

	const getProvideIcon = (appointment) => {
		const providerName = _.get(appointment, 'visit_provider', '');
		let providerImage = null;
		const mappedImage = providers_images[providerName];
		if (providerName && mappedImage) {
			providerImage = <img src={mappedImage} alt='' />;
		} else if (providerName != '')
			providerImage = <img src={imageDefault} alt='' />;
		return providerImage;
	};

	const getConsultationSubType = (obj) => {
		return obj.type && obj.consultation_type && obj.type == 'visit'
			? obj.consultation_type
			: '';
	};

	const getVisitStatus = (obj) => {
		const enums = appointmentStatus;
		const enum_value = _.find(enums, function (o) {
			return o.key == _.get(obj, 'visit_status', '--');
		});
		let visitStatus = '';
		if (enum_value) {
			visitStatus = _.get(enum_value, 'display_name', '');
		} else {
			const separator = '_';
			visitStatus = ucFirstAllWords(
				_.get(obj, 'visit_status', ''),
				separator
			);
		}
		return visitStatus;
	};

	const getVisitPayor = ({ payor }) => {
		const enumMatch = payorEnums.find((e) => e.key === payor);
		const display_name = enumMatch ? enumMatch.display_name : payor;

		return display_name === 'Self Pay' ? 'Non-insurance' : display_name;
	};

	const getVisitStatusColor = (obj) => {
		return _.get(
			visit_status_colors,
			_.get(obj, 'visit_status', ''),
			'green'
		);
	};

	const getVisitProvidername = (obj) => {
		const enum_value = _.find(visitProviders, function (o) {
			return o.key == _.get(obj, 'visit_provider', '--');
		});
		let providerName = '';
		let separator = ' ';
		if (enum_value) {
			providerName = _.get(enum_value, 'display_name', '');
		} else {
			separator = '_';
			providerName = ucFirstAllWords(
				_.get(obj, 'visit_provider', ''),
				separator
			);
		}
		if (providerName.length > 0) {
			const ProviderNameobj = providerName.split(separator);
			const first_name = _.get(ProviderNameobj, 0, '');
			const middle_name =
				providerName.length > 2
					? ' ' + _.get(ProviderNameobj, 1, '')
					: '';
			const last_name =
				providerName.length > 2
					? ' ' + _.get(ProviderNameobj, 2, '')
					: ' ' + _.get(ProviderNameobj, 1, '');
			const result = first_name.charAt(0) + '.' + middle_name + last_name;
			return result;
		} else {
			return '--';
		}
	};

	const getRefferalname = (obj) => {
		return _.get(obj, ['patient', 'partner', 'display_name'], '');
	};

	const isAppointmentStartedFunc = (appointment) => {
		if (appointment && appointment.date_of_service) {
			const appointmentDateObj = moment(
				appointment.date_of_service,
				'YYYY-MM-DD HH:mm:ss'
			).toDate();
			const appointmentTime =
				appointmentDateObj.getTime() -
				appointmentDateObj.getTimezoneOffset() * 60000;
			const currentTime = new Date().getTime();
			return currentTime >= appointmentTime;
		}
		return false;
	};

	const getPatientFullName = (appointment) => {
		const full_name =
			_.get(appointment, ['patient', 'first_name'], '') +
			' ' +
			_.get(appointment, ['patient', 'last_name'], '');
		return full_name;
	};

	const getPatientEmail = (appointment) => {
		const email = _.get(appointment, ['patient', 'email'], '');
		return email;
	};

	const getPatientPhone = (appointment) => {
		const phone = _.get(appointment, ['patient', 'phone'], '');
		return phone;
	};

	const getPatientModality = (appointment) => {
		const modality = _.get(appointment, ['tm_platform'], '');
		return modality;
	};

	const getPatientModalityIcon = (appointment) => {
		const modality = _.get(appointment, ['tm_platform'], '');
		if (modality.toLowerCase() == 'video')
			return <Icon className='modalityIcon' icon='video' />;
		return <Icon className='modalityIcon' icon='phone' />;
	};

	const getFulldateofService = (appointment) => {
		const dos = moment(_.get(appointment, 'date_of_service'));
		const endtime = moment(_.get(appointment, 'end_time'));
		const timezone = _.get(appointment, 'timezone');
		const dosmoment = moment.tz(dos.format('YYYY-MM-DD HH:mm:ss'), 'UTC');
		const endtimemoment = moment.tz(
			endtime.format('YYYY-MM-DD HH:mm:ss'),
			'UTC'
		);

		if (timezone && timezone.length > 0) {
			const date_time_zone = moment
				.tz(dosmoment, timezone)
				.format('dddd, MMM D, h:mm A');
			const endtime_zone = moment
				.tz(endtimemoment, timezone)
				.format('h:mm A z ');
			return date_time_zone + ' - ' + endtime_zone;
		} else {
			const tzone = moment.tz.guess();
			const date_time_zone = moment
				.tz(dosmoment, tzone)
				.format('dddd, MMM D, h:mm A');
			const endtime_zone = moment
				.tz(endtimemoment, tzone)
				.format('h:mm A z ');
			return date_time_zone + ' - ' + endtime_zone;
		}
	};

	const confirmModalityChange = (appointment, type) => {
		const phone = _.get(appointment, ['patient', 'phone'], '');
		if (type == 'phone' && (_.isNil(phone) || _.isEmpty(phone))) {
			/* alert for modality can't not change */
			setShowModalityConfirmDialog(false);
			setShowModalityErrorDialog(true);
			setUpdatingModalityUuid(null);
		} else {
			setUpdatingModalityAppointment(appointment);
			setUpdatingModalityType(type);
			setShowModalityConfirmDialog(true);
		}
	};

	const renderappointmentGroup = (group) => {
		const groupstringdate = getGroupDate(group);
		const dayclass = isTodayDate(group)
			? 'app_table_num'
			: 'app_table_num_blank';
		const datestringclass = isTodayDate(group) ? '' : 'app_table_day_blank';
		let index = 0;
		return (
			<div
				className='flex-table appointment_list_table'
				role='rowgroup'
				key={_.get(groupstringdate, 'timestamp', '')}
			>
				<div
					className='flex-row app_table_title_date rowspan first'
					style={{ width: '8%' }}
				>
					<div className='app_table_date'>
						<div className={dayclass}>
							{_.get(groupstringdate, 'day', '')}
						</div>
						<div className={datestringclass}>
							{_.get(groupstringdate, 'monthday', '')}
						</div>
					</div>
				</div>
				<div className='column' style={{ width: '92%' }}>
					{_.get(group, 'data', []).map((appointment) => {
						const encounter_uuid = _.get(appointment, ['uuid'], 0);
						const StartTime = UTCToCurrentTimeView(
							moment,
							moment(_.get(appointment, 'date_of_service')),
							_.get(appointment, 'timezone')
						);
						const statusclass =
							'flex-cell app_table_head_status status-' +
							getVisitStatusColor(appointment);
						const appclassnames =
							_.get(group, ['data', 'length'], 0) == index + 1
								? 'flex-row'
								: 'flex-row  border-btmshow';
						index = index + 1;
						const selected_modality = _.get(
							appointment,
							'tm_platform',
							''
						);
						return (
							<Loading
								key={'loader-' + encounter_uuid}
								loading={
									switchingEncounterModality &&
									updatingModalityUuid == encounter_uuid
								}
								className='appointmentcell_loading'
							>
								<div className='appointmentcell_hover'>
									<span style={{ width: '90%' }}>
										<Popover
											width='200'
											visibleArrow={false}
											trigger='click'
											popperClass={classnames(
												'appointmentquickview'
											)}
											content={getPopOverView(
												appointment
											)}
											key={
												_.get(appointment, 'uuid', '') +
												'popover'
											}
											visible={visible}
										>
											<div
												className={appclassnames}
												key={
													_.get(
														appointment,
														'uuid',
														''
													) + 'row'
												}
											>
												<div
													className='flex-cell app_table_head_time'
													role='cell'
												>
													{StartTime}
												</div>
												<div
													className='flex-cell app_table_head_fullname'
													role='cell'
												>
													{getPatientFullName(
														appointment
													)}
												</div>
												<div
													className='flex-cell app_table_head_provider'
													role='cell'
												>
													{getProvideIcon(
														appointment
													)}{' '}
													<span className='font-bold'>
														{getVisitProvidername(
															appointment
														)}
													</span>
												</div>
												<div
													className='flex-cell app_table_head_type'
													role='cell'
												>
													{getConsultationSubType(
														appointment
													)}
												</div>
												<div
													className={statusclass}
													role='cell'
												>
													{getVisitStatus(
														appointment
													)}
												</div>
												<div
													className='flex-cell app_table_head_payor'
													role='cell'
												>
													{getVisitPayor(appointment)}
												</div>
											</div>
										</Popover>
									</span>
									<span
										style={{
											display: 'flex',
											width: '10%',
										}}
									>
										<div
											className={classnames(
												appclassnames
											)}
											key={
												_.get(appointment, 'uuid', '') +
												'modality-row'
											}
										>
											<div
												className='flex-cell app_table_head_modality'
												role='cell'
												key={
													_.get(
														appointment,
														'uuid',
														''
													) + 'modality'
												}
											>
												<RBAC
													action={
														CARE_APPOINTMENTS_APPOINTMENT_MODALITY
													}
													yes={
														<Dropdown
															disabled={
																appointment
																	.patient
																	.partner
																	.is_phone_only
															}
															key={
																'modality-select-' +
																encounter_uuid
															}
															hideOnClick={false}
															onCommand={(type) =>
																confirmModalityChange(
																	appointment,
																	type
																)
															}
															menu={
																<Dropdown.Menu>
																	{modalities &&
																		modalities.map(
																			(
																				status
																			) => {
																				return (
																					status.key !=
																						'webinar' && (
																						<Dropdown.Item
																							disabled={
																								selected_modality ==
																								status.key
																							}
																							key={
																								'modality-select-options' +
																								'-' +
																								encounter_uuid +
																								'-' +
																								status.key
																							}
																							command={
																								status.key
																							}
																						>
																							{
																								status.display_name
																							}
																						</Dropdown.Item>
																					)
																				);
																			}
																		)}
																</Dropdown.Menu>
															}
														>
															<Button
																type='default'
																disabled={
																	updatingModalityUuid
																		? true
																		: false
																}
																className='modality-dropdown'
															>
																{
																	selected_modality
																}{' '}
																<i className='el-icon-caret-bottom el-icon--right'></i>
															</Button>
														</Dropdown>
													}
													no={
														<div>
															{' '}
															{_.capitalize(
																_.get(
																	appointment,
																	'tm_platform',
																	''
																)
															)}{' '}
														</div>
													}
												/>
											</div>
										</div>
									</span>
								</div>
							</Loading>
						);
					})}
				</div>
			</div>
		);
	};

	const getPopOverView = (appointment) => {
		const statusclass = 'popover_quickview_appointmentstatus';
		const visit_states = {
			cancelled_by_provider: 1,
			cancelled_rescheduled_by_provider: 1,
			cancelled_rescheduled_by_patient: 1,
			cancelled_by_care_coordinator: 1,
			cancelled_by_patient: 1,
			no_show: 1,
			completed: 1,
		};
		const isDisabledRescheduleApt =
			_.get(appointment, 'visit_status', '') in visit_states ||
			isAppointmentStartedFunc(appointment);
		const activeRole = _.get(me, 'activerole.name', null);
		const isExternal = isExternalUser(activeRole);
		return (
			<div>
				<div className='popover_quickview_title'>
					{isExternal === false && (
						<LinkPopover
							enqueueSnackbar={enqueueSnackbar}
							linkTo={navigatePaymentTabURL(appointment)}
							options={{ link: true }}
						>
							<Icon
								className='appointment-billing'
								icon='dollor-circle'
								onClick={() => navigatePaymentTab(appointment)}
							/>
						</LinkPopover>
					)}
					<Icon
						className='quick-apmnt-close'
						icon='close'
						onClick={closePopoverDialog}
					/>
				</div>
				<div className='popover_quickview_header'>
					<div className='popover_quickview_appname'>
						{getPatientFullName(appointment)}
					</div>
					<div className='popover_quickview_partnername'>
						{getRefferalname(appointment)}
					</div>
				</div>
				<div className='popover_quickview_midcontent'>
					<div>
						<span className='popover_quickview_result'>
							<LinkPopover
								enqueueSnackbar={enqueueSnackbar}
								linkTo={NavigateToEncounterDetailURL(
									appointment
								)}
								options={{ link: true }}
							>
								<div
									className='linkWrapper'
									onClick={() =>
										viewAppointmentHandler(appointment)
									}
								>
									<span>
										{getConsultationSubType(appointment)}
									</span>
								</div>
							</LinkPopover>
						</span>
					</div>
					<div className='popover_quickview_phonetitle'>
						{getPatientModalityIcon(appointment)}
						<span>{getPatientModality(appointment)}</span>
					</div>
					<div className='popover_quickview_datebox'>
						<div className='popover_quickview_appointmentdate'>
							{getFulldateofService(appointment)} -
							<span>
								&nbsp;{_.get(appointment, 'state_of_visit', '')}
							</span>
						</div>
						<div className={statusclass}>
							{getVisitStatus(appointment)}
						</div>
					</div>
					<div className='popover_quickview_pioneer'>
						<span className='popover_label'>
							Pioneer Appointment ID
						</span>
						<span className='popover_value'>
							{_.get(appointment, 'pioneer_appointment_id') ||
								'N/A'}
						</span>
					</div>
					<div className='popover_quickview_providercontainer'>
						<div className='popover_quickview_appointmentprovidername'>
							{getProvideIcon(appointment)}
							<span>{getVisitProvidername(appointment)}</span>
						</div>
						<div className='popover_quickview_appointmentemail'>
							{getPatientEmail(appointment)}
						</div>
						<div className='popover_quickview_appointmentproviderno'>
							{getPatientPhone(appointment)}
						</div>
					</div>
				</div>

				<RBAC
					action={CARE_APPOINTMENTS_APPOINTMENT_CANCEL}
					yes={
						<div className='popover_quickview_footer'>
							<div>
								<Tooltip
									className='item'
									effect='dark'
									content='Reschedule'
									placement='top'
									disabled={isDisabledRescheduleApt}
								>
									<button
										type='button'
										className='popover_quickview_editbtn'
										onClick={() =>
											navigateToRescheduleAppointmentPage(
												_.get(appointment, 'uuid', 0)
											)
										}
										disabled={isDisabledRescheduleApt}
									>
										<Icon
											className='pencilIcon'
											icon='pencil'
										/>
										<span>Reschedule</span>
									</button>
								</Tooltip>
							</div>
							{isAppointmentBooked(appointment) === true && (
								<div>
									<Tooltip
										className='item'
										visibleArrow={true}
										effect='dark'
										content='Cancel appointment'
										placement='top-start'
									>
										<button
											type='button'
											className='popover_quickview_cancelby'
											onClick={() =>
												showRemoveDialog(
													CANCEL,
													appointment
												)
											}
										>
											<Icon
												icon='calendar-close'
												className='calendarCloseIcon'
											/>
										</button>
									</Tooltip>
								</div>
							)}
							{isAppointmentBooked(appointment) === true &&
								isAppointmentStartedFunc(appointment) ===
									true && (
									<div>
										<Tooltip
											className='item'
											visibleArrow={true}
											effect='dark'
											content=' Mark as No show'
											placement='top-start'
										>
											<button
												type='button'
												className='popover_quickview_noshow'
												onClick={() =>
													showRemoveDialog(
														NO_SHOW,
														appointment
													)
												}
											>
												<Icon
													icon='timer-help'
													className='timerHelpIcon'
												/>
											</button>
										</Tooltip>
									</div>
								)}
						</div>
					}
				/>
			</div>
		);
	};

	const renderModalityConfirmDialog = () => {
		const oldModality = _.get(
			updatingModalityAppointment,
			'tm_platform',
			''
		);
		const newModality = updatingModalityType;
		return (
			<div className='appointment-modalitypopup'>
				<Dialog
					className='modality-dialog'
					title={
						<span className='title'>
							<Icon
								icon='warning-circle'
								className='error-image'
							/>{' '}
							<span className='float-left'>Modality change</span>
						</span>
					}
					size='tiny'
					visible={showModalityConfirmDialog}
					onCancel={closeModalityConfirmDialog}
					lockScroll={false}
					showClose={false}
				>
					<Dialog.Body>
						<span>
							Are you sure you would like to change the
							appointment's modality{' '}
							<b className='modality-text'>{oldModality}</b> to{' '}
							<b className='modality-text'>{newModality}</b>?
						</span>
					</Dialog.Body>
					<Dialog.Footer className='dialog-footer'>
						<Button onClick={closeModalityConfirmDialog}>
							Cancel
						</Button>
						<Button
							type='primary'
							onClick={() =>
								changeModality(
									updatingModalityAppointment,
									updatingModalityType
								)
							}
						>
							Yes, change
						</Button>
					</Dialog.Footer>
				</Dialog>
			</div>
		);
	};

	const renderModalityErrorDialog = () => {
		return (
			<div className='appointment-dialogpopup'>
				<Dialog
					className='modality-dialog'
					title={
						<span className='title'>
							<Icon
								icon='warning-circle'
								className='error-image'
							/>
							<span className='error-title'>Error</span>
						</span>
					}
					size='tiny'
					visible={showModalityErrorDialog}
					onCancel={closeModalityErrorDialog}
					lockScroll={false}
					showClose={false}
				>
					<Dialog.Body>
						<span className='error-text'>
							Cannot update the modality to phone because patient
							does not have a phone number recorded.
						</span>
					</Dialog.Body>
					<Dialog.Footer className='dialog-footer'>
						<Button
							className='close-modal'
							type='primary'
							onClick={closeModalityErrorDialog}
						>
							{' '}
							Confirm
						</Button>
					</Dialog.Footer>
				</Dialog>
			</div>
		);
	};

	return (
		<Fragment>
			<div className='app_list_sec'>
				{loaded ? (
					<Fragment>
						<div className='app_list_container'>
							<Fragment>
								<div className='appointment_list_view_header'>
									<div style={{ width: '7%' }}>Date</div>
									<div
										style={{
											display: 'flex',
											width: '93%',
										}}
									>
										<span
											style={{
												display: 'flex',
												width: '90%',
											}}
										>
											<div style={{ width: '10%' }}>
												Start Time
											</div>
											<div style={{ width: '20%' }}>
												Name
											</div>
											<div style={{ width: '20%' }}>
												Provider
											</div>
											<div style={{ width: '20%' }}>
												Type
											</div>
											<div style={{ width: '10%' }}>
												Visit Status
											</div>
											<div style={{ width: '20%' }}>
												Payor
											</div>
										</span>
										<span
											style={{
												display: 'flex',
											}}
										>
											<div style={{ width: '10%' }}>
												Modality
											</div>
										</span>
									</div>
								</div>
								<div className='appointment_list_view'>
									<div
										className='table-container '
										role='table'
									>
										{appointments.length > 0 ? (
											appointments.map((group) =>
												renderappointmentGroup(group)
											)
										) : (
											<div
												className='flex-table appointment_list_table no-result'
												role='rowgroup'
											>
												No results
											</div>
										)}
									</div>
								</div>
							</Fragment>
						</div>
						<div className='appointment-pgnation-wrapper'>
							<div key={'dummydiv'} ref={dummyDivRef}></div>
							<Pagination
								className='appointment-pagination'
								pageSize={_.get(
									appointmentPagination,
									['limit'],
									20
								)}
								total={_.get(
									appointmentPagination,
									['total'],
									0
								)}
								offset={_.get(
									appointmentPagination,
									['offset'],
									0
								)}
								layout='prev, pager, next'
								onCurrentChange={handlePageChange}
							/>
						</div>
					</Fragment>
				) : (
					<Loading loading={true} className='noBackgroundSpinner'>
						<div style={{ height: '50vh', width: '50vw' }}></div>
					</Loading>
				)}
				<Dialog
					title='Confirmation'
					size='tiny'
					visible={dialogShow}
					onCancel={() => resetDialog()}
					lockScroll={false}
					showClose={true}
				>
					<Dialog.Body>
						<Loading
							loading={dialogLoading}
							style={{ position: 'unset' }}
							className='changeAppointmentStatusLoader'
						>
							<span className='changeAppointmentStatusMessage'>
								{dialogType === CANCEL ? (
									<>
										<div style={{ marginBottom: '20px' }}>
											Are you sure you would like to
											cancel this appointment?
										</div>
										<div>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													marginBottom: '20px',
												}}
											>
												<CommonCheckbox
													checked={notifyPatient}
													onChangeFunc={() =>
														setNotifyPatient(
															(state) => !state
														)
													}
													marginLeftOverride={0}
													marginRightOverride={10}
													marginBottomOverride={10}
												/>
												<span>
													Notify patient of
													cancellation
												</span>
											</div>
										</div>
									</>
								) : dialogType === NO_SHOW ? (
									<div style={{ marginBottom: '20px' }}>
										Are you sure you would like to mark this
										appointment as No Show?
									</div>
								) : (
									''
								)}
							</span>
						</Loading>
					</Dialog.Body>
					<Dialog.Footer className='dialog-footer'>
						<Button onClick={() => resetDialog()}>
							No, Keep it
						</Button>
						{dialogType === CANCEL ? (
							<Button
								className='cancelpopup'
								type='primary'
								onClick={() => handleDialog()}
							>
								<Icon
									icon='calendar-close'
									className='cancelIcon'
								/>
								<span className='canceltext'>Yes, Cancel</span>
							</Button>
						) : dialogType === NO_SHOW ? (
							<Button
								className='noshowbtn'
								type='primary'
								onClick={() => handleDialog()}
							>
								<Icon
									icon='timer-help'
									className='noShowIcon'
								/>
								<span className='canceltext'>Yes, No Show</span>
							</Button>
						) : (
							''
						)}
					</Dialog.Footer>
				</Dialog>
			</div>
			{renderModalityErrorDialog()}
			{renderModalityConfirmDialog()}
			<AppointmentErrorDialog
				showErrorDialog={showErrorDialog}
				errorCloseHandler={errorCloseHandler}
				errorTitle={'Appointment Cancellation Failed'}
				initialErrorMessage={
					'We were unable to schedule this appointment due to '
				}
				messages={errorMessages}
			/>
		</Fragment>
	);
};

AppointmentList.propTypes = {
	history: PropTypes.object.isRequired,
	modalities: PropTypes.array.isRequired,
	changefilter: PropTypes.func.isRequired,
	appointmentPagination: PropTypes.object.isRequired,
	appointmentCancel: PropTypes.func.isRequired,
	appointmentNoShow: PropTypes.func.isRequired,
	getAppointments: PropTypes.func.isRequired,
};

export default AppointmentList;
